import React from 'react';
import FeatherIcon from "feather-icons-react";
import { BeatLoader } from "react-spinners";
import PreviewGoogleSearch from '../previews/google/search';
import tiktok_cta from '../assets/json/tiktok_cta.json';
import snapchat_cta from '../assets/json/snapchat_cta.json';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import PreviewSnapchatStories from '../previews/snapchat/stories';
import PreviewSectionFacebook from './previewSectionFacebook';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import PreviewSectionTwitter from './previewSectionTwitter';
import PreviewTwitterCarousel from '../previews/twitter/carousel';
import PreviewGoogleDisplaySquare from '../previews/google/displayBannerSquare'
import PreviewGoogleDisplaySkyscraper from '../previews/google/displayBannerSkyscraper'
import PreviewGoogleDisplayBillboard from '../previews/google/displayBannerBillboard'
import PreviewSectionSnapchat from './previewSectionSnapchat';
import domtoimage from 'dom-to-image';
import PreviewSectionGoogle from './previewSectionGoogle';

export default class AdvancedReportCellPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cell: {},
            prevent_snapshot_loop: 0,
            load_status: "Waiting on data from platforms",
        };
    }

    async componentDidMount() {
        await this.setState({
            cell: this.props.source ? this.props.source : {}
        });
        // console.log(this.state.cell);
        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200);
    }

    async componentWillReceiveProps(nextProps, nextContext) {

        await this.promisedSetState({
            cell: nextProps.source ? nextProps.source : {}
        });

        // console.log(this.state.cell);

        //TAKE SNAPSHOT FOR POWERPOINT
        if (!this.state.cell.loading && this.state.cell.data && !this.props.static) {
            if (!this.state.cell.preview_url || (this.state.cell.preview_url !== nextProps.source.preview_url)) {
                await this.sleep(1000);
                if (!this.state.cell.fake) {
                    if (this.state.prevent_snapshot_loop < 3) {
                        this.takeSnapSot();
                    }
                }
            }
        }

    }

    takeSnapSot() {
        let self = this;
        let node = document.getElementById(self.state.cell.i + '_preview');
        domtoimage.toPng(node)
            .then(function (dataUrl) {
                if (self.props.onUpdate && dataUrl) {
                    self.props.onUpdate({ data: dataUrl });
                }
            })
            .catch(function (error) {
                self.setState({
                    prevent_snapshot_loop: self.state.prevent_snapshot_loop += 1
                });
            });
    }

    setLoader() { }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div
                className="chart-template-img w-full h-full flex justify-center relative"
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
            >
                {/* FAKE PREVIEWS */}
                {
                    this.state.cell.data && this.state.cell.data.fake &&
                    !this.state.cell.loading &&
                    <div className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                        {
                            [{}, {}, {}, {}, {}, {}].map((ad) => {
                                return (
                                    <div className="flex items-center justify-center">
                                        <div className="scale-75 transform w-full flex items-center justify-center">
                                            <div className="border rounded-md bg-custom-input flex items-center justify-center h-56 w-80">
                                                <div className="text-xs">Preview placeholder</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/* FORMATS */}
                {
                    !(this.state.cell.data && this.state.cell.data.fake) &&
                    !this.state.cell.loading &&
                    this.state.cell && this.state.cell.data &&
                    <div className={(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) ? "" : "scale-75") + " transform w-full h-full flex justify-center"}>

                        {/* TIKTOK VIDEO */}
                        {
                            this.state.cell.data.channel === "tiktok" &&
                            this.state.cell.preview_placement !== "no_frame" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            <PreviewTikTokFeed
                                video={this.state.cell.data.url || null}
                                cta={tiktok_cta.find((cta => cta.id.includes(this.state.cell.data.report_ad_data.call_to_action)))}
                                page={(this.state.cell.data.report_ad_data && this.state.cell.data.report_ad_data.page) || null}
                                text={(this.state.cell.data.report_ad_data && this.state.cell.data.report_ad_data.text) || null}
                                thumbnail={null}
                            />
                        }

                        {/* TIKTOK IMAGE NO FRAME */}
                        {
                            !(this.state.cell.data.channel === "tiktok" && this.state.cell.preview_placement !== "no_frame") &&
                            this.state.cell.data.channel === "tiktok" &&
                            this.state.cell.data.creative_type === "image" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            (this.state.cell && (this.state.cell.preview_placement === "no_frame" || !this.state.cell.preview_placement)) &&
                            <img
                                className="h-full object-contain"
                                src={this.state.cell.data.url}
                                alt={"Creative media"}
                            />
                        }

                        {/* TIKTOK VIDEO NO FRAME */}
                        {
                            !(this.state.cell.data.channel === "tiktok" && this.state.cell.preview_placement !== "no_frame") &&
                            this.state.cell.data.channel === "tiktok" &&
                            this.state.cell.data.creative_type === "video" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            (this.state.cell && (this.state.cell.preview_placement === "no_frame" || !this.state.cell.preview_placement)) &&
                            <video className="w-full h-full" controls={true} loop={true} autoPlay={false} muted={true}>
                                <source src={this.state.cell.data.url} type="video/mp4" ref="video" />
                            </video>
                        }

                        {
                            this.state.cell.data.channel === "tiktok" &&
                            this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) &&
                            <div className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                {
                                    this.state.cell.data.ads.map((ad) => {
                                        return (
                                            <div className="flex items-center justify-center">
                                                <div className="scale-75 transform w-full flex items-center justify-center">
                                                    <div className="w-full">
                                                        {
                                                            this.state.cell.preview_placement !== "no_frame" &&
                                                            ad.report_ad_data &&
                                                            <PreviewTikTokFeed
                                                                video={ad.url || null}
                                                                cta={ad.report_ad_data && ad.report_ad_data.call_to_action ? tiktok_cta.find((cta => cta.id.includes(ad.report_ad_data.call_to_action))) : {}}
                                                                page={(ad.report_ad_data && ad.report_ad_data.page) || null}
                                                                text={(ad.report_ad_data && ad.report_ad_data.text) || null}
                                                                thumbnail={null}
                                                            />
                                                        }
                                                        {
                                                            !(this.state.cell.preview_placement !== "no_frame" && ad.report_ad_data) &&
                                                            (this.state.cell && (this.state.cell.preview_placement === "no_frame" || !this.state.cell.preview_placement)) &&
                                                            <img
                                                                className="h-full object-contain"
                                                                src={ad.url}
                                                                alt={"Creative media"}
                                                            />
                                                        }
                                                        {
                                                            !(this.state.cell.preview_placement !== "no_frame" && ad.report_ad_data) &&
                                                            ad.data &&
                                                            ad.data.creative_type === "video" &&
                                                            (this.state.cell && (this.state.cell.preview_placement === "no_frame" || !this.state.cell.preview_placement)) &&
                                                            <video className="w-full h-full" controls={false} loop={true} autoPlay={true} muted={true}>
                                                                <source src={ad.url} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {/* FACEBOOK */}
                        {
                            this.state.cell.data.channel === "facebook" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            <PreviewSectionFacebook
                                ad={this.state.cell.data.report_ad_data ? this.state.cell.data.report_ad_data : {}}
                                channel={{ value: this.state.cell.data.channel }}
                                for_report={true}
                                is_post={this.state.cell.data.report_ad_data && this.state.cell.data.report_ad_data.is_post}
                                preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "feed"}
                            />
                        }
                        {
                            this.state.cell.data.channel === "facebook" &&
                            this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) &&
                            <div className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                {
                                    this.state.cell.data.ads.map((ad) => {
                                        return (
                                            <div className="flex items-center justify-center">
                                                <div className="scale-75 transform w-full flex items-center justify-center">
                                                    <div className="w-full">
                                                        <PreviewSectionFacebook
                                                            ad={ad.report_ad_data ? ad.report_ad_data : {}}
                                                            channel={{ value: ad.channel }}
                                                            for_report={true}
                                                            is_post={ad.report_ad_data && ad.report_ad_data.is_post}
                                                            preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "feed"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {/* TWITTER  */}
                        {
                            this.state.cell.data.channel === "twitter" &&
                            <PreviewSectionTwitter
                                ad={this.state.cell.data.report_ad_data}
                                channel={{ value: this.state.cell.data.channel }}
                                for_report={true}
                                preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "feed"}
                            />
                        }

                        {/* SNAPCHAT */}
                        {
                            this.state.cell.data.channel === "snapchat" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            <PreviewSectionSnapchat
                                ad={this.state.cell.data.report_ad_data}
                                channel={{ value: this.state.cell.data.channel }}
                                for_report={true}
                                preview_placement={this.state.cell && this.state.cell.settings.preview_placement ? this.state.cell.settings.preview_placement.value : "story"}
                            />
                        }
                        {
                            this.state.cell.data.channel === "snapchat" &&
                            this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) &&
                            <div className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                {
                                    this.state.cell.data.ads.filter((item) => {
                                        return item.report_ad_data
                                    }).map((ad) => {
                                        return (
                                            <div className="flex items-center justify-center">
                                                <div className="scale-75 transform w-full flex items-center justify-center">
                                                    <div className="w-full">
                                                        <PreviewSectionSnapchat
                                                            ad={ad.report_ad_data}
                                                            channel={{ value: ad.channel }}
                                                            for_report={true}
                                                            preview_placement={this.state.cell && this.state.cell.settings.preview_placement ? this.state.cell.settings.preview_placement.value : "story"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {/* LINKEDIN */}
                        {
                            this.state.cell.data.channel === "linkedin" &&
                            !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                            <PreviewSectionLinkedin
                                ad={this.state.cell.data.report_ad_data}
                                channel={{ value: this.state.cell.data.channel }}
                                for_report={true}
                                preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "feed"}
                            />
                        }
                        {
                            this.state.cell.data.channel === "linkedin" &&
                            this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) &&
                            <div className={"flex-row px-8 w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                {
                                    this.state.cell.data.ads.map((ad) => {
                                        return (
                                            <div className="flex items-center justify-center">
                                                <div className="scale-75 transform w-full flex items-center justify-center">
                                                    <div className="w-full">
                                                        <PreviewSectionLinkedin
                                                            ad={ad.report_ad_data ? ad.report_ad_data : {}}
                                                            channel={{ value: ad.channel }}
                                                            for_report={true}
                                                            preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "feed"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            <>
                                {(this.state.cell.data.channel === "google" || this.state.cell.data.channel === "bing") &&
                                    !(this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads)) &&
                                    <PreviewSectionGoogle
                                        ad={this.state.cell.data.report_ad_data}
                                        subType={this.state.cell.data.sub_type}
                                        channel={{ value: "google" }}
                                        for_report={true}
                                        preview_placement={this.state.cell && this.state.cell.preview_placement ? this.state.cell.preview_placement.value : "square"}
                                    />}
                            </>
                        }

                        {
                            (this.state.cell.data.channel === "google" || this.state.cell.data.channel === "bing") &&
                            this.state.cell.data.multiple && Array.isArray(this.state.cell.data.ads) &&
                            <div className={(this.state.cell.h >= 5 ? "flex-col py-8" : "flex-row px-8") + " w-full justify-start overflow-x-auto flex items-center table-overflow"}>
                                {
                                    this.state.cell.data.ads.map((ad) => {
                                        if (ad.type == "display" && Array.isArray(ad.square_images) && ad.square_images.length > 0) {
                                            console.log("ad1", ad)
                                            return (
                                                <div className={this.state.cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                    <PreviewGoogleDisplaySquare
                                                        image={ad.square_images && ad.square_images.length > 0 ? ad.square_images[0].image : ""}
                                                        subType={ad.sub_type}
                                                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                        main_color={ad.main_color ? ad.main_color : ""}
                                                        accent_color={ad.accent_color ? ad.accent_color : ""}
                                                    />
                                                </div>
                                            )
                                        } else if (ad.type == "display" && Array.isArray(ad.vertical_images) && ad.vertical_images.length > 0) {
                                            console.log("ad2", ad)

                                            return (
                                                <div className={this.state.cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                    <PreviewGoogleDisplaySkyscraper
                                                        image={ad.vertical_images && ad.vertical_images.length > 0 ? ad.vertical_images[0].image : ""}
                                                        subType={ad.sub_type}
                                                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                        main_color={ad.main_color ? ad.main_color : ""}
                                                        accent_color={ad.accent_color ? ad.accent_color : ""}
                                                    />
                                                </div>
                                            )
                                        } else if (ad.type == "display" && Array.isArray(ad.horizontal_images) && ad.horizontal_images.length > 0) {
                                            console.log("ad3", ad)

                                            return (
                                                <div className={this.state.cell.h >= 5 ? "mb-4" : "mr-4"}>
                                                    <PreviewGoogleDisplayBillboard
                                                        image={ad.horizontal_images && ad.horizontal_images.length > 0 ? ad.horizontal_images[0].image : ""}
                                                        subType={ad.sub_type}
                                                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                                                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                                                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                                                        main_color={ad.main_color ? ad.main_color : ""}
                                                        accent_color={ad.accent_color ? ad.accent_color : ""}
                                                    />
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="flex items-center justify-center" style={{ maxWidth: "500px" }}>
                                                    <div className="scale-75 transform w-full flex items-center justify-center">
                                                        <div style={{ minWidth: "600px" }} className="w-full">
                                                            <PreviewGoogleSearch
                                                                headlineFirst={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? (typeof ad.headlines[0] == "string" ? ad.headlines[0] : ad.headlines[0].text) : false}
                                                                headlineSecond={Array.isArray(ad.headlines) && ad.headlines.length > 1 ? (typeof ad.headlines[1] == "string" ? ad.headlines[1] : ad.headlines[1].text) : false}
                                                                headlineThird={Array.isArray(ad.headlines) && ad.headlines.length > 2 ? (typeof ad.headlines[2] == "string" ? ad.headlines[2] : ad.headlines[2].text) : false}
                                                                bodyOne={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? (typeof ad.descriptions[0] == "string" ? ad.descriptions[0] : ad.descriptions[0].text) : false}
                                                                bodySecond={Array.isArray(ad.descriptions) && ad.descriptions.length > 1 ? (typeof ad.descriptions[1] == "string" ? ad.descriptions[1] : ad.descriptions[1].text) : false}
                                                                link={Array.isArray(ad.final_urls) && ad.final_urls.length > 0 ? ad.final_urls[0] : false}
                                                                path1={ad.path1}
                                                                path2={ad.path2}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }

                    </div>
                }

                {/* NO FORMAT */}
                {
                    !(this.state.cell.data && this.state.cell.data.fake) &&
                    !this.state.cell.loading &&
                    this.state.cell.data &&
                    this.state.cell.data.type === "" &&
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <span className="text-red-500 text-sm cursor-pointer font-bold">Format not supported</span>
                    </div>
                }

                {/* LOADING */}
                {
                    this.state.cell.loading &&
                    <div className="w-full h-full absolute flex flex-col justify-center items-center">
                        <div>
                            <div style={{ borderTopColor: "transparent" }} class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                        <div className="text-xs mt-3">{this.state.load_status}</div>
                    </div>
                }

            </div >
        );
    }

}
