import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from "react-spinners";
import { SlideDown } from "react-slidedown";
import { calls } from "./calls";
import cx from "classnames";
import { userRegister } from "../../services/userRegister";
// import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CogIcon, LockClosedIcon, UserGroupIcon } from '@heroicons/react/outline';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, AdjustmentsIcon, TrashIcon, UserGroupIcon, CogIcon, CalculatorIcon, IdentificationIcon, UsersIcon, FolderIcon } from '@heroicons/react/outline'
import InputTailwind from '../../moduleFiles/inputTailwind';
import { apiRegister } from '../../services/apiRegister';
import { tokenRegister } from '../../services/tokenRegister';
import { clientRegister } from '../../services/clientRegister';


var moment = require('moment');

class CreateNewReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            // open: {
            // },
            open: {
                type: true,
            },
            search: {},
            clients: [],
            // page: 0,
            // page_size: 10,
            client: {},
            type: {},
            selected_external: {},
            external_campaigns: {},
            template: null,
            loading_internals: false,
            loading_externals: false,
            advancedUser: false,
            campaigns: [],
            sort: 'name',
            order: 'asc',
            name: "",
            name_error: true,
            templates: [],
            selected_template: {},
            selected_campaigns: [],
            report_type: "",
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
        })
        console.log(nextProps)
    }

    componentWillMount() { }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.functions.user();
        this.functions.getAllTemplates();
        this.functions.init();
        if (window.location.pathname.indexOf("advanced") !== -1) {
            this.setState({ report_type: "advanced" })
        } else {
            this.setState({ report_type: "standard" })

        }
    }

    functions = {
        init: () => {
            this.setState({
                client: {},
                type: {},
                selected_external: {},
                external_campaigns: {},
                open: {
                    type: true,
                },
                // template: this.props.template,
                loading_internals: false,
                loading_externals: false
            }, () => {
                if (this.state.clients.length < 1) {
                    this.functions.clients();
                }
            })
        },
        getQuery: (q) => {
            return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [null])[1];
        },
        user: () => {
            let user_data = userRegister.get();
            if (user_data.advanced) {
                this.setState({
                    advancedUser: user_data.advanced
                })
            }
        },
        clients: () => {
            this.setState({
                loading_clients: true
            }, () => {
                calls.getClient().then((response) => {
                    this.setState({
                        clients: response.data,
                        external_campaigns: {},
                        loading_clients: false,
                    });
                }, (error) => {
                    this.setState({
                        loading_clients: false,
                    });
                }
                );
            }
            );
        },
        campaigns: () => {
            this.setState({
                loading_externals: true
            }, async () => {
                let campaigns = {};
                try {
                    let response = await calls.getCampaignsByClient(this.state.client.id, 0, this.state.sort, this.state.order, 50000);
                    // WAS ! BEFORE
                    if (this.state.closed) {
                        campaigns = { ...campaigns, ...{ 'internal': response.data } }
                    }
                } catch (error) { }
                try {
                    let response = await calls.getAllExternalCampaigns({ id: this.state.client.id });
                    if (this.state.closed) {
                        if (response.data && Array.isArray(response.data.adform)) {
                            response.data.adform = response.data.adform.map((item) => {
                                this.renders.daysToEndDate(item.endDate);
                                if (this.renders.daysToEndDate(item.endDate) > 397) {
                                    item.disabled = true;
                                }
                                return item;
                            });
                        }
                        campaigns = { ...campaigns, ...response.data };
                    }
                } catch (error) {
                    console.log("error", error)
                }

                this.setState({
                    external_campaigns: campaigns,
                    loading_externals: false
                });
            })
        },
        create: () => {
            this.setState({
                loading_report: true
            }, () => {

                //CREATE NEW CAMPAIGN OBJECT (HIDDEN)
                let campaign_data = {
                    type: this.state.type.value,
                    client: this.state.client.id,
                    name: this.state.name,
                };

                if (this.state.type.value === "template") {
                    campaign_data.template = this.state.selected_template;
                    this.setState({
                        template: this.state.selected_template
                    })
                }

                for (let key in this.state.selected_external) {
                    campaign_data[key] = [];
                    for (let campaign in this.state.selected_external[key]) {
                        if (this.state.selected_external[key][campaign].id) {
                            campaign_data[key].push(this.state.selected_external[key][campaign]);
                        }
                    }
                }

                console.log("campaign_data", campaign_data)

                //IF ONLY ONE INTERNAL CAMPAIGN IS SELECTED USE OLD WAY, ELSE CREATE NEW HIDDEN CAMPAIGN
                if (campaign_data.client && campaign_data.internal && campaign_data.internal.length === 1 && Object.keys(campaign_data).length === 2) {
                    console.log("one internal campaign")
                    if (this.state.template) {
                        calls.updateReport({
                            report: this.state.template.id,
                            client: this.state.client.id,
                            clientName: this.state.client.name,
                            campaign: campaign_data.internal[0].id,
                            campaignName: campaign_data.internal[0].name
                        }).then((response) => {
                            if (this.state.template.freemium) {
                                this.props.history.push('/v2/reports/standard/' + campaign_data.internal[0].id + "/" + this.state.template.id);
                            } else {
                                this.props.history.push('/v2/reports/advanced/' + campaign_data.internal[0].id + "/" + this.state.template.id);
                            }
                        }, (error) => {

                        });
                    } else {
                        if (this.state.type && this.state.type.id === "standard") {
                            this.props.history.push('/v2/reports/new/standard/' + campaign_data.internal[0].id);
                        } else {
                            this.props.history.push('/v2/reports/new/advanced/' + campaign_data.internal[0].id + "?client=" + this.state.client.id);
                        }
                    }
                } else {
                    console.log("mupltiple campaigns")
                    for (let key in campaign_data) {
                        if (Array.isArray(campaign_data[key])) {
                            campaign_data[key] = campaign_data[key].map((item) => {
                                return item.id;
                            })
                        }
                    }
                    console.log("camp data", campaign_data)
                    calls.createReportCampaign(campaign_data).then((campaign) => {
                        if (campaign && campaign.data && campaign.data.id) {
                            console.log("call createReport if", this.state.template)
                            if (this.state.template) {
                                console.log("call createReport if template")
                                calls.updateReport({
                                    report: this.state.template.id,
                                    client: this.state.client.id,
                                    clientName: this.state.client.name,
                                    campaign: campaign.data.id,
                                    campaignName: campaign.data.name
                                }).then((response) => {
                                    if (this.state.template.freemium) {
                                        this.props.history.push('/v2/reports/standard/' + campaign.data.id + "/" + this.state.template.id);
                                    } else {
                                        this.props.history.push('/v2/reports/advanced/' + campaign.data.id + "/" + this.state.template.id);
                                    }
                                }, (error) => {

                                });
                            } else {
                                console.log("call createReport else", this.state.type)
                                if (this.state.type && this.state.type.id === "standard") {
                                    this.props.history.push('/v2/reports/new/standard/' + campaign.data.id);
                                } else {
                                    this.props.history.push('/v2/reports/new/advanced/' + campaign.data.id + "?client=" + this.state.client.id);
                                }
                            }
                        }
                    }, (error) => {
                        console.log("Error: ", error)
                        this.setState({
                            loading_report: false
                        })
                    });
                }
            });

        },
        getAllTemplates: async () => {
            try {
                if (this.state.templates.length === 0) {
                    let response = await this.calls.getAllTemplates()
                    console.log(response)
                    if (response.data && response.data.length > 0) {
                        this.setState({
                            templates: response.data
                        })
                    }
                } else {
                    console.log("Something went wrong")
                }
            } catch (error) {
                console.log(error)
            }
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.query();
        },
    };

    calls = {
        getAllTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?status=template"
            return apiRegister.call(options, url);
        },
    }

    renders = {
        stepValid: (step) => {
            if (step === "campaigns") {
                return this.state.client && this.state.client.id
            } else if (step === "type") {
                return this.state.client.id && this.state.selected_external && Object.keys(this.state.selected_external).length > 0
            } else {
                return false
            }
        },
        valid: () => {
            return this.state.client && this.state.client.id && this.state.selected_external && Object.keys(this.state.selected_external).length > 0 && ((this.state.type && this.state.type.id && !this.state.template) || this.state.template)
        },
        daysToEndDate: (date) => {
            var now = moment(new Date());
            var end = moment(date);
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            return days;
        },
    };

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                {
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    <div className={"col-span-12 px-6"}>
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <CogIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Select action</div>
                                                {
                                                    this.state.type.value &&
                                                    <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.type.value === "advanced" && this.state.type.name}
                                                        {this.state.type.value === "template" && this.state.type.name}{Object.keys(this.state.selected_template).length > 0 && this.state.type.value === "template" && `, ${this.state.selected_template.name}`}</div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.type.value &&
                                                    <div className="font-medium text-xs text-gray-500">Select report type</div>
                                                }
                                            </div>
                                            <div className='flex'>
                                                {
                                                    (this.state.type.value === "advanced" || (this.state.type.value === "template" && Object.keys(this.state.selected_template).length > 0)) &&
                                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <CheckIcon className="w-5 h-5 " />
                                                    </div>
                                                }
                                                <div>
                                                    <div onClick={() => {
                                                        if (!this.props.report) {
                                                            this.state.open.type = !this.state.open.type;
                                                            if (this.state.open.type) {
                                                                this.state.open.client = false;
                                                                this.state.open.campaigns = false;
                                                            }
                                                            this.setState({
                                                                open: this.state.open
                                                            });
                                                        }
                                                    }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.type ? "opacity-100" : "opacity-50"}`}>
                                                        {
                                                            !this.props.report &&
                                                            !this.state.open.type &&
                                                            <ChevronDownIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            !this.props.report &&
                                                            this.state.open.type &&
                                                            <ChevronUpIcon className="w-5 h-5 " />
                                                        }
                                                        {
                                                            this.props.report &&
                                                            <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* SELECT ACTION */}

                                        <SlideDown
                                            closed={!this.state.open.type}
                                        >
                                            <div className="mt-4 grid-cols-12 grid gap-4">
                                                {
                                                    [
                                                        { name: `New ${this.state.report_type === "advanced" ? "professional" : "standard"} report`, value: "advanced", description: this.state.report_type === "advanced" ? "Create a highly customizable report featuring multiple draggable options to display your data" : "Create a quick report using campaigns, adgroups and ads to display your data" },
                                                        { name: "From template", value: "template", description: "Use one of our premade templates or one of your previously saved templates to display your data" }
                                                    ].map((item, index) => {
                                                        return (
                                                            <div onClick={() => {
                                                                if (!this.props.report || this.props.report === item.value) {
                                                                    if (item.value === "advanced") {
                                                                        this.state.open.type = false;
                                                                        this.state.open.client = true;
                                                                        this.state.open.campaigns = false;
                                                                    }
                                                                    item.value === "template" && this.functions.getAllTemplates();
                                                                    console.log(item)
                                                                    this.setState({
                                                                        type: item,
                                                                        open: this.state.open,
                                                                    });
                                                                }
                                                            }} className={(!this.props.fixedType || this.props.fixedType === item.value ? "cursor-pointer" : "opacity-25 cursor-not-allowed") + " " + (this.state.type.value === item.value ? "bg-purple-100 border-purple-500" : "border-gray-300 bg-custom-input") + " flex flex-col col-span-6 relative p-4 rounded-md border-1.5 "}>
                                                                <div className={(this.state.type.value === item.value ? "text-purple-500" : "") + " text-sm font-semibold mb-1"}>
                                                                    {item.name}
                                                                </div>
                                                                <div className={(this.state.type.value === item.value ? "text-gray-700" : "text-gray-500") + " text-sm font-medium "}>
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {/* SELECT TEMPLATE */}

                                                {this.state.type.value === "template" &&
                                                    <>
                                                        <div className={"col-span-12"}>
                                                            <div className="flex flex-row">
                                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                    <ClipboardListIcon className="w-5 h-5 " />
                                                                </div>
                                                                <div className="flex flex-1 flex-col justify-center ml-3">
                                                                    <div
                                                                        className="font-semibold text-sm">Select template</div>
                                                                    {
                                                                        this.state.selected_template.id &&
                                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                            You have selected <div className="text-purple-500 ml-1">{this.state.selected_template.id ? this.state.selected_template.name : ""}
                                                                            </div></div>
                                                                    }
                                                                    {
                                                                        !this.state.selected_template.id &&
                                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">No template selected yet</div>

                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-row mt-4">
                                                                <div className="flex flex-1">
                                                                    <div className="w-full">
                                                                        <div className="flex flex-1 flex-col justify-center">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.search['templates'] = event.target.value;
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    });
                                                                                }}
                                                                                placeholder={"Search ..."}
                                                                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-12 space-y-4">
                                                            {
                                                                this.state.templates.filter((item) => {
                                                                    if (this.state.search['templates']) {
                                                                        let flat_nested_values = item.name;
                                                                        return flat_nested_values.toLowerCase().indexOf(this.state.search['templates'].toLowerCase()) !== -1
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }).map(item => {
                                                                    return (
                                                                        <div className="flex flex-row cursor-pointer items-center">
                                                                            <div
                                                                                onClick={() => {
                                                                                    this.state.open.type = false;
                                                                                    this.state.open.client = true;
                                                                                    this.state.open.campaigns = false;
                                                                                    console.log("selectedtemplate", item)
                                                                                    this.setState({
                                                                                        selected_template: item,
                                                                                        template: item,
                                                                                        open: this.state.open
                                                                                    })
                                                                                }}
                                                                                className={
                                                                                    `h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.selected_template.id === item.id ?
                                                                                        "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100"}`}>
                                                                                {
                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                }
                                                                            </div>

                                                                            <div className="flex flex-1 flex-col justify-center">
                                                                                <div
                                                                                    className="font-semibold text-sm">{item.name}</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    <div className="col-span-12 flex items-center justify-center">
                                                        <div onClick={async () => {
                                                            if (this.state.type.value) {
                                                                this.state.open.type = false;
                                                                this.state.open.client = true;
                                                                this.state.open.campaigns = false;
                                                                this.setState({
                                                                    open: this.state.open
                                                                });
                                                            }
                                                            console.log(this.state)
                                                        }}
                                                            className={(this.state.type.value === "advanced" || this.state.selected_template && this.state.selected_template.id ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                            <span style={{ whiteSpace: "nowrap" }}
                                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                Next step
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </SlideDown>
                                    </div>
                                    {
                                        this.state.type.value &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }

                                    {/* SELECT CLIENTS */}

                                    {
                                        this.state.type.value &&
                                        <div className={"col-span-12 px-6"}>
                                            <div className="flex flex-row">
                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <IdentificationIcon className="w-5 h-5" />
                                                </div>
                                                <div className="flex flex-1 flex-col justify-center ml-3">
                                                    <div className="font-semibold text-sm">Select client</div>
                                                    {
                                                        Object.keys(this.state.client).length > 0 &&
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.client && this.state.client.name}</div></div>
                                                    }
                                                    {
                                                        Object.keys(this.state.client).length === 0 &&
                                                        <div className="font-medium text-xs text-gray-500">No client selected yet</div>
                                                    }
                                                </div>
                                                <div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.client && this.state.client.name &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            if (!this.props.report) {
                                                                this.state.open.client = !this.state.open.client;
                                                                if (this.state.open.client) {
                                                                    this.state.open.type = false;
                                                                    this.state.open.campaigns = false;
                                                                }
                                                                this.setState({
                                                                    open: this.state.open
                                                                });
                                                            }
                                                        }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.client ? "opacity-100" : "opacity-50"}`}>
                                                            {
                                                                !this.props.report &&
                                                                !this.state.open.client &&
                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                !this.props.report &&
                                                                this.state.open.client &&
                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                this.props.report &&
                                                                <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown
                                                closed={!this.state.open.client}
                                            >
                                                <div className={"col-span-12 mt-4"}>
                                                    <div className="flex flex-row">
                                                        <div className="flex flex-1">
                                                            <div className="w-full">
                                                                <div className="flex flex-1 flex-col justify-center">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.search['clients'] = event.target.value;
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            });
                                                                        }}
                                                                        placeholder={"Search ..."}
                                                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-12">
                                                    <div className="flex flex-row cursor-pointer space-y-2 items-center">
                                                        <div className="flex flex-col">
                                                            {
                                                                this.state.clients.filter((item) => {
                                                                    if (this.state.search['clients']) {
                                                                        let flat_nested_values = item.name;
                                                                        return flat_nested_values.toLowerCase().indexOf(this.state.search['clients'].toLowerCase()) !== -1
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }).map((item, i) => {
                                                                    return (
                                                                        <div key={i}
                                                                            className="flex flex-row items-center mt-4">
                                                                            <div
                                                                                onClick={() => {
                                                                                    console.log("selected", this.state.selected, item)
                                                                                    if (!this.state.loading_externals && !this.state.loading_internals) {
                                                                                        this.state.open.client = !this.state.open.client;
                                                                                        this.state.open.campaigns = true;
                                                                                        this.setState({
                                                                                            client: item,
                                                                                            open: this.state.open,
                                                                                            external_campaigns: {},
                                                                                            selected: item,
                                                                                        }, () => {
                                                                                            this.functions.campaigns();
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                className={
                                                                                    ` h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.selected && this.state.selected.id === item.id ?
                                                                                        "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100"
                                                                                    }`}
                                                                            >
                                                                                {
                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                }
                                                                            </div>
                                                                            <div className="cursor-pointer flex flex-1 flex-row justify-center items-center">
                                                                                <div
                                                                                    className="text-purple-500 p-1 overflow-hidden bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                    <img className="w-full" src={item.logo} />
                                                                                </div>
                                                                                <div className="flex flex-1 flex-col truncate">
                                                                                    <div className="font-semibold text-sm truncate">
                                                                                        {item.name}
                                                                                    </div>
                                                                                    <div className="text-xs font-medium text-gray-500">
                                                                                        {item.company}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    <div className="col-span-12 flex items-center justify-center mt-4">
                                                        <div onClick={async () => {
                                                            if (this.state.type.value) {
                                                                this.state.open.client = false;
                                                                this.state.open.campaigns = true;
                                                                this.setState({
                                                                    open: this.state.open
                                                                });
                                                            }
                                                        }}
                                                            className={(this.state.client.id ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                            <span style={{ whiteSpace: "nowrap" }}
                                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                Next step
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        this.state.client.id &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }

                                    {/* SELECT CAMPAIGNS */}

                                    {
                                        this.state.client.id &&
                                        <div className={"col-span-12 px-6"}>
                                            <div className="flex flex-row">
                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <FolderIcon className="w-5 h-5 " />
                                                </div>
                                                <div className="flex flex-1 flex-col justify-center ml-3">
                                                    <div className="font-semibold text-sm">Select campaigns</div>
                                                    {
                                                        Object.keys(this.state.selected_external).length > 0 &&
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 mx-1">{this.state.selected_campaigns.length}</div> campaigns</div>
                                                    }
                                                    {
                                                        (this.state.loading_externals || this.state.loading_internals) &&
                                                        <p className="font-medium text-xs text-gray-500">
                                                            Loading ...
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.loading_externals && Object.keys(this.state.selected_external).length === 0 &&
                                                        <div className="font-medium text-xs text-gray-500">No campaign selected yet</div>
                                                    }
                                                </div>
                                                <div>
                                                    <div className='flex'>
                                                        {
                                                            Object.keys(this.state.selected_external).length > 0 &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            if (!this.props.report) {
                                                                this.state.open.campaigns = !this.state.open.campaigns;
                                                                if (this.state.open.campaigns) {
                                                                    this.state.open.client = false;
                                                                    this.state.open.type = false;
                                                                }
                                                                this.setState({
                                                                    open: this.state.open
                                                                });
                                                            }
                                                        }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.campaigns ? "opacity-100" : "opacity-50"}`}>
                                                            {
                                                                !this.props.report &&
                                                                !this.state.open.campaigns &&
                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                !this.props.report &&
                                                                this.state.open.campaigns &&
                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                this.props.report &&
                                                                <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown
                                                closed={!this.state.open.campaigns}
                                            >
                                                {
                                                    (this.renders.stepValid('campaigns')) &&
                                                    <div className={(this.renders.stepValid('campaigns') ? "" : "opacity-50 ") + ""}>
                                                        <SlideDown closed={!this.state.open.campaigns || this.state.loading_externals || this.state.loading_internals}>
                                                            {
                                                                !this.state.loading_externals && !this.state.loading_internals &&
                                                                <div className='mt-4'>
                                                                    {
                                                                        Object.keys(this.state.external_campaigns).sort().filter((channel) => {
                                                                            return this.state.external_campaigns[channel].length > 0 && channel !== "internal"
                                                                        }).map((channel) => {
                                                                            return (
                                                                                <div className="mt-4">
                                                                                    <div className="">
                                                                                        <div className="flex flex-1 flex-row justify-center items-center">
                                                                                            <div className="flex flex-row flex-1 items-center align-middle">
                                                                                                {
                                                                                                    channel === "internal" &&
                                                                                                    <div
                                                                                                        className="bg-purple-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-5"
                                                                                                            src={require('../../assets/images/Adcredo Logotype Symbol (White).png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "bidtheatre" &&
                                                                                                    <div
                                                                                                        className="bg-bidtheatre-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-4"
                                                                                                            src={require('../../assets/images/bidtheatre_icon.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "google" &&
                                                                                                    <div
                                                                                                        className="bg-google-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/google_icon.svg')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "linkedin" &&
                                                                                                    <div
                                                                                                        className="bg-linkedin-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/linkedin_icon.svg')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "facebook" &&
                                                                                                    <div
                                                                                                        className="bg-facebook-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img style={{ width: '0.4rem' }}
                                                                                                            src={require('../../assets/images/facebook_icon.svg')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "google_analytics" &&
                                                                                                    <div
                                                                                                        className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/google_analytics_icon.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "google_analytics_4" &&
                                                                                                    <div
                                                                                                        className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/ga_icon.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "adform" &&
                                                                                                    <div
                                                                                                        className="bg-adform-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/adform_icon.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "twitter" &&
                                                                                                    <div
                                                                                                        className="bg-twitter-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/twitter_icon.svg')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "tiktok" &&
                                                                                                    <div
                                                                                                        className="bg-tiktok-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/tiktok_icon.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "snapchat" &&
                                                                                                    <div
                                                                                                        className="bg-snapchat-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/snapchat.svg')} />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    channel === "bing" &&
                                                                                                    <div
                                                                                                        className="bg-bing-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                                        <img className="w-3"
                                                                                                            src={require('../../assets/images/bing-logo.png')} />
                                                                                                    </div>
                                                                                                }
                                                                                                <div style={{ marginTop: "-6px" }} className="flex flex-col justify-center">
                                                                                                    <h3 className={"mb-0 font-semibold"}>
                                                                                                        <span className="capitalize text-sm">{channel ? (channel.replace("_", " ").replace("_", " ")) : "..."}</span>
                                                                                                    </h3>
                                                                                                    {
                                                                                                        !this.state.selected_external[channel] &&
                                                                                                        <p className="text-xs font-medium flex items-center text-gray-500 leading-tight">
                                                                                                            There are<span
                                                                                                                className="ml-1 mr-1 text-purple-500">{this.state.external_campaigns[channel].length}</span> campaigns
                                                                                                            available
                                                                                                        </p>
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.selected_external[channel] &&
                                                                                                        <p className="text-xs flex items-center text-gray-500 leading-tight">
                                                                                                            <span>You have selected <span className=" text-purple-500">{Object.keys(this.state.selected_external[channel]).length}</span></span>
                                                                                                        </p>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                this.state.external_campaigns[channel].length > 0 &&
                                                                                                <div className="flex flex-row">
                                                                                                    <div onClick={() => {
                                                                                                        console.log(channel, this.state.open[channel])
                                                                                                        this.state.open[channel] = !this.state.open[channel];
                                                                                                        this.setState({
                                                                                                            open: this.state.open
                                                                                                        })
                                                                                                    }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open[channel] ? "opacity-100" : "opacity-50"}`}>
                                                                                                        {
                                                                                                            !this.state.open[channel] &&
                                                                                                            <ChevronDownIcon className="w-5 h-5 " />
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.open[channel] &&
                                                                                                            <ChevronUpIcon className="w-5 h-5 " />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <SlideDown closed={!this.state.open[channel]}>
                                                                                            {
                                                                                                <div className="w-full h-4" />
                                                                                            }
                                                                                            {
                                                                                                <div className="flex flex-1 flex-col justify-center mb-4">
                                                                                                    <input
                                                                                                        onChange={(event) => {
                                                                                                            console.log(channel, event.target.value)
                                                                                                            this.state.search[channel] = event.target.value;
                                                                                                            this.setState({
                                                                                                                search: this.state.search
                                                                                                            });
                                                                                                        }}
                                                                                                        placeholder={"Search ..."}
                                                                                                        value={this.state.search[channel] ? this.state.search[channel] : ""}
                                                                                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                this.state.external_campaigns[channel].sort((a, b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0)).filter((item) => {
                                                                                                    if (this.state.search[channel]) {
                                                                                                        let flat_nested_values = item.name;
                                                                                                        return flat_nested_values.toLowerCase().indexOf(this.state.search[channel].toLowerCase()) !== -1
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).map((item, i) => {
                                                                                                    return (
                                                                                                        <Fragment key={i}>
                                                                                                            <div className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " flex flex-1 flex-row justify-center mb-4"}>
                                                                                                                {
                                                                                                                    <div onClick={() => {
                                                                                                                        console.log("clicking campaign here", item)

                                                                                                                        if (!item.disabled) {
                                                                                                                            if (!this.state.selected_external[channel]) {
                                                                                                                                this.state.selected_external[channel] = {};
                                                                                                                                this.state.selected_external[channel][item.id] = item;
                                                                                                                            } else if (!this.state.selected_external[channel][item.id]) {
                                                                                                                                this.state.selected_external[channel][item.id] = item;
                                                                                                                            } else {
                                                                                                                                delete this.state.selected_external[channel][item.id];
                                                                                                                                if (Object.keys(this.state.selected_external[channel]).length < 1) {
                                                                                                                                    delete this.state.selected_external[channel];
                                                                                                                                }
                                                                                                                            }

                                                                                                                            // TO TRACK TOTAL SELECTED CAMPAIGNS OUTSIDE OF THIS COMPONENT
                                                                                                                            if (this.state.selected_campaigns.filter((selected) => { return selected.id === item.id }).length > 0) {
                                                                                                                                this.state.selected_campaigns = this.state.selected_campaigns.filter((selected) => { return selected.id !== item.id })
                                                                                                                            } else {
                                                                                                                                this.state.selected_campaigns.push(item)
                                                                                                                            }

                                                                                                                            this.setState({
                                                                                                                                selected_external: this.state.selected_external,
                                                                                                                            })

                                                                                                                            console.log("selected camps", this.state.selected_campaigns)
                                                                                                                        }
                                                                                                                    }}
                                                                                                                        className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + ((this.state.selected_external && this.state.selected_external[channel] && this.state.selected_external[channel][item.id]) ? " border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                                        {
                                                                                                                            this.state.selected_external && this.state.selected_external[channel] && this.state.selected_external[channel][item.id] &&
                                                                                                                            <FeatherIcon
                                                                                                                                className={'stroke-current'}
                                                                                                                                size={14}
                                                                                                                                icon="check" />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <div
                                                                                                                    className="flex flex-1 flex-col truncate">
                                                                                                                    <div className="text-sm font-semibold truncate">
                                                                                                                        {item.name}
                                                                                                                    </div>
                                                                                                                    <div className="text-xxs text-gray-500">
                                                                                                                        {item.id} | {
                                                                                                                            item.status &&
                                                                                                                            <span className={cx("uppercase",
                                                                                                                                {
                                                                                                                                    ["text-green-500"]: item.status.toUpperCase() === "ACTIVE" || item.status === "ENABLED" || item.status === "ENABLE",
                                                                                                                                    ["text-purple-500"]: item.status.toUpperCase() === "ENDED",
                                                                                                                                    ["text-orange-500 bg-opacity-50"]: item.status.toUpperCase() === "PAUSED" || item.status === "DISABLE",
                                                                                                                                    ["text-red-600"]: item.status.toUpperCase() === "error",
                                                                                                                                })
                                                                                                                            }>{item.status}</span>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            item.disabled &&
                                                                                                                            <span className="ml-2 uppercase">Enddate out of scope</span>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </SlideDown>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </SlideDown>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        Object.keys(this.state.selected_external).length > 0 &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }
                                    {
                                        Object.keys(this.state.selected_external).length > 0 &&
                                        <div className={"col-span-12 px-6"}>
                                            <div className={"w-full"}>
                                                <InputTailwind
                                                    locked={false}
                                                    label={"Report name (required)"}
                                                    placeholder={"Enter report name"}
                                                    value={this.state.name}
                                                    error={this.state.name_error}
                                                    onChange={(value) => {
                                                        if (value.length > 0) {
                                                            this.props.onNameChange(false)
                                                            this.state.name_error = false;
                                                        } else {
                                                            this.props.onNameChange(true)
                                                            this.state.name_error = true;
                                                        }
                                                        this.setState({
                                                            name: value,
                                                            name_error: this.state.name_error
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='h-10 w-15 bg-gray-200'
                                                onClick={() => { console.log(this.state) }}
                                            >
                                                state
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }

}

export default CreateNewReport;
