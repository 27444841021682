import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, BeakerIcon, StopIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrderABTest from '../moduleFiles/createOrderABTest';
import SuccessModal from '../moduleFiles/successModal';
import moment from 'moment';
import { random } from '@turf/turf';
import SyncWithBing from '../moduleFiles/syncWithBing';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import AbTest from '../moduleFiles/AbTest';


class OrderABTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_abtest: {},
            wizzard: "",
            edit_loader: false,
            loading: true,
            order: {},
            client: {},
            campaigns: [],
            campaign: { channel: 'all' },
            adgroups: [],
            adgroup: {},
            ad: {},
            ads: [],
            keywords: [],
            negative_keywords: [],
            search_terms: [],
            channels: [],
            channel: { id: "all", name: "All channels", value: "all" },
            syncToBing: false,
            importAlertShow: false,
            status: {},
            search_term: {},
            edit_negative: {},
            edit_positive: {},
            keyword: {},
            selected_adgroup: { id: 0, name: "Select adgroup" },
            create_wizard: false,
            create_keyword: false,
            edit_keyword: false,
            add_keyword: false,
            locked_adgroup: false,
            selected_adgroup_error: false,
            pagination: "1",
            negative_pagination: "1",
            sortBy: 'name',
            orderBy: 'DESC',
            pageSize: 50,
            loading_wizard: false,
            open: false,
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'start', value: 'startDate', min_width: '250px' },
                { name: 'end', value: 'endDate', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channels', value: 'channels', min_width: '250px' }
            ],
            abtests: [],
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getABtests();
        this.functions.getMetrics();
        await this.functions.getCampaigns();
        //this.props.updateStatistics();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getABtests: async () => {
            return new Promise(async (resolve) => {
                try {

                    let response = await this.calls.getABtests();
                    this.promisedSetState({
                        order: response.data.order ? response.data.order : {},
                        client: response.data.client ? response.data.client : {},
                        abtests: response.data.abtests ? response.data.abtests : [],
                        loading: false
                    });
                    if (this.props.updateStatistics) {
                        this.props.updateStatistics();
                    }
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                }
                resolve();
            });
        },
        stopABtests: async (item) => {
            try {
                let body = {
                    order_id: this.state.order.id,
                    id: item.id,
                    startDate: item.startDate,
                    endDate: moment().format("YYYY-MM-DD"),
                    metric: item.metric,
                    name: item.name,
                    campaigns: item.campaigns,
                    adgroups: item.adgroups,
                }
                let response = await this.calls.updateAbTest(body);
                this.state.abtests = this.state.abtests.map((data) => { if (data.id === item.id) { data.endDate = moment().format("YYYY-MM-DD") } return data });
                this.promisedSetState({
                    abtests: this.state.abtests
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading: false
                })
            }
        },
        deleteAbTest: async (data) => {
            try {

                this.state.abtests.map((item) => { if (data.id === item.id) { item.loading = true } return item });
                await this.promisedSetState({ abtests: this.state.abtests });
                try {
                    console.log("DATA", data)
                    let response = await this.calls.deleteAbTest({ id: data.id });
                    this.state.abtests.filter((item) => { return data.id !== item.id });
                    await this.promisedSetState({ abtests: this.state.abtests });
                } catch (error) { }

                this.state.abtests.map((item) => { if (data.id === item.id) { item.loading = false } return item });
                await this.promisedSetState({ abtests: this.state.abtests });
                if (this.props.setOrder) {
                    this.props.setOrder(this.state.order);
                }
                this.functions.getABtests();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading: false
                })
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        getMetrics: () => {
            this.setState({
                loading_metrics: true
            }, () => {
                this.calls.getMetrics().then((response) => {
                    this.setState({
                        metrics: { calculated: response.data.calculated.filter((item) => { return item.channels && item.calculation_options.length > 0 }), custom: response.data.custom.filter((item) => { return item.channels }) },
                        loading_metrics: false,
                    });
                }, (error) => {
                    this.setState({
                        loading_metrics: false,
                    })
                });
            })
        },
        getCampaigns: async () => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading: true,
                    });
                    let response = await this.calls.getCampaigns();
                    let channels = [{ id: "all", name: "All channels", value: "all" }];
                    response.data.campaigns.map((item) => {
                        if (channels.filter((channel) => { return channel.value === item.channel }).length < 1) {
                            channels.push({ id: item.channel, name: item.channel.charAt(0).toUpperCase() + item.channel.slice(1), value: item.channel });
                        }
                    });
                    await this.promisedSetState({
                        campaigns: response.data.campaigns,
                        channels: channels,
                        loading: false
                    });

                    this.functions.getCampaignsData();
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                }
                resolve();
            })
        },
        getCampaignsData: async (campaign) => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading_metrics: true
                    });
                    let campaigns_string = "";
                    if (!campaign) {
                        this.state.campaigns.map((campaign) => {
                            campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                        });
                    } else {
                        campaigns_string = ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                    }
                    let statistics = campaign ? false : true;

                    let response = await this.calls.getCampaignsData(campaigns_string, statistics);
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        if (response.data[campaign.channel]) {
                            response.data[campaign.channel].map((item) => {
                                if (campaign.id === item.id) {
                                    campaign = { ...campaign, ...item };
                                }
                            })
                        }
                        return campaign;
                    });
                    this.promisedSetState({
                        campaigns: this.state.campaigns,
                        loading_metrics: false
                    })
                    console.log(this.state.campaigns, "CAMPAIGNS")
                } catch (error) {
                    console.log(error, "ERROR");
                    this.promisedSetState({
                        error: true,
                        loading_metrics: false
                    })
                }
                resolve();
            })
        },
        getAdgroups: async (campaign) => {
            let self = this;
            return new Promise(function (resolve, reject) {
                let campaigns_string = "";
                campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.id + "," + self.state.client.id);

                self.calls.getAdgroups(campaigns_string).then((response) => {
                    let data = { channels: {} };
                    for (let channel in response.data) {
                        data.channels[channel] = {
                            adgroups: response.data[channel]
                        };
                    }
                    self.setState({
                        adgroups: self.state.adgroups.concat(Object.keys(data.channels).map((channel) => {
                            return data.channels[channel].adgroups.filter((adgroup) => {
                                return adgroup.status !== "REMOVED" && self.state.adgroups.filter((item) => { return item.id === adgroup.id }).length < 1;
                            }).map((item) => {
                                item.campaign = item.campaign_id;
                                return item;
                            });
                        }).flat()),
                    }, () => {
                        resolve();
                    });
                }, (error) => {
                    resolve();
                })
            })
        },
        getAdgroupsData: async (adgroup) => {
            try {
                this.promisedSetState({
                    loading_metrics: true
                });
                let adgroups_string = "";
                if (!adgroup) {
                    this.state.adgroups.map((adgroup) => {
                        adgroups_string = adgroups_string + ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                    });
                } else {
                    adgroups_string = ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                }
                let statistics = adgroup ? false : true;

                let response = await this.calls.getAdgroupsData(adgroups_string, statistics);
                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                    if (response.data[adgroup.channel]) {
                        response.data[adgroup.channel].map((item) => {
                            if (adgroup.id == item.id) {
                                adgroup = { ...adgroup, ...item };
                            }
                        })
                    }
                    return adgroup;
                });
                this.promisedSetState({
                    adgroups: this.state.adgroups,
                    loading_metrics: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_metrics: false
                })
            }
        },
    }




    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        getABtests: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderABTests?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateAbTest: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAbTest?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        deleteAbTest: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/deleteOrderABTest?id=" + this.functions.getIdFromPath() + "&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderCampaigns?id=" + this.functions.getIdFromPath() + "&information=true";
            return apiRegister.call(options, url);
        },
        getCampaignsData: (campaigns, statistics) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?" + "statistics=false&client=" + this.state.client.id + campaigns + "&information=true";
            return apiRegister.call(options, url);
        },
        getMetrics: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/metrics?custom=true";
            return apiRegister.call(options, url);
        },
        getAdgroups: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "client=" + this.state.client.id + "&information=true" + campaigns;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        sort: (list) => {

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : 0) : (x[this.state.sortBy] ? x[this.state.sortBy] : 0);
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : 0) : (y[this.state.sortBy] ? y[this.state.sortBy] : 0);
                    if (this.state.orderBy == 'DESC') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : "") : (x[this.state.sortBy] ? x[this.state.sortBy] : "");
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : "") : (y[this.state.sortBy] ? y[this.state.sortBy] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (this.state.orderBy == 'ASC') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : new Date()) : (x[this.state.sortBy] ? x[this.state.sortBy] : new Date());
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : new Date()) : (y[this.state.sortBy] ? y[this.state.sortBy] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (this.state.orderBy == 'ASC') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (this.state.sortBy === "name" || this.state.sortBy === "channel" || this.state.sortBy === "status") {
                return sortCharacter(list);
            } else if (this.state.sortBy === "startDate" || this.state.sortBy === "endDate") {
                return sortDate(list);
            } else {
                return sortNumber(list);
            }



        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {
                            if (type === "new") {
                                this.promisedSetState({
                                    open: true
                                })
                            }
                        }}
                        showDateSpan={false}
                        hideUserDropdown={true}
                        breadcrumb={"A/B test"}
                        history={this.props.history}
                        buttons={[
                            { text: "New A/B test", icon: BeakerIcon, value: "new", loading: this.state.loading_wizard, show: !this.state.loading && this.state.order.status !== "archived" }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>






                {/* SUCCESS MESSAGE */}
                <SuccessModal
                    open={this.state.success ? true : false}
                    title={"Created"}
                    text={"Click OK to continue"}
                    button={"Ok"}
                    showInput={false}
                    onClose={(value) => {
                        this.setState({
                            success: null
                        })
                    }}
                />



                {/* SLIDEOUT UPDATE */}
                {
                    <SlideoutTailwind
                        title={"A/B Test"}
                        full={true}
                        noSubmit={true}
                        disabled={this.state.disabled}
                        submitButtonText={"Create"}
                        open={this.state.selected_abtest && this.state.selected_abtest.id ? true : false}
                        onCancel={() => {
                            this.promisedSetState({
                                selected_abtest: {}
                            });
                        }}
                    >
                        {
                            this.state.selected_abtest && this.state.selected_abtest.id &&
                            <AbTest
                                ref="AbTest"
                                client={this.state.client}
                                order={this.state.order}
                                abtest={this.state.selected_abtest}
                            />
                        }

                    </SlideoutTailwind>
                }
                {/** Slidein */}
                {
                    <CreateOrderABTest
                        ref="child"
                        closed={!this.state.open}
                        toggle={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }}
                        close={() => {
                            this.setState({
                                slideinclosed: true
                            })
                        }}
                        order={this.state.order}
                        client={this.state.client}
                        metrics={this.state.metrics}
                        campaigns={this.state.campaigns}
                        adgroups={this.state.adgroups}
                        getAdgroups={(campaign) => {
                            this.functions.getAdgroups({ channel: campaign.channel, id: campaign.id });
                        }}
                        create={() => {
                            this.functions.getABtests();
                        }}

                    />
                }







                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO WIZARDS*/}
                            {
                                this.state.abtests.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No A/B Test available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.abtests.length > 0 &&
                                <div className="relative overflow-scroll h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                await this.promisedSetState({
                                                                    sortBy: item.value,
                                                                    orderBy: this.state.orderBy === 'ASC' ? 'DESC' : 'ASC'
                                                                });
                                                                //this.functions.wizards();
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(this.state.sort === item.value ? "bg-gray-100 bg-opacity-75" : "hover:bg-gray-100 hover:bg-opacity-75") + " px-6 py-3 border-gray-300 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.orderBy === 'ASC' && this.state.sortBy === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.orderBy === 'DESC' && this.state.sortBy === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                Array.isArray(this.state.abtests) &&
                                                this.renders.sort(this.state.abtests).map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300 relative"}>
                                                                    <div className="mt-2 cursor-pointer bg-red-500 items-center justify-center hover:bg-red-600 text-white whitespace-nowrap py-3 px-2.5 border-b-2 font-medium text-sm border-t-1.5 border-r-1.5 border-l-1.5 rounded-md "
                                                                        onClick={async () => {
                                                                            this.functions.deleteAbTest(item);
                                                                        }}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5" />
                                                                    </div>

                                                                    {
                                                                        moment().isAfter(moment(item.startDate)) && moment().isBefore(moment(item.endDate)) &&
                                                                        <div className="ml-1 mt-2 cursor-pointer bg-gray-300 items-center justify-center hover:bg-red-500 text-gray-800 hover:text-white whitespace-nowrap py-3 px-2.5 border-b-2 font-medium text-sm border-t-1.5 border-r-1.5 border-l-1.5 rounded-md "
                                                                            onClick={async () => {
                                                                                this.functions.stopABtests(item);
                                                                            }}
                                                                        >
                                                                            <StopIcon className="h-5 w-5" />
                                                                        </div>
                                                                    }

                                                                </td>
                                                                <td onClick={async () => {
                                                                    await this.promisedSetState({
                                                                        selected_abtest: item
                                                                    });

                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'start' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.startDate ? moment(item.startDate).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'end' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.endDate ? moment(item.endDate).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: moment().isAfter(moment(item.startDate)) && moment().isBefore(moment(item.endDate)),
                                                                                ["bg-purple-100 text-purple-500"]: moment().isBefore(moment(item.startDate)),
                                                                                ["bg-gray-100 text-gray-500"]: moment().isAfter(moment(item.endDate)),
                                                                            })
                                                                        }>
                                                                        {moment().isAfter(moment(item.startDate)) && moment().isBefore(moment(item.endDate)) ? "active" : (moment().isBefore(moment(item.startDate)) ? "upcoming" : "ended")}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>

                                                                    <div className="flex flex-row pl-2">
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "adform" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "adform" }).length > 0)) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "facebook" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "facebook" }).length > 0)) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "linkedin" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "linkedin" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "google" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "google" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "tiktok" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "tiktok" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "twitter" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "twitter" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "snapchat" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "snapchat" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            ((Array.isArray(item.campaigns) && item.campaigns.filter((item) => { return item.channel === "bing" }).length > 0) ||
                                                                                (Array.isArray(item.adgroups) && item.adgroups.filter((item) => { return item.channel === "bing" }).length > 0)) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                    </div>
                }


            </div >
        )
    }

}

export default OrderABTest;