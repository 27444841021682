import { htmlToPptxText } from 'html2pptxgenjs';

const shadowOpts = { type: 'outer', color: '696969', blur: 10, offset: 0, angle: 90, opacity: 0.3 };

export const firstPresentationSlide = async (params) => {
    const { slide, initSizes, logoUrl, title } = params;
    const { documentWidth, documentHeight } = initSizes;

    // function that add logo to slide
    const addLogoToFirstSlide = async (logoSrc) => {
        const result = await _getWidthAndHeight(logoSrc);
        const { width, height } = result;

        slide.addImage({
            path: logoSrc,
            x: documentWidth / 4,
            y: 2,
            w: documentWidth / 2,
            h: (documentWidth / 2) * (height / width),
        });
    };

    // check value
    logoUrl !== "" && (await addLogoToFirstSlide(logoUrl));

    // formating text to firstPresentationSlide
    let arrTitleObjs = [
        {
            text: title && title,
            options: { fontSize: 24, bold: true, color: '#A1A1AA', breakLine: true },
        },
    ];

    // add text block to firstPresentationSlide
    title &&
        slide.addText([...arrTitleObjs], {
            x: documentWidth / 4,
            y: 3,
            w: documentWidth / 2,
            h: documentHeight / 2,
            align: 'center',
            valign: 'middle',
            fontFace: 'Poppins',
            margin: 10,
        });

    return slide;
};

export const secondPresentationSlide = (params) => {
    const { slide, initSizes, summary } = params;
    const { documentWidth, documentHeight } = initSizes;

    // formating text to firstPresentationSlide
    let arrSummaryObjs = [
        {
            text: summary && summary,
            options: { fontSize: 48, bold: true, color: '#A1A1AA', breakLine: true },
        },
    ];

    // add text block to firstPresentationSlide
    summary &&
        slide.addText([...arrSummaryObjs], {
            x: documentWidth / 4,
            y: documentHeight / 4,
            w: documentWidth / 2,
            h: documentHeight / 2,
            align: 'center',
            valign: 'middle',
            fontFace: 'Poppins',
            margin: 10,
        });
};

export const thirdPresentationSlide = (params) => {
    const { slide, initSizes, summary } = params;
    const { documentWidth, documentHeight } = initSizes;

    // formating text to firstPresentationSlide
    let arrSummaryObjs = [
        {
            text: summary && summary,
            options: { fontSize: 22, bold: false, color: '#A1A1AA', breakLine: true },
        },
    ];

    // add text block to firstPresentationSlide
    summary &&
        slide.addText([...arrSummaryObjs], {
            x: documentWidth / 4,
            y: documentHeight / 4,
            w: documentWidth / 2,
            h: documentHeight / 2,
            align: 'center',
            valign: 'middle',
            fontFace: 'Poppins',
            margin: 10,
        });
};

export const setTabNameBeforeContent = (params) => {
    let { slide, initSizes, tabName } = params;
    const { documentWidth, documentHeight } = initSizes;

    // formating text to firstPresentationSlide
    let arrTextObjs = [
        {
            text: tabName && tabName ? tabName : 'error with tabName',
            options: { fontSize: 42, bold: true, color: '#A1A1AA', breakLine: true, underline: true }
        },
    ];

    slide.addText([...arrTextObjs], {
        x: 0,
        y: documentHeight / 4,
        w: documentWidth,
        h: documentHeight / 2,
        align: 'center',
        valign: 'middle',
        fontFace: 'Poppins',
        margin: 10,
    });

};

export const chartNumber = (params) => {
    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    console.log(data);

    let arrTextObjs = [
        {
            text: data.data ? data.data : 0,
            options: { fontSize: 36, bold: true, color: '333333', breakLine: true }
        },
        { text: data.header, options: { fontSize: 16, bold: false, color: '333333', breakLine: true } },
    ];

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    slide.addText([...arrTextObjs], {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        align: 'center',
        valign: 'middle',
        fontFace: 'Poppins',
        margin: 10,
    });

    return slide;
};

export const chartList = (params) => {
    let { slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    let tempArray = [{ text: 'UL test', color: '00CD00' }];

    data.data.forEach((d) => {
        tempArray.push({
            text: d,
            options: { bullet: true, color: '696969' },
        });
    });

    slide.addText([...tempArray], {
        x: data.x * blockWidth,
        y: (data.y - yDifference) * blockHeight,
        w: data.w * blockWidth,
        h: data.h * blockHeight,
        valign: 'middle',
        color: 'ABABAB',
        margin: 1,
    });

    return slide;
};

export const chartLine = (params) => {
    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    let dataChartAreaLine = [];

    if (data.parsed_data && data.parsed_data.datasets) {
        data.parsed_data.datasets.map((item) => {
            let dataset = {
                name: item.label,
                labels: data.data.labels,
                values: item.data.filter((item) => {
                    return !isFunction(item)
                })
            };
            dataChartAreaLine.push(dataset);
        });
    }

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    if (data.parsed_data) {
        if (!data.settings) {
            data.settings = {};
        }

        if (!data.settings.title || data.settings.title == "") {
            data.settings.title = '...';
        }

        slide.addChart(
            pptx.charts.LINE,
            dataChartAreaLine,
            {
                chartColors: data.parsed_data.datasets.map((item) => {
                    return item.borderColor
                }),

                x: (data.x * blockWidth + 0.1) + 0.5,
                y: ((data.y - yDifference) * blockHeight + 0.1) + 0.62,
                w: (data.w * blockWidth - 0.2) - 1,
                h: (data.h * blockHeight - 0.2) - 0.8,

                chartColorsOpacity: 100,
                titleColor: '000000',
                titleFontFace: 'Poppins',
                titleFontSize: 14,
                showTitle: false,
                title: data.settings.title,
                showLegend: true,
                legendeColor: '000000',
                legendFontFace: 'Poppins',
                legendPos: 't'
            }
        );

        slide.addText(data.settings.title, {
            x: data.x * blockWidth + 0.1,
            y: (data.y + (data.y * 0.24)) + 0.5,
            w: data.w * blockWidth - 0.2,
            color: '000000',
            fontFace: 'Poppins',
            fontSize: 14,
            align: 'center'
        });
    }

};

export const chartTable = (params) => {
    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    //console.log(data);

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    let headers = [];

    //CHECK BASIC HEADERS
    if (!data.remove_channel) {
        let header = { text: "channel", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }
    if (!data.remove_breakdown) {
        let header = { text: "breakdown", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }
    if (!data.remove_breakdown_value) {
        let header = { text: "breakdown value", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }
    if (!data.remove_level) {
        let header = { text: "level", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }
    if (!data.remove_id) {
        let header = { text: "id", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }
    if (!data.remove_name) {
        let header = { text: "name", options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    }

    data.parsed_metrics.map((item) => {
        let header = { text: item.title, options: { margin: [12, 12, 12, 12], fill: { color: "fcfcfc" }, valign: "middle", align: "left" } };
        headers.push(header);
    });

    let rows = [];

    data.parsed_items.map((item) => {
        let row = [];
        /*
        if (!data.remove_preview) {
            let row_item = { text: item["preview"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        */
        if (!data.remove_channel) {
            let row_item = { text: item["channel"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        if (!data.remove_breakdown) {
            let row_item = { text: item["channel_breakdown"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        if (!data.remove_breakdown_value) {
            let row_item = { text: item["breakdown_value"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        if (!data.remove_level) {
            let row_item = { text: item["type"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        if (!data.remove_id) {
            let row_item = { text: item["id"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        if (!data.remove_name) {
            let row_item = { text: item["name"], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
            row.push(row_item);
        }
        for (let metric in item) {
            if (data.parsed_metrics.filter((item) => { return item.title === metric }).length > 0) {
                let row_item = { text: item[metric], options: { margin: [8, 8, 8, 8], valign: "middle", align: "left" } };
                row.push(row_item);
            }
        }
        rows.push(row);
    });

    rows.unshift(headers);

    slide.addTable(rows, {
        x: (data.x * blockWidth + 0.1) + 0.5,
        y: ((data.y - yDifference) * blockHeight + 0.1) + 0.8,
        w: (data.w * blockWidth - 0.2) - 1,
        fill: { color: "F7F7F7" },
        fontSize: 12,
        color: "363636",
        border: { pt: "1", color: "BBCCDD" },
    });

    if (!data.settings) {
        data.settings = {};
    }

    if (!data.settings.title || data.settings.title == "") {
        data.settings.title = '...';
    }

    slide.addText(data.settings.title, {
        x: data.x * blockWidth + 0.1,
        y: (data.y + (data.y * 0.24)) + 0.5,
        w: data.w * blockWidth - 0.2,
        color: '000000',
        fontFace: 'Poppins',
        fontSize: 14,
        align: 'center'
    });

};

export const chartBar = (params) => {
    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    let dataChartAreaLine = [];

    if (data.parsed_data && data.parsed_data.datasets) {
        data.parsed_data.datasets.map((item) => {
            let dataset = {
                name: item.label,
                labels: data.data.labels,
                values: item.data.filter((item) => {
                    return !isFunction(item)
                })
            };
            dataChartAreaLine.push(dataset);
        });
    }

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    if (!data.settings) {
        data.settings = {};
    }

    if (!data.settings.title || data.settings.title == "") {
        data.settings.title = '...';
    }

    let char_options = {
        x: (data.x * blockWidth + 0.1) + 0.5,
        y: ((data.y - yDifference) * blockHeight + 0.1) + 0.62,
        w: (data.w * blockWidth - 0.2) - 1,
        h: (data.h * blockHeight - 0.2) - 0.8,
        chartColorsOpacity: 100,
        titleColor: '000000',
        titleFontFace: 'Poppins',
        titleFontSize: 14,
        showTitle: false,
        title: data.settings.title,
        showLegend: true,
        legendeColor: '000000',
        legendFontFace: 'Poppins',
        legendPos: 't'
    };

    if (data.parsed_data) {

        if (data.parsed_data.datasets.map((item) => {
            return item.backgroundColor[0]
        }).length > 1) {
            char_options.chartColors = data.parsed_data.datasets.map((item) => {
                return item.backgroundColor[0]
            });
        }

        slide.addChart(
            pptx.charts.BAR,
            dataChartAreaLine,
            char_options
        );

        slide.addText(data.settings.title, {
            x: data.x * blockWidth + 0.1,
            y: (data.y + (data.y * 0.24)) + 0.5,
            w: data.w * blockWidth - 0.2,
            color: '000000',
            fontFace: 'Poppins',
            fontSize: 14,
            align: 'center'
        });
    }

};

export const chartDonut = (params) => {

    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    let dataChartAreaLine = [];

    if (data.parsed_data && data.parsed_data.datasets) {
        data.parsed_data.datasets.map((item) => {
            let dataset = {
                name: item.label,
                labels: data.data.labels,
                values: item.data.filter((item) => {
                    return !isFunction(item)
                })
            };
            dataChartAreaLine.push(dataset);
        });
    }

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    if (data.parsed_data) {

        let chartColors = [];
        data.parsed_data.datasets.map((item) => {
            chartColors = chartColors.concat(item.backgroundColor.map((color) => {
                return color;
            }));
        });

        if (!data.settings) {
            data.settings = {};
        }

        if (!data.settings.title || data.settings.title == "") {
            data.settings.title = '...';
        }

        slide.addChart(
            pptx.charts.DOUGHNUT,
            dataChartAreaLine,
            {
                chartColors: chartColors,

                x: (data.x * blockWidth + 0.1) + 0.5,
                y: ((data.y - yDifference) * blockHeight + 0.1) + 0.62,
                w: (data.w * blockWidth - 0.2) - 1,
                h: (data.h * blockHeight - 0.2) - 0.8,

                holeSize: 50,
                chartColorsOpacity: 100,
                titleColor: '000000',
                titleFontFace: 'Poppins',
                titleFontSize: 14,
                showTitle: false,
                title: data.settings.title,
                showLegend: true,
                legendeColor: '000000',
                legendFontFace: 'Poppins',
                legendPos: 't'
            }
        );

        slide.addText(data.settings.title, {
            x: data.x * blockWidth + 0.1,
            y: (data.y + (data.y * 0.24)) + 0.5,
            w: data.w * blockWidth - 0.2,
            color: '000000',
            fontFace: 'Poppins',
            fontSize: 14,
            align: 'center'
        });

    }

};

export const chartImg = (params) => {
    let { pptx, slide, data, initSizes, yDifference, format } = params;
    const { blockWidth, blockHeight } = initSizes;

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    if (format === "image") {
        slide.addImage({
            path: data.data,
            x: data.x * blockWidth + 0.1,
            y: (data.y - yDifference) * blockHeight + 0.1,
            w: data.w * blockWidth - 0.2,
            h: data.h * blockHeight - 0.2,
            margin: 10,
        });
    } else {
        slide.addMedia({
            type: "video",
            path: data.data,
            x: data.x * blockWidth + 0.1,
            y: (data.y - yDifference) * blockHeight + 0.1,
            w: data.w * blockWidth - 0.2,
            h: data.h * blockHeight - 0.2,
            margin: 10,
        });
    }

};

export const chartHtmlText = (params) => {
    let { pptx, slide, data, initSizes, yDifference } = params;
    const { blockWidth, blockHeight } = initSizes;

    const options = {
        fontFace: 'Poppins',
    };

    const items = htmlToPptxText(data.data, options);

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: data.x * blockWidth + 0.1,
        y: data.y === Infinity ? 0 + 0.1 : (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        rectRadius: 0.1,
        fill: { color: 'FFFFFF' },
        shadow: { ...shadowOpts },
    });

    slide.addText(items, {
        x: data.x * blockWidth + 0.1,
        y: data.y === Infinity ? 0 + 0.1 : (data.y - yDifference) * blockHeight + 0.1,
        w: data.w * blockWidth - 0.2,
        h: data.h * blockHeight - 0.2,
        valign: 'top',
        color: '000000',

        margin: 25,
    });

    return slide;
};

// finding width and height of image ( Promise func! )
export const _getWidthAndHeight = (logoUrl) =>
    new Promise((resolve, reject) => {
        const img = new Image();

        // the following handler will fire after the successful loading of the image
        img.onload = () => {
            const { width, height } = img;
            resolve({ width: width, height: height, proportion: width / height });
        };

        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            reject('There was some problem with the image.');
        };

        img.src = logoUrl;
    });

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function trim(str) {
    return str.replace(/^\s+|\s+$/gm, '');
}

function rgbaToHex(rgba) {
    var inParts = rgba.substring(rgba.indexOf("(")).split(","),
        r = parseInt(trim(inParts[0].substring(1)), 10),
        g = parseInt(trim(inParts[1]), 10),
        b = parseInt(trim(inParts[2]), 10),
        a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
    var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
        if (part.length === 1) {
            outParts[i] = '0' + part;
        }
    });

    return (outParts.join(''));
}
