class draftRegister {

    static draft = {};

    static set(key, value) {
        if(!draftRegister.draft){
            draftRegister.draft = {};
        }
        draftRegister.draft[key] = value;
        try {
            if (sessionStorage.getItem("draft") && sessionStorage.getItem("draft") !== "undefined") {
                let draft_data = JSON.parse(sessionStorage.getItem("draft"));
                draft_data[key] = value;
                sessionStorage.setItem("draft", JSON.stringify(draft_data));
            } else {
                sessionStorage.setItem("draft", JSON.stringify(draftRegister.draft));
            }
        } catch (error) {
            console.log(error);
        }
    }

    static get() {
        try {
            if (sessionStorage.getItem("draft")) {
                let draft_data = JSON.parse(sessionStorage.getItem("draft"));
                return draft_data;
            } else {
                return draftRegister.draft
            }
        } catch (e) {
            console.log(e);
            return draftRegister.draft
        }
    }

    static remove() {
        draftRegister.draft = {};
        try {
            sessionStorage.removeItem("draft");
        } catch (e) { }
    }

}

export { draftRegister }