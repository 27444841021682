import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import cn from "classnames";

class CreateGoogleKeyword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            adgroups: [],
            client: {},
            adgroup: { id: 0, name: "Click here ..." },
            match_type: { id: 1, name: "Exact match", value: "EXACT" },
            keyword: "",
            loader: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroups: this.props.adgroups,
            client: this.props.client
        })
    }

    functions = {
        create: async () => {
            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);
            let body = {
                operations: [
                    {
                        "create": {
                            keyword: {
                                text: this.state.keyword,
                                match_type: this.state.match_type.value
                            },
                            adGroup: this.state.adgroup.resource_name,
                            negative: false,
                            status: "ENABLED"
                        }
                    }
                ]
            }
            try {
                await this.calls.createAdgroupKeyword(body);
                this.props.onSuccess("Keyword created");
                await this.promisedSetState({
                    keyword: "",
                    adgroup: { id: 0, name: "Click here ..." },
                    match_type: { id: 1, name: "Exact match", value: "EXACT" },
                    keyword: ""
                })
                this.props.onCreate();
            } catch (error) {
                this.props.onError(error.body ? error.body.message : "Something went wrong");
            }
            this.props.onLoader(false);
        }
    };

    calls = {
        createAdgroupKeyword: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createAdgroupCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    timeout = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    render() {
        return (
            <>
                <div className="mt-5 grid grid-cols-3 gap-4">

                    {/*Adgroup level*/}
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Select adgroups"}
                            searchInput={true}
                            selected={this.state.adgroup}
                            options={Array.isArray(this.state.adgroups) ? this.state.adgroups : []}
                            onChange={(value) => {
                                this.setState({
                                    adgroup: value
                                })
                            }}
                        />
                    </div>

                    {/*Keyword info*/}
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Match type"}
                            selected={this.state.match_type}
                            options={[
                                { id: 1, name: "Exact match", value: "EXACT" },
                                { id: 2, name: "Phrase match", value: "PHRASE" },
                                { id: 3, name: "Broad match", value: "BROAD" }
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    match_type: value
                                })
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <InputTailwind
                            label={"Keyword text"}
                            value={this.state.keyword}
                            onChange={(value) => {
                                this.setState({
                                    keyword: value
                                })
                            }}
                        />
                    </div>

                </div>
            </>
        )
    }
}

export default CreateGoogleKeyword;
