import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'

class FaqItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open?this.props.open:false
        };
    };

    componentWillMount() {}

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div className="FaqItem">
                <div className="FaqItem-InnerContainerInner">
                    <div onClick={() => {
                        this.setState({open: !this.state.open})
                    }} className="FaqItem-InnerContainerInnerTop">
                        <div className={
                            cx("FaqItem-InnerContainerInnerTopTitle",
                                {
                                    ["FaqItem-InnerContainerInnerTopTitleOpen"]: this.state.open,
                                })
                        }>
                            {this.props.title}
                        </div>
                        <div className="FaqItem-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("FaqItem-InnerContainerInnerTopArrow",
                                    {
                                        ["FaqItem-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#0185fe"} size={20} icon="plus"/>
                            <div className="FaqItem-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("FaqItem-InnerContainerInnerTopMinus",
                                        {
                                            ["FaqItem-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#0185fe"} size={20} icon="minus"/>
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="Section FaqItemText">
                            {this.props.children}
                        </div>
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default FaqItem;
