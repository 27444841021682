import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../dropdown";

class TargetingGender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            channels: {},
            search: false
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting?this.props.targeting:{},
            channels: this.props.channels?this.props.channels:{},
            search: this.props.search
        }, () => {
            this.state.targeting.gender = this.state.targeting.gender?this.state.targeting.gender:{name: 'Male and Female', value: 0};
            this.setState({
                targeting: this.state.targeting
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting?nextProps.targeting:{},
            channels: nextProps.channels?nextProps.channels:{},
            search: nextProps.search
        }, () => {
            this.state.targeting.gender = this.state.targeting.gender?this.state.targeting.gender:{name: 'Male and Female', value: 0};
            this.setState({
                targeting: this.state.targeting
            })
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        number: (x) => {
            try {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <Dropdown
                filter={false}
                title={'Gender'}
                size={'full'}
                placeholder={"Click here .."}
                error={this.state.error === "gender"}
                options={[
                    {name: 'Male', value: 1},
                    {name: 'Female', value: 2},
                    {name: 'Male and Female', value: 0}
                ]}
                icons={true}
                channels={Object.keys(this.state.channels).filter((channel) => {
                    return this.state.channels[channel] && ((this.state.search && channel === "google") || (!this.state.search && channel !== "google"))
                })}
                value={this.state.targeting.gender?this.state.targeting.gender:{}}
                onChange={(value) => {
                    this.state.targeting.gender = value;
                    this.setState({targeting: this.state.targeting}, () => {
                        this.props.onChange(this.state.targeting);
                    })
                }}
            />
        )
    }
}

export default TargetingGender;
