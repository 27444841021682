import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon, FolderIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import ToolTip from './toolTip';
import { cli } from 'tailwindcss/lib/constants';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class WizardModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            channels: [],
            channels_option: [
                { id: 1, name: "facebook", selected: false },
                { id: 2, name: "linkedin", selected: false },
                { id: 3, name: "tiktok", selected: false },
                { id: 4, name: "twitter", selected: false },
                { id: 5, name: "snapchat", selected: false },
            ],
            carousel: false,
            merge: false,
            collection: false,
            show_size_error: false,
            size_error: false,
            client: {},
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            channels: this.props.channels,
            carousel: this.props.carousel,
            merge: this.props.merge,
            collection: this.props.collection,
            client: this.props.client ? this.props.client : false,
        })

        if (Object.keys(this.state.client).length > 0) {
            await this.functions.getClient(this.state.client.id);
        }
        if (this.state.carousel || this.state.merge || this.state.collection) {
            if (this.state.carousel) {
                this.state.channels_option = [
                    { id: 1, name: "facebook", selected: false },
                    { id: 2, name: "linkedin", selected: false },
                    { id: 4, name: "twitter", selected: false }
                ]
            } else {
                this.state.channels_option = [
                    { id: 1, name: "facebook", selected: false }
                ]
            }
            await this.promisedSetState({
                //channels_option: this.state.channels_option
            })
        } else {
            this.state.channels_option = [
                { id: 1, name: "facebook", selected: false },
                { id: 2, name: "linkedin", selected: false },
                { id: 3, name: "tiktok", selected: false },
                { id: 4, name: "twitter", selected: false },
                { id: 5, name: "snapchat", selected: false },
            ]
        }
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            open: nextProps.open,
            channels: nextProps.channels,
            carousel: nextProps.carousel,
            merge: nextProps.merge,
            collection: nextProps.collection,
            size_error: nextProps.size_error,
            client: nextProps.client ? nextProps.client : false,
        })
        if (Object.keys(this.state.client).length > 0 && !this.state.client.channels) {
            await this.functions.getClient(this.state.client.id);
        }
        if (this.state.carousel || this.state.merge || this.state.collection) {
            if (this.state.carousel) {
                this.state.channels_option = [
                    { id: 1, name: "facebook", selected: false },
                    { id: 2, name: "linkedin", selected: false },
                    { id: 4, name: "twitter", selected: false }
                ]
            } else {
                this.state.channels_option = [
                    { id: 1, name: "facebook", selected: false }
                ]
            }
            await this.promisedSetState({
                //channels_option: this.state.channels_option
            })
        } else {
            this.state.channels_option = [
                { id: 1, name: "facebook", selected: false },
                { id: 2, name: "linkedin", selected: false },
                { id: 3, name: "tiktok", selected: false },
                { id: 4, name: "twitter", selected: false },
                { id: 5, name: "snapchat", selected: false },

            ]
        }
    }
    functions = {
        getClient: async (id) => {
            this.calls.getClient(id).then(async (response) => {
                if (!response.data.scheduler) {
                    response.data.scheduler = { enabled: false, frequency: { id: 0, name: "Select ..." }, day: { id: 0, name: "Select ..." } };
                }
                await this.promisedSetState({
                    client: response.data
                })
            }, (error) => { });
        },
    }
    calls = {
        getClient: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getClient?client=" + id;
            return apiRegister.call(options, url);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }
    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-60 inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose(true, false);
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* CHANGED OVERFLOW-HIDDEN TO NORMAL BELOW */}
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                                        {
                                            this.props.icon &&
                                            <this.props.icon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                        }
                                        {
                                            !this.props.icon &&
                                            <FolderIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                        }
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 flex justify-center items-center">
                                            {this.props.title}
                                            {
                                                this.state.size_error &&
                                                <span className="relative ml-2">
                                                    <ExclamationIcon
                                                        onMouseEnter={() => { this.state.show_size_error = true; this.setState({ show_size_error: this.state.show_size_error }) }}
                                                        onMouseLeave={() => { this.state.show_size_error = false; this.setState({ show_size_error: this.state.show_size_error }) }}
                                                        className='w-4 h-4 text-red-500'
                                                    />
                                                    <div className='absolute'
                                                        style={{ right: 137, bottom: -15 }}
                                                    >
                                                        <ToolTip show={this.state.show_size_error}>
                                                            <div className="text-xs text-gray-500">
                                                                File size too large, Snapchat disabled
                                                            </div>
                                                        </ToolTip>
                                                    </div>
                                                </span>
                                            }
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {this.props.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (this.state.size_error ? this.state.channels_option.filter(item => { return this.state.channels.includes(item.name !== "snapchat" && item.name) }) : this.state.channels_option.filter((item) => { return this.state.channels.includes(item.name) })).map((item) => {
                                        return (
                                            <>
                                                <div onClick={async () => {

                                                    this.state.channels_option = this.state.channels_option.map((inner_item) => {
                                                        if (this.state.client.channels && !this.state.client.channels[item.name]) {

                                                        } else {
                                                            if (inner_item.id === item.id) {
                                                                if (item.selected) {
                                                                    inner_item.selected = false
                                                                } else {
                                                                    inner_item.selected = true
                                                                }
                                                            }
                                                        }
                                                        return inner_item
                                                    })
                                                    await this.promisedSetState({
                                                        channels_option: this.state.channels_option
                                                    });
                                                }}
                                                    className={(item.selected ? ("bg-" + item.name + "-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400") + " my-2 py-2 px-2 flex flex-row bg-custom-input  overflow-hidden rounded-md items-center " + (((this.state.client.channels && this.state.client.channels[item.name]) || Object.keys(this.state.client).length === 0) ? "cursor-pointer" : "cursor-not-allowed")}>
                                                    {
                                                        item.name === 'facebook' &&
                                                        <img className="w-1"
                                                            alt=""
                                                            src={require('../assets/images/facebook_icon.svg')} />
                                                    }
                                                    {
                                                        item.name === 'linkedin' &&
                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/linkedin_icon.svg')} />
                                                    }
                                                    {
                                                        item.name === 'twitter' &&
                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/twitter_icon.svg')} />
                                                    }
                                                    {
                                                        item.name === 'tiktok' &&
                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/tiktok_icon.png')} />
                                                    }
                                                    {
                                                        item.name === 'snapchat' &&
                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/snapchat.svg')} />
                                                    }
                                                    {
                                                        <span
                                                            className={"flex w-full items-center justify-between pl-4 text-sm font-medium text-center capitalize" + (item.selected && item.name === "snapchat" ? " text-black" : "")}>
                                                            {item.name}
                                                        </span>
                                                    }
                                                    {
                                                        this.state.client.channels && !this.state.client.channels[item.name] &&
                                                        <span className=' text-red-500 text-xxs'>
                                                            Not connected
                                                        </span>
                                                    }
                                                </div >

                                            </>
                                        )
                                    })

                                }
                                <div className="mt-4">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (this.props.onClose) {
                                                        this.props.onClose();
                                                    }
                                                }}
                                            >
                                                {"Close"}
                                            </button>
                                        </div>
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className={(this.state.channels_option.filter((option) => { return option.selected }).length > 0 ? "bg-purple-500  hover:bg-purple-600  text-white " : "cursor-not-allowed bg-gray-300 text-gray-600 ") + " inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"}
                                                onClick={async () => {

                                                    if (this.props.onCreate && this.state.channels_option.filter((option) => { return option.selected }).length > 0) {
                                                        await this.promisedSetState({
                                                            channels: this.state.channels_option.filter((option) => { return option.selected }).map((channel) => { return channel.name })
                                                        })
                                                        this.props.onCreate(this.state.channels);
                                                    }
                                                }}
                                            >
                                                {this.props.button}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default WizardModal;
