import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from "react-spinners";
import { SlideDown } from "react-slidedown";
import { calls } from "./calls";
import cx from "classnames";
import { userRegister } from "../../services/userRegister";
var moment = require('moment');

class SelectClientAndCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            tab: {},
            open: {
                campaigns: true
            },
            search: {},
            clients: [],
            page: 0,
            page_size: 10,
            total: 0,
            client: {},
            type: {},
            selected_external: {},
            external_campaigns: {},
            template: null,
            loading_internals: false,
            loading_externals: false,
            advancedUser: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed
        })
    }

    componentWillMount() { }

    componentDidMount() {
        window.scrollTo(0, 0);
        //this.functions.clients();
        //this.functions.user();
    }

    functions = {
        init: () => {
            this.setState({
                client: {},
                type: {},
                selected_external: {},
                external_campaigns: {},
                open: {
                    client: true,
                    campaigns: true
                },
                template: this.props.template,
                loading_internals: false,
                loading_externals: false
            }, () => {
                if (this.state.clients.length < 1) {
                    this.functions.clients();
                }
            })
        },
        user: () => {
            let user_data = userRegister.get();
            if (user_data.advanced) {
                this.setState({
                    advancedUser: user_data.advanced
                })
            }
        },
        clients: () => {
            this.setState({
                loading_clients: true
            }, () => {
                calls.getClient().then((response) => {
                    this.setState({
                        clients: response.data,
                        external_campaigns: {},
                        loading_clients: false,
                    });
                }, (error) => {
                    this.setState({
                        loading_clients: false,
                    });
                }
                );
            }
            );
        },
        campaigns: () => {
            this.setState({
                loading_externals: true
            }, async () => {
                let campaigns = {};
                try {
                    let response = await calls.getCampaignsByClient(this.state.client.id, 0, this.state.sort, this.state.order, 50000);
                    if (!this.state.closed) {
                        //ONLY GOOGLE
                        let google_campaigns = response.data.filter((item) => { return item.channels.google });
                        campaigns = { ...campaigns, ...{ 'internal': google_campaigns } }
                    }
                } catch (error) { }
                try {
                    let response = await calls.getAllExternalCampaigns({ id: this.state.client.id });
                    if (!this.state.closed) {
                        //ONLY GOOGLE
                        if (response.data.google && Array.isArray(response.data.google)) {
                            campaigns = { ...campaigns, ...{ 'google': response.data.google } };
                        }
                    }
                } catch (error) { }
                this.setState({
                    external_campaigns: campaigns,
                    loading_externals: false
                });
            })
        },
        create: () => {
            if (this.props.onChange) {
                let campaigns = Object.keys(this.state.selected_external.google).map((id) => { return this.state.selected_external.google[id] })
                console.log(campaigns);
                this.props.onChange(this.state.client, campaigns);
            }
        }
    };

    renders = {
        stepValid: (step) => {
            if (step === "campaigns") {
                return this.state.client && this.state.client.id
            } else {
                return false
            }
        },
        valid: () => {
            return this.state.client && this.state.client.id && this.state.selected_external && this.state.selected_external.google && Object.keys(this.state.selected_external.google).length > 0
        },
        daysToEndDate: (date) => {
            var now = moment(new Date());
            var end = moment(date);
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            return days;
        }
    };

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                {
                    <div className={"px-4 sm:px-6 pb-6 border-b"}>
                        <div className="flex flex-1 flex-row justify-center items-center">
                            <div className="flex flex-col flex-1">
                                <h3 className="mb-0 font-bold text-sm">
                                    Select client
                                </h3>
                                {
                                    !this.state.client.id && !this.state.loading_clients &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        There are <span
                                            className="font-bold ml-1 mr-1">{this.state.clients.length}</span> clients
                                        available
                                    </p>
                                }
                                {
                                    this.state.client.id && !this.state.loading_clients &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected <span
                                            className="font-bold ml-1 mr-1">{this.state.client.name}</span>
                                    </p>
                                }
                                {
                                    this.state.loading_clients &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Loading ...
                                    </p>
                                }
                            </div>
                            {
                                <div className="flex flex-row">
                                    {
                                        this.state.client.id && !this.state.loading_clients &&
                                        <div
                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'} size={14}
                                                icon={"check"} />
                                        </div>
                                    }
                                    {
                                        !this.state.loading_clients &&
                                        <div onClick={() => {
                                            this.state.open.client = !this.state.open.client;
                                            this.setState({
                                                open: this.state.open
                                            })
                                        }}
                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                            {
                                                !this.state.open.client &&
                                                <FeatherIcon className={'stroke-current'} size={14}
                                                    icon={"chevron-down"} />
                                            }
                                            {
                                                this.state.open.client &&
                                                <FeatherIcon className={'stroke-current'} size={14}
                                                    icon={"chevron-up"} />
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.loading_clients &&
                                        <div
                                            className="cursor-pointer h-8 w-8 rounded text-purple-500 bg-purple-100 flex justify-center align-middle items-center">
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={4}
                                                color={'#060534'}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <SlideDown closed={!this.state.open.client}>
                            {
                                !this.state.loading_clients &&
                                <div className="w-full h-6" />
                            }
                            {
                                !this.state.loading_clients &&
                                <div className="flex flex-1 flex-col justify-center mb-4">
                                    <input
                                        onChange={(event) => {
                                            this.state.search.clients = event.target.value;
                                            this.setState({
                                                search: this.state.search
                                            });
                                        }}
                                        placeholder={"Search ..."}
                                        value={this.state.search['clients'] ? this.state.search['clients'] : ""}
                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                    />
                                </div>
                            }
                            <div className="">
                                {
                                    this.state.clients.filter((item) => {
                                        if (this.state.search['clients']) {
                                            let flat_nested_values = item.name;
                                            return flat_nested_values.toLowerCase().indexOf(this.state.search['clients'].toLowerCase()) !== -1
                                        } else {
                                            return true;
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <div
                                                    className={"cursor-pointer " + (i !== (this.state.clients.length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                    <div
                                                        className="text-purple-500 p-1 overflow-hidden bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                        <img className="w-full" src={item.logo} />
                                                    </div>
                                                    <div onClick={() => {
                                                        if (!this.state.loading_externals && !this.state.loading_internals) {
                                                            this.state.open.client = false;
                                                            this.setState({
                                                                client: item,
                                                                external_campaigns: {},
                                                                open: this.state.open
                                                            }, () => {
                                                                this.functions.campaigns();
                                                                //this.functions.externals();
                                                                //this.functions.campaigns();
                                                            })
                                                        }
                                                    }}
                                                        className={((this.state.client && this.state.client.id === item.id) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.client && this.state.client.id === item.id &&
                                                            <div className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col truncate">
                                                        <div className="font-bold text-xs truncate">
                                                            {item.name}
                                                        </div>
                                                        <div className="text-xxs">
                                                            {item.company}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.client && this.state.client.id &&
                                <div className="flex justify-center items-center">
                                    <div onClick={() => {
                                        this.state.open.client = false;
                                        this.state.open.campaigns = true;
                                        this.setState({
                                            open: this.state.open
                                        })
                                    }} className="font-bold text-xs text-purple-500 cursor-pointer mt-5">NEXT
                                    </div>
                                </div>
                            }
                        </SlideDown>
                    </div>
                }
                {
                    (this.renders.stepValid('campaigns')) &&
                    <div className={(this.renders.stepValid('campaigns') ? "" : "opacity-50 ") + ""}>
                        <div className="flex flex-1 flex-row justify-center items-center px-4 sm:px-6 pt-6 pb-6 border-b">
                            <div className="flex flex-col flex-1">
                                <h3 className="mb-0 font-bold text-sm">
                                    Select campaigns
                                </h3>
                                {
                                    Object.keys(this.state.selected_external).length > 0 && !this.state.loading_externals && !this.state.loading_internals &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected <span className="font-bold ml-1 mr-1">{Object.keys(this.state.selected_external).length}</span> campaigns
                                    </p>
                                }
                                {
                                    Object.keys(this.state.selected_external).length < 1 && !this.state.loading_externals && !this.state.loading_internals &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Select campaigns below
                                    </p>
                                }
                                {
                                    (this.state.loading_externals || this.state.loading_internals) &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Loading ...
                                    </p>
                                }
                            </div>
                            <div className="flex flex-row">
                                {
                                    Object.keys(this.state.selected_external).length > 0 &&
                                    <div
                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                                {
                                    (this.state.loading_externals || this.state.loading_internals) &&
                                    <div
                                        className="cursor-pointer h-8 w-8 rounded text-purple-500 bg-purple-100 flex justify-center align-middle items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.loading_externals && !this.state.loading_internals &&
                                    <div className="flex flex-row">
                                        <div onClick={() => {
                                            this.state.open.campaigns = !this.state.open.campaigns;
                                            this.setState({
                                                open: this.state.open
                                            })
                                        }}
                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                            {
                                                !this.state.open.campaigns &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14}
                                                    icon={"chevron-down"} />
                                            }
                                            {
                                                this.state.open.campaigns &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14} icon={"chevron-up"} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <SlideDown closed={!this.state.open.campaigns || this.state.loading_externals || this.state.loading_internals}>
                            {
                                !this.state.loading_externals && !this.state.loading_internals &&
                                this.renders.stepValid('campaigns') &&
                                <div>
                                    {
                                        Object.keys(this.state.external_campaigns).sort().filter((channel) => {
                                            return this.state.external_campaigns[channel].length > 0;
                                        }).map((channel) => {
                                            return (
                                                <div className="border-b pb-6 pt-6 box-border">
                                                    <div className="px-6 pl-12">
                                                        <div className="flex flex-1 flex-row justify-center items-center">
                                                            <div className="flex flex-row flex-1 items-center align-middle">
                                                                {
                                                                    channel === "internal" &&
                                                                    <div
                                                                        className="bg-purple-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-5"
                                                                            src={require('../../assets/images/Adcredo Logotype Symbol (White).png')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "google" &&
                                                                    <div
                                                                        className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-3"
                                                                            src={require('../../assets/images/google_icon.svg')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "linkedin" &&
                                                                    <div
                                                                        className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-3"
                                                                            src={require('../../assets/images/linkedin_icon.svg')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "facebook" &&
                                                                    <div
                                                                        className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img style={{ width: '0.4rem' }}
                                                                            src={require('../../assets/images/facebook_icon.svg')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "google_analytics" &&
                                                                    <div
                                                                        className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-3"
                                                                            src={require('../../assets/images/ga_icon.png')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "adform" &&
                                                                    <div
                                                                        className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-3"
                                                                            src={require('../../assets/images/adform_icon.png')} />
                                                                    </div>
                                                                }
                                                                {
                                                                    channel === "twitter" &&
                                                                    <div
                                                                        className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                        <img className="w-3"
                                                                            src={require('../../assets/images/twitter_icon.svg')} />
                                                                    </div>
                                                                }
                                                                <div style={{ marginTop: "-6px" }} className="flex flex-col justify-center">
                                                                    <h3 className={"mb-0 font-bold"}>
                                                                        <span className="capitalize text-sm">{channel ? (channel.replace("_", " ")) : "..."}</span>
                                                                    </h3>
                                                                    {
                                                                        !this.state.selected_external[channel] &&
                                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                            There are<span
                                                                                className="font-bold ml-1 mr-1">{this.state.external_campaigns[channel].length}</span> campaigns
                                                                            available
                                                                        </p>
                                                                    }
                                                                    {
                                                                        this.state.selected_external[channel] &&
                                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                            <span>You have selected <span className="font-bold">{Object.keys(this.state.selected_external[channel]).length}</span></span>
                                                                        </p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.external_campaigns[channel].length > 0 &&
                                                                <div className="flex flex-row">
                                                                    <div onClick={() => {
                                                                        this.state.open[channel] = !this.state.open[channel];
                                                                        this.setState({
                                                                            open: this.state.open
                                                                        })
                                                                    }}
                                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                        {
                                                                            !this.state.open[channel] &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={"chevron-down"} />
                                                                        }
                                                                        {
                                                                            this.state.open[channel] &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14} icon={"chevron-up"} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <SlideDown closed={!this.state.open[channel]}>
                                                            {
                                                                <div className="w-full h-6" />
                                                            }
                                                            {
                                                                <div className="flex flex-1 flex-col justify-center mb-4">
                                                                    <input
                                                                        onChange={(event) => {
                                                                            this.state.search[channel] = event.target.value;
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            });
                                                                        }}
                                                                        placeholder={"Search ..."}
                                                                        value={this.state.search[channel] ? this.state.search[channel] : ""}
                                                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.external_campaigns[channel].sort((a, b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0)).filter((item) => {
                                                                    if (this.state.search[channel]) {
                                                                        let flat_nested_values = item.name;
                                                                        return flat_nested_values.toLowerCase().indexOf(this.state.search[channel].toLowerCase()) !== -1
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }).map((item, i) => {
                                                                    return (
                                                                        <Fragment key={i}>
                                                                            <div className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " flex flex-1 flex-row justify-center mb-2"}>
                                                                                {
                                                                                    <div onClick={() => {
                                                                                        if (!item.disabled) {
                                                                                            if (!this.state.selected_external[channel]) {
                                                                                                this.state.selected_external[channel] = {};
                                                                                                this.state.selected_external[channel][item.id] = item;
                                                                                            } else if (!this.state.selected_external[channel][item.id]) {
                                                                                                this.state.selected_external[channel][item.id] = item;
                                                                                            } else {
                                                                                                delete this.state.selected_external[channel][item.id];
                                                                                                if (Object.keys(this.state.selected_external[channel]).length < 1) {
                                                                                                    delete this.state.selected_external[channel];
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                selected_external: this.state.selected_external
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                        className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + ((this.state.selected_external && this.state.selected_external[channel] && this.state.selected_external[channel][item.id]) ? " border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.selected_external && this.state.selected_external[channel] && this.state.selected_external[channel][item.id] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14}
                                                                                                icon="check" />
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                <div
                                                                                    className="flex flex-1 flex-col truncate">
                                                                                    <div className="font-bold text-xs truncate">
                                                                                        {item.name}
                                                                                    </div>
                                                                                    <div className="text-xxs">
                                                                                        {item.id} | {
                                                                                            item.status &&
                                                                                            <span className={cx("uppercase",
                                                                                                {
                                                                                                    ["text-green-500"]: item.status.toUpperCase() === "ACTIVE" || item.status === "ENABLED",
                                                                                                    ["text-purple-500"]: item.status.toUpperCase() === "ENDED",
                                                                                                    ["text-orange-500 bg-opacity-50"]: item.status.toUpperCase() === "PAUSED",
                                                                                                    ["text-red-600"]: item.status.toUpperCase() === "error",
                                                                                                })
                                                                                            }>{item.status}</span>
                                                                                        }
                                                                                        {
                                                                                            item.disabled &&
                                                                                            <span className="ml-2 uppercase">Enddate out of scope</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </SlideDown>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </SlideDown>
                    </div>
                }
                <div className="w-full h-6" />
                <div className="px-4">
                    <div onClick={() => {
                        if (this.renders.valid()) {
                            this.functions.create();
                        }
                    }} className={(this.renders.valid() ? "bg-green-500 text-white cursor-pointer" : "bg-gray-100 text-gray-500 cursor-not-allowed") + " py-3 px-4 transition-all duration-200 rounded-full flex rounded font-bold justify-center align-middle items-center font-bold w-full text-sm text-center"}>
                        <div>Optimize campaign</div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SelectClientAndCampaign;
