import React, {Component} from 'react';
import './style.css';
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";

class AdvertisingKeywordsIdeas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ideas: {},
            client: {},
            search: "",
            language: {},
            loading: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            website: nextProps.website,
            language: nextProps.language,
            ideas: nextProps.ideas,
        });
    }

    componentDidMount() {
        this.setState({
            client: this.props.client,
            website: this.props.website,
            language: this.props.language,
            ideas: this.props.ideas
        }, () => {
            if((!Array.isArray(this.state.ideas) || (Array.isArray(this.state.ideas) && this.state.ideas.length < 1)) && this.state.website && this.state.language.google_id && this.state.client.id){
                this.functions.ideas();
            }
        })
    }

    init = {};

    functions = {
        ideas: () => {
            this.setState({
                loading: true
            }, () => {
                let object = {
                    client: this.state.client.id,
                    page_url: this.state.website,
                    language_id: this.state.language.google_id
                };
                calls.getKeywordIdeas(object).then((response) => {
                    this.setState({
                        ideas: response.data,
                        loading: false
                    }, () => {
                        try{
                            this.props.updateIdeas(this.state.ideas);
                        }catch (e) {}
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    validators = {};

    renders = {};

    render() {
        return (
            <div className="flex flex-row overflow-scroll w-full">
                {
                    this.state.loading && this.state.language.google_id &&
                    <div className="flex flex-1 justify-center items-center align-middle h-full">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading && !this.state.language.google_id &&
                    <div className="flex flex-1 justify-start items-center align-middle h-full text-center font-bold text-xs">
                        Add campaign language to fetch keywords ideas
                    </div>
                }
                {
                    !this.state.loading && this.state.language.google_id &&
                    Array.isArray(this.state.ideas) &&
                    this.state.ideas.sort((a, b) => {
                        var nameA = null;
                        var nameB = null;
                        nameA = a['monthly_searches'];
                        nameB = b['monthly_searches'];
                        return nameB - nameA
                    }).filter((item) => {
                        try{
                            return item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                        }catch (e) {
                            return true
                        }
                    }).map((item) => {
                        return(
                            <div >
                                <button onClick={() => {
                                    try{
                                        this.props.addKeyword(item);
                                    }catch (e) {}
                                }} className="bg-google-100 whitespace-no-wrap text-gray-600 py-2 px-4 rounded-full hover:bg-google-500 hover:text-white text-xs font-bold mr-2">
                                    {item.name} <span className="font-normal">({item.monthly_searches})</span>
                                </button>
                            </div>

                        )
                    })
                }
            </div>
        )
    }
}

export default AdvertisingKeywordsIdeas;
