import React, {Component} from 'react';
import cx from "classnames";
import {SlideDown} from "react-slidedown";

class CampaignErrors extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {
        this.setState({
            open: !this.props.toggle
        })
    }

    componentDidMount() {
    }

    functions = {};

    renders = {
        amount: () => {
            let count = 0;
            if(this.props.campaignDataErrors.errors){
                for(let i = 0; i < this.props.campaignDataErrors.errors.length; i ++){
                    count = count + this.props.campaignDataErrors.errors[i].errors.length;
                }
            }
            return count
        },
        markdown: (input) => {
            return { __html: input }
        },
        errorName: (item) => {
            try{
                if(typeof item.name === 'string'){
                    return item.name
                }else if(Array.isArray(item.name)){
                    let string = "";
                    return item.name.map((inner_item) => {
                        return inner_item.name;
                    }).join(', ');
                }
            }catch (e) {
                return 'Something went wrong'
            }
        }
    };

    render() {
        return (
            <section className={
                cx("p-4 bg-white shadow-lg rounded-md",
                    {
                        ["mt-0 mb-8"]: this.props.toggle
                    })
            }>
                <div className="flex flex-row">
                    <div className="flex justify-center items-center align-middle">
                        {
                            <span className="h-10 w-10 flex justify-center items-center align-middle rounded-full text-xs font-black bg-red-500 text-white">{this.renders.amount()}</span>
                        }
                        <div className="flex flex-1 flex-col ml-2 flex justify-start align-middle">
                            <h3 className="mb-0 font-bold">
                                Errors
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Please correct errors
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-1"></div>
                    {
                        false &&
                        <h3 className="text-xl flex flex-row items-center flex-1">Errors <span className="ml-2 py-1 px-4 rounded-full text-xs font-black bg-red-500 text-white">{this.renders.amount()}</span>
                        </h3>
                    }
                    {this.props.toggle &&
                        <div onClick={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }} className={
                            cx("py-1 px-1 text-xs font-bold rounded-full flex items-center justify-center cursor-pointer bg-opacity-50",
                                {
                                    ["bg-red-500 bg-opacity-25 text-red-500"]: this.state.open,
                                    ["bg-black text-white-500"]: !this.state.open,
                                })
                        }>
                            {this.state.open &&
                            <span className="close w-6 h-6 bg-red-500 rounded-full flex justify-center items-center text-white">&#10005;</span>
                            }
                            {!this.state.open &&
                            <span className="expand w-6 h-6 rounded-full flex justify-center items-center text-gray-900 bg-gray-200 text-base">+</span>
                            }
                            <span className="px-2 text-white">{this.state.open ? 'Close' : 'Expand'}</span>
                        </div>
                    }
                </div>
                <SlideDown closed={!this.state.open}>
                    <div className="mt-4 campaign-error--wrapper">
                        {this.props.campaignDataErrors.errors.map((item, index) => {
                            return (
                                <div key="index" className={cx("campaign-error__block py-4", {
                                    ["border-t"]: index > 0
                                })}>
                                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
                                        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="overflow-hidden">
                                                <table className="table-fixed min-w-full bg-white">
                                                    <thead>
                                                    <tr>
                                                        <th className="pb-2 pl-0 px-6 text-left text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">

                                                        </th>
                                                        <th className="pb-2 w-1/6 pl-0 px-6 text-left text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                                                            Type
                                                        </th>
                                                        <th className="pb-2 w-1/6 px-6 text-left text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                                                            Name
                                                        </th>
                                                        <th className="pb-2 w-4/6 px-6 text-left text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">
                                                            Message
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="bg-white">
                                                    {item.errors.map((err, j) => {
                                                        return (
                                                            <tr className="" key="j">
                                                                <td className="px-6 pb-2 pl-0">
                                                                    <div className="flex-shrink-0">
                                                                        <div
                                                                            className={cx("w-10 h-10 rounded flex items-center justify-center", {
                                                                                ["bg-google-500"]: item.channel === 'google',
                                                                                ["bg-facebook-500"]: item.channel === 'facebook',
                                                                                ["bg-instagram-500"]: item.channel === 'instagram'
                                                                            })}>
                                                                            {item.channel == 'google' &&
                                                                            <img className="w-4 h-4"
                                                                                 src={require('../../assets/images/google_icon.svg')}/>
                                                                            }
                                                                            {item.channel == 'facebook' &&
                                                                            <img className="w-4 h-4"
                                                                                 src={require('../../assets/images/facebook_icon.svg')}/>
                                                                            }
                                                                            {item.channel == 'instagram' &&
                                                                            <img className="w-4 h-4"
                                                                                 src={require('../../assets/images/instagram_icon.svg')}/>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 pb-2 text-sm capitalize leading-5 text-gray-900 pl-0">
                                                                    {err.type}
                                                                </td>
                                                                <td className="px-6 pb-2 text-sm capitalize leading-5 text-gray-900">
                                                                    {this.renders.errorName(err)}
                                                                </td>
                                                                <td dangerouslySetInnerHTML={this.renders.markdown(typeof err.message === 'string'?err.message:"")} className="px-6 pb-2 text-sm capitalize leading-5 text-gray-900">
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        false &&
                                        <div className="action-btns flex flex-row justify-end">
                                            {/* Edit */}
                                            <button
                                                className="bg-transparent text-purple-900 flex py-2 px-6 rounded-full font-bold underline text-sm transition-all duration-200 hover:text-purple-500 hover:no-underline">
                                                Edit campaign
                                            </button>
                                            {/* Resolve */}
                                            <button className="ml-2 bg-purple-100 text-purple-500 flex py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-purple-500 hover:text-white">
                                                Resolve errors
                                            </button>
                                            {/* Publish */}
                                            <button className="ml-2 bg-green-100 text-green-500 flex py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-green-500 hover:text-white">
                                                Publish anyway
                                            </button>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </SlideDown>
            </section>
        )
    }
}

export default CampaignErrors;
