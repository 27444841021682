import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, CalendarIcon, ChevronDownIcon, ChevronUpIcon, ClipboardListIcon, CubeIcon, ExclamationCircleIcon, FolderIcon, PlusIcon, TrashIcon, ArrowDownIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import { Doughnut, Line } from 'react-chartjs-2';
import CampaignOverview from '../moduleFiles/campaignOverview';

class CampaignPerformanceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            search: "",
            sort: "spend",
            direction: "descending",
            create_wizard: false,
            wizard_name: "",
            metric: "spend",
            orders: [],
            dateSpan: { id: 3, name: "Last 7 days", value: "last_7" },
            columns: [
                { name: 'Name', value: 'name', min_width: '350px', border: false },
                { name: 'Client', value: 'client', min_width: '450px', border: true },
                { name: 'Status', value: 'status', min_width: '150px', border: true },
                { name: 'Start date', value: 'start_date', min_width: '150px', border: true },
                { name: 'End date', value: 'end_date', min_width: '150px', border: true },
                { name: 'Spend', value: 'spend', min_width: '200px', border: true },
                { name: 'Impressions', value: 'impressions', min_width: '200px', border: true },
                { name: 'Click', value: 'clicks', min_width: '200px', border: true },
                { name: 'CTR', value: 'ctr', min_width: '200px', border: true },
                { name: 'CPC', value: 'cpc', min_width: '200px', border: true },
                { name: 'CPM', value: 'cpm', min_width: '200px', border: true }
            ],
            channel: { id: 1, name: "All channels", value: "all" },
            campaigns: [],
            status: {},
            client: {},
            clients: [],
            stats: [],
            filters: [],
            throttling: {},
            campaign: null,
            search_ids: null,
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "dateSpan",
                    placeholder: "This month (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month (default)", value: "this_month" },
                        { id: 2, name: "Last week", value: "last_week" },
                        { id: 3, name: "Last month", value: "last_month" },
                        { id: 4, name: "Last 60 days", value: "last_60" },
                        { id: 5, name: "Last 90 days", value: "last_90" }
                    ],
                    selected: { id: 1, name: "This month (default)", value: "this_month" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "All channels (default)", value: "all" },
                        { id: 2, name: "Facebook", value: "facebook" },
                        { id: 3, name: "Tiktok", value: "tiktok" },
                        { id: 4, name: "Linkedin", value: "linkedin" },
                        { id: 5, name: "Google", value: "google" },
                        { id: 8, name: "Bing", value: "bing" }
                    ],
                    selected: { id: 1, name: "All channels (default)", value: "all" }
                }
            ],
            doughnuts: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            disable_chart: false,
        }
    };

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        this.state.search_ids = searchParams.get('ids');
        this.functions.client();
        this.props.updateStatistics();
    }

    functions = {
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.getCampaigns(true);
            this.functions.getAgencyAccountsPerformanceChart(true);
        },
        getCampaigns: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.campaigns(params);
                await this.promisedSetState({
                    campaigns: response.data,
                    meta: response.meta
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        getAgencyAccountsPerformanceChart: async (init) => {
            await this.promisedSetState({ loading_chart: true });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getAgencyAccountsPerformanceChart(params);
                await this.promisedSetState({
                    chart: response.data,
                    doughnuts: response.meta,
                    total: response.meta.total
                });
            } catch (error) { }
            await this.promisedSetState({ loading_chart: false });
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    };

    renders = {
        comparedValue(org_number, preceding_number) {
            try {
                if (+preceding_number > 0) {
                    let increase = +org_number - +preceding_number;
                    let value = (+increase / +preceding_number) * 100;
                    return value.toFixed(2);;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        metricGoal(key) {
            let temp = false;
            if (temp) {
                if (key == "impressions" || key == "clicks" || key == "spend" || key == "ctr") {
                    return "highest"
                } else if (key == "cpc" || key == "cpm") {
                    return "lowest"
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        }
    }

    calls = {
        campaigns: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listConnectedCampaigns?ids=" + this.state.search_ids + "&limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=active" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        getAgencyAccountsPerformanceChart: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyAccountsPerformanceChart?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.getCampaigns();
                            this.functions.getAgencyAccountsPerformanceChart();
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_order: true
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getCampaigns();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getCampaigns();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getCampaigns();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.getCampaigns(false, value);
                                }
                            }, 400);
                        }}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={true}
                        hideUserDropdown={true}
                        breadcrumb={"Campaigns"}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        showClientLimited={false}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.getCampaigns();
                            this.functions.getAgencyAccountsPerformanceChart();
                            this.props.updateStatistics(client);
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaigns ...</div>
                    </div>
                }

                {/*CREATE DEFAULT METRIC*/}
                <SlideoutTailwind
                    title={this.state.campaign ? this.state.campaign.name : ""}
                    submitButtonText={"Close"}
                    full={true}
                    noPadding={true}
                    secondaryButton={false}
                    open={this.state.campaign ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ campaign: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            campaign: false
                        });
                    }}
                >
                    {
                        this.state.campaign &&
                        <CampaignOverview
                            campaign={this.state.campaign}
                        />
                    }
                </SlideoutTailwind>

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            <div className="grid grid-cols-5 gap-4 mb-4">
                                {
                                    ["impressions", "clicks", "spend", "CTR", "CPC"].map((metric) => {
                                        return (
                                            <div className="col-span-1 relative">
                                                {
                                                    this.state.loading_chart &&
                                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            disable_chart: true,
                                                            metric: metric
                                                        })
                                                        await this.functions.sleep(1000);
                                                        await this.promisedSetState({
                                                            disable_chart: false,
                                                        })
                                                    }}
                                                    className={"bg-white shadow rounded-lg relative px-4 py-6 flex w-full h-full cursor-pointer"}>
                                                    <div className="data--item__donut flex flex-row justify-between items-center w-full">
                                                        <div className="mr-4 leading-none">
                                                            <div className="font-bold text-xl md:text-xl xl:text-2xl">{this.state.total && this.state.total[metric.toLowerCase()] ? this.renders.addCommas(this.state.total[metric.toLowerCase()]) : "-"}</div>
                                                            <span className="text-xs xl:text-sm capitalize">{metric}</span>
                                                        </div>
                                                        <div className="flex">
                                                            <Doughnut
                                                                width={100}
                                                                height={100}
                                                                options={this.state.doughnut}
                                                                data={this.state.doughnuts[metric.toLowerCase()]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-4 pb-2 absolute bottom-0 left-0 right-0">
                                                        <div className={(this.state.metric == metric ? "bg-purple-500" : (this.state.hover == metric ? "bg-purple-200" : "bg-white")) + " h-0.5 w-full rounded"}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            this.state.chart &&
                            this.state.chart[this.state.metric.toLowerCase()] &&
                            <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col overflow-hidden relative">
                                {
                                    (this.state.loading_chart || this.state.disable_chart) &&
                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                <div className="p-4 pb-1 text-xs capitalize">
                                    {this.state.metric} per channel/day
                                </div>
                                <div className="w-full h-64 p-4">
                                    {
                                        !this.state.disable_chart &&
                                        <Line
                                            options={{
                                                tooltips: {
                                                    mode: 'index'
                                                },
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            fontSize: 8,
                                                            beginAtZero: true,
                                                            precision: 0,
                                                        }
                                                    }],
                                                    xAxes: [{
                                                        ticks: {
                                                            fontSize: 8
                                                        }
                                                    }]
                                                }
                                            }}
                                            data={this.state.chart[this.state.metric.toLowerCase()] ? this.state.chart[this.state.metric.toLowerCase()] : {}}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.campaigns.length === 0 &&
                            <div className='flex flex-col h-64 items-center justify-center bg-white rounded-lg shadow'>
                                <div className="mt-2 text-ls font-medium text-gray-500">No campaigns available</div>
                            </div>
                        }

                        <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col overflow-hidden">
                            {/*TABLE*/}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="relative overflow-x-auto table-overflow">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                {
                                                    this.state.columns.map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.getCampaigns();
                                                                    }
                                                                }}
                                                                style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(index === 0 ? "sticky z-50 left-0 bg-white " : "") + (item.border ? "border-l " : "") + (item.value == this.state.sort ? " bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? " hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                            >
                                                                <div className="flex flex-row items-center">
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex flex-col">
                                                                            <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.campaigns.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr
                                                                onMouseEnter={() => {
                                                                    item.hover = true;
                                                                    this.setState({
                                                                        campaigns: this.state.campaigns
                                                                    })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    item.hover = false;
                                                                    this.setState({
                                                                        campaigns: this.state.campaigns
                                                                    })
                                                                }}
                                                                className="border-b" key={item.id}>
                                                                {
                                                                    false &&
                                                                    <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-4 flex flex-row items-center justify-center  border-gray-300"}>
                                                                        <Menu as="div" className="relative inline-block text-left">
                                                                            <div>
                                                                                <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    {
                                                                                        item.loading &&
                                                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </Menu.Button>
                                                                            </div>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Menu.Items className="border absolute z-55 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                    <div className="py-1">
                                                                                        {
                                                                                            ["Open"].map((option) => {
                                                                                                return (
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.setState({
                                                                                                                        campaign: item
                                                                                                                    })
                                                                                                                }}
                                                                                                                className={cn(
                                                                                                                    active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                                )}
                                                                                                            >
                                                                                                                {option}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </Menu.Items>
                                                                            </Transition>
                                                                        </Menu>
                                                                    </td>
                                                                }
                                                                <td className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 " : "bg-white ") + " sticky z-50 left-0 border-r whitespace-no-wrap  px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="pl-2">
                                                                            {
                                                                                (item.channel === "facebook") &&
                                                                                < div
                                                                                    className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                    <div
                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.channel === "linkedin") &&
                                                                                <div
                                                                                    className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                    <div
                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.channel === "google") &&
                                                                                <div
                                                                                    className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                    <div
                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.channel === "tiktok") &&
                                                                                <div
                                                                                    className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                    <div
                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.channel === "twitter") &&
                                                                                <div
                                                                                    className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                    <div
                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                        style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="ml-3">
                                                                            {item.name ? item.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td onClick={() => {
                                                                    //this.props.history.push('/v2/clients/' + item.id);
                                                                }} className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50" : "") + " px-4 sm:px-6 border-r py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div>
                                                                            <div className="h-8 w-8 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                {
                                                                                    item.client &&
                                                                                    <img src={item.client.logo} className="bg-cover" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.client ? item.client.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={((this.state.sort === 'status' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    <span
                                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status.toString().toLowerCase() === "enabled" || item.status.toString().toLowerCase() === "active",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status.toString().toLowerCase() === "paused",
                                                                                ["bg-gray-100 text-gray-500 bg-opacity-50"]: item.status.toString().toLowerCase() !== "enabled" && item.status.toString().toLowerCase() !== "paused" && item.status.toString().toLowerCase() !== "active"
                                                                            })
                                                                        }>
                                                                        {typeof item.status == "string" && item.status !== "" ? item.status.toLowerCase() : "unknown"}
                                                                    </span>
                                                                </td>
                                                                <td className={((this.state.sort === 'start_date' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.start_date && item.start_date !== "unknown" ? moment(item.start_date).format("YYYY-MM-DD") : "unknown"}
                                                                </td>
                                                                <td className={((this.state.sort === 'end_date' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.end_date && item.end_date !== "unknown" ? moment(item.end_date).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                {
                                                                    false &&
                                                                    ['spend', 'impressions', 'clicks', 'ctr', 'cpc', 'cpm'].map((metric, index) => {
                                                                        return (
                                                                            <td className={(index !== 5 ? "border-r " : "") + ((this.state.sort === metric || item.hover) ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                                <div className="w-full h-full flex flex-row">
                                                                                    <div className="flex flex-1">{item[metric] ? item[metric] : "-"}</div>
                                                                                    {
                                                                                        ((this.renders.metricGoal(metric) === "highest" && this.renders.comparedValue(item[metric], item[metric + '_compare']) > 0) || (this.renders.metricGoal(metric) === "lowest" && this.renders.comparedValue(item[metric], item[metric + '_compare']) < 0)) &&
                                                                                        <div className="flex flex-row items-center">
                                                                                            <div className="bg-green-100 text-green-500 border-green-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                                {
                                                                                                    this.renders.comparedValue(item[metric], item[metric + '_compare']) > 0 &&
                                                                                                    <ArrowUpIcon className={"w-3"} />
                                                                                                }
                                                                                                {
                                                                                                    this.renders.comparedValue(item[metric], item[metric + '_compare']) < 0 &&
                                                                                                    <ArrowDownIcon className={"w-3"} />
                                                                                                }
                                                                                            </div>
                                                                                            <div className={"text-sm leading-5 text-green-500 truncate"}>
                                                                                                {this.renders.comparedValue(item[metric], item[metric + '_compare'])}%
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        ((this.renders.metricGoal(metric) === "lowest" && this.renders.comparedValue(item[metric], item[metric + '_compare']) > 0) || (this.renders.metricGoal(metric) === "highest" && this.renders.comparedValue(item[metric], item[metric + '_compare']) < 0)) &&
                                                                                        <div className="flex flex-row items-center">
                                                                                            <div className="bg-red-100 text-red-500 border-red-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                                {
                                                                                                    this.renders.comparedValue(item[metric], item[metric + '_compare']) > 0 &&
                                                                                                    <ArrowUpIcon className={"w-3"} />
                                                                                                }
                                                                                                {
                                                                                                    this.renders.comparedValue(item[metric], item[metric + '_compare']) < 0 &&
                                                                                                    <ArrowDownIcon className={"w-3"} />
                                                                                                }
                                                                                            </div>
                                                                                            <div className={"text-sm leading-5 text-red-500 truncate"}>
                                                                                                {this.renders.comparedValue(item[metric], item[metric + '_compare'])}%
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'spend' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.spend)} {item.client.currency}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'impressions' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.impressions ? item.impressions : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'clicks' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.clicks ? item.clicks : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'ctr' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.ctr)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'cpc' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.cpc)} {item.client.currency}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'cpm' || item.hover) ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.cpm)} {item.client.currency}
                                                                    </td>
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="flex flex-row p-4 items-center">
                                    <div className="flex justify-center items-center">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1) {
                                                    await this.promisedSetState({
                                                        page: this.state.page - 1
                                                    });
                                                    this.functions.getCampaigns();
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous
                                        </button>
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                        this.functions.getCampaigns();
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                }

            </div>
        )
    }
}

export default CampaignPerformanceList;
