import React, { Component } from 'react';
import './style.css';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import { BeatLoader } from "react-spinners";
import Dropdown from "../dropdown";
import Input from "../input";
import { calls } from "./calls";
import TwitterLogin from 'react-twitter-auth';
import OauthPopup from "react-oauth-popup";

class ClientTwitter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            refresh_token: null,
            loading: false,
            accounts: [],
            account: {},
            user_information: null,
            funding_instrument: {},
            funding_instruments: [],
            currency: "",
            promotable_users: [],
            user: {},
            loading_accounts: false,
            submenu: 0,
            twitter_url: "",
            twitter_params: ""
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        });
        //this.functions.authenticationUrl();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    functions = {
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.twitter;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        account: this.state.account,
                        user: this.state.user,
                        funding: this.state.funding_instrument,
                        access_token: this.state.access_token,
                        access_token_secret: this.state.access_token_secret,
                        user_information: this.state.user_information
                    }).then((response) => {
                        this.state.item.twitter = response.data;
                        this.setState({
                            access_token: null,
                            access_token_secret: null,
                            account: {},
                            accounts: [],
                            funding_instruments: [],
                            funding_instrument: {},
                            item: this.state.item,
                            loading_create: false,
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        accounts: (data) => {
            if (!this.state.loading_accounts) {
                this.setState({
                    loading_accounts: true
                }, () => {
                    calls.getAccounts(this.state.item.id, data.access_token, data.access_token_secret).then((response) => {
                        this.setState({
                            accounts: response.data,
                            loading_accounts: false,
                        })
                    }, (error) => {
                        this.setState({
                            loading_accounts: false
                        })
                    });
                })
            }
        },
        promotableUsers: () => {
            if (!this.state.loading_account) {
                this.setState({
                    loading_account: true
                }, () => {
                    calls.getPromotableUsers(this.state.item.id, this.state.access_token, this.state.access_token_secret, this.state.account.id).then((response) => {
                        this.setState({
                            promotable_users: Array.isArray(response.data) ? response.data.filter((item) => { return item.promotable_user_type == "FULL" }).map((item) => { item.name = item.user_id; return item }) : [],
                            loading_account: false,
                        })
                    }, (error) => {
                        this.setState({
                            loading_account: false
                        })
                    });
                })
            }
        },
        getAccount: () => {
            if (!this.state.loading_account) {
                this.setState({
                    loading_account: true
                }, () => {
                    calls.getAccount(this.state.item.id, this.state.access_token, this.state.access_token_secret, this.state.account.id).then((response) => {
                        this.setState({
                            user_information: response.data
                        })
                    }, (error) => {
                        this.setState({
                            loading_account: false
                        })
                    });
                })
            }
        },
        fundingInstruments: (data) => {
            if (!this.state.loading_fundinginstruments) {
                this.setState({
                    loading_fundinginstruments: true
                }, () => {
                    calls.getFundingInstruments(this.state.item.id, this.state.access_token, this.state.access_token_secret, this.state.account.id).then((response) => {
                        this.setState({
                            funding_instruments: response.data.map((item) => { item.name = item.description; return item }),
                            loading_fundinginstruments: false,
                        })
                    }, (error) => {
                        this.setState({
                            loading_fundinginstruments: false
                        })
                    });
                })
            }
        },
        remove: () => {
            this.setState({
                loading: true,
                remove_modal: false
            }, () => {
                calls.remove(this.state.item.id).then((response) => {
                    delete this.state.item.twitter;
                    this.setState({
                        access_token: null,
                        access_token_secret: null,
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });
                });
            });
        },
        authenticationUrl: async () => {
            let response = await calls.authenticationUrl();
            this.promisedSetState({
                twitter_url: response
            });
            //await calls.authenticationVerifier(response);
        },
        authenticationVerifier: async () => {
            await this.promisedSetState({ loading_token: true });
            var url = new URL(this.state.twitter_url);
            var oauth_token = url.searchParams.get("oauth_token");
            var oauth_verifier = url.searchParams.get("oauth_verifier");
            var oauth_token_secret = url.searchParams.get("oauth_token_secret");
            console.log(oauth_token);
            console.log(oauth_verifier);
            console.log(oauth_token_secret);
            let response = await calls.authenticationVerifier({
                oauth_token: oauth_token,
                oauth_verifier: oauth_verifier
            });
            await this.promisedSetState({
                access_token: response.access_token,
                access_token_secret: response.access_token_secret,
                loading_token: false
            });
            this.functions.accounts(response);
        }
    };

    renders = {
        submenus: () => {
            let array = [
                { id: 0, name: "Add adaccount" }
            ];
            return array;
        }
    };

    handleSuccess = async (data) => {
        console.log(data);
        if (data.status >= 200 && data.status < 300) {
            let response = await data.json();
            this.setState({
                access_token: response.access_token,
                access_token_secret: response.access_token_secret
            }, () => {
                this.functions.accounts(response);
            })
        }
    };

    handleFailure = (error) => {
        console.log(error);
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Twitter"
                    type="warning"
                    text={"You wont be able access any Twitter campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({ remove_modal: false });
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.access_token && !this.state.item.twitter &&
                            <TwitterLogin
                                forceLogin={true}
                                style={{ width: '100%' }}
                                loginUrl={calls.url.api + "/v3/twitter/authenticationVerifier"}
                                onFailure={this.handleFailure}
                                onSuccess={this.handleSuccess}
                                requestTokenUrl={calls.url.api + "/v3/twitter/authenticationUrl"}
                            >
                                <div className="Button ButtonFull ButtonGoogle w-full">
                                    {
                                        !this.state.loading &&
                                        <div>signin Twitter</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </TwitterLogin>
                        }
                        {
                            !this.state.access_token && !this.state.item.twitter &&
                            <div className="Section">
                                <Input
                                    title={'Twitter login params'}
                                    type={'text'}
                                    value={this.state.twitter_url}
                                    size={'full'}
                                    onChange={(event) => {
                                        this.setState({
                                            twitter_url: event.target.value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            !this.state.access_token && !this.state.item.twitter &&
                            <div className="Section">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_token) {
                                            this.functions.authenticationVerifier()
                                        }
                                    }}
                                    className={(!this.state.disabled ?
                                        "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    Fetch token
                                    {
                                        this.state.loading_token &&
                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        }
                        {
                            this.state.access_token && !this.state.item.twitter &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Dropdown
                                    filter={false}
                                    title={'Select account'}
                                    size={'full'}
                                    placeholder={(!this.state.loading_accounts && this.state.accounts.length < 1) ? "No accounts available" : (this.state.loading_accounts ? "Loading accounts" : "Click here ..")}
                                    error={this.state.error === "manager" && !this.state.account.name}
                                    options={this.state.accounts}
                                    loading={this.state.loading_accounts}
                                    removeArrow={!this.state.loading_accounts && this.state.accounts.length < 1}
                                    value={this.state.account}
                                    onChange={(value) => {
                                        this.setState({
                                            account: value
                                        }, () => {
                                            this.functions.promotableUsers();
                                            this.functions.getAccount();
                                            this.functions.fundingInstruments();
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.access_token && !this.state.item.twitter && this.state.account.id &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select promotable user'}
                                    size={'full'}
                                    placeholder={(!this.state.loading_account && this.state.promotable_users.length < 1) ? "No users available" : (this.state.loading_account ? "Loading users" : "Click here ..")}
                                    options={this.state.promotable_users}
                                    loading={this.state.loading_account}
                                    removeArrow={!this.state.loading_account && this.state.promotable_users.length < 1}
                                    value={this.state.user}
                                    onChange={(value) => {
                                        this.setState({
                                            user: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.access_token && !this.state.item.twitter && this.state.account.id && this.state.user.id &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select funding'}
                                    size={'full'}
                                    placeholder={(!this.state.loading_fundinginstruments && this.state.funding_instruments.length < 1) ? "No fundings available" : (this.state.loading_fundinginstruments ? "Loading fundings" : "Click here ..")}
                                    options={this.state.funding_instruments}
                                    loading={this.state.loading_fundinginstruments}
                                    removeArrow={!this.state.loading_fundinginstruments && this.state.funding_instruments.length < 1}
                                    value={this.state.funding_instrument}
                                    onChange={(value) => {
                                        this.setState({
                                            funding_instrument: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.item.twitter &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Input
                                    title={'adaccount'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.twitter.adaccountName}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.item.twitter &&
                            <div className="Section">
                                <Input
                                    title={'user id'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.twitter.userId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.item.twitter &&
                            <div className="Section">
                                <Input
                                    title={'Funding source'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.twitter.fundingInstrumentName}
                                    size={'full'}
                                />
                            </div>
                        }
                        {!this.state.loading_accounts && this.state.access_token && !this.state.item.twitter && this.state.account.id && this.state.funding_instrument.id && this.state.user.id &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>add Twitter account</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {this.state.item.twitter &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>signout Twitter</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {this.state.access_token && !this.state.item.twitter &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        access_token: null,
                                        access_token_secret: null,
                                        account: {},
                                        accounts: []
                                    })} className="Button ButtonFull ButtonGoogle">
                                        signout Twitter
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientTwitter;
