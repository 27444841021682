import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Client from "../../components/client";
import {calls} from "./calls";
import User from "../../components/user";
import Dropdown from "../../components/dropdown";
import {userRegister} from "../../services/userRegister";
import UsersList from "../../modules/userslist";
import CampaignsList from "../../modules/campaignslist";

class UserUsersAllAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            email: "",
            loading: true,
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.users();
        window.scrollTo(0, 0);
    }

    init = {
        users: () => {
            calls.getUsers(userRegister.get().agency).then((response) => {
                this.setState({
                    users: response.data,
                    loading: false
                })
            }, (error) => {});
        }
    };

    functions = {
        remove: (id) => {
            for(let i = 0; i < this.state.users.length; i++){
                if(this.state.users[i]._id === id){
                    this.state.users.splice(i, 1);
                    break;
                }
            }
            this.setState({
                users: this.state.users
            })
        }
    };

    renders = {};

    validators = {};

    render(){
        return (
            <section className="w-full pt-8">
                <SweetAlert
                    show={this.state.success_modal}
                    title="Agency removed"
                    type="success"
                    text={"Click OK"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            success_modal: false
                        })
                    }}
                />
                <div className="">
                    <div className="">
                        {
                            !this.state.loading &&
                            <div className="flex items-center justify-center">
                                <div className="flex flex-1">
                                    <div>
                                        <h3 className="mb-0 font-bold">
                                            All users
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            There are <span
                                            className="font-bold ml-1 mr-1">{this.state.users.length}</span> users
                                            available
                                        </p>
                                    </div>
                                </div>
                                <a href="#" onClick={(e) => {
                                    try {
                                        e.preventDefault();
                                        this.props.history.push('/user/users/new');
                                    } catch (e) {}
                                }} className="btn inline-block btn-primary">+ Create user</a>
                            </div>
                        }
                        {
                            !this.state.loading &&
                            <UsersList
                                properties={{name: true, email: true, status: true}}
                                items={this.state.users.filter((item) => {
                                    return item.email.indexOf(this.state.email) !== -1;
                                }).sort((a, b) => {
                                    return  b.createdAt - a.createdAt;
                                })}
                            />
                        }
                        {
                            this.state.loading &&
                            <div className="background-Loader">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={24}
                                    color={'#060534'}
                                    loading={true}
                                />
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

/*
<div className="Profile">
                <div className="Profile-Inner">
                    <div className="background">
                        {
                            !this.state.loading &&
                            <div className="background-Inner">
                                {
                                    this.state.users.filter((item) => {
                                        return item.email.indexOf(this.state.email) !== -1;
                                    }).sort((a, b) => {
                                        return  b.createdAt - a.createdAt;
                                    }).map((item) => {
                                        return (
                                            <User
                                                item={item}
                                                onRemove={(id) => {
                                                    this.functions.remove(id);
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.loading &&
                            <div className="background-Loader">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={30}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            !this.state.loading && this.state.users.length < 1 &&
                            <div className="background-Loader">
                                <div className="background-LoaderText">
                                    You dont have any users yet
                                </div>
                                <div onClick={() => this.props.history.push("/user/users/new")} className="background-LoaderButton">
                                    Lets get started
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
 */

export default UserUsersAllAgent;
