import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCountries(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?search="+encodeURI(data.search)+'&level=4';
        return apiRegister.call(options, url);
    }

    static getRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?search="+encodeURI(data.search);
        return apiRegister.call(options, url);
    }

    static postRequest(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', null);
        let url = calls.url.api + "/region/request?region="+encodeURI(data.region);
        return apiRegister.call(options, url);
    }

    static getRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal/"+data.region;
        return apiRegister.call(options, url);
    }

}

export {calls}