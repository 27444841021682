import React, { Component } from 'react';
import './style.css';
import { SlideDown } from 'react-slidedown'
import 'sweetalert/dist/sweetalert.css'
import Dropdown from "../dropdown";
import Input from "../input";
import TextArea from "../text-area";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import AdvertisingCreativesInstagramCarousel from "../advertising-creatives-instagram-carousel";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import FeatherIcon from "feather-icons-react";
import { Carousel } from "react-responsive-carousel";

class AdvertisingCreativesInstagram extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: this.props.client,
            creative: this.props.creative,
            variations: this.props.variations,
            preview: this.props.preview,
            type: this.props.type,
            site: this.props.site,
            language: {},
            campaign: {},
            call_to_action: this.props.callToAction,
            locations: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            creative: nextProps.creative,
            preview: nextProps.preview,
            type: nextProps.type,
            language: nextProps.language,
            variations: nextProps.variations,
            campaign: nextProps.campaign,
            call_to_action: nextProps.callToAction,
            locations: nextProps.locations
        });
    }

    componentWillMount() { }

    componentDidMount() {
        this.setState({
            client: this.props.client,
            creative: this.props.creative,
            variations: this.props.variations,
            preview: this.props.preview,
            type: this.props.type,
            site: this.props.site,
            language: this.props.language,
            campaign: this.props.campaign,
            call_to_action: this.props.callToAction,
            locations: this.props.locations,
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        images: () => {
            try {
                return this.state.creative.image_ads ? this.state.creative.image_ads.filter((item) => {
                    return !item.facebook_event && this.state.type === 'image' && !item.error && item.creative.ratio !== '9:16' && item.internal_adset.campaign_id === this.state.campaign.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        videos: () => {
            try {
                return this.state.creative.video_ads ? this.state.creative.video_ads.filter((item) => {
                    return !item.facebook_event && this.state.type === 'video' && !item.error && item.creative.ratio !== '9:16' && item.internal_adset.campaign_id === this.state.campaign.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        carousels: () => {
            try {
                if (this.state.type === 'carousel') {
                    return this.state.creative.carousel_ads ? this.state.creative.carousel_ads.filter((item) => {
                        return item.internal_adset.campaign_id === this.state.campaign.id
                    }) : [];
                } else {
                    return []
                }
            } catch (e) {
                return []
            }
        },
        dynamic: () => {
            try {
                if (this.state.type === 'dynamic') {
                    return this.state.creative.dynamic_ads ? this.state.creative.dynamic_ads.filter((item) => {
                        return item.internal_adset.campaign_id === this.state.campaign.id
                    }) : [];
                } else {
                    return []
                }
            } catch (e) {
                return []
            }
        },
        posts: () => {
            try {
                if (this.state.type === 'post') {
                    return this.state.creative.post_ads ? this.state.creative.post_ads.filter((item) => {
                        return item.internal_adset.campaign_id === this.state.campaign.id
                    }) : [];
                } else {
                    return []
                }
            } catch (e) {
                console.log(e);
                return []
            }
        },
        vertical: () => {
            try {
                let images = this.state.creative.image_ads?this.state.creative.image_ads.filter((item) => {
                    return !item.error && item.creative && item.creative.ratio == '9:16' && this.state.type === 'image' && item.internal_adset.campaign_id === this.state.campaign.id
                }):[];
                let videos = this.state.creative.video_ads?this.state.creative.video_ads.filter((item) => {
                    return !item.error && item.creative && item.creative.ratio == '9:16' && this.state.type === 'video' && item.internal_adset.campaign_id === this.state.campaign.id
                }):[];
                console.log(videos.length)
                return images.concat(videos);
            }catch (e) {
                console.log(e);
                return []
            }
        },
        page: (channel) => {
            try {
                let page = {};
                if (channel === "facebook") {
                    page = {
                        name: this.state.client[channel].facebookPageName,
                        image: this.state.client[channel].facebookPageImage
                    };
                } else if (channel === "instagram") {
                    page = {
                        name: this.state.client['facebook'].facebookPageName,
                        image: this.state.client['facebook'].facebookPageImage
                    };
                }
                //this.state.client);
                //console.log(page);
                return page
            } catch (e) {
                return {}
            }
        },
        item: (item) => {
            try {
                let array = [];
                array.push(item);
                return array;
            } catch {
                return []
            }
        },
        postType: (item) => {
            try {
                if (item.facebook_post.attachments.data[0].type == "video_inline") {
                    return "video";
                } else {
                    return "image";
                }
            } catch (error) {
                return false;
            }
        },
        location: (item) => {
            let adset = {};
            let location = null;
            this.state.creative.adsets.map((inner_adset) => {
                if (item.internal_adset && item.internal_adset.id == inner_adset.id) {
                    adset = inner_adset;
                }
            })
            if (adset.exclude_locations) {
                this.state.locations.map((inner_location) => {
                    if (inner_location && !adset.exclude_locations[inner_location.id] && inner_location.channels && inner_location.channels.facebook && !location) {
                        location = inner_location;
                    }
                })
            } else {
                this.state.locations.map((inner_location) => {
                    if (inner_location && inner_location.channels && inner_location.channels.facebook && !location) {
                        location = inner_location;
                    }
                })
            }
            return location ? location.name : "{location}";
        }
    };

    render() {
        return (
            <div>
                <div>
                    <div className="flex flex-1 flex-row overflow-scroll">
                        {
                            this.renders.images().map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramImage
                                            page={this.renders.page('instagram')}
                                            creatives={this.renders.item(item)}
                                            language={this.state.language.code}
                                            noContainer={true}
                                            location={this.renders.location(item)}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.videos().map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramVideo
                                            page={this.renders.page('instagram')}
                                            creatives={this.renders.item(item)}
                                            language={this.state.language.code}
                                            noContainer={true}
                                            location={this.renders.location(item)}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.carousels().map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramCarousel
                                            page={this.renders.page('instagram')}
                                            body={item}
                                            content={item}
                                            language={this.state.language.code}
                                            noContainer={true}
                                            noHighlight={true}
                                            showArrows={false}
                                            onChange={(value) => {

                                            }}
                                            location={this.renders.location(item)}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.dynamic().map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramCarousel
                                            page={this.renders.page('instagram')}
                                            body={item}
                                            dynamic={true}
                                            content={item}
                                            language={this.state.language.code}
                                            noContainer={true}
                                            noHighlight={true}
                                            showArrows={false}
                                            autoLoop={true}
                                            onChange={(value) => {

                                            }}
                                            location={this.renders.location(item)}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.posts().filter((item) => {
                                return (item.instagram_post && item.instagram_post.media_type === "IMAGE") || (item.facebook_post && this.renders.postType(item) === "image")
                            }).map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramImage
                                            page={this.renders.page('instagram')}
                                            creatives={this.renders.item(item)}
                                            language={this.state.language.code}
                                            noContainer={true}
                                            post={true}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.posts().filter((item) => {
                                return (item.instagram_post && item.instagram_post.media_type === "VIDEO") || (item.facebook_post && this.renders.postType(item) === "video")
                            }).map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesInstagramVideo
                                            page={this.renders.page('instagram')}
                                            creatives={this.renders.item(item)}
                                            language={this.state.language.code}
                                            post={true}
                                            noContainer={true}
                                        />
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.vertical().map((item) => {
                                return (
                                    <div className="mr-2">
                                        <AdvertisingCreativesVertical
                                            page={this.renders.page('instagram')}
                                            creatives={this.renders.item(item)}
                                            language={this.state.language.code}
                                            noContainer={true}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesInstagram;
