import React, {Component} from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";

class CampaignShortcuts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            loading: true
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            loading: nextProps.loading
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            loading: this.props.loading
        })
    }

    init = {};

    functions = {
        client: () => {},
        report: () => {

        },
        optimize: (type) => {
            let query = 'campaign=' + this.state.campaign.id;
            this.props.history.push('/user/optimize/all?' + query + '&type=' + type);
        }
    };

    renders = {
        channels: () => {
            try{
                return Object.keys(this.state.campaign.channels).filter((channel) => {return this.state.campaign.channels[channel]});
            }catch (e) {
                return []
            }
        },
        search: () => {
            try{
                return Object.keys(this.state.campaign.channels).filter((channel) => {return this.state.campaign.channels[channel] && channel === 'google'});
            }catch (e) {
                return []
            }
        },
        social: () => {
            try{
                return Object.keys(this.state.campaign.channels).filter((channel) => {return this.state.campaign.channels[channel] && (channel === 'facebook' || channel === 'instagram')});
            }catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <div className='campaign-shortcuts'>
                <div className='campaign-shortcuts-top'>
                    SHORTCUTS
                </div>
                <div className="campaign-shortcuts-inner">
                    <div style={(this.renders.search().length > 0 && this.renders.social().length > 0)?{width: 'calc(25% - 10px)'}:{width: 'calc(33% - 5px)'}}>
                        <div className="Button ButtonNeutral ButtonFull">
                            Open client
                        </div>
                    </div>
                    <div style={(this.renders.search().length > 0 && this.renders.social().length > 0)?{width: 'calc(25% - 10px)'}:{width: 'calc(33% - 5px)'}}>
                        <div className="Button ButtonNeutral ButtonFull">
                            Report
                        </div>
                    </div>
                    {
                        this.renders.search().length > 0 &&
                        <div style={(this.renders.search().length > 0 && this.renders.social().length > 0)?{width: 'calc(25% - 10px)'}:{width: 'calc(33% - 5px)'}}>
                            <div onClick={() => {
                                this.functions.optimize('search');
                            }} className="Button ButtonNeutral ButtonFull">
                                Optimize Search
                            </div>
                        </div>
                    }
                    {
                        this.renders.social().length > 0 &&
                        <div style={(this.renders.search().length > 0 && this.renders.social().length > 0)?{width: 'calc(25% - 10px)'}:{width: 'calc(33% - 5px)'}}>
                            <div onClick={() => {
                                this.functions.optimize('social');
                            }} className="Button ButtonNeutral ButtonFull">
                                Optimize Social
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignShortcuts;
