import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import OptimizeSocialItem from "../optimize-social-item";
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import SocialAdsList from "../../modules/socialadslist";
import NegativeKeywordList from "../../modules/negativekeywordlist";

class OptimizeSocial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            channels: [],
            ads: [],
            open: true,
            loading: false,
            sort: 'click',
            order: 'desc',
            navToggleActive: "ads",
            navToggle: [
                {
                    title: 'ads',
                    id: 'ads',
                    icon: 'bar-chart-2'
                }
            ]
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign
        }, () => {
            this.init.ads();
        });
    }

    init = {
        ads: () => {
            let channels = [];
            if (!this.state.campaign.channels.fb_insta_combined) {
                for (let key in this.state.campaign.ads) {
                    if (key === 'facebook' || key === 'instagram') {
                        if (this.state.campaign.ads[key].length > 0) {
                            channels.push({name: key, value: key});
                        }
                        for (let i = 0; i < this.state.campaign.ads[key].length; i++) {
                            this.state.ads.push(this.state.campaign.ads[key][i]);
                        }
                    }
                }
            } else {
                try {
                    channels.push({name: 'Facebook and Instagram', value: 'facebook', extra: 'instagram'});
                    for (let i = 0; i < this.state.campaign.ads.facebook.length; i++) {
                        this.state.campaign.ads.facebook[i].combined = true;
                        this.state.ads.push(this.state.campaign.ads.facebook[i]);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            this.setState({
                channels: channels
            })
        }
    };

    renders = {};

    render() {
        return (
            <div>
                {
                    <div>
                        <nav className="py-2">
                            <ul className="flex flex-row items-center overflow-scroll w-full whitespace-no-wrap">
                                {this.state.navToggle.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <button onClick={() => {
                                                this.setState({
                                                    navToggleActive: item.id,
                                                    selected: item.id
                                                }, () => {
                                                    if (this.state.navToggleActive === 'external') {

                                                    }
                                                })
                                            }}
                                                    className={cx("cursor-pointer py-2 px-4 hover:opacity-100 font-bold text-sm border-b-2 flex flex-row items-center focus:outline-none", {
                                                        ["border-purple-500 opacity-100 text-purple-500"]: item.id === this.state.navToggleActive,
                                                        ["border-gray-300 opacity-50"]: item.id != this.state.navToggleActive
                                                    })}>
                                                <FeatherIcon className="stroke-current mr-2" size={15}
                                                             icon={item.icon}/>
                                                <span className="text-gray-900">{item.title}</span>
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                }
                {
                    /*
                    <div className="Optimize-Search-Sort">
                    <div className="Optimize-Search-Sort-Left">
                        <Dropdown
                            filter={false}
                            title={'Sort'}
                            size={'half'}
                            tiny={true}
                            disabled={this.state.ads.length < 1}
                            placeholder={"Select here.."}
                            options={[
                                {name: 'channel (default)', value: 'channel'},
                                {name: 'type', value: 'type'},
                                {name: 'name', value: 'name'},
                                {name: 'impressions', value: 'impressions'},
                                {name: 'click', value: 'click'},
                                {name: 'ctr', value: 'ctr'},
                                {name: 'cpc', value: 'cpc'},
                                {name: 'spend', value: 'spend'},
                                {name: 'conversions', value: 'conversions'},
                                {name: 'cost pr con.', value: 'cost_pr_conversion'}
                            ]}
                            value={this.state.sort}
                            onChange={(value) => {
                                this.setState({
                                    sort: value
                                })
                            }}
                        />
                        <Dropdown
                            filter={false}
                            title={'Order'}
                            size={'half'}
                            tiny={true}
                            disabled={this.state.ads.length < 1}
                            placeholder={"Select here.."}
                            options={[
                                {name: 'Ascending', value: 'ascend'},
                                {name: 'Descending', value: 'descend'}
                            ]}
                            value={this.state.order}
                            onChange={(value) => {
                                this.setState({
                                    order: value
                                })
                            }}
                        />
                    </div>
                    <Dropdown
                        filter={false}
                        title={'Filter channel'}
                        size={'half'}
                        tiny={true}
                        disabled={this.state.ads.length < 1}
                        placeholder={"Select here.."}
                        options={[{
                            name: 'Show all',
                            value: null
                        }].concat(this.state.channels)}
                        value={this.state.filter}
                        onChange={(value) => {
                            this.setState({
                                filter: value
                            })
                        }}
                    />
                </div>
                     */
                }
                {
                    <SocialAdsList
                        campaign={this.state.campaign}
                        campaignitems={this.state.ads}
                        properties={{
                            channels: true,
                            name: true,
                            type: true,
                            status: true,
                            click: true,
                            impressions: true,
                            ctr: true,
                            cpc: true,
                            spend: true,
                            conversions: true,
                            actions: {delete: true}
                        }}
                        sort={this.state.sort}
                        order={this.state.order}
                        pagesize={this.state.pagesize}
                        updateSort={(sort, order) => {
                            this.setState({
                                sort: sort,
                                order: order
                            })
                        }}
                        updateItems={(value) => {
                            this.setState({
                                ads: value
                            })
                        }}
                    />
                }
                <div>
                    {
                        /*
                        this.state.ads.filter((item) => {
                            if (this.state.filter.value) {
                                return item.channel === this.state.filter.name
                            } else {
                                return true
                            }
                        }).sort((a, b) => {
                            if (this.state.sort.name) {
                                var nameA = null;
                                var nameB = null;
                                try {
                                    nameA = a[this.state.sort.value].toLowerCase();
                                } catch (e) {
                                    nameA = a[this.state.sort.value];
                                }
                                try {
                                    nameB = b[this.state.sort.value].toLowerCase();
                                } catch (e) {
                                    nameB = b[this.state.sort.value];
                                }
                                if (this.state.order.value === 'descend') {
                                    if (nameA > nameB)
                                        return -1;
                                    if (nameA < nameB)
                                        return 1;
                                    return 0
                                } else {
                                    if (nameA < nameB)
                                        return -1;
                                    if (nameA > nameB)
                                        return 1;
                                    return 0
                                }
                            } else {
                                return 1
                            }
                        }).map((item, index) => {
                            return (
                                <OptimizeSocialItem
                                    item={item}
                                    sort={this.state.sort}
                                    channels={this.state.channels}
                                    onChange={(value) => {
                                        item = value;
                                        this.setState({
                                            ads: this.state.ads
                                        });
                                    }}
                                />
                            )
                        })

                         */
                    }
                </div>
            </div>
        )
    }
}

export default OptimizeSocial;
