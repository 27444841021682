import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import Switch from "../advertising-channels";
import {calls} from "./calls";
import {tokenRegister} from "../../services/tokenRegister";
import {userRegister} from "../../services/userRegister";
import UploadImage from "../image-upload";

class ClientSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        remove: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    warning_modal: false,
                    loading_title: "Removing client"
                }, () => {
                    calls.removeClient(this.state.item.id).then((response) => {
                        this.props.onRemove();
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            loading: false
                        })
                    });
                })
            }
        },
        update: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    warning_modal: false,
                    loading_title: "Updating client"
                }, () => {
                    let client = {
                        name: this.state.item.name,
                        company: this.state.item.company,
                        logo: this.state.item.logo,
                        email: this.state.item.email
                    };
                    calls.updateClient(client, this.state.item.id).then((response) => {
                        this.setState({
                            loading: false,
                            edit: false,
                            item: response.data
                        })
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            edit: false,
                            loading: false
                        })
                    });
                })
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="ClientSettings">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove client"
                    type="warning"
                    text={"All campaigns and users connected to this client will be removed"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div className={
                    cx("FacebookClientTop",
                        {
                            ["Site-InnerContainerInnerTopOpen"]: false,
                        })
                } style={{padding: '15px', boxSizing: 'border-box'}}>
                    <div onClick={() => {
                        this.setState({open: !this.state.open})
                    }} className="FacebookClientTop-InnerContainerInnerTop">
                        <div className="FacebookClientTop-InnerContainerInnerTopTitle">
                            Settings
                        </div>
                        <div className={
                            cx("Client-InnerContainerInnerTopArrowContainer",
                                {
                                    ["Site-InnerContainerInnerTopArrowContainerOpen"]: false,
                                })
                        }>
                            <FeatherIcon className={
                                cx("FacebookClientTop-InnerContainerInnerTopArrow",
                                    {
                                        ["FacebookClientTop-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={20} icon="plus"/>
                            <div className="FacebookClientTop-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("FacebookClientTop-InnerContainerInnerTopMinus",
                                        {
                                            ["FacebookClientTop-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="minus"/>
                            </div>
                        </div>
                    </div>
                </div>
                <SlideDown closed={!this.state.open}>
                    <div style={{padding: '15px', paddingTop: '20px'}} className="Section">
                        <Input
                            title={'Name'}
                            value={this.state.item.name}
                            error={this.state.error === "name"}
                            placeholder={""}
                            disabled={!this.state.edit}
                            size={'half'}
                            type={"text"}
                            onChange={(value) => {
                                this.state.item.name = value.target.value;
                                this.setState({item: this.state.item});
                            }}
                        />
                        <Input
                            title={'Company'}
                            value={this.state.item.company}
                            error={this.state.error === "company"}
                            placeholder={""}
                            disabled={!this.state.edit}
                            size={'half'}
                            type={"text"}
                            onChange={(value) => {
                                this.state.item.company = value.target.value;
                                this.setState({item: this.state.item});
                            }}
                        />
                    </div>
                    <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                        <Input
                            title={'Email'}
                            value={this.state.item.email}
                            error={this.state.error === "email"}
                            placeholder={""}
                            disabled={!this.state.edit}
                            size={'full'}
                            type={"text"}
                            onChange={(value) => {
                                this.state.item.email = value.target.value;
                                this.setState({item: this.state.item});
                            }}
                        />
                    </div>
                    <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                        <UploadImage
                            title={"Upload logo (png)"}
                            size={"full"}
                            cover={false}
                            logo={true}
                            value={this.state.item.logo}
                            onChange={(url) => {
                                this.state.item.logo = url;
                                this.setState({
                                    item: this.state.item
                                });
                            }}
                        />
                    </div>
                    <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                        {
                            this.state.edit &&
                            <div onClick={() => {
                                this.functions.update();
                            }} className="Button ButtonNeutral">
                                Save
                            </div>
                        }
                        {
                            !this.state.edit &&
                            <div onClick={() => {
                                this.setState({
                                    edit: true
                                })
                            }} className="Button ButtonNeutral">
                                Edit
                            </div>
                        }
                        <div onClick={() => {
                            this.setState({
                                warning_modal: true
                            })
                        }} className="Button ButtonRed">
                            Remove
                        </div>
                    </div>
                </SlideDown>
            </div>
        )
    }
}

export default ClientSettings;
