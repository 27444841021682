import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";

class OptimizeSearchItemNegative extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adset: this.props.adset ? this.props.adset : {},
            item: this.props.item ? this.props.item : {},
            hide: this.props.hide,
            sort: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            adset: nextProps.adset,
            item: nextProps.item,
            hide: nextProps.hide
        })
    }

    componentDidMount() {
        this.setState({
            adset: this.props.adset,
            item: this.props.item
        });
    }

    init = {};

    functions = {
        remove: () => {
            this.state.item.checked = true;
            this.state.item.loading_remove = true;
            this.setState({
                item: this.state.item,
            }, () => {
                calls.removeNegative(this.state.adset.internal, this.state.adset.id, this.state.item.criterion_id).then((response) => {
                    this.props.onRemove(this.state.item.criterion_id);
                }, (error) => {
                    this.setState({
                        checked: false
                    })
                })
            })
        }
    };

    renders = {};

    render() {
        return (
            <div className="Optimize-ContainerListItem">
                <div className="Optimize-ContainerListItemChannelQuery">
                    <FeatherIcon size={20} color={'#333333'} icon={'x-circle'}/>
                </div>
                <div className="Optimize-ContainerListItemValues">
                    {
                        this.state.item.loading &&
                        <div className="Optimize-ContainerListItemValuesLoader">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        this.state.item.loading_remove &&
                        <div className="Optimize-ContainerListItemValuesLoaderInternal">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        !this.state.item.loading && this.state.hide &&
                        <div className="Optimize-ContainerListItemValuesEmpty">
                            No negative keywords
                        </div>
                    }
                    <div className="Optimize-ContainerListItemValuesInner">
                        <div className="Optimize-ContainerListItemValuesInnerItem Optimize-ContainerListItemValuesInnerItemKeywordType">
                            <div className="Optimize-ContainerListItemValuesInnerItemTitle">
                                Type
                            </div>
                            <div className="Optimize-ContainerListItemValuesInnerItemValue">
                                {this.state.item.type}
                            </div>
                        </div>
                        <div className="Optimize-ContainerListItemValuesInnerItem">
                            <div
                                className="Optimize-ContainerListItemValuesInnerItemTitle">
                                Name
                            </div>
                            <div
                                className="Optimize-ContainerListItemValuesInnerItemValue">
                                {this.state.item.text}
                            </div>
                        </div>
                        <div className="Optimize-ContainerListItemSlider Optimize-ContainerListItemSliderQuery">
                            <Switch
                                onColor="#24bda8"
                                checked={!this.state.item.checked}
                                onChange={(value) => {
                                    this.functions.remove();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OptimizeSearchItemNegative;
