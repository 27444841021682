import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";
import Input from "../../components/input";
import Dropdown from "../../components/dropdown";
import DropDown from "../dropdown";
import Switch from "react-ios-switch";

class SearchtermList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            campaignitems: [],
            sort: "",
            order: "",
            total: 0,
            pagesize: 0,
            type: {name: 'Exact match', value: 'exact'},
            level: {name: 'Add to Campaign', value: 'campaign'}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            campaignitems: this.props.campaignitems,
            sort: this.props.sort,
            order: this.props.order,
            total: this.props.total,
            pagesize: this.props.pagesize
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            campaignitems: nextProps.campaignitems,
            sort: nextProps.sort,
            order: nextProps.order,
            total: nextProps.total,
            pagesize: nextProps.pagesize
        })
    }

    functions = {
        addNegative: (item) => {
            item.loading = true;
            this.setState({
                campaignitems: this.state.campaignitems,
            }, () => {
                calls.createNegative({
                    keyword: this.state.query,
                    type: this.state.type.value,
                    adgroup_id: item.adgroup_id,
                    campaign_id: this.state.campaign.id,
                    level: this.state.level.value,
                    channels: {google: true}
                }).then((response) => {
                    item.loading = false;
                    item.open = false;
                    this.setState({
                        campaignitems: this.state.campaignitems
                    }, () => {
                        item.negative_keywords = {};
                        for(let key in response.data){
                            item.negative_keywords[key] = {};
                            item.negative_keywords[key] = response.data[key];
                            if(!response.data[key].error){
                                item.negative_keywords[key].channel = key;
                                item.negative_keywords[key].adgroup_id = item.adgroup_id;
                                item.negative_keywords[key].criterion_id = response.data[key].criterion_id;
                                item.negative_keywords[key].type = this.state.type.value;
                                item.negative_keywords[key].level = this.state.level.value;
                                item.negative_keywords[key].text = this.state.query;
                            }
                        }
                        try{
                            this.props.onRemove(item);
                        }catch (e) {}
                    })
                }, (error) => {
                    item.loading = false;
                    this.setState({
                        campaignitems: this.state.campaignitems
                    })
                });
            })
        }
    };

    renders = {
        ending: (key, item) => {
            if (key === 'ctr') {
                return "%"
            } else if (key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion') {
                return " " + item.currency
            } else {
                return "";
            }
        },
        campaigns: () => {
            let campaigns = [];
            try{
                if(this.state.campaign.external_campaigns){
                    for(let key in this.state.campaign.external_campaigns){
                        if(key === "google"){
                            this.state.campaign.external_campaigns[key].map((item) => {
                                campaigns.push(item);
                            })
                        }
                    }
                }
            }catch (e) {
                campaigns.push({id: this.state.campaign.external_id, name: ""});
            }
            return campaigns;
        }
    };

    render() {
        return (
            <div className="flex flex-col">
                {this.state.campaignitems.length > 0 && this.props.headline &&
                <h3 className="font-bold text-sm mb-2">{this.props.headline}</h3>
                }
                {this.state.campaignitems.length > 0 &&
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                <tr>
                                    <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions{false&&<div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                    {/** Exempel på class som sätts vid aktiv: sort-desc eller sort-asc på th-nivå */}
                                    {
                                        this.props.properties && this.props.properties.channel &&
                                        <th className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'query' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                Channel
                                            </div>
                                        </th>
                                    }
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('campaign', 'campaign' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'campaign' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Campaign <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('query', 'query' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'query' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Query <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    {this.props.properties && this.props.properties.type &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('type', 'type' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Type <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.keyword &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('keyword', 'keyword' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'keyword' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Keyword <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {
                                        <th onClick={() => {
                                            try{
                                                this.props.updateSort('conversions', 'conversions' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                            }catch (e) {}
                                        }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'conversions' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                Conversions <div className="sorting-arrows ml-2"></div>
                                            </div>
                                        </th>
                                    }
                                    {this.props.properties && this.props.properties.impressions &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('impressions', 'impressions' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'impressions' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Impressions <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.click &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('click', 'click' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'click' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Click <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.ctr &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('ctr', 'ctr' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'ctr' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            CTR <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.cpc &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('cpc', 'cpc' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'cpc' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            CPC <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.spend &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('spend', 'spend' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'spend' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Spend <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.campaignitems.sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            nameA = a[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            nameB = b[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).filter((item, i) => {
                                    return i < this.state.pagesize
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete &&
                                                        <button onClick={() => {
                                                            item.open = !item.open;
                                                            item.negative_keyword = item.query;
                                                            item.negative_type =  {name: 'Exact match', value: 'exact'};
                                                            this.setState({
                                                                campaignitems: this.state.campaignitems
                                                            });
                                                        }} className="bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                            <span>Add negative</span>
                                                            <FeatherIcon className="stroke-current ml-2" size={15} icon="thumbs-down"/>
                                                        </button>
                                                        }
                                                    </div>
                                                </td>
                                                {this.props.properties && this.props.properties.channel &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className="flex flex-row">
                                                        {item.channel === 'google' &&
                                                        <div className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")"}}></div>
                                                        </div>}
                                                    </div>
                                                </td>
                                                }
                                                <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                                    <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'campaign'?'font-bold':'')}>
                                                        #{item.external_id}
                                                    </div>
                                                </td>
                                                {this.props.properties && this.props.properties.query &&
                                                    <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                                        <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'query'?'font-bold':'')}>
                                                            {item.query}
                                                        </div>
                                                    </td>
                                                }
                                                {this.props.properties && this.props.properties.type &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'type'?'font-bold':'')}>
                                                        {item.type?(item.type + this.renders.ending('type', item)):'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.keyword &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'keyword'?'font-bold':'')}>
                                                        {item.keyword?item.keyword:'-'}
                                                    </div>
                                                </td>
                                                }
                                                {
                                                    <td className="px-6 py-4 whitespace-no-wrap">
                                                        <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'conversions'?'font-bold':'')}>
                                                            {item.conversions?item.conversions:'0'}
                                                        </div>
                                                    </td>
                                                }
                                                {this.props.properties && this.props.properties.impressions &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'impressions'?'font-bold':'')}>
                                                        {item.impressions?item.impressions:'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.click &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'click'?'font-bold':'')}>
                                                        {item.click?item.click:'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.ctr &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'ctr'?'font-bold':'')}>
                                                        {item.ctr?(item.ctr + this.renders.ending('ctr', item)):'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.cpc &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'cpc'?'font-bold':'')}>
                                                        {item.cpc?(item.cpc + this.renders.ending('cpc', item)):'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.spend &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'spend'?'font-bold':'')}>
                                                        {item.spend?(item.spend + this.renders.ending('spend', item)):'-'}
                                                    </div>
                                                </td>
                                                }
                                            </tr>
                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                            <tr style={{borderTop: 'none'}}>
                                                <td colspan="100">
                                                    <SlideDown closed={!item.open}>
                                                        <div style={{backgroundColor: "#fcfcfc"}} className="p-4 border-t relative">
                                                            {
                                                                item.loading &&
                                                                <div className="absolute bg-opacity-75 z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center">
                                                                    <BeatLoader
                                                                        sizeUnit={"px"}
                                                                        size={10}
                                                                        color={'#060534'}
                                                                        loading={true}
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="rounded-md bg-gray-100 p-4">
                                                                <div className="flex flex-1 flex-col">
                                                                    <div className="text-xs font-bold mb-1">
                                                                        Add negative keyword to selected campaigns
                                                                    </div>
                                                                    {
                                                                        this.renders.campaigns().map((external_campaign) => {
                                                                            return(
                                                                                <div className="flex flex-row flex-1 items-center mb-2 bg-white px-4 py-3 py-1 rounded-md">
                                                                                    <div style={{fontWeight: "500", fontSize: "14px"}} className="text-sm">{external_campaign.name}{" "}{"#"}{external_campaign.id}</div>
                                                                                    <div className="flex flex-1"></div>
                                                                                    {
                                                                                        item.external_id == external_campaign.id &&
                                                                                        <div className="text-sm font-bold mr-4">
                                                                                            Owner of search term
                                                                                        </div>
                                                                                    }
                                                                                    <Switch
                                                                                        onColor="#1AD5BD"
                                                                                        checked={!external_campaign.exclude}
                                                                                        onChange={(checked) => {
                                                                                            external_campaign.exclude = !checked;
                                                                                            if(!item.exclude_campaigns){
                                                                                                item.exclude_campaigns = {};
                                                                                            }
                                                                                            item.exclude_campaigns[external_campaign.id] = external_campaign.exclude;
                                                                                            this.setState({
                                                                                                campaignitems: this.state.campaignitems
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className="flex flex-row mt-2">
                                                                    <div className="flex flex-1 mr-2">
                                                                        <Input
                                                                            noTitle={false}
                                                                            title={'Keyword'}
                                                                            sign={false}
                                                                            type={'text'}
                                                                            value={item.negative_keyword}
                                                                            size={'full'}
                                                                            noBorder={true}
                                                                            white={true}
                                                                            onChange={(event) => {
                                                                                item.negative_keyword = event.target.value;
                                                                                this.setState({
                                                                                    campaignitems: this.state.campaignitems
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-1 ml-2">
                                                                        <Dropdown
                                                                            noTitle={false}
                                                                            title={'Type'}
                                                                            filter={false}
                                                                            disabled={false}
                                                                            noBorder={true}
                                                                            white={true}
                                                                            size={'full'}
                                                                            options={[{name: 'Exact match', value: 'exact'}, {name: 'Phrase match', value: 'phrase'}, {name: 'Broad match', value: 'broad'}]}
                                                                            value={item.negative_type?item.negative_type:{}}
                                                                            onChange={(value) => {
                                                                                item.negative_type = value;
                                                                                this.setState({
                                                                                    campaignitems: this.state.campaignitems
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    Array.isArray(item.errors) && item.errors.length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                                                        <ul className="list-disc">
                                                                            {
                                                                                item.errors.map((item) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold ">
                                                                                            {item}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="mt-4 flex flex-row">
                                                                <div className="flex flex-1 flex-row">

                                                                </div>
                                                                <div className="justify-end">
                                                                    <button onClick={() => {
                                                                            try{
                                                                                this.props.addNegative(item);
                                                                            }catch (e) {}
                                                                    }} className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                                                                        <span>Add negative keyword</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SlideDown>
                                                </td>
                                            </tr>
                                            }
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default SearchtermList;