import React from 'react';
import './index.css';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { BeatLoader } from "react-spinners";
import ReportTable from "../../../../modules/report-components/table";
import FeatherIcon from 'feather-icons-react';

export default class ChartTemplateTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: 'name',
            order: 'asc',
            loading: true,
            campaigns: [],
            adgroups: [],
            ads: [],
            parsed_items: [],
            parsed_metrics: []
        };
    }

    componentDidMount() {
        this.setState({
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected: this.props.source,
            static: this.props.static
        }, () => {
            if (this.state.static) {
                this.init.list();
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected: nextProps.source ? nextProps.source : {}
        })
    }

    parseData() {
        this.init.list();
    }

    setLoader() {
        this.setState({
            loading: true,
        })
    }

    init = {
        list: () => {
            this.setState({
                campaigns: this.props.campaigns,
                adgroups: this.props.adgroups,
                ads: this.props.ads,
                selected: this.props.source,
                sort: (this.props.source && this.props.source.sort) ? this.props.source.sort : "name",
                order: (this.props.source && this.props.source.order) ? this.props.source.order : "asc"
            }, () => {
                let header = this.state.selected.settings ? this.state.selected.settings.title : "";
                if (
                    (!Array.isArray(this.state.selected.parsed_metrics) || (Array.isArray(this.state.selected.parsed_metrics) && this.state.selected.parsed_metrics.length < 1)) ||
                    (!Array.isArray(this.state.selected.parsed_items) || (Array.isArray(this.state.selected.parsed_items) && this.state.selected.parsed_items.length < 1))
                ) {
                    this.state.parsed_metrics = this.renders.metrics();
                    this.state.parsed_items = this.renders.items().filter((item) => {
                        if (item.type === "campaign") {
                            return this.state.selected.data_sources.campaigns[item.id];
                        } else if (item.type === "adgroup") {
                            return this.state.selected.data_sources.adgroups[item.id];
                        } else if (item.type === "ad") {
                            return this.state.selected.data_sources.ads[item.id];
                        } else {
                            return false
                        }
                    });
                    this.state.parsed_items = this.state.parsed_items.map((item) => {
                        item = this.renders.data(item);
                        this.state.parsed_metrics.map((metric) => {
                            item[metric.metric] = this.renders.number(item[metric.metric]);
                        });
                        return item;
                    });
                    console.log(this.state.parsed_items);
                    this.setState({
                        header: header,
                        parsed_items: this.state.parsed_items,
                        parsed_metrics: this.state.parsed_metrics,
                    }, () => {
                        this.state.selected.parsed_items = this.state.parsed_items;
                        this.state.selected.parsed_metrics = this.state.parsed_metrics;
                        this.setState({
                            selected: this.state.selected,
                            loading: false,
                        })
                    })
                } else {
                    this.setState({
                        header: header,
                        parsed_metrics: this.state.selected.parsed_metrics,
                        parsed_items: this.state.selected.parsed_items,
                    }, () => {
                        this.state.selected.parsed_items = this.state.parsed_items;
                        this.state.selected.parsed_metrics = this.state.parsed_metrics;
                        this.setState({
                            selected: this.state.selected,
                            loading: false,
                        })
                    })
                }
            });
        }
    };

    renders = {
        data: (item) => {
            let data = {};
            item = JSON.parse(JSON.stringify(item));

            let ref_channel = item.channel;

            if (item.channel === 'google_shopping') {
                item.channel = 'google';
            }

            if (Array.isArray(item.insights)) {
                item.insights.map((inner_item) => {
                    if (
                        (
                            (!inner_item.daterange_start && !inner_item.daterange_end && this.state.selected.daterange.value === "lifetime") ||
                            (inner_item.daterange_start === this.state.selected.daterange.start_date &&
                                inner_item.daterange_end === this.state.selected.daterange.end_date)) &&
                        (this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns[item.channel] && this.state.selected.channel_breakdowns[item.channel][inner_item.channel_breakdown])
                    ) {
                        for (let metric in inner_item) {
                            if (this.state.selected && this.state.selected.metrics) {
                                for (let channel in this.state.selected.metrics) {
                                    if (channel === item.channel) {
                                        for (let key in this.state.selected.metrics[channel]) {
                                            if (this.state.selected.metrics[channel][key].name === metric || this.state.selected.metrics[channel][key].org_name === metric) {
                                                if (!(metric in data)) {
                                                    data[metric] = 0;
                                                }
                                                if (this.renders.isNumeric(inner_item[metric])) {
                                                    data[metric] += (+inner_item[metric] * +this.state.selected.metrics[channel][key].factor);
                                                } else {
                                                    data[metric] = inner_item[metric];
                                                }
                                            }
                                        }
                                    } else if (channel === "custom") {
                                        try {
                                            for (let custom_metric in this.state.selected.metrics[channel]) {
                                                for (let custom_metric_channel in this.state.selected.metrics[channel][custom_metric].channels) {

                                                    let item_channel = "";
                                                    if (item.channel === "instagram") {
                                                        item_channel = "facebook";
                                                    } else {
                                                        item_channel = item.channel;
                                                    }

                                                    let custom_metric_item = this.state.selected.metrics[channel][custom_metric].channels[custom_metric_channel];

                                                    if (custom_metric_channel === item_channel && custom_metric_item.name === metric) {
                                                        if (!(custom_metric in data)) {
                                                            data[custom_metric] = 0;
                                                        }
                                                        if (this.renders.isNumeric(inner_item[metric])) {
                                                            data[custom_metric] += (+inner_item[metric] * +custom_metric_item.factor);
                                                        } else {
                                                            data[custom_metric] = inner_item[metric];
                                                        }
                                                    }

                                                }
                                            }
                                        } catch (e) {

                                        }
                                    }
                                }
                            }
                            if (this.renders.isNumeric(inner_item[metric])) {
                                if (!(metric in data)) {
                                    data[metric] = 0;
                                }
                                data[metric] += +inner_item[metric];
                            } else {
                                data[metric] = inner_item[metric];
                            }
                        }
                    }
                });

            }

            //CALCULATE METRIC
            if (this.state.selected.metrics["custom"]) {
                for (let custom_metric in this.state.selected.metrics["custom"]) {
                    if (this.state.selected.metrics["custom"][custom_metric].calculated) {
                        let calc_string = this.state.selected.metrics["custom"][custom_metric].calculation;
                        for (let key in this.state.selected.metrics["custom"][custom_metric].metrics) {

                            let channel_metric = this.state.selected.metrics["custom"][custom_metric].metrics[key].channels[item.channel].name;

                            if (calc_string.indexOf("<" + key + ">") !== -1) {
                                calc_string = calc_string.replace("<" + key + ">", data[channel_metric]);
                            }
                        }
                        console.log(calc_string);
                        console.log(this.renders.calculate(calc_string));
                        data[custom_metric] = this.renders.calculate(calc_string);
                    }
                }
            }

            if (ref_channel === 'google_shopping') {
                item.channel = 'google_shopping';
            }

            item = { ...item, ...data };
            return item;
        },
        metrics: () => {
            let metrics = [];
            try {
                for (let channel in this.state.selected.metrics) {
                    for (let metric in this.state.selected.metrics[channel]) {
                        let name = this.state.selected.metrics[channel][metric].alias ? this.state.selected.metrics[channel][metric].alias : this.state.selected.metrics[channel][metric].name;
                        let calculated = false;
                        if (this.state.selected.metrics[channel][metric].calculated) {
                            calculated = true;
                        }
                        metrics.push({
                            title: name,
                            subtitle: calculated ? "calculated" : channel,
                            //metric: this.state.selected_tab.metrics[channel][metric].org_name ? this.state.selected_tab.metrics[channel][metric].org_name : this.state.selected_tab.metrics[channel][metric].name,
                            metric: this.state.selected.metrics[channel][metric].name,
                            value: this.state.selected.metrics[channel][metric]
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            }
            metrics = metrics.filter((v, i, a) => a.findIndex(t => (t.metric === v.metric)) === i);
            //SORT ARRAY ON METRIC INDEX
            if (this.state.selected.table_metrics_index && this.state.selected.table_metrics) {
                metrics = metrics.filter((item) => {
                    return (item.metric in this.state.selected.table_metrics)
                }).map((item) => {
                    this.state.selected.table_metrics_index.map((inner_item, index) => {
                        if (inner_item.name === item.metric) {
                            item.index = index;
                        }
                    });
                    return item;
                });
            }
            return metrics.sort((a, b) => a.index - b.index);
        },
        items: () => {
            try {
                let items = [];
                if (this.state.selected.channel_breakdowns) {
                    let all_items = [];
                    all_items = all_items.concat(Array.isArray(this.state.campaigns) ? JSON.parse(JSON.stringify(this.state.campaigns)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.adgroups) ? JSON.parse(JSON.stringify(this.state.adgroups)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.ads) ? JSON.parse(JSON.stringify(this.state.ads)) : []);
                    all_items.map((campaign) => {
                        let dimension = {};
                        if (Array.isArray(campaign.insights)) {
                            campaign.insights.map((item) => {
                                if (
                                    this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns[campaign.channel] && this.state.selected.channel_breakdowns[campaign.channel][item.channel_breakdown]
                                    &&
                                    ((this.state.selected.daterange.value === "lifetime" && !item.daterange_start && !item.daterange_end) || (item.daterange_start === this.state.selected.daterange.start_date && item.daterange_end === this.state.selected.daterange.end_date))
                                ) {
                                    item.breakdown_value = item[item.channel_breakdown];
                                    if (item.breakdown_value in dimension) {
                                        dimension[item.breakdown_value].data.push(item);
                                    } else {
                                        dimension[item.breakdown_value] = { campaign: campaign, data: [] };
                                        dimension[item.breakdown_value].data.push(item);
                                    }
                                }
                            });
                        }
                        for (let dim in dimension) {
                            let campaign = { ...JSON.parse(JSON.stringify(dimension[dim].campaign)), ...{ insights: dimension[dim].data } };
                            items = items.concat([campaign]);
                        }
                    });
                }
                return items;
            } catch (e) {
                return [];
            }
        },
        number: (item) => {
            try {
                if (item === undefined) {
                    return "";
                } else {
                    if (this.renders.isNumeric(item)) {
                        return Math.round((item + Number.EPSILON) * 100) / 100;
                    } else {
                        return item;
                    }
                }
            } catch (e) {
                return item;
            }
        },
        isNumeric: (str) => {
            if (typeof str === "number") {
                return true;
            } else {
                return !isNaN(str) && !isNaN(parseFloat(str))
            }
        },
        calculate(input) {

            var f = {
                add: '+',
                sub: '-',
                div: '/',
                mlt: '*',
                mod: '%',
                exp: '^'
            };

            // Create array for Order of Operation and precedence
            f.ooo = [
                [
                    [f.mlt],
                    [f.div],
                    [f.mod],
                    [f.exp]
                ],
                [
                    [f.add],
                    [f.sub]
                ]
            ];

            input = input.replace(/[^0-9%^*\/()\-+.]/g, ''); // clean up unnecessary characters

            var output;
            for (var i = 0, n = f.ooo.length; i < n; i++) {

                // Regular Expression to look for operators between floating numbers or integers
                var re = new RegExp('(\\d+\\.?\\d*)([\\' + f.ooo[i].join('\\') + '])(\\d+\\.?\\d*)');
                re.lastIndex = 0; // take precautions and reset re starting pos

                // Loop while there is still calculation for level of precedence
                while (re.test(input)) {
                    output = _calculate(RegExp.$1, RegExp.$2, RegExp.$3);
                    if (isNaN(output) || !isFinite(output))
                        return output; // exit early if not a number
                    input = input.replace(re, output);
                }
            }

            return output;

            function _calculate(a, op, b) {
                a = a * 1;
                b = b * 1;
                switch (op) {
                    case f.add:
                        return a + b;
                        break;
                    case f.sub:
                        return a - b;
                        break;
                    case f.div:
                        return a / b;
                        break;
                    case f.mlt:
                        return a * b;
                        break;
                    case f.mod:
                        return a % b;
                        break;
                    case f.exp:
                        return Math.pow(a, b);
                        break;
                    default:
                        return null;
                }
            }
        },
        template: () => {
            try {
                return Object.keys(this.state.selected.data_sources.campaigns).length < 1 && Object.keys(this.state.selected.data_sources.adgroups).length < 1 && Object.keys(this.state.selected.data_sources.ads).length < 1
            } catch (error) {
                return false;
            }
        }
    };

    render() {
        if (!this.state.loading && !this.renders.template()) {
            return (
                <div className="px-8 py-8 absolute w-full flex justify-center align-middle items-center flex-col h-full">
                    <div className="mb-4">{this.state.header !== "" ? this.state.header : "..."}</div>
                    <div className="w-full h-full overflow-scroll">
                        <ReportTable
                            premium={true}
                            ref="list"
                            static={true}
                            parsed_items={this.state.parsed_items}
                            parsed_metrics={this.state.parsed_metrics}
                            selected={this.state.selected}
                            updateTab={(data) => {
                                if (this.props.onUpdate) {
                                    //this.props.onUpdate(data);
                                }
                            }}
                        />
                    </div>
                </div>
            );
        } else if (this.state.loading) {
            return (
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={'#060534'}
                        loading={true}
                    />
                </div>
            );
        } else if (this.renders.template()) {
            return (
                <div className="w-full h-full flex flex-col justify-center items-center relative">
                    <button style={{ top: "15px", left: "15px" }}
                        className="transition-all duration-200 absolute px-2 font-bold text-xs leading-none flex items-center justify-center mr-1 h-8 bg-purple-100 text-purple-500 rounded">
                        <FeatherIcon className={"stroke-current"} size={15} icon={"align-justify"} />
                        <span className="ml-2">Table</span>
                    </button>
                    <div onClick={() => {
                        this.props.onEditItem();
                    }} className={"cursor-pointer transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center px-4 h-8 bg-purple-500 text-white rounded"}>
                        <div className="ml-2">Select datasource</div>
                    </div>
                </div>
            );
        } else {
            return (<div></div>)
        }
    }

}
