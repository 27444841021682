class adgroupTwitter {
    static validate(adgroup) {
        let error = {
            name: false,
            budget: false,
            location: false,
            start_date: false,
            end_date: false,
        };
        if (adgroup.adgroup_budget_optimization && adgroup.adgroup_budget_optimization.value) {
            if (!adgroup.daily_budget || (adgroup.daily_budget && (adgroup.daily_budget === "" || parseInt(adgroup.daily_budget) < 1 || isNaN(parseInt(adgroup.daily_budget)))) || (adgroup.total_budget && (parseInt(adgroup.total_budget) < 1 || isNaN(parseInt(adgroup.total_budget))))) {
                error.budget = true;
            }
        }
        if (!adgroup.locations || adgroup.locations.filter((item) => { return item.included }).length < 1) {
            error.location = true;
        }
        if (adgroup.start_date && !adgroup.status) {
            if (new Date(adgroup.start_date).getTime() < new Date().getTime()) {
                error.start_date = true;
            }
        }
        if (!adgroup.name || adgroup.name === "") {
            error.name = true;
        }
        if(adgroup.end_date && !adgroup.status){
            if(new Date(adgroup.end_date).getTime() <= new Date(adgroup.start_date).getTime() || new Date(adgroup.end_date).getTime() <= new Date().getTime()){
                error.end_date = true;
            }
        }
        return error;
    }
}
export { adgroupTwitter }