import React, { PureComponent } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import ChartTemplateBar from './charts-templates/chart-template-bar.js';
import ChartTemplateDoughnut from './charts-templates/chart-template-doughnut.js';
import ChartTemplateImg from './charts-templates/chart-template-img.js';
import ChartTemplateInput from './charts-templates/chart-template-input.js';
import ChartTemplateNumber from './charts-templates/chart-template-number.js';
import _ from 'lodash';

import FeatherIcon from 'feather-icons-react';

import './style.css';
import ChartTemplateChart from "./charts-templates/chart-template-chart";
import ChartTemplateTable from "./charts-templates/chart-template-table";
import Input from '../../../components/input/index.js';
import SlideDown from 'react-slidedown';
import Modal from '../../../modules/modal/index.js';

export default class ReactGridLayout extends PureComponent {

    static defaultProps = {
        className: 'layout',
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        cols: { lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 },
        maxRows: 11,
        margin: [0, 0],
        containerPadding: [0, 0],
        rowHeight: 100
    };

    constructor(props) {
        super(props);
        this.state = {
            layout: [],
            items: [],
            breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
            cols: { lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 },
            isBounded: this.props.isBounded || false,
            preventCollision: this.props.preventCollision || false,
            verticalCompact: this.props.verticalCompact || false,
            hover: {},
            rows: [1, 2, 3, 4, 5, 6],
            visible_rows: 6,
            groups: [],
            data_sources: [],
            initiated: false
        };

        this.onEditItem = this.onEditItem.bind(this);
        this.onUpdateItem = this.onUpdateItem.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onCopyItem = this.onCopyItem.bind(this);

    }

    updateCell(cell) {
        try {
            this.refs[cell].parseData();
        } catch (error) {
            console.log(error);
        }
    }

    updateLoaderCell(cell) {
        try {
            this.refs[cell].setLoader();
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.setState({
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            items: this.props.items ? this.props.items : [],
            groups: this.props.groups ? this.props.groups : [],
            static: this.props.static
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            items: nextProps.initialItems ? nextProps.initialItems : [],
            groups: nextProps.groups ? nextProps.groups : [],
            static: nextProps.static
        });
    }

    createElement(el) {
        const i = el.add ? '+' : el.i;
        const renderBlock = (el) => {
            const { typeOfBlock, header, data, i, format, loading, metric, chart } = el;

            switch (typeOfBlock) {
                case 'chart':
                    return (
                        <ChartTemplateChart
                            ref={el.i}
                            source={el}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            data={data}
                            metric={metric}
                            header={header}
                            loading={loading}
                            typeOfBlock={typeOfBlock}
                            static={this.state.static}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            onUpdateItem={(data) => {
                                console.log(data);
                                el.data = data;
                                this.onUpdateItem(el);
                            }}
                        />
                    );
                case 'table':
                    return (
                        <ChartTemplateTable
                            ref={el.i}
                            source={el}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            static={this.state.static}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            updateData={(data) => {

                            }}
                        />
                    );
                case 'img':
                    return (
                        <ChartTemplateImg
                            onImage={(value) => {
                                el.data = value;
                                el.format = "image";
                                this.onUpdateItem(el);
                            }}
                            onVideo={(value) => {
                                el.data = value;
                                el.format = "video";
                                this.onUpdateItem(el);
                            }}
                            header={header}
                            data={data}
                            format={format}
                            typeOfBlock={typeOfBlock}
                            static={this.state.static}
                        />
                    );
                case 'input':
                    return (
                        <ChartTemplateInput
                            header={header}
                            data={data}
                            typeOfBlock={typeOfBlock}
                            onInput={(htmlState) => {
                                el.data = htmlState;
                                this.onUpdateItem(el);
                            }}
                            static={this.state.static}
                        />
                    );
                case 'number':
                    return (
                        <ChartTemplateNumber
                            ref={el.i}
                            source={el}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            onUpdateItem={(data) => {
                                el.data = data;
                                this.onUpdateItem(el);
                            }}
                            static={this.state.static}
                        />
                    );

                default:
                    return (
                        <div className="flex justify-center items-center text-center font-bold text-xs h-full">
                            Something went wront
                        </div>
                    );
            }
        };

        return (
            <div key={i} data-grid={el}>
                {!el.add &&
                    <>
                        <div
                            onMouseOver={() => {
                                if (!this.state.static) {
                                    var move = document.getElementById("move_" + el.i);
                                    move.style.opacity = 100;
                                    var copy = document.getElementById("copy_" + el.i);
                                    copy.style.opacity = 100;
                                    var remove = document.getElementById("remove_" + el.i);
                                    remove.style.opacity = 100;
                                    var edit = document.getElementById("edit_" + el.i);
                                    edit.style.opacity = 100;
                                }
                            }}
                            onMouseLeave={() => {
                                if (!this.state.static) {
                                    var elem = document.getElementById("move_" + el.i);
                                    elem.style.opacity = 0;
                                    var copy = document.getElementById("copy_" + el.i);
                                    copy.style.opacity = 0;
                                    var remove = document.getElementById("remove_" + el.i);
                                    remove.style.opacity = 0;
                                    var edit = document.getElementById("edit_" + el.i);
                                    edit.style.opacity = 0;
                                }
                            }}
                            className={"p-2 w-full h-full relative overflow-hidden"}>
                            <div style={{ cursor: "default" }} className="text w-full h-full overflow-hidden shadow-xl cursor-default">{renderBlock(el)}</div>

                            {
                                !this.state.static &&
                                <button id={"remove_" + el.i} style={{ bottom: "22px", left: "22px" }}
                                    className="opacity-0 transition-all duration-200 absolute leading-none flex items-center justify-center mr-1 w-8 h-8 bg-red-100 text-red-500 rounded"
                                    onClick={() => {
                                        this.onRemoveItem(el)
                                    }}>
                                    <FeatherIcon className={"stroke-current"} size={15} icon={"trash"} />
                                </button>
                            }

                            {
                                !this.state.static &&
                                <button id={"edit_" + el.i} onClick={() => {
                                    this.onEditItem(el);
                                }} style={{ bottom: "22px", right: "93px" }}
                                    className="opacity-0 transition-all duration-200 absolute leading-none flex items-center justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                    <FeatherIcon className={"stroke-current"} size={15} icon={"edit"} />
                                </button>
                            }

                            {
                                !this.state.static &&
                                <button id={"copy_" + el.i} onClick={() => {
                                    this.onCopyItem(el);
                                }} style={{ bottom: "22px", right: "57px" }}
                                    className="opacity-0 transition-all duration-200 absolute leading-none flex items-center justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                    <FeatherIcon className={"stroke-current"} size={15} icon={"copy"} />
                                </button>
                            }

                            {
                                !this.state.static &&
                                <button id={"move_" + el.i} style={{ bottom: "22px", right: "21px" }} className="opacity-0 drag-handle transition-all duration-200 absolute leading-none flex items-center justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                    <FeatherIcon className={"stroke-current"} size={15} icon={"move"} />
                                </button>
                            }

                        </div>
                    </>
                }
            </div>
        );
    }

    onLayoutChange(layout, layouts, items, group) {
        for (let i = 0; i < items.length; i++) {
            for (let m = 0; m < layout.length; m++) {
                if (items[i].i === layout[m].i) {
                    items[i].x = layout[m].x;
                    items[i].y = layout[m].y;
                    items[i].w = layout[m].w;
                    items[i].h = layout[m].h;
                    break;
                }
            }
        }
        if (this.props.onGridChange) {
            this.props.onGridChange(items);
        }
    }

    onUpdateItem(item) {
        if (this.props.onUpdateItem) {
            this.props.onUpdateItem(item);
        }
    }

    onRemoveItem(item) {
        if (this.props.onRemove) {
            this.props.onRemove(item);
        }
    }

    onCopyItem(item) {
        if (this.props.onCopyItem) {
            this.props.onCopyItem(item);
        }
    }

    onEditItem(item) {
        if (this.props.onEditItem) {
            this.props.onEditItem(item);
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.groups.map((item, index) => {
                        let rows_array = [];
                        for (let i = 0; i < item.rows; i++) {
                            rows_array.push(i);
                        }
                        return (
                            <div>
                                <div style={{ backgroundColor: "#fcfcfc", maxWidth: "1200px", margin: 'auto' }} className="rounded w-full">
                                    {
                                        !this.state.static &&
                                        <div style={{ backgroundColor: "#fcfcfc" }} className={(item.closed ? "" : "border-b-0 rounded-b-none ") + " w-full rounded border px-2 flex flex-row justify-center align-middle items-center relative"}>
                                            <div onClick={() => {
                                                /*
                                                item.update_index = !item.update_index;
                                                this.setState({
                                                    groups: this.state.groups
                                                }, () => {
                                                    if (this.props.updateGroups) {
                                                        this.props.updateGroups(this.state.groups);
                                                    }
                                                })
                                                */
                                            }}
                                                className="transition-all duration-200 leading-none flex items-center justify-center text-xs font-bold w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                                <div>
                                                    {index + 1}
                                                </div>
                                            </div>
                                            {
                                                item.update_index &&
                                                <div className="absolute flex-row flex justify-start left-0 top-0 bottom-0  align-middle items-center px-2 right-0 bg-white rounded z-10">
                                                    {
                                                        this.state.groups.map((inner_item, inner_index) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    if (index === inner_index) {
                                                                        item.update_index = false;
                                                                        this.setState({
                                                                            groups: this.state.groups
                                                                        }, () => {
                                                                            if (this.props.updateGroups) {
                                                                                this.props.updateGroups(this.state.groups);
                                                                            }
                                                                        })
                                                                    } else {
                                                                        item.update_index = false;
                                                                        let new_groups = this.state.groups.filter((item, filter_index) => { return index !== filter_index });
                                                                        new_groups.splice(inner_index, 0, item);
                                                                        this.state.groups = new_groups;
                                                                        this.setState({
                                                                            groups: this.state.groups
                                                                        }, () => {
                                                                            if (this.props.updateGroups) {
                                                                                this.props.updateGroups(this.state.groups);
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                    className={(index === inner_index ? "border-2 border-purple-500" : "") + " cursor-pointer transition-all mr-2 duration-200 leading-none flex items-center justify-center text-xs font-bold w-8 h-8 bg-purple-100 text-purple-500 rounded"}>
                                                                    <div>
                                                                        {inner_index + 1}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="flex flex-1 justify-end pr-1">
                                                        <div onClick={() => {
                                                            item.update_index = false;
                                                            this.setState({
                                                                groups: this.state.groups
                                                            }, () => {
                                                                if (this.props.updateGroups) {
                                                                    this.props.updateGroups(this.state.groups);
                                                                }
                                                            })
                                                        }}
                                                            className={"cursor-pointer transition-all duration-200 leading-none flex items-center justify-center text-xs font-bold w-8 h-8 bg-red-100 text-red-500 rounded"}>
                                                            <div>
                                                                <FeatherIcon className={"stroke-current"} size={15} icon={"x"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="ml-4">
                                                <button
                                                    disabled={this.state.groups.length < 2}
                                                    className={(this.state.groups.length < 2 ? "opacity-25 cursor-not-allowed" : "") + " transition-all duration-200 leading-none flex items-center justify-center mr-1 px-4 font-bold text-xs h-8 bg-red-100 text-red-500 rounded"}
                                                    onClick={() => {
                                                        if (this.props.removePage && this.state.groups.length > 1) {
                                                            this.props.removePage(item);
                                                        }
                                                    }}>
                                                    <FeatherIcon className={"stroke-current"} size={15} icon={"trash"} />
                                                    <div className="ml-2">Remove</div>
                                                </button>
                                            </div>
                                            <div className="flex flex-1">
                                                <Input
                                                    bgcolor={false}
                                                    title={"Name"}
                                                    sign={false}
                                                    noTitle={true}
                                                    noBorder={true}
                                                    placeholder={"Page name ..."}
                                                    white={false}
                                                    value={item.name}
                                                    type={"text"}
                                                    size={'full'}
                                                    onChange={(event) => {
                                                        item.name = event.target.value;
                                                        this.setState({
                                                            groups: this.state.groups
                                                        }, () => {
                                                            if (this.props.updateGroups) {
                                                                this.props.updateGroups(this.state.groups);
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                            {
                                                item.rows > 6 &&
                                                <div className="flex mr-5">
                                                    <div className="transition-all duration-200 leading-none text-xs font-bold flex items-center justify-center px-4 h-8 bg-yellow-100 text-yellow-500 rounded">
                                                        <FeatherIcon className={"stroke-current"} size={15} icon={"alert-triangle"} />
                                                        <div className="ml-2">OBS! Not eligible for Power Point (maximum 6 rows)</div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                    <div onClick={() => {
                                                        if (this.props.addCell && !item.closed) {
                                                            this.props.addCell(item.id);
                                                        }
                                                    }} className={(item.closed ? "cursor-not-allowed" : "cursor-pointer") + " transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center px-4 h-8 bg-purple-500 text-white rounded"}>
                                                        <FeatherIcon className={"stroke-current"} size={15} icon={"grid"} />
                                                        <div className="ml-2">Add cell</div>
                                                        {
                                                            false &&
                                                            <span>{this.state.items.filter((inner_item) => {
                                                                return inner_item.group == item.id
                                                            }).length}</span>
                                                        }
                                                    </div>
                                                    {
                                                        false &&
                                                        <div onClick={() => {
                                                            if (this.props.addCell && !item.closed) {
                                                                this.props.addCell(item.id);
                                                            }
                                                        }} className={(item.closed ? "cursor-not-allowed" : "cursor-pointer") + " transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center mr-1 w-8 h-8 bg-purple-500 text-white rounded rounded-l-none"}>
                                                            <FeatherIcon className={"stroke-current"} size={15} icon={"plus"} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex justify-end"}>
                                                {
                                                    <div className="flex flex-row">
                                                        <div className="transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center px-4 border-grey-300 border-r h-8 bg-purple-100 text-purple-500 rounded rounded-r-none">
                                                            <FeatherIcon className={"stroke-current"} size={15} icon={"layers"} />
                                                            <div className="ml-2">{item.rows} rows</div>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (this.props.addRows && !item.closed) {
                                                                this.props.addRows(item);
                                                            }
                                                        }} className={(item.closed ? "cursor-not-allowed" : "cursor-pointer") + " transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center border-grey-300 border-r w-8 h-8 bg-purple-100 text-purple-500"}>
                                                            <FeatherIcon className={"stroke-current"} size={15} icon={"plus"} />
                                                        </div>
                                                        <div onClick={() => {
                                                            if (this.props.removeRows && item.rows > 6 && !item.closed) {
                                                                this.props.removeRows(item);
                                                            }
                                                        }} className={(item.rows > 6 && !item.closed ? "cursor-pointer" : "cursor-not-allowed") + " transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded rounded-l-none"}>
                                                            <FeatherIcon className={"stroke-current"} size={15} icon={"minus"} />
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            {
                                                <button onClick={() => {
                                                    if (this.props.clonePage) {
                                                        this.props.clonePage(item);
                                                    }
                                                }}
                                                    className="transition-all duration-200  leading-none flex items-center justify-center w-8  mr-1 text-xs font-bold h-8 bg-purple-100 text-purple-500 rounded">
                                                    <FeatherIcon className={"stroke-current"} size={15} icon={"copy"} />
                                                </button>
                                            }
                                            {
                                                <button onClick={() => {
                                                    item.closed = !item.closed;
                                                    this.setState({
                                                        groups: this.state.groups
                                                    }, () => {
                                                        if (this.props.updateGroups) {
                                                            this.props.updateGroups(this.state.groups);
                                                        }
                                                    })
                                                }} className=" transition-all duration-200  leading-none flex items-center justify-center mr-1 text-xs font-bold w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                                    <FeatherIcon className={"stroke-current"} size={15} icon={!item.closed ? "minimize-2" : "maximize-2"} />

                                                </button>
                                            }
                                            {
                                                false &&
                                                <button className=" transition-all duration-200  leading-none flex items-center justify-center w-8  mr-1 text-xs font-bold h-8 bg-purple-100 text-purple-500 rounded">
                                                    <FeatherIcon className={"stroke-current"} size={15} icon={"move"} />

                                                </button>
                                            }
                                            {
                                                false &&
                                                <div className="">
                                                    <button onClick={() => {
                                                        if (this.props.addCell) {
                                                            this.props.addCell(item.id);
                                                        }
                                                    }} className=" transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded">
                                                        <FeatherIcon className={"stroke-current"} size={15} icon={"plus"} />
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <SlideDown closed={item.closed && !this.state.static}>
                                        <div style={{ height: 100 * item.rows + "px" }}>
                                            <div style={{ maxWidth: "1200px", margin: 'auto' }} className={(this.props.backgroundColor && this.props.backgroundColor ? this.props.backgroundColor : "bg-transparent ") + (this.state.static ? "" : " rounded-t-none ") + " rounded relative z-10 h-full"}>
                                                {
                                                    <ResponsiveGridLayout
                                                        {...this.props}
                                                        //draggableCancel={'.MyNonDraggableAreaClassName'}
                                                        draggableHandle=".drag-handle"
                                                        // draggableHandle={'.MyDragHandleClassName'}
                                                        rowHeight={100}
                                                        layout={item.layout}
                                                        //layouts={this.state.layouts}
                                                        autoSize={false}
                                                        maxRows={item.rows}
                                                        static={this.state.static}
                                                        isResizable={this.state.static}
                                                        cols={this.state.static ? { lg: 8, md: 8, sm: 6, xs: 4, xxs: 2 } : { lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 }}
                                                        isDraggable={!this.state.static}
                                                        isBounded={this.state.isBounded}
                                                        verticalCompact={true}
                                                        preventCollision={true}

                                                        onDragStop={() => {

                                                        }}
                                                        onResizeStop={() => {

                                                        }}
                                                        onLayoutChange={(layout, layouts) => {
                                                            this.onLayoutChange(layout, layouts, this.state.items);
                                                        }}

                                                        resizeHandles={this.state.static ? [] : ['se']}
                                                    //onBreakpointChange={this.onBreakpointChange}
                                                    >
                                                        {_.map(this.state.items.filter((inner_item) => {
                                                            return inner_item.group == item.id
                                                        }), (el) => this.createElement(el))}
                                                    </ResponsiveGridLayout>
                                                }
                                                {
                                                    !this.state.static &&
                                                    <div className={"pattern absolute inset-0 overflow-hidden"} style={{ zIndex: '-1' }}>
                                                        {rows_array.map((e, i) => {
                                                            return (
                                                                <div key={i} className={"flex flex-row w-full items-stretch relative"} style={{ height: '100px' }}>
                                                                    {
                                                                        false &&
                                                                        (i === 5 || i === 11 || i === 17 || i === 23 || i === 29 || i === 35 || i === 41) &&
                                                                        <div style={{ bottom: "-12px" }} className="absolute flex justify-center align-middle items-center right-0 left-0">
                                                                            <div className="text-xxs font-bold bg-gray-100 px-3 py-1 rounded-full z-10">power point page</div>
                                                                        </div>
                                                                    }
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-r-2 border-dashed border-gray-200"}></span>
                                                                    <span className={(i !== 0 ? "border-t-2" : "") + " w-1/6 border-dashed border-gray-200"}></span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </SlideDown>

                                </div>
                                <div className={"mt-4 w-full"} />
                                {
                                    !this.state.static && (index === this.state.groups.length - 1) &&
                                    <div className="cursor-pointer flex justify-center align-middle items-center w-full">
                                        <div>
                                            <button className="text-xs w-48 font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-purple-500 text-purple-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white"
                                                onClick={() => {
                                                    if (this.props.addPage) {
                                                        this.props.addPage();
                                                    }
                                                }}>
                                                Add page
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

const ResponsiveGridLayout = WidthProvider(Responsive);

if (process.env.STATIC_EXAMPLES === true) {
    import('./test-hook.js').then((fn) => fn.default(ReactGridLayout));
}
