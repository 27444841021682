import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../../components/facebook";
import Dropdown from "../../components/dropdown";
import Input from "../../components/input";
import Switch from "../../components/advertising-channels";
import { tokenRegister } from "../../services/tokenRegister";
import { SlideDown } from "react-slidedown";
import { calls } from "./calls";
import { TelegramShareButton } from 'react-share';
import { apiRegister } from '../../services/apiRegister';
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';

const fuzzysort = require('fuzzysort');

class CreateCustomMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            metrics: {},
            metric_data: [],
            tab: {},
            search: {},
            open: {},
            option: {},
            custom_metrics: {},
            datasources: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            clients: [],
            view: "",
            name: "",
            edit: false,
            premium: false,
            type: {},
            level: {},
            client: {},
            search_custom_metric: "",
            calculation: "",
            custom_metrics_index: [],
            suffix_options: [
                { id: 0, name: "No suffix", value: "" },
                { id: 1, name: "Percentage", value: "%" },
                { id: 2, name: "Currency", value: "currency" },
            ],
            suffix: { id: 0, name: "No suffix", value: "" }
        };
    };

    componentDidMount() {
        this.setState({
            view: this.props.view,
            channels: this.props.channels,
            channelMetric: this.props.channelMetric,
            datasources: this.props.datasources,
            //clients: this.props.clients,
            premium: this.props.premium,
            option: this.props.option
        });
    }

    componentWillReceiveProps(nextProps, nextContext) { }

    functions = {
        init: async () => {

            if (this.props.option && this.props.option.value === "edit_metric") {

                this.state.open.type = false;

                this.state.metrics = this.props.metric.channels;
                this.state.suffix = this.props.metric.suffix ? this.props.metric.suffix : { id: 0, name: "No suffix", value: "" };
                this.state.name = this.props.metric.name;
                this.state.id = this.props.metric.id;
                this.state.edit = true;

                if (this.props.metric.level === 'client') {
                    this.state.client = { id: this.props.metric.client };
                    this.state.open.client = false;
                }

                if (this.props.metric.level === "client") {
                    this.state.level = {
                        name: "Client",
                        id: "client",
                        description: "Metric will only be available for selected client",
                        icon: "percent"
                    };
                } else {
                    this.state.level = {
                        name: "Agency",
                        id: "agency",
                        description: "Allow metric to be visible for all clients",
                        icon: "code"
                    };
                }

                if (!this.props.metric.calculated) {
                    this.state.type = {
                        name: "Custom",
                        id: "custom",
                        description: "Used to compare and display channel metrics",
                        icon: "code",
                        disabled: false
                    };
                } else {
                    this.state.type = {
                        name: "Calculated",
                        id: "calculated",
                        description: "Calculate common metric cross channel",
                        icon: "percent",
                        disabled: false
                    };
                    this.state.custom_metrics_index = [];
                    for (let key in this.props.metric.metrics) {
                        this.state.custom_metrics_index.push({ ...this.props.metric.metrics[key], ... { index: key } });
                        this.state.custom_metrics[this.props.metric.metrics[key].name] = this.props.metric.metrics[key];
                    }
                    this.state.custom_metrics_index.sort((a, b) => (a.index > b.index) ? 1 : -1);
                    this.state.custom_metrics_index = this.state.custom_metrics_index.map((item) => {
                        delete item.index;
                        return item;
                    });
                    this.state.calculation = this.props.metric.calculation;
                    this.state.calculation_options = this.props.metric.calculation_options ? this.props.metric.calculation_options : [];
                    this.functions.listCustomMetrics();
                    this.state.open.custom_metric = false;
                    this.state.open.calculation = true;
                }
            } else {

                this.state.open.type = true;
                this.state.open.metrics = false;
                this.state.metrics = {};
                this.state.custom_metrics = {};
                this.state.custom_metrics_index = [];
                this.state.type = {};
                this.state.level = {};
                this.state.client = {};
                this.state.name = "";
                this.state.calculation_options = [];
                this.state.calculation = "";
                this.state.search_custom_metric = "";
                this.state.id = null;
                this.state.edit = false;

            }

            await this.promisedSetState({
                open: this.state.open,
                custom_metrics: this.state.custom_metrics ? this.state.custom_metrics : {},
                edit: this.state.edit,
                calculation: this.state.calculation,
                name: this.state.name,
                type: this.state.type,
                custom_metrics_index: this.state.custom_metrics_index,
                option: this.props.option,
                calculation_options: this.state.calculation_options,
                metrics: this.state.metrics,
                view: this.props.view,
                //metric_data: this.props.custom_metrics,
                channels: this.props.channels,
                channelMetric: this.props.channelMetric,
                datasources: this.props.datasources,
                //clients: this.props.clients
            });

            if (this.props.option && this.props.option.value === "edit_metric") {
                this.functions.listClients(true);
            }

            this.functions.listMetrics(true);

        },
        listClients: async (keep_closed) => {
            this.state.open.client = false;
            await this.promisedSetState({
                loading_clients: true,
                open: this.state.open
            });
            try {
                let response = await this.calls.listClients();
                await this.promisedSetState({
                    clients: Array.isArray(response.data) ? response.data : [],
                });
            } catch (error) { }
            this.state.open.client = !keep_closed;
            await this.promisedSetState({
                loading_clients: false,
                open: this.state.open
            });
        },
        listCustomMetrics: async () => {
            await this.promisedSetState({
                loading_custom: true
            });
            try {
                let response = await this.calls.listCustomMetrics();
                await this.promisedSetState({
                    custom_metric_data: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_custom: false
            });
        },
        listMetrics: async (keep_closed) => {
            this.state.open.custom_metric = false;
            await this.promisedSetState({
                loading_metrics: true,
                open: this.state.open
            });
            try {
                let response = await this.calls.listMetrics();
                this.state.open.custom_metric = !keep_closed;
                await this.promisedSetState({
                    metric_data: response.data,
                    open: this.state.open
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_metrics: false
            });
        },
        select: (item, channel) => {
            if (this.state.premium || !this.state.channelMetric) {
                if (!this.state.metrics[channel]) {
                    this.state.metrics[channel] = item;

                    //CHECK SPECS (ADFORM)
                    if (channel === "adform") {
                        this.state.metrics[channel].specs = {};
                        if (item.nested && Array.isArray(item.nested_values)) {
                            item.nested_values.map((nested_item) => {
                                if (nested_item.nested && Array.isArray(nested_item.nested_values)) {
                                    nested_item.nested_values.map((inner_nested_item) => {
                                        if (inner_nested_item.default) {
                                            this.state.metrics[channel].specs[nested_item.name] = inner_nested_item;
                                        }
                                    })
                                }
                            });
                        }
                    }

                    this.state.open[channel] = false;
                } else {
                    if (this.state.metrics[channel].name === item.name) {
                        delete this.state.metrics[channel];
                    } else {
                        this.state.metrics[channel] = item;

                        //CHECK SPECS (ADFORM)
                        if (channel === "adform") {
                            this.state.metrics[channel].specs = {};
                            if (item.nested && Array.isArray(item.nested_values)) {
                                item.nested_values.map((nested_item) => {
                                    if (nested_item.nested && Array.isArray(nested_item.nested_values)) {
                                        nested_item.nested_values.map((inner_nested_item) => {
                                            if (inner_nested_item.default) {
                                                this.state.metrics[channel].specs[nested_item.name] = inner_nested_item;
                                            }
                                        })
                                    }
                                });
                            }
                        }

                        this.state.open[channel] = false;
                    }
                }
            } else {
                if (!this.state.metrics[channel]) {
                    this.state.metrics[channel] = {};
                    this.state.metrics[channel][item.name] = item;
                } else if (this.state.metrics[channel] && !this.state.metrics[channel][item.name]) {
                    this.state.metrics[channel][item.name] = item;
                } else if (this.state.metrics[channel] && this.state.metrics[channel][item.name]) {
                    delete this.state.metrics[channel][item.name];
                    if (Object.keys(this.state.metrics[channel]).length < 1) {
                        delete this.state.metrics[channel];
                    }
                }
            }
            this.setState({
                metrics: this.state.metrics,
                open: this.state.open
            })
        },
        create: () => {
            if (this.state.type && this.state.type.id === "custom") {
                if (this.state.option && this.state.option.value === "channel_metric") {
                    if (this.state.metrics && this.state.metrics.adform) {
                        for (let key in this.state.metrics["adform"]) {
                            this.state.metrics["adform"][key].specs = {};
                            if (this.state.metrics["adform"][key].nested && Array.isArray(this.state.metrics["adform"][key].nested_values)) {
                                this.state.metrics["adform"][key].nested_values.map((nested_item) => {
                                    if (nested_item.nested && Array.isArray(nested_item.nested_values)) {
                                        nested_item.nested_values.map((inner_nested_item) => {
                                            if (inner_nested_item.default) {
                                                this.state.metrics["adform"][key].specs[nested_item.name] = inner_nested_item;
                                            }
                                        })
                                    }
                                });
                            }
                            let array = [];
                            let column_key = this.state.metrics["adform"][key].name;
                            for (let spec in this.state.metrics["adform"][key].specs) {
                                array.push({ ...this.state.metrics["adform"][key].specs[spec], ...{ parent: spec } });
                            }
                            array.sort((a, b) => {
                                return (a.parent > b.parent) ? 1 : -1
                            });
                            array.map((item) => {
                                column_key = column_key + ("." + item.parent + "##" + item.name);
                            })
                            this.state.metrics["adform"][key].name = column_key;
                        }
                    }
                    this.props.close(this.state.metrics, "channel_metric");
                } else if (this.state.option && this.state.option.value === "temp_metric") {
                    this.props.close({ name: this.state.name, channels: this.state.metrics }, "temp_metric");
                } else if (this.state.option && this.state.option.value === "new_metric") {
                    this.functions.createCustom();
                } else if (this.state.option && this.state.option.value === "edit_metric") {
                    this.functions.editCustom();
                }
            } else {
                if (this.state.edit) {
                    this.functions.editCalculated();
                } else {
                    this.functions.createCalculated();
                }
            }
        },
        editCalculated: () => {
            this.setState({
                loading_metric: true
            }, () => {
                let custom_metrics = {};
                this.state.custom_metrics_index.map((item, index) => {
                    custom_metrics[index + 1] = item;
                });
                calls.editMetric({
                    id: this.state.id,
                    name: this.state.name,
                    suffix: this.state.suffix,
                    calculation_options: this.state.calculation_options,
                    calculation: this.state.calculation,
                    custom_metrics: custom_metrics,
                    calculated: true
                }).then((response) => {
                    this.setState({
                        loading_metric: false
                    }, () => {
                        response.data.id = this.state.id;
                        response.data.icon = 'code';
                        response.data.title = response.data.name;
                        this.props.close(response.data, "edit_metric");
                    })
                }, (error) => {
                    this.setState({
                        loading_metric: false
                    })
                });
            })
        },
        editCustom: () => {
            this.setState({
                loading_metric: true
            }, () => {
                calls.editMetric({
                    id: this.state.id,
                    name: this.state.name,
                    suffix: this.state.suffix,
                    facebook: this.state.metrics.facebook,
                    google: this.state.metrics.google,
                    linkedin: this.state.metrics.linkedin,
                    adform: this.state.metrics.adform,
                    bidtheatre: this.state.metrics.bidtheatre,
                    tiktok: this.state.metrics.tiktok,
                    twitter: this.state.metrics.twitter,
                    google_analytics: this.state.metrics.google_analytics
                }).then((response) => {
                    this.setState({
                        metrics: {},
                        loading_metric: false
                    }, () => {
                        response.data.id = this.state.id;
                        response.data.icon = 'code';
                        response.data.title = response.data.name;
                        this.props.close(response.data, "edit_metric");
                    })
                }, (error) => {
                    this.setState({
                        loading_metric: false
                    })
                });
            })
        },
        createCustom: () => {
            this.setState({
                loading_metric: true
            }, () => {
                calls.createMetric({
                    name: this.state.name,
                    suffix: this.state.suffix,
                    level: this.state.level.id,
                    client: this.state.client.id,
                    facebook: this.state.metrics.facebook,
                    google: this.state.metrics.google,
                    linkedin: this.state.metrics.linkedin,
                    bidtheatre: this.state.metrics.bidtheatre,
                    adform: this.state.metrics.adform,
                    tiktok: this.state.metrics.tiktok,
                    twitter: this.state.metrics.twitter,
                    google_analytics: this.state.metrics.google_analytics
                }).then((response) => {
                    this.setState({
                        metrics: {},
                        loading_metric: false
                    }, () => {
                        if (response.data._id) {
                            response.data.id = response.data._id;
                        }
                        this.props.close("created");
                    })
                }, (error) => {
                    let message = "";
                    try {
                        if (error.body.message) {
                            message = error.body.message;
                        }
                    } catch (error) {
                        message = "something wrong wrong";
                    }
                    this.setState({
                        message: message,
                        metric_error: true,
                        loading_metric: false
                    })
                });
            })
        },
        createCalculated: () => {
            this.setState({
                loading_metric: true
            }, () => {
                let custom_metrics = {};
                this.state.custom_metrics_index.map((item, index) => {
                    custom_metrics[index + 1] = item;
                });
                calls.createMetric({
                    name: this.state.name,
                    suffix: this.state.suffix,
                    level: this.state.level.id,
                    client: this.state.client.id,
                    calculation_options: this.state.calculation_options,
                    calculation: this.state.calculation,
                    custom_metrics: custom_metrics,
                    calculated: true
                }).then((response) => {
                    this.setState({
                        loading_metric: false
                    }, () => {
                        if (response.data._id) {
                            response.data.id = response.data._id;
                        }
                        this.props.close(response.data, "create_metric");
                    })
                }, (error) => {
                    let message = "";
                    try {
                        if (error.body.message) {
                            message = error.body.message;
                        }
                    } catch (error) {
                        message = "something wrong wrong";
                    }
                    this.setState({
                        message: message,
                        metric_error: true,
                        loading_metric: false
                    })
                });
            })
        },
        /*
        getChannelMetrics: () => {
            this.setState({
                loading_metrics: true
            }, () => {
                let function_call = { function: "listMetrics", channel: "adcredo", data: { channels: true } };
                if (this.state.level.id === "client" && this.state.client.id) {
                    function_call.params = { client: this.state.client.id };
                }
                this.calls.batch([function_call]).then((response) => {
                    if (response.data[0] && response.data[0].status == 200) {
                        let data = response.data[0].data;
                        this.setState({
                            metric_data: { ...this.state.metric_data, ...data },
                            loading_metrics: false
                        }, () => {
                            console.log(this.state.metric_data);
                        })
                    }
                }, (error) => {
                    this.setState({
                        loading_metrics: false
                    });
                });
            })
        }
        */
    };

    renders = {
        channels: () => {
            let channels = [];
            if (this.state.channelMetric && Array.isArray(this.state.channels)) {
                let uniqueChannels = this.state.channels.filter((c, index) => {
                    return this.state.channels.indexOf(c) === index;
                });
                uniqueChannels.map((channel) => {
                    channels.push(channel);
                });
            } else {
                channels.push("facebook");
                channels.push("google");
                channels.push("linkedin");
                channels.push("bing");
                channels.push("snapchat");
                channels.push("bidtheatre");
                channels.push("google_analytics");
                channels.push("google_analytics_4");
                channels.push("adform");
                channels.push("tiktok");
                channels.push("twitter");
            }
            return channels;
        },
        channel: (channel) => {
            return [];
        },
        levels: (item) => {
            if (Array.isArray(item.levels)) {
                return item.levels.join("/");
            }
        },
        name: (name, parent) => {
            return name;
        },
        stepValid: () => {
            return true
        },
        valid: () => {
            if (this.state.type && this.state.type.id === "custom") {
                if (this.state.channelMetric) {
                    if (this.state.premium) {
                        let channels = {};
                        this.state.datasources.map((item) => {
                            channels[item.channel] = true;
                        });
                        for (let channel in this.state.metrics) {
                            delete channels[channel];
                        };
                        return Object.keys(channels).length === 0 && (this.state.datasources.length < 2 || (this.state.datasources.length > 1 && this.state.name !== ""));
                    } else {
                        return Object.keys(this.state.metrics).length > 0
                    }
                } else {
                    return Object.keys(this.state.metrics).length > 0 && this.state.name !== ""
                }
            } else if (this.state.type && this.state.type.id === "calculated") {
                return this.state.custom_metrics_index.length > 0 && this.state.calculation !== "" && this.state.name !== "";
            } else {
                return false;
            }
        },
        selected: (channel, item) => {
            if (this.state.premium || !this.state.channelMetric) {
                return this.state.metrics[channel] && this.state.metrics[channel].name === item.name;
            } else {
                return this.state.metrics[channel] && this.state.metrics[channel][item.name];
            }
        },
        selectedDataSources: () => {
            return [];
        },
        filter: (options, search) => {
            let keywords = [];
            if (search && search !== "") {
                let clients = fuzzysort.go(search, options.map((item) => { return item.name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
        calculation: () => {
            let calc_string = "";
            (Array.isArray(this.state.calculation_options) ? this.state.calculation_options : []).map((item) => {
                if (item.type === "metric") {
                    calc_string = calc_string + "<" + (item.index + 1) + ">";
                } else if (item.type === "number") {
                    if (item.value !== "number" && item.value > 0) {
                        calc_string = calc_string + item.value;
                    }
                } else {
                    calc_string = calc_string + item.value;
                }
            })
            return calc_string
        }
    };

    calls = {
        batch: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v2/controllers/batchFunctions";
            return apiRegister.call(options, url);
        },
        listClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients";
            return apiRegister.call(options, url);
        },
        listMetrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listMetrics" + (this.state.client.id ? ("?client=" + this.state.client.id) : "")
            return apiRegister.call(options, url);
        },
        listCustomMetrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomMetrics?type=custom";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                <div>
                    {
                        //SELECT TYPE STEP
                        <>
                            <div
                                className={"px-4 sm:px-6 pb-6 border-b"}>
                                <div className="flex flex-1 flex-row justify-center items-center">
                                    <div className="flex flex-col flex-1">
                                        <h3 className="mb-0 font-bold text-sm">
                                            Select metric type
                                        </h3>
                                        {
                                            !this.state.type.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                There are <span
                                                    className="font-bold ml-1 mr-1">{2}</span> types
                                                available
                                            </p>
                                        }
                                        {
                                            this.state.type.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                You have selected <span
                                                    className="font-bold ml-1 mr-1">{this.state.type.name}</span>
                                            </p>
                                        }
                                    </div>
                                    {
                                        <div className="flex flex-row">
                                            {
                                                this.state.type.id &&
                                                <div
                                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                        icon={"check"} />
                                                </div>
                                            }
                                            {
                                                <div onClick={() => {
                                                    this.state.open.type = !this.state.open.type;
                                                    this.setState({
                                                        open: this.state.open
                                                    })
                                                }}
                                                    className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                    {
                                                        !this.state.open.type &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-down"} />
                                                    }
                                                    {
                                                        this.state.open.type &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-up"} />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <SlideDown closed={!this.state.open.type}>
                                    <div className="w-full h-6" />
                                    <div className="">
                                        {
                                            [
                                                {
                                                    name: "Custom",
                                                    id: "custom",
                                                    description: "Used to compare and display channel metrics",
                                                    icon: "code",
                                                    disabled: this.state.edit
                                                },
                                                {
                                                    name: "Calculated",
                                                    id: "calculated",
                                                    description: "Calculate common metric cross channel",
                                                    icon: "percent",
                                                    disabled: this.state.edit
                                                }].map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + " cursor-pointer " + (i !== ([1, 2].length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                <div className={(item.id === "advanced" ? "bg-yellow-300 text-yellow-800" : "bg-purple-100 text-purple-500") + " p-1 overflow-hidden h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center"}>
                                                                    <FeatherIcon className={'stroke-current'} size={14} icon={item.icon} />
                                                                </div>
                                                                <div onClick={() => {
                                                                    if (!item.disabled) {
                                                                        this.state.open.type = false;
                                                                        this.state.open.level = true;
                                                                        this.setState({
                                                                            type: item,
                                                                            client: {},
                                                                            open: this.state.open
                                                                        });
                                                                        if (item.id === 'calculated') {
                                                                            this.functions.listCustomMetrics();
                                                                        }
                                                                    }
                                                                }} className={((this.state.type && this.state.type.id === item.id) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                    {
                                                                        this.state.type && this.state.type.id === item.id &&
                                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                                    }
                                                                </div>
                                                                <div className="flex flex-1 flex-col truncate">
                                                                    <div className="font-bold text-xs truncate">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-xxs">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                        }
                                    </div>
                                    {
                                        this.state.type && this.state.type.id &&
                                        <div className="flex justify-center items-center">
                                            <div onClick={() => {
                                                this.state.open.type = false;
                                                this.state.open.level = true;
                                                this.setState({
                                                    open: this.state.open
                                                })
                                            }}
                                                className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-5">NEXT
                                            </div>
                                        </div>
                                    }
                                </SlideDown>
                            </div>
                        </>
                    }
                    {
                        //SELECT AGENCY OR CLIENT METRIC
                        this.state.type && this.state.type.id &&
                        <>
                            <div
                                className={"px-4 sm:px-6 pb-6 pt-6 border-b"}>
                                <div className="flex flex-1 flex-row justify-center items-center">
                                    <div className="flex flex-col flex-1">
                                        <h3 className="mb-0 font-bold text-sm">
                                            Select metric level
                                        </h3>
                                        {
                                            !this.state.level.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                There are <span
                                                    className="font-bold ml-1 mr-1">{2}</span> levels
                                                available
                                            </p>
                                        }
                                        {
                                            this.state.level.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                You have selected <span
                                                    className="font-bold ml-1 mr-1">{this.state.level.name}</span>
                                            </p>
                                        }
                                    </div>
                                    {
                                        <div className="flex flex-row">
                                            {
                                                this.state.level.id &&
                                                <div
                                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                        icon={"check"} />
                                                </div>
                                            }
                                            {
                                                <div onClick={() => {
                                                    this.state.open.level = !this.state.open.level;
                                                    this.setState({
                                                        open: this.state.open
                                                    })
                                                }}
                                                    className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                    {
                                                        !this.state.open.level &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-down"} />
                                                    }
                                                    {
                                                        this.state.open.level &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-up"} />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <SlideDown closed={!this.state.open.level}>
                                    <div className="w-full h-6" />
                                    <div className="">
                                        {
                                            [
                                                {
                                                    name: "Agency",
                                                    id: "agency",
                                                    description: "Allow metric to be visible for all clients",
                                                    icon: "code",
                                                    disabled: this.state.edit
                                                },
                                                {
                                                    name: "Client",
                                                    id: "client",
                                                    description: "Metric will only be available for selected client",
                                                    icon: "percent",
                                                    disabled: this.state.edit
                                                }].map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + " cursor-pointer " + (i !== ([1, 2].length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                <div className={(item.id === "advanced" ? "bg-yellow-300 text-yellow-800" : "bg-purple-100 text-purple-500") + " p-1 overflow-hidden h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center"}>
                                                                    <FeatherIcon className={'stroke-current'} size={14} icon={item.icon} />
                                                                </div>
                                                                <div onClick={async () => {
                                                                    if (!item.disabled) {
                                                                        this.state.open.level = false;
                                                                        if (item.id === "client") {
                                                                            this.state.open.client = true;
                                                                        } else {
                                                                            this.state.open.custom_metric = true;
                                                                            this.state.client = {};
                                                                        }
                                                                        await this.promisedSetState({
                                                                            level: item,
                                                                            client: this.state.client,
                                                                            open: this.state.open
                                                                        });
                                                                        if (item.id === "client") {
                                                                            this.functions.listClients();
                                                                        } else {
                                                                            this.functions.listMetrics();
                                                                        }
                                                                    }
                                                                }} className={((this.state.level && this.state.level.id === item.id) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                    {
                                                                        this.state.level && this.state.level.id === item.id &&
                                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                                    }
                                                                </div>
                                                                <div className="flex flex-1 flex-col truncate">
                                                                    <div className="font-bold text-xs truncate">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-xxs">
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                        }
                                    </div>
                                    {
                                        this.state.level && this.state.level.id &&
                                        <div className="flex justify-center items-center">
                                            <div onClick={() => {
                                                this.state.open.level = false;
                                                if (this.state.level.id === "client") {
                                                    this.state.open.client = true;
                                                } else {
                                                    this.state.open.custom_metric = true;
                                                }
                                                this.setState({
                                                    open: this.state.open
                                                })
                                            }}
                                                className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-5">NEXT
                                            </div>
                                        </div>
                                    }
                                </SlideDown>
                            </div>
                        </>
                    }
                    {
                        //SELECT CLIENT (IF LEVEL CLIENT)
                        this.state.level && this.state.level.id === "client" &&
                        <>
                            <div
                                className={"px-4 sm:px-6 pb-6 pt-6 border-b"}>
                                <div className="flex flex-1 flex-row justify-center items-center">
                                    <div className="flex flex-col flex-1">
                                        <h3 className="mb-0 font-bold text-sm">
                                            Select client
                                        </h3>
                                        {
                                            !this.state.client.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                There are <span
                                                    className="font-bold ml-1 mr-1">{this.state.clients.length}</span> clients
                                                available
                                            </p>
                                        }
                                        {
                                            this.state.client.id &&
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                You have selected <span
                                                    className="font-bold ml-1 mr-1">{this.state.client.name}</span>
                                            </p>
                                        }
                                    </div>
                                    {
                                        <div className="flex flex-row">
                                            {
                                                this.state.client.id &&
                                                <div
                                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                        icon={"check"} />
                                                </div>
                                            }
                                            {
                                                <div onClick={() => {
                                                    this.state.open.client = !this.state.open.client;
                                                    this.setState({
                                                        open: this.state.open
                                                    })
                                                }}
                                                    className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                    {
                                                        !this.state.open.client && !this.state.loading_clients &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-down"} />
                                                    }
                                                    {
                                                        this.state.open.client && !this.state.loading_clients &&
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"chevron-up"} />
                                                    }
                                                    {
                                                        this.state.loading_clients &&
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <SlideDown closed={!this.state.open.client}>
                                    <div className="w-full h-6" />
                                    <div className="">
                                        {
                                            this.state.clients.map((item, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div className={((item.disabled || this.state.edit) ? "opacity-50 cursor-not-allowed " : "") + " cursor-pointer " + (i !== (this.state.clients.length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                            <div
                                                                className="text-purple-500 p-1 overflow-hidden bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <img className="w-full" src={item.logo} />
                                                            </div>
                                                            <div onClick={() => {
                                                                if (!item.disabled && !this.state.edit) {
                                                                    this.state.open.client = false;
                                                                    this.setState({
                                                                        client: item,
                                                                        open: this.state.open
                                                                    }, () => {
                                                                        this.functions.listMetrics();
                                                                    })
                                                                }
                                                            }} className={((this.state.client && this.state.client.id === item.id) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                {
                                                                    this.state.client && this.state.client.id === item.id &&
                                                                    <div className="w-full h-full rounded-full bg-purple-500" />
                                                                }
                                                            </div>
                                                            <div className="flex flex-1 flex-col truncate">
                                                                <div className="font-bold text-xs truncate">
                                                                    {item.name}
                                                                </div>
                                                                <div className="text-xxs">
                                                                    {item.company}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        this.state.client && this.state.client.id &&
                                        <div className="flex justify-center items-center">
                                            <div onClick={() => {
                                                this.state.open.client = false;
                                                this.state.open.custom_metric = true;
                                                this.setState({
                                                    open: this.state.open
                                                })
                                            }}
                                                className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-5">NEXT
                                            </div>
                                        </div>
                                    }
                                </SlideDown>
                            </div>
                        </>
                    }
                    {
                        //SELECT CUSTOM METRICS TO BUILD CALCULATED METRIC
                        this.state.type && this.state.type.id === "calculated" &&
                        this.state.level && ((this.state.level.id === "client" && this.state.client.id) || (this.state.level.id === "agency")) &&
                        <>
                            {
                                <div
                                    className={"px-4 sm:px-6 pb-6 pt-6 border-b"}>
                                    <div className="flex flex-1 flex-row justify-center items-center">
                                        <div className="flex flex-col flex-1">
                                            <h3 className="mb-0 font-bold text-sm">
                                                Select custom metrics
                                            </h3>
                                            {
                                                this.state.custom_metrics && Object.keys(this.state.custom_metrics).length < 1 &&
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    There are <span
                                                        className="font-bold ml-1 mr-1">{this.state.custom_metric_data.length}</span> types
                                                    available
                                                </p>
                                            }
                                            {
                                                this.state.custom_metrics && Object.keys(this.state.custom_metrics).length > 0 &&
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    You have selected <span
                                                        className="font-bold ml-1 mr-1">{Object.keys(this.state.custom_metrics).length}</span> metrics
                                                </p>
                                            }
                                        </div>
                                        {
                                            <div className="flex flex-row">
                                                {
                                                    this.state.custom_metrics && Object.keys(this.state.custom_metrics).length > 0 &&
                                                    <div
                                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"check"} />
                                                    </div>
                                                }
                                                {
                                                    <div onClick={() => {
                                                        this.state.open.custom_metric = !this.state.open.custom_metric;
                                                        this.setState({
                                                            open: this.state.open
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.open.custom_metric &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.open.custom_metric &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <SlideDown closed={!this.state.open.custom_metric}>
                                        <div className="w-full h-6" />
                                        {
                                            <div className="flex flex-1 flex-col justify-center mb-4">
                                                <div className="text-xs font-bold mb-1">
                                                    Search metrics
                                                </div>
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            search_custom_metric: event.target.value
                                                        });
                                                    }}
                                                    value={this.state.search_custom_metric}
                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                />
                                            </div>
                                        }
                                        <div className="">
                                            {
                                                this.state.custom_metric_data && Array.isArray(this.state.custom_metric_data) &&
                                                this.renders.filter(this.state.custom_metric_data, this.state.search_custom_metric).map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className="flex flex-row">
                                                                <div
                                                                    className={((this.renders.selectedDataSources().filter((source) => {
                                                                        return item.channels[source.channel];
                                                                    }).length === 0) ? "cursor-pointer " : "opacity-50 cursor-not-allowed") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                                    <div
                                                                        className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                        <FeatherIcon
                                                                            className={'stroke-current'}
                                                                            size={14}
                                                                            icon={'code'} />
                                                                    </div>
                                                                    {
                                                                        <div onClick={() => {
                                                                            if (!this.state.custom_metrics) {
                                                                                this.state.custom_metrics = {};
                                                                            }
                                                                            if (this.state.custom_metrics[item.name]) {
                                                                                delete this.state.custom_metrics[item.name];
                                                                                this.state.custom_metrics_index = this.state.custom_metrics_index.filter((inner_item) => {
                                                                                    return inner_item.name !== item.name;
                                                                                })
                                                                            } else {
                                                                                this.state.custom_metrics[item.name] = item;
                                                                                this.state.custom_metrics_index.push(item);
                                                                            }
                                                                            this.setState({
                                                                                custom_metrics_index: this.state.custom_metrics_index,
                                                                                custom_metrics: this.state.custom_metrics
                                                                            })
                                                                        }}
                                                                            className={(this.state.custom_metrics && this.state.custom_metrics[item.name] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.custom_metrics && this.state.custom_metrics[item.name] &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check" />
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        className="flex flex-1 flex-col truncate">
                                                                        <div
                                                                            className="font-bold text-xs truncate">
                                                                            {item.alias ? item.alias : item.name}
                                                                        </div>
                                                                        <div className="text-xxs capitalize">
                                                                            {
                                                                                item.channels &&
                                                                                Object.keys(item.channels).map((channel, index) => {
                                                                                    let last = index === (Object.keys(item.channels).length - 1);
                                                                                    return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            this.state.custom_metrics &&
                                            Object.keys(this.state.custom_metrics).length > 0 &&
                                            <div className="flex justify-center items-center">
                                                <div onClick={() => {
                                                    this.state.open.custom_metric = false;
                                                    this.state.open.calculation = true;
                                                    this.setState({
                                                        open: this.state.open
                                                    })
                                                }}
                                                    className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                </div>
                                            </div>
                                        }
                                    </SlideDown>
                                </div>
                            }
                        </>
                    }
                    {
                        //SET CALCULATION OF METRIC
                        this.state.custom_metrics &&
                        Object.keys(this.state.custom_metrics).length > 0 &&
                        this.state.type && this.state.type.id === "calculated" &&
                        this.state.level && ((this.state.level.id === "client" && this.state.client.id) || (this.state.level.id === "agency")) &&
                        <>
                            {
                                <div
                                    className={"px-4 sm:px-6 pb-6 pt-6 border-b"}>
                                    <div className="flex flex-1 flex-row justify-center items-center">
                                        <div className="flex flex-col flex-1">
                                            <h3 className="mb-0 font-bold text-sm">
                                                Set calculation
                                            </h3>
                                            {
                                                this.state.calculation === "" &&
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    Calculation is <span
                                                        className="font-bold ml-1 mr-1">not valid</span>
                                                </p>
                                            }
                                            {
                                                this.state.calculation !== "" &&
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    Calculation is <span
                                                        className="font-bold ml-1 mr-1">valid</span>
                                                </p>
                                            }
                                        </div>
                                        {
                                            Object.keys(this.state.custom_metrics).length > 0 &&
                                            <div className="flex flex-row">
                                                {
                                                    this.state.calculation !== "" &&
                                                    <div
                                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                        <FeatherIcon className={'stroke-current'} size={14}
                                                            icon={"check"} />
                                                    </div>
                                                }
                                                {
                                                    <div onClick={() => {
                                                        this.state.open.calculation = !this.state.open.calculation;
                                                        this.setState({
                                                            open: this.state.open
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.open.calculation &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.open.calculation &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <SlideDown closed={!this.state.open.calculation}>
                                        <div className="w-full h-6" />
                                        <div className="">
                                            {
                                                this.state.custom_metrics_index.map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + " cursor-pointer " + " mb-2 flex flex-1 flex-row justify-center"}>
                                                                <div className={(item.id === "advanced" ? "bg-yellow-300 text-yellow-800" : "bg-purple-100 text-purple-500") + " p-1 overflow-hidden h-8 w-8 flex rounded font-bold mr-1 justify-center align-middle items-center"}>
                                                                    <FeatherIcon className={'stroke-current'} size={14} icon={'code'} />
                                                                </div>
                                                                <div className={(item.id === "advanced" ? "bg-yellow-300 text-yellow-800" : "bg-purple-100 text-purple-500") + " p-1 text-xs overflow-hidden h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center"}>
                                                                    {i + 1}
                                                                </div>
                                                                <div className="flex flex-1 flex-col truncate">
                                                                    <div className="font-bold text-xs truncate">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="text-xxs capitalize">
                                                                        {
                                                                            Object.keys(item.channels).map((channel, index) => {
                                                                                let last = index === (Object.keys(item.channels).length - 1);
                                                                                return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            <div className="border-b mt-4 mb-4">

                                            </div>
                                        }
                                        {
                                            <div className="text-xs font-bold mb-1">
                                                Calculation ({this.renders.calculation()})
                                            </div>
                                        }
                                        {
                                            <div className="w-full rounded border-2 flex flex-row flex-wrap p-2 pb-0 box-border">
                                                {
                                                    Array.isArray(this.state.calculation_options) &&
                                                    this.state.calculation_options.map((item, index) => {
                                                        return (
                                                            <div className="rounded h-6 text-purple-500 mb-2 mr-2 bg-purple-100 items-center flex flex-row justify-center">
                                                                {
                                                                    !item.selected &&
                                                                    <div onClick={() => {
                                                                        if (item.type === "number") {
                                                                            item.selected = true;
                                                                            this.setState({
                                                                                calculation_options: this.state.calculation_options
                                                                            }, () => {
                                                                                document.getElementById("numberInput" + index).focus();
                                                                            })
                                                                        }
                                                                    }} className="text-xs font-bold px-2">
                                                                        {item.title !== "number" ? item.title : item.value}
                                                                    </div>
                                                                }
                                                                {
                                                                    item.selected &&
                                                                    <input onBlur={() => {
                                                                        item.selected = false;
                                                                        this.setState({
                                                                            calculation_options: this.state.calculation_options
                                                                        })
                                                                    }} id={"numberInput" + index} style={{ maxWidth: "70px" }} type="number" pattern="[0-9]+" className="h-full w-full bg-transparent p-2 text-xs font-bold border-none" onChange={(event) => {
                                                                        item.value = event.target.value;
                                                                        this.setState({
                                                                            calculation_options: this.state.calculation_options,
                                                                            calculation: this.renders.calculation()
                                                                        })
                                                                    }} />
                                                                }
                                                                <div onClick={() => {
                                                                    this.state.calculation_options = this.state.calculation_options.filter((item, inner_index) => {
                                                                        return index !== inner_index
                                                                    });
                                                                    this.setState({
                                                                        calculation_options: this.state.calculation_options
                                                                    })
                                                                }} className="flex cursor-pointer items-center justify-center border-l px-2">
                                                                    <FeatherIcon className={'stroke-current'} size={12}
                                                                        icon={"x"} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    (!Array.isArray(this.state.calculation_options) || (Array.isArray(this.state.calculation_options) && this.state.calculation_options.length < 1)) &&
                                                    <div className="h-6 bg-opacity-0 mb-2"></div>
                                                }
                                            </div>
                                        }
                                        {
                                            <div className="text-xs font-bold mb-1 mt-4">
                                                Select options
                                            </div>
                                        }
                                        {
                                            <div className="flex flex-row flex-wrap">
                                                {
                                                    this.state.custom_metrics_index.map((item, index) => {
                                                        return (
                                                            <div onClick={() => {
                                                                if (!this.state.calculation_options) {
                                                                    this.state.calculation_options = [];
                                                                }
                                                                this.state.calculation_options.push({ title: item.name, value: item, type: "metric", index: index });
                                                                this.setState({
                                                                    calculation_options: this.state.calculation_options,
                                                                    calculation: this.renders.calculation()
                                                                })
                                                            }} className="rounded cursor-pointer mb-2 mr-2 h-6 text-purple-500 bg-purple-100 items-center flex flex-row justify-center">
                                                                <div className="text-xs font-bold px-4">
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    ["+", "-", "/", "*", "number"].map((item) => {
                                                        return (
                                                            <div onClick={() => {
                                                                if (!this.state.calculation_options) {
                                                                    this.state.calculation_options = [];
                                                                }
                                                                this.state.calculation_options.push({ title: item, value: item, type: item !== "number" ? "math" : "number" });
                                                                this.setState({
                                                                    calculation_options: this.state.calculation_options
                                                                })
                                                            }} className="rounded mb-2 cursor-pointer mr-2 h-6 text-purple-500 bg-purple-100 items-center flex flex-row justify-center">
                                                                <div className="text-xs font-bold px-4">
                                                                    {item}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.calculation !== "" &&
                                            <div className="flex justify-center items-center">
                                                <div onClick={() => {
                                                    this.state.open.calculation = false;
                                                    this.setState({
                                                        open: this.state.open
                                                    })
                                                }}
                                                    className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-5">NEXT
                                                </div>
                                            </div>
                                        }
                                    </SlideDown>
                                </div>
                            }
                        </>
                    }
                    {
                        //SELECT REGULAR METRICS TO CREATE CUSTOM METRIC
                        this.state.type && this.state.type.id === "custom" &&
                        this.state.level && ((this.state.level.id === "client" && this.state.client && this.state.client.id) || (this.state.level.id === "agency")) &&
                        <>
                            {
                                Object.keys(this.state.metric_data).filter((channel) => {
                                    return this.renders.channels().filter((item) => {
                                        return channel === item
                                    }).length > 0
                                }).map((channel, index) => {
                                    let last = (Object.keys(this.state.metric_data).filter((channel) => {
                                        return this.renders.channels().filter((item) => {
                                            return channel === item
                                        }).length > 0
                                    }).length - 1) === index;
                                    return (
                                        <div className={(last ? "" : "mb-6 ") + (index === 0 ? " pt-6" : "") + " border-b box-border"}>
                                            <div className="">
                                                <div
                                                    className="flex px-6 flex-1 flex-row justify-center items-center">
                                                    {
                                                        channel === "internal" &&
                                                        <div
                                                            className="bg-purple-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-5"
                                                                src={require('../../assets/images/Adcredo Logotype Symbol (White).png')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "google" &&
                                                        <div
                                                            className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/google_icon.svg')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "linkedin" &&
                                                        <div
                                                            className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/linkedin_icon.svg')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "facebook" &&
                                                        <div
                                                            className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img style={{ width: '0.4rem' }}
                                                                src={require('../../assets/images/facebook_icon.svg')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "google_analytics" &&
                                                        <div
                                                            className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/ga_icon.png')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "adform" &&
                                                        <div
                                                            className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/adform_icon.png')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "bidtheatre" &&
                                                        <div
                                                            className="bg-bidtheatre-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/bidtheatre_icon.png')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "twitter" &&
                                                        <div
                                                            className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/twitter_icon.svg')} />
                                                        </div>
                                                    }
                                                    {
                                                        channel === "tiktok" &&
                                                        <div
                                                            className="bg-tiktok-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                            <img className="w-3"
                                                                src={require('../../assets/images/tiktok_icon.png')} />
                                                        </div>
                                                    }
                                                    <div style={{ marginTop: "-6px" }} className="flex flex-col flex-1">
                                                        <h3 className={"mb-0 font-bold"}>
                                                            <span className="capitalize text-sm">{channel.replace("_", " ")}</span>
                                                        </h3>
                                                        {
                                                            this.state.metric_data[channel] && !this.state.metrics[channel] &&
                                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                There are<span
                                                                    className="font-bold ml-1 mr-1">{this.state.metric_data[channel].length}</span> categories
                                                                available
                                                            </p>
                                                        }
                                                        {
                                                            this.state.metrics[channel] &&
                                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                {
                                                                    (this.state.premium || !this.state.channelMetric) &&
                                                                    <span>You have selected <span className="font-bold">1</span></span>
                                                                    ||
                                                                    <span>You have selected <span className="font-bold">{Object.keys(this.state.metrics[channel]).length}</span></span>
                                                                }
                                                            </p>
                                                        }
                                                    </div>
                                                    {
                                                        this.state.metric_data[channel].filter((item) => {
                                                            return !item.nested || (item.nested && item.nested_values.length > 0)
                                                        }).length > 0 &&
                                                        <div className="flex flex-row">
                                                            {
                                                                this.state.metrics[channel] && false &&
                                                                <div
                                                                    className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                        icon={"check"} />
                                                                </div>
                                                            }
                                                            <div onClick={() => {
                                                                this.state.open[channel] = !this.state.open[channel];
                                                                this.setState({
                                                                    open: this.state.open
                                                                })
                                                            }}
                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                {
                                                                    !this.state.open[channel] &&
                                                                    <FeatherIcon className={'stroke-current'}
                                                                        size={14}
                                                                        icon={"chevron-down"} />
                                                                }
                                                                {
                                                                    this.state.open[channel] &&
                                                                    <FeatherIcon className={'stroke-current'}
                                                                        size={14} icon={"chevron-up"} />
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <SlideDown closed={!this.state.open[channel]}>
                                                    {
                                                        this.state.metric_data[channel].filter((item) => {
                                                            return !item.nested || (item.nested && item.nested_values.length > 0)
                                                        }).length > 0 &&
                                                        <div className="w-full h-6" />
                                                    }
                                                    {
                                                        <div className="mb-2 text-xs font-bold px-6">
                                                            Search all metrics
                                                        </div>
                                                    }
                                                    {
                                                        <div className="flex flex-1 flex-col justify-center mb-4 px-6">
                                                            <input
                                                                onChange={(event) => {
                                                                    this.state.search[channel] = event.target.value;
                                                                    this.setState({
                                                                        search: this.state.search
                                                                    });
                                                                }}
                                                                placeholder={"Search ..."}
                                                                value={this.state.search[channel] ? this.state.search[channel] : ""}
                                                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.metric_data[channel].filter((item) => {
                                                            if (this.state.search[channel]) {
                                                                let flat_nested_values = this.renders.name(item.name);
                                                                return flat_nested_values.toLowerCase().indexOf(this.state.search[channel].toLowerCase()) !== -1
                                                            } else {
                                                                return true;
                                                            }
                                                        }).filter((item) => {
                                                            return !item.nested || (item.nested && item.nested_values.length > 0)
                                                        }).sort(function (a, b) {
                                                            if (a.name < b.name) {
                                                                return -1;
                                                            }
                                                            if (a.name > b.name) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).sort(function (a, b) {
                                                            if (a.default) {
                                                                return -1;
                                                            }
                                                            if (!a.default) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        }).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center mb-2 px-6">
                                                                        {
                                                                            item.nested &&
                                                                            <div onClick={() => {
                                                                                item.open = !item.open;
                                                                                this.setState({
                                                                                    metrics: this.state.metrics
                                                                                })
                                                                            }}
                                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                {
                                                                                    !item.open &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={14} icon="plus" />
                                                                                }
                                                                                {
                                                                                    item.open &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={14} icon="minus" />
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !item.nested && (this.state.option && (this.state.option.value === "edit_metric" || this.state.option.value === "new_metric" || this.state.option.value === "temp_metric")) &&
                                                                            <div onClick={() => {
                                                                                this.functions.select(item, channel);
                                                                            }} className={((this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, item)) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full cursor-pointer border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    (this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, item)) &&
                                                                                    <div className="w-full h-full rounded-full bg-purple-500" />
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !item.nested && (this.state.option && (this.state.option.value !== "edit_metric" && this.state.option.value !== "new_metric" && this.state.option.value !== "temp_metric")) &&
                                                                            <div onClick={() => {
                                                                                this.functions.select(item, channel);
                                                                            }} className={((this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, item)) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    (this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, item)) &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={14}
                                                                                        icon="check" />
                                                                                }
                                                                            </div>
                                                                        }
                                                                        <div
                                                                            className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.alias ? item.alias : this.renders.name(item.name)}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.levels && item.levels.join(", ")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        item.open && item.nested && item.nested_values.sort(function (a, b) {
                                                                            if (a.name < b.name) {
                                                                                return -1;
                                                                            }
                                                                            if (a.name > b.name) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        }).map((inner_item) => {
                                                                            return (
                                                                                <Fragment>
                                                                                    <div className="px-6">
                                                                                        <div
                                                                                            className="flex flex-1 flex-row justify-center pl-10">
                                                                                            <div className="flex flex-1 flex-row justify-center mb-2 truncate">
                                                                                                {
                                                                                                    (this.state.option && (this.state.option.value === "edit_metric" || this.state.option.value === "new_metric" || this.state.option.value === "temp_metric")) &&
                                                                                                    <div onClick={() => {
                                                                                                        this.functions.select(inner_item, channel);
                                                                                                    }} className={((this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, inner_item)) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full cursor-pointer border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                        {
                                                                                                            (this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, inner_item)) &&
                                                                                                            <div className="w-full h-full rounded-full bg-purple-500" />
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {

                                                                                                    (this.state.option && (this.state.option.value !== "edit_metric" && this.state.option.value !== "new_metric" && this.state.option.value !== "temp_metric")) &&
                                                                                                    <div onClick={() => {
                                                                                                        this.functions.select(inner_item, channel);
                                                                                                    }} className={((this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, inner_item)) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                        {
                                                                                                            (this.state.metrics && this.state.metrics[channel] && this.renders.selected(channel, inner_item)) &&
                                                                                                            <FeatherIcon
                                                                                                                className={'stroke-current'}
                                                                                                                size={14}
                                                                                                                icon="check" />
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                <div
                                                                                                    className="flex flex-1 flex-col truncate">
                                                                                                    <div
                                                                                                        className="font-bold text-xs truncate">
                                                                                                        {inner_item.alias ? inner_item.alias : this.renders.name(inner_item.name, item.name)}
                                                                                                    </div>
                                                                                                    <div className="text-xxs">
                                                                                                        {this.renders.name(item.name)}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </SlideDown>
                                                {
                                                    !this.state.metrics[channel] &&
                                                    <div className="w-full h-6" />
                                                }
                                                {
                                                    this.state.option && this.state.option.value !== "channel_metric" &&
                                                    this.state.metrics[channel] &&
                                                    <div className="mt-6 flex flex-col pt-6 pb-6 bg-gray-100 bg-opacity-75 rounded px-6">
                                                        {
                                                            false &&
                                                            <div className="mb-1 text-xs font-bold">
                                                                Selected {channel} metrics
                                                            </div>
                                                        }
                                                        <div className="flex flex-row">
                                                            <div
                                                                className="text-purple-500 border-2 border-purple-500 mr-2 bg-white cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"code"} />
                                                            </div>
                                                            <div className="flex flex-row flex-1">
                                                                <div
                                                                    className="flex flex-1 flex-col truncate">
                                                                    <div className="font-bold text-xs truncate">
                                                                        {this.state.metrics[channel].alias ? this.state.metrics[channel].alias : this.renders.name(this.state.metrics[channel].name)}
                                                                    </div>
                                                                    <div className="text-xxs">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    delete this.state.metrics[channel];
                                                                    this.setState({
                                                                        metrics: this.state.metrics
                                                                    })
                                                                }}
                                                                className="text-red-500 border-2 border-red-500 bg-white ml-2 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"trash"} />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.metrics[channel].nested && this.state.metrics[channel].nested_values.sort(function (a, b) {
                                                                if (a.name < b.name) {
                                                                    return -1;
                                                                }
                                                                if (a.name > b.name) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            }).map((inner_item) => {
                                                                return (
                                                                    <Fragment>
                                                                        <div className="flex flex-1 flex-row justify-center mt-2 pl-10">
                                                                            {
                                                                                inner_item.nested &&
                                                                                <div onClick={() => {
                                                                                    inner_item.open = !inner_item.open;
                                                                                    this.setState({
                                                                                        metrics: this.state.metrics
                                                                                    })
                                                                                }}
                                                                                    className="text-purple-500 bg-white border-2 border-purple-500 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                    {
                                                                                        !inner_item.open &&
                                                                                        <FeatherIcon
                                                                                            className={'stroke-current'}
                                                                                            size={14} icon="plus" />
                                                                                    }
                                                                                    {
                                                                                        inner_item.open &&
                                                                                        <FeatherIcon
                                                                                            className={'stroke-current'}
                                                                                            size={14} icon="minus" />
                                                                                    }
                                                                                </div>
                                                                            }

                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate">
                                                                                    {inner_item.alias ? inner_item.alias : this.renders.name(inner_item.name)}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    {(this.state.metrics[channel].specs && this.state.metrics[channel].specs[inner_item.name]) ? this.state.metrics[channel].specs[inner_item.name].alias : inner_item.default_alias}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            <SlideDown closed={!inner_item.open}>
                                                                                {
                                                                                    inner_item.nested_values.sort(function (a, b) {
                                                                                        if (a.name < b.name) {
                                                                                            return -1;
                                                                                        }
                                                                                        if (a.name > b.name) {
                                                                                            return 1;
                                                                                        }
                                                                                        return 0;
                                                                                    }).map((inner_inner_item) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="flex flex-1 flex-row justify-center pl-20">
                                                                                                <div className="flex flex-1 flex-row justify-center mt-2 truncate">
                                                                                                    <div onClick={() => {
                                                                                                        if (!this.state.metrics[channel].specs) {
                                                                                                            this.state.metrics[channel].specs = {};
                                                                                                        }
                                                                                                        this.state.metrics[channel].specs[inner_item.name] = inner_inner_item;
                                                                                                        inner_item.open = false;
                                                                                                        this.setState({
                                                                                                            metrics: this.state.metrics
                                                                                                        })
                                                                                                    }} className={(((this.state.metrics[channel].specs && this.state.metrics[channel].specs[inner_item.name] && this.state.metrics[channel].specs[inner_item.name].name === inner_inner_item.name)) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full bg-white cursor-pointer border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                        {
                                                                                                            ((this.state.metrics[channel].specs && this.state.metrics[channel].specs[inner_item.name] && this.state.metrics[channel].specs[inner_item.name].name === inner_inner_item.name)) &&
                                                                                                            <div className="w-full h-full rounded-full bg-purple-500" />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="flex flex-1 flex-col truncate">
                                                                                                        <div
                                                                                                            className="font-bold text-xs truncate">
                                                                                                            {inner_inner_item.alias}
                                                                                                        </div>
                                                                                                        <div className="text-xxs">
                                                                                                            {inner_inner_item.default ? <span className="ml-1">(default)</span> : <span></span>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </SlideDown>

                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        //SET UNIQUE NAME
                        this.state.type && ((this.state.type.id === "calculated" && Object.keys(this.state.custom_metrics).length > 0) || (this.state.type.id === "custom" && Object.keys(this.state.metrics).length > 0)) &&
                        this.state.level && ((this.state.level.id === "client" && this.state.client.id) || (this.state.level.id === "agency")) &&
                        <>
                            {
                                <div className={"mb-6 border-b pb-6 pt-6 box-border"}>
                                    <div className="px-4 sm:px-6">
                                        <div className="flex flex-1 flex-row justify-center items-center">
                                            <div className="flex flex-col flex-1">
                                                <h3 className={"mb-0 font-bold"}>
                                                    <span className="capitalize text-sm">Metric name</span>
                                                </h3>
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    Give your metrics a common unique name
                                                </p>
                                            </div>
                                        </div>
                                        <div className="w-full h-6" />
                                        <div className="flex flex-1 flex-col justify-center mb-2">
                                            <input
                                                onChange={(event) => {
                                                    this.state.name = event.target.value;
                                                    this.setState({
                                                        name: this.state.name
                                                    });
                                                }}
                                                placeholder={"Type metric name here ..."}
                                                value={this.state.name}
                                                className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                            />
                                        </div>
                                        {
                                            this.state.metric_error &&
                                            <div className="mt-2">
                                                <div className="rounded bg-red-100 text-red-500 p-4 font-bold text-xs">
                                                    {this.state.message}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full h-6" />
                                    <div className="px-4 sm:px-6">
                                        <div className="flex flex-1 flex-col justify-center mb-2">
                                            <DropdownTailwind
                                                label={"Select suffix"}
                                                selected={this.state.suffix}
                                                locked={false}
                                                options={this.state.suffix_options}
                                                onChange={(value) => {
                                                    this.state.suffix = value;
                                                    this.setState({
                                                        suffix: this.state.suffix
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    //BUTTON
                    <div className="px-4">
                        <div onClick={() => {
                            if (this.renders.valid()) {
                                this.functions.create();
                            }
                        }} className={(this.renders.valid() ? "bg-green-500 text-white cursor-pointer" : "bg-gray-100 text-gray-500 cursor-not-allowed") + " py-3 px-4 transition-all duration-200 rounded-full flex rounded font-bold justify-center align-middle items-center font-bold w-full text-sm text-center"}>
                            {
                                !this.state.premium && !this.state.loading_metric && this.state.channelMetric &&
                                <div>Add metrics</div>
                            }
                            {
                                this.state.premium && !this.state.loading_metric && this.state.channelMetric &&
                                <div>Add metric</div>
                            }
                            {
                                !this.state.loading_metric && !this.state.edit && !this.state.channelMetric &&
                                <div>Create metric</div>
                            }
                            {
                                !this.state.loading_metric && this.state.edit && !this.state.channelMetric &&
                                <div>Edit metric</div>
                            }
                            {
                                this.state.loading_metric &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default CreateCustomMetric;
