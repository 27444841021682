import React, {Component} from 'react';
import cx from "classnames";
import moment from 'moment';
import IconGoogle from "../../components/icons/google";
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";

class DropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropOpen:false,
            disabled: false
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            disabled: this.props.disabled
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disabled: nextProps.disabled
        })
    }

    functions = {};

    renders = {};

    render(){
        return (
            <section className="w-full">
                <div className="relative mb-5">
                    <div>
                        {this.props.headline &&
                            <h3 className="font-bold text-sm mb-2">{this.props.headline}</h3>
                        }
                        <div className="w-full relative">
                            <button onClick={() => {
                                    if(!this.state.disabled){
                                        this.setState({dropOpen: !this.state.dropOpen})
                                    }
                                }} type="button" className={`flex justify-between items-center w-full border-2 border-transparent px-4 py-3 font-semibold text-sm text-purple-900 focus:outline-none active:bg-transparent active:text-purple-900 transition-all duration-200 ${this.props.bg ? this.props.bg : 'bg-white'} ${this.props.rounded ? this.props.rounded : 'rounded-md'}`} id="options-menu" aria-haspopup="true" aria-expanded="true">
                                {this.props.label}
                                <svg className="-mr-1 ml-2 h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                                </button>
                            { this.props.showreset &&
                                <button className="absolute transform -translate-y-1/2 top-50 right-0 mr-2 px-4 py-2 focus:outline-none bg-red-100 rounded-full text-red-500 text-xs font-bold hover:bg-red-500 hover:text-white transition-all duration-200" onClick={this.props.res}>Reset</button>
                            }
                        </div>
                    </div>
                    <div className={"origin-top transform bg-white absolute right-0 w-full rounded-b shadow-xl transition-all duration-200 " + (this.state.dropOpen ? 'opacity-100 scale-y-100 visible z-10' : 'opacity-0 scale-y-80 invisible z-0')}>
                        <div className="">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {this.props.search === false ? null : (
                                    <div className="py-2 px-4">
                                        <input type="text" placeholder="Search" className="w-full px-4 py-2 rounded-full" />
                                    </div>
                                )}
                                <div className="max-h-40 overflow-scroll">
                                    { this.props.listitems &&
                                        this.props.listitems.map((item, j) => {
                                            return (
                                                <a key={j} href="#" className="block font-bold px-4 py-3 text-sm hover:text-purple-500 focus:outline-none" role="menuitem"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            dropOpen: false
                                                        })
                                                        e.preventDefault();
                                                        this.props.callfunc(item)
                                                    }}
                                                >{item.name}</a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.props.emptybool && 
                    <div>
                        <p>{this.props.emptylabel}</p>
                    </div>
                }
            </section>
        )
    }
}

export default DropDown;
