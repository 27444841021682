import React, { Component } from 'react';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import TextAreaTailwind from './textAreaTailwind';
import cn from 'classnames';
import moment from 'moment';
import UploadImage from '../components/image-upload';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import TagManager from './tagManager';

class CampaignTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {}
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign ? JSON.parse(JSON.stringify(this.props.campaign)) : {},
        })
        console.log(this.state.campaign);
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            campaign: nextProps.campaign ? JSON.parse(JSON.stringify(nextProps.campaign)) : {},
        })
        console.log(this.state.campaign);
    }

    functions = {
        update: async () => {
            this.props.onSave(this.state.campaign)
        }
    }

    calls = {}

    renders = {}

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='flex flex-col px-6'>
                <div className='grid grid-cols-3'>
                    <div className="col-span-3 mt-4">
                        <TagManager
                            campaign={this.props.campaign ? this.props.campaign : {}}
                            order={{ id: this.props.order }}
                            label={"Tags"}
                            tags={Array.isArray(this.state.campaign.tags) ? this.state.campaign.tags : []}
                            tagType={"campaign"}
                            onUpdateTags={(tags) => {
                                this.state.campaign.tags = tags;
                                if (this.props.onUpdateTags) {
                                    this.props.onUpdateTags(tags);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CampaignTags;
