import React from 'react';
import './index.css';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { BeatLoader } from "react-spinners";
import FeatherIcon from 'feather-icons-react';
var moment = require('moment');

export default class ChartTemplateChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            header: '',
            source: {},
            loading: false,
            selected_metrics: {},
            items: [],
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true
                },
                cutoutPercentage: 50,
            },
            line: {
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0
                        }
                    }]
                }
            },
            data: {},
            loading: true
        };
    }

    componentDidMount() {
        console.log('hej');
        this.setState({
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected: this.props.source ? this.props.source : {},
            static: this.props.static
        }, () => {
            if (this.state.static) {
                this.parseData();
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('hej');
        this.setState({
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected: nextProps.source ? nextProps.source : {}
        })
    }

    setLoader() {
        this.setState({
            loading: true,
        })
    }

    parseData() {
        console.log(this.state.selected);
        let title = this.state.selected.settings ? this.state.selected.settings.title : "";
        this.setState({
            header: title,
            selected_metrics: this.state.selected.metric
        }, () => {
            this.state.items = this.functions.items().filter((item) => {
                if (item.type === "campaign") {
                    return this.state.selected.data_sources.campaigns[item.id];
                } else if (item.type === "adgroup") {
                    return this.state.selected.data_sources.adgroups[item.id];
                } else if (item.type === "ad") {
                    return this.state.selected.data_sources.ads[item.id];
                } else {
                    return false
                }
            });
            this.state.items = this.state.items.map((item) => {
                item = this.functions.data(item);
                return item;
            });
            console.log(this.state.items);
            this.setState({
                items: this.state.items
            }, () => {
                this.functions.chart();
            })

        });
    }

    functions = {
        data: (item) => {
            let data = {};
            item = JSON.parse(JSON.stringify(item));

            let ref_channel = item.channel;

            if (item.channel === 'google_shopping') {
                item.channel = 'google';
            }

            console.log(this.state.selected);

            if (Array.isArray(item.insights)) {
                item.insights.map((inner_item) => {
                    if (
                        (
                            (!inner_item.daterange_start && !inner_item.daterange_end && this.state.selected.daterange.value === "lifetime") ||
                            (inner_item.daterange_start === this.state.selected.daterange.start_date &&
                                inner_item.daterange_end === this.state.selected.daterange.end_date)) &&
                        ((this.state.selected.channel_breakdowns[item.channel][inner_item.channel_breakdown]))
                    ) {
                        for (let metric in inner_item) {
                            if (this.state.selected && this.state.selected.metrics) {
                                for (let channel in this.state.selected.metrics) {
                                    if (channel === item.channel) {
                                        for (let key in this.state.selected.metrics[channel]) {
                                            if (this.state.selected.metrics[channel][key].name === metric || this.state.selected.metrics[channel][key].org_name === metric) {
                                                if (!(metric in data)) {
                                                    data[metric] = 0;
                                                }
                                                if (this.functions.isNumeric(inner_item[metric])) {
                                                    data[metric] += (+inner_item[metric] * +this.state.selected.metrics[channel][key].factor);
                                                    data[metric] = this.functions.number(data[metric]);
                                                } else {
                                                    data[metric] = inner_item[metric];
                                                }
                                            }
                                        }
                                    } else if (channel === "custom") {
                                        try {
                                            for (let custom_metric in this.state.selected.metrics[channel]) {
                                                for (let custom_metric_channel in this.state.selected.metrics[channel][custom_metric].channels) {
                                                    let item_channel = "";
                                                    if (item.channel === "instagram") {
                                                        item_channel = "facebook";
                                                    } else {
                                                        item_channel = item.channel;
                                                    }
                                                    let custom_metric_item = this.state.selected.metrics[channel][custom_metric].channels[custom_metric_channel];
                                                    if (custom_metric_channel === item_channel && custom_metric_item.name === metric) {
                                                        if (!(custom_metric in data)) {
                                                            data[custom_metric] = 0;
                                                        }
                                                        if (this.functions.isNumeric(inner_item[metric])) {
                                                            data[custom_metric] += (+inner_item[metric] * +custom_metric_item.factor);
                                                            data[custom_metric] = this.functions.number(data[custom_metric]);
                                                        } else {
                                                            data[custom_metric] = inner_item[metric];
                                                        }
                                                    }

                                                }
                                            }
                                        } catch (e) {

                                        }
                                    }
                                }
                            }
                            /*
                            if (!(metric in data)) {
                                if (this.functions.isNumeric(inner_item[metric])) {
                                    data[metric] = this.functions.number(inner_item[metric]);
                                } else {
                                    data[metric] = this.functions.number(inner_item[metric]);
                                }
                            } else {

                            }
                            */
                            if (this.functions.isNumeric(inner_item[metric])) {
                                if (!(metric in data)) {
                                    data[metric] = 0;
                                }
                                data[metric] += +inner_item[metric];
                                data[metric] = this.functions.number(inner_item[metric]);
                            } else {
                                data[metric] = inner_item[metric];
                                data[metric] = this.functions.number(inner_item[metric]);
                            }
                        }
                    }
                });


            }

            if (ref_channel === 'google_shopping') {
                item.channel = 'google_shopping';
            }

            //CALCULATE METRIC
            if (this.state.selected.metric && this.state.selected.metric.calculated) {
                let calc_string = this.state.selected.metric.calculation;
                for (let key in this.state.selected.metric.metrics) {
                    let channel_metric = this.state.selected.metric.metrics[key].channels[item.channel].name;
                    if (calc_string.indexOf("<" + key + ">") !== -1) {
                        calc_string = calc_string.replace("<" + key + ">", data[channel_metric]);
                    }
                }
                data[this.state.selected.metric.name] = this.functions.calculate(calc_string);
                data[this.state.selected.metric.name] = this.functions.number(data[this.state.selected.metric.name]);
            }

            item = { ...item, ...data };
            return item;
        },
        chart: () => {

            let data = {
                labels: [],
                datasets: []
            };

            this.state.metrics = [];
            this.state.metrics.push(this.state.selected.metric);

            let dateList = [];

            try {
                let startDate = moment(this.state.selected.daterange.start_date);
                let endDate = moment(this.state.selected.daterange.end_date);
                dateList = this.functions.getDaysBetweenDates(startDate, endDate);
            } catch (error) { }

            if (this.state.selected.channel_breakdowns) {

                let labels = {};
                let campaigns = {};

                this.state.items.map((item) => {
                    console.log(item);
                    if (this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns[item.channel] && this.state.selected.channel_breakdowns[item.channel][item.channel_breakdown]) {
                        labels[item[item.channel_breakdown]] = { key: item[item.channel_breakdown], value: item.channel_breakdown };

                        if (item.channel_breakdown === 'date') {
                            dateList.map((item) => {
                                labels[item] = { key: item, value: 'date' };
                            })
                        }

                    }
                    if (!campaigns[item.id]) {
                        campaigns[item.id] = { channel: item.channel, id: item.id, name: item.name, items: [item] };
                    } else {
                        campaigns[item.id].items.push(item);
                    }
                });

                for (let key in labels) {
                    data.labels.push(labels[key]);
                }

                //SORT ON CHANNEL BREAKDOWN
                data.labels.sort((a, b) => moment(a.key).valueOf() - moment(b.key).valueOf());
                data.labels = data.labels.map((item) => {
                    return item.key
                });

                console.log(data.labels);

                for (let campaign in campaigns) {
                    let color = "";

                    //CHECK IF CUSTOM COLOR
                    if (
                        this.state.selected.data_sources &&
                        (
                            (this.state.selected.data_sources.ads[campaign] && this.state.selected.data_sources.ads[campaign].hex) ||
                            (this.state.selected.data_sources.adgroups[campaign] && this.state.selected.data_sources.adgroups[campaign].hex) ||
                            (this.state.selected.data_sources.campaigns[campaign] && this.state.selected.data_sources.campaigns[campaign].hex)
                        )
                    ) {
                        if (this.state.selected.data_sources.ads[campaign]) {
                            color = this.state.selected.data_sources.ads[campaign].hex;
                        }
                        if (this.state.selected.data_sources.adgroups[campaign]) {
                            color = this.state.selected.data_sources.adgroups[campaign].hex;
                        }
                        if (this.state.selected.data_sources.campaigns[campaign]) {
                            color = this.state.selected.data_sources.campaigns[campaign].hex;
                        }
                    } else {
                        if (campaigns[campaign].channel === "facebook") {
                            color = "#3A5998";
                        }
                        if (campaigns[campaign].channel === "google") {
                            color = "#4384F4";
                        }
                        if (campaigns[campaign].channel === "adform") {
                            color = "#60C1A4";
                        }
                        if (campaigns[campaign].channel === "google_analytics") {
                            color = "#e8710a";
                        }
                    }

                    let dataset = {
                        label: campaigns[campaign].name,
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2,
                    };
                    if (this.state.selected.chart.value === "line") {
                        dataset.fill = false;
                        dataset.borderColor = color;
                    }
                    let labels_value = {};
                    data.labels.map((key) => {
                        campaigns[campaign].items.map((item) => {
                            if (key === item[item.channel_breakdown]) {
                                if (this.state.selected.metric.calculated) {
                                    labels_value[key] = item[this.state.selected.metric.name] ? item[this.state.selected.metric.name] : 0;
                                } else {
                                    labels_value[key] = item[this.state.selected.metric.channels[item.channel].name] ? item[this.state.selected.metric.channels[item.channel].name] : 0;
                                }
                            }
                        })
                    });

                    data.labels.map((key) => {
                        dataset.data.push(labels_value[key] ? labels_value[key] : 0);
                        if (this.state.selected.chart.value !== "line") {
                            dataset.backgroundColor.push(color);
                            dataset.borderColor.push(color);
                        }
                    });

                    data.datasets.push(dataset);
                }

            }

            console.log(data);

            this.setState({
                data: data,
                loading: false
            }, () => {
                if (this.props.onUpdateItem) {
                    this.props.onUpdateItem(this.state.data);
                }
            })

        },
        items: () => {

            console.log(this.state.selected);

            try {
                let items = [];
                if (this.state.selected.channel_breakdowns) {
                    let all_items = [];
                    all_items = all_items.concat(Array.isArray(this.state.campaigns) ? JSON.parse(JSON.stringify(this.state.campaigns)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.adgroups) ? JSON.parse(JSON.stringify(this.state.adgroups)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.ads) ? JSON.parse(JSON.stringify(this.state.ads)) : []);
                    all_items.map((campaign) => {
                        let dimension = {};
                        if (Array.isArray(campaign.insights)) {
                            campaign.insights.map((item) => {
                                if (
                                    this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns[campaign.channel] && this.state.selected.channel_breakdowns[campaign.channel][item.channel_breakdown]
                                    &&
                                    ((this.state.selected.daterange.value === "lifetime" && !item.daterange_start && !item.daterange_end) || (item.daterange_start === this.state.selected.daterange.start_date && item.daterange_end === this.state.selected.daterange.end_date))
                                ) {
                                    item.breakdown_value = item[item.channel_breakdown];
                                    if (item.breakdown_value in dimension) {
                                        dimension[item.breakdown_value].data.push(item);
                                    } else {
                                        let object = { ...campaign, ...item };
                                        dimension[item.breakdown_value] = { campaign: object, data: [] };
                                        dimension[item.breakdown_value].data.push(item);
                                    }
                                }
                            });
                        }
                        for (let dim in dimension) {
                            let campaign = { ...JSON.parse(JSON.stringify(dimension[dim].campaign)), ...{ insights: dimension[dim].data } };
                            items = items.concat([campaign]);
                        }
                    });
                }
                return items;
            } catch (e) {
                return [];
            }
        },
        number: (item) => {
            try {
                if (item === undefined) {
                    return "";
                } else {
                    if (this.functions.isNumeric(item)) {
                        return Math.round((item + Number.EPSILON) * 100) / 100;
                    } else {
                        return item;
                    }
                }
            } catch (e) {
                return item;
            }
        },
        isNumeric: (str) => {
            if (typeof str === "number") {
                return true;
            } else {
                return !isNaN(str) && !isNaN(parseFloat(str))
            }
        },
        calculate(input) {

            var f = {
                add: '+',
                sub: '-',
                div: '/',
                mlt: '*',
                mod: '%',
                exp: '^'
            };

            // Create array for Order of Operation and precedence
            f.ooo = [
                [
                    [f.mlt],
                    [f.div],
                    [f.mod],
                    [f.exp]
                ],
                [
                    [f.add],
                    [f.sub]
                ]
            ];

            input = input.replace(/[^0-9%^*\/()\-+.]/g, ''); // clean up unnecessary characters

            var output;
            for (var i = 0, n = f.ooo.length; i < n; i++) {

                // Regular Expression to look for operators between floating numbers or integers
                var re = new RegExp('(\\d+\\.?\\d*)([\\' + f.ooo[i].join('\\') + '])(\\d+\\.?\\d*)');
                re.lastIndex = 0; // take precautions and reset re starting pos

                // Loop while there is still calculation for level of precedence
                while (re.test(input)) {
                    output = _calculate(RegExp.$1, RegExp.$2, RegExp.$3);
                    if (isNaN(output) || !isFinite(output))
                        return output; // exit early if not a number
                    input = input.replace(re, output);
                }
            }

            return output;

            function _calculate(a, op, b) {
                a = a * 1;
                b = b * 1;
                switch (op) {
                    case f.add:
                        return a + b;
                        break;
                    case f.sub:
                        return a - b;
                        break;
                    case f.div:
                        return a / b;
                        break;
                    case f.mlt:
                        return a * b;
                        break;
                    case f.mod:
                        return a % b;
                        break;
                    case f.exp:
                        return Math.pow(a, b);
                        break;
                    default:
                        return null;
                }
            }
        },
        getDaysBetweenDates: (startDate, endDate) => {
            var now = startDate.clone(), dates = [];
            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
            }
            return dates;
        },
        template: () => {
            try {
                return Object.keys(this.state.selected.data_sources.campaigns).length < 1 && Object.keys(this.state.selected.data_sources.adgroups).length < 1 && Object.keys(this.state.selected.data_sources.ads).length < 1
            } catch (error) {
                return false;
            }
        }
    };

    render() {
        if (this.state.selected && this.state.selected.chart && this.state.selected.chart.value === "donut" && !this.state.loading && !this.functions.template()) {
            return (
                <div className="px-12 py-8 absolute w-full flex justify-center align-middle items-center flex-col h-full">
                    {
                        <div className="mb-4">{this.state.header !== "" ? this.state.header : "..."}</div>
                    }
                    <div className="px-12 w-full h-full">
                        <Doughnut
                            width={null}
                            height={null}
                            responsive={false}
                            options={this.state.doughnut}
                            data={this.state.data}
                        />
                    </div>
                </div>
            );
        } if (this.state.selected && this.state.selected.chart && this.state.selected.chart.value === "line" && !this.state.loading && !this.functions.template()) {
            return (
                <div className="px-12 py-8 absolute w-full flex justify-center align-middle items-center flex-col h-full">
                    {
                        <div className="mb-4">{this.state.header !== "" ? this.state.header : "..."}</div>
                    }
                    <div className="px-12 w-full h-full">
                        <Line
                            width={null}
                            height={null}
                            responsive={false}
                            options={this.state.line}
                            data={this.state.data}
                        />
                    </div>
                </div>
            );
        } if (this.state.selected && this.state.selected.chart && this.state.selected.chart.value === "bar" && !this.state.loading && !this.functions.template()) {
            return (
                <div className="px-12 py-8 absolute w-full flex justify-center align-middle items-center flex-col h-full">
                    {
                        <div className="mb-4">{this.state.header !== "" ? this.state.header : "..."}</div>
                    }
                    <div className="px-12 w-full h-full">
                        <Bar
                            width={null}
                            height={null}
                            responsive={false}
                            options={this.state.line}
                            data={this.state.data}
                        />
                    </div>
                </div>
            );
        } else if (this.state.loading) {
            return (
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={'#060534'}
                        loading={true}
                    />
                </div>
            );
        } else if (this.functions.template()) {
            return (
                <div className="w-full h-full relative flex flex-col justify-center items-center relative">
                    <button style={{ top: "15px", left: "15px" }}
                        className="transition-all duration-200 absolute px-2 font-bold text-xs leading-none flex items-center justify-center mr-1 h-8 bg-purple-100 text-purple-500 rounded">
                        <FeatherIcon className={"stroke-current"} size={15} icon={"pie-chart"} />
                        <span className="ml-2">Chart</span>
                    </button>
                    <div onClick={() => {
                        this.props.onEditItem();
                    }} className={"cursor-pointer transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center px-4 h-8 bg-purple-500 text-white rounded"}>
                        <div className="ml-2">Select datasource</div>
                    </div>
                </div>
            );
        } else {
            return (<div></div>)
        }
    }

}
