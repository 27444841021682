import React, { Component, Fragment } from 'react';
import { PlusIcon, ScissorsIcon, XIcon, ExclamationIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import cn from "classnames";
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import CreateOrderCreativeCropper from './createOrderCreativeCropper';
import { performanceMax } from '../validators/performanceMax';

class CreateOrderCreativeDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            groups: [],
            group: {},
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Headlines", value: "headlines" },
                { id: 3, name: "Long headlines", value: "long_headlines" },
                { id: 4, name: "Descriptions", value: "descriptions" },
                //{ id: 4, name: "Shopping", value: "shopping" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            creative_tab: { id: 1, name: "Image", value: "image" },
            loader: false,
            client: {},
            wizard: {},
            cropper: false
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            groups: this.props.groups,
            client: this.props.client,
            wizard: this.props.wizard
        });

        //IF ONLY ONE SELETED
        if (Array.isArray(this.state.groups) && this.state.groups.length === 1) {
            await this.promisedSetState({
                group: this.state.groups[0]
            });
        } else if (this.state.groups && !Array.isArray(this.state.groups)) {
            await this.promisedSetState({
                group: this.state.groups
            });
        } else {
            this.state.group.images = [];
            this.state.group.videos = [];
            this.state.group.long_headlines = [];
            this.state.group.long_headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines = [];
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies = [];
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            await this.promisedSetState({
                group: this.state.group
            });
        }

    }

    functions = {
        save: async () => {
            if (this.props.onSave) {
                this.props.onSave(this.state.group);
            }
        },
        onSelectFile: async (e, type) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        //this.functions.uploadVideo(files[i]);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        this.functions.uploadImage(files[i], type);
                    }
                    const randomString = Math.random().toString(36);
                    this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
        },
        uploadImage: async (file, type) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                type: type
            };
            this.state.group.images.push(files_object);
            await this.promisedSetState({ group: this.state.group });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.image(formData);
                this.state.group.images = this.state.group.images.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                        item = this.functions.errorHandlerImage(item, type);
                    }
                    return item;
                });
                await this.promisedSetState({ group: this.state.group });

            } catch (error) {
                this.state.group.images = this.state.group.images.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ group: this.state.group });
            }
        },
        errorHandlerImage: (item, type) => {
            if (item.size > 5110000) {
                item.error = true;
                item.message = "Image is to large, max size 5120KB";
            } else if (type === "portrait" && item.ratio !== "4:5") {
                item.error = true;
                item.message = "Ratio not allowed, cropp image";
            } else if (type === "landscape" && item.ratio !== "1.91:1") {
                item.error = true;
                item.message = "Ratio not allowed, cropp image";
            } else if ((type === "square" || type === "logo_square") && item.ratio !== "1:1") {
                item.error = true;
                item.message = "Ratio not allowed, cropp image";
            } else if (type === "portrait" && (item.width < 480 || item.height < 600)) {
                item.error = true;
                item.message = "Image is to small";
            } else if (type === "landscape" && (item.width < 314 || item.height < 600)) {
                item.error = true;
                item.message = "Image is to small";
            } else if (type === "square" && (item.width < 300 || item.height < 300)) {
                item.error = true;
                item.message = "Image is to small";
            } else if (type === "logo_square" && (item.width < 128 || item.height < 128)) {
                item.error = true;
                item.message = "Image is to small";
            } else {
                delete item.error;
                delete item.message;
            }
            return item;
        },
        uploadVideo: async (file) => {
            /*
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                type: 'video'
            };
            this.state.creatives.push(files_object);
            await this.promisedSetState({ creatives: this.state.creatives });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                if (response && response.status === 200) {
                    self.state.creatives = self.state.creatives.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ creatives: self.state.creatives });
                    await self.form.set();
                    self.props.setSteps();
                } else {
                    self.state.creatives = self.state.creatives.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ creatives: self.state.creatives });
                }
                channel.unsubscribe(channel_id);
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video(formData, channel_id);
            } catch (error) {
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
            */
        },
        uploadYoutube: async () => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                //name: file.name,
                type: "youtube"
            };
            if (this.state.youtube_url.indexOf("https://www.youtube.com/watch") !== -1) {
                try {
                    var video_id = this.state.youtube_url.split('v=')[1];
                    var ampersandPosition = video_id.indexOf('&');
                    if (ampersandPosition != -1) {
                        video_id = video_id.substring(0, ampersandPosition);
                    }
                    files_object.youtube_id = video_id;
                } catch (error) {
                    files_object.error = true;
                }
            } else if (this.state.youtube_url.indexOf("https://www.youtube.com/shorts") !== -1) {
                files_object.youtube_id = this.state.youtube_url.replace("https://www.youtube.com/shorts/", "");
            } else {
                files_object.loading = false;
                files_object.error = true;
            }
            if (!Array.isArray(this.state.group.videos)) {
                this.state.group.videos = [];
            }
            this.state.group.videos.push(files_object);
            await this.promisedSetState({ group: this.state.group, youtube_url: "" });
            console.log(this.state.group.videos);
            if (!files_object.error && files_object.youtube_id) {
                try {
                    let response = await this.calls.getYoutubeData(files_object.youtube_id);
                    if (response) {
                        this.state.group.videos = this.state.group.videos.map((item) => {
                            if (item.id === files_object.id) {
                                item.loading = false;
                                if (item.duration < 10) {
                                    item.error = true;
                                    item.message = "Video is to short";
                                } else {
                                    item = { ...item, ...response.data };
                                }
                            }
                            return item;
                        });
                        await this.promisedSetState({ group: this.state.group });
                        console.log(this.state.group.videos);
                    }
                } catch (error) {

                }
            }
        },
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/updateAdgroup?client=" + this.state.client.id + "&id=" + this.state.adgroup.id;
            return apiRegister.call(options, url);
        },
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        video: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        getYoutubeData: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/getYoutubeVideoData?id=" + id;
            return apiRegister.call(options, url);
        },
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-12 gap-4">

                    {
                        this.state.cropper &&
                        <div className="bg-white top-0 z-50 left-0 absolute right-0 bottom-0 bg-opacity-75 py-20 pt-10 px-32">
                            <div className="w-full h-full relative">
                                <div className="bg-black rounded-lg overflow-hidden left-0 absolute top-0 right-0 bottom-0 ">
                                    <CreateOrderCreativeCropper
                                        ref={"CreateOrderCreativeCropper"}
                                        wizard={this.state.wizard.id}
                                        files={[{ type: this.state.cropper.type, url: this.state.cropper.url }]}
                                        allowedAspects={this.state.cropper.allowed_aspects}
                                        aspectName={this.state.cropper.aspect_name}
                                        aspect={this.state.cropper.aspect}
                                        onClose={() => {
                                            this.setState({
                                                cropper: null
                                            })
                                        }}
                                        onNew={async (media) => {
                                            this.state.group.images = this.state.group.images.map((item) => {
                                                if (item.id === this.state.cropper.id) {
                                                    item.id = media.id;
                                                    item.url = media.url;
                                                    item.format = media.format;
                                                    item.size = media.size;
                                                    item.ratio = media.ratio;
                                                    item.size_pretty = media.size_pretty;
                                                    item.size_pretty = media.size_pretty;
                                                    item = this.functions.errorHandlerImage(item, item.type);
                                                }
                                                return item;
                                            })
                                            this.setState({
                                                group: this.state.group,
                                                cropper: null
                                            });
                                        }}
                                        onLoader={(value) => {
                                            this.setState({
                                                cropper_loader: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.cropper_loader &&
                                    <div className="top-0 left-0 absolute right-0 bottom-0 bg-black bg-opacity-75 inset-0 flex justify-center items-center z-20 rounded-lg">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-white border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </div>
                            {
                                !this.state.cropper_loader &&
                                <div className="w-full flex justify-center items-center pt-8">
                                    <button
                                        onClick={() => {
                                            this.refs.CreateOrderCreativeCropper.functions.crop();
                                        }}
                                        className={"inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 py-2 px-12 text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        Cropp Image
                                    </button>
                                </div>
                            }
                        </div>
                    }

                    <div className="col-span-6">
                        <div className="col-span-6 grid grid-cols-6 gap-4">
                            <div className="overflow-scroll col-span-6">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {[{ id: 1, name: "Image", value: "image" }, { id: 3, name: "Youtube", value: "youtube" }].map((tab) => (
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    creative_tab: tab
                                                });
                                            }}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={cn(
                                                tab.id == this.state.creative_tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                            )}
                                            aria-current={tab.id == this.state.creative_tab.id ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                            {
                                [
                                    { type: "youtube", prefix_title: "Youtube videos (", suffix_title: "/5)", subtitle: "horizontal, vertical, or square, >=10 seconds in length", max: 5, property: "youtube" },
                                    { type: "image", prefix_title: "Landscape images (", suffix_title: "/20)", subtitle: "(1.91:1) 1200 x 628 recommended; 600 x 314 min; 5120KB max file size", max: 20, property: "landscape", aspect: (400 / 209), aspect_name: "1.91:1", allowed_aspects: { "1.91:1": true } },
                                    { type: "image", prefix_title: "Square images (", suffix_title: "/20)", subtitle: "(1:1) 1200 x 1200 recommended; 300 x 300 min; 5120KB max file size", max: 20, property: "square", aspect: (1 / 1), aspect_name: "1:1", allowed_aspects: { "1:1": true } },
                                    { type: "image", prefix_title: "Logo square (", suffix_title: "/5)", subtitle: "(1:1) 1200 x 1200 recommended; 128 x 128 min; 5120KB max file size", max: 5, property: "logo_square", aspect: (1 / 1), aspect_name: "1:1", allowed_aspects: { "1:1": true } },
                                    { type: "image", prefix_title: "Portrait images (", suffix_title: "/20)", subtitle: "(4:5) 960 x 1200 recommended; 480 x 600 min; 5120KB max file size", max: 20, property: "portrait", aspect: (4 / 5), aspect_name: "4:5", allowed_aspects: { "4:5": true } }
                                ].filter((item) => {
                                    return item.type === this.state.creative_tab.value
                                }).map((format) => {
                                    return (
                                        <Fragment>
                                            <div className="text-sm font-medium col-span-6">
                                                {
                                                    format.type === "image" &&
                                                    <div className="">
                                                        {format.prefix_title}{Array.isArray(this.state.group.images) ? this.state.group.images.filter((item) => { return item.type === format.property }).length : 0}{format.suffix_title} {performanceMax.validate(this.state.group)[format.property] && <span className="text-red-500 ml-2">OBS! Minimum 1</span>}
                                                    </div>
                                                }
                                                {
                                                    format.type === "youtube" &&
                                                    <div className="">
                                                        {format.prefix_title}{Array.isArray(this.state.group.videos) ? this.state.group.videos.filter((item) => { return item.type === "youtube" }).length : 0}{format.suffix_title}
                                                    </div>
                                                }
                                                <div className="text-xs mt-1">
                                                    {format.subtitle}
                                                </div>
                                            </div>
                                            <div className="col-span-6 pr-20">
                                                {
                                                    format.type === "youtube" &&
                                                    (Array.isArray(this.state.group.videos) ? this.state.group.videos : []).filter((item) => {
                                                        return item.type === format.property
                                                    }).length < format.max &&
                                                    <div className="w-full mb-4">
                                                        <div className="w-full -mt-1">
                                                            <InputTailwind
                                                                value={this.state.youtube_url}
                                                                placeholder={"Youtube url"}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        youtube_url: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="inline-flex mt-4">
                                                            <div onClick={() => {
                                                                this.functions.uploadYoutube();
                                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                                <PlusIcon className="h-5 w-5 mr-1" /> Add youtube video
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="flex flex-row">
                                                    {
                                                        format.type === "image" &&
                                                        Array.isArray(this.state.group.images) &&
                                                        this.state.group.images.filter((item) => {
                                                            return item.type === format.property
                                                        }).length < format.max &&
                                                        <div>
                                                            <div key={this.state.theInputKeyOne || ''} className="w-24 h-24 flex cursor-pointer justify-center mr-2 items-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                                <PlusIcon className="w-5 h-5 text-purple-500" />
                                                                <input
                                                                    onChange={(e) => this.functions.onSelectFile(e, format.property)}
                                                                    type="file"
                                                                    multiple
                                                                    accept={"image/png, image/jpg, image/jpeg"}
                                                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        format.type === "video" &&
                                                        Array.isArray(this.state.group.images) &&
                                                        this.state.group.images.filter((item) => {
                                                            return item.type === format.property
                                                        }).length < format.max &&
                                                        <div>
                                                            <div key={this.state.theInputKeyOne || ''} className="w-24 h-24 flex cursor-pointer justify-center mr-2 items-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                                <PlusIcon className="w-5 h-5 text-purple-500" />
                                                                <input
                                                                    onChange={(e) => this.functions.onSelectFile(e, format.property)}
                                                                    type="file"
                                                                    multiple
                                                                    accept={"video/*"}
                                                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="overflow-scroll flex flex-row w-full flex-1">
                                                        {
                                                            (format.type === "image" ? (Array.isArray(this.state.group.images) ? this.state.group.images : []) : Array.isArray(this.state.group.videos) ? this.state.group.videos : []).filter((item) => {
                                                                return item.type === format.property
                                                            }).map((item) => {
                                                                return (
                                                                    <div>
                                                                        <div className={(item.placeholder ? "opacity-25 border-purple-500" : (item.error ? "border-red-500" : "border-gray-300")) + " w-24 h-24 rounded-md relative bg-purple-100 mr-2 border-1.5"}>
                                                                            {
                                                                                format.type === "image" &&
                                                                                <div className="w-full h-full overflow-hidden rounded-md">
                                                                                    <div className="object-cover w-full h-full" style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                format.type === "youtube" &&
                                                                                <div className="w-full h-full overflow-hidden rounded-md">
                                                                                    <iframe src={"https://www.youtube.com/embed/" + item.youtube_id} frameborder={"0"} styles={{ "overflow": "hidden", "height": "100%", "width": "100%" }} height={"100%"} width={"100%"}></iframe>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.loading &&
                                                                                <div className="w-full h-full absolute inset-0 flex justify-center items-center z-20 rounded-lg">
                                                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !item.placeholder &&
                                                                                !item.loading &&
                                                                                item.error &&
                                                                                <div className="w-full h-full absolute cursor-pointer inset-0 flex justify-center bg-red-500 bg-opacity-75 items-center z-10 rounded-lg">
                                                                                    <div className="h-full w-full relative flex items-center justify-center text-xxs text-center text-white font-medium">
                                                                                        {item.message}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !item.placeholder &&
                                                                                !item.loading &&
                                                                                <div className="w-full h-full absolute cursor-pointer inset-0 flex justify-center items-center z-20 rounded-lg hover:opacity-100 opacity-0">
                                                                                    <div className="h-full w-full relative">
                                                                                        <div className="bg-black bg-opacity-75 h-full w-full"></div>
                                                                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center flex-row">
                                                                                            {
                                                                                                format.type !== "youtube" &&
                                                                                                <div onClick={() => {
                                                                                                    this.setState({
                                                                                                        cropper: {
                                                                                                            id: item.id,
                                                                                                            url: item.url,
                                                                                                            type: "image",
                                                                                                            aspect: format.aspect,
                                                                                                            aspect_name: format.aspect_name,
                                                                                                            allowed_aspects: format.allowed_aspects
                                                                                                        }
                                                                                                    })
                                                                                                }} className="rounded-full w-8 h-8 bg-purple-500 text-white flex justify-center items-center mr-1">
                                                                                                    <ScissorsIcon className="w-4 h-4" />
                                                                                                </div>
                                                                                            }
                                                                                            <div onClick={() => {
                                                                                                if (format.type === "image") {
                                                                                                    this.state.group.images = this.state.group.images.filter((inner_item) => {
                                                                                                        return inner_item.id !== item.id
                                                                                                    });
                                                                                                    this.setState({
                                                                                                        group: this.state.group
                                                                                                    });
                                                                                                } else {
                                                                                                    this.state.group.videos = this.state.group.videos.filter((inner_item) => {
                                                                                                        return inner_item.id !== item.id
                                                                                                    });
                                                                                                    this.setState({
                                                                                                        group: this.state.group
                                                                                                    });
                                                                                                }
                                                                                            }} className="rounded-full w-8 h-8 bg-red-500 text-white flex justify-center items-center ml-1">
                                                                                                <XIcon className="w-4 h-4" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                <div className="w-full h-10"></div>
                            }
                        </div>
                    </div>

                    <div className="col-span-6">
                        <div className="grid grid-cols-6 gap-4">
                            <div className="block overflow-scroll col-span-6">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {this.state.tabs.map((tab) => (
                                        <>
                                            <div
                                                onClick={() => (
                                                    this.setState({
                                                        tab: tab
                                                    })
                                                )}
                                                key={tab.name}
                                                style={{ whiteSpace: "nowrap" }}
                                                className={cn(
                                                    tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                    'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                                )}
                                                aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                            >
                                                {tab.name}

                                                {
                                                    ((tab.value == "headlines" && performanceMax.validate(this.state.group).headlines) || (tab.value == "descriptions" && performanceMax.validate(this.state.group).descriptions) || (tab.value == "long_headlines" && performanceMax.validate(this.state.group).long_headlines) || (tab.value == "basic" && ["name", "business", "link"].map((item) => { return performanceMax.validate(this.state.group)[item] }).filter((item) => { return item }).length > 0)) &&
                                                    <div className='text-red-600'>
                                                        <ExclamationIcon className="h-5 w-5 text-red-600 ml-2 flex" />
                                                    </div>
                                                }


                                            </div>

                                        </>
                                    ))}
                                </nav>
                            </div>

                            {
                                this.state.tab.value == "basic" &&
                                <>
                                    <div className="text-sm font-medium col-span-6">
                                        Ad information
                                    </div>
                                    <div className="col-span-6">
                                        <InputTailwind
                                            error={performanceMax.validate(this.state.group).name}
                                            label={"Name"}
                                            value={this.state.group.name}
                                            onChange={(value) => {
                                                this.state.group.name = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <InputTailwind
                                            error={performanceMax.validate(this.state.group).link}
                                            label={"Website"}
                                            value={this.state.group.link}
                                            onChange={(value) => {
                                                this.state.group.link = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <InputTailwind
                                            error={performanceMax.validate(this.state.group).business}
                                            label={"Company name"}
                                            value={this.state.group.business}
                                            onChange={(value) => {
                                                this.state.group.business = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Call to action"}
                                            searchInput={true}
                                            selected={this.state.group.cta ? this.state.group.cta : { id: 1, name: "Auto" }}
                                            options={[
                                                { id: 1, name: "Auto", value: "AUTO" },
                                                { id: 2, name: "Apply Now", value: "APPLY_NOW" },
                                                { id: 3, name: "Book Now", value: "BOOK_NOW" },
                                                { id: 4, name: "Contact Us", value: "CONTACT_US" },
                                                { id: 5, name: "Download", value: "DOWNLOAD" },
                                                { id: 6, name: "Learn More", value: "LEARN_MORE" },
                                                //{ id: 7, name: "Install", value: "INSTALL" },
                                                //{ id: 8, name: "Visit Site", value: "VISIT_SITE" },
                                                { id: 9, name: "Shop Now", value: "SHOP_NOW" },
                                                { id: 10, name: "Sign Up", value: "SIGN_UP" },
                                                { id: 11, name: "Get Quote", value: "GET_QUOTE" },
                                                { id: 12, name: "Subscribe", value: "SUBSCRIBE" },
                                                //{ id: 13, name: "See More", value: "See More" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.group.cta = value;
                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            {
                                this.state.tab.value == "headlines" &&
                                <>
                                    <div className="text-sm font-medium col-span-6">
                                        Headlines ({this.state.group.headlines.length}/5)
                                        {
                                            performanceMax.validate(this.state.group).headlines &&
                                            <span className=' text-red-600 ml-2'>OBS! Minimum 3</span>
                                        }
                                    </div>
                                    {
                                        Array.isArray(this.state.group.headlines) &&
                                        this.state.group.headlines.map((item, index) => {
                                            return (
                                                <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-6"}>
                                                    <InputTailwind
                                                        error={item.text === "" && performanceMax.validate(this.state.group).headlines ? true : false}
                                                        value={item.text}
                                                        label={(index + 1) + " Headline (" + item.text.length + "/30)"}
                                                        onChange={async (value) => {
                                                            item.text = value;
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        maxLength={30}
                                                        rightLabel={this.state.group.headlines.length > 3}
                                                        rightLabelColor={"hover:text-red-700 text-red-500"}
                                                        rightLabelText={"Remove"}
                                                        onRightLabelClick={() => {
                                                            this.state.group.headlines = this.state.group.headlines.filter((headline) => { return headline.id !== item.id });
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        onBlur={() => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        Array.isArray(this.state.group.headlines) && this.state.group.headlines.length < 5 &&
                                        <div className="col-span-6">
                                            <div className="inline-flex">
                                                <div onClick={() => {
                                                    this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                    this.setState({
                                                        group: this.state.group
                                                    });
                                                }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                    <PlusIcon className="h-5 w-5 mr-1" /> Add new headline
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        <div className="col-span-6 h-10"></div>
                                    }
                                </>
                            }

                            {
                                this.state.tab.value == "long_headlines" &&
                                <>
                                    <div className="text-sm font-medium col-span-6">
                                        Long headlines ({this.state.group.long_headlines.length}/5)
                                        {
                                            performanceMax.validate(this.state.group).long_headlines &&
                                            <span className=' text-red-600 ml-2'>OBS! Minimum 1</span>
                                        }
                                    </div>
                                    {
                                        Array.isArray(this.state.group.long_headlines) &&
                                        this.state.group.long_headlines.map((item, index) => {
                                            return (
                                                <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-6"}>
                                                    <InputTailwind
                                                        error={item.text === "" && performanceMax.validate(this.state.group).long_headlines ? true : false}
                                                        value={item.text}
                                                        label={(index + 1) + " Long headline (" + item.text.length + "/90)"}
                                                        onChange={async (value) => {
                                                            item.text = value;
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        maxLength={90}
                                                        rightLabel={this.state.group.long_headlines.length > 3}
                                                        rightLabelColor={"hover:text-red-700 text-red-500"}
                                                        rightLabelText={"Remove"}
                                                        onRightLabelClick={() => {
                                                            this.state.group.long_headlines = this.state.group.long_headlines.filter((headline) => { return headline.id !== item.id });
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        onBlur={() => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        Array.isArray(this.state.group.long_headlines) && this.state.group.long_headlines.length < 5 &&
                                        <div className="col-span-6">
                                            <div className="inline-flex">
                                                <div onClick={() => {
                                                    this.state.group.long_headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                    this.setState({
                                                        group: this.state.group
                                                    });
                                                }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                    <PlusIcon className="h-5 w-5 mr-1" /> Add new headline
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        <div className="col-span-6 h-10"></div>
                                    }
                                </>
                            }

                            {
                                this.state.tab.value == "descriptions" &&
                                <>
                                    <div className="text-sm font-medium col-span-6">
                                        Descriptions ({this.state.group.bodies.length}/5)
                                        {
                                            performanceMax.validate(this.state.group).descriptions &&
                                            <span className=' text-red-600 ml-2'>OBS! Minimum 2</span>
                                        }
                                    </div>
                                    {
                                        Array.isArray(this.state.group.bodies) &&
                                        this.state.group.bodies.map((item, index) => {
                                            return (
                                                <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-6"}>
                                                    <TextAreaTailwind
                                                        error={item.text === "" && performanceMax.validate(this.state.group).descriptions ? true : false}
                                                        value={item.text}
                                                        label={(index + 1) + " Descripton (" + item.text.length + "/ " + (index < 1 ? 60 : 90) + ") "}
                                                        onChange={async (value) => {
                                                            item.text = value;
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        rows={2}
                                                        maxLength={index < 1 ? 60 : 90}
                                                        rightLabel={index > 0 && this.state.group.bodies.length > 2}
                                                        rightLabelColor={"hover:text-red-700 text-red-500"}
                                                        rightLabelText={"Remove"}
                                                        onRightLabelClick={() => {
                                                            this.state.group.bodies = this.state.group.bodies.filter((headline) => { return headline.id !== item.id });
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        onBlur={() => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        Array.isArray(this.state.group.bodies) && this.state.group.bodies.length < 4 &&
                                        <div className="col-span-6">
                                            <div className="inline-flex">
                                                <div onClick={() => {
                                                    this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                    this.setState({
                                                        group: this.state.group
                                                    });
                                                }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                    <PlusIcon className="h-5 w-5 mr-1" /> Add new description
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                        </div>

                    </div>

                </div>
            </>
        )
    }
}

export default CreateOrderCreativeDisplay;
