import React, { Component } from 'react';
import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import cn from 'classnames';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import InputTailwind from '../moduleFiles/inputTailwind';
import UploadImage from '../components/image-upload';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { isNumber, round } from '@turf/turf';
import AccountSnapchat from '../moduleFiles/accountSnapchat';
import AccountLinkedin from '../moduleFiles/accountLinkedin';
import AccountFacebook from '../moduleFiles/accountFacebook';
import AccountGoogle from '../moduleFiles/accountGoogle';
import AccountTwitter from '../moduleFiles/accountTwitter';
import AccountTiktok from '../moduleFiles/accountTiktok';
import AccountGoogleAnalytics from '../moduleFiles/accountGoogleAnalytics';
import AccountGoogleAnalytics4 from '../moduleFiles/accountGoogleAnalytics4';
import AccountDv360 from '../moduleFiles/accountDv360';
import AccountBing from '../moduleFiles/accountBing';
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
import formated_timezones from '../assets/json/formated_timezones.json';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';


class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agent: {},
            loader: true,
            loading: {
                settings: false,
                user: true,
                download: false,
                max_limit: false,
                remove_cache: false,
                timezone: false,
                import: false,
            },
            name: "",
            email: "",
            company: "",
            logo: "",
            status: { name: "Active", value: "active" },
            premium: { name: "Enabled", value: true },
            test: { id: 0, name: "Live account", value: false },
            agency: {},
            tabs: [
                { id: 0, name: "General settings" },
                { id: 1, name: "Report settings" },
                //{ id: 3, name: "Budget split" },
                //{ id: 4, name: "Google default settings" },
                //{ id: 5, name: "Payment settings (create Ad-account)" },
                //{ id: 6, name: "Performance overview" },
                //{ id: 7, name: "Max budget limits" },
                { id: 9, name: "Platform settings" },
                { id: 10, name: "Email settings" }
            ],
            sub_tabs: [{ id: 0, name: "Generic" }, { id: 1, name: "Navbar" }, { id: 2, name: "Footer" }],
            tab: { id: 0, name: "General settings" },
            sub_tab: { id: 0, name: "Generic" },
            selectedId: "",
            whitelabel_settings: {
                color_accent: "",
                color_navbar: "",
                color_font: "000000",
                color_tab_font: "000000",
                color_background: "",
                color_generic_font: "000000",
                logo_size: { id: 2, name: "Medium", value: "h-5" },
                shadows: false,
                logo_primary: "",
            },
            client_settings: {
                color_font: "000000",
                color_accent: "",
                logo_size: { id: 2, name: "Medium", value: "h-5" },
                logo_primary: "",
                logo_secondary: "",
                public_orders: true,
            },
            googleDefaultSettings: {
            },
            googlePaymentsAccountId: "",
            open: {
                remove_modal: false,
            },
            timezone: {},
            timezones: formated_timezones,
            remove: null,
            remove_name: "",
            errors: {
                agent: false
            },
            templates: [],
            templates_search: '',
            templates_limit: 10,
            templates_page: 1,
            templates_total: 0,
            defaultMasterTemplate: {},
            throttling: {},
            agents: [],
            agents_search: '',
            agents_limit: 10,
            agents_page: 1,
            agents_total: 0,
        };
    }

    componentDidMount() {
        this.functions.agency();
        this.functions.getTemplates(true);
        //this.functions.getAgents(true);
    }

    functions = {
        agency: async () => {
            try {
                let response = await this.calls.getAgency();
                await this.promisedSetState({
                    agency: response.data,
                });
                await this.promisedSetState({
                    loading: {},
                    name: this.state.agency.name,
                    email: this.state.agency.email,
                    logo: this.state.agency.logo,
                    company: this.state.agency.company,
                    test: this.state.agency.test ? { id: 1, name: "Test account", value: true } : { id: 0, name: "Live account", value: false },
                    status: this.state.agency.status === "active" ? { name: "Active", value: "active" } : { name: "Disabled", value: "disabled" },
                    premium: this.state.agency.showPremium ? { name: "Enabled", value: true } : { name: "Disabled", value: false },
                    tab: { id: 0, name: "General settings" },
                    sub_tab: { id: 0, name: "Other settings" },
                    defaultMasterTemplate: this.state.agency.defaultMasterTemplate ? this.state.agency.defaultMasterTemplate : {},
                    whitelabel_settings: {
                        color_accent: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_accent : "",
                        color_navbar: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_navbar : "",
                        color_font: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_font : "000000",
                        color_tab_font: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_tab_font : "000000",
                        color_generic_font: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_generic_font : "000000",
                        color_background: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_background : "",
                        logo_size: this.state.agency.whitelabel ? this.state.agency.whitelabel.logo_size : { id: 2, name: "Medium", value: "h-5" },
                        shadows: this.state.agency.whitelabel ? this.state.agency.whitelabel.shadows : false,
                        logo_primary: this.state.agency.whitelabel ? this.state.agency.whitelabel.logo_primary : "",
                        link_1: this.state.agency.whitelabel && this.state.agency.whitelabel.link_1 ? this.state.agency.whitelabel.link_1 : { id: 1, name: "", value: "", url: "" },
                        link_2: this.state.agency.whitelabel && this.state.agency.whitelabel.link_2 ? this.state.agency.whitelabel.link_2 : { id: 2, name: "", value: "", url: "" },
                        link_3: this.state.agency.whitelabel && this.state.agency.whitelabel.link_3 ? this.state.agency.whitelabel.link_3 : { id: 3, name: "", value: "", url: "" },
                        link_4: this.state.agency.whitelabel && this.state.agency.whitelabel.link_4 ? this.state.agency.whitelabel.link_4 : { id: 4, name: "", value: "", url: "" },
                        link_5: this.state.agency.whitelabel && this.state.agency.whitelabel.link_5 ? this.state.agency.whitelabel.link_5 : { id: 5, name: "", value: "", url: "" },
                        footer: this.state.agency.whitelabel ? this.state.agency.whitelabel.footer : false,
                        color_footer: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_footer : "",
                        color_footer_font: this.state.agency.whitelabel ? this.state.agency.whitelabel.color_footer_font : "",
                        footer_text: this.state.agency.whitelabel ? this.state.agency.whitelabel.footer_text : "",
                        copyright: this.state.agency.whitelabel ? this.state.agency.whitelabel.copyright : false,
                        datelock: this.state.agency.whitelabel ? this.state.agency.whitelabel.datelock : false
                    },
                    googlePaymentsAccountId: this.state.agency.googlePaymentsAccountId,
                    bingPaymentsAccountId: this.state.agency.bingPaymentsAccountId,
                    bingPrimaryUser: this.state.agency.bingPrimaryUser,
                    googleAdsImportCredentialID: this.state.agency.googleAdsImportCredentialID,
                    googleDefaultSettings: {
                        searchNetwork: this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.searchNetwork ? this.state.agency.googleDefaultSettings.searchNetwork : { id: 2, name: "Disabled", value: false },
                        contentNetwork: this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.contentNetwork ? this.state.agency.googleDefaultSettings.contentNetwork : { id: 2, name: "Disabled", value: false },
                        partnerSearchNetwork: this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.partnerSearchNetwork ? this.state.agency.googleDefaultSettings.partnerSearchNetwork : { id: 2, name: "Disabled", value: false },
                    },
                    automatedEmails: this.state.agency.automatedEmails,
                    automatedEmailsSendgridTemplateReport: this.state.agency.automatedEmailsSendgridTemplateReport,
                    automatedEmailsFrequency: this.state.agency.automatedEmailsFrequency,
                    automatedEmailsWeekday: this.state.agency.automatedEmailsWeekday,
                    automatedEmailsAfterOrderStart: this.state.agency.automatedEmailsAfterOrderStart,
                    automatedEmailsAfterOrderStartDays: this.state.agency.automatedEmailsAfterOrderStartDays,
                    automatedEmailsSendgridSenderID: this.state.agency.automatedEmailsSendgridSenderID,

                    bingScaleSettings: this.state.agency.bingScaleSettings ? this.state.agency.bingScaleSettings : {},
                    googleScaleSettings: this.state.agency.googleScaleSettings ? this.state.agency.googleScaleSettings : {},

                    /*
                    client_settings: {
                        color_font: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.color_font : "000000",
                        color_accent: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.color_accent : "",
                        logo_size: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.logo_size : { id: 2, name: "Medium", value: "h-5" },
                        logo_primary: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.logo_primary : "",
                        logo_secondary: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.logo_secondary : "",
                        public_orders: this.state.agency.whitelabelClient ? this.state.agency.whitelabelClient.public_orders : true,
                    },
                    */

                });
                this.functions.getAgent();
            } catch (error) {
                console.log(error)
            }
        },
        updateAgency: async () => {
            if (!this.state.loading_settings) {
                await this.promisedSetState({
                    loading_settings: true
                });
                try {
                    let agency = {
                        //whitelabelClient: this.state.client_settings,
                        defaultMasterTemplate: this.state.defaultMasterTemplate,
                        name: this.state.name,
                        email: this.state.email,
                        company: this.state.company,
                        logo: this.state.logo,
                        status: this.state.status.value,
                        showPremium: this.state.premium.value,
                        test: this.state.test.value,
                        whitelabel: this.state.whitelabel_settings,
                        ...(this.state.agency.budgetSplit && { budgetSplit: this.state.agency.budgetSplit }),
                        googleDefaultSettings: this.state.googleDefaultSettings,
                        googlePaymentsAccountId: this.state.googlePaymentsAccountId,
                        bingPaymentsAccountId: this.state.bingPaymentsAccountId,
                        bingPrimaryUser: this.state.bingPrimaryUser,
                        googleAdsImportCredentialID: this.state.googleAdsImportCredentialID,
                        automatedEmails: this.state.agency.automatedEmails,
                        automatedEmailsSendgridTemplateReport: this.state.agency.automatedEmailsSendgridTemplateReport,
                        automatedEmailsFrequency: this.state.agency.automatedEmailsFrequency,
                        automatedEmailsWeekday: this.state.agency.automatedEmailsWeekday,
                        automatedEmailsAfterOrderStart: this.state.agency.automatedEmailsAfterOrderStart,
                        automatedEmailsAfterOrderStartDays: this.state.agency.automatedEmailsAfterOrderStartDays,
                        automatedEmailsSendgridSenderID: this.state.agency.automatedEmailsSendgridSenderID,
                        bingScaleSettings: this.state.bingScaleSettings,
                        googleScaleSettings: this.state.googleScaleSettings
                    };
                    await this.calls.updateAgency(agency);
                } catch (error) { }
                await this.promisedSetState({
                    loading_settings: false
                });
            }
        },
        getUser: async () => {
            await this.promisedSetState({
                agent: userRegister.get() ? userRegister.get() : {}
            })
            console.log(this.state.agent);
        },
        getAgent: async () => {
            this.state.loading.user = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgent();
                await this.promisedSetState({ agent: response.data });
                if (Object.keys(response.data).length === 0) {
                    this.state.errors.user = false
                } else {
                    this.state.errors.user = true
                }
                await this.promisedSetState({ errors: this.state.errors });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.user = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
                console.log(error)
            }
            this.state.loading.user = false
            await this.promisedSetState({ loading: this.state.loading, loader: false });
        },
        updateAgencyFromManager: async () => {
            this.state.loading.timezone = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                await this.calls.updateAgencyFromManager({
                    timezone: this.state.agent.agency_timezone,
                    external_link: this.state.agent.agency_external_link,
                    external_link_password: this.state.agent.agency_external_link_password
                });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.timezone = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
                console.log(error)
            }
            this.state.loading.timezone = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        updateAgencyMaxLimits: async () => {
            this.state.loading.max_limit = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                await this.calls.updateAgencyFromManager({
                    maxBudgetPercentageLimitFacebook: this.state.agent.maxBudgetPercentageLimitFacebook,
                    maxBudgetPercentageLimitBing: this.state.agent.maxBudgetPercentageLimitBing,
                    maxBudgetPercentageLimitTiktok: this.state.agent.maxBudgetPercentageLimitTiktok,
                    maxBudgetPercentageLimitSnapchat: this.state.agent.maxBudgetPercentageLimitSnapchat,
                    maxBudgetPercentageLimitLinkedin: this.state.agent.maxBudgetPercentageLimitLinkedin
                });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.max_limit = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
            }
            this.state.loading.max_limit = false;
            await this.promisedSetState({ loading: this.state.loading });
        },
        removeCachedData: async () => {
            this.state.loading.remove_cache = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.removeCachedData();
            } catch (error) { }
            this.state.loading.remove_cache = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        importClients: async (data) => {
            this.state.loading.import = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                await this.calls.importClients({ clients: data });
            } catch (error) { }
            this.state.loading.import = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgencyClients: async () => {
            this.state.loading.download = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgencyClients();
                this.functions.exportToCSv(response.data);
            } catch (error) { }
            this.state.loading.download = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgencyOrders: async () => {
            this.state.loading.download = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgencyOrders();
                this.functions.exportOrdersToCSv(response.data);
            } catch (error) { }
            this.state.loading.download = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgencyReports: async () => {
            await this.promisedSetState({ loading_download: true });
            try {
                let response = await this.calls.getAgencyReports();
                this.functions.exportDuplicatesToCSv(response.data);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_download: false });
        },
        getAgents: async (init, paginaton, search, channel) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents(channel);
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        agents_total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        agents_total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        getTemplates: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_templates_pagination: paginaton,
                loading_templates: init
            });
            try {
                let response = await this.calls.getTemplates();
                if (!search_value || (search_value && search_value == this.state.templates_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            templates: response.data,
                            templates_total: response.meta.total
                        });
                    } else {
                        this.state.templates = this.state.templates.concat(response.data);
                        await this.promisedSetState({
                            templates: this.state.templates,
                            templates_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_templates_search: false,
                loading_templates_pagination: false,
                loading_templates: false
            });
        },
        exportToCSv: (items) => {

            var headers = {
                name: "Name",
                crm_id: "crm ID",
                email: "Email",
                status: "Status",
                company: "Company",
                website: "Website",
                agents: "Agents",
                timezone: "Timezone",
                google_campaigns: "Google campaigns",
                google_manager: "Google manager",
                google_account: "Google account",
                google_maxbudget: "Google max budget",
                bing_campaigns: "Bing campaigns",
                bing_manager: "Bing manager",
                bing_account: "Bing account",
                bing_maxbudget: "Bing max budget",
                facebook_campaigns: "Facebook campaigns",
                facebook_manager: "Facebook manager",
                facebook_account: "Facebook account",
                facebook_maxbudget: "Facebook max budget",
                tiktok_campaigns: "Tiktok campaigns",
                tiktok_manager: "Tiktok manager",
                tiktok_account: "Tiktok account",
                tiktok_maxbudget: "Tiktok max budget",
                snapchat_campaigns: "Snapchat campaigns",
                snapchat_manager: "Snapchat manager",
                snapchat_account: "Snapchat account",
                snapchat_maxbudget: "Snapchat max budget",
                ga4_manager: "Google Analytics 4 Account",
                ga4_account: "Google Analytics 4 Property"
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    name: item.name ? item.name.replaceAll(",", ".") : "",
                    crm_id: item.crm_id ? item.crm_id : "",
                    email: item.email ? item.email : "",
                    status: item.status ? item.status : "",
                    company: item.company ? item.company.replaceAll(",", ".") : "",
                    website: item.website ? item.website : "",
                    agents: item.agents ? item.agents : "",
                    timezone: item.timezone ? item.timezone : "",
                    google_campaigns: item.google_campaigns ? item.google_campaigns : "",
                    google_manager: item.google_manager ? item.google_manager : "",
                    google_account: item.google_account ? item.google_account : "",
                    google_maxbudget: item.google_maxbudget ? item.google_maxbudget : "",
                    bing_campaigns: item.bing_campaigns ? item.bing_campaigns : "",
                    bing_manager: item.bing_manager ? item.bing_manager : "",
                    bing_account: item.bing_account ? item.bing_account : "",
                    bing_maxbudget: item.bing_maxbudget ? item.bing_maxbudget : "",
                    facebook_campaigns: item.facebook_campaigns ? item.facebook_campaigns : "",
                    facebook_manager: item.facebook_manager ? item.facebook_manager : "",
                    facebook_account: item.facebook_account ? item.facebook_account : "",
                    facebook_maxbudget: item.facebook_maxbudget ? item.facebook_maxbudget : "",
                    tiktok_campaigns: item.tiktok_campaigns ? item.tiktok_campaigns : "",
                    tiktok_manager: item.tiktok_manager ? item.tiktok_manager : "",
                    tiktok_account: item.tiktok_account ? item.tiktok_account : "",
                    tiktok_maxbudget: item.tiktok_maxbudget ? item.tiktok_maxbudget : "",
                    snapchat_campaigns: item.snapchat_campaigns ? item.snapchat_campaigns : "",
                    snapchat_manager: item.snapchat_manager ? item.snapchat_manager : "",
                    snapchat_account: item.snapchat_account ? item.snapchat_account : "",
                    snapchat_maxbudget: item.snapchat_maxbudget ? item.snapchat_maxbudget : "",
                    ga4_manager: item.ga4_manager ? item.ga4_manager : "",
                    ga4_account: item.ga4_account ? item.ga4_account : ""
                });
            });

            var fileTitle = 'Clients';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        },
        exportReportsToCSv: (items) => {

            var headers = {
                report: "Report",
                crm_id: "crm ID",
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    report: item.report,
                    crm_id: item.crm_id ? item.crm_id : "",
                });
            });

            var fileTitle = 'Reports';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        },
        exportOrdersToCSv: (items) => {

            // var headers = {
            //     client: "Client",
            //     order: "Order",
            //     status: "status",
            //     crm_id: "Crm ID",
            //     crm_budget: "Crm Budget",
            //     google: "Google connected",
            //     google_budgets: "Google Shared budgets",
            //     google_daily: "Google Shared Daily budget",
            //     google_30: "Google Shared 30 days total budget",
            //     bing: "Bing connected",
            //     bing_budgets: "Bing Shared budgets",
            //     bing_daily: "Bing Shared Daily budget",
            //     bing_30: "Bing Shared 30 days total budget",
            //     crm_compare: "(Google + Bing 30 days budget) - CRM Budget",
            //     agency: "Agency"
            // };

            // var itemsFormatted = [];

            // items.forEach((item) => {
            //     itemsFormatted.push({
            //         client: item.client ? item.client.replaceAll(",", ".") : "",
            //         order: item.order ? item.order.replaceAll(",", ".") : "",
            //         status: item.status,
            //         crm_id: item.crm_id,
            //         crm_budget: item.crm_budget,
            //         google: item.google,
            //         google_budgets: item.google_budgets,
            //         google_daily: item.google_daily,
            //         google_30: item.google_30,
            //         bing: item.bing,
            //         bing_budgets: item.bing_budgets,
            //         bing_daily: item.bing_daily,
            //         bing_30: item.bing_30,
            //         crm_compare: item.crm_compare,
            //         agency: item.agency
            //     });
            // });

            var headers = {
                agency_name: "Agency name",
                client_name: "Client name",
                client_status: "Client status",
                client_crm_id: "Client CRM ID",
                order_name: "Order name",
                order_crm_id: "Order CRM ID",
                order_status: "Order status",
                order_start: "Start date",
                order_end: "End date",
                order_budget: "Order Budget"
            };
            var itemsFormatted = [];

            items.forEach((item) => {
                let order = {
                    agency_name: item.agency_name && item.agency_name !== "" ? item.agency_name : "-",
                    client_name: item.client_name && item.client_name !== "" ? item.client_name : "-",
                    client_status: item.client_status && item.client_status !== "" ? item.client_status : "-",
                    client_crm_id: item.client_crm_id && item.client_crm_id !== "" ? item.client_crm_id : "-",
                    order_name: item.order_name && item.order_name !== "" ? item.order_name : "-",
                    order_crm_id: item.order_crm_id && item.order_crm_id !== "" ? item.order_crm_id : "-",
                    order_status: item.order_status && item.order_status !== "" ? item.order_status : "-",
                    order_start: item.order_start && item.order_start !== "" ? item.order_start : "-",
                    order_end: item.order_end && item.order_end !== "" ? item.order_end : "-",
                    order_budget: item.order_budget && item.order_budget !== "" ? item.order_budget : "-",
                };

                order.order_start !== "-" ? order.order_start = new Date(order.order_start).toLocaleDateString() : order.order_start = "-";
                order.order_end !== "-" ? order.order_end = new Date(order.order_end).toLocaleDateString() : order.order_end = "-";

                itemsFormatted.push(order);
            });


            var fileTitle = 'Orders';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        },
        exportDuplicatesToCSv: (items) => {

            var headers = {
                client: "Client",
                name: "Name",
                email: "Email",
                crmId: "Crm ID"
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    client: item.client,
                    name: item.name ? item.name.replace(",", ".") : "",
                    email: item.email,
                    crmId: item.crmId
                });
            });

            var fileTitle = 'Clients';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        }
    };

    calls = {
        getAgents: (channel) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.agents_limit + "&page=" + this.state.agents_page + "&sortBy=email" + (typeof this.state.agents_search == "string" && this.state.agents_search !== "" ? ("&search=" + this.state.agents_search) : "") + (channel ? "&channel=" + channel : "");
            return apiRegister.call(options, url);
        },
        updateAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgencyFromManager";
            return apiRegister.call(options, url);
        },
        getAgency: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgency"
            return apiRegister.call(options, url);
        },
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        },
        updateAgencyFromManager: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgencyFromManager";
            return apiRegister.call(options, url);
        },
        removeCachedData: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCachedData";
            return apiRegister.call(options, url);
        },
        importClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/importClients";
            return apiRegister.call(options, url);
        },
        getAgencyClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyClients";
            return apiRegister.call(options, url);
        },
        getAgencyOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyOrders";
            return apiRegister.call(options, url);
        },
        getAgencyReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/importClients";
            return apiRegister.call(options, url);
        },
        getTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?template=true&master=true&limit=" + this.state.templates_limit + "&page=" + this.state.templates_page + "&status=" + this.state.type + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.templates_search !== "" ? ("&search=" + this.state.templates_search) : "");
            return apiRegister.call(options, url);
        }
    };

    handleFileUpload = (event) => {
        if (!this.state.loading_file) {
            this.setState({
                loading_file: true
            });
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {

                const csvText = e.target.result;
                const data = this.csvToJson(csvText);

                console.log(data);

                this.functions.importClients(data.json);

            };
            reader.readAsText(file);
        }
    };

    csvToJson = (csvText) => {

        const lines = csvText.split('\n');

        //LOCATE WHERE HEADERS STARTS
        let header_index = 0;

        const headers = Splitter(lines[header_index]);

        let result_json = [];
        for (let i = 0; i < lines.length; i++) {
            if (i > header_index) {
                const obj_json = {};
                const currentLine = Splitter(lines[i]);
                if (currentLine) {
                    for (let j = 0; j < headers.length; j++) {
                        if (headers[j]) {
                            let currentHeader = headers[j].replaceAll(/["\r]/g, "");
                            if (currentHeader) {
                                if (currentLine[j]) {
                                    obj_json[currentHeader] = currentLine[j].replaceAll(/["\r]/g, "");
                                } else {
                                    obj_json[currentHeader] = "";
                                }
                            }
                        }
                    }
                }
                result_json.push(obj_json);
            }
        }

        return { json: result_json }

        function Splitter(text) {
            var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
            var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            // Return NULL if input string is not well formed CSV string.

            if (!re_valid.test(text)) {
                console.log("CSV ROW ERROR: ", text);
            }

            if (!re_valid.test(text)) return null;
            var a = [];                     // Initialize array to receive values.
            text.replace(re_value, // "Walk" the string using replace with callback.
                function (m0, m1, m2, m3) {
                    // Remove backslash from \' in single quoted values.
                    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                    // Remove backslash from \" in double quoted values.
                    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                    else if (m3 !== undefined) a.push(m3);
                    return ''; // Return empty string.
                });
            // Handle special case of empty last value.
            if (/,\s*$/.test(text)) a.push('');
            return a;
        };

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='w-full h-screen'>

                <TopNavigationAgent
                    buttons={[]}
                    onSubtab={(tab) => {
                        this.setState({
                            tab: tab
                        })
                    }}
                    hideMainTabs={true}
                    subtab={this.state.tab}
                    show_subtabs={true}
                    subtabs={this.state.tabs}
                />

                {
                    this.state.loader &&
                    <div className='min-h-screen flex flex-col justify-center items-center w-full'>
                        <div style={{ borderTopColor: "transparent" }} className='w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin'></div>
                        <div className='font-semibold mt-3'>Loading settings...</div>
                    </div>
                }

                <WarningModalTailwind
                    open={this.state.open.remove_modal}
                    title={"Remove"}
                    description={'Are you sure you want to remove ? This action cannot be undone'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type name to remove"}
                    copyInput={this.state.open.remove_modal ? this.state.remove.name : ""}
                    inputError={(!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)) ? "Name does not match" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ open: { remove_modal: false } });
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={async (value) => {
                        if (this.state.open.remove_modal && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeCachedData();
                            await this.promisedSetState({ open: { remove_modal: false } });
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* GENERAL SETTINGS  */}
                {
                    !this.state.loader &&
                    this.state.tab.id === 0 &&
                    <div className="grid grid-cols-12 gap-4 p-4">
                        <div className='col-span-6'>
                            <div className='bg-white shadow rounded-lg'>
                                <div class="py-5 px-4 border-b font-semibold text-sm">
                                    Agency settings
                                </div>
                                <div className="p-4">
                                    <div className="flex flex-col">
                                        <div className="flex flex-1">
                                            <div className="w-full">

                                                <div className="grid-cols-3 gap-4 grid mb-4">
                                                    <div className={"col-span-1"}>
                                                        <InputTailwind
                                                            label={"Name"}
                                                            value={this.state.name}
                                                            onChange={(value) => {
                                                                this.promisedSetState({
                                                                    name: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"col-span-1"}>
                                                        <InputTailwind
                                                            label={"Company name"}
                                                            value={this.state.company}
                                                            onChange={(value) => {
                                                                this.promisedSetState({
                                                                    company: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"col-span-1"}>
                                                        <InputTailwind
                                                            label={"Email"}
                                                            value={this.state.email}
                                                            onChange={(value) => {
                                                                this.promisedSetState({
                                                                    email: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex w-full gap-4 mt-4'>
                                                    <div className='flex flex-col w-full'>
                                                        <UploadImage
                                                            imageOnly={true}
                                                            title={"Upload logo (png)"}
                                                            size={"full"}
                                                            cover={false}
                                                            logo={true}
                                                            max_width={false}
                                                            value={this.state.logo}
                                                            error={this.state.error === "logo" && !this.state.logo}
                                                            onChange={(url) => {
                                                                this.setState({ logo: url });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className={"col-span-12"}>
                                                        <div className="bg-red-100 p-4 rounded-md">
                                                            <div className="text-sm text-red-500">
                                                                {this.state.error}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className='flex w-full justify-end pt-4'>
                                                    <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                        onClick={() => {
                                                            this.functions.updateAgency()
                                                        }}>
                                                        Save
                                                        {
                                                            this.state.loading_settings &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            !this.state.loading.user &&
                            (this.state.agent.manager || this.state.agent.admin) &&
                            <div className="col-span-6">
                                <div className='px-4 pb-4 grid-cols-8 grid gap-4'>
                                    <div className='shadow bg-white rounded-md col-span-4'>
                                        <div class="py-5 px-4 border-b font-semibold text-sm">
                                            Export Clients
                                        </div>
                                        <div className="py-5 px-4 sm:px-6">
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_download) {
                                                        this.functions.getAgencyClients();
                                                    }
                                                }}
                                                className={"mr-2 cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow relative inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"}
                                            >
                                                Download (csv)
                                                {
                                                    this.state.loading_download &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className='shadow bg-white rounded-md col-span-4'>
                                        <div class="py-5 px-4 border-b font-semibold text-sm">
                                            Export Orders
                                        </div>
                                        <div className="py-5 px-4 sm:px-6">
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_download) {
                                                        this.functions.getAgencyOrders();
                                                    }
                                                }}
                                                className={"mr-2 cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow relative inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"}
                                            >
                                                Download (csv)
                                                {
                                                    this.state.loading_download &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='px-4 pb-4'>
                                    <div className='shadow bg-white rounded-md'>
                                        <div class="py-5 px-4 border-b font-semibold text-sm">
                                            Remove all cached data
                                        </div>
                                        <div className="pt-5 px-4 sm:px-6">
                                            <button
                                                onClick={() => {
                                                    this.state.open.remove_modal = true;
                                                    this.state.remove = { name: "123456" };
                                                    if (!this.state.loading.remove_cache) {
                                                        this.promisedSetState({ open: this.state.open, remove: this.state.remove });
                                                    }
                                                    console.log("removing", this.state.remove)
                                                }}
                                                className={"mr-2 cursor-pointer bg-red-500 hover:bg-red-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"}
                                            >
                                                Remove
                                                {
                                                    this.state.loading.remove_cache &&
                                                    <div className="w-full h-full absolute bg-red-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                        <div className="py-5 px-4 sm:px-6">
                                            <div className="bg-blue-100 p-4 text-blue-500 text-sm font-medium rounded-md">
                                                Removing all cached data might result in slower load times.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>

                }

                {/* REPORT SETTINGS */}
                {
                    !this.state.loader &&
                    this.state.tab.id === 1 &&
                    <div className='p-4'>
                        <div className='bg-white shadow rounded-lg'>
                            <div class="p-4 border-b font-semibold text-sm rounded-t-lg">
                                Generic
                            </div>
                            <div className="p-4">

                                <div className='grid grid-cols-3 gap-4 mb-4'>
                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Background color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_background" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_background"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='000000'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_background ? this.state.whitelabel_settings.color_background : "ffffff"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_background = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_background ? this.state.whitelabel_settings.color_background : "ffffff")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_background = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Font color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_generic_font" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_generic_font"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='000000'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_generic_font ? this.state.whitelabel_settings.color_generic_font : "000000"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_generic_font = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_generic_font ? this.state.whitelabel_settings.color_generic_font : "000000")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_generic_font = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Tab font color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_tab_font" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_tab_font"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='000000'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_tab_font ? this.state.whitelabel_settings.color_tab_font : "000000"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_tab_font = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_tab_font ? this.state.whitelabel_settings.color_tab_font : "000000")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_tab_font = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='flex w-full justify-end'>
                                    <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                        onClick={() => {
                                            this.functions.updateAgency()
                                        }}>
                                        Save
                                        {
                                            this.state.loading_settings &&
                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>

                            </div>

                            <div class="py-5 px-4 border-t border-b font-semibold text-sm">
                                Navbar
                            </div>

                            <div className='p-4'>

                                <div className="grid grid-cols-4 gap-4">

                                    {/* UPLOAD WHITELABEL REPORT LOGO */}
                                    <div className='col-span-5 w-full gap-4'>
                                        <UploadImage
                                            imageOnly={true}
                                            title={"Upload logo (png)"}
                                            size={"full"}
                                            cover={false}
                                            logo={true}
                                            max_width={false}
                                            value={this.state.whitelabel_settings.logo_primary}
                                            error={this.state.error === "logo" && !this.state.logo}
                                            onChange={(url) => {
                                                // this.setState({ logo: url });
                                                this.state.whitelabel_settings.logo_primary = url;
                                                this.promisedSetState({
                                                    whitelabel_settings: this.state.whitelabel_settings
                                                })
                                            }}
                                        />
                                    </div>

                                    {/* PICK WHITELABEL AGENCY COLORS */}

                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Accent color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_accent" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_accent"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='FFFFFF'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_accent ? this.state.whitelabel_settings.color_accent : "ffffff"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_accent = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_accent ? this.state.whitelabel_settings.color_accent : "ffffff")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_accent = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Font color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_font" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_font"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='000000'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_font ? this.state.whitelabel_settings.color_font : "000000"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_font = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_font ? this.state.whitelabel_settings.color_font : "000000")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_font = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-xs font-medium mb-1">
                                            Navbar color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_navbar" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_navbar"
                                                        className="w-full border-none p-2 bg-transparent"
                                                        type="text"
                                                        placeholder='000000'
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_navbar ? this.state.whitelabel_settings.color_navbar : "ffffff"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_navbar = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className="absolute cursor-pointer"
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_navbar ? this.state.whitelabel_settings.color_navbar : "ffffff")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_navbar = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <DropdownTailwind
                                            label='Logo size'
                                            selected={this.state.whitelabel_settings.logo_size && this.state.whitelabel_settings.logo_size.value ? this.state.whitelabel_settings.logo_size : { id: 2, name: 'Medium', value: "h-5" }}
                                            options={[
                                                { id: 1, name: "Large", value: "h-8" },
                                                { id: 2, name: "Medium", value: "h-5" },
                                                { id: 3, name: "Small", value: "h-3" },
                                            ]}
                                            onChange={async (value) => {
                                                this.state.whitelabel_settings.logo_size = value;
                                                await this.promisedSetState({
                                                    whitelabel_settings: this.state.whitelabel_settings
                                                })
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="grid grid-cols-4 gap-4 mt-4">
                                    <div className="col-span-1">
                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Datelock</div>
                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 text-sm ml-5">
                                                {this.state.whitelabel_settings.datelock ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.whitelabel_settings.datelock ? true : false}
                                                    onSwitch={async () => {
                                                        this.state.whitelabel_settings.datelock = !this.state.whitelabel_settings.datelock;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        false &&
                                        <div className="col-span-1">
                                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Data comparison</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 text-sm ml-5">
                                                    {!this.state.whitelabel_settings.disable_comparison ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={!this.state.whitelabel_settings.disable_comparison ? true : false}
                                                        onSwitch={async () => {
                                                            this.state.whitelabel_settings.disable_comparison = !this.state.whitelabel_settings.disable_comparison;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='flex w-full justify-end'>
                                    <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                        onClick={() => {
                                            this.functions.updateAgency()
                                        }}>
                                        Save
                                        {
                                            this.state.loading_settings &&
                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div class="py-5 px-4 border-t border-b font-semibold text-sm">
                                Footer
                            </div>
                            <div className="p-4">

                                <>
                                    <div className='grid grid-cols-5 gap-4 mb-4'>
                                        <div className='col-span-1'>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Footer</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 text-sm ml-5">
                                                        {this.state.whitelabel_settings.footer ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.state.whitelabel_settings.footer ? true : false}
                                                            onSwitch={async () => {
                                                                this.state.whitelabel_settings.footer = !this.state.whitelabel_settings.footer;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="text-xs font-medium mb-1">
                                                Footer color
                                            </div>
                                            <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_navbar" ? "border-purple-500" : ""}`}>
                                                <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                    <div
                                                        style={{ backgroundColor: "#fcfcfc" }}
                                                        className="flex items-center col-span-3 p-1">
                                                        <span className={`pl-3`}>#</span>
                                                        <input
                                                            id="color_navbar"
                                                            className={`w-full border-none p-2 bg-transparent ${this.state.whitelabel_settings && !this.state.whitelabel_settings.footer && "cursor-not-allowed"}`}
                                                            type="text"
                                                            placeholder='ffffff'
                                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                            value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer ? this.state.whitelabel_settings.color_footer : "ffffff"}
                                                            onClick={(e) => {
                                                                this.setState({ selectedId: e.target.id })
                                                            }}
                                                            onChange={async (e) => {
                                                                // if (!this.state.whitelabel_settings) {
                                                                //     this.state.whitelabel_settings = { color_accent: "" };
                                                                // }
                                                                e.target.value = e.target.value.replace(/#/g, "")
                                                                this.state.whitelabel_settings.color_footer = e.target.value;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                        <input
                                                            style={{ width: 300, height: 65, bottom: -10 }}
                                                            className={`absolute ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                            type="color"
                                                            value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer ? this.state.whitelabel_settings.color_footer : "ffffff")}
                                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                            onChange={async (e) => {
                                                                // if (!this.state.whitelabel_settings) {
                                                                //     this.state.whitelabel_settings = { color_accent: "" };
                                                                // }
                                                                let color_string = e.target.value.slice(1)
                                                                this.state.whitelabel_settings.color_footer = color_string;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="text-xs font-medium mb-1">
                                                Font color
                                            </div>
                                            <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_footer_font" ? "border-purple-500" : ""}`}>
                                                <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                    <div
                                                        style={{ backgroundColor: "#fcfcfc" }}
                                                        className="flex items-center col-span-3 p-1">
                                                        <span className={`pl-3`}>#</span>
                                                        <input
                                                            id="color_footer_font"
                                                            className={`w-full border-none p-2 bg-transparent ${this.state.whitelabel_settings && !this.state.whitelabel_settings.footer && "cursor-not-allowed"}`}
                                                            type="text"
                                                            placeholder='333333'
                                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                            value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer_font ? this.state.whitelabel_settings.color_footer_font : "333333"}
                                                            onClick={(e) => {
                                                                this.setState({ selectedId: e.target.id })
                                                            }}
                                                            onChange={async (e) => {
                                                                // if (!this.state.whitelabel_settings) {
                                                                //     this.state.whitelabel_settings = { color_accent: "" };
                                                                // }
                                                                e.target.value = e.target.value.replace(/#/g, "")
                                                                this.state.whitelabel_settings.color_footer_font = e.target.value;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                        <input
                                                            style={{ width: 300, height: 65, bottom: -10 }}
                                                            className={`absolute ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                            type="color"
                                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                            value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer_font ? this.state.whitelabel_settings.color_footer_font : "333333")}
                                                            onChange={async (e) => {
                                                                // if (!this.state.whitelabel_settings) {
                                                                //     this.state.whitelabel_settings = { color_accent: "" };
                                                                // }
                                                                let color_string = e.target.value.slice(1)
                                                                this.state.whitelabel_settings.color_footer_font = color_string;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="w-full grid grid-cols-5 gap-4">
                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="flex flex-col space-y-2">
                                                <div>
                                                    <InputTailwind
                                                        label="Link 1 text"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_1 && this.state.whitelabel_settings.link_1.name ? this.state.whitelabel_settings.link_1.name : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_1.name = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTailwind
                                                        label="URL"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_1 && this.state.whitelabel_settings.link_1.url ? this.state.whitelabel_settings.link_1.url : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_1.url = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="flex flex-col space-y-2">
                                                <div>
                                                    <InputTailwind
                                                        label="Link 2"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_2 && this.state.whitelabel_settings.link_2.name ? this.state.whitelabel_settings.link_2.name : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_2.name = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTailwind
                                                        label="URL"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_2 && this.state.whitelabel_settings.link_2.url ? this.state.whitelabel_settings.link_2.url : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_2.url = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="flex flex-col space-y-2">
                                                <div>
                                                    <InputTailwind
                                                        label="Link 3 text"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_3 && this.state.whitelabel_settings.link_3.name ? this.state.whitelabel_settings.link_3.name : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_3.name = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTailwind
                                                        label="URL"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_3 && this.state.whitelabel_settings.link_3.url ? this.state.whitelabel_settings.link_3.url : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_3.url = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="flex flex-col space-y-2">
                                                <div>
                                                    <InputTailwind
                                                        label="Link 4 text"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_4 && this.state.whitelabel_settings.link_4.name ? this.state.whitelabel_settings.link_4.name : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_4.name = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTailwind
                                                        label="URL"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_4 && this.state.whitelabel_settings.link_4.url ? this.state.whitelabel_settings.link_4.url : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_4.url = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className="flex flex-col space-y-2">
                                                <div>
                                                    <InputTailwind
                                                        label="Link 5 text"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_5 && this.state.whitelabel_settings.link_5.name ? this.state.whitelabel_settings.link_5.name : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_5.name = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputTailwind
                                                        label="URL text"
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_5 && this.state.whitelabel_settings.link_5.url ? this.state.whitelabel_settings.link_5.url : ""}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (value) => {
                                                            this.state.whitelabel_settings.link_5.url = value
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='grid w-full grid-cols-5 mt-4 gap-4'>

                                        <div className={`col-span-4 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <InputTailwind
                                                label="Footer text"
                                                value={this.state.whitelabel_settings && this.state.whitelabel_settings.footer_text ? this.state.whitelabel_settings.footer_text : ""}
                                                disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                onChange={async (value) => {
                                                    this.state.whitelabel_settings.footer_text = value
                                                    await this.promisedSetState({
                                                        whitelabel_settings: this.state.whitelabel_settings
                                                    })
                                                }}
                                            />
                                        </div>

                                        <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Copyright icon & year</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 text-sm ml-5">
                                                        {this.state.whitelabel_settings.copyright ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.state.whitelabel_settings.copyright ? true : false}
                                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                            onSwitch={async () => {
                                                                this.state.whitelabel_settings.copyright = !this.state.whitelabel_settings.copyright;
                                                                await this.promisedSetState({
                                                                    whitelabel_settings: this.state.whitelabel_settings
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            </div>

                            <div class="py-5 px-4 border-t border-b font-semibold text-sm">
                                Scale template
                            </div>
                            <div className="p-4">
                                <div className="col-span-1">
                                    <DropdownTailwind
                                        label={"Scale-report template (optional)"}
                                        small={false}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_templates}
                                        loadingPagination={this.state.loading_templates_pagination}
                                        loadingSearch={this.state.loading_templates_search}
                                        total={this.state.templates_total}
                                        removeButton={this.state.defaultMasterTemplate.id && this.state.defaultMasterTemplate.id !== 1}
                                        searchInput={true}
                                        placeholder={"Search ..."}
                                        pagination={this.state.templates_total > (this.state.templates_page * this.state.templates_limit)}
                                        selected={this.state.defaultMasterTemplate.id && this.state.defaultMasterTemplate.id !== 1 ? this.state.defaultMasterTemplate : { id: 1, name: "Select template" }}
                                        options={this.state.templates.length > 0 ? this.state.templates : []}
                                        onRemove={() => {
                                            this.promisedSetState({
                                                defaultMasterTemplate: {}
                                            });
                                        }}
                                        onChange={async (value) => {
                                            this.state.defaultMasterTemplate = value;
                                            await this.promisedSetState({
                                                defaultMasterTemplate: value
                                            });
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_templates_pagination) {
                                                await this.promisedSetState({
                                                    templates_page: this.state.templates_page + 1
                                                });
                                                this.functions.getTemplates(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_templates_search: true,
                                                templates_search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.templates_search) {
                                                    await this.promisedSetState({
                                                        templates_page: 1
                                                    });
                                                    this.functions.getTemplates(false, false, true, value);
                                                }
                                            }, 400);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='flex w-full justify-end px-4 pb-4'>
                                <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                    onClick={() => {
                                        this.functions.updateAgency()
                                    }}>
                                    Save
                                    {
                                        this.state.loading_settings &&
                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {/* PLATFORM SETTINGS (Google and Bing) */}
                {
                    !this.state.loader &&
                    this.state.tab.id === 9 &&
                    <div className='grid grid-cols-2 gap-4 pt-4'>

                        <div className='col-span-1 pl-4'>
                            <div className='flex flex-col bg-white shadow rounded-lg h-full justify-between'>
                                <div>
                                    <div class="py-5 px-4 border-b font-semibold text-sm">
                                        Google
                                    </div>
                                    <div className='w-full p-4'>
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Google acccount setting</div>
                                        <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm font-medium">
                                                Be able to create new accounts
                                                <div class="flex flex-1 justify-end mr-3">
                                                    {this.state.googleScaleSettings.createAccounts ? "Enabled" : "Disabled"}
                                                </div>
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.googleScaleSettings.createAccounts}
                                                    onSwitch={async () => {
                                                        this.state.googleScaleSettings.createAccounts = !this.state.googleScaleSettings.createAccounts;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.googleScaleSettings.createAccounts &&
                                        <div className='w-full p-4 pt-0'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Account budget</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    Auto renew account budget
                                                    <div class="flex flex-1 justify-end mr-3">
                                                        {this.state.googleScaleSettings.autoRenewIO ? "Enabled" : "Disabled"}
                                                    </div>
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.googleScaleSettings.autoRenewIO}
                                                        onSwitch={async () => {
                                                            this.state.googleScaleSettings.autoRenewIO = !this.state.googleScaleSettings.autoRenewIO;
                                                            await this.promisedSetState({
                                                                googleScaleSettings: this.state.googleScaleSettings
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.googleScaleSettings.createAccounts &&
                                        <div className='w-full p-4 pt-0'>
                                            <DropdownTailwind
                                                label={"Agent (select one connected to Google with correct credentials)"}
                                                small={false}
                                                skipInternalSearch={true}
                                                loader={this.state.loading_agents}
                                                loadingPagination={this.state.loading_agents_pagination}
                                                loadingSearch={this.state.loading_agents_search}
                                                total={this.state.total}
                                                searchInput={true}
                                                placeholder={"..."}
                                                defaultOptions={[]}
                                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                                selected={this.state.googleScaleSettings.agent ? this.state.googleScaleSettings.agent : { id: 1, name: "Select agent", value: "none" }}
                                                options={this.state.agents}
                                                onChange={async (value) => {
                                                    this.state.googleScaleSettings.agent = value;
                                                    await this.promisedSetState({
                                                        googleScaleSettings: this.state.googleScaleSettings
                                                    });
                                                }}
                                                onPagination={async () => {
                                                    if (!this.state.loading_agents_pagination) {
                                                        await this.promisedSetState({
                                                            agents_page: this.state.agents_page + 1
                                                        });
                                                        this.functions.getAgents(false, true, false, "google");
                                                    }
                                                }}
                                                onSearch={async (value) => {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            agents_search: "",
                                                            agents_page: 1
                                                        });
                                                        this.functions.getAgents(false, false, true, "google");
                                                    } else {
                                                        if (this.state.throttling.current) {
                                                            //SKIP
                                                        } else {
                                                            if (value && value === "") {
                                                                await this.promisedSetState({
                                                                    agents_search: value,
                                                                    agents_page: 1
                                                                });
                                                                this.functions.getAgents(false, false, true, "google");
                                                            } else {
                                                                this.state.throttling.current = true;
                                                                await this.promisedSetState({
                                                                    agents_search: value,
                                                                    throttling: this.state.throttling
                                                                });
                                                                setTimeout(async () => {
                                                                    this.state.throttling.current = false;
                                                                    await this.promisedSetState({
                                                                        throttling: this.state.throttling
                                                                    });
                                                                    await this.promisedSetState({
                                                                        agents_page: 1
                                                                    });
                                                                    this.functions.getAgents(false, false, true, "google");
                                                                }, 300);
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.googleScaleSettings.createAccounts &&
                                        <div className='w-full grid grid-cols-12 gap-4 px-4'>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Timezone"}
                                                    small={false}
                                                    skipInternalSearch={false}
                                                    searchInput={false}
                                                    placeholder={"..."}
                                                    selected={this.state.googleScaleSettings.timeZone ? this.state.googleScaleSettings.timeZone : { id: 1, name: "Select timezone", value: "none" }}
                                                    options={[
                                                        { id: "Europe/Stockholm", name: "Europe/Stockholm", value: "Europe/Stockholm" },
                                                        { id: "Europe/Helsinki", name: "Europe/Helsinki", value: "Europe/Helsinki" }
                                                    ]}
                                                    onChange={async (option) => {
                                                        this.state.googleScaleSettings.timeZone = option;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Currency"}
                                                    small={false}
                                                    skipInternalSearch={false}
                                                    searchInput={false}
                                                    placeholder={"..."}
                                                    selected={this.state.googleScaleSettings.currency ? this.state.googleScaleSettings.currency : { id: 1, name: "Select currency", value: "none" }}
                                                    options={[
                                                        { id: "DKK", name: "DKK", id: "DKK" },
                                                        { id: "SEK", name: "SEK", id: "SEK" },
                                                        { id: "NOK", name: "NOK", id: "NOK" },
                                                        { id: "EUR", name: "EUR", id: "EUR" }
                                                    ]}
                                                    onChange={async (option) => {
                                                        this.state.googleScaleSettings.currency = option;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.googleScaleSettings.createAccounts &&
                                        <div className='w-full grid grid-cols-12 gap-4 p-4'>
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    full={true}
                                                    label={"Google Payment ID (OLD)"}
                                                    value={this.state.googlePaymentsAccountId}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            googlePaymentsAccountId: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    full={true}
                                                    label={"Google Payment ID"}
                                                    value={this.state.googleScaleSettings.googlePaymentsAccountId}
                                                    onChange={async (value) => {
                                                        this.state.googleScaleSettings.googlePaymentsAccountId = value;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    full={true}
                                                    label={"Manager ID"}
                                                    value={this.state.googleScaleSettings.managerID}
                                                    onChange={async (value) => {
                                                        this.state.googleScaleSettings.managerID = value;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    full={true}
                                                    label={"Manager Name"}
                                                    value={this.state.googleScaleSettings.managerName}
                                                    onChange={async (value) => {
                                                        this.state.googleScaleSettings.managerName = value;
                                                        await this.promisedSetState({
                                                            googleScaleSettings: this.state.googleScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="w-full border-b"></div>
                                    <div className='w-full grid grid-cols-12 gap-4 p-4'>
                                        <div className='col-span-4'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Search Network</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.googleDefaultSettings.searchNetwork.value ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.googleDefaultSettings.searchNetwork.value}
                                                        onSwitch={async () => {
                                                            if (this.state.googleDefaultSettings.searchNetwork.value) {
                                                                this.state.googleDefaultSettings.searchNetwork = { id: 2, name: "Disabled", value: false }
                                                            } else {
                                                                this.state.googleDefaultSettings.searchNetwork = { id: 1, name: "Enabled", value: true }
                                                            }
                                                            await this.promisedSetState({
                                                                googleDefaultSettings: this.state.googleDefaultSettings
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-4'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Content Network</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.googleDefaultSettings.contentNetwork.value ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.googleDefaultSettings.contentNetwork.value}
                                                        onSwitch={async () => {
                                                            if (this.state.googleDefaultSettings.contentNetwork.value) {
                                                                this.state.googleDefaultSettings.contentNetwork = { id: 2, name: "Disabled", value: false }
                                                            } else {
                                                                this.state.googleDefaultSettings.contentNetwork = { id: 1, name: "Enabled", value: true }
                                                            }
                                                            await this.promisedSetState({
                                                                googleDefaultSettings: this.state.googleDefaultSettings
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-span-4'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Partner Search Network</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.googleDefaultSettings.partnerSearchNetwork.value ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.googleDefaultSettings.partnerSearchNetwork.value}
                                                        onSwitch={async () => {
                                                            if (this.state.googleDefaultSettings.partnerSearchNetwork.value) {
                                                                this.state.googleDefaultSettings.partnerSearchNetwork = { id: 2, name: "Disabled", value: false }
                                                            } else {
                                                                this.state.googleDefaultSettings.partnerSearchNetwork = { id: 1, name: "Enabled", value: true }
                                                            }
                                                            await this.promisedSetState({
                                                                googleDefaultSettings: this.state.googleDefaultSettings
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full justify-end p-4 bg-gray-50 border-t rounded-b-lg'>
                                    <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                        onClick={() => {
                                            this.functions.updateAgency()
                                        }}>
                                        Save
                                        {
                                            this.state.loading_settings &&
                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-1 pr-4'>
                            <div className='flex flex-col bg-white shadow rounded-lg h-full justify-between'>
                                <div>
                                    <div class="py-5 px-4 border-b font-semibold text-sm">
                                        Bing
                                    </div>
                                    <div className='w-full p-4'>
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Bing account setting</div>
                                        <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm font-medium">
                                                Be able to create new accounts
                                                <div class="flex flex-1 justify-end mr-3">
                                                    {this.state.bingScaleSettings.createAccounts ? "Enabled" : "Disabled"}
                                                </div>
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.bingScaleSettings.createAccounts}
                                                    onSwitch={async () => {
                                                        this.state.bingScaleSettings.createAccounts = !this.state.bingScaleSettings.createAccounts;
                                                        await this.promisedSetState({
                                                            bingScaleSettings: this.state.bingScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.bingScaleSettings.createAccounts &&
                                        <div className='w-full p-4 pt-0 grid grid-cols-12 gap-4'>
                                            <div className={this.state.bingScaleSettings.version && this.state.bingScaleSettings.version.value == "child_managers" ? "col-span-4" : "col-span-12"}>
                                                <DropdownTailwind
                                                    label={"Version"}
                                                    small={false}
                                                    skipInternalSearch={false}
                                                    searchInput={false}
                                                    placeholder={"..."}
                                                    selected={this.state.bingScaleSettings.version ? this.state.bingScaleSettings.version : { id: 1, name: "Legacy", value: "legacy" }}
                                                    options={[
                                                        { id: 1, name: "Legacy", value: "legacy" },
                                                        { id: 2, name: "Child managers", value: "child_managers" }
                                                    ]}
                                                    onChange={async (option) => {
                                                        this.state.bingScaleSettings.version = option;
                                                        await this.promisedSetState({
                                                            bingScaleSettings: this.state.bingScaleSettings
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {
                                                this.state.bingScaleSettings.version && this.state.bingScaleSettings.version.value == "child_managers" &&
                                                <div className="col-span-8">
                                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Insertion orders</div>
                                                    <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                        <div className="flex flex-1 ml-5 text-sm font-medium">
                                                            Auto renew insertion orders
                                                            <div class="flex flex-1 justify-end mr-3">
                                                                {this.state.bingScaleSettings.autoRenewIO ? "Enabled" : "Disabled"}
                                                            </div>
                                                        </div>
                                                        <div className="relative overflow-hidden mr-5">
                                                            <SwitchTailwind
                                                                value={this.state.bingScaleSettings.autoRenewIO}
                                                                onSwitch={async () => {
                                                                    this.state.bingScaleSettings.autoRenewIO = !this.state.bingScaleSettings.autoRenewIO;
                                                                    await this.promisedSetState({
                                                                        bingScaleSettings: this.state.bingScaleSettings
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.bingScaleSettings.createAccounts &&
                                        <div className='w-full p-4 pt-0'>
                                            <DropdownTailwind
                                                label={"Agent (select one connected to Bing with correct credentials)"}
                                                small={false}
                                                skipInternalSearch={true}
                                                loader={this.state.loading_agents}
                                                loadingPagination={this.state.loading_agents_pagination}
                                                loadingSearch={this.state.loading_agents_search}
                                                total={this.state.total}
                                                searchInput={true}
                                                placeholder={"..."}
                                                defaultOptions={[]}
                                                pagination={this.state.agents_total > (this.state.agents_page * this.state.agents_limit)}
                                                selected={this.state.bingScaleSettings.agent ? this.state.bingScaleSettings.agent : { id: 1, name: "Select agent", value: "none" }}
                                                options={this.state.agents}
                                                onChange={async (value) => {
                                                    this.state.bingScaleSettings.agent = value;
                                                    await this.promisedSetState({
                                                        bingScaleSettings: this.state.bingScaleSettings
                                                    });
                                                }}
                                                onPagination={async () => {
                                                    if (!this.state.loading_agents_pagination) {
                                                        await this.promisedSetState({
                                                            agents_page: this.state.agents_page + 1
                                                        });
                                                        this.functions.getAgents(false, true, false, "bing");
                                                    }
                                                }}
                                                onSearch={async (value) => {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            agents_search: "",
                                                            agents_page: 1
                                                        });
                                                        this.functions.getAgents(false, false, true, "bing");
                                                    } else {
                                                        if (this.state.throttling.current) {
                                                            //SKIP
                                                        } else {
                                                            if (value && value === "") {
                                                                await this.promisedSetState({
                                                                    agents_search: value,
                                                                    agents_page: 1
                                                                });
                                                                this.functions.getAgents(false, false, true, "bing");
                                                            } else {
                                                                this.state.throttling.current = true;
                                                                await this.promisedSetState({
                                                                    agents_search: value,
                                                                    throttling: this.state.throttling
                                                                });
                                                                setTimeout(async () => {
                                                                    this.state.throttling.current = false;
                                                                    await this.promisedSetState({
                                                                        throttling: this.state.throttling
                                                                    });
                                                                    await this.promisedSetState({
                                                                        agents_page: 1
                                                                    });
                                                                    this.functions.getAgents(false, false, true, "bing");
                                                                }, 300);
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.bingScaleSettings.createAccounts &&
                                        <div className='flex flex-col space-y-4 p-4 pt-0'>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Company name"}
                                                        value={this.state.bingScaleSettings.companyName}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.companyName = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Country"}
                                                        value={this.state.bingScaleSettings.country}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.country = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Language"}
                                                        small={false}
                                                        skipInternalSearch={false}
                                                        searchInput={false}
                                                        placeholder={"..."}
                                                        selected={this.state.bingScaleSettings.language ? this.state.bingScaleSettings.language : { id: 1, name: "Select language", value: "none" }}
                                                        options={[
                                                            { id: 1, name: "Danish", value: "Danish" },
                                                            { id: 2, name: "Swedish", value: "Swedish" },
                                                            { id: 3, name: "Norwegian", value: "Norwegian" },
                                                            { id: 4, name: "Finnish", value: "Finnish" },
                                                            { id: 5, name: "English", value: "English" }
                                                        ]}
                                                        onChange={async (option) => {
                                                            this.state.bingScaleSettings.language = option;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"City"}
                                                        value={this.state.bingScaleSettings.city}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.city = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Zip code"}
                                                        value={this.state.bingScaleSettings.zipCode}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.zipCode = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-2">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"ISO statae"}
                                                        value={this.state.bingScaleSettings.IsoState}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.IsoState = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-10">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Address"}
                                                        value={this.state.bingScaleSettings.address}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.address = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Timezone"}
                                                        small={false}
                                                        skipInternalSearch={false}
                                                        searchInput={false}
                                                        placeholder={"..."}
                                                        selected={this.state.bingScaleSettings.timeZone ? this.state.bingScaleSettings.timeZone : { id: 1, name: "Select timezone", value: "none" }}
                                                        options={[
                                                            { id: 1, name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", id: "AmsterdamBerlinBernRomeStockholmVienna" },
                                                            { id: 2, name: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", id: "HelsinkiKyivRigaSofiaTallinnVilnius" }
                                                        ]}
                                                        onChange={async (option) => {
                                                            this.state.bingScaleSettings.timeZone = option;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Currency"}
                                                        small={false}
                                                        skipInternalSearch={false}
                                                        searchInput={false}
                                                        placeholder={"..."}
                                                        selected={this.state.bingScaleSettings.currency ? this.state.bingScaleSettings.currency : { id: 1, name: "Select currency", value: "none" }}
                                                        options={[
                                                            { id: "DKK", name: "DKK", id: "DKK" },
                                                            { id: "SEK", name: "SEK", id: "SEK" },
                                                            { id: "NOK", name: "NOK", id: "NOK" },
                                                            { id: "EUR", name: "EUR", id: "EUR" }
                                                        ]}
                                                        onChange={async (option) => {
                                                            this.state.bingScaleSettings.currency = option;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Manager ID"}
                                                        value={this.state.bingScaleSettings.managerID}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.managerID = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Manager Name"}
                                                        value={this.state.bingScaleSettings.managerName}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.managerName = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Payment ID (OLD)"}
                                                        value={this.state.bingPaymentsAccountId}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                bingPaymentsAccountId: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Payment ID"}
                                                        value={this.state.bingScaleSettings.bingPaymentsAccountId}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.bingPaymentsAccountId = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Primary user ID (OLD)"}
                                                        value={this.state.bingPrimaryUser}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                bingPrimaryUser: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Primary user ID"}
                                                        value={this.state.bingScaleSettings.bingPrimaryUser}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.bingPrimaryUser = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full grid grid-cols-12 gap-4'>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Google Ads Import Credential ID (OLD)"}
                                                        value={this.state.googleAdsImportCredentialID}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                googleAdsImportCredentialID: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Google Ads Import Credential ID"}
                                                        value={this.state.bingScaleSettings.googleAdsImportCredentialID}
                                                        onChange={async (value) => {
                                                            this.state.bingScaleSettings.googleAdsImportCredentialID = value;
                                                            await this.promisedSetState({
                                                                bingScaleSettings: this.state.bingScaleSettings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='flex w-full mt-4 justify-end px-4 pb-4'>
                                    <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                        onClick={() => {
                                            this.functions.updateAgency()
                                        }}>
                                        Save
                                        {
                                            this.state.loading_settings &&
                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* PLATFORM SETTINGS (Budget split)  */}
                {
                    !this.state.loader &&
                    this.state.tab.id === 9 &&
                    this.state.agency.scheduler &&
                    <div className='p-4'>
                        <div className='bg-white shadow rounded-lg'>
                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                Budget split
                            </div>
                            <div className="px-4">
                                <div className="flex w-full mt-4">
                                    <div className='flex w-full transform transition-all duration-500 ease-in-out'>

                                        <div className='flex flex-col flex-1'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget split</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.state.agency.budgetSplit && this.state.agency.budgetSplit.value ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.agency.budgetSplit && this.state.agency.budgetSplit.value ? this.state.agency.budgetSplit.value : false}
                                                        onSwitch={async () => {
                                                            if (!this.state.agency.budgetSplit) {
                                                                this.state.agency.budgetSplit = {
                                                                    value: true,
                                                                    google_budget: 50,
                                                                    bing_budget: 50,
                                                                    budget: 0
                                                                };
                                                            }
                                                            this.state.agency.budgetSplit.value = !this.state.agency.budgetSplit.value;
                                                            await this.promisedSetState({
                                                                agency: this.state.agency
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.agency.budgetSplit && this.state.agency.budgetSplit.value ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                            <InputTailwind
                                                channel={"google"}
                                                full={true}
                                                label={"Google budget"}
                                                value={this.state.agency.budgetSplit && this.state.agency.budgetSplit.google_budget ? +this.state.agency.budgetSplit.google_budget : 0}
                                                leftSection={true}
                                                leftSectionLabel={"%"}
                                                onChange={async (value) => {
                                                    if (+value >= 0 && +value <= 100) {
                                                        //if (+value + +this.state.agency.budgetSplit.bing_budget > 100) {
                                                        this.state.agency.budgetSplit.bing_budget = 100 - +value;
                                                        //}
                                                        this.state.agency.budgetSplit.google_budget = +value;
                                                        await this.promisedSetState({
                                                            agency: this.state.agency
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.agency.budgetSplit && this.state.agency.budgetSplit.value ? " flex-1 pl-2" : " w-0 overflow-hidden")}>
                                            <InputTailwind
                                                channel={"bing"}
                                                full={true}
                                                label={"Bing budget"}
                                                leftSection={true}
                                                leftSectionLabel={"%"}
                                                value={this.state.agency.budgetSplit && this.state.agency.budgetSplit.bing_budget ? +this.state.agency.budgetSplit.bing_budget : 0}
                                                onChange={async (value) => {
                                                    if (+value >= 0 && +value <= 100) {
                                                        //if (+value + +this.state.agency.budgetSplit.google_budget > 100) {
                                                        this.state.agency.budgetSplit.google_budget = 100 - +value;
                                                        //}
                                                        this.state.agency.budgetSplit.bing_budget = +value;
                                                        await this.promisedSetState({
                                                            agency: this.state.agency
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='flex w-full justify-end px-4 pb-4'>
                                <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                    onClick={() => {
                                        this.functions.updateAgency()
                                    }}>
                                    Save
                                    {
                                        this.state.loading_settings &&
                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {/* EMAIL SETTINGS  */}
                {
                    !this.state.loader &&
                    this.state.tab.id === 10 &&
                    <div className='p-4'>
                        <div className='bg-white shadow rounded-lg'>
                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                Automated report emails
                            </div>
                            <div className="p-4">
                                <div className='grid w-full grid-cols-12 gap-4'>

                                    <div className='col-span-4'>
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Allow to send report emails</div>
                                        <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm font-medium">
                                                {this.state.agency.automatedEmails ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.agency.automatedEmails}
                                                    onSwitch={async () => {
                                                        this.state.agency.automatedEmails = !this.state.agency.automatedEmails;
                                                        await this.promisedSetState({
                                                            agency: this.state.agency
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.agency.automatedEmails &&
                                        <div className='col-span-4'>
                                            <InputTailwind
                                                label={"Sendgrid template ID (report)"}
                                                value={this.state.agency.automatedEmailsSendgridTemplateReport}
                                                onChange={(value) => {
                                                    this.state.agency.automatedEmailsSendgridTemplateReport = value;
                                                    this.setState({
                                                        agency: this.state.agency
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        this.state.agency.automatedEmails &&
                                        <div className='col-span-4'>
                                            <InputTailwind
                                                label={"Email sender alias (report)"}
                                                value={this.state.agency.automatedEmailsSendgridSenderID}
                                                onChange={(value) => {
                                                    this.state.agency.automatedEmailsSendgridSenderID = value;
                                                    this.setState({
                                                        agency: this.state.agency
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        false &&
                                        this.state.agency.automatedEmails &&
                                        <div className={this.state.agency.automatedEmailsFrequency && this.state.agency.automatedEmailsFrequency.value == "once_week" ? "col-span-2" : 'col-span-4'}>
                                            <DropdownTailwind
                                                label='Frequency'
                                                selected={this.state.agency.automatedEmailsFrequency ? this.state.agency.automatedEmailsFrequency : { id: 0, name: 'No frequency', value: "no_frequency" }}
                                                options={[
                                                    { id: 0, name: 'No frequency', value: "no_frequency" },
                                                    { id: 1, name: 'Once a month (the 1st)', value: "once_month_start" },
                                                    //{ id: 2, name: 'Once a week', value: "once_week" },
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.agency.automatedEmailsFrequency = value;
                                                    await this.promisedSetState({
                                                        agency: this.state.agency
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        false &&
                                        this.state.agency.automatedEmails &&
                                        this.state.agency.automatedEmailsFrequency && this.state.agency.automatedEmailsFrequency.value == "once_week" &&
                                        <div className='col-span-2'>
                                            <DropdownTailwind
                                                label='Week day'
                                                selected={this.state.agency.automatedEmailsWeekday ? this.state.agency.automatedEmailsWeekday : { id: 0, name: 'Monday', value: "monday" }}
                                                options={[
                                                    { id: 0, name: 'Monday', value: "monday" },
                                                    { id: 1, name: 'Tuesday', value: "tuesday" },
                                                    { id: 2, name: 'Wednesday', value: "wednesday" },
                                                    { id: 3, name: 'Thursday', value: "thursday" },
                                                    { id: 4, name: 'Friday', value: "friday" },
                                                    { id: 5, name: 'Saturday', value: "saturday" },
                                                    { id: 6, name: 'Sunday', value: "sunday" }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.agency.automatedEmailsWeekday = value;
                                                    await this.promisedSetState({
                                                        agency: this.state.agency
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        false &&
                                        this.state.agency.automatedEmails &&
                                        <div className='col-span-4'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Send report after order is started</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.agency.automatedEmailsAfterOrderStart ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.agency.automatedEmailsAfterOrderStart}
                                                        onSwitch={async () => {
                                                            this.state.agency.automatedEmailsAfterOrderStart = !this.state.agency.automatedEmailsAfterOrderStart;
                                                            await this.promisedSetState({
                                                                agency: this.state.agency
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        false &&
                                        this.state.agency.automatedEmails &&
                                        this.state.agency.automatedEmailsAfterOrderStart &&
                                        <div className='col-span-4'>
                                            <DropdownTailwind
                                                label='Send first report'
                                                selected={this.state.agency.automatedEmailsAfterOrderStartDays ? this.state.agency.automatedEmailsAfterOrderStartDays : { id: 0, name: 'Select days', value: "none" }}
                                                options={[
                                                    /*
                                                    { id: 1, name: '1 day after', value: "1" },
                                                    { id: 2, name: '2 days after', value: "2" },
                                                    { id: 3, name: '3 days after', value: "3" },
                                                    { id: 4, name: '4 days after', value: "4" },
                                                    { id: 5, name: '5 days after', value: "5" },
                                                    { id: 6, name: '6 days after', value: "6" },
                                                    */
                                                    { id: 7, name: '7 days after', value: "7" },
                                                    /*{ id: 8, name: '8 days after', value: "8" },
                                                    { id: 9, name: '9 days after', value: "9" },
                                                    { id: 10, name: '10 days after', value: "10" },
                                                    { id: 11, name: '11 days after', value: "11" },
                                                    { id: 12, name: '12 days after', value: "12" },
                                                    { id: 13, name: '13 days after', value: "13" },
                                                    */
                                                    { id: 14, name: '14 days after', value: "14" },
                                                    { id: 21, name: '21 days after', value: "21" },
                                                    { id: 28, name: '28 days after', value: "28" },
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.agency.automatedEmailsAfterOrderStartDays = value;
                                                    await this.promisedSetState({
                                                        agency: this.state.agency
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className='flex w-full justify-end p-4 bg-custom-input rounded-b-lg border-t'>
                                <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                    onClick={() => {
                                        this.functions.updateAgency()
                                    }}>
                                    Save
                                    {
                                        this.state.loading_settings &&
                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )

    }

}

export default Settings;

/*
{
    false &&
        !this.state.loader &&
        this.state.tab.id == 6 &&
        !this.state.loading.user &&
        this.state.agent.manager &&
        <div className='p-4'>
            <div className='shadow bg-white rounded-md'>
                <div class="py-5 px-4 border-b font-semibold text-sm">
                    Performance overview
                </div>
                <div className='py-5 px-4 sm:px-6'>
                    <div className='grid grid-cols-4 gap-6'>
                        <div className="col-span-2">
                            <DropdownTailwind
                                label={"Timezone"}
                                small={false}
                                skipInternalSearch={false}
                                searchInput={true}
                                placeholder={"..."}
                                selected={this.state.agent.agency_timezone ? { name: this.state.agent.agency_timezone, value: this.state.agent.agency_timezone } : { id: 0, name: "Select timezone" }}
                                options={this.state.timezones.map((item) => {
                                    return item.utc.map((item) => {
                                        return { name: item, value: item };
                                    });
                                }).flat()}
                                onChange={async (option) => {
                                    this.state.agent.agency_timezone = option.value;
                                    await this.promisedSetState({
                                        agent: this.state.agent
                                    });
                                }}
                                onPagination={async () => {

                                }}
                                onSearch={async (value) => {

                                }}
                            />
                        </div>
                        <div className={this.state.agent.agency_external_link ? "col-span-1" : "col-span-2"}>
                            <DropdownTailwind
                                label={"Enable external link (client performance)"}
                                small={false}
                                skipInternalSearch={false}
                                searchInput={false}
                                placeholder={"..."}
                                selected={this.state.agent.agency_external_link ? { id: 1, name: "Enabled" } : { id: 0, name: "Disabled" }}
                                options={[
                                    { id: 0, name: "Disabled", value: false },
                                    { id: 1, name: "Enabled", value: true }
                                ]}
                                onChange={async (option) => {
                                    this.state.agent.agency_external_link = option.value;
                                    if (!option.value) {
                                        this.state.agent.agency_external_link_password = "";
                                    }
                                    await this.promisedSetState({
                                        agent: this.state.agent
                                    });
                                }}
                                onPagination={async () => {

                                }}
                                onSearch={async (value) => {

                                }}
                            />
                        </div>
                        {
                            this.state.agent.agency_external_link &&
                            <div className="col-span-1">
                                <InputTailwind
                                    label={"Password"}
                                    value={this.state.agent.agency_external_link_password}
                                    onChange={(value) => {
                                        this.state.agent.agency_external_link_password = value;
                                        this.setState({
                                            agent: this.state.agent
                                        })
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className='flex justify-end space-x-2 mb-5 px-4 sm:px-6'>
                    <button
                        onClick={() => {
                            if (window.location.hostname === 'localhost') {
                                window.open("http://localhost:5001/performance/" + this.state.agent.agency);
                            } else if (window.location.hostname === 'app.adcredo.io') {
                                window.open("https://app.adcredo.io/performance/" + this.state.agent.agency);
                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                window.open("https://dev.adcredo.io/performance/" + this.state.agent.agency);
                            }
                        }}
                        className={`${this.state.agent.agency_external_link ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600"} shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                    >
                        External link
                    </button>
                    <button
                        onClick={() => {
                            if (!this.state.loading.timezone) {
                                this.functions.updateAgencyFromManager();
                            }
                        }}
                        className={`${this.state.agent.agency_timezone ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600"} shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                    >
                        Save
                        {
                            this.state.loading.timezone &&
                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                <div style={{ borderTopColor: "transparent" }}
                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                            </div>
                        }
                    </button>
                </div>
                <div className='grid grid-cols-4 gap-6  px-4 sm:px-6'>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountGoogle
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountFacebook
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountTiktok
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountLinkedin
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountSnapchat
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                        <AccountBing
                            healthcheck={this.state.healthcheck}
                            agencyUser={this.state.agent}
                            agency={this.state.agent.agency_performance}
                        />
                    </div>
                    <div className="col-span-4 mb-4 bg-blue-100 p-4 text-blue-500 text-sm font-medium rounded-md">
                        Connect manager accounts to fetch top layer metrics for all connected clients. Allow Adcredo to check for warning triggers.
                    </div>
                </div>
            </div>
        </div>
}
*/

/*
{
    false &&
    this.state.tab.id === 2 &&
    <div className='p-4'>
        <div className='bg-white shadow rounded-lg'>
            <div class="py-5 px-4 border-b font-semibold text-sm">
                {this.state.tab.name}
            </div>
            <div className="p-4">
                <div className="grid grid-cols-4 gap-4">



                    <div className="col-span-1">
                        <div className="text-xs font-medium mb-1">
                            Font color
                        </div>
                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_font" ? "border-purple-500" : ""}`}>
                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                <div
                                    style={{ backgroundColor: "#fcfcfc" }}
                                    className="flex items-center col-span-3 p-1">
                                    <span className={`pl-3`}>#</span>
                                    <input
                                        id="color_font"
                                        className="w-full border-none p-2 bg-transparent"
                                        type="text"
                                        placeholder='000000'
                                        value={this.state.client_settings && this.state.client_settings.color_font ? this.state.client_settings.color_font : "ffffff"}
                                        onClick={(e) => {
                                            this.setState({ selectedId: e.target.id })
                                        }}
                                        onChange={async (e) => {
                                            // if (!this.state.client_settings) {
                                            //     this.state.client_settings = { color_accent: "" };
                                            // }
                                            e.target.value = e.target.value.replace(/#/g, "")
                                            this.state.client_settings.color_font = e.target.value;
                                            await this.promisedSetState({
                                                client_settings: this.state.client_settings
                                            })
                                        }}
                                    />
                                </div>
                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                    <input
                                        style={{ width: 300, height: 65, bottom: -10 }}
                                        className="absolute cursor-pointer"
                                        type="color"
                                        value={"#" + (this.state.client_settings && this.state.client_settings.color_font ? this.state.client_settings.color_font : "ffffff")}
                                        onChange={async (e) => {
                                            // if (!this.state.client_settings) {
                                            //     this.state.client_settings = { color_accent: "" };
                                            // }
                                            let color_string = e.target.value.slice(1)
                                            this.state.client_settings.color_font = color_string;
                                            await this.promisedSetState({
                                                client_settings: this.state.client_settings
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="text-xs font-medium mb-1">
                            Accent color
                        </div>
                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_accent" ? "border-purple-500" : ""}`}>
                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                <div
                                    style={{ backgroundColor: "#fcfcfc" }}
                                    className="flex items-center col-span-3 p-1">
                                    <span className={`pl-3`}>#</span>
                                    <input
                                        id="color_accent"
                                        className="w-full border-none p-2 bg-transparent"
                                        type="text"
                                        placeholder='FFFFFF'
                                        value={this.state.client_settings && this.state.client_settings.color_accent ? this.state.client_settings.color_accent : "ffffff"}
                                        onClick={(e) => {
                                            this.setState({ selectedId: e.target.id })
                                        }}
                                        onChange={async (e) => {
                                            // if (!this.state.client_settings) {
                                            //     this.state.client_settings = { color_accent: "" };
                                            // }
                                            e.target.value = e.target.value.replace(/#/g, "")
                                            this.state.client_settings.color_accent = e.target.value;
                                            await this.promisedSetState({
                                                client_settings: this.state.client_settings
                                            })
                                        }}
                                    />
                                </div>
                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                    <input
                                        style={{ width: 300, height: 65, bottom: -10 }}
                                        className="absolute cursor-pointer"
                                        type="color"
                                        value={"#" + (this.state.client_settings && this.state.client_settings.color_accent ? this.state.client_settings.color_accent : "ffffff")}
                                        onChange={async (e) => {
                                            // if (!this.state.client_settings) {
                                            //     this.state.client_settings = { color_accent: "" };
                                            // }
                                            let color_string = e.target.value.slice(1)
                                            this.state.client_settings.color_accent = color_string;
                                            await this.promisedSetState({
                                                client_settings: this.state.client_settings
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-1'>
                        <div className='flex flex-col w-full'>
                            <DropdownTailwind
                                label='Primary logo size'
                                selected={this.state.client_settings.logo_size && this.state.client_settings.logo_size.value ? this.state.client_settings.logo_size : { id: 2, name: 'Medium', value: "h-5" }}
                                options={[
                                    { id: 1, name: "Large", value: "h-8" },
                                    { id: 2, name: "Medium", value: "h-5" },
                                    { id: 3, name: "Small", value: "h-3" },
                                ]}
                                onChange={async (value) => {
                                    this.state.client_settings.logo_size = value;
                                    await this.promisedSetState({
                                        client_settings: this.state.client_settings
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className='col-span-1'>
                        <div className='flex flex-col w-full'>
                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Access public orders</div>
                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 text-sm ml-5">
                                    {this.state.client_settings.public_orders ? "Enabled" : "Disabled"}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.state.client_settings.public_orders ? true : false}
                                        onSwitch={async () => {
                                            this.state.client_settings.public_orders = !this.state.client_settings.public_orders;
                                            await this.promisedSetState({
                                                client_settings: this.state.client_settings
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='flex w-full gap-4 mt-4'>
                    <div className='flex flex-col w-full'>
                        <UploadImage
                            imageOnly={true}
                            title={"Upload primary logo (png)"}
                            size={"full"}
                            cover={false}
                            logo={true}
                            max_width={false}
                            value={this.state.client_settings.logo_primary}
                            error={this.state.error === "logo" && !this.state.logo}
                            onChange={(url) => {
                                // this.setState({ logo: url });
                                this.state.client_settings.logo_primary = url;
                                this.promisedSetState({
                                    client_settings: this.state.client_settings
                                })
                            }}
                        />
                    </div>
                    <div className='w-full'>
                        <UploadImage
                            imageOnly={true}
                            title={"Upload smaller, secondary logo (png)"}
                            size={"full"}
                            cover={false}
                            logo={true}
                            max_width={false}
                            value={this.state.client_settings.logo_secondary}
                            error={this.state.error === "logo" && !this.state.logo}
                            onChange={(url) => {
                                // this.setState({ logo: url });
                                this.state.client_settings.logo_secondary = url;
                                this.promisedSetState({
                                    client_settings: this.state.client_settings
                                })
                            }}
                        />
                    </div>

                </div>
            </div>
        </div>
    </div>
}
*/

