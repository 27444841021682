import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import TextAreaTailwind from './textAreaTailwind';
import { campaignLinkedin } from '../validators/campaignLinkedin';
import SwitchTailwind from './switchTailwind';

class EditLiveLinkedinCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            campaign: {},
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ],
            status_values: [
                { id: 1, name: 'Active', value: "ACTIVE" },
                { id: 2, name: 'Paused', value: "PAUSED" },
                { id: 3, name: 'Deleted', value: "REMOVED" },
                { id: 4, name: 'Archived', value: "ARCHIVED" },
                { id: 5, name: 'Cancelled', value: "CANCELLED" },
                { id: 6, name: 'Draft', value: "DRAFT" },

            ],
            locked: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false
        })
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            "patch": {
                                "$set": {
                                    ...(this.state.updated.name) && { name: this.state.updated.name },
                                    ...(this.state.updated.budget) && { totalBudget: { "amount": this.state.updated.budget, "currencyCode": this.state.campaign.budget.currencyCode } },
                                    ...(this.state.updated.start_date || this.state.updated.end_date || this.state.updated.end_date === null) && {
                                        runSchedule: {
                                            start: (new Date(this.state.campaign.runSchedule.start).getTime() < new Date().getTime() ? this.state.campaign.runSchedule.start : new Date(this.renders.startDate()).getTime()),
                                            ...(this.state.updated.end_date) && { end: new Date(this.renders.endDate()).getTime() }
                                        }
                                    },
                                }
                            }
                        }
                        body.changelog = {
                            initial_data: this.state.campaign,
                            updated_data: this.state.updated,
                            level: "campaign",
                        }
                        try {
                            let response = await this.calls.updateLiveCampaign(body);
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseCampaign(true);
                        } else {
                            this.props.onActivateCampaign(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });

                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) {
                        this.props.onSuccess(true);
                    }
                }

                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        }
    };

    calls = {
        updateLiveCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveCampaign?client=" + this.state.client.id + "&campaign=" + this.state.campaign.id + "&channel=linkedin";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        budgetMode: () => {
            try {
                if ('budgetMode' in this.state.updated) {
                    return this.state.updated.budgetMode;
                } else if (this.state.campaign.budget === null) {
                    return { id: 1, name: "No", value: false };
                } else {
                    return { id: 2, name: "Yes", value: true };
                }
            } catch (error) {
                return "";
            }
        },
        budget: () => {
            try {
                if ('budget' in this.state.updated) {
                    return this.state.updated.budget;
                } else if (this.state.campaign.budget) {
                    return this.state.campaign.budget.amount;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else if (this.state.campaign.start) {
                    return this.state.campaign.start;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else if (this.state.campaign.end) {
                    return this.state.campaign.end;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },

        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && campaignLinkedin.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.campaign.start).unix() && { status: this.renders.status() }) }).start_date) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*BASIC*/}
                    {
                        this.state.tab.id === 1 &&
                        <div className="col-span-6 grid grid-cols-6 gap-4">
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className={(this.renders.budgetMode().id == 2 ? "col-span-3" : "col-span-6") + ""}>
                                <DropdownTailwind
                                    label={"Campaign total budget"}
                                    selected={this.renders.budgetMode()}
                                    options={[
                                        { id: 1, name: 'No', value: false },
                                        { id: 2, name: 'Yes', value: true }
                                    ]}
                                    locked={true}
                                />
                            </div>
                            {
                                this.renders.budgetMode().id == 2 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={(this.state.client.currency && this.state.client.currency.linkedin ? true : false)}
                                        leftSectionLabel={(this.state.client.currency && this.state.client.currency.linkedin ? this.state.client.currency.linkedin : "")}
                                        label={"Total budget"}
                                        value={this.renders.budget()}
                                        onChange={(value) => {
                                            this.state.updated.budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>

                            }
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        error={campaignLinkedin.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.campaign.start).unix() && { status: this.renders.status() }) }).start_date}
                                        disabled={(moment().unix() > moment(this.state.campaign.start).unix() ? true : false)}
                                        locked={(moment().unix() > moment(this.state.campaign.start).unix() ? true : false)}
                                        placeholder={(moment().unix() > moment(this.renders.startDate()).unix() ? moment(this.renders.startDate()).format("MM/DD/YYYY") : "")}
                                        label={"Start date"}
                                        value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        label={"End date"}
                                        disabled={!this.renders.endDate()}
                                        labelRight={this.renders.budgetMode().id == 2 ? false : (this.renders.endDate() ? "Disable" : "Enable")}
                                        placeholder={!this.renders.endDate() ? "No end date" : ""}
                                        value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                        onChange={async (value) => {
                                            this.state.updated.end_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        onLabelRightClick={async (value) => {
                                            if (this.renders.endDate()) {
                                                this.state.updated.end_date = null;
                                            } else {
                                                let new_date = moment(this.renders.startDate()).add(1, 'days').format("YYYY-MM-DD");
                                                this.state.updated.end_date = moment(new_date);
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-span-3">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ACTIVE" ? true : false}
                                            disabled={this.state.locked}
                                            onSwitch={async () => {
                                                if (!this.state.locked) {
                                                    if (this.renders.status().value === "ACTIVE") {
                                                        this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                                    } else if (this.renders.status().value === "PAUSED") {
                                                        this.functions.update({ id: 1, name: 'Active', value: "ACTIVE" });
                                                    }
                                                }
                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/*BUDGET*/}


                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveLinkedinCampaign;
