import { IdentificationIcon } from "@heroicons/react/outline";

class clientRegister {

    static client = { id: 0, name: "All clients (default)", icon: IdentificationIcon };

    static set(client) {
        clientRegister.client = client;
        try {
            sessionStorage.setItem("client", JSON.stringify(client));
        } catch (e) { }
    }

    static get() {
        if (clientRegister.client.id !== 0) {
            return clientRegister.client
        } else {
            try {
                if (sessionStorage.getItem("client")) {
                    return JSON.parse(sessionStorage.getItem("client"));
                } else {
                    return { id: 0, name: "All clients (default)", icon: IdentificationIcon };
                }
            } catch (e) {
                return { id: 0, name: "All clients (default)", icon: IdentificationIcon };
            }
        }
    }

    static remove() {
        clientRegister.user = { id: 0, name: "All clients (default)", icon: IdentificationIcon };
        try {
            sessionStorage.removeItem("client");
        } catch (e) { }
    }

}

export { clientRegister }