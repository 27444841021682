import React, {Component, Fragment} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";
import {calls} from "../facebook-location/calls";
import Dropdown from "../dropdown";
import FacebookLocationMap from "../facebook-location-map";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class FacebookLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            loading_countries: false,
            countries_suggestions: [],
            countries_selected: [],
            countries_search: "",
            loading_locations: false,
            locations_suggestions: [],
            locations_selected: [],
            locations_search: "",
            center: {lat: 40.64, lng: -73.96},
            zoom: 12,
            location: {}
        };
        this.google = window.google;
    };

    componentDidMount() {
        this.setState({
            token: this.props.token
        });
        try {
            this.autocomplete = new this.google.maps.places.AutocompleteService();
        } catch (e) {}
        try {
            this.geocoder = new this.google.maps.Geocoder;
        } catch (e) {}
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            token: this.state.token !== nextProps.token ? nextProps.token : this.state.token,
        });
    }

    functions = {
        countries: (search) => {
            this.setState({
                countries_suggestions: [],
                loading_countries: true
            }, () => {
                if(search !== ""){
                    calls.getGeoTargeting(this.state.token, search).then((response) => {
                        this.setState({
                            countries_suggestions: response.data,
                            loading_countries: false
                        });
                    }, (error) => {
                        this.setState({
                            countries_suggestions: [],
                            loading_countries: false
                        })
                    });
                }else{
                    this.setState({
                        countries_suggestions: [],
                        loading_countries: false
                    })
                }
            })
        },
        addCountry: (value) => {
            this.state.countries_selected.push(value);
            this.setState({
                countries_selected: this.state.countries_selected,
                countries_suggestions: []
            })
        },
        removeCountry: () => {

        },
        locations: (search) => {
            let self = this;
            if(search !== ""){
                this.setState({
                    loading_locations: true
                }, () => {
                    this.autocomplete.getPlacePredictions({
                        input: search
                    }, function (predictions, status) {
                        if (status === 'OK' && predictions && predictions.length > 0) {
                            self.setState({
                                loading_locations: false,
                                locations_suggestions: predictions.map((item) => {
                                    item.name = item.description;
                                    return item;
                                })
                            });
                        }else{
                            self.setState({
                                loading_locations: false,
                                locations_suggestions: []
                            });
                        }
                    });
                })
            }else{
                self.setState({
                    loading_locations: false,
                    locations_suggestions: []
                });
            }
        },
        addLocation: (value) => {
            let object = {
                name: value.formatted_address,
                lat: parseFloat(value.geometry.location.lat()),
                lng: parseFloat(value.geometry.location.lng()),
                radius: 3000
            };
            this.state.locations_selected.push(object);
            this.setState({
                center: {lat: value.geometry.location.lat(), lng: value.geometry.location.lng()},
                locations_selected: this.state.locations_selected,
                location: object
            })
        },
        locate: (value) => {
            this.setState({
                center: {lat: value.lat, lng: value.lng},
                location: value
            });
        }
    };

    init = {};

    renders = {};

    render() {
        return (
            <div style={{width: '100%'}}>
                <div style={{textAlign: 'left'}} className="Section">
                    Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                    usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                    molestiae est.
                </div>
                <div className="Section">
                    <Dropdown
                        filter={true}
                        searchFilter={true}
                        search={true}
                        title={'Countries'}
                        size={'full'}
                        loading={this.state.loading_countries}
                        placeholder={"Search here .."}
                        error={this.state.error === "countries"}
                        options={this.state.countries_suggestions}
                        value={this.state.countries_search}
                        onSearch={(value) => {
                            this.functions.countries(value)
                        }}
                        onChange={(value) => {
                            this.functions.addCountry(value);
                        }}
                    />
                </div>
                {
                    this.state.countries_selected.length > 0 &&
                    <div style={{paddingTop: '10px'}} className="Section">
                        <div className="Locations-Items">
                            {
                                this.state.countries_selected.map((item) => {
                                    return (
                                        <div className="Location-Item">
                                            <div className="Location-ItemLeft">
                                                {item.name}
                                            </div>
                                            <div onClick={() => {
                                                this.functions.removeCountry(item);
                                            }} className="Location-ItemRight">
                                                <FeatherIcon color={"#FC5353"} size={23} icon="trash"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    <div className="Section">
                        <Dropdown
                            filter={true}
                            searchFilter={true}
                            search={true}
                            title={'Locations'}
                            size={'full'}
                            loading={this.state.loading_locations}
                            placeholder={"Search here .."}
                            error={this.state.error === "locations"}
                            options={this.state.locations_suggestions}
                            value={this.state.locations_search}
                            onSearch={(value) => {
                                this.functions.locations(value)
                            }}
                            onChange={(value) => {
                                let self = this;
                                this.geocoder.geocode({'placeId': value.place_id}, function (results, status) {
                                    if (status === 'OK' && results && results.length > 0) {
                                        //console.log(results[0]);
                                        self.functions.addLocation(results[0]);
                                    }
                                });
                            }}
                        />
                    </div>
                }
                {
                    <div style={{position: 'relative', width: '100%'}} className="Section">
                        <FacebookLocationMap
                            center={this.state.center}
                            zoom={12}
                            places={this.state.locations_selected}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                            loadingElement={<div style={{ height: `100%`, width: '100%'}} />}
                            containerElement={<div style={{ height: `500px` ,width: '100%', borderRadius: '3px', overflow: 'hidden', border: '2px solid #e7e7e7'}}/>}
                            mapElement={<div style={{ height: `100%` ,width: '100%'}} />}
                        />
                    </div>
                }
                {
                    this.state.location.name &&
                    <div className="Section">
                        <div className="SliderContainer">
                            <div className="Section-InnerTitle">
                                Set radius
                            </div>
                            <div className="SliderContainer-Inner">
                                <Slider
                                    handleStyle={{
                                        width: '25px',
                                        height: '25px',
                                        border: 'solid 2px #0185fe',
                                        marginTop: '-10px'
                                    }}
                                    trackStyle={{
                                        backgroundColor: '#0185fe'
                                    }}
                                    min={3000}
                                    max={100000}
                                    onChange={(value) => {
                                        this.state.location.radius = value;
                                        this.setState({
                                            location: this.state.location
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="SliderContainerValue">
                            <div className="Section-InnerTitle">
                                Radius
                            </div>
                            <div className="SliderContainerValue-Inner">
                                {Math.round(this.state.location.radius/1000)}km
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.locations_selected.length > 0 &&
                    <div className="Section">
                        <div className="Locations-Items">
                            {
                                this.state.locations_selected.map((item) => {
                                    return (
                                        <div className="Location-Item">
                                            <div onClick={() => {
                                                this.functions.locate(item);
                                            }} className="Location-ItemRight">
                                                <FeatherIcon color={"#0185fe"} size={23} icon="map-pin"/>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.locate(item);
                                            }} className="Location-ItemLeft">
                                                {item.name}<span className="Location-ItemLeftRadius">{item.radius/1000}km radius</span>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.removeLocation(item);
                                            }} className="Location-ItemRight">
                                                <FeatherIcon color={"#FC5353"} size={23} icon="trash"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    this.state.countries_selected.length > 0 &&
                    <div className="Section">
                        <div className="Button ButtonFull">
                            Next
                        </div>
                    </div>
                }
            </div>
        )
    }
}

/*
<GoogleMap
                        defaultZoom={this.state.zoom}
                        defaultCenter={this.state.center}
                    >
                        {this.state.locations_selected.map(place => {
                            return (
                                <Fragment key={place.id}>
                                    <Marker
                                        position={{
                                            lat: parseFloat(place.latitude),
                                            lng: parseFloat(place.longitude)
                                        }}
                                    />
                                    {place.circle && <Circle
                                        defaultCenter={{
                                            lat: parseFloat(place.latitude),
                                            lng: parseFloat(place.longitude)
                                        }}
                                        radius={place.circle.radius}
                                        options={place.circle.options}
                                    />}
                                </Fragment>
                            );
                        })}
                    </GoogleMap>
 */

export default FacebookLocation;
