import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchItem from "../optimize-search-item";
import OptimizeSocialItem from "../optimize-social-item";
import cx from "classnames";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import OptimizeSearchQueries from "../optimize-search-queries";
import OptimizeSearchKeywords from "../optimize-search-keywords";
import OptimizeSearchKeywordsPositive from "../optimize-search-keywords-positive";

class OptimizeSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 'search-terms',
            tabs: [{name: 'Searchterms', value: 'search-terms'}, {name: 'Negative keywords', value: 'keywords'}, {name: 'Keywords', value: 'positive_keywords'}],
            queries: [],
            sort_queries: {name: 'click', value: 'click'},
            order_queries: {name: 'Descending', value: 'desc'},
            loading_queries: true,
            keywords: [],
            sort_keywords: {},
            order_keywords: {},
            loading_keywords: true,
            positive_keywords: [],
            loading_positive_keywords: true,
            navToggleActive: "search-terms",
            navToggle: [
                {
                    title: 'Searchterms',
                    id: 'search-terms',
                    icon: ''
                },
                {
                    title: 'Negative keywords',
                    id: 'keywords',
                    icon: ''
                },
                {
                    title: 'Keywords',
                    id: 'positive_keywords',
                    icon: ''
                }
            ]
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: this.props.campaign
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign
        }, () => {
            this.functions.getSearchterms();
            this.functions.getNegativeKeywords();
            this.functions.getPositiveKeywords();
        });
    }

    init = {};

    functions = {
        getSearchterms: () => {
            this.setState({
                loading_queries: true,
                queries: [],
            }, () => {
                calls.getSearchterms(this.state.campaign.id, this.state.sort_queries.value, this.state.order_queries.value).then((response) => {
                    this.setState({
                        queries: response.data,
                        loading_queries: false
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        },
        removeSearchterm: (value) => {
            if(value.negative_keywords){
                for(let key in value.negative_keywords){
                    if(!value.negative_keywords[key].error){
                        this.state.keywords.unshift(value.negative_keywords[key]);
                    }
                }
            }
            this.setState({
                keywords: this.state.keywords
            })
        },
        getPositiveKeywords: () => {
            this.setState({
                loading_positive_keywords: true,
                positive_keywords: [],
            }, () => {
                calls.getPositiveKeywords(this.state.campaign.id).then((response) => {
                    this.setState({
                        positive_keywords: response.data,
                        loading_positive_keywords: false
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        },
        addPositivtKeyword: (value) => {
            this.state.positive_keywords.unshift(value);
            this.setState({
                positive_keywords: this.state.positive_keywords
            })
        },
        getNegativeKeywords: () => {
            this.setState({
                loading_keywords: true,
                keywords: [],
            }, () => {
                calls.getNegativeKeywords(this.state.campaign.id).then((response) => {
                    this.setState({
                        keywords: response.data,
                        loading_keywords: false
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        },
        addNegativeKeyword: (value) => {
            this.state.keywords.unshift(value);
            this.setState({
                keywords: this.state.keywords
            })
        },
        removeNegativeKeyword: (value) => {
            let keywords = this.state.keywords.filter((item) => {
                return JSON.stringify(item) !== JSON.stringify(value)
            });
            this.setState({
                keywords: keywords
            })
        }
    };

    renders = {
        amount: (key) => {
            if(key === 'search-terms'){
                return "<b>" + ((this.state.loading_queries || this.state.loading_keywords)?"":this.renders.queries().length) + "</b>";
            }else if(key === 'keywords'){
                return "<b>" + (this.state.loading_keywords?"":this.state.keywords.length) + "</b>";
            }else if(key === 'positive_keywords'){
                return "<b>" + (this.state.loading_positive_keywords?"":this.state.positive_keywords.length) + "</b>";
            }else{
                return ""
            }
        },
        queries: () => {
            if(this.state.keywords && this.state.queries){
                for(let i = 0; i < this.state.keywords.length; i++){
                    for(let m = 0; m < this.state.queries.length; m++){
                        try {
                            if(this.state.queries[m].query.toLowerCase() === this.state.keywords[i].text.toLowerCase() && this.state.keywords[i].type === 'exact'){
                                this.state.queries[m].negative_exist = true;
                            }
                        }catch (e) {}
                        try {
                            if(this.state.queries[m].query.toLowerCase().indexOf(this.state.keywords[i].text.toLowerCase()) !== -1 && this.state.keywords[i].type === 'phrase'){
                                this.state.queries[m].negative_exist = true;
                            }
                        }catch (e) {}
                        try {
                            let tokens = this.state.keywords[i].text.split(" ");
                            for(let index = 0; index < tokens.length; index++){
                                if(this.state.queries[m].query.toLowerCase().indexOf(tokens[index].toLowerCase()) !== -1 && this.state.keywords[i].type === 'broad'){
                                    this.state.queries[m].negative_exist = true;
                                    break;
                                }
                            }
                        }catch (e) {}
                    }
                }
                let parsed = this.state.queries.filter((item) => {
                    return !item.negative_exist
                });
                return parsed;
            }else{
                return [];
            }
        },
        markdown: (input) => {
            return {__html: input}
        },
    };

    render() {
        return (
            <div className="">
                {
                    !this.state.loading_queries && !this.state.loading_keywords &&
                    <div>
                        <nav className="py-2">
                            <ul className="flex flex-row items-center overflow-scroll w-full whitespace-no-wrap">
                                {this.state.navToggle.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <button onClick={() => {
                                                this.setState({
                                                    navToggleActive: item.id,
                                                    selected: item.id
                                                }, () => {
                                                    if(this.state.navToggleActive === 'external'){

                                                    }
                                                })
                                            }}
                                                    className={cx("cursor-pointer py-2 px-4 hover:opacity-100 font-bold text-sm border-b-2 flex flex-row items-center focus:outline-none", {
                                                        ["border-purple-500 opacity-100 text-purple-500"]: item.id === this.state.navToggleActive,
                                                        ["border-gray-300 opacity-50"]: item.id != this.state.navToggleActive
                                                    })}>
                                                {item.icon !== '' &&
                                                    <FeatherIcon className="stroke-current mr-2" size={15}
                                                                 icon={item.icon}/>
                                                }
                                                <span className="text-gray-900">{item.title}</span>
                                                <div className="text-gray-900" dangerouslySetInnerHTML={this.renders.markdown(this.renders.amount(item.id))} style={{marginLeft: '7px', textTransform: 'capitalize'}}/>
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                }
                {
                    this.state.selected === 'search-terms' &&
                    <OptimizeSearchQueries
                        campaign={this.state.campaign}
                        queries={this.renders.queries()}
                        loading={this.state.loading_queries || this.state.loading_keywords}
                        onRemove={(value) => {
                            this.functions.removeSearchterm(value);
                        }}
                    />
                }
                {
                    this.state.selected === 'keywords' &&
                    <OptimizeSearchKeywords
                        campaign={this.state.campaign}
                        keywords={this.state.keywords}
                        loading={this.state.loading_keywords}
                        onRemove={(value) => {
                            this.functions.removeNegativeKeyword(value);
                        }}
                        onAdd={(value) => {
                            this.functions.addNegativeKeyword(value);
                        }}
                    />
                }
                {
                    this.state.selected === 'positive_keywords' &&
                    <OptimizeSearchKeywordsPositive
                        campaign={this.state.campaign}
                        keywords={this.state.positive_keywords}
                        loading={this.state.loading_positive_keywords}
                        onRemove={(value) => {
                            //this.functions.removeNegativeKeyword(value);
                        }}
                        onAdd={(value) => {
                            this.functions.addPositivtKeyword(value);
                        }}
                    />
                }
            </div>
        )
    }
}

export default OptimizeSearch;
