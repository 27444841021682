import React, { Component } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/solid'

import { AnnotationIcon, ThumbUpIcon, RefreshIcon } from '@heroicons/react/outline';

class PreviewLinkedInCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            slides: {},
            body: "",
            read_more: false,
            adData: {},
            is_post: false,
            for_report: false,
            preview_placement: "",
        }
    };


    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            slides: this.props.slides,
            body: this.props.body,
            adData: this.props.adData,
            is_post: this.props.is_post,
            for_report: this.props.for_report,
        })

        try {
            const slider = document.querySelector('#carousel_container');
            let isDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        } catch (e) {

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            slides: nextProps.slides,
            body: nextProps.body,
            adData: nextProps.adData,
            is_post: nextProps.is_post,
        })
    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.cta.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.cta.name;
                    }
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                <div
                    className='w-80 rounded border shadow-xl bg-white'>
                    <div className='flex flex-1 justify-between px-3 py-2'>
                        <div className='flex flex-1 items-start'>
                            <div className='mr-1'>
                                {
                                    this.state.page.image &&
                                    <img src={this.state.page.image} alt={this.state.page.name ? this.state.page.name : "Alt text"} className='h-10 w-10 rounded-full' />
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='h-10 w-10 border rounded-full'></div>
                                }
                            </div>
                            <div className='flex flex-col text-sm font-semibold'>
                                {this.state.page.name ? this.state.page.name : "Page name"}
                                <div className='flex flex-col font-normal text-xs text-gray-700'>
                                    <span>453 followers</span>
                                    <span>Promoted</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-0'><DotsHorizontalIcon className='w-5' /></div>
                            <div className="flex flex-1"></div>
                        </div>
                    </div>
                    <div className='text-sm px-3 pb-2'>
                        {
                            this.state.body !== "" ?
                                <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                </span>
                                : "Body..."
                        }
                        {
                            !this.state.read_more &&
                            this.renders.body().length > 125 &&
                            <span
                                onClick={async () => {
                                    await this.promisedSetState({
                                        read_more: true
                                    });
                                }} className="cursor-pointer font-semibold text-sm text-center text-gray-600">
                                {" "}more
                            </span>
                        }
                    </div>
                    <div
                        id={"carousel_container"}
                        className='flex  w-full space-x-2 overflow-x-auto px-1 pb-1 cursor-pointer select-none'>
                        {
                            Array.isArray(this.state.slides) && this.state.slides.length > 0 &&
                            this.state.slides.map((slide, index) => {
                                return (
                                    <span key={index}
                                        className='flex flex-col flex-1 min-w-68 min-h-68 rounded-t'>
                                        {slide.type === "image" &&
                                            <img src={slide.picture} alt={slide.name ? slide.name : "Alt text"}
                                                className={`w-full overflow-hidden ${!this.state.is_post ? "rounded-t" : "rounded"} border-t border-l border-r`}
                                                style={!this.state.adData.files ? { height: 272, objectFit: "contain" } : (this.state.adData.files && ((this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16") || (this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio !== "1:1"))) ?
                                                    { height: 272, objectFit: `${this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16" ? "cover" : "contain"}` }
                                                    : { maxHeight: 272, height: "100%" }}
                                            />
                                        }
                                        {slide.type === "video" &&
                                            <div style={{ height: 272 }}
                                                className="flex flex-col justify-center text-sm rounded-t w-full px-5 bg-gray-300"
                                            >
                                                Video ads not supported by platform
                                            </div>
                                        }
                                        {
                                            !this.state.is_post &&
                                            <div className='flex p-2 border bg-white rounded-b'>
                                                <div className="flex flex-col space-y-2 w-full">
                                                    <div className='text-sm font-semibold pr-1'>
                                                        {slide.title ? this.functions.truncText(slide.title, 70) : "Headline..."}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </span>
                                )
                            })
                        }
                    </div >
                    <div className='flex justify-between space-x-4 items-center text-xms font-semibold px-3 py-2 text-gray-700'>
                        <div className='flex items-center'><ThumbUpIcon className='w-5 mr-1' />Like</div>
                        <div className='flex items-center'><AnnotationIcon className='w-5 mr-1' />Comment</div>
                        <div className='flex items-center'><RefreshIcon className='w-5 mr-1' />Repost</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewLinkedInCarousel;