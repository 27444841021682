import {apiRegister} from "../../services/apiRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAccount(token) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/me?fields=id%2Cname&access_token=' + token, options)
                .then((response) => {
                    //console.log(response);
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getBusinesses(token) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/me/businesses?fields=timezone_id,name&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getAdaccounts(business_id, token) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+business_id+'/owned_ad_accounts?fields=name,funding_source,funding_source_details,account_id,currency&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getAdaccount(adaccount_id, token) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+adaccount_id+'?fields=name,funding_source,funding_source_details,account_id,currency&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createAdaccount(business_id, token, name, timezone, currency) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+business_id+'/adaccount?end_advertiser='+business_id+'&media_agency=NONE&partner=NONE&currency='+currency+'&name='+name+'&timezone_id='+timezone+'&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getPages(business_id, token) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+business_id+'/owned_pages?fields=name,picture&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getGeoTargeting(token, search) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/search?q='+search+'&type=adgeolocation&location_types=["country","city"]&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getAudience(token, adaccount, data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+adaccount+'/reachestimate?targeting_spec='+data+'&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getEstimate(token, adaccount, data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+adaccount+'/delivery_estimate?targeting_spec='+data+'&optimization_goal=LINK_CLICKS&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createCampaign(adaccount, token, name, objective, bid_strategy) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/'+adaccount+'/campaigns?special_ad_category=NONE&name='+name+'&objective='+objective+'&status=PAUSED&bid_strategy='+bid_strategy+'&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

}

export {calls}