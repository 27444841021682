import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import {calls} from "../advertising-targeting/calls";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import TextArea from "../text-area";

class AdvertisingTargetingCommon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            channels: {},
            age_min_options: [],
            age_max_options: []
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting,
            channels: this.props.channels,
        }, () => {

            this.state.targeting.age_max = this.state.targeting.age_max?this.state.targeting.age_max:{name: '65+', value: 65};
            this.state.targeting.age_min = this.state.targeting.age_min?this.state.targeting.age_min:{name: 18, value: 18};
            this.state.targeting.gender = this.state.targeting.gender?this.state.targeting.gender:{name: 'Male and Female', value: 0};

            this.setState({
                targeting: this.state.targeting,
                age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
            })

        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        age: (min, max) => {
            let age = [];
            for (let i = min; i < max; i++) {
                let object = {name: i, value: i};
                if (i === 65) {
                    object.name = '65+';
                }
                age.push(object);
            }
            return age;
        },
        number: (x) => {
            try {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    <div style={{marginBottom: '0px'}} className="Section">
                        <Dropdown
                            filter={false}
                            title={'Gender'}
                            size={'half'}
                            icons={false}
                            channels={Object.keys(this.state.channels).filter((key) => {
                                return this.state.channels[key]
                            })}
                            placeholder={"Click here .."}
                            error={this.state.error === "gender"}
                            options={[
                                {name: 'Male', value: 1},
                                {name: 'Female', value: 2},
                                {name: 'Male and Female', value: 0}
                            ]}
                            value={this.state.targeting.gender?this.state.targeting.gender:{}}
                            onChange={(value) => {
                                this.state.targeting.gender = value;
                                this.setState({targeting: this.state.targeting}, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                        <div className="AgeSpan">
                            <div className="AgeSpanItem">
                                <Dropdown
                                    filter={false}
                                    title={'Min Age'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    icons={false}
                                    channels={Object.keys(this.state.channels).filter((key) => {
                                        return this.state.channels[key]
                                    })}
                                    error={this.state.error === "gender"}
                                    options={this.state.age_min_options}
                                    value={this.state.targeting.age_min?this.state.targeting.age_min:{}}
                                    onChange={(value) => {
                                        this.state.targeting.age_min = value;
                                        this.setState({
                                            targeting: this.state.targeting,
                                            age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                                            age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                            <div className="AgeSpanItemCenter">
                            </div>
                            <div className="AgeSpanItem">
                                <Dropdown
                                    filter={false}
                                    title={'Max Age'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    icons={false}
                                    channels={Object.keys(this.state.channels).filter((key) => {
                                        return this.state.channels[key]
                                    })}
                                    error={this.state.error === "gender"}
                                    options={this.state.age_max_options}
                                    value={this.state.targeting.age_max?this.state.targeting.age_max:{}}
                                    onChange={(value) => {
                                        this.state.targeting.age_max = value;
                                        this.setState({
                                            targeting: this.state.targeting,
                                            age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                                            age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.targeting &&
                    <div style={{paddingLeft: '0px', paddingRight: '0px'}} className="WeekDays">
                        <div className="WeekDaysTitle">
                            <div className={cx("InputContainerTitleText")}>
                                Weekdays to show ads
                            </div>
                            {
                                false &&
                                <div className={cx("InputContainerTitleIcons")}>
                                    {
                                        this.state.channels.facebook &&
                                        <div style={{marginLeft: '3px'}}>
                                            <IconFacebook noMargin={true}/>
                                        </div>
                                    }
                                    {
                                        this.state.channels.instagram &&
                                        <div style={{marginLeft: '3px'}}>
                                            <IconInstagram noMargin={true}/>
                                        </div>
                                    }
                                    {
                                        this.state.channels.google &&
                                        <div style={{marginLeft: '3px'}}>
                                            <IconGoogle noMargin={true}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {
                            this.state.targeting.weekdays &&
                            <div className="WeekDaysItems">
                                <div className="WeekDaysItems-Item">
                                    <FeatherIcon color={"#333333"} size={22} icon="calendar"/>
                                    <div className="WeekDaysItems-ItemTitle">
                                        All week
                                    </div>
                                    <div className="WeekDaysItems-ItemSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            checked={this.state.targeting.weekdays.custom}
                                            onChange={(checked) => {
                                                this.state.targeting.weekdays.custom = checked;
                                                for(let key in this.state.targeting.weekdays){
                                                    this.state.targeting.weekdays[key] = checked;
                                                }
                                                this.props.onChange(this.state.targeting);
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.targeting.weekdays.custom &&
                                    <div className="WeekDaysItems-Item-Border"/>
                                }
                                {
                                    Object.keys(this.state.targeting.weekdays).filter((item) => {
                                        return this.state.targeting.weekdays.custom && item !== 'custom'
                                    }).map((day, index) => {
                                        return(
                                            <div style={{with: '100%'}}>
                                                <div className="WeekDaysItems-Item">
                                                    <div className="WeekDaysItems-ItemIcon">
                                                        <FeatherIcon color={"#333333"} size={22} icon="corner-down-right"/>
                                                    </div>
                                                    <div className="WeekDaysItems-ItemTitle">
                                                        {day}
                                                    </div>
                                                    <div className="WeekDaysItems-ItemSlider">
                                                        <Switch
                                                            onColor="#24bda8"
                                                            checked={this.state.targeting.weekdays[day]}
                                                            onChange={(checked) => {
                                                                this.state.targeting.weekdays[day] = checked;
                                                                this.props.onChange(this.state.targeting);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    index !== 6 &&
                                                    <div className="WeekDaysItems-Item-Border"/>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingTargetingCommon;
