import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BeatLoader } from "react-spinners";
import Input from "../input";
import TextArea from "../text-area";

class AdvertisingCreativesFacebookVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: [],
            page: {},
            no_container: false,
            language: "",
            location: "",
            loader: false,
            read_more: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            page: nextProps.page,
            post: nextProps.post,
            language: nextProps.language,
            no_container: nextProps.noContainer,
            location: nextProps.location,
            loader: nextProps.loader,
        }, () => {
            console.log(this.state.creatives);
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            page: this.props.page,
            post: this.props.post,
            language: this.props.language,
            no_container: this.props.noContainer,
            location: this.props.location,
            loader: this.props.loader,
        });
    }

    functions = {};

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.creatives[0].headline !== "" ? this.state.creatives[0].headline : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.creatives[0].body !== "" ? this.state.creatives[0].body : "Body ...";
                body = body.replace("{location}", this.state.location);
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.creatives[0].description !== "" ? this.state.creatives[0].description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        /*
        title: () => {
            try {
                return this.state.creatives[0].headline !== "" ? this.state.creatives[0].headline : "Headline ..."
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                return this.state.creatives[0].body !== "" ? this.state.creatives[0].body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                return this.state.creatives[0].description
            } catch (e) {
                return ""
            }
        },
        */
        video: () => {
            try {
                return this.state.creatives[0].creative.url
            } catch (e) {
                return ""
            }
        },
        gif: () => {
            try {
                return this.state.creatives[0].creative.url.indexOf('.gif') !== -1 ? this.state.creatives[0].creative.url : null
            } catch (e) {
                return ""
            }
        },
        poster: () => {
            try {
                return this.state.creatives[0].creative.thumbnail.url
            } catch (e) {
                return ""
            }
        },
        mediaVideo: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.attachments.data[0].media.source
                }
            } catch (e) {
                return ""
            }
        },
        mediaPoster: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.thumbnail_url
                } else {
                    return this.state.creatives[0].facebook_post.attachments.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        ratio: () => {
            try {
                return this.state.creatives[0].creative.ratio
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.creatives[0].call_to_action.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action.name;
                    }
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer")}>
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem",
                        {
                            ["FacebookImage-PreviewBasicContainerItemError"]: this.state.error,
                            ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                        })
                }>
                    <div className={cx("FacebookImage-PreviewBasicContainerItemInner")}>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeader">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                                <div style={{ backgroundImage: 'url(' + this.state.page.image + ')' }} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                    {this.state.page.name}
                                </div>
                                {
                                    !this.props.linkedin &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                            Sponsored
                                        </div>
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot" />
                                        <img className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe" src={require('../../assets/images/globe.png')} />
                                    </div>
                                }
                                {
                                    this.props.linkedin &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                            Promoted
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="p-3">
                            {
                                !this.state.post &&
                                <div dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="font-medium text-sm whitespace-pre-wrap">

                                </div>
                            }

                            <div>
                                {
                                    !this.state.read_more && this.renders.body().length > 125 &&
                                    <div onClick={() => {
                                        this.setState({
                                            read_more: true
                                        });
                                    }} className=" font-semibold text-sm text-center text-purple-500 ">
                                        Read more
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.post &&
                            this.state.creatives[0].instagram_post &&
                            <div className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText">
                                {this.state.creatives[0].instagram_post.caption}
                            </div>
                        }
                        {
                            this.state.post &&
                            this.state.creatives[0].facebook_post &&
                            <div className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText">
                                {this.state.creatives[0].facebook_post.message}
                            </div>
                        }
                        <div style={{ maxHeight: "400px" }} className="w-full overflow-hidden flex justify-center items-center">
                            {
                                !this.state.loader &&
                                !this.renders.gif() &&
                                <video poster={!this.state.post ? this.renders.poster() : this.renders.mediaPoster()} autoPlay={true} muted={true} controls={true} style={{ width: '100%', height: '100%' }}>
                                    <source src={!this.state.post ? this.renders.video() : this.renders.mediaVideo()} type="video/mp4" />
                                </video>
                            }
                            {
                                this.renders.gif() &&
                                <img src={this.renders.gif()} style={{ width: '100%', height: '100%' }} />
                            }
                        </div>
                        {
                            false &&
                            <div className={
                                cx("FacebookImage-PreviewBasicContainerItemInnerVideo",
                                    {
                                        ["FacebookImage-PreviewBasicContainerItemInnerImageSquare"]: this.renders.ratio() === '1:1',
                                    })
                            }>
                                {
                                    !this.state.loader &&
                                    !this.renders.gif() &&
                                    <video poster={!this.state.post ? this.renders.poster() : this.renders.mediaPoster()} autoPlay={true} muted={true} controls={true} style={{ width: '100%', height: '100%' }}>
                                        <source src={!this.state.post ? this.renders.video() : this.renders.mediaVideo()} type="video/mp4" />
                                    </video>
                                }
                                {
                                    this.renders.gif() &&
                                    <img src={this.renders.gif()} style={{ width: '100%', height: '100%' }} />
                                }
                            </div>
                        }
                        {
                            !this.state.post &&
                            <div className={
                                cx("flex flex-col border-t border-b px-4 py-4",
                                    {
                                        ["bg-google-100"]: this.props.linkedin,
                                        ["bg-gray-100 bg-opacity-50"]: !this.props.linkedin,
                                    })
                            } style={{ marginTop: '-7px' }}>
                                <div className="flex flex-row">
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft">
                                        {
                                            !this.props.linkedin &&
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftUrl">
                                                {this.renders.url()}
                                            </div>
                                        }
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftHeadline">
                                            {this.renders.title()}
                                        </div>
                                        {
                                            !this.props.linkedin &&
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftDescription">
                                                {this.renders.description()}
                                            </div>
                                        }
                                    </div>
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRight">
                                        {
                                            this.renders.callToAction() && !this.props.linkedin &&
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton">
                                                {this.renders.callToAction()}
                                            </div>
                                        }
                                        {
                                            this.renders.callToAction() && this.props.linkedin &&
                                            <div className="rounded-full px-2 py-1 border border-google-500 text-google-500 font-bold text-xs">
                                                {this.renders.callToAction()}
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.props.linkedin &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftUrl">
                                        {this.renders.url()}
                                    </div>
                                }
                            </div>
                        }
                        <div className="FacebookImage-PreviewBasicContainerItemInnerShare">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike" src={require('../../assets/images/like.png')} />
                                Like
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment" src={require('../../assets/images/comment.png')} />
                                Comment
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare" src={require('../../assets/images/share.png')} />
                                Share
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesFacebookVideo;
