import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static removeNegative(internal, id, keyword) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/adset/" + internal + '/' + id + '/' + keyword;
        return apiRegister.call(options, url);
    }

    static createNegative(internal, id, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/adset/" + internal + '/' + id + '/keyword';
        return apiRegister.call(options, url);
    }

}

export {calls}