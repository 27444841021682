import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCountries(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?all=true&level=4";
        return apiRegister.call(options, url);
    }

    static getRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?search=" +encodeURI(data);
        return apiRegister.call(options, url);
    }

}

export {calls}