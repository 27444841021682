import { apiRegister } from '../../services/apiRegister';
import { tokenRegister } from '../../services/tokenRegister';

class calls {
    static url = {
        api: apiRegister.url.api,
    };

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', data);
        let url = calls.url.api + '/client';
        return apiRegister.call(options, url);
    }

    /*
    static getAllExternalCampaigns(data) {
        console.log(calls.url.api + "/v2/import?client=" + data.id);
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/import?client=" + data.id;
        return apiRegister.call(options, url);
    }
    */

    static getAllExternalCampaigns(data) {
        console.log("getAllExternalCampaigns", data);
        let options = apiRegister.options(tokenRegister.get(), 'POST', {});
        let url = calls.url.api + "/v2/controllers/listClientCampaigns?client=" + data.id;
        return apiRegister.call(options, url);
    }

    static getCampaignsByClient(id, page, sort, order, page_size) {
        console.log("getCampaignsByClient")
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?page=" + page + '&sort=' + sort + '&order=' + order + '&page_size=' + page_size + '&client=' + id;
        return apiRegister.call(options, url);
    }

    static getCampaigns() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + '/v2/internal';
        return apiRegister.call(options, url);
    }

    static updateReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + '/v2/report/' + data.report;
        return apiRegister.call(options, url);
    }

    static createReportCampaign(data) {
        console.log("createReport data", data)
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + '/v2/internal/hidden';
        return apiRegister.call(options, url);
    }

    static getCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + '/campaign/' + id;
        return apiRegister.call(options, url);
    }

    // static getSearchterms(id, sort, order) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/campaign/' + id + '/searchterms?sort=' + sort + '&order=' + order;
    //     return apiRegister.call(options, url);
    // }

    // static getAdset(internal, id) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/adset/' + internal + '/' + id;
    //     return apiRegister.call(options, url);
    // }

    // static getAd(internal, id) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/ad/' + internal + '/' + id;
    //     return apiRegister.call(options, url);
    // }

    // static updateAd(internal, id, status) {
    //     let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
    //     let url = calls.url.api + '/ad/' + internal + '/' + id + '?status=' + status;
    //     return apiRegister.call(options, url);
    // }
}

export { calls };
