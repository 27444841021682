import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { clientRegister } from "../services/clientRegister";
import InputTailwind from '../moduleFiles/inputTailwind';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import phone_codes from '../assets/json/phone-countries.json'
import translations from '../assets/json/onboarding_translation.json'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            number: "",
            loading: false,
            number_sent: false,
            otp: ["", "", "", "", "", ""],
            copied_code: ["", "", "", "", "", ""],
            whitelabel_settings: {},
            phone_codes: phone_codes,
            country_code: { id: 0, name: "Select...", value: 0 },
            error_country_code: false,
            error_number: false,
        }
    };

    // eniro yellow: ffe000
    // eniro black: 404040

    componentDidMount() {
        this.promisedSetState({
            whitelabel_settings: {
                logo: "https://res.cloudinary.com/ddu7oaekf/image/upload/v1697629226/jkj63fhe26mkgzrazupw.png",
                background_image: "https://images.pexels.com/photos/4473398/pexels-photo-4473398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                language: "sv",
                color_accent: "404040",
                color_font: "ffe000",
            },
            user: {
                language: "en"
            }
        })
    }

    functions = {
        tempCheckNumber: async () => {
            let response = await this.calls.tempLogin({ country_code: this.state.country_code, phone: this.state.number })
            console.log("response", response)
            tokenRegister.set(response.headers['x-auth-token'], false);
            let res = await this.calls.tempVerify();
            console.log("response", res)
            tokenRegister.set(res.headers['x-auth-token'], false);
            userRegister.set(res.data, false);
            this.props.history.push('/client');
        },
        checkNumber: async () => {
            // IF THERE IS A COUNTRY CODE BUT NO NUMBER
            if (this.state.country_code.value !== 0 && this.state.number === "") {
                await this.promisedSetState({
                    error_country_code: false,
                    error_number: true
                })
            }

            // IF THERE NO COUNTRY CODE BUT A NUMBER
            if (this.state.country_code.value === 0 && this.state.number !== "") {
                await this.promisedSetState({
                    error_country_code: true,
                    error_number: true
                })
            }

            // IF BOTH ARE MISSING
            if (this.state.country_code.value === 0 && this.state.number === "") {
                await this.promisedSetState({
                    error_country_code: true,
                    error_number: true
                })
            }

            // IF BOTH ARE FILLED IN
            if (this.state.country_code.value !== 0 && this.state.number !== "") {
                await this.promisedSetState({
                    error_country_code: false,
                    error_number: false
                })
            }

            // CHANGE THIS LATER
            try {
                if (!this.state.error_country_code && !this.state.error_number && this.state.number !== "") {
                    this.promisedSetState({
                        loading: true
                    })

                    let response = await this.calls.login({ country_code: this.state.country_code, phone: this.state.number })
                    tokenRegister.set(response.headers['x-auth-token'], false);

                    this.promisedSetState({
                        loading: false,
                        number_sent: true
                    })
                }
            } catch (error) {
                this.promisedSetState({
                    error_country_code: true,
                    error_number: true,
                    loading: false
                })
                console.log(error)
            }


            // DO STUFF
        },
        verify: async () => {
            console.log(this.state.otp)
            if (this.state.error || this.state.loading) {
                this.promisedSetState({
                    error: true,
                    loading: false
                })
                return
            }
            try {
                let otp_string = this.state.otp.join("")
                if (otp_string.length === 6) {
                    let response = await this.calls.verify({ phone: this.state.number, code: otp_string })
                    tokenRegister.set(response.headers['x-auth-token'], false);
                    userRegister.set(response.data, false);
                    this.props.history.push('/client');
                } else {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleOtpInput: async (value, index, mobile) => {
            let otp_string = [...this.state.otp];
            if (this.state.error && otp_string.length === 6) {
                await this.promisedSetState({
                    error: false
                })
            }
            otp_string[index] = value;
            await this.promisedSetState({
                otp: otp_string
            });
            if (this.state.otp[index] !== "") {
                if (index !== 5) {
                    if (mobile) {
                        document.getElementById("mobile-otp-digit" + (index)).blur();
                        document.getElementById("mobile-otp-digit" + (index + 1)).focus();
                    } else {
                        document.getElementById("otp-digit" + (index)).blur();
                        document.getElementById("otp-digit" + (index + 1)).focus();
                    }
                } else {
                    return
                }
            }
        },
        handleKeyDown: async (e, index, mobile) => {
            let otp_string = [...this.state.otp];
            if (e.key !== "Backspace") {
                return
            } else {
                if (index !== 0 && e.key === "Backspace") {
                    e.preventDefault();
                    otp_string[index] = "";
                    if (mobile) {
                        document.getElementById("mobile-otp-digit" + (index)).blur();
                        document.getElementById("mobile-otp-digit" + (index - 1)).focus();
                    } else {
                        document.getElementById("otp-digit" + (index)).blur();
                        document.getElementById("otp-digit" + (index - 1)).focus();
                    }

                    await this.promisedSetState({
                        otp: otp_string
                    });
                } else {
                    return
                }
            }
        },
        handlePaste: async (e) => {
            let pasted = e.clipboardData.getData('text');
            this.state.otp = pasted.split("");
            await this.promisedSetState({
                error: false,
                otp: this.state.otp
            });
        }

    };

    calls = {
        tempVerify: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/client/verify";
            return apiRegister.call(options, url);
        },
        tempLogin: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/client/login";
            return apiRegister.call(options, url);
        },
        login: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/client/login";
            return apiRegister.call(options, url);
        },
        verify: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/client/verify";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        if (!this.state.user.language) {
            return translations.sv.login[text]
        } else if (this.state.user.language === "sv") {
            return translations.sv.login[text]
        } else if (this.state.user.language === "en") {
            return translations.en.login[text]
        }
    }

    render() {
        let t = this.translate
        return (
            <>

                {/* MOBILE OR SMALLER SIZE */}
                <div className="min-h-full flex lg:hidden">
                    <div className="flex-1 flex flex-col justify-center lg:justify-center py-12 px-2 lg:w-1/3 lg:flex-none lg:px-20 xl:px-24 bg-cover bg-center"
                        style={{ backgroundImage: `url(${this.state.whitelabel_settings.background_image})` }}
                    >
                        <div className="mx-auto w-full max-w-sm lg:w-96 rounded-lg shadow-lg px-2 py-8 bg-white lg:bg-transparent lg:shadow-none lg:rounded-none">
                            <div>
                                {
                                    this.state.logo !== "" &&
                                    <div className='flex justify-center lg:block'>
                                        <img
                                            className="h-6 lg:h-8 w-auto"
                                            src={this.state.whitelabel_settings.logo ? this.state.whitelabel_settings.logo : null}
                                            alt="Company logo"
                                        />
                                    </div>
                                }
                                <div className='text-center lg:text-left'>
                                    <h2 className="mt-5 text-3xl font-extrabold text-gray-900">
                                        {t("text_1")}
                                    </h2>
                                    <p className="text-sm text-gray-600">
                                        {
                                            !this.state.number_sent &&
                                            <>
                                                {t("text_2")}
                                            </>
                                        }
                                        {
                                            this.state.number_sent &&
                                            <>
                                                {t("text_3")}
                                            </>
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6">
                                {/* MOBILE PHONE NUMBER INPUT */}
                                {
                                    !this.state.number_sent &&
                                    <>
                                        <div className='flex items-center w-full'>

                                            <div>
                                                <DropdownTailwind
                                                    error={this.state.error_country_code}
                                                    label={t("label_1")}
                                                    small={false}
                                                    searchInput={true}
                                                    selected={this.state.country_code ? this.state.country_code : { id: 0, name: "Select...", value: 0 }}
                                                    options={this.state.phone_codes.map((item) => {
                                                        return { name: `${item.iso} (${item.code})`, value: item.code, id: item.code };
                                                    })}
                                                    onChange={async (value) => {
                                                        if (this.state.country_code !== 0) {
                                                            this.state.error_country_code = false
                                                        }
                                                        this.state.country_code = value;
                                                        await this.promisedSetState({
                                                            country_code: this.state.country_code
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div className='w-full'>

                                                <InputTailwind
                                                    error={this.state.error_number}
                                                    label={t("label_2")}
                                                    type={"tel"}
                                                    value={this.state.number}
                                                    onChange={(value) => {
                                                        if (value.length > 0 && /[^0-9]/.test(value)) {
                                                            this.state.error_number = true
                                                        } else {
                                                            this.state.error_number = false
                                                        }
                                                        this.state.number = value
                                                        this.promisedSetState({
                                                            number: this.state.number
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {/* MOBILE OTP INPUT */}
                                {
                                    this.state.number_sent &&
                                    <div className={`flex flex-col`}>
                                        <label className={`text-xs font-medium text-gray-700 ${!this.state.error ? "flex" : "hidden"}`}>
                                            {t("label_3")}
                                        </label>
                                        {
                                            <div className={`${this.state.error && this.state.number_sent ? "flex items-center mb-1" : "hidden"}`}>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" aria-hidden="true" />
                                                <div className='text-red-600 text-xs'>
                                                    {t("error_1")}
                                                </div>
                                            </div>
                                        }
                                        <div className='flex justify-between space-x-2 w-full mt-1'>
                                            {
                                                Array.from({ length: 6 }).map((_, index) => (
                                                    <input
                                                        error={this.state.error}
                                                        id={"mobile-otp-digit" + index}
                                                        key={index}
                                                        className={`focus:ring-estatery-600 focus:border-purple-500 ${this.state.error ? "border-red-300" : "border-gray-300"} rounded-md h-12 w-10 block px-2 bg-custom-input border-1.5 focus:outline-none sm:text-sm text-center`}
                                                        value={this.state.otp[index]}
                                                        onChange={(e) => this.functions.handleOtpInput(e.target.value, index, true)}
                                                        onKeyDown={async (e) => { this.functions.handleKeyDown(e, index, true) }}
                                                        // onPaste={(e) => { this.functions.handlePaste(e) }}
                                                        maxLength={1}
                                                        type={"tel"}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }


                                <div className={"pt-6"}>
                                    <button
                                        onClick={() => {
                                            if (!this.state.number_sent) {
                                                this.functions.checkNumber();
                                            } else if (this.state.number_sent) {
                                                this.functions.verify();
                                            }
                                        }}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                    >
                                        {
                                            this.state.loading &&
                                            <div>
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        {
                                            !this.state.loading &&
                                            <div
                                                style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                            >
                                                {
                                                    !this.state.number_sent &&
                                                    <>
                                                        {t("button_1")}
                                                    </>
                                                }
                                                {
                                                    this.state.number_sent &&
                                                    <>
                                                        {t("button_2")}
                                                    </>
                                                }
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.whitelabel_settings && this.state.whitelabel_settings.background_image !== "" &&
                        <div className="hidden lg:block relative w-0 flex-1 bg-cover bg-center" style={{ backgroundImage: `url(${this.state.whitelabel_settings.background_image})` }}></div>
                    }
                    {
                        this.state.whitelabel_settings && this.state.whitelabel_settings.background_image === "" &&
                        <div className="hidden lg:block relative w-0 flex-1 overlay overlay--gradient bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80)' }}></div>
                    }
                </div>

                {/* DESKTOP SIZE */}

                <div className="hidden min-h-full lg:flex">
                    <div className="flex-1 flex flex-col justify-center lg:justify-center py-12 px-4 sm:px-6 lg:w-1/3 lg:flex-none lg:px-20 xl:px-24"
                    >

                        <div className="mx-auto w-full max-w-sm lg:w-96 rounded-lg shadow-lg px-6 py-8 bg-white lg:bg-transparent lg:shadow-none lg:rounded-none">
                            <div>
                                {
                                    this.state.logo !== "" &&
                                    <div className='hidden lg:absolute lg:block'
                                        style={{ top: 40, left: 40 }}
                                    >
                                        <img
                                            className="h-6 lg:h-8 w-auto"
                                            src={this.state.whitelabel_settings.logo ? this.state.whitelabel_settings.logo : null}
                                            alt="Company logo"
                                        />
                                    </div>
                                }
                                <div className='text-center lg:text-left'>
                                    <h2 className="mt-5 text-3xl font-extrabold text-gray-900">
                                        {t("text_1")}
                                    </h2>
                                    <p className="text-sm text-gray-600">
                                        {
                                            !this.state.number_sent &&
                                            <>
                                                {t("text_2")}
                                            </>
                                        }
                                        {
                                            this.state.number_sent &&
                                            <>
                                                {t("text_3")}
                                            </>
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="mt-6">

                                {/* PHONE NUMBER INPUT */}
                                {
                                    // ADD SMALL DROPDOWN COUNTRY PICKER WITH FLAGS
                                    !this.state.number_sent &&
                                    <>
                                        <div className='flex items-center w-full'>

                                            <div>
                                                <DropdownTailwind
                                                    error={this.state.error_country_code}
                                                    label={t("label_1")}
                                                    small={false}
                                                    searchInput={true}
                                                    selected={this.state.country_code ? this.state.country_code : { id: 0, name: "Select...", value: 0 }}
                                                    options={this.state.phone_codes.map((item) => {
                                                        return { name: `${item.iso} (${item.code})`, value: item.code, id: item.code };
                                                    })}
                                                    onChange={async (value) => {
                                                        if (this.state.country_code !== 0) {
                                                            this.state.error_country_code = false
                                                        }
                                                        this.state.country_code = value;
                                                        await this.promisedSetState({
                                                            country_code: this.state.country_code
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div className='w-full'>

                                                <InputTailwind
                                                    error={this.state.error_number}
                                                    label={t("label_2")}
                                                    type={"tel"}
                                                    value={this.state.number}
                                                    // phone_codes={true}
                                                    onChange={(value) => {
                                                        if (value.length > 0 && /[^0-9]/.test(value)) {
                                                            this.state.error_number = true
                                                        } else {
                                                            this.state.error_number = false
                                                        }
                                                        this.state.number = value
                                                        this.promisedSetState({
                                                            number: this.state.number
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>

                                }



                                {/* OTP INPUT */}
                                {
                                    this.state.number_sent &&
                                    <div className={`flex flex-col`}>
                                        <label className={`text-xs font-medium text-gray-700 ${!this.state.error ? "flex" : "hidden"}`}>
                                            {t("label_3")}
                                        </label>
                                        {
                                            <div className={`${this.state.error && this.state.number_sent ? "flex items-center mb-1" : "hidden"}`}>
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" aria-hidden="true" />
                                                <div className='text-red-600 text-xs'>
                                                    {t("error_1")}
                                                </div>
                                            </div>
                                        }
                                        <div className='flex justify-between space-x-2 w-full mt-1'>
                                            {
                                                Array.from({ length: 6 }).map((_, index) => (
                                                    <input
                                                        error={this.state.error}
                                                        id={"otp-digit" + index}
                                                        key={index}
                                                        className={`focus:ring-estatery-600 focus:border-purple-500 ${this.state.error ? "border-red-300" : "border-gray-300"} rounded-md h-12 w-12 block px-2 bg-custom-input border-1.5 focus:outline-none sm:text-sm text-center`}
                                                        value={this.state.otp[index]}
                                                        onChange={(e) => this.functions.handleOtpInput(e.target.value, index)}
                                                        onKeyDown={async (e) => { this.functions.handleKeyDown(e, index) }}
                                                        onPaste={(e) => { this.functions.handlePaste(e) }}
                                                        maxLength={1}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                <div className={"pt-6"}>
                                    <button
                                        onClick={() => {
                                            if (!this.state.number_sent) {
                                                this.functions.checkNumber();
                                            } else if (this.state.number_sent) {
                                                this.functions.verify();
                                            }
                                        }}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                    >
                                        {
                                            this.state.loading &&
                                            <div>
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        {
                                            !this.state.loading &&
                                            <div
                                                style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                            >
                                                {
                                                    !this.state.number_sent &&
                                                    <>
                                                        {t("button_1")}
                                                    </>
                                                }
                                                {
                                                    this.state.number_sent &&
                                                    <>
                                                        {t("button_2")}
                                                    </>
                                                }
                                            </div>
                                        }
                                    </button>

                                    {
                                        this.state.number_sent &&
                                        <button
                                            onClick={() => { this.functions.checkNumber() }}
                                            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                        >
                                            {
                                                this.state.loading &&
                                                <div>
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                            {
                                                !this.state.loading && this.state.number_sent &&
                                                <div style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                                    {t("retry")}
                                                </div>
                                            }
                                        </button>
                                    }


                                    <button
                                        onClick={() => { this.functions.tempCheckNumber() }}
                                        className='mt-4 bg-red-400 rounded px-2 py-1 text-white font-semibold'
                                    >
                                        TEMP LOGIN
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        this.state.whitelabel_settings && this.state.whitelabel_settings.background_image !== "" &&
                        <div className="hidden lg:block relative w-0 flex-1 bg-cover bg-center" style={{ backgroundImage: `url(${this.state.whitelabel_settings.background_image})` }}></div>
                    }
                    {
                        this.state.whitelabel_settings && this.state.whitelabel_settings.background_image === "" &&
                        <div className="hidden lg:block relative w-0 flex-1 overlay overlay--gradient bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80)' }}></div>
                    }

                </div>
            </>
        )
    }
}

export default Login;
