import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon, LockClosedIcon } from '@heroicons/react/outline'

class TextAreaTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            rightLabelText: "",
            error: false,
            disabled: false,
            locked: false
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            error: this.props.error,
            disabled: this.props.disabled,
            rightLabelText: this.props.rightLabelText,
            channel: this.props.channel,
            locked: this.props.locked,
            maxLength: this.props.maxLength ? this.props.maxLength : 1000000
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            error: nextProps.error,
            disabled: nextProps.disabled,
            rightLabelText: nextProps.rightLabelText,
            channel: nextProps.channel,
            locked: this.props.locked,
            maxLength: nextProps.maxLength ? nextProps.maxLength : 1000000
        })
    }

    render() {
        return (
            <div>
                <label className="text-xs flex flex-row font-medium text-gray-700 ">
                    {
                        this.state.channel &&
                        <div className="mr-1">
                            <div className={"bg-" + this.state.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>
                                {
                                    this.state.channel === 'google' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/google_icon.png')} />
                                }
                                {
                                    this.state.channel === 'facebook' &&
                                    <img className="w-1"
                                        alt=""
                                        src={require('../assets/images/facebook_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'linkedin' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/linkedin_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'twitter' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/twitter_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'tiktok' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/tiktok_icon.png')} />
                                }
                                {
                                    this.state.channel === 'snapchat' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/snapchat.svg')} />
                                }
                            </div>
                        </div>
                    }
                    <div className={this.state.error ? "text-red-600" : ""}>
                        {this.props.label}
                    </div>
                    {
                        this.props.rightLabel &&
                        <div onClick={() => {
                            if (this.props.onRightLabelClick) {
                                this.props.onRightLabelClick();
                            }
                        }} className={(this.props.rightLabelColor ? this.props.rightLabelColor : "hover:text-purple-500 text-gray-700") + " flex flex-1 justify-end text-xs cursor-pointer font-medium "}>
                            {this.state.rightLabelText}
                        </div>
                    }
                </label>
                <div className="mt-1 relative flex flex-row rounded-md">
                    <textarea
                        rows={this.props.rows ? this.props.rows : 4}
                        maxLength={this.state.maxLength}
                        disabled={this.state.disabled}
                        placeholder={this.props.placeholder}
                        className={(this.state.error ? " border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 " : " border-gray-300 ") + " bg-custom-input rounded-md block w-full border-1.5 pr-10 p-2 focus:outline-none sm:text-sm"}
                        value={this.state.value}
                        onChange={(event) => {
                            if (this.props.onChange && !this.state.disabled) {
                                this.props.onChange(event.target.value);
                            }
                        }}
                        onBlur={() => {
                            if (this.props.onBlur) {
                                this.props.onBlur();
                            }
                        }}
                        onKeyDown={(event) => {
                            try {
                                if (this.props.showKeyDown) {
                                    this.props.onKeyDown(event.keyCode);
                                }
                            } catch (e) { }
                        }}
                    />
                    {
                        this.state.locked &&
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <LockClosedIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    }
                </div>
                {
                    this.state.error &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {this.state.errorMessage}
                    </p>
                }
            </div>
        )
    }
}

export default TextAreaTailwind;
