import React, { Component, Fragment } from 'react';
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon, FolderIcon, PencilIcon, PlusCircleIcon, SortAscendingIcon, SortDescendingIcon, ThumbDownIcon, TrashIcon, ViewGridIcon } from '@heroicons/react/outline';
import SlideoutTailwind from '../../moduleFiles/slideoutTailwind';
import EditFacebookCampaign from '../../moduleFiles/editFacebookCampaign';
import EditFacebookAdset from '../../moduleFiles/editFacebookAdset';
import EditFacebookAd from '../../moduleFiles/editFacebookAd';
import moment from 'moment';
import WarningModalTailwind from '../../moduleFiles/warningModalTailwind';
import EditGoogleCampaign from '../../moduleFiles/editGoogleCampaign';
import CreateGoogleNegativeKeyword from '../../moduleFiles/createGoogleNegativeKeyword';
import CreateGoogleKeyword from '../../moduleFiles/createGoogleKeyword';
import EditGoogleAd from '../../moduleFiles/editGoogleAd';
import EditLinkedinCampaign from '../../moduleFiles/editLinkedinCampaign';
import EditLinkedinAdgroup from '../../moduleFiles/editLinkedinAdgroup';
import InputTailwind from '../../moduleFiles/inputTailwind';

class Optimize extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_partial: null,
            stats: {
                google: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total search terms",
                        value: "-",
                        key: "search_terms"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                    {
                        title: "Total keywords",
                        value: "-",
                        key: "keywords"
                    },
                    {
                        title: "Total negative keywords",
                        value: "-",
                        key: "negative_keywords"
                    }
                ],
                facebook: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ],
                linkedin: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ]
            },
            tabs: {
                google: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Search terms", value: "search_terms", new: false, default_sort: "text" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "campaign_name" },
                    { name: "Keywords", value: "keywords", new: true, default_sort: "keyword_text" },
                    { name: "Negative keywords", value: "negative_keywords", new: true, new: true, default_sort: "keyword_text" }
                ],
                facebook: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ],
                linkedin: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ]
            },
            columns: {
                facebook: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "start date", value: "start_time" },
                        { name: "end date", value: "stop_time" },
                        { name: "budget", value: "daily_budget" }
                    ],
                    adgroups: [
                        { name: "adset", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "start date", value: "start_time" },
                        { name: "end date", value: "stop_time" },
                        { name: "budget", value: "daily_budget" }
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "adset", value: "adset_name" },
                    ]
                },
                linkedin: {
                    campaigns: [
                        { name: "campaign", value: "name", type: "char" },
                        { name: "status", value: "status", min_width: "150px", type: "char" },
                        { name: "start date", value: "start_date", min_width: "150px", type: "date" },
                        { name: "spent", value: "spent", min_width: "150px", type: "number" },
                        { name: "impressions", value: "impressions", min_width: "150px", type: "number" },
                        { name: "clicks", value: "clicks", min_width: "150px", type: "number" },
                        { name: "Average CTR", value: "ctr", min_width: "150px", type: "number" },
                        { name: "Bid", value: "bid", min_width: "150px", type: "number" },
                        { name: "Average CPM", value: "cpm", min_width: "150px", type: "number" },
                        { name: "Average CPC", value: "cpc", min_width: "150px", type: "number" },
                        { name: "Conversions", value: "conversions", min_width: "150px", type: "number" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px", type: "number" },
                        { name: "Leads", value: "leads", min_width: "150px", type: "number" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px", type: "number" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px", type: "number" },
                        //{ name: "end date", value: "end_date" },
                        //{ name: "daily budget", value: "daily_budget" },
                        //{ name: "total budget", value: "total_budget" }
                    ],
                    adgroups: [
                        { name: "adset", value: "name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "spent", value: "spent", min_width: "150px" },
                        { name: "Key Results", value: "key_results", min_width: "150px" },
                        { name: "Cost Per Result", value: "cost_per_result", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "Average CTR", value: "ctr", min_width: "150px" },
                        { name: "Bid", value: "bid", min_width: "150px" },
                        { name: "Average CPM", value: "cpm", min_width: "150px" },
                        { name: "Average CPC", value: "cpc", min_width: "150px" },
                        { name: "Conversions", value: "conversions", min_width: "150px" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "Leads", value: "leads", min_width: "150px" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px" },
                        //{ name: "start date", value: "start_date" },
                        //{ name: "end date", value: "end_date" },
                        //{ name: "daily budget", value: "daily_budget" },
                        //{ name: "total budget", value: "total_budget" }
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adset", value: "adgroup_name", min_width: "150px" },
                        { name: "Key Results", value: "key_results", min_width: "150px" },
                        { name: "Cost Per Result", value: "cost_per_result", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "Average CTR", value: "ctr", min_width: "150px" },
                        { name: "Bid", value: "bid", min_width: "150px" },
                        { name: "Average CPM", value: "cpm", min_width: "150px" },
                        { name: "Average CPC", value: "cpc", min_width: "150px" },
                        { name: "Conversions", value: "conversions", min_width: "150px" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "Leads", value: "leads", min_width: "150px" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px" },
                    ]
                },
                google: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        { name: "budget", value: "budget_value", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "serving status", value: "serving_status", min_width: "150px" },
                        { name: "campaign type", value: "advertising_type", min_width: "150px" },
                        { name: "optimisation score", value: "optimisation_score", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                        { name: "bid strategty type", value: "strategy_type", min_width: "150px" },
                        //{ name: "start date", value: "start_date" },
                        //{ name: "end date", value: "end_date" },

                    ],
                    adgroups: [
                        { name: "adgroup", value: "name" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "Adgroup type", value: "adgroup_type", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "ad strength", value: "ad_strength", min_width: "150px" },
                        { name: "ad type", value: "advertising_type", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    keywords: [
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "approval status", value: "approval_status", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    negative_keywords: [
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "level", value: "level", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                    ],
                    search_terms: [
                        { name: "search term", value: "text", min_width: "150px" },
                        { name: "added/excluded", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ]
                }
            },
            client: { id: 0, name: "Click here ..." },
            channel: { id: 0, name: "Click here ..." },
            campaign: { id: 0, name: "Click here ..." },
            campaigns: {},
            overview: null,
            tab: { name: "Campaigns", value: "campaigns" },
            page_limit: 10,
            page: 1,
            edit: false,
            create_new: false,
            selected: {},
            sort: "clicks",
            order: "ascending"
        }
    };

    componentDidMount() {
        this.functions.clients();
    }

    functions = {
        clients: async () => {
            await this.promisedSetState({ loading_clients: true });
            try {
                let response = await this.calls.clients();
                if (response) {
                    await this.promisedSetState({ clients: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_clients: false });
        },
        campaigns: async () => {
            await this.promisedSetState({ loading_campaigns: true });
            try {
                let response = await this.calls.clientCampaigns();
                if (response) {
                    await this.promisedSetState({ campaigns: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_campaigns: false });
        },
        overview: async () => {
            await this.promisedSetState({ loading_overview: true, overview: null });
            try {
                let functions = null;
                if (this.state.channel.value == "google") {
                    functions = "&functions=campaigns,adgroups,ads,keywords,negativeadgroupkeywords,negativecampaignkeywords,locations,budget"
                }
                let response = await this.calls.campaignOverview(functions);
                if (response) {
                    await this.promisedSetState({ overview: response.data });
                    if (this.state.channel.value == "google") {
                        this.functions.overviewPartial("&functions=searchterms", "search_terms");
                    }
                }
            } catch (error) { }
            await this.promisedSetState({ loading_overview: false });
        },
        overviewPartial: async (functions, tab_value) => {
            await this.promisedSetState({ loading_partial: tab_value ? tab_value : this.state.tab.value });
            try {
                let response = await this.calls.campaignOverview(functions);
                if (response) {
                    this.state.overview.channels[this.state.channel.value][(tab_value ? tab_value : this.state.tab.value)] = response.data.channels[this.state.channel.value][(tab_value ? tab_value : this.state.tab.value)];
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_partial: false });
        },
        remove: async () => {
            if (this.state.channel.value == "facebook") {
                this.functions.removeFacebook();
            } else if (this.state.channel.value == "google") {
                this.functions.removeGoogle();
            } else if (this.state.channel.value == "linkedin") {
                this.functions.removeGoogle();
            }
        },
        removeGoogle: async () => {
            if (this.state.tab.value == "campaigns") {
                let property_id = this.state.remove.resource_name;
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    response = await this.calls.removeGoogleCampaign({
                        operations: [
                            { "remove": property_id }
                        ]
                    });
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } else if (this.state.tab.value == "negative_keywords" || this.state.tab.value == "keywords") {
                let property_id = this.state.remove.resource_name;
                let property_type = this.state.tab.value == "negative_keywords" ? this.state.remove.level : "adgroup";
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    if (property_type == "campaign") {
                        response = await this.calls.removeGoogleCampaignCriteria({
                            operations: [
                                { "remove": property_id }
                            ]
                        });
                    } else if (property_type == "adgroup") {
                        response = await this.calls.removeGoogleAdgroupCriteria({
                            operations: [
                                { "remove": property_id }
                            ]
                        });
                    }
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } else if (this.state.tab.value == "ads") {
                let property_id = this.state.remove.adgroup_ad_resource_name;
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.adgroup_ad_resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    response = await this.calls.removeGoogleAd({
                        operations: [
                            { "remove": property_id }
                        ]
                    });
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.adgroup_ad_resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.adgroup_ad_resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            }
        },
        removeFacebook: async () => {
            let property_id = this.state.remove.id;
            this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                if (item.id == property_id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
            try {
                let response = await this.calls.removeFacebook(property_id);
                if (response && response.data) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                        return item.id !== property_id
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) {
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.id == property_id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview });
            }
        },
        removeLinkedin: async () => {
            let property_id = this.state.remove.id;
            this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                if (item.id == property_id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
            try {
                let response = await this.calls.removeFacebook(property_id);
                if (response && response.data) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                        return item.id !== property_id
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) {
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.id == property_id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview });
            }
        }
    };

    open = {
        facebook: (level, item) => {
            if (level === "campaigns") {
                window.open("https://business.facebook.com/adsmanager/manage/campaigns/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.id, "_blank");
            } else if (level === "adgroups") {
                window.open("https://business.facebook.com/adsmanager/manage/adsets/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.id, "_blank");
            } else if (level === "ads") {
                window.open("https://business.facebook.com/adsmanager/manage/ads/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.adset_id + "&selected_ad_ids=" + item.id, "_blank");
            }
        },
        linkedin: (level, item) => {
            if (level === "campaigns") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaign-groups/" + item.id, "_blank");
            } else if (level === "adgroups") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.id + "/details", "_blank");
            } else if (level === "ads") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.adgroup + "/creatives/" + item.id + "?action=edit", "_blank");
            }
        }
    };

    calls = {
        clients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients";
            return apiRegister.call(options, url);
        },
        clientCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientCampaigns?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        campaignOverview: (functions) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/campaignOverview?client=" + this.state.client.id + "&channel=" + this.state.channel.value + "&campaign=" + this.state.campaign.id + (functions ? functions : "");
            return apiRegister.call(options, url);
        },
        removeFacebook: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/facebook/remove?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        removeGoogleCampaignCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaignCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleAdgroupCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAdgroupCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaign?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        creativeFacebook: (item) => {
            let creative = null;
            try {
                if (item.creative.object_story_spec.video_data) {
                    creative = item.creative.object_story_spec.video_data.image_url;
                }
            } catch (eror) { }
            try {
                if (item.creative.object_story_spec.link_data.picture) {
                    creative = item.creative.object_story_spec.link_data.picture;
                }
            } catch (eror) { }
            try {
                if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
                    creative = item.creative.object_story_spec.link_data.child_attachments;
                }
            } catch (eror) { }
            return creative;
        },
        typeFacebook: (item) => {
            let type = null;
            try {
                if (item.creative.object_story_spec.video_data) {
                    type = "video";
                }
            } catch (eror) { }
            try {
                if (item.creative.object_story_spec.link_data.picture || item.creative.object_story_spec.link_data.image_hash) {
                    type = "image";
                }
            } catch (eror) { }
            try {
                if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
                    type = "carousel";
                }
            } catch (eror) { }
            return type;
        },
        sort: (list) => {

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : 0) : (x[this.state.sort] ? x[this.state.sort] : 0);
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : 0) : (y[this.state.sort] ? y[this.state.sort] : 0);
                    if (this.state.order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : "") : (x[this.state.sort] ? x[this.state.sort] : "");
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : "") : (y[this.state.sort] ? y[this.state.sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (this.state.order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : new Date()) : (x[this.state.sort] ? x[this.state.sort] : new Date());
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : new Date()) : (y[this.state.sort] ? y[this.state.sort] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (this.state.order == 'descending') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (this.state.sort === "name" || this.state.sort === "status") {
                return sortCharacter(list);
            } else if (this.state.sort === "start_date" || this.state.sort === "end_date") {
                return sortDate(list);
            } else {
                return sortNumber(list);
            }

        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        let row_campaign = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex flex-row">
                        <div className="h-10 w-10 rounded-full flex justify-center items-center bg-gray-100">
                            <FolderIcon className="h-5 w-5 text-gray-700" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.campaign_name ? item.campaign_name : item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">CAMPAIGN #{item.campaign_id ? item.campaign_id : item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_adgroup = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex flex-row">
                        <div className="h-10 w-10 rounded-full flex justify-center items-center bg-gray-100">
                            <ViewGridIcon className="h-5 w-5 text-gray-700" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.adset_name ? item.adset_name : item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">ADSET #{item.adset_id ? item.adset_id : item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_facebook = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex items-center flex-row">
                        <div>
                            {
                                this.renders.typeFacebook(item) == "carousel" &&
                                <div className="flex -space-x-4 relative z-0 overflow-hidden">
                                    {
                                        this.renders.creativeFacebook(item).map((item) => {
                                            return (
                                                <img
                                                    className="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                                                    src={item.picture}
                                                    alt=""
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.renders.typeFacebook(item) == "image" &&
                                <div className="flex items-center justify-center h-10 w-10 rounded-full overflow-hidden border bg-gray-100">
                                    <img
                                        className="w-full"
                                        src={this.renders.creativeFacebook(item)}
                                        alt=""
                                    />
                                </div>
                            }
                            {
                                this.renders.typeFacebook(item) == "video" &&
                                <div className="flex items-center justify-center h-10 w-10 rounded-full overflow-hidden border bg-gray-100">
                                    <video poster={this.renders.creativeFacebook(item)} controls={false} loop={true} autoPlay={true} muted={true} style={{ width: '100%', height: '100%' }}>
                                        <source src={""} type="video/mp4" />
                                    </video>
                                </div>
                            }
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700"><span className="uppercase">{this.renders.typeFacebook(item)}</span> #{item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_google = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxs text-purple-500 flex-wrap">
                            {item.headlines.filter((item, index) => { return index < 3 }).map((item) => { return item.text }).join(" | ") + (item.headlines.length > 3 ? (" +" + (item.headlines.length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxs truncate">
                                {item.descriptions.map((item) => { return item.text }).join(". ")}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_linkedin = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>

                </td>
            )
        }

        let row_default = (value, property, last, min_width) => {
            if (property == "start_time" || property == "stop_time") {
                value = value ? moment(value).format("YYYY-MM-DD") : "No date";
            } else if (property == "effective_status") {
                value = value.replace("_", " ");
            }
            return (
                <td style={min_width ? { minWidth: min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }} className={(this.state.sort === property ? "bg-gray-100 " : "") + (!last ? " border-r-1.5" : "") + " px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                    {value}
                </td>
            )
        }

        return (
            <div className="pt-8 pb-8">

                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove_name != "123456"}
                    showInput={true}
                    inputTitle={"Type 123456 to remove"}
                    inputError={(this.state.remove_name != "123456") ? "Name is not matching" : false}
                    onClose={(value) => {
                        this.setState({
                            remove: null,
                            remove_name: ""
                        })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove_name == "123456") {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {
                    this.state.channel.id !== 0 && this.state.tab.id !== 0 && this.state.overview &&
                    <SlideoutTailwind
                        title={"Update"}
                        large={(this.state.channel.value === "facebook" || this.state.channel.value === "linkedin") && (this.state.tab.value === "ads")}
                        submitButtonText={"Save"}
                        secondaryButton={this.state.channel.value === "facebook" && this.state.tab.value === "ads"}
                        secondaryButtonText={"Create new"}
                        loader={this.state.edit_loader}
                        loaderSecondary={this.state.duplicate_loader}
                        open={(this.state.selected.id || this.state.create_new) ? true : false}
                        error={this.state.slideoutError}
                        onError={(value) => {
                            this.setState({
                                slideoutError: value
                            })
                        }}
                        success={this.state.slideoutSuccess}
                        onSuccess={(value) => {
                            this.setState({
                                slideoutSuccess: value
                            })
                        }}
                        onCancel={() => {
                            this.setState({
                                selected: {},
                                create_new: null,
                                selected_search_term: null
                            })
                        }}
                        onSubmit={() => {
                            if (this.state.channel.value === "facebook") {
                                if (this.state.tab.value === "campaigns") {
                                    this.refs.EditFacebookCampaign.functions.update();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.refs.EditFacebookAdset.functions.update();
                                } else if (this.state.tab.value === "ads") {
                                    this.refs.EditFacebookAd.functions.update();
                                }
                            } else if (this.state.channel.value == "google") {
                                if (this.state.tab.value === "campaigns") {
                                    this.refs.EditGoogleCampaign.functions.update();
                                } else if ((this.state.tab.value === "negative_keywords" || this.state.tab.value === "search_terms") && this.state.create_new) {
                                    this.refs.CreateGoogleNegativeKeyword.functions.create();
                                } else if (this.state.tab.value === "keywords" && this.state.create_new) {
                                    this.refs.CreateGoogleKeyword.functions.create();
                                } else if (this.state.tab.value === "ads") {
                                    this.refs.EditGoogleAd.functions.update();
                                }
                            } else if (this.state.channel.value === "linkedin") {
                                if (this.state.tab.value == "campaigns") {
                                    this.refs.EditLinkedinCampaign.functions.update();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.refs.EditLinkedinAdgroup.functions.update();
                                }
                            }
                        }}
                        onSecondarySubmit={() => {
                            if (this.state.channel.value === "facebook" && this.state.tab.value === "ads") {
                                this.refs.EditFacebookAd.functions.update(true);
                            }
                        }}
                    >

                        {/* LINKEDIN */}
                        {
                            this.state.channel.value === "linkedin" && this.state.tab.value === "campaigns" &&
                            <EditLinkedinCampaign
                                ref="EditLinkedinCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }
                        {
                            this.state.channel.value === "linkedin" && this.state.tab.value === "adgroups" &&
                            <EditLinkedinAdgroup
                                ref="EditLinkedinAdgroup"
                                client={this.state.client}
                                adgroup={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }

                        {/* FACEBOOK */}
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "campaigns" &&
                            <EditFacebookCampaign
                                ref="EditFacebookCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "adgroups" &&
                            <EditFacebookAdset
                                ref="EditFacebookAdset"
                                client={this.state.client}
                                adset={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "ads" &&
                            <EditFacebookAd
                                ref="EditFacebookAd"
                                client={this.state.client}
                                ad={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onLoaderSecondary={(value) => { this.setState({ duplicate_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                                onAdd={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value].push(value);
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }

                        {/* GOOGLE */}
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "campaigns" &&
                            <EditGoogleCampaign
                                ref="EditGoogleCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "ads" &&
                            <EditGoogleAd
                                ref="EditGoogleAd"
                                client={this.state.client}
                                ad={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && (this.state.tab.value == "negative_keywords" || this.state.tab.value == "search_terms") &&
                            <CreateGoogleNegativeKeyword
                                ref="CreateGoogleNegativeKeyword"
                                client={this.state.client}
                                searchTerm={this.state.selected_search_term}
                                campaigns={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].campaigns)) ? this.state.overview.channels[this.state.channel.value].campaigns : []}
                                adgroups={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].adgroups)) ? this.state.overview.channels[this.state.channel.value].adgroups : []}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onCreate={async (value) => {
                                    await this.promisedSetState({
                                        selected: {},
                                        selected_search_term: null,
                                        create_new: false,
                                        overview: this.state.overview
                                    });
                                    if (this.state.tab.value == "negative_keywords") {
                                        this.functions.overviewPartial("&functions=negativeadgroupkeywords,negativecampaignkeywords");
                                    }
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "keywords" &&
                            <CreateGoogleKeyword
                                ref="CreateGoogleKeyword"
                                client={this.state.client}
                                adgroups={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].adgroups)) ? this.state.overview.channels[this.state.channel.value].adgroups : []}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onCreate={async (value) => {
                                    await this.promisedSetState({
                                        selected: {},
                                        create_new: false,
                                        overview: this.state.overview
                                    });
                                    this.functions.overviewPartial("&functions=keywords");
                                }}
                            />
                        }
                    </SlideoutTailwind>
                }

                <div className='rounded-md shadow bg-white'>

                    <div className={(this.state.channel &&
                        this.state.channel.value &&
                        this.state.stats[this.state.channel.value] &&
                        !this.state.loading_overview &&
                        this.state.overview &&
                        this.state.overview.channels &&
                        this.state.overview.channels[this.state.channel.value] ? "" : "rounded-b-md") + " bg-white p-6 rounded-t-md"}>
                        <div className="">
                            <div className="text-lg leading-6 font-semibold text-gray-900">
                                Optimize
                            </div>
                            <p className="mt-1 text-sm text-gray-500">
                                Edit and optimize your campaigns, adgroups and ads.
                            </p>
                        </div>
                        <div className="mt-5 grid grid-cols-3 gap-4">
                            <div className="col-span-3 sm:col-span-1">
                                <DropdownTailwind
                                    label={"Select client"}
                                    searchInput={true}
                                    selected={this.state.client}
                                    loader={this.state.loading_clients}
                                    options={Array.isArray(this.state.clients) ? this.state.clients.map((item) => { item.image = item.logo; return item }) : []}
                                    onChange={async (client) => {
                                        await this.promisedSetState({
                                            client: client,
                                            channel: { id: 0, name: "Click here ..." },
                                            campaign: { id: 0, name: "Click here ..." },
                                            overview: null
                                        });
                                        this.functions.campaigns();
                                    }}
                                />
                            </div>
                            <div className="col-span-3 sm:col-span-1">
                                <DropdownTailwind
                                    label={"Select channel"}
                                    searchInput={true}
                                    selected={this.state.channel}
                                    options={[{ id: 1, name: "Facebook", value: "facebook" }, { id: 2, name: "Google", value: "google" }, { id: 3, name: "Linkedin", value: "linkedin" }].filter((item) => {
                                        return this.state.campaigns && Array.isArray(this.state.campaigns[item.value]) && this.state.campaigns[item.value].length > 0
                                    })}
                                    disabled={this.state.client.id == 0}
                                    loader={this.state.loading_campaigns}
                                    onChange={async (channel) => {
                                        await this.promisedSetState({
                                            channel: channel,
                                            campaign: { id: 0, name: "Click here ..." },
                                            overview: null
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3 sm:col-span-1">
                                <DropdownTailwind
                                    label={"Select campaign"}
                                    selected={this.state.campaign}
                                    searchInput={true}
                                    loader={this.state.loading_overview}
                                    disabled={this.state.client.id == 0 || this.state.channel.id == 0}
                                    options={(this.state.campaigns && Array.isArray(this.state.campaigns[this.state.channel.value]) ? this.state.campaigns[this.state.channel.value] : [])}
                                    onChange={async (campaign) => {
                                        await this.promisedSetState({
                                            campaign: campaign,
                                            tab: { name: "Campaigns", value: "campaigns" },
                                            overview: null
                                        });
                                        this.functions.overview();
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.channel &&
                        this.state.channel.value &&
                        this.state.stats[this.state.channel.value] &&
                        !this.state.loading_overview &&
                        this.state.overview &&
                        this.state.overview.channels &&
                        this.state.overview.channels[this.state.channel.value] &&
                        <div className="flex flex-row mt-5">
                            <div className="border-b-1.5 w-6"></div>
                            {
                                this.state.tabs[this.state.channel.value].map((tab, index) => {
                                    return (
                                        <Fragment>
                                            <div onClick={() => {
                                                this.setState({
                                                    tab: tab,
                                                    sort: tab.default_sort
                                                })
                                            }}
                                                style={tab.value == this.state.tab.value ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                className={(tab.value == this.state.tab.value ? "bg-gray-50" : "") + " border-1.5 py-3 max-w-1/5 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                <div className="flex flex-1 truncate">
                                                    <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                        {tab.name}
                                                    </div>
                                                </div>
                                                {
                                                    <div className={(tab.value == this.state.tab.value ? "bg-gray-300" : "text-gray-500 bg-gray-100") + " rounded-full text-sm font-medium px-3 py-1 "}>
                                                        {Array.isArray(this.state.overview.channels[this.state.channel.value][tab.value]) ? this.state.overview.channels[this.state.channel.value][tab.value].length : tab.value}
                                                    </div>
                                                }
                                            </div>
                                            <div className="w-2 border-b-1.5"></div>
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                <div className="border-b-1.5 flex flex-1">
                                    <div className="flex flex-1 flex-row pr-3">
                                        <div className="flex flex-1"></div>
                                        {
                                            this.state.tab.new &&
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        create_new: true
                                                    })
                                                }}
                                                className={"text-purple-500 items-center text-sm flex flex-row font-medium rounded-md  focus:outline-none py-2 px-4 hover:text-purple-700"}
                                            >
                                                <PlusCircleIcon className={"h-6 w-6 -ml-1 mr-2 "} />
                                                New
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {
                        this.state.channel &&
                        this.state.channel.value &&
                        this.state.stats[this.state.channel.value] &&
                        !this.state.loading_overview &&
                        this.state.overview &&
                        this.state.overview.channels &&
                        this.state.overview.channels[this.state.channel.value] &&
                        <div className="bg-gray-50 p-6">
                            <InputTailwind
                                label={"Search name"}
                                value={this.state.search}
                                onChange={(value) => {
                                    this.setState({
                                        search: value
                                    })
                                }}
                            />
                        </div>
                    }

                    {
                        false &&
                        this.state.channel &&
                        this.state.channel.value &&
                        this.state.stats[this.state.channel.value] &&
                        !this.state.loading_overview &&
                        this.state.overview &&
                        this.state.overview.channels &&
                        this.state.overview.channels[this.state.channel.value] &&
                        <div className="p-6 pt-0">
                            <dl className={"grid border rounded-md grid-cols-1 bg-white overflow-hidden divide-y divide-gray-300 md:divide-y-0 md:divide-x md:grid-cols-" + this.state.stats[this.state.channel.value].length}>
                                {this.state.stats[this.state.channel.value].map((item) => (
                                    <div key={item.key} className="p-6 overflow-hidden">
                                        <dt className="text-sm font-medium text-gray-900 truncate">{item.title}</dt>
                                        {
                                            this.state.loading_partial !== item.key &&
                                            <dd className="mt-1 text-3xl font-semibold text-purple-700">{Array.isArray(this.state.overview.channels[this.state.channel.value][item.key]) ? this.state.overview.channels[this.state.channel.value][item.key].length : item.value}</dd>
                                        }
                                        {
                                            this.state.loading_partial === item.key &&
                                            <dd className="mt-1 text-3xl font-semibold text-purple-700">...</dd>
                                        }
                                    </div>
                                ))}
                            </dl>
                        </div>
                    }
                    {
                        !this.state.loading_overview &&
                        this.state.overview &&
                        <div className="rounded-b-md">

                            {
                                false &&
                                <div className="px-4 py-5 sm:px-6">
                                    <div className="flex">
                                        <div className="flex flex-1">
                                            <div className="sm:hidden w-full">
                                                <label htmlFor="tabs" className="sr-only">
                                                    Select a tab
                                                </label>
                                                <select
                                                    id="tabs"
                                                    name="tabs"
                                                    value={this.state.tab}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            tab: event.target.value
                                                        })
                                                    }}
                                                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                >
                                                    {this.state.tabs[this.state.channel.value].map((tab) => (
                                                        <option key={tab.name}>{tab.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="hidden sm:block">
                                                <nav className="flex space-x-4" aria-label="Tabs">
                                                    {this.state.tabs[this.state.channel.value].map((tab) => (
                                                        <div
                                                            onClick={() => {
                                                                this.setState({
                                                                    tab: tab,
                                                                    sort: tab.default_sort
                                                                })
                                                            }}
                                                            key={tab.name}
                                                            className={cn(
                                                                tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-600 hover:text-purple-500',
                                                                'px-3 h-10 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex justify-center items-center'
                                                            )}
                                                            aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                                        >
                                                            {tab.name}
                                                        </div>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="flex justify-items-end">
                                            <button
                                                //disabled={this.state.loading_partial}
                                                onClick={() => {
                                                    this.setState({
                                                        create_new: true
                                                    })
                                                }}
                                                type="button"
                                                className={(this.state.tab.new ? "" : "hidden") + " inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-semibold rounded-md shadow-sm text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                            >
                                                Create new
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }



                            <div className="overflow-x-scroll relative">
                                {
                                    this.state.loading_partial == this.state.tab.value &&
                                    <div className="bg-white z-50 border-t bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item, index) => {
                                        return (index >= this.state.page * this.state.page_limit - this.state.page_limit) && (index < this.state.page * this.state.page_limit)
                                    }).length < 1 && !this.state.loading_overview &&
                                    <div className="min-w-full h-48 divide-y divide-estatery-400 border-t flex justify-center items-center">
                                        {
                                            this.state.loading_partial !== this.state.tab.value &&
                                            <div className="text-sm font-medium text-gray-900">
                                                No {this.state.tab.name} available
                                            </div>
                                        }
                                        {
                                            this.state.loading_partial == this.state.tab.value &&
                                            <div className="text-sm font-medium text-gray-900">
                                                Loading {this.state.tab.name}
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item, index) => {
                                        return (index >= this.state.page * this.state.page_limit - this.state.page_limit) && (index < this.state.page * this.state.page_limit)
                                    }).length > 0 &&
                                    <table className="min-w-full divide-y-1.5 divide-gray-300 border-t-1.5">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ whiteSpace: "nowrap", width: "240px" }} scope="col" className="relative px-6 py-3 whitespace-nowrap border-r-1.5">
                                                    <span className="sr-only">Options</span>
                                                </th>
                                                {this.state.columns[this.state.channel.value][this.state.tab.value].map((item, index) => {
                                                    let last = index == (this.state.columns[this.state.channel.value][this.state.tab.value] - 1);
                                                    return (
                                                        <th
                                                            onClick={() => {
                                                                this.setState({
                                                                    sort: item.value,
                                                                    order: this.state.order == 'ascending' ? 'descending' : 'ascending'
                                                                })
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(this.state.sort === item.value ? "bg-gray-100 " : "") + (!last ? "border-r-1.5 " : "") + "px-6 py-3 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.order == 'ascending' && this.state.sort == item.value ? "text-gray-900" : "opacity-25") + " h-4 w-4 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.order == 'descending' && this.state.sort == item.value ? "text-gray-900" : "opacity-25") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y-1.5 divide-gray-200">
                                            {
                                                this.renders.sort(this.state.overview.channels[this.state.channel.value][this.state.tab.value]).filter((item, index) => {
                                                    return ((index >= ((this.state.page * this.state.page_limit) - this.state.page_limit)) && (index < (this.state.page * this.state.page_limit)));
                                                }).map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td style={{ whiteSpace: "nowrap", width: "260px" }} className={"px-4 sm:px-6 py-4 flex items-center flex-row border-r-1.5"}>
                                                                <button
                                                                    onClick={() => {
                                                                        if (this.state.channel.value == "google" && this.state.tab.value == "search_terms") {
                                                                            this.setState({
                                                                                create_new: true,
                                                                                selected_search_term: item
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                remove: item
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="button"
                                                                    className="relative overflow-hidden mr-2 text-white h-10 px-2 flex justify-center items-center rounded-md bg-red-500 text-sm font-medium hover:bg-red-600 focus:z-10 focus:outline-none focus:ring-1"
                                                                >
                                                                    {
                                                                        this.state.tab.value !== "search_terms" &&
                                                                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                                                    }
                                                                    {
                                                                        this.state.tab.value !== "search_terms" &&
                                                                        <span className="ml-2 text-sm mr-1">Remove</span>
                                                                    }
                                                                    {
                                                                        this.state.tab.value === "search_terms" &&
                                                                        <ThumbDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                    }
                                                                    {
                                                                        this.state.tab.value === "search_terms" &&
                                                                        <span className="ml-2 text-sm mr-1">Exclude</span>
                                                                    }
                                                                    {
                                                                        item.loading &&
                                                                        <div className="w-full h-full absolute bg-red-100 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button
                                                                    disabled={item.loading || (this.state.channel.value !== "facebook" && this.state.channel.value !== "linkedin")}
                                                                    onClick={() => {
                                                                        if (!item.loading && (this.state.channel.value == "facebook" || this.state.channel.value == "linkedin")) {
                                                                            this.open[this.state.channel.value](this.state.tab.value, item);
                                                                        }
                                                                    }}
                                                                    type="button"
                                                                    className={((item.loading || (this.state.channel.value !== "facebook" && this.state.channel.value !== "linkedin")) ? "opacity-25 cursor-not-allowed" : "") + " relative mr-2 text-purple-500 h-10 w-10 flex justify-center items-center rounded-md bg-purple-100 text-sm font-medium hover:bg-purple-200 hover:bg-opacity-50 focus:z-10 focus:outline-none focus:ring-1"}
                                                                >
                                                                    <ExternalLinkIcon className="h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                                <button
                                                                    disabled={item.loading || (this.state.channel.value == "google" && this.state.tab.value !== "campaigns" && this.state.tab.value !== "ads")}
                                                                    onClick={() => {
                                                                        if (!(item.loading || (this.state.channel.value == "google" && this.state.tab.value !== "campaigns" && this.state.tab.value !== "ads"))) {
                                                                            this.setState({
                                                                                slideoutError: false,
                                                                                slideoutSuccess: false,
                                                                                selected: item
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="button"
                                                                    className={((item.loading || (this.state.channel.value == "google" && this.state.tab.value !== "campaigns" && this.state.tab.value !== "ads")) ? "opacity-25 cursor-not-allowed" : "") + " relative mr-2 text-purple-500 h-10 w-10 flex justify-center items-center rounded-md bg-purple-100 text-sm font-medium hover:bg-purple-200 hover:bg-opacity-50 focus:z-10 focus:outline-none focus:ring-1"}
                                                                >
                                                                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                            </td>
                                                            {this.state.columns[this.state.channel.value][this.state.tab.value].map((column, index) => {

                                                                let last = index === (this.state.columns[this.state.channel.value][this.state.tab.value].length - 1);

                                                                if (column.value === "name" && this.state.tab.value === "campaigns") {
                                                                    return row_campaign(item)
                                                                } else if (column.value === "name" && this.state.tab.value === "adgroups") {
                                                                    return row_adgroup(item)
                                                                } else if (column.value === "name" && this.state.tab.value === "ads" && this.state.channel.value === "facebook") {
                                                                    return row_ad_facebook(item)
                                                                } else if (column.value === "name" && this.state.tab.value === "ads" && this.state.channel.value === "google") {
                                                                    return row_ad_google(item)
                                                                } else if (column.value === "name" && this.state.tab.value === "ads" && this.state.channel.value === "linkedin") {
                                                                    return row_ad_linkedin(item)
                                                                } else {
                                                                    if ("row_values" in item) {
                                                                        return row_default(item["row_values"][column.value], column.value, last, column.min_width)
                                                                    } else {
                                                                        return row_default(item[column.value], column.value, last, column.min_width)
                                                                    }
                                                                }

                                                            })}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                            <nav
                                className="bg-gray-50 p-6 flex rounded-b-md items-center justify-between border-t border-gray-300"
                                aria-label="Pagination"
                            >
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{this.state.page * this.state.page_limit - this.state.page_limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.page_limit) > this.state.overview.channels[this.state.channel.value][this.state.tab.value].length) ? this.state.overview.channels[this.state.channel.value][this.state.tab.value].length : this.state.page * this.state.page_limit}</span> of{' '}
                                        <span className="font-medium">{this.state.overview.channels[this.state.channel.value][this.state.tab.value].length}</span> results
                                    </p>
                                </div>
                                <div className="flex-1 flex justify-between sm:justify-end">
                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                        <div>
                                            <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                Page limit: {this.state.page_limit}
                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {
                                                        [10, 20, 30, 50, 100, 500].map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    page_limit: item
                                                                                })
                                                                            }}
                                                                            className={cn(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                this.state.page_limit == item ? "text-purple-500" : ""
                                                                            )}
                                                                        >
                                                                            {item}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <div
                                        onClick={() => {
                                            this.setState({
                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                            });
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-semibold rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Previous
                                    </div>
                                    <div
                                        onClick={() => {
                                            if ((((this.state.page) * this.state.page_limit) <= this.state.overview.channels[this.state.channel.value][this.state.tab.value].length)) {
                                                this.setState({
                                                    page: this.state.page + 1
                                                });
                                            }
                                        }}
                                        className={((((this.state.page) * this.state.page_limit) > this.state.overview.channels[this.state.channel.value][this.state.tab.value].length) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-semibold rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Next
                                    </div>
                                </div>
                            </nav>
                        </div>
                    }

                </div>

            </div>
        )
    }
}

export default Optimize;
