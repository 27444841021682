import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
        facebook_version: 'v8.0'
    };

    static getAccount(token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/me?fields=id%2Cname&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getBusinesses(token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/me/businesses?fields=timezone_id,name&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getAdaccounts(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/owned_ad_accounts?fields=name,funding_source,funding_source_details,account_id,currency&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getClientAdaccounts(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/client_ad_accounts?fields=name,funding_source,funding_source_details,account_id,currency&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getAdaccount(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+adaccount_id+'?fields=name,funding_source,funding_source_details,account_id,currency,end_advertiser,end_advertiser_name,min_daily_budget,spend_cap,is_in_3ds_authorization_enabled_market&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static createAdaccount(business_id, token, name, timezone, currency) {
        let options = apiRegister.options(null, 'POST', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/adaccount?end_advertiser='+business_id+'&media_agency=NONE&partner=NONE&currency='+currency+'&name='+name+'&timezone_id='+timezone+'&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getPages(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/owned_pages?fields=name,picture&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getClientPages(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/client_pages?fields=name,picture&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getInternalPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/pages';
        return apiRegister.call(options, url);
    }

    static getInternalClientPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/pages?clients=true';
        return apiRegister.call(options, url);
    }

    static updatePage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = apiRegister.url.api  + '/client/facebook/' + data.client;
        return apiRegister.call(options, url);
    }

    static getInstagramAccounts(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+adaccount_id+'/instagram_accounts?fields=username&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getPixels(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+adaccount_id+'/adspixels?fields=name&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static updateInstagramSettings(data, client_id) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/instagram/' + client_id;
        return apiRegister.call(options, url);
    }

    static removeInstagramSettings(client_id) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = apiRegister.url.api  + '/client/instagram/' + client_id;
        return apiRegister.call(options, url);
    }

}

export {calls}