import { ChevronRightIcon, InformationCircleIcon, MenuIcon, SearchIcon, PhotographIcon } from '@heroicons/react/outline';
import { ChartBarIcon, WifiIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';

class DisplayBannerSquare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: "",
            logo: false,
            headlines: [],
            bodies: [],
            cta: {},
            error: false,
            background_color: "",
            font_color: "black",
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            image: this.props.image,
            logo: this.props.logo,
            headlines: this.props.headlines,
            bodies: this.props.bodies,
            cta: this.props.cta,
            main_color: this.props.main_color,
            accent_color: this.props.accent_color,
            error: this.props.error,
            subType: this.props.subType
        })

        if (this.state.image !== "") {
            try {
                let image_data = this.state.image ? this.state.image : ""
                let image = new Image()
                image.crossOrigin = 'anonymous';
                image.onload = () => {
                    let extracted_color = this.functions.getDominantColor(image);
                    this.setState({ background_color: extracted_color });
                    this.functions.calculateFontColor(extracted_color);
                };
                image.src = image_data;
            } catch (error) { }
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            image: nextProps.image,
            logo: nextProps.logo,
            headlines: nextProps.headlines,
            bodies: nextProps.bodies,
            cta: nextProps.cta,
            main_color: nextProps.main_color,
            accent_color: nextProps.accent_color,
            error: nextProps.error,
            subType: nextProps.subType
        })
    }

    renders = {
        headline: () => {
            try {
                if (this.state.headlines.length > 0 && this.state.headlines[0] !== "") {
                    return this.state.headlines[0]
                } else {
                    return "Headline ..."
                }
            } catch (error) {
                return "Headline ..."
            }
        },
        body: () => {
            try {
                if (this.state.bodies.length > 0 && this.state.bodies[0] !== "") {
                    return this.state.bodies[0]
                } else {
                    return "Description ..."
                }
            } catch (error) {
                return "Description ..."
            }
        },
        image: () => {
            try {
                if (this.state.image !== "") {
                    return this.state.image
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
        logo: () => {
            try {
                if (this.state.logo !== "") {
                    return this.state.logo
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        },
        getDominantColor: (image) => {
            let average_color = this.functions.averageColor(image);
            return average_color;
            // return this.mapColorToLuminance(average_color);
        },
        averageColor: (image) => {
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            let pixels = ctx.getImageData(0, 0, image.width, image.height).data;
            let red = [];
            let green = [];
            let blue = [];
            for (let i = 0; i < pixels.length; i += 4) {
                red.push(pixels[i]);
                green.push(pixels[i + 1]);
                blue.push(pixels[i + 2]);
            }
            return {
                r: Math.round(red.reduce((sum, val) => sum + val) / red.length),
                g: Math.round(green.reduce((sum, val) => sum + val) / green.length),
                b: Math.round(blue.reduce((sum, val) => sum + val) / blue.length),
            };
        },
        mapColorToLuminance: (color) => {
            let luminance = (color.r * 0.2126 + color.g * 0.7152 + color.b * 0.0722);
            return luminance;
        },
        calculateFontColor: async (background_color) => {
            const contrast_ratio = this.functions.contrastRatio(background_color, { r: 0, g: 0, b: 0 });
            if (contrast_ratio >= 10) {
                await this.promisedSetState({ font_color: 'black' });
            } else {
                await this.promisedSetState({ font_color: 'white' });
            }
        },
        contrastRatio: (background_color, font_color) => {
            let background_luminance = this.functions.mapColorToLuminance(background_color) / 255;
            let font_luminance = this.functions.mapColorToLuminance(font_color) / 255;
            let L1 = Math.max(background_luminance, font_luminance);
            let L2 = Math.min(background_luminance, font_luminance);
            return (L1 + 0.05) / (L2 + 0.05);
        }

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                {console.log("this.state square: ", this.state)}
                {
                    this.state.subType == "IMAGE_AD" &&
                    <div>
                        <div className='relative w-80 border-4 border-gray-800 rounded-lg' >
                            <div className='flex w-full h-4 items-center justify-center'>
                                <div className='h-4 bg-gray-800 rounded-b-lg'
                                    style={{ width: "30%" }}
                                ></div>
                            </div>
                            <div className='absolute top-0 w-full flex justify-between px-2'>
                                <div className='flex justify-center'>
                                    <div className='text-xs pl-8'>9:41</div>
                                </div>
                                <div className='flex flex-1'></div>
                                <div className='flex items-center justify-center space-x-2'><ChartBarIcon className='w-5 h-5 text-gray-800' /><WifiIcon className='w-5 h-5 text-gray-800' />
                                    <div className='flex items-center'>
                                        <div className='w-5 h-3 bg-gray-800 rounded-l-sm'></div><div className='w-3 h-3 border border-gray-800 rounded-r-sm'></div><div className='w-1 h-1 bg-gray-800 rounded-r-md' />
                                    </div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div className='my-4'>
                                    <img
                                        className="w-full object-cover"
                                        src={this.renders.image()} />
                                </div>
                                <div className='h-full w-full'>
                                    <div className='flex flex-col space-y-2'>
                                        <div className='bg-gray-300 w-full h-4'></div>
                                        <div className='bg-gray-300 w-full h-4'></div>
                                        <div className='bg-gray-300 w-full h-4'></div>
                                        <div className='bg-gray-300 w-full h-4'></div>
                                    </div>
                                </div>
                                <div className='bg-gray-300 w-full flex flex-col flex-1 items-center justify-center h-24'>
                                    <PhotographIcon className='h-10 w-10 text-gray-500' />
                                </div>
                                <div className='w-full h-4 flex items-center justify-center mt-4'>
                                    <div className='h-2 bg-gray-800 rounded-full'
                                        style={{ width: "30%" }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.subType !== "IMAGE_AD" &&
                    <div
                        className="w-80 border rounded-md bg-white">
                        <div className="h-10 w-full flex flex-row bg-gray-200 items-center">
                            <div className="px-4">
                                <MenuIcon className="w-5 h-5" />
                            </div>
                            <div className="flex flex-1 justify-center items-center">
                                <div className="h-2 bg-gray-500 w-32"></div>
                            </div>
                            <div className="px-4">
                                <SearchIcon className="w-5 h-5" />
                            </div>
                        </div>
                        <div className="px-2 pt-3 mb-3">
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                        </div>
                        <div className="px-2">
                            <div className="w-full relative">
                                <div className='absolute top-0 right-0'>
                                    <div className='flex flex-col items-center justify-center w-5 h-5 bg-white'>
                                        <InformationCircleIcon className="w-5 h-5 text-google-500" />
                                    </div>
                                </div>
                                {
                                    this.state.image === "" &&
                                    <div className='flex flex-col items-center w-full rounded-t-lg bg-gray-50'
                                        style={{ height: 250 }}
                                    >
                                        <div className='text-sm mt-10'>Problem loading image data</div>
                                    </div>
                                }
                                {
                                    this.state.image !== "" &&
                                    <div>
                                        <img
                                            className="w-full object-cover"
                                            src={this.renders.image()} />
                                    </div>
                                }
                                <div className='absolute bottom-0 px-4 py-2'
                                    style={{ backdropFilter: 'blur(4px)', backgroundColor: 'rgba(0,0,0,0.4)' }}
                                >
                                    <div className="text-white font-bold mb-1"
                                        style={this.state.background_color !== "" ? { color: this.state.font_color } : {}}
                                    >
                                        {this.functions.truncText(this.renders.headline(), 30)}
                                    </div>
                                    <div className='grid grid-cols-5 gap-2 items-center'>
                                        <div className='col-span-1'>
                                            {
                                                this.state.logo &&
                                                <img className='w-full' src={this.renders.logo()} />
                                            }
                                            {
                                                !this.state.logo &&
                                                <div className='border rounded-full h-10 w-10'></div>
                                            }
                                        </div>
                                        <div className='col-span-3'>
                                            <div className='text-sm text-white ml-2'
                                                style={this.state.background_color !== "" ? { color: this.state.font_color } : {}}
                                            >
                                                {this.functions.truncText(this.renders.body(), 90)}
                                            </div>
                                        </div>
                                        <div className='col-span-1 flex justify-center items-center'>
                                            {
                                                this.state.font_color === "black" &&
                                                <>
                                                    <div className="w-8 h-8 bg-black rounded-full flex items-center justify-center shadow" >
                                                        <ChevronRightIcon className="w-5 h-5 text-white" />
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.font_color === "white" &&
                                                <>
                                                    <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow" >
                                                        <ChevronRightIcon className="w-5 h-5 text-black" />
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.font_color === "" &&
                                                <>
                                                    <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow" >
                                                        <ChevronRightIcon className="w-5 h-5 text-black" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 pt-3">
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default DisplayBannerSquare;
