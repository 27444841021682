import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from 'react-spinners';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { calls } from "./calls";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import SweetAlert from "sweetalert-react";

class AdvertisingCreativesUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: this.props.disabled,
            success: false,
            value: null,
            title: this.props.title,
            size: this.props.size,
            type: this.props.type,
            site: this.props.site,
            client: this.props.client,
            channels: this.props.channels,
            variations: {},
            creatives: {},
            conversion: {},
            selected: this.props.selected,
            languages: [],
            loading: false,
            error: false,
            theInputKey: "",
            adset: {},
            adsets: [],
            campaign: {},
            content: this.props.content ? this.props.content : [{ button: true }]
        };
        this.functions.uploadImage = this.functions.uploadImage.bind(this);
    };

    componentDidMount() {
        this.setState({
            type: this.props.type,
            site: this.props.site,
            creatives: this.props.creatives,
            languages: this.props.languages,
            adset: this.props.adset,
            conversion: this.props.conversion,
            client: this.props.client,
            adsets: this.props.adsets,
            campaign: this.props.campaign,
            channels: this.props.channels,
            productsOnly: this.props.productsOnly,
            content: this.props.content ? this.props.content : [{ button: true }]
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            size: nextProps.size,
            type: nextProps.type,
            error: nextProps.error,
            creatives: nextProps.creatives,
            languages: nextProps.languages,
            adset: nextProps.adset,
            conversion: nextProps.conversion,
            adsets: nextProps.adsets,
            client: nextProps.client,
            site: nextProps.site,
            channels: nextProps.channels,
            campaign: nextProps.campaign,
            productsOnly: nextProps.productsOnly,
            content: nextProps.content ? nextProps.content : [{ button: true }]
        });
    }

    init = {};

    functions = {
        uploadImage: async (image) => {

            if (!this.state.creatives.image_ads) {
                this.state.creatives.image_ads = [];
            }

            if (!this.state.creatives.images) {
                this.state.creatives.images = [];
            }

            let selected_adset = this.state.adset;

            //CREATE ADSET IF NONE EXIST
            if (!this.state.creatives.adsets) {
                this.state.creatives.adsets = [];
                selected_adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset 1', value: 1, type: 'normal' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    selected_adset.campaign_id = this.state.campaign.id;
                }
                selected_adset.targeting = {};
                selected_adset.targeting.age_max = { name: '65+', value: 65 };
                selected_adset.targeting.age_min = { name: 18, value: 18 };
                selected_adset.targeting.gender = { name: 'Male and Female', value: 0 };
                selected_adset.targeting.facebook = {};
                selected_adset.targeting.linkedin = {};
                selected_adset.targeting.placement = { all: true };
                selected_adset.conversion = { prospect_default: true };
                selected_adset.targeting.weekdays = { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, all: true };
                this.state.creatives.adsets.push(selected_adset);
                if (this.props.updateAdset) {
                    this.props.updateAdset(selected_adset);
                }
            }

            //SET IMAGE INFORMATION
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                internal_id: "image_" + id,
                internal_adset: selected_adset,
                headline: this.state.creatives.headline ? this.state.creatives.headline : "",
                body: this.state.creatives.body ? this.state.creatives.body : "",
                show: true,
                error: false,
                loading: true,
                image: true,
                overview: this.props.overview,
                adsets: {},
                title: image.name,
                description: this.state.site.description,
                call_to_action: { name: 'No call to action', value: 'NO_BUTTON' },
                creative: {},
                website: (this.state.campaign && this.state.campaign.website) ? this.state.campaign.website : this.state.site.url,
                ...(this.state.creatives.call_to_action && { call_to_action: this.state.creatives.call_to_action }),
                ...(this.state.creatives.call_to_action_linkedin && { call_to_action_linkedin: this.state.creatives.call_to_action_linkedin }),
                ...(this.state.creatives.facebook_page && { facebook_page: this.state.creatives.facebook_page }),
                ...(this.state.creatives.instagram_account && { instagram_account: this.state.creatives.instagram_account })
            };
            try {
                if (this.state.campaign.budget.facebook_instagram.focus.value === "EVENT_RESPONSES") {
                    item.call_to_action = { name: "INTERESTED", value: "EVENT_RSVP", vertical: true };
                }
            } catch (error) { }
            try {
                item.title = item.title.replace(".jpg", "");
                item.title = item.title.replace(".jpeg", "");
                item.title = item.title.replace(".png", "");
            } catch (error) { }
            try {
                let url = this.state.campaign.website.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                item.display_website = url;
            } catch (e) {
                item.display_website = this.state.site.hostname;
            }

            if (this.props.overview) {
                this.state.creatives.images.push(item);
                await this.promisedSetState({ creatives: this.state.creatives });
                this.props.onChange(this.state.creatives);
            } else {
                this.state.creatives.image_ads.push(item);
            }

            await this.promisedSetState({ creatives: this.state.creatives });
            this.props.onChange(this.state.creatives);

            //UPLOAD IMAGE
            const formData = new FormData();
            formData.append('file', image);
            try {
                let response = await calls.uploadImage(formData);
                item.loading = false;
                item.creative.url = response.data.url;
                item.creative.format = response.data.format;
                item.creative.ratio = response.data.ratio;
            } catch (error) {
                item.loading = false;
                item.error_message = error.body ? error.body.message : "Somehting went wrong";
                item.error = true;
                item.internal_adset = {};
            }

            //UPDATE OVERVIEW ITEM
            if (this.props.overview) {
                this.state.creatives.images = this.state.creatives.images.map((image) => {
                    if (image.id == item.id) {
                        image = item;
                        image.loading = false;
                    }
                    return image;
                });
            } else {
                this.state.creatives.image_ads = this.state.creatives.image_ads.map((image) => {
                    if (image.id == item.id) {
                        image = item;
                        image.loading = false;
                    }
                    return image;
                });
            }

            await this.promisedSetState({ creatives: this.state.creatives });
            this.props.onChange(this.state.creatives);

        },
        uploadImageOld: (image, first_upload) => {

            const formData = new FormData();
            formData.append('file', image);

            if (!this.state.creatives.image_ads) {
                this.state.creatives.image_ads = [];
            }

            let adset_id = Math.floor((Math.random() * 9999999999) + 1);

            //CHECK ADSET SELECTED
            let adset = {};
            if (typeof this.state.adset === "object" && this.state.adset.type === 'normal') {

                adset = this.state.adset;

            } else {

                adset = { id: adset_id, name: 'Adset ' + (this.state.adsets.length + 1), value: this.state.adsets.length + 1, type: 'normal' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    adset.campaign_id = this.state.campaign.id;
                }
                adset.targeting = {};
                adset.targeting.age_max = { name: '65+', value: 65 };
                adset.targeting.age_min = { name: 18, value: 18 };
                adset.targeting.gender = { name: 'Male and Female', value: 0 };
                adset.targeting.facebook = {};
                adset.targeting.linkedin = {};
                adset.targeting.placement = {
                    all: true
                };
                adset.conversion = {
                    prospect_default: true
                };
                adset.targeting.weekdays = {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    all: true
                };
                if (!this.state.creatives.adsets) {
                    this.state.creatives.adsets = [];
                }
                this.state.adset = adset;
                this.state.creatives.adsets.push(adset);
                this.props.updateAdset(adset);
            }

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                show: true,
                error: false,
                loading: true,
                image: true,
                overview: this.props.overview,
                adsets: {},
                internal_adset: adset,
                title: image.name,
                internal_id: "image_" + id,
                headline: '',
                body: '',
                description: this.state.site.description,
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                display_website: "",
                call_to_action: { name: 'No call to action', value: 'NO_BUTTON' },
                creative: {}
            };

            try {
                let url = this.state.campaign.website.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                item.display_website = url;
            } catch (e) {
                item.display_website = this.state.site.hostname;
            }

            try {
                item.headline = this.state.creatives.headline;
                item.body = this.state.creatives.body;
                if (this.state.creatives.call_to_action) {
                    item.call_to_action = this.state.creatives.call_to_action;
                }
                if (this.state.creatives.call_to_action_linkedin) {
                    //item.call_to_action_linkedin = this.state.creatives.call_to_action_linkedin;
                }
            } catch (e) { }

            this.state.creatives.image_ads.push(item);

            this.setState({
                creatives: this.state.creatives
            }, () => {

                this.props.onChange(this.state.creatives);

                calls.uploadImage(formData).then((response) => {

                    for (let i = 0; i < this.state.creatives.image_ads.length; i++) {

                        if (this.state.creatives.image_ads[i].id === id) {
                            this.state.creatives.image_ads[i].loading = false;

                            this.state.creatives.image_ads[i].creative.url = response.data.url;
                            this.state.creatives.image_ads[i].creative.format = response.data.format;
                            this.state.creatives.image_ads[i].creative.ratio = response.data.ratio;

                            break;
                        }
                    }

                    /*
                    if(this.state.creatives.carousel_ads && first_upload && response.data.ratio === '1:1'){
                        for(let i = 0; i < this.state.creatives.carousel_ads.length; i++){
                            let carousel = this.state.creatives.carousel_ads[i];
                            let slide = {
                                id: item.id,
                                title: item.title,
                                headline: "",
                                description: "",
                                website: this.state.site.url,
                                creative: {
                                    url: response.data.url,
                                    format: response.data.format,
                                    ratio: response.data.ratio
                                },
                                image: true,
                                video: false,
                                show: true,
                                call_to_action: {name: 'No call to action', value: 'NO_BUTTON'},
                            };
                            try{
                                slide.headline = this.state.creatives.carousel_title;
                                slide.description = this.state.creatives.carousel_description;
                                if(this.state.creatives.call_to_action){
                                    slide.call_to_action = this.state.creatives.call_to_action;
                                }
                            }catch (e) {}
                            carousel.items.push(slide);
                        }
                    }
                    */

                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        this.props.onChange(this.state.creatives);
                    });

                }, (error) => {
                    for (let i = 0; i < this.state.creatives.image_ads.length; i++) {
                        if (this.state.creatives.image_ads[i].id === id) {
                            try {
                                this.state.creatives.image_ads[i].creative.url = error.body.data.url;
                                this.state.creatives.image_ads[i].creative.format = error.body.data.format;
                                this.state.creatives.image_ads[i].creative.ratio = error.body.data.ratio;
                            } catch (e) { }
                            this.state.creatives.image_ads[i].error = true;
                            this.state.creatives.image_ads[i].error_message = error.body.message;
                            this.state.creatives.image_ads[i].loading = false;
                            this.state.creatives.image_ads[i].internal_adset = {};
                            break;
                        }
                    }
                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        this.props.onChange(this.state.creatives);
                    });
                });
            });
        },
        uploadVideo: async (video) => {

            if (!this.state.creatives.video_ads) {
                this.state.creatives.video_ads = [];
            }

            if (!this.state.creatives.videos) {
                this.state.creatives.videos = [];
            }

            let selected_adset = this.state.adset;

            //CREATE ADSET IF NONE EXIST
            if (!this.state.creatives.adsets) {
                this.state.creatives.adsets = [];
                selected_adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset 1', value: 1, type: 'normal' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    selected_adset.campaign_id = this.state.campaign.id;
                }
                selected_adset.targeting = {};
                selected_adset.targeting.age_max = { name: '65+', value: 65 };
                selected_adset.targeting.age_min = { name: 18, value: 18 };
                selected_adset.targeting.gender = { name: 'Male and Female', value: 0 };
                selected_adset.targeting.facebook = {};
                selected_adset.targeting.linkedin = {};
                selected_adset.targeting.placement = { all: true };
                selected_adset.conversion = { prospect_default: true };
                selected_adset.targeting.weekdays = { monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, all: true };
                this.state.creatives.adsets.push(selected_adset);
                if (this.props.updateAdset) {
                    this.props.updateAdset(selected_adset);
                }
            }

            //SET IMAGE INFORMATION
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                internal_id: "video_" + id,
                internal_adset: selected_adset,
                headline: this.state.creatives.headline ? this.state.creatives.headline : "",
                body: this.state.creatives.body ? this.state.creatives.body : "",
                show: true,
                error: false,
                loading: true,
                video: true,
                overview: this.props.overview,
                adsets: {},
                title: video.name,
                description: this.state.site.description,
                call_to_action: { name: 'No call to action', value: 'NO_BUTTON' },
                creative: {},
                website: (this.state.campaign && this.state.campaign.website) ? this.state.campaign.website : this.state.site.url,
                ...(this.state.creatives.call_to_action && { call_to_action: this.state.creatives.call_to_action }),
                ...(this.state.creatives.call_to_action_linkedin && { call_to_action_linkedin: this.state.creatives.call_to_action_linkedin }),
                ...(this.state.creatives.facebook_page && { facebook_page: this.state.creatives.facebook_page }),
                ...(this.state.creatives.instagram_account && { instagram_account: this.state.creatives.instagram_account })
            };
            try {
                if (this.state.campaign.budget.facebook_instagram.focus.value === "EVENT_RESPONSES") {
                    item.call_to_action = { name: "INTERESTED", value: "EVENT_RSVP", vertical: true };
                }
            } catch (error) { }
            try {
                item.title = item.title.replace(".mp4", "");
                item.title = item.title.replace(".gif", "");
                item.title = item.title.replace(".mov", "");
            } catch (error) { }
            try {
                let url = this.state.campaign.website.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                item.display_website = url;
            } catch (e) {
                item.display_website = this.state.site.hostname;
            }

            if (this.props.overview) {
                this.state.creatives.videos.push(item);
                await this.promisedSetState({ creatives: this.state.creatives });
                this.props.onChange(this.state.creatives);
            } else {
                this.state.creatives.video_ads.push(item);
            }

            await this.promisedSetState({ creatives: this.state.creatives });
            this.props.onChange(this.state.creatives);

            //UPLOAD IMAGE
            const formData = new FormData();
            formData.append('file', video);
            try {
                let channels = Object.keys(this.state.channels).filter((key) => {
                    return this.state.channels[key]
                });
                channels = channels.join(',');
                let response = await calls.uploadVideo(formData, channels, this.state.client.id);
                item.loading = false;
                item.creative.url = response.data.url.replace(".mov", ".mp4");
                item.creative.format = response.data.format;
                item.creative.ratio = response.data.ratio;
                item.creative.channels = response.data.channels;

                console.log(item.creative.url);

                //THUMBNAIL
                item.creative.thumbnail = {};
                //item.creative.thumbnail.url = response.data.url.replace(".mov", ".jpg");
                item.creative.thumbnail.url = item.creative.url.replace(".mp4", ".jpg");
                item.creative.thumbnail.ratio = response.data.ratio;
                item.creative.thumbnail.format = "jpg";

                console.log(item.creative.thumbnail.url);

            } catch (error) {
                item.loading = false;
                item.error_message = error.body ? error.body.message : "Something went wrong";
                item.error = true;
                item.internal_adset = {};
            }

            //UPDATE OVERVIEW ITEM
            if (this.props.overview) {
                this.state.creatives.videos = this.state.creatives.videos.map((video) => {
                    if (video.id == item.id) {
                        video = item;
                        video.loading = false;
                    }
                    return video;
                });
            } else {
                this.state.creatives.video_ads = this.state.creatives.video_ads.map((video) => {
                    if (video.id == item.id) {
                        video = item;
                        video.loading = false;
                    }
                    return video;
                });
            }

            await this.promisedSetState({ creatives: this.state.creatives });
            this.props.onChange(this.state.creatives);

        },
        uploadVideoOld: (video, first_upload) => {

            const formData = new FormData();
            formData.append('file', video);

            if (!this.state.creatives.video_ads) {
                this.state.creatives.video_ads = [];
            }

            //CHECK ADSET SELECTED
            let adset = {};
            if (typeof this.state.adset === "object" && this.state.adset.type === 'normal') {
                adset = this.state.adset;
            } else {
                adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset ' + (this.state.adsets.length + 1), value: this.state.adsets.length + 1, type: 'normal' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    adset.campaign_id = this.state.campaign.id;
                }
                adset.targeting = {};
                adset.targeting.age_max = { name: '65+', value: 65 };
                adset.targeting.age_min = { name: 18, value: 18 };
                adset.targeting.gender = { name: 'Male and Female', value: 0 };
                adset.targeting.facebook = {};
                adset.targeting.linkedin = {};
                adset.targeting.placement = {
                    all: true
                };
                adset.conversion = {
                    prospect_default: true
                }; //this.state.conversion;
                adset.targeting.weekdays = {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    all: true
                };
                if (!this.state.creatives.adsets) {
                    this.state.creatives.adsets = [];
                }
                this.state.adset = adset;
                this.state.creatives.adsets.push(adset);
                this.props.updateAdset(adset);
            }

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                show: true,
                error: false,
                loading: true,
                video: true,
                adsets: {},
                internal_adset: adset,
                title: video.name,
                overview: this.props.overview,
                internal_id: "video_" + id,
                creative: {},
                languages: {},
                headline: '',
                body: '',
                description: this.state.site.description,
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                display_website: "",
                call_to_action: { name: 'No call to action', value: 'NO_BUTTON' }
            };

            try {
                let url = this.state.campaign.website.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                item.display_website = url;
            } catch (e) {
                item.display_website = this.state.site.hostname;
            }

            try {
                item.headline = this.state.creatives.headline;
                item.body = this.state.creatives.body;
                if (this.state.creatives.call_to_action) {
                    item.call_to_action = this.state.creatives.call_to_action;
                }
                if (this.state.creatives.call_to_action_linkedin) {
                    item.call_to_action_linkedin = this.state.creatives.call_to_action_linkedin;
                }
            } catch (e) { }

            this.state.creatives.video_ads.push(item);

            this.setState({
                creatives: this.state.creatives
            }, () => {

                this.props.onChange(this.state.creatives);

                let channels = Object.keys(this.state.channels).filter((key) => {
                    return this.state.channels[key]
                });
                channels = channels.join(',');

                calls.uploadVideo(formData, channels, this.state.client.id).then((response) => {

                    for (let i = 0; i < this.state.creatives.video_ads.length; i++) {
                        if (this.state.creatives.video_ads[i].id === id) {
                            this.state.creatives.video_ads[i].loading = false;
                            this.state.creatives.video_ads[i].creative.url = response.data.url;
                            this.state.creatives.video_ads[i].creative.format = response.data.format;
                            this.state.creatives.video_ads[i].creative.ratio = response.data.ratio;
                            this.state.creatives.video_ads[i].creative.channels = response.data.channels;

                            //THUMBNAIL
                            this.state.creatives.video_ads[i].creative.thumbnail = {};
                            this.state.creatives.video_ads[i].creative.thumbnail.url = response.data.url.replace(".mp4", ".jpg");
                            this.state.creatives.video_ads[i].creative.thumbnail.ratio = response.data.ratio;
                            this.state.creatives.video_ads[i].creative.thumbnail.format = "jpg";

                            break;
                        }
                    }

                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        this.props.onChange(this.state.creatives);
                    });

                }, (error) => {
                    for (let i = 0; i < this.state.creatives.video_ads.length; i++) {
                        if (this.state.creatives.video_ads[i].id === id) {

                            try {
                                this.state.creatives.image_ads[i].creative.url = error.body.data.url;
                                this.state.creatives.image_ads[i].creative.format = error.body.data.format;
                                this.state.creatives.image_ads[i].creative.ratio = error.body.data.ratio;
                            } catch (e) { }

                            this.state.creatives.video_ads[i].error = true;
                            this.state.creatives.video_ads[i].error_message = error.body.message;
                            this.state.creatives.video_ads[i].loading = false;
                            this.state.creatives.video_ads[i].internal_adset = {};
                            break;
                        }
                    }
                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        this.props.onChange(this.state.creatives);
                    });
                });
            });

        },
        uploadCarousel: (files) => {

            let self = this;

            if (!this.state.creatives.carousel_ads) {
                this.state.creatives.carousel_ads = [];
            }

            //CHECK ADSET SELECTED
            let adset = {};
            if (this.state.adset && this.state.adset.type === 'normal') {
                adset = this.state.adset;
            } else {
                adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset ' + (this.state.adsets.length + 1), value: this.state.adsets.length + 1, type: 'normal' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    adset.campaign_id = this.state.campaign.id;
                }
                adset.targeting = {};
                adset.targeting.age_max = { name: '65+', value: 65 };
                adset.targeting.age_min = { name: 18, value: 18 };
                adset.targeting.gender = { name: 'Male and Female', value: 0 };
                adset.targeting.facebook = {};
                adset.targeting.linkedin = {};
                adset.targeting.placement = {
                    all: true
                };
                adset.conversion = {
                    prospect_default: true
                }; //this.state.conversion;
                adset.targeting.weekdays = {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    all: true
                };
                if (!this.state.creatives.adsets) {
                    this.state.creatives.adsets = [];
                }
                this.state.adset = adset;
                this.state.creatives.adsets.push(adset);
                this.props.updateAdset(adset);
            }

            let carousel_id = Math.floor((Math.random() * 9999999999) + 1);
            let carousel = {
                id: carousel_id,
                carousel: true,
                title: "Carousel " + (this.state.creatives.carousel_ads.length + 1),
                languages: {},
                show: true,
                adsets: {},
                internal_adset: adset,
                headline: "",
                body: this.state.creatives.body,
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                items: []
            };

            for (let i = 0; i < files.length; i++) {
                if (files[i].size < 100000000) {

                    let video = (files[i].type === 'video/mp4' || files[i].type === 'image/gif');
                    let image = files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png';

                    const formData = new FormData();
                    formData.append('file', files[i]);
                    let id = Math.floor((Math.random() * 9999999999) + 1);

                    let slide = {
                        id: id,
                        headline: "",
                        description: "",
                        website: this.state.site.url,
                        creative: {
                            url: "",
                            format: "",
                            ratio: "",
                            channels: {}
                        },
                        loading: true,
                        error: false,
                        image: image,
                        video: video,
                        call_to_action: { name: 'No call to action', value: 'NO_BUTTON' },
                    };
                    try {
                        slide.headline = this.state.creatives.carousel_title;
                        slide.description = this.state.creatives.carousel_description;
                        if (this.state.creatives.call_to_action) {
                            slide.call_to_action = this.state.creatives.call_to_action;
                        }
                        /*
                        if(this.state.creatives.call_to_action_linkedin){
                            slide.call_to_action_linkedin = this.state.creatives.call_to_action_linkedin;
                        }
                        */
                    } catch (e) { }
                    carousel.items.push(slide);

                    if (image) {
                        calls.uploadImage(formData).then((response) => {
                            if (response.data.ratio === '1:1') {
                                setResponse(response, id);
                            } else {
                                setError(id, 'Wrong aspect ratio, must be 1:1');
                            }
                        }, (error) => {
                            setError(id, 'Something went wrong');
                        });
                    } else if (video) {
                        let channels = Object.keys(this.state.channels).filter((key) => {
                            return this.state.channels[key]
                        });
                        channels = channels.join(',');
                        calls.uploadVideo(formData, channels, this.state.client.id).then((response) => {
                            if (response.data.ratio === '1:1') {
                                setResponse(response, id);
                            } else {
                                setError(id, 'Wrong aspect ratio, must be 1:1');
                            }
                        }, (error) => {
                            setError(id, 'Something went wrong');
                        });
                    }
                } else {
                    this.setState({
                        error_modal: true,
                        error_message: 'File is to large! Max. 100MB'
                    })
                }
            }

            function setResponse(response, id) {
                for (let n = 0; n < self.state.creatives.carousel_ads.length; n++) {
                    if (self.state.creatives.carousel_ads[n].id === carousel_id) {
                        let carousel = self.state.creatives.carousel_ads[n];

                        for (let m = 0; m < carousel.items.length; m++) {
                            if (carousel.items[m].id === id) {
                                carousel.items[m].loading = false;
                                carousel.items[m].creative = {
                                    url: response.data.url,
                                    format: response.data.format,
                                    ratio: response.data.ratio,
                                    channels: response.data.channels ? response.data.channels : {}
                                };
                                break;
                            }
                        }

                    }
                }
                self.setState({
                    creatives: self.state.creatives
                }, () => {
                    self.props.onChange(self.state.creatives);
                });
            }

            function setError(id, message) {
                for (let n = 0; n < self.state.creatives.carousel_ads.length; n++) {
                    if (self.state.creatives.carousel_ads[n].id === carousel_id) {
                        let carousel = self.state.creatives.carousel_ads[n];

                        for (let m = 0; m < carousel.items.length; m++) {
                            if (carousel.items[m].id === id) {
                                carousel.items[m].loading = false;
                                carousel.items[m].error = true;
                                carousel.items[m].error_message = message;
                                break;
                            }
                        }

                    }
                }
                self.setState({
                    creatives: self.state.creatives
                }, () => {
                    self.props.onChange(self.state.creatives);
                });
            }

            this.state.creatives.carousel_ads.push(carousel);

            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.props.onChange(this.state.creatives);
            });

        },
        onSelectCarouselFiles: (e) => {
            if (e.target.files && e.target.files.length > 0) {
                this.functions.uploadCarousel(e.target.files);
            }
        },
        onSelectFile: (e, first_upload) => {

            let files = e.target.files;

            if (!files) {
                files = {};
            }

            /*
            if(!this.state.creatives.carousel_ads){
                this.state.creatives.carousel_ads = [];
            }

            //CHECK ADSET SELECTED
            let adset = {};
            if(this.state.adset && this.state.adset.type === 'normal'){
                adset = this.state.adset;
            }else{
                adset = {id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset ' + (this.state.adsets.length + 1), value: this.state.adsets.length + 1, type: 'normal'};
                if(typeof this.state.campaign === "object" && this.state.campaign.id){
                    adset.campaign_id = this.state.campaign.id;
                }
                adset.targeting = {};
                adset.targeting.age_max = {name: '65+', value: 65};
                adset.targeting.age_min = {name: 18, value: 18};
                adset.targeting.gender = {name: 'Male and Female', value: 0};
                adset.targeting.facebook = {};
                adset.targeting.placement = {
                    all: true
                };
                adset.conversion = {
                    prospect_default: true
                }; //this.state.conversion;
                adset.targeting.weekdays = {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    all: true
                };
                if(!this.state.creatives.adsets){
                    this.state.creatives.adsets = [];
                }
                this.state.adset = adset;
                this.state.creatives.adsets.push(adset);
                this.props.updateAdset(adset);
            }

            //console.log(adset);

            if(this.state.creatives.carousel_ads.length < 1 && files.length > 1){
                let carousel_id = Math.floor((Math.random() * 9999999999) + 1);
                let carousel = {
                    id: carousel_id,
                    carousel: true,
                    title: "Carousel " + (this.state.creatives.carousel_ads.length + 1),
                    languages: {},
                    show: true,
                    adsets: {},
                    internal_adset: adset,
                    internal_id: "carousel_" + carousel_id,
                    headline: "",
                    body: "",
                    website: this.state.site.url,
                    items: [],
                };

                try{
                    carousel.headline = this.state.creatives.headline;
                    carousel.body = this.state.creatives.body;
                }catch (e) {}


                this.state.creatives.carousel_ads.push(carousel);
            }


            this.setState({
                creatives: this.state.creatives
            }, () => {
                if (files && files.length > 0) {

                    if(files.length < 2){
                        first_upload = false;
                    }

                    for (let i = 0; i < files.length; i++) {
                        if(files[i].size < 100000000){
                            if (files[i].type === 'video/mp4') {
                                this.functions.uploadVideo(files[i], first_upload);
                            } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                                this.functions.uploadImage(files[i], first_upload);
                            }
                        }else{
                            this.setState({
                                error_modal: true,
                                error_message: 'File is to large! Max. 100MB'
                            })
                        }
                    }
                    const randomString = Math.random().toString(36);
                    this.setState({
                        theInputKeyOne: randomString + 1
                    });
                }
            });

             */

            //console.log(files);

            for (let i in files) {
                if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                    this.functions.uploadVideo(files[i], false);
                } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                    this.functions.uploadImage(files[i], false);
                }
                const randomString = Math.random().toString(36);
                this.setState({
                    theInputKeyOne: randomString + 1
                });
            }

        },
        createCatalogAd: () => {

            //CHECK ADSET SELECTED
            let adset = {};
            if (this.state.adset && this.state.adset.type === 'dynamic') {
                adset = this.state.adset;
            } else {
                adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset ' + (this.state.adsets.length + 1) + ' dynamic', value: this.state.adsets.length + 1, type: 'dynamic' };
                if (typeof this.state.campaign === "object" && this.state.campaign.id) {
                    adset.campaign_id = this.state.campaign.id;
                }
                //console.log(this.state.campaign);
                adset.targeting = {};
                adset.targeting.age_max = { name: '65+', value: 65 };
                adset.targeting.age_min = { name: 18, value: 18 };
                adset.targeting.gender = { name: 'Male and Female', value: 0 };
                adset.targeting.facebook = {};
                adset.targeting.linkedin = {};
                adset.targeting.placement = {
                    all: true
                };
                adset.conversion = {
                    prospect_default: true
                }; //this.state.conversion;
                adset.targeting.weekdays = {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    all: true
                };
                if (!this.state.creatives.adsets) {
                    this.state.creatives.adsets = [];
                }
                this.state.adset = adset;
                this.state.creatives.adsets.push(adset);
                this.props.updateAdset(adset);
            }
            if (!this.state.creatives.dynamic_ads) {
                this.state.creatives.dynamic_ads = [];
            }
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                show: true,
                error: false,
                loading: false,
                dynamic: true,
                adsets: {},
                internal_adset: adset,
                title: "Dynamic " + (this.state.creatives.dynamic_ads.length + 1),
                internal_id: "dynamic_" + id,
                creative: {},
                languages: {},
                headline: "",
                body: "",
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                items: [],
            };

            try {
                item.headline = this.state.creatives.headline;
                item.body = this.state.creatives.body;
            } catch (e) { }

            /*
            for(let i = 0; i < this.state.languages.length; i++){
                item.languages[this.state.languages[i].code] = {
                    headline: "",
                    body: "",
                    website: this.state.site.url,
                    items: [],
                };
                try{
                    item.languages[this.state.languages[i].code].headline = this.state.creatives.languages[this.state.languages[i].code].headline;
                    item.languages[this.state.languages[i].code].body = this.state.creatives.languages[this.state.languages[i].code].body;
                }catch (e) {}
            }
            */

            this.state.creatives.dynamic_ads.push(item);
            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.props.onChange(this.state.creatives);
            });
        },
        createEventAd: () => {

            //CHECK ADSET SELECTED
            let adset = {};
            adset = this.state.adset;
            if (!this.state.creatives.event_ads) {
                this.state.creatives.event_ads = [];
            }

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                show: true,
                error: false,
                loading: false,
                event: true,
                adsets: {},
                internal_adset: adset,
                title: "Event " + (this.state.creatives.event_ads.length + 1),
                internal_id: "event_" + id,
                creative: {},
                facebook_event: {},
                languages: {},
                headline: "",
                body: "",
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                items: [],
            };

            try {
                item.headline = this.state.creatives.headline;
                item.body = this.state.creatives.body;
            } catch (e) { }

            this.state.creatives.event_ads.push(item);
            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.props.onChange(this.state.creatives);
            });
        },
        createPostAd: () => {

            //CHECK ADSET SELECTED
            let adset = {};
            adset = JSON.parse(JSON.stringify(this.state.adset));
            if (!this.state.creatives.post_ads) {
                this.state.creatives.post_ads = [];
            }

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let item = {
                id: id,
                show: true,
                error: false,
                loading: false,
                post: true,
                adsets: {},
                internal_adset: adset,
                title: "Post " + (this.state.creatives.post_ads.length + 1),
                internal_id: "post_" + id,
                creative: {},
                facebook_media: {},
                instagram_media: {},
                languages: {},
                headline: "",
                body: "",
                website: this.state.campaign.website ? this.state.campaign.website : this.state.site.url,
                items: [],
            };

            try {
                item.headline = this.state.creatives.headline;
                item.body = this.state.creatives.body;
            } catch (e) { }

            this.state.creatives.post_ads.push(item);
            this.setState({
                creatives: this.state.creatives
            }, () => {
                console.log(this.state.creatives);
                this.props.onChange(this.state.creatives);
            });
        }
    };

    renders = {
        objects: () => {
            try {
                let videos = this.state.creatives.video_ads ? this.state.creatives.video_ads.length : 0;
                let images = this.state.creatives.image_ads ? this.state.creatives.image_ads.length : 0;
                let carousel = this.state.creatives.carousel_ads ? this.state.creatives.carousel_ads.length : 0;
                return videos + images + carousel;
            } catch (e) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={
                cx("",
                    {
                        ["Upload-ImageContainerHalf"]: this.state.size === "half",
                        ["HideUpload"]: this.props.hide,
                    })
            }>
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false });
                    }}
                />
                <div key={this.state.theInputKeyOne || ''} className={
                    cx("relative w-full py-8 px-8 text-center transition-all duration-200 hover:bg-opacity-0 hover:border-green-500 bg-purple-500 bg-opacity-25 border-2 border-dashed border-purple-500 text-purple-500 rounded",
                        {
                            ["py-2"]: ((this.renders.objects() > 0 && !this.props.overview) || this.props.showAll),
                        })
                }>
                    {
                        (this.renders.objects() < 1 || this.props.overview) && !this.state.productsOnly && !this.props.showAll &&
                        <div className={
                            cx("",
                                {
                                    ["pr-3"]: this.props.products,
                                })
                        }>
                            <div className="">
                                <div>
                                    <div className="icons flex flex-row space-x-2 text-purple-500 justify-center">
                                        <FeatherIcon className="stroke-current" size={26} icon="image" />
                                        <FeatherIcon className="stroke-current" size={26} icon="video" />
                                    </div>
                                    <h4 className="text-xl">Drag and drop your images and/or videos here</h4>
                                </div>
                                <div className="text-sm font-semibold py-2 inline-flex px-6 rounded bg-purple-500 text-white hover:bg-purple-700 transition-all duration-200 cursor-pointer mt-4">
                                    or click here to select them from your computer
                                </div>
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e, true)}
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpg, image/jpeg, image/gif, video/*"}
                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                />
                            </div>
                        </div>
                    }
                    {
                        (this.renders.objects() > 0 && !this.props.overview && !this.state.productsOnly && !this.props.showAll) &&
                        <div className="pl-3 flex flex-1">
                            <div onClick={() => {
                                this.functions.createCatalogAd();
                            }} className="Upload-ImageContainerArea">
                                <div className="Upload-ImageContainerArea-Add">
                                    Click here to create&nbsp;<b>Catalog ads</b>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        ((this.renders.objects() > 0 && !this.props.overview && !this.state.productsOnly) || this.props.showAll) &&
                        <div className="flex flex-1 pr-3">
                            <div className="Upload-ImageContainerArea">
                                <div className="Upload-ImageContainerArea-Add">
                                    Create&nbsp;<b>Image ads</b>
                                </div>
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e, false)}
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpg, image/jpeg"}
                                    className="Upload-ImageContainerAreaPlusUpload"
                                />
                            </div>
                        </div>
                    }
                    {
                        ((this.renders.objects() > 0 && !this.props.overview && !this.state.productsOnly) || this.props.showAll) &&
                        <div className="flex flex-1 pl-3 pr-3">
                            <div className="Upload-ImageContainerArea">
                                <div className="Upload-ImageContainerArea-Add">
                                    Create&nbsp;<b>Video ads</b>
                                </div>
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e, false)}
                                    type="file"
                                    multiple
                                    accept={"video/mp4"}
                                    className="Upload-ImageContainerAreaPlusUpload"
                                />
                            </div>
                        </div>
                    }
                    {
                        ((this.renders.objects() > 0 && !this.props.overview && !this.state.productsOnly) || this.props.showAll) &&
                        <div className="flex flex-1 pr-3 pl-3">
                            <div className="Upload-ImageContainerArea">
                                <div className="Upload-ImageContainerArea-Add">
                                    Create&nbsp;<b>Carousel ads</b>
                                </div>
                                <input
                                    onChange={(e) => this.functions.onSelectCarouselFiles(e)}
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                                    className="Upload-ImageContainerAreaPlusUpload"
                                />
                            </div>
                        </div>
                    }
                    {
                        ((this.renders.objects() > 0 && !this.props.overview && !this.state.productsOnly) || this.props.showAll) &&
                        <div className="flex flex-1 pl-3">
                            <div className="Upload-ImageContainerArea">
                                <div onClick={() => {
                                    this.functions.createCatalogAd();
                                }} className="Upload-ImageContainerArea-Add">
                                    Create&nbsp;<b>Catalog ads</b>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.productsOnly && !this.props.showAll &&
                        <div className="flex flex-1">
                            <div className="Upload-ImageContainerArea">
                                <div onClick={() => {
                                    this.functions.createCatalogAd();
                                }} className="Upload-ImageContainerArea-Add">
                                    Create&nbsp;<b>Catalog ads</b>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesUpload;
