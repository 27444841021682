import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {calls} from "../../pages/user-optimize-all/calls";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import CsvDownload from 'react-json-to-csv'
import SearchtermList from "../../modules/searchtermlist";
import CampaignsList from "../../modules/campaignslist";
import FeatherIcon from "feather-icons-react";

class OptimizeSearchQueries extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queries: [],
            channels: [],
            campaign: {},
            sort: 'click',
            order: 'desc',
            setting: {name: 'Ask for which level each time (adgroup or campaign)', value: 'ask'},
            loading: true,
            advanced: false,
            index: 10,
            search: "",
            pagesize: 10
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            queries: nextProps.queries,
            loading: nextProps.loading
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            queries: this.props.queries,
            loading: this.props.loading,
            channels: this.props.channels
        });
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div>
                {
                    this.state.loading &&
                    <div className="background-Loader">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={24}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.loading &&
                    <SearchtermList
                        campaign={this.state.campaign}
                        campaignitems={this.state.queries}
                        properties={{channel: true, query: true, type: true, keyword: true, impressions: true, click: true, ctr: true, cpc: true, spend: true, conversions: true, actions: {delete: true, preview: true}}}
                        sort={this.state.sort}
                        order={this.state.order}
                        pagesize={this.state.pagesize}
                        updateSort={(sort, order) => {
                            this.setState({
                                sort: sort,
                                order: order
                            })
                        }}
                        onRemove={(value) => {
                            this.props.onRemove(value)
                        }}
                    />
                }
                {
                    !this.state.loading && (this.state.queries.length > this.state.pagesize) &&
                    <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                        <div onClick={(e) => {
                            this.setState({
                                pagesize: this.state.pagesize + 10
                            })
                        }} className="btn btn-primary btn-lg max-w-md flex-1">
                            <div>
                                Show more ({this.state.queries.length}/{this.state.pagesize})
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchQueries;
