import { apiRegister } from './apiRegister';
import { tokenRegister } from './tokenRegister';

function createChangelog(data) {
    // console.log('incoming data createChangelog: ', data)
    try {
        let changes = []
        let changelog_data = {}

        if (data && data.level !== "keyword") {
            if (data && Object.keys(data).length > 0 && data.initial_data && Object.keys(data.initial_data).length > 0 && data.updated_data && Object.keys(data.updated_data).length > 0) {
                Object.keys(data.updated_data).forEach(key => {
                    if (!data.initial_data.hasOwnProperty(key) || (data.updated_data[key] !== undefined && !deepEqual(data.updated_data[key], data.initial_data[key]))) {
                        changes.push({
                            object_type: key,
                            old_value: data.initial_data[key] !== undefined ? (data.initial_data[key].name !== undefined ? data.initial_data[key].name : data.initial_data[key]) : null,
                            new_value: data.updated_data[key].name !== undefined ? data.updated_data[key].name : data.updated_data[key]
                        });
                    }
                })
            }
        } else {
            if (data && data.updated_data && data.updated_data.status === "added" || data.updated_data.status === "remove") {
                changes.push({
                    object_type: "keyword",
                    new_value: JSON.stringify(data.updated_data)
                })
            }
        }


        changelog_data.client = data.client
        changelog_data.order = data.order
        changelog_data.item = data.item
        changelog_data.level = data.level
        changelog_data.changes = changes

        // console.log("changes", changes)
        // console.log("updated_data", data.updated_data)

        // IF STATUS IS REMOVED, ADD ITEM NAME TO CHANGELOG DATA BECAUSE IT GETS REMOVED DUH!
        if (changes.some(change => change.object_type === 'status' && change.new_value === 'removed')) {
            changelog_data.removed = true
            changelog_data.item_name = data.initial_data.name
        }

        if (data.reference_id) {
            changelog_data.reference_id = data.reference_id
        }

        // FOR ADGROUP SCHEDULER
        if (data.level === "adgroup" && data.initial_data && data.initial_data.name && data.updated_data && data.updated_data.name && data.initial_data.name === data.updated_data.name) {
            changelog_data.item_name = data.initial_data.name
        }

        if (data.level === "adgroup" && data.initial_data && data.initial_data.campaign) {
            changelog_data.campaign = data.initial_data.campaign
        }

        if (data.level === "ad" && data.initial_data) {
            changelog_data.item_name = data.initial_data.name
            changelog_data.adgroup = data.initial_data.adgroup_id
            changelog_data.campaign = data.initial_data.campaign_id
        }

        if (data.level === "keyword") {
            if (data.keyword_level) {
                changelog_data.keyword_level = data.keyword_level
            }
            if (data.keyword_item_name) {
                changelog_data.item_name = data.keyword_item_name
            }
            if (data.channel) {
                changelog_data.channel = data.channel
            }
        }

        // METRICS ALWAYS READDED WHEN EDITING, SO IF THEY ARE THE SAME, REMOVE METRICS FROM CHANGELOG
        let metrics = changelog_data.changes.find(change => change.object_type === 'metrics')
        if (metrics) {
            let new_reduced = metrics.new_value.reduce((acc, curr) => {
                acc[curr.name] = curr.value
                return acc
            }, {})

            if (deepEqual(metrics.old_value, new_reduced)) {
                changelog_data.changes = changelog_data.changes.filter(change => change.object_type !== 'metrics');
            }
        }

        // console.log("CHANGELOG DATA", changelog_data)
        console.log("This fx still uses old changelog endpoint - don't remove yet")

        let options = apiRegister.options(tokenRegister.get(), 'POST', changelog_data);
        let url = apiRegister.url.api + "/v3/adcredo/createChangelog";
        return apiRegister.call(options, url);
    } catch (error) {
        console.log("Error creating changelog: ", error)
    }

}

function deepEqual(a, b) {
    if (a === b) return true;

    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) return false;

    let keysA = Object.keys(a), keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
    }

    return true;
}

export { createChangelog }