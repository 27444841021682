import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle, Polygon
} from "react-google-maps";
import Dropdown from "../../components/dropdown";
import {calls} from "./calls";
import IconFacebook from "../../components/icons/facebook";
import IconGoogle from "../../components/icons/google";
import RegionsNewMap from "../../components/regions-new-map";
import Loader from "../../components/loader";
import Switch from "react-ios-switch";

class UserRegionsNewItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            region: {},
            loading: false,
            loading_text: "Loading country regions",
            center: {lat: 40.64, lng: -73.96},
            zoom: 9,
            countries: [],
            country: {},
            countries_search: "",
            client_search: "",
            calls: {},
            selected_channels: {},
            locations: [],
            clients: [],
            client: {},
            item: {},
            places: []
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            item: this.props.item?this.props.item:{},
            country: this.props.country?this.props.country:{},
            client: this.props.client?this.props.client:{}
        }, () => {
            this.functions.region();
        })
    }

    functions = {
        region: () => {
            if(!this.state.item.paths){
                calls.getInternalRegion({region: this.state.item.id}).then((response) => {
                    this.state.item.paths = response.data.paths;
                    this.setState({
                        item: this.state.item
                    }, () => {
                        this.props.onUpdate(this.state.item);
                    });
                }, (error) => {

                });
            }
        },
        regions: (item, search) => {
            if (search !== "") {
                if (!item.calls) {
                    item.calls = {};
                }
                if (!item.calls[search]) {
                    let call = {
                        string: search,
                        loading: true,
                        error: false,
                        regions: {}
                    };
                    item.calls[search] = call;
                    this.setState({
                        item: item
                    }, () => {
                        let data = {
                            client: this.state.client.id,
                            query: search,
                            country: item.country?item.country.code:this.state.country.code
                        };
                        calls.getRegions(data).then((response) => {
                            if (item.calls[search]) {
                                item.calls[search].regions = response.data;
                                item.calls[search].loading = false;
                            }
                            this.setState({
                                item: item
                            })
                        }, (error) => {
                            if (item.calls[search]) {
                                item.calls[search].loading = false;
                                item.calls[search].error = true;
                            }
                            this.setState({
                                item: item
                            })
                        });
                    });
                }
            }
        },
        update: (item) => {
            item.error = false;
            let channels = null;
            if (item.channels) {
                channels = JSON.parse(JSON.stringify(item.channels));
                for (let key in channels) {
                    channels[key] = {
                        id: channels[key].id,
                        channel: key,
                        name: channels[key].name
                    }
                }
            }
            let object = {
                id: item._id,
                name: item.name,
                country: this.state.country.code,
                country_name: this.state.country.name,
                level: this.renders.level() ? this.renders.level().value : null,
                status: this.renders.status() ? this.renders.status().value : null,
                //paths: item.paths,
                channels: channels
            };
            item.errors = [];
            if (!item.name || item.name === "") {
                item.error = true;
                item.errors.push("Title is missing");
            }
            if (!channels || Object.keys(channels).length < 1) {
                item.error = true;
                item.errors.push("Select platform regions");
            }
            if (!object.level) {
                item.error = true;
                item.errors.push("Select level");
            }
            if (!object.status) {
                item.error = true;
                item.errors.push("Select status");
            }
            if (!this.state.loading && !item.error) {
                this.setState({
                    loading: true,
                    loading_text: "Updating region"
                }, () => {
                    calls.updateRegion(object).then((response) => {
                        item.created = true;
                        item.open = false;
                        this.setState({
                            item: item,
                            loading: false
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            } else {
                this.setState({
                    item: item
                });
            }
        },
        remove: () => {
            this.setState({
                loading: true,
                loading_text: "Removing region"
            }, () => {
                calls.removeRegion({region: this.state.item.id}).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onRemove(this.state.item.id);
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    renders = {
        regions: (item) => {
            let regions = [];
            try {
                for (let key in item.calls[item.search].regions) {
                    item.calls[item.search].regions[key] = item.calls[item.search].regions[key].map((item) => {
                        item.channel = key;
                        if (key === 'facebook') {
                            item.id = item.key;
                        }
                        return item;
                    });
                    regions = regions.concat(item.calls[item.search].regions[key]);
                }
            } catch (e) {
            }
            return regions;
        },
        sortRegions: (region, array) => {
            let match = [];
            let substring = [];
            let other = [];
            array.map((item) => {
                if (item.name.toLowerCase() === region.search.toLowerCase()) {
                    match.push(item);
                } else if (item.name.toLowerCase().indexOf(region.search.toLowerCase()) !== -1) {
                    item.index = item.name.length - region.search.length;
                    substring.push(item);
                } else {
                    other.push(item);
                }
            });
            substring.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
            let new_array = match.concat(substring);
            return new_array;
        },
        paths: () => {
            try{
                let item = JSON.parse(JSON.stringify(this.state.item));
                item.paths = item.paths.map((item) => {
                    return {paths: item, include: true};
                });
                return item;
            }catch (e) {

                return null
            }
        },
        level: () => {
            if(this.state.item.level === '1'){
                return  {name: 'level 1 (area)', value: 1};
            }else if(this.state.item.level === '2'){
                return {name: 'level 2 (city)', value: 2}
            }else if(this.state.item.level === '3'){
                return {name: 'level 3 (county/state)', value: 3}
            }else if(this.state.item.level === '4'){
                return  {name: 'level 4 (country)', value: 4}
            }else{
                return this.state.item.level
            }
        },
        status: () => {
            if(this.state.item.status === 'active'){
                return {name: 'Active', value: 'active'};
            }else if(this.state.item.status === 'disabled'){
                return {name: 'Disabled', value: 'disabled'}
            }else{
                return this.state.item.status
            }
        }
    };

    render() {
        return (
            <div className="Region-item-bottom">
                {
                    this.state.loading &&
                    <Loader
                        title={this.state.loading_text}
                    />
                }
                <div style={{paddingTop: '5px'}} className="Section">
                    <div className="Region-item-three">
                        <Input
                            noTitle={false}
                            title={'Title'}
                            white={true}
                            type={'text'}
                            error={this.state.item.error && this.state.item.name === ""}
                            value={this.state.item.name}
                            size={'full'}
                            onChange={(event) => {
                                this.state.item.name = event.target.value;
                                this.setState({
                                    item: this.state.item
                                })
                            }}
                        />
                    </div>
                    <div className="Region-item-three">
                        <Dropdown
                            filter={false}
                            searchFilter={false}
                            search={false}
                            white={true}
                            title={'Select level'}
                            error={this.state.item.error && !this.state.item.level}
                            size={'full'}
                            placeholder={"Click here"}
                            options={[
                                {name: 'level 1 (area)', value: 1},
                                {name: 'level 2 (city)', value: 2},
                                {name: 'level 3 (county/state)', value: 3},
                                {name: 'level 4 (country)', value: 4}
                            ]}
                            value={this.renders.level()}
                            onChange={(value) => {
                                this.state.item.level = value;
                                this.setState({
                                    item: this.state.item
                                })
                            }}
                        />
                    </div>
                    <div className="Region-item-three">
                        <Dropdown
                            filter={false}
                            searchFilter={false}
                            search={false}
                            white={true}
                            title={'Select status'}
                            error={this.state.item.error && !this.state.item.status}
                            size={'full'}
                            placeholder={"Click here"}
                            options={[
                                {name: 'Active', value: 'active'},
                                {name: 'Disabled', value: 'disabled'}
                            ]}
                            value={this.renders.status()}
                            onChange={(value) => {
                                this.state.item.status = value;
                                this.setState({
                                    item: this.state.item
                                })
                            }}
                        />
                    </div>
                </div>
                {
                    this.state.item.channels && Object.keys(this.state.item.channels).length > 0 &&
                    <div className="Section">
                        <div className="Section-Inner">
                            <div className="Section-InnerTitle">
                                Selected platform regions
                            </div>
                            <div className="Region-Channel-inner Region-Channel-inner-selected">
                                {
                                    Object.keys(this.state.item.channels).map((channel) => {
                                        return (
                                            <div className="Region-Channel-inner-item">
                                                {this.state.item.channels[channel].name}
                                                <b style={{
                                                    marginLeft: '5px',
                                                    textTransform: 'capitalize'
                                                }}>{this.state.item.channels[channel].type}</b>
                                                {
                                                    this.state.item.channels[channel].channel === 'facebook' &&
                                                    <div style={{marginLeft: '7px'}}>
                                                        <IconFacebook noMargin={true}/>
                                                    </div>
                                                }
                                                {
                                                    this.state.item.channels[channel].channel === 'google' &&
                                                    <div style={{marginLeft: '7px'}}>
                                                        <IconGoogle noMargin={true}/>
                                                    </div>
                                                }
                                                <div style={{flex: '1', height: '100%'}}>

                                                </div>
                                                <div onClick={() => {
                                                    delete this.state.item.channels[channel];
                                                    this.setState({
                                                        item: this.state.item
                                                    });
                                                }} className="Region-Channel-inner-item-remove">
                                                    <FeatherIcon color={"#333333"} size={23} icon="trash"/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="Section">
                    <div className="region-item-suggestions">
                        {
                            <div style={{paddingTop: '0px'}} className="Section">
                                <Input
                                    noTitle={false}
                                    title={'Search platform regions'}
                                    type={'text'}
                                    white={true}
                                    error={this.state.item.error && !this.state.item.channels}
                                    value={this.state.item.search}
                                    size={'full'}
                                    onChange={(event) => {
                                        this.state.item.search = event.target.value;
                                        this.setState({
                                            item: this.state.item
                                        });
                                        this.functions.regions(this.state.item, event.target.value);
                                    }}
                                />
                            </div>
                        }
                        {
                            <div className="Section">
                                <div className="Region-Container">
                                    <div className="Region-Channel">
                                        {
                                            <div className="Region-Channel-inner">
                                                {
                                                    this.renders.sortRegions(this.state.item, this.renders.regions(this.state.item)).map((inner_item) => {
                                                        return (
                                                            <div onClick={() => {
                                                                this.state.item.channels[inner_item.channel] = inner_item;
                                                                this.setState({
                                                                    item: this.state.item
                                                                });
                                                            }} className="Region-Channel-inner-item">
                                                                {inner_item.name}
                                                                <b style={{
                                                                    marginLeft: '5px',
                                                                    textTransform: 'capitalize'
                                                                }}>{inner_item.type}</b>
                                                                {
                                                                    inner_item.channel === 'facebook' &&
                                                                    <div style={{marginLeft: '7px'}}>
                                                                        <IconFacebook noMargin={true}/>
                                                                    </div>
                                                                }
                                                                {
                                                                    inner_item.channel === 'google' &&
                                                                    <div style={{marginLeft: '7px'}}>
                                                                        <IconGoogle noMargin={true}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.item.calls && this.state.item.calls[this.state.item.search] && this.state.item.calls[this.state.item.search].loading &&
                                            <div className="Region-Channel-loader">
                                                Loading regions
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        <div className="region-item-map">
                            {
                                this.renders.paths() &&
                                <div style={{paddingTop: '0px'}} className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Map
                                        </div>
                                        <RegionsNewMap
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?libraries=drawing&key=AIzaSyAOpL-2YHBEYSGq6cvFshbk9AidtmdrznU"
                                            loadingElement={<div style={{height: `100%`, width: '100%'}}/>}
                                            containerElement={<div style={{
                                                height: `400px`,
                                                width: '100%',
                                                borderRadius: '3px',
                                                overflow: 'hidden',
                                                border: '1px solid #e7e7e7'
                                            }}/>}
                                            place={this.renders.paths()}
                                            mapElement={<div style={{height: `100%`, width: '100%'}}/>}
                                            updatePlace={(value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                !this.renders.paths() &&
                                <div className="region-item-map-loader">
                                    Loading map
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    this.state.item.error && this.state.item.errors &&
                    <div className="SectionError">
                        {
                            this.state.item.errors.map((item) => {
                                return (
                                    <div className="Section">{item}</div>
                                )
                            })
                        }
                    </div>
                }
                {
                    <div className="Section">
                        <div onClick={() => {
                            this.functions.update(this.state.item)
                        }} className="Button">
                            Update region
                        </div>
                        <div onClick={() => {
                            this.functions.remove()
                        }} className="Button ButtonRed">
                            Remove region
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default UserRegionsNewItem;
