import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import callToActionLinkedin from '../../assets/json/call_to_action_linkedin';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import { SlideDown } from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesFacebookImageMobile from "../advertising-creatives-facebook-image-mobile";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import AdvertisingCreativesAdsItemCreativeThumbnail from "../advertising-creatives-ads-item-creative-thumbnail";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import AdvertisingCreativesAdsItemCreativeCarousel from "../advertising-creatives-ads-item-creative-carousel";
import AdvertisingCreativesInstagramCarousel from "../advertising-creatives-instagram-carousel";
import AdvertisingCreativesFacebookCarouselNew from "../advertising-creatives-facebook-carousel-new";
import { calls } from "./calls";
import { campaignRegister } from "../../services/campaignRegister";
import { eventRegister } from "../../services/eventRegister";
import IconLinkedin from "../icons/linkedin";
// import PreviewFacebookFeed from '../../previews/facebook/feed';


class AdvertisingCreativesAdsItemCreative extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            index: this.props.index,
            channels: this.props.channels,
            variations: this.props.variations,
            content: this.props.content,
            client: this.props.client,
            creatives: {},
            conversion: {},
            site: this.props.site,
            error: this.props.error,
            channel: null,
            call_to_actions: callToAction,
            call_to_actions_linkedin: callToActionLinkedin,
            copy_from: {},
            languages: [],
            products: [],
            array: [],
            catalog: {},
            campaign: {},
            adset: {},
            catalogs: [],
            dynamic: false,
            selected: null,
            errors: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : null,
            index: nextProps.index,
            open: nextProps.open,
            adset: nextProps.adset,
            campaign: nextProps.campaign,
            client: nextProps.client,
            site: nextProps.site,
            variations: nextProps.variations,
            channels: nextProps.channels,
            catalogs: nextProps.catalogs,
            dynamic: nextProps.dynamic,
            creatives: nextProps.creatives,
            languages: nextProps.languages ? nextProps.languages : [],
            error: nextProps.error,
            errors: nextProps.errors ? nextProps.errors : []
        }, () => {
            if (this.state.channels && !this.state.item.selected_channel) {
                let keys = Object.keys(this.state.channels).filter((key) => {
                    return key === 'facebook' || key === 'instagram'
                });
                if (keys.length > 0) {
                    this.state.item.selected_channel = keys[0];
                    this.setState({
                        item: this.state.item
                    })
                }
            }
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            content: this.props.content,
            channels: this.props.channels,
            item: this.props.item ? this.props.item : null,
            index: this.props.index,
            client: this.props.client,
            site: this.props.site,
            campaign: this.props.campaign,
            adset: this.props.adset,
            catalogs: this.props.catalogs,
            dyamic: this.props.dyamic,
            creatives: this.props.creatives,
            languages: this.props.languages ? this.props.languages : [],
            error: this.props.error,
            errors: this.props.errors ? this.props.errors : []
        }, () => {

            if (this.state.channels) {
                let keys = Object.keys(this.state.channels).filter((key) => {
                    return key === 'facebook' || key === 'instagram'
                });
                if (keys.length > 0) {
                    this.state.item.selected_channel = keys[0];
                    this.setState({
                        item: this.state.item
                    })
                }
            }

            if (!(this.state.creatives && Array.isArray(this.state.creatives.facebook_pages))) {
                this.functions.pages();
            }

            if (this.state.item.facebook_page) {
                this.functions.events();
            }

            if (this.state.item.post && !(this.state.creatives && Array.isArray(this.state.creatives.instagram_business_accounts))) {
                this.functions.businessInstagramAccounts();
            }

            this.setState({
                content: this.state.content
            }, () => {
                this.props.onChange(this.state.content);
                this.functions.data();
            })

        });
    }

    init = {};

    functions = {
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {}
            })
        },
        remove: () => {
            let content = this.state.content.filter((item) => {
                return item.id !== this.state.selected
            });
            this.setState({
                warning_modal: false,
                selected: null
            }, () => {
                this.props.onChange(content);
            })
        },
        new: () => {
            this.state.item.edit = false;
            let new_carousel = JSON.parse(JSON.stringify(this.state.item));
            new_carousel.id = Math.floor((Math.random() * 9999999999) + 1);
            if (this.state.item.carousel) {
                new_carousel.title = 'Carousel ' + (this.state.content.length + 1);
            } else {
                new_carousel.internal_adset = {
                    name: 'Group ' + (this.state.content.length + 1) + ' - Dynamic',
                    value: (this.state.content.length + 1),
                    type: 'dynamic'
                };
                new_carousel.title = 'Dynamic ' + (this.state.content.length + 1);
            }
            new_carousel.edit = true;
            this.state.content.push(new_carousel);
            this.setState({
                content: this.state.content,
            }, () => {
                this.props.onChange(this.state.content);
            })
        },
        productsets: (catalog_id) => {
            this.setState({
                loading_product_sets: true
            }, () => {
                calls.getProductSets({
                    channels: JSON.stringify({ facebook: true }),
                    client: this.state.client.id,
                    catalog: catalog_id
                }).then((response) => {
                    this.state.creatives.product_sets = response.data;
                    this.setState({
                        creatives: this.state.creatives,
                        loading_product_sets: false
                    }, () => {
                        this.functions.save('creatives');
                    })
                }, (error) => {
                    this.state.creatives.catalogs = [];
                    this.setState({
                        creatives: this.state.creatives,
                        loading_product_sets: false
                    }, () => {
                        this.functions.save('creatives');
                    })
                });
            })
        },
        products: (productset_id) => {
            this.setState({
                loading_products: true
            }, () => {
                calls.getProducts({
                    channels: JSON.stringify({ facebook: true }),
                    client: this.state.client.id,
                    productset: productset_id
                }).then((response) => {
                    this.state.creatives.products = response.data;
                    for (let i = 0; i < response.data.length; i++) {
                        let id = Math.floor((Math.random() * 9999999999) + 1);
                        let slide = {
                            id: id,
                            headline: response.data[i].name,
                            description: response.data[i].price,
                            website: "",
                            creative: {
                                url: response.data[i].image_url,
                                format: "",
                                ratio: "1:1",
                                channels: {}
                            },
                            loading: false,
                            error: false,
                            image: true,
                            dynamic: true,
                            call_to_action: { name: 'No call to action', value: 'NO_BUTTON' },
                        };
                        this.state.item.items.push(slide);
                        /*
                        for(let v = 0; v < this.state.languages.length; v++){
                            if(this.state.item.languages[this.state.languages[v].code]){
                                let slide = {
                                    id: id,
                                    headline: response.data[i].name,
                                    description:  response.data[i].price,
                                    website: "",
                                    creative: {
                                        url: response.data[i].image_url,
                                        format: "",
                                        ratio: "1:1",
                                        channels: {}
                                    },
                                    loading: false,
                                    error: false,
                                    image: true,
                                    dynamic: true,
                                    call_to_action: {name: 'No call to action', value: 'NO_BUTTON'},
                                };
                                this.state.item.languages[this.state.languages[v].code].items.push(slide);
                            }
                        }
                        */
                    }
                    this.setState({
                        creatives: this.state.creatives,
                        loading_products: false
                    }, () => {
                        this.functions.save('creatives');
                    })
                }, (error) => {
                    this.state.creatives.catalogs = [];
                    this.setState({
                        creatives: this.state.creatives,
                        loading_products: false
                    }, () => {
                        this.functions.save('creatives');
                    })
                });
            })
        },
        pages: () => {
            if (!this.state.loading_pages) {
                this.setState({
                    loading_pages: true
                }, () => {
                    calls.getPages({
                        client: this.state.client.id
                    }).then((response) => {

                        this.state.creatives.facebook_pages = response.data;

                        this.state.creatives.facebook_pages = this.state.creatives.facebook_pages.map((item) => {
                            item.picture = item.picture.data.url;
                            return item;
                        });

                        console.log(this.state.creatives.facebook_pages);

                        this.setState({
                            creatives: this.state.creatives,
                            loading_pages: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_pages: false
                        })
                    });

                })
            }
        },
        events: () => {
            if (!this.state.loading_events) {
                this.setState({
                    loading_events: true
                }, () => {
                    calls.getEvents({
                        client: this.state.client.id,
                        page: (this.state.item.facebook_page ? this.state.item.facebook_page.id : null)
                    }).then((response) => {
                        this.state.creatives.facebook_events = response.data
                        this.setState({
                            creatives: this.state.creatives,
                            loading_events: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_events: false
                        })
                    });
                })
            }
        },
        createPageToken: (facebook_page) => {
            if (!this.state.loading_page_token) {
                this.setState({
                    loading_page_token: true
                }, () => {
                    calls.createPageToken({
                        client: this.state.client.id,
                        page: (facebook_page ? facebook_page.id : null)
                    }).then((response) => {
                        this.functions.pageInstagramAccounts(response.data);
                    }, (error) => {
                        this.setState({
                            loading_page_token: false
                        })
                    });
                })
            }
        },
        businessInstagramAccounts: () => {
            if (!this.state.loading_instagram_accounts) {
                this.setState({
                    loading_instagram_accounts: true
                }, () => {
                    calls.getBusinessInstagramAccounts({
                        client: this.state.client.id
                    }).then((response) => {
                        this.state.creatives.instagram_business_accounts = response.data;
                        this.state.creatives.instagram_business_accounts = this.state.creatives.instagram_business_accounts.map((item) => {
                            item.name = item.username;
                            item.picture = item.profile_picture_url;
                            return item;
                        });
                        this.setState({
                            creatives: this.state.creatives,
                            loading_instagram_accounts: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_instagram_accounts: false
                        })
                    });
                })
            }
        },
        pageInstagramAccounts: (page_token) => {
            if (!this.state.loading_page_instagram_accounts) {
                this.setState({
                    loading_page_instagram_accounts: true
                }, () => {
                    calls.getInstagramAccounts({
                        client: this.state.client.id,
                        page_token: page_token,
                        page: (this.state.item.facebook_page ? this.state.item.facebook_page.id : null)
                    }).then((response) => {
                        this.state.creatives.instagram_page_accounts = response.data;
                        this.state.creatives.instagram_page_accounts = this.state.creatives.instagram_page_accounts.map((item) => {
                            item.picture = item.profile_pic;
                            return item;
                        })
                        this.setState({
                            creatives: this.state.creatives,
                            loading_page_instagram_accounts: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_page_instagram_accounts: false
                        })
                    });
                })
            }
        },
        instagramMedia: () => {
            if (!this.state.loading_instagram_media) {
                this.setState({
                    loading_instagram_media: true
                }, () => {
                    calls.getInstagramMedia({
                        client: this.state.client.id,
                        instagram_account: (this.state.item.instagram_account ? this.state.item.instagram_account.id : null)
                    }).then((response) => {
                        this.state.creatives.instagram_media = response.data;
                        this.setState({
                            creatives: this.state.creatives,
                            loading_instagram_media: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_instagram_media: false
                        })
                    });
                })
            }
        },
        facebookMedia: () => {
            if (!this.state.loading_facebook_media) {
                this.setState({
                    loading_facebook_media: true
                }, () => {
                    calls.getFacebookMedia({
                        client: this.state.client.id,
                        page: (this.state.item.facebook_page ? this.state.item.facebook_page.id : null)
                    }).then((response) => {
                        this.state.creatives.facebook_media = response.data;
                        this.setState({
                            creatives: this.state.creatives,
                            loading_facebook_media: false
                        }, () => {
                            this.functions.save('creatives');
                        })
                    }, (error) => {
                        this.setState({
                            loading_facebook_media: false
                        })
                    });
                })
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    validators = {};

    renders = {
        emoji: (value) => {
            try {
                const emojisArray = toArray(value);
                const newValue = emojisArray.reduce((previous, current) => {
                    if (typeof current === "string") {
                        return previous + current;
                    }
                    return previous + current.props.children;
                }, "");
                return newValue;
            } catch (e) {
                return value
            }
        },
        tabs: () => {
            let keys = [];
            for (let key in this.state.channels) {
                if (this.state.channels[key] && (key === 'facebook' || key === 'linkedin')) {
                    if (key === "facebook" && this.renders.instagram()) {
                        keys.push("instagram");
                    }
                    keys.push(key);
                }
            }
            return keys;
        },
        page: (channel) => {
            try {
                let page = {};
                if (channel === "facebook") {
                    if (this.state.item.facebook_page) {
                        page = {
                            name: this.state.item.facebook_page.name,
                            image: this.state.item.facebook_page.picture
                        };
                    } else {
                        page = {
                            name: this.state.client[channel].facebookPageName,
                            image: this.state.client[channel].facebookPageImage
                        };
                    }
                } else if (channel === "instagram") {
                    if (this.state.item.instagram_account) {
                        page = {
                            name: this.state.item.instagram_account.name,
                            image: this.state.item.instagram_account.picture
                        };
                    } else {
                        page = {
                            name: this.state.client['facebook'].facebookPageName,
                            image: this.state.client['facebook'].facebookPageImage
                        };
                    }
                } else if (channel === "linkedin") {
                    page = {
                        name: this.state.client[channel].pageName,
                        image: this.state.client[channel].pageImage
                    };
                }
                //console.log(this.state.client);
                //console.log(page);
                return page
            } catch (e) {
                return {}
            }
        },
        string: (item) => {
            try {
                return item.length
            } catch (e) {
                return 0
            }
        },
        item: (item) => {
            try {
                let array = [];
                array.push(item);
                return array;
            } catch {
                return []
            }
        },
        property: (option) => {
            try {
                if (option !== 'call_to_action' && option !== 'conversion_dynamic_days' && option !== 'conversion_dynamic_event') {
                    return this.state.item[option] ? this.state.item[option] : ""
                } else {
                    return this.state.item[option] ? this.state.item[option] : {}
                }
            } catch {
                return ""
            }
        },
        vertical: () => {
            try {
                return this.state.item.creative.ratio === '9:16';
            } catch (e) {
            }
        },
        copyFrom: () => {
            try {
                if (this.state.item.video || this.state.item.image) {
                    let images = this.state.creatives.image_ads.filter((item) => {
                        return item.id !== this.state.item.id
                    }).map((item) => {
                        item.name = item.title;
                        return item
                    });
                    let videos = this.state.creatives.video_ads.filter((item) => {
                        return item.id !== this.state.item.id
                    }).map((item) => {
                        item.name = item.title;
                        return item
                    });
                    return images.concat(videos);
                } else if (this.state.item.carousel) {
                    return []
                } else {
                    return []
                }
            } catch (e) {
                //console.log(e);
                return []
            }
        },
        index: (item) => {
            let index = 0;
            this.state.content.map((inner_item, inner_index) => {
                if (item.id === inner_item.id) {
                    index = inner_index;
                }
            });
            return index;
        },
        catalogs: () => {
            try {
                return this.state.creatives.catalogs ? this.state.creatives.catalogs : [];
            } catch (e) {
                return [];
            }
        },
        productsets: () => {
            try {
                return this.state.creatives.product_sets ? this.state.creatives.product_sets : [];
            } catch (e) {
                return [];
            }
        },
        products: () => {
            try {
                return this.state.creatives.products ? this.state.creatives.products : [];
            } catch (e) {
                return [];
            }
        },
        days: () => {
            let days = [];
            for (let i = 1; i < 181; i++) {
                if (i === 1) {
                    days.push({ name: i + ' day', value: i });
                } else {
                    days.push({ name: i + ' days', value: i });
                }
            }
            return days;
        },
        instagram: () => {
            try {
                return !this.renders.eventFocus() && (!this.state.adset.targeting.placement || (this.state.adset && this.state.adset.targeting && this.state.adset.targeting.placement && (this.state.adset.targeting.placement.all || this.state.adset.targeting.placement.feed_instagram || this.state.adset.targeting.placement.story_instagram || this.state.adset.targeting.placement.explore)));
            } catch (e) {
                return false;
            }
        },
        postType: () => {
            try {
                if (this.state.item.facebook_post.attachments.data[0].type == "video_inline") {
                    return "video";
                } else {
                    return "image";
                }
            } catch (error) {
                return false;
            }
        },
        eventFocus: () => {
            try {
                return this.state.campaign.budget.facebook_instagram.focus.value == "EVENT_RESPONSES";
            } catch (error) {
                return false;
            }
        },
        appInstallFocus: () => {
            try {
                return this.state.campaign.budget.facebook_instagram.focus.value == "APP_INSTALLS";
            } catch (error) {
                return false;
            }
        }
    };

    render() {

        return (
            <div className="Creative-ads-item-bottom">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove"
                    type="warning"
                    text={'Are you sure ?'}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            warning_modal: false
                        }, () => {
                            this.props.onRemove(this.state.item.id);
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            warning_modal: false
                        });
                    }}
                />
                {
                    this.state.item &&
                    <div style={{ backgroundColor: "#fcfcfc" }} className="flex flex-row border-t">
                        <div className="Creative-ads-item-bottom-inner-left relative">
                            <div className="flex flex-col items-center mt-4 absolute z-10">
                                {
                                    this.renders.tabs().filter((item) => {
                                        return !this.state.dynamic || (this.state.dynamic && item !== "linkedin")
                                    }).map((key) => {
                                        return (
                                            <div onClick={() => {
                                                this.state.item.selected_channel = key;
                                                this.setState({
                                                    content: this.state.content
                                                });
                                            }}>
                                                {key === 'instagram' &&
                                                    <div className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent w-12 h-12 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.item.selected_channel ? 'bg-instagram-100 text-instagram-500 border-instagram-500' : ''}`}>
                                                        <IconInstagram />
                                                    </div>
                                                }
                                                {key === 'facebook' &&
                                                    <div className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent w-12 h-12 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.item.selected_channel ? 'bg-facebook-100 text-facebook-500 border-facebook-500' : ''}`}>
                                                        <IconFacebook />
                                                    </div>
                                                }
                                                {key === 'google' && false &&
                                                    <div className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent w-12 h-12 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.item.selected_channel ? 'bg-google-100 text-google-500 border-google-500' : ''}`}>
                                                        <IconGoogle />
                                                    </div>
                                                }
                                                {key === 'linkedin' &&
                                                    <div className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent w-12 h-12 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.item.selected_channel ? 'bg-linkedin-100 text-linkedin-500 border-linkedin-500' : ''}`}>
                                                        <IconLinkedin />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="Creative-ads-item-bottom-inner-left-inner">
                                {
                                    (this.state.item.selected_channel === 'facebook' || this.state.item.selected_channel === 'linkedin') && (this.state.item.image || this.state.item.event || (this.state.item.post && ((this.state.item.facebook_post && this.renders.postType() === "image") || (this.state.item.instagram_post && this.state.item.instagram_post.media_type === "IMAGE")))) && !this.renders.vertical() &&
                                    <AdvertisingCreativesFacebookImage
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        creatives={this.renders.item(this.state.item)}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        event={this.state.item.event}
                                        post={this.state.item.post}
                                        linkedin={this.state.item.selected_channel === 'linkedin'}
                                    />
                                }
                                {
                                    (this.state.item.selected_channel === 'facebook' || this.state.item.selected_channel === 'linkedin') && (this.state.item.video || (this.state.item.post && ((this.state.item.facebook_post && this.renders.postType() === "video") || (this.state.item.instagram_post && this.state.item.instagram_post.media_type === "VIDEO")))) && !this.renders.vertical() &&
                                    <AdvertisingCreativesFacebookVideo
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        creatives={this.renders.item(this.state.item)}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        post={this.state.item.post}
                                        linkedin={this.state.item.selected_channel === 'linkedin'}
                                    />
                                }
                                {
                                    this.state.item.selected_channel === 'instagram' && (this.state.item.image || (this.state.item.post && ((this.state.item.facebook_post && this.renders.postType() === "image") || (this.state.item.instagram_post && this.state.item.instagram_post.media_type === "IMAGE")))) && !this.renders.vertical() &&
                                    <AdvertisingCreativesInstagramImage
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        creatives={this.renders.item(this.state.item)}
                                        language={this.state.item.selected_language}
                                        post={this.state.item.post}
                                        noContainer={true}
                                    />
                                }
                                {
                                    this.state.item.selected_channel === 'instagram' && (this.state.item.video || (this.state.item.post && ((this.state.item.facebook_post && this.renders.postType() === "video") || (this.state.item.instagram_post && this.state.item.instagram_post.media_type === "VIDEO")))) && !this.renders.vertical() &&
                                    <AdvertisingCreativesInstagramVideo
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        creatives={this.renders.item(this.state.item)}
                                        language={this.state.item.selected_language}
                                        post={this.state.item.post}
                                        noContainer={true}
                                    />
                                }
                                {
                                    (this.state.item.selected_channel === 'facebook' || this.state.item.selected_channel === 'linkedin') && this.state.item.carousel && !this.renders.vertical() &&
                                    <AdvertisingCreativesFacebookCarouselNew
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        body={this.state.item}
                                        content={this.state.item}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        onChange={(value) => {
                                            this.state.item.items = value;
                                            this.setState({
                                                content: this.state.content
                                            }, () => {
                                                this.props.onChange(this.state.content);
                                            });
                                        }}
                                        linkedin={this.state.item.selected_channel === 'linkedin'}
                                    />
                                }
                                {
                                    this.state.item.selected_channel === 'facebook' && this.state.item.dynamic && !this.renders.vertical() &&
                                    <AdvertisingCreativesFacebookCarousel
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        body={this.state.item}
                                        dynamic={true}
                                        content={this.state.item}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        onChange={(value) => {
                                            this.state.item.items = value;
                                            this.setState({
                                                content: this.state.content
                                            }, () => {
                                                this.props.onChange(this.state.content);
                                            });
                                        }}
                                    />
                                }
                                {
                                    this.state.item.selected_channel === 'instagram' && this.state.item.carousel && !this.renders.vertical() &&
                                    <AdvertisingCreativesInstagramCarousel
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        body={this.state.item}
                                        content={this.state.item}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        showArrows={true}
                                        onChange={(value) => {
                                            this.state.item.items = value;
                                            this.setState({
                                                content: this.state.content
                                            }, () => {
                                                this.props.onChange(this.state.content);
                                            });
                                        }}
                                    />
                                }
                                {
                                    this.state.item.selected_channel === 'instagram' && this.state.item.dynamic && !this.renders.vertical() &&
                                    <AdvertisingCreativesInstagramCarousel
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        body={this.state.item}
                                        content={this.state.item}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                        showArrows={true}
                                        dynamic={true}
                                        onChange={(value) => {
                                            this.state.item.items = value;
                                            this.setState({
                                                content: this.state.content
                                            }, () => {
                                                this.props.onChange(this.state.content);
                                            });
                                        }}
                                    />
                                }
                                {
                                    this.renders.vertical() &&
                                    <AdvertisingCreativesVertical
                                        page={this.renders.page(this.state.item.selected_channel)}
                                        creatives={this.renders.item(this.state.item)}
                                        language={this.state.item.selected_language}
                                        noContainer={true}
                                    />
                                }
                            </div>
                        </div>
                        <div style={{ minHeight: "600px" }} className="Creative-ads-item-bottom-inner-right">
                            {
                                <div className="pt-1" style={{ width: '100%' }}>
                                    {
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Input
                                                type={'text'}
                                                white={true}
                                                title={"Ad name"}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item] && (!this.state.dynamic || (this.state.dynamic && item !== "linkedin"))
                                                })}
                                                error={this.renders.string(this.renders.property('title')) < 1}
                                                value={this.renders.property('title')}
                                                size={'full'}
                                                onChange={(event) => {
                                                    console.log(this.state.item);
                                                    this.state.item.title = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_title'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_title'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        !this.state.item.event && !this.state.item.post &&
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box', marginTop: "10px" }} className="flex flex-row">
                                            <div className="flex flex-1 mr-2">
                                                <Dropdown
                                                    filter={true}
                                                    search={true}
                                                    white={true}
                                                    title={'Facebook page'}
                                                    size={'full'}
                                                    icons={true}
                                                    channels={[]}
                                                    placeholder={this.state.loading_pages ? "Loading ..." : (Array.isArray(this.state.creatives.facebook_pages) && this.state.creatives.facebook_pages.length < 1 ? "No pages available" : "Click here")}
                                                    options={Array.isArray(this.state.creatives.facebook_pages) ? this.state.creatives.facebook_pages : []}
                                                    value={this.state.item.facebook_page ? this.state.item.facebook_page : {}}
                                                    onChange={(value) => {
                                                        this.state.item.facebook_page = value;
                                                        this.setState({
                                                            content: this.state.content
                                                        }, () => {
                                                            if (this.state.item.event) {
                                                                this.functions.events();
                                                            } else {
                                                                this.functions.events();
                                                                this.functions.createPageToken(this.state.item.facebook_page);
                                                            }
                                                            this.props.onChange(this.state.content);
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="flex flex-1 ml-2">
                                                <Dropdown
                                                    filter={true}
                                                    search={true}
                                                    white={true}
                                                    title={'Instagram account'}
                                                    size={'full'}
                                                    icons={true}
                                                    channels={[]}
                                                    disabled={!this.state.item.facebook_page}
                                                    loading={this.state.loading_page_instagram_accounts}
                                                    placeholder={this.state.loading_page_instagram_accounts ? "Loading ..." : (Array.isArray(this.state.creatives.instagram_page_accounts) && this.state.creatives.instagram_page_accounts.length < 1 ? "No accounts available" : (this.state.item.facebook_page ? "Click here ..." : "Select facebook page"))}
                                                    options={Array.isArray(this.state.creatives.instagram_page_accounts) ? this.state.creatives.instagram_page_accounts : []}
                                                    value={this.state.item.instagram_account ? this.state.item.instagram_account : {}}
                                                    onChange={(value) => {
                                                        this.state.item.instagram_account = value;
                                                        this.setState({
                                                            content: this.state.content
                                                        }, () => {
                                                            this.props.onChange(this.state.content);
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.dynamic &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={false}
                                                white={true}
                                                title={'Select catalog'}
                                                size={'full'}
                                                icons={true}
                                                channels={["facebook"]}
                                                error={this.state.errors.filter((item) => {
                                                    return item.type === 'ad_catalog'
                                                }).length > 0}
                                                placeholder={this.state.loading_product_sets ? "Loading ..." : (this.renders.catalogs().length < 1 ? "No catalogs available" : "Click here")}
                                                options={this.renders.catalogs()}
                                                value={this.renders.property('catalog')}
                                                onChange={(value) => {
                                                    this.state.item.catalog = value;
                                                    this.state.item.catalog = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                        this.functions.productsets(value.id);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.dynamic &&
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_catalog'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_catalog'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.dynamic && this.state.item.catalog &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={false}
                                                white={true}
                                                title={'Select product set'}
                                                icons={true}
                                                channels={["facebook"]}
                                                size={'full'}
                                                error={this.state.errors.filter((item) => {
                                                    return item.type === 'ad_products'
                                                }).length > 0}
                                                placeholder={this.state.loading_product_sets ? "Loading ..." : (this.renders.productsets().length < 1 ? "No product sets available" : "Click here")}
                                                loading={this.state.loading_product_sets}
                                                options={this.renders.productsets()}
                                                value={this.renders.property('product_set')}
                                                onChange={(value) => {
                                                    this.state.item.product_set = value;
                                                    this.state.item.product_set = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                        this.functions.products(value.id);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.dynamic && this.state.item.catalog &&
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_products'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_products'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.renders.eventFocus() &&
                                        !this.state.item.post &&
                                        ((this.state.dynamic && this.state.item.catalog && this.state.item.product_set) || !this.state.dynamic) &&
                                        (this.state.item.video || this.state.item.image || this.state.item.dynamic) &&
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }} className="Creative-ads-item-bottom-inner-right-item flex flex-row">
                                            {
                                                (this.state.channels.facebook || this.state.channels.instagram) &&
                                                <div className="flex flex-1">
                                                    <Dropdown
                                                        filter={false}
                                                        white={true}
                                                        title={'CTA button (optional)'}
                                                        size={'full'}
                                                        icons={true}
                                                        channels={["facebook"]}
                                                        placeholder={"Call to action"}
                                                        options={this.state.call_to_actions.filter((option) => {
                                                            try {
                                                                if (this.state.item.creative.ratio === '9:16') {
                                                                    return option.vertical
                                                                } else {
                                                                    return true
                                                                }
                                                            } catch (e) {
                                                                return true
                                                            }
                                                        })}
                                                        value={this.renders.property('call_to_action')}
                                                        onChange={(value) => {
                                                            this.state.item.call_to_action = value;
                                                            if (this.state.item.dynamic) {
                                                                for (let i = 0; i < this.state.item.items.length; i++) {
                                                                    this.state.item.items[i].call_to_action = value;
                                                                }
                                                            }
                                                            this.setState({
                                                                content: this.state.content
                                                            }, () => {
                                                                this.props.onChange(this.state.content);
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                (this.state.channels.facebook || this.state.channels.instagram) && (this.state.channels.linkedin && this.state.item.video) &&
                                                <div className="w-4" />
                                            }
                                            {
                                                (this.state.channels.linkedin) && this.state.item.video &&
                                                <div className="flex flex-1">
                                                    <Dropdown
                                                        filter={false}
                                                        white={true}
                                                        title={'CTA button (optional)'}
                                                        size={'full'}
                                                        icons={true}
                                                        channels={["linkedin"]}
                                                        placeholder={"Call to action"}
                                                        options={this.state.call_to_actions_linkedin}
                                                        value={this.renders.property('call_to_action_linkedin')}
                                                        onChange={(value) => {
                                                            this.state.item.call_to_action_linkedin = value;
                                                            this.setState({
                                                                content: this.state.content
                                                            }, () => {
                                                                this.props.onChange(this.state.content);
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.item.video &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <AdvertisingCreativesAdsItemCreativeThumbnail
                                                item={this.state.item}
                                                onChange={(value) => {
                                                    this.state.item = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_thumbnail'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_thumbnail'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        !this.state.item.post &&
                                        !this.state.item.event &&
                                        !this.state.item.dynamic &&
                                        this.state.creatives &&
                                        this.state.item.call_to_action &&
                                        this.state.item.call_to_action.value == "EVENT_RSVP" &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                title={"Select facebook event"}
                                                size={'full'}
                                                filter={false}
                                                white={true}
                                                icons={true}
                                                channels={["facebook"]}
                                                loading={this.state.loading_events}
                                                placeholder={this.state.loading_events ? "Loading events" : (Array.isArray(this.state.creatives.facebook_events) && this.state.creatives.facebook_events.length > 0 ? "Search events ..." : "No events available")}
                                                options={Array.isArray(this.state.creatives.facebook_events) ? this.state.creatives.facebook_events : []}
                                                value={this.state.item.facebook_event ? this.state.item.facebook_event : {}}
                                                onChange={(value) => {
                                                    this.state.item.facebook_event = value;
                                                    this.state.item.headline = value.name;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (this.state.item.video || this.state.item.image) &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Input
                                                sign={!this.renders.eventFocus()}
                                                white={true}
                                                disabled={this.renders.eventFocus()}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}
                                                type={'text'}
                                                rightTitle={!this.renders.eventFocus() && this.state.errors.filter((item) => {
                                                    return item.type === 'ad_headline'
                                                }).length < 1}
                                                rightTitleText={this.state.item.image ? "Copy to images" : "Copy to videos"}
                                                onRightTitleClick={(value) => {
                                                    try {
                                                        this.props.updateTitle(this.state.item.headline);
                                                    } catch (e) { }
                                                }}
                                                error={this.renders.string(this.renders.property('headline')) < 1}
                                                title={"Headline text"}
                                                placeholder={!this.renders.eventFocus() ? 'Recommended max length 30' : 'Select an event'}
                                                value={!this.renders.eventFocus() ? this.renders.emoji(this.renders.property('headline')) : (this.state.item.facebook_event ? this.state.item.facebook_event.name : "")}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.item.headline = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_headline'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_headline'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        ((this.state.dynamic && this.state.item.catalog && this.state.item.product_set) || !this.state.dynamic) && !this.state.item.post && !this.state.item.event &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <TextArea
                                                type={'text'}
                                                tiny={false}
                                                white={true}
                                                sign={false}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}

                                                error={this.renders.string(this.renders.property('body')) < 1}
                                                title={"Body text"}
                                                placeholder={'Recommended max length 125'}
                                                rightTitle={!this.state.item.event}
                                                rightTitleText={this.state.item.image ? "Copy to images" : (this.state.dynamic && this.state.item.catalog && this.state.item.product_set ? "Copy to products" : (this.state.item.carousel ? "Copy to carousels" : "Copy to videos"))}
                                                onRightTitleClick={(value) => {
                                                    try {
                                                        this.props.updateBody(this.state.item.body);
                                                    } catch (e) { }
                                                }}
                                                value={this.renders.emoji(this.renders.property('body'))}
                                                size={(this.state.item.carousel || this.state.item.dynamic || this.state.item.event) ? 'full' : 'half'}
                                                onChange={(event) => {
                                                    this.state.item.body = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                            {
                                                (this.state.item.video || this.state.item.image) &&
                                                <TextArea
                                                    type={'text'}
                                                    tiny={true}
                                                    white={true}
                                                    icons={true}
                                                    channels={Object.keys(this.state.channels).filter((item) => {
                                                        return item !== "google" && this.state.channels[item]
                                                    })}
                                                    title={"Description"}
                                                    value={this.renders.property('description')}
                                                    size={'half'}
                                                    rightTitle={true}
                                                    rightTitleText={this.state.item.image ? "Copy to images" : "Copy to videos"}
                                                    onRightTitleClick={(value) => {
                                                        try {
                                                            this.props.updateDescription(this.state.item.description);
                                                        } catch (e) { }
                                                    }}
                                                    onChange={(event) => {
                                                        this.state.item.description = event.target.value;
                                                        this.setState({
                                                            content: this.state.content
                                                        }, () => {
                                                            this.props.onChange(this.state.content);
                                                        });
                                                    }}
                                                />
                                            }
                                        </div>
                                    }
                                    {
                                        !this.state.item.event &&
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_body'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_body'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.creatives &&
                                        (this.state.item.event || this.state.item.post) &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={true}
                                                search={true}
                                                white={true}
                                                title={'Facebook page'}
                                                size={'full'}
                                                icons={true}
                                                channels={[]}
                                                placeholder={this.state.loading_pages ? "Loading ..." : (Array.isArray(this.state.creatives.facebook_pages) && this.state.creatives.facebook_pages.length < 1 ? "No pages available" : "Click here")}
                                                options={Array.isArray(this.state.creatives.facebook_pages) ? this.state.creatives.facebook_pages : []}
                                                value={this.state.item.facebook_page ? this.state.item.facebook_page : {}}
                                                onChange={(value) => {
                                                    this.state.item.facebook_page = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        delete this.state.item.instagram_account;
                                                        delete this.state.item.instagram_post;
                                                        delete this.state.item.facebook_post;
                                                        delete this.state.item.facebook_event;
                                                        if (this.state.item.event) {
                                                            this.functions.events();
                                                        }
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        !this.state.item.post &&
                                        this.state.creatives &&
                                        this.state.item.event &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={false}
                                                white={true}
                                                title={'Select event'}
                                                size={'full'}
                                                icons={true}
                                                channels={["facebook"]}
                                                placeholder={this.state.loading_events ? "Loading ..." : (Array.isArray(this.state.creatives.facebook_events) && this.state.creatives.facebook_events.length < 1 ? "No events available" : "Click here")}
                                                options={Array.isArray(this.state.creatives.facebook_events) ? this.state.creatives.facebook_events : []}
                                                value={this.state.item.facebook_event ? this.state.item.facebook_event : {}}
                                                onChange={(value) => {
                                                    this.state.item.facebook_event = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.item.event &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <TextArea
                                                type={'text'}
                                                tiny={false}
                                                white={true}
                                                sign={false}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}
                                                error={this.renders.string(this.renders.property('body')) < 1}
                                                title={"Body text"}
                                                placeholder={'Recommended max length 125'}
                                                rightTitle={!this.state.item.event}
                                                rightTitleText={this.state.item.image ? "Copy to images" : (this.state.dynamic && this.state.item.catalog && this.state.item.product_set ? "Copy to products" : (this.state.item.carousel ? "Copy to carousels" : "Copy to videos"))}
                                                onRightTitleClick={(value) => {
                                                    try {
                                                        this.props.updateBody(this.state.item.body);
                                                    } catch (e) { }
                                                }}
                                                value={this.renders.emoji(this.renders.property('body'))}
                                                size={(this.state.item.carousel || this.state.item.dynamic || this.state.item.event) ? 'full' : 'half'}
                                                onChange={(event) => {
                                                    this.state.item.body = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.item.event &&
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_body'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_body'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.item.post &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={false}
                                                white={true}
                                                title={'Select channel'}
                                                size={'full'}
                                                icons={true}
                                                channels={["facebook"]}
                                                placeholder={"Click here ..."}
                                                options={[{ name: "Facebook", value: "facebook" }, { name: "Instagram", value: "instagram" }]}
                                                value={this.state.item.post_channel ? this.state.item.post_channel : {}}
                                                onChange={(value) => {
                                                    this.state.item.post_channel = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        delete this.state.item.instagram_account;
                                                        delete this.state.item.instagram_post;
                                                        delete this.state.item.facebook_post;
                                                        if (value.value === "instagram") {
                                                            this.functions.businessInstagramAccounts()
                                                        } else {
                                                            this.functions.businessInstagramAccounts()
                                                            //this.functions.createPageToken();
                                                            this.functions.facebookMedia();
                                                        }
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        false &&
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.item.post &&
                                        this.state.item.post_channel &&
                                        this.state.item.post_channel.value === "facebook" &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={true}
                                                search={true}
                                                white={true}
                                                title={'Instagram account'}
                                                size={'full'}
                                                icons={true}
                                                channels={[]}
                                                disabled={!this.state.item.facebook_page}
                                                loading={this.state.loading_page_instagram_accounts}
                                                placeholder={this.state.loading_page_instagram_accounts ? "Loading ..." : (Array.isArray(this.state.creatives.instagram_page_accounts) && this.state.creatives.instagram_page_accounts.length < 1 ? "No accounts available" : (this.state.item.facebook_page ? "Click here ..." : "Select facebook page"))}
                                                options={Array.isArray(this.state.creatives.instagram_page_accounts) ? this.state.creatives.instagram_page_accounts : []}
                                                value={this.state.item.instagram_account ? this.state.item.instagram_account : {}}
                                                onChange={(value) => {
                                                    this.state.item.instagram_account = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.item.post &&
                                        this.state.item.post_channel &&
                                        this.state.item.post_channel.value &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Dropdown
                                                filter={true}
                                                search={true}
                                                white={true}
                                                title={'Select instagram business account'}
                                                size={'full'}
                                                icons={true}
                                                channels={["facebook"]}
                                                placeholder={this.state.loading_instagram_accounts ? "Loading ..." : (Array.isArray(this.state.creatives.instagram_business_accounts) && this.state.creatives.instagram_business_accounts.length < 1 ? "No accounts available" : "Click here")}
                                                options={Array.isArray(this.state.creatives.instagram_business_accounts) ? this.state.creatives.instagram_business_accounts : []}
                                                value={this.state.item.instagram_account ? this.state.item.instagram_account : {}}
                                                onChange={(value) => {
                                                    this.state.item.instagram_account = value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.functions.instagramMedia();
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        this.state.item.post_channel &&
                                        this.state.item.post_channel.value === "facebook" &&
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box', marginTop: "10px" }}>
                                            <div className="font-bold text-xs mb-1">
                                                Select post
                                            </div>
                                            <div className="w-full border relative rounded-md bg-white h-64 p-4 overflow-scroll">
                                                {
                                                    this.state.loading_facebook_media &&
                                                    <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div class="grid grid-cols-5 gap-4">
                                                    {
                                                        Array.isArray(this.state.creatives.facebook_media) &&
                                                        this.state.creatives.facebook_media.filter((item) => {
                                                            return item.is_instagram_eligible && item.is_eligible_for_promotion && item.attachments && Array.isArray(item.attachments.data) && item.attachments.data.length > 0 && item.attachments.data[0] && (item.attachments.data[0].type === "photo" || item.attachments.data[0].type === "video_inline")
                                                        }).map((item) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    this.state.item.facebook_post = item;
                                                                    this.setState({
                                                                        content: this.state.content
                                                                    }, () => {
                                                                        this.props.onChange(this.state.content);
                                                                    });
                                                                }} style={{ paddingTop: "100%" }} class={(this.state.item.facebook_post && this.state.item.facebook_post.id == item.id ? "border-2 border-purple-500" : "border-2") + " relative rounded-md overflow-hidden cursor-pointer"}>
                                                                    <img src={item.full_picture} class="w-full h-full absolute inset-0 object-cover" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        this.state.item.facebook_page &&
                                        this.state.item.facebook_page.id &&
                                        this.state.item.post_channel &&
                                        this.state.item.post_channel.value === "instagram" &&
                                        this.state.item.instagram_account &&
                                        this.state.item.instagram_account.id &&
                                        <div style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box', marginTop: "10px" }}>
                                            <div className="font-bold text-xs mb-1">
                                                Select post
                                            </div>
                                            <div className="w-full border relative rounded-md bg-white h-64 p-4 overflow-scroll">
                                                {
                                                    this.state.loading_instagram_media &&
                                                    <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div class="grid grid-cols-5 gap-4">
                                                    {
                                                        Array.isArray(this.state.creatives.instagram_media) &&
                                                        this.state.creatives.instagram_media.filter((item) => {
                                                            return item.media_type === "VIDEO" || item.media_type === "IMAGE"
                                                        }).map((item) => {
                                                            if (item.media_type === "VIDEO") {
                                                                return (
                                                                    <div onClick={() => {
                                                                        this.state.item.instagram_post = item;
                                                                        this.setState({
                                                                            content: this.state.content
                                                                        }, () => {
                                                                            this.props.onChange(this.state.content);
                                                                        });
                                                                    }} style={{ paddingTop: "100%" }} class={(this.state.item.instagram_post && this.state.item.instagram_post.id == item.id ? "border-2 border-purple-500" : "border-2") + " relative rounded-md overflow-hidden cursor-pointer"}>
                                                                        <img src={item.thumbnail_url} class="w-full h-full absolute inset-0 object-cover" />
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div onClick={() => {
                                                                        this.state.item.instagram_post = item;
                                                                        this.setState({
                                                                            content: this.state.content
                                                                        }, () => {
                                                                            this.props.onChange(this.state.content);
                                                                        });
                                                                    }} style={{ paddingTop: "100%" }} class={(this.state.item.instagram_post && this.state.item.instagram_post.id == item.id ? "border-2 border-purple-500" : "border-2") + " relative rounded-md overflow-hidden cursor-pointer"}>
                                                                        <img src={item.media_url} class="w-full h-full absolute inset-0 object-cover" />
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.renders.appInstallFocus() &&
                                        !this.renders.eventFocus() &&
                                        !this.state.item.post &&
                                        !this.state.item.event &&
                                        ((this.state.dynamic && this.state.item.catalog && this.state.item.product_set) || (!this.state.dynamic)) &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box',
                                            marginTop: '10px'
                                        }} className="Creative-ads-item-bottom-inner-right-item">
                                            <Input
                                                type={'text'}
                                                title={"Website Url"}
                                                white={true}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}
                                                value={this.renders.property('website')}
                                                error={this.renders.string(this.renders.property('website')) < 1}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.item.website = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.errors.filter((item) => {
                                            return item.type === 'ad_website'
                                        }).length > 0 &&
                                        <div style={{
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            boxSizing: 'border-box'
                                        }}>
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-disc">
                                                    {
                                                        this.state.errors.filter((item) => {
                                                            return item.type === 'ad_website'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.item.carousel &&
                                        <AdvertisingCreativesAdsItemCreativeCarousel
                                            items={this.state.item.items}
                                            client={this.state.client}
                                            errors={this.state.errors.filter((item) => {
                                                return item.type === 'ad_carousel'
                                            })}
                                            site={this.state.site}
                                            channels={this.state.channels}
                                            onChange={(value) => {
                                                this.state.item.items = value;
                                                this.setState({
                                                    content: this.state.content
                                                }, () => {
                                                    this.props.onChange(this.state.content);
                                                });
                                            }}
                                        />
                                    }
                                    {
                                        !this.renders.appInstallFocus() &&
                                        !this.renders.eventFocus() &&
                                        (this.state.item.video || this.state.item.image) &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Input
                                                type={'text'}
                                                white={true}
                                                title={"Display Url (optional)"}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}
                                                value={this.renders.property('display_website')}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.item.display_website = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.renders.appInstallFocus() &&
                                        (this.state.item.video || this.state.item.image) &&
                                        <div
                                            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}
                                            className="Creative-ads-item-bottom-inner-right-item">
                                            <Input
                                                type={'text'}
                                                white={true}
                                                title={"Deferred deep link (optional)"}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((item) => {
                                                    return item !== "google" && this.state.channels[item]
                                                })}
                                                value={this.renders.property('deep_link')}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.item.deep_link = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <div className="Creative-ads-item-bottom-inner-right-item-filler" />
                            <div style={{
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                boxSizing: 'border-box',
                                marginTop: '15px',
                                marginBottom: '15px'
                            }} className="Creative-ads-item-bottom-inner-right-item">
                                <div className="Creative-ads-item-bottom-inner-right-item-left">
                                    <div onClick={() => {
                                        this.state.item.edit = false;
                                        try {
                                            if (this.renders.index(this.state.item) < (this.state.content.length - 1)) {
                                                this.state.content[(this.renders.index(this.state.item) + 1)].edit = true;
                                                this.state.content[(this.renders.index(this.state.item) + 1)].selected_channel = Object.keys(this.state.channels)[0];
                                            }
                                        } catch (e) {
                                        }
                                        this.setState({
                                            content: this.state.content
                                        }, () => {
                                            this.props.onChange(this.state.content);
                                        });
                                    }} className="Button ButtonNeutral ButtonFull">
                                        {
                                            this.renders.index(this.state.item) < (this.state.content.length - 1) &&
                                            <span>Next Ad</span>
                                        }
                                        {
                                            this.renders.index(this.state.item) >= (this.state.content.length - 1) &&
                                            <span>Close</span>
                                        }
                                    </div>
                                </div>
                                {
                                    (this.state.item.carousel || this.state.item.dynamic) &&
                                    <div className="Creative-ads-item-bottom-inner-right-item-right">
                                        <div onClick={() => {
                                            this.functions.new()
                                        }} className="Button ButtonFull ButtonNeutral">
                                            <FeatherIcon color={"#333333"} size={23} icon="copy" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesAdsItemCreative;
