import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon, XCircleIcon, ChevronUpIcon, TrashIcon, PhotographIcon, FilmIcon, SwitchVerticalIcon, SparklesIcon, StopIcon, BanIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import UploadImageTailwind from './uploadImageTailwind';
import UploadVideoTailwind from './uploadVideoTailwind';
import linkedinCTA from '../assets/json/linkedin_cta.json';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import EditFacebookAdCarousel from './editFacebookAdCarousel';
import { adLinkedin } from '../validators/adLinkedin';
import WarningModalTailwind from './warningModalTailwind';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

var pusherService = null;

class EditLiveLinkedinAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            ad: {},
            updated: {},
            client: {},
            pages: [],
            loader: false,
            wizard: false,
            channel: { id: 1, name: "Feed" },
            call_to_actions: linkedinCTA,
            wizardId: 0,
            selectedAsset: 0,
            carousel_menu: "Main ad",
            open_slide: 0,
            preview_data: {},
            delete_modal: false,
        }
    };

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
            //wizard: this.props.wizard,
            //assetItems: this.props.assetItems,
            //wizardId: this.props.wizardId
        })
        if (this.state.ad.is_post) {
            this.props.onDisable(true);
        }
        if (this.props.updated) {
            this.setState({
                updated: this.props.updated
            });
        }

        //LIST IDENTITIES
        // this.functions.listPages();
        //if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
        //this.props.onUpdatedChat(this.state.updated.OpenAiResult);
        //}
        this.functions.updatePreview();
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            client: nextProps.client,
            //wizard: nextProps.wizard,
            //assetItems: nextProps.assetItems,
            //wizardId: nextProps.wizardId
        })
        //if (nextProps.updated) {
        //this.setState({
        //    updated: nextProps.updated
        // });
        //}
    }

    functions = {
        onSelectFile: async (e, slide = false) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        await this.functions.uploadVideo(files[i], slide);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        await this.functions.uploadImage(files[i], slide);
                    }
                    const randomString = Math.random().toString(36);
                    await this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
            this.functions.updatePreview();
        },
        uploadImage: async (file, slide) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    name_by_channels: {
                        facebook: file.name,
                        linkedin: file.name,
                        tiktok: file.name,
                        twitter: file.name,
                        snapchat: file.name
                    },
                    type: 'image'
                };

                files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                files_object.allowed_types = { regular: true };
                files_object.allowed_multiple = true;

                this.state.assetItems.push(files_object);
                //this.state.updated.file = { ...this.state.updated.file,  };
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.image(formData);
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems });
                    await this.props.updateAssets(this.state.assetItems);
                    if (!slide) {
                        this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        this.state.updated.picture = response.data.url;
                    } else {
                        this.state.updated.slides = this.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.image = true;
                                item.video = false;
                                item.picture = response.data.url;
                                item.url = response.data.url;
                            }
                            return item;
                        });
                    }
                    await this.promisedSetState({ updated: this.state.updated, selectedAsset: response.data.id, loader: false });

                } catch (error) {
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                }
                resolve()
            });
        },
        uploadVideo: async (file, slide) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            //this.state.updated.file = { ...this.state.updated.file, ...files_object };
            await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                if (response && response.status === 200) {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                    await self.props.updateAssets(self.state.assetItems);
                    if (!slide) {
                        self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        self.state.updated.video = response.data.url;
                    } else {
                        self.state.updated.slides = self.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                        self.state.updated.files = self.state.updated.files.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                    }
                    await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, loader: false });
                    self.functions.updatePreview();

                } else {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video_upload(formData, channel_id);
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                await this.props.updateAssets(this.state.assetItems);

            }
        },
        update: async () => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (Object.keys(this.state.updated).length > 0) {
                    if (this.state.ad.reference && this.state.ad.creative_type !== "unknown") {
                        let body = {
                            "patch":
                            {
                                "$set":
                                {
                                    ...(this.state.updated.body && { "commentary": this.state.updated.body }),
                                    ...(this.state.updated.cta && { "contentCallToActionLabel": this.state.updated.cta.value }),
                                    ...(this.state.updated.link && { "contentLandingPage": this.state.updated.link }),
                                },
                                ...(this.state.updated.name && {
                                    "adContext":
                                    {
                                        "$set":
                                        {
                                            "dscName": this.state.updated.name
                                        }
                                    }
                                }),
                            }
                        }
                        body.changelog = {
                            initial_data: this.state.ad,
                            updated_data: this.state.updated,
                            level: "ad",
                            order: window.location.pathname.split("/").pop()
                        }
                        try {
                            let response = await this.calls.update(body, this.state.ad.reference);
                            if (response.data && response.data !== "Success") {
                                await this.promisedSetState({
                                    error: response.data.message
                                });
                            }
                        } catch (error) {
                            await this.promisedSetState({
                                error: error.body && error.body.message ? error.body.message : "Something went wrong"
                            });
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }


                }
                if (!this.state.error) {
                    this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
            }
        },
        /*
        listPages: async () => {
            await this.promisedSetState({ loading_linkedin: true });
            try {
                let response = await this.calls.listPages();
                this.promisedSetState({
                    pages: response.data,
                    loading_linkedin: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_linkedin: false });
            }
        },*/
        updatePreview: async () => {
            let preview_data = {
                merge: false,
                carousel: this.state.carousel ? true : false,
                catalog: false,
                creative: this.state.ad && this.state.ad.creative ? this.state.ad.creative : false,
                headline_by_channels: { linkedin: this.renders.title() },
                body_by_channels: { linkedin: this.renders.body() },
                cta: { linkedin: typeof this.renders.cta() === "string" ? this.renders.cta() : (typeof this.renders.cta() === "object" ? this.renders.cta().value : "NO_BUTTON") },
                page: { linkedin: this.renders.page() },
                website_by_channels: { linkedin: this.renders.link() },
                preview_channel: "linkedin",
                previews: { linkedin: [{ id: 1, value: "feed", name: "Feed" }] },
                preview: { id: 1, value: "feed", name: "Feed" },
                utm_channels: ["linkedin"],
                channel_menu: "linkedin",
                validation: {
                    linkedin: false
                },
            };

            if (preview_data.creative) {
                preview_data.creative.body = this.renders.body();
                preview_data.creative.title = this.renders.title();
                preview_data.creative.link = this.renders.link();
                preview_data.creative.call_to_action = typeof this.renders.cta() === "string" ? this.renders.cta() : (typeof this.renders.cta() === "object" ? this.renders.cta().value : "NO_BUTTON");
                if (this.state.ad.creative_type === "image") {
                    preview_data.creative.image = this.state.ad.image_url;
                } else if (this.state.ad.creative_type === "video") {
                    preview_data.creative.video = this.state.ad.video_url;
                }
            }

            console.log("PREVIEW", preview_data)

            //carousel: this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad" ? this.state.carousel : false,
            // this.state.updated && this.state.updated.is_post ? [this.state.updated] : this.state.updated.files && this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" ? this.state.updated.files : (this.state.updated.ad_type.value === "carousel_ad" ? [this.state.carousel] : (this.state.updated.file ? [this.state.updated.file] : [])),




            try {
                this.props.updatePreview(preview_data)
            } catch (error) {
                console.log(error)
            }
        },

        updateOpenAi: async (data) => {
            try {
                this.state.updated.OpenAiResult = data;
                await this.promisedSetState({
                    updated: this.state.updated
                })
            } catch (error) {
                console.log(error)
            }
        },
        addHeadlines: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post && !this.state.updated.catalog) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            if (!this.state.carousel) {
                                this.state.updated.title = data[i].value[0];
                            }
                        } else {
                            if (!this.state.carousel) {
                                if (this.renders.title() === "") {
                                    this.state.updated.title = data[i].value[0];
                                }
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        addDescriptions: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            this.state.updated.body = data[i].value[0];
                        } else {
                            if (this.renders.body() === "") {
                                this.state.updated.body = data[i].value[0];
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        removeDescription: async () => {
            try {
                this.state.updated.body = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        removeHeadline: async () => {
            try {
                this.state.updated.title = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        deleteSlide: async (slide) => {
            try {
                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                    this.state.updated.slides = this.state.updated.slides.filter((item) => {
                        return slide.id !== item.id;
                    });
                }
                await this.promisedSetState({
                    updated: this.state.updated, open_slide: 0, delete_modal: false
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.updated);
                }
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else {
                    return this.state.ad.name;
                }
            } catch (error) {
                return "";
            }
        },
        body: () => {
            try {
                if ('body' in this.state.updated) {
                    return this.state.updated.body;
                } else {
                    return this.state.ad.creative.body;
                }
            } catch (error) {
                return "";
            }
        },
        title: () => {
            try {
                if ('title' in this.state.updated) {
                    return this.state.updated.title;
                } else {
                    return this.state.ad.creative.title;
                }
            } catch (error) {
                return "";
            }
        },
        caption: () => {
            try {
                if ('caption' in this.state.updated) {
                    return this.state.updated.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: () => {
            try {
                if ('link' in this.state.updated) {
                    return this.state.updated.link;
                } else {
                    return this.state.ad.creative.link;
                }
            } catch (error) {
                return "";
            }
        },
        page: () => {
            try {
                if ("page" in this.state.updated) {
                    return this.state.updated.page;
                } else {
                    return this.state.ad.creative.page;
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        video: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    return this.state.updated.url;
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        image: () => {
            try {
                if (this.state.updated.type === "image") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    return this.state.updated.url;
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        creative: () => {
            let creative = null;
            try {
                if (this.state.updated.type === "carousel") {
                    if (Array.isArray(this.state.updated.slides)) {
                        creative = this.state.updated.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                } else if (this.state.updated.type === "posts") {
                    if (Array.isArray(this.state.updated.files)) {
                        creative = this.state.updated.files.map((item) => {
                            return {
                                video: (item.type === "video" ? true : false),
                                image: (item.type === "image" ? true : false),
                                creative: { url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) { }
            return creative;
        },
        thumbnail: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.thumbnail;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        cta: () => {
            try {
                if ("cta" in this.state.updated) {
                    return this.state.updated.cta;
                } else {
                    return this.state.ad.creative.call_to_action;
                }
            } catch (error) {
                return { id: 0, name: "No Button", value: "NO_BUTTON" };
            }
        },
        type: (item) => {
            try {
                return this.state.ad.creative_type;
            } catch (error) {
                return "";
            }
        },
    };

    calls = {
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizardId;
            return apiRegister.call(options, url);
        },
        video_upload: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizardId + "&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        },
        update: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/updateAdContent?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        listPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    SortableItem = SortableElement(({ item, itemIndex }) => {
        return (
            <div
                style={{ zIndex: 99999999 }}
                className="cursor-pointer">
                {
                    <>
                        <div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === itemIndex ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
                            <div className='flex w-full justify-between'>
                                <div className='flex'>
                                    <div className={"border-1.5 h-12 w-12 mr-2 flex justify-center cursor-pointer items-center rounded-md"}>
                                        <SwitchVerticalIcon className="h-5 w-5" />
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
                                            {
                                                item.type === "image" &&
                                                <img src={item.url ? item.url : ""} className="w-full object-contain rounded-md"
                                                    style={{ maxHeight: "48px" }}
                                                />
                                            }
                                            {
                                                item.type === "video" &&
                                                // <video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className=""
                                                //     style={{ maxHeight: "48px" }}
                                                // />
                                                <div className='flex justify-center items-center bg-gray-300 w-full h-full rounded-md'>
                                                    <BanIcon className="h-5 w-5 text-gray-500" />
                                                </div>
                                            }
                                        </div>
                                        <div className='flex flex-col'>
                                            <div className="text-sm font-medium">
                                                Slide {itemIndex + 1}
                                            </div>
                                            <div className="flex text-xs text-gray-600">
                                                {
                                                    this.state.open_slide === itemIndex ?
                                                        <>
                                                            <PhotographIcon className="h-4 w-4 mr-1" />
                                                            Image
                                                        </>
                                                        :
                                                        <div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
                                                            <div className='col-span-1'>
                                                                Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, 30) : ''}
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center space-x-4">
                                    {
                                        this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 && false &&
                                        <div className='bg-red-200 p-2 rounded-md cursor-pointer'
                                            onClick={async () => {
                                                await this.promisedSetState({ delete_modal: true, delete_slide: item })
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
                                        </div>
                                    }
                                    <div
                                        onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === itemIndex ? null : itemIndex })}
                                        className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
                                        <ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === itemIndex && "rotate-180"}`} />
                                    </div >
                                </div>
                            </div>

                            {/*
                                this.state.updated && this.state.updated.type !== "posts" &&
                                <div className='flex w-full space-x-4'>
                                    <div className='w-full'>
                                        <DropdownTailwind
                                            label={"Select other asset"}
                                            loader={this.state.loader}
                                            searchInput={true}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                            options={this.state.assetItems && this.state.assetItems.filter((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return item.type === "image"
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return item.type === "video"
                                                } else return item
                                            }).map((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.collection_ad) {
                                                    return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                                }
                                            }).filter((item) => { return item })}
                                            onChange={async (value) => {
                                                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                                                    this.state.updated.slides[itemIndex] = { ...this.state.updated.slides[itemIndex], ...value };
                                                }
                                                // this.state.updated.slides = this.state.updated.files;
                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    selectedAsset: value.id
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                        <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                            <input
                                                onChange={(e) => this.functions.onSelectFile(e, item)}
                                                type="file"
                                                accept={"image/png, image/jpg, image/jpeg, image/gif"}
                                                className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                            />
                                            {
                                                this.state.loader &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    <div className="ml-2 text-xs">
                                                        Loading ...
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.state.loader &&
                                                <div className="text-xs text-gray-600">
                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                        */}

                            <div className="w-full">
                                <InputTailwind
                                    label={"Website"}
                                    channel={"linkedin"}
                                    value={item.link ? item.link : ""}
                                    locked={true}
                                    onChange={async (value) => {

                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>
                            {
                                (!item.link || !item.link.includes("https://")) &&
                                <div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                    OBS! Prefix https:// is required.
                                </div>
                            }
                            <div className="flex space-x-4 w-full">
                                <div className='w-full'>
                                    <InputTailwind
                                        label={"Headline"}
                                        channel={"linkedin"}
                                        locked={true}
                                        value={item.title ? item.title : ""}
                                        // error={this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 && (!item.title || item.title === "") ? true : false}
                                        onChange={async (value) => {

                                            this.functions.updatePreview();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </>
                }
            </div>
        )
    });

    SortableList = SortableContainer(({ items }) => (
        <div className="relative space-y-4">
            {items.map((item, index) => {
                if (item) {
                    return (
                        <div>
                            {
                                <>
                                    <div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === index ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
                                        <div className='flex w-full justify-between'>
                                            <div className='flex'>
                                                <div className='flex items-center'>
                                                    <div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
                                                        {
                                                            item.type === "image" &&
                                                            <img src={item.picture ? item.picture : ""} className="w-full object-contain rounded-md"
                                                                style={{ maxHeight: "48px" }}
                                                            />
                                                        }
                                                        {
                                                            item.type === "video" &&
                                                            // <video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className=""
                                                            //     style={{ maxHeight: "48px" }}
                                                            // />
                                                            <div className='flex justify-center items-center bg-gray-300 w-full h-full rounded-md'>
                                                                <BanIcon className="h-5 w-5 text-gray-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <div className="text-sm font-medium">
                                                            Slide {index + 1}
                                                        </div>
                                                        <div className="flex text-xs text-gray-600">
                                                            {
                                                                this.state.open_slide === index ?
                                                                    <>
                                                                        <PhotographIcon className="h-4 w-4 mr-1" />
                                                                        Image
                                                                    </>
                                                                    :
                                                                    <div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
                                                                        <div className='col-span-1'>
                                                                            Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, 30) : ''}
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center space-x-4">
                                                {
                                                    this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 && false &&
                                                    <div className='bg-red-200 p-2 rounded-md cursor-pointer'
                                                        onClick={async () => {
                                                            await this.promisedSetState({ delete_modal: true, delete_slide: item })
                                                        }}
                                                    >
                                                        <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
                                                    </div>
                                                }
                                                <div
                                                    onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === index ? null : index })}
                                                    className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
                                                    <ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === index && "rotate-180"}`} />
                                                </div >
                                            </div>
                                        </div>

                                        {/*
                                this.state.updated && this.state.updated.type !== "posts" &&
                                <div className='flex w-full space-x-4'>
                                    <div className='w-full'>
                                        <DropdownTailwind
                                            label={"Select other asset"}
                                            loader={this.state.loader}
                                            searchInput={true}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                            options={this.state.assetItems && this.state.assetItems.filter((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return item.type === "image"
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return item.type === "video"
                                                } else return item
                                            }).map((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "carousel_ad" || this.state.updated.ad_type === "carousel_ad")) {
                                                    return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.collection_ad) {
                                                    return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                                }
                                            }).filter((item) => { return item })}
                                            onChange={async (value) => {
                                                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                                                    this.state.updated.slides[itemIndex] = { ...this.state.updated.slides[itemIndex], ...value };
                                                }
                                                // this.state.updated.slides = this.state.updated.files;
                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    selectedAsset: value.id
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                        <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                            <input
                                                onChange={(e) => this.functions.onSelectFile(e, item)}
                                                type="file"
                                                accept={"image/png, image/jpg, image/jpeg, image/gif"}
                                                className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                            />
                                            {
                                                this.state.loader &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    <div className="ml-2 text-xs">
                                                        Loading ...
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.state.loader &&
                                                <div className="text-xs text-gray-600">
                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                        */}

                                        <div className="w-full">
                                            <InputTailwind
                                                label={"Website"}
                                                channel={"linkedin"}
                                                value={item.link ? item.link : ""}
                                                locked={true}
                                                onChange={async (value) => {

                                                    this.functions.updatePreview();
                                                }}
                                            />
                                        </div>
                                        {
                                            (!item.link || !item.link.includes("https://")) &&
                                            <div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                                OBS! Prefix https:// is required.
                                            </div>
                                        }
                                        <div className="flex space-x-4 w-full">
                                            <div className='w-full'>
                                                <InputTailwind
                                                    label={"Headline"}
                                                    channel={"linkedin"}
                                                    locked={true}
                                                    value={item.title ? item.title : ""}
                                                    // error={this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 && (!item.title || item.title === "") ? true : false}
                                                    onChange={async (value) => {

                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        </div>
                        /*
                        <this.SortableItem
                            index={index}
                            itemIndex={index}
                            item={item}
                        />*/
                    )
                }
            })}
        </div>
    ));

    onSortEnd = async ({ oldIndex, newIndex }) => {
        await this.promisedSetState({
            array: arrayMove((this.state.updated.slides ? this.state.updated.slides : []), oldIndex, newIndex),
        })
        if (this.state.updated.slides) {
            this.state.updated.slides = this.state.array;
            // this.state.updated.files = this.state.array;
            await this.promisedSetState({
                updated: this.state.updated
            })
            this.functions.updatePreview()
        }
    };

    renderSortableList(items) {
        return (
            <div className="relative space-y-4">
                <this.SortableList
                    items={this.state.ad.creative.slides}
                    //onSortEnd={this.onSortEnd}
                    axis="y"
                    distance={1}
                />
            </div>
        )
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>

                <WarningModalTailwind
                    open={this.state.delete_modal ? true : false}
                    title={"Delete slide"}
                    description={'Are you sure you want to delete this slide ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Delete"}
                    onClose={async (value) => {
                        await this.promisedSetState({ delete_modal: false });
                    }}
                    onSubmit={() => {
                        this.functions.deleteSlide(this.state.delete_slide);
                    }}
                />

                <div className='flex flex-col px-4 space-y-4 bg-gray-50 pb-4'>
                    {
                        this.state.ad && this.state.ad.creative_type && this.state.ad.creative_type === "carousel" &&
                        <div className="block w-full">
                            <div className="border-gray-200 w-full">
                                <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                                    {
                                        ["Main ad", "Slides information"].map((item) => {
                                            return (
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            carousel_menu: item
                                                        })
                                                    }}
                                                    key={item}
                                                    className={classNames(
                                                        item === this.state.carousel_menu
                                                            ? 'border-purple-500 text-purple-600'
                                                            : ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
                                                        'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md'
                                                    )}
                                                    aria-current={item === this.state.carousel_menu ? 'page' : undefined}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        }
                                        )}
                                </nav>
                            </div>
                        </div>
                    }

                    {
                        this.state.carousel_menu === "Main ad" &&
                        <>
                            {/*
                                this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" && this.state.updated.type !== "posts" &&
                                <div className="flex flex-row items-center space-x-4">
                                    <div className='w-full'>
                                        <DropdownTailwind
                                            label={"Select other asset"}
                                            channel={"linkedin"}
                                            loader={this.state.loader}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                            options={this.state.assetItems && this.state.assetItems.filter((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                                    return item.type === "image"
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                                    return item.type === "video"
                                                } else return item
                                            }).map((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                                    return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                                    return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.collection_ad) {
                                                    return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                                }
                                            }).filter((item) => { return item })}
                                            onChange={async (value) => {
                                                this.state.updated.file = { ...this.state.updated.file, ...value };
                                                if (value.image) {
                                                    delete this.state.updated.video;
                                                    this.state.updated.picture = value.image;
                                                    this.state.updated.type = "image";
                                                } else if (value.video) {
                                                    delete this.state.updated.picture;
                                                    this.state.updated.video = value.video;
                                                    this.state.updated.type = "video";
                                                }

                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    selectedAsset: value.id
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    {
                                        !this.state.loader &&
                                        <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                            <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                {
                                                    this.state.loader &&
                                                    <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        <div className="ml-2 text-xs">
                                                            Loading ...
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.loader &&
                                                    <div className="text-xs text-gray-600">
                                                        <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                */}

                            {
                                this.state.error &&
                                <div className="flex w-full bg-red-100 rounded-md p-4 text-sm font-medium text-red-500">
                                    {typeof this.state.error === "string" ? this.state.error : "Something goes wrong"}
                                </div>
                            }
                            {

                                this.renders.type() && typeof this.renders.type() === 'string' && ["unknown", ""].includes(this.renders.type()) &&
                                <div className="flex w-full bg-purple-100 rounded-md p-4 text-sm font-medium text-purple-500">
                                    {"This format is unsupported. Open external link to edit ad."}
                                </div>
                            }
                            <div className="flex w-full space-x-4">
                                <div className='w-full'>
                                    <InputTailwind
                                        label={"Name"}
                                        channel={"linkedin"}
                                        value={this.renders.name()}
                                        onChange={async (value) => {
                                            this.state.updated.name = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                            this.functions.updatePreview();
                                        }}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <InputTailwind
                                        label={"Type"}
                                        channel="linkedin"
                                        value={this.renders.type() && typeof this.renders.type() === 'string' ? this.renders.type().charAt(0).toUpperCase() + this.renders.type().slice(1) : ''}
                                        disabled={true}
                                    />
                                </div>
                                {
                                    true && //this.renders.type() && typeof this.renders.type() === 'string' && ["unknown", ""].includes(this.renders.type()) &&
                                    <div className='w-1/3'>
                                        <div>
                                            <label class="text-xs flex flex-row font-medium text-gray-700 ">
                                                <div class="mr-1">
                                                    <div class="bg-linkedin-500 h-4 w-4 rounded-full flex justify-center items-center">
                                                        <img className="w-2"
                                                            alt=""
                                                            src={require('../assets/images/linkedin_icon.svg')} />
                                                    </div>
                                                </div>
                                                <div class="flex-1  flex flex-row">External link</div>
                                            </label>
                                            <div class="mt-1 relative flex flex-row rounded-md">
                                                <div className="items-center text-sm bg-purple-500 hover:bg-purple-600 mr-2 cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-3 px-3 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                                    onClick={async () => {
                                                        window.open("https://www.linkedin.com/campaignmanager/accounts/" + this.state.ad.account + "/campaigns/" + this.state.ad.adgroup_id + "/creatives/" + this.state.ad.id.replace("urn:li:sponsoredCreative:", ""));
                                                    }}
                                                >
                                                    <ExternalLinkIcon class="w-5 h-5 mr-2" />
                                                    <span>Open</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="w-full text-lg font-semibold">
                                Select identity
                            </div>

                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Select profile"}
                                    channel={"linkedin"}
                                    selected={this.renders.page()}
                                    locked={true}
                                    options={[]}
                                    onChange={async (value) => {

                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>

                            <div className="w-full text-lg font-semibold">
                                Ad settings
                            </div>

                            <div className="w-full">
                                <InputTailwind
                                    label={"Website"}
                                    channel={"linkedin"}
                                    value={this.renders.link()}
                                    locked={this.state.ad.creative_type === "image" || this.state.ad.creative_type === "unknown" ? true : false}
                                    disabled={this.state.ad.creative_type === "image" || this.state.ad.creative_type === "unknown" ? true : false}
                                    onChange={async (value) => {
                                        this.state.updated.link = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                                {
                                    false && adLinkedin.validate(this.state.updated).website &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>

                            {/* <div className="w-full">
                                <InputTailwind
                                    label={"Caption"}
                                    channel={"linkedin"}
                                    // error={adLinkedin.validate(this.state.updated).website}
                                    value={this.renders.caption()}
                                    onChange={async (value) => {
                                        this.state.updated.caption = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div> */}

                            {
                                this.state.ad && this.state.ad.creative_type && this.state.ad.creative_type !== "carousel" &&
                                <div className="w-full relative">
                                    <InputTailwind
                                        label={"Headline"}
                                        channel={"linkedin"}
                                        locked={true}
                                        value={this.renders.title()}
                                        disabled={true}
                                        onChange={async (value) => {

                                            this.functions.updatePreview();
                                        }}
                                    />
                                    {
                                        false && this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === "linkedin" && inner_item.value.includes(this.renders.title()) }).length > 0 }).length > 0 &&
                                        <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                            <SparklesIcon className="h-5 w-5 text-purple-500" />
                                        </div>
                                    }
                                </div>
                            }

                            <div className="w-full relative">
                                <TextAreaTailwind
                                    label={"Text"}
                                    channel={"linkedin"}
                                    value={this.renders.body()}
                                    onChange={async (value) => {
                                        this.state.updated.body = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                                {
                                    false && this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === "linkedin" && inner_item.value.includes(this.renders.body()) }).length > 0 }).length > 0 &&
                                    <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                        <SparklesIcon className="h-5 w-5 text-purple-500" />
                                    </div>
                                }
                            </div>

                            {
                                this.state.ad && this.state.ad.creative_type && this.state.ad.creative_type !== "carousel" &&
                                <>
                                    <div className="col-span-4 text-lg font-semibold">
                                        Select CTA
                                    </div>

                                    <div className="w-full">
                                        <DropdownTailwind
                                            channel={"linkedin"}
                                            label={"CTA button"}
                                            selected={typeof this.renders.cta() === "string" ? { id: 0, name: this.renders.cta().charAt(0).toUpperCase() + this.renders.cta().toLowerCase().replace(/_/g, ' ').slice(1) } : (typeof this.renders.cta() === "object" ? this.renders.cta() : { id: 0, name: "No Button", type: "NO_BUTTON" })}
                                            options={this.state.call_to_actions}
                                            onChange={async (value) => {
                                                this.state.updated.cta = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                </>

                            }

                        </>
                    }

                    {
                        this.state.carousel_menu === "Slides information" &&
                        <>
                            {
                                this.state.ad && Array.isArray(this.state.ad.creative.slides) && this.state.ad.creative.slides.length > 0 &&
                                this.renderSortableList(this.state.ad.creative.slides)
                            }
                        </>
                    }
                </div>


                {/* <div className="grid grid-cols-12">
                        {
                            this.state.updated &&
                            Array.isArray(this.state.updated.previews) &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                {
                                    (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type.value === "video_ad") &&
                                    <div className="col-span-3 grid grid-cols-3 gap-4">
                                        <div className="col-span-2">
                                            <DropdownTailwind
                                                label={"Select other asset"}
                                                loader={this.state.loader}
                                                locked={!this.state.wizard}
                                                selected={this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                                options={this.state.assetItems.filter((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad") {
                                                        return item.type === "image"
                                                    } else if (this.state.updated.ad_type.value === "video_ad") {
                                                        return item.type === "video"
                                                    } else return item
                                                }).map((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad") {
                                                        return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                    } else if (this.state.updated.ad_type.value === "video_ad") {
                                                        return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                    }
                                                })}
                                                onChange={async (value) => {
                                                    this.state.updated.file = { ...this.state.updated.file, ...value };
                                                    if (this.state.updated.ad_type.value === "image_ad") {
                                                        this.state.updated.picture = value.image;
                                                    } else if (this.state.updated.ad_type.value === "video_ad") {
                                                        this.state.updated.video = value.video;
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated,
                                                        selectedAsset: value.id
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1 pt-5.5">
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                <div className="space-y-1 text-center justify-center flex flex-col">
                                                    {
                                                        this.state.loader &&
                                                        <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            <div className="ml-2 text-xs">
                                                                Loading ...
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.loader &&
                                                        <div className="text-xxs text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview"}
                                        selected={this.state.updated.preview}
                                        options={this.state.updated.previews}
                                        onChange={(value) => {
                                            this.state.updated.preview = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3 mx-auto">
                                    <PreviewSectionLinkedin
                                        ad={this.state.updated}
                                        channel={{ name: 'linkedin', value: 'linkedin' }}
                                        is_post={this.state.updated && this.state.updated.type === "posts" ? true : false}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !this.state.updated.new_preview &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview"}
                                        selected={this.state.channel}
                                        options={[{ id: 1, name: "Feed" }]}
                                        onChange={(value) => {
                                            this.setState({
                                                channel: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    ((this.state.updated && this.state.updated.file && this.state.updated.file.type === "image") ||
                                        (this.state.updated && this.state.updated.url && this.state.updated.type === "posts" && this.state.updated.ad_type.type === "image")) &&
                                    <div className="col-span-3 ">
                                        <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                            <AdvertisingCreativesFacebookImage
                                                linkedin={true}
                                                page={this.renders.page()}
                                                creatives={[
                                                    {
                                                        call_to_action_linkedin: this.renders.cta(),
                                                        description: "",
                                                        display_website: this.renders.caption(),
                                                        headline: this.renders.title(),
                                                        body: this.renders.body(),
                                                        creative: { url: this.renders.image() }
                                                    }
                                                ]}
                                                noContainer={true}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    ((this.state.updated && this.state.updated.file && this.state.updated.file.type === "video") ||
                                        (this.state.updated && this.state.updated.url && this.state.updated.type === "posts" && this.state.updated.ad_type.type === "video")) &&
                                    <div className="col-span-3 ">
                                        <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                            <AdvertisingCreativesFacebookVideo
                                                linkedin={true}
                                                page={this.renders.page()}
                                                creatives={[
                                                    {
                                                        call_to_action_linkedin: this.renders.cta(),
                                                        description: "",
                                                        display_website: this.renders.caption(),
                                                        headline: this.renders.title(),
                                                        body: this.renders.body(),
                                                        creative: { url: this.renders.video() }
                                                    }
                                                ]}
                                                noContainer={true}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    ((this.state.updated && this.state.updated.type && this.state.updated.type === "carousel") ||
                                        (this.state.updated && this.state.updated.type && this.state.updated.type === "posts" && this.state.updated.files)) &&
                                    <div className="col-span-3 ">
                                        <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                            <AdvertisingCreativesFacebookCarousel
                                                linkedin={true}
                                                page={this.renders.page()}
                                                content={{ body: this.renders.body(), items: this.renders.creative() }}
                                                noContainer={true}
                                                onChange={() => {

                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div className="col-span-7 grid grid-cols-3 gap-4 content-start pb-10 pl-6">

                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Ad name"}
                                    error={adLinkedin.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Select profile"}
                                    error={adLinkedin.validate(this.state.updated).page}
                                    selected={this.renders.page()}
                                    locked={false}
                                    options={this.state.pages}
                                    onChange={(value) => {
                                        this.state.updated.page = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                (this.state.updated && this.state.updated.type && this.state.updated.type === "carousel") &&
                                <EditFacebookAdCarousel
                                    linkedin={true}
                                    ad={this.state.ad}
                                    assetItems={this.state.assetItems}
                                    loader={this.state.loader}
                                    wizard={this.state.wizard}
                                    pages={this.state.pages}
                                    updated={this.state.updated}
                                    client={this.state.client}
                                    onChange={(value) => {
                                        this.setState({
                                            updated: value
                                        })
                                    }}
                                    UploadFile={(e, item) => {
                                        this.functions.onSelectFile(e, item)
                                    }}
                                />
                            }
                            {
                                false &&
                                <div className="col-span-3">
                                    <UploadVideoTailwind
                                        label={"Change video"}
                                        channel={"tiktok"}
                                        client={this.state.client}
                                        onChange={async (value) => {
                                            delete this.state.updated.file;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                            this.state.updated.file = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <TextAreaTailwind
                                    label={"Text"}
                                    error={adLinkedin.validate(this.state.updated).body}
                                    value={this.renders.body()}
                                    onChange={(value) => {
                                        this.state.updated.body = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Headline"}
                                    error={adLinkedin.validate(this.state.updated).headline}
                                    value={this.renders.title()}
                                    onChange={(value) => {
                                        this.state.updated.title = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"CTA button"}
                                    error={adLinkedin.validate(this.state.updated).cta}
                                    selected={this.renders.cta()}
                                    options={this.state.call_to_actions}
                                    onChange={(value) => {
                                        this.state.updated.cta = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"URL"}
                                    error={adLinkedin.validate(this.state.updated).website}
                                    value={this.renders.link()}
                                    onChange={(value) => {
                                        this.state.updated.link = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                                {
                                    adLinkedin.validate(this.state.updated).website &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"caption"}
                                    value={this.renders.caption()}
                                    onChange={(value) => {
                                        this.state.updated.caption = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>

                    </div> */}
            </>
        )
    }
}

export default EditLiveLinkedinAd;
