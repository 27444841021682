import React, {Component, createRef} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Switch from 'react-ios-switch';
import {BeatLoader} from "react-spinners";
import Input from "../../input";
import Dropdown from "../../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {eventRegister} from "../../../services/eventRegister";
import {campaignRegister} from "../../../services/campaignRegister";
import * as turf from '@turf/turf'
import {calls} from "./calls";
import IconFacebook from "../../icons/facebook";
import IconInstagram from "../../icons/instagram";

class TargetingLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exclude_locations: {},
            locations: [],
            channels: {},
            search: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            exclude_locations: nextProps.exclude_locations,
            search: nextProps.search,
            channels: nextProps.channels?nextProps.channels:{},
            locations: nextProps.locations?nextProps.locations:[]
        })
    }

    componentDidMount() {
        this.setState({
            exclude_locations: this.props.exclude_locations,
            search: this.props.search,
            channels: this.props.channels?this.props.channels:{},
            locations: this.props.locations?this.props.locations:[]
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="w-full">
                {
                    this.state.locations.filter((item) => {
                        let include = false;
                        for(let key in this.state.channels){
                            let inner_key = "";
                            if(key === "facebook_instagram"){
                                inner_key = "facebook";
                            }else{
                                inner_key = key;
                            }
                            if(item.channels[inner_key] && !item.channels[inner_key].disabled && !this.state.channels[key].disabled){
                                include = true;
                            }
                        }
                        return include
                    }).map((item, index) => {
                        return(
                            <div style={{backgroundColor: "#fcfcfc"}} className={(this.props.border ? "border" : "") + " flex flex-row flex-1 items-center mb-2 bg-white px-4 py-3 py-1 rounded-md"}>
                                <div className="flex flex-row">
                                    {
                                        Object.keys(item.channels).filter((channel) => {
                                            return ((this.state.search && channel === "google") || (!this.state.search && channel !== "google"))
                                        }).map((key) => {
                                            return(
                                                <div className="flex flex-row">
                                                    {key === 'google' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-google-500">
                                                        <img className="w-3"
                                                             src={require('../../../assets/images/google_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {key === 'linkedin' &&
                                                    <div
                                                        className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-linkedin-500">
                                                        <img className="w-3"
                                                             src={require('../../../assets/images/linkedin_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {key === 'twitter' &&
                                                    <div
                                                        className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-twitter-500">
                                                        <img className="w-3"
                                                             src={require('../../../assets/images/twitter_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {(key === 'facebook' || key === 'facebook_instagram') &&
                                                    <div className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-1 flex items-center justify-center bg-facebook-500">
                                                        <img style={{width: "6px"}}
                                                             src={require('../../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {(key === 'facebook' || key === 'facebook_instagram') &&
                                                    <div className="px-2 border-2 border-white -ml-3 py-2 h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-instagram-500">
                                                        <img className="w-3"
                                                             src={require('../../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="text-sm font-bold">{item.name}</div>
                                {
                                    <span
                                        className={cx("px-4 py-1 ml-3 inline-flex text-xs font-bold rounded-full",
                                            {
                                                ["bg-green-100 text-green-500"]: item.include,
                                                ["bg-red-100 text-red-600"]: !item.include
                                            })
                                        }>
                                                                                {item.include && <span>include</span>}
                                        {!item.include && <span>exclude</span>}
                                                                            </span>
                                }
                                <div className="flex flex-1"></div>
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={!this.state.exclude_locations[item.id]}
                                    onChange={(checked) => {
                                        this.state.exclude_locations[item.id] = !checked;
                                        this.setState({
                                            exclude_locations: this.state.exclude_locations
                                        }, () => {
                                            try{
                                                this.props.updateLocation(this.state.exclude_locations);
                                            }catch (e) {}
                                        })
                                    }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default TargetingLocation;
