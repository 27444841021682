import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../../accordion-fixed";
import Switch from 'react-ios-switch';
import IconFacebook from "../../icons/facebook";
import IconInstagram from "../../icons/instagram";
import Input from "../../input";
import {eventRegister} from "../../../services/eventRegister";
import {campaignRegister} from "../../../services/campaignRegister";
import SweetAlert from "sweetalert-react";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";
import Dropdown from "../../dropdown";
import Accordion from "../../accordion";

var moment = require('moment');

class ConversionFacebookProspect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            channels: {},
            conversion: {},
            creatives: {},
            adset: {},
            durations: ['20', '30', '60', '90'],
            error: false,
            loading_conversion_actions: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            conversion: nextProps.conversion,
            client: nextProps.client,
            creatives: nextProps.creatives,
            adset: nextProps.adset,
            channels: nextProps.channels,
            locations: nextProps.locations
        })
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            conversion: this.props.conversion,
            client: this.props.client,
            creatives: this.props.creatives,
            adset: this.props.adset,
            channels: this.props.channels,
            locations: this.props.locations
        }, () => {
            this.functions.audience()
        })
    }

    init = {};

    functions = {
        audience: () => {
            if(typeof this.state.conversion.facebook !== "object" || (typeof this.state.conversion.facebook === "object" && Array.isArray(this.state.conversion.facebook.audiences) && this.state.conversion.facebook.audiences.length < 1)){
                calls.getAudience({
                    channel: "facebook",
                    client: this.state.client.id
                }).then((response) => {
                    if(typeof this.state.conversion.facebook === "object") {
                        this.state.conversion.facebook.audiences = response.data;
                    }else{
                        this.state.conversion.facebook = {
                            audiences: response.data
                        };
                    }
                    this.setState({
                        conversion: this.state.conversion
                    }, () => {
                        this.props.updateConversion(this.state.conversion);
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            }
        },
        createLookALike: (item) => {
            let id =  Math.floor((Math.random() * 9999999999) + 1);
            this.state.conversion.facebook.audiences.push({
                id: id,
                name: "",
                loading: true,
                error: false,
                message: "",
                not_done: true,
                look_a_like_selected: true
            });
            this.setState({
                conversion: this.state.conversion
            }, () => {
                this.props.updateConversion(this.state.conversion);
                calls.createLookALike({
                    client: this.state.client.id,
                    geo_locations: this.state.locations,
                    custom_audience: item.id,
                    custom_audience_name: item.name,
                    type: "lookalike",
                    channel: "facebook"
                }).then((response) => {
                    this.state.conversion.facebook.audiences = this.state.conversion.facebook.audiences.map((item) => {
                        if(item.id === id){
                            item = response.data;
                        }
                        return item;
                    });
                    this.setState({
                        conversion: this.state.conversion
                    }, () => {
                        this.props.updateConversion(this.state.conversion);
                    })
                }, (error) => {
                    this.state.conversion.facebook.audiences = this.state.conversion.facebook.audiences.map((item) => {
                        if(item.id === id){
                            item.error = true;
                            item.loading = false;
                            item.message = error.body.message;
                        }
                        return item;
                    });
                    this.setState({
                        conversion: this.state.conversion
                    }, () => {
                        this.props.updateConversion(this.state.conversion);
                    })
                });
            });
        }
    };

    renders = {
        days: () => {
            let days = [];
            for(let i = 1; i < 181; i++){
                if(i === 1){
                    days.push({name: i + ' day', value: i});
                }else{
                    days.push({name: i + ' days', value: i});
                }
            }
            return days;
        },
        type: () => {
            let type = "normal";
            if(this.state.creatives.dynamic_ads && this.state.creatives.dynamic_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id}).length > 0){
                type = "dynamic";
            }else if(this.state.creatives.video_ads && this.state.creatives.video_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id && this.state.adset.type === 'vertical'}).length > 0){
                type = "vertical";
            }else if(this.state.creatives.image_ads && this.state.creatives.image_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id && this.state.adset.type === 'vertical'}).length > 0){
                type = "vertical";
            }
            return type;
        },
        audiences: () => {
            try{
                return Array.isArray(this.state.conversion.facebook.audiences)?this.state.conversion.facebook.audiences:[];
            }catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <div className="w-full">
                {
                    this.state.conversion.lookalike_default &&
                    <div className="pb-3">
                        <Dropdown
                            noTitle={false}
                            title={"Select look a like audience"}
                            size={'full'}
                            search={true}
                            searchFilter={true}
                            showError={true}
                            showType={true}
                            filter={true}
                            loading={this.state.loading}
                            placeholder={this.state.loading?"Loading audience":"Search audience ..."}
                            options={this.renders.audiences().filter((item) => {
                                return !item.look_a_like_selected && !item.not_done
                            }).map((item) => {
                                return item
                            })}
                            value={{}}
                            onChange={(value) => {
                                if(value.status_code === 200){
                                    if(value.type !== "LOOKALIKE"){
                                        value.loading = true;
                                        this.functions.createLookALike(value);
                                    }else{
                                        value.loading = false;
                                        value.look_a_like_selected = true;
                                    }
                                    this.setState({
                                        conversion: this.state.conversion
                                    }, () => {
                                        this.props.updateConversion(this.state.conversion);
                                    })
                                }
                            }}
                        />
                    </div>
                }
                {
                    this.state.conversion.lookalike_default &&
                    this.renders.audiences().filter((item) => {
                        return item.look_a_like_selected
                    }).map((item) => {
                        return(
                            <div className='flex flex-row mb-3'>
                                <div style={{backgroundColor: "#fcfcfc"}} className="Audience-item-value relative flex flex-row">
                                    {
                                        !item.error &&
                                        <span>{item.name}</span>
                                    }
                                    {
                                        item.error &&
                                        <span>{item.message}</span>
                                    }
                                    {
                                        item.loading &&
                                        <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#667eea'}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                    <span
                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full ml-3",
                                            {
                                                ["bg-green-100 text-green-500"]: item.type === "LOOKALIKE",
                                                ["bg-purple-100 text-purple-500"]: item.type === "WEBSITE",
                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.type === "SHOPPING"
                                            })
                                        }>
                                                            {item.type}
                                    </span>
                                </div>
                                {
                                    <div className="Audience-item-button">
                                        <div onClick={() => {
                                            item.look_a_like_selected = false;
                                            this.setState({
                                                conversion: this.state.conversion
                                            }, () => {
                                                this.props.updateConversion(this.state.conversion);
                                            })
                                        }} className="Button ButtonFull ButtonRed">
                                            <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                {
                    this.state.conversion.remarketing_default && this.renders.type() !== 'dynamic' &&
                    <div className="pb-3">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select remarketing audience"}
                                size={'full'}
                                search={true}
                                searchFilter={true}
                                showError={true}
                                filter={true}
                                showType={true}
                                loading={this.state.loading}
                                placeholder={this.state.loading?"Loading audience":"Search audience ..."}
                                options={this.renders.audiences().filter((item) => {
                                    return !item.remarketing_selected && !item.not_done
                                }).map((item) => {
                                    return item
                                })}
                                value={this.state.remarketing_audience?this.state.remarketing_audience:{}}
                                onChange={(value) => {
                                    if(value.status_code === 200){
                                        value.remarketing_selected = true;
                                        this.setState({
                                            conversion: this.state.conversion
                                        }, () => {
                                            this.props.updateConversion(this.state.conversion);
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.conversion.remarketing_default && this.renders.type() !== 'dynamic' &&
                    this.renders.audiences().filter((item) => {
                        return item.remarketing_selected
                    }).map((item) => {
                        return(
                            <div className='flex flex-row mb-3 '>
                                <div style={{backgroundColor: "#fcfcfc"}} className="Audience-item-value">
                                    {item.name}
                                    <span
                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full ml-3",
                                            {
                                                ["bg-green-100 text-green-500"]: item.type === "LOOKALIKE",
                                                ["bg-purple-100 text-purple-500"]: item.type === "WEBSITE",
                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.type === "SHOPPING"
                                            })
                                        }>
                                                            {item.type}
                                    </span>
                                </div>
                                <div className="Audience-item-button">
                                    <div onClick={() => {
                                        item.remarketing_selected = false;
                                        this.setState({
                                            conversion: this.state.conversion
                                        }, () => {
                                            this.props.updateConversion(this.state.conversion);
                                        })
                                    }} className="Button ButtonFull ButtonRed">
                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    <div className="">
                        <Dropdown
                            filter={false}
                            title={'Select conversion event'}
                            size={'full'}
                            placeholder={"Click here"}
                            options={[
                                {name: "Made a purchase", value: "PURCHASED"},
                                {name: "Initiated checkout", value: "INITIATED_CHECKOUT"},
                                {name: "Added to cart", value: "ADDED_TO_CART"},
                                {name: "Registered/Signed up", value: "REGISTER"}
                            ]}
                            value={typeof this.state.conversion.facebook === "object"?(typeof this.state.conversion.facebook.conversion_event === "object"?this.state.conversion.facebook.conversion_event:{}):{}}
                            onChange={(value) => {
                                if(typeof this.state.conversion.facebook !== "object"){
                                    this.state.conversion.facebook = {
                                        audiences: [],
                                        conversion_event: value,
                                        conversion_dynamic_event: {}
                                    };
                                }else{
                                    this.state.conversion.facebook.conversion_event = value;
                                }
                                this.setState({
                                    conversion: this.state.conversion
                                }, () => {
                                    this.props.updateConversion(this.state.conversion);
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.conversion.remarketing_default && this.renders.type() === 'dynamic' &&
                    <div className="flex flex-1 flex-row justify-between mt-3">
                        <Dropdown
                            filter={false}
                            title={'Target audience'}
                            size={'half'}
                            placeholder={"Click here"}
                            options={[
                                {name: "Made a purchase", value: "PURCHASED"},
                                {name: "Added to cart", value: "ADDED_TO_CART"},
                                {name: "Viewed product", value: "VIEW_CONTENT"}
                            ]}
                            value={typeof this.state.conversion.facebook === "object"?(typeof this.state.conversion.facebook.conversion_dynamic_event === "object"?this.state.conversion.facebook.conversion_dynamic_event:{}):{}}
                            onChange={(value) => {
                                if(typeof this.state.conversion.facebook !== "object"){
                                    this.state.conversion.facebook = {
                                        audiences: [],
                                        conversion_event: {},
                                        conversion_dynamic_event: value,
                                        conversion_dynamic_days: {}
                                    };
                                }else{
                                    this.state.conversion.facebook.conversion_dynamic_event = value;
                                }
                                this.setState({
                                    conversion: this.state.conversion
                                }, () => {
                                    this.props.updateConversion(this.state.conversion);
                                })
                            }}
                        />
                        <Dropdown
                            filter={false}
                            title={'Retention days'}
                            size={'half'}
                            placeholder={"Click here"}
                            options={this.renders.days()}
                            value={typeof this.state.conversion.facebook === "object"?(typeof this.state.conversion.facebook.conversion_dynamic_days === "object"?this.state.conversion.facebook.conversion_dynamic_days:{}):{}}
                            onChange={(value) => {
                                if(typeof this.state.conversion.facebook !== "object"){
                                    this.state.conversion.facebook = {
                                        audiences: [],
                                        conversion_event: {},
                                        conversion_dynamic_event: {},
                                        conversion_dynamic_days: value
                                    };
                                }else{
                                    this.state.conversion.facebook.conversion_dynamic_days = value;
                                }
                                this.setState({
                                    conversion: this.state.conversion
                                }, () => {
                                    this.props.updateConversion(this.state.conversion);
                                })
                            }}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default ConversionFacebookProspect;
