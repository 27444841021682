import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import ReactTooltip from "react-tooltip";
import cx from "classnames";

class OptimizeSocialItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            sort: {},
            loading: true
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            sort: nextProps.sort
        })
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
            sort: this.props.sort
        }, () => {
            this.init.ad();
        });
    }

    init = {
        ad: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getAd(this.state.item.internal, this.state.item.id).then((response) => {
                    this.state.item.status = response.data.status;
                    this.state.item.impressions = response.data.impressions;
                    this.state.item.click = response.data.click;
                    this.state.item.cpc = response.data.cpc;
                    this.state.item.ctr = response.data.ctr;
                    this.state.item.spend = response.data.spend;
                    this.state.item.conversions = response.data.conversions;
                    this.state.item.currency = response.data.currency;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onChange(this.state.item);
                    });
                }, (error) => {
                    this.state.item.error = true;
                    this.state.item.error_message = error.body.message;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onChange(this.state.item);
                    });
                });
            })
        }
    };

    functions = {
        update: (status) => {
            this.setState({
                loading: true
            }, () => {
                calls.updateAd(this.state.item.internal, this.state.item.id, status).then((response) => {
                    this.state.item.status = response.data.status;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onChange(this.state.item);
                    });
                }, (error) => {
                    this.setState({
                        error_modal: true,
                        error_message: error.body.message,
                        loading: false
                    });
                });
            });
        }
    };

    renders = {
        width: (key) => {
            if(key === 'name'){
                return {width: '100px'}
            }
            else if(key === 'type'){
                return {width: '100px'}
            }
            else if(key === 'impressions'){
                return {width: '100px'}
            }
            else if(key === 'click'){
                return {width: '100px'}
            }
            else if(key === 'ctr'){
                return {width: '100px'}
            }
            else if(key === 'cpc'){
                return {width: '100px'}
            }
            else if(key === 'spend'){
                return {width: '100px'}
            }
            else if(key === 'conversions'){
                return {width: '100px'}
            }
        },
        ending: (key) => {
            if(key === 'ctr'){
                return "%"
            }else if(key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion'){
                return this.state.item.currency
            }else{
                return  "";
            }
        },
        keys: () => {
            return ['name', 'type', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
        },
        active: () => {
            try {
                return this.state.item.status.toLowerCase() === 'active' || this.state.item.status.toLowerCase() === 'enabled'
            } catch (e) {
                return false;
            }
        },
        creative: () => {
            try {
                return this.state.item.items[0].creative_link?this.state.item.items[0].creative_link:this.state.item.items[0].creative;
            } catch (e) {
                return null
            }
        },
    };

    render() {
        let frames = [];
        if (this.state.item.type === 'carousel') {
            frames = this.state.item.items.filter((item) => {
                return item.type === 'image' || item.type === 'video';
            }).map((item, index) => {
                if (item.type === 'image') {
                    return (
                        <img key={index} src={item.creative_link?item.creative_link:item.creative} style={{width: '100%', height: '100%'}}/>
                    )
                } else if (item.type === 'video') {
                    return (
                        <video key={index} controls={false} muted={true} autoPlay={true} loop={true}
                               style={{width: '100%', height: '100%'}}>
                            <source src={item.creative_link?item.creative_link:item.creative} type="video/mp4"/>
                        </video>
                    )
                }
            });
        }
        return (
            <div className="Optimize-Social-Item">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div className="Optimize-Social-Item-Channel">
                    {
                        this.state.item.channel === 'facebook' && !this.state.item.combined &&
                        <div
                            className="Optimize-Social-Item-Channel-Facebook">
                            <img style={{width: '12px'}}
                                 src={require('../../assets/images/facebook_icon.svg')}/>
                        </div>
                    }
                    {
                        this.state.item.channel === 'instagram' && !this.state.item.combined &&
                        <div
                            className="Optimize-Social-Item-Channel-Instagram">
                            <img style={{width: '25px'}}
                                 src={require('../../assets/images/instagram_icon.svg')}/>
                        </div>
                    }
                    {
                        this.state.item.combined &&
                        <div className="Optimize-Social-Item-Channel-Combined">
                            <div className="Optimize-Social-Item-Channel-Combined-Facebook"></div>
                            <img className="Optimize-Social-Item-Channel-Combined-Facebook-icon" src={require('../../assets/images/facebook_icon.svg')}/>
                            <div className="Optimize-Social-Item-Channel-Combined-Instagram"></div>
                            <img className="Optimize-Social-Item-Channel-Combined-Instagram-icon" src={require('../../assets/images/instagram_icon.svg')}/>
                        </div>
                    }
                </div>
                <div className="Optimize-Social-Item-Channel-Preview">
                    {
                        this.state.item.type === 'image' &&
                        <img src={this.renders.creative()} style={{width: '100%'}}/>
                    }
                    {
                        this.state.item.type === 'video' &&
                        <video controls={false} loop={true}
                               autoPlay={true}
                               muted={true}
                               style={{width: '100%', height: '100%'}}>
                            <source src={this.renders.creative()} type="video/mp4"/>
                        </video>
                    }
                    {
                        this.state.item.type === 'carousel' &&
                        <Carousel interval={5000} loop auto frames={frames}/>
                    }
                </div>
                <div className="Optimize-Social-Item-Values">
                    {
                        this.state.loading &&
                        <div className="Optimize-Social-Item-Values-Loading">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        this.renders.keys().map((key) => {
                            return(
                                <div style={this.renders.width(key)} className={
                                    cx("Optimize-Social-Item-Section",
                                        {
                                            ["Optimize-Social-Item-Section-Selected"]: this.state.sort.value === key,
                                        })
                                }>
                                    <div className="Optimize-Social-Item-Section-Title">
                                        {key}
                                    </div>
                                    <div data-tip-disable={key !== 'name'} data-tip={(this.state.item[key] + ' ' + this.renders.ending(key))} className="Optimize-Social-Item-Section-Value">
                                        {this.state.item[key]}{" "}{this.renders.ending(key)}
                                        <ReactTooltip />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="Optimize-Social-Item-Section Optimize-Social-Item-Section-Slider">
                        <Switch
                            onColor="#24bda8"
                            checked={this.renders.active()}
                            onChange={(checked) => {
                                let status = 'activate';
                                if(this.renders.active()){
                                    status = 'disable';
                                }
                                this.functions.update(status);
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default OptimizeSocialItem;
