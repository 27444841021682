import React, { Component } from 'react';
import { DotsHorizontalIcon, GlobeIcon } from '@heroicons/react/solid'
import { ThumbUpIcon, AnnotationIcon, ShareIcon, XIcon } from '@heroicons/react/outline';

class PreviewFacebookFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cta: {},
            page: {},
            text: "",
            video: null,
            thumbnail: null,
            image: null,
            body: "",
            description: "",
            title: "",
            link: "",
            caption: "",
            adData: {},
            read_more: false,
            for_report: false,
            is_post: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            cta: this.props.cta,
            page: this.props.page,
            text: this.props.text,
            video: this.props.video,
            thumbnail: this.props.thumbnail,
            image: this.props.image,
            body: this.props.body,
            description: this.props.description,
            title: this.props.title,
            link: this.props.link,
            caption: this.props.caption,
            adData: this.props.adData,
            for_report: this.props.for_report,
            is_post: this.props.is_post
        })

        if (this.state.adData && this.state.adData.is_post) {
            await this.promisedSetState({
                is_post: true
            })
        }

    }

    componentWillReceiveProps(nextProps) {
        this.promisedSetState({
            cta: nextProps.cta,
            page: nextProps.page,
            text: nextProps.text,
            video: nextProps.video,
            thumbnail: nextProps.thumbnail,
            image: nextProps.image,
            body: nextProps.body,
            description: nextProps.description,
            title: nextProps.title,
            link: nextProps.link,
            caption: nextProps.caption,
            adData: nextProps.adData,
            for_report: nextProps.for_report,
            // is_post: nextProps.is_post
        })
        setTimeout(() => {
            try {
                if (this.refs.videoOne) {
                    this.refs.videoOne.setAttribute("muted", true);
                }
                if (this.refs.videoTwo) {
                    this.refs.videoTwo.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error);
            }
        }, 200);
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        },

    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        link: () => {
            try {
                let fullUrl = new URL(this.state.link);
                let hostUrl = fullUrl.host;
                return this.state.caption !== "" ? this.state.caption : hostUrl
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.cta.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.cta.name;
                    }
                }
            } catch (e) {
                return null
            }
        },
        ratioCheck: () => {
            try {
                // REMOVED || this.state.adData.ad_type.value === "image_ad" || this.state.adData.ad_type.value === "video_ad" || this.state.adData.ad_type === "image" || this.state.adData.ad_type === "video"
                if (this.state.adData.ad_type.value === "posts") {
                    return true
                } else if (this.state.adData.ad_type.value !== "merge_ad") {
                    if (this.state.adData.file.ratio === "9:16") {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        }
    };

    onLoadedMetadata = () => {
        if (this.videoRefTwo.current) {
            this.videoRefTwo.current.setAttribute("muted", true);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                <div className='w-80 py-3 rounded-lg border shadow-xl bg-white'
                    style={{ height: "fit-content" }}
                >
                    {console.log("STATE", this.state)}
                    <div className='px-3 pb-3 border-b border-gray-200'>
                        <div className='flex pb-3'>
                            <div className='flex flex-row flex-1'>
                                {
                                    this.state.page && this.state.page.image &&
                                    <div>
                                        <div className='h-10 w-10 mr-2 border-gray-200'>

                                            <img src={this.state.page.image} alt={this.state.page.name}
                                                className='rounded-full'
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.page && !this.state.page.image &&
                                    <div className='h-10 w-10 mr-2 border rounded-full'></div>
                                }
                                <div className='flex flex-1 flex-col'>
                                    <div className='text-sm font-bold'>{this.state.page && this.state.page.name ? this.state.page.name : "Select page"}</div>
                                    <div className='flex items-center text-xs text-gray-500'>
                                        Sponsored
                                        <span className='mx-1'>&#183;</span>
                                        <span><GlobeIcon style={{ width: "14px" }} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-row'>
                                    <DotsHorizontalIcon className='w-5 mr-3' />
                                    <XIcon className='w-7' />
                                </div>
                                <div className='flex flex-1'></div>
                            </div>
                        </div>
                        <div>
                            {
                                this.state.body !== "" ?
                                    <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                    </span>
                                    :
                                    "Body ..."
                            }
                            {
                                !this.state.read_more &&
                                this.renders.body().length > 125 &&
                                <span
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            read_more: true
                                        });
                                    }} className="cursor-pointer font-semibold text-sm text-center pb-1">
                                    {" "}See more
                                </span>
                            }
                        </div>
                    </div>
                    <div className='w-full'>
                        {/* REMOVED RATIOCHECK */}
                        {!this.state.for_report &&
                            <div style={this.renders.ratioCheck() ? { height: 318 } : { height: "100%" }}>
                                {
                                    this.state.image &&
                                    <img src={this.state.image} alt={this.state.name} className="w-full h-full object-cover" />
                                }
                                {
                                    this.state.video &&
                                    <>
                                        <video poster={this.state.thumbnail} src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoOne" />
                                    </>
                                }
                            </div>
                        }
                        {
                            this.state.for_report && !this.renders.ratioCheck() && this.state.video !== null && (
                                <div style={{ height: 318 }}>
                                    {this.state.image && (
                                        <img src={this.state.image} alt={this.state.name} className="w-full h-full object-cover" />
                                    )}
                                    {this.state.video && (
                                        <>
                                            <video poster={this.state.thumbnail} src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoTwo" />
                                        </>
                                    )}
                                </div>
                            )
                        }
                        {
                            this.state.for_report && this.state.video === null && this.state.thumbnail !== null &&
                            <div style={{ height: 318 }}>
                                <img src={this.state.thumbnail} alt={this.state.name} className='w-full h-full object-cover' />
                            </div>
                        }
                    </div>
                    <div className='flex w-full p-3 justify-between items-center border-b border-gray-200 bg-gray-100'>
                        <div
                            style={{ maxWidth: 180 }}
                            className='flex flex-col flex-0'>
                            <div className='truncate text-xs text-gray-700'>
                                {this.state.link && this.renders.link()}
                            </div>
                            {
                                !this.state.is_post &&
                                <>
                                    <div className='text-sm font-bold'>{this.state.title ? this.functions.truncText(this.renders.title(), 40) : "Headline..."}</div>
                                    <div className='text-sm text-gray-700'>{this.state.description !== "" ? this.functions.truncText(this.renders.description(), 30) : this.state.description === "" && this.state.for_report ? "" : "Description..."}</div>
                                </>
                            }
                        </div>
                        <div
                            className='flex flex-1 justify-end'>
                            <div className='w-fit text-sm rounded-lg px-4 py-2 bg-gray-300 font-bold whitespace-no-wrap'>
                                {this.state.cta ? this.state.cta.name : ''}
                            </div>
                        </div>
                    </div>
                    <div className='pt-4'>
                        <div className='flex justify-around text-xms text-gray-600 font-bold border-t pt-3 mx-3 border-gray-300'>
                            <div className='flex items-center'><span><ThumbUpIcon className='w-5 mr-1' /></span>Like</div>
                            <div className='flex items-center'><span><AnnotationIcon className='w-5 mr-1' /></span>Comment</div>
                            <div className='flex items-center'><span><ShareIcon className='w-5 mr-1' /></span>Share</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewFacebookFeed;
