import React, { Component, Fragment } from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import { BeatLoader } from "react-spinners";
import { SlideDown } from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import { calls } from "./calls";
import CampaignDonuts2 from "../../components/campaign-donuts-2";
import { tokenRegister } from '../../services/tokenRegister';
import { apiRegister } from '../../services/apiRegister';

class CampaignsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignitems: [],
            sort: "",
            order: "",
            total: 0,
            selected_campaigns: {}
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            campaignitems: this.props.campaignitems,
            sort: this.props.sort,
            order: this.props.order,
            total: this.props.total,
            loading: this.props.loading,
            selected_campaigns: this.props.selected_campaigns ? this.state.selected_campaigns : {}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaignitems: nextProps.campaignitems,
            sort: nextProps.sort,
            order: nextProps.order,
            total: nextProps.total,
            loading: nextProps.loading,
            selected_campaigns: nextProps.selected_campaigns ? nextProps.selected_campaigns : {}
        })
    }

    functions = {
        order: (item) => {
            item.loading = true;
            this.setState({
                campaignitems: this.state.campaignitems
            }, () => {
                this.calls.getOrderReport({ id: item.id }).then((response) => {
                    item.tab = {
                        cells: response.data
                    };
                    item.loading = false;
                    this.setState({
                        campaignitems: this.state.campaignitems
                    })
                }, (error) => {
                    item.loading = false;
                    item.error = true;
                    this.setState({
                        campaignitems: this.state.campaignitems
                    })
                });
            })
        }
    };

    calls = {
        getOrderReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/v3/orderReport?order=" + data.id;
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <div className="flex flex-col my-5">
                {this.state.campaignitems.length > 0 && this.props.headline &&
                    <h3 className="font-bold text-sm mb-2">{this.props.headline}</h3>
                }
                {this.state.campaignitems.length > 0 &&
                    <div className="-my-2 overflow-x-auto">
                        <div className="py-2 align-middle inline-block min-w-full">
                            <div className="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200 bg-white">
                                    <thead>
                                        <tr>
                                            {
                                                this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                                <th className="transition-all duration-200 pl-6 pr-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                                            }
                                            {/** Exempel på class som sätts vid aktiv: sort-desc eller sort-asc på th-nivå */}
                                            <th onClick={() => {
                                                try {
                                                    this.props.updateSort('name', 'name' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                } catch (e) { }
                                            }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                <div className="flex flex-row items-center">
                                                    Campaign name <div className="sorting-arrows ml-2"></div>
                                                </div>
                                            </th>
                                            {this.props.properties && this.props.properties.client &&
                                                <th onClick={() => {
                                                    try {
                                                        this.props.updateSort('client', 'client' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                    } catch (e) { }
                                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'client' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                                    <div className="flex flex-row items-center">
                                                        Client <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {this.props.properties && this.props.properties.created &&
                                                <th onClick={() => {
                                                    try {
                                                        this.props.updateSort('created', 'created' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                    } catch (e) { }
                                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'created' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                                    <div className="flex flex-row items-center">
                                                        Created <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {this.props.properties && this.props.properties.status &&
                                                <th onClick={() => {
                                                    try {
                                                        this.props.updateSort('status', 'status' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                    } catch (e) { }
                                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'status' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        Status <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {this.props.properties && this.props.properties.start_date &&
                                                <th onClick={() => {
                                                    try {
                                                        this.props.updateSort('startDate', 'startDate' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                    } catch (e) { }
                                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'startDate' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        Start date <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {this.props.properties && this.props.properties.end_date &&
                                                <th onClick={() => {
                                                    try {
                                                        this.props.updateSort('endDate', 'endDate' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                    } catch (e) { }
                                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'endDate' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="flex flex-row items-center">
                                                        End date <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {this.props.properties && this.props.properties.channels &&
                                                <th className="transition-all duration-200 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    <div className="flex flex-row items-center">
                                                        Channels {false && <div className="sorting-arrows ml-2"></div>}
                                                    </div>
                                                </th>
                                            }
                                            <th className="transition-all duration-200 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <div className="flex flex-row items-center justify-end">
                                                    Actions{false && <div className="sorting-arrows ml-2"></div>}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {this.state.campaignitems.sort((a, b) => {
                                            if (this.state.sort) {
                                                var nameA = null;
                                                var nameB = null;
                                                try {
                                                    nameA = a[this.state.sort].toLowerCase();
                                                } catch (e) {
                                                    nameA = a[this.state.sort];
                                                }
                                                try {
                                                    nameB = b[this.state.sort].toLowerCase();
                                                } catch (e) {
                                                    nameB = b[this.state.sort];
                                                }
                                                if (this.state.order === 'desc') {
                                                    if (nameA > nameB)
                                                        return -1;
                                                    if (nameA < nameB)
                                                        return 1;
                                                    return 0
                                                } else {
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0
                                                }
                                            } else {
                                                return 1
                                            }
                                        }).map((item, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <tr>
                                                        {
                                                            this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                                            <td className="pr-3 pl-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                                <div className="flex flex-row items-center justify-start">
                                                                    {this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                                                        <button onClick={() => {
                                                                            item.open = !item.open;
                                                                            this.setState({
                                                                                campaignitems: this.state.campaignitems
                                                                            }, () => {
                                                                                if (item.open && !item.tab) {
                                                                                    this.functions.order(item);
                                                                                }
                                                                            });
                                                                        }} className={"focus:outline-none hover:text-blue-500 " + (item.open ? 'text-red-500' : 'text-purple-500')}>
                                                                            <FeatherIcon className="stroke-current" size={15} icon={item.open ? 'eye-off' : 'eye'} />
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </td>
                                                        }
                                                        <td className="px-3 py-4 max-w-xs">
                                                            <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'name' ? 'font-bold' : '')}>
                                                                {this.props.properties && this.props.properties.actions && this.props.properties.actions.open && (!this.props.draft || (this.props.draft && item.version === "2"))
                                                                    ? <span onClick={() => {
                                                                        try {
                                                                            this.props.callfunc(item)
                                                                        } catch (e) {
                                                                        }
                                                                    }} className={"cursor-pointer hover:text-blue-500 hover:underline"}>
                                                                        {item.name}
                                                                    </span>

                                                                    : <span>{item.name}</span>
                                                                }
                                                            </div>
                                                        </td>
                                                        {this.props.properties && this.props.properties.client &&
                                                            <td className="px-3 py-4 whitespace-no-wrap">
                                                                <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'client' ? 'font-bold' : '')}>
                                                                    {item.client ? item.client : '-'}
                                                                </div>
                                                            </td>
                                                        }
                                                        {this.props.properties && this.props.properties.created &&
                                                            <td className="px-3 py-4 whitespace-no-wrap text-sm">
                                                                <div className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'created' ? 'font-bold' : '')}>
                                                                    {item.created ? moment(item.created).format('DD MMMM') : '-'}
                                                                </div>
                                                                <div className={"text-xs leading-5 " + (this.state.sort === 'created' ? 'text-gray-900' : 'text-gray-500')}>
                                                                    {item.created ? moment(item.created).format('DD/MM/YYYY') : '-'}
                                                                </div>
                                                            </td>
                                                        }
                                                        {this.props.properties && this.props.properties.status &&
                                                            <td className="px-3 py-4 whitespace-no-wrap capitalize">
                                                                <span
                                                                    className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                        {
                                                                            ["bg-green-100 text-green-500"]: item.status === "active",
                                                                            ["bg-purple-100 text-purple-500"]: item.status === "ended",
                                                                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                            ["bg-yellow-100 text-yellow-600"]: item.status === "pending",
                                                                            ["bg-red-100 text-red-600"]: item.status === "error",
                                                                        })
                                                                    }>
                                                                    {item.status}
                                                                </span>
                                                            </td>
                                                        }
                                                        {this.props.properties && this.props.properties.start_date &&
                                                            <td className="px-3 py-4 whitespace-no-wrap text-sm">
                                                                <div className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'startDate' ? 'font-bold' : '')}>
                                                                    {moment(item.startDate).format('DD MMMM')}
                                                                </div>
                                                                <div className={"text-xs leading-5 " + (this.state.sort === 'startDate' ? 'text-gray-900' : 'text-gray-500')}>
                                                                    {moment(item.startDate).format('DD/MM/YYYY')}
                                                                </div>
                                                            </td>
                                                        }
                                                        {this.props.properties && this.props.properties.end_date &&
                                                            <td className="px-3 py-4 whitespace-no-wrap text-sm">
                                                                <div className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'endDate' ? 'font-bold' : '')}>
                                                                    {item.endDate ? moment(item.endDate).format('DD MMMM') : "No end date"}
                                                                </div>
                                                                <div className={"text-xs leading-5 " + (this.state.sort === 'endDate' ? 'text-gray-900' : 'text-gray-500')}>
                                                                    {item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : "..."}
                                                                </div>
                                                            </td>
                                                        }
                                                        {this.props.properties && this.props.properties.channels &&
                                                            <td className="px-3 py-4 whitespace-no-wrap">
                                                                <div className="flex flex-row">
                                                                    {item.channels && item.channels.facebook &&
                                                                        <div
                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {item.channels && item.channels.instagram &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white ' + (item.channels.facebook ? '-ml-2' : '')}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {item.channels && item.channels.linkedin &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white ' + ((item.channels.instagram || item.channels.facebook) ? '-ml-2' : '')}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {item.channels && item.channels.google &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram) ? '-ml-2' : '')}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {item.channels && item.channels.google_shopping &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram || item.channels.google) ? '-ml-2' : '')}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../../assets/images/google-shopping.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        }
                                                        <td className="px-3 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                            <div className="flex flex-row items-center justify-end">
                                                                {this.props.properties && this.props.properties.actions && this.props.properties.actions.open && (!this.props.draft || (this.props.draft && item.version === "2")) &&
                                                                    <button onClick={() => {
                                                                        try {
                                                                            this.props.callfunc(item)
                                                                        } catch (e) {
                                                                        }
                                                                    }} className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white " + ((this.props.properties && this.props.properties.actions && this.props.properties.actions.delete) ? 'ml-2' : '')}>
                                                                        <span>Select campaign</span>
                                                                        <FeatherIcon className="stroke-current ml-2" size={16}
                                                                            icon="arrow-right-circle" />
                                                                    </button>
                                                                }
                                                                {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete &&
                                                                    <button onClick={() => {
                                                                        try {
                                                                            this.props.delete(item)
                                                                        } catch (e) {
                                                                        }
                                                                    }}
                                                                        className="ml-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                        <span>Delete</span>
                                                                        <FeatherIcon className="stroke-current ml-2" size={15}
                                                                            icon="trash" />
                                                                    </button>
                                                                }
                                                                {this.props.properties && this.props.properties.actions && this.props.properties.actions.select &&
                                                                    <div onClick={() => {
                                                                        if (this.props.updateSelected) {
                                                                            if (this.state.selected_campaigns[item.id]) {
                                                                                delete this.state.selected_campaigns[item.id];
                                                                            } else {
                                                                                this.state.selected_campaigns[item.id] = item;
                                                                            }
                                                                            this.props.updateSelected(this.state.selected_campaigns);
                                                                        }
                                                                    }} className={((this.state.selected_campaigns[item.id]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.selected_campaigns[item.id] &&
                                                                            <FeatherIcon className={'stroke-current'} size={14} icon={"check"} />
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                                        <tr style={{ borderTop: 'none' }}>
                                                            <td colspan="10">
                                                                <SlideDown closed={!item.open}>
                                                                    <div className="flex flex-1 pl-2 pr-2 box-border">
                                                                        {
                                                                            item.tab &&
                                                                            <CampaignDonuts2
                                                                                border={true}
                                                                                tab={item.tab}
                                                                                data={{}}
                                                                                loading={item.loading}
                                                                                properties={['click', 'impressions', 'ctr', 'spend', 'cpc']}
                                                                                showchannels={false}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </SlideDown>
                                                            </td>
                                                        </tr>
                                                    }
                                                </Fragment>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="mt-8 mb-8">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Campaigns ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
                {this.state.campaignitems.length === 0 && this.props.clientname && !this.state.loading &&
                    <div className="mt-10 text-center">
                        <p>Sorry, but <span className="font-bold">{this.props.clientname}</span> doesn't have any campaigns
                        </p>
                        <a href="#" onClick={(e) => {
                            try {
                                e.preventDefault();
                                this.props.history.push('/user/campaigns/new')
                            } catch (e) {
                            }
                        }} className="btn inline-block btn-primary mt-3">+ Create a campaign for {this.props.clientname}</a>
                    </div>
                }
            </div>
        )
    }
}

export default CampaignsList;