import React, {Component} from 'react';
import './style.css';

class UserProfileBilling extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div className="Profile">
                <div className="Profile-Inner">

                </div>
            </div>
        )
    }
}

export default UserProfileBilling;
