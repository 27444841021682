import React, { Component, Fragment } from 'react';
import { HomeIcon, SearchIcon, PlusIcon, ChatIcon, UserIcon, HeartIcon } from '@heroicons/react/solid'
import { ChevronRightIcon, ReplyIcon, PlayIcon, PauseIcon, RefreshIcon } from '@heroicons/react/outline';

class PreviewTikTokFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cta: {},
            page: {},
            text: "",
            video: null,
            thumbnail: null,
            show_overlay: false,
            video_ended: false,
            video_playing: false,
        }
    };

    componentDidMount() {
        this.setState({
            cta: this.props.cta,
            page: this.props.page,
            text: this.props.text,
            video: this.props.video,
            thumbnail: this.props.thumbnail,
            show_overlay: this.props.show_overlay,
            video_ended: this.props.video_ended,
            video_playing: this.props.video_playing
        });

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
                this.refs.video.addEventListener('ended', this.functions.videoEnded, false);
            } catch (error) {
                console.log(error)
            }
        }, 200)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cta: nextProps.cta,
            page: nextProps.page,
            text: nextProps.text,
            video: nextProps.video,
            thumbnail: nextProps.thumbnail,
            show_overlay: nextProps.show_overlay,
            video_ended: nextProps.video_ended,
            video_playing: nextProps.video_playing
        })
    }

    functions = {
        handleClickVideo: async () => {
            if (this.refs.video.ended) {
                await this.promisedSetState({ video_ended: true, video_playing: false });
                this.refs.video.currentTime = 0;
                this.refs.video.play();
                await this.promisedSetState({ video_ended: false, video_playing: true });
            } else if (this.refs.video.paused) {
                this.refs.video.play();
                await this.promisedSetState({ video_playing: true });
            } else {
                this.refs.video.pause();
                await this.promisedSetState({ video_playing: false });
            }
        },
        videoEnded: async () => {
            await this.promisedSetState({ video_ended: true, video_playing: false })
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    render() {
        return (

            <div className='flex flex-col justify-center'>
                <div className="w-80 flex items-center justify-center">
                    <div
                        className="w-80 rounded-md overflow-hidden">
                        {/* style={{ width: "351px" }} */}
                        <div
                            onMouseEnter={async () => {
                                await this.promisedSetState({
                                    show_overlay: true
                                });
                            }}
                            onMouseLeave={async () => {
                                await this.promisedSetState({
                                    show_overlay: false
                                });
                            }}
                            style={{ height: "624px" }} className="w-full bg-gray-900 relative flex justify-center items-center overflow-hidden">

                            {this.state.video &&
                                <div
                                    className="absolute flex flex-col justify-center items-center inset-0"
                                >
                                    {
                                        this.state.show_overlay &&
                                        <div
                                            className="absolute z-50 h-full w-full flex flex-col justify-center items-center bg-black bg-opacity-0 hover:bg-opacity-25 transition-all duration-300">
                                            <div
                                                onClick={() => this.functions.handleClickVideo()}
                                                className="z-60 flex flex-col justify-center items-center w-12 h-12 rounded-full bg-white opacity-75 cursor-pointer">
                                                {
                                                    !this.state.video_playing && !this.state.video_ended &&
                                                    <PlayIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                                }
                                                {
                                                    this.state.video_playing &&
                                                    <PauseIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                                }
                                                {
                                                    this.state.video_ended && !this.state.video_playing &&
                                                    <RefreshIcon className="text-gray-600 h-10 w-10 stroke-1" />
                                                }
                                            </div>
                                        </div>
                                    }
                                    <video src={this.state.video} autoplay={false} controls={false} muted className="w-full" ref="video" />
                                </div>
                            }
                            <div className="absolute text-sm text-white p-4 top-0 left-0 right-0 flex rounded-md justify-center items-center">
                                <span className="opacity-50">Following</span> <span className="ml-4 font-semibold">For You</span>
                            </div>
                            <div className="absolute right-0 top-0 bottom-0 pr-6">
                                <div className="justify-center items-end flex h-full flex-col">
                                    {
                                        this.state.page && this.state.page.id !== 0 &&
                                        <div className="w-12 h-12 rounded-full overflow-hidden mb-4">
                                            <img src={this.state.page.image} className="w-full h-full" />
                                        </div>
                                    }
                                    <div>
                                        <div className="text-white text-xs justify-center items-center flex flex-col mb-4">
                                            <HeartIcon className="w-10 h-10 text-white" />
                                            71.1k
                                        </div>
                                        <div className="text-white text-xs justify-center items-center flex flex-col mb-4">
                                            <ChatIcon className="w-10 h-10 text-white" />
                                            1281
                                        </div>
                                        <div className="text-white text-xs justify-center items-center flex flex-col">
                                            <ReplyIcon className="w-10 h-10 text-white" />
                                            232
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute p-6 bottom-0 left-0 right-0">
                                {
                                    this.state.page && this.state.page.id !== 0 &&
                                    <div className="text-sm text-white flex flex-row mb-3">
                                        @{this.state.page.name}
                                    </div>
                                }
                                <div className="text-sm text-white flex flex-row mb-3 items-center">
                                    {this.state.text}
                                    <div><div className="bg-white ml-2 text-black text-xxs rounded-sm px-1">Ad</div></div>
                                </div>
                                <div className="h-10 w-full text-xs flex rounded-md justify-center items-center flex-row text-white bg-white bg-opacity-25">
                                    {this.state.cta ? this.state.cta.name : ""} <ChevronRightIcon className="w-3 h-3 ml-2 text-white" />
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-800 grid grid-cols-5 py-2">
                            <div className="col-span-1 flex items-center justify-center flex-col">
                                <HomeIcon className="w-6 h-6 text-white" />
                                <div className="text-xxs text-white">
                                    Home
                                </div>
                            </div>
                            <div className="col-span-1 flex items-center justify-center flex-col">
                                <SearchIcon className="w-6 h-6 text-white" />
                                <div className="text-xxs text-white">
                                    Discover
                                </div>
                            </div>
                            <div className="col-span-1 flex items-center justify-center">
                                <div className="bg-white rounded-lg w-10 h-8 flex items-center justify-center">
                                    <PlusIcon className="w-5 h-5 text-black" />
                                </div>
                            </div>
                            <div className="col-span-1 flex items-center justify-center flex-col">
                                <ChatIcon className="w-6 h-6 text-white" />
                                <div className="text-xxs text-white">
                                    Notifications
                                </div>
                            </div>
                            <div className="col-span-1 flex items-center justify-center flex-col">
                                <UserIcon className="w-6 h-6 text-white" />
                                <div className="text-xxs text-white">
                                    Me
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PreviewTikTokFeed;
