import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from 'react-spinners';
import cx from "classnames";
import SweetAlert from "sweetalert-react";
import Loader from "../components/loader";
import CreateNewReport from "../modules/createNewReport";
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';

const fuzzysort = require('fuzzysort');
var moment = require('moment');

class ReportStandardList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            loading: true,
            sort: "created",
            order: "desc",
            closed: true,
            submenu: "Standard Reports",
            search_prio: 'name'
        };
    }

    componentDidMount() {
        this.functions.reports();
    }

    functions = {
        reports: () => {
            this.calls.getReports().then((response) => {
                this.setState({
                    reports: response.data,
                    loading: false
                })
            }, (error) => {
                this.setState({
                    loading: false
                })
            });
        },
        delete: () => {
            this.setState({
                loading: true,
                remove_modal: false
            }, () => {
                this.calls.deleteReport({ id: this.state.item.id }).then((response) => {
                    this.setState({
                        loading: false,
                        reports: this.state.reports.filter((item) => {
                            return item.id !== this.state.item.id
                        }),
                        item: {}
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    renders = {
        reports: () => {
            let reports = [];
            return reports;
        },
        list: () => {
            if (this.state.search_prio === 'name') {
                return this.renders.clientSearch(this.renders.nameSearch(this.state.reports.filter((item) => {
                    if (item.freemium && this.state.submenu === "Standard Reports" && !item.template) {
                        return true;
                    } else if (item.premium && this.state.submenu === "Advanced Reports" && !item.template) {
                        return true;
                    } else if (item.template && this.state.submenu === "Templates") {
                        return true;
                    } else {
                        return false;
                    }
                })));
            } else {
                return this.renders.nameSearch(this.renders.clientSearch(this.state.reports.filter((item) => {
                    if (item.freemium && this.state.submenu === "Standard Reports" && !item.template) {
                        return true;
                    } else if (item.premium && this.state.submenu === "Advanced Reports" && !item.template) {
                        return true;
                    } else if (item.template && this.state.submenu === "Templates") {
                        return true;
                    } else {
                        return false;
                    }
                })));
            }
        },
        nameSearch: (options) => {
            let keywords = [];
            if (this.state.name_search && this.state.name_search !== "") {
                let clients = fuzzysort.go(this.state.name_search, options.map((item) => { return item.name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        },
        clientSearch: (options) => {
            let keywords = [];
            if (this.state.client_search && this.state.client_search !== "") {
                let clients = fuzzysort.go(this.state.client_search, options.map((item) => { return item.client_name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].client_name.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        }
    };

    //["Standard Reports", "Advanced Reports", "Templates"]

    validators = {};

    calls = {
        getReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/report";
            return apiRegister.call(options, url);
        },
        deleteReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v2/report/" + data.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <section className="relative h-full flex flex-col">

                <TopNavigationAgent
                    onButton={async (type) => {
                        if (type === "create") {
                            await this.promisedSetState({
                                closed: false
                            });
                            if (!this.state.closed) {
                                this.refs.CreateNewReport.functions.init();
                            }
                        }
                    }}
                    breadcrumb={"Standard reports"}
                    history={this.props.history}
                    buttonTypes={{
                        create_report: !this.state.loading
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading Reports ...</div>
                    </div>
                }

                <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                    style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                    <div className="absolute inset-0 overflow-hidden">
                        <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                            <div
                                className={`relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}>
                                <div className="absolute right-100 pr-6 pt-6 flex">
                                    <button aria-label="Close panel"
                                        className="bg-red-500 text-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                        onClick={() => {
                                            this.setState({
                                                closed: !this.state.closed
                                            })
                                        }}
                                    >
                                        <FeatherIcon className="stroke-current" size={16} icon="x" />
                                    </button>
                                </div>
                                <div className={"flex flex-row-reverse bg-white h-full shadow-xl"}>
                                    <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                        <header className="px-4 sm:px-6 mb-6 py-6 bg-gray-100">
                                            <h2 className="text-lg leading-7 text-gray-900">
                                                Create new report
                                            </h2>
                                        </header>
                                        <CreateNewReport
                                            report={this.state.report}
                                            template={this.state.template}
                                            ref="CreateNewReport"
                                            history={this.props.history}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {
                    <SweetAlert
                        show={this.state.remove_modal}
                        title={'Delete report'}
                        type="warning"
                        text={'Are you sure ?'}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            this.functions.delete();
                        }}
                        onCancel={() => {
                            this.setState({
                                remove_modal: false
                            });
                        }}
                    />
                }

                {
                    !this.state.loading && this.state.reports.length > 0 && (
                        <>
                            {
                                false &&
                                <div className="flex items-center justify-center">
                                    <div onClick={(e) => {
                                        this.setState({
                                            closed: false
                                        }, () => {
                                            if (!this.state.closed) {
                                                this.refs.CreateNewReport.functions.init();
                                            }
                                        })
                                    }} className="btn inline-block btn-primary">+ Create new report
                                    </div>
                                </div>
                            }
                            {
                                <div className="p-4">

                                    <div className="bg-white rounded-md flex-col flex  overflow-x-auto border-b border-gray-200 sm:rounded-lg shadow-lg">

                                        {
                                            false &&
                                            <div style={{ backgroundColor: "#fcfcfc", height: "52px" }} className="px-4 rounded-t-lg border-b-2 relative">
                                                <div>
                                                    <div className="navigation pl-2">
                                                        <nav role="navigation" aria-label="Social sub navigation">
                                                            <ul className="flex flex-row items-end">
                                                                {["Standard Reports", "Advanced Reports", "Templates"].map((key, j) => {
                                                                    return (
                                                                        <li key={j}
                                                                            className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'text-gray-500')}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    submenu: key,
                                                                                    client_search: key === 'Templates' ? '' : this.state.client_search
                                                                                });
                                                                            }}>
                                                                            {key}
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                                <li className="flex-grow border-b-2"></li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="py-4 px-6 flex flex-row w-full">
                                            <div className="w-64 mr-5">
                                                <div className="text-xs font-bold mb-1">
                                                    Name
                                                </div>
                                                <input
                                                    onChange={(event) => {
                                                        this.setState({
                                                            name_search: event.target.value
                                                        })
                                                    }}
                                                    value={this.state.name_search}
                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                />
                                            </div>
                                            {
                                                this.state.submenu !== "Templates" &&
                                                <div className="w-64">
                                                    <div className="text-xs font-bold mb-1">
                                                        Client
                                                    </div>
                                                    <input
                                                        onChange={(event) => {
                                                            this.setState({
                                                                client_search: event.target.value
                                                            })
                                                        }}
                                                        value={this.state.client_search}
                                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="border-b w-full" />
                                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                                            <thead>
                                                <tr>
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: 'name',
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 pr-3 pl-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            Name <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: 'type',
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            Report <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: 'template',
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'template' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            Type <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: 'client_name',
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 pr-3 pl-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'client_name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            Client <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: 'created',
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'created' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            Created <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                    <th className="transition-all duration-200 px-3 py-3 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {this.renders.list().sort((a, b) => {
                                                    if (this.state.sort) {
                                                        var nameA = null;
                                                        var nameB = null;
                                                        try {
                                                            nameA = a[this.state.sort].value.toLowerCase();
                                                        } catch (e) {
                                                            nameA = a[this.state.sort];
                                                        }
                                                        try {
                                                            nameB = b[this.state.sort].value.toLowerCase();
                                                        } catch (e) {
                                                            nameB = b[this.state.sort];
                                                        }
                                                        if (this.state.order === 'desc') {
                                                            if (nameA > nameB)
                                                                return -1;
                                                            if (nameA < nameB)
                                                                return 1;
                                                            return 0
                                                        } else {
                                                            if (nameA < nameB)
                                                                return -1;
                                                            if (nameA > nameB)
                                                                return 1;
                                                            return 0
                                                        }
                                                    } else {
                                                        return 1
                                                    }
                                                }).map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td className="pr-3 pl-6 py-4 whitespace-no-wrap max-w-xs">
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'name' ? 'font-bold' : '')}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-4 whitespace-no-wrap capitalize">
                                                                    <div
                                                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-gray-100 text-gray-500"]: item.freemium,
                                                                                ["bg-purple-100 text-purple-500"]: item.premium,
                                                                            })
                                                                        }>
                                                                        {item.freemium ? "standard" : "professional"}
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-4 whitespace-no-wrap capitalize">
                                                                    <div
                                                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-gray-100 text-gray-500"]: true,
                                                                            })
                                                                        }>
                                                                        {item.template ? "template" : "report"}
                                                                    </div>
                                                                </td>
                                                                <td className="pr-3 pl-6 py-4 whitespace-no-wrap max-w-xs">
                                                                    <div className="flex flex-row items-center">
                                                                        <div className={"text-sm leading-5 text-gray-900 cursor-pointer truncate " + (this.state.sort === 'client_name' ? 'font-bold' : '')}>
                                                                            {!item.template ? item.client_name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-4 whitespace-no-wrap text-sm">
                                                                    <div
                                                                        className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'created' ? 'font-bold' : '')}>
                                                                        {moment(item.created).format('DD MMMM')}
                                                                    </div>
                                                                    <div
                                                                        className={"text-xs leading-5 " + (this.state.sort === 'created' ? 'text-gray-900' : 'text-gray-500')}>
                                                                        {moment(item.created).format('DD/MM/YYYY')}
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                                    <div className="flex flex-row items-center justify-end">
                                                                        <button onClick={() => {
                                                                            this.props.history.push("/v2/reports/standard/" + item.campaign + "/" + item.id);
                                                                        }}
                                                                            className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                                            <span>Open</span>
                                                                            <FeatherIcon className="stroke-current ml-2"
                                                                                size={16} icon="arrow-right-circle" />
                                                                        </button>
                                                                        {
                                                                            false &&
                                                                            <button onClick={() => {
                                                                                this.setState({
                                                                                    closed: false,
                                                                                    report: item
                                                                                }, () => {
                                                                                    if (!this.state.closed) {
                                                                                        this.refs.CreateNewReport.functions.init();
                                                                                    }
                                                                                })
                                                                            }}
                                                                                className={"ml-2 bg-gray-200 bg-opacity-25 text-gray-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-500 hover:bg-opacity-100 hover:text-white"}>
                                                                                <span>Edit</span>
                                                                                <FeatherIcon className="stroke-current ml-2"
                                                                                    size={16} icon="edit" />
                                                                            </button>
                                                                        }
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                remove_modal: true,
                                                                                item: item
                                                                            })
                                                                        }}
                                                                            className={"ml-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white"}>
                                                                            <span>Delete</span>
                                                                            <FeatherIcon className="stroke-current ml-2"
                                                                                size={16} icon="trash" />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </>
                    )}
            </section>
        );
    }
}

export default ReportStandardList;
