import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconGoogle from "../icons/google";

class AdvertisingKeywordsLanguage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            languages: languages,
            selected_languages: [],
            keywords: {},
            site: {},
            channels: {},
            error: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            selected_languages: nextProps.languages?nextProps.languages:[],
            channels: nextProps.channels,
            site: nextProps.site,
        });
    }

    componentDidMount() {
        this.setState({
            open: this.props.open,
            selected_languages: this.props.languages?this.props.languages:[],
            channels: this.props.channels,
            site: this.props.site,
            error: this.props.error
        })
    }

    init = {};

    functions = {
        removeLanguage: (item) => {
            let languages = this.state.selected_languages.filter((item_inner) => {
                return item_inner.code !== item.code
            });
            if (item.selected && languages.length > 0) {
                languages[0].selected = true;
            }
            this.state.selected_languages = languages;
            this.setState({
                selected_languages: this.state.selected_languages
            }, () => {
                this.props.onChange(this.state.selected_languages);
            })
        }
    };

    validators = {};

    renders = {};

    render() {
        return (
            <div style={{width: '100%', paddingTop: '15px'}}>
                <div className="Section-Inner">
                    <div className="Section-InnerTitle">
                        <div className={cx("Upload-ImageContainerTitleText")}>
                            Keyword language
                        </div>
                        <div className={cx("InputContainerTitleIcons")}>
                            <div style={{marginLeft: '3px'}}>
                                <IconGoogle noMargin={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.selected_languages.length < 1 &&
                    <div style={{
                        width: '100%',
                        boxSizing: 'border-box'
                    }}>
                        <Dropdown
                            filter={true}
                            searchFilter={true}
                            search={true}
                            title={'Language'}
                            noTitle={true}
                            icons={false}
                            size={'full'}
                            placeholder={"Click here"}
                            options={this.state.languages.filter((item) => {
                                if (this.state.selected_languages) {
                                    let exist = false;
                                    this.state.selected_languages.map((item_inner) => {
                                        if (item_inner.code === item.code) {
                                            exist = true;
                                        }
                                    });
                                    return !exist;
                                } else {
                                    return true
                                }
                            })}
                            value={{}}
                            onChange={(value) => {
                                if (this.state.selected_languages.length < 1) {
                                    value.selected = true;
                                }
                                this.state.selected_languages.push(value);
                                this.setState({
                                    selected_languages: this.state.selected_languages
                                }, () => {
                                    this.props.onChange(this.state.selected_languages);
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.selected_languages.length > 0 &&
                    <div style={this.state.selected_languages.length > 0 ? {paddingTop: '0px'} : {paddingTop: '0px'}}
                         className="Section">
                        <div style={{boxSizing: 'border-box'}}
                             className="Locations-Items">
                            {
                                this.state.selected_languages.map((item) => {
                                    return (
                                        <div className="targetingItem">
                                            <div className="Location-Item">
                                                <div onClick={() => {
                                                    this.state.selected_languages = this.state.selected_languages.map((item) => {
                                                        item.selected = false;
                                                        return item;
                                                    });
                                                    item.selected = true;
                                                    this.setState({
                                                        selected_languages: this.state.selected_languages
                                                    }, () => {
                                                        this.props.onChange(this.state.selected_languages);
                                                    })
                                                }} className="Location-ItemLeft">
                                                    <div className="Location-ItemLeftText">
                                                        {item.name}
                                                    </div>
                                                    {
                                                        item.selected &&
                                                        <div data-tip={item.name + " is used to create keyword ideas"}
                                                             className="Location-ItemLeftIcon">
                                                            <FeatherIcon color={"#333333"} size={25} icon="target"/>
                                                            <ReactTooltip/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.removeLanguage(item);
                                            }} className="targetingItem-Remove ButtonRed">
                                                <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingKeywordsLanguage;
