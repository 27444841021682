import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'

class ModalTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showOptions: false,
            options: []
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            showOptions: this.props.showOptions,
            options: this.props.options
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            showOptions: nextProps.showOptions,
            options: nextProps.options
        })
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" style={{ zIndex: "999" }} className="fixed inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose(true, false);
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <BeakerIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                {this.props.title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {this.props.text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        Array.isArray(this.state.options) &&
                                        this.props.showOptions &&
                                        <div className="sm:pl-10 mt-2">
                                            {
                                                this.state.options.map((item) => {
                                                    return (
                                                        <div className="sm:pl-4">
                                                            <div className="flex flex-row border-b py-2 cursor-pointer">
                                                                <div className="flex flex-row ">
                                                                    {
                                                                        Array.isArray(item.nested) && item.nested.length > 0 &&
                                                                        <div onClick={() => {
                                                                            item.open = !item.open;
                                                                            this.setState({
                                                                                options: this.state.options
                                                                            })
                                                                        }} className={" mr-2 border-2 rounded cursor-pointer h-8 w-8 bg-purple-100 flex justify-center items-center"}>
                                                                            {
                                                                                !item.open &&
                                                                                <PlusIcon className="h-4 w-4 text-purple-600" aria-hidden="true" />
                                                                            }
                                                                            {
                                                                                item.open &&
                                                                                <MinusIcon className="h-4 w-4 text-purple-600" aria-hidden="true" />
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div onClick={() => {
                                                                        item.selected = !item.selected;
                                                                        if(item.nested){
                                                                            item.nested = item.nested.map((nested_item) => {
                                                                                nested_item.selected = item.selected;
                                                                                return nested_item;
                                                                            })
                                                                        }
                                                                        this.setState({
                                                                            options: this.state.options
                                                                        })
                                                                    }} className={(item.selected ? "border-purple-500" : "") + " border-2 rounded cursor-pointer h-8 w-8 bg-purple-100 flex justify-center items-center"}>
                                                                        {
                                                                            item.selected &&
                                                                            <CheckIcon className="h-4 w-4 text-purple-600" aria-hidden="true" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div onClick={() => {
                                                                    item.selected = !item.selected;
                                                                    this.setState({
                                                                        options: this.state.options
                                                                    })
                                                                }} className="flex justify-start items-center ml-3 text-sm">
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            {
                                                                item.open &&
                                                                Array.isArray(item.nested) && item.nested.length > 0 &&
                                                                <div className={"pl-10"}>
                                                                    {
                                                                        item.nested.map((nested) => {
                                                                            return (
                                                                                <div className="flex flex-row border-b py-2 cursor-pointer">
                                                                                    <div className="flex flex-row ">
                                                                                        <div onClick={() => {
                                                                                            nested.selected = !nested.selected;
                                                                                            this.setState({
                                                                                                options: this.state.options
                                                                                            })
                                                                                        }} className={(nested.selected ? "border-purple-500" : "") + " border-2 rounded cursor-pointer h-8 w-8 bg-purple-100 flex justify-center items-center"}>
                                                                                            {
                                                                                                nested.selected &&
                                                                                                <CheckIcon className="h-4 w-4 text-purple-600" aria-hidden="true" />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div onClick={() => {
                                                                                        nested.selected = !nested.selected;
                                                                                        this.setState({
                                                                                            options: this.state.options
                                                                                        })
                                                                                    }} className="flex justify-start items-center ml-3 text-sm">
                                                                                        {nested.name}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            if (this.props.onSubmit) {
                                                this.props.onSubmit(this.state.options);
                                            }
                                        }}
                                    >
                                        {this.props.submitButtonText}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            if (this.props.onCancel) {
                                                this.props.onCancel();
                                            }
                                        }}
                                    >
                                        {this.props.cancelButtonText}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default ModalTailwind;
