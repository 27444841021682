import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from 'react-spinners';
import { calls } from './calls';

import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import './style.css';
import cx from "classnames";
import { SlideDown } from "react-slidedown";
import Loader from "../../components/loader";
import Input from "../../components/input";
import Switch from "react-ios-switch";
import { CirclePicker } from 'react-color';
import Dropdown from "../../components/dropdown";
import ReportTable from "../../modules/report-components/table";
import TextArea from "../../components/text-area";
import ReactTooltip from "react-tooltip";
import Modal from "../../modules/modal";
import SingleDatepicker from "../../modules/singleDatepicker";
import CreateCustomMetric from "../../modules/createCustomMetric";
import PptsGenJs from "../premium-report/report-pptsGenJs";
import { userRegister } from "../../services/userRegister";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { Line, Bar } from "react-chartjs-2";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import ReportGrid from '../../modules/report-grid';
import ReportComponentsDonuts from '../../modules/report-components/donuts';
import GoogleAnalyticsDimensions from '../../assets/json/dimensions/google_analytics.json';

var moment = require('moment');
const fuzzysort = require('fuzzysort');

class FreemiumReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tab: 1,
            data: {},
            metrics: {},
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            name: "",
            public: false,
            slideinclosed: true,
            slideinclosedMetrics: true,
            slideinclosedBreakdowns: true,
            tabs: [],
            client: {},
            selected_tab: {},
            selected_cell: {},
            init_metrics: [],
            report_logo: { name: "No Logo", value: "none" },
            activeTab: 0,
            tabCounter: null,
            navToggleActive: "overview",
            navToggle: [],
            test_123: true,
            default_charts: {
                "impressions": {
                    title: "Impressions",
                    subtitle: "Show total impressions per platform",
                    icon: "pie-chart",
                    value: "impressions",
                    default: true
                },
                "click": {
                    title: "Clicks",
                    subtitle: "Show total click per platform",
                    icon: "pie-chart",
                    value: "click",
                    default: true
                },
                "ctr": {
                    title: "Avg. CTR",
                    subtitle: "Show average CTR per platform",
                    icon: "pie-chart",
                    value: "ctr",
                    default: true
                },
                "cpc": {
                    title: "Avg. CPC",
                    subtitle: "Show average CPC per platform",
                    icon: "pie-chart",
                    value: "cpc",
                    default: true
                },
                "spend": {
                    title: "Spend",
                    subtitle: "Show total spend per platform",
                    icon: "pie-chart",
                    value: "spend",
                    default: true
                }
            },
            default_metrics: [
                {
                    name: "Impressions (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "impressions.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Clicks (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "inline_link_clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:adClicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "clicks.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Avg. CTR (default)",
                    default: true,
                    find_average: true,
                    channels: {
                        facebook: {
                            "name": "inline_link_click_ctr",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "ctr",
                            "average": true,
                            "factor": 100,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:CTR",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "ctr.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 100,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Avg. CPC (default)",
                    default: true,
                    find_average: true,
                    channels: {
                        facebook: {
                            "name": "cpc",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "average_cpc",
                            "average": true,
                            "factor": 0.000001,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:CPC",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "ecpc.adUniqueness##all.costType##maxCost.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Spend (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "spend",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "cost_micros",
                            "average": false,
                            "factor": 0.000001,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:adCost",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "costInLocalCurrency",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "cost.costDataSource##adform.costType##maxCost",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                }
            ]
        };
    }

    componentWillMount() {
        this.functions.order();
        this.functions.metrics();
        this.functions.client();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {
        order: () => {
            this.setState({
                premium: this.props.match.path.indexOf("premium") !== -1
            }, () => {
                //INITIATE NEW REPORT OR LOAD SELECTED
                if (this.props.match.path.indexOf("/v2/reports/new/freemium/") !== -1 || this.props.match.path.indexOf("/v2/reports/new/premium/") !== -1) {
                    this.init.tabs();
                } else {
                    this.functions.report(this.props.match.params.report);
                }
            });
        },
        client: () => {
            this.setState({
                loading_client: true
            }, () => {
                let clientID = this.functions.getQuery('client');
                if (clientID) {
                    calls.getClient({ id: clientID }).then((response) => {
                        this.setState({
                            client: response.data,
                            loading_client: false
                        });
                    }, (error) => {
                        this.setState({
                            loading_client: false
                        })
                    });
                }
            })
        },
        metrics: () => {
            this.setState({
                loading_metrics: true
            }, () => {
                calls.getMetrics().then((response) => {

                    //ADD DEFAULT METRICS
                    if (response.data && Array.isArray(response.data.custom)) {
                        response.data.custom = response.data.custom.concat(this.state.default_metrics);
                    }

                    this.setState({
                        metrics: response.data,
                        loading_metrics: false,
                        metrics_error: false
                    });

                }, (error) => {
                    this.setState({
                        loading_metrics: false,
                        metrics_error: true
                    })
                });
            })
        },
        report: (id) => {
            this.setState({
                loading: true
            }, () => {
                calls.getReport({ id: id }).then((response) => {


                    if (response.data.template && response.data.internal_data && Array.isArray(response.data.internal_data.tabs)) {
                        response.data.internal_data.tabs.map((item) => {
                            if (Array.isArray(item.grid_data)) {
                                item.grid_data.map((inner_item) => {
                                    inner_item.data_sources = { ads: {}, adgroups: {}, campaigns: {} };
                                });
                            }
                            if (item.data_sources) {
                                item.data_sources = { ads: {}, adgroups: {}, campaigns: {} };
                            }
                        });
                    }

                    //console.log(response.data.internal_data.tabs);

                    this.setState({
                        loading: false,
                        report_error: false,
                        template: response.data.template,
                        report_name: response.data.name,
                        report_title: response.data.title,
                        report_description: response.data.description,
                        report_public: response.data.public,
                        tabs: response.data.internal_data.tabs,
                        report_logo: response.data.logo === "agency" ? {
                            name: "Agency logo",
                            value: "agency"
                        } : (response.data.logo === "client" ? {
                            name: "Client logo",
                            value: "client"
                        } : { name: "No Logo", value: "none" })
                    }, () => {
                        this.init.tabs();
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        report_error: true
                    })
                });
            })
        },
        save: () => {
            if (!this.state.loading_save) {

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    template: this.state.save_template && !this.state.save_report,
                    freemium: !this.state.premium,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: this.state.tabs
                    }
                };

                let campaigns = {};
                let adgroups = {};
                let ads = {};

                if (!this.state.premium) {
                    this.state.tabs.map((item) => {

                        if (this.state.save_template && !this.state.save_report) {

                        } else {
                            for (let campaign in item.data_sources.campaigns) {
                                campaigns[campaign] = {
                                    channel: item.data_sources.campaigns[campaign].channel,
                                    id: campaign
                                };
                                item.data_sources.campaigns[campaign] = {
                                    channel: item.data_sources.campaigns[campaign].channel,
                                    id: campaign
                                };
                            }
                            for (let adgroup in item.data_sources.adgroups) {
                                adgroups[adgroup] = {
                                    channel: item.data_sources.adgroups[adgroup].channel,
                                    id: adgroup,
                                    campaign: item.data_sources.adgroups[adgroup].campaign
                                };
                                item.data_sources.adgroups[adgroup] = {
                                    channel: item.data_sources.adgroups[adgroup].channel,
                                    id: adgroup,
                                    campaign: item.data_sources.adgroups[adgroup].campaign
                                };
                            }
                            for (let ad in item.data_sources.ads) {
                                ads[ad] = {
                                    channel: item.data_sources.ads[ad].channel,
                                    id: ad,
                                    adgroup: item.data_sources.ads[ad].adgroup,
                                    campaign: item.data_sources.ads[ad].campaign
                                };
                                item.data_sources.ads[ad] = {
                                    channel: item.data_sources.ads[ad].channel,
                                    id: ad,
                                    adgroup: item.data_sources.ads[ad].adgroup,
                                    campaign: item.data_sources.ads[ad].campaign
                                };
                            }
                        }

                    });
                    data.internal_data.selected_campaigns = campaigns;
                    data.internal_data.selected_adgroups = adgroups;
                    data.internal_data.selected_ads = ads;
                } else {
                    data.internal_data.tabs = data.internal_data.tabs.map((item) => {

                        if (item.data_sources && item.data_sources.campaigns) {
                            for (let campaign in item.data_sources.campaigns) {
                                item.data_sources.campaigns[campaign].insights = [];
                            }
                        }
                        if (item.data_sources && item.data_sources.adgroups) {
                            for (let adgroup in item.data_sources.adgroups) {
                                item.data_sources.adgroups[adgroup].insights = [];
                            }
                        }
                        if (item.data_sources && item.data_sources.ads) {
                            for (let ad in item.data_sources.ads) {
                                item.data_sources.ads[ad].insights = [];
                            }
                        }

                        if (Array.isArray(item.grid_data)) {
                            item.grid_data = item.grid_data.map((cell) => {

                                if ((this.state.report_template || this.state.save_new_template) && !this.state.save_new_report) {
                                    cell.data_sources.campaigns = {};
                                    cell.data_sources.adgroups = {};
                                    cell.data_sources.ads = {};
                                } else {
                                    if (cell.data_sources && cell.data_sources.campaigns) {
                                        for (let campaign in cell.data_sources.campaigns) {
                                            cell.data_sources.campaigns[campaign].insights = [];
                                        }
                                    }
                                    if (cell.data_sources && cell.data_sources.adgroups) {
                                        for (let adgroup in cell.data_sources.adgroups) {
                                            cell.data_sources.adgroups[adgroup].insights = [];
                                        }
                                    }
                                    if (cell.data_sources && cell.data_sources.ads) {
                                        for (let ad in cell.data_sources.ads) {
                                            cell.data_sources.ads[ad].insights = [];
                                        }
                                    }
                                }

                                //REMOVE CHART DATA, CANT STRINGIFY
                                if (cell.typeOfBlock === 'chart') {
                                    delete cell.data;
                                    //cell.data = cell.original_data;
                                }
                                return cell;
                            });
                        }
                        return item;
                    })
                }

                //REMOVE PARSED ITEMS
                if (data.internal_data && Array.isArray(data.internal_data.tabs)) {
                    data.internal_data.tabs = data.internal_data.tabs.map((item) => {
                        item.parsed_items = [];
                        return item;
                    })
                }

                this.setState({
                    loading_save: true,
                    save_modal: false,
                    save_report: false,
                    save_template: false,
                    update_report: false,
                    update_template: false
                }, () => {
                    calls.saveReport(data).then((response) => {
                        this.props.history.push("/v2/reports/standard");
                    }, (error) => {
                        this.setState({
                            loading_save: false
                        })
                    });
                })

            }
        },
        update: () => {
            if (!this.state.loading_save) {

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    id: this.props.match.params.report,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: this.state.tabs
                    }
                };

                let campaigns = {};
                let adgroups = {};
                let ads = {};

                this.state.tabs.map((item) => {
                    for (let campaign in item.data_sources.campaigns) {
                        campaigns[campaign] = {
                            channel: item.data_sources.campaigns[campaign].channel,
                            id: campaign
                        };
                        item.data_sources.campaigns[campaign] = {
                            channel: item.data_sources.campaigns[campaign].channel,
                            id: campaign
                        };
                    }
                    for (let adgroup in item.data_sources.adgroups) {
                        adgroups[adgroup] = {
                            channel: item.data_sources.adgroups[adgroup].channel,
                            id: adgroup,
                            campaign: item.data_sources.adgroups[adgroup].campaign
                        };
                        item.data_sources.adgroups[adgroup] = {
                            channel: item.data_sources.adgroups[adgroup].channel,
                            id: adgroup,
                            campaign: item.data_sources.adgroups[adgroup].campaign
                        };
                    }
                    for (let ad in item.data_sources.ads) {
                        ads[ad] = {
                            channel: item.data_sources.ads[ad].channel,
                            id: ad,
                            adgroup: item.data_sources.ads[ad].adgroup,
                            campaign: item.data_sources.ads[ad].campaign
                        };
                        item.data_sources.ads[ad] = {
                            channel: item.data_sources.ads[ad].channel,
                            id: ad,
                            adgroup: item.data_sources.ads[ad].adgroup,
                            campaign: item.data_sources.ads[ad].campaign
                        };
                    }

                    if (Array.isArray(item.grid_data)) {
                        item.grid_data = item.grid_data.map((cell) => {

                            if ((this.state.report_template || this.state.save_new_template) && !this.state.save_new_report) {
                                cell.data_sources.campaigns = {};
                                cell.data_sources.adgroups = {};
                                cell.data_sources.ads = {};
                            } else {
                                if (cell.data_sources && cell.data_sources.campaigns) {
                                    for (let campaign in cell.data_sources.campaigns) {
                                        cell.data_sources.campaigns[campaign].insights = [];
                                    }
                                }
                                if (cell.data_sources && cell.data_sources.adgroups) {
                                    for (let adgroup in cell.data_sources.adgroups) {
                                        cell.data_sources.adgroups[adgroup].insights = [];
                                    }
                                }
                                if (cell.data_sources && cell.data_sources.ads) {
                                    for (let ad in cell.data_sources.ads) {
                                        cell.data_sources.ads[ad].insights = [];
                                    }
                                }
                            }

                            //REMOVE CHART DATA, CANT STRINGIFY
                            if (cell.typeOfBlock === 'chart') {
                                delete cell.data;
                            }

                            return cell;
                        });
                    }

                    if (this.state.template) {
                        if (Array.isArray(item.grid_data)) {
                            item.grid_data = item.grid_data.map((cell) => {
                                if (this.state.report_template) {
                                    cell.data_sources.campaigns = {};
                                    cell.data_sources.adgroups = {};
                                    cell.data_sources.ads = {};
                                }
                                return cell;
                            });
                        }
                    }

                });

                data.internal_data.selected_campaigns = campaigns;
                data.internal_data.selected_adgroups = adgroups;
                data.internal_data.selected_ads = ads;

                //REMOVE PARSED ITEMS
                if (data.internal_data && Array.isArray(data.internal_data.tabs)) {
                    data.internal_data.tabs = data.internal_data.tabs.map((item) => {
                        item.parsed_items = [];
                        return item;
                    })
                }

                this.setState({
                    loading_save: true,
                    save_modal: false
                }, () => {
                    calls.updateReport(data).then((response) => {
                        this.setState({
                            loading_save: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_save: false
                        })
                    });
                })
            }
        },
        getQuery: (q) => {
            return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [null])[1];
        },
    };

    init = {
        tabs: () => {
            let new_tab = {};
            if (this.state.tabs.length < 1) {
                new_tab = {
                    loading: true,
                    init: true,
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    title: !this.state.premium ? 'Campaigns' : 'Tab 1',
                    editMode: false,
                    premium: this.state.premium,
                    grid_data: [],
                    backup: '',
                    groups: [{
                        id: 1,
                        name: "",
                        rows: 6,
                        layout: [],
                        index: 0,
                        items: [],
                        coordinates: {}
                    }],
                    data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                    start_date: null,
                    end_date: null,
                    analytics_breakdown: { name: "Overview", value: "overview", parent: "Overview" },
                    channel_breakdowns: {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    },
                    breakdown: {
                        title: "Overview",
                        subtitle: "",
                        icon: "globe",
                        value: "overview",
                        default: true
                    },
                    metrics: {
                        custom: {}
                    },
                    piechart: this.state.default_charts,
                    daterange: {
                        title: "Last 30 days",
                        subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                        icon: "calendar",
                        value: "30_days",
                        disabled: false
                    }
                };

                if (new_tab.daterange && new_tab.daterange.value === "30_days") {
                    new_tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                    new_tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                }

                //SET DEFAULT METRICS
                this.state.default_metrics.map((item) => {
                    new_tab.metrics["custom"][item.name] = item;
                });

                this.state.tabs.push(new_tab);
            } else {

                //UPDATE DATERANGE
                this.state.tabs = this.state.tabs.map((tab) => {
                    if (tab.daterange && tab.daterange.value === '10_days') {
                        tab.daterange = {
                            title: "Last 10 days",
                            end_date: moment(new Date()).format("YYYY-MM-DD"),
                            start_date: moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD"),
                            subtitle: ("From " + moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                            icon: "calendar",
                            value: "10_days",
                            disabled: false
                        };
                    }
                    if (tab.daterange && tab.daterange.value === '20_days') {
                        tab.daterange = {
                            title: "last 20 days",
                            end_date: moment(new Date()).format("YYYY-MM-DD"),
                            start_date: moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD"),
                            subtitle: ("From " + moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                            icon: "calendar",
                            value: "20_days",
                            disabled: false
                        };
                    }
                    if (tab.daterange && tab.daterange.value === '30_days') {
                        tab.daterange = {
                            title: "Last 30 days",
                            end_date: moment(new Date()).format("YYYY-MM-DD"),
                            start_date: moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD"),
                            subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                            icon: "calendar",
                            value: "30_days",
                            disabled: false
                        };
                    }
                    return tab;
                });

                //SET OLD REPORTS
                this.state.tabs = this.state.tabs.map((tab) => {

                    if (!tab.channel_breakdowns) {
                        if (tab.breakdown.value === "overview") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                            };
                        } else if (tab.breakdown.value === "gender") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "gender": { "gender": { name: "Gender", value: "gender", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "gender": { name: "Gender", value: "gender", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "gender": { name: "Gender", value: "gender", parent: "basic" } };
                        } else if (tab.breakdown.value === "age") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "age": { name: "Age", value: "age", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "age": { name: "Age", value: "age", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "age": { name: "Age", value: "age", parent: "Basic" } };
                        } else if (tab.breakdown.value === "daily") {
                            tab.channel_breakdowns = {};
                            tab.channel_breakdowns["google"] = { "date": { name: "Date", value: "date", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "date": { name: "Date", value: "date", parent: "basic" } };
                            tab.channel_breakdowns["adform"] = { "date": { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." } };
                            tab.channel_breakdowns["google_analytics"] = { "date": { name: "Date", value: "ga:date", parent: "basic" } };
                            tab.channel_breakdowns["basic"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                        } else if (tab.breakdown.value === "region") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "region": { name: "Region", value: "region", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                            tab.channel_breakdowns["facebook"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                        }

                        tab.piechart = this.state.default_charts;
                        if (!tab.metrics) {
                            tab.metrics = {
                                custom: {}
                            };
                        } else {
                            if (!tab.metrics.custom) {
                                tab.metrics.custom = {};
                            }
                        }
                        this.state.default_metrics.map((item) => {
                            tab.metrics["custom"][item.name] = item;
                        });
                    }
                    return tab;
                });

                new_tab = this.state.tabs[0];
                new_tab.loading = true;
            }

            this.setState({
                loading: false,
                selected_tab: new_tab,
                tabs: this.state.tabs,
            }, async () => {

                this.init.tab();

            });
        },
        tab: () => {

            this.state.selected_tab.loading = true;

            this.setState({
                selected_tab: this.state.selected_tab
            }, async () => {

                //CHECK IF GOOGLE ANALYTICS

                //GET ALL CAMPAIGNS
                if (this.state.campaigns.length < 1) {
                    await this.all.campaigns(this.props.match.params.id);
                }

                //INITIALIZE FIRST TAB WITH ALL CAMPAIGNS
                if (this.state.selected_tab.init) {
                    this.state.campaigns.map((campaign) => {
                        this.state.selected_tab.data_sources.campaigns[campaign.id] = campaign;
                    });
                    this.state.selected_tab.init = false;
                }

                //CHECK IF ADGROUPS OR ADS IS SELECTED, GET ALL TOP LEVELS ABOVE SELECTED
                let adgroup_calls = [];
                let adgroup_campaigns = {};
                this.state.campaigns.map(async (campaign) => {
                    campaign = JSON.parse(JSON.stringify(campaign));
                    for (let adgroup in this.state.selected_tab.data_sources.adgroups) {
                        if (this.state.selected_tab.data_sources.adgroups[adgroup].campaign === campaign.id) {
                            adgroup_campaigns[campaign.id] = campaign;
                            adgroup_calls.push(this.all.adgroups(campaign));
                        }
                    }
                });
                this.state.campaigns.map(async (campaign) => {
                    campaign = JSON.parse(JSON.stringify(campaign));
                    for (let ad in this.state.selected_tab.data_sources.ads) {
                        if (this.state.selected_tab.data_sources.ads[ad].campaign === campaign.id && !adgroup_campaigns[campaign.id]) {
                            adgroup_calls.push(this.all.adgroups(campaign));
                        }
                    }
                });

                await Promise.all(adgroup_calls);

                let ad_calls = [];

                this.state.adgroups.map(async (adgroup) => {
                    adgroup = JSON.parse(JSON.stringify(adgroup));
                    for (let ad in this.state.selected_tab.data_sources.ads) {
                        if (this.state.selected_tab.data_sources.ads[ad].adgroup === adgroup.id) {
                            ad_calls.push(this.all.ads(adgroup));
                        }
                    }
                });

                await Promise.all(ad_calls);

                let bulk_calls = [];

                console.log(this.state.selected_tab.metrics);

                //GET CUSTOM METRICS FROM CALCULATED METRICS
                let tab_source = JSON.parse(JSON.stringify(this.state.selected_tab));
                if (tab_source.metrics && tab_source.metrics["custom"]) {
                    for (let metric in tab_source.metrics["custom"]) {
                        if (tab_source.metrics["custom"][metric].calculated) {
                            for (let custom_metric in tab_source.metrics["custom"][metric].metrics) {
                                tab_source.metrics[tab_source.metrics["custom"][metric].metrics[custom_metric].name] = tab_source.metrics["custom"][metric].metrics[custom_metric];
                            }
                            delete tab_source.metrics["custom"][metric];
                        }
                    }
                }

                console.log(tab_source.metrics);

                //GET CORRECT DATA FOR SELECTED TAB DATESPAN AND BREAKDOWN
                if (this.state.selected_tab.data_sources.campaigns && Object.keys(this.state.selected_tab.data_sources.campaigns).length > 0) {
                    let campaigns = [];
                    for (let key in this.state.selected_tab.data_sources.campaigns) {
                        //CHECK IF CAMPAIGN EXIST
                        let campaign = this.state.selected_tab.data_sources.campaigns[key];
                        this.state.campaigns.map((item) => {
                            if (item.id == key) {
                                campaign = JSON.parse(JSON.stringify(item));
                            }
                        });
                        campaigns.push(campaign);
                    }
                    bulk_calls.push(this.bulk.campaigns(campaigns, tab_source));
                }
                if (this.state.selected_tab.data_sources.adgroups && Object.keys(this.state.selected_tab.data_sources.adgroups).length > 0) {
                    let adgroups = [];
                    for (let key in this.state.selected_tab.data_sources.adgroups) {
                        //CHECK IF ADGROUP EXIST
                        let adgroup = this.state.selected_tab.data_sources.adgroups[key];
                        this.state.adgroups.map((item) => {
                            if (item.id == key) {
                                adgroup = JSON.parse(JSON.stringify(item));
                            }
                        });
                        adgroups.push(adgroup);
                    }
                    //console.log(adgroups);
                    //SEPERATE INTO CAMPAIGNS
                    let campaigns = {};
                    adgroups.map((adgroup) => {
                        if (!(adgroup.campaign in campaigns)) {
                            campaigns[adgroup.campaign] = [];
                        }
                        campaigns[adgroup.campaign].push(adgroup);
                    });
                    //console.log(campaigns);
                    for (let key in campaigns) {
                        bulk_calls.push(this.bulk.adgroups(campaigns[key], tab_source));
                    }
                }
                if (this.state.selected_tab.data_sources.ads && Object.keys(this.state.selected_tab.data_sources.ads).length > 0) {
                    let ads = [];
                    for (let key in this.state.selected_tab.data_sources.ads) {
                        //CHECK IF ADGROUP EXIST
                        let ad = this.state.selected_tab.data_sources.ads[key];
                        this.state.ads.map((item) => {
                            if (item.id == key) {
                                ad = JSON.parse(JSON.stringify(item));
                            }
                        });
                        ads.push(ad);
                    }
                    //SEPERATE INTO ADGROUPS
                    let adgroups = {};
                    ads.map((ad) => {
                        if (!(ad.adgroup in adgroups)) {
                            adgroups[ad.adgroup] = [];
                        }
                        adgroups[ad.adgroup].push(ad);
                    });
                    for (let key in adgroups) {
                        bulk_calls.push(this.bulk.ads(adgroups[key], tab_source));
                    }
                }

                await Promise.all(bulk_calls);

                this.state.selected_tab.loading = false;

                this.setState({
                    selected_tab: this.state.selected_tab
                }, () => {

                    //GET DATA FOR SELECTED TAB OR CELLS
                    if (this.state.premium && Array.isArray(this.state.selected_tab.grid_data)) {
                        this.state.selected_tab.grid_data.map((item) => {
                            this.init.cell(item);
                        });
                    }

                    if (!this.state.premium) {
                        this.renders.init_items();
                    }

                });

            })

        },
        cell: async (cell) => {

            let tab = {};
            let text = {};
            let image = {};
            let number = {};

            if (cell.celltype && cell.celltype.value === "number") {
                cell.channel_breakdowns = {
                    "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "basic": {
                        "overview": {
                            title: "Overview",
                            subtitle: "Agregated data",
                            icon: "globe",
                            value: "overview",
                            supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                        }
                    }
                };
            }

            //SET LOADER
            if (cell.i) {
                try {
                    this.refs.grid.updateLoaderCell(cell.i);
                } catch (error) {
                    console.log(error);
                }
            }

            //UPDATE TAB WITH DATASOURCES
            if (this.state.selected_tab.data_sources.campaigns && cell.data_sources && cell.data_sources.campaigns) {
                this.state.selected_tab.data_sources.campaigns = { ...this.state.selected_tab.data_sources.campaigns, ...cell.data_sources.campaigns };
            }
            if (this.state.selected_tab.data_sources.adgroups && cell.data_sources && cell.data_sources.adgroups) {
                this.state.selected_tab.data_sources.adgroups = { ...this.state.selected_tab.data_sources.adgroups, ...cell.data_sources.adgroups };
            }
            if (this.state.selected_tab.data_sources.ads && cell.data_sources && cell.data_sources.ads) {
                this.state.selected_tab.data_sources.ads = { ...this.state.selected_tab.data_sources.ads, ...cell.data_sources.ads };
            }

            let cell_item = null;

            if (cell.celltype && cell.celltype.value === "text") {
                text = {
                    group: cell.group,
                    i: 'n' + Date.now() + Math.floor(Math.random() * 1000),
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 3,
                    header: 'Text field',
                    data: "",
                    typeOfBlock: 'input',
                };
                if (!cell.i) {
                    cell_item = text;
                } else {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (item.i === cell.i) {
                            item = text;
                            item.group = cell.group;
                            item.x = cell.x;
                            item.y = cell.y;
                            item.w = cell.w;
                            item.h = cell.h;
                        }
                        return item;
                    });
                }
            }

            else if (cell.celltype && cell.celltype.value === "image") {
                image = {
                    group: cell.group,
                    i: 'n' + Date.now() + Math.floor(Math.random() * 1000),
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 3,
                    header: 'screenshot',
                    data: null,
                    typeOfBlock: 'img',
                };
                if (!cell.i) {
                    cell_item = image;
                } else {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (item.i === cell.i) {
                            item = image;
                            item.group = cell.group;
                            item.x = cell.x;
                            item.y = cell.y;
                            item.w = cell.w;
                            item.h = cell.h;
                        }
                        return item;
                    });
                }
            }

            else if (cell.celltype && (cell.celltype.value === "number" || cell.celltype.value === "chart" || cell.celltype.value === "table")) {
                if (!cell.i) {

                    console.log(cell);

                    number = {

                        remove_name: cell.remove_name,
                        remove_id: cell.remove_id,
                        remove_level: cell.remove_level,
                        remove_breakdown_value: cell.remove_breakdown_value,
                        remove_breakdown: cell.remove_breakdown,
                        remove_channel: cell.remove_channel,
                        remove_preview: cell.remove_preview,

                        group: cell.group,
                        i: 'n' + Date.now() + Math.floor(Math.random() * 1000),
                        x: 0,
                        y: 0,
                        w: 2,
                        h: 3,
                        header: cell.settings ? cell.settings.title : "",
                        metric: cell.metric,
                        table_metrics: cell.table_metrics,
                        table_metrics_index: cell.table_metrics_index,
                        metrics: {},
                        data: 0,
                        celltype: cell.celltype,
                        settings: cell.settings,
                        breakdown: cell.breakdown,
                        channel_breakdowns: cell.channel_breakdowns,
                        chart: cell.chart,
                        daterange: cell.daterange,
                        data_sources: cell.data_sources,
                        typeOfBlock: cell.celltype.value,
                        loading: true
                    };

                    //SET CALCULATED METRIC
                    if (cell.celltype.value !== "table") {
                        if (cell.metric.calculated) {
                            for (let custom_metric in cell.metric.metrics) {
                                for (let channel in cell.metric.metrics[custom_metric].channels) {
                                    let metric = cell.metric.metrics[custom_metric].channels[channel];
                                    if (!number.metrics[channel]) {
                                        number.metrics[channel] = {};
                                    }
                                    number.metrics[channel][metric.name] = metric;
                                    //number.metrics[channel][metric.name].org_name = metric.name
                                }
                            }
                        } else {
                            for (let channel in cell.metric.channels) {
                                let metric = cell.metric.channels[channel];
                                number.metrics[channel] = {};
                                number.metrics[channel][metric.name] = metric;
                                //number.metrics[channel][metric.name].org_name = metric.name
                            }
                        }
                    } else {

                        number.metrics = cell.metrics;

                    }

                    cell = number;
                    cell_item = number;

                } else {

                    let new_item = {};

                    new_item.remove_name = cell.remove_name;
                    new_item.remove_id = cell.remove_id;
                    new_item.remove_level = cell.remove_level;
                    new_item.remove_breakdown_value = cell.remove_breakdown_value;
                    new_item.remove_breakdown = cell.remove_breakdown;
                    new_item.remove_channel = cell.remove_channel;
                    new_item.remove_preview = cell.remove_preview;

                    new_item.i = cell.i;
                    new_item.group = cell.group;
                    new_item.header = cell.settings ? cell.settings.title : "";
                    new_item.chart = cell.chart;
                    new_item.x = cell.x;
                    new_item.y = cell.y;
                    new_item.w = cell.w;
                    new_item.h = cell.h;
                    new_item.daterange = cell.daterange;
                    new_item.breakdown = cell.breakdown;
                    new_item.channel_breakdowns = cell.channel_breakdowns;
                    new_item.settings = cell.settings;
                    new_item.celltype = cell.celltype;
                    new_item.metric = cell.metric;
                    new_item.table_metrics = cell.table_metrics;
                    new_item.table_metrics_index = cell.table_metrics_index;
                    new_item.typeOfBlock = cell.celltype.value;
                    new_item.data_sources = cell.data_sources;
                    new_item.loading = true;
                    new_item.metrics = {};

                    if (cell.celltype.value !== "table") {
                        if (cell.metric.calculated) {
                            for (let custom_metric in cell.metric.metrics) {
                                for (let channel in cell.metric.metrics[custom_metric].channels) {
                                    let metric = cell.metric.metrics[custom_metric].channels[channel];
                                    if (!new_item.metrics[channel]) {
                                        new_item.metrics[channel] = {};
                                    }
                                    new_item.metrics[channel][metric.name] = metric;
                                    //number.metrics[channel][metric.name].org_name = metric.name
                                }
                            }
                        } else {
                            for (let channel in cell.metric.channels) {
                                let metric = cell.metric.channels[channel];
                                new_item.metrics[channel] = {};
                                new_item.metrics[channel][metric.name] = metric;
                                //number.metrics[channel][metric.name].org_name = metric.name
                            }
                        }
                    } else {
                        new_item.metrics = cell.metrics;
                    }

                    cell = new_item;

                }
            }

            //ADD ITEM TO DATA_GRID HERE
            if (cell_item) {

                let group = null;
                let group_coordinates = {};
                this.state.selected_tab.groups.map((item) => {
                    if (item.id === cell_item.group) {
                        group = item;
                    }
                })

                //UPDATE GROUP COORDINATES
                this.state.selected_tab.grid_data.map((item) => {
                    if (group && item.group === group.id) {
                        let init_w = JSON.parse(JSON.stringify(item.w));
                        let init_h = JSON.parse(JSON.stringify(item.h));
                        for (let y = 0; y < init_h; y++) {
                            let init_y = JSON.parse(JSON.stringify(item.y));
                            init_y = init_y + y;
                            for (let x = 0; x < init_w; x++) {
                                let init_x = JSON.parse(JSON.stringify(item.x));
                                init_x = init_x + x;
                                group_coordinates[init_x + ":" + init_y] = true;
                            }
                        }
                    }
                })

                let start_x = 0;
                let start_y = 0;
                let columns = 7;
                let rows = (group.rows - 1);
                let free_spot = false;
                let checked_positions = [];

                for (let y = 0; y < rows; y++) {
                    let init_y = start_y + y;
                    for (let x = 0; x < columns; x++) {
                        let init_x = start_x + x;
                        checked_positions = [];
                        for (let h = 0; h < cell_item.h; h++) {
                            for (let w = 0; w < cell_item.w; w++) {
                                if (init_y + h < group.rows) {
                                    checked_positions.push(group_coordinates[(init_x + w) + ":" + (init_y + h)]);
                                }
                            }
                        }
                        if (checked_positions.filter((item) => { return !item }).length === checked_positions.length && checked_positions.length === (cell_item.w * cell_item.h)) {
                            free_spot = true;
                            cell_item.x = init_x;
                            cell_item.y = init_y;
                            break;
                        }
                    }
                    if (free_spot) {
                        break;
                    }
                }

                if (free_spot) {
                    this.state.selected_tab.grid_data.push(cell_item);
                } else {
                    //POPUP, NOT ENOUGH SPACE
                    this.setState({
                        cell_modal: true
                    })
                }
            }

            let bulk_calls = [];

            if (cell.i) {
                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                    if (cell.i == item.i) {
                        item = cell;
                    }
                    return item;
                })
            }

            this.state.tabs = this.state.tabs.map((item) => {
                if (item.id == this.state.selected_tab.id) {
                    item = this.state.selected_tab;
                }
                return item;
            });

            this.setState({
                tabs: this.state.tabs,
                selected_tab: this.state.selected_tab
            }, async () => {

                if (cell.celltype && (cell.celltype.value === "number" || cell.celltype.value === "chart" || cell.celltype.value === "table")) {

                    //SET CUSTOM METRICS
                    let cell_metrics = JSON.parse(JSON.stringify(cell.metrics));
                    if (cell.celltype.value === "table") {
                        if (cell_metrics && cell_metrics["custom"]) {
                            for (let metric in cell_metrics["custom"]) {
                                if (cell_metrics["custom"][metric].calculated) {
                                    for (let custom_metric in cell_metrics["custom"][metric].metrics) {
                                        cell_metrics[cell_metrics["custom"][metric].metrics[custom_metric].name] = cell_metrics["custom"][metric].metrics[custom_metric];
                                    }
                                    delete cell_metrics["custom"][metric];
                                }
                            }
                        }
                    }

                    //GET CORRECT DATA FOR SELECTED TAB DATESPAN AND BREAKDOWN
                    if (cell.data_sources.campaigns && Object.keys(cell.data_sources.campaigns).length > 0) {
                        let campaigns = [];
                        for (let key in cell.data_sources.campaigns) {
                            //CHECK IF CAMPAIGN EXIST
                            let campaign = cell.data_sources.campaigns[key];
                            this.state.campaigns.map((item) => {
                                if (item.id == key) {
                                    campaign = JSON.parse(JSON.stringify(item));
                                }
                            });
                            campaigns.push(campaign);
                        }
                        console.log(cell.channel_breakdowns);
                        bulk_calls.push(this.bulk.campaigns(
                            campaigns,
                            { channel_breakdowns: cell.channel_breakdowns, breakdown: cell.breakdown, daterange: cell.daterange, metrics: cell_metrics }
                        ));
                    }

                    if (cell.data_sources.adgroups && Object.keys(cell.data_sources.adgroups).length > 0) {
                        let adgroups = [];
                        for (let key in cell.data_sources.adgroups) {
                            //CHECK IF CAMPAIGN EXIST
                            let adgroup = cell.data_sources.adgroups[key];
                            this.state.adgroups.map((item) => {
                                if (item.id == key) {
                                    adgroup = JSON.parse(JSON.stringify(item));
                                }
                            });
                            adgroups.push(adgroup);
                        }
                        bulk_calls.push(this.bulk.adgroups(
                            adgroups,
                            { channel_breakdowns: cell.channel_breakdowns, breakdown: cell.breakdown, daterange: cell.daterange, metrics: cell_metrics }
                        ));
                    }

                    if (cell.data_sources.ads && Object.keys(cell.data_sources.ads).length > 0) {
                        let ads = [];
                        for (let key in cell.data_sources.ads) {
                            //CHECK IF CAMPAIGN EXIST
                            let ad = cell.data_sources.ads[key];
                            this.state.ads.map((item) => {
                                if (item.id == key) {
                                    ad = JSON.parse(JSON.stringify(item));
                                }
                            });
                            ads.push(ad);
                        }
                        bulk_calls.push(this.bulk.ads(
                            ads, { channel_breakdowns: cell.channel_breakdowns, breakdown: cell.breakdown, daterange: cell.daterange, metrics: cell_metrics }
                        ));
                    }

                    let result = await Promise.all(bulk_calls);

                    //CAll UPDATE CELL 
                    try {
                        this.refs.grid.updateCell(cell.i);
                    } catch (error) {
                        console.log(error);
                    }

                }

            });

        }
    };

    all = {
        campaigns: () => {
            let self = this;
            return new Promise(function (resolve, reject) {
                calls.getCampaignsInformation(
                    self.props.match.params.id,
                    [{ all: true }]
                ).then((response) => {
                    let campaigns = [];
                    if (response.data.channels) {
                        for (let channel in response.data.channels) {
                            if (response.data.channels[channel].campaigns) {
                                response.data.channels[channel].campaigns.map((object) => {
                                    object.channel = channel;
                                    object.type = "campaign";
                                    campaigns.push(object);
                                });
                            }
                        }
                    }
                    self.setState({
                        campaigns: campaigns
                    }, () => {
                        resolve();
                    });
                }, (error) => {
                    resolve();
                });
            });
        },
        adgroups: (campaign) => {
            let self = this;
            return new Promise(function (resolve, reject) {
                if (!campaign.loading && !Array.isArray(campaign.adgroups)) {
                    campaign.loading = true;
                    self.setState({
                        campaigns: self.state.campaigns.map((item) => {
                            if (item.id === campaign.id) {
                                item = campaign;
                            }
                            return item;
                        })
                    }, () => {
                        let query = "&" + campaign.channel + "_campaigns=" + campaign.id;
                        calls.getAdgroups({
                            id: self.props.match.params.id,
                            query: query
                        }).then((response) => {
                            campaign.adgroups = response.data.channels[campaign.channel].adgroups.map((item) => {
                                item.campaign = campaign.id;
                                item.campaign_name = campaign.name;
                                item.channel = campaign.channel;
                                item.internal_id = campaign.internal_id;
                                item.instagram = campaign.instagram;
                                item.currency = campaign.currency;
                                item.type = "adgroup";
                                return item;
                            }).filter((item) => {
                                return item.status !== "REMOVED"
                            });
                            campaign.loading = false;
                            self.state.tabs = self.state.tabs.map((tab) => {
                                if (tab.id === self.state.selected_tab.id) {
                                    tab = self.state.selected_tab;
                                }
                                return tab;
                            });
                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                adgroups: self.state.adgroups.concat(campaign.adgroups),
                                campaigns: self.state.campaigns.map((item) => {
                                    if (item.id === campaign.id) {
                                        item = campaign;
                                    }
                                    return item;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            })
        },
        ads: (adgroup) => {
            let self = this;
            return new Promise(function (resolve, reject) {
                if (!adgroup.loading && !Array.isArray(adgroup.ads)) {
                    adgroup.loading = true;
                    self.setState({
                        adgroups: self.state.adgroups.map((item) => {
                            if (item.id == adgroup.id) {
                                item = adgroup;
                            }
                            return item;
                        })
                    }, () => {
                        let query = "";
                        calls.getAds({
                            id: self.props.match.params.id,
                            adgroup: adgroup.id,
                            campaign: adgroup.campaign,
                            channel: adgroup.channel,
                            query: query
                        }).then((response) => {
                            adgroup.ads = response.data.channels[adgroup.channel].ads.map((item) => {
                                item.campaign = adgroup.campaign;
                                item.campaign_name = adgroup.campaign_name;
                                item.adgroup = adgroup.id;
                                item.adgroup_name = adgroup.name;
                                item.channel = adgroup.channel;
                                item.instagram = adgroup.instagram;
                                item.currency = adgroup.currency;
                                item.type = "ad";
                                return item;
                            });
                            self.state.ads = self.state.ads.concat(adgroup.ads);
                            adgroup.loading = false;
                            /*
                            adgroup.ads.map((ad) => {
                                if (self.state.selected_tab.data_sources.ads && self.state.selected_tab.data_sources.ads[ad.id]) {
                                    self.state.selected_tab.data_sources.ads[ad.id].insights = ad.insights;
                                }
                            });
                            */
                            self.state.tabs = self.state.tabs.map((tab) => {
                                if (tab.id === self.state.selected_tab.id) {
                                    tab = self.state.selected_tab;
                                }
                                return tab;
                            });
                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                ads: self.state.ads,
                                adgroups: self.state.adgroups.map((item) => {
                                    if (item.id == adgroup.id) {
                                        item = adgroup;
                                    }
                                    return item;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            });
        }
    };

    bulk = {
        Oldcampaigns: (campaigns, source) => {
            let self = this;
            return new Promise(function (resolve, reject) {
                campaigns = JSON.parse(JSON.stringify(campaigns));
                let bulk_campaigns = [];

                campaigns.map((campaign) => {

                    campaign = JSON.parse(JSON.stringify(campaign));

                    //CHECK ALL METRICS
                    let missing_metrics = [];
                    let metric_channels = ["custom"];
                    metric_channels.push(campaign.channel);
                    metric_channels.map((channel) => {
                        if (source.metrics[channel]) {
                            for (let metric in source.metrics[channel]) {
                                let exist = false;
                                let name = false;
                                if (channel === "custom") {
                                    if (source.metrics[channel][metric].channels[campaign.channel]) {
                                        name = source.metrics[channel][metric].channels[campaign.channel].name;
                                    }
                                } else {
                                    name = source.metrics[channel][metric].org_name ? source.metrics[channel][metric].org_name : source.metrics[channel][metric].name;
                                }
                                if (Array.isArray(campaign.insights) && name) {
                                    campaign.insights.map((item) => {
                                        if (
                                            (
                                                source.channel_breakdowns && source.channel_breakdowns[campaign.channel] && source.channel_breakdowns[campaign.channel][item.channel_breakdown]
                                            ) &&
                                            ((item.daterange_start === source.daterange.start_date && item.daterange_end === source.daterange.end_date)
                                                || (!item.daterange_start && !item.daterange_end && source.daterange.value === "lifetime"))) {
                                            if (name in item) {
                                                exist = true;
                                            }
                                        }
                                    });
                                }
                                if (!exist && name) {
                                    missing_metrics.push(name);
                                }
                            }
                        }
                    })

                    campaign.missing_metrics = missing_metrics;

                    if (Array.isArray(campaign.missing_metrics) && campaign.missing_metrics.length > 0) {
                        bulk_campaigns.push(campaign);
                    }

                });

                if (bulk_campaigns.length > 0) {
                    self.setState({
                        campaigns: self.state.campaigns.map((item) => {
                            bulk_campaigns.map((inner_item) => {
                                if (item.id == inner_item.id) {
                                    item.is_loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {
                        let channel_metrics = {};
                        bulk_campaigns.map((campaign) => {
                            if (Array.isArray(campaign.missing_metrics)) {
                                if (campaign.channel in channel_metrics) {
                                    campaign.missing_metrics.map((metric) => {
                                        channel_metrics[campaign.channel][metric] = true;
                                    });
                                } else {
                                    channel_metrics[campaign.channel] = {};
                                    campaign.missing_metrics.map((metric) => {
                                        channel_metrics[campaign.channel][metric] = true;
                                    });
                                }
                            }
                        });
                        let query = "";
                        if (source.channel_breakdowns) {
                            for (let channel in source.channel_breakdowns) {
                                let dimensions = Object.keys(source.channel_breakdowns[channel]);
                                query = query + "&" + channel + "_breakdown=" + dimensions.join(",");
                            }
                        }
                        if (source.daterange && source.daterange.start_date) {
                            query = query + "&startdate=" + source.daterange.start_date;
                        }
                        if (source.daterange && source.daterange.end_date) {
                            query = query + "&enddate=" + source.daterange.end_date;
                        }
                        if (bulk_campaigns.length > 0) {
                            let channels_campaigns = {};
                            bulk_campaigns.map((item) => {
                                if (!channels_campaigns[item.channel]) {
                                    channels_campaigns[item.channel] = [];
                                }
                                channels_campaigns[item.channel].push(item.id);
                            });
                            for (let channel in channels_campaigns) {
                                query = query + "&" + channel + "_campaigns=";
                                query = query + channels_campaigns[channel].join(",");
                            }
                        }
                        if (Object.keys(channel_metrics).length > 0) {
                            for (let channel in channel_metrics) {
                                query = query + "&" + channel + "_metrics=";
                                let metrics_array = [];
                                for (let metric in channel_metrics[channel]) {
                                    metrics_array.push(metric);
                                }
                                query = query + encodeURIComponent(metrics_array.join(","));
                            }
                        }
                        calls.getCampaignsReport({
                            id: self.props.match.params.id,
                            query: query
                        }).then((response) => {
                            bulk_campaigns = bulk_campaigns.map((campaign) => {

                                if (response.data.channels[campaign.channel]) {
                                    response.data.channels[campaign.channel].map((response_campaign) => {
                                        if (response_campaign.id === campaign.id) {

                                            if (!Array.isArray(campaign.insights)) {
                                                campaign.insights = [];
                                            }

                                            //CHECK IF INSIGHTS DONT ALREADY EXIST
                                            let merged = false;
                                            campaign.insights = campaign.insights.map((item) => {
                                                response_campaign.insights.map((inner_item) => {
                                                    if (
                                                        item.daterange_start === inner_item.daterange_start &&
                                                        item.daterange_end === inner_item.daterange_end &&
                                                        item.channel_breakdown === inner_item.channel_breakdown
                                                    ) {
                                                        merged = true;
                                                        for (let key in item) {
                                                            if (key in inner_item) {
                                                                item[key] = inner_item[key];
                                                            }
                                                        }
                                                        item = { ...item, ...inner_item };
                                                    }
                                                });
                                                return item;
                                            })

                                            if (!merged) {
                                                campaign.insights = campaign.insights.concat(Array.isArray(response_campaign.insights) ? response_campaign.insights : []);
                                            }

                                        }
                                    })
                                }

                                /*
                                if (response.data.channels[campaign.channel] && response.data.channels[campaign.channel].campaigns[campaign.id]) {
                                    if (!Array.isArray(campaign.insights)) {
                                        campaign.insights = [];
                                    }

                                    //CHECK IF INSIGHTS DONT ALREADY EXIST
                                    let merged = false;
                                    campaign.insights = campaign.insights.map((item) => {
                                        response.data.channels[campaign.channel].campaigns[campaign.id].insights.map((inner_item) => {
                                            if (
                                                item.daterange_start === inner_item.daterange_start &&
                                                item.daterange_end === inner_item.daterange_end &&
                                                item.channel_breakdown === inner_item.channel_breakdown
                                            ) {
                                                merged = true;
                                                for (let key in item) {
                                                    if (key in inner_item) {
                                                        item[key] = inner_item[key];
                                                    }
                                                }
                                                item = { ...item, ...inner_item };
                                            }
                                        });
                                        return item;
                                    })

                                    if (!merged) {
                                        campaign.insights = campaign.insights.concat(Array.isArray(response.data.channels[campaign.channel].campaigns[campaign.id].insights) ? response.data.channels[campaign.channel].campaigns[campaign.id].insights : []);
                                    }

                                }
                                */

                                if (!Array.isArray(campaign.insights)) {
                                    campaign.insights = [];
                                }
                                campaign.is_loading = false;
                                return campaign;

                            });
                            self.setState({
                                campaigns: self.state.campaigns.map((item) => {
                                    bulk_campaigns.map((inner_item) => {
                                        if (item.id == inner_item.id) {
                                            item = inner_item;
                                        }
                                    });
                                    return item;
                                })
                            }, () => {
                                //console.log(self.state.campaigns);
                                resolve();
                            });
                        }, (error) => {
                            //console.log(self.state.campaigns);
                            resolve();
                        });
                    })
                } else {
                    //console.log(self.state.campaigns);
                    resolve();
                }
            });
        },
        adgroups: (adgroups, source) => {

            let self = this;
            return new Promise(function (resolve, reject) {

                adgroups = JSON.parse(JSON.stringify(adgroups));
                source = JSON.parse(JSON.stringify(source));

                let channel = "";
                let campaign = "";
                let bulk_adgroups = [];

                adgroups.map((adgroup) => {

                    //SET PARENT DATA
                    channel = adgroup.channel;
                    campaign = adgroup.campaign;
                    adgroup = JSON.parse(JSON.stringify(adgroup));

                    //CHECK ALL METRICS
                    let missing_metrics = [];
                    let metric_channels = ["custom"];
                    metric_channels.push(adgroup.channel);
                    metric_channels.map((channel) => {
                        if (source.metrics[channel]) {
                            for (let metric in source.metrics[channel]) {
                                let exist = false;
                                let name = "";
                                if (channel === "custom" && source.metrics[channel][metric].channels[adgroup.channel]) {
                                    name = source.metrics[channel][metric].channels[adgroup.channel].name;
                                } else {
                                    name = source.metrics[channel][metric].org_name ? source.metrics[channel][metric].org_name : source.metrics[channel][metric].name;
                                }
                                if (Array.isArray(adgroup.insights)) {
                                    adgroup.insights.map((item) => {
                                        if (
                                            source.channel_breakdowns && source.channel_breakdowns[adgroup.channel] && source.channel_breakdowns[adgroup.channel][item.channel_breakdown] &&
                                            //source.breakdown.value === item.breakdown &&
                                            ((item.daterange_start === source.daterange.start_date && item.daterange_end === source.daterange.end_date)
                                                || (!item.daterange_start && !item.daterange_end && source.daterange.value === "lifetime"))
                                        ) {
                                            if (name in item) {
                                                exist = true;
                                            }
                                        }
                                    });
                                }
                                if (!exist) {
                                    missing_metrics.push(name);
                                }
                            }
                        }
                    })

                    adgroup.missing_metrics = missing_metrics;

                    if (Array.isArray(adgroup.missing_metrics) && adgroup.missing_metrics.length > 0) {
                        bulk_adgroups.push(adgroup);
                    }

                });
                if (bulk_adgroups.length > 0) {
                    self.setState({
                        adgroups: self.state.adgroups.map((item) => {
                            bulk_adgroups.map((inner_item) => {
                                if (item.id == inner_item.id) {
                                    item.is_loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {
                        let channel_metrics = {};
                        bulk_adgroups.map((adgroup) => {
                            if (Array.isArray(adgroup.missing_metrics)) {
                                if (adgroup.channel in channel_metrics) {
                                    adgroup.missing_metrics.map((metric) => {
                                        channel_metrics[adgroup.channel][metric] = true;
                                    });
                                } else {
                                    channel_metrics[adgroup.channel] = {};
                                    adgroup.missing_metrics.map((metric) => {
                                        channel_metrics[adgroup.channel][metric] = true;
                                    });
                                }
                            }
                        });
                        let query = "";
                        if (source.breakdown) {
                            query = query + "&breakdown=" + source.breakdown.value;
                        }
                        if (source.daterange && source.daterange.start_date) {
                            query = query + "&startdate=" + source.daterange.start_date;
                        }
                        if (source.daterange && source.daterange.end_date) {
                            query = query + "&enddate=" + source.daterange.end_date;
                        }
                        if (source.channel_breakdowns) {
                            for (let channel in source.channel_breakdowns) {
                                let dimensions = Object.keys(source.channel_breakdowns[channel]);
                                query = query + "&" + channel + "_breakdown=" + dimensions.join(",");
                            }
                        }
                        if (Object.keys(channel_metrics).length > 0) {
                            for (let channel in channel_metrics) {
                                query = query + "&" + channel + "_metrics=";
                                let metrics_array = [];
                                for (let metric in channel_metrics[channel]) {
                                    metrics_array.push(metric);
                                }
                                query = query + encodeURIComponent(metrics_array.join(","));
                            }
                        }
                        calls.getBulkAdgroups({
                            id: self.props.match.params.id,
                            channel: channel,
                            campaign: campaign,
                            adgroups: bulk_adgroups.map((item) => {
                                return item.id
                            }).join(","),
                            query: query
                        }).then((response) => {

                            bulk_adgroups = bulk_adgroups.map((adgroup) => {

                                if (response.data.channels[adgroup.channel] && Array.isArray(response.data.channels[adgroup.channel].adgroups)) {

                                    response.data.channels[adgroup.channel].adgroups.map((inner_adgroup) => {

                                        if (adgroup.id === inner_adgroup.id) {

                                            //console.log(inner_adgroup);

                                            if (!Array.isArray(adgroup.insights)) {
                                                adgroup.insights = [];
                                            }

                                            //CHECK IF INSIGHTS DONT ALREADY EXIST
                                            let merged = false;
                                            if (adgroup.channel === "facebook") {
                                                adgroup.insights = adgroup.insights.map((item) => {
                                                    inner_adgroup.insights.map((inner_item) => {
                                                        if (
                                                            item.daterange_start === inner_item.daterange_start &&
                                                            item.daterange_end === inner_item.daterange_end &&
                                                            //item.breakdown === inner_item.breakdown &&
                                                            item.channel_breakdown === inner_item.channel_breakdown &&
                                                            item.publisher_platform === inner_item.publisher_platform
                                                        ) {
                                                            merged = true;
                                                            for (let key in item) {
                                                                if (key in inner_item) {
                                                                    item[key] = inner_item[key];
                                                                }
                                                            }
                                                            item = { ...item, ...inner_item };
                                                        }
                                                    });
                                                    return item;
                                                })
                                            } else {
                                                adgroup.insights = adgroup.insights.map((item) => {
                                                    inner_adgroup.insights.map((inner_item) => {
                                                        if (
                                                            item.daterange_start === inner_item.daterange_start &&
                                                            item.daterange_end === inner_item.daterange_end &&
                                                            //item.breakdown === inner_item.breakdown
                                                            item.channel_breakdown === inner_item.channel_breakdown
                                                        ) {
                                                            merged = true;
                                                            for (let key in item) {
                                                                if (key in inner_item) {
                                                                    item[key] = inner_item[key];
                                                                }
                                                            }
                                                            item = { ...item, ...inner_item };
                                                        }
                                                    });
                                                    return item;
                                                })
                                            }
                                            if (!merged) {
                                                adgroup.insights = adgroup.insights.concat(Array.isArray(inner_adgroup.insights) ? inner_adgroup.insights : []);
                                            }

                                        }
                                    });
                                }
                                //console.log(adgroup);
                                if (!Array.isArray(adgroup.insights)) {
                                    adgroup.insights = [];
                                }
                                adgroup.is_loading = false;
                                return adgroup;
                            });
                            self.setState({
                                adgroups: self.state.adgroups.map((item) => {
                                    bulk_adgroups.map((inner_item) => {
                                        if (item.id == inner_item.id) {
                                            item = JSON.parse(JSON.stringify(inner_item));
                                        }
                                    });
                                    return item;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        });
                    })
                } else {
                    resolve();
                }
            });
        },
        ads: (ads, source) => {
            ads = JSON.parse(JSON.stringify(ads));
            source = JSON.parse(JSON.stringify(source));
            let self = this;
            let channel = "";
            let campaign = "";
            let adgroup = "";
            return new Promise(function (resolve, reject) {
                let bulk_ads = [];
                ads.map((ad) => {

                    //SET PARENT DATA
                    channel = ad.channel;
                    adgroup = ad.adgroup;
                    campaign = ad.campaign;

                    ad = JSON.parse(JSON.stringify(ad));

                    //CHECK ALL METRICS
                    let missing_metrics = [];
                    let metric_channels = ["custom"];
                    metric_channels.push(ad.channel);
                    metric_channels.map((channel) => {
                        if (source.metrics[channel]) {
                            for (let metric in source.metrics[channel]) {
                                let exist = false;
                                let name = "";
                                if (channel === "custom" && source.metrics[channel][metric].channels[ad.channel]) {
                                    name = source.metrics[channel][metric].channels[ad.channel].name;
                                } else {
                                    name = source.metrics[channel][metric].org_name ? source.metrics[channel][metric].org_name : source.metrics[channel][metric].name;
                                }
                                if (Array.isArray(ad.insights)) {
                                    ad.insights.map((item) => {
                                        if (
                                            source.channel_breakdowns && source.channel_breakdowns[ad.channel] && source.channel_breakdowns[ad.channel][item.channel_breakdown] &&
                                            //source.breakdown.value === item.breakdown &&
                                            ((item.daterange_start === source.daterange.start_date && item.daterange_end === source.daterange.end_date)
                                                || (!item.daterange_start && !item.daterange_end && source.daterange.value === "lifetime"))) {
                                            if (name in item) {
                                                exist = true;
                                            }
                                        }
                                    });
                                }
                                if (!exist) {
                                    missing_metrics.push(name);
                                }
                            }
                        }
                    })

                    ad.missing_metrics = missing_metrics;

                    if (Array.isArray(ad.missing_metrics) && ad.missing_metrics.length > 0) {
                        bulk_ads.push(ad);
                    }

                });
                if (bulk_ads.length > 0) {
                    self.setState({
                        ads: self.state.ads.map((item) => {
                            bulk_ads.map((inner_item) => {
                                if (item.id == inner_item.id) {
                                    item.is_loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {
                        let channel_metrics = {};
                        bulk_ads.map((ad) => {
                            if (Array.isArray(ad.missing_metrics)) {
                                if (ad.channel in channel_metrics) {
                                    ad.missing_metrics.map((metric) => {
                                        channel_metrics[ad.channel][metric] = true;
                                    });
                                } else {
                                    channel_metrics[ad.channel] = {};
                                    ad.missing_metrics.map((metric) => {
                                        channel_metrics[ad.channel][metric] = true;
                                    });
                                }
                            }
                        });
                        let query = "";
                        if (source.breakdown) {
                            query = query + "&breakdown=" + source.breakdown.value;
                        }
                        if (source.daterange && source.daterange.start_date) {
                            query = query + "&startdate=" + source.daterange.start_date;
                        }
                        if (source.daterange && source.daterange.end_date) {
                            query = query + "&enddate=" + source.daterange.end_date;
                        }
                        if (source.channel_breakdowns) {
                            for (let channel in source.channel_breakdowns) {
                                let dimensions = Object.keys(source.channel_breakdowns[channel]);
                                query = query + "&" + channel + "_breakdown=" + dimensions.join(",");
                            }
                        }
                        if (Object.keys(channel_metrics).length > 0) {
                            for (let channel in channel_metrics) {
                                query = query + "&" + channel + "_metrics=";
                                let metrics_array = [];
                                for (let metric in channel_metrics[channel]) {
                                    metrics_array.push(metric);
                                }
                                query = query + encodeURIComponent(metrics_array.join(","));
                            }
                        }
                        calls.getBulkAds({
                            id: self.props.match.params.id,
                            channel: channel,
                            campaign: campaign,
                            adgroup: adgroup,
                            ads: bulk_ads.map((item) => {
                                return item.id
                            }).join(","),
                            query: query
                        }).then((response) => {

                            bulk_ads = bulk_ads.map((ad) => {
                                if (response.data.channels[ad.channel] && Array.isArray(response.data.channels[ad.channel].ads)) {
                                    response.data.channels[ad.channel].ads.map((inner_ad) => {
                                        if (ad.id === inner_ad.id) {
                                            if (!Array.isArray(ad.insights)) {
                                                ad.insights = [];
                                            }

                                            //CHECK IF INSIGHTS DONT ALREADY EXIST
                                            let merged = false;
                                            if (ad.channel === "facebook") {
                                                ad.insights = ad.insights.map((item) => {
                                                    inner_ad.insights.map((inner_item) => {
                                                        if (
                                                            item.daterange_start === inner_item.daterange_start &&
                                                            item.daterange_end === inner_item.daterange_end &&
                                                            //item.breakdown === inner_item.breakdown &&
                                                            item.channel_breakdown === inner_item.channel_breakdown &&
                                                            item.publisher_platform === inner_item.publisher_platform
                                                        ) {
                                                            merged = true;
                                                            for (let key in item) {
                                                                if (key in inner_item) {
                                                                    item[key] = inner_item[key];
                                                                }
                                                            }
                                                            item = { ...item, ...inner_item };
                                                        }
                                                    });
                                                    return item;
                                                })
                                            } else {
                                                ad.insights = ad.insights.map((item) => {
                                                    inner_ad.insights.map((inner_item) => {
                                                        if (
                                                            item.daterange_start === inner_item.daterange_start &&
                                                            item.daterange_end === inner_item.daterange_end &&
                                                            //item.breakdown === inner_item.breakdown
                                                            item.channel_breakdown === inner_item.channel_breakdown
                                                        ) {
                                                            merged = true;
                                                            for (let key in item) {
                                                                if (key in inner_item) {
                                                                    item[key] = inner_item[key];
                                                                }
                                                            }
                                                            item = { ...item, ...inner_item };
                                                        }
                                                    });
                                                    return item;
                                                })
                                            }
                                            if (!merged) {
                                                ad.insights = ad.insights.concat(Array.isArray(inner_ad.insights) ? inner_ad.insights : []);
                                            }

                                        }
                                    });
                                }
                                if (!Array.isArray(ad.insights)) {
                                    ad.insights = [];
                                }
                                ad.is_loading = false;
                                return ad;
                            });

                            self.setState({
                                ads: self.state.ads.map((item) => {
                                    bulk_ads.map((inner_item) => {
                                        if (item.id == inner_item.id) {
                                            item = JSON.parse(JSON.stringify(inner_item));
                                        }
                                    });
                                    return item;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        });
                    })
                }
            });
        },
        cellData: (source) => {

            //console.log(source);

            var self = this;

            source.loading = false;
            delete source.data;

            let data_sources = [];
            self.state.campaigns.map((item) => {
                if (source.data_sources.campaigns && source.data_sources.campaigns[item.id]) {
                    item.temp_type = "campaigns";
                    data_sources.push(item);
                }
            });
            self.state.adgroups.map((item) => {
                if (source.data_sources.adgroups && source.data_sources.adgroups[item.id]) {
                    item.temp_type = "adgroups";
                    data_sources.push(item);
                }
            });
            self.state.ads.map((item) => {
                if (source.data_sources.ads && source.data_sources.ads[item.id]) {
                    item.temp_type = "ads";
                    data_sources.push(item);
                }
            });

            if (source.celltype.value === "number") {
                let total = 0;
                data_sources.map((item, index) => {
                    item.insights.map((insight, index) => {
                        if (source.breakdown.value === insight.breakdown &&
                            ((insight.daterange_start === source.daterange.start_date && insight.daterange_end === source.daterange.end_date)
                                ||
                                (!insight.daterange_start && !insight.daterange_end && source.daterange.value === "lifetime"))) {
                            let metric = source.metric.channels[item.channel];
                            if (metric) {
                                total = +total + (+insight[metric.name] * +metric.factor);
                            }
                        }
                    });
                });
                source.data = total;
                source.data = parseFloat((source.data).toFixed(2));
            } else if (source.celltype.value === "chart") {

                //ALL CHARTS
                let chart_data = {
                    labels: [],
                    datasets: []
                };

                //DONUT
                let dataset_donut = {
                    data: [],
                    backgroundColor: []
                };

                data_sources.map((item) => {

                    //LINE
                    let dataset_line = {
                        label: "",
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 1
                    };

                    //BAR
                    let dataset_bar = {
                        label: "",
                        data: [],
                        backgroundColor: []
                    };

                    let metric = source.metric.channels[item.channel];

                    if (source.data_sources[item.temp_type] && source.data_sources[item.temp_type][item.id] && source.data_sources[item.temp_type][item.id].rgb) {

                        //DATA SOURCE COLOR
                        var color = 'rgba(' + (source.data_sources[item.temp_type][item.id].rgb.r) + ',' + (source.data_sources[item.temp_type][item.id].rgb.g) + ',' + (source.data_sources[item.temp_type][item.id].rgb.b);

                        //LINE
                        if (source.chart.value === "line") {
                            dataset_line.label = item.name;
                            dataset_line.backgroundColor.push(color + ',0.5)');
                            dataset_line.borderColor.push(color + ',1)');
                        }

                        //DONUT
                        if (source.breakdown.value === "overview" && source.chart.value === "donut") {
                            dataset_donut.backgroundColor.push(color + ',1)');
                            chart_data.labels.push(item.name);
                        }

                        //BAR
                        if (source.chart.value === "bar") {
                            dataset_bar.label = item.name;
                            dataset_bar.backgroundColor.push(color + ',1)');
                            if (source.breakdown.value === "overview") {
                                if (chart_data.labels.length < 1) {
                                    chart_data.labels.push(metric.name);
                                }
                            }
                        }

                    }
                    let breakdown = {};
                    let date_range = {};
                    let daily_data = {};
                    if (source.breakdown.value === "daily") {
                        date_range = this.renders.dateRange(source.daterange.start_date, source.daterange.end_date);
                    }
                    item.insights.map((insight) => {
                        if (source.breakdown.value === insight.breakdown &&
                            ((insight.daterange_start === source.daterange.start_date && insight.daterange_end === source.daterange.end_date)
                                ||
                                (!insight.daterange_start && !insight.daterange_end && source.daterange.value === "lifetime"))) {
                            if (source.chart.value === "donut" || source.chart.value === "bar") {
                                if (source.breakdown.value === "gender") {
                                    if (!breakdown[insight.gender]) {
                                        breakdown[insight.gender] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                    }
                                    breakdown[insight.gender] = breakdown[insight.gender] + (insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                } else if (source.breakdown.value === "age") {
                                    if (!breakdown[insight.age]) {
                                        breakdown[insight.age] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                    }
                                    breakdown[insight.age] = breakdown[insight.age] + (insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                } else if (source.breakdown.value === "platforms") {
                                    if (item.channel !== "facebook") {
                                        insight.platform = item.channel;
                                    }
                                    if (!breakdown[insight.platform]) {
                                        breakdown[insight.platform] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                    }
                                    breakdown[insight.platform] = breakdown[insight.platform] + (insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                } else if (source.breakdown.value === "region") {
                                    if (!breakdown[insight.region]) {
                                        breakdown[insight.region] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                    }
                                    breakdown[insight.region] = breakdown[insight.region] + (insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                } else {
                                    if (source.chart.value === "donut" || source.chart.value === "bar") {
                                        if (!breakdown['overview']) {
                                            breakdown['overview'] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                        }
                                        breakdown['overview'] = breakdown['overview'] + (insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                    }
                                }
                            } else if (source.chart.value === "line") {
                                if (source.breakdown.value === "daily") {
                                    daily_data[insight.date ? insight.date : insight.start_date] = insight[metric.name] ? (insight[metric.name] * metric.factor) : 0;
                                    //chart_data.labels.push(insight.date ? insight.date : insight.start_date);
                                    //dataset_line.data.push(insight[metric.name] ? (insight[metric.name] * metric.factor) : 0);
                                }
                            }
                        }
                    });

                    if (source.chart.value === "line" && source.breakdown.value === "daily") {
                        for (let date in date_range) {
                            chart_data.labels.push(date);
                            dataset_line.data.push(daily_data[date] ? parseFloat((daily_data[date]).toFixed(2)) : 0);
                        }
                    }

                    if ((source.chart.value === "donut" || source.chart.value === "bar") && source.breakdown.value === "overview") {
                        dataset_donut.data.push(breakdown['overview']);
                        dataset_bar.data.push(parseFloat((breakdown['overview']).toFixed(2)));
                    }

                    if (source.chart.value === "donut" && source.breakdown.value !== "overview") {
                        for (let key in breakdown) {
                            dataset_donut.backgroundColor.push(color + ',1)');
                            chart_data.labels.push(key);
                            dataset_donut.data.push(parseFloat((breakdown[key]).toFixed(2)));
                        }
                    }

                    if (source.chart.value === "line") {
                        chart_data.datasets.push(dataset_line);
                    }

                    if (source.chart.value === "bar") {
                        if (source.breakdown.value !== "overview") {
                            for (let key in breakdown) {
                                dataset_bar.backgroundColor.push(color + ',1)');
                                chart_data.labels.push(key);
                                dataset_bar.data.push(parseFloat((breakdown[key]).toFixed(2)));
                            }
                        }
                        chart_data.datasets.push(dataset_bar);
                    }
                });

                if (source.chart.value === "donut") {
                    chart_data.datasets.push(dataset_donut);
                }

                source.data = chart_data;
                source.original_data = JSON.parse(JSON.stringify(chart_data));
            }

            self.state.selected_tab.grid_data = self.state.selected_tab.grid_data.map((item) => {
                if (item.i == source.i) {
                    item = JSON.parse(JSON.stringify(source));
                }
                return item;
            });

            self.state.tabs = self.state.tabs.map((item) => {
                if (item.id == self.state.selected_tab.id) {
                    item = self.state.selected_tab;
                }
                return item;
            });

            self.setState({
                tabs: self.state.tabs,
                selected_tab: self.state.selected_tab
            }, () => {
                //console.log(self.state.selected_tab)
            });

        }
    };

    renders = {
        dateRange: (startDate, endDate) => {
            var dates = {};
            var currDate = moment(startDate).startOf('day');
            var lastDate = moment(endDate).startOf('day');
            lastDate = lastDate.add(1, 'days');
            dates[moment(moment(startDate).startOf('day').clone().toDate()).format("YYYY-MM-DD")] = true;
            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                dates[moment(currDate.clone().toDate()).format("YYYY-MM-DD")] = true;
            }
            dates[moment(moment(endDate).startOf('day').clone().toDate()).format("YYYY-MM-DD")] = true;
            return dates;
        },
        dataSourcesSelected: () => {
            try {
                let campaigns = Object.keys(this.state.selected_tab.data_sources.campaigns).length;
                let adgroups = Object.keys(this.state.selected_tab.data_sources.adgroups).length;
                let ads = Object.keys(this.state.selected_tab.data_sources.ads).length;
                let total = 0;
                total = total + campaigns;
                total = total + adgroups;
                total = total + ads;
                return total > 0;
            } catch (e) {
                return false
            }
        },
        name: (name) => {
            try {
                return name.replace(/_/g, " ");
            } catch (e) {
                return name;
            }
        },
        tabLoading: () => {
            let loading = false;
            try {
                this.state.campaigns.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.campaigns && item.is_loading && this.state.selected_tab.data_sources.campaigns[item.id]) {
                        loading = true;
                    }
                });
                this.state.adgroups.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.adgroups && item.is_loading && this.state.selected_tab.data_sources.adgroups[item.id]) {
                        loading = true;
                    }
                });
                this.state.ads.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.ads && item.is_loading && this.state.selected_tab.data_sources.ads[item.id]) {
                        loading = true;
                    }
                });
            } catch (e) {

            }
            return loading || (!this.state.selected_tab || (this.state.selected_tab && this.state.selected_tab.loading));
        },
        lowerLevelThanCampaign: () => {
            return (this.state.selected_tab.data_sources && Object.keys(this.state.selected_tab.data_sources.ads).length > 0) ||
                (this.state.selected_tab.data_sources && Object.keys(this.state.selected_tab.data_sources.adgroups).length > 0)
        },
        number: (item) => {
            try {
                if (item === undefined) {
                    return "";
                } else {
                    if (this.renders.isNumeric(item)) {
                        return Math.round((item + Number.EPSILON) * 100) / 100;
                    } else {
                        return item;
                    }
                }
            } catch (e) {
                return item;
            }
        },
        isNumeric: (str) => {
            if (typeof str === "number") {
                return true;
            } else {
                return !isNaN(str) && !isNaN(parseFloat(str))
            }
        },
        init_items: () => {
            if (
                (!Array.isArray(this.state.selected_tab.parsed_metrics) || (Array.isArray(this.state.selected_tab.parsed_metrics) && this.state.selected_tab.parsed_metrics.length < 1)) ||
                (!Array.isArray(this.state.selected_tab.parsed_items) || (Array.isArray(this.state.selected_tab.parsed_items) && this.state.selected_tab.parsed_items.length < 1))
            ) {
                this.state.selected_tab.parsed_metrics = this.renders.parse_metrics();
                this.state.selected_tab.parsed_items = this.renders.parse_items().filter((item) => {
                    if (item.type === "campaign") {
                        return this.state.selected_tab.data_sources.campaigns[item.id];
                    } else if (item.type === "adgroup") {
                        return this.state.selected_tab.data_sources.adgroups[item.id];
                    } else if (item.type === "ad") {
                        return this.state.selected_tab.data_sources.ads[item.id];
                    } else {
                        return false
                    }
                });
                this.state.selected_tab.parsed_items = this.state.selected_tab.parsed_items.map((item) => {
                    console.log(item, "item");
                    item = this.renders.parse_item(item);
                    this.state.selected_tab.parsed_metrics.map((metric) => {
                        item[metric.metric] = this.renders.number(item[metric.metric]);
                    });
                    return item;
                });
                console.log(this.state.selected_tab);
                this.setState({
                    selected_tab: this.state.selected_tab
                })
            }
        },
        parse_items: () => {
            try {
                let items = [];
                if (this.state.selected_tab.channel_breakdowns) {
                    let all_items = [];
                    all_items = all_items.concat(Array.isArray(this.state.campaigns) ? JSON.parse(JSON.stringify(this.state.campaigns)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.adgroups) ? JSON.parse(JSON.stringify(this.state.adgroups)) : []);
                    all_items = all_items.concat(Array.isArray(this.state.ads) ? JSON.parse(JSON.stringify(this.state.ads)) : []);
                    //console.log(all_items);
                    all_items.map((campaign) => {
                        let dimension = {};
                        if (Array.isArray(campaign.insights)) {
                            console.log(campaign);
                            campaign.insights.map((item) => {
                                if (
                                    this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown]
                                    &&
                                    ((this.state.selected_tab.daterange.value === "lifetime" && !item.daterange_start && !item.daterange_end) || (item.daterange_start === this.state.selected_tab.daterange.start_date && item.daterange_end === this.state.selected_tab.daterange.end_date))
                                ) {
                                    item.breakdown_value = item[item.channel_breakdown];
                                    if (item.breakdown_value in dimension) {
                                        dimension[item.breakdown_value].data.push(item);
                                    } else {
                                        dimension[item.breakdown_value] = { campaign: campaign, data: [] };
                                        dimension[item.breakdown_value].data.push(item);
                                    }
                                }
                            });
                        }
                        for (let dim in dimension) {
                            let campaign = { ...JSON.parse(JSON.stringify(dimension[dim].campaign)), ...{ insights: dimension[dim].data } };
                            items = items.concat([campaign]);
                        }
                    });
                }
                return items;
            } catch (e) {
                return [];
            }
        },
        parse_item: (item) => {
            let data = {};
            item = JSON.parse(JSON.stringify(item));
            let ref_channel = item.channel;
            if (item.channel === 'google_shopping') {
                item.channel = 'google';
            }
            if (Array.isArray(item.insights)) {
                item.insights.map((inner_item) => {
                    if (
                        (
                            (!inner_item.daterange_start && !inner_item.daterange_end && this.state.selected_tab.daterange.value === "lifetime") ||
                            (inner_item.daterange_start === this.state.selected_tab.daterange.start_date &&
                                inner_item.daterange_end === this.state.selected_tab.daterange.end_date)) &&
                        (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[item.channel] && this.state.selected_tab.channel_breakdowns[item.channel][inner_item.channel_breakdown])
                    ) {
                        for (let metric in inner_item) {
                            if (this.state.selected_tab && this.state.selected_tab.metrics) {
                                for (let channel in this.state.selected_tab.metrics) {
                                    if (channel === item.channel) {
                                        for (let key in this.state.selected_tab.metrics[channel]) {
                                            if (this.state.selected_tab.metrics[channel][key].name === metric || this.state.selected_tab.metrics[channel][key].org_name === metric) {
                                                if (!(metric in data)) {
                                                    data[metric] = 0;
                                                }
                                                if (this.renders.isNumeric(inner_item[metric])) {
                                                    data[metric] += (+inner_item[metric] * +this.state.selected_tab.metrics[channel][key].factor);
                                                } else {
                                                    data[metric] = inner_item[metric];
                                                }
                                            }
                                        }
                                    } else if (channel === "custom") {
                                        try {
                                            for (let custom_metric in this.state.selected_tab.metrics[channel]) {
                                                for (let custom_metric_channel in this.state.selected_tab.metrics[channel][custom_metric].channels) {
                                                    let item_channel = "";
                                                    if (item.channel === "instagram") {
                                                        item_channel = "facebook";
                                                    } else {
                                                        item_channel = item.channel;
                                                    }
                                                    let custom_metric_item = this.state.selected_tab.metrics[channel][custom_metric].channels[custom_metric_channel];
                                                    if (custom_metric_channel === item_channel && custom_metric_item.name === metric) {
                                                        if (!(custom_metric in data)) {
                                                            data[custom_metric] = 0;
                                                        }
                                                        if (this.renders.isNumeric(inner_item[metric])) {
                                                            data[custom_metric] += (+inner_item[metric] * +custom_metric_item.factor);
                                                        } else {
                                                            data[custom_metric] = inner_item[metric];
                                                        }
                                                    }

                                                }
                                            }
                                        } catch (e) { }
                                    }
                                }
                            }

                            if (this.renders.isNumeric(inner_item[metric])) {
                                if (!(metric in data)) {
                                    data[metric] = 0;
                                }
                                data[metric] += +inner_item[metric];
                            } else {
                                data[metric] = inner_item[metric];
                            }

                        }
                    }
                });

                console.log(this.state.selected_tab.metrics);

                //CALCULATE METRIC
                if (this.state.selected_tab.metrics["custom"]) {
                    for (let custom_metric in this.state.selected_tab.metrics["custom"]) {
                        if (this.state.selected_tab.metrics["custom"][custom_metric].calculated) {
                            let calc_string = this.state.selected_tab.metrics["custom"][custom_metric].calculation;
                            for (let key in this.state.selected_tab.metrics["custom"][custom_metric].metrics) {

                                let channel_metric = this.state.selected_tab.metrics["custom"][custom_metric].metrics[key].channels[item.channel].name;

                                if (calc_string.indexOf("<" + key + ">") !== -1) {
                                    calc_string = calc_string.replace("<" + key + ">", data[channel_metric]);
                                }
                            }
                            console.log(calc_string);
                            console.log(this.renders.calculate(calc_string));
                            data[custom_metric] = this.renders.calculate(calc_string);
                        }
                    }
                }

            }
            if (ref_channel === 'google_shopping') {
                item.channel = 'google_shopping';
            }
            item = { ...item, ...data };
            return item;
        },
        parse_metrics: () => {
            let metrics = [];
            try {
                for (let channel in this.state.selected_tab.metrics) {
                    for (let metric in this.state.selected_tab.metrics[channel]) {
                        let name = this.state.selected_tab.metrics[channel][metric].alias ? this.state.selected_tab.metrics[channel][metric].alias : this.state.selected_tab.metrics[channel][metric].name;
                        /*
                        if (channel === "linkedin") {
                            let tokens = name.split(/(?=[A-Z])/);
                            name = tokens.join(" ");
                        }
                        if (channel === "facebook") {
                            if (this.state.selected_tab.metrics[channel][metric].parent) {
                                name = name.replace(this.state.selected_tab.metrics[channel][metric].parent + ".", "");
                            }
                            name = name.replace(/_/g, " ");
                        }
                        if (channel === "google") {
                            name = name.replace(/_/g, " ");
                        }
                        */

                        let calculated = false;
                        if (this.state.selected_tab.metrics[channel][metric].calculated) {
                            calculated = true;
                        }

                        metrics.push({
                            title: name,
                            subtitle: calculated ? "calculated" : channel,
                            //metric: this.state.selected_tab.metrics[channel][metric].org_name ? this.state.selected_tab.metrics[channel][metric].org_name : this.state.selected_tab.metrics[channel][metric].name,
                            metric: this.state.selected_tab.metrics[channel][metric].name,
                            value: this.state.selected_tab.metrics[channel][metric]
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            }
            metrics = metrics.filter((v, i, a) => a.findIndex(t => (t.metric === v.metric)) === i);
            //SORT ARRAY ON METRIC INDEX
            if (this.state.selected_tab.table_metrics_index && this.state.selected_tab.table_metrics) {
                metrics = metrics.filter((item) => {
                    return (item.metric in this.state.selected_tab.table_metrics)
                }).map((item) => {
                    this.state.selected_tab.table_metrics_index.map((inner_item, index) => {
                        if (inner_item.name === item.metric) {
                            item.index = index;
                        }
                    });
                    return item;
                });
            }
            return metrics.sort((a, b) => a.index - b.index);
        },
        calculate(input) {

            var f = {
                add: '+',
                sub: '-',
                div: '/',
                mlt: '*',
                mod: '%',
                exp: '^'
            };

            // Create array for Order of Operation and precedence
            f.ooo = [
                [
                    [f.mlt],
                    [f.div],
                    [f.mod],
                    [f.exp]
                ],
                [
                    [f.add],
                    [f.sub]
                ]
            ];

            input = input.replace(/[^0-9%^*\/()\-+.]/g, ''); // clean up unnecessary characters

            var output;
            for (var i = 0, n = f.ooo.length; i < n; i++) {

                // Regular Expression to look for operators between floating numbers or integers
                var re = new RegExp('(\\d+\\.?\\d*)([\\' + f.ooo[i].join('\\') + '])(\\d+\\.?\\d*)');
                re.lastIndex = 0; // take precautions and reset re starting pos

                // Loop while there is still calculation for level of precedence
                while (re.test(input)) {
                    output = _calculate(RegExp.$1, RegExp.$2, RegExp.$3);
                    if (isNaN(output) || !isFinite(output))
                        return output; // exit early if not a number
                    input = input.replace(re, output);
                }
            }

            return output;

            function _calculate(a, op, b) {
                a = a * 1;
                b = b * 1;
                switch (op) {
                    case f.add:
                        return a + b;
                        break;
                    case f.sub:
                        return a - b;
                        break;
                    case f.div:
                        return a / b;
                        break;
                    case f.mlt:
                        return a * b;
                        break;
                    case f.mod:
                        return a % b;
                        break;
                    case f.exp:
                        return Math.pow(a, b);
                        break;
                    default:
                        return null;
                }
            }
        }
    };

    render() {
        return (
            <div className="w-full pt-8 pb-8">
                <SweetAlert
                    show={this.state.cell_modal}
                    title="Not enough space"
                    type="warning"
                    text={"Remove cells or add more rows to fit this cell"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            cell_modal: false
                        })
                    }}
                />
                {
                    this.state.loading_save &&
                    <Loader
                        title={"Saving report"}
                    />
                }
                {
                    this.state.loading &&
                    <div className="mt-48 mb-48">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Report ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <Fragment>
                        <div className="flex flex-1 flex-row justify-start px-4 relative z-10">
                            {
                                this.state.premium &&
                                <div
                                    className={cx("cursor-pointer relative max-w-xs bg-white flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-center rounded-t-md text-sm", {
                                        ["bg-opacity-100 text-purple-900 text-opacity-100"]: 'settings' === this.state.selected_tab.id,
                                        ["border-transparent"]: 'settings' !== this.state.selected_tab.id
                                    })}>
                                    <div onClick={(e) => {
                                        let settings_tab = {
                                            id: 'settings',
                                            title: 'Settings',
                                            editMode: false,
                                            backup: '',
                                            loading: false,
                                            data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                                            grid_data: []
                                        };
                                        this.setState({
                                            selected_tab: settings_tab
                                        })
                                    }} className={"font-bold truncate p-3 " + 'w-11/12'}
                                        title={'settings'}>{"Settings"}
                                    </div>
                                    {'settings' === this.state.selected_tab.id &&
                                        <span className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
                                    }
                                </div>
                            }
                            {
                                Array.isArray(this.state.tabs) &&
                                this.state.tabs.map((item, index) => {
                                    return (
                                        <div
                                            className={cx("cursor-pointer relative max-w-xs truncate bg-white flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-center justify-center rounded-t-md text-sm", {
                                                ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.selected_tab.id,
                                                ["border-transparent"]: item.id !== this.state.selected_tab.id,
                                                ["ml-1"]: true
                                            })}>
                                            {
                                                !item.edit_name &&
                                                <div onClick={(e) => {
                                                    if (!this.state.loading_remove && this.state.selected_tab.id !== item.id && !this.renders.tabLoading()) {
                                                        this.setState({
                                                            selected_tab: item
                                                        }, () => {
                                                            this.init.tab();
                                                        });
                                                    }
                                                }}
                                                    className={"font-bold truncate p-3 " + (index !== 0 ? 'w-11/12' : 'w-full')}
                                                    title={item.title}>{(item.title && item.title !== "") ? item.title : "..."}
                                                </div>
                                            }
                                            {
                                                item.edit_name &&
                                                <div className="px-3 flex flex-1">
                                                    <input
                                                        id={"tabName" + item.id}
                                                        style={{ fontWeight: "700" }}
                                                        value={item.title}
                                                        className="w-full font-bold truncate text-sm text-black border-none bg-white"
                                                        onChange={(event) => {
                                                            item.title = event.target.value;
                                                            this.setState({
                                                                tabs: this.state.tabs
                                                            });
                                                        }}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                item.edit_name = false;
                                                                this.setState({
                                                                    tabs: this.state.tabs
                                                                });
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            item.edit_name = false;
                                                            this.setState({
                                                                tabs: this.state.tabs
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {item.id === this.state.selected_tab.id &&
                                                <span
                                                    className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
                                            }
                                            {
                                                !item.edit_name && this.state.selected_tab.id === item.id &&
                                                <div className="flex justify-center items-center mr-2 align-middle">
                                                    <div onClick={() => {
                                                        item.edit_name = true;
                                                        this.setState({
                                                            tabs: this.state.tabs
                                                        }, () => {
                                                            document.getElementById("tabName" + item.id).focus();
                                                        });
                                                    }} className="close w-6 h-6 transform bg-gray-100 flex items-center justify-center text-lg rounded-md">
                                                        <FeatherIcon className="stroke-current" size={12}
                                                            icon="settings" />
                                                        <ReactTooltip />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className="flex justify-center items-center mr-2 align-middle">
                                                    <span
                                                        className={(this.state.tabs.length > 1 ? "" : "opacity-25 cursor-not-allowed") + " close w-6 h-6 mr-2 transform bg-gray-100 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"}
                                                        onClick={() => {
                                                            if (this.state.tabs.length > 1) {
                                                                let tab_index = 0;
                                                                let new_tab = {};
                                                                let new_tabs = JSON.parse(JSON.stringify(this.state.tabs.filter((tab, index) => {
                                                                    if (tab.id === item.id) {
                                                                        tab_index = index;
                                                                    }
                                                                    return tab.id !== item.id;
                                                                })));
                                                                if (this.state.selected_tab.id === item.id) {
                                                                    if (new_tabs.length > 1 && tab_index !== 0) {
                                                                        new_tab = new_tabs[tab_index - 1];
                                                                    } else {
                                                                        new_tab = new_tabs[0];
                                                                    }
                                                                } else {
                                                                    new_tab = this.state.selected_tab
                                                                }
                                                                this.setState({
                                                                    selected_tab: new_tab,
                                                                    tabs: new_tabs
                                                                }, () => {
                                                                    this.init.tab();
                                                                })
                                                            }
                                                        }}
                                                    >&times;
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="flex justify-start pl-1">
                                <div data-tip={"Clone tab"} onClick={() => {
                                    if (!this.renders.tabLoading()) {

                                        let cloned_tab = {};
                                        if (this.state.premium && Array.isArray(this.state.selected_tab.grid_data)) {

                                            let new_tab = {};
                                            let new_grid = [];
                                            this.state.selected_tab.grid_data.map((cell) => {
                                                let cell_item = null;
                                                if (cell.typeOfBlock === "chart") {
                                                    let temp_data = cell.data;
                                                    delete cell.data;
                                                    cell_item = JSON.parse(JSON.stringify(cell));
                                                    cell.data = temp_data;
                                                } else {
                                                    cell_item = JSON.parse(JSON.stringify(cell));
                                                }
                                                if (cell_item) {
                                                    cell_item.i = 'n' + Date.now() + Math.floor(Math.random() * 1000);
                                                    new_grid.push(cell_item);
                                                }
                                            })

                                            let temp_grid = this.state.selected_tab.grid_data;
                                            delete this.state.selected_tab.grid_data;

                                            new_tab = JSON.parse(JSON.stringify(this.state.selected_tab));
                                            new_tab.grid_data = new_grid;
                                            new_tab.id = Math.floor((Math.random() * 9999999999) + 1);
                                            new_tab.title += " clone";

                                            this.state.selected_tab.grid_data = temp_grid;
                                            this.state.tabs.push(new_tab);
                                            cloned_tab = new_tab;

                                        } else {
                                            cloned_tab = JSON.parse(JSON.stringify(this.state.selected_tab));
                                            cloned_tab.id = Math.floor((Math.random() * 9999999999) + 1);
                                            cloned_tab.title += " clone";
                                            this.state.tabs.push(cloned_tab);
                                        }

                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: cloned_tab
                                        }, () => {
                                            if (this.state.premium && Array.isArray(this.state.selected_tab.grid_data)) {
                                                this.state.selected_tab.grid_data.map((cell) => {
                                                    this.init.cell(cell);
                                                })
                                            }
                                        });
                                    }
                                }}
                                    className="bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                        <FeatherIcon className="stroke-current" size={11}
                                            icon="copy" />
                                        <ReactTooltip />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    if (!this.renders.tabLoading()) {

                                        let new_tab = {
                                            loading: !this.state.premium,
                                            init: true,
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            title: 'Tab ' + (this.state.tabs.length + 1),
                                            editMode: false,
                                            premium: this.state.premium,
                                            grid_data: [],
                                            backup: '',
                                            groups: [{
                                                id: 1,
                                                name: "",
                                                rows: 6,
                                                layout: [],
                                                index: 0,
                                                items: [],
                                                coordinates: {}
                                            }],
                                            data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                                            start_date: null,
                                            end_date: null,
                                            channel_breakdowns: {
                                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                            },
                                            breakdown: {
                                                title: "Overview",
                                                subtitle: "",
                                                icon: "globe",
                                                value: "overview",
                                                default: true
                                            },
                                            metrics: {
                                                custom: {}
                                            },
                                            piechart: this.state.default_charts,
                                            daterange: {
                                                title: "Last 30 days",
                                                subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                                                icon: "calendar",
                                                value: "30_days",
                                                disabled: false
                                            }
                                        }
                                        this.state.tabs.push(new_tab);
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: new_tab,
                                            selected_cell: null,
                                            slideinclosed: false
                                        }, () => {
                                            if (!this.state.slideinclosed && this.state.selected_tab.id) {

                                                this.setState({
                                                    campaigns: this.state.campaigns.map((item) => {
                                                        item.open = false;
                                                        if (this.state.selected_tab.data_sources.adgroups) {
                                                            for (let adgroup in this.state.selected_tab.data_sources.adgroups) {
                                                                if (this.state.selected_tab.data_sources.adgroups[adgroup].campaign == item.id) {
                                                                    item.open = true;
                                                                }
                                                            }
                                                        }
                                                        if (this.state.selected_tab.data_sources.ads) {
                                                            for (let ad in this.state.selected_tab.data_sources.ads) {
                                                                if (this.state.selected_tab.data_sources.ads[ad].campaign == item.id) {
                                                                    item.open = true;
                                                                }
                                                            }
                                                        }
                                                        return item;
                                                    }),
                                                    adgroups: this.state.adgroups.map((item) => {
                                                        item.open = false;
                                                        if (this.state.selected_tab.data_sources.ads) {
                                                            for (let ad in this.state.selected_tab.data_sources.ads) {
                                                                if (this.state.selected_tab.data_sources.ads[ad].adgroup == item.id) {
                                                                    item.open = true;
                                                                }
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                }, () => {
                                                    this.refs.child.functions.initiate(
                                                        this.state.selected_tab.data_sources.campaigns,
                                                        this.state.selected_tab.data_sources.adgroups,
                                                        this.state.selected_tab.data_sources.ads
                                                    );
                                                });
                                            }
                                        })
                                    }
                                }} data-tip={"New tab"}
                                    className="ml-1 bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>+
                                    </div>
                                    <ReactTooltip />
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow-xl rounded-md p-4 relative overflow-hidden">
                            {
                                false &&
                                this.renders.tabLoading() && !this.state.premium && this.state.selected_tab.id !== "settings" &&
                                <div className="h-64 w-full bg-white rounded-md">
                                    <div
                                        className="justify-center align-middle flex bg-white flex-col text-center h-full w-full">
                                        {
                                            this.state.slideinclosed &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={17}
                                                color={'#060534'}
                                                loading={true}
                                            />
                                        }
                                        {
                                            !this.state.slideinclosed &&
                                            <div className="font-bold text-sm">
                                                Select at least one data source
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.selected_tab && this.state.selected_tab.id === "settings" &&
                                <div>
                                    <div className="flex">
                                        <div className="flex-1 mr-2">
                                            <Input
                                                bgcolor={false}
                                                title={"Report title"}
                                                sign={false}
                                                noTitle={false}
                                                noBorder={false}
                                                placeholder={"Title"}
                                                white={false}
                                                value={this.state.report_title}
                                                type={"text"}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.setState({
                                                        report_title: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="flex-1 ml-2">
                                            <Dropdown
                                                filter={false}
                                                title={'Report logo'}
                                                size={'full'}
                                                noBorder={false}
                                                white={false}
                                                placeholder={"Click here"}
                                                options={[
                                                    { name: "Agency logo", value: "agency" },
                                                    { name: "Client logo", value: "client" },
                                                    { name: "No Logo", value: "none" },
                                                ]}
                                                value={this.state.report_logo}
                                                onChange={(value) => {
                                                    this.setState({
                                                        report_logo: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mt-4">
                                        <TextArea
                                            bgcolor={false}
                                            title={"Report description"}
                                            sign={false}
                                            noTitle={false}
                                            noBorder={false}
                                            placeholder={"Description"}
                                            white={false}
                                            value={this.state.report_description}
                                            type={"text"}
                                            size={'full'}
                                            onChange={(event) => {
                                                this.setState({
                                                    report_description: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                !this.state.premium &&
                                this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                <div style={this.state.premium ? {
                                    backgroundColor: "#fcfcfc",
                                    maxWidth: "1200px",
                                    margin: "auto"
                                } : { backgroundColor: "#fcfcfc" }}
                                    className="flex flex-row border rounded justify-center items-center">
                                    {
                                        !this.state.premium &&
                                        <div className="flex flex-1 justify-start">
                                            <Input
                                                bgcolor={false}
                                                title={"Name"}
                                                sign={false}
                                                noTitle={true}
                                                noBorder={true}
                                                placeholder={!this.renders.tabLoading() ? "Tab name" : "Loading ..."}
                                                disabled={this.renders.tabLoading()}
                                                white={false}
                                                value={!this.renders.tabLoading() ? this.state.selected_tab.title : "Loading ..."}
                                                type={"text"}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.selected_tab.title = event.target.value;
                                                    this.setState({
                                                        selected_tab: this.state.selected_tab
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        !this.state.premium &&
                                        <div className="ml-4 mr-4">
                                            <button onClick={() => {
                                                if (!this.renders.tabLoading()) {
                                                    this.setState({
                                                        slideinclosed: false,
                                                        selected_cell: false
                                                    }, () => {
                                                        if (!this.state.slideinclosed && this.state.selected_tab.id) {
                                                            let data_sources = {};
                                                            if (this.state.premium) {
                                                                data_sources = this.state.selected_cell ? this.state.selected_cell.data_sources : {};
                                                            } else {
                                                                data_sources = this.state.selected_tab.data_sources;
                                                            }
                                                            this.setState({
                                                                campaigns: this.state.campaigns.map((item) => {
                                                                    item.open = false;
                                                                    if (data_sources.adgroups) {
                                                                        for (let adgroup in data_sources.adgroups) {
                                                                            if (data_sources.adgroups[adgroup].campaign == item.id) {
                                                                                item.open = true;
                                                                            }
                                                                        }
                                                                    }
                                                                    if (data_sources.ads) {
                                                                        for (let ad in data_sources.ads) {
                                                                            if (data_sources.ads[ad].campaign == item.id) {
                                                                                item.open = true;
                                                                            }
                                                                        }
                                                                    }
                                                                    return item;
                                                                }),
                                                                adgroups: this.state.adgroups.map((item) => {
                                                                    item.open = false;
                                                                    if (data_sources.ads) {
                                                                        for (let ad in data_sources.ads) {
                                                                            if (data_sources.ads[ad].adgroup == item.id) {
                                                                                item.open = true;
                                                                            }
                                                                        }
                                                                    }
                                                                    return item;
                                                                })
                                                            }, () => {
                                                                this.refs.child.functions.initiate(
                                                                    this.state.premium ? {} : this.state.selected_tab.data_sources.campaigns,
                                                                    this.state.premium ? {} : this.state.selected_tab.data_sources.adgroups,
                                                                    this.state.premium ? {} : this.state.selected_tab.data_sources.ads
                                                                );
                                                            });
                                                        }
                                                    })
                                                }
                                            }}
                                                className="text-xs w-48 font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-purple-500 text-purple-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                                {
                                                    !this.renders.tabLoading() &&
                                                    <FeatherIcon className="stroke-current mr-2" size={13}
                                                        icon="clipboard" />
                                                }
                                                {!this.renders.tabLoading() && !this.state.premium &&
                                                    <span>Change tab data</span>}
                                                {!this.renders.tabLoading() && this.state.premium &&
                                                    <span>Add new cell</span>}
                                                {
                                                    this.renders.tabLoading() &&
                                                    <BeatLoader sizeUnit={'px'} size={7} color={'#ffffff'} loading={true} />
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                !this.state.premium &&
                                this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                <div className="w-full h-3" />
                            }
                            {
                                !this.renders.tabLoading() && !this.state.premium &&
                                !this.renders.dataSourcesSelected() && this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                <div className="flex flex-1 h-24 justify-center items-center align-middle rounded-md">
                                    <div className="font-bold text-sm">
                                        Select at least one data source
                                    </div>
                                </div>
                            }
                            {
                                !this.renders.tabLoading() && !this.state.premium &&
                                this.renders.dataSourcesSelected() && this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                !this.renders.lowerLevelThanCampaign() &&
                                <ReportComponentsDonuts
                                    ref="donut"
                                    campaigns={this.state.campaigns}
                                    adgroups={this.state.adgroups}
                                    ads={this.state.ads}
                                    tab={this.state.selected_tab}
                                    update={(value) => {
                                        this.state.tabs = this.state.tabs.map((tab) => {
                                            if (tab.id === value.id) {
                                                tab = value;
                                            }
                                            return tab;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: value,
                                        })
                                    }}
                                />
                            }
                            {
                                !this.renders.tabLoading() && !this.state.premium &&
                                Array.isArray(this.state.selected_tab.parsed_items) &&
                                this.state.selected_tab.parsed_items.length > 0 &&
                                Array.isArray(this.state.selected_tab.parsed_metrics) &&
                                this.state.selected_tab.parsed_metrics.length > 0 &&
                                this.renders.dataSourcesSelected() && this.state.selected_tab.id && this.state.selected_tab.id !== "settings" &&
                                (this.state.selected_tab.breakdown.value !== 'products' || (this.state.selected_tab.breakdown.value === 'products' && this.state.selected_tab.products_selected && Object.keys(this.state.selected_tab.products_selected).length > 0)) &&
                                <LineChartNew
                                    metric={this.state.selected_tab.sort}
                                    selected={this.state.selected_tab}
                                    items={this.state.selected_tab.parsed_items}
                                    metrics={this.state.selected_tab.parsed_metrics}
                                />
                            }
                            {
                                !this.renders.tabLoading() && !this.state.premium &&
                                Array.isArray(this.state.selected_tab.parsed_items) &&
                                this.state.selected_tab.parsed_items.length > 0 &&
                                Array.isArray(this.state.selected_tab.parsed_metrics) &&
                                this.state.selected_tab.parsed_metrics.length > 0 &&
                                this.renders.dataSourcesSelected() && this.state.selected_tab.id && this.state.selected_tab.id !== "settings" &&
                                !this.state.selected_tab.products_selected &&
                                this.state.selected_tab.breakdown.value === 'products' &&
                                <div className="border rounded flex justify-center items-center align-middle h-32 mb-4">
                                    <div className="font-bold text-xs">
                                        Select products to show in graph
                                    </div>
                                </div>
                            }
                            {
                                !this.renders.tabLoading() && !this.state.premium &&
                                this.renders.dataSourcesSelected() && this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                <ReportTable
                                    parsed_items={this.state.selected_tab.parsed_items}
                                    parsed_metrics={this.state.selected_tab.parsed_metrics}
                                    selected={this.state.selected_tab}
                                    metrics={this.state.metrics}
                                    toggleMetrics={() => {
                                        this.setState({
                                            slideinclosedMetrics: !this.state.slideinclosedMetrics
                                        }, () => {
                                            this.refs.metrics.functions.initiate(this.state.selected_tab);
                                        })
                                    }}
                                    updateTab={(value) => {
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === value.id) {
                                                item = value;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: value
                                        })
                                    }}
                                />
                            }
                            {
                                this.state.selected_tab &&
                                this.state.selected_tab.id &&
                                this.state.premium &&
                                Array.isArray(this.state.selected_tab.grid_data) &&
                                this.state.selected_tab.id !== "settings" &&
                                <ReportGrid
                                    ref="grid"
                                    campaigns={this.state.campaigns}
                                    adgroups={this.state.adgroups}
                                    ads={this.state.ads}
                                    data={this.state.selected_tab.grid_data}
                                    groups={this.state.selected_tab.groups ? this.state.selected_tab.groups : []}
                                    addCell={(group) => {
                                        if (!this.renders.tabLoading()) {
                                            this.setState({
                                                slideinclosed: false,
                                                selected_cell: false
                                            }, () => {
                                                if (!this.state.slideinclosed && this.state.selected_tab.id) {
                                                    let data_sources = {};
                                                    if (this.state.premium) {
                                                        data_sources = this.state.selected_cell ? this.state.selected_cell.data_sources : {};
                                                    } else {
                                                        data_sources = this.state.selected_tab.data_sources;
                                                    }
                                                    this.setState({
                                                        campaigns: this.state.campaigns.map((item) => {
                                                            item.open = false;
                                                            if (data_sources.adgroups) {
                                                                for (let adgroup in data_sources.adgroups) {
                                                                    if (data_sources.adgroups[adgroup].campaign == item.id) {
                                                                        item.open = true;
                                                                    }
                                                                }
                                                            }
                                                            if (data_sources.ads) {
                                                                for (let ad in data_sources.ads) {
                                                                    if (data_sources.ads[ad].campaign == item.id) {
                                                                        item.open = true;
                                                                    }
                                                                }
                                                            }
                                                            return item;
                                                        }),
                                                        adgroups: this.state.adgroups.map((item) => {
                                                            item.open = false;
                                                            if (data_sources.ads) {
                                                                for (let ad in data_sources.ads) {
                                                                    if (data_sources.ads[ad].adgroup == item.id) {
                                                                        item.open = true;
                                                                    }
                                                                }
                                                            }
                                                            return item;
                                                        })
                                                    }, () => {
                                                        this.refs.child.functions.initiate(
                                                            this.state.premium ? {} : this.state.selected_tab.data_sources.campaigns,
                                                            this.state.premium ? {} : this.state.selected_tab.data_sources.adgroups,
                                                            this.state.premium ? {} : this.state.selected_tab.data_sources.ads,
                                                            group
                                                        );
                                                    });
                                                }
                                            })
                                        }
                                    }}
                                    cloneCell={(cell) => {

                                        let cell_item = null;
                                        if (cell.typeOfBlock === "chart") {
                                            let temp_data = cell.data;
                                            delete cell.data;
                                            cell_item = JSON.parse(JSON.stringify(cell));
                                            cell.data = temp_data;
                                        } else {
                                            cell_item = JSON.parse(JSON.stringify(cell));
                                        }
                                        cell_item.i = 'n' + Date.now() + Math.floor(Math.random() * 1000);

                                        //TEMP FIX, SET W = 2 AND H = 3
                                        cell_item.w = 2;
                                        cell_item.h = 3;

                                        let group = null;
                                        let group_coordinates = {};
                                        this.state.selected_tab.groups.map((item) => {
                                            if (item.id === cell_item.group) {
                                                group = item;
                                            }
                                        })

                                        //UPDATE GROUP COORDINATES
                                        this.state.selected_tab.grid_data.map((item) => {
                                            if (group && item.group === group.id) {
                                                let init_w = JSON.parse(JSON.stringify(item.w));
                                                let init_h = JSON.parse(JSON.stringify(item.h));
                                                for (let y = 0; y < init_h; y++) {
                                                    let init_y = JSON.parse(JSON.stringify(item.y));
                                                    init_y = init_y + y;
                                                    for (let x = 0; x < init_w; x++) {
                                                        let init_x = JSON.parse(JSON.stringify(item.x));
                                                        init_x = init_x + x;
                                                        group_coordinates[init_x + ":" + init_y] = true;
                                                    }
                                                }
                                            }
                                        })

                                        let start_x = 0;
                                        let start_y = 0;
                                        let columns = 7;
                                        let rows = (group.rows - 1);
                                        let free_spot = false;
                                        let checked_positions = [];

                                        for (let y = 0; y < rows; y++) {
                                            let init_y = start_y + y;
                                            for (let x = 0; x < columns; x++) {
                                                let init_x = start_x + x;
                                                checked_positions = [];
                                                for (let h = 0; h < cell_item.h; h++) {
                                                    for (let w = 0; w < cell_item.w; w++) {
                                                        if (init_y + h < group.rows) {
                                                            checked_positions.push(group_coordinates[(init_x + w) + ":" + (init_y + h)]);
                                                        }
                                                    }
                                                }
                                                if (checked_positions.filter((item) => { return !item }).length === checked_positions.length && checked_positions.length === (cell_item.w * cell_item.h)) {
                                                    free_spot = true;
                                                    cell_item.x = init_x;
                                                    cell_item.y = init_y;
                                                    break;
                                                }
                                            }
                                            if (free_spot) {
                                                break;
                                            }
                                        }

                                        if (free_spot) {
                                            cell_item.parsed_items = [];
                                            cell_item.parsed_metrics = [];
                                            cell_item.data = {};
                                            this.state.selected_tab.grid_data.push(cell_item);
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            }, () => {
                                                this.init.cell(cell_item);
                                            })
                                        } else {
                                            //POPUP, NOT ENOUGH SPACE
                                            this.setState({
                                                cell_modal: true
                                            })
                                        }

                                    }}
                                    addRows={(group) => {
                                        this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                            if (item.id === group.id) {
                                                item.rows = item.rows + 1;
                                            }
                                            return item;
                                        })
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    removeRows={(group) => {
                                        let last_row_contain_cells = false;
                                        this.state.selected_tab.grid_data.map((item) => {
                                            if (item.group === group.id) {
                                                if ((item.y + item.h) >= group.rows) {
                                                    last_row_contain_cells = true;
                                                }
                                            }
                                        })
                                        if (!last_row_contain_cells) {
                                            this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                                if (item.id === group.id) {
                                                    item.rows = item.rows - 1;
                                                }
                                                return item;
                                            })
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }
                                    }}
                                    removePage={(group) => {
                                        if (Array.isArray(this.state.selected_tab.groups)) {
                                            this.state.selected_tab.groups = this.state.selected_tab.groups.filter((item) => {
                                                return item.id !== group.id;
                                            });
                                        }
                                        if (Array.isArray(this.state.selected_tab.grid_data)) {
                                            this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.filter((item) => {
                                                return item.group !== group.id;
                                            });
                                        }
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    clonePage={(group) => {
                                        let group_id = 0;
                                        this.state.selected_tab.groups.map((item) => {
                                            if (item.id >= group_id) {
                                                group_id = item.id;
                                            }
                                        })
                                        group_id = group_id + 1;
                                        this.state.selected_tab.groups.push({
                                            id: group_id,
                                            name: group.name + " (clone)",
                                            rows: group.rows,
                                            layout: [],
                                            index: this.state.selected_tab.groups.length,
                                            items: [],
                                            coordinates: {}
                                        });
                                        let new_cells = [];
                                        this.state.selected_tab.grid_data.map((item) => {
                                            if (item.group === group.id) {
                                                let new_item = {};
                                                if (item.typeOfBlock === "chart") {
                                                    //let temp_data = item.data;
                                                    delete item.data;
                                                    new_item = JSON.parse(JSON.stringify(item));
                                                    //item.data = temp_data;
                                                    //new_item.data = new_item.original_data;
                                                } else {
                                                    new_item = JSON.parse(JSON.stringify(item));
                                                }
                                                new_item.i = 'n' + Date.now() + Math.floor(Math.random() * 1000);
                                                new_item.group = group_id;
                                                new_cells.push(new_item);
                                            }
                                        });
                                        this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.concat(new_cells);
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        }, () => {
                                            new_cells.map((cell) => {
                                                this.init.cell(cell);
                                            })
                                        })
                                    }}
                                    addPage={() => {
                                        if (Array.isArray(this.state.selected_tab.groups)) {
                                            let group_id = 0;
                                            this.state.selected_tab.groups.map((item) => {
                                                if (item.id >= group_id) {
                                                    group_id = item.id;
                                                }
                                            })
                                            group_id = group_id + 1;
                                            this.state.selected_tab.groups.push({
                                                id: group_id,
                                                name: "",
                                                rows: 6,
                                                layout: [],
                                                index: this.state.selected_tab.groups.length,
                                                items: [],
                                                coordinates: {}
                                            });
                                        }
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    updateGroups={(groups) => {
                                        this.state.selected_tab.groups = groups;
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    onGridChange={(data) => {
                                        this.state.selected_tab.grid_data = data;
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    onUpdateItem={(cell) => {
                                        console.log(cell);
                                        this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                                            if (item.i === cell.i) {
                                                item = cell;
                                            }
                                            return item;
                                        });
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                    onEditItem={(item) => {
                                        this.setState({
                                            selected_cell: item,
                                            slideinclosed: false
                                        }, () => {
                                            let data_sources = this.state.selected_cell ? (this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources : {}) : {};
                                            this.setState({
                                                campaigns: this.state.campaigns.map((item) => {
                                                    item.open = false;
                                                    if (data_sources.adgroups) {
                                                        for (let adgroup in data_sources.adgroups) {
                                                            if (data_sources.adgroups[adgroup].campaign == item.id) {
                                                                item.open = true;
                                                            }
                                                        }
                                                    }
                                                    if (data_sources.ads) {
                                                        for (let ad in data_sources.ads) {
                                                            if (data_sources.ads[ad].campaign == item.id) {
                                                                item.open = true;
                                                            }
                                                        }
                                                    }
                                                    return item;
                                                }),
                                                adgroups: this.state.adgroups.map((item) => {
                                                    item.open = false;
                                                    if (data_sources.ads) {
                                                        for (let ad in data_sources.ads) {
                                                            if (data_sources.ads[ad].adgroup == item.id) {
                                                                item.open = true;
                                                            }
                                                        }
                                                    }
                                                    return item;
                                                })
                                            }, () => {
                                                this.refs.child.functions.initiate(
                                                    this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.campaigns : {},
                                                    this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.adgroups : {},
                                                    this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.ads : {}
                                                );
                                            });

                                        })
                                    }}
                                    updateGrid={(grid_data) => {
                                        this.state.selected_tab.grid_data = grid_data;
                                        this.state.tabs = this.state.tabs.map((item) => {
                                            if (item.id === this.state.selected_tab.id) {
                                                item = this.state.selected_tab;
                                            }
                                            return item;
                                        });
                                        this.setState({
                                            tabs: this.state.tabs,
                                            selected_tab: this.state.selected_tab
                                        })
                                    }}
                                />
                            }
                        </div>
                    </Fragment>
                }

                {/** Slidein */}
                <Slidein
                    ref="child"
                    premium={this.state.premium}
                    closed={this.state.slideinclosed}
                    toggle={() => {
                        this.setState({
                            slideinclosed: !this.state.slideinclosed
                        })
                    }}
                    close={() => {

                        //REMOVE NEW TAB IF NOT USED
                        let update_tabs = false;
                        if (!this.state.premium && this.state.selected_tab &&
                            Object.keys(this.state.selected_tab.data_sources.campaigns).length < 1 &&
                            Object.keys(this.state.selected_tab.data_sources.adgroups).length < 1 &&
                            Object.keys(this.state.selected_tab.data_sources.ads).length < 1
                        ) {
                            update_tabs = true;
                            let index = 0;
                            this.state.tabs = this.state.tabs.filter((item, inner_index) => {
                                if (item.id == this.state.selected_tab.id) {
                                    index = inner_index;
                                }
                                return item.id !== this.state.selected_tab.id;
                            })

                            this.state.selected_tab = this.state.tabs[(index - 1)];
                        }

                        this.setState({
                            selected_tab: this.state.selected_tab,
                            tabs: this.state.tabs,
                            slideinclosed: true
                        }, () => {
                            if (update_tabs) {
                                this.init.tab();
                            }
                        })
                    }}

                    template={this.state.template}

                    tab={this.state.selected_tab}
                    cell={this.state.selected_cell}

                    metrics={this.state.metrics}
                    default_metrics={this.state.default_metrics}
                    custom_metrics={this.state.metrics ? this.state.metrics.custom : []}
                    calculated_metrics={this.state.metrics ? this.state.metrics.calculated : []}

                    campaigns={this.state.campaigns}
                    adgroups={this.state.adgroups}
                    ads={this.state.ads}

                    selectedCampaigns={this.state.selected_tab.id ? this.state.selected_tab.data_sources.campaigns : {}}
                    selectedAdgroups={this.state.selected_tab.id ? this.state.selected_tab.data_sources.adgroups : {}}
                    selectedAds={this.state.selected_tab.id ? this.state.selected_tab.data_sources.ads : {}}

                    getAdgroups={(campaign) => {
                        this.all.adgroups(campaign);
                    }}
                    getAds={(adgroup) => {
                        this.all.ads(adgroup);
                    }}

                    updateMetrics={(metric) => {
                        if (!Array.isArray(this.state.metrics.custom)) {
                            this.state.metrics.custom = [];
                        }
                        this.state.metrics.custom.push(metric);
                        this.setState({
                            metrics: this.state.metrics
                        });
                    }}

                    getMetrics={() => {
                        this.functions.metrics();
                    }}

                    updateData={(campaigns, adgroups, ads, tab, cell) => {

                        if (this.state.selected_tab.id) {
                            if (this.state.premium) {

                                cell.parsed_metrics = [];
                                cell.parsed_items = [];

                                this.init.cell(cell);

                            } else {

                                tab.data_sources.campaigns = campaigns;
                                tab.data_sources.adgroups = adgroups;
                                tab.data_sources.ads = ads;

                                tab.parsed_metrics = [];
                                tab.parsed_items = [];
                                tab.donut = false;

                                //SET LOADING
                                this.state.selected_tab.loading = true;

                                this.setState({
                                    selected_tab: tab
                                }, () => {
                                    this.state.tabs = this.state.tabs.map((tab) => {
                                        if (tab.id === this.state.selected_tab.id) {
                                            tab = this.state.selected_tab;
                                        }
                                        return tab;
                                    });
                                    this.setState({
                                        tabs: this.state.tabs
                                    }, () => {
                                        this.init.tab();
                                    });
                                })
                            }
                        }
                    }}

                />

                {/*TOPNAV*/}
                {
                    !this.state.loading &&
                    <aside style={{ zIndex: 52 }} className="bg-white top-0 fixed flex flex-row items-center justify-end px-6 py-2 md:py-3 right-0">
                        {
                            this.props.match.params.report && this.state.report_public && !this.state.loading &&
                            <button onClick={() => {
                                let type = this.state.premium ? "premium" : "freemium";
                                if (window.location.hostname === 'localhost') {
                                    window.open("http://localhost:5001/" + type + "/report/" + this.props.match.params.report);
                                } else if (window.location.hostname === 'app.adcredo.io') {
                                    window.open("https://app.adcredo.io/" + type + "/report/" + this.props.match.params.report);
                                } else if (window.location.hostname === 'dev.adcredo.io') {
                                    window.open("https://dev.adcredo.io/" + type + "/report/" + this.props.match.params.report);
                                }
                            }}
                                className="bg-purple-100 hover:bg-purple-500 hover:text-white text-purple-600 font-bold text-sm py-2 px-4 rounded-full mr-4 inline-flex justify-center items-center">
                                Open external
                            </button>
                        }
                        {
                            !this.state.loading && this.state.premium &&
                            <button onClick={() => {
                                let logo = "";
                                if (this.state.report_logo.value === "client") {
                                    logo = this.state.client.logo;
                                } else if (this.state.report_logo.value === "agency") {
                                    let user = userRegister.get();
                                    if (user) {
                                        logo = user.logo;
                                    }
                                }
                                this.refs.pptx.functions.createNewPptx({
                                    tabs: this.state.tabs,
                                    selected_logo: logo,
                                    title: this.state.report_title,
                                    summary: this.state.report_description,
                                })
                            }} className="bg-green-500 text-white font-bold text-sm py-2 px-4 rounded-full">
                                Export .pptx
                            </button>
                        }
                        {
                            this.props.match.params.report && !this.state.loading &&
                            <button onClick={() => {
                                this.setState({
                                    save_modal: true,
                                    update_report: !this.state.template,
                                    update_template: this.state.template
                                })
                            }} className="bg-green-500 text-white font-bold text-sm py-2 px-4 rounded-full ml-4">
                                Update
                            </button>
                        }
                        {
                            !this.state.loading && (this.state.template || !this.props.match.params.report) &&
                            <button onClick={() => {
                                this.setState({
                                    save_modal: true,
                                    save_report: true
                                })
                            }} className="bg-green-500 text-white font-bold text-sm py-2 px-4 rounded-full  ml-4">
                                Create report
                            </button>
                        }
                        {
                            !this.state.loading && (!this.state.template || !this.props.match.params.report) &&
                            <button onClick={() => {
                                this.setState({
                                    save_modal: true,
                                    save_template: true
                                })
                            }} className="bg-green-500 text-white font-bold text-sm py-2 px-4 rounded-full ml-4">
                                Create template
                            </button>
                        }
                    </aside>
                }
                <PptsGenJs ref={"pptx"} />
                {
                    this.state.save_modal &&
                    <Modal
                        title={this.state.update_report ? "Update report" : (this.state.save_report ? "Create report" : (this.state.save_template ? "Create template" : "Update template"))}
                        subtitle={this.props.match.params.report ? ("#" + this.props.match.params.report) : ("#" + this.props.match.params.id)}
                        blueButton={true}
                        blueButtonText={"next"}
                        onBlueButtonClick={() => {
                            if (this.props.match.params.report) {
                                if (this.state.save_report || this.state.save_template) {
                                    this.functions.save();
                                } else {
                                    this.functions.update();
                                }
                            } else {
                                this.functions.save();
                            }
                        }}
                        onClose={() => {
                            this.setState({
                                update_template: false,
                                update_report: false,
                                save_report: false,
                                save_template: false,
                                save_modal: false
                            })
                        }}
                    >
                        <div>
                            <div className="flex flex-1 rounded-md flex-col">
                                <div className="flex flex-1 flex-row">
                                    <div className="flex flex-1">
                                        <Input
                                            title={"Name"}
                                            sign={false}
                                            noBorder={false}
                                            white={false}
                                            value={this.state.report_name}
                                            type={"text"}
                                            size={'full'}
                                            onChange={(event) => {
                                                this.setState({
                                                    report_name: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    {
                                        !this.state.save_new_template && !this.state.template && !this.state.save_template &&
                                        <div className="flex flex-col ml-2">
                                            <div className="text-xs mb-1 font-bold">Public</div>
                                            <div style={{ backgroundColor: "#fcfcfc", height: "55px" }}
                                                className="flex flex-row flex-1 items-center border px-4 rounded justify-center">
                                                <Switch
                                                    onColor="#1AD5BD"
                                                    disabled={this.state.report_template || (this.state.template && !this.state.save_new_report)}
                                                    checked={(this.state.report_public && !this.state.template) || (this.state.report_public && this.state.save_new_report)}
                                                    onChange={(checked) => {
                                                        this.setState({
                                                            report_public: checked
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.report_public &&
                                    <div className="flex flex-1 mt-4">
                                        <Dropdown
                                            filter={false}
                                            title={'Logo'}
                                            size={'full'}
                                            noBorder={false}
                                            white={false}
                                            placeholder={"Click here"}
                                            options={[
                                                { name: "Agency logo", value: "agency" },
                                                { name: "Client logo", value: "client" },
                                                { name: "No Logo", value: "none" },
                                            ]}
                                            value={this.state.report_logo}
                                            onChange={(value) => {
                                                this.setState({
                                                    report_logo: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal>
                }

            </div>
        );
    }
}

class LineChartNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            selected_metrics: {},
            selected_metric: {},
            items: [],
            metrics: [],
            bar: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }]
                }
            },
            line: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }]
                }
            },
        };
    };

    componentDidMount() {
        this.setState({
            selected: this.props.selected,
            items: this.props.items,
            metrics: this.props.metrics
        }, () => {
            this.state.metrics.map((metric, index) => {
                this.state.selected_metrics[metric.title] = metric;
                if (index === 0) {
                    this.state.selected_metric = metric;
                }
            });
            this.setState({
                selected_metric: this.state.selected_metric,
                selected_metrics: this.state.selected_metrics
            }, () => {
                this.functions.data();
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            (nextProps.selected.products_selected &&
                this.state.selected.products_selected &&
                Object.keys(nextProps.selected.products_selected).length !== Object.keys(this.state.selected.products_selected))
            ||
            (nextProps.selected.products_selected !== this.state.selected.products_selected)
        ) {
            this.setState({
                selected: nextProps.selected
            }, () => {
                this.functions.data();
            })
        }
    }

    functions = {
        data: () => {

            let data = {
                labels: [],
                datasets: []
            };

            if (this.state.selected.channel_breakdowns) {

                let labels = {};
                let campaigns = {};

                let dateList = [];
                try {
                    let startDate = moment(this.state.selected.daterange.start_date);
                    let endDate = moment(this.state.selected.daterange.end_date);
                    dateList = this.functions.getDaysBetweenDates(startDate, endDate);
                } catch (error) { }

                this.state.items.map((item) => {
                    if (this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns[item.channel] && this.state.selected.channel_breakdowns[item.channel][item.channel_breakdown]) {
                        labels[item[item.channel_breakdown]] = { key: item[item.channel_breakdown], value: item.channel_breakdown };

                        if (item.channel_breakdown === 'date') {
                            dateList.map((item) => {
                                labels[item] = { key: item, value: 'date' };
                            })
                        }

                    }
                    if (!campaigns[item.id]) {
                        campaigns[item.id] = { channel: item.channel, id: item.id, name: item.name, items: [item] };
                    } else {
                        campaigns[item.id].items.push(item);
                    }
                });

                for (let key in labels) {
                    data.labels.push(labels[key]);
                }

                if (this.state.selected.channel_breakdowns.basic && this.state.selected.channel_breakdowns.basic['daily']) {
                    //data.labels.sort((a, b) => moment(a.key).format('YYYYMMDD') - moment(b.key).format('YYYYMMDD'))
                    data.labels.sort((a, b) => moment(a.key).valueOf() - moment(b.key).valueOf());
                } else {
                    data.labels.sort((a, b) => {
                        if (this.state.sort) {
                            var nameA = a.value;
                            var nameB = b.value;
                            if (nameA < nameB)
                                return -1;
                            if (nameA > nameB)
                                return 1;
                            return 0
                        } else {
                            return 1
                        }
                    });
                }

                data.labels = data.labels.map((item) => {
                    return item.key
                });

                for (let campaign in campaigns) {
                    let color = "";
                    if (campaigns[campaign].channel === "facebook") {
                        color = "#3A5998";
                    }
                    if (campaigns[campaign].channel === "google") {
                        color = "#4384F4";
                    }
                    if (campaigns[campaign].channel === "adform") {
                        color = "#60C1A4";
                    }
                    if (campaigns[campaign].channel === "google_analytics") {
                        color = "#e8710a";
                    }
                    let dataset = {
                        label: campaigns[campaign].name,
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2,
                    };
                    if (this.state.selected && this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns.basic && this.state.selected.channel_breakdowns.basic.daily) {
                        dataset.fill = false;
                        dataset.borderColor = color;
                    }
                    let labels_value = {};
                    data.labels.map((key) => {
                        campaigns[campaign].items.map((item) => {
                            if (key === item[item.channel_breakdown]) {
                                labels_value[key] = item[this.state.selected_metric.metric] ? item[this.state.selected_metric.metric] : 0;
                            }
                        })
                    });

                    data.labels.map((key) => {
                        dataset.data.push(labels_value[key] ? labels_value[key] : 0);
                        if (this.state.selected && this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns.basic && this.state.selected.channel_breakdowns.basic.daily) {

                        } else {
                            dataset.backgroundColor.push(color);
                            dataset.borderColor.push(color);
                        }
                    });

                    data.datasets.push(dataset);
                }

            }

            console.log(this.state.selected);

            this.setState({
                data: data
            })

        },
        getDaysBetweenDates: (startDate, endDate) => {
            var now = startDate.clone(), dates = [];
            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format('YYYY-MM-DD'));
                now.add(1, 'days');
            }
            return dates;
        },
    };

    renders = {};

    render() {
        return (
            <div style={{ backgroundColor: "rgb(252, 252, 252)" }} className="rounded border p-4 relative mb-4">
                <div className="inline-flex mb-4 flex-row">
                    {
                        false &&
                        this.state.selected && this.state.selected.breakdown && this.state.selected.breakdown.value === "overview" &&
                        this.state.metrics.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    if (this.state.selected_metrics[item.title]) {
                                        delete this.state.selected_metrics[item.title];
                                    } else {
                                        this.state.selected_metrics[item.title] = true;
                                    }
                                    this.setState({
                                        selected_metrics: this.state.selected_metrics
                                    }, () => {
                                        this.functions.data();
                                    })
                                }} className={(this.state.selected_metrics[item.title] ? "text-gray-900 " : "text-gray-500") + (0 === index ? " rounded-l " : "") + ((this.state.metrics.length - 1) === index ? " rounded-r" : "") + " text-xs cursor-pointer font-bold ml-1 inline-flex items-center px-4 h-8 justify-center bg-opacity-25 bg-gray-500 transition-all duration-200"}>
                                    {item.title}
                                </div>
                            )
                        })
                    }
                    {
                        this.state.metrics.map((item, index) => {
                            return (
                                <div onClick={() => {
                                    this.setState({
                                        selected_metric: item
                                    }, () => {
                                        this.functions.data();
                                    })
                                }} className={((this.state.selected_metric && this.state.selected_metric.title === item.title) ? "text-gray-900 " : "text-gray-500") + (0 === index ? " rounded-l " : "") + ((this.state.metrics.length - 1) === index ? " rounded-r" : "") + " text-xs cursor-pointer font-bold ml-1 inline-flex items-center px-4 h-8 justify-center bg-opacity-25 bg-gray-500 transition-all duration-200"}>
                                    {item.title}
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ height: "350px" }}>
                    {
                        this.state.selected && this.state.selected.channel_breakdowns && this.state.selected.channel_breakdowns.basic && this.state.selected.channel_breakdowns.basic.daily &&
                        <Line
                            options={this.state.line}
                            data={this.state.data}
                        />
                        ||
                        <Bar
                            options={this.state.bar}
                            data={this.state.data}
                        />
                    }
                </div>
            </div>
        )
    }

}

class Slidein extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_metrics: {},
            datasource: true,
            data: {},
            tab: {},
            default_metrics: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            premium: false,
            celltype: true,
            custom_metrics: [],
            calculated_metrics: [],
            channel_breakdowns: [],
            analytics_breakdowns: [],
            ga_dimensions: GoogleAnalyticsDimensions,
        };
    };

    componentDidMount() {
        this.setState({
            closed: this.props.closed,
            all_metrics: this.props.metrics,
            premium: this.props.premium,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            template: this.props.template,
            default_metrics: this.props.default_metrics,
            //custom_metrics: this.renders.metrics(this.props.custom_metrics),
            //calculated_metrics: this.renders.metrics(this.props.calculated_metrics)
        }, () => {

            let custom_metrics = [];
            if (this.state.all_metrics.custom) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.custom);
            }
            if (this.state.all_metrics.calculated) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.calculated);
            }
            this.setState({
                custom_metrics: custom_metrics
            })

        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            all_metrics: nextProps.metrics,
            premium: nextProps.premium,
            template: nextProps.template,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            ads: nextProps.ads,
            //custom_metrics: this.renders.metrics(nextProps.custom_metrics),
            //calculated_metrics: this.renders.metrics(nextProps.calculated_metrics)
        }, () => {

            let custom_metrics = [];
            if (this.state.all_metrics.custom) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.custom);
            }
            if (this.state.all_metrics.calculated) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.calculated);
            }
            this.setState({
                custom_metrics: custom_metrics
            })

        });
    }

    functions = {
        initiate: (campaigns, adgroups, ads, group) => {

            this.renders.channelBreakdowns();

            let cell = null;
            if (this.props.cell) {
                cell = this.props.cell;
                if (this.props.cell.typeOfBlock === 'chart') {
                    let temp_data = cell.data;
                    delete cell.data;
                    cell.cell = JSON.parse(JSON.stringify(cell));
                    cell.data = temp_data;
                } else {
                    cell.cell = JSON.parse(JSON.stringify(cell));
                }
            }

            this.setState({
                celltype: true,
                datasource: !this.state.premium,
                breakdown: false,
                daterange: false,
                piechart: false,
                settings: false,
                update_cell: false,
                init_tab: { ...this.props.tab },
                tab: { ...this.props.tab },
                cell: cell,
                selected_campaigns_internal: JSON.parse(JSON.stringify(campaigns)),
                selected_adgroups_internal: JSON.parse(JSON.stringify(adgroups)),
                selected_ads_internal: JSON.parse(JSON.stringify(ads)),
            }, () => {

                console.log(this.state.init_tab);

                if (this.props.premium) {

                    if (this.state.cell && this.state.cell.typeOfBlock) {
                        this.state.update_cell = true;
                    }

                    delete this.state.tab.celltype;

                    if (this.state.cell) {
                        if (this.state.cell.typeOfBlock === 'input') {
                            this.state.tab.celltype = {
                                title: "Text",
                                subtitle: "Write text in the cell",
                                icon: "file-text",
                                value: "text"
                            };
                        } else if (this.state.cell.typeOfBlock === 'img') {
                            this.state.tab.celltype = {
                                title: "Image",
                                subtitle: "Upload an image to the cell",
                                icon: "image",
                                value: "image"
                            };
                        } else if (this.state.cell.typeOfBlock === 'number') {
                            this.state.tab.celltype = {
                                title: "Aggregated number",
                                subtitle: "Summarize data from datasources",
                                icon: "tag",
                                value: "number"
                            };
                            this.state.tab.metric = this.state.cell.metric;
                            this.state.tab.daterange = this.state.cell.daterange;
                        } else if (this.state.cell.typeOfBlock === 'chart') {
                            this.state.tab.celltype = {
                                title: "Chart",
                                subtitle: "Show data in a chart",
                                icon: "pie-chart",
                                value: "chart"
                            };
                            this.state.tab.breakdown = this.state.cell.breakdown;
                            this.state.tab.chart = this.state.cell.chart;
                            this.state.tab.metric = this.state.cell.metric;
                            this.state.tab.daterange = this.state.cell.daterange;
                            this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                        } else if (this.state.cell.typeOfBlock === 'table') {
                            this.state.tab.celltype = {
                                title: "Table",
                                subtitle: "Show data in a table",
                                icon: "align-justify",
                                value: "table"
                            };
                            this.state.tab.breakdown = this.state.cell.breakdown;
                            this.state.tab.chart = this.state.cell.chart;
                            this.state.tab.metric = this.state.cell.metric;
                            this.state.tab.table_metrics = this.state.cell.table_metrics;
                            this.state.tab.table_metrics_index = this.state.cell.table_metrics_index;
                            this.state.tab.daterange = this.state.cell.daterange;
                            this.state.tab.channel_breakdowns = this.state.cell.channel_breakdowns;
                        }
                        if (this.state.cell.header !== "") {
                            this.state.tab.settings = { title: this.state.cell.header };
                        }

                        this.state.tab.group = this.state.cell.group;

                        //CHECK IF TEMPLATE AND IF NEW OR OLD CELL
                        if (
                            this.state.template &&
                            this.state.cell.typeOfBlock &&
                            Object.keys(this.state.cell.data_sources.campaigns).length < 1 &&
                            Object.keys(this.state.cell.data_sources.adgroups).length < 1 &&
                            Object.keys(this.state.cell.data_sources.ads).length < 1
                        ) {
                            this.state.celltype = false;
                            this.state.datasource = true;
                        }

                    } else {

                        if (group) {
                            this.state.tab.group = group;
                        }

                        this.state.tab.cellType = null;
                        this.state.tab.channel_breakdowns = null;
                        this.state.tab.metric = null;
                        this.state.tab.daterange = null;

                        /*
                        if (!this.state.init_tab.channel_breakdowns) {
                            this.state.init_tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": {
                                    "overview": {
                                        title: "Overview",
                                        subtitle: "Agregated data",
                                        icon: "globe",
                                        value: "overview",
                                        supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                                    }
                                }
                            };
                        }
                        */


                    }
                } else {

                    if (!this.state.init_tab.channel_breakdowns) {
                        this.state.tab.channel_breakdowns = {
                            "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                            "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                            "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                            "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                            "basic": {
                                "overview": {
                                    title: "Overview",
                                    subtitle: "Agregated data",
                                    icon: "globe",
                                    value: "overview",
                                    supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                                }
                            }
                        };
                    }

                    if (this.state.tab.metrics && this.state.tab.metrics.custom) {
                        if (!this.state.tab.table_metrics) {
                            this.state.tab.table_metrics = {};
                            this.state.tab.table_metrics_index = [];
                            for (let metric in this.state.tab.metrics.custom) {
                                this.state.tab.metrics.custom[metric].metric_type = "custom";
                                this.state.tab.table_metrics[metric] = this.state.tab.metrics.custom[metric];
                                this.state.tab.table_metrics_index.push(this.state.tab.metrics.custom[metric]);
                            }
                        }
                    }
                    if (this.state.tab.metrics) {
                        this.state.channel_metrics = [];
                        for (let channel in this.state.tab.metrics) {
                            if (channel !== "custom") {
                                for (let metric in this.state.tab.metrics[channel]) {
                                    this.state.tab.metrics[channel][metric].channels = {};
                                    this.state.tab.metrics[channel][metric].code = 'code';
                                    this.state.tab.metrics[channel][metric].title = this.state.tab.metrics[channel][metric].name;
                                    this.state.tab.metrics[channel][metric].channels[channel] = JSON.parse(JSON.stringify(this.state.tab.metrics[channel][metric]));
                                    this.state.channel_metrics.push(this.state.tab.metrics[channel][metric]);
                                }
                            }
                        }
                    }
                }

                if (!this.state.tab.piechart) {
                    this.state.tab.piechart = {
                        "impressions": {
                            title: "Impressions",
                            subtitle: "Show total impressions per platform",
                            icon: "pie-chart",
                            value: "impressions"
                        },
                        "click": {
                            title: "Clicks",
                            subtitle: "Show total click per platform",
                            icon: "pie-chart",
                            value: "click"
                        },
                        "ctr": {
                            title: "Avg. CTR",
                            subtitle: "Show average CTR per platform",
                            icon: "pie-chart",
                            value: "ctr"
                        },
                        "cpc": {
                            title: "Avg. CPC",
                            subtitle: "Show average CPC per platform",
                            icon: "pie-chart",
                            value: "cpc"
                        },
                        "spend": {
                            title: "Spend",
                            subtitle: "Show total spend per platform",
                            icon: "pie-chart",
                            value: "spend"
                        }
                    };
                }

                this.setState({
                    celltype: this.state.celltype,
                    datasource: this.state.datasource,
                    channel_metrics: this.state.channel_metrics ? this.state.channel_metrics : [],
                    update_cell: this.state.update_cell,
                    cell: this.state.cell,
                    tab: this.state.tab
                })
            })
        }
    };

    renders = {
        metrics: (items) => {
            if (Array.isArray(items)) {
                items = items.map((item) => {
                    item.title = item.name;
                    item.subtitle = "Summarize all " + item.name;
                    item.icon = "code";
                    item.value = item.id + item.name;
                    return item;
                });
                return Array.isArray(items) ? items : [];
            } else {
                return [];
            }
        },
        analyticsBreakDowns: () => {
            let breakdowns = [
                {
                    title: "User",
                    values: [
                        { name: "User Type", value: "ga:userType" },
                        { name: "Count of Sessions", value: "ga:sessionCount" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdowns: () => {
            let breakdowns = [
                {
                    title: "Overview",
                    subtitle: "Agregated data",
                    icon: "globe",
                    value: "overview",
                    supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                },
                {
                    title: "Daily",
                    subtitle: "Agregated data per 24h",
                    icon: "sun",
                    value: "daily",
                    supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                },
                {
                    title: "Gender",
                    subtitle: "Agregated data based on gender",
                    icon: "users",
                    value: "gender",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Age",
                    subtitle: "Agregated data based on age",
                    icon: "watch",
                    value: "age",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Region",
                    subtitle: "Agregated data per region",
                    icon: "navigation",
                    value: "region",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Products",
                    subtitle: "Agregated data per product (shopping)",
                    icon: "gift",
                    value: "products",
                    supported_channels: { "google": false, "linkedin": false, "facebook": true, "google_analytics": false, "google_shopping": true, "adform": false }
                }
            ];
            return breakdowns;
        },
        channelBreakdowns: () => {
            let channels = [];
            channels.push({ channel: "google", breakdowns: this.renders.breakdownsGoogle() });
            channels.push({ channel: "adform", breakdowns: this.renders.breakdownsAdform() });
            channels.push({ channel: "facebook", breakdowns: this.renders.breakdownsFacebook() });
            channels.push({ channel: "google_analytics", breakdowns: this.renders.breakdownsGoogleAnalytics() });
            this.setState({
                channel_breakdowns: channels
            });
        },
        breakdownsGoogle: () => {
            let breakdowns = [
                {
                    title: "basic",
                    values: [
                        //{ name: "Overview", value: "overview", parent: "basic" },
                        { name: "Gender", value: "gender", parent: "basic" },
                        { name: "Age", value: "age", parent: "basic" },
                        { name: "Region", value: "region", parent: "basic" },
                        //{ name: "Date", value: "date", parent: "basic" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsAdform: () => {
            let breakdowns = [
                /*{
                    title: "basic", values: [
                        { name: "Overview", value: "overview", parent: "basic" }
                    ]
                },
                */
                {
                    title: "Banner", values: [
                        { name: "Banner Size", value: "bannerSize", parent: "Banner", description: "This dimension groups information by banner size. Banner size is shown as \"width x height\" in pixels, e.g. 300 x 250." },
                        { name: "Banner/Adgroups", value: "banner", parent: "Banner", description: "This dimension groups information by banner for Display campaigns, or by Adgroup for Search campaigns. Banner is an advertisement vehicle. The banner is displayed through different medias/publishers on different placements. Adgroup contains one or more keywords used in Search Engine campaigns." },
                    ]
                },
                {
                    title: "Site Tracking", values: [
                        { name: "Page", value: "page", parent: "Site Tracking", description: "Website tracking dimension. Page is a definable content unit in advertiser's site. Usually one tracking point is implemented per page." },
                    ]
                },
                /*{
                    title: "Time", values: [
                        { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." },
                    ]
                },*/
                {
                    title: "Technical", values: [
                        { name: "Device Type", value: "deviceType", parent: "Technical", description: "Technical dimension, groups data by device type, which can be: Desktop and Laptop, Small Screen Phone, Smart Phone, Tablet, Other Mobile, Media Hub, Console, TV, eReader, Smart Watch, Smart Speaker. It shows what share of traffic comes from different device types." },
                    ]
                },
                {
                    title: "RTB", values: [
                        { name: "RTB Domain", value: "rtbDomain", parent: "RTB", description: "This dimension groups data by RTB domain. RTB domains show 2nd level URLs of publisher sites (e.g. cnn.com), where a banner was displayed when impression was registered. RTB domain values are tracked and sent to Adform by AdExchanges." },
                        { name: "RTB Audience", value: "rtbAudience", parent: "RTB", description: "This dimension groups information by RTB audience selected in the Targeting step. An audience is a customized segment of users to be targeted, e.g. women from Denmark using Android devices." }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogleAnalytics: () => {
            let beakdowns = [
                {
                    title: "User",
                    values: [
                        { name: "User Type", value: "ga:userType", parent: "User" },
                        { name: "Count of Sessions", value: "ga:sessionCount", parent: "User" }
                    ]
                },
                {
                    title: "Traffic sources",
                    values: [
                        { name: "Medium", value: "ga:medium", parent: "Traffic sources" },
                        { name: "Source / Medium", value: "ga:sourceMedium", parent: "Traffic sources" },
                        { name: "Full Referrer", value: "ga:fullReferrer", parent: "Traffic sources" },
                        { name: "Referral path", value: "ga:referralPath", parent: "Traffic sources" }
                    ]
                }
            ];
            return this.state.ga_dimensions;
        },
        breakdownsFacebook: () => {
            let beakdowns = [
                {
                    title: "Basic",
                    values: [
                        //{ name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Ad Format Asset", value: "ad_format_asset", parent: "Basic" },
                        { name: "App ID", value: "app_id", parent: "Basic" },
                        { name: "Body Asset", value: "body_asset", parent: "Basic" },
                        { name: "Call To Action Asset", value: "call_to_action_asset", parent: "Basic" },
                        { name: "Description Asset", value: "description_asset", parent: "Basic" },
                        { name: "Image Asset", value: "image_asset", parent: "Basic" },
                        { name: "Link URL Asset", value: "link_url_asset", parent: "Basic" },
                        { name: "Skan Conversion ID", value: "skan_conversion_id", parent: "Basic" },
                        { name: "Title Asset", value: "title_asset", parent: "Basic" },
                        { name: "Video Asset", value: "video_asset", parent: "Basic" },
                        { name: "DMA", value: "dma", parent: "Basic" },
                        { name: "Frequency Value", value: "frequency_value", parent: "Basic" },
                        { name: "Hourly stats by advertiser timezone", value: "hourly_stats_aggregated_by_advertiser_time_zone", parent: "Basic" },
                        { name: "Hourly stats by audience timezone", value: "hourly_stats_aggregated_by_audience_time_zone", parent: "Basic" },
                        { name: "Place Page ID", value: "place_page_id", parent: "Basic" },
                        { name: "Country", value: "country", parent: "Basic" },
                        { name: "Region", value: "region", parent: "Basic" },
                        { name: "Age", value: "age", parent: "Basic" },
                        { name: "Gender", value: "gender", parent: "Basic" },
                        //{ name: "Date", value: "date", parent: "Basic" },
                        { name: "Platform posistion", value: "platform_position", parent: "Basic" },
                        { name: "Publisher Platform", value: "publisher_platform", parent: "Basic" },
                        { name: "Impression Device", value: "impression_device", parent: "Basic" },
                        { name: "Product ID", value: "product_id", parent: "Basic" }
                    ]
                }
            ];
            return beakdowns;
        },
        cellTypes: () => {
            let celltypes = [
                {
                    title: "Image/Video",
                    subtitle: "Upload an image/video to the cell",
                    icon: "image",
                    value: "image"
                },
                {
                    title: "Text",
                    subtitle: "Write text in the cell",
                    icon: "file-text",
                    value: "text"
                },
                {
                    title: "Aggregated number",
                    subtitle: "Summarize data from datasources",
                    icon: "tag",
                    value: "number"
                },
                {
                    title: "Chart",
                    subtitle: "Show data in a chart",
                    icon: "pie-chart",
                    value: "chart"
                },
                {
                    title: "Table",
                    subtitle: "Show data in a table",
                    icon: "align-justify",
                    value: "table"
                }
            ];
            return celltypes;
        },
        chartTypes: () => {
            let chartTypes = [
                {
                    title: "Donut",
                    subtitle: "Display data in a donut chart",
                    icon: "pie-chart",
                    value: "donut",
                    disabled: true
                },
                {
                    title: "Bar",
                    subtitle: "Display data in a bar chart",
                    icon: "bar-chart-2",
                    value: "bar"
                },
                {
                    title: "Line",
                    subtitle: "Display data in a line chart",
                    icon: "activity",
                    value: "line"
                }
            ];
            return chartTypes;
        },
        selectedDataSources: () => {
            let sources = [];
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    sources.push(this.state.selected_campaigns_internal[key]);
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    sources.push(this.state.selected_adgroups_internal[key]);
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    sources.push(this.state.selected_ads_internal[key]);
                }
            }
            return sources;
        },
        selectedDataSourcesChannells: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    channels[this.state.selected_adgroups_internal[key].channel] = true;
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    channels[this.state.selected_ads_internal[key].channel] = true;
                }
            }
            return channels;
        },
        analyticsSelected: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            return channels["google_analytics"];
        },
        anythingButAnalyticsSelected: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    if (this.state.selected_campaigns_internal[key].channel !== "google_analytics") {
                        channels[this.state.selected_campaigns_internal[key].channel] = true;
                    }
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    if (this.state.selected_adgroups_internal[key].channel !== "google_analytics") {
                        channels[this.state.selected_adgroups_internal[key].channel] = true;
                    }
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    if (this.state.selected_ads_internal[key].channel !== "google_analytics") {
                        channels[this.state.selected_ads_internal[key].channel] = true;
                    }
                }
            }
            return Object.keys(channels).length > 0;
        },
        customMetrics: (options, search) => {
            if (search && search !== "") {
                if (Array.isArray(options)) {
                    let metrics = [];
                    let result = fuzzysort.go(search, options.map((item) => { return item.name }));
                    result.map((item) => {
                        for (let i = 0; i < options.length; i++) {
                            if (item.target && item.target.toLowerCase() === options[i].name.toLowerCase()) {
                                metrics.push(options[i]);
                            }
                        }
                    });
                    return metrics;
                } else {
                    return [];
                }
            } else {
                return Array.isArray(options) ? options : [];
            }
        },
        channelMetrics: () => {
            return Array.isArray(this.state.channel_metrics) ? this.state.channel_metrics : [];
        },
        piecharts: () => {
            let piecharts = [
                {
                    title: "Impressions",
                    subtitle: "Show total impressions per platform",
                    icon: "pie-chart",
                    value: "impressions"
                },
                { title: "Clicks", subtitle: "Show total click per platform", icon: "pie-chart", value: "click" },
                { title: "Avg. CTR", subtitle: "Show average CTR per platform", icon: "pie-chart", value: "ctr" },
                { title: "Avg. CPC", subtitle: "Show average CPC per platform", icon: "pie-chart", value: "cpc" },
                { title: "Spend", subtitle: "Show total spend per platform", icon: "pie-chart", value: "spend" },
            ];
            return piecharts;
        },
        dateranges: () => {
            let dateranges = [
                {
                    title: "Lifetime",
                    subtitle: "From start date until today",
                    icon: "calendar",
                    value: "lifetime"
                },
                {
                    title: "Last 10 days",
                    subtitle: ("From " + moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "10_days",
                    disabled: false
                },
                {
                    title: "last 20 days",
                    subtitle: ("From " + moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "20_days",
                    disabled: false
                },
                {
                    title: "Last 30 days",
                    subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "30_days",
                    disabled: false
                },
                {
                    title: "Custom dates",
                    subtitle: "Add custom start date and end date",
                    icon: "calendar",
                    value: "custom_dates",
                    disabled: false
                }
            ];
            return dateranges;
        },
        valid: () => {

            if (this.state.premium) {
                return (

                    //IF TEXT OR IMAGE SELECTED
                    (this.state.tab.celltype && (this.state.tab.celltype.value === "text" || this.state.tab.celltype.value === "image"))

                    ||

                    //IF NUMBER SELECTED
                    (this.state.tab.celltype && (this.state.tab.celltype.value === "number" && ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") ||
                        (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                        this.state.tab.metric && this.state.tab.metric.value && this.state.tab.metric.value !== "new_metric" && this.state.tab.metric.value !== "channel_metric" &&
                        //this.state.tab.settings && this.state.tab.settings.title && this.state.tab.settings.title !== "" &&
                        this.renders.selectedDataSources().filter((source) => {
                            if (this.state.tab.metric && this.state.tab.metric.calculated) {
                                return true;
                            } else {
                                return this.state.tab.metric.channels && this.state.tab.metric.channels[source.channel];
                            }
                        }).length > 0 &&
                        (
                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                        )))

                    ||

                    //IF CHART SELECTED
                    (this.state.tab.celltype && (this.state.tab.celltype.value === "chart" && ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") ||
                        (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                        this.state.tab.metric && this.state.tab.metric.value && this.state.tab.metric.value !== "new_metric" && this.state.tab.metric.value !== "channel_metric" &&
                        //this.state.tab.breakdown && this.state.tab.breakdown.value &&
                        this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                        this.state.tab.chart && this.state.tab.chart.value &&
                        //this.state.tab.settings && this.state.tab.settings.title && this.state.tab.settings.title !== "" &&
                        this.renders.selectedDataSources().filter((source) => {
                            if (this.state.tab.metric && this.state.tab.metric.calculated) {
                                return true;
                            } else {
                                return this.state.tab.metric.channels && this.state.tab.metric.channels[source.channel];
                            }
                        }).length > 0 &&
                        (
                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                        )))

                    ||

                    //IF TABLE SELECTED
                    (this.state.tab.celltype && (this.state.tab.celltype.value === "table" && ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") ||
                        (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                        this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length !== 0 && this.state.tab.table_metrics.constructor === Object &&
                        //this.state.tab.breakdown && this.state.tab.breakdown.value &&
                        this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                        //this.state.tab.settings && this.state.tab.settings.title && this.state.tab.settings.title !== "" &&
                        (
                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                        )))

                )
            } else {
                return ((this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates") ||
                    (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                    //this.state.tab.breakdown && this.state.tab.breakdown.value &&
                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                    this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length !== 0 && this.state.tab.table_metrics.constructor === Object &&
                    (
                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                    )
            }
        },
        stepValid: (step) => {
            if (step === "datasource") {
                return (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value) || (!this.state.premium);
            } else if (step === "breakdown") {
                return this.renders.anythingSelected();
            } else if (step === "analytics_breakdown") {
                return this.renders.anythingSelected();
            } else if (step === "chart") {
                return this.renders.stepValid('breakdown') && this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0; //this.state.tab.breakdown && this.state.tab.breakdown.value;
            } else if (step === "piechart") {
                return this.renders.anythingSelected() &&
                    !this.renders.lowerLevelThanCampaign() &&
                    //this.state.tab.breakdown &&
                    //this.state.tab.breakdown.value &&
                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                    this.state.tab.daterange &&
                    ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates")
            } else if (step === "daterange") {
                return (
                    this.renders.anythingSelected() &&
                    (
                        (
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 && this.state.tab.chart && this.state.tab.chart.value)
                            ||
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "table" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0)
                            ||
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "number")
                        )
                        ||
                        (
                            !this.state.premium && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0
                        )
                    )
                )
            } else if (step === "metric") {
                return (
                    this.renders.anythingSelected() &&
                    (
                        (
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 && this.state.tab.chart && this.state.tab.chart.value && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates"))
                            ||
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "table" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates"))
                            ||
                            (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "number" && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates"))
                        )
                        ||
                        (
                            !this.state.premium && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0
                        )
                    )
                )
            } else if (step === "settings") {

                return (
                    ((this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "text" || this.state.tab.celltype.value === "image")))
                    ||
                    (this.renders.anythingSelected() &&
                        (
                            (
                                //this.state.tab.settings && this.state.tab.settings.title && this.state.tab.settings.title !== "" &&
                                (
                                    (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 && this.state.tab.chart && this.state.tab.chart.value && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates") && this.state.tab.metric && this.state.tab.metric.value && this.state.tab.metric.value !== "new_metric" && this.state.tab.metric.value !== "channel_metric" && this.state.tab.metric.value !== "temp_metric")
                                    ||
                                    (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "number" && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates") && this.state.tab.metric && this.state.tab.metric.value && this.state.tab.metric.value !== "new_metric" && this.state.tab.metric.value !== "channel_metric" && this.state.tab.metric.value !== "temp_metric")
                                    ||
                                    (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "table" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 && this.state.tab.daterange && ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates") && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length !== 0 && this.state.tab.table_metrics.constructor === Object)
                                )
                            )
                            ||
                            (!this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length !== 0)
                        )
                    ))
            } else {
                return false
            }
        },
        anythingSelected: () => {
            return ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0))
        },
        multipleLevels: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        lowerLevelThanCampaign: () => {
            return (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
        },
        groups: () => {
            let groups = [];
            let unique_groups = {};
            if (this.state.tab && Array.isArray(this.state.tab.grid_data)) {
                this.state.tab.grid_data.map((item) => {
                    if (!item.group) {
                        item.group = 0;
                    }
                    if (!unique_groups[item.group]) {
                        unique_groups[item.group] = [];
                    }
                    unique_groups[item.group].push(item);
                });
                for (let key in unique_groups) {
                    groups.push({ id: key, items: unique_groups[key].length });
                }
                groups.sort((a, b) => {
                    if (a > b) return 1;
                    if (b > a) return -1;
                    return 0;
                });
            }
            return groups;
        }
    };

    render() {

        const SortableItem = SortableElement(({ item, index }) => {
            return (
                <div style={{ zIndex: 99999999 }}
                    className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                    <div
                        className="text-purple-500 text-xs font-bold bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                        {item.index + 1}
                    </div>
                    <div className="text-xs flex flex-1 ml-2">
                        <div className="flex flex-1 flex-col truncate">
                            <div className="font-bold text-xs truncate">
                                {item.alias ? item.alias : item.name}
                            </div>
                            <div className="text-xxs capitalize">
                                {item.calculated ? "Calculated metric" : "Custom metric"}
                            </div>
                        </div>
                    </div>
                    <div
                        className="text-purple-500 text-xs font-bold cursor-pointer bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                        <FeatherIcon className="stroke-current transform rotate-90"
                            size={14}
                            icon={'code'}
                        />
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({ items }) => (
            <div className="">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));

        const onSortEnd = ({ oldIndex, newIndex }) => {
            this.state.tab.table_metrics_index = arrayMove(this.state.tab.table_metrics_index, oldIndex, newIndex);
            this.setState({
                tab: this.state.tab
            });
        };

        return (
            <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                <div className="absolute inset-0 overflow-hidden">
                    <div
                        className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.props.closed ? 'opacity-0' : 'opacity-100'}`}></div>

                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <div
                            className={`relative w-screen ${(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "edit_metric" || this.state.tab.metric.value === "temp_metric")) ? 'max-w-4xl' : 'max-w-md'} transform transition ease-in-out duration-500 sm:duration-700 ${this.props.closed ? 'translate-x-full' : 'translate-x-0'}`}
                            ref={this.props.slideOver && this.props.slideOver}>
                            {
                                false &&
                                <div className="absolute right-100 md:pr-4 md:pt-4 flex">
                                    <button aria-label="Close panel"
                                        className="bg-red-500 text-white font-bold text-xs flex items-center rounded-full py-2 px-4 hover:bg-red-700 transition ease-in-out duration-150"
                                        onClick={() => {
                                            this.props.close();
                                        }}
                                    >
                                        <FeatherIcon className="stroke-current mr-1" size={16} icon="x" /> <span>Close</span>
                                    </button>
                                </div>
                            }
                            <div className="absolute right-100 pr-6 pt-6 flex">
                                <button aria-label="Close panel"
                                    className="bg-red-500 text-white border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                    onClick={() => {
                                        this.props.close();
                                    }}
                                >
                                    <FeatherIcon className="stroke-current" size={16} icon="x" />
                                </button>
                            </div>
                            <div
                                className={`flex flex-row-reverse bg-white h-full shadow-xl ${this.props.slideOver && this.props.rounded ? this.props.rounded : ''}`}>

                                {
                                    <div
                                        className={((this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "edit_metric" || this.state.tab.metric.value === "temp_metric")) ? "w-full" : "w-0") + " h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                        <header className="px-4 sm:px-6 py-6 bg-gray-100 flex flex-row">
                                            <div className="flex flex-1 items-center">
                                                {
                                                    this.state.premium && this.state.tab.metric && this.state.tab.metric.value === "temp_metric" &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Create temporary metric
                                                    </h2>
                                                }
                                                {
                                                    !this.state.premium && this.state.tab.metric && this.state.tab.metric.value === "channel_metric" &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Select channel metrics
                                                    </h2>
                                                }
                                                {
                                                    this.state.tab.metric && this.state.tab.metric.value === "new_metric" && !this.state.edit_metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Create custom metric
                                                    </h2>
                                                }
                                                {
                                                    this.state.tab.metric && this.state.tab.metric.value === "edit_metric" && this.state.edit_metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Edit custom metric
                                                    </h2>
                                                }
                                            </div>
                                            <div>
                                                <div onClick={() => {
                                                    //this.props.close();
                                                    delete this.state.tab.metric;
                                                    this.setState({
                                                        edit_metric: false,
                                                        tab: this.state.tab
                                                    });
                                                }}
                                                    className="transition ease-in-out duration-150 bg-red-500 text-white cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                        icon={"x"} />
                                                </div>
                                            </div>
                                        </header>
                                        <div className="overflow-scroll">
                                            <div className="w-full h-6" />
                                            <CreateCustomMetric
                                                ref={'customMetric'}
                                                view={"report"}
                                                premium={this.state.premium}
                                                channels={this.renders.selectedDataSources().map((item) => {
                                                    return item.channel
                                                })}
                                                channelMetric={this.state.tab.metric && this.state.tab.metric.value === "channel_metric"}
                                                metrics={this.state.all_metrics}
                                                metric={this.state.edit_metric}
                                                option={this.state.tab.metric}
                                                clients={[]}
                                                datasources={this.renders.selectedDataSources()}
                                                close={(value, type) => {

                                                    console.log(value, type);

                                                    if (type === "create_metric") {
                                                        //this.state.tab.metric = {};
                                                        //this.props.getMetrics();
                                                        //this.state.tab.metric = value;
                                                        value.title = value.name;
                                                        value.new = true;
                                                        value.icon = 'code';
                                                        //this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(value)));

                                                        if (this.props.updateMetrics) {
                                                            this.props.updateMetrics(value);
                                                        }

                                                        delete this.state.tab.metric;
                                                    }


                                                    //console.log(value);

                                                    /*
                                                    if (type === "create_metric") {
                                                        this.state.tab.metric = value;
                                                        this.state.tab.metric.title = value.name;
                                                        this.state.tab.metric.icon = 'code';
                                                        this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(this.state.tab.metric)));
                                                        this.state.tab.metric = {};
                                                    } else if (type === "edit_metric") {
                                                        this.state.tab.metric = {};
                                                        this.state.custom_metrics = this.state.custom_metrics.map((item) => {
                                                            if (item.id === value.id) {
                                                                item = value;
                                                            }
                                                            return item;
                                                        })
                                                    } else if (type === "temp_metric") {
                                                        this.state.tab.metric = value;
                                                        this.state.tab.metric.title = value.name;
                                                        this.state.tab.metric.icon = 'code';
                                                        this.state.tab.metric.temp = true;
                                                        this.state.tab.metric.value = value.name;
                                                        this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(this.state.tab.metric)));
                                                    } else if (type === 'channel_metric') {
                                                        this.state.tab.metric = {};
                                                        if (!Array.isArray(this.state.channel_metrics)) {
                                                            this.state.channel_metrics = [];
                                                        }
                                                        for (let channel in value) {
                                                            for (let metric in value[channel]) {
                                                                let item = JSON.parse(JSON.stringify(value[channel][metric]))
                                                                item.title = value[channel][metric].name;
                                                                item.channels = {};
                                                                item.channels[channel] = JSON.parse(JSON.stringify(value[channel][metric]));
                                                                item.icon = "code";
                                                                this.state.channel_metrics.push(item);

                                                                if (!this.state.tab.table_metrics) {
                                                                    this.state.tab.table_metrics = {};
                                                                }
                                                                if (!this.state.tab.table_metrics_index) {
                                                                    this.state.tab.table_metrics_index = [];
                                                                }
                                                                if (item.name in this.state.tab.table_metrics) {

                                                                } else {
                                                                    item.metric_type = "channel";
                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                    this.state.tab.table_metrics_index.push(item);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    */

                                                    if (this.state.premium) {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = {
                                                                title: ""
                                                            };
                                                        }
                                                        if (this.state.tab.metric) {
                                                            //this.state.tab.settings.title = this.state.tab.metric.alias ? this.state.tab.metric.alias : this.state.tab.metric.title;
                                                        }
                                                    }

                                                    this.setState({
                                                        tab: this.state.tab,
                                                        channel_metrics: this.state.channel_metrics,
                                                        custom_metrics: this.state.custom_metrics,
                                                        edit_metric: false
                                                    });

                                                }}
                                            />
                                        </div>
                                    </div>
                                }


                                <div className={"flex w-full flex-col pb-6 max-w-md border-r overflow-scroll h-full relative"}>
                                    <header className="px-4 sm:px-6 mb-6 bg-gray-100 py-6 flex flex-row">
                                        <div className="flex flex-1 items-center">
                                            {
                                                this.state.premium &&
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Create new cell
                                                </h2>
                                            }
                                            {
                                                !this.state.premium &&
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Update tab
                                                </h2>
                                            }
                                        </div>
                                    </header>
                                    {
                                        this.state.premium &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50" : "") + " px-4 sm:px-6 pb-6 border-b"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        celltype: !this.state.celltype
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select cell type
                                                    </h3>
                                                    {
                                                        !this.state.tab.celltype &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.cellTypes().length}</span> cell
                                                            types
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.celltype.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        this.state.tab.celltype &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    <div onClick={() => {
                                                        this.setState({
                                                            celltype: !this.state.celltype
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.celltype &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.celltype &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.celltype}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.cellTypes().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={("cursor-pointer") + (i !== (this.renders.cellTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            this.state.tab.celltype = item;
                                                                            if (!this.state.tab.group) {
                                                                                this.state.tab.group = 0;
                                                                            }

                                                                            this.setState({
                                                                                tab: this.state.tab,
                                                                                celltype: false,
                                                                                datasource: (item.value !== "image" && item.value !== "text"),
                                                                                settings: (item.value === "image" || item.value === "text")
                                                                            })
                                                                        }}
                                                                            className={((this.state.tab.celltype && this.state.tab.celltype.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.celltype && this.state.tab.celltype.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.celltype && this.state.tab.celltype.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                celltype: false,
                                                                datasource: (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text"),
                                                                settings: (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50" : "") + (this.renders.stepValid('datasource') ? " " : " opacity-50 ") + (this.state.premium ? "pt-6" : "") + " px-4 sm:px-6 pb-6 border-b"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        datasource: !this.state.datasource
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select data source 123
                                                    </h3>
                                                    {
                                                        (
                                                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                            ||
                                                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                            ||
                                                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                        )
                                                        &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected
                                                            {
                                                                (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_campaigns_internal).length} campaign
                                                                    {
                                                                        Object.keys(this.state.selected_campaigns_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                    {
                                                                        ((this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                                            || (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                                        ) &&
                                                                        <span>,</span>
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_adgroups_internal).length} adgroup
                                                                    {
                                                                        Object.keys(this.state.selected_adgroups_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                    {
                                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) &&
                                                                        <span>,</span>
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_ads_internal).length} ad
                                                                    {
                                                                        Object.keys(this.state.selected_ads_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                </span>
                                                            }
                                                        </p>
                                                        ||
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Display data from selected data sources
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        (
                                                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                            ||
                                                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                            ||
                                                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                        )
                                                        &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    <div onClick={() => {
                                                        this.setState({
                                                            datasource: !this.state.datasource
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.datasource &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.datasource &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.datasource}>
                                                <div className="w-full h-6" />
                                                {
                                                    this.state.campaigns.map((campaign, index) => {
                                                        return (
                                                            <Fragment>
                                                                <div
                                                                    className="flex flex-1 flex-row justify-center mb-2">
                                                                    <div onClick={() => {
                                                                        if (campaign.channel !== "google_analytics") {
                                                                            if (!campaign.open) {
                                                                                this.props.getAdgroups(campaign);
                                                                                campaign.open = true;
                                                                            } else {
                                                                                campaign.open = false;
                                                                            }
                                                                            this.setState({
                                                                                campaigns: this.state.campaigns
                                                                            })
                                                                        }
                                                                    }}
                                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            !campaign.open && !campaign.loading &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="plus" />
                                                                        }
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            campaign.open && !campaign.loading &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="minus" />
                                                                        }
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            campaign.loading &&
                                                                            <BeatLoader
                                                                                sizeUnit={"px"}
                                                                                size={4}
                                                                                color={'#060534'}
                                                                                loading={true}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        if (!campaign.is_loading) {
                                                                            if (this.state.selected_campaigns_internal[campaign.id]) {
                                                                                delete this.state.selected_campaigns_internal[campaign.id]
                                                                            } else {
                                                                                let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                let color = "";
                                                                                if (campaign.channel === "facebook") {
                                                                                    color = "#3A5998";
                                                                                } else if (campaign.channel === "google") {
                                                                                    color = "#4384F4";
                                                                                } else if (campaign.channel === "linkedin") {
                                                                                    color = "#0D65A1";
                                                                                } else if (campaign.channel === "google_analytics") {
                                                                                    color = "#e8710a";
                                                                                } else if (campaign.channel === "google_shopping") {
                                                                                    color = "#34A853";
                                                                                } else if (campaign.channel === "adform") {
                                                                                    color = "#60C1A4";
                                                                                } else {
                                                                                    color = colors[index];
                                                                                }
                                                                                campaign.hex = color;
                                                                                campaign.rgb = campaign.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                    , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                    .substring(1).match(/.{2}/g)
                                                                                    .map(x => parseInt(x, 16));
                                                                                if (Array.isArray(campaign.rgb)) {
                                                                                    try {
                                                                                        let rgb = campaign.rgb;
                                                                                        campaign.rgb = {
                                                                                            r: rgb[0],
                                                                                            g: rgb[1],
                                                                                            b: rgb[2]
                                                                                        };
                                                                                    } catch (e) {
                                                                                    }
                                                                                }
                                                                                this.state.selected_campaigns_internal[campaign.id] = campaign;
                                                                            }
                                                                            this.setState({
                                                                                selected_campaigns_internal: this.state.selected_campaigns_internal
                                                                            })
                                                                        }
                                                                    }}
                                                                        className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.selected_campaigns_internal[campaign.id] &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="check" />
                                                                        }
                                                                        {
                                                                            campaign.is_loading &&
                                                                            <div
                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                <BeatLoader
                                                                                    sizeUnit={"px"}
                                                                                    size={4}
                                                                                    color={'#060534'}
                                                                                    loading={true}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        campaign.channel === "google" &&
                                                                        <div
                                                                            className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/google_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "google_shopping" &&
                                                                        <div
                                                                            className="bg-googleshopping-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/google-shopping.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "linkedin" &&
                                                                        <div
                                                                            className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/linkedin_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "facebook" &&
                                                                        <div
                                                                            className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img style={{ width: '0.4rem' }}
                                                                                src={require('../../assets/images/facebook_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "google_analytics" &&
                                                                        <div
                                                                            className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/ga_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "adform" &&
                                                                        <div
                                                                            className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/adform_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "twitter" &&
                                                                        <div
                                                                            className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../assets/images/twitter_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    <div className="flex flex-1 flex-col truncate">
                                                                        <div className="font-bold text-xs truncate">
                                                                            {campaign.name}
                                                                        </div>
                                                                        <div className="text-xxs flex flex-row">
                                                                            CAMPAIGN
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.adgroups.filter((adgroup) => {
                                                                        return adgroup.campaign === campaign.id && campaign.open
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                        <div
                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500"></div>
                                                                        <div onClick={() => {
                                                                            if (this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length !== this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id
                                                                            }).length) {
                                                                                //SELECT ALL
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign === campaign.id
                                                                                }).map((adgroup) => {
                                                                                    this.state.selected_adgroups_internal[adgroup.id] = adgroup;
                                                                                });

                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                    this.state.tab.breakdown = {
                                                                                        title: "Overview",
                                                                                        subtitle: "Agregated data",
                                                                                        icon: "globe",
                                                                                        value: "overview"
                                                                                    };
                                                                                }

                                                                            } else {
                                                                                //REMOVE ALL
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                                }).map((adgroup) => {
                                                                                    delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                });
                                                                            }
                                                                            this.setState({
                                                                                tab: this.state.tab,
                                                                                selected_adgroups_internal: this.state.selected_adgroups_internal
                                                                            })
                                                                        }}
                                                                            className={(this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length === this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id
                                                                            }).length ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                                }).length === this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign === campaign.id
                                                                                }).length &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check" />
                                                                            }
                                                                            {
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id] && adgroup.is_loading
                                                                                }).length > 0 &&
                                                                                <div
                                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                    <BeatLoader
                                                                                        sizeUnit={"px"}
                                                                                        size={4}
                                                                                        color={'#060534'}
                                                                                        loading={true}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length === this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id
                                                                            }).length &&
                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    Deselect all
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    Click here to deselect all adgroups
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length !== this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign === campaign.id
                                                                            }).length &&
                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    Select all
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    Click here to select all adgroups
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.adgroups.filter((adgroup) => {
                                                                        return adgroup.campaign === campaign.id && campaign.open
                                                                    }).map((adgroup) => {
                                                                        return (
                                                                            <Fragment>
                                                                                <div
                                                                                    className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                                    <div onClick={() => {
                                                                                        if (!adgroup.open) {
                                                                                            this.props.getAds(adgroup);
                                                                                            adgroup.open = true;
                                                                                        } else {
                                                                                            adgroup.open = false;
                                                                                        }
                                                                                        this.setState({
                                                                                            adgroups: this.state.adgroups
                                                                                        })
                                                                                    }}
                                                                                        className="cursor-pointer h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                                                                        {
                                                                                            !adgroup.open && !adgroup.loading &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14} icon="plus" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.open && !adgroup.loading &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14} icon="minus" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.loading &&
                                                                                            <BeatLoader
                                                                                                sizeUnit={"px"}
                                                                                                size={4}
                                                                                                color={'#060534'}
                                                                                                loading={true}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    <div onClick={() => {
                                                                                        if (!adgroup.is_loading) {
                                                                                            if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                                                                delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                            } else {
                                                                                                let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                                let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                                let color = "";
                                                                                                if (adgroup.channel === "facebook") {
                                                                                                    color = "#3A5998";
                                                                                                } else if (adgroup.channel === "google") {
                                                                                                    color = "#4384F4";
                                                                                                } else if (adgroup.channel === "linkedin") {
                                                                                                    color = "#0D65A1";
                                                                                                } else if (adgroup.channel === "google_analytics") {
                                                                                                    color = "#e8710a";
                                                                                                } else if (adgroup.channel === "google_shopping") {
                                                                                                    color = "#34A853";
                                                                                                } else if (adgroup.channel === "adform") {
                                                                                                    color = "#60C1A4";
                                                                                                } else {
                                                                                                    color = colors[index];
                                                                                                }
                                                                                                adgroup.hex = color;
                                                                                                adgroup.rgb = adgroup.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                                    , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                                    .substring(1).match(/.{2}/g)
                                                                                                    .map(x => parseInt(x, 16));
                                                                                                if (Array.isArray(adgroup.rgb)) {
                                                                                                    try {
                                                                                                        let rgb = adgroup.rgb;
                                                                                                        adgroup.rgb = {
                                                                                                            r: rgb[0],
                                                                                                            g: rgb[1],
                                                                                                            b: rgb[2]
                                                                                                        };
                                                                                                    } catch (e) {
                                                                                                    }
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[adgroup.id] = adgroup;

                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                    this.state.tab.breakdown = {
                                                                                                        title: "Overview",
                                                                                                        subtitle: "Agregated data",
                                                                                                        icon: "globe",
                                                                                                        value: "overview"
                                                                                                    };
                                                                                                }

                                                                                            }
                                                                                            this.setState({
                                                                                                tab: this.state.tab,
                                                                                                selected_adgroups_internal: this.state.selected_adgroups_internal
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                        className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14}
                                                                                                icon="check" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.is_loading &&
                                                                                            <div
                                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                <BeatLoader
                                                                                                    sizeUnit={"px"}
                                                                                                    size={4}
                                                                                                    color={'#060534'}
                                                                                                    loading={true}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex flex-1 flex-col truncate">
                                                                                        <div
                                                                                            className="font-bold text-xs truncate">
                                                                                            {adgroup.name}
                                                                                        </div>
                                                                                        <div className="text-xxs">
                                                                                            ADGROUP
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.ads.filter((ad) => {
                                                                                        return ad.adgroup === adgroup.id && adgroup.open
                                                                                    }).length > 0 &&
                                                                                    <div
                                                                                        className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                                        <div
                                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500" />
                                                                                        <div
                                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500" />
                                                                                        <div onClick={() => {
                                                                                            if (this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length !== this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id
                                                                                            }).length) {

                                                                                                //SELECT ALL
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup === adgroup.id
                                                                                                }).map((ad) => {
                                                                                                    this.state.selected_ads_internal[ad.id] = ad;
                                                                                                });

                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                    this.state.tab.breakdown = {
                                                                                                        title: "Overview",
                                                                                                        subtitle: "Agregated data",
                                                                                                        icon: "globe",
                                                                                                        value: "overview"
                                                                                                    };
                                                                                                }

                                                                                            } else {
                                                                                                //REMOVE ALL
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                                }).map((ad) => {
                                                                                                    delete this.state.selected_ads_internal[ad.id];
                                                                                                });
                                                                                            }
                                                                                            this.setState({
                                                                                                tab: this.state.tab,
                                                                                                selected_ads_internal: this.state.selected_ads_internal
                                                                                            })
                                                                                        }}
                                                                                            className={(this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length === this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id
                                                                                            }).length ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                            {
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                                }).length === this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup === adgroup.id
                                                                                                }).length &&
                                                                                                <FeatherIcon
                                                                                                    className={'stroke-current'}
                                                                                                    size={14}
                                                                                                    icon="check" />
                                                                                            }
                                                                                            {
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id] && ad.is_loading
                                                                                                }).length > 0 &&
                                                                                                <div
                                                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                    <BeatLoader
                                                                                                        sizeUnit={"px"}
                                                                                                        size={4}
                                                                                                        color={'#060534'}
                                                                                                        loading={true}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length === this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id
                                                                                            }).length &&
                                                                                            <div
                                                                                                className="flex flex-1 flex-col truncate">
                                                                                                <div
                                                                                                    className="font-bold text-xs truncate">
                                                                                                    Deselect all
                                                                                                </div>
                                                                                                <div
                                                                                                    className="text-xxs">
                                                                                                    Click here to
                                                                                                    deselect
                                                                                                    all
                                                                                                    ads
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length !== this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup === adgroup.id
                                                                                            }).length &&
                                                                                            <div
                                                                                                className="flex flex-1 flex-col truncate">
                                                                                                <div
                                                                                                    className="font-bold text-xs truncate">
                                                                                                    Select all
                                                                                                </div>
                                                                                                <div
                                                                                                    className="text-xxs">
                                                                                                    Click here to select
                                                                                                    all
                                                                                                    ads
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.ads.filter((ad) => {
                                                                                        return ad.adgroup === adgroup.id && adgroup.open
                                                                                    }).map((ad) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="flex flex-1 flex-row justify-center pl-20 mb-2">
                                                                                                <div
                                                                                                    className="h-8 w-8 flex rounded mr-2"></div>
                                                                                                <div onClick={() => {
                                                                                                    if (!ad.is_loading) {
                                                                                                        if (this.state.selected_ads_internal[ad.id]) {
                                                                                                            delete this.state.selected_ads_internal[ad.id];
                                                                                                        } else {
                                                                                                            let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                                            let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                                            let color = "";
                                                                                                            if (ad.channel === "facebook") {
                                                                                                                color = "#3A5998";
                                                                                                            } else if (ad.channel === "google") {
                                                                                                                color = "#4384F4";
                                                                                                            } else if (ad.channel === "linkedin") {
                                                                                                                color = "#0D65A1";
                                                                                                            } else if (ad.channel === "google_analytics") {
                                                                                                                color = "#e8710a";
                                                                                                            } else if (ad.channel === "google_shopping") {
                                                                                                                color = "#34A853";
                                                                                                            } else if (ad.channel === "adform") {
                                                                                                                color = "#60C1A4";
                                                                                                            } else {
                                                                                                                color = colors[index];
                                                                                                            }
                                                                                                            ad.hex = color;
                                                                                                            ad.rgb = ad.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                                                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                                                .substring(1).match(/.{2}/g)
                                                                                                                .map(x => parseInt(x, 16));

                                                                                                            if (Array.isArray(ad.rgb)) {
                                                                                                                try {
                                                                                                                    let rgb = ad.rgb;
                                                                                                                    ad.rgb = {
                                                                                                                        r: rgb[0],
                                                                                                                        g: rgb[1],
                                                                                                                        b: rgb[2]
                                                                                                                    };
                                                                                                                } catch (e) {
                                                                                                                }
                                                                                                            }

                                                                                                            this.state.selected_ads_internal[ad.id] = ad;

                                                                                                            //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                this.state.tab.breakdown = {
                                                                                                                    title: "Overview",
                                                                                                                    subtitle: "Agregated data",
                                                                                                                    icon: "globe",
                                                                                                                    value: "overview"
                                                                                                                };
                                                                                                            }

                                                                                                        }
                                                                                                        this.setState({
                                                                                                            tab: this.state.tab,
                                                                                                            selected_ads_internal: this.state.selected_ads_internal
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                                    className={(this.state.selected_ads_internal[ad.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.selected_ads_internal[ad.id] &&
                                                                                                        <FeatherIcon
                                                                                                            className={'stroke-current'}
                                                                                                            size={14}
                                                                                                            icon="check" />
                                                                                                    }
                                                                                                    {
                                                                                                        ad.is_loading &&
                                                                                                        <div
                                                                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                            <BeatLoader
                                                                                                                sizeUnit={"px"}
                                                                                                                size={4}
                                                                                                                color={'#060534'}
                                                                                                                loading={true}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex flex-1 flex-col">
                                                                                                    <div
                                                                                                        className="font-bold text-xs">
                                                                                                        {ad.name !== "" ? ad.name : "No name"}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="text-xxs">
                                                                                                        AD
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    (this.state.campaigns.length - 1) !== index &&
                                                                    <div className="border-t flex-1 flex mb-2" />
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    )
                                                    &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                datasource: false,
                                                                daterange: this.state.premium ? (this.state.tab.celltype.value !== "chart" && this.state.tab.celltype.value !== "table") : false,
                                                                breakdown: this.state.premium ? (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table") : true
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-3">NEXT
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.renders.anythingSelected() &&
                                                    this.renders.multipleLevels() &&
                                                    <div className="flex flex-1 bg-yellow-100 rounded-md p-4 mt-4">
                                                        <div className="text-xs text-yellow-500 font-bold">
                                                            OBS! You have selected multiple levels of datasources
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        false &&
                                        this.renders.analyticsSelected() &&
                                        Array.isArray(this.state.analytics_breakdowns) &&
                                        <div className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('analytics_breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div className="flex flex-col flex-1">
                                                    <h3 className="mb-0 font-bold">
                                                        Select analytics breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.analytics_breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.state.analytics_breakdowns.length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.analytics_breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.analytics_breakdown &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                analytics_breakdown: !this.state.analytics_breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.analytics_breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.analytics_breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.analytics_breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.state.analytics_breakdowns.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className={(index !== (this.state.analytics_breakdowns.length - 1) ? "mb-2 " : "")}>
                                                                        <div
                                                                            className={"cursor-pointer flex flex-1 flex-row justify-center"}>
                                                                            <div onClick={() => {
                                                                                item.open = !item.open;
                                                                                this.setState({
                                                                                    analytics_breakdowns: this.state.analytics_breakdowns
                                                                                })
                                                                            }}
                                                                                className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                {
                                                                                    !item.open &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"plus"} />
                                                                                }
                                                                                {
                                                                                    item.open &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"minus"} />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                false &&
                                                                                <div onClick={() => {
                                                                                    this.state.tab.analytics_breakdown = item;
                                                                                    this.setState({
                                                                                        tab: this.state.tab,
                                                                                        analytics_breakdown: false,
                                                                                        breakdown: true
                                                                                    })
                                                                                }}
                                                                                    className={((this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                    {
                                                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item.value &&
                                                                                        <div
                                                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate">
                                                                                    {item.title}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    There are <span
                                                                                        className="font-bold">{item.values.length}</span> breakdowns
                                                                                    available
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <SlideDown closed={!item.open}>
                                                                            {
                                                                                item.values.map((item_inner) => {
                                                                                    return (
                                                                                        <div className={"cursor-pointer flex flex-1 flex-row justify-center mt-2 pl-10"}>
                                                                                            {
                                                                                                <div onClick={() => {

                                                                                                    this.state.analytics_breakdowns.map((item) => {
                                                                                                        if (item_inner.parent !== item.title) {
                                                                                                            item.open = false;
                                                                                                        }
                                                                                                    })

                                                                                                    this.state.tab.analytics_breakdown = item_inner;
                                                                                                    this.setState({
                                                                                                        analytics_breakdown: this.state.analytics_breakdown,
                                                                                                        tab: this.state.tab,
                                                                                                        analytics_breakdown: false,
                                                                                                        breakdown: true
                                                                                                    })
                                                                                                }}
                                                                                                    className={((this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item_inner.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item_inner.value &&
                                                                                                        <div
                                                                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                                <div className="font-bold text-xs truncate">
                                                                                                    {item_inner.name}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    {item_inner.value}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SlideDown>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                analytics_breakdown: false,
                                                                breakdown: true
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {/*SELECT BREAKDOWN*/}
                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        breakdown: !this.state.breakdown
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: !this.state.breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    <div className="text-xs mb-1 font-bold">
                                                        Common breakdowns
                                                    </div>
                                                    {
                                                        this.renders.breakdowns().filter((item) => {
                                                            let channels = this.renders.selectedDataSourcesChannells();
                                                            return Object.keys(channels).filter((channel) => { return item.supported_channels[channel] }).length === Object.keys(channels).length
                                                        }).map((item) => {
                                                            return (
                                                                <div
                                                                    className="flex flex-1 flex-row justify-center mb-2">
                                                                    <div
                                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                        <FeatherIcon className={'stroke-current'}
                                                                            size={14}
                                                                            icon={item.icon} />
                                                                    </div>
                                                                    <div onClick={() => {

                                                                        if (!this.state.tab.channel_breakdowns["basic"]) {
                                                                            this.state.tab.channel_breakdowns["basic"] = {};
                                                                            this.state.tab.channel_breakdowns["basic"][item.value] = item;
                                                                        } else if (this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"][item.value]) {
                                                                            delete this.state.tab.channel_breakdowns["basic"];
                                                                        } else if (this.state.tab.channel_breakdowns["basic"] && !this.state.tab.channel_breakdowns["basic"][item.value]) {
                                                                            this.state.tab.channel_breakdowns["basic"] = {};
                                                                            this.state.tab.channel_breakdowns["basic"][item.value] = item;
                                                                        }

                                                                        //SET ALL CHANNELS TO DATE IF DATE IS SELECTED
                                                                        if (this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"][item.value]) {
                                                                            if (item.value === "overview") {
                                                                                this.state.tab.channel_breakdowns = {
                                                                                    "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                                    "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                                    "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                                    "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                                                    "basic": {
                                                                                        "overview": {
                                                                                            title: "Overview",
                                                                                            subtitle: "Agregated data",
                                                                                            icon: "globe",
                                                                                            value: "overview",
                                                                                            supported_channels: { "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_shopping": true, "adform": true }
                                                                                        }
                                                                                    }
                                                                                };
                                                                            } else if (item.value === "daily") {
                                                                                this.state.tab.channel_breakdowns["google"] = { "date": { name: "Date", value: "date", parent: "Basic" } };
                                                                                this.state.tab.channel_breakdowns["facebook"] = { "date": { name: "Date", value: "date", parent: "basic" } };
                                                                                this.state.tab.channel_breakdowns["adform"] = { "date": { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." } };
                                                                                this.state.tab.channel_breakdowns["google_analytics"] = { "date": { name: "Date", value: "ga:date", parent: "basic" } };
                                                                            } else if (item.value === "age") {
                                                                                this.state.tab.channel_breakdowns["google"] = { "age": { name: "Age", value: "age", parent: "Basic" } };
                                                                                this.state.tab.channel_breakdowns["facebook"] = { "age": { name: "Age", value: "age", parent: "basic" } };
                                                                            } else if (item.value === "gender") {
                                                                                this.state.tab.channel_breakdowns["google"] = { "gender": { name: "Gender", value: "gender", parent: "Basic" } };
                                                                                this.state.tab.channel_breakdowns["facebook"] = { "gender": { name: "Gender", value: "gender", parent: "basic" } };
                                                                            } else if (item.value === "region") {
                                                                                this.state.tab.channel_breakdowns["google"] = { "region": { name: "Region", value: "region", parent: "Basic" } };
                                                                                this.state.tab.channel_breakdowns["facebook"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                                                                            } else if (item.value === "products") {
                                                                                this.state.tab.channel_breakdowns["google"] = { "products": { name: "Products", value: "products", parent: "Basic" } };
                                                                                this.state.tab.channel_breakdowns["facebook"] = { "products": { name: "Products", value: "products", parent: "basic" } };
                                                                            }
                                                                        } else {
                                                                            this.state.tab.channel_breakdowns["google"] = {};
                                                                            this.state.tab.channel_breakdowns["facebook"] = {};
                                                                            this.state.tab.channel_breakdowns["google_analytics"] = {};
                                                                            this.state.tab.channel_breakdowns["adform"] = {};
                                                                        }

                                                                        //RESET CHART TYPE
                                                                        this.state.tab.chart = {};

                                                                        this.setState({
                                                                            tab: this.state.tab
                                                                        }, () => {
                                                                            if (this.state.tab.channel_breakdowns["basic"]) {
                                                                                this.setState({
                                                                                    breakdown: false,
                                                                                    daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                                    chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                                                })
                                                                            }
                                                                        });
                                                                    }}
                                                                        className={(this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"][item.value] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"][item.value] &&
                                                                            <FeatherIcon
                                                                                className={'stroke-current'}
                                                                                size={14}
                                                                                icon="check" />
                                                                        }
                                                                    </div>
                                                                    <div className="flex flex-1 flex-col truncate capitalize">
                                                                        <div className="font-bold text-xs truncate">
                                                                            {item.title}
                                                                        </div>
                                                                        <div className="text-xxs">
                                                                            {item.subtitle}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <div className="w-full h-2"></div>
                                                    }
                                                    <div className={((this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"]) ? "opacity-25" : "") + " text-xs mb-1 font-bold "}>
                                                        Channel breakdowns
                                                    </div>
                                                    {
                                                        this.state.channel_breakdowns.filter((item) => {
                                                            let channels = this.renders.selectedDataSourcesChannells();
                                                            return channels[item.channel];
                                                        }).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className={((this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"]) ? "opacity-25" : "")}>
                                                                        {
                                                                            item.breakdowns.map((inner_item) => {
                                                                                return (
                                                                                    <Fragment key={i}>
                                                                                        <div
                                                                                            className="flex flex-1 flex-row justify-center mb-2">
                                                                                            <div onClick={() => {
                                                                                                inner_item.open = !inner_item.open;
                                                                                                this.setState({
                                                                                                    channel_breakdowns: this.state.channel_breakdowns
                                                                                                })
                                                                                            }}
                                                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                                {
                                                                                                    !item.open &&
                                                                                                    <FeatherIcon className={'stroke-current'}
                                                                                                        size={14}
                                                                                                        icon="plus" />
                                                                                                }
                                                                                                {
                                                                                                    item.open &&
                                                                                                    <FeatherIcon className={'stroke-current'}
                                                                                                        size={14}
                                                                                                        icon="minus" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                item.channel === "google" &&
                                                                                                <div
                                                                                                    className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/google_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "google_shopping" &&
                                                                                                <div
                                                                                                    className="bg-googleshopping-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/google-shopping.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "linkedin" &&
                                                                                                <div
                                                                                                    className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/linkedin_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "facebook" &&
                                                                                                <div
                                                                                                    className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img style={{ width: '0.4rem' }}
                                                                                                        src={require('../../assets/images/facebook_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "google_analytics" &&
                                                                                                <div
                                                                                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/ga_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "adform" &&
                                                                                                <div
                                                                                                    className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/adform_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "twitter" &&
                                                                                                <div
                                                                                                    className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../assets/images/twitter_icon.svg')} />
                                                                                                </div>
                                                                                            }

                                                                                            <div className="flex flex-1 flex-col truncate capitalize">
                                                                                                <div className="font-bold text-xs truncate">
                                                                                                    {inner_item.title}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    Breakdowns {inner_item.values.length} available
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <SlideDown closed={!inner_item.open}>
                                                                                            {
                                                                                                inner_item.values.map((dimension) => {
                                                                                                    return (
                                                                                                        <div className="ml-10 flex flex-1 flex-row mb-2">
                                                                                                            <div onClick={() => {
                                                                                                                if (this.state.tab.channel_breakdowns[item.channel]) {
                                                                                                                    this.state.tab.channel_breakdowns[item.channel] = {};
                                                                                                                }
                                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value] = dimension;
                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab
                                                                                                                });
                                                                                                            }}
                                                                                                                className={(this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel][dimension.value] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                                {
                                                                                                                    this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                                                    <FeatherIcon
                                                                                                                        className={'stroke-current'}
                                                                                                                        size={14}
                                                                                                                        icon="check" />
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="flex flex-1 flex-col truncate capitalize">
                                                                                                                <div className="font-bold text-xs truncate">
                                                                                                                    {dimension.name}
                                                                                                                </div>
                                                                                                                <div className="text-xxs">
                                                                                                                    {dimension.value}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </SlideDown>
                                                                                    </Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            (this.state.channel_breakdowns.filter((item) => {
                                                                                let channels = this.renders.selectedDataSourcesChannells();
                                                                                return channels[item.channel];
                                                                            }).length - 1) !== i &&
                                                                            <div className="border-t flex-1 flex mb-2" />
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {/*SELECT BREAKDOWN*/}
                                    {
                                        false &&
                                        this.renders.anythingButAnalyticsSelected() &&
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div className="flex flex-col flex-1">
                                                    <h3 className="mb-0 font-bold">
                                                        Select breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.breakdowns().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.breakdown &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: !this.state.breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.breakdowns().map((item, i) => {
                                                            let channels_selected = this.renders.selectedDataSourcesChannells();
                                                            //REMOVE GOOGLE ANALYTICS
                                                            delete channels_selected.google_analytics;
                                                            let enable_breakdown = (Object.keys(item.supported_channels).filter((channel) => { return item.supported_channels[channel] && channels_selected[channel] }).length) === (Object.keys(channels_selected).filter((channel) => { return channels_selected[channel] }).length);
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (this.renders.lowerLevelThanCampaign() && item.value !== "overview") || !enable_breakdown) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled && (!this.renders.lowerLevelThanCampaign() || item.value === "overview") && enable_breakdown) {
                                                                                this.state.tab.breakdown = item;
                                                                                if (item.value === "daily" && this.state.tab.daterange && this.state.tab.daterange.value === "lifetime") {
                                                                                    this.state.tab.daterange = {};
                                                                                }

                                                                                this.state.tab.chart = null;

                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    breakdown: false,
                                                                                    //daterange: !this.state.premium,
                                                                                    daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                                    chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.breakdown && this.state.tab.breakdown.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.breakdown && this.state.tab.breakdown.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === 'temp_metric') ? "opacity-50 " : "") + (this.renders.stepValid('chart') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        chart: !this.state.chart
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select chart type
                                                    </h3>
                                                    {
                                                        !this.state.tab.chart &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.chartTypes().length}</span> metrics
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.chart && this.state.tab.chart.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.chart.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('chart') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.chart &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                chart: !this.state.chart
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.chart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.chart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.chart}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        //className={((item.disabled || (this.renders.lowerLevelThanCampaign() && item.value !== "overview")) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                        this.renders.chartTypes().map((item, i) => {

                                                            //CHECK IF DAILY BREAKDOWN IS SELECTED
                                                            let date = null;
                                                            if (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"]) {
                                                                date = true;
                                                            }

                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (item.value === "line" && !date)) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.chartTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if ((item.disabled || (item.value === "line" && !date))) {

                                                                            } else {
                                                                                this.state.tab.chart = item;
                                                                                if (!this.state.tab.settings) {
                                                                                    this.state.tab.settings = {
                                                                                        title: ""
                                                                                    };
                                                                                }
                                                                                //this.state.tab.settings.title = "table";
                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    chart: false,
                                                                                    daterange: true
                                                                                })
                                                                            }

                                                                        }}
                                                                            className={((this.state.tab.chart && this.state.tab.chart.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.chart && this.state.tab.chart.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.chart && this.state.tab.chart.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                chart: false,
                                                                daterange: true,
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === 'temp_metric') ? "opacity-50 " : "") + (this.renders.stepValid('daterange') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        daterange: !this.state.daterange
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select date range
                                                    </h3>
                                                    {
                                                        !this.state.tab.daterange &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.dateranges().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.daterange.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('daterange') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            (
                                                                (this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates")
                                                                ||
                                                                (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                                            ) &&
                                                            <div
                                                                className="mr-2 border-green-500 border-2 text-green-500 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                daterange: !this.state.daterange
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.daterange &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.daterange &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.daterange}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.dateranges().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"] && item.value === "lifetime")) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div onClick={() => {

                                                                        }}
                                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14} icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled && !(this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"] && item.value === "lifetime")) {
                                                                                this.state.tab.daterange = item;
                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    daterange: item.value === "custom_dates",
                                                                                    //piechart: item.value !== "custom_dates",
                                                                                    metric: item.value !== "custom_dates"
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.daterange && this.state.tab.daterange.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            {
                                                                                (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                                                    (<div className="text-xxs">
                                                                                        From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                                                    </div>) :
                                                                                    (<div className="text-xxs">
                                                                                        {item.subtitle}
                                                                                    </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                                                    <div className="w-full">
                                                        <SingleDatepicker
                                                            maxDate={new Date()}
                                                            from={this.state.tab.daterange.start_date}
                                                            to={this.state.tab.daterange.end_date}
                                                            onChange={(data) => {
                                                                this.state.tab.daterange.start_date = data.start_date;
                                                                this.state.tab.daterange.end_date = data.end_date;
                                                                if (this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) {
                                                                    this.state.daterange = false;
                                                                    //this.state.piechart = true;
                                                                    this.state.metric = true;
                                                                }
                                                                this.setState({
                                                                    metric: this.state.metric,
                                                                    piechart: this.state.piechart,
                                                                    daterange: this.state.daterange,
                                                                    tab: this.state.tab
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.state.tab.daterange && this.state.tab.daterange.value && (this.state.tab.daterange.value !== "custom_dates" || (this.state.tab.daterange.value == "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                daterange: false,
                                                                metric: true,
                                                                //piechart: !this.state.premium,
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.renders.stepValid('metric') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        metric: !this.state.metric
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select metric{!this.state.premium && <span>s</span>}
                                                    </h3>
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                                                        !this.state.tab.metrics &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Choose between default, custom and calculated
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value !== "table" &&
                                                        !this.state.tab.metric &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Choose between default, custom and calculated
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value !== "table" &&
                                                        this.state.tab.metric && this.state.tab.metric.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.metric.name}</span>
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value === "table" &&
                                                        this.state.tab.metrics &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{Object.keys(this.state.tab.metrics).length}</span> metrics
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('metric') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            (
                                                                (
                                                                    (
                                                                        this.state.premium && this.state.tab.metric &&
                                                                        this.state.tab.metric.value !== 'new_metric' &&
                                                                        this.state.tab.metric.value !== 'channel_metric' &&
                                                                        this.state.tab.metric.value !== 'temp_metric' &&
                                                                        this.state.tab.celltype.value !== "table" &&
                                                                        this.renders.selectedDataSources().filter((source) => {
                                                                            if (this.state.tab.metric && this.state.tab.metric.calculated) {
                                                                                return true;
                                                                            } else {
                                                                                return this.state.tab.metric.channels && this.state.tab.metric.channels[source.channel];
                                                                            }
                                                                        }).length > 0
                                                                    )
                                                                    ||
                                                                    (
                                                                        this.state.premium &&
                                                                        this.state.tab.table_metrics &&
                                                                        Object.keys(this.state.tab.table_metrics).length > 0 &&
                                                                        this.state.tab.celltype.value === "table"
                                                                    )
                                                                )
                                                                ||
                                                                (!this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0)
                                                            ) &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        {
                                                            false &&
                                                            this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") &&
                                                            <div onClick={() => {
                                                                delete this.state.tab.metric;
                                                                this.setState({
                                                                    edit_metric: false,
                                                                    tab: this.state.tab
                                                                });
                                                            }}
                                                                className="mr-2 text-red-500 border-red-500 border-2 bg-red-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"x"} />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") &&
                                                            <div
                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-right"} />
                                                            </div>
                                                            ||
                                                            <div onClick={() => {
                                                                this.setState({
                                                                    metric: !this.state.metric
                                                                })
                                                            }}
                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                {
                                                                    !this.state.metric &&
                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                        icon={"chevron-down"} />
                                                                }
                                                                {
                                                                    this.state.metric &&
                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                        icon={"chevron-up"} />
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.metric}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        false &&
                                                        <Fragment>
                                                            <div
                                                                className={"mb-2 flex flex-1 flex-row justify-center cursor-pointer"}
                                                                onClick={() => {
                                                                    this.state.tab.metric = { value: "new_metric" };
                                                                    if (!this.state.tab.settings) {
                                                                        this.state.tab.settings = {};
                                                                    }
                                                                    this.setState({
                                                                        tab: this.state.tab
                                                                    }, () => {
                                                                        this.refs.customMetric.functions.init();
                                                                    })
                                                                }}
                                                            >
                                                                <div
                                                                    className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                    <FeatherIcon className={'stroke-current'}
                                                                        size={14}
                                                                        icon={'plus'} />
                                                                </div>
                                                                {
                                                                    false &&
                                                                    <div onClick={() => {
                                                                        this.state.tab.metric = { value: "new_metric" };
                                                                        if (!this.state.tab.settings) {
                                                                            this.state.tab.settings = {};
                                                                        }
                                                                        this.setState({
                                                                            tab: this.state.tab
                                                                        }, () => {
                                                                            this.refs.customMetric.functions.init();
                                                                        })
                                                                    }}
                                                                        className={((this.state.tab.metric && this.state.tab.metric.value === 'new_metric') ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.tab.metric && this.state.tab.metric.value === 'new_metric' &&
                                                                            <div
                                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="flex flex-1 flex-col truncate">
                                                                    <div className="font-bold text-xs truncate">
                                                                        Create custom metric
                                                                    </div>
                                                                    <div className="text-xxs capitalize">
                                                                        Click here
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    {
                                                        false &&
                                                        Array.isArray(this.state.custom_metrics) &&
                                                        this.state.custom_metrics.length > 0 &&
                                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "table" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "number"))) &&
                                                        <div className={"mt-5 text-xs font-bold mb-2"}>
                                                            Metrics
                                                        </div>
                                                    }
                                                    {
                                                        Array.isArray(this.state.custom_metrics) &&
                                                        this.state.custom_metrics.length > 0 &&
                                                        ["default", "custom", "calculated"].map((category) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className="">
                                                                        <div
                                                                            className={"cursor-pointer flex flex-1 flex-row justify-center mb-2"}>
                                                                            <div onClick={() => {
                                                                                this.state.tab["open_metric_" + category] = !this.state.tab["open_metric_" + category];
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                })
                                                                            }}
                                                                                className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                {
                                                                                    !this.state.tab["open_metric_" + category] &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"plus"} />
                                                                                }
                                                                                {
                                                                                    this.state.tab["open_metric_" + category] &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"minus"} />
                                                                                }
                                                                            </div>

                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate capitalize">
                                                                                    {category}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    There are <span
                                                                                        className="font-bold">{this.renders.customMetrics(this.state.custom_metrics.filter((item) => {
                                                                                            return (!item.calculated && category === "default" && item.default) || (!item.calculated && category === "custom" && !item.default) || (item.calculated && category === "calculated")
                                                                                        }), "").filter((item) => {
                                                                                            if (category !== "calculated") {
                                                                                                if (this.state.premium && this.state.tab.celltype.value !== "table") {
                                                                                                    let channels = this.renders.selectedDataSourcesChannells();
                                                                                                    return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                        return channels[channel]
                                                                                                    }).length >= Object.keys(channels).length
                                                                                                } else {
                                                                                                    let channels = this.renders.selectedDataSourcesChannells();
                                                                                                    return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                        return channels[channel]
                                                                                                    }).length > 0
                                                                                                }
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).length}</span> metrics
                                                                                    available
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <SlideDown closed={!this.state.tab["open_metric_" + category]}>
                                                                            <div className="text-xs font-bold mb-1 pl-10">
                                                                                Search {category}
                                                                            </div>
                                                                            <div className="flex flex-1 flex-col justify-center mb-2 pl-10">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        if (!this.state.search) {
                                                                                            this.state.search = {};
                                                                                        }
                                                                                        this.state.search["search_custom" + category] = event.target.value;
                                                                                        this.setState({
                                                                                            search: this.state.search
                                                                                        });
                                                                                    }}
                                                                                    placeholder={"Search ..."}
                                                                                    value={this.state.search ? (this.state.search["search_custom" + category] ? this.state.search["search_custom" + category] : "") : ""}
                                                                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.renders.customMetrics(this.state.custom_metrics.filter((item) => {
                                                                                    return (!item.calculated && category === "default" && item.default) || (!item.calculated && category === "custom" && !item.default) || (item.calculated && category === "calculated")
                                                                                }), (this.state.search ? (this.state.search["search_custom" + category] ? this.state.search["search_custom" + category] : "") : "")).filter((item) => {
                                                                                    if (category !== "calculated") {
                                                                                        if (this.state.premium && this.state.tab.celltype.value !== "table") {
                                                                                            let channels = this.renders.selectedDataSourcesChannells();
                                                                                            return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                return channels[channel]
                                                                                            }).length >= Object.keys(channels).length
                                                                                        } else {
                                                                                            let channels = this.renders.selectedDataSourcesChannells();
                                                                                            return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                return channels[channel]
                                                                                            }).length > 0
                                                                                        }
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {

                                                                                    let channels = this.renders.selectedDataSourcesChannells();

                                                                                    return (
                                                                                        <Fragment key={i}>
                                                                                            <div className="flex flex-row pl-10">
                                                                                                <div
                                                                                                    className={((this.renders.selectedDataSources().filter((source) => {
                                                                                                        if (category === "custom" || category === "default") {
                                                                                                            return item.channels && item.channels[source.channel];
                                                                                                        } else {
                                                                                                            if (item.metrics) {
                                                                                                                for (let metric in item.metrics) {
                                                                                                                    return item.metrics[metric].channels && item.metrics[metric].channels[source.channel];
                                                                                                                }
                                                                                                            } else {
                                                                                                                return false;
                                                                                                            }
                                                                                                        }
                                                                                                    }).length > 0) ? "cursor-pointer " : "opacity-50 cursor-not-allowed") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                                                                    {
                                                                                                        false &&
                                                                                                        <div
                                                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                                            <FeatherIcon
                                                                                                                className={'stroke-current'}
                                                                                                                size={14}
                                                                                                                icon={item.icon} />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        ((this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value !== "table")) &&
                                                                                                        <div onClick={() => {
                                                                                                            if (this.renders.selectedDataSources().filter((source) => {
                                                                                                                if (category === "custom" || category === "default") {
                                                                                                                    return item.channels && item.channels[source.channel];
                                                                                                                } else {
                                                                                                                    if (item.metrics) {
                                                                                                                        for (let metric in item.metrics) {
                                                                                                                            return item.metrics[metric].channels && item.metrics[metric].channels[source.channel];
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                            }).length > 0) {
                                                                                                                if (!item.value) {
                                                                                                                    item.value = item.id + " " + item.name;
                                                                                                                }
                                                                                                                this.state.tab.metric = item;
                                                                                                                if (!this.state.tab.settings) {
                                                                                                                    this.state.tab.settings = {
                                                                                                                        title: ""
                                                                                                                    };
                                                                                                                }
                                                                                                                //this.state.tab.settings.title = item.title;
                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab,
                                                                                                                    metric: false,
                                                                                                                    settings: true,
                                                                                                                    daterange: this.state.metric ? this.state.daterange : false,
                                                                                                                    breakdown: this.state.metric ? this.state.breakdown : false,
                                                                                                                    datasource: this.state.metric ? this.state.datasource : false
                                                                                                                })
                                                                                                            }
                                                                                                        }}
                                                                                                            className={((this.state.tab.metric && this.state.tab.metric.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                            {
                                                                                                                this.state.tab.metric && this.state.tab.metric.value === item.value &&
                                                                                                                <div
                                                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "table")) &&
                                                                                                        <div onClick={() => {
                                                                                                            if (this.renders.selectedDataSources().filter((source) => {
                                                                                                                if (category === "custom" || category === "default") {
                                                                                                                    return item.channels && item.channels[source.channel];
                                                                                                                } else {
                                                                                                                    if (item.metrics) {
                                                                                                                        for (let metric in item.metrics) {
                                                                                                                            return item.metrics[metric].channels && item.metrics[metric].channels[source.channel];
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                            }).length > 0) {
                                                                                                                if (!this.state.tab.table_metrics) {
                                                                                                                    this.state.tab.table_metrics = {};
                                                                                                                }
                                                                                                                if (!this.state.tab.table_metrics_index) {
                                                                                                                    this.state.tab.table_metrics_index = [];
                                                                                                                }
                                                                                                                if (item.name in this.state.tab.table_metrics) {
                                                                                                                    delete this.state.tab.table_metrics[item.name];
                                                                                                                    this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                        return metric.name !== item.name
                                                                                                                    });
                                                                                                                } else {
                                                                                                                    item.metric_type = "custom";
                                                                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                                                                    this.state.tab.table_metrics_index.push(item);
                                                                                                                }
                                                                                                                if (!this.state.tab.settings) {
                                                                                                                    this.state.tab.settings = {
                                                                                                                        title: ""
                                                                                                                    };
                                                                                                                    //this.state.tab.settings.title = "Table";
                                                                                                                }
                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab,
                                                                                                                })
                                                                                                            }
                                                                                                        }}
                                                                                                            className={(this.state.tab.table_metrics && this.state.tab.table_metrics[item.name] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                            {
                                                                                                                this.state.tab.table_metrics && this.state.tab.table_metrics[item.name] &&
                                                                                                                <FeatherIcon
                                                                                                                    className={'stroke-current'}
                                                                                                                    size={14}
                                                                                                                    icon="check" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div
                                                                                                        className="flex flex-1 flex-col truncate">
                                                                                                        <div
                                                                                                            className="font-bold text-xs truncate">
                                                                                                            {item.alias ? item.alias : item.name}
                                                                                                        </div>
                                                                                                        <div className="text-xxs capitalize">
                                                                                                            {
                                                                                                                item.channels &&
                                                                                                                Object.keys(item.channels).map((channel, index) => {
                                                                                                                    let last = index === (Object.keys(item.channels).length - 1);
                                                                                                                    if (channels[channel]) {
                                                                                                                        return (<span className="font-bold mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                                                    } else {
                                                                                                                        return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    false &&
                                                                                                    <div onClick={() => {
                                                                                                        this.state.tab.metric = { value: "edit_metric" };
                                                                                                        this.setState({
                                                                                                            tab: this.state.tab,
                                                                                                            edit_metric: item
                                                                                                        }, () => {
                                                                                                            this.refs.customMetric.functions.init();
                                                                                                        })
                                                                                                    }}
                                                                                                        className={(this.state.edit_metric && this.state.edit_metric.name !== item.name ? "opacity-50" : "") + " text-purple-500 cursor-pointer bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center"}>
                                                                                                        <FeatherIcon className={'stroke-current'}
                                                                                                            size={14}
                                                                                                            icon={'edit'}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SlideDown>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    false &&
                                                    (this.state.premium && this.state.tab.metric &&
                                                        this.state.tab.metric.value !== 'new_metric' &&
                                                        this.state.tab.metric.value !== 'channel_metric' &&
                                                        this.state.tab.metric.value !== 'temp_metric' &&
                                                        this.state.tab.celltype.value !== "table" &&
                                                        this.renders.selectedDataSources().filter((source) => {
                                                            return this.state.tab.metric.channels && this.state.tab.metric.channels[source.channel];
                                                        }).length < 1) &&
                                                    <div className="flex flex-1 bg-red-100 rounded-md p-4 mt-4">
                                                        <div className="text-xs text-red-500 font-bold">
                                                            Select a metric which datasources support
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    ((
                                                        (this.state.premium && this.state.tab.metric && this.state.tab.metric.value !== 'new_metric' && this.state.tab.metric.value !== 'channel_metric' && this.state.tab.celltype.value !== "table")
                                                        ||
                                                        (this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0 && this.state.tab.celltype.value === "table")
                                                    )
                                                        ||
                                                        (!this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0)
                                                    ) &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                metric: false,
                                                                piechart: !this.state.premium,
                                                                settings: this.state.premium
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        !this.renders.lowerLevelThanCampaign() &&
                                        !this.state.premium &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric") ? "opacity-50 " : "") + (this.renders.stepValid('piechart') ? " " : " opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        piechart: !this.state.piechart
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Show pie charts
                                                    </h3>
                                                    {
                                                        (!this.state.tab.piechart || (this.state.tab.piechart && Object.keys(this.state.tab.piechart).length < 1)) &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.breakdowns().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.piechart && Object.keys(this.state.tab.piechart).length > 0 &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected
                                                            {
                                                                Object.keys(this.state.tab.piechart).length !== this.renders.piecharts().length &&
                                                                <span
                                                                    className="font-bold ml-1 mr-1">{Object.keys(this.state.tab.piechart).map((key) => {
                                                                        return this.state.tab.piechart[key].title
                                                                    }).join(", ")}</span>
                                                            }
                                                            {
                                                                Object.keys(this.state.tab.piechart).length === this.renders.piecharts().length &&
                                                                <span className="font-bold ml-1 mr-1">All charts</span>
                                                            }
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        this.renders.stepValid('piechart') &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.stepValid('piechart') &&
                                                        <div onClick={() => {
                                                            this.setState({
                                                                piechart: !this.state.piechart
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.piechart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.piechart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.piechart}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.piecharts().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={(item.disabled ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.piecharts().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled) {
                                                                                if (!this.state.tab.piechart) {
                                                                                    this.state.tab.piechart = {};
                                                                                }
                                                                                if (this.state.tab.piechart[item.value]) {
                                                                                    delete this.state.tab.piechart[item.value];
                                                                                } else {
                                                                                    this.state.tab.piechart[item.value] = item;
                                                                                }
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.piechart && this.state.tab.piechart[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.piechart && this.state.tab.piechart[item.value] &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    !this.state.premium &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                piechart: false,
                                                                settings: true
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('settings') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        settings: !this.state.settings
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    {
                                                        this.state.premium &&
                                                        <h3 className="mb-0 font-bold">
                                                            Settings
                                                        </h3>
                                                    }
                                                    {
                                                        !this.state.premium &&
                                                        <h3 className="mb-0 font-bold">
                                                            Table settings
                                                        </h3>
                                                    }
                                                    {
                                                        this.state.premium &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Select color of each data source and edit data
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.premium &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Set order of columns in table
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('settings') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.renders.stepValid('settings') &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                settings: !this.state.settings
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.settings &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.settings &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.settings}>
                                                <div className="w-full h-6" />
                                                {
                                                    this.state.premium &&
                                                    this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "table") &&
                                                    <div className="flex flex-1 flex-col justify-center mb-4">
                                                        <div className="text-xs font-bold mb-1">
                                                            Cell title
                                                        </div>
                                                        <input
                                                            onChange={(event) => {
                                                                if (!this.state.tab.settings) {
                                                                    this.state.tab.settings = { title: "" };
                                                                }
                                                                this.state.tab.settings.title = event.target.value;
                                                                this.setState({
                                                                    tab: this.state.tab
                                                                });
                                                            }}
                                                            value={this.state.tab.settings ? this.state.tab.settings.title : ""}
                                                            className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.state.premium &&
                                                    this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                                                    <div className="mb-4">
                                                        <div className="text-xs font-bold mb-1">
                                                            Chart colors
                                                        </div>
                                                        {
                                                            this.renders.selectedDataSources().map((item, i) => {
                                                                return (
                                                                    <Fragment key={i}>
                                                                        <div
                                                                            className="flex flex-1 flex-row justify-center mb-2">
                                                                            <div onClick={() => {
                                                                                item.setColor = !item.setColor;
                                                                                this.setState({
                                                                                    selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                                                    selected_adgroups_internal: this.state.selected_adgroups_internal,
                                                                                    selected_ads_internal: this.state.selected_ads_internal
                                                                                });
                                                                            }}
                                                                                style={{ backgroundColor: "#" + item.hex ? item.hex : "ffffff" }}
                                                                                className="cursor-pointer mr-2 h-8 w-8 rounded-full text-purple-500 flex justify-center align-middle items-center">

                                                                            </div>
                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    {item.name !== "" ? item.name : "No name"}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    Source color {item.hex}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            item.setColor &&
                                                                            <div className="mt-3">
                                                                                <CirclePicker
                                                                                    width={"100%"}
                                                                                    colors={["#3A5998", "#4384F4", "#0D65A1", "#50ABF1", "#e8710a", "#34A853", "#60C1A4", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b", "#000000"]}
                                                                                    circleSize={31}
                                                                                    onChange={(value) => {
                                                                                        item.hex = value.hex;
                                                                                        item.rgb = value.rgb;
                                                                                        item.setColor = false;
                                                                                        this.setState({
                                                                                            selected_campaigns_internal: this.state.selected_campaigns_internal,
                                                                                            selected_adgroups_internal: this.state.selected_adgroups_internal,
                                                                                            selected_ads_internal: this.state.selected_ads_internal
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    ((!this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0) || (this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0 && this.state.tab.celltype && this.state.tab.celltype.value === "table")) &&
                                                    <div className="flex flex-1 flex-col justify-center mb-4">
                                                        <div className="text-xs font-bold mb-2">
                                                            Order table headers
                                                        </div>
                                                        <div>
                                                            {
                                                                [
                                                                    {
                                                                        title: "preview",
                                                                        description: "Shows preview of ad",
                                                                        value: "remove_preview"
                                                                    },
                                                                    {
                                                                        title: "channel",
                                                                        description: "Shows channel of datasource",
                                                                        value: "remove_channel"
                                                                    },
                                                                    {
                                                                        title: "breakdown",
                                                                        description: "Show data breakdown",
                                                                        value: "remove_breakdown"
                                                                    },
                                                                    {
                                                                        title: "breakdown value",
                                                                        description: "Show data breakdown value",
                                                                        value: "remove_breakdown_value"
                                                                    },
                                                                    {
                                                                        title: "level",
                                                                        description: "Shows if datasource is campaign, adgroup or ad",
                                                                        value: "remove_level"
                                                                    },
                                                                    {
                                                                        title: "id",
                                                                        description: "Shows id of datasource",
                                                                        value: "remove_id"
                                                                    },
                                                                    {
                                                                        title: "name",
                                                                        description: "Shows name of datasource",
                                                                        value: "remove_name"
                                                                    }
                                                                ].map((item) => {
                                                                    return (
                                                                        <div
                                                                            className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                                                                            <div
                                                                                className="text-purple-500 text-xs font-bold bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="lock" />
                                                                            </div>
                                                                            <div className="text-xs flex flex-1 ml-2">
                                                                                <div
                                                                                    className="flex flex-1 flex-col truncate">
                                                                                    <div
                                                                                        className="font-bold text-xs truncate capitalize">
                                                                                        {item.title}
                                                                                    </div>
                                                                                    <div className="text-xxs">
                                                                                        {item.description}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.state.tab[item.value] = !this.state.tab[item.value];
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                });
                                                                            }}
                                                                                className={((!this.state.tab[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    !this.state.tab[item.value] &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={14}
                                                                                        icon="check" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div>
                                                            <SortableList
                                                                items={Array.isArray(this.state.tab.table_metrics_index) ? this.state.tab.table_metrics_index : []}
                                                                onSortEnd={onSortEnd}
                                                                axis="y"
                                                                distance={1}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    this.state.premium &&
                                                    <div className={((this.state.cell && this.state.cell.group) ? "opacity-25 cursor-not-allowed " : "") + ((this.state.tab.celltype && (this.state.tab.celltype.value === "text" || this.state.tab.celltype.value === "image")) ? "" : "mt-4 ") + " flex flex-1 flex-col"}>
                                                        <div className="text-xs font-bold mb-1">
                                                            Available pages
                                                        </div>
                                                        {
                                                            Array.isArray(this.state.tab.groups) &&
                                                            this.state.tab.groups.map((group, index) => {
                                                                return (
                                                                    <div className="flex flex-row mb-2">
                                                                        <div onClick={() => {
                                                                            if (!this.state.cell || (this.state.cell && !this.state.cell.group)) {
                                                                                this.state.tab.group = group.id;
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                });
                                                                            }
                                                                        }} className={((this.state.cell && this.state.cell.group) ? "cursor-not-allowed " : "cursor-pointer ") + ((this.state.tab.group == group.id) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.group == group.id &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="flex flex-1 flex-col truncate">
                                                                            <div
                                                                                className="font-bold text-xs truncate">
                                                                                {group.name !== "" ? group.name : ("Page " + (index + 1))}
                                                                            </div>
                                                                            <div className="text-xxs capitalize">
                                                                                Contains {Array.isArray(this.state.tab.data_grid) ? this.state.tab.data_grid.filter((item) => {
                                                                                    return item.group === group.id
                                                                                }).length : 0} cells
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                settings: false,
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    <div className="px-4 sm:px-6 mt-6">
                                        <div onClick={() => {
                                            if (this.renders.valid()) {

                                                if (this.state.tab.daterange && this.state.tab.daterange.value === "10_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "20_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "30_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates") {

                                                } else {
                                                    this.state.tab.daterange = {};
                                                    this.state.tab.daterange.value = "lifetime";
                                                }

                                                if (!this.state.tab.breakdown) {
                                                    this.state.tab.breakdown = {};
                                                    this.state.tab.breakdown.value = "overview";
                                                }

                                                if (!this.state.tab.channel_breakdowns) {
                                                    this.state.tab.channel_breakdowns = {};
                                                    //this.state.tab.breakdown.value = "overview";
                                                }

                                                if (!this.state.tab.analytics_breakdown) {
                                                    this.state.tab.analytics_breakdown = {};
                                                    this.state.tab.analytics_breakdown.value = null;
                                                }

                                                if (this.state.premium) {

                                                    if (!this.state.cell) {
                                                        this.state.cell = {};
                                                    }

                                                    this.state.cell.remove_name = this.state.tab.remove_name;
                                                    this.state.cell.remove_id = this.state.tab.remove_id;
                                                    this.state.cell.remove_level = this.state.tab.remove_level;
                                                    this.state.cell.remove_breakdown_value = this.state.tab.remove_breakdown_value;
                                                    this.state.cell.remove_breakdown = this.state.tab.remove_breakdown;
                                                    this.state.cell.remove_channel = this.state.tab.remove_channel;
                                                    this.state.cell.remove_preview = this.state.tab.remove_preview;

                                                    this.state.cell.group = this.state.tab.group ? this.state.tab.group : 1;

                                                    this.state.cell.breakdown = this.state.tab.breakdown;
                                                    this.state.cell.channel_breakdowns = this.state.tab.channel_breakdowns;
                                                    this.state.cell.daterange = this.state.tab.daterange;
                                                    this.state.cell.celltype = this.state.tab.celltype;
                                                    this.state.cell.metric = this.state.tab.metric;
                                                    this.state.cell.table_metrics = this.state.tab.table_metrics;
                                                    this.state.cell.table_metrics_index = this.state.tab.table_metrics_index;
                                                    this.state.cell.chart = this.state.tab.chart;
                                                    this.state.cell.settings = this.state.tab.settings;
                                                    this.state.cell.data_sources = {
                                                        campaigns: this.state.selected_campaigns_internal,
                                                        adgroups: this.state.selected_adgroups_internal,
                                                        ads: this.state.selected_ads_internal
                                                    };

                                                    this.state.cell.metrics = {
                                                        custom: {}
                                                    };

                                                    for (let metric in this.state.tab.table_metrics) {
                                                        if (this.state.tab.table_metrics[metric].metric_type === "custom") {
                                                            if (!this.state.cell.metrics.custom) {
                                                                this.state.cell.metrics.custom = {};
                                                            }
                                                            this.state.cell.metrics.custom[metric] = this.state.tab.table_metrics[metric];
                                                        } else if (this.state.tab.table_metrics[metric].metric_type === "channel") {
                                                            if (this.state.tab.table_metrics[metric].channels) {
                                                                for (let channel in this.state.tab.table_metrics[metric].channels) {
                                                                    if (!this.state.cell.metrics[channel]) {
                                                                        this.state.cell.metrics[channel] = {};
                                                                    }
                                                                    this.state.cell.metrics[channel][metric] = this.state.tab.table_metrics[metric].channels[channel];
                                                                }
                                                            }
                                                        } else if (this.state.tab.table_metrics[metric].metric_type === "default") {
                                                            if (!this.state.cell.metrics.custom) {
                                                                this.state.cell.metrics.custom = {};
                                                            }
                                                            this.state.cell.metrics.custom[metric] = this.state.tab.table_metrics[metric];
                                                        }
                                                    }

                                                    //UPDATE CAMPAIGNS
                                                    this.props.updateData(
                                                        this.state.selected_campaigns_internal,
                                                        this.state.selected_adgroups_internal,
                                                        this.state.selected_ads_internal,
                                                        this.state.tab,
                                                        this.state.cell
                                                    );

                                                } else {

                                                    //CHECK ORIGINAL TAB
                                                    if (Object.keys(this.state.init_tab.data_sources.campaigns).length < 1 &&
                                                        Object.keys(this.state.init_tab.data_sources.adgroups).length < 1 &&
                                                        Object.keys(this.state.init_tab.data_sources.ads).length < 1) {

                                                    }

                                                    this.state.tab.metrics = {
                                                        custom: {}
                                                    };

                                                    for (let metric in this.state.tab.table_metrics) {
                                                        if (this.state.tab.table_metrics[metric].metric_type === "custom") {
                                                            if (!this.state.tab.metrics.custom) {
                                                                this.state.tab.metrics.custom = {};
                                                            }
                                                            this.state.tab.metrics.custom[metric] = this.state.tab.table_metrics[metric];
                                                        } else if (this.state.tab.table_metrics[metric].metric_type === "channel") {
                                                            if (this.state.tab.table_metrics[metric].channels) {
                                                                for (let channel in this.state.tab.table_metrics[metric].channels) {
                                                                    if (!this.state.tab.metrics[channel]) {
                                                                        this.state.tab.metrics[channel] = {};
                                                                    }
                                                                    this.state.tab.metrics[channel][metric] = this.state.tab.table_metrics[metric].channels[channel];
                                                                }
                                                            }
                                                        } else if (this.state.tab.table_metrics[metric].metric_type === "default") {
                                                            if (!this.state.tab.metrics.custom) {
                                                                this.state.tab.metrics.custom = {};
                                                            }
                                                            this.state.tab.metrics.custom[metric] = this.state.tab.table_metrics[metric];
                                                        }
                                                    }

                                                    if (this.renders.lowerLevelThanCampaign()) {
                                                        this.state.tab.piechart = {};
                                                    }

                                                    //SET DEFAULT METRICS
                                                    this.state.default_metrics.map((item) => {
                                                        if (this.state.tab.piechart && this.state.tab.piechart.click && item.name.toLowerCase() === "clicks") {
                                                            this.state.tab.metrics["custom"][item.name] = item;
                                                        }
                                                        if (this.state.tab.piechart && this.state.tab.piechart.impressions && item.name.toLowerCase() === "impressions") {
                                                            this.state.tab.metrics["custom"][item.name] = item;
                                                        }
                                                        if (this.state.tab.piechart && this.state.tab.piechart.ctr && item.name.toLowerCase() === "avg. ctr") {
                                                            this.state.tab.metrics["custom"][item.name] = item;
                                                        }
                                                        if (this.state.tab.piechart && this.state.tab.piechart.cpc && item.name.toLowerCase() === "avg. cpc") {
                                                            this.state.tab.metrics["custom"][item.name] = item;
                                                        }
                                                        if (this.state.tab.piechart && this.state.tab.piechart.spend && item.name.toLowerCase() === "spend") {
                                                            this.state.tab.metrics["custom"][item.name] = item;
                                                        }
                                                    });

                                                    //UPDATE CAMPAIGNS
                                                    this.props.updateData(
                                                        this.state.selected_campaigns_internal,
                                                        this.state.selected_adgroups_internal,
                                                        this.state.selected_ads_internal,
                                                        this.state.tab
                                                    );

                                                }

                                                this.props.toggle();

                                            } else {
                                                this.setState({
                                                    error: true
                                                })
                                            }
                                        }}
                                            className={(this.renders.valid() ? "bg-green-500 text-white cursor-pointer" : "bg-gray-100 text-gray-500 cursor-not-allowed") + " py-3 px-4 transition-all duration-200 rounded-full font-bold w-full text-sm text-center"}>
                                            {
                                                !this.state.premium &&
                                                <span>
                                                    Update data
                                                </span>
                                            }
                                            {
                                                this.state.premium && !this.state.update_cell &&
                                                <span>
                                                    Add cell
                                                </span>
                                            }
                                            {
                                                this.state.premium && this.state.update_cell &&
                                                <span>
                                                    Update cell
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default FreemiumReport;
