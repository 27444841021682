import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {Bar, Doughnut} from "react-chartjs-2";
import Dropdown from "../dropdown";
var moment = require('moment');

class ReportListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            items: {},
            properties: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item?nextProps.item:{},
            items: nextProps.items?nextProps.items:{}
        });
    }

    componentDidMount() {
        this.setState({
            item: this.props.item?this.props.item:{},
            items: this.props.items?this.props.items:{},
            properties: this.props.properties?this.props.properties:[]
        }, () => {
            //console.log(this.props.properties);
        })
    }

    init = {};

    functions = {};

    renders = {
        title: (item) => {
            if(item === 'cost_per_conversion'){
                return "Cost Per Con."
            }else if(item === 'top_impression_percentage'){
                return "Top imp %"
            }else if(!this.state.item.channel && item ==='cpc'){
                return "Avg. cpc"
            }else if(!this.state.item.channel && item === 'ctr'){
                return "Avg. ctr"
            }else if(!this.state.item.channel && item === 'cpp'){
                return "Avg. cpp"
            }else if(item === 'website_purchase_roas'){
                return "ROAS"
            }else{
                return item;
            }
        },
        value: (item) => {
            if(!item && item !== 0){
                return '-'
            }else{
                return item
            }
        },
        summary: (item) => {
            let total = 0;
            if(item === 'click' || item === 'impressions' || item === 'conversions'){
                for(let channel in this.state.items){
                    total = total + this.state.items[channel][item];
                }
                return total;
            }else if(item === 'ctr' || item === 'cpc' || item === 'cost_per_conversion'){
                for(let channel in this.state.items){
                    total = total + this.state.items[channel][item];
                }
                total = total / Object.keys(this.state.items).length;
                total = parseFloat(total).toFixed(2);
                return total;
            }else if(item === 'cpp'){
                for(let channel in this.state.items){
                    if(channel === 'instagram' || channel === 'facebook'){
                        total = total + this.state.items[channel][item];
                    }
                }
                total = total / Object.keys(this.state.items).filter((channel) => {
                    return channel === 'instagram' || channel === 'facebook'
                }).length;
                total = parseFloat(total).toFixed(2);
                return total;
            }else if(item === 'top_impression_percentage' || item === 'active_view_measurable_impressions' || item === 'spend' ){
                for(let channel in this.state.items){
                    if(channel === 'google'){
                        total = total + this.state.items[channel][item];
                    }
                }
                total = parseFloat(total).toFixed(2);
                return total;
            }else{
                return '-';
            }
        },
        type: (item) => {
            let currency = null;
            for(let key in this.state.items){
                currency = this.state.items[key].currency;
            }
            if(item === 'cost_per_conversion' || item === 'spend' || item === 'cpc' || item === 'cpp'){
                if(this.state.item.currency){
                    return this.state.item.currency
                }else{
                    return currency
                }
            }else if(item === 'ctr' || item === 'top_impression_percentage'){
                return "%";
            }else{
                return ""
            }
        }
    };

    render(){
        return (
            <div className="ReportListItem-ContainerListItem">
                {
                    this.state.item.channel !== 'campaign' &&
                    <div className="ReportListItem-ContainerListItemChannel">
                        {
                            this.state.item.channel === 'facebook' &&
                            <div className="ReportListItem-ContainerListItemChannelFacebook">
                                <img style={{width: '12px'}} src={require('../../assets/images/facebook_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.item.channel === 'instagram' &&
                            <div className="ReportListItem-ContainerListItemChannelInstagram">
                                <img style={{width: '25px'}} src={require('../../assets/images/instagram_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.item.channel === 'google' &&
                            <div className="ReportListItem-ContainerListItemChannelGoogle">
                                <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        }
                        {
                            !this.state.item.channel &&
                            <div className="ReportListItem-ContainerListItemChannelNone">
                                <FeatherIcon color={"#333333"} size={22} icon="layers"/>
                            </div>
                        }
                    </div>
                }
                <div className="ReportListItem-ContainerListItemValues">
                    <div className="ReportListItem-ContainerListItemValuesInner">
                        {
                            this.state.properties.map((item) => {
                                return(
                                    <div className="ReportListItem-ContainerListItemValuesInnerItem">
                                        <div className="ReportListItem-ContainerListItemValuesInnerItemTitle">
                                            {this.renders.title(item)}
                                        </div>
                                        {
                                            this.state.item.channel &&
                                            <div className="ReportListItem-ContainerListItemValuesInnerItemValue">
                                                {this.renders.value(this.state.item[item])}
                                                {
                                                    this.renders.value(this.state.item[item]) !== '-' &&
                                                    <span>{" "}{this.renders.type(item)}</span>
                                                }
                                            </div>
                                        }
                                        {
                                            !this.state.item.channel &&
                                            <div className="ReportListItem-ContainerListItemValuesInnerItemValue">
                                                {this.renders.summary(item)}
                                                <span>{" "}{this.renders.type(item)}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportListItem;
