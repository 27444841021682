import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";
import ReportsListRow from "../reportslistrow";

class ReportListRows extends Component {

    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            headers: [],
            items: [],
            sort: "",
            order: ""
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            properties: this.props.properties,
            headers: this.props.headers,
            items: this.props.items,
            sort: this.props.sort,
            order: this.props.order
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            properties: nextProps.properties,
            headers: nextProps.headers,
            items: nextProps.items,
            sort: nextProps.sort,
            order: nextProps.order
        })
    }

    functions = {};

    renders = {
        title: (item) => {
            if (item.toLowerCase() === 'cost_per_conversion') {
                return "Cost Per Con."
            } else if (item.toLowerCase() === 'top_impression_percentage') {
                return "Top imp %"
            } else if (item.toLowerCase() === 'cpc') {
                return "Avg. cpc"
            } else if (item.toLowerCase() === 'ctr') {
                return "Avg. ctr"
            } else if (item.toLowerCase() === 'cpp') {
                return "Avg. cpp"
            } else if (item.toLowerCase() === 'website_purchase_roas') {
                return "ROAS"
            } else {
                return item;
            }
        },
        value: (item) => {
            if (!item && item !== 0) {
                return '-'
            } else {
                return item
            }
        },
        summary: (items, header) => {

            let total = 0;
            let date = items.date;

            items = items.channels;

            if (header === 'click' || header === 'impressions' || header === 'conversions') {
                for (let channel in items) {
                    total = total + items[channel][header];
                }
                return total;
            } else if (header === 'ctr' || header === 'cpc' || header === 'cost_per_conversion') {
                for (let channel in items) {
                    total = total + items[channel][header];
                }
                total = total / Object.keys(items).length;
                total = parseFloat(total).toFixed(2);
                return total;
            } else if (header === 'cpp') {
                for (let channel in items) {
                    if (channel === 'instagram' || channel === 'facebook') {
                        total = total + items[channel][header];
                    }
                }
                total = total / Object.keys(items).filter((channel) => {
                    return channel === 'instagram' || channel === 'facebook'
                }).length;
                total = parseFloat(total).toFixed(2);
                return total;
            } else if (header === 'spend') {
                for (let channel in items) {
                    total = total + items[channel][header];
                }
                total = parseFloat(total).toFixed(2);
                return total;
            } else if (header === 'top_impression_percentage' || header === 'active_view_measurable_impressions') {
                for (let channel in items) {
                    if (channel === 'google') {
                        total = total + items[channel][header];
                    }
                }
                total = parseFloat(total).toFixed(2);
                return total;
            } else if (header === 'date') {
                return moment(date).format('DD MMM');
            } else {
                return '-';
            }
        },
        type: (item, header) => {
            try{
                let currency = null;
                for (let key in item) {
                    currency = item[key].currency;
                }
                if (header === 'cost_per_conversion' || header === 'spend' || header === 'cpc' || header === 'cpp') {
                    return currency
                } else if (header === 'ctr' || header === 'top_impression_percentage') {
                    return "%";
                } else {
                    return ""
                }
            }catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <div className="flex flex-col my-5">
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                <tr>
                                    <th className={"whitespace-no-wrap transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"}>
                                        <div className="flex flex-row items-center">
                                            Actions
                                        </div>
                                    </th>
                                    {this.state.properties.map((header) => {
                                        return (
                                            <th onClick={() => {
                                                try {
                                                    this.props.updateSort(header, header === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                                                } catch (e) {
                                                }
                                            }}
                                                className={"whitespace-no-wrap cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === header ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                <div className="flex flex-row items-center">
                                                    {this.renders.title(header)}
                                                    {
                                                        header.toLowerCase() !== 'channels' &&
                                                        <div className="sorting-arrows ml-2"></div>
                                                    }
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.items.sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        if(this.state.sort === 'date'){
                                            try{
                                                nameA =  a.date;
                                                nameB =  b.date;
                                            }catch (e) {}
                                        }else{
                                            try {
                                                nameA = this.renders.summary(a, this.state.sort).toLowerCase();
                                            } catch (e) {
                                                nameA = this.renders.summary(a, this.state.sort);
                                            }
                                            try {
                                                nameB = this.renders.summary(b, this.state.sort).toLowerCase();
                                            } catch (e) {
                                                nameB = this.renders.summary(b, this.state.sort);
                                            }
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        <button onClick={() => {
                                                            item.open = !item.open;
                                                            this.setState({
                                                                items: this.state.items
                                                            })
                                                        }}
                                                                className="bg-black-200 bg-opacity-25 text-black-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-black hover:bg-opacity-25 hover:text-white">
                                                            <span>Pr. channel</span>
                                                            <FeatherIcon className="stroke-current ml-2" size={15} icon="chevron-down"/>
                                                        </button>
                                                    </div>
                                                </td>
                                                {
                                                    this.state.properties.map((header) => {
                                                        return (
                                                            <td className="px-6 py-4 whitespace-no-wrap">
                                                                <div
                                                                    className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === header ? 'font-bold' : '')}>
                                                                    {this.renders.summary(item, header)}
                                                                    {this.renders.summary(item, header) !== '-' &&
                                                                    <span>{" "}{this.renders.type(item, header)}</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            {item.open &&
                                                <Fragment>
                                                    { Object.keys(item.channels).map((key, k) => {
                                                        return (
                                                            <tr className="text-gray-900">
                                                                <td className="px-3 py-2 whitespace-no-wrap">
                                                                    <div className={cx("capitalize block text-xs text-center font-bold rounded-lg",
                                                                        {
                                                                            ["px-2 py-1 bg-facebook-100 text-facebook-500"]: key === 'facebook',
                                                                            ["px-2 py-1 bg-instagram-100 text-instagram-500"]: key === 'instagram',
                                                                            ["px-2 py-1 bg-google-100 text-google-500"]: key === 'google',
                                                                        })
                                                                    }>
                                                                        {key}
                                                                    </div>
                                                                </td>
                                                                { this.state.properties.map((prop) => {
                                                                    return(
                                                                        <td className="px-6 py-4 whitespace-no-wrap">
                                                                            {
                                                                                prop !== 'date' &&
                                                                                <div className="text-sm leading-5 text-gray-900 truncate">
                                                                                    {this.renders.value(item.channels[key][prop])}
                                                                                    { this.renders.value(item.channels[key][prop]) !== '-' &&
                                                                                    <span>{" "}{this.renders.type(item.channels[key], prop)}</span>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    )
                                                                })}
                                                                <td></td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportListRows;