import { apiRegister } from '../../../services/apiRegister';
import { tokenRegister } from '../../../services/tokenRegister';

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', data);
        let url = calls.url.api + "/client";
        return apiRegister.call(options, url);
    }

    static getReports() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/report";
        return apiRegister.call(options, url);
    }

    static deleteReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/v2/report/" + data.id;
        return apiRegister.call(options, url);
    }

}

export {calls}
