import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import {SlideDown} from "react-slidedown";

class AdvertisingCreativesSearchAdsItemSitelinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            content: [],
            languages: [],
            edit: false,
            inner_edit: 'basic',
            selected_language: null,
            item: {},
            name: "",
            website: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            site: nextProps.site,
            languages: nextProps.languages,
            content: nextProps.content,
            item: nextProps.item,
            selected_language: nextProps.selectedLanguage
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives,
            site: this.props.site,
            languages: this.props.languages,
            content: this.props.content,
            item: this.props.item,
            selected_language: this.props.selectedLanguage,
        }, () => {
            this.setState({
                website: this.props.site.url
            })
        })
    }

    init = {};

    functions = {
        add: () => {
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let site_link = {
                name: this.state.name,
                url: this.state.website,
                id: id
            };
            for(let i = 0; i < this.state.content.length; i++){
                this.state.content[i].languages[this.state.selected_language].site_links.unshift(site_link);
            }
            this.setState({
                item: this.state.item,
                website: this.state.site.url,
                name: ""
            }, () => {
                this.props.onChange(this.state.content);
            });
        },
        remove: (item) => {
            try{
                for(let i = 0; i < this.state.content.length; i++){
                    this.state.content[i].languages[this.state.selected_language].site_links =  this.state.content[i].languages[this.state.selected_language].site_links.filter((inner_item) => {
                        return inner_item.id !== item.id
                    });
                }
            }catch (e) {}
            this.setState({
                item: this.state.item
            }, () => {
                this.props.onChange(this.state.content);
            });
        }
    };

    validators = {};

    renders = {
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        },
        property: (type) => {
            try{
                return this.state.item.languages[this.state.selected_language][type];
            }catch (e) {
                if(type !== 'site_links'){
                    return ""
                }else{
                    return []
                }
            }
        }
    };

    render() {
        return (
            <div style={{paddingTop: '5px'}} className="Section">
                <div className="Section-Inner">
                    <div className="Section-InnerTitle">
                        <div className={cx("Upload-ImageContainerTitleText")}>
                            Add Site Link, same used in all templates (optional)
                        </div>
                    </div>
                    {
                        this.renders.property('site_links').length < 4  &&
                        <div className="Site-link-container">
                            <div style={{width: '514px'}} className="Site-link-container-name">
                                <Input
                                    noTitle={true}
                                    maxLength={25}
                                    white={true}
                                    value={this.state.name}
                                    placeholder={"Name"}
                                    sign={true}
                                    signTitle={this.renders.characters(this.state.name) +' / 25'}
                                    size={'full'}
                                    type={"text"}
                                    onChange={(value) => {
                                        this.setState({
                                            name: value.target.value
                                        });
                                    }}
                                    showKeyDown={true}
                                    onKeyDown={(value) => {
                                        if (value === 13) {
                                            if(this.state.name !== "" && this.state.website !== ""){
                                                this.functions.add();
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div style={{marginRight: '10px'}} className="Site-link-container-url">
                                <Input
                                    noTitle={true}
                                    white={true}
                                    value={this.state.website}
                                    placeholder={"Url"}
                                    sign={true}
                                    signTitle={"Url"}
                                    size={'full'}
                                    type={"text"}
                                    onChange={(value) => {
                                        this.setState({
                                            website: value.target.value
                                        });
                                    }}
                                    showKeyDown={true}
                                    onKeyDown={(value) => {
                                        if (value === 13) {
                                            if(this.state.name !== "" && this.state.website !== ""){
                                                this.functions.add();
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="Site-link-container-add">
                                <div onClick={() => {
                                    if(this.state.name !== "" && this.state.website !== ""){
                                        this.functions.add();
                                    }
                                }} className="Button ButtonNeutral ButtonFull">
                                    <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.renders.property('site_links').map((item, index) => {
                            return (
                                <div className="Site-link-container">
                                    <div style={{width: '514px'}} className="Site-link-container-name">
                                        <Input
                                            noTitle={true}
                                            maxLength={25}
                                            white={true}
                                            value={item.name}
                                            placeholder={"Name"}
                                            disabled={true}
                                            size={'full'}
                                            type={"text"}
                                        />
                                    </div>
                                    <div style={{marginRight: '10px'}} className="Site-link-container-url">
                                        <Input
                                            noTitle={true}
                                            white={true}
                                            value={item.url}
                                            placeholder={"Url"}
                                            disabled={true}
                                            size={'full'}
                                            type={"text"}
                                        />
                                    </div>
                                    <div className="Site-link-container-add">
                                        <div onClick={() => {
                                            this.functions.remove(item)
                                        }} className="Button ButtonRed ButtonFull">
                                            <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesSearchAdsItemSitelinks;
