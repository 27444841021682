import React, {Component} from 'react';
import cx from "classnames";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";

import moment from 'moment'

class CampaignDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            loading: true,
            view: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            loading: nextProps.loading,
            view: nextProps.view
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            loading: this.props.loading,
            view: this.props.view
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="mb-6 px-6 py-4 rounded bg-gray-200">
                { !this.state.loading &&
                    <div className="campaign--name">
                        <h3 className="font-bold flex flex-row items-center">
                            <span>
                                {this.state.campaign.name} 
                                { this.state.campaign.name.indexOf(this.state.campaign.client) == -1 &&
                                    <span className="font-normal italic"> ({this.state.campaign.client})</span>
                                }
                                {this.state.campaign.duration && this.state.campaign.duration.start_date &&
                                    <span className="font-normal"> {moment(this.state.campaign.duration.start_date).format("DD MMM YYYY")} - {moment(this.state.campaign.duration.end_date).format("DD MMM YYYY")} </span>
                                }
                                {this.state.campaign.channels &&
                                    <span className="font-normal">
                                    for {this.state.campaign.channels.facebook ? 'Facebook' : ''}{this.state.campaign.channels.facebook && this.state.campaign.channels.instagram ? ', ' : ''}{this.state.campaign.channels.instagram ? 'Instagram' : ''}{(this.state.campaign.channels.facebook && this.state.campaign.channels.google) || (this.state.campaign.channels.instagram && this.state.campaign.channels.google) ? ' & ' : ''}{this.state.campaign.channels.google ? 'Google' : ''}
                                </span>
                                }
                            </span>
                            <span className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize ml-2",
                                {
                                    ["bg-green-500 text-white"]: this.state.campaign.status === "active",
                                    ["bg-orange-500 text-white"]: this.state.campaign.status === "paused",
                                    ["bg-yellow-600 text-white"]: this.state.campaign.status === "pending",
                                    ["bg-purple-500 text-white"]: this.state.campaign.status === "upcoming",
                                    ["bg-red-500 text-white"]: this.state.campaign.status === "error",
                                    ["bg-blue-500 text-white"]: this.state.campaign.status === "ended",
                                })
                            }>
                                {this.state.campaign.status}
                            </span>
                        </h3>
                    </div>
                }
            </div>
        )
    }
}

export default CampaignDescription;
