import React, { Component } from 'react';
import cx from "classnames";


class DashboardCards extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    render() {
        return (
            <section className="grid grid-cols-4 gap-4 mb-4">
                {this.props.cards.map((item, i) => {
                    return (
                        <div className="col-span-4 md:col-span-1 border-1.5 bg-white rounded-md p-4 md:p-6" key={i}>
                            <div className="flex items-center flex-1">
                                <div className="truncate text-left flex-col flex-1 flex">
                                    <div className="text-4xl font-bold leading-none truncate">{item.stat >= 0 ? item.stat : '-'}</div>
                                    <div className="name text-sm truncate">{item.title}</div>
                                </div>
                                <div className="">
                                    <img src={require('../../assets/images/' + item.icon)} className="w-16 h-16" />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </section>
        )
    }
}

export default DashboardCards;