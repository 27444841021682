import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCountries(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?all=true&level=4";
        return apiRegister.call(options, url);
    }

    static getRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?paths=true&all=true&country="+encodeURI(data.country);
        if(data.countries){
            url = calls.url.api + "/region/internal?paths=true&all=true&countries="+encodeURI(data.countries);
        }
        return apiRegister.call(options, url);
    }

    static getRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal/"+data.region;
        return apiRegister.call(options, url);
    }

    /*
    static getRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/"+data.client+'/'+data.channels+'/region?region='+data.region+'&country='+data.country;
        return apiRegister.call(options, url);
    }
    */
}

export {calls}