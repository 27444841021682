import React, { Component, Fragment } from 'react';
import DropdownTailwind from './dropdownTailwind';
import PreviewGooglePerformanceMax from '../previews/google/performanceMax';
import PreviewGoogleSearch from '../previews/google/search';
import PreviewGoogleShopping from '../previews/google/shopping';
import PreviewGoogleDisplaySkyscraper from '../previews/google/displayBannerSkyscraper';
import PreviewGoogleDisplayBillboard from '../previews/google/displayBannerBillboard';
import PreviewGoogleDisplaySquare from '../previews/google/displayBannerSquare'
import { ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/outline';

class PreviewSectionGoogle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            ad: {},
            channel: {},
            for_report: false,
            preview_placement: "",
            is_post: false,
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            ads: this.props.ads,
            channel: this.props.channel,
            for_report: this.props.for_report,
            preview_placement: this.props.preview_placement,
            is_post: this.props.is_post,
        });
        console.log(this.state.ad, "GALALALALALA")
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            ads: nextProps.ads,
            channel: nextProps.channel,
            for_report: nextProps.for_report,
            preview_placement: nextProps.preview_placement,
            is_post: nextProps.is_post,
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value === "google_search_desktop" &&
                    (
                        (Array.isArray(ad.headlines) &&
                            ad.headlines.filter((item, index) => {
                                return typeof item.text == "string" && item.text.indexOf("{keyword}") !== -1
                            }).length > 0) ||
                        (Array.isArray(ad.bodies) &&
                            ad.bodies.filter((item, index) => {
                                return typeof item.text == "string" && item.text.indexOf("{keyword}") !== -1
                            }).length > 0)
                    ) &&
                    <div className="mb-4 flex flex-row">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Keyword insert"}
                                    selected={ad.keyword_insert ? ad.keyword_insert : { id: 0, name: "Select keyword" }}
                                    options={this.state.ads.filter((item) => {
                                        return item.type === "keywords"
                                    }).map((item) => {
                                        return item.keywords.map((item) => {
                                            return { id: item.id, name: item.keyword }
                                        })
                                    }).flat(1)}
                                    onChange={(value) => {
                                        ad.keyword_insert = value;
                                        this.setState({
                                            ads: this.state.ads
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value === "google_search_desktop" &&
                    this.state.ads.filter((item) => { return item.type === "extension" && item.picture }).length > 0 &&
                    <div className="mb-4 flex flex-row">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Image extension"}
                                    selected={ad.image_insert ? ad.image_insert : { id: 0, name: "Select image" }}
                                    options={this.state.ads.filter((item) => {
                                        return item.type === "extension" && item.picture
                                    }).map((item) => {
                                        return { url: item.picture, name: item.name }
                                    }).flat(1)}
                                    onChange={(value) => {
                                        ad.image_insert = value;
                                        this.setState({
                                            ads: this.state.ads
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value === "google_search_desktop" &&
                    Array.isArray(ad.combinations) &&
                    ad.combinations.filter((item, index) => {
                        return (!("combi_index" in ad) && index === 0) || (ad.combi_index === index)
                    }).map((item) => {
                        return (
                            <div className="border rounded-md">
                                <PreviewGoogleSearch
                                    noBorder={true}
                                    keywordInsert={ad.keyword_insert}
                                    image={ad.image_insert}
                                    headlineFirst={item.headlineFirst.text}
                                    headlineSecond={item.headlineSecond.text}
                                    headlineThird={item.headlineThird.text}
                                    bodyOne={item.bodyOne.text}
                                    bodySecond={item.bodySecond.text}
                                    link={ad.link}
                                    path1={ad.path1}
                                    path2={ad.path2}
                                />
                            </div>
                        )
                    })
                }
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value === "google_shopping_desktop" &&
                    Array.isArray(ad.preview_products) &&
                    <PreviewGoogleShopping
                        products={ad.preview_products}
                        noBorder={true}
                    />
                }
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value.indexOf("performance_max") !== -1 &&
                    <div className="border rounded-md">
                        <PreviewGooglePerformanceMax
                            channel={{ value: ad.preview.value.replace("performance_max_", "") }}
                            videos={ad.videos}
                            images={ad.images}
                            headlines={ad.headlines}
                            descriptions={ad.bodies}
                            long_headlines={ad.long_headlines}
                            business={ad.business}
                            website={ad.link}
                            cta={ad.cta}
                        />
                    </div>
                }
                {
                    !this.state.for_report &&
                    ad.preview && ad.preview.value.indexOf("display") !== -1 &&
                    <div className="border rounded-md">
                        <PreviewGooglePerformanceMax
                            channel={{ value: ad.preview.value.replace("display_", "") }}
                            videos={ad.videos}
                            images={ad.images}
                            headlines={ad.headlines}
                            descriptions={ad.bodies}
                            long_headlines={ad.long_headlines}
                            business={ad.business}
                            website={ad.link}
                            cta={ad.cta}
                        />
                    </div>
                }
                {
                    !this.state.for_report &&
                    Array.isArray(ad.combinations) &&
                    ad.preview.value === "google_search_desktop" &&
                    <div className="flex">
                        <div className="flex flex-row pt-5.5">
                            <div onClick={() => {
                                if (!("combi_index" in ad)) {
                                    ad.combi_index = 1;
                                } else {
                                    if (ad.combi_index > 0) {
                                        ad.combi_index = ad.combi_index - 1;
                                    }
                                }
                                this.setState({
                                    ads: this.state.ads
                                })
                            }}
                                className={(!("combi_index" in ad) || ad.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                <ArrowSmLeftIcon
                                    className="w-5 h-5" />
                            </div>
                            <div onClick={() => {
                                if (!("combi_index" in ad)) {
                                    ad.combi_index = 1;
                                } else {
                                    if (ad.combi_index < ad.combinations.length - 1) {
                                        ad.combi_index = ad.combi_index + 1;
                                    }
                                }
                                this.setState({
                                    ads: this.state.ads
                                })
                            }}
                                className={(ad.combi_index === (ad.combinations.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 rounded-full flex items-center justify-center"}>
                                <ArrowSmRightIcon
                                    className="w-5 h-5" />
                            </div>
                        </div>
                    </div>
                }

                {/* FOR REPORT */}
                {
                    this.state.for_report &&
                    ad.type === "search" &&
                    <PreviewGoogleSearch
                        //headlineFirst={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines[0] : false}
                        //headlineSecond={Array.isArray(ad.headlines) && ad.headlines.length > 1 ? ad.headlines[1] : false}
                        //headlineThird={Array.isArray(ad.headlines) && ad.headlines.length > 2 ? ad.headlines[2] : false}
                        //bodyOne={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions[0] : false}
                        //bodySecond={Array.isArray(ad.descriptions) && ad.descriptions.length > 1 ? ad.descriptions[1] : false}

                        headlineFirst={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? (typeof ad.headlines[0] == "string" ? ad.headlines[0] : ad.headlines[0].text) : false}
                        headlineSecond={Array.isArray(ad.headlines) && ad.headlines.length > 1 ? (typeof ad.headlines[1] == "string" ? ad.headlines[1] : ad.headlines[1].text) : false}
                        headlineThird={Array.isArray(ad.headlines) && ad.headlines.length > 2 ? (typeof ad.headlines[2] == "string" ? ad.headlines[2] : ad.headlines[2].text) : false}
                        bodyOne={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? (typeof ad.descriptions[0] == "string" ? ad.descriptions[0] : ad.descriptions[0].text) : false}
                        bodySecond={Array.isArray(ad.descriptions) && ad.descriptions.length > 1 ? (typeof ad.descriptions[1] == "string" ? ad.descriptions[1] : ad.descriptions[1].text) : false}

                        link={Array.isArray(ad.final_urls) && ad.final_urls.length > 0 ? ad.final_urls[0] : false}
                        path1={ad.path1}
                        path2={ad.path2}
                    />
                }

                {
                    this.state.for_report &&
                    ad.type === "display" &&
                    ((ad.sub_type != "IMAGE_AD" && this.state.preview_placement === "skyscraper") || (ad.sub_type == "IMAGE_AD" && Array.isArray(ad.vertical_images) && ad.vertical_images.length > 0)) &&
                    <PreviewGoogleDisplaySkyscraper
                        image={ad.sub_type == "IMAGE_AD" ? (ad.vertical_images && ad.vertical_images.length > 0 ? ad.vertical_images[0].image : "") : (ad.square_images && ad.square_images.length > 0 ? ad.square_images[0].image : "")}
                        subType={ad.sub_type}
                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                        main_color={ad.main_color ? ad.main_color : ""}
                        accent_color={ad.accent_color ? ad.accent_color : ""}
                        cta={ad.cta ? ad.cta : { name: "No Button", type: "NO_BUTTON" }}
                    />
                }
                {
                    this.state.for_report &&
                    ad.type === "display" &&
                    ((ad.sub_type != "IMAGE_AD" && this.state.preview_placement === "billboard") || (ad.sub_type == "IMAGE_AD" && Array.isArray(ad.horizontal_images) && ad.horizontal_images.length > 0)) &&
                    <PreviewGoogleDisplayBillboard
                        image={ad.sub_type == "IMAGE_AD" ? (ad.horizontal_images && ad.horizontal_images.length > 0 ? ad.horizontal_images[0].image : "") : (ad.marketing_images && ad.marketing_images.length > 0 ? ad.marketing_images[0].image : "")}
                        subType={ad.sub_type}
                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                        main_color={ad.main_color ? ad.main_color : ""}
                        accent_color={ad.accent_color ? ad.accent_color : ""}
                    />
                }
                {
                    this.state.for_report &&
                    ad.type === "display" &&
                    ((ad.sub_type != "IMAGE_AD" && this.state.preview_placement === "square") || (ad.sub_type == "IMAGE_AD" && Array.isArray(ad.square_images) && ad.square_images.length > 0)) &&
                    <PreviewGoogleDisplaySquare
                        image={ad.square_images && ad.square_images.length > 0 ? ad.square_images[0].image : ""}
                        subType={ad.sub_type}
                        logo={ad.square_logo_images && ad.square_logo_images.length > 0 ? ad.square_logo_images[0].image : ""}
                        headlines={Array.isArray(ad.headlines) && ad.headlines.length > 0 ? ad.headlines : []}
                        bodies={Array.isArray(ad.descriptions) && ad.descriptions.length > 0 ? ad.descriptions : []}
                        main_color={ad.main_color ? ad.main_color : ""}
                        accent_color={ad.accent_color ? ad.accent_color : ""}
                    />
                }
                {console.log("ad prevSecGoogle: ", ad)}
                {console.log("state prevSecGoogle: ", this.state)}
            </>
        )
    }
}

export default PreviewSectionGoogle;
