import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

class AdvertisingCreativesFacebookCarouselNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            body: this.props.body,
            page: this.props.page,
            no_container: this.props.noContainer,
            language: "",
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            content: nextProps.content,
            body: nextProps.body,
            language: nextProps.language,
            page: nextProps.page
        }, () => {

            //console.log(this.refs.carousel);

            let index = 0;
            this.renders.items().filter((item) => {
                return this.renders.ratio(item) === '1:1'
            }).map((item, inner_index) => {
                if (item.selected) {
                    index = inner_index;
                }
            });

            //console.log(this.refs.carousel);

            if (this.state.index !== index) {
                this.setState({
                    index: index
                }, () => {
                    try {
                        this.refs.carousel.moveTo(index);
                    } catch (e) {

                    }
                })
            }

        });
    }

    componentDidMount() {

        this.setState({
            content: this.props.content,
            body: this.props.body,
            language: this.props.language,
            page: this.props.page
        }, () => {

            let index = 0;
            this.renders.items().filter((item) => {
                return this.renders.ratio(item) === '1:1'
            }).map((item, inner_index) => {
                if (item.selected) {
                    index = inner_index;
                }
            });

            if (this.state.index !== index) {
                this.setState({
                    index: index
                }, () => {
                    try {
                        this.refs.carousel.moveTo(index);
                    } catch (e) {

                    }
                })
            }

        });

        /*
        const slider = document.querySelector('#carousel_slider');
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
         */

    }

    functions = {};

    init = {};

    renders = {
        body: () => {
            try {
                return this.state.content.body !== "" ? this.state.content.body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        title: (item) => {
            try {
                return item.headline !== "" ? item.headline : "Headline ..."
            } catch (e) {
                return ""
            }
        },
        description: (item) => {
            try {
                return item.description !== "" ? item.description : "Description ..."
            } catch (e) {
                return ""
            }
        },
        video: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        image: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        gif: (item) => {
            try {
                return item.creative.url.indexOf('.gif') !== -1 ? item.creative.url : null
            } catch (e) {
                return ""
            }
        },
        poster: (item) => {
            try {
                return item.creative.thumbnail.url
            } catch (e) {
                return ""
            }
        },
        ratio: (item) => {
            try {
                return item.creative.ratio
            } catch (e) {
                return ""
            }
        },
        items: () => {
            try {
                return this.state.content.items
            } catch (e) {
                return []
            }
        },
        callToAction: (item) => {
            try {
                if (item.call_to_action.value === 'NO_BUTTON') {
                    return null
                } else {
                    return item.call_to_action.name;
                }
            } catch (e) {
                return null
            }
        }
    };


    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer")}>
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem",
                        {
                            ["FacebookImage-PreviewBasicContainerItemError"]: this.state.error,
                            ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                            ["FacebookImage-PreviewBasicContainerItemWhiteBackground"]: this.props.white,
                        })
                }>
                    <div className={cx("FacebookImage-PreviewBasicContainerItemInner")}>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeader">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                                <div style={{backgroundImage: 'url(' + this.state.page.image + ')'}}
                                     className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                    {this.state.page.name}
                                </div>
                                {
                                    !this.props.linkedin &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                        <div
                                            className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                            Sponsored
                                        </div>
                                        <div
                                            className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot"/>
                                        <img
                                            className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe"
                                            src={require('../../assets/images/globe.png')}/>
                                    </div>
                                }
                                {
                                    this.props.linkedin &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                        <div
                                            className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                            Promoted
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div
                            className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText FacebookImage-PreviewBasicContainerItemInnerPrimaryTextCarousel">
                            {this.renders.body()}
                        </div>
                        {
                            <div onClick={() => {
                                this.refs.carousel.onClickPrev();
                            }} className="Preview-container-prev-facebook">
                                <FeatherIcon color={"#333333"} size={19} icon="arrow-left"/>
                            </div>
                        }
                        {
                            <div onClick={() => {
                                this.refs.carousel.onClickNext();
                            }} className="Preview-container-next-facebook">
                                <FeatherIcon color={"#333333"} size={19} icon="arrow-right"/>
                            </div>
                        }
                        <div id={"carousel_slider"} className={
                            cx("FacebookImage-PreviewBasicContainerItemInnerImageCarouselNew",
                                {
                                    ["FacebookImage-PreviewBasicContainerItemInnerImageSquare"]: false,
                                })
                        }>
                            <Carousel
                                ref={'carousel'}
                                showThumbs={false}
                                showStatus={false}
                                swipeable={true}
                                showArrows={false}
                                infiniteLoop={true}
                                showIndicators={false}
                                onChange={(index, item) => {
                                    this.setState({
                                        index: index
                                    }, () => {
                                        let index = null;
                                        this.renders.items().map((inner_item, inner_index) => {
                                            if (inner_item.selected) {
                                                index = inner_index;
                                            }
                                        });
                                        if (this.state.index !== index) {
                                            this.renders.items().map((inner_item, index) => {
                                                inner_item.selected = index === this.state.index;
                                            });
                                            this.props.onChange(this.state.content.items);
                                        }
                                    });
                                }}
                            >
                                {
                                    this.renders.items().filter((item) => {
                                        return (this.renders.ratio(item) === '1:1' || item.loading)
                                    }).map((item) => {
                                        return (
                                            <div
                                                className="FacebookImage-PreviewBasicContainerItemInnerImageItemNewContainer relative">
                                                <div onClick={(e) => {
                                                    if (!this.props.noHighlight) {
                                                        this.renders.items().map((inner_item) => {
                                                            inner_item.selected = item.id === inner_item.id;
                                                        });
                                                        this.props.onChange(this.state.content.items);
                                                    }
                                                }} className="FacebookImage-PreviewBasicContainerItemInnerImageItemNew">
                                                    {
                                                        item.selected && !item.loading && !this.props.noHighlight && false &&
                                                        <div
                                                            className="FacebookImage-PreviewBasicContainerItemInnerImageItemActive">
                                                            <FeatherIcon color={"#ffffff"} size={20} icon="check"/>
                                                        </div>
                                                    }
                                                    {
                                                        item.loading &&
                                                        <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                                                            <BeatLoader
                                                                sizeUnit={"px"}
                                                                size={10}
                                                                color={'#388f8a'}
                                                                loading={true}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.props.linkedin && item.video &&
                                                        <div className="absolute z-10 bg-white left-0 right-0 top-0 bottom-0 flex justify-center align-middle items-center">
                                                                <div className="font-bold text-xs align-middle text-center">
                                                                    Video in carousel is not possible for Linkedin
                                                                    <div>
                                                                        (wont show in ads)
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    }
                                                    {
                                                        (item.image || this.renders.gif(item)) &&
                                                        <div
                                                            className="FacebookImage-PreviewBasicContainerItemInnerImageItemTop">
                                                            {
                                                                !item.loading &&
                                                                <img src={this.renders.image(item)}
                                                                     className="FacebookImage-PreviewBasicContainerItemInnerImageItemTopImage"/>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        !this.renders.gif(item) &&
                                                        item.video &&
                                                        <div
                                                            className="FacebookImage-PreviewBasicContainerItemInnerImageItemTop">
                                                            {
                                                                !item.loading &&
                                                                <video poster={this.renders.poster(item)}
                                                                       controls={true}
                                                                       style={{width: '100%', height: '100%'}}>
                                                                    <source src={this.renders.video(item)}
                                                                            type="video/mp4"/>
                                                                </video>
                                                            }
                                                        </div>
                                                    }
                                                    <div style={this.props.linkedin ? {
                                                        paddingTop: "20px",
                                                        paddingBottom: "20px"
                                                    } : {}}
                                                         className="flex flex-row FacebookImage-PreviewBasicContainerItemInnerImageItemBottom">
                                                        <div
                                                            className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft">
                                                            <div
                                                                className="FacebookImage-PreviewBasicContainerItemInnerImageItemBottomTitle">
                                                                {this.renders.title(item)}
                                                            </div>
                                                            {
                                                                !this.props.linkedin &&
                                                                <div
                                                                    className="FacebookImage-PreviewBasicContainerItemInnerImageItemBottomDescription">
                                                                    {this.renders.description(item)}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div
                                                            className="FacebookImage-PreviewBasicContainerItemInnerBottomRight">
                                                            {
                                                                this.renders.callToAction(item) && !this.props.linkedin &&
                                                                <div
                                                                    className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton">
                                                                    {this.renders.callToAction(item)}
                                                                </div>
                                                            }
                                                            {
                                                                this.renders.callToAction(item) && this.props.linkedin && false &&
                                                                <div
                                                                    className="rounded-full px-2 py-1 border border-google-500 text-google-500 font-bold text-xs">
                                                                    {this.renders.callToAction(item)}
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                            <div style={{height: '100px', minWidth: '5px'}}></div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerShare">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike"
                                     src={require('../../assets/images/like.png')}/>
                                Like
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment"
                                     src={require('../../assets/images/comment.png')}/>
                                Comment
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare"
                                     src={require('../../assets/images/share.png')}/>
                                Share
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesFacebookCarouselNew;
