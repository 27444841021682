import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static uploadImage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/image";
        return apiRegister.call(options, url);
    }

    static uploadVideo(data, channels, client) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/video?channels=" + channels + '&client=' + client;
        return apiRegister.call(options, url);
    }

    static getVideoStatus(client_id, video_id, channels) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null, true);
        let url = calls.url.api + "/video/status/" + client_id + "/" + video_id + '?channels=' + channels;
        return apiRegister.call(options, url);
    }

}

export {calls}