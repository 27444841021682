
class extensionGoogle {
    static validate(item) {
        let error = {};
        if (item.subtype.value === "call") {
            error = {
                phone: false,
                name: false,
            }
            if (!Array.isArray(item.items) || item.items.length === 0) {
                error.phone = true;
            } else {
                if (Array.isArray(item.items) && item.items.filter((inner_item) => { return inner_item.number !== "" && inner_item.country_code.id !== 0}).length < 1) {
                    error.phone = true;
                }
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
        } else if (item.subtype.value === "sitelink") {
            error = {
                sitelink: false,
                name: false,
            }
            if (!Array.isArray(item.items) || item.items.length === 0) {
                error.sitelink = true;
            } else {
                if (Array.isArray(item.items) && item.items.filter((inner_item) => { return inner_item.url !== "" && inner_item.text !== "" && inner_item.url.includes("https://")}).length < 1) {
                    error.sitelink = true;
                }
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
        
        } else if (item.subtype.value === "image") {
            error = {
                picture: false,
                name: false,
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
            if (!item.picture || item.picture === "") {
                error.picture = true;
            }
        } else if (item.subtype.value === "callout") {
            error = {
                callout: false,
                name: false,
            }
            if (!Array.isArray(item.items) || item.items.length === 0) {
                error.callout = true;
            } else {
                if (Array.isArray(item.items) && item.items.filter((inner_item) => { return inner_item.callout !== ""}).length < 1) {
                    error.callout = true;
                }
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
           
        
        } else if (item.subtype.value === "price") {
            error = {
                price: false,
                name: false,
                language: false,
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
            if (!item.language || item.language.id === 0) {
                error.language = true;
            }
            if (!Array.isArray(item.items) || item.items.length === 0) {
                error.price = true;
            } else {
                if (Array.isArray(item.items) && item.items.filter((inner_item) => { return inner_item.url !== "" && inner_item.url.includes("https://") && inner_item.text !== "" && inner_item.description1 !== "" && inner_item.number !== "" }).length < 3) {
                    error.price = true;
                }
            }

        } else if (item.subtype.value === "promotion") {
            error = {
                promotion: false,
                name: false,
                language: false,
                description: false,
                link: false,
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
            if (!item.language || item.language.id === 0) {
                error.language = true;
            }

            if (!item.promotion_description || item.promotion_description === "") {
                error.description = true;
            }

            if (!item.promotion_url || item.promotion_url === "" || !item.promotion_url.includes("https://")) {
                error.link = true;
            }

            if (!item.promotion_value || item.promotion_value === "" || item.promotion_value < 1) {
                error.promotion = true;
            }
        
        } else if (item.subtype.value === "snippet") {
            error = {
                snippet: false,
                name: false,
                language: false,
                header: false
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
            if (!item.language || item.language.id === 0) {
                error.language = true;
            }
            if (!item.header || item.header.id === 0) {
                error.header = true;
            }
            if (!Array.isArray(item.items) || item.items.length === 0) {
                error.snippet = true;
            } else {
                if (Array.isArray(item.items) && item.items.filter((inner_item) => { return inner_item.text !== "" }).length < 3) {
                    error.snippet = true;
                }
            }
        } else if (item.subtype.value === "business_name") {
            error = {
                business_name: false,
                name: false,
            }
            if (!item.business_name || item.business_name === "") {
                error.business_name = true;
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
        } else if (item.subtype.value === "business_logo") {
            error = {
                business_logo: false,
                name: false,
            }
            if (!item.business_logo || item.business_logo === "") {
                error.business_logo = true;
            }
            if (!item.name || item.name === "") {
                error.name = true;
            }
        }

        
        return error;
    }
}

export { extensionGoogle }