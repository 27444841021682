import React, { Component, Fragment } from 'react';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesInstagramVideo from '../components/advertising-creatives-instagram-video';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesInstagramImage from '../components/advertising-creatives-instagram-image';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import AdvertisingCreativesInstagramCarousel from '../components/advertising-creatives-instagram-carousel';
import AdvertisingCreativesVertical from '../components/advertising-creatives-vertical';
import PreviewTikTokFeed from '../previews/tiktok/feed';

class PreviewSectionTikTok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            channel: {}
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            channel: this.props.channel
        });
        console.log(this.state.ad, "======");
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            channel: nextProps.channel
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.type === "posts" && item.ad_type === "video") {
                    if (item.url && item.url !== "") {
                        creative = item.url;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    ad.preview && ad.preview.value === "video_feed" &&
                    <PreviewTikTokFeed
                        video={ad.type !== "posts" ? this.renders.creative(ad).video : this.renders.creative(ad)}
                        text={this.renders.body(ad)}
                        profile={this.renders.page(ad)}
                        thumbnail={this.renders.creative(ad).thumbnail}
                        cta={this.renders.cta(ad)}
                        page={this.renders.page(ad)}
                    />
                }
            </>
        )
    }
}

export default PreviewSectionTikTok;
