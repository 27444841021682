import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'

class AccordionStatic extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {}

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div className="mb-4">
                <div className="">
                    <h5 className="text-left font-bold">
                        {this.props.title}
                    </h5>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default AccordionStatic;
