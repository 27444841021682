import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearch from "../advertising-creatives-search";
import AdvertisingCreativesSearchList from "../advertising-creatives-search-list";
import AdvertisingCreativesSearchAds from "../advertising-creatives-search-ads";
import AdvertisingCreativesSitelinks from "../advertising-creatives-sitelinks";
import Accordion from "../accordion";
import { calls } from "./calls";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ReactTooltip from "react-tooltip";
import AdvertisingCreativesCall from "../advertising-creatives-call";
import AdvertisingTargetingCommon from "../advertising-targeting-common";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import AdvertisingTargetingFacebookPlacement from "../advertising-targeting-facebook-placement";
import TargetingPlacement from "../targeting/placement";
import TargetingGender from "../targeting/gender";
import TargetingAge from "../targeting/age";
import TargetingWeekdays from "../targeting/weekdays";
import TargetingFacebook from "../targeting/facebook";
import ConversionFacebookProspect from "../conversion/facebook-prospect";
import callToAction from "../../assets/json/call_to_action";
import callToActionLinkedin from "../../assets/json/call_to_action_linkedin";
import TargetingLocation from "../targeting/location";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import AdvertisingCreativesAdsItemCreativeCarouselUpload
    from "../advertising-creatives-ads-item-creative-carousel-upload";
import ModalTailwind from '../../moduleFiles/modalTailwind';
import triangleGrid from '@turf/triangle-grid';

class AdvertisingCreativeOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            locations: [],
            languages: [],
            adset: {},
            campaign: {},
            details: {},
            conversion: {},
            adsets: [],
            items: [],
            tab: { id: 1 },
            social_campaign: {},
            next: false,
            call_to_actions: callToAction,
            call_to_actions_linkedin: callToActionLinkedin,
            loaded: false,
            open_campaigns_selector: false
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'creative_overview' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        //eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                loaded: true
            }, () => {
                if (!Array.isArray(this.state.creatives.campaigns) || (Array.isArray(this.state.creatives.campaigns) && this.state.creatives.campaigns.length < 1)) {

                    this.state.creatives.campaigns = [];

                    let item = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        name: this.state.details.name,
                        website: this.state.site.url,
                        focus: {},
                        start_date: null,
                        end_date: null,
                        budget: {}
                    };

                    this.state.creatives.website = this.state.site.url;

                    if (this.state.details.startDate) {
                        item.start_date = this.state.details.startDate;
                    }
                    if (this.state.details.endDate) {
                        item.end_date = this.state.details.endDate;
                    }
                    if (this.state.details.focus && this.state.details.focus.value) {
                        if (this.state.details.focus.value === 'impressions') {
                            item.focus = { name: "Reach", value: 'impressions' };
                        }
                        if (this.state.details.focus.value === 'clicks') {
                            item.focus = { name: "Traffic", value: 'clicks' };
                        }
                        if (this.state.details.focus.value === 'conversion') {
                            item.focus = { name: "Conversions", value: 'conversion' };
                        }
                    }
                    this.state.creatives.campaigns.push(item);

                    //ADD ADSET
                    if (!Array.isArray(this.state.creatives.adsets)) {
                        this.state.creatives.adsets = [];
                    }
                    let adset = { id: Math.floor((Math.random() * 9999999999) + 1), name: 'Adset ' + (this.state.creatives.adsets.length + 1), value: this.state.creatives.adsets.length + 1, type: 'normal' };
                    adset.campaign_id = item.id;
                    adset.targeting = {};
                    adset.targeting.age_max = { name: '65+', value: 65 };
                    adset.targeting.age_min = { name: 18, value: 18 };
                    adset.targeting.gender = { name: 'Male and Female', value: 0 };
                    adset.targeting.facebook = {};
                    adset.targeting.linkedin = {
                        daily_budget: this.state.budget.linkedin ? this.state.budget.linkedin.daily : 0
                    };
                    adset.targeting.placement = { all: true };
                    adset.conversion = { prospect_default: true };
                    adset.targeting.weekdays = { monday: true, tuesday: true, wednesday: true, thursday: true, riday: true, saturday: true, sunday: true, all: true };
                    if (!this.state.creatives.adsets) { this.state.creatives.adsets = []; }
                    this.state.creatives.adsets.push(adset);

                    this.setState({
                        creatives: this.state.creatives,
                        social_campaign: item
                    }, () => {
                        this.functions.save('creatives');
                    })
                } else {
                    this.setState({
                        social_campaign: this.state.creatives.campaigns[0]
                    })
                }

                if (!(this.state.creatives && Array.isArray(this.state.creatives.facebook_pages))) {
                    this.functions.pages();
                }

            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                overview: information ? (information.overview ? (information.overview.value ? information.overview.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
            }, () => {

                if (!(this.state.creatives && Array.isArray(this.state.creatives.facebook_pages))) {
                    this.functions.pages();
                }

            })
        }
    };

    functions = {
        next: async () => {
            if (this.renders.errors().length > 0) {
                await this.promisedSetState({ error: true, next: true });
            } else {

                if (!Array.isArray(this.state.creatives.videos)) {
                    this.state.creatives.videos = [];
                }

                if (!Array.isArray(this.state.creatives.images)) {
                    this.state.creatives.images = [];
                }

                if (!Array.isArray(this.state.creatives.carousel_ads)) {
                    this.state.creatives.carousel_ads = [];
                }

                if (!Array.isArray(this.state.creatives.image_ads)) {
                    this.state.creatives.image_ads = [];
                }

                if (!Array.isArray(this.state.creatives.video_ads)) {
                    this.state.creatives.video_ads = [];
                }

                let items = [];
                items = items.concat(this.state.creatives.carousel_ads);
                items = items.concat(this.state.creatives.image_ads);
                items = items.concat(this.state.creatives.video_ads);

                if (items.length > 0) {
                    this.props.onNext();
                } else {
                    let items = [];
                    items = items.concat(this.state.creatives.images);
                    items = items.concat(this.state.creatives.videos);
                    if (items.length > 0) {
                        await this.promisedSetState({ push_all_modal: true });
                    } else {
                        this.props.onNext();
                    }
                }

            }
        },
        remove: (item) => {
            if (Array.isArray(this.state.creatives.videos)) {
                this.state.creatives.videos = this.state.creatives.videos.filter((inner_item) => {
                    return inner_item.id !== item.id
                });
            }
            if (Array.isArray(this.state.creatives.images)) {
                this.state.creatives.images = this.state.creatives.images.filter((inner_item) => {
                    return inner_item.id !== item.id
                });
            }
            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.functions.save('creatives');
            })
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        pages: async () => {
            if (!this.state.loading_pages) {
                await this.promisedSetState({ loading_pages: true });
                try {
                    let response = await calls.getPages({ client: this.state.client.id });
                    this.state.creatives.facebook_pages = Array.isArray(response.data) ? response.data : [];
                    this.state.creatives.facebook_pages = this.state.creatives.facebook_pages.map((item) => {
                        item.picture = item.picture.data.url;
                        return item;
                    });
                    await this.promisedSetState({ loading_pages: false, creatives: this.state.creatives });
                    this.functions.save('creatives');
                } catch (error) {
                    await this.promisedSetState({ loading_pages: false });
                }
            }
        },
        createPageToken: async (facebook_page) => {
            if (!this.state.loading_page_token) {
                await this.promisedSetState({ loading_page_token: true });
                try {
                    let response = await calls.createPageToken({ client: this.state.client.id, page: (facebook_page ? facebook_page.id : null) })
                    this.functions.pageInstagramAccounts(response.data);
                    await this.promisedSetState({ loading_page_token: false });
                } catch (error) {
                    await this.promisedSetState({ loading_page_token: false });
                }
            }
        },
        pageInstagramAccounts: async (page_token) => {
            if (!this.state.loading_page_instagram_accounts) {
                await this.promisedSetState({ loading_page_instagram_accounts: true });
                try {
                    let response = await calls.getInstagramAccounts({ client: this.state.client.id, page_token: page_token, page: (this.state.creatives.facebook_page ? this.state.creatives.facebook_page.id : null) });
                    this.state.creatives.instagram_page_accounts = Array.isArray(response.data) ? response.data : [];
                    this.state.creatives.instagram_page_accounts = this.state.creatives.instagram_page_accounts.map((item) => {
                        item.picture = item.profile_pic;
                        return item;
                    })
                    await this.promisedSetState({ loading_page_instagram_accounts: false, creatives: this.state.creatives });
                    this.functions.save('creatives');
                } catch (error) {
                    console.log(error);
                    await this.promisedSetState({ loading_page_instagram_accounts: false });
                }
            }
        },
        pushImagesVideosToAdsets: async (no_delay, select_all, selected_adsets) => {
            return new Promise(async (resolve) => {

                if (!no_delay) {
                    await this.promisedSetState({ loading_push_images_videos: true });
                }

                if (Array.isArray(this.state.creatives.images) || Array.isArray(this.state.creatives.videos)) {

                    let creatives = [];
                    if (Array.isArray(this.state.creatives.images)) {
                        creatives = creatives.concat(this.state.creatives.images.map((item) => { item.image = true; return item }));
                    }
                    if (Array.isArray(this.state.creatives.videos)) {
                        creatives = creatives.concat(this.state.creatives.videos.map((item) => { item.video = true; return item }));
                    }

                    creatives.map((item) => {
                        if (!item.error && (item.selected || select_all) && Array.isArray(this.state.creatives.adsets)) {

                            let images = [];
                            let videos = [];
                            this.state.creatives.adsets.filter((item) => {
                                return (selected_adsets && selected_adsets[item.id]) || !selected_adsets
                            }).map((adset) => {

                                let new_item = JSON.parse(JSON.stringify(item));
                                new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                                new_item.internal_id = new_item.image ? ("image_" + new_item.id) : ("video_" + new_item.id);
                                new_item.internal_adset = adset;

                                new_item.headline = this.state.creatives.headline ? this.state.creatives.headline : "";
                                new_item.body = this.state.creatives.body ? this.state.creatives.body : "";
                                new_item.call_to_action = this.state.creatives.call_to_action ? this.state.creatives.call_to_action : {
                                    "name": "No Button",
                                    "value": "NO_BUTTON",
                                    "vertical": true
                                };

                                if (this.state.creatives.facebook_page) {
                                    new_item.facebook_page = this.state.creatives.facebook_page;
                                }
                                if (this.state.creatives.instagram_account) {
                                    new_item.instagram_account = this.state.creatives.instagram_account;
                                }

                                try {
                                    let ref_campaign = null;
                                    this.state.creatives.campaigns.map((item) => {
                                        if (adset.campaign_id == item.id) {
                                            ref_campaign = item;
                                        }
                                    })
                                    if (ref_campaign && ref_campaign.budget.facebook_instagram.focus.value === "EVENT_RESPONSES") {
                                        new_item.call_to_action = { name: "INTERESTED", value: "EVENT_RSVP", vertical: true };
                                    }
                                } catch (error) { }

                                try {
                                    let url = this.state.creatives.website.replace('https://', "");
                                    url = url.replace('http://', "");
                                    url = url.split('/')[0];
                                    new_item.display_website = url;
                                } catch (e) {
                                    //new_item.display_website = this.state.site.hostname;
                                }
                                new_item.website = this.state.creatives.website;

                                if (new_item.image) {
                                    images.push(new_item);
                                }
                                if (new_item.video) {
                                    videos.push(new_item);
                                }

                            })

                            if (!Array.isArray(this.state.creatives.image_ads)) {
                                this.state.creatives.image_ads = [];
                            }
                            this.state.creatives.image_ads = this.state.creatives.image_ads.concat(images);

                            if (!Array.isArray(this.state.creatives.video_ads)) {
                                this.state.creatives.video_ads = [];
                            }
                            this.state.creatives.video_ads = this.state.creatives.video_ads.concat(videos);

                        }
                    })

                }


                await this.promisedSetState({ creatives: this.state.creatives });
                this.functions.save('creatives');

                if (!no_delay) {
                    await new Promise(r => setTimeout(r, 1000));
                    await this.promisedSetState({ loading_push_images_videos: false, push_images_videos_success: true });
                    await new Promise(r => setTimeout(r, 1000));
                    await this.promisedSetState({ push_images_videos_success: false });
                }

                resolve();

            });
        },
        pushCarouselAdsets: async (selected_adsets) => {

            await this.promisedSetState({ loading_push_carousels: true });

            if (!Array.isArray(this.state.creatives.carousel_ads)) {
                this.state.creatives.carousel_ads = [];
            }

            this.state.creatives.adsets.filter((item) => {
                return selected_adsets && selected_adsets[item.id]
            }).map((adset) => {
                let carousel = {
                    carousel: true,
                    languages: {},
                    show: true,
                    adsets: {},
                    headline: "",
                    body: "",
                    items: [],
                };
                carousel.id = Math.floor((Math.random() * 9999999999) + 1);
                carousel.internal_id = "carousel_" + carousel.id;
                carousel.internal_adset = adset;
                carousel.title = "Carousel";
                if (this.state.creatives.facebook_page) {
                    carousel.facebook_page = this.state.creatives.facebook_page;
                }
                if (this.state.creatives.instagram_account) {
                    carousel.instagram_account = this.state.creatives.instagram_account;
                }
                let ref_campaign = null;
                if (Array.isArray(this.state.creatives.campaigns)) {
                    this.state.creatives.campaigns.map((campaign) => {
                        if (campaign.id == adset.campaign_id) {
                            ref_campaign = campaign;
                        }
                    })
                }
                carousel.website = this.state.creatives.website;
                carousel.headline = this.state.creatives.headline;
                carousel.body = this.state.creatives.body;
                this.renders.items().filter((item) => {
                    return !item.error && item.selected && !item.loading && item.creative && item.creative.ratio === "1:1"
                }).map((item) => {
                    let slide = {
                        id: item.id,
                        title: item.title,
                        headline: "",
                        description: "",
                        creative: item.creative,
                        image: item.image,
                        video: item.video,
                        show: true,
                        call_to_action: typeof this.state.creatives.call_to_action === "object" && this.state.creatives.call_to_action.value ? this.state.creatives.call_to_action : { name: 'No call to action', value: 'NO_BUTTON' },
                    };
                    slide.website = this.state.creatives.website;
                    carousel.items.push(slide);
                });

                this.state.creatives.carousel_ads.push(carousel);

            });

            await this.promisedSetState({ creatives: this.state.creatives });
            this.functions.save('creatives');

            await new Promise(r => setTimeout(r, 1000));
            await this.promisedSetState({ loading_push_carousels: false, push_carousels_success: true });

            await new Promise(r => setTimeout(r, 1000));
            await this.promisedSetState({ push_carousels_success: false });

        }
    };

    validators = {};

    renders = {
        subtitle: () => {
            try {
                let string = [];
                return string.join(', ');
            } catch (e) {
                return ""
            }
        },
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        invalidCharacters: (string) => {
            try {
                var pattern = /[!()/|*@"'_]/;
                return pattern.test(string);
            } catch (e) {
                return true
            }
        },
        errors: () => {
            let array = [];
            return array;
        },
        items: () => {
            try {
                let array = [];
                if (Array.isArray(this.state.creatives.videos)) {
                    array = array.concat(this.state.creatives.videos);
                }
                if (Array.isArray(this.state.creatives.images)) {
                    array = array.concat(this.state.creatives.images);
                }
                return array;
            } catch (e) {
                return []
            }
        },
        gif: (item) => {
            try {
                return item.creative.url.indexOf('.gif') !== -1 ? item.creative.url : null
            } catch (e) {
                return ""
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        const SortableItemTwo = SortableElement(({ item }) => {
            return (
                <div className={(item.error ? "border-red-500" : "") + (item.selected ? "border-purple-500" : "") + " h-40 border-2 w-40 flex justify-center rounded-md overflow-hidden relative mr-2 mb-2"}>
                    {
                        !item.loading && (item.image || this.renders.gif(item)) && typeof item.creative === "object" && item.creative.url &&
                        <img src={item.creative.url} style={{ height: '100%' }} />
                    }
                    {
                        !this.renders.gif(item) &&
                        !item.loading && item.video && typeof item.creative === "object" && item.creative.url &&
                        <video controls={false} loop={true} autoPlay={true} muted={true}
                            style={{ width: '100%', height: '100%' }}>
                            <source src={item.creative.url} type="video/mp4" />
                        </video>
                    }
                    {
                        !item.loading && item.error &&
                        <div className="top-0 bottom-0 left-0 right-0 rounded-md absolute bg-red-100 justify-center items-center flex">
                            <div className="font-bold text-xs text-red-500 text-center">
                                {item.error_message}
                            </div>
                        </div>
                    }
                    {
                        item.loading &&
                        <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    }
                    <div onClick={() => {
                        if (!item.loading && !item.error) {
                            item.selected = !item.selected;
                            this.setState({
                                creatives: this.state.creatives
                            })
                        }
                    }} className="absolute right-0 left-0 top-0 bottom-0 cursor-pointer">
                        <div onClick={() => {
                            this.functions.remove(item)
                        }} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-remove cursor-pointer">
                            <FeatherIcon color={"#ffffff"} size={14} icon="trash" />
                        </div>
                        {
                            false &&
                            !item.loading && !item.error &&
                            <div onClick={() => {
                                if (!item.loading && !item.error) {
                                    item.selected = !item.selected;
                                    this.setState({
                                        creatives: this.state.creatives
                                    })
                                }
                            }} style={{ top: "5px", right: "5px", height: "30px", width: "30px" }} className={(item.selected ? "bg-purple-100 border-purple-500 text-purple-500" : "bg-gray-100") + " border-2 cursor-pointer absolute flex justify-center items-center  rounded"}>
                                {
                                    item.selected &&
                                    <FeatherIcon size={14} icon="check" />
                                }
                            </div>
                        }
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({ items }) => (
            <div className="flex flex-1 flex-row flex-wrap">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItemTwo
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));

        const onSortEnd = ({ oldIndex, newIndex }) => {
            this.setState(() => ({
                array: arrayMove(this.renders.items(), oldIndex, newIndex),
            }), () => {
                this.state.items = this.state.array;
                this.setState({
                    items: this.state.items
                })
            });
        };

        return (
            <div className="h-full flex flex-1 flex-col pb-5">

                <ModalTailwind
                    title={"Push to selected campaigns"}
                    text={"Your selected creatives will be added to selected campaigns below"}
                    submitButtonText={"Add"}
                    cancelButtonText={"Cancel"}
                    showOptions={true}
                    options={(this.state.creatives && Array.isArray(this.state.creatives.campaigns)) ? (this.state.creatives.campaigns.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            nested: Array.isArray(this.state.creatives.adsets) ? this.state.creatives.adsets.filter((adset) => { return adset.campaign_id == item.id }).map((adset) => { return { id: adset.id, name: adset.name } }) : []
                        }
                    })) : []}
                    onSubmit={async (options) => {

                        let adsets = {};
                        options.map((item) => {
                            if (Array.isArray(item.nested)) {
                                item.nested.map((item) => {
                                    if (item.selected) {
                                        adsets[item.id] = true;
                                    }
                                })
                            }
                        })

                        if (this.state.open_campaigns_selector_carousel) {
                            this.functions.pushCarouselAdsets(adsets);
                        } else if (this.state.open_campaigns_selector_images) {
                            this.functions.pushImagesVideosToAdsets(false, false, adsets);
                        }

                        await this.promisedSetState({
                            open_campaigns_selector: false,
                            open_campaigns_selector_carousel: false,
                            open_campaigns_selector_images: false
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            open_campaigns_selector_carousel: false,
                            open_campaigns_selector_images: false,
                            open_campaigns_selector: false
                        })
                    }}
                    open={this.state.open_campaigns_selector}
                />

                <SweetAlert
                    show={this.state.push_all_modal}
                    title="Push all creatives to campaigns"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={async () => {
                        await this.functions.pushImagesVideosToAdsets(true, true);
                        await this.promisedSetState({ push_all_modal: false });
                        this.props.onNext();
                    }}
                    onCancel={async () => {
                        await this.promisedSetState({ push_all_modal: false });
                        this.props.onNext();
                    }}
                />

                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 4: Creative overview
                    </div>
                </div>
                <div>
                    <div>
                        <div className="rounded-md px-4 py-4 bg-white">
                            {
                                (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin) &&
                                <div className={"flex flex-1 flex-row pb-3"}>
                                    <Input
                                        title={"Website"}
                                        white={false}
                                        value={this.state.creatives.website}
                                        placeholder={""}
                                        icons={true}
                                        channels={Object.keys(this.state.channels).filter((key) => { return this.state.channels[key] && (key === "facebook" || key === "instagram" || key === "linkedin") })}
                                        type={"text"}
                                        size={'full'}
                                        onChange={(value) => {
                                            this.state.creatives.website = value.target.value;
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.functions.save('creatives');
                                                if (this.props.onChange) {
                                                    this.props.onChange();
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            }
                            <div className="flex flex-1 flex-row justify-between">
                                <div className={"flex flex-1 flex-row"}>
                                    <div className="flex flex-1 flex-col">
                                        <Input
                                            title={"Headline"}


                                            white={false}
                                            error={this.state.next && this.renders.errors().filter((item) => {
                                                return item.type === 'headline'
                                            }).length > 0}

                                            value={this.state.creatives.headline}
                                            //placeholder={"Max 30 characters"}
                                            icons={true}
                                            channels={Object.keys(this.state.channels).filter((key) => {
                                                return this.state.channels[key] && key !== 'google'
                                            })}
                                            type={"text"}
                                            size={'full'}
                                            onChange={(value) => {
                                                this.state.creatives.headline = value.target.value;
                                                this.setState({
                                                    creatives: this.state.creatives
                                                }, () => {
                                                    this.functions.save('creatives');
                                                    try {
                                                        this.props.onChange();
                                                    } catch (e) {

                                                    }
                                                });
                                            }}
                                        />
                                        {
                                            this.state.next && this.renders.errors().filter((item) => {
                                                return item.type === 'headline'
                                            }).length > 0 &&
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-decimal">
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'headline'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    {
                                        false &&
                                        this.state.channels.google &&
                                        <div className="flex flex-1 pl-3 pr-3 flex-col">
                                            <Input
                                                title={"Headline second"}
                                                sign={true}
                                                maxLength={30}
                                                white={false}
                                                signTitle={this.renders.characters(this.state.creatives.headline_second) + ' / 30'}
                                                error={this.state.next && this.renders.errors().filter((item) => {
                                                    return item.type === 'headline_second'
                                                }).length > 0}
                                                value={this.state.creatives.headline_second}
                                                icons={true}
                                                placeholder={"Max 30 characters"}
                                                channels={['google']}
                                                type={"text"}
                                                size={'full'}
                                                onChange={(value) => {
                                                    this.state.creatives.headline_second = value.target.value;
                                                    this.setState({
                                                        creatives: this.state.creatives
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                        try {
                                                            this.props.onChange();
                                                        } catch (e) {

                                                        }
                                                    });
                                                }}
                                            />
                                            {
                                                this.state.next && this.renders.errors().filter((item) => {
                                                    return item.type === 'headline_second'
                                                }).length > 0 &&
                                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                    <ul className="list-decimal">
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.type === 'headline_second'
                                                            }).map((item) => {
                                                                return (
                                                                    <li className="text-xs text-red-500 font-bold ">
                                                                        {item.title}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        false &&
                                        this.state.channels.google &&
                                        <div className="flex flex-1 flex-col">
                                            <Input
                                                title={"Headline third (optional)"}
                                                sign={true}
                                                maxLength={30}
                                                white={false}
                                                error={this.state.next && this.renders.errors().filter((item) => {
                                                    return item.type === 'headline_third'
                                                }).length > 0}
                                                signTitle={this.renders.characters(this.state.creatives.headline_third) + ' / 30'}
                                                value={this.state.creatives.headline_third}
                                                placeholder={"Max 30 characters"}
                                                icons={true}
                                                channels={['google']}
                                                type={"text"}
                                                size={'full'}
                                                onChange={(value) => {
                                                    this.state.creatives.headline_third = value.target.value;
                                                    this.setState({
                                                        creatives: this.state.creatives
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                        try {
                                                            this.props.onChange();
                                                        } catch (e) {

                                                        }
                                                    });
                                                }}
                                            />
                                            {
                                                this.state.next && this.renders.errors().filter((item) => {
                                                    return item.type === 'headline_third'
                                                }).length > 0 &&
                                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                    <ul className="list-decimal">
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.type === 'headline_third'
                                                            }).map((item) => {
                                                                return (
                                                                    <li className="text-xs text-red-500 font-bold ">
                                                                        {item.title}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"flex flex-1 flex-row justify-between pt-3"}>
                                <div className={"flex flex-1 flex-col"}>
                                    <TextArea
                                        white={false}
                                        error={this.state.next && this.renders.errors().filter((item) => {
                                            return item.type === 'body'
                                        }).length > 0}
                                        size={"full"}
                                        title={"Body"}
                                        //placeholder={this.state.channels.google ? "Max 90 characters" : "Recommended 125 characters"}
                                        icons={true}
                                        channels={Object.keys(this.state.channels).filter((key) => {
                                            return this.state.channels[key] && key !== 'google'
                                        })}
                                        value={this.state.creatives.body}
                                        onChange={(value) => {
                                            try {
                                                this.state.campaign.body = value.target.value;
                                                this.state.creatives.body = value.target.value;
                                                this.state.creatives.social_body = value.target.value;
                                                this.setState({
                                                    campaign: this.state.campaign,
                                                    creatives: this.state.creatives
                                                }, () => {
                                                    this.functions.save('creatives');
                                                    try {
                                                        this.props.onChange();
                                                    } catch (e) {

                                                    }
                                                });
                                            } catch (e) {
                                                //console.log(e);
                                            }
                                        }}
                                    />
                                    {
                                        this.state.next && this.renders.errors().filter((item) => {
                                            return item.type === 'body'
                                        }).length > 0 &&
                                        <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                            <ul className="list-decimal">
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return item.type === 'body'
                                                    }).map((item) => {
                                                        return (
                                                            <li className="text-xs text-red-500 font-bold ">
                                                                {item.title}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                {
                                    false &&
                                    this.state.channels.google &&
                                    <div className={"flex flex-1 pl-4 flex-col"}>
                                        <TextArea
                                            sign={true}
                                            maxLength={90}
                                            signTitle={this.renders.characters(this.state.creatives.body_second) + ' / 90'}
                                            white={false}
                                            size={"full"}
                                            error={this.state.next && this.renders.errors().filter((item) => {
                                                return item.type === 'body_second'
                                            }).length > 0}
                                            title={"Body second (optional)"}
                                            placeholder={"Max 90 characters"}
                                            icons={true}
                                            channels={['google']}
                                            value={this.state.creatives.body_second}
                                            onChange={(value) => {
                                                this.state.creatives.body_second = value.target.value;
                                                this.setState({
                                                    creatives: this.state.creatives
                                                }, () => {
                                                    this.functions.save('creatives');
                                                    try {
                                                        this.props.onChange();
                                                    } catch (e) {

                                                    }
                                                });
                                            }}
                                        />
                                        {
                                            this.state.next && this.renders.errors().filter((item) => {
                                                return item.type === 'body_second'
                                            }).length > 0 &&
                                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ul className="list-decimal">
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'body_second'
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            {
                                (this.state.channels.facebook || this.state.channels.instagram) &&
                                <div className="flex flex-1 flex-row pt-3">
                                    <div className="flex flex-1">
                                        <Dropdown

                                            white={false}
                                            search={true}
                                            filter={true}
                                            title={'Facebook page'}
                                            size={'full'}
                                            icons={true}
                                            channels={["facebook"]}
                                            placeholder={this.state.loading_pages ? "Loading ..." : (Array.isArray(this.state.creatives.facebook_pages) && this.state.creatives.facebook_pages.length < 1 ? "No pages available" : "Click here")}
                                            options={Array.isArray(this.state.creatives.facebook_pages) ? this.state.creatives.facebook_pages : []}
                                            value={this.state.creatives.facebook_page ? this.state.creatives.facebook_page : {}}
                                            onChange={(value) => {
                                                this.state.creatives.facebook_page = value;
                                                this.promisedSetState({ creatives: this.state.creatives });
                                                this.functions.createPageToken(value);
                                            }}
                                        />
                                    </div>
                                    <div className="w-4" />
                                    <div className="flex flex-1">
                                        <Dropdown
                                            search={true}
                                            filter={true}
                                            white={false}
                                            title={'Instagram account'}
                                            size={'full'}
                                            icons={true}
                                            removeArrow={!this.state.creatives.facebook_page}
                                            channels={["facebook"]}
                                            disabled={!this.state.creatives.facebook_page}
                                            loading={this.state.loading_page_instagram_accounts}
                                            placeholder={this.state.loading_page_instagram_accounts ? "Loading ..." : (Array.isArray(this.state.creatives.instagram_page_accounts) && this.state.creatives.instagram_page_accounts.length < 1 ? "No accounts available" : (this.state.creatives.facebook_page ? "Click here ..." : "Select facebook page"))}
                                            options={Array.isArray(this.state.creatives.instagram_page_accounts) ? this.state.creatives.instagram_page_accounts : []}
                                            value={this.state.creatives.instagram_account ? this.state.creatives.instagram_account : {}}
                                            onChange={(value) => {
                                                this.state.creatives.instagram_account = value;
                                                this.promisedSetState({ creatives: this.state.creatives });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin) &&
                                <div className="flex flex-1 flex-row pt-3">
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                        <div className="flex flex-1">
                                            <Dropdown
                                                filter={false}
                                                white={false}
                                                size={'full'}
                                                tiny={true}
                                                noTitle={false}
                                                title={'Call To Action (optional)'}
                                                placeholder={"Click here"}
                                                icons={true}
                                                channels={["facebook"]}
                                                options={this.state.call_to_actions}
                                                value={this.state.creatives.call_to_action ? this.state.creatives.call_to_action : {}}
                                                onChange={(value) => {
                                                    try {
                                                        this.state.creatives.call_to_action = value;
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.functions.save('creatives');
                                                            try {
                                                                this.props.onChange();
                                                            } catch (e) {

                                                            }
                                                        });
                                                    } catch (e) {
                                                        //console.log(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        ((this.state.channels.facebook || this.state.channels.instagram) && this.state.channels.linkedin) &&
                                        <div className="w-4" />
                                    }
                                    {
                                        this.state.channels.linkedin &&
                                        <div className="flex flex-1">
                                            <Dropdown
                                                filter={false}
                                                white={false}
                                                size={'full'}
                                                tiny={true}
                                                noTitle={false}
                                                title={'Call To Action (optional)'}
                                                placeholder={"Click here"}
                                                icons={true}
                                                channels={["linkedin"]}
                                                options={this.state.call_to_actions_linkedin}
                                                value={this.state.creatives.call_to_action_linkedin ? this.state.creatives.call_to_action_linkedin : {}}
                                                onChange={(value) => {
                                                    try {
                                                        this.state.creatives.call_to_action_linkedin = value;
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.functions.save('creatives');
                                                            try {
                                                                this.props.onChange();
                                                            } catch (e) { }
                                                        });
                                                    } catch (e) {
                                                        //e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {
                                (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin || this.state.channels.twitter) &&
                                <div className="bg-gray-100 rounded-md px-4 py-4 mt-4">
                                    <div className="mb-4 flex flex-row">
                                        <div className="flex-col flex flex-1 mb-1">
                                            <h3 className="mb-0 font-bold">
                                                Upload media
                                            </h3>
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                Drag to rearrange them
                                            </p>
                                        </div>
                                        {
                                            this.renders.items().filter((item) => { return item.selected }).length > 0 &&
                                            <div className="flex flex-row">
                                                {
                                                    this.renders.items().filter((item) => { return item.selected }).length > 1 &&
                                                    <button
                                                        onClick={() => {
                                                            //this.functions.pushCarouselAdsets();
                                                            this.setState({
                                                                open_campaigns_selector: true,
                                                                open_campaigns_selector_carousel: true
                                                            })
                                                        }}
                                                        type="button"
                                                        className="inline-flex relative mr-2 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        Push carousel ads
                                                        {
                                                            this.state.loading_push_carousels &&
                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.push_carousels_success &&
                                                            <div className="w-full h-full absolute text-green-500 bg-green-100 border-green-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                <FeatherIcon icon="check" />
                                                            </div>
                                                        }
                                                    </button>
                                                }
                                                <button
                                                    onClick={() => {
                                                        //this.functions.pushImagesVideosToAdsets();
                                                        this.setState({
                                                            open_campaigns_selector: true,
                                                            open_campaigns_selector_images: true
                                                        })
                                                    }}
                                                    type="button"
                                                    className="inline-flex relative items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Push image/video ads
                                                    {
                                                        this.state.loading_push_images_videos &&
                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.push_images_videos_success &&
                                                        <div className="w-full h-full absolute text-green-500 bg-green-100 border-green-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <FeatherIcon icon="check" />
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.renders.items().length > 0 &&
                                        <div className="mb-2">
                                            <div className="flex flex-1 flex-row flex-wrap">
                                                {
                                                    this.renders.items().map((item) => {
                                                        return (
                                                            <div className={(item.error ? "border-red-500 " : "bg-white ") + (item.selected ? "border-purple-500" : "") + " h-40 border-2 w-40 flex justify-center rounded-md overflow-hidden relative mr-2 mb-2"}>
                                                                {
                                                                    !item.loading && (item.image || this.renders.gif(item)) && typeof item.creative === "object" && item.creative.url &&
                                                                    <img src={item.creative.url} style={{ height: '100%' }} />
                                                                }
                                                                {
                                                                    !this.renders.gif(item) &&
                                                                    !item.loading && item.video && typeof item.creative === "object" && item.creative.url &&
                                                                    <video controls={false} loop={true} autoPlay={true} muted={true}
                                                                        style={{ width: '100%', height: '100%' }}>
                                                                        <source src={item.creative.url} type="video/mp4" />
                                                                    </video>
                                                                }
                                                                {
                                                                    !item.loading && item.error &&
                                                                    <div className="top-0 bottom-0 left-0 right-0 rounded-md absolute bg-red-100 justify-center items-center flex">
                                                                        <div className="font-bold text-xs text-red-500 text-center">
                                                                            {item.error_message}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.loading &&
                                                                    <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={10}
                                                                            color={'#060534'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                }
                                                                <div onClick={() => {
                                                                    if (!item.loading && !item.error) {
                                                                        item.selected = !item.selected;
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        })
                                                                    }
                                                                }} className="absolute right-0 left-0 top-0 bottom-0 cursor-pointer">
                                                                    <div onClick={() => {
                                                                        this.functions.remove(item)
                                                                    }} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-remove cursor-pointer">
                                                                        <FeatherIcon color={"#ffffff"} size={14} icon="trash" />
                                                                    </div>
                                                                    {
                                                                        item.selected &&
                                                                        <div style={{ top: "5px", right: "5px", height: "30px", width: "30px" }} className={(item.selected ? "bg-purple-100 z-50 border-purple-500 text-purple-500" : "bg-gray-100") + " border-2 cursor-pointer absolute flex justify-center items-center  rounded"}>
                                                                            <FeatherIcon size={14} icon="check" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        false &&
                                        this.renders.items().length > 0 &&
                                        <div className="mb-2">
                                            <div className="flex flex-1 flex-row flex-wrap">
                                                <SortableList
                                                    items={this.renders.items()}
                                                    onSortEnd={onSortEnd}
                                                    axis="yx"
                                                    distance={1}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin || this.state.channels.twitter) &&
                                        <AdvertisingCreativesUpload
                                            title={this.renders.items().length > 0 ? "" : "Upload media"}
                                            size={"full"}
                                            cover={false}
                                            products={false}
                                            site={this.state.site}
                                            overview={true}
                                            icons={this.state.channels.google}
                                            client={this.state.client}
                                            channels={this.state.channels}
                                            creatives={this.state.creatives}
                                            conversion={this.state.conversion}
                                            languages={this.state.languages}
                                            campaign={this.state.social_campaign}
                                            adset={Array.isArray(this.state.creatives.adsets) ? this.state.creatives.adsets[0] : {}}
                                            adsets={this.state.creatives.adsets ? this.state.creatives.adsets : []}
                                            onChange={(value) => {
                                                this.setState({
                                                    creatives: value
                                                }, () => {
                                                    this.functions.save('creatives');
                                                    try {
                                                        this.props.onChange();
                                                    } catch (e) {

                                                    }
                                                })
                                            }}
                                            updateAdset={(adset) => {

                                            }}
                                        />
                                    }
                                </div>
                            }
                            {
                                this.state.next && this.renders.errors().filter((item) => {
                                    return item.type === 'items'
                                }).length > 0 &&
                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                    <ul className="list-decimal">
                                        {
                                            this.renders.errors().filter((item) => {
                                                return item.type === 'items'
                                            }).map((item) => {
                                                return (
                                                    <li className="text-xs text-red-500 font-bold ">
                                                        {item.title}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-1"></div>
                <div className="flex flex-row mt-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) { }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>
                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }}>
                            {
                                (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin || this.state.channels.twitter) &&
                                <div className="btn btn-lg btn-primary inline-flex items-center">
                                    Next step: Social <span className="arrow-right"></span>
                                </div>
                                ||
                                <div className="btn btn-lg btn-primary inline-flex items-center">
                                    Next step: Search <span className="arrow-right"></span>
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativeOverview;
