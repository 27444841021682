import React, { Component } from "react";
import InputTailwind from "../moduleFiles/inputTailwind";
import SuccessModal from "../moduleFiles/successModal";
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";

class onboardingVerificationCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: "",
      loading: false,
      error_message: ""
    };
  }

  componentDidMount() {

  }

  functions = {
    verify: () => {
      if (!this.state.loading) {
        this.setState({
          loading: true,
          error: false
        }, () => {
          this.calls.verifyCode({ code: this.state.code }).then((response) => {
            tokenRegister.set(response.headers['x-auth-token'], response.data.type === 'admin');
            userRegister.set(response.data, response.data.type === 'admin');
            if (response.data.type === 'admin') {
              this.props.history.push('/admin/dashboard');
            } else if (response.data.type === 'agent') {
              this.props.history.push('/v2/dashboard');
            }
          }, (error) => {
            if (error.body) {
              this.setState({
                error: true,
                loading: false,
                error_message: error.body.message
              })
            }
          });
        })
      }
    }
  };

  calls = {
    verifyCode: (data) => {
      let options = apiRegister.options(tokenRegister.get(), "POST", data);
      let url = apiRegister.url.api + "/v3/adcredo/verifyUserCode";
      return apiRegister.call(options, url);
    },
  };

  render() {
    return (
      <>
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={require("../assets/images/adcredo_icon_purple.png")}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Verifieringskod
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Skriv in koden som du har fått via SMS
            </p>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <InputTailwind
                  error={this.state.error}
                  errorMessage={this.state.error_message}
                  label={"Code"}
                  value={this.state.code}
                  onChange={(value) => {
                    this.setState({
                      code: value
                    })
                  }}
                />
                <div className={"pt-6"}>
                  <button
                    onClick={() => {
                      this.functions.verify();
                    }}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {this.state.loading && (
                      <div>
                        <div
                          style={{ borderTopColor: "transparent" }}
                          class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"
                        ></div>
                      </div>
                    )}
                    {!this.state.loading && <div>Verifiera</div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default onboardingVerificationCode;
