import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Input from "../input";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import SweetAlert from "sweetalert-react";
import Dropdown from "../dropdown";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";

var moment = require('moment');

class AdvertisingChannels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            budget: {},
            details: {},
            client: {},
            channels: {},
            error: false,
            total: 0,
            loading: false,
            round: {name: "Round down", value: 'down'}
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open
        })
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                open: this.props.open,
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
            }, () => {
                let total = 0;
                for (let key in this.state.budget) {
                    total = this.renders.round(total + this.state.budget[key].total);
                }
                if (!this.state.details.budget_calculation) {
                    this.state.details.budget_calculation = {
                        name: "Include system fee in total budget",
                        value: 'include'
                    };
                }
                try {
                    if (this.state.client.google && this.state.details.focus.value === 'conversion') {
                        if (!this.state.client.google.pixelId) {
                            this.functions.getSnippets();
                        }
                    }
                } catch (e) {

                }
                this.setState({
                    total: total,
                    budget: this.state.budget,
                    details: this.state.details
                }, () => {
                    if (!this.state.details.agency) {
                        this.init.agency();
                    }
                })
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                next: information ? (information.creatives ? (information.creatives.next ? information.creatives.next : false) : false) : false
            })
        },
        agency: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getAgency().then((response) => {
                    //console.log(response);
                    this.state.details.agency = response.data;
                    this.setState({
                        loading: false,
                        details: this.state.details
                    }, () => {
                        this.functions.save('details');
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                    });
                });
            });
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true,
                    next: true
                })
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        reset: () => {
            let information = campaignRegister.get();
            delete information.locations;
            delete information.advanced;
            delete information.targeting;
            delete information.creatives;
            delete information.languages;
            delete information.conversion;
            delete information.keywords;
            delete information.preview;
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        updateChannelsFromTotal: () => {
            let custom = 0;
            let percentage = 0;
            let total = 0;
            for (let key in this.state.budget) {
                if (this.state.budget[key].percentage && this.state.budget[key].percentage.value === 'custom') {
                    custom = custom + parseInt(this.state.budget[key].total);
                }
            }
            percentage = total;
            for (let key in this.state.budget) {
                if (this.state.channels[key]) {
                    let skip = false;
                    if (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram')) {
                        this.state.budget[key].total = 0;
                        skip = true;
                    }
                    if (total > 0 && !skip) {
                        try {
                            if (this.state.budget[key].percentage && this.state.budget[key].percentage.value === 'equal') {
                                this.state.budget[key].total = this.renders.round(percentage / Object.keys(this.state.budget).filter((item) => {
                                    let add = false;
                                    if (item === 'google') {
                                        add = true;
                                    } else if (this.state.channels.facebook_instagram && (item === 'facebook' || item === 'instagram')) {
                                        add = false;
                                    } else {
                                        add = true;
                                    }
                                    return add;
                                }).filter((item) => {
                                    return this.state.budget[item].percentage.value === 'equal' && this.state.channels[item]
                                }).length);
                            } else if (this.state.budget[key].percentage && this.state.budget[key].percentage.type === 'percent') {
                                this.state.budget[key].total = this.renders.round(total * this.state.budget[key].percentage.value);
                            }
                        } catch (e) {}
                    }
                }
            }
            //UPDATE CHANNELS DAILY BUDGET
            for (let key in this.state.budget) {
                let dates = [];
                var currDate = moment(this.state.details.startDate).startOf('day');
                var lastDate = moment(this.state.details.endDate).startOf('day');
                dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                    dates.push(currDate.clone().toDate());
                }
                dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                this.state.budget[key].daily = this.renders.round(this.state.budget[key].total / dates.length);
            }
            this.setState({
                budget: this.state.budget
            }, () => {
                this.functions.save('budget');
                this.functions.save('channels');
                try{
                    this.props.onChange();
                }catch (e) {}
            });
        },
        updateTotalFromChannels: () => {

            let custom = 0;
            let percentage = 0;

        },
        updateBudget: () => {

            let custom = 0;
            let percentage = 0;

            for (let key in this.state.budget) {
                if (this.state.budget[key].percentage && this.state.budget[key].percentage.value === 'custom') {
                    custom = custom + parseInt(this.state.budget[key].total);
                }
            }

            let total = 0;

            if (this.state.details.budget_calculation.value === 'include') {
                total = (this.state.total - this.renders.systemfee()) - custom;
                //console.log(this.state.total, this.renders.systemfee());
            } else {
                total = this.state.total - custom;
            }

            percentage = total;

            for (let key in this.state.budget) {
                if (this.state.budget[key].percentage && this.state.budget[key].percentage.type === 'percent') {
                    if (this.state.details.budget_calculation.value === 'include') {
                        percentage = percentage - ((this.state.total - this.renders.systemfee()) * this.state.budget[key].percentage.value);
                    } else {
                        percentage = percentage - (this.state.total * this.state.budget[key].percentage.value);
                    }
                }
            }

            //console.log(this.state.budget, this.state.channels);

            for (let key in this.state.budget) {
                if (this.state.channels[key]) {
                    //console.log(total);

                    let skip = false;

                    if (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram')) {
                        this.state.budget[key].total = 0;
                        skip = true;
                    }

                    if (total > 0 && !skip) {
                        try {
                            if (this.state.budget[key].percentage && this.state.budget[key].percentage.value === 'equal') {
                                this.state.budget[key].total = this.renders.round(percentage / Object.keys(this.state.budget).filter((item) => {
                                    let add = false;
                                    if (item === 'google') {
                                        add = true;
                                    } else if (this.state.channels.facebook_instagram && (item === 'facebook' || item === 'instagram')) {
                                        add = false;
                                    } else {
                                        add = true;
                                    }
                                    return add;
                                }).filter((item) => {
                                    return this.state.budget[item].percentage.value === 'equal' && this.state.channels[item]
                                }).length);
                            } else if (this.state.budget[key].percentage && this.state.budget[key].percentage.type === 'percent') {
                                this.state.budget[key].total = this.renders.round(total * this.state.budget[key].percentage.value);
                            }
                        } catch (e) {

                        }
                    }
                }
            }

            this.setState({
                budget: this.state.budget
            }, () => {
                for (let key in this.state.budget) {
                    this.renders.dailyBudget(key);
                }
            });
        },
        updatePercentBudget: (channel) => {
            try {
                this.state.budget[channel].total = Math.floor(this.state.total * (this.state.budget[channel].percentage.value / 100));
                this.setState({
                    budget: this.state.budget
                }, () => {
                    this.renders.dailyBudget(channel);
                })
            } catch (e) {

            }
        },
        getSnippets: () => {
            this.setState({
                loading_snippets: true
            }, () => {
                calls.getSnippets({channel: 'google', client: this.state.client.id}).then((response) => {
                    this.setState({
                        snippets: response.data,
                        loading_snippets: false
                    }, () => {
                        try {
                            this.state.client.google.pixelId = response.data.html;
                            this.setState({
                                client: this.state.client
                            }, () => {
                                this.functions.save('client');
                            })
                        } catch (e) {
                        }
                    });
                }, (error) => {
                    this.setState({
                        loading_snippets: false
                    })
                });
            })
        }
    };

    renders = {
        availableChannels: () => {
            try {
                let channels = [];
                if (this.state.client.google) {
                    channels.push('google');
                }
                if (this.state.client.facebook && this.state.client.instagram) {
                    channels.push('facebook_instagram');
                }
                if (this.state.client.facebook) {
                    channels.push('facebook');
                }
                if (this.state.client.instagram) {
                    channels.push('instagram');
                }
                return channels;
            } catch (e) {
                return []
            }
        },
        checkmark: () => {
            try {
                return this.renders.error.length < 1
            } catch (e) {
                return false
            }
        },
        channels: () => {
            try {
                return Object.keys(this.state.channels).filter((key) => {
                    return this.state.channels[key]
                }).length > 0
            } catch (e) {
                return false
            }
        },
        totalBudget: (channel) => {
            try {
                let dates = [];
                var currDate = moment(this.state.details.startDate).startOf('day');
                var lastDate = moment(this.state.details.endDate).startOf('day');
                dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                    dates.push(currDate.clone().toDate());
                }
                dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                this.state.budget[channel].total = this.renders.round(this.state.budget[channel].daily * dates.length);
                this.setState({
                    budget: this.state.budget
                }, () => {
                    this.functions.updateBudget();
                })
            } catch (e) {
                //console.log(e);
            }
        },
        dailyBudget: (channel) => {
            try {
                let dates = [];
                var currDate = moment(this.state.details.startDate).startOf('day');
                var lastDate = moment(this.state.details.endDate).startOf('day');
                dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                    dates.push(currDate.clone().toDate());
                }
                dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                this.state.budget[channel].daily = this.renders.round(this.state.budget[channel].total / dates.length);
                this.setState({
                    budget: this.state.budget
                }, () => {
                    this.functions.save('budget');
                    try{
                        this.props.onChange();
                    }catch (e) {

                    }
                })
            } catch (e) {
                //console.log(e);
            }
        },
        currency: (key) => {
            try {
                if (key === 'facebook' || key === 'instagram') {
                    return this.state.client[key].facebookAdAccountCurrency
                } else if (key === 'facebook_instagram') {
                    return this.state.client['facebook'].facebookAdAccountCurrency
                } else {
                    return this.state.client[key].currency
                }
            } catch (e) {
                return false
            }
        },
        budgetError: () => {
            try {
                let total = 0;
                Object.keys(this.state.budget).map((channel) => {
                    total = total + parseInt(this.state.budget[channel].total);
                });
                return this.state.total < total;
            } catch (e) {
                return false
            }
        },
        total: () => {
            try {
                let total = 0;
                Object.keys(this.state.budget).map((channel) => {
                    total = total + parseInt(this.state.budget[channel].total);
                });
                return total
            } catch (e) {
                return false
            }
        },
        systemfee: () => {
            try {
                let percentage = this.state.details.agency.system_fee / 100;
                //console.log();
                return this.renders.round(this.state.total * percentage);
            } catch (e) {
                //console.log(e);
                return 0
            }
        },
        percentages: () => {
            let percentages = [];
            for (let i = 0; i < 100; i++) {
                percentages.push({name: (i + 1) + '%', value: (i + 1)});
            }
            percentages.push({name: '100%+', value: 100});
            return percentages;
        },
        error: () => {
            let errors = [];
            if (Object.keys(this.state.channels).filter((key) => {
                return this.state.channels[key]
            }).length < 1) {
                errors.push('Select channels');
            }
            if (!this.state.total || this.state.total < 1) {
                errors.push('Total budget is to low');
            }
            if (Object.keys(this.state.budget).filter((item) => {
                let add = false;
                if (item === 'google') {
                    add = true;
                } else if (this.state.channels.facebook_instagram && (item === 'facebook' || item === 'instagram')) {
                    add = false;
                } else {
                    add = true;
                }
                return add;
            }).filter((key) => {
                return this.state.budget[key].daily < 1 && this.state.channels[key]
            }).length > 0) {
                Object.keys(this.state.budget).map((key) => {
                    if (this.state.budget[key].daily < 1 && this.state.channels[key]) {
                        errors.push('Daily budget is to low (' + key + ')');
                    }
                })
            }
            return errors;
        },
        errors: () => {
            let array = [];
            if (Object.keys(this.state.channels).filter((key) => {
                return this.state.channels[key]
            }).length < 1) {
                array.push({title: 'Select channels', type: 'channels', section: 2});
            }
            if (!this.state.total || this.state.total < 1) {
                array.push({title: 'Total budget is to low', type: 'total', section: 2});
            }
            return array;
        },
        conversionFocus: () => {
            try {
                return this.state.details.focus.value === 'conversion';
            } catch (e) {
                return false
            }
        },
        conversionAvailable: (channel) => {
            try {
                if (channel === 'facebook' || channel === 'facebook_instagram') {
                    return this.state.client.facebook.facebookPixelId
                } else if (channel === 'instagram') {
                    return this.state.client.instagram.facebookPixelId
                } else if (channel === 'google') {
                    return this.state.client.google.pixelId
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
        facebookInstagramCombined: (channel) => {
            try {
                if (channel === 'google') {
                    return false
                } else if (channel === 'facebook_instagram' && (this.state.channels.facebook || this.state.channels.instagram)) {
                    return true
                } else if ((channel === 'facebook' || channel === 'instagram') && this.state.channels.facebook_instagram) {
                    return true
                } else {
                    return false
                }
            } catch (e) {
                return true
            }
        },
        days: () => {
            try {
                let dates = [];
                var currDate = moment(this.state.details.startDate).startOf('day');
                var lastDate = moment(this.state.details.endDate).startOf('day');
                dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                    dates.push(currDate.clone().toDate());
                }
                dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                return dates.length;
            } catch (e) {
                return "-"
            }
        },
        round: (num) => {
            try {
                return Math.round((num + Number.EPSILON) * 100) / 100
            } catch (e) {
                return 0
            }
        },
        checked: (key) => {
            if (key === 'google') {
                return this.state.channels.google;
            } else {
                if (key === 'facebook_instagram') {
                    return this.state.channels[key]
                } else {
                    if (this.state.channels.facebook_instagram) {
                        return false
                    } else {
                        return this.state.channels[key]
                    }
                }
            }
        }
    };

    render() {
        return (
            <div className="h-full flex flex-1 flex-col pb-5">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'}/>
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 2: Select Channels
                    </div>
                </div>
                <div>
                    <div className="bg-white rounded-md px-4 py-4 h-full w-full">
                        <div className="flex flex-row flex-wrap mb-3">
                            {
                                this.renders.availableChannels().map((key, index) => {
                                    return(
                                        <div onClick={() => {
                                            if((key === 'instagram' || key === 'facebook') && this.renders.checked('facebook_instagram')){

                                            }else{
                                                if (key === 'facebook_instagram') {
                                                    if (this.renders.checked(key)) {
                                                        if (this.state.client.facebook) {
                                                            this.state.channels['facebook'] = false;
                                                        }
                                                        if (this.state.client.instagram) {
                                                            this.state.channels['instagram'] = false;
                                                        }
                                                    } else {
                                                        if (this.state.client.facebook) {
                                                            this.state.channels['facebook'] = true;
                                                        }
                                                        if (this.state.client.instagram) {
                                                            this.state.channels['instagram'] = true;
                                                        }
                                                    }
                                                }
                                                this.state.channels[key] = !this.state.channels[key];
                                                this.state.budget[key] = {};
                                                this.state.budget[key].percentage = {
                                                    name: "Equal divided",
                                                    value: 'equal'
                                                };
                                                this.state.budget[key].total = 0;
                                                this.state.budget[key].daily = 0;
                                                this.setState({
                                                    channels: this.state.channels,
                                                    budget: this.state.budget
                                                }, () => {
                                                    this.functions.updateChannelsFromTotal();
                                                })
                                            }
                                        }} style={!this.renders.checked(key)?{backgroundColor: '#fcfcfc'}:{}} className={
                                            cx("flex-col border-gray-100 border bg-white h-40 w-48 cursor-pointer rounded-md mr-3 flex items-center justify-center",
                                                {
                                                    ["border-green-500 bg-green-100"]: this.renders.checked(key),
                                                    ['border-red-500']: this.state.next && this.renders.errors().filter((item) => {return item.type === 'channels'}).length > 0,
                                                    ['opacity-25 cursor-not-allowed']: (key === 'instagram' || key === 'facebook') && this.renders.checked('facebook_instagram')
                                                })
                                        }>
                                            <div className="flex flex-row">
                                                {
                                                    key === 'google' &&
                                                    <div className="px-2 py-2 rounded-lg mb-2 flex items-center justify-center" style={{backgroundColor: "#4285F4", width: '60px', height: '60px'}}>
                                                        <img className="w-8" src={require('../../assets/images/google_icon.png')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook' &&
                                                    <div className="px-2 py-2 rounded-lg mb-2 flex items-center justify-center" style={{backgroundColor: "#3A5997", width: '60px', height: '60px'}}>
                                                        <img className="w-4" src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'instagram' &&
                                                    <div className="px-2 py-2 rounded-lg mb-2 flex items-center justify-center" style={{backgroundColor: "#DD2A7B", width: '60px', height: '60px'}}>
                                                        <img className="w-8" src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook_instagram' &&
                                                    <div className="px-2 py-2 rounded-lg mb-2 flex items-center justify-center" style={{backgroundColor: "#3A5997", width: '60px', height: '60px'}}>
                                                        <img className="w-4" src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook_instagram' &&
                                                    <div className="font-bold text-lg pl-1 pr-1 flex items-center justify-center">
                                                        +
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook_instagram' &&
                                                    <div className="px-2 py-2 rounded-lg mb-2 flex items-center justify-center" style={{backgroundColor: "#DD2A7B", width: '60px', height: '60px'}}>
                                                        <img className="w-8" src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className="font-bold text-sm capitalize text-center">
                                                {key!=='facebook_instagram'?key:'Facebook & Instagram'}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.next && this.renders.errors().filter((item) => {return item.type === 'channels'}).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-disc">
                                    {
                                        this.renders.errors().filter((item) => {return item.type === 'channels'}).map((item) => {
                                            return(
                                                <li className="text-sm text-red-500 font-bold ">
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        <div className="flex mt-3">
                            <Input
                                noTitle={false}
                                title={'Set total budget'}
                                type={'number'}
                                placeholder={'Type campaign budget here'}
                                error={this.state.next && this.renders.errors().filter((item) => {return item.type === 'total'}).length > 0}
                                value={this.state.total}
                                size={'full'}
                                onChange={(event) => {
                                    this.setState({
                                        total: event.target.value.replace(/^0+/, '')
                                    }, () => {
                                        this.functions.updateChannelsFromTotal();
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.next && this.renders.errors().filter((item) => {return item.type === 'total'}).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-disc">
                                    {
                                        this.renders.errors().filter((item) => {return item.type === 'total'}).map((item) => {
                                            return(
                                                <li className="text-sm text-red-500 font-bold ">
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        <div className="flex mt-3 flex-col">
                            {
                                this.renders.availableChannels().filter((key) => {
                                    return this.renders.checked(key)
                                }).map((key, index) => {
                                    return(
                                        <div className="flex-1 flex flex-row mb-2">
                                            <div className="flex flex-col flex-1">
                                                {
                                                    index === 0 &&
                                                    <div className="font-bold text-sm mb-1">
                                                        Channels
                                                    </div>
                                                }
                                                <div style={{minHeight: '55px', backgroundColor: '#fcfcfc'}} className="border rounded-md px-4 flex flex-row items-center flex-1">
                                                    {
                                                        key === 'google' &&
                                                        <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                            <IconGoogle noMargin={true}/>
                                                        </div>
                                                    }
                                                    {
                                                        (key === 'facebook' || key === 'facebook_instagram') &&
                                                        <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                    {
                                                        (key === 'instagram' || key === 'facebook_instagram') &&
                                                        <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                            <IconInstagram noMargin={true}/>
                                                        </div>
                                                    }
                                                    <div className="font-bold text-sm">
                                                        {key!=='facebook_instagram'?key:'Facebook & Instagram'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 ml-2">
                                                {
                                                    index === 0 &&
                                                    <div className="font-bold text-sm mb-1">
                                                        Divide budget
                                                    </div>
                                                }
                                                <div>
                                                    <Dropdown
                                                        filter={false}
                                                        searchFilter={false}
                                                        search={false}
                                                        noTitle={true}
                                                        disabled={!this.state.channels[key]}
                                                        size={'full'}
                                                        placeholder={"Select option"}
                                                        options={[
                                                            {name: "Equal divided", value: 'equal'},
                                                            {name: "10%", value: 0.1, type: 'percent'},
                                                            {name: "20%", value: 0.2, type: 'percent'},
                                                            {name: "30%", value: 0.3, type: 'percent'},
                                                            {name: "40%", value: 0.4, type: 'percent'},
                                                            {name: "50%", value: 0.5, type: 'percent'},
                                                            {name: "60%", value: 0.6, type: 'percent'},
                                                            {name: "70%", value: 0.7, type: 'percent'},
                                                            {name: "80%", value: 0.8, type: 'percent'},
                                                            {name: "90%", value: 0.9, type: 'percent'},
                                                            {name: "100%", value: 1, type: 'percent'},
                                                            {name: "Custom", value: 'custom'},
                                                        ]}
                                                        value={this.state.budget[key] ? (this.state.budget[key].percentage ? this.state.budget[key].percentage : {}) : {}}
                                                        onChange={(value) => {
                                                            //console.log(this.state.budget);
                                                            this.state.budget[key].percentage = value;
                                                            this.setState({
                                                                budget: this.state.budget
                                                            }, () => {
                                                                //this.functions.updateBudget();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 ml-2">
                                                {
                                                    index === 0 &&
                                                    <div className="font-bold text-sm mb-1">
                                                        Channel budget
                                                    </div>
                                                }
                                                <div>
                                                    <Input
                                                        noTitle={true}
                                                        type={'number'}
                                                        disabled={this.state.budget[key] ? (!this.state.channels[key] || (this.state.budget[key].percentage ? (this.state.budget[key].percentage.value === 'equal' || this.state.budget[key].percentage.type === 'percent') : false)) : true}
                                                        value={this.state.budget[key] ? this.state.budget[key].total : 0}
                                                        error={this.state.budget[key] ? (this.state.budget[key].total < 1 && this.state.error && this.state.channels[key]) : false}
                                                        size={'full'}
                                                        onChange={(event) => {
                                                            let number = event.target.value.replace(/^0+/, '');
                                                            this.state.budget[key].total = number;
                                                            this.setState({
                                                                budget: this.state.budget
                                                            }, () => {
                                                                //this.functions.updateBudget();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 ml-2">
                                                {
                                                    index === 0 &&
                                                    <div className="font-bold text-sm mb-1">
                                                        Daily budget
                                                    </div>
                                                }
                                                <div>
                                                    <Input
                                                        noTitle={true}
                                                        type={'number'}
                                                        disabled={this.state.budget[key] ? (!this.state.channels[key] || (this.state.budget[key].percentage ? (this.state.budget[key].percentage.value === 'equal' || this.state.budget[key].percentage.type === 'percent') : false)) : true}
                                                        value={this.state.budget[key] ? this.state.budget[key].daily : 0}
                                                        error={this.state.budget[key] ? (this.state.budget[key].daily < 1 && this.state.error && this.state.channels[key]) : false}
                                                        size={'full'}
                                                        onChange={(event) => {
                                                            let number = event.target.value.replace(/^0+/, '');
                                                            this.state.budget[key].daily = number;
                                                            this.setState({
                                                                budget: this.state.budget
                                                            }, () => {
                                                                //this.renders.totalBudget(key);
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{maxWidth: '100px'}} className="flex flex-col flex-1 ml-2">
                                                {
                                                    index === 0 &&
                                                    <div className="font-bold text-sm mb-1">
                                                        Currency
                                                    </div>
                                                }
                                                <div style={{minHeight: '55px', backgroundColor: '#fcfcfc'}} className="font-bold text-sm border rounded-md px-4 flex flex-row items-center">
                                                    {this.renders.currency(key)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-row mt-4">
                    <div className="">
                        <button onClick={() => {
                            try{
                                this.props.onBack();
                            }catch (e) {}
                        }} className="btn btn-lg inline-flex items-center">
                            Previous step
                        </button>
                    </div>
                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }} className="btn btn-lg btn-primary inline-flex items-center">
                            Next step: Locations <span className="arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

/*
<div>
                    <div className="bg-white rounded-md px-4 py-4 h-full w-full">
                        <Input
                            noTitle={false}
                            title={'Budget*'}
                            type={'number'}
                            placeholder={'Type campaign budget here'}
                            error={this.state.error && this.state.total < 1}
                            value={this.state.total}
                            size={'full'}
                            onChange={(event) => {
                                this.setState({
                                    total: event.target.value.replace(/^0+/, '')
                                }, () => {
                                    this.functions.updateBudget();
                                })
                            }}
                        />
                        {
                            this.state.details && this.state.details.agency && false &&
                            <div className="Channels-totalBudgetText">
                                *System fee included in budget ({this.state.details.agency.system_fee}%)
                            </div>
                        }
                        {
                            this.state.details.budget_calculation && this.state.details.agency && false &&
                            <div className="Section">
                                <div className="Channels-totalBudget">
                                    <Input
                                        noTitle={false}
                                        title={'Budget'}
                                        type={'number'}
                                        highlight={true}
                                        placeholder={'Type campaign budget here'}
                                        error={this.state.error && this.state.total < 1}
                                        value={this.state.total}
                                        size={'full'}
                                        onChange={(event) => {
                                            this.setState({
                                                total: event.target.value.replace(/^0+/, '')
                                            }, () => {
                                                this.functions.updateBudget();
                                            })
                                        }}
                                    />
                                </div>
                                <div className="Channels-totalBudget">
                                    {
                                        this.state.details.budget_calculation.value !== 'include' &&
                                        <Input
                                            noTitle={false}
                                            title={'Spend'}
                                            readOnly={true}
                                            tight={true}
                                            type={'number'}
                                            value={this.state.total}
                                            size={'full'}
                                            onChange={(event) => {
                                            }}
                                        />
                                    }
                                    {
                                        this.state.details.budget_calculation.value === 'include' &&
                                        <Input
                                            noTitle={false}
                                            title={'Spend'}
                                            readOnly={true}
                                            tight={true}
                                            type={'number'}
                                            value={+this.state.total - this.renders.systemfee()}
                                            size={'full'}
                                            onChange={(event) => {
                                            }}
                                        />
                                    }
                                </div>
                                <div className="Channels-totalBudget">
                                    <Input
                                        noTitle={false}
                                        title={'System fee'}
                                        type={'number'}
                                        value={this.renders.systemfee()}
                                        size={'full'}
                                        onChange={(event) => {

                                        }}
                                    />
                                </div>
                            </div>
                        }
                        <div className="Section">
                            <SweetAlert
                                show={this.state.reset_modal}
                                title="Change channels"
                                type="warning"
                                text={"All steps will be reset"}
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                showCancelButton={true}
                                onConfirm={() => {
                                    try {
                                        this.state.channels[this.state.temp_channel] = true;
                                        this.state.budget[this.state.temp_channel] = {};
                                        this.state.budget[this.state.temp_channel].percentage = {
                                            name: "Equal divided",
                                            value: 'equal'
                                        };
                                        this.state.budget[this.state.temp_channel].total = 0;
                                        this.state.budget[this.state.temp_channel].daily = 0;
                                        this.setState({
                                            channels: this.state.channels,
                                            budget: this.state.budget,
                                            temp_channel: null,
                                            reset_modal: false,
                                        }, () => {
                                            this.functions.updateBudget();
                                            this.functions.save('channels');
                                            this.functions.reset();
                                        })
                                    } catch (e) {
                                    }
                                }}
                                onCancel={() => {
                                    this.setState({reset_modal: false});
                                }}
                            />
                            <SweetAlert
                                show={this.state.google_pixel_modal}
                                title="No Google conversion pixel available"
                                type="warning"
                                text={"Would you like to continue without conversion for Google ?"}
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                showCancelButton={true}
                                onConfirm={() => {
                                    this.setState({google_pixel_modal: false});
                                }}
                                onCancel={() => {
                                    this.setState({google_pixel_modal: false});
                                }}
                            />
                            {
                                this.renders.availableChannels().map((item) => {
                                }).length > 0 &&
                                <div className="RelatedSites-items">
                                    <div className="Channels-itemsItemsTitles">
                                        <div className="Channels-itemsItemsTitlesChannel">
                                            Channels
                                        </div>
                                        <div className="Channels-itemsItemsTitlesBudget">
                                            Share of spend
                                        </div>
                                        <div className="Channels-itemsItemsTitlesBudget">
                                            Channel spend ({this.renders.days()} days)
                                        </div>
                                        <div className="Channels-itemsItemsTitlesBudgetDaily">
                                            Daily spend
                                        </div>
                                        <div className="Channels-itemsItemsTitlesCurrency">
                                            Currency
                                        </div>
                                    </div>
                                    {
                                        this.renders.availableChannels().map((key) => {
                                            return (
                                                <div className="Channels-itemsItem">
                                                    <div className="Channels-itemsItemChannel">
                                                        {
                                                            key === 'google' && this.state.loading_snippets &&
                                                            <div className="Channels-itemsItemChannelLoader">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={12}
                                                                    color={'#388f8a'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                        <div className="Channels-itemsItemChannelName">
                                                            {
                                                                key === 'google' &&
                                                                <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                                    <IconGoogle noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                (key === 'facebook' || key === 'facebook_instagram') &&
                                                                <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                                    <IconFacebook noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                (key === 'instagram' || key === 'facebook_instagram') &&
                                                                <div style={{marginRight: '7px', marginTop: '0px'}}>
                                                                    <IconInstagram noMargin={true}/>
                                                                </div>
                                                            }
                                                            <div style={{textTransform: 'capitalize'}}
                                                                 className="RelatedSites-itemsItemTitle">
                                                                {
                                                                    key !== 'facebook_instagram' &&
                                                                    <span>{key}</span>
                                                                }
                                                                {
                                                                    key === 'facebook_instagram' &&
                                                                    <span>Facebook and Instagram combined</span>
                                                                }
                                                                {
                                                                    this.renders.conversionFocus() && !this.renders.conversionAvailable(key) &&
                                                                    <span style={{color: "#FC5353", paddingLeft: '5px'}}>(No Pixel available)</span>
                                                                }
                                                                {
                                                                    this.renders.conversionFocus() && !this.renders.conversionAvailable(key) &&
                                                                    <span onClick={() => {
                                                                        this.state.client.google.pixelId = true;
                                                                        this.state.client.google.noConversion = true;
                                                                        this.setState({
                                                                            client: this.state.client
                                                                        }, () => {
                                                                            this.functions.save('client');
                                                                        });
                                                                    }} style={{
                                                                        color: "#FC5353",
                                                                        paddingRight: '10px',
                                                                        flex: 1,
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}>Skip conversion</span>
                                                                }
                                                                {
                                                                    this.renders.conversionFocus() && key === 'google' && this.state.client && this.state.client.google && this.state.client.google.noConversion &&
                                                                    <span style={{paddingLeft: '5px'}}>(click focused)</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="Channels-itemsItemChannelSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={this.renders.checked(key)}
                                                                disabled={(((this.renders.conversionFocus() && !this.renders.conversionAvailable(key)) || ((this.state.channels.facebook_instagram && (key === 'instagram' || key === 'facebook')))) || this.state.loading_snippets)}
                                                                onChange={(checked) => {
                                                                    if (this.state.next && checked) {
                                                                        this.setState({
                                                                            reset_modal: true,
                                                                            temp_channel: key
                                                                        })
                                                                    } else {

                                                                        if (key === 'facebook_instagram') {
                                                                            if (checked) {
                                                                                if (this.state.client.facebook) {
                                                                                    this.state.channels['facebook'] = true;
                                                                                }
                                                                                if (this.state.client.instagram) {
                                                                                    this.state.channels['instagram'] = true;
                                                                                }
                                                                            } else {
                                                                                if (this.state.client.facebook) {
                                                                                    this.state.channels['facebook'] = false;
                                                                                }
                                                                                if (this.state.client.instagram) {
                                                                                    this.state.channels['instagram'] = false;
                                                                                }
                                                                            }
                                                                        }

                                                                        this.state.channels[key] = checked;
                                                                        this.state.budget[key] = {};
                                                                        this.state.budget[key].percentage = {
                                                                            name: "Equal divided",
                                                                            value: 'equal'
                                                                        };
                                                                        this.state.budget[key].total = 0;
                                                                        this.state.budget[key].daily = 0;
                                                                        this.setState({
                                                                            channels: this.state.channels,
                                                                            budget: this.state.budget
                                                                        }, () => {
                                                                            this.functions.save('channels');
                                                                            this.functions.updateBudget();
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={(!this.state.channels[key] || (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram'))) ? {opacity: '0.4'} : {}}
                                                        className="Channels-itemsItemBudget">
                                                        {
                                                            <Dropdown
                                                                filter={false}
                                                                searchFilter={false}
                                                                search={false}
                                                                noTitle={true}
                                                                disabled={!this.state.channels[key]}
                                                                size={'full'}
                                                                placeholder={"Select option"}
                                                                options={[
                                                                    {name: "Equal divided", value: 'equal'},
                                                                    {name: "10%", value: 0.1, type: 'percent'},
                                                                    {name: "20%", value: 0.2, type: 'percent'},
                                                                    {name: "30%", value: 0.3, type: 'percent'},
                                                                    {name: "40%", value: 0.4, type: 'percent'},
                                                                    {name: "50%", value: 0.5, type: 'percent'},
                                                                    {name: "60%", value: 0.6, type: 'percent'},
                                                                    {name: "70%", value: 0.7, type: 'percent'},
                                                                    {name: "80%", value: 0.8, type: 'percent'},
                                                                    {name: "90%", value: 0.9, type: 'percent'},
                                                                    {name: "100%", value: 1, type: 'percent'},
                                                                    {name: "Custom", value: 'custom'},
                                                                ]}
                                                                value={this.state.budget[key] ? (this.state.budget[key].percentage ? this.state.budget[key].percentage : {}) : {}}
                                                                onChange={(value) => {
                                                                    this.state.budget[key].percentage = value;
                                                                    this.setState({
                                                                        budget: this.state.budget
                                                                    }, () => {
                                                                        this.functions.updateBudget();
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div
                                                        style={(!this.state.channels[key] || (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram'))) ? {opacity: '0.4'} : {}}
                                                        className="Channels-itemsItemBudget">
                                                        <Input
                                                            noTitle={true}
                                                            type={'number'}
                                                            disabled={this.state.budget[key] ? (!this.state.channels[key] || (this.state.budget[key].percentage ? (this.state.budget[key].percentage.value === 'equal' || this.state.budget[key].percentage.type === 'percent') : false)) : true}
                                                            value={this.state.budget[key] ? this.state.budget[key].total : 0}
                                                            error={this.state.budget[key] ? (this.state.budget[key].total < 1 && this.state.error && this.state.channels[key]) : false}
                                                            size={'full'}
                                                            onChange={(event) => {
                                                                let number = event.target.value.replace(/^0+/, '');
                                                                this.state.budget[key].total = number;
                                                                this.setState({
                                                                    budget: this.state.budget
                                                                }, () => {
                                                                    this.functions.updateBudget();
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={(!this.state.channels[key] || (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram'))) ? {opacity: '0.4'} : {}}
                                                        className="Channels-itemsItemBudgetDaily">
                                                        <Input
                                                            noTitle={true}
                                                            type={'number'}
                                                            disabled={this.state.budget[key] ? (!this.state.channels[key] || (this.state.budget[key].percentage ? (this.state.budget[key].percentage.value === 'equal' || this.state.budget[key].percentage.type === 'percent') : false)) : true}
                                                            value={this.state.budget[key] ? this.state.budget[key].daily : 0}
                                                            error={this.state.budget[key] ? (this.state.budget[key].daily < 1 && this.state.error && this.state.channels[key]) : false}
                                                            size={'full'}
                                                            onChange={(event) => {
                                                                let number = event.target.value.replace(/^0+/, '');
                                                                this.state.budget[key].daily = number;
                                                                this.setState({
                                                                    budget: this.state.budget
                                                                }, () => {
                                                                    this.renders.totalBudget(key);
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={(!this.state.channels[key] || (this.state.channels.facebook_instagram && (key === 'facebook' || key === 'instagram'))) ? {opacity: '0.4'} : {}}
                                                        className="Channels-itemsItemCurrency">
                                                        {this.renders.currency(key)}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                        {
                            this.state.details.budget_calculation && false &&
                            <div className="Section">
                                {
                                    this.state.details.budget_calculation.value !== 'include' &&
                                    <Input
                                        noTitle={false}
                                        title={'Total budget = Spend + System fee'}
                                        readOnly={true}
                                        tight={true}
                                        type={'number'}
                                        value={this.renders.systemfee() + +this.state.total}
                                        size={'full'}
                                        onChange={(event) => {
                                        }}
                                    />
                                }
                                {
                                    this.state.details.budget_calculation.value === 'include' &&
                                    <Input
                                        noTitle={false}
                                        title={'Total budget = Spend + System fee'}
                                        readOnly={true}
                                        tight={true}
                                        type={'number'}
                                        value={this.state.total}
                                        size={'full'}
                                        onChange={(event) => {
                                        }}
                                    />
                                }
                            </div>
                        }
                        {
                            this.state.error && this.renders.error().length > 0 &&
                            this.renders.error().map((item) => {
                                return (
                                    <div className="Section SectionError">
                                        {item}
                                    </div>
                                )
                            })
                        }
                        {
                            this.renders.budgetError() && this.state.error &&
                            <div className="Site-Error">
                                Channel budgets are bigger than the Total budget, <b onClick={() => {
                                this.setState({
                                    total: this.renders.total()
                                })
                            }} style={{cursor: 'pointer'}}>Click here to adjust the Total budget</b>
                            </div>
                        }
                    </div>
                </div>
 */

export default AdvertisingChannels;
