import React, { Component } from 'react';

class PreviewGoogleShopping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    };

    componentDidMount() {
        this.setState({
            products: this.props.products
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            products: nextProps.products
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + (chars > 30 ? " ..." : "");
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "500px" }} className={"w-full border rounded-md p-4 bg-white"}>
                <div className="text-xs font-bold mb-2">
                    Ads
                </div>
                <div className="flex flex-row overflow-scroll">
                    {
                        this.state.products.map((item) => {
                            return (
                                <div>
                                    <div className="w-40 border rounded-md mr-4 overflow-hidden">
                                        <div className="w-full h-40 bg-gray-50 overflow-hidden">
                                            <img src={item.image} className="w-full h-full" />
                                        </div>
                                        <div className="p-2 border-t">
                                            <div className="text-sm font-medium h-10 overflow-hidden">
                                                {this.renders.truncate(item.name, 30)}
                                            </div>
                                            <div className="text-sm mt-3 font-bold">
                                                {item.price}
                                            </div>
                                            <div className="text-sm font-medium text-gray-700">
                                                {item.brand ? item.brand : "..."}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default PreviewGoogleShopping;
