import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
        facebook_version: 'v16.0'
    };

    static getAccount(token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + "/" + 'me?fields=id%2Cname&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getBusinessesFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + "me/businesses?access_token=" + data.token + "&limit=100";
        return apiRegister.call(options, url);
    }

    static getAdaccountsOwnedFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.manager + "/owned_ad_accounts?access_token=" + data.token + "&limit=100&fields=name,funding_source,funding_source_details,account_id,currency,end_advertiser,end_advertiser_name,min_daily_budget,timezone_name";
        return apiRegister.call(options, url);
    }

    static getAdaccountsClientFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.manager + "/client_ad_accounts?access_token=" + data.token + "&limit=100&fields=name,funding_source,funding_source_details,account_id,currency,end_advertiser,end_advertiser_name,min_daily_budget,timezone_name";
        return apiRegister.call(options, url);
    }

    static getPagesOwnedFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.manager + "/owned_pages?access_token=" + data.token + "&limit=100";
        return apiRegister.call(options, url);
    }

    static getPagesClientFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.manager + "/client_pages?access_token=" + data.token + "&limit=100";
        return apiRegister.call(options, url);
    }

    static getInstagramFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.page + "/instagram_accounts?access_token=" + data.token + "&fields=username,id&limit=100";
        return apiRegister.call(options, url);
    }

    static getInstagramPageBackedFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.page + "/page_backed_instagram_accounts?access_token=" + data.token + "&fields=username,id&limit=100";
        return apiRegister.call(options, url);
    }

    static createPageTokenFB(data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = "https://graph.facebook.com/" + calls.url.facebook_version + "/" + data.page + "?access_token=" + data.token + "&fields=access_token";
        return apiRegister.call(options, url);
    }

    /*
    static getBusinesses(token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/me/businesses?fields=timezone_id,name&access_token=' + token;
        return apiRegister.call(options, url);
    }
    */

    static getBusinesses(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/v3/facebook/listBusinessManagers?client=' + data.client + "&token=" + encodeURIComponent(data.token);
        return apiRegister.call(options, url);
    }

    static getCreditLines(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + business_id + '/extendedcredits?access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getCreditCards(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + business_id + '/creditcards?access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getAdaccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/v3/facebook/listAdaccounts?client=' + data.client + "&manager=" + data.manager + "&token=" + encodeURIComponent(data.token);
        return apiRegister.call(options, url);
    }

    static getClientAdaccounts(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + business_id + '/client_ad_accounts?fields=name,funding_source,funding_source_details,account_id,currency,end_advertiser,end_advertiser_name,min_daily_budget&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getAdaccount(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + adaccount_id + '?fields=name,funding_source,funding_source_details,account_id,currency,end_advertiser,end_advertiser_name,min_daily_budget&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static createAdaccount(business_id, token, name, timezone, currency) {
        let options = apiRegister.options(null, 'POST', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + business_id + '/adaccount?end_advertiser=' + business_id + '&media_agency=NONE&partner=NONE&currency=' + currency + '&name=' + name + '&timezone_id=' + timezone + '&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/v3/facebook/listPages?client=' + data.client + "&manager=" + data.manager + "&token=" + encodeURIComponent(data.token);
        return apiRegister.call(options, url);
    }

    static getPageAccessToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', null);
        let url = apiRegister.url.api + '/v3/facebook/createPageToken?client=' + data.client + "&page=" + data.page + "&token=" + encodeURIComponent(data.token);
        return apiRegister.call(options, url);
    }

    static getInternalPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api + '/facebook/pages';
        return apiRegister.call(options, url);
    }

    static getInternalClientPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api + '/facebook/pages?clients=true';
        return apiRegister.call(options, url);
    }

    /*
    static getPageAccessToken(page_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + page_id + '?fields=access_token&access_token=' + token;
        return apiRegister.call(options, url);
    }
    */

    static getInstagramAccountsFromAdAccount(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + adaccount_id + '/instagram_accounts?fields=username&access_token=' + token;
        return apiRegister.call(options, url);
    }

    /*
    static getInstagramAccountsFromPage(page_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + page_id + '/instagram_accounts?fields=username&access_token=' + token;
        return apiRegister.call(options, url);
    }
    */

    static getInstagramAccountsFromPage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/v3/facebook/listInstagramAccounts?client=' + data.client + "&page=" + data.page + "&token=" + encodeURIComponent(data.token);
        return apiRegister.call(options, url);
    }

    static getInstagramAccountsFromPBIA(page_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + page_id + '/page_backed_instagram_accounts?fields=username&access_token=' + token;
        //console.log(url);
        return apiRegister.call(options, url);
    }

    static createInstagramAccountFromPBIA(page_id, token) {
        let options = apiRegister.options(null, 'POST', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + page_id + '/page_backed_instagram_accounts?fields=username&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static updatePage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = apiRegister.url.api + '/client/facebook/' + data.client;
        return apiRegister.call(options, url);
    }

    static getPixels(adaccount_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/' + calls.url.facebook_version + '/' + adaccount_id + '/adspixels?fields=name&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static updateFacebookSettings(data, client_id) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api + '/client/facebook/' + client_id;
        return apiRegister.call(options, url);
    }

    static removeFacebookSettings(client_id) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = apiRegister.url.api + '/client/facebook/' + client_id;
        return apiRegister.call(options, url);
    }

}

export { calls }