import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './index.css';

export default class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.dropdownRef = React.createRef();
    }

    componentWillMount() {}

    componentDidMount() {
        document.addEventListener('mousedown', this.functions.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.functions.handleClickOutside);
    }

    functions = {
        handleClickOutside: (e) => {
            let path = e.path || (e.composedPath && e.composedPath()) || e.composedPath(e.target);
            if (this.props.isDroppedDown === true && !path.includes(this.dropdownRef.current)) {
                this.props.setDropdown();
            }
        },
    };

    render() {
        return (
            <div ref={this.dropdownRef} className="dropdown relative inline-block text-left ml-6">
                <div onClick={this.props.setDropdown}>
                    <button
                        type="button"
                        className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-transparent hover:bg-gray-300 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 flex justify-center align-center"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {this.props.label}
                        <svg
                            className={`-mr-1 ml-2 h-5 w-5 ${this.props.isDroppedDown ? 'rotated' : 'base'}`}
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true">
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                <div
                    className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 max-h-40 overflow-y-auto z-40  
                    ${this.props.isDroppedDown ? 'block' : 'hidden'}`}>
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {this.props.data.map(({name}, index) => (
                            <Link
                                to="#"
                                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 hover:text-gray-900 ${
                                    this.props.droppedDownActiveValue === name ? 'bg-gray-100' : 'bg-white'
                                }`}
                                role="menuitem"
                                onClick={() => this.props.setFilterValue(name)}
                                key={index}>
                                {name}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
