import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from 'react-spinners';
import Input from "../input";

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            success_modal: false,
            error_modal: false,
            email: "",
            name: "",
            loading: true,
            button_loading: false,
            error_message: "",
        };
    };

    componentWillMount() {
        this.init.user();
    }

    init = {
        user: () => {
            dataRegister.getUser().then((response) => {
                this.setState({
                    loading: false,
                    email: response.email,
                    name: response.name
                });
            }, (error) => {

            });
        }
    };

    functions = {
        update: () => {
            if(!this.state.button_loading){
                this.setState({button_loading: true}, () => {
                    dataRegister.updateUser({email: this.state.email, name: this.state.name}).then((response) => {
                        this.setState({
                            button_loading: false,
                            success_modal: true,
                            email: response.email,
                            name: response.name
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    });
                });
            }
        }
    };

    renders = {};

    render(){
        return (
            <div className="Container">
                <SweetAlert
                    show={this.state.success_modal}
                    title="Updated"
                    type="success"
                    text="Click OK to continue!"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                {
                    this.state.loading &&
                    <div className="Account-Loader">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#388f8a'}
                            loading={true}
                        />
                    </div>
                }
                <div className="Section">
                    <Input
                        title={'Name'}
                        type={'text'}
                        value={this.state.name}
                        onChange={(event) => this.setState({name: event.target.value})}
                    />
                    <Input
                        title={'Email'}
                        type={'email'}
                        value={this.state.email}
                        onChange={(event) => this.setState({email: event.target.value})}
                    />
                </div>
                <div className="Section">
                    <div onClick={() => {
                        this.functions.update();
                    }} className="Button">
                        {
                            !this.state.button_loading &&
                            <div>Uppdate</div>
                        }
                        {
                            this.state.button_loading &&
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={'#ffffff'}
                                loading={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Account;
