import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, CubeIcon, ExclamationCircleIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import CreateCustomMetric from '../modules/createCustomMetric';
import CreateMetric from '../moduleFiles/createMetric';
import CreateCustomNewMetric from '../moduleFiles/createCustomMetric';

class DefaultMetricList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            closed: true,
            search: "",
            sort: "name",
            direction: "ascending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'type', value: 'type', min_width: '150px', noSort: true },
                { name: 'channels', value: 'channels', min_width: '250px', noSort: true },
                { name: 'created', value: 'created', min_width: '250px' }
            ],
            status: {},
            client: {},
            clients: [],
            stats: [],
            filters: [],
            throttling: {},
            metric: null
        }
    };

    componentDidMount() {
        this.functions.metrics(true);
    }

    functions = {
        metrics: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.metrics();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ orders: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
        },
        menu: async (option, row) => {
            if (option === "Edit") {
                await this.promisedSetState({
                    metric: row,
                    create_metric: true,
                });
            } else if (option === "Delete") {
                await this.promisedSetState({
                    remove: row
                });
            }
        }
    };

    calls = {
        metrics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomMetrics?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&agency=system&default=true";
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeMetric?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={async (type) => {
                            if (type === "create") {
                                await this.promisedSetState({
                                    metric: null,
                                    create_metric: true
                                });
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.orders();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.orders(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        filters={[]}
                        hideUserDropdown={true}
                        breadcrumb={"Orders"}
                        buttons={[
                            { text: "Create new", icon: AdjustmentsIcon, value: "create", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                        onClient={async (client) => {
                            /*
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.orders();
                            this.props.updateStatistics(client);
                            */
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading metrics ...</div>
                    </div>
                }

                {/*CREATE DEFAULT METRIC*/}
                <SlideoutTailwind
                    title={this.state.metric ? "Edit default metric" : "Create default metric"}
                    submitButtonText={this.state.metric ? "Update" : "Create"}
                    large={true}
                    secondaryButton={false}
                    open={this.state.create_metric ? true : false}
                    loader={this.state.create_metric_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_metric: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_metric_loading: true
                        });
                        this.refs.CreateMetric.functions.createMetric();
                    }}
                >
                    {
                        this.state.create_metric &&
                        <CreateCustomNewMetric
                            admin={true}
                            default={true}
                            ref="CreateMetric"
                            metric={this.state.metric}
                            onCreated={async () => {
                                await this.promisedSetState({
                                    metric: null,
                                    create_metric: false,
                                    create_metric_loading: false,
                                    sort: 'created',
                                    direction: 'descending'
                                });
                                this.functions.metrics();
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_metric_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full overflow-hidden">

                            {/*NO ORDERS*/}
                            {
                                this.state.orders.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No {this.state.status.name} metrics available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.orders.length > 0 &&
                                <div className="relative overflow-scroll pb-32">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.orders();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                        <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.orders.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr className="border-b" key={item.id}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row  border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Edit", "Delete"].map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Edit", item);
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={" px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    <span
                                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                //["bg-green-100 text-green-500"]: item.status === "active",
                                                                                //["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: true,
                                                                                //["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused"
                                                                            })
                                                                        }>
                                                                        {item.calculated ? "calculated" : "custom"}
                                                                    </span>
                                                                </td>
                                                                <td className={"px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row pl-2">
                                                                        {item.channels && item.channels.facebook &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.instagram &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.linkedin &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_shopping &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.tiktok &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.twitter &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_analytics &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 font-medium  sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    {item.created ? moment(item.created).format('YYYY-MM-DD') : '-'}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.orders.length > 0 &&
                                <div className="flex flex-row p-4 items-center">
                                    <div className="flex justify-center items-center">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1) {
                                                    await this.promisedSetState({
                                                        page: this.state.page - 1
                                                    });
                                                    this.functions.orders();
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous
                                        </button>
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                        this.functions.orders();
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default DefaultMetricList;
