import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import Dropdown from "../../dropdown";
import languages from '../../../assets/json/languages';
import Input from "../../input";
import DropdownMultiSelect from '../../dropdown-multiselect';

class TargetingLanguages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            languages: languages,
            selected_languages: [],
            keywords: {},
            site: {},
            channels: {},
            language: {},
            error: false,
            search: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            selected_languages: nextProps.languages ? nextProps.languages : [],
            channels: nextProps.channels,
            language: nextProps.language,
            site: nextProps.site,
            error: nextProps.error
        }, () => {
            console.log(this.state.selected_languages);
        });
    }

    componentDidMount() {
        this.setState({
            open: this.props.open,
            selected_languages: this.props.languages ? this.props.languages : [],
            channels: this.props.channels,
            language: this.props.language,
            site: this.props.site,
            error: this.props.error
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <div className="w-full">
                <DropdownMultiSelect
                    ref={this.multiSelect}
                    title={"Languages"}
                    search={this.state.search}
                    showType={false}
                    showSearch={true}
                    placeholderSearch={"Search language..."}
                    type={false}
                    types={[]}
                    showAllOnFocus={true}
                    loading={false}
                    options={this.state.languages.map((item) => {
                        item.id = item.code;
                        return item;
                    })}
                    selected={Array.isArray(this.state.selected_languages)?this.state.selected_languages.map((item) => {
                        item.id = item.code;
                        return item;
                    }):[]}
                    onSelect={(value) => {
                        console.log(value);
                        if(!Array.isArray(this.state.selected_languages)){
                            this.state.selected_languages = [];
                        }
                        this.state.selected_languages.push(value);
                        this.setState({
                            selected_languages: this.state.selected_languages
                        }, () => {
                            this.props.onChange(this.state.selected_languages);
                        })
                    }}
                    onRemove={(item) => {
                        console.log(item);
                        this.state.selected_languages = this.state.selected_languages.filter((inner_item) => {
                            return item.id !== inner_item.id;
                        });
                        this.setState({
                            selected_languages: this.state.selected_languages
                        }, () => {
                            this.props.onChange(this.state.selected_languages);
                        })
                    }}
                    onType={(value) => {

                    }}
                    onSearch={(value) => {
                        this.setState({
                            search: value
                        })
                    }}
                />
                {
                    false &&
                    <Dropdown
                        filter={true}
                        searchFilter={false}
                        search={true}
                        title={'Language'}
                        noTitle={false}
                        error={this.state.error}
                        icons={false}
                        size={'full'}
                        placeholder={"Click here"}
                        options={this.state.languages.filter((item) => {
                            if (this.state.selected_languages) {
                                let exist = false;
                                this.state.selected_languages.map((item_inner) => {
                                    if (item_inner.code === item.code) {
                                        exist = true;
                                    }
                                });
                                return !exist;
                            } else {
                                return true
                            }
                        })}
                        value={this.state.language ? this.state.language : {}}
                        onChange={(value) => {
                            this.setState({
                                language: value
                            }, () => {
                                this.props.onChange(this.state.language);
                            })
                        }}
                    />
                }
            </div>
        )
    }
}

export default TargetingLanguages;
