import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getSearchterms(id, sort, order) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/keyword/searchterms/" + id + '?sort=' + sort + '&order=' + order;
        return apiRegister.call(options, url);
    }

    static getNegativeKeywords(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/keyword/negative/" + id;
        return apiRegister.call(options, url);
    }

    static getPositiveKeywords(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/keyword/positive/" + id;
        return apiRegister.call(options, url);
    }

}

export {calls}