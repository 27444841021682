import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/linkedin/code";
        return apiRegister.call(options, url);
    }

    static getAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/linkedin/accounts";
        return apiRegister.call(options, url);
    }

    static getAccount(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/linkedin/organization";
        return apiRegister.call(options, url);
    }

    static create(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/linkedin";
        return apiRegister.call(options, url);
    }

    static remove(client) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/v2/linkedin/" + client;
        return apiRegister.call(options, url);
    }

}

export {calls}