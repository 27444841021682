import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from 'react-spinners';
import { calls } from "./calls";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'

class UploadImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: this.props.disabled,
            success: false,
            value: null,
            title: this.props.title,
            size: this.props.size,
            loading: false,
            cover: this.props.cover,
            imageOnly: this.props.imageOnly,
            error: false,
            client: "",
            max_width: true
        };
        this.functions.uploadImage = this.functions.uploadImage.bind(this);
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            imageOnly: this.props.imageOnly,
            value: this.props.value,
            color: this.props.color,
            max_width: this.props.max_width
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disabled: nextProps.disabled,
            success: nextProps.success,
            size: nextProps.size,
            title: nextProps.title,
            value: nextProps.value,
            error: nextProps.error,
            client: nextProps.client,
            color: nextProps.color,
            max_width: nextProps.max_width
        });
    }

    init = {};

    functions = {
        uploadImage: (e) => {
            if (e.target.files) {
                if (e.target.files[0].size > 5999000) {
                    this.setState({
                        error_modal: true,
                        error_message: 'Image is to large'
                    });
                } else {
                    const formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    this.setState({ loading: true }, () => {
                        dataRegister.uploadLogo(formData).then((response) => {
                            if (this.state.imageOnly) {
                                this.setState({
                                    value: null,
                                    loading: false
                                }, () => {
                                    this.props.onChange(response.url);
                                });
                            } else {
                                this.functions.update(response.url);
                            }
                        }, (error) => {
                            this.setState({
                                value: null,
                                loading: false
                            });
                        });
                    });
                }
            }
        },
        update: (url) => {
            let client = {
                logo: url
            };
            calls.updateClient(client, this.state.client).then((response) => {
                this.setState({
                    value: url,
                    loading: false
                }, () => {
                    this.props.onChange(url);
                });
            }, (error) => {
                this.setState({
                    error_message: error.body.message,
                    error_modal: true,
                    loading: false
                });
            });
        }
    };

    renders = {};

    render() {
        return (
            <div className={`${this.state.max_width || undefined ? "h-full w-full flex flex-col" : ""}`}>
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div className="text-xs font-medium text-gray-700 mb-1">
                    {this.props.title}
                </div>
                <div
                    style={this.state.color ? { backgroundColor: "#" + this.state.color } : {}}
                    className={`${this.state.max_width || undefined ?
                        "flex h-40 items-center overflow-hidden justify-center border-1.5 rounded-md bg-custom-input relative" :
                        "flex flex-grow flex-1 items-center justify-center border-1.5 rounded-md bg-custom-input relative min-h-32 max-h-40"}`
                    }>
                    {
                        !this.props.disabled &&
                        <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                            onChange={(e) => this.functions.uploadImage(e)} type="file"
                            accept={"image/png"}
                            className="UploadImageContainerAreaUpload" />
                    }
                    {
                        this.state.value && !this.state.loading && this.state.max_width &&
                        <div className="max-w-40">
                            <img src={this.state.value} className={"h-full"} />
                        </div>
                    }
                    {
                        this.state.value && !this.state.loading && !this.state.max_width &&
                        <img src={this.state.value}
                            style={{ maxWidth: 150 }}
                            className={
                                cx("UploadImageContainerAreaImage",
                                    {
                                        ["UploadImageContainerAreaImageCover"]: this.state.cover,
                                    })
                            } />
                    }
                    {
                        this.state.loading &&
                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                    }
                    {
                        !this.state.value && !this.state.loading &&
                        <div className="text-xs font-medium text-gray-700">
                            Click here
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default UploadImage;
