import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {BeatLoader} from "react-spinners";
import OptimizeSearchKeywordsItem from "../optimize-search-keywords-item";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import {calls} from "./calls";
import cx from "classnames";
import IconGoogle from "../icons/google";

class AdvertisingKeywordsNegativeAsset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading_assets: true,
            client: {},
            keywords: [],
            assets: [],
            asset: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            keywords: nextProps.keywords,
            client: nextProps.client
        })
    }

    componentDidMount() {
        this.setState({
            keywords: this.props.keywords,
            client: this.props.client
        }, () => {
            this.functions.assets();
        });
    }

    init = {};

    functions = {
        assets: () => {
            this.setState({
                loading_assets: true
            }, () => {
                calls.getAssets(this.state.client.id).then((response) => {
                    this.setState({
                        loading_assets: false,
                        assets: response.data
                    })
                }, (error) => {

                });
            })
        },
        asset: (item) => {
            this.setState({
                loading_asset: true
            }, () => {
                calls.getAsset(item.id, this.state.client.id).then((response) => {
                    this.setState({
                        loading_asset: false
                    }, () => {
                        this.functions.add(response.data);
                    })
                }, (error) => {

                });
            })
        },
        add: (items) => {
            for(let i = 0; i < items.length; i++){
                let id = Math.floor((Math.random() * 9999999999) + 1);
                let main = {
                    id: id,
                    name: items[i].text,
                    exact: false,
                    broad: false,
                    phrase: false,
                };
                if(items[i].type === 'exact'){
                    main.exact = true
                }
                if(items[i].type === 'broad'){
                    main.broad = true
                }
                if(items[i].type === 'phrase'){
                    main.phrase = true
                }
                this.state.keywords.unshift(main);
            }
            this.props.onChange(this.state.keywords);
        },
    };

    renders = {};

    render() {
        return (
            <div className="Optimize-Search-Asset">
                <div className="text-xs mb-1 font-bold">
                    Add existing negative Keyword list to campaign
                </div>
                <div style={{marginBottom: '10px'}} className="Optimize-Search-Asset-Get">
                    <div className="Optimize-Search-Asset-Get-dropdown">
                        <Dropdown
                            noTitle={true}
                            filter={false}
                            size={'full'}
                            tiny={true}
                            placeholder={(this.state.loading_assets || this.state.loading_campaign_assets || this.state.loading_asset)?"Loading lists":((!this.state.loading_assets&&!this.state.loading_campaign_assets&&this.state.assets.length<1)?"No lists available":"Select list")}
                            loading={this.state.loading_assets || this.state.loading_campaign_assets || this.state.loading_asset}
                            removeArrow={!this.state.loading_assets && this.state.assets.length < 1}
                            disabled={this.state.loading_assets || this.state.loading_campaign_assets || this.state.loading_asset}
                            options={this.state.assets}
                            value={this.state.asset}
                            extraItem={"count"}
                            extraItemTitle={"Negative keywords"}
                            onChange={(value) => {
                                this.functions.asset(value);
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingKeywordsNegativeAsset;
