import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from "react-spinners";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { calls } from "./calls";

import Agency from "../../../components/agency";
import AgencyTableRow from "../../../modules/agencytablerow";
import SlideOver from "../../../modules/slideover";
import AgencyUsers from "../../../modules/agencyusers";
import { tokenRegister } from "../../../services/tokenRegister";
import Input from "../../../components/input";
import cx from "classnames";
import DropdownTailwind from '../../../moduleFiles/dropdownTailwind';
import InputTailwind from '../../../moduleFiles/inputTailwind';
import { apiRegister } from '../../../services/apiRegister';

class UserAgenciesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agencies: [],
            agency: {},
            loading: true,
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.init.agencies();
        //window.scrollTo(0, 0);
    }

    init = {
        agencies: () => {
            calls.getAgencies().then((response) => {
                this.setState({
                    agencies: response.data,
                    loading: false
                })
            }, (error) => {

            });
        }
    };

    functions = {};

    renders = {};

    validators = {};

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                <SweetAlert
                    show={this.state.success_modal}
                    title="Agency removed"
                    type="success"
                    text={"Click OK"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            success_modal: false
                        })
                    }}
                />
                <div className="">
                    <div className="">
                        {
                            !this.state.loading &&
                            <div className="flex items-center justify-center mb-4">
                                <div className="flex flex-1">
                                    <div>
                                        <h3 className="mb-0 font-bold">
                                            All agencies
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            There are <span
                                                className="font-bold ml-1 mr-1">{this.state.agencies.length}</span> agencies
                                            available
                                        </p>
                                    </div>
                                </div>
                                <a href="#" onClick={(e) => {
                                    try {
                                        e.preventDefault();
                                        this.props.history.push('/user/agencies/new');
                                    } catch (e) {
                                    }
                                }} className="btn inline-block btn-primary">+ Create new</a>
                            </div>
                        }
                        {
                            !this.state.loading &&
                            <AgencyList
                                agencies={this.state.agencies}
                                onSelect={(agency) => {
                                    this.setState({
                                        agency: agency,
                                        open: true
                                    })
                                }}
                                onEdit={(agency) => {
                                    this.props.history.push('/user/agencies/' + agency.id);
                                }}
                            />
                        }
                        {
                            !this.state.loading &&
                            <AgencySlideOver
                                onClick={() => {
                                    this.setState({
                                        open: !this.state.open,
                                        agency: {}
                                    });
                                }}
                                closed={!this.state.open}
                                headline={this.state.agency.company + ' users'}
                                agency={this.state.agency}
                            />
                        }
                        {
                            this.state.loading &&
                            <div className="background-Loader">
                                <div className="justify-center align-middle flex flex-col text-center">
                                    <div className="font-bold mb-2">Loading Agencies ...</div>
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={17}
                                        color={'#060534'}
                                        loading={true}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !this.state.loading && this.state.agencies.length < 1 &&
                            <div className="background-Loader">
                                <div className="background-LoaderText">
                                    You dont have any agencies yet
                                </div>
                                <div onClick={() => this.props.history.push("/user/agencies/new")}
                                    className="background-LoaderButton">
                                    Lets get started
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

class AgencyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agencies: [],
            order: "asc",
            sort: "name"
        };
    }

    componentDidMount() {
        this.setState({
            agencies: this.props.agencies
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            agencies: nextProps.agencies
        })
    }

    render() {
        return (
            <>
                <div className="shadow-lg overflow-hidden border-b border-gray-200 rounded-lg w-full">
                    <table className="min-w-full divide-y divide-gray-200 bg-white">
                        <thead>
                            <tr>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'name',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Name <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'status',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'status' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Status <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'company',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Company info <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'test',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Type <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Users <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    <div className="flex flex-row items-center justify-end">
                                        Actions
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                this.state.agencies.sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            nameA = a[this.state.sort].value.toLowerCase();
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            nameB = b[this.state.sort].value.toLowerCase();
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <tr className="">
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                                <div className="flex items-center">
                                                    <div
                                                        className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                                                        {item.logo &&
                                                            <img className="h-10 w-10 rounded-full object-contain"
                                                                src={item.logo}
                                                                alt={item.company} />
                                                        }
                                                    </div>
                                                    <div
                                                        className="text-sm leading-5 font-bold text-gray-900 hover:text-blue-500 hover:underline cursor-pointer ml-4"
                                                        onClick={() => {
                                                            this.props.onEdit(item);
                                                        }}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap capitalize">
                                                <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                    {
                                                        ["bg-green-100 text-green-500"]: !item.status || item.status === "active",
                                                        ["bg-red-100 text-red-500 bg-opacity-50"]: item.status === "disabled",
                                                    })
                                                }>
                                                    {item.status ? item.status : "Active"}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className="text-sm leading-5 text-gray-900 capitalize">
                                                    {item.company}
                                                </div>
                                                <div className="text-xs leading-5 text-gray-500">
                                                    {item.email}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className="flex flex-row">
                                                    {
                                                        !item.test &&
                                                        <div
                                                            className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-1 px-5 rounded-full font-bold text-xs transition-all duration-200  "}>
                                                            <span>LIVE</span>
                                                        </div>
                                                    }
                                                    {
                                                        item.test &&
                                                        <div
                                                            className={"bg-gray-200 bg-opacity-25 text-gray-500 flex flex-row items-center py-1 px-5 rounded-full font-bold text-xs transition-all duration-200  "}>
                                                            <span>TEST</span>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className="flex flex-row">
                                                    <button onClick={() => {
                                                        this.props.onSelect(item);
                                                    }}
                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                        <span> Show all users</span>
                                                        <FeatherIcon className="stroke-current ml-2" size={16}
                                                            icon="arrow-right-circle" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                <div className="flex flex-row items-end justify-end">
                                                    <button onClick={() => {
                                                        this.props.onEdit(item);
                                                    }}
                                                        className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                                        <span>Edit agency</span>
                                                        <FeatherIcon className="stroke-current ml-2" size={14} icon="edit" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

class AgencySlideOver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agency: {},
        };
    };

    componentDidMount() {
        this.setState({
            agency: this.props.agency
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            agency: nextProps.agency
        })
    }

    functions = {};

    renders = {};

    render() {
        return (
            <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                style={!this.props.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                <div className="absolute inset-0 overflow-hidden">
                    <div
                        className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.props.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <div
                            className={`relative w-screen ${this.props.maxWidth && this.props.maxWidth ? this.props.maxWidth : 'max-w-2xl'} transform transition ease-in-out duration-500 sm:duration-700 ${this.props.closed ? 'translate-x-full' : 'translate-x-0'}`}
                            ref={this.props.slideOver && this.props.slideOver}>
                            <div className="absolute right-100 md:pr-4 md:pt-4 flex">
                                <button aria-label="Close panel"
                                    className="bg-red-500 text-white font-bold text-xs flex items-center rounded-full py-2 px-4 hover:bg-red-700 transition ease-in-out duration-150"
                                    onClick={this.props.onClick}
                                >
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="x" /> <span>Close</span>
                                </button>
                            </div>
                            <div
                                className={`h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll ${this.props.slideOver && this.props.rounded ? this.props.rounded : ''}`}>
                                <header className="px-4 sm:px-6">
                                    <h2 className="text-lg leading-7 font-medium text-gray-900">
                                        {this.props.headline}
                                    </h2>
                                </header>
                                <div className="relative flex-1 px-4 sm:px-6">
                                    {this.props.content}
                                    {this.state.agency.company &&
                                        <AgencySlideOverUsers
                                            item={this.state.agency}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

class AgencySlideOverUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            users: [],
            selected: null,
            manager: { id: 1, name: "No", value: false }
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentDidMount() {
        this.init.users();
    }

    init = {
        users: () => {
            calls.getUsers(this.state.item.id).then((response) => {
                this.setState({
                    users: response.data
                })
            }, (error) => {
            });
        }
    };

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.addUser({
                    name: this.state.name,
                    email: this.state.email,
                    type: 'agent',
                    agency: this.state.item.id,
                    agency_manager: this.state.manager.value
                }).then((response) => {
                    if (response.data) {
                        this.state.users.push(response.data);
                    } else {
                        this.state.users.push(response);
                    }
                    this.setState({
                        users: this.state.users,
                        loading: false,
                        new: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: error.body.message
                    })
                });
            });
        },
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false
            }, () => {
                calls.removeUser(this.state.selected).then((response) => {
                    let users = this.state.users.map((item) => {
                        item.id = item._id;
                        return item;
                    }).filter((item) => {
                        return item.id !== this.state.selected;
                    });
                    this.setState({
                        loading: false,
                        selected: null,
                        users: users
                    })
                }, (error) => {
                    this.setState({
                        selected: null,
                        error_modal: true,
                        loading: false,
                        error_message: error.body.message
                    });
                });
            })
        },
        open: (item) => {
            if (!this.state.loading && this.state.selected !== item.id) {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/login/" + item);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/login/" + item);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/login/" + item);
                }
            }
        },
        update: async (item) => {
            item.loading = true;
            await this.promisedSetState({
                users: this.state.users
            });
            item.agencyManager = !item.agencyManager;
            try {
                let response = await this.calls.update({ id: item.id, agencyManager: item.agencyManager });
            } catch (error) {
                item.agencyManager = !item.agencyManager;
            }
            item.loading = false;
            await this.promisedSetState({
                users: this.state.users
            });
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgent?id=" + data.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove user"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({
                            warning_modal: false,
                            selected: null
                        });
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div>
                    {this.state.users.length > 0 &&
                        <div className="agency-user__block">
                            {
                                this.state.users.map((item) => {
                                    item.id = item._id;
                                    return item;
                                }).map((item, j) => {
                                    return (
                                        <div className="agency-user__block--inner py-3 border-b relative" key={j}>
                                            {
                                                item.loading &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            <div className="flex flex-row flex-wrap justify-between">
                                                <div>
                                                    <div className="font-bold text-sm capitalize">{item.name}{item.agencyManager && <span className="text-purple-500 ml-2">Manager</span>}</div>
                                                    <div className="text-xs">{item.email}</div>
                                                </div>
                                            </div>
                                            <div className="flex flex-row mt-2">
                                                {
                                                    item.status === 'pending' && !this.state.loading && this.state.selected !== item.id &&
                                                    <div className="mb-2 mr-2 md:mb-0">
                                                        <button onClick={() => {
                                                            //console.log(item);
                                                            if (window.location.hostname === 'localhost') {
                                                                window.open("http://localhost:5001/set-password/" + item.resetPasswordToken);
                                                            } else if (window.location.hostname === 'app.adcredo.io') {
                                                                window.open("https://app.adcredo.io/set-password/" + item.resetPasswordToken);
                                                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                window.open("https://dev.adcredo.io/set-password/" + item.resetPasswordToken);
                                                            }
                                                        }}
                                                            className="bg-transparent bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                                            <FeatherIcon className="stroke-current mr-1" size={16} icon="lock" />
                                                            <span>Password</span>
                                                        </button>
                                                    </div>
                                                }
                                                <div className="mb-2 mr-2 md:mb-0">
                                                    <button onClick={() => {
                                                        if (!this.state.loading && this.state.selected !== item.id) {
                                                            this.setState({
                                                                selected: item.id,
                                                                warning_modal: true
                                                            })
                                                        }
                                                    }}
                                                        className="bg-transparent bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                        <FeatherIcon className="stroke-current mr-1" size={16}
                                                            icon="trash" /> <span>Delete user</span>
                                                    </button>
                                                </div>
                                                <div className="flex flex-1" />
                                                {
                                                    <div className="mb-2 mr-2 md:mb-0">
                                                        <button onClick={() => {
                                                            this.functions.update(item);
                                                        }}
                                                            className="bg-transparent bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white">
                                                            {
                                                                !item.agencyManager &&
                                                                <span>Uppgrade to Manager</span>
                                                            }
                                                            {
                                                                item.agencyManager &&
                                                                <span>Downgrade to Agent</span>
                                                            }
                                                        </button>
                                                    </div>
                                                }
                                                <div className="md:ml-2">
                                                    <button onClick={() => {
                                                        this.functions.open(item.id);
                                                    }}
                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                        <span>Login as user</span>
                                                        <FeatherIcon className="stroke-current ml-2" size={16}
                                                            icon="arrow-right-circle" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        !this.state.new &&
                        <div className="mt-4">
                            <div onClick={() => {
                                this.setState({
                                    new: true
                                })
                            }}
                                className="py-3 px-4 bg-green-100 text-green-600 transition-all duration-200 hover:bg-green-500 hover:text-white rounded-full font-bold w-full text-sm text-center">
                                + Create new user
                            </div>
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="grid grid-cols-6 col-gap-4 row-gap-4">
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Name"}
                                    value={this.state.name}
                                    onChange={(value) => {
                                        this.setState({
                                            name: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Email"}
                                    value={this.state.email}
                                    onChange={(value) => {
                                        this.setState({
                                            email: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Manager"}
                                    selected={{ id: 1, name: "No", value: false }}
                                    options={[
                                        { id: 1, name: "No", value: false },
                                        { id: 2, name: "Yes", value: true }
                                    ]}
                                    onChange={(value) => {
                                        this.setState({
                                            manager: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="Section">
                            <div onClick={() => {
                                if (!this.state.loading) {
                                    this.functions.create()
                                }
                            }} className="Button ButtonFull">
                                {
                                    !this.state.loading &&
                                    <div>Add user</div>
                                }
                                {
                                    this.state.loading &&
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={12}
                                        color={'#ffffff'}
                                        loading={true}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default UserAgenciesList;
