import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import SlideDown from 'react-slidedown';

class Changelog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                logs: true,
            },
            error: { message: "", show: false },
            client: { id: 0 },
            order: {},
            sort: "asc",
            logs: [],
            meta: {},
            page: 1,
            limit: 10,
            search: "",
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                },
                {
                    multiSelect: true,
                    maxWidth: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 0, name: "All channels (default)", value: "all" },
                    options: [],
                    selected: []
                },
            ],
            log_index: null,
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getExternalLogs();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });

    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getExternalLogs: async () => {
            return new Promise(async (resolve) => {
                this.state.loading.logs = true;
                await this.promisedSetState({
                    loading: this.state.loading
                });
                try {
                    let response;
                    let id = window.location.pathname.split('/')[3];
                    if (id !== "") {
                        response = await this.calls.getExternalLogs(id, "order");

                        if (response.data && response.data.length > 0) {
                            this.state.logs = response.data;
                            let channels = [
                                { id: 1, name: "All channels (default)", value: "all" },
                                { id: 2, name: "Adcredo", value: "adcredo" }
                            ]
                            response.data.map((log) => {
                                if (log.channel && log.channel !== "" && log.channel !== "adcredo" && log.channel !== "linkedin" && log.channel !== "tiktok" && log.channel !== "twitter") {
                                    if (!channels.some(channel => channel.value === log.channel.toLowerCase())) {
                                        channels.push({ id: channels.length + 1, name: log.channel.charAt(0).toUpperCase() + log.channel.slice(1), value: log.channel.toLowerCase() });
                                    }
                                }
                            })
                            this.state.advancedFilters[1].options = channels;
                        }

                        let order_data = await this.calls.getOrder(id)
                        if (order_data.data && Object.keys(order_data.data).length > 0) {
                            this.state.order = order_data.data;
                        }

                        await this.promisedSetState({
                            logs: this.state.logs,
                            advancedFilters: this.state.advancedFilters,
                            order: this.state.order,
                            ...(order_data.data.client && { client: order_data.data.client })
                        });

                        if (this.props.setOrder) {
                            this.props.setOrder(this.state.order);
                        }

                        this.functions.calcTotal();
                        this.functions.sortLogs();

                    }
                } catch (error) {
                    console.log(error)
                    this.state.error.message = error.message;
                    this.state.error.show = true;
                    await this.promisedSetState({
                        error: this.state.error
                    });
                }
                this.state.loading.logs = false;
                await this.promisedSetState({
                    loading: this.state.loading
                });
                resolve();
            });
        },
        sortLogs: async () => {
            await this.promisedSetState({
                sort: this.state.sort === "asc" ? "desc" : "asc"
            });

            let sortedLogs = [...this.state.logs];

            if (this.state.sort === "asc") {
                sortedLogs.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });
            } else {
                sortedLogs.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });
            }

            await this.promisedSetState({
                logs: sortedLogs
            });
        },
        calcTotal: async () => {
            if (Array.isArray(this.state.logs) && this.state.logs.length > 0) {
                let filteredLogs = this.state.logs;

                if (this.state.advancedFilters[1].selected.length > 0) {
                    filteredLogs = filteredLogs.filter((item) => Array.isArray(this.state.advancedFilters[1].selected) && this.state.advancedFilters[1].selected.length > 0 ? this.state.advancedFilters[1].selected.map(option => option.value && option.value.toLowerCase()).some(value => item.channel && value === item.channel.toLowerCase()) : true);
                }

                if (this.state.search) {
                    filteredLogs = filteredLogs.filter((log) => Object.values(log).some(value => String(value).toLowerCase().includes(this.state.search.toLowerCase())));
                }

                this.state.meta.total = filteredLogs.length;
            }

            await this.promisedSetState({
                meta: this.state.meta
            })
        },
    }

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        getExternalLogs: (id, type) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExternalChangelog?order=" + id + "&type=" + type;
            return apiRegister.call(options, url);
        },
        getInternalLogs: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getInternalChangelog?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        getOrder: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrder?id=" + id;
            return apiRegister.call(options, url);
        }
    }

    renders = {
        time: (log) => {
            try {
                if (log.date) {
                    return moment(log.date).format("HH:mm");
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        date: (log) => {
            try {
                if (log.date) {
                    return moment(log.date).format("DD/MM/YYYY");
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        user: (log) => {
            try {
                if (log.user) {
                    return log.user.charAt(0).toUpperCase() + log.user.slice(1);
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        icon: (log) => {
            try {
                if (log.channel) {
                    return (
                        <div>
                            {
                                log.channel === "google" &&
                                <div
                                    className="bg-google-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "google_shopping" &&
                                <div
                                    className="bg-googleshopping-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google-shopping.png')} />
                                </div>
                            }
                            {
                                log.channel === "linkedin" &&
                                <div
                                    className="bg-linkedin-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/linkedin_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "facebook" &&
                                <div
                                    className="bg-facebook-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img style={{ width: '0.4rem' }}
                                        src={require('../assets/images/facebook_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "google_analytics" &&
                                <div
                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/google_analytics_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "google_analytics_4" &&
                                <div
                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/ga_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "adform" &&
                                <div
                                    className="bg-adform-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/adform_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "twitter" &&
                                <div
                                    className="bg-twitter-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/twitter_icon.svg')} />
                                </div>
                            }
                            {
                                log.channel === "tiktok" &&
                                <div
                                    className="bg-tiktok-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/tiktok_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "bidtheatre" &&
                                <div
                                    className="bg-bidtheatre-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/bidtheatre_icon.png')} />
                                </div>
                            }
                            {
                                log.channel === "snapchat" &&
                                <div
                                    className="bg-snapchat-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/snapchat.svg')} />
                                </div>
                            }
                            {
                                log.channel === "bing" &&
                                <div
                                    className="bg-bing-500 mr-2 h-8 w-8 rounded-full flex justify-center align-middle items-center">
                                    <img className="w-3"
                                        src={require('../assets/images/bing-logo.png')} />
                                </div>
                            }
                            {
                                log.channel === "adcredo" &&
                                <div
                                    className="bg-white mr-2 rounded-full flex justify-center align-middle items-center">
                                    <img style={{ width: '2rem' }}
                                        src={require('../assets/images/adcredo_icon_purple.png')} />
                                </div>
                            }
                        </div>
                    )
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        channel: (log) => {
            try {
                if (log.channel) {
                    return log.channel.charAt(0).toUpperCase() + log.channel.slice(1);
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objectType: (log) => {
            try {
                if (log.object_type) {
                    let buff = log.object_type.replace(/_/g, " ");
                    return buff.charAt(0).toUpperCase() + buff.slice(1).toLowerCase();
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objectName: (log) => {
            try {
                if (log.object_name) {
                    return log.object_name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objectId: (log) => {
            try {
                if (log.id) {
                    return log.id;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        action: (log) => {
            // if (log.action === "remove") {
            //     console.log("REMOVED", log)
            // }
            try {
                if (log.action) {
                    return log.action.charAt(0).toUpperCase() + log.action.slice(1).toLowerCase();
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        details: (log) => {
            try {
                if (!log.internal) {
                    if (log.details) {
                        let raw = JSON.parse(log.details);
                        let changes = []
                        if (raw.type === "run_status" && typeof raw.old_value === "string" && typeof raw.new_value === "string") {
                            changes.push(<span className="text-xs">Old: {raw.old_value.charAt(0).toUpperCase() + raw.old_value.toLowerCase().slice(1)}, New: {raw.new_value.charAt(0).toUpperCase() + raw.new_value.toLowerCase().slice(1)}</span>);
                        } else if (raw.old_value && raw.new_value === null) {
                            changes.push(<span className="text-xs">Old: {((raw.old_value) / 100)}, New: Removed</span>);
                        } else if (raw.old_value === "" && raw.new_value) {
                            changes.push(<span className="text-xs">New: {raw.new_value}</span>);
                        } else if (!raw.old_value && raw.new_value) {
                            let buff = raw.new_value.replace(/_/g, " ");
                            buff = buff.charAt(0).toUpperCase() + buff.slice(1).toLowerCase();
                            changes.push(<span className="text-xs">New: {buff}</span>);
                        } else if (raw && raw.new_value && raw.new_value.currency) {
                            if (raw.new_value.new_value && raw.old_value.old_value) {
                                changes.push(<span className="text-xs">Old: {((raw.old_value.old_value) / 100)}, New: {(raw.new_value.new_value / 100)}</span>);
                            } else if (raw.new_value.new_value && !raw.old_value) {
                                changes.push(<span className="text-xs">New: {raw.new_value.new_value}</span>);
                            }
                        } else if (raw.old_value && raw.new_value) {
                            let buff_old = raw.old_value.replace(/_/g, " ");
                            buff_old = buff_old.charAt(0).toUpperCase() + buff_old.slice(1).toLowerCase();
                            let buff_new = raw.new_value.replace(/_/g, " ");
                            buff_new = buff_new.charAt(0).toUpperCase() + buff_new.slice(1).toLowerCase();
                            changes.push(<span className="text-xs">Old: {buff_old}, New: {buff_new}</span>);
                        } else if (raw.campaign_id) {
                            changes.push(<span className="text-xs">Old campaign ID: {raw.campaign_id.mutation_input}, new campaign ID: {raw.campaign_id.new}</span>);
                        } else {
                            return "";
                        }

                        let first_two = changes.slice(0, 2);

                        return {
                            first_two,
                            changes,
                            seeMore: changes.length > 2 ? <span className='text-sm text-gray-600 whitespace-no-wrap'>See more ...</span> : null
                        };

                    } else {
                        return "";
                    }
                } else {
                    let raw = JSON.parse(log.details);
                    let changes = raw
                        .filter(change => change.object_type !== 'loading')
                        .map((change, index) => {
                            if (log.action === "remove") {
                                if (change.object_type === "keyword (adgroup)" || change.object_type === "keyword (campaign)") {
                                    return (
                                        <div key={index}>
                                            <div className="text-xs"><span className='font-semibold'>Removed: </span>{change.new_value}</div>
                                        </div>
                                    );
                                    // REMOVED BUDGET ITEM
                                } else if (change.old_value.budget) {
                                    return (
                                        <div key={index}>
                                            <div className="text-xs"><span className='font-semibold'>Removed: </span>{change.old_value.reference} (budget item)</div>
                                        </div>
                                    );
                                }
                            }
                            let old_value = typeof change.old_value === 'string' ? change.old_value.charAt(0).toUpperCase() + change.old_value.toLowerCase().slice(1) : change.old_value;
                            let new_value = typeof change.new_value === 'string' ? change.new_value.charAt(0).toUpperCase() + change.new_value.toLowerCase().slice(1) : JSON.stringify(change.new_value);
                            let objectType = change.object_type.replace(/_/g, ' ');
                            let changeString = (
                                <div className='text-xs'>
                                    <span className="font-semibold">{objectType && objectType.charAt(0).toUpperCase() + objectType.toLowerCase().slice(1) + ": "}</span>
                                    {
                                        change.old_value !== null &&
                                        <span> Old: {String(old_value)}, </span>
                                    }
                                    {
                                        change.new_value !== null &&
                                        <span className="">New: {String(new_value)}</span>
                                    }
                                </div>
                            );
                            return (
                                <div key={index}>
                                    {changeString}
                                </div>
                            );
                        });

                    let first_two = changes.slice(0, 2);

                    return {
                        first_two,
                        changes,
                        see_more: changes.length > 2 ? <span className='text-xs text-gray-600 whitespace-no-wrap'>See more ...</span> : null
                    };

                }
            } catch (error) {
                return "";
            }
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='h-screen w-full'>
                <TopNavigationAgent
                    showClient={true}
                    onAdvancedFilter={async (value, type) => {
                        this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                            if (item.type === type) {
                                item.selected = value;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            page: 1,
                            limit: 10,
                            advancedFilters: this.state.advancedFilters,
                        });
                        this.functions.calcTotal();
                        if (type === "orders") {
                            let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                            let path = window.location.pathname.replace(id, value.id);
                            window.open(path, "_self");
                        }
                    }}
                    onLimit={async (value) => {
                        await this.promisedSetState({
                            page: 1,
                            limit: value
                        })
                    }}
                    onNext={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                    }}
                    onPrevious={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                    }}
                    onSearch={async (value) => {
                        await this.promisedSetState({
                            search: value
                        })
                        this.functions.calcTotal();
                    }}
                    showAdvanced={true}
                    advancedFilter={true}
                    advancedFilters={this.state.advancedFilters}
                    limit={this.state.limit}
                    page={this.state.page}
                    total={this.state.meta ? this.state.meta["total"] : 0}
                    showPaginaton={true}
                    showPaginationSearch={true}
                    showFilters={!this.state.loading}
                    placeholder={"Search ..."}
                    showMessage={true}
                    message={"LinkedIn, TikTok and Twitter logs are currently not supported."}
                    lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                />

                {
                    this.state.loading.logs &&
                    <div className='min-h-screen flex flex-col justify-center items-center w-full'>
                        <div style={{ borderTopColor: "transparent" }} className='w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin'></div>
                        <div className='font-semibold mt-3'>Loading...</div>
                    </div>
                }

                <div className='p-4'>
                    <div className='bg-white shadow-lg rounded-md'>

                        {
                            !this.state.loading.logs && this.state.logs.length === 0 &&
                            <div className='flex items-center justify-center font-medium text-gray-500 p-4'>
                                No logs to show
                            </div>
                        }

                        {
                            this.state.logs.length !== 0 &&
                            <>
                                <div className='table-overflow'>
                                    <table className='min-w-full divide-y divide-gray-300 border-gray-300'>
                                        <thead className=''>
                                            <tr className='select-none'>
                                                <th
                                                    onClick={async () => {
                                                        this.functions.sortLogs();
                                                    }}
                                                    style={{ width: 300, minWidth: 300 }}
                                                    scope="col" className='hover:bg-gray-100 bg-gray-100 hover:bg-opacity-75 px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider cursor-pointer border-r'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='text-xs font-medium text-gray-700'>User/Date</div>
                                                        <ArrowDownIcon className={`w-4 transform ${this.state.sort === "asc" && "rotate-180"}`} />
                                                    </div>
                                                </th>
                                                <th
                                                    style={{ minWidth: 200, width: 200 }}
                                                    scope="col" className='px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider border-r'>
                                                    Channel
                                                </th>
                                                <th
                                                    style={{ minWidth: 300, width: 300 }}
                                                    scope="col" className='px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider border-r'>
                                                    Item
                                                </th>
                                                <th scope="col" className='px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider'>
                                                    Change
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='divide-y divide-gray-300 w-full'>
                                            {
                                                this.state.logs
                                                    .filter((item) => Array.isArray(this.state.advancedFilters[1].selected) && this.state.advancedFilters[1].selected.length > 0 ? this.state.advancedFilters[1].selected.map(option => option.value && option.value.toLowerCase()).some(value => item.channel && value === item.channel.toLowerCase()) : true)
                                                    .filter((log) => {
                                                        if (this.state.search) {
                                                            return Object.values(log).some(value => String(value).includes(this.state.search));
                                                        }
                                                        return true;
                                                    })
                                                    .slice((this.state.page - 1) * this.state.limit, this.state.page * this.state.limit)
                                                    .map((log, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <tr className='border-b'>
                                                                    <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 border-r bg-gray-50'>
                                                                        <div className='flex flex-col text-sm'>
                                                                            <span>{this.renders.user(log)}</span>
                                                                            <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                Source: {log.internal ? "Adcredo" : log.channel.charAt(0).toUpperCase() + log.channel.slice(1)}
                                                                            </span>
                                                                            <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                {moment(log.date).format("DD/MM/YYYY HH:mm")}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 border-r'>
                                                                        <div className='flex items-center'>
                                                                            {this.renders.icon(log)}
                                                                            {this.renders.channel(log)}
                                                                        </div>
                                                                    </td>
                                                                    <td className='px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 truncate border-r'>
                                                                        <div className='flex flex-col truncate'>
                                                                            <span className='truncate'>
                                                                                {this.renders.objectName(log)}
                                                                            </span>
                                                                            <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                {this.renders.objectType(log)}
                                                                            </span>
                                                                            <span className='text-sm text-gray-600 whitespace-no-wrap truncate'>
                                                                                {/* ID: {this.renders.objectId(log)} */}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        onClick={async () => {
                                                                            if (!this.renders.details(log).see_more) {
                                                                                return
                                                                            }
                                                                            if (this.state.log_index === index) {
                                                                                this.state.log_index = null;
                                                                            } else {
                                                                                this.state.log_index = index;
                                                                            }
                                                                            await this.promisedSetState({
                                                                                log_index: this.state.log_index
                                                                            })
                                                                        }}
                                                                        className={`px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 ${this.renders.details(log).see_more && "cursor-pointer"}`}>
                                                                        {
                                                                            this.state.log_index !== index ?
                                                                                <>
                                                                                    <div className='flex flex-col'>
                                                                                        <span>
                                                                                            {this.renders.action(log)}
                                                                                        </span>
                                                                                        <span className='text-sm text-gray-600'>
                                                                                            {this.renders.details(log).first_two}
                                                                                            {this.renders.details(log).see_more}
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <SlideDown closed={this.state.log_index !== index ? true : false}>
                                                                                        <div className='flex flex-col'>
                                                                                            <span>
                                                                                                {this.renders.action(log)}
                                                                                            </span>
                                                                                            <span className='text-sm text-gray-600'>
                                                                                                {this.renders.details(log).changes}
                                                                                            </span>
                                                                                        </div>
                                                                                    </SlideDown>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>

                                </div>

                                {/* PAGINATION */}
                                {
                                    <div className="flex p-4 items-center border-t">
                                        <div className="flex justify-center items-center">
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    if (this.state.page !== 1) {
                                                        await this.promisedSetState({
                                                            page: this.state.page - 1
                                                        });
                                                    }
                                                }}
                                                className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                Previous
                                            </button>
                                        </div>
                                        <div className="flex flex-1 items-center justify-center">
                                            {
                                                (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                    <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                                </p>
                                            }
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }


                            </>
                        }

                    </div>

                </div>
            </div >
        );
    }
}

export default Changelog;
