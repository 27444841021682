import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';

class CreateFacebookPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            campaign: {},
            updated: {},
            client: {},
            loader: false,
        }
    };

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            client: this.props.client
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client
        })
    }

    functions = {
        update: async () => {
            let body = {
                ...(this.state.updated.name && this.state.updated.name !== this.state.campaign.name && { name: this.state.updated.name }),
                ...(this.state.updated.daily_budget && this.state.updated.daily_budget !== this.state.campaign.daily_budget && { daily_budget: +this.state.updated.daily_budget * 100 })
            };
            this.props.onLoader(true);
            try {
                let response = await this.calls.update(body);
                this.props.onSubmit(this.state.updated);
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            this.props.onLoader(false);
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="mt-5 grid grid-cols-3 gap-4">
                    <div className="col-span-3">
                        <TextAreaTailwind
                            label={"Campaign name"}
                            value={!this.state.updated.text ? this.state.campaign.text : this.state.updated.name}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default CreateFacebookPost;
