import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import ClientAudience from "../client-audience";
import TextArea from "../text-area";
import ClientConversionAction from "../client-conversionaction";

class ClientGooglePixel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            pixel: {},
            pixels: [],
            snippets: {},
            loading: true,
            error_modal: false,
            error_message: ""
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            item: this.props.item
        }, () => {
            this.functions.getSnippets();
        })
    }

    init = {};

    functions = {
        getSnippets: () => {
            calls.getSnippets({channel: 'google', client: this.state.client}).then((response) => {
                this.setState({
                    snippets: response.data,
                    loading: false
                });
            }, (error) => {
                this.setState({
                    loading: false
                })
            });
        },
    };

    renders = {};

    render() {
        return (
            <div>
                <div className="bg-white rounded-md px-4 py-4">
                    {
                        <div style={{width: '100%', position: 'relative'}}>
                            <TextArea
                                clickToCopy={true}
                                title={"Pixel code (click to copy)"}
                                value={this.state.snippets.html}
                            />
                            {
                                this.state.loading &&
                                <div className="Pixel-loader">
                                    Loading pixel code
                                </div>
                            }
                            {
                                !this.state.loading && !this.state.snippets.html &&
                                <div className="Pixel-loader">
                                    No pixel available
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.client && false &&
                        <ClientConversionAction
                            item={this.state.item}
                            client={this.state.client}
                            channel={'google'}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default ClientGooglePixel;
