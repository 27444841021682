import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';

class AgencyMonthlySpend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            agency: {},
            type: {},
            overspend: {},
            year: {},
            month: {}
        };
    }

    componentDidMount() {
        this.setState({
            agency: this.props.agency
        })
    }

    functions = {
        run: () => {
            if (this.state.type.value == "update") {
                this.functions.updateSpend();
            } else if (this.state.type.value == "get") {
                this.functions.getSpend();
            }
        },
        updateSpend: async () => {
            this.props.onLoading(true);
            this.setState({
                error: false,
                success: false,
                json: false
            });
            try {
                let response = await this.calls.updateSpend();
                this.props.onLoading(false);
                this.setState({
                    success: true
                });
            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        },
        getSpend: async () => {
            this.props.onLoading(true);
            this.setState({
                error: false,
                success: false,
                json: false
            });
            try {
                let response = await this.calls.getSpend();
                if (response.data.length < 1) {
                    this.setState({
                        error: "Empty response"
                    });
                } else {
                    this.setState({
                        json: JSON.stringify(response.data, null, 2)
                    });
                    this.functions.exportToCSv(response.meta ? response.meta.headers : {}, response.data);
                }
                this.props.onLoading(false);
            } catch (error) {
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        },
        exportToCSv: (headers, items) => {

            var fileTitle = this.state.agency.name + "_" + this.state.year.value + "_" + this.state.month.value + "_" + (this.state.overspend.value ? "overspend" : "spend");

            exportCSVFile(headers, items, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        }
    };

    calls = {
        updateSpend: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/updateMonthlySpend?agency=" + this.state.agency.id + "&year=" + this.state.year.value + "&month=" + this.state.month.value;
            return apiRegister.call(options, url);
        },
        getSpend: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getMonthlySpend?agency=" + this.state.agency.id + "&year=" + this.state.year.value + "&month=" + this.state.month.value + (this.state.overspend.value ? "&overSpend=true" : "")
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col h-full">
                    <div className="flex flex-1 h-full">
                        <div className="w-full h-full flex flex-col">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className={this.state.type.value !== "get" ? "col-span-6" : "col-span-3"}>
                                    <DropdownTailwind
                                        label={"Update/Get"}
                                        selected={this.state.type}
                                        options={[
                                            { id: 1, name: "Update", value: "update" },
                                            { id: 2, name: "Get", value: "get" }
                                        ]}
                                        onChange={(value) => {
                                            this.setState({
                                                type: value,
                                                overspend: { id: 2, name: "No", value: false },
                                                json: false
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.type.value == "get" &&
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Overspend report ?"}
                                            selected={this.state.overspend}
                                            options={[
                                                { id: 1, name: "Yes", value: true },
                                                { id: 2, name: "No", value: false }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    overspend: value,
                                                    json: false
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Year"}
                                        selected={this.state.year}
                                        options={[
                                            { id: 1, name: "2024", value: "2024" },
                                            { id: 1, name: "2023", value: "2023" },
                                            { id: 2, name: "2022", value: "2022" }
                                        ]}
                                        onChange={(value) => {
                                            this.setState({
                                                year: value,
                                                json: false
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Month"}
                                        selected={this.state.month}
                                        options={[
                                            { id: 1, name: "Jan", value: 0 },
                                            { id: 2, name: "Feb", value: 1 },
                                            { id: 3, name: "Mar", value: 3 },
                                            { id: 4, name: "Apr", value: 4 },
                                            { id: 5, name: "May", value: 5 },
                                            { id: 6, name: "Jun", value: 6 },
                                            { id: 7, name: "Jul", value: 7 },
                                            { id: 8, name: "Aug", value: 8 },
                                            { id: 9, name: "Sep", value: 9 },
                                            { id: 10, name: "Okt", value: 10 },
                                            { id: 11, name: "Nov", value: 11 },
                                            { id: 12, name: "Dec", value: 12 }
                                        ]}
                                        onChange={(value) => {
                                            this.setState({
                                                month: value,
                                                json: false
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.success &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-blue-100 p-4 rounded-md">
                                            <div className="text-sm text-blue-500">
                                                Update has started, check Slack for notification about update.
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                this.state.json &&
                                <div className={"flex flex-1 mt-4 pb-4 w-full overflow-hidden"}>
                                    <div className="border-1.5 h-full w-full overflow-hidden rounded-md">
                                        <pre className="h-full w-full overflow-scroll">
                                            {this.state.json}
                                        </pre>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AgencyMonthlySpend;