import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import FeatherIcon from 'feather-icons-react';
import Dropdown from "../../components/dropdown";
import CampaignDonuts from "../../components/campaign-donuts";
import CampaignOverview from "../../components/campaign-overview";
import CampaignEdit from "../../components/campaign-edit";
import CampaignDescription from "../../components/campaign-description";
import CampaignShortcuts from "../../components/campaign-shortcuts";
import ClientDescription from "../../components/client-description";
import ClientOverview from "../../components/client-overview";

class UserClientItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            view: 'overview'
        };
    };

    componentWillMount() {
        this.init.client(this.props.match.params.id);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {
        client: (id) => {
            this.setState({
                loading: true
            }, () => {
                calls.getClient(id).then((response) => {
                    this.setState({
                        data: response.data,
                        loading: false
                    })
                }, (error) => {

                });
            })
        }
    };

    functions = {};

    renders = {};

    render() {
        return (
            <div className="w-full pt-8 pb-8">
                {!this.state.loading &&
                    <ClientOverview
                        client={this.state.data?this.state.data:{}}
                        loading={this.state.loading}
                        view={this.state.view}
                        history={this.props.history}
                    />
                }
                {
                    this.state.loading &&
                    <div className="mt-48 mb-48">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Client ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default UserClientItem;
