import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import {SlideDown} from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesAdsItem from "../advertising-creatives-ads-item";
import {calls} from "./calls";

class AdvertisingCreativesPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {},
            client: {},
            site: {},
            creatives: {},
            languages: [],
            posts: [],
            errors: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            site: nextProps.site,
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            languages: nextProps.languages,
            adset: nextProps.adset,
            posts: nextProps.posts?nextProps.posts:[],
            errors: nextProps.errors?nextProps.errors:[]
        }, () => {
            console.log(this.state.adset, "creative-Posts");
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
            channels: this.props.channels,
            client: this.props.client,
            site: this.props.site,
            adset: this.props.adset,
            posts: this.props.posts?this.props.posts:[],
            creatives: this.props.creatives,
            errors: this.props.errors?this.props.errors:[]
        }, () => {
            console.log(this.state.adset, "creative-Posts");
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    <div className="flex flex-1 flex-row items-center pt-4">
                        <div className="bg-white rounded-md overflow-hidden w-full flex-1">
                            <div className="px-4 py-4 flex-row flex">
                                <div
                                    className="h-10 mr-4 bg-purple-100 text-purple-500 rounded w-10 flex justify-center align-middle items-center">
                                    <FeatherIcon className="stroke-current" size={16} icon="tv"/>
                                </div>
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        Sponsored post ads
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                <span
                                                    className="font-bold mr-1">{this.state.posts.length}</span> available
                                    </p>
                                </div>
                                <div className="flex flex-row">
                                    <button onClick={() => {
                                        try{
                                            this.props.createPost();
                                        }catch (e) {}
                                    }} className="bg-purple-500 text-2xl relative text-white h-10 w-10 flex justify-center items-center rounded">
                                        +
                                    </button>
                                    {
                                        this.state.posts.length > 0 &&
                                        <button
                                            className="bg-gray-200 text-gray-900 ml-2 h-10 w-10 flex justify-center items-center rounded"
                                            onClick={() => {
                                                this.setState({
                                                    open_dynamic: !this.state.open_dynamic
                                                })
                                            }}>
                                            {
                                                !this.state.open_dynamic &&
                                                <FeatherIcon className="stroke-current" size={16}
                                                             icon={"chevron-down"}/>
                                            }
                                            {
                                                this.state.open_dynamic &&
                                                <FeatherIcon className="stroke-current" size={16}
                                                             icon={"chevron-up"}/>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                            <SlideDown closed={this.state.open_dynamic}>
                                {
                                    this.state.posts.length > 0 &&
                                    <div className="w-full border-t"/>
                                }
                                <div className="">
                                    {
                                        this.state.posts.map((item, index) => {
                                            return (
                                                <AdvertisingCreativesAdsItem
                                                    last={index === (this.state.posts.length - 1)}
                                                    item={item}
                                                    index={index}
                                                    post={true}
                                                    errors={this.state.errors}
                                                    catalogs={[]}
                                                    creatives={this.state.creatives}
                                                    channels={this.state.channels}
                                                    adset={this.state.adset}
                                                    content={this.state.creatives.post_ads}
                                                    languages={this.state.languages}
                                                    client={this.state.client}
                                                    onChange={(value) => {
                                                        this.state.creatives.post_ads = value;
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.props.onChange(this.state.creatives);
                                                        })
                                                    }}
                                                    updateCreatives={(value) => {
                                                        this.setState({
                                                            creatives: value
                                                        }, () => {
                                                            this.props.onChange(this.state.creatives);
                                                        })
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </SlideDown>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesPosts;
