import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import { SlideDown } from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesAdsItem from "../advertising-creatives-ads-item";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ErrorAlert from "../../modules/error-alert";
import AdvertisingCreativesEvents from '../advertising-creatives-events';
import AdvertisingCreativesPosts from '../advertising-creatives-posts';

class AdvertisingCreativesAds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {},
            client: {},
            site: {},
            creatives: {},
            adset: {},
            languages: [],
            errors: [],
            campaign: {}
        };
        this.child = React.createRef();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            site: nextProps.site,
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            campaign: nextProps.campaign,
            adset: nextProps.adset,
            languages: nextProps.languages,
            errors: nextProps.errors ? nextProps.errors : []
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
            channels: this.props.channels,
            client: this.props.client,
            site: this.props.site,
            adset: this.props.adset,
            campaign: this.props.campaign,
            creatives: this.props.creatives,
            errors: this.props.errors ? this.props.errors : []
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        images: () => {
            try {
                console.log(this.state.creatives.image_ads);
                return this.state.creatives.image_ads ? this.state.creatives.image_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                console.log(e);
                return []
            }
        },
        videos: () => {
            try {
                return this.state.creatives.video_ads ? this.state.creatives.video_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        carousels: () => {
            try {
                return this.state.creatives.carousel_ads ? this.state.creatives.carousel_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        dynamic: () => {
            try {
                return this.state.creatives.dynamic_ads ? this.state.creatives.dynamic_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        event: () => {
            try {
                return Object.keys(this.state.campaign.budget).filter((channel) => { return channel === "facebook_instagram" && this.state.campaign.budget[channel].focus && this.state.campaign.budget[channel].focus.value === "EVENT_RESPONSES" }).length > 0;
            } catch (e) {
                return false
            }
        },
        events: () => {
            try {
                return this.state.creatives.event_ads ? this.state.creatives.event_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        posts: () => {
            try {
                return this.state.creatives.post_ads ? this.state.creatives.post_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }) : [];
            } catch (e) {
                return []
            }
        },
        all: () => {
            try {
                let videos = this.state.creatives.video_ads ? this.state.creatives.video_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }).length : 0;
                let images = this.state.creatives.image_ads ? this.state.creatives.image_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }).length : 0;
                let carousels = this.state.creatives.carousel_ads ? this.state.creatives.carousel_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id === this.state.adset.id
                }).length : 0;
                let total = videos + images + carousels;
                return total;
            } catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div style={{ width: '100%' }}>
                {
                    <div style={{ width: '100%' }} className="Section-Inner">
                        {
                            (this.state.channels.facebook || this.state.channels.instagram) && this.renders.event() &&
                            <AdvertisingCreativesEvents
                                channels={this.state.channels}
                                error={this.state.error}
                                errors={this.state.errors}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                events={this.renders.events()}
                                adset={this.state.adset}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }}
                                createEvent={() => {
                                    this.child.current.functions.createEventAd();
                                }}
                            />
                        }
                        {
                            this.renders.dynamic().length < 1 &&
                            <div className="flex flex-1 flex-row items-center pt-4">
                                <div className="bg-white rounded-md overflow-hidden w-full flex-1">
                                    <div className="px-4 py-4 flex-row flex">
                                        <div
                                            className="h-10 mr-4 bg-purple-100 text-purple-500 rounded w-10 flex justify-center align-middle items-center">
                                            <FeatherIcon className="stroke-current" size={16} icon="video" />
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="mb-0 font-bold relative">
                                                Video ads
                                                {
                                                    Array.isArray(this.state.errors) && this.state.errors.length > 0 &&
                                                    this.state.errors.filter((error) => {
                                                        return this.renders.videos().filter((item) => {
                                                            return error.ad === item.id
                                                        }).length > 0
                                                    }).length > 0 &&
                                                    <div style={{ left: "92px", top: "-1px" }}
                                                        className={"bg-gray-100 close mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15} icon="alert-circle" />
                                                    </div>
                                                }
                                            </h3>
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                <span
                                                    className="font-bold mr-1">{this.renders.videos().length}</span> available
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <button
                                                className="bg-purple-500 text-2xl relative text-white h-10 w-10 flex justify-center items-center rounded">
                                                +
                                                <input
                                                    ref="uploadVideoFile"
                                                    onClick={() => this.refs.uploadVideoFile.value = null}
                                                    onChange={(e) => this.child.current.functions.onSelectFile(e, false)}
                                                    type="file"
                                                    multiple
                                                    accept={"video/*, image/gif"}
                                                    className="Upload-ImageContainerAreaPlusUpload"
                                                />
                                            </button>
                                            {
                                                this.renders.videos().length > 0 &&
                                                <button
                                                    className="bg-gray-200 text-gray-900 ml-2 h-10 w-10 flex justify-center items-center rounded"
                                                    onClick={() => {
                                                        this.setState({
                                                            open_videos: !this.state.open_videos
                                                        })
                                                    }}>
                                                    {
                                                        !this.state.open_videos &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-down"} />
                                                    }
                                                    {
                                                        this.state.open_videos &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-up"} />
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <SlideDown closed={!this.state.open_videos}>
                                        {
                                            this.renders.videos().length > 0 &&
                                            <div className="w-full border-t" />
                                        }
                                        <div className="">
                                            {
                                                this.renders.videos().map((item, index) => {
                                                    return (
                                                        <AdvertisingCreativesAdsItem
                                                            last={index === (this.renders.videos().length - 1)}
                                                            item={item}
                                                            index={index}
                                                            campaign={this.state.campaign}
                                                            videos={this.renders.videos()}
                                                            images={this.renders.images()}
                                                            carousels={this.renders.carousels()}
                                                            creatives={this.state.creatives}
                                                            errors={this.state.errors}
                                                            channels={this.state.channels}
                                                            content={this.state.creatives.video_ads}
                                                            client={this.state.client}
                                                            site={this.state.site}
                                                            adset={this.state.adset}
                                                            languages={this.state.languages}
                                                            updateTitle={(value) => {
                                                                this.renders.videos().map((video) => {
                                                                    video.headline = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateBody={(value) => {
                                                                this.renders.videos().map((video) => {
                                                                    video.body = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateDescription={(value) => {
                                                                this.renders.videos().map((video) => {
                                                                    video.description = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            onChange={(value) => {
                                                                this.state.creatives.video_ads = value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateCreatives={(value) => {
                                                                this.setState({
                                                                    creatives: value
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </SlideDown>
                                </div>
                            </div>
                        }
                        {

                            this.renders.dynamic().length < 1 &&
                            <div className="flex flex-1 flex-row items-center pt-4">
                                <div className="bg-white rounded-md overflow-hidden w-full flex-1">
                                    <div className="px-4 py-4 flex-row flex">
                                        <div
                                            className="h-10 mr-4 bg-purple-100 text-purple-500 rounded w-10 flex justify-center align-middle items-center">
                                            <FeatherIcon className="stroke-current" size={16} icon="camera" />
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="mb-0 font-bold relative">
                                                Image ads
                                                {
                                                    Array.isArray(this.state.errors) && this.state.errors.length > 0 &&
                                                    this.state.errors.filter((error) => {
                                                        return this.renders.images().filter((item) => {
                                                            return error.ad === item.id
                                                        }).length > 0
                                                    }).length > 0 &&
                                                    <div style={{ left: "99px", top: "-1px" }}
                                                        className={"bg-gray-100 close mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15} icon="alert-circle" />
                                                    </div>
                                                }
                                            </h3>
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                <span
                                                    className="font-bold mr-1">{this.renders.images().length}</span> available
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <button
                                                className="bg-purple-500 text-2xl relative text-white h-10 w-10 flex justify-center items-center rounded">
                                                +
                                                <input
                                                    ref="uploadImageFile"
                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                    onChange={(e) => this.child.current.functions.onSelectFile(e, false)}
                                                    type="file"
                                                    multiple
                                                    accept={"image/png, image/jpg, image/jpeg, image/gif"}
                                                    className="Upload-ImageContainerAreaPlusUpload"
                                                />
                                            </button>
                                            {
                                                this.renders.images().length > 0 &&
                                                <button
                                                    className="bg-gray-200 text-gray-900 ml-2 h-10 w-10 flex justify-center items-center rounded"
                                                    onClick={() => {
                                                        this.setState({
                                                            open_images: !this.state.open_images
                                                        })
                                                    }}>
                                                    {
                                                        !this.state.open_images &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-down"} />
                                                    }
                                                    {
                                                        this.state.open_images &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-up"} />
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <SlideDown closed={!this.state.open_images}>
                                        {
                                            this.renders.images().length > 0 &&
                                            <div className="w-full border-t" />
                                        }
                                        <div className="">
                                            {
                                                this.renders.images().map((item, index) => {
                                                    return (
                                                        <AdvertisingCreativesAdsItem
                                                            last={index === (this.renders.images().length - 1)}
                                                            item={item}
                                                            campaign={this.state.campaign}
                                                            videos={this.renders.videos()}
                                                            images={this.renders.images()}
                                                            carousels={this.renders.carousels()}
                                                            index={index}
                                                            adset={this.state.adset}
                                                            creatives={this.state.creatives}
                                                            channels={this.state.channels}
                                                            errors={this.state.errors}
                                                            content={this.state.creatives.image_ads}
                                                            languages={this.state.languages}
                                                            client={this.state.client}
                                                            updateTitle={(value) => {
                                                                this.renders.images().map((image) => {
                                                                    image.headline = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateBody={(value) => {
                                                                this.renders.images().map((image) => {
                                                                    image.body = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateDescription={(value) => {
                                                                this.renders.images().map((image) => {
                                                                    image.description = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            onChange={(value) => {
                                                                this.state.creatives.image_ads = value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateCreatives={(value) => {
                                                                this.state.creatives = value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </SlideDown>
                                </div>
                            </div>
                        }
                        {

                            this.renders.dynamic().length < 1 && !this.renders.event() &&
                            <div className="flex flex-1 flex-row items-center pt-4">
                                <div className="bg-white rounded-md overflow-hidden w-full flex-1">
                                    <div className="px-4 py-4 flex-row flex">
                                        <div
                                            className="h-10 mr-4 bg-purple-100 text-purple-500 rounded w-10 flex justify-center align-middle items-center">
                                            <FeatherIcon className="stroke-current" size={16} icon="layers" />
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="mb-0 font-bold relative">
                                                Carousel ads
                                                {
                                                    Array.isArray(this.state.errors) && this.state.errors.length > 0 &&
                                                    this.state.errors.filter((error) => {
                                                        return this.renders.carousels().filter((item) => {
                                                            return error.ad === item.id
                                                        }).length > 0
                                                    }).length > 0 &&
                                                    <div style={{ left: "119px", top: "-1px" }}
                                                        className={"bg-gray-100 close mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15} icon="alert-circle" />
                                                    </div>
                                                }
                                            </h3>
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                <span
                                                    className="font-bold mr-1">{this.renders.carousels().length}</span> available
                                            </p>
                                        </div>
                                        <div className="flex flex-row">
                                            <button
                                                className="bg-purple-500 text-2xl relative text-white h-10 w-10 flex justify-center items-center rounded">
                                                +
                                                <input
                                                    ref="uploadCarouselFile"
                                                    onClick={() => this.refs.uploadCarouselFile.value = null}
                                                    onChange={(e) => this.child.current.functions.onSelectCarouselFiles(e)}
                                                    type="file"
                                                    multiple
                                                    accept={(this.state.channels.facebook || this.state.channels.instagram) ? "image/png, image/jpg, image/jpeg, video/*, image/gif" : "image/png, image/jpg, image/jpeg"}
                                                    className="Upload-ImageContainerAreaPlusUpload"
                                                />
                                            </button>
                                            {
                                                this.renders.carousels().length > 0 &&
                                                <button
                                                    className="bg-gray-200 text-gray-900 ml-2 h-10 w-10 flex justify-center items-center rounded"
                                                    onClick={() => {
                                                        this.setState({
                                                            open_carousels: !this.state.open_carousels
                                                        })
                                                    }}>
                                                    {
                                                        !this.state.open_carousels &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-down"} />
                                                    }
                                                    {
                                                        this.state.open_carousels &&
                                                        <FeatherIcon className="stroke-current" size={16}
                                                            icon={"chevrons-up"} />
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <SlideDown closed={!this.state.open_carousels}>
                                        {
                                            this.renders.carousels().length > 0 &&
                                            <div className="w-full border-t" />
                                        }
                                        <div className="">
                                            {
                                                this.renders.carousels().map((item, index) => {
                                                    return (
                                                        <AdvertisingCreativesAdsItem
                                                            item={item}
                                                            last={index === (this.renders.carousels().length - 1)}
                                                            videos={this.renders.videos()}
                                                            images={this.renders.images()}
                                                            carousels={this.renders.carousels()}
                                                            index={index}
                                                            campaign={this.state.campaign}
                                                            adset={this.state.adset}
                                                            creatives={this.state.creatives}
                                                            channels={this.state.channels}
                                                            errors={this.state.errors}
                                                            content={this.state.creatives.carousel_ads}
                                                            languages={this.state.languages}
                                                            client={this.state.client}
                                                            updateBody={(value) => {
                                                                this.renders.carousels().map((carousel) => {
                                                                    carousel.body = value;
                                                                });
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            onChange={(value) => {
                                                                this.state.creatives.carousel_ads = value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                            updateCreatives={(value) => {
                                                                this.state.creatives = value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </SlideDown>
                                </div>
                            </div>
                        }
                        {

                            (this.state.channels.facebook || this.state.channels.instagram) && !this.renders.event() &&
                            <AdvertisingCreativesProducts
                                channels={this.state.channels}
                                error={this.state.error}
                                errors={this.state.errors}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                dynamic={this.renders.dynamic()}
                                adset={this.state.adset}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }}
                                createProduct={() => {
                                    this.child.current.functions.createCatalogAd()
                                }}
                            />
                        }
                        {
                            (this.state.channels.facebook || this.state.channels.instagram) && this.state.adset && !this.renders.event() &&
                            <AdvertisingCreativesPosts
                                channels={this.state.channels}
                                error={this.state.error}
                                errors={this.state.errors}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                posts={this.renders.posts()}
                                adset={this.state.adset}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }}
                                createPost={() => {
                                    this.child.current.functions.createPostAd();
                                }}
                            />
                        }

                    </div>
                }
                {
                    <AdvertisingCreativesUpload
                        ref={this.child}
                        hide={true}
                        title={"Create ads"}
                        size={"full"}
                        cover={false}
                        error={false}
                        site={this.state.site}
                        client={this.state.client}
                        channels={this.state.channels}
                        creatives={this.state.creatives}
                        conversion={this.state.conversion}
                        adset={this.state.adset}
                        campaign={this.state.campaign}
                        adsets={this.state.creatives.adsets}
                        onChange={(value) => {
                            this.state.creatives = value;
                            try {
                                if (value.adsets.length > 0 && !this.state.adset.name) {
                                    this.setState({
                                        adset: value.adsets[0]
                                    })
                                }
                            } catch (e) { }
                            this.props.onChange(this.state.creatives);
                        }}
                        updateAdset={(adset) => {
                            this.setState({
                                adset: adset
                            }, () => {
                                this.props.updateAdset(adset);
                            });
                        }}
                    />
                }
            </div>
        )
    }
}

export default AdvertisingCreativesAds;
