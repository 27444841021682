import React, { Component } from 'react';
import { Transition } from '@headlessui/react'
import { tokenRegister } from '../services/tokenRegister';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';

class OrderCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            loading: false,
            loaded: false,
            line: {
                data: {
                    labels: ["overview"],
                    datasets: [
                        {
                            label: 'Clicks',
                            data: [10, 15, 15],
                            fill: false,
                            backgroundColor: '#ffffff',
                            borderColor: 'rgba(75, 192, 192, 0.2)',
                        },
                    ],
                },
            },
            date_intervals: {
                "last_7": { start: moment().subtract(7, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_10": { start: moment().subtract(10, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_14": { start: moment().subtract(14, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_20": { start: moment().subtract(20, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_30": { start: moment().subtract(30, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_60": { start: moment().subtract(60, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
                "last_90": { start: moment().subtract(90, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }
            },
            dimension: "date",
            metricType: { campaigns: "Clicks (default)", adgroups: "Clicks (default)", ads: "Clicks (default)" },
            client: this.props.client,
            custom_dates: this.props.customDates,
            date_span: this.props.date_span,
            campaigns: [],
            adgroups: [],
            ads: [],
            tab: this.props.tab,
            selected: false,
            metrics: [],
            loadedCharts: {},
            channel: "all",
            orderId: null,
            defaultMetricOrder: "Spend,Impressions,Clicks,CTR,CPC,CPM,Conversions"
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            client: this.state.client,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            custom_dates: this.props.customDates,
            date_span: this.props.dateSpan,
            tab: this.props.tab,
            channel: this.props.channel,
            metrics: this.getMetrics(this.props.metrics),
            open: this.props.open,
            orderId: this.props.orderId
        });
        await this.getMetricType();
        this.functions.getcharts()
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevProps.customDates) !== JSON.stringify(this.props.customDates) ||
            JSON.stringify(prevProps.dateSpan) !== JSON.stringify(this.props.dateSpan) ||
            (prevProps.tab !== this.props.tab && prevProps.campaigns.length !== this.props.campaigns.length) ||
            ((this.props.tab === 'adgroups') && (prevProps.adgroups.length !== this.props.adgroups.length)) ||
            ((this.props.tab === 'ads') && (prevProps.ads.length !== this.props.ads.length)) ||
            JSON.stringify(prevProps.metrics) !== JSON.stringify(this.props.metrics) ||
            prevProps.selected !== this.props.selected ||
            prevProps.channel !== this.props.channel ||
            prevProps.open !== this.props.open
        ) {
            await this.promisedSetState({ open: this.props.open });
            const campaigns = this.props.channel === 'all' ? this.props.campaigns : this.props.campaigns.filter(item => item.channel === this.props.channel);

            await this.promisedSetState({
                client: this.state.client,
                campaigns: campaigns,
                adgroups: this.props.adgroups,
                ads: this.props.ads,
                custom_dates: this.props.customDates,
                date_span: this.props.dateSpan,
                tab: this.props.tab,
                channel: this.props.channel,
                metrics: this.getMetrics(this.props.metrics),
                selected: this.props.selected
            });

            this.functions.getcharts()
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload) {
            this.functions.getcharts()
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        getcharts: async () => {
            if (!this.state.open) return;

            let key = (this.state[this.state.tab].map(item => item.id).join("") + this.state.metricType[this.state.tab] + this.state.channel + this.state.dimension).replaceAll(/\s/g, '');
            key += this.state.custom_dates.start ? this.state.custom_dates.start + this.state.custom_dates.end : this.state.date_intervals[this.state.date_span.value].start + this.state.date_intervals[this.state.date_span.value].end;
            const loadedCharts = { ...this.state.loadedCharts };

            if (loadedCharts[key]) {
                await this.promisedSetState({ line: { ...loadedCharts[key] } });
                return false;
            }
            await this.promisedSetState({ loading: true });

            const body = [{
                id: "main_chart",
                campaigns: this.state.tab === "campaigns" ? this.state.campaigns.map(campaign => { return { id: campaign.id, channel: campaign.channel, name: campaign.name } }) : [],
                adgroups: this.state.tab === "adgroups" ? this.state.adgroups.map(adgroup => { return { id: adgroup.id, channel: adgroup.channel, name: adgroup.name } }) : [],
                ads: this.state.tab === "ads" ? this.state.ads.map(ad => { return { id: ad.id, channel: ad.channel, name: ad.name } }) : [],
                start_date: this.state.custom_dates.start || this.state.date_intervals[this.state.date_span.value].start,
                end_date: this.state.custom_dates.end || this.state.date_intervals[this.state.date_span.value].end,
                metrics: this.state.metrics.filter(item => item.value === this.state.metricType[this.state.tab]).map(item => ({ name: item.value })),
                information: {
                    color: this.generateColors(this.state[this.state.tab])
                },
                type: "chart_line",
                level: this.state.tab.slice(0, -1),
                dimensions: {
                    basic: this.state.dimension,
                    adform: this.state.dimension,
                    bidtheatre: this.state.dimension,
                    bing: this.state.dimension,
                    facebook: this.state.dimension,
                    google: this.state.dimension,
                    google_analytics: this.state.dimension,
                    google_analytics_4: this.state.dimension,
                    linkedin: this.state.dimension,
                    snapchat: this.state.dimension,
                    tiktok: this.state.dimension,
                    twitter: this.state.dimension
                }
            }];

            const response = await this.calls.getLineChart(body);

            let lineData;

            if (this.state.selected) {
                lineData = response.data[0];
            } else {
                const originalData = response.data[0].data;
                const maxLength = originalData.labels.length;
                const aggregatedData = Array(maxLength).fill(0);

                for (const dataset of originalData.datasets) {
                    dataset.data.forEach((value, index) => {
                        aggregatedData[index] += parseFloat(value);
                    });
                }

                const aggregatedDataset = {
                    backgroundColor: "#453ff1",
                    borderColor: "#453ff1",
                    borderWidth: 2,
                    data: aggregatedData,
                    fill: false,
                    label: this.state.metricType[this.state.tab]
                };

                const updatedData = {
                    ...originalData,
                    datasets: [aggregatedDataset]
                };

                lineData = { data: updatedData };
            }
            if (lineData.data && lineData.data.datasets) {
                lineData.data.datasets.forEach(dataset => {
                    delete dataset._meta;
                });
            }
            loadedCharts[key] = JSON.parse(JSON.stringify(lineData));
            await this.promisedSetState({ loadedCharts, line: { ...lineData }, loading: false });
        }
    }

    generateColorFromId(Id) {
        let hash = 0;
        for (let i = 0; i < Id.length; i++) {
            hash = Id.charCodeAt(i) + ((hash << 5) - hash);
        }

        const color = "#" + ((hash & 0x00FFFFFF)
            .toString(16))
            .toUpperCase();

        return color;
    }

    generateColors(datas) {
        const colorsObject = {};

        for (const data of datas) {
            const { id } = data;
            const color = this.generateColorFromId(id);
            colorsObject[id] = color;
            if (datas.length < 2) {
                colorsObject[id] = "#453ff1";
            }
        };

        return colorsObject;
    }

    getMetrics(metrics) {
        const metricsWithIds = metrics.filter(item => item.id);

        if (metricsWithIds.length) {
            return metrics;
        }

        const metricsOrder = metrics.map(item => item.name).join(",");

        metrics = metrics.map(item => ({ ...item, value: item.reportName }));

        if (metricsOrder === this.state.defaultMetricOrder) {
            // Find the index of the selected metric type "clicks, spend, etc "
            let index = metrics.findIndex(metric => metric.value === this.state.metricType[this.state.tab]);

            if (index !== -1) {
                let metric = metrics.splice(index, 1)[0];
                metrics.unshift(metric);
            }
        }


        return metrics;
    }

    setDbMetricType() {
        const data = {
            orderId: this.state.orderId,
            tab: this.state.tab,
            metricType: this.state.metricType[this.state.tab]
        }

        this.calls.setUserchartMetricType(data);
    }

    async getMetricType() {
        const response = await this.calls.getUserMetricType();

        if (response.status === 200 && response.data && response.data[this.state.tab]) {
            await this.promisedSetState({
                metricType: { ...this.state.metricType, [this.state.tab]: response.data[this.state.tab].metricType },
                metrics: this.getMetrics(this.state.metrics)
            })
        }
    }

    calls = {
        getLineChart: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportABTest?&client=" + this.state.client.id + "&calculate_basic_metrics=true&statistics=true";
            return apiRegister.call(options, url);
        },
        setUserchartMetricType: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderMetricType";
            return apiRegister.call(options, url);
        },
        getUserMetricType: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getUserOrderMetricType?order_id=" + this.state.orderId;
            return apiRegister.call(options, url);
        },
    }

    render() {
        return ["campaigns", "adgroups", "ads"].includes(this.state.tab) &&
            <>
                <div className={`bg-white rounded-lg shadow-lg relative transition-all duration-500 ease-in-out ` + (this.state.open ? "h-auto p-4 " : "h-0")} >

                    <div className={`flex flex-row items-center`}>
                        {/* {this.state.open && ( */}
                        <>
                            <div className="grid grid-cols-3 flex-1 ml-2 items-center">
                                {/* Button */}
                                <div className="col-span-1">
                                    <DropdownTailwind
                                        searchInput={false}
                                        label={null}
                                        selected={this.state.metrics.find(item => item.value === this.state.metricType[this.state.tab]) || {}}
                                        options={this.state.metrics}
                                        onChange={async (value) => {
                                            if (this.state.metricType[this.state.tab] !== value.value) {
                                                await this.promisedSetState({ metricType: { ...this.state.metricType, [this.state.tab]: value.value } })
                                                this.functions.getcharts();
                                                this.setDbMetricType();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                        {/* )} */}
                    </div>
                    {/* {this.state.open && ( */}
                    <div style={{ backgroundColor: "rgb(252, 252, 252)" }} className={"rounded-lg shadow-lg p-4 relative transition-all duration-500 ease-in-out "  + (this.state.open ? "h-auto" : "h-0")}>
                        <div style={{ height: "250px", width: "100%" }}>
                            {this.state.loading ? (
                                <div className="h-full flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            ) : (
                                <Line
                                    height={50}
                                    data={this.state.line.data}
                                    options={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {/* )} */}
                    {/* </div> */}
                    {/* Your content here */}
                </div>
            </>

    }

}

export default OrderCharts;