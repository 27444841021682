import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, CheckIcon, XIcon } from '@heroicons/react/outline'

class SuccessNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open
        })
    }

    render() {
        return (
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-8 z-50 pointer-events-none pt-16 sm:items-start"
                >
                    <div className="w-full flex pt-4 flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={this.state.open}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="max-w-sm w-full bg-white border-1.5 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            <p className="text-sm font-medium text-gray-900">Successfully saved!</p>
                                            <p className="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p>
                                        </div>
                                        <div className="ml-4 flex-shrink-0 flex">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    if(this.props.onClose){
                                                        this.props.onClose();
                                                    }
                                                }}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        )
    }
}

export default SuccessNotification;
