class calls {

    static url = {
        //api: "https://api.adcredo.io/api",
        api: "http://localhost:5000/api",
    };

    static getGeoTargeting(token, search) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            fetch('https://graph.facebook.com/v16.0/search?q='+search+'&type=adgeolocation&location_types=["country"]&access_token=' + token, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

}

export {calls}