import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import * as turf from '@turf/turf'
import AdvertisingLocationLanguage from "../advertising-location-language";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import {calls} from "./calls";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";

class AdvertisingLocationRegions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: "",
            regions: [],
            languages: [],
            calls: {},
            disable: false,
            loading_region: false,
            loading_request: false,
            requests: {},
            request_success: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disable: nextProps.disable
        })
    }

    componentDidMount() {
        this.setState({
            disable: this.props.disable,
            languages: this.props.languages,
        })
    }

    functions = {
        countries: (search) => {
            if (search !== "") {
                if(!this.state.calls[search]){
                    let call = {
                        string: search,
                        loading: true,
                        error: false,
                        regions: []
                    };
                    this.state.calls[search] = call;
                    this.setState({
                        calls: this.state.calls
                    }, () => {
                        calls.getCountries({search: search}).then((response) => {
                            this.state.calls[search].regions = response.data;
                            this.state.calls[search].loading = false;
                            this.setState({
                                calls: this.state.calls
                            })
                        }, (error) => {
                            this.state.calls[search].loading = false;
                            this.state.calls[search].error = true;
                        });
                    });
                }
            }
        },
        region: (region) => {
            this.setState({
                loading_region: true
            }, () => {
                calls.getRegion({
                    region: encodeURI(region.id)
                }).then((response) => {
                    this.setState({
                        loading_region: false,
                    }, () => {
                        region.paths = response.data.paths;
                        region.include = true;
                        try{
                            region.language = this.state.languages[0];
                        }catch (e) {}
                        this.props.onChange(region);
                    })
                }, (error) => {
                    this.setState({
                        loading_region: false
                    })
                });
            })
        },
        request: (region) => {
            if(!this.state.requests[region]){
                this.state.requests[region] = true;
                this.setState({
                    requests: this.state.requests,
                    loading_request: true
                }, () => {
                    calls.postRequest({
                        region: encodeURI(region)
                    }).then((response) => {
                        this.setState({
                            loading_request: false,
                            request_success: true
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    request_success: false
                                })
                            }, 2000);
                        })
                    }, (error) => {
                        this.setState({
                            loading_request: false,
                            request_success: true
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    request_success: false
                                })
                            }, 2000);
                        })
                    });
                })
            }
        }
    };

    renders = {
        regions: () => {
            try{
                return this.state.calls[this.state.search].regions;
            }catch (e) {
                return []
            }
        },
        loading: () => {
            try{
                return this.state.calls[this.state.search].loading || this.state.loading_region;
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <Dropdown
                filter={true}
                searchFilter={true}
                search={true}
                disabled={this.state.disable}
                title={'Select countries'}
                size={'full'}
                request={true}
                removeArrow={this.state.disable}
                loading={this.renders.loading()}
                placeholder={!this.state.request_success?(!this.state.disable?(!this.state.loading_request?(this.renders.loading()?"Loading":"Search here .."):"Sending request"):"Remove custom areas before selecting locations"):"Request send, thanks!"}
                options={this.renders.regions()}
                value={this.state.search}
                requests={this.state.requests}
                requestSuccess={this.state.request_success}
                onSearch={(value) => {
                    this.setState({
                        search: value
                    }, () => {
                        this.functions.countries(value);
                    })
                }}
                onRequest={(value) => {
                    this.setState({
                        search: ""
                    }, () => {
                        this.functions.request(value);
                    })
                }}
                onChange={(value) => {
                    this.functions.region(value);
                }}
            />
        )
    }
}

export default AdvertisingLocationRegions;
