import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";
import Input from "../input";
import TextArea from "../text-area";

class CampaignStatusSocialPreviewFacebookImage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentDidMount() {}

    functions = {};

    init = {};

    renders = {};

    render() {
        return (
            <div className={cx("FacebookImage-PreviewBasicContainerItemInner")}>
                <div className="FacebookImage-PreviewBasicContainerItemInnerHeader">
                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                        <div style={{backgroundImage: 'url('+this.props.pageImage+')'}} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                    </div>
                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText">
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop">
                            {this.props.pageName}
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                Sponsored
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot"/>
                            <img className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe" src={require('../../assets/images/globe.png')}/>
                        </div>
                    </div>
                </div>
                <div className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText">
                    {this.props.body}
                </div>
                <img style={{width: '100%'}} src={this.props.image}/>
                <div className="FacebookImage-PreviewBasicContainerItemInnerBottom">
                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft">
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftUrl">
                            {this.props.url}
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftHeadline">
                            {this.props.title}
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftDescription">
                            {this.props.description}
                        </div>
                    </div>
                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRight">
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton">
                            Learn more
                        </div>
                    </div>
                </div>
                <div className="FacebookImage-PreviewBasicContainerItemInnerShare">
                    <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                        <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike" src={require('../../assets/images/like.png')}/>
                        Like
                    </div>
                    <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                        <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment" src={require('../../assets/images/comment.png')}/>
                        Comment
                    </div>
                    <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                        <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare" src={require('../../assets/images/share.png')}/>
                        Share
                    </div>
                </div>
            </div>
        )
    }
}

export default CampaignStatusSocialPreviewFacebookImage;
