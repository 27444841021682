import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';

export default class ChartTemplateBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            line: {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: this.props.header,
                    position: this.props.labelBarPos || 'top',
                    align: 'center',
                    fontStyle: 'bold',
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                    fontColor: '#333333',
                    lineHeight: 1,
                },
                legend: {
                    display: true,
                    position: this.props.labelBarPos || 'top',
                    align: 'center',
                    labels: {
                        boxWidth: 11,
                        borderRadius: 5,
                        fontSize: 12,
                    },
                },
                layout: {
                    padding: {
                        left: 16,
                        right: 16,
                        top: this.props.labelBarPos && this.props.labelBarPos === 'left' ? 40 : 16,
                        bottom: 16,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            categoryPercentage: 1.0,
                            barPercentage: 1.0,
                            gridLines: {
                                drawOnChartArea: false,
                                tickMarkLength: 0,
                            },
                            ticks: {
                                maxTicksLimit: 3,
                                fontSize: 12,
                                fontFamily: "'Poppins', sans-serif",
                                fontColor: '#333333',
                                fontStyle: 'bold',
                                padding: 8,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1.0,
                            categoryPercentage: 0.8,
                            gridLines: {
                                display: false,
                                drawOnChartArea: false,
                            },
                            ticks: {
                                // display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'nearest',
                },
                ticks: {
                    maxRotation: 0,
                },
            },

            chart: {},
            level: {},
            properties: [],
            chart_type: {name: 'Bar chart', value: 'bar'},
            open: true,
            data: this.props.data,
        };
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     this.setState({
    //         report: nextProps.report ? nextProps.report : {},
    //         level: nextProps.level ? nextProps.level : {},
    //     });
    // }

    componentDidMount() {
        this.setState(
            {
                report: this.props.report ? this.props.report : {},
                level: this.props.level ? this.props.level : {},
                properties: this.props.properties ? this.props.properties : [],
            },
            () => {
                this.setState(
                    {
                        chart: {name: 'click', value: 'click'},
                    },
                    () => {
                        //this.functions.data();
                    }
                );
            }
        );
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <>
                {this.state.chart.name && this.state.chart_type.value === 'bar' && (
                    <Bar options={this.state.line} responsive={true} data={this.state.data} width={null} height={null} />
                )}
            </>
        );
    }
}
