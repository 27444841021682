import { AdjustmentsIcon, ArchiveIcon, ArrowNarrowLeftIcon, ArrowRightIcon, ArrowSmDownIcon, ArrowSmUpIcon, CloudIcon, CalculatorIcon, CalendarIcon, ChartPieIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ClipboardCheckIcon, ClipboardIcon, ClipboardListIcon, ClockIcon, CogIcon, CollectionIcon, CubeIcon, CurrencyDollarIcon, DeviceMobileIcon, DocumentDuplicateIcon, DocumentReportIcon, DocumentTextIcon, DownloadIcon, ExclamationCircleIcon, ExclamationIcon, FolderIcon, GlobeAltIcon, HandIcon, HomeIcon, IdentificationIcon, InformationCircleIcon, KeyIcon, LinkIcon, LockClosedIcon, LogoutIcon, MenuIcon, OfficeBuildingIcon, PaperClipIcon, PauseIcon, PencilAltIcon, PencilIcon, PhoneIcon, PhotographIcon, PlayIcon, PlusIcon, RefreshIcon, StopIcon, TemplateIcon, UploadIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { clientRegister } from '../services/clientRegister';
import { draftRegister } from '../services/draftRegister';
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import DropdownTailwind from './dropdownTailwind';


const navigation = [
    {
        id: 1,
        title: "Dashboard",
        regex: /^\/(admin)\/(dashboard)/,
        link: "/admin/dashboard",
        icon: HomeIcon,
    },
    {
        id: 2,
        title: "Agencies",
        regex: /^\/(admin)\/(agencies)/,
        link: "/admin/agencies",
        icon: OfficeBuildingIcon,
    },
    {
        id: 3,
        title: "Agents",
        regex: /^\/(admin)\/(agents)/,
        link: "/admin/agents",
        icon: UserGroupIcon,
    },
    {
        id: 4,
        title: "Admin users",
        regex: /^\/(admin)\/(users)/,
        link: "/admin/users",
        icon: UserCircleIcon,
    },
    /*
    {
        id: 5,
        title: "Finance",
        regex: /^\/(admin)\/(finance)/,
        link: "/admin/finance",
        icon: CurrencyDollarIcon,
    },
    {
        id: 6,
        title: "Default metrics",
        regex: /^\/(admin)\/(metrics)/,
        link: "/admin/metrics",
        icon: AdjustmentsIcon,
    },
    */
    {
        id: 7,
        title: "Custom sources",
        regex: /^\/(admin)\/(custom-platforms)/,
        link: "/admin/custom-platforms",
        icon: CloudIcon,
    },
    {
        id: 8,
        title: "Logs",
        regex: /^\/(admin)\/(logs)/,
        link: "/admin/logs",
        icon: ClipboardListIcon,
    },
    /*
    {
        id: 8,
        title: "Run cron",
        regex: /^\/(admin)\/(cron)/,
        link: "/admin/cron",
        icon: ClockIcon,
    },
    */
    /*
     {
         id: 9,
         title: "Clients",
         regex: /^\/(admin)\/(clients)/,
         link: "/admin/clients",
         icon: IdentificationIcon
     },
     */
    {
        id: 10,
        title: "Client platforms",
        regex: /^\/(admin)\/(accounts)/,
        link: "/admin/accounts",
        icon: IdentificationIcon
    },
    {
        id: 11,
        title: "Campaigns",
        regex: /^\/(admin)\/(campaigns)/,
        link: "/admin/campaigns",
        icon: FolderIcon
    },
    {
        id: 13,
        title: "CRON list",
        regex: /^\/(admin)\/(jobs)/,
        link: "/admin/jobs",
        icon: DocumentTextIcon
    },
    {
        id: 14,
        title: "CRON controllers",
        regex: /^\/(admin)\/(controllers)/,
        link: "/admin/controllers",
        icon: AdjustmentsIcon
    }
];

const account_menu = [
    {
        id: 12,
        title: "Sign out",
        regex: /^\/(v2)\/(settings)/,
        signout: true,
        icon: LogoutIcon,
    }
];

class SideNavigationAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            menu: navigation,
            account_menu: account_menu,
            selected: {},
            user: {},
            order: {},
            orders_statistics: {},
            order_statistics: {},
            campaigns_statistics: {},
            steps: {},
            clients: [],
            client: {},
            page: 1,
            search: "",
            minimize: false
        }
    };

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.url();
    }

    componentWillReceiveProps() {
        this.functions.url();
    }

    functions = {
        url: async () => {
            let selected = {};
            this.state.menu = this.state.menu.map((item) => {
                if (this.renders.regex(item)) {
                    selected = item;
                }
                return item;
            });
            await this.promisedSetState({
                selected: selected,
                menu: this.state.menu
            });
        },
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        minimizeMenu: (option) => {
            this.setState({
                minimize: option !== undefined ? option : !this.state.minimize
            });
        }
    };

    renders = {
        firstLetter: () => {
            try {
                return userRegister.get().name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        user: () => {
            try {
                if (userRegister.get()) {
                    return userRegister.get();
                } else {
                    return {}
                }
            } catch (e) {
                return {}
            }
        },
        regex: (item, string) => {
            try {
                return item.regex.test(string ? string : window.location.pathname);
            } catch (error) {
                return false;
            }
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        },
        adminStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/adminStatistics";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="flex-col h-full sm:flex hidden bg-white">

                <div className="flex-row flex flex-1">

                    {/*MENU*/}
                    <div style={{ transitionProperty: "width" }}
                        className={(this.state.minimize ? "w-20" : "w-64") + " bg-purple-900 duration-75 flex flex-col relative"}>
                        <div onClick={() => {
                            this.props.history.push("/admin/dashboard");
                        }} className="flex items-center h-16   flex-row truncate overflow-hidden pl-4 cursor-pointer">
                            <div onClick={() => {
                                this.setState({
                                    minimize: !this.state.minimize
                                });
                            }} className="w-12 h-12 overflow-hidden relative bg-white bg-opacity-0 p-1">
                                <div className="object-cover w-full h-full inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(https://res.cloudinary.com/ddu7oaekf/image/upload/v1647421559/rl65ptm6jcy91nzzcnje.png) center center / contain no-repeat' }}>

                                </div>
                            </div>
                            <div className="relative">
                                <div style={{ transitionProperty: "width", top: "-18px" }} className={(this.state.minimize ? "hidden" : "absolute") + " flex flex-1 flex-col"}>
                                    <div className={"truncate text-sm ml-4 font-semibold text-white"}>
                                        Adcredo System
                                    </div>
                                    <div className={"truncate text-xxs ml-4 font-medium text-white text-opacity-75"}>Admin View</div>
                                </div>
                            </div>
                        </div>
                        <nav className="px-4 py-5">
                            {this.state.menu.filter((item) => {
                                return !item.hidden
                            }).map((item, index) => (
                                <Fragment>
                                    <div
                                        onMouseEnter={() => {
                                            item.hover = true;
                                            this.promisedSetState({
                                                menu: this.state.menu
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            item.hover = false;
                                            this.promisedSetState({
                                                menu: this.state.menu
                                            });
                                        }}
                                        onClick={async () => {
                                            await this.promisedSetState({
                                                menu: this.state.menu,
                                                selected: item
                                            });
                                            this.props.history.push(item.link);
                                        }}
                                        key={item.link}
                                        className={classNames(
                                            this.renders.regex(item) ? (item.dropdown ? 'text-white text-opacity-100 bg-white bg-opacity-25' : 'text-white text-opacity-100 bg-white bg-opacity-25') : 'text-white text-opacity-50  hover:text-opacity-100',
                                            'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer relative',
                                        )}
                                        aria-current={this.renders.regex(item) ? 'page' : undefined}
                                    >
                                        {
                                            this.state.minimize &&
                                            item.hover &&
                                            <div style={{ left: "75px" }} className={"absolute shadow z-50 bg-purple-900 whitespace-no-wrap h-full items-center flex px-3 flex-row rounded-md"}>
                                                <div style={{
                                                    left: "-7px",
                                                    width: 0,
                                                    height: 0,
                                                    borderTop: "7px solid transparent",
                                                    borderBottom: "7px solid transparent",
                                                    borderRight: "7px solid #060534"
                                                }} className="absolute"></div>
                                                <span className="text-sm font-medium">{item.title}</span>
                                            </div>
                                        }
                                        {
                                            item.icon &&
                                            <item.icon
                                                className={classNames(
                                                    this.renders.regex(item) ? 'text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                    'flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                        }
                                        {
                                            !this.state.minimize &&
                                            <span className="truncate flex flex-1 ml-3">{item.title}</span>
                                        }
                                    </div>
                                </Fragment>
                            ))}
                            <div className="h-2 w-full"></div>
                        </nav>
                        <div className="flex flex-1"></div>
                        {
                            !this.state.minimize &&
                            <div className="font-medium text-white pl-7 mt-4 text-xs uppercase">
                                ACCOUNT
                            </div>
                        }
                        <nav className="space-y-1 p-4 pt-2 pb-6">
                            {this.state.account_menu.filter((item) => {
                                if (item.id === 14) {
                                    return this.renders.user().manager
                                } else {
                                    return true
                                }
                            }).map((item, index) => (
                                <Fragment>
                                    <div
                                        onMouseEnter={() => {
                                            item.hover = true;
                                            this.promisedSetState({
                                                account_menu: this.state.account_menu
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            item.hover = false;
                                            this.promisedSetState({
                                                account_menu: this.state.account_menu
                                            });
                                        }}
                                        onClick={async () => {
                                            if (item.signout) {
                                                this.functions.logout();
                                            } else {
                                                await this.promisedSetState({
                                                    account_menu: this.state.account_menu,
                                                    selected: item
                                                });
                                                this.props.history.push(item.link);
                                            }
                                        }}
                                        key={item.link}
                                        className={classNames(
                                            this.renders.regex(item) ? (item.dropdown ? 'text-white text-opacity-100 bg-white bg-opacity-25' : 'text-white text-opacity-100 bg-white bg-opacity-25') : 'text-white text-opacity-50  hover:text-opacity-100',
                                            'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer relative',
                                        )}
                                        aria-current={this.renders.regex(item) ? 'page' : undefined}
                                    >
                                        {
                                            this.state.minimize &&
                                            item.hover &&
                                            <div style={{ left: "75px" }} className={"absolute shadow z-50 bg-purple-900 whitespace-no-wrap h-full items-center flex px-3 flex-row rounded-md"}>
                                                <div style={{
                                                    left: "-7px",
                                                    width: 0,
                                                    height: 0,
                                                    borderTop: "7px solid transparent",
                                                    borderBottom: "7px solid transparent",
                                                    borderRight: "7px solid #060534"
                                                }} className="absolute"></div>
                                                <span className="text-sm font-medium">{item.title}</span>
                                            </div>
                                        }
                                        {
                                            item.icon &&
                                            <item.icon
                                                className={classNames(
                                                    this.renders.regex(item) ? 'text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                    'flex-shrink-0 h-6 w-6',
                                                )}
                                                aria-hidden="true"
                                            />
                                        }
                                        {
                                            !this.state.minimize &&
                                            <span className="truncate flex flex-1 ml-3">{item.title}</span>
                                        }
                                    </div>
                                </Fragment>
                            ))}
                        </nav>
                    </div>

                </div>

            </div >
        )
    }

}

export default SideNavigationAdmin;