import React, { Component } from 'react';
import './style.css';
import Menu from "../../components/menu";
import AdvertisingCreativesFacebookImage from "../../components/advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../../components/advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../../components/advertising-creatives-facebook-carousel";
import AdvertisingCreativesInstagramImage from "../../components/advertising-creatives-instagram-image";
import AdvertisingCreativesInstagramVideo from "../../components/advertising-creatives-instagram-video";
import AdvertisingCreativesInstagramCarousel from "../../components/advertising-creatives-instagram-carousel";
import AdvertisingPreviewSearch from "../../components/advertising-preview-search";
import AccordionFixed from "../../components/accordion-fixed";
import FeatherIcon from "feather-icons-react";
import Loader from "../../components/loader";
import AdvertisingCreativesVertical from "../../components/advertising-creatives-vertical";
import AdvertisingCreativesSearchPreview from "../../components/advertising-creatives-search-preview";
import { calls } from "./calls";
import IconInstagram from "../../components/icons/instagram";
import IconFacebook from "../../components/icons/facebook";
import IconGoogle from "../../components/icons/google";
import cx from "classnames";
import AdvertisingPreviewSearchAll from "../../components/advertising-preview-search-all";
import AdvertisingPreviewSocial from "../../components/advertising-preview-social";
import IconGoogleShopping from "../../components/icons/google-shopping";
import AdvertisingPreviewShoppingAll from "../../components/advertising-preview-shopping-all";
const queryString = require('query-string');

class Preview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            body: "",
            channels: {},
            page: {},
            site: {},
            locations: [],
            creatives: {},
            type: {},
            campaign: {},
            search: {},
            shopping: {},
            loading: true,
            error: false,
            types: {},
        };
    };

    componentWillMount() {
        this.init.data();
    }

    init = {
        data: () => {
            calls.getPreview(this.props.match.params.id).then((response) => {
                this.setState({
                    channels: response.data.channels,
                    search: response.data.search,
                    shopping: response.data.shopping,
                    shoppingName: response.data.shoppingName,
                    creatives: response.data.creatives,
                    title: response.data.title,
                    text: response.data.text,
                    logo: response.data.logo,
                    locations: Array.isArray(response.data.locations) ? response.data.locations : [],
                    client: response.data.client,
                    loading: false,
                }, () => {
                    if (!this.state.channel) {
                        let keys = Object.keys(this.state.channels).filter((key) => { return this.state.channels[key] && key !== 'facebook_instagram' });
                        if (this.state.channels.google) {
                            keys[0] = 'google';
                        }
                        this.setState({
                            channel: keys[0]
                        }, () => {
                            this.functions.channel(this.state.channel);
                        })
                    } else {
                        this.functions.channel(this.state.channel);
                    }
                })
            }, (error) => {
                this.setState({
                    loading: false,
                    error: true
                })
            });
        }
    };

    functions = {
        channel: (key) => {
            this.setState({
                channel: key
            }, () => {

                if (this.state.channel === "facebook" || this.state.channel === "instagram" || this.state.channel === "linkedin") {

                    if (this.renders.campaigns("social").length > 0) {

                        this.renders.campaigns("social").map((campaign) => {
                            this.state.types[campaign.id] = this.renders.previews(campaign)[0];
                        });

                        /*
                        this.setState({
                            campaign: this.renders.campaigns("social")[0]
                        }, () => {
                            if (this.renders.previews().length > 0) {
                                if (this.renders.previews().filter((preview) => {
                                    return preview.value === this.state.type.value
                                }).length < 1) {
                                    this.setState({
                                        type: this.renders.previews()[0]
                                    })
                                }
                            }
                        })*/
                    }
                } else if (this.state.channel === "google") {

                    this.renders.campaigns("search").map((campaign) => {
                        this.state.types[campaign.id] = { id: 0, name: "Search ads" };
                    });

                    /*
                    if (this.renders.campaigns("search").length > 0) {
                        this.setState({
                            campaign: this.renders.campaigns("search")[0]
                        }, () => {
                            this.setState({
                                type: { id: 0, name: "Search ads" }
                            })
                        })
                    }
                    */
                } else {
                    if (this.renders.campaigns("shopping").length > 0) {

                        this.renders.campaigns("shopping").map((campaign) => {
                            this.state.types[campaign.id] = { id: 0, name: "Shopping ads" };
                        });

                        /*
                        this.setState({
                            campaign: this.renders.campaigns("shopping")[0]
                        }, () => {
                            this.setState({
                                type: { id: 0, name: "Shopping ads" }
                            })
                        })
                        */
                    }
                }

                this.setState({
                    types: this.state.types
                })

            })
        },
    };

    renders = {
        tabs: () => {
            let keys = [];
            for (let key in this.state.channels) {
                if (this.state.channels[key]) {
                    if (key === "facebook_instagram" && this.renders.instagram()) {
                        keys.push("instagram");
                    } else {
                        keys.push(key);
                    }
                }
            }
            let uniqueKeys = keys.filter(function (item, pos) {
                return keys.indexOf(item) == pos;
            })
            return uniqueKeys;
        },
        instagram: () => {
            try {
                let instagram = false;
                this.state.creatives.adsets.map((adset) => {
                    if (adset.targeting.placement.all || adset.targeting.placement.feed_instagram || adset.targeting.placement.story_instagram || adset.targeting.placement.explore) {
                        instagram = true;
                    }
                });
                return instagram;
            } catch (e) {
                return false;
            }
        },
        previews: (campaign) => {
            console.log(campaign);
            try {
                let options = [];
                if (Array.isArray(this.state.creatives.video_ads) && this.state.creatives.video_ads && this.state.creatives.video_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Video', value: 'video', type: 'video' });
                }
                if (Array.isArray(this.state.creatives.image_ads) && this.state.creatives.image_ads && this.state.creatives.image_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Image', value: 'image', type: 'image' });
                }
                if (Array.isArray(this.state.creatives.carousel_ads) && this.state.creatives.carousel_ads && this.state.creatives.carousel_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Carousel', value: 'carousel' });
                }
                if (Array.isArray(this.state.creatives.dynamic_ads) && this.state.creatives.dynamic_ads && this.state.creatives.dynamic_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Dynamic', value: 'dynamic' });
                }
                if (Array.isArray(this.state.creatives.post_ads) && this.state.creatives.post_ads && this.state.creatives.post_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Sponsored', value: 'post' });
                }
                if (Array.isArray(this.state.creatives.event_ads) && this.state.creatives.event_ads && this.state.creatives.event_ads.filter((item) => { return item.show && !item.error && item.internal_adset.campaign_id == campaign.id }).length > 0) {
                    options.push({ name: 'Event', value: 'event' });
                }
                console.log(this.state.creatives);
                console.log(options);
                return options;
            } catch (e) {
                return [];
            }
        },
        campaigns: (option) => {
            let campaigns = [];
            try {
                if (option === "social") {
                    campaigns = Array.isArray(this.state.creatives.campaigns) ? this.state.creatives.campaigns : []
                } else if (option === "search") {
                    campaigns = Array.isArray(this.state.search.campaigns) ? this.state.search.campaigns : []
                } else if (option === "shopping") {
                    campaigns = Array.isArray(this.state.shopping.campaigns) ? this.state.shopping.campaigns : []
                }
                return campaigns;
            } catch (e) {
                return campaigns;
            }
        }
    };

    render() {
        return (
            <div className="justify-center flex align-middle items-center">
                {
                    this.state.loading &&
                    <Loader
                        title={"Loading report"}
                    />
                }
                <div className="max-w-6xl w-full pt-12 pb-12 px-5">
                    {
                        this.state.logo &&
                        <div className="w-40 mb-6">
                            <img style={{ width: '100%' }} src={this.state.logo} />
                        </div>
                    }
                    {
                        (this.state.title || this.state.text) &&
                        <div className="mb-8">
                            <div className="text-2xl font-bold">
                                {this.state.title}
                            </div>
                            <div className="text-sm mt-2 font-bold">
                                {this.state.text}
                            </div>
                        </div>
                    }
                    <div className="flex flex-row items-center space-x-2 flex-wrap">
                        {
                            this.renders.tabs().map((key) => {
                                return (
                                    <div onClick={() => {
                                        this.functions.channel(key);
                                    }}>
                                        {key === 'instagram' &&
                                            <div className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-instagram-100 text-instagram-500 border-instagram-500' : ''}`}>
                                                <IconInstagram /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'facebook' &&
                                            <div className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-facebook-100 text-facebook-500 border-facebook-500' : ''}`}>
                                                <IconFacebook /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'google' &&
                                            <div className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-google-100 text-google-500 border-google-500' : ''}`}>
                                                <IconGoogle /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'google_shopping' &&
                                            <div className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-googleshopping-100 text-googleshopping-500 border-googleshopping-500' : ''}`}>
                                                <IconGoogleShopping /> <span className="ml-1">{'Google Shopping'}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className="flex flex-1"></div>
                    </div>
                    {
                        this.renders.campaigns((this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') ? "social" : (this.state.channel === 'google_shopping' ? 'shopping' : "search")).map((campaign) => {
                            return (
                                <div>
                                    <div className="mt-6">
                                        <h3 className="mb-0 font-bold">
                                            <span className="opacity-50">Campaign</span>{" "}{campaign.name}
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Creatives beloning to campaign
                                        </p>
                                    </div>
                                    <div className="rounded-md bg-gray-100 px-4 pb-4 mt-4">
                                        {
                                            this.state.channel === 'google' &&
                                            <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                                <ul className="flex flex-row items-end">
                                                    {[{ id: 0, name: "Search ads" }].map((item, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.types[campaign.id] && this.state.types[campaign.id].name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                onClick={() => {
                                                                    this.state.types[campaign.id] = item;
                                                                    this.setState({
                                                                        activesearchsub: item.id,
                                                                        types: this.state.types
                                                                    });
                                                                }}>
                                                                {item.name}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                                </ul>
                                            </nav>
                                        }
                                        {
                                            this.state.channel === 'google_shopping' &&
                                            <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                                <ul className="flex flex-row items-end">
                                                    {[{ id: 0, name: "Shopping ads" }].map((item, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.types[campaign.id] && this.state.types[campaign.id].name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                onClick={() => {
                                                                    this.state.types[campaign.id] = item;
                                                                    this.setState({
                                                                        activesearchsub: item.id,
                                                                        types: this.state.types
                                                                    });
                                                                }}>
                                                                {item.name + ((campaign.shopping_campaigntype && campaign.shopping_campaigntype.value === 'smart') ? " (smart)" : "")}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                                </ul>
                                            </nav>
                                        }
                                        {
                                            (this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') &&
                                            <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                                <ul className="flex flex-row items-end">
                                                    {this.renders.previews(campaign).map((item, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.types[campaign.id] && this.state.types[campaign.id].name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                onClick={() => {
                                                                    this.state.types[campaign.id] = item;
                                                                    this.setState({
                                                                        activesearchsub: item.name,
                                                                        types: this.state.types
                                                                    }, () => {
                                                                        console.log(this.state.types);
                                                                    });
                                                                }}>
                                                                {item.name}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                                </ul>
                                            </nav>
                                        }
                                        {
                                            this.state.channel === 'google_shopping' &&
                                            <AdvertisingPreviewShoppingAll
                                                shoppingName={this.state.shoppingName}
                                                campaign={campaign}
                                                shopping={this.state.shopping}
                                            />
                                        }
                                        {
                                            this.state.channel === 'google' &&
                                            <AdvertisingPreviewSearchAll
                                                campaign={campaign}
                                                search={this.state.search}
                                                creatives={this.state.creatives}
                                                locations={Array.isArray(this.state.locations) ? this.state.locations : []}
                                            />
                                        }
                                        {
                                            (this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') &&
                                            <AdvertisingPreviewSocial
                                                type={this.state.types[campaign.id] ? this.state.types[campaign.id].value : ""}
                                                channel={this.state.channel}
                                                client={this.state.client}
                                                campaign={campaign}
                                                creatives={this.state.creatives}
                                                locations={Array.isArray(this.state.locations) ? this.state.locations : []}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

/*
<div style={{width: '100%'}}>
                {
                    <div className="preview">
                        {
                            this.state.loading &&
                            <Loader
                                title={"Loading report"}
                            />
                        }
                        {
                            this.state.logo &&
                            <div className="preview-logo">
                                <img style={{width: '100%'}} src={this.state.logo}/>
                            </div>
                        }
                        {
                            (this.state.name || this.state.text) &&
                            <div className="preview-item">
                                {
                                    this.state.name &&
                                    <div className="preview-name">
                                        {this.state.name}
                                    </div>
                                }
                                {
                                    this.state.text &&
                                    <div className="preview-text">
                                        {this.state.text}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {
                    this.renders.images().length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_image}
                            title={"Facebook image ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_image: !this.state.facebook_image
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.images().map((item) => {
                                        return this.renders.languages().map((language) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesFacebookImage
                                                        page={this.state.page}
                                                        creatives={this.renders.item(item)}
                                                        language={language}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        })
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.videos().length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_video}
                            title={"Facebook video ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_video: !this.state.facebook_video
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.videos().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesFacebookVideo
                                                        page={this.state.page}
                                                        creatives={this.renders.item(item)}
                                                        language={lang}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        })
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.carousels().length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_carousel}
                            title={"Facebook carousel ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_carousel: !this.state.facebook_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.carousels().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesFacebookCarousel
                                                        page={this.state.page}
                                                        body={item}
                                                        content={item}
                                                        language={lang}
                                                        noContainer={true}
                                                        noHighlight={true}
                                                    />
                                                </div>
                                            )
                                        });
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.dynamic().length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_carousel}
                            title={"Facebook dynamic ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_carousel: !this.state.facebook_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.dynamic().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesFacebookCarousel
                                                        page={this.state.page}
                                                        body={item}
                                                        content={item}
                                                        dynamic={true}
                                                        language={lang}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        });
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.vertical().length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_story}
                            title={"Facebook story ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_story: !this.state.facebook_story
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.vertical().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesVertical
                                                        page={this.state.page}
                                                        creatives={this.renders.item(item)}
                                                        language={lang}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        });
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.images().length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_image}
                            title={"Instagram image ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_image: !this.state.instagram_image
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.images().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesInstagramImage
                                                        page={this.state.page}
                                                        creatives={this.renders.item(item)}
                                                        language={lang}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        });
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.videos().length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_video}
                            title={"Instagram video ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_video: !this.state.instagram_video
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.videos().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return(
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesInstagramVideo
                                                        page={this.state.page}
                                                        creatives={this.renders.item(item)}
                                                        language={lang}
                                                        noContainer={true}
                                                    />
                                                </div>
                                            )
                                        });
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.carousels().length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_carousel}
                            title={"Instagram carousel ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_carousel: !this.state.instagram_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.carousels().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesInstagramCarousel
                                                        page={this.state.page}
                                                        body={item}
                                                        content={item}
                                                        language={lang}
                                                        noContainer={true}
                                                        noHighlight={true}
                                                        showArrows={false}
                                                        autoLoop={true}
                                                        onChange={() => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    })
                                }

                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.dynamic().length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_carousel}
                            title={"Instagram dynamic ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_carousel: !this.state.instagram_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.dynamic().map((item) => {
                                        return this.renders.languages().map((lang) => {
                                            return (
                                                <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                    <AdvertisingCreativesInstagramCarousel
                                                        page={this.state.page}
                                                        body={item}
                                                        content={item}
                                                        dynamic={true}
                                                        language={lang}
                                                        noContainer={true}
                                                        noHighlight={true}
                                                        showArrows={false}
                                                        autoLoop={true}
                                                        onChange={() => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    })
                                }

                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.renders.vertical().length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_story}
                            title={"Instagram story ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_story: !this.state.instagram_story
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.vertical().map((item) => {
                                        return this.renders.languages().map((lang) => {return (
                                            <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                <AdvertisingCreativesVertical
                                                    page={this.state.page}
                                                    creatives={this.renders.item(item)}
                                                    language={lang}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )})
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.channels.google &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.google_search}
                            title={"Google ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    google_search: !this.state.google_search
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.searchNoInsert().map((item) => {
                                        return (
                                            <div className="preview-Items-inner-item-search" style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                {
                                                    this.renders.languages().map((lang) => {
                                                        return(
                                                            <AdvertisingCreativesSearchPreview
                                                                item={item}
                                                                creatives={this.state.creatives}
                                                                language={lang}
                                                                url={this.state.site.url}
                                                                noContainer={true}
                                                                selectedKeyword={{}}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        )})
                                }
                                {
                                    this.renders.searchInsert().map((item) => {
                                        return (
                                            <div className="preview-Items-inner">
                                                {
                                                    this.renders.keywords().map((keyword) => {
                                                        return(
                                                            <div className="preview-Items-inner">
                                                                {
                                                                    this.renders.languages().map((lang) => {
                                                                        return(
                                                                            <div className="preview-Items-inner-item-search" style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                                                <AdvertisingCreativesSearchPreview
                                                                                    item={item}
                                                                                    creatives={this.state.creatives}
                                                                                    language={lang}
                                                                                    url={keyword.url}
                                                                                    noContainer={true}
                                                                                    selectedKeyword={keyword}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    )})
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>

                    </div>
                }
            </div>
 */

export default Preview;
