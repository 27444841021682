import React, {Component} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {}

    componentDidMount() {}

    functions = {};

    renders = {};

    render(){
        return (
            <div style={{zIndex: 9999}} className="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    {
                        /*
                         <!--
                      Background overlay, show/hide based on modal state.

                      Entering: "ease-out duration-300"
                        From: "opacity-0"
                        To: "opacity-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100"
                        To: "opacity-0"
                    -->
                         */
                    }
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" aria-hidden="true"></div>

                    {/*
                        <!-- This element is to trick the browser into centering the modal contents. -->
                    */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true">&#8203;</span>
                    {/*
                         <!--
                      Modal panel, show/hide based on modal state.

                      Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    -->
                    */}
                    <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                        <div className="bg-white rounded-lg">
                            <div className="px-4 py-4 flex-row flex border-b rounded-t-lg">
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        {this.props.title}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        {this.props.subtitle}
                                    </p>
                                </div>
                                <div>
                                    <button className="bg-transparent text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                        onClick={() => {
                                            try{
                                                this.props.onClose();
                                            }catch (e) {}
                                        }}>
                                        <FeatherIcon className="stroke-current" size={16} icon={"x"}/>
                                    </button>
                                </div>
                            </div>
                            <div className="px-4 py-4">
                                {this.props.children}
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-4 flex flex-row items-center border-t">
                            <div className="flex-1 flex justify-start">
                                <button onClick={() => {
                                    try{
                                        this.props.onClose();
                                    }catch (e) {}
                                }} className="px-3 py-2 pl-0 text-gray-500 bg-white rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                    Close
                                </button>
                            </div>
                            {
                                this.props.redButton &&
                                <div>
                                    <button onClick={() => {
                                        try{
                                            this.props.onRedButtonClick();
                                        }catch (e) {}
                                    }} className="ml-2 px-3 py-2 text-white bg-red-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                        {this.props.redButtonText}
                                    </button>
                                </div>
                            }
                            {
                                this.props.blueButton &&
                                <div>
                                    <button onClick={() => {
                                        try{
                                            this.props.onBlueButtonClick();
                                        }catch (e) {}
                                    }} className="px-3 ml-2 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                        {this.props.blueButtonText}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;
