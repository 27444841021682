import React, {Component} from 'react';
import './style.css';
import {SlideDown} from 'react-slidedown'
import 'sweetalert/dist/sweetalert.css'
import Dropdown from "../dropdown";
import Input from "../input";
import TextArea from "../text-area";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import FeatherIcon from "feather-icons-react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";
import AdvertisingShoppingTemplatePreview from "../advertising-shopping-template-preview";

class AdvertisingPreviewShoppingAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: {},
            search: {},
            campaign: {},
            locations: [],
            width: null
        };
    };

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            shopping: this.props.shopping,
            shoppingName: this.props.shoppingName,
            width: this.props.width
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            shopping: nextProps.shopping,
            shoppingName: nextProps.shoppingName,
            width: nextProps.width
        });
    }

    componentWillMount() {
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <div className="">
                <AdvertisingShoppingTemplatePreview
                    shoppingName={this.state.shoppingName}
                    item={this.state.campaign ? this.state.campaign : {}}
                    shopping={this.state.shopping?this.state.shopping:{}}
                    width={this.state.width}
                />
            </div>
        )
    }
}

export default AdvertisingPreviewShoppingAll;
