import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CSVReader from 'react-csv-reader'
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import {SlideDown} from "react-slidedown";
import Accordion from "../accordion";
import AdvertisingKeywordsNegativeAsset from "../advertising-keywords-negative-asset";
import AdvertisingKeywordsNegativeAdd from "../advertising-keywords-negative-add";
import AdvertisingKeywordsNegativeList from "../advertising-keywords-negative-list";

class AdvertisingKeywordsNegative extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            site: {},
            channels: {},
            client: {},
            keywords: [],
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            site: nextProps.site,
            channels: nextProps.channels,
            client: nextProps.client,
            keywords: nextProps.keywords,
        });
    }

    componentDidMount() {
        this.setState({
            open: this.props.open,
            site: this.props.site,
            channels: this.props.channels,
            client: this.props.client,
            keywords: this.props.keywords,
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <Accordion
                inner={true}
                icon_color={"#0185fe"}
                title={"Negative Keywords"}
                subtitle={""}
                noPadding={false}
            >
                <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                    <div className="Section">
                        <AdvertisingKeywordsNegativeAdd
                            keywords={this.state.keywords}
                            channels={this.state.channels}
                            onChange={(value) => {
                                this.state.keywords = value;
                                this.setState({
                                    keywords: this.state.keywords
                                }, () => {
                                    this.props.onChange(this.state.keywords);
                                })
                            }}
                        />
                    </div>
                    <div className="Section">
                        <AdvertisingKeywordsNegativeAsset
                            keywords={this.state.keywords}
                            client={this.state.client}
                            onChange={(value) => {
                                this.state.keywords = value;
                                this.setState({
                                    keywords: this.state.keywords
                                }, () => {
                                    this.props.onChange(this.state.keywords);
                                })
                            }}
                        />
                    </div>
                    {
                        this.state.keywords.length > 0 &&
                        <div style={{paddingTop: '10px'}} className="Section">
                            <AdvertisingKeywordsNegativeList
                                keywords={this.state.keywords}
                                onChange={(value) => {
                                    this.state.keywords = value;
                                    this.setState({
                                        keywords: this.state.keywords
                                    }, () => {
                                        this.props.onChange(this.state.keywords);
                                    })
                                }}
                            />
                        </div>
                    }
                </div>
            </Accordion>
        )
    }
}

export default AdvertisingKeywordsNegative;
