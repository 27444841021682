import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon, InformationCircleIcon, MenuIcon, PhotographIcon, PlayIcon, SearchIcon, StarIcon, TrashIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

class DisplayBannerSkyscraper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: false,
            logo: false,
            headlines: [],
            bodies: [],
            cta: {},
            error: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            image: this.props.image,
            logo: this.props.logo,
            headlines: this.props.headlines,
            bodies: this.props.bodies,
            cta: this.props.cta,
            main_color: this.props.main_color,
            accent_color: this.props.accent_color,
            error: this.props.error,
            subType: this.props.subType
        })
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            image: nextProps.image,
            logo: nextProps.logo,
            headlines: nextProps.headlines,
            bodies: nextProps.bodies,
            cta: nextProps.cta,
            main_color: nextProps.main_color,
            accent_color: nextProps.accent_color,
            error: nextProps.error,
            subType: nextProps.subType
        })
    }

    renders = {
        headline: () => {
            try {
                if (this.state.headlines.length > 0 && this.state.headlines[0] !== "") {
                    return this.state.headlines[0]
                } else {
                    return "Headline ..."
                }
            } catch (error) {
                return "Headline ..."
            }
        },
        body: () => {
            try {
                if (this.state.bodies.length > 0 && this.state.bodies[0] !== "") {
                    return this.state.bodies[0]
                } else {
                    return "Description ..."
                }
            } catch (error) {
                return "Description ..."
            }
        },
        image: () => {
            try {
                if (this.state.image !== "") {
                    return this.state.image
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
        logo: () => {
            try {
                if (this.state.logo !== "") {
                    return this.state.logo
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
        cta: () => {
            try {
                if (this.state.cta.value !== "none") {
                    return this.state.cta.name
                } else {
                    return "No button"
                }
            } catch (error) {
                return "No button"
            }
        },
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div
                className={`${this.state.subType === "IMAGE_AD" ? "flex flex-col justify-center w-full" : "flex flex-col justify-center"}`}
                style={this.state.subType === "IMAGE_AD" ? { maxWidth: 600 } : {}}
            >
                {
                    this.state.subType == "IMAGE_AD" &&
                    <div>
                        <div className='border-2 border-gray-400 rounded-lg overflow-hidden w-full'
                            style={{ maxHeight: 500 }}
                        >
                            <div className='p-1 bg-gray-800 h-full'>
                                <div className='bg-white h-full w-full rounded-lg'>
                                    <div className='flex w-full p-4 h-80'>
                                        <div className='flex flex-col w-full'>
                                            <div className='flex flex-col space-y-4 px-12 pt-2 pl-8'>
                                                <div className='bg-gray-300 w-full flex items-center justify-center h-40'>
                                                    <PhotographIcon className='h-10 w-10 text-gray-500' />
                                                </div>
                                                <div className='flex flex-col space-y-2'>
                                                    <div className='bg-gray-300 w-full h-4'></div>
                                                    <div className='bg-gray-300 w-full h-4'></div>
                                                </div>
                                                <div className='bg-gray-300 w-full flex items-center justify-center'>
                                                    <PhotographIcon className='h-10 w-10 text-gray-500' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''
                                            style={{ minWidth: 100, maxWidth: 250 }}
                                        >
                                            <div className=''
                                                style={{
                                                    backgroundImage: `url(${this.renders.image()})`,
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            >
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex justify-center items-center h-5 bg-gray-800 w-full rounded-b-lg rounded-t-sm'>
                                <div className='h-3 rounded-b-lg bg-gray-600'
                                    style={{ width: "20%" }}></div>
                            </div>

                        </div>
                    </div>
                }

                {
                    this.state.subType !== "IMAGE_AD" &&
                    <div
                        className='flex flex-col w-80 p-2 rounded-md bg-gray-200 relative'>
                        <div className='absolute top-0 right-0'>
                            <div className='flex flex-col items-center justify-center w-5 h-5 bg-white mt-1'>
                                <InformationCircleIcon className="w-5 h-5 text-google-500" />
                            </div>
                        </div>
                        <div className={this.state.subType !== "IMAGE_AD" ? 'w-full rounded-lg bg-white' : "bg-white"}>
                            {
                                !this.state.image &&
                                <div className='flex flex-col items-center justify-center w-full rounded-t-lg bg-gray-50'
                                    style={{ height: 250 }}
                                >
                                    <div className='text-sm'>Problem loading image data</div>
                                </div>
                            }
                            <img className={this.state.subType !== "IMAGE_AD" ? 'w-full rounded-t-lg' : "w-full"} src={this.renders.image()} />
                            {
                                this.state.subType !== "IMAGE_AD" &&
                                <div className='flex flex-col pt-2 px-4 pb-6 text-center'>
                                    <div className='flex flex-col items-center justify-center pb-2'>
                                        {
                                            this.state.logo &&
                                            <img
                                                className='h-12 w-12'
                                                src={this.renders.logo()}
                                            />
                                        }
                                    </div>
                                    <div className='text-3xl text-black font-semibold leading-tight'>
                                        {this.functions.truncText(this.renders.headline(), 30)}
                                    </div>
                                    <div className='text-lg text-gray-700 pb-2'>
                                        {this.functions.truncText(this.renders.body(), 90)}
                                    </div>
                                    <div>
                                        <div className='flex justify-center space-x-4'>
                                            <div className='px-4 py-2 text-sm border rounded-md w-full'>
                                                Close
                                            </div>
                                            <div className='px-4 py-2 text-sm border rounded-md bg-purple-500 shadow text-white w-full'
                                                style={this.state.accent_color && this.state.accent_color !== "" ? { backgroundColor: this.state.accent_color } : {}}
                                            >
                                                {this.renders.cta()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                }
            </div >

        )
    }
}

export default DisplayBannerSkyscraper;
