import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";

class AdvertisingCreativesCarouselSlides extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            edit: this.props.edit,
            channels: this.props.channels,
            variations: this.props.variations,
            content: this.props.content,
            error: this.props.error,
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            content: nextProps.content,
            edit: nextProps.edit,
            open: nextProps.open,
            variations: nextProps.variations,
            channels: nextProps.channels,
            error: nextProps.error
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            open: this.props.open,
            edit: this.props.edit,
            content: this.props.content,
            variations: this.props.variations,
            channels: this.props.channels,
            error: this.props.error
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        index: (item) => {
            if(item.video){
                let index = 0;
                let videos = this.state.content.filter((item) => {
                    return item.video && !item.button && !item.error && item.url
                });
                for(let i = 0; i < videos.length; i++){
                    if(videos[i].id === item.id){
                        index = (i + 1);
                        break;
                    }
                }
                return 'video ' + index;
            }else if(item.image){
                let index = 0;
                let images = this.state.content.filter((item) => {
                    return item.image && !item.button && !item.error && item.url
                });
                for(let i = 0; i < images.length; i++){
                    if(images[i].id === item.id){
                        index = (i + 1);
                        break;
                    }
                }
                return 'image ' + index;
            }
        },
        emoji: (value) => {
            try{
                const emojisArray = toArray(value);
                const newValue = emojisArray.reduce((previous, current) => {
                    if (typeof current === "string") {
                        return previous + current;
                    }
                    return previous + current.props.children;
                }, "");
                return newValue;
            }catch (e) {
                return value
            }
        }
    };

    render() {
        if(this.state.content.filter((item) => {
            return item.url && !item.error && !item.button && item.carousel_show
        }).length > 0 && this.state.variations.carousel){
            return (
                <div style={{width: '100%', paddingTop: '20px'}}>
                    <div className="Section-Inner">
                        <div className="Section-InnerTitle">
                            <div className={cx("InputContainerTitleText")}>
                                Carousel Title and Descriptions
                            </div>
                            {
                                <div className={cx("InputContainerTitleIcons")}>
                                    {
                                        this.state.channels.facebook &&
                                        <div style={{marginLeft: '3px', marginTop: '2px'}}>
                                            <IconFacebook noMargin={true}/>
                                        </div>
                                    }
                                    {
                                        this.state.channels.instagram &&
                                        <div style={{marginLeft: '3px', marginTop: '2px'}}>
                                            <IconInstagram noMargin={true}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {
                            this.state.content.filter((item) => {
                                return !item.button && !item.error && item.carousel_show
                            }).map((item, index) => {
                                return (
                                    <div style={index === 0 ? {paddingTop: '0px'} : {paddingTop: '10px'}} className="Section">
                                        <div className="CarouselSlide-preview">
                                            {
                                                item.image &&
                                                <img src={item.url} style={{width: '100%'}}/>
                                            }
                                            {
                                                item.video &&
                                                <video controls={false} loop={true} autoPlay={true} muted={true} style={{width: '100%', height: '100%'}}>
                                                    <source src={item.url} type="video/mp4"/>
                                                </video>
                                            }
                                        </div>
                                        <div className="CarouselSlide-input CarouselSlide-name">
                                            <Input
                                                maxLength={25}
                                                noTitle={true}
                                                disabled={true}
                                                type={'text'}
                                                tight={true}
                                                value={this.renders.index(item)}
                                                size={'full'}
                                            />
                                        </div>
                                        <div className="CarouselSlide-input">
                                            <Input
                                                maxLength={25}
                                                noTitle={true}
                                                type={'text'}
                                                tight={true}
                                                placeholder={'Title'}
                                                error={(item.carousel_title === "" || !item.carousel_title) && this.state.error}
                                                value={this.renders.emoji(item.carousel_title)}
                                                size={'full'}
                                                onChange={(event) => {
                                                    item.carousel_title = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="CarouselSlide-input">
                                            <Input
                                                maxLength={20}
                                                noTitle={true}
                                                type={'text'}
                                                tight={true}
                                                placeholder={'Description'}
                                                error={(item.carousel_description === "" || !item.carousel_description) && this.state.error}
                                                value={this.renders.emoji(item.carousel_description)}
                                                size={'full'}
                                                onChange={(event) => {
                                                    item.carousel_description = event.target.value;
                                                    this.setState({
                                                        content: this.state.content
                                                    }, () => {
                                                        this.props.onChange(this.state.content);
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }else{
            return(<div></div>)
        }
    }
}

export default AdvertisingCreativesCarouselSlides;
