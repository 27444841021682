import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAgencies() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/agency";
        return apiRegister.call(options, url);
    }

    static getClients(agency) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + '/client?agency=' + agency;
        return apiRegister.call(options, url);
    }

    static getCampaignsByClient(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?client="+id+"&page_size=100000&external_campaigns=true";
        return apiRegister.call(options, url);
    }

    static getCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/campaign/" + data.id + "?external=" + data.external + "&insights=true&startdate=" + data.start_date + "&enddate=" + data.end_date;
        return apiRegister.call(options, url);
    }

}

export {calls}