import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Dropdown from "../../dropdown";
import { calls } from "./calls";
import Datepicker from '../../datepicker';
import Input from '../../input';

class TargetingFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            adset: {},
            campaign: {},
            client: {},
            channels: {},
            category: {},
            details: {},
            categories: [],
            behaviors: [],
            locations: [],
            pages: [],
            instagram_accounts: [],
            behaviors_categories: [],
            loading_behaviors: false,
            interests: [],
            interests_search: '',
            loading_interests: false,
            loading_apps: false,
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting,
            client: nextProps.client,
            channels: nextProps.channels,
            details: nextProps.details,
            adset: nextProps.adset,
            campaign: nextProps.campaign,
            locations: nextProps.locations ? nextProps.locations : [],
        });
    }

    componentWillMount() { }

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting,
            client: this.props.client,
            channels: this.props.channels,
            details: this.props.details,
            adset: this.props.adset,
            campaign: this.props.campaign,
            locations: this.props.locations ? this.props.locations : [],
        }, () => {

            try {
                this.functions.estimate();
            } catch (e) {
                console.log(e);
            }

            this.functions.behaviors();

            if (!(this.state.targeting && this.state.targeting.facebook && Array.isArray(this.state.targeting.facebook.audiences))) {
                this.functions.audience();
            }

            if (!(this.state.targeting && this.state.targeting.apps && Array.isArray(this.state.targeting.facebook.apps))) {
                this.functions.apps();
            }

        });
    }

    init = {};

    functions = {
        behaviors: () => {
            let channel = this.state.channels.facebook ? 'facebook' : 'instagram';
            let behavior_data = {
                client: this.state.client.id,
                channel: channel,
            };
            this.setState({
                loading_behaviors: true
            }, () => {
                calls.getBehaviors(behavior_data).then((response) => {
                    this.setState({
                        loading_behaviors: false,
                        behaviors: response.data
                    })
                })
            })
        },
        removeBehavior: (value) => {
            if (this.state.channels.facebook) {
                this.state.targeting.facebook.behaviors = this.state.targeting.facebook.behaviors.filter((item) => {
                    return item.name !== value.name
                })
            } else {
                this.state.targeting.instagram.behaviors = this.state.targeting.instagram.behaviors.filter((item) => {
                    return item.name !== value.name
                })
            }
            this.setState({
                targeting: this.state.targeting
            }, () => {
                this.functions.estimate();
                //this.props.onChange(this.state.targeting);
            })
        },
        interests: (search) => {
            let channel = this.state.channels.facebook ? 'facebook' : 'instagram';
            let behavior_data = {
                client: this.state.client.id,
                channel: channel,
                query: search
            };
            if (search !== "") {
                this.setState({
                    loading_interests: true
                }, () => {
                    calls.getInterests(behavior_data).then((response) => {
                        this.setState({
                            loading_interests: false,
                            interests: response.data
                        })
                    })
                })
            } else {
                this.setState({
                    loading_interests: false,
                    interests: []
                });
            }
        },
        removeInterest: (value) => {
            if (this.state.channels.facebook) {
                this.state.targeting.facebook.interests = this.state.targeting.facebook.interests.filter((item) => {
                    return item.name !== value.name
                });
            } else {
                this.state.targeting.instagram.interests = this.state.targeting.instagram.interests.filter((item) => {
                    return item.name !== value.name
                });
            }
            this.setState({
                targeting: this.state.targeting
            }, () => {
                this.functions.estimate();
                //this.props.onChange(this.state.targeting);
            })
        },
        audience: () => {
            if (!this.state.loading_audience) {
                this.setState({
                    loading_audience: true
                }, () => {
                    calls.getAudience({
                        channel: "facebook",
                        client: this.state.client.id
                    }).then((response) => {
                        if (this.state.targeting.facebook) {
                            this.state.targeting.facebook.audiences = response.data;
                        } else {
                            this.state.targeting.facebook = {
                                audiences: response.data
                            };
                        }
                        this.setState({
                            targeting: this.state.targeting,
                            loading_audience: false
                        }, () => {
                            this.props.onChange(this.state.targeting);
                        })
                    }, (error) => {
                        this.setState({
                            loading_audience: false
                        })
                    });
                })
            }
        },
        pages: () => {
            if (!this.state.loading_pages) {
                this.setState({
                    loading_pages: true
                }, () => {
                    calls.getPages({
                        client: this.state.client.id
                    }).then((response) => {
                        if (this.state.targeting.facebook) {
                            this.state.targeting.facebook.pages = response.data;
                        } else {
                            this.state.targeting.facebook = {
                                pages: response.data
                            };
                        }
                        this.setState({
                            targeting: this.state.targeting,
                            loading_pages: false
                        }, () => {
                            this.props.onChange(this.state.targeting);
                        })
                    }, (error) => {
                        this.setState({
                            loading_pages: false
                        })
                    });
                })
            }
        },
        events: () => {
            if (!this.state.loading_events) {
                this.setState({
                    loading_events: true
                }, () => {
                    calls.getEvents({
                        client: this.state.client.id,
                        page: (this.state.targeting.facebook.selected_page ? this.state.targeting.facebook.selected_page.id : null)
                    }).then((response) => {
                        if (this.state.targeting.facebook) {
                            this.state.targeting.facebook.events = response.data;
                        } else {
                            this.state.targeting.facebook = {
                                events: response.data
                            };
                        }
                        this.setState({
                            targeting: this.state.targeting,
                            loading_events: false
                        }, () => {
                            this.props.onChange(this.state.targeting);
                        })
                    }, (error) => {
                        this.setState({
                            loading_events: false
                        })
                    });
                })
            }
        },
        apps: () => {
            if (!this.state.loading_apps) {
                this.setState({
                    loading_apps: true
                }, () => {
                    calls.getApps({
                        client: this.state.client.id
                    }).then((response) => {
                        if (this.state.targeting.facebook) {
                            this.state.targeting.facebook.apps = response.data;
                        } else {
                            this.state.targeting.facebook = {
                                apps: response.data
                            };
                        }
                        this.setState({
                            targeting: this.state.targeting,
                            loading_apps: false
                        }, () => {
                            this.props.onChange(this.state.targeting);
                        })
                    }, (error) => {
                        this.setState({
                            loading_apps: false
                        })
                    });
                })
            }
        },
        instagramAccounts: (page_token) => {
            calls.getInstagramAccounts({
                client: this.state.client.id,
                page_token: page_token,
                page: (this.state.targeting.facebook.selected_page ? this.state.targeting.facebook.selected_page.id : null)
            }).then((response) => {
                if (this.state.targeting.facebook) {
                    this.state.targeting.facebook.instagram_accounts = response.data;
                } else {
                    this.state.targeting.facebook = {
                        instagram_accounts: response.data
                    };
                }
                this.setState({
                    targeting: this.state.targeting,
                    loading_instagram_accounts: false
                }, () => {
                    this.props.onChange(this.state.targeting);
                })
            }, (error) => {
                this.setState({
                    loading_instagram_accounts: false
                })
            });
        },
        createPageToken: () => {
            if (!this.state.loading_instagram_accounts) {
                this.setState({
                    loading_instagram_accounts: true
                }, () => {
                    calls.createPageToken({
                        client: this.state.client.id,
                        page: (this.state.targeting.facebook.selected_page ? this.state.targeting.facebook.selected_page.id : null)
                    }).then((response) => {
                        this.functions.instagramAccounts(response.data);
                    }, (error) => {
                        this.setState({
                            loading_instagram_accounts: false
                        })
                    });
                })
            }
        },
        estimate: () => {

            //console.log(this.state.channels);
            //console.log(this.state.targeting);
            //console.log(this.state.locations);
            //console.log(this.state.details);
            //console.log(this.state.adset);
            //console.log(this.state.campaign);

            let channels = [];
            let channel = 'facebook';

            //POSITIONS
            let facebook_positions = [];
            let instagram_positions = [];
            let network_positions = [];
            let messenger_positions = [];

            if (!this.state.targeting.placement.all) {
                if (this.state.targeting.placement.feed) {
                    facebook_positions.push('feed');
                }
                if (this.state.targeting.placement.right_hand_column) {
                    facebook_positions.push('right_hand_column');
                }
                if (this.state.targeting.placement.instant_article) {
                    facebook_positions.push('instant_article');
                }
                if (this.state.targeting.placement.marketplace) {
                    facebook_positions.push('marketplace');
                }
                if (this.state.targeting.placement.story) {
                    facebook_positions.push('story');
                }
                if (this.state.targeting.placement.search) {
                    facebook_positions.push('search');
                }
                if (this.state.targeting.placement.video_feeds) {
                    facebook_positions.push('video_feeds');
                }
                if (this.state.targeting.placement.messenger_inbox) {
                    messenger_positions.push('messenger_home');
                }
                if (this.state.targeting.placement.messenger_story) {
                    messenger_positions.push('story');
                }
                if (this.state.targeting.placement.story_instagram) {
                    instagram_positions.push('story');
                }
                if (this.state.targeting.placement.feed_instagram) {
                    instagram_positions.push('stream');
                }
                if (this.state.targeting.placement.explore) {
                    instagram_positions.push('explore');
                }
                if (this.state.targeting.placement.audience_rewarded_video) {
                    network_positions.push('rewarded_video');
                }
                if (this.state.targeting.placement.audience_classic) {
                    network_positions.push('classic');
                }
                if (this.state.targeting.placement.audience_instream_video) {
                    network_positions.push('instream_video');
                }
                //PUSH CHANNELS
                if (messenger_positions.length > 0) {
                    channels.push('messenger');
                }
                if (instagram_positions.length > 0) {
                    channels.push('instagram');
                }
                if (network_positions.length > 0) {
                    channels.push('audience_network');
                }
                if (facebook_positions.length > 0) {
                    channels.push('facebook');
                }
            }

            let targeting_spec = {
                excluded_geo_locations: {
                    countries: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && !item.include && (item.level === '4' || item.channels.facebook.type == "country") }).map((item) => { return item.channels.facebook.id }),
                    regions: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && !item.include && (item.level === '3' || item.channels.facebook.type == "region") }).map((item) => { return { key: item.channels.facebook.id } }),
                    cities: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && !item.include && (item.level === '2' || (item.channels.facebook.type == "city" || item.channels.facebook.type == "subcity")) }).map((item) => { return { key: item.channels.facebook.id } }),
                    zips: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && !item.include && item.channels.facebook.type == "zip" }).map((item) => { return { key: item.channels.facebook.id } }),
                    places: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && !item.include && item.channels.facebook.type !== "zip" && item.channels.facebook.type !== "city" && item.channels.facebook.type !== "subcity" && item.channels.facebook.type !== "region" && item.channels.facebook.type !== "country" }).map((item) => { return { key: item.channels.facebook.id } }),
                },
                geo_locations: {
                    countries: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && item.include && (item.level === '4' || item.channels.facebook.type == "country") }).map((item) => { return item.channels.facebook.id }),
                    regions: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && item.include && (item.level === '3' || item.channels.facebook.type == "region") }).map((item) => { return { key: item.channels.facebook.id } }),
                    cities: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && item.include && (item.level === '2' || (item.channels.facebook.type == "city" || item.channels.facebook.type == "subcity")) }).map((item) => { return { key: item.channels.facebook.id } }),
                    zips: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && item.include && item.channels.facebook.type == "zip" }).map((item) => { return { key: item.channels.facebook.id } }),
                    places: this.state.locations.filter((item) => { return item.channels.facebook && !item.custom && item.include && item.channels.facebook.type !== "zip" && item.channels.facebook.type !== "city" && item.channels.facebook.type !== "subcity" && item.channels.facebook.type !== "region" && item.channels.facebook.type !== "country" }).map((item) => { return { key: item.channels.facebook.id } }),
                    custom_locations: this.state.locations.filter((item) => { return item.custom }).map((item) => { return { "latitude": item.lat, "longitude": item.lng, "radius": item.radius / 1000, "distance_unit": "kilometer" } }),
                    /*
                    regions: this.state.locations.filter((item) => {
                        return !item.custom && !item.negative && item.level === "3" && item.include
                    }).map((item) => {
                        return { key: item.channels.facebook.id }
                    }),
                    cities: this.state.locations.filter((item) => {
                        return !item.custom && !item.negative && item.level === "2" && item.include
                    }).map((item) => {
                        return { key: item.channels.facebook.id }
                    }),
                    countries: this.state.locations.filter((item) => {
                        return !item.custom && !item.negative && item.level === "4" && item.include
                    }).map((item) => {
                        return item.channels.facebook.id
                    }),
                    custom_locations: this.state.locations.filter((item) => {
                        return !item.intersection && item.custom && !item.negative && item.include
                    }).map((item) => {
                        return {
                            "latitude": item.lat,
                            "longitude": item.lng,
                            "radius": item.radius / 1000,
                            "distance_unit": "kilometer"
                        }
                    }),
                    */
                },
                genders: this.state.targeting.gender.value === 0 ? [] : (this.state.targeting.gender.value === 1 ? [1] : [2]),
                age_min: this.state.targeting.age_min.value,
                age_max: this.state.targeting.age_max.value,
                publisher_platforms: channels,
                facebook_positions: facebook_positions,
                instagram_positions: instagram_positions,
                messenger_positions: messenger_positions,
                device_platforms: [],
                flexible_spec: [
                    {
                        interests: this.state.targeting[channel].interests ? this.state.targeting[channel].interests.map((item) => {
                            return { id: item.id, name: item.name }
                        }) : [],
                        behaviors: this.state.targeting[channel].behaviors ? this.state.targeting[channel].behaviors.map((item) => {
                            return { id: item.id, name: item.name }
                        }) : []
                    }
                ]
            };

            //CUSTOM AUDIENCE custom_audiences
            if (Array.isArray(this.state.targeting.facebook.selected_audience) && this.state.targeting.facebook.selected_audience.length > 0) {
                targeting_spec.custom_audiences = [];
                this.state.targeting.facebook.selected_audience.map((item) => {
                    targeting_spec.custom_audiences.push(item.id);
                })
            }

            let estimate_data = {
                client: this.state.client.id,
                channel: channel,
                targeting: targeting_spec
            };

            if (this.state.campaign && this.state.campaign.budget && this.state.campaign.budget.facebook_instagram && this.state.campaign.budget.facebook_instagram.focus) {
                estimate_data.goal = this.state.campaign.budget.facebook_instagram.focus.value;
            }

            if (this.state.campaign && this.state.campaign.budget && this.state.campaign.budget.facebook_instagram && this.state.campaign.budget.facebook_instagram.focus.value === "conversion") {
                estimate_data.promoted_object = {};
                if (this.state.adset.conversion && this.state.adset.conversion.facebook && this.state.adset.conversion.facebook.value) {
                    if (this.state.adset.conversion.facebook.conversion_event.value === 'PURCHASED') {
                        estimate_data.promoted_object.custom_event_type = 'PURCHASE'
                    } else if (this.state.adset.conversion.facebook.conversion_event.value === 'INITIATED_CHECKOUT') {
                        estimate_data.promoted_object.custom_event_type = 'INITIATED_CHECKOUT'
                    } else if (this.state.adset.conversion.facebook.conversion_event.value === 'ADDED_TO_CART') {
                        estimate_data.promoted_object.custom_event_type = 'ADD_TO_CART'
                    } else if (this.state.adset.conversion.facebook.conversion_event.value === 'REGISTER') {
                        estimate_data.promoted_object.custom_event_type = 'COMPLETE_REGISTRATION'
                    } else {
                        estimate_data.promoted_object.custom_event_type = 'PURCHASE'
                    }
                }
            }

            calls.getFacebookAudience(estimate_data).then((response) => {
                let estimate = {};
                if (Array.isArray(response.data) && response.data.length > 0) {
                    estimate = response.data[0];
                }
                this.state.targeting[channel].estimate = estimate;
                this.setState({
                    targeting: this.state.targeting
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.targeting);
                    }
                })
            }, (error) => {
                this.setState({
                    targeting: this.state.targeting
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.targeting);
                    }
                })
            });
        }
    };

    renders = {
        categories: () => {
            let a = [];
            try {
                this.state.behaviors.map((item) => {
                    return item.path[0];
                }).map((x) => {
                    if (!a.includes(x)) {
                        a.push(x);
                    }
                });
                return a.map((item) => {
                    return { name: item }
                });
            } catch (e) {
                return a;
            }
        },
        behaviors: () => {
            try {
                return this.state.behaviors.filter((item) => {
                    return item.path[0] === this.state.behaviors_category.name;
                })
            } catch (e) {
                return [];
            }
        },
        selectedBehaviors: () => {
            let behaviors = [];
            if (this.state.channels.facebook) {
                if (this.state.targeting.facebook) {
                    behaviors = this.state.targeting.facebook.behaviors ? this.state.targeting.facebook.behaviors : [];
                }
            } else {
                if (this.state.targeting.instagram) {
                    behaviors = this.state.targeting.instagram.behaviors ? this.state.targeting.instagram.behaviors : [];
                }
            }
            return behaviors;
        },
        selectedInterests: () => {
            let interests = [];
            if (this.state.channels.facebook) {
                if (this.state.targeting.facebook) {
                    interests = this.state.targeting.facebook.interests ? this.state.targeting.facebook.interests : [];
                }
            } else {
                if (this.state.targeting.instagram) {
                    interests = this.state.targeting.instagram.interests ? this.state.targeting.instagram.interests : [];
                }
            }
            return interests;
        },
        audiences: () => {
            try {
                return Array.isArray(this.state.targeting.facebook.audiences) ? this.state.targeting.facebook.audiences : [];
            } catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <div className="flex flex-1 flex-col">
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] && (this.state.campaign.budget["facebook_instagram"].focus && this.state.campaign.budget["facebook_instagram"].focus.value == "APP_INSTALLS") &&
                    <div className="flex flex-row pb-3">
                        <Input
                            noBorder={false}
                            noTitle={false}
                            title={"Exact App store URL"}
                            type={'text'}
                            white={false}
                            placeholder={''}
                            disabled={false}
                            value={this.state.targeting.facebook.app_url ? this.state.targeting.facebook.app_url : ""}
                            size={'full'}
                            onChange={(event) => {
                                this.state.targeting.facebook.app_url = event.target.value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] && (this.state.campaign.budget["facebook_instagram"].focus && this.state.campaign.budget["facebook_instagram"].focus.value == "APP_INSTALLS") &&
                    <div className="flex flex-row pb-3">
                        <Dropdown
                            title={"Select application"}
                            size={'full'}
                            search={true}
                            searchFilter={true}
                            showError={true}
                            filter={true}
                            showType={true}
                            loading={this.state.loading_apps}
                            placeholder={this.state.loading_apps ? "Loading apps" : (Array.isArray(this.state.targeting.facebook.apps) && this.state.targeting.facebook.apps.length > 0 ? "Search apps ..." : "No apps available")}
                            options={Array.isArray(this.state.targeting.facebook.apps) ? this.state.targeting.facebook.apps : []}
                            value={{}}
                            onChange={(value) => {
                                this.state.targeting.facebook.app = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] && (this.state.campaign.budget["facebook_instagram"].focus && this.state.campaign.budget["facebook_instagram"].focus.value == "APP_INSTALLS") &&
                    this.state.targeting.facebook &&
                    this.state.targeting.facebook.app &&
                    <div className='flex flex-row mb-3 Location-Item'>
                        <div className="flex flex-row flex-1 justify-center items-center">
                            {this.state.targeting.facebook.app.name}
                            <div className="flex flex-1"></div>
                            <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                onClick={() => {
                                    delete this.state.targeting.facebook.app;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.targeting);
                                        }
                                    })
                                }}>
                                <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                            </button>
                        </div>
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] && (this.state.campaign.budget["facebook_instagram"].budget_level && this.state.campaign.budget["facebook_instagram"].budget_level.value == "adset_budget") &&
                    <div className="flex flex-row pb-3">
                        <Input
                            noBorder={false}
                            noTitle={false}
                            title={"Adset budget"}
                            type={'number'}
                            white={false}
                            placeholder={''}
                            disabled={false}
                            value={this.state.targeting.facebook.daily_budget ? this.state.targeting.facebook.daily_budget : 0}
                            size={'full'}
                            onChange={(event) => {
                                this.state.targeting.facebook.daily_budget = +event.target.value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] && (!this.state.campaign.budget["facebook_instagram"].budget_level || (this.state.campaign.budget["facebook_instagram"].budget_level && this.state.campaign.budget["facebook_instagram"].budget_level.value == "campaign_budget")) &&
                    <div className="flex flex-row pb-3">
                        <div className="flex flex-1 mr-2">
                            <Input
                                noBorder={false}
                                noTitle={false}
                                title={"Min budget"}
                                type={'number'}
                                white={false}
                                placeholder={''}
                                disabled={false}
                                value={this.state.targeting.facebook.min_budget ? this.state.targeting.facebook.min_budget : 0}
                                size={'full'}
                                onChange={(event) => {
                                    this.state.targeting.facebook.min_budget = +event.target.value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.targeting);
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-1 ml-2">
                            <Input
                                noBorder={false}
                                noTitle={false}
                                title={"Max budget"}
                                type={'number'}
                                white={false}
                                placeholder={''}
                                disabled={false}
                                value={this.state.targeting.facebook.max_budget ? this.state.targeting.facebook.max_budget : 0}
                                size={'full'}
                                onChange={(event) => {
                                    this.state.targeting.facebook.max_budget = +event.target.value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.targeting);
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook && this.state.campaign && this.state.campaign.budget && this.state.campaign.budget["facebook_instagram"] &&
                    <div className="pb-3">
                        <Datepicker
                            size={'full'}
                            showTime={true}
                            border={true}
                            showNoEndDateSwitch={true}
                            disableEndDate={("no_end_date" in this.state.targeting.facebook) ? this.state.targeting.facebook.no_end_date : this.state.campaign.budget["facebook_instagram"].no_end_date}
                            startDate={this.state.targeting.facebook.start_date ? this.state.targeting.facebook.start_date : this.state.campaign.budget["facebook_instagram"].start_date}
                            endDate={this.state.targeting.facebook.end_date ? this.state.targeting.facebook.end_date : this.state.campaign.budget["facebook_instagram"].end_date}
                            onStartDate={(value) => {
                                this.state.targeting.facebook.start_date = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                            onEndDate={(value) => {
                                this.state.targeting.facebook.end_date = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                            updateNoEndDate={(value) => {
                                this.state.targeting.facebook.no_end_date = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.targeting);
                                    }
                                })
                            }}
                        />
                    </div>
                }
                {
                    false &&
                    this.state.targeting && this.state.targeting.facebook &&
                    <div className="pb-3">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select page (optional)"}
                                size={'full'}
                                search={true}
                                searchFilter={true}
                                showError={true}
                                filter={true}
                                showType={true}
                                loading={this.state.loading_pages}
                                placeholder={this.state.loading_pages ? "Loading pages" : (Array.isArray(this.state.targeting.facebook.pages) && this.state.targeting.facebook.pages.length > 0 ? "Search pages ..." : "No pages available")}
                                options={Array.isArray(this.state.targeting.facebook.pages) ? this.state.targeting.facebook.pages : []}
                                value={this.state.page ? this.state.page : {}}
                                onChange={(value) => {
                                    this.state.targeting.facebook.selected_page = value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.createPageToken();
                                        this.functions.events();
                                        this.functions.estimate();
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.targeting.facebook &&
                    this.state.targeting.facebook.selected_page &&
                    <div className='flex flex-row mb-3 Location-Item'>
                        <div className="flex flex-row flex-1 justify-center items-center">
                            {this.state.targeting.facebook.selected_page.name} #{this.state.targeting.facebook.selected_page.id}
                            <div className="flex flex-1"></div>
                            <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                onClick={() => {
                                    delete this.state.targeting.facebook.selected_page;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.estimate();
                                    })
                                }}>
                                <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                            </button>
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.targeting && this.state.targeting.facebook && this.state.targeting.facebook.selected_page && this.state.targeting.facebook.selected_page.id &&
                    <div className="pb-3">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select instagram account"}
                                size={'full'}
                                search={true}
                                searchFilter={true}
                                showError={true}
                                filter={true}
                                showType={true}
                                loading={this.state.loading_instagram_accounts}
                                placeholder={this.state.loading_instagram_accounts ? "Loading accounts" : (Array.isArray(this.state.targeting.facebook.instagram_accounts) && this.state.targeting.facebook.instagram_accounts.length > 0 ? "Search accounts ..." : "No accounts available")}
                                options={Array.isArray(this.state.targeting.facebook.instagram_accounts) ? this.state.targeting.facebook.instagram_accounts : []}
                                value={this.state.insta ? this.state.insta : {}}
                                onChange={(value) => {
                                    this.state.targeting.facebook.selected_instagram = value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.estimate();
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.targeting.facebook &&
                    this.state.targeting.facebook.selected_instagram &&
                    <div className='flex flex-row mb-3 Location-Item'>
                        <div className="flex flex-row flex-1 justify-center items-center">
                            {this.state.targeting.facebook.selected_instagram.username} #{this.state.targeting.facebook.selected_instagram.id}
                            <div className="flex flex-1"></div>
                            <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                onClick={() => {
                                    delete this.state.targeting.facebook.selected_instagram;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.estimate();
                                    })
                                }}>
                                <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                            </button>
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.targeting && this.state.targeting.facebook && this.state.targeting.facebook.selected_page && this.state.targeting.facebook.selected_page.id &&
                    <div className="pb-3">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select page event (optional)"}
                                size={'full'}
                                search={true}
                                searchFilter={true}
                                showError={true}
                                filter={true}
                                showType={true}
                                loading={this.state.loading_events}
                                placeholder={this.state.loading_events ? "Loading events" : (Array.isArray(this.state.targeting.facebook.events) && this.state.targeting.facebook.events.length > 0 ? "Search events ..." : "No events available")}
                                options={Array.isArray(this.state.targeting.facebook.events) ? this.state.targeting.facebook.events : []}
                                value={this.state.event ? this.state.event : {}}
                                onChange={(value) => {
                                    this.state.targeting.facebook.selected_event = value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.estimate();
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.targeting.facebook &&
                    this.state.targeting.facebook.selected_event &&
                    <div className='flex flex-row mb-3 Location-Item'>
                        <div className="flex flex-row flex-1 justify-center items-center">
                            {this.state.targeting.facebook.selected_event.name} #{this.state.targeting.facebook.selected_event.id}
                            <div className="flex flex-1"></div>
                            <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                onClick={() => {
                                    delete this.state.targeting.facebook.selected_event;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.functions.estimate();
                                    })
                                }}>
                                <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                            </button>
                        </div>
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.facebook &&
                    <div className="pb-3">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select audience"}
                                size={'full'}
                                search={true}
                                searchFilter={true}
                                showError={true}
                                filter={true}
                                showType={true}
                                loading={this.state.loading_audience}
                                placeholder={this.state.loading_audience ? "Loading audience" : (this.renders.audiences().length > 0 ? "Search audience ..." : "No audience available")}
                                options={
                                    this.renders.audiences().filter((item) => {
                                        return (Array.isArray(this.state.targeting.facebook.selected_audience) ? (this.state.targeting.facebook.selected_audience.filter((inner_item) => {
                                            return inner_item.id === item.id
                                        }).length < 1) : true);
                                    })}
                                value={this.state.remarketing_audience ? this.state.remarketing_audience : {}}
                                onChange={(value) => {
                                    if (value.status_code === 200) {
                                        if (!Array.isArray(this.state.targeting.facebook.selected_audience)) {
                                            this.state.targeting.facebook.selected_audience = [];
                                        }
                                        this.state.targeting.facebook.selected_audience.push(value);
                                        this.setState({
                                            targeting: this.state.targeting
                                        }, () => {
                                            this.functions.estimate();
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.targeting.facebook &&
                    Array.isArray(this.state.targeting.facebook.selected_audience) &&
                    this.state.targeting.facebook.selected_audience.map((item) => {
                        return (
                            <div className='flex flex-row mb-3 Location-Item'>
                                <div className="flex flex-row flex-1 justify-center items-center">
                                    {item.name}
                                    <span
                                        className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full ml-3",
                                            {
                                                ["bg-green-100 text-green-500"]: item.type === "LOOKALIKE",
                                                ["bg-purple-100 text-purple-500"]: item.type === "WEBSITE",
                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.type === "SHOPPING"
                                            })
                                        }>
                                        {item.type}
                                    </span>
                                    <div className="flex flex-1"></div>
                                    <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                        onClick={() => {
                                            this.state.targeting.facebook.selected_audience = this.state.targeting.facebook.selected_audience.filter((audience) => {
                                                return audience.id !== item.id;
                                            });
                                            this.setState({
                                                targeting: this.state.targeting
                                            }, () => {
                                                this.functions.estimate();
                                            })
                                        }}>
                                        <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="flex flex-1 flex-row">
                    <div className="flex flex-1 pr-3">
                        <Dropdown
                            filter={false}
                            searchFilter={false}
                            search={false}
                            title={'Behaviors'}
                            size={'full'}
                            loading={this.state.loading_behaviors}
                            placeholder={"Select category here ..."}
                            options={this.renders.categories()}
                            value={this.state.behaviors_category ? this.state.behaviors_category : {}}
                            onChange={(value) => {
                                this.setState({
                                    behaviors_category: value
                                });
                            }}
                        />
                    </div>
                    <div className="flex flex-1 pl-3">
                        <Dropdown
                            filter={true}
                            searchFilter={false}
                            search={false}
                            noTitle={false}
                            title={"Behaviors options"}
                            size={'full'}
                            disabled={!this.state.behaviors_category}
                            removeArrow={!this.state.behaviors_category}
                            loading={this.state.loading_behaviors}
                            placeholder={this.state.behaviors_category ? "Select behavior here .." : "First Select category"}
                            options={this.renders.behaviors()}
                            value={{}}
                            onChange={(value) => {
                                if (this.state.channels.facebook) {
                                    if (!this.state.targeting.facebook.behaviors) {
                                        this.state.targeting.facebook.behaviors = [];
                                    }
                                    if (this.state.targeting.facebook.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.facebook.behaviors.push(value);
                                    }
                                } else {
                                    if (!this.state.targeting.instagram.behaviors) {
                                        this.state.targeting.instagram.behaviors = [];
                                    }
                                    if (this.state.targeting.instagram.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.instagram.behaviors.push(value);
                                    }
                                }
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.functions.estimate();
                                    //this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                </div>
                {
                    this.state.behaviors_category && false &&
                    <div className="flex flex-1 pt-3">
                        <Dropdown
                            filter={true}
                            searchFilter={false}
                            search={false}
                            noTitle={true}
                            size={'full'}
                            disabled={!this.state.behaviors_category}
                            noArrow={!this.state.behaviors_category}
                            loading={this.state.loading_behaviors}
                            placeholder={this.state.behaviors_category ? "Select behavior here .." : "Select category first"}
                            options={this.renders.behaviors()}
                            value={{}}
                            onChange={(value) => {
                                if (this.state.channels.facebook) {
                                    if (!this.state.targeting.facebook.behaviors) {
                                        this.state.targeting.facebook.behaviors = [];
                                    }
                                    if (this.state.targeting.facebook.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.facebook.behaviors.push(value);
                                    }
                                } else {
                                    if (!this.state.targeting.instagram.behaviors) {
                                        this.state.targeting.instagram.behaviors = [];
                                    }
                                    if (this.state.targeting.instagram.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.instagram.behaviors.push(value);
                                    }
                                }
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.functions.estimate();
                                    //this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
                {
                    (this.state.channels.facebook || this.state.channels.instagram) && this.renders.selectedBehaviors().length > 0 &&
                    <div className="pt-3">
                        <div className="Locations-Items">
                            {
                                this.renders.selectedBehaviors().map((item) => {
                                    return (
                                        <div className="targetingItem">
                                            <div style={{ backgroundColor: "#fcfcfc" }} className="Location-Item">
                                                <div className="Location-ItemLeft">
                                                    {item.name}
                                                </div>
                                                <div className="flex flex-1"></div>
                                                <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                                    onClick={() => {
                                                        this.functions.removeBehavior(item);
                                                    }}>
                                                    <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    <div className="pt-3">
                        <Dropdown
                            filter={true}
                            searchFilter={true}
                            search={true}
                            title={'Interests'}
                            size={'full'}
                            loading={this.state.loading_interests}
                            placeholder={"Search here .."}
                            options={this.state.interests}
                            value={this.state.interests_search}
                            onSearch={(value) => {
                                this.functions.interests(value);
                            }}
                            onChange={(value) => {
                                if (this.state.channels.facebook) {
                                    if (!this.state.targeting.facebook.interests) {
                                        this.state.targeting.facebook.interests = [];
                                    }
                                    if (this.state.targeting.facebook.interests.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.facebook.interests.push(value);
                                    }
                                } else {
                                    if (!this.state.targeting.instagram.interests) {
                                        this.state.targeting.instagram.interests = [];
                                    }
                                    if (this.state.targeting.instagram.interests.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.instagram.interests.push(value);
                                    }
                                }
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.functions.estimate();
                                    //this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
                {
                    (this.state.channels.facebook || this.state.channels.instagram) && this.renders.selectedInterests().length > 0 &&
                    <div className="pt-3">
                        <div className="Locations-Items">
                            {
                                this.renders.selectedInterests().map((item) => {
                                    return (
                                        <div className="targetingItem">
                                            <div style={{ backgroundColor: "#fcfcfc" }} className="Location-Item">
                                                <div className="Location-ItemLeft">
                                                    {item.name}
                                                </div>
                                                <div className="flex flex-1"></div>
                                                <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                                    onClick={() => {
                                                        this.functions.removeInterest(item);
                                                    }}>
                                                    <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    false &&
                    <div className="pt-3">
                        <Dropdown
                            filter={false}
                            searchFilter={false}
                            search={false}
                            title={'Special Ad Category'}
                            size={'full'}
                            placeholder={"Select category here ..."}
                            options={[
                                { name: 'Housing', value: "HOUSING" },
                                { name: 'Credit', value: "CREDIT" },
                                { name: 'Employment', value: "EMPLOYMENT" },
                                { name: 'None', value: "NONE" }
                            ]}
                            value={this.state.targeting.special_ad_category ? this.state.targeting.special_ad_category : {}}
                            onChange={(value) => {
                                this.state.targeting.special_ad_category = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default TargetingFacebook;
