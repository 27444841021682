
import { AdjustmentsIcon, ArchiveIcon, ArrowNarrowLeftIcon, CloudIcon, ArrowRightIcon, ArrowSmDownIcon, ArrowSmUpIcon, CalculatorIcon, CalendarIcon, ChartPieIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ClipboardCheckIcon, ClipboardIcon, ClipboardListIcon, ClockIcon, CogIcon, CollectionIcon, CubeIcon, DeviceMobileIcon, DocumentDuplicateIcon, DocumentReportIcon, DocumentTextIcon, DownloadIcon, ExclamationCircleIcon, ExclamationIcon, FireIcon, FolderIcon, GlobeAltIcon, HandIcon, HomeIcon, IdentificationIcon, InformationCircleIcon, KeyIcon, LinkIcon, LockClosedIcon, LogoutIcon, MenuIcon, PaperClipIcon, PauseIcon, PencilAltIcon, PencilIcon, PhoneIcon, PhotographIcon, PlayIcon, PlusIcon, RefreshIcon, StopIcon, TemplateIcon, UploadIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon, XCircleIcon, XIcon, BeakerIcon, LightningBoltIcon, ArrowLeftIcon, UserRemoveIcon, TrendingUpIcon, CurrencyDollarIcon } from '@heroicons/react/outline';
import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { apiRegister } from '../services/apiRegister';
import { clientRegister } from '../services/clientRegister';
import { draftRegister } from '../services/draftRegister';
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import DropdownTailwind from './dropdownTailwind';
import WarningModalTailwind from './warningModalTailwind';
import LeavePageModal from './leavePageModal';
import { Iterable, isCollection } from 'immutable';

import { adFacebook } from '../validators/adFacebook';
import { adgroupFacebook } from '../validators/adgroupFacebook';
import { campaignFacebook } from '../validators/campaignFacebook';

import { campaignLinkedin } from '../validators/campaignLinkedin';
import { adgroupLinkedin } from '../validators/adgroupLinkedin';
import { adLinkedin } from '../validators/adLinkedin';

import { campaignTiktok } from '../validators/campaignTiktok';
import { adgroupTikTok } from '../validators/adgroupTiktok';
import { adTiktok } from '../validators/adTiktok';

import { campaignTwitter } from '../validators/campaignTwitter';
import { adgroupTwitter } from '../validators/adgroupTwitter';
import { adTwitter } from '../validators/adTwitter';

import { campaignGoogle } from '../validators/campaignGoogle';
import { adgroupGoogle } from '../validators/adgroupGoogle';
import { extensionGoogle } from '../validators/extensionGoogle';
import { performanceMax } from '../validators/performanceMax';
import { searchGoogle } from '../validators/searchGoogle';

import { campaignSnapchat } from '../validators/campaignSnapchat';
import { adgroupSnapchat } from '../validators/adgroupSnapchat';
import { adSnapchat } from '../validators/adSnapchat';

const navigation = [
    {
        id: 1,
        title: "Dashboard",
        regex: /^\/(v2)\/(dashboard)/,
        link: "/v2/dashboard",
        icon: HomeIcon,
    },
    {
        id: 2,
        title: "Orders",
        regex: /^\/(v2)\/(orders)/,
        link: "/v2/orders/active",
        icon: CubeIcon,
        dropdown: true,
        submenu: [
            {
                title: "Active",
                regex: /^\/(v2)\/(orders)\/(active)/,
                link: "/v2/orders/active",
                type: "active",
                icon: PlayIcon
            },
            {
                title: "Upcoming",
                regex: /^\/(v2)\/(orders)\/(upcoming)/,
                link: "/v2/orders/upcoming",
                type: "upcoming",
                icon: ClockIcon
            },
            {
                title: "Paused",
                regex: /^\/(v2)\/(orders)\/(paused)/,
                link: "/v2/orders/paused",
                type: "paused",
                icon: PauseIcon
            },
            {
                title: "Ended",
                regex: /^\/(v2)\/(orders)\/(ended)/,
                link: "/v2/orders/ended",
                type: "ended",
                icon: HandIcon
            },
            {
                title: "Archived",
                regex: /^\/(v2)\/(orders)\/(archived)/,
                link: "/v2/orders/archived",
                type: "archived",
                icon: ArchiveIcon
            },
            {
                title: "Unassigned",
                regex: /^\/(v2)\/(orders)\/(unassigned)/,
                link: "/v2/orders/unassigned",
                type: "unassigned",
                red: true,
                icon: UserRemoveIcon,
            },
            {
                title: "Drafts",
                regex: /^\/(v2)\/(drafts)/,
                link: "/v2/drafts",
                icon: PencilAltIcon,
                type: "draft",
                dropdown: false
            },
            {
                title: "Templates",
                regex: /^\/(v2)\/(templates)/,
                link: "/v2/templates",
                type: "template",
                icon: ClipboardListIcon,
            }
        ]
    },
    {
        id: 3,
        title: "New order",
        active: "/v2/orders/new",
        regex: /^\/(v2)\/(orders)\/(new)/,
        hidden: true,
        icon: CubeIcon,
        submenu: [
            {
                title: "Client",
                regex: /^\/(v2)\/(orders)\/(new)$/,
                link: "/v2/orders/new",
                icon: IdentificationIcon
            },
            {
                title: "Information",
                regex: /^\/(v2)\/(orders)\/(new)\/(information)/,
                link: "/v2/orders/new/information",
                icon: InformationCircleIcon
            }
        ]
    },
    {
        id: 4,
        title: "Order",
        regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}/,
        hidden: true,
        icon: CubeIcon,
        submenu: [
            /*
            {
                title: "Actions",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(actions)/,
                type: "actions",
                link: "/v2/orders/:id/actions",
                icon: LightningBoltIcon
            },
            */
            {
                title: "Campaigns",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}$/,
                link: "/v2/orders/:id",
                type: "campaigns",
                dropdown: false,
                icon: FolderIcon
            },
            {
                title: "Budget management",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(budgetplaner)$/,
                link: "/v2/orders/:id/budgetplaner",
                type: "budgetplaner",
                dropdown: false,
                icon: CurrencyDollarIcon
            },
            {
                title: "A/B tests",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(abtest)$/,
                link: "/v2/orders/:id/abtest",
                type: "ab_test",
                dropdown: false,
                icon: BeakerIcon
            },
            {
                title: "Pacing",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(performance)$/,
                link: "/v2/orders/:id/performance",
                type: "performance",
                dropdown: false,
                icon: TrendingUpIcon
            },
            {
                title: "Import",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(campaigns)\/(import)/,
                link: "/v2/orders/:id/campaigns/import",
                dropdown: false,
                icon: DownloadIcon
            },
            {
                title: "Drafts",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(drafts)$/,
                link: "/v2/orders/:id/drafts",
                type: "drafts",
                icon: ClipboardListIcon
            },
            {
                title: "Pending",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(drafts)\/(pending)/,
                link: "/v2/orders/:id/drafts/pending",
                type: "drafts_pending",
                icon: RefreshIcon
            },
            {
                title: "Errors",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(drafts)\/(error)/,
                link: "/v2/orders/:id/drafts/error",
                type: "drafts_error",
                icon: ExclamationCircleIcon
            },
            /*
            {
                title: "Reports",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(reports)/,
                link: "/v2/orders/:id/reports",
                type: "reports",
                icon: ChartPieIcon
            },
            */
            {
                title: "Assets",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(creatives)/,
                link: "/v2/orders/:id/creatives",
                type: "creatives",
                icon: PhotographIcon
            },
            {
                title: "Documents",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(documents)/,
                link: "/v2/orders/:id/documents",
                type: "documents",
                icon: PaperClipIcon
            },
            /*
            {
                title: "Go back",
                regex: '',
                back: true,
                link: "/v2/orders/active",
                icon: ArrowNarrowLeftIcon
            },
            */
            {
                title: "Settings",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(settings)/,
                link: "/v2/orders/:id/settings",
                type: "settings",
                icon: CogIcon
            },
            {
                title: "Change logs",
                regex: /^\/(v2)\/(orders)\/[0-9a-z]{20,}\/(changelog)/,
                link: "/v2/orders/:id/changelog",
                type: "changelog",
                icon: DocumentTextIcon
            },
        ]
    },
    {
        id: 6,
        title: "Campaigns",
        regex: /^\/(v2)\/(campaigns)/,
        link: "/v2/campaigns",
        icon: FolderIcon,
        type: "active",
        dropdown: false,
        hidden: true
    },
    // {
    //     id: 11,
    //     title: "Drafts",
    //     regex: /^\/(v2)\/(drafts)/,
    //     link: "/v2/drafts",
    //     icon: PencilAltIcon,
    //     type: "draft",
    //     dropdown: false
    // },
    {
        id: 12,
        title: "Optimize",
        regex: /^\/(v2)\/(optimize)/,
        link: "/v2/optimize",
        icon: CalculatorIcon,
        type: "optimize",
        hidden: true,
        dropdown: false
    },
    {
        id: 7,
        title: "New campaign",
        regex: /^\/(v2)\/(campaigns)\/new/,
        hidden: true,
        icon: FolderIcon,
        submenu: [
            {
                title: "Client",
                regex: /^\/(v2)\/(campaigns)\/new\/client/,
                checked: false,
                selectable: true,
                available: true,
                link: "/v2/campaigns/new/client",
                icon: IdentificationIcon
            },
            {
                title: "Order",
                regex: /^\/(v2)\/(campaigns)\/new\/order/,
                checked: false,
                selectable: false,
                available: true,
                link: "/v2/campaigns/new/order",
                icon: CubeIcon
            },
            {
                title: "Channels",
                regex: /^\/(v2)\/(campaigns)\/new\/channels/,
                checked: false,
                selectable: false,
                available: true,
                link: "/v2/campaigns/new/channels",
                icon: TemplateIcon
            },
            {
                title: "Assets",
                regex: /^\/(v2)\/(campaigns)\/new\/creatives/,
                checked: false,
                selectable: false,
                available: true,
                link: "/v2/campaigns/new/creatives",
                icon: PhotographIcon
            },
            {
                title: "Facebook",
                regex: /^\/(v2)\/(campaigns)\/new\/facebook/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/facebook",
                channel: "facebook"
            },
            {
                title: "Twitter",
                regex: /^\/(v2)\/(campaigns)\/new\/twitter/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/twitter",
                channel: "twitter"
            },
            {
                title: "TikTok",
                regex: /^\/(v2)\/(campaigns)\/new\/tiktok/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/tiktok",
                channel: "tiktok"
            },
            {
                title: "Linkedin",
                regex: /^\/(v2)\/(campaigns)\/new\/linkedin/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/linkedin",
                channel: "linkedin"
            },
            {
                title: "Google",
                regex: /^\/(v2)\/(campaigns)\/new\/google/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/google",
                channel: "google"
            },
            {
                title: "Snapchat",
                regex: /^\/(v2)\/(campaigns)\/new\/snapchat/,
                checked: false,
                selectable: false,
                available: false,
                link: "/v2/campaigns/new/snapchat",
                channel: "snapchat"
            },
            {
                title: "Preview",
                regex: /^\/(v2)\/(campaigns)\/new\/preview/,
                checked: false,
                available: true,
                link: "/v2/campaigns/new/preview",
                icon: DeviceMobileIcon
            },

            /*
            {
                title: "Summary",
                regex: /^\/(v2)\/(campaigns)\/new\/summary/,
                checked: false,
                selectable: false,
                available: true,
                link: "/v2/campaigns/new/summary",
                icon: ClipboardCheckIcon
            },
            */
            {
                title: "Go back",
                regex: '',
                back: true,
                link: "/v2/orders/active",
                icon: ArrowNarrowLeftIcon
            },
        ]
    },
    // {
    //     id: 10,
    //     title: "Templates",
    //     regex: /^\/(v2)\/(templates)/,
    //     link: "/v2/templates",
    //     type: "template",
    //     icon: ClipboardListIcon,
    // },
    {
        id: 8,
        title: "Clients",
        regex: /^\/(v2)\/(clients)/,
        link: "/v2/clients",
        type: "client",
        icon: IdentificationIcon,
        dropdown: true,
        submenu: [
            {
                title: "All",
                regex: /^\/(v2)\/(clients)\/(all)/,
                link: "/v2/clients/all",
                type: "clients_total",
            },
            {
                title: "Active",
                regex: /^\/(v2)\/(clients)\/(active)/,
                link: "/v2/clients/active",
                type: "clients_active",
            },
            {
                title: "Paused",
                regex: /^\/(v2)\/(clients)\/(disabled)/,
                link: "/v2/clients/disabled",
                type: "clients_disabled",
            },
            {
                title: "Archived",
                regex: /^\/(v2)\/(clients)\/(archived)/,
                link: "/v2/clients/archived",
                type: "clients_archived",
            },
            {
                title: "Budgets",
                regex: /^\/(v2)\/(clients)\/(budgets)/,
                link: "/v2/clients/budgets",
                type: "clients_total"
            },
            {
                title: "Accounts",
                regex: /^\/(v2)\/(clients)\/(accounts)/,
                link: "/v2/clients/accounts",
                type: "performance_accounts"
            }
        ]
    },
    {
        id: 9,
        title: "Reports",
        regex: /^\/(v2)\/(reports)/,
        link: "/v2/reports",
        dropdown: true,
        icon: ChartPieIcon,
        submenu: [
            {
                title: "Professional",
                disabled: true,
                regex: /^\/(v2)\/(reports)\/(advanced)/,
                link: "/v2/reports/advanced",
                type: "advanced_report",
                icon: DocumentReportIcon
            },
            {
                title: "Standard",
                regex: /^\/(v2)\/(reports)\/(standard)/,
                link: "/v2/reports/standard",
                type: "standard_report",
                icon: DocumentTextIcon
            },
            {
                title: "Template",
                regex: /^\/(v2)\/(reports)\/(template)/,
                link: "/v2/reports/template",
                type: "template_report",
                icon: DocumentDuplicateIcon
            },
            /*
            {
                lineAbove: true,
                title: "Accounts",
                regex: /^\/(v2)\/(reports)\/(accounts)/,
                link: "/v2/reports/accounts",
                //type: "performance_accounts",
                icon: IdentificationIcon
            },
            */
            /*
            {
                title: "Campaigns",
                regex: /^\/(v2)\/(reports)\/(campaigns)/,
                link: "/v2/reports/campaigns",
                //type: "performance_campaigns",
                icon: FolderIcon
            }
            */
            /*
            {
                title: "Custom metric",
                regex: /^\/(v2)\/(reports)\/(metrics)\/(custom)/,
                link: "/v2/reports/metrics/custom",
                type: "custom_metric",
                icon: AdjustmentsIcon
            },
            {
                title: "Calc. metric",
                regex: /^\/(v2)\/(reports)\/(metrics)\/(calculated)/,
                link: "/v2/reports/metrics/calculated",
                type: "calculated_metric",
                icon: AdjustmentsIcon
            }
            */
        ]
    },
    {
        id: 15,
        title: "Metrics",
        regex: /^\/(v2)\/(metrics)/,
        link: "/v2/metrics",
        dropdown: true,
        icon: AdjustmentsIcon,
        submenu: [
            {
                title: "Custom",
                regex: /^\/(v2)\/(metrics)\/(custom)/,
                link: "/v2/metrics/custom",
                type: "custom_metric",
                icon: AdjustmentsIcon
            },
            {
                title: "Calculated",
                regex: /^\/(v2)\/(metrics)\/(calculated)/,
                link: "/v2/metrics/calculated",
                type: "calculated_metric",
                icon: AdjustmentsIcon
            }
        ]
    },
    {
        id: 17,
        title: "Performance",
        regex: /^\/(v2)\/(performance)/,
        link: "/v2/performance",
        dropdown: true,
        hidden: true,
        icon: CheckCircleIcon,
        submenu: [
            {
                title: "Accounts",
                regex: /^\/(v2)\/(performance)\/(accounts)/,
                link: "/v2/performance/accounts",
                type: "performance_accounts",
                icon: IdentificationIcon
            },
            {
                title: "Campaigns",
                regex: /^\/(v2)\/(performance)\/(campaigns)/,
                link: "/v2/performance/campaigns",
                type: "performance_campaigns",
                icon: IdentificationIcon
            }
        ]
    },
    {
        id: 18,
        title: "Imported data",
        regex: /^\/(v2)\/(custom-datasources)/,
        link: "/v2/custom-datasources",
        dropdown: true,
        icon: CloudIcon,
        submenu: [
            {
                title: "Files",
                regex: /^\/(v2)\/(custom-datasources)\/(datasources)/,
                link: "/v2/custom-datasources/datasources",
                type: "custom_platforms_datasources"
            },
            {
                title: "Platforms",
                regex: /^\/(v2)\/(custom-datasources)\/(platforms)/,
                link: "/v2/custom-datasources/platforms",
                type: "custom_platforms_platforms"
            }
        ]
    },
    {
        id: 19,
        title: "Warnings",
        regex: /^\/(v2)\/(warnings)/,
        link: "/v2/warnings",
        type: "warnings",
        dropdown: false,
        icon: ExclamationIcon
    },
    /*
    {
        id: 20,
        title: "Actions",
        regex: /^\/(v2)\/(actions)/,
        link: "/v2/actions",
        type: "actions",
        dropdown: false,
        icon: LightningBoltIcon
    }
    */
];

const account_menu = [
    {
        id: 13,
        title: "My profile",
        regex: /^\/(v2)\/(profile)\/(account)/,
        link: "/v2/profile/account",
        icon: UserCircleIcon,
    },
    {
        id: 19,
        title: "Connections",
        regex: /^\/(v2)\/(profile)\/(connections)/,
        link: "/v2/profile/connections",
        icon: LightningBoltIcon,
    },
    {
        id: 14,
        title: "Agents",
        regex: /^\/(v2)\/(profile)\/(agents)/,
        link: "/v2/profile/agents",
        icon: UserGroupIcon,
    },
    {
        id: 20,
        title: "Settings",
        regex: /^\/(v2)\/(profile)\/(settings)/,
        link: "/v2/profile/settings",
        icon: CogIcon,
    },
    {
        id: 12,
        title: "Sign out",
        regex: /^\/(v2)\/(settings)/,
        signout: true,
        icon: LogoutIcon,
    }
];

class SideNavigationAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            menu: navigation,
            account_menu: account_menu,
            selected: {},
            user: {},
            order: {},
            orders_statistics: {},
            order_statistics: {},
            campaigns_statistics: {},
            steps: {},
            clients: [],
            client: {},
            page: 1,
            search: "",
            minimize: false,
            hover_menu: false,
            hover_submenu_menu: false
        }
    };

    componentWillMount() {
        this.functions.client();
        this.functions.user();
    }

    componentDidMount() {
        this.functions.url();
        if (window.location.pathname.indexOf("/v2/orders/" + this.functions.getIdFromPath()) !== -1) {
            this.functions.orderStatistics();
        } else {
            this.functions.ordersStatistics();
            this.functions.campaignsStatistics();
        }
    }

    async componentWillReceiveProps() {
        this.functions.url();
    }

    functions = {
        client: async () => {
            this.setState({
                client: clientRegister.get()
            });
        },
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
            if (this.state.user && this.state.user.advanced) {
                this.state.menu = this.state.menu.map((item) => {
                    if (item.id == 9 && Array.isArray(item.submenu)) {
                        item.submenu = item.submenu.map((item) => {
                            if (item.type == "advanced_report") {
                                item.disabled = false;
                            }
                            return item;
                        });
                    }
                    if (item.id === 4 && Array.isArray(item.submenu)) {
                        item.submenu = item.submenu.map((item) => {
                            /*
                            if (item.type == "performance" || item.type == "actions") {
                                if (!this.renders.user().agency_sme) {
                                    item = false;
                                }
                            }
                            */
                            return item;
                        }).filter((item) => { return item });
                    }
                    return item;
                });
                await this.promisedSetState({
                    menu: this.state.menu
                });
            }
            if (this.state.user && this.state.user.userRole === "sales") {
                await this.promisedSetState({
                    sales: true
                });

                this.state.account_menu = [
                    {
                        id: 13,
                        title: "My profile",
                        regex: /^\/(v2)\/(profile)\/(account)/,
                        link: "/v2/profile/account",
                        icon: UserCircleIcon,
                    },
                    {
                        id: 12,
                        title: "Sign out",
                        regex: /^\/(v2)\/(settings)/,
                        signout: true,
                        icon: LogoutIcon,
                    }
                ];
                await this.promisedSetState({
                    account_menu: this.state.account_menu
                });
            } else if (this.state.user && (this.state.user.userRole === "regular" || (!this.state.user.userRole && !this.state.user.manager))) {
                this.state.account_menu = this.state.account_menu.filter((item) => { return item.id !== 14 && item.id !== 20 });
                await this.promisedSetState({
                    account_menu: this.state.account_menu
                });
            } else if (this.state.user && this.state.user.manager && (!this.state.user.userRole || (this.state.user.userRole === "manager"))) {
                this.state.account_menu = this.state.account_menu.filter((item) => { return item.id !== 20 });
                await this.promisedSetState({
                    account_menu: this.state.account_menu
                });
            }
        },
        url: async () => {
            let selected = {};
            this.state.menu = this.state.menu.map((item) => {
                if (this.renders.regex(item)) {
                    selected = item;
                    if (item.dropdown) {
                        item.open = true;
                    }
                }
                return item;
            });
            await this.promisedSetState({
                selected: selected,
                menu: this.state.menu
            });
        },
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        setOrder: async (order) => {
            this.setState({
                order: order ? order : {}
            })
        },
        ordersStatistics: async (client) => {
            await this.promisedSetState({
                loading_orders_statistics: true,
                client: client ? client : this.state.client
            });
            try {
                let response = await this.calls.ordersStatistics();
                await this.promisedSetState({ orders_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_orders_statistics: false });
        },
        orderStatistics: async () => {
            if (window.location.pathname == ("/v2/orders/" + this.functions.getIdFromPath())) {
                await this.promisedSetState({
                    order_statistics: {}
                });
            }
            await this.promisedSetState({ loading_order_statistics: true });
            try {
                let response = await this.calls.orderStatistics();
                await this.promisedSetState({ order_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_order_statistics: false });
        },
        campaignsStatistics: async (client) => {
            await this.promisedSetState({
                loading_campaigns_statistics: true,
                client: client ? client : this.state.client
            });
            try {
                let response = await this.calls.campaignsStatistics();
                await this.promisedSetState({ campaigns_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_campaigns_statistics: false });
        },
        steps: async () => {

            let steps = {
                client: draftRegister.get()["client"] ? draftRegister.get()["client"] : {},
                order: draftRegister.get()["order"] ? draftRegister.get()["order"] : {},
                wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : {},
                channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : {},
                creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : [],
                campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : [],
                adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : [],
                ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : []
            };

            // CLIENT SELECTED
            this.state.menu[6].submenu[0].hidden = steps.client.template;
            this.state.menu[6].submenu[0].checked = steps.client.id ? true : false;
            this.state.menu[6].submenu[1].selectable = steps.client.id ? true : false;

            // ORDER SELECTED
            this.state.menu[6].submenu[1].hidden = steps.order.template;
            this.state.menu[6].submenu[1].checked = steps.order.id ? true : false;
            this.state.menu[6].submenu[2].selectable = (steps.client.template || steps.order.id) ? true : false;

            // CHANNELS SELECTED
            this.state.menu[6].submenu[2].checked = Object.keys(steps.channels).length > 0 ? true : false;
            this.state.menu[6].submenu[3].selectable = Object.keys(steps.channels).length > 0 ? true : false;

            //CREATIVES
            this.state.menu[6].submenu[3].checked = Object.keys(steps.channels).length > 0 && steps.creatives.length > 0 ? true : false;

            //FACEBOOK
            this.state.menu[6].submenu[4].available = steps.channels.facebook ? true : false;
            this.state.menu[6].submenu[4].selectable = steps.channels.facebook && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[4].checked = steps.ads.filter((item) => { return item.channel === "facebook" }).length > 0 ? true : false;
            //CHECK VALIDATION FACEBOOK
            this.state.menu[6].submenu[4].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "facebook" }).length > 0) {
                //if (!steps.campaigns.filter((item) => { return item.channel === "facebook" })[0].status) {
                if (steps.campaigns.filter((item) => { return item.channel === "facebook" }).map((item) => {
                    if (item.partial_parced) {
                        return false;
                    } else {
                        return campaignFacebook.validate(item)
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[4].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "facebook" }).length > 0) {
                //if (!steps.adgroups.filter((item) => { return item.channel === "facebook" })[0].status) {
                if (steps.adgroups.filter((item) => { return item.channel === "facebook" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupFacebook.validate(adgroup)).map((item) => { return adgroupFacebook.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[4].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "facebook" }).length > 0) {
                if (steps.ads.filter((item) => { return item.channel === "facebook" }).map((ad) => {
                    if (Object.keys(adFacebook.validate(ad)).map((item) => { return adFacebook.validate(ad)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[4].error = true;
                }
            }
            if (!this.state.menu[6].submenu[4].error) {
                this.state.menu[6].submenu[4].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "facebook" }).length > 0 ? true : false;
            }


            //TWITTER
            this.state.menu[6].submenu[5].available = steps.channels.twitter ? true : false;
            this.state.menu[6].submenu[5].selectable = steps.channels.twitter && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[5].checked = steps.ads.filter((item) => { return item.channel === "twitter" }).length > 0 ? true : false;
            //CHECK VALIDATION TWITTER
            this.state.menu[6].submenu[5].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "twitter" }).length > 0) {
                //if (!steps.campaigns.filter((item) => { return item.channel === "twitter" })[0].status) {
                if (steps.campaigns.filter((item) => { return item.channel === "twitter" }).map((item) => {
                    if (item.partial_parced) {
                        return false;
                    } else {
                        return campaignTwitter.validate(item)
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[5].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "twitter" }).length > 0) {
                //if (!steps.adgroups.filter((item) => { return item.channel === "twitter" })[0].status) {
                if (steps.adgroups.filter((item) => { return item.channel === "twitter" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupTwitter.validate(adgroup)).map((item) => { return adgroupTwitter.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[5].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "twitter" }).length > 0) {
                if (steps.ads.filter((item) => { return item.channel === "twitter" }).map((ad) => {
                    if (Object.keys(adTwitter.validate(ad)).map((item) => { return adTwitter.validate(ad)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[5].error = true;
                }
            }
            if (!this.state.menu[6].submenu[5].error) {
                this.state.menu[6].submenu[5].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "twitter" }).length > 0 ? true : false;
            }

            //TIKTOK
            this.state.menu[6].submenu[6].available = steps.channels.tiktok ? true : false;
            this.state.menu[6].submenu[6].selectable = steps.channels.tiktok && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[6].checked = steps.ads.filter((item) => { return item.channel === "tiktok" }).length > 0 ? true : false;
            //CHECK VALIDATION TIKTOK
            this.state.menu[6].submenu[6].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "tiktok" }).length > 0) {
                //if (!steps.campaigns.filter((item) => { return item.channel === "tiktok" })[0].status) {
                if (steps.campaigns.filter((item) => { return item.channel === "tiktok" }).map((item) => {
                    if (item.partial_parced) {
                        return false;
                    } else {
                        return campaignTiktok.validate(item)
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[6].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "tiktok" }).length > 0) {
                //if (!steps.adgroups.filter((item) => { return item.channel === "tiktok" })[0].status) {
                if (steps.adgroups.filter((item) => { return item.channel === "tiktok" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupTikTok.validate(adgroup)).map((item) => { return adgroupTikTok.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[6].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "tiktok" }).length > 0) {
                if (steps.ads.filter((item) => { return item.channel === "tiktok" }).map((ad) => {
                    if (Object.keys(adTiktok.validate(ad)).map((item) => { return adTiktok.validate(ad)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[6].error = true;
                }
            }
            if (!this.state.menu[6].submenu[6].error) {
                this.state.menu[6].submenu[6].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "tiktok" }).length > 0 ? true : false;
            }

            //LINKEDIN
            this.state.menu[6].submenu[7].available = steps.channels.linkedin ? true : false;
            this.state.menu[6].submenu[7].selectable = steps.channels.linkedin && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[7].checked = steps.ads.filter((item) => { return item.channel === "linkedin" }).length > 0 ? true : false;
            //CHECK VALIDATION LINKEDIN
            this.state.menu[6].submenu[7].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "linkedin" }).length > 0) {
                // if (!steps.campaigns.filter((item) => { return item.channel === "linkedin" })[0].status) {
                if (steps.campaigns.filter((item) => { return item.channel === "linkedin" }).map((campaign) => {
                    if (campaign.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(campaignLinkedin.validate(campaign)).map((item) => { return campaignLinkedin.validate(campaign)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[7].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "linkedin" }).length > 0) {
                //if (!steps.adgroups.filter((item) => { return item.channel === "linkedin" })[0].status) {
                if (steps.adgroups.filter((item) => { return item.channel === "linkedin" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupLinkedin.validate(adgroup)).map((item) => { return adgroupLinkedin.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[7].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "linkedin" }).length > 0) {
                if (steps.ads.filter((item) => { return item.channel === "linkedin" }).map((ad) => {
                    if (Object.keys(adLinkedin.validate(ad)).map((item) => { return adLinkedin.validate(ad)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[7].error = true;
                }
            }
            if (!this.state.menu[6].submenu[7].error) {
                this.state.menu[6].submenu[7].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "linkedin" }).length > 0 ? true : false;
            }

            //GOOGLE
            this.state.menu[6].submenu[8].available = steps.channels.google ? true : false;
            this.state.menu[6].submenu[8].selectable = steps.channels.google && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[8].checked = steps.ads.filter((item) => { return item.channel === "google" }).length > 0 ? true : false;
            //CHECK VALIDATION GOOGLE
            this.state.menu[6].submenu[8].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "google" }).length > 0) {
                //if (!steps.campaigns.filter((item) => { return item.channel === "google" })[0].status) {
                if (steps.campaigns.filter((item) => { return item.channel === "google" }).map((campaign) => {
                    if (campaign.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(campaignGoogle.validate(campaign)).map((item) => { return campaignGoogle.validate(campaign)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[8].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "google" }).length > 0) {
                //if (!steps.adgroups.filter((item) => { return item.channel === "google" })[0].status) {
                if (steps.adgroups.filter((item) => { return item.channel === "google" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupGoogle.validate(adgroup, steps.ads, steps.campaigns)).map((item) => { return adgroupGoogle.validate(adgroup, steps.ads, steps.campaigns)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[8].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "google" }).length > 0) {

                if (steps.ads.filter((item) => { return item.channel === "google" }).map((ad) => {
                    if (ad.ad_type) {
                        if (ad.ad_type.value === "performance_max" && Object.keys(performanceMax.validate(ad)).map((inner_item) => { return performanceMax.validate(ad)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else if (ad.ad_type.value === "keywords" && (ad.name === "" || ad.keywords.length === 0)) {
                            return true;
                        } else if (ad.ad_type.value === "search_ad" && Object.keys(searchGoogle.validate(ad)).map((inner_item) => { return searchGoogle.validate(ad)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else if (ad.ad_type.value === "extension" && Object.keys(extensionGoogle.validate(ad)).map((inner_item) => { return extensionGoogle.validate(ad)[inner_item] }).filter((inner_item) => { return inner_item }).length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[8].error = true;
                }
            }
            if (!this.state.menu[6].submenu[8].error) {
                this.state.menu[6].submenu[8].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "google" }).length > 0 ? true : false;
            }

            //SNAPCHAT
            this.state.menu[6].submenu[9].available = steps.channels.snapchat ? true : false;
            this.state.menu[6].submenu[9].selectable = steps.channels.snapchat && steps.creatives.length > 0 ? true : false;
            this.state.menu[6].submenu[9].checked = steps.ads.filter((item) => { return item.channel === "snapchat" }).length > 0 ? true : false;
            //CHECK VALIDATION SNAPCHAT
            this.state.menu[6].submenu[9].error = false;
            if (steps.campaigns.filter((item) => { return item.channel === "snapchat" }).length > 0) {
                //if (typeof steps.campaigns.filter((item) => { return item.channel === "snapchat" })[0].status !== "string") {
                if (steps.campaigns.filter((item) => { return item.channel === "snapchat" }).map((campaign) => {
                    if (campaign.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(campaignSnapchat.validate(campaign)).map((item) => { return campaignSnapchat.validate(campaign)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[9].error = true;
                }
                //}
            }
            if (steps.adgroups.filter((item) => { return item.channel === "snapchat" }).length > 0) {
                //if (typeof steps.adgroups.filter((item) => { return item.channel === "snapchat" })[0].status !== "string") {
                if (steps.adgroups.filter((item) => { return item.channel === "snapchat" }).map((adgroup) => {
                    if (adgroup.partial_parced) {
                        return false;
                    } else {
                        if (Object.keys(adgroupSnapchat.validate(adgroup, steps.campaigns.filter((item) => { return item.channel === "snapchat" }), false)).map((item) => { return adgroupSnapchat.validate(adgroup, steps.campaigns.filter((item) => { return item.channel === "snapchat" }), false)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                    }
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[9].error = true;
                }
                //}
            }
            if (steps.ads.filter((item) => { return item.channel === "snapchat" }).length > 0) {
                if (steps.ads.filter((item) => { return item.channel === "snapchat" }).map((ad) => {
                    if (Object.keys(adSnapchat.validate(ad)).map((item) => { return adSnapchat.validate(ad)[item] }).filter((item) => { return item }).length > 0) { return true } else return false
                }).filter((item) => { return item }).length > 0) {
                    this.state.menu[6].submenu[9].error = true;
                }
            }
            if (!this.state.menu[6].submenu[9].error) {
                this.state.menu[6].submenu[9].error = Array.isArray(steps.wizard.errors) && steps.wizard.errors.filter((item) => { return item.channel === "snapchat" }).length > 0 ? true : false;
            }

            //PREVIEW
            this.state.menu[6].submenu[10].selectable = Object.keys(steps.channels).length > 0 && steps.ads.length > 0 ? true : false;
            this.state.menu[6].submenu[10].checked = Object.keys(steps.channels).length > 0 && steps.ads.length > 0 ? true : false;

            Object.keys(steps.channels).forEach(async item => {
                if (Array.isArray(steps.client.channels) && steps.client.channels.filter((inner_item) => { return inner_item.value === item }).length < 1) {
                    this.state.menu[7].submenu.map((item) => {
                        item.selectable = false
                        return item;
                    })
                }
            });

            await this.promisedSetState({ menu: this.state.menu });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        getClients: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getClients();
                await this.promisedSetState({ clients: response.data.map((item) => { item.image = item.logo; return item }), meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        minimizeMenu: (option) => {
            this.setState({
                minimize: option !== undefined ? option : !this.state.minimize
            });
        }
    };

    renders = {
        firstLetter: () => {
            try {
                return userRegister.get().name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        user: () => {
            try {
                if (userRegister.get()) {
                    return userRegister.get();
                } else {
                    return {}
                }
            } catch (e) {
                return {}
            }
        },
        regex: (item, string) => {
            try {
                return item.regex.test(string ? string : window.location.pathname);
            } catch (error) {
                return false;
            }
        },
        minimizeMainMenu: () => {
            if (window.location.pathname.indexOf("/v2/dashboard") !== -1) {
                return false;
            } else {
                return !this.state.hover_menu;
            }
        },
        minimizeSubMenu: () => {
            if (window.location.pathname.indexOf("/v2/orders/" + this.functions.getIdFromPath()) !== -1) {
                return !this.state.hover_sub_menu;
            } else {
                return false;
            }
        },
        preventOpenMenuOption: () => {
            return (
                window.location.pathname.indexOf("/v2/reports/advanced/" + this.functions.getIdFromPath()) !== -1 ||
                window.location.pathname.indexOf("/v2/reports/standard/" + this.functions.getIdFromPath()) !== -1 ||
                window.location.pathname.indexOf("/v2/campaigns/new") !== -1
            )
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        },
        ordersStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/ordersStatistics" + (this.state.client && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : '');
            return apiRegister.call(options, url);
        },
        orderStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderStatistics?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        campaignsStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/campaignsStatistics" + (this.state.client && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : '');
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + "10" + "&page=" + this.state.page + "&sortBy=" + "name" + "&orderBy=" + "descending" + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{
                height: "100%",
                overflowY: "scroll"
            }} className="flex-col h-full sm:flex hidden bg-white">

                {/*LOGOUT MODAL*/}
                <LeavePageModal
                    open={this.state.leave_page ? true : false}
                    onLeave={async (value) => {
                        let option = JSON.parse(JSON.stringify(this.state.leave_page));
                        await this.promisedSetState({ leave_page: null });
                        if (option && option.type == "lower_menu") {
                            if (option.signout) {
                                this.functions.logout();
                            } else {
                                this.props.history.push(option.link);
                            }
                        } else if (option && option.type == "top_menu") {
                            if (option.sub_type) {
                                this.props.history.push(option.link);
                            } else {
                                this.state.menu = this.state.menu.map((inner_item) => {
                                    inner_item.open = false;
                                    return inner_item;
                                });
                                await this.promisedSetState({
                                    selected: option,
                                    menu: this.state.menu
                                });
                                this.props.history.push(option.link);
                            }
                        }
                    }}
                    onClose={async (value) => {
                        await this.promisedSetState({ leave_page: null });
                    }}
                />

                <div className="flex-row flex flex-1">

                    {/*MENU*/}
                    <div
                        onMouseEnter={() => {
                            this.setState({
                                hover_menu: true
                            })
                        }}
                        onMouseLeave={async () => {
                            await this.promisedSetState({
                                hover_menu: false
                            })
                        }}
                        style={{ transitionProperty: "width" }}
                        className={(this.renders.minimizeMainMenu() ? "w-20" : "w-64") + " bg-purple-900 duration-150 flex flex-col relative"}>
                        <div className="flex items-center h-16 pr-4 flex-row truncate overflow-hidden pl-4">
                            <div>
                                <div className="w-12 h-12 cursor-pointer overflow-hidden relative p-1">
                                    <div className="object-cover w-full h-full inset-0" style={{ background: 'url(' + this.renders.user().logo + ') center center / contain no-repeat' }}>

                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                <div style={{ transitionProperty: "width", top: "-18px" }} className={(this.renders.minimizeMainMenu() ? "hidden" : "absolute") + " flex flex-1 flex-col"}>
                                    <div className={"truncate text-sm ml-4 font-semibold text-white"}>
                                        {this.renders.user().agency_name ? this.renders.user().agency_name : "..."}
                                    </div>
                                    <div className={"truncate text-xxs ml-4 font-medium text-white text-opacity-75"}>Agent View</div>
                                </div>
                            </div>
                        </div>
                        <nav className="px-4 py-5">
                            {this.state.menu.filter((item) => {
                                if (this.state.sales) {
                                    return [1, 2, 4, 9].includes(item.id)
                                } else {
                                    return true;
                                }
                            }).filter((item) => {
                                return !item.hidden
                            }).map((item, index) => (
                                <Fragment>
                                    <div
                                        onClick={async () => {
                                            if (item.dropdown) {
                                                this.state.menu = this.state.menu.map((inner_item) => {
                                                    if (item.open && inner_item.id === item.id) {
                                                        inner_item.open = false;
                                                    } else {
                                                        inner_item.open = (inner_item.id === item.id);
                                                    }
                                                    return inner_item;
                                                });
                                                await this.promisedSetState({
                                                    menu: this.state.menu
                                                });
                                            } else {
                                                if (this.renders.preventOpenMenuOption()) {
                                                    item.type = "top_menu";
                                                    this.setState({
                                                        leave_page: item
                                                    });
                                                } else {
                                                    this.state.menu = this.state.menu.map((inner_item) => {
                                                        inner_item.open = false;
                                                        return inner_item;
                                                    });
                                                    await this.promisedSetState({
                                                        menu: this.state.menu,
                                                        selected: item
                                                    });
                                                    this.props.history.push(item.link);
                                                }
                                            }
                                        }}
                                        key={item.link}
                                        className={classNames(
                                            !this.renders.minimizeMainMenu() && this.renders.regex(item) && !item.dropdown ? "bg-white bg-opacity-15 text-opacity-100 text-white rounded-md" : 'text-white text-opacity-50  hover:text-opacity-100',
                                            this.renders.minimizeMainMenu() && this.renders.regex(item) ? (item.dropdown ? 'text-white text-opacity-100 ' : 'text-white text-opacity-100 ') : ' text-white text-opacity-50  hover:text-opacity-100',
                                            'flex items-center px-3 py-2 text-sm font-medium cursor-pointer relative overflow-hidden whitespace-no-wrap',
                                            this.renders.minimizeMainMenu() && !item.dropdown && this.renders.regex(item) ? "bg-white bg-opacity-25" : "",
                                            item.open && "bg-white bg-opacity-15 rounded-t-md pb-3",
                                            item.id === 1 && "mb-1",
                                            item.id === 19 && "rounded-md mt-1",
                                        )}
                                        aria-current={this.renders.regex(item) ? 'page' : undefined}
                                    >
                                        {
                                            item.icon &&
                                            <item.icon
                                                className={classNames(
                                                    this.renders.regex(item) ? 'text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                    'flex-shrink-0 h-6 w-6',
                                                    this.renders.minimizeMainMenu() ? "hover:text-opacity-100" : ""
                                                )}
                                                aria-hidden="true"
                                            />
                                        }
                                        {
                                            <span className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + " overflow-hidden truncate flex flex-1 ml-3"}>{item.title}</span>
                                        }
                                        {false &&
                                            item.dropdown &&
                                            <div className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + "overflow-hidden"}>
                                                {
                                                    !item.open &&
                                                    <ChevronDownIcon className="w-5 h-5 mr-1" />
                                                }
                                                {
                                                    item.open &&
                                                    <ChevronUpIcon className="w-5 h-5 mr-1" />
                                                }
                                            </div>
                                        }

                                        {/* DRAFTS STATISTICS  */}
                                        {/* HERE FOR STATS ON SIDE ITEM.ID == 8 */}
                                        {
                                            (item.id === 6 || item.id === 10 || item.id === 18) && item.type ? (
                                                <span style={{ minWidth: "30px", paddingTop: "3px", paddingBottom: "2px" }}
                                                    className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + (this.renders.regex(item) ? "" : (item.type == "warnings" ? "bg-red-500 bg-opacity-50" : "bg-white bg-opacity-25")) + " flex overflow-hidden rounded-md justify-center items-center font-semibold text-xxs text-white  ml-1 text-center"}
                                                >
                                                    {this.state.campaigns_statistics[item.type]}
                                                </span>
                                            ) : null
                                        }

                                    </div>
                                    {
                                        //!this.renders.minimizeMainMenu() &&
                                        item.dropdown &&
                                        item.open &&
                                        <div className="overflow-hidden bg-white bg-opacity-15 rounded-b-md">
                                            <nav className={"relative"}>
                                                {item.submenu.filter((inner_item) => {
                                                    if (!this.state.sales) {
                                                        return true;
                                                    } else {
                                                        if (item.id === 9) {
                                                            return inner_item.title === "Professional" || inner_item.title === "Standard";
                                                        } else if (item.id === 2) {
                                                            return ["Active", "Upcoming", "Paused", "Ended", "Unassigned"].includes(inner_item.title);
                                                        } else {
                                                            return true;
                                                        }
                                                    }
                                                }).map((subitem, index) => (
                                                    <Fragment>
                                                        {false &&
                                                            subitem.lineAbove &&
                                                            <div className={this.renders.minimizeMainMenu() ? "py-3 px-2" : "pl-5 pr-3 py-3"}>
                                                                <div style={{ height: '1px' }} className="w-full bg-white bg-opacity-25"></div>
                                                            </div>
                                                        }
                                                        <div
                                                            onClick={() => {
                                                                if (this.renders.preventOpenMenuOption() && !subitem.disabled) {
                                                                    let subitem_copy = JSON.parse(JSON.stringify(subitem));
                                                                    subitem_copy.type = "top_menu";
                                                                    subitem_copy.sub_type = true;
                                                                    this.setState({
                                                                        leave_page: subitem_copy
                                                                    });
                                                                } else {
                                                                    if (!subitem.disabled) {
                                                                        this.props.history.push(subitem.link);
                                                                        /*
                                                                        if (subitem.link.indexOf("/:id") !== -1) {
                                                                            this.props.history.push(subitem.link.replace(":id", this.functions.getIdFromPath()));
                                                                        } else {
                                                                            this.props.history.push(subitem.link);
                                                                        }
                                                                        */
                                                                    }
                                                                }
                                                            }}
                                                            key={subitem.link}
                                                            className={classNames(
                                                                !this.renders.minimizeMainMenu() && !subitem.disabled && this.renders.regex(subitem) ? 'text-white text-opacity-100' : 'text-white text-opacity-50  hover:text-opacity-100',
                                                                subitem.back ? "text-white" : "",
                                                                subitem.disabled ? "cursor-not-allowed" : "",
                                                                'group flex items-center px-3 py-2 text-sm font-medium cursor-pointer pl-12 relative',
                                                                //subitem === item.submenu[item.submenu.length - 1] && "mb-2"
                                                            )}
                                                            aria-current={this.renders.regex(subitem) ? 'page' : undefined}
                                                        >
                                                            <div style={{ left: "22px", width: "3px", height: "100%" }} className={`${this.renders.regex(subitem) ? "bg-purple-400" : "bg-white bg-opacity-25"} absolute ${subitem === item.submenu[0] ? "rounded-t" : subitem === item.submenu[item.submenu.length - 1] ? "rounded-b" : ""}`}></div>

                                                            {/* TITLE */}
                                                            <span className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + "flex flex-1 truncate overflow-hidden"}>{subitem.title}</span>

                                                            {/* ORDERS STATISTICS */}
                                                            {item.id === 2 && subitem.type ? (
                                                                <span style={{ minWidth: "30px", paddingTop: "3px", paddingBottom: "2px" }}
                                                                    className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + (this.renders.regex(subitem) ? "bg-purple-400" : (subitem.red ? "bg-orange-500" : "bg-white bg-opacity-25")) + " overflow-hidden flex rounded-md justify-center font-semibold items-center text-xxs text-white ml-1 text-center"}
                                                                >
                                                                    {item.id === 2 && this.state.orders_statistics[subitem.type]}
                                                                </span>
                                                            ) : null}

                                                            {/* OTHER STATISTICS */}
                                                            {(item.id === 6 || item.id === 9 || item.id === 15 || item.id === 17 || item.id === 8) && subitem.type ? (
                                                                <span style={{ minWidth: "30px", paddingTop: "3px", paddingBottom: "2px" }}
                                                                    className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + (this.renders.regex(subitem) ? "bg-purple-400" : "bg-white bg-opacity-25") + " overflow-hidden flex rounded-md justify-center font-semibold items-center text-xxs text-white ml-1 text-center"}
                                                                >
                                                                    {this.state.campaigns_statistics[subitem.type]}
                                                                </span>
                                                            ) : null}

                                                        </div>
                                                    </Fragment>
                                                ))}
                                            </nav>
                                        </div>
                                    }
                                </Fragment>
                            ))}
                            {
                                false &&
                                <div className="w-full h-1"></div>
                            }
                            {
                                this.state.user && (!this.state.user.userRole || this.state.user.userRole !== "sales") &&
                                <div className={this.renders.minimizeMainMenu() ? "justify-start items-start w-full flex" : ""}>
                                    <div onClick={() => {
                                        //this.props.history.push("/v2/orders/new");
                                        if (window.location.href.indexOf("orders/active") !== -1) {
                                            try {
                                                this.props.trigger("newOrder");
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        } else {
                                            this.props.history.push("/v2/orders/active?trigger=newOrder");
                                        }
                                    }} className={(this.renders.minimizeMainMenu() ? "w-full " : "px-3 ") + ' rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white mt-1'}>
                                        <span className={this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "delay-100 opacity-100 duration-100"}>Create Order</span>
                                        <CubeIcon className={(!this.renders.minimizeMainMenu() ? "ml-2" : "") + " w-6 h-6"} />
                                    </div>
                                </div>
                            }
                        </nav>
                        <div className="flex flex-1"></div>
                        <div className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "delay-100 opacity-100 duration-100") + " font-medium transition-opacity overflow-hidden text-white pl-7 mt-4 text-xs uppercase"}>
                            ACCOUNT
                        </div>
                        <nav className="space-y-1 p-4 pt-2 pb-6">
                            {this.state.account_menu.filter((item) => {
                                if (item.id === 14 || item.id === 20) {
                                    return (this.renders.user().manager || (this.renders.user().userRole && this.renders.user().userRole === "admin"));
                                } else {
                                    return true
                                }
                            }).map((item, index) => (
                                <Fragment>
                                    <div
                                        onClick={async () => {
                                            if (this.renders.preventOpenMenuOption()) {
                                                item.type = "lower_menu";
                                                this.setState({
                                                    leave_page: item
                                                });
                                            } else {
                                                if (item.signout) {
                                                    this.functions.logout();
                                                } else {
                                                    this.props.history.push(item.link);
                                                }
                                            }
                                        }}
                                        key={item.link}
                                        className={classNames(
                                            this.renders.regex(item) ? (item.dropdown ? 'text-white text-opacity-100 bg-white bg-opacity-25' : 'text-white text-opacity-100 bg-white bg-opacity-25') : 'text-white text-opacity-50  hover:text-opacity-100',
                                            'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer relative'
                                        )}
                                        aria-current={this.renders.regex(item) ? 'page' : undefined}
                                    >
                                        {
                                            item.icon &&
                                            <item.icon
                                                className={classNames(
                                                    this.renders.regex(item) ? 'text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                    'flex-shrink-0 h-6 w-6',
                                                    this.renders.minimizeMainMenu() ? "hover:text-opacity-100" : ""
                                                )}
                                                aria-hidden="true"
                                            />
                                        }
                                        {
                                            <span className={(this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + "truncate overflow-hidden flex flex-1 ml-3"}>{item.title}</span>
                                        }
                                    </div>
                                </Fragment>
                            ))}
                        </nav>
                    </div>

                    {/*SUBEMNU*/}
                    {
                        Array.isArray(this.state.selected.submenu) &&
                        !this.state.selected.dropdown &&
                        <div
                            onMouseEnter={() => {
                                this.setState({
                                    hover_sub_menu: true
                                })
                            }}
                            onMouseLeave={async () => {
                                await this.promisedSetState({
                                    hover_sub_menu: false
                                })
                            }}
                            className={(!this.renders.minimizeMainMenu() ? "w-20" : (this.renders.minimizeSubMenu() ? "w-20" : "w-64")) + " border-l duration-150 pt-1 border-gray-100 bg-purple-900 border-opacity-50"}>
                            <div className="flex items-center w-full truncate whitespace-no-wrap flex-wrap h-16 border-b border-gray-100 border-opacity-50 pl-5 pr-5 cursor-pointer overflow-hidden">
                                {
                                    this.state.selected.id !== 4 && this.state.order &&
                                    <div className={"text-sm font-medium text-white truncate"}>
                                        {this.state.selected.title}
                                    </div>
                                }
                                {

                                    this.state.selected.id === 4 && this.state.order &&
                                    <div className={"text-sm font-medium text-white truncate"}>
                                        {this.state.order.name}
                                    </div>
                                }
                            </div>
                            <nav className="space-y-1 p-4 relative">
                                {this.state.selected.submenu.filter((inner_item) => {
                                    if (!this.state.sales) {
                                        return true;
                                    } else {
                                        if (this.state.selected.id === 4) {
                                            return inner_item.title === "Campaigns"
                                        }
                                    }
                                }).filter((inner_item) => {
                                    return ((this.state.selected.id === 7 && inner_item.available) || this.state.selected.id !== 7) && !inner_item.hidden
                                }).map((item, index) => (
                                    <Fragment>
                                        <div
                                            onClick={() => {
                                                if (item.dropdown) {
                                                    item.open = !item.open;
                                                    this.promisedSetState({
                                                        menu: this.state.menu
                                                    });
                                                } else if (item.link.indexOf("/:id") !== -1) {
                                                    this.props.history.push(item.link.replace(":id", this.functions.getIdFromPath()));
                                                } else {
                                                    if (this.state.selected.id === 7) {
                                                        if (item.selectable) {
                                                            this.props.history.push(item.link);
                                                        }
                                                    } else {
                                                        this.props.history.push(item.link);
                                                    }
                                                }
                                            }}
                                            key={item.link}
                                            className={classNames(
                                                this.state.selected.id !== 7 ? (this.renders.regex(item) ? (item.dropdown ? ' text-opacity-100' : ' text-opacity-100 bg-white bg-opacity-25') : ' text-opacity-50  hover:text-opacity-100') : "",
                                                (this.state.selected.id !== 7 || (this.state.selected.id === 7 && item.selectable)) ? "text-white text-opacity-50  hover:text-opacity-100" : "opactiy-25 cursor-not-allowed text-opacity-50",
                                                this.state.selected.id === 7 && item.selectable && this.renders.regex(item) ? "text-opacity-100 bg-white bg-opacity-25" : "",
                                                'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer text-white h-10'
                                            )}
                                            aria-current={this.renders.regex(item) ? 'page' : undefined}
                                        >
                                            {
                                                item.icon &&
                                                <item.icon
                                                    className={classNames(
                                                        this.renders.regex(item) ? 'text-opacity-100' : 'text-white text-opacity-50',
                                                        'flex-shrink-0 mr-3 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            }
                                            {item.channel === "facebook" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        //'mr-3 w-5 h-5  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4 bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "linkedin" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? 'bg-linkedin-500' : 'bg-white bg-opacity-25',
                                                        //'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4  bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "google" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? 'bg-google-500' : 'bg-white bg-opacity-25',
                                                        //'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4  bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "google_shopping" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? ' bg-googleshopping-500' : 'bg-white bg-opacity-25',
                                                        //'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4 bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "tiktok" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? ' bg-black' : 'bg-white bg-opacity-25',
                                                        // 'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4 bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "snapchat" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? ' bg-snapchat-500' : 'bg-white bg-opacity-25',
                                                        // 'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4  bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                </div>
                                            }
                                            {item.channel === "twitter" &&
                                                <div
                                                    className={classNames(
                                                        this.renders.regex(item) ? ' opacity-100' : ' opacity-50',
                                                        // this.renders.regex(item) ? ' bg-twitter-500' : 'bg-white bg-opacity-25',
                                                        //'mr-3 w-6 h-6  rounded-full p-1.5'
                                                    )}
                                                >
                                                    <div
                                                        className="block w-5 h-4 bg-contain bg-no-repeat bg-center"
                                                        style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                </div>
                                            }

                                            {/* TITLE */}
                                            <span
                                                className={("channel" in item ? "ml-4 " : "") + (!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + " flex flex-1 whitespace-no-wrap overflow-hidden"}
                                            >{item.title}</span>

                                            {
                                                item.checked && !item.error &&
                                                <CheckCircleIcon className={(!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + " overflow-hidden w-5 h-5 text-green-300"} />
                                            }
                                            {
                                                item.error &&
                                                <XCircleIcon className={(!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + " overflow-hidden w-5 h-5 text-red-400"} />
                                            }

                                            {
                                                item.dropdown &&
                                                <div>
                                                    {
                                                        !item.open &&
                                                        <ArrowSmDownIcon className={(!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + "  overflow-hidden w-5 h-5"} />
                                                    }
                                                    {
                                                        item.open &&
                                                        <ArrowSmUpIcon className={(!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + "  overflow-hidden w-5 h-5"} />
                                                    }
                                                </div>
                                            }

                                            {/* ORDER STATISTICS */}
                                            {
                                                !this.renders.minimizeSubMenu() &&
                                                    window.location.pathname.indexOf("/v2/orders/" + this.functions.getIdFromPath()) !== -1 && item.type && this.state.order_statistics[item.type] > 0 ? (
                                                    <span style={{ minWidth: "30px", paddingTop: "3px", paddingBottom: "2px" }}
                                                        className={(!this.renders.minimizeMainMenu() ? "opacity-0 w-0 " : "") + (this.renders.regex(item) ? "bg-purple-1000 text-white" : (item.type === "drafts_error" ? "bg-red-500 bg-opacity-50 text-white" : "bg-white bg-opacity-25 text-white")) + " overflow-hidden flex rounded-md justify-center items-center text-xxs font-small  ml-1 text-center"}
                                                    >
                                                        {this.state.order_statistics[item.type]}
                                                    </span>
                                                ) : null
                                            }

                                        </div>
                                    </Fragment>
                                ))}
                                {
                                    this.state.menu.filter((item) => { return item.hover }).length > 0 &&
                                    <div className="absolute left-0 right-0 top-0 bottom-0 bg-purple-1000 bg-opacity-75"></div>
                                }
                            </nav>
                        </div>
                    }
                </div>

            </div >
        )
    }

}

export default SideNavigationAgent;