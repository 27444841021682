import React, { Component, Fragment } from 'react';
import { ExclamationIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import cn from "classnames";
import { campaignSnapchat } from '../validators/campaignSnapchat';
import moment from 'moment';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';


class EditSnapchatCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizard: false,
            open: false,
            currency: "",
            campaign: {},
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            // DEFAULT BRAND_AWARENESS
            objectives: [
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - IMPRESSIONS, SWIPES, STORY_OPENS, ?? SHARE ??
                // { id: 0, name: 'Brand Awareness', value: "BRAND_AWARENESS", goal: { id: 0, name: 'Brand awareness', value: "BRAND_AWARENESS" } },
                { id: 1, name: 'Awareness', value: "AWARENESS", goal: { id: 1, name: 'awareness', value: "VARIABLE" } },
                { id: 2, name: 'Promote Places', value: "PROMOTE_PLACES", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - APP_INSTALLS, IMPRESSIONS, SWIPES, APP_PURCHASE, APP_SIGNUP, APP_ADD_TO_CART
                { id: 3, name: 'App Installs', value: "APP_INSTALLS", goal: { id: 3, name: 'VARIABLE', value: "VARIABLE" } },
                { id: 4, name: 'Drive Traffic To Website', value: "TRAFFIC_WEBSITE", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                { id: 5, name: 'Drive Traffic To App', value: "TRAFFIC_APP", goal: { id: 2, name: 'swipes', value: "SWIPES" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, STORY OPENS, ?? SHARE ?? MAYBE USES ??
                { id: 6, name: 'Engagement', value: "ENGAGEMENT", goal: { id: 6, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - IMPRESSIONS, VIDEO_VIEWS, VIDEO_VIEWS_15_SEC
                { id: 7, name: 'Video Views', value: "VIDEO_VIEWS", goal: { id: 7, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - STORY_OPENS, SWIPES, LEAD_FORM_SUBMISSIONS
                { id: 8, name: 'Lead Generation', value: "LEAD_GENERATION", goal: { id: 8, name: 'variable', value: "VARIABLE" } },
                // CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_INSTALLS, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART
                { id: 9, name: 'App Conversions', value: "APP_CONVERSIONS", goal: { id: 9, name: 'variable', value: "VARIABLE" } },
                // !!! REQUIRES PIXEL ON WEBSITE !!! CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART, APP_INSTALLS
                // OR PIXEL_PURCHASE, PIXEL_SIGNUP, PIXEL_PAGE_VIEW, PIXEL_ADD_TO_CART ?? 
                { id: 10, name: 'Website Conversions', value: "WEBSITE_CONVERSIONS", goal: { id: 10, name: 'variable', value: "VARIABLE" } },
                // !!! REQUIRES CATALOG !!! CAN HAVE MULTIPLE, SELECTED FROM DROPWDOWN - SWIPES, APP_PURCHASES, APP_SIGNUPS, APP_ADD_TO_CART, APP_INSTALLS
                { id: 11, name: 'Catalog Sales', value: "CATALOG_SALES", goal: { id: 11, name: 'variable', value: "VARIABLE" } },
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ],
            status: [],
            daily_spend_cap: [],
            lifetime_spend_cap: [],
            start_time: "00:00",
            end_time: "00:00",
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            adgroups: this.props.adgroups ? this.props.adgroups : []
        })
        if (this.state.client.channels.filter((item) => { return item.value === "snapchat" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "snapchat" })[0].currency
            })
        }
        if (this.props.updated) {
            this.setState({
                updated: this.props.updated
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        /*this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }*/
    }

    functions = {
        update: async () => {
            if (!this.state.wizard) {
            } else {
                this.state.updated.name = this.renders.name();
                this.state.updated.objective = this.renders.objective();
                this.state.updated.daily_spend_cap = this.renders.dailySpendCap();
                this.state.updated.lifetime_spend_cap = this.renders.lifetimeSpendCap();
                this.props.onUpdate(this.state.updated, this.state.adgroups);
            }
        },
        updateAdgroups: async () => {
            let campaigns = [];
            if (this.state.updated.id && this.state.updated.id !== "multiple") {
                campaigns.push(this.state.updated.id);
            } else if (this.state.updated.ids) {
                campaigns = this.state.updated.ids;
            }
            campaigns.map((campaign_id) => {
                this.state.adgroups = this.state.adgroups.map((item) => {
                    if (item.campaign === campaign_id) {
                        if (this.state.updated.objective && this.state.updated.objective.value === "REACH") {
                            item = {
                                ...item, ...{
                                    goal: this.state.updated.goal,
                                    billing_event: this.state.updated.billing_event,
                                    auto_placements: { id: 2, name: "No", value: false },
                                    placements: { snapchat: true, news_feed: false, pangle: false }
                                }
                            }
                        } else {
                            item = {
                                ...item, ...{
                                    auto_placements: { id: 1, name: "Yes", value: true },
                                    placements: { snapchat: true, news_feed: true, pangle: true },
                                    goal: this.state.updated.goal,
                                    billing_event: this.state.updated.billing_event
                                }
                            }
                        }
                    }

                    return item;
                });
            });
            this.promisedSetState({
                adgroups: this.state.adgroups
            });
        },
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/snapchat/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.updated.objective) {
                    return this.state.updated.objective;
                } else {
                    return { id: 0, name: 'Select value' }
                }
            } catch (error) {
                return { id: 0, name: 'Select value' }
            }
        },
        dailySpendCap: () => {
            try {
                if ('daily_spend_cap' in this.state.updated) {
                    return this.state.updated.daily_spend_cap;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        lifetimeSpendCap: () => {
            try {
                if ('lifetime_spend_cap' in this.state.updated) {
                    return this.state.updated.lifetime_spend_cap;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else if (this.state.campaign.runSchedule) {
                    return moment(this.state.campaign.runSchedule.start).format("YYYY-MM-DD HH:mm");
                }
            } catch (error) {
                return moment().format("YYYY-MM-DD HH:mm");
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else {
                    return { id: 1, name: "Active", value: "ACTIVE" };
                }
            } catch (error) {
                return { id: 1, name: "Active", value: "ACTIVE" };
            }
        },
        hasDailyCap: () => {
            try {
                if ("has_daily_cap" in this.state.updated) {
                    return this.state.updated.has_daily_cap;
                } else {
                    return { id: 2, name: "No cap", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No cap", value: false };
            }
        },
        hasLifeTimeCap: () => {
            try {
                if ("has_lifetime_cap" in this.state.updated) {
                    return this.state.updated.has_lifetime_cap;
                } else {
                    return { id: 2, name: "No cap", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No cap", value: false };
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    {/*Menu*/}
                    <div className="col-span-3">
                        <div className="block overflow-scroll w-full">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && (Object.keys(campaignSnapchat.validate(this.state.updated)).filter((item) => { return campaignSnapchat.validate(this.state.updated)[item] }).length > 0)) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Name*/}
                    <div className="col-span-6">
                        <InputTailwind
                            label={"Campaign name"}
                            value={this.renders.name()}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>

                    {/*objective*/}
                    <div className="col-span-6">
                        <DropdownTailwind
                            label={"Campaign objective"}
                            selected={this.state.updated.objective ? this.state.updated.objective : { id: 0, name: "Select value" }}
                            locked={true}
                            options={[]}
                            onChange={async (value) => {
                                /*
                                if (this.state.wizard) {
                                    this.state.updated.objective = value;
                                    this.state.updated.goal = value.goal;
                                    this.state.updated.billing_event = value.billing_event;
                                    await this.setState({
                                        updated: this.state.updated
                                    })

                                    //UPDATE CONNECTED ADGROUPS
                                    this.functions.updateAdgroups();
                                }
                                */
                            }}
                        />
                    </div>
                    <div className="col-span-3">
                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily spend cap</div>
                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                            <div className="flex flex-1 ml-5">
                                {this.renders.hasDailyCap().name}
                            </div>
                            <div className="relative overflow-hidden mr-5">
                                <SwitchTailwind
                                    value={this.renders.hasDailyCap().value}
                                    onSwitch={async () => {
                                        if (this.renders.hasDailyCap().value) {
                                            this.state.updated.has_daily_cap = { id: 2, name: "No cap", value: false };
                                            this.state.updated.daily_spend_cap = "";
                                        } else if (!this.renders.hasDailyCap().value) {
                                            this.state.updated.has_daily_cap = { id: 1, name: "Custom", value: true };
                                        }
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <InputTailwind
                            error={campaignSnapchat.validate(this.state.updated).daily_spend_cap}
                            label={"Daily amount"}
                            leftSection={(this.state.currency !== "" ? true : false)}
                            leftSectionLabel={(this.state.currency)}
                            type={'number'}
                            value={this.renders.dailySpendCap()}
                            onChange={(value) => {
                                this.state.updated.daily_spend_cap = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                            disabled={!this.renders.hasDailyCap().value}
                            locked={!this.renders.hasDailyCap().value}
                        />
                    </div>
                    <div className="col-span-3">
                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Lifetime spend cap</div>
                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                            <div className="flex flex-1 ml-5">
                                {this.renders.hasLifeTimeCap().name}
                            </div>
                            <div className="relative overflow-hidden mr-5">
                                <SwitchTailwind
                                    value={this.renders.hasLifeTimeCap().value}
                                    onSwitch={async () => {
                                        if (this.renders.hasLifeTimeCap().value) {
                                            this.state.updated.has_lifetime_cap = { id: 2, name: "No cap", value: false };
                                            this.state.updated.lifetime_spend_cap = "";
                                        } else if (!this.renders.hasLifeTimeCap().value) {
                                            this.state.updated.has_lifetime_cap = { id: 1, name: "Custom", value: true };
                                        }
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <InputTailwind
                            error={campaignSnapchat.validate(this.state.updated).lifetime_spend_cap}
                            label={"Lifetime amount"}
                            leftSection={(this.state.currency !== "" ? true : false)}
                            leftSectionLabel={(this.state.currency)}
                            type={'number'}
                            value={this.renders.lifetimeSpendCap()}
                            onChange={(value) => {
                                this.state.updated.lifetime_spend_cap = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                            disabled={!this.renders.hasLifeTimeCap().value}
                            locked={!this.renders.hasLifeTimeCap().value}
                        />
                    </div>
                    <div className="col-span-3">
                        <div className="col-span-3 grid grid-cols-6 col-gap-2 gap-4">
                            <div className="grid grid-cols-6 col-span-6 gap-2">
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        label={"Start date"}
                                        error={campaignSnapchat.validate(this.state.updated).start_date}
                                        value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            let time = moment(this.renders.startDate()).format("HH:mm");
                                            let new_date = value + " " + time;
                                            this.state.updated.start_date = moment(new_date);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <InputTimepickerTailwind
                                        label={"Start time"}
                                        // error={campaignSnapchat.validate(this.state.updated).start_date}
                                        value={moment(this.renders.startDate()).format("HH:mm")}
                                        onChange={(value) => {
                                            let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                            let new_date = date + " " + value;
                                            this.state.updated.start_date = moment(new_date);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 grid grid-cols-6 gap-4">
                        <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                            <InputDatepickerTailwind
                                label={"End date"}
                                error={campaignSnapchat.validate(this.state.updated).end_date}
                                disabled={!this.renders.endDate()}
                                value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                labelRight={(this.renders.endDate() ? "Disable" : "Enable")}
                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                onLabelRightClick={() => {
                                    if (this.renders.endDate()) {
                                        this.state.updated.end_date = null;
                                    } else {
                                        let new_date = moment(this.renders.startDate()).add(1, 'days');
                                        this.state.updated.end_date = moment(new_date);
                                    }
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                                onChange={(value) => {
                                    let time = moment(this.renders.endDate()).format("HH:mm");
                                    let new_date = value + " " + time;
                                    this.state.updated.end_date = moment(new_date);
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />

                        </div>
                        {
                            this.renders.endDate() &&
                            <div className="col-span-3">
                                <InputTimepickerTailwind
                                    label={"End time"}
                                    error={campaignSnapchat.validate(this.state.updated).end_date}
                                    placeholder={!this.renders.endDate() ? "No end time" : ""}
                                    disabled={!this.renders.endDate()}
                                    value={moment(this.renders.endDate()).format("HH:mm")}
                                    onChange={async (value) => {
                                        let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                        let new_date = date + " " + value;
                                        this.state.updated.end_date = moment(new_date);
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default EditSnapchatCampaign;
