import React, { Component } from 'react';
import cn from "classnames";
import { ArrowRightIcon, ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CameraIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, GiftIcon, PlusCircleIcon, PlusIcon, SparklesIcon, SwitchVerticalIcon, VideoCameraIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import languages from '../assets/json/languages.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import PreviewGoogleSearch from '../previews/google/search';
import TextAreaTailwind from './textAreaTailwind';

import { searchGoogle } from '../validators/searchGoogle';
import { text } from 'body-parser';

const fuzzysort = require('fuzzysort');

class EditLiveGoogleSearchAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            keywords: [],
            ad: {},
            sort_suggestions: 'keyword',
            direction_suggestions: 'descending',
            ai_suggetion: false,
            headlines_max_characters: 30,
            description_max_characters: 90,
            headlines_amount: { id: 15, name: "15", value: 15 },
            description_amount: { id: 4, name: "4", value: 4 },
            temperature: { id: 1, name: "Low", value: 0 },
            language: { id: 1, name: "English" },
            searchOpenAi: "",
            OpenAiCompare: "",
            OpenAiResult: [],
            loading_suggest: false,
            openAi_error: false,
            ai_result: false,
            combinations: [],
            OpenAiResult: [],
            updated: {},
            orderBy: "DESC",
            sortBy: "name",
            pagination: "1",
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
        });
        if (Array.isArray(this.state.ad.headlines)) {
            this.state.ad.headlines = this.state.ad.headlines.map((item) => {
                let insertion = false;
                if (item.text.indexOf("{") !== -1 && item.text.indexOf("}") !== -1) {
                    insertion = item.text.substring(0, 9);
                    item.text = item.text.substring(9, item.text.length - 1);
                }
                return {
                    text: item.text,
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    pinned: { id: 0, value: item.pinnedField ? item.pinnedField : "UNSPECIFIED", name: item.pinnedField ? (item.pinnedField === "HEADLINE_1" ? "First" : (item.pinnedField === "HEADLINE_2" ? "Second" : "Third")) : "Auto" },
                    keywordInsertion: insertion
                }
            });
        } else {
            this.state.ad.headlines = [];
        }
        if (Array.isArray(this.state.ad.descriptions)) {
            this.state.ad.descriptions = this.state.ad.descriptions.map((item) => { return { text: item.text, id: Math.floor((Math.random() * 9999999999) + 1), pinned: { id: 0, value: item.pinnedField ? item.pinnedField : "UNSPECIFIED", name: item.pinnedField ? (item.pinnedField === "DESCRIPTION_1" ? "First" : "Second") : "Auto" } } });
        } else {
            this.state.ad.descriptions = [];
        }
        await this.promisedSetState({
            ad: this.state.ad
        });
        //console.log("VALIDTE", this.functions.validate());
        console.log("AD-", this.state.ad);
        this.functions.combinations();
        this.functions.updatePreview();
        await this.functions.getKeywords();

        this.functions.insideHeadlines();
        this.functions.insideDescriptions();
        this.functions.updatePreview();

        //IF ONLY ONE GROUP SELETED
        /*
        if (Array.isArray(this.state.groups) && this.state.groups.length === 1) {
            await this.promisedSetState({
                group: this.state.groups[0]
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        } else if (this.state.groups && !Array.isArray(this.state.groups)) {
            await this.promisedSetState({
                group: this.state.groups
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        } else {
            this.state.group.name = "";  
            this.state.group.link = "";
            this.state.group.OpenAiResult = [];
            this.state.group.format = { id: 1, name: "Responsive text ad", value: "responsive" };
            this.state.group.headlines = [];
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies = [];
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            await this.promisedSetState({
                group: this.state.group
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        }
        */

    }

    functions = {
        updatePreview: async () => {
            try {
                this.functions.combinations();
                let path_mask = false;
                let exact_reg = /([^?&\/]+)/g;
                if (Array.isArray(this.renders.finalUrl().match(exact_reg)) && this.renders.finalUrl().match(exact_reg).length > 1) {
                    path_mask = this.renders.finalUrl().match(exact_reg)[0] + "//" + this.renders.finalUrl().match(exact_reg)[1];
                }
                this.props.updatePreview({
                    combinations: this.state.combinations,
                    keywords: this.state.keywords,
                    link: path_mask ? path_mask : this.renders.finalUrl(),
                    path1: this.renders.path1(),
                    path2: this.renders.path2(),
                    bodies: this.renders.descriptions(),
                    headlines: this.renders.headlines(),
                    format: { id: 1, name: "Responsive search ad", value: "responsive" },
                });
            } catch (error) {

            }
        },
        addKeyword: async (person) => {
            try {
                if (this.renders.headlines().filter((inner_item) => { return inner_item.text !== "" }).length < 15) {
                    if (this.renders.headlines().filter((inner_item) => { return inner_item.text === person.keyword }).length > 0) {

                    } else if (this.renders.headlines().filter((item) => { return item.text === "" }).length > 0 && this.renders.headlines().filter((inner_item) => { return inner_item.text === person.keyword }).length < 1) {

                        let buff = JSON.parse(JSON.stringify(this.renders.headlines())).filter((item) => { return item.text === "" })[0];
                        this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((inner_item) => {
                            if (inner_item.id === buff.id) {
                                inner_item.text = person.keyword;
                            }
                            return inner_item
                        })
                    } else {
                        this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines()))
                        this.state.updated.headlines.push({
                            id: Math.floor((Math.random() * 9999999999) + 1),
                            text: person.keyword,
                        })
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    })
                    this.functions.validate();
                }
                this.functions.insideHeadlines();
                this.functions.updatePreview();
            } catch (error) {

            }
        },
        addHeadlines: async (headlines) => {
            try {
                for (let i = 0; i < headlines.length; i++) {
                    if (this.renders.headlines().filter((inner_item) => { return inner_item.text !== "" }).length < 15) {
                        if (this.renders.headlines().filter((inner_item) => { return inner_item.text === headlines[i] }).length > 0) {

                        } else if (this.renders.headlines().filter((item) => { return item.text === "" }).length > 0 && this.renders.headlines().filter((inner_item) => { return inner_item.text === headlines[i] }).length < 1) {

                            let buff = JSON.parse(JSON.stringify(this.renders.headlines())).filter((item) => { return item.text === "" })[0];
                            this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((inner_item) => {
                                if (inner_item.id === buff.id) {
                                    inner_item.text = headlines[i];
                                }
                                return inner_item
                            })
                        } else {
                            this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines()))
                            this.state.updated.headlines.push({
                                id: Math.floor((Math.random() * 9999999999) + 1),
                                text: headlines[i],
                            })
                        }
                        await this.promisedSetState({
                            updated: this.state.updated
                        })
                        this.functions.validate();
                    }
                    this.functions.insideHeadlines();
                    this.functions.updatePreview();
                }
            } catch (error) {

            }
        },
        addDescriptions: async (descriptions) => {
            try {
                for (let i = 0; i < descriptions.length; i++) {
                    if (this.renders.descriptions().filter((inner_item) => { return inner_item.text !== "" }).length < 4) {
                        if (this.renders.descriptions().filter((inner_item) => { return (inner_item.text === descriptions[i]) }).length > 0) {

                        } else if (this.renders.descriptions().filter((item) => { return item.text === "" }).length > 0 && this.renders.descriptions().filter((inner_item) => { return (inner_item.text === descriptions[i]) }).length < 1) {
                            let buff = JSON.parse(JSON.stringify(this.renders.descriptions())).filter((item) => { return item.text === "" })[0];
                            this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions())).map((inner_item) => {
                                if (inner_item.id === buff.id) {
                                    inner_item.text = descriptions[i]
                                }
                                return inner_item
                            })
                        } else {
                            this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions()))
                            this.state.updated.descriptions.push({
                                id: Math.floor((Math.random() * 9999999999) + 1),
                                text: descriptions[i]
                            })
                        }
                        await this.promisedSetState({
                            updated: this.state.updated
                        })
                        await new Promise(resolve => setTimeout(resolve, 300));
                        this.functions.validate();
                        this.functions.updatePreview();
                    }
                }
            } catch (error) {

            }
        },
        updateOpenAi: async (data) => {
            console.log(data, "OpenAiResult ===");
            this.state.OpenAiResult = data;
            await this.promisedSetState({
                OpenAiResult: this.state.OpenAiResult,
            });
        },
        removeDescription: async (description) => {
            this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions().filter((item) => { return item.text !== description })));
            await this.promisedSetState({
                updated: this.state.updated
            })
            this.functions.updatePreview();
        },
        removeHeadline: async (headline) => {
            try {
                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines().filter((item) => { return item.text !== headline })));
                await this.promisedSetState({
                    updated: this.state.updated
                })
                this.functions.updatePreview();
            } catch (error) { }
        },
        update: async () => {
            this.props.onLoader(true);
            let body = {
                operations: [
                    {
                        ...(this.state.ad.policy_summary && Array.isArray(this.state.ad.policy_summary.policyTopicEntries) && { "policyValidationParameter": { "ignorablePolicyTopics": this.state.ad.policy_summary.policyTopicEntries.map((item) => { return item.topic }) } }),
                        "updateMask": "",
                        "update": {
                            resourceName: this.state.ad.resource_name
                        }
                    }
                ]
            }
            /*
            if (this.state.ad.advertising_type == "EXPANDED_TEXT_AD") {
                body.operations[0].updateMask += "final_urls";
                body.operations[0].update.finalUrls = [this.renders.finalUrl()];
                body.operations[0].update.expandedTextAd = {};
                if (this.renders.path1() !== "") {
                    body.operations[0].updateMask += ",expanded_text_ad.path1";
                    body.operations[0].update.expandedTextAd.path1 = this.renders.path1();
                }
                if (this.renders.path2() !== "") {
                    body.operations[0].updateMask += ",expanded_text_ad.path2";
                    body.operations[0].update.expandedTextAd.path2 = this.renders.path2();
                }
                this.renders.headlines().map((item, index) => {
                    body.operations[0].updateMask += ",expanded_text_ad.headlinePart" + (index + 1);
                    body.operations[0].update.expandedTextAd["headlinePart" + (index + 1)] = item.text;
                })
                this.renders.descriptions().map((item, index) => {
                    body.operations[0].updateMask += ",expanded_text_ad.description" + (index > 0 ? 2 : "");
                    body.operations[0].update.expandedTextAd["description" + (index > 0 ? 2 : "")] = item.text;
                })
            } else */
            if (this.state.ad.advertising_type == "RESPONSIVE_SEARCH_AD") {
                body.operations[0].updateMask += "final_urls,responsive_search_ad.headlines,responsive_search_ad.descriptions";
                body.operations[0].update.finalUrls = [this.renders.finalUrl()];
                body.operations[0].update.responsiveSearchAd = {};
                if (this.renders.path1() !== "") {
                    body.operations[0].updateMask += ",responsive_search_ad.path1";
                    body.operations[0].update.responsiveSearchAd.path1 = this.renders.path1();
                }
                if (this.renders.path2() !== "") {
                    body.operations[0].updateMask += ",responsive_search_ad.path2";
                    body.operations[0].update.responsiveSearchAd.path2 = this.renders.path2();
                }
                body.operations[0].update.responsiveSearchAd.headlines = this.renders.headlines().map((item, index) => {
                    if (item.keywordInsertion) {
                        item.text = item.keywordInsertion + item.text + "}";
                    }
                    let headline = {
                        text: item.text,
                    };
                    if (item.pinned && (item.pinned.id !== 1 || item.pinned.value !== "UNSPECIFIED")) {
                        headline.pinnedField = item.pinned.value;
                    }
                    return headline;
                })
                body.operations[0].update.responsiveSearchAd.descriptions = this.renders.descriptions().map((item, index) => {
                    let decription = {
                        text: item.text,
                    };
                    if (item.pinned && (item.pinned.id !== 1 || item.pinned.value !== "UNSPECIFIED")) {
                        decription.pinnedField = item.pinned.value;
                    }
                    return decription;
                })
            }
            body.changelog = {
                initial_data: this.state.ad,
                updated_data: this.state.updated,
                level: "ad",
                order: window.location.pathname.split("/").pop()
            }
            try {
                await this.calls.updateAd(body);
                this.props.onSuccess("Ad updated");
                this.props.onUpdate();
            } catch (error) {
                //this.props.onError(error.body ? error.body.message : "Something went wrong");
            }
            this.props.onLoader(false);
        },
        validate: () => {
            let ad = {
                name: "1",
                bodies: this.renders.descriptions(),
                headlines: this.renders.headlines(),
                link: this.renders.finalUrl()
            };

            if (Object.keys(searchGoogle.validate(ad)).map((item) => {
                if (searchGoogle.validate(ad)[item]) { return item } else { return null }
            }).filter((item) => { return item }).length > 0) {
                this.props.onDisable(true);
                //return searchGoogle.validate(ad);
            } else {
                if (this.renders.headlines().filter((item) => { return item.text.length > 30 }).length > 0 || this.renders.descriptions().filter((item) => { return item.text.length > 90 }).length > 0 || this.renders.headlines().filter((item) => { return item.text === "" }).length > 0 || this.renders.descriptions().filter((item) => { return item.text === "" }).length > 0) {
                    this.props.onDisable(true);
                } else this.props.onDisable(false);
                //return searchGoogle.validate(ad);
            }
        },
        openAi: async () => {

            let data = {
                text: this.renders.promt_text(),
                temperature: this.state.temperature
            }

            await this.promisedSetState({ loading_suggest: true, ai_result: true });
            try {
                let response = await this.calls.openAi(data);


                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })

                response.data[0].text = response.data[0].text.replace(/\\/g, "");
                response.data[0].text = response.data[0].text.replace(/\\n/g, "");

                let a = JSON.parse(response.data[0].text);

                let headlines = [];
                let descriptions = [];
                if (this.state.language.name === "English") {
                    headlines = a.headlines;
                    descriptions = a.descriptions;
                } else if (this.state.language.name === "Swedish") {
                    headlines = a.rubriker;
                    descriptions = a.beskrivningar;
                } else if (this.state.language.name === "Finnish") {
                    headlines = a.otsikot;
                    descriptions = a.kuvauksille;
                } else if (this.state.language.name === "Danish") {
                    headlines = a.hovedtekster;
                    descriptions = a.beskrivelser;
                } else if (this.state.language.name === "Norwegian") {
                    headlines = a.topptitler;
                    descriptions = a.beskrivelser;
                }

                this.state.OpenAiResult.unshift({ headlines: headlines, descriptions: descriptions, error: false, hidden: false });

                this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: false
                })

            } catch (error) {



                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })

                this.state.OpenAiResult.unshift({
                    text: ((error && error.body && error.body.message) ? error.body.message : "Something went wrong"),
                    error: true
                });

                await this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: true
                });

            }
        },
        getKeywords: async (partial) => {
            return new Promise(async (resolve) => {
                try {
                    if (partial) {
                        await this.promisedSetState({
                            loading_partial: true
                        });
                    } else {
                        await this.promisedSetState({
                            loading_keywords: true
                        });
                    }

                    let response = await this.calls.getKeywords("google", "&positive=true", null);
                    response.data = response.data.map((item) => {
                        return {
                            channel: 'google',
                            id: item.adGroupCriterion.resourceName,
                            resourceName: item.adGroupCriterion.resourceName,
                            name: item.adGroupCriterion.keyword.text,
                            keyword: item.adGroupCriterion.keyword.text,
                            status: item.adGroupCriterion.status ? item.adGroupCriterion.status.toLowerCase() : '-',
                            approval_status: item.adGroupCriterion.approvalStatus ? item.adGroupCriterion.approvalStatus.toLowerCase() : '-',
                            keyword_type: item.adGroupCriterion.keyword.matchType,
                        };
                    });

                    this.state.pagination = response.pagination ? response.pagination : null;
                    await this.promisedSetState({
                        keywords: this.state.keywords.concat(response.data),
                        pagination: this.state.pagination
                    });

                    if (partial) {
                        await this.promisedSetState({
                            loading_partial: false
                        });
                    } else {
                        await this.promisedSetState({
                            loading_keywords: false
                        });
                    }
                    resolve();
                } catch (error) {
                    if (partial) {
                        await this.promisedSetState({
                            error: true,
                            loading_partial: true
                        });
                    } else {
                        await this.promisedSetState({
                            error: true,
                            loading_keywords: true
                        });
                    }
                    resolve();
                }
            });
        },

        combinations: () => {

            let combinations_headlines = [];
            let combinations_bodies = [];
            let combinations = [];

            if (Array.isArray(this.renders.headlines())) {
                let first_headlines = [];
                let second_headlines = [];
                let third_headlines = [];
                this.renders.headlines().map((item) => {
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_1"))) {
                        first_headlines.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_2"))) {
                        second_headlines.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_3"))) {
                        third_headlines.push(item);
                    }
                });
                first_headlines.map((first) => {
                    second_headlines.map((second) => {
                        third_headlines.map((third) => {
                            let combo = { headlineFirst: first, headlineSecond: second, headlineThird: third };
                            combinations_headlines.push(combo);
                        })
                    })
                });
                combinations_headlines = combinations_headlines.filter((item) => {
                    let keys = [];
                    for (let key in item) {
                        keys.push(item[key].id)
                    };
                    let uniqueArray = keys.filter(function (item, pos) {
                        return keys.indexOf(item) == pos;
                    });
                    return uniqueArray.length === 3;
                })
            }

            if (Array.isArray(this.renders.descriptions())) {
                let first_bodies = [];
                let second_bodies = [];
                this.renders.descriptions().map((item) => {
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "DESCRIPTION_1"))) {
                        first_bodies.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "DESCRIPTION_2"))) {
                        second_bodies.push(item);
                    }
                });
                first_bodies.map((first) => {
                    second_bodies.map((second) => {
                        let combo = { bodyOne: first, bodySecond: second };
                        combinations_bodies.push(combo);
                    })
                });
                combinations_bodies = combinations_bodies.filter((item) => {
                    let keys = [];
                    for (let key in item) {
                        keys.push(item[key].id)
                    };
                    let uniqueArray = keys.filter(function (item, pos) {
                        return keys.indexOf(item) == pos;
                    });
                    return uniqueArray.length === 2;
                })
            }

            combinations_bodies.map((bodies) => {
                combinations_headlines.map((headlines) => {
                    let combo = { ...bodies, ...headlines };
                    combinations.push(combo);
                });
            });

            this.state.combinations = combinations.filter((item, index) => { return index < 100 });

            this.setState({
                combinations: this.state.combinations
            })
        },
        insideHeadlines: () => {
            if (Array.isArray(this.renders.headlines()) && this.state.keywords.length > 0) {
                this.state.keywords = this.state.keywords.map((item) => { item.inside_headline = false; return item; })
                this.renders.headlines().map((item) => {
                    this.state.keywords = this.state.keywords.map((inner_item) => {
                        if (typeof inner_item.name === "string" && typeof item.text === "string" && item.text.toLowerCase().indexOf(inner_item.name.toLowerCase()) !== -1) {
                            inner_item.inside_headline = true;
                        }
                        return inner_item;
                    });
                });
                this.setState({
                    keywords: this.state.keywords
                });
            }
        },
        insideDescriptions: () => {
            if (Array.isArray(this.renders.descriptions()) && this.state.keywords.length > 0) {
                this.state.keywords = this.state.keywords.map((item) => { item.inside_description = false; return item; })
                this.renders.descriptions().map((item) => {
                    this.state.keywords = this.state.keywords.map((inner_item) => {
                        if (typeof inner_item.name === "string" && typeof item.text === "string" && item.text.toLowerCase().indexOf(inner_item.name.toLowerCase()) !== -1) {
                            inner_item.inside_description = true;
                        }
                        return inner_item;
                    });
                });
                this.setState({
                    keywords: this.state.keywords
                });
            }
        }

    };

    calls = {
        openAi: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAi?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getKeywords: (channel, queryString = null, negative = null) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + `/v3/${channel}/listKeywords?` + "client=" + this.state.client.id + "&campaign=" + this.state.ad.campaign_id + "&pagination=" + this.state.pagination + "&sortBy=" + this.state.sortBy + "&orderBy=" + this.state.orderBy + "&pageSize=20&datespan=last_7" + queryString + "&calculate_basic_metrics=false";
            return apiRegister.call(options, url);
        },
        updateAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        sort: (list, sort, order) => {
            if (!Array.isArray(list)) {
                list = [];
            }
            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : 0) : (x[sort] ? x[sort] : 0);
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : 0) : (y[sort] ? y[sort] : 0);
                    if (order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }
            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : "") : (x[sort] ? x[sort] : "");
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : "") : (y[sort] ? y[sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }
            if (sort === "keyword" || sort === "keyword_type" || sort === "status") {
                return sortCharacter(list);
            } else {
                return sortNumber(list);
            }
        },

        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.ad.status === 'UNSPECIFIED') {
                    return { id: 3, name: 'Unspecified', value: "UNSPECIFIED" }
                } else if (this.state.ad.status === 'UNKNOWN') {
                    return { id: 4, name: 'Unknown', value: "UNKNOWN" }
                } else if (this.state.ad.status === 'ENABLED') {
                    return { id: 1, name: 'Enabled', value: "ENABLED" }
                } else if (this.state.ad.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.ad.status === 'REMOVED') {
                    return { id: 5, name: 'Removed', value: "REMOVED" }
                } else {
                    return { id: 0, name: 'Unknown', value: "UNKNOWN" }
                }
            } catch (error) {
                console.log(error);
                return { id: 0, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        path1: () => {
            try {
                if ('path1' in this.state.updated) {
                    return this.state.updated.path1;
                } else if (this.state.ad.path1) {
                    return this.state.ad.path1;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        path2: () => {
            try {
                if ('path2' in this.state.updated) {
                    return this.state.updated.path2;
                } else if (this.state.ad.path2) {
                    return this.state.ad.path2;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        headlines: () => {
            if ("headlines" in this.state.updated) {
                return this.state.updated.headlines;
            } else {
                return this.state.ad.headlines
            }
        },
        descriptions: () => {
            if ("descriptions" in this.state.updated) {
                return this.state.updated.descriptions;
            } else {
                return this.state.ad.descriptions
            }
        },
        finalUrl: () => {
            if ("final_url" in this.state.updated) {
                return this.state.updated.final_url;
            } else {
                return Array.isArray(this.state.ad.final_urls) && this.state.ad.final_urls.length > 0 ? this.state.ad.final_urls[0] : "";
            }
        },


        adStrength: () => {
            let strength = "Incomplete";

            if (Array.isArray(this.renders.headlines()) && this.renders.headlines().filter((item) => { return item.text !== "" }).length > 2 && this.renders.descriptions().filter((item) => { return item.text !== "" }).length > 1) {

                strength = "Poor";

                // Average (Popular keywords)
                if (Array.isArray(this.state.keywords) && this.state.keywords.filter((item) => { return item.inside_headline }).length > 4) {
                    strength = "Average";
                }

                // Good (Unique headlines)
                if (strength === "Average" && this.renders.headlines().filter((value, index, self) => {
                    return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                }).length > 4) {
                    strength = "Good";
                }

                // Excellent (14+ headlines and Unique descriptions)
                if (strength === "Good" && this.renders.headlines().filter((item) => { return item.text !== "" }).length > 13 && this.renders.descriptions().filter((value, index, self) => {
                    return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                }).length > 1) {
                    strength = "Excellent";
                }

            }
            return strength;
        },
        promt_text: () => {
            if (this.state.language.id === 5) {
                return "Generer " + this.state.headlines_amount.name + " forslag til topptitler og " + this.state.description_amount.name + " forslag til beskrivelser for min Google-søkeannonse basert på følgende:" + "\n" +
                    this.state.searchOpenAi + "\n" + "Topptitler skal ha maksimalt " + this.state.headlines_max_characters + " tegn og beskrivelser skal ha maksimalt " + this.state.description_max_characters + ' tegn. Skriv på norsk og returner i JSON format som dette {"topptitler":[], "beskrivelser":[]}.';
            } else if (this.state.language.id === 4) {
                return "Generer " + this.state.headlines_amount.name + " forslag til hovedtekster og " + this.state.description_amount.name + " forslag til beskrivelser til min Google-søgeannonce baseret på følgende:" + "\n" +
                    this.state.searchOpenAi + "\n" + "Hovedtekster skal have maksimalt " + this.state.headlines_max_characters + " tegn, og beskrivelser skal have maksimalt " + this.state.description_max_characters + ' tegn. Skriv dansk og returner i JSON format som dette {"hovedtekster":[], "beskrivelser":[]}.';
            } else if (this.state.language.id === 3) {
                return "Luo " + this.state.headlines_amount.name + " otsikkoehdotusta ja " + this.state.description_amount.name + " ehdotusta kuvauksiksi Google-hakumainokselleni seuraavien seikkojen perusteella:" + "\n" +
                    this.state.searchOpenAi + "\n" + "Otsikoissa saa olla enintään " + this.state.headlines_max_characters + " merkkiä ja kuvauksissa enintään " + this.state.description_max_characters + ' merkkiä. Kirjoita suomeksi ja palauta JSON muodossa näin {"otsikot":[], "kuvauksille":[]}.';
            } else if (this.state.language.id === 2) {
                return "Generera " + this.state.headlines_amount.name + " stycken förslag på rubriker samt " + this.state.description_amount.name + " stycken förslag på beskrivningar till min Google sökannons baserat på följande:" + "\n" +
                    this.state.searchOpenAi + "\n" + "Rubrikerna ska ha maximalt " + this.state.headlines_max_characters + " tecken och beskrivningarna ska ha högst " + this.state.description_max_characters + ' tecken. Skriv på svenska samt återvända in JSON format så här {"rubriker":[], "beskrivningar":[]}.';
            } else {
                return "Generate " + this.state.headlines_amount.name + " proposals for headlines and " + this.state.description_amount.name + " proposals for descriptions for my Google search ad based on the following:" + "\n" +
                    this.state.searchOpenAi + "\n" + "Headlines should have a maximum of " + this.state.headlines_max_characters + " characters and descriptions should have a maximum of " + this.state.description_max_characters + " characters. Write in " + this.state.language.name + ' and return in JSON format like this {"headlines":[], "descriptions":[]}.';
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }
        return (
            <div className="flex flex-1">
                <div className="flex flex-row flex-1">
                    {/*
                    <div className="h-full pb-4 flex flex-1 flex-col">
                        <div className="w-full h-full flex flex-1 flex-col">
                            <div className="text-sm font-medium mb-4 bg-white">
                                Preview (combinations)
                            </div>
                            <div className="w-full">
                                <div className="w-full relative">
                                    {
                                        Array.isArray(this.state.combinations) &&
                                        this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" &&
                                        this.state.combinations.filter((item, index) => {
                                            return (!("combi_index" in this.state) && index === 0) || (this.state.combi_index === index)
                                        }).map((item) => {
                                            return (
                                                <div className="mb-4">
                                                    <PreviewGoogleSearch
                                                        headlineFirst={item.headlineFirst.text}
                                                        headlineSecond={item.headlineSecond.text}
                                                        headlineThird={item.headlineThird.text}
                                                        bodyOne={item.bodyOne.text}
                                                        bodySecond={item.bodySecond.text}
                                                        link={this.renders.finalUrl()}
                                                        path1={this.renders.path1()}
                                                        path2={this.renders.path2()}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        Array.isArray(this.state.combinations) &&
                                        this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" &&
                                        <div className="flex flex-row">
                                            <div onClick={() => {
                                                if (!("combi_index" in this.state)) {
                                                    this.state.combi_index = 1;
                                                } else {
                                                    if (this.state.combi_index > 0) {
                                                        this.state.combi_index = this.state.combi_index - 1;
                                                    }
                                                }
                                                this.setState({
                                                    combi_index: this.state.combi_index
                                                })
                                            }} className={(!("combi_index" in this.state) || this.state.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                                <ArrowSmLeftIcon className="w-5 h-5" />
                                            </div>
                                            <div onClick={() => {
                                                if (!("combi_index" in this.state)) {
                                                    this.state.combi_index = 1;
                                                } else {
                                                    if (this.state.combi_index < this.state.combinations.length - 1) {
                                                        this.state.combi_index = this.state.combi_index + 1;
                                                    }
                                                }
                                                this.setState({
                                                    combi_index: this.state.combi_index
                                                })
                                            }} className={(this.state.combi_index === (this.state.combinations.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 rounded-full flex items-center justify-center"}>
                                                <ArrowSmRightIcon className="w-5 h-5" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        !Array.isArray(this.state.combinations) &&
                                        this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" &&
                                        <div className="w-full h-full flex items-center justify-center">
                                            <div className="text-sm font-medium">Add minimum 3 headlines and 1 description for preview</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full py-4">
                                <nav className="-mb-px flex" aria-label="Channels">
                                    {
                                        ["Keyword", "AI Suggestion"].map((item, index) => (
                                            <div
                                                onClick={() => {
                                                    this.setState({
                                                        ai_suggetion: (index === 1 ? true : false),
                                                    })
                                                }}
                                                key={item}
                                                style={{ borderBottomColor: "#f9fafb40" }}
                                                className={"bg-gray-50 border-b-1.5 border-t-1.5 border-r-1.5 border-l-1.5 py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg"}
                                            >{item}
                                            </div>
                                        ))
                                    }
                                </nav>
                            </div>
                            {
                                !this.state.ai_suggetion &&
                                <>
                                    <div className="rounded-md overflow-scroll mt-4 relative flex flex-1 flex-col">
                                        <div className='h-10 border'>
                                            <div className='bg-gray-100 grid grid-cols-12 h-full text-xs whitespace-no-wrap font-medium text-gray-900'>
                                                <div
                                                    onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'keyword',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="col-span-4 items-center pl-3 cursor-pointer flex">
                                                    <div className='mr-1'>Keyword</div>
                                                    <div className="flex flex-col">
                                                        {
                                                            this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'ascending' &&
                                                            <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                        {
                                                            this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'descending' &&
                                                            <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                    </div>

                                                </div>
                                                <div
                                                    onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'keyword_type',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="col-span-2 items-center pl-3 cursor-pointer flex">
                                                    <div className='mr-1'>Type</div>
                                                    <div className="flex flex-col">
                                                        {
                                                            this.state.sort_suggestions === 'keyword_type' && this.state.direction_suggestions === 'ascending' &&
                                                            <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                        {
                                                            this.state.sort_suggestions === 'keyword_type' && this.state.direction_suggestions === 'descending' &&
                                                            <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        this.setState({
                                                            sort_suggestions: 'status',
                                                            direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                        })
                                                    }} className="col-span-2 items-center pl-3 cursor-pointer flex">
                                                    <div className='mr-1'>Status</div>
                                                    <div className="flex flex-col">
                                                        {
                                                            this.state.sort_suggestions === 'status' && this.state.direction_suggestions === 'ascending' &&
                                                            <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                        {
                                                            this.state.sort_suggestions === 'status' && this.state.direction_suggestions === 'descending' &&
                                                            <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-span-2 items-center flex">
                                                    Headline
                                                </div>
                                                <div className="col-span-2 pr-2 items-center flex">
                                                    Description
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            Array.isArray(this.state.keywords) && this.state.keywords.length > 0 &&
                                            this.renders.sort(this.state.keywords, this.state.sort_suggestions, this.state.direction_suggestions).map((item) => {
                                                let init = true;
                                                let id = item.resourceName.substring(0, item.resourceName.indexOf("~")).substring(item.resourceName.lastIndexOf("/") + 1);
                                                if (id !== this.state.ad.adgroup_id) {
                                                    init = false;
                                                }
                                                if (init) {
                                                    return (
                                                        <div className='h-10 border-t-0 border'>
                                                            <div className='bg-white grid grid-cols-12 h-full text-xs whitespace-no-wrap font-medium text-gray-900'>
                                                                <div className="flex col-span-4 items-center pl-3">
                                                                    {item.name}
                                                                </div>
                                                                <div className="flex col-span-2 items-center pl-3">
                                                                    {item.keyword_type}
                                                                </div>
                                                                <div className="flex col-span-2 items-center pl-3">
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active" || item.status === "enable" || item.status === "enabled" || item.status === "scheduled",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming" || item.status === "syncing",
                                                                                ["bg-gray-100 text-gray-500"]: (item.status === "archived" || item.status === "ended" || item.status === "done" || item.status === "balance_exceed"),
                                                                                [" bg-gray-200 text-gray-500"]: (item.status === "expired" || item.status === "completed" || item.status === "time_done"),
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused" || item.status === "disable" || item.status === 'campaign_disable' || item.status === 'adgroup_disable',
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "draft" || item.status === "pending",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error" || item.status === "removed"
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </div>
                                                                <div className="flex col-span-2 items-center pl-3">
                                                                    <div className={(item.inside_headline ? "bg-green-700" : "bg-white") + " border-green-700 text-white w-5 h-5 rounded-full flex justify-center items-center"}>
                                                                        <CheckIcon className="w-4 h-4" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex col-span-2 items-center pl-3">
                                                                    <div className={(item.inside_description ? "bg-green-700" : "bg-white") + " border-green-700 text-white w-5 h-5 rounded-full flex justify-center items-center"}>
                                                                        <CheckIcon className="w-4 h-4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                        {
                                            this.state.pagination && this.state.pagination !== "1" &&
                                            <div className='items-center justify-center flex mt-5'>
                                                <button
                                                    onClick={() => {
                                                        this.functions.getKeywords(true);

                                                    }}
                                                    className={"ml-4 inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 py-2 px-12 text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    Load more
                                                    {
                                                        this.state.loading_partial &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.loading_keywords &&
                                            <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                (this.state.ai_suggetion) &&
                                <div className="block w-full py-4">
                                    <div className=" border-gray-200 w-full">
                                        <nav className="-mb-px flex" aria-label="Tabs">
                                            {["Suggestions", "Results"].map((item) => (
                                                <div
                                                    onClick={async () => {
                                                        if (item == "Suggestions") {
                                                            await this.promisedSetState({
                                                                ai_result: false
                                                            })
                                                        } else {
                                                            await this.promisedSetState({
                                                                ai_result: true
                                                            })
                                                        }
                                                    }}
                                                    key={item}
                                                    className={classNames(
                                                        ((item === "Suggestions" && !this.state.ai_result) || (item === "Results" && this.state.ai_result))
                                                            ? 'border-purple-500 text-purple-600'
                                                            : 'border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400',
                                                        'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md mx-2'
                                                    )}
                                                >
                                                    {item}
                                                </div>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                            }
                            {
                                this.state.ai_suggetion && !this.state.ai_result &&

                                <div className='grid grid-cols-4 gap-4'>

                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Headlines amount"}
                                            selected={this.state.headlines_amount ? this.state.headlines_amount : { id: 15, value: 15, name: "15" }}
                                            options={[{ id: 5, value: 5, name: "5" }, { id: 10, value: 10, name: "10" }, { id: 15, value: 15, name: "15" }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    OpenAiCompare: "",
                                                    headlines_amount: value
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Headlines max characters"}
                                            value={this.state.headlines_max_characters}
                                            onChange={async (value) => {
                                                value = value.replace(/(\D+)/g, '')
                                                if (value < 1000) {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        headlines_max_characters: value
                                                    })
                                                } else {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        headlines_max_characters: 999
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Description amount"}
                                            selected={this.state.description_amount ? this.state.description_amount : { id: 10, value: 10, name: "10" }}
                                            options={[{ id: 2, value: 2, name: "2" }, { id: 4, value: 4, name: "4" }, { id: 6, value: 6, name: "6" }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    OpenAiCompare: "",
                                                    description_amount: value
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Description max characters"}
                                            value={this.state.description_max_characters}
                                            onChange={async (value) => {
                                                value = value.replace(/(\D+)/g, '')
                                                if (value < 1000) {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        description_max_characters: value
                                                    })
                                                } else {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        headlines_max_characters: 999
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Choose language"}
                                            selected={this.state.language}
                                            options={
                                                [
                                                    { id: 1, name: "English" },
                                                    { id: 2, name: "Swedish" },
                                                    { id: 3, name: "Finnish" },
                                                    { id: 4, name: "Danish" },
                                                    { id: 5, name: "Norwegian" },
                                                ]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    OpenAiCompare: "",
                                                    language: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Choose randomness"}
                                            selected={this.state.temperature}
                                            options={[
                                                { id: 1, name: "Low", value: 0 },
                                                { id: 2, name: "Mid", value: 0.5 },
                                                { id: 3, name: "High", value: 1 },
                                            ]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    OpenAiCompare: "",
                                                    temperature: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <TextAreaTailwind
                                            label={"What do you want suggestions for?"}
                                            value={this.state.searchOpenAi}
                                            onChange={(value) => {
                                                let regex = /(.*):(.*?)(?=Descriptions:|$)/s;
                                                this.setState({
                                                    searchOpenAi: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3 mb-2 inline-flex pl-5 pr-5">
                                        <div onClick={async () => {

                                            if (
                                                this.state.searchOpenAi === "" ||
                                                (
                                                    (this.state.searchOpenAi === this.state.OpenAiCompare) &&
                                                    (
                                                        (Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error)
                                                        ||
                                                        (Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length === 0)
                                                    )
                                                )
                                            ) {

                                            } else {
                                                await this.promisedSetState({
                                                    OpenAiCompare: this.state.searchOpenAi
                                                })
                                                this.functions.openAi();
                                            }
                                        }} className={"flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden bg-purple-100 " + (this.state.searchOpenAi === "" || ((this.state.searchOpenAi === this.state.OpenAiCompare) && ((Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error) || (Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length === 0))) ? "" : " bg-purple-500 cursor-pointer text-white hover:bg-purple-600")}>
                                            Suggest
                                            {
                                                this.state.loading_suggest &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            }
                            {
                                this.state.loading_suggest &&
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-6 relative">
                                        <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.ai_suggetion && this.state.ai_result && Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length < 1 &&
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-6 m-2 text-gray-700 text-base">No Results</div>
                                </div>
                            }
                            {
                                this.state.ai_suggetion && this.state.ai_result && Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length > 0 && !this.state.loading_suggest &&
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-6 m-2 text-gray-700 text-base">Headlines</div>
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        {
                                            this.state.OpenAiResult[0] && Array.isArray(this.state.OpenAiResult[0].headlines) &&
                                            this.state.OpenAiResult[0].headlines.map((item, index) => (
                                                <div className="col-span-3 flex justify-between flex-1 bg-gray-100 rounded-md">
                                                    <div className="m-4">
                                                        <div className="rounded-md text-sm">
                                                            <div className="mb-2">
                                                                {index + 1}. {item}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inline-flex items-center text-center pl-5 pr-5">
                                                        {
                                                            Array.isArray(this.renders.headlines()) && this.renders.headlines().filter((inner_item) => { return inner_item.text === item }).length > 0 &&
                                                            <div className={"flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden bg-purple-300 text-white"}>Added</div>
                                                        }
                                                        {
                                                            Array.isArray(this.renders.headlines()) && this.renders.headlines().filter((inner_item) => { return inner_item.text !== "" }).length < 15 && this.renders.headlines().filter((inner_item) => { return inner_item.text === item }).length < 1 &&

                                                            <div onClick={async () => {
                                                                if (this.renders.headlines().filter((inner_item) => { return inner_item.text !== "" }).length < 15) {
                                                                    if (this.renders.headlines().filter((inner_item) => { return inner_item.text === item }).length > 0) {

                                                                    } else if (this.renders.headlines().filter((item) => { return item.text === "" }).length > 0 && this.renders.headlines().filter((inner_item) => { return inner_item.text === item }).length < 1) {

                                                                        let buff = JSON.parse(JSON.stringify(this.renders.headlines())).filter((item) => { return item.text === "" })[0];
                                                                        this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((inner_item) => {
                                                                            if (inner_item.id === buff.id) {
                                                                                inner_item.text = item
                                                                            }
                                                                            return inner_item
                                                                        })
                                                                    } else {
                                                                        this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines()))
                                                                        this.state.updated.headlines.push({
                                                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                                                            text: item
                                                                        })
                                                                    }
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                    this.functions.validate();
                                                                }
                                                            }} className={("flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden " + (this.renders.headlines().filter((inner_item) => { return inner_item.text === item }).length < 1 ? " bg-purple-500 cursor-pointer text-white hover:bg-purple-600" : ""))}>
                                                                Add
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="col-span-6 m-2 text-gray-700 text-base">Descriptions</div>
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        {
                                            this.state.OpenAiResult[0] && Array.isArray(this.state.OpenAiResult[0].descriptions) &&
                                            this.state.OpenAiResult[0].descriptions.map((item, index) => (
                                                <div className="col-span-6 flex justify-between flex-1 bg-gray-100">
                                                    <div className="m-4">
                                                        <div className="rounded-md text-sm">
                                                            <div className="mb-2">
                                                                {index + 1}. {item}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="inline-flex items-center text-center pl-5 pr-5">
                                                        {
                                                            Array.isArray(this.renders.descriptions()) && this.renders.descriptions().filter((inner_item) => { return inner_item.text === item }).length > 0 &&
                                                            <div className={"flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden bg-purple-300 text-white"}>Added</div>
                                                        }
                                                        {
                                                            Array.isArray(this.renders.descriptions()) && this.renders.descriptions().filter((inner_item) => { return inner_item.text !== "" }).length < 4 && this.renders.descriptions().filter((inner_item) => { return inner_item.text === item }).length < 1 &&
                                                            <div onClick={async () => {
                                                                if (this.renders.descriptions().filter((inner_item) => { return inner_item.text !== "" }).length < 4) {
                                                                    if (this.renders.descriptions().filter((inner_item) => { return (inner_item.text === item) }).length > 0) {

                                                                    } else if (this.renders.descriptions().filter((item) => { return item.text === "" }).length > 0 && this.renders.descriptions().filter((inner_item) => { return (inner_item.text === item) }).length < 1) {
                                                                        let buff = JSON.parse(JSON.stringify(this.renders.descriptions())).filter((item) => { return item.text === "" })[0];
                                                                        this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions())).map((inner_item) => {
                                                                            if (inner_item.id === buff.id) {
                                                                                inner_item.text = item
                                                                            }
                                                                            return inner_item
                                                                        })
                                                                    } else {
                                                                        this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions()))
                                                                        this.state.updated.descriptions.push({
                                                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                                                            text: item
                                                                        })
                                                                    }
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                    this.functions.validate();
                                                                }
                                                            }} className={("flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden " + (this.renders.descriptions().filter((inner_item) => { return (inner_item.text === item) }).length < 1 ? " bg-purple-500 cursor-pointer text-white hover:bg-purple-600" : ""))}>
                                                                Add
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                        
                    <div className="px-4">
                        <div style={{ minHeight: "100%" }} className="border-r h-full"></div>
                    </div>
                        */}
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="bg-blue-100 flex flex-row rounded-md mb-2 p-2 sticky top-0 z-60">
                            <div className="flex flex-1 flex-col justify-center">
                                <div className="flex flex-row justify-center items-center pr-2">
                                    <div className="font-medium text-sm mr-2">Ad Strength - </div>
                                    <div className="text-sm">{this.renders.adStrength()}</div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className="flex-row  items-center mb-2 flex mr-2">
                                        <div className={((Array.isArray(this.renders.headlines()) && this.renders.headlines().length > 8) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Add more headlines</div>
                                    </div>
                                    <div className="flex-row flex items-center mb-2">
                                        <div className={((Array.isArray(this.state.keywords) && this.state.keywords.filter((item) => { return item.inside_headline }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Include popular keywords in your headlines</div>
                                    </div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className="flex-row flex items-center mr-2">
                                        <div className={((Array.isArray(this.renders.headlines()) && this.renders.headlines().filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your headlines more unique</div>
                                    </div>
                                    <div className="flex-row flex items-center">
                                        <div className={((Array.isArray(this.renders.descriptions()) && this.renders.descriptions().filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 1) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your descriptions more unique</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* OLD CODE
                        <div className="bg-blue-100 flex flex-row rounded-md mb-4 p-4">
                            <div className="flex flex-1 flex-col justify-center items-center">
                                <div className="font-medium text-sm">Ad Strength</div>
                                <div className="text-sm">{this.renders.adStrength()}</div>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <div>
                                    <div className="flex-row flex items-center mb-2">
                                        <div className={((Array.isArray(this.renders.headlines()) && this.renders.headlines().length > 8) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Add more headlines</div>
                                    </div>
                                    <div className="flex-row flex items-center mb-2">
                                        <div className={((Array.isArray(this.state.keywords) && this.state.keywords.filter((item) => { return item.inside_headline }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Include popular keywords in your headlines</div>
                                    </div>
                                    <div className="flex-row flex items-center mb-2">
                                        <div className={((Array.isArray(this.renders.headlines()) && this.renders.headlines().filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your headlines more unique</div>
                                    </div>
                                    <div className="flex-row flex items-center">
                                        <div className={((Array.isArray(this.renders.descriptions()) && this.renders.descriptions().filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 1) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your descriptions more unique</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                                    */}



                        <div className="flex flex-1 flex-col">
                            <div className="grid grid-cols-12 gap-4 mb-4 w-full ">

                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Ad type"}
                                        locked={true}
                                        selected={{ id: 1, name: "Responsive text ad", value: "responsive" }}
                                        options={[
                                            { id: 1, name: "Responsive text ad", value: "responsive" }
                                        ]}

                                    />
                                </div>


                                {
                                    <div className="col-span-8">
                                        <InputTailwind
                                            label={"Website"}
                                            error={this.renders.finalUrl() === "" ? true : false}
                                            value={this.renders.finalUrl()}
                                            onChange={async (value) => {
                                                this.state.updated.final_url = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.validate();
                                                this.functions.updatePreview();
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Path 1"}
                                            value={this.renders.path1()}
                                            onChange={async (value) => {
                                                this.state.updated.path1 = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.updatePreview();
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Path 2"}
                                            value={this.renders.path2()}
                                            onChange={async (value) => {
                                                this.state.updated.path2 = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.updatePreview();
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                <div className="text-sm font-medium col-span-12">
                                    Headlines
                                </div>
                                {
                                    this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" && Array.isArray(this.renders.headlines()) &&
                                    this.renders.headlines().map((item, index) => {
                                        return (
                                            <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-12"
                                            }>
                                                <div className="grid grid-cols-12 col-gap-4">
                                                    <div className="col-span-9 relative">
                                                        <InputTailwind
                                                            error={item.text.length > 30 || item.text === "" ? true : false}
                                                            value={item.text}
                                                            label={(index + 1) + " Headline (" + item.text.replace("{keyword}", "").length + "/30) " + (item.text.indexOf("{keyword}") !== -1 ? "OBS! Keyword + Headline cant be more than 30 characters" : "")}
                                                            onChange={async (value) => {
                                                                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((headline) => {
                                                                    if (headline.id === item.id) {
                                                                        headline.text = value;
                                                                    }
                                                                    return headline
                                                                })
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.insideHeadlines();
                                                                this.functions.validate();
                                                                this.functions.updatePreview();
                                                            }}
                                                            maxLength={30}
                                                            insertion={true}
                                                            keywordInsertion={item.keywordInsertion ? true : false}
                                                            onKeywordInsertionClick={async () => {

                                                                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((headline) => {
                                                                    if (headline.id === item.id) {
                                                                        if ("keywordInsertion" in headline) {
                                                                            if (headline.keywordInsertion) {
                                                                                headline.keywordInsertion = false;
                                                                            } else {
                                                                                headline.keywordInsertion = "{Keyword:";
                                                                            }
                                                                            //headline.keywordInsertion = !headline.keywordInsertion;
                                                                        } else {
                                                                            headline.keywordInsertion = true;
                                                                        }
                                                                    }
                                                                    return headline
                                                                })
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.insideHeadlines();
                                                                this.functions.validate();
                                                                this.functions.updatePreview();
                                                            }}
                                                            leftSection={item.keywordInsertion}
                                                            leftSectionLabel={item.keywordInsertion ? item.keywordInsertion + "}": "{keyword}"}
                                                            rightLabel={this.renders.headlines().length > 3}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={async () => {
                                                                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).filter((headline) => { return headline.id !== item.id });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.insideHeadlines();
                                                                this.functions.combinations();
                                                                this.functions.validate();
                                                                this.functions.updatePreview();
                                                            }}

                                                        />
                                                        {
                                                            this.state.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.includes(item.text) }).length > 0 &&
                                                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-span-3">
                                                        <DropdownTailwind
                                                            searchInput={false}
                                                            label={"Pin"}
                                                            selected={item.pinned ? item.pinned : { id: 1, name: "Auto", value: "UNSPECIFIED" }}
                                                            options={[
                                                                { id: 1, name: "Auto", value: "UNSPECIFIED" },
                                                                { id: 2, name: "First", value: "HEADLINE_1" },
                                                                { id: 3, name: "Second", value: "HEADLINE_2" },
                                                                { id: 4, name: "Third", value: "HEADLINE_3" }
                                                            ]}
                                                            onChange={async (value) => {
                                                                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines())).map((headline) => {
                                                                    if (headline.id === item.id) {
                                                                        headline.pinned = value;
                                                                    }
                                                                    return headline;
                                                                })
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.combinations();
                                                            }}

                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" && Array.isArray(this.renders.headlines()) && this.renders.headlines().length < 15 &&
                                    <div className="col-span-12">
                                        <div className="inline-flex">
                                            <div onClick={() => {
                                                this.state.updated.headlines = JSON.parse(JSON.stringify(this.renders.headlines()));
                                                this.state.updated.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "", keywordInsertion: false });
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.validate();
                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                <PlusIcon className="h-5 w-5 mr-1" /> Add new headline
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="text-sm font-medium col-span-12">
                                    Descriptions
                                </div>
                                {
                                    this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" && Array.isArray(this.renders.descriptions()) &&
                                    this.renders.descriptions().map((item, index) => {
                                        return (
                                            <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-12"}>
                                                <div className="grid grid-cols-12 col-gap-4">
                                                    <div className="col-span-9 relative">
                                                        <TextAreaTailwind
                                                            error={item.text.length > 90 || item.text === "" ? true : false}
                                                            value={item.text}
                                                            label={(index + 1) + " Descripton (" + item.text.replace("{keyword}", "").length + "/90) " + (item.text.indexOf("{keyword}") !== -1 ? "OBS! Keyword + Description cant be more than 90 characters" : "")}
                                                            onChange={async (value) => {
                                                                this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions())).map((description) => {
                                                                    if (description.id === item.id) {
                                                                        description.text = value;
                                                                    }
                                                                    return description
                                                                })
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.insideDescriptions();
                                                                this.functions.validate();
                                                                this.functions.updatePreview();
                                                            }}
                                                            lookLikeInput={true}
                                                            maxLength={90}
                                                            rightLabel={this.renders.descriptions().length > 2}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions())).filter((description) => { return description.id !== item.id });
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })

                                                                this.functions.insideDescriptions();
                                                                this.functions.combinations();
                                                                this.functions.validate();
                                                                this.functions.updatePreview();
                                                            }}

                                                        />
                                                        {
                                                            this.state.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.includes(item.text) }).length > 0 &&
                                                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-span-3">
                                                        <DropdownTailwind
                                                            searchInput={false}
                                                            label={"Pin"}
                                                            selected={item.pinned ? item.pinned : { id: 1, name: "Auto", value: "UNSPECIFIED" }}
                                                            options={[
                                                                { id: 1, name: "Auto", value: "UNSPECIFIED" },
                                                                { id: 2, name: "First", value: "DESCRIPTION_1" },
                                                                { id: 3, name: "Second", value: "DESCRIPTION_2" }
                                                            ]}
                                                            onChange={async (value) => {
                                                                item.pinned = value;
                                                                this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions())).map((description) => {
                                                                    if (description.id === item.id) {
                                                                        description.pinned = value;
                                                                    }
                                                                    return description;
                                                                })
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                                this.functions.combinations();
                                                                this.functions.updatePreview();
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.ad.advertising_type && this.state.ad.advertising_type === "RESPONSIVE_SEARCH_AD" && Array.isArray(this.renders.descriptions()) && this.renders.descriptions().length < 4 &&
                                    <div className="col-span-12">
                                        <div className="inline-flex">
                                            <div onClick={() => {
                                                this.state.updated.descriptions = JSON.parse(JSON.stringify(this.renders.descriptions()));
                                                this.state.updated.descriptions.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.validate();
                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                <PlusIcon className="h-5 w-5 mr-1" /> Add new description
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}

export default EditLiveGoogleSearchAd;
