import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import cx from "classnames";
import {BeatLoader} from 'react-spinners';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import Input from "../../components/input";
import Menu from "../../components/menu";
import Dropdown from "../../components/dropdown";

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            type: {},
            loading: false,
            error_modal: false,
            error_message: "",
        }
    };

    init = {};

    functions = {
        register: () => {
            if (!this.state.loading) {
                this.setState({loading: true}, () => {
                    dataRegister.register({
                        name: this.state.name,
                        email: this.state.email,
                        type: this.state.type.value
                    }).then((response) => {
                        this.props.history.push('/user/profile');
                    }, (error) => {
                        this.setState({
                            error_modal: true,
                            loading: false,
                            error_message: error.body.message
                        });
                    });
                });
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="ForgotPpassword">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Ups, something went wrong"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({error_modal: false});
                    }}
                />
                <div className="Login-Container">
                    <div className="Login-ContainerTitle">
                        Register user
                    </div>
                    <div className="Section">
                        <Input
                            title={'Name'}
                            type={'text'}
                            value={this.state.name}
                            size={'full'}
                            onChange={(event) => this.setState({name: event.target.value})}
                        />
                    </div>
                    <div className="Section">
                        <Input
                            title={'Email'}
                            type={'email'}
                            value={this.state.email}
                            size={'full'}
                            onChange={(event) => this.setState({email: event.target.value})}
                        />
                    </div>
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            title={'Select user type'}
                            size={'full'}
                            placeholder={"Click here .."}
                            error={this.state.error === "type" && !this.state.type.name}
                            options={[
                                {name: "Admin", value: 'admin'},
                                {name: "Agent", value: 'agent'},
                                {name: "Client", value: 'client'}
                            ]}
                            value={this.state.type}
                            onChange={(value) => this.setState({type: value})}
                        />
                    </div>
                    <div className="Section">
                        <div onClick={() => this.functions.register()} className="Button ButtonFull">
                            {
                                !this.state.loading &&
                                <div>Create</div>
                            }
                            {
                                this.state.loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;
