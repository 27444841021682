import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import { SlideDown } from "react-slidedown";
import AdvertisingCreativesSearchAdsItemSitelinks from "../advertising-creatives-search-ads-item-sitelinks";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";
import ReactTooltip from "react-tooltip";
import DropdownMultiSelect from "../dropdown-multiselect";

const fuzzysort = require('fuzzysort');

class AdvertisingSearchTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            site_links: [],
            phone_numbers: [],
            keywords: [],
            phone: {},
            edit: false,
            inner_edit: 'basic',
            selected_language: null,
            item: {},
            dropone: false,
            droptwo: false,
            dropthree: false,
            search_keywords: "",
            campaign: {}
        };
        this.multiSelect = React.createRef();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives ? nextProps.creatives : {},
            site: nextProps.site,
            item: nextProps.item,
            site_links: nextProps.site_links,
            phone_numbers: nextProps.phone_numbers,
            phone: nextProps.phone,
            index: nextProps.index,
            path1: nextProps.path1,
            path2: nextProps.path2,
            errors: nextProps.errors,
            campaign: nextProps.campaign,
            keywords: nextProps.keywords,
            new: nextProps.new,
            edit: nextProps.edit
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives ? this.props.creatives : {},
            site: this.props.site,
            item: this.props.item,
            site_links: this.props.site_links,
            phone_numbers: this.props.phone_numbers,
            campaign: this.props.campaign,
            phone: this.props.phone,
            index: this.props.index,
            errors: this.props.errors,
            path1: this.props.path1,
            path2: this.props.path2,
            edit: this.props.edit,
            new: this.props.new,
            error: this.props.error,
            keywords: this.props.keywords
        }, () => {
            console.log(this.state.path1);
            console.log(this.state.path2);
            console.log(this.state.site);
            if (this.state.new && this.state.item) {
                this.setState({
                    item: this.state.item
                })
            }
        })
    }

    init = {};

    functions = {
        remove: () => {

        },
        new: () => {
            if (this.renders.errors().length < 1) {
                this.setState({
                    error: false
                }, () => {
                    this.props.onNew(this.state.item);
                })
            } else {
                this.setState({
                    error: true
                })
            }
        }
    };

    validators = {};

    renders = {
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        invalidCharacters: (string) => {
            try {
                var pattern = /[!()/|*@"'_]/;
                return pattern.test(string);
            } catch (e) {
                return true
            }
        },
        headline: (item) => {
            try {
                let option = {};
                if (this.state.item[item].type !== 'location_insert' && this.state.item[item].type !== 'keyword_insert' && this.state.item[item].type !== 'no_option') {
                    option = {
                        name: this.renders.removeEmoji(this.state.creatives[this.state.item[item].type]),
                        type: this.state.item[item].type
                    };
                } else {
                    option = this.state.item[item];
                }
                return option
            } catch (e) {
                return {}
            }
        },
        phoneNumber: () => {
            try {
                if (this.state.phone_numbers.length > 0) {
                    return this.state.phone_numbers[0].number
                }
            } catch (e) {
                return false
            }
        },
        bold: (item) => {
            try {
                return this.state.item[item].bold
            } catch (e) {
                return false
            }
        },
        property: (type) => {
            try {
                return this.state.item[type];
            } catch (e) {
                if (type !== 'site_links') {
                    return ""
                } else {
                    return []
                }
            }
        },
        options: (last) => {
            try {
                let extras = [];
                let options = [
                    { name: 'First headline', type: 'headline' },
                    { name: 'Second headline', type: 'headline_second' },
                    { name: 'Third headline', type: 'headline_third' }
                ];
                options = options.filter((item) => {
                    return this.state.creatives[item.type] && this.state.creatives[item.type] !== "";
                }).map((item) => {
                    item.name = this.state.creatives[item.type];
                    return item
                });
                if (last) {
                    options.unshift({ name: 'No third headline', type: 'no_option', bold: true });
                }
                options.push({ name: 'Keyword', type: 'keyword_insert', bold: true });
                //options.push({ name: 'Location', type: 'location_insert', bold: true });
                options.push({ name: 'Custom headline', type: 'custom', bold: true });
                return options;
            } catch (e) {
                return [];
            }
        },
        removeEmoji: (input) => {
            try {
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            } catch (e) {
                return input
            }
        },
        customHeadline: (option) => {
            try {
                return this.state.item.custom[option]
            } catch (e) {
                return ""
            }
        },
        markdown: (input) => {
            return { __html: input }
        },
        siteLinks: () => {
            try {
                if (this.state.site_links.length > 0) {
                    let items = this.state.site_links.filter((item) => {
                        return !this.state.item.specific_sitelinks || (this.state.item.specific_sitelinks && this.state.item.specific_sitelinks[item.id])
                    }).map((item) => {
                        return item.name
                    });
                    return items.join(' ‧ ');
                } else {
                    return null;
                }
            } catch (e) {
                return null;
            }
        },
        name: (name) => {
            try {
                const n = name.toUpperCase();
                const q = this.state.search.toUpperCase();
                const x = n.indexOf(q);
                if (!q || x === -1) {
                    return name; // bail early
                }
                const l = q.length;
                return name.substr(0, x) + '<b>' + name.substr(x, l) + '</b>' + name.substr(x + l);
            } catch (e) {
                return name;
            }
        },
        baseUrl: (url) => {
            try {
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                url = 'https://' + url;
                return url;
            } catch (e) {
                return url
            }
        },
        errors: () => {
            let errors = [];
            if (((this.state.item.headline_first_template && !this.state.item.headline_first_template.type) || (this.state.item.headline_first_template && this.state.item.headline_first_template.type === "custom" && this.renders.customHeadline('headline') === ""))) {
                errors.push("Headline one is missing");
            }
            if ((this.state.item.headline_first_template && this.state.item.headline_first_template.type === "custom" && this.renders.customHeadline('headline') !== "") &&
                this.renders.invalidCharacters(this.renders.customHeadline('headline'))) {
                errors.push("Headline one contains invalid characters");
            }
            if (((this.state.item.headline_second_template && !this.state.item.headline_second_template.type) || (this.state.item.headline_second_template && this.state.item.headline_second_template.type === "custom" && this.renders.customHeadline('headline_second') === ""))) {
                errors.push("Headline two is missing");
            }
            if ((this.state.item.headline_second_template && this.state.item.headline_second_template.type === "custom" && this.renders.customHeadline('headline_second') !== "") &&
                this.renders.invalidCharacters(this.renders.customHeadline('headline_second'))) {
                errors.push("Headline two contains invalid characters");
            }
            if (((this.state.item.headline_third_template && !this.state.item.headline_third_template.type) || (this.state.item.headline_third_template && this.state.item.headline_third_template.type === "custom" && this.renders.customHeadline('headline_third') === ""))) {
                errors.push("Headline three is missing");
            }
            if ((this.state.item.headline_third_template && this.state.item.headline_third_template.type === "custom" && this.renders.customHeadline('headline_third') !== "") &&
                this.renders.invalidCharacters(this.renders.customHeadline('headline_third'))) {
                errors.push("Headline three contains invalid characters");
            }
            if ((this.state.item && (this.state.item.body === "" || !this.state.item.body))) {
                errors.push("Body is missing");
            }
            if (this.state.item && this.state.item.template_type && this.state.item.template_type.value === 'responsive') {
                if ((this.state.item && (this.state.item.body_second === "" || !this.state.item.body_second))) {
                    errors.push("Body second is missing");
                }
            }
            return errors;
        },
        templateSpecifickeywords: (item) => {
            let string = "<ul>";
            for (let key in item.specific_keywords) {
                string += "<li>" + item.specific_keywords[key].name + "</li>"
            }
            string += "</ul>";
            return string;
        },
        keywords: () => {
            let result = fuzzysort.go(this.state.search_keywords, this.state.keywords.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(this.state.keywords));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        }
    };

    render() {
        return (
            <div className="w-full flex flex-col h-full relative">
                {
                    this.state.new &&
                    <div className="mb-3 px-2 flex flex-row">
                        <div className="flex flex-1 mr-2">
                            <Dropdown
                                filter={false}
                                searchFilter={false}
                                search={false}
                                title={"Select template type"}
                                size={'full'}
                                white={false}
                                placeholder={"Select type"}
                                options={[
                                    { name: "Expanded text ad", value: "expanded" },
                                    { name: "Responsive text ad", value: "responsive" }
                                ]}
                                value={this.state.item.template_type ? this.state.item.template_type : {}}
                                onChange={(value) => {
                                    this.state.item.template_type = value;
                                    this.state.item.errors = this.renders.errors();

                                    if (value.value === "responsive") {

                                    } else {
                                        this.state.item.extra_descriptions = [];
                                    }

                                    this.setState({
                                        item: this.state.item
                                    }, () => {
                                        this.props.onChange(this.state.item);
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-1 ml-2">
                            <Dropdown
                                filter={false}
                                searchFilter={false}
                                search={false}
                                title={"Select adgroup strategy"}
                                size={'full'}
                                white={false}
                                placeholder={"Select strategy"}
                                options={[
                                    { name: "Create an adgroup per keyword", value: "individual" },
                                    { name: "Create one adgroup for all keywords", value: "combined" }
                                ]}
                                value={this.state.item.adgroup_strategy ? this.state.item.adgroup_strategy : {}}
                                onChange={(value) => {
                                    this.state.item.adgroup_strategy = value;
                                    this.state.item.errors = this.renders.errors();
                                    if (value.value == "combined") {
                                        this.state.item.overwrite = true;
                                        this.state.item.overwrite_url = this.state.campaign.website;
                                        if (this.state.campaign.path1) {
                                            this.state.item.overwrite_path1 = this.state.campaign.path1;
                                        }
                                        if (this.state.campaign.path2) {
                                            this.state.item.overwrite_path2 = this.state.campaign.path2;
                                        }
                                    } else {
                                        this.state.item.overwrite = false;
                                    }
                                    this.setState({
                                        item: this.state.item
                                    }, () => {
                                        this.props.onChange(this.state.item);
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.new && this.state.item.adgroup_strategy && this.state.item.adgroup_strategy.value === "combined" &&
                    <div className="mb-3 px-2">
                        {
                            <Input
                                sign={false}
                                title={"Adgroup name"}
                                white={false}
                                error={this.state.item.adgroup_name === "" || !this.state.item.adgroup_name}
                                value={this.state.item.adgroup_name}
                                size={'full'}
                                type={"text"}
                                icons={false}
                                onChange={(event) => {
                                    this.state.item.adgroup_name = event.target.value;
                                    this.state.item.errors = this.renders.errors();
                                    this.setState({
                                        item: this.state.item
                                    }, () => {
                                        this.props.onChange(this.state.item);
                                    })
                                }}
                            />
                        }
                        {
                            this.state.error && (this.state.item.adgroup_name === "" || !this.state.item.adgroup_name) &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-decimal">
                                    <li className="text-xs text-red-500 font-bold ">
                                        Adgroup name missing
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                }
                {
                    !this.state.new &&
                    <div className="w-full flex flex-col h-full relative">
                        <div style={{ backgroundColor: "#fcfcfc" }} className={`border relative flex flex-col w-full rounded-md h-full ${this.state.edit ? "border-green-500 border-dashed" : ""} ${this.state.errors.length > 0 ? "border border-red-500" : ""}`}>
                            <div className="action-btns flex flex-row items-center p-4 border-b">
                                {
                                    !this.state.item.no_sitelinks &&
                                    Array.isArray(this.state.site_links) && this.state.site_links.length > 0 &&
                                    <div>
                                        {
                                            (this.state.item.specific_sitelinks && Object.keys(this.state.item.specific_sitelinks).length > 0) &&
                                            <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                {
                                                    ["bg-gray-100 text-gray-500"]: true
                                                })
                                            }>
                                                {Object.keys(this.state.item.specific_sitelinks).length} site links
                                            </div>
                                            ||
                                            <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                {
                                                    ["bg-gray-100 text-gray-500"]: true
                                                })
                                            }>
                                                All site links
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.item.template_type && this.state.item.template_type.value === "responsive" &&
                                    <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full mr-2",
                                        {
                                            ["bg-gray-100 text-gray-500"]: true
                                        })
                                    }>
                                        Responsive
                                    </div>
                                }
                                {
                                    (this.state.item.specific_keywords && Object.keys(this.state.item.specific_keywords).length > 0) &&
                                    <div data-html={true} data-multiline={true} data-tip={this.renders.templateSpecifickeywords(this.state.item)} className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full mr-2",
                                        {
                                            ["bg-gray-100 text-gray-500"]: true
                                        })
                                    }>
                                        {Object.keys(this.state.item.specific_keywords).length} keywords
                                        <ReactTooltip />
                                    </div>
                                    ||
                                    <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full mr-2",
                                        {
                                            ["bg-gray-100 text-gray-500"]: true
                                        })
                                    }>
                                        All keywords
                                    </div>
                                }
                            </div>
                            <div className="p-4 mt-2">
                                <div className="">
                                    <AdvertisingSearchTemplatePreview
                                        item={this.state.item ? this.state.item : {}}
                                        url={"{website}"}
                                        path1={this.state.path1}
                                        path2={this.state.path2}
                                        site_links={Array.isArray(this.state.site_links) ? this.state.site_links.filter((item, index) => {
                                            return ((!this.state.item.specific_sitelinks || (this.state.item.specific_sitelinks && Object.keys(this.state.item.specific_sitelinks).length < 1)) || (this.state.item.specific_sitelinks && this.state.item.specific_sitelinks[item.id]))
                                        }).filter((item, index) => {
                                            return index <= 3
                                        }) : []}
                                        phone={this.renders.phoneNumber()}
                                        creatives={this.state.creatives}
                                    />
                                </div>
                            </div>
                            {
                                <div className="mt-2 flex flex-row bg-gray-100 p-4">
                                    <div className="flex flex-1 justify-start flex-row">
                                        {
                                            <button onClick={() => {
                                                this.props.onClone();
                                            }} className="text-purple-500 flex flex-row items-center flex justify-center items-center mr-5 rounded-full font-bold text-xs transition-all duration-200">
                                                <FeatherIcon className="stroke-current mr-2" size={15} icon="copy" />
                                                <span>Clone</span>
                                            </button>
                                        }
                                        {
                                            <button onClick={() => {
                                                this.props.onEdit(true);
                                            }} className="text-purple-500 flex flex-row items-center flex justify-center items-center rounded-full font-bold text-xs transition-all duration-200">
                                                <FeatherIcon className="stroke-current mr-2" size={15} icon="edit" />
                                                <span>Edit</span>
                                            </button>
                                        }
                                        <div className="flex flex-1" />
                                        {
                                            <button onClick={() => {
                                                this.props.onRemove(this.state.item);
                                            }} className="text-red-500 flex flex-row items-center flex justify-center items-center rounded-full font-bold text-xs transition-all duration-200">
                                                <FeatherIcon className="stroke-current mr-2" size={15} icon="trash" />
                                                <span>Remove template</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    this.state.new &&
                    <div className="">
                        <div className={'flex flex-row flex-wrap bg-white rounded-md'}>
                            <div className={`first-head w-full ${this.state.new ? 'md:w-1/3 md:px-2' : ''}`}>
                                {this.state.item.headline_first_template && this.state.item.headline_first_template.type !== 'custom' &&
                                    <Dropdown
                                        filter={false}
                                        searchFilter={false}
                                        search={false}
                                        title={"First headline"}
                                        size={'full'}
                                        white={false}
                                        placeholder={"Select first headline"}
                                        options={this.renders.options()}
                                        value={this.renders.headline('headline_first_template') ? this.renders.headline('headline_first_template') : {}}
                                        bold={this.renders.bold('headline_first_template')}
                                        onChange={(value) => {
                                            this.state.item.headline_first_template = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.item.headline_first_template && this.state.item.headline_first_template.type === 'custom' &&
                                    <Input
                                        sign={false}
                                        title={"First Headline"}
                                        maxLength={30}
                                        white={false}
                                        error={this.state.errors.filter((item) => {
                                            return item.type === 'template_headline'
                                        }).length > 0}
                                        value={this.renders.customHeadline('headline')}
                                        placeholder={"Max 30 characters"}
                                        tight={true}
                                        noBorderRight={false}
                                        size={'full'}
                                        type={"text"}
                                        icons={false}
                                        showCross={true}
                                        onCrossClick={() => {
                                            if (this.state.item.custom) {
                                                this.state.item.custom.headline = "";
                                            }
                                            this.state.item.headline_first_template = {
                                                name: 'Headline first',
                                                type: 'headline'
                                            };
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                        onChange={(event) => {
                                            if (!this.state.item.custom) {
                                                this.state.item.custom = {
                                                    headline: "",
                                                    headline_second: "",
                                                    headline_third: ""
                                                };
                                            }
                                            this.state.item.custom.headline = event.target.value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.error && ((this.state.item.headline_first_template && !this.state.item.headline_first_template.type) || (this.state.item.headline_first_template && this.state.item.headline_first_template.type === "custom" && this.renders.customHeadline('headline') === "")) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline option missing
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.error && (this.state.item.headline_first_template && this.state.item.headline_first_template.type === "custom" && this.renders.customHeadline('headline') !== "") &&
                                    this.renders.invalidCharacters(this.renders.customHeadline('headline')) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline containing invalid characters
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className={`second-head w-full ${this.state.new ? 'md:w-1/3 md:px-2' : ''}`}>
                                {this.state.item.headline_second_template && this.state.item.headline_second_template.type !== 'custom' &&
                                    <Dropdown
                                        filter={false}
                                        searchFilter={false}
                                        search={false}
                                        title={"Second headline"}
                                        size={'full'}
                                        placeholder={"Select second headline"}
                                        options={this.renders.options()}
                                        value={this.renders.headline('headline_second_template') ? this.renders.headline('headline_second_template') : {}}
                                        bold={this.renders.bold('headline_second_template')}
                                        onChange={(value) => {
                                            this.state.item.headline_second_template = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.item.headline_second_template && this.state.item.headline_second_template.type === 'custom' &&
                                    <Input
                                        sign={false}
                                        title={"Second headline"}
                                        maxLength={30}
                                        white={false}
                                        error={this.state.errors.filter((item) => {
                                            return item.type === 'template_headline_second'
                                        }).length > 0}
                                        value={this.renders.customHeadline('headline_second')}
                                        placeholder={"Max 30 characters"}
                                        tight={true}
                                        noBorderRight={false}
                                        size={'full'}
                                        type={"text"}
                                        icons={false}
                                        showCross={true}
                                        onCrossClick={() => {
                                            if (this.state.item.custom) {
                                                this.state.item.custom.headline_second = "";
                                            }
                                            this.state.item.headline_second_template = {
                                                name: 'Headline second',
                                                type: 'headline_second'
                                            };
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                        onChange={(event) => {
                                            if (!this.state.item.custom) {
                                                this.state.item.custom = {
                                                    headline: "",
                                                    headline_second: "",
                                                    headline_third: ""
                                                };
                                            }
                                            this.state.item.custom.headline_second = event.target.value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.error && ((this.state.item.headline_second_template && !this.state.item.headline_second_template.type) || (this.state.item.headline_second_template && this.state.item.headline_second_template.type === "custom" && this.renders.customHeadline('headline_second') === "")) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline option missing
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.error && (this.state.item.headline_second_template && this.state.item.headline_second_template.type === "custom" && this.renders.customHeadline('headline_second') !== "") &&
                                    this.renders.invalidCharacters(this.renders.customHeadline('headline_second')) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline containing invalid characters
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className={`third-head w-full ${this.state.new ? 'md:w-1/3 md:px-2' : ''}`}>
                                {
                                    this.state.item.headline_third_template && this.state.item.headline_third_template.type !== 'custom' &&
                                    <Dropdown
                                        filter={false}
                                        searchFilter={false}
                                        search={false}
                                        title={"Third headline"}
                                        size={'full'}
                                        error={this.state.errors.filter((item) => {
                                            return item.type === 'template_headline_third'
                                        }).length > 0}
                                        placeholder={"Select third headline"}
                                        options={this.renders.options(true)}
                                        value={this.renders.headline('headline_third_template') ? this.renders.headline('headline_third_template') : {}}
                                        bold={this.renders.bold('headline_third_template')}
                                        onChange={(value) => {
                                            this.state.item.headline_third_template = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.item.headline_third_template && this.state.item.headline_third_template.type === 'custom' &&
                                    <Input
                                        sign={false}
                                        title={!this.state.new ? "Headline third" : "New headline third"}
                                        maxLength={30}
                                        white={false}
                                        error={this.state.errors.filter((item) => {
                                            return item.type === 'template_headline_third'
                                        }).length > 0}
                                        value={this.renders.customHeadline('headline_third')}
                                        placeholder={"Max 30 characters"}
                                        tight={true}
                                        noBorderRight={false}
                                        size={'full'}
                                        type={"text"}
                                        icons={false}
                                        showCross={true}
                                        onCrossClick={() => {
                                            if (this.state.item.custom) {
                                                this.state.item.custom.headline_third = "";
                                            }
                                            this.state.item.headline_third_template = {
                                                name: 'No third headline',
                                                type: 'no_option',
                                                bold: true
                                            };
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                        onChange={(event) => {
                                            if (!this.state.item.custom) {
                                                this.state.item.custom = {
                                                    headline: "",
                                                    headline_second: "",
                                                    headline_third: ""
                                                };
                                            }
                                            this.state.item.custom.headline_third = event.target.value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.item.template_type && this.state.item.template_type.value === "responsive" &&
                                    (this.state.item.headline_third_template && this.state.item.headline_third_template.type === "no_option") &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Third headline is mandatory for responsive ads
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.error && ((this.state.item.headline_third_template && !this.state.item.headline_third_template.type) || (this.state.item.headline_third_template && this.state.item.headline_third_template.type === "custom" && this.renders.customHeadline('headline_third') === "")) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline option missing
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.error && (this.state.item.headline_third_template && this.state.item.headline_third_template.type === "custom" && this.renders.customHeadline('headline_third') !== "") &&
                                    this.renders.invalidCharacters(this.renders.customHeadline('headline_third')) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Headline containing invalid characters
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>

                            {/*EXTRA HEADLINES (RESPONSIVE TEMPLATE)*/}
                            {
                                this.state.item.template_type && this.state.item.template_type.value === "responsive" && Array.isArray(this.state.item.extra_headlines) &&
                                <div className="px-2 w-full">
                                    {
                                        this.state.item.extra_headlines.map((item, index) => {
                                            return (
                                                <div className="w-full mt-3">
                                                    <Input
                                                        sign={false}
                                                        title={"Extra headlines"}
                                                        maxLength={30}
                                                        white={false}
                                                        noTitle={index > 0}
                                                        value={item.text}
                                                        placeholder={"Max 30 characters"}
                                                        tight={true}
                                                        noBorderRight={false}
                                                        size={'full'}
                                                        type={"text"}
                                                        icons={false}
                                                        showCross={true}
                                                        onChange={(event) => {
                                                            item.text = event.target.value;
                                                            this.setState({
                                                                item: this.state.item
                                                            }, () => {
                                                                this.props.onChange(this.state.item);
                                                            })
                                                        }}
                                                        onCrossClick={() => {
                                                            this.state.item.extra_headlines = this.state.item.extra_headlines.filter((inner_item) => {
                                                                return item.id !== inner_item.id;
                                                            });
                                                            this.setState({
                                                                item: this.state.item
                                                            }, () => {
                                                                this.props.onChange(this.state.item);
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.item.template_type && this.state.item.template_type.value === "responsive" &&
                                <div className="w-full justify-end flex mt-3 px-2">
                                    <button onClick={() => {
                                        if (!Array.isArray(this.state.item.extra_headlines)) {
                                            this.state.item.extra_headlines = [];
                                        }
                                        this.state.item.extra_headlines.push({
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            text: ""
                                        });
                                        this.setState({
                                            item: this.state.item
                                        }, () => {
                                            this.props.onChange(this.state.item);
                                        })
                                    }} className="px-3 ml-2 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                        Add extra headline
                                    </button>
                                </div>
                            }

                            <div className={`first-body w-full mt-3 ${this.state.new ? 'md:w-1/2 md:px-2' : ''}`}>
                                <TextArea
                                    sign={true}
                                    title="Body"
                                    maxLength={90}
                                    signTitle={this.renders.characters(this.state.item.body) + ' / 90'}
                                    white={false}
                                    error={this.state.errors.filter((item) => {
                                        return item.type === 'template_body'
                                    }).length > 0}
                                    placeholder={"Max 90 characters"}
                                    value={this.state.item.body}
                                    onChange={(value) => {
                                        this.state.item.body = value.target.value;
                                        this.state.item.errors = this.renders.errors();
                                        this.setState({
                                            item: this.state.item
                                        }, () => {
                                            this.props.onChange(this.state.item);
                                        })
                                    }}
                                />
                                {
                                    (this.state.error && this.state.item && (this.state.item.body === "" || !this.state.item.body)) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Body is missing
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>

                            <div className={`second-body w-full mt-3 ${this.state.new ? 'md:w-1/2 md:px-2' : ''}`}>
                                <TextArea
                                    sign={true}
                                    maxLength={90}
                                    error={this.state.errors.filter((item) => {
                                        return item.type === 'template_body_second'
                                    }).length > 0}
                                    signTitle={this.renders.characters(this.state.item.body_second) + ' / 90'}
                                    white={false}
                                    title={(this.state.item.template_type && this.state.item.template_type.value === "responsive") ? "Body second" : "Body second (optional)"}
                                    placeholder={"Max 90 characters"}
                                    value={this.state.item.body_second}
                                    onChange={(value) => {
                                        this.state.item.body_second = value.target.value;
                                        this.state.item.errors = this.renders.errors();
                                        this.setState({
                                            item: this.state.item
                                        }, () => {
                                            this.props.onChange(this.state.item);
                                        })
                                    }}
                                />
                                {
                                    this.state.item.template_type && this.state.item.template_type.value === "responsive" &&
                                    (this.state.item.body_second === "" || !this.state.item.body_second) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-decimal">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Body second is missing
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>

                            {/*EXTRA DESCRIPTIONS (RESPONSIVE TEMPLATE)*/}
                            {
                                this.state.item.template_type && this.state.item.template_type.value === "responsive" && Array.isArray(this.state.item.extra_descriptions) &&
                                <div className="px-2 w-full">
                                    {
                                        this.state.item.extra_descriptions.map((item, index) => {
                                            return (
                                                <div className="w-full mt-3">
                                                    <Input
                                                        sign={false}
                                                        title={"Extra bodies"}
                                                        maxLength={90}
                                                        noTitle={index > 0}
                                                        white={false}
                                                        value={item.text}
                                                        placeholder={"Max 90 characters"}
                                                        tight={true}
                                                        noBorderRight={false}
                                                        size={'full'}
                                                        error={item.text === ""}
                                                        type={"text"}
                                                        icons={false}
                                                        showCross={true}
                                                        onChange={(event) => {
                                                            item.text = event.target.value;
                                                            this.setState({
                                                                item: this.state.item
                                                            }, () => {
                                                                this.props.onChange(this.state.item);
                                                            })
                                                        }}
                                                        onCrossClick={() => {
                                                            this.state.item.extra_descriptions = this.state.item.extra_descriptions.filter((inner_item) => {
                                                                return item.id !== inner_item.id;
                                                            });
                                                            this.setState({
                                                                item: this.state.item
                                                            }, () => {
                                                                this.props.onChange(this.state.item);
                                                            })
                                                        }}
                                                    />
                                                    {
                                                        item.text === "" &&
                                                        <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                            <ul className="list-disc">
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    Body invalid
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                (!Array.isArray(this.state.item.extra_descriptions) || (Array.isArray(this.state.item.extra_descriptions)) && this.state.item.extra_descriptions.length < 2) &&
                                this.state.item.template_type && this.state.item.template_type.value === "responsive" &&
                                <div className="w-full justify-end flex mt-3 px-2">
                                    <button onClick={() => {
                                        if (!Array.isArray(this.state.item.extra_descriptions)) {
                                            this.state.item.extra_descriptions = [];
                                        }
                                        this.state.item.extra_descriptions.push({
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            text: ""
                                        });
                                        this.setState({
                                            item: this.state.item
                                        }, () => {
                                            this.props.onChange(this.state.item);
                                        })
                                    }} className="px-3 ml-2 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                        Add extra body
                                    </button>
                                </div>
                            }

                            {/*IF ADGROUP PR TEMPLATE AND KEYWORD INSERT, ADD FALLBACK KEYWORD*/}

                            {
                                this.state.new && this.state.item.adgroup_strategy && this.state.item.adgroup_strategy.value === "combined" &&
                                <div className="mt-3 px-2 flex-1 flex flex-col w-full">
                                    {
                                        <Input
                                            sign={false}
                                            title={"Keyword insert fallback (max 30 characters)"}
                                            white={false}
                                            error={this.state.item.adgroup_keyword_fallback === "" || !this.state.item.adgroup_keyword_fallback}
                                            value={this.state.item.adgroup_keyword_fallback}
                                            maxLength={30}
                                            size={'full'}
                                            type={"text"}
                                            icons={false}
                                            onChange={(event) => {
                                                this.state.item.adgroup_keyword_fallback = event.target.value;
                                                this.state.item.errors = this.renders.errors();
                                                this.setState({
                                                    item: this.state.item
                                                }, () => {
                                                    this.props.onChange(this.state.item);
                                                })
                                            }}
                                        />
                                    }
                                    {
                                        this.state.error && (this.state.item.adgroup_keyword_fallback === "" || !this.state.item.adgroup_keyword_fallback) &&
                                        <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                            <ul className="list-decimal">
                                                <li className="text-xs text-red-500 font-bold ">
                                                    Keyword insert fallback is missing
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            }

                            <div className="w-full mt-3 px-2">
                                <div className="flex flex-col">
                                    <div className="flex flex-1">
                                        <div className="flex flex-1">
                                            <Input
                                                sign={false}
                                                title={(this.state.item.adgroup_strategy && this.state.item.adgroup_strategy.value === "combined") ? "Template URL" : "Overwrite keyword URL's (optional)"}
                                                white={false}
                                                value={this.state.item.overwrite ? this.state.item.overwrite_url : ""}
                                                placeholder={this.state.item.overwrite ? "Website" : "Website disabled"}
                                                disabled={!this.state.item.overwrite}
                                                tight={true}
                                                noBorderRight={false}
                                                size={'full'}
                                                type={"text"}
                                                icons={false}
                                                onChange={(value) => {
                                                    if (this.state.item.overwrite) {
                                                        this.state.item.overwrite_url = value.target.value;
                                                        this.state.item.errors = this.renders.errors();
                                                        this.setState({
                                                            item: this.state.item
                                                        }, () => {
                                                            this.props.onChange(this.state.item);
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        {
                                            (this.state.item.adgroup_strategy && this.state.item.adgroup_strategy.value === "combined") &&
                                            <div></div>
                                            ||
                                            <div style={{ marginTop: "22px", height: "55px", width: "100px", backgroundColor: "#fcfcfc" }} className="border flex rounded px-4 justify-center align-middle items-center ml-2">
                                                <Switch
                                                    onColor="#1AD5BD"
                                                    checked={this.state.item.overwrite}
                                                    onChange={(checked) => {
                                                        this.state.item.overwrite = checked;
                                                        this.state.item.errors = this.renders.errors();
                                                        this.setState({
                                                            item: this.state.item
                                                        }, () => {
                                                            this.props.onChange(this.state.item);
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.item.overwrite &&
                                        <div className="flex flex-1 mt-3">
                                            <div className="flex flex-2 mr-2">
                                                <Input
                                                    noTitle={false}
                                                    title={'Display website'}
                                                    type={'text'}
                                                    disabled={true}
                                                    placeholder={'Display website'}
                                                    value={this.state.item.overwrite ? this.renders.baseUrl(this.state.item.overwrite_url) : ""}
                                                    size={'full'}
                                                    onChange={(event) => {

                                                    }}
                                                    showKeyDown={false}
                                                />
                                            </div>
                                            <div className="flex flex-1 ml-2">
                                                <Input
                                                    noTitle={false}
                                                    title={'Path 1 (optional)'}
                                                    type={'text'}
                                                    maxLength={15}
                                                    disabled={!this.state.item.overwrite}
                                                    placeholder={'Path 1'}
                                                    value={this.state.item.overwrite ? this.state.item.overwrite_url_path1 : ""}
                                                    size={'full'}
                                                    onChange={(value) => {
                                                        this.state.item.overwrite_url_path1 = value.target.value;
                                                        this.state.item.errors = this.renders.errors();
                                                        this.setState({
                                                            item: this.state.item
                                                        }, () => {
                                                            this.props.onChange(this.state.item);
                                                        })
                                                    }}
                                                    showKeyDown={false}
                                                />
                                            </div>
                                            <div className="flex flex-1 ml-2">
                                                <Input
                                                    noTitle={false}
                                                    title={'Path 2 (optional)'}
                                                    type={'text'}
                                                    maxLength={15}
                                                    disabled={!this.state.item.overwrite}
                                                    placeholder={'Path 2'}
                                                    value={this.state.item.overwrite ? this.state.item.overwrite_url_path2 : ""}
                                                    size={'full'}
                                                    onChange={(value) => {
                                                        this.state.item.overwrite_url_path2 = value.target.value;
                                                        this.state.item.errors = this.renders.errors();
                                                        this.setState({
                                                            item: this.state.item
                                                        }, () => {
                                                            this.props.onChange(this.state.item);
                                                        })
                                                    }}
                                                    showKeyDown={false}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full mt-3 px-2 flex flex-row">
                                <div className="flex flex-1">
                                    <Dropdown
                                        filter={false}
                                        searchFilter={false}
                                        search={false}
                                        title={"Sitelink extensions (optional)"}
                                        size={'full'}
                                        placeholder={!this.state.item.no_sitelinks ? ((Array.isArray(this.state.site_links) && this.state.site_links.length > 0 ? "Click here ..." : "No extensions are available")) : "Sitelinks disabled"}
                                        options={Array.isArray(this.state.site_links) ? JSON.parse(JSON.stringify(this.state.site_links)).map((item) => {
                                            item.name = item.name + ' (' + item.url + ')';
                                            return item;
                                        }) : []}
                                        value={{}}
                                        disabled={this.state.item.no_sitelinks}
                                        removeArrow={this.state.item.no_sitelinks || !Array.isArray(this.state.site_links) || (Array.isArray(this.state.site_links) && this.state.site_links.length < 1)}
                                        onChange={(value) => {
                                            if (!this.state.item.specific_sitelinks) {
                                                this.state.item.specific_sitelinks = {};
                                            }
                                            this.state.item.specific_sitelinks[value.id] = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "23px", height: "55px", width: "100px", backgroundColor: "#fcfcfc" }} className="border flex rounded px-4 justify-center align-middle items-center ml-2">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={!this.state.item.no_sitelinks}
                                        onChange={(checked) => {
                                            this.state.item.no_sitelinks = !checked;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                !this.state.item.no_sitelinks &&
                                this.state.item.specific_sitelinks &&
                                <div className="w-full mt-3 px-2">
                                    {
                                        Object.keys(this.state.item.specific_sitelinks).map((item) => {
                                            return (
                                                <div className="targetingItem">
                                                    <div style={{ backgroundColor: "#fcfcfc" }} className="Location-Item">
                                                        <div className="Location-ItemLeft">
                                                            {this.state.item.specific_sitelinks[item].name}
                                                        </div>
                                                        <button className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                                            onClick={() => {
                                                                delete this.state.item.specific_sitelinks[item];
                                                                if (Object.keys(this.state.item.specific_sitelinks).length < 1) {
                                                                    delete this.state.item.specific_sitelinks;
                                                                }
                                                                this.state.item.errors = this.renders.errors();
                                                                this.setState({
                                                                    item: this.state.item
                                                                }, () => {
                                                                    this.props.onChange(this.state.item);
                                                                })
                                                            }}>
                                                            <FeatherIcon className="stroke-current" size={16}
                                                                icon={'trash'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                <div className="w-full mt-3 px-2">
                                    <DropdownMultiSelect
                                        ref={this.multiSelect}
                                        title={"Link specific keywords (optional)"}
                                        search={this.state.search_keywords}
                                        showType={false}
                                        showSearch={true}
                                        placeholderSearch={"Search keyword..."}
                                        type={false}
                                        types={[]}
                                        showAllOnFocus={true}
                                        loading={false}
                                        options={this.state.keywords}
                                        selected={this.state.item.specific_keywords ? Object.keys(this.state.item.specific_keywords).map((item) => {
                                            this.state.item.specific_keywords[item].id = this.state.item.specific_keywords[item].name;
                                            return this.state.item.specific_keywords[item];
                                        }) : []}
                                        onSelect={(value) => {
                                            if (!this.state.item.specific_keywords) {
                                                this.state.item.specific_keywords = {};
                                            }
                                            this.state.item.specific_keywords[value.id] = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                        onRemove={(item) => {
                                            for (let key in this.state.item.specific_keywords) {
                                                if (this.state.item.specific_keywords[key].name === item.name) {
                                                    delete this.state.item.specific_keywords[key];
                                                }
                                            }
                                            if (Object.keys(this.state.item.specific_keywords).length < 1) {
                                                delete this.state.item.specific_keywords;
                                            }
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                        onType={(value) => {

                                        }}
                                        onSearch={(value) => {
                                            this.setState({
                                                search_keywords: value
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                false &&
                                <div className="w-full mt-3 px-2">
                                    <Dropdown
                                        filter={false}
                                        searchFilter={false}
                                        search={false}
                                        title={"Link specific keywords (optional)"}
                                        size={'full'}
                                        placeholder={this.state.keywords.length > 0 ? (this.state.item.specific_keywords && Object.keys(this.state.item.specific_keywords).length === this.state.keywords.length ? "All keywords selected" : "Click here ...") : "No keywords available"}
                                        options={this.state.keywords.filter((item) => {
                                            return (this.state.item.specific_keywords && !this.state.item.specific_keywords[item.id]) || !this.state.item.specific_keywords;
                                        })}
                                        value={{}}
                                        removeArrow={this.state.item.specific_keywords && Object.keys(this.state.item.specific_keywords).length === this.state.keywords.length}
                                        onChange={(value) => {
                                            if (!this.state.item.specific_keywords) {
                                                this.state.item.specific_keywords = {};
                                            }
                                            this.state.item.specific_keywords[value.id] = value;
                                            this.state.item.errors = this.renders.errors();
                                            this.setState({
                                                item: this.state.item
                                            }, () => {
                                                this.props.onChange(this.state.item);
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                false &&
                                this.state.item.specific_keywords &&
                                <div className="w-full mt-3 px-2">
                                    {
                                        Object.keys(this.state.item.specific_keywords).map((item) => {
                                            return (
                                                <div className="targetingItem">
                                                    <div style={{ backgroundColor: "#fcfcfc" }} className="Location-Item">
                                                        <div className="Location-ItemLeft">
                                                            {this.state.item.specific_keywords[item].name}
                                                        </div>
                                                        <button
                                                            className="bg-red-200 text-red-500 ml-2 py-3 px-3 rounded mr-2"
                                                            onClick={() => {
                                                                delete this.state.item.specific_keywords[item];
                                                                if (Object.keys(this.state.item.specific_keywords).length < 1) {
                                                                    delete this.state.item.specific_keywords;
                                                                }
                                                                this.state.item.errors = this.renders.errors();
                                                                this.setState({
                                                                    item: this.state.item
                                                                }, () => {
                                                                    this.props.onChange(this.state.item);
                                                                })
                                                            }}>
                                                            <FeatherIcon className="stroke-current" size={16}
                                                                icon={'trash'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingSearchTemplate;
