import React, { Component, Fragment } from 'react';
import { BeatLoader } from "react-spinners";
import ClientOverview from "../components/client-overview";
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { Menu, Transition } from '@headlessui/react';
import { BadgeCheckIcon, CalendarIcon, ChevronDownIcon } from '@heroicons/react/outline';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { userRegister } from '../services/userRegister';
import moment from 'moment';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Runcron extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sync: false,
            syncLogs: [],
            function: {},
            functions: [
                {
                    id: 1, name: "updateAgencyClientsPerformance", value: "updateAgencyClientsPerformance",
                    sub_functions: [
                        { id: 1, name: "All", value: "all" },
                        { id: 2, name: "Accounts", value: "accounts" },
                        { id: 3, name: "Campaigns", value: "campaigns" },
                        //{ id: 4, name: "Errors", value: "errors" }
                    ]
                }
            ],
            sub_function: {},
            channel: {},
            channels: [
                { id: 1, name: "Facebook", value: "facebook" },
                { id: 2, name: "Google", value: "google" },
                { id: 3, name: "Linkedin", value: "linkedin" }
            ],
            date: { id: 1, name: "1 day", value: [moment().subtract(1, 'days').format("YYYY-MM-DD")] },
            skip_save: { id: 1, name: "Skip saving data", value: true },

            agency: {},
            agencies: [],
            loading_agencies: false,
            loading_agencies_search: false,
            loading_agencies_pagination: false,
            agency_search: "",
            agency_limit: 10,
            agency_page: 1,
            agency_sort: "name",
            agency_direction: "descending",
            agency_total: 0,

            client: {},
            clients: [],
            loading_clients: false,
            loading_clients_search: false,
            loading_clients_pagination: false,
            client_search: "",
            client_limit: 10,
            client_page: 1,
            client_sort: "name",
            client_direction: "descending",
            clients_total: 0,
            random_id: Math.floor(Math.random() * 10000000)
        };
    };

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.getAgencies(true);
    }

    componentWillUnmount() {

    }

    functions = {
        getAgencies: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_agencies_pagination: paginaton,
                loading_agencies: init
            });
            try {
                let response = await this.calls.getAgencies();
                if (!search_value || (search_value && search_value == this.state.agency_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            agencies: response.data.map((item) => { item.image = item.logo; return item }),
                            agency_total: response.meta.total
                        });
                    } else {
                        this.state.agencies = this.state.agencies.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            agencies: this.state.agencies,
                            agency_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agencies_search: false,
                loading_agencies_pagination: false,
                loading_agencies: false
            });
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        runFunction: async () => {
            let self = this;
            if (self.state.agency.id && self.state.client.id && self.state.function.id && self.state.channel.id) {
                await self.promisedSetState({
                    sync: true,
                    syncLogs: []
                });
                await self.calls.runFunction();
            }
        }
    };

    renders = {
        dates: () => {
            let options = [];
            let date = moment();
            for (let i = 1; i < 32; i++) {
                let dates = [];
                for (let m = 1; m < i + 1; m++) {
                    let new_date = date.clone();
                    dates.push(new_date.subtract(m, 'days').format("YYYY-MM-DD"));
                }
                options.push({ id: i, name: i + " days", value: dates });
            }
            return options;
        }
    };

    calls = {
        getAgencies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencies?limit=" + this.state.agency_limit + "&page=" + this.state.agency_page + "&sortBy=" + this.state.agency_sort + "&orderBy=" + this.state.agency_direction + (this.state.agency_search !== "" ? ("&search=" + this.state.agency_search) : "");
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + this.state.client_sort + "&orderBy=" + this.state.client_direction + (this.state.agency && this.state.agency.id !== 0 ? "&agency=" + this.state.agency.id : "") + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        runFunction: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/v3/adcredo/runCron?agency=" + this.state.agency.id + "&client=" + this.state.client.id + "&function=" + this.state.function.value + "&sub_function=" + this.state.sub_function.value + "&channel=" + this.state.channel.value + (this.state.skip_save && this.state.skip_save.value ? "&skip_save=true" : "") + "&dates=" + this.state.date.value + "&pusher=" + this.state.random_id;
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={async (type) => {

                        }}
                        hideUserDropdown={true}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*SECTIONS*/}
                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div className={"bg-white rounded-lg p-4 grid grid-cols-12 gap-4"}>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Select agency"}
                                    fillOut={true}
                                    small={false}
                                    searchInput={true}
                                    placeholder={"Search agencies"}
                                    selected={this.state.agency}
                                    options={this.state.agencies}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            agency: value
                                        });
                                        this.functions.getClients(true);
                                    }}
                                    onPagination={async () => {
                                        if (!this.state.loading_agencies_pagination) {
                                            await this.promisedSetState({
                                                agency_page: this.state.agency_page + 1
                                            });
                                            this.functions.getAgencies(false, true, false);
                                        }
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({
                                            loading_agencies_search: true,
                                            agency_search: value
                                        });
                                        setTimeout(async () => {
                                            if (value === this.state.agency_search) {
                                                await this.promisedSetState({
                                                    agency_page: 1
                                                });
                                                this.functions.getAgencies(false, false, true, value);
                                            }
                                        }, 400);
                                    }}
                                />
                            </div>
                            {
                                this.state.agency && this.state.agency.id &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Select client"}
                                        fillOut={true}
                                        small={false}
                                        locked={!this.state.agency.id}
                                        searchInput={true}
                                        placeholder={"Search clients"}
                                        selected={this.state.client}
                                        options={this.state.clients}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                client: value
                                            });
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_clients_pagination) {
                                                await this.promisedSetState({
                                                    client_page: this.state.client_page + 1
                                                });
                                                this.functions.getClients(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_clients_search: true,
                                                client_search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.client_search) {
                                                    await this.promisedSetState({
                                                        client_page: 1
                                                    });
                                                    this.functions.getClients(false, false, true, value);
                                                }
                                            }, 400);
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.client && this.state.client.id &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Select function"}
                                        fillOut={true}
                                        small={false}
                                        locked={!this.state.client.id}
                                        searchInput={false}
                                        placeholder={"Functions"}
                                        selected={this.state.function}
                                        options={this.state.functions}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                function: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.function && this.state.function.value == "updateAgencyClientsPerformance" &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Select sub function"}
                                        fillOut={true}
                                        small={false}
                                        searchInput={false}
                                        placeholder={"Sub functions"}
                                        selected={this.state.sub_function}
                                        options={Array.isArray(this.state.function.sub_functions) ? this.state.function.sub_functions : []}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                sub_function: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.function && this.state.function.value == "updateAgencyClientsPerformance" &&
                                this.state.sub_function.value &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Select channel"}
                                        fillOut={true}
                                        small={false}
                                        searchInput={false}
                                        placeholder={"Channels"}
                                        selected={this.state.channel}
                                        options={this.state.channels}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                channel: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.function && this.state.function.value == "updateAgencyClientsPerformance" &&
                                this.state.sub_function.value &&
                                this.state.channel.value &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Date"}
                                        fillOut={true}
                                        small={false}
                                        searchInput={false}
                                        placeholder={"Dates"}
                                        selected={this.state.date}
                                        options={this.renders.dates()}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                date: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.function && this.state.function.value == "updateAgencyClientsPerformance" &&
                                this.state.sub_function.value &&
                                this.state.channel.value &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Save data or not"}
                                        fillOut={true}
                                        small={false}
                                        searchInput={false}
                                        placeholder={"Options"}
                                        selected={this.state.skip_save}
                                        options={[
                                            { id: 1, name: "Skip saving data", value: true },
                                            { id: 2, name: "Save data", value: false }
                                        ]}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                skip_save: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                <div className="col-span-12">
                                    <div className="flex flex-1 justify-start">
                                        <button
                                            onClick={() => {
                                                this.functions.runFunction();
                                            }}
                                            className={"bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            Run function
                                            {
                                                this.state.sync &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                Array.isArray(this.state.syncLogs) && this.state.syncLogs.length > 0 &&
                                <div className="col-span-12">
                                    <div className="bg-blue-100 p-4 mt-4 text-blue-600 text-xxs font-medium rounded-md w-full">
                                        {
                                            this.state.syncLogs.map((item, index) => {
                                                return (
                                                    <div className={(index !== 0 ? "mt-2" : "") + " py-2 flex flex-col"}>
                                                        <div className="flex flex-row flex-1">
                                                            <div className={"font-bold flex-1 flex text-xs"}>
                                                                {item.date}
                                                            </div>
                                                        </div>
                                                        {
                                                            Array.isArray(item.logs) &&
                                                            item.logs.map((item, index) => {
                                                                return (
                                                                    <div className={(item.noBorder ? "" : "border-b ") + " py-2 flex flex-col"}>
                                                                        {
                                                                            item.level !== "campaign" &&
                                                                            <div className="flex flex-row flex-1">
                                                                                <div className={(item.bold ? "font-bold " : "") + " flex-1 flex"}>
                                                                                    {item.log}
                                                                                </div>
                                                                                <div className="">
                                                                                    {moment(item.time).format("hh:mm a")}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.level === "campaign" &&
                                                                            <div className="flex flex-row">{item.id}</div>
                                                                        }
                                                                        {
                                                                            item.type === "performance" &&
                                                                            <div className="flex flex-row mt-2">
                                                                                <div className="mr-2">Clicks: {item.data.clicks}</div>
                                                                                <div className="mr-2">impressions: {item.data.impressions}</div>
                                                                                <div className="mr-2">spend: {item.data.spend}</div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.level === "campaign" &&
                                                                            <div className="flex flex-row mt-2">
                                                                                <div className="mr-2">Name: {item.data.campaignName ? item.data.campaignName : "-"}</div>
                                                                                <div className="mr-2">Start: {item.data.campaignStartDate ? item.data.campaignStartDate : "-"}</div>
                                                                                <div className="mr-2">End: {item.data.campaignEndDate ? item.data.campaignEndDate : "-"}</div>
                                                                                <div className="mr-2">Status: {item.data.campaignStatus ? item.data.campaignStatus : "-"}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Runcron;
