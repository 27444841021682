import React from 'react';

import './index.css';
import FeatherIcon from "feather-icons-react";
import {BeatLoader} from "react-spinners";
import {calls} from "./calls";

export default class ChartTemplateImg extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            header: '',
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            format: this.props.format,
            header: this.props.header,
            static: this.props.static
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            format: nextProps.format,
            header: nextProps.header,
            static: nextProps.static
        })
    }

    functions = {
        onSelectFile: (e) => {
            let files = e.target.files;
            if(!files){
                files = {};
            }
            for(let i in files){
                if (files[i].type === 'video/mp4') {
                    this.functions.uploadVideo(files[i]);
                } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                    this.functions.uploadImage(files[i]);
                }
                const randomString = Math.random().toString(36);
                this.setState({
                    theInputKeyOne: randomString + 1
                });
            }
        },
        uploadImage: (image) => {
            this.setState({
                loading: true
            }, () => {
                const formData = new FormData();
                formData.append('file', image);
                calls.uploadImage(formData).then((response) => {
                    this.setState({
                        loading: false,
                        data: response.data.url,
                        format: "image"
                    }, () => {
                        if(this.props.onImage){
                            this.props.onImage(this.state.data);
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        uploadVideo: (video) => {
            this.setState({
                loading: true
            }, () => {
                const formData = new FormData();
                formData.append('file', video);
                calls.uploadVideo(formData).then((response) => {
                    this.setState({
                        loading: false,
                        data: response.data.url,
                        format: "video"
                    }, () => {
                        if(this.props.onVideo){
                            this.props.onVideo(this.state.data);
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    render() {
        return (
            <div key={this.state.theInputKeyOne || ''} className="chart-template-img text-left text-sm w-full h-full flex flex-col justify-center">
                {
                    !this.state.static &&
                    <button style={{top: "22px", left: "22px"}} className="transition-all duration-200 cursor-pointer absolute leading-none flex items-center justify-center mr-1 w-8 h-8 bg-purple-100 text-purple-500 rounded">
                        <FeatherIcon className={"stroke-current"} size={15} icon={"upload"}/>
                        <input
                            onChange={(e) => this.functions.onSelectFile(e, true)}
                            type="file"
                            multiple
                            accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                            className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                        />
                    </button>
                }
                {
                    this.state.data && !this.state.loading && this.state.format === "image" &&
                    <img
                        className="w-full h-full"
                        src={this.state.data}
                        alt={this.state.header}
                    />
                }
                {
                    this.state.data && !this.state.loading && this.state.format === "video" &&
                    <video className="w-full h-full" controls={false} loop={true} autoPlay={true} muted={true}>
                        <source    alt={this.state.header} src={ this.state.data} type="video/mp4"/>
                    </video>
                }
                {
                    this.state.loading &&
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    !this.state.data && !this.state.loading && !this.state.static &&
                    <div className="w-full h-full flex flex-col cursor-pointer justify-center items-center duration-200 text-black relative">
                        <span className="text-purple-500 cursor-pointer hover:text-purple-700 duration-200 text-base font-bold">Upload image/video</span>
                        <input
                            onChange={(e) => this.functions.onSelectFile(e, true)}
                            type="file"
                            multiple
                            accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                            className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
                        />
                    </div>
                }
            </div>
        );
    }

}
