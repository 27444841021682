import { DotsVerticalIcon, PlayIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

class PreviewGooglePerformanceMaxYoutube extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: {},
            videos: [],
            images: [],
            headlines: [],
            descriptions: [],
            long_headlines: [],
            business: "",
            website: "",
            cta: {}
        }
    };

    componentDidMount() {
        this.setState({
            videos: this.props.videos,
            images: this.props.images,
            headlines: this.props.headlines,
            descriptions: this.props.descriptions,
            long_headlines: this.props.long_headlines,
            business: this.props.business,
            website: this.props.website,
            cta: this.props.cta
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            videos: nextProps.videos,
            images: nextProps.images,
            headlines: nextProps.headlines,
            descriptions: nextProps.descriptions,
            long_headlines: nextProps.long_headlines,
            business: nextProps.business,
            website: nextProps.website,
            cta: nextProps.cta
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + (chars > 30 ? " ..." : "");
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "400px" }} className={"w-full border rounded-md p-4 bg-white"}>
                <div className="flex flex-row items-center mb-4">
                    <div className="flex flex-1">
                        <div className="bg-red-500 rounded-md px-4 py-2">
                            <PlayIcon className="text-white h-5 w-5" />
                        </div>
                    </div>
                    <div className="rounded-full h-10 w-10 bg-gray-600"></div>
                </div>
                <div className="w-full mb-4 bg-gray-400 h-10"></div>
                <div className="flex mb-4 flex-row flex-1 items-center">
                    <div className="px-4">
                        <div className="rounded-full w-14 h-14 bg-gray-600"></div>
                    </div>
                    <div className="flex flex-1 flex-col">
                        <div className="bg-gray-500 h-5 w-full"></div>
                        <div className="w-full flex flex-row">
                            <div className="bg-gray-500 h-3 flex-1 flex"></div>
                            <div className="h-3 w-5"></div>
                        </div>
                    </div>
                    <div className="pl-4">
                        <DotsVerticalIcon className="w-5 h-5 text-gray-700" />
                    </div>
                </div>
                <div className="h-48 w-full bg-gray-600 flex justify-center items-center mb-4">
                    <div className="bg-white rounded-md px-4 py-2">
                        <PlayIcon className="text-gray-600 h-5 w-5" />
                    </div>
                </div>
                <div className="flex flex-row mb-4">
                    <div className="w-6 h-6 bg-gray-600 mr-2 mt-1 overflow-hidden">
                        {
                            Array.isArray(this.state.images) &&
                            this.state.images.filter((item) => {
                                return item.type === "logo_square"
                            }).map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <img className="w-full h-full" src={item.url} />
                                    )
                                } else {
                                    return ("");
                                }
                            })
                        }
                    </div>
                    <div className="flex flex-1 flex-col">
                        <div className="text-xs font-bold">
                            {Array.isArray(this.state.long_headlines) && this.state.long_headlines.length > 0 ? this.state.long_headlines[0].text : "Headline"}
                        </div>
                        <div className="text-xs font-medium text-gray2-600 mb-1">
                            {Array.isArray(this.state.descriptions) && this.state.descriptions.length > 0 ? this.state.descriptions[0].text : "Description"}
                        </div>
                        <div className="flex flex-row">
                            <div className="h-5 w-5 mr-1 bg-yellow-400 text-xxs font-medium flex items-center justify-center text-white">
                                AD
                            </div>
                            <div className="text-xs font-medium">
                                {this.state.website ? this.state.website : "website"}
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <DotsVerticalIcon className="w-5 h-5 text-gray-700" />
                    </div>
                </div>
                <div className="w-full bg-blue-700 uppercase rounded-sm h-8 flex justify-center items-center text-sm text-white">
                    {this.state.cta ? this.state.cta.name : "Learn More"}
                </div>
            </div>
        )
    }
}

export default PreviewGooglePerformanceMaxYoutube;
