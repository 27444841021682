import { KeyIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import SuccessModal from './successModal';
import TopNavigationAgent from './topNavigationAgent';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            agency: {},
            agency_user: {},
            message: "",
            me: {},
            is_manager: false,
            loading: false,
            loading_managers: false,
            loading_adaccounts: false,
            manager: {},
            managers: [],
            adaccount: {},
            adaccounts: [],
            loading_agents_search: "",
            search_adaccounts: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            healthcheck: {},
            owner: false,
            disabled: false,
            throttling: {},
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '3033879003330818',
                //appId: '1463647207383186', DEV APPLICATION
                version: 'v6.0',
                status: false,
                redirect_uri: '',
                autoLogAppEvents: true,
                xfbml: true,
            });
            window.FB.AppEvents.logPageView();
        }.bind(this);
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me ? this.props.me : {},
            agency: this.props.agency ? this.props.agency : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.facebook && !this.state.agency.facebook) {
            this.functions.getAgents(true);
        }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me ? nextprops.me : {},
            owner: nextprops.owner,
            agency: nextprops.agency ? nextprops.agency : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "facebook",
                    token: token
                });
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ facebook: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "facebook"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ facebook: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listManagers: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.listManagers();
                await this.promisedSetState({
                    managers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        listAdaccounts: async (prevent_pagination, search_value) => {
            await this.promisedSetState({
                loading_adaccounts: true
            });
            if (!search_value || (search_value && search_value == this.state.search_adaccounts)) {
                try {
                    let response = await this.calls.listAdaccounts(prevent_pagination);
                    await this.promisedSetState({
                        adaccounts: response.data
                    });
                } catch (error) { }
                await this.promisedSetState({
                    loading_adaccounts: false,
                    loading_search_adaccounts: false
                });
            }
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.adaccount.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    manager: this.state.manager,
                    adaccount: this.state.adaccount
                });
                this.state.client.facebook = {
                    defaultAgent: this.state.agent,
                    facebookBusinessManagerId: this.state.manager.id,
                    facebookBusinessManagerName: this.state.manager.name,
                    facebookAdAccountId: this.state.adaccount.id,
                    facebookAdAccountName: this.state.adaccount.name,
                    facebookAdAccountCurrency: this.state.adaccount.currency,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    adaccount: {},
                    manager: {},
                    client: this.state.client
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_budget: true,
                error: false
            });
            try {
                let response = await this.calls.updateCientPlatformBudget({
                    channel: "facebook",
                    enabled: this.state.client.facebook.maxBudgetEnabled,
                    budget: this.state.client.facebook.maxBudget
                });
                this.state.client.facebook.campaignsWithSchedule = response.data.campaignsWithSchedule;
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_budget: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.facebook;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    adaccount: {},
                    manager: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'facebook'
                });
                this.state.agency.facebook = {
                    facebook_connected: response.data.facebook_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'facebook'
                });
                this.state.agency.facebook = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateCientPlatformBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCientPlatformBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/facebook/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listManagers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listBusinessManagers?client=" + this.state.client.id + (this.state.agency_user.id ? ("&agent=" + this.state.agency_user.id) : ("&agent=" + this.state.agent.id));
            return apiRegister.call(options, url);
        },
        listAdaccounts: (prevent_pagination, search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listAdaccounts?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id + (prevent_pagination ? "&prevent_pagination=true" : "") + "&search=" + this.state.search_adaccounts
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=facebook";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Facebook"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-facebook-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Facebook
                        </div>
                        {
                            !this.state.agency.facebook &&
                            !this.state.user.id &&
                            <div className="text-sm text-gray-500">
                                Connect Facebook to this client
                            </div>
                        }
                        {
                            !this.state.agency.facebook &&
                            this.state.user.id &&
                            <div className="text-sm text-gray-500">
                                Connect Facebook to this account
                            </div>
                        }
                        {
                            this.state.agency.facebook &&
                            <div className="text-sm text-gray-500">
                                Connect Facebook to agency
                            </div>
                        }
                    </div>
                    {
                        (
                            this.state.agency.facebook ||
                            (
                                !this.state.user.id &&
                                this.state.client.facebook &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.facebook && this.state.client.facebook.defaultAgent && this.state.client.facebook.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.facebook ? (this.state.client.facebook ? true : false) : this.state.agency.facebook.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.facebook) {
                                        if (!this.state.agency.facebook.authenticated) {
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                        }
                                    } else {
                                        if (!this.state.client.facebook) {
                                            this.state.client.facebook = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account || this.state.syncManagers) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.agency.facebook &&
                    !this.state.user.id &&
                    < div className="mt-5">
                        <DropdownTailwind
                            label={"Default agent"}
                            small={false}
                            locked={this.state.client.facebook}
                            skipInternalSearch={this.state.is_manager}
                            loader={this.state.loading_agents}
                            loadingPagination={this.state.loading_agents_pagination}
                            loadingSearch={this.state.loading_agents_search}
                            total={this.state.total}
                            searchInput={true}
                            placeholder={"..."}
                            defaultOptions={this.state.me.facebook && this.state.me.facebook.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                            pagination={this.state.total > (this.state.page * this.state.limit)}
                            selected={this.state.client.facebook && this.state.client.facebook.defaultAgent ? this.state.client.facebook.defaultAgent : this.state.agent}
                            options={this.state.agents}
                            onChange={async (value) => {
                                await this.promisedSetState({
                                    agent: value,
                                    manager: {},
                                    adaccount: {}
                                });
                                this.functions.listManagers();
                            }}
                            onPagination={async () => {
                                if (!this.state.loading_agents_pagination) {
                                    await this.promisedSetState({
                                        page: this.state.page + 1
                                    });
                                    this.functions.getAgents(false, true, false);
                                }
                            }}
                            onSearch={async (value) => {
                                if (value && value === "") {
                                    await this.promisedSetState({
                                        search: "",
                                        page: 1
                                    });
                                    this.functions.getAgents(false, false, true);
                                } else {
                                    if (this.state.throttling.current) {
                                        //SKIP
                                    } else {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: value,
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            this.state.throttling.current = true;
                                            await this.promisedSetState({
                                                search: value,
                                                throttling: this.state.throttling
                                            });
                                            setTimeout(async () => {
                                                this.state.throttling.current = false;
                                                await this.promisedSetState({
                                                    throttling: this.state.throttling
                                                });
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            }, 300);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {
                    ((this.state.client.id && (this.state.client.facebook || this.state.agent.id !== 0))) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={!this.state.agency.facebook ? (this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId ? "Business Manager" : "Business managers") : (this.state.agency.facebook && this.state.agency.facebook.facebookBusinessManagerId ? "Manager" : "Managers")}
                                loader={this.state.loading_managers}
                                searchInput={true}
                                locked={!this.state.agency.facebook ? this.state.client.facebook : this.state.agency.facebook.facebookBusinessManagerId}
                                selected={!this.state.agency.facebook ? (this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId ? { name: this.state.client.facebook.facebookBusinessManagerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" })) : (this.state.agency.facebook && this.state.agency.facebook.facebookBusinessManagerId ? { name: this.state.agency.facebook.facebookBusinessManagerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" }))}
                                options={this.state.managers}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        manager: value
                                    });
                                    if (!this.state.agency.facebook) {
                                        this.functions.listAdaccounts(true);
                                    }
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId) || this.state.manager.id)}
                            copyText={(this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId) ? this.state.client.facebook.facebookBusinessManagerId : this.state.manager.id}
                        />
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    this.state.client.id &&
                    (this.state.manager.id || (this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId)) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                loadingSearch={this.state.loading_search_adaccounts}
                                searchInput={true}
                                label={this.state.client.facebook && this.state.client.facebook.facebookAdAccountId ? "Ad account" : "Ad accounts"}
                                loader={this.state.loading_adaccounts}
                                locked={this.state.client.facebook}
                                selected={this.state.client.facebook && this.state.client.facebook.facebookAdAccountId ? { name: this.state.client.facebook.facebookAdAccountName } : (this.state.adaccount.id ? this.state.adaccount : { name: "Select adaccount" })}
                                options={this.state.adaccounts}
                                onChange={async (value) => {

                                    //PRESET CURRENCY
                                    let currency = null;
                                    this.state.currencies.map((item) => {
                                        if (item.id == value.currency) {
                                            currency = item;
                                        }
                                    });
                                    if (currency) {
                                        this.state.currency = currency;
                                    }

                                    this.setState({
                                        adaccount: value
                                    });
                                }}
                                onSearch={async (value) => {
                                    await this.promisedSetState({
                                        loading_search_adaccounts: true,
                                        search_adaccounts: value
                                    });
                                    setTimeout(async () => {
                                        if (value === this.state.search_adaccounts) {
                                            this.functions.listAdaccounts(false, value);
                                        }
                                    }, 400);
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.facebook && this.state.client.facebook.facebookAdAccountId) || this.state.adaccount.id)}
                            copyText={(this.state.client.facebook && this.state.client.facebook.facebookAdAccountId) ? this.state.client.facebook.facebookAdAccountId : this.state.adaccount.id}
                        />
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    this.state.client.id &&
                    (this.state.adaccount.id || (this.state.client.facebook && this.state.client.facebook.facebookAdAccountId)) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.facebook}
                            selected={this.state.client.facebook && this.state.client.facebook.facebookAdAccountId ? { name: this.state.client.facebook.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    false &&
                    !this.state.agency.facebook &&
                    this.state.client.id &&
                    this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId &&
                    <div className="mt-5 border-t flex items-center flex-row pt-5 w-full">
                        <div className="flex flex-2">
                            <InputTailwind
                                progressBar={this.state.client.facebook && this.state.client.facebook.maxBudgetEnabled && +this.state.client.facebook.maxBudget > 0}
                                progressBarValue={this.state.client.facebook.totalBudgetThisMonth ? +this.state.client.facebook.totalBudgetThisMonth : 0}
                                progressBarLowestValue={0}
                                progressBarTopValue={this.state.client.facebook.maxBudget}
                                progressBarPercentage={(+this.state.client.facebook.totalBudgetThisMonth / +this.state.client.facebook.maxBudget) * 100}
                                leftSection={true}
                                leftSectionLabel={this.state.client.facebook.currency ? this.state.client.facebook.currency : "-"}
                                onBlur={() => {
                                    this.functions.updateMaxBudget();
                                }}
                                onFocus={() => {

                                }}
                                loading={this.state.loading_budget}
                                full={true}
                                label={"Monthly max budget"}
                                disabled={!this.state.client.facebook.maxBudgetEnabled || this.state.loading_budget}
                                value={this.state.client.facebook && this.state.client.facebook.maxBudgetEnabled ? this.state.client.facebook.maxBudget : ""}
                                onChange={async (value) => {
                                    this.state.client.facebook.maxBudget = value;
                                    this.setState({
                                        client: this.state.client
                                    });
                                }}
                            />
                        </div>
                        <div className="flex justify-end pt-5 pl-10">
                            <SwitchTailwind
                                value={this.state.client.facebook.maxBudgetEnabled}
                                onSwitch={async () => {
                                    if (!this.state.loading_budget) {
                                        this.state.client.facebook.maxBudgetEnabled = !this.state.client.facebook.maxBudgetEnabled;
                                        if (!this.state.client.facebook.maxBudgetEnabled) {
                                            this.state.client.facebook.maxBudget = 0;
                                            this.functions.updateMaxBudget();
                                        }
                                        this.setState({
                                            client: this.state.client
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    this.state.client.id &&
                    this.state.client.facebook && this.state.client.facebook.facebookBusinessManagerId &&
                    this.state.client.facebook.maxBudgetEnabled &&
                    this.state.client.facebook.campaignsWithSchedule < 1 &&
                    <div className="mt-5 border-t pt-5 w-full">
                        <div className="bg-orange-100 p-4 rounded-md text-sm text-orange-500">
                            <div>No connected campaigns with Schedule activated</div>
                        </div>
                    </div>
                }
                {
                    this.state.adaccount.id &&
                    !this.state.client.facebook &&
                    this.state.adaccount.id &&
                    this.state.manager.id &&
                    <div className="flex w-full flex-row">
                        <button
                            onClick={() => {
                                if (!this.state.loading_account) {
                                    if (!this.state.agency.facebook) {
                                        this.functions.connectToClient();
                                    } else {
                                        this.functions.connectToAgency();
                                    }
                                }
                            }}
                            className={(((this.state.agency.facebook && this.state.agency.facebook.authenticated && this.state.manager.id) || this.state.adaccount.id) ? "bg-facebook-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Save Facebook account</span>
                            {
                                this.state.loading_account &&
                                <div className={"bg-facebook-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    !this.state.client.id &&
                    (!this.state.user.facebook || (this.state.user.facebook && !this.state.user.facebook.authenticated)) &&
                    <div className="mt-5">
                        <button onClick={() => {
                            let self = this;
                            window.FB.login(function (response) {
                                if (response.authResponse && response.status === "connected") {
                                    self.functions.login(response.authResponse.accessToken);
                                }
                            }, {
                                scope: 'ads_management, ads_read, business_management, instagram_basic, pages_manage_ads, pages_show_list, catalog_management, pages_read_engagement, pages_read_user_content',
                                return_scopes: true,
                                auth_type: 'rerequest'
                            })
                            //OBS!!!!!!! REMOVE BEFORE PUSH TO LIVE pages_manage_posts
                        }} className={(!(this.state.user.facebook && this.state.user.facebook.authenticated) ?
                            "cursor-pointer bg-facebook-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login with Facebook</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.facebook && this.state.user.facebook.authenticated) ? "bg-facebook-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    !this.state.client.id &&
                    this.state.user.facebook && this.state.user.facebook.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.facebook && this.state.user.facebook.authenticated) ?
                                "cursor-pointer bg-facebook-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.facebook && this.state.user.facebook.authenticated) ? "bg-facebook-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    false &&
                    this.state.agency.facebook &&
                    this.state.agency.facebook.authenticated &&
                    <div className="mt-5 w-full">
                        <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4"}>
                            {[
                                { name: "Clients", stat: this.state.agency.facebook.facebook_connected },
                                { name: "Managers", stat: this.state.agency.facebook.matching_managers },
                                { name: "Ad accounts", stat: this.state.agency.facebook.matching_accounts },
                                { name: "Campaigns", stat: this.state.agency.facebook.campaigns }
                            ].map((item) => (
                                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-5">
                                    <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }
                {
                    this.state.agency.facebook &&
                    this.state.agency.facebook.authenticated &&
                    this.state.syncManagers &&
                    <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                        {this.state.syncMessage}
                    </div>
                }
                {
                    (this.state.error) &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    this.state.healthcheck && this.state.healthcheck.facebook && this.state.healthcheck.facebook.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.facebook.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    !this.state.user.id &&
                    this.state.client.facebook &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.facebook && this.state.client.facebook.defaultAgent && this.state.client.facebook.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    !this.state.user.id &&
                    !this.state.is_manager &&
                    !this.state.client.facebook &&
                    !(this.state.me.facebook && this.state.me.facebook.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated Facebook, go to My Profile.
                    </div>
                }
                {
                    !this.state.agency.facebook &&
                    !this.state.user.id &&
                    this.state.is_manager &&
                    !this.state.client.facebook &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated Facebook
                    </div>
                }
            </div>
        )
    }
}

export default AccountFacebook;
