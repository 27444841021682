import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAccounts(client, access_token, access_token_secret) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/twitter/listAccounts?client=" + client + "&access_token=" + access_token + "&access_token_secret=" + access_token_secret;
        return apiRegister.call(options, url);
    }

    static getPromotableUsers(client, access_token, access_token_secret, account_id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/twitter/getPromotableUsers?client=" + client + "&access_token=" + access_token + "&access_token_secret=" + access_token_secret + "&id=" + account_id;
        return apiRegister.call(options, url);
    }

    static getAccount(client, access_token, access_token_secret, account_id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/twitter/getAccount?client=" + client + "&access_token=" + access_token + "&access_token_secret=" + access_token_secret + "&id=" + account_id;
        return apiRegister.call(options, url);
    }

    static getFundingInstruments(client, access_token, access_token_secret, account) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/twitter/listFundingInstruments?client=" + client + "&access_token=" + access_token + "&access_token_secret=" + access_token_secret + "&account=" + account;
        return apiRegister.call(options, url);
    }

    static create(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v3/twitter/connectAccount?client=" + data.client;
        return apiRegister.call(options, url);
    }

    static authenticationUrl() {
        let options = apiRegister.options(tokenRegister.get(), 'POST', {});
        let url = calls.url.api + "/v3/twitter/authenticationUrl";
        return apiRegister.call(options, url);
    }

    static authenticationVerifier(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v3/twitter/authenticationVerifier";
        return apiRegister.call(options, url);
    }

    static remove(client) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/v2/twitter/" + client;
        return apiRegister.call(options, url);
    }

}

export { calls }