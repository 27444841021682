import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, PaperClipIcon, ExclamationCircleIcon, ChevronUpIcon, ArrowLeftIcon, FolderIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';
import WizardModal from '../moduleFiles/wizardModal';

class OrderCampaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            sort: "created",
            direction: "ascending",
            order: {},
            meta: {},
            campaigns: [],
            create_wizard: false,
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channel', value: 'channel', min_width: '250px' },
                { name: 'start date', value: 'start', min_width: '250px' },
                { name: 'end date', value: 'end', min_width: '250px' }
            ],
            metrics: [
                { name: 'Spend', value: 'spend', min_width: '250px' },
                { name: 'Impressions', value: 'impressions', min_width: '250px' },
                { name: 'Clicks', value: 'clicks', min_width: '250px' },
                { name: 'CTR', value: 'ctr', min_width: '250px' },
                { name: 'CPC', value: 'cpc', min_width: '250px' },
                { name: 'CPM', value: 'cpm', min_width: '250px' },
                { name: 'Conversions', value: 'conversions', min_width: '250px' }
            ],
            status: {},
            date_span: { id: 3, name: "Last 7 days", value: "last_7" }
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.campaigns();
        this.functions.campaignsData();
        this.props.updateStatistics();
    }

    functions = {
        campaigns: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.campaigns();
                let campaigns = [];
                for (let channel in response.data) {
                    campaigns = campaigns.concat(response.data[channel]);
                }
                await this.promisedSetState({
                    order: response.order ? response.order : {},
                    campaigns: campaigns
                });
                if (this.props.setOrder) {
                    this.props.setOrder(this.state.order);
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        campaignsData: async () => {
            await this.promisedSetState({ loading_partial: true });
            try {
                let response = await this.calls.campaigns();
                let campaigns = [];
                for (let channel in response.data) {
                    campaigns = campaigns.concat(response.data[channel]);
                }
                await this.promisedSetState({
                    //order: response.order ? response.order : {},
                    //campaigns: campaigns
                });
            } catch (error) { }
            await this.promisedSetState({ loading_partial: false });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        createWizard: async (id) => {
            await this.promisedSetState({ loading_wizard: true });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.removeCampaign(temp.id);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        removeCampaignReference: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove_ref));
            await this.promisedSetState({ remove_ref: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.removeCampaignReference(temp.id);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        menu: (option, row) => {
            if (option === "Edit") {
                this.props.history.push("/v2/orders/" + this.functions.getIdFromPath() + "/optimize?campaign=" + row.campaignId + "&channel=" + row.channel + "&client=" + row.client);
            } else if (option === "Remove reference") {
                this.setState({
                    remove_ref: row
                });
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            }
        }
    };

    calls = {
        campaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?order=" + this.functions.getIdFromPath() + "&information=true" + "&datespan=" + this.state.date_span.value;
            return apiRegister.call(options, url);
        },
        campaignsData: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?order=" + this.functions.getIdFromPath() + "&statistics=true" + "&datespan=" + this.state.date_span.value;
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        },
        removeCampaign: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCampaign?id=" + id;
            return apiRegister.call(options, url);
        },
        removeCampaignReference: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeExternalCampaignRef?id=" + id + "&order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {
                            if (type === "new") {
                                this.promisedSetState({
                                    wizard_name: this.state.order.name,
                                    create_wizard: true
                                })
                            }
                        }}
                        onDateSpan={async (value) => {
                            await this.promisedSetState({
                                date_span: value
                            });
                            this.functions.campaigns();
                        }}
                        showDateSpan={!this.state.loading && this.state.campaigns.length > 0}
                        dateSpan={this.state.date_span}
                        hideUserDropdown={true}
                        breadcrumb={"Campaigns"}
                        history={this.props.history}
                        buttons={[
                            { text: "New campaign", icon: FolderIcon, value: "new", loading: this.state.loading_wizard, show: !this.state.loading && this.state.order.status !== "archived" }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*REMOVE REFERENCE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove_ref ? true : false}
                    title={"Remove reference"}
                    description={'Are you sure you want to remove campaign reference ?'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove_ref || (this.state.remove_name != this.state.remove_ref.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove_ref ? this.state.remove_ref.name : ""}
                    inputError={(!this.state.remove_ref || (this.state.remove_name != this.state.remove_ref.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove_ref: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove_ref && this.state.remove_name == this.state.remove_ref.name) {
                            this.functions.removeCampaignReference();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                <WizardModal
                    title={"New campaign (draft)"}
                    text={"Set Name below"}
                    button={"Create"}
                    open={this.state.create_wizard}
                    input={this.state.wizard_name}
                    onLoading={this.state.loading_wizard}
                    onInput={(value) => {
                        this.setState({
                            wizard_name: value
                        })
                    }}
                    onCreate={() => {
                        this.functions.createWizard();
                    }}
                    onClose={() => {
                        this.setState({
                            create_wizard: false
                        })
                    }}
                />


                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaigns ...</div>
                    </div>
                }

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO CAMPAIGNS*/}
                            {
                                this.state.campaigns.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No campaigns available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="relative overflow-scroll h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th scope="col" className="cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.concat(this.state.metrics).map((item, index) => {
                                                    let last = (this.state.columns.length - 1) === index;
                                                    return (
                                                        <th
                                                            onClick={() => {
                                                                this.setState({
                                                                    sort: item.value,
                                                                    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                })
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(!last ? " " : "") + "px-6 py-3 border-gray-300 hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.campaigns.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ whiteSpace: "nowrap" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Edit", "Remove reference", "Delete"].filter((item) => {
                                                                                            if (item === "Edit") {
                                                                                                return this.state.status.value !== "pending"
                                                                                            } else if (item === "Remove reference") {
                                                                                                return this.state.status.value !== "pending"
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Edit", item);
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={"px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active" || item.status === "enable" || item.status === "enabled" || item.status === "scheduled",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: (item.status === "archived" || item.status === "ended"),
                                                                                [" bg-gray-200 text-gray-500"]: item.status === "expired",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                ["bg-gray-100 text-gray-501"]: item.status === "draft" || item.status === "pending",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error"
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 capitalize py-4 items-center text-sm border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        {((item.status !== "draft" && item.channel === "facebook") || (item.status === "draft" && item.channels && item.channels["facebook"])) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "linkedin") || (item.status == "draft" && item.channels && item.channels["linkedin"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "google") || (item.status == "draft" && item.channels && item.channels["google"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "tiktok") || (item.status == "draft" && item.channels && item.channels["tiktok"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "snapchat") || (item.status == "draft" && item.channels && item.channels["snapchat"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "twitter") || (item.status == "draft" && item.channels && item.channels["twitter"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.status !== "draft" &&
                                                                            <div className="ml-3 font-medium">
                                                                                {item.channel}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'endDate' ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    {item.start ? moment(item.start).format('YYYY-MM-DD') : '-'}
                                                                </td>
                                                                <td className={(this.state.sort === 'endDate' ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    {item.end ? moment(item.end).format('YYYY-MM-DD') : '-'}
                                                                </td>
                                                                {
                                                                    this.state.metrics.map((metric) => {
                                                                        return (
                                                                            <td className={(this.state.sort === metric.value ? "bg-gray-50" : "") + " px-4 font-medium sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                                {item[metric.value] ? item[metric.value] : '-'}
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default OrderCampaigns;