import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, CheckIcon, XIcon } from '@heroicons/react/outline'

class SideNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            icon: null,
            title: "",
            text: ""
        }
    };

    componentDidMount() {
        this.setState({
            icon: this.props.icon,
            title: this.props.title,
            text: this.props.text
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            icon: nextProps.icon,
            title: nextProps.title,
            text: nextProps.text
        })
    }

    functions = {
        trigger: async () => {
            await this.promisedSetState({
                open: true
            });
            setTimeout(async () => {
                this.promisedSetState({
                    open: false
                });
            }, 4000);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-8 z-50 pointer-events-none pt-16 sm:items-start"
                >
                    <div className="w-full flex pt-4 flex-col items-center space-y-4 sm:items-end">
                        <Transition
                            show={this.state.open}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="max-w-sm w-full bg-white border-1.5 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            {
                                                this.state.icon &&
                                                <this.state.icon className={`h-6 w-6 ${this.props.error ? "text-red-400" : "text-green-400"}`} aria-hidden="true" />
                                            }
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            <p className="text-sm font-medium text-gray-900">{this.state.title}</p>
                                            <p className="mt-1 text-sm text-gray-500">{this.state.text}</p>
                                        </div>
                                        <div className="ml-4 flex-shrink-0 flex">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    this.setState({
                                                        open: false
                                                    });
                                                }}
                                            >
                                                <span className="sr-only">Close</span>
                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        )
    }
}

export default SideNotification;
