import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";

class AdvertisingCreativesSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            languages: [],
            errors: [],
            selected_language: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            languages: nextProps.languages,
            site: nextProps.site
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives,
            languages: this.props.languages,
            site: this.props.site
        }, () => {
            if(!this.state.selected_language){
                this.setState({
                    selected_language:this.state.languages[0].code
                })
            }
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        },
        property: (type) => {
            try{
                return this.state.creatives.search_ads[this.state.selected_language][type];
            }catch (e) {
                if(type !== 'site_links'){
                    return ""
                }else{
                    return []
                }
            }
        }
    };

    render() {
        return (
            <div>
                {
                    <div className="Tabs">
                        {
                            this.state.languages.map((item) => {
                                return (
                                    <div onClick={() => {
                                        this.setState({
                                            selected_language: item.code
                                        })
                                    }} className={
                                        cx("Tab",
                                            {
                                                ["Tab-Active"]: item.code === this.state.selected_language,
                                            })
                                    }>
                                        <span style={{textTransform: 'capitalize'}}>{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    this.state.selected_language &&
                    <div className="Section">
                        <div className="headline">
                            <Input
                                title={'Headline first'}
                                icons={true}
                                channels={Object.keys(this.state.channels).filter((key) => {
                                    return this.state.channels[key] && key === 'google'
                                })}
                                sign={true}
                                signTitle={this.renders.characters(this.renders.property('headline')) +' / 30'}
                                maxLength={30}
                                error={this.renders.property('headline') === "" && this.state.error}
                                value={this.renders.property('headline')}
                                placeholder={"Max 30 characters"}
                                disabled={false}
                                size={'full'}
                                type={"text"}
                                onChange={(value) => {
                                    try{
                                        this.state.creatives.search_ads[this.state.selected_language].headline = value.target.value;
                                        this.setState({
                                            creatives: this.state.creatives
                                        }, () => {
                                            this.props.onChange(this.state.creatives);
                                        })
                                    }catch (e) {
                                        //console.log(e);
                                    }
                                }}
                            />
                        </div>
                        <div className="headline">
                            <Input
                                title={'Headline second'}
                                icons={true}
                                channels={Object.keys(this.state.channels).filter((key) => {
                                    return this.state.channels[key] && key === 'google'
                                })}
                                sign={true}
                                signTitle={this.renders.characters(this.renders.property('headline_second')) +' / 30'}
                                maxLength={30}
                                error={this.renders.property('headline_second') === "" && this.state.error}
                                value={this.renders.property('headline_second')}
                                placeholder={"Max 30 characters"}
                                disabled={false}
                                size={'full'}
                                type={"text"}
                                onChange={(value) => {
                                    try{
                                        this.state.creatives.search_ads[this.state.selected_language].headline_second = value.target.value;
                                        this.setState({
                                            creatives: this.state.creatives
                                        }, () => {
                                            this.props.onChange(this.state.creatives);
                                        })
                                    }catch (e) {}
                                }}
                            />
                        </div>
                        <div className="headline">
                            <Input
                                title={'Headline third (optional)'}
                                icons={true}
                                channels={Object.keys(this.state.channels).filter((key) => {
                                    return this.state.channels[key] && key === 'google'
                                })}
                                sign={true}
                                signTitle={this.renders.characters(this.renders.property('headline_third')) +' / 30'}
                                maxLength={30}
                                error={this.renders.property('headline_third') === "" && this.state.error}
                                value={this.renders.property('headline_third')}
                                placeholder={"Max 30 characters"}
                                disabled={false}
                                size={'full'}
                                type={"text"}
                                onChange={(value) => {
                                    try{
                                        this.state.creatives.search_ads[this.state.selected_language].headline_third = value.target.value;
                                        this.setState({
                                            creatives: this.state.creatives
                                        }, () => {
                                            this.props.onChange(this.state.creatives);
                                        })
                                    }catch (e) {}
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.selected_language &&
                    <div className="Section">
                        <TextArea
                            maxLength={90}
                            icons={true}
                            channels={Object.keys(this.state.channels).filter((key) => {
                                return this.state.channels[key] && (key === 'google' || key === 'bing')
                            })}
                            sign={true}
                            signTitle={this.renders.characters(this.renders.property('body')) +' / 90'}
                            tiny={true}
                            size={"half"}
                            title={"Body"}
                            placeholder={"Max 90 characters"}
                            error={this.renders.property('body') === "" && this.state.error}
                            value={this.renders.property('body')}
                            onChange={(value) => {
                                try{
                                    this.state.creatives.search_ads[this.state.selected_language].body = value.target.value;
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }catch (e) {}
                            }}
                        />
                        <TextArea
                            maxLength={90}
                            icons={true}
                            channels={Object.keys(this.state.channels).filter((key) => {
                                return this.state.channels[key] && (key === 'google' || key === 'bing')
                            })}
                            tiny={true}
                            sign={true}
                            signTitle={this.renders.characters(this.renders.property('body_second')) +' / 90'}
                            size={"half"}
                            title={"Body extended (optional)"}
                            placeholder={"Max 90 characters"}
                            value={this.renders.property('body_second')}
                            onChange={(value) => {
                                try{
                                    this.state.creatives.search_ads[this.state.selected_language].body_second = value.target.value;
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }catch (e) {}
                            }}
                        />
                    </div>
                }
                {
                    this.state.selected_language &&
                    <div style={{paddingTop: '12px'}} className="Section">
                        <div className="Section-Inner">
                            <div className="Section-InnerTitle">
                                <div className={cx("Upload-ImageContainerTitleText")}>
                                    Add Site Link (optional)
                                </div>
                                <div className={cx("InputContainerTitleIcons")}>
                                    <div style={{marginLeft: '3px'}}>
                                        <IconGoogle noMargin={true}/>
                                    </div>
                                </div>
                            </div>
                            {
                                this.renders.property('site_links').map((item, index) => {
                                    return (
                                        <div className="Site-link-container">
                                            <div className="Site-link-container-name">
                                                <Input
                                                    noTitle={true}
                                                    maxLength={25}
                                                    value={item.name}
                                                    placeholder={"Name"}
                                                    disabled={index > 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3}
                                                    sign={index === 0}
                                                    signTitle={this.renders.characters(item.name) +' / 25'}
                                                    size={'full'}
                                                    type={"text"}
                                                    onChange={(value) => {
                                                        item.name = value.target.value;
                                                        this.props.onChange(this.state.creatives);
                                                    }}
                                                    showKeyDown={true}
                                                    onKeyDown={(value) => {
                                                        if (value === 13) {
                                                            if(item.name !== "" && item.url !== ""){
                                                                item.show = true;
                                                                if(this.renders.property('site_links').length < 4){
                                                                    try{
                                                                        let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                        this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                            name: "",
                                                                            url: this.state.site.url,
                                                                            id: id
                                                                        });
                                                                    }catch (e) {}
                                                                }
                                                                this.props.onChange(this.state.creatives);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="Site-link-container-url">
                                                <Input
                                                    noTitle={true}
                                                    value={item.url}
                                                    placeholder={"Url"}
                                                    sign={index === 0}
                                                    signTitle={"Url"}
                                                    disabled={index > 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3}
                                                    size={'full'}
                                                    type={"text"}
                                                    onChange={(value) => {
                                                        item.url = value.target.value;
                                                        this.props.onChange(this.state.creatives);
                                                    }}
                                                    showKeyDown={true}
                                                    onKeyDown={(value) => {
                                                        if (value === 13) {
                                                            if(item.name !== "" && item.url !== ""){
                                                                item.show = true;
                                                                if(this.renders.property('site_links').length < 4){
                                                                    try{
                                                                        let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                        this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                            name: "",
                                                                            url: this.state.site.url,
                                                                            id: id
                                                                        });
                                                                    }catch (e) {}
                                                                }
                                                                this.props.onChange(this.state.creatives);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="Site-link-container-add">
                                                {
                                                    index === 0 && this.renders.property('site_links').filter((item) => {return item.show}).length < 4 &&
                                                    <div onClick={() => {
                                                        if(item.name !== "" && item.url !== ""){
                                                            item.show = true;
                                                            if(this.renders.property('site_links').length < 4){
                                                                try{
                                                                    let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                    this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                        name: "",
                                                                        url: this.state.site.url,
                                                                        id: id
                                                                    });
                                                                }catch (e) {}
                                                            }
                                                            this.props.onChange(this.state.creatives);
                                                        }
                                                    }} className="Button ButtonNeutral ButtonFull">
                                                        <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                                                    </div>
                                                }
                                                {
                                                    (index !== 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3) &&
                                                    <div onClick={() => {
                                                        try{
                                                            this.state.creatives.search_ads[this.state.selected_language].site_links = this.state.creatives.search_ads[this.state.selected_language].site_links.filter((inner_item) => {
                                                                return inner_item.id !== item.id
                                                            });
                                                            if(this.state.creatives.search_ads[this.state.selected_language].site_links.filter((item) => {return !item.show}).length < 1){
                                                                let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                    name: "",
                                                                    url: this.state.site.url,
                                                                    id: id
                                                                });
                                                            }
                                                            this.setState({
                                                                creatives: this.state.creatives
                                                            }, () => {
                                                                this.props.onChange(this.state.creatives);
                                                            })
                                                        }catch (e) {}
                                                    }} className="Button ButtonRed ButtonFull">
                                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    this.state.selected_language &&
                    <div className="Section">
                        <AdvertisingCreativesSearchPreview
                            creatives={this.state.creatives}
                            language={this.state.selected_language}
                            url={this.state.site.url}
                            selected={'search_one'}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesSearch;
