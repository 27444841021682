import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import snapchatCTA from '../assets/json/snapchat_cta.json';
import PreviewSectionSnapchat from './previewSectionSnapchat';
import SwitchTailwind from './switchTailwind';
import { adSnapchat } from '../validators/adSnapchat';
import { SparklesIcon } from '@heroicons/react/outline';

var pusherService = null;

class EditSnapchatAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            updated: {},
            client: {},
            pages: [],
            loader: false,
            wizard: false,
            channel: { id: 1, name: "Snapchat" },
            call_to_actions: snapchatCTA,
            attachment_options: [
                { id: 1, name: "None", value: "NONE", allowed: ["awareness"] },
                { id: 2, name: "Website", value: "WEB_VIEW", allowed: ["awareness", "traffic_website"] },
                // { id: 3, name: "App install", value: "APP_INSTALL", allowed: ["awareness"] },
                // { id: 4, name: "Deep link", value: "DEEP_LINK", allowed: ["awareness"] },
                // { id: 5, name: "AR lens", value: "AD_TO_LENS", allowed: ["awareness", "engagement"] },
                // { id: 6, name: "Call", value: "AD_TO_CALL", allowed: ["awareness", "engagement"] },
                // { id: 7, name: "Text", value: "AD_TO_MESSAGE", allowed: ["awareness", "engagement"] },
                // { id: 7, name: "Lead form", value: "AD_TO_MESSAGE", allowed: ["awareness"] },
            ],
            swipe_tags: [],
            impression_tags: [],
            // snapchat_shareable: { id: 1, name: "Enabled", value: true },
            // snapchat_autofill: { id: 2, name: "Disabled", value: false },
            // snapchat_prefetching: { id: 2, name: "Disabled", value: false },
            snapchat_attachment: {},
            snapchat_shareable: {},
            snapchat_autofill: {},
            snapchat_prefetching: {},
            snapchat_adType: {},
            snapchat_playStyle: {},
            snapchat_brandname: "",
            website_error: null,
            // initial_attachment_error: null,
            // attachment_error: null,
            // goal: null,
            selectedAsset: 0,
            preview_data: {},

        }
    };

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
            wizard: this.props.wizard,
            goal: this.props.goal,
            assetItems: this.props.assetItems,
            wizardId: this.props.wizardId
        })

        if (this.props.updated) {
            this.setState({
                updated: this.props.updated
            });
        }

        this.setState({
            is_post: (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "posts") ? true : false
        });
        this.functions.listProfiles();
        this.functions.checkWebsiteError();
        // this.functions.checkInitialAttachmentError();
        if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
            this.props.onUpdatedChat(this.state.updated.OpenAiResult);
        }
        this.functions.updatePreview();
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            client: nextProps.client,
            wizard: nextProps.wizard,
            goal: nextProps.goal,
            assetItems: nextProps.assetItems,
            wizardId: nextProps.wizardId
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }
    }

    functions = {
        uploadVideo: async (file, slide) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            //this.state.updated.file = { ...this.state.updated.file, ...files_object };
            await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                if (response && response.status === 200) {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                    await self.props.updateAssets(self.state.assetItems);
                    if (!slide) {
                        self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        self.state.updated.video = response.data.url;
                    } else {
                        self.state.updated.slides = self.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                        self.state.updated.files = self.state.updated.files.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                            }
                            return item;
                        });
                    }
                    await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, loader: false });
                    self.functions.updatePreview();

                } else {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video_upload(formData, channel_id);
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                await this.props.updateAssets(this.state.assetItems);

            }
        },
        uploadImage: async (file, slide) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    name_by_channels: {
                        facebook: file.name,
                        linkedin: file.name,
                        tiktok: file.name,
                        twitter: file.name,
                        snapchat: file.name
                    },
                    type: 'image'
                };

                files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                files_object.allowed_types = { regular: true };
                files_object.allowed_multiple = true;

                this.state.assetItems.push(files_object);
                //this.state.updated.file = { ...this.state.updated.file,  };
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.image(formData);
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems });
                    await this.props.updateAssets(this.state.assetItems);
                    if (!slide) {
                        this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        this.state.updated.picture = response.data.url;
                    } else {
                        this.state.updated.slides = this.state.updated.slides.map((item) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.image = true;
                                item.video = false;
                                item.picture = response.data.url;
                                item.url = response.data.url;
                            }
                            return item;
                        });
                    }
                    await this.promisedSetState({ updated: this.state.updated, selectedAsset: response.data.id, loader: false });

                } catch (error) {
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                }
                resolve()
            });
        },

        update: async (duplicate) => {
            if (!this.state.wizard) {

            } else {
                this.state.updated.name = this.renders.name();
                this.state.updated.link = this.renders.link();
                this.state.updated.page = this.renders.page();
                this.state.updated.snapchat_brandname = this.renders.snapchat_brandname();
                this.state.updated.snapchat_shareable = this.renders.snapchat_shareable();
                this.state.updated.snapchat_attachment = this.renders.snapchat_attachment();
                this.state.updated.snapchat_prefetching = this.renders.snapchat_prefetching();
                this.state.updated.snapchat_autofill = this.renders.snapchat_autofill();
                this.state.updated.snapchat_autofill = this.renders.snapchat_autofill();
                this.state.updated.snapchat_autofill = this.renders.snapchat_autofill();
                this.props.onUpdate(this.state.updated);
            }
        },
        listProfiles: async () => {
            await this.promisedSetState({ loading_snapchat: true });
            try {
                let response = await this.calls.listProfiles();
                this.promisedSetState({
                    pages: response.data,
                    loading_snapchat: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_snapchat: false });
            }
        },
        checkWebsiteError: async () => {
            if (!this.state.updated.file.website_by_channels.snapchat.includes("https://") || this.state.updated.link && !this.state.updated.link.includes("https://")) {
                this.setState({ website_error: true })
            } else {
                this.setState({ website_error: false })
            }
        },
        onSelectFile: async (e, slide = false) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        await this.functions.uploadVideo(files[i], slide);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        await this.functions.uploadImage(files[i], slide);
                    }
                    const randomString = Math.random().toString(36);
                    await this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
            this.functions.updatePreview();
        },
        updatePreview: async () => {
            let preview_data = {
                merge: false,
                carousel: false,
                catalog: false,
                files: [],
                headline_by_channels: { snapchat: this.renders.title() },
                cta: { snapchat: this.renders.cta(this.updated) },
                page: { snapchat: this.renders.page(this.updated) },
                website_by_channels: { snapchat: this.renders.link() },
                preview_channel: this.state.updated && this.state.updated.preview_channel ? this.state.updated.preview_channel : "snapchat",
                previews: { snapchat: [{ id: 1, value: "feed", name: "Stories" }, { id: 2, value: "spotlight", name: "Spotlight" }] },
                preview: this.state.preview_data ? this.state.preview_data.preview : { value: "feed", name: "Stories" },
                snapchat_brandname: this.state.updated ? this.renders.snapchat_brandname(this.state.updated) : false,
                snapchat_phone: this.state.preview_data ? this.state.preview_data.snapchat_phone : false,
                snapchat_message: this.state.preview_data ? this.state.preview_data.snapchat_message : false,
                snapchat_attachment: this.state.updated ? this.renders.snapchat_attachment(this.state.updated) : false,
                snapchat_status: this.state.preview_data ? this.state.preview_data.snapchat_status : false,
                snapchat_shareable: this.state.preview_data ? this.state.preview_data.snapchat_shareable : false,
                snapchat_autofill: this.state.preview_data ? this.state.preview_data.snapchat_autofill : false,
                snapchat_prefetching: this.state.preview_data ? this.state.preview_data.snapchat_prefetching : false,
                utm_channels: ["snapchat"],
                channel_menu: "snapchat",
                validation: { snapchat: Object.keys(adSnapchat.validate(this.state.updated)).filter((item) => { return adSnapchat.validate(this.state.updated)[item] }).filter((item) => { return item }).length > 0 ? true : false },
            };

            if (this.state.updated) {
                if (this.state.updated.type === "posts") {
                    if (this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad") {
                        preview_data.carousel = this.state.updated;
                    } else {
                        preview_data.files = [this.state.updated];
                    }
                } else {
                    if (this.state.updated.ad_type.value !== "carousel_ad") {
                        if (this.state.updated.ad_type && this.state.updated.ad_type.value === "catalog_ad") {
                            let buff = { ...this.state.updated, files: this.state.updated.slides };
                            this.state.catalog = true;
                            if (this.state.updated.collection_ad) {
                                preview_data.instantExperience = this.state.updated.instantExperience;
                            }
                            preview_data.files = [buff];
                            preview_data.carousel = buff;
                            if (preview_data.carousel) {
                                preview_data.carousel.files = [buff];
                            }
                            preview_data.catalog = buff;
                            await this.promisedSetState({ catalog: this.state.catalog });
                        }
                        if (this.state.updated.files) {
                            preview_data.files = this.state.updated.files;
                        } else if (this.state.updated.file) {
                            preview_data.files = [this.state.updated.file];
                        }
                    } else {
                        let carousel = JSON.parse(JSON.stringify(this.state.updated));
                        carousel.files = carousel.slides;
                        preview_data.carousel = carousel;
                        preview_data.files = [this.state.updated];
                    }
                }
            }

            try {
                this.props.updatePreview(preview_data, this.state.updated)
            } catch (error) {
                console.log(error)
            }
        },
        updateOpenAi: async (data) => {
            try {
                this.state.updated.OpenAiResult = data;
                await this.promisedSetState({
                    updated: this.state.updated
                })
            } catch (error) {
                console.log(error)
            }
        },
        addHeadlines: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post && !this.state.updated.catalog) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            if (!this.state.carousel) {
                                this.state.updated.title = data[i].value[0];
                            }
                        } else {
                            if (!this.state.carousel) {
                                if (this.renders.name() === "") {
                                    this.state.updated.title = data[i].value[0];
                                }
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        addDescriptions: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            this.state.updated.body = data[i].value[0];
                        } else {
                            if (this.renders.body() === "") {
                                this.state.updated.body = data[i].value[0];
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        removeDescription: async () => {
            try {
                this.state.updated.body = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        removeHeadline: async () => {
            try {
                this.state.updated.title = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
    }

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        title: () => {
            try {
                if ('title' in this.state.updated) {
                    return this.state.updated.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        snapchat_brandname: () => {
            try {
                if ("snapchat_brandname" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_brandname;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: () => {
            try {
                if ('link' in this.state.updated) {
                    !this.state.updated.link.includes("https://") ? this.state.website_error = true : this.state.website_error = false;
                    return this.state.updated.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        page: () => {
            try {
                if ("page" in this.state.updated) {
                    return this.state.updated.page;
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        video: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    if ("video" in this.state.updated) {
                        return this.state.updated.video;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        picture: () => {
            try {
                if (this.state.updated.type === "picture") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    if ("picture" in this.state.updated) {
                        return this.state.updated.picture;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        cta: () => {
            try {
                if ("cta" in this.state.updated) {
                    return this.state.updated.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" };
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        snapchat_shareable: () => {
            try {
                if ("snapchat_shareable" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_shareable;
                } else {
                    return { id: 1, name: "Enabled", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Enabled", value: true };
            }
        },
        snapchat_attachment: () => {
            try {
                if ("snapchat_attachment" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_attachment;
                } else {
                    return { id: 0, name: "No Attachment", type: "NO_ATTACHMENT" };
                }
            } catch (error) {
                return { id: 0, name: "No ERROR", type: "NO_ATTACHMENT" };
            }
        },
        snapchat_autofill: () => {
            try {
                if ("snapchat_autofill" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_autofill;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        snapchat_prefetching: () => {
            try {
                if ("snapchat_prefetching" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_prefetching;
                } else {
                    return { id: 2, name: "Disabled", value: false };
                }
            } catch (error) {
                return { id: 2, name: "Disabled", value: false };
            }
        },
        snapchatAdType: () => {
            try {
                if ("snapchat_adType" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_adType;
                } else {
                    return { id: 1, name: "Single Image or Video", value: "feed" };
                }
            } catch (error) {
                return { id: 1, name: "Single Image or Video", value: "feed" };
            }
        },
        snapchatPlayStyle: () => {
            try {
                if ("snapchat_playStyle" in this.state.updated.file) {
                    return this.state.updated.file.snapchat_playStyle;
                } else {
                    return { id: 1, name: "Loop", value: "LOOPING" };
                }
            } catch (error) {
                return { id: 1, name: "Loop", value: "LOOPING" };
            }
        },
        type: (item) => {
            if (!this.state.wizard) {
                let type = null;
                try {
                    if (item.updated && item.updated.video !== null) {
                        type = "video";
                    }
                } catch (eror) { }
                try {
                    if (item.updated && item.updated.image !== null) {
                        type = "image";
                    }
                } catch (eror) { }
                return type;
            } else {
                return this.state.updated.type;
            }
        },
    }

    calls = {
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizardId;
            return apiRegister.call(options, url);
        },
        video_upload: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizardId + "&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        },
        update: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateAdCreative?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listProfiles: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listProfiles?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>

                <div className='flex flex-col px-4 space-y-4 bg-gray-50 pb-4'>
                    <div className="flex flex-row items-center space-x-4">
                        <div className='w-full'>
                            <DropdownTailwind
                                label={"Select other asset"}
                                channel={"snapchat"}
                                loader={this.state.loader}
                                locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                options={this.state.assetItems && this.state.assetItems.filter((item) => {
                                    if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                        return item.type === "image"
                                    } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                        return item.type === "video"
                                    } else return item
                                }).map((item) => {
                                    if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                        return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                    } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                        return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                    } else if (this.state.updated && this.state.updated.collection_ad) {
                                        return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                    }
                                }).filter((item) => { return item })}
                                onChange={async (value) => {
                                    this.state.updated.file = { ...this.state.updated.file, ...value };
                                    if (value.image) {
                                        delete this.state.updated.video;
                                        this.state.updated.picture = value.image;
                                        this.state.updated.type = "image";
                                    } else if (value.video) {
                                        delete this.state.updated.picture;
                                        this.state.updated.video = value.video;
                                        this.state.updated.type = "video";
                                    }

                                    await this.promisedSetState({
                                        updated: this.state.updated,
                                        selectedAsset: value.id
                                    });
                                    this.functions.updatePreview();
                                }}
                            />
                        </div>
                        {
                            !this.state.loader &&
                            <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                    <input
                                        onChange={(e) => this.functions.onSelectFile(e)}
                                        type="file"
                                        accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                        className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                    />
                                    {
                                        this.state.loader &&
                                        <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            <div className="ml-2 text-xs">
                                                Loading ...
                                            </div>
                                        </div>
                                    }
                                    {
                                        !this.state.loader &&
                                        <div className="text-xs text-gray-600">
                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="flex w-full space-x-4">
                        <div className='w-full'>
                            <InputTailwind
                                label={"Name"}
                                channel={"snapchat"}
                                error={adSnapchat.validate(this.state.updated).name}
                                value={this.renders.name()}
                                onChange={async (value) => {
                                    this.state.updated.name = value;
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    })
                                    this.functions.updatePreview();
                                }}
                            />
                        </div>
                        <div className='w-1/3'>
                            <InputTailwind
                                label={"Type"}
                                channel="snapchat"
                                value={this.renders.type() && typeof this.renders.type() === 'string' ? this.renders.type().charAt(0).toUpperCase() + this.renders.type().slice(1) : ''}
                                disabled={true}
                            />
                        </div>

                    </div>

                    <div className="w-full text-lg font-semibold">
                        Select identity
                    </div>

                    <div className='flex w-full space-x-4'>
                        <div className="w-full">
                            <DropdownTailwind
                                label={"Public profile"}
                                channel={"snapchat"}
                                error={adSnapchat.validate(this.state.updated).page}
                                selected={this.renders.page()}
                                locked={this.state.is_post}
                                options={this.state.pages}
                                onChange={async (value) => {
                                    this.state.updated.page = value;
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    })
                                    this.functions.updatePreview();
                                }}
                            />

                        </div>

                        <div className="w-full">
                            <InputTailwind
                                label={"Brand name (optional, 32 chars)"}
                                channel={"snapchat"}
                                value={this.renders.snapchat_brandname()}
                                maxLength={32}
                                onChange={(value) => {
                                    this.state.updated.file.snapchat_brandname = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                    this.functions.updatePreview();
                                }}
                            />
                        </div>
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Ad settings
                    </div>

                    <div className="w-full">
                        <div className="flex flex-row mb-1">
                            <div className={"bg-snapchat-500 h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                <div
                                    className="block w-2 h-2 bg-contain bg-no-repeat bg-center"
                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                            </div>
                            <div className='text-xs font-medium text-gray-700'>Ad type</div>
                        </div>
                        <div className="col-span-4 grid gap-4 grid-cols-4">
                            <div onClick={async () => {
                                this.state.updated.file.snapchat_adType = { id: 1, name: "Single Image or Video", value: "feed" };
                                await this.promisedSetState({
                                    updated: this.state.updated
                                })
                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                Single Image or Video
                            </div>
                            <div onClick={async () => {
                                this.state.updated.file.snapchat_adType = { id: 2, name: "Story", value: "story" };
                                await this.promisedSetState({
                                    updated: this.state.updated
                                })
                            }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                Story
                            </div>
                        </div>
                    </div>

                    {
                        this.renders.snapchatAdType().id === 2 &&
                        <div className="col-span-3 grid gap-4 grid-cols-4 -mt-3">
                            <div className="flex flex-row mb-1">
                                <div className={"bg-snapchat-500 h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                    <div
                                        className="block w-2 h-2 bg-contain bg-no-repeat bg-center"
                                        style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                </div>
                                <div className='text-xs font-medium text-gray-700'>Play Style</div>
                            </div>
                            <div className="col-span-4 grid gap-4 grid-cols-4 -mt-3">
                                <div onClick={async () => {
                                    this.state.updated.file.snapchat_playStyle = { id: 1, name: "Loop", value: "LOOPING" };
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    })
                                }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                    Loop
                                </div>
                                <div onClick={async () => {
                                    this.state.updated.file.snapchat_playStyle = { id: 2, name: "Auto Advance", value: "AUTO_ADVANCING" };
                                    await this.promisedSetState({
                                        updated: this.state.updated
                                    })
                                }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                    Auto Advance
                                </div>
                            </div>
                        </div>
                    }

                    <div className="w-full">
                        <InputTailwind
                            label={"Website"}
                            channel={"snapchat"}
                            error={adSnapchat.validate(this.state.updated).website}
                            value={this.renders.link()}
                            onChange={async (value) => {
                                this.state.updated.link = value;
                                await this.promisedSetState({
                                    updated: this.state.updated
                                })
                                this.functions.updatePreview();
                            }}
                        />
                        {
                            adSnapchat.validate(this.state.updated).website &&
                            <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                OBS! Prefix https:// is required.
                            </div>
                        }
                    </div>

                    <div className="w-full relative">
                        <InputTailwind
                            label={"Headline (max 34 characters)"}
                            channel={"snapchat"}
                            value={this.renders.title()}
                            maxLength={34}
                            onChange={(value) => {
                                this.state.updated.title = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                                this.functions.updatePreview();
                            }}
                        />
                        {
                            this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === "snapchat" && inner_item.value.includes(this.renders.title()) }).length > 0 }).length > 0 &&
                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                            </div>
                        }
                    </div>

                    <div className='flex w-full space-x-4'>
                        <div className="w-full">
                            <div className="flex flex-row mb-1">
                                <div className={"bg-snapchat-500 h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                    <div
                                        className="block w-2 h-2 bg-contain bg-no-repeat bg-center"
                                        style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                </div>
                                <div className='text-xs font-medium text-gray-700'>Shareable</div>
                            </div>
                            <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5 text-xs">
                                    {this.renders.snapchat_shareable().name}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.renders.snapchat_shareable().value}
                                        onSwitch={async () => {
                                            if (this.renders.snapchat_shareable().value) {
                                                this.state.updated.file.snapchat_shareable = { id: 2, name: "Disabled", value: false };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })

                                            } else {
                                                this.state.updated.file.snapchat_shareable = { id: 1, name: "Enabled", value: true };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <DropdownTailwind
                                label={"Attachment"}
                                channel={"snapchat"}
                                selected={this.renders.snapchat_attachment()}
                                locked={true}
                                options={this.state.attachment_options}
                                onChange={async (value) => {
                                    this.state.updated.file.snapchat_attachment = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                    // await this.functions.checkAttachmentError(value);
                                }}
                            />
                            {/* {
                                // this.state.snapchat_attachment.value === "NONE" &&
                                // this.state.adgroups.length > 0 && this.state.adgroups.filter(item => item.goal.value === "SWIPES").length > 0 &&
                                this.state.attachment_error &&
                                <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                    OBS! Optimization goal "Swipe up" requires website attachment.
                                </div>
                            } */}
                        </div>
                    </div>

                    <div className="flex w-full space-x-4">
                        <div className="w-full">
                            <div className="flex flex-row mb-1">
                                <div className={"bg-snapchat-500 h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                    <div
                                        className="block w-2 h-2 bg-contain bg-no-repeat bg-center"
                                        style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                </div>
                                <div className='text-xs font-medium text-gray-700'>Autofill</div>
                            </div>
                            <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5 text-xs">
                                    {this.renders.snapchat_autofill().name}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.renders.snapchat_autofill().value}
                                        onSwitch={async () => {
                                            if (this.renders.snapchat_autofill().value) {
                                                this.state.updated.file.snapchat_autofill = { id: 2, name: "Disabled", value: false };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })

                                            } else {
                                                this.state.updated.file.snapchat_autofill = { id: 1, name: "Enabled", value: true };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-row mb-1">
                                <div className={"bg-snapchat-500 h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                    <div
                                        className="block w-2 h-2 bg-contain bg-no-repeat bg-center"
                                        style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                </div>
                                <div className='text-xs font-medium text-gray-700'>Prefetching</div>
                            </div>
                            <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5 text-xs">
                                    {this.renders.snapchat_prefetching().name}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.renders.snapchat_prefetching().value}
                                        onSwitch={async () => {
                                            if (this.renders.snapchat_prefetching().value) {
                                                this.state.updated.file.snapchat_prefetching = { id: 2, name: "Disabled", value: false };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                            } else {
                                                this.state.updated.file.snapchat_prefetching = { id: 1, name: "Enabled", value: true };
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full text-lg font-semibold">
                        Select CTA
                    </div>

                    <div className="w-full">
                        <DropdownTailwind
                            label={"Call to action"}
                            channel={"snapchat"}
                            error={adSnapchat.validate(this.state.updated).cta}
                            selected={this.renders.cta()}
                            locked={false}
                            options={this.state.call_to_actions}
                            onChange={async (value) => {
                                this.state.updated.cta = value;
                                await this.promisedSetState({
                                    updated: this.state.updated
                                })
                                this.functions.updatePreview();
                            }}
                        />
                    </div>

                </div>

                {/* {
                    <div className="grid grid-cols-12">

                        {
                            this.state.updated &&
                            Array.isArray(this.state.updated.previews) &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                {
                                    (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type.value === "video_ad") &&
                                    //(!this.state.updated.ad_type.value && (this.state.updated.ad_type === "image" || this.state.updated.ad_type === "video") && this.state.updated.type !== "posts")
                                    //&&
                                    <div className="col-span-3 grid grid-cols-3 gap-4">
                                        <div className="col-span-2">
                                            <DropdownTailwind
                                                label={"Select other asset"}
                                                channel={"snapchat"}
                                                loader={this.state.loader}
                                                locked={!this.state.wizard}
                                                selected={this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                                options={this.state.assetItems.filter((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image") {
                                                        return item.type === "image"
                                                    } else if (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video") {
                                                        return item.type === "video"
                                                    } else return item
                                                }).map((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image") {
                                                        return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                    } else if (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video") {
                                                        return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                    } else if (this.state.updated.collection_ad) {
                                                        return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                                    }
                                                })}
                                                onChange={async (value) => {
                                                    this.state.updated.file = { ...this.state.updated.file, ...value };

                                                    if (value.image) {
                                                        delete this.state.updated.video;
                                                        this.state.updated.picture = value.image;
                                                        this.state.updated.type = "image";
                                                    } else if (value.video) {
                                                        delete this.state.updated.picture;
                                                        this.state.updated.video = value.video;
                                                        this.state.updated.type = "video";
                                                    }

                                                    await this.promisedSetState({
                                                        updated: this.state.updated,
                                                        selectedAsset: value.id
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1 pt-5.5">
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                <div className="space-y-1 text-center justify-center flex flex-col">
                                                    {
                                                        this.state.loader &&
                                                        <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            <div className="ml-2 text-xs">
                                                                Loading ...
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.loader &&
                                                        <div className="text-xxs text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview"}
                                        selected={this.state.updated.preview}
                                        options={this.state.updated.previews}
                                        onChange={(value) => {
                                            this.state.updated.preview = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3 mx-auto">
                                    <PreviewSectionSnapchat
                                        ad={this.state.updated}
                                        channel={{ value: "snapchat" }}
                                    />
                                </div>
                            </div>
                        }



                        <div className="col-span-7 grid grid-cols-3 gap-4 content-start pb-10 pl-6">


                            <div className="col-span-4 grid gap-4 grid-cols-4">
                                <div className="flex flex-row">
                                    <div className='text-xs font-medium text-gray-700'>Ad type</div>
                                </div>
                                <div className="col-span-4 grid gap-4 grid-cols-4 -mt-3">
                                    <div onClick={async () => {
                                        this.state.updated.file.snapchat_adType = { id: 1, name: "Single Image or Video", value: "feed" };
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                        Single Image or Video
                                    </div>
                                    <div onClick={async () => {
                                        this.state.updated.file.snapchat_adType = { id: 2, name: "Story", value: "story" };
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                    }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatAdType().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                        Story
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Ad name"}
                                    error={adSnapchat.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Public profile"}
                                    error={adSnapchat.validate(this.state.updated).page}
                                    selected={this.renders.page()}
                                    options={this.state.pages}
                                    onChange={(value) => {
                                        this.state.updated.page = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Brand name (optional, 32 characters max)"}
                                    value={this.renders.snapchat_brandname()}
                                    maxLength={32}
                                    onChange={(value) => {
                                        this.state.updated.file.snapchat_brandname = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Website"}
                                    error={adSnapchat.validate(this.state.updated).website}
                                    value={this.renders.link()}
                                    onChange={(value) => {
                                        // value.includes("https://") ? this.state.website_error = false : this.state.website_error = true;
                                        this.state.updated.link = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                                {
                                    this.state.website_error &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Headline (max 34 characters)"}
                                    value={this.renders.title()}
                                    maxLength={34}
                                    onChange={(value) => {
                                        this.state.updated.title = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className="flex w-full space-x-4">
                                <div className="w-full">
                                    <div className="flex flex-row">
                                        <div className='text-xs font-medium text-gray-700'>Shareable</div>
                                    </div>
                                    <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5 text-xs">
                                            {this.renders.snapchat_shareable().name}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.snapchat_shareable().value}
                                                onSwitch={async () => {
                                                    if (this.renders.snapchat_shareable().value) {
                                                        this.state.updated.file.snapchat_shareable = { id: 2, name: "Disabled", value: false };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })

                                                    } else {
                                                        this.state.updated.file.snapchat_shareable = { id: 1, name: "Enabled", value: true };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.renders.snapchatAdType().id === 2 &&
                                <div className="col-span-3 grid gap-4 grid-cols-4 -mt-3">
                                    <div className="flex flex-row">
                                        <div className='text-xs font-medium text-gray-700'>Play Style</div>
                                    </div>
                                    <div className="col-span-4 grid gap-4 grid-cols-4 -mt-3">
                                        <div onClick={async () => {
                                            this.state.updated.file.snapchat_playStyle = { id: 1, name: "Loop", value: "LOOPING" };
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 1 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                            Loop
                                        </div>
                                        <div onClick={async () => {
                                            this.state.updated.file.snapchat_playStyle = { id: 2, name: "Auto Advance", value: "AUTO_ADVANCING" };
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }} className={"col-span-2 text-sm font-medium justify-center flex items-center h-12 relative overflow-hidden border-1.5 rounded-md text-center " + (this.renders.snapchatPlayStyle().id === 2 ? "border-purple-300 bg-purple-100 " : "bg-gray-100 border-gray-300 cursor-pointer hover:bg-purple-100 hover:border-purple-300")}>
                                            Auto Advance
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-span-3 grid gap-4 grid-cols-4">
                                <div className="col-span-2">
                                    <div className="flex flex-row">
                                        <div className='text-xs font-medium text-gray-700'>Autofill</div>
                                    </div>
                                    <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5 text-xs">
                                            {this.renders.snapchat_autofill().name}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.snapchat_autofill().value}
                                                onSwitch={async () => {
                                                    if (this.renders.snapchat_autofill().value) {
                                                        this.state.updated.file.snapchat_autofill = { id: 2, name: "Disabled", value: false };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })

                                                    } else {
                                                        this.state.updated.file.snapchat_autofill = { id: 1, name: "Enabled", value: true };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="flex flex-row">
                                        <div className='text-xs font-medium text-gray-700'>Prefetching</div>
                                    </div>
                                    <div className='flex items-center justify-center mt-1 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5 text-xs">
                                            {this.renders.snapchat_prefetching().name}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.snapchat_prefetching().value}
                                                onSwitch={async () => {
                                                    if (this.renders.snapchat_prefetching().value) {
                                                        this.state.updated.file.snapchat_prefetching = { id: 2, name: "Disabled", value: false };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    } else {
                                                        this.state.updated.file.snapchat_prefetching = { id: 1, name: "Enabled", value: true };
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Call to action"}
                                    channel={"snapchat"}
                                    error={adSnapchat.validate(this.state.updated).cta}
                                    selected={this.state.updated.file && this.state.updated.file.snapchat_attachment.value !== "NONE" ? this.renders.cta() : { id: 1, name: "None", value: "NONE", allowed: ["awareness"] }}
                                    locked={this.state.updated.file && this.state.updated.file.snapchat_attachment.value === "NONE" ? true : false}
                                    options={this.state.call_to_actions}
                                    onChange={(value) => {
                                        this.state.updated.cta = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                } */}

            </>
        )
    }
}

export default EditSnapchatAd;
