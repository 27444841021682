import { ArrowDownIcon, ArrowUpIcon, DuplicateIcon, PlusIcon, PencilAltIcon, PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { ViewGridIcon } from '@heroicons/react/solid';
import React, { Component, Fragment } from 'react';

class AdgroupTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: "",
            campaigns: [],
            adgroups: [],
            show_side_menu: false,
            minimize_adgroups: false,
            hide_remove: false,
            hide_new: false,
            hide_clone: false
        }
    };

    componentDidMount() {
        this.setState({
            channel: this.props.channel,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            show_side_menu: this.props.showSideMenu,
            minimize_adgroups: this.props.minimize,
            hide_remove: this.props.hideRemoveButton,
            hide_new: this.props.hideNewButton,
            hide_clone: this.props.hideCloneButton
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            channel: nextProps.channel,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            show_side_menu: nextProps.showSideMenu,
            minimize_adgroups: nextProps.minimize,
            hide_remove: nextProps.hideRemoveButton,
            hide_new: nextProps.hideNewButton,
            hide_clone: nextProps.hideCloneButton
        })
    }

    render() {
        return (
            <div className="flex flex-row bg-gray-50">
                <div className="w-0 border-b-1.5"></div>
                <div className="w-0 border-b-1.5"></div>
                {
                    Array.isArray(this.state.adgroups) &&
                    this.state.adgroups.filter((adgroup) => {
                        return adgroup.channel === this.state.channel && this.state.campaigns.filter((campaign) => { return campaign.selected && adgroup.campaign === campaign.id }).length > 0
                    }).sort((a, b) => {
                        let a_campaign = this.state.campaigns.filter((campaign) => { return campaign.id === a.campaign })[0];
                        let b_campaign = this.state.campaigns.filter((campaign) => { return campaign.id === b.campaign })[0];
                        return (a_campaign.group_color ? a_campaign.group_color.id : 0) - (b_campaign.group_color ? b_campaign.group_color.id : 0);
                    }).map((adgroup, index) => {
                        /*
                        let color_group_start_index = -1;
                        let color_group_length = 0;
                        let color_group = {};
                        let campaign = this.state.campaigns.filter((campaign) => { return campaign.id === adgroup.campaign })[0];
                        this.state.campaigns.filter((item) => { return item.channel === this.state.channel }).sort((a, b) => { return (a.group_color ? a.group_color.id : 0) - (b.group_color ? b.group_color.id : 0) }).map((item, index) => {
                            if (color_group_start_index < 0 && item.group_color && campaign.group_color && campaign.group_color.id === item.group_color.id) {
                                color_group_start_index = index;
                                color_group = campaign.group_color;
                            } else if (item.group_color && campaign.group_color && campaign.group_color.id === item.group_color.id) {
                                color_group_length = color_group_length + 1;
                            }
                        });
                        */
                        return (
                            <Fragment>
                                <div onClick={() => {
                                    if (window.event && window.event.shiftKey) {
                                        adgroup.selected = !adgroup.selected;
                                    } else {
                                        this.state.adgroups = this.state.adgroups.map((item) => {
                                            item.selected = (item.id === adgroup.id);
                                            return item;
                                        });
                                    }
                                    this.props.onAdgroups(this.state.adgroups);
                                }}
                                    style={adgroup.selected ? { borderBottomColor: "#ffffff" } : {}}
                                    className={(adgroup.selected ? "" : "text-gray-300 hover:text-gray-900") + " border-1.5 py-3 h-14 bg-white max-w-1/5 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                    {
                                        <ViewGridIcon className={(adgroup.selected ? "" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                    }
                                    <div className="flex flex-1 truncate">
                                        <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                            {adgroup.name}
                                        </div>
                                    </div>
                                    <div onClick={() => {
                                        this.state.adgroups = this.state.adgroups.filter((item) => {
                                            return item.id !== adgroup.id;
                                        });
                                        this.props.onAdgroups(this.state.adgroups);
                                    }} className={(this.state.adgroups.filter((item) => {
                                        return item.channel === this.state.channel
                                    }).length < 2 ? 'opacity-0' : 'opacity-100') + " border-1.5 -mr-2 h-5 w-5 rounded-full hover:text-red-500 flex justify-center items-center border-opacity-0 border-red-500 hover:border-opacity-100 hover:bg-red-100"}>
                                        <XIcon className="h-4 w-4" />
                                    </div>
                                </div>
                                <div className="w-2 border-b-1.5"></div>
                            </Fragment>
                        )
                    })
                }
                {
                    this.state.show_side_menu &&
                    <div className="flex flex-1 flex-row">
                        {
                            !this.state.hide_new &&
                            <div onClick={() => {
                                this.props.onNew("adgroup");
                            }} className={"text-purple-500 bg-white hover:text-purple-700 cursor-pointer border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                                <PlusIcon className={"h-6 w-6"} />
                            </div>
                        }
                        {
                            !this.state.hide_new &&
                            <div className="w-2 border-b-1.5"></div>
                        }
                        <div onClick={() => {
                            this.props.onEdit("adgroup");
                        }} className={(this.state.adgroups.filter((item) => {
                            return item.selected && item.channel === this.state.channel
                        }).length > 0 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " bg-white border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                            <PencilAltIcon className={"h-6 w-6"} />
                        </div>
                        {
                            !this.state.hide_clone &&
                            <div className="w-2 border-b-1.5"></div>
                        }
                        {
                            !this.state.hide_clone &&
                            <div onClick={() => {
                                if (this.state.adgroups.filter((item) => {
                                    return item.selected && item.channel === this.state.channel
                                }).length === 1) {
                                    this.props.onClone("adgroup");
                                }
                            }} className={(this.state.adgroups.filter((item) => {
                                return item.selected && item.channel === this.state.channel
                            }).length === 1 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " bg-white border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                                <DuplicateIcon className={"h-6 w-6"} />
                            </div>
                        }
                        <div className="flex-1 flex border-b-1.5"></div>
                    </div>
                }
            </div>
        )
    }
}

export default AdgroupTabs;
