import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, IdentificationIcon, CurrencyDollarIcon, InformationCircleIcon, CubeIcon, UsersIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import SlideDown from 'react-slidedown';
import { isNumber, round } from '@turf/turf';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';
import { userRegister } from '../services/userRegister';

class CreateOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            note: "",
            clients: [],
            client: { id: 0, name: "Click to search ..." },
            loading_clients: false,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: null,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            selected_client: {},
            throttling: {},
            agents: [],
            agent_options: [],
            loading_agents_search: "",
            loading_agents_pagination: 0,
            loading_agents: false,
            open: {
                clients: true
            },
            total_budget: 0,
            google_budget: 0,
            bing_budget: 0
        };
    }

    componentDidMount() {
        this.functions.getUser();
        this.functions.getClients(true)
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            this.state.agents.push({
                email: this.state.user.email,
                id: this.state.user.id
            });
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        createOrder: async () => {
            this.setState({
                open: {}
            })
            let order = {
                name: this.state.name,
                crmId: this.state.crmId,
                crmRef: this.state.crmRef,
                maxBudget: this.state.total_budget,
                ...(this.state.selected_client.id && { client: this.state.selected_client.id }),
                ...(this.state.startDate && { startDate: this.state.startDate }),
                endDate: this.state.endDate,
                documents: this.state.documents,
                agents: this.state.agents,
                //public: this.state.public,
                //client_status: { id: 3, name: "Pending", value: "pending", step: 1 },
                //...(this.state.budget_split && { budget_split: { value: this.state.budget_split, budget: this.state.total_budget, bing_budget: this.state.bing_budget, google_budget: this.state.google_budget } }),
            };
            try {
                let response = await this.calls.createOrder(order);
                this.props.onCreated(response);
            } catch (error) {
                this.props.onError();
                await this.promisedSetState({ error: error.body ? error.body.message : "Something went wrong" });
            }
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i] && files[i].name && files[i].size) {
                        this.functions.uploadDocument(files[i]);
                    }
                }
            }
            const randomString = Math.random().toString(36);
            this.promisedSetState({
                theInputKeyOne: randomString + 1
            });
        },
        uploadDocument: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name
            };
            this.state.documents.push(files_object);
            await this.promisedSetState({ documents: this.state.documents });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.uploadDocument(formData);
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            } catch (error) {
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            }
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agent_options: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agent_options = this.state.agent_options.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agent_options: this.state.agent_options,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        uploadDocument: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadFile?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, false);
            let url = apiRegister.url.api + "/v3/adcredo/createOrder";
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">


                    {/* SELECT CLIENT */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <IdentificationIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Select client</div>
                                        {
                                            this.state.selected_client && this.state.selected_client.id &&
                                            <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.selected_client.name}</div></div>
                                        }
                                        {
                                            !(this.state.selected_client && this.state.selected_client.id) &&
                                            <div className="font-medium text-xs text-gray-500">No client selected</div>
                                        }
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.selected_client && this.state.selected_client.id &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            //if (!this.props.report) {
                                            this.state.open.clients = !this.state.open.clients;
                                            this.setState({ open: this.state.open })
                                            //}
                                        }}
                                    >
                                        {
                                            //!this.props.report &&
                                            !this.state.open.clients &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            //!this.props.report &&
                                            this.state.open.clients &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                        {
                                            false &&
                                            this.props.report &&
                                            <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.clients}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <DropdownTailwind
                                            small={false}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_clients}
                                            loadingPagination={this.state.loading_clients_pagination}
                                            loadingSearch={this.state.loading_clients_search}
                                            total={this.state.clients_total}
                                            searchInput={true}
                                            placeholder={"Search ..."}
                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                            selected={this.state.selected_client.id !== 1 ? this.state.selected_client : { id: 1, name: "Select client" }}
                                            options={this.state.clients.length > 0 ? this.state.clients : []}
                                            onChange={async (value) => {
                                                this.state.open.clients = false;
                                                this.state.open.information = true;
                                                await this.promisedSetState({
                                                    selected_client: value,
                                                    open: this.state.open
                                                });
                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_clients_pagination) {
                                                    await this.promisedSetState({
                                                        client_page: this.state.client_page + 1
                                                    });
                                                    this.functions.getClients(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                await this.promisedSetState({
                                                    loading_clients_search: true,
                                                    client_search: value
                                                });
                                                setTimeout(async () => {
                                                    if (value === this.state.client_search) {
                                                        await this.promisedSetState({
                                                            client_page: 1
                                                        });
                                                        this.functions.getClients(false, false, true, value);
                                                    }
                                                }, 400);
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                this.state.open.clients = false;
                                                this.state.open.information = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.selected_client.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {/* FULL BORDER */}
                    {
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* SET INFROMATION */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CubeIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Order informaton</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Fill in data below</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.name !== "" &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            this.state.open.information = !this.state.open.information;
                                            this.setState({ open: this.state.open })
                                        }}
                                    >
                                        {
                                            !this.state.open.information &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.open.information &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.information}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className={"col-span-12"}>
                                                <InputTailwind
                                                    label={"Name"}
                                                    value={this.state.name}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            name: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"Start date"}
                                                        value={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            let time = moment(this.state.startDate).format("HH:mm");
                                                            let new_date = value + " " + time;
                                                            await this.promisedSetState({
                                                                startDate: moment(new_date)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-3">
                                                    <InputTimepickerTailwind
                                                        label={"Start time"}
                                                        value={moment(this.state.startDate).format("HH:mm")}
                                                        onChange={async (value) => {
                                                            let date = moment(this.state.startDate).format("YYYY-MM-DD");
                                                            let new_date = date + " " + value;
                                                            await this.promisedSetState({
                                                                startDate: moment(new_date)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                                <div className={(this.state.endDate ? "col-span-3" : "col-span-6")}>
                                                    <InputDatepickerTailwind
                                                        label={"End date"}
                                                        disabled={!this.state.endDate}
                                                        labelRight={this.state.endDate ? "Disable" : "Enable"}
                                                        placeholder={!this.state.endDate ? "No end date" : ""}
                                                        value={moment(this.state.endDate).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            let time = moment(this.state.endDate).format("HH:mm");
                                                            let new_date = value + " " + time;
                                                            await this.promisedSetState({
                                                                endDate: moment(new_date)
                                                            })
                                                        }}
                                                        onLabelRightClick={async (value) => {
                                                            if (this.state.endDate) {
                                                                this.state.endDate = null;
                                                            } else {
                                                                let new_date = moment(this.state.startDate).add(1, 'days');
                                                                this.state.endDate = new_date;
                                                            }
                                                            await this.promisedSetState({
                                                                endDate: this.state.endDate
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.endDate &&
                                                    <div className="col-span-3">
                                                        <InputTimepickerTailwind
                                                            label={"End time"}
                                                            placeholder={!this.state.endDate ? "No end time" : ""}
                                                            disabled={!this.state.endDate}
                                                            value={moment(this.state.endDate).format("HH:mm")}
                                                            onChange={async (value) => {
                                                                let date = moment(this.state.endDate).format("YYYY-MM-DD");
                                                                let new_date = date + " " + value;
                                                                await this.promisedSetState({
                                                                    endDate: moment(new_date)
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className={"col-span-12"}>
                                                <div className="mb-1 text-xs font-medium text-gray-700">
                                                    Upload documents
                                                </div>
                                                <div key={this.state.theInputKeyOne || ''} className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                    {
                                                        !this.state.success &&
                                                        <input
                                                            onChange={(e) => this.functions.onSelectFile(e)}
                                                            type="file"
                                                            multiple
                                                            className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                        />
                                                    }
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a document</span>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PDF, TXT, ODT, DOC, PPT up to 100MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                this.state.open.information = false;
                                                this.state.open.budget = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* BUDGET */}
                    {
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CurrencyDollarIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Budget management (optional)</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Fill in data below</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.name !== "" &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            this.state.open.budget = !this.state.open.budget;
                                            this.setState({ open: this.state.open })
                                        }}
                                    >
                                        {
                                            !this.state.open.budget &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.open.budget &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.budget}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className={"col-span-4"}>
                                                <InputTailwind
                                                    label={"Monthly budget"}
                                                    value={this.state.total_budget}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            total_budget: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-4"}>
                                                <InputTailwind
                                                    label={"Crm ID"}
                                                    value={this.state.crmId}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            crmId: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-4"}>
                                                <InputTailwind
                                                    label={"Crm Ref"}
                                                    value={this.state.crmRef}
                                                    onChange={(value) => {
                                                        this.promisedSetState({
                                                            crmRef: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    false &&
                                    <div className='flex justify-center'>
                                        <div
                                            onClick={() => {
                                                this.state.open.budget = false;
                                                this.state.open.agents = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        false &&
                        <div className="border-b w-full mt-4 mb-4"></div>
                    }

                    {/* SELECT AGENTS */}
                    {
                        false &&
                        <>
                            <div className={"flex justify-between"}>
                                <div className='flex'>
                                    <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <UsersIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col ml-2">
                                        <div className="font-semibold text-sm">Select agents</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Select agents</div>
                                    </div>
                                </div>
                                <div className='flex'>
                                    {
                                        this.state.agents.length > 0 &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                        onClick={() => {
                                            this.state.open.agents = !this.state.open.agents;
                                            this.setState({ open: this.state.open })
                                        }}
                                    >
                                        {
                                            !this.state.open.agents &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.open.agents &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                    </div>
                                </div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.agents}
                            >
                                <div className='mb-4'>
                                    <div className='mt-4'>
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className="col-span-12">
                                                <DropdownTailwind
                                                    label={"Agents"}
                                                    small={false}
                                                    skipInternalSearch={true}
                                                    loader={this.state.loading_agents}
                                                    loadingPagination={this.state.loading_agents_pagination}
                                                    loadingSearch={this.state.loading_agents_search}
                                                    total={this.state.total}
                                                    searchInput={true}
                                                    placeholder={"..."}
                                                    pagination={this.state.total > (this.state.page * this.state.limit)}
                                                    selected={{ id: 0, name: "Select agent" }}
                                                    options={this.state.agent_options}
                                                    onChange={async (value) => {
                                                        this.state.agents.unshift({
                                                            email: value.name,
                                                            id: value.id
                                                        });
                                                        await this.promisedSetState({
                                                            client: this.state.client
                                                        });
                                                    }}
                                                    onPagination={async () => {
                                                        if (!this.state.loading_agents_pagination) {
                                                            await this.promisedSetState({
                                                                page: this.state.page + 1
                                                            });
                                                            this.functions.getAgents(false, true, false);
                                                        }
                                                    }}
                                                    onSearch={async (value) => {
                                                        if (value && value === "") {
                                                            await this.promisedSetState({
                                                                search: "",
                                                                page: 1
                                                            });
                                                            this.functions.getAgents(false, false, true);
                                                        } else {
                                                            if (this.state.throttling.current) {
                                                                //SKIP
                                                            } else {
                                                                if (value && value === "") {
                                                                    await this.promisedSetState({
                                                                        search: value,
                                                                        page: 1
                                                                    });
                                                                    this.functions.getAgents(false, false, true);
                                                                } else {
                                                                    this.state.throttling.current = true;
                                                                    await this.promisedSetState({
                                                                        search: value,
                                                                        throttling: this.state.throttling
                                                                    });
                                                                    setTimeout(async () => {
                                                                        this.state.throttling.current = false;
                                                                        await this.promisedSetState({
                                                                            throttling: this.state.throttling
                                                                        });
                                                                        await this.promisedSetState({
                                                                            page: 1
                                                                        });
                                                                        this.functions.getAgents(false, false, true);
                                                                    }, 300);
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {
                                                Array.isArray(this.state.agents) && this.state.agents.length > 0 &&
                                                <div className="col-span-12 -mt-2">
                                                    {
                                                        this.state.agents.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className={"bg-indigo-100 text-indigo-700 mt-2 flex flex-1 rounded-md items-center p-2 text-sm font-medium "}>
                                                                        <div className="flex flex-1 ml-2">{item.email}</div>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.agents = this.state.agents.filter((agent) => {
                                                                                    return agent.id !== item.id;
                                                                                });
                                                                                this.setState({
                                                                                    client: this.state.client
                                                                                });
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-indigo-500  bg-indigo-200 text-indigo-500 flex-shrink-0 ml-1 h-8 w-8 rounded-md inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove agent</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* NEXT */}
                                {
                                    false &&
                                    <div className='flex justify-center mb-4'>
                                        <div
                                            onClick={() => {
                                                this.state.open.agents = false;
                                                this.state.open.agents = true;
                                                this.setState({ open: this.state.open })
                                            }}
                                            className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.name !== "" ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                            Next step
                                        </div>
                                    </div>
                                }

                            </SlideDown>
                        </>
                    }

                    {
                        this.state.error &&
                        <div className={"w-full mt-4"}>
                            <div className="bg-red-100 p-4 rounded-md">
                                <div className="text-sm text-red-500">
                                    {this.state.error}
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }
}

export default CreateOrder;