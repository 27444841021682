import React, { Component } from 'react';
import './style.css';

class Callback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            success_modal: false,
            error_modal: false,
            error_message: "",
        }
    };

    componentDidMount() {
        if (window.location.href.indexOf("code") !== -1 && window.location.hostname.indexOf("localhost") === -1 && window.location.hostname.indexOf("dev") === -1) {
            window.close();
        } else if (window.location.href.indexOf("adform") !== -1) {
            window.close();
        }
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="">

            </div>
        )
    }
}

export default Callback;
