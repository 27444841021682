import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import {calls} from "./calls";
import AccordionFixed from "../accordion-fixed";
import CampaignStatusLevels from "../campaign-status-levels";
import Loader from "../loader";
import CampaignOverview from "../campaign-overview";
import CampaignDonuts from "../campaign-donuts";
import {campaignRegister} from "../../services/campaignRegister";

var moment = require('moment');

class CampaignEditSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            errors: {},
            loading: false
        };
    };

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            errors: nextProps.errors
        }, () => {
            //console.log(this.props.errors)
        });
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            errors: this.props.errors
        })
    }

    init = {};

    functions = {
        pause: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    loader_title: 'Pausing campaign',
                    warning_modal: false
                }, () => {
                    calls.pauseCampaign(this.state.campaign.id).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            this.props.history.push('/user/campaigns/paused');
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        activate: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: false,
                    loader_title: 'Activating campaign',
                    warning_modal: false
                }, () => {
                    calls.activateCampaign(this.state.campaign.id).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            this.props.history.push('/user/campaigns/active');
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        remove: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    loader_title: 'Removing campaign',
                    warning_modal: false
                }, () => {
                    calls.removeCampaign(this.props.campaign.id).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            this.props.history.push('/user/campaigns/active');
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        edit: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    loading_text: 'Loading draft',
                    warning_modal: false
                }, () => {
                    calls.getDraft(this.props.campaign.id).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            if (response.data.onboarding) {
                                response.data.onboarding.extra = {
                                    campaign_id: this.props.campaign.id,
                                    remove_after: true
                                };
                                campaignRegister.set(response.data.onboarding);
                                this.props.history.push('/user/campaigns/new');
                            }
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        }
    };

    renders = {
        status: () => {
            try {
                return this.state.campaign.status
            } catch (e) {
                return false
            }
        },
        errorLevel: () => {
            try {
                let level = 1;
                for (let i = 0; i < this.state.errors.errors.length; i++) {
                    for (let m = 0; m < this.state.errors.errors[i].errors.length; m++) {
                        if (this.state.errors.errors[i].errors[m].level === 2) {
                            level = 2;
                            break;
                        }
                    }
                    if (level === 2) {
                        break;
                    }
                }
                return level;
            } catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div className="">
                <div className="">
                    {
                        this.state.loading &&
                        <Loader
                            title={this.state.loader_title}
                        />
                    }
                    <SweetAlert
                        show={this.state.warning_modal}
                        title={this.state.warning_title}
                        type="warning"
                        text={this.state.warning_subtitle}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            if (this.state.warning_type === 'remove') {
                                this.functions.remove();
                            } else if (this.state.warning_type === 'pause') {
                                this.functions.pause();
                            } else if (this.state.warning_type === 'activate') {
                                this.functions.activate();
                            } else if (this.state.warning_type === 'edit') {
                                this.functions.edit();
                            }
                        }}
                        onCancel={() => {
                            this.setState({warning_modal: false});
                        }}
                    />
                    <div className="flex flex-row justify-end items-center mt-6 md:mt-8 py-4 mb-6">
                        {
                            this.renders.status() === 'error' &&
                            <button onClick={() => {
                                this.setState({
                                    warning_modal: true,
                                    warning_type: 'edit',
                                    warning_title: 'Edit campaign',
                                    warning_subtitle: 'Are you sure ?'
                                })
                            }}
                                    className="ml-2 mr-2 bg-purple-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-purple-800">
                                Resolve errors
                            </button>
                        }
                        {
                            false &&
                            this.renders.status() === 'error' && this.renders.errorLevel() === 1 &&
                            <button onClick={() => {
                                this.setState({
                                    warning_modal: true,
                                    warning_type: 'activate',
                                    warning_title: 'Activate campaign',
                                    warning_subtitle: 'Are you sure ?'
                                })
                            }}
                                    className="ml-2 mr-2 bg-green-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-green-800">
                                Publish anyway
                            </button>
                        }
                        <button onClick={() => {
                            this.setState({
                                warning_modal: true,
                                warning_type: 'remove',
                                warning_title: 'Remove campaign',
                                warning_subtitle: 'Are you sure ?'
                            })
                        }}
                                className="ml-2 mr-2 bg-red-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-red-800">
                            {false &&
                            <FeatherIcon className="stroke-current mr-1" size={16} icon="trash"/>
                            }
                            <span>Delete campaign</span>
                        </button>
                        {
                            this.renders.status() !== 'pending' && this.renders.status() !== 'paused' && this.renders.status() !== 'error' &&
                            <button onClick={() => {
                                this.setState({
                                    warning_modal: true,
                                    warning_type: 'pause',
                                    warning_title: 'Pause campaign',
                                    warning_subtitle: 'Are you sure ?'
                                })
                            }}
                                    className="mr-2 bg-orange-100 text-orange-500 flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-orange-500 hover:text-white">
                                <FeatherIcon className="stroke-current mr-1" size={16} icon="pause-circle"/> <span>Pause campaign</span>
                            </button>
                        }
                        {
                            (this.renders.status() === 'pending' || this.renders.status() === 'paused') &&
                            <button onClick={() => {
                                this.setState({
                                    warning_modal: true,
                                    warning_type: 'activate',
                                    warning_title: 'Activate campaign',
                                    warning_subtitle: 'Are you sure ?'
                                })
                            }}
                                    className="bg-green-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-green-800">
                                <FeatherIcon className="stroke-current mr-1" size={16} icon="fast-forward"/> <span>Activate campaign</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CampaignEditSettings;
