import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from 'react-spinners';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import cx from "classnames";
import CampaignDonuts2 from "../components/campaign-donuts-2";
import { Bar, Line } from "react-chartjs-2";
import ReportTable from '../modules/report-components/table';
import { apiRegister } from '../services/apiRegister';
import ReportComponentsDonuts from '../modules/report-components/donuts';
import { Menu, Transition } from '@headlessui/react';
import { AdjustmentsIcon, CalendarIcon, ChartBarIcon, ChevronDownIcon, DownloadIcon, PresentationChartBarIcon, PresentationChartLineIcon } from '@heroicons/react/outline';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';

var moment = require('moment');

class StandardReportPublic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            tabs: [],
            temp: {},
            dateSpan: { id: 1, name: "Default", value: "default" },
            selected_tab: {},
            loading: true,
            loading_partial: true,
            chart_type: { name: "Bar chart", value: "bar", icon: PresentationChartBarIcon }
        };
    }

    componentWillMount() {
        this.functions.report();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {
        report: async () => {
            await this.promisedSetState({
                loading: true
            });
            this.calls.getReport({ report_id: this.props.match.params.id }).then(async (response) => {
                response.data.tabs[0].name = response.data.tabs[0].title;
                await this.promisedSetState({
                    tabs: response.data.tabs,
                    logo: response.data.logo,
                    name: response.data.name,
                    description: response.data.description,
                    selected_tab: response.data.tabs[0],
                    temp: response.data.tabs[0],
                    loading: false
                });
                this.functions.partialReport(this.state.selected_tab, true);
            }, (error) => {
                this.promisedSetState({
                    loading: false
                })
            });
        },
        partialReport: async (tab, init) => {
            tab.name = tab.title;
            await this.promisedSetState({
                loading: init,
                loading_partial: true,
                temp: tab
            });
            this.calls.getPartialReport({ report_id: this.props.match.params.id, tab_id: tab.id }).then((response) => {
                this.state.tabs = this.state.tabs.map((item) => {
                    if (item.id == response.data.tabs[0].id) {
                        item = response.data.tabs[0];
                        this.state.selected_tab = response.data.tabs[0];
                    }
                    return item;
                });
                this.setState({
                    loading: false,
                    tabs: this.state.tabs,
                    selected_tab: this.state.selected_tab,
                    temp: response.data.tabs[0],
                    loading_partial: false
                })
            }, (error) => {
                this.setState({
                    loading: false,
                    loading_partial: false
                })
            });
        },
        updateMainChart: (tab, column) => {
            this.setState({
                loading_chart: true
            }, () => {
                this.calls.getPartialReport({ report_id: this.props.match.params.id, tab_id: tab.id, column: column }).then((response) => {
                    this.state.tabs = this.state.tabs.map((item) => {
                        if (item.id == response.data.tabs[0].id) {
                            if (!item.cells) {
                                item = response.data.tabs[0].cells;
                            } else {
                                item.cells = { ...item.cells, ...response.data.tabs[0].cells };
                            }
                            this.state.selected_tab = item;
                        }
                        return item;
                    });
                    this.state.selected_tab.column = column;
                    this.setState({
                        tabs: this.state.tabs,
                        selected_tab: this.state.selected_tab,
                        loading_chart: false
                    })
                }, (error) => {
                    this.setState({
                        loading_chart: false
                    })
                });
            })
        },
        downloadCsv: () => {
            try {
                let filename = this.state.selected_tab.title + ".csv";
                const replacer = (key, value) => value === null ? "" : value;
                let header = this.state.selected_tab.columns.map((metric) => {
                    return metric;
                });
                let csv = this.state.selected_tab.cells["main_table"].map(row => header.map(fieldName =>
                    JSON.stringify(row[fieldName], replacer)).join(','))
                csv.unshift(header.join(','))
                csv = csv.join('\r\n')
                var link = document.createElement('a');
                link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        template: () => {
            try {
                return Object.keys(this.state.selected_tab.data_sources.campaigns) < 1 && Object.keys(this.state.selected_tab.data_sources.adgroups) < 1 && Object.keys(this.state.selected_tab.data_sources.ads) < 1
            } catch (error) {
                return true;
            }
        }
    };

    calls = {
        getReport: () => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getPublicReport?report=" + this.props.match.params.id;
            return apiRegister.call(options, url);
        },
        getPartialReport: (data) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/publicPartialReport?dateSpan=" + this.state.dateSpan.value + "&tab_id=" + data.tab_id + "&report_id=" + data.report_id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex flex-1 flex-col">

                {/*MENU*/}
                {
                    !this.state.loading &&
                    <div className="sm:sticky sm:top-0 sm:z-50">
                        <div className="md:h-16 py-4 md:py-0 px-4 border-b flex flex-col md:flex-row md:items-center bg-white">
                            <div className="flex flex-row items-center mb-4 md:mb-0">
                                <div className="mr-4">
                                    <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                        <img src={this.state.logo} className="bg-cover" />
                                    </div>
                                </div>
                                <div className="font-semibold text-sm">
                                    {this.state.name}
                                </div>
                            </div>
                            <div className="flex flex-1">

                            </div>
                            <div className="hidden md:block relative">
                                {
                                    this.state.loading_partial &&
                                    <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                }
                                <Menu as="div" className="relative">
                                    <div>
                                        <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                            {
                                                this.state.chart_type.value == 'bar' &&
                                                <PresentationChartBarIcon className="w-5 h-5" />
                                            }
                                            {
                                                this.state.chart_type.value == 'line' &&
                                                <PresentationChartLineIcon className="w-5 h-5" />
                                            }
                                            <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                {this.state.chart_type ? this.state.chart_type.name : ""}
                                            </span>
                                            <ChevronDownIcon
                                                className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {
                                                [{ name: "Bar chart", value: "bar", icon: PresentationChartBarIcon }, { name: "Line chart", value: "line", icon: PresentationChartLineIcon }].map((item) => {
                                                    return (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <div
                                                                    onClick={async (e) => {
                                                                        this.setState({
                                                                            chart_type: item
                                                                        });
                                                                        /*
                                                                        if (this.state.selected_tab && this.state.selected_tab.cells && this.state.selected_tab.cells["main_chart_" + item]) {
                                                                            this.state.selected_tab.column = item;
                                                                            this.setState({
                                                                                selected_tab: this.state.selected_tab
                                                                            })
                                                                        } else {
                                                                            this.functions.updateMainChart(this.state.selected_tab, item);
                                                                        }
                                                                        */
                                                                    }}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-3 text-sm cursor-pointer'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="hidden md:block relative ml-3">
                                {
                                    this.state.loading_partial &&
                                    <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                }
                                <Menu as="div" className="relative">
                                    <div>
                                        <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                            <AdjustmentsIcon className="w-5 h-5" />
                                            <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                {this.state.selected_tab ? this.state.selected_tab.column : ""}
                                            </span>
                                            <ChevronDownIcon
                                                className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {
                                                (Array.isArray(this.state.selected_tab.columns) ?
                                                    this.state.selected_tab.columns.filter((item) => {
                                                        return item !== "channel" && item !== "level" && item !== "breakdown" && item !== "breakdown_value" && item !== "name" && item !== "id"
                                                    }) : []).map((item) => {
                                                        return (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={async (e) => {
                                                                            if (this.state.selected_tab && this.state.selected_tab.cells && this.state.selected_tab.cells["main_chart_" + item]) {
                                                                                this.state.selected_tab.column = item;
                                                                                this.setState({
                                                                                    selected_tab: this.state.selected_tab
                                                                                })
                                                                            } else {
                                                                                this.functions.updateMainChart(this.state.selected_tab, item);
                                                                            }
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-3 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {item}
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    })
                                            }
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="items-center ml-3 hidden md:flex relative">
                                {
                                    this.state.loading_partial &&
                                    <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                }
                                <Menu as="div" className="relative">
                                    <div>
                                        <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                            <CalendarIcon className="w-5 h-5" />
                                            {
                                                this.state.dateSpan.value === 'default' &&
                                                this.state.selected_tab.daterange.value !== "lifetime" &&
                                                <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                    {moment(this.state.selected_tab.daterange.start_date).format("YYYY.MM.DD")}{" - "}
                                                    {moment(this.state.selected_tab.daterange.end_date).format("YYYY.MM.DD")}
                                                </span>
                                            }
                                            {
                                                this.state.dateSpan.value !== 'default' &&
                                                <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                    {this.state.dateSpan.name}
                                                </span>
                                            }
                                            <ChevronDownIcon
                                                className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {
                                                [
                                                    { id: 1, name: "Default", value: "default" },
                                                    { id: 2, name: "Last 7 days", value: "last_7" },
                                                    { id: 3, name: "Last 14 days", value: "last_14" },
                                                    { id: 4, name: "Last 20 days", value: "last_20" },
                                                    { id: 5, name: "Last 30 days", value: "last_30" }
                                                ].map((item) => {
                                                    return (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <div
                                                                    onClick={async (e) => {
                                                                        this.state.tabs = this.state.tabs.map((tab) => {
                                                                            tab.fetched = false;
                                                                            return tab;
                                                                        });
                                                                        await this.promisedSetState({
                                                                            tabs: this.state.tabs,
                                                                            dateSpan: item
                                                                        });
                                                                        this.functions.partialReport(this.state.selected_tab);
                                                                    }}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-3 text-sm cursor-pointer'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="ml-3 hidden md:block relative">
                                {
                                    this.state.loading_partial &&
                                    <div className="bg-white cursor-not-allowed z-50 absolute opacity-50 left-0 top-0 right-0 bottom-0 rounded-md"></div>
                                }
                                <div onClick={() => {
                                    this.functions.downloadCsv();
                                }} className="max-w-xs h-10 cursor-pointer px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                    <DownloadIcon className="w-5 h-5" />
                                    <span className="ml-3 text-gray-700 text-sm font-medium block">
                                        Download CSV
                                    </span>
                                </div>
                            </div>

                            {/*CHART MOBILE DROPDOWN*/}
                            <div className="flex-1 flex-col flex md:hidden">
                                <DropdownTailwind
                                    label={"Dates"}
                                    small={false}
                                    skipInternalSearch={false}
                                    searchInput={false}
                                    locked={this.state.loading_partial}
                                    placeholder={"..."}
                                    selected={this.state.dateSpan.value === "default" ? { name: moment(this.state.selected_tab.daterange.start_date).format("YYYY.MM.DD") + " - " + moment(this.state.selected_tab.daterange.end_date).format("YYYY.MM.DD"), value: 'default' } : this.state.dateSpan}
                                    options={[
                                        { id: 1, name: "Default", value: "default" },
                                        { id: 2, name: "Last 7 days", value: "last_7" },
                                        { id: 3, name: "Last 14 days", value: "last_14" },
                                        { id: 4, name: "Last 20 days", value: "last_20" },
                                        { id: 5, name: "Last 30 days", value: "last_30" }
                                    ]}
                                    onChange={async (item) => {
                                        this.state.tabs = this.state.tabs.map((tab) => {
                                            tab.fetched = false;
                                            return tab;
                                        });
                                        await this.promisedSetState({
                                            tabs: this.state.tabs,
                                            dateSpan: item
                                        });
                                        this.functions.partialReport(this.state.selected_tab);
                                    }}
                                />
                            </div>

                            {/*TABS MOBILE DROPDOWN*/}
                            <div className="flex-1 flex-col mt-4 flex md:hidden">
                                <DropdownTailwind
                                    label={"Tab"}
                                    small={false}
                                    skipInternalSearch={false}
                                    searchInput={false}
                                    locked={this.state.loading_partial}
                                    placeholder={"..."}
                                    selected={this.state.selected_tab}
                                    options={this.state.tabs.map((item) => { item.name = item.title; return item })}
                                    onChange={async (item) => {
                                        if (!this.state.loading_partial && this.state.selected_tab.id !== item.id) {
                                            if (!item.fetched) {
                                                this.functions.partialReport(item);
                                            } else {
                                                item.name = item.title;
                                                this.setState({
                                                    selected_tab: item,
                                                    temp: item
                                                });
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        {/*TABS*/}
                        <div className="sm:h-16 hidden md:flex py-4 sm:py-0 px-4 border-b flex-col sm:flex-row sm:items-center bg-white">
                            <div className="flex flex-1 justify-center">
                                {
                                    Array.isArray(this.state.tabs) &&
                                    this.state.tabs.map((item, index) => {
                                        return (
                                            <div
                                                className={cx("cursor-pointer text-white bg-purple-100 relative max-w-xs flex items-center rounded-md text-sm", {
                                                    ["bg-opacity-100 text-purple-500"]: item.id === this.state.temp.id,
                                                    ["border-transparent text-opacity-50 text-gray-900 bg-opacity-0 hover:text-purple-500 hover:text-opacity-100"]: item.id !== this.state.temp.id,
                                                    ["ml-4"]: index !== 0
                                                })}>
                                                <div onClick={(e) => {
                                                    if (!this.state.loading_partial && this.state.selected_tab.id !== item.id) {
                                                        if (!item.fetched) {
                                                            this.functions.partialReport(item);
                                                        } else {
                                                            this.setState({
                                                                selected_tab: item,
                                                                temp: item
                                                            });
                                                        }
                                                    }
                                                }} className="font-bold p-2 px-3 text-sm" title={item.title}>
                                                    {item.title}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                }

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*VIEW*/}
                {
                    !this.state.loading &&
                    <div className="flex flex-1 flex-col p-4 pt-0">
                        <div className="w-full flex relative flex-col overflow-hidden">

                            <div className={(this.state.loading_partial ? "overflow-hidden h-160" : "") + " flex items-center relative justify-center"}>

                                {/*LOADING PARTIAL*/}
                                {
                                    this.state.loading_partial &&
                                    <div className="absolute left-0 z-50 rounded-b-md bg-white right-0 top-0 bottom-0 flex justify-center items-center flex-col flex-1 w-full">
                                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        <div className="font-semibold mt-3">Loading ...</div>
                                    </div>
                                }

                                <div className="w-full" style={{ maxWidth: "1200px" }}>

                                    {/*DONUTS*/}
                                    {
                                        !this.state.loading_partial &&
                                        this.state.selected_tab.cells &&
                                        this.state.selected_tab && this.state.selected_tab.piechart && Object.keys(this.state.selected_tab.piechart).length > 0 &&
                                        <div className="mt-4">
                                            <DonutsNew
                                                tab={this.state.selected_tab}
                                            />
                                        </div>
                                    }

                                    {/*CHART MOBILE DROPDOWN*/}
                                    <div style={{ maxWidth: "1200px" }} className="flex-1 flex-flex mb-4 flex md:hidden">
                                        <div className="flex flex-1 pr-1 flex-col">
                                            <DropdownTailwind
                                                label={"Chart type"}
                                                small={false}
                                                skipInternalSearch={false}
                                                searchInput={false}
                                                locked={this.state.loading_partial}
                                                placeholder={"..."}
                                                selected={this.state.chart_type}
                                                options={[{ name: "Bar chart", value: "bar", icon: PresentationChartBarIcon }, { name: "Line chart", value: "line", icon: PresentationChartLineIcon }]}
                                                onChange={async (item) => {
                                                    this.setState({
                                                        chart_type: item
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-1 pl-1 flex-col">
                                            <DropdownTailwind
                                                label={"Chart data"}
                                                small={false}
                                                skipInternalSearch={false}
                                                searchInput={false}
                                                locked={this.state.loading_partial}
                                                placeholder={"..."}
                                                selected={{ value: this.state.selected_tab.column, name: this.state.selected_tab.column }}
                                                options={(Array.isArray(this.state.selected_tab.columns) ?
                                                    this.state.selected_tab.columns.filter((item) => {
                                                        return item !== "channel" && item !== "level" && item !== "breakdown" && item !== "breakdown_value" && item !== "name" && item !== "id"
                                                    }) : []).map((item) => {
                                                        return { name: item, value: item }
                                                    })}
                                                onChange={async (item) => {
                                                    if (this.state.selected_tab && this.state.selected_tab.cells && this.state.selected_tab.cells["main_chart_" + item.value]) {
                                                        this.state.selected_tab.column = item.value;
                                                        this.setState({
                                                            selected_tab: this.state.selected_tab
                                                        })
                                                    } else {
                                                        this.functions.updateMainChart(this.state.selected_tab, item.value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        !(!this.state.loading_partial &&
                                            this.state.selected_tab.cells &&
                                            this.state.selected_tab && this.state.selected_tab.piechart && Object.keys(this.state.selected_tab.piechart).length > 0) &&
                                        <div className="w-full h-5"></div>
                                    }

                                    {/*CHART*/}
                                    {
                                        !this.state.loading_partial &&
                                        this.state.selected_tab.cells &&
                                        <div style={{ maxWidth: "1200px" }} className="mb-4">
                                            <LineChartNew
                                                chartType={this.state.chart_type}
                                                loading={this.state.loading_chart}
                                                tab={this.state.selected_tab}
                                                column={this.state.selected_tab.column}
                                            />
                                        </div>
                                    }

                                    {/*TABLE*/}
                                    {
                                        !this.state.loading_partial &&
                                        this.state.selected_tab.cells && this.state.selected_tab.cells["main_table"] &&
                                        Array.isArray(this.state.selected_tab.columns) &&
                                        <div style={{ maxWidth: "1200px" }} className="pb-4">
                                            <TableNew
                                                tab={this.state.selected_tab}
                                            />
                                        </div>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                }

            </div>
        )
    }
}

class DonutsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: null
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tab && nextProps.tab.id !== this.state.tab.id) {
            this.setState({
                tab: nextProps.tab
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.tab &&
                    <CampaignDonuts2
                        tab={this.state.tab}
                        border={false}
                        properties={this.state.tab.piechart ? Object.keys(this.state.tab.piechart).map((item) => {
                            return this.state.tab.piechart[item].value
                        }) : []}
                        showchannels={false}
                    />
                }
            </>
        )
    }

}

class TableNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_limit: 50,
            page: 1,
            tab: {}
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tab && nextProps.tab.id !== this.state.tab.id) {
            this.setState({
                tab: nextProps.tab
            }, () => {
                console.log(this.state.tab);
            })
        }
    }

    functions = {
        downloadCsv: () => {
            let filename = this.state.tab.title + ".csv";
            const replacer = (key, value) => value === null ? "" : value;
            let header = this.state.tab.columns.map((metric) => {
                return metric;
            });
            let csv = this.state.tab.cells["main_table"].map(row => header.map(fieldName =>
                JSON.stringify(row[fieldName], replacer)).join(','))
            csv.unshift(header.join(','))
            csv = csv.join('\r\n')
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {

        let search_ad = (item) => {
            return (
                <td className={"flex justify-start"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxxs text-purple-500 flex-wrap">
                            {Array.isArray(item.headlines) && item.headlines.filter((item, index) => { return index < 3 }).join(" | ") + (item.headlines.length > 3 ? (" +" + (item.headlines.length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxxs truncate">
                                {Array.isArray(item.descriptions) ? item.descriptions.join(". ") : ""}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        return (
            <>
                <div className="shadow-lg rounded-lg w-full overflow-hidden">
                    <div style={{ backgroundColor: 'rgb(252,252,252)' }} className="overflow-scroll">
                        <table style={{ backgroundColor: 'rgb(252,252,252)' }} className="min-w-full divide-y divide-gray-200 table-fixed"
                            id="reportstable">
                            <thead className="bg-gray-200">
                                <tr>
                                    {
                                        Array.isArray(this.state.tab.columns) &&
                                        this.state.tab.columns.map((column, index) => {
                                            return (
                                                <th key={index} onClick={() => {
                                                    this.setState({
                                                        sort: column,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium text-gray-700 uppercase " + (this.state.sort === column ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                        <div>
                                                            {column}
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className=" divide-y divide-gray-200 relative">
                                {
                                    this.state.tab.cells &&
                                    Array.isArray(this.state.tab.cells["main_table"]) &&
                                    this.state.tab.cells["main_table"].sort((a, b) => {
                                        if (this.state.order === 'asc') {
                                            return (a[this.state.sort] > b[this.state.sort]) ? -1 : 1
                                        } else {
                                            return (a[this.state.sort] > b[this.state.sort]) ? 1 : -1
                                        }
                                    }).filter((item, index) => {
                                        if (this.state.tab.cells["main_table"].length > this.state.page_limit) {
                                            return (this.state.page * this.state.page_limit) > index
                                        } else {
                                            return true;
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    <tr>
                                                        {
                                                            Array.isArray(this.state.tab.columns) &&
                                                            this.state.tab.columns.map((column, i) => {
                                                                if (column == "channel") {
                                                                    return (
                                                                        <td key={i} className={(this.state.sort === column ? " border-l border-r " : "") + " text-sm px-6 py-4  text-gray-900 truncate"}>
                                                                            <div className="flex flex-row justify-start align-middle items-center">
                                                                                {
                                                                                    item.channel === "google" &&
                                                                                    <div
                                                                                        className="bg-google-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img className="w-3" src={require('../assets/images/google_icon.svg')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "google_shopping" &&
                                                                                    <div
                                                                                        className="bg-googleshopping-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img style={{ width: "0.65rem" }} src={require('../assets/images/google-shopping.png')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "linkedin" &&
                                                                                    <div
                                                                                        className="bg-linkedin-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img className="w-3"
                                                                                            src={require('../assets/images/linkedin_icon.svg')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "facebook" &&
                                                                                    <div
                                                                                        className="bg-facebook-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img style={{ width: '0.4rem' }}
                                                                                            src={require('../assets/images/facebook_icon.svg')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "google_analytics" &&
                                                                                    <div
                                                                                        className="bg-googleanalytics-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img className="w-3"
                                                                                            src={require('../assets/images/ga_icon.png')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "adform" &&
                                                                                    <div
                                                                                        className="bg-adform-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img className="w-3"
                                                                                            src={require('../assets/images/adform_icon.png')} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.channel === "twitter" &&
                                                                                    <div
                                                                                        className="bg-twitter-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                        <img className="w-3"
                                                                                            src={require('../assets/images/twitter_icon.svg')} />
                                                                                    </div>
                                                                                }
                                                                                <div className="capitalize ml-1">
                                                                                    {item.channel === 'google_shopping' ? "Google Shopping" : (item.channel === 'google_analytics' ? "Google Analytics" : item.channel)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } else if (column == "preview" && item.level == "ad" && (item.channel === "google" || item.channel === "bing")) {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            {search_ad(item)}
                                                                        </td>
                                                                    )
                                                                } else if (column == "preview" && item.level == "ad" && item.channel !== "google" && item.channel !== "bing") {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            <div className="flex flex-row items-center">
                                                                                <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                    <div className="h-32 w-32 rounded-md justify-center flex overflow-hidden bg-gray-50">
                                                                                        {
                                                                                            item.video_url &&
                                                                                            <video controls={true} loop={true} autoPlay={false} muted={true}
                                                                                                style={{ width: '100%', height: '100%' }}>
                                                                                                <source src={item.video_url} type="video/mp4" />
                                                                                            </video>
                                                                                        }
                                                                                        {
                                                                                            item.image_url && !item.video_url &&
                                                                                            <img src={item.image_url} style={{ height: '100%' }} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            <div className="flex flex-row items-center">
                                                                                <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                    {item[column]}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tr>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody >
                        </table >
                    </div >
                </div >
                {
                    this.state.tab.cells &&
                    Array.isArray(this.state.tab.cells["main_table"]) &&
                    this.state.tab.cells["main_table"].length > (this.state.page * this.state.page_limit) &&
                    <div className="mt-5 mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div onClick={(e) => {
                            this.setState({
                                page: this.state.page + 1
                            })
                        }} className="btn btn-primary btn-lg max-w-md">
                            <div>
                                Load more ({this.state.page * this.state.page_limit}/{this.state.tab.cells["main_table"].length})
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                page_limit: 10000000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
            </>
        )
    }

}

class LineChartNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            column: "",
            tab: null,
            bar: {
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 10,
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10
                        }
                    }]
                }
            },
            line: {
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 10,
                            //suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            //max: 5
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10
                        }
                    }]
                }
            },
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab,
            column: this.props.column,
            chartType: this.props.chartType
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            column: nextProps.column,
            loading: nextProps.loading,
            chartType: nextProps.chartType,
            tab: null
        }, () => {
            this.setState({
                tab: nextProps.tab
            })
        });
    }

    render() {
        return (
            <div style={{ backgroundColor: "rgb(252, 252, 252)" }} className="rounded-lg shadow-lg p-4 relative">
                <div style={{ height: "250px" }}>
                    {
                        this.state.tab && this.state.tab.cells && this.state.tab.cells["main_chart_" + this.state.column] && !this.state.loading &&
                        <div style={{ height: "250px" }}>
                            {
                                this.state.chartType && this.state.chartType.value === 'line' &&
                                <Line
                                    options={this.state.line}
                                    data={this.state.tab.cells["main_chart_" + this.state.column]}
                                />
                            }
                            {
                                this.state.chartType && this.state.chartType.value === 'bar' &&
                                <Bar
                                    options={this.state.bar}
                                    data={this.state.tab.cells["main_chart_" + this.state.column]}
                                />
                            }
                        </div>
                    }
                </div>
                {
                    this.state.loading &&
                    <div className="top-0 left-0 right-0 bottom-0 absolute bg-white rounded">
                        <div className="flex h-full justify-center items-center flex-1">
                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default StandardReportPublic;
