import React, {Component} from 'react';
import './style.css';
import Account from "../../components/account";
import Settings from "../../components/settings/index";
import Accordion from "../../components/accordion";
import UserPaymentMethod from "../../components/user-payment-method";
import AccordionStatic from "../../components/accordion-static";

class UserProfileSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div className="Profile">
                <div className="Profile-Inner">
                    <AccordionStatic title={"My settings"}>
                        <Settings/>
                    </AccordionStatic>
                </div>
            </div>
        )
    }
}

export default UserProfileSettings;
