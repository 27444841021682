import moment from 'moment';
import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import { ArrowLeftIcon, ArrowNarrowDownIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, CogIcon, CursorClickIcon, InformationCircleIcon, PlusIcon, TrashIcon, UploadIcon, XIcon } from '@heroicons/react/outline'
import { userRegister } from '../services/userRegister';
import currencies from '../assets/json/currencies.json';
import pako from 'pako';

class CreateCustomDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading_file: false,
            loading: false,
            name: "",
            json: null,
            second_json: null,
            json_html: null,
            currency: {},
            client: {},
            clients: [],
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            throttling: {},
            parser: {},
            parsers: [],
            parser_search: '',
            parser_limit: 10,
            parser_page: 1,
            parser_total: 0,
            rows_filter: "all_rows",
            rows_pagination: 10,
            rows_page: 1,
            currencies: currencies,
            step: { id: 1, step: "one" },
            steps: [
                { id: 1, step: "one", type: "File information", icon: CogIcon },
                { id: 2, step: "two", type: "Upload .csv file", icon: UploadIcon },
                { id: 3, step: "three", type: "New columns", icon: CursorClickIcon },
                { id: 4, step: "four", type: "Overview", icon: ClipboardCheckIcon, type_value: "date" }
            ],
            others: [],
            pagination_rows: []
        };
    }

    componentDidMount() {
        this.functions.user();
        if (this.props.datasource && this.props.datasource.id) {
            this.functions.getDatasource(true);
        } else {
            this.props.onStep(this.state.step, true);
            this.functions.getClients(true);
            this.functions.getParsers(true);
        }
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        create: async () => {
            this.props.onCreate();
            setTimeout(async () => {
                try {
                    const processedJson = this.state.json.reduce((acc, item) => {
                        //PARSE JSON
                        const {
                            row_index,
                            index,
                            clean_merge,
                            edit,
                            ...rest
                        } = item;

                        //REMOVE EMPTY ROWS
                        if (!this.renders.empty(rest)) {
                            acc.push(rest);
                        }

                        return acc;
                    }, []);

                    const name = this.state.name;
                    const currency = this.state.currency && this.state.currency.name ? this.state.currency.name : "none";
                    const parser_id = this.state.parser.id;
                    const client_id = this.state.client.id;
                    const total_rows = processedJson.length;

                    const jsonString = JSON.stringify(processedJson);
                    const stringAsBytes = new TextEncoder().encode(jsonString);
                    const compressedData = pako.gzip(stringAsBytes);
                    const file = new Blob([compressedData], { type: 'application/gzip' });

                    const formData = new FormData();
                    formData.append('file', file);

                    await this.calls.create(formData, name, currency, parser_id, client_id, total_rows);
                    this.props.onCreated();
                } catch (err) {
                    console.log(err)
                    this.setState({
                        error: err.body.message
                    });
                    this.props.onError();
                }
            }, 50);
        },
        getDatasource: async () => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.get();
                await this.promisedSetState({
                    json: Array.isArray(response.data.json) ? response.data.json : [],
                    parser: response.data.parser,
                    client: response.data.client,
                    name: response.data.name
                });
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        getParsers: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_parsers_pagination: paginaton,
                loading_parsers: init
            });
            try {
                let response = await this.calls.getParsers();
                if (!search_value || (search_value && search_value == this.state.parsers_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            parsers: response.data.map((item) => { item.image = item.logo; return item }),
                            parsers_total: response.meta.total
                        });
                    } else {
                        this.state.parsers = this.state.parsers.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            parsers: this.state.parsers,
                            parsers_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_parsers_search: false,
                loading_parsers_pagination: false,
                loading_parsers: false
            });
        },
        setNext: async () => {
            if (this.state.step.step == "one") {
                await this.promisedSetState({
                    step: { id: 2, step: "two" }
                });
                this.props.onStep(this.state.step, !(Array.isArray(this.state.json) && this.state.json.length > 0));
            } else if (this.state.step.step == "two") {
                await this.promisedSetState({
                    step: { id: 3, step: "three" }
                });
                this.props.onStep(this.state.step, !(this.state.others.filter((item) => { return !item.type }).length < 1));
            } else if (this.state.step.step == "three") {
                await this.promisedSetState({
                    step: { id: 4, step: "four" }
                });
                if (this.state.others.length > 0) {
                    this.state.others.map((item) => {
                        if (item.type == 'breakdown') {
                            if (this.state.parser.breakdowns.filter((breakdown) => { return breakdown.value == item.column }).length < 1) {
                                this.state.parser.breakdowns.push({ name: item.column, value: item.column });
                            }
                        } else if (item.type == 'metric') {
                            if (this.state.parser.metrics.filter((metric) => { return metric.value == item.column }).length < 1) {
                                this.state.parser.metrics.push({ name: item.column, value: item.column });
                            }
                        }
                    });
                }
                await this.promisedSetState({
                    parser: this.state.parser
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "four") {
                await this.functions.create();
            }
        },
        setBack: async () => {
            if (this.state.step.step == "two") {
                await this.promisedSetState({
                    step: { id: 1, step: "one" }
                });
                this.props.onStep(this.state.step, !(this.state.client.id && this.state.parser.id));
            } else if (this.state.step.step == "three") {
                await this.promisedSetState({
                    step: { id: 2, step: "two" }
                });
                this.props.onStep(this.state.step, !(Array.isArray(this.state.json) && this.state.json.length > 0));
            } else if (this.state.step.step == "four") {
                await this.promisedSetState({
                    step: { id: 3, step: "three" }
                });
                this.props.onStep(this.state.step, !(this.state.others.filter((item) => { return !item.type }).length < 1));
            }
        }
    };

    calls = {
        get: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getCustomDatasource?id=" + this.props.datasource.id;
            return apiRegister.call(options, url);
        },
        create: (data, name, currency, parser_id, client_id, total_rows) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + `/v3/adcredo/createCustomDatasource?name=${name}&currency=${currency}&parser_id=${parser_id}&client_id=${client_id}&total_rows=${total_rows}`;
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        getParsers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomPlatforms?limit=" + this.state.parser_limit + "&page=" + this.state.parser_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.parser_search !== "" ? ("&search=" + this.state.parser_search) : "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        empty: (item) => {
            let empty = true;
            for (let key in item) {
                if (item[key] && item[key] !== "" && key !== "index" && key !== "row_index" && key !== "edit" && key !== "clean_merge" && key !== "merge_row") {
                    empty = false;
                }
            }
            return empty;
        },
        date: (item) => {
            let date = null;
            if (this.state.parser && this.state.parser.id) {
                try {
                    let date_parser = moment(item[this.state.parser.date]);
                    date_parser.isValid();
                    if (date_parser.isValid()) {
                        date = date_parser.format("YYYY-MM-DD");
                    } else {
                        date = null;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            return date;
        },
        identifiers: () => {
            let identifiers = [];
            if (this.state.parser && this.state.parser.id) {
                //identifiers.push({ name: "Account ID", value: this.state.parser.accountID });
                //identifiers.push({ name: "Account Name", value: this.state.parser.accountName });
                identifiers.push({ name: "Campaign ID", value: this.state.parser.campaignID });
                identifiers.push({ name: "Campaign Name", value: this.state.parser.campaignName });
                identifiers.push({ name: "Adgroup ID", value: this.state.parser.adgroupID });
                identifiers.push({ name: "Adgroup Name", value: this.state.parser.adgroupName });
                identifiers.push({ name: "Ad ID", value: this.state.parser.adID });
                identifiers.push({ name: "Ad Name", value: this.state.parser.adName });
            }
            return identifiers;
        },
        metrics: () => {
            let metrics = [];
            if (this.state.parser && this.state.parser.id) {
                metrics = Array.isArray(this.state.parser.metrics) ? this.state.parser.metrics : [];
            }
            return metrics;
        },
        breakdowns: () => {
            let breakdowns = [];
            if (this.state.parser && this.state.parser.id) {
                breakdowns = Array.isArray(this.state.parser.breakdowns) ? this.state.parser.breakdowns : [];
            }
            return breakdowns;
        },
        others: () => {
            let others = [];
            let unique = {};
            if (Array.isArray(this.state.json) && this.state.json.length > 0 && this.state.parser.id) {
                let first_item = this.state.json[0];
                Object.keys(first_item).map((key) => {
                    let exist = false;
                    if (this.renders.identifiers().filter((identifier) => { return key == identifier.value }).length > 0) {
                        exist = true;
                    }
                    if (this.renders.metrics().filter((metric) => { return key == metric.value }).length > 0) {
                        exist = true;
                    }
                    if (this.renders.breakdowns().filter((breakdown) => { return key == breakdown.value }).length > 0) {
                        exist = true;
                    }
                    if (key == this.state.parser.date) {
                        exist = true;
                    }
                    if (!exist && key !== "row_index" && key !== "index" && key !== "edit" && key !== "clean_merge" && key !== "merge_row") {
                        others.push(key);
                    }
                });
            }
            others.map((item) => {
                unique[item] = true;
            });
            return Object.keys(unique);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    handleFileUpload = (event) => {
        if (!this.state.loading_file) {
            this.setState({
                loading_file: true
            });
            const file = event.target.files[0];

            let max_size = this.state.user && this.state.user.custom_upload && this.state.user.custom_upload.file_size ? this.state.user.custom_upload.file_size : 10;
            max_size = max_size * 1024 * 1024;

            if (file) {
                if (file.size >= max_size) {
                    this.setState({
                        size_error: true,
                        loading_file: false
                    });
                } else {
                    this.setState({
                        size_error: false
                    });
                    const reader = new FileReader();
                    reader.onload = async (e) => {

                        const csvText = e.target.result;
                        const data = this.csvToJson(csvText);
                        if (this.props.datasource && this.props.datasource.id) {
                            this.mergeJson(data.second_json);
                        } else {

                            let name = file.name;
                            try {
                                name = name.replace(".csv", "");
                            } catch (error) { }

                            await this.promisedSetState({
                                json: data.json.map(item => {
                                    item[this.state.parser.date] = this.renders.date(item);
                                    return item;
                                }),
                                rows: JSON.parse(JSON.stringify(data.json)).splice(0, this.state.rows_pagination),
                                name: name
                            });

                            //CHECK IF NO MATCH COLUMN
                            let others = this.renders.others();
                            if (Array.isArray(others) && others.length > 0) {
                                await this.promisedSetState({
                                    step: { id: 3, step: "three" },
                                    others: others.map((item) => { return { column: item, type: null } }),
                                    loading_file: false
                                });
                                this.props.onStep(this.state.step, true);
                            } else {
                                await this.promisedSetState({
                                    step: { id: 4, step: "four" },
                                    loading_file: false
                                });
                                this.props.onStep(this.state.step, false);
                            }

                        }
                    };
                    reader.readAsText(file);
                }
            }
        }
    };

    csvToJson = (csvText) => {

        const lines = csvText.split('\n');

        //LOCATE WHERE HEADERS STARTS
        let header_index = null;
        let highest_score = { index: 0, score: 0 };
        lines.map((line, index) => {
            let headers = Splitter(line);
            let score = 0;
            headers.map((header) => {
                header = header.replaceAll(/["\r]/g, "");
                if (header == this.state.parser.date && (typeof this.state.parser.date == "string" && this.state.parser.date !== "")) {
                    score = score + 1;
                }
                if (header == this.state.parser.campaignID && (typeof this.state.parser.campaignID == "string" && this.state.parser.campaignID !== "")) {
                    score = score + 1;
                }
                if (header == this.state.parser.adgroupID && (typeof this.state.parser.adgroupID == "string" && this.state.parser.adgroupID !== "")) {
                    score = score + 1;
                }
                if (header == this.state.parser.adID && (typeof this.state.parser.adID == "string" && this.state.parser.adID !== "")) {
                    score = score + 1;
                }
            });
            if (score > highest_score.score) {
                highest_score.score = score;
                highest_score.index = index;
            }
        });
        header_index = highest_score.index;
        //LOCATE WHERE HEADERS STARTS (END)

        const headers = Splitter(lines[header_index]);

        let result_json = [];
        for (let i = 1; i < lines.length; i++) {
            if (i > header_index) {
                const obj_json = {};
                const currentLine = Splitter(lines[i]);
                for (let j = 0; j < headers.length; j++) {
                    let currentHeader = headers[j].replaceAll(/["\r]/g, "");
                    if (currentHeader) {
                        if (currentLine[j]) {
                            obj_json[currentHeader] = currentLine[j].replaceAll(/["\r]/g, "");
                        } else {
                            obj_json[currentHeader] = "";
                        }
                    }
                }
                obj_json.row_index = i - 1;
                result_json.push(obj_json);
            }
        }
        if (this.props.datasource && this.props.datasource.id) {
            return { second_json: result_json }
        } else {
            return { json: result_json }
        }

        function Splitter(text) {
            var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
            var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            // Return NULL if input string is not well formed CSV string.
            if (!re_valid.test(text)) return null;
            var a = [];                     // Initialize array to receive values.
            text.replace(re_value, // "Walk" the string using replace with callback.
                function (m0, m1, m2, m3) {
                    // Remove backslash from \' in single quoted values.
                    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                    // Remove backslash from \" in double quoted values.
                    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                    else if (m3 !== undefined) a.push(m3);
                    return ''; // Return empty string.
                });
            // Handle special case of empty last value.
            if (/,\s*$/.test(text)) a.push('');
            return a;
        };

    };

    render() {
        return (
            <div className="h-full flex flex-col relative">
                {
                    this.state.loading &&
                    <div className="h-full absolute z-10 flex justify-center bg-white items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                    </div>
                }
                <div className="col-span-12 h-full grid-cols-12 gap-4 grid mb-4">
                    <div className={"col-span-6 flex flex-col h-full border-1.5 rounded-md"}>
                        {
                            this.state.step &&
                            this.state.step.step == "four" &&
                            <div class="w-full p-4 border-b-1.5">
                                <div className="text-xs ml-1 font-medium text-gray-700 flex flex-row items-center">
                                    <div className='flex flex-row'><div className='h-4 w-4 bg-orange-500 rounded-md'></div><div className='ml-1.5'>Date</div></div>
                                    <div className='ml-4 flex flex-row'><div className='h-4 w-4 bg-black rounded-md'></div><div className='ml-1.5'>Identifiers</div></div>
                                    <div className='ml-4 flex flex-row'><div className='h-4 w-4 bg-green-500 rounded-md'></div><div className='ml-1.5'>Metrics</div></div>
                                    <div className='ml-4 flex flex-row'><div className='h-4 w-4 bg-blue-500 rounded-md'></div><div className='ml-1.5'>Breakdowns</div></div>
                                    <div className='ml-4 flex flex-row'><div className='h-4 w-4 bg-red-500 rounded-md'></div><div className='ml-1.5'>Ignore</div></div>
                                </div>
                            </div>
                        }
                        <div className="w-full h-full flex-col flex overflow-hidden">
                            <div className="flex w-full flex-col relative flex-1 overflow-hidden">
                                <div className="absolute h-full w-full">
                                    <div className="overflow-scroll h-full">
                                        {
                                            this.state.step &&
                                            this.state.step.step == "one" &&
                                            <div class="h-full w-full flex justify-center items-center">
                                                <div className="relative w-68">
                                                    <div className={"col-span-12 relative"}>
                                                        <div style={{ right: "-115px", top: "35px" }} className="absolute">
                                                            <div className="items-center justify-center flex-row flex text-sm font-medium">
                                                                <div className="mr-2">
                                                                    <ArrowLeftIcon className="w-5" />
                                                                </div>
                                                                Start here
                                                            </div>
                                                        </div>
                                                        <DropdownTailwind
                                                            label={"Select client"}
                                                            small={false}
                                                            locked={this.props.datasource && this.props.datasource.id}
                                                            skipInternalSearch={true}
                                                            loader={this.state.loading_clients}
                                                            loadingPagination={this.state.loading_clients_pagination}
                                                            loadingSearch={this.state.loading_clients_search}
                                                            total={this.state.clients_total}
                                                            searchInput={true}
                                                            placeholder={"Search ..."}
                                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                                            selected={this.state.client.id !== 1 ? this.state.client : { id: 1, name: "Select client" }}
                                                            options={this.state.clients}
                                                            onChange={async (value) => {
                                                                await this.promisedSetState({
                                                                    client: value,
                                                                });
                                                            }}
                                                            onPagination={async () => {
                                                                if (!this.state.loading_clients_pagination) {
                                                                    await this.promisedSetState({
                                                                        client_page: this.state.client_page + 1
                                                                    });
                                                                    this.functions.getClients(false, true, false);
                                                                }
                                                            }}
                                                            onSearch={async (value) => {
                                                                await this.promisedSetState({
                                                                    loading_clients_search: true,
                                                                    client_search: value
                                                                });
                                                                setTimeout(async () => {
                                                                    if (value === this.state.client_search) {
                                                                        await this.promisedSetState({
                                                                            client_page: 1
                                                                        });
                                                                        this.functions.getClients(false, false, true, value);
                                                                    }
                                                                }, 400);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"col-span-12 mt-6"}>
                                                        <DropdownTailwind
                                                            label={"Select parser"}
                                                            small={false}
                                                            locked={this.props.datasource && this.props.datasource.id}
                                                            skipInternalSearch={true}
                                                            disabled={!this.state.client.id}
                                                            loader={this.state.loading_parsers}
                                                            loadingPagination={this.state.loading_parsers_pagination}
                                                            loadingSearch={this.state.loading_parsers_search}
                                                            total={this.state.parser_total}
                                                            searchInput={true}
                                                            placeholder={"Search ..."}
                                                            pagination={this.state.parser_total > (this.state.parser_page * this.state.parser_limit)}
                                                            selected={this.state.parser.id !== 1 ? this.state.parser : { id: 1, name: "Select parser" }}
                                                            options={this.state.parsers}
                                                            onChange={async (value) => {
                                                                await this.promisedSetState({
                                                                    parser: value
                                                                });
                                                            }}
                                                            onPagination={async () => {
                                                                if (!this.state.loading_parsers_pagination) {
                                                                    await this.promisedSetState({
                                                                        parser_page: this.state.parser_page + 1
                                                                    });
                                                                    this.functions.getParsers(false, true, false);
                                                                }
                                                            }}
                                                            onSearch={async (value) => {
                                                                await this.promisedSetState({
                                                                    loading_parsers_search: true,
                                                                    parser_search: value
                                                                });
                                                                setTimeout(async () => {
                                                                    if (value === this.state.parser_search) {
                                                                        await this.promisedSetState({
                                                                            parser_page: 1
                                                                        });
                                                                        this.functions.getParsers(false, false, true, value);
                                                                    }
                                                                }, 400);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={"col-span-12 mt-6"}>
                                                        <DropdownTailwind
                                                            label={"Currency"}
                                                            disabled={!this.state.parser.id}
                                                            searchInput={true}
                                                            selected={this.state.currency ? this.state.currency : { id: 1, name: "Select currency" }}
                                                            options={Array.isArray(currencies) ? currencies : []}
                                                            onChange={async (value) => {
                                                                await this.promisedSetState({
                                                                    currency: value,
                                                                    step: { id: 2, step: "two" }
                                                                });
                                                                this.props.onStep(this.state.step, true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.step &&
                                            this.state.step.step == "two" &&
                                            <div className="rounded-md absolute h-full w-full cursor-pointer flex justify-center items-center" onClick={() => document.getElementById('file').click()}>
                                                <div className="relative">
                                                    <div className="items-center animate-bounce justify-center flex-col flex text-sm font-medium">
                                                        Click here
                                                        <div className="mt-2">
                                                            <ArrowNarrowDownIcon className="w-5" />
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <input type="file" accept='.csv' className='hidden' id="file" onChange={this.handleFileUpload} />
                                                        <div className="font-medium rounded-full overflow-hidden relative inline-flex flex-row cursor-pointer py-2 px-4 bg-purple-100 text-sm text-purple-500">
                                                            {
                                                                !(Array.isArray(this.state.json) && this.state.json.length > 0) &&
                                                                <span>Upload .csv file</span>
                                                                ||
                                                                <span>Upload new file</span>
                                                            }
                                                            <UploadIcon className="w-5 ml-2" />
                                                            {
                                                                this.state.loading_file &&
                                                                <div className="absolute left-0 right-0 top-0 bottom-0 flex w-full h-full items-center bg-purple-100 justify-center">
                                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500  border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.size_error &&
                                                        <div className="text-center absolute pt-2 text-red-500 text-sm">
                                                            File is to big, max size is {this.state.user && this.state.user.custom_upload && this.state.user.custom_upload.file_size ? this.state.user.custom_upload.file_size : 10}MB
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            Array.isArray(this.state.others) &&
                                            this.state.step &&
                                            this.state.step.step == "three" &&
                                            <table>
                                                <tr className="border-b shadow-md bg-gray-50 sticky top-0 z-20">

                                                    {/*ROW NUMBER COLUMN*/}
                                                    <th className="border-r w-full text-left px-4 p-2 text-xs font-medium">
                                                        Column
                                                    </th>

                                                    {/*METRIC*/}
                                                    <th className="border-r truncate whitespace-no-wrap text-center w-33 min-h-33 px-4 p-2 text-xs cursor-pointer font-medium">
                                                        Is it metric ?
                                                    </th>

                                                    {/*BREAKDOWN*/}
                                                    <th className="border-r truncate whitespace-no-wrap text-center w-33 min-w-33 px-4 p-2 text-xs cursor-pointer font-medium">
                                                        Is it breakdown ?
                                                    </th>

                                                    {/*BREAKDOWN*/}
                                                    <th className="truncate whitespace-no-wrap text-center w-33 min-w-33 px-4 p-2 text-xs cursor-pointer font-medium">
                                                        Ignore
                                                    </th>

                                                </tr>
                                                {
                                                    Array.isArray(this.state.others) &&
                                                    this.state.others.map((item, index) => {
                                                        return (
                                                            <tr className={"border-b"}>

                                                                {/**/}
                                                                {
                                                                    !item.merge_row &&
                                                                    <th className="border-r text-left w-full px-4 p-2 text-xs font-medium">
                                                                        {item.column}
                                                                    </th>
                                                                }

                                                                {/**/}
                                                                {
                                                                    <th onClick={async () => {
                                                                        item.type = 'metric';
                                                                        await this.setState({
                                                                            others: this.state.others
                                                                        });
                                                                        this.props.onStep(this.state.step, this.state.others.filter((item) => { return !item.type }).length > 0);
                                                                    }} className="border-r cursor-pointer hover:bg-gray-100 w-33 min-w-33 px-4 p-2 text-xs font-medium">
                                                                        {
                                                                            item.type === 'metric' &&
                                                                            <div className="text-purple-500">Metric</div>
                                                                        }
                                                                    </th>
                                                                }

                                                                {/**/}
                                                                {
                                                                    <th onClick={async () => {
                                                                        item.type = 'breakdown';
                                                                        await this.setState({
                                                                            others: this.state.others
                                                                        });
                                                                        this.props.onStep(this.state.step, this.state.others.filter((item) => { return !item.type }).length > 0);
                                                                    }} className="cursor-pointer hover:bg-gray-100 w-33 min-w-33 border-r px-4 p-2 text-xs font-medium">
                                                                        {
                                                                            item.type === 'breakdown' &&
                                                                            <div className="text-purple-500">Breakdown</div>
                                                                        }
                                                                    </th>
                                                                }

                                                                {/**/}
                                                                {
                                                                    <th onClick={async () => {
                                                                        item.type = 'ignore';
                                                                        await this.setState({
                                                                            others: this.state.others
                                                                        });
                                                                        this.props.onStep(this.state.step, this.state.others.filter((item) => { return !item.type }).length > 0);
                                                                    }} className="cursor-pointer hover:bg-gray-100 w-33 min-w-33 px-4 p-2 text-xs font-medium">
                                                                        {
                                                                            item.type === 'ignore' &&
                                                                            <div className="text-purple-500">Ignore</div>
                                                                        }
                                                                    </th>
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        }
                                        {
                                            Array.isArray(this.state.json) &&
                                            this.state.step &&
                                            this.state.step.step == "four" &&
                                            <table>
                                                <tr className="border-b shadow-md bg-gray-50 sticky top-0 z-20">

                                                    {/*ROW NUMBER COLUMN*/}
                                                    <th className="border-r w-20 min-w-20 px-4 p-2 text-xs font-medium">
                                                        Row
                                                    </th>

                                                    {/*REMOVE COLUMN*/}
                                                    {
                                                        false &&
                                                        <th className="border-r px-4 p-2 text-xs font-medium">
                                                            Action
                                                        </th>
                                                    }

                                                    {/*WARNING COLUMN*/}
                                                    {
                                                        false &&
                                                        <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                            Message
                                                        </th>
                                                    }

                                                    {/*DATE COLUMN*/}
                                                    <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                        Date
                                                    </th>

                                                    {/*IDENTIFIER COLUMNS*/}
                                                    {
                                                        this.renders.identifiers().map((identifier) => {
                                                            return (
                                                                <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                                    {identifier.name}
                                                                </th>
                                                            )
                                                        })
                                                    }

                                                    {/*METRIC COLUMNS*/}
                                                    {
                                                        this.renders.metrics().map((metric) => {
                                                            return (
                                                                <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                                    {metric.name}
                                                                </th>
                                                            )
                                                        })
                                                    }

                                                    {/*BREAKDOWN COLUMNS*/}
                                                    {
                                                        this.renders.breakdowns().map((breakdown) => {
                                                            return (
                                                                <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                                    {breakdown.name}
                                                                </th>
                                                            )
                                                        })
                                                    }

                                                    {/*OTHER COLUMNS*/}
                                                    {
                                                        false &&
                                                        this.renders.others().map((other) => {
                                                            return (
                                                                <th className="border-r truncate whitespace-no-wrap text-left w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-medium">
                                                                    {other}
                                                                </th>
                                                            )
                                                        })
                                                    }

                                                </tr>
                                                {
                                                    Array.isArray(this.state.json) &&
                                                    this.state.rows.map((item, index) => {
                                                        return (
                                                            <tr className={(index !== 0 ? "border-t" : "")}>

                                                                {/*ROW NUMBER COLUMN*/}
                                                                {
                                                                    <th className="border-r w-20 min-w-20 px-4 p-2 text-xs font-medium">
                                                                        {item.row_index}
                                                                    </th>
                                                                }

                                                                {/*DATE COLUMN*/}
                                                                <td className={" border-r items-center flex flex-row text-left truncate whitespace-no-wrap w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-light"}>
                                                                    {this.renders.date(item) && <div className='h-2 mr-2 w-2 bg-orange-500 rounded-full'></div>}{this.renders.date(item) ? this.renders.date(item) : <span className="opacity-0">-</span>}
                                                                </td>

                                                                {/*IDENTIFIER COLUMNS*/}
                                                                {
                                                                    this.renders.identifiers().map((identifier) => {
                                                                        return (
                                                                            <td
                                                                                onClick={async () => {
                                                                                    item.edit = identifier.value;
                                                                                    await this.promisedSetState({
                                                                                        json: this.state.json
                                                                                    });
                                                                                    try {
                                                                                        document.getElementById(index + identifier.value).focus();
                                                                                    } catch (error) { }
                                                                                }}
                                                                                className={"border-r text-left relative truncate whitespace-no-wrap w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-light"}>
                                                                                <div className="flex flex-row items-center">
                                                                                    {item[identifier.value] && <div className='h-2 mr-2 w-2 bg-black rounded-full'></div>}
                                                                                    {item[identifier.value] ? item[identifier.value] : ""}
                                                                                </div>
                                                                                {
                                                                                    item.edit === identifier.value &&
                                                                                    <div className="absolute z-10 top-0 left-0 h-full right-0 bottom-0 items-center flex flex-row">
                                                                                        <input
                                                                                            id={index + identifier.value}
                                                                                            className={"text-sm font-light px-4 border-none w-full h-full"}
                                                                                            value={item[identifier.value]}
                                                                                            onChange={(e) => {
                                                                                                item[identifier.value] = e.target.value;
                                                                                                this.setState({
                                                                                                    json: this.state.json
                                                                                                });
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.keyCode === 13) {
                                                                                                    item.edit = null;
                                                                                                    this.setState({
                                                                                                        json: this.state.json
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            onBlur={() => {
                                                                                                item.edit = null;
                                                                                                this.setState({
                                                                                                    json: this.state.json
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                }

                                                                {/*METRIC COLUMNS*/}
                                                                {
                                                                    this.renders.metrics().map((metric) => {
                                                                        return (
                                                                            <td className="border-r text-left truncate whitespace-no-wrap w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-light">
                                                                                <div className="flex flex-row items-center">
                                                                                    {item[metric.value] && <div className='h-2 mr-2 w-2 bg-green-500 rounded-full'></div>}
                                                                                    {item[metric.value] ? item[metric.value] : ""}
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }

                                                                {/*BREAKDOWN COLUMNS*/}
                                                                {
                                                                    this.renders.breakdowns().map((breakdown) => {
                                                                        return (
                                                                            <td className="border-r text-left truncate whitespace-no-wrap w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-light">
                                                                                <div className="flex flex-row items-center">
                                                                                    {item[breakdown.value] && <div className='h-2 mr-2 w-2 bg-blue-500 rounded-full'></div>}
                                                                                    {item[breakdown.value] ? item[breakdown.value] : ""}
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }

                                                                {/*OTHER COLUMNS*/}
                                                                {
                                                                    this.renders.others().map((other) => {
                                                                        return (
                                                                            <td className="border-r text-left truncate whitespace-no-wrap w-48 min-w-48 px-4 p-2 text-xs cursor-pointer font-light">
                                                                                <div className="flex flex-row items-center">
                                                                                    {item[other] && <div className='h-2 mr-2 w-2 bg-red-500 rounded-full'></div>}
                                                                                    {item[other] ? item[other] : ""}
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                Array.isArray(this.state.json) &&
                                this.state.step &&
                                this.state.step.step == "four" &&
                                <div>
                                    <div className="w-full py-2 bg-gray-50 border-t flex justify-center items-center">
                                        <button
                                            onClick={async () => {
                                                if ((this.state.json.length > (this.state.rows_page * this.state.rows_pagination))) {
                                                    await this.setState({
                                                        rows_page: this.state.rows_page + 1,
                                                    });
                                                    console.log(this.state.rows_page);
                                                    await this.setState({
                                                        rows: JSON.parse(JSON.stringify(this.state.json)).splice(0, this.state.rows_page * this.state.rows_pagination),
                                                    });
                                                }
                                            }}
                                            className={(!(this.state.json.length > (this.state.rows_page * this.state.rows_pagination)) ? "cursor-not-allowed opacity-50" : "cursor-pointer hover:border-gray-500") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            {
                                                (this.state.json.length > (this.state.rows_page * this.state.rows_pagination)) &&
                                                <span>Load more ({this.state.rows_page * this.state.rows_pagination}/{this.state.json.length})</span>
                                                ||
                                                <span>All loaded ({this.state.json.length})</span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.step &&
                        this.state.step.step !== 'fifteen' &&
                        <div className="col-span-6 relative overflow-hidden">
                            <div className="absolute w-full h-full flex flex-col">
                                {
                                    this.state.steps.map((item) => {
                                        if (this.state.step && item.id < this.state.step.id) {
                                            item.hide = true;
                                        } else {
                                            item.hide = false;
                                        }
                                        return item;
                                    }).map((item) => {
                                        return (
                                            <div style={item.hide ? { marginTop: "-" + 88 + "px" } : {}} className={(this.state.step.step == item.step ? " bg-blue-200 " : " opacity-25 bg-blue-100 ") + " transition-all duration-500 ease-in-out rounded-md  p-4 mb-4"}>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row items-center">
                                                        <div className="bg-white rounded-full flex items-center justify-center w-10 h-10 text-blue-700">
                                                            <item.icon className="w-5" />
                                                        </div>
                                                        <div className="text-sm text-blue-700 ml-3">
                                                            <span className="font-medium">Step {item.step}:</span>
                                                            <span className="text-sm text-blue-700 ml-2">{item.type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="flex flex-1"></div>
                            </div>
                        </div>
                    }

                    {
                        false &&
                        <div className={"col-span-3"}>
                            <div className={"col-span-12 grid-cols-12 gap-4 grid mb-4"}>
                                <div className={"col-span-12 pt-5"}>
                                    <InputTailwind
                                        locked={this.props.metric}
                                        label={"File name"}
                                        value={this.state.name}
                                        onChange={async (value) => {
                                            this.setState({
                                                name: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={"col-span-12"}>
                                    <DropdownTailwind
                                        label={"Select client"}
                                        small={false}
                                        locked={this.props.datasource && this.props.datasource.id}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_clients}
                                        loadingPagination={this.state.loading_clients_pagination}
                                        loadingSearch={this.state.loading_clients_search}
                                        total={this.state.clients_total}
                                        searchInput={true}
                                        placeholder={"Search ..."}
                                        pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                        selected={this.state.client.id !== 1 ? this.state.client : { id: 1, name: "Select client" }}
                                        options={this.state.clients}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                client: value,
                                            });
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_clients_pagination) {
                                                await this.promisedSetState({
                                                    client_page: this.state.client_page + 1
                                                });
                                                this.functions.getClients(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_clients_search: true,
                                                client_search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.client_search) {
                                                    await this.promisedSetState({
                                                        client_page: 1
                                                    });
                                                    this.functions.getClients(false, false, true, value);
                                                }
                                            }, 400);
                                        }}
                                    />
                                </div>
                                {
                                    this.state.client.id &&
                                    <div className={"col-span-12"}>
                                        <DropdownTailwind
                                            label={"Select parser"}
                                            small={false}
                                            locked={this.props.datasource && this.props.datasource.id}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_parsers}
                                            loadingPagination={this.state.loading_parsers_pagination}
                                            loadingSearch={this.state.loading_parsers_search}
                                            total={this.state.parser_total}
                                            searchInput={true}
                                            placeholder={"Search ..."}
                                            pagination={this.state.parser_total > (this.state.parser_page * this.state.parser_limit)}
                                            selected={this.state.parser.id !== 1 ? this.state.parser : { id: 1, name: "Select parser" }}
                                            options={this.state.parsers}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    parser: value
                                                });
                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_parsers_pagination) {
                                                    await this.promisedSetState({
                                                        parser_page: this.state.parser_page + 1
                                                    });
                                                    this.functions.getParsers(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                await this.promisedSetState({
                                                    loading_parsers_search: true,
                                                    parser_search: value
                                                });
                                                setTimeout(async () => {
                                                    if (value === this.state.parser_search) {
                                                        await this.promisedSetState({
                                                            parser_page: 1
                                                        });
                                                        this.functions.getParsers(false, false, true, value);
                                                    }
                                                }, 400);
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            {
                                this.state.error &&
                                <div className={"w-full mb-4"}>
                                    <div className="bg-red-100 p-4 rounded-md">
                                        <div className="text-sm font-medium text-red-500">
                                            {this.state.error}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div >
            </div >
        )
    }
}

export default CreateCustomDatasource;