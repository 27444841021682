import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import {SlideDown} from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesFacebookImageMobile from "../advertising-creatives-facebook-image-mobile";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import AdvertisingCreativesAdsItemCreativeThumbnail from "../advertising-creatives-ads-item-creative-thumbnail";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import AdvertisingCreativesAdsItemCreativeCarouselUpload
    from "../advertising-creatives-ads-item-creative-carousel-upload";

class AdvertisingCreativesAdsItemCreativeCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            item: {},
            client: {},
            site: {},
            channels: {},
            call_to_actions: callToAction,
            errors: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items,
            channels: nextProps.channels,
            site: nextProps.site,
            errors: nextProps.errors?nextProps.errors:[]
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            items: this.props.items,
            client: this.props.client,
            site: this.props.site,
            channels: this.props.channels,
            errors: this.props.errors?this.props.errors:[]
        }, () => {
            if(this.state.items.filter((item) => {
                return item.selected
            }).length < 1 && this.state.items.length > 0){
                this.state.items[0].selected = true;
                this.setState({
                    items: this.state.items
                }, () => {
                    this.props.onChange(this.state.items);
                })
            }
        });
    }

    init = {};

    functions = {
        remove: (item) => {
            this.state.items = this.state.items.filter((inner_item) => {
                return inner_item.id !== item.id
            }).map((item) => {
                item.selected = false;
                return item
            });
            if(this.state.items.length > 0){
                this.state.items[0].selected = true;
            }
            this.setState({
                items: this.state.items
            }, () => {
                this.props.onChange(this.state.items);
            })
        }
    };

    validators = {};

    renders = {
        emoji: (value) => {
            try{
                const emojisArray = toArray(value);
                const newValue = emojisArray.reduce((previous, current) => {
                    if (typeof current === "string") {
                        return previous + current;
                    }
                    return previous + current.props.children;
                }, "");
                return newValue;
            }catch (e) {
                return value
            }
        },
        string: (item) => {
            try{
                return item.length
            }catch (e) {
                return 0
            }
        },
    };

    render() {

        const SortableItemTwo = SortableElement(({item}) => {
            return(
                <div style={item.error?{borderColor: '#FC5353'}:{}} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview">
                    {
                        item.image && !item.error &&
                        <img src={item.creative.url} style={{height: '100%'}}/>
                    }
                    {
                        item.video && !item.error &&
                        <video controls={false} loop={true} autoPlay={true} muted={true} style={{width: '100%', height: '100%'}}>
                            <source src={item.creative.url} type="video/mp4"/>
                        </video>
                    }
                    {
                        item.selected && !item.loading && !item.error &&
                        <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-active">
                            <FeatherIcon color={"#ffffff"} size={15} icon="check"/>
                        </div>
                    }
                    {
                        item.loading &&
                        <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        (item.index > 9 || item.error) &&
                        <div onClick={() => {
                            this.functions.remove(item)
                        }} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-overlay-error">
                            {
                                !item.error &&
                                <div>Maximum 10 slides</div>
                            }
                            {
                                item.error &&
                                <div>{item.error_message}</div>
                            }
                            <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-overlay-error-subtitle">(click to remove)</div>
                        </div>
                    }
                    {
                        !item.error && item.index <= 9 &&
                        <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-overlay">
                            <div onClick={() => {
                                if(item.creative.ratio === '1:1' && !item.loading && !item.error){
                                    this.state.items.map((inner_item) => {
                                        inner_item.selected = inner_item.id === item.id;
                                    });
                                    this.props.onChange(this.state.items);
                                }
                            }} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-overlay-inner"/>
                            {
                                this.state.items.length > 2 &&
                                <div onClick={() => {
                                    this.functions.remove(item)
                                }} className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-remove">
                                    <FeatherIcon color={"#ffffff"} size={14} icon="trash"/>
                                </div>
                            }
                        </div>
                    }
                </div>
            )
        });

        const SortableList = SortableContainer(({items}) => (
            <div className="Creative-ads-item-bottom-inner-right-item-inner-container">
                {items.map((item, index) => {
                    item.index = index;
                    if(item){
                        return (
                            <SortableItemTwo
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
                <div className="Creative-ads-item-bottom-inner-right-item-inner-container-button">
                    <FeatherIcon color={"#333333"} size={23} icon="upload"/>
                    <AdvertisingCreativesAdsItemCreativeCarouselUpload
                        items={this.state.items}
                        site={this.state.site}
                        client={this.state.client}
                        channels={this.state.channels}
                        onChange={(value) => {
                            this.setState({
                                items: value
                            }, () => {
                                this.props.onChange(this.state.items);
                            })
                        }}
                    />
                </div>
            </div>
        ));

        const onSortEnd = ({oldIndex, newIndex}) => {
            this.setState(() => ({
                array: arrayMove(this.state.items, oldIndex, newIndex),
            }), () => {
                this.state.items = this.state.array;
                this.setState({
                    items: this.state.items
                }, () => {
                    this.props.onChange(this.state.items);
                })
            });
        };

        return(
            <div style={{width: '100%', paddingLeft: '15px', paddingRight: '15px'}}>
                <div className="bg-gray-100 rounded-md p-4 mt-4">
                    <div style={{marginTop: "0px"}} className="Creative-ads-item-bottom-inner-right-item">
                        <div className="Creative-ads-item-bottom-inner-right-item-inner">
                            <div className="Creative-ads-item-bottom-inner-right-item-inner-container">
                                <SortableList
                                    items={this.state.items}
                                    onSortEnd={onSortEnd}
                                    axis="yx"
                                    distance={1}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.items.filter((item) => {
                            return item.selected
                        }).length > 0 &&
                        <div style={{marginTop: '0px'}} className="Creative-ads-item-bottom-inner-right-item">
                            <div className="Creative-ads-item-bottom-inner-right-item-inner">
                                {
                                    this.state.items.map((item, index) => {
                                        item.slide_index = index;
                                        return item;
                                    }).filter((item) => {
                                        return item.selected
                                    }).map((item) => {
                                        return(
                                            <div style={{marginTop: '0px'}} className="Creative-ads-item-bottom-inner-right-item-inner-item-bottom">
                                                {
                                                    item.video &&
                                                    <div className="text-xs font-bold mb-1 flex flex-row">
                                                        Video thumbnail
                                                        <div className="flex-row flex ml-2">
                                                            <div className={'w-5 h-5 bg-facebook-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                <img style={{width: "4px"}} src={require('../../assets/images/facebook_icon.svg')}/>
                                                            </div>
                                                            <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                <img className="w-2" src={require('../../assets/images/instagram_icon.svg')}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.video &&
                                                    <div className="Creative-ads-item-bottom-inner-right-item-inner-item-bottom-top">
                                                        <AdvertisingCreativesAdsItemCreativeThumbnail
                                                            item={item}
                                                            noTitle={true}
                                                            marginBottom={true}
                                                            site={this.state.site}
                                                            client={this.state.site}
                                                            onChange={(value) => {
                                                                item = value;
                                                                this.setState({
                                                                    items: this.state.items
                                                                }, () => {
                                                                    this.props.onChange(this.state.items);
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className="Creative-ads-item-bottom-inner-right-item-inner-item-bottom-top">
                                                    <Input
                                                        type={'text'}
                                                        title={"Headline"}
                                                        white={true}
                                                        sign={true}
                                                        tight={true}
                                                        icons={true}
                                                        channels={Object.keys(this.state.channels).filter((channel) => {
                                                            return channel !== "google"
                                                        })}
                                                        noBorder={true}
                                                        signTitle={this.renders.string(item.headline) + ' / 40'}
                                                        value={this.renders.emoji(item.headline)}
                                                        error={this.renders.string(item.headline) < 1}
                                                        disabled={item.loading}
                                                        size={(this.state.channels.facebook || this.state.channels.instagram)?'half':'full'}
                                                        maxLength={40}
                                                        placeholder={"Title"}
                                                        rightTitle={true}
                                                        rightTitleText={"Copy to all slides"}
                                                        onRightTitleClick={(value) => {
                                                            try{
                                                                this.state.items.map((slide) => {
                                                                   // slide.description = item.description;
                                                                    slide.headline = item.headline;
                                                                    //slide.website = item.website;
                                                                    //slide.call_to_action = item.call_to_action?item.call_to_action:{};
                                                                });
                                                                this.setState({
                                                                    items: this.state.items
                                                                }, () => {
                                                                    this.props.onChange(this.state.items);
                                                                });
                                                            }catch (e) {}
                                                        }}
                                                        onChange={(event) => {
                                                            item.headline = event.target.value;
                                                            this.setState({
                                                                items: this.state.items
                                                            }, () => {
                                                                this.props.onChange(this.state.items);
                                                            });
                                                        }}
                                                    />
                                                    {
                                                        (this.state.channels.facebook || this.state.channels.instagram) &&
                                                        <Input
                                                            type={'text'}
                                                            noTitle={true}
                                                            white={true}
                                                            sign={true}
                                                            tight={true}
                                                            noBorder={true}
                                                            icons={true}
                                                            title={"Description"}
                                                            channels={["facebook"]}
                                                            disabled={item.loading}
                                                            error={this.renders.string(item.description) < 1}
                                                            signTitle={this.renders.string(item.description) + ' / 25'}
                                                            value={this.renders.emoji(item.description)}
                                                            size={'half'}
                                                            maxLength={25}
                                                            placeholder={"Description"}
                                                            rightTitle={true}
                                                            rightTitleText={"Copy to all slides"}
                                                            onRightTitleClick={(value) => {
                                                                try{
                                                                    this.state.items.map((slide) => {
                                                                        slide.description = item.description;
                                                                        //slide.headline = item.headline;
                                                                        //slide.website = item.website;
                                                                        //slide.call_to_action = item.call_to_action?item.call_to_action:{};
                                                                    });
                                                                    this.setState({
                                                                        items: this.state.items
                                                                    }, () => {
                                                                        this.props.onChange(this.state.items);
                                                                    });
                                                                }catch (e) {}
                                                            }}
                                                            onChange={(event) => {
                                                                item.description = event.target.value;
                                                                this.setState({
                                                                    items: this.state.items
                                                                }, () => {
                                                                    this.props.onChange(this.state.items);
                                                                });
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                <div style={{marginTop: '10px'}} className="Creative-ads-item-bottom-inner-right-item-inner-item-bottom-top">
                                                    <Input
                                                        type={'text'}
                                                        noTitle={false}
                                                        white={true}
                                                        size={(this.state.channels.facebook )?'half':"full"}
                                                        tight={true}
                                                        noBorder={true}
                                                        title={"Website"}
                                                        error={this.renders.string(item.website) < 1}
                                                        disabled={item.loading}
                                                        value={item.website}
                                                        icons={true}
                                                        channels={Object.keys(this.state.channels).filter((channel) => {
                                                            return channel !== "google"
                                                        })}
                                                        placeholder={"Website"}
                                                        rightTitle={true}
                                                        rightTitleText={"Copy to all slides"}
                                                        onRightTitleClick={(value) => {
                                                            try{
                                                                this.state.items.map((slide) => {
                                                                    //slide.description = item.description;
                                                                    //slide.headline = item.headline;
                                                                    slide.website = item.website;
                                                                    //slide.call_to_action = item.call_to_action?item.call_to_action:{};
                                                                });
                                                                this.setState({
                                                                    items: this.state.items
                                                                }, () => {
                                                                    this.props.onChange(this.state.items);
                                                                });
                                                            }catch (e) {}
                                                        }}
                                                        onChange={(event) => {
                                                            item.website = event.target.value;
                                                            this.setState({
                                                                items: this.state.items
                                                            }, () => {
                                                                this.props.onChange(this.state.items);
                                                            });
                                                        }}
                                                    />
                                                    {
                                                        this.state.channels.facebook &&
                                                        <Dropdown
                                                            filter={false}
                                                            white={true}
                                                            size={'half'}
                                                            tiny={true}
                                                            noBorder={true}
                                                            noTitle={false}
                                                            icons={true}
                                                            channels={["facebook"]}
                                                            title={"CTA button"}
                                                            disabled={item.loading}
                                                            placeholder={"Select call to action"}
                                                            options={this.state.call_to_actions.filter((option) => {
                                                                if(item.creative.ratio === '9:16'){
                                                                    return option.vertical
                                                                }else{
                                                                    return true
                                                                }
                                                            })}
                                                            value={item.call_to_action?item.call_to_action:{}}
                                                            rightTitle={true}
                                                            rightTitleText={"Copy to all slides"}
                                                            onRightTitleClick={(value) => {
                                                                try{
                                                                    this.state.items.map((slide) => {
                                                                        //slide.description = item.description;
                                                                        //slide.headline = item.headline;
                                                                        //slide.website = item.website;
                                                                        slide.call_to_action = item.call_to_action?item.call_to_action:{};
                                                                    });
                                                                    this.setState({
                                                                        items: this.state.items
                                                                    }, () => {
                                                                        this.props.onChange(this.state.items);
                                                                    });
                                                                }catch (e) {}
                                                            }}
                                                            onChange={(value) => {
                                                                item.call_to_action = value;
                                                                this.setState({
                                                                    items: this.state.items
                                                                }, () => {
                                                                    this.props.onChange(this.state.items);
                                                                });
                                                            }}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.state.errors.filter((slide) => {
                                                        return slide.slide === item.slide_index
                                                    }).length > 0 &&
                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                        <ul className="list-disc">
                                                            {
                                                                this.state.errors.filter((slide) => {
                                                                    return slide.slide === item.slide_index
                                                                }).map((item) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold ">
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesAdsItemCreativeCarousel;
