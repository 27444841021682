import React, { Component, Fragment } from 'react';
import { CheckIcon, ExclamationIcon, ReplyIcon, XCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import languages from '../assets/json/languages.json';
import cn from "classnames";
import { adgroupGoogle } from '../validators/adgroupGoogle';
import SwitchTailwind from './switchTailwind';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class EditGoogleAdgroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            campaign: {},
            updated: {},
            client: {},
            loader: false,
            languages: languages,
            stats: [
                { id: 1, name: 'Enabled', value: "ENABLED" },
                { id: 2, name: 'Paused', value: "PAUSED" }
            ],
            search_locations: "",
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Targeting", value: "locations" },
            ],
            adgroups: [],
            tab: { id: 1, name: "Basic", value: "basic" },
            center: { lat: 40.64, lng: -73.96 },
            zoom: 6,
            place: null,
            map: { id: 1, name: "List View", value: false },
            location_type: { id: 1, name: "Included", value: true },
            targeting: {
                detailedDemographic: [],
                lifeEvent: [],
                userInterest: [],
                gender: [
                    { id: 1, name: "Female", value: "FEMALE" },
                    { id: 2, name: "Male", value: "MALE" },
                ],
                age: {
                    minAge: [18, 25, 35, 45, 55, 65],
                    maxAge: [24, 34, 44, 54, 64, 65]
                },
                parental_status: [
                    { id: 1, name: "Not a Parent", value: "NOT_A_PARENT" },
                    { id: 2, name: "Parent", value: "PARENT" },
                    { id: 3, name: "Undetermined", value: "UNDETERMINED"}
                ]
            },
            targeting_types: [
                { id: 0, name: "Select Type", value: null },
                { id: 1, name: "Affinity", value: "userInterest", type: "AFFINITY" },
                { id: 3, name: "In-market", value: "userInterest", type: "IN_MARKET" }, //yes, it is userInterests segment
            ],
            selected_type: { id: 0, name: "Select Type", value: null }
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            adgroups: this.props.adgroups
        })

        if (this.props.updated) {
            
            this.state.targeting.parental_status.map(item => {
                if (this.props.updated.targeting && this.props.updated.targeting.parental_status && this.props.updated.targeting.parental_status.map(item => item.name).includes(item.name)) {
                    item.included = true;
                }
                return item;
            })

            await this.promisedSetState({
                updated: this.props.updated
            });
        }

    }

    functions = {
        update: async () => {
            if (!this.props.wizard) {

            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        updateCampaign: async () => {

        },
        getDetailedDemographics: async () => {
            let parentsNames = [
                "Highest Level of Educational Attainment",
                "Parental Status", "Parents", "Marital Status", "Education", "Home-ownership Status"
            ]
            this.calls.getDetailedDemographics().then(async res => {
                this.state.targeting.detailedDemographic = res.data.flatMap(item => {
                    if (!parentsNames.includes(item.detailedDemographic.name)) {
                        return item.detailedDemographic.availabilities
                            .filter(availability =>
                                availability.channel.advertisingChannelType === 'DISPLAY')
                            .map(availability => ({
                                id: item.detailedDemographic.id,
                                name: item.detailedDemographic.name,
                                resourceName: item.detailedDemographic.resourceName,
                                type: 'detailedDemographic'
                            }));
                    } else {
                        return [];
                    }
                });
                await this.promisedSetState({ targeting: this.state.targeting });
            });
        },
        getLiveEvents: async () => {
            this.calls.getLiveEvents().then(async res => {
                this.state.targeting.lifeEvent = res.data.flatMap(item => ({
                    id: item.lifeEvent.id,
                    name: item.lifeEvent.name,
                    resourceName: item.lifeEvent.resourceName,
                    type: 'lifeEvent'
                })
                );
                await this.promisedSetState({ targeting: this.state.targeting });
            });
        },
        getUserInterests: async (data = null) => {
            await this.promisedSetState({ loading_targeting: true });
            try {
                let res = await this.calls.getUserInterests(data);
                if (res.data) {
                    this.state.targeting.userInterest = res.data.flatMap(item => ({
                        name: "(" +item.userInterest.taxonomyType.charAt(0).toUpperCase() + item.userInterest.taxonomyType.toLowerCase().replace("_", " ").slice(1) +") " + item.userInterest.name,
                        resourceName: item.userInterest.resourceName,
                        type: 'userInterest'
                    })
                    );
                    await this.promisedSetState({ targeting: this.state.targeting });
                }
            } catch (error) {
                
            }
            /*this.calls.getUserInterests(data).then(async res => {
                this.state.targeting.userInterest = res.data.flatMap(item => ({
                    name: item.userInterest.name,
                    resourceName: item.userInterest.resourceName,
                    type: 'userInterest'
                })
                );
                await this.promisedSetState({ targeting: this.state.targeting });
            });*/
            await this.promisedSetState({ loading_targeting: false });
        }
    };

    calls = {
        getDetailedDemographics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET');
            let url = apiRegister.url.api + "/v3/google/listDetailedDemographics?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getLiveEvents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET');
            let url = apiRegister.url.api + "/v3/google/listLiveEvents?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getUserInterests: (query) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET');
            let url = apiRegister.url.api + "/v3/google/listUserInterests?client=" + this.state.client.id + "&type="+ this.state.selected_type.type +"&query=" + (query || '');
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.campaign.status === 'UNSPECIFIED') {
                    return { id: 3, name: 'Unspecified', value: "UNSPECIFIED" }
                } else if (this.state.campaign.status === 'UNKNOWN') {
                    return { id: 4, name: 'Unknown', value: "UNKNOWN" }
                } else if (this.state.campaign.status === 'ENABLED') {
                    return { id: 1, name: 'Enabled', value: "ENABLED" }
                } else if (this.state.campaign.status === 'PAUSED') {
                    return { id: 1, name: 'Paused', value: "PAUSED" }
                } else if (this.state.campaign.status === 'REMOVED') {
                    return { id: 5, name: 'Removed', value: "REMOVED" }
                } else {
                    return { id: 1, name: 'Unknown', value: "UNKNOWN" }
                }
            } catch (error) {
                return { id: 1, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        showSelected: () => {
            return [...this.state.updated.targeting.userInterest]
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && adgroupGoogle.validate(this.state.updated).name) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id == 1 &&
                        <>
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Adgroup name"}
                                    error={adgroupGoogle.validate(this.state.updated).name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                !this.props.wizard &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"ADgroup status"}
                                        selected={this.renders.status()}
                                        options={this.state.stats}
                                        onChange={(value) => {
                                            this.state.updated.status = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }

                        </>
                    }

                    {/*Targeting*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            {true && <>
                                {
                                    this.state.updated.advertising_type === 'DISPLAY_STANDARD' &&
                                    <div className="col-span-6">
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Optimized Targeting</div>
                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5">
                                                {this.state.updated.optimizedTargetingEnabled ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={!!this.state.updated.optimizedTargetingEnabled}
                                                    onSwitch={async () => {
                                                        this.state.updated.optimizedTargetingEnabled = !this.state.updated.optimizedTargetingEnabled;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Gender"}
                                        searchInput={true}
                                        selected={this.state.updated.gender ? this.state.updated.gender : { id: 0, name: "Select gender" }}
                                        options={[
                                            { id: 1, name: "All genders", value: "ALL" },
                                            { id: 2, name: "Male only", value: "MALE" },
                                            { id: 3, name: "Female only", value: "FEMALE" }
                                        ]}
                                        onChange={async (value) => {
                                            this.state.updated.gender = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        onSearch={async (value) => {

                                        }}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Age"}
                                        searchInput={true}
                                        selected={{ id: 0, name: "Select ages" }}
                                        options={[
                                            { id: 1, name: "18 - 24", value: "AGE_RANGE_18_24", included: true },
                                            { id: 2, name: "25 - 34", value: "AGE_RANGE_25_34", included: true },
                                            { id: 3, name: "35 - 44", value: "AGE_RANGE_35_44", included: true },
                                            { id: 4, name: "45 - 54", value: "AGE_RANGE_45_54", included: true },
                                            { id: 5, name: "55 - 64", value: "AGE_RANGE_55_64", included: true },
                                            { id: 6, name: "65+", value: "AGE_RANGE_65_UP", included: true },
                                            { id: 7, name: "Undetermined", value: "AGE_RANGE_UNDETERMINED", included: true }
                                        ].filter((item) => {
                                            return (!Array.isArray(this.state.updated.ages) || (Array.isArray(this.state.updated.ages) && this.state.updated.ages.filter((inner_item) => { return inner_item.id === item.id }).length < 1))
                                        })}
                                        onChange={async (value) => {
                                            if (!Array.isArray(this.state.updated.ages)) {
                                                this.state.updated.ages = [];
                                            }
                                            this.state.updated.ages.push(value);
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        onSearch={async (value) => {

                                        }}
                                    />
                                </div>
                                {
                                    Array.isArray(this.state.updated.ages) &&
                                    this.state.updated.ages.length > 0 &&
                                    <div className="col-span-6 flex flex-col">
                                        {
                                            this.state.updated.ages.map((item, index) => {
                                                return (
                                                    <Fragment>

                                                        <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                            <div className="flex flex-1">{item.name}</div>
                                                            <button
                                                                onClick={() => {
                                                                    this.state.updated.ages = this.state.updated.ages.filter((age) => {
                                                                        return age.id !== item.id;
                                                                    })
                                                                    this.setState({
                                                                        updated: this.state.updated
                                                                    })
                                                                }}
                                                                type="button"
                                                                className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                            >
                                                                <span className="sr-only">Remove</span>
                                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    this.state.updated.advertising_type === 'DISPLAY_STANDARD' &&
                                    <>
                                        <div className="col-span-6 grid grid-cols-12">
                                            <div class="col-span-12 text-xs font-medium text-gray-700 mb-1 w-full flex cursor-pointer">Parental Status</div>
                                            {this.state.targeting.parental_status.map((content, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={async () => {
                                                            content.included = !content.included;
                                                            this.state.updated.targeting.parental_status = this.state.targeting.parental_status;
                                                            await this.promisedSetState({
                                                                targeting: this.state.targeting,
                                                                updated: this.state.updated
                                                            });
                                                            
                                                        }}
                                                        className={"col-span-12 md:col-span-4 lg:col-span-2 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{content.name}</div>
                                                        <div className="ml-auto">
                                                            <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="col-span-6">
                                            <DropdownTailwind
                                                label={"Type"}
                                                searchInput={true}
                                                selected={this.state.selected_type}
                                                options={this.state.targeting_types}
                                                onChange={async (value) => {
                                                    this.state.targeting.userInterest = [];
                                                    await this.promisedSetState({ 
                                                        selected_type: value, 
                                                        targeting: this.state.targeting
                                                    })
                                                    const types = { 'userInterest': 'getUserInterests' }
                                                    if (['userInterest'].includes(value.value) && !this.state.targeting[value.value].length) {
                                                        this.functions[types[value.value]]();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {
                                            !!this.state.selected_type.id &&
                                            <div className="col-span-6">
                                                <div className='flex'>
                                                    <div className='mx-4 self-center'>
                                                        <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                                    </div>
                                                    <div className='flex-col flex-1'>
                                                        <DropdownTailwind
                                                            ref="searchDropdown"
                                                            label={"Audience"}
                                                            searchInput={true}
                                                            loader={this.state.loading_targeting}
                                                            selected={{ name: "Search ..." }}
                                                            options={!!this.state.selected_type.id ? this.state.targeting[this.state.selected_type.value] : []}
                                                            loadingSearch={this.state.loading_targeting || false}
                                                            onChange={async (value) => {
                                                                const type = this.state.selected_type.value;
                                                                value.included = true;
                                                                if (!this.state.updated.targeting[type]) {
                                                                    this.state.updated.targeting[type] = []
                                                                }

                                                                if (!this.state.updated.targeting[type].some(item => item.resourceName === value.resourceName)) {
                                                                    this.state.updated.targeting[type].push(value)
                                                                }

                                                                await this.promisedSetState({ updated: this.state.updated })
                                                            }}
                                                            onSearch={async (value) => {
                                                                if (this.state.selected_type.value === 'userInterest') {
                                                                    this.functions.getUserInterests(value)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-span-6 grid grid-cols-12">
                                            {this.renders.showSelected().map((content, index) => {
                                                return (
                                                    <div key={index}

                                                        className={"col-span-12 md:col-span-6 lg:col-span-4 bg-indigo-100 text-indigo-700 cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">
                                                            <span className='w-full'>{content.name}</span>
                                                        </div>
                                                        <div className="ml-auto">
                                                            <div className={"bg-indigo-500 h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <XCircleIcon
                                                                    onClick={async () => {
                                                                        const find = this.state.updated.targeting[content.type].findIndex(item => item.resourceName === content.resourceName);
                                                                        if (find >= 0) {
                                                                            this.state.updated.targeting[content.type] = this.state.updated.targeting[content.type].filter(item => item.resourceName !== content.resourceName);
                                                                        }

                                                                        this.setState({
                                                                            updated: this.state.updated
                                                                        });
                                                                    }}
                                                                    className={"h-5 w-5 text-white"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                }


                            </>}
                    
                        </>
                    }

                </div>
            </>
        )
    }
}

export default EditGoogleAdgroup;
