import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from 'react-spinners';
import { calls } from './calls';

import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import './style.css';
import cx from "classnames";
import Loader from "../../components/loader";
import Input from "../../components/input";
import Switch from "react-ios-switch";
import Dropdown from "../../components/dropdown";
import ReportTable from "../../modules/report-components/table";
import TextArea from "../../components/text-area";
import ReactTooltip from "react-tooltip";
import Modal from "../../modules/modal";
import PptsGenJs from "../premium-report/report-pptsGenJs";
import { userRegister } from "../../services/userRegister";
import { Line, Bar } from "react-chartjs-2";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import ReportComponentsDonuts from '../../modules/report-components/donuts';
import ReportComponentsSlideIn from '../../modules/report-components/slide-in';
import { tokenRegister } from '../../services/tokenRegister';
import { apiRegister } from '../../services/apiRegister';
import { BookOpenIcon, CalendarIcon, CogIcon, DocumentDuplicateIcon, DocumentReportIcon, DownloadIcon, DuplicateIcon, ExternalLinkIcon, PencilAltIcon, PlusIcon, PresentationChartBarIcon, PresentationChartLineIcon, SaveIcon, XIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../../moduleFiles/topNavigationAgent';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SuccessModal from '../../moduleFiles/successModal';
import WizardModal from '../../moduleFiles/wizardModal';
import SlideoutTailwind from '../../moduleFiles/slideoutTailwind';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import ExternalReportSettings from '../../moduleFiles/externalReportSettings';
import { io } from 'socket.io-client';

var moment = require('moment');
const fuzzysort = require('fuzzysort');

//WEBSOCKET
const socket = io((window.location.hostname === 'localhost' ? "http://localhost:3000" : (window.location.hostname === 'app.adcredo.io' ? "https://api.adcredo.io" : "https://apidev.adcredo.io")), {
    autoConnect: false,
    transports: ['websocket'],
    auth: {
        token: tokenRegister.get()
    }
});
//WEBSOCKET END
class ReportStandardInternal extends Component {

    onBackButton = (event) => {
        alert('Dont use browser back.');
        window.history.pushState(null, document.title, window.location.href);
    };

    constructor(props) {
        super(props);
        this.state = {
            dataSourceColors: {},
            loading: true,
            tab: 1,
            data: {},
            metrics: {},
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            name: "",
            slideinclosed: true,
            slideinclosedMetrics: true,
            tabs: [],
            working_users_count: 0,
            premium: false,
            client: {},
            selected_tab: {},
            report_logo: { name: "No Logo", value: "none" },
            default_charts: {
                "impressions": {
                    title: "Impressions",
                    subtitle: "Show total impressions per platform",
                    icon: "pie-chart",
                    value: "impressions",
                    default: true
                },
                "click": {
                    title: "Clicks",
                    subtitle: "Show total click per platform",
                    icon: "pie-chart",
                    value: "click",
                    default: true
                },
                "ctr": {
                    title: "Avg. CTR",
                    subtitle: "Show average CTR per platform",
                    icon: "pie-chart",
                    value: "ctr",
                    default: true
                },
                "cpc": {
                    title: "Avg. CPC",
                    subtitle: "Show average CPC per platform",
                    icon: "pie-chart",
                    value: "cpc",
                    default: true
                },
                "spend": {
                    title: "Spend",
                    subtitle: "Show total spend per platform",
                    icon: "pie-chart",
                    value: "spend",
                    default: true
                }
            },
            default_metrics: [
                {
                    name: "Impressions (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        tiktok: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        twitter: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "impressions.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        snapchat: {
                            "name": "impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        bing: {
                            "name": "Impressions",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Clicks (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "inline_link_clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:adClicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        twitter: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        tiktok: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "clicks.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        snapchat: {
                            "name": "clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        bing: {
                            "name": "Clicks",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Avg. CTR (default)",
                    default: true,
                    find_average: true,
                    channels: {
                        facebook: {
                            "name": "inline_link_click_ctr",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "ctr",
                            "average": true,
                            "factor": 100,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:CTR",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "ctr.adUniqueness##all.dataSource##adform",
                            "average": false,
                            "factor": 100,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        snapchat: {
                            "name": "ctr",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        bing: {
                            "name": "Ctr",
                            "average": false,
                            "factor": 100,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Avg. CPC (default)",
                    default: true,
                    find_average: true,
                    channels: {
                        facebook: {
                            "name": "cpc",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "average_cpc",
                            "average": true,
                            "factor": 0.000001,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:CPC",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "ecpc.adUniqueness##all.costType##maxCost.dataSource##adform",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        snapchat: {
                            "name": "cpc",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        bing: {
                            "name": "AverageCpc",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                },
                {
                    name: "Spend (default)",
                    default: true,
                    find_average: false,
                    channels: {
                        facebook: {
                            "name": "spend",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google: {
                            "name": "cost_micros",
                            "average": false,
                            "factor": 0.000001,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        google_analytics: {
                            "name": "ga:adCost",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        linkedin: {
                            "name": "costInLocalCurrency",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        tiktok: {
                            "name": "spend",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        twitter: {
                            "name": "billed_charge_local_micro",
                            "average": false,
                            "factor": 0.000001,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        adform: {
                            "name": "cost.costDataSource##adform.costType##maxCost",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        snapchat: {
                            "name": "spend",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        },
                        bing: {
                            "name": "Spend",
                            "average": false,
                            "factor": 1,
                            "levels": ["campaign", "adgroup", "ad"]
                        }
                    }
                }
            ],
            settings_open: false,
            report_type: "",
            updated_settings: {},
            report_title: "",
            report_description: "",
            report_public: false,
        };
    }

    componentWillMount() {
        this.functions.getMetrics();
        this.functions.getReport(this.props.match.params.report);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', this.onBackButton);
        this.functions.user();
        //WEBSOCKET
        this.socket.init();
        window.addEventListener('beforeunload', this.stopWorking);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onBackButton);
        window.removeEventListener('beforeunload', this.stopWorking);
        this.stopWorking();
        this.socket.close();
    }

    socket = {
        init: () => {
            socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
                if (socket.active) {
                    console.log(`socket.active`);
                    // temporary failure, the socket will automatically try to reconnect
                } else {
                    console.log(`run socket.connect()`);
                    // the connection was denied by the server
                    // in that case, `socket.connect()` must be manually called in order to reconnect
                    console.log(err.message);
                    socket.connect();
                }
                /*if (!self.state.report_init) {
                    self.functions.getReport(self.props.match.params.report);
                }
                */
            });
            socket.on("error", (err) => {
                console.log(`error due to ${err}`);
            });
            socket.on('connect', () => {
                console.log(`connect`);
                socket.emit("subscribeToReport", this.props.match.params.report);
                socket.emit('startWorkingOnReport', {
                    entityId: this.props.match.params.report,
                });
            });
            socket.on("disconnect", (reason) => {
                console.log(`disconnect due to ${reason}`);
            });
            socket.on('workingUsersUpdate', (data) => {
                if (data.entityId === this.props.match.params.report) {
                    this.promisedSetState({
                        working_users_count: data.count
                    });
                }
            });
            socket.open();
        },
        close: () => {
            console.log(12);
            socket.disconnect();
            socket.off('connect_error');
            socket.off('error');
            socket.off('connect');
            socket.off('disconnect');
            socket.off('workingUsersUpdate');
        }
    };

    stopWorking = () => {
        socket.emit('stopWorkingOnReport', {
            entityId: this.props.match.params.report,
        });
    };

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
            if (this.state.user && this.state.user.userRole) {

            }
        },
        //REPORT MANAGEMENT
        getReport: async (id) => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.getReport(id);
                if (response.data.template && response.data.internal_data && Array.isArray(response.data.internal_data.tabs)) {
                    response.data.internal_data.tabs.map((item) => {
                        if (Array.isArray(item.grid_data)) {
                            item.grid_data.map((inner_item) => {
                                inner_item.data_sources = { ads: {}, adgroups: {}, campaigns: {} };
                            });
                        }
                        if (item.data_sources) {
                            item.data_sources = { ads: {}, adgroups: {}, campaigns: {} };
                        }
                    });
                }
                await this.promisedSetState({
                    client: response.data.client,
                    loading: false,
                    template: response.data.template,
                    report_name: response.data.name,
                    report_title: typeof response.data.title == "string" ? response.data.title : "",
                    report_description: typeof response.data.description == "string" ? response.data.description : "",
                    report_public: typeof response.data.public == "boolean" ? response.data.public : false,
                    tabs: response.data.internal_data.tabs,
                    report_logo: response.data.logo === "agency" ? {
                        name: "Agency logo",
                        value: "agency"
                    } : (response.data.logo === "client" ? {
                        name: "Client logo",
                        value: "client"
                    } : { name: "No Logo", value: "none" })
                });
                this.functions.initiateTabs();
            } catch (error) {
                this.promisedSetState({
                    loading: false
                })
            }
        },
        updateReport: async () => {
            if (!this.state.loading_save) {
                await this.promisedSetState({
                    loading_save: true
                });

                let tabs = this.state.tabs.map((tab) => {
                    let new_tab = {};
                    for (let key in tab) {
                        if (key !== "cells") {
                            new_tab[key] = tab[key];
                        }
                    }
                    for (let campaign in tab.data_sources.campaigns) {
                        tab.data_sources.campaigns[campaign] = {
                            channel: tab.data_sources.campaigns[campaign].channel,
                            id: campaign
                        };
                    }
                    for (let adgroup in tab.data_sources.adgroups) {
                        tab.data_sources.adgroups[adgroup] = {
                            channel: tab.data_sources.adgroups[adgroup].channel,
                            id: adgroup,
                            campaign: tab.data_sources.adgroups[adgroup].campaign
                        };
                    }
                    for (let ad in tab.data_sources.ads) {
                        tab.data_sources.ads[ad] = {
                            channel: tab.data_sources.ads[ad].channel,
                            id: ad,
                            adgroup: tab.data_sources.ads[ad].adgroup,
                            campaign: tab.data_sources.ads[ad].campaign
                        };
                    }
                    return new_tab;
                });

                let data = {
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: tabs
                    }
                };

                try {
                    await this.calls.updateReport(data);
                    await this.promisedSetState({
                        saved_success: true,
                        loading_save: false
                    });
                } catch (error) {
                    await this.promisedSetState({
                        loading_save: false
                    });
                }
            }
        },
        createReport: async () => {
            if (!this.state.loading_create) {

                this.setState({
                    loading_create: true,
                    create_report: false
                });

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    freemium: true,
                    premium: false,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: []
                    }
                };

                //MERGE ALL CAMPAIGNS
                if (Array.isArray(this.state.campaigns)) {
                    this.state.campaigns.map((campaign) => {
                        if (!data[campaign.channel]) {
                            data[campaign.channel] = [];
                        }
                        data[campaign.channel].push(campaign.id);
                    });
                }

                this.state.tabs.map((tab) => {
                    let new_tab = {};
                    for (let key in tab) {
                        if (key !== "cells") {
                            new_tab[key] = tab[key];
                        }
                    }
                    for (let campaign in tab.data_sources.campaigns) {
                        tab.data_sources.campaigns[campaign] = {
                            channel: tab.data_sources.campaigns[campaign].channel,
                            id: campaign
                        };
                    }
                    for (let adgroup in tab.data_sources.adgroups) {
                        tab.data_sources.adgroups[adgroup] = {
                            channel: tab.data_sources.adgroups[adgroup].channel,
                            id: adgroup,
                            campaign: tab.data_sources.adgroups[adgroup].campaign
                        };
                    }
                    for (let ad in tab.data_sources.ads) {
                        tab.data_sources.ads[ad] = {
                            channel: tab.data_sources.ads[ad].channel,
                            id: ad,
                            adgroup: tab.data_sources.ads[ad].adgroup,
                            campaign: tab.data_sources.ads[ad].campaign
                        };
                    }
                    data.internal_data.tabs.push(new_tab);
                });

                try {
                    await this.calls.createReport(data, this.state.client.id);
                    this.props.history.push("/v2/reports/standard");
                } catch (error) {
                    console.log(error);
                }
                this.setState({
                    loading_create: false
                });
            }
        },
        createTemplate: async () => {
            if (!this.state.loading_create) {

                this.setState({
                    loading_create: true,
                    create_template: false
                });

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    freemium: true,
                    premium: false,
                    new_template: true,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: []
                    }
                };

                //MERGE ALL CAMPAIGNS
                if (Array.isArray(this.state.campaigns)) {
                    this.state.campaigns.map((campaign) => {
                        if (!data[campaign.channel]) {
                            data[campaign.channel] = [];
                        }
                        data[campaign.channel].push(campaign.id);
                    });
                }

                this.state.tabs.map((tab) => {
                    let new_tab = {};
                    for (let key in tab) {
                        if (key !== "cells") {
                            new_tab[key] = tab[key];
                        }
                    }
                    new_tab.data_sources = {
                        campaigns: {},
                        adgroups: {},
                        ads: {}
                    };
                    data.internal_data.tabs.push(new_tab);
                });
                try {
                    await this.calls.createReport(data, this.state.client.id);
                    this.props.history.push("/v2/reports/template");
                } catch (error) {
                    console.log(error);
                }
                this.setState({
                    loading_create: false
                });
            }
        },
        updateTemplate: async () => {
            if (!this.state.loading_save) {
                await this.promisedSetState({
                    loading_save: true
                });
                let data = {
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    internal_data: {
                        tabs: []
                    }
                };
                this.state.tabs.map((tab) => {
                    let new_tab = {};
                    for (let key in tab) {
                        if (key !== "cells") {
                            new_tab[key] = tab[key];
                        }
                    }
                    new_tab.data_sources = {
                        campaigns: {},
                        adgroups: {},
                        ads: {}
                    };
                    data.internal_data.tabs.push(new_tab);
                });
                try {
                    await this.calls.updateReport(data);
                    await this.promisedSetState({
                        saved_success: true,
                        loading_save: false
                    });
                } catch (error) {
                    await this.promisedSetState({
                        loading_save: false
                    });
                }
            }
        },

        //HELPERS
        getMetrics: () => {
            this.setState({
                loading_metrics: true
            }, () => {
                calls.getMetrics().then((response) => {

                    //ADD DEFAULT METRICS
                    if (response.data && Array.isArray(response.data.custom)) {
                        response.data.custom = response.data.custom.concat(this.state.default_metrics);
                    }

                    this.setState({
                        metrics: response.data,
                        loading_metrics: false,
                        metrics_error: false
                    });

                }, (error) => {
                    this.setState({
                        loading_metrics: false,
                        metrics_error: true
                    })
                });
            })
        },
        getQuery: (q) => {
            return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [null])[1];
        },
        initiateTabs: () => {
            let new_tab = {};

            //IF NOT TABS
            if (this.state.tabs.length < 1) {
                new_tab = {
                    loading: true,
                    init: true,
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    title: !this.state.premium ? 'Campaigns' : 'Tab 1',
                    editMode: false,
                    premium: this.state.premium,
                    grid_data: [],
                    backup: '',
                    groups: [{
                        id: 1,
                        name: "",
                        rows: 6,
                        layout: [],
                        index: 0,
                        items: [],
                        coordinates: {}
                    }],
                    data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                    start_date: null,
                    end_date: null,
                    analytics_breakdown: { name: "Overview", value: "overview", parent: "Overview" },
                    channel_breakdowns: {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "twitter": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    },
                    breakdown: {
                        title: "Overview",
                        subtitle: "",
                        icon: "globe",
                        value: "overview",
                        default: true
                    },
                    metrics: {
                        custom: {}
                    },
                    piechart: this.state.default_charts,
                    daterange: {
                        title: "Last 30 days",
                        subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                        icon: "calendar",
                        value: "30_days",
                        disabled: false
                    }
                };

                if (new_tab.daterange && new_tab.daterange.value === "30_days") {
                    new_tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                    new_tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                }

                //SET DEFAULT METRICS
                this.state.default_metrics.map((item) => {
                    new_tab.metrics["custom"][item.name] = item;
                });

                this.state.tabs.push(new_tab);
            }

            //IF TABS EXIST
            else {

                //UPDATE DATERANGE
                this.state.tabs = this.state.tabs.map((tab) => {
                    if (tab.daterange && tab.daterange.value === '10_days') {
                        tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                        tab.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                        tab.daterange.subtitle = ("From " + moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD"));
                    }
                    if (tab.daterange && tab.daterange.value === '20_days') {
                        tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                        tab.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                        tab.daterange.subtitle = ("From " + moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD"));
                    }
                    if (tab.daterange && tab.daterange.value === '30_days') {
                        tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                        tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                        tab.daterange.subtitle = ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD"));
                    }
                    return tab;
                });

                //SET OLD REPORTS
                this.state.tabs = this.state.tabs.map((tab) => {

                    //TEMP FIX
                    if (tab.channel_breakdowns && !tab.channel_breakdowns.linkedin) {
                        if (tab.breakdown.value === "overview") {
                            tab.channel_breakdowns.linkedin = { "overview": { name: "Overview", value: "overview", parent: "Overview" } };
                        }
                    }

                    //TEMP FIX END
                    if (!tab.channel_breakdowns) {
                        if (tab.breakdown.value === "overview") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },

                            };
                        } else if (tab.breakdown.value === "gender") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "gender": { "gender": { name: "Gender", value: "gender", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "gender": { name: "Gender", value: "gender", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "gender": { name: "Gender", value: "gender", parent: "basic" } };
                        } else if (tab.breakdown.value === "age") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "age": { name: "Age", value: "age", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "age": { name: "Age", value: "age", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "age": { name: "Age", value: "age", parent: "Basic" } };
                        } else if (tab.breakdown.value === "daily") {
                            tab.channel_breakdowns = {};
                            tab.channel_breakdowns["google"] = { "date": { name: "Date", value: "date", parent: "Basic" } };
                            tab.channel_breakdowns["facebook"] = { "date": { name: "Date", value: "date", parent: "basic" } };
                            tab.channel_breakdowns["adform"] = { "date": { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." } };
                            tab.channel_breakdowns["google_analytics"] = { "date": { name: "Date", value: "ga:date", parent: "basic" } };
                            tab.channel_breakdowns["google_analytics_4"] = { "date": { name: "Date", value: "date", parent: "basic" } };
                            tab.channel_breakdowns["basic"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                        } else if (tab.breakdown.value === "region") {
                            tab.channel_breakdowns = {
                                "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                "basic": { "region": { name: "Region", value: "region", parent: "Basic" } },
                            };
                            tab.channel_breakdowns["google"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                            tab.channel_breakdowns["facebook"] = { "region": { name: "Region", value: "region", parent: "basic" } };
                        }
                        tab.piechart = this.state.default_charts;
                        if (!tab.metrics) {
                            tab.metrics = {
                                custom: {}
                            };
                        } else {
                            if (!tab.metrics.custom) {
                                tab.metrics.custom = {};
                            }
                        }
                        this.state.default_metrics.map((item) => {
                            tab.metrics["custom"][item.name] = item;
                        });
                    }
                    return tab;
                });

                new_tab = this.state.tabs[0];
                new_tab.loading = true;
            }

            this.setState({
                loading: false,
                selected_tab: new_tab,
                tabs: this.state.tabs,
            }, async () => {

                //GET DATASOURCE DATA FOR SELECTED TAB
                this.functions.updateSelectedTab();

            });
        },
        updateSelectedTab: () => {

            this.state.selected_tab.loading = true;

            this.setState({
                selected_tab: this.state.selected_tab
            }, async () => {

                //GET ALL CAMPAIGNS INFORMATION IF NONE
                if (this.state.campaigns.length < 1) {
                    await this.all.campaigns();
                }

                //INITIALIZE FIRST TAB WITH ALL CAMPAIGNS
                if (this.state.selected_tab.init) {
                    this.state.campaigns.map((campaign) => {
                        this.state.selected_tab.data_sources.campaigns[campaign.id] = campaign;
                    });
                    this.state.selected_tab.init = false;
                }

                //GET ALL ADGROUPS
                let adgroup_data = {};
                this.state.campaigns.map((campaign) => {
                    if (!adgroup_data[campaign.channel]) {
                        adgroup_data[campaign.channel] = [];
                    }
                    for (let adgroup in this.state.selected_tab.data_sources.adgroups) {
                        if (this.state.selected_tab.data_sources.adgroups[adgroup].campaign == campaign.id) {
                            adgroup_data[campaign.channel].push(campaign.id);
                        }
                    }
                    for (let ad in this.state.selected_tab.data_sources.ads) {
                        if (this.state.selected_tab.data_sources.ads[ad].campaign == campaign.id) {
                            adgroup_data[campaign.channel].push(campaign.id);
                        }
                    }
                });
                let adgroup_calls = Object.keys(adgroup_data).filter((channel) => {
                    return adgroup_data[channel].length > 0
                }).map((channel) => {
                    let call = {
                        channel: channel,
                        campaigns: adgroup_data[channel].filter((item, pos) => {
                            return adgroup_data[channel].indexOf(item) == pos;
                        })
                    };
                    return call;
                })

                if (adgroup_calls.length > 0) {
                    await this.all.adgroups(adgroup_calls)
                }

                //GET ALL ADS
                let ad_data = {};
                this.state.adgroups.map(async (adgroup) => {
                    adgroup = JSON.parse(JSON.stringify(adgroup));
                    for (let ad in this.state.selected_tab.data_sources.ads) {
                        if (this.state.selected_tab.data_sources.ads[ad].adgroup == adgroup.id) {
                            if (!ad_data[adgroup.channel]) {
                                ad_data[adgroup.channel] = [];
                            }
                            ad_data[adgroup.channel].push(adgroup.id);
                        }
                    }
                });
                let ad_calls = Object.keys(ad_data).filter((channel) => {
                    return ad_data[channel].length > 0
                }).map((channel) => {
                    let call = {
                        channel: channel,
                        adgroups: ad_data[channel].filter((item, pos) => {
                            return ad_data[channel].indexOf(item) == pos;
                        })
                    };
                    return call;
                })

                if (ad_calls.length > 0) {
                    await this.all.ads(ad_calls)
                }

                await this.functions.setColorsOfSelectedDatasources(this.state.selected_tab);

                if (this.state.selected_tab.data_sources && (
                    (this.state.selected_tab.data_sources.campaigns && Object.keys(this.state.selected_tab.data_sources.campaigns).length > 0) ||
                    (this.state.selected_tab.data_sources.adgroups && Object.keys(this.state.selected_tab.data_sources.adgroups).length > 0) ||
                    (this.state.selected_tab.data_sources.ads && Object.keys(this.state.selected_tab.data_sources.ads).length > 0)
                )) {

                    //SELECT DEFAULT COLUMN
                    this.state.selected_tab.column = null;

                    //PARSE COLUMNS
                    this.state.selected_tab.columns = [];
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_preview ? this.state.selected_tab.columns.concat(["preview"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_channel ? this.state.selected_tab.columns.concat(["channel"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_level ? this.state.selected_tab.columns.concat(["level"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_breakdown ? this.state.selected_tab.columns.concat(["breakdown"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_breakdown_value ? this.state.selected_tab.columns.concat(["breakdown_value"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_name ? this.state.selected_tab.columns.concat(["name"]) : this.state.selected_tab.columns;
                    this.state.selected_tab.columns = !this.state.selected_tab.remove_id ? this.state.selected_tab.columns.concat(["id"]) : this.state.selected_tab.columns;

                    if (Array.isArray(this.state.selected_tab.table_metrics_index)) {
                        this.state.selected_tab.table_metrics_index.map((item) => {
                            if (!this.state.selected_tab.column) {
                                this.state.selected_tab.column = item.name;
                            }
                            this.state.selected_tab.columns.push(item.name);
                        })
                    } else {
                        this.state.selected_tab.column = "Clicks (default)";
                        this.state.selected_tab.columns.push("Clicks (default)");
                        this.state.selected_tab.columns.push("Impressions (default)");
                        this.state.selected_tab.columns.push("Avg. CTR (default)");
                        this.state.selected_tab.columns.push("Avg. CPC (default)");
                        this.state.selected_tab.columns.push("Spend (default)");
                    }

                    let cell_calls = [];

                    //GET DONUTS DATA (CLICK)
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["chart_donut_click"])) {
                        if (this.state.selected_tab.piechart && this.state.selected_tab.piechart.click) {
                            cell_calls.push(this.functions.reportForm({
                                id: "chart_donut_click",
                                type: "chart_donut",
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: {
                                    google_analytics: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google_analytics_4: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    facebook: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    linkedin: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    adform: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    twitter: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    tiktok: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    bidtheatre: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    snapchat: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                    bing: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                },
                                metrics: {
                                    click: {
                                        "name": "Clicks (default)",
                                        "channels": {
                                            "facebook": {
                                                "name": "inline_link_clicks",
                                                "factor": 1,
                                            },
                                            "google": {
                                                "name": "clicks",
                                                "factor": 1,
                                            },
                                            "google_analytics": {
                                                "name": "ga:adClicks",
                                                "factor": 1,
                                            },
                                            "linkedin": {
                                                "name": "clicks",
                                                "factor": 1,
                                            },
                                            "tiktok": {
                                                "name": "clicks",
                                                "factor": 1,
                                            },
                                            "twitter": {
                                                "name": "clicks",
                                                "factor": 1,
                                            },
                                            "adform": {
                                                "name": "clicks.adUniqueness##all.dataSource##adform",
                                                "factor": 1,
                                            },
                                            "snapchat": {
                                                "name": "clicks",
                                                "factor": 1,
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }

                    //GET DONUTS DATA (IMPRESSIONS)
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["chart_donut_impressions"])) {
                        if (this.state.selected_tab.piechart && this.state.selected_tab.piechart.impressions) {
                            cell_calls.push(this.functions.reportForm({
                                id: "chart_donut_impressions",
                                type: "chart_donut",
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: {
                                    google_analytics: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google_analytics_4: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    facebook: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    linkedin: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    adform: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    twitter: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    tiktok: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    bidtheatre: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    snapchat: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                    bing: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                },
                                metrics: {
                                    impressions: {
                                        "name": "Impressions (default)",
                                        "channels": {
                                            "facebook": {
                                                "name": "impressions",
                                                "factor": 1,
                                            },
                                            "google": {
                                                "name": "impressions",
                                                "factor": 1,
                                            },
                                            "google_analytics": {
                                                "name": "ga:impressions",
                                                "factor": 1,
                                            },
                                            "linkedin": {
                                                "name": "impressions",
                                                "factor": 1,
                                            },
                                            "tiktok": {
                                                "name": "impressions",
                                                "factor": 1,
                                            },
                                            "twitter": {
                                                "name": "impressions",
                                                "factor": 1,
                                            },
                                            "adform": {
                                                "name": "impressions.adUniqueness##all.dataSource##adform",
                                                "factor": 1,
                                            },
                                            "snapchat": {
                                                "name": "impressions",
                                                "factor": 1,
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }

                    //GET DONUTS DATA (CTR)
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["chart_donut_ctr"])) {
                        if (this.state.selected_tab.piechart && this.state.selected_tab.piechart.ctr) {
                            cell_calls.push(this.functions.reportForm({
                                id: "chart_donut_ctr",
                                type: "chart_donut",
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: {
                                    google_analytics: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google_analytics_4: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    facebook: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    linkedin: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    adform: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    tiktok: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    twitter: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    bidtheatre: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    snapchat: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                    bing: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                },
                                metrics: {
                                    ctr: {
                                        "name": "Avg. CTR (default)",
                                        "channels": {
                                            "facebook": {
                                                "name": "inline_link_click_ctr",
                                                "factor": 1
                                            },
                                            "google": {
                                                "name": "ctr",
                                                "factor": 100
                                            },
                                            "google_analytics": {
                                                "name": "ga:CTR",
                                                "factor": 1
                                            },
                                            "adform": {
                                                "name": "ctr.adUniqueness##all.dataSource##adform",
                                                "factor": 100,
                                            },
                                            "snapchat": {
                                                "name": "ctr",
                                                "factor": 1,
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }

                    //GET DONUTS DATA (CPC)
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["chart_donut_cpc"])) {
                        if (this.state.selected_tab.piechart && this.state.selected_tab.piechart.cpc) {
                            cell_calls.push(this.functions.reportForm({
                                id: "chart_donut_cpc",
                                type: "chart_donut",
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: {
                                    google_analytics: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google_analytics_4: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    facebook: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    linkedin: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    adform: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    twitter: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    tiktok: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    bidtheatre: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    snapchat: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                    bing: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                },
                                metrics: {
                                    cpc: {
                                        "name": "Avg. CPC (default)",
                                        "channels": {
                                            "facebook": {
                                                "name": "cpc",
                                                "factor": 1,
                                            },
                                            "tiktok": {
                                                "name": "cpc",
                                                "factor": 1,
                                            },
                                            "google": {
                                                "name": "average_cpc",
                                                "factor": 0.000001,
                                            },
                                            "google_analytics": {
                                                "name": "ga:CPC",
                                                "factor": 1
                                            },
                                            "adform": {
                                                "name": "ecpc.adUniqueness##all.costType##maxCost.dataSource##adform",
                                                "factor": 1,
                                            },
                                            "snapchat": {
                                                "name": "cpc",
                                                "factor": 1,
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }

                    //GET DONUTS DATA (SPEND)
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["chart_donut_spend"])) {
                        if (this.state.selected_tab.piechart && this.state.selected_tab.piechart.spend) {
                            cell_calls.push(this.functions.reportForm({
                                id: "chart_donut_spend",
                                type: "chart_donut",
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: {
                                    google_analytics: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google_analytics_4: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    google: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    facebook: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    linkedin: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    adform: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    tiktok: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    twitter: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    bidtheatre: { "overview": { name: 'Overview', value: 'overview', parent: 'Overview' } },
                                    snapchat: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                    bing: { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                },
                                metrics: {
                                    spend: {
                                        "name": "Spend (default)",
                                        "channels": {
                                            "facebook": {
                                                "name": "spend",
                                                "factor": 1,
                                            },
                                            "google": {
                                                "name": "cost_micros",
                                                "factor": 0.000001,
                                            },
                                            "google_analytics": {
                                                "name": "ga:adCost",
                                                "factor": 1,
                                            },
                                            "linkedin": {
                                                "name": "costInLocalCurrency",
                                                "factor": 1,
                                            },
                                            "tiktok": {
                                                "name": "spend",
                                                "factor": 1,
                                            },
                                            "twitter": {
                                                "name": "billed_charge_local_micro",
                                                "factor": 0.000001,
                                            },
                                            "adform": {
                                                "name": "cost.costDataSource##adform.costType##maxCost",
                                                "factor": 1,
                                            },
                                            "snapchat": {
                                                "name": "spend",
                                                "factor": 1,
                                            }
                                        }
                                    }
                                }
                            }));
                        }
                    }

                    //GET CHART
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["main_chart"])) {
                        let metric = null;
                        let metrics = {};
                        let chart_type = (Object.keys(this.state.selected_tab.channel_breakdowns).filter((channel) => { return this.state.selected_tab.channel_breakdowns[channel].date }).length > 0) ? "chart_line" : "chart_bar";
                        if (this.state.selected_tab.column && this.state.selected_tab.metrics.custom[this.state.selected_tab.column]) {
                            metric = this.state.selected_tab.metrics.custom[this.state.selected_tab.column];
                            metrics[metric.name] = metric;
                            cell_calls.push(this.functions.reportForm({
                                id: "main_chart",
                                type: chart_type,
                                daterange: this.state.selected_tab.daterange,
                                data_sources: this.state.selected_tab.data_sources,
                                channel_breakdowns: this.state.selected_tab.channel_breakdowns,
                                metrics: metrics,
                                information: {}
                            }));
                        }
                    }

                    //GET TABLE
                    if (!this.state.selected_tab.cells || (this.state.selected_tab.cells && !this.state.selected_tab.cells["main_table"])) {
                        cell_calls.push(this.functions.reportForm({
                            id: "main_table",
                            type: "table",
                            daterange: this.state.selected_tab.daterange,
                            data_sources: this.state.selected_tab.data_sources,
                            channel_breakdowns: this.state.selected_tab.channel_breakdowns,
                            metrics: this.state.selected_tab.metrics.custom
                        }));
                    }

                    if (cell_calls.length > 0) {
                        let cell_reports = await this.calls.createInternalCellReport(this.props.match.params.report, cell_calls);
                        if (!this.state.selected_tab.cells) {
                            this.state.selected_tab.cells = {};
                        }
                        cell_reports.data.map((item) => {
                            this.state.selected_tab.cells[item.id] = item.data;
                            if (!this.state.selected_tab.errors) {
                                this.state.selected_tab.errors = {};
                            }
                            this.state.selected_tab.errors[item.id] = item.errors;
                        });
                    }

                }

                this.state.selected_tab.loading = false;

                this.setState({
                    selected_tab: this.state.selected_tab
                }, () => {
                    console.log(this.state.selected_tab);
                });

            })

        },
        updateMainChart: (column) => {

            this.state.selected_tab.column = column;
            this.state.selected_tab.loading_chart = true;

            this.setState({
                selected_tab: this.state.selected_tab
            }, async () => {

                let cell_calls = [];

                //ONLY GET FIRST METRIC
                let metric = null;
                let metrics = {};
                let chart_type = (Object.keys(this.state.selected_tab.channel_breakdowns).filter((channel) => { return this.state.selected_tab.channel_breakdowns[channel].date }).length > 0) ? "chart_line" : "chart_bar";
                if (this.state.selected_tab.column && this.state.selected_tab.metrics.custom[this.state.selected_tab.column]) {
                    metric = this.state.selected_tab.metrics.custom[this.state.selected_tab.column];
                    metrics[metric.name] = metric;
                    cell_calls.push(this.functions.reportForm({
                        id: "main_chart",
                        type: chart_type,
                        daterange: this.state.selected_tab.daterange,
                        data_sources: this.state.selected_tab.data_sources,
                        channel_breakdowns: this.state.selected_tab.channel_breakdowns,
                        metrics: metrics,
                        information: {}
                    }));
                }

                if (cell_calls.length > 0) {
                    let cell_reports = await this.calls.createInternalCellReport(this.props.match.params.report, cell_calls);
                    if (!this.state.selected_tab.cells) {
                        this.state.selected_tab.cells = {};
                    }
                    cell_reports.data.map((item) => {
                        this.state.selected_tab.cells[item.id] = item.data;
                    });
                }

                this.state.selected_tab.loading_chart = false;

                this.setState({
                    selected_tab: this.state.selected_tab
                });

            })

        },
        dataSourcesSelected: () => {
            try {
                let campaigns = Object.keys(this.state.selected_tab.data_sources.campaigns).length;
                let adgroups = Object.keys(this.state.selected_tab.data_sources.adgroups).length;
                let ads = Object.keys(this.state.selected_tab.data_sources.ads).length;
                let total = 0;
                total = total + campaigns;
                total = total + adgroups;
                total = total + ads;
                return total > 0;
            } catch (e) {
                return false
            }
        },
        lowerLevelThanCampaign: () => {
            return (this.state.selected_tab.data_sources && Object.keys(this.state.selected_tab.data_sources.ads).length > 0) ||
                (this.state.selected_tab.data_sources && Object.keys(this.state.selected_tab.data_sources.adgroups).length > 0)
        },
        reportForm: (cell_information) => {

            let cell_info = {}

            let dimensions = {};
            for (let channel in cell_information.channel_breakdowns) {
                dimensions[channel] = null;
                for (let dimension in cell_information.channel_breakdowns[channel]) {
                    dimensions[channel] = dimension;
                }
            }

            //PREPARE DATA TO FETCH REPORT FOR CELL
            cell_info = {
                id: cell_information.id,
                type: cell_information.type,
                start_date: cell_information.daterange ? cell_information.daterange.start_date : false,
                end_date: cell_information.daterange ? cell_information.daterange.end_date : false,
                dimensions: dimensions,
                campaigns: cell_information.data_sources.campaigns ? this.state.campaigns.filter((item) => { return cell_information.data_sources.campaigns[item.id] }).map((item) => {
                    return { id: item.id, name: item.name, channel: item.channel }
                }) : [],
                adgroups: cell_information.data_sources.adgroups ? this.state.adgroups.filter((item) => { return cell_information.data_sources.adgroups[item.id] }).map((item) => {
                    return { id: item.id, name: item.name, channel: item.channel, campaign: item.campaign }
                }) : [],
                ads: cell_information.data_sources.ads ? this.state.ads.filter((item) => { return cell_information.data_sources.ads[item.id] }).map((item) => {
                    return { id: item.id, name: item.name, channel: item.channel, campaign: item.campaign, adgroup: item.adgroup }
                }) : [],
                metrics: Object.keys(cell_information.metrics).map((item) => {
                    if (cell_information.metrics[item].id) {
                        return { id: cell_information.metrics[item].id, name: cell_information.metrics[item].name }
                    } else {
                        return {
                            name: cell_information.metrics[item].name,
                            metrics: cell_information.metrics[item].metrics,
                            channels: cell_information.metrics[item].channels
                        };
                    }
                }),
                information: cell_information.information ? cell_information.information : {}
            };

            if (cell_information.type && cell_information.type.indexOf("chart") !== -1) {
                cell_info.information.color = {};
                for (let level in cell_information.data_sources) {
                    for (let id in cell_information.data_sources[level]) {
                        cell_info.information.color[id] = cell_information.data_sources[level][id].hex ? cell_information.data_sources[level][id].hex : (this.state.dataSourceColors[cell_information.data_sources[level][id].channel] && this.state.dataSourceColors[cell_information.data_sources[level][id].channel][id] ? this.state.dataSourceColors[cell_information.data_sources[level][id].channel][id] : channelColor(cell_information.data_sources[level][id].channel));
                    }
                }
            }

            function channelColor(channel) {
                //WHAT COLOR FOR SNAPCHAT?
                let colors = {
                    facebook: "#3A5998",
                    bing: "#0C8484",
                    google: "#4384F4",
                    adform: "#60C1A4",
                    bidtheatre: "#f48e22",
                    linkedin: "#0D65A1",
                    tiktok: "#000000",
                    twitter: "#50ABF1",
                    google_analytics: "#e8710a",
                    google_analytics_4: "#e8710a",
                    snapchat: "#FFFC00",
                }
                return colors[channel]
            }

            return cell_info;

        },
        setColorsOfSelectedDatasources: async (tab) => {
            let self = this;
            return new Promise(function (resolve, reject) {

                let colors = {
                    facebook: "#3A5998",
                    google: "#4384F4",
                    bing: "#0C8484",
                    adform: "#60C1A4",
                    bidtheatre: "#f48e22",
                    linkedin: "#0D65A1",
                    tiktok: "#000000",
                    twitter: "#50ABF1",
                    google_analytics: "#e8710a",
                    google_analytics_4: "#e8710a",
                    snapchat: "#FFFC00",
                }

                if (tab.data_sources && tab.data_sources.campaigns) {
                    for (let key in tab.data_sources.campaigns) {
                        if (!self.state.dataSourceColors[tab.data_sources.campaigns[key].channel]) {
                            self.state.dataSourceColors[tab.data_sources.campaigns[key].channel] = {};
                        }
                        self.state.dataSourceColors[tab.data_sources.campaigns[key].channel][tab.data_sources.campaigns[key].id] = null;
                    }
                    for (let key in tab.data_sources.adgroups) {
                        if (!self.state.dataSourceColors[tab.data_sources.adgroups[key].channel]) {
                            self.state.dataSourceColors[tab.data_sources.adgroups[key].channel] = {};
                        }
                        self.state.dataSourceColors[tab.data_sources.adgroups[key].channel][tab.data_sources.adgroups[key].id] = null;
                    }
                    for (let key in tab.data_sources.ads) {
                        if (!self.state.dataSourceColors[tab.data_sources.ads[key].channel]) {
                            self.state.dataSourceColors[tab.data_sources.ads[key].channel] = {};
                        }
                        self.state.dataSourceColors[tab.data_sources.ads[key].channel][tab.data_sources.ads[key].id] = null;
                    }
                }

                for (let channel in self.state.dataSourceColors) {
                    let percentage = 0;
                    for (let id in self.state.dataSourceColors[channel]) {
                        self.state.dataSourceColors[channel][id] = shadeColor(colors[channel], percentage);
                        percentage += 30;
                    }
                }

                self.setState({
                    dataSourceColors: self.state.dataSourceColors
                }, () => {
                    resolve();
                })

                function shadeColor(color, percent) {
                    try {
                        var R = parseInt(color.substring(1, 3), 16);
                        var G = parseInt(color.substring(3, 5), 16);
                        var B = parseInt(color.substring(5, 7), 16);
                        R = parseInt(R * (100 + percent) / 100);
                        G = parseInt(G * (100 + percent) / 100);
                        B = parseInt(B * (100 + percent) / 100);
                        R = (R < 255) ? R : 255;
                        G = (G < 255) ? G : 255;
                        B = (B < 255) ? B : 255;
                        R = Math.round(R)
                        G = Math.round(G)
                        B = Math.round(B)
                        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
                        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
                        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
                        return "#" + RR + GG + BB;
                    } catch (error) {
                        return null;
                    }
                }

            })
        }

    };

    all = {
        campaigns: () => {
            let self = this;
            return new Promise(function (resolve, reject) {
                self.calls.getCampaignsInformation(
                    self.props.match.params.report
                ).then((response) => {
                    let campaigns = [];
                    for (let channel in response.data) {
                        if (Array.isArray(response.data[channel])) {
                            response.data[channel].map((object) => {
                                object.channel = channel;
                                object.type = "campaign";
                                campaigns.push(object);
                            });
                        }
                    }
                    self.setState({
                        campaigns: campaigns
                    }, () => {
                        resolve();
                    });
                }, (error) => {
                    resolve();
                });
            });
        },
        adgroups: (adgroup_calls) => {
            let self = this;
            return new Promise(function (resolve, reject) {

                //CHECK IF ADGROUPS ALREADY COLLECTED
                adgroup_calls = adgroup_calls.filter((item) => {
                    item.campaigns = item.campaigns.filter((campaign) => {
                        return self.state.adgroups.filter((adgroup) => { return campaign == adgroup.campaign }).length < 1;
                    });
                    return item.campaigns.length > 0
                });

                if (adgroup_calls.length > 0) {
                    self.setState({
                        campaigns: self.state.campaigns.map((item) => {
                            adgroup_calls.map((call) => {
                                if (call.campaigns.filter((id) => { return item.id == id }).length > 0) {
                                    item.loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {

                        let call_data = {};
                        adgroup_calls.map((item) => {
                            call_data[item.channel + "_campaigns"] = item.campaigns;
                        });

                        var query_str = "";
                        query_str = Object.keys(call_data).map(key => {
                            if (call_data[key].constructor === Array) {
                                var theArrSerialized = ''
                                for (let singleArrIndex of call_data[key]) {
                                    theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
                                }
                                return theArrSerialized
                            }
                            else {
                                return key + '=' + call_data[key] + '&'
                            }
                        }).join('');

                        self.calls.getAdgroupsInformation(
                            self.props.match.params.report,
                            {},
                            query_str
                        ).then((response) => {
                            let data = { channels: {} };
                            for (let channel in response.data) {
                                data.channels[channel] = {
                                    adgroups: response.data[channel]
                                };
                            }
                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                adgroups: self.state.adgroups.concat(Object.keys(data.channels).map((channel) => {
                                    return data.channels[channel].adgroups.filter((adgroup) => {
                                        return adgroup.status !== "REMOVED";
                                    }).map((item) => {
                                        item.campaign = item.campaign_id;
                                        return item;
                                    });
                                }).flat()),
                                campaigns: self.state.campaigns.map((campaign) => {
                                    campaign.loading = false;
                                    if (data.channels[campaign.channel]) {
                                        campaign.adgroups = data.channels[campaign.channel].adgroups.filter((adgroup) => {
                                            return adgroup.status !== "REMOVED";
                                        }).filter((adgroup) => {
                                            return adgroup.campaign == campaign.id;
                                        }).map((adgroup) => {
                                            adgroup.campaign_name = campaign.name;
                                            adgroup.type = "adgroup";
                                            return adgroup;
                                        });
                                    }
                                    return campaign;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            })
        },
        ads: (ad_calls) => {
            let self = this;
            return new Promise(function (resolve, reject) {

                //CHECK IF ADGROUPS ALREADY COLLECTED
                ad_calls = ad_calls.filter((item) => {
                    item.adgroups = item.adgroups.filter((adgroup) => {
                        return self.state.ads.filter((ad) => { return adgroup == ad.adgroup }).length < 1;
                    });
                    return item.adgroups.length > 0
                })

                if (ad_calls.length > 0) {
                    self.setState({
                        adgroups: self.state.adgroups.map((item) => {
                            ad_calls.map((call) => {
                                if (call.adgroups.filter((id) => { return item.id == id }).length > 0) {
                                    item.loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {

                        let call_data = {};
                        ad_calls.map((item) => {
                            call_data[item.channel + "_adgroups"] = item.adgroups;
                        });

                        var query_str = "";
                        query_str = Object.keys(call_data).map(key => {
                            if (call_data[key].constructor === Array) {
                                var theArrSerialized = ''
                                for (let singleArrIndex of call_data[key]) {
                                    theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
                                }
                                return theArrSerialized
                            }
                            else {
                                return key + '=' + call_data[key] + '&'
                            }
                        }).join('');

                        self.calls.getAdsInformation(
                            self.props.match.params.report,
                            {},
                            query_str
                        ).then((response) => {

                            let data = { channels: {} };
                            for (let channel in response.data) {
                                data.channels[channel] = {
                                    ads: response.data[channel]
                                };
                            }

                            Object.keys(data.channels).map((channel) => {
                                data.channels[channel].ads = data.channels[channel].ads.map((ad) => {
                                    self.state.campaigns.map((campaign) => {
                                        self.state.adgroups.map((adgroup) => {
                                            if (adgroup.campaign == campaign.id && ad.adgroup_id == adgroup.id) {
                                                ad.campaign_id = adgroup.campaign;
                                                ad.campaign = adgroup.campaign;
                                                ad.adgroup_id = adgroup.id;
                                                ad.adgroup = adgroup.id;
                                            }
                                        });
                                    });
                                    return ad;
                                })
                            });

                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                ads: self.state.ads.concat(Object.keys(data.channels).map((channel) => {
                                    return data.channels[channel].ads.filter((ad) => {
                                        return ad.status !== "REMOVED";
                                    });
                                }).flat()),
                                adgroups: self.state.adgroups.map((adgroup) => {
                                    adgroup.loading = false;
                                    if (data.channels[adgroup.channel]) {
                                        adgroup.ads = data.channels[adgroup.channel].ads.filter((ad) => {
                                            return ad.status !== "REMOVED";
                                        }).map((item) => {
                                            item.adgroup = item.adgroup_id;
                                            return item;
                                        }).filter((ad) => {
                                            return ad.adgroup == adgroup.id;
                                        }).map((ad) => {
                                            ad.adgroup_name = adgroup.name;
                                            ad.type = "ad";
                                            return ad;
                                        });
                                    }
                                    return adgroup;
                                })
                            }, () => {
                                resolve();
                            });

                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            })
        }
    };

    renders = {
        tabLoading: () => {
            let loading = false;
            try {
                this.state.campaigns.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.campaigns && item.is_loading && this.state.selected_tab.data_sources.campaigns[item.id]) {
                        loading = true;
                    }
                });
                this.state.adgroups.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.adgroups && item.is_loading && this.state.selected_tab.data_sources.adgroups[item.id]) {
                        loading = true;
                    }
                });
                this.state.ads.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.ads && item.is_loading && this.state.selected_tab.data_sources.ads[item.id]) {
                        loading = true;
                    }
                });
            } catch (e) {

            }
            return loading || (!this.state.selected_tab || (this.state.selected_tab && this.state.selected_tab.loading));
        },
        modalTitle: () => {
            let string = "";
            if (this.state.save_template) {
                string = "Create template";
            } else if (this.state.update_template) {
                string = "Update template";
            } else if (this.state.save_report) {
                string = "Create report";
            } else if (this.state.update_report) {
                string = "Update report";
            }
            return string;
        },
        template: () => {
            try {
                return Object.keys(this.state.selected_tab.data_sources.campaigns) < 1 && Object.keys(this.state.selected_tab.data_sources.adgroups) < 1 && Object.keys(this.state.selected_tab.data_sources.ads) < 1
            } catch (error) {
                return true;
            }
        },
        pendingData: () => {
            let pending = false;
            try {
                for (let key in this.state.selected_tab.errors) {
                    if (Array.isArray(this.state.selected_tab.errors[key])) {
                        this.state.selected_tab.errors[key].map((item) => {
                            if (typeof item === "string" && item == "Data is pending, refresh in a couple of minutes") {
                                pending = true;
                            } else if (item.message == "Data is pending, refresh in a couple of minutes") {
                                pending = true;
                            }
                        });
                    }
                }
            } catch (error) { }
            return pending;
        }
    };

    calls = {
        getReport: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getReport?report=" + id;
            return apiRegister.call(options, url);
        },
        createReport: (data, client) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + '/v3/adcredo/createReportStandard?client=' + client;
            return apiRegister.call(options, url);
        },
        updateReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateReportStandard?report=" + data.id;
            return apiRegister.call(options, url);
        },
        createInternalCellReport: (report, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportHandlerStandard?report=" + report
            return apiRegister.call(options, url);
        },
        getAdsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdsData?report=" + report + "&information=true&" + query_str;
            return apiRegister.call(options, url);
        },
        getAdgroupsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?report=" + report + "&information=true&" + query_str;
            return apiRegister.call(options, url);
        },
        getCampaignsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?report=" + report + "&information=true&" + query_str;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ tabs }) => ({
            tabs: arrayMove(tabs, oldIndex, newIndex),
        }));
    };

    render() {
        const SortableList = SortableContainer(({ items }) => (
            <div className="flex flex-1 flex-row justify-start">
                {items.map((item, index) => (
                    <SortableItem
                        key={`item-${item.id}`}
                        index={index}
                        item={item}
                    />
                ))}
            </div>
        ));

        const SortableItem = SortableElement(({ index, item }) => (
            <div
                style={item.id === this.state.selected_tab.id ? {borderBottomColor: "rgb(249, 250, 251)"} : {}}
                className={(item.id === this.state.selected_tab.id ? "bg-white" : "text-gray-700 bg-gray-50") + " shadow-xl mr-2 flex max-w-1/2 flex-1 relative h-12 items-center flex-row truncate px-2 text-sm font-medium rounded-t-lg cursor-pointer"}
            >
                {
                    !item.edit_name &&
                    <div onClick={(e) => {
                        if (!this.state.loading_remove && this.state.selected_tab && this.state.selected_tab.id !== item.id && !this.renders.tabLoading()) {
                            this.setState({
                                selected_tab: item
                            }, () => {
                                this.functions.updateSelectedTab();
                            });
                        }
                    }}
                         className={"font-medium text-sm truncate p-3 " + (index !== 0 ? 'w-11/12' : 'w-full')}
                         title={item.title}>{(item.title && item.title !== "") ? item.title : "..."}
                    </div>
                }
                {
                    item.edit_name &&
                    <div className="px-3 flex flex-1">
                        <input
                            id={"tabName" + item.id}
                            style={{fontWeight: "700"}}
                            value={item.title}
                            className="w-full font-bold truncate text-sm text-black border-none bg-white"
                            onChange={(event) => {
                                item.title = event.target.value;
                                this.setState({
                                    tabs: this.state.tabs
                                });
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    item.edit_name = false;
                                    this.setState({
                                        tabs: this.state.tabs
                                    });
                                }
                            }}
                            onBlur={() => {
                                item.edit_name = false;
                                this.setState({
                                    tabs: this.state.tabs
                                });
                            }}
                        />
                    </div>
                }
                {item.id === this.state.selected_tab.id &&
                    <span className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
                }
                {
                    !item.edit_name &&
                    this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                    <div className="flex justify-center items-center mr-2 align-middle">
                        <div onClick={() => {
                            if (item.id === this.state.selected_tab.id) {
                                this.setState({
                                    slideinDateclosed: false
                                })
                            }
                        }}
                             className={(this.state.selected_tab.id == item.id ? "cursor-pointer hover:text-purple-500" : "opacity-25 cursor-not-allowed") + (item.daterange ? " px-2" : "") + " close  transform flex items-center justify-center"}>
                            <CalendarIcon className="h-5"/>
                            {
                                false &&
                                item.daterange && item.daterange.value &&
                                <div style={{marginTop: "2px"}} className="text-xxs ml-2">
                                    {item.daterange.title}
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    !item.edit_name &&
                    this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                    <div
                        className={(this.state.selected_tab.id == item.id ? "cursor-pointer" : "opacity-25 cursor-not-allowed") + " flex justify-center items-center mr-2.5 align-middle"}>
                        <div onClick={() => {
                            if (item.id === this.state.selected_tab.id) {
                                item.edit_name = true;
                                this.setState({
                                    tabs: this.state.tabs
                                }, () => {
                                    document.getElementById("tabName" + item.id).focus();
                                });
                            }
                        }} className="close transform hover:text-purple-500 flex items-center justify-center">
                            <CogIcon className="h-5"/>
                        </div>
                    </div>
                }
                {
                    this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                    <div className="flex justify-center items-center align-middle">
                                                        <span
                                                            className={(this.state.tabs.length > 1 ? "cursor-pointer hover:text-red-500" : "opacity-25 cursor-not-allowed") + " close mr-2 transform flex items-center justify-center "}
                                                            onClick={() => {
                                                                if (this.state.tabs.length > 1) {
                                                                    let new_tab = {};
                                                                    this.state.tabs = this.state.tabs.filter((tab) => {
                                                                        return tab.id !== item.id
                                                                    })
                                                                    if (this.state.tabs.length <= index) {
                                                                        new_tab = this.state.tabs[(this.state.tabs.length - 1)];
                                                                    } else {
                                                                        new_tab = this.state.tabs[index];
                                                                    }
                                                                    this.setState({
                                                                        selected_tab: new_tab,
                                                                        tabs: this.state.tabs
                                                                    }, () => {
                                                                        this.functions.updateSelectedTab();
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <XIcon className="h-6"/>
                                                        </span>
                    </div>
                }
            </div>
        ));
        return (
            <div className="relative h-full">

                <TopNavigationAgent
                    onButton={async (type) => {
                        if (!this.renders.tabLoading()) {
                            if (type === "open_external") {
                                if (window.location.hostname === 'localhost') {
                                    window.open("http://localhost:5001/" + "standard" + "/report/" + this.props.match.params.report);
                                } else if (window.location.hostname === 'app.adcredo.io') {
                                    window.open("https://app.adcredo.io/" + "standard" + "/report/" + this.props.match.params.report);
                                } else if (window.location.hostname === 'dev.adcredo.io') {
                                    window.open("https://dev.adcredo.io/" + "standard" + "/report/" + this.props.match.params.report);
                                }
                            } else if (type === "save") {
                                if (this.state.template) {
                                    this.functions.updateTemplate();
                                } else {
                                    this.functions.updateReport();
                                }
                            } else if (type === "create_report") {
                                await this.promisedSetState({
                                    create_report: true
                                })
                            } else if (type === "create_template") {
                                await this.promisedSetState({
                                    create_template: true
                                })
                            } else if (type === "settings") {
                                this.setState({
                                    // selected_tab: {
                                    //     id: 'settings',
                                    //     title: 'Settings',
                                    //     editMode: false,
                                    //     backup: '',
                                    //     loading: false,
                                    //     data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                                    //     grid_data: []
                                    // }
                                    settings_open: true
                                })
                            }
                        }
                    }}
                    history={this.props.history}
                    breadcrumb={"Advanced report"}
                    buttons={this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) ? [
                        {
                            text: "Settings",
                            icon: CogIcon,
                            value: "settings",
                            loading: false,
                            show: !this.state.loading && this.props.match.params.report
                        },
                        {
                            text: "Open external",
                            icon: ExternalLinkIcon,
                            value: "open_external",
                            loading: false,
                            show: this.props.match.params.report && this.state.report_public && !this.state.loading
                        },
                        {
                            text: "Save " + (this.state.template ? "template" : "report"),
                            icon: SaveIcon,
                            value: "save",
                            loading: this.state.loading_save,
                            show: !this.state.loading && this.props.match.params.report
                        },
                        {
                            text: "Create report",
                            icon: DocumentReportIcon,
                            value: "create_report",
                            loading: this.state.loading_create,
                            show: !this.state.loading && (this.state.template || !this.props.match.params.report)
                        },
                        {
                            text: "Create template",
                            icon: BookOpenIcon,
                            value: "create_template",
                            loading: this.state.loading_create,
                            show: !this.state.loading && !this.state.template
                        },
                    ] : [
                        {
                            text: "Open external",
                            icon: ExternalLinkIcon,
                            value: "open_external",
                            loading: false,
                            show: this.props.match.params.report && this.state.report_public && !this.state.loading
                        },
                    ]}
                    buttonTypes={{}}
                    buttonLoaders={{}}
                    showClient={true}
                    lockClient={true}
                    client={this.state.client}
                />

                <WizardModal
                    open={this.state.create_template ? true : false}
                    icon={BookOpenIcon}
                    title={"Create template"}
                    text={"Add title for new template"}
                    button={"Create"}
                    input={this.state.report_name}
                    onInput={(value) => {
                        this.setState({
                            report_name: value
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            create_template: false
                        })
                    }}
                    onCreate={() => {
                        this.functions.createTemplate();
                    }}
                />

                <WizardModal
                    open={this.state.create_report ? true : false}
                    icon={DocumentReportIcon}
                    title={"Create report"}
                    text={"Add title for new report"}
                    button={"Create"}
                    input={this.state.report_name}
                    onInput={(value) => {
                        this.setState({
                            report_name: value
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            create_report: false
                        })
                    }}
                    onCreate={() => {
                        this.functions.createReport();
                    }}
                />

                <SuccessModal
                    open={this.state.ppxt_success ? true : false}
                    title={"Success"}
                    text={"Check your download folder"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            ppxt_success: false
                        })
                    }}
                />

                <SuccessModal
                    open={this.state.created_success ? true : false}
                    title={"Success"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            created_success: false
                        })
                    }}
                />

                <SuccessModal
                    open={this.state.saved_success ? true : false}
                    title={"Saved"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            saved_success: false
                        })
                    }}
                />

                <SweetAlert
                    show={this.state.cell_modal}
                    title="Not enough space"
                    type="warning"
                    text={"Remove cells or add more rows to fit this cell"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            cell_modal: false
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{borderTopColor: "transparent"}}
                             class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading report ...</div>
                    </div>
                }

                <SlideoutTailwind
                    open={this.state.settings_open}
                    title={"External report settings"}
                    submitButtonText={"Save"}
                    large={false}
                    medium={true}
                    noPadding={true}
                    secondaryButton={false}
                    onCancel={() => {
                        this.promisedSetState({settings_open: false, updated_settings: {}});
                    }}
                    onSubmit={async () => {
                        await this.refs.ExternalReportSettings.functions.update();
                        this.promisedSetState({settings_open: false});
                    }}
                >
                    <ExternalReportSettings
                        ref="ExternalReportSettings"
                        title={this.state.report_title}
                        description={this.state.report_description}
                        logo={this.state.report_logo}
                        public={this.state.report_public}
                        onSave={async (value) => {
                            await this.promisedSetState({
                                report_title: value.title,
                                report_description: value.description,
                                report_logo: value.logo,
                                report_public: value.public,
                            })
                            this.functions.updateReport()
                        }}

                    />
                </SlideoutTailwind>

                {
                    !this.state.loading &&
                    <div className="p-4">
                        <Fragment>
                            <div className="flex flex-1 flex-row justify-start px-4 relative z-10">
                                <SortableList
                                    items={this.state.tabs}
                                    onSortEnd={this.onSortEnd}
                                    axis="x"
                                    distance={1}
                                />
                                {
                                    this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&

                                    <div className="flex justify-start">
                                        <div className="mr-2" onClick={() => {
                                            if (!this.renders.tabLoading()) {
                                                let cloned_tab = {};
                                                let temp_data = this.state.selected_tab.cells;
                                                delete this.state.selected_tab.cells;
                                                cloned_tab = JSON.parse(JSON.stringify(this.state.selected_tab));
                                                cloned_tab.cells = temp_data;
                                                this.state.selected_tab.cells = temp_data;
                                                cloned_tab.id = Math.floor((Math.random() * 9999999999) + 1);
                                                cloned_tab.title += " clone";
                                                this.state.tabs.push(cloned_tab);
                                                this.setState({
                                                    tabs: this.state.tabs,
                                                    selected_tab: cloned_tab
                                                });
                                            }
                                        }}
                                            style={{ height: '100%' }}>
                                            <div
                                                className={"close h-12 px-4 cursor-pointer hover:text-purple-800 flex items-center  bg-white font-medium justify-center rounded-b-none text-sm rounded-md transition-all duration-200 text-purple-500 "}>
                                                <span className="mr-2">Clone tab</span>
                                                <DuplicateIcon className="h-5" />
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            if (!this.renders.tabLoading()) {
                                                let new_tab = {
                                                    loading: !this.state.premium,
                                                    init: true,
                                                    id: Math.floor((Math.random() * 9999999999) + 1),
                                                    title: 'Tab ' + (this.state.tabs.length + 1),
                                                    editMode: false,
                                                    premium: this.state.premium,
                                                    grid_data: [],
                                                    backup: '',
                                                    groups: [{
                                                        id: 1,
                                                        name: "",
                                                        rows: 6,
                                                        layout: [],
                                                        index: 0,
                                                        items: [],
                                                        coordinates: {}
                                                    }],
                                                    data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                                                    start_date: null,
                                                    end_date: null,
                                                    channel_breakdowns: {
                                                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                    },
                                                    breakdown: {
                                                        title: "Overview",
                                                        subtitle: "",
                                                        icon: "globe",
                                                        value: "overview",
                                                        default: true
                                                    },
                                                    metrics: {
                                                        custom: {}
                                                    },
                                                    piechart: this.state.default_charts,
                                                    daterange: {
                                                        title: "Last 30 days",
                                                        subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                                                        icon: "calendar",
                                                        value: "30_days",
                                                        disabled: false
                                                    }
                                                }
                                                this.state.tabs.push(new_tab);
                                                this.setState({
                                                    tabs: this.state.tabs,
                                                    selected_tab: new_tab,

                                                    slideinclosed: false
                                                }, () => {
                                                    if (!this.state.slideinclosed && this.state.selected_tab && this.state.selected_tab.id) {

                                                        this.setState({
                                                            campaigns: this.state.campaigns.map((item) => {
                                                                item.open = false;
                                                                if (this.state.selected_tab.data_sources.adgroups) {
                                                                    for (let adgroup in this.state.selected_tab.data_sources.adgroups) {
                                                                        if (this.state.selected_tab.data_sources.adgroups[adgroup].campaign == item.id) {
                                                                            item.open = true;
                                                                        }
                                                                    }
                                                                }
                                                                if (this.state.selected_tab.data_sources.ads) {
                                                                    for (let ad in this.state.selected_tab.data_sources.ads) {
                                                                        if (this.state.selected_tab.data_sources.ads[ad].campaign == item.id) {
                                                                            item.open = true;
                                                                        }
                                                                    }
                                                                }
                                                                return item;
                                                            }),
                                                            adgroups: this.state.adgroups.map((item) => {
                                                                item.open = false;
                                                                if (this.state.selected_tab.data_sources.ads) {
                                                                    for (let ad in this.state.selected_tab.data_sources.ads) {
                                                                        if (this.state.selected_tab.data_sources.ads[ad].adgroup == item.id) {
                                                                            item.open = true;
                                                                        }
                                                                    }
                                                                }
                                                                return item;
                                                            })
                                                        }, () => {
                                                            this.refs.child.functions.initiate(
                                                                this.state.selected_tab.data_sources.campaigns,
                                                                this.state.selected_tab.data_sources.adgroups,
                                                                this.state.selected_tab.data_sources.ads
                                                            );
                                                        });
                                                    }
                                                })
                                            }
                                        }} style={{ height: '100%' }}>
                                            <div
                                                className={"close h-12 px-4 cursor-pointer hover:text-purple-800 flex items-center  bg-white font-medium justify-center rounded-b-none text-sm rounded-md transition-all duration-200 text-purple-500 "}>
                                                <span className="mr-2">New tab</span>
                                                <PlusIcon className="h-5" />
                                            </div>
                                        </div>
                                        {
                                            this.state.working_users_count > 1 &&
                                            <div className="relative ml-2" style={{height: '100%'}}>
                                                <div
                                                    className=" close h-12 px-4  hover:text-purple-800 flex items-center  bg-white font-medium justify-center rounded-b-none text-sm rounded-md transition-all duration-200 text-red-500 ">
                                                    <span> {this.state.working_users_count} </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="bg-white shadow-xl rounded-md p-4 relative overflow-hidden">
                                {
                                    this.renders.tabLoading() &&
                                    <div className="flex flex-row justify-center items-center h-120">
                                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                {
                                    !this.renders.tabLoading() &&
                                    this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="flex justify-start">
                                            <div className="text-sm px-4 font-medium border-r flex items-center h-10 justify-center bg-gray-50 text-gray-700 rounded-l transition-all">
                                                {this.state.selected_tab.title}
                                            </div>
                                        </div>
                                        <div className="flex flex-1 justify-start">
                                            <div className="text-sm px-4 font-medium flex items-center h-10 justify-center bg-gray-50 text-gray-700 rounded-r transition-all">
                                                {this.state.selected_tab &&
                                                    this.state.selected_tab.daterange &&
                                                    this.state.selected_tab.daterange.value !== "lifetime" &&
                                                    <span style={{ marginTop: "2px" }} className="">
                                                        {moment(this.state.selected_tab.daterange.start_date).format("YYYY.MM.DD")}{" - "}
                                                        {moment(this.state.selected_tab.daterange.end_date).format("YYYY.MM.DD")}
                                                    </span>}
                                                {<CalendarIcon className="w-5 ml-2" />}
                                            </div>
                                        </div>
                                        {
                                            !this.renders.tabLoading() &&
                                            this.renders.pendingData() &&
                                            <div className="">
                                                <div onClick={() => {
                                                    if (!this.renders.tabLoading() && this.state.selected_tab && this.state.selected_tab.id) {

                                                        this.state.selected_tab.parsed_metrics = [];
                                                        this.state.selected_tab.parsed_items = [];
                                                        this.state.selected_tab.donut = false;

                                                        //RESET DATA
                                                        delete this.state.selected_tab.cells;

                                                        //SET LOADING
                                                        this.state.selected_tab.loading = true;

                                                        this.setState({
                                                            selected_tab: this.state.selected_tab,
                                                            tabs: this.state.tabs.map((inner_tab) => {
                                                                if (inner_tab.id === this.state.selected_tab.id) {
                                                                    inner_tab = this.state.selected_tab;
                                                                }
                                                                return inner_tab;
                                                            })
                                                        }, () => {
                                                            this.functions.updateSelectedTab();
                                                        })

                                                    }
                                                }}
                                                    className="text-sm px-4 cursor-pointer font-medium flex items-center px-4 h-10 justify-center bg-purple-100 text-purple-500 rounded transition-all duration-200 hover:bg-purple-200">
                                                    {<span>Refresh data</span>}
                                                    {<PencilAltIcon className="w-5 ml-2" />}
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected_tab && this.state.selected_tab.id !== "settings" &&
                                            this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                                            <div className="ml-4">
                                                <div onClick={() => {
                                                    if (!this.renders.tabLoading()) {
                                                        this.setState({
                                                            slideinclosed: false,

                                                        }, () => {
                                                            if (!this.state.slideinclosed && this.state.selected_tab && this.state.selected_tab.id) {
                                                                let data_sources = {};
                                                                data_sources = this.state.selected_tab.data_sources;
                                                                this.setState({
                                                                    campaigns: this.state.campaigns.map((item) => {
                                                                        item.open = false;
                                                                        if (data_sources.adgroups) {
                                                                            for (let adgroup in data_sources.adgroups) {
                                                                                if (data_sources.adgroups[adgroup].campaign == item.id) {
                                                                                    item.open = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        if (data_sources.ads) {
                                                                            for (let ad in data_sources.ads) {
                                                                                if (data_sources.ads[ad].campaign == item.id) {
                                                                                    item.open = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        return item;
                                                                    }),
                                                                    adgroups: this.state.adgroups.map((item) => {
                                                                        item.open = false;
                                                                        if (data_sources.ads) {
                                                                            for (let ad in data_sources.ads) {
                                                                                if (data_sources.ads[ad].adgroup == item.id) {
                                                                                    item.open = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        return item;
                                                                    })
                                                                }, () => {
                                                                    this.refs.child.functions.initiate(
                                                                        this.state.premium ? {} : this.state.selected_tab.data_sources.campaigns,
                                                                        this.state.premium ? {} : this.state.selected_tab.data_sources.adgroups,
                                                                        this.state.premium ? {} : this.state.selected_tab.data_sources.ads
                                                                    );
                                                                });
                                                            }
                                                        })
                                                    }
                                                }}
                                                    className="text-sm px-4 cursor-pointer font-medium flex items-center px-4 h-10 justify-center bg-purple-100 text-purple-500 rounded transition-all duration-200 hover:bg-purple-200">
                                                    {!this.renders.tabLoading() && <span>Change tab data</span>}
                                                    {!this.renders.tabLoading() && <PencilAltIcon className="w-5 ml-2" />}
                                                    {this.renders.tabLoading() && <BeatLoader sizeUnit={'px'} size={7} color={'#ffffff'} loading={true} />}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    !this.renders.template() && !this.renders.tabLoading() &&
                                    <div className="w-full h-3" />
                                }
                                {
                                    !this.renders.template() &&
                                    !this.renders.tabLoading() &&
                                    this.state.selected_tab.cells &&
                                    !(this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns.google && ("search_terms" in this.state.selected_tab.channel_breakdowns.google)) &&
                                    < ReportComponentsDonuts
                                        ref="donut"
                                        campaigns={this.state.campaigns}
                                        adgroups={this.state.adgroups}
                                        ads={this.state.ads}
                                        tab={this.state.selected_tab}
                                        update={(value) => {

                                        }}
                                    />
                                }
                                {
                                    !this.renders.template() &&
                                    !this.renders.tabLoading() &&
                                    this.state.selected_tab.cells && this.state.selected_tab.cells["main_chart"] &&
                                    !(this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns.google && ("search_terms" in this.state.selected_tab.channel_breakdowns.google)) &&
                                    <LineChartNew
                                        loading={this.state.selected_tab.loading_chart}
                                        selected={this.state.selected_tab}
                                        column={this.state.selected_tab.column}
                                        onColumn={(item) => {
                                            this.functions.updateMainChart(item);
                                        }}
                                    />
                                }
                                {
                                    !this.renders.template() &&
                                    !this.renders.tabLoading() &&
                                    this.state.selected_tab.cells && this.state.selected_tab.cells["main_table"] &&
                                    Array.isArray(this.state.selected_tab.columns) &&
                                    <TableNew
                                        tab={this.state.selected_tab}
                                    />
                                }
                            </div>
                        </Fragment>
                    </div>
                }

                {/** Slidein */}
                <ReportComponentsSlideIn
                    ref="child"
                    premium={this.state.premium}
                    closed={this.state.slideinclosed}
                    toggle={() => {
                        this.setState({
                            slideinclosed: !this.state.slideinclosed
                        })
                    }}
                    close={() => {

                        /*
                        //REMOVE NEW TAB IF NOT USED
                        let update_tabs = false;
                        if (!this.state.premium && this.state.selected_tab &&
                            Object.keys(this.state.selected_tab.data_sources.campaigns).length < 1 &&
                            Object.keys(this.state.selected_tab.data_sources.adgroups).length < 1 &&
                            Object.keys(this.state.selected_tab.data_sources.ads).length < 1
                        ) {
                            update_tabs = true;
                            let index = 0;
                            this.state.tabs = this.state.tabs.filter((item, inner_index) => {
                                if (this.state.selected_tab && item.id == this.state.selected_tab.id) {
                                    index = inner_index;
                                }
                                return item.id !== this.state.selected_tab.id;
                            });
                            console.log(index);
                        }
                        */

                        this.setState({
                            selected_tab: this.state.selected_tab,
                            tabs: this.state.tabs,
                            slideinclosed: true
                        }, () => {
                            /*
                            if (update_tabs) {
                                this.functions.updateSelectedTab();
                            }
                            */
                        })
                    }}

                    template={this.state.template}

                    tab={this.state.selected_tab}
                    cell={{}}

                    metrics={this.state.metrics}
                    default_metrics={this.state.default_metrics}
                    custom_metrics={this.state.metrics ? this.state.metrics.custom : []}
                    calculated_metrics={this.state.metrics ? this.state.metrics.calculated : []}

                    campaigns={this.state.campaigns}
                    adgroups={this.state.adgroups}
                    ads={this.state.ads}

                    selectedCampaigns={this.state.selected_tab && this.state.selected_tab.id ? this.state.selected_tab.data_sources.campaigns : {}}
                    selectedAdgroups={this.state.selected_tab && this.state.selected_tab.id ? this.state.selected_tab.data_sources.adgroups : {}}
                    selectedAds={this.state.selected_tab && this.state.selected_tab.id ? this.state.selected_tab.data_sources.ads : {}}

                    getAdgroups={(campaign) => {
                        this.all.adgroups([{ channel: campaign.channel, campaigns: [campaign.id] }]);
                    }}

                    getAds={(adgroup) => {
                        this.all.ads([{ channel: adgroup.channel, adgroups: [adgroup.id] }]);
                    }}

                    updateMetrics={(metric) => {
                        if (!Array.isArray(this.state.metrics.custom)) {
                            this.state.metrics.custom = [];
                        }
                        this.state.metrics.custom.push(metric);
                        this.setState({
                            metrics: this.state.metrics
                        });
                    }}

                    getMetrics={() => {
                        this.functions.metrics();
                    }}

                    updateData={(campaigns, adgroups, ads, tab, cell) => {

                        if (this.state.selected_tab && this.state.selected_tab.id) {

                            tab.data_sources.campaigns = campaigns;
                            tab.data_sources.adgroups = adgroups;
                            tab.data_sources.ads = ads;

                            tab.parsed_metrics = [];
                            tab.parsed_items = [];
                            tab.donut = false;

                            //RESET DATA
                            delete tab.cells;

                            //SET LOADING
                            tab.loading = true;

                            this.setState({
                                selected_tab: tab,
                                tabs: this.state.tabs.map((inner_tab) => {
                                    if (inner_tab.id === tab.id) {
                                        inner_tab = tab;
                                    }
                                    return inner_tab;
                                })
                            }, () => {
                                this.functions.updateSelectedTab();
                            })
                        }
                    }}

                />

                {
                    this.state.save_modal &&
                    <Modal
                        title={this.renders.modalTitle()}
                        subtitle={this.props.match.params.report ? ("#" + this.props.match.params.report) : ("#" + this.props.match.params.id)}
                        blueButton={true}
                        blueButtonText={"next"}
                        onBlueButtonClick={() => {
                            if (this.props.match.params.report) {
                                if (this.state.save_report || this.state.save_template) {
                                    this.functions.saveReport();
                                } else {
                                    this.functions.updateReport();
                                }
                            } else {
                                this.functions.saveReport();
                            }
                        }}
                        onClose={() => {
                            this.setState({
                                update_template: false,
                                update_report: false,
                                save_report: false,
                                save_template: false,
                                save_modal: false
                            })
                        }}
                    >
                        <div>
                            <div className="flex flex-1 rounded-md flex-col">
                                <div className="flex flex-1 flex-row">
                                    <div className="flex flex-1">
                                        <Input
                                            title={"Name"}
                                            sign={false}
                                            noBorder={false}
                                            white={false}
                                            value={this.state.report_name}
                                            type={"text"}
                                            size={'full'}
                                            onChange={(event) => {
                                                this.setState({
                                                    report_name: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    {
                                        !this.state.save_new_template && !this.state.template && !this.state.save_template &&
                                        <div className="flex flex-col ml-2">
                                            <div className="text-xs mb-1 font-bold">Public</div>
                                            <div style={{ backgroundColor: "#fcfcfc", height: "55px" }}
                                                className="flex flex-row flex-1 items-center border px-4 rounded justify-center">
                                                <Switch
                                                    onColor="#1AD5BD"
                                                    disabled={this.state.report_template || (this.state.template && !this.state.save_new_report)}
                                                    checked={(this.state.report_public && !this.state.template) || (this.state.report_public && this.state.save_new_report)}
                                                    onChange={(checked) => {
                                                        this.setState({
                                                            report_public: checked
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.report_public &&
                                    <div className="flex flex-1 mt-4">
                                        <Dropdown
                                            filter={false}
                                            title={'Logo'}
                                            size={'full'}
                                            noBorder={false}
                                            white={false}
                                            placeholder={"Click here"}
                                            options={[
                                                { name: "Agency logo", value: "agency" },
                                                { name: "Client logo", value: "client" },
                                                { name: "No Logo", value: "none" },
                                            ]}
                                            value={this.state.report_logo}
                                            onChange={(value) => {
                                                this.setState({
                                                    report_logo: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal>
                }

            </div>
        );
    }
}

class TableNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page_limit: 50,
            page: 1,
            tab: {}
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab
        }, () => {
            console.log(this.state.tab);
        })
    }

    functions = {
        downloadCsv: () => {
            let filename = this.state.tab.title + ".csv";
            const replacer = (key, value) => value === null ? "" : value;
            let header = this.state.tab.columns.map((metric) => {
                return metric;
            });
            let csv = this.state.tab.cells["main_table"].map(row => header.map(fieldName =>
                JSON.stringify(row[fieldName], replacer)).join(','))
            csv.unshift(header.join(','))
            csv = csv.join('\r\n')
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {

        let search_ad = (item) => {
            return (
                <td className={"flex justify-start"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxxs text-purple-500 flex-wrap">
                            {Array.isArray(item.headlines) && item.headlines.filter((item, index) => { return index < 3 }).join(" | ") + (item.headlines.length > 3 ? (" +" + (item.headlines.length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxxs truncate">
                                {Array.isArray(item.descriptions) ? item.descriptions.join(". ") : ""}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        return (
            <>
                <div className="flex flex-row mb-4">
                    <div className="flex flex-1"></div>
                    <div className="">
                        <div onClick={() => {
                            this.functions.downloadCsv();
                        }}
                            className="text-sm px-4 cursor-pointer font-medium flex items-center px-4 h-10 justify-center bg-purple-100 text-purple-500 rounded transition-all duration-200 hover:bg-purple-200">
                            <span>Export table (.csv)</span>
                            <DownloadIcon className="w-5 ml-2" />
                        </div>
                    </div>
                </div>
                <div className="border rounded w-full overflow-hidden">
                    <div className="overflow-scroll">
                        <table className="min-w-full divide-y divide-gray-200 bg-white table-fixed"
                            id="reportstable">
                            <thead className="bg-gray-100">
                                <tr>
                                    {
                                        Array.isArray(this.state.tab.columns) &&
                                        this.state.tab.columns.map((column, index) => {
                                            return (
                                                <th key={index} onClick={() => {
                                                    this.setState({
                                                        sort: column,
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    });
                                                }} className={"cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium text-gray-500 uppercase " + (this.state.sort === column ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div className="px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                        <div>
                                                            {column}
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 relative">
                                {
                                    this.state.tab.cells &&
                                    Array.isArray(this.state.tab.cells["main_table"]) &&
                                    this.state.tab.cells["main_table"].sort((a, b) => {
                                        if (this.state.order === 'asc') {
                                            return (a[this.state.sort] > b[this.state.sort]) ? -1 : 1
                                        } else {
                                            return (a[this.state.sort] > b[this.state.sort]) ? 1 : -1
                                        }
                                    }).filter((item, index) => {
                                        if (this.state.tab.cells["main_table"].length > this.state.page_limit) {
                                            return (this.state.page * this.state.page_limit) > index
                                        } else {
                                            return true;
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                {
                                                    <tr>
                                                        {
                                                            Array.isArray(this.state.tab.columns) &&
                                                            this.state.tab.columns.map((column, i) => {
                                                                if (column == "channel") {
                                                                    return (
                                                                        <td key={i} className={(this.state.sort === column ? " border-l border-r " : "") + " "}>
                                                                            <div className="text-sm flex justify-start items-center px-6 text-gray-900 truncate">
                                                                                <div className="flex-row flex items-center justify-start">
                                                                                    {
                                                                                        item.channel === "google" &&
                                                                                        <div
                                                                                            className="bg-google-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3" src={require('../../assets/images/google_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "google_shopping" &&
                                                                                        <div
                                                                                            className="bg-googleshopping-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img style={{ width: "0.65rem" }} src={require('../../assets/images/google-shopping.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "linkedin" &&
                                                                                        <div
                                                                                            className="bg-linkedin-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/linkedin_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "facebook" &&
                                                                                        <div
                                                                                            className="bg-facebook-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img style={{ width: '0.4rem' }}
                                                                                                src={require('../../assets/images/facebook_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "google_analytics" &&
                                                                                        <div
                                                                                            className="bg-googleanalytics-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/ga_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "adform" &&
                                                                                        <div
                                                                                            className="bg-adform-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/adform_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "twitter" &&
                                                                                        <div
                                                                                            className="bg-twitter-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/twitter_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "tiktok" &&
                                                                                        <div
                                                                                            className="bg-tiktok-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/tiktok_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "bing" &&
                                                                                        <div
                                                                                            className="bg-bing-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-2.5"
                                                                                                src={require('../../assets/images/bing-logo.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.channel === "snapchat" &&
                                                                                        <div
                                                                                            className="bg-snapchat-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-2.5"
                                                                                                src={require('../../assets/images/snapchat.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="capitalize ml-1">
                                                                                        {item.channel === 'google_shopping' ? "Google Shopping" : (item.channel === 'google_analytics' ? "Google Analytics" : item.channel)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } else if (column == "preview" && item.level == "ad" && (item.channel === "google" || item.channel === "bing")) {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            {search_ad(item)}
                                                                        </td>
                                                                    )
                                                                } else if (column == "preview" && item.level == "ad" && item.channel !== "google" && item.channel !== "bing") {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            <div className="flex flex-row items-center">
                                                                                <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                    <div className="h-32 w-32 rounded-md justify-center flex overflow-hidden bg-gray-50">
                                                                                        {
                                                                                            item.video_url &&
                                                                                            <video controls={true} loop={true} autoPlay={false} muted={true}
                                                                                                style={{ width: '100%', height: '100%' }}>
                                                                                                <source src={item.video_url} type="video/mp4" />
                                                                                            </video>
                                                                                        }
                                                                                        {
                                                                                            item.image_url && !item.video_url &&
                                                                                            <img src={item.image_url} style={{ height: '100%' }} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <td
                                                                            key={i}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === column ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        >
                                                                            <div className="flex flex-row items-center">
                                                                                <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                    {item[column]}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tr>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody >
                        </table >
                    </div >
                </div >
                {
                    this.state.tab.cells &&
                    Array.isArray(this.state.tab.cells["main_table"]) &&
                    this.state.tab.cells["main_table"].length > (this.state.page * this.state.page_limit) &&
                    <div className="mt-5 mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                        <div onClick={(e) => {
                            this.setState({
                                page: this.state.page + 1
                            })
                        }} className="btn btn-primary btn-lg max-w-md">
                            <div>
                                Load more ({this.state.page * this.state.page_limit}/{this.state.tab.cells["main_table"].length})
                            </div>
                        </div>
                        <div onClick={() => {
                            this.setState({
                                page_limit: 10000000000000
                            })
                        }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                            show all
                        </div>
                    </div>
                }
            </>
        )
    }

}

class LineChartNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            column: "",
            selected: {},
            metrics: [],
            plugin: {
                plugins: [ChartDataLabels],
                options: {
                    plugins: {
                        datalabels: {
                            color: 'white'
                        }
                    }
                },
            },
            bar: null,
            line: null,
            chart_type: "bar"
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            selected: this.props.selected,
            column: this.props.column,
            metrics: this.props.metrics ? this.props.metrics : []
        });
        this.functions.setBarChartOptions();
        this.functions.setLineChartOptions();
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            loading: nextProps.loading,
            column: nextProps.column,
        });
        this.functions.setBarChartOptions();
        this.functions.setLineChartOptions();
    }

    functions = {
        setBarChartOptions: async () => {
            let total = 0;
            if (this.state.selected.cells && this.state.selected.cells["main_chart"]) {
                total = (this.state.selected.cells["main_chart"].datasets.length * this.state.selected.cells["main_chart"].labels.length);
            }
            let bar_options = {
                orientation: 'horizontal',
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: function (context) {
                            let ctx = context
                            var width = ctx.chart.width;
                            let size = 0;
                            if (total > 15) {
                                size = 0;
                            } else if (total >= 12) {
                                size = 110;
                            } else if (total >= 9) {
                                size = 90;
                            } else if (total >= 6) {
                                size = 70;
                            } else if (total >= 3) {
                                size = 50;
                            } else {
                                size = 45;
                            }
                            var font_size = Math.round(width / size);
                            return {
                                size: font_size,
                                weight: 900,
                                family: "Poppins",
                            };
                        }
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 10,
                            beginAtZero: true,
                            precision: 0
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10
                        }
                    }]
                },
            };
            await this.promisedSetState({
                bar: bar_options
            });
        },
        setLineChartOptions: async () => {
            let total = 0;
            if (this.state.selected.cells && this.state.selected.cells["main_chart"]) {
                total = (this.state.selected.cells["main_chart"].datasets.length * this.state.selected.cells["main_chart"].labels.length);
            }
            let line_options = {
                maintainAspectRatio: false,
                legend: {
                    display: true
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                plugins: {
                    datalabels: {
                        backgroundColor: function (context) {
                            return context.dataset.backgroundColor;
                        },
                        borderRadius: 4,
                        color: 'white',
                        font: {
                            size: "7",
                            weight: '500'
                        },
                        padding: 4
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 10,
                            beginAtZero: true,
                            precision: 0
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10
                        }
                    }]
                }
            };
            await this.promisedSetState({
                line: line_options
            });
        },
        renderData: (chart) => {
            try {
                chart.datasets = chart.datasets.map((dataset) => {
                    dataset.data = dataset.data.map((item) => {
                        return +item;
                    });
                    return dataset
                })
            } catch (error) {

            }
            return chart;
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div style={{ backgroundColor: "rgb(252, 252, 252)" }} className="rounded border p-4 relative mb-4">
                <div className="flex flex-row">
                    <div className="inline-flex flex-row mr-4">
                        <div onClick={() => {
                            this.setState({
                                chart_type: "bar"
                            });
                        }} className={(this.state.chart_type === "bar" ? "text-purple-500 " : "") + " rounded-l-md border-r hover:bg-purple-200 whitespace-no-wrap text-sm cursor-pointer font-medium inline-flex items-center px-4 h-10 justify-center  bg-purple-100 transition-all duration-200"}>
                            <PresentationChartBarIcon className="h-5 mr-2" />
                            Bar chart
                        </div>
                        <div onClick={() => {
                            this.setState({
                                chart_type: "line"
                            });
                        }} className={(this.state.chart_type === "line" ? "text-purple-500 " : "") + " rounded-r-md hover:bg-purple-200 whitespace-no-wrap text-sm cursor-pointer font-medium inline-flex items-center px-4 h-10 justify-center  bg-purple-100 transition-all duration-200"}>
                            <PresentationChartLineIcon className="h-5 mr-2" />
                            Line chart
                        </div>
                    </div>
                    <div className="inline-flex mb-4 flex-row overflow-scroll">
                        {
                            this.state.selected && Array.isArray(this.state.selected.columns) &&
                            this.state.selected.columns.filter((item) => {
                                return item !== "channel" && item !== "level" && item !== "breakdown" && item !== "breakdown_value" && item !== "name" && item !== "id"
                            }).map((item, index) => {
                                return (
                                    <div style={{ whiteSpace: "nowrap" }} onClick={() => {
                                        if (item !== this.state.column) {
                                            this.props.onColumn(item);
                                        }
                                    }} className={(index === this.state.selected.columns.filter((item) => {
                                        return item !== "channel" && item !== "level" && item !== "breakdown" && item !== "breakdown_value" && item !== "name" && item !== "id"
                                    }).length - 1 ? "rounded-r-md " : "") + (index !== 0 ? "border-l " : "") + (index === 0 ? "rounded-l-md " : "") + (this.state.column === item ? "text-purple-500 " : "") + " hover:bg-purple-200 text-sm cursor-pointer font-medium inline-flex items-center px-4  h-10 justify-center  bg-purple-100 transition-all duration-200"}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="overflow-hidden" style={{ height: "350px" }}>
                    {
                        this.state.selected && this.state.selected.cells && this.state.selected.cells["main_chart"] && !this.state.loading &&
                        <div style={{ height: "350px" }}>
                            {
                                this.state.chart_type == "line" &&
                                this.state.line &&
                                <Line
                                    options={this.state.line}
                                    data={this.functions.renderData(this.state.selected.cells["main_chart"])}
                                    plugins={this.state.selected && this.state.selected.settings && this.state.selected.settings.chart_numbers && this.state.selected.settings.chart_numbers.value === "enabled" ? this.state.plugin.plugins : {}}
                                />
                            }
                            {
                                this.state.chart_type == "bar" &&
                                this.state.bar &&
                                <Bar
                                    options={this.state.bar}
                                    data={this.functions.renderData(this.state.selected.cells["main_chart"])}
                                    plugins={this.state.selected && this.state.selected.settings && this.state.selected.settings.chart_numbers && this.state.selected.settings.chart_numbers.value === "enabled" ? this.state.plugin.plugins : {}}
                                />
                            }
                        </div>
                    }
                </div>
                {
                    this.state.loading &&
                    <div className="top-0 left-0 right-0 bottom-0 absolute bg-white rounded">
                        <div className="justify-center align-middle flex w-full h-full flex-col text-center">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default ReportStandardInternal;
