import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { BeatLoader } from "react-spinners";
import { calls } from "./calls";
import IconGoogle from "../../components/icons/google";
import IconFacebook from "../../components/icons/facebook";
import Input from "../../components/input";
import { SlideDown } from "react-slidedown";
import CreateCustomMetric from "../../modules/createCustomMetric";
import { apiRegister } from '../../services/apiRegister';
import { tokenRegister } from '../../services/tokenRegister';

const fuzzysort = require('fuzzysort');

var moment = require('moment');

class UserMetrics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            custom_metrics: [],
            clients: [],
            loading: true,
            closed: true,
            submenu: "Custom metrics"
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.init();
    }

    functions = {
        init: () => {
            this.setState({
                loading: true
            }, () => {
                let function_calls = [
                    { function: "listMetrics", channel: "adcredo", data: { custom: true, channels: true } },
                    { function: "listClients", channel: "adcredo" }
                ];
                this.calls.batch(function_calls).then((response) => {
                    let all_metrics = {};
                    let custom_metrics = [];
                    let clients = [];
                    if (Array.isArray(response.data)) {
                        if (response.data[0] && response.data[0].status == 200) {
                            let data = response.data[0].data;
                            custom_metrics = custom_metrics.concat(Array.isArray(data.default) ? data.default : []);
                            custom_metrics = custom_metrics.concat(Array.isArray(data.custom) ? data.custom : []);
                            custom_metrics = custom_metrics.concat(Array.isArray(data.calculated) ? data.calculated : []);
                            all_metrics = data;
                        }
                        if (response.data[1] && response.data[1].status == 200) {
                            let data = response.data[1].data;
                            clients = data;
                        }
                    }
                    this.setState({
                        all_metrics: all_metrics,
                        custom_metrics: custom_metrics,
                        clients: clients,
                        loading: false,
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                });
            })
        },
        remove: (item) => {
            item.loading = true;
            this.setState({
                custom_metrics: this.state.custom_metrics
            }, () => {
                calls.removeMetric(item.id).then((response) => {
                    this.setState({
                        custom_metrics: this.state.custom_metrics.filter((inner_item) => {
                            return inner_item.id !== item.id
                        })
                    })
                }, (error) => {
                    item.loading = false;
                    this.setState({
                        custom_metrics: this.state.custom_metrics
                    })
                });
            })
        }
    };

    calls = {
        batch: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v2/controllers/batchFunctions";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        filter: () => {
            let options = this.state.custom_metrics.filter((item) => {
                return (!item.calculated && this.state.submenu === "Custom metrics") || (item.calculated && this.state.submenu === "Calculated metrics")
            });
            let keywords = [];
            if (this.state.name_search && this.state.name_search !== "") {
                let clients = fuzzysort.go(this.state.name_search, options.map((item) => { return item.name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                clients.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                keywords = options;
            }
            return keywords;
        }
    };

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                {
                    !this.state.loading && this.state.custom_metrics.length > 0 &&
                    <div className="flex items-center justify-center mb-4">
                        <div className="flex flex-1">
                            <div>
                                <h3 className="mb-0 font-bold">
                                    All metrics
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.state.custom_metrics.length}</span> metrics
                                    available
                                </p>
                            </div>
                        </div>
                        <div onClick={(e) => {
                            this.setState({
                                closed: false,
                                metric: null
                            }, () => {
                                this.refs.customMetric.functions.init();
                            })
                        }} className="btn inline-block btn-primary">+ Create new</div>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="mt-48 mb-48">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Metrics ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.loading && this.state.custom_metrics.length > 0 &&
                    <div className="bg-white rounded-md mt-4 flex-col flex  overflow-x-auto border-b border-gray-200 sm:rounded-lg shadow-lg">
                        <div style={{ backgroundColor: "#fcfcfc", height: "52px" }} className="px-4 rounded-t-lg border-b-2 relative">
                            <div>
                                <div className="navigation pl-2">
                                    <nav role="navigation" aria-label="Social sub navigation">
                                        <ul className="flex flex-row items-end">
                                            {["Default metrics", "Custom metrics", "Calculated metrics"].map((key, j) => {
                                                return (
                                                    <li key={j}
                                                        className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'text-gray-500')}
                                                        onClick={() => {
                                                            this.setState({
                                                                submenu: key,
                                                                //client_search: key === 'Templates' ? '' : this.state.client_search
                                                            });
                                                        }}>
                                                        {key}
                                                    </li>
                                                )
                                            })
                                            }
                                            <li className="flex-grow border-b-2"></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="py-4 px-6 flex flex-row w-full">
                            <div className="w-64 mr-5">
                                <div className="text-xs font-bold mb-1">
                                    Name
                                </div>
                                <input
                                    onChange={(event) => {
                                        this.setState({
                                            name_search: event.target.value
                                        })
                                    }}
                                    value={this.state.name_search}
                                    className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                />
                            </div>
                        </div>
                        <div className="border-b w-full" />
                        <MetricsList
                            items={this.renders.filter()}
                            onRemove={(item) => {
                                this.functions.remove(item);
                            }}
                            onNew={(item) => {
                                this.setState({
                                    closed: false,
                                    metric: null
                                }, () => {
                                    this.refs.customMetric.functions.init();
                                })
                            }}
                            onEdit={(item) => {
                                this.setState({
                                    closed: false,
                                    metric: item
                                }, () => {
                                    this.refs.customMetric.functions.init();
                                })
                            }}
                        />
                    </div>
                }
                {
                    !this.state.loading && this.state.custom_metrics.length < 1 &&
                    <div className="mt-10 text-center">
                        <p>You dont have any metrics</p>
                        <div onClick={(e) => {
                            this.setState({
                                closed: false,
                                metric: null
                            }, () => {
                                this.refs.customMetric.functions.init();
                            })
                        }} className="btn inline-block btn-primary mt-3">+ Create metric
                        </div>
                    </div>
                }
                {
                    <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                        style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                        <div className="absolute inset-0 overflow-hidden">
                            <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.state.closed ? 'opacity-0' : 'opacity-100'}`}></div>
                            <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                                <div
                                    className={`relative w-screen ${this.props.maxWidth && this.props.maxWidth ? this.props.maxWidth : 'max-w-md'} transform transition ease-in-out duration-500 sm:duration-700 ${this.state.closed ? 'translate-x-full' : 'translate-x-0'}`}
                                    ref={this.props.slideOver && this.props.slideOver}>
                                    <div className="absolute right-100 pr-6 pt-6 flex">
                                        <button aria-label="Close panel"
                                            className="bg-red-500 text-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                            onClick={() => {
                                                this.setState({
                                                    closed: !this.state.closed
                                                })
                                            }}
                                        >
                                            <FeatherIcon className="stroke-current" size={16} icon="x" />
                                        </button>
                                    </div>
                                    <div className={`h-full flex flex-col py-6 pt-0 bg-white shadow-xl overflow-y-scroll ${this.props.slideOver && this.props.rounded ? this.props.rounded : ''}`}>
                                        <div className={"w-full h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                            <header className="px-4 sm:px-6 mb-6 py-6 bg-gray-100">
                                                {
                                                    !this.state.metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Create metric
                                                    </h2>
                                                }
                                                {
                                                    this.state.metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Edit metric
                                                    </h2>
                                                }
                                            </header>
                                            <CreateCustomMetric
                                                ref={'customMetric'}
                                                premium={true}
                                                option={!this.state.metric ? { value: "new_metric" } : { value: "edit_metric" }}
                                                view={"custom_metric"}
                                                channels={["facebook", "google", "linkedin", "google_analycs"]}
                                                custom_metrics={this.state.all_metrics}
                                                metric={this.state.metric}
                                                clients={this.state.clients}
                                                datasources={[]}
                                                close={(type) => {
                                                    this.setState({
                                                        closed: true
                                                    }, () => {
                                                        if (type === "created") {
                                                            this.functions.init();
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                }
            </section>
        )
    }
}

class MetricsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            order: "asc",
            sort: "created"
        };
    }

    componentDidMount() {
        this.setState({
            items: this.props.items
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items
        })
    }

    renders = {
        channels: () => {
            let channels = {};
            let channels_array = [];
            channels_array.push('google');
            channels_array.push('facebook');
            channels_array.push('linkedin');
            channels_array.push('adform');
            channels_array.push('bidtheatre');
            channels_array.push('google_analytics');
            return channels_array;
        },
        channel: (item, channel) => {
            try {
                return item.channels[channel] ? item.channels[channel].name : "-";
            } catch (e) {
                return "-"
            }
        }
    };

    render() {
        return (
            <>
                <div className="shadow-lg overflow-scroll border-b border-gray-200 rounded-lg w-full">
                    <table className="min-w-full divide-y divide-gray-200 bg-white">
                        <thead>
                            <tr>

                                <th onClick={() => {
                                    this.setState({
                                        sort: 'name',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Name <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'channels',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'channels' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Channels <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'calculated',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'calculated' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Metric <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'created',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'created' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Created <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'name',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    });
                                }}
                                    className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Created by <div className="sorting-arrows ml-2"></div>
                                    </div>
                                </th>
                                {
                                    false &&
                                    this.renders.channels().map((item) => {
                                        return (
                                            <th className={"cursor-pointer transition-all w-20 duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === item ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                <div className="flex flex-row items-center">
                                                    {item}
                                                    <div className="sorting-arrows ml-2"></div>
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                                <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    <div className="flex flex-row items-center justify-end">
                                        Actions
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                this.state.items.sort((a, b) => {
                                    if (this.state.order === 'desc') {
                                        return (a[this.state.sort] > b[this.state.sort]) ? 1 : -1
                                    } else {
                                        return (a[this.state.sort] > b[this.state.sort]) ? -1 : 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <tr className="">

                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className="text-sm leading-5 text-gray-900">
                                                    {item.name}
                                                </div>
                                            </td>

                                            <td className="px-3 py-4 whitespace-no-wrap">
                                                <div className="flex flex-row">
                                                    {item.channels && item.channels.facebook &&
                                                        <div
                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/facebook_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.instagram &&
                                                        <div
                                                            className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white ' + (item.channels.facebook ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/instagram_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.linkedin &&
                                                        <div
                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white ' + ((item.channels.instagram || item.channels.facebook) ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.google &&
                                                        <div
                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram) ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.google_analytics &&
                                                        <div
                                                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram || item.channels.google) ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/ga_icon.png') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.adform &&
                                                        <div
                                                            className={'w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram || item.channels.google || item.channels.google_analytics) ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/adform_icon.png') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {item.channels && item.channels.bidtheatre &&
                                                        <div
                                                            className={'w-8 h-8 bg-bidtheatre-500 rounded-full p-2 border-2 border-white ' + ((item.channels.facebook || item.channels.linkedin || item.channels.instagram || item.channels.google || item.channels.google_analytics || item.channels.adform) ? '-ml-2' : '')}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap capitalize">
                                                <div
                                                    className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                        {
                                                            ["bg-gray-100 text-gray-500"]: !item.calculated,
                                                            ["bg-purple-100 text-purple-500"]: item.calculated,
                                                        })
                                                    }>
                                                    {item.calculated ? "calculated" : "custom"}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'created' ? 'font-bold' : '')}>
                                                    {item.created ? moment(item.created).format('DD MMMM') : "..."}
                                                </div>
                                                <div className={"text-xs leading-5 " + (this.state.sort === 'created' ? 'text-gray-900' : 'text-gray-500')}>
                                                    {item.created ? moment(item.created).format('DD/MM/YYYY') : "..."}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                                <div className={"text-sm leading-5 text-gray-900 capitalize " + (this.state.sort === 'user' ? 'font-bold' : '')}>
                                                    {item.user_name}
                                                </div>
                                                <div className={"text-xs leading-5 " + (this.state.sort === 'created' ? 'text-gray-900' : 'text-gray-500')}>
                                                    {item.user_email}
                                                </div>
                                            </td>
                                            {
                                                false &&
                                                this.renders.channels().map((channel) => {
                                                    return (
                                                        <td className="px-6 py-4 whitespace-no-wrap text-sm truncate w-20">
                                                            <div className="text-sm leading-5 text-gray-900 truncate">
                                                                {this.renders.channel(item, channel)}
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                            <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                <div className="flex flex-row items-end justify-end">
                                                    <button onClick={() => {
                                                        this.props.onEdit(item);
                                                    }} className={"bg-purple-200 flex justify-center align-middle items-center w-24 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                        <span>Edit</span>
                                                        <FeatherIcon className="stroke-current ml-2" size={16} icon="edit" />
                                                    </button>
                                                    <button onClick={() => {
                                                        try {
                                                            if (!item.loading) {
                                                                this.props.onRemove(item)
                                                            }
                                                        } catch (e) {
                                                        }
                                                    }} className="ml-2 relative overflow-hidden flex justify-center align-middle items-center bg-red-200 w-24 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                        <span>Delete</span>
                                                        <FeatherIcon className="stroke-current ml-2" size={15}
                                                            icon="trash" />
                                                        {
                                                            item.loading &&
                                                            <div className="absolute left-0 bg-red-500 bg-opacity-75 right-0 top-0 bottom-0 flex justify-center items-center">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={10}
                                                                    color={'#ffffff'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default UserMetrics;
