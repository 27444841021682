import React, { Component, Fragment } from 'react';
import AdvancedReportGridLayout from "./advancedReportGridLayout";
import { userRegister } from "../services/userRegister";

class ReportGrid extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			campaigns: [],
			adgroups: [],
			ads: [],
			groups: [],
			tab: 0,
			static: false
		};
	};

	componentDidMount() {
		this.setState({
			static: this.props.static,
			data: this.props.data ? this.props.data : [],
			groups: this.props.groups ? this.props.groups : [],
			campaigns: Array.isArray(this.props.campaigns) ? this.props.campaigns : [],
			adgroups: Array.isArray(this.props.adgroups) ? this.props.adgroups : [],
			ads: Array.isArray(this.props.ads) ? this.props.ads : [],
			tabLoading: this.props.tabLoading,
			transparent_cells: this.props.transparent_cells,
			reportBackgroundEnabled: this.props.reportBackgroundEnabled,
			reportBackgroundColor: this.props.reportBackgroundColor,
			master_template: this.props.masterTemplate,
			scale: this.props.scale,
			template: this.props.template
		});

		this.functions.user();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			data: nextProps.data ? nextProps.data : [],
			groups: nextProps.groups ? nextProps.groups : [],
			campaigns: Array.isArray(nextProps.campaigns) ? nextProps.campaigns : [],
			adgroups: Array.isArray(nextProps.adgroups) ? nextProps.adgroups : [],
			ads: Array.isArray(nextProps.ads) ? nextProps.ads : [],
			tabLoading: nextProps.tabLoading,
			transparent_cells: nextProps.transparent_cells,
			reportBackgroundEnabled: nextProps.reportBackgroundEnabled,
			reportBackgroundColor: nextProps.reportBackgroundColor,
			master_template: nextProps.masterTemplate,
			scale: nextProps.scale,
			template: nextProps.template
		})
	}

	functions = {
		user: async () => {
			await this.promisedSetState({
				user: userRegister.get()
			});
			if (this.state.user && this.state.user.userRole === "sales") {
				await this.promisedSetState({
					static: true
				});
			}
		},
	};

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	updateCell(cell) {
		try {
			this.refs.grid.updateCell(cell);
		} catch (error) { }
	}

	updateLoaderCell(cell) {
		try {
			this.refs.grid.updateLoaderCell(cell);
		} catch (error) { }
	}

	disableGrid() {
		try {
			this.refs.grid.disableGrid();
		} catch (error) { }
	}

	enableGrid() {
		try {
			this.refs.grid.enableGrid();
		} catch (error) { }
	}

	render() {
		return (
			<div className="">
				{
					Array.isArray(this.state.data) &&
					<AdvancedReportGridLayout
						static={this.state.static}
						ref={"grid"}
						onGridChange={(items, layout) => {
							if (this.props.onGridChange) {
								this.props.onGridChange(items);
							}
						}}
						scale={this.state.scale}
						template={this.state.template}
						masterTemplate={this.state.master_template}
						reportBackgroundEnabled={this.state.reportBackgroundEnabled}
						reportBackgroundColor={this.state.reportBackgroundColor}
						tabLoading={this.state.tabLoading}
						initialItems={this.state.data}
						items={this.state.data}
						groups={this.state.groups}

						campaigns={this.state.campaigns}
						adgroups={this.state.adgroups}
						ads={this.state.ads}

						isSlideOverClosed={() => {
							this.setState({
								isSlideOverClosed: !this.state.isSlideOverClosed
							});
						}}
						//backgroundColor={'overflow-hidden'}
						isBounded={false}
						preventCollision={false}
						verticalCompact={true}
						remove={true}
						transparent_cells={this.state.transparent_cells}
						//ref={this.child}
						selectAllCells={(page, value) => {
							if (this.props.selectAllCells) {
								this.props.selectAllCells(page, value);
							}
						}}
						editSelected={() => {
							if (this.props.editSelected) {
								this.props.editSelected();
							}
						}}
						removePage={(group) => {
							if (this.props.removePage) {
								this.props.removePage(group);
							}
						}}
						onRefreshItem={(item) => {
							if (this.props.onRefreshItem) {
								this.props.onRefreshItem(item);
							}
						}}
						onSelectItem={(item) => {
							if (this.props.onSelectItem) {
								this.props.onSelectItem(item);
							}
						}}
						onEditItem={(item) => {
							if (this.props.onEditItem) {
								this.props.onEditItem(item);
							}
						}}
						onUpdateItem={(item) => {
							if (this.props.onUpdateItem) {
								this.props.onUpdateItem(item);
							}
						}}
						clonePage={(group) => {
							if (this.props.clonePage) {
								this.props.clonePage(group);
							}
						}}
						cloneCell={(cell) => {

						}}
						updatePage={(group) => {
							if (this.props.updatePage) {
								this.props.updatePage(group);
							}
						}}
						addPage={(index) => {
							if (this.props.addPage) {
								this.props.addPage(index);
							}
						}}
						addRows={(group) => {
							if (this.props.addRows) {
								this.props.addRows(group);
							}
						}}
						removeRows={(group) => {
							if (this.props.removeRows) {
								this.props.removeRows(group);
							}
						}}
						addCell={(group) => {
							if (this.props.addCell) {
								this.props.addCell(group);
							}
						}}
						updateGroups={(groups) => {
							if (this.props.updateGroups) {
								this.props.updateGroups(groups);
							}
						}}
						onUpdate={(item) => {
							this.setState({
								data: this.state.data.map((inner_item) => {
									if (inner_item.i === item.i) {
										inner_item = item;
									}
									return inner_item;
								})
							}, () => {
								if (this.props.updateGrid) {
									this.props.updateGrid(this.state.data);
								}
							})
						}}
						onRemove={(item) => {
							this.state.data = this.state.data.filter((inner_item) => {
								return inner_item.parent_cell != item.i;
							});
							this.state.data = this.state.data.filter((inner_item) => {
								return inner_item.i != item.i;
							});
							this.setState({
								data: this.state.data
							}, () => {
								if (this.props.updateGrid) {
									this.props.updateGrid(this.state.data);
								}
							})
						}}
						onCopyItem={(item) => {
							if (this.props.cloneCell) {
								this.props.cloneCell(item);
							}
						}}
					/>
				}
			</div>
		)
	}

}

export default ReportGrid;