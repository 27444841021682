// initData for tabsFiltering

let data1 = [];
let data2 = [];
let data3 = [];
let data4 = [];

function randomInteger(min, max) {
    let rand = Math.floor(min + Math.random() * (max + 1 - min));
    return Math.floor(rand);
}

for (let i = 0; i < 6; i++) {
    data1.push(randomInteger(0, 10000));
    data2.push(randomInteger(0, 10000));
    data3.push(randomInteger(0, 10000));
    data4.push(randomInteger(0, 10000));
}

// export const initialFakeData = [
//     {
//         id: 1,
//         tab: {id: 1, text: 'Impressions', editMode: false},
//         data: [
//             {
//                 i: '0',
//                 x: 0,
//                 y: 0,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Estimated TMP and reach',
//                 data: ['Target Group: A30-59', 'KPI: Reach & awareness', 'Estimated imps: 10 2017 211'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 i: '1',
//                 x: 1,
//                 y: 0,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Total Impressions',
//                 data: 28087409,
//                 typeOfBlock: 'number',
//             },
//             {
//                 i: '2',
//                 x: 2,
//                 y: 0,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Gross reach',
//                 data: 4717605,
//                 typeOfBlock: 'number',
//             },
//             {
//                 i: '3',
//                 x: 3,
//                 y: 0,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Clicks',
//                 data: 105337,
//                 typeOfBlock: 'number',
//             },
//             {
//                 i: '4',
//                 x: 0,
//                 y: 1,
//                 w: 4,
//                 h: 2,
//                 static: true,
//                 data: {
//                     labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

//                     datasets: [
//                         {
//                             label: 'Clicks',
//                             backgroundColor: '#DBDAFA',
//                             hoverBorderColor: '#DBDAFA',
//                             data: data1,
//                         },

//                         {
//                             label: 'CTR',
//                             backgroundColor: '#FCDAC3',
//                             hoverBorderColor: '#FCDAC3',
//                             data: data2,
//                         },
//                     ],
//                 },
//                 header: 'Clicks & CRT%',
//                 typeOfBlock: 'bar',
//             },
//             {
//                 i: '5',
//                 x: 0,
//                 y: 3,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Results',
//                 data: ['Conversions (step 1): 21 162', 'Conv. Approved: 579', 'CTR: 0,38 %', 'CMP: 34,32'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 i: '6',
//                 x: 1,
//                 y: 3,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Spend',
//                 data: ['Media spend: 963 889 SEK', 'Avarage vCPM: 47,58 SEK', 'SPA (step 1): 45,55 SEK', 'Budget CTC: 1 085 594 SEK'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 i: '7',
//                 x: 2,
//                 y: 3,
//                 w: 2,
//                 h: 2,
//                 static: true,
//                 header: 'Conversions platform',
//                 data: {
//                     labels: ['Bonnier', 'Prog. Display'],
//                     datasets: [
//                         {
//                             data: [50, 70],
//                             backgroundColor: ['#C3FCE0', '#6FDB9D'],
//                             hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
//                         },
//                     ],
//                     text: '23%',
//                 },
//                 typeOfBlock: 'doughnut',
//             },
//             {
//                 i: '8',
//                 x: 0,
//                 y: 4,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Results',
//                 data: ['Conversions (step 1): 21 162', 'Conv. Approved: 579', 'CTR: 0,38 %', 'CMP: 34,32'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 i: '9',
//                 x: 1,
//                 y: 4,
//                 w: 1,
//                 h: 1,
//                 static: true,
//                 header: 'Spend',
//                 data: ['Media spend: 963 889 SEK', 'Avarage vCPM: 47,58 SEK', 'SPA (step 1): 45,55 SEK', 'Budget CTC: 1 085 594 SEK'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 i: '10',
//                 x: 0,
//                 y: 5,
//                 w: 2,
//                 h: 2,
//                 static: true,
//                 header: 'screenshot',
//                 data: 'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/mac_apps/safari/macos-big-sur-safari-screenshot-portion.jpg',
//                 typeOfBlock: 'img',
//             },
//             {
//                 i: '11',
//                 x: 2,
//                 y: 5,
//                 w: 2,
//                 h: 2,
//                 static: true,
//                 header: 'screenshot',
//                 data: '<p>Hello, <u><em><b>world!</b></em></u></p>',
//                 typeOfBlock: 'input',
//             },
//             {i: '+', x: 0, y: 7, w: 1, h: 1, add: true, static: true, typeOfBlock: 'add'},
//         ],
//     },
// ];

export const initialFakeData = [
    {
        id: 1,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Total Impressions',
                data: 28087409,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Gross reach',
                data: 4717605,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Clicks',
                data: 105337,
                typeOfBlock: 'number',
            },
            {
                i: '4',
                x: 0,
                y: 1,
                w: 4,
                h: 2,
                static: false,
                data: {
                    labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

                    datasets: [
                        {
                            label: 'Clicks',
                            backgroundColor: '#DBDAFA',
                            hoverBorderColor: '#DBDAFA',
                            data: data1,
                        },

                        {
                            label: 'CTR',
                            backgroundColor: '#FCDAC3',
                            hoverBorderColor: '#FCDAC3',
                            data: data2,
                        },
                    ],
                },
                header: 'Clicks & CRT%',
                typeOfBlock: 'bar',
            },
            {
                i: '5',
                x: 0,
                y: 3,
                w: 1,
                h: 2,
                static: false,
                header: 'Results',
                data: '<p><b>Results</b></p><ul><li>Conversions (step 1): 21 162</li><li>Conv. Approved: 579</li><li>CTR: 0,38 %</li><li>CMP: 34,32</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '6',
                x: 1,
                y: 3,
                w: 1,
                h: 2,
                static: false,
                header: 'Spend',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '7',
                x: 2,
                y: 3,
                w: 2,
                h: 2,
                static: false,
                header: 'Conversions platform',
                data: {
                    labels: ['Bonnier', 'Prog. Display'],
                    datasets: [
                        {
                            data: [50, 70],
                            backgroundColor: ['#C3FCE0', '#6FDB9D'],
                            hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
                        },
                    ],
                    text: '23%',
                },
                typeOfBlock: 'doughnut',
            },

            {
                i: '10',
                x: 0,
                y: 5,
                w: 2,
                h: 2,
                static: false,
                header: 'screenshot',
                data: 'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/mac_apps/safari/macos-big-sur-safari-screenshot-portion.jpg',
                typeOfBlock: 'img',
            },
            {
                i: '11',
                x: 2,
                y: 5,
                w: 2,
                h: 2,
                static: false,
                header: 'screenshot',
                data: '<p>Hello, <u><em><b>world!</b></em></u></p>',
                typeOfBlock: 'input',
            },
            //{i: '+', x: 0, y: 7, w: 1, h: 1, add: true, static: true, typeOfBlock: 'add'},
        ],
    },
    {
        id: 2,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Clicks',
                data: 14525623,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Gross reach',
                data: 2261605,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Clicks',
                data: 58337,
                typeOfBlock: 'number',
            },
            {
                i: '4',
                x: 0,
                y: 1,
                w: 4,
                h: 2,
                static: false,
                data: {
                    labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

                    datasets: [
                        {
                            label: 'Clicks',
                            backgroundColor: '#DBDAFA',
                            hoverBorderColor: '#DBDAFA',
                            data: data3,
                        },

                        {
                            label: 'CTR',
                            backgroundColor: '#FCDAC3',
                            hoverBorderColor: '#FCDAC3',
                            data: data4,
                        },
                    ],
                },
                header: 'Clicks & CRT%',
                typeOfBlock: 'bar',
            },
            {
                i: '5',
                x: 0,
                y: 3,
                w: 2,
                h: 2,
                static: false,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },

            {
                i: '7',
                x: 2,
                y: 3,
                w: 2,
                h: 2,
                static: false,
                header: 'Conversions platform',
                data: {
                    labels: ['Bonnier', 'Prog. Display'],
                    datasets: [
                        {
                            data: [50, 70],
                            backgroundColor: ['#C3FCE0', '#6FDB9D'],
                            hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
                        },
                    ],
                    text: '23%',
                },
                typeOfBlock: 'doughnut',
            },

            //{i: '+', x: 0, y: 5, w: 1, h: 1, add: true, static: true, typeOfBlock: 'add'},
        ],
    },
    {
        id: 3,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                static: false,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Clicks',
                data: 25063463,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Gross reach',
                data: 3537395,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: false,
                header: 'Clicks',
                data: 87235,
                typeOfBlock: 'number',
            },

            //{i: '+', x: 1, y: 1, w: 1, h: 1, add: false, static: false, typeOfBlock: 'add'},
        ],
    },
];

// export const initialFakeData = [
//     {
//         id: 1,
//         layout: [
//             {
//                 i: '+',
//                 x: 1,
//                 y: 1,
//                 w: 1,
//                 h: 1,
//                 add: true,
//                 static: true,
//             },
//             {i: '0', x: 0, y: 0, w: 1, h: 1, static: true},
//             {i: '1', x: 1, y: 0, w: 1, h: 1, static: true},
//             {i: '2', x: 2, y: 0, w: 1, h: 1, static: true},
//             {i: '3', x: 3, y: 0, w: 1, h: 1, static: true},
//         ],
//         data: [
//             {typeOfBlock: 'add'},
//             {
//                 header: 'Estimated TMP and reach',
//                 data: ['Target Group: A30-59', 'KPI: Reach & awareness', 'Estimated imps: 10 2017 211'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 header: 'Total Impressions',
//                 data: 28087409,
//                 typeOfBlock: 'number',
//             },
//             {
//                 header: 'Gross reach',
//                 data: 4717605,
//                 typeOfBlock: 'number',
//             },
//             {
//                 header: 'Clicks',
//                 data: 105337,
//                 typeOfBlock: 'number',
//             },
//         ],
//     },
//     {
//         id: 2,
//         layout: [
//             {
//                 i: '+',
//                 x: 1,
//                 y: 1,
//                 w: 1,
//                 h: 1,
//                 add: true,
//                 static: true,
//             },
//             {i: '0', x: 0, y: 0, w: 1, h: 1, static: true},
//             {i: '1', x: 1, y: 0, w: 1, h: 1, static: true},
//             {i: '2', x: 2, y: 0, w: 1, h: 1, static: true},
//             {i: '3', x: 3, y: 0, w: 1, h: 1, static: true},
//         ],
//         data: [
//             {typeOfBlock: 'add'},
//             {
//                 header: 'Estimated TMP and reach',
//                 data: ['Target Group: A30-59', 'KPI: Reach & awareness', 'Estimated imps: 10 2017 211'],
//                 typeOfBlock: 'list',
//             },
//             {
//                 header: 'Total Impressions',
//                 data: 28087409,
//                 typeOfBlock: 'number',
//             },
//             {
//                 header: 'Gross reach',
//                 data: 4717605,
//                 typeOfBlock: 'number',
//             },
//             {
//                 header: 'Clicks',
//                 data: 105337,
//                 typeOfBlock: 'number',
//             },
//         ],
//     },
// ];
