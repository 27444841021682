import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from "../services/userRegister";
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';

class CreateAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            name: "",
            email: "",
            manager: { id: 1, name: "No", value: false },
            agency: { id: 0, name: "Select", value: null },
            roles: [
                { id: 1, name: "Admin", value: "admin" },
                { id: 2, name: "Manager", value: "manager" },
                { id: 3, name: "Regular", value: "regular" },
                { id: 4, name: "Sales", value: "sales" }
            ],
            role: { id: 1, name: "Regular", value: "regular" },
            loading: false,
            clients: [],
            throttling: {},
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
        };
    }

    componentDidMount() {
        if (this.props.admin) {
            this.functions.getClients(true, false, false);
        }    
        this.functions.user();
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        createAgent: async () => {
            await this.promisedSetState({ loading: true });
            let order = {
                name: this.state.name,
                email: this.state.email,
                agency: this.state.agency.id,
                manager: this.state.manager.value,
                role: this.state.role.value
            };
            try {
                let response = await this.calls.createAgent(order);
                this.props.onCreated();
            } catch (error) {
                console.log(error);
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        },
        getClients: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_clients_search: search,
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getAgencies();
                if (!paginaton) {
                    await this.promisedSetState({
                        clients: response.data.map((item) => { item.image = item.logo; return item }),
                        clients_total: response.meta.total
                    });
                } else {
                    this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                    await this.promisedSetState({
                        clients: this.state.clients,
                        clients_total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        }
    };

    calls = {
        getAgencies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencies?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "");
            return apiRegister.call(options, url);
        },
        createAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createAgent";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                {
                                    this.props.admin &&
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Agency"}
                                            selected={this.state.agency}
                                            options={this.state.clients}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_clients}
                                            loadingPagination={this.state.loading_clients_pagination}
                                            loadingSearch={this.state.loading_clients_search}
                                            total={this.state.clients_total}
                                            searchInput={true}
                                            placeholder={"..."}
                                            pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                            onChange={(value) => {
                                                this.setState({
                                                    agency: value
                                                })
                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_clients_pagination) {
                                                    await this.promisedSetState({
                                                        client_page: this.state.client_page + 1
                                                    });
                                                    this.functions.getClients(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                if (value && value === "") {
                                                    await this.promisedSetState({
                                                        client_search: "",
                                                        client_page: 1
                                                    });
                                                    this.functions.getClients(false, false, true);
                                                } else {
                                                    if (this.state.throttling.current) {
                                                        //SKIP
                                                    } else {
                                                        if (value && value === "") {
                                                            await this.promisedSetState({
                                                                client_search: value,
                                                                client_page: 1
                                                            });
                                                            this.functions.getClients(false, false, true);
                                                        } else {
                                                            this.state.throttling.current = true;
                                                            await this.promisedSetState({
                                                                client_search: value,
                                                                throttling: this.state.throttling
                                                            });
                                                            setTimeout(async () => {
                                                                this.state.throttling.current = false;
                                                                await this.promisedSetState({
                                                                    throttling: this.state.throttling
                                                                });
                                                                await this.promisedSetState({
                                                                    client_page: 1
                                                                });
                                                                this.functions.getClients(false, false, true);
                                                            }, 300);
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Name"}
                                        value={this.state.name}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                name: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Email"}
                                        value={this.state.email}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                email: value
                                            })
                                        }}
                                    />
                                </div>


                                {
                                    this.props.admin &&
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Role"}
                                            selected={this.state.role}
                                            options={this.state.roles}
                                            onChange={async (value) => {
                                                if (value.value === "manager") {
                                                    this.state.manager = { id: 1, name: "Yes", value: true }
                                                } else {
                                                    this.state.manager = { id: 2, name: "No", value: false }
                                                }
                                                await this.promisedSetState({
                                                    role: value,
                                                    manager: this.state.manager
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.props.admin &&
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Role"}
                                            selected={this.state.role}
                                            options={this.state.roles.filter((item) => {
                                                if (this.state.user && this.state.user.userRole === "admin") {
                                                    return true;
                                                } else {
                                                    return item.value !== "admin";
                                                }
                                            })}
                                            onChange={async (value) => {
                                                if (value.value === "manager") {
                                                    this.state.manager = { id: 1, name: "Yes", value: true }
                                                } else {
                                                    this.state.manager = { id: 2, name: "No", value: false }
                                                }
                                                await this.promisedSetState({
                                                    role: value,
                                                    manager: this.state.manager
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateAgent;