import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon, ReplyIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import TextAreaTailwind from './textAreaTailwind';


import { adgroupLinkedin } from '../validators/adgroupLinkedin';
import SwitchTailwind from './switchTailwind';

class EditLiveLinkedinAdgroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            adgroup: {},
            updated: {},
            client: {},
            loader: false,
            loading_status: false,
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 2, name: "Locations", value: "location" },
                { id: 3, name: "Audience", value: "audience" },
                { id: 4, name: "Placements", value: "placements" },
            ],
            audience_network: { id: 1, name: "Yes", value: true },
            iab_categories_options: [],
            loading_iab_categories: false,
            iab_category: { id: 0, name: "Click here", value: "" },
            status_values: [
                { id: 1, name: 'Active', value: "ACTIVE" },
                { id: 2, name: 'Paused', value: "PAUSED" },
                { id: 3, name: 'Deleted', value: "REMOVED" }
            ],
            adgroup_types: [
                { id: 1, name: "Single image ad", value: "STANDARD_UPDATE" },
                { id: 2, name: 'Video ad', value: "SINGLE_VIDEO" },
                { id: 3, name: "Carousel image ad", value: "CAROUSEL" }
            ],
            objectives: [
                {
                    name: "Website visits",
                    value: "WEBSITE_VISIT",
                    optimization_goals: [
                        {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPM",
                                    set_cost: true
                                }
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Engagement",
                    value: "ENGAGEMENT",
                    optimization_goals: [
                        {
                            name: "Engagement clicks (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPC",
                                    set_cost: true
                                }
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Video views",
                    value: "VIDEO_VIEW",
                    optimization_goals: [
                        {
                            name: "Video views",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_VIDEO_VIEW",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Target cost per video view",
                                    value: "TARGET_COST_PER_VIDEO_VIEW",
                                    cost_type: "CPV",
                                    set_cost: true
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ],
                        }
                    ]
                },
                {
                    name: "Lead generation",
                    value: "LEAD_GENERATION",
                    optimization_goals: [
                        {
                            name: "Leads (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_LEAD",
                                    cost_type: "CPC",
                                    set_cost: false
                                }
                            ]
                        },
                        {
                            name: "Click",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Website conversions",
                    value: "WEBSITE_CONVERSION",
                    optimization_goals: [
                        {
                            name: "Website conversion (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_CONVERSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        {
                            name: "Landing page click",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Job applicants",
                    value: "JOB_APPLICANT",
                    optimization_goals: [
                        {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Manual bidding",
                                    value: "NONE",
                                    cost_type: "CPC",
                                    set_cost: true
                                },
                            ],
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Manual bidding",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Brand awareness",
                    value: "BRAND_AWARENESS",
                    optimization_goals: [
                        {
                            name: "Reach (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_REACH",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        {
                            name: "Impressions",
                            bidding_strategies: [
                                {
                                    name: "Max Delivery (recommended)",
                                    value: "MAX_IMPRESSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_IMPRESSION",
                                    cost_type: "CPM",
                                    set_cost: true
                                },
                            ]
                        }
                    ]
                },

            ],
            targeting_options: [],
            search_targeting_options: [],
            targeting_type: { id: 1, name: "Included", value: true },
            location_type: { id: 1, name: "Included", value: true },
            location_options: [],
            age: [],
            age_options: [
                {
                    id: 1,
                    name: "All ages",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "ALL",
                    included: true
                },
                {
                    id: 2,
                    name: "18 to 24",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(18,24)",
                    included: false
                },
                {
                    id: 3,
                    name: "25 to 34",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(25,34)",
                    included: false
                },
                {
                    id: 4,
                    name: "35 to 54",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(35,54)",
                    included: false
                },
                {
                    id: 5,
                    name: "55+",
                    facetUrn: "urn:li:adTargetingFacet:ageRanges",
                    value: "urn:li:ageRange:(55,2147483647)",
                    included: false
                }
            ],
            gender_options: [
                {
                    id: 1,
                    name: "All gender",
                    included: true
                },
                {
                    id: 2,
                    name: "Female",
                    facetUrn: "urn:li:adTargetingFacet:genders",
                    value: "urn:li:gender:FEMALE",
                    included: false
                },
                {
                    id: 3,
                    name: "Male",
                    facetUrn: "urn:li:adTargetingFacet:genders",
                    value: "urn:li:gender:MALE",
                    included: false
                },
            ],
            targeting_types: [
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company follower of",
                    value: "followedCompanies",
                    URN: "urn:li:adTargetingFacet:followedCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company names",
                    value: "employers",
                    URN: "urn:li:adTargetingFacet:employers",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company Connections",
                    value: "firstDegreeConnectionCompanies",
                    URN: "urn:li:adTargetingFacet:firstDegreeConnectionCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company industries",
                    value: "industries",
                    URN: "urn:li:adTargetingFacet:industries",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company growth rate",
                    value: "growthRate",
                    URN: "urn:li:adTargetingFacet:growthRate",
                    TYPEAHEAD: false
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company categories",
                    value: "companyCategory",
                    URN: "urn:li:adTargetingFacet:companyCategory",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company size",
                    value: "staffCountRanges",
                    URN: "urn:li:adTargetingFacet:staffCountRanges",
                    TYPEAHEAD: false
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education Degrees",
                    value: "degrees",
                    URN: "urn:li:adTargetingFacet:degrees",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education fields of study",
                    value: "fieldsOfStudy",
                    URN: "urn:li:adTargetingFacet:fieldsOfStudy",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education member schools",
                    value: "schools",
                    URN: "urn:li:adTargetingFacet:schools",
                    TYPEAHEAD: true
                },

                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job experience functions",
                    value: "jobFunctions",
                    URN: "urn:li:adTargetingFacet:jobFunctions",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job titles",
                    value: "titlesPast",
                    URN: "urn:li:adTargetingFacet:titlesPast",
                    TYPEAHEAD: true
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job seniorities",
                    value: "seniorities",
                    URN: "urn:li:adTargetingFacet:seniorities",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Member skills",
                    value: "skills",
                    URN: "urn:li:adTargetingFacet:skills",
                    TYPEAHEAD: true
                },
                { disabled: true, name: "staffCountRanges", URN: "urn:li:adTargetingFacet:staffCountRanges", TYPEAHEAD: false },
                { disabled: true, name: "titles", URN: "urn:li:adTargetingFacet:titles", TYPEAHEAD: true },
                { disabled: true, name: "groups", URN: "urn:li:adTargetingFacet:groups", TYPEAHEAD: true },
                { disabled: true, name: "employersPast", URN: "urn:li:adTargetingFacet:employersPast", TYPEAHEAD: true },
                { disabled: true, name: "yearsOfExperienceRanges", URN: "urn:li:adTargetingFacet:yearsOfExperienceRanges", TYPEAHEAD: false },
                { disabled: true, name: "titlesAll", URN: "urn:li:adTargetingFacet:titlesAll", TYPEAHEAD: true },
                { disabled: true, name: "Company names", URN: "urn:li:adTargetingFacet:employers", TYPEAHEAD: true },
                { disabled: true, name: "employersAll", URN: "urn:li:adTargetingFacet:employersAll", TYPEAHEAD: true },
                { disabled: true, name: "interfaceLocales", URN: "urn:li:adTargetingFacet:interfaceLocales", TYPEAHEAD: false },
                { disabled: true, name: "interests", URN: "urn:li:adTargetingFacet:interests", TYPEAHEAD: true },
                { disabled: true, name: "memberBehaviors", URN: "urn:li:adTargetingFacet:memberBehaviors", TYPEAHEAD: true },
            ],
            company_size_options: [
                {
                    id: 1,
                    name: "Myself Only",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(1,1)",
                    urn: "urn:li:staffCountRange:(1,1)",
                    included: false
                },
                {
                    id: 2,
                    name: "2-10 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(2,10)",
                    urn: "urn:li:staffCountRange:(2,10)",
                    included: false
                },
                {
                    id: 3,
                    name: "11-50 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(11,50)",
                    urn: "urn:li:staffCountRange:(11,50)",
                    included: false
                },
                {
                    id: 4,
                    name: "51-200 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(51,200)",
                    urn: "urn:li:staffCountRange:(51,200)",
                    included: false
                },
                {
                    id: 5,
                    name: "201-500 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(201,500)",
                    urn: "urn:li:staffCountRange:(201,500)",
                    included: false
                },
                {
                    id: 6,
                    name: "501-1000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(501,1000)",
                    urn: "urn:li:staffCountRange:(501,1000)",
                    included: false
                },
                {
                    id: 7,
                    name: "1001-5000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(1001,5000)",
                    urn: "urn:li:staffCountRange:(1001,5000)",
                    included: false
                },
                {
                    id: 8,
                    name: "5001-10,000 employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(5001,10000)",
                    urn: "urn:li:staffCountRange:(5001,10000)",
                    included: false
                },
                {
                    id: 9,
                    name: "10,001+ employees",
                    facetUrn: "urn:li:adTargetingFacet:staffCountRanges",
                    value: "urn:li:staffCountRange:(10001,2147483647)",
                    urn: "urn:li:staffCountRange:(10001,2147483647)",
                    included: false
                }

            ],
            locked: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked ? this.props.locked : false
        })
        this.functions.gender();
        this.functions.ages();
        this.functions.listIABCategories();
        console.log(this.state.adgroup, "adgroup");
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        //CRITERIA
                        let buffer = false;
                        if (["locations", "gender", "audience", "age"].filter((item) => { return this.state.updated[item] }).length > 0) {
                            buffer = {
                                targetingCriteria: {
                                    include: {
                                        and: []
                                    },
                                    exclude: {
                                        or: {}
                                    }
                                }
                            };
                            buffer.targetingCriteria.include.and = [{ "or": { "urn:li:adTargetingFacet:locations": this.renders.locations().filter((item) => { return item.included }).map((item) => { return item.urn }) } }];
                            buffer.targetingCriteria.exclude.or["urn:li:adTargetingFacet:locations"] = this.renders.locations().filter((item) => { return !item.included }).map((item) => { return item.urn });

                            if (this.renders.gender().id !== 1) {
                                let buff = "urn:li:adTargetingFacet:genders";
                                let or = { "or": {} };
                                or.or[buff] = [this.renders.gender().id == 2 ? "urn:li:gender:FEMALE" : "urn:li:gender:MALE"]
                                buffer.targetingCriteria.include.and.push(or);
                            }
                            if (this.renders.language().id !== 0) {
                                let buff = "urn:li:adTargetingFacet:interfaceLocales";
                                let or = { "or": {} };
                                or.or[buff] = [this.renders.language().urn]
                                buffer.targetingCriteria.include.and.push(or);
                            }
                            if (Array.isArray(this.renders.audience()) && this.renders.audience().length > 0) {
                                this.renders.audience().filter((item) => { return item }).map((item) => {
                                    if (Array.isArray(item) && item.filter((inner_item) => { return inner_item.included }).length > 0) {
                                        let buff = "";
                                        let or = { "or": {} };
                                        item.filter((inner_item) => { return inner_item.included }).map((inner_item) => {
                                            if (buff !== inner_item.facetUrn) {
                                                buff = inner_item.facetUrn;
                                            }
                                            if (!or.or[buff]) {
                                                or.or[buff] = [];
                                            }
                                            or.or[buff].push(item.filter((inner_inner_item) => { return inner_inner_item.id === inner_item.id })[0].urn);
                                        })
                                        buffer.targetingCriteria.include.and.push(or);
                                    }

                                    if (Array.isArray(item) && item.filter((inner_item) => { return !inner_item.included }).length > 0) {
                                        item.filter((inner_item) => { return !inner_item.included }).map((inner_item) => {
                                            if (buffer.targetingCriteria.exclude.or[inner_item.facetUrn] && !buffer.targetingCriteria.exclude.or[inner_item.facetUrn].includes(inner_item.urn)) {
                                                buffer.targetingCriteria.exclude.or[inner_item.facetUrn].push(inner_item.urn)
                                            } else if (!buffer.targetingCriteria.exclude.or[inner_item.facetUrn]) {
                                                buffer.targetingCriteria.exclude.or[inner_item.facetUrn] = [inner_item.urn]
                                            }
                                        })
                                    }
                                })
                            }
                            if (this.renders.age().length > 0 && this.renders.age().filter((item) => { return item.included }).length > 0 && this.renders.age().filter((item) => { return item.id === 1 }).length === 0) {
                                let buff = "";
                                let or = { "or": {} };
                                this.renders.age().map((item) => {
                                    if (buff !== item.facetUrn) {
                                        buff = item.facetUrn;
                                        or.or[buff] = this.renders.age().filter((item) => { return item.included }).filter((inner_item) => { return inner_item.facetUrn === item.facetUrn }).map((item) => { return item.value });
                                    }
                                })
                                buffer.targetingCriteria.include.and.push(or);
                            }

                            console.log(buffer, "buffer");
                        }


                        let body = {
                            "patch": {
                                "$set": {
                                    ...(this.state.updated.name) && { name: this.state.updated.name },
                                    ...(this.state.updated.daily_budget) && { dailyBudget: { "amount": this.state.updated.daily_budget, "currencyCode": this.state.adgroup.budget_daily.currencyCode } },
                                    ...(this.state.updated.total_budget) && { totalBudget: { "amount": this.state.updated.total_budget, "currencyCode": this.state.adgroup.budget_total.currencyCode } },
                                    ...(this.state.updated.target_cost) && { unitCost: { "amount": this.state.updated.target_cost, "currencyCode": this.state.adgroup.unitCost.currencyCode } },

                                    ...(this.state.updated.start_date || this.state.updated.end_date || this.state.updated.end_date === null) && {
                                        runSchedule: {
                                            start: (this.state.updated.start_date) ? new Date(this.state.updated.start_date).getTime() : this.state.adgroup.runSchedule.start,
                                            //start: new Date(this.renders.startDate()).getTime(),
                                            ...(this.state.updated.end_date) && { end: new Date(this.renders.endDate()).getTime() }
                                        }
                                    },

                                    ...(this.state.updated.audience_network) && { offsiteDeliveryEnabled: this.state.updated.audience_network.value },
                                    ...(this.state.updated.selected_iab_categories) && { offsitePreferences: { "iabCategories": { "exclude": this.state.updated.selected_iab_categories.map((item) => { return "urn:li:iabCategory:" + item.id }) } } },
                                    ...(buffer) && { targetingCriteria: buffer.targetingCriteria }
                                },
                                ...(this.state.updated.adgroup_budget && this.state.updated.adgroup_budget.id === 1) && { "$delete": ["totalBudget"] },
                                ...(this.state.updated.adgroup_budget && this.state.updated.adgroup_budget.id === 2) && { "$delete": ["dailyBudget"] },

                            }
                        }
                        body.changelog = {
                            initial_data: this.state.adgroup,
                            updated_data: this.state.updated,
                            level: "adgroup",
                            order: window.location.pathname.split("/").pop()
                        }
                        try {
                            let response = await this.calls.updateLiveAdgroup(body);

                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseAdgroup(true);
                        } else {
                            this.props.onActivateAdgroup(true);
                        }
                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });
                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        ages: async () => {
            try {
                if (this.state.adgroup.age && this.state.adgroup.age.length > 0) {
                    this.state.age_options = this.state.age_options.map((item) => {
                        if (this.state.adgroup.age.filter((age) => { return age.id === item.id && age.included }).length > 0) {
                            item.included = true;
                        } else {
                            item.included = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ age_options: this.state.age_options });
                }
            } catch (error) {

            }
        },
        gender: async () => {
            try {
                if (this.state.adgroup.gender) {
                    this.state.gender_options = this.state.gender_options.map((item) => {
                        if (item.value !== this.state.adgroup.gender.value) {
                            item.included = false;
                        } else {
                            item.included = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ gender_options: this.state.gender_options });
                }
            } catch (error) {

            }
        },
        listIABCategories: async () => {
            try {
                this.promisedSetState({ loading_iab_categories: true });
                let response = await this.calls.listIABCategories();
                this.promisedSetState({ loading_iab_categories: false, iab_categories_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    iab_categories_options: []
                })
            }
        },
        searchLocations: async () => {
            try {
                this.promisedSetState({ loading_locations: true });
                let response = await this.calls.locations();
                this.promisedSetState({ loading_locations: false, location_options: response.data[0] });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        searchTargeting: async () => {
            try {
                this.promisedSetState({ loading_targeting: true });
                let response = await this.calls.targeting();
                if (this.renders.audience().length > 0 && this.renders.audience().slice(-1)[0] !== null) {
                    this.renders.audience().slice(-1).map((item) => {
                        response.data = response.data.filter((buff) => { return buff.urn !== item.urn })
                    })
                }

                await this.promisedSetState({ loading_targeting: false, targeting_options: response.data });
            } catch (error) {
                this.promisedSetState({
                    targeting_options: [],
                    loading_targeting: false
                })
            }
        }

    };

    calls = {
        updateLiveAdgroup: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveAdgroup?client=" + this.state.client.id + "&adgroup=" + this.state.adgroup.id + "&channel=linkedin";
            return apiRegister.call(options, url);
        },
        locations: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        },
        listIABCategories: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listIABCategories?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        targeting: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listTargeting?client=" + this.state.client.id + (this.state.updated.selected_type.TYPEAHEAD ? "&search=" + this.state.search_targeting : "") + "&urn=" + this.state.updated.selected_type.URN;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adgroup.name) {
                    return this.state.adgroup.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        format: () => {
            try {
                if (this.state.adgroup.format) {
                    return this.state.adgroup_types.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.format.toLowerCase() })[0].name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        objective: () => {
            try {
                if (this.state.adgroup.objective) {
                    return this.state.adgroup.objective.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        optimization_goal: () => {
            try {
                if (this.state.adgroup.optimization_goal) {
                    return this.state.adgroup.optimization_goal;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        bidding_strategy: () => {
            try {
                if (this.state.adgroup.bidding_strategy) {
                    return this.state.adgroup.bidding_strategy;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },

        budgetMode: () => {
            try {
                if ('adgroup_budget' in this.state.updated) {
                    return this.state.updated.adgroup_budget;
                } else if (this.state.adgroup.budget_daily && this.state.adgroup.budget_total) {
                    return { id: 3, name: 'Daily and Total budget', value: "daily_total" }
                } else if (this.state.adgroup.budget_daily) {
                    return { id: 1, name: 'Daily budget', value: "daily" };
                } else if (this.state.adgroup.budget_total) {
                    return { id: 2, name: 'Total budget', value: "total" }
                } else {
                    return { id: 0, name: '...', value: "PAUSED" };
                }
            } catch (error) {
                return { id: 0, name: '...', value: "PAUSED" }
            }
        },
        budgetDaily: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.state.adgroup.budget_daily) {
                    return this.state.adgroup.budget_daily.amount;
                } else {
                    return 0
                }
            } catch (error) {
                return 0
            }
        },
        budgetTotal: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else if (this.state.adgroup.budget_total) {
                    return this.state.adgroup.budget_total.amount;
                } else {
                    return 0
                }
            } catch (error) {
                return 0
            }
        },
        targetCost: () => {
            try {
                if ('target_cost' in this.state.updated) {
                    return this.state.updated.target_cost;
                } else if (this.state.adgroup.unitCost) {
                    return this.state.adgroup.unitCost.amount;
                } else {
                    return 0
                }
            } catch (error) {
                return 0
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else if (this.state.adgroup.start) {
                    return this.state.adgroup.start;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else if (this.state.adgroup.end) {
                    return this.state.adgroup.end;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        locations: () => {
            try {
                if ('locations' in this.state.updated) {
                    return this.state.updated.locations;
                } else {
                    return this.state.adgroup.locations;
                }
            } catch (error) {
                return "";
            }
        },
        audience_network: () => {
            try {
                if ('audience_network' in this.state.updated) {
                    return this.state.updated.audience_network;
                } else if (this.state.adgroup.audience_network) {
                    return this.state.adgroup.audience_network;
                } else {
                    return this.state.audience_network;
                }
            } catch (error) {
                return null;
            }
        },
        selected_iab_categories: () => {
            try {
                if ('selected_iab_categories' in this.state.updated) {
                    return this.state.updated.selected_iab_categories;
                } else if (this.state.adgroup.selected_iab_categories) {
                    return this.state.adgroup.selected_iab_categories;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        audience: () => {
            try {
                if ('audience' in this.state.updated) {
                    return this.state.updated.audience;
                } else {
                    return this.state.adgroup.audience;
                }
            } catch (error) {
                return "";
            }
        },
        age: () => {
            try {
                if ('age' in this.state.updated) {
                    return this.state.updated.age;
                } else {
                    return this.state.adgroup.age;
                }
            } catch (error) {
                return [];
            }
        },
        language: () => {
            try {
                if ('language' in this.state.updated) {
                    return this.state.updated.language;
                } else {
                    return this.state.adgroup.language;
                }
            } catch (error) {
                return { id: 0, name: "Error handle" };
            }
        },
        gender: () => {
            try {
                if ('gender' in this.state.updated) {
                    return this.state.updated.gender;
                } else if (this.state.adgroup.gender) {
                    return this.state.adgroup.gender;
                } else {
                    return { id: 1, name: "All gender", included: false };
                }
            } catch (error) {
                return "";
            }
        },
        selected_type: () => {
            try {
                if ('selected_type' in this.state.updated) {
                    return this.state.updated.selected_type;
                } else {
                    return { name: "Select type" };
                }
            } catch (error) {
                return "";
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && parseInt(this.renders.budgetDaily()) < 1) ||
                                                    (tab.id === 1 && adgroupLinkedin.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start).unix() && { status: this.renders.status() }) }).start_date) ||
                                                    (tab.id === 2 && adgroupLinkedin.validate({ locations: this.renders.locations() }).location) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id == 1 &&
                        <>

                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Adgroup name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Allowed Ad format"}
                                    value={this.renders.format()}
                                    locked={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Objective"}
                                    value={this.renders.objective()}
                                    locked={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputTailwind
                                    label={"Optimizaton goal"}
                                    value={this.renders.optimization_goal().name}
                                    locked={true}
                                />
                            </div>
                            <div className={(this.renders.bidding_strategy().set_cost ? "col-span-3" : "col-span-6")}>
                                <InputTailwind
                                    label={"Bidding strategy"}
                                    value={this.renders.bidding_strategy().name}
                                    locked={true}
                                />
                            </div>
                            {
                                this.renders.bidding_strategy().set_cost &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={(this.state.client.currency && this.state.client.currency.linkedin ? true : false)}
                                        leftSectionLabel={(this.state.client.currency && this.state.client.currency.linkedin ? this.state.client.currency.linkedin : "")}
                                        label={"Target cost (unit cost)"}
                                        value={this.renders.targetCost()}
                                        onChange={(value) => {
                                            this.state.updated.target_cost = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Budget"}
                                    selected={this.renders.budgetMode()}
                                    options={[
                                        { id: 1, name: 'Daily budget', value: "daily" },
                                        { id: 2, name: 'Total budget', value: "total" },
                                        { id: 3, name: 'Daily and Total budget', value: "daily_total" }
                                    ]}
                                    onChange={(value) => {
                                        this.state.updated.adgroup_budget = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    locked={true}
                                />
                            </div>

                            <div className="col-span-3 grid grid-cols-6 col-gap-4">
                                {
                                    (this.renders.budgetMode().id === 1 || this.renders.budgetMode().id === 3) &&
                                    <div className={(this.renders.budgetMode().id === 1 ? "col-span-6" : "col-span-3")}>
                                        <InputTailwind
                                            error={parseInt(this.renders.budgetDaily()) < 1}
                                            leftSection={(this.state.client.currency && this.state.client.currency.linkedin ? true : false)}
                                            leftSectionLabel={(this.state.client.currency && this.state.client.currency.linkedin ? this.state.client.currency.linkedin : "")}
                                            type={"number"}
                                            label={"Daily budget"}
                                            value={this.renders.budgetDaily()}
                                            onChange={(value) => {
                                                this.state.updated.daily_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })

                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (this.renders.budgetMode().id === 2 || this.renders.budgetMode().id === 3) &&
                                    <div className={(this.renders.budgetMode().id === 2 ? "col-span-6" : "col-span-3")}>
                                        <InputTailwind
                                            leftSection={(this.state.client.currency && this.state.client.currency.linkedin ? true : false)}
                                            leftSectionLabel={(this.state.client.currency && this.state.client.currency.linkedin ? this.state.client.currency.linkedin : "")}
                                            type={"number"}
                                            label={"Total budget"}
                                            value={this.renders.budgetTotal()}
                                            onChange={(value) => {
                                                this.state.updated.total_budget = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        error={adgroupLinkedin.validate({ start_date: this.renders.startDate(), ...(moment().unix() > moment(this.state.adgroup.start).unix() && { status: this.renders.status() }) }).start_date}
                                        disabled={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                        locked={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                        placeholder={(moment().unix() > moment(this.renders.startDate()).unix() ? moment(this.renders.startDate()).format("MM/DD/YYYY") : "")}
                                        label={"Start date"}
                                        value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        label={"End date"}
                                        disabled={!this.renders.endDate()}
                                        labelRight={this.renders.budgetMode().id == 2 ? false : (this.renders.endDate() ? "Disable" : "Enable")}
                                        placeholder={!this.renders.endDate() ? "No end date" : ""}
                                        value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                        onChange={async (value) => {
                                            this.state.updated.end_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        onLabelRightClick={async (value) => {
                                            if (this.renders.endDate()) {
                                                this.state.updated.end_date = null;
                                            } else {
                                                let new_date = moment(this.renders.startDate()).add(1, 'days').format("YYYY-MM-DD");
                                                this.state.updated.end_date = moment(new_date);
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ACTIVE" ? true : false}
                                            disabled={this.state.locked}
                                            onSwitch={async () => {
                                                if (!this.state.locked) {
                                                    if (this.renders.status().value === "ACTIVE") {
                                                        this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                                    } else if (this.renders.status().value === "PAUSED") {
                                                        this.functions.update({ id: 1, name: 'Active', value: "ACTIVE" });
                                                    }
                                                }

                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </>
                    }


                    {/*Location*/}
                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Locations"}
                                    rightLabel={true}
                                    rightLabelText={this.state.location_type.value ? "Included" : "Excluded"}
                                    onRightLabelClick={async () => {
                                        await this.promisedSetState({ location_type: !this.state.location_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                    }}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    loadingSearch={this.state.loading_locations}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    onChange={(value) => {
                                        let location = null;
                                        if (!this.state.location_type || (this.state.location_type && this.state.location_type.id == 1)) {
                                            value.included = true;
                                        } else {
                                            value.included = false;
                                        }
                                        location = value;
                                        location.id = Math.floor((Math.random() * 9999999999) + 1);
                                        if (!Array.isArray(this.state.updated.locations)) {
                                            this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations()));
                                        }
                                        this.state.updated.locations.unshift(location);
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        this.functions.searchLocations();
                                    }}
                                />
                            </div>
                            {
                                this.renders.locations().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.locations().sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.renders.locations().filter((item) => { return item.included }).length;
                                            let excluded_amount = this.renders.locations().filter((item) => { return !item.included }).length;
                                            return (
                                                <Fragment>
                                                    {
                                                        index == 0 && included_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Included
                                                        </div>
                                                    }
                                                    {
                                                        (included_amount == index) && excluded_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Excluded
                                                        </div>
                                                    }
                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <LocationMarkerIcon onClick={async () => {

                                                        }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1"} aria-hidden="true" />
                                                        {
                                                            !item.custom &&
                                                            <div className="flex flex-1">{item.name}</div>
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.updated.locations) {
                                                                    this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations()));
                                                                }
                                                                this.state.updated.locations = this.state.updated.locations.filter((location) => {
                                                                    return location.id !== item.id;
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                    }

                    {/*Audience targeting*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Language"}
                                    selected={this.renders.language()}
                                    locked={true}
                                    options={[]}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>


                            <div className="col-span-6">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Gender
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.gender_options.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    item.included = true;
                                                    this.state.updated.gender = item;
                                                    this.state.gender_options = this.state.gender_options.map((inner_item) => {
                                                        if (inner_item.id !== item.id) {
                                                            inner_item.included = false;
                                                        } else {
                                                            inner_item.included = true;
                                                        }
                                                        return inner_item;
                                                    })
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        gender_options: this.state.gender_options
                                                    })
                                                    //this.functions.estimateAudience();

                                                }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="col-span-6">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Ages
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.age_options.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    //item.selected = !item.selected;
                                                    let buff = !item.included;

                                                    if (item.id === 1) {
                                                        this.state.updated.age = [item];
                                                        this.state.age_options = this.state.age_options.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.included = false;
                                                            } else {
                                                                inner_item.included = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                    } else {
                                                        if (!Array.isArray(this.state.updated.ages)) {
                                                            this.state.updated.age = [];
                                                        }
                                                        this.state.age_options = this.state.age_options.map((inner_item) => {
                                                            if (inner_item.id === 1) {
                                                                inner_item.included = false;
                                                            } else if (inner_item.id === item.id) {
                                                                if (this.state.age_options.filter((inner_item) => { return inner_item.included; }).length === 1 && !buff) {
                                                                } else {
                                                                    inner_item.included = buff;
                                                                }
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.state.updated.age = this.state.age_options.filter((inner_item) => {
                                                            return inner_item.id !== 1;
                                                        })
                                                        //this.state.updated.ages.push(item);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        age_options: this.state.age_options
                                                    })
                                                    //this.functions.estimateAudience();

                                                }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="text-2xl flex-1 flex leading-6 font-semibold text-gray-900">
                                    Audience
                                </div>
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Type"}
                                    searchInput={true}
                                    selected={this.renders.selected_type()}
                                    options={this.state.targeting_types.filter((item) => { return !item.disabled }).map((item) => {
                                        item.id = item.value;
                                        return item;
                                    })}
                                    onChange={async (value) => {
                                        this.state.updated.selected_type = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated,
                                            search_targeting: "",
                                            targeting_options: []
                                        });
                                        if (value.value === "staffCountRanges") {
                                            await this.promisedSetState({
                                                targeting_options: this.state.company_size_options
                                            });
                                        }
                                        this.refs.searchDropdown.functions.resetSearch();
                                        if (!value.TYPEAHEAD && value.value !== "staffCountRanges") {
                                            this.functions.searchTargeting();
                                        }
                                    }}
                                />
                            </div>

                            {
                                this.state.updated.selected_type &&
                                <div className="col-span-6">
                                    <div className='flex'>
                                        <div className='mx-4 self-center'>
                                            <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                        </div>
                                        <div className='flex-col flex-1'>
                                            <DropdownTailwind
                                                ref="searchDropdown"
                                                rightLabel={true}
                                                rightLabelText={this.state.targeting_type.value ? "Included" : "Excluded"}
                                                onRightLabelClick={async () => {
                                                    await this.promisedSetState({ targeting_type: !this.state.targeting_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                                }}
                                                label={"Audience"}
                                                searchInput={true}
                                                loader={this.state.loading_targeting}
                                                selected={{ name: "Search ..." }}
                                                options={Array.isArray(this.state.targeting_options) ? this.state.targeting_options : []}
                                                loadingSearch={this.state.loading_targeting}
                                                onChange={(value) => {
                                                    let audience = null;
                                                    if (!this.state.targeting_type || (this.state.targeting_type && this.state.targeting_type.id == 1)) {
                                                        value.included = true;
                                                    } else {
                                                        value.included = false;
                                                    }
                                                    audience = value;
                                                    audience.id = Math.floor((Math.random() * 9999999999) + 1);
                                                    audience.parent = this.state.updated.selected_type.name;
                                                    if (!Array.isArray(this.state.updated.audience) || this.state.updated.audience.length === 0) {
                                                        this.state.updated.audience = JSON.parse(JSON.stringify(this.renders.audience()));
                                                        this.state.updated.audience.push([]);
                                                    }
                                                    if (this.state.updated.audience[this.state.updated.audience.length - 1].filter((item) => { return item.urn === audience.urn }).length === 0) {
                                                        this.state.updated.audience[this.state.updated.audience.length - 1].push(audience);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        targeting_options: this.state.targeting_options
                                                    });

                                                }}
                                                onSearch={async (value) => {
                                                    await this.promisedSetState({ search_targeting: value });
                                                    if (this.state.updated.selected_type.TYPEAHEAD) {
                                                        this.functions.searchTargeting();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                <div onClick={() => {

                                    if (!Array.isArray(this.state.updated.audience)) {
                                        this.state.updated.audience = JSON.parse(JSON.stringify(this.renders.audience()));
                                    }
                                    if (this.state.updated.audience.slice(-1)[0].length > 0) {
                                        this.state.updated.audience.push([]);
                                    }

                                    this.setState({
                                        updated: this.state.updated
                                    });

                                }} className="cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                    Add "AND" selector
                                </div>
                            }


                            {
                                this.renders.audience().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.audience().map((audience_group, audience_index) => {
                                            return (
                                                audience_group &&
                                                <div className="col-span-6 rounded-md">
                                                    {
                                                        //first
                                                        audience_index > 0 && audience_group &&
                                                        <div className=" text-base font-medium text-blue-700 mb-1 m-2">
                                                            AND
                                                        </div>
                                                    }
                                                    {
                                                        audience_group &&
                                                        audience_group.sort(function (x, y) {
                                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                                        }).map((item, index) => {
                                                            let included_amount = audience_group.filter((item) => { return item.included }).length;
                                                            let excluded_amount = audience_group.filter((item) => { return !item.included }).length;
                                                            return (
                                                                <Fragment>
                                                                    {
                                                                        index == 0 && included_amount > 0 &&
                                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                                            Included
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (included_amount == index) && excluded_amount > 0 &&
                                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                                            Excluded
                                                                        </div>
                                                                    }
                                                                    {
                                                                        ((index > 0 && index < included_amount) || (index > included_amount)) &&
                                                                        <div className="text-xs font-medium text-blue-500 mb-1">
                                                                            Or
                                                                        </div>
                                                                    }
                                                                    <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                                        <div className="flex flex-1">{item.parent + "->" + item.name}</div>
                                                                        <button
                                                                            onClick={() => {
                                                                                if (!Array.isArray(this.state.updated.audience)) {
                                                                                    this.state.updated.audience = JSON.parse(JSON.stringify(this.renders.audience()));
                                                                                }
                                                                                this.state.updated.audience[audience_index] = audience_group.filter((audience) => {
                                                                                    return audience.id !== item.id;
                                                                                })
                                                                                if (this.state.updated.audience[audience_index].length < 1) {
                                                                                    delete this.state.updated.audience[audience_index];
                                                                                }
                                                                                this.state.updated.audience = this.state.updated.audience.filter((audience) => { return audience });

                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove targeting</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })

                                    }
                                </div>
                            }
                        </>
                    }
                    {/*PLACEMENTS*/}
                    {
                        this.state.tab.id === 4 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>LinkedIn</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {"Yes"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>LinkedIn Audience Network</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.audience_network().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.audience_network().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.audience_network().value) {
                                                        this.state.updated.audience_network = { id: 2, name: "No", value: false };
                                                        this.state.updated.selected_iab_categories = [];

                                                    } else {
                                                        this.state.updated.audience_network = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.renders.audience_network().value &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={"Exclude categories from the LinkedIn Audience Network"}
                                                    searchInput={true}
                                                    selected={this.state.iab_category}
                                                    options={Array.isArray(this.state.iab_categories_options) && this.state.iab_categories_options.length > 0 ? this.state.iab_categories_options.filter(item => {
                                                        return !item.parentId
                                                    }) : []}
                                                    onChange={async (value) => {
                                                        this.setState({
                                                            iab_category: value
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className='flex justify-end ml-3 mt-5 items-center'>
                                                <button onClick={async () => {
                                                    if (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length < this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length) {
                                                        if (!Array.isArray(this.state.updated.selected_iab_categories)) {
                                                            this.state.updated.selected_iab_categories = JSON.parse(JSON.stringify(this.renders.selected_iab_categories()));
                                                        }
                                                        this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).map((item) => {
                                                            if (this.renders.selected_iab_categories().filter((inner_item) => { return inner_item.id === item.id }).length === 0) {
                                                                item.included = false;
                                                                this.state.updated.selected_iab_categories.unshift(item);
                                                            }
                                                        })
                                                        //this.state.updated.selected_lifestyles.unshift(value);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    } else if (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length) {
                                                        this.state.updated.selected_iab_categories = this.renders.selected_iab_categories().filter((item) => { return item.parentId !== this.state.iab_category.id })
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }} className={(this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length < this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "cursor-pointer bg-purple-500 hover:bg-purple-600 " : (this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "bg-red-500 hover:bg-red-600 focus:ring-red-500" : "bg-gray-500")) + "  text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-24 py-3 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}>
                                                    {this.state.iab_category.id !== 0 && this.renders.selected_iab_categories().filter((item) => { return item.parentId === this.state.iab_category.id }).length === this.state.iab_categories_options.filter((item) => { return item.parentId === this.state.iab_category.id }).length ? "Remove all" : "Add all"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.iab_category.id !== 0 &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='mx-4 self-center'>
                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={this.state.iab_category.name}
                                                    searchInput={true}
                                                    selected={{ id: 0, name: "Click here", value: "" }}
                                                    options={Array.isArray(this.state.iab_categories_options) && this.state.iab_categories_options.length > 0 ? this.state.iab_categories_options.filter(item => {
                                                        return item.parentId === this.state.iab_category.id
                                                    }) : []}
                                                    onChange={async (value) => {
                                                        value.included = false;
                                                        if (!Array.isArray(this.state.updated.selected_iab_categories)) {
                                                            this.state.updated.selected_iab_categories = [];
                                                        }
                                                        if (this.renders.selected_iab_categories().filter((item) => { return item.id === value.id }).length === 0) this.state.updated.selected_iab_categories.unshift(value);
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.renders.selected_iab_categories().length > 0 &&
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        <div className="col-span-6 text-xs font-medium text-gray-700 mb-1">
                                            Excluded categories
                                        </div>
                                        <div className="col-span-6 flex flex-row flex-wrap">

                                            {
                                                this.renders.selected_iab_categories().map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={"bg-red-100 text-red-700 flex m-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                                <div className="flex flex-1">{item.name}</div>
                                                                <button
                                                                    onClick={() => {
                                                                        this.state.updated.selected_iab_categories = this.renders.selected_iab_categories().filter((inner_item) => { return item.id !== inner_item.id })
                                                                        this.setState({
                                                                            updated: this.state.updated
                                                                        })
                                                                    }}
                                                                    type="button"
                                                                    className={"focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                >
                                                                    <span className="sr-only">Remove</span>
                                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                }
                            </div>
                        </>
                    }

                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveLinkedinAdgroup;
