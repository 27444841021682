import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import currencies from '../../assets/json/currencies';
import Dropdown from "../../components/dropdown";
import UploadImage from "../../components/image-upload";
import {calls} from "./calls";

class UserAgenciesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            company: "",
            logo: "",
            fee: {},
            loading: false,
            error: null,
            error_message: ""
        };
    };

    componentWillMount() {}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {};

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.createAgency({
                    name: this.state.name,
                    email: this.state.email,
                    company: this.state.company,
                    logo: this.state.logo,
                    fee: this.state.fee.value
                }).then((response) => {
                    this.props.history.push('/user/agencies/all');
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: error.body.name,
                        error_message: error.body.message
                    })
                });
            });
        }
    };

    renders = {
        fees: () => {
            let fees = [];
            for(let i = 0; i < 101; i++){
                fees.push({name: i + '%', value: i});
            }
            return fees;
        }
    };

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render(){
        return (
            <div className="Profile">
                <div className="Profile-Inner">
                    <AccordionStatic title={"Create new agency"}>
                        <div className="Container">
                            <div style={{textAlign: 'left'}} className="Section">
                                Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                                usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                                molestiae est.
                            </div>
                            <div className="Section">
                                <Input
                                    title={'Name'}
                                    value={this.state.name}
                                    error={this.state.error === "name" && this.state.name.length < 1}
                                    type={"text"}
                                    maxLength={40}
                                    onChange={(value) => this.setState({name: value.target.value}, () => {

                                    })}
                                />
                                <Input
                                    title={'Email'}
                                    value={this.state.email}
                                    error={this.state.error === "email" && !this.validators.email(this.state.email)}
                                    placeholder={""}
                                    type={"text"}
                                    onChange={(value) => this.setState({email: value.target.value}, () => {

                                    })}
                                />
                            </div>
                            <div className="Section">
                                <UploadImage
                                    imageOnly={true}
                                    title={"Upload logo (png)"}
                                    size={"full"}
                                    cover={false}
                                    logo={true}
                                    value={this.state.logo}
                                    error={this.state.error === "logo" && !this.state.logo}
                                    onChange={(url) => {
                                        this.setState({logo: url});
                                    }}
                                />
                            </div>
                            <div className="Section">
                                <Input
                                    title={'Company name'}
                                    value={this.state.company}
                                    size={'half'}
                                    error={this.state.error === "company" && this.state.company.length < 1}
                                    placeholder={""}
                                    type={"text"}
                                    onChange={(value) => this.setState({company: value.target.value}, () => {

                                    })}
                                />
                                <Dropdown
                                    filter={false}
                                    title={'System fee'}
                                    size={'half'}
                                    placeholder={"Select systemfee"}
                                    error={this.state.error === "fee" && !this.state.fee.name}
                                    options={this.renders.fees()}
                                    value={this.state.fee}
                                    onChange={(value) => {
                                        this.setState({fee: value});
                                    }}
                                />
                            </div>
                            {
                                this.state.error &&
                                <div className="Section SectionError">
                                    {this.state.error_message}
                                </div>
                            }
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.create();
                                }} className="ButtonFull Button">
                                    {
                                        !this.state.loading &&
                                        <div>Create</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionStatic>
                </div>
            </div>
        )
    }
}

export default UserAgenciesNew;
