import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import AdvertisingPreviewSearch from "../advertising-preview-search";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
var moment = require('moment');

class AdvertisingPreviewSocial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            edit: this.props.edit,
            site: this.props.site,
            targeting: this.props.targeting,
            creatives: this.props.creatives,
            channel: this.props.channel,
            client: this.props.client,
            selected: this.props.selected,
            type: this.props.type,
            language: {},
            campaign: {},
            locations: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            edit: nextProps.edit,
            open: nextProps.open,
            site: nextProps.site,
            targeting: nextProps.targeting,
            channels: nextProps.channels,
            channel: nextProps.channel,
            client: nextProps.client,
            creatives: nextProps.creatives,
            selected: nextProps.selected,
            type: nextProps.type,
            language: nextProps.language,
            campaign: nextProps.campaign,
            locations: Array.isArray(nextProps.locations) ? nextProps.locations : []
        });
    }

    componentDidMount() {
        this.setState({
            open: this.props.open,
            edit: this.props.edit,
            site: this.props.site,
            targeting: this.props.targeting,
            channel: this.props.channel,
            client: this.props.client,
            selected: this.props.selected,
            channels: this.props.channels,
            creatives: this.props.creatives,
            type: this.props.type,
            language: this.props.language,
            campaign: this.props.campaign,
            locations: Array.isArray(this.props.locations) ? this.props.locations : []
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div style={{ width: '100%' }}>
                {
                    (this.state.channel === 'facebook' || this.state.channel === 'linkedin') &&
                    <AdvertisingCreativesFacebook
                        creative={this.state.creatives}
                        variations={this.state.creatives.variations ? this.state.creatives.variations : {}}
                        site={this.state.site}
                        client={this.state.client}
                        preview={this.state.selected}
                        type={this.state.type}
                        campaign={this.state.campaign}
                        language={{}}
                        channel={this.state.channel}
                        locations={this.state.locations}
                    />
                }
                {
                    this.state.channel === 'instagram' &&
                    <AdvertisingCreativesInstagram
                        creative={this.state.creatives}
                        variations={this.state.creatives.variations ? this.state.creatives.variations : {}}
                        site={this.state.site}
                        client={this.state.client}
                        preview={this.state.selected}
                        type={this.state.type}
                        campaign={this.state.campaign}
                        language={{}}
                        locations={this.state.locations}
                    />
                }
            </div>
        )
    }
}

export default AdvertisingPreviewSocial;
