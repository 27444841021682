import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { BeatLoader } from 'react-spinners';
import SwitchTailwind from './switchTailwind';
import SingleDatepicker from '../modules/singleDatepicker';

class AdvancedReportSlideInUpdatePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            page: {}
        };
    };

    async componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed
        }, () => {
            if (!nextProps.closed) {
                this.setState({
                    page: nextProps.page ? JSON.parse(JSON.stringify(nextProps.page)) : {}
                })
            }
        });
    }

    functions = {
        valid: () => {
            return true;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <Transition.Root
                show={!this.state.closed} as={Fragment}
            >
                <Dialog as="div" style={{ zIndex: 9999, position: "absolute" }} className="absolute inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 transition duration-500 ease-in-out bg-black bg-opacity-25" />
                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-6 px-4 bg-purple-500 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">Update page</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-purple-500 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-indigo-300">
                                                        Select options below to update cells
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between px-4 sm:px-6 pb-6 border-b pt-6">
                                                <div className='flex flex-col w-full'>
                                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Background color</div>
                                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                        <div className={`flex flex-1 text-sm ml-5`}>
                                                            {this.state.page.enable_background ? "Enabled" : "Disabled"}
                                                        </div>
                                                        <div className="relative overflow-hidden mr-5">
                                                            <SwitchTailwind
                                                                value={this.state.page.enable_background ? true : false}
                                                                onSwitch={async () => {
                                                                    if (!this.state.page.enable_background) {
                                                                        this.state.page.background_color = "F5F5F5";
                                                                        this.state.page.enable_background_full = false;
                                                                        this.state.page.enable_margin = false;
                                                                        this.state.page.enable_round_corners = false;
                                                                    }
                                                                    this.state.page.enable_background = !this.state.page.enable_background;
                                                                    await this.promisedSetState({ page: this.state.page });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.page.enable_background &&
                                                    <div className="text-xs font-medium text-gray-700 mb-2 mt-4">Background color</div>
                                                }
                                                {
                                                    this.state.page.enable_background &&
                                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "background_color" ? "border-purple-500" : ""}`}>
                                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                            <div
                                                                style={{ backgroundColor: "#fcfcfc" }}
                                                                className="flex items-center col-span-3 p-1">
                                                                <span className={`pl-3`}>#</span>
                                                                <input
                                                                    id="background_color"
                                                                    className="w-full border-none p-2 bg-transparent"
                                                                    type="text"
                                                                    placeholder='F5F5F5'
                                                                    value={this.state.page && this.state.page.background_color ? this.state.page.background_color : "F5F5F5"}
                                                                    onClick={(e) => {
                                                                        this.promisedSetState({ selectedId: e.target.id })
                                                                    }}
                                                                    onChange={async (e) => {
                                                                        e.target.value = e.target.value.replace(/#/g, "");
                                                                        this.state.page.background_color = e.target.value;
                                                                        await this.promisedSetState({ page: this.state.page })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                                <input
                                                                    style={{ width: 110, height: 65, bottom: -10 }}
                                                                    className="absolute cursor-pointer"
                                                                    type="color"
                                                                    value={this.state.page && this.state.page.background_color ? ("#" + this.state.page.background_color) : "#F5F5F5"}
                                                                    onChange={async (e) => {
                                                                        let color_string = e.target.value.slice(1);
                                                                        this.state.page.background_color = color_string;
                                                                        await this.promisedSetState({ page: this.state.page })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.page.enable_background &&
                                                    <div className='flex flex-col w-full mt-4'>
                                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Cover background side to side</div>
                                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                            <div className={`flex flex-1 text-sm ml-5`}>
                                                                {this.state.page.enable_background_full ? "Enabled" : "Disabled"}
                                                            </div>
                                                            <div className="relative overflow-hidden mr-5">
                                                                <SwitchTailwind
                                                                    value={this.state.page.enable_background_full ? true : false}
                                                                    onSwitch={async () => {
                                                                        this.state.page.enable_background_full = !this.state.page.enable_background_full;
                                                                        if (this.state.page.enable_background_full) {
                                                                            this.state.page.enable_round_corners = false;
                                                                        }
                                                                        await this.promisedSetState({ page: this.state.page });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.page.enable_background &&
                                                    //!this.state.page.enable_background_full &&
                                                    //this.state.page.enable_background &&
                                                    <div className='flex flex-col w-full mt-4'>
                                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Margin Top/Bottom (external only)</div>
                                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                            <div className={`flex flex-1 text-sm ml-5`}>
                                                                {this.state.page.enable_margin ? "Enabled" : "Disabled"}
                                                            </div>
                                                            <div className="relative overflow-hidden mr-5">
                                                                <SwitchTailwind
                                                                    value={this.state.page.enable_margin ? true : false}
                                                                    onSwitch={async () => {
                                                                        this.state.page.enable_margin = !this.state.page.enable_margin;
                                                                        await this.promisedSetState({ page: this.state.page });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.page.enable_background &&
                                                    !this.state.page.enable_background_full &&
                                                    <div className='flex flex-col w-full mt-4'>
                                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Round corners (external only)</div>
                                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                            <div className={`flex flex-1 text-sm ml-5`}>
                                                                {this.state.page.enable_round_corners ? "Enabled" : "Disabled"}
                                                            </div>
                                                            <div className="relative overflow-hidden mr-5">
                                                                <SwitchTailwind
                                                                    value={this.state.page.enable_round_corners ? true : false}
                                                                    onSwitch={async () => {
                                                                        this.state.page.enable_round_corners = !this.state.page.enable_round_corners;
                                                                        await this.promisedSetState({ page: this.state.page });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <div className='flex flex-col w-full mt-4'>
                                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Padding between cells</div>
                                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                            <div className={`flex flex-1 text-sm ml-5`}>
                                                                {this.state.page.disable_padding ? "Disabled" : "Enabled"}
                                                            </div>
                                                            <div className="relative overflow-hidden mr-5">
                                                                <SwitchTailwind
                                                                    value={this.state.page.disable_padding ? false : true}
                                                                    onSwitch={async () => {
                                                                        this.state.page.disable_padding = !this.state.page.disable_padding;
                                                                        await this.promisedSetState({ page: this.state.page });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    this.props.toggle()
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={async () => {
                                                    if (this.functions.valid()) {
                                                        this.props.onUpdate(this.state.page);
                                                        this.props.toggle();
                                                    }
                                                }}
                                                className={(this.functions.valid() ? "" : "opacity-50 cursor-not-allowed") + " ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                Update page
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

export default AdvancedReportSlideInUpdatePage;