import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {BeatLoader} from "react-spinners";
import OptimizeSearchKeywordsItem from "../optimize-search-keywords-item";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import {calls} from "./calls";
import Loader from "../loader";

class OptimizeSearchKeywordsPositiveAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword_channel: {},
            keyword_type: {name: 'Exact', value: 'exact'},
            campaign: {},
            error: false,
            errors: {},
            error_message: "",
            error_modal: false,
            loading: false,
            keyword_prefix: "",
            keyword_text: "",
            keyword_sufix: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign
        }, () => {
            this.setState({
                keyword_url: this.state.campaign.website
            })
        });
    }

    init = {};

    functions = {
        add: () => {
            if(!this.state.loading){
                if(this.state.keyword_text === "" || !this.state.keyword_type.value){
                    this.setState({
                        error: true,
                        errors: {},
                        error_modal: false,
                        error_message: ""
                    })
                }else{
                    this.setState({
                        loading: true,
                        errors: {},
                        error_modal: false,
                        error_message: ""
                    }, () => {
                        calls.addPositiveKeyword({
                            keyword: this.state.keyword_text,
                            type: this.state.keyword_type.value,
                            campaign_id: this.state.campaign.id,
                            prefix: this.state.keyword_prefix,
                            suffix: this.state.keyword_sufix,
                            url: this.state.keyword_url,
                            channels: {google: true}
                        }).then((response) => {
                            this.props.onAdd({
                                channel: 'google',
                                type: this.state.keyword_type.value,
                                status: 'active',
                                text: this.state.keyword_text,
                                adgroup_id: response.data.adgroup_id
                            });
                            this.setState({
                                loading: false,
                                keyword_prefix: "",
                                keyword_sufix: "",
                                keyword_text: ""
                            })
                        }, (error) => {
                            this.setState({
                                loading: false
                            })
                        });
                    })
                }
            }
        }
    };

    renders = {
        characters: (prefix, keyword, sufix) => {
            try {
                let prefix_length = prefix ? prefix.length : 0;
                let keyword_length = keyword.length;
                let suffix_length = sufix ? sufix.length : 0;
                return prefix_length + keyword_length + suffix_length;
            } catch (e) {
                console.log(e);
                return 0
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.state.loading &&
                <Loader
                    title={'Adding keyword'}
                />
                }
                <div className="Optimize-Search-Add-Title">
                    Add keyword
                </div>
                <div className="Optimize-Search-Add">
                    <div className="Optimize-Search-Add-input">
                        <div className="Optimize-Search-Add-input-keyword">
                            <Input
                                noTitle={true}
                                type={'text'}
                                placeholder={'Prefix (optional)'}
                                value={this.state.keyword_prefix}
                                size={'full'}
                                sign={true}
                                signTitle={this.renders.characters(this.state.keyword_prefix, this.state.keyword_text, this.state.keyword_sufix)+"/30)"}
                                onChange={(event) => {
                                    if(this.renders.characters(event.target.value, this.state.keyword_text, this.state.keyword_sufix) < 31){
                                        this.setState({keyword_prefix: event.target.value});
                                    }
                                }}
                            />
                        </div>
                        <div className="Optimize-Search-Add-input-keyword">
                            <Input
                                noTitle={true}
                                type={'text'}
                                placeholder={'Keyword'}
                                error={this.state.error && this.state.keyword_text === ""}
                                value={this.state.keyword_text}
                                size={'full'}
                                onChange={(event) => this.setState({keyword_text: event.target.value})}
                            />
                        </div>
                        <div className="Optimize-Search-Add-input-keyword">
                            <Input
                                noTitle={true}
                                type={'text'}
                                placeholder={'Sufix (optional)'}
                                value={this.state.keyword_sufix}
                                size={'full'}
                                sign={true}
                                signTitle={this.renders.characters(this.state.keyword_prefix, this.state.keyword_text, this.state.keyword_sufix)+"/30)"}
                                onChange={(event) => {
                                    if(this.renders.characters(this.state.keyword_prefix, this.state.keyword_text, event.target.value) < 31){
                                        this.setState({keyword_sufix: event.target.value});
                                    }
                                }}
                            />
                        </div>
                        <div className="Optimize-Search-Add-input-keyword">
                            <Input
                                noTitle={true}
                                type={'text'}
                                placeholder={'Website (optional)'}
                                value={this.state.keyword_url}
                                size={'full'}
                                sign={false}
                                signTitle={"Website"}
                                onChange={(event) => {
                                    this.setState({keyword_url: event.target.value});
                                }}
                            />
                        </div>
                    </div>
                    {
                        false &&
                        <div className="Optimize-Search-Add-dropdown">
                            <Dropdown
                                noTitle={true}
                                filter={false}
                                size={'full'}
                                tiny={true}
                                error={this.state.error && !this.state.keyword_channel.value}
                                placeholder={"Select channels"}
                                options={[
                                    {name: 'All channels', value: 'all'},
                                    {name: 'Google', value: 'google'}
                                ]}
                                value={this.state.keyword_channel}
                                onChange={(value) => {
                                    this.setState({
                                        keyword_channel: value
                                    })
                                }}
                            />
                        </div>
                    }
                    <div className="Optimize-Search-Add-dropdown">
                        <Dropdown
                            noTitle={true}
                            filter={false}
                            title={'Order'}
                            size={'full'}
                            tiny={true}
                            error={this.state.error && !this.state.keyword_type.value}
                            placeholder={"Select type"}
                            options={[
                                {name: 'Exact', value: 'exact'},
                                {name: 'Phrase', value: 'phrase'},
                                {name: 'Broad', value: 'broad'}
                            ]}
                            value={this.state.keyword_type}
                            onChange={(value) => {
                                this.setState({
                                    keyword_type: value
                                })
                            }}
                        />
                    </div>
                    <div className="Optimize-Search-Add-Button">
                        <div onClick={() => {
                            this.functions.add();
                        }} className="Button ButtonFull ButtonNeutral">
                            <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                        </div>
                    </div>
                </div>
                {
                    this.state.error_modal &&
                    <div style={{marginBottom: '15px'}} className="SectionError">
                        {
                            Object.keys(this.state.errors).map((key) => {
                                return(
                                    <div>
                                        {key}{": "}{this.state.errors[key]}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchKeywordsPositiveAdd;
