import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, TrashIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, FilterIcon, UserAddIcon, LightningBoltIcon, ExternalLinkIcon, PauseIcon, ArrowUpIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';
import { createChangelog } from '../services/createChangelog';
var moment = require('moment');

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manager: false,
            clients: [],
            loading: true,
            loading_partial: false,
            meta: {},
            user: {},
            previous: {},
            limit: 10,
            page: 1,
            sort: "spend",
            direction: "descending",
            search: "",
            columns: [
                { name: 'name', value: 'name', min_width: '450px' },
                { name: 'channels', value: 'channels', noSort: true },
                { name: 'status', value: 'status', min_width: '150px' },
                { name: 'agents', value: 'agents', min_width: '150px' },
                { name: 'Budget', value: 'budget', min_width: '200px' },
                { name: 'Spend', value: 'spend', min_width: '200px' },
                { name: 'impressions', value: 'impressions', min_width: '200px' },
                { name: 'click', value: 'click', min_width: '200px' },
                { name: 'ctr', value: 'ctr', min_width: '200px' },
                { name: 'cpc', value: 'cpc', min_width: '200px' },
                { name: 'cpm', value: 'cpm', min_width: '200px' },
                { name: 'created', value: 'created', min_width: '100px' },
                { name: 'email', value: 'email', min_width: '350px' },
                { name: 'reference id', value: 'internalId', min_width: '150px' }
            ],
            client: {},
            showCompare: false,
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "timeSpan",
                    placeholder: "This month",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month", value: "this_month" },
                        { id: 2, name: "Last month", value: "last_month" },
                        { id: 3, name: "Two months ago", value: "2_months" },
                        { id: 4, name: "Three months ago", value: "3_months" }
                    ],
                    selected: { id: 1, name: "This month", value: "this_month" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "agents",
                    placeholder: "All",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Assigned/Unassigned", value: "all" },
                        { id: 2, name: "Unassigned", value: "unassigned" },
                        { id: 3, name: "Assigned", value: "assigned" }
                    ],
                    selected: { id: 1, name: "Assigned/Unassigned", value: "all" },
                    loading: false
                }
            ],
            updated: false,
            metric: "spend",
            doughnuts: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            disable_chart: false,
            pause: null,
            claimData: null,
            selected_tags: [],
            pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true }
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page") || 1;
        let limit = params.get("limit") || this.state.limit;
        let sort = params.get("sortBy") || this.state.sort;
        let direction = params.get("orderBy") || this.state.direction;
        let search = params.get("search") || this.state.search;
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");
        let all = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        let buff = [];
        if (tags.length > 0) {
            tags.map(tag => {
                buff.push({ name: tag });
            })
        }

        await this.promisedSetState({
            page: +page,
            limit: +limit,
            sort: sort,
            direction: direction,
            ...(search && { search: search }),
            advancedFilters: this.state.advancedFilters,
            selected_tags: buff
        });

        this.functions.getUser();
        this.functions.client();
        this.functions.getClients(true);
        this.functions.listClientsCharts(true);
        this.functions.listClientsChartsDaily(true);
    }

    // ON BACK BUTTON
    async componentWillReceiveProps(nextProps, nextContext) {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page")
        let limit = params.get("limit")
        let sort = params.get("sortBy")
        let direction = params.get("orderBy")
        let search = params.get("search");
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        if (tags && tags.length > 0) {
            let selected_tags = [];
            tags.map(tag => {
                selected_tags.push({ name: tag });
            })
            this.state.selected_tags = selected_tags;
        }

        if (page && page !== this.state.page) {
            await this.promisedSetState({
                page: +page,
                limit: +limit,
                sort: sort,
                direction: direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        } else if (!window.location.pathname.includes("?limit=")) {
            await this.promisedSetState({
                page: 1,
                limit: 10,
                sort: this.state.sort,
                direction: this.state.direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        }
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            /*
            console.log(this.state.user, "USER")
            if (this.state.user && ((this.state.user.userRole && this.state.user.userRole === "admin" ) || this.state.user.manager)) {
                await this.promisedSetState({
                    manager: true
                });
            } else {
                await this.promisedSetState({
                    client: { id: 1, name: "My clients" }
                });
            }*/
            this.functions.getAgent();
        },
        getAgent: async () => {
            if (!(this.state.user.channels && this.state.user.channels.length > 0)) {
                try {
                    const response = await this.calls.getAgent();
                    const channels = [];
                    Object.keys(internalChannels).forEach((item, index) => {
                        if (response.data[item]) {
                            channels.push({
                                id: (index + 1),
                                channel: internalChannels[item].value,
                                name: internalChannels[item].name,
                                value: item,
                                authenticated: response.data[item].authenticated
                            })
                        }
                    })
                    this.state.user.channels = channels;
                    await this.promisedSetState({ user: this.state.user });
                } catch (error) { }
            }
        },
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name;
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getClients(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: response.data, meta: response.meta, initial_data: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        listClientsCharts: async (init) => {
            await this.promisedSetState({ loading_chart: true });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name;
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.listClientsCharts(params);
                await this.promisedSetState({
                    doughnuts: response.meta,
                    total: response.meta.total,
                    previous: response.meta.previous
                });
            } catch (error) { }
            await this.promisedSetState({ loading_chart: false });
        },
        listClientsChartsDaily: async (init) => {
            await this.promisedSetState({ loading_chart_daily: true });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name;
                });
                let type = window.location.href.split('clients/')[1].split('?')[0];
                if (type !== "all") {
                    params = params + "&status=" + type;
                }
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.listClientsChartsDaily(params);
                await this.promisedSetState({
                    chart: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading_chart_daily: false });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.clients.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ clients: this.state.clients });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ clients: this.state.clients.filter((item) => { return item.id !== temp.id }) });
                this.functions.handleCreateChangelog(temp.id, "removed")
            } catch (error) {
                this.state.clients.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ clients: this.state.clients });
            }
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        updateClient: async (id, data) => {
            this.state.clients.map((item) => { if (item.id === id) { item.loading = true } return item });
            const response = await this.calls.updateClient(id, data);
            if (response.status === 200) {
                let clients = this.state.clients.map(item => {
                    if (item.id === id) {
                        item = { ...item, ...data };
                    }
                    return item;
                });
                await this.promisedSetState({ clients: clients.filter((item) => { return item.id !== id }), updated: true, pause: null, claimData: null });
                this.functions.handleCreateChangelog(id, data.status);
                if (this.props.updateStatistics) {
                    this.props.updateStatistics();
                }
            }
            this.state.clients.map((item) => { if (item.id === id) { item.loading = false } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        pauseOrArchive: async (id, data) => {
            await this.promisedSetState({ pause: null });
            this.state.clients.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (data.status && data.status == "disabled") {
                console.log("DISABLED")
                data.pause_campaigns = this.state.pause_all_campaigns.value;
            }
            try {
                await this.calls.updateClient(id, data);
                let clients = this.state.clients.map(item => {
                    if (item.id === id) {
                        item = { ...item, ...data };
                    }
                    return item;
                });
                await this.promisedSetState({ clients: clients.filter((item) => { return item.id !== id }), updated: true, pause: null });
                this.functions.handleCreateChangelog(id, data.status);
            } catch (error) { }
            this.state.clients.map((item) => { if (item.id === id) { item.loading = false } return item });
            await this.promisedSetState({ clients: this.state.clients });
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
            await this.promisedSetState({ clients: this.state.clients, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } });
        },
        claimClient: async () => {
            this.setState({
                claim_client_loader: true
            });
            let claim_channels = {};
            if (this.state.claimData.selectedChannels) {
                for (let key in this.state.claimData.selectedChannels) {
                    claim_channels[key] = { email: this.state.user.email, id: this.state.user.id };
                }
            }
            try {
                await this.calls.updateClient(this.state.claimData.id, {
                    claim_agent: { email: this.state.user.email, id: this.state.user.id },
                    claim_channels: claim_channels
                });
                this.setState({
                    claim_client_loader: false,
                    claimData: null
                });
            } catch (error) {
                this.setState({
                    claim_client_loader: false
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        handleSetTags: async (value) => {
            await this.promisedSetState({
                selected_tags: value
            });
            this.functions.getClients();
            this.functions.listClientsCharts();
            this.functions.listClientsChartsDaily();
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                    client: JSON.stringify(this.state.client),
                    agents: this.state.advancedFilters[1].selected ? this.state.advancedFilters[1].selected : { id: 1, name: "Assigned/Unassigned", value: "all" },
                    active: this.state.advancedFilters[0].selected ? this.state.advancedFilters[0].selected : { id: 1, name: "Active", value: "active" },
                    tags: this.state.selected_tags ? this.state.selected_tags : []
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.selected_tags.length > 0 ? this.state.selected_tags.map(item => "&tags[]=" + item.name).join('') : "") + "&agents=" + this.state.advancedFilters[1].selected.value + "&active=" + this.state.advancedFilters[0].selected.value)
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleCreateChangelog: async (id, action) => {
            try {
                let client = null;
                client = this.state.initial_data.find(item => item.id === id)
                this.state.updated_data = client
                this.state.updated_data = { ...this.state.updated_data, status: action };
                if (action !== "removed") {
                    await this.promisedSetState({
                        updated_data: this.state.updated_data
                    })
                }
                createChangelog({ initial_data: client, updated_data: this.state.updated_data, client: id, order: null, item: id, level: "client" });
                if (action !== "removed") {
                    client = null;
                    await this.promisedSetState({
                        updated_data: {}
                    })
                }
            } catch (error) { }

        }
    };

    renders = {
        comparedValue(org_number, preceding_number) {
            try {
                if (+preceding_number > 0) {
                    let increase = +org_number - +preceding_number;
                    let value = (+increase / +preceding_number) * 100;
                    return value.toFixed(2);;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        metricGoal(key) {
            if (key == "impressions" || key == "clicks" || key == "spend" || key == "ctr" || key == "budget") {
                return "highest";
            } else if (key == "cpc" || key == "cpm") {
                return "lowest";
            } else {
                return null;
            }
        },
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        },
        pauseAllCampaigns: (value) => {
            try {
                if (value) {
                    return value;
                } else if (this.state.pause_all_campaigns) {
                    return this.state.pause_all_campaigns;
                } else {
                    return { id: 1, name: "Yes, pause everything", value: true }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        }
    }

    calls = {
        getClients: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsWithPerformance?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&performance=true" + params + "&include_agents=true";
            return apiRegister.call(options, url);
        },
        listClientsChartsDaily: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsChartsDaily?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        },
        listClientsCharts: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsCharts?chart=1" + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeClient?client=" + id;
            return apiRegister.call(options, url);
        },
        updateClient: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClient?client=" + id;
            return apiRegister.call(options, url);
        },
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">
                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        showEnableStats={true}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.getClients();
                            this.functions.listClientsCharts();
                            this.functions.listClientsChartsDaily();
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_order: true
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getClients();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.history()
                            this.functions.getClients();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.history()
                            this.functions.getClients();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.getClients(false, value);
                                }
                            }, 400);
                        }}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={true}
                        hideUserDropdown={true}
                        breadcrumb={"Clients"}
                        buttons={[
                            { text: "Create new", icon: IdentificationIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.getClients();
                            this.functions.listClientsCharts();
                            this.functions.listClientsChartsDaily();
                            this.props.updateStatistics(client);
                        }}
                        // show_table_settings={true}
                        onSetTags={async (value) => {
                            this.functions.handleSetTags(value);

                        }}
                        onSetStats={async (value) => {
                            this.setState({
                                show_stats: !this.state.show_stats
                            });
                        }}
                        showTagsDropdown={true}
                        tagType={"client"}
                        url_tags={this.state.selected_tags}
                        searchInputValue={this.state.search}
                        client={this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading clients ...</div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                    onSetTags={async (value) => {
                        this.functions.handleSetTags(value)
                    }}
                    showTagsDropdown={true}
                />

                {/*Pause MODAL*/}
                <WarningModalTailwind
                    open={!!this.state.pause}
                    title={this.state.pause ? this.state.pause.statusName : ""}
                    description={'Are you sure you want to ' + (this.state.pause ? this.state.pause.statusName : "") + '? All connected orders and campaigns will be paused.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={this.state.pause ? this.state.pause.statusName : ""}
                    showInput={false}
                    onClose={async (value) => await this.promisedSetState({ pause: null, pause_all_campaigns: { id: 1, name: "Yes, pause everything", value: true } })}
                    onSubmit={(value) => {
                        if (this.state.pause && this.state.pause.status) {
                            this.functions.pauseOrArchive(this.state.pause.itemId, { status: this.state.pause.status })
                        }
                    }}
                    loading={this.state.loading}
                    dropDown={true}
                    dropDownOptions={[{ id: 1, name: "Yes, pause everything", value: true }, { id: 2, name: "No, don't pause everything", value: false }]}
                    dropDownSelectedRender={this.renders.pauseAllCampaigns()}
                    onDropDownSelect={async (value) => {
                        this.state.pause_all_campaigns = value
                        await this.promisedSetState({ pause_all_campaigns: this.state.pause_all_campaigns });
                    }}
                />

                {/*CLAIM CLIENT*/}
                <SlideoutTailwind
                    title={"Claim Client"}
                    submitButtonText={"Update"}
                    secondaryButton={false}
                    open={!!this.state.claimData}
                    loader={this.state.claim_client_loader}
                    onCancel={() => {
                        this.promisedSetState({ claimData: null });
                    }}
                    onSubmit={async () => {
                        this.functions.claimClient();
                    }}
                >
                    {
                        this.state.claimData && <div>
                            <div className="bg-blue-100 text-blue-500 font-medium text-sm p-4 mb-4 rounded-md">
                                OBS! Select channels to update the default agent, this is optional.
                            </div>
                            {
                                this.state.user.channels.filter(item => { return item.authenticated && this.state.claimData[item.channel] }).map((item) => {
                                    return (
                                        <div className={`flex flex-1 text-sm items-center justify-center border-b h-14`}>
                                            <div className='text-sm font-medium'>{item.name}</div>
                                            <div className="flex flex-1"></div>
                                            <div className="relative overflow-hidden">
                                                <SwitchTailwind
                                                    value={this.state.claimData.selectedChannels && this.state.claimData.selectedChannels[item.channel]}
                                                    onSwitch={async () => {
                                                        if (!this.state.claimData.selectedChannels) {
                                                            this.state.claimData.selectedChannels = {};
                                                        }
                                                        if (this.state.claimData.selectedChannels[item.channel]) {
                                                            delete this.state.claimData.selectedChannels[item.channel];
                                                        } else {
                                                            this.state.claimData.selectedChannels[item.channel] = true;
                                                        }
                                                        await this.promisedSetState({ claimData: this.state.claimData })
                                                        /*
                                                        if (Array.isArray(this.state.claimData.channels)) {
                                                            if (this.state.claimData.channels.includes(channel.value)) {
                                                                this.state.claimData.channels = this.state.claimData.channels.filter(item => item !== channel.value)
                                                            } else {
                                                                this.state.claimData.channels.push(channel.value);
                                                            }
                                                            await this.promisedSetState({ claimData: this.state.claimData })
                                                        }
                                                        */
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </SlideoutTailwind>

                {/*CREATE CLIENT*/}
                <SlideoutTailwind
                    title={"Create Client"}
                    submitButtonText={"Create"}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.create_order ? true : false}
                    loader={this.state.create_order_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_order: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_order_loading: true
                        });
                        this.refs.CreateClient.functions.createClient();
                    }}
                >
                    {
                        this.state.create_order &&
                        <CreateClient
                            ref="CreateClient"
                            admin={true}
                            onCreated={async (client) => {
                                this.props.history.push("/v2/client/" + client.id);
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_order_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                <SuccessModal
                    open={!!this.state.updated}
                    title="Updated"
                    button="OK"
                    onClose={() => {
                        this.setState({
                            updated: false
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            //this.state.show_stats &&
                            <div className="grid grid-cols-7 gap-4 mb-4">
                                {
                                    ["impressions", "clicks", "spend", "budget", "spend_vs_budget", "CTR", "CPC"].map((metric) => {
                                        return (
                                            <div className="col-span-1 relative">
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            disable_chart: true,
                                                            metric: metric
                                                        })
                                                        await this.functions.sleep(1000);
                                                        await this.promisedSetState({
                                                            disable_chart: false,
                                                        })
                                                    }}
                                                    className={"bg-white shadow rounded-lg p-4 relative overflow-hidden flex w-full h-full cursor-pointer"}>
                                                    {
                                                        this.state.loading_chart &&
                                                        <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    <div className="data--item__donut relative items-center w-full">
                                                        {
                                                            !this.state.loading_chart &&
                                                            !(Array.isArray(this.state.advancedFilters) && this.state.advancedFilters.length > 0 && this.state.advancedFilters[0].selected && this.state.advancedFilters[0].selected.value == "this_month") &&
                                                            this.state.previous && this.state.total &&
                                                            <div style={{ top: "4.5px", right: "-2px" }} className="text-xxs absolute font-medium flex flex-row">
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-green-100 text-green-500 border-green-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <div className="bg-red-100 text-red-500 border-red-500 h-3 w-3 mr-1 rounded-full flex justify-center items-center">
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0 &&
                                                                            <ArrowUpIcon className={"w-2"} />
                                                                        }
                                                                        {
                                                                            this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0 &&
                                                                            <ArrowDownIcon className={"w-2"} />
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-green-500 relative">
                                                                        {this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%
                                                                        {
                                                                            false &&
                                                                            <span style={{ top: "-10px" }} className="absolute text-black left-0 text-xxs">{this.state.previous[metric.toLowerCase()]}</span>
                                                                        }
                                                                    </span>
                                                                }
                                                                {
                                                                    ((this.renders.metricGoal(metric.toLowerCase()) === "lowest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) > 0) || (this.renders.metricGoal(metric.toLowerCase()) === "highest" && this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()]) < 0)) &&
                                                                    <span className="text-red-500">{this.renders.comparedValue(this.state.total[metric.toLowerCase()], this.state.previous[metric.toLowerCase()])}%</span>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <div className={(this.state.metric == metric ? "text-purple-500" : "") + " text-sm font-bold"}>{this.state.total && this.state.total[metric.toLowerCase()] ? this.renders.addCommas(this.state.total[metric.toLowerCase()]) : "-"}{metric == "spend_vs_budget" ? "%" : ""}</div>
                                                            <div className="text-sm text-gray-700">{metric !== "spend_vs_budget" ? metric : "Spend/Budget"} {(metric == "CTR" || metric == "CPC") ? "Avg." : ""}</div>
                                                        </div>
                                                        {
                                                            this.state.doughnuts[metric.toLowerCase()] &&
                                                            <div className="px-6 pt-3">
                                                                <Doughnut
                                                                    width={50}
                                                                    height={50}
                                                                    options={this.state.doughnut}
                                                                    data={this.state.doughnuts[metric.toLowerCase()]}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            this.state.show_stats &&
                            this.state.chart &&
                            this.state.chart[this.state.metric.toLowerCase()] &&
                            <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col  relative">
                                {
                                    (this.state.loading_chart_daily || this.state.disable_chart) &&
                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                <div className="w-full h-64 p-4">
                                    {
                                        !this.state.disable_chart &&
                                        <Bar
                                            options={{
                                                tooltips: {
                                                    mode: 'index'
                                                },
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                scales: {
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontSize: 8,
                                                            beginAtZero: true,
                                                            precision: 0,
                                                        }
                                                    }],
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontSize: 8
                                                        }
                                                    }]
                                                }
                                            }}
                                            data={this.state.chart[this.state.metric.toLowerCase()] ? this.state.chart[this.state.metric.toLowerCase()] : {}}
                                        />
                                    }
                                </div>
                            </div>
                        }

                        <div className={`shadow bg-white w-full overflow-hidden h-full ${this.state.clients.length > 0 ? "rounded-t-lg" : "rounded-lg"}`}
                            style={{ minHeight: "250px" }}
                        >

                            {/*NO ORDERS*/}
                            {
                                this.state.clients.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No clients available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }
                            {/*TABLE*/}
                            {
                                this.state.clients.length > 0 &&
                                <div className="relative overflow-x-auto table-overflow h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-55 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.getClients();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value === 'name' ? "sticky z-50 left-0 " : "") + (item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-50 hover:bg-opacity-75 cursor-pointer bg-white" : "bg-white")) + " border-l px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.clients.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr
                                                                onMouseEnter={() => {
                                                                    item.hover = true;
                                                                    this.setState({
                                                                        clients: this.state.clients
                                                                    })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    item.hover = false;
                                                                    this.setState({
                                                                        clients: this.state.clients
                                                                    })
                                                                }}
                                                                className="border-b" key={item.id}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-4 flex flex-row items-center justify-center  border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                                                                style={{ zIndex: 9999 }}
                                                                            >
                                                                                {/* <Menu.Items className={`${this.state.clients.length - (index + 1) < 5 && this.state.clients.length > 5 ? "bottom-100 left-100" : ""} border absolute  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                                                                style={{ zIndex: 9999 }}
                                                                            > */}


                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Claim client", "Open", "Open new tab", "Archive", "Activate", "Pause", "Delete"].filter((option) => {
                                                                                            if (item.status === "active") {
                                                                                                return option !== "Delete" && option !== "Activate" && option !== "Archive"
                                                                                            } else if (item.status === "archived") {
                                                                                                return option !== "Archive" && option !== "Pause"
                                                                                            } else if (item.status === "unassigned") {
                                                                                                return option !== "Delete" && option !== "Archive"
                                                                                            } else if (item.status === "disabled") {
                                                                                                return option !== "Pause" && option !== "Delete"
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                if (option === "Open") {
                                                                                                                    this.functions.history()
                                                                                                                    this.props.history.push('/v2/client/' + item.id);
                                                                                                                } else if (option === "Delete" && item.status == "archived") {
                                                                                                                    this.setState({
                                                                                                                        remove: item
                                                                                                                    });
                                                                                                                } else if (option === 'Pause' && item.status == "active") {
                                                                                                                    this.setState({
                                                                                                                        pause: { itemId: item.id, status: 'disabled', statusName: "Pause" }
                                                                                                                    });
                                                                                                                } else if (option === 'Archive' && item.status == "disabled") {
                                                                                                                    this.functions.pauseOrArchive(item.id, { status: "archived" });
                                                                                                                } else if (option === 'Activate' && item.status !== "active") {
                                                                                                                    this.functions.updateClient(item.id, { status: 'active' });
                                                                                                                } else if (option == "Open new tab") {
                                                                                                                    if (window.location.hostname === 'localhost') {
                                                                                                                        window.open("http://localhost:5001/v2/client/" + item.id);
                                                                                                                    } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                                                        window.open("https://app.adcredo.io/v2/client/" + item.id);
                                                                                                                    } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                                                        window.open("https://dev.adcredo.io/v2/client/" + item.id);
                                                                                                                    }
                                                                                                                } else if (option === 'Claim client') {
                                                                                                                    this.setState({
                                                                                                                        claimData: item
                                                                                                                    })
                                                                                                                }
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? (option !== "Delete" ? 'bg-gray-50 text-gray-900' : '') : (option !== "Delete" ? 'text-gray-700' : ''),
                                                                                                                option === "Delete" && item.disabled ? 'bg-red-500 text-white' : '',
                                                                                                                'px-4 py-2 text-sm relative items-center flex flex-row cursor-pointer',
                                                                                                                option == "Claim client" ? 'bg-purple-100 text-purple-500' : ''
                                                                                                            )}
                                                                                                        >
                                                                                                            <div className="flex flex-1">
                                                                                                                {option}
                                                                                                            </div>
                                                                                                            {
                                                                                                                option === "Delete" && item.disabled &&
                                                                                                                <TrashIcon class="w-4 h-4" />
                                                                                                            }
                                                                                                            {
                                                                                                                option == "Claim client" &&
                                                                                                                <UserAddIcon className="w-5" />
                                                                                                            }
                                                                                                            {
                                                                                                                option == "Open new tab" &&
                                                                                                                <ExternalLinkIcon className="w-5" />
                                                                                                            }
                                                                                                            {
                                                                                                                option == "Pause" &&
                                                                                                                <PauseIcon className="w-5" />
                                                                                                            }
                                                                                                        </div>

                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.history()
                                                                    this.props.history.push('/v2/client/' + item.id);
                                                                }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50" : "bg-white") + " border-l sticky left-0 z-50 border-r px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div>
                                                                            <div className="h-8 w-8 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                <img src={item.logo} className="bg-cover" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.name ? item.name : "-"}
                                                                            {
                                                                                Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                <div class="flex flex-row whitespace-no-wrap mt-1">
                                                                                    {
                                                                                        item.tags.map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className="text-xxs text-gray-600 font-medium mr-1 rounded-full bg-gray-100 px-1.5">{item.name}</div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            false &&
                                                                            <div className="ml-4">
                                                                                {item.name}
                                                                                {
                                                                                    Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                    <div className="text-xxs text-gray-600 font-medium">{item.tags.map((item) => { return "#" + item.name }).join(" ")}</div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                {
                                                                    <td className={((this.state.sort === 'channels' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                        <div className="flex flex-row pl-2">
                                                                            {
                                                                                Array.isArray(item.channels) &&
                                                                                item.channels.map((channel) => {
                                                                                    if (channel.value === "facebook") {
                                                                                        return (
                                                                                            <div
                                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2 relative">
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "linkedin") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "google") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "tiktok") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "twitter") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "adform") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "google_analytics_4") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "google_analytics") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div></div>
                                                                                        )
                                                                                    } else if (channel.value === "snapchat") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(https://s3.amazonaws.com/bitmoji-sdk-images/logo-snapchat.svg)" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "bing") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2 relative'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                                {
                                                                                                    item.maxBudgetEnabled && item.maxBudgetEnabled[channel.value] &&
                                                                                                    <div style={{ top: "-7px", width: "15px", height: "15px", right: "-2px" }} className="absolute rounded-full bg-purple-500 flex items-center justify-center">
                                                                                                        <FilterIcon className="w-2 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "dv360") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                                            </div>
                                                                                        )
                                                                                    } else if (channel.value === "bidtheatre") {
                                                                                        return (
                                                                                            <div
                                                                                                className={'w-8 h-8 bg-bidtheatre-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                                <div
                                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                                            </div>
                                                                                        )
                                                                                    } else {
                                                                                        return (<></>)
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                }
                                                                <td className={((this.state.sort === 'status' || item.hover) ? "bg-gray-50 " : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-red-100 text-orange-500 bg-opacity-50"]: item.status === "disabled",
                                                                                ["bg-gray-100 text-gray-500 bg-opacity-50"]: item.status === "archived"
                                                                            })
                                                                        }>
                                                                        {item.status === "disabled" ? "paused" : item.status}
                                                                    </span>
                                                                </td>
                                                                <td className={((this.state.sort === 'agents' || item.hover) ? "bg-gray-50" : "") + " border-r  px-4 sm:px-6 font-medium py-4 items-center text-sm border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-purple-100 text-purple-500"]: Array.isArray(item.agents) && item.agents.length > 0,
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: !(Array.isArray(item.agents) && item.agents.length > 0)
                                                                            })
                                                                        }>
                                                                        {Array.isArray(item.agents) && item.agents.length > 0 ? "Assigned" : "Unassigned"}
                                                                    </span>
                                                                </td>
                                                                {
                                                                    <td className={((this.state.sort === 'budget' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.budget)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'spend' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.total_spend)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'impressions' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_impressions ? item.total_impressions : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'click' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_clicks ? item.total_clicks : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'ctr' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.total_ctr)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'cpc' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.total_cpc)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'cpm' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {this.renders.convertToDecimal(item.total_cpm)}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'created' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.created ? moment(item.created).format("YYYY-MM-DD") : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'email' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.email ? item.email : "-"}
                                                                    </td>
                                                                }
                                                                {
                                                                    <td className={((this.state.sort === 'internalId' || item.hover) ? "bg-gray-50" : "") + " whitespace-no-wrap px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.internalId ? item.internalId : "-"}
                                                                    </td>
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }



                        </div>

                        {/* PAGINATION */}
                        {
                            this.state.clients.length > 0 &&
                            <div className="flex flex-row p-4 items-center bg-white rounded-b-lg">
                                <div className="flex justify-center items-center">
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (this.state.page !== 1) {
                                                await this.promisedSetState({
                                                    page: this.state.page - 1
                                                });
                                                this.functions.getClients();
                                            }
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                        Previous
                                    </button>
                                </div>
                                <div className="flex flex-1 items-center justify-center">
                                    {
                                        (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                            <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                        </p>
                                    }
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="">
                                        <button
                                            onClick={async () => {
                                                if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                    await this.promisedSetState({
                                                        page: this.state.page + 1
                                                    });
                                                    this.functions.getClients();
                                                }
                                            }}
                                            className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <span>Next</span>
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>
        )
    }
}

/*
<>
                <div className="relative h-full flex flex-col">

                    <div className="sticky top-0 z-50">
                        <TopNavigationAgent
                            onButton={(type) => {
                                if (type === "create") {
                                    this.props.history.push('/v2/clients/new');
                                }
                            }}
                            hideUserDropdown={true}
                            breadcrumb={"Clients"}
                            history={this.props.history}
                            buttons={[
                                { text: "Create new", icon: UserIcon, value: "create", loading: false, show: !this.state.loading }
                            ]}
                        />
                    </div>

             
                    {
                        this.state.loading &&
                        <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold mt-3">Loading Clients ...</div>
                        </div>
                    }

                    {
                        !this.state.loading &&
                        <div className="p-4">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                           
                                {
                                    !this.state.loading && this.state.clients.length > 0 &&
                                    <div className="flex flex-col">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg border-none rounded-t-none">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                {
                                                                    ["NAME", "COMPANY INFO", 'CHANNELS'].map((heading) => {
                                                                        return (
                                                                            <th
                                                                                scope="col"
                                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                            >
                                                                                {heading}
                                                                            </th>
                                                                        )
                                                                    })
                                                                }
                                                                <th scope="col" className="relative px-6 py-3">
                                                                    <span className="sr-only">Edit</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {this.state.clients.map((client) => (
                                                                <tr key={client.email} className="hover:bg-gray-50">
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            <div className="flex-shrink-0 h-10 w-10 rounded-full overflow-hidden border p-1">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + client.logo + ")" }}></div>
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="text-sm font-medium text-gray-900">{client.name}</div>
                                                                                <div className="text-sm text-gray-500">#{client.id}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-900">{client.company}</div>
                                                                        <div className="text-sm text-gray-500">{client.email}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="flex-shrink-0">
                                                                            <div className="flex overflow-hidden -space-x-2">
                                                                                {[
                                                                                    { name: 'facebook', icon: 'facebook_icon.svg' },
                                                                                    { name: 'google', icon: 'google_icon.svg' },
                                                                                    { name: 'google_analytics', icon: 'google_analytics_icon.png' },
                                                                                    { name: 'google_shopping', icon: 'google_shopping_icon.png' },
                                                                                    { name: 'adform', icon: 'adform_icon.png' },
                                                                                    { name: 'linkedin', icon: 'linkedin_icon.svg' },
                                                                                    { name: 'twitter', icon: 'twitter_icon.svg' }
                                                                                ].filter((channel) => { return (channel.name in client) }).map((channel) => (
                                                                                    <div
                                                                                        className={"w-8 h-8 bg-" + channel.name.replace('_', '') + "-500 rounded-full p-2 border-2 border-white"}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/' + channel.icon) + ")" }}></div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                        <div onClick={() => {
                                                                            this.props.history.push('/v2/clients/' + client.id);
                                                                        }} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                            Edit
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                             
                                {
                                    !this.state.loading && this.state.clients.length < 1 &&
                                    <div className="text-center mt-12 mb-12">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                vectorEffect="non-scaling-stroke"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                            />
                                        </svg>
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No clients</h3>
                                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new client.</p>
                                        <div className="mt-6">
                                            <button
                                                onClick={() => {
                                                    this.props.history.push('/v2/clients/new');
                                                }}
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Create new
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                </div>
            </>

*/

export default ClientList;