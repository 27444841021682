import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getUsers(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/user/agency/" + id;
        return apiRegister.call(options, url);
    }

    static removeUser(id) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/user/" + id;
        return apiRegister.call(options, url);
    }

    static addUser(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/register";
        return apiRegister.call(options, url);
    }

}

export {calls}