import React, {Component} from 'react';
import DropDown from '../../../modules/dropdown';
import queryString from 'query-string';
import {calls} from './calls';
import FeatherIcon from 'feather-icons-react';
import {BeatLoader} from 'react-spinners';

import './style.css';
import ClientTableRow from "../../../modules/clienttablerow";
import CampaignsList from "../../../modules/campaignslist";
import cx from "classnames";

class PremiumReportNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            client: {},
            loading_clients: true,
            loading_campaigns: false,
            types: [],
            loading_types: false,
            campaigns: [],
            campaign: {},
            ads: [],
            adsets: [],
            queries: [],
            type: {},
            external_campaigns: {},
            selected_externals: {},
            loading: true,
            loading_campaign: false,
            open: true,
            social_channels: [],
            search_channels: [],
            order: 'desc',
            sort: 'startDate',
            indexOfActiveBox: null,
            page: 0,
            page_size: 10,
            total: 0,
            submenu: "internal"
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.functions.clients();
    }

    functions = {
        getQuery: (q) => {
            return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [null])[1];
        },
        clients: () => {
            this.setState({
                    loading_clients: true
                }, () => {
                    calls.getClient().then((response) => {
                            this.setState({
                                    clients: response.data,
                                    loading_clients: false,
                                }, () => {
                                    if (window.location.search.indexOf('client=') >= 0) {
                                        let clientID = this.functions.getQuery('client');
                                        this.functions.fetchClientFromQuery(clientID);
                                    }
                                }
                            );
                        }, (error) => {
                            this.setState({
                                loading_clients: false,
                            });
                        }
                    );
                }
            );
        },
        campaigns: (load_more) => {
            this.setState({
                    loading_campaigns: !load_more,
                    load_more: load_more,
                    campaigns: load_more ? this.state.campaigns : [],
                    page: load_more ? this.state.page : 0,
                }, () => {
                    calls.getCampaignsByClient(this.state.client.id, this.state.page, this.state.sort, this.state.order, this.state.page_size).then((response) => {
                        this.setState({
                            total: response.metadata.count,
                            page: response.metadata.page,
                            campaigns: load_more ? (this.state.campaigns.concat(response.data)) : response.data,
                            loading_campaigns: false,
                            load_more: false
                        });
                    }, (error) => {
                        this.setState({
                            loading_campaigns: false,
                            load_more: false
                        })
                    });
                }
            );
        },
        externals: () => {
            this.setState({
                    loading_externals: true,
                }, () => {
                    calls.getAllExternalCampaigns({id: this.state.client.id}).then((response) => {
                        let merge_campaigns = [];
                        for (let channel in response.data) {
                            response.data[channel].map((item) => {
                                let campaign = item;
                                item.channels = {};
                                item.channels[channel] = true;
                                merge_campaigns.push(campaign);
                            });
                        }
                        this.setState({
                            external_campaigns: merge_campaigns,
                            loading_externals: false
                        });
                    }, (error) => {
                        this.setState({
                            loading_externals: false
                        })
                    });
                }
            );
        },
        campaign: (id) => {
            this.setState({
                    loading_campaign: true,
                }, () => {
                    calls.getCampaign(id).then(
                        (response) => {
                            response.data.name = this.state.campaign.name;
                            this.setState({
                                campaign: response.data,
                                open: false,
                                loading: false,
                                show: true,
                                loading_campaign: false,
                            });
                        }, (error) => {
                            console.log(error);
                        }
                    );
                }
            );
        },
        setClient: (item) => {
            this.setState({
                    client: item,
                    campaigns: [],
                    campaign: {},
                }, () => {
                    this.functions.campaigns();
                    this.functions.externals();
                    this.props.history.push('?client=' + item.id);
                }
            );
        },
        setCampaign: (item) => {
            this.setState({
                campaign: item
            }, () => {
                this.props.history.push('?client=' + this.state.client.id + "&campaign=" + item.id);
            });
        },
        fetchClientFromQuery: (clientID) => {
            const selectedClient = this.state.clients.find((c) => {
                return c.id === clientID;
            });
            if (selectedClient) {
                this.setState({
                    client: selectedClient,
                    loading_campaigns: true,
                }, () => {

                    //GET EXTERNALS
                    this.functions.externals();

                    //GET INTERNALS
                    calls.getCampaignsByClient(this.state.client.id, this.state.page, this.state.sort, this.state.order, this.state.page_size).then((response) => {
                        this.setState({
                            total: response.metadata.count,
                            page: response.metadata.page,
                            campaigns: response.data,
                            loading_campaigns: false,
                        }, () => {
                            if (window.location.search.indexOf('campaign=') >= 0) {
                                let campaignID = this.functions.getQuery('campaign');
                                this.functions.fetchCampaignFromQuery(campaignID);
                            }
                        });
                    });
                });
            } else {
                this.props.history.push('?');
            }
        },
        fetchCampaignFromQuery: (campaignID) => {
            const selectedCampaign = this.state.campaigns.find((ca) => {
                return ca.id === campaignID;
            });
            if (selectedCampaign) {
                this.functions.setCampaign(selectedCampaign);
            } else {
                this.props.history.push('?client=' + this.state.client.id);
            }
        }
    };

    render() {
        return (
            <section className="new-report w-full h-full pt-8 mb-8 relative">
                {
                    this.state.loading_clients && (
                        <div className="background-Loader">
                            <BeatLoader sizeUnit={'px'} size={24} color={'#060534'} loading={true}/>
                        </div>
                    )
                }
                {
                    !this.state.loading_clients && (
                        <>
                            <div className="flex flex-1 flex-col">
                                <div className="flex flex-1 mb-4 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        1. Select client
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                                {
                                    typeof this.state.client == "object" && !this.state.client.name &&
                                    <div className="flex flex-1">
                                        <ClientList
                                            clients={this.state.clients}
                                            onSelect={(client) => {
                                                this.functions.setClient(client);
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name &&
                                    <DropDown
                                        label={this.state.client.name ? this.state.client.name : 'Click here'}
                                        listitems={this.state.clients}
                                        showreset={!this.state.loading_clients && this.state.client.name}
                                        callfunc={() => {
                                        }}
                                        disabled={this.state.client.name}
                                        res={() => {
                                            this.setState(
                                                {
                                                    type: {},
                                                    types: [],
                                                    campaigns: [],
                                                    client: {},
                                                    campaign: {},
                                                },
                                                () => {
                                                    this.props.history.push('?');
                                                }
                                            );
                                        }}
                                        emptybool={false}
                                    />
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name &&
                                    <div className="flex flex-1 flex-col">
                                        <h3 className="mb-0 font-bold">
                                            2. Select campaign
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Avg. number of search per month in parantheses
                                        </p>
                                    </div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && !this.state.campaign.name &&
                                    <div className="bg-white p-4 mt-4 rounded-md pt-0">
                                        <div className="navigation mb-4">
                                            <nav role="navigation" aria-label="Social sub navigation">
                                                <ul className="flex flex-row items-end">
                                                    {[{
                                                        title: "Internal campaigns",
                                                        value: "internal"
                                                    }, {
                                                        title: "Import external campaigns",
                                                        value: "external"
                                                    }].map((item, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.value ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        submenu: item.value
                                                                    });
                                                                }}>
                                                                {item.title}
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.submenu === "external" &&
                                    typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && !this.state.campaign.name &&
                                    <CampaignsList
                                        campaignitems={this.state.external_campaigns}
                                        total={this.state.total}
                                        order={this.state.order}
                                        sort={this.state.sort}
                                        selected_campaigns={this.state.selected_externals}
                                        properties={{
                                            name: true,
                                            client: false,
                                            status: false,
                                            start_date: false,
                                            end_date: false,
                                            channels: true,
                                            actions: {open: false, preview: false, delete: false, select: true}
                                        }}
                                        loading={this.state.loading_externals}
                                        callfunc={(item) => {
                                            this.functions.setCampaign(item);
                                        }}
                                        updateSelected={(value) => {
                                            this.setState({
                                                selected_externals: value
                                            })
                                        }}
                                        updateSort={(sort, order) => {
                                            this.setState({
                                                loading: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total),
                                                page: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total) ? 0 : this.state.page,
                                                sort: sort,
                                                order: order
                                            }, () => {
                                                if (this.state.campaigns.length === 0) {
                                                    this.functions.campaigns();
                                                }
                                            })
                                        }}
                                    />
                                }
                                {
                                    this.state.submenu === "internal" &&
                                    typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && !this.state.campaign.name &&
                                    <CampaignsList
                                        campaignitems={this.state.campaigns}
                                        total={this.state.total}
                                        order={this.state.order}
                                        sort={this.state.sort}
                                        properties={{
                                            name: true,
                                            client: false,
                                            status: true,
                                            start_date: true,
                                            end_date: true,
                                            channels: true,
                                            actions: {open: true, preview: false, delete: false}
                                        }}
                                        loading={this.state.loading_campaigns}
                                        callfunc={(item) => {
                                            this.functions.setCampaign(item);
                                        }}
                                        updateSort={(sort, order) => {
                                            this.setState({
                                                loading: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total),
                                                page: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total) ? 0 : this.state.page,
                                                sort: sort,
                                                order: order
                                            }, () => {
                                                if (this.state.campaigns.length === 0) {
                                                    this.functions.campaigns();
                                                }
                                            })
                                        }}
                                        clientname={this.state.client.name ? this.state.client.name : ''}
                                    />
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && !this.state.loading_campaigns && this.state.campaigns.length < this.state.total && typeof this.state.campaign == "object" && !this.state.campaign.name &&
                                    <div className="mt-5 mb-5 text-center flex-1 flex align-middle justify-center">
                                        <div onClick={(e) => {
                                            this.setState({
                                                loading_more: true
                                            }, () => {
                                                this.functions.campaigns(true);
                                            })
                                        }} className="btn btn-primary btn-lg max-w-md flex-1">
                                            {
                                                !this.state.load_more &&
                                                <div>
                                                    Load more ({this.state.campaigns.length}/{this.state.total})
                                                </div>
                                            }
                                            {
                                                this.state.load_more &&
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={10}
                                                    color={'#ffffff'}
                                                    loading={true}
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                    <div className="mt-4"></div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                    <DropDown
                                        label={this.state.campaign.name ? this.state.campaign.name : 'Campaign'}
                                        listitems={this.state.campaigns}
                                        showreset={!this.state.loading_campaigns && this.state.campaign.name}
                                        callfunc={() => {
                                        }}
                                        disabled={this.state.campaign.name}
                                        res={() => {
                                            this.setState({
                                                    campaign: {},
                                                    type: {},
                                                }, () => {
                                                    this.props.history.push('?client=' + this.state.client.id);
                                                }
                                            );
                                        }}
                                        emptybool={false}
                                    />
                                }
                            </div>
                            {
                                typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                <div className="flex flex-1 flex-col mb-4">
                                    <h3 className="mb-0 font-bold">
                                        3. Select template
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                <div className="flex flex-row overflow-scroll mb-4">
                                    <TemplateButton
                                        type={"freemium"}
                                        campaign={this.state.campaign}
                                        history={this.props.history}
                                    />
                                    {
                                        false &&
                                        <TemplateButton
                                            type={"premium"}
                                            campaign={this.state.campaign}
                                            client={this.state.client.id}
                                            history={this.props.history}
                                        />
                                    }
                                </div>
                            }
                            {
                                false && typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                <div className="flex flex-row overflow-scroll mb-4">
                                    <div className="flex flex-1 flex-row overflow-scroll">
                                        <FakeDataForGrid
                                            client={this.state.client}
                                            campaign={this.state.campaign}
                                            indexOfCheckedBox={this.state.indexOfCheckedBox}
                                            setIndexOfActiveBox={(index) => {
                                                this.setState({
                                                    indexOfActiveBox: index
                                                });
                                            }}
                                            history={this.props.history}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                false &&
                                typeof this.state.client == "object" && this.state.client.name && typeof this.state.campaign == "object" && this.state.campaign.name &&
                                <div className="flex flex-row overflow-scroll">
                                    <TemplateButton
                                        type={"premium"}
                                    />
                                </div>
                            }
                        </>
                    )
                }
            </section>
        );
    }
}

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            order: "asc",
            sort: "name"
        };
    }

    componentDidMount() {
        this.setState({
            clients: this.props.clients
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            clients: nextProps.clients
        })
    }

    render() {
        return (
            <>
                <div className="shadow-lg overflow-hidden border-b border-gray-200 rounded-lg w-full">
                    <table className="min-w-full divide-y divide-gray-200 bg-white">
                        <thead>
                        <tr>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'name',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Name <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'company',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Company info <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                <div className="flex flex-row items-center justify-end">
                                    Actions
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            this.state.clients.sort((a, b) => {
                                if (this.state.sort) {
                                    var nameA = null;
                                    var nameB = null;
                                    try {
                                        nameA = a[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameA = a[this.state.sort];
                                    }
                                    try {
                                        nameB = b[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameB = b[this.state.sort];
                                    }
                                    if (this.state.order === 'desc') {
                                        if (nameA > nameB)
                                            return -1;
                                        if (nameA < nameB)
                                            return 1;
                                        return 0
                                    } else {
                                        if (nameA < nameB)
                                            return -1;
                                        if (nameA > nameB)
                                            return 1;
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }).map((item, i) => {
                                return (
                                    <tr className="">
                                        <td className="px-6 py-4 whitespace-no-wrap">
                                            <div className="flex items-center">
                                                <div
                                                    className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                                                    {item.logo &&
                                                    <img className="h-10 w-10 rounded-full object-contain"
                                                         src={item.logo}
                                                         alt={item.company}/>
                                                    }
                                                </div>
                                                <div
                                                    className="text-sm leading-5 font-bold text-gray-900 hover:text-blue-500 hover:underline cursor-pointer ml-4"
                                                    onClick={() => {
                                                        this.props.onSelect(item);
                                                    }}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                            <div className="text-sm leading-5 text-gray-900 capitalize">
                                                {item.company}
                                            </div>
                                            <div className="text-xs leading-5 text-gray-500">
                                                {item.email}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                            <div className="flex flex-row items-end justify-end">
                                                <button onClick={() => {
                                                    this.props.onSelect(item);
                                                }}
                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                    <span>Select client</span>
                                                    <FeatherIcon className="stroke-current ml-2" size={16}
                                                                 icon="arrow-right-circle"/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

class TemplateButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {}
        };
    }

    componentDidMount() {
        this.setState(({
            campaign: this.props.campaign
        }))
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(({
            campaign: nextProps.campaign
        }))
    }

    render() {
        return (
            <>
                <div style={{minWidth: "300px", width: "300px"}}
                     className={`box--wrapper rounded-lg p-4 text-center mr-4 ${this.props.type === "premium" ? 'bg-gradient-to-br from-yellow-100 to-yellow-300 border-2 border-yellow-300' : 'bg-purple-200 bg-opacity-25'}`}>
                    <div className="box--wrapper__body flex flex-col">
                        <div>
                            <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize mb-2",
                                {
                                    ["bg-purple-200 bg-opacity-50 text-purple-400"]: this.props.type === "freemium",
                                    ["bg-yellow-300 text-yellow-800"]: this.props.type === "premium",
                                })
                            }>
                                {this.props.type === "freemium" ? "standard" : "premium"}
                            </div>
                        </div>
                        <div className="box--wrapper__title font-bold text-base">New <span
                            className="capitalize">{this.props.type === "freemium" ? "standard" : "premium"}</span> Template
                        </div>
                        <div className="text-xs flex justify-center align-middle items-center mb-2">
                            Build your own template and save for the template library
                        </div>
                    </div>
                    <div className="flex flex-1"></div>
                    <div className="box--wrapper__footer flex justify-center">
                        <button onClick={() => {
                            if (this.props.type === "freemium") {
                                this.props.history.push('/v2/reports/new/freemium/' + this.state.campaign.id);
                            } else {
                                this.props.history.push('/v2/reports/new/premium/' + this.state.campaign.id + "?client=" + this.props.client);
                            }
                        }}
                                className={`text-sm font-bold py-2 px-4 rounded-md w-full mt-4 transition-all duration-200 ${this.props.type === "premium" ? 'bg-yellow-500 hover:bg-yellow-300 hover:text-yellow-800 ' : 'bg-purple-200 text-purple-500 hover:bg-purple-500 hover:text-white'}`}>+
                            Create new template
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

class FakeDataForGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fakeDataForGrid: []
        };
    }

    render() {
        return (
            <>
                {this.state.fakeDataForGrid.map((f, index) => (
                    <div style={{minWidth: "300px", width: "300px"}}
                         className={`box--wrapper rounded-lg p-4 text-center mr-4 bg-white`}
                         key={f.id}
                         onClick={() => {
                             this.props.history.push('/user/reports/template/olv/777')
                         }}>
                        <div className="box--wrapper__body flex flex-col">
                            <div className="box--wrapper__title font-bold text-base">{f.title}</div>
                            <div style={{height: "50px"}}
                                 className="text-xs flex justify-center align-middle items-center mb-2">
                                {f.description}
                            </div>
                            <div>
                                <div className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                    {
                                        ["bg-gray-100 text-gray-500"]: f.type === "freemium",
                                        ["bg-purple-100 text-purple-500"]: f.type === "premium",
                                    })
                                }>
                                    {f.type}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="box--wrapper__footer flex justify-center">
                            <div style={{background: "#ffffff", color: "#000000"}}
                                 className="btn font-bold text-lg mt-4 w-full max-w-xxs">Select this template
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }
}

export default PremiumReportNew;
