import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, SearchIcon, SortAscendingIcon, PlusIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, CalendarIcon, CheckCircleIcon, ExclamationCircleIcon, AdjustmentsIcon, ClipboardListIcon, LockClosedIcon, LockOpenIcon, MinusCircleIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { UserIcon } from '@heroicons/react/outline';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import moment from "moment";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import { Line } from 'react-chartjs-2';

class ClientPerformanceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            loading: true,
            loading_partial: false,
            meta: {},
            chart: {},
            limit: 10,
            page: 1,
            sort: "spend",
            direction: "descending",
            search: "",
            dateSpan: { id: 3, name: "Last 7 days", value: "last_7" },
            metric: { id: 1, name: "Spend", value: "spend" },
            channel: { id: 1, name: "Total & channels", value: "all" },
            columns: [
                { name: 'Client', value: 'name', min_width: '350px', border: true },
                { name: 'Spend', value: 'spend', min_width: '200px', border: true },
                { name: 'Impressions', value: 'impressions', min_width: '200px', border: true },
                { name: 'Click', value: 'clicks', min_width: '200px', border: true },
                { name: 'CTR', value: 'ctr', min_width: '200px', border: true },
                { name: 'CPC', value: 'cpc', min_width: '200px', border: true },
                { name: 'CPM', value: 'cpm', min_width: '200px', border: true }
            ],
            client: {},
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.client();
    }

    functions = {
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getClients();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        getClientsPerformance: async () => {
            await this.promisedSetState({ loading_chart: true });
            try {
                let response = await this.calls.getClientsPerformance();
                this.setState({
                    chart: response.data
                })
            } catch (error) { }
            await this.promisedSetState({ loading_chart: false });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.getClients(true);
            //this.functions.getClientsPerformance();
        }
    };

    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&performance=true&dateSpan=" + this.state.dateSpan.value;
            return apiRegister.call(options, url);
        },
        getClientsPerformance: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyClientsPerformance?performance=true&dateSpan=" + this.state.dateSpan.value + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "");
            return apiRegister.call(options, url);
        }
    }

    renders = {
        chart: () => {
            let chart = JSON.parse(JSON.stringify(this.state.chart));
            if (chart && Array.isArray(chart.datasets)) {
                chart.datasets = chart.datasets.filter((item) => {
                    if (this.state.channel.value === "all") {
                        return item.label.toLowerCase().indexOf(this.state.metric.value) !== -1;
                    } else {
                        return item.label.toLowerCase().indexOf(this.state.metric.value) !== -1 && item.label.toLowerCase().indexOf(this.state.channel.value) !== -1;
                    }
                });
            }
            return chart;
        },
        comparedValue(org_number, preceding_number) {
            try {
                if (preceding_number > 0) {
                    let increase = org_number - preceding_number;
                    let value = (increase / preceding_number) * 100;
                    return value.toFixed(2);;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        metricGoal(key) {
            if (key == "impressions" || key == "clicks" || key == "spend") {
                return "highest"
            } else if (key == "cpc") {
                return "lowest"
            } else {
                return null;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_order: true
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getClients();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.getClients(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={true}
                        hideUserDropdown={true}
                        breadcrumb={"Clients"}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        showClientLimited={false}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.getClients();
                            //this.functions.getClientsPerformance();
                            this.props.updateStatistics(client);
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading clients ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col overflow-hidden">
                            <div className="h-16 px-4 border-b flex flex-row items-center">
                                <div className=" font-semibold text-sm flex flex-1">
                                    Clients performance data
                                </div>
                                <div className="flex items-center">
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                <AdjustmentsIcon className="w-5 h-5" />
                                                <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                    {this.state.metric && this.state.metric.value ? this.state.metric.name : "Impressions"}
                                                </span>
                                                <ChevronDownIcon
                                                    className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {
                                                    [
                                                        { id: 3, name: "Spend", value: "spend" },
                                                        { id: 1, name: "Impressions", value: "impressions" },
                                                        { id: 2, name: "Clicks", value: "clicks" },
                                                        { id: 4, name: "CTR", value: "ctr" },
                                                        { id: 5, name: "CPC", value: "cpc" },
                                                        { id: 6, name: "CPM", value: "cpm" }
                                                    ].map((item) => {
                                                        return (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={async (e) => {
                                                                            await this.promisedSetState({
                                                                                metric: item
                                                                            });
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-3 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="flex items-center">
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                <CalendarIcon className="w-5 h-5" />
                                                <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                    {this.state.dateSpan && this.state.dateSpan.value ? this.state.dateSpan.name : "Last 7 days"}
                                                </span>
                                                <ChevronDownIcon
                                                    className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {
                                                    [
                                                        { id: 3, name: "Last 7 days", value: "last_7" },
                                                        { id: 2, name: "Last 14 days", value: "last_14" },
                                                        { id: 1, name: "Last 30 days", value: "last_30" }
                                                    ].map((item) => {
                                                        return (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={async (e) => {
                                                                            await this.promisedSetState({
                                                                                dateSpan: item
                                                                            });
                                                                            this.functions.getClients();
                                                                            //this.functions.getClientsPerformance();
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-3 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                            {
                                false &&
                                <div className="h-120 flex pt-5">
                                    <div className="p-4 flex-1 relative">
                                        <Line
                                            options={{
                                                tooltips: {
                                                    mode: 'index'
                                                },
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            precision: 0,
                                                        }
                                                    }]
                                                }
                                            }}
                                            data={this.renders.chart()}
                                        />
                                        {
                                            this.state.loading_chart &&
                                            <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="mt-5 border-t">
                                {/*TABLE*/}
                                {
                                    this.state.clients.length > 0 &&
                                    <div className="relative overflow-scroll pb-32">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                            <thead className="bg-white">
                                                <tr>
                                                    {
                                                        this.state.columns.map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.getClients();
                                                                        }
                                                                    }}
                                                                    style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(index === 0 ? "sticky z-50 left-0 bg-white " : "") + (item.border ? "border-r " : "") + (item.value == this.state.sort ? " bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? " hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="flex flex-col">
                                                                                <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                                <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-gray-300">
                                                {
                                                    this.state.clients.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr className="border-b" key={item.id}>
                                                                    <td onClick={async () => {
                                                                        clientRegister.set({ id: item.id, name: item.name, logo: item.logo });
                                                                        this.props.history.push('/v2/performance/accounts');
                                                                    }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " border-r sticky z-50 left-0 bg-white px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div className="flex flex-row items-center">
                                                                            <div>
                                                                                <div className="h-8 w-8 overflow-hidden bg-gray-100 rounded-md flex justify-center items-center">
                                                                                    <img src={item.logo} className="bg-cover" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                {item.name ? item.name : "-"}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className={(this.state.sort === 'spend' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        <div className="w-full h-full flex flex-row">
                                                                            <div className="flex flex-1">{item.total_spend ? item.total_spend : "-"}</div>
                                                                            {
                                                                                ((this.renders.metricGoal("spend") === "highest" && this.renders.comparedValue(item.total_spend, item.total_spend_compare) > 0) || (this.renders.metricGoal("spend") === "lowest" && this.renders.comparedValue(item.total_spend, item.total_spend_compare) < 0)) &&
                                                                                <div className="flex flex-row items-center">
                                                                                    <div className="bg-green-100 text-green-500 border-green-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                        {
                                                                                            this.renders.comparedValue(item.total_spend, item.total_spend_compare) > 0 &&
                                                                                            <ArrowUpIcon className={"w-3"} />
                                                                                        }
                                                                                        {
                                                                                            this.renders.comparedValue(item.total_spend, item.total_spend_compare) < 0 &&
                                                                                            <ArrowDownIcon className={"w-3"} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className={"text-sm leading-5 text-green-500 truncate"}>
                                                                                        {this.renders.comparedValue(item.total_spend, item.total_spend_compare)}%
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                ((this.renders.metricGoal("spend") === "lowest" && this.renders.comparedValue(item.total_spend, item.total_spend_compare) > 0) || (this.renders.metricGoal("spend") === "highest" && this.renders.comparedValue(item.total_spend, item.total_spend_compare) < 0)) &&
                                                                                <div className="flex flex-row items-center">
                                                                                    <div className="bg-red-100 text-red-500 border-red-500 h-5 w-5 mr-1 rounded-full flex justify-center items-center">
                                                                                        {
                                                                                            this.renders.comparedValue(item.total_spend, item.total_spend_compare) > 0 &&
                                                                                            <ArrowUpIcon className={"w-3"} />
                                                                                        }
                                                                                        {
                                                                                            this.renders.comparedValue(item.total_spend, item.total_spend_compare) < 0 &&
                                                                                            <ArrowDownIcon className={"w-3"} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className={"text-sm leading-5 text-red-500 truncate"}>
                                                                                        {this.renders.comparedValue(item.total_spend, item.total_spend_compare)}%
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </td>
                                                                    <td className={(this.state.sort === 'impressions' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_impressions ? item.total_impressions : "-"}
                                                                    </td>
                                                                    <td className={(this.state.sort === 'clicks' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_clicks ? item.total_clicks : "-"}
                                                                    </td>
                                                                    <td className={(this.state.sort === 'ctr' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_ctr ? item.total_ctr : "-"}
                                                                    </td>
                                                                    <td className={(this.state.sort === 'cpc' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_cpc ? item.total_cpc : "-"}
                                                                    </td>
                                                                    <td className={(this.state.sort === 'cpm' ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        {item.total_cpm ? item.total_cpm : "-"}
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {/* PAGINATION */}
                                {
                                    this.state.clients.length > 0 &&
                                    <div className="flex flex-row p-4 items-center">
                                        <div className="flex justify-center items-center">
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    if (this.state.page !== 1) {
                                                        await this.promisedSetState({
                                                            page: this.state.page - 1
                                                        });
                                                        this.functions.getClients();
                                                    }
                                                }}
                                                className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                Previous
                                            </button>
                                        </div>
                                        <div className="flex flex-1 items-center justify-center">
                                            {
                                                (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                    <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                                </p>
                                            }
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <div className="">
                                                <button
                                                    onClick={async () => {
                                                        if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                            await this.promisedSetState({
                                                                page: this.state.page + 1
                                                            });
                                                            this.functions.getClients();
                                                        }
                                                    }}
                                                    className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                >
                                                    <span>Next</span>
                                                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default ClientPerformanceList;