import React, { Component, Fragment } from 'react';

import ReactDOM from 'react-dom';
import { CheckIcon, SelectorIcon, LockClosedIcon, SearchIcon, IdentificationIcon } from '@heroicons/react/outline'
import { Listbox, Transition } from '@headlessui/react';
const fuzzysort = require('fuzzysort');

class MultiDropdownTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locked: false,
            search: "",
            label: "",
            options: [],
            selected: [],
            default: {},
            placeholder: "",
            total: 0,
            defaultOptions: [],
            error: false,
            addPlusExtra: 0,
            icon: false,
            channel_icon: false,
            smallText: false,
            open: false,
            defaultSelected: { id: 1, name: "All (default)", value: "all" },
            searchInput: false,
            pagination: false,
            showPagination: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            search: this.props.search,
            label: this.props.label,
            selected: this.props.selected,
            options: this.props.options,
            default: this.props.default,
            loader: this.props.loader,
            disabled: this.props.disabled,
            locked: this.props.locked,
            placeholder: this.props.placeholder,
            channel: this.props.channel,
            loadingSearch: this.props.loadingSearch,
            loadingPagination: this.props.loadingPagination,
            rightLabelText: this.props.rightLabelText,
            pagination: this.props.pagination,
            small: this.props.small,
            total: this.props.total,
            defaultOptions: this.props.defaultOptions,
            skipInternalSearch: this.props.skipInternalSearch,
            error: this.props.error,
            addPlusExtra: this.props.addPlusExtra,
            icon: this.props.icon,
            channel_icon: this.props.channel_icon,
            smallText: this.props.smallText,
            defaultSelected: this.props.defaultSelected,
            searchInput: this.props.searchInput,
            pagination: this.props.pagination,
            showPagination: this.props.showPagination
        })
        let self = this;
        const element = document.documentElement;
        element.addEventListener("click", function () {
            if (!self.state.hover) {
                self.setState({
                    open: false
                })
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            search: nextProps.search,
            label: nextProps.label,
            selected: nextProps.selected,
            options: nextProps.options,
            loader: nextProps.loader,
            disabled: nextProps.disabled,
            locked: nextProps.locked,
            placeholder: nextProps.placeholder,
            channel: nextProps.channel,
            loadingSearch: nextProps.loadingSearch,
            loadingPagination: nextProps.loadingPagination,
            rightLabelText: nextProps.rightLabelText,
            pagination: nextProps.pagination,
            small: nextProps.small,
            total: nextProps.total,
            defaultOptions: nextProps.defaultOptions,
            skipInternalSearch: nextProps.skipInternalSearch,
            error: nextProps.error,
            addPlusExtra: nextProps.addPlusExtra,
            icon: nextProps.icon,
            channel_icon: nextProps.channel_icon,
            smallText: nextProps.smallText,
            defaultSelected: nextProps.defaultSelected,
            searchInput: nextProps.searchInput,
            pagination: nextProps.pagination,
            showPagination: nextProps.showPagination
        })
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }
    functions = {
        resetSearch: () => {
            this.setState({
                search: ""
            })
        }
    };

    filter = () => {
        let options = this.state.options;
        try {
            if (!this.state.skipInternalSearch && this.state.search && this.props.searchInput && this.state.search !== "") {
                let result = fuzzysort.go(this.state.search, this.state.options.map((item) => { return item.name }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(this.state.options));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            } else {
                if (this.props.limitList) {
                    options = options.filter((item, index) => {
                        return index < 20
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <label className="text-xs flex flex-row font-medium text-gray-700 ">
                    <div className={" flex flex-row"}>
                        {this.props.label ? this.props.label : ""}
                    </div>
                </label>
                <div className='w-full relative'>
                    <button
                        onMouseEnter={() => {
                            this.setState({
                                hover: true
                            })
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                hover: false
                            })
                        }}
                        onClick={async () => {
                            await this.promisedSetState({
                                open: !this.state.open
                            })
                        }}
                        className='h-10 border-gray-300 whitespace-no-wrap truncate relative w-full bg-custom-input border-1.5 rounded-md  pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm'>
                        {
                            this.state.selected.length > 0 &&
                            <span>{this.state.selected.map((item) => { return item.name }).join(", ")}</span>
                        }
                        {
                            this.state.selected.length < 1 &&
                            <span>{this.state.defaultSelected.name}</span>
                        }
                        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="h-5 w-5 text-gray-400"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4"></path></svg></span>
                    </button>
                    {
                        this.state.open &&
                        <ul
                            onMouseEnter={() => {
                                this.setState({
                                    hover: true
                                })
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    hover: false
                                })
                            }}
                            class="absolute border-1.5 border-gray-300 z-60 mt-1 w-full bg-white shadow-lg max-h-40 rounded-md p-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" aria-labelledby="headlessui-listbox-button-3" aria-orientation="vertical" id="headlessui-listbox-options-36" role="listbox" tabindex="0">
                            <div class="relative">
                                {
                                    this.props.searchInput &&
                                    <div className="sticky top-0 z-20 left-0 right-0">
                                        <div className={(this.filter().length > 0 ? "mb-1" : "") + " bg-gray-100 rounded-md justify-center items-center overflow-hidden flex flex-row"}>
                                            <div className="px-3 text-gray-500 text-opacity-75">
                                                <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                            </div>
                                            <input
                                                id={"test"}
                                                type={this.props.type}
                                                disabled={this.state.disabled}
                                                className={(this.props.small ? "h-10" : "h-12") + " bg-gray-100 block font-normal w-full border-none pr-4 py-2 focus:outline-none text-sm"}
                                                placeholder={this.state.placeholder}
                                                value={this.state.search}
                                                onChange={(event) => {
                                                    if (this.props.onSearch) {
                                                        this.props.onSearch(event.target.value);
                                                    }
                                                    this.setState({
                                                        search: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                <div class="relative text-sm">
                                    {
                                        this.props.searchInput &&
                                        this.filter().length > 0 &&
                                        this.state.loadingSearch &&
                                        <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    {
                                        this.state.options.map((item, index) => {
                                            return (
                                                <div onClick={() => {
                                                    if (this.props.onChange) {
                                                        if (item.id === 1) {
                                                            this.state.selected = [];
                                                        } else {
                                                            if (this.state.selected.filter((i) => { return i.id === item.id }).length > 0) {
                                                                this.state.selected = this.state.selected.filter((i) => { return i.id !== item.id });
                                                            } else this.state.selected.push(item);
                                                        }
                                                        this.setState({
                                                            selected: this.state.selected
                                                        })

                                                        this.props.onChange(this.state.selected)
                                                    }
                                                }} class={' hover:bg-gray-200 cursor-pointer rounded-md focus:outline-none py-2 pl-3 flex h-10' + (index !== 0 ? " pt-2" : "")}>

                                                    <div class="flex items-center">
                                                        {
                                                            item.id !== 1 &&
                                                            <div className={(this.state.selected && this.state.selected.filter((i) => { return i.id === item.id }).length > 0 ? "border-purple-500 bg-purple-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center rounded-md items-center border-2"}>
                                                                <CheckIcon className={(this.state.selected && this.state.selected.filter((i) => { return i.id === item.id }).length > 0 ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        }
                                                        <div className='ml-2'>
                                                            {item.name}
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.state.showPagination && this.state.options.length < this.state.total &&
                                        <div className="sticky bottom-0 z-20 left-0 right-0 pt-1">
                                            <div onClick={() => {
                                                if (this.props.onPagination && !this.state.loadingPagination) {
                                                    this.props.onPagination();
                                                }
                                            }} className={(this.props.small ? "h-10" : "h-12") + " bg-purple-500 relative flex text-center font-medium cursor-pointer hover:bg-purple-600 text-white text-sm rounded-md justify-center items-center overflow-hidden"}>
                                                Load more ({this.state.options.length}/{this.state.total})
                                                {
                                                    this.state.loadingPagination &&
                                                    <div className="absolute flex items-center justify-center left-0 top-0 bottom-0 right-0 bg-purple-500">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            className="w-4 h-4 border-2 border-white-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ul>
                    }
                </div>
            </>

        )
    }

}


export default MultiDropdownTailwind;
