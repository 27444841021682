import { tokenRegister } from "./tokenRegister";
import { apiRegister } from "./apiRegister";

class dataRegister {

    static url = {
        api: apiRegister.url.api,
    };

    static login(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/login", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static forgotPassword(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/password/forgot", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static resetPassword(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/password/reset", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updatePassword(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/password/change", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static register(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/register", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUser() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/user/", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getCard() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/card/get", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static addCard(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/card/add", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeCard(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/card/remove", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getButton(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            fetch(dataRegister.url.api + "/button/get/public/" + data.id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getAdminButtons() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
            };
            fetch(dataRegister.url.api + "/button/list/admin", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createAdminButton(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/button/create/admin", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createUserButtonDonation(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/button/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUserButtonDonation() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/button/get", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeUserButtonDonation(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/button/remove", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createUserButtonCustom(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/button/create/custom", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUserButtonsCustom() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/button/list/custom", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createTransaction(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/transaction/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateUser(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/user/update", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeUser(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
            };
            fetch(dataRegister.url.api + "/user/remove", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUsers(admin) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/user/list/specific/" + admin, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUsersAll() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/user/list/all", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createUsers(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/user/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createOrganization(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/organization/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeOrganization(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/organization/remove", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getOrganizations() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/organization/admin", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getOrganizationsPublic() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/organization/public", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getStatistics() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/statistics", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getStatisticsOrganizations() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/statistics/organizations", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createSiteWorkplace(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/site/create/workplace", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createSiteCharity(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/site/create/charity", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeSite(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/site/remove", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateSite(id, data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/site/update/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateRelatedSite(id, data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/site/update/related/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getSite(id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/site/get/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getSites() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/site/list", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getSiteTraffic(id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/analytics/traffic/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createClient(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/client/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeClient(id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/client/remove/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getClients() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/client/list", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static updateClientFacebook(data, id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/client/facebook/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static removeClientFacebook(id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/client/facebook/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static createCampaign(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/campaign/create", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getCampaigns() {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/campaign/get", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getSiteCampaigns(id) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                }
            };
            fetch(dataRegister.url.api + "/campaign/get/" + id, options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static uploadImage(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'x-auth-token': tokenRegister.get()
                },
                body: data
            };
            fetch(dataRegister.url.api + "/image/upload", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static uploadLogo(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'x-auth-token': tokenRegister.get()
                },
                body: data
            };
            fetch(dataRegister.url.api + "/image/logo", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getUrlData(data) {
        return new Promise(function (resolve, reject) {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': tokenRegister.get()
                },
                body: JSON.stringify(data)
            };
            fetch(dataRegister.url.api + "/scraper/meta", options)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    return response.json().then(json => {
                        return Promise.reject({
                            status: response.status,
                            ok: false,
                            statusText: response.statusText,
                            body: json
                        });
                    });
                })
                .then((response) => {
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

}

export { dataRegister }