import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon, PencilAltIcon, TrashIcon, ArrowDownIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { clientRegister } from '../services/clientRegister';

class WizardList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "created",
            direction: "descending",
            orders: [],
            order: { id: 0, name: "Filter by order" },
            drafts: [],
            campaigns: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'created', value: 'created', min_width: '250px' },
                { name: 'client', value: 'client', min_width: '250px', noSort: true },
                { name: 'order', value: 'order', min_width: '250px', noSort: true },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channels', value: 'channels', min_width: '250px', noSort: true }
            ],
            status: { id: 0, name: "All", value: "total" },
            stats: [
                { id: 0, name: "All", value: "total", },
                { id: 1, name: "Draft", value: "draft", link: "/v2/campaigns/draft" },
                { id: 2, name: "Pending", value: "pending", link: "/v2/campaigns/pending" },
                { id: 3, name: "Error", value: "error", link: "/v2/campaigns/error" }
            ],
            client: {},
            filters: [],
            search: "",
            throttling: {}
        }
    };

    async componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page") || 1;
        let limit = params.get("limit") || this.state.limit;
        let sort = params.get("sortBy") || this.state.sort;
        let direction = params.get("orderBy") || this.state.direction;
        let search = params.get("search") || this.state.search;

        await this.promisedSetState({
            page: +page,
            limit: +limit,
            sort: sort,
            direction: direction,
            ...(search && { search: search }),
        })


        this.functions.client();
        this.functions.query();
        this.props.updateStatistics();
    }

    // ON BACK BUTTON
    async componentWillReceiveProps(nextProps, nextContext) {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page")
        let limit = params.get("limit")
        let sort = params.get("sortBy")
        let direction = params.get("orderBy")
        let search = params.get("search");

        if (page && page !== this.state.page) {
            await this.promisedSetState({
                page: +page,
                limit: +limit,
                sort: sort,
                direction: direction,
                ...(search && { search: search }),
            })
            this.functions.wizards();
        } else if (!window.location.pathname.includes("?limit=")) {
            await this.promisedSetState({
                page: 1,
                limit: 10,
                sort: this.state.sort,
                direction: this.state.direction,
                ...(search && { search: search }),
            })
            this.functions.wizards();
        }
    }

    functions = {
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            let status = null;

            //SET STATUS
            this.state.stats.map((item) => {
                if (item.link === window.location.pathname) {
                    status = item;
                }
            })
            await this.promisedSetState({
                status: status ? status : this.state.status
            });

            //CHECK IF ORDER
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("order=") !== -1) {
                    let value = queries[i].replace("order=", "");
                    let order = null;
                    this.state.orders.map((item) => {
                        if (item.id == value) {
                            order = item;
                        }
                    });
                    await this.promisedSetState({
                        order: order ? order : this.state.order
                    });
                } else if (queries[i].indexOf("status=") !== -1) {
                    let value = queries[i].replace("status=", "");
                    let status = null;
                    this.state.stats.map((item) => {
                        if (item.value == value) {
                            status = item;
                        }
                    });
                    await this.promisedSetState({
                        status: status ? status : this.state.status
                    });
                }
            }
            this.functions.wizards(true);
            this.props.updateStatistics();
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.query();
        },
        wizards: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.wizards();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ campaigns: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        clone: async (wizard) => {
            this.state.campaigns.map((item) => { if (item.id === wizard.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                let response = await this.calls.clone(wizard.id);
                let cloned_wizard = JSON.parse(JSON.stringify(wizard));
                cloned_wizard.id = response.data.id;
                cloned_wizard.loading = false;
                this.state.campaigns.push(cloned_wizard);
                this.state.campaigns.map((item) => { if (item.id === wizard.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === wizard.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
            this.props.updateStatistics();
        },
        template: async (wizard) => {
            this.state.campaigns.map((item) => { if (item.id === wizard.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.template(wizard.id);
            } catch (error) { }
            this.state.campaigns.map((item) => { if (item.id === wizard.id) { item.loading = false } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            this.props.updateStatistics();
            this.props.history.push("/v2/templates");
        },
        menu: (option, row) => {
            if (option === "Open") {
                this.props.history.push("/v2/campaigns/new/channels?draft=" + (row.id ? row.id : row._id));
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            } else if (option === "Clone") {
                this.functions.clone(row);
            } else if (option === "Order") {
                this.props.history.push("/v2/orders/" + (row.order ? row.order.id : null));
            } else if (option === "Preview") {
                window.open(window.location.protocol + "//" + window.location.host + "/preview/v2/" + row.id, "_blank");
            } else if ("Create template") {
                this.functions.template(row);
            }
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : ""))
                );
            } catch (error) {
                console.log(error)
            }
        }
    };

    calls = {
        wizards: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listWizards?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&order=" + this.state.order.id + "&status=" + this.state.status.value + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeWizard?id=" + id;
            return apiRegister.call(options, url);
        },
        clone: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/cloneWizard?id=" + id;
            return apiRegister.call(options, url);
        },
        template: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', { wizard: id, template: true });
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "new") {
                                this.props.history.push("/v2/campaigns/new/client");
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.wizards();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                            this.functions.history()
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                            this.functions.history()
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.wizards(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta[this.state.status.value] : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[
                            { text: "Create new", icon: PencilAltIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.wizards();
                            this.props.updateStatistics(client);
                        }}
                        client={this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading drafts ...</div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-t-lg w-full overflow-hidden h-full">

                            {/*NO DRAFTS*/}
                            {
                                this.state.campaigns.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative rounded-lg">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-sm font-medium ">No drafts available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="overflow-x-auto  table-overflow h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    })
                                                                    this.functions.wizards();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(this.state.sort === item.value ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.campaigns.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                {/* <Menu.Items className={`${this.state.campaigns.length - (index + 1) < 5 && this.state.campaigns.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Open", "Preview", "Create template", "Delete"].filter((item) => {
                                                                                            if (item === "Open" || item === "Preview" || item === "Create template") {
                                                                                                return this.state.status.value !== "pending"
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.history()
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Open", item);
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.created ? moment(item.created).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'client' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {
                                                                        item.client &&
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="h-8 w-8 p-1 rounded-full overflow-hidden flex justify-center items-center border">
                                                                                <img src={item.client.logo} className="bg-cover" />
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <div className="font-medium text-sm">{item.client.name}</div>
                                                                                <div className="text-xxs">{item.client.company}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Order", item);
                                                                }} className={(this.state.sort === 'order' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.order ? item.order.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "archived" || item.status === "ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "pending",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "draft",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error",
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    <div className="flex flex-row items-center pl-2">
                                                                        {((item.status !== "draft" && item.channel === "facebook") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["facebook"])) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "linkedin") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["linkedin"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "google") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["google"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "tiktok") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["tiktok"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "twitter") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["twitter"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "snapchat") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["snapchat"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.status !== "draft" &&
                                                                            <div className="ml-3 font-medium">
                                                                                {item.channel}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>


                        {/* PAGINATION */}
                        {
                            this.state.campaigns.length > 0 &&
                            <div className="flex flex-row p-4 items-center bg-white rounded-b-lg shadow">
                                <div className="flex justify-center items-center">
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (this.state.page !== 1) {
                                                await this.promisedSetState({
                                                    page: this.state.page - 1
                                                });
                                                this.functions.wizards();
                                            }
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                        Previous
                                    </button>
                                </div>
                                <div className="flex flex-1 items-center justify-center">
                                    {
                                        (this.state.meta ? +this.state.meta[this.state.status.value] : 0) !== 0 &&
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta[this.state.status.value] : 0)) ? (this.state.meta ? +this.state.meta[this.state.status.value] : 0) : this.state.page * this.state.limit}</span> of{' '}
                                            <span className="font-medium">{(this.state.meta ? +this.state.meta[this.state.status.value] : 0)}</span> results
                                        </p>
                                    }
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="">
                                        <button
                                            onClick={async () => {
                                                if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta[this.state.status.value] : 0))) {
                                                    await this.promisedSetState({
                                                        page: this.state.page + 1
                                                    });
                                                    this.functions.wizards();
                                                }
                                            }}
                                            className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta[this.state.status.value] : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <span>Next</span>
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>
        )
    }
}

export default WizardList;
