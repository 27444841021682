import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";

class Accordion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open?this.props.open:false,
            noBottomBorder: this.props.noBottomBorder,
            inner: this.props.inner,
            channels: {}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            channels: this.props.channels?this.props.channels:{}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open
        })
    }

    init = {};

    functions = {};

    renders = {
        markdown: (input) => {
            return { __html: input }
        }
    };

    render(){
        return (
            <div className="pt-2 mt-2 border-t">
                <div>
                    <div onClick={() => {
                        this.setState({open: !this.state.open}, () => {
                            try{
                                this.props.onOpen(this.state.open);
                            }catch (e) {

                            }
                        })
                    }} className={
                        cx("Accordion-InnerContainerInnerTop",
                            {
                                ["Accordion-InnerContainerInnerTopNoPadding"]: this.props.noPadding
                            })
                    }>
                        <div style={this.props.errorTitle?{color: '#FC5353'}:{}} className="Accordion-InnerContainerInnerTopTitle">
                            {
                                (this.props.type === 'search') &&
                                <div style={{marginRight: '2px'}}>
                                    Click here to edit your <b>Search Ads</b>
                                </div>
                            }
                            {
                                (this.props.type === 'social') &&
                                <div style={{marginRight: '2px'}}>
                                    Click here to edit your <b>Social Ads</b>
                                </div>
                            }
                            {
                                !this.props.type &&
                                <div style={{marginRight: '2px'}}>
                                    {this.props.title}
                                </div>
                            }
                            {
                                this.state.channels.google && this.props.type === 'search' && false &&
                                <div style={{marginLeft: '3px'}}>
                                    <IconGoogle noMargin={true}/>
                                </div>
                            }
                            {
                                this.state.channels.facebook && this.props.type === 'social' &&  false &&
                                <div style={{marginLeft: '3px'}}>
                                    <IconFacebook noMargin={true}/>
                                </div>
                            }
                            {
                                this.state.channels.instagram && this.props.type === 'social' &&  false &&
                                <div style={{marginLeft: '3px'}}>
                                    <IconInstagram noMargin={true}/>
                                </div>
                            }
                        </div>
                        <div className="Accordion-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("Accordion-InnerContainerInnerTopArrow",
                                    {
                                        ["Accordion-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={25} icon="chevron-down"/>
                            <div className="Accordion-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("Accordion-InnerContainerInnerTopMinus",
                                        {
                                            ["Accordion-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={25} icon="chevron-up"/>
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        {this.props.children}
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default Accordion;
