class adgroupSnapchat {

    static validate(adgroup, campaigns = false, live = false) {
        let error = {
            name: false,
            budget: false,
            location: false,
            start_date: false,
            end_date: false,
            out_of_range: false,
        };
        if (adgroup && !live) {
            if (!adgroup.name || adgroup.name === "") error.name = true;
            if (adgroup.budget_mode) {
                if (adgroup.budget_mode.value === "BUDGET_MODE_LIFETIME" && (adgroup.lifetime_budget === "" || parseInt(adgroup.lifetime_budget) < 40 || isNaN(parseInt(adgroup.lifetime_budget)))) {
                    error.budget = true;
                } else if (adgroup.budget_mode.value === "BUDGET_MODE_DAILY" && (adgroup.daily_budget === "" || parseInt(adgroup.daily_budget) < 5 || isNaN(parseInt(adgroup.daily_budget)))) {
                    error.budget = true;
                }
                if (adgroup.budget_mode.value === "BUDGET_MODE_LIFETIME" && (!adgroup.end_date || adgroup.end_date === "" || adgroup.end_date === null)) {
                    error.end_date = true;
                }
            }
            if (!adgroup.locations || adgroup.locations.filter((item) => { return item.included }).length < 1) {
                error.location = true;
            }
            if (adgroup.start_date && typeof adgroup.status !== "string") {
                if (campaigns) {
                    let campaign = campaigns.filter((item) => { return item.id === adgroup.campaign });
                    if (campaign.length > 0) {
                        if (!campaign[0].end_date) {
                            if (new Date(adgroup.start_date).getTime() < new Date(campaign[0].start_date).getTime()) {
                                error.out_of_range = true;
                            }
                        } else {
                            if (new Date(adgroup.start_date).getTime() < new Date(campaign[0].start_date).getTime() || new Date(adgroup.start_date).getTime() > new Date(campaign[0].end_date).getTime()) {
                                error.out_of_range = true;
                            }
                        }
                    }

                }

                if (new Date(adgroup.start_date).getTime() <= new Date().getTime()) {
                    error.start_date = true;
                }

            }
            if (adgroup.end_date && typeof adgroup.status !== "string") {
                if (campaigns) {
                    let campaign = campaigns.filter((item) => { return item.id === adgroup.campaign });
                    if (campaign.length > 0) {
                        if (campaign[0].end_date) {
                            if (new Date(adgroup.end_date).getTime() > new Date(campaign[0].end_date).getTime() || new Date(adgroup.end_date).getTime() < new Date(campaign[0].start_date).getTime()) {
                                error.out_of_range = true;
                            }
                        } else {
                            if (new Date(adgroup.end_date).getTime() < new Date(campaign[0].start_date).getTime()) {
                                error.out_of_range = true;
                            }
                        }
                    }
                }
                if (new Date(adgroup.end_date).getTime() <= new Date(adgroup.start_date).getTime() || new Date(adgroup.end_date).getTime() <= new Date().getTime()) {
                    error.end_date = true;
                }

            }
            if (adgroup.end_date === null && campaigns) {
                if (campaigns && Array.isArray(campaigns) && campaigns.length > 0) {
                    let campaign = campaigns.filter((item) => { return item.id === adgroup.campaign });
                    if (campaign.length > 0) {
                        if (campaign[0].end_date) {
                            error.out_of_range = true;
                        }
                    }
                }
            }
        }
        if (adgroup && live) {
            if (adgroup.start && campaigns.length > 0) {
                if (campaigns[0].end && campaigns[0].end !== null) {
                    if (new Date(adgroup.start).getTime() < new Date(campaigns[0].start).getTime() || new Date(adgroup.start).getTime() < new Date().getTime() || new Date(adgroup.start).getTime() > new Date(campaigns[0].end).getTime()) {
                        error.start_date = true;
                    }
                } else {
                    if (new Date(adgroup.start).getTime() < new Date(campaigns[0].start).getTime() || new Date(adgroup.start).getTime() < new Date().getTime()) {
                        error.start_date = true;
                    }
                }
            }
            if (adgroup.end && campaigns.length > 0) {
                if (campaigns[0].end && campaigns[0].end !== null) {
                    if (new Date(adgroup.end).getTime() > new Date(campaigns[0].end).getTime() || new Date(adgroup.end).getTime() < new Date(adgroup.start).getTime() || new Date(adgroup.end).getTime() < new Date(campaigns[0].start).getTime()) {
                        error.end_date = true;
                    }
                } else {
                    if (new Date(adgroup.end).getTime() < new Date(adgroup.start).getTime() || new Date(adgroup.end).getTime() < new Date(campaigns[0].start).getTime()) {
                        error.end_date = true;
                    }
                }
            } else if (adgroup.end === null && campaigns.length > 0) {
                if (campaigns[0].end && campaigns[0].end !== null) {
                    error.end_date = true;
                }
            }
        }
        return error;
    }
}
export { adgroupSnapchat }