import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import {calls} from "../client-instagram/calls";
import Dropdown from "../dropdown";
import Input from "../input";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientInstagramPixel from "../client-instagram-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import ClientFacebookRequestAdaccount from "../client-facebook-request-adaccount";
import ClientFacebookRequestPage from "../client-facebook-request-page";
import ReactTooltip from "react-tooltip";

class ClientInstagram extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            instagram: {},
            instagrams: [],
            business: {},
            businesses: [],
            businesses_loading: false,
            adaccount: {},
            owned_adaccount: {},
            owned_adaccounts: [],
            owned_adaccount_loading: false,
            client_adaccount: {},
            client_adaccounts: [],
            client_adaccounts_loading: false,
            page: {},
            owned_page: {},
            client_page: {},
            owned_pages: [],
            owned_pages_loading: false,
            client_pages: [],
            client_pages_loading: false,
            pixel: {},
            pixels: [],
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        }, () => {
            this.init.facebook();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item?nextProps.item:{}
        });
    }

    init = {
        facebook: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '3033879003330818',
                    version: 'v6.0',
                    redirect_uri: '',
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    };

    functions = {
        login: () => {
            let self = this;
            window.FB.login(function (response) {
                if (response.authResponse && response.status === "connected") {
                    self.setState({
                        token: response.authResponse.accessToken
                    }, () => {
                        self.functions.user();
                        self.functions.businesses();
                    });
                }
            }, {
                scope: 'ads_management, ads_read, business_management, manage_pages',
                return_scopes: true
            })
        },
        logout: () => {
            this.setState({
                token: null,
                userName: null,
                userId: null,
                instagram: {},
                instagrams: [],
                business: {},
                businesses: [],
                businesses_loading: false,
                adaccount: {},
                owned_adaccount: {},
                owned_adaccounts: [],
                owned_adaccount_loading: false,
                client_adaccount: {},
                client_adaccounts: [],
                client_adaccounts_loading: false,
                page: {},
                owned_page: {},
                client_page: {},
                owned_pages: [],
                owned_pages_loading: false,
                client_pages: [],
                client_pages_loading: false,
                pixel: {},
                pixels: [],
            })
        },
        user: () => {
            calls.getAccount(this.state.token).then((response) => {
                this.setState({
                    userName: response.name,
                    userId: response.id
                })
            }, (error) => {
                console.log(error);
            });
        },
        payment: () => {
            let self = this;
            let id = "";
            try{
                id = this.state.adaccount.id;
            }catch (e) {}
            try{
                id = this.state.adaccount.id.toLowerCase();
                id = id.replace("act_", "");
            }catch (e) {}
            window.FB.ui({
                account_id: id,
                display: 'popup',
                method: 'ads_payment',
            }, function (response) {
                //console.log(response);
                try{
                    if(response.success){
                        self.functions.adaccount(self.state.adaccount.id);
                    }
                }catch (e) {}
            })
        },
        businesses: () => {
            this.setState({
                businesses_loading: true
            }, () => {
                calls.getBusinesses(this.state.token).then((response) => {
                    this.setState({
                        business: {},
                        businesses: response.data,
                        businesses_loading: false
                    })
                }, (error) => {
                    this.setState({
                        businesses_error: true,
                        businesses_loading: error
                    })
                });
            })
        },
        adaccounts: () => {
            this.setState({
                owned_adaccount_loading: true
            }, () => {
                calls.getAdaccounts(this.state.business.id, this.state.token).then((response) => {
                    this.setState({
                        adaccount: {},
                        owned_adaccount: {},
                        adaccount_new: false,
                        adaccount_error: false,
                        owned_adaccounts: response.data,
                        owned_adaccount_loading: false
                    })
                }, (error) => {
                    this.setState({
                        owned_adaccounts_error: true,
                        owned_adaccount_loading: error
                    })
                });
            })
        },
        clientAdaccounts: () => {
            this.setState({
                client_adaccount_loading: true
            }, () => {
                calls.getClientAdaccounts(this.state.business.id, this.state.token).then((response) => {
                    this.setState({
                        adaccount: {},
                        client_adaccount: {},
                        adaccount_new: false,
                        adaccount_error: false,
                        client_adaccounts: response.data,
                        client_adaccount_loading: false
                    })
                }, (error) => {
                    this.setState({
                        client_adaccounts_error: true,
                        client_adaccount_loading: error
                    })
                });
            })
        },
        adaccount: (id) => {
            this.setState({
                adaccount: {},
                page: {},
                owned_page: {},
                client_page: {},
                instagram: {},
                owned_adaccount_loading: true
            }, () => {
                calls.getAdaccount(id, this.state.token).then((response) => {
                    this.setState({
                        owned_adaccount_loading: false,
                        adaccount_error: false,
                        adaccount: response,
                    })
                }, (error) => {
                    this.setState({
                        owned_adaccount_loading: false,
                    })
                });
            })
        },
        pages: () => {
            this.setState({
                owned_pages_loading: true
            }, () => {
                if(!this.state.item.facebook){
                    calls.getPages(this.state.business.id, this.state.token).then((response) => {
                        this.setState({
                            page: {},
                            owned_page: {},
                            owned_pages: response.data,
                            owned_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            owned_pages_error: true,
                            owned_pages_loading: false
                        })
                    });
                }else{
                    calls.getInternalPages({client: this.state.item.id, channel: "facebook"}).then((response) => {
                        this.setState({
                            page: {},
                            owned_page: {},
                            owned_pages: response.data,
                            owned_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            owned_pages_error: true,
                            owned_pages_loading: false
                        })
                    });
                }
            })
        },
        clientPages: () => {
            this.setState({
                client_pages_loading: true
            }, () => {
                if(!this.state.item.facebook){
                    calls.getClientPages(this.state.business.id, this.state.token).then((response) => {
                        this.setState({
                            page: {},
                            client_page: {},
                            client_pages: response.data,
                            client_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            client_pages_error: true,
                            client_pages_loading: false
                        })
                    });
                }else{
                    calls.getInternalClientPages({client: this.state.item.id, channel: "facebook"}).then((response) => {
                        this.setState({
                            page: {},
                            client_page: {},
                            client_pages: response.data,
                            client_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            client_pages_error: true,
                            client_pages_loading: false
                        })
                    });
                }
            })
        },
        updatePage: (page) => {
            this.setState({
                client_pages_loading: true
            }, () => {
                let image = null;
                try{
                    image = page.picture.data.url;
                }catch (e) {}
                calls.updatePage({
                    facebookPageId: page.id,
                    facebookPageName: page.name,
                    facebookPageImage: image,
                    client: this.state.item.id,
                    update: 'page'
                }).then((response) => {
                    this.state.item.instagram = response.data;
                    this.setState({
                        client_pages_loading: false,
                        item: this.state.item
                    })
                }, (error) => {
                    this.setState({
                        client_pages_error: true,
                        client_pages_loading: false
                    })
                });
            });
        },
        instagram: (id) => {
            this.setState({
                instagram_loading: true
            }, () => {
                calls.getInstagramAccounts(id, this.state.token).then((response) => {
                    this.setState({
                        instagram: {},
                        instagrams: response.data.map((item) => {
                            return {name: item.username, id: item.id}
                        }),
                        instagram_loading: false
                    })
                }, (error) => {
                    this.setState({
                        instagram_error: error,
                        instagram_loading: false
                    })
                });
            })
        },
        pixels: (id) => {
            this.setState({
                pixel: {},
            }, () => {
                calls.getPixels(id, this.state.token).then((response) => {
                    this.setState({
                        pixels: response.data,
                    })
                }, (error) => {
                    console.log(error);
                });
            })
        },
        reset: () => {
            this.setState({
                loading: true,
                remove_modal: false
            }, () => {
                calls.removeInstagramSettings(this.state.item.id).then((response) => {
                    delete this.state.item.instagram;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                        this.functions.logout();
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });
                });
            });
        },
        settings: () => {
            this.setState({
                loading: true
            }, () => {

                let object = {
                    facebookUserId: this.state.userId,
                    facebookUserName: this.state.userName,
                    facebookUserToken: this.state.token,
                    facebookBusinessManagerId: this.state.business.id,
                    facebookBusinessManagerName: this.state.business.name,
                    facebookAdAccountId: this.state.adaccount.id,
                    facebookAdAccountName: this.state.adaccount.name,
                    facebookAdAccountCurrency: this.state.adaccount.currency,
                    facebookPageId: this.state.page.id,
                    facebookPageName: this.state.page.name,
                    instagramUserId: this.state.instagram.id,
                    instagramUserName: this.state.instagram.name
                };

                if(this.state.facebookAdPixelName && this.state.facebookAdPixelId){
                    object.facebookAdPixelName = this.state.pixel.name;
                    object.facebookAdPixelId = this.state.pixel.id;
                }

                try {
                    object.facebookPageImage = this.state.page.picture.data.url;
                }catch (e) {}
                calls.updateInstagramSettings(object, this.state.item.id).then((response) => {
                    this.state.item.instagram = response.data;
                    this.setState({
                        item: this.state.item,
                        loading: false,
                        open: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                    });
                }, (error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });
            });
        },
        updateToken: () => {

        }
    };

    renders = {
        submenus: () => {
            let array = [
                {id: 0, name: "Add adaccount"},
                {id: 1, name: "Create adaccount"}
            ];
            if(this.state.token || this.state.item.instagram){
                array.push({id: 2, name: "Setup pixel"});
                array.push({id: 3, name: "Request adaccount"});
                array.push({id: 4, name: "Request page"});
            }
            return array;
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Error"
                    type="error"
                    text={"Payment method doesnt exist for this adaccount, please select another"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ warning_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Facebook"
                    type="warning"
                    text={"You wont be able access any Facebook campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.reset();
                    }}
                    onCancel={() => {
                        this.setState({remove_modal: false});
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            this.state.token && !this.state.item.instagram &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Dropdown
                                    filter={false}
                                    title={'Select a business manager'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "business" && !this.state.business.name}
                                    options={this.state.businesses}
                                    loading={this.state.businesses_loading}
                                    value={this.state.business}
                                    onChange={(value) => this.setState({business: value}, () => {
                                        this.functions.adaccounts();
                                        this.functions.pages();
                                        this.functions.clientAdaccounts();
                                        this.functions.clientPages();
                                    })}
                                />
                            </div>
                        }
                        {
                            this.state.item.instagram &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Input
                                    title={'Facebook user'}
                                    value={this.state.item.instagram.facebookUserName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.item.instagram &&
                            <div className="Section">
                                <Input
                                    title={'Selected business manager'}
                                    value={this.state.item.instagram.facebookBusinessManagerName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.token && this.state.business.name && !this.state.item.instagram &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select account'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "adaccount" && !this.state.owned_adaccount.name}
                                    options={this.state.owned_adaccounts.concat(this.state.client_adaccounts)}
                                    loading={this.state.owned_adaccount_loading || this.state.client_adaccount_loading}
                                    value={this.state.owned_adaccount}
                                    extraItem={"currency"}
                                    extraSecondItem={"funding_source"}
                                    onChange={(value) => {
                                        if(!value.funding_source){
                                            this.setState({
                                                warning_modal: true
                                            })
                                        }else{
                                            this.setState({
                                                client_adaccount: {},
                                                page: {},
                                                pixel: {},
                                                owned_page: {},
                                                client_page: {},
                                                adaccount: value,
                                                owned_adaccount: value,
                                            }, () => {
                                                //this.functions.adaccount(value.id);
                                                this.functions.pixels(value.id);
                                                this.functions.instagram(value.id);
                                            })
                                        }
                                    }}
                                />
                                <div onClick={() => {
                                    this.functions.adaccounts();
                                    this.functions.clientAdaccounts();
                                }} data-tip={"Update adaccounts"} style={{marginTop: '26px', marginLeft: '10px', width: '55px'}} className="Button ButtonNeutral">
                                    <FeatherIcon color="#333333" size={20} icon="refresh-ccw"/>
                                    <ReactTooltip />
                                </div>
                            </div>
                        }
                        {
                            this.state.item.instagram &&
                            <div className="Section">
                                <Input
                                    title={'Selected adaccount'}
                                    value={this.state.item.instagram.facebookAdAccountName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.instagram && !this.state.adaccount.funding_source &&
                            <div className="Section">
                                <div onClick={() => this.functions.payment()} className="Button ButtonFull ButtonNeutral">
                                    Add payment method
                                </div>
                            </div>
                        }
                        {
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.instagram && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select page'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "page" && !this.state.owned_page.name}
                                    options={this.state.owned_pages.concat(this.state.client_pages)}
                                    loading={this.state.owned_pages_loading || this.state.client_pages_loading}
                                    value={this.state.owned_page}
                                    onChange={(value) => this.setState({owned_page: value, page: value, client_page: {}}, () => {

                                    })}
                                />
                                <div onClick={() => {
                                    this.functions.pages();
                                    this.functions.clientPages();
                                }} data-tip={"Update pages"} style={{marginTop: '26px', marginLeft: '10px', width: '55px'}} className="Button ButtonNeutral">
                                    <FeatherIcon color="#333333" size={20} icon="refresh-ccw"/>
                                    <ReactTooltip />
                                </div>
                            </div>
                        }
                        {
                            this.state.item.instagram &&
                            <div className="Section">
                                <Input
                                    title={'Selected page'}
                                    value={this.state.item.instagram.facebookPageName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.instagram && this.state.page.name && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select a instagram account'}
                                    size={'full'}
                                    placeholder={this.state.instagrams.length > 0 ? 'Click here ..' : 'No instagram accounts are available'}
                                    error={this.state.error === "instagram" && !this.state.instagram.name}
                                    loading={this.state.instagram_loading}
                                    options={this.state.instagrams}
                                    value={this.state.instagram}
                                    onChange={(value) => this.setState({instagram: value}, () => {

                                    })}
                                />
                            </div>
                        }
                        {
                            this.state.item.instagram &&
                            <div className="Section">
                                <Input
                                    title={'Selected Instagram account'}
                                    value={this.state.item.instagram.instagramUserName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.token && this.state.business.name && this.state.adaccount.name && this.state.page.name && this.state.instagram.name && !this.state.item.instagram && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.settings();
                                }} className="Button ButtonFull">
                                    {
                                        !this.state.loading &&
                                        <div>Add Instagram settings to client</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                !this.state.token && !this.state.item.instagram &&
                                <div className="Section">
                                    <div onClick={() => {
                                        this.functions.login()
                                    }} className="ButtonFull Button ButtonFacebook">
                                        login Facebook
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.facebook &&
                                <div className="Section">
                                    <div onClick={() => {
                                        this.functions.logout()
                                    }} className="ButtonFull Button ButtonFacebook">
                                        signout Facebook
                                    </div>
                                </div>
                            }
                            {
                                this.state.item.instagram &&
                                <div className="Section">
                                    <div onClick={() => {
                                        this.setState({
                                            remove_modal: true
                                        })
                                    }} className="Button ButtonFull ButtonFacebook">
                                        {
                                            !this.state.loading &&
                                            <div>signout Facebook</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                    {
                                        false &&
                                        <div onClick={() => {
                                            //this.functions.update()
                                        }} className="Button ButtonNeutral">
                                            update token
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.submenu === 1 &&
                    <ClientFacebookAdaccount
                        client={this.state.item.id}
                        token={this.state.token}
                        item={this.state.item.instagram}
                        channel={'instagram'}
                        onChange={(value) => {
                            this.functions.adaccounts();
                            this.functions.clientAdaccounts();
                        }}
                    />
                }
                {
                    this.state.submenu === 2 &&
                    <ClientInstagramPixel
                        client={this.state.item.id}
                        item={this.state.item.instagram}
                        onChange={(value) => {
                            this.state.item.instagram = value;
                            this.setState({
                                item: this.state.item
                            })
                        }}
                    />
                }
                {
                    this.state.submenu === 3 &&
                    <ClientFacebookRequestAdaccount
                        client={this.state.item.id}
                        token={this.state.token}
                        channel={'instagram'}
                        item={this.state.item.instagram}
                        onChange={(value) => {

                        }}
                    />
                }
                {
                    this.state.submenu === 4 &&
                    <ClientFacebookRequestPage
                        client={this.state.item.id}
                        token={this.state.token}
                        channel={'instagram'}
                        item={this.state.item.instagram}
                        onChange={(value) => {

                        }}
                    />
                }
            </div>
        )
    }
}

export default ClientInstagram;
