import React, {Component, Fragment} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle, Polygon
} from "react-google-maps";

const {DrawingManager} = require("react-google-maps/lib/components/drawing/DrawingManager");

class UserRegionsNewMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            center: this.props.center,
            zoom: this.props.zoom,
            place: {},
            control: true,
            polygon: null,
            drawing_manager: null,
            show: true,
            refs: {}
        };
        this.google = window.google;
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            center: this.state.center !== nextProps.center ? nextProps.center : this.state.center,
            zoom: this.state.zoom !== nextProps.zoom ? nextProps.zoom : this.state.zoom
        });
    }

    componentDidMount() {
        this.setState({
            center: this.props.center,
            zoom: this.props.zoom
        });
    }

    functions = {
        getPaths: (polygon) => {
            var polygonBounds = polygon.getPath();
            var bounds = [];
            for (var i = 0; i < polygonBounds.length; i++) {
                var point = {
                    lat: polygonBounds.getAt(i).lat(),
                    lng: polygonBounds.getAt(i).lng()
                };
                bounds.push(point);
            }
            return bounds;
        }
    };

    renders = {};

    handleOverlayComplete(e) {
        this.setDrawingMode(null);
        this.setOptions({drawingControlOptions: {drawingModes: [], position: window.google.maps.ControlPosition.TOP_CENTER}});
    };

    onLoad(e){
        console.log(e);
    };

    render() {
        const defaultMapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };
        return (
            <div style={{width: '100%', position: 'relative', top: '-700px'}}>
                <div onClick={() => {
                    try {
                        this.setState({
                            show: false
                        }, () => {
                            this.setState({
                                show: true
                            })
                        });
                        this.state.polygon.setMap(null);
                        this.setState({
                            polygon: null
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }} className="region-remove-button Button ButtonRed">
                    <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                </div>
                <GoogleMap
                    defaultOptions={defaultMapOptions}
                    defaultZoom={18}
                    defaultCenter={{lat: 3.1314067, lng: 101.6285082}}
                    ref={(ref) => {
                        this.map = ref;
                    }}
                >
                    {
                        this.state.show &&
                        <DrawingManager
                            onLoad={this.onLoad}
                            drawingMode={this.google.maps.drawing.OverlayType.POLYGON}
                            defaultOptions={{
                                drawingControl: true,
                                drawingControlOptions: {
                                    position: this.google.maps.ControlPosition.TOP_CENTER,
                                    drawingModes: [this.google.maps.drawing.OverlayType.POLYGON]
                                },
                                polygonOptions: {
                                    fillColor: '#455A64',
                                    fillOpacity: 0.3,
                                    strokeColor: '#455A64',
                                    strokeWeight: 3,
                                    clickable: true,
                                    editable: true,
                                    draggable: true,
                                    zIndex: 1
                                },
                            }}
                            onOverlayComplete={this.handleOverlayComplete}
                            onPolygonComplete={(value) => {
                                this.setState({
                                    polygon: value
                                });
                            }}
                        />
                    }
                </GoogleMap>
            </div>
        );
    }

}

export default withGoogleMap(UserRegionsNewMap);
