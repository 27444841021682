import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';

class ErrorAlert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    };

    componentWillMount() {
        this.setState({
            items: this.props.items,
            amount: this.props.amount
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items,
            amount: nextProps.amount
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className={(this.props.noMargin?"":"mt-4") + " p-4 flex flex-row bg-red-100 rounded-md"}>
                <div className="flex flex-1 flex-col pt-1">
                    <ol className="list-disc pl-4">
                        {
                            !this.state.amount &&
                            this.state.items.map((item) => {
                                return (
                                    <li className="text-xs text-red-500 font-bold">
                                        {item.title}
                                    </li>
                                )
                            })

                        }
                        {
                            this.state.amount &&
                            <li className="text-xs text-red-500 font-bold">
                                {this.state.items.length} Errors
                            </li>

                        }
                    </ol>
                </div>
            </div>
        )
    }
}

export default ErrorAlert;
