import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import { SparklesIcon, ChevronUpIcon, TrashIcon, PhotographIcon, FilmIcon, SwitchVerticalIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import PreviewTwitterFeed from '../previews/twitter/feed';
import PreviewTwitterCarousel from '../previews/twitter/carousel';
import PreviewSectionTwitter from './previewSectionTwitter';
import { adTwitter } from '../validators/adTwitter';
import WarningModalTailwind from './warningModalTailwind';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

var pusherService = null;

class EditTwitterAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            ad: {},
            updated: {},
            client: {},
            pages: [],
            loader: false,
            wizard: false,
            channel: { id: 1, name: "Feed" },
            wizardId: 0,
            selectedAsset: 0,
            carousel_menu: "Main ad",
            open_slide: 0,
            preview_data: {},
            delete_modal: false,
        }
    };

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
            wizard: this.props.wizard,
            assetItems: this.props.assetItems,
            wizardId: this.props.wizardId
        })

        if (this.props.updated) {
            this.setState({
                updated: this.props.updated
            });
        }

        //LIST IDENTITIES
        this.functions.listPages();
        if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
            this.props.onUpdatedChat(this.state.updated.OpenAiResult);
        }
        this.functions.updatePreview();

    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            client: nextProps.client,
            wizard: nextProps.wizard,
            assetItems: nextProps.assetItems,
            wizardId: nextProps.wizardId
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }
    }

    functions = {
        onSelectFile: async (e, slide = false) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        await this.functions.uploadVideo(files[i], slide);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        await this.functions.uploadImage(files[i], slide);
                    }
                    const randomString = Math.random().toString(36);
                    await this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
            this.functions.updatePreview();
        },
        uploadImage: async (file, slide) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    name_by_channels: {
                        facebook: file.name,
                        linkedin: file.name,
                        tiktok: file.name,
                        twitter: file.name,
                        snapchat: file.name
                    },
                    type: 'image'
                };

                files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                files_object.allowed_types = { regular: true };
                files_object.allowed_multiple = true;

                this.state.assetItems.push(files_object);
                //this.state.updated.file = { ...this.state.updated.file,  };
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.image(formData);
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems });
                    await this.props.updateAssets(this.state.assetItems);
                    if (!slide) {
                        this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        this.state.updated.picture = response.data.url;
                    } else {
                        if (!this.state.updated_slides) {
                            this.state.updated_slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((item) => {
                                return {}
                            });
                        }
                        this.state.updated.slides = this.state.updated.slides.map((item, index) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.image = true;
                                item.video = false;
                                item.picture = response.data.url;
                                item.url = response.data.url;
                                this.state.updated_slides[index] = item;
                                this.state.updated_slides[index].image = response.data.url;
                            }
                            return item;
                        });
                        await this.promisedSetState({ updated_slides: this.state.updated_slides });

                    }
                    await this.promisedSetState({ updated: this.state.updated, selectedAsset: response.data.id, loader: false });

                } catch (error) {
                    this.state.assetItems = this.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                }
                resolve()
            });
        },
        uploadVideo: async (file, slide) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            //this.state.updated.file = { ...this.state.updated.file, ...files_object };
            await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                if (response && response.status === 200) {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                    await self.props.updateAssets(self.state.assetItems);
                    if (!slide) {
                        self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                        self.state.updated.video = response.data.url;
                    } else {
                        if (!self.state.updated_slides) {
                            self.state.updated_slides = JSON.parse(JSON.stringify(self.state.updated.slides)).map((item) => {
                                return {}
                            });
                        }
                        self.state.updated.slides = self.state.updated.slides.map((item, index) => {
                            if (item.id === slide.id) {
                                item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
                                item.url = response.data.url;
                                item.image = false;
                                item.video = true;
                                self.state.updated_slides[index] = item;
                            }
                            return item;
                        });
                        await self.promisedSetState({ updated_slides: self.state.updated_slides });
                    }
                    await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, loader: false });
                    self.functions.updatePreview();

                } else {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video_upload(formData, channel_id);
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
                await this.props.updateAssets(this.state.assetItems);

            }
        },
        update: async () => {
            if (!this.state.wizard) {

            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        listPages: async () => {
            await this.promisedSetState({ loading_pages: true });
            try {
                let response = await this.calls.getPages();
                this.promisedSetState({
                    pages: response.data,
                    loading_pages: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_pages: false });
            }
        },
        updatePreview: async () => {
            let preview_data = {
                merge: false,
                carousel: false,
                catalog: false,
                files: [],
                headline_by_channels: { twitter: this.renders.title() },
                body_by_channels: { twitter: this.renders.body() },
                // cta: { twitter: this.renders.cta(this.updated) },
                page: { twitter: this.renders.page(this.updated) },
                website_by_channels: { twitter: this.renders.link() },
                // description: this.renders.description(),
                // caption: this.renders.caption(),
                preview_channel: this.state.updated && this.state.updated.preview_channel ? this.state.updated.preview_channel : "twitter",
                previews: { twitter: [{ id: 1, value: "feed", name: "Feed" }] },
                preview: this.state.preview_data ? this.state.preview_data.preview : { value: "feed", name: "Feed" },
                utm_channels: ["twitter"],
                channel_menu: "twitter",
                validation: { twitter: Object.keys(adTwitter.validate(this.state.updated)).filter((item) => { return adTwitter.validate(this.state.updated)[item] }).filter((item) => { return item }).length > 0 ? true : false },
            };

            if (this.state.updated) {
                if (this.state.updated.type === "posts") {
                    if (this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad") {
                        preview_data.carousel = this.state.updated;
                    } else {
                        preview_data.files = [this.state.updated];
                    }
                } else {
                    if (this.state.updated.ad_type.value !== "carousel_ad") {
                        if (this.state.updated.ad_type && this.state.updated.ad_type.value === "catalog_ad") {
                            let buff = { ...this.state.updated, files: this.state.updated.slides };
                            this.state.catalog = true;
                            if (this.state.updated.collection_ad) {
                                preview_data.instantExperience = this.state.updated.instantExperience;
                            }
                            preview_data.files = [buff];
                            preview_data.carousel = buff;
                            if (preview_data.carousel) {
                                preview_data.carousel.files = [buff];
                            }
                            preview_data.catalog = buff;
                            await this.promisedSetState({ catalog: this.state.catalog });
                        }
                        if (this.state.updated.files) {
                            preview_data.files = this.state.updated.files;
                        } else if (this.state.updated.file) {
                            preview_data.files = [this.state.updated.file];
                        }
                    } else {
                        let carousel = JSON.parse(JSON.stringify(this.state.updated));
                        carousel.files = carousel.slides;
                        preview_data.carousel = carousel;
                        preview_data.files = [this.state.updated];
                    }
                }
            }

            try {
                this.props.updatePreview(preview_data, this.state.updated)
            } catch (error) {
                console.log(error)
            }
        },
        updateOpenAi: async (data) => {
            try {
                this.state.updated.OpenAiResult = data;
                await this.promisedSetState({
                    updated: this.state.updated
                })
            } catch (error) {
                console.log(error)
            }
        },
        addHeadlines: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post && !this.state.updated.catalog) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            if (!this.state.carousel) {
                                this.state.updated.title = data[i].value[0];
                            }
                        } else {
                            if (!this.state.carousel) {
                                if (this.renders.title() === "") {
                                    this.state.updated.title = data[i].value[0];
                                }
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        addDescriptions: async (data) => {
            try {
                if (this.state.updated && !this.state.updated.is_post) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].force) {
                            this.state.updated.body = data[i].value[0];
                        } else {
                            if (this.renders.body() === "") {
                                this.state.updated.body = data[i].value[0];
                            }
                        }
                    }
                    await this.promisedSetState({
                        updated: this.state.updated
                    });
                    this.functions.updatePreview();
                } else {
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        },
        removeDescription: async () => {
            try {
                this.state.updated.body = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        removeHeadline: async () => {
            try {
                this.state.updated.title = "";
                await this.promisedSetState({
                    updated: this.state.updated
                });
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
        deleteSlide: async (slide) => {
            try {
                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                    this.state.updated.slides = this.state.updated.slides.filter((item) => {
                        return slide.id !== item.id;
                    });
                }
                await this.promisedSetState({
                    updated: this.state.updated, open_slide: 0, delete_modal: false
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.updated);
                }
                this.functions.updatePreview();
            } catch (error) {
                console.log(error)
            }
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: () => {
            try {
                if ('body' in this.state.updated) {
                    return this.state.updated.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        title: () => {
            try {
                if ('title' in this.state.updated) {
                    return this.state.updated.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: () => {
            try {
                if ('link' in this.state.updated) {
                    return this.state.updated.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        video: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else if (this.state.updated.type === "posts") {
                    return this.state.updated.url;
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        image: () => {
            try {
                if (this.state.updated.type === "image") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.url;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        page: () => {
            try {
                if ("page" in this.state.updated) {
                    return this.state.updated.page;
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: () => {
            let creative = null;
            try {
                if (this.state.updated.type === "carousel") {
                    if (Array.isArray(this.state.updated.slides)) {
                        creative = this.state.updated.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) { }
            return creative;
        },
        thumbnail: () => {
            try {
                if (this.state.updated.type === "video") {
                    if ("file" in this.state.updated) {
                        return this.state.updated.file.thumbnail;
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } catch (error) {
                return null
            }
        },
        type: (item) => {
            console.log(item)
            if (!this.state.wizard) {
                let type = null;
                try {
                    if (item.updated && item.updated.video !== null) {
                        type = "video";
                    }
                } catch (eror) { }
                try {
                    if (item.updated && item.updated.image !== null) {
                        type = "image";
                    }
                } catch (eror) { }
                try {
                    if (Array.isArray(item.updated.slides) && item.updated.slides.length > 1) {
                        type = "carousel";
                    }
                } catch (eror) { }
                return type;
            } else {
                return this.state.updated.type;
            }
        },
    };

    calls = {
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizardId;
            return apiRegister.call(options, url);
        },
        video_upload: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizardId + "&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        },
        update: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateAdCreative?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getPages: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    SortableItem = SortableElement(({ item, itemIndex }) => {
        return (
            <div
                style={{ zIndex: 99999999 }}
                className="cursor-pointer">
                {
                    <>
                        <div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === itemIndex ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
                            <div className='flex w-full justify-between'>
                                <div className='flex'>
                                    <div className={"border-1.5 h-12 w-12 mr-2 flex justify-center cursor-pointer items-center rounded-md"}>
                                        <SwitchVerticalIcon className="h-5 w-5" />
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
                                            {
                                                item.type === "image" &&
                                                <img src={item.url ? item.url : ""} className="w-full object-contain rounded-md"
                                                    style={{ maxHeight: "48px" }}
                                                />
                                            }
                                            {
                                                item.type === "video" &&
                                                <video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className="rounded-md"
                                                    style={{ maxHeight: "48px" }}
                                                />
                                            }
                                        </div>
                                        <div className='flex flex-col'>
                                            <div className="text-sm font-medium">
                                                Slide {itemIndex + 1}
                                            </div>
                                            <div className="flex text-xs text-gray-600">
                                                {
                                                    item.type === "image" ?
                                                        <>
                                                            <PhotographIcon className="h-4 w-4 mr-1" />
                                                            Image
                                                        </>
                                                        :
                                                        <>
                                                            <FilmIcon className="h-4 w-4 mr-1" />
                                                            Video
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center space-x-4">
                                    {
                                        this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 &&
                                        <div className='bg-red-200 p-2 rounded-md cursor-pointer'
                                            onClick={async () => {
                                                await this.promisedSetState({ delete_modal: true, delete_slide: item })
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
                                        </div>
                                    }
                                    <div
                                        onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === itemIndex ? null : itemIndex })}
                                        className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
                                        <ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === itemIndex && "rotate-180"}`} />
                                    </div >
                                </div>
                            </div>

                            {
                                this.state.updated && this.state.updated.type !== "posts" &&
                                <div className='flex w-full space-x-4'>
                                    <div className='w-full'>
                                        {/* <DropdownTailwind
                                            label={"Select other asset"}
                                            channel={"twitter"}
                                            loader={this.state.loader}
                                            searchInput={true}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                            options={this.state.assetItems && this.state.assetItems.map((item) => {
                                                return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                            })}
                                            onChange={async (value) => {
                                                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                                                    this.state.updated.slides[itemIndex] = { ...this.state.updated.slides[itemIndex], ...value };
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    selectedAsset: value.id
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        /> */}
                                        <DropdownTailwind
                                            label={"Select other asset"}
                                            loader={this.state.loader}
                                            searchInput={true}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={
                                                this.state.updated_slides && this.state.updated_slides[itemIndex] ? this.state.updated_slides[itemIndex] : { id: 0, name: "Select an asset" }
                                            }
                                            options={this.state.assetItems && this.state.assetItems.map((item) => {
                                                return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                            })}
                                            onChange={async (value) => {
                                                if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
                                                    let updated_slide = { ...this.state.updated.slides[itemIndex], ...value };
                                                    if (value.image) {
                                                        updated_slide.type = "image";
                                                    } else if (value.video) {
                                                        updated_slide.type = "video";
                                                    }
                                                    this.state.updated.slides[itemIndex] = updated_slide;
                                                }
                                                if (!this.state.updated_slides) {
                                                    this.state.updated_slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((item, index) => {
                                                        return {};
                                                    })
                                                }
                                                this.state.updated_slides[itemIndex] = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    updated_slides: this.state.updated_slides
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                        <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                            <input
                                                onChange={(e) => this.functions.onSelectFile(e, item)}
                                                type="file"
                                                accept={this.state.updated && this.state.updated.type === "carousel" ? "image/png, image/jpg, image/jpeg, image/gif, video/*" : this.state.updated && this.state.updated.type === "video" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif"}
                                                className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                            />
                                            {
                                                this.state.loader &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    <div className="ml-2 text-xs">
                                                        Loading ...
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.state.loader &&
                                                <div className="text-xs text-gray-600">
                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                <>
                                    <div className="w-full">
                                        <InputTailwind
                                            label={"Website"}
                                            value={item.link ? item.link : ""}
                                            error={(!item.link || !item.link.includes("https://")) ? true : false}
                                            onChange={async (value) => {
                                                this.state.updated.slides[itemIndex].link = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    {
                                        (!item.link || !item.link.includes("https://")) &&
                                        <div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                            OBS! Prefix https:// is required.
                                        </div>
                                    }
                                    {/* {
                                        this.state.updated && this.state.updated.type !== "carousel" &&
                                        <div className="flex space-x-4 w-full">
                                            <div className='w-full'>
                                                <InputTailwind
                                                    label={"Headline"}
                                                    value={item.title ? item.title : ""}
                                                    onChange={async (value) => {
                                                        this.state.updated.slides[itemIndex].title = value;
                                                        this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <InputTailwind
                                                    label={"Description"}
                                                    value={item.body ? item.body : ""}
                                                    onChange={async (value) => {
                                                        this.state.updated.slides[itemIndex].body = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                        this.functions.updatePreview();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    } */}
                                </>
                            }

                        </div>

                    </>
                }
            </div>
        )
    });

    SortableList = SortableContainer(({ items }) => (
        <div className="relative space-y-4">
            {items.map((item, index) => {
                if (item) {
                    return (
                        <this.SortableItem
                            index={index}
                            itemIndex={index}
                            item={item}
                        />
                    )
                }
            })}
        </div>
    ));

    onSortEnd = async ({ oldIndex, newIndex }) => {
        await this.promisedSetState({
            array: arrayMove((this.state.updated.slides ? this.state.updated.slides : []), oldIndex, newIndex),
            array_buffer: arrayMove((this.state.updated_slides ? this.state.updated_slides : []), oldIndex, newIndex)
        })
        if (this.state.updated.slides) {
            this.state.updated.slides = this.state.array;
            if (this.state.updated_slides) {
                this.state.updated_slides = this.state.array_buffer;
            }
            // this.state.updated.files = this.state.array;
            await this.promisedSetState({
                updated: this.state.updated
            })
            this.functions.updatePreview()
        }
    };

    renderSortableList(items) {
        return (
            <div className="relative space-y-4">
                <this.SortableList
                    items={this.state.updated.slides}
                    onSortEnd={this.onSortEnd}
                    axis="y"
                    distance={1}
                />
            </div>
        )
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>

                <WarningModalTailwind
                    open={this.state.delete_modal ? true : false}
                    title={"Delete slide"}
                    description={'Are you sure you want to delete this slide ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Delete"}
                    onClose={async (value) => {
                        await this.promisedSetState({ delete_modal: false });
                    }}
                    onSubmit={() => {
                        this.functions.deleteSlide(this.state.delete_slide);
                    }}
                />

                <div className='flex flex-col px-4 space-y-4 bg-gray-50 pb-4'>
                    {
                        this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad" &&
                        <div className="block w-full">
                            <div className="border-gray-200 w-full">
                                <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                                    {
                                        ["Main ad", "Slides information"].map((item) => {
                                            return (
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            carousel_menu: item
                                                        })
                                                    }}
                                                    key={item}
                                                    className={classNames(
                                                        item === this.state.carousel_menu
                                                            ? 'border-purple-500 text-purple-600'
                                                            : ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
                                                        'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md'
                                                    )}
                                                    aria-current={item === this.state.carousel_menu ? 'page' : undefined}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        }
                                        )}
                                </nav>
                            </div>
                        </div>
                    }

                    {
                        this.state.carousel_menu === "Main ad" &&
                        <>
                            {
                                this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" && this.state.updated.type !== "posts" &&
                                <div className="flex flex-row items-center space-x-4">
                                    <div className='w-full'>
                                        <DropdownTailwind
                                            label={"Select other asset"}
                                            channel="twitter"
                                            loader={this.state.loader}
                                            locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
                                            selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                            options={this.state.assetItems && this.state.assetItems.filter((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                                    return item.type === "image"
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                                    return item.type === "video"
                                                } else return item
                                            }).map((item) => {
                                                if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
                                                    return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
                                                    return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                } else if (this.state.updated && this.state.updated.collection_ad) {
                                                    return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
                                                }
                                            }).filter((item) => { return item })}
                                            onChange={async (value) => {
                                                this.state.updated.file = { ...this.state.updated.file, ...value };
                                                if (value.image) {
                                                    delete this.state.updated.video;
                                                    this.state.updated.picture = value.image;
                                                    this.state.updated.type = "image";
                                                } else if (value.video) {
                                                    delete this.state.updated.picture;
                                                    this.state.updated.video = value.video;
                                                    this.state.updated.type = "video";
                                                }

                                                await this.promisedSetState({
                                                    updated: this.state.updated,
                                                    selectedAsset: value.id
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                    {
                                        !this.state.loader && this.state.updated && this.state.updated.type !== "posts" &&
                                        <div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
                                            <div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                {
                                                    this.state.loader &&
                                                    <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        <div className="ml-2 text-xs">
                                                            Loading ...
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.loader &&
                                                    <div className="text-xs text-gray-600">
                                                        <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            <div className="flex w-full space-x-4">
                                <div className='w-full'>
                                    <InputTailwind
                                        label={"Name"}
                                        channel="twitter"
                                        error={adTwitter.validate(this.state.updated).name}
                                        value={this.renders.name()}
                                        onChange={async (value) => {
                                            this.state.updated.name = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                            this.functions.updatePreview();
                                        }}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <InputTailwind
                                        label={"Type"}
                                        channel="twitter"
                                        value={this.renders.type() && typeof this.renders.type() === 'string' ? this.renders.type().charAt(0).toUpperCase() + this.renders.type().slice(1) : ''}
                                        disabled={true}
                                    />
                                </div>

                            </div>

                            <div className="w-full text-lg font-semibold">
                                Select identity
                            </div>

                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Select profile"}
                                    channel={"twitter"}
                                    error={adTwitter.validate(this.state.updated).page}
                                    selected={this.renders.page()}
                                    locked={false}
                                    options={this.state.pages}
                                    onChange={(value) => {
                                        this.state.updated.page = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                            </div>

                            <div className="w-full text-lg font-semibold">
                                Ad settings
                            </div>

                            <div className="w-full">
                                <InputTailwind
                                    label={"Website"}
                                    channel="twitter"
                                    error={adTwitter.validate(this.state.updated).website}
                                    value={this.renders.link()}
                                    onChange={(value) => {
                                        this.state.updated.link = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                                {
                                    adTwitter.validate(this.state.updated).website &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>
                            {/* NO HEADLINE ON CAROUSEL BUT CANT FIND IT ON BACKEND TO CONFIRM, SDK ???  */}
                            {
                                this.state.updated &&
                                this.state.updated.type !== "posts" &&
                                this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" &&
                                <div className="w-full relative">
                                    <InputTailwind
                                        label={"Headline"}
                                        channel="twitter"
                                        error={adTwitter.validate(this.state.updated).headline}
                                        value={this.renders.title()}
                                        onChange={(value) => {
                                            this.state.updated.title = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                            this.functions.updatePreview();
                                        }}
                                    />
                                    {
                                        this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === "twitter" && inner_item.value.includes(this.renders.title()) }).length > 0 }).length > 0 &&
                                        <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                            <SparklesIcon className="h-5 w-5 text-purple-500" />
                                        </div>
                                    }
                                </div>
                            }

                            <div className="w-full relative">
                                <TextAreaTailwind
                                    label={"Text"}
                                    locked={this.state.updated && this.state.updated.type === "posts" ? true : false}
                                    disabled={this.state.updated && this.state.updated.type === "posts" ? true : false}
                                    channel="twitter"
                                    error={adTwitter.validate(this.state.updated).body}
                                    value={this.renders.body()}
                                    onChange={(value) => {
                                        this.state.updated.body = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.updatePreview();
                                    }}
                                />
                                {
                                    this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === "twitter" && inner_item.value.includes(this.renders.body()) }).length > 0 }).length > 0 &&
                                    <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                        <SparklesIcon className="h-5 w-5 text-purple-500" />
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {
                        this.state.carousel_menu === "Slides information" &&
                        <>
                            {
                                this.state.updated && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 0 &&
                                this.renderSortableList(this.state.updated && this.state.updated.slides ? this.state.updated.slides : [])
                            }
                        </>
                    }
                </div>



                {/* <div className="grid grid-cols-12">
                        {
                            this.state.updated &&
                            Array.isArray(this.state.updated.previews) &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                {
                                    (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type.value === "video_ad") ||
                                    (!this.state.updated.ad_type.value && (this.state.updated.ad_type === "image" || this.state.updated.ad_type === "video") && this.state.updated.type === "posts")
                                    &&
                                    <div className="col-span-3 grid grid-cols-3 gap-4">
                                        <div className="col-span-2">
                                            <DropdownTailwind
                                                label={"Select other asset"}
                                                channel="twitter"
                                                loader={this.state.loader}
                                                locked={!this.state.wizard}
                                                selected={this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
                                                options={this.state.assetItems.filter((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image") {
                                                        return item.type === "image"
                                                    } else if (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video") {
                                                        return item.type === "video"
                                                    } else return item
                                                }).map((item) => {
                                                    if (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image") {
                                                        return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
                                                    } else if (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video") {
                                                        return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
                                                    }
                                                })}
                                                onChange={async (value) => {
                                                    this.state.updated.file = { ...this.state.updated.file, ...value };
                                                    if (this.state.updated.ad_type.value === "image_ad") {
                                                        this.state.updated.picture = value.image;
                                                    } else if (this.state.updated.ad_type.value === "video_ad") {
                                                        this.state.updated.video = value.video;
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated,
                                                        selectedAsset: value.id
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1 pt-5.5">
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    accept={(this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
                                                    className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                />
                                                <div className="space-y-1 text-center justify-center flex flex-col">
                                                    {
                                                        this.state.loader &&
                                                        <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            <div className="ml-2 text-xs">
                                                                Loading ...
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.loader &&
                                                        <div className="text-xxs text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview"}
                                        selected={this.state.updated.preview}
                                        options={this.state.updated.previews}
                                        onChange={(value) => {
                                            this.state.updated.preview = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <PreviewSectionTwitter
                                        ad={this.state.updated}
                                        channel={{ value: "twitter" }}
                                    />
                                </div>
                            </div>
                        }

                        {
                            !this.state.updated.new_preview &&
                            <div className="col-span-5 grid grid-cols-3 gap-4 content-start pr-6">
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Preview channel"}
                                        selected={this.state.channel}
                                        options={[{ id: 1, name: "Feed" }]}
                                        onChange={(value) => {
                                            this.setState({
                                                channel: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.state.updated.type !== "carousel" &&
                                    <div className="col-span-3 ">
                                        <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                            <PreviewTwitterFeed
                                                video={this.renders.video()}
                                                image={this.renders.image()}
                                                link={this.renders.link()}
                                                body={this.renders.body()}
                                                headline={this.renders.title()}
                                                thumbnail={this.renders.thumbnail()}
                                                page={this.renders.page()}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.updated.type === "carousel" &&
                                    <div className="col-span-3 ">
                                        <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                            <PreviewTwitterCarousel
                                                page={this.renders.page()}
                                                body={this.renders.body()}
                                                content={{ body: this.renders.body(), items: this.renders.creative() }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div className="col-span-7 grid grid-cols-3 gap-4 content-start pb-10 pl-6">

                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Ad name"}
                                    error={this.state.updated.error && this.state.updated.error.name}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Select profile"}
                                    error={adTwitter.validate(this.state.updated).page}
                                    selected={this.renders.page()}
                                    locked={false}
                                    options={this.state.pages}
                                    onChange={(value) => {
                                        this.state.updated.page = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>

                            <div className="col-span-3">
                                <TextAreaTailwind
                                    label={"Text"}
                                    error={adTwitter.validate(this.state.updated).body}
                                    value={this.renders.body()}
                                    onChange={(value) => {
                                        this.state.updated.body = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Headline"}
                                    error={adTwitter.validate(this.state.updated).headline}
                                    value={this.renders.title()}
                                    onChange={(value) => {
                                        this.state.updated.title = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"URL"}
                                    error={adTwitter.validate(this.state.updated).website}
                                    value={this.renders.link()}
                                    onChange={(value) => {
                                        this.state.updated.link = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                                {
                                    adTwitter.validate(this.state.updated).website &&
                                    <div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Prefix https:// is required.
                                    </div>
                                }
                            </div>
                        </div>
                    </div> */}
            </>
        )
    }
}

export default EditTwitterAd;
