import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api
    };

    static getPixels(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/pixel/facebook/' + data.client;
        return apiRegister.call(options, url);
    }

    static getBusinesses(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/business';
        return apiRegister.call(options, url);
    }

    static createAdaccount(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/facebook/create/adaccount';
        return apiRegister.call(options, url);
    }

    static getAdaccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/business';
        return apiRegister.call(options, url);
    }

    static getCreditLines(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/extendedcredits?access_token=' + token;
        return apiRegister.call(options, url);
    }

    static getCreditLinesInternal(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/creditcard';
        return apiRegister.call(options, url);
    }

    static getCreditCards(business_id, token) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/'+calls.url.facebook_version+'/'+business_id+'/creditcards?access_token=' + token;
        return apiRegister.call(options, url);
    }

    static addPixel(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/pixel';
        return apiRegister.call(options, url);
    }

    static removePixel(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = apiRegister.url.api  + '/client/pixel/facebook/' + data.client;
        return apiRegister.call(options, url);
    }

}

export {calls}