import React, { Component } from 'react';
import { userRegister } from "../services/userRegister";
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import ClientTopNavigation from '../moduleFiles/clientTopNavigation';
import { CubeIcon, ChevronRightIcon } from '@heroicons/react/outline';
import InputTailwind from '../moduleFiles/inputTailwind';
import translations from '../assets/json/onboarding_translation.json';

class ClientOrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            loading_partial: false,
            orders: [],
            page: 1,
            limit: 30,
            total: "",
            search: "",
            sort: "created",
            direction: "descending",
            whitelabel_settings: {},
            mobile_search: "",
            index_count: 5,
            show_more: false,
            load_more: false,
        };
    }

    componentWillMount() {
        this.init.user();
    }

    componentDidMount() {
        this.functions.getOrders(true, false);
    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
    };

    functions = {
        getOrders: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getOrders();
                if (response && response.meta && response.meta.total >= 5) {
                    await this.promisedSetState({
                        show_more: true,
                    })
                }
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        orders: response.data,
                        total: response.meta.total,
                    });
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        handleMobileSearch: async (value) => {
            await this.promisedSetState({
                loading_partial: true,
                search: value
            });
            setTimeout(async () => {
                if (value === this.state.search) {
                    await this.promisedSetState({
                        page: 1
                    });
                    this.functions.getOrders(false, value);
                }
            }, 400);
        },
        handleMobileLoadMore: async () => {
            await this.promisedSetState({
                loading_partial: true,
                load_more: true,
            });
            setTimeout(async () => {
                await this.promisedSetState({
                    index_count: this.state.index_count + 6,
                    loading_partial: false,
                    load_more: false,
                })
            }, 300);
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" && ("&search=" + this.state.search))
            return apiRegister.call(options, url);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = window.location.pathname.split("/")[2];

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {

        let t = this.translate;

        return (
            <>
                {
                    this.state.loading &&
                    <div className="h-screen flex flex-col justify-center items-center">
                        <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                            style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}></div>
                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <>
                        {/* {
                            // IF THERES NO ORDERS
                            this.state.orders.length === 0 &&
                            <>
                                <div className="h-screen flex flex-col justify-center items-center">
                                    <CubeIcon className="w-10 h-10 text-gray-500" />
                                    <div className="font-semibold mt-3">{t("no_orders")}</div>
                                    <div className="text-sm text-gray-500 mt-1 mb-3">{t("get_started")}</div>
                                    <button
                                        onClick={() => {
                                            window.open("/client/order/step-one");
                                        }}
                                        className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative  text-white bg-purple-500 mt-4'
                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                    >
                                        <span
                                            style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                            New Order
                                        </span>
                                    </button>
                                </div>
                            </>

                        } */}

                        {/* IF THERE ARE ORDERS */}
                        {
                            // this.state.orders.length > 0 &&
                            <>
                                {/* MOBILE SEARCH  */}
                                <div className='flex w-full h-16 min-h-16 bg-white justify-center items-center md:hidden shadow'>
                                    <InputTailwind
                                        placeholder={t("search") + " ..."}
                                        value={this.state.mobile_search}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                mobile_search: value
                                            })
                                            this.functions.handleMobileSearch(value);
                                        }
                                        }
                                        small={true}
                                        noHeight={true}
                                    />
                                </div>

                                <div className='hidden md:block'>
                                    <ClientTopNavigation
                                        breadcrumb={"Orders"}
                                        limit={this.state.limit}
                                        page={this.state.page}
                                        total={this.state.total}
                                        showPaginaton={!this.state.loading}
                                        showPaginationSearch={!this.state.loading}
                                        buttons={[]}
                                        onPrevious={async (value) => {
                                            await this.promisedSetState({
                                                page: value
                                            })
                                            this.functions.getOrders();
                                        }}
                                        onNext={async (value) => {
                                            await this.promisedSetState({
                                                page: value
                                            })
                                            this.functions.getOrders();
                                        }}
                                        onLimit={async (value) => {
                                            await this.promisedSetState({
                                                page: 1,
                                                limit: value
                                            })
                                            this.functions.getOrders();
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_partial: true,
                                                search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.search) {
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getOrders(false, value);
                                                }
                                            }, 400);
                                        }}

                                    />
                                </div>

                                {
                                    this.state.loading_partial &&
                                    <div className="flex flex-col h-screen justify-center items-center">
                                        <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                                            style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}></div>
                                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                                    </div>
                                }

                                {
                                    !this.state.loading_partial &&
                                    <div className='flex flex-col space-y-4 px-4 md:pb-4'>
                                        {
                                            this.state.orders.length > 0 &&
                                            <div className='text-left text-sm pt-4'>
                                                {t("text_1")}
                                            </div>
                                        }

                                        <div className={`relative flex flex-col space-y-4 md:pb-0 md:grid md:grid-cols-3 md:gap-4 md:space-y-0 lg:grid-cols-4 ${this.state.orders.length === 0 ? "pb-0" : "pb-20"}`}>

                                            {/* MOBILE CARD STARTS HERE */}
                                            {
                                                this.state.orders.map((order, index) => {
                                                    if (index > this.state.index_count) {
                                                        return;
                                                    }
                                                    return (
                                                        <div className='md:hidden flex flex-col rounded-lg bg-gray-50 px-4 py-2 shadow md:justify-between cursor-pointer'
                                                            onClick={() => {
                                                                window.open("/client/order/" + order.id, "_blank");
                                                            }}
                                                        >
                                                            <div className='flex justify-between items-center mb-4'>
                                                                <div className="font-medium text-sm">
                                                                    {order.name}
                                                                </div>
                                                                <div className='bg-gray-100 rounded-full p-2 ml-2 md:flex md:flex-col md:self-start'
                                                                    style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}

                                                                >
                                                                    <ChevronRightIcon className="h-5 w-5 text-gray-700" aria-hidden="true"
                                                                        style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between items-center'>
                                                                <div className='flex'>
                                                                    {
                                                                        order.channels && order.channels.adform &&
                                                                        <div
                                                                            className="w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.facebook &&
                                                                        <div
                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.instagram &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.linkedin &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_shopping &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.tiktok &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.twitter &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_analytics &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_analytics_4 &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.snapchat &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.bing &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.bidtheatre &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-bidtheatre-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.dv360 &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        (order.clientStatus.id === 1 || order.clientStatus.id === 4) &&
                                                                        <div className='py-1 px-2 rounded-full bg-gray-100 border border-gray-400 text-xs text-gray-400 font-semibold'>
                                                                            {order.clientStatus ? order.clientStatus.name : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 5 &&
                                                                        <div className='py-1 px-2 rounded-full bg-orange-100 border border-orange-400 text-xs text-orange-400 font-semibold'>
                                                                            {order.clientStatus ? order.clientStatus.name : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 2 &&
                                                                        <div className='py-1 px-2 rounded-full bg-red-100 border border-red-400 text-xs text-red-400 font-semibold'>
                                                                            {order.clientStatus ? order.clientStatus.name : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 3 &&
                                                                        <div className='py-1 px-2 rounded-full bg-green-100 border border-green-400 text-xs text-green-400 font-semibold'>
                                                                            {order.clientStatus ? order.clientStatus.name : "Error"}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* DESKTOP CARD */}
                                            {
                                                this.state.orders.map((order, index) => {
                                                    if (index > this.state.limit) {
                                                        return;
                                                    }
                                                    return (
                                                        <div
                                                            key={index}
                                                            className='hidden md:flex flex-col rounded-lg bg-gray-50 p-4 shadow md:justify-between cursor-pointer'
                                                            onClick={() => {
                                                                window.open("/client/order/" + order.id, "_blank");
                                                            }}
                                                        >
                                                            <div className='flex justify-between items-center mb-4'>
                                                                <div className="font-medium text-sm">
                                                                    {order.name}
                                                                </div>
                                                                <div className='bg-gray-100 rounded-full p-2 ml-2 md:flex md:flex-col md:self-start'
                                                                    style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}

                                                                >
                                                                    <ChevronRightIcon className="h-5 w-5 text-gray-700" aria-hidden="true"
                                                                        style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='flex justify-between items-center'>
                                                                <div className='flex'>
                                                                    {
                                                                        order.channels && order.channels.adform &&
                                                                        <div
                                                                            className="w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.facebook &&
                                                                        <div
                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.instagram &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.linkedin &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_shopping &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.tiktok &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.twitter &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_analytics &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.google_analytics_4 &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.snapchat &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.bing &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.bidtheatre &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-bidtheatre-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.channels && order.channels.dv360 &&
                                                                        <div
                                                                            className={'w-8 h-8 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        (order.clientStatus.id === 1 || order.clientStatus.id === 4) &&
                                                                        <div className='py-1 px-2 rounded-full bg-gray-100 border border-gray-400 text-xs text-gray-400 font-semibold'>
                                                                            {/* {order.clientStatus ? order.clientStatus.name : "Error"} */}
                                                                            {order.clientStatus ? t("status_1") : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 5 &&
                                                                        <div className='py-1 px-2 rounded-full bg-orange-100 border border-orange-400 text-xs text-orange-400 font-semibold'>
                                                                            {order.clientStatus ? t("status_5") : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 2 &&
                                                                        <div className='py-1 px-2 rounded-full bg-red-100 border border-red-400 text-xs text-red-400 font-semibold'>
                                                                            {order.clientStatus ? t("status_2") : "Error"}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        order.clientStatus.id === 3 &&
                                                                        <div className='py-1 px-2 rounded-full bg-green-100 border border-green-400 text-xs text-green-400 font-semibold'>
                                                                            {order.clientStatus ? t("status_3") : "Error"}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        {/* MOBILE BOTTOM BUTTONS */}
                                        <div className='fixed bottom-0 left-0 flex w-full justify-center items-center space-x-2 px-4 pb-4 md:hidden'>
                                            {
                                                this.state.show_more &&
                                                <button
                                                    onClick={async () => {
                                                        this.functions.handleMobileLoadMore()
                                                    }}
                                                    className='w-full px-4 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white'>
                                                    {
                                                        this.state.load_more &&
                                                        <>
                                                            <div>
                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        !this.state.load_more &&
                                                        <>
                                                            {t("load_more")}
                                                        </>
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    // IF THERES NO ORDERS
                                    this.state.orders.length === 0 && !this.state.loading_partial &&
                                    <>
                                        <div className="h-screen flex flex-col justify-center items-center">
                                            <CubeIcon className="w-10 h-10 text-gray-500" />
                                            <div className="font-semibold mt-3">{t("no_orders")}</div>
                                            <div className="text-sm text-gray-500 mt-1 mb-3">{t("get_started")}</div>
                                            <button
                                                onClick={() => {
                                                    window.open("/client/order/step-one");
                                                }}
                                                className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative  text-white bg-purple-500 mt-4'
                                                style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                            >
                                                <span
                                                    style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                                    New Order
                                                </span>
                                            </button>
                                        </div>
                                    </>

                                }

                            </>
                        }
                    </>
                }
            </>
        )
    }
}

export default ClientOrderList;