import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {BeatLoader} from "react-spinners";
import OptimizeSearchKeywordsItem from "../optimize-search-keywords-item";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import {calls} from "./calls";

class OptimizeSearchKeywordsAsset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            error: false,
            error_message: "",
            error_modal: false,
            loading_assets: true,
            loading_campaign_assets: true,
            loading_create: false,
            keywords: [],
            asset: {},
            assets: [],
            campaign_assets: [],
            asset_name: "",
            asset_option: {name: 'Add list as list', value: 'list'}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            keywords: nextProps.keywords
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            keywords: this.props.keywords
        }, () => {
            this.functions.get();
            this.functions.getCampaign();
        });
    }

    init = {};

    functions = {
        get: () => {
            calls.getAssets(this.state.campaign.client_id).then((response) => {
                this.setState({
                    loading_assets: false,
                    assets: response.data
                })
            }, (error) => {

            });
        },
        getCampaign: () => {
            calls.getCampaignAssets(this.state.campaign.id).then((response) => {
                this.setState({
                    loading_campaign_assets: false,
                    campaign_assets: response.data
                })
            }, (error) => {

            });
        },
        add: () => {
            if(!this.state.loading_add){
                if(!this.state.asset.name || !this.state.asset_option.value){
                    this.setState({
                        error: true
                    })
                }else{
                    this.setState({
                        loading_add: true
                    }, () => {
                        if(this.state.asset_option.value === 'keyword'){
                            calls.addKeywords({
                                asset_id: this.state.asset.id,
                                campaign_id: this.state.campaign.id
                            }).then((response) => {
                                this.setState({
                                    loading_add: false,
                                    asset_option: {},
                                    asset: {}
                                })
                            }, (error) => {
                                this.setState({
                                    loading_add: false
                                })
                            });
                        }else if(this.state.asset_option.value === 'list'){
                            calls.addAsset({
                                resource_name: this.state.asset.resource_name,
                                campaign_id: this.state.campaign.id
                            }).then((response) => {
                                let asset = {
                                    channel: this.state.asset.channel,
                                    name: this.state.asset.name,
                                    campaign_resource_name: response.data
                                };
                                this.state.campaign_assets.unshift(asset);
                                this.setState({
                                    loading_add: false,
                                    asset_option: {},
                                    asset: {},
                                    campaign_assets: this.state.campaign_assets
                                })
                            }, (error) => {
                                this.setState({
                                    loading_add: false
                                })
                            });
                        }
                    })
                }
            }
        },
        create: () => {
            if(!this.state.loading_create){
                if(this.state.asset_name === ""){
                    this.setState({
                        error: true
                    })
                }else{
                    this.setState({
                        loading_create: true
                    }, () => {
                        let object = {
                            name: this.state.asset_name,
                            keywords: this.state.keywords.map((item) => {
                                return {text: item.text, type: item.type}
                            }),
                            campaign: this.state.campaign.id
                        };
                        calls.createAsset(object).then((response) => {
                            this.setState({
                                loading_create: false,
                                success_modal: true,
                                success_message: 'Asset created',
                                asset_name: ""
                            })
                        }, (error) => {

                        });
                    })
                }
            }
        },
        remove: (item) => {
            if(!item.loading){
                item.loading = true;
                this.setState({
                    campaign_assets: this.state.campaign_assets
                }, () => {
                    let object = {
                        resource_name: item.campaign_resource_name,
                        campaign: this.state.campaign.id
                    };
                    calls.removeAsset(object).then((response) => {
                        this.state.campaign_assets = this.state.campaign_assets.filter((asset) => {
                            return asset.resource_name !== item.resource_name
                        });
                        this.setState({
                            campaign_assets: this.state.campaign_assets
                        })
                    }, (error) => {
                        item.loading = false;
                        this.setState({
                            campaign_assets: this.state.campaign_assets,
                            error_message: error.body.message,
                            error_modal: true
                        })
                    });
                })
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="Optimize-Search-Asset">
                {
                    <SweetAlert
                        show={this.state.success_modal}
                        title={this.state.success_message}
                        type="success"
                        text={"Click OK to continue"}
                        confirmButtonText="OK"
                        onConfirm={() => {
                            this.setState({success_modal: false});
                        }}
                    />
                }
                {
                    <SweetAlert
                        show={this.state.error_modal}
                        title="Ups!"
                        type="warning"
                        text={this.state.error_message}
                        confirmButtonText="OK"
                        onConfirm={() => {
                            this.setState({ warning_modal: false});
                        }}
                    />
                }
                <div className="Optimize-Search-Asset-Title">
                    Add existing negative Keyword list to campaign
                </div>
                <div style={{marginBottom: '10px'}} className="Optimize-Search-Asset-Get">
                    {
                        this.state.loading_add &&
                        <div className="Optimize-Search-Add-Loader">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    <div className="Optimize-Search-Asset-Get-dropdown">
                        <Dropdown
                            noTitle={true}
                            filter={false}
                            size={'full'}
                            tiny={true}
                            placeholder={(this.state.loading_assets || this.state.loading_campaign_assets)?"Loading lists":((!this.state.loading_assets&&!this.state.loading_campaign_assets&&this.state.assets.length<1)?"No lists available":"Select list")}
                            loading={this.state.loading_assets || this.state.loading_campaign_assets}
                            removeArrow={!this.state.loading_assets && this.state.assets.length < 1}
                            options={this.state.assets}
                            value={this.state.asset}
                            extraItem={"count"}
                            extraItemTitle={"Negative keywords"}
                            onChange={(value) => {
                                this.setState({
                                    asset: value
                                })
                            }}
                        />
                    </div>
                    {
                        false &&
                        <div className="Optimize-Search-Add-dropdown">
                            <Dropdown
                                noTitle={true}
                                filter={false}
                                title={'Order'}
                                size={'full'}
                                tiny={true}
                                error={this.state.error && !this.state.asset_option.value}
                                placeholder={"Select option"}
                                options={[
                                    {name: 'Add list as keywords', value: 'keywords'},
                                    {name: 'Add list as list', value: 'list'},
                                ]}
                                value={this.state.asset_option}
                                onChange={(value) => {
                                    this.setState({
                                        asset_option: value
                                    })
                                }}
                            />
                        </div>
                    }
                    <div className="Optimize-Search-Asset-Get-dropdown-Button">
                        <div onClick={() => {
                            if(!this.state.loading_assets && this.state.assets.length > 0){
                                this.functions.add();
                            }
                        }} className="Button ButtonFull ButtonNeutral">
                            {
                                !this.state.loading_assets && this.state.assets.length > 0 &&
                                <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.campaign_assets.map((item) => {
                        return(
                            <div className="Optimize-Search-Asset-Added">
                                {
                                    item.loading &&
                                    <div className="Optimize-Search-Add-Loader">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#388f8a'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                <div className="Optimize-Query-Item-Container-Channel">
                                    {
                                        item.channel === 'google' &&
                                        <div className="Optimize-Query-Item-Container-Channel-Google">
                                            <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                                        </div>
                                    }
                                </div>
                                <div className="Optimize-Search-Asset-Added-Name">
                                    {item.name}
                                </div>
                                <div className="Optimize-Search-Asset-Added-Button">
                                    <div onClick={() => {
                                        this.functions.remove(item);
                                    }} className="Button ButtonRed ButtonFull">
                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    false &&
                    <div className="Optimize-Search-Asset-Add">
                        <div className="Optimize-Search-Asset-Title">
                            Create asset ({this.state.keywords.length}{" "} negative keywords)
                        </div>
                        <div className="Optimize-Search-Asset-Get">
                            {
                                this.state.loading_create &&
                                <div className="Optimize-Search-Add-Loader">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={10}
                                        color={'#388f8a'}
                                        loading={true}
                                    />
                                </div>
                            }
                            <div className="Optimize-Search-Asset-Get-dropdown">
                                <Input
                                    noTitle={true}
                                    type={'text'}
                                    placeholder={'Asset list name'}
                                    error={this.state.error && this.state.asset_name === ""}
                                    value={this.state.asset_name}
                                    size={'full'}
                                    onChange={(event) => this.setState({asset_name: event.target.value})}
                                />
                            </div>
                            <div className="Optimize-Search-Asset-Get-dropdown-Button">
                                <div onClick={() => {
                                    this.functions.create();
                                }} className="Button ButtonFull ButtonNeutral">
                                    <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchKeywordsAsset;
