import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import ClientAudience from "../client-audience";
import TextArea from "../text-area";

class ClientInstagramPixel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            pixel: {},
            pixels: [],
            error_modal: false,
            error_message: ""
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            item: this.props.item
        }, () => {
            this.functions.getPixels();
        })
    }

    init = {};

    functions = {
        getPixels: () => {
            this.setState({
                pixels: [],
            }, () => {
                calls.getPixels({client: this.state.client}).then((response) => {
                    this.setState({
                        pixels: response.data,
                    })
                }, (error) => {
                    console.log(error);
                });
            })
        },
        addPixel: (item) => {
            this.setState({
                pixel: {},
                loading: true,
            }, () => {
                calls.addPixel({channel: 'instagram', client: this.state.client, pixel: item}, this.state.client).then((response) => {
                    this.state.item.facebookPixelName = response.data.name;
                    this.state.item.facebookPixelId = response.data.id;
                    this.state.item.facebookPixelCode = response.data.code;
                    this.setState({
                        item: this.state.item,
                        loading: false,
                    }, () => {
                        this.props.onChange(this.state.item);
                    })
                }, (error) => {
                    this.setState({
                        error_modal: true,
                        error_message: "Something went wrong",
                        loading: false
                    })
                });
            })
        },
        removePixel: () => {
            this.setState({
                loading: true,
            }, () => {
                calls.removePixel({channel: 'instagram', client: this.state.client}).then((response) => {
                    delete this.state.item.facebookPixelName;
                    delete this.state.item.facebookPixelId;
                    delete this.state.item.facebookPixelCode;
                    this.setState({
                        item: this.state.item,
                        loading: false,
                    }, () => {
                        this.props.onChange(this.state.item);
                    })
                }, (error) => {
                    this.setState({
                        error_modal: true,
                        error_message: "Something went wrong",
                        loading: false
                    })
                });
            })
        },
        addConversionEvent: () => {

        }
    };

    renders = {};

    render() {
        return (
            <div className="bg-white rounded-md px-4 py-4">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove pixel"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            warning_modal: false
                        }, () => {
                            this.functions.removePixel();
                        })
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div>
                    {
                        !this.state.item.facebookPixelName &&
                        <div className="Section" style={{paddingTop: '0px'}}>
                            <Dropdown
                                filter={false}
                                title={'Select a pixel'}
                                size={'full'}
                                placeholder={this.state.loading?"Adding pixel":(this.state.pixels.length > 0 ? 'Click here ..' : 'No pixels are available')}
                                removeArrow={this.state.loading || this.state.pixels.length < 1}
                                options={this.state.pixels}
                                value={this.state.pixel}
                                onChange={(value) => {
                                    this.functions.addPixel(value);
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.item.facebookPixelName &&
                        <div className="Section" style={{paddingTop: '0px', position: 'relative'}}>
                            {
                                this.state.loading &&
                                <div style={{marginTop: '26px', width: '100%'}} className="Creative-ads-item-loading">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={10}
                                        color={'#388f8a'}
                                        loading={true}
                                    />
                                </div>
                            }
                            <Input
                                title={'Selected pixel'}
                                value={this.state.item.facebookPixelName}
                                disabled={true}
                                size={'full'}
                                type={"text"}
                            />
                            {
                                !this.state.loading &&
                                <div style={{minWidth: '55px', marginLeft: '10px', marginTop: '26px'}}>
                                    <div onClick={() => {
                                        this.setState({
                                            warning_modal: true
                                        })
                                    }} className="Button ButtonRed ButtonFull">
                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.item.facebookPixelName &&
                        <div style={{width: '100%', marginTop: '20px'}}>
                            <TextArea
                                clickToCopy={true}
                                title={"Pixel code (click to copy)"}
                                value={this.state.item.facebookPixelCode}
                            />
                        </div>
                    }
                    {
                        !this.state.item.facebookConversionEvent && false &&
                        <div className="Section">
                            <Dropdown
                                filter={false}
                                title={'Select conversion event'}
                                size={'full'}
                                placeholder={"Click here"}
                                options={[
                                    {name: "Made a purchase", value: "PURCHASED"},
                                    {name: "Visited website", value: "WEBSITE_VISIT"},
                                    {name: "Initiated checkout", value: "INITIATED_CHECKOUT"},
                                    {name: "Added to cart", value: "ADDED_TO_CART"},
                                    {name: "Registered/Signed up", value: "REGISTER"}
                                ]}
                                value={this.state.conversion_event?this.state.conversion_event:{}}
                                onChange={(value) => {
                                    this.functions.addConversionEvent(value);
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.item.facebookConversionEvent &&
                        <div className="Section" style={{position: 'relative'}}>
                            {
                                this.state.loading &&
                                <div style={{marginTop: '26px', width: '100%'}} className="Creative-ads-item-loading">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={10}
                                        color={'#388f8a'}
                                        loading={true}
                                    />
                                </div>
                            }
                            <Input
                                title={'Selected conversion event'}
                                value={this.state.item.facebookConversionEvent}
                                disabled={true}
                                size={'full'}
                                type={"text"}
                            />
                            {
                                !this.state.loading &&
                                <div style={{minWidth: '55px', marginLeft: '10px', marginTop: '26px'}}>
                                    <div onClick={() => {
                                        this.setState({
                                            warning_modal: true
                                        })
                                    }} className="Button ButtonRed ButtonFull">
                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.item.facebookPixelName &&
                        <ClientAudience
                            item={this.state.item}
                            client={this.state.client}
                            channel={'facebook'}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default ClientInstagramPixel;
