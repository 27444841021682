import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import {calls} from "./calls";
import AccordionFixed from "../accordion-fixed";
import CampaignStatusLevels from "../campaign-status-levels";
import Loader from "../loader";
import CampaignOverview from "../campaign-overview";
import CampaignDonuts from "../campaign-donuts";

var moment = require('moment');

class CampaignStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "",
            data: {},
            item: {},
            loading: false,
            loading_status: false
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            type: this.props.type,
            item: this.props.item
        })
    }

    init = {};

    functions = {
        pause: () => {
            if (!this.state.loading_status) {
                this.setState({
                    message: 'Pausing campaign',
                    loading_status: true,
                    pause_modal: false
                }, () => {
                    calls.pauseCampaign(this.props.item.id).then((response) => {
                        this.setState({
                            loading_status: false
                        }, () => {
                            this.props.history.push('/user/campaigns/paused');
                        })
                    }, (error) => {
                        this.setState({
                            error_modal: true,
                            loading_status: false,
                            error_message: error.body.message
                        });
                    });
                })
            }
        },
        activate: () => {
            if (!this.state.loading_status) {
                this.setState({
                    message: 'Activating campaign',
                    loading_status: true,
                    activate_modal: false
                }, () => {
                    calls.activateCampaign(this.props.item.id).then((response) => {
                        this.setState({
                            loading_status: false
                        }, () => {
                            this.props.history.push('/user/campaigns/active');
                        })
                    }, (error) => {
                        this.setState({
                            error_modal: true,
                            loading_status: false,
                            error_message: error.body.message
                        });
                    });
                })
            }
        },
        remove: () => {
            if (!this.state.loading_status) {
                this.setState({
                    message: 'Removing campaign',
                    loading_status: true,
                    remove_modal: false
                }, () => {
                    calls.removeCampaign(this.props.item.id).then((response) => {
                        this.setState({
                            loading_status: false
                        }, () => {
                            this.props.onRemove(this.props.item);
                        })
                    }, (error) => {
                        this.setState({
                            error_modal: true,
                            loading_status: false,
                            error_message: error.body.message
                        });
                    });
                })
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="Campaign-Overview">
                <div className="campaign-Overview-top">
                    Settings
                </div>
                <div className="Campaign-Overview-inner">
                    {
                        this.state.loading_status &&
                        <Loader
                            title={this.state.message}
                        />
                    }
                    <SweetAlert
                        show={this.state.error_modal}
                        title="Error"
                        type="error"
                        text={this.state.error_message}
                        confirmButtonText="OK"
                        onConfirm={() => {
                            this.setState({error_modal: false});
                        }}
                    />
                    <SweetAlert
                        show={this.state.pause_modal}
                        title="Pause campaign"
                        type="warning"
                        text={"Are you sure ?"}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            this.functions.pause();
                        }}
                        onCancel={() => {
                            this.setState({pause_modal: false});
                        }}
                    />
                    <SweetAlert
                        show={this.state.activate_modal}
                        title="Activate campaign"
                        type="warning"
                        text={"Are you sure ?"}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            this.functions.activate();
                        }}
                        onCancel={() => {
                            this.setState({activate_modal: false});
                        }}
                    />
                    <SweetAlert
                        show={this.state.remove_modal}
                        title="Remove campaign"
                        type="warning"
                        text={"Are you sure ?"}
                        confirmButtonText="Yes"
                        cancelButtonText="No"
                        showCancelButton={true}
                        onConfirm={() => {
                            this.functions.remove();
                        }}
                        onCancel={() => {
                            this.setState({remove_modal: false});
                        }}
                    />
                    <div className="campaignStatus-Container">
                        <div className="campaignStatus-Buttons">
                            {
                                (this.state.type === 'active' || this.state.type === 'ended') &&
                                <div
                                    className={cx("Button ButtonNeutral")}
                                    style={(this.state.type === 'active' || this.state.type === 'ended') ? {width: 'calc(33% - 10px)'} : {}}
                                    onClick={() => {
                                        //
                                    }}>
                                    Edit
                                </div>
                            }
                            {
                                (this.state.type === 'active' || this.state.type === 'ended') &&
                                <div
                                    className={cx("Button ButtonNeutral")}
                                    style={(this.state.type === 'active' || this.state.type === 'ended') ? {width: 'calc(33% - 10px)'} : {}}
                                    onClick={() => {
                                        let query = 'campaign=' + this.props.item.id;
                                        this.props.history.push('/user/reports/all?' + query);
                                    }}>
                                    Report
                                </div>
                            }
                            {
                                (this.state.type === 'active' || this.state.type === 'upcoming') &&
                                <div style={this.state.type === 'active' ? {width: 'calc(25% - 10px) !important'} : {}}
                                     className={cx("Button ButtonNeutral")}
                                     onClick={() => {
                                         let query = 'campaign=' + this.props.item.id;
                                         this.props.history.push('/user/optimize/all?' + query);
                                     }}>
                                    Optimize
                                </div>
                            }
                            {
                                this.state.type === 'active' &&
                                <div style={this.state.type === 'active' ? {width: 'calc(25% - 10px) !important'} : {}}
                                     className={cx("Button ButtonNeutral")}
                                     onClick={() => {
                                         this.setState({
                                             pause_modal: true
                                         });
                                     }}>
                                    Pause
                                </div>
                            }
                            {
                                this.state.type === 'paused' &&
                                <div className={cx("Button ButtonNeutral")}
                                     onClick={() => {
                                         this.functions.activate();
                                     }}>
                                    Activate
                                </div>
                            }
                            {
                                <div
                                    className={cx("Button ButtonRed")}
                                    style={(this.state.type === 'active' || this.state.type === 'ended') ? {width: 'calc(33% - 10px)'} : {}}
                                    onClick={() => {
                                        this.setState({
                                            remove_modal: true
                                        })
                                    }}>
                                    Delete
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CampaignStatus;
