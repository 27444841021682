import React, { Component, Fragment } from 'react';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';

class MigrateClients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            migrate_from: { id: 0, name: "Search ..." },
            migrate_to: null,
            affected_clients: {
                total: 0,
                channels: {}
            },
            open: false,
            loading: false,
            loading_affected: false,
            show_warning: false,
            search: "",
            error: null,
        }
    };

    componentDidMount() {
        this.setState({
            agents: this.props.agents,
            migrate_from: this.props.migrate_from,
            affected_clients: this.props.affected_clients,
            error: this.props.error,
            loading: this.props.loading,
            loading_affected: this.props.loading_affected,

        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            agents: nextProps.agents,
            migrate_from: nextProps.migrate_from,
            affected_clients: nextProps.affected_clients,
            error: nextProps.error,
            loading: nextProps.loading,
            loading_affected: nextProps.loading_affected,

        })
    }

    functions = {

    }

    calls = {

    }

    renders = {

    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    render() {
        return (
            <div className='w-full'>
                <div className='w-full'>
                    <div className="flex flex-col w-full space-y-4">
                        <div className="w-full">
                            <DropdownTailwind
                                ref="searchDropdown"
                                label={"From:"}
                                searchInput={true}
                                locked={true}
                                selected={this.state.migrate_from && this.state.migrate_from.id ? this.state.migrate_from : { id: 0, name: "Search ..." }}
                                options={Array.isArray(this.state.agents) && this.state.agents.length > 0 ? this.state.agents : [{ id: 0, name: "No agents available" }]}
                                loadingSearch={this.state.loading}
                                onChange={async (user) => {
                                }}
                                onSearch={async (value) => {
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <DropdownTailwind
                                ref="searchDropdown"
                                label={"To:"}
                                searchInput={true}
                                search={this.state.search}
                                selected={this.state.migrate_to !== null ? this.state.migrate_to : { id: 0, name: "Search ..." }}
                                options={
                                    Array.isArray(this.state.agents) && this.state.agents.length > 0
                                        ? this.state.agents.filter(agent => agent.id !== this.state.migrate_from.id)
                                        : [{ id: 0, name: "No agents available" }]
                                }
                                loadingSearch={this.state.loading}
                                onChange={async (agent) => {
                                    this.state.error = null;
                                    this.state.migrate_to = agent;
                                    await this.promisedSetState({
                                        migrate_to: this.state.migrate_to,
                                        error: this.state.error,
                                    });
                                    await this.props.getAffectedClients(agent.id)
                                }}
                                onSearch={async (value) => {

                                }}
                            />
                        </div>
                    </div>
                    {
                        this.state.loading_affected &&
                        <div className='flex flex-col w-full pt-8 items-center justify-center space-y-4'>
                            <div style={{ borderTopColor: "transparent" }}
                                class={"w-4 h-4 border-2 border-indigo-600 border-solid rounded-full animate-spin"}></div>
                            <div className="text-sm font-medium text-gray-500">Loading ...</div>
                        </div>
                    }

                    {
                        !this.state.loading_affected && this.state.migrate_to !== null && this.state.affected_clients && this.state.affected_clients.total > 0 &&
                        <div className='bg-blue-100 rounded-md text-blue-500 p-4 text-sm font-medium mt-4 w-full'>
                            <div>
                                This will affect <span className='font-bold'>{this.state.affected_clients.total}</span> client(s)
                            </div>
                            {
                                this.state.affected_clients && Array.isArray(this.state.affected_clients.channels) && this.state.affected_clients.channels.length > 0 &&
                                <div className='flex flex-col pt-4'>

                                    <div className='border-t border-blue-300 py-2'>
                                        Channels:
                                    </div>
                                    {
                                        this.state.affected_clients.channels.map((channel, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`flex justify-between items-center ${index === 0 ? 'border-none' : 'border-t'} border-blue-300 ${index === this.state.affected_clients.channels.length - 1 ? 'pt-2' : 'py-2'}`}
                                                >
                                                    <div>{channel.name.charAt(0).toUpperCase() + channel.name.slice(1).replace(/_/, " ")}</div>
                                                    <div className='transform scale-75'>
                                                        <SwitchTailwind
                                                            value={channel.value}
                                                            onSwitch={async () => {
                                                                this.state.affected_clients.channels.find(c => c.name === channel.name).value = !channel.value;
                                                                await this.promisedSetState({
                                                                    affected_clients: this.state.affected_clients
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className='w-full pt-4'>
                            <div className="bg-red-100 text-red-500 rounded-md p-4 font-medium text-sm">
                                {this.state.error}
                            </div>
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default MigrateClients;
