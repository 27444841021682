import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';

export default class ChartTemplateDoughnut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: {},
            level: {},
            properties: [],
            chart_type: {name: 'Doughnut chart', value: 'doughnut'},
            open: true,
            data: this.props.data,
            line: {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: this.props.header,
                    position: this.props.labelDoughnutPos || 'top',
                    align: 'center',
                    fontStyle: 'bold',
                    fontSize: 15,
                    fontFamily: "'Poppins', sans-serif",
                    fontColor: '#333333',
                    lineHeight: 1,
                },
                legend: {
                    display: true,
                    position: this.props.labelDoughnutPos || 'top',
                    align: 'center',
                    labels: {
                        boxWidth: 11,
                        borderRadius: 5,
                        fontSize: 12,
                    },
                },
                layout: {
                    padding: {
                        left: 8,
                        right: 8,
                        top: this.props.labelBarPos && this.props.labelBarPos === 'left' ? 40 : 8,
                        bottom: 8,
                    },
                },
            },
        };
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     this.setState({
    //         report: nextProps.report ? nextProps.report : {},
    //         level: nextProps.level ? nextProps.level : {},
    //     });
    // }

    componentDidMount() {
        this.setState(
            {
                report: this.props.report ? this.props.report : {},
                level: this.props.level ? this.props.level : {},
                properties: this.props.properties ? this.props.properties : [],
            },
            () => {
                this.setState(
                    {
                        chart: {name: 'click', value: 'click'},
                    },
                    () => {
                        //this.functions.data();
                    }
                );
            }
        );
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <>
                {this.state.chart.name && this.state.chart_type.value === 'doughnut' && (
                    <Doughnut options={this.state.line} responsive={true} data={this.state.data} width={null} height={null} />
                )}
            </>
        );
    }
}
