import React, {Component, Fragment} from 'react';
import DropDown from '../../../modules/dropdown';
import Tabs from '../reports-tabs';
// import Dropdown from '../../../components/dropdown';

import FeatherIcon from 'feather-icons-react';
import {BeatLoader} from 'react-spinners';


import './style.css';

class ReportsSlideOverContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            checkboxes: [
                {id: 1, name: 'All', active: false},
                {id: 2, name: 'AddForm', active: false},
                {id: 3, name: 'Facebook', active: true},
                {id: 4, name: 'Google Adwords', active: true},
                {id: 5, name: 'Google Analytics', active: false},
                {id: 6, name: 'Instagram', active: false},
                {id: 7, name: 'Youtube', active: false},
            ],
            fakeDataCharts: [
                {
                    id: 1,
                    header: 'Impressions & Completions per media',
                    description: 'Compare the impressions to completions for each active media',
                    chartTypes: ['Bar chart', 'Line chart', 'Pie chart'],
                },
                {
                    id: 2,
                    header: 'Reach per Platform',
                    description: 'Showcase the reach per active platform',
                    chartTypes: 'Pie chart',
                },
                {
                    id: 3,
                    header: 'Clicks & CTR',
                    description: 'Compare the impressions to completions for each active media',
                    chartTypes: 'Bar chart',
                },
                {
                    id: 4,
                    header: 'Clicks & CTR',
                    description: 'Compare the impressions to completions for each active media',
                    chartTypes: 'Bar chart',
                },
                {
                    id: 5,
                    header: 'Clicks & CTR',
                    description: 'Compare the impressions to completions for each active media',
                    chartTypes: 'Bar chart',
                },
            ],
            fakeDataNumbers: [
                {
                    id: 1,
                    header: 'Impressions',
                    description: 'During the selected period of time',
                },
                {
                    id: 2,
                    header: 'Media spend',
                    description: 'During the selected period of time',
                },
                {
                    id: 3,
                    header: 'Avarage completion rate',
                    description: 'During the selected period of time',
                },
                {
                    id: 4,
                    header: 'Avarage CPV',
                    description: 'During the selected period of time',
                },
                {
                    id: 5,
                    header: 'Clicks & CTR',
                    description: 'Compare the impressions to completions for each active media',
                },
                {
                    id: 6,
                    header: 'Conversions',
                    description: 'During the selected period of time',
                },
            ],
            fakeDataText: [
                {
                    id: 1,
                    header: 'Screenshot',
                    description: 'Image drop',
                    chartTypes: 'img',
                },
                {
                    id: 2,
                    header: 'Rich text',
                    description: 'Add comments and insights',
                    chartTypes: 'input',
                },
            ],
            fakeDataTabs: [
                {
                    number: 1,
                    name: 'Charts',
                },
                {
                    number: 2,
                    name: 'Numbers',
                },
                {
                    number: 3,
                    name: 'Text block',
                },
            ],


            dropDownData: [
                {
                    id: 1,
                    name: 'Impressions',
                },
                {
                    id: 2,
                    name: 'Media spend',
                },
                {
                    id: 3,
                    name: 'Avarage completion rate',
                },
                {
                    id: 4,
                    name: 'Avarage CPV',
                },
                {
                    id: 5,
                    name: 'Clicks & CTR',
                },
                {
                    id: 6,
                    name: 'Conversions',
                },
            ],



            activeStep:1,
            fakeCampaigns:[{"id":"12736035325","name":"Adcredo - Inhouse Kampanj #2021-04-12T17:49:24.270+00:00","status":"ENABLED","campaign_budget":"customers/2422387729/campaignBudgets/8540147962","start_date":"2021-04-12","end_date":"2022-01-01","objective":"TARGET_SPEND","daily_budget":100000000,"internal_id":"607488235fdde6ae34db4120","currency":"SEK","channel":"google","type":"campaign","is_loading":false,"insights":[{"absolute_top_impression_percentage":1,"average_cost":165000,"average_cpc":165000,"average_cpm":66000000,"clicks":4,"cost_micros":660000,"ctr":0.4,"impressions":10,"interaction_event_types":{},"interaction_rate":0.4,"interactions":4,"search_absolute_top_impression_share":0.5,"search_budget_lost_absolute_top_impression_share":0.4,"search_budget_lost_impression_share":0.4,"search_budget_lost_top_impression_share":0.4,"search_click_share":0.5827729246309281,"search_exact_match_impression_share":0.5294117647058824,"search_impression_share":0.5,"search_rank_lost_absolute_top_impression_share":0.1,"search_rank_lost_impression_share":0.1,"search_rank_lost_top_impression_share":0.1,"search_top_impression_share":0.5,"top_impression_percentage":1}],"open":false}],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            visualCharts:[
                {
                    type:'piechart',
                    label:'Pie chart',
                    icon:'pie-chart'
                },
                {
                    type:'barchart',
                    label:'Bar chart',
                    icon:'bar-chart-2'
                },
                {
                    type:'linechart',
                    label:'Line chart',
                    icon:'trending-up'
                }
            ],
            visualBlocks:[
                {
                    type:'numbers',
                    label:'Number block',
                    icon:'cloud-rain'
                },
                {
                    type:'richtext',
                    label:'Rich text block',
                    icon:'align-left'
                }
            ],
            breakdownBlocks:[
                {
                    type:'daily',
                    label:'Daily',
                    icon:'sunrise'
                },
                {
                    type:'gender',
                    label:'Gender',
                    icon:'users'
                },
                {
                    type:'age',
                    label:'Age',
                    icon:'calendar'
                }
            ]
        };
    }

    componentWillMount() {}

    componentDidMount() {}

    functions = {
        setActiveTab: (number) => {
            this.setState({activeTab: number});
        },

        handleChangeCheckbox: (id, value) => {
            let checkboxes = [...this.state.checkboxes];
            checkboxes.forEach(function (c) {
                if (c.id === id) {
                    if (c.active === value) {
                        c.active = false;
                    } else {
                        c.active = true;
                    }
                }
            });

            // (c) => (c.id === id ? (c.active = true) : (c.active = false));

            this.setState({checkboxes: checkboxes});
        },

        handleFilter: ({target}) => {
            console.log('target.value', target.value);
        },
    };

    renders = {};

    render() {
        return (
            <div className="report--slideover">

                <div className={`p-4 ${this.state.activeStep == 1 ? 'rounded-md bg-gray-100' : (this.state.activeStep != 2 && 'border-b')}`}>
                    <div className={"cursor-pointer flex flex-row items-center justify-between"} onClick={() => {this.setState({activeStep: 1})}}>
                        <div className={"step-row flex flex-row items-center text-lg font-bold"}>
                            <div className={`step mr-2 w-8 h-8 flex items-center justify-center rounded-full font-bold text-xs leading-none ${this.state.activeStep == 1 ? 'bg-purple-900 text-white' : (this.state.activeStep > 1 ? 'bg-green-500 text-white' : '')}`}>1</div>
                            Datasources
                        </div>
                        {this.state.activeStep != 1 &&
                            <button className={`text-xs transition-all duration-200 bg-opacity-100 hover:bg-opacity-50 rounded-full py-2 px-4 font-bold ${this.state.activeStep > 1 ? 'bg-purple-100 text-purple-900' : 'bg-purple-500 text-white'}`}>
                                {this.state.activeStep > 1 ? 'Change' : 'Select' } datasource
                            </button>
                        }
                    </div>
                    {this.state.activeStep == 1 &&
                        <div className="mt-3">
                            <div className="px-4 sm:px-6 mb-10">
                                    {
                                        this.state.fakeCampaigns.map((campaign, index) => {
                                            return (
                                                <Fragment>
                                                    <div className="flex flex-1 flex-row justify-center mb-2">
                                                        <div onClick={() => {
                                                            if (!campaign.open) {
                                                                this.props.getAdgroups(campaign);
                                                                campaign.open = true;
                                                            } else {
                                                                campaign.open = false;
                                                            }
                                                            this.setState({
                                                                campaigns: this.state.campaigns
                                                            })
                                                        }}
                                                             className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            {
                                                                !campaign.open && !campaign.loading &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                             icon="plus"/>
                                                            }
                                                            {
                                                                campaign.open && !campaign.loading &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                             icon="minus"/>
                                                            }
                                                            {
                                                                campaign.loading &&
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={4}
                                                                    color={'#060534'}
                                                                    loading={true}
                                                                />
                                                            }
                                                        </div>
                                                        <div onClick={() => {
                                                            if (!campaign.is_loading) {
                                                                if (this.state.selected_campaigns[campaign.id]) {
                                                                    delete this.state.selected_campaigns[campaign.id]
                                                                } else {
                                                                    this.state.selected_campaigns[campaign.id] = campaign;
                                                                }
                                                                this.props.updateSelectedCampaigns(this.state.selected_campaigns);
                                                            }
                                                        }}
                                                             className="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center">
                                                            {
                                                                this.state.selected_campaigns[campaign.id] &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                             icon="check"/>
                                                            }
                                                            {
                                                                campaign.is_loading &&
                                                                <div
                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                    <BeatLoader
                                                                        sizeUnit={"px"}
                                                                        size={4}
                                                                        color={'#060534'}
                                                                        loading={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="flex flex-1 flex-col truncate">
                                                            <div className="font-bold text-xs truncate">
                                                                {campaign.name}
                                                            </div>
                                                            <div className="text-xxs">
                                                                CAMPAIGN (<span
                                                                className="uppercase">{campaign.channel}</span>)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.adgroups.filter((adgroup) => {
                                                            return adgroup.campaign === campaign.id && campaign.open
                                                        }).map((adgroup) => {
                                                            return (
                                                                <Fragment>
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                        <div onClick={() => {
                                                                            if (!adgroup.open) {
                                                                                this.props.getAds(adgroup);
                                                                                adgroup.open = true;
                                                                            } else {
                                                                                adgroup.open = false;
                                                                            }
                                                                            this.setState({
                                                                                adgroups: this.state.adgroups
                                                                            })
                                                                        }}
                                                                             className="cursor-pointer h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                                                            {
                                                                                !adgroup.open && !adgroup.loading &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14} icon="plus"/>
                                                                            }
                                                                            {
                                                                                adgroup.open && !adgroup.loading &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14} icon="minus"/>
                                                                            }
                                                                            {
                                                                                adgroup.loading &&
                                                                                <BeatLoader
                                                                                    sizeUnit={"px"}
                                                                                    size={4}
                                                                                    color={'#060534'}
                                                                                    loading={true}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!adgroup.is_loading) {
                                                                                if (this.state.selected_adgroups[adgroup.id]) {
                                                                                    delete this.state.selected_adgroups[adgroup.id];
                                                                                    this.props.updateSelectedAdgroups(this.state.selected_adgroups, null);
                                                                                } else {
                                                                                    this.state.selected_adgroups[adgroup.id] = adgroup;
                                                                                    this.props.updateSelectedAdgroups(this.state.selected_adgroups, adgroup);
                                                                                }
                                                                            }
                                                                        }}
                                                                             className="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center">
                                                                            {
                                                                                this.state.selected_adgroups[adgroup.id] &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check"/>
                                                                            }
                                                                            {
                                                                                adgroup.is_loading &&
                                                                                <div
                                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                    <BeatLoader
                                                                                        sizeUnit={"px"}
                                                                                        size={4}
                                                                                        color={'#060534'}
                                                                                        loading={true}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {adgroup.name}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                ADGROUP (<span
                                                                                className="uppercase">{campaign.channel}</span>)
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.ads.filter((ad) => {
                                                                            return ad.adgroup === adgroup.id && adgroup.open
                                                                        }).map((ad) => {
                                                                            return (
                                                                                <div
                                                                                    className="flex flex-1 flex-row justify-center pl-20 mb-2">
                                                                                    <div
                                                                                        className="h-8 w-8 flex rounded mr-2"></div>
                                                                                    <div onClick={() => {
                                                                                        if (!ad.is_loading) {
                                                                                            if (this.state.selected_ads[ad.id]) {
                                                                                                delete this.state.selected_ads[ad.id];
                                                                                                this.props.updateSelectedAds(this.state.selected_ads, null);
                                                                                            } else {
                                                                                                this.state.selected_ads[ad.id] = ad;
                                                                                                this.props.updateSelectedAds(this.state.selected_ads, ad);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                         className="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center">
                                                                                        {
                                                                                            this.state.selected_ads[ad.id] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14}
                                                                                                icon="check"/>
                                                                                        }
                                                                                        {
                                                                                            ad.is_loading &&
                                                                                            <div
                                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                <BeatLoader
                                                                                                    sizeUnit={"px"}
                                                                                                    size={4}
                                                                                                    color={'#060534'}
                                                                                                    loading={true}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex flex-1 flex-col">
                                                                                        <div
                                                                                            className="font-bold text-xs">
                                                                                            {ad.name !== "" ? ad.name : "No name"}
                                                                                        </div>
                                                                                        <div className="text-xxs">
                                                                                            AD (<span
                                                                                            className="uppercase">{campaign.channel}</span>)
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                        </div>
                    }
                </div>

                <div className={`p-4 ${this.state.activeStep == 2 ? 'rounded-md bg-gray-100' : (this.state.activeStep != 3 && 'border-b')}`}>
                    <div className={"cursor-pointer flex flex-row items-center justify-between"} onClick={() => {this.setState({activeStep: 2})}}>
                        <div className={"step-row flex flex-row items-center text-lg font-bold"}>
                            <div className={`step mr-2 w-8 h-8 flex items-center justify-center rounded-full font-bold text-xs leading-none ${this.state.activeStep == 2 ? 'bg-purple-900 text-white' : (this.state.activeStep > 2 ? 'bg-green-500 text-white' : 'bg-purple-100 text-purple-900')}`}>2</div>
                            Visual type
                        </div>
                        {this.state.activeStep != 2 &&
                            <button className={`text-xs transition-all duration-200 bg-opacity-100 hover:bg-opacity-50 rounded-full py-2 px-4 font-bold ${this.state.activeStep > 2 ? 'bg-purple-100 text-purple-900' : (this.state.activeStep < 2 && 'bg-purple-500 text-white')}`}>
                                {this.state.activeStep > 2 ? 'Change' : (this.state.activeStep < 2 && 'Select') } visuals
                            </button>
                        }
                    </div>
                    {this.state.activeStep == 2 &&
                        <div className="mt-3">
                            <div className={'mb-3 pb-3 border-b'}>
                                { this.state.visualCharts.map((chart, index) => {
                                        return (
                                            <div key={index} class="flex w-full flex-row items-center mb-2">
                                                <div class="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center"></div>
                                                <div className={'mr-1'}>
                                                    <FeatherIcon className={'stroke-current'} size={14} icon={chart.icon} />
                                                </div>
                                                <div class="flex flex-1 flex-col truncate">
                                                    <div class="font-bold text-xs truncate">{chart.label}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                { this.state.visualBlocks.map((block, index) => {
                                        return (
                                            <div key={index} class="flex w-full flex-row items-center mb-2">
                                                <div class="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center"></div>
                                                <div className={'mr-1'}>
                                                    <FeatherIcon className={'stroke-current'} size={14} icon={block.icon} />
                                                </div>
                                                <div class="flex flex-1 flex-col truncate">
                                                    <div class="font-bold text-xs truncate">{block.label}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className={`p-4 ${this.state.activeStep == 3 ? 'rounded-md bg-gray-100' : (this.state.activeStep != 4 && 'border-b')}`}>
                    <div className={"cursor-pointer flex flex-row items-center justify-between"} onClick={() => {this.setState({activeStep: 3})}}>
                        <div className={"step-row flex flex-row items-center text-lg font-bold"}>
                            <div className={`step mr-2 w-8 h-8 flex items-center justify-center rounded-full font-bold text-xs leading-none ${this.state.activeStep == 3 ? 'bg-purple-900 text-white' : (this.state.activeStep > 3 ? 'bg-green-500 text-white' : 'bg-purple-100 text-purple-900')}`}>3</div>
                            Breakdown
                        </div>

                        {this.state.activeStep != 3 &&
                            <button className={`text-xs transition-all duration-200 bg-opacity-100 hover:bg-opacity-50 rounded-full py-2 px-4 font-bold ${this.state.activeStep > 3 ? 'bg-purple-100 text-purple-900' : (this.state.activeStep < 3 && 'bg-purple-500 text-white')}`}>
                                {this.state.activeStep > 3 ? 'Change' : (this.state.activeStep < 3 && 'Select') } breakdown
                            </button>
                        }
                    </div>

                    {this.state.activeStep == 3 &&
                        <div className="mt-3">
                            { this.state.breakdownBlocks.map((block, index) => {
                                    return (
                                        <div key={index} class="flex w-full flex-row items-center mb-2">
                                            <div class="cursor-pointer relative h-8 w-8 rounded border-2 mr-2 flex justify-center align-middle items-center"></div>
                                            <div className={'mr-1'}>
                                                <FeatherIcon className={'stroke-current'} size={14} icon={block.icon} />
                                            </div>
                                            <div class="flex flex-1 flex-col truncate">
                                                <div class="font-bold text-xs truncate">{block.label}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>

                <div className={`p-4 ${this.state.activeStep == 4 && 'rounded-md bg-gray-100'}`}>
                    <div className={"cursor-pointer flex flex-row items-center justify-between"} onClick={() => {this.setState({activeStep: 4})}}>
                        <div className={"step-row flex flex-row items-center text-lg font-bold"}>
                            <div className={`step mr-2 w-8 h-8 flex items-center justify-center rounded-full font-bold text-xs leading-none ${this.state.activeStep == 4 ? 'bg-purple-900 text-white' : (this.state.activeStep > 4 ? 'bg-green-500 text-white' : 'bg-purple-100 text-purple-900')}`}>4</div>
                            Metrics
                        </div>
                        {this.state.activeStep != 4 &&
                            <button className={`text-xs transition-all duration-200 bg-opacity-100 hover:bg-opacity-50 rounded-full py-2 px-4 font-bold ${this.state.activeStep > 4 ? 'bg-purple-100 text-purple-900' : (this.state.activeStep < 4 && 'bg-purple-500 text-white')}`}>
                                {this.state.activeStep > 4 ? 'Change' : (this.state.activeStep < 4 && 'Select') } metrics
                            </button>
                        }
                    </div>

                    {this.state.activeStep == 4 &&
                        <div className="mt-3">
                            Metrics goes here
                        </div>
                    }
                </div>
            </div>
        );
    }
}







const Checkboxes = (props) => {
    let {checkboxes, handleChangeCheckbox} = props;

    return (
        <div className="report--slideover__data-source">
            <div className="flex items-center mb-2">
                <div className="text-sm font-bold">Select data source</div>
                <span className="text-gray-500 ml-2 px-3 bg-gray-100 rounded-lg text-xs font-bold">{checkboxes.length - 1}</span>
            </div>
            <div className="data-source flex items-center overflow-x-auto">
                {checkboxes.map(({id, name, active}, index) => (
                    <label
                        className="custom-checkbox flex items-center w-full whitespace-no-wrap mr-6"
                        key={id}
                        onClick={() => handleChangeCheckbox(id, active)}>
                        <input name={name} type="checkbox" checked={active} onChange={() => {}} />
                        <span className="checkmark"></span>
                        {name}
                    </label>
                ))}
            </div>
        </div>
    );
};

const ChartBlock = (props) => {
    const {header, description, chartTypes, addingBlockData} = props;

    return (
        <div className="chart-block p-4 mb-4 rounded-lg border border-red-50">
            <div className="text-lg font-bold mb-3">{header}</div>
            <div className="text-sm font-light">{description}</div>
            <div className="left-bordered flex justify-between">
                <div>
                    <div className="ml-3 text-sm font-bold">Avaible chart types</div>
                    {typeof chartTypes === 'object' ? (
                        chartTypes.map((chartType, index) => (
                            <div className="ml-3 text-sm font-light" key={index}>
                                {chartType}
                            </div>
                        ))
                    ) : (
                        <div className="ml-3 text-sm font-light">{chartTypes}</div>
                    )}
                </div>
                <div className="flex items-end">
                    <button
                        className="bg-purple-500 text-white font-bold text-xs rounded-full py-2 px-8 hover:bg-green-500 transition duration-150 focus:outline-none"
                        style={{whiteSpace: 'nowrap'}}
                        onClick={() => addingBlockData()}>
                        Use this block
                    </button>
                </div>
            </div>
        </div>
    );
};

const NumberAndTextBlock = (props) => {
    const {header, description, addingBlockData} = props;

    return (
        <div className="number-block p-4 mb-4 rounded-lg border border-red-50">
            <div className="flex justify-between">
                <div>
                    <div className="text-lg font-bold mb-3">{header}</div>
                    <div className="text-sm font-light flex items-center">{description}</div>
                </div>

                <div className="flex items-end min-w-min">
                    <button
                        className="bg-purple-500 text-white font-bold text-xs rounded-full py-2 px-8 hover:bg-green-500 transition duration-150 focus:outline-none"
                        style={{whiteSpace: 'nowrap'}}
                        onClick={() => addingBlockData()}>
                        Use this block
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportsSlideOverContent;
