import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getConversionAction(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/conversionaction/' + data.client + '/' + data.channel;
        return apiRegister.call(options, url);
    }

    static getAudienceStatus(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/audience/status/' + data.channel + '/' + data.client + '/' + data.audience;
        return apiRegister.call(options, url);
    }

    static getAudience(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/v2/audience?channel=' + data.channel + '&client=' + data.client;
        return apiRegister.call(options, url);
    }

    static createLookALike(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/v2/audience';
        return apiRegister.call(options, url);
    }

}

export {calls}