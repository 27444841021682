import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class AdvertisingPreviewSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: this.props.creatives,
            site: this.props.site,
            targeting: this.props.targeting,
            selected: this.props.selected,
            selected_keyword: this.props.selectedKeyword,
            no_container: this.props.noContainer
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            site: nextProps.site,
            targeting: nextProps.targeting,
            selected: nextProps.selected,
            selected_keyword: nextProps.selectedKeyword
        }, () => {
            //console.log(this.state.selected);
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            site: this.props.site,
            targeting: this.props.targeting,
            selected: this.props.selected,
            selected_keyword: this.props.selectedKeyword
        });
    }

    functions = {};

    init = {};

    renders = {
        site: () => {
            try{
                return this.state.site.hostname
            }catch (e) {
                return "wwww.example.com"
            }
        },
        variations: () => {
            try {
                let string = "";
                if(this.state.selected === 'search_one'){
                    string = this.state.creatives.search_headline_first + ' | ' + this.state.creatives.search_headline_second + ' | ' + this.state.creatives.search_headline_third;
                }
                if(this.state.selected === 'search_two'){
                    let first_keywords = (this.state.selected_keyword.prefix!==""?(this.state.selected_keyword.prefix + " "):"")+'<b>'+this.state.selected_keyword.name+'</b>'+(this.state.selected_keyword.suffix!==""?(" "+this.state.selected_keyword.suffix):"");
                    string = first_keywords + ' | ' + this.state.creatives.search_headline_second + ' | ' + this.state.creatives.search_headline_third;
                }
                if(this.state.selected === 'search_three'){
                    let first_keywords = (this.state.selected_keyword.prefix!==""?(this.state.selected_keyword.prefix + " "):"")+'<b>'+this.state.selected_keyword.name+'</b>'+(this.state.selected_keyword.suffix!==""?(" "+this.state.selected_keyword.suffix):"");
                    string = this.state.creatives.search_headline_first + ' | ' + first_keywords + ' | ' + this.state.creatives.search_headline_third;
                }
                if(this.state.selected === 'search_four'){
                    let first_keywords = (this.state.selected_keyword.prefix!==""?(this.state.selected_keyword.prefix + " "):"")+'<b>'+this.state.selected_keyword.name+'</b>'+(this.state.selected_keyword.suffix!==""?(" "+this.state.selected_keyword.suffix):"");
                    string = this.state.creatives.search_headline_first + ' | ' + this.state.creatives.search_headline_second + ' | ' + first_keywords;
                }
                return string;
            }catch (e) {
                console.log(e);
                return "";
            }
        },
        bodyOne: () => {
            try{
                return this.state.creatives.search_body;
            }catch (e) {
                return "";
            }
        },
        bodyTwo: () => {
            try{
                return this.state.creatives.search_body_second;
            }catch (e) {
                return "";
            }
        },
        markdown: (input) => {
            return { __html: input }
        }
    };

    render() {
        return (
            <div className={cx("Google-PreviewContainer")}>
                <div className={
                    cx("Google-PreviewBasicContainerItem",
                        {
                            ["Google-PreviewBasicContainerItemNo"]: this.state.no_container,
                        })
                }>
                    <div className="Google-PreviewBasicContainerItemInner">
                        <div className="Google-PreviewBasicContainerItemInnerTop">
                            <span className="Google-PreviewBasicContainerItemInnerTopAd">
                                Ad
                            </span>
                            <span className="Google-PreviewBasicContainerItemInnerTopUrl">
                                {this.renders.site()}
                            </span>
                        </div>
                        <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.variations())} className="Google-PreviewBasicContainerItemInnerTitle">

                        </div>
                        <div className="Google-PreviewBasicContainerItemInnerBody">
                            {this.renders.bodyOne()}{". "}{this.renders.bodyTwo()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingPreviewSearch;
