import React, { Component } from 'react';
import { BeatLoader } from "react-spinners";
import FeatherIcon from 'feather-icons-react';

export default class ReactGridLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header: "",
            data: 0,
            loading: true
        };
    };

    componentDidMount() {
        this.setState({
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            source: this.props.source ? this.props.source : {},
            static: this.props.static
        }, () => {
            if (this.state.static) {
                this.parseData();
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            source: nextProps.source ? nextProps.source : {}
        });
    }

    setLoader() {
        this.setState({
            loading: true
        })
    }

    parseData() {
        let total = 0;
        let items = [];

        items = items.concat(Array.isArray(this.state.campaigns) ? JSON.parse(JSON.stringify(this.state.campaigns.filter((campaign) => {
            return this.state.source.data_sources && this.state.source.data_sources.campaigns && this.state.source.data_sources.campaigns[campaign.id]
        }))) : []);
        items = items.concat(Array.isArray(this.state.adgroups) ? JSON.parse(JSON.stringify(this.state.adgroups.filter((adgroup) => {
            return this.state.source.data_sources && this.state.source.data_sources.adgroups && this.state.source.data_sources.adgroups[adgroup.id]
        }))) : []);
        items = items.concat(Array.isArray(this.state.ads) ? JSON.parse(JSON.stringify(this.state.ads.filter((ad) => {
            return this.state.source.data_sources && this.state.source.data_sources.ads && this.state.source.data_sources.ads[ad.id]
        }))) : []);

        let collect_values_for_calculate_metric = {};

        items.map((item, index) => {
            item.insights.map((insight, index) => {
                if (
                    this.state.source.channel_breakdowns && this.state.source.channel_breakdowns[item.channel] && this.state.source.channel_breakdowns[item.channel][insight.channel_breakdown] &&
                    ((insight.daterange_start === this.state.source.daterange.start_date && insight.daterange_end === this.state.source.daterange.end_date)
                        ||
                        (!insight.daterange_start && !insight.daterange_end && this.state.source.daterange.value === "lifetime"))) {

                    //IF CALCULATED METRIC ELSE CUSTOM METRIC
                    if (this.state.source.metric.calculated) {
                        for (let key in this.state.source.metric.metrics) {
                            let metric = this.state.source.metric.metrics[key].channels[item.channel];
                            if (metric) {
                                if (!collect_values_for_calculate_metric[key]) {
                                    collect_values_for_calculate_metric[key] = 0;
                                }
                                collect_values_for_calculate_metric[key] = +collect_values_for_calculate_metric[key] + (+insight[metric.name] * +metric.factor);
                            }
                        }
                    } else {
                        let metric = this.state.source.metric.channels[item.channel];
                        if (metric && insight[metric.name]) {
                            total = +total + (+insight[metric.name] * +metric.factor);
                        }
                    }

                }
            });
        });

        //FIND TOTAL BY CALCULATION COLLECTED METRIC DATA
        if (this.state.source.metric.calculated) {
            let calc_string = this.state.source.metric.calculation;
            for (let key in collect_values_for_calculate_metric) {
                if (calc_string.indexOf("<" + key + ">") !== -1) {
                    calc_string = calc_string.replace("<" + key + ">", collect_values_for_calculate_metric[key]);
                }
            }
            console.log(calc_string);
            total = this.calculate(calc_string);

            if(total == NaN){
                total = 0;
            }

        }

        total = parseFloat((total).toFixed(2));

        this.setState({
            data: total,
            header: this.state.source.header,
            loading: false
        }, () => {
            if (this.props.onUpdateItem) {
                this.props.onUpdateItem(this.state.data);
            }
        })
    }

    calculate(input) {

        var f = {
            add: '+',
            sub: '-',
            div: '/',
            mlt: '*',
            mod: '%',
            exp: '^'
        };

        // Create array for Order of Operation and precedence
        f.ooo = [
            [
                [f.mlt],
                [f.div],
                [f.mod],
                [f.exp]
            ],
            [
                [f.add],
                [f.sub]
            ]
        ];

        input = input.replace(/[^0-9%^*\/()\-+.]/g, ''); // clean up unnecessary characters

        var output;
        for (var i = 0, n = f.ooo.length; i < n; i++) {

            // Regular Expression to look for operators between floating numbers or integers
            var re = new RegExp('(\\d+\\.?\\d*)([\\' + f.ooo[i].join('\\') + '])(\\d+\\.?\\d*)');
            re.lastIndex = 0; // take precautions and reset re starting pos

            // Loop while there is still calculation for level of precedence
            while (re.test(input)) {
                output = _calculate(RegExp.$1, RegExp.$2, RegExp.$3);
                if (isNaN(output) || !isFinite(output))
                    return output; // exit early if not a number
                input = input.replace(re, output);
            }
        }

        return output;

        function _calculate(a, op, b) {
            a = a * 1;
            b = b * 1;
            switch (op) {
                case f.add:
                    return a + b;
                    break;
                case f.sub:
                    return a - b;
                    break;
                case f.div:
                    return a / b;
                    break;
                case f.mlt:
                    return a * b;
                    break;
                case f.mod:
                    return a % b;
                    break;
                case f.exp:
                    return Math.pow(a, b);
                    break;
                default:
                    return null;
            }
        }
    }

    template() {
        try {
            return Object.keys(this.state.source.data_sources.campaigns).length < 1 && Object.keys(this.state.source.data_sources.adgroups).length < 1 && Object.keys(this.state.source.data_sources.ads).length < 1
        } catch (error) {
            return false;
        }
    }

    render() {
        return (
            <div className="flex absolute w-full h-full flex-col justify-center items-center text-center">
                {
                    !this.state.loading && !this.template() &&
                    <div className="flex flex-col justify-center items-center text-center">
                        <div className="text-4xl font-bold text-black">{this.state.data}</div>
                        <div>{this.state.header !== "" ? this.state.header : "..."}</div>
                    </div>
                }
                {
                    this.state.loading &&
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                {
                    this.template() && !this.state.loading &&
                    <div className="w-full h-full flex flex-col justify-center items-center relative">
                        <button style={{ top: "22px", left: "22px" }}
                            className="transition-all duration-200 absolute px-2 font-bold text-xs leading-none flex items-center justify-center mr-1 h-8 bg-purple-100 text-purple-500 rounded">
                            <FeatherIcon className={"stroke-current"} size={15} icon={"tag"} />
                            <span className="ml-2">Number</span>
                        </button>
                        <div onClick={() => {
                            this.props.onEditItem();
                        }} className={"cursor-pointer transition-all duration-200  leading-none flex items-center text-xs font-bold justify-center px-4 h-8 bg-purple-500 text-white rounded"}>
                            <div className="ml-2">Select datasource</div>
                        </div>
                    </div>
                }
            </div>
        )
    }

};
