import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, PaperClipIcon, PhotographIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';

class OrderDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            order: {},
            tab: { id: 1, name: 'Uploaded documents', value: 'uploaded', icon: PhotographIcon },
            documents: [],
            extraInformation: {},
            extraInformationXml: "",
            client: {},
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        };
    }

    componentWillMount() {

    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getDocuments();
        this.props.updateStatistics();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getDocuments: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.documents();
                    await this.promisedSetState({
                        order: response.data,
                        documents: response.data.documents,
                        extraInformation: response.data.extraInformation,
                        extraInformationXml: response.data.extraInformationXml,
                        meta: response.meta,
                        client: response.client
                    });
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i] && files[i].name && files[i].size) {
                        this.functions.uploadDocument(files[i]);
                    }
                }
            }
            const randomString = Math.random().toString(36);
            this.promisedSetState({
                theInputKeyOne: randomString + 1
            });
        },
        uploadDocument: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name
            };
            this.state.order.documents.push(files_object);
            await this.promisedSetState({ order: this.state.order });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.uploadDocument(formData);
                this.state.order.documents = this.state.order.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                this.state.order.documents = this.state.order.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            }
        },
        removeDocument: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.order.documents.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ order: this.state.order });
            try {
                await this.calls.removeDocument(temp.id);
                this.state.order.documents = this.state.order.documents.filter((item) => { return item.id !== temp.id })
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                this.state.order.documents.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ order: this.state.order });
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        documents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderDocuments?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        uploadDocument: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadFile?order=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        removeDocument: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeDocument?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={() => {

                        }}
                        hideUserDropdown={true}
                        breadcrumb={"Documents"}
                        history={this.props.history}
                        showClient={true}
                        lockClient={false}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeDocument();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading documents ...</div>
                    </div>
                }

                {/*SECTION INFORMATION*/}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {/* DOCUMENTS */}
                        {
                            <div className="flex flex-row">
                                {
                                    [
                                        { id: 1, name: 'Uploaded documents', value: 'uploaded', icon: PhotographIcon },
                                        { id: 2, name: 'CRM data', value: 'crm', icon: PhotographIcon },
                                    ].map((tab, index) => {
                                        return (
                                            <Fragment>
                                                <div onClick={() => {
                                                    this.promisedSetState({
                                                        tab: tab
                                                    })
                                                }}
                                                    style={tab.id === this.state.tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                    className={(tab.id === this.state.tab.id ? "bg-white" : "text-gray-300 hover:text-gray-700 bg-gray-50") + " shadow py-3 max-w-1/5 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                    <tab.icon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                    <div className="flex flex-1 truncate">
                                                        <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                            {tab.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-2"></div>
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            <div className=" bg-white flex flex-1 rounded-b-lg shadow flex-col">
                                <div className="p-6 h-full">
                                    {
                                        this.state.tab.value == 'crm' &&
                                        <div className="flex h-full px-6 pt-5 pb-6 border-2 border-gray-300 rounded-md relative bg-custom-input">
                                            <div>
                                                <pre>
                                                    {JSON.stringify(this.state.extraInformation, null, 2)}
                                                </pre>
                                                {
                                                    this.state.extraInformationXml &&
                                                    <div lang="xml" className="mt-5">
                                                        {this.state.extraInformationXml}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.tab.value == 'uploaded' &&
                                        <div className="">
                                            <div className="w-full">
                                                <div className="grid-cols-12 gap-4 grid">

                                                    <div className={"col-span-12"}>
                                                        <div key={this.state.theInputKeyOne || ''} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                            <input
                                                                onChange={(e) => this.functions.onSelectFile(e)}
                                                                type="file"
                                                                multiple
                                                                className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-30"
                                                            />
                                                            <div className="space-y-1 text-center">
                                                                <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    viewBox="0 0 48 48"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">PDF, TXT, ODT, DOC, PPT up to 100MB</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.tab.value == 'uploaded' &&
                                        Array.isArray(this.state.order.documents) &&
                                        this.state.order.documents.length > 0 &&
                                        <div className={"col-span-12 mt-4"}>
                                            {
                                                this.state.order.documents.map((item, index) => {
                                                    let last = (this.state.order.documents.length - 1) === index;
                                                    return (
                                                        <div className={(last ? "" : "border-b") + " flex flex-1 flex-row py-4"}>
                                                            <div className="relative">
                                                                <PaperClipIcon className="w-5 h-5 text-gray-400" />
                                                                {
                                                                    item.loading &&
                                                                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full bg-white bg-opacity-50 absolute top-0">
                                                                        <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="flex flex-1 text-sm ml-4 truncate overflow-hidden font-medium flex-row">
                                                                {item.name}
                                                                {
                                                                    item.error &&
                                                                    <div className="text-red-400 ml-4">
                                                                        Error, something went wrong
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div onClick={() => {
                                                                window.open(item.url);
                                                            }} className="text-sm text-gray-800 hover:text-purple-500 font-medium cursor-pointer mr-4">Download</div>
                                                            <div onClick={() => {
                                                                this.setState({
                                                                    remove: item
                                                                })
                                                            }} className="text-sm text-red-400 hover:text-red-600 font-medium cursor-pointer">Remove</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        }

                    </div>
                }

            </div>
        )
    }
}

export default OrderDocuments;