import React, { Component } from 'react';
import classNames from 'classnames';
import { ArrowRightIcon, LockClosedIcon, XIcon } from '@heroicons/react/outline';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { draftRegister } from '../services/draftRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import InputTailwind from '../moduleFiles/inputTailwind';
import moment from 'moment';
import { clientRegister } from '../services/clientRegister';

class CreateDraftOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            client: {},
            order: {},
            wizard: null,
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 15,
            page: 1,
            sort: "name",
            direction: "ascending",
            channels: {},
            campaigns: [],
            adgroups: [],
            creatives: [],
            assetItems: [],
            ads: [],
            filter_client: {}
        };
    }

    async componentWillMount() {
    }

    async componentDidMount() {
        this.functions.query();
        this.functions.client();
        this.functions.getClients(true);
    }

    form = {
        set: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("campaigns", self.state.campaigns);
                draftRegister.set("adgroups", self.state.adgroups);
                draftRegister.set("creatives", self.state.creatives);
                draftRegister.set("assetItems", self.state.assetItems);
                draftRegister.set("ads", self.state.ads);
                draftRegister.set("channels", self.state.channels);
                draftRegister.set("wizard", self.state.wizard);
                draftRegister.set("client", self.state.client);
                draftRegister.set("order", self.state.order);
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : self.state.creatives,
                        assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : self.state.assetItems,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : self.state.campaigns,
                        adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : self.state.adgroups,
                        ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : self.state.ads,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels
                    })
                }
                resolve();
            })
        }
    };

    functions = {
        query: async () => {
            if ((window.location.search.match(new RegExp('[?&]' + "template" + '=([^&]+)')) || [null])[1]) {
                if (draftRegister.get() && draftRegister.get()["wizard"]) {
                    await this.form.get();
                    await this.form.set();
                    await this.promisedSetState({ loading: false });
                    this.props.setSteps();
                } else {
                    let id = (window.location.search.match(new RegExp('[?&]' + "template" + '=([^&]+)')) || [null])[1];
                    this.functions.getWizard(id);
                }
            } else {
                await this.form.get();
                await this.form.set();
                await this.promisedSetState({ loading: false });
                this.props.setSteps();
            }
        },
        getClients: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getClients();
                await this.promisedSetState({ clients: response.data, meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        client: async () => {
            await this.promisedSetState({
                filter_client: clientRegister.get()
            });
        },
        getWizard: async (id) => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getWizard(id);
                await this.promisedSetState({
                    wizard: response.data.wizard ? response.data.wizard : this.state.wizard,
                    //client: response.data.client ? response.data.client : this.state.client,
                    //order: response.data.order ? response.data.order : this.state.order,
                    assetItems: response.data.assetItems ? response.data.assetItems : this.state.assetItems,
                    creatives: response.data.creatives ? response.data.creatives : this.state.creatives,
                    campaigns: response.data.campaigns ? response.data.campaigns : this.state.campaigns,
                    adgroups: response.data.adgroups ? response.data.adgroups : this.state.adgroups,
                    ads: response.data.ads ? response.data.ads : this.state.ads,
                    channels: response.data.channels ? response.data.channels : this.state.channels
                });
            } catch (error) {
                console.log(error);
            }
            await this.form.set();
            await this.promisedSetState({ loading: false });
            this.props.setSteps();
        },
    };

    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.filter_client && this.state.filter_client.id !== 0 ? "&client=" + this.state.filter_client.id : "");
            return apiRegister.call(options, url);
        },
        getWizard: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizard?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative h-full">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                if (this.state.order && this.state.order.id) {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                } else {
                                    this.props.history.push("/v2/drafts");
                                }
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getClients();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onSearch={async (value) => {
                            /*
                            await this.promisedSetState({
                                search: value
                            })
                            this.functions.getClients();
                            */
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={false}
                        hideUserDropdown={true}
                        breadcrumb={"Clients"}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", loading: false, show: !this.state.loading, red: true }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                filter_client: client
                            });
                            this.functions.getClients();
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading clients ...</div>
                    </div>
                }

                {/*SECTION: SELECT CLIENT*/}
                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="shadow bg-white rounded-lg flex flex-col relative">

                            {/*LOADER PARTIAL*/}
                            {
                                this.state.loading_partial &&
                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }

                            {
                                false &&
                                <div className="p-6 pb-0">
                                    <InputTailwind
                                        label={""}
                                        placeholder={"Search clients ..."}
                                        value={this.state.search}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                status: value
                                            });
                                        }}
                                    />
                                </div>
                            }

                            <div className="flex flex-1 p-6">
                                <div className="w-full">
                                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-5">
                                        {this.state.clients.map((client, index) => (
                                            <div
                                                onClick={async () => {
                                                    if (!(this.state.wizard && this.state.wizard.id && !this.state.wizard.template)) {
                                                        await this.promisedSetState({
                                                            client: client
                                                        });
                                                        await this.form.set();
                                                        this.props.history.push("/v2/campaigns/new/order");
                                                        this.props.setSteps();
                                                    } else if (this.state.client.id === client.id) {
                                                        this.props.history.push("/v2/campaigns/new/order");
                                                    }
                                                }}
                                                key={index}
                                                className={(this.state.client.id === client.id ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " relative cursor-pointer rounded-lg border-1.5 p-6 shadow-sm flex-col flex items-center"}
                                            >
                                                {
                                                    this.state.client.id === client.id && this.state.wizard && this.state.wizard.id && !this.state.wizard.template &&
                                                    <div style={{ right: "10px", top: "10px" }} className="absolute h-10 w-10 rounded-full bg-white flex items-center justify-center">
                                                        <LockClosedIcon className="w-5 h-5 text-gray-900" />
                                                    </div>
                                                }
                                                <div className="h-16 w-16 rounded-full overflow-hidden flex items-center justify-center border bg-white p-2 mb-4">
                                                    <div>
                                                        <img className="w-full" src={client.logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="text-sm font-medium text-gray-900 text-center">
                                                    {client.name}
                                                </div>
                                                <div className="text-sm font-medium text-gray-500 text-center">
                                                    {client.company}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-4 flex flex-row">
                            <div className="flex flex-1"></div>
                            <div className="flex justify-center items-center">
                                <div className="">
                                    <button
                                        onClick={async () => {
                                            if (this.state.client.id) {
                                                this.props.history.push("/v2/campaigns/new/order");
                                            }
                                        }}
                                        className={(this.state.client.id ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <span>Next: Select order</span>
                                        <ArrowRightIcon className="ml-2 h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default CreateDraftOrder;