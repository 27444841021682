import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import Switch from "react-ios-switch";
import CampaignStatusSocialPreviewFacebookImage from "../campaign-status-social-preview-facebook-image";
import Carousel from 're-carousel'
import {calls} from "./calls";
import ReactTooltip from 'react-tooltip'
var moment = require('moment');

class CampaignStatusLevelsAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: this.props.channel,
            ad: this.props.ad,
            sort: this.props.sort,
            loading: true
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.ad();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channel: nextProps.channel,
            ad: nextProps.ad,
            sort: nextProps.sort
        });
    }

    init = {
        ad: () => {
            calls.getAd(this.state.ad.internal, this.state.ad.id).then((response) => {
                this.state.ad.status = response.data.status;
                this.state.ad.impressions = response.data.impressions?response.data.impressions:0;
                this.state.ad.click = response.data.click?response.data.click:0;
                this.state.ad.cpc = response.data.cpc?response.data.cpc:0;
                this.state.ad.spend = response.data.spend?response.data.spend:0;
                this.state.ad.reach = response.data.reach?response.data.reach:0;
                this.state.ad.ctr = response.data.ctr?response.data.ctr:0;
                this.state.ad.conversions = response.data.conversions?response.data.conversions:0;
                this.state.ad.currency = response.data.currency;
                this.state.ad.level = 'Ad';
                this.state.ad.message = response.data.message?response.data.message:'Something went wrong';
                this.setState({
                    loading: false,
                    ad: this.state.ad
                }, () => {
                    this.props.onChange(this.state.ad);
                })
            }, (error) => {
                this.state.ad.level = 'Ad';
                this.state.ad.status = 'error';
                this.state.ad.message = 'Something went wrong, please try again';
                this.setState({
                    loading: false,
                    ad: this.state.ad
                })
            });
        }
    };

    functions = {};

    renders = {
        creative: (item) => {
            try{
                return this.state.ad.items[0].creative_link;
            }catch (e) {}
        },
        width: (key) => {
            if(key === 'level'){
                return {width: '90px'}
            }
            else if(key === 'name'){
                return {width: '100px'}
            }
            else if(key === 'type'){
                return {width: '75px'}
            }
            else if(key === 'status'){
                return {width: '70px'}
            }
            else if(key === 'impressions'){
                return {width: '90px'}
            }
            else if(key === 'click'){
                return {width: '70px'}
            }
            else if(key === 'ctr'){
                return {width: '85px'}
            }
            else if(key === 'cpc'){
                return {width: '90px'}
            }
            else if(key === 'spend'){
                return {width: '100px'}
            }
            else if(key === 'conversions'){
                return {width: '90px'}
            }
            else if(key === 'message'){
                return {width: '540px'}
            }
        },
        ending: (key) => {
            if(key === 'ctr'){
                return "%"
            }else if(key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion'){
                return this.state.ad.currency
            }else{
                return  "";
            }
        },
        keys: () => {
            if(this.state.ad.status === 'error'){
                return ['level', 'name', 'type', 'status', 'message'];
            }else{
                return ['level', 'name', 'type', 'status', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
            }
        },
        status: (key, value) => {
            try{
                if(key === 'status'){
                    if(value.toLowerCase() === 'enabled' || value.toLowerCase() === 'active'){
                        return 'ACTIVE';
                    }else if(value.toLowerCase() === 'error'){
                        return 'ERROR';
                    }else{
                        return value
                    }
                }else{
                    return value
                }
            }catch (e) {
                return value
            }
        }
    };

    render(){
        let frames = [];
        if(this.state.ad.type === 'carousel'){
            frames = this.state.ad.items.filter((item) => {
                return item.type === 'video' || item.type === 'image'
            }).map((item, index) => {
                if(item.type === 'image'){
                    return(
                        <img key={index} src={item.creative_link} style={{width: '100%', height: '100%'}}/>
                    )
                }else if(item.type === 'video'){
                    return(
                        <video key={index} controls={false} autoPlay={true} loop={true} style={{width: '100%', height: '100%'}}>
                            <source src={item.creative_link} type="video/mp4"/>
                        </video>
                    )
                }
            });
        }
        return(
            <div style={{width: '100%'}}>
                <div className="Campaign-Ad-Item">
                    <div className="Campaign-Ad-Item-Preview">
                        {
                            this.state.ad.type === 'image' &&
                            <img src={this.renders.creative()} style={{width: '100%'}}/>
                        }
                        {
                            this.state.ad.type === 'video' &&
                            <video controls={false} loop={true} autoPlay={true} muted={true} style={{width: '100%', height: '100%'}}>
                                <source src={this.renders.creative()} type="video/mp4"/>
                            </video>
                        }
                        {
                            this.state.ad.type === 'carousel' &&
                            <Carousel interval={5000} loop auto frames={frames}/>
                        }
                    </div>
                    <div className={cx("Campaign-Ad-Item-Values",
                        {
                            ["Campaign-Ad-Item-Values-Error"]: this.state.ad.status === 'error'
                        })
                    }>
                        {
                            this.state.loading &&
                            <div className="Campaign-Ad-Item-Values-Loading">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            this.renders.keys().map((key) => {
                                return(
                                    <div style={this.renders.width(key)} className={cx("Campaign-Ad-Item-Section",
                                            {
                                                ["Campaign-Ad-Item-Section-Selected"]: this.state.sort.value === key,
                                            })
                                    }>
                                        <div className="Campaign-Ad-Item-Section-Title">
                                            {key}
                                        </div>
                                        <div data-tip-disable={key !== 'name' && key !== 'message'} data-tip={(this.state.ad[key] + ' ' + this.renders.ending(key))} className="Campaign-Ad-Item-Section-Value">
                                            {this.renders.status(key, this.state.ad[key])}{" "}{this.renders.ending(key)}
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    /*
    render() {
        let frames = [];
        if(this.state.ad.type === 'carousel'){
            frames = this.state.ad.items.filter((item) => {
                return item.type === 'video' || item.type === 'image'
            }).map((item, index) => {
                if(item.type === 'image'){
                    return(
                        <img key={index} src={item.creative_link} style={{width: '100%', height: '100%'}}/>
                    )
                }else if(item.type === 'video'){
                    return(
                        <video key={index} controls={false} autoPlay={true} loop={true} style={{width: '100%', height: '100%'}}>
                            <source src={item.creative_link} type="video/mp4"/>
                        </video>
                    )
                }
            });
        }
        return (
            <div className="CampaignStatusSocial-ContainerList">
                <div className="CampaignStatusSocial-ContainerListChannel">
                    <div style={{width: '100%'}}>
                        <div className="CampaignStatusSocial-ContainerListItem">
                            <div className="CampaignStatusSocial-ContainerListItemPreviewContainer"/>
                            <div className="CampaignStatusSocial-ContainerListItemPreview">
                                {
                                    this.state.ad.type === 'image' &&
                                    <img src={this.renders.creative()} style={{width: '100%'}}/>
                                }
                                {
                                    this.state.ad.type === 'video' &&
                                    <video controls={false} loop={true} autoPlay={true} muted={true} style={{width: '100%', height: '100%'}}>
                                        <source src={this.renders.creative()} type="video/mp4"/>
                                    </video>
                                }
                                {
                                    this.state.ad.type === 'carousel' &&
                                    <Carousel interval={5000} loop auto frames={frames}/>
                                }
                            </div>
                            <div className="CampaignStatusSocial-ContainerListItemValues CampaignStatusSocial-ContainerListItemValuesPadding">
                                {
                                    this.state.loading &&
                                    <div className="CampaignStatusSocial-ContainerListItemValuesLoader">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#333333'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.loading && this.state.error &&
                                    <div className="CampaignStatusSocial-ContainerListItemValuesLoader">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesLoaderError">
                                            {this.state.message}
                                        </div>
                                    </div>
                                }
                                <div className="CampaignStatusSocial-ContainerListItemValuesInner">
                                    {
                                        false &&
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemType">
                                            <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                                Level
                                            </div>
                                            <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                                Ad
                                            </div>
                                        </div>
                                    }
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemNameAd">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Name
                                        </div>
                                        <div data-tip={this.state.ad.name} className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.name}
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemLevel">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Type
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.type}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemStatus">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Status
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.status}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemImp">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Imp
                                        </div>
                                        <div style={this.state.sort.value === 'impressions' ? {fontWeight: '700'} : {}} className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.impressions?this.state.ad.impressions:'0'}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemClick">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Click
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.click?this.state.ad.click:'0'}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemCtr">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            CTR
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.ctr?this.state.ad.ctr:'0'}%
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemCpc">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            CPC
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.cpc?this.state.ad.cpc:'0'}{" "}{this.state.ad.currency}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemReach">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Reach
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.reach?this.state.ad.reach:'0'}
                                        </div>
                                    </div>
                                    <div className="CampaignStatusSocial-ContainerListItemValuesInnerItem CampaignStatusSocial-ContainerListItemValuesInnerItemSpend">
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemTitle">
                                            Spend
                                        </div>
                                        <div className="CampaignStatusSocial-ContainerListItemValuesInnerItemValue">
                                            {this.state.ad.spend?this.state.ad.spend:'0'}{" "}{this.state.ad.currency}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
     */
}

export default CampaignStatusLevelsAd;
