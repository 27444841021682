import React, { Component, Fragment } from 'react';
import { tokenRegister } from "../../services/tokenRegister";
import { userRegister } from "../../services/userRegister";
import { withRouter } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { Dialog, Transition, Menu } from '@headlessui/react';
import { HomeIcon, UsersIcon, ChevronDownIcon, CalendarIcon, InboxIcon, ChartBarIcon, XIcon, MenuAlt2Icon, SearchIcon, BellIcon, OfficeBuildingIcon, CurrencyDollarIcon, AdjustmentsIcon, ChartPieIcon, VariableIcon, PlayIcon, PauseIcon, ExclamationIcon, CloudIcon, HandIcon, ClockIcon, LightBulbIcon, FolderIcon, UserIcon } from '@heroicons/react/outline';
import { apiRegister } from '../../services/apiRegister';
import { clientRegister } from '../../services/clientRegister';

class Topnav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewName: '',
            currLoc: ''
        }
    };
    componentDidMount() {
        this.functions.createViewName();
    }

    componentDidUpdate(previousProps, previousState) {
        if (window.location.pathname != previousState.currLoc) {
            this.functions.createViewName();
        }
    }

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        createViewName: () => {
            this.setState({
                currLoc: window.location.pathname
            });

            let currView = [];
            let startViewName;
            if (window.location.pathname.includes('/user/')) {
                startViewName = window.location.pathname.replace('/user/', '');
            } else {
                startViewName = window.location.pathname;
            }
            if (startViewName.includes('/all')) {
                startViewName = startViewName.replace('/all', '');
            }
            let viewNameArr = startViewName.split('/');
            //currView = viewNameArr.reduce((arr, viewNameArr) => [...arr, viewNameArr, '›'], []);
            currView = viewNameArr.join(' › ');
            this.setState({
                viewName: currView
            });
        }
    };

    renders = {
        firstLetter: () => {
            try {
                return userRegister.get().name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        user: () => {
            try {
                if (userRegister.get()) {
                    return userRegister.get();
                } else {
                    return {}
                }
            } catch (e) {
                return {}
            }
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        }
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <header className={(window.location.href.indexOf("user/order/new") !== -1 ? "left-0 pl-4" : "md:w-10/12 lg:w-10/12") + " border-b-1.5 border-estatery-400 hidden md:flex px-6 py-2 fixed top-0 w-full bg-white right-0 items-center justify-between z-50"}>
                <div className={(window.location.href.indexOf("user/order/new") !== -1 ? "flex-1" : "") + " flex flex-row items-center justify-center"}>
                    {
                        window.location.href.indexOf("user/order/new") !== -1 &&
                        userRegister.get().logo &&
                        <img
                            className="h-12 w-auto mr-5"
                            src={userRegister.get().logo}
                            alt="Workflow"
                        />
                    }
                    {
                        this.state.viewName === "dashboard" &&
                        <div className="mr-3">
                            <HomeIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "optimize" &&
                        <div className="mr-3">
                            <AdjustmentsIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "reports" &&
                        <div className="mr-3">
                            <ChartPieIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "clients" &&
                        <div className="mr-3">
                            <UsersIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "metrics" &&
                        <div className="mr-3">
                            <VariableIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns" &&
                        <div className="mr-3">
                            <FolderIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › active" &&
                        <div className="mr-3">
                            <PlayIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › upcoming" &&
                        <div className="mr-3">
                            <ClockIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › pending" &&
                        <div className="mr-3">
                            <LightBulbIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › paused" &&
                        <div className="mr-3">
                            <PauseIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › error" &&
                        <div className="mr-3">
                            <ExclamationIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › drafted" &&
                        <div className="mr-3">
                            <CloudIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "campaigns › ended" &&
                        <div className="mr-3">
                            <HandIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        this.state.viewName === "profile › account" &&
                        <div className="mr-3">
                            <UserIcon
                                className={"text-purple-400 h-6 w-6"}
                                aria-hidden="true"
                            />
                        </div>
                    }
                    {
                        window.location.href.indexOf("user/order/new") == -1 &&
                        <h2 style={{ paddingTop: "1px", letterSpacing: "0.5px" }} className={(window.location.href.indexOf("user/order/new") !== -1 ? "flex-1 flex justify-center" : "") + " font-lg font-bold text-purple-900 uppercase"}>
                            {this.state.viewName}
                        </h2>
                    }
                    {
                        window.location.href.indexOf("user/order/new") !== -1 &&
                        <h2 style={{ paddingTop: "1px", letterSpacing: "0.5px" }} className={"flex-1 flex justify-center font-lg -mr-8 font-bold text-purple-900 uppercase"}>

                        </h2>
                    }
                </div>
                <div className="flex flex-row items-center">
                    <div className="ml-4 flex items-center md:ml-6">
                        <button
                            type="button"
                            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                        >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                            <div>
                                <Menu.Button className="max-w-xs bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-2 rounded-md hover:bg-gray-100 hover:bg-opacity-50">
                                    {
                                        this.renders.user().picture &&
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={this.renders.user().picture}
                                            alt=""
                                        />
                                    }
                                    {
                                        !this.renders.user().picture &&
                                        <div className="h-8 w-8 rounded-full flex justify-center items-center border-1.5 bg-purple-100 text-sm uppercase font-semibold text-purple-500">
                                            {this.renders.firstLetter()}
                                        </div>
                                    }
                                    <span className="ml-3 text-gray-700 text-sm font-semibold block">
                                        <span className="sr-only">Open user menu for </span>{this.renders.user().name}
                                    </span>
                                    <ChevronDownIcon
                                        className="flex-shrink-0 ml-2 h-5 w-5 text-gray-400 block"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="origin-top-right absolute border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                    <div className="px-4 py-3">
                                        <p className="text-sm">Signed in as</p>
                                        <p className="text-sm font-medium text-gray-900 truncate">{this.renders.user().email}</p>
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push('/user/profile/account');
                                                    }}
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    My profile
                                                </a>
                                            )}
                                        </Menu.Item>
                                        {
                                            this.renders.user().type === 'agent' &&
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push('/user/users/all');
                                                        }}
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Settings
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        }
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => {
                                                        this.functions.logout();
                                                    }}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block w-full text-left px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    Sign out
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {
                        false &&
                        <div className="photo w-12 h-12 bg-gray-100 relative rounded-full font-bold flex items-center justify-center capitalize">
                            {this.renders.firstLetter()}
                            <input className="Dashboard-MenuButtonsUserSettingsInput"
                                onMouseDown={() => {
                                    this.setState({
                                        user_dropdown: !this.state.user_dropdown
                                    })
                                }}
                                onFocus={() => {
                                    this.setState({
                                        user_dropdown: true
                                    })
                                }}
                                onBlur={() => {
                                    this.setState({
                                        user_dropdown: false
                                    })
                                }}
                            />
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsTriangle"></div>
                            }
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsTriangleWhite"></div>
                            }
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsDropdown">
                                    <div onMouseDown={() => {
                                        this.props.history.push('/user/profile/account');
                                    }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                        Profile
                                    </div>
                                    {userRegister.get().type === 'agent' &&
                                        <div>
                                            <a href="#" onMouseDown={(e) => { e.preventDefault(); this.props.history.push('/user/users/all') }} className="text-sm py-2 px-4 block menu__block--wrapper--item cursor-pointer">
                                                Users
                                            </a>
                                            {
                                                false &&
                                                <a href="#" onMouseDown={(e) => { e.preventDefault(); this.props.history.push('/user/profile/billing') }} className="text-sm font-bold py-2 px-4 block menu__block--wrapper--item cursor-pointer">
                                                    Billing
                                                </a>
                                            }
                                        </div>
                                    }
                                    <div onMouseDown={() => {
                                        this.functions.logout()
                                    }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                        Logout
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </header>
        )
    }
}

export default Topnav;


