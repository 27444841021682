class adgroupGoogle {

    static validate(adgroup, ads = false, campaign = false) {
        let error = {
            name: false,
            ads: false,
        };
        if (!adgroup.resourceName) {
            if (ads) {
                if (campaign && campaign.filter((item) => item.id === adgroup.campaign).length > 0 && campaign.filter((item) => item.id === adgroup.campaign)[0].campaign_type_property === "search") {
                    if (ads.filter((item) => item.adgroup == adgroup.id && item.type === "keywords").length > 0 && ads.filter((item) => item.adgroup == adgroup.id && item.type === "search_ad").length > 0) {
                    } else {
                        error.ads = true;
                    }
                } else {
                    if (ads.filter((item) => item.adgroup == adgroup.id).length > 0) {
                    } else {
                        error.ads = true;
                    }
                }
            }
        }
        if (adgroup.name === "") error.name = true;
        return error;
    }
}
export { adgroupGoogle }