import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import AdvertisingPreviewSearch from "../advertising-preview-search";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingPreviewSocial from "../advertising-preview-social";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { tokenRegister } from "../../services/tokenRegister";
import { apiRegister } from "../../services/apiRegister";
import Loader from "../loader";
import TextArea from "../text-area";
import { userRegister } from "../../services/userRegister";
import SweetAlert from "sweetalert-react";
import { BeatLoader } from "react-spinners";
import AdvertisingPreviewSearchAll from "../advertising-preview-search-all";
import { calls } from "./calls";
import IconLinkedin from "../icons/linkedin";
import IconGoogleShopping from "../icons/google-shopping";
import AdvertisingPreviewShoppingAll from "../advertising-preview-shopping-all";

const queryString = require('query-string');
var moment = require('moment');

class AdvertisingPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            site: {},
            targeting: {},
            keywords: {},
            creatives: {},
            channels: {},
            client: {},
            selected: {},
            selected_keyword: {},
            selected_logo: { name: "Show Agency logo in preview link", value: 'agency_logo' },
            title: "",
            text: "",
            channel: null,
            languages: [],
            language: {},
            type: {},
            campaign: {},
            search: {},
            locations: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open
        })
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.first();
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                open: this.props.open,
                targeting: information ? (information.targeting ? (information.targeting.value ? information.targeting.value : {}) : {}) : {},
                keywords: information ? (information.keywords ? (information.keywords.value ? information.keywords.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
                shopping: information ? (information.shopping ? (information.shopping.value ? information.shopping.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
            }, () => {
                if (!this.state.channel) {
                    let keys = Object.keys(this.state.budget).filter((key) => {
                        return this.state.channels[key]
                    });
                    console.log(keys);
                    if (keys.length > 0) {
                        if (keys[0] === "facebook_instagram") {
                            keys[0] = "facebook";
                        }
                        this.setState({
                            channel: keys[0]
                        }, () => {
                            this.functions.channel(this.state.channel);
                        })
                    }
                } else {
                    this.functions.channel(this.state.channel);
                }
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                targeting: information ? (information.targeting ? (information.targeting.value ? information.targeting.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
                shopping: information ? (information.shopping ? (information.shopping.value ? information.shopping.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                keywords: information ? (information.keywords ? (information.keywords.value ? information.keywords.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : []
            }, () => {
                //this.functions.channel(this.state.channel);
                if (!this.state.channel) {
                    let keys = Object.keys(this.state.budget).filter((key) => {
                        return this.state.channels[key]
                    });
                    if (keys.length > 0) {
                        if (keys[0] === "facebook_instagram") {
                            keys[0] = "facebook";
                        }
                        this.setState({
                            channel: keys[0]
                        }, () => {
                            this.functions.channel(this.state.channel);
                        })
                    }
                } else {
                    this.functions.channel(this.state.channel);
                }
            })
        }
    };

    functions = {
        channel: (key) => {
            this.setState({
                channel: key
            }, () => {
                if (this.state.channel === "facebook" || this.state.channel === "instagram" || this.state.channel === "linkedin") {
                    if (this.renders.campaigns("social").length > 0) {
                        this.setState({
                            campaign: this.renders.campaigns("social")[0]
                        }, () => {
                            if (this.renders.previews().length > 0) {
                                if (this.renders.previews().filter((preview) => {
                                    return preview.value === this.state.type.value
                                }).length < 1) {
                                    this.setState({
                                        type: this.renders.previews()[0]
                                    })
                                }
                            }
                        })
                    }
                } else if (this.state.channel === "google") {
                    if (this.renders.campaigns("search").length > 0) {
                        this.setState({
                            campaign: this.renders.campaigns("search")[0]
                        }, () => {
                            this.setState({
                                type: { id: 0, name: "Search ads" }
                            })
                        })
                    }
                } else {
                    if (this.renders.campaigns("shopping").length > 0) {
                        this.setState({
                            campaign: this.renders.campaigns("shopping")[0]
                        }, () => {
                            this.setState({
                                type: { id: 0, name: "Shopping ads" }
                            })
                        })
                    }
                }
            })
        },
        preview: () => {
            if (!this.state.loading_link) {

                let channels = JSON.parse(JSON.stringify(this.state.channels));
                if (!this.renders.instagram()) {
                    delete channels.instagram;
                }

                let shopping = {};
                if (this.state.shopping) {
                    shopping = JSON.parse(JSON.stringify(this.state.shopping));
                    if (Array.isArray(shopping.products)) {
                        let products = [];
                        shopping.products.filter((item, index) => {
                            return index < 20
                        }).map((item, index) => {
                            products.push({
                                imageLink: item.imageLink,
                                title: item.title,
                                price: item.price
                            });
                        });
                        shopping.products = products;
                    }
                }

                let object = {
                    data: {
                        channels: channels,
                        social_campaigns: this.renders.campaigns("social"),
                        search_campaigns: this.renders.campaigns("search"),
                        shopping_campaigns: this.renders.campaigns("shopping"),
                        search: this.state.search,
                        shopping: shopping,
                        creatives: this.state.creatives,
                        locations: this.state.locations,
                        shoppingName: this.state.client.google_shopping ? this.state.client.google_shopping.accountName : ""
                    },
                    title: this.state.title,
                    text: this.state.text,
                    logo: this.state.selected_logo.value,
                    client: this.state.client.id
                };

                //REMOVE KEYWORD IDEAS
                try {
                    object.data.search_campaigns.map((item) => {
                        delete item.ideas;
                    });
                } catch (e) {
                }

                this.setState({
                    loading_link: true
                }, () => {
                    calls.createPreview(object).then((response) => {
                        this.setState({
                            loading_link: false,
                        }, () => {
                            if (window.location.hostname === 'localhost') {
                                window.open("http://localhost:5001/preview/" + response.data.id);
                            } else if (window.location.hostname === 'app.adcredo.io') {
                                window.open("https://app.adcredo.io/preview/" + response.data.id);
                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                window.open("https://dev.adcredo.io/preview/" + response.data.id);
                            }
                        })
                    }, (error) => {
                        this.setState({
                            loading_link: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    });
                })
            }
        }
    };

    renders = {
        tabs: () => {
            let keys = [];
            console.log(this.state.channels);
            for (let key in this.state.channels) {
                if (this.state.channels[key]) {
                    if (key === "facebook_instagram" && this.renders.instagram()) {
                        keys.push("instagram");
                    } else {
                        keys.push(key);
                    }
                }
            }
            let uniqueKeys = keys.filter(function(item, pos) {
                return keys.indexOf(item) == pos;
            })
            return uniqueKeys;
        },
        previews: () => {
            try {
                let options = [];
                if (Array.isArray(this.state.creatives.video_ads) && this.state.creatives.video_ads && this.state.creatives.video_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id
                }).length > 0) {
                    options.push({ name: 'Video', value: 'video', type: 'video' });
                }
                if (Array.isArray(this.state.creatives.image_ads) && this.state.creatives.image_ads && this.state.creatives.image_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id
                }).length > 0) {
                    options.push({ name: 'Image', value: 'image', type: 'image' });
                }
                if (Array.isArray(this.state.creatives.carousel_ads) && this.state.creatives.carousel_ads && this.state.creatives.carousel_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id
                }).length > 0) {
                    options.push({ name: 'Carousel', value: 'carousel' });
                }
                if (Array.isArray(this.state.creatives.dynamic_ads) && this.state.creatives.dynamic_ads && this.state.creatives.dynamic_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id
                }).length > 0) {
                    options.push({ name: 'Dynamic', value: 'dynamic' });
                }
                if (Array.isArray(this.state.creatives.event_ads) && this.state.creatives.event_ads && this.state.creatives.event_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id && this.state.channel !== "instagram"
                }).length > 0) {
                    options.push({ name: 'Event', value: 'event' });
                }
                if (Array.isArray(this.state.creatives.post_ads) && this.state.creatives.post_ads && this.state.creatives.post_ads.filter((item) => {
                    return item.show && !item.error && item.internal_adset.campaign_id === this.state.campaign.id
                }).length > 0) {
                    options.push({ name: 'Sponsored post', value: 'post' });
                }
                return options;
            } catch (e) {
                return [];
            }
        },
        campaigns: (option) => {
            let campaigns = [];
            if (option === "social") {
                campaigns = Array.isArray(this.state.creatives.campaigns) ? this.state.creatives.campaigns : []
            } else if (option === "search") {
                campaigns = Array.isArray(this.state.search.campaigns) ? this.state.search.campaigns : []
            } else if (option === "shopping") {
                campaigns = Array.isArray(this.state.shopping.campaigns) ? this.state.shopping.campaigns : []
            }
            return campaigns;
        },
        keywords: () => {
            try {
                return this.state.keywords.positive;
            } catch (e) {
                return [];
            }
        },
        instagram: () => {
            try {
                let instagram = false;
                console.log(this.state.creatives.adsets);
                this.state.creatives.adsets.map((adset) => {
                    if (adset.targeting.placement.all || adset.targeting.placement.feed_instagram || adset.targeting.placement.story_instagram || adset.targeting.placement.explore) {
                        instagram = true;
                    }
                });
                return instagram;
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="flex flex-row mb-3">
                    <div
                        className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                        <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                    </div>
                    <div className="text-2xl font-bold">
                        Step 6: Preview
                    </div>
                </div>
                <div className="pb-4 bg-white px-4 py-4 rounded-md">
                    <div>
                        {
                            <SweetAlert
                                show={this.state.error_modal}
                                title="Ups!"
                                type="warning"
                                text={this.state.error_message}
                                confirmButtonText="OK"
                                onConfirm={() => {
                                    this.setState({ error_modal: false });
                                }}
                            />
                        }
                        {
                            <div>
                                <div className="flex flex-row items-center space-x-2">
                                    {
                                        this.renders.tabs().map((key) => {
                                            return (
                                                <div onClick={() => {
                                                    this.functions.channel(key);
                                                }}>
                                                    {key === 'linkedin' &&
                                                        <div
                                                            className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-linkedin-100 text-linkedin-500 border-linkedin-500' : ''}`}>
                                                            <IconLinkedin /> <span className="ml-1">{key}</span>
                                                        </div>
                                                    }
                                                    {key === 'instagram' &&
                                                        <div
                                                            className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-instagram-100 text-instagram-500 border-instagram-500' : ''}`}>
                                                            <IconInstagram /> <span className="ml-1">{key}</span>
                                                        </div>
                                                    }
                                                    {key === 'facebook' &&
                                                        <div
                                                            className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-facebook-100 text-facebook-500 border-facebook-500' : ''}`}>
                                                            <IconFacebook /> <span className="ml-1">{key}</span>
                                                        </div>
                                                    }
                                                    {key === 'google' &&
                                                        <div
                                                            className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-google-100 text-google-500 border-google-500' : ''}`}>
                                                            <IconGoogle /> <span className="ml-1">{key}</span>
                                                        </div>
                                                    }
                                                    {key === 'google_shopping' &&
                                                        <div
                                                            className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-google-100 text-googleshopping-500 border-googleshopping-500' : ''}`}>
                                                            <IconGoogleShopping /> <span className="ml-1">{'Google Shopping'}</span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="flex flex-1"></div>
                                    <div onClick={() => {
                                        this.functions.channel("preview");
                                    }} className={`cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${"preview" === this.state.channel ? 'bg-google-100 text-purple-500 border-purple-500' : ''}`}>
                                        <span className="ml-1">External preview</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.channel !== 'preview' &&
                            <div className="mt-6 mb-4">
                                <h3 className="mb-0 font-bold capitalize">
                                    Preview {this.state.channel !== 'google_shopping' ? this.state.channel : 'Google Shopping'}
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Each tab below represents a campaign
                                </p>
                            </div>
                        }
                        {
                            this.state.channel !== 'preview' &&
                            this.renders.campaigns((this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') ? "social" : (this.state.channel === 'google_shopping' ? 'shopping' : "search")).length > 0 &&
                            <div className="flex flex-1 flex-row mt-4 justify-start ml-4 mr-4">
                                {
                                    this.renders.campaigns((this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') ? "social" : (this.state.channel === 'google_shopping' ? 'shopping' : "search")).map((item, index) => {
                                        return (
                                            <div onClick={() => {
                                                this.setState({
                                                    campaign: item
                                                }, () => {
                                                    if (this.renders.previews().length > 0) {
                                                        if (this.renders.previews().filter((preview) => {
                                                            return preview.value === this.state.type.value
                                                        }).length < 1) {
                                                            this.setState({
                                                                type: this.renders.previews()[0]
                                                            })
                                                        }
                                                    }
                                                })
                                            }}
                                                style={item.id !== this.state.campaign.id ? { backgroundColor: "#fcfcfc" } : {}}
                                                className={cx("relative overflow-hidden max-w-xs bg-gray-100 flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 justify-start align-middle rounded-t-md p-3 cursor-pointer text-sm font-bold", {
                                                    ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.campaign.id,
                                                    ["ml-1"]: index !== 0
                                                })}>
                                                {
                                                    <div className={cx("pl-3 pr-5 truncate")}>
                                                        {item.name ? item.name : "..."}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.channel !== 'preview' &&
                            <div className="rounded-md bg-gray-100 px-4 pb-4">
                                {
                                    this.state.channel === 'google' &&
                                    <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                        <ul className="flex flex-row items-end">
                                            {[{ id: 0, name: "Search ads" }].map((item, j) => {
                                                return (
                                                    <li key={j}
                                                        className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.type.name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                        onClick={() => {
                                                            this.setState({
                                                                activesearchsub: item.id,
                                                                type: item
                                                            });
                                                        }}>
                                                        {item.name}
                                                    </li>
                                                )
                                            })
                                            }
                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                        </ul>
                                    </nav>
                                }
                                {
                                    this.state.channel === 'google_shopping' &&
                                    <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                        <ul className="flex flex-row items-end">
                                            {[{ id: 0, name: "Shopping ads" }].map((item, j) => {
                                                return (
                                                    <li key={j}
                                                        className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.type.name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                        onClick={() => {
                                                            this.setState({
                                                                activesearchsub: item.id,
                                                                type: item
                                                            });
                                                        }}>
                                                        {item.name + ((this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === 'smart') ? " (smart)" : "")}
                                                    </li>
                                                )
                                            })
                                            }
                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                        </ul>
                                    </nav>
                                }
                                {
                                    (this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') &&
                                    <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                        <ul className="flex flex-row items-end">
                                            {this.renders.previews().map((item, j) => {
                                                return (
                                                    <li key={j}
                                                        className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.type.name == item.name ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                        onClick={() => {
                                                            this.setState({
                                                                activesearchsub: item.name,
                                                                type: item
                                                            });
                                                        }}>
                                                        {item.name}
                                                    </li>
                                                )
                                            })
                                            }
                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                        </ul>
                                    </nav>
                                }
                                {
                                    this.state.channel === 'google_shopping' &&
                                    <AdvertisingPreviewShoppingAll
                                        shoppingName={this.state.client.google_shopping ? this.state.client.google_shopping.accountName : ""}
                                        campaign={this.state.campaign}
                                        shopping={this.state.shopping}
                                    />
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <AdvertisingPreviewSearchAll
                                        campaign={this.state.campaign}
                                        search={this.state.search}
                                        creatives={this.state.creatives}
                                        locations={this.state.locations}
                                    />
                                }
                                {
                                    (this.state.channel === 'facebook' || this.state.channel === 'instagram' || this.state.channel === 'linkedin') &&
                                    <AdvertisingPreviewSocial
                                        site={this.state.site}
                                        language={this.state.language}
                                        targeting={this.state.targeting}
                                        selected={this.state.selected.value}
                                        type={typeof this.state.type === "object" ? this.state.type.value : {}}
                                        channel={this.state.channel}
                                        client={this.state.client}
                                        campaign={this.state.campaign}
                                        creatives={this.state.creatives}
                                        preview={this.state.selected.value}
                                        locations={this.state.locations}
                                    />
                                }
                            </div>
                        }
                        {
                            this.state.channel === 'preview' &&
                            <div className="mt-6">
                                <div>
                                    <h3 className="mb-0 font-bold">
                                        Add new template
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                                <div className="rounded-md mt-4">
                                    <div>
                                        <Dropdown
                                            filter={false}
                                            title={'Logo'}
                                            noTitle={false}
                                            size={'full'}
                                            placeholder={"Select option"}
                                            options={[
                                                { name: "Dont show logo in preview link", value: 'no_logo' },
                                                { name: "Show Agency logo in preview link", value: 'agency_logo' },
                                                { name: "Show Client logo in preview link", value: 'client_logo' },
                                            ]}
                                            value={this.state.selected_logo}
                                            onChange={(value) => {
                                                this.setState({
                                                    selected_logo: value
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <Input
                                            maxLength={100}
                                            title={'Preview link title'}
                                            type={'text'}
                                            placeholder={'Title in preview link'}
                                            value={this.state.title}
                                            sign={true}
                                            signTitle={'Title in preview link'}
                                            size={'full'}
                                            onChange={(event) => this.setState({ title: event.target.value })}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <TextArea
                                            maxLength={500}
                                            size={"full"}
                                            title="Preview link text"
                                            placeholder={'Text in preview link'}
                                            value={this.state.text}
                                            sign={true}
                                            signTitle={'Text in preview link'}
                                            onChange={(value) => {
                                                this.setState({
                                                    text: value.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="flex justify-end mt-5">
                                        <button onClick={() => {
                                            this.functions.preview()
                                        }}
                                            className="bg-purple-500 py-2 px-6 rounded-full text-white font-bold text-sm flex justify-center items-center transition-all duration-200 hover:bg-purple-700">
                                            {
                                                !this.state.loading_link &&
                                                <div className="flex justify-center items-center">Create preview
                                                    link <FeatherIcon className={"stroke-current ml-2"} size={16}
                                                        icon={'external-link'} /></div>
                                            }
                                            {
                                                this.state.loading_link &&
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    color={'#ffffff'}
                                                    loading={true}
                                                />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingPreview;
