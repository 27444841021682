import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import {
    XIcon,
    FolderIcon,
    PencilAltIcon,
    DuplicateIcon,
    FolderAddIcon,
    PlusCircleIcon,
    ViewGridIcon,
    CameraIcon,
    VideoCameraIcon,
    CollectionIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    EyeIcon,
    ArrowNarrowRightIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    PlusIcon,
    ArrowCircleRightIcon,
    BellIcon,
    CheckIcon,
    SaveIcon,
    LinkIcon,
    ExternalLinkIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/outline';
import TextAreaTailwind from '../moduleFiles/textAreaTailwind';
import { draftRegister } from '../services/draftRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import SuccessNotification from '../moduleFiles/successNotification';
import PreviewSection from '../moduleFiles/previewSection';
import WizardModal from '../moduleFiles/wizardModal';
import SideNotification from '../moduleFiles/sideNotification';
import SlideDown from 'react-slidedown';


import { adgroupTikTok } from '../validators/adgroupTiktok';
import { campaignTiktok } from '../validators/campaignTiktok';
import { adTiktok } from '../validators/adTiktok';

import { adgroupSnapchat } from '../validators/adgroupSnapchat';
import { campaignSnapchat } from '../validators/campaignSnapchat';
import { adSnapchat } from '../validators/adSnapchat';

import { campaignFacebook } from '../validators/campaignFacebook';
import { adgroupFacebook } from '../validators/adgroupFacebook';
import { adFacebook } from '../validators/adFacebook';

import { adgroupLinkedin } from '../validators/adgroupLinkedin';
import { campaignLinkedin } from '../validators/campaignLinkedin';
import { adLinkedin } from '../validators/adLinkedin';

import { campaignTwitter } from '../validators/campaignTwitter';
import { adgroupTwitter } from '../validators/adgroupTwitter';
import { adTwitter } from '../validators/adTwitter';


import { campaignGoogle } from '../validators/campaignGoogle';
import { adgroupGoogle } from '../validators/adgroupGoogle';
import { searchGoogle } from '../validators/searchGoogle';
import { performanceMax } from '../validators/performanceMax';
import { extensionGoogle } from '../validators/extensionGoogle';

class CreateDraftPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            ads: [],
            creatives: [],
            channels: {},
            saved_success: false,
            wizard: {},
            order: {},
            campaign: {},
            adgroup: {},
            channel: {},
            assetItems: [],
            options: [
                { id: 1, name: "Facebook Ads Manager", value: "facebook", color: "facebook-500" },
                { id: 2, name: "Google Adwords", value: "google", color: "google-500" },
                { id: 3, name: "Linkedin Ads", value: "linkedin", color: "linkedin-500" },
                { id: 4, name: "Twitter Ads", value: "twitter", color: "twitter-500" },
                { id: 5, name: "TikTok Ads", value: "tiktok", color: "black" },
                { id: 5, name: "Snapchat Ads", value: "snapchat", color: "snapchat-500" }
            ],
            create_wizard: false,
            error: false,
            show_ext_prev_settings: false,
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        await this.form.get();
        this.functions.init();
        this.props.setSteps();
        console.log(this.functions.validation(), "VAIDATION");
        Object.keys(this.state.channels).forEach(async item => {
            if (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((inner_item) => { return inner_item.value === item }).length < 1) {
                await this.promisedSetState({
                    error: true
                })
            }
        });
    }

    form = {
        set: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("order", self.state.order);
                draftRegister.set("wizard", self.state.wizard);
                draftRegister.set("channels", self.state.channels);
                draftRegister.set("ads", self.state.ads);
                draftRegister.set("adgroups", self.state.adgroups);
                draftRegister.set("campaigns", self.state.campaigns);
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : self.state.creatives,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : self.state.assetItems,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : self.state.campaigns,
                        adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : self.state.adgroups,
                        ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : self.state.ads,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels
                    })
                }
                resolve();
            })
        }
    };

    functions = {
        validation: () => {
            if (this.state.campaigns.map((campaign) => {
                let campaign_error = false;
                if (!campaign.partial_parced) {
                    if (campaign.channel === "facebook") {
                        if (campaignFacebook.validate(campaign)) {
                            campaign_error = true;
                        }
                    } else if (campaign.channel === "google") {
                        if (Object.keys(campaignGoogle.validate(campaign)).filter((item) => { return campaignGoogle.validate(campaign)[item] }).length > 0) {
                            campaign_error = true;
                        }
                    } else if (campaign.channel === "linkedin") {
                        if (Object.keys(campaignLinkedin.validate(campaign)).filter((item) => { return campaignLinkedin.validate(campaign)[item] }).length > 0) {
                            campaign_error = true;
                        }
                    } else if (campaign.channel === "twitter") {
                        if (campaignTwitter.validate(campaign)) {
                            campaign_error = true;
                        }
                    } else if (campaign.channel === "tiktok") {
                        if (campaignTiktok.validate(campaign)) {
                            campaign_error = true;
                        }
                    } else if (campaign.channel === "snapchat") {
                        if (Object.keys(campaignSnapchat.validate(campaign)).filter((item) => { return campaignSnapchat.validate(campaign)[item] }).length > 0) {
                            campaign_error = true;
                        }
                    }
                }
                if (!campaign_error) {
                    if (this.state.adgroups.filter((adgroup) => {
                        return adgroup.campaign === campaign.id
                    }).length > 0) {
                        if (this.state.adgroups.filter((adgroup) => {
                            return adgroup.campaign === campaign.id
                        }).map((adgroup) => {
                            let adgroup_error = false;
                            if (!adgroup.partial_parced) {
                                if (adgroup.channel === "facebook") {
                                    if (Object.keys(adgroupFacebook.validate(adgroup)).filter((item) => { return adgroupFacebook.validate(adgroup)[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                } else if (adgroup.channel === "google") {
                                    if (Object.keys(adgroupGoogle.validate(adgroup)).filter((item) => { return adgroupGoogle.validate(adgroup)[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                } else if (adgroup.channel === "linkedin") {
                                    if (Object.keys(adgroupLinkedin.validate(adgroup)).filter((item) => { return adgroupLinkedin.validate(adgroup)[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                } else if (adgroup.channel === "twitter") {
                                    if (Object.keys(adgroupTwitter.validate(adgroup)).filter((item) => { return adgroupTwitter.validate(adgroup)[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                } else if (adgroup.channel === "tiktok") {
                                    if (Object.keys(adgroupTikTok.validate(adgroup)).filter((item) => { return adgroupTikTok.validate(adgroup)[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                } else if (adgroup.channel === "snapchat") {
                                    if (Object.keys(adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && !adgroup.partial ? true : false))).filter((item) => { return adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && !adgroup.partial ? true : false))[item] }).length > 0) {
                                        adgroup_error = true;
                                    }
                                }
                            }
                            console.log("adgroup_error", adgroup_error)
                            if (!adgroup_error) {
                                if (this.state.ads.filter((ad) => {
                                    return ad.adgroup === adgroup.id
                                }).length > 0) {
                                    let ad_error = false;
                                    this.state.ads.filter((ad) => {
                                        return ad.adgroup === adgroup.id
                                    }).map((ad) => {
                                        if (ad.channel === "facebook") {
                                            if (Object.keys(adFacebook.validate(ad)).filter((item) => { return adFacebook.validate(ad)[item] }).length > 0) {
                                                ad_error = true;
                                            }
                                        } else if (ad.channel === "google") {
                                            if (ad.type === "search_ad") {
                                                if (Object.keys(searchGoogle.validate(ad)).filter((item) => { return searchGoogle.validate(ad)[item] }).length > 0) {
                                                    ad_error = true;
                                                }
                                            } else if (ad.type === "keywords") {
                                                if (ad.name === "" || ad.keywords.length < 1) {
                                                    ad_error = true;
                                                }
                                            } else if (ad.type === "performance_max") {
                                                if (Object.keys(performanceMax.validate(ad)).filter((item) => { return performanceMax.validate(ad)[item] }).length > 0) {
                                                    ad_error = true;
                                                }
                                            } else if (ad.type === "display") {
                                                if (Object.keys(performanceMax.validate(ad)).filter((item) => { return performanceMax.validate(ad)[item] }).length > 0) {
                                                    ad_error = true;
                                                }
                                            } else if (ad.type === "extension") {
                                                if (Object.keys(extensionGoogle.validate(ad)).filter((item) => { return extensionGoogle.validate(ad)[item] }).length > 0) {
                                                    ad_error = true;
                                                }
                                            }
                                        } else if (ad.channel === "linkedin") {
                                            if (Object.keys(adLinkedin.validate(ad)).filter((item) => { return adLinkedin.validate(ad)[item] }).length > 0) {
                                                ad_error = true;
                                            }
                                        } else if (ad.channel === "twitter") {
                                            if (Object.keys(adTwitter.validate(ad)).filter((item) => { return adTwitter.validate(ad)[item] }).length > 0) {
                                                ad_error = true;
                                            }
                                        } else if (ad.channel === "tiktok") {
                                            if (Object.keys(adTiktok.validate(ad)).filter((item) => { return adTiktok.validate(ad)[item] }).length > 0) {
                                                ad_error = true;
                                            }
                                        } else if (ad.channel === "snapchat") {
                                            if (Object.keys(adSnapchat.validate(ad)).filter((item) => { return adSnapchat.validate(ad)[item] }).length > 0) {
                                                ad_error = true;
                                            }
                                        }
                                    })
                                    console.log("ad_error", ad_error)
                                    if (!ad_error) {
                                        if (adgroup.channel !== "google" || (adgroup.channel === "google" && ["shopping", "performance_max", "display", "video"].includes(campaign.campaign_type_property))) {
                                            return true
                                        } else {
                                            if (adgroup.resourceName) {
                                                return true
                                            } else {
                                                if (this.state.ads.filter((ad) => { return ad.adgroup === adgroup.id && ad.type === "keywords" }).length > 0 && this.state.ads.filter((ad) => { return ad.adgroup === adgroup.id && ad.type === "search_ad" }).length > 0) {
                                                    return true
                                                } else return false
                                            }
                                        }
                                    } else {
                                        return false
                                    }
                                } else {

                                    console.log("no ads", adgroup.name, "===", adgroup.partial, "===WIZARD", this.state.wizard.partial)
                                    if (!this.state.wizard.partial) {
                                        return false;
                                    } else {
                                        if (adgroup.partial) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                }
                            } else {
                                return false
                            }
                        }).filter((item) => { return !item }).length > 0) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }).filter((item) => { return !item }).length > 0) {
                return false
            } else {
                console.log("no error")
                return true
            }
        },
        init: async () => {
            if (Array.isArray(this.state.campaigns) && this.state.campaigns.length > 0) {
                await this.promisedSetState({
                    campaign: this.state.campaigns[0],
                    channel: this.state.options.filter((item) => {
                        return item.value == this.state.campaigns[0].channel
                    })[0]
                });
                if (Array.isArray(this.state.adgroups) && this.state.adgroups.filter((item) => {
                    return item.campaign == this.state.campaign.id
                }).length > 0) {
                    await this.promisedSetState({
                        adgroup: this.state.adgroups.filter((item) => {
                            return item.campaign == this.state.campaign.id
                        })[0]
                    });
                }
            }
        },
        updateWizard: async (preview) => {
            await this.promisedSetState({ loading_wizard: !preview, loading_external_wizard: preview });
            try {
                await this.calls.updateWizard({
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups,
                    ads: this.state.ads,
                    assetItems: this.state.assetItems,
                    creatives: this.state.creatives,
                    channels: this.state.channels,
                    previewEnabled: this.state.wizard.previewEnabled ? this.state.wizard.previewEnabled : {},
                    previewLogo: this.state.wizard.previewLogo ? this.state.wizard.previewLogo : {},
                    previewHeadline: this.state.wizard.previewHeadline,
                    previewDescription: this.state.wizard.previewDescription
                });
                if (!preview) {
                    this.refs.SideNotification.functions.trigger();
                } else {
                    window.open(window.location.protocol + "//" + window.location.host + "/preview/v2/" + this.state.wizard.id, "_blank");
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false, loading_external_wizard: false });
        },
        create: async () => {
            let order = {
                client: draftRegister.get()["client"] ? draftRegister.get()["client"] : {},
                order: draftRegister.get()["order"] ? draftRegister.get()["order"] : {},
                wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : {},
                assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : [],
                channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : {},
                creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : [],
                campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : [],
                adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : [],
                ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : []
            };


            //WIZARD HAS STATUS ERROR: REMOVE ALL CAMPAIGNS, ADGROUPS AND ADS WHICH HAS PROPERTY remove_from_order
            if (order.wizard.status === "error") {
                console.log("wizard status error")
                order.campaigns = order.campaigns.filter((item) => {
                    return !item.remove_from_order
                });
                order.adgroups = order.adgroups.filter((item) => {
                    return !item.remove_from_order && order.campaigns.filter((campaign) => {
                        return campaign.id === item.campaign
                    }).length > 0
                });
                order.ads = order.ads.filter((item) => {
                    return !item.remove_from_order && order.adgroups.filter((adgroup) => {
                        return adgroup.id === item.adgroup
                    }).length > 0
                });
            }

            try {
                this.promisedSetState({ loading: true, create_wizard: false });
                if (!this.state.wizard.partial) {
                    await this.calls.create(order);
                } else {
                    await this.calls.create_to_existing(order);
                }
                this.promisedSetState({ loading: false });
                this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts/pending");
            } catch (error) {
                this.promisedSetState({ loading: false });
            }

        }
    };

    calls = {
        updateWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateWizard?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        create: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizardCampaigns?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        create_to_existing: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createExistingOrders?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                            } else if (type === "save" || type === "preview") {
                                this.functions.updateWizard(type === "preview");
                            } else if (type === "create" && this.functions.validation()) {
                                this.setState({
                                    create_wizard: true
                                })
                            }
                        }}
                        hideUserDropdown={true}
                        history={this.props.history}
                        breadcrumb={"Preview"}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", disabled: this.state.loading_external_wizard || this.state.loading_wizard || this.state.loading_template, loading: false, show: !this.state.loading, red: true },
                            { text: this.state.wizard.template ? "Save template" : "Save draft", icon: SaveIcon, value: "save", disabled: this.state.loading_external_wizard || this.state.loading_wizard || this.state.loading_template, loading: this.state.loading_wizard, show: !this.state.loading },
                            { text: !this.state.wizard.partial ? "Create campaigns" : "Publish updates", icon: PlusIcon, value: "create", disabled: this.state.loading_external_wizard || this.state.loading_wizard || this.state.loading_template || !this.functions.validation(), loading: this.state.loading_external_wizard, show: !this.state.loading },
                            { text: "External preview", icon: ExternalLinkIcon, value: "preview", disabled: this.state.loading_external_wizard || this.state.loading_wizard || this.state.loading_template, loading: this.state.loading_external_wizard, show: !this.state.loading },
                        ]}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                {/* SUCCESS NOTIFICATION*/}
                <SideNotification
                    ref={"SideNotification"}
                    icon={CheckIcon}
                    title={"Success"}
                    text={"Wizard updated"}
                />

                {/*CREATE MODAL*/}
                <WizardModal
                    title={"Create campaigns"}
                    text={"Are you sure ?"}
                    button={"Create"}
                    icon={BellIcon}
                    open={this.state.create_wizard}
                    hideInput={true}
                    onLoading={false}
                    onCreate={() => {
                        this.functions.create();
                    }}
                    onClose={() => {
                        this.setState({
                            create_wizard: false
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }}
                            class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div>
                            <div className={`text-sm shadow bg-white rounded-lg p-6`}>
                                <div
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            show_ext_prev_settings: !this.state.show_ext_prev_settings
                                        })
                                    }}
                                    className='flex justify-between items-center select-none cursor-pointer'>
                                    <div>
                                        <span className={`duration-200 ease-in-out ${this.state.show_ext_prev_settings ? "opacity-0" : "opacity-100"}`}>External preview settings</span>
                                    </div>
                                    <span>
                                        {
                                            !this.state.show_ext_prev_settings &&
                                            <ChevronDownIcon className="h-5 w-5" />
                                        }
                                        {
                                            this.state.show_ext_prev_settings &&
                                            <ChevronUpIcon className="h-5 w-5" />
                                        }
                                    </span>
                                </div>
                                {
                                    <SlideDown closed={!this.state.show_ext_prev_settings}>
                                        <div className={`grid grid-cols-6 row-gap-4 duration-500 ease-in-out ${!this.state.show_ext_prev_settings ? "opacity-0" : "opacity-100"}`}>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"External preview"}
                                                    selected={this.state.wizard.previewEnabled ? this.state.wizard.previewEnabled : {
                                                        id: 1,
                                                        name: "Enable",
                                                        value: true
                                                    }}
                                                    options={[{ id: 1, name: "Enable", value: true }, {
                                                        id: 2,
                                                        name: "Disable",
                                                        value: false
                                                    }]}
                                                    onChange={async (value) => {
                                                        this.state.wizard.previewEnabled = value;
                                                        await this.promisedSetState({
                                                            wizard: this.state.wizard
                                                        });
                                                        this.form.set();
                                                    }}
                                                />
                                            </div>
                                            {
                                                (!this.state.wizard.previewEnabled || (this.state.wizard.previewEnabled && this.state.wizard.previewEnabled.id === 1)) &&
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Select logo"}
                                                        selected={this.state.wizard.previewLogo ? this.state.wizard.previewLogo : {
                                                            id: 1,
                                                            name: "Client Logo",
                                                            value: "client"
                                                        }}
                                                        options={[{ id: 1, name: "Client Logo", value: "client" }, {
                                                            id: 2,
                                                            name: "Agency Logo",
                                                            value: "agency"
                                                        }]}
                                                        onChange={async (value) => {
                                                            this.state.wizard.previewLogo = value;
                                                            await this.promisedSetState({
                                                                wizard: this.state.wizard
                                                            });
                                                            this.form.set();
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                (!this.state.wizard.previewEnabled || (this.state.wizard.previewEnabled && this.state.wizard.previewEnabled.id === 1)) &&
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Headline"}
                                                        value={this.state.wizard.previewHeadline}
                                                        onChange={async (value) => {
                                                            this.state.wizard.previewHeadline = value;
                                                            await this.promisedSetState({
                                                                wizard: this.state.wizard
                                                            });
                                                            this.form.set();
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                (!this.state.wizard.previewEnabled || (this.state.wizard.previewEnabled && this.state.wizard.previewEnabled.id === 1)) &&
                                                <div className="col-span-6">
                                                    <TextAreaTailwind
                                                        label={"Description"}
                                                        value={this.state.wizard.previewDescription}
                                                        onChange={async (value) => {
                                                            this.state.wizard.previewDescription = value;
                                                            await this.promisedSetState({
                                                                wizard: this.state.wizard
                                                            });
                                                            this.form.set();
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </SlideDown>
                                }
                            </div>
                        </div>
                        <PreviewSection
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            ads={JSON.parse(JSON.stringify(this.state.ads))}
                            wizard={JSON.parse(JSON.stringify(this.state.wizard))}
                            channels={JSON.parse(JSON.stringify(this.state.channels))}
                            is_post={JSON.parse(JSON.stringify(this.state.ads)).filter((item) => { return item.type === "post" }).length > 0 ? true : false}
                        />

                        {/* BOTTOM MENU */}
                        <div className="flex flex-row mt-4">
                            <div className="flex flex-1">
                                <button
                                    type="button"
                                    onClick={async () => {
                                        if (this.state.channels.linkedin) {
                                            this.props.history.push("/v2/campaigns/new/linkedin");
                                        } else if (this.state.channels.tiktok) {
                                            this.props.history.push("/v2/campaigns/new/tiktok");
                                        } else if (this.state.channels.twitter) {
                                            this.props.history.push("/v2/campaigns/new/twitter");
                                        } else if (this.state.channels.google) {
                                            this.props.history.push("/v2/campaigns/new/google");
                                        } else if (this.state.channels.facebook) {
                                            this.props.history.push("/v2/campaigns/new/facebook");
                                        } else if (this.state.channels.snapchat) {
                                            this.props.history.push("/v2/campaigns/new/snapchat");
                                        }
                                        //this.props.history.push("/v2/campaigns/new/channels");
                                    }}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none shadow focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                    Back
                                </button>
                            </div>
                            <div className="flex flex-1"></div>
                            {
                                !this.state.wizard.template &&
                                <div className="flex justify-center items-center">
                                    <div className="">
                                        <button
                                            onClick={() => {
                                                if (!this.state.loading && this.functions.validation()) {
                                                    if (!this.state.error) {
                                                        this.setState({
                                                            create_wizard: true
                                                        })
                                                    }

                                                }
                                            }}
                                            className={(!this.state.error && this.functions.validation() ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                        >
                                            <span>{!this.state.wizard.partial ? "Create campaigns" : "Publish updates"}</span>
                                            <PlusIcon className="ml-2 h-5 w-5" />
                                            {
                                                this.state.loading &&
                                                <div
                                                    className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default CreateDraftPreview;
