import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getFacebookEstimate(token, adaccount, data, optimization_goal) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/v16.0/'+adaccount+'/delivery_estimate?targeting_spec='+data+'&optimization_goal='+optimization_goal+'&access_token=' + token;
        return apiRegister.call(options, url);
    }

    static createCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/internal";
        return apiRegister.call(options, url);
    }

}

export {calls}