import React, { Component } from 'react';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import Cropper from 'react-easy-crop';
import { XIcon } from '@heroicons/react/outline';

class CreateOrderCreativeCropper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wizard: null,
            order: null,
            image: null,
            video: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1 / 1,
            aspect_name: "1:1",
            croppedAreaPixels: {},
            allowed_aspects: {
                "1:1": true,
                "1.91:1": true,
                "9:16": true
            }
        }
    }

    componentDidMount() {
        if (Array.isArray(this.props.files) && this.props.files.length > 0) {
            this.setState({
                order: this.props.order ? this.props.order : null,
                wizard: this.props.wizard,
                file: this.props.files[0],
                image: this.props.files[0].type === "image" ? this.props.files[0].url : null,
                video: this.props.files[0].type === "video" ? this.props.files[0].url : null,
                aspect: this.props.aspect ? this.props.aspect : this.state.aspect,
                aspect_name: this.props.aspectName ? this.props.aspectName : this.state.aspect_name,
                allowed_aspects: this.props.allowedAspects ? this.props.allowedAspects : this.state.allowed_aspects
            })
        }
    }

    functions = {
        crop: async () => {
            this.props.onLoader(true);
            await this.promisedSetState({ loading: true });
            try {
                const { croppedAreaPixels } = this.state;
                let response = await this.calls.cropMedia({
                    video: this.state.file.type === "video",
                    image: this.state.file.type === "image",
                    ratio: this.state.aspect_name,
                    name: this.state.file.name,
                    name_by_channels: this.state.file.name_by_channels,
                    width: croppedAreaPixels.width,
                    height: croppedAreaPixels.height,
                    x: croppedAreaPixels.x,
                    y: croppedAreaPixels.y,
                    url: this.state.file.url
                });
                response.data.name = this.state.file.name;
                response.data.name_by_channels = this.state.file.name_by_channels;
                response.data.type = this.state.file.type;
                this.props.onLoader(false);
                this.props.onNew(response.data);
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        }
    }

    calls = {
        cropMedia: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/cropMedia?" + (this.state.wizard ? "wizard=" + this.state.wizard : (this.state.order ? "order=" + this.state.order : ""));
            return apiRegister.call(options, url);
        }
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {


        //console.log(gcfcalc(croppedAreaPixels.width, croppedAreaPixels.height));
        let value = gcd(croppedAreaPixels.width, croppedAreaPixels.height);
        console.log(croppedAreaPixels);
        console.log((croppedAreaPixels.width / value) + ":" + (croppedAreaPixels.height / value));

        function gcd(a, b) {
            if (!b) {
                return a;
            }
            return gcd(b, a % b);
        };

        function gcfcalc(a, b) {
            if (a != Math.floor(a) || b != Math.floor(b)) {
                alert("Please enter integer numbers");
                return;
            }
            if (a == '' || b == '') {
                alert("Please enter integer numbers");
                return;
            }
            if (a == 0 && b == 0) {
                alert("Please enter at least one non zero integer");
                return;
            }
            var g = gcd(a, b);
            if (g < 0) g = -g;
            a = parseInt(a);
            b = parseInt(b);
            g = parseInt(g);
            return g;
        }

        this.setState({
            croppedAreaPixels
        })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        const { croppedAreaPixels, crop, zoom, aspect } = this.state;
        return (
            <div className="">
                {
                    this.state.image &&
                    <Cropper
                        image={this.state.image}
                        crop={crop}
                        zoom={zoom}
                        minZoom={1}
                        maxZoom={1}
                        aspect={aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                }
                {
                    this.state.video &&
                    <Cropper
                        video={this.state.video}
                        crop={crop}
                        zoom={zoom}
                        minZoom={1}
                        maxZoom={1}
                        aspect={aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                }
                <div className="flex-row flex mt-6 absolute left-0 pl-6">
                    {
                        this.state.allowed_aspects["1:1"] &&
                        <div onClick={() => {
                            this.setState({
                                aspect: (1 / 1),
                                aspect_name: "1:1"
                            })
                        }} className="rounded-full cursor-pointer mr-2 flex h-12 w-12 items-center justify-center text-sm font-medium bg-purple-500 text-white">
                            1:1
                        </div>
                    }
                    {
                        this.state.allowed_aspects["1.91:1"] &&
                        <div onClick={() => {
                            this.setState({
                                aspect: (400 / 209),
                                aspect_name: "1.91:1"
                            })
                        }} className="rounded-full cursor-pointer mr-2 flex h-12 w-12 items-center justify-center text-sm font-medium bg-purple-500 text-white">
                            1.91:1
                        </div>
                    }
                    {
                        this.state.allowed_aspects["4:5"] &&
                        <div onClick={() => {
                            this.setState({
                                aspect: (4 / 5),
                                aspect_name: "4:5"
                            })
                        }} className="rounded-full cursor-pointer mr-2 flex h-12 w-12 items-center justify-center text-sm font-medium bg-purple-500 text-white">
                            4:5
                        </div>
                    }
                    {
                        this.state.allowed_aspects["9:16"] &&
                        <div onClick={() => {
                            this.setState({
                                aspect: (9 / 16),
                                aspect_name: "9:16"
                            })
                        }} className="rounded-full cursor-pointer mr-2 flex h-12 w-12 items-center justify-center text-sm font-medium bg-purple-500 text-white">
                            9:16
                        </div>
                    }
                </div>
                {
                    this.props.onClose &&
                    <div className="flex-row flex mt-6 absolute right-0 pr-6">
                        <div onClick={() => {
                            this.props.onClose();
                        }} className="rounded-full cursor-pointer flex h-12 w-12 items-center justify-center text-sm font-medium bg-red-500 text-white">
                            <XIcon className="w-5 h-5" />
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default CreateOrderCreativeCropper;
