import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import Switch from "react-ios-switch";
import CampaignStatusSocialPreviewFacebookImage from "../campaign-status-social-preview-facebook-image";
import Carousel from 're-carousel'
import CampaignStatusLevelsCampaign from "../campaign-status-levels-campaign";
import CampaignStatusLevelsAdset from "../campaign-status-levels-adset";
import CampaignStatusLevelsAd from "../campaign-status-levels-ad";
var moment = require('moment');

class CampaignStatusLevels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: this.props.channel,
            campaigns: this.props.campaigns,
            campaign: {},
            adsets: this.props.adsets,
            ads: this.props.ads,
            sort: {},
            order: {},
            index: {
                google: 10
            }
        };
    };

    componentWillMount() {}

    componentDidMount() {}

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channel: nextProps.channel,
            campaigns: nextProps.campaigns,
            adsets: nextProps.adsets,
            ads: nextProps.ads
        });
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="CampaignStatusSocial-Wrapper">
                <div className="CampaignStatusSocial-WrapperTitle">
                    {this.state.channel}
                </div>
                {
                    this.state.campaigns.map((campaign) => {
                        return(
                            <div style={{width: '100%'}}>
                                <CampaignStatusLevelsCampaign
                                    channel={this.state.channel}
                                    campaign={campaign}
                                    onChange={(value) => {
                                        campaign.open = value;
                                        if(!campaign.open){
                                            this.state.adsets.filter((adset) => {
                                                return campaign.id === adset.campaign
                                            }).map((adset) => {
                                                adset.open = false;
                                                this.state.ads.filter((ad) => {
                                                    return adset.id === ad.adset
                                                }).map((ad) => {
                                                    ad.open = false;
                                                })
                                            })
                                        }
                                        this.setState({
                                            campaigns: this.state.campaigns,
                                            adsets: this.state.adsets,
                                            ads: this.state.ads
                                        })
                                    }}
                                />
                                {
                                    this.state.adsets.filter((adset) => {
                                        return campaign.id === adset.campaign && campaign.open && this.state.channel === 'google' && adset.status === 'error'
                                    }).map((adset) => {
                                        return(
                                            <CampaignStatusLevelsAdset
                                                channel={this.state.channel}
                                                adset={adset}
                                            />
                                        )
                                    })
                                }
                                {
                                    this.state.adsets.filter((adset, index) => {
                                        return campaign.id === adset.campaign && campaign.open && this.state.channel === 'google' && adset.status !== 'error' && index < this.state.index.google
                                    }).map((adset) => {
                                        return(
                                            <CampaignStatusLevelsAdset
                                                channel={this.state.channel}
                                                adset={adset}
                                            />
                                        )
                                    })
                                }
                                {
                                    this.state.adsets.filter((adset) => {
                                        return campaign.id === adset.campaign && campaign.open && this.state.channel !== 'google'
                                    }).map((adset) => {
                                        return(
                                            <div style={{width: '100%'}}>
                                                <CampaignStatusLevelsAdset
                                                    channel={this.state.channel}
                                                    adset={adset}
                                                    more={this.state.ads.filter((ad) => {
                                                        return adset.id === ad.adset
                                                    }).length > 0}
                                                    onChange={(value) => {
                                                        adset.open = value;
                                                        this.setState({
                                                            adsets: this.state.adsets
                                                        })
                                                    }}
                                                />
                                                {
                                                    this.state.ads.filter((ad) => {
                                                        return adset.id === ad.adset && campaign.open && adset.open
                                                    }).length > 0 &&
                                                    <div style={{paddingTop: '0px', paddingBottom: '10px', paddingLeft: '65px'}} className="Section">
                                                        <Dropdown
                                                            tiny={true}
                                                            filter={false}
                                                            noTitle={true}
                                                            title={'Sort'}
                                                            size={'half'}
                                                            placeholder={"Sort by"}
                                                            options={[
                                                                {name: 'Name', value: 'name'},
                                                                {name: 'Type', value: 'type'},
                                                                {name: 'Impressions', value: 'impressions'},
                                                                {name: 'Click', value: 'click'},
                                                                {name: 'Ctr', value: 'ctr'},
                                                                {name: 'Cpc', value: 'cpc'},
                                                                {name: 'Spend', value: 'spend'},
                                                                {name: 'Conversions', value: 'conversions'}
                                                            ]}
                                                            value={this.state.sort[adset.id]?this.state.sort[adset.id]:{name: 'Click', value: 'click'}}
                                                            onChange={(value) => {
                                                                this.state.sort[adset.id] = value;
                                                                this.setState({
                                                                    sort: this.state.sort
                                                                })
                                                            }}
                                                        />
                                                        <Dropdown
                                                            tiny={true}
                                                            filter={false}
                                                            noTitle={true}
                                                            title={'Order'}
                                                            size={'half'}
                                                            placeholder={"Order by"}
                                                            options={[
                                                                {name: 'Ascending', value: 'asc'},
                                                                {name: 'Descending', value: 'desc'}
                                                            ]}
                                                            value={this.state.order[adset.id]?this.state.order[adset.id]:{name: 'Ascending', value: 'ascend'}}
                                                            onChange={(value) => {
                                                                this.state.order[adset.id] = value;
                                                                this.setState({
                                                                    order: this.state.order
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.state.ads.filter((ad) => {
                                                        return adset.id === ad.adset && campaign.open && adset.open
                                                    }).sort((a, b) => {
                                                        var nameA = null;
                                                        var nameB = null;
                                                        var order = null;
                                                        try{
                                                            nameA = a[this.state.sort[adset.id].value];
                                                            nameB = b[this.state.sort[adset.id].value];
                                                        }catch (e) {
                                                            nameA = a['click'];
                                                            nameB = b['click'];
                                                        }
                                                        try{
                                                            order = this.state.order[adset.id].value;
                                                        }catch (e) {
                                                            order = 'asc';
                                                        }
                                                        if (order === 'desc') {
                                                            return nameB - nameA
                                                        } else {
                                                            return nameA - nameB
                                                        }
                                                    }).map((ad, index) => {
                                                        return(
                                                            <CampaignStatusLevelsAd
                                                                channel={this.state.channel}
                                                                ad={ad}
                                                                sort={this.state.sort[adset.id]?this.state.sort[adset.id]:{name: 'Click', value: 'click'}}
                                                                onChange={(value) => {
                                                                    ad = value;
                                                                    this.setState({
                                                                        ads: this.state.ads
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.channel === 'google' && (this.state.index.google < this.state.adsets.filter((adset) => {
                                        return campaign.id === adset.campaign && campaign.open && adset.status !== 'error'
                                    }).length) &&
                                    <div style={{width: '100%'}}>
                                        <div onClick={() => {
                                            this.state.index.google = this.state.index.google + 10;
                                            this.setState({
                                                index: this.state.index
                                            })
                                        }} className="Button ButtonFull ButtonNeutral">
                                            Load more ({this.state.index.google}/{this.state.adsets.filter((adset) => {
                                            return campaign.id === adset.campaign && campaign.open && adset.status !== 'error'
                                        }).length})
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default CampaignStatusLevels;
