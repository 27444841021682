import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, UploadIcon } from '@heroicons/react/outline';
import moment from 'moment';
import InputTailwind from '../moduleFiles/inputTailwind';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { Menu, Transition } from '@headlessui/react';

const fuzzysort = require('fuzzysort');

class OrderImportCampaigns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_import: false,
            search: "",
            sort: 'imported',
            orderBy: 'descending',
            columns: [
                { name: 'imported', value: 'imported', min_width: '250px' },
                { name: 'name', value: 'name', min_width: '450px', max_width: '450px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'start date', value: 'start', min_width: '250px' },
                { name: 'end date', value: 'end', min_width: '250px' }
            ],
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            campaigns: {},
            channels: [],
            client: {},
            order: {},
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        }
    };

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.campaigns(true);
        this.props.updateStatistics();

        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        campaigns: async (init) => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: init });
                try {
                    let response = await this.calls.listCampaigns();
                    await this.promisedSetState({
                        order: response.order,
                        campaigns: response.data,
                        client: response.client,
                        channels: Object.keys(response.data).map((channel) => { return { direction: "descending", open: false, sort: "imported", search: "", value: channel, limit: 10, page: 1, total: response.data[channel].length } })
                    });
                    if (this.props.setOrder && this.state.order) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        importCampaigns: async () => {
            await this.promisedSetState({ loading_import: true });
            try {
                let data = [];
                for (let channel in this.state.campaigns) {
                    this.state.campaigns[channel].map((campaign) => {
                        if (campaign.selected) {
                            data.push({ channel: channel, id: campaign.id, name: campaign.name, status: campaign.status });
                        }
                    });
                }
                await this.calls.importCampaigns({ campaigns: data });
                this.props.history.push(this.props.history.push("/v2/orders/:id/campaigns".replace(":id", this.functions.getIdFromPath())));
            } catch (error) {
                await this.promisedSetState({ loading_import: false });
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                console.log(error);
                return null
            }
        }
    };

    open = {
        facebook: (level, item) => {
            if (level === "campaigns") {
                window.open("https://business.facebook.com/adsmanager/manage/campaigns/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.id, "_blank");
            }
        },
        linkedin: (level, item) => {
            if (level === "campaigns") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaign-groups/" + item.id, "_blank");
            }
        },
        twitter: (level, item) => {
            if (level === "campaigns") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.ui_id + "/edit", "_blank");
            }
        },
        tiktok: (level, item) => {
            if (level === "campaigns") {
                window.open("https://ads.tiktok.com/i18n/perf?aadvid=" + item.account + "&keyword=" + item.id + "", "_blank");
            }
        },
        snapchat: (level, item) => {
            if (level === "campaigns") {
                window.open("https://ads.tiktok.com/i18n/perf?aadvid=" + item.account + "&keyword=" + item.id + "", "_blank");
            }
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        listCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listExternalCampaigns?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        importCampaigns: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/importExternalCampaigns?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        }
    };

    renders = {
        sort: (channel, list) => {

            list = this.renders.filter(list, channel.search);

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[channel.sort] ? x.row_values[channel.sort] : 0) : (x[channel.sort] ? x[channel.sort] : 0);
                    let y_value = y.row_values ? (y.row_values[channel.sort] ? y.row_values[channel.sort] : 0) : (y[channel.sort] ? y[channel.sort] : 0);
                    if (channel.direction == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[channel.sort] ? x.row_values[channel.sort] : "") : (x[channel.sort] ? x[channel.sort] : "");
                    let y_value = y.row_values ? (y.row_values[channel.sort] ? y.row_values[channel.sort] : "") : (y[channel.sort] ? y[channel.sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (channel.direction == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[channel.sort] ? x.row_values[channel.sort] : new Date()) : (x[channel.sort] ? x[channel.sort] : new Date());
                    let y_value = y.row_values ? (y.row_values[channel.sort] ? y.row_values[channel.sort] : new Date()) : (y[channel.sort] ? y[channel.sort] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (channel.direction == 'descending') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (channel.sort === "name" || channel.sort === "status") {
                return sortCharacter(list);
            } else if (channel.sort === "start" || channel.sort === "end") {
                return sortDate(list);
            } else {
                return sortNumber(list);
            }

        },
        filter: (list, search) => {
            if (search !== "") {
                let result = fuzzysort.go(search, list.map((item) => { return item.name }));
                let keywords = [];
                let old_list = JSON.parse(JSON.stringify(list));
                result.map((item) => {
                    for (let i = 0; i < old_list.length; i++) {
                        if (("name" in old_list[i]) && item.target && item.target.toLowerCase() === old_list[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_list[i].html = html_string;
                            keywords.push(old_list[i]);
                            old_list.splice(i, 1);
                            break;
                        }
                    }
                });
                return keywords;
            } else {
                return list;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === 'import') {
                                this.functions.importCampaigns();
                            }
                        }}
                        breadcrumb={"Import"}
                        history={this.props.history}
                        hideUserDropdown={true}
                        buttons={[
                            {
                                text: "Import selected",
                                icon: UploadIcon,
                                value: "import",
                                loading: this.state.loading_import,
                                show: !this.state.loading,
                                disabled: !(Object.keys(this.state.campaigns).map((channel) => {
                                    return channel
                                }).filter((channel) => { return this.state.campaigns[channel].filter((campaign) => { return campaign.selected }).length > 0 }).length > 0)
                            }
                        ]}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaigns ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        {
                            this.state.channels.map((item, index) => {
                                return (
                                    <div className={(index !== 0 ? "mt-4" : "") + " shadow bg-white rounded-lg w-full h-full"}>
                                        <div className="flex flex-row items-center p-4">
                                            <div className="flex flex-1 items-center">
                                                {item.value === "facebook" &&
                                                    <div
                                                        className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                    </div>
                                                }
                                                {item.value === "linkedin" &&
                                                    <div
                                                        className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                    </div>
                                                }
                                                {item.value === "google" &&
                                                    <div
                                                        className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                    </div>
                                                }
                                                {item.value === "tiktok" &&
                                                    <div
                                                        className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                    </div>
                                                }
                                                {/* {item.value === "snapchat" &&
                                                    <div
                                                        className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                    </div>
                                                } */}
                                                {item.value === "twitter" &&
                                                    <div
                                                        className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                    </div>
                                                }
                                                {item.value === "adform" &&
                                                    <div
                                                        className={'w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}>
                                                        </div></div>
                                                }
                                                {item.value === "bing" &&
                                                    <div
                                                        className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}>

                                                        </div>
                                                    </div>
                                                }
                                                {item.value === "snapchat" &&
                                                    <div
                                                        className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}>

                                                        </div>
                                                    </div>
                                                }
                                                <div className="ml-2 font-medium text-sm capitalize">
                                                    {item.value}
                                                </div>
                                            </div>
                                            <div className="flex flex-row">
                                                <div className="flex flex-1 items-center justify-center mr-4">
                                                    {
                                                        item.total !== 0 &&
                                                        <p className="text-sm text-gray-700">
                                                            Showing <span className="font-medium">{item.page * item.limit - item.limit + 1}</span> to <span className="font-medium">{((item.page * item.limit) > item.total) ? item.total : item.page * item.limit}</span> of{' '}
                                                            <span className="font-medium">{item.total}</span> results
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex justify-center items-center mr-2">
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            if (item.page !== 1) {
                                                                item.page = (item.page > 1 ? (item.page - 1) : 1);
                                                                this.setState({
                                                                    channels: this.state.channels
                                                                });
                                                            }
                                                        }}
                                                        className={(item.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  shadow text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                    >
                                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                        Previous
                                                    </button>
                                                </div>
                                                {
                                                    item.total > 0 &&
                                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                                        <div>
                                                            <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  shadow px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                Page limit: {item.limit}
                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {
                                                                        [5, 10, 20, 30, 50, 100, 500].map((value) => {
                                                                            return (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <div
                                                                                            onClick={async () => {
                                                                                                item.limit = value;
                                                                                                this.setState({
                                                                                                    channels: this.state.channels
                                                                                                })
                                                                                            }}
                                                                                            className={cn(
                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                item.limit == value ? "text-purple-500" : ""
                                                                                            )}
                                                                                        >
                                                                                            {value}
                                                                                        </div>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                                <div className="flex justify-center items-center">
                                                    <div className="">
                                                        <button
                                                            onClick={async () => {
                                                                if (!((item.page * item.limit) >= +item.total)) {
                                                                    item.page = (item.page + 1);
                                                                    this.setState({
                                                                        channels: this.state.channels
                                                                    });
                                                                }
                                                            }}
                                                            className={(((item.page * item.limit) >= +item.total) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  shadow text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                        >
                                                            <span>Next</span>
                                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={async () => {
                                                                item.open = !item.open;
                                                                this.setState({
                                                                    channels: this.state.channels
                                                                })
                                                            }}
                                                            className={(item.total > 0 ? "text-white bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-300 text-gray-600 cursor-not-allowed") + "  ml-2 inline-flex items-center px-4 h-10 shadow text-sm font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                        >
                                                            {
                                                                !item.open &&
                                                                <span>Open</span>
                                                            }
                                                            {
                                                                item.open &&
                                                                <span>Close</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            item.open &&
                                            <div className="p-4 pt-0">
                                                <InputTailwind
                                                    label={"Search name"}
                                                    value={item.search}
                                                    onChange={(value) => {
                                                        item.search = value;
                                                        this.setState({
                                                            channels: this.state.channels
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.campaigns[item.value].length > 0 &&
                                            <div className={(item.open ? "h-full overflow-x-auto table-overflow" : "h-0 overflow-hidden") + " transition ease-in-out duration-500 relative"}>

                                                {/*LOADER PARTIAL*/}
                                                {
                                                    item.loading_partial &&
                                                    <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }

                                                <table className="min-w-full border-t divide-y divide-gray-300 border-gray-300 h-full">
                                                    <thead className="bg-white">
                                                        <tr>
                                                            <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                            {this.state.columns.map((column, index) => {
                                                                return (
                                                                    <th
                                                                        onClick={async () => {
                                                                            if (!column.noSort) {
                                                                                item.sort = column.value;
                                                                                item.direction = item.direction === 'ascending' ? 'descending' : 'ascending'
                                                                                await this.promisedSetState({
                                                                                    page: 1,
                                                                                    campaigns: this.state.campaigns,
                                                                                    //sort: column.value,
                                                                                    //direction: item.direction === 'ascending' ? 'descending' : 'ascending'
                                                                                });
                                                                            }
                                                                        }}
                                                                        style={{ ...(column.max_width && { maxWidth: column.max_width }), ...(column.min_width && { minWidth: column.min_width }), whiteSpace: "nowrap" }}
                                                                        scope="col"
                                                                        className={(column.value == item.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!column.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                                    >
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="mr-4">{column.name}</div>
                                                                            {
                                                                                !column.noSort &&
                                                                                <div className="flex flex-col">
                                                                                    <ChevronUpIcon className={(item.direction === 'ascending' && item.sort === column.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                                    <ChevronDownIcon className={(item.direction === 'descending' && item.sort === column.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                                    {/* <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === column.value && "opacity-100"} ${this.state.sort === column.value && item.direction === "ascending" && "transform rotate-180"}`} /> */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-gray-300">
                                                        {
                                                            this.renders.sort(item, this.state.campaigns[item.value]).filter((campaign, index) => {
                                                                return index < (item.page * item.limit) && index >= ((item.page * item.limit) - item.limit)
                                                            }).map((item) => {
                                                                if (typeof item.status === 'string') {
                                                                    item.status = item.status.toLowerCase();
                                                                }
                                                                return item;
                                                            }).map((row, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <tr className="border-b" key={row.id}>
                                                                            <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row  border-gray-300"}>
                                                                                <div onClick={() => {
                                                                                    //row.selected = !row.selected;
                                                                                    this.state.campaigns[item.value] = this.state.campaigns[item.value].map((inner_item) => {
                                                                                        if (row.id === inner_item.id) {
                                                                                            inner_item.selected = !inner_item.selected;
                                                                                        }
                                                                                        return inner_item;
                                                                                    });
                                                                                    this.setState({
                                                                                        campaigns: this.state.campaigns
                                                                                    });
                                                                                }} className={(row.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700 ") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                    {
                                                                                        row.selected &&
                                                                                        <CheckIcon className="h-5 w-5" />
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ width: "450px", maxWidth: "450px" }} className={(item.sort === 'imported' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                <span
                                                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                                        {
                                                                                            ["bg-purple-100 text-purple-500"]: row.imported,
                                                                                            ["bg-gray-100 text-gray-500"]: !row.imported,
                                                                                        })
                                                                                    }>
                                                                                    {row.imported ? "TRUE" : "FALSE"}
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: "450px", maxWidth: "450px" }} className={(item.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                {row.name ? row.name : "-"}
                                                                            </td>
                                                                            <td className={(item.sort === 'status' ? "bg-gray-50" : "") + " px-4 whitespace-no-wrap sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                                <span
                                                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                                        {
                                                                                            ["bg-green-100 text-green-500"]: row.status === "active" || row.status === "enable" || row.status === "enabled" || row.status === "scheduled",
                                                                                            ["bg-purple-100 text-purple-500"]: row.status === "upcoming",
                                                                                            [" bg-gray-200 text-gray-500"]: (row.status === "archived" || row.status === "ended" || row.status === "done" || row.status === "balance_exceed" || row.status === "unknown" || row.status === "draft" || row.status === "pending" || row.status === "expired" || row.status === "completed" || row.status === "time_done"),
                                                                                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: row.status === "paused" || row.status === "disable" || row.status === 'campaign_disable' || row.status === 'adgroup_disable',
                                                                                            //["bg-gray-100 text-gray-500"]: row.status === "draft" || row.status === "pending",
                                                                                            ["bg-red-100 text-red-600"]: row.status === "error" || row.status === "removed"
                                                                                        })
                                                                                    }>
                                                                                    {row.status ? row.status : "-"}
                                                                                </span>
                                                                            </td>
                                                                            <td className={(item.sort === 'start' ? "bg-gray-50" : "") + " px-4 whitespace-no-wrap sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                                {row.start ? moment(row.start).format('YYYY-MM-DD') : '-'}
                                                                            </td>
                                                                            <td className={(item.sort === 'end' ? "bg-gray-50" : "") + " px-4 whitespace-no-wrap sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                                {row.end ? moment(row.end).format('YYYY-MM-DD') : '-'}
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        {
                                            item.open &&
                                            <div className="p-4 flex flex-row items-center">

                                            </div>
                                        }
                                        {
                                            false &&
                                            <div className="p-4 flex flex-row items-center">
                                                {
                                                    this.state.campaigns[item.value].filter((item) => { return item.selected }).length > 0 &&
                                                    <div className="flex flex-1 items-center pl-2 flex-row text-sm">
                                                        <div className="font-medium mr-2">{this.state.campaigns[item.value].filter((item) => { return item.selected }).length}</div> selected
                                                    </div>
                                                }
                                                <div className="flex flex-1"></div>
                                                <button
                                                    onClick={async () => {
                                                        item.open = !item.open;
                                                        this.setState({
                                                            channels: this.state.channels
                                                        })
                                                    }}
                                                    className={"cursor-pointer inline-flex items-center px-4 h-10 shadow text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                >
                                                    {
                                                        !item.open &&
                                                        <span>Show more</span>
                                                    }
                                                    {
                                                        item.open &&
                                                        <span>Show less</span>
                                                    }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    false &&
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*TABLES*/}
                            {
                                this.state.campaigns.length > 0 &&
                                this.state.channels.map((channel) => {
                                    return (
                                        <div className="relative overflow-scroll h-full">

                                            <div>
                                                <div className="flex flex-row items-center border-b p-6">
                                                    {channel.value === "facebook" &&
                                                        <div
                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {channel.value === "linkedin" &&
                                                        <div
                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {channel.value === "google" &&
                                                        <div
                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }

                                                    {channel.value === "tiktok" &&
                                                        <div
                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {channel.value === "snapchat" &&
                                                        <div
                                                            className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    {channel.value === "twitter" &&
                                                        <div
                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                        </div>
                                                    }
                                                    <div className="ml-2 font-medium text-sm capitalize">
                                                        {channel.value}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*LOADER PARTIAL*/}
                                            {
                                                channel.loading_partial &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                            <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                                {
                                                    this.renders.filter(this.state.campaigns.filter((item) => {
                                                        return item.channel == channel.value
                                                    })).length > 0 &&
                                                    <thead className="bg-white">
                                                        <tr>
                                                            <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                            {this.state.columns.map((item, index) => {
                                                                return (
                                                                    <th
                                                                        onClick={async () => {
                                                                            channel.page = 1;
                                                                            channel.sort = item.value;
                                                                            channel.direction = item.direction === 'ascending' ? 'descending' : 'ascending';
                                                                            await this.promisedSetState({
                                                                                channels: this.state.channels
                                                                            });
                                                                        }}
                                                                        style={item.width ? { width: item.width } : (item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" })}
                                                                        scope="col"
                                                                        className={(item.value == channel.sort ? "bg-gray-100" : "hover:bg-gray-100 hover:bg-opacity-75") + " px-6 py-3 border-gray-300 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                                    >
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="mr-4">{item.name}</div>
                                                                            <div className="flex flex-col">
                                                                                <ChevronUpIcon className={(channel.direction === 'ascending' && channel.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                                <ChevronDownIcon className={(channel.direction === 'descending' && channel.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                }
                                                <tbody className="bg-white divide-gray-300 relative">
                                                    {
                                                        this.renders.filter(this.state.campaigns.filter((item) => {
                                                            return item.channel == channel.value
                                                        })).length < 1 &&
                                                        <div className="h-24">
                                                            <div className="flex border-b w-full justify-center items-center absolute h-full text-sm font-medium text-gray-500">No {channel.value} campaigns available</div>
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.filter(this.state.campaigns.filter((item) => {
                                                            return item.channel == channel.value
                                                        })).map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr className="border-b" key={item.id}>
                                                                        <td style={{ width: "100px" }} className="relative whitespace-nowrap p-4 py-3 text-left text-sm font-medium sm:pr-6">
                                                                            <div onClick={() => {
                                                                                if (!item.imported) {
                                                                                    this.state.campaigns = this.state.campaigns.map((inner_item) => {
                                                                                        if (inner_item.id === item.id && item.channel === inner_item.channel) {
                                                                                            inner_item.selected = !inner_item.selected;
                                                                                        }
                                                                                        return inner_item;
                                                                                    });
                                                                                    this.setState({
                                                                                        campaigns: this.state.campaigns
                                                                                    });
                                                                                }
                                                                            }} className={(item.imported ? " cursor-not-allowed opacity-50 " : "cursor-pointer ") + (item.selected ? " border-purple-500 bg-purple-100" : "") + " mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    item.selected &&
                                                                                    <CheckIcon className="w-5 h-5" />
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                        {
                                                                            false &&
                                                                            <td className={(this.state.sort === 'channel' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                                <div className="flex flex-row items-center">
                                                                                    {item.channel === "facebook" &&
                                                                                        <div
                                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {item.channel === "linkedin" &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {item.channel === "google" &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }

                                                                                    {item.channel === "tiktok" &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {item.channel === "snapchat" &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }

                                                                                    {item.channel === "twitter" &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="ml-2 font-medium text-sm capitalize">
                                                                                        {item.channel}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }

                                                                        <td className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm  border-gray-300"}>
                                                                            {item.name ? item.name : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                            <span
                                                                                className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                                    {
                                                                                        ["bg-green-100 text-green-500"]: item.status.toLowerCase() === "active" || item.status.toLowerCase() === "enable" || item.status.toLowerCase() === "scheduled" || item.status.toLowerCase() === "enabled",
                                                                                        ["bg-purple-100 text-purple-500"]: item.status.toLowerCase() === "upcoming",
                                                                                        ["bg-gray-100 text-gray-500"]: item.status.toLowerCase() === "archived" || item.status.toLowerCase() === "ended",
                                                                                        ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status.toLowerCase() === "paused",
                                                                                        ["bg-gray-100 text-gray-500"]: item.status.toLowerCase() === "pending",
                                                                                        ["bg-gray-100 text-gray-500"]: item.status.toLowerCase() === "draft",
                                                                                        ["bg-red-100 text-red-600"]: item.status.toLowerCase() === "error" || item.status.toLowerCase() === "disable" || item.status.toLowerCase() === "unknown" || item.status.toLowerCase() === "expired",
                                                                                    })
                                                                                }>
                                                                                {item.status ? item.status : "-"}
                                                                            </span>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'start' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300"}>
                                                                            {item.start ? moment(item.start).format('YYYY-MM-DD') : '-'}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'end' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  items-center text-sm  border-gray-300"}>
                                                                            {item.end ? moment(item.end).format('YYYY-MM-DD') : '-'}
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })

                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default OrderImportCampaigns;
