import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import TwitterLogin from 'react-twitter-auth';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountTwitter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            loading: false,
            loading_account: false,
            loading_accounts: false,
            loading_twitter_users: false,
            loading_fundinginstruments: false,
            account: {},
            accounts: [],
            twitter_user: {},
            twitter_users: [],
            funding_instrument: {},
            funding_instruments: [],
            healthcheck: {},
            owner: false,
            disabled: false,
            loading_advertisers: false,
            advertiser: {},
            advertisers: [],
            me: {},
            throttling: {},
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user,
            me: this.props.me,
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.twitter) {
            this.functions.getAgents(true);
        }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user,
            me: nextprops.me,
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    functions = {
        login: async () => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true
                });
                await self.calls.connect({
                    channel: "twitter",
                    token: this.state.access_token,
                    token_secret: this.state.access_token_secret,
                    url: this.state.twitter_url
                });
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ twitter: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "twitter"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ twitter: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        getAccount: async () => {
            await this.promisedSetState({
                loading_twitter_account: true
            });
            try {
                let response = await this.calls.getAccount();
                await this.promisedSetState({
                    account: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_twitter_account: false
            });
        },
        listUsers: async () => {
            await this.promisedSetState({
                loading_twitter_users: true
            });
            try {
                let response = await this.calls.listUsers();
                await this.promisedSetState({
                    twitter_users: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_twitter_users: false
            });
        },
        listFundingInstruments: async () => {
            await this.promisedSetState({
                loading_fundinginstruments: true
            });
            try {
                let response = await this.calls.listFundingInstruments();
                await this.promisedSetState({
                    funding_instruments: response.data.map((item) => { item.name = item.description; return item })
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_fundinginstruments: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    user_information: {
                        profile_image_url: this.state.account.profile_image_url,
                        name: this.state.account.name,
                        username: this.state.account.username
                    },
                    user: {
                        user_id: this.state.account.id
                    },
                    twitter_user: {
                        id: this.state.twitter_user.id,
                        timezone: this.state.twitter_user.timezone
                    },
                    account: {
                        id: this.state.account.id,
                        name: this.state.account.name,
                    },
                    funding: {
                        currency: this.state.currency.id,
                        id: this.state.funding_instrument.id,
                        description: this.state.funding_instrument.description,
                    }
                });
                this.state.client.twitter = {
                    defaultAgent: this.state.agent,
                    userId: this.state.account.id,
                    adaccountName: this.state.account.name,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    account: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.twitter;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    account: {},
                    currency: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/twitter/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/twitter/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listUsers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listAccounts?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        listFundingInstruments: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/listFundingInstruments?client=" + this.state.client.id + "&account=" + this.state.twitter_user.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=twitter";
            return apiRegister.call(options, url);
        },
        getAccount: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/twitter/getAccount?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Twitter"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-twitter-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Twitter
                        </div>
                        {
                            !this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Twitter to this client
                            </div>
                        }
                        {
                            this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Twitter to this account
                            </div>
                        }
                    </div>
                    {
                        !this.state.user &&
                        this.state.client.twitter &&
                        (this.state.is_manager || (!this.state.is_manager && this.state.client.twitter.defaultAgent && this.state.client.twitter.defaultAgent.id === this.state.me.id)) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.twitter ? true : false}
                                onSwitch={async () => {
                                    if (!this.state.client.twitter) {
                                        this.state.client.twitter = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                this.state.loading_account &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.user &&
                    < div className="mt-5">
                        <DropdownTailwind
                            label={"Default agent"}
                            small={false}
                            locked={this.state.client.twitter}
                            skipInternalSearch={this.state.is_manager}
                            loader={this.state.loading_agents}
                            loadingPagination={this.state.loading_agents_pagination}
                            loadingSearch={this.state.loading_agents_search}
                            total={this.state.total}
                            searchInput={true}
                            placeholder={"..."}
                            defaultOptions={this.state.me.twitter && this.state.me.twitter.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                            pagination={this.state.total > (this.state.page * this.state.limit)}
                            selected={this.state.client.twitter && this.state.client.twitter.defaultAgent ? this.state.client.twitter.defaultAgent : this.state.agent}
                            options={this.state.agents}
                            onChange={async (value) => {
                                await this.promisedSetState({
                                    agent: value,
                                    twitter_user: {},
                                    funding_instrument: {}
                                });
                                this.functions.getAccount();
                                this.functions.listUsers();
                            }}
                            onPagination={async () => {
                                if (!this.state.loading_agents_pagination) {
                                    await this.promisedSetState({
                                        page: this.state.page + 1
                                    });
                                    this.functions.getAgents(false, true, false);
                                }
                            }}
                            onSearch={async (value) => {
                                if (value && value === "") {
                                    await this.promisedSetState({
                                        search: "",
                                        page: 1
                                    });
                                    this.functions.getAgents(false, false, true);
                                } else {
                                    if (this.state.throttling.current) {
                                        //SKIP
                                    } else {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: value,
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            this.state.throttling.current = true;
                                            await this.promisedSetState({
                                                search: value,
                                                throttling: this.state.throttling
                                            });
                                            setTimeout(async () => {
                                                this.state.throttling.current = false;
                                                await this.promisedSetState({
                                                    throttling: this.state.throttling
                                                });
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            }, 300);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.client.twitter || this.state.agent.id !== 0) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={this.state.client.twitter ? "User" : "Users"}
                                loader={this.state.loading_twitter_users || this.state.loading_twitter_account}
                                locked={this.state.client.twitter}
                                selected={this.state.client.twitter && this.state.client.twitter.userId ? { name: this.state.client.twitter.adaccountName } : (this.state.twitter_user.id ? this.state.twitter_user : { name: "Select user" })}
                                options={this.state.twitter_users}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        twitter_user: value
                                    });
                                    this.functions.listFundingInstruments();
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.twitter && this.state.client.twitter.userId) || this.state.twitter_user.id)}
                            copyText={(this.state.client.twitter && this.state.client.twitter.userId) ? this.state.client.twitter.userId : this.state.twitter_user.id}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    !this.state.client.twitter &&
                    this.state.twitter_user.id &&
                    this.state.account.id &&
                    <div className="mt-5">
                        <DropdownTailwind
                            label={"Funding sources"}
                            locked={this.state.client.twitter}
                            loader={this.state.loading_fundinginstruments}
                            selected={this.state.funding_instrument.id ? this.state.funding_instrument : { name: "Select funding source" }}
                            options={this.state.funding_instruments}
                            onChange={async (value) => {

                                //PRESET CURRENCY
                                let currency = null;
                                this.state.currencies.map((item) => {
                                    if (item.id == value.currency) {
                                        currency = item;
                                    }
                                });
                                if (currency) {
                                    this.state.currency = currency;
                                }

                                await this.promisedSetState({
                                    funding_instrument: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.funding_instrument.id || (this.state.client.twitter && this.state.client.twitter.userId)) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.twitter}
                            selected={this.state.client.twitter && this.state.client.twitter.userId ? { name: this.state.client.twitter.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    !this.state.client.twitter &&
                    this.state.account.id &&
                    this.state.funding_instrument.id &&
                    <div className="">
                        <button
                            onClick={() => {
                                if (!this.state.loading_account) {
                                    this.functions.connectToClient();
                                }
                            }}
                            className={(this.state.funding_instrument.id ? "bg-twitter-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Save Twitter account</span>
                            {
                                this.state.loading_account &&
                                <div className={"bg-twitter-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.client.id &&
                    (!this.state.user.twitter || (this.state.user.twitter && !this.state.user.twitter.authenticated)) &&
                    <div className="mt-5">
                        <TwitterLogin
                            forceLogin={true}
                            loginUrl={apiRegister.url.api + "/v3/twitter/authenticationVerifier"}
                            onSuccess={async (data) => {
                                console.log(data);
                                console.log("success");
                                if (data && data.status >= 200 && data.status < 300) {
                                    let response = await data.json();
                                    await this.promisedSetState({
                                        access_token: response.access_token,
                                        access_token_secret: response.access_token_secret
                                    });
                                    this.functions.login();
                                }
                            }}
                            onFailure={async (data) => {
                                console.log(data);
                            }}
                            requestTokenUrl={apiRegister.url.api + "/v3/twitter/authenticationUrl"}
                        >
                            <button className={(!(this.state.user.twitter && this.state.user.twitter.authenticated) ?
                                "cursor-pointer bg-twitter-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                            >
                                <span>Login with Twitter</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.twitter && this.state.user.twitter.authenticated) ? "bg-twitter-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </TwitterLogin>
                    </div>
                }
                {
                    !this.state.client.id &&
                    this.state.user.twitter && this.state.user.twitter.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.twitter && this.state.user.twitter.authenticated) ?
                                "cursor-pointer bg-twitter-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.twitter && this.state.user.twitter.authenticated) ? "bg-twitter-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    this.state.error &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    this.state.healthcheck && this.state.healthcheck.twitter && this.state.healthcheck.twitter.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.twitter.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.client.twitter &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.twitter && this.state.client.twitter.defaultAgent && this.state.client.twitter.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.user &&
                    !this.state.is_manager &&
                    !this.state.client.twitter &&
                    !(this.state.me.twitter && this.state.me.twitter.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated Twitter, go to My Profile.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.is_manager &&
                    !this.state.client.twitter &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated Twitter
                    </div>
                }
            </div>
        )
    }
}

export default AccountTwitter;
