import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from "./inputTailwind";

class ShareMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agency: { id: 0, name: "Select", value: null },
            throttling: {},
            agencies: [],
            agency_search: '',
            agency_limit: 10,
            agency_page: 1,
            agency_total: 0,
            template: null,
            metric_name: '',
            error: null,
        };
    };

    async componentDidMount() {
        this.functions.getAgencies();
        this.setState({
            metric: this.props.metric,
            metric_name: this.props.metric.name
        });
    }

    functions = {
        share: async () => {
            await this.promisedSetState({
                error: null,
            });
            this.functions.validate().then(async () => {
                let data = {
                    agency: this.state.agency.id,
                    metric_name: this.state.metric_name
                }
                await this.calls.share(this.state.metric.id, data);
                this.props.onCreated();
            }).catch(() => {
                this.props.onError();
            })
        },
        validate: async () => {
            if (this.state.metric_name.length == 0) {
                await this.promisedSetState({
                    error: "Metric name is required"
                });
                this.props.onError();
                return Promise.reject();
            }

            if (this.state.agency && this.state.agency.id === 0) {
                await this.promisedSetState({
                    error: "Agency is required"
                });
                this.props.onError();
                return Promise.reject();
            }

            return Promise.resolve();
        },
        getAgencies: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_agencies_pagination: paginaton,
                loading_agencies: init
            });
            try {
                let response = await this.calls.getAgencies();
                if (!search_value || (search_value && search_value == this.state.agency_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            agencies: response.data.map((item) => { item.image = item.logo; return item }),
                            agency_total: response.meta.total
                        });
                    } else {
                        this.state.agencies = this.state.agencies.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            agencies: this.state.agencies,
                            agency_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agencies_search: false,
                loading_agencies_pagination: false,
                loading_agencies: false
            });
        },
    }

    calls = {
        getAgencies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/shortListAgencies?limit=" + this.state.agency_limit + "&page=" + this.state.agency_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.agency_search !== "" ? ("&search=" + this.state.agency_search) : "");
            return apiRegister.call(options, url);
        },
        share: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/shareMetric?metric=" + id;
            return apiRegister.call(options, url);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className="col-span-12">
                                    <InputTailwind
                                        label={"Metric name"}
                                        value={this.state.metric_name}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                metric_name: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Agency"}
                                        selected={this.state.agency}
                                        options={this.state.agencies}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_agencies}
                                        loadingPagination={this.state.loading_agencies_pagination}
                                        loadingSearch={this.state.loading_agencies_search}
                                        total={this.state.agency_total}
                                        searchInput={true}
                                        placeholder={"..."}
                                        pagination={this.state.agency_total > (this.state.agency_page * this.state.agency_limit)}
                                        onChange={(value) => {
                                            this.setState({
                                                agency: value,
                                                error: null
                                            })
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_agencies_pagination) {
                                                await this.promisedSetState({
                                                    agency_page: this.state.agency_page + 1
                                                });
                                                this.functions.getAgencies(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    agency_search: "",
                                                    agency_page: 1
                                                });
                                                this.functions.getAgencies(false, false, true);
                                            } else {
                                                if (this.state.throttling.current) {
                                                    //SKIP
                                                } else {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            agency_search: value,
                                                            agency_page: 1
                                                        });
                                                        this.functions.getAgencies(false, false, true);
                                                    } else {
                                                        this.state.throttling.current = true;
                                                        await this.promisedSetState({
                                                            agency_search: value,
                                                            throttling: this.state.throttling
                                                        });
                                                        setTimeout(async () => {
                                                            this.state.throttling.current = false;
                                                            await this.promisedSetState({
                                                                throttling: this.state.throttling
                                                            });
                                                            await this.promisedSetState({
                                                                agency_page: 1
                                                            });
                                                            this.functions.getAgencies(false, false, true);
                                                        }, 300);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.error &&
                    <div className={"w-full mt-4"}>
                        <div className="bg-red-100 p-4 rounded-md">
                            <div className="text-sm text-red-500">
                                {this.state.error}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

};

export default ShareMetric;
