import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../input";
import ClientFacebook from "../client-facebook";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import ClientUsers from "../client-users";
import ClientInstagram from "../client-instagram";
import ClientGoogle from "../client-google";
import ClientBing from "../client-bing";
import Accordion from "../accordion";
import AccordionChannels from "../accordion-channels";
import {calls} from "./calls";
import Loader from "../loader";
import UploadImage from "../image-upload";
import IconGoogle from "../icons/google";
import ClientSettings from "../client-settings";

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            loading: true,
            warning_modal: false
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {
        client: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getClient(this.state.item.id).then((response) => {
                    this.setState({
                        item: response.data,
                        loading: false
                    })
                }, (error) => {
                    this.setState({
                        error: true,
                        loading: false
                    })
                })
            })
        }
    };

    functions = {
        remove: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    warning_modal: false,
                    loading_title: "Removing client"
                }, () => {
                    calls.removeClient(this.state.item.id).then((response) => {
                        this.props.onRemove();
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            loading: false
                        })
                    });
                })
            }
        },
        update: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    warning_modal: false,
                    loading_title: "Updating client"
                }, () => {
                    let client = {
                        name: this.state.item.name,
                        company: this.state.item.company,
                        logo: this.state.item.logo,
                        email: this.state.item.email
                    };
                    calls.updateClient(client, this.state.item.id).then((response) => {
                        this.setState({
                            loading: false,
                            edit: false,
                            item: response.data
                        })
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            edit: false,
                            loading: false
                        })
                    });
                })
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="bg-white shadow-lg rounded">
                <div className="Client-InnerContainerInner">
                    <div className={
                        cx("Client-InnerContainerInnerTop",
                            {
                                ["Site-InnerContainerInnerTopOpen"]: this.state.open,
                            })
                    }>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Name
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.name}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Company
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.company}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Channels
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {
                                    this.state.item.facebook &&
                                    <IconFacebook noMargin={true}/>
                                }
                                {
                                    this.state.item.instagram &&
                                    <IconInstagram/>
                                }
                                {
                                    this.state.item.google &&
                                    <IconGoogle/>
                                }
                                {
                                    !this.state.item.facebook && !this.state.item.instagram && !this.state.item.google &&
                                    <span>
                                        No channels
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Users
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.users_count ? this.state.item.users_count : "0"}
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end'}}>
                            <div style={{width: '55px', height: '55px'}}>
                                <div onClick={() => {
                                    this.props.history.push('/user/clients/' + this.state.item.id);
                                }} className="Button ButtonFull ButtonNeutral">
                                    <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        false &&
                        <SlideDown closed={!this.state.open}>
                            {
                                this.state.loading &&
                                <div className="Client-Loader">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={20}
                                        color={'#388f8a'}
                                        loading={true}
                                    />
                                </div>
                            }
                            {
                                !this.state.loading &&
                                <ClientSettings
                                    item={this.state.item}
                                    onRemove={(value) => {
                                        this.props.onRemove(value);
                                    }}
                                />
                            }
                            {
                                !this.state.loading &&
                                <ClientUsers
                                    item={this.state.item}
                                />
                            }
                            {
                                !this.state.loading &&
                                <AccordionChannels
                                    google={this.state.item.google}
                                    facebook={this.state.item.facebook}
                                    instagram={this.state.item.instagram}
                                    title={'Channels'}
                                >
                                    <ClientFacebook
                                        onUpdate={(item) => {
                                            this.setState({
                                                item: item
                                            })
                                        }}
                                        item={this.state.item}
                                    />
                                    <ClientInstagram
                                        onUpdate={(item) => {
                                            this.setState({
                                                item: item
                                            })
                                        }}
                                        item={this.state.item}
                                    />
                                    <ClientGoogle
                                        onUpdate={(item) => {
                                            this.setState({
                                                item: item
                                            })
                                        }}
                                        item={this.state.item
                                        }/>
                                    <ClientBing
                                        onUpdate={(item) => {
                                            this.setState({
                                                item: item
                                            })
                                        }} item={this.state.item}
                                    />
                                </AccordionChannels>
                            }
                        </SlideDown>
                    }
                </div>
            </div>
        )
    }
}

export default Client;
