import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle, Polygon
} from "react-google-maps";
import FacebookLocationMap from "../../components/advertising-location-map";
import UserRegionsNewMap from "../user-regions-new-map";
import Dropdown from "../../components/dropdown";
import countries from '../../assets/json/countries';
import {calls} from "./calls";
import IconFacebook from "../../components/icons/facebook";
import IconGoogle from "../../components/icons/google";
import RegionsNewMap from "../../components/regions-new-map";
import Loader from "../../components/loader";
import Switch from "react-ios-switch";
import UserRegionsNewItem from "../../components/user-regions-new-item";

class UserRegionsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            region: {},
            loading: false,
            loading_text: "Loading country regions",
            center: {lat: 40.64, lng: -73.96},
            zoom: 9,
            countries: countries,
            country: {},
            countries_search: "",
            client_search: "",
            calls: {},
            selected_channels: {},
            locations: [],
            clients: [],
            client: {},
            places: []
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.functions.clients();
    }

    functions = {
        regions: (item, search) => {
            if (search !== "") {
                if (!item.calls) {
                    item.calls = {};
                }
                if (!item.calls[search]) {
                    let call = {
                        string: search,
                        loading: true,
                        error: false,
                        regions: {}
                    };
                    item.calls[search] = call;
                    this.setState({
                        places: this.state.places
                    }, () => {
                        let data = {
                            client: this.state.client.id,
                            query: search,
                            country: item.country?item.country.code:this.state.country.code
                        };
                        calls.getRegions(data).then((response) => {
                            if (item.calls[search]) {
                                item.calls[search].regions = response.data;
                                item.calls[search].loading = false;
                            }
                            this.setState({
                                places: this.state.places
                            })
                        }, (error) => {
                            if (item.calls[search]) {
                                item.calls[search].loading = false;
                                item.calls[search].error = true;
                            }
                            this.setState({
                                places: this.state.places
                            })
                        });
                    });
                }
            }
        },
        clients: () => {
            if(!this.state.loading){
                this.setState({
                    loading: true,
                    loading_text: "Loading clients"
                }, () => {
                    calls.getClients().then((response) => {
                        this.setState({
                            clients: response.data,
                            loading: false
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        internalRegions: () => {
            if(!this.state.loading){
                this.setState({
                    loading: true,
                    loading_text: "Loading country regions"
                }, () => {
                    calls.getInternalRegions({country: this.state.country.code, client: this.state.client.id}).then((response) => {
                        this.setState({
                            locations: response.data,
                            loading: false
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: (item) => {

            item.error = false;

            let channels = null;
            if(item.selected_channels) {
                channels = JSON.parse(JSON.stringify(item.selected_channels));
                for(let key in channels){
                    channels[key] = {
                        id: channels[key].id,
                        channel: key,
                        name: channels[key].name
                    }
                }
            }

            let object = {
                name: item.name,
                country: item.country?item.country.code:this.state.country.code,
                country_name: item.country?item.country.name:this.state.country.name,
                level: item.level?item.level.value:null,
                status: item.status?item.status.value:null,
                paths: item.paths.filter((item) => {return item.include}).map((item) => {return item.paths}),
                channels: channels
            };

            item.errors = [];
            if(!item.name || item.name === ""){
                item.error = true;
                item.errors.push("Title is missing");
            }
            if(!channels || Object.keys(channels).length < 1){
                item.error = true;
                item.errors.push("Select platform regions");
            }
            if(object.paths.length < 1){
                item.error = true;
                item.errors.push("Select one polygon on the map");
            }
            if(!object.level){
                item.error = true;
                item.errors.push("Select level");
            }
            if(!object.status){
                item.error = true;
                item.errors.push("Select status");
            }

            if(!item.loading && !item.error){
                item.loading = true;
                item.open = false;
                this.setState({
                    places: this.state.places
                }, () => {
                    calls.createRegion(object).then((response) => {
                        this.state.locations.push(response.data);
                        item.created = true;
                        item.loading = false;
                        this.setState({
                            places: this.state.places,
                            locations: this.state.locations
                        })
                    }, (error) => {
                        item.loading = false;
                        this.setState({
                            places: this.state.places
                        })
                    });
                })
            }else{
                this.setState({
                    places: this.state.places
                });
            }

        },
        onSelectFile: (e, type) => {
            let file = e.target.files[0];
            //console.log(file);
            this.setState({
                loading: true,
                loading_text: "Parsing regions"
            }, () => {
                let fileReader = new FileReader();
                fileReader.onload = async (e) => {
                    if(type === 'kml'){
                        this.functions.parseXml(e.target.result);
                    }else if(type === 'json'){
                        this.functions.parseJson(e.target.result);
                    }
                };
                fileReader.readAsText(file)
            });
        },
        parseJson: (plainText) => {
            try{
                let json = JSON.parse(plainText);
                let geo_items = [];
                for(let i = 0; i < json.features.length; i++){
                    let id = Math.floor((Math.random() * 9999999999) + 1);
                    let data = {
                        id: id,
                        paths: [],
                        name: "",
                        status: {name: 'Active', value: 'active'}
                    };
                    data.name = json.features[i].properties.ADMIN;

                    if(data.name === 'Burkina Faso'){
                        //console.log(json.features[i].geometry.coordinates);
                    }

                    if(i === 2){
                        //console.log(json.features[i].geometry.coordinates);
                    }

                    let paths = [];
                    for(let index = 0; index < json.features[i].geometry.coordinates.length; index++){
                        let array = [];
                        if(json.features[i].geometry.coordinates.length > 1){
                            array = json.features[i].geometry.coordinates[index][0].map((inner_item) => {
                                return {lat: inner_item[1], lng: inner_item[0]}
                            });
                        }else{
                            array = json.features[i].geometry.coordinates[index].map((inner_item) => {
                                return {lat: inner_item[1], lng: inner_item[0]}
                            });
                        }
                        paths.push({include: true, paths: array});
                    }

                    data.paths = paths;
                    geo_items.push(data);
                }
                //console.log(geo_items);
                this.setState({
                    loading: false,
                    places: geo_items
                });
            }catch (e) {
                //console.log(e);
                this.setState({
                    loading: false
                });
            }
        },
        parseXml: (plainText) => {
            try{
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(plainText, "text/xml");
                let geo_items = [];
                if (xmlDoc.documentElement.nodeName === "kml") {
                    for (const item of xmlDoc.getElementsByTagName('Placemark')) {
                        let id = Math.floor((Math.random() * 9999999999) + 1);
                        let data = {
                            id: id,
                            paths: [],
                            name: "",
                            status: {name: 'Active', value: 'active'}
                        };
                        let simpleDatas = item.getElementsByTagName('SimpleData');
                        if(simpleDatas.length > 0){
                            data.name = simpleDatas[0].childNodes[0].nodeValue.trim();
                        }
                        if(simpleDatas.length > 1){
                            data.name = simpleDatas[1].childNodes[0].nodeValue.trim();
                        }
                        if(simpleDatas.length > 2){
                            data.name = simpleDatas[2].childNodes[0].nodeValue.trim();
                        }
                        let polygons = item.getElementsByTagName('Polygon');
                        for (const polygon of polygons) {
                            let coords = polygon.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
                            let points = coords.split(" ");

                            let googlePolygonsPaths = {include: false, paths: []};
                            for (const point of points) {
                                let coord = point.split(",");
                                googlePolygonsPaths.paths.push({lat: +coord[1], lng: +coord[0]})
                            }
                            data.paths.push(googlePolygonsPaths)
                        }
                        geo_items.push(data);
                    }
                } else {
                    this.setState({
                        loading: false
                    });
                }
                this.setState({
                    loading: false,
                    places: geo_items
                }, () => {
                    this.state.places.map((item) => {
                        item.added = this.state.locations.filter((inner_item) => {
                            return item.name === inner_item.name;
                        }).length > 0;
                    });
                    this.setState({
                        places: this.state.places
                    });
                });
            }catch (e) {
                this.setState({
                    loading: false
                });
            }
        }
    };

    renders = {
        regions: (item) => {
            let regions = [];
            try{
                for(let key in item.calls[item.search].regions){
                    item.calls[item.search].regions[key] = item.calls[item.search].regions[key].map((item) => {
                        item.channel = key;
                        if(key === 'facebook'){
                            item.id = item.key;
                        }
                        return item;
                    });
                    regions = regions.concat(item.calls[item.search].regions[key]);
                }
            }catch (e) {}
            return regions;
        },
        sortRegions: (region, array) => {
            let match = [];
            let substring = [];
            let other = [];
            array.map((item) => {
                if(item.name.toLowerCase() === region.search.toLowerCase()){
                    match.push(item);
                }else if(item.name.toLowerCase().indexOf(region.search.toLowerCase()) !== -1){
                    item.index = item.name.length - region.search.length;
                    substring.push(item);
                }else{
                    other.push(item);
                }
            });

            substring.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

            let new_array = match.concat(substring);
            new_array = new_array.concat(other);
            return new_array;
        }
    };

    render() {
        return (
            <div className="Profile">
                <div className="Profile-Inner">
                    <div className="background">
                        <div className="region-container">
                            {
                                this.state.loading &&
                                <Loader
                                    title={this.state.loading_text}
                                />
                            }
                            {
                                !this.state.client.name &&
                                <div style={{paddingTop: '5px'}} className="Section">
                                    <Dropdown
                                        filter={true}
                                        searchFilter={true}
                                        search={true}
                                        title={'Select client'}
                                        size={'full'}
                                        placeholder={"Search here .."}
                                        options={this.state.clients}
                                        value={this.state.client_search}
                                        onSearch={(value) => {

                                        }}
                                        onChange={(value) => {
                                            this.setState({
                                                client: value,
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.client.name &&
                                <div style={{paddingTop: '5px'}} className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Selected client
                                        </div>
                                        <div className="Region-country">
                                            <div className="Region-country-value">
                                                {this.state.client.name}
                                            </div>
                                            <div className="Region-country-button">
                                                <div onClick={() => {
                                                    this.setState({
                                                        client: {},
                                                        country: {},
                                                        locations: []
                                                    })
                                                }} className="Button ButtonFull ButtonRed">
                                                    <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.client.name && !this.state.country.name &&
                                <div className="Section">
                                    <Dropdown
                                        filter={true}
                                        searchFilter={true}
                                        search={true}
                                        title={'Select country'}
                                        size={'full'}
                                        placeholder={"Search here .."}
                                        options={this.state.countries}
                                        value={this.state.countries_search}
                                        onSearch={(value) => {

                                        }}
                                        onChange={(value) => {
                                            this.setState({
                                                country: value,
                                            }, () => {
                                                this.functions.internalRegions();
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.client.name && this.state.country.name &&
                                <div className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Selected country
                                        </div>
                                        <div className="Region-country">
                                            <div className="Region-country-value">
                                                {this.state.country.name}
                                            </div>
                                            <div className="Region-country-button">
                                                <div onClick={() => {
                                                    this.setState({
                                                        country: {},
                                                        locations: []
                                                    })
                                                }}  className="Button ButtonFull ButtonRed">
                                                    <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.country.name &&
                                <div className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            <div className="Region-item-top-name">
                                                Added regions for {this.state.country.name}
                                            </div>
                                            {
                                                this.state.locations.length > 0 &&
                                                <div className="Region-item-top-channels">
                                                    Channels
                                                </div>
                                            }
                                            {
                                                this.state.locations.length > 0 &&
                                                <div style={{justifyContent: 'flex-start'}} className="Region-item-top-status">
                                                    Status
                                                </div>
                                            }
                                            {
                                                this.state.locations.length > 0 &&
                                                <div style={{height: 'inherit'}} className="Region-item-top-open">

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.locations.map((item) =>  {
                                                return(
                                                    <div className="Region-item">
                                                        <div className="Region-item-top">
                                                            <div className="Region-item-top-value Region-item-top-name">
                                                                {item.name}
                                                            </div>
                                                            <div className="Region-item-top-value Region-item-top-channels">
                                                                {
                                                                    Object.keys(item.channels).map((channel) => {
                                                                        if (channel === 'facebook') {
                                                                            return (
                                                                                <div style={{marginLeft: '7px'}}>
                                                                                    <IconFacebook noMargin={true}/>
                                                                                </div>
                                                                            )
                                                                        }else if (channel === 'google') {
                                                                            return (
                                                                                <div style={{marginLeft: '7px'}}>
                                                                                    <IconGoogle noMargin={true}/>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (<div></div>)
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="Region-item-top-value Region-item-top-status">
                                                                {item.status}
                                                            </div>
                                                            <div className="Region-item-top-open">
                                                                <div onClick={() => {
                                                                    item.open = !item.open;
                                                                    this.setState({
                                                                        locations: this.state.locations
                                                                    });
                                                                }} className="Button ButtonFull ButtonNeutral">
                                                                    {
                                                                        !item.open &&
                                                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-down"/>
                                                                    }
                                                                    {
                                                                        item.open &&
                                                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-up"/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <SlideDown closed={!item.open}>
                                                            {
                                                                item.open &&
                                                                <UserRegionsNewItem
                                                                    item={item}
                                                                    client={this.state.client}
                                                                    country={this.state.country}
                                                                    onRemove={(id) => {
                                                                        item.open = false;
                                                                        this.setState({
                                                                            locations: this.state.locations.filter((item) => {
                                                                                return item._id !== id
                                                                            })
                                                                        })
                                                                    }}
                                                                    onUpdate={(value) => {
                                                                        console.log(value);
                                                                        this.setState({
                                                                            locations: this.state.locations.map((item) => {
                                                                                if(item.id === value.id){
                                                                                    item.paths = value.paths
                                                                                }
                                                                                return item
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                        </SlideDown>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            this.state.locations.length < 1 &&
                                            <div className="Region-item">
                                                <div className="Region-item-top">
                                                    <div className="Region-item-top-value Region-item-top-empty">
                                                        No regions added
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.country.name &&
                                <div className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Upload file (.kml)
                                        </div>
                                        <div className="Region-upload">
                                            <input
                                                onChange={(e) => this.functions.onSelectFile(e, 'kml')}
                                                type="file"
                                                className="Region-upload-Input"
                                            />
                                            <div className="Region-upload-text">
                                                Upload
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{minWidth: '20px'}}></div>
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Upload file (.json)
                                        </div>
                                        <div className="Region-upload">
                                            <input
                                                onChange={(e) => this.functions.onSelectFile(e, 'json')}
                                                type="file"
                                                className="Region-upload-Input"
                                            />
                                            <div className="Region-upload-text">
                                                Upload
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{minWidth: '20px'}}></div>
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Add custom region
                                        </div>
                                        <div onClick={() => {

                                        }} className="ButtonFull Button ButtonNeutral">
                                            Add custom region
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.country.name && this.state.places.length > 0 &&
                                <div className="Section">
                                    <div className="Section-Inner">
                                        <div className="Section-InnerTitle">
                                            Uploaded regions
                                        </div>
                                        {
                                            this.state.places.map((item) =>  {
                                                return(
                                                    <div className="Region-item">
                                                        <div className="Region-item-top">
                                                            <div className="Region-item-top-value Region-item-top-name">
                                                                {item.name}
                                                            </div>
                                                            {
                                                                (item.created || item.added) &&
                                                                <div className="Region-item-top-open">
                                                                    <div className="Button ButtonFull">
                                                                        <FeatherIcon color={"#ffffff"} size={25} icon="check"/>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                item.loading &&
                                                                <div className="Region-item-top-open">
                                                                    <div className="Button ButtonFull">
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={7}
                                                                            color={'#ffffff'}
                                                                            loading={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="Region-item-top-open">
                                                                <div onClick={() => {
                                                                    item.open = !item.open;
                                                                    this.setState({
                                                                        places: this.state.places
                                                                    });
                                                                }} className="Button ButtonFull ButtonNeutral">
                                                                    {
                                                                        !item.open &&
                                                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-down"/>
                                                                    }
                                                                    {
                                                                        item.open &&
                                                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-up"/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <SlideDown closed={!item.open}>
                                                            {
                                                                item.open &&
                                                                <div className="Region-item-bottom">
                                                                    <div style={{paddingTop: '5px'}} className="Section">
                                                                        <div className="Region-item-three">
                                                                            <Input
                                                                                noTitle={false}
                                                                                title={'Title'}
                                                                                white={true}
                                                                                type={'text'}
                                                                                error={item.error && item.name === ""}
                                                                                value={item.name}
                                                                                size={'full'}
                                                                                onChange={(event) => {
                                                                                    item.name = event.target.value;
                                                                                    this.setState({
                                                                                        places: this.state.places
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            false &&
                                                                            <div className="Region-item-four">
                                                                                <Dropdown
                                                                                    filter={true}
                                                                                    searchFilter={true}
                                                                                    search={true}
                                                                                    title={'Select country'}
                                                                                    size={'full'}
                                                                                    placeholder={"Search here .."}
                                                                                    options={this.state.countries}
                                                                                    value={this.state.countries_search}
                                                                                    onSearch={(value) => {

                                                                                    }}
                                                                                    onChange={(value) => {
                                                                                        item.country = value;
                                                                                        this.setState({
                                                                                            places: this.state.places
                                                                                        }, () => {
                                                                                            //console.log(this.state.places);
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <div className="Region-item-three">
                                                                            <Dropdown
                                                                                filter={false}
                                                                                searchFilter={false}
                                                                                search={false}
                                                                                white={true}
                                                                                title={'Select level'}
                                                                                error={item.error && !item.level}
                                                                                size={'full'}
                                                                                placeholder={"Click here"}
                                                                                options={[
                                                                                    {name: 'level 1 (area)', value: 1},
                                                                                    {name: 'level 2 (city)', value: 2},
                                                                                    {name: 'level 3 (county/state)', value: 3},
                                                                                    {name: 'level 4 (country)', value: 4}
                                                                                ]}
                                                                                value={item.level?item.level:{}}
                                                                                onChange={(value) => {
                                                                                    item.level = value;
                                                                                    this.setState({
                                                                                        places: this.state.places,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="Region-item-three">
                                                                            <Dropdown
                                                                                filter={false}
                                                                                searchFilter={false}
                                                                                search={false}
                                                                                white={true}
                                                                                title={'Select status'}
                                                                                error={item.error && !item.status}
                                                                                size={'full'}
                                                                                placeholder={"Click here"}
                                                                                options={[
                                                                                    {name: 'Active', value: 'active'},
                                                                                    {name: 'Disabled', value: 'disabled'}
                                                                                ]}
                                                                                value={item.status?item.status:{}}
                                                                                onChange={(value) => {
                                                                                    item.status = value;
                                                                                    this.setState({
                                                                                        places: this.state.places,
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        item.selected_channels && Object.keys(item.selected_channels).length > 0 &&
                                                                        <div className="Section">
                                                                            <div className="Section-Inner">
                                                                                <div className="Section-InnerTitle">
                                                                                    Selected platform regions
                                                                                </div>
                                                                                <div className="Region-Channel-inner Region-Channel-inner-selected">
                                                                                    {
                                                                                        Object.keys(item.selected_channels).map((channel) => {
                                                                                            return(
                                                                                                <div className="Region-Channel-inner-item">
                                                                                                    {item.selected_channels[channel].name}
                                                                                                    <b style={{marginLeft: '5px', textTransform: 'capitalize'}}>{item.selected_channels[channel].type}</b>
                                                                                                    {
                                                                                                        item.selected_channels[channel].channel === 'facebook' &&
                                                                                                        <div style={{marginLeft: '7px'}}>
                                                                                                            <IconFacebook noMargin={true}/>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        item.selected_channels[channel].channel === 'google' &&
                                                                                                        <div style={{marginLeft: '7px'}}>
                                                                                                            <IconGoogle noMargin={true}/>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div style={{flex: '1', height: '100%'}}>

                                                                                                    </div>
                                                                                                    <div onClick={() => {
                                                                                                        delete item.selected_channels[channel];
                                                                                                        this.setState({
                                                                                                            places: this.state.places
                                                                                                        });
                                                                                                    }} className="Region-Channel-inner-item-remove">
                                                                                                        <FeatherIcon color={"#333333"} size={23} icon="trash"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="Section">
                                                                        <div className="region-item-suggestions">
                                                                            {
                                                                                <div style={{paddingTop: '0px'}}  className="Section">
                                                                                    <Input
                                                                                        noTitle={false}
                                                                                        title={'Search platform regions'}
                                                                                        type={'text'}
                                                                                        white={true}
                                                                                        error={item.error && !item.selected_channels}
                                                                                        value={item.search}
                                                                                        size={'full'}
                                                                                        onChange={(event) => {
                                                                                            item.search = event.target.value;
                                                                                            this.setState({
                                                                                                places: this.state.places
                                                                                            });
                                                                                            this.functions.regions(item, event.target.value);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                <div className="Section">
                                                                                    <div className="Region-Container">
                                                                                        <div className="Region-Channel">
                                                                                            {
                                                                                                <div className="Region-Channel-inner">
                                                                                                    {
                                                                                                        this.renders.sortRegions(item, this.renders.regions(item)).map((inner_item) => {
                                                                                                            return(
                                                                                                                <div onClick={() => {
                                                                                                                    if(!item.selected_channels){
                                                                                                                        item.selected_channels = {};
                                                                                                                    }
                                                                                                                    item.selected_channels[inner_item.channel] = inner_item;
                                                                                                                    this.setState({
                                                                                                                        places: this.state.places
                                                                                                                    });
                                                                                                                }} className="Region-Channel-inner-item">
                                                                                                                    {inner_item.name}
                                                                                                                    <b style={{marginLeft: '5px', textTransform: 'capitalize'}}>{inner_item.type}</b>
                                                                                                                    {
                                                                                                                        inner_item.channel === 'facebook' &&
                                                                                                                        <div style={{marginLeft: '7px'}}>
                                                                                                                            <IconFacebook noMargin={true}/>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        inner_item.channel === 'google' &&
                                                                                                                        <div style={{marginLeft: '7px'}}>
                                                                                                                            <IconGoogle noMargin={true}/>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.calls && item.calls[item.search] && item.calls[item.search].loading &&
                                                                                                <div className="Region-Channel-loader">
                                                                                                    Loading regions
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="region-item-map">
                                                                            <div style={{paddingTop: '0px'}} className="Section">
                                                                                <div className="Section-Inner">
                                                                                    <div className="Section-InnerTitle">
                                                                                        Map
                                                                                    </div>
                                                                                    <RegionsNewMap
                                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?libraries=drawing&key=AIzaSyAOpL-2YHBEYSGq6cvFshbk9AidtmdrznU"
                                                                                        loadingElement={<div style={{height: `100%`, width: '100%'}}/>}
                                                                                        containerElement={<div style={{
                                                                                            height: `400px`,
                                                                                            width: '100%',
                                                                                            borderRadius: '3px',
                                                                                            overflow: 'hidden',
                                                                                            border: '1px solid #e7e7e7'
                                                                                        }}/>}
                                                                                        place={item}
                                                                                        mapElement={<div style={{height: `100%`, width: '100%'}}/>}
                                                                                        updatePlace={(value) => {
                                                                                            item = value;
                                                                                            this.setState({
                                                                                                places: this.state.places
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        item.error && item.errors &&
                                                                        <div className="SectionError">
                                                                            {
                                                                                item.errors.map((item) => {
                                                                                    return(
                                                                                        <div className="Section">{item}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="Section">
                                                                            <div onClick={() => {
                                                                                this.functions.create(item)
                                                                            }} className="ButtonFull Button">
                                                                                Create region
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </SlideDown>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserRegionsNew;
