import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../dropdown";

class TargetingAge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            channels: {},
            search: false,
            age_min_options: [],
            age_max_options: []
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            search: this.props.search,
            targeting: this.props.targeting ? this.props.targeting : {},
            channels: this.props.channels ? this.props.channels : {},
        }, () => {
            this.state.targeting.age_max = this.state.targeting.age_max ? this.state.targeting.age_max : {
                name: '65+',
                value: 65
            };
            this.state.targeting.age_min = this.state.targeting.age_min ? this.state.targeting.age_min : {
                name: 18,
                value: 18
            };
            this.state.targeting.age_span_linkedin = this.state.targeting.age_span_linkedin ? this.state.targeting.age_span_linkedin : {
                name: "18-55+ (all ages)",
                value: "18_ALL"
            };
            this.setState({
                targeting: this.state.targeting,
                age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
            })

        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            search: nextProps.search,
            targeting: nextProps.targeting ? nextProps.targeting : {},
            channels: nextProps.channels ? nextProps.channels : {},
        }, () => {
            this.state.targeting.age_max = this.state.targeting.age_max ? this.state.targeting.age_max : {
                name: '65+',
                value: 65
            };
            this.state.targeting.age_min = this.state.targeting.age_min ? this.state.targeting.age_min : {
                name: 18,
                value: 18
            };
            this.setState({
                targeting: this.state.targeting,
                age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
            })

        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        age: (min, max) => {
            let age = [];
            for (let i = min; i < max; i++) {
                let object = {name: i, value: i};
                if (i === 65) {
                    object.name = '65+';
                }
                age.push(object);
            }
            return age;
        },
        number: (x) => {
            try {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <div className="flex flex-row flex-1">
                {
                    <div className="flex flex-row flex-1">
                        {
                            (this.state.channels.facebook || this.state.channels.facebook_instagram || this.state.search) &&
                            <div className="flex flex-1">
                                <Dropdown
                                    filter={false}
                                    title={'Min Age'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "age"}
                                    icons={true}
                                    channels={Object.keys(this.state.channels).filter((channel) => {
                                        return channel !== "linkedin" && ((this.state.search && channel === "google") || (!this.state.search && channel !== "google"))
                                    })}
                                    options={this.state.age_min_options}
                                    value={this.state.targeting.age_min ? this.state.targeting.age_min : {}}
                                    onChange={(value) => {
                                        this.state.targeting.age_min = value;
                                        this.setState({
                                            targeting: this.state.targeting,
                                            age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                                            age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            (this.state.channels.facebook || this.state.channels.facebook_instagram || this.state.search) &&
                            <div className="w-2"/>
                        }
                        {
                            (this.state.channels.facebook || this.state.channels.facebook_instagram || this.state.search) &&
                            <div className="flex flex-1">
                                <Dropdown
                                    filter={false}
                                    title={'Max Age'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    icons={true}
                                    channels={Object.keys(this.state.channels).filter((channel) => {
                                        return channel !== "linkedin" && ((this.state.search && channel === "google") || (!this.state.search && channel !== "google"))
                                    })}
                                    error={this.state.error === "age"}
                                    options={this.state.age_max_options}
                                    value={this.state.targeting.age_max ? this.state.targeting.age_max : {}}
                                    onChange={(value) => {
                                        this.state.targeting.age_max = value;
                                        this.setState({
                                            targeting: this.state.targeting,
                                            age_min_options: this.renders.age(18, this.state.targeting.age_max.value ? this.state.targeting.age_max.value : 65),
                                            age_max_options: this.renders.age(this.state.targeting.age_min.value ? (this.state.targeting.age_min.value + 1) : 18, 66)
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            !this.state.search && (this.state.channels.facebook || this.state.channels.facebook_instagram) && this.state.channels.linkedin &&
                            <div className="w-2"/>
                        }
                        {
                            !this.state.search && this.state.channels.linkedin &&
                            <div className="flex flex-1">
                                <Dropdown
                                    filter={false}
                                    title={'Age span'}
                                    size={'full'}
                                    icons={true}
                                    channels={["linkedin"]}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "gender"}
                                    options={[
                                        {name: "18-55+ (all ages)", value: "18_ALL"},
                                        {name: "18-24", value: "18_24"},
                                        {name: "18-34", value: "18_34"},
                                        {name: "18-54", value: "18_54"},
                                        {name: "25-34", value: "25_34"},
                                        {name: "25-54", value: "25_54"},
                                        {name: "25-55+", value: "25_ALL"},
                                        {name: "35-54", value: "35_54"},
                                        {name: "35-55+", value: "35_ALL"},
                                        {name: "55+", value: "55_ALL"},
                                    ]}
                                    value={this.state.targeting.age_span_linkedin ? this.state.targeting.age_span_linkedin : {}}
                                    onChange={(value) => {
                                        this.state.targeting.age_span_linkedin = value;
                                        this.setState({
                                            targeting: this.state.targeting
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.search && false &&
                            <div className="flex flex-1">
                                <Dropdown
                                    filter={false}
                                    title={'Age span'}
                                    size={'full'}
                                    icons={true}
                                    channels={["google"]}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "gender"}
                                    options={[
                                        {name: "18-65+ (all ages)", value: "18_ALL"},
                                        {name: "18-24", value: "18_24"},
                                        {name: "18-34", value: "18_34"},
                                        {name: "18-54", value: "18_54"},
                                        {name: "18-64", value: "18_64"},
                                        {name: "24-34", value: "24_34"},
                                        {name: "24-54", value: "24_54"},
                                        {name: "24-64", value: "24_64"},
                                        {name: "24-65+", value: "24_ALL"},
                                        {name: "34-54", value: "34_54"},
                                        {name: "34-64", value: "34_64"},
                                        {name: "34-65+", value: "34_ALL"},
                                        {name: "65+", value: "65_ALL"},
                                    ]}
                                    value={this.state.targeting.age_span_google ? this.state.targeting.age_span_google : {}}
                                    onChange={(value) => {
                                        this.state.targeting.age_span_google = value;
                                        this.setState({
                                            targeting: this.state.targeting
                                        }, () => {
                                            this.props.onChange(this.state.targeting);
                                        })
                                    }}
                                />
                            </div>
                        }
                    </div>
                }
                {
                    false &&
                    (this.state.channels.facebook || this.state.channels.facebook_instagram) && this.state.channels.linkedin &&
                    <div className="w-2"/>
                }
                {
                    false &&
                    this.state.channels.linkedin &&
                    <div>
                        <div className="flex flex-1">
                            <Dropdown
                                filter={false}
                                title={'Age span'}
                                size={'full'}
                                icons={true}
                                channels={["linkedin"]}
                                placeholder={"Click here .."}
                                error={this.state.error === "gender"}
                                options={[]}
                                value={this.state.targeting.age_span_linkedin ? this.state.targeting.age_span_linkedin : {}}
                                onChange={(value) => {
                                    this.state.targeting.age_span_linkedin = value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.props.onChange(this.state.targeting);
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default TargetingAge;
