class orderRegister {

    static order = {};

    static set(key, value) {
        orderRegister.order[key] = value;
        try {
            if (sessionStorage.getItem("order")) {
                let order_data = JSON.parse(sessionStorage.getItem("order"));
                order_data[key] = value;
                sessionStorage.setItem("order", JSON.stringify(order_data));
            } else {
                sessionStorage.setItem("order", JSON.stringify(orderRegister.order));
            }
        } catch (error) {
            console.log(error);
        }
    }

    static get() {
        try {
            if (sessionStorage.getItem("order")) {
                let order_data = JSON.parse(sessionStorage.getItem("order"));
                return order_data;
            } else {
                return orderRegister.order
            }
        } catch (e) {
            return orderRegister.order
        }
    }

    static remove() {
        orderRegister.order = {};
        try {
            sessionStorage.removeItem("order");
        } catch (e) { }
    }

}

export { orderRegister }