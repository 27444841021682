import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { AdjustmentsIcon, BeakerIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClipboardListIcon, CogIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, LockClosedIcon, MenuAlt1Icon, MinusIcon, PlusIcon, ViewGridAddIcon } from '@heroicons/react/outline'

import FeatherIcon from 'feather-icons-react';
import { SlideDown } from "react-slidedown";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import languages from '../assets/json/languages.json';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';

import fuzzysort from 'fuzzysort';
import moment from 'moment';
import cn from "classnames";

class CustomizeOrderMetrics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: {
                type: true,
                metrics_custom: false
            },
            categories: [{ name: "custom" }, { name: "calculated" }],
            categories_v2: {
                custom: { name: "custom", open: false, search: "" },
                calculated: { name: "calculated", open: false, search: "" }
            },
            order: {},
            type: {},
            loader: false,
            templates: [],
            selected_template: {},
            dates: {},
            name: "",
            no_end: true,
            template_campaigns: [],
            channels: {},
            metrics: {},
            selected_metrics: {},
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            metrics: this.props.metrics ? this.props.metrics : {},
            order: this.props.order,
        })
        await this.functions.getMetrics();
        if (Object.keys(this.state.metrics).length > 0) {
            Object.keys(this.state.metrics).map((metric_id) => {
                this.state.selected_metrics[metric_id] = this.state.metrics_options.calculated.filter((item) => { return item.id == metric_id }).length > 0 ? this.state.metrics_options.calculated.filter((item) => { return item.id == metric_id })[0] : this.state.metrics_options.custom.filter((item) => { return item.id == metric_id })[0];
            })
            await this.promisedSetState({
                type: { name: "Custom metrics", value: "custom", description: "Set custom metrics" },
                selected_metrics: this.state.selected_metrics,
                open: {
                    type: false,
                    metrics_custom: true
                }
            })
        }
        this.props.onDisable(true);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            metrics: nextProps.metrics,
            order: nextProps.order,
        })
    }

    functions = {
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        },

        getMetrics: async () => {
            return new Promise(async (resolve) => {

                try {
                    let response = await this.calls.getMetrics();
                    await this.promisedSetState({
                        metrics_options: { calculated: response.data.calculated.filter((item) => { return item.channels && item.calculation_options.length > 0 }), custom: response.data.custom.filter((item) => { return item.channels }) },
                        loading_metrics: false
                    })
                } catch (error) {
                    console.log(error)
                }
                resolve();
            })
        },
        calculatedMetric: (metric) => {
            let new_string = JSON.parse(JSON.stringify(metric.calculation));
            for (let key in metric.metrics) {
                new_string = new_string.replace("<" + key + ">", metric.metrics[key].name);
            }
            return new_string;
        },
        create: async () => {
            try {
                let body = {
                    order_id: this.state.order.id,
                    metrics: this.state.selected_metrics,
                };
                let response = await this.calls.update(body);
                if (response.message == "Order updated!") {
                    this.props.onUpdate(this.state.selected_metrics);
                }
            } catch (error) {
            }
        }
    };

    calls = {
        getMetrics: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/metrics?custom=true";
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderMetrics";
            return apiRegister.call(options, url);
        },
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                {
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">


                                    {
                                        true &&
                                        <div className={"col-span-12 px-6"}>
                                            <div className="flex flex-row">
                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <CogIcon className="w-5 h-5 " />
                                                </div>
                                                <div className="flex flex-1 flex-col justify-center ml-3">
                                                    <div className="font-semibold text-sm">Select action</div>
                                                    {
                                                        this.state.type.value &&
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.type.name}</div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.type.value &&
                                                        <div className="font-medium text-xs text-gray-500">Select report type</div>
                                                    }
                                                </div>
                                                <div className='flex'>
                                                    {
                                                        this.state.type.value &&
                                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                            <CheckIcon className="w-5 h-5 " />
                                                        </div>
                                                    }
                                                    <div>
                                                        <div onClick={() => {
                                                            this.state.open = {
                                                                type: !this.state.open.type,
                                                                metrics_custom: false,
                                                                metrics_calculated: false
                                                            };
                                                            this.setState({
                                                                open: this.state.open
                                                            });

                                                        }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.type ? "opacity-100" : "opacity-50"}`}>
                                                            {
                                                                !this.state.open.type &&
                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                this.state.open.type &&
                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* SELECT ACTION */}

                                            <SlideDown
                                                closed={!this.state.open.type}
                                            >
                                                <div className="mt-4 grid-cols-12 grid gap-4">
                                                    {
                                                        [
                                                            { name: `Default metrics`, value: "default", description: "Set default merics" },
                                                            { name: "Custom metrics", value: "custom", description: "Set custom metrics" }
                                                        ].map((item, index) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    if (item.value !== "custom") {
                                                                        this.state.selected_metrics = {};
                                                                        this.props.onDisable(false);
                                                                    } else {
                                                                        this.state.open.type = false;
                                                                        this.state.open.metrics_custom = true;
                                                                        this.state.open.metrics_calculated = false;
                                                                        this.props.onDisable(true);
                                                                    }
                                                                    this.setState({
                                                                        type: item,
                                                                        open: this.state.open
                                                                    });
                                                                }} className={"cursor-pointer " + (this.state.type.value === item.value ? "bg-purple-100 border-purple-500" : "border-gray-300 bg-custom-input") + " flex flex-col col-span-6 relative p-4 rounded-md border-1.5 "}>
                                                                    <div className={(this.state.type.value === item.value ? "text-purple-500" : "") + " text-sm font-semibold mb-1"}>
                                                                        {item.name}
                                                                    </div>
                                                                    <div className={(this.state.type.value === item.value ? "text-gray-700" : "text-gray-500") + " text-sm font-medium "}>
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.type.value && this.state.type.value === "custom" &&
                                                        <div className="col-span-12 flex items-center justify-center">
                                                            <div onClick={async () => {
                                                                if (this.state.type.value) {
                                                                    if (this.state.type.value === "custom") {
                                                                        this.state.open.metrics_custom = true;
                                                                    }
                                                                    this.state.open.type = false;
                                                                    this.state.open.metrics_calculated = false;

                                                                    this.setState({
                                                                        open: this.state.open
                                                                    });
                                                                }
                                                            }}
                                                                className={(this.state.type.value ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                <span style={{ whiteSpace: "nowrap" }}
                                                                    className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                    Next step
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        this.state.type.value &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }
                                    {
                                        this.state.type.value === "custom" &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <AdjustmentsIcon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div
                                                            className="font-semibold text-sm">Custom</div>
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.custom.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length > 0 &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                You have selected <span className="text-purple-500 mx-1">{this.state.metrics_options.custom.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length} metrics</span>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.custom.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length < 1 &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                You have {this.state.metrics_options.custom.length} metrics avalible
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.custom.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length > 0 &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    metrics_custom: !this.state.open.metrics_custom,
                                                                    type: false
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.metrics_custom ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.metrics_custom &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.metrics_custom &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.metrics_custom}
                                                >
                                                    <div className="mt-4">
                                                        <div className="flex flex-1 flex-col justify-center mb-2">
                                                            <InputTailwind
                                                                label={""}
                                                                placeholder={"Search metric ..."}
                                                                value={this.state.categories_v2.custom.search}
                                                                onChange={async (value) => {
                                                                    this.state.categories_v2.custom.search = value;
                                                                    this.setState({
                                                                        categories_v2: this.state.categories_v2
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.metrics_options.custom && this.functions.search(this.state.categories_v2.custom.search, this.state.metrics_options.custom).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className="flex flex-row">
                                                                        <div
                                                                            className={"cursor-pointer mb-2 flex flex-1 flex-row justify-center"}>

                                                                            <div onClick={async () => {
                                                                                if (!this.state.selected_metrics) {
                                                                                    this.state.selected_metrics = {};
                                                                                }
                                                                                if (item.id in this.state.selected_metrics) {
                                                                                    delete this.state.selected_metrics[item.id];
                                                                                    if (Object.keys(this.state.selected_metrics).length == 0) {
                                                                                        this.props.onDisable(true);
                                                                                    } else {
                                                                                        this.props.onDisable(false);
                                                                                    }
                                                                                } else {
                                                                                    //this.state.selected_metrics = {};
                                                                                    item.metric_type = "custom";
                                                                                    this.state.selected_metrics[item.id] = item;
                                                                                    this.props.onDisable(false);
                                                                                }
                                                                                await this.promisedSetState({
                                                                                    selected_metrics: this.state.selected_metrics
                                                                                });
                                                                            }}
                                                                                className={(this.state.selected_metrics && this.state.selected_metrics[item.id] ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                {
                                                                                    this.state.selected_metrics && this.state.selected_metrics[item.id] &&

                                                                                    <CheckIcon className="h-5 w-5" />

                                                                                }
                                                                            </div>


                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xxs capitalize">
                                                                                    {
                                                                                        item.channels &&
                                                                                        Object.keys(item.channels).map((channel, index) => {
                                                                                            let last = index === (Object.keys(item.channels).length - 1);
                                                                                            return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                        })
                                                                                    }
                                                                                    {/*
                                                                                        category.name == "calculated" &&
                                                                                        <span>{this.functions.calculatedMetric(item)}</span>
                                                                                */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )

                                                        })
                                                    }
                                                </SlideDown>
                                            </div>
                                            <div className="col-span-12">
                                                <div className="border-b w-full"></div>
                                            </div>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <ViewGridAddIcon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div
                                                            className="font-semibold text-sm">Calculated</div>
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.calculated.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length > 0 &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                You have selected <span className="text-purple-500 mx-1">{this.state.metrics_options.calculated.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length} metrics</span>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.calculated.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length < 1 &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                You have {this.state.metrics_options.calculated.length} metrics avalible
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.selected_metrics && this.state.metrics_options.calculated.filter((item) => { return Object.keys(this.state.selected_metrics).includes(item.id) }).length > 0 &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    metrics_calculated: !this.state.open.metrics_calculated,
                                                                    metrics_custom: false,
                                                                    type: false
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.metrics_calculated ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.metrics_calculated &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.metrics_calculated &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.metrics_calculated}
                                                >
                                                    <div className="mt-4">
                                                        <div className="flex flex-1 flex-col justify-center mb-2">
                                                            <InputTailwind
                                                                label={""}
                                                                placeholder={"Search metric ..."}
                                                                value={this.state.categories_v2.calculated.search}
                                                                onChange={async (value) => {
                                                                    this.state.categories_v2.calculated.search = value;
                                                                    this.setState({
                                                                        categories_v2: this.state.categories_v2
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.metrics_options.calculated && this.functions.search(this.state.categories_v2.calculated.search, this.state.metrics_options.calculated).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className="flex flex-row">
                                                                        <div
                                                                            className={"cursor-pointer mb-2 flex flex-1 flex-row justify-center"}>

                                                                            <div onClick={async () => {
                                                                                if (!this.state.selected_metrics) {
                                                                                    this.state.selected_metrics = {};
                                                                                }
                                                                                if (item.id in this.state.selected_metrics) {
                                                                                    delete this.state.selected_metrics[item.id];
                                                                                    if (Object.keys(this.state.selected_metrics).length == 0) {
                                                                                        this.props.onDisable(true);
                                                                                    } else {
                                                                                        this.props.onDisable(false);
                                                                                    }
                                                                                } else {
                                                                                    //this.state.selected_metrics = {};
                                                                                    item.metric_type = "calculated";
                                                                                    this.state.selected_metrics[item.id] = item;
                                                                                    this.props.onDisable(false);
                                                                                }
                                                                                await this.promisedSetState({
                                                                                    selected_metrics: this.state.selected_metrics
                                                                                });
                                                                            }}
                                                                                className={(this.state.selected_metrics && this.state.selected_metrics[item.id] ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-3 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                {
                                                                                    this.state.selected_metrics && this.state.selected_metrics[item.id] &&

                                                                                    <CheckIcon className="h-5 w-5" />

                                                                                }
                                                                            </div>


                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xxs capitalize">
                                                                                    <span>{this.functions.calculatedMetric(item)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )

                                                        })
                                                    }
                                                </SlideDown>
                                            </div>

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

export default CustomizeOrderMetrics;
