import React, { Component, Fragment } from 'react';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind'
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
var moment = require('moment');

class SyncWithBing extends Component {

	constructor(props) {
		super(props);
		this.state = {
			clinet: {},
			campaign: {},
			budget: null,
			budgetOptions: [{ name: "Increase" }, { name: "Decrease" }],
			budgetOption: { name: "Increase" },
			schedule: { "name": "Daily" },
			date: new Date(),
			time: { name: `${moment().format('HH')}:00`, value: moment().format('HH') },
			week: { name: moment().format('dddd'), value: moment().day() },
			day: { name: moment().format('DD'), value: moment().format('DD') },
			timeZone: {
				"name": "Amsterdam, Berlin, Bern, Rome, Stockholm, and Vienna time zone. UTC+1",
				"value": "AmsterdamBerlinBernRomeStockholmVienna"
			},
			name: "",
			weekOptions: [
				{ name: 'Sunday', value: 0 },
				{ name: 'Monday', value: 1 },
				{ name: 'Tuesday', value: 2 },
				{ name: 'Wednesday', value: 3 },
				{ name: 'Thursday', value: 4 },
				{ name: 'Friday', value: 5 },
				{ name: 'Saturday', value: 6 }
			],
			daysOfMonthOptions: Array.from({ length: 28 }, (_, index) => ({ name: (index + 1) })),
			timeOptions: Array.from({ length: 24 }, (_, index) => ({ name: `${index.toString().padStart(2, '0')}:00`, value: index.toString().padStart(2, '0') })),
			timezoneOptions: [
				{
					"name": "Abu Dhabi and Muscat time zone. (UTC+4)",
					"value": "AbuDhabiMuscat"
				},
				{
					"name": "Adelaide, Australia time zone. (UTC+9.5)",
					"value": "Adelaide"
				},
				{
					"name": "Alaska time zone. (UTC-9)",
					"value": "Alaska"
				},
				{
					"name": "Almaty and Novosibirsk time zone. (UTC+6)",
					"value": "AlmatyNovosibirsk"
				},
				{
					"name": "Amsterdam, Berlin, Bern, Rome, Stockholm, and Vienna time zone. (UTC+1)",
					"value": "AmsterdamBerlinBernRomeStockholmVienna"
				},
				{
					"name": "Arizona time zone. (UTC-7)",
					"value": "Arizona"
				},
				{
					"name": "Astana and Dhaka time zone. (UTC+6)",
					"value": "AstanaDhaka"
				},
				{
					"name": "Athens, Istanbul, Beirut, and Minsk time zone. (UTC+2)",
					"value": "AthensBuckarestIstanbul"
				},
				{
					"name": "Atlantic Time and Canada time zone. (UTC-4)",
					"value": "AtlanticTimeCanada"
				},
				{
					"name": "Auckland and Wellington, New Zealand time zone. (UTC+12)",
					"value": "AucklandWellington"
				},
				{
					"name": "Azores time zone. (UTC-1)",
					"value": "Azores"
				},
				{
					"name": "Baghdad time zone. (UTC+3)",
					"value": "Baghdad"
				},
				{
					"name": "Baku, Tbilisi, and Yerevan time zone. (UTC+4)",
					"value": "BakuTbilisiYerevan"
				},
				{
					"name": "Bangkok, Hanoi, and Jakarta time zone. (UTC+7)",
					"value": "BangkokHanoiJakarta"
				},
				{
					"name": "Beijing, Chongqing, Hong Kong SAR, and Urumqi time zone. (UTC+8)",
					"value": "BeijingChongqingHongKongUrumqi"
				},
				{
					"name": "Belgrade, Bratislava, Budapest, Ljubljana, and Prague time zone. (UTC+1)",
					"value": "BelgradeBratislavaBudapestLjubljanaPrague"
				},
				{
					"name": "Bogota, Lima, and Quito time zone. (UTC-5)",
					"value": "BogotaLimaQuito"
				},
				{
					"name": "Brasilia time zone. (UTC-3)",
					"value": "Brasilia"
				},
				{
					"name": "Brisbane time zone. (UTC+10)",
					"value": "Brisbane"
				},
				{
					"name": "Brussels, Copenhagen, Madrid, and Paris time zone. (UTC+1)",
					"value": "BrusselsCopenhagenMadridParis"
				},
				{
					"name": "Bucharest time zone. (UTC+2)",
					"value": "Bucharest"
				},
				{
					"name": "Buenos Aires and Georgetown time zone. (UTC-3)",
					"value": "BuenosAiresGeorgetown"
				},
				{
					"name": "Cairo time zone. (UTC+2)",
					"value": "Cairo"
				},
				{
					"name": "Canberra, Melbourne, and Sydney time zone. (UTC+10)",
					"value": "CanberraMelbourneSydney"
				},
				{
					"name": "Cape Verde Island time zone. (UTC-1)",
					"value": "CapeVerdeIsland"
				},
				{
					"name": "Caracas and La Paz (Bolivia) time zone. (UTC-4)",
					"value": "CaracasLaPaz"
				},
				{
					"name": "Casablanca and Monrovia time zone. (UTC+0)",
					"value": "CasablancaMonrovia"
				},
				{
					"name": "Central America time zone. (UTC-6)",
					"value": "CentralAmerica"
				},
				{
					"name": "Central Time U.S. and Canada time zone. (UTC-6)",
					"value": "CentralTimeUSCanada"
				},
				{
					"name": "Chennai, Kolkata, Mumbai, and New Delhi time zone. (UTC+5.5)",
					"value": "ChennaiKolkataMumbaiNewDelhi"
				},
				{
					"name": "Chihuahua, La Paz (Mexico), and Mazatlan time zone. (UTC-7)",
					"value": "ChihuahuaLaPazMazatlan"
				},
				{
					"name": "Darwin time zone. (UTC+9.5)",
					"value": "Darwin"
				},
				{
					"name": "Eastern Time U.S. and Canada time zone. (UTC-5)",
					"value": "EasternTimeUSCanada"
				},
				{
					"name": "Ekaterinburg time zone. (UTC+5)",
					"value": "Ekaterinburg"
				},
				{
					"name": "Fiji, Kamchatka, and Marshall Island time zone. (UTC+12)",
					"value": "FijiKamchatkaMarshallIsland"
				},
				{
					"name": "Greenland time zone. (UTC-3)",
					"value": "Greenland"
				},
				{
					"name": "Greenwich Mean Time, Dublin, Edinburgh, Lisbon, and London time zone. (UTC+0)",
					"value": "GreenwichMeanTimeDublinEdinburghLisbonLondon"
				},
				{
					"name": "Guadalajara, Mexico City, and Monterrey time zone. (UTC-6)",
					"value": "GuadalajaraMexicoCityMonterrey"
				},
				{
					"name": "Guam and Port Moresby time zone. (UTC+10)",
					"value": "GuamPortMoresby"
				},
				{
					"name": "Harare and Pretoria time zone. (UTC+2)",
					"value": "HararePretoria"
				},
				{
					"name": "Hawaii time zone. (UTC-10)",
					"value": "Hawaii"
				},
				{
					"name": "Helsinki, Kyiv, Riga, Sofia, Tallinn, and Vilnius time zone. (UTC+2)",
					"value": "HelsinkiKyivRigaSofiaTallinnVilnius"
				},
				{
					"name": "Hobart time zone. (UTC+10)",
					"value": "Hobart"
				},
				{
					"name": "Indiana (East) time zone. (UTC-5)",
					"value": "IndianaEast"
				},
				{
					"name": "International Date Line (West) time zone. (UTC-12)",
					"value": "InternationalDateLineWest"
				},
				{
					"name": "Irkutsk and Ulaanbaatar time zone. (UTC+8)",
					"value": "IrkutskUlaanBataar"
				},
				{
					"name": "Islamabad, Karachi, and Tashkent time zone. (UTC+5)",
					"value": "IslandamabadKarachiTashkent"
				},
				{
					"name": "Jerusalem time zone. (UTC+2)",
					"value": "Jerusalem"
				},
				{
					"name": "Kabul time zone. (UTC+4.5)",
					"value": "Kabul"
				},
				{
					"name": "Kathmandu time zone. (UTC+5.75)",
					"value": "Kathmandu"
				},
				{
					"name": "Krasnoyarsk time zone. (UTC+7)",
					"value": "Krasnoyarsk"
				},
				{
					"name": "Kuala Lumpur and Singapore time zone. (UTC+8)",
					"value": "KualaLumpurSingapore"
				},
				{
					"name": "Kuwait and Riyadh time zone. (UTC+3)",
					"value": "KuwaitRiyadh"
				},
				{
					"name": "Mid-Atlantic time zone. (UTC-2)",
					"value": "MidAtlantic"
				},
				{
					"name": "Midway Island and Samoa time zone. (UTC-11)",
					"value": "MidwayIslandAndSamoa"
				},
				{
					"name": "Moscow, St Petersburg, and Volgograd time zone. (UTC+3)",
					"value": "MoscowStPetersburgVolgograd"
				},
				{
					"name": "Mountain Time U.S. and Canada time zone. (UTC-7)",
					"value": "MountainTimeUSCanada"
				},
				{
					"name": "Nairobi time zone. (UTC+3)",
					"value": "Nairobi"
				},
				{
					"name": "Newfoundland time zone. (UTC-3.5)",
					"value": "Newfoundland"
				},
				{
					"name": "Nuku'alofa time zone. (UTC+13)",
					"value": "Nukualofa"
				},
				{
					"name": "Osaka, Sapporo, and Tokyo time zone. (UTC+9)",
					"value": "OsakaSapporoTokyo"
				},
				{
					"name": "Pacific Time, U.S. and Canada, and Tijuana time zone. (UTC-8)",
					"value": "PacificTimeUSCanadaTijuana"
				},
				{
					"name": "Perth time zone. (UTC+8)",
					"value": "Perth"
				},
				{
					"name": "Rangoon time zone. (UTC+6.5)",
					"value": "Rangoon"
				},
				{
					"name": "Santiago time zone. (UTC-4)",
					"value": "Santiago"
				},
				{
					"name": "Sarajevo, Skopie, Warsaw, and Zagreb time zone. (UTC+1)",
					"value": "SarajevoSkopjeWarsawZagreb"
				},
				{
					"name": "Saskatchewan time zone. (UTC-6)",
					"value": "Saskatchewan"
				},
				{
					"name": "Seoul time zone. (UTC+9)",
					"value": "Seoul"
				},
				{
					"name": "Magadan, Solomon Islands, and New Caledonia time zone. (UTC+11)",
					"value": "SolomonIslandNewCaledonia"
				},
				{
					"name": "Sri Jayawardenepura time zone. (UTC+5.5)",
					"value": "SriJayawardenepura"
				},
				{
					"name": "Taipei time zone. (UTC+8)",
					"value": "Taipei"
				},
				{
					"name": "Tehran time zone. (UTC+3.5)",
					"value": "Tehran"
				},
				{
					"name": "Vladivostok time zone. (UTC+10)",
					"value": "Vladivostok"
				},
				{
					"name": "West Central Africa time zone. (UTC+1)",
					"value": "WestCentralAfrica"
				},
				{
					"name": "Yakutsk time zone. (UTC+9)",
					"value": "Yakutsk"
				}
			],
			notificationType: { name: "no emails", value: "None" },
			notificationOptions: [
				{ name: "no emails", value: "None" },
				{ name: "Only if there are changes or errors", value: "Changes" },
				{ name: "Only if there are errors", value: "Errors" },
				{ name: "Every time this import runs", value: "EveryTime" },
			],
			email: "",
			inputRef: "",
			error: false
		}
	};

	componentDidMount() {
		this.setState({
			name: this.props.campaign.name ? this.props.campaign.name : "",
			client: this.props.client,
			campaign: this.props.campaign,
			inputRef: React.createRef()
		})
	}

	componentWillReceiveProps(nextProps) {

	}

	functions = {
		syncWitnBing: async () => {
			this.props.onLoader(true);
			await this.promisedSetState({ error: false });
			try {
				await this.calls.update({
					budget: this.functions.calculatedBudget(),
					name: this.state.name,
					currentName: this.state.campaign.name,
					schedule: this.state.schedule.name,
					date: this.state.date,
					time: this.state.time.value,
					week: this.state.week.value,
					dayOfMonth: this.state.day.value,
					timeZone: this.state.timeZone.value,
					notificationType: this.state.notificationType.value,
					email: this.state.email,
					campaignId: this.state.campaign.id
				});
				this.props.onSuccess({ currentCampaign: this.state.campaign, importedCampaign: { name: this.state.name, status: 'syncing', channel: "bing", importId: this.state.campaign.id } });
			} catch (error) {
				this.setState({
					error: error.body ? error.body.message : "Something went wrong"
				});
			}
			this.props.onLoader(false);
		},
		calculatedBudget: () => {
			if (+this.state.budget > +this.state.campaign.budget) {
				return ((100 * +this.state.budget) / +this.state.campaign.budget) - 100;
			} else if (this.state.budget > +this.state.campaign.budget) {
				return 0;
			} else {
				return -1 * (100 - (100 * +this.state.budget) / +this.state.campaign.budget);
			}
		},
		getIdFromPath: () => {
			try {
				return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
			} catch (error) {
				return null
			}
		},
	};

	calls = {
		update: (data) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/bing/google/import?client=" + this.state.client.id + "&orderId=" + this.functions.getIdFromPath();
			return apiRegister.call(options, url);
		}
	};

	renders = {
		minimumDailyBudget: () => {
			let value = "-";
			if (this.state.campaign && this.state.campaign.budget) {
				value = this.state.campaign.budget - ((this.state.campaign.budget / 100) * 90);
			}
			return value;
		},
		calculatedBudget: () => {
			return (100 * +this.state.budget) / +this.state.campaign.budget;
		},
	};

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	render() {
		return (
			<div className="w-full grid grid-cols-12 gap-4">
				<div className="col-span-12">
					<InputTailwind
						label={"Name"}
						required="required"
						value={this.state.name}
						onChange={async (value) => {
							this.setState({
								name: value
							});
						}}
					/>
				</div>
				<div className="col-span-6">
					<InputTailwind
						label={"Daily budget (" + this.renders.calculatedBudget() + "% of Google daily budget)"}
						value={this.state.budget}
						required="required"
						onChange={async (value) => {
							this.setState({
								budget: value
							});
						}}
					/>
				</div>
				<div className="col-span-6">
					<DropdownTailwind
						searchInput={false}
						label={"Set Schedule"}
						selected={this.state.schedule}
						options={[{ "name": "Now" }, { "name": "Once" }, { "name": "Daily" }, { "name": "Weekly" }, { "name": "Monthly" }]}
						placeholder={"Search Schedule"}
						onChange={async (value) => {
							this.setState({
								schedule: value
							});
						}}
					/>
				</div>
				<div className={"col-span-12"}>
					<div className="bg-blue-100 p-4 rounded-md">
						<div className="text-sm text-blue-500">
							Minimum daily budget is {this.renders.minimumDailyBudget()}
						</div>
					</div>
				</div>
				{
					!['Auto', 'Now'].includes(this.state.schedule.name) &&
					<div className="col-span-12 grid grid-cols-12 gap-4">
						{
							this.state.schedule.name === 'Once' &&
							<div className="col-span-12">
								<InputDatepickerTailwind
									label={"Date"}
									value={moment(new Date()).format("YYYY-MM-DD")}
									onChange={(value) => {
										this.setState({
											date: value
										});
									}}
								/>
							</div>
						}
						{
							this.state.schedule.name === 'Weekly' &&
							<div className="col-span-12">
								<DropdownTailwind
									searchInput={true}
									label={"Week"}
									selected={this.state.week}
									options={this.state.weekOptions}
									placeholder={"Select day of the week"}
									onChange={async (value) => {
										this.setState({
											week: value
										});
									}}
								/>
							</div>
						}
						{
							this.state.schedule.name === 'Monthly' &&
							<div className="col-span-12">
								<DropdownTailwind
									searchInput={true}
									label={"Day of the Month"}
									selected={this.state.day}
									options={this.state.daysOfMonthOptions}
									placeholder={"Select day of the month"}
									onChange={async (value) => {
										this.setState({
											day: value
										});
									}}
								/>
							</div>
						}
						<div className="col-span-6">
							<DropdownTailwind
								searchInput={true}
								label={"At (24-hours)"}
								selected={this.state.time}
								options={this.state.timeOptions}
								placeholder={"Select time"}
								onChange={async (value) => {
									this.setState({
										time: value
									});
								}}
							/>
						</div>
						<div className="col-span-6">
							<DropdownTailwind
								searchInput={true}
								label={"Time Zone"}
								selected={this.state.timeZone}
								options={this.state.timezoneOptions}
								placeholder={"Select time"}
								onChange={async (value) => {
									this.setState({
										timeZone: value
									});
								}}
							/>
						</div>
					</div>
				}
				<div className="col-span-12">
					<DropdownTailwind
						searchInput={false}
						label={"Receive email notifications"}
						selected={this.state.notificationType}
						options={this.state.notificationOptions}
						placeholder={"Search Schedule"}
						onChange={async (value) => {
							this.setState({
								notificationType: value
							});
						}}
					/>
				</div>
				{
					this.state.notificationType.value !== 'None' &&
					<div className="col-span-12">
						<InputTailwind
							label={"Send report to"}
							type="email"
							required="required"
							value={this.state.email}
							onChange={async (value) => {
								this.setState({
									email: value
								});
							}}
						/>
					</div>
				}
				{
					this.state.error &&
					<div className={"col-span-12"}>
						<div className="bg-red-100 p-4 rounded-md">
							<div className="text-sm text-red-500">
								{this.state.error}
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default SyncWithBing;
