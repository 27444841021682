import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon, LockClosedIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class TagManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            order: {},
            report: {},
            campaign: {},
            label: '',
            tags: [],
            tagType: "",
            new_tag: '',
            page: 1,
            limit: 100,
            search: '',
            suggestions: [],
            show_suggestions: false,
            selected_suggestion_index: null,
            error: { show: false, message: '' },
            loading: {
                save: false,
                delete: false,
                delete_id: null
            }
        }
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            order: this.props.order,
            label: this.props.label,
            campaign: this.props.campaign,
            tags: this.props.tags,
            tagType: this.props.tagType,
            new_tag: '',
            page: 1,
            limit: 100,
            search: '',
            suggestions: [],
            show_suggestions: false,
            error: { show: false, message: '' },
            loading: {
                save: false,
                delete: false,
                delete_id: null
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            client: nextProps.client ? nextProps.client : {},
            order: nextProps.order ? nextProps.order : {},
            report: nextProps.report ? nextProps.report : {},
            campaign: nextProps.campaign ? nextProps.campaign : {},
            label: nextProps.label ? nextProps.label : 'Add new tag',
            tags: nextProps.tags ? nextProps.tags : [],
            tagType: nextProps.tagType ? nextProps.tagType : 'client',
        })
    }

    functions = {
        save: async (tag) => {
            if (this.state.loading.save || this.state.loading.delete || (this.state.new_tag.length === 0 && !tag)) {
                return
            }
            if (this.state.tagType === 'report' || this.state.tagType === 'component') {
                if (tag) {
                    let new_tag = JSON.parse(JSON.stringify(tag));
                    new_tag.created = new Date();
                    this.state.tags.push(tag);
                } else {
                    this.state.tags.push({
                        name: this.state.new_tag,
                        created: new Date(),
                        id: Math.floor(Math.random() * 9999999)
                    });
                }
                await this.promisedSetState({ tags: this.state.tags, new_tag: '', loading: this.state.loading, show_suggestions: false, suggestions: [] });
                if (this.props.onUpdateTags) {
                    this.props.onUpdateTags(this.state.tags);
                }
            } else {
                try {
                    this.state.loading.save = true;
                    await this.promisedSetState({ loading: this.state.loading });
                    let response = await this.calls.save({
                        ...tag && { tag: tag },
                        name: this.state.new_tag,
                        tagType: this.state.tagType
                    });
                    this.state.loading.save = false;
                    await this.promisedSetState({
                        tags: Array.isArray(response.data) ? response.data : this.state.tags,
                        new_tag: '',
                        loading: this.state.loading,
                        show_suggestions: false,
                        suggestions: []
                    });
                    if (this.props.onUpdateTags) {
                        this.props.onUpdateTags(this.state.tags);
                    }
                } catch (error) {
                    this.state.loading.save = false;
                    await this.promisedSetState({ loading: this.state.loading });
                }
            }
        },
        delete: async (id) => {
            if (this.state.loading.save) {
                return
            }
            if (this.state.loading.delete) {
                return
            }
            if (this.state.tagType === 'report' || this.state.tagType === 'component') {
                this.state.tags = this.state.tags.filter((item) => {
                    return item.id !== id;
                });
                await this.promisedSetState({ tags: this.state.tags });
                if (this.props.onUpdateTags) {
                    this.props.onUpdateTags(this.state.tags);
                }
            } else {
                try {
                    this.state.loading.delete = true;
                    this.state.loading.delete_id = id;
                    await this.promisedSetState({ loading: this.state.loading });
                    let response = await this.calls.delete(id);
                    if (response.data) {
                        this.state.loading.delete = false;
                        this.state.loading.delete_id = null;
                        await this.promisedSetState({ tags: response.data, loading: this.state.loading });
                        if (this.props.onUpdateTags) {
                            this.props.onUpdateTags(this.state.tags);
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        handleOnChange: async (e) => {
            this.state.new_tag = e.target.value;
            this.state.search = e.target.value;
            await this.promisedSetState({ new_tag: this.state.new_tag, search: this.state.search });
            if (this.state.new_tag.length > 0) {
                this.functions.getTags();
            } else if (this.state.new_tag.length == 0 || this.state.new_tag == "") {
                this.promisedSetState({ suggestions: [] });
            }
        },
        getTags: async () => {
            try {
                let res = await this.calls.getTags();
                if (!(Array.isArray(res.data.all_tags) && res.data.all_tags.length > 0)) {
                    this.promisedSetState({ suggestions: [] });
                } else if (Array.isArray(res.data.all_tags)) {
                    let tags = res.data.all_tags.filter((item) => {
                        return !item.channel_tag;
                    });
                    await this.promisedSetState({ suggestions: tags, show_suggestions: true });
                }
            } catch (error) { }
        },
        selectSuggestion: async (suggestion) => {
            this.functions.save(suggestion);
        }
    }

    calls = {
        save: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url;
            if (this.props.tagType === 'client') {
                url = apiRegister.url.api + "/v3/adcredo/createTagClient?client=" + this.props.client.id;
            } else if (this.props.tagType === 'order') {
                url = apiRegister.url.api + "/v3/adcredo/createTagOrder?order=" + this.props.order.id;
            } else if (this.props.tagType === 'campaign') {
                url = apiRegister.url.api + "/v3/adcredo/createTagCampaign?campaign=" + this.state.campaign.internal;
            }
            return apiRegister.call(options, url);
        },
        delete: async (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url;
            if (this.state.tagType === 'client') {
                url = apiRegister.url.api + "/v3/adcredo/deleteTagClient?client=" + this.state.client.id + "&id=" + id;
            } else if (this.state.tagType === 'order') {
                url = apiRegister.url.api + "/v3/adcredo/deleteTagOrder?order=" + this.state.order.id + "&id=" + id;
            } else if (this.props.tagType === 'campaign') {
                url = apiRegister.url.api + "/v3/adcredo/deleteTagCampaign?campaign=" + this.state.campaign.internal + "&id=" + id;
            }
            return apiRegister.call(options, url);
        },
        getTags: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url;
            if (this.props.tagType === "client") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsClients?limit=" + this.state.limit + "&page=" + this.state.page + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            } else if (this.props.tagType === "order" || this.props.tagType === "report") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsOrders?limit=" + this.state.limit + "&page=" + this.state.page + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.props.client && this.props.client.id !== 0 ? "&client=" + this.props.client.id : "");
            } else if (this.props.tagType === "component") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsReports?limit=" + this.state.limit + "&page=" + this.state.page + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.props.client && this.props.client.id !== 0 ? "&client=" + this.props.client.id : "");
            }
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            })
        })
    }

    render() {
        return (
            <>
                <div className='h-full w-full'>
                    <div className='w-full relative'>
                        <div className='flex items-center'>
                            <label className='mb-1 text-xs font-medium text-gray-700'>
                                {this.props.label}
                            </label>
                            {
                                this.state.error.show &&
                                <div className='flex-grow flex ml-2 items-center text-red-500 text-xs'>
                                    <ExclamationCircleIcon className='h-4 w-4 mr-1' />
                                    {this.state.error.message}
                                </div>
                            }
                        </div>
                        <div className='flex w-full relative'>
                            <input
                                onBlur={() => {
                                    this.setState({
                                        show_suggestions: false
                                    })
                                }}
                                onFocus={() => {
                                    if (this.state.new_tag !== "") {
                                        this.functions.getTags();
                                    }
                                    this.setState({
                                        show_suggestions: true
                                    })
                                }}
                                value={this.state.new_tag}
                                onChange={async (e) => {
                                    this.functions.handleOnChange(e);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        this.functions.save();
                                    }
                                }}
                                type="text"
                                className={`focus:ring-estatery-600 focus:border-purple-500 border-gray-300 rounded-l-md h-12 block w-full bg-custom-input border-1.5 px-2 py-2 focus:outline-none sm:text-sm ${this.state.error.show && "border-red-500"}`}
                            />
                            <button
                                disabled={this.state.loading.save}
                                onClick={() => {
                                    this.functions.save();
                                }}
                                className={`relative flex flex-col justify-center items-center bg-purple-500  whitespace-no-wrap hover:bg-purple-600 rounded-r-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 cursor-pointer ${this.state.error.show && "border-red-500"}`}
                            >
                                <span className={`${this.state.loading.save ? "opacity-0" : "opacity-100"}`}>Add tag</span>
                                {
                                    this.state.loading.save &&
                                    <div className='absolute inset-0 flex items-center justify-center'>
                                        <div
                                            style={{ borderTopColor: "transparent" }}
                                            class="w-3 h-3 border-2 border-white border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                        {
                            ((this.props.tagType == "component" && this.state.suggestions.length > 0 && this.state.new_tag !== "") || (this.props.tagType != "component" && (this.state.show_suggestions || this.state.hover_suggestions))) &&
                            <div
                                onMouseEnter={() => {
                                    this.setState({
                                        hover_suggestions: true
                                    })
                                }}
                                onMouseLeave={() => {
                                    this.setState({
                                        hover_suggestions: false
                                    })
                                }}
                                className={`absolute border-gray-300 rounded-md left-0 right-0 mt-1 bg-custom-input border-1.5 px-2 py-2 sm:text-sm z-60`}>
                                {
                                    <div className='flex flex-col'>
                                        {
                                            this.props.tagType !== "component" &&
                                            this.state.suggestions.length > 0 &&
                                            <div className="text-xxs mb-1">Custom tags</div>
                                        }
                                        <ul
                                            id="suggestion-list"
                                            className='flex flex-wrap w-full'>
                                            {
                                                Array.isArray(this.state.suggestions) &&
                                                this.state.suggestions.map((suggestion, index) => {
                                                    return (
                                                        <li
                                                            id={`suggestion-${index}`}
                                                            key={index}
                                                            onPointerDown={() => {
                                                                this.functions.save(suggestion);
                                                            }}
                                                            className={`cursor-pointer px-2 py-1 hover:bg-gray-100 rounded-md font-medium text-sm ${index === this.state.selected_suggestion_index ? "bg-gray-100" : ""}`}>
                                                            {suggestion.name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        {
                                            this.props.tagType !== "component" &&
                                            this.state.suggestions.length > 0 &&
                                            <div className="w-full border-b mt-2 mb-2"></div>
                                        }
                                        {
                                            this.props.tagType !== "component" &&
                                            <div className="text-xxs mb-1">Channel tags</div>
                                        }
                                        {
                                            this.props.tagType !== "component" &&
                                            <ul
                                                id="suggestion-list"
                                                className='flex flex-wrap w-full'>
                                                {
                                                    [
                                                        { channel: "adform", id: "adform", name: "Adform", value: "adform", channel_tag: true },
                                                        { channel: "google", id: "google", name: "Google", value: "google", channel_tag: true },
                                                        { channel: "google", id: "google_search", name: "Google Search", value: "google_search", channel_tag: true },
                                                        { channel: "google", id: "google_shopping", name: "Google Shopping", value: "google_shopping", channel_tag: true },
                                                        { channel: "google", id: "google_display", name: "Google Display", value: "google_display", channel_tag: true },
                                                        { channel: "facebook", id: "facebook", name: "Facebook", value: "facebook", channel_tag: true },
                                                        { channel: "bing", id: "bing", name: "Bing", value: "bing", channel_tag: true },
                                                        { channel: "linkedin", id: "linkedin", name: "Linkedin", value: "linkedin", channel_tag: true },
                                                        { channel: "snapchat", id: "snapchat", name: "Snapchat", value: "snapchat", channel_tag: true },
                                                        { channel: "tiktok", id: "tiktok", name: "TikTok", value: "tiktok", channel_tag: true },
                                                        { channel: "dv360", id: "dv360", name: "DV360", value: "dv360", channel_tag: true },
                                                        { channel: "google_analytics_4", id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4", channel_tag: true },
                                                        { channel: "bidtheatre", id: "bidtheatre", name: "Bidtheatre", value: "bidtheatre", channel_tag: true },
                                                        { channel: "twitter", id: "twitter", name: "Twitter", value: "twitter", channel_tag: true }
                                                    ].filter((item) => {
                                                        return this.state.tags.filter((tag) => { return tag.name == item.name }).length < 1
                                                    }).map((suggestion, index) => {
                                                        return (
                                                            <li
                                                                id={`suggestion-${index}`}
                                                                key={index}
                                                                onPointerDown={() => {
                                                                    this.functions.save(suggestion);
                                                                }}
                                                                className={"cursor-pointer px-2 py-1 hover:bg-gray-100 rounded-md font-medium text-sm"}>
                                                                {suggestion.name}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        this.state.tags.length > 0 &&
                        <>
                            {
                                this.state.tags.filter((item) => { return item.channel_tag }).length > 0 &&
                                < div className="text-xxs mb-1 mt-2">Channel tags</div>
                            }
                            {
                                this.state.tags.filter((item) => { return item.channel_tag }).length > 0 &&
                                <div className='flex flex-wrap w-full -ml-1 mb-2'>
                                    {
                                        this.state.tags.filter((item) => { return item.channel_tag }).map((tag, index) => {
                                            return (
                                                <div key={index} className={(tag.channel_tag && tag.channel ? ("text-" + tag.channel + "-500") : "text-gray-700 ") + " bg-gray-100 border-gray-500 flex items-center justify-center text-xs font-bold  py-1 px-2 mb-2 mx-1  border-2 rounded-full cursor-pointer  whitespace-no-wrap"}>
                                                    <div className="px-2">{tag.name}</div>
                                                    <div
                                                        onClick={() => {
                                                            this.functions.delete(tag.id)
                                                        }}
                                                        className={`flex items-center justify-center rounded-full h-5 w-5 hover:bg-gray-500 hover:text-white ${this.state.loading.delete && this.state.loading.delete_id === tag.id ? "bg-gray-500" : ""}`}>
                                                        {
                                                            this.state.loading.delete && this.state.loading.delete_id === tag.id ?
                                                                <div
                                                                    style={{ borderTopColor: "transparent" }}
                                                                    className="w-3 h-3 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                :
                                                                <span className="opacity-100">
                                                                    <XIcon className='h-4 w-4' />
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.props.tagType !== "component" &&
                                this.state.tags.filter((item) => { return !item.channel_tag }).length > 0 &&
                                <div className="text-xxs mb-1 mt-2">Custom tags</div>
                            }
                            {
                                this.props.tagType == "component" &&
                                <div className="text-xxs mb-1 mt-2"></div>
                            }
                            {
                                this.state.tags.filter((item) => { return !item.channel_tag }).length > 0 &&
                                <div className='flex flex-wrap w-full -ml-1'>
                                    {
                                        this.state.tags.filter((item) => { return !item.channel_tag }).map((tag, index) => {
                                            return (
                                                <div key={index} className={(tag.channel_tag ? ("text-" + tag.id + "-500") : "text-gray-700 ") + " bg-gray-100 border-gray-500 flex items-center justify-center text-xs font-bold  py-1 px-2 mb-2 mx-1  border-2 rounded-full cursor-pointer  whitespace-no-wrap"}>
                                                    <div className="px-2">{tag.name}</div>
                                                    <div
                                                        onClick={() => {
                                                            this.functions.delete(tag.id)
                                                        }}
                                                        className={`flex items-center justify-center rounded-full h-5 w-5 hover:bg-gray-500 hover:text-white ${this.state.loading.delete && this.state.loading.delete_id === tag.id ? "bg-gray-500" : ""}`}>
                                                        {
                                                            this.state.loading.delete && this.state.loading.delete_id === tag.id ?
                                                                <div
                                                                    style={{ borderTopColor: "transparent" }}
                                                                    className="w-3 h-3 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                :
                                                                <span className="opacity-100">
                                                                    <XIcon className='h-4 w-4' />
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                    }
                </div >
            </>
        )
    }
}

export default TagManager;
