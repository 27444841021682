import React, { Component } from 'react';
import './style.css';
import StatCard from "../../modules/statcard";
import { BeatLoader } from "react-spinners";

class CampaignDonuts2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            tab: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            donutSize: 65,
            impressions: null,
            click: null,
            ctr: null,
            cpc: null,
            conversions: null,
            spend: null,
            loading: true,
            properties: []
        };
    };

    componentDidMount() {
        this.setState({
            data: this.props.data,
            properties: this.props.properties ? this.props.properties : [],
            tab: this.props.tab,
            loading: this.props.loading
        });
    }

    init = {};

    functions = {
        data: () => {
            let init = {};
            this.props.properties.map((item) => {
                init[item] = {
                    labels: [],
                    datasets: [{
                        label: item,
                        data: [],
                        backgroundColor: []
                    }]
                };
            });
            for (let key in this.state.data) {
                if (key === 'facebook') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Facebook');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#3A5998');
                        }
                    }
                }
                if (key === 'instagram') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Instagram');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#DC2A7B');
                        }
                    }
                }
                if (key === 'google') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Google');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#4384F4');
                        }
                    }
                }
                if (key === 'google_analytics') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Google Analytics');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#e8710a');
                        }
                    }
                }
                if (key === 'linkedin') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Linkedin');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#0D65A1');
                        }
                    }
                }
                if (key === 'adform') {
                    for (let metric in this.state.data[key]) {
                        if (init[metric]) {
                            init[metric].labels.push('Adform');
                            init[metric].datasets[0].data.push(this.state.data[key][metric] ? this.renders.number(this.state.data[key][metric]) : 0);
                            init[metric].datasets[0].backgroundColor.push('#60C1A4');
                        }
                    }
                }
            }
            this.setState({
                impressions: init.impressions,
                click: init.click,
                ctr: init.ctr,
                cpc: init.cpc,
                conversions: init.conversions,
                spend: init.spend
            })
        }
    };

    renders = {
        impressions: () => {
            try {
                if (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_impressions"]) {
                    let total = 0;
                    this.state.tab.cells["chart_donut_impressions"].datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(total);
                } else {
                    let total = 0;
                    this.state.impressions.datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(+total);
                }
            } catch (e) {
                return 0
            }
        },
        click: () => {
            try {
                if (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_click"]) {
                    let total = 0;
                    this.state.tab.cells["chart_donut_click"].datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(total);
                } else {
                    let total = 0;
                    this.state.click.datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(+total);
                }
            } catch (e) {
                return 0
            }
        },
        ctr: () => {
            try {
                if (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_click"] && this.state.tab.cells["chart_donut_impressions"]) {
                    let click = 0;
                    let impressions = 0;
                    this.state.tab.cells["chart_donut_click"].datasets[0].data.map((item) => {
                        click = +click + +item;
                    });
                    this.state.tab.cells["chart_donut_impressions"].datasets[0].data.map((item) => {
                        impressions = +impressions + +item;
                    });
                    return this.renders.number((+click / +impressions) * 100);
                } else {
                    return "-"
                }
            } catch (e) {
                return "-"
            }
        },
        cpc: () => {
            try {
                if (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_click"] && this.state.tab.cells["chart_donut_spend"]) {
                    let click = 0;
                    let spend = 0;
                    this.state.tab.cells["chart_donut_click"].datasets[0].data.map((item) => {
                        click = +click + +item;
                    });
                    this.state.tab.cells["chart_donut_spend"].datasets[0].data.map((item) => {
                        spend = +spend + +item;
                    });
                    return this.renders.number((+spend / +click));
                } else {
                    return "-"
                }
            } catch (e) {
                return "-"
            }
        },
        conversions: () => {
            try {
                let total = 0;
                this.state.conversions.datasets[0].data.map((item) => {
                    total = +total + +item;
                });
                return this.renders.number(+total);
            } catch (e) {
                return 0
            }
        },
        spend: () => {
            try {
                if (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_spend"]) {
                    let total = 0;
                    this.state.tab.cells["chart_donut_spend"].datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(total);
                } else {
                    let total = 0;
                    this.state.spend.datasets[0].data.map((item) => {
                        total = +total + +item;
                    });
                    return this.renders.number(+total);
                }
            } catch (e) {
                return 0
            }
        },
        number: (item) => {
            try {
                if (!item) {
                    return "";
                } else {
                    return Math.round((item + Number.EPSILON) * 100) / 100;
                }
            } catch (e) {
                return item;
            }
        }
    };

    render() {
        return (
            <div className="relative" style={{ width: '100%' }}>
                <div className="data--wrapper flex flex-col md:flex-row flex-wrap md:-mx-2">
                    {
                        (this.state.impressions || (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_impressions"])) &&
                        this.state.properties.filter((key) => { return key === 'impressions' }).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                border={this.props.border}
                                stats={this.renders.impressions()}
                                label={'Impressions'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={(this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_impressions"]) ? this.state.tab.cells["chart_donut_impressions"] : this.state.impressions}
                            />
                        </div>
                    }
                    {
                        (this.state.click || (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_click"])) &&
                        this.state.properties.filter((key) => { return key === 'click' }).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                border={this.props.border}
                                stats={this.renders.click()}
                                label={'Clicks'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={(this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_click"]) ? this.state.tab.cells["chart_donut_click"] : this.state.click}
                            />
                        </div>
                    }
                    {
                        (this.state.ctr || (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_ctr"])) &&
                        this.state.properties.filter((key) => { return key === 'ctr' }).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                border={this.props.border}
                                stats={this.renders.ctr()}
                                unit={'%'}
                                label={'Avg. CTR'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={(this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_ctr"]) ? this.state.tab.cells["chart_donut_ctr"] : this.state.ctr}
                            />
                        </div>
                    }
                    {
                        (this.state.cpc || (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_cpc"])) &&
                        this.state.properties.filter((key) => { return key === 'cpc' }).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                border={this.props.border}
                                stats={this.renders.cpc()}
                                label={'Avg. CPC'}
                                unit={this.props.currency ? this.props.currency : ''}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={(this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_cpc"]) ? this.state.tab.cells["chart_donut_cpc"] : this.state.cpc}
                            />
                        </div>
                    }
                    {
                        (this.state.spend || (this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_spend"])) &&
                        this.state.properties.filter((key) => { return key === 'spend' }).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                border={this.props.border}
                                stats={this.renders.spend()}
                                label={'Spend'}
                                unit={this.props.currency ? this.props.currency : ''}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={(this.state.tab && this.state.tab.cells && this.state.tab.cells["chart_donut_spend"]) ? this.state.tab.cells["chart_donut_spend"] : this.state.spend}
                            />
                        </div>
                    }
                </div>
                {
                    this.state.loading &&
                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-white flex justify-center items-center">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default CampaignDonuts2;
