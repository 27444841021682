import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";

class AdvertisingSites extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            edit: this.props.edit,
            site: this.props.site,
            loading_sites: true,
            loading_url: false,
            sites: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            site: nextProps.site,
            edit: nextProps.edit,
            open: nextProps.open
        });
    }

    componentWillMount() {
        this.init.sites();
    }

    componentDidMount() {}

    init = {
        sites: () => {
            this.setState({
                sites: []
            }, () => {
                dataRegister.getSites().then((response) => {
                    this.setState({
                        sites: response,
                        loading_sites: false,
                    }, () => {
                        this.state.sites.push({name: 'Custom site', custom: true})
                    });
                }, (error) => {
                });
            })
        }
    };

    functions = {
        url: () => {
            this.setState({
                loading_url: true
            }, () => {
                dataRegister.getUrlData({
                    url: this.state.url
                }).then((response) => {
                    this.state.site = response;
                    this.state.site.custom = true;
                    this.state.site.checked = true;
                    this.state.site.name = 'Custom site';
                    this.state.site.url = this.state.url;
                    this.setState({
                        site: this.state.site,
                        loading_url: false,
                        url: "",
                        error: null,
                        errorMessage: ""
                    }, () => {
                        this.props.onChange(this.state.site);
                    })
                }, (error) => {
                    this.setState({
                        loading_url: false,
                        error: "url",
                        errorMessage: error.body.message
                    })
                })
            });
        },
        select: (value) => {
            this.props.onChange(value);
        },
        edit: (value) => {
            this.props.onEdit(value);
        },
        remove: () => {
            this.props.onChange({});
        }
    };

    renders = {};

    render() {
        return (
            <AccordionFixed
                loading={this.state.loading_sites}
                icon={((this.state.edit && this.state.site.name && !this.state.site.custom) || (this.state.edit && this.state.site.custom && this.state.site.checked)) ? "check" : ""}
                icon_color={"#0185fe"}
                open={!this.state.loading && this.state.open}
                title={this.state.site.name ? ("Site:") : "Set site for your ads"}
                subtitle={this.state.site.name ? (this.state.site.name) : ""}
                edit={this.state.edit}
                onEdit={() => {
                    this.functions.edit(!this.state.open);
                }}
            >
                <div style={{textAlign: 'left'}} className="Section">
                    Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                    usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                    molestiae est.
                </div>
                {
                    ((this.state.site.custom && !this.state.site.checked) || !this.state.site.custom) &&
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            title={'My sites'}
                            size={'full'}
                            placeholder={"Click here .."}
                            error={this.state.error === "site" && !this.state.site.name}
                            options={this.state.sites}
                            value={this.state.site}
                            onChange={(value) => {
                                this.functions.select(value);
                            }}
                        />
                    </div>
                }
                {
                    this.state.site.custom && !this.state.site.checked &&
                    <div className="Section">
                        <Input
                            title={'Url'}
                            value={this.state.url}
                            error={this.state.error === "url"}
                            placeholder={""}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            onChange={(e) => {
                                this.setState({
                                    url: e.target.value
                                });
                            }}
                        />
                    </div>
                }
                {
                    this.state.error &&
                    <div style={{textAlign: 'left'}} className="Section SectionError">
                        {this.state.errorMessage}
                    </div>
                }
                {
                    this.state.site.custom && this.state.site.checked &&
                    <div className="Section">
                        <div className="Locations-Items">
                            <div className="Location-ItemTitle">
                                Approved URL
                            </div>
                            <div className="Location-Item">
                                <div className="Location-ItemLeft">
                                    {this.state.site.url}
                                </div>
                                <div onClick={() => {
                                    this.functions.remove();
                                }} className="Location-ItemRight">
                                    <FeatherIcon color={"#FC5353"} size={23} icon="trash"/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.site.custom && !this.state.site.checked &&
                    <div className="Section">
                        <div onClick={() => this.functions.url()} className="Button ButtonFull">
                            {
                                !this.state.loading_url &&
                                <div>Check site</div>
                            }
                            {
                                this.state.loading_url &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                }
            </AccordionFixed>
        )
    }
}

export default AdvertisingSites;
