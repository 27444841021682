import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleshopping/code";
        return apiRegister.call(options, url);
    }

    static getMerchants(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleshopping/merchants";
        return apiRegister.call(options, url);
    }

    static getAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleshopping/accounts";
        return apiRegister.call(options, url);
    }

    static getAccount(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleshopping/account";
        return apiRegister.call(options, url);
    }

    static create(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleshopping";
        return apiRegister.call(options, url);
    }

    static remove(client) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/client/googleshopping/" + client;
        return apiRegister.call(options, url);
    }

}

export {calls}