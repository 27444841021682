import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon, TrashIcon, UploadIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';

class CreativeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "created",
            direction: "ascending",
            orders: [],
            order: { id: 1, name: "Filter by order" },
            creatives: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'order', value: 'order', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channels', value: 'channels', min_width: '250px' },
                { name: 'created', value: 'created', min_width: '250px' },
            ],
            status: { id: 1, name: "All", value: "all" },
            client: { id: 1, name: "All", value: "all" },
            clients: [
                { id: 1, name: "All", value: "all" }
            ],
            stats: [
                { id: 1, name: "All", value: "all" }
            ],
            temp: []
        }
    };

    componentDidMount() {
        this.functions.query();
        this.functions.orders(true);
    }

    functions = {
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("status=") !== -1) {
                    let value = queries[i].replace("status=", "");
                    let status = null;
                    this.state.stats.map((item) => {
                        if (item.value == value) {
                            status = item;
                        }
                    });
                    await this.promisedSetState({
                        status: status ? status : this.state.status
                    });
                }
                if (queries[i].indexOf("order=") !== -1) {
                    let value = queries[i].replace("order=", "");
                    let order = null;
                    this.state.orders.map((item) => {
                        if (item.id == value) {
                            order = item;
                        }
                    });
                    await this.promisedSetState({
                        order: order ? order : this.state.order
                    });
                }
            }
            this.functions.creatives(true);
        },
        orders: async (init) => {
            await this.promisedSetState({ loading_orders: true });
            try {
                let response = await this.calls.orders();
                await this.promisedSetState({ orders: response.data, meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading_orders: false });
        },
        creatives: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.creatives();
                await this.promisedSetState({ creatives: response.data, meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.creatives.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ creatives: this.state.creatives });
            try {
                let response = await this.calls.remove(temp.id);
                await this.promisedSetState({ creatives: this.state.creatives.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.creatives.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
        },
        menu: (option, row) => {
            if (option === "Open") {

            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            }
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        this.functions.uploadVideo(files[i]);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        this.functions.uploadImage(files[i]);
                    }
                    const randomString = Math.random().toString(36);
                    this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    }, true);
                }
            }
        },
        uploadImage: async (file) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    type: 'image'
                };
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.image(formData);
                    resolve(response.data);
                } catch (error) {
                    resolve(null);
                }
            });
        },
        uploadVideo: async (file) => {
            return new Promise(async (resolve) => {
                let files_object = {
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    loading: true,
                    name: file.name,
                    type: 'video'
                };
                try {
                    const formData = new FormData();
                    formData.append('file', file);
                    let response = await this.calls.video(formData);
                    resolve(response.data);
                } catch (error) {
                    resolve(null);
                }
            });
        }
    };

    calls = {
        orders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders";
            return apiRegister.call(options, url);
        },
        creatives: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCreatives?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&order=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCreative?id=" + id;
            return apiRegister.call(options, url);
        },
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        video: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative h-full">

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-80 flex justify-center items-center flex-col">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading Drafts ...</div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* DRAFT SUBMENU */}
                {
                    !this.state.loading &&
                    <div className="bg-white py-4 w-full border-t px-6 flex flex-row">
                        <div className="block">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.stats.map((tab, index) => (
                                    <div
                                        onClick={async () => {
                                            await this.promisedSetState({
                                                status: tab
                                            });
                                            this.functions.creatives();
                                        }}
                                        key={index}
                                        className={classNames(
                                            this.state.status.id === tab.id ? 'bg-gray-100 text-purple-500' : 'text-gray-500 hover:text-gray-700',
                                            'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
                                        )}
                                        aria-current={this.state.status.id === tab.id ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                        <div className="flex flex-1 justify-end items-center">
                            <button
                                onClick={() => {

                                }}
                                type="button"
                                className="inline-flex ml-3 relative justify-center rounded-md border border-transparent btn btn-primary py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            >
                                Upload creatives
                                <UploadIcon className="ml-2 h-5 w-5" />
                                <input
                                    onChange={(e) => this.functions.onSelectFile(e)}
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpg, image/jpeg, image/gif, video/*"}
                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                />
                            </button>
                        </div>
                    </div>
                }

                {/*TABLE*/}
                {
                    !this.state.loading &&
                    <div className="p-6">
                        <div className="shadow bg-white rounded-lg">

                            {/*HEADER*/}
                            <div className="p-6 flex flex-row items-center border-b">
                                <div className="flex flex-col flex-1">
                                    <div className="text-lg leading-6 font-semibold text-gray-900">
                                        {this.state.status.name} creatives
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Filter your creatives on status and order to narrow down your search
                                    </p>
                                </div>
                            </div>

                            {/*FILTERS*/}
                            <div className="p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-1">
                                        <DropdownTailwind
                                            label={"Status"}
                                            selected={this.state.status}
                                            options={this.state.stats}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    status: value
                                                });
                                                this.functions.creatives();
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <DropdownTailwind
                                            label={"Orders"}
                                            searchInput={true}
                                            selected={this.state.order}
                                            loader={this.state.loading_orders}
                                            options={Array.isArray(this.state.orders) ? this.state.orders : []}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    order: value
                                                });
                                                this.functions.creatives();
                                            }}
                                        />
                                    </div>
                                    {
                                        false &&
                                        <div className="col-span-2">
                                            <InputTailwind
                                                label={"Search name"}
                                                value={this.state.name}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        name: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            {/*NO ORDERS*/}
                            {
                                this.state.creatives.length < 1 &&
                                <div className="border-t border-gray-200 h-64 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <ExclamationCircleIcon className="h-10 w-10 text-gray-300" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No creatives</h3>
                                        <p className="mt-1 text-sm text-gray-500">Create new creative to get started</p>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*DRAFTS*/}
                            {
                                this.state.creatives.length > 0 &&
                                <div className="overflow-x-scroll relative">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-t border-gray-300">
                                        <thead className="bg-gray-50 bg-opacity-50">
                                            <tr>
                                                <th scope="col" className="relative border-gray-300 px-6 py-3 border-r"></th>
                                                {this.state.columns.map((item, index) => {
                                                    let last = (this.state.columns.length - 1) === index;
                                                    return (
                                                        <th
                                                            onClick={() => {
                                                                this.setState({
                                                                    sort: item.value,
                                                                    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                })
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(!last ? "border-r " : "") + "px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-4 w-4 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-4 w-4 cursor-pointer"} aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-300">
                                            {
                                                this.state.creatives.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id}>
                                                                <td style={{ whiteSpace: "nowrap" }} className={"px-4 sm:px-6 py-4 flex flex-row border-r border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer inline-flex relative w-full h-10 justify-center items-center rounded-md border border-gray-300 px-4 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                Options
                                                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Open", "Clone", "Delete"].map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-r border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-r border-gray-300"}>
                                                                    <span
                                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "archived" || item.status === "ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                //["bg-yellow-100 text-yellow-600"]: item.status === "pending",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error",
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-r border-gray-300"}>
                                                                    <div className="flex flex-row">
                                                                        {item.channels && item.channels.facebook &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.instagram &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.linkedin &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_shopping &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.tiktok &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.twitter &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-r border-gray-300"}>
                                                                    {item.created ? moment(item.created).format('DD MMMM YYYYY') : '-'}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/*BOTTOM NAVIGATION*/}
                            <nav
                                className="roundded-b-lg p-6 flex items-center justify-between border-t border-gray-300 sm:px-6"
                                aria-label="Pagination"
                            >
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > this.state.meta.total) ? this.state.meta.total : this.state.page * this.state.limit}</span> of{' '}
                                        <span className="font-medium">{this.state.meta.total}</span> results
                                    </p>
                                </div>
                                <div className="flex-1 flex justify-between sm:justify-end">
                                    <Menu as="div" className="relative inline-block text-left mr-4">
                                        <div>
                                            <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md border border-gray-300 shadow-sm px-4 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                Page limit: {this.state.limit}
                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {
                                                        [5, 10, 20, 30, 50, 100, 500].map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={async () => {
                                                                                if (!this.state.loading_partial) {
                                                                                    await this.promisedSetState({
                                                                                        limit: item
                                                                                    });
                                                                                    this.functions.creatives();
                                                                                }
                                                                            }}
                                                                            className={cn(
                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                this.state.limit == item ? "text-purple-500" : ""
                                                                            )}
                                                                        >
                                                                            {item}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <div
                                        onClick={async () => {
                                            if (!this.state.loading_partial) {
                                                await this.promisedSetState({
                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                });
                                                this.functions.creatives();
                                            }
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Previous
                                    </div>
                                    <div
                                        onClick={async () => {
                                            if ((((this.state.page) * this.state.limit) <= this.state.meta.total)) {
                                                if (!this.state.loading_partial) {
                                                    await this.promisedSetState({
                                                        page: this.state.page + 1
                                                    });
                                                    this.functions.creatives();
                                                }
                                            }
                                        }}
                                        className={((((this.state.page) * this.state.limit) >= this.state.meta.total) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-4 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                    >
                                        Next
                                    </div>
                                </div>
                            </nav>

                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default CreativeList;
