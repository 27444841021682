import React, { Component } from 'react';
import classNames from 'classnames';
import { ArrowLeftIcon, ArrowRightIcon, LockClosedIcon, XIcon } from '@heroicons/react/outline';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { draftRegister } from '../services/draftRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import InputTailwind from '../moduleFiles/inputTailwind';
import moment from 'moment';
import WizardModal from '../moduleFiles/wizardModal';

class CreateDraftOrderDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            order: {},
            client: {},
            wizard: null,
            loading: false,
            loading_wizard: false,
            create_wizard: false,
            wizard_name: "",
            meta: {},
            limit: 15,
            page: 1,
            search: "",
            sort: "name",
            direction: "descending",
            throttling: {}
        };
    }

    async componentWillMount() {
    }

    async componentDidMount() {
        await this.form.get();
        this.functions.orders(true);
        this.props.setSteps();
    }

    form = {
        set: (template) => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("order", self.state.order);
                draftRegister.set("wizard", self.state.wizard);
                if (template) {
                    draftRegister.set("campaigns", self.state.campaigns);
                    draftRegister.set("adgroups", self.state.adgroups);
                    draftRegister.set("creatives", self.state.creatives);
                    draftRegister.set("assetItems", self.state.assetItems);
                    draftRegister.set("ads", self.state.ads);
                    draftRegister.set("channels", self.state.channels);
                    draftRegister.set("client", self.state.client);
                }
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : self.state.creatives,
                        assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : self.state.assetItems,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : self.state.campaigns,
                        adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : self.state.adgroups,
                        ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : self.state.ads,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels
                    })
                }
                resolve();
            })
        }
    };

    functions = {
        orders: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.orders();
                await this.promisedSetState({ orders: response.data, meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        createWizard: async () => {
            await this.promisedSetState({ loading_wizard: true, create_wizard: false });
            try {
                let is_template = this.state.wizard && this.state.wizard.template;
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name,
                    ...((is_template) && {
                        template_data: {
                            creatives: this.state.creatives,
                            assetItems: this.state.assetItems,
                            wizard: this.state.wizard,
                            client: this.state.client,
                            order: this.state.order,
                            campaigns: this.state.campaigns,
                            adgroups: this.state.adgroups,
                            ads: this.state.ads,
                            channels: this.state.channels
                        }
                    })
                });
                if (is_template) {
                    await this.promisedSetState({
                        campaigns: response.data.campaigns,
                        adgroups: response.data.adgroups,
                        ads: response.data.ads,
                        channels: response.data.channels
                    });
                }
                await this.promisedSetState({ wizard: { id: response.data.id } });
                await this.form.set(is_template);
                this.props.history.push("/v2/campaigns/new/channels");
                this.props.setSteps();
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        }
    };

    calls = {
        ordersActive: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=" + "active" + "&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        ordersUpcoming: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=" + "upcoming" + "&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        orders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&stats=active,upcoming" + "&client=" + this.state.client.id + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard?id=" + this.state.order.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative h-full">

                {
                    false &&
                    <div className="sticky top-0 z-50">
                        <TopNavigationAgent
                            onButton={(type) => {
                                if (type === "cancel") {
                                    draftRegister.remove();
                                    this.props.history.push("/v2/drafts");
                                }
                            }}
                            hideUserDropdown={true}
                            history={this.props.history}
                            breadcrumb={"Select order"}
                            buttonTypes={{ cancel: !this.state.loading_wizard, save: false }}
                        />
                    </div>
                }

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                this.props.history.push("/v2/drafts");
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.orders();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onSearch={async (value) => {
                            if (value && value === "") {
                                await this.promisedSetState({
                                    search: "",
                                    page: 1
                                });
                                this.functions.orders();
                            } else {
                                if (this.state.throttling.current) {
                                    //SKIP
                                } else {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: value,
                                            page: 1
                                        });
                                        this.functions.orders();
                                    } else {
                                        this.state.throttling.current = true;
                                        await this.promisedSetState({
                                            search: value,
                                            throttling: this.state.throttling
                                        });
                                        setTimeout(async () => {
                                            this.state.throttling.current = false;
                                            await this.promisedSetState({
                                                throttling: this.state.throttling
                                            });
                                            await this.promisedSetState({
                                                page: 1
                                            });
                                            this.functions.orders();
                                        }, 300);
                                    }
                                }
                            }
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta.total}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        filters={[]}
                        hideUserDropdown={true}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", loading: false, show: !this.state.loading, red: true }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                <WizardModal
                    title={"Create new draft"}
                    text={"Set name of draft below"}
                    button={"Create"}
                    open={this.state.create_wizard}
                    input={this.state.wizard_name}
                    onLoading={this.state.loading_wizard}
                    onInput={(value) => {
                        this.setState({
                            wizard_name: value
                        })
                    }}
                    onCreate={() => {
                        this.functions.createWizard();
                    }}
                    onClose={() => {
                        this.setState({
                            create_wizard: false
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading orders ...</div>
                    </div>
                }

                {/*SECTION: SELECT ORDER*/}
                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="shadow bg-white rounded-lg flex flex-col relative">

                            {/*LOADER PARTIAL*/}
                            {
                                this.state.loading_partial &&
                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }

                            {
                                false &&
                                <div className="p-6 pb-0">
                                    <InputTailwind
                                        label={""}
                                        placeholder={"Search orders ..."}
                                        value={this.state.search}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                status: value
                                            });
                                        }}
                                    />
                                </div>
                            }

                            <div className="flex flex-1 p-6">
                                <div className="w-full">
                                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-5">
                                        {this.state.orders.map((order, index) => (
                                            <div
                                                onClick={async () => {
                                                    if (this.state.wizard && this.state.wizard.id && !this.state.wizard.template && this.state.order.id === order.id) {
                                                        this.props.history.push("/v2/campaigns/new/channels");
                                                    } else if (!(this.state.wizard && this.state.wizard.id) || (this.state.wizard && this.state.wizard.template)) {
                                                        this.setState({
                                                            order: order,
                                                            wizard_name: order.name,
                                                            create_wizard: true
                                                        })
                                                    }
                                                }}
                                                key={index}
                                                className={(this.state.order.id === order.id ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " relative cursor-pointer rounded-lg border-1.5 p-6 shadow-sm flex-col flex items-center"}
                                            >
                                                {
                                                    this.state.order.id === order.id && this.state.wizard && this.state.wizard.id && !this.state.wizard.template &&
                                                    <div style={{ right: "10px", top: "10px" }} className="absolute h-10 w-10 rounded-full bg-white flex items-center justify-center">
                                                        <LockClosedIcon className="w-5 h-5 text-gray-900" />
                                                    </div>
                                                }
                                                <div className="h-16 w-16 rounded-full overflow-hidden flex items-center justify-center border bg-white p-2 mb-4">
                                                    <div>
                                                        <img className="w-full" src={order.logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="text-sm font-medium text-gray-900 text-center">
                                                    {order.name}
                                                </div>
                                                <div className="text-sm font-medium text-gray-500 text-center">
                                                    {moment(order.startDate).format("YYYY.MM.DD")} - {moment(order.endDate).format("YYYY.MM.DD")}
                                                </div>
                                                {false &&
                                                    <span
                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                            {
                                                                ["bg-green-100 text-green-500"]: order.status === "active",
                                                                ["bg-purple-100 text-purple-500"]: order.status === "upcoming",
                                                                ["bg-gray-100 text-gray-500"]: order.status === "archived" || order.status === "ended",
                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: order.status === "paused",
                                                                //["bg-yellow-100 text-yellow-600"]: item.status === "pending",
                                                                ["bg-red-100 text-red-600"]: order.status === "error",
                                                            })
                                                        }>
                                                        {order.status ? order.status : "-"}
                                                    </span>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-4 flex flex-row">
                            <div className="flex flex-1">
                                <button
                                    type="button"
                                    onClick={async () => {
                                        if (!this.state.loading_wizard) {
                                            this.props.history.push("/v2/campaigns/new/client");
                                        }
                                    }}
                                    className="inline-flex items-center px-4 py-2 shadow text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                    Back
                                </button>
                            </div>
                            <div className="flex flex-1"></div>
                            <div className="flex justify-center items-center">
                                <div className="">
                                    <button
                                        onClick={() => {
                                            if (this.state.order.id && !this.state.loading_wizard) {
                                                if (this.state.wizard && this.state.wizard.id) {
                                                    this.props.history.push("/v2/campaigns/new/channels");
                                                } else {
                                                    this.setState({
                                                        wizard_name: this.state.order.name,
                                                        create_wizard: true
                                                    })
                                                }
                                            }
                                        }}
                                        className={(this.state.order.id ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <span>Next: Select channels</span>
                                        <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        {
                                            this.state.loading_wizard &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                }


            </div>
        )
    }
}

export default CreateDraftOrderDetails;