import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import timezones from "../../assets/json/timezones";
import currencies from "../../assets/json/currencies";
import ClientAudience from "../client-audience";
import TextArea from "../text-area";
import {calls} from "./calls";
import Loader from "../loader";

class ClientFacebookAdaccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            business: {},
            businesses: [],
            creditcard: {},
            creditcards: [],
            creditline: {},
            creditlines: [],
            currency: {},
            currencies: currencies,
            timezone: {},
            timezones: timezones
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            item: this.props.item,
            token: this.props.token,
            channel: this.props.channel
        }, () => {
            this.functions.getBusinesses();
            this.functions.facebook();
        })
    }

    init = {};

    functions = {
        facebook: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '1813873645538492',
                    version: 'v4.0',
                    redirect_uri: '',
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        payment: () => {
            let self = this;
            window.FB.ui({
                account_id: '2707654609552756',
                display: 'popup',
                method: 'ads_payment',
            }, function (response) {

            })
        },
        getBusinesses: () => {
            this.setState({
                businesses_loading: true
            }, () => {
                calls.getBusinesses({
                    token: this.state.token,
                    client: this.state.client,
                    channel: this.state.channel
                }).then((response) => {
                    this.setState({
                        business: {},
                        businesses: response.data,
                        businesses_loading: false
                    })
                }, (error) => {
                    this.setState({
                        businesses_error: true,
                        businesses_loading: error
                    })
                });
            })
        },
        getAdaccounts: () => {
            this.setState({
                businesses_loading: true
            }, () => {
                calls.getBusinesses({
                    token: this.state.token,
                    client: this.state.client,
                    channel: this.state.channel
                }).then((response) => {
                    this.setState({
                        business: {},
                        businesses: response.data,
                        businesses_loading: false
                    })
                }, (error) => {
                    this.setState({
                        businesses_error: true,
                        businesses_loading: error
                    })
                });
            })
        },
        getCreditLines: () => {
            this.setState({
                creditlines_loading: true
            }, () => {
                calls.getCreditLines(this.state.token).then((response) => {
                    this.setState({
                        creditline: {},
                        creditlines: response.data,
                        creditlines_loading: false
                    })
                }, (error) => {
                    this.setState({
                        creditlines_error: true,
                        creditlines_loading: error
                    })
                });
            })
        },
        createAdaccount: (item) => {
            this.setState({
                loading: true,
            }, () => {
                calls.createAdaccount({
                    token: this.state.token,
                    client: this.state.client,
                    channel: this.state.channel,
                    name: this.state.name,
                    business: this.state.business.id,
                    timezone: JSON.stringify(this.state.timezone.id),
                    currency: this.state.currency.name
                }).then((response) => {
                    //console.log(response);
                    this.setState({
                        loading: false,
                        success_modal: true,
                        name: "",
                        business: {},
                        timezone: {},
                        currency: {},
                        open: false
                    }, () => {
                        this.props.onChange();
                    })
                }, (error) => {
                    console.log(error);
                    let message = "Something went wrong";
                    try{
                        message = error.body.message;
                    }catch (e) {}
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: message
                    })
                });
            })
        }
    };

    renders = {};

    render() {
        return (
            <div>{
                this.state.loading &&
                <Loader
                    title={"Creating adaccount"}
                />
            }
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.success_modal}
                    title="Success"
                    type="success"
                    text={"Adaccount created"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ success_modal: false});
                    }}
                />
                <div className="bg-white rounded-md px-4 py-4">
                    {
                        <div className="Section" style={{paddingTop: '0px'}}>
                            <Dropdown
                                filter={true}
                                title={'Select a business manager'}
                                size={'full'}
                                placeholder={"Click here .."}
                                error={this.state.error === "business" && !this.state.business.name}
                                options={this.state.businesses}
                                loading={this.state.businesses_loading}
                                value={this.state.business}
                                onChange={(value) => this.setState({business: value}, () => {

                                })}
                            />
                        </div>
                    }
                    {
                        this.state.business.name &&
                        <div className="Section">
                            <Dropdown
                                filter={true}
                                title={'Select a currency'}
                                size={'full'}
                                placeholder={"Click here .."}
                                error={this.state.error === "currency" && !this.state.currency.name}
                                options={this.state.currencies}
                                loading={this.state.currencies_loading}
                                value={this.state.currency}
                                onChange={(value) => this.setState({currency: value}, () => {

                                })}
                            />
                        </div>
                    }
                    {
                        this.state.business.name && this.state.currency.name &&
                        <div className="Section">
                            <Dropdown
                                filter={true}
                                title={'Select a timezone'}
                                size={'full'}
                                placeholder={"Click here .."}
                                error={this.state.error === "timezone" && !this.state.timezone.name}
                                options={this.state.timezones}
                                loading={this.state.timezones_loading}
                                value={this.state.timezone}
                                onChange={(value) => this.setState({timezone: value}, () => {

                                })}
                            />
                        </div>
                    }
                    {
                        this.state.business.name && this.state.currency.name && this.state.timezone.name &&
                        <div className="Section">
                            <Input
                                title={"Name"}
                                value={this.state.name}
                                error={this.state.error === "name"}
                                placeholder={""}
                                disabled={false}
                                size={'full'}
                                type={"text"}
                                onChange={(value) => {
                                    this.setState({
                                        name:  value.target.value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.business.name && this.state.currency.name && this.state.timezone.name &&
                        <div className="Section">
                            <div onClick={() => {this.functions.createAdaccount()}} className="Button ButtonFull ButtonNeutral">
                                create ad account
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ClientFacebookAdaccount;
