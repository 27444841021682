import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BeatLoader } from "react-spinners";
import Input from "../input";
import TextArea from "../text-area";

class AdvertisingCreativesVertical extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: [],
            page: {},
            no_container: false,
            language: "",
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            page: nextProps.page,
            language: nextProps.language,
            no_container: nextProps.noContainer,
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            page: this.props.page,
            language: this.props.language,
            no_container: this.props.noContainer,
        });
    }

    functions = {};

    init = {};

    renders = {
        title: () => {
            try {
                return this.state.creatives[0].headline !== "" ? this.state.creatives[0].headline : "Headline ..."
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                return this.state.creatives[0].body !== "" ? this.state.creatives[0].body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.creatives[0].creative.url
            } catch (e) {
                return ""
            }
        },
        gif: () => {
            try {
                return this.state.creatives[0].creative.url.indexOf('.gif') !== -1 ? this.state.creatives[0].creative.url : null
            } catch (e) {
                return ""
            }
        },
        poster: () => {
            try {
                return this.state.creatives[0].creative.thumbnail.url
            } catch (e) {
                return ""
            }
        },
        type: () => {
            try {
                if (this.state.creatives[0].video) {
                    return 'video'
                } else {
                    return 'image'
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.state.creatives[0].call_to_action.value === 'NO_BUTTON') {
                    return null
                } else {
                    return this.state.creatives[0].call_to_action.name;
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer")}>
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem",
                        {
                            ["FacebookImage-PreviewBasicContainerItemError"]: this.state.error,
                            ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                            ["FacebookImage-PreviewBasicContainerWhite"]: this.props.white,
                        })
                }>
                    <div style={{ height: '568.89px' }} className={"FacebookImage-PreviewBasicContainerItemInner-Vertical items-center flex justify-center bg-gray-800"}>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-Vertical">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                                <div style={{ backgroundImage: 'url(' + this.state.page.image + ')' }} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon-Vertical"></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText-Vertical">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop-Vertical">
                                    {this.state.page.name}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored-Vertical">
                                        Sponsored
                                    </div>
                                </div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-Icons">
                                <div>
                                    <FeatherIcon color={"#ffffff"} size={21} icon="more-horizontal" />
                                </div>
                                <div style={{ marginLeft: '2px' }}>
                                    <FeatherIcon color={"#ffffff"} size={21} icon="x" />
                                </div>
                            </div>
                        </div>
                        {
                            this.renders.callToAction() &&
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-Vertical-CTA">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-Vertical-CTAButton">
                                    <FeatherIcon color={"#333333"} size={18} icon="chevron-up" />
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-Vertical-CTAText">
                                    {this.renders.callToAction()}
                                </div>
                            </div>
                        }
                        {
                            !this.renders.gif() &&
                            this.renders.type() === 'image' &&
                            <img style={{ width: '100%' }} src={this.renders.image()} />
                        }
                        {
                            this.renders.gif() &&
                            <img style={{ width: '100%' }} src={this.renders.gif()} />
                        }
                        {
                            !this.renders.gif() &&
                            this.renders.type() === 'video' &&
                            <video poster={this.renders.poster()} autoPlay={false} muted={true} controls={true} style={{ width: '100%', height: '100%' }}>
                                <source src={this.renders.image()} type="video/mp4" />
                            </video>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesVertical;
