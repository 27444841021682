import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCampaigns(page, sort, order, page_size) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?type=error&page=" + page +'&sort=' + sort + '&order=' + order + '&page_size=' + page_size;
        return apiRegister.call(options, url);
    }

    static removeCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/v2/internal/" + id;
        return apiRegister.call(options, url);
    }

}

export {calls}