import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import Switch from "react-ios-switch";
import {calls} from "./calls";
import {tokenRegister} from "../../services/tokenRegister";
import {userRegister} from "../../services/userRegister";

class ClientUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            selected: null,
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client
        }, () => {
            this.functions.getAgents();
        })
    }

    init = {};

    functions = {
        getAgents: () => {
            this.setState({
                loading: true,
                error: false,
                error_message: ""
            }, () => {
                calls.getAgents().then((response) => {
                    this.setState({
                        users: response.data,
                        loading: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: true,
                        error_message: typeof error.body === "object" ? error.body.message : ""
                    })
                });
            });
        },
        updateAgent: (item, value) => {
            item.loading = true;
            this.setState({
                users: this.state.users
            }, () => {
                if(!item.excludedClients){
                    item.excludedClients = {};
                }
                item.excludedClients[this.state.client.id] = !value;
                calls.updateAgent({
                    agent: item._id,
                    excludedClients: item.excludedClients
                }).then((response) => {
                    item.loading = false;
                    this.setState({
                        users: this.state.users
                    });
                }, (error) => {
                    item.loading = false;
                    this.setState({
                        users: this.state.users
                    });
                });
            });
        }
    };

    renders = {};

    render() {
        return (
            <div className="bg-white rounded-md px-4 py-4 mb-12 mt-6">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div>
                    <h3 className="mb-0 font-bold">
                        Access
                    </h3>
                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                        Avg. number of search per month in parantheses
                    </p>
                </div>
                <div className="rounded-md bg-gray-100 px-4 pb-2 pt-4 mt-4">
                    {
                        Array.isArray(this.state.users) &&
                        this.state.users.map((item) => {
                            return (
                                <div
                                    className="flex flex-row flex-1 items-center mb-2 bg-white px-4 py-3 py-1 rounded-md relative">
                                    {
                                        item.loading &&
                                        <div
                                            className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center">
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={10}
                                                color={'#060534'}
                                                loading={true}
                                            />
                                        </div>
                                    }
                                    <div style={{fontWeight: "500", fontSize: "14px"}}
                                         className="text-sm">{item.email}</div>
                                    <div className="flex flex-1"></div>
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={(!item.excludedClients || (typeof item.excludedClients === "object" && !item.excludedClients[this.state.client.id]))}
                                        onChange={(checked) => {
                                            this.functions.updateAgent(item, checked);
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default ClientUsers;
