import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import {calls} from "../advertising-targeting-facebook/calls";
import Accordion from "../accordion";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";

class AdvertisingTargetingFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            targeting: {},
            client: {},
            locations: {},
            channels: {},
            category: {},
            creatives: {},
            categories: [],
            behaviors: [],
            behaviors_categories: [],
            loading_behaviors: false,
            interests: [],
            interests_search: '',
            loading_interests: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            title: nextProps.title,
            targeting: nextProps.targeting,
            client: nextProps.client,
            locations: nextProps.locations,
            channels: nextProps.channels,
            creatives: nextProps.creatives
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            title: this.props.title,
            targeting: this.props.targeting,
            client: this.props.client,
            locations: this.props.locations,
            channels: this.props.channels,
            creatives: this.props.creatives
        }, () => {
            this.functions.behaviors();
            //this.functions.audience();
        });
    }

    init = {};

    functions = {
        audience: () => {
            try{
                let channels = (this.state.channels.facebook && this.state.channels.instagram) ? ['facebook', 'instagram'] : (this.state.channels.facebook ? ['facebook'] : ['instagram']);
                let channel = this.state.channels.facebook ? 'facebook' : 'instagram';

                //POSITIONS
                let facebook_positions = [];
                let instagram_positions = [];
                let messenger_positions = [];

                if (this.renders.verticalFormatOnly()) {
                    facebook_positions.push('story');
                    instagram_positions.push('story');
                    if(this.state.channels.facebook && !this.state.channels.instagram){
                        facebook_positions.push('feed');
                    }
                    if(this.state.targeting.placement.messenger_story){
                        messenger_positions.push('story');
                    }
                }else{
                    if (this.renders.normalFormatOnly()) {
                        facebook_positions.push('feed');
                        instagram_positions.push('stream');
                    }else if(this.renders.bothFormats()){
                        facebook_positions.push('feed');
                        instagram_positions.push('stream');

                        facebook_positions.push('story');
                        instagram_positions.push('story');
                    }
                    if(this.state.targeting.placement.right_hand_column){
                        facebook_positions.push('right_hand_column');
                    }
                    if(this.state.targeting.placement.instant_article){
                        facebook_positions.push('instant_article');
                    }
                    if(this.state.targeting.placement.marketplace){
                        facebook_positions.push('marketplace');
                    }
                    if(this.state.targeting.placement.messenger_inbox){
                        messenger_positions.push('messenger_home');
                    }
                    if(this.state.targeting.placement.explore){
                        instagram_positions.push('explore');
                    }
                }

                //console.log(this.state.locations);

                let targeting_spec = {
                    excluded_geo_locations: {
                        regions: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "3" && !item.include
                        }).map((item) => {
                            return {key: item.channels.facebook.id}
                        }),
                        cities: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "2" && !item.include
                        }).map((item) => {
                            return {key: item.channels.facebook.id}
                        }),
                        countries: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "4" && !item.include
                        }).map((item) => {
                            return item.channels.facebook.id
                        }),
                    },
                    geo_locations: {
                        regions: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "3" && item.include
                        }).map((item) => {
                            return {key: item.channels.facebook.id}
                        }),
                        cities: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "2" && item.include
                        }).map((item) => {
                            return {key: item.channels.facebook.id}
                        }),
                        countries: this.state.locations.filter((item) => {
                            return !item.custom && !item.negative && item.level === "4" && item.include
                        }).map((item) => {
                            return item.channels.facebook.id
                        }),
                        custom_locations: this.state.locations.filter((item) => {
                            return !item.intersection && item.custom && !item.negative && item.include
                        }).map((item) => {
                            return {
                                "latitude": item.lat,
                                "longitude": item.lng,
                                "radius": item.radius / 1000,
                                "distance_unit": "kilometer"
                            }
                        }),
                    },
                    genders: this.state.targeting.gender.value === 0 ? [] : (this.state.targeting.gender.value === 1 ? [1] : [2]),
                    age_min: this.state.targeting.age_min.value,
                    age_max: this.state.targeting.age_max.value,
                    publisher_platforms: channels,
                    facebook_positions: facebook_positions,
                    instagram_positions: instagram_positions,
                    messenger_positions: messenger_positions,
                    device_platforms: ['mobile', 'desktop'],
                    flexible_spec: [
                        {
                            interests: this.state.targeting[channel].interests ? this.state.targeting[channel].interests.map((item) => {
                                return {id: item.id, name: item.name}
                            }) : [],
                            behaviors: this.state.targeting[channel].behaviors ? this.state.targeting[channel].behaviors.map((item) => {
                                return {id: item.id, name: item.name}
                            }) : []
                        }
                    ]
                };
                let estimate_data = {
                    client: this.state.client.id,
                    channel: channel,
                    targeting: targeting_spec
                };
                calls.getFacebookAudience(estimate_data).then((response) => {
                    this.state.targeting[channel].audience = response.data.users;
                    this.setState({
                        targeting: this.state.targeting
                    }, () => {
                        this.props.onChange(this.state.targeting);
                    })
                }, (error) => {
                    this.props.onChange(this.state.targeting);
                });
            }catch (e) {}
        },
        behaviors: () => {
            let channel = this.state.channels.facebook ? 'facebook' : 'instagram';
            let behavior_data = {
                client: this.state.client.id,
                channel: channel,
            };
            this.setState({
                loading_behaviors: true
            }, () => {
                calls.getBehaviors(behavior_data).then((response) => {
                    this.setState({
                        loading_behaviors: false,
                        behaviors: response.data
                    })
                })
            })
        },
        removeBehavior: (value) => {
            if (this.state.channels.facebook) {
                this.state.targeting.facebook.behaviors = this.state.targeting.facebook.behaviors.filter((item) => {
                    return item.name !== value.name
                })
            } else {
                this.state.targeting.instagram.behaviors = this.state.targeting.instagram.behaviors.filter((item) => {
                    return item.name !== value.name
                })
            }
            this.setState({
                targeting: this.state.targeting
            }, () => {
                this.functions.audience();
            })
        },
        interests: (search) => {
            let channel = this.state.channels.facebook ? 'facebook' : 'instagram';
            let behavior_data = {
                client: this.state.client.id,
                channel: channel,
                query: search
            };
            if (search !== "") {
                this.setState({
                    loading_interests: true
                }, () => {
                    calls.getInterests(behavior_data).then((response) => {
                        this.setState({
                            loading_interests: false,
                            interests: response.data
                        })
                    })
                })
            } else {
                this.setState({
                    loading_interests: false,
                    interests: []
                });
            }
        },
        removeInterest: (value) => {
            if (this.state.channels.facebook) {
                this.state.targeting.facebook.interests = this.state.targeting.facebook.interests.filter((item) => {
                    return item.name !== value.name
                });
            } else {
                this.state.targeting.instagram.interests = this.state.targeting.instagram.interests.filter((item) => {
                    return item.name !== value.name
                });
            }
            this.setState({
                targeting: this.state.targeting
            }, () => {
                this.functions.audience();
            })
        }
    };

    renders = {
        categories: () => {
            let a = [];
            try {
                this.state.behaviors.map((item) => {
                    return item.path[0];
                }).map((x) => {
                    if (!a.includes(x)) {
                        a.push(x);
                    }
                });
                return a.map((item) => {
                    return {name: item}
                });
            } catch (e) {
                return a;
            }
        },
        behaviors: () => {
            try {
                return this.state.behaviors.filter((item) => {
                    return item.path[0] === this.state.behaviors_category.name;
                })
            } catch (e) {
                return [];
            }
        },
        selectedBehaviors: () => {
            let behaviors = [];
            if (this.state.channels.facebook) {
                if (this.state.targeting.facebook) {
                    behaviors = this.state.targeting.facebook.behaviors ? this.state.targeting.facebook.behaviors : [];
                }
            } else {
                if (this.state.targeting.instagram) {
                    behaviors = this.state.targeting.instagram.behaviors ? this.state.targeting.instagram.behaviors : [];
                }
            }
            return behaviors;
        },
        selectedInterests: () => {
            let interests = [];
            if (this.state.channels.facebook) {
                if (this.state.targeting.facebook) {
                    interests = this.state.targeting.facebook.interests ? this.state.targeting.facebook.interests : [];
                }
            } else {
                if (this.state.targeting.instagram) {
                    interests = this.state.targeting.instagram.interests ? this.state.targeting.instagram.interests : [];
                }
            }
            return interests;
        },
        verticalFormatOnly: () => {
            try {
                let images = this.state.creatives.image_ads?this.state.creatives.image_ads.filter((item) => {return item.show}):[];
                let videos = this.state.creatives.video_ads?this.state.creatives.video_ads.filter((item) => {return item.show}):[];
                let dynamic = this.state.creatives.dynamic_ads?this.state.creatives.dynamic_ads.filter((item) => {return item.show}):[];
                let total = images.length + videos.length;
                let vertical_images = images.filter((item) => {return item.creative.ratio === '9:16'});
                let vertical_videos = videos.filter((item) => {return item.creative.ratio === '9:16'});
                let dynamic_valid = dynamic.filter((item) => {return item.catalog && item.product_set});
                let vertical_total = vertical_images.length + vertical_videos.length;
                return vertical_total === total && dynamic_valid.length < 1;
            } catch (e) {
                return false
            }
        },
        normalFormatOnly: () => {
            try {
                let images = this.state.creatives.image_ads?this.state.creatives.image_ads.filter((item) => {return item.show}):[];
                let videos = this.state.creatives.video_ads?this.state.creatives.video_ads.filter((item) => {return item.show}):[];
                let dynamic = this.state.creatives.dynamic_ads?this.state.creatives.dynamic_ads.filter((item) => {return item.show}):[];
                let total = images.length + videos.length + dynamic.length;
                let vertical_images = images.filter((item) => {return item.creative.ratio !== '9:16'});
                let vertical_videos = videos.filter((item) => {return item.creative.ratio !== '9:16'});
                let dynamic_valid = dynamic.filter((item) => {return item.catalog && item.product_set});
                let vertical_total = vertical_images.length + vertical_videos.length + dynamic_valid.length;
                //console.log(vertical_total, total);
                return vertical_total === total;
            } catch (e) {
                //console.log(e);
                return false
            }
        },
        noFormats: () => {
            try {
                let images = this.state.creatives.image_ads?this.state.creatives.image_ads.filter((item) => {return item.show}):[];
                let videos = this.state.creatives.video_ads?this.state.creatives.video_ads.filter((item) => {return item.show}):[];
                let dynamic = this.state.creatives.dynamic_ads?this.state.creatives.dynamic_ads.filter((item) => {return item.show}):[];
                let dynamic_valid = dynamic.filter((item) => {return item.catalog && item.product_set});
                let total = images.length + videos.length + dynamic_valid.length;
                return total.length < 1
            } catch (e) {
                return false
            }
        },
        bothFormats: () => {
            try{
                return !this.renders.noFormats() && !this.renders.normalFormatOnly() && !this.renders.verticalFormatOnly()
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div>
                <div className="Section">
                    <Dropdown
                        filter={false}
                        searchFilter={false}
                        search={false}
                        title={'Behaviors'}
                        size={'half'}
                        loading={this.state.loading_behaviors}
                        placeholder={"Select category here ..."}
                        options={this.renders.categories()}
                        value={this.state.behaviors_category ? this.state.behaviors_category : {}}
                        onChange={(value) => {
                            this.setState({
                                behaviors_category: value
                            });
                        }}
                    />
                    <Dropdown
                        filter={true}
                        searchFilter={false}
                        search={false}
                        noTitle={false}
                        title={"Behaviors options"}
                        size={'half'}
                        disabled={!this.state.behaviors_category}
                        removeArrow={!this.state.behaviors_category}
                        loading={this.state.loading_behaviors}
                        placeholder={this.state.behaviors_category ? "Select behavior here .." : "First Select category"}
                        options={this.renders.behaviors()}
                        value={{}}
                        onChange={(value) => {
                            if (this.state.channels.facebook) {
                                if (!this.state.targeting.facebook.behaviors) {
                                    this.state.targeting.facebook.behaviors = [];
                                }
                                if (this.state.targeting.facebook.behaviors.filter((item) => {
                                    return item.name === value.name
                                }).length < 1) {
                                    this.state.targeting.facebook.behaviors.push(value);
                                }
                            } else {
                                if (!this.state.targeting.instagram.behaviors) {
                                    this.state.targeting.instagram.behaviors = [];
                                }
                                if (this.state.targeting.instagram.behaviors.filter((item) => {
                                    return item.name === value.name
                                }).length < 1) {
                                    this.state.targeting.instagram.behaviors.push(value);
                                }
                            }
                            this.setState({
                                targeting: this.state.targeting
                            }, () => {
                                this.functions.audience();
                            })
                        }}
                    />
                </div>
                {
                    this.state.behaviors_category && false &&
                    <div style={{paddingTop: '10px'}} className="Section">
                        <Dropdown
                            filter={true}
                            searchFilter={false}
                            search={false}
                            noTitle={true}
                            size={'full'}
                            disabled={!this.state.behaviors_category}
                            noArrow={!this.state.behaviors_category}
                            loading={this.state.loading_behaviors}
                            placeholder={this.state.behaviors_category ? "Select behavior here .." : "Select category first"}
                            options={this.renders.behaviors()}
                            value={{}}
                            onChange={(value) => {
                                if (this.state.channels.facebook) {
                                    if (!this.state.targeting.facebook.behaviors) {
                                        this.state.targeting.facebook.behaviors = [];
                                    }
                                    if (this.state.targeting.facebook.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.facebook.behaviors.push(value);
                                    }
                                } else {
                                    if (!this.state.targeting.instagram.behaviors) {
                                        this.state.targeting.instagram.behaviors = [];
                                    }
                                    if (this.state.targeting.instagram.behaviors.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.instagram.behaviors.push(value);
                                    }
                                }
                                this.functions.audience();
                            }}
                        />
                    </div>
                }
                {
                    (this.state.channels.facebook || this.state.channels.instagram) &&
                    <div
                        style={this.renders.selectedBehaviors().length > 0 ? {paddingTop: '10px'} : {paddingTop: '0px'}}
                        className="Section">
                        <div className="Locations-Items">
                            {
                                this.renders.selectedBehaviors().map((item) => {
                                    return (
                                        <div className="targetingItem">
                                            <div className="Location-Item">
                                                <div className="Location-ItemLeft">
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.removeBehavior(item);
                                            }} className="targetingItem-Remove ButtonRed">
                                                <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    <div className="Section">
                        <Dropdown
                            filter={true}
                            searchFilter={true}
                            search={true}
                            title={'Interests'}
                            size={'full'}
                            loading={this.state.loading_interests}
                            placeholder={"Search here .."}
                            options={this.state.interests}
                            value={this.state.interests_search}
                            onSearch={(value) => {
                                this.functions.interests(value);
                            }}
                            onChange={(value) => {
                                if (this.state.channels.facebook) {
                                    if (!this.state.targeting.facebook.interests) {
                                        this.state.targeting.facebook.interests = [];
                                    }
                                    if (this.state.targeting.facebook.interests.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.facebook.interests.push(value);
                                    }
                                } else {
                                    if (!this.state.targeting.instagram.interests) {
                                        this.state.targeting.instagram.interests = [];
                                    }
                                    if (this.state.targeting.instagram.interests.filter((item) => {
                                        return item.name === value.name
                                    }).length < 1) {
                                        this.state.targeting.instagram.interests.push(value);
                                    }
                                }
                                this.functions.audience();
                            }}
                        />
                    </div>
                }
                {
                    (this.state.channels.facebook || this.state.channels.instagram) &&
                    <div
                        style={this.renders.selectedInterests().length > 0 ? {paddingTop: '10px'} : {paddingTop: '0px'}}
                        className="Section">
                        <div className="Locations-Items">
                            {
                                this.renders.selectedInterests().map((item) => {
                                    return (
                                        <div className="targetingItem">
                                            <div className="Location-Item">
                                                <div className="Location-ItemLeft">
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.removeInterest(item);
                                            }} className="targetingItem-Remove ButtonRed">
                                                <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            searchFilter={false}
                            search={false}
                            title={'Special Ad Category'}
                            size={'full'}
                            placeholder={"Select category here ..."}
                            options={[
                                {name: 'Housing', value: "HOUSING"},
                                {name: 'Credit', value: "CREDIT"},
                                {name: 'Employment', value: "EMPLOYMENT"},
                                {name: 'None', value: "NONE"}
                            ]}
                            value={this.state.targeting.special_ad_category ? this.state.targeting.special_ad_category : {}}
                            onChange={(value) => {
                                this.state.targeting.special_ad_category = value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting.placement && false &&
                    <div className="Section">
                        <div style={{width: '100%'}}>
                            <div className="WeekDaysTitle">
                                <div className={cx("InputContainerTitleText")}>
                                    Placement
                                </div>
                            </div>
                            {
                                <div className="WeekDaysItems">
                                    <div style={{with: '100%'}}>
                                        {
                                            false &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>All compatible placements</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.channels.instagram && this.state.channels.facebook &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconInstagram noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={this.state.targeting.placement.all}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.all = checked;
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            false&&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        <div className="WeekDaysItems-Item">
                                            <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                <div style={{marginRight: '3px'}}>Feed</div>
                                                {
                                                    this.state.channels.facebook && this.state.channels.instagram &&
                                                    <div style={{marginLeft: '3px'}}>
                                                        <IconFacebook noMargin={true}/>
                                                    </div>
                                                }
                                                {
                                                    this.state.channels.instagram && this.state.channels.facebook &&
                                                    <div style={{marginLeft: '3px'}}>
                                                        <IconInstagram noMargin={true}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className="WeekDaysItems-ItemSlider">
                                                <Switch
                                                    onColor="#24bda8"
                                                    disabled={this.renders.verticalFormatOnly() && (!this.state.channels.facebook || !this.state.channels.instagram)}
                                                    checked={this.state.targeting.placement.feed}
                                                    onChange={(checked) => {
                                                        this.state.targeting.placement.feed = checked;
                                                        this.setState({
                                                            targeting: this.state.targeting
                                                        }, () => {
                                                            this.functions.audience();
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="WeekDaysItems-Item-Border"/>
                                        <div className="WeekDaysItems-Item">
                                            <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                <div style={{marginRight: '3px'}}>Story</div>
                                                {
                                                    this.state.channels.facebook && this.state.channels.instagram &&
                                                    <div style={{marginLeft: '3px'}}>
                                                        <IconFacebook noMargin={true}/>
                                                    </div>
                                                }
                                                {
                                                    this.state.channels.instagram && this.state.channels.facebook &&
                                                    <div style={{marginLeft: '3px'}}>
                                                        <IconInstagram noMargin={true}/>
                                                    </div>
                                                }
                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical
                                                    format
                                                </div>
                                            </div>
                                            <div className="WeekDaysItems-ItemSlider">
                                                <Switch
                                                    onColor="#24bda8"
                                                    checked={this.state.targeting.placement.story}
                                                    //disabled={this.renders.normalFormatOnly()}
                                                    onChange={(checked) => {
                                                        this.state.targeting.placement.story = checked;
                                                        this.setState({
                                                            targeting: this.state.targeting
                                                        }, () => {
                                                            this.functions.audience();
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Right hand column</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        disabled={this.renders.verticalFormatOnly()}
                                                        checked={this.state.targeting.placement.right_hand_column}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.right_hand_column = checked;
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Instant article</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={this.state.targeting.placement.instant_article}
                                                        disabled={this.renders.verticalFormatOnly()}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.instant_article = checked;
                                                            if (checked) {
                                                                this.state.targeting.placement.feed = true;
                                                            }
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Marketplace</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={this.state.targeting.placement.marketplace}
                                                        disabled={this.renders.verticalFormatOnly()}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.marketplace = checked;
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Messenger Inbox</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={this.state.targeting.placement.messenger_inbox}
                                                        disabled={this.renders.verticalFormatOnly()}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.messenger_inbox = checked;
                                                            if (checked) {
                                                                this.state.targeting.placement.feed = true;
                                                            }
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.facebook &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Messenger Story</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconFacebook noMargin={true}/>
                                                        </div>
                                                    }
                                                    <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical
                                                        format
                                                    </div>
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={this.state.targeting.placement.messenger_story}
                                                        //disabled={this.renders.normalFormatOnly()}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.messenger_story = checked;
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.channels.instagram &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                        {
                                            this.state.channels.instagram &&
                                            <div className="WeekDaysItems-Item">
                                                <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                    <div style={{marginRight: '3px'}}>Explore</div>
                                                    {
                                                        this.state.channels.facebook && this.state.channels.instagram &&
                                                        <div style={{marginLeft: '3px'}}>
                                                            <IconInstagram noMargin={true}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="WeekDaysItems-ItemSlider">
                                                    <Switch
                                                        onColor="#24bda8"
                                                        disabled={this.renders.verticalFormatOnly()}
                                                        checked={this.state.targeting.placement.explore}
                                                        onChange={(checked) => {
                                                            this.state.targeting.placement.explore = checked;
                                                            this.setState({
                                                                targeting: this.state.targeting
                                                            }, () => {
                                                                this.functions.audience();
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div style={{paddingTop: '10px'}} className="Section"></div>
            </div>
        )
    }
}

export default AdvertisingTargetingFacebook;
