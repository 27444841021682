import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../../components/facebook";
import Dropdown from "../../components/dropdown";
import Input from "../../components/input";
import Switch from "../../components/advertising-channels";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";

class AgencyUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            users: [],
            selected: null
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentDidMount() {
        this.init.users();
    }

    init = {
        users: () => {
            calls.getUsers(this.state.item._id).then((response) => {
                this.setState({
                    users: response.data
                })
            }, (error) => { });
        }
    };

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.addUser({
                    name: this.state.name,
                    email: this.state.email,
                    type: 'agent',
                    agency: this.state.item._id
                }).then((response) => {
                    if (response.data) {
                        this.state.users.push(response.data);
                    } else {
                        this.state.users.push(response);
                    }
                    this.setState({
                        users: this.state.users,
                        loading: false,
                        new: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: error.body.message
                    })
                });
            });
        },
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false
            }, () => {
                calls.removeUser(this.state.selected).then((response) => {
                    let users = this.state.users.filter((item) => {
                        return item._id !== this.state.selected;
                    });
                    this.setState({
                        loading: false,
                        selected: null,
                        users: users
                    })
                }, (error) => {
                    this.setState({
                        selected: null,
                        error_modal: true,
                        loading: false,
                        error_message: error.body.message
                    });
                });
            })
        },
        open: (item) => {
            if (!this.state.loading && this.state.selected !== item._id) {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/login/" + item);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/login/" + item);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/login/" + item);
                }
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove user"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({
                            warning_modal: false,
                            selected: null
                        });
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div>
                    {this.state.users.length > 0 &&
                        <div className="agency-user__block">
                            {
                                this.state.users.map((item, j) => {
                                    return (
                                        <div className="agency-user__block--inner mb-3 pb-3 border-b" key={j}>
                                            <div className="flex flex-row flex-wrap justify-between">
                                                <div>
                                                    <div className="font-bold text-sm">{item.name}</div>
                                                    <div className="text-xs">{item.email}</div>
                                                </div>
                                                <div className="md:ml-2">
                                                    <button onClick={() => {
                                                        this.functions.open(item._id);
                                                    }} className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                                        <FeatherIcon className="stroke-current mr-1" size={16} icon="external-link" /> <span>Show user</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex flex-row flex-wrap mt-2">
                                                {item.status === 'pending' && !this.state.loading && this.state.selected !== item._id &&
                                                    <div className="mb-2 mr-2 md:mb-0">
                                                        <button onClick={() => {
                                                            if (window.location.hostname === 'localhost') {
                                                                window.open("http://localhost:5001/set-password/" + item.resetPasswordToken);
                                                            } else if (window.location.hostname === 'app.adcredo.io') {
                                                                window.open("https://app.adcredo.io/set-password/" + item.resetPasswordToken);
                                                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                window.open("https://dev.adcredo.io/set-password/" + item.resetPasswordToken);
                                                            }
                                                        }} className="bg-transparent bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                                            <FeatherIcon className="stroke-current mr-1" size={16} icon="lock" /> <span>Change password</span>
                                                        </button>
                                                    </div>
                                                }
                                                <div className="mb-2 mr-2 md:mb-0">
                                                    <button onClick={() => {
                                                        if (!this.state.loading && this.state.selected !== item._id) {
                                                            this.setState({
                                                                selected: item._id,
                                                                warning_modal: true
                                                            })
                                                        }
                                                    }} className="bg-transparent bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                        <FeatherIcon className="stroke-current mr-1" size={16} icon="trash" /> <span>Delete user</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="Section">
                            <Input
                                title={'Name'}
                                value={this.state.name}
                                type={"text"}
                                maxLength={40}
                                onChange={(value) => this.setState({ name: value.target.value }, () => {

                                })}
                            />
                            <Input
                                title={'Email'}
                                value={this.state.email}
                                placeholder={""}
                                type={"text"}
                                onChange={(value) => this.setState({ email: value.target.value }, () => {

                                })}
                            />
                        </div>
                    }
                    {
                        !this.state.new &&
                        <div className="mt-4">
                            <div onClick={() => {
                                this.setState({
                                    new: true
                                })
                            }} className="py-3 px-4 bg-green-100 text-green-600 transition-all duration-200 hover:bg-green-500 hover:text-white rounded-full font-bold w-full text-sm text-center">
                                + Create new user
                            </div>
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="Section">
                            <div onClick={() => {
                                this.functions.create()
                            }} className="Button ButtonFull">
                                {
                                    !this.state.loading &&
                                    <div>Add user</div>
                                }
                                {
                                    this.state.loading &&
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={12}
                                        color={'#ffffff'}
                                        loading={true}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AgencyUsers;
