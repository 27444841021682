import React, { Component, Fragment } from 'react';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArchiveIcon, ArrowRightIcon, CameraIcon, ThumbUpIcon, ChatAltIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, CollectionIcon, DuplicateIcon, ExternalLinkIcon, HeartIcon, PencilAltIcon, PencilIcon, SearchIcon, TrashIcon, UserAddIcon, VideoCameraIcon, ReplyIcon } from '@heroicons/react/outline';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import EditFacebookCampaign from '../moduleFiles/editFacebookCampaign';
import EditFacebookAdset from '../moduleFiles/editFacebookAdset';
import EditFacebookAd from '../moduleFiles/editFacebookAd';
import CreateFacebookPost from '../moduleFiles/createFacebookPost';

class OrganicPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            tabs: {
                facebook: [
                    { name: "Images", value: "image", new: false },
                    { name: "Videos", value: "video", new: false },
                    { name: "Events", value: "event", new: false },
                    { name: "Ratings", value: "ratings", new: false },
                    { name: "Tagged", value: "tagged", new: false },
                    { name: "Other", value: "other", new: false },
                ]
            },
            stats: {
                facebook: [
                    { name: "Images", value: "image" },
                    { name: "Videos", value: "video" },
                    { name: "Events", value: "event" },
                    { name: "Ratings", value: "ratings" },
                    { name: "Tagged", value: "tagged" },
                    { name: "Other", value: "other" },
                ]
            },
            client: { id: 0, name: "Click here ..." },
            page: { id: 0, name: "Click here ..." },
            overview: null,
            tab: { name: "Images", value: "image", new: false },
            page_limit: 10,
            page_nr: 1,
            edit: false,
            selected: {},
            pages: [],
            feed: [],
            ratings: [],
            tagged: []
        }
    };

    componentDidMount() {
        this.functions.clients();
    }

    functions = {
        clients: async () => {
            await this.promisedSetState({ loading_clients: true });
            try {
                let response = await this.calls.clients();
                if (response) {
                    await this.promisedSetState({ clients: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_clients: false });
        },
        pages: async () => {
            try {
                await this.promisedSetState({ loading_pages: true });
                let response = await this.calls.pages();
                await this.promisedSetState({ loading_pages: false, pages: Array.isArray(response.data) ? response.data : [] })
            } catch (error) { }
        },
        feed: async () => {
            try {
                await this.promisedSetState({ loading_feed: true });
                let response = await this.calls.feed();
                await this.promisedSetState({ loading_feed: false, feed: Array.isArray(response.data) ? response.data : [] })
            } catch (error) { }
        },
        ratings: async () => {
            try {
                await this.promisedSetState({ loading_ratings: true });
                let response = await this.calls.ratings();
                await this.promisedSetState({ loading_ratings: false, ratings: Array.isArray(response.data) ? response.data : [] })
            } catch (error) { }
        },
        tagged: async () => {
            try {
                await this.promisedSetState({ loading_tagged: true });
                let response = await this.calls.tagged();
                await this.promisedSetState({ loading_tagged: false, tagged: Array.isArray(response.data) ? response.data : [] })
            } catch (error) { }
        }
    };

    open = {

    };

    calls = {
        clients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients";
            return apiRegister.call(options, url);
        },
        pages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        feed: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listFacebookMedia?client=" + this.state.client.id + "&page=" + this.state.page.id;
            return apiRegister.call(options, url);
        },
        ratings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPageRatings?client=" + this.state.client.id + "&page=" + this.state.page.id;
            return apiRegister.call(options, url);
        },
        tagged: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPageTagged?client=" + this.state.client.id + "&page=" + this.state.page.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        type: (item) => {
            try {
                if (item.attachments && item.attachments.data[0].type == "photo") {
                    return "image";
                } else if (item.attachments && item.attachments.data[0].type == "video_inline") {
                    return "video";
                } else if (item.attachments && item.attachments.data[0].type == "event") {
                    return "event";
                } else {
                    return "other"
                }
            } catch (error) {
                console.log(error);
            }
        },
        media: () => {
            try {

            } catch (error) { }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }


    render() {

        return (
            <div className="pt-8">

                <SlideoutTailwind
                    title={this.state.new ? "Create" : "Update"}
                    submitButtonText={"save"}
                    loader={this.state.edit_loader}
                    open={(this.state.selected.id || this.state.new) ? true : false}
                    onCancel={() => {
                        this.setState({ selected: {}, new: false })
                    }}
                    onSubmit={() => {

                    }}
                >
                    {
                        false &&
                        this.state.new &&
                        <CreateFacebookPost
                            ref="CreateFacebookPost"
                            client={this.state.client}
                            onLoader={(value) => { this.setState({ edit_loader: value }) }}
                            onSubmit={(value) => { this.setState({ selected: {} }) }}
                        />
                    }
                </SlideoutTailwind>

                <div className="rounded-md shadow">
                    <div className="bg-white px-4 py-5 rounded-t-md">
                        <div className="">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Organic</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit quam corrupti consectetur.
                            </p>
                        </div>
                        <div className="mt-5 grid grid-cols-6 gap-4">
                            <div className="col-span-6 sm:col-span-3">
                                <DropdownTailwind
                                    label={"Select client"}
                                    selected={this.state.client}
                                    loader={this.state.loading_clients}
                                    options={Array.isArray(this.state.clients) ? this.state.clients.map((item) => { item.image = item.logo; return item }) : []}
                                    onChange={(client) => {
                                        this.promisedSetState({ client: client });
                                        this.functions.pages();
                                    }}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <DropdownTailwind
                                    label={"Select page"}
                                    selected={this.state.page}
                                    options={this.state.pages}
                                    disabled={this.state.client.id == 0}
                                    loader={this.state.loading_pages}
                                    onChange={(page) => {
                                        this.promisedSetState({ page: page })
                                        this.functions.feed();
                                        this.functions.ratings();
                                        this.functions.tagged();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading_feed &&
                        <div className="border-t bg-white h-48 flex justify-center items-center rounded-b-md">
                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }
                    {
                        !this.state.loading_feed &&
                        Array.isArray(this.state.feed) && this.state.feed.length > 0 &&
                        <dl className={"border-t grid grid-cols-1 bg-white overflow-hidden divide-y divide-gray-200 md:divide-y-0 md:divide-x md:grid-cols-" + this.state.stats["facebook"].length}>
                            {this.state.stats["facebook"].map((item) => (
                                <div key={item.value} className="px-4 py-5 sm:p-6 overflow-hidden">
                                    <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                                    {
                                        item.value !== "ratings" && item.value !== "tagged" &&
                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{this.state.feed.filter((post) => { return this.renders.type(post) == item.value }).length}</dd>
                                    }
                                    {
                                        item.value === "ratings" &&
                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{Array.isArray(this.state.ratings) ? this.state.ratings.length : 0}</dd>
                                    }
                                    {
                                        item.value === "tagged" &&
                                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{Array.isArray(this.state.tagged) ? this.state.tagged.length : 0}</dd>
                                    }
                                </div>
                            ))}
                        </dl>
                    }
                    {
                        !this.state.loading_feed &&
                        Array.isArray(this.state.feed) && this.state.feed.length > 0 &&
                        <div className="border-t">
                            <div className="bg-white">
                                <div className="px-4 py-5 sm:px-6">
                                    <div className="flex">
                                        <div className="flex flex-1">
                                            <div className="sm:hidden w-full">
                                                <label htmlFor="tabs" className="sr-only">
                                                    Select a tab
                                                </label>
                                                <select
                                                    id="tabs"
                                                    name="tabs"
                                                    value={this.state.tab}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            tab: value
                                                        })
                                                    }}
                                                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                >
                                                    {this.state.tabs["facebook"].map((tab) => (
                                                        <option key={tab.name}>{tab.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="hidden sm:block">
                                                <nav className="flex space-x-4" aria-label="Tabs">
                                                    {this.state.tabs["facebook"].map((tab) => (
                                                        <div
                                                            onClick={() => (
                                                                this.setState({
                                                                    tab: tab
                                                                })
                                                            )}
                                                            key={tab.name}
                                                            className={cn(
                                                                tab.value == this.state.tab.value ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                                            )}
                                                            aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                                        >
                                                            {tab.name}
                                                        </div>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="flex justify-items-end">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        new: true
                                                    })
                                                }}
                                                type="button"
                                                className={(this.state.tab.new ? "" : "hidden") + " inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                Create new
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tab.value == "ratings" &&
                                    !this.state.loading_feed &&
                                    this.state.tab.value == "ratings" && Array.isArray(this.state.ratings) && this.state.ratings.length > 0 &&
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-100 bg-opacity-50">
                                            <tr>
                                                <th scope="col" className="py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Created
                                                </th>
                                                <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-gray-900">
                                                    Review text
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {this.state.ratings.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {item.created_time}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {item.review_text}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                                {
                                    (this.state.tab.value == "tagged") &&
                                    !this.state.loading_feed &&
                                    Array.isArray(this.state.tagged) && this.state.tagged.length > 0 &&
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-100 bg-opacity-50">
                                            <tr>
                                                <th scope="col" className="py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Created
                                                </th>
                                                <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-gray-900">
                                                    Post ID
                                                </th>
                                                <th scope="col" className="px-3 py-4 text-left text-sm font-semibold text-gray-900">
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {this.state.tagged.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {item.created_time}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {item.id}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <a className="text-purple-500" href={item.permalink_url} target="_blank">
                                                            Go to post
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                                {
                                    this.state.tab.value !== "ratings" && this.state.tab.value !== "tagged" &&
                                    !this.state.loading_feed &&
                                    Array.isArray(this.state.feed) && this.state.feed.length > 0 &&
                                    <div class="grid grid-cols-4 gap-4 px-4 py-5 sm:px-6 border-t">
                                        {
                                            (this.state.tab.value == "tagged" ? this.state.tagged : this.state.feed).filter((item) => {
                                                return (this.state.tab.value != "tagged" && this.renders.type(item) == this.state.tab.value) || this.state.tab.value == "tagged"
                                            }).map((item) => {
                                                return (
                                                    <div className={(this.state.selected && this.state.selected.id == item.id ? "border-2 border-purple-500" : "border-2") + " relative rounded-md overflow-hidden cursor-pointer"}>
                                                        <div onClick={() => {
                                                            window.open(item.permalink_url, "_blank");
                                                        }} style={{ paddingTop: "100%" }} className="relative">
                                                            <img src={item.full_picture} class="w-full h-full absolute inset-0 object-cover" />

                                                        </div>
                                                        <div className="px-4 py-2 grid border-t-2 grid-cols-3 gap-4">
                                                            <div>
                                                                <div className="flex flex-row justify-center items-center">
                                                                    <span className="font-bold text-xs mr-2">{item.likes && Array.isArray(item.likes.data) ? item.likes.data.length : 0}</span>
                                                                    <ThumbUpIcon className="h-4 w-4" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex flex-row justify-center items-center">
                                                                    <span className="font-bold text-xs mr-2">{item.comments && Array.isArray(item.comments.data) ? item.comments.data.length : 0}</span>
                                                                    <ChatAltIcon className="h-4 w-4" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex flex-row justify-center items-center">
                                                                    <span className="font-bold text-xs mr-2">{item.sharedposts && Array.isArray(item.sharedposts.data) ? item.sharedposts.data.length : 0}</span>
                                                                    <ReplyIcon className="h-4 w-4 rotate-90 transform" aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    /*
                                    <nav
                                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{this.state.page_nr * this.state.page_limit - this.state.page_limit + 1}</span> to <span className="font-medium">{((this.state.page_nr * this.state.page_limit) > this.state.overview.channels["facebook"][this.state.tab.value].length) ? this.state.overview.channels[this.state.channel.value][this.state.tab.value].length : this.state.page_nr * this.state.page_limit}</span> of{' '}
                                            <span className="font-medium">{this.state.overview.channels["facebook"][this.state.tab.value].length}</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <div
                                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Previous
                                        </div>
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    page_nr: this.state.page_nr + 1
                                                })
                                            }}
                                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Next
                                        </div>
                                    </div>
                                </nav>
                                */
                                }

                            </div>
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default OrganicPosts;
