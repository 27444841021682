import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import {SlideDown} from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesFacebookImageMobile from "../advertising-creatives-facebook-image-mobile";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import {calls} from "./calls";

class AdvertisingCreativesAdsItemCreativeThumbnail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            channels: this.props.channels,
            loading: false,
            theInputKey: "",
            error: false,
            error_message: "",
            noTitle: false,
            marginBottom: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            channels: nextProps.channels,
            noTitle: nextProps.noTitle,
            marginBottom: nextProps.marginBottom
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            item: this.props.item,
            noTitle: this.props.noTitle,
            marginBottom: this.props.marginBottom
        });
    }

    init = {};

    functions = {
        uploadThumbnail: (file) => {
            //console.log(file);
            const formData = new FormData();
            formData.append('file', file);
            this.setState({
                loading: true,
                error: false,
            }, () => {
                calls.uploadImage(formData).then((response) => {
                    if(this.functions.checkRatio(this.state.item.creative.ratio, response.data.ratio)){
                        this.state.item.creative.thumbnail = {
                            url: response.data.url,
                            format: response.data.format,
                            ratio: response.data.ratio
                        };
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onChange(this.state.item);
                        })
                    }else{
                        this.setState({
                            error: true,
                            loading: false,
                            error_message: "Thumbnail must have same ratio as video"
                        })
                    }
                }, (error) => {
                    this.setState({
                        error: true,
                        loading: false,
                        error_message: "Something went wrong"
                    })
                });
            });
        },
        onSelectFile: (e) => {
            if (e.target.files && e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    if(e.target.files[i].size < 100000000){
                        if (e.target.files[i].type === 'image/jpg' || e.target.files[i].type === 'image/jpeg' || e.target.files[i].type === 'image/png') {
                            this.functions.uploadThumbnail(e.target.files[i]);
                            const randomString = Math.random().toString(36);
                            this.setState({
                                theInputKey: randomString,
                            });
                        }
                    }else{
                        //TO LARGE
                    }
                }
            }
        },
        checkRatio: (first, second) => {
            let first_numbers = first.split(":");
            let second_numbers = second.split(":");

            let third_first = parseFloat(first_numbers[0]) * parseFloat(second_numbers[1]);
            let third_second = parseFloat(first_numbers[1]) * parseFloat(second_numbers[1]);

            let fourth_first = parseFloat(first_numbers[1]) * parseFloat(second_numbers[0]);
            let fourth_second = parseFloat(first_numbers[1]) * parseFloat(second_numbers[1]);

            return third_first === fourth_first && third_second === fourth_second;
        }
    };

    validators = {};

    renders = {
        thumbnail: () => {
            try {
                return this.state.item.creative.thumbnail.url;
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return(
            <div style={this.state.marginBottom?{marginBottom: '10px'}:{}} className="Creative-ads-thumbnail">
                {
                    !this.state.noTitle &&
                    <div className="font-bold text-xs mb-1">
                        Video thumbnail
                    </div>
                }
                <div className="Creative-ads-thumbnail-inner">
                    {
                        this.renders.thumbnail() && !this.state.loading &&
                        <div className="Creative-ads-thumbnail-inner-preview">
                            <img src={this.renders.thumbnail()} style={{height: '100%'}}/>
                        </div>
                    }
                    <div style={(this.renders.thumbnail()&&!this.state.loading)?{}:{borderColor: '#FC5353'}} key={this.state.theInputKey || ''} className="Creative-ads-thumbnail-inner-noValue">
                        {
                            this.state.loading &&
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        }
                        {
                            !this.state.loading && !this.renders.thumbnail() && !this.state.error &&
                            <div>Click here to upload thumbnail</div>
                        }
                        {
                            !this.state.loading && this.renders.thumbnail() && !this.state.error &&
                            <div>Click here to change thumbnail</div>
                        }
                        {
                            !this.state.loading && this.state.error &&
                            <div>{this.state.error_message}</div>
                        }
                        {
                            !this.state.loading &&
                            <input
                                onChange={(e) => this.functions.onSelectFile(e)}
                                type="file"
                                accept={"image/png, image/jpg, image/jpeg"}
                                className="Upload-ImageContainerAreaPlusUpload"
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesAdsItemCreativeThumbnail;
