import React, { Component } from 'react';
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import cn from "classnames";
import { campaignLinkedin } from '../validators/campaignLinkedin';
import { ExclamationIcon } from '@heroicons/react/outline';

class EditLinkedinCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            campaign: {},
            updated: {},
            client: {},
            currency: "",
            loader: false,
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ]
        }
    };

    async componentDidMount() {
        
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard
        })
        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });
        }
        if (this.state.client.channels.filter((item) => {return item.value === "linkedin"}).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => {return item.value === "linkedin"})[0].currency
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        /*
        this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }
        */
    }

    functions = {
        update: async () => {
            if (!this.state.wizard) {
                this.props.onError(false);
                this.props.onSuccess(false);
                this.props.onLoader(true);

                let body = {
                    "patch": {
                        "$set": {
                            name: this.renders.name(),
                            status: this.renders.status().value,
                            runSchedule: {
                                start: new Date(this.renders.startDate()).getTime(),
                                end: this.renders.endDate() ? new Date(this.renders.endDate()).getTime() : null
                            }
                        }
                    }
                };

                if (this.renders.budget() > 0) {
                    body.patch["$set"].totalBudget = {
                        amount: this.renders.budget().toString(),
                        currencyCode: this.state.campaign.currencyCode
                    };
                } else {
                    //REMOVE TOTAL BUDGET
                }

                try {
                    let response = await this.calls.update(body);
                    //let updated_camaign = { ...this.state.campaign, ...response.data };
                    this.props.onSuccess("Campaign updated");
                    //this.props.onUpdate(updated_camaign);

                    //UPDATE ORDER
                    if (this.renders.endDate()) {

                    }

                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                }
                this.props.onLoader(false);
            } else {
                console.log(this.state.updated);
                this.props.onUpdate(this.state.updated);
            }
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        campaignBudget: () => {
            try {
                if ("campaign_budget" in this.state.updated) {
                    return this.state.updated.campaign_budget;
                } else {
                    return { id: 1, name: 'No', value: false }
                }
            } catch (error) {
                return { id: 1, name: 'No', value: false }
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return moment(this.state.updated.start_date).format("YYYY-MM-DD");
                } else if (this.state.campaign.runSchedule) {
                    return moment(this.state.campaign.runSchedule.start).format("YYYY-MM-DD");
                }
            } catch (error) {
                return moment().format("YYYY-MM-DD");
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? moment(this.state.updated.end_date).format("YYYY-MM-DD") : null;
                } else if (this.state.campaign.runSchedule) {
                    return this.state.campaign.runSchedule.end ? moment(this.state.campaign.runSchedule.end).format("YYYY-MM-DD") : null;
                }
            } catch (error) {
                return null;
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.campaign.status === 'ACTIVE') {
                    return { id: 1, name: 'Active', value: "ACTIVE" }
                } else if (this.state.campaign.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.campaign.status === 'CANCELED') {
                    return { id: 3, name: 'Canceled', value: "CANCELED" }
                } else if (this.state.campaign.status === 'DRAFT') {
                    return { id: 4, name: 'Draft', value: "DRAFT" }
                } else {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 2, name: 'Paused', value: "PAUSED" }
            }
        },
        totalBudget: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else if (this.state.campaign.totalBudget) {
                    return this.state.campaign.totalBudget.amount;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.state.campaign.totalBudget) {
                    return this.state.campaign.totalBudget.amount;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && campaignLinkedin.validate(this.state.updated).budget) ||
                                                (tab.id === 1 && (campaignLinkedin.validate(this.state.updated).start_date || campaignLinkedin.validate(this.state.updated).end_date)) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    <div className="col-span-6">
                        <InputTailwind
                            label={"Name"}
                            value={this.renders.name()}
                            onChange={(value) => {
                                this.state.updated.name = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                    <div className={(this.renders.campaignBudget().id == 2 ? "col-span-3" : "col-span-6") + ""}>
                        <DropdownTailwind
                            label={"Campaign total budget"}
                            selected={this.renders.campaignBudget()}
                            options={[
                                { id: 1, name: 'No', value: false },
                                { id: 2, name: 'Yes', value: true }
                            ]}
                            onChange={(value) => {
                                this.state.updated.campaign_budget = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>
                    {
                        this.renders.campaignBudget().id == 2 &&
                        <div className="col-span-3">
                            <InputTailwind
                                error={campaignLinkedin.validate(this.state.updated).budget}
                                label={"Total budget"}
                                leftSection={(this.state.currency !== "" ? true : false)}
                                leftSectionLabel={(this.state.currency)}
                                type={'number'}
                                value={this.renders.totalBudget()}
                                onChange={(value) => {
                                    this.state.updated.total_budget = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />
                        </div>
                    }
                    <div className="col-span-6">
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-span-3">
                                <InputDatepickerTailwind
                                    label={"Start date"}
                                    error={campaignLinkedin.validate(this.state.updated).start_date}
                                    value={this.renders.startDate()}
                                    onChange={(value) => {
                                        this.state.updated.start_date = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <InputDatepickerTailwind
                                    label={"End date"}
                                    error={campaignLinkedin.validate(this.state.updated).end_date}
                                    labelRight={this.renders.endDate() ? "Enabled" : "Disabled"}
                                    placeholder={!this.renders.endDate() ? "No end date" : ""}
                                    disabled={!this.renders.endDate()}
                                    value={this.renders.endDate()}
                                    onChange={(value) => {
                                        this.state.updated.end_date = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    onLabelRightClick={(value) => {
                                        if (this.renders.endDate()) {
                                            this.state.updated.end_date = null;
                                        } else {
                                            let new_date = moment(this.renders.startDate(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                                            this.state.updated.end_date = new_date;
                                        }
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default EditLinkedinCampaign;
