import React, {PureComponent} from 'react';
import FeatherIcon from 'feather-icons-react';
import {Link} from 'react-router-dom';
import ReactGridLayout from '../react-grid-layout/index';
import SlideOver from '../../../modules/slideover/index';
//import ReportsSlideOverContent from '../reports-slideover-content/index';
import PptsGenJs from '../report-pptsGenJs/index';
import Dropdown from '../../../components/dropdown';
import TextArea from '../../../components/text-area';
import Input from '../../../components/input';

import ReportsSlideOverContent from '../premium-slideout/index';

import 'react-slidedown/lib/slidedown.css';
import {SlideDown} from "react-slidedown";

import {calls} from './calls';
import {initialFakeData} from './initialFakeData';

import cx from "classnames";

import './style.css';

export default class CreateNewTemplate extends PureComponent {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.slideOver = React.createRef();
        this.child = React.createRef();
        this.pptx = React.createRef();

        this.state = {
            isDroppedDown: false,
            fakeDataForDropdown: [
                {
                    id: 1,
                    icon: 'link',
                    text: 'Generate external link for client',
                    link: '#',
                },
                {
                    id: 2,
                    icon: 'link-2',
                    text: 'Generate internal link',
                    link: '#',
                },
                {
                    id: 3,
                    icon: 'file-text',
                    text: 'Download as PDF',
                    link: '#',
                },
                {
                    id: 4,
                    icon: 'file-text',
                    text: 'Download as PowerPoint',
                    link: '',
                    func: () =>
                        this.pptx.current.functions.createNewPptx({
                            initialFakeData: this.state.initialFakeData,
                            tabs: this.state.tabs,
                            selected_logo: this.state.selected_logo,
                            title: this.state.title,
                            summary: this.state.summary,
                        }),
                },
                {
                    id: 5,
                    icon: 'plus-circle',
                    text: 'Save as new template',
                    func: () => console.log('Data for saving: ', this.state.initialFakeData),
                },
            ],
            isSlideOverClosed: true,

            tabs: [
                {id: 1, name: 'Impressions', editMode: false, backup: ''},
                {id: 2, name: 'Clicks', editMode: false, backup: ''},
                {id: 3, name: 'ROI', editMode: false, backup: ''},
            ],

            activeTab: 0,
            tabCounter: null,
            onHoverTab: false,
            initialFakeData: initialFakeData ? initialFakeData : [],
            selected_logo: '',
            title: '',
            summary: '',
            open_settings:false
        };


        this.handleClickOutside = this.functions.handleClickOutside.bind(this);
        this.onChange = this.functions.onChange.bind(this);
    }

    componentWillMount() {
        this.setState({tabCounter: this.state.tabs.length});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('mousedown', this.functions.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.functions.handleClickOutside);
    }

    functions = {
        setDropdownExport: () => {
            this.setState({isDroppedDown: !this.state.isDroppedDown});
        },

        isSlideOverClosed: () => {
            this.setState({isSlideOverClosed: !this.state.isSlideOverClosed});
        },

        handleCloseSlideOver: () => {
            this.setState({isSlideOverClosed: false});
        },

        // check, if click was outside slideover --> close slideover window
        handleClickOutside: (e) => {
            let path = e.path || (e.composedPath && e.composedPath()) || e.composedPath(e.target);

            if (this.state.isSlideOverClosed === false && !path.includes(this.slideOver.current)) {
                this.setState({isSlideOverClosed: true});
            } else if (!path.includes(this.buttonRef.current) && !path.includes(this.dropdownRef.current)) {
                this.setState({isDroppedDown: false});
            }
        },

        // data generator for 'bar' typeOfBlock
        addingBlockData: (typeOfBlock, header, data) => {
            if (typeOfBlock === 'bar') {
                let data1 = [];
                let data2 = [];

                function randomInteger(min, max) {
                    let rand = Math.floor(min + Math.random() * (max + 1 - min));
                    return Math.floor(rand);
                }

                for (let i = 0; i < 6; i++) {
                    data1.push(randomInteger(0, 10000));
                    data2.push(randomInteger(0, 10000));
                }

                data = {
                    labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

                    datasets: [
                        {
                            label: 'Impressions',
                            backgroundColor: '#C2F4EE',
                            hoverBorderColor: '#C2F4EE',
                            data: data1,
                        },

                        {
                            label: 'Completed views',
                            backgroundColor: '#1AD5BD',
                            hoverBorderColor: '#1AD5BD',
                            data: data2,
                        },
                    ],
                };
            }

            // data generator for 'doughnut' typeOfBlock
            if (typeOfBlock === 'doughnut') {
                let data1 = [];

                function randomInteger(min, max) {
                    let rand = Math.floor(min + Math.random() * (max + 1 - min));
                    return Math.floor(rand);
                }

                let a = randomInteger(0, 2000);
                let b = randomInteger(0, 2000);

                data1.push(a);
                data1.push(b);

                data = {
                    labels: ['Bonnier', 'Prog. Display'],
                    datasets: [
                        {
                            data: data1,
                            backgroundColor: ['#C3FCE0', '#6FDB9D'],
                            hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
                        },
                    ],
                    text: '23%',
                };
            }

            this.child.current.onAddItem(typeOfBlock, header, data);
            this.setState({isSlideOverClosed: !this.state.isSlideOverClosed});
        },

        onAddTab: (id) => {
            const tabs = this.state.tabs;
            const initialFakeData = this.state.initialFakeData;
            const newActiveTab = this.state.tabs.length;

            tabs.push({id: id, name: `Tab ${id} title`, editMode: false});
            initialFakeData.push({id: id, data: [{i: '0', x: 0, y: 0, w: 1, h: 1, add: true, static: false, typeOfBlock: 'add', isResizable: false}]});

            this.setState({tabs: tabs, tabCounter: this.state.tabCounter + 1, initialFakeData: initialFakeData, activeTab: newActiveTab});
        },

        // find and remove tab from 'tabs' and data from 'initialFakeData'
        onRemoveTab: (idx) => {
            const {activeTab} = this.state;
            let filteredTabs = [];
            let tempData = [...this.state.initialFakeData];
            let newActiveTab = null;

            this.state.tabs.forEach((t, index) => index !== idx && filteredTabs.push(t));
            tempData = tempData.filter((d, index) => index !== idx);

            // set newActiveTab after tab remove
            if ((idx > 0 && idx <= activeTab) || (idx === 0 && activeTab > 1)) {
                newActiveTab = activeTab - 1;
            } else if (idx === 0 && (activeTab === 0 || activeTab === 1)) {
                newActiveTab = 0;
            } else if (idx > activeTab) {
                newActiveTab = activeTab;
            }

            this.setState({tabs: filteredTabs, initialFakeData: tempData, activeTab: newActiveTab});
        },

        setActiveTab: (index) => {
            this.setState({activeTab: index});
        },

        // each grid onChangeLayout => we call this func
        onGridChange: (items, layout, id) => {
            let gridData = [...this.state.initialFakeData];

            //console.log('create-new-template.onGridChange: items', items);
            //console.log('create-new-template.onGridChange: layout', layout);

            gridData.forEach(function (gd) {
                if (gd.id === id) {
                    gd.data = items;
                }
                return gd;
            });

            this.setState({initialFakeData: gridData});
        },

        // getting input typeBlock htmlState, and uid - 'i'
        onInput: (htmlState, i, fakeDataId) => {
            let dataCopy = [...this.state.initialFakeData];
            let block = {};

            // find and change htmlState in input block
            dataCopy = dataCopy.filter((d) => d.id === fakeDataId);
            block = dataCopy[0].data.find((x) => x.i === i);
            block.data = htmlState;
        },

        properties: (init) => {
            if (!this.state.loading_properties) {
                this.setState(
                    {
                        loading_properties: true,
                    },
                    () => {
                        let data = {
                            // campaign: this.state.campaign.id,
                            title: this.state.title,
                            text: this.state.text,
                            logo: this.state.selected_logo.value,
                            properties: this.state.properties,
                            // status: this.state.report_status.value,
                        };
                        if (init) {
                            delete data.title;
                            delete data.text;
                            delete data.logo;
                            delete data.properties;
                            delete data.status;
                        }
                        calls.updateReportProperties(data).then(
                            (response) => {
                                //console.log(response);
                                let status = {};
                                let logo = {};
                                if (response.data.status === 'enabled') {
                                    status = {name: 'Enabled', value: 'enabled'};
                                } else {
                                    status = {name: 'Disabled', value: 'disabled'};
                                }
                                if (response.data.logo === 'agency_logo') {
                                    logo = {name: 'Agency logo', value: 'agency_logo'};
                                } else if (response.data.logo === 'client_logo') {
                                    logo = {name: 'Client logo', value: 'client_logo'};
                                } else {
                                    logo = {name: 'No logo', value: 'no_logo'};
                                }
                                this.setState({
                                    loading_properties: false,
                                    title: response.data.title ? response.data.title : '',
                                    text: response.data.text ? response.data.text : '',
                                    selected_logo: logo,
                                    properties: response.data.properties ? response.data.properties : this.state.all_properties,
                                    report_status: status,
                                });
                            },
                            (error) => {
                                this.setState({
                                    loading_properties: false,
                                });
                            }
                        );
                    }
                );
            }
        },

        /* functions for EditableTabs start */

        onFocus: ({event, id}) => {
            const tabs = [...this.state.tabs];
            let newTabs = tabs.map((tab) => {
                if (tab.id === id) {
                    tab.backup = event.target.value;
                }
                return tab;
            });

            this.setState({...this.state, tabs: newTabs});
        },

        onChange: ({event, id}) => {
            console.log('onChange-event', event.target.value);

            const tabs = [...this.state.tabs];
            let newTabs = tabs.map((tab) => {
                if (tab.id === id) {
                    tab.name = event.target.value;
                }
                return tab;
            });

            this.setState({...this.state, tabs: newTabs});
        },

        onBlur: ({event, id}) => {
            const tabs = [...this.state.tabs];
            let newTabs = tabs.map((tab) => {
                if (tab.id === id) {
                    tab.name = event.target.value.length > 0 ? event.target.value : `Tab ${id} title`;
                    tab.editMode = false;
                }
                return tab;
            });

            this.setState({...this.state, tabs: newTabs});
        },

        onKeyUp: ({event, id}) => {
            const tabs = [...this.state.tabs];

            if (event.key === 'Escape') {
                let newTabs = tabs.map((tab) => {
                    if (tab.id === id) {
                        tab.editMode = false;
                        tab.name = tab.backup;
                    }
                    return tab;
                });
                this.setState({...this.state, tabs: newTabs});
            }

            if (event.key === 'Enter') {
                let newTabs = tabs.map((tab) => {
                    if (tab.id === id) {
                        tab.editMode = false;
                        tab.name = event.target.value.length > 0 ? event.target.value : `Tab ${id} title`;
                    }
                    return tab;
                });
                this.setState({...this.state, tabs: newTabs});
            }
        },

        onClickSpan: ({editMode, id}) => {
            const tabs = [...this.state.tabs];
            let newTabs = tabs.map((tab) => {
                if (tab.id === id) {
                    tab.editMode = !editMode;
                }
                return tab;
            });

            this.setState({...this.state, tab: newTabs});
        },

        /* functions for EditableTabs end */
    };

    init = {};

    render() {
        return (
            <section className="create-new-template w-full h-full py-8">
                <div className="bg-white rounded-md p-4 pb-0">
                    <div className="flex flex-row justify-between items-center pb-4">
                        <h3 className="mb-0 text-lg font-bold">
                            {this.state.title 
                            ? <span>{this.state.title}</span>
                            : <span className="text-gray-500 cursor-pointer font-normal" onClick={() => {this.setState({open_settings: !this.state.open_settings})}}>Add a name to this report ...</span>
                            }
                        </h3>
                        <div onClick={() => {
                            this.setState({
                                open_settings: !this.state.open_settings
                            })
                        }} className="px-3 h-10 flex-row cursor-pointer flex justify-center items-center rounded-md bg-gray-300">
                            <FeatherIcon className="stroke-current" size={20} icon={this.state.open_settings ? "x" : "edit"}/>
                            {
                                <div className="text-xs font-bold ml-2">
                                    {this.state.open_settings ? 'Close edit' : 'Edit report info'}
                                </div>
                            }
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open_settings}>
                        <div className="pb-4">
                            <div className="flex flex-1 p-4 bg-gray-100 rounded-md flex-col">
                                <div className="inputs-container">
                                    <p className="font-normal mb-2">This information will be visible when generating your report to your client</p>
                                    <div className="w-full">
                                        <Input
                                            title={'Name'}
                                            placeholder={'Givet this report a name'}
                                            type={'text'}
                                            value={this.state.title}
                                            size={'full'}
                                            // onBlur={() => {
                                            //     this.functions.properties();
                                            // }}
                                            onChange={(event) => this.setState({title: event.target.value})}
                                        />
                                    </div>
                                    <div className="w-full pt-4">
                                        <Dropdown
                                            search={false}
                                            filter={false}
                                            title={'Logo'}
                                            size={'full'}
                                            placeholder={'Select option'}
                                            options={[
                                                {name: 'No logo', value: 'no_logo'},
                                                {name: 'Agency logo', value: 'agency_logo'},
                                                {name: 'Client logo', value: 'client_logo'},
                                            ]}
                                            value={this.state.selected_logo}
                                            onChange={(value) => {
                                                this.setState(
                                                    {
                                                        selected_logo: value,
                                                    }
                                                    // () => {
                                                    //     this.functions.properties();
                                                    // }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="w-full pt-4">
                                        <TextArea
                                            maxLength={1000}
                                            size={'full'}
                                            title={'Summary (max characters 1000)'}
                                            placeholder={'Summarize this report for your clients to read'}
                                            value={this.state.summary}
                                            // onBlur={() => {
                                            //     this.functions.properties();
                                            // }}
                                            onChange={(value) => {
                                                this.setState({
                                                    summary: value.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SlideDown>
                </div>







                {/* {console.log('this.state.initialFakeData', this.state.initialFakeData)} 
                {console.log('this.state.tabs', this.state.tabs)}
                {console.log('this.state.activeTab: ', this.state.activeTab)}
                */}
                

                {/* Dropdown and Textfields start */}

                

                {/* Dropdown and Textfields start */}
                {/* Tabs start 

                <div className="mt-8 overflow-x-auto">
                    <ul className="reports-tabs flex items-center border-b border-gray-400">
                        {this.state.tabs.map((tab, index) => (
                            <li
                                className={`${this.state.activeTab === index && '-mb-px'} mr-2`}
                                onClick={() => {
                                    this.functions.setActiveTab(index);
                                }}
                                key={tab.id}>
                                <div>
                                    <Link
                                        className={`${
                                            this.state.activeTab === index ? 'bg-white' : 'bg-gray-300'
                                        }  reports-tabs__link border-l border-t border-r rounded-t-lg py-3 px-4 text-sm text-blue-dark font-normal duration-200 flex items-center justify-between`}
                                        style={{width: '200px'}}
                                        to="#">
                                        <span className="reports-tabs__text whitespace-no-wrap overflow-x-auto">
                                            <EditableTabs
                                                name={tab.name}
                                                value={tab.name}
                                                currentTabId={tab.id}
                                                tabs={this.state.tabs}
                                                backup={tab.backup}
                                                editMode={tab.editMode}
                                                onFocus={(event) => this.functions.onFocus({event: event, id: tab.id})}
                                                onChange={(event) => this.functions.onChange({event: event, id: tab.id})}
                                                onBlur={(event) => this.functions.onBlur({event: event, id: tab.id})}
                                                onKeyUp={(event) => this.functions.onKeyUp({event: event, id: tab.id})}
                                                onClickSpan={() => this.functions.onClickSpan({editMode: tab.editMode, id: tab.id})}
                                            />
                                        </span>

                                        <span className="reports-tabs__edit duration-200 ml-2">
                                            <FeatherIcon
                                                id="svg--check"
                                                color={'black'}
                                                size={14}
                                                icon="check"
                                                onClick={(event) =>
                                                    this.editableText.current.setState({
                                                        edit: this.state.edit !== true,
                                                    })
                                                }
                                            />
                                        </span>

                                        <span className="reports-tabs__remove duration-200 ml-2">
                                            <FeatherIcon
                                                id="svg--x"
                                                color={'black'}
                                                size={14}
                                                icon="x"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.functions.onRemoveTab(index);
                                                }}
                                            />
                                        </span>
                                    </Link>
                                </div>
                            </li>
                        ))}
                        <li className="mr-1 ml-2">
                            <div style={{padding: '8px 0'}}>
                                <button
                                    className="bg-purple-500 text-white hover:bg-purple-700 font-bold text-sm
                            py-1 px-8 rounded-full focus:outline-none duration-200 z-10"
                                    style={{minWidth: '175px'}}
                                    onClick={() => this.functions.onAddTab(this.state.tabCounter + 1)}>
                                    + Add new tab
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                Tabs end */}

                <div className="flex flex-1 flex-row justify-start px-4 mt-4 relative z-10">
                    { Array.isArray(this.state.tabs) &&
                        this.state.tabs.map((item, index) => {
                            return (
                                <div
                                    className={cx("cursor-pointer relative max-w-xs bg-white flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-center rounded-t-md text-sm", {
                                        ["bg-opacity-100 text-purple-900 text-opacity-100"]: index === this.state.activeTab,
                                        ["border-transparent"]: index !== this.state.activeTab,
                                        ["ml-1"]: index !== 0
                                    })}>
                                    <div onClick={(e) => {
                                        if (!this.state.loading_remove && this.state.activeTab !== index) {
                                            this.setState({
                                                activeTab: index
                                            });
                                        }
                                    }}
                                            className={"font-bold truncate p-3 " + (index !== 0 ? 'w-11/12' : 'w-full')}
                                            title={item.name}>
                                        {item.name}
                                    </div>
                                    {index === this.state.activeTab &&
                                    <span
                                        className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
                                    }
                                    {index != 0 &&
                                    <span
                                        className="close w-6 h-6 absolute right-0 mr-2 top-50 transform -translate-y-1/2 bg-gray-100 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"
                                        onClick={() => {
                                            let tab_index = 0;
                                            let new_tab = {};
                                            this.state.tabs = this.state.tabs.filter((tab, index) => {
                                                if (tab.id === item.id) {
                                                    tab_index = index;
                                                }
                                                return tab.id !== item.id;
                                            });
                                            if (this.state.activeTab === index) {
                                                if (this.state.tabs.length > 1) {
                                                    new_tab = this.state.tabs[tab_index - 1];
                                                } else if (this.state.tabs.length > 0) {
                                                    new_tab = this.state.tabs[0];
                                                }
                                            } else {
                                                new_tab = this.state.selected_tab
                                            }
                                            this.setState({
                                                activeTab: new_tab,
                                                tabs: this.state.tabs
                                            })
                                        }}
                                    >&times;
                                </span>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="flex flex-1 justify-start pl-1">
                        <div onClick={() => {
                            let new_tab = {
                                id: Math.floor((Math.random() * 9999999999) + 1),
                                title: 'Tab ' + (this.state.tabs.length + 1),
                                editMode: false,
                                backup: '',
                                data_sources: {campaigns: {}, adgroups: {}, ads: {}}
                            };
                            this.state.tabs.push(new_tab);
                            this.setState({
                                tabs: this.state.tabs,
                                selected_tab: new_tab,
                                slideinclosed: false
                            }, () => {
                                if(!this.state.slideinclosed && this.state.selected_tab.id){
                                    this.setState({
                                        campaigns: this.state.campaigns.map((item) => {
                                            item.open = false;
                                            if(this.state.selected_tab.data_sources.adgroups){
                                                for(let adgroup in this.state.selected_tab.data_sources.adgroups){
                                                    if(this.state.selected_tab.data_sources.adgroups[adgroup].campaign == item.id){
                                                        item.open = true;
                                                    }
                                                }
                                            }
                                            if(this.state.selected_tab.data_sources.ads){
                                                for(let ad in this.state.selected_tab.data_sources.ads){
                                                    if(this.state.selected_tab.data_sources.ads[ad].campaign == item.id){
                                                        item.open = true;
                                                    }
                                                }
                                            }
                                            return item;
                                        }),
                                        adgroups: this.state.adgroups.map((item) => {
                                            item.open = false;
                                            if(this.state.selected_tab.data_sources.ads){
                                                for(let ad in this.state.selected_tab.data_sources.ads){
                                                    if(this.state.selected_tab.data_sources.ads[ad].adgroup == item.id){
                                                        item.open = true;
                                                    }
                                                }
                                            }
                                            return item;
                                        })
                                    });
                                }
                            })
                        }}
                                className="flex flex-row bg-white justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3 transition-all duration-200 text-purple-500 hover:bg-purple-500 hover:text-white"
                                style={{height: '100%'}}>
                            <FeatherIcon className="stroke-current" size={20} icon="plus"/>
                        </div>
                    </div>
                </div>


                {/** Body */}

                <div className="bg-white shadow-xl rounded-md p-4 relative">
                    <div className="visual-blocks mt-4">
                        <div className="text-xl font-bold">Visual blocks</div>
                        <div className="mb-4 text-sm">Reorder, add new blocks and resize to build your ideal report</div>
                    </div>

                    {this.state.initialFakeData.map(
                        ({id, data, layout}, index) =>
                            index === this.state.activeTab && (
                                <ReactGridLayout
                                    onGridChange={(items, layout) => this.functions.onGridChange(items, layout, id)}
                                    initialItems={data}
                                    layout={layout}
                                    isSlideOverClosed={this.functions.isSlideOverClosed}
                                    backgroundColor={'border-2 border-dashed bg-gray-100 bg-opacity-50 border-gray-200'}
                                    isBounded={false}
                                    preventCollision={false}
                                    verticalCompact={true}
                                    remove={true}
                                    ref={this.child}
                                    key={id}
                                    onInput={(htmlState, i) => this.functions.onInput(htmlState, i, id)}
                                />
                            )
                    )}
                </div>

                <SlideOver
                    slideOver={this.slideOver}
                    closed={this.state.isSlideOverClosed}
                    onClick={this.functions.isSlideOverClosed}
                    maxWidth="max-w-2xl"
                    rounded="rounded-l-3xl"
                    content={
                        <ReportsSlideOverContent addingBlockData={(typeOfBlock, header, data) => this.functions.addingBlockData(typeOfBlock, header, data)} />
                    }
                    headline={'Add a visual block'}
                />

                <aside
                    className="bg-white top-0 fixed flex flex-row items-center justify-end lg:w-6/12 md:w-5/12 px-6 py-2 md:py-3 right-0 w-full"
                    style={{zIndex: 9998}}>
                    <button
                        onClick={() => alert('save compaign')}
                        className="bg-transparent text-purple-500 font-bold text-sm py-2 px-4 rounded-full mr-4 focus:outline-none">
                        Save template
                    </button>

                    <div className="relative">
                        <button
                            className="bg-green-100 text-green-500 hover:bg-green-500 hover:text-white font-bold text-sm py-2 px-4 rounded-full focus:outline-none"
                            onClick={() =>
                                this.setState({
                                    isDroppedDown: !this.state.isDroppedDown,
                                })
                            }
                            ref={this.buttonRef}>
                            Export
                        </button>

                        <div
                            className={`export-dropdown absolute mt-2 right-0 rounded-md overflow-hidden shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200      
                    ${this.state.isDroppedDown ? 'block' : 'hidden'}`}
                            ref={this.dropdownRef}>
                            {this.state.fakeDataForDropdown.map(({id, icon, text, link, func}) => (
                                <div className="export-dropdown__button text-sm hover:bg-gray-300 divide-y divide-gray-200" key={id} onClick={func}>
                                    <div className="flex items-center whitespace-nowrap p-4">
                                        <FeatherIcon id={icon} color={'#0f0a7e'} size={18} icon={icon} />
                                        <span className="export-dropdown__text ml-2 font-semibold text-gray-900">{text}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </aside>
                <PptsGenJs ref={this.pptx} />
            </section>
        );
    }
}

const EditableTabs = (props) => {
    const {name, type, editMode, onFocus, onChange, onBlur, onKeyUp, onClickSpan} = props;

    return (
        (editMode === true && (
            <input
                className={'form-control w-full'}
                name={name}
                type={type}
                value={name}
                autoFocus
                onFocus={(event) => onFocus(event)}
                onChange={(event) => onChange(event)}
                onBlur={(event) => onBlur(event)}
                onKeyUp={(event) => onKeyUp(event)}
            />
        )) || <span onClick={() => onClickSpan()}>{name}</span>
    );
};
