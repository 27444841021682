import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/google/code";
        return apiRegister.call(options, url);
    }

    static getManagers(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/google/managers";
        return apiRegister.call(options, url);
    }

    static getClients(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/google/clients";
        return apiRegister.call(options, url);
    }

    static getUserInformation(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/google/information";
        return apiRegister.call(options, url);
    }

    static create(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/google";
        return apiRegister.call(options, url);
    }

    static remove(client) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/client/google/" + client;
        return apiRegister.call(options, url);
    }

}

export {calls}