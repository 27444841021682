import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api
    };

    static getConversionAction(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/conversionaction/' + data.client + '/' + data.channel;
        return apiRegister.call(options, url);
    }

    static getAudienceStatus(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/audience/status/' + data.channel + '/' + data.client + '/' + data.audience;
        return apiRegister.call(options, url);
    }

    static removeAudience(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = apiRegister.url.api  + '/client/audience/' + data.channel + '/' + data.client + '/' + data.audience;
        return apiRegister.call(options, url);
    }

    static createAudience(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/audience';
        return apiRegister.call(options, url);
    }

}

export {calls}