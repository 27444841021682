import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BeatLoader } from "react-spinners";
import CarouselOld from 're-carousel'
import IndicatorDots from '../../components/advertising-creatives-instagram-carousel-indicators'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class AdvertisingCreativesInstagramCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            body: this.props.body,
            page: this.props.page,
            no_container: this.props.noContainer,
            language: "",
            slide: 0,
            index: null,
            block: true,
            dynamic: this.props.dynamic,
            show_arrows: true,
            auto_loop: false,
            location: "",
            read_more: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            content: nextProps.content,
            body: nextProps.body,
            language: nextProps.language,
            dynamic: nextProps.dynamic,
            page: nextProps.page,
            location: nextProps.location,
        }, () => {

            //console.log(this.refs.carousel);

            let index = 0;
            this.renders.items().filter((item) => {
                return this.renders.ratio(item) === '1:1'
            }).map((item, inner_index) => {
                if (item.selected) {
                    index = inner_index;
                }
            });

            //console.log(this.refs.carousel);

            if (this.state.index !== index) {
                this.setState({
                    index: index
                }, () => {
                    try {
                        this.refs.carousel.moveTo(index);
                    } catch (e) {

                    }
                })
            }

        });
    }

    componentDidMount() {
        this.setState({
            content: this.props.content,
            body: this.props.body,
            language: this.props.language,
            page: this.props.page,
            dynamic: this.props.dynamic,
            show_arrows: this.props.showArrows,
            auto_loop: this.props.autoLoop,
            location: this.props.location
        }, () => {

            let index = 0;
            this.renders.items().filter((item) => {
                return this.renders.ratio(item) === '1:1'
            }).map((item, inner_index) => {
                if (item.selected) {
                    index = inner_index;
                }
            });

            if (this.state.index !== index && this.renders.items().length > 0) {
                this.setState({
                    index: index
                }, () => {
                    try {
                        this.refs.carousel.moveTo(index);
                    } catch (e) {

                    }
                })
            }

        });
    }

    functions = {};

    init = {};

    renders = {
        title: () => {
            try {
                if (this.renders.items().filter((item) => {
                    return this.renders.ratio(item) === '1:1'
                })[this.state.index].headline) {
                    return this.renders.items().filter((item) => {
                        return this.renders.ratio(item) === '1:1'
                    })[this.state.index].headline
                } else {
                    return 'Headline ...';
                }
            } catch (e) {
            }
        },
        body: () => {
            try {
                let body = this.state.content.body !== "" ? this.state.content.body : "Body ...";
                body = body.replace("{location}", this.state.location);
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        /*
        body: () => {
            try {
                return this.state.content.body !== "" ? this.state.content.body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        */
        video: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        image: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        gif: (item) => {
            try {
                return item.creative.url.indexOf('.gif') !== -1 ? item.creative.url : null
            } catch (e) {
                return ""
            }
        },
        poster: (item) => {
            try {
                return item.creative.thumbnail.url
            } catch (e) {
                return ""
            }
        },
        ratio: (item) => {
            try {
                return item.creative.ratio
            } catch (e) {
                return ""
            }
        },
        items: () => {
            try {
                return this.state.content.items
            } catch (e) {
                return []
            }
        },
        callToAction: () => {
            try {
                if (this.renders.items().filter((item) => {
                    return this.renders.ratio(item) === '1:1'
                })[this.state.index].call_to_action && this.renders.items().filter((item) => {
                    return this.renders.ratio(item) === '1:1'
                })[this.state.index].call_to_action.value !== 'NO_BUTTON') {
                    return this.renders.items().filter((item) => {
                        return this.renders.ratio(item) === '1:1'
                    })[this.state.index].call_to_action.name
                } else {
                    return null;
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className={cx("InstagramImage-PreviewContainer")}>
                {
                    (!this.state.dynamic || (this.state.dynamic && this.renders.items().length > 0)) &&
                    <div className={
                        cx("FacebookImage-PreviewBasicContainerItem",
                            {
                                ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                            })
                    }>
                        {
                            this.state.show_arrows &&
                            <div onClick={() => {
                                this.refs.carousel.onClickPrev();
                            }} className="Preview-container-prev-instagram">
                                <FeatherIcon color={"#333333"} size={19} icon="arrow-left" />
                            </div>
                        }
                        {
                            this.state.show_arrows &&
                            <div onClick={() => {
                                this.refs.carousel.onClickNext();
                            }} className="Preview-container-next-instagram">
                                <FeatherIcon color={"#333333"} size={19} icon="arrow-right" />
                            </div>
                        }
                        <div className={cx("InstagramImage-PreviewContainerItemInner")}>
                            <div className="InstagramImage-PreviewContainerItemInnerTop">
                                <img className="InstagramImage-PreviewContainerItemInnerTopLogo"
                                    src={require('../../assets/images/insta_logo.svg')} />
                            </div>
                            <div className="InstagramImage-PreviewBasicContainerItemInnerHeader">
                                <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderPage">
                                    <div style={{ backgroundImage: 'url(' + this.state.page.image + ')' }}
                                        className="InstagramImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                                </div>
                                <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderText">
                                    <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                        {this.state.page.name}
                                    </div>
                                    <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                        <div
                                            className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                            Sponsored
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="InstagramImage-PreviewContainerItemInnerCarousel">
                                <Carousel
                                    ref={'carousel'}
                                    showThumbs={false}
                                    showStatus={false}
                                    swipeable={true}
                                    showArrows={false}
                                    showIndicators={!this.state.dynamic}
                                    swipeScrollTolerance={1}
                                    infiniteLoop={true}
                                    autoPlay={this.state.auto_loop}
                                    onChange={(index, item) => {
                                        this.setState({
                                            index: index
                                        }, () => {
                                            let index = null;
                                            this.renders.items().map((inner_item, inner_index) => {
                                                if (inner_item.selected) {
                                                    index = inner_index;
                                                }
                                            });
                                            if (this.state.index !== index) {
                                                this.renders.items().map((inner_item, index) => {
                                                    inner_item.selected = index === this.state.index;
                                                });
                                                this.props.onChange(this.state.content.items);
                                            }
                                        });
                                    }}
                                >
                                    {
                                        this.renders.items().filter((item) => {
                                            return (this.renders.ratio(item) === '1:1' || item.loading)
                                        }).map((item, index) => {
                                            if (item.image || this.renders.gif(item)) {
                                                if (this.state.dynamic) {
                                                    return (
                                                        <div style={{ backgroundImage: "url(" + this.renders.image(item) + ")" }} className="InstagramImage-PreviewBasicContainerItemInnerImageItemTopDynamic" />
                                                    )
                                                } else {
                                                    return (
                                                        <img key={index} src={this.renders.image(item)} className="InstagramImage-PreviewContainerItemInnerCarouselSlide" />
                                                    )
                                                }
                                            } else if (item.video) {
                                                return (
                                                    <video poster={this.renders.poster(item)} key={index} controls={true}
                                                        style={{ width: '100%', height: '100%' }}>
                                                        <source src={this.renders.video(item)} type="video/mp4" />
                                                    </video>
                                                )
                                            }
                                        })
                                    }
                                </Carousel>
                            </div>
                            {
                                this.renders.callToAction() &&
                                <div className="InstagramImage-PreviewContainerItemInnerButtonCarousel">
                                    <div className="InstagramImage-PreviewContainerItemInnerButtonText">
                                        {
                                            this.renders.callToAction()
                                        }
                                    </div>
                                    <FeatherIcon color={"#3397f0"} size={20} icon="chevron-right" />
                                </div>
                            }
                            {
                                this.renders.callToAction() &&
                                <div className="InstagramImage-PreviewContainerItemInnerDivider">
                                    <div className="InstagramImage-PreviewContainerItemInnerDividerLine" />
                                </div>
                            }
                            <div className="InstagramImage-PreviewContainerItemInnerIcons">
                                <div className="InstagramImage-PreviewContainerItemInnerIconsLeft">
                                    <FeatherIcon color={"#575757"} size={20} icon="heart" />
                                    <FeatherIcon color={"#575757"} size={20} icon="message-circle" />
                                    <FeatherIcon color={"#575757"} size={20} icon="send" />
                                </div>
                                <div className="InstagramImage-PreviewContainerItemInnerIconsRight">
                                    <FeatherIcon color={"#575757"} size={20} icon="bookmark" />
                                </div>
                            </div>
                            <div className="InstagramImage-PreviewContainerItemInnerCaption">
                                <span className="InstagramImage-PreviewContainerItemInnerCaptionAuthor">
                                    {this.state.page.name}
                                </span>
                                <span className="InstagramImage-PreviewContainerItemInnerCaptionPrimaryText">
                                    {this.renders.title()}
                                </span>
                                <div className="p-3">
                                    {
                                        !this.state.post &&
                                        <div dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-xs font-medium whitespace-pre-wrap">

                                        </div>
                                    }

                                    <div>
                                        {
                                            !this.state.read_more && this.renders.body().length > 125 &&
                                            <div onClick={() => {
                                                this.setState({
                                                    read_more: true
                                                });
                                            }} className=" font-semibold text-sm text-center text-purple-500 ">
                                                Read more
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (this.state.dynamic && this.renders.items().length > 0) &&
                    <div style={{ fontSize: '12px', fontWeight: '500', marginTop: '10px', textAlign: 'center' }}>(Sample products)</div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesInstagramCarousel;
