import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";
import Input from "../input";
import TextArea from "../text-area";

class AdvertisingCreativesFacebookImageMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: this.props.creatives,
            page: this.props.page,
            no_container: this.props.noContainer,
            selected: {},
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            page: nextProps.page,
        }, () => {
            if(!this.state.selected.id && this.renders.images()[0]){
                this.setState({
                    selected: this.renders.images()[0]
                });
            }
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            page: this.props.page,
        }, () => {
            if(!this.state.selected.id && this.renders.images()[0]){
                this.setState({
                    selected: this.renders.images()[0]
                });
            }
        });
    }

    functions = {
        select: (item) => {
            this.setState({
                selected: item
            })
        }
    };

    init = {};

    renders = {
        images: () => {
            try {
                return this.state.creatives.filter((item) => {
                    return !item.button && item.image && !item.error && item.show && (item.ratio !== '9:16')
                });
            }catch (e) {
                return []
            }
        },
        title: () => {
            try {
                return this.state.creatives.filter((item) => {
                    return item.id === this.state.selected.id
                })[0].title
            }catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                return this.state.creatives.filter((item) => {
                    return item.id === this.state.selected.id
                })[0].body
            }catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                return this.state.creatives.filter((item) => {
                    return item.id === this.state.selected.id
                })[0].description
            }catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.selected.url
            }catch (e) {
                return ""
            }
        },
        callToAction: () => {
            try{
                if(this.state.creatives.filter((item) => {
                    return item.id === this.state.selected.id
                })[0].call_to_action.value !== 'NO_BUTTON'){
                    return this.state.creatives.filter((item) => {
                        return item.id === this.state.selected.id
                    })[0].call_to_action.name
                }else{
                    return null
                }
            }catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer-mobile")}>
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem-mobile",
                        {
                            ["FacebookImage-PreviewBasicContainerItemError-mobile"]: this.state.error,
                            ["FacebookImage-PreviewBasicContainerItemNo-mobile"]: this.state.no_container,
                        })
                }>
                    <div className={cx("FacebookImage-PreviewBasicContainerItemInner-mobile")}>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeader-mobile">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage-mobile">
                                <div style={{backgroundImage: 'url('+this.state.page.image+')'}} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon-mobile"></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText-mobile">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop-mobile">
                                    {this.state.page.name}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom-mobile">
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored-mobile">
                                        Sponsored
                                    </div>
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot-mobile"/>
                                    <img className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe-mobile" src={require('../../assets/images/globe.png')}/>
                                </div>
                            </div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText-mobile">
                            {this.renders.body()}
                        </div>
                        <img style={{width: '100%'}} src={this.renders.image()}/>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottom-mobile">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft-mobile">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftUrl-mobile">
                                    {this.props.url}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftHeadline-mobile">
                                    {this.renders.title()}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftDescription-mobile">
                                    {this.renders.description()}
                                </div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRight-mobile">
                                {
                                    this.renders.callToAction() &&
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton-mobile">
                                        {this.renders.callToAction()}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerShare-mobile">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem-mobile">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike-mobile" src={require('../../assets/images/like.png')}/>
                                Like
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem-mobile">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment-mobile" src={require('../../assets/images/comment.png')}/>
                                Comment
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem-mobile">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare-mobile" src={require('../../assets/images/share.png')}/>
                                Share
                            </div>
                        </div>
                    </div>
                    {
                        this.renders.images().length > 1 &&
                        <div className="FacebookImage-PreviewContainerItems-mobile">
                            {
                                this.renders.images().map((item) => {
                                    return(
                                        <div onClick={() => this.functions.select(item)} style={{backgroundImage: 'url('+item.url+')'}} className={
                                            cx("FacebookImage-PreviewContainerItemsItem-mobile",
                                                {
                                                    ["FacebookImage-PreviewContainerItemsItemSelected-mobile"]: this.state.selected.id === item.id,
                                                })
                                        }/>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesFacebookImageMobile;
