import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import currencies from '../../assets/json/currencies';
import Dropdown from "../../components/dropdown";
import UploadImage from "../../components/image-upload";
import {calls} from "./calls";

class UserUsersNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            agency: {},
            agencies: [],
            client: {},
            type: {},
            error: null,
            error_message: ""
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.agencies();
        window.scrollTo(0, 0);
    }

    init = {
        agencies: () => {
            calls.getAgencies().then((response) => {
                this.setState({
                    agencies: response.data,
                    loading: false
                })
            }, (error) => {

            });
        },
        clients: (agency) => {

        }
    };

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.createUser({
                    name: this.state.name,
                    email: this.state.email,
                    type: this.state.type.value?this.state.type.value:null,
                    client: this.state.client._id?this.state.client._id:"",
                    agency: this.state.agency._id?this.state.agency._id:""
                }).then((response) => {
                    this.props.history.push('/user/users/all');
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: error.body.name,
                        error_message: error.body.message
                    })
                });
            });
        }
    };

    renders = {

    };

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render(){
        return (
            <div className="Profile">
                <div className="Profile-Inner">
                    <AccordionStatic title={"Create new user"}>
                        <div className="Container">
                            <div className="Section">
                                <Input
                                    title={'Name'}
                                    value={this.state.name}
                                    error={this.state.error === "name" && this.state.name.length < 1}
                                    type={"text"}
                                    maxLength={40}
                                    onChange={(value) => this.setState({name: value.target.value}, () => {

                                    })}
                                />
                                <Input
                                    title={'Email'}
                                    value={this.state.email}
                                    error={this.state.error === "email" && !this.validators.email(this.state.email)}
                                    placeholder={""}
                                    type={"text"}
                                    onChange={(value) => this.setState({email: value.target.value}, () => {

                                    })}
                                />
                            </div>
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select user type'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "type" && !this.state.type.name}
                                    options={[
                                        {name: "Admin", value: 'admin'},
                                        {name: "Agent", value: 'agent'}
                                    ]}
                                    value={this.state.type}
                                    onChange={(value) => this.setState({type: value})}
                                />
                            </div>
                            {
                                this.state.type.value === 'agent' &&
                                <div className="Section">
                                    <Dropdown
                                        filter={false}
                                        title={'Select agency'}
                                        size={'full'}
                                        placeholder={"Click here .."}
                                        error={this.state.error === "agency" && !this.state.agency.name}
                                        options={this.state.agencies}
                                        value={this.state.agency}
                                        onChange={(value) => this.setState({agency: value}, () => {

                                        })}
                                    />
                                </div>
                            }
                            {
                                this.state.error &&
                                <div className="Section SectionError">
                                    {this.state.error_message}
                                </div>
                            }
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.create();
                                }} className="ButtonFull Button">
                                    {
                                        !this.state.loading &&
                                        <div>Create</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </AccordionStatic>
                </div>
            </div>
        )
    }
}

export default UserUsersNew;
