import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import countries from '../assets/json/countries.json';
import moment from 'moment';
import SwitchTailwind from './switchTailwind';
import SlideDown from 'react-slidedown';
import { Bar } from 'react-chartjs-2';
import { campaignFacebook } from '../validators/campaignFacebook';
import InputDatepickerTailwind from './inputDatepickerTailwind';

import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';

class EditFacebookCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: countries,
            currency: "",
            wizard: false,
            open: false,
            campaign: {},
            ios_options: [{ id: 0, name: "No", value: false }, { id: 0, name: "Yes", value: true }],
            adgroups: [],
            updated: {},
            client: {},
            loader: false,
            app_promotion_types: [
                { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" },
                { id: 2, name: 'App Ads', value: "GUIDED_CREATION" }
            ],
            special_ad_categories: [
                { id: 1, name: 'None', value: "NONE" },
                { id: 2, name: 'Housing', value: "HOUSING" },
                { id: 3, name: 'Credit', value: "CREDIT" },
                { id: 4, name: 'Employment', value: "EMPLOYMENT" },
                { id: 5, name: 'Issues Elections Politics', value: "ISSUES_ELECTIONS_POLITICS" }
            ],
            objectives: [
                { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" },
                { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" },
                { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" },
                { id: 4, name: 'Leads', value: "OUTCOME_LEADS" },
                { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" },
                { id: 6, name: 'Sales', value: "OUTCOME_SALES" }
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" }
            ]
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            adgroups: this.props.adgroups
        })
        if (this.state.client && this.state.client.channels && this.state.client.channels.filter((item) => { return item.value === "facebook" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].currency
            })
        }
        if (this.props.updated) {
            await this.promisedSetState({
                updated: this.props.updated
            });

            if (this.state.updated.scheduler) {
                this.state.tabs.push({ id: 2, name: "Scheduler", value: "schedule" });
                if (this.renders.objective().id === 5 && this.renders.app_promotion_type().id === 1) {
                    this.state.updated.scheduler.planer = false;
                }
                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                    this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                })
            }
        }
        campaignFacebook.validate(this.state.updated)
    }

    componentWillReceiveProps(nextProps) {
        /*
        this.setState({
            campaign: nextProps.campaign,
            client: nextProps.client,
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.setState({
                updated: nextProps.updated
            });
        }
        */
    }

    functions = {
        update: async () => {
            if (!this.state.wizard) {
                this.props.onError(false);
                this.props.onSuccess(false);
                let body = {
                    name: this.renders.name(),
                    daily_budget: parseInt(this.renders.budget()) * 100,
                    status: this.renders.status().value
                };
                if (this.renders.dailyBudget() > 0) {
                    body.daily_budget = parseInt(this.renders.dailyBudget()) * 100
                }
                if (this.renders.totalBudget() > 0) {
                    body.lifetime_budget = parseInt(this.renders.totalBudget()) * 100
                }
                if (this.renders.specialAdCategory()) {
                    body.special_ad_category = this.renders.specialAdCategory().value;
                }
                this.props.onLoader(true);
                try {
                    let response = await this.calls.update(body);
                    let updated_camaign = {
                        ...this.state.campaign,
                        ...{
                            name: this.renders.name(),
                            ...(this.state.dailyBudget() > 0 && { daily_budget: this.renders.dailyBudget() }),
                            ...(this.state.totalBudget() > 0 && { lifetime_budget: this.renders.totalBudget() }),
                            status: this.renders.status().value
                        }
                    };
                    this.props.onSuccess("Campaign updated");
                    this.props.onUpdate(updated_camaign);
                } catch (error) {
                    this.props.onError(error.body ? error.body.message : "Something went wrong");
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated, this.state.adgroups);
            }
        },
        updateAdgroups: async () => {
            let campaigns = [];
            if (this.state.updated.id && this.state.updated.id !== "multiple") {
                campaigns.push(this.state.updated.id);
            } else if (this.state.updated.ids) {
                campaigns = this.state.updated.ids;
            }
            campaigns.map((campaign_id) => {
                this.state.adgroups = this.state.adgroups.map((item) => {
                    if (item.campaign === campaign_id) {

                        //TRAFFIC
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_TRAFFIC") {
                            item = {
                                ...item, ...{
                                    //Conversion (Website)
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    campaign_objective: this.state.updated.objective,
                                    optimization_goal: { id: 8, name: "Link Clicks", value: "LINK_CLICKS" },
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                }
                            }
                        }

                        //AWARENESS
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_AWARENESS") {
                            item = {
                                ...item, ...{
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    campaign_objective: this.state.updated.objective,
                                    optimization_goal: { id: 13, name: "Reach", value: "REACH" },
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                    //Frequency cap (1 impression every 7 days)
                                }
                            }
                        }

                        //ENGAGEMENT
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_ENGAGEMENT") {
                            item = {
                                ...item, ...{
                                    //Conversion (on your ad)
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    campaign_objective: this.state.updated.objective,
                                    optimization_goal: { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" },
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                }
                            }
                        }

                        //LEADS
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_LEADS") {
                            item = {
                                ...item, ...{
                                    //Conversion (website)
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    campaign_objective: this.state.updated.objective,
                                    optimization_goal: { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                }
                            }
                        }

                        //APP PROMOTION
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_APP_PROMOTION") {
                            item = {
                                ...item, ...{
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    destination_type: { id: 2, name: "App", value: "APP" },
                                    campaign_objective: this.state.updated.objective,
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    optimization_goal: this.renders.app_promotion_type().id === 2 ? { id: 8, name: "Link Clicks", value: "LINK_CLICKS" } : { id: 1, name: "App Installs", value: "APP_INSTALLS" },
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                }
                            }
                        }

                        //SALES
                        if (this.state.updated.objective && this.state.updated.objective.value === "OUTCOME_SALES") {
                            item = {
                                ...item, ...{
                                    //Conversion (website)
                                    page: { id: 0, name: "Select page", value: null },
                                    application: { id: 0, name: "No App", value: null },
                                    campaign_objective: this.state.updated.objective,
                                    campaign_smart_promotion_type: this.renders.app_promotion_type(),
                                    optimization_goal: { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" },
                                    billing_event: { id: 2, name: "Impressions", value: "IMPRESSIONS" },
                                    campaign_budget_optimization: this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }
                                }
                            }
                        }

                    }
                    return item;
                });
            });
            this.promisedSetState({
                adgroups: this.state.adgroups
            });
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/facebook/updateCampaign?client=" + this.state.client.id + "&id=" + this.state.campaign.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        app_promotion_type: () => {
            try {

                if ('app_promotion_type' in this.state.updated) {
                    return this.state.updated.app_promotion_type;
                } else if (this.state.campaign.app_promotion_type) {
                    return this.state.campaign.app_promotion_type;
                } else {
                    return { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                }

            } catch (error) {
                return { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
            }
        },
        ios: () => {
            try {
                if ('ios' in this.state.updated) {
                    return this.state.updated.ios;
                } else {
                    return { id: 0, name: 'No', value: false };
                }
            } catch (error) {
                return { id: 0, name: 'No', value: false };
            }
        },
        specialAdCategory: () => {
            try {
                if (this.state.updated.special_ad_category) {
                    return this.state.updated.special_ad_category;
                } else if (this.state.campaign.special_ad_category === 'NONE') {
                    return { id: 1, name: 'None', value: "NONE" }
                } else if (this.state.campaign.special_ad_category === 'HOUSING') {
                    return { id: 2, name: 'Housing', value: "HOUSING" }
                } else if (this.state.campaign.special_ad_category === 'CREDIT') {
                    return { id: 3, name: 'Credit', value: "CREDIT" }
                } else if (this.state.campaign.special_ad_category === 'EMPLOYMENT') {
                    return { id: 4, name: 'Emplyment', value: "EMPLOYMENT" }
                } else if (this.state.campaign.special_ad_category === 'ISSUES_ELECTIONS_POLITICS') {
                    return { id: 5, name: 'Issues Elections Politics', value: "ISSUES_ELECTIONS_POLITICS" }
                } else {
                    //return { id: 1, name: 'None', value: "NONE" }
                    return { id: 0, name: 'Click here ...', value: "NONE" }
                }
            } catch (error) {
                //return { id: 1, name: 'None', value: "NONE" }
                return { id: 0, name: 'Click here ...', value: "NONE" }
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.campaign.status === 'ACTIVE') {
                    return { id: 1, name: 'Active', value: "ACTIVE" }
                } else if (this.state.campaign.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.campaign.status === 'DELETED') {
                    return { id: 3, name: 'Deleted', value: "DELETED" }
                } else if (this.state.campaign.status === 'ARCHIVED') {
                    return { id: 4, name: 'Archived', value: "ARCHIVED" }
                } else {
                    //return { id: 2, name: 'Paused', value: "PAUSED" }
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                //return { id: 2, name: 'Paused', value: "PAUSED" }
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        buyingType: () => {
            try {
                if (this.state.updated.buying_type) {
                    return this.state.updated.buying_type;
                } else if (this.state.campaign.buying_type === 'AUCTION') {
                    return { id: 1, name: 'Auction', value: "AUCTION" }
                } else if (this.state.campaign.buying_type === 'RESERVED') {
                    return { id: 2, name: 'Reserved', value: "RESERVED" }
                } else {
                    return { id: 1, name: 'Auction', value: "AUCTION" }
                }
            } catch (error) {
                return { id: 1, name: 'Auction', value: "AUCTION" }
            }
        },
        objective: () => {
            try {
                if (this.state.updated.objective) {
                    return this.state.updated.objective;
                } else if (this.state.campaign.objective === 'APP_INSTALLS') {
                    return { id: 1, name: 'App Installs', value: "APP_INSTALLS" }
                } else if (this.state.campaign.objective === 'BRAND_AWARENESS') {
                    return { id: 2, name: 'Brand awareness', value: "BRAND_AWARENESS" }
                } else if (this.state.campaign.objective === 'CONVERSIONS') {
                    return { id: 3, name: 'Conversions', value: "CONVERSIONS" }
                } else if (this.state.campaign.objective === 'EVENT_RESPONSES') {
                    return { id: 4, name: 'Event responses', value: "EVENT_RESPONSES" }
                } else if (this.state.campaign.objective === 'LEAD_GENERATION') {
                    return { id: 5, name: 'Lead generation', value: "LEAD_GENERATION" }
                } else if (this.state.campaign.objective === 'LINK_CLICKS') {
                    return { id: 6, name: 'Link clicks', value: "LINK_CLICKS" }
                } else if (this.state.campaign.objective === 'LOCAL_AWARENESS') {
                    return { id: 7, name: 'Local Awareness', value: "LOCAL_AWARENESS" }
                } else if (this.state.campaign.objective === 'LOCAL_AWARENESS') {
                    return { id: 8, name: 'Messages', value: "MESSAGES" }
                } else if (this.state.campaign.objective === 'OFFER_CLAIMS') {
                    return { id: 9, name: 'Offer claims', value: "OFFER_CLAIMS" }
                } else if (this.state.campaign.objective === 'PAGE_LIKES') {
                    return { id: 10, name: 'Page likes', value: "PAGE_LIKES" }
                } else if (this.state.campaign.objective === 'POST_ENGAGEMENT') {
                    return { id: 11, name: 'Post engagement', value: "POST_ENGAGEMENT" }
                } else if (this.state.campaign.objective === 'PRODUCT_CATALOG_SALES') {
                    return { id: 12, name: 'Product catalog sales', value: "PRODUCT_CATALOG_SALES" }
                } else if (this.state.campaign.objective === 'REACH') {
                    return { id: 13, name: 'Reach', value: "REACH" }
                } else if (this.state.campaign.objective === 'STORE_VISITS') {
                    return { id: 13, name: 'Store visits', value: "STORE_VISITS" }
                } else if (this.state.campaign.objective === 'VIDEO_VIEWS') {
                    return { id: 13, name: 'Video views', value: "VIDEO_VIEWS" }
                } else {
                    //return { id: 6, name: 'Link clicks', value: "LINK_CLICKS" }
                    return { id: 0, name: 'Click here ...', value: "LINK_CLICKS" }
                }
            } catch (error) {
                //return { id: 6, name: 'Link clicks', value: "LINK_CLICKS" }
                return { id: 0, name: 'Click here ...', value: "LINK_CLICKS" }
            }
        },
        strategy: () => {
            try {
                if (this.state.updated.strategy) {
                    return this.state.updated.strategy;
                } else if (this.state.campaign.strategy === 'LOWEST_COST_WITHOUT_CAP') {
                    return { id: 1, name: 'Lowest cost without cap', value: "LOWEST_COST_WITHOUT_CAP" }
                } else if (this.state.campaign.strategy === 'LOWEST_COST_WITH_BID_CAP') {
                    return { id: 2, name: 'Lowest cost with bid cap', value: "LOWEST_COST_WITH_BID_CAP" }
                } else if (this.state.campaign.strategy === 'COST_CAP') {
                    return { id: 3, name: 'Cost cap', value: "COST_CAP" }
                } else {
                    //return { id: 1, name: 'Lowest cost without cap', value: "LOWEST_COST_WITHOUT_CAP" }
                    return { id: 0, name: 'Click here ...', value: "LOWEST_COST_WITHOUT_CAP" }
                }
            } catch (error) {
                //return { id: 1, name: 'Lowest cost without cap', value: "LOWEST_COST_WITHOUT_CAP" }
                return { id: 0, name: 'Click here ...', value: "LOWEST_COST_WITHOUT_CAP" }
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else if (this.state.campaign.daily_budget) {
                    return +this.state.campaign.daily_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        totalBudget: () => {
            try {
                if ('lifetime_budget' in this.state.updated) {
                    return this.state.updated.lifetime_budget;
                } else if (this.state.campaign.lifetime_budget) {
                    return +this.state.campaign.lifetime_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (tab.id === 1 && campaignFacebook.validate(this.state.updated)) ||
                                                    (tab.id === 2 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>



                    {/* BASIC */}

                    {
                        this.state.tab.id === 1 &&
                        <>
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                !this.state.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Status"}
                                        selected={this.renders.status()}
                                        options={[
                                            { id: 1, name: 'Active', value: "ACTIVE" },
                                            { id: 2, name: 'Paused', value: "PAUSED" }
                                        ]}
                                        onChange={(value) => {
                                            this.state.updated.status = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Special ad category"}
                                    selected={this.renders.specialAdCategory()}
                                    options={this.state.special_ad_categories}
                                    onChange={(value) => {
                                        this.state.updated.special_ad_category = value;
                                        if (value.value === 'NONE') {
                                            this.state.updated.special_ad_category_countries = [];
                                        }
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    disabled={this.renders.specialAdCategory().value === 'NONE'}
                                    label={"Special ad category Countries"}
                                    selected={{ name: 'Select country' }}
                                    options={this.state.countries}
                                    searchInput={true}
                                    onChange={(value) => {
                                        if (!Array.isArray(this.state.updated.special_ad_category_countries)) {
                                            this.state.updated.special_ad_category_countries = [];
                                        }
                                        this.state.updated.special_ad_category_countries.push(value);
                                        this.setState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                            {
                                this.renders.specialAdCategory() &&
                                this.renders.specialAdCategory().value !== "NONE" &&
                                Array.isArray(this.state.updated.special_ad_category_countries) && this.state.updated.special_ad_category_countries.length > 0 &&
                                <div className="col-span-6 flex flex-col -m-2">
                                    {
                                        this.state.updated.special_ad_category_countries.map((item, index) => {
                                            return (
                                                <Fragment>
                                                    <div className={"bg-indigo-100 mt-2 text-indigo-700 flex flex-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.special_ad_category_countries = this.state.updated.special_ad_category_countries.filter((inner_item) => {
                                                                    return inner_item.code !== item.code;
                                                                });
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                });
                                                            }}
                                                            type="button"
                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.renders.specialAdCategory() &&
                                this.renders.specialAdCategory().value !== "NONE" &&
                                (!Array.isArray(this.state.updated.special_ad_category_countries) || (Array.isArray(this.state.updated.special_ad_category_countries) && this.state.updated.special_ad_category_countries.length < 1)) &&
                                <div className="col-span-6">
                                    <div className="w-full rounded-md p-4 bg-orange-100 text-orange-500 text-sm font-medium">
                                        Select at least one country. This will determine the advertising options available to you.
                                    </div>
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Buying type"}
                                    selected={this.renders.buyingType()}
                                    options={[]}
                                    locked={true}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Campaign objective"}
                                    selected={this.renders.objective()}
                                    locked={true}
                                    options={this.state.wizard ? this.state.objectives : []}
                                    onChange={async (value) => {
                                        /*
                                        if (this.state.wizard) {
        
                                            this.state.updated.objective = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
        
                                            //UPDATE CONNECTED ADGROUPS
                                            this.functions.updateAdgroups();
        
                                        }
                                        */
                                    }}
                                />
                            </div>
                            {
                                this.renders.objective().id === 5 &&
                                <div className="col-span-6">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"App promotion campaign types "}
                                            selected={this.renders.app_promotion_type()}
                                            options={this.state.wizard ? this.state.app_promotion_types : []}
                                            locked={!this.state.wizard}
                                            onChange={async (value) => {
                                                if (this.state.wizard) {
                                                    this.state.updated.app_promotion_type = value;
                                                    if (value.id === 1) {
                                                        this.state.updated.budget_optimization = { id: 1, name: "No", value: false };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });
                                                    //UPDATE CONNECTED ADGROUPS
                                                    this.functions.updateAdgroups();
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"IOS 14+ campaign "}
                                            selected={this.renders.ios()}
                                            options={this.state.wizard ? this.state.ios_options : []}
                                            locked={!this.state.wizard}
                                            onChange={async (value) => {
                                                if (this.state.wizard) {
                                                    this.state.updated.ios = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                            }
                            {
                                this.state.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Campaign Budget Optimization"}
                                        selected={this.state.updated.budget_optimization ? this.state.updated.budget_optimization : { id: 1, name: "No", value: false }}
                                        options={[
                                            { id: 1, name: "No", value: false },
                                            { id: 2, name: "Yes", value: true }
                                        ]}
                                        locked={((this.renders.objective().id === 5 && this.renders.app_promotion_type().id === 1) || (this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) ? true : false)}
                                        onChange={(value) => {
                                            this.state.updated.budget_optimization = value;
                                            if (value.id === 1) {
                                                this.state.updated.daily_budget = 0;
                                                this.state.updated.lifetime_budget = 0;
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                            //UPDATE CONNECTED ADGROUPS
                                            this.functions.updateAdgroups();
                                            campaignFacebook.validate(this.state.updated)
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.wizard &&
                                <div className={(this.state.updated.budget_optimization && this.state.updated.budget_optimization.id === 2 ? "" : "opacity-50 cursor-not-allowed") + " col-span-3"}>
                                    <div className='grid grid-cols-6 gap-4'>
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                locked={(this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer)}
                                                disabled={(!(this.state.updated.budget_optimization && this.state.updated.budget_optimization.id === 2) || (this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer))}
                                                label={"Campaign Budget"}
                                                selected={this.state.updated.budget_optimization_type ? this.state.updated.budget_optimization_type : { id: 1, name: "Daily budget", value: 'daily' }}
                                                options={[
                                                    { id: 1, name: "Daily budget", value: 'daily' },
                                                    { id: 2, name: "Total budget", value: 'total' }
                                                ]}
                                                onChange={(value) => {
                                                    this.state.updated.budget_optimization_type = value;
                                                    if (!this.state.updated.lifetime_budget && value.id === 2) {
                                                        this.state.updated.lifetime_budget = 0;
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    campaignFacebook.validate(this.state.updated)
                                                }}
                                            />
                                        </div>
                                        {
                                            (!this.state.updated.budget_optimization_type || (this.state.updated.budget_optimization_type && this.state.updated.budget_optimization_type.id === 1)) &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    error={campaignFacebook.validate(this.state.updated)}
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    disabled={(!(this.state.updated.budget_optimization && this.state.updated.budget_optimization.id === 2) || (this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer))}
                                                    locked={(this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer)}
                                                    label={"Amount"}
                                                    value={this.renders.dailyBudget()}
                                                    type={"number"}
                                                    onChange={(value) => {
                                                        if (value < 0) {
                                                            value = 0;
                                                        }
                                                        this.state.updated.daily_budget = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                        campaignFacebook.validate(this.state.updated)
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.updated.budget_optimization_type && this.state.updated.budget_optimization_type.id === 2 &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    error={campaignFacebook.validate(this.state.updated)}
                                                    disabled={!(this.state.updated.budget_optimization && this.state.updated.budget_optimization.id === 2)}
                                                    label={"Amount"}
                                                    leftSection={(this.state.currency !== "" ? true : false)}
                                                    leftSectionLabel={(this.state.currency)}
                                                    value={this.renders.totalBudget()}
                                                    type={"number"}
                                                    onChange={(value) => {
                                                        if (value < 0) {
                                                            value = 0;
                                                        }
                                                        this.state.updated.lifetime_budget = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                        campaignFacebook.validate(this.state.updated)
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Add campaign spending limit? (optional)"}
                                        selected={this.state.updated.add_spend_limit ? this.state.updated.add_spend_limit : { id: 1, name: "No", value: false }}
                                        options={[
                                            { id: 1, name: "No", value: false },
                                            { id: 2, name: "Yes", value: true }
                                        ]}
                                        locked={false}
                                        onChange={(value) => {
                                            this.state.updated.add_spend_limit = value;
                                            if (value.id === 1) {
                                                this.state.updated.spend_limit = 0;
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.wizard &&
                                <div className={(this.state.updated.add_spend_limit && this.state.updated.add_spend_limit.id === 2 ? "" : "opacity-50 cursor-not-allowed") + " col-span-3"}>
                                    <InputTailwind
                                        disabled={!(this.state.updated.add_spend_limit && this.state.updated.add_spend_limit.id === 2)}
                                        label={"Total spend limit"}
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        value={this.state.updated.spend_limit}
                                        onChange={(value) => {
                                            value = value.replace(/[\D]/g, '')
                                            this.state.updated.spend_limit = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.dailyBudget() > 0 &&
                                !this.state.wizard &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Campaign budget (daily)"}
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        value={this.renders.dailyBudget()}
                                        onChange={(value) => {
                                            this.state.updated.daily_budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.totalBudget() > 0 &&
                                !this.state.wizard &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Campaign budget (lifetime)"}
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        value={this.renders.totalBudget()}
                                        onChange={(value) => {
                                            this.state.updated.lifetime_budget = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                !this.state.wizard &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Strategy"}
                                        selected={this.renders.strategy()}
                                        options={[]}
                                        locked={true}
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                            }
                        </>
                    }


                    {/*SCHEDULER */}

                    {
                        this.state.tab.id == 2 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;
                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {
                                                    this.state.updated.budget_optimization = { id: 2, name: "Yes", value: true };
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                                this.functions.updateAdgroups();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })

                                                        this.functions.updateAdgroups();


                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5" + ((this.renders.objective().id === 5 && this.renders.app_promotion_type().id === 1) ? " cursor-not-allowed" : "")}>
                                                        <SwitchTailwind
                                                            disabled={(this.renders.objective().id === 5 && this.renders.app_promotion_type().id === 1)}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {
                                                                if (!(this.renders.objective().id === 5 && this.renders.app_promotion_type().id === 1)) {
                                                                    this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                    if (this.state.updated.scheduler.planer) {
                                                                        this.state.updated.budget_optimization = { id: 2, name: "Yes", value: true };
                                                                        if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                            this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                        } else {
                                                                            this.state.updated.daily_budget = 0;
                                                                        }
                                                                    }
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }


                </div>
            </>
        )
    }
}

export default EditFacebookCampaign;
