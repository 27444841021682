import React from 'react';

import './style.css';

const Tabs = (props) => {
    const {fakeDataTabs, setActiveTab, activeTab} = props;
    const tabClassName =
        'flex-1 tab-button block divide-y-reverse-px-6 mr-4 pb-1 hover:text-purple-500 focus:outline-none text-sm font-semibold whitespace-no-wrap duration-200';

    return (
        <div className="flex flex-1">
            <nav className="flex flex-col sm:flex-row relative">
                {fakeDataTabs.map(({number, name}) => (
                    <button
                        className={
                            tabClassName +
                            (activeTab === number ? ' text-gray-900 border-b-2 border-purple-500' : ' text-gray-400 border-b-2 border-transparent')
                        }
                        onClick={() => setActiveTab(number)}
                        key={number}>
                        {name}
                    </button>
                ))}
            </nav>
        </div>
    );
};

export default Tabs;
