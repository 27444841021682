import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";


class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static changeStatus(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/adset/status";
        return apiRegister.call(options, url);
    }

}

export {calls}