import React, { Component, Fragment } from 'react';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesInstagramVideo from '../components/advertising-creatives-instagram-video';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesInstagramImage from '../components/advertising-creatives-instagram-image';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import AdvertisingCreativesInstagramCarousel from '../components/advertising-creatives-instagram-carousel';
import AdvertisingCreativesVertical from '../components/advertising-creatives-vertical';
import PreviewLinkedIn from '../previews/linkedin/feed';
import PreviewLinkedInCarousel from '../previews/linkedin/carousel';
import linkedin_cta from '../assets/json/linkedin_cta.json'

class PreviewSectionLinkedin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            channel: {},
            for_report: false,
            preview_placement: "",
            is_post: false
        };
    }


    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            channel: this.props.channel,
            for_report: this.props.for_report,
            preview_placement: this.props.preview_placement,
            is_post: this.props.is_post
        });
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            channel: nextProps.channel,
            for_report: nextProps.for_report,
            preview_placement: nextProps.preview_placement,
            is_post: nextProps.is_post
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (this.state.for_report) {
                    return linkedin_cta.find((cta => cta.value.includes(item.call_to_action)))
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (this.state.for_report && this.state.ad.ad_type.value === "carousel") {
                    return item.slides;
                }
            } catch (error) {
                console.log(error)
            }
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline ? item.headline : "",
                                description: item.body ? item.body : "",
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading ? item.loading : false,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        },
        pdf: (item) => {
            try {
                if (item.file && item.file.document) {
                    return item.file.document;
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    ad.preview && ad.preview.value === "image_feed" &&
                    <PreviewLinkedIn
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        image={this.state.ad.picture}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.ad && this.state.ad.ad_type.value === "posts" ? true : this.state.is_post ? true : false}
                        pdf={this.renders.pdf(ad)}
                    />
                }
                {
                    ad.preview && ad.preview.value === "video_feed" &&
                    <PreviewLinkedIn
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        video={this.state.is_post ? this.state.ad.url : this.state.ad.video}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.ad && this.state.ad.ad_type && this.state.ad.ad_type.value === "posts" ? true : this.state.is_post ? true : false}
                        pdf={this.renders.pdf(ad)}

                    />
                }
                {
                    ad.preview && ad.preview.value === "carousel_feed" &&
                    <PreviewLinkedInCarousel
                        page={this.renders.page(ad, 'not_vertical')}
                        slides={this.state.ad && this.state.ad.ad_type && ((this.state.ad.ad_type.value === "posts" && this.state.ad.ad_type.type === "carousel") || (this.state.ad.type === "posts" && this.state.ad.ad_type === "carousel")) ? this.state.ad.files.map(item => {
                            return {
                                type: item.type,
                                picture: item.url,
                            }
                        }) : this.state.ad.slides}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={this.state.ad}
                        is_post={this.state.ad && this.state.ad.ad_type && this.state.ad.ad_type.value === "posts" ? true : this.state.is_post ? true : false}
                    />
                }

                {/* FOR REPORTS */}
                {
                    this.state.for_report &&
                    this.state.preview_placement !== "no_frame" && ad.image &&
                    //  ad.ad_type !== "carousel_feed" && 
                    <PreviewLinkedIn
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        image={this.state.ad.image}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                    />
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement !== "no_frame" && ad.video &&
                    //  ad.ad_type !== "carousel_feed" && 
                    <PreviewLinkedIn
                        page={this.renders.page(ad)}
                        cta={this.renders.cta(ad, 'not_vertical')}
                        video={this.state.ad.video}
                        body={this.renders.body(ad, 'not_vertical')}
                        title={this.renders.title(ad, 'not_vertical')}
                        link={this.renders.link(ad, 'not_vertical')}
                        caption={this.renders.caption(ad, 'not_vertical')}
                        adData={this.state.ad}
                    />
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement !== "no_frame" &&
                    ad.ad_type && ad.ad_type.value === "carousel" &&
                    <PreviewLinkedInCarousel
                        page={this.renders.page(ad)}
                        slides={this.renders.creative(ad)}
                        body={this.renders.body(ad, 'not_vertical')}
                        adData={{}}
                    />
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement === "no_frame" &&
                    ad && ad.ad_type && ad.ad_type.value !== "carousel" &&
                    <div>
                        {
                            ad.video && ad.video !== "" &&
                            (<video className="w-full h-full" autoplay={false} controls={true} loop={true} muted={true}>
                                <source src={this.state.ad.video} type="video/mp4" />
                            </video>)
                        }
                        {
                            ad.image && ad.image !== "" &&
                            (<img
                                className="h-full object-contain"
                                src={this.state.ad.image}
                                alt={ad && ad.title ? ad.title : "Creative image"}
                            />)
                        }
                    </div>
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement === "no_frame" &&
                    ad.ad_type && ad.ad_type.value === "carousel" &&
                    <div
                        id={"carousel_container"}
                        className='flex flex-col overflow-x-auto justify-center cursor-pointer select-none'>
                        <div className='flex space-x-2'>
                            {
                                Array.isArray(ad.slides) && ad.slides.length > 0 &&
                                ad.slides.map((slide, index) => {
                                    return (
                                        <span key={index}
                                            className='flex min-w-68 rounded'>
                                            {slide.type === "image" &&
                                                <img src={slide.picture} alt={slide.name ? slide.name : "Alt text"}
                                                    className="w-full overflow-hidden rounded"
                                                />
                                            }
                                            {slide.type === "video" &&
                                                <video src={slide.url} autoplay={false} controls={true} loop muted
                                                    className="h-full w-full overflow-hidden rounded"
                                                />
                                            }
                                        </span>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default PreviewSectionLinkedin;
