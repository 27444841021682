import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {BeatLoader} from "react-spinners";
import OptimizeSearchKeywordsItem from "../optimize-search-keywords-item";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import {calls} from "./calls";
import Loader from "../loader";

class OptimizeSearchKeywordsAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword_text: "",
            keyword_channel: {},
            keyword_type: {name: 'Exact', value: 'exact'},
            campaign: {},
            error: false,
            errors: {},
            error_message: "",
            error_modal: false,
            loading: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign
        });
    }

    init = {};

    functions = {
        add: () => {
            if(!this.state.loading){
                if(this.state.keyword_text === "" || !this.state.keyword_type.value){
                    this.setState({
                        error: true,
                        errors: {},
                        error_modal: false,
                        error_message: ""
                    })
                }else{
                    this.setState({
                        loading: true,
                        errors: {},
                        error_modal: false,
                        error_message: ""
                    }, () => {
                        calls.addNegativeKeyword({
                            keyword: this.state.keyword_text,
                            type: this.state.keyword_type.value,
                            campaign_id: this.state.campaign.id,
                            level: 'campaign',
                            channels: {google: true}
                        }).then((response) => {
                            this.setState({
                                loading: false
                            }, () => {
                                let errors = {};
                                for(let key in response.data){
                                    if(response.data[key].error){
                                        errors[key] = response.data[key].message;
                                    }else if(response.data[key].success){
                                        let negative_keyword = {
                                            channel: key,
                                            criterion_id: response.data[key].criterion_id,
                                            level: 'campaign',
                                            text: this.state.keyword_text,
                                            type: this.state.keyword_type.value
                                        };
                                        this.props.onAdd(negative_keyword);
                                    }
                                }
                                this.setState({
                                    error_modal: Object.keys(errors).length > 0,
                                    errors: errors,
                                    keyword_text: Object.keys(errors).length > 0 ? this.state.keyword_text : '',
                                    keyword_type: Object.keys(errors).length > 0 ? this.state.keyword_type : {},
                                    keyword_channel: Object.keys(errors).length > 0 ? this.state.keyword_channel : {},
                                })
                            })
                        }, (error) => {

                        });
                    })
                }
            }
        }
    };

    renders = {};

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.state.loading &&
                <Loader
                    title={'Adding negative keyword'}
                />
                }
                <div className="Optimize-Search-Add-Title">
                    Add negative keyword
                </div>
                <div className="Optimize-Search-Add">
                    <div className="Optimize-Search-Add-input">
                        <Input
                            noTitle={true}
                            type={'text'}
                            placeholder={'Keyword'}
                            error={this.state.error && this.state.keyword_text === ""}
                            value={this.state.keyword_text}
                            size={'full'}
                            onChange={(event) => this.setState({keyword_text: event.target.value})}
                        />
                    </div>
                    {
                        false &&
                        <div className="Optimize-Search-Add-dropdown">
                            <Dropdown
                                noTitle={true}
                                filter={false}
                                size={'full'}
                                tiny={true}
                                error={this.state.error && !this.state.keyword_channel.value}
                                placeholder={"Select channels"}
                                options={[
                                    {name: 'All channels', value: 'all'},
                                    {name: 'Google', value: 'google'}
                                ]}
                                value={this.state.keyword_channel}
                                onChange={(value) => {
                                    this.setState({
                                        keyword_channel: value
                                    })
                                }}
                            />
                        </div>
                    }
                    <div className="Optimize-Search-Add-dropdown">
                        <Dropdown
                            noTitle={true}
                            filter={false}
                            title={'Order'}
                            size={'full'}
                            tiny={true}
                            error={this.state.error && !this.state.keyword_type.value}
                            placeholder={"Select type"}
                            options={[
                                {name: 'Exact', value: 'exact'},
                                {name: 'Phrase', value: 'phrase'},
                                {name: 'Broad', value: 'broad'}
                            ]}
                            value={this.state.keyword_type}
                            onChange={(value) => {
                                this.setState({
                                    keyword_type: value
                                })
                            }}
                        />
                    </div>
                    <div className="Optimize-Search-Add-Button">
                        <div onClick={() => {
                            this.functions.add();
                        }} className="Button ButtonFull ButtonNeutral">
                            <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                        </div>
                    </div>
                </div>
                {
                    this.state.error_modal &&
                    <div style={{marginBottom: '15px'}} className="SectionError">
                        {
                            Object.keys(this.state.errors).map((key) => {
                                return(
                                    <div>
                                        {key}{": "}{this.state.errors[key]}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchKeywordsAdd;
