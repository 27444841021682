import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import {calls} from "../facebook/calls";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import FacebookImage from "../facebook-image";
import TextArea from "../text-area";

class CreativeFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: this.props.settings,
            creative: this.props.creative
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            settings: nextProps.settings,
            creative: nextProps.creative
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            settings: this.props.settings,
            creative: this.props.creative
        });
    }

    init = {};

    functions = {};

    renders = {
        type: () => {
            try {
                return this.state.creative.type.value === 'image'
            }catch (e) {}
        },
        valid: () => {
            try {
                if(
                    this.state.creative.type.name &&
                    this.state.creative.title.length > 1 &&
                    this.state.creative.description.length > 1 &&
                    this.state.creative.body.length > 1 &&
                    this.state.creative.image
                ){
                    this.state.creative.valid = true;
                    this.props.onChange(this.state.creative);
                }else{
                    this.state.creative.valid = false;
                    this.props.onChange(this.state.creative);
                }
            }catch (e) {}
        }
    };

    render() {
        return (
            <SlideDown closed={false}>
                <div className="Section">
                    <Dropdown
                        filter={false}
                        title={'Select creative type'}
                        size={'full'}
                        placeholder={"Click here .."}
                        error={this.state.error === "creative"}
                        options={[{name: 'Video', value: 'video'}, {name: 'Image', value: 'image'}]}
                        value={this.state.creative.type}
                        onChange={(value) => {
                            this.state.creative.type = value;
                            this.state.creative.size = {name: 'Horizontal', value: '1.91:1'};
                            this.state.creative.title = 'Put your title here';
                            this.state.creative.description = 'Put your description here';
                            this.state.creative.body = 'Put your primary text here ...';
                            this.props.onChange(this.state.creative);
                            this.renders.valid();
                        }}
                    />
                </div>
                {
                    this.renders.type() &&
                    <div className="Section">
                        <Input
                            title={'Headline (Maximum 40 characters)'}
                            maxLength={40}
                            value={this.state.creative.title}
                            error={this.state.error === "creative_title" && this.state.creative.title.length < 1}
                            placeholder={""}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            onChange={(value) => {
                                this.state.creative.title = value.target.value;
                                this.props.onChange(this.state.creative);
                                this.renders.valid();
                            }}
                        />
                    </div>
                }
                {
                    this.renders.type() &&
                    <div className="Section">
                            <TextArea
                                maxLength={90}
                                size={"half"}
                                title={"Body (Maximum 90 characters)"}
                                value={this.state.creative.body}
                                error={this.state.error === "creative_body" && this.state.creative.body.length < 1}
                                onChange={(value) => {
                                    this.state.creative.body = value.target.value;
                                    this.props.onChange(this.state.creative);
                                    this.renders.valid();
                                }}
                            />
                        <TextArea
                            size={"half"}
                            title={"Description"}
                            value={this.state.creative.description}
                            error={this.state.error === "creative_description" && this.state.creative.description.length < 1}
                            onChange={(value) => {
                                this.state.creative.description = value.target.value;
                                this.props.onChange(this.state.creative);
                                this.renders.valid();
                            }}
                        />
                    </div>
                }
                {
                    this.renders.type() &&
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            title={'Select size'}
                            size={'full'}
                            placeholder={"Click here .."}
                            error={this.state.error === "creative_size"}
                            options={[{name: 'Square', value: 1 / 1}, {name: 'Horizontal', value: 1.91 / 1}]}
                            value={this.state.creative.size}
                            onChange={(value) => {
                                this.state.creative.image = null;
                                this.state.creative.size = value;
                                this.props.onChange(this.state.creative);
                                this.renders.valid();
                            }}
                        />
                    </div>
                }
                {
                    this.renders.type() && this.state.creative.size &&
                    <div className="Section">
                        <FacebookImage
                            error={this.state.error === 'creative_image' && !this.state.creative.image}
                            size={this.state.creative.size.value}
                            page={{image: this.state.settings.facebookPageImage, name: this.state.settings.facebookPageName}}
                            title={this.state.creative.title}
                            body={this.state.creative.body}
                            description={this.state.creative.description}
                            image={this.state.creative.image}
                            onChange={(image) => {
                                this.state.creative.image = image;
                                this.props.onChange(this.state.creative);
                                this.renders.valid();
                            }}
                        />
                    </div>
                }
            </SlideDown>
        )
    }
}

export default CreativeFacebook;
