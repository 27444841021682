import React, { Component, Fragment } from 'react';
import CampaignDonuts2 from '../../../components/campaign-donuts-2';

class ReportComponentsDonuts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_tab: {}
    };
  };

  componentDidMount() {
    this.setState({
      selected_tab: this.props.tab
    }, () => {
      this.init.donut();
    });
  }

  init = {
    donut: () => {
      let donut_data = {};
      try {
        let items = [];
        items = items.concat(this.props.campaigns);
        items = items.concat(this.props.adgroups);
        items = items.concat(this.props.ads);
        let total = items.filter((item) => {
          if (item.type === "campaign") {
            return this.state.selected_tab.data_sources.campaigns[item.id];
          } else if (item.type === "adgroup") {
            return this.state.selected_tab.data_sources.adgroups[item.id];
          } else if (item.type === "ad") {
            return this.state.selected_tab.data_sources.ads[item.id];
          } else {
            return false;
          }
        }).length;
        let items_filtered = items.filter((item) => {
          if (item.type === "campaign") {
            return this.state.selected_tab.data_sources.campaigns[item.id];
          } else if (item.type === "adgroup") {
            return this.state.selected_tab.data_sources.adgroups[item.id];
          } else if (item.type === "ad") {
            return this.state.selected_tab.data_sources.ads[item.id];
          } else {
            return false;
          }
        });
        items_filtered.map((campaign) => {
          if (!donut_data[campaign.channel]) {
            donut_data[campaign.channel] = {
              click: 0,
              impressions: 0,
              ctr: 0,
              cpc: 0,
              spend: 0
            };
          }
          if (campaign.channel === "facebook") {
            if (Array.isArray(campaign.insights)) {
              campaign.insights.map((item) => {
                if (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown] &&
                  ((item.daterange_start === this.state.selected_tab.daterange.start_date &&
                    item.daterange_end === this.state.selected_tab.daterange.end_date) ||
                    (this.state.selected_tab.daterange.value === "lifetime" &&
                      !item.daterange_start && !item.daterange_end))) {

                  donut_data[campaign.channel].click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                  donut_data[campaign.channel].impressions += +item.impressions ? +item.impressions : 0;
                  donut_data[campaign.channel].spend += +item.spend ? +item.spend : 0;

                }
              });
            }
          } else if (campaign.channel === "google") {
            if (Array.isArray(campaign.insights)) {
              campaign.insights.map((item, index) => {
                if (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown] &&
                  ((item.daterange_start === this.state.selected_tab.daterange.start_date &&
                    item.daterange_end === this.state.selected_tab.daterange.end_date) ||
                    (this.state.selected_tab.daterange.value === "lifetime" &&
                      !item.daterange_start && !item.daterange_end))) {

                  donut_data[campaign.channel].click += +item.clicks ? +item.clicks : 0;
                  donut_data[campaign.channel].impressions += +item.impressions ? +item.impressions : 0;
                  donut_data[campaign.channel].spend += +item.cost_micros ? +(item.cost_micros / 1000000) : 0;

                }
              });
            }
          } else if (campaign.channel === "adform") {
            if (Array.isArray(campaign.insights)) {
              campaign.insights.map((item, index) => {
                if (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown] &&
                  ((item.daterange_start === this.state.selected_tab.daterange.start_date &&
                    item.daterange_end === this.state.selected_tab.daterange.end_date) ||
                    (this.state.selected_tab.daterange.value === "lifetime" &&
                      !item.daterange_start && !item.daterange_end))) {

                  donut_data[campaign.channel].click += +item["clicks.adUniqueness##all.dataSource##adform"] ? +item["clicks.adUniqueness##all.dataSource##adform"] : 0;
                  donut_data[campaign.channel].impressions += +item["impressions.adUniqueness##all.dataSource##adform"] ? +item["impressions.adUniqueness##all.dataSource##adform"] : 0;
                  donut_data[campaign.channel].spend += +item["cost.costDataSource##adform.costType##maxCost"] ? +item["cost.costDataSource##adform.costType##maxCost"] : 0;

                }
              });
            }
          } else if (campaign.channel === "google_analytics") {
            if (Array.isArray(campaign.insights)) {
              campaign.insights.map((item, index) => {
                if (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown] &&
                  ((item.daterange_start === this.state.selected_tab.daterange.start_date &&
                    item.daterange_end === this.state.selected_tab.daterange.end_date) ||
                    (this.state.selected_tab.daterange.value === "lifetime" &&
                      !item.daterange_start && !item.daterange_end))) {

                  donut_data[campaign.channel].click += +item["ga:adClicks"] ? +item["ga:adClicks"] : 0;
                  donut_data[campaign.channel].impressions += +item["ga:impressions"] ? +item["ga:impressions"] : 0;
                  donut_data[campaign.channel].spend += +item["ga:adCost"] ? +item["ga:adCost"] : 0;

                }
              });
            }
          } else if (campaign.channel === "linkedin") {
            if (Array.isArray(campaign.insights)) {
              campaign.insights.map((item, index) => {
                if (this.state.selected_tab.channel_breakdowns && this.state.selected_tab.channel_breakdowns[campaign.channel] && this.state.selected_tab.channel_breakdowns[campaign.channel][item.channel_breakdown] &&
                  ((item.daterange_start === this.state.selected_tab.daterange.start_date &&
                    item.daterange_end === this.state.selected_tab.daterange.end_date) ||
                    (this.state.selected_tab.daterange.value === "lifetime" &&
                      !item.daterange_start && !item.daterange_end))) {

                  donut_data[campaign.channel].click += +item.clicks ? +item.clicks : 0;
                  donut_data[campaign.channel].impressions += +item.impressions ? +item.impressions : 0;
                  donut_data[campaign.channel].spend += +item.costInLocalCurrency ? +(item.costInLocalCurrency) : 0;

                }
              });
            }
          }

        });

        //GET AVERAGE
        for (let key in donut_data) {
          donut_data[key].ctr = (donut_data[key].click / donut_data[key].impressions) * 100;
          donut_data[key].cpc = donut_data[key].spend / donut_data[key].click;
        }

      } catch (e) {
        //console.log(e);
      }

      this.state.selected_tab.donut = donut_data;
      this.setState({
        selected_tab: this.state.selected_tab
      }, () => {
        if (this.props.update) {
          this.props.update(this.state.selected_tab);
        }
      });
    }
  }

  functions = {};

  renders = {};

  render() {
    return (
      <>
        {
          this.state.selected_tab && this.state.selected_tab.donut &&
          <CampaignDonuts2
            data={this.state.selected_tab.donut}
            tab={this.state.selected_tab}
            border={true}
            properties={this.state.selected_tab.piechart ? Object.keys(this.state.selected_tab.piechart).map((item) => {
              return this.state.selected_tab.piechart[item].value
            }) : []}
            showchannels={false}
          />
        }
      </>
    )
  }

}

export default ReportComponentsDonuts;