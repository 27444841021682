import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import Switch from "../advertising-channels";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";

class AgencyUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            users: [],
            selected: null
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentDidMount() {
        this.init.users();
    }

    init = {
        users: () => {
            calls.getUsers(this.state.item._id).then((response) => {
                this.setState({
                    users: response.data
                })
            }, (error) => { });
        }
    };

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.addUser({
                    name: this.state.name,
                    email: this.state.email,
                    type: 'agent',
                    agency: this.state.item._id
                }).then((response) => {
                    if (response.data) {
                        this.state.users.push(response.data);
                    } else {
                        this.state.users.push(response);
                    }
                    this.setState({
                        users: this.state.users,
                        loading: false,
                        new: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: error.body.message
                    })
                });
            });
        },
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false
            }, () => {
                calls.removeUser(this.state.selected).then((response) => {
                    let users = this.state.users.filter((item) => {
                        return item._id !== this.state.selected;
                    });
                    this.setState({
                        loading: false,
                        selected: null,
                        users: users
                    })
                }, (error) => {
                    this.setState({
                        selected: null,
                        error_modal: true,
                        loading: false,
                        error_message: error.body.message
                    });
                });
            })
        },
        open: (item) => {
            if (!this.state.loading && this.state.selected !== item._id) {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/login/" + item);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/login/" + item);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/login/" + item);
                }
            }
        }
    };

    renders = {};

    render() {
        return (
            <div style={{ padding: '15px' }} className="ClientSettings">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove user"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({
                            warning_modal: false,
                            selected: null
                        });
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div className="FacebookClientTop">
                    <div onClick={() => {
                        this.setState({ open: !this.state.open })
                    }} className="FacebookClientTop-InnerContainerInnerTop">
                        <div className="FacebookClientTop-InnerContainerInnerTopTitle">
                            Users ({this.state.users.length})
                        </div>
                        <div className="FacebookClientTop-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("FacebookClientTop-InnerContainerInnerTopArrow",
                                    {
                                        ["FacebookClientTop-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={20} icon="plus" />
                            <div className="FacebookClientTop-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("FacebookClientTop-InnerContainerInnerTopMinus",
                                        {
                                            ["FacebookClientTop-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="minus" />
                            </div>
                        </div>
                    </div>
                </div>
                <SlideDown closed={!this.state.open}>
                    {
                        this.state.users.length > 0 &&
                        <div className="AgencyUser-items">
                            {
                                this.state.users.map((item) => {
                                    return (
                                        <div className="AgencyUser-item">
                                            <div onClick={() => {
                                                this.functions.open(item._id);
                                            }} className="AgencyUser-itemLeft">
                                                <div className="AgencyUser-itemLeftIcon">
                                                    <FeatherIcon color={"#333333"} size={23} icon="external-link" />
                                                </div>
                                                <div className="AgencyUser-itemLeftText">
                                                    {item.email}
                                                </div>
                                                {
                                                    this.state.loading && this.state.selected === item._id &&
                                                    <div
                                                        className="Optimize-ContainerListItemValuesLoader">
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={10}
                                                            color={'#388f8a'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                item.status === 'pending' && !this.state.loading && this.state.selected !== item._id &&
                                                <div onClick={() => {
                                                    if (window.location.hostname === 'localhost') {
                                                        window.open("http://localhost:3000/set-password/" + item.resetPasswordToken);
                                                    } else if (window.location.hostname === 'app.adcredo.io') {
                                                        window.open("https://app.adcredo.io/set-password/" + item.resetPasswordToken);
                                                    } else if (window.location.hostname === 'dev.adcredo.io') {
                                                        window.open("https://dev.adcredo.io/set-password/" + item.resetPasswordToken);
                                                    }
                                                }} className="AgencyUser-itemRemove ButtonNeutral">
                                                    <FeatherIcon color={"#333333"} size={23} icon="mail" />
                                                </div>
                                            }
                                            <div onClick={() => {
                                                if (!this.state.loading && this.state.selected !== item._id) {
                                                    this.setState({
                                                        selected: item._id,
                                                        warning_modal: true
                                                    })
                                                }
                                            }} className="AgencyUser-itemRemove ButtonRed">
                                                <FeatherIcon color={"#ffffff"} size={23} icon="trash" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="Section">
                            <Input
                                title={'Name'}
                                value={this.state.name}
                                type={"text"}
                                maxLength={40}
                                onChange={(value) => this.setState({ name: value.target.value }, () => {

                                })}
                            />
                            <Input
                                title={'Email'}
                                value={this.state.email}
                                placeholder={""}
                                type={"text"}
                                onChange={(value) => this.setState({ email: value.target.value }, () => {

                                })}
                            />
                        </div>
                    }
                    {
                        !this.state.new &&
                        <div className="Section">
                            <div onClick={() => {
                                this.setState({
                                    new: true
                                })
                            }} className="Button ButtonNeutral ButtonFull">
                                Create new user
                            </div>
                        </div>
                    }
                    {
                        this.state.new &&
                        <div className="Section">
                            <div onClick={() => {
                                this.functions.create()
                            }} className="Button ButtonFull">
                                {
                                    !this.state.loading &&
                                    <div>Add user</div>
                                }
                                {
                                    this.state.loading &&
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={12}
                                        color={'#ffffff'}
                                        loading={true}
                                    />
                                }
                            </div>
                        </div>
                    }
                </SlideDown>
            </div>
        )
    }
}

export default AgencyUsers;
