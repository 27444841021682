import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";
import cx from "classnames";
import ReactTooltip from "react-tooltip";

class OptimizeSearchItemQuery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adset: this.props.adset,
            item: this.props.item ? this.props.item : {},
            hide: this.props.hide,
            sort: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            sort: nextProps.sort
        })
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
            sort: this.props.sort
        });
    }

    init = {};

    functions = {
        addNegative: () => {
            this.setState({
                loading: true
            }, () => {
                calls.createNegative(this.state.adset.internal, this.state.adset.id, {keyword: this.state.item.query, type: 'exact'}).then((response) => {
                    this.state.item.negative_id = response.data;
                    this.state.item.negative_exist = true;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_message: error.body.message,
                        error_modal: true
                    })
                });
            })
        },
        removeNegative: () => {
            this.setState({
                loading: true
            }, () => {
                calls.removeNegative(this.state.adset.internal, this.state.adset.id, {keyword: this.state.item.negative_id}).then((response) => {
                    delete this.state.item.negative_id;
                    this.state.item.negative_exist = false;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_message: error.body.message,
                        error_modal: true
                    })
                });
            })
        }
    };

    renders = {
        width: (key) => {
            if(key === 'query'){
                return {width: '270px'}
            }
            else if(key === 'type'){
                return {width: '100px'}
            }
            else if(key === 'impressions'){
                return {width: '100px'}
            }
            else if(key === 'click'){
                return {width: '100px'}
            }
            else if(key === 'ctr'){
                return {width: '100px'}
            }
            else if(key === 'cpc'){
                return {width: '100px'}
            }
            else if(key === 'spend'){
                return {width: '100px'}
            }
            else if(key === 'conversions'){
                return {width: '100px'}
            }
        },
        ending: (key) => {
            if(key === 'ctr'){
                return "%"
            }else if(key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion'){
                return this.state.adset.currency
            }else{
                return  "";
            }
        },
        keys: () => {
            return ['query', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
        },
    };

    render() {
        return (
            <div className="Optimize-Query-Item-Container">
                <div className="Optimize-Query-Item">
                    <SweetAlert
                        show={this.state.error_modal}
                        title="Error"
                        type="error"
                        text={this.state.error_message}
                        confirmButtonText="Ok"
                        onConfirm={() => {
                            this.setState({
                                error_modal: false
                            })
                        }}
                    />
                    {
                        this.state.loading &&
                        <div className="Optimize-Query-Item-Values-Loading">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        this.renders.keys().map((key) => {
                            return(
                                <div style={this.renders.width(key)} className={
                                    cx("Optimize-Query-Item-Section",
                                        {
                                            ["Optimize-Query-Item-Section-Selected"]: this.state.sort.value === key,
                                        })
                                }>
                                    <div className="Optimize-Query-Item-Section-Title">
                                        {key}
                                    </div>
                                    <div data-tip-disable={key !== 'query'} data-tip={(this.state.item[key] + ' ' + this.renders.ending(key))} className="Optimize-Search-Item-Section-Value">
                                        {this.state.item[key]}{" "}{this.renders.ending(key)}
                                        <ReactTooltip />
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        false &&
                        <div className="Optimize-Query-Item-Section Optimize-Query-Item-Section-Slider">
                            <Switch
                                onColor="#24bda8"
                                checked={!this.state.item.negative_exist}
                                onChange={(checked) => {
                                    if(!this.state.item.negative_exist){
                                        this.functions.addNegative();
                                    }else{
                                        this.functions.removeNegative();
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                <div className="Optimize-Query-Button">
                    <div className="Button ButtonFull">
                        <FeatherIcon color={"#ffffff"} size={23} icon="thumbs-down"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default OptimizeSearchItemQuery;
