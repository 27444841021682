import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";

class OptimizeActive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            channels: [],
            open: true,
            sort: {name: 'channel (default)', value: 'channel'},
            filter: {name: 'Show all', value: null},
            order: {name: 'Ascending', value: 'ascend'}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            ads: this.props.ads,
            channels: this.props.channels
        })
    }

    componentDidMount() {
        this.setState({
            ads: this.props.ads,
            channels: this.props.channels
        });
    }

    init = {};

    functions = {
        open: () => {
            this.setState({
                open: true
            })
        }
    };

    renders = {
        active: (item) => {
            try {
                return item.status === 'ACTIVE'
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <AccordionFixed
                    open={this.state.ads.filter((item) => {
                        return item.loading_data
                    }).length > 0?true:(this.state.ads.filter((item) => {
                        return this.renders.active(item);
                    }).length > 0?this.state.open:false)}
                    title={"Active ads: "}
                    subtitle={this.state.ads.filter((item) => {
                        return this.renders.active(item);
                    }).length}
                    edit={this.state.ads.filter((item) => {
                        return this.renders.active(item);
                    }).length > 0}
                    onEdit={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }}
                >
                    {
                        <div style={{width: '100%'}}>
                            {
                                <div className="Section">
                                    <div className="Optimize-Sort">
                                        <Dropdown
                                            filter={false}
                                            title={'Sort'}
                                            size={'half'}
                                            disabled={this.state.ads.filter((item) => {
                                                return this.renders.active(item);
                                            }).length < 1}
                                            placeholder={"Select here.."}
                                            options={[
                                                {name: 'channel (default)', value: 'channel'},
                                                {name: 'type', value: 'type'},
                                                {name: 'name', value: 'name'},
                                                {name: 'impressions', value: 'impressions'},
                                                {name: 'click', value: 'click'},
                                                {name: 'ctr', value: 'ctr'},
                                                {name: 'cpc', value: 'cpc'},
                                                {name: 'spend', value: 'spend'},
                                                {name: 'conversions', value: 'conversions'},
                                                {name: 'cost pr con.', value: 'cost_pr_conversion'}
                                            ]}
                                            value={this.state.sort}
                                            onChange={(value) => {
                                                this.setState({
                                                    sort: value
                                                })
                                            }}
                                        />
                                        <Dropdown
                                            filter={false}
                                            title={'Order'}
                                            size={'half'}
                                            disabled={this.state.ads.filter((item) => {
                                                return this.renders.active(item);
                                            }).length < 1}
                                            placeholder={"Select here.."}
                                            options={[
                                                {name: 'Ascending', value: 'ascend'},
                                                {name: 'Descending', value: 'descend'}
                                            ]}
                                            value={this.state.order}
                                            onChange={(value) => {
                                                this.setState({
                                                    order: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <Dropdown
                                        filter={false}
                                        title={'Filter channel'}
                                        size={'half'}
                                        disabled={this.state.ads.filter((item) => {
                                            return this.renders.active(item);
                                        }).length < 1}
                                        placeholder={"Select here.."}
                                        options={[{
                                            name: 'Show all',
                                            value: null
                                        }].concat(this.state.channels)}
                                        value={this.state.filter}
                                        onChange={(value) => {
                                            this.setState({
                                                filter: value
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.ads.filter((item) => {
                                    return this.renders.active(item);
                                }).length > 0 &&
                                <div className="Optimize-ContainerList">
                                    {
                                        this.state.ads.filter((item) => {
                                            return this.renders.active(item);
                                        }).filter((item) => {
                                            if (this.state.filter.value) {
                                                return item.channel === this.state.filter.name
                                            } else {
                                                return true
                                            }
                                        }).sort((a, b) => {
                                            if (this.state.sort.name) {
                                                var nameA = null;
                                                var nameB = null;
                                                try {
                                                    nameA = a[this.state.sort.value].toLowerCase();
                                                } catch (e) {
                                                    nameA = a[this.state.sort.value];
                                                }
                                                try {
                                                    nameB = b[this.state.sort.value].toLowerCase();
                                                } catch (e) {
                                                    nameB = b[this.state.sort.value];
                                                }
                                                if (this.state.order.value === 'descend') {
                                                    if (nameA > nameB)
                                                        return -1;
                                                    if (nameA < nameB)
                                                        return 1;
                                                    return 0
                                                } else {
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0
                                                }
                                            } else {
                                                return 1
                                            }
                                        }).map((item, index) => {
                                            let frames = [];
                                            if(item.type === 'carousel'){
                                                frames = item.creative_items.filter((item) => {
                                                    return item.type === 'image' || item.type === 'video';
                                                }).map((item, index) => {
                                                    if(item.type === 'image'){
                                                        return(
                                                            <img key={index} src={item.creative_link} style={{width: '100%', height: '100%'}}/>
                                                        )
                                                    }else if(item.type === 'video'){
                                                        return(
                                                            <video key={index} controls={false} autoPlay={true} loop={true} style={{width: '100%', height: '100%'}}>
                                                                <source src={item.creative_link} type="video/mp4"/>
                                                            </video>
                                                        )
                                                    }
                                                });
                                            }
                                            return (
                                                <div className="Optimize-ContainerListItem">
                                                    <div className="Optimize-ContainerListItemChannel">
                                                        {
                                                            item.channel === 'facebook' &&
                                                            <div
                                                                className="Optimize-ContainerListItemChannelFacebook">
                                                                <img style={{width: '12px'}}
                                                                     src={require('../../assets/images/facebook_icon.svg')}/>
                                                            </div>
                                                        }
                                                        {
                                                            item.channel === 'instagram' &&
                                                            <div
                                                                className="Optimize-ContainerListItemChannelInstagram">
                                                                <img style={{width: '25px'}}
                                                                     src={require('../../assets/images/instagram_icon.svg')}/>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="Optimize-ContainerListItemPreview">
                                                        {
                                                            item.type === 'image' &&
                                                            <img src={item.creative_link}
                                                                 style={{width: '100%'}}/>
                                                        }
                                                        {
                                                            item.type === 'video' &&
                                                            <video controls={false} loop={true}
                                                                   autoPlay={true}
                                                                   muted={true}
                                                                   style={{width: '100%', height: '100%'}}>
                                                                <source src={item.creative_link}
                                                                        type="video/mp4"/>
                                                            </video>
                                                        }
                                                        {
                                                            item.type === 'carousel' &&
                                                            <Carousel interval={5000} loop auto frames={frames}/>
                                                        }
                                                    </div>
                                                    <div className="Optimize-ContainerListItemValues">
                                                        {
                                                            item.loading &&
                                                            <div
                                                                className="Optimize-ContainerListItemValuesLoader">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={10}
                                                                    color={'#388f8a'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                        <div
                                                            className="Optimize-ContainerListItemValuesInner">
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem">
                                                                {
                                                                    this.state.sort.value === 'type' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Type
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.type}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem">
                                                                {
                                                                    this.state.sort.value === 'name' &&
                                                                    <div className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Name
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem Optimize-ContainerListItemValuesInnerItemExtra">
                                                                {
                                                                    this.state.sort.value === 'impressions' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Impressions
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.impressions>0?item.impressions:'0'}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem">
                                                                {
                                                                    this.state.sort.value === 'click' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Click
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.click>0?item.click:'0'}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem">
                                                                {
                                                                    this.state.sort.value === 'ctr' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    CTR
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.ctr ? item.ctr : '0'}%
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem">
                                                                {
                                                                    this.state.sort.value === 'cpc' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    CPC
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.cpc ? item.cpc : '0'}{" "}{item.currency}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem Optimize-ContainerListItemValuesInnerItemExtra">
                                                                {
                                                                    this.state.sort.value === 'spend' &&
                                                                    <div className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Spend
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.spend}{" "}{item.currency}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem Optimize-ContainerListItemValuesInnerItemExtra">
                                                                {
                                                                    this.state.sort.value === 'conversions' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Conversions
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.conversions ? item.conversions : '0'}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="Optimize-ContainerListItemValuesInnerItem Optimize-ContainerListItemValuesInnerItemExtra">
                                                                {
                                                                    this.state.sort.value === 'costprconversion' &&
                                                                    <div
                                                                        className="Optimize-ContainerListItemValuesInnerItemActive"/>
                                                                }
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemTitle">
                                                                    Cost pr con.
                                                                </div>
                                                                <div
                                                                    className="Optimize-ContainerListItemValuesInnerItemValue">
                                                                    {item.cost_pr_conversion ? item.cost_pr_conversion : '0'}{" "}{item.currency}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="Optimize-ContainerListItemSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={this.renders.active(item)}
                                                                onChange={(checked) => {
                                                                    if (this.state.ads.length > 1) {
                                                                        this.props.disable(item);
                                                                    } else {
                                                                        this.setState({
                                                                            warning_modal: true
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.ads.filter((item) => {
                            return item.loading_data
                        }).length > 0 &&
                        <div className="Optimize-Loader">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                </AccordionFixed>
            </div>
        )
    }
}

export default OptimizeActive;
