import React, { Component, Fragment } from 'react';
import { ArrowDownIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';

class JobController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false,
            loading_partial: false,
            sort: "error",
            direction: "descending",
            total_jobs: 0,
            total_locked: 0,
            total_clients: 0,
            oldest_locked: {},
            oldest_job: {},
            columns: [
                { name: 'name', value: 'name', min_width: '250px', noSort: true },
                { name: 'pending', value: 'pending', min_width: '75px' },
                { name: 'success', value: 'success', min_width: '50px' },
                { name: 'error', value: 'error', min_width: '50px' },
            ],
            client: { id: 0 },
            job_type_data: {},
            rows: [
                { id: "campaignSchedule", value: "campaign_schedule", name: "Campaign Schedule" },
                { id: "adgroupSchedule", value: "adgroup_schedule", name: "Adgroup Schedule" },
                { id: "checkErrors", value: "check_errors", name: "Check errors" },
                { id: "createWarnings", value: "create_warnings", name: "Create warnings" },
                { id: "checkUserPlatformToken", value: "check_user_platform_tokens", name: "Check User platform tokens" },
                { id: "maxOrderBudget", value: "check_order_max_budget", name: "Check order max budget" },
                { id: "campaignInformation", value: "campaign_information", name: "Campaign information" },
                { id: "campaignPerformanceDate", value: "campaign_performance", name: "Campaign performance" },
                { id: "firstOfMonthOrderManager", value: "first_of_month_order_manager", name: "First Of Month Order Manager" },
                { id: "checkBudgetPlans", value: "budget_items", name: "Budget items" },
                { id: "checkBingCampaignInSync", value: "check_bing_campaign_sync", name: "Check Bing campaign sync" },
                { id: "clientSendReportEmail", value: "client_send_report_email", name: "Email send (client)" },
                { id: "getClientAccountBudget", value: "get_client_account_budget", name: "Get current account budget" },
                { id: "aggregateData", value: "aggregate_data", name: "Aggregate data" },
                { id: "createNextMonthAccountBudgets", value: "create_next_month_account_budgets", name: "New Account budgets" },
                { id: "reportSendReportEmail", value: "report_send_report_email", name: "Email send (report)" },
                { id: "orderCampaignBudgetAdjust", value: "order_campaign_budget_adjust", name: "Adjust spend pacing/total total" },
                { id: "clientPerformance", value: "client_performance", name: "client Performance" }
            ],
            advancedFilters: [
                {
                    type: "date",
                    placeholder: "Today",
                    defaultOptions: [],
                    maxWidth: true,
                    options: [
                        { id: 0, name: "Today", value: "today" },
                        { id: 1, name: "Yesterday", value: "yesterday" },
                        { id: 2, name: "Two days ago", value: "2_days" },
                        { id: 3, name: "Three days ago", value: "3_days" }
                    ],
                    selected: { id: 0, name: "Today", value: "today" }
                },
            ]
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.logSegments(true);
    }

    functions = {
        logSegments: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "&date=today";
                let response = await this.calls.getJobSegments(params)
                this.promisedSetState({
                    job_type_data: response.data,
                    total_jobs: response.meta.total_jobs,
                    total_clients: response.meta.total_clients,
                    total_locked: response.meta.total_locked,
                    oldest_locked: response.meta.oldest_locked,
                    oldest_job: response.meta.oldest_job
                });
                this.functions.sortData();
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        dateChange: async () => {
            await this.promisedSetState({ loading_partial: true });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = `&date=${filter.selected.value}`
                });
                let response = await this.calls.getJobSegments(params);
                await this.promisedSetState({
                    job_type_data: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading_partial: false });
        },
        sortData: async () => {
            let new_job_type_data;
            let new_rows;
            new_job_type_data = Object.entries(this.state.job_type_data);
            if (this.state.direction === 'ascending') {
                new_job_type_data.sort((a, b) => a[1][this.state.sort] - b[1][this.state.sort]);
            } else {
                new_job_type_data.sort((a, b) => b[1][this.state.sort] - a[1][this.state.sort]);
            }
            new_rows = new_job_type_data.map((item) => {
                if (item[0] === "campaign_budgetplaner") {
                    return this.state.rows.find(row => row.value === "campaign_planer");
                } else if (item[0] === "adgroup_budgetplaner") {
                    return this.state.rows.find(row => row.value === "adgroup_planer");
                } else {
                    return this.state.rows.find(row => row.value === item[0]);
                }
            })
            await this.promisedSetState({
                rows: new_rows
            });
        },
        handleSortClick: async (item) => {
            await this.promisedSetState({
                sort: item.value,
                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
            })
            this.functions.sortData();
        }
    };

    renders = {
        sektionsTotal: (type, secondary) => {
            let total = 0;
            if (this.state.job_type_data) {
                for (let key in this.state.job_type_data) {
                    if (type == "success") {
                        if (secondary) {
                            total += this.state.job_type_data[key].success_total;
                        } else {
                            total += this.state.job_type_data[key].success;
                        }
                    } else if (type == "pending") {
                        if (secondary) {
                            total += this.state.job_type_data[key].pending_total;
                        } else {
                            total += this.state.job_type_data[key].pending;
                        }
                    } else if (type == "error") {
                        if (secondary) {
                            total += this.state.job_type_data[key].error_total;
                        } else {
                            total += this.state.job_type_data[key].error;
                        }
                    }
                }
            }
            return total;
        }
    };

    calls = {
        getJobSegments: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url;
            if (params) {
                url = apiRegister.url.api + "/v3/adcredo/getJobSegments?" + params;
            } else {
                url = apiRegister.url.api + "/v3/adcredo/getJobSegments";
            }
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {

                        }}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.dateChange();
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.logs();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onSearch={async (value) => {

                        }}
                        showAdvanced={true}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta["total"] : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={false}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            !this.state.loading &&
                            <div className="grid w-full grid-cols-4 gap-4 mb-4">
                                {
                                    false &&
                                    [{ name: "Success", value: "success" }, { name: "Pending", value: "pending" }, { name: "Error", value: "error" }].map((sektion) => {
                                        return (
                                            <div className={"sm:col-span-1 col-span-6 rounded-lg overflow-hidden bg-white shadow"}>
                                                <div className="flex p-4 px-6 border-b border-gray-100">
                                                    <div className="font-medium text-sm">
                                                        {sektion.name}
                                                    </div>
                                                </div>
                                                <div className="p-6 py-6 flex flex-col">
                                                    <div className="flex flex-row">
                                                        <dd className="text-3xl font-bold tracking-tight">{this.renders.sektionsTotal(sektion.value)}</dd>
                                                        <div className="flex flex-1"></div>
                                                        <dd className="text-3xl font-bold tracking-tight text-gray-300">{this.renders.sektionsTotal(sektion.value, true)}</dd>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={"sm:col-span-1 col-span-6 rounded-lg overflow-hidden bg-white shadow"}>
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-medium text-sm">
                                            Total clients
                                        </div>
                                    </div>
                                    <div className="p-6 py-6 flex flex-col">
                                        <div className="flex flex-row">
                                            <dd className="text-3xl font-bold tracking-tight">{this.state.total_clients}</dd>
                                            <div className="flex flex-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"sm:col-span-1 col-span-6 rounded-lg overflow-hidden bg-white shadow"}>
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-medium text-sm">
                                            Total locked (all days)
                                        </div>
                                    </div>
                                    <div className="p-6 py-6 flex flex-col">
                                        <div className="flex flex-row">
                                            <dd className="text-3xl font-bold tracking-tight">{this.state.total_locked}</dd>
                                            <div className="flex flex-1"></div>
                                        </div>
                                        <dd className="text-xs tracking-tight">{this.state.oldest_locked ? this.state.oldest_locked.created : "-"} (oldest)</dd>
                                    </div>
                                </div>
                                <div className={"sm:col-span-1 col-span-6 rounded-lg overflow-hidden bg-white shadow"}>
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-medium text-sm">
                                            Total jobs (all days)
                                        </div>
                                    </div>
                                    <div className="p-6 py-6 flex flex-col">
                                        <div className="flex flex-row">
                                            <dd className="text-3xl font-bold tracking-tight">{this.state.total_jobs}</dd>
                                            <div className="flex flex-1"></div>
                                        </div>
                                        <dd className="text-xs tracking-tight">{this.state.oldest_job ? this.state.oldest_job.created : "-"} (oldest)</dd>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="shadow bg-white rounded-lg w-full overflow-hidden">
                            {/*NO ORDERS*/}
                            {
                                Object.keys(this.state.job_type_data).length < 1 &&
                                <div className="border-t border-gray-200 h-170 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No jobs available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                Object.keys(this.state.job_type_data).length > 0 &&
                                <div className="relative overflow-scroll">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="border-r"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={() => {
                                                                if (!item.noSort) {
                                                                    this.functions.handleSortClick(item)
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={`${index !== this.state.columns.length - 1 && "border-r"} px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider ${!item.noSort && "hover:bg-gray-100 cursor-pointer"} ${this.state.sort === item.value ? "bg-gray-100" : "bg-white"}`}
                                                        >
                                                            <div className="flex flex-row items-center justify-between">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.rows.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr className='border-b'>
                                                                <td className='bg-white px-4 sm:px-6 py-4 flex flex-row items-center justify-center border-gray-300'>
                                                                    <div
                                                                        onClick={async () => {
                                                                            this.props.history.push(`/admin/jobs?${item.id}`);
                                                                        }}
                                                                        className='cursor-pointer px-5 flex relative h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                                                                        Check
                                                                    </div>
                                                                </td>
                                                                <td className='px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300 border-l border-r'>
                                                                    {item.name}
                                                                </td>
                                                                <td className={`px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300 border-l border-r ${this.state.sort === "pending" ? "bg-gray-50" : "bg-white"}`}>
                                                                    {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].pending : "0"} / {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].pending_total : "0"}
                                                                </td>
                                                                <td className={`px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300 border-l border-r ${this.state.sort === "success" ? "bg-gray-50" : "bg-white"}`}>
                                                                    {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].success : "0"} / {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].success_total : "0"}
                                                                </td>
                                                                <td className={`px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300 border-l ${this.state.sort === "error" ? "bg-gray-50" : "bg-white"}`}>
                                                                    {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].error : "0"} / {this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].error_total : "0"}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default JobController;