
class userRegister {

    static admin = false;
    static user = {};

    static set(user, admin) {
        userRegister.user = user;
        userRegister.admin = admin;
        try {
            if (admin) {
                sessionStorage.setItem("admin", admin);
                sessionStorage.setItem("user", JSON.stringify(user));
                localStorage.removeItem("user");
            } else {
                sessionStorage.removeItem("admin");
                sessionStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(user));
            }
        } catch (e) { }
    }

    static get() {
        if (userRegister.user.name) {
            return userRegister.user
        } else {
            try {
                if (sessionStorage.getItem("admin")) {
                    if (sessionStorage.getItem("user")) {
                        return JSON.parse(sessionStorage.getItem("user"));
                    } else {
                        return {};
                    }
                } else {
                    if (localStorage.getItem("user")) {
                        return JSON.parse(localStorage.getItem("user"));
                    } else {
                        return {};
                    }
                }
            } catch (e) {
                return {};
            }
        }
    }

    static isAdmin() {
        try {
            if (userRegister.admin) {
                return true;
            } else if (sessionStorage.getItem("admin")) {
                return sessionStorage.getItem("admin");
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    static remove() {
        userRegister.admin = false;
        userRegister.user = {};
        try {
            sessionStorage.removeItem("admin");
            sessionStorage.removeItem("user");
            localStorage.removeItem("user");
        } catch (e) { }
    }

}

export { userRegister }