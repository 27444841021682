import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import moment from 'moment';
import languages from '../assets/json/languages.json';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import { resolve } from 'path';

class EditLiveGoogleCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            wizard: false,
            open: false,
            campaign: {},
            ios_options: [{ id: 0, name: "No", value: false }, { id: 0, name: "Yes", value: true }],
            adgroups: [],
            updated: {},
            client: {},
            bidding_strategies: [],
            loader: false,
            languages: languages,
            target_impression_share_location: [
                { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" },
                { id: 2, name: "Top of results page", value: "TOP_OF_PAGE" },
                { id: 3, name: "Absolute top of results page", value: "ABSOLUTE_TOP_OF_PAGE" }
            ],
            strategies: [
                { id: 1, name: "Clicks", value: "TARGET_SPEND" },
                { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" },
                { id: 3, name: "Conversions value", value: "MAXIMIZE_CONVERSION_VALUE" },
                { id: 4, name: "Impression share", value: "TARGET_IMPRESSION_SHARE" },
                { id: 5, name: "Manual CPC", value: "MANUAL_CPC" },
                { id: 6, name: "Target ROAS", value: "MAXIMIZE_CONVERSION_VALUE" },
                { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" },
                { id: 8, name: "Viewable CPM", value: "MANUAL_CPM" },
            ],
            tab: { id: 1, name: "Basic", value: "basic" },
            tabs: [
                { id: 1, name: "Basic", value: "basic" },
                { id: 7, name: "Goal", value: "goal" },
                { id: 2, name: "Budget", value: "budget" },
                { id: 3, name: "Location", value: "location" },
                { id: 4, name: "Settings", value: "settings" },
                //{ id: 5, name: "Targeting", value: "targeting" },


            ],
            networks: [
                { id: 1, name: "Google search", value: "google_search", locked: false },
                { id: 2, name: "Search network", value: "search_network", locked: false },
                { id: 3, name: "Content network", value: "content_network", locked: false },
                { id: 4, name: "Partner search network", value: "partner_search_network", locked: false }
            ],
            status_values: [
                { id: 1, name: 'Enabled', value: "ENABLED" },
                { id: 2, name: 'Paused', value: "PAUSED" },
                { id: 3, name: 'Deleted', value: "REMOVED" }
            ],
            budget_modes: [
                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
            ],
            map: { id: 1, name: "List View", value: false },
            location_type: { id: 1, name: "Included", value: true },
            locked: false,
            deviceSetting: [
                { id: 1, name: "Computer", value: "DESKTOP", included: true },
                { id: 2, name: "Mobile Phone", value: "MOBILE", included: true },
                { id: 3, name: "Tablets", value: "TABLET", included: true },
            ],
            content_exclusions: [
                { id: 2, name: "Sexually suggestive content.", value: "SEXUALLY_SUGGESTIVE", included: false },
                { id: 3, name: "Below the fold placement.", value: "BELOW_THE_FOLD", included: false },
                { id: 4, name: "Parked domain.", value: "PARKED_DOMAIN", included: false },
                { id: 5, name: "Juvenile, gross & bizarre content.", value: "JUVENILE", included: false },
                { id: 6, name: "Profanity & rough language.", value: "PROFANITY", included: false },
                { id: 7, name: "Death & tragedy.", value: "TRAGEDY", included: false },
                { id: 8, name: "Video.", value: "VIDEO", included: false },
                { id: 9, name: "Content rating: G.", value: "VIDEO_RATING_DV_G", included: false },
                { id: 10, name: "Content rating: PG", value: "VIDEO_RATING_DV_PG", included: false },
                { id: 11, name: "Content rating: T.", value: "VIDEO_RATING_DV_T", included: false },
                { id: 12, name: "Content rating: MA.", value: "VIDEO_RATING_DV_MA", included: false },
                { id: 13, name: "Content rating: not yet rated.", value: "VIDEO_NOT_YET_RATED", included: false },
                { id: 14, name: "Embedded video.", value: "EMBEDDED_VIDEO", included: false },
                { id: 15, name: "Live streaming video.", value: "LIVE_STREAMING_VIDEO", included: false },
                { id: 16, name: "Sensitive social issues.", value: "SOCIAL_ISSUES", included: false },
            ],
            old_locations: [],
            place_default: {
                "latitude": 59.32932349999999,
                "longitude": 18.0685808,
                "radius": 30000,
                "name": "(SE) Stockholm, Sweden, (59.329 lat, 18.069 lng, 30km)",
                "custom": true,
                "negative": false,
                "included": true,
                "include": true,
                "id": 4919442428,
                "code": "se",
                "lat": 59.32932349999999,
                "lng": 18.0685808
            },
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaign: this.props.campaign,
            client: this.props.client,
            wizard: this.props.wizard,
            locked: this.props.locked,
            loading_map: true
        });
        this.functions.map().then((response) => {
            try {
                this.autocomplete = new response.maps.places.AutocompleteService();
                this.geocoder = new response.maps.Geocoder;
            } catch (e) { }
        });
        if (this.renders.advertising_type() === "DISPLAY") {
            this.state.content_exclusions = JSON.parse(JSON.stringify(this.state.campaign.content_exclusions));
            this.state.deviceSetting = JSON.parse(JSON.stringify(this.state.campaign.deviceSetting));
        }

        this.functions.assetsets();
        this.functions.merchantAccounts();
        await this.functions.biddingStrategies();
        if (this.props.campaign && Array.isArray(this.props.campaign.locations) && this.props.campaign.locations.length > 0) {
            let old_locations = this.props.campaign.locations;
            old_locations = JSON.parse(JSON.stringify(old_locations))
            if (old_locations.length > 0) {
                this.promisedSetState({
                    old_locations: old_locations
                });
            }
        }

        try {
            if (Object.keys(this.state.campaign).length > 0 && Array.isArray(this.state.campaign.locations) && this.state.campaign.locations.length > 0) {
                let only_custom = true;
                for (let location of this.state.campaign.locations) {
                    if (!location.hasOwnProperty('custom')) {
                        only_custom = false;
                        break;
                    }
                }

                if (only_custom) {
                    this.state.map = { id: 2, name: "Map View", value: true }
                    this.state.place = this.state.campaign.locations[0];
                    await this.promisedSetState({ map: this.state.map, place: this.state.place });
                }
            }
        } catch (error) { }

        await this.promisedSetState({ loading_map: false })

    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = {
                            ...(this.state.updated.name) && { name: this.state.updated.name },
                            ...(this.state.updated.start_date) && { start_date: this.state.updated.start_date },
                            ...(this.state.updated.end_date) && { end_date: this.state.updated.end_date },
                            ...(this.state.updated.end_date === null) && { end_date: "2037-12-30" },
                            ...(this.state.updated.budget) && { budget: this.state.updated.budget, budgetId: this.state.campaign.budgetId },
                            // ...(this.state.updated.locations) && { locations: { updated: this.state.updated.locations, old: this.state.campaign.locations } },
                            ...(this.state.updated.locations) && { locations: { updated: this.state.updated.locations, old: this.state.old_locations } },
                            ...(this.state.networks.map((item) => { return item.value }).filter((item) => { return this.state.updated[item] }).length > 0) && {
                                networkSettings: {
                                    ...(this.state.updated.content_network) && { targetContentNetwork: this.state.updated.content_network.value },
                                    ...(this.state.updated.google_search) && { targetGoogleSearch: this.state.updated.google_search.value },
                                    ...(this.state.updated.partner_search_network) && { targetPartnerSearchNetwork: this.state.updated.partner_search_network.value },
                                    ...(this.state.updated.search_network) && { targetSearchNetwork: this.state.updated.search_network.value },
                                }
                            },
                            ...(this.state.updated.languages) && { languages: { updated: this.state.updated.languages, old: this.state.campaign.languages } },
                            ...(((this.state.updated.dsa_domain || this.state.updated.dsa_language) && ((this.state.updated.dsa && this.state.updated.dsa.value) || this.state.campaign.dynamicSearchAdsSetting))) && {
                                dynamicSearchAdsSetting: {
                                    ...(this.state.updated.dsa_domain) && { domainName: this.state.updated.dsa_domain },
                                    ...(this.state.updated.dsa_language) && { languageCode: this.state.updated.dsa_language.code },
                                }
                            },
                            ...(this.state.updated.deviceType || this.state.updated.contentLabel) && {
                                ...(this.state.updated.deviceType) && { deviceType: { updated: this.state.updated.deviceType, old: this.state.campaign.deviceType } },
                                ...(this.state.updated.contentLabel) && { contentLabel: { updated: this.state.updated.contentLabel, old: this.state.campaign.content_exclusions } }
                            }
                        };
                        if (this.state.updated.convertionGoals) {
                            if (this.state.updated.convertionGoals.filter((item) => {
                                if (this.state.campaign.convertion_goals.filter((goal) => { return goal.resourceName === item.resourceName && goal.biddable !== item.biddable }).length > 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }).filter((item) => { return item }).length > 0) {
                                body.conversionGoals = this.state.updated.convertionGoals.filter((item) => {
                                    if (this.state.campaign.convertion_goals.filter((goal) => { return goal.resourceName === item.resourceName && goal.biddable !== item.biddable }).length > 0) {
                                        return item;
                                    } else {
                                        return false;
                                    }
                                }).filter((item) => { return item }).map((item) => { return { resourceName: item.resourceName, biddable: item.biddable } });

                            }
                        }
                        /*conversionGoals: [{
                                resourceName: "customers/1960524488/campaignConversionGoals/20139508731~SUBMIT_LEAD_FORM~WEBSITE",
                                biddable: false
                            }]*/
                        if (this.renders.portfolio()) {
                            if (this.state.updated.biddingStrategy && this.state.updated.biddingStrategy.resourceName !== this.state.campaign.biddingStrategy) {
                                body.biddingStrategy = this.state.updated.biddingStrategy.resourceName;
                            }
                        } else {
                            if (["targetSpendCpcBidCeiling", "allowTargetSpendCeiling", "maximizeConversionsValuesTargetROAS", "allowMaximizeConversionsValueTargetRoas", "strategy", "targetImpressionShareLocation", "targetImpressionShareFraction", "targetImpressionShareCeiling", "allowMaximizeConversionsTargetCPA", "maximizeConversionsTargetCPA",
                            ].map((key) => { return Object.keys(this.state.updated).includes(key) }).filter((item) => { return item }).length > 0 || ("portfolio" in this.state.updated)) {
                                if (this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" && (this.state.updated.targetImpressionShareLocation || this.state.updated.targetImpressionShareFraction || this.state.updated.targetImpressionShareCeiling)) {
                                    body.targetImpressionShare = {
                                        location: this.renders.targetImpressionShareLocation().value,
                                        locationFractionMicros: this.renders.targetImpressionShareFraction() * 10000,
                                        cpcBidCeilingMicros: this.renders.targetImpressionShareCeiling() * 1000000
                                    }
                                } else if (this.renders.strategy().value === "MAXIMIZE_CONVERSIONS") {
                                    body.maximizeConversions = {
                                        ...(this.renders.allowMaximizeConversionsTargetCPA().value === true) && { targetCpaMicros: this.renders.maximizeConversionsTargetCPA() * 1000000 }
                                    }
                                } else if (this.renders.strategy().value === "MAXIMIZE_CONVERSION_VALUE") {
                                    body.maximizeConversionValue = {
                                        ...(this.renders.allowMaximizeConversionsValueTargetRoas().value === true) && { targetRoas: this.renders.maximizeConversionsValuesTargetROAS() / 100 }
                                    }
                                } else if (this.renders.strategy().value === "TARGET_SPEND") {
                                    body.targetSpend = {
                                        ...(this.renders.allowTargetSpendCeiling().value === true) && { cpcBidCeilingMicros: this.renders.targetSpendCpcBidCeiling() * 1000000 }
                                    }
                                } else if (this.renders.strategy().value === "MANUAL_CPC") {
                                    body.manualCpc = {
                                        enhancedCpcEnabled: this.renders.manualCpcEnhanced().value
                                    }
                                } else if (this.renders.strategy().value === "TARGET_ROAS") {
                                }

                            }
                        }
                        body.changelog = {
                            initial_data: this.state.campaign,
                            updated_data: this.state.updated,
                            level: "campaign",
                        }
                        try {
                            let response = await this.calls.updateLiveCampaign(body);

                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseCampaign(true);
                        } else {
                            this.props.onActivateCampaign(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });
                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        assetsets: async () => {
            try {
                await this.promisedSetState({ loading_assets: true });
                let response = await this.calls.getAssetSets();
                this.promisedSetState({
                    loading_assets: false,
                    asset_sets: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    loading_assets: false,
                    asset_sets: []
                })
            }
        },
        biddingStrategies: async () => {
            return new Promise(async (resolve) => {
                try {
                    //await this.promisedSetState({ loading_merchants: true });
                    let response = await this.calls.listBiddingStrategies();
                    response.data.map((item) => {
                        this.state.bidding_strategies.push(item.biddingStrategy);
                    })

                    //response.data.unshift({ id: 0, name: "None" });
                    await this.promisedSetState({
                        bidding_strategies: this.state.bidding_strategies
                    });
                } catch (error) {
                    this.promisedSetState({

                    })
                }
                resolve();
            })
        },
        merchantAccounts: async () => {
            try {
                await this.promisedSetState({ loading_merchants: true });
                let response = await this.calls.getMerchantAccount();
                response.data.unshift({ id: 0, name: "None" });
                await this.promisedSetState({
                    loading_merchants: false,
                    merchant_accounts: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    loading_merchants: false,
                    merchant_accounts: []
                })
            }
        },
        searchLocations: async () => {
            try {
                await this.promisedSetState({ loading_locations: true });
                let response = await this.calls.getLocations();
                this.promisedSetState({
                    loading_locations: false,
                    location_options: response.data.map(((item) => {
                        if (item.geoTargetConstant.canonicalName) {
                            item.name = item.geoTargetConstant.canonicalName;
                        } else {
                            item.name = item.geoTargetConstant.name;
                        }
                        item.key = item.geoTargetConstant.resourceName;
                        return item;
                    }))
                });
            } catch (error) {
                this.promisedSetState({
                    location_options: []
                })
            }
        },
        searchGoogleLocations: async () => {
            let self = this;
            if (self.state.search_locations !== "") {
                await self.promisedSetState({ loading_locations: true });
                self.autocomplete.getPlacePredictions({
                    input: this.state.search_locations
                }, function (predictions, status) {
                    if (status === 'OK' && predictions && predictions.length > 0) {
                        self.promisedSetState({
                            loading_locations: false,
                            location_options: predictions.map((item) => {
                                item.name = item.description;
                                item.custom = true;
                                return item;
                            })
                        });
                    } else {
                        self.promisedSetState({ loading_locations: true, location_options: [] });
                    }
                });
            } else {
                self.promisedSetState({ loading_locations: true, location_options: [] });
            }
        },
        map: () => {
            if (!this.googleMapsPromise && !window.google) {
                this.googleMapsPromise = new Promise((resolve) => {
                    window.resolveGoogleMapsPromise = () => {
                        resolve(window.google);
                        delete window.resolveGoogleMapsPromise;
                    };
                    const script = document.createElement("script");
                    const API = "AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow";
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                    script.async = true;
                    document.body.appendChild(script);
                });
            } else {
                this.googleMapsPromise = new Promise((resolve) => {
                    resolve(window.google);
                });
            }
            return this.googleMapsPromise;
        },
    };

    calls = {
        updateLiveCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveCampaign?client=" + this.state.client.id + "&campaign=" + this.state.campaign.id + "&channel=google";
            return apiRegister.call(options, url);
        },
        getLocations: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listLocations?client=" + this.state.client.id + "&search=" + this.state.search_locations;
            return apiRegister.call(options, url);
        },
        getAssetSets: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listAssetSets?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listBiddingStrategies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBiddingStrategies?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getMerchantAccount: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listMerchantAccounts?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.campaign.name) {
                    return this.state.campaign.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        optimization: () => {
            try {
                if (this.state.campaign.budget_optimize_on === false) {
                    return { id: 2, name: "No", value: false };
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return "";
            }
        },
        advertising_type: () => {
            try {
                if (this.state.campaign.advertising_type !== "") {
                    /*{ id: 1, name: 'Search', value: "SEARCH" },
                                        { id: 3, name: "Video (Youtube) - Preview only", value: "VIDEO" },
                                        { id: 4, name: "Shopping Standard", value: "SHOPPING" },
                                        { id: 6, name: "Performance Max", value: "PERFORMANCE_MAX" }*/
                    return this.state.campaign.advertising_type.value;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        convertionGoals: () => {
            try {
                if ('convertionGoals' in this.state.updated) {
                    return this.state.updated.convertionGoals;
                } else if (this.state.campaign.convertion_goals) {
                    return this.state.campaign.convertion_goals;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        strategy: () => {
            try {
                if ('strategy' in this.state.updated) {
                    return this.state.updated.strategy;
                } else if (this.state.campaign.strategy) {
                    if (this.state.strategies.filter((item) => { return item.value === this.state.campaign.strategy }).length > 1) {
                        if (this.state.campaign.advertising_type.value === "PERFORMANCE_MAX") {
                            return this.state.strategies.filter((item) => { return item.id === 3 })[0];
                        } else if (this.state.campaign.advertising_type.value === "SHOPPING") {
                            return this.state.strategies.filter((item) => { return item.id === 6 })[0];
                        } else {
                            return this.state.strategies.filter((item) => { return item.value === this.state.campaign.strategy })[0];
                        }
                    } else if (this.state.strategies.filter((item) => { return item.value === this.state.campaign.strategy }).length === 1) {
                        return this.state.strategies.filter((item) => { return item.value === this.state.campaign.strategy })[0];
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        budget: () => {
            try {
                if ('budget' in this.state.updated) {
                    return this.state.updated.budget;
                } else if (this.state.campaign.budget) {
                    return this.state.campaign.budget;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else if (this.state.campaign.start) {
                    return this.state.campaign.start;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date;
                } else if (this.state.campaign.end) {
                    return this.state.campaign.end;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        targetImpressionShareLocation: () => {
            try {
                if ("targetImpressionShareLocation" in this.state.updated) {
                    return this.state.updated.targetImpressionShareLocation;
                } else if (this.state.campaign.campaign_data.targetImpressionShare && this.state.campaign.campaign_data.targetImpressionShare.location) {
                    return this.state.target_impression_share_location.filter((item) => { return item.value === this.state.campaign.campaign_data.targetImpressionShare.location })[0];
                } else {
                    return { id: 1, name: "...", value: "" };
                }
            } catch (error) {
                return { id: 1, name: "...", value: "" };
            }
        },
        targetImpressionShareFraction: () => {
            try {
                if ("targetImpressionShareFraction" in this.state.updated) {
                    return this.state.updated.targetImpressionShareFraction;
                } else if (this.state.campaign.campaign_data.targetImpressionShare && this.state.campaign.campaign_data.targetImpressionShare.locationFractionMicros) {
                    return parseFloat(this.state.campaign.campaign_data.targetImpressionShare.locationFractionMicros) / 10000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        targetImpressionShareCeiling: () => {
            try {
                if ("targetImpressionShareCeiling" in this.state.updated) {
                    return this.state.updated.targetImpressionShareCeiling;
                } else if (this.state.campaign.campaign_data.targetImpressionShare && this.state.campaign.campaign_data.targetImpressionShare.cpcBidCeilingMicros) {
                    return parseFloat(this.state.campaign.campaign_data.targetImpressionShare.cpcBidCeilingMicros) / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },

        manualCpcEnhanced: () => {
            try {
                if ("manualCpcEnhanced" in this.state.updated) {
                    return this.state.updated.manualCpcEnhanced;
                } else if (this.state.campaign.campaign_data.manualCpc && this.state.campaign.campaign_data.manualCpc.enhancedCpcEnabled) {
                    if (this.state.campaign.campaign_data.manualCpc.enhancedCpcEnabled === true) {
                        return { id: 1, name: "Yes", value: true }
                    } else {
                        return { id: 2, name: "No", value: false }
                    }
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        allowMaximizeConversionsTargetCPA: () => {
            try {
                if ("allowMaximizeConversionsTargetCPA" in this.state.updated) {
                    return this.state.updated.allowMaximizeConversionsTargetCPA;
                } else if (this.state.campaign.campaign_data.maximizeConversions && this.state.campaign.campaign_data.maximizeConversions.targetCpaMicros) {
                    return { id: 2, name: 'Enabled', value: true }
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        maximizeConversionsTargetCPA: () => {
            try {
                if ("maximizeConversionsTargetCPA" in this.state.updated) {
                    return this.state.updated.maximizeConversionsTargetCPA;
                } else if (this.state.campaign.campaign_data.maximizeConversions && this.state.campaign.campaign_data.maximizeConversions.targetCpaMicros) {
                    return parseFloat(this.state.campaign.campaign_data.maximizeConversions.targetCpaMicros) / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        allowMaximizeConversionsValueTargetRoas: () => {
            try {
                if ("allowMaximizeConversionsValueTargetRoas" in this.state.updated) {
                    return this.state.updated.allowMaximizeConversionsValueTargetRoas;
                } else if (this.state.campaign.campaign_data.maximizeConversionValue && this.state.campaign.campaign_data.maximizeConversionValue.targetRoas) {
                    return { id: 2, name: 'Enabled', value: true }
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        maximizeConversionsValuesTargetROAS: () => {
            try {
                if ("maximizeConversionsValuesTargetROAS" in this.state.updated) {
                    return this.state.updated.maximizeConversionsValuesTargetROAS;
                } else if (this.state.campaign.campaign_data.maximizeConversionValue && this.state.campaign.campaign_data.maximizeConversionValue.targetRoas) {
                    return parseFloat(this.state.campaign.campaign_data.maximizeConversionValue.targetRoas) * 100;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        allowTargetSpendCeiling: () => {
            try {
                if ("allowTargetSpendCeiling" in this.state.updated) {
                    return this.state.updated.allowTargetSpendCeiling;
                } else if (this.state.campaign.campaign_data.targetSpend && this.state.campaign.campaign_data.targetSpend.cpcBidCeilingMicros) {
                    return { id: 2, name: 'Enabled', value: true }
                } else {
                    return { id: 1, name: 'Disabled', value: false };
                }
            } catch (error) {
                return { id: 1, name: 'Disabled', value: false };
            }
        },
        targetSpendCpcBidCeiling: () => {
            try {
                if ("targetSpendCpcBidCeiling" in this.state.updated) {
                    return this.state.updated.targetSpendCpcBidCeiling;
                } else if (this.state.campaign.campaign_data.targetSpend && this.state.campaign.campaign_data.targetSpend.cpcBidCeilingMicros) {
                    return parseFloat(this.state.campaign.campaign_data.targetSpend.cpcBidCeilingMicros) / 1000000;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        biddingStrategy: () => {
            try {
                if ("biddingStrategy" in this.state.updated) {
                    return this.state.updated.biddingStrategy;
                } else if (this.state.campaign.biddingStrategy && this.state.bidding_strategies.filter((item) => { return item.resourceName === this.state.campaign.biddingStrategy }).length > 0) {
                    return this.state.bidding_strategies.filter((item) => { return item.resourceName === this.state.campaign.biddingStrategy })[0];
                } else {
                    return { id: 0, name: "None", value: "" };
                }
            } catch (error) {
                return 0;
            }
        },
        portfolio: () => {
            try {
                if ("portfolio" in this.state.updated) {
                    return this.state.updated.portfolio;
                } else if (this.state.campaign.biddingStrategy) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        locations: () => {
            try {
                if ("locations" in this.state.updated) {
                    return this.state.updated.locations;
                } else if (this.state.campaign.locations) {
                    return this.state.campaign.locations;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        languages: () => {
            try {
                if ("languages" in this.state.updated) {
                    return this.state.updated.languages;
                } else if (this.state.campaign.languages) {
                    return this.state.campaign.languages;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },

        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.campaign.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*BASIC*/}
                    {
                        this.state.tab.id === 1 &&
                        <div className="col-span-6 grid grid-cols-6 gap-4">
                            <div className="col-span-6">
                                <InputTailwind
                                    label={"Campaign name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>


                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        disabled={(moment().unix() > moment(this.renders.startDate()).unix() ? true : false)}
                                        placeholder={(moment().unix() > moment(this.renders.startDate()).unix() ? moment(this.renders.startDate()).format("MM/DD/YYYY") : "")}
                                        label={"Start date"}
                                        value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-3">
                                    <InputDatepickerTailwind
                                        label={"End date"}
                                        disabled={!this.renders.endDate()}
                                        labelRight={(this.renders.endDate() ? "Disable" : "Enable")}
                                        placeholder={!this.renders.endDate() ? "No end date" : ""}
                                        value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                        onChange={async (value) => {
                                            this.state.updated.end_date = moment(value).format("YYYY-MM-DD");
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                        onLabelRightClick={async (value) => {
                                            if (this.renders.endDate()) {
                                                this.state.updated.end_date = null;
                                            } else {
                                                let new_date = moment(this.renders.startDate()).add(1, 'days').format("YYYY-MM-DD");
                                                this.state.updated.end_date = moment(new_date);
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Bidding"}
                                        selected={this.renders.strategy()}
                                        locked={false}
                                        options={this.state.strategies.filter((item) => {
                                            if (this.renders.advertising_type() === "SHOPPING") {
                                                if (item.id === 6 || item.id === 5 || item.id === 7) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else if (this.renders.advertising_type() === "PERFORMANCE_MAX") {
                                                if (item.id == 2 || item.id == 3) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else if (this.renders.advertising_type() === "VIDEO") {
                                                return false;
                                            } else if (this.renders.advertising_type() === "DISPLAY") {
                                                if (item.id == 2 || item.id == 3 || item.id == 7 || item.id == 8) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                if (item.id !== 6 && item.id !== 5 && item.id !== 7 && !item.id == 8) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        })}
                                        onChange={async (value) => {
                                            this.state.updated.strategy = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })

                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Where do you want your ads to appear"}
                                        selected={this.renders.targetImpressionShareLocation()}

                                        locked={this.renders.portfolio()}
                                        disabled={this.renders.portfolio()}
                                        options={this.state.target_impression_share_location}
                                        onChange={async (value) => {
                                            this.state.updated.targetImpressionShareLocation = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}


                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={true}
                                        leftSectionLabel={"%"}
                                        label={"Percent (%) impression share to target"}
                                        value={this.renders.targetImpressionShareFraction()}

                                        locked={this.renders.portfolio()}
                                        disabled={this.renders.portfolio()}
                                        onChange={async (value) => {
                                            this.state.updated.targetImpressionShareFraction = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}

                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_IMPRESSION_SHARE" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        leftSection={(this.state.client.currency && this.state.client.currency.google ? true : false)}
                                        leftSectionLabel={(this.state.client.currency && this.state.client.currency.google ? this.state.client.currency.google : "")}
                                        label={"Maximum CPC bid limit"}
                                        value={this.renders.targetImpressionShareCeiling()}

                                        locked={this.renders.portfolio()}
                                        disabled={this.renders.portfolio()}
                                        onChange={async (value) => {
                                            this.state.updated.targetImpressionShareCeiling = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}

                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MANUAL_CPC" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Help increase conversions with Enhanced CPC"}
                                        selected={this.renders.manualCpcEnhanced()}
                                        options={[
                                            { id: 1, name: "Yes", value: true },
                                            { id: 2, name: "No", value: false }
                                        ]}
                                        onChange={async (value) => {
                                            this.state.updated.manualCpcEnhanced = value;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MAXIMIZE_CONVERSIONS" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.renders.allowMaximizeConversionsTargetCPA().value === true) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a target cost per action (optional)"}

                                                locked={this.renders.portfolio()}
                                                disabled={this.renders.portfolio()}
                                                selected={this.renders.allowMaximizeConversionsTargetCPA()}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.updated.allowMaximizeConversionsTargetCPA = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}

                                            />
                                        </div>
                                        {
                                            this.renders.allowMaximizeConversionsTargetCPA().value === true &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.client.currency && this.state.client.currency.google ? true : false)}
                                                    leftSectionLabel={(this.state.client.currency && this.state.client.currency.google ? this.state.client.currency.google : "")}
                                                    label={"Target CPA"}

                                                    locked={this.renders.portfolio()}
                                                    disabled={this.renders.portfolio()}
                                                    value={this.renders.maximizeConversionsTargetCPA()}
                                                    onChange={async (value) => {
                                                        this.state.updated.maximizeConversionsTargetCPA = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}

                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.strategy().value === "MAXIMIZE_CONVERSION_VALUE" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.renders.allowMaximizeConversionsValueTargetRoas().value === true) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a target return on ad spend (optional)"}
                                                selected={this.renders.allowMaximizeConversionsValueTargetRoas()}

                                                locked={this.renders.portfolio()}
                                                disabled={this.renders.portfolio()}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.updated.allowMaximizeConversionsValueTargetRoas = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.allowMaximizeConversionsValueTargetRoas().value === true &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={true}
                                                    leftSectionLabel={"%"}

                                                    locked={this.renders.portfolio()}
                                                    disabled={this.renders.portfolio()}
                                                    label={"Percent (%) target ROAS"}
                                                    value={this.renders.maximizeConversionsValuesTargetROAS()}
                                                    onChange={async (value) => {
                                                        this.state.updated.maximizeConversionsValuesTargetROAS = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}

                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.strategy().value === "TARGET_SPEND" && this.renders.advertising_type() !== "VIDEO" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className={(this.renders.allowTargetSpendCeiling().value === true) ? "col-span-3" : "col-span-6"}>
                                            <DropdownTailwind
                                                label={"Set a maximum cost per click bid limit"}
                                                locked={this.renders.portfolio()}
                                                disabled={this.renders.portfolio()}
                                                selected={this.renders.allowTargetSpendCeiling()}
                                                options={[
                                                    { id: 1, name: 'Disabled', value: false },
                                                    { id: 2, name: 'Enabled', value: true }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.updated.allowTargetSpendCeiling = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}

                                            />
                                        </div>
                                        {
                                            this.renders.allowTargetSpendCeiling().value === true &&
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    leftSection={(this.state.client.currency && this.state.client.currency.google ? true : false)}
                                                    leftSectionLabel={(this.state.client.currency && this.state.client.currency.google ? this.state.client.currency.google : "")}
                                                    label={"Maximum CPC bid limit"}
                                                    locked={this.renders.portfolio()}
                                                    disabled={this.renders.portfolio()}
                                                    value={this.renders.targetSpendCpcBidCeiling()}
                                                    onChange={async (value) => {
                                                        this.state.updated.targetSpendCpcBidCeiling = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}

                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                true &&
                                <>
                                    <div className="col-span-3">
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Use portfolio strategy</div>
                                        <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5">
                                                {this.renders.portfolio() ? "Active" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.renders.portfolio()}
                                                    onSwitch={async () => {
                                                        this.state.updated.portfolio = !this.renders.portfolio();
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Portfolio strategy"}
                                            locked={!this.renders.portfolio()}
                                            disabled={!this.renders.portfolio()}
                                            selected={this.renders.biddingStrategy()}
                                            options={this.state.bidding_strategies.filter((item) => { return item.type === this.renders.strategy().value })}
                                            onChange={async (value) => {
                                                this.state.updated.biddingStrategy = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            <div className="col-span-3">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ENABLED" ? true : false}
                                            disabled={this.state.locked}
                                            onSwitch={async () => {
                                                if (this.renders.status().value === "ENABLED") {
                                                    this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                                } else if (this.renders.status().value === "PAUSED") {
                                                    this.functions.update({ id: 1, name: 'Active', value: "ENABLED" });
                                                }

                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/*GOAL*/}
                    {
                        this.state.tab.id === 7 &&
                        Array.isArray(this.renders.convertionGoals()) && this.renders.convertionGoals().length > 0 &&
                        <>
                            {
                                this.renders.convertionGoals().map((item, index) => {
                                    return (
                                        <div className="col-span-3">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>{item.name}</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {item.biddable ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={item.biddable ? true : false}
                                                        onSwitch={async () => {
                                                            if (!this.state.updated.convertionGoals) {
                                                                this.state.updated.convertionGoals = JSON.parse(JSON.stringify(this.renders.convertionGoals()));
                                                            }
                                                            this.state.updated.convertionGoals = this.state.updated.convertionGoals.map((goal) => {
                                                                if (goal.resourceName === item.resourceName) {
                                                                    goal.biddable = !item.biddable;
                                                                }
                                                                return goal;
                                                            })
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </>


                    }

                    {/*BUDGET*/}
                    {
                        this.state.tab.id === 2 &&

                        <div className="col-span-6">
                            <InputTailwind
                                label={"Daily budget"}
                                value={this.renders.budget()}
                                type={"number"}
                                onChange={(value) => {
                                    this.state.updated.budget = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />
                        </div>

                    }
                    {/*Location*/}
                    {
                        this.state.tab.id === 3 &&
                        <>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"View type"}
                                    loader={this.state.loading_map}
                                    selected={this.state.map ? this.state.map : { id: 1, name: "List View", value: false }}
                                    options={[
                                        { id: 1, name: "List View", value: false },
                                        { id: 2, name: "Map view", value: true }
                                    ]}
                                    onChange={async (value) => {
                                        // if (Object.keys(this.state.campaign).length > 0 && Array.isArray(this.state.campaign.locations) && this.state.campaign.locations.length > 0) {
                                        //     this.state.place = this.renders.locations().find((item => item.custom));
                                        // }
                                        let buff = null;
                                        // if (Object.keys(this.state.campaign).length > 0 && Array.isArray(this.state.campaign.locations) && this.state.campaign.locations.length > 0) {
                                        if (this.renders.locations().length > 0) {
                                            buff = this.renders.locations().find((item) => item.custom);
                                            if (buff === undefined || buff === null) {
                                                this.state.place = this.state.place_default;
                                            } else {
                                                if (!buff.lat) {
                                                    buff.lat = buff.latitude;
                                                }
                                                if (!buff.lng) {
                                                    buff.lng = buff.longitude
                                                }
                                                this.state.place = buff;
                                            }
                                        }
                                        this.refs.searchDropdown.functions.resetSearch();
                                        await this.promisedSetState({
                                            map: value,
                                            location_options: [],
                                            search_locations: "",
                                            place: this.state.place,
                                        })
                                        await this.promisedSetState({
                                            location_type: this.state.map.value ? { id: 1, name: "Included", value: true } : this.state.location_type
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-span-4">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    loader={this.state.loading_map}
                                    rightLabel={true}
                                    rightLabelText={this.state.location_type.value ? "Included" : "Excluded"}
                                    onRightLabelClick={async () => {
                                        if (!this.state.map.value) {
                                            await this.promisedSetState({ location_type: !this.state.location_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                        }
                                    }}
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.location_options) ? this.state.location_options : []}
                                    loadingSearch={this.state.loading_locations}
                                    onChange={(value) => {
                                        let location = null;
                                        if (!this.state.map.value) {
                                            if (!this.state.location_type || (this.state.location_type && this.state.location_type.id == 1)) {
                                                value.included = true;
                                            } else {
                                                value.included = false;
                                            }
                                            location = value;
                                            location.id = Math.floor((Math.random() * 9999999999) + 1);
                                            if (!Array.isArray(this.state.updated.locations)) {
                                                this.state.updated.locations = JSON.parse(JSON.stringify(this.state.campaign.locations));
                                            }
                                            if (this.renders.locations().filter((item) => { return item.key === location.key }).length === 0) {
                                                this.state.updated.locations.push(location);
                                            }
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        } else {
                                            let self = this;
                                            this.geocoder.geocode({ 'placeId': value.place_id }, async function (results, status) {
                                                if (status === 'OK' && results && results.length > 0) {
                                                    location = {
                                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                                        custom: true,
                                                        included: true,
                                                        include: true,
                                                        new_location: true,
                                                        campaign_edit: true,
                                                        name: (parseFloat(results[0].geometry.location.lat().toFixed(3)) + " lat, " + parseFloat(results[0].geometry.location.lng()).toFixed(3) + " lng"),
                                                        lat: parseFloat(results[0].geometry.location.lat()),
                                                        lng: parseFloat(results[0].geometry.location.lng()),
                                                        radius: 30000,
                                                        geo_point: {
                                                            lat: parseFloat(results[0].geometry.location.lat()),
                                                            lng: parseFloat(results[0].geometry.location.lng())
                                                        }
                                                    };
                                                    if (!Array.isArray(self.state.updated.locations)) {
                                                        self.state.updated.locations = JSON.parse(JSON.stringify(self.state.campaign.locations));
                                                    }
                                                    if (self.renders.locations().filter((item) => { return item.lat === location.lat && item.lng === location.lng }).length === 0) {
                                                        self.state.updated.locations.push(location);
                                                    }
                                                    await self.promisedSetState({
                                                        place: location,
                                                        updated: self.state.updated,
                                                        updateMap: true
                                                    });
                                                    await self.promisedSetState({
                                                        updateMap: false
                                                    });
                                                }
                                            });
                                        }

                                        console.log(this.renders.locations(), "LOCATIONS");
                                    }}
                                    onSearch={async (value) => {
                                        await this.promisedSetState({ search_locations: value });
                                        if (this.state.map.value) {
                                            this.functions.searchGoogleLocations();
                                        } else {
                                            this.functions.searchLocations();
                                        }
                                    }}
                                />
                            </div>

                            {
                                this.renders.locations().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.locations().sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.renders.locations().filter((item) => { return item.included }).length;
                                            let included = this.renders.locations().filter((item) => { return item.included });
                                            let excluded = this.renders.locations().filter((item) => { return !item.included });
                                            return (
                                                <>
                                                    {included.length > 0 && index === 0 && (
                                                        <>
                                                            <div className="text-xs font-medium text-gray-700 mb-1">Included</div>
                                                            <div className='flex flex-wrap'>
                                                                {included.map((item, index) => (
                                                                    <div className={`${((item.geo_point && this.state.map.value) || (!item.geo_point && !this.state.map.value) ? " cursor-pointer " : " opacity-50 ") + " bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "} ${index === included.length + 1 ? "ml-1" : "mr-1"}`}
                                                                        style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                    >
                                                                        <LocationMarkerIcon onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className={"text-indigo-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                        <div onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                        {
                                                                            this.state.map.value &&
                                                                            this.state.place && this.state.place.id == item.id &&
                                                                            <CursorClickIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-indigo-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                        }
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.locations = this.renders.locations().filter((location) => {
                                                                                    if (location.custom && item.custom) {
                                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                                    } else {
                                                                                        return location.key !== item.key;
                                                                                    }
                                                                                })
                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove location</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {excluded.length > 0 && index === included_amount && (
                                                        <>
                                                            <div className="text-xs font-medium text-gray-700 mb-1">Excluded</div>
                                                            <div className='flex flex-wrap space-x-2'>
                                                                {excluded.map((item, index) => (
                                                                    <div className={(((item.geo_point && this.state.map.value) || (!item.geo_point && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + " bg-red-100 text-red-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}
                                                                        style={{ minWidth: "fit-content", maxWidth: "fit-content", width: "fit-content" }}
                                                                    >
                                                                        <LocationMarkerIcon onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className={"text-red-500 h-5 w-5 mr-1"} aria-hidden="true" />
                                                                        <div onClick={async () => {
                                                                            if (this.state.map.value && item.custom) {
                                                                                await this.promisedSetState({
                                                                                    place: item,
                                                                                    updateMap: true
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    updateMap: false
                                                                                });
                                                                            }
                                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                                        {
                                                                            this.state.map.value &&
                                                                            this.state.place && this.state.place.id == item.id &&
                                                                            <CursorClickIcon onClick={async () => {
                                                                                if (this.state.map.value && item.custom) {
                                                                                    await this.promisedSetState({
                                                                                        place: item,
                                                                                        updateMap: true
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        updateMap: false
                                                                                    });
                                                                                }
                                                                            }} className={"text-red-500 h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                                        }
                                                                        <button
                                                                            onClick={() => {
                                                                                this.state.updated.locations = this.renders.locations().filter((location) => {
                                                                                    if (location.custom && item.custom) {
                                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                                    } else {
                                                                                        return location.key !== item.key;
                                                                                    }
                                                                                })
                                                                                this.setState({
                                                                                    updated: this.state.updated
                                                                                })
                                                                            }}
                                                                            type="button"
                                                                            className={"focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                        >
                                                                            <span className="sr-only">Remove location</span>
                                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            }

                            {/* {
                                this.renders.locations().length > 0 &&
                                <div className="col-span-6 flex flex-col">
                                    {
                                        this.renders.locations().sort(function (x, y) {
                                            return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                        }).map((item, index) => {
                                            let included_amount = this.renders.locations().filter((item) => { return item.included }).length;
                                            let excluded_amount = this.renders.locations().filter((item) => { return !item.included }).length;
                                            return (
                                                <Fragment>
                                                    {
                                                        index == 0 && included_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Included
                                                        </div>
                                                    }
                                                    {
                                                        (included_amount == index) && excluded_amount > 0 &&
                                                        <div className="text-xs font-medium text-gray-700 mb-1">
                                                            Excluded
                                                        </div>
                                                    }
                                                    <div className={(((item.geo_point && this.state.map.value) || (!item.geo_point && !this.state.map.value)) ? " cursor-pointer " : " opacity-50 ") + (item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                        <LocationMarkerIcon onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1"} aria-hidden="true" />
                                                        <div onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }} className="flex flex-1 whitespace-no-wrap truncate">{item.name}{item.radius ? (" (" + Math.round(item.radius / 1000) + " km)") : ""}</div>
                                                        {
                                                            this.state.map.value &&
                                                            this.state.place && this.state.place.id == item.id &&
                                                            <CursorClickIcon onClick={async () => {
                                                                if (this.state.map.value && item.custom) {
                                                                    await this.promisedSetState({
                                                                        place: item,
                                                                        updateMap: true
                                                                    });
                                                                    await this.promisedSetState({
                                                                        updateMap: false
                                                                    });
                                                                }
                                                            }} className={(item.included ? "text-indigo-500" : "text-red-500") + " h-5 w-5 mr-1 ml-2"} aria-hidden="true" />
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                this.state.updated.locations = this.renders.locations().filter((location) => {
                                                                    if (location.custom && item.custom) {
                                                                        return !(location.lat == item.lat && location.lng == item.lng);
                                                                    } else {
                                                                        return location.key !== item.key;
                                                                    }
                                                                })
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            type="button"
                                                            className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove location</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            } */}

                            {
                                this.state.place &&
                                this.state.map.value &&
                                <div className="col-span-6">
                                    <div className="overflow-hidden border-1.5 border-estatery-400 rounded-md">
                                        <AdvertisingLocationMapRegions
                                            ref="googleMapsRef"
                                            update={this.state.updateMap}
                                            center={this.state.center}
                                            zoom={this.state.zoom}
                                            place={this.state.place}
                                            places={this.renders.locations().filter((item) => { return item.geo_point })}
                                            selected={this.renders.locations().filter((item) => { return item.geo_point })}
                                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                            loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            containerElement={<div style={{
                                                height: "500px",
                                                width: '100%',
                                                borderRadius: '0px',
                                                overflow: 'hidden',
                                                border: 'none'
                                            }} />}
                                            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                                            onUpdateLocations={(values) => {

                                            }}
                                            onUpdateSelected={(values, location) => {
                                                this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations())).map((item) => {
                                                    if (item.id == location.id) {
                                                        item = location;
                                                        item.geo_point = {
                                                            lat: location.lat ? location.lat : location.latitude,
                                                            lng: location.lng ? location.lng : location.longitude
                                                        }
                                                        item.updated = true;
                                                        item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng";
                                                    }
                                                    return item;
                                                })
                                                this.setState({
                                                    place: location,
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onUpdate={(value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                    }
                    {/*SETTINGS*/}
                    {
                        this.state.tab.id === 4 &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Advertising type"}
                                    locked={true}
                                    selected={[{ id: 1, name: 'Search', value: "SEARCH" },
                                    { id: 2, name: 'Display Network', value: "DISPLAY" },
                                    { id: 3, name: "Video (Youtube) - Preview only", value: "VIDEO" },
                                    { id: 4, name: "Shopping Standard", value: "SHOPPING" },
                                    { id: 6, name: "Performance Max", value: "PERFORMANCE_MAX" }, { id: 0, name: "", value: "" }].filter((item) => { return item.value === this.renders.advertising_type() })[0]}
                                    options={[]}
                                    onChange={async (value) => {

                                    }}
                                />
                            </div>
                            {
                                this.renders.advertising_type() !== "DISPLAY" && this.renders.advertising_type() !== "PERFORMANCE_MAX" && this.renders.advertising_type() !== "" &&
                                <div className="col-span-6 grid grid-cols-12 gap-4">
                                    {
                                        this.state.networks.map((item) => {
                                            return (
                                                <div className="col-span-3">
                                                    <DropdownTailwind
                                                        label={item.name}
                                                        searchInput={false}
                                                        locked={item.value === "google_search" || (this.renders.advertising_type() === "SHOPPING" && item.value === "google_search_network") ? true : false}
                                                        selected={this.state.updated[item.value] ? this.state.updated[item.value] : (this.state.campaign[item.value].value ? { id: 1, name: "Enabled", value: true } : { id: 2, name: "Disabled", value: false })}
                                                        options={[
                                                            { id: 1, name: "Enabled", value: true },
                                                            { id: 2, name: "Disabled", value: false }
                                                        ]}
                                                        onChange={async (value) => {
                                                            this.state.updated[item.value] = value;
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                        onSearch={async (value) => {

                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.renders.advertising_type() === "DISPLAY" &&
                                <>
                                    <div className="col-span-6 grid grid-cols-12">
                                        <div class="col-span-12 text-xs font-medium text-gray-700 mb-1 w-full">Content Exclusions</div>
                                        {this.state.content_exclusions.map((content, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={async () => {
                                                        this.state.content_exclusions = JSON.parse(JSON.stringify(this.state.content_exclusions)).map((item) => {
                                                            if (item.value === content.value) {
                                                                item.included = !content.included;
                                                            }
                                                            return item;
                                                        });
                                                        await this.promisedSetState({
                                                            content_exclusions: this.state.content_exclusions
                                                        });
                                                        //if (!this.state.updated.contentLabel) {
                                                        this.state.updated.contentLabel = JSON.parse(JSON.stringify(this.state.content_exclusions));
                                                        //}
                                                        console.log(this.state.updated.contentLabel, "CONTENT EXCLUSIONS");
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                    className={"col-span-12 md:col-span-6 lg:col-span-4 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{content.name}</div>
                                                    <div className="ml-auto">
                                                        <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="col-span-6 flex flex-wrap">
                                        <div class="text-xs font-medium text-gray-700 mb-1 w-full">Set specific targeting for devices (Default is all devices)</div>
                                        {this.state.deviceSetting.map((content, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={async () => {

                                                        content.included = !content.included;
                                                        //if (!this.state.updated.deviceType) {
                                                        this.state.updated.deviceType = JSON.parse(JSON.stringify(this.state.deviceSetting));
                                                        //}

                                                        //const find = this.state.updated.deviceType.findIndex(item => item.value === content.value);
                                                        //if (find >= 0) {
                                                        //this.state.updated.deviceType = this.state.updated.deviceType.filter(device => device.value !== content.value);
                                                        //} else {
                                                        //this.state.updated.deviceType.push(content)
                                                        //}
                                                        //this.state.updated.devices = !!this.state.updated.deviceType.length;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                    className={"sm:w-full md:w-1/4 lg:w-1/4 " + (content.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{content.name}</div>
                                                    <div className="ml-auto">
                                                        <div className={(content.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(content.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                            {
                                this.renders.advertising_type() !== "SHOPPING" && this.renders.advertising_type() !== "" &&
                                <>
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Languages"}
                                            searchInput={true}
                                            selected={{ name: "Search ..." }}
                                            options={this.state.languages}
                                            onChange={(value) => {
                                                let location = value;
                                                location.id = location.google_id;
                                                if (!Array.isArray(this.state.updated.languages)) {
                                                    this.state.updated.languages = JSON.parse(JSON.stringify(this.state.campaign.languages));
                                                }
                                                if (this.renders.languages().filter((item) => { return item.id == location.id }).length === 0) this.state.updated.languages.push(location);
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                            onSearch={async (value) => {

                                            }}
                                        />
                                    </div>
                                    {
                                        this.renders.languages().length > 0 &&
                                        <div className="col-span-6 flex flex-col">
                                            {
                                                this.renders.languages().map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={" bg-indigo-100 text-indigo-700 flex flex-1 rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium "}>
                                                                <div className="flex flex-1 whitespace-no-wrap truncate">{item.name}</div>
                                                                <button
                                                                    onClick={() => {
                                                                        this.state.updated.languages = this.renders.languages().filter((location) => {
                                                                            return location.id !== item.id;
                                                                        })
                                                                        this.setState({
                                                                            updated: this.state.updated
                                                                        })
                                                                    }}
                                                                    type="button"
                                                                    className={" focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                >
                                                                    <span className="sr-only">Remove language</span>
                                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </>
                            }
                            {
                                //TEMP
                                //false &&
                                this.renders.advertising_type() === "SEARCH" &&
                                <>
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Dynamic search ad"}
                                            locked={this.state.campaign.dynamicSearchAdsSetting ? true : false}
                                            selected={this.state.updated.dsa ? this.state.updated.dsa : (this.state.campaign.dynamicSearchAdsSetting ? { id: 1, name: "Enable", value: true } : { id: 2, name: "Disable", value: false })}
                                            options={[
                                                { id: 1, name: "Enable", value: true },
                                                { id: 2, name: "Disable", value: false }
                                            ]}
                                            onChange={async (value) => {
                                                this.state.updated.dsa = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                    {
                                        (this.state.updated.dsa && this.state.updated.dsa.value === true) || this.state.campaign.dynamicSearchAdsSetting &&
                                        <>
                                            <div className="col-span-3">
                                                <InputTailwind
                                                    label={"Domain (ex www.website.com or website.com)"}
                                                    value={this.state.updated.dsa_domain ? this.state.updated.dsa_domain : (this.state.campaign.dynamicSearchAdsSetting ? this.state.campaign.dynamicSearchAdsSetting.domainName : "")}
                                                    onChange={(value) => {
                                                        this.state.updated.dsa_domain = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <DropdownTailwind
                                                    label={"Language"}
                                                    searchInput={true}
                                                    selected={this.state.updated.dsa_language ? this.state.updated.dsa_language : (this.state.campaign.dynamicSearchAdsSetting ? this.state.languages.filter((item) => { return item.code == this.state.campaign.dynamicSearchAdsSetting.languageCode })[0] : { id: 0, name: "Select language", value: null })}
                                                    options={this.state.languages}
                                                    onChange={async (value) => {
                                                        this.state.updated.dsa_language = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>

                    }

                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div>
            </>
        )
    }
}

export default EditLiveGoogleCampaign;
