import { jwtDecode } from 'jwt-decode';
import {apiRegister} from "./apiRegister";

class tokenRegister {

    static admin = null;
    static token = null;
    static refresh_token = null;

    static set(token, refresh_token, admin) {
        tokenRegister.token = token;
        tokenRegister.refresh_token = refresh_token;
        tokenRegister.admin = admin;
        try {
            if (admin) {
                sessionStorage.setItem("admin", admin);
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("refresh_token", refresh_token);
            } else {
                localStorage.setItem("token", token);
                localStorage.setItem("refresh_token", refresh_token);
                sessionStorage.removeItem("admin");
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("refresh_token");
            }
        } catch (e) { }
    }

    static setToken(token) {
        tokenRegister.token = token;
        try {
            if (tokenRegister.admin) {
                sessionStorage.setItem("token", token);
            } else {
                localStorage.setItem("token", token);
            }
        } catch (e) { }
    }

    static get() {
        if (tokenRegister.token) {
            return tokenRegister.token
        } else {
            try {
                if (sessionStorage.getItem("admin")) {
                    if (sessionStorage.getItem("token")) {
                        return sessionStorage.getItem("token");
                    } else {
                        return null;
                    }
                } else {
                    if (localStorage.getItem("token")) {
                        return localStorage.getItem("token");
                    } else {
                        return null;
                    }
                }
            } catch (e) {
                return null;
            }
        }
    }

    static getRefreshToken() {
        if (tokenRegister.refresh_token) {
            return tokenRegister.refresh_token
        } else {
            try {
                if (sessionStorage.getItem("admin")) {
                    if (sessionStorage.getItem("refresh_token")) {
                        return sessionStorage.getItem("refresh_token");
                    } else {
                        return null;
                    }
                } else {
                    if (localStorage.getItem("refresh_token")) {
                        return localStorage.getItem("refresh_token");
                    } else {
                        return null;
                    }
                }
            } catch (e) {
                return null;
            }
        }
    }

    static isTokenExpired(token) {
        try {
            const decoded = jwtDecode(token);

            if (decoded.exp && decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return true;
        }
    }

    static refreshToken() {
        let data = {
            refresh_token: tokenRegister.getRefreshToken()
        }

        let options = apiRegister.options(null, 'POST', data);
        let url = apiRegister.url.api + "/v3/adcredo/refreshToken";
        return apiRegister.call(options, url);
    }

    static remove() {
        tokenRegister.token = null;
        tokenRegister.refresh_token = null;
        tokenRegister.admin = null;
        try {
            sessionStorage.removeItem("admin");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("refresh_token");
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
        } catch (e) { }
    }

}

export { tokenRegister }