import React, { Component } from 'react';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { HeartIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import AccountAdform from '../moduleFiles/accountAdform';
import AccountSnapchat from '../moduleFiles/accountSnapchat';
import AccountLinkedin from '../moduleFiles/accountLinkedin';
import AccountFacebook from '../moduleFiles/accountFacebook';
import AccountGoogle from '../moduleFiles/accountGoogle';
import AccountTwitter from '../moduleFiles/accountTwitter';
import AccountTiktok from '../moduleFiles/accountTiktok';
import AccountGoogleAnalytics from '../moduleFiles/accountGoogleAnalytics';
import AccountGoogleAnalytics4 from '../moduleFiles/accountGoogleAnalytics4';
import AccountDv360 from '../moduleFiles/accountDv360';
import AccountBing from '../moduleFiles/accountBing';
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
import { userRegister } from '../services/userRegister';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SideNotification from '../moduleFiles/sideNotification';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import formated_timezones from '../assets/json/formated_timezones.json';
import AccountGoogleSearchConsole from '../moduleFiles/accountGoogleSearchConsole';

class Connections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                user: false,
                page: false,
                healthcheck: false,
                remove_cache: false,
            },
            open: {
                remove_modal: false,
            },
            errors: {
                healthcheck: false,
                agent: false
            },
            agent: {},
            healthcheck: {},
            timezone: {},
            timezones: formated_timezones,
            remove: null,
            remove_name: "",
        }
    }

    componentDidMount() {
        this.functions.getAgent();
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                agent: userRegister.get() ? userRegister.get() : {}
            })
        },
        healthcheck: async () => {
            this.state.loading.healthcheck = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.healthcheck();
                await this.promisedSetState({ healthcheck: response.data });
                if (Object.keys(response.data).length === 0) {
                    this.state.errors.healthcheck = false
                } else {
                    this.state.errors.healthcheck = true
                }
                await this.promisedSetState({ errors: this.state.errors });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {
                console.log(error);
            }
            this.state.loading.healthcheck = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgent: async () => {
            this.state.loading.user = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgent();
                await this.promisedSetState({ agent: response.data });
                if (Object.keys(response.data).length === 0) {
                    this.state.errors.user = false
                } else {
                    this.state.errors.user = true
                }
                await this.promisedSetState({ errors: this.state.errors });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.user = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
                console.log(error)
            }
            this.state.loading.user = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        updateAgencyFromManager: async () => {
            this.state.loading.timezone = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                await this.calls.updateAgencyFromManager({
                    timezone: this.state.agent.agency_timezone,
                    external_link: this.state.agent.agency_external_link,
                    external_link_password: this.state.agent.agency_external_link_password
                });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.timezone = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
                console.log(error)
            }
            this.state.loading.timezone = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        updateAgencyMaxLimits: async () => {
            this.state.loading.max_limit = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                await this.calls.updateAgencyFromManager({
                    maxBudgetPercentageLimitFacebook: this.state.agent.maxBudgetPercentageLimitFacebook,
                    maxBudgetPercentageLimitBing: this.state.agent.maxBudgetPercentageLimitBing,
                    maxBudgetPercentageLimitTiktok: this.state.agent.maxBudgetPercentageLimitTiktok,
                    maxBudgetPercentageLimitSnapchat: this.state.agent.maxBudgetPercentageLimitSnapchat,
                    maxBudgetPercentageLimitLinkedin: this.state.agent.maxBudgetPercentageLimitLinkedin
                });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.max_limit = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
            }
            this.state.loading.max_limit = false;
            await this.promisedSetState({ loading: this.state.loading });
        },
        removeCachedData: async () => {
            this.state.loading.remove_cache = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.removeCachedData();
            } catch (error) { }
            this.state.loading.remove_cache = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        importClients: async (data) => {
            this.state.loading_import = true
            await this.promisedSetState({ loading_import: true });
            try {
                await this.calls.importClients({ clients: data });
            } catch (error) { }
            this.state.loading.import = false
            await this.promisedSetState({ loading_import: false });
        },
        uploadGoogleAdsCsv: async (data) => {
            try {
                await this.calls.uploadGoogleAdsCsv({ clients: data });
            } catch (error) { }
            await this.promisedSetState({ loading_file: false });
        },
        getAgencyClients: async () => {
            this.state.loading.download = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgencyClients();
                this.functions.exportToCSv(response.data);
            } catch (error) { }
            this.state.loading.download = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgencyOrders: async () => {
            this.state.loading.download = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgencyOrders();
                this.functions.exportOrdersToCSv(response.data);
            } catch (error) { }
            this.state.loading.download = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgencyReports: async () => {
            this.state.loading.download = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgencyReports();
                //this.functions.exportReportsToCSv(response.data);
            } catch (error) { }
            this.state.loading.download = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        exportToCSv: (items) => {

            var headers = {
                name: "Name",
                crm_id: "crm ID",
                email: "Email",
                status: "Status",
                company: "Company",
                website: "Website",
                agents: "Agents",
                timezone: "Timezone",
                google_campaigns: "Google campaigns",
                google_manager: "Google manager",
                google_account: "Google account",
                google_maxbudget: "Google max budget",
                bing_campaigns: "Bing campaigns",
                bing_manager: "Bing manager",
                bing_account: "Bing account",
                bing_maxbudget: "Bing max budget",
                facebook_campaigns: "Facebook campaigns",
                facebook_manager: "Facebook manager",
                facebook_account: "Facebook account",
                facebook_maxbudget: "Facebook max budget",
                tiktok_campaigns: "Tiktok campaigns",
                tiktok_manager: "Tiktok manager",
                tiktok_account: "Tiktok account",
                tiktok_maxbudget: "Tiktok max budget",
                snapchat_campaigns: "Snapchat campaigns",
                snapchat_manager: "Snapchat manager",
                snapchat_account: "Snapchat account",
                snapchat_maxbudget: "Snapchat max budget",
                ga4_manager: "Google Analytics 4 Account",
                ga4_account: "Google Analytics 4 Property"
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    name: item.name ? item.name.replaceAll(",", ".") : "",
                    crm_id: item.crm_id ? item.crm_id : "",
                    email: item.email ? item.email : "",
                    status: item.status ? item.status : "",
                    company: item.company ? item.company.replaceAll(",", ".") : "",
                    website: item.website ? item.website : "",
                    agents: item.agents ? item.agents : "",
                    timezone: item.timezone ? item.timezone : "",
                    google_campaigns: item.google_campaigns ? item.google_campaigns : "",
                    google_manager: item.google_manager ? item.google_manager : "",
                    google_account: item.google_account ? item.google_account : "",
                    google_maxbudget: item.google_maxbudget ? item.google_maxbudget : "",
                    bing_campaigns: item.bing_campaigns ? item.bing_campaigns : "",
                    bing_manager: item.bing_manager ? item.bing_manager : "",
                    bing_account: item.bing_account ? item.bing_account : "",
                    bing_maxbudget: item.bing_maxbudget ? item.bing_maxbudget : "",
                    facebook_campaigns: item.facebook_campaigns ? item.facebook_campaigns : "",
                    facebook_manager: item.facebook_manager ? item.facebook_manager : "",
                    facebook_account: item.facebook_account ? item.facebook_account : "",
                    facebook_maxbudget: item.facebook_maxbudget ? item.facebook_maxbudget : "",
                    tiktok_campaigns: item.tiktok_campaigns ? item.tiktok_campaigns : "",
                    tiktok_manager: item.tiktok_manager ? item.tiktok_manager : "",
                    tiktok_account: item.tiktok_account ? item.tiktok_account : "",
                    tiktok_maxbudget: item.tiktok_maxbudget ? item.tiktok_maxbudget : "",
                    snapchat_campaigns: item.snapchat_campaigns ? item.snapchat_campaigns : "",
                    snapchat_manager: item.snapchat_manager ? item.snapchat_manager : "",
                    snapchat_account: item.snapchat_account ? item.snapchat_account : "",
                    snapchat_maxbudget: item.snapchat_maxbudget ? item.snapchat_maxbudget : "",
                    ga4_manager: item.ga4_manager ? item.ga4_manager : "",
                    ga4_account: item.ga4_account ? item.ga4_account : ""
                });
            });

            var fileTitle = 'Clients';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        },
        exportReportsToCSv: (items) => {

            var headers = {
                report: "Report",
                crm_id: "crm ID",
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    report: item.report,
                    crm_id: item.crm_id ? item.crm_id : "",
                });
            });

            var fileTitle = 'Reports';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        },
        exportOrdersToCSv: (items) => {

            var headers = {
                client: "Client",
                order: "Order",
                status: "status",
                crm_id: "Crm ID",
                crm_budget: "Crm Budget",
                google: "Google connected",
                google_budgets: "Google Shared budgets",
                google_daily: "Google Shared Daily budget",
                google_30: "Google Shared 30 days total budget",
                bing: "Bing connected",
                bing_budgets: "Bing Shared budgets",
                bing_daily: "Bing Shared Daily budget",
                bing_30: "Bing Shared 30 days total budget",
                crm_compare: "(Google + Bing 30 days budget) - CRM Budget",
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    client: item.client ? item.client.replaceAll(",", ".") : "",
                    order: item.order ? item.order.replaceAll(",", ".") : "",
                    status: item.status,
                    crm_id: item.crm_id,
                    crm_budget: item.crm_budget,
                    google: item.google,
                    google_budgets: item.google_budgets,
                    google_daily: item.google_daily,
                    google_30: item.google_30,
                    bing: item.bing,
                    bing_budgets: item.bing_budgets,
                    bing_daily: item.bing_daily,
                    bing_30: item.bing_30,
                    crm_compare: item.crm_compare,
                });
            });

            var fileTitle = 'Orders';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        }
    }

    calls = {
        healthcheck: async () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/platformsHealthCheck";
            return apiRegister.call(options, url);
        },
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        },
        updateAgencyFromManager: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgencyFromManager";
            return apiRegister.call(options, url);
        },
        removeCachedData: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCachedData";
            return apiRegister.call(options, url);
        },
        importClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/importClients";
            return apiRegister.call(options, url);
        },
        uploadGoogleAdsCsv: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/custom-crm/uploadGoogleAdsCsv";
            return apiRegister.call(options, url);
        },
        getAgencyClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyClients";
            return apiRegister.call(options, url);
        },
        getAgencyOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyOrders";
            return apiRegister.call(options, url);
        },
        getAgencyReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/importClients";
            return apiRegister.call(options, url);
        }
    }

    handleFileUpload = (event) => {
        if (!this.state.loading_file) {
            this.setState({
                loading_file: true
            });
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {

                const csvText = e.target.result;
                const data = this.csvToJson(csvText);

                //this.functions.uploadGoogleAdsCsv(data.json);
                this.functions.importClients(data.json);

            };
            reader.readAsText(file);
        }
    };

    csvToJson = (csvText) => {

        csvText = csvText.replaceAll(/[']/g, "");

        const lines = csvText.split('\n');

        //LOCATE WHERE HEADERS STARTS
        let header_index = 0;

        const headers = Splitter(lines[header_index]);

        let result_json = [];
        for (let i = 0; i < lines.length; i++) {
            if (i > header_index) {
                const obj_json = {};
                const currentLine = Splitter(lines[i]);
                if (currentLine) {
                    for (let j = 0; j < headers.length; j++) {
                        if (headers[j]) {
                            let currentHeader = headers[j].replaceAll(/["\r]/g, "");
                            if (currentHeader) {
                                if (currentLine[j]) {
                                    obj_json[currentHeader] = currentLine[j].replaceAll(/["\r]/g, "");
                                } else {
                                    obj_json[currentHeader] = "";
                                }
                            }
                        }
                    }
                }
                result_json.push(obj_json);
            }
        }

        return { json: result_json }

        function Splitter(text) {

            var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
            var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            // Return NULL if input string is not well formed CSV string.

            if (!re_valid.test(text)) {
                console.log("CSV ROW ERROR: ", text);
            }

            if (!re_valid.test(text)) return null;
            var a = [];                     // Initialize array to receive values.
            text.replace(re_value, // "Walk" the string using replace with callback.
                function (m0, m1, m2, m3) {
                    // Remove backslash from \' in single quoted values.
                    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                    // Remove backslash from \" in double quoted values.
                    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                    else if (m3 !== undefined) a.push(m3);
                    return ''; // Return empty string.
                });
            // Handle special case of empty last value.
            if (/,\s*$/.test(text)) a.push('');
            return a;
        };

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='w-full'>

                {
                    this.state.loading.user &&
                    <div className='min-h-screen flex flex-col justify-center items-center w-full'>
                        <div style={{ borderTopColor: "transparent" }} className='w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin'></div>
                        <div className='font-semibold mt-3'>Loading connections...</div>
                    </div>
                }

                <WarningModalTailwind
                    open={this.state.open.remove_modal}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.open.remove_modal ? this.state.remove.name : ""}
                    inputError={(!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)) ? "Name does not match" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ open: { remove_modal: false } });
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={async (value) => {
                        if (this.state.open.remove_modal && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeCachedData();
                            await this.promisedSetState({ open: { remove_modal: false } });
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*ONLY FOR RUNNING SCRIPTS*/}
                {
                    false &&
                    <div className="w-full h-24 bg-white relative">
                        <div className="rounded-md absolute h-full w-full cursor-pointer flex justify-center items-center" >
                            <div className="relative">
                                {
                                    <div className="items-center animate-bounce justify-center flex-col flex text-sm font-medium">
                                        Click here (importClients)
                                    </div>
                                }
                                {
                                    <div className="pt-2">
                                        <div onClick={() => { this.functions.importClients([]) }} className="font-medium rounded-full overflow-hidden relative inline-flex flex-row cursor-pointer py-2 px-4 bg-purple-100 text-sm text-purple-500">
                                            {
                                                !(Array.isArray(this.state.json) && this.state.json.length > 0) &&
                                                <span>Upload .csv file</span>
                                                ||
                                                <span>Upload new file</span>
                                            }
                                            {
                                                this.state.loading_import &&
                                                <div className="absolute left-0 right-0 top-0 bottom-0 flex w-full h-full items-center bg-purple-100 justify-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500  border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/*ONLY FOR RUNNING SCRIPTS*/}
                {
                    false &&
                    <div className="w-full h-24 bg-white relative">
                        <div className="rounded-md absolute h-full w-full cursor-pointer flex justify-center items-center" onClick={() => document.getElementById('file').click()}>
                            <div className="relative">
                                {
                                    <div className="items-center animate-bounce justify-center flex-col flex text-sm font-medium">
                                        Click here
                                    </div>
                                }
                                {
                                    <div className="pt-2">
                                        <input type="file" accept='.csv' className='hidden' id="file" onChange={this.handleFileUpload} />
                                        <div className="font-medium rounded-full overflow-hidden relative inline-flex flex-row cursor-pointer py-2 px-4 bg-purple-100 text-sm text-purple-500">
                                            {
                                                !(Array.isArray(this.state.json) && this.state.json.length > 0) &&
                                                <span>Upload .csv file</span>
                                                ||
                                                <span>Upload new file</span>
                                            }
                                            {
                                                this.state.loading_file &&
                                                <div className="absolute left-0 right-0 top-0 bottom-0 flex w-full h-full items-center bg-purple-100 justify-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500  border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    !this.state.loading.user &&
                    <div className='p-4'>

                        <div className='shadow bg-white rounded-md'>

                            <div className='py-5 px-4 sm:px-6'>

                                {/* GRID */}
                                <div className='grid grid-cols-4 gap-4'>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountTiktok
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountTwitter
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountGoogle
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountFacebook
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountLinkedin
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountSnapchat
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    {
                                        false &&
                                        <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                            <AccountGoogleSearchConsole
                                                healthcheck={this.state.healthcheck}
                                                user={this.state.agent}
                                            />
                                        </div>
                                    }
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountGoogleAnalytics
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountDv360
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountGoogleAnalytics4
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountAdform
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountBing
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                    <div className="col-span-4 p-4 sm:col-span-2 border rounded-md">
                                        <AccountBidtheatre
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                        />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                }

            </div >
        )

    }

}

export default Connections;

