import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import * as turf from '@turf/turf'
import AdvertisingLocationLanguage from "../advertising-location-language";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import {calls} from "./calls";
import AdvertisingLocationRegions from "../advertising-location-regions";

class AdvertisingLocationCustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            countries: [],
            languages: [],
            selected: [],
            channels: [],
            loading: false,
            search: "",
            channel: "",
            disable: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disable: nextProps.disable,
            languages: nextProps.languages?nextProps.languages:[],
            selected: nextProps.selected?nextProps.selected:[],
            country: nextProps.country?nextProps.country:{},
            channel: nextProps.channel?nextProps.channel:"",
            countries: nextProps.countries?nextProps.countries:[],
            channels: nextProps.channels?nextProps.channels:[]
        })
    }

    componentDidMount() {
        this.setState({
            languages: this.props.languages?this.props.languages:[],
            disable: this.props.disable,
            country: this.props.country?this.props.country:{},
            channel: this.props.channel?this.props.channel:"",
            countries: this.props.countries?this.props.countries:[],
            selected: this.props.selected?this.props.selected:[],
            channels: this.props.channels?this.props.channels:[]
        });
        this.functions.map().then((response) => {
            try {
                this.autocomplete = new response.maps.places.AutocompleteService();
                this.geocoder = new response.maps.Geocoder;
                this.setState({
                    loading: false
                })
            } catch (e) {}
        });
    }

    functions = {
        map: () => {
            if (!this.googleMapsPromise && !window.google) {
                this.googleMapsPromise = new Promise((resolve) => {
                    window.resolveGoogleMapsPromise = () => {
                        resolve(window.google);
                        delete window.resolveGoogleMapsPromise;
                    };
                    const script = document.createElement("script");
                    const API = "AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow";
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                    script.async = true;
                    document.body.appendChild(script);
                });
            }else{
                this.googleMapsPromise = new Promise((resolve) => {
                    resolve(window.google);
                });
            }
            return this.googleMapsPromise;
        },
        locations: (search) => {
            let self = this;
            if (search !== "") {
                this.setState({
                    loading: true
                }, () => {
                    this.autocomplete.getPlacePredictions({
                        input: search
                    }, function (predictions, status) {
                        if (status === 'OK' && predictions && predictions.length > 0) {
                            self.setState({
                                loading: false,
                                locations: predictions.map((item) => {
                                    item.name = item.description;
                                    return item;
                                })
                            });
                        } else {
                            self.setState({
                                loading: false,
                                locations: []
                            });
                        }
                    });
                })
            } else {
                self.setState({
                    loading: false,
                    locations: []
                });
            }
        },
        add: (value) => {
            let country = {};
            try{
                value.address_components.map((item) => {
                    this.state.countries.map((inner_item) => {
                        if(inner_item.country === item.short_name){
                            country = inner_item;
                        }
                    })
                });
                //console.log(country);
            }catch (e) {
                //console.log(e);
            }
            try {

                let item = {
                    channels: {},
                    channel: this.state.channel,
                    id: Math.floor((Math.random() * 99999999) + 1),
                    name: this.renders.place(value),
                    lat: parseFloat(value.geometry.location.lat()),
                    lng: parseFloat(value.geometry.location.lng()),
                    radius: 30000,
                    custom: true,
                    include: true,
                    country: country.country,
                    code: country.country,
                    country_name: country.name,
                    level: "custom",
                    language: this.state.languages[0]
                };

                if(this.state.selected.filter((item) => {return item.channels.google && !item.custom}).length < 1){
                    item.channels.google = {keep: "true"};
                }

                if(this.state.selected.filter((item) => {return item.channels.google_shopping && !item.custom}).length < 1){
                    item.channels.google_shopping = {keep: "true"};
                }

                if(this.state.selected.filter((item) => {return item.channels.facebook && !item.custom}).length < 1){
                    item.channels.facebook = {keep: "true"};
                }

                this.setState({
                    search: "",
                    locations: []
                });
                this.props.onChange(item);
            } catch (e) {
                //console.log(e);
            }
        }
    };

    renders = {
        place: (item) => {
            try{
                return item.address_components[0].long_name;
            }catch (e) {
                return item.name
            }
        },
        available: () => {
            return this.state.channels.filter((item) => {
                return item !== "linkedin";
            }).filter((channel) => {
                return this.state.selected.filter((item) => {return item.channels[channel] && !item.custom}).length < 1
            }).length > 0
        }
    };

    render() {
        return (
            <Dropdown
                filter={true}
                searchFilter={true}
                searchList={false}
                search={true}
                icons={true}
                channels={this.state.channels.filter((channel) => {
                    return channel !== "linkedin" && this.state.selected.filter((item) => {return item.channels[channel] && !item.custom}).length < 1
                })}
                title={this.props.title + (this.state.disable?" (disabled)":"")}
                size={this.props.size?this.props.size:'half'}
                placeholder={(!this.state.disable && this.renders.available())?"Search here ..":("Regular locations selected, remove to enable this input")}
                disabled={this.state.disable || !this.renders.available()}
                removeArrow={this.state.disable || !this.renders.available()}
                options={this.state.locations.map((item) => {
                    try{
                        if(Array.isArray(item.types) && item.types.length > 0){
                            item.suffix = " (" + item.types[0] + ")";
                            item.suffix = item.suffix.replace("_", " ");
                        }
                    }catch (e) {}
                    return item;
                })}
                value={this.state.search}
                onSearch={(value) => {
                    this.setState({
                        search: value
                    }, () => {
                        this.functions.locations(this.state.search)
                    })
                }}
                onChange={(item) => {
                    if(this.renders.available()){
                        let self = this;
                        this.geocoder.geocode({'placeId': item.place_id}, function (results, status) {
                            if (status === 'OK' && results && results.length > 0) {
                                self.functions.add(results[0]);
                            }
                        });
                    }
                }}
            />
        )
    }
}

export default AdvertisingLocationCustom;
