import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import UploadImage from "../../components/image-upload";
import AgencyUsers from "../../components/agency-users";
import {calls} from "./calls";

import SlideOver from "../slideover";

class Agency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            open: false,
            loading: false,
            warning_modal: false
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false,
            }, () => {
                calls.removeAgency(this.state.item._id).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onRemove(this.state.item._id);
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        handleChildClick: (e) => {
            this.setState({open: !this.state.open})
        }
    };

    renders = {};

    render() {
        return (
            <tr className="">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove agency"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex items-center">
                        <div className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                            <img className="h-10 w-10 rounded-full object-contain" src={this.state.item.logo}
                                 alt={this.state.item.company}/>
                        </div>
                        <div className="text-sm leading-5 font-bold text-gray-900 ml-4">
                            {this.state.item.company}
                        </div>
                    </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm capitalize">
                    <div className="text-sm leading-5 text-gray-900">
                        {this.state.item.name}
                    </div>
                    <div className="text-xs leading-5 text-gray-500">
                        {this.state.item.email}
                    </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm">
                    <div className="flex flex-row items-center">
                        {
                            <button onClick={() => {
                                this.setState({open: !this.state.open})
                            }} className="bg-purple-100 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:text-white">
                                {
                                    false &&
                                    <span className="h-6 w-6 bg-purple-500 text-white flex items-center justify-center rounded-full mr-2">{this.state.item.users}</span>
                                }
                                Show all users »
                            </button>
                        }
                    </div>

                    {/*

                    <SlideDown closed={!this.state.open}>
                        <AgencyUsers item={this.state.item}/>
                    </SlideDown>
*/}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                    <div className="flex flex-row items-center">
                        <button onClick={() => {
                            this.props.history.push('/user/agencies/' + this.state.item._id);
                        }}
                                className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                            <FeatherIcon className="stroke-current mr-1" size={16} icon="edit"/> <span>Edit</span>
                        </button>
                        <button onClick={() => this.setState({
                            warning_modal: true
                        })}
                                className="ml-2 bg-red-300 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                            <FeatherIcon className="stroke-current mr-1" size={16} icon="trash"/> <span>Remove</span>
                        </button>
                    </div>
                </td>
                <td>
                    <SlideOver
                        onClick={this.functions.handleChildClick}
                        closed={!this.state.open}
                        headline={this.state.item.company + ' users'}
                        agencyusers={this.state.item}
                    />
                </td>
            </tr>
        )
    }
}

export default Agency;
