import React, {Component} from 'react';
import './style.css';
import CampaignEditBudget from "../campaign-edit-budget";
import CampaignEditDuration from "../campaign-edit-duration";
import CampaignEditLocation from "../campaign-edit-location";
import CampaignEditSettings from "../campaign-edit-settings";

class CampaignEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            errors: {},
            loading: true
        };
    };

    componentWillMount() {}

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            loading: nextProps.loading,
            errors: nextProps.campaignDataErrors
        });
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            loading: this.props.loading,
            errors: this.props.campaignDataErrors
        })
    }

    init = {};

    functions = {};

    renders = {
        status: () => {
            try{
                return this.state.campaign.status
            }catch (e) {}
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    this.state.campaign.duration && this.renders.status() !== 'error' &&
                    <CampaignEditDuration
                        campaign={this.state.campaign.id}
                        duration={this.state.campaign.duration}
                    />
                }
                {
                    this.state.campaign.budget && this.renders.status() !== 'error' &&
                    <CampaignEditBudget
                        budget={this.state.campaign.budget}
                        channels={this.state.campaign.channels}
                        campaign={this.state.campaign.id}
                    />
                }
                {
                    this.state.campaign.locations && this.renders.status() !== 'error' &&
                    <CampaignEditLocation
                        locations={this.state.campaign.locations}
                    />
                }
                {
                    <CampaignEditSettings
                        campaign={this.state.campaign}
                        errors={this.state.errors}
                        history={this.props.history}
                    />
                }
            </div>
        )
    }
}

export default CampaignEdit;
