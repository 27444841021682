import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {dataRegister} from "../../services/dataRegister";
import {tokenRegister} from "../../services/tokenRegister";

export default function checkAuth(ComponentToProtect) {

    return class extends Component {
        constructor() {
            super();
            this.state = {
                loading: true,
                redirect: false,
            };
        }
        componentDidMount() {
            if(tokenRegister.get()){
                let options = {
                    method: 'GET',
                    headers: {
                        'x-auth-token': tokenRegister.get()
                    }
                };
                fetch(dataRegister.url.api + "/auth/user", options)
                    .then(res => {
                        if (res.status === 200) {
                            this.setState({ loading: false });
                        } else {
                            const error = new Error(res.error);
                            throw error;
                        }
                    })
                    .catch(err => {
                        tokenRegister.remove();
                        this.setState({ loading: false, redirect: true });
                    });
            }else{
                this.setState({ loading: false, redirect: true });
            }
        }
        render() {
            const { loading, redirect } = this.state;
            if (loading) {
                return null;
            }
            if (redirect) {
                return <Redirect to="/login" />;
            }
            return (
                <React.Fragment>
                    <ComponentToProtect {...this.props} />
                </React.Fragment>
            );
        }
    }
}