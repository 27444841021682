import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import Switch from "react-ios-switch";
import CampaignStatusSocialPreviewFacebookImage from "../campaign-status-social-preview-facebook-image";
import Carousel from 're-carousel'
import {calls} from "./calls";
import ReactTooltip from 'react-tooltip'
import CampaignOverviewTypeItem from "../campaign-overview-type-item";
var moment = require('moment');

class CampaignOverviewType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            channel: this.props.channel,
            type: this.props.type,
            error: false
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            data: this.props.data,
            channel: this.props.channel,
            type: this.props.type,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            channel: nextProps.channel,
            type: nextProps.type
        });
    }

    init = {};

    functions = {};

    renders = {
        amount: (type) => {
            try{
                return Object.keys(this.state.data[type]).length
            }catch (e) {
                return 0
            }
        },
        status: (type, id) => {
            try{
                return this.state.data[type][id].data.status.toLowerCase();
            }catch (e) {
                return "-"
            }
        },
        active: (type) => {
            try{
                return Object.keys(this.state.data[type]).filter((item) => {
                    return this.state.data[type][item].data.status.toLowerCase() === 'active' ||  this.state.data[type][item].data.status.toLowerCase() === 'enabled'
                }).length
            }catch (e) {
                return 0
            }
        },
        paused: (type) => {
            try{
                return Object.keys(this.state.data[type]).filter((item) => {
                    return this.state.data[type][item].data.status.toLowerCase() === 'paused'
                }).length
            }catch (e) {
                return 0
            }
        },
        pending: (type) => {
            try{
                return Object.keys(this.state.data[type]).filter((item) => {
                    return this.state.data[type][item].data.status === 'pending'
                }).length
            }catch (e) {
                return 0
            }
        },
        failed: (type) => {
            try{
                return Object.keys(this.state.data[type]).filter((item) => {
                    return this.state.data[type][item].data.status === 'error'
                }).length
            }catch (e) {
                return 0
            }
        },
        errors: () => {
            try{
                let errors = 0;
                errors = errors + this.state.data.campaign.errors.length;
                Object.keys(this.state.data.adsets).map((item) => {
                    errors = errors + this.state.data.adsets[item].errors.length;
                });
                Object.keys(this.state.data.ads).map((item) => {
                    errors = errors + this.state.data.ads[item].errors.length;
                });
                return errors
            }catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <div className="Campaign-Adset-Item">
                    <div className="Campaign-Campaign-Item-Channel">
                        {
                            this.state.channel === 'google' &&
                            <div className="Campaign-Campaign-Item-Channel-Google">
                                <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.channel === 'facebook' &&
                            <div className="Campaign-Campaign-Item-Channel-Facebook">
                                <img style={{width: '12px'}} src={require('../../assets/images/facebook_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.channel === 'instagram' &&
                            <div className="Campaign-Campaign-Item-Channel-Instagram">
                                <img style={{width: '25px'}} src={require('../../assets/images/instagram_icon.svg')}/>
                            </div>
                        }
                    </div>
                    <div className={cx("Campaign-Adset-Item-Values")}>
                        {
                            <div style={{width: '140px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Campaign status"}
                                </div>
                                <div style={{textTransform: 'capitalize'}} className="Campaign-Adset-Item-Section-Value">
                                    {this.state.data.campaign.status}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section Campaign-Adset-Item-Section-Border")}>
                                <div className="Campaign-Adset-Item-Section-Title Campaign-Adset-Item-Section-Title-Bold">
                                    {"Adsets"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.amount('adsets')}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Active"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.active('adsets')}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Pending"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.pending('adsets')}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Paused"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.paused('adsets')}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Failed"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.failed('adsets')}
                                </div>
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className="Campaign-Adset-Item-Section Campaign-Adset-Item-Section-Border">
                                <div className="Campaign-Adset-Item-Section-Title Campaign-Adset-Item-Section-Title-Bold">
                                    {"Ads"}
                                </div>
                                {
                                    this.state.channel !== 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        {this.renders.amount('ads')}
                                    </div>
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        -
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                {
                                    <div className="Campaign-Adset-Item-Section-Title">
                                        {"Active"}
                                    </div>
                                }
                                {
                                    this.state.channel !== 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        {this.renders.active('ads')}
                                    </div>
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        -
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Pending"}
                                </div>
                                {
                                    this.state.channel !== 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        {this.renders.pending('ads')}
                                    </div>
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        -
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Paused"}
                                </div>
                                {
                                    this.state.channel !== 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        {this.renders.paused('ads')}
                                    </div>
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        -
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div style={{width: '60px'}} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Failed"}
                                </div>
                                {
                                    this.state.channel !== 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        {this.renders.failed('ads')}
                                    </div>
                                }
                                {
                                    this.state.channel === 'google' &&
                                    <div className="Campaign-Adset-Item-Section-Value">
                                        -
                                    </div>
                                }
                            </div>
                        }
                        {
                            <div style={{flex: '1'}} className={cx("Campaign-Adset-Item-Section Campaign-Adset-Item-Section-Border")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Total errors"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.errors()}
                                </div>
                            </div>
                        }
                    </div>
                    <div onClick={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }} className="Campaign-Adset-Item-Button">
                        {
                            !this.state.open &&
                            <FeatherIcon size={25} color={'#333333'} icon={'chevron-down'}/>
                        }
                        {
                            this.state.open &&
                            <FeatherIcon size={25} color={'#333333'} icon={'chevron-up'}/>
                        }
                    </div>
                </div>
                {
                    false &&
                    <div style={{width: '100%'}}>
                        {
                            Object.keys(this.state.data).map((item) => {
                                return(
                                    <CampaignOverviewTypeItem
                                        data={this.state.data[item]}
                                        channel={this.state.channel}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }

}

export default CampaignOverviewType;
