import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from 'feather-icons-react';
import {BeatLoader} from "react-spinners";
import Dropdown from "../advertising-targeting-facebook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-ios-switch";

var moment = require('moment');

class Datepicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate ? this.props.startDate : new Date(),
            endDate: this.props.endDate ? this.props.endDate : null,
            minDate: this.props.minDate ? this.props.minDate : null,
            maxDate: this.props.maxDate ? this.props.maxDate : null,
            time: this.props.showTime ? this.props.showTime : false,
            size: this.props.size,
            disabled: this.props.disabled,
            disableEndDate: false
        };
    };

    componentDidMount() {
        this.setState({
            startDate: this.props.startDate ? this.props.startDate : new Date(),
            endDate: this.props.endDate ? this.props.endDate : null,
            minDate: this.props.minDate ? this.props.minDate : null,
            maxDate: this.props.maxDate ? this.props.maxDate : null,
            time: this.props.showTime ? this.props.showTime : false,
            size: this.props.size,
            disabled: this.props.disabled,
            disableEndDate: this.props.disableEndDate
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            startDate: nextProps.startDate ? nextProps.startDate : new Date(),
            endDate: nextProps.endDate ? nextProps.endDate : null,
            minDate: nextProps.minDate ? nextProps.minDate : null,
            maxDate: nextProps.maxDate ? nextProps.maxDate : null,
            time: nextProps.showTime ? nextProps.showTime : false,
            size: nextProps.size,
            disabled: nextProps.disabled,
            error: nextProps.error,
            disableEndDate: nextProps.disableEndDate,
            loading_end_date: nextProps.loadingEndDate
        });
    }

    init = {};

    functions = {};

    renders = {
        date: (date) => {
            try {
                if (this.state.time) {
                    return moment(date).format('DD MMM YYYY - LT');
                } else {
                    return moment(date).format('DD MMM YYYY');
                }
            } catch (e) {
            }
        },
        validDate: (date) => {
            var date = moment(date);
            return date.isValid();
        }
    };

    render() {
        return (
            <div className="w-full flex flex-row flex-wrap">
                <div className="flex flex-col w-full md:w-1/2 md:pr-2">
                    {
                        !this.props.hideDate &&
                        <div className={
                            cx("font-bold text-xs mb-1",
                                {
                                    ["text-red-500"]: this.state.error && !this.state.startDate
                                })
                        }>
                            Start date {this.props.disableStartDate && <span>(disabled)</span>}
                        </div>
                    }
                    <div style={this.props.border ? {
                        borderWidth: '1px',
                        backgroundColor: !this.props.white ? '#fcfcfc' : "#ffffff",
                        height: "55px"
                    } : {height: "55px"}} className={
                        cx("flex flew-row items-center datepicker__block bg-white text-sm rounded w-full border-0 transition-all duration-200 px-6 pr-4 text-purple-900 relative box-border",
                            {
                                ["border-red-500"]: this.state.error && !this.state.startDate,
                                ["border"]: this.props.border,
                                ["bg-white"]: this.props.white,
                                ["cursor-not-allowed text-opacity-50"]: (this.state.disabled || this.props.disableStartDate),
                                ["cursor-pointer"]: (!this.state.disabled && !this.props.disableStartDate)
                            })
                    }>
                        <div style={{right: "0"}} className="DatepickerItemInner">
                            <DatePicker
                                disabled={this.state.disabled || this.props.disableStartDate}
                                showTimeSelect={this.state.time}
                                excludeDates={this.state.endDate ? [new Date(this.state.endDate)] : []}
                                minDate={this.props.allowPast ? null : (this.state.minDate ? new Date(this.state.minDate) : new Date())}
                                maxDate={this.state.endDate ? new Date(this.state.endDate) : null}
                                selected={new Date(this.state.startDate)}
                                onChange={(date) => {
                                    this.props.onStartDate(date);
                                }}
                            />
                        </div>
                        {
                            <span style={{fontWeight: "500"}} className='inline-flex items-center justify-center'>
                                {
                                    !this.props.noIcon &&
                                    <span>
                                {this.state.startDate
                                    ?
                                    <span className="text-green-500"><FeatherIcon className="stroke-current mr-2"
                                                                                  size={13}
                                                                                  icon="check-circle"/></span>
                                    : <FeatherIcon className="stroke-current mr-2" size={13} icon="calendar"/>
                                }
                            </span>
                                }
                                {this.renders.date(this.state.startDate) === this.renders.date(new Date()) ? "Today" : this.renders.date(this.state.startDate)}
                        </span>
                        }
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2 md:pl-2">
                    {
                        !this.props.hideDate &&
                        <div className={
                            cx("font-bold text-xs mb-1",
                                {
                                    ["text-red-500"]: this.state.error && !this.state.endDate
                                })
                        }>
                            End date
                        </div>
                    }
                    <div className="flex flex-row">
                        <div className="flex flex-1">
                            <div style={this.props.border ? {
                                borderWidth: '1px',
                                backgroundColor: !this.props.white ? '#fcfcfc' : "#ffffff",
                                height: "55px"
                            } : {height: "55px"}} className={
                                cx("datepicker__block flex flew-row items-center bg-white text-sm rounded w-full border-0 transition-all duration-200 px-6 pr-4 text-purple-900 relative box-border",
                                    {
                                        ["border-red-500"]: this.state.error && !this.state.endDate,
                                        ["border"]: this.props.border,
                                        ["bg-white"]: this.props.white,
                                        ["cursor-not-allowed text-opacity-50"]: this.state.disabled || this.state.disableEndDate,
                                        ["cursor-pointer"]: (!this.state.disabled && !this.state.disableEndDate)
                                    })
                            }>
                                {
                                    this.state.loading_end_date &&
                                    <div
                                        className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                <div style={this.props.showNoEndDateSwitch ? {right: "80px"} : {right: "0"}}
                                     className="DatepickerItemInner">
                                    <DatePicker
                                        error={this.state.error && !this.state.endDate}
                                        disabled={this.state.disabled || this.state.disableEndDate}
                                        showTimeSelect={this.state.time}
                                        excludeDates={[this.state.startDate ? new Date(this.state.startDate) : new Date()]}
                                        minDate={this.props.minEndDate?this.props.minEndDate:(this.state.startDate ? new Date(this.state.startDate) : new Date())}
                                        maxDate={this.state.maxDate ? new Date(this.state.maxDate) : null}
                                        selected={this.renders.validDate(this.state.endDate) ? new Date(this.state.endDate) : (this.props.minEndDate?this.props.minEndDate:new Date(moment(this.state.startDate).add(1, 'days')))}
                                        onChange={(date) => {
                                            this.props.onEndDate(date);
                                        }}
                                    />
                                </div>
                                {
                                    <div style={{fontWeight: "500"}}
                                         className={(this.renders.validDate(this.state.endDate) ? '' : 'text-gray-500') + ' inline-flex items-center justify-center h-full'}>
                                        {
                                            !this.props.noIcon &&
                                            <div>
                                                {this.renders.validDate(this.state.endDate)
                                                    ? <div className="text-green-500"><FeatherIcon
                                                        className="stroke-current mr-2"
                                                        size={13}
                                                        icon="check-circle"/></div>
                                                    : <FeatherIcon className="stroke-current mr-2" size={13}
                                                                   icon="calendar"/>
                                                }
                                            </div>
                                        }
                                        {
                                            (!this.props.showNoEndDateSwitch || !this.state.disableEndDate) &&
                                            <div>
                                                {this.renders.validDate(this.state.endDate) ? this.renders.date(this.state.endDate) : "Select end date"}
                                            </div>
                                        }
                                        {
                                            this.props.showNoEndDateSwitch && this.state.disableEndDate &&
                                            <div>
                                                {"No end date"}
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    !this.props.showNoEndDateSwitch &&
                                    <div className="flex flex-1"></div>
                                }
                                {
                                    this.props.showNoEndDateSwitch &&
                                    <div className="flex flex-1 justify-end relative">
                                        <Switch
                                            onColor="#1AD5BD"
                                            checked={!this.state.disableEndDate}
                                            onChange={(checked) => {
                                                try {
                                                    this.props.updateNoEndDate(!checked);
                                                } catch (e) {
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Datepicker;
