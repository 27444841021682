import React, { Component, Fragment } from 'react';
import { IdentificationIcon, SearchIcon, ChevronDownIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import { Transition, Menu } from '@headlessui/react'
import { userRegister } from "../services/userRegister";
import { clientRegister } from "../services/clientRegister";
import DropdownTailwind from './dropdownTailwind';
import translations from '../assets/json/onboarding_translation.json'
const fuzzysort = require('fuzzysort');

class ClientTopNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            expand: false,
            advanced: false,
            breadcrumb: "",
            buttonTypes: {},
            buttonLoaders: {},
            orderId: "",
            dateSpan: {},
            searchInputValue: "",
            clients: [],
            search: "",
            fixed_client: null,
            client: { id: 0, name: "All clients (default)", icon: IdentificationIcon },
            page: 1,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            throttling: {},
            multi_client: 0,
            whitelabel_settings: {},
            time_spans: [
                { id: 1, name: "This month (default)", value: "this_month", lang_key: "datespan_1" },
                { id: 2, name: "Last week", value: "last_week", lang_key: "datespan_2" },
                { id: 3, name: "Last month", value: "last_month", lang_key: "datespan_3" },
                { id: 4, name: "Last 60 days", value: "last_60", lang_key: "datespan_4" },
                { id: 5, name: "Last 90 days", value: "last_90", lang_key: "datespan_5" }
            ],
            selected_time_span: { id: 1, name: "This month (default)", value: "this_month", lang_key: "datespan_1" },
        }
    };

    componentWillMount() {
        this.init.user();
        this.init.client();
    }

    componentDidMount() {
        this.setState({
            orderId: this.props.orderId,
            breadcrumb: this.props.breadcrumb,
            dateSpan: this.props.dateSpan,
            buttonTypes: this.props.buttonTypes,
            buttonLoaders: this.props.buttonLoaders ? this.props.buttonLoaders : {},
            buttons: this.props.buttons ? this.props.buttons : [],
            showPaginaton: this.props.showPaginaton,
            showDateSpan: this.props.showDateSpan,
            limit: this.props.limit,
            page: this.props.page,
            total: this.props.total,
            searchInput: this.props.searchInput,
            searchInputValue: this.props.searchInputValue,
            showPaginationSearch: this.props.showPaginationSearch,
            showFilters: this.props.showFilters,
            filters: this.props.filters,
            fixed_client: this.props.client,
            show_advanced: this.props.showAdvanced,
            advanced_filters: this.props.advancedFilters,
            multi_client: this.props.multiClient,
            advanced_filter: this.props.advancedFilter ? this.props.advancedFilter : this.state.advanced_filter,
            whitelabel_settings: this.props.whitelabel_settings ? this.props.whitelabel_settings : this.state.whitelabel_settings
        });

        if (!this.props.lockClient && !this.props.showClientLimited) {
            // this.functions.getClients(true, false, false);
        }

        if (this.props.miniMizeOnLoad && this.props.minimizeMenu) {
            this.props.minimizeMenu(true);
        }


    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            orderId: nextProps.orderId,
            breadcrumb: nextProps.breadcrumb,
            dateSpan: nextProps.dateSpan,
            buttonTypes: nextProps.buttonTypes,
            buttonLoaders: nextProps.buttonLoaders ? nextProps.buttonLoaders : {},
            buttons: nextProps.buttons ? nextProps.buttons : [],
            showPaginaton: nextProps.showPaginaton,
            showDateSpan: nextProps.showDateSpan,
            limit: nextProps.limit,
            page: nextProps.page,
            total: nextProps.total,
            searchInput: nextProps.searchInput,
            searchInputValue: nextProps.searchInputValue,
            showPaginationSearch: nextProps.showPaginationSearch,
            showFilters: nextProps.showFilters,
            filters: nextProps.filters,
            fixed_client: nextProps.client,
            multi_client: nextProps.multiClient,
            show_advanced: nextProps.showAdvanced,
            advanced_filters: nextProps.advancedFilters
        })
    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        }
    };

    functions = {
        handleTimeSpanChange: async (value) => {
            try {
                await this.promisedSetState({
                    selected_time_span: value
                });
                if (this.props.onTimeSpanChange) {
                    this.props.onTimeSpanChange(value);
                }

            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        selectedTimeSpan: () => {
            try {
                if (this.state.selected_time_span) {
                    console.log("first", this.state.selected_time_span)
                    return { ...this.state.selected_time_span, name: this.translate(this.state.selected_time_span.lang_key) }
                } else {
                    return { id: 0, name: "Select ...", value: "" }
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: "" }
            }
        }
    };

    filter = (list, search) => {
        let options = list;
        try {
            if (search && search !== "") {
                let result = fuzzysort.go(search, list.map((item) => { return item.name }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(list));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    calls = {
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = "top_nav"

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {

        let t = this.translate;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{ minHeight: "4rem" }} className={"h-16 bg-white shadow flex-col flex"}>
                <>
                    <div className="px-4 flex flex-1 items-center">
                        <div className="flex flex-row flex-1 justify-end">
                            {
                                window.location.pathname === "/client" &&
                                <div className='hidden md:block md:ml-12 lg:ml-0 text-sm md:w-68 cursor-pointer'>
                                    <DropdownTailwind
                                        options={this.state.time_spans.map((item) => {
                                            return { ...item, name: t(item.lang_key) }
                                        })
                                        }
                                        selected={this.renders.selectedTimeSpan()}
                                        small={true}
                                        onChange={async (value) => {
                                            this.functions.handleTimeSpanChange(value)
                                        }}
                                    />
                                </div>
                            }

                            {/* WELCOME MESSAGE */}
                            {
                                this.props.showUser &&
                                <div className="text-sm pr-2 justify-end items-center font-semibold flex flex-1">
                                    {t("welcome")} <span className={"pl-1"}>{this.state.user ? this.state.user.name : "user"}</span>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.showPaginaton &&
                                <div className="flex flex-row">
                                    <div className="flex flex-1 items-center justify-center mr-4">
                                        {
                                            this.state.total !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                {t("text_1")} <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> {t("text_2")} <span className="font-medium">{((this.state.page * this.state.limit) > this.state.total) ? this.state.total : this.state.page * this.state.limit}</span> {t("text_3")}{' '}
                                                <span className="font-medium">{this.state.total ? this.state.total : 0}</span> {t("text_4")}
                                            </p>
                                        }
                                    </div>
                                    {
                                        this.state.showPaginationSearch &&
                                        <div className="flex justify-center items-center mr-2">
                                            <div className="rounded-md border-1.5 flex flex-row">
                                                <div className="w-48 mr-2">
                                                    <input
                                                        type={"text"}
                                                        className={" bg-custom-input block font-normal w-full border-none pr-4 py-2 px-4 rounded-md rounded-r-none h-9 focus:outline-none text-sm"}
                                                        placeholder={t("search") + " ..."}
                                                        value={this.state.search}
                                                        onChange={async (event) => {
                                                            await this.promisedSetState({
                                                                search: event.target.value
                                                            });
                                                            if (this.props.onSearch) {
                                                                this.props.onSearch(this.state.search);
                                                            }
                                                        }}
                                                        onKeyDown={(value) => {

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="flex justify-center items-center mr-2">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1 && this.props.onPrevious) {
                                                    this.props.onPrevious(this.state.page > 1 ? (this.state.page - 1) : 1);
                                                }
                                            }}
                                            // className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}

                                            className={"inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}

                                            style={(this.state.page === 1 && this.state.whitelabel_settings.color_accent !== "" ?
                                                { opacity: 0.5, cursor: "not-allowed" } :
                                                { backgroundColor: "#" + this.state.whitelabel_settings.color_accent, cursor: "pointer" })}
                                        >
                                            <ArrowLeftIcon className="lg:mr-2 h-5 w-5"
                                                style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                            />
                                            <span className='hidden lg:block'
                                                style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                            >{t("button_1")}</span>
                                        </button>
                                    </div>
                                    {
                                        Array.isArray(this.state.filters) &&
                                        this.state.showFilters &&
                                        <div className="flex flex-row">
                                            {
                                                this.state.filters.map((filter) => {
                                                    return (
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    {filter.name}: {filter.selected ? filter.selected.name : "-"}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border max-h-40 absolute z-50 overflow-scroll mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1 pt-0">
                                                                        {
                                                                            <div className={" bg-gray-100 justify-center items-center overflow-hidden flex flex-row"}>
                                                                                <div className="px-3 text-gray-500 text-opacity-75">
                                                                                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </div>
                                                                                <input
                                                                                    id={"test"}
                                                                                    type={this.props.type}
                                                                                    disabled={this.state.disabled}
                                                                                    className={"bg-gray-100 block font-normal w-full border-none pr-4 py-2 h-12 focus:outline-none text-sm"}
                                                                                    placeholder={this.props.placeholder}
                                                                                    value={filter.search}
                                                                                    onChange={(event) => {
                                                                                        filter.search = event.target.value;
                                                                                        this.setState({
                                                                                            filters: this.state.filters
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            Array.isArray(filter.options) &&
                                                                            this.filter(filter.options, filter.search).map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={async () => {
                                                                                                    if (this.props.onFilter) {
                                                                                                        this.props.onFilter(item, filter);
                                                                                                    }
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    filter.selected && filter.selected.id == item.id ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item.name}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                        <div>
                                            <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                {t("page_limit")}: {this.state.limit}
                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="border absolute z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {
                                                        [5, 10, 20, 30, 50, 100, 500, 1000, 2000].map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={async () => {
                                                                                if (this.props.onLimit) {
                                                                                    this.props.onLimit(item);
                                                                                }
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                this.state.limit == item ? "text-purple-500" : ""
                                                                            )}
                                                                        >
                                                                            {item}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= +this.state.total) && this.props.onNext) {
                                                        this.props.onNext(this.state.page + 1);
                                                    }
                                                }}
                                                // className={(((this.state.page * this.state.limit) >= +this.state.total) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                className={"inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                style={((this.state.page * this.state.limit) >= +this.state.total) && this.state.whitelabel_settings.color_accent !== "" ?
                                                    { opacity: 0.5, cursor: "not-allowed" } :
                                                    { backgroundColor: "#" + this.state.whitelabel_settings.color_accent, cursor: "pointer" }}
                                            >
                                                <span className='hidden lg:block'
                                                    style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                >
                                                    {t("button_2")}
                                                </span>
                                                <ArrowRightIcon className="lg:ml-2 h-5 w-5"
                                                    style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </>
            </div>
        )
    }

}

export default ClientTopNavigation;