import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import {calls} from "../advertising-targeting-facebook/calls";
import Accordion from "../accordion";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";

class AdvertisingTargetingFacebookPlacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            creatives: {},
            channels: {},
            adset: {},
            positions: [
                {
                    title: 'All',
                    channels: {facebook: true, instagram: true},
                    key: 'all'
                },
                {
                    title: 'Feed',
                    channels: {facebook: true, instagram: true},
                    key: 'feed'
                },
                {
                    title: 'Story',
                    channels: {facebook: true, instagram: true},
                    key: 'story',
                    vertical: true
                },
                {
                    title: 'Right hand column',
                    channels: {facebook: true, instagram: false},
                    key: 'right_hand_column'
                },
                {
                    title: 'Instant article',
                    channels: {facebook: true, instagram: false},
                    key: 'instant_article'
                },
                {
                    title: 'Marketplace',
                    channels: {facebook: true, instagram: false},
                    key: 'marketplace'
                },
                {
                    title: 'Messenger Inbox',
                    channels: {facebook: true, instagram: false},
                    key: 'messenger_inbox'
                },
                {
                    title: 'Messenger Story',
                    channels: {facebook: true, instagram: false},
                    key: 'messenger_story',
                    vertical: true
                },
                {
                    title: 'Explore',
                    channels: {facebook: false, instagram: true},
                    key: 'explore'
                }
            ]
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting,
            creatives: nextProps.creatives,
            channels: nextProps.channels,
            adset: nextProps.adset
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting,
            creatives: this.props.creatives,
            channels: this.props.channels,
            adset: this.props.adset
        });
    }

    init = {};

    functions = {};

    renders = {
        filter: (item) => {
            if(this.state.targeting.placement && this.state.targeting.placement.all && item.key !== 'all'){
                return false
            }else{
                return true
            }
        }
    };

    render() {
        return (
            <div>
                {
                    this.state.targeting.placement &&
                    <div className="Section">
                        <div style={{width: '100%'}}>
                            <div className="WeekDaysTitle">
                                <div className={cx("InputContainerTitleText")}>
                                    Placement
                                </div>
                            </div>
                            <div className="WeekDaysItems">
                                <div style={{with: '100%'}}>
                                    {
                                        this.state.positions.filter((item) => {
                                            return this.renders.filter(item)
                                        }).map((item, index) => {
                                            return(
                                                <div>
                                                    <div className="WeekDaysItems-Item">
                                                        <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                            <div style={{marginRight: '3px'}}>{item.title}</div>
                                                            {
                                                                item.channels.facebook && this.state.channels.facebook && this.state.channels.instagram &&
                                                                <div style={{marginLeft: '3px'}}>
                                                                    <IconFacebook noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                item.channels.instagram && this.state.channels.instagram && this.state.channels.facebook &&
                                                                <div style={{marginLeft: '3px'}}>
                                                                    <IconInstagram noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                item.vertical &&
                                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical
                                                                    format
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="WeekDaysItems-ItemSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={this.state.targeting.placement[item.key]}
                                                                onChange={(checked) => {
                                                                    this.state.targeting.placement[item.key] = checked;
                                                                    this.setState({
                                                                        targeting: this.state.targeting
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        index !== (this.state.positions.filter((item) => {
                                                            return this.renders.filter(item)
                                                        }).length - 1) &&
                                                        <div className="WeekDaysItems-Item-Border"/>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingTargetingFacebookPlacement;
