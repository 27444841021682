import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ExclamationIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import phone_codes from '../assets/json/phone-countries.json';
import SingleDatepicker from '../modules/singleDatepicker';

class CreateBudgetPlanItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reference: "",
            date: null,
            budget: "",
            //max_budget: { id: 1, name: "Active", value: "active" },
            action: { id: 1, name: "Active", value: "active" },
            sub_action: { id: 2, name: "Spend pacing", value: "spend_pacing" },
            scheduleMonday: true,
            scheduleTuesday: true,
            scheduleWednesday: true,
            scheduleThursday: true,
            scheduleFriday: true,
            scheduleSaturday: true,
            scheduleSunday: true,
            order: {},
        };
    }

    async componentDidMount() {
        await this.promisedSetState({
            reference: this.props.crmId,
            order: this.props.order
        })
    }

    functions = {
        createItem: async () => {
            await this.promisedSetState({ loading: true });
            let item = {
                max_budget: this.state.max_budget,
                reference: this.state.reference,
                date: this.state.date && this.state.date.full_date,
                budget: this.state.budget,
                action: this.state.action,
                sub_action: this.state.sub_action
            };
            let changelog = {
                initial_data: { reference: "", date: "", budget: "", action: "", sub_action: "" },
                updated_data: {
                    max_budget: this.state.max_budget,
                    reference: this.state.reference,
                    date: this.state.date && this.state.date.full_date,
                    budget: this.state.budget,
                    action: this.state.action,
                    sub_action: this.state.sub_action
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "created",
                type: "budget_plan_item"
            }
            console.log("item", this.state)
            item.changelog = changelog;
            try {
                let response = await this.calls.createItem(item);
                this.props.onCreated(response.data);
            } catch (error) {
                console.log("error", error)
                this.setState({
                    error: error.body && error.body.message ? error.body.message : "An error occurred"
                });
                this.props.onError();
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        }
    };

    calls = {
        createItem: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createOrderBudgetPlanItem?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        }
    };

    renders = {}

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Reference ID (optional)"}
                                        value={this.state.reference}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                reference: value
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    false &&
                                    <div className={this.state.action.value === "active" ? "col-span-4" : "col-span-6"}>
                                        <DropdownTailwind
                                            label={"Max budget"}
                                            small={false}
                                            searchInput={true}
                                            selected={this.state.max_budget}
                                            options={[{ id: 1, name: "Active", value: "active" }, { id: 2, name: "Disabled", value: "disabled" }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    max_budget: value
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                <div className={this.state.action.value === "active" ? "col-span-6" : "col-span-12"}>
                                    <DropdownTailwind
                                        label={"Action"}
                                        small={false}
                                        searchInput={true}
                                        selected={this.state.action}
                                        options={[{ id: 1, name: "Active", value: "active" }, { id: 2, name: "Paused", value: "paused" }]}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                action: value
                                            });
                                        }}
                                    />
                                </div>
                                {
                                    this.state.action.value == "active" &&
                                    <div className={"col-span-6"}>
                                        <DropdownTailwind
                                            label={"Sub-action"}
                                            small={false}
                                            searchInput={true}
                                            selected={this.state.sub_action}
                                            options={[{ id: 2, name: "Spend pacing", value: "spend_pacing" }, { id: 1, name: "Spend total", value: "spend_total" }, { id: 1, name: "Spend rest", value: "spend_rest" }]}
                                            onChange={async (value) => {
                                                await this.promisedSetState({
                                                    sub_action: value
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.action.value == "active" &&
                                    <div className={"col-span-6"}>
                                        <InputTailwind
                                            label={"Budget"}
                                            value={this.state.budget}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    budget: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.action.value == "active" && this.state.budget && this.state.budget < this.state.order.totalSpendThisMonth && this.state.date && moment(this.state.date.full_date).format("YYYYMM") === moment().format("YYYYMM") &&
                                    <div className={"col-span-12 bg-red-100 text-red-500 rounded-md "}>
                                        <div className="p-4 font-medium text-sm inline-flex items-center">
                                            <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                            OBS! Total spend so far  <b className='mx-1'>{this.state.order.totalSpendThisMonth}</b> is higher than new budget.
                                        </div>
                                    </div>
                                }
                                {
                                    false &&
                                    this.state.action.value == "active" &&
                                    <div className={"col-span-6"}>
                                        <div className="flex justify-between flex-row ml-10 mr-10 pt-5">
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleMonday: !this.state.scheduleMonday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Mo</div>
                                                <div className={(this.state.scheduleMonday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleTuesday: !this.state.scheduleTuesday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Tu</div>
                                                <div className={(this.state.scheduleTuesday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleWednesday: !this.state.scheduleWednesday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">We</div>
                                                <div className={(this.state.scheduleWednesday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleThursday: !this.state.scheduleThursday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Th</div>
                                                <div className={(this.state.scheduleThursday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleFriday: !this.state.scheduleFriday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Fr</div>
                                                <div className={(this.state.scheduleFriday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleSaturday: !this.state.scheduleSaturday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Sa</div>
                                                <div className={(this.state.scheduleSaturday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                            <div onClick={() => {
                                                this.setState({
                                                    scheduleSunday: !this.state.scheduleSunday
                                                })
                                            }} className="flex flex-col cursor-pointer items-center justify-center">
                                                <div className="text-xxs font-medium mb-1">Su</div>
                                                <div className={(this.state.scheduleSunday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={"col-span-12"}>
                                    <div className="border-1.5 bg-custom-input pb-0 p-2 pt-0 rounded-md">
                                        <SingleDatepicker
                                            //showNames={true}
                                            minToday={true}
                                            minDateToday={true}
                                            singleDate={true}
                                            onChange={(date) => {
                                                console.log(date);
                                                this.setState({
                                                    date: date
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateBudgetPlanItem;