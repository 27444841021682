import React, {Component, Fragment} from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
    Circle,
    Polygon,
    FaAnchor
} from "react-google-maps";
import * as turf from '@turf/turf'

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

class AdvertisingLocationMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            center: this.props.center,
            zoom: this.props.zoom,
            places: [],
            place: {},
            negative_places: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            center: this.state.center !== nextProps.center ? nextProps.center : this.state.center,
            zoom: this.state.zoom !== nextProps.zoom ? nextProps.zoom : this.state.zoom,
            places: nextProps.places,
            place: nextProps.place
        }, () => {
            this.functions.init();
        });
    }

    componentDidMount() {
        this.setState({
            center: this.props.center,
            zoom: this.props.zoom,
            place: this.props.place,
            places: this.props.places
        }, () => {
            this.functions.init();
        });
    }

    functions = {
        init: () => {
            if (this.state.place.name) {
                if (this.state.place.custom) {
                    var circle = new window.google.maps.Circle({
                        center: new window.google.maps.LatLng(this.state.place.lat, this.state.place.lng),
                        radius: this.state.place.radius
                    });
                    var bounds = new window.google.maps.LatLngBounds();
                    bounds.union(circle.getBounds());
                    this.map.fitBounds(bounds);
                } else {
                    //console.log("hek");
                    var bounds = new window.google.maps.LatLngBounds();
                    window.google.maps.Polygon.prototype.getBounds = function () {
                        var bounds = new window.google.maps.LatLngBounds();
                        var paths = this.getPaths();
                        for (var i = 0; i < paths.getLength(); i++) {
                            var path = paths.getAt(i);
                            for (var j = 0; j < path.getLength(); j++) {
                                bounds.extend(path.getAt(j));
                            }
                        }
                        return bounds;
                    };
                    if(this.state.place.paths){
                        for (let i = 0; i < this.state.place.paths.length; i++) {
                            var polygon = new window.google.maps.Polygon({
                                paths: this.state.place.paths[i]
                            });
                            bounds.union(polygon.getBounds());
                        }
                        this.map.fitBounds(bounds);
                    }else if(this.state.place.lat && this.state.place.lng){
                        //console.log(this.state.place.lat, this.state.place.lng);
                        let center = new window.google.maps.LatLng(this.state.place.lat, this.state.place.lng);
                        //console.log(center);
                        this.map.fitBounds(center);
                    }
                }
            }
        }
    };

    renders = {
        center: () => {
            try {
                let place = this.state.places.filter((item) => {
                    return item.hover
                })[0];
                let array = [];
                place.paths.map((item) => {
                    let inner_array = [];
                    item.map((inner_item) => {
                        let coordinates_array = [inner_item.lat, inner_item.lng];
                        inner_array.push(coordinates_array);
                    });
                    array.push(inner_array);
                });
                var polygon = turf.polygon(array);
                var center = turf.centerOfMass(polygon);
                return {lat: center.geometry.coordinates[0], lng: center.geometry.coordinates[1]};
            } catch (e) {
                //console.log(e);
                return null
            }
        }
    };

    render() {
        const defaultMapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };
        return (
            <GoogleMap
                defaultOptions={defaultMapOptions}
                ref={(ref) => {
                    this.map = ref;
                }}
            >
                {
                    this.state.places.map((place) => {
                        if (place.custom) {
                            return (
                                <Fragment key={place.id}>
                                    <Circle
                                        zIndex={1}
                                        defaultCenter={{
                                            lat: parseFloat(place.lat),
                                            lng: parseFloat(place.lng)
                                        }}
                                        radius={place.radius}
                                        options={{
                                            strokeWeight: "2",
                                            strokeColor: this.state.places.filter((item) => {return item.relative_location === place.id}).length < 1 ? "#0185fe" : "#FC5353",
                                            fillColor: this.state.places.filter((item) => {return item.relative_location === place.id}).length < 1 ? "#0185fe" : "#FC5353"
                                        }}
                                    />
                                </Fragment>
                            )
                        } else if (place.negative) {
                            return (
                                <Fragment key={place.id}>
                                    <Polygon
                                        paths={place.paths}
                                        options={{
                                            zIndex: 100,
                                            strokeWeight: "2",
                                            strokeColor: "#FC5353",
                                            fillColor: '#FC5353',
                                            fillOpacity: '0.1'
                                        }}/>
                                </Fragment>
                            )
                        } else {
                            return (
                                <Fragment key={place.id}>
                                    <Polygon
                                        onMouseOver={() => {
                                            place.hover = true;
                                            this.setState({
                                                places: this.state.places
                                            })
                                        }}
                                        onMouseOut={() => {
                                            place.hover = false;
                                            this.setState({
                                                places: this.state.places
                                            })
                                        }}
                                        onClick={() => {

                                        }}
                                        paths={place.paths}
                                        options={{
                                            zIndex: 1,
                                            strokeWeight: "2",
                                            fillOpacity: place.hover?'0.8':'0.4',
                                            strokeColor: place.include?"#0185fe":"#FC5353",
                                            fillColor: place.include?"#0185fe":"#FC5353"
                                        }}/>
                                </Fragment>
                            )
                        }
                    })}
            </GoogleMap>
        );
    }

}

export default withGoogleMap(AdvertisingLocationMap);