import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline'

class ToolTip extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    };

    componentDidMount() {
        this.setState({
            text: this.props.text,
            show: this.props.show,
            disabled: this.props.disabled,
            top: this.props.top,
            start: this.props.start ? this.props.start : false
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            text: nextProps.text,
            error: nextProps.error,
            show: nextProps.show,
            top: nextProps.top
        })
    }

    render() {
        return (
            <div style={this.state.top ? { top: this.state.top } : { bottom: "45px" }} className={(this.state.show ? "opacity-100" : "opacity-0") + " duration-75 items-start flex flex-col justify-center transition-opacity absolute z-60"}>
                <div className={(this.state.start ? "": "rounded-md ") + "whitespace-no-wrap font-medium bg-purple-900 text-white px-4 py-2 text-xs"} style={this.state.start ? {borderRadius: "0.375rem 0.375rem 0.375rem 0rem"} : {}}>
                    {this.props.children}
                </div>
                <div style={{ width: "0", height: "0", borderLeft: "15px solid transparent", borderRight: "15px solid transparent", borderTop: "15px solid #060534" }}></div>
            </div>
        )
    }
}

export default ToolTip;
