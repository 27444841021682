
class performanceMax {
    static validate(ad) {
        let error = {
            square: false,
            logo_square: false,
            landscape: false,
            headlines: false,
            descriptions: false,
            long_headlines: false,
            business: false,
            name: false,
            link: false
        };
        if (!Array.isArray(ad.images) || (Array.isArray(ad.images) && ad.images.length === 0)) {
            error.square = true;
            error.logo_square = true;
            error.landscape = true;
        } else {
            if (Array.isArray(ad.images) && ad.images.filter((item) => { return item.type === "square" && !item.error}).length === 0) {
                error.square = true;
            }
            if (Array.isArray(ad.images) && ad.images.filter((item) => { return item.type === "logo_square" && !item.error}).length === 0) {
                error.logo_square = true;
            }
            if (Array.isArray(ad.images) && ad.images.filter((item) => { return item.type === "landscape" && !item.error }).length === 0) {
                error.landscape = true;
            }
        }

        if (!Array.isArray(ad.headlines) || (Array.isArray(ad.headlines) && ad.headlines.length === 0)) {
            error.headlines = true;
        } else {
            if (Array.isArray(ad.headlines) && ad.headlines.filter((item) => { return item.text !== "" }).length < 3) {
                error.headlines = true;
            }
        }

        if (!Array.isArray(ad.bodies) || (Array.isArray(ad.bodies) && ad.bodies.length === 0)) {
            error.descriptions = true;
        } else {
            if (Array.isArray(ad.bodies) && ad.bodies.filter((item) => { return item.text !== "" }).length < 2) {
                error.descriptions = true;
            }
        }

        if (!Array.isArray(ad.long_headlines) || (Array.isArray(ad.long_headlines) && ad.long_headlines.length === 0)) {
            error.long_headlines = true;
        } else {
            if (Array.isArray(ad.long_headlines) && ad.long_headlines.filter((item) => { return item.text !== "" }).length < 1) {
                error.long_headlines = true;
            }
        }

        if (!ad.business || ad.business === "") {
            error.business = true;
        }

        if (!ad.name || ad.name === "") {
            error.name = true;
        }

        if (!ad.link || ad.link === "") {
            error.link = true;
        }
        return error;
    }
}

export { performanceMax }