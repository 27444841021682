import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import AdminOverview from './adminOverview';
import SideNavigationAdmin from '../moduleFiles/sideNavigationAdmin';
import AgencyListNew from './agencyListNew';
import UserAgenciesNew from '../pages/user-agencies-new';
import AgencyEdit from '../pages/agencies/edit';
import AgentListNew from './agentListNew';
import AdminList from './adminList';
import LogList from './logList';
import DefaultMetricList from './defaultMetricsList';
import CustomPlatformsList from './customPlatformsList';
import Runcron from './runCron';
import AdminCampaignList from './adminCampaignList';
import ClientList from './clientList';
import AdminAccountsList from './adminAccountsList';
import JobList from './jobList';
import JobController from './jobController';

class LoggedInAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    functions = {};

    renders = {};

    render() {
        return (
            <>
                <div className="min-h-full h-full">
                    <div className="min-h-full h-full">
                        <div className="min-h-full h-full flex flex-row">
                            <div className="">
                                <SideNavigationAdmin ref={"navigation"} history={this.props.history} />
                            </div>
                            <div className="flex flex-1 flex-col relative overflow-x-hidden">
                                <div className="flex flex-1 overflow-y-scroll">
                                    <div className="w-full relative h-full flex flex-col">
                                        <Switch>
                                            <Route exact path="/admin/dashboard">
                                                <AdminOverview history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/agencies">
                                                <AgencyListNew history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/agencies/new">
                                                <UserAgenciesNew history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/agencies/:id" component={AgencyEdit} />
                                            <Route exact path="/admin/agents">
                                                <AgentListNew history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/users">
                                                <AdminList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/logs">
                                                <LogList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/jobs">
                                                <JobList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/controllers">
                                                <JobController history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/metrics">
                                                <DefaultMetricList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/custom-platforms">
                                                <CustomPlatformsList admin={true} history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/cron">
                                                <Runcron history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/clients">
                                                <ClientList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/accounts">
                                                <AdminAccountsList history={this.props.history} />
                                            </Route>
                                            <Route exact path="/admin/campaigns">
                                                <AdminCampaignList history={this.props.history} />
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LoggedInAdmin;
