import React, { Component, Fragment } from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";
import UploadImage from "../image-upload";
import AccordionChannels from "../accordion-channels";
import ClientFacebook from "../client-facebook";
import ClientInstagram from "../client-instagram";
import ClientGoogle from "../client-google";
import ClientBing from "../client-bing";
import ClientUsers from "../client-users";
import AccordionStatic from "../accordion-static";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { calls } from "./calls";
import Loader from "../loader";
import IconLinkedin from "../icons/linkedin";
import ClientLinkedin from "../client-linkedin";
import IconTwitter from "../icons/twitter";
import ClientTwitter from "../client-twitter";
import ClientGoogleAnalytics from "../client-google-analytics";
import IconGoogleAnalytics from "../icons/google-analytics";
import IconAdform from "../icons/adform";
import ClientAdform from "../client-adform";
import ClientGoogleShopping from "../client-google-shopping";
import IconGoogleShopping from "../icons/google-shopping";
import IconBidtheatre from '../icons/bidtheatre';
import ClientBidtheatre from '../client-bidtheatre';
import IconTikTok from '../icons/tiktok';
import ClientTikTok from '../client-tiktok';
import { apiRegister } from '../../services/apiRegister';
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';
import { validateBBox } from '@turf/helpers';
import TopNavigationAgent from '../../moduleFiles/topNavigationAgent';
import InputTailwind from '../../moduleFiles/inputTailwind';
import formated_timezones from '../../assets/json/formated_timezones.json';

class ClientOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            timezones: formated_timezones,
            client: {},
            loading_title: "",
            loading: true,
            loading_update: false,
            view: null,
            channel: "facebook",

            search: "",
            limit: 10,
            page: 1,
            sort: "name",
            direction: "descending",
            throttling: {},
            agents: [],
            total: 0
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            loading: nextProps.loading,
            view: nextProps.view
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            client: this.props.client,
            loading: this.props.loading,
            view: this.props.view
        });
        this.functions.user();
    }

    init = {};

    functions = {
        update: () => {
            if (!this.state.loading_update) {
                this.setState({
                    loading_update: true,
                    warning_modal: false,
                    loading_title: "Updating client"
                }, () => {
                    let client = {
                        name: this.state.client.name,
                        company: this.state.client.company,
                        email: this.state.client.email,
                        internal_id: this.state.client.internal_id,
                        org_number: this.state.client.org_number,
                        phone: this.state.client.phone,
                        timezone: this.state.client.timezone
                    };
                    if (this.state.user.manager) {
                        client.agents = Array.isArray(this.state.client.agents) ? this.state.client.agents : [];
                    }
                    calls.updateClient(client, this.state.client.id).then((response) => {
                        this.setState({
                            loading_update: false,
                            edit: false,
                            item: response.data
                        })
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            error: error.body.name,
                            edit: false,
                            loading_update: false
                        })
                    });
                })
            }
        },
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
            console.log(this.state.user);
            if (this.state.user.manager) {
                this.functions.getAgents(true);
            }
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    renders = {};

    calls = {
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div style={{ width: '100%' }} className="">
                {
                    this.state.loading &&
                    <Loader
                        title={this.state.loading_title}
                    />
                }
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div className='flex flex-row'>
                    <div className='flex flex-2 bg-white rounded-md mr-2 p-4 mb-4'>
                        <div className="grid grid-cols-6 gap-4 w-full">
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Name"}
                                    error={this.state.client.name === "" && this.state.error}
                                    errorMessage={"Name is not valid"}
                                    value={this.state.client.name}
                                    onChange={(value) => {
                                        this.state.client.name = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Company"}
                                    error={this.state.client.name === "" && this.state.error}
                                    errorMessage={"Company is not valid"}
                                    value={this.state.client.company}
                                    onChange={(value) => {
                                        this.state.client.company = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Internal ID"}
                                    value={this.state.client.internal_id}
                                    onChange={(value) => {
                                        this.state.client.internal_id = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Org number"}
                                    value={this.state.client.org_number}
                                    onChange={(value) => {
                                        this.state.client.org_number = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Email"}
                                    value={this.state.client.email}
                                    onChange={(value) => {
                                        this.state.client.email = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6 md:col-span-3">
                                <InputTailwind
                                    label={"Phone"}
                                    value={this.state.client.phone}
                                    onChange={(value) => {
                                        this.state.client.phone = value;
                                        this.setState({ client: this.state.client })
                                    }}
                                />
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Timezone"}
                                    small={false}
                                    skipInternalSearch={false}
                                    searchInput={true}
                                    placeholder={"..."}
                                    selected={this.state.client.timezone ? { name: this.state.client.timezone, value: this.state.client.timezone } : { id: 0, name: "Select timezone" }}
                                    options={this.state.timezones.map((item) => {
                                        return item.utc.map((item) => {
                                            return { name: item, value: item };
                                        });
                                    }).flat()}
                                    onChange={async (option) => {
                                        this.state.client.timezone = option.value;
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    }}
                                    onPagination={async () => {

                                    }}
                                    onSearch={async (value) => {

                                    }}
                                />
                            </div>
                            {
                                !this.state.client.timezone &&
                                <div className="col-span-6 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100">
                                    OBS! Important to set Client timezone, system default is Europe/Stockholm.
                                </div>
                            }
                            {
                                this.state.user.manager &&
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Agents"}
                                        small={false}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_agents}
                                        loadingPagination={this.state.loading_agents_pagination}
                                        loadingSearch={this.state.loading_agents_search}
                                        total={this.state.total}
                                        searchInput={true}
                                        placeholder={"..."}
                                        pagination={this.state.total > (this.state.page * this.state.limit)}
                                        selected={{ id: 0, name: "Select agent" }}
                                        options={this.state.agents}
                                        onChange={async (value) => {
                                            if (!Array.isArray(this.state.client.agents)) {
                                                this.state.client.agents = [];
                                            }
                                            this.state.client.agents.push({
                                                email: value.name,
                                                id: value.id
                                            });
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_agents_pagination) {
                                                await this.promisedSetState({
                                                    page: this.state.page + 1
                                                });
                                                this.functions.getAgents(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: "",
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                if (this.state.throttling.current) {
                                                    //SKIP
                                                } else {
                                                    if (value && value === "") {
                                                        await this.promisedSetState({
                                                            search: value,
                                                            page: 1
                                                        });
                                                        this.functions.getAgents(false, false, true);
                                                    } else {
                                                        this.state.throttling.current = true;
                                                        await this.promisedSetState({
                                                            search: value,
                                                            throttling: this.state.throttling
                                                        });
                                                        setTimeout(async () => {
                                                            this.state.throttling.current = false;
                                                            await this.promisedSetState({
                                                                throttling: this.state.throttling
                                                            });
                                                            await this.promisedSetState({
                                                                page: 1
                                                            });
                                                            this.functions.getAgents(false, false, true);
                                                        }, 300);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {
                                this.state.user.manager &&
                                Array.isArray(this.state.client.agents) &&
                                <div className="col-span-6">
                                    {
                                        this.state.client.agents.map((item, index) => {
                                            return (
                                                <Fragment>
                                                    <div className={"bg-indigo-100 text-indigo-700 mb-2 flex flex-1 rounded-md items-center p-3 text-sm font-medium "}>
                                                        <div className="flex flex-1">{item.email}</div>
                                                        <button
                                                            onClick={() => {
                                                                this.state.client.agents = this.state.client.agents.filter((agent) => {
                                                                    return agent.id !== item.id;
                                                                });
                                                                this.setState({
                                                                    client: this.state.client
                                                                });
                                                            }}
                                                            type="button"
                                                            className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                        >
                                                            <span className="sr-only">Remove agent</span>
                                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div className="col-span-6">
                                <div>
                                    <button
                                        onClick={() => {
                                            if (!this.state.loading_update) {
                                                this.functions.update()
                                            }
                                        }}
                                        className={(!this.state.disabled ?
                                            "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                        }
                                    >
                                        Update client
                                        {
                                            this.state.loading_update &&
                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex bg-white rounded-md ml-2 p-4 mb-4'>
                        <div className="">
                            <UploadImage
                                title={"Upload logo (png)"}
                                size={"full"}
                                cover={false}
                                logo={true}
                                value={this.state.client.logo}
                                client={this.state.client.id}
                                onChange={(url) => {
                                    this.state.client.logo = url;
                                    this.setState({
                                        client: this.state.client
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-md px-4 py-4">
                    <div className="flex flex-row items-center space-x-2 flex-wrap">
                        {
                            [
                                "facebook",
                                'linkedin',
                                "google adwords",
                                //'google shopping',
                                "google analytics",
                                'twitter',
                                //'adform',
                                //'bidtheatre',
                                'tiktok',
                            ].map((key) => {
                                return (
                                    <div onClick={() => {
                                        this.setState({
                                            channel: key
                                        })
                                    }}>
                                        {key === 'instagram' &&
                                            <div className={`cursor-pointer transition-all duration-200 mb-2  hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-instagram-100 text-instagram-500 border-instagram-500' : ''}`}>
                                                <IconInstagram /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'facebook' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-facebook-100 text-facebook-500 border-facebook-500' : ''}`}>
                                                <IconFacebook /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'google adwords' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-google-100 text-google-500 border-google-500' : ''}`}>
                                                <IconGoogle /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'linkedin' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-linkedin-100 text-linkedin-500 border-linkedin-500' : ''}`}>
                                                <IconLinkedin /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'twitter' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-twitter-100 text-twitter-500 border-twitter-500' : ''}`}>
                                                <IconTwitter /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'google analytics' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-googleanalytics-100 text-googleanalytics-500 border-googleanalytics-500' : ''}`}>
                                                <IconGoogleAnalytics /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {
                                            key === 'google shopping' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-googleshopping-100 text-googleshopping-500 border-googleshopping-500' : ''}`}>
                                                <IconGoogleShopping /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'adform' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2  transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-adform-100 text-adform-500 border-adform-500' : ''}`}>
                                                <IconAdform /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'bidtheatre' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-bidtheatre-100 text-bidtheatre-500 border-bidtheatre-500' : ''}`}>
                                                <IconBidtheatre /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                        {key === 'tiktok' &&
                                            <div style={{ minWidth: "180px" }} className={`cursor-pointer mb-2 transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-black text-black border-black' : ''}`}>
                                                <IconTikTok /> <span className="ml-1">{key}</span>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className="flex flex-1"></div>
                    </div>
                    <div className="rounded-md bg-gray-100 px-4 pb-4 mt-4">
                        {
                            this.state.channel === "facebook" &&
                            <ClientFacebook
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                                submenu={this.state.submenu}
                            />
                        }
                        {
                            this.state.channel === "instagram" && false &&
                            <ClientInstagram
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "google adwords" &&
                            <ClientGoogle
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "linkedin" &&
                            <ClientLinkedin
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "twitter" &&
                            <ClientTwitter
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "google analytics" &&
                            <ClientGoogleAnalytics
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "google shopping" &&
                            <ClientGoogleShopping
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "adform" &&
                            <ClientAdform
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "bidtheatre" &&
                            <ClientBidtheatre
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                        {
                            this.state.channel === "tiktok" &&
                            <ClientTikTok
                                onUpdate={(item) => {
                                    this.setState({
                                        client: item
                                    })
                                }}
                                item={this.state.client}
                            />
                        }
                    </div>
                </div>
                {
                    !this.state.loading && false &&
                    <ClientUsers
                        client={this.state.client}
                    />
                }
            </div>
        )
    }
}

export default ClientOverview;
