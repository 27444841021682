import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import * as turf from '@turf/turf'
import AdvertisingLocationLanguage from "../advertising-location-language";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import {calls} from "./calls";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CountryCodes from "../../assets/json/countries";

class AdvertisingLocationPlatform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            options: [],
            loading: false,
            client: {}
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            channel: this.props.channel,
            disable: this.props.disable
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            channel: nextProps.channel,
            disable: nextProps.disable
        })
    }

    functions = {
        regions: (value) => {
            this.setState({
                search: value,
                loading: true
            }, () => {
                if(value !== ""){
                    calls.getLocations({
                        query: value,
                        channel: this.state.channel!=='google_shopping'?this.state.channel:'google',
                        client: this.state.client.id
                    }).then((response) => {
                        this.setState({
                            loading: false,
                            options: response.data
                        })
                    }, (error) => {
                        this.setState({
                            loading: false,
                            options: []
                        })
                    });
                }
            })
        },
        region: (item) => {
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let location = {
                id: id,
                channels: {},
                custom: false,
                negative: false,
                name: item.name,
                country_code: item.country_code,
                include: true
            };
            location.channels[item.channel] = item;
            try{
                this.props.onRegion(location);
            }catch (e) {}
        }
    };

    renders = {
        suffix: (item) => {
            let suffix = "";
            if(item.channel === "google" || item.channel === "google_shopping"){
                suffix = ", "+this.renders.countryName(item.country_code)+"("+item.type+")";
            }
            if(item.channel === "facebook"){
                let type = "";
                try{
                    type = item.type.replace("_", " ");
                }catch (e) {
                    type = item.type;
                }
                suffix = ", "+item.country_name+" ("+type+")";
            }
            return suffix;
        },
        countryName: (code) => {
            let name = "";
            try{
                CountryCodes.map((item) => {
                    if(item.code === code){
                        name = item.name;
                    }
                });
                if(name === ""){
                    name = code;
                }
            }catch (e) {
                name = code;
            }
            return name;
        }
    };

    render() {
        return (
            <Dropdown
                filter={true}
                searchFilter={true}
                searchList={false}
                search={true}
                title={this.props.title + (this.state.disable?" (disabled)":"")}
                size={'full'}
                removeArrow={this.state.disable}
                disabled={this.state.disable}
                icons={true}
                channels={[this.state.channel]}
                placeholder={!this.state.disable?"Search here ..":"Custom locations selected, remove to enable this input"}
                loading={this.state.loading}
                options={this.state.options.filter((item) => {
                    if(!this.state.search || this.state.search == ""){
                        return false
                    }else{
                        return true
                    }
                }).map((item) => {
                    item.suffix = this.renders.suffix(item);
                    return item
                })}
                value={this.state.search}
                onSearch={(value) => {
                    this.functions.regions(value);
                }}
                onChange={(value) => {
                    //console.log(value);
                    //TEMP
                    if(this.state.channel === 'google_shopping' && value.channel === 'google'){
                        value.channel = 'google_shopping';
                    }
                    this.functions.region(value);
                }}
            />
        )
    }
}

export default AdvertisingLocationPlatform;
