import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import {SlideDown} from "react-slidedown";
import AdvertisingCreatives from "../advertising-creatives";
import AdvertisingCreativesSearchAdsItem from "../advertising-creatives-search-ads-item";
import AdvertisingCreativesAdsItem from "../advertising-creatives-ads-item";

class AdvertisingCreativesSearchAds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            languages: [],
            edit: false,
            inner_edit: 'basic',
            selected_language: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            site: nextProps.site,
            languages: nextProps.languages
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives,
            site: this.props.site,
            languages: this.props.languages
        }, () => {
            if (this.state.creatives.search_ads === undefined || this.state.creatives.search_ads.length === 0) {
                this.init.textAds();
            }
        })
    }

    init = {
        textAds: () => {
            if(!this.state.creatives.search_ads){
                this.state.creatives.search_ads = [];
            }
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let ad = {
                id: id,
                title: "",
                languages: {},
                show: true,
                headline_first_template: {type: 'headline'},
                headline_second_template: {type: 'headline_second'},
                headline_third_template: {name: 'No third headline', type: 'no_option', bold: true}
            };
            for(let i = 0; i < this.state.languages.length; i++){
                ad.languages[this.state.languages[i].code] = {
                    headline: "",
                    headline_second: "",
                    headline_third: "",
                    body: "",
                    body_second: ""
                };
                if(this.state.creatives.languages[this.state.languages[i].code]['headline_third'] !== ""){
                    ad.headline_third_template = {type: 'headline_third'};
                }
            }
            this.state.creatives.search_ads.push(ad);
            id = Math.floor((Math.random() * 9999999999) + 1);
            ad = {
                id: id,
                title: "",
                languages: {},
                show: true,
                headline_first_template: {name: "Keyword", type: 'keyword_insert', bold: true},
                headline_second_template: {type: 'headline_second'},
                headline_third_template: {name: 'No third headline', type: 'no_option', bold: true}
            };
            for(let i = 0; i < this.state.languages.length; i++){
                ad.languages[this.state.languages[i].code] = {
                    headline: "",
                    headline_second: "",
                    headline_third: "",
                    body: "",
                    body_second: ""
                };
                if(this.state.creatives.languages[this.state.languages[i].code]['headline_third'] !== ""){
                    ad.headline_third_template = {type: 'headline_third'};
                }
            }
            this.state.creatives.search_ads.push(ad);
            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.props.onChange(this.state.creatives);
            })
        }
    };

    functions = {
        createSearchAd: (show) => {
            if(!this.state.creatives.search_ads){
                this.state.creatives.search_ads = [];
            }
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let ad = {
                id: id,
                title: "",
                languages: {},
                show: true,
                headline_first_template: {type: 'headline'},
                headline_second_template: {type: 'headline_second'},
                headline_third_template: {name: 'No third headline', type: 'no_option', bold: true}
            };
            for(let i = 0; i < this.state.languages.length; i++){
                ad.languages[this.state.languages[i].code] = {
                    headline: "",
                    headline_second: "",
                    headline_third: "",
                    body: "",
                    body_second: ""
                };
                if(this.state.creatives.languages[this.state.languages[i].code]['headline_third'] !== ""){
                    ad.headline_third_template = {type: 'headline_third'};
                }
            }
            this.state.creatives.search_ads.push(ad);
            this.setState({
                creatives: this.state.creatives
            }, () => {
                this.props.onChange(this.state.creatives);
            })
        }
    };

    validators = {};

    renders = {
        search: () => {
            try{
                return this.state.creatives.search_ads?this.state.creatives.search_ads:[]
            }catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <div className="Creative-search-ads">
                <div style={{width: '100%'}} className="Section-Inner">
                    <div className="Section-InnerTitle">
                        <div>
                            Text ads
                        </div>
                        {
                            <div className={cx("InputContainerTitleIcons")}>
                                <div style={{marginLeft: '3px'}}>
                                    <IconGoogle noMargin={true}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    <div className="Advertising-Creatives-ads-title">
                        <FeatherIcon color={"#333333"} size={25} icon="layout"/>
                        <div className="Advertising-Creatives-ads-title-text">
                            Create <span className="Advertising-Creatives-ads-title-text-bold">{this.renders.search().filter((item) => {
                            return !item.error
                        }).length}</span> Text ad
                            {
                                this.renders.search().filter((item) => {
                                    return !item.error
                                }).length > 1 &&
                                <span>s</span>
                            }
                        </div>
                        <div className="Advertising-Creatives-ads-title-slider">
                            <Switch
                                onColor="#24bda8"
                                checked={true}
                                disabled={true}
                                onChange={(checked) => {
                                    this.state.creatives.search_ads = this.state.creatives.search_ads.map((item) => {
                                        item.show = checked;
                                        return item;
                                    });
                                    this.setState({
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.props.onChange(this.state.creatives);
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    <SlideDown closed={false}>
                        {
                            this.renders.search().map((item) => {
                                return(
                                    <AdvertisingCreativesSearchAdsItem
                                        channels={this.state.channels}
                                        item={item}
                                        content={this.state.creatives.search_ads}
                                        creatives={this.state.creatives}
                                        languages={this.state.languages}
                                        site={this.state.site}
                                        onChange={(value) => {
                                            item = value;
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.props.onChange(this.state.creatives);
                                            })
                                        }}
                                        onRemove={(value) => {
                                            this.state.creatives.search_ads = value;
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.props.onChange(this.state.creatives);
                                            })
                                        }}
                                    />
                                )
                            })
                        }
                        {
                            this.renders.search().length < 10 &&
                            <div className="Creative-search-item-inner-ads-buttons">
                                <div onClick={() => {
                                    this.functions.createSearchAd(true)
                                }} className="Creative-search-item-inner-ads-button-add Button">
                                    Add&nbsp;<b>New</b>
                                </div>
                            </div>
                        }
                    </SlideDown>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesSearchAds;
