import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, BeakerIcon, StopIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import moment from 'moment';
import { random, round } from '@turf/turf';
import SyncWithBing from '../moduleFiles/syncWithBing';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import Loader from '../components/loader';
import InputTailwind from '../moduleFiles/inputTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';

class orderPerformance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order_scheduler: {},
            wizzard: "",
            edit_loader: false,
            loading: true,
            order: {},
            client: {},
            campaigns: [],
            campaign: { channel: 'all' },
            adgroups: [],
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getOrderBudgetPlaner();
    }

    functions = {
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        getOrderBudgetPlaner: async () => {
            return new Promise(async (resolve) => {
                try {
                    await this.promisedSetState({
                        loading: true,
                    });
                    let planer = false;
                    let response = await this.calls.getOrderBudgetPlaner();
                    /*
                    planer = JSON.parse(JSON.stringify(response.data.planer));
                    if (Object.keys(planer).filter((item) => { return !["total"].includes(item) }).map((key) => {
                        if (planer[key].campaigns.length < 1 && planer[key].adgroups.length < 1 && planer[key].sharedBudgets.length < 1) {
                            return false;
                        } else return true;
                    }).filter((item) => { return item }).length < 1) {
                        planer = false;
                    }
                    await this.promisedSetState({
                        order_planer: planer,
                        client: response.data.client,
                        order: response.data.order,
                    });
                    let campaigns_string = "";
                    let campaigns_array = [];
                    Object.keys(planer).filter((item) => { return !["total"].includes(item) }).map((key) => {
                        planer[key].campaigns.map((campaign) => {
                            if (!campaigns_array.includes(campaign.campaignId)) {
                                campaigns_array.push(campaign.campaignId);
                                campaigns_string = campaigns_string + ("&" + key + "_campaigns[]=" + campaign.campaignId);
                            }
                        });
                        planer[key].adgroups.map((adgroup) => {
                            if (!campaigns_array.includes(adgroup.campaignId)) {
                                campaigns_array.push(adgroup.campaignId);
                                campaigns_string = campaigns_string + ("&" + key + "_campaigns[]=" + adgroup.campaignId);
                            }
                        });

                    });

                    let adgroups = await this.calls.getAdgroups(campaigns_string);

                    for (let channel in adgroups.data) {
                        adgroups.data[channel].map((item) => {
                            if (this.state.order_planer[channel].adgroups.filter((inner_item) => { return inner_item.id == item.id }).length > 0) {
                                this.state.order_planer[channel].adgroups = this.state.order_planer[channel].adgroups.map((inner_item) => {
                                    if (inner_item.id == item.id) {
                                        inner_item.name = item.name;
                                    }
                                    return inner_item;
                                });
                            }
                        });
                    }

                    await this.promisedSetState({
                        order_planer: this.state.order_planer,
                        loading: false
                    });
                    */

                } catch (error) {
                    await this.promisedSetState({
                        error: true,
                        loading: false,
                    });
                }
                resolve();
            })
        },
        updateBudgetSchedule: async (item, level, daily = false, shared = false, channel = false) => {
            return new Promise(async (resolve) => {
                try {
                    await this.promisedSetState({
                        loading_save: true
                    });
                    if (!shared) {
                        let body = {
                            budgetplaner_activated: item.budgetPlanerActivated,
                            schedule_activated: item.scheduleActivated,
                            schedule_monday: item.scheduleMonday,
                            schedule_tuesday: item.scheduleTuesday,
                            schedule_wednesday: item.scheduleWednesday,
                            schedule_thursday: item.scheduleThursday,
                            schedule_friday: item.scheduleFriday,
                            schedule_saturday: item.scheduleSaturday,
                            schedule_sunday: item.scheduleSunday,
                            budgetplaner_total: this.state.update[item.id].budget,
                            schedule_start_date: moment().format('YYYY-MM-DD')
                        }
                        if (level === "campaign") {
                            let resp = await this.calls.updateCampaign(body, item.id);
                            Object.keys(this.state.order_planer).map((channel) => {
                                this.state.order_planer[channel].campaigns = this.state.order_planer[channel].campaigns.map((campaign) => {
                                    if (campaign.id === item.id) {
                                        campaign.budgetPlanerTotal = this.state.update[item.id].budget;
                                        campaign.scheduleStartDate = moment().format('YYYY-MM-DD');
                                    }
                                    return campaign;
                                });

                            })
                        } else if (level === "adgroup") {
                            let resp = await this.calls.updateAdgroup(body, item.id, item.internal_camapign_id);
                            Object.keys(this.state.order_planer).map((channel) => {
                                this.state.order_planer[channel].adgroups = this.state.order_planer[channel].adgroups.map((adgroup) => {
                                    if (adgroup.id === item.id) {
                                        adgroup.budgetPlanerTotal = this.state.update[item.id].budget;
                                        adgroup.scheduleStartDate = moment().format('YYYY-MM-DD');
                                    }
                                    return adgroup;
                                });

                            })
                        }

                        delete this.state.update[item.id];
                        await this.promisedSetState({
                            update: this.state.update,
                            order_planer: this.state.order_planer,
                            loading_save: false
                        });
                    } else {
                        if (channel === "google") {

                            await this.calls.updateGoogleBudget({
                                operations: [{
                                    updateMask: "amount_micros",
                                    update: {
                                        resourceName: item.resourceName,
                                        amountMicros: parseInt(daily) * 1000000
                                    }
                                }]
                            });

                            this.state.order.googleBudgets = this.state.order.googleBudgets.map((budget) => {
                                if (budget.id === item.id) {
                                    budget.budgetPlanerTotal = this.state.update[item.id].budget;
                                    budget.scheduleStartDate = moment().format('YYYY-MM-DD');
                                }
                                return budget;
                            })

                            let resp = await this.calls.updateOrder({ googleBudgets: this.state.order.googleBudgets });

                            if (resp.status === 200) {
                                await this.promisedSetState({
                                    order: this.state.order
                                });
                            }
                        } else if (channel === "bing") {
                            await this.calls.updateBingBudget({ amount: daily, id: item.id });
                            this.state.order.bingBudgets = this.state.order.bingBudgets.map((budget) => {
                                if (budget.id === item.id) {
                                    budget.budgetPlanerTotal = this.state.update[item.id].budget;
                                    budget.scheduleStartDate = moment().format('YYYY-MM-DD');
                                }
                                return budget;
                            })

                            let resp = await this.calls.updateOrder({ bingBudgets: this.state.order.bingBudgets });

                            if (resp.status === 200) {
                                await this.promisedSetState({
                                    order: this.state.order
                                });
                            }
                        }
                        this.state.order_planer[channel].sharedBudgets = this.state.order_planer[channel].sharedBudgets.map((campaign) => {
                            if (campaign.id === item.id) {
                                campaign.budgetPlanerTotal = this.state.update[item.id].budget;
                                campaign.scheduleStartDate = moment().format('YYYY-MM-DD');
                            }
                            return campaign;
                        });
                        delete this.state.update[item.id];
                        await this.promisedSetState({
                            update: this.state.update,
                            order_planer: this.state.order_planer,
                            loading_save: false
                        });
                    }
                } catch (error) {
                    await this.promisedSetState({
                        loading_save: false
                    });
                }
                resolve();
            });
        },
        updateBudgetPlanerStatus: async (level, item, status, shared = false) => {
            return new Promise(async (resolve) => {
                try {
                    if (!shared) {
                        let body = {
                            budgetplaner_activated: status,
                            schedule_activated: status,
                            schedule_monday: item.scheduleMonday,
                            schedule_tuesday: item.scheduleTuesday,
                            schedule_wednesday: item.scheduleWednesday,
                            schedule_thursday: item.scheduleThursday,
                            schedule_friday: item.scheduleFriday,
                            schedule_saturday: item.scheduleSaturday,
                            schedule_sunday: item.scheduleSunday,
                            budgetplaner_total: item.budgetPlanerTotal,
                            schedule_start_date: item.scheduleStartDate
                        }
                        if (level === "campaign") {
                            let resp = await this.calls.updateCampaign(body, item.id);
                        } else if (level === "adgroup") {
                            let resp = await this.calls.updateAdgroup(body, item.id, item.internal_camapign_id);

                        }
                    } else {
                        if (level === "google") {
                            this.state.order.googleBudgets = this.state.order.googleBudgets.map((budget) => {
                                if (budget.id === item.id) {
                                    budget.budgetPlanerActivated = status;
                                    budget.scheduleActivated = status;
                                }
                                return budget;
                            })
                            let resp = await this.calls.updateOrder({ googleBudgets: this.state.order.googleBudgets });
                            if (resp.status === 200) {
                                await this.promisedSetState({
                                    order: this.state.order
                                });
                            }
                        } else if (level === "bing") {
                            this.state.order.bingBudgets = this.state.order.bingBudgets.map((budget) => {
                                if (budget.id === item.id) {
                                    budget.budgetPlanerActivated = status;
                                    budget.scheduleActivated = status;
                                }
                                return budget;
                            })
                            let resp = await this.calls.updateOrder({ bingBudgets: this.state.order.bingBudgets });
                            if (resp.status === 200) {
                                await this.promisedSetState({
                                    order: this.state.order
                                });
                            }
                        }
                    }

                } catch (error) {

                }
                resolve();
            })
        },
        getCampaigns: async () => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading: true,
                    });
                    let response = await this.calls.getCampaigns();
                    let channels = [{ id: "all", name: "All channels", value: "all" }];
                    response.data.campaigns.map((item) => {
                        if (channels.filter((channel) => { return channel.value === item.channel }).length < 1) {
                            channels.push({ id: item.channel, name: item.channel.charAt(0).toUpperCase() + item.channel.slice(1), value: item.channel });
                        }
                    });
                    //response.data.campaigns = response.data.campaigns.concat(response.data.campaigns);
                    //console.log(response.data.campaigns, "CAMPAIGNS");
                    //response.data.order.budgetSplit = {
                    //budget: 1500,
                    //};
                    await this.promisedSetState({
                        campaigns: response.data.campaigns,
                        client: response.data.client,
                        order: response.data.order,
                        channels: channels,
                        loading: false
                    });


                    //console.log(this.state.campaigns.map((campaign) => { return campaign.channel }).filter((value, index, array) => { return array.indexOf(value) === index }), "CAMPAIGNS FILTER");
                    if (response.data.order.budgetSplit && response.data.order.budgetSplit.budget && response.data.order.budgetSplit.budget > 0) {
                        await this.functions.calculation(this.state.campaigns);
                        //this.functions.getCampaignsData();
                    } else {
                        await this.promisedSetState({
                            //error_budget: true
                        });
                    }
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                }
                resolve();
            })
        },
        getAdgroups: async (campaign = false) => {
            try {
                this.promisedSetState({
                    loading_partial: true
                });
                let campaigns_string = "";
                if (!campaign) {
                    campaigns_string = campaigns_string + ("&" + this.state.campaign.channel + "_campaigns[]=" + this.state.campaign.campaignId);
                } else {
                    campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.campaignId);
                }
                let response = await this.calls.getAdgroups(campaigns_string);
                let adgroups = [];
                for (let channel in response.data) {
                    let buffer = {};
                    if (!campaign) {
                        buffer = this.state.campaign;
                    } else {
                        buffer = campaign;
                    }

                    if (buffer.channel === channel) {
                        response.data[channel] = response.data[channel].map((item) => {
                            item.internal_campaign = buffer.internal;
                            if (buffer.scheduleAdgroups) {
                                if (item.id in buffer.scheduleAdgroups) {
                                    item = { ...item, ...buffer.scheduleAdgroups[item.id] };
                                }
                            }
                            return item;
                        });
                    }
                    adgroups = adgroups.concat(response.data[channel]);
                }
                await this.promisedSetState({
                    adgroups: adgroups,
                    loading_partial: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        }
    }

    calls = {
        getOrderBudgetPlaner: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderBudgetPlaner?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        getCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderCampaigns?id=" + this.functions.getIdFromPath() + "&information=true";
            return apiRegister.call(options, url);
        },
        updateCampaign: (data, campaign) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCampaignSchedule?id=" + campaign;
            return apiRegister.call(options, url);
        },
        updateAdgroup: (data, adgroup, campaign) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupSchedule?id=" + adgroup + "&campaign=" + campaign;
            return apiRegister.call(options, url);
        },
        getAdgroups: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "client=" + this.state.client.id + "&information=true" + campaigns;
            return apiRegister.call(options, url);
        },
        updateOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        updateGoogleBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateCampaignBudget?client=" + (this.state.client ? this.state.client.id : "undefined");
            return apiRegister.call(options, url);
        },
        updateBingBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateBingBudget?client=" + (this.state.client ? this.state.client.id : "undefined");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        daily: (item, total = false, start_date = false) => {
            try {
                let data = {};
                let budget = 0;
                if (!item) {
                    data = {
                        start_date: this.state.update && this.state.update[item.id] ? moment().format('YYYY-MM-DD') : start_date,
                        weekdays: {
                            scheduleMonday: true,
                            scheduleTuesday: true,
                            scheduleWednesday: true,
                            scheduleThursday: true,
                            scheduleFriday: true,
                            scheduleSaturday: true,
                            scheduleSunday: true,
                        }
                    }
                    budget = +total;
                } else {
                    data = {
                        start_date: this.state.update && this.state.update[item.id] ? moment().format('YYYY-MM-DD') : (item.scheduleStartDate ? item.scheduleStartDate : moment().startOf('month').format('YYYY-MM-DD')),
                        weekdays: {
                            scheduleMonday: item.scheduleMonday,
                            scheduleTuesday: item.scheduleTuesday,
                            scheduleWednesday: item.scheduleWednesday,
                            scheduleThursday: item.scheduleThursday,
                            scheduleFriday: item.scheduleFriday,
                            scheduleSaturday: item.scheduleSaturday,
                            scheduleSunday: item.scheduleSunday,
                        }
                    }
                    budget = this.state.update && this.state.update[item.id] ? this.state.update[item.id].budget : item.budgetPlanerTotal
                }
                if (budgetPlannerGraph.main(data, budget).datasets[0].data[0] !== Infinity) {
                    return +budgetPlannerGraph.main(data, budget).datasets[0].data[0];
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {

                        }}
                        showDateSpan={false}
                        hideUserDropdown={true}
                        breadcrumb={""}
                        history={this.props.history}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }
                {
                    !this.state.loading && !this.state.order_planer &&
                    <div className='p-4 flex flex-1 flex-col max-h-screen items-center justify-items-center'>
                        <span className='text-gray-600'>No Budget planer found in Order</span>
                    </div>
                }
                {
                    !this.state.loading && this.state.order_planer &&
                    Object.keys(this.state.order_planer).filter((item) => { return this.state.order_planer[item].campaigns.length > 0 || this.state.order_planer[item].adgroups.length > 0 || this.state.order_planer[item].sharedBudgets.length > 0 }).map((item) => {
                        return (
                            <div className='p-4 flex flex-col'>
                                <div className='bg-white rounded-lg shadow p-2 w-full'>
                                    <div className='flex flex-row justify-between my-3'>
                                        <div className='flex flex-1 text-2xl ml-5 text items-center'>
                                            {item.slice(0, 1).toLocaleUpperCase() + item.slice(1)}
                                        </div>
                                    </div>
                                    {
                                        (this.state.order_planer[item].campaigns.length > 0 || this.state.order_planer[item].sharedBudgets.length > 0) &&
                                        <>
                                            <div className='flex border-t border-b my-1'>
                                                <div className='ml-5 text-base py-2'>
                                                    Campaigns
                                                </div>
                                            </div>
                                            <div className='flex flex-col'>
                                                {
                                                    (item === "google" || item === "bing") &&
                                                    this.state.order_planer[item].sharedBudgets.length > 0 &&
                                                    this.state.order_planer[item].sharedBudgets.map((budget) => {
                                                        return (
                                                            <>
                                                                <div className='flex flex-row justify-between mt-2'>
                                                                    <div className='flex flex-1 text-xs ml-5 items-center'>
                                                                        {budget.name}
                                                                    </div>
                                                                    <div className='flex mx-2 '>
                                                                        {
                                                                            [{ name: "Mon", value: "scheduleMonday" }, { name: "Tue", value: "scheduleTuesday" }, { name: "Wed", value: "scheduleWednesday" }, { name: "Thu", value: "scheduleThursday" }, { name: "Fri", value: "scheduleFriday" }, { name: "Sat", value: "scheduleSaturday" }, { name: "Sun", value: "scheduleSunday" }].map((day) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className='flex flex-col mr-2 items-center min-w-7'>
                                                                                            <div className='text-xs'>{day.name}</div>
                                                                                            <div className={(true ? "bg-purple-500 " : "bg-gray-500 ") + 'w-3 h-12 rounded-md'}></div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className='flex flex-col mx-2 w-48'>
                                                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                            <div className="flex flex-1 ml-5 min-w-20 ">
                                                                                {budget.budgetPlanerActivated ? "Active" : "Disabled"}
                                                                            </div>
                                                                            <div className="relative overflow-hidden mr-5">
                                                                                <SwitchTailwind
                                                                                    value={budget.budgetPlanerActivated}
                                                                                    onSwitch={async () => {
                                                                                        budget.budgetPlanerActivated = !budget.budgetPlanerActivated;
                                                                                        await this.promisedSetState({
                                                                                            order_planer: this.state.order_planer
                                                                                        });
                                                                                        this.functions.updateBudgetPlanerStatus(item, budget, budget.budgetPlanerActivated, true);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex mr-2 w-24'>
                                                                        <InputTailwind
                                                                            value={this.renders.daily(false, this.state.update && this.state.update[budget.id] ? this.state.update[budget.id].budget : budget.budgetPlanerTotal, this.state.update && this.state.update[budget.id] ? moment().format("YYYY-MM-DD") : budget.scheduleStartDate)}
                                                                            label={"Daily budget"}
                                                                            locked={true}
                                                                            onChange={async (value) => {

                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='flex w-32 '>
                                                                        <InputTailwind
                                                                            value={this.state.update && this.state.update[budget.id] ? this.state.update[budget.id].budget : budget.budgetPlanerTotal}
                                                                            type={"number"}
                                                                            leftSection={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? true : false)}
                                                                            leftSectionLabel={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? this.state.client.currency[item] : "")}
                                                                            label={"Total"}
                                                                            onChange={async (value) => {
                                                                                if (!this.state.update) {
                                                                                    this.state.update = {};
                                                                                }
                                                                                if (!this.state.update[budget.id]) {
                                                                                    this.state.update[budget.id] = {};
                                                                                }
                                                                                this.state.update[budget.id].budget = value;
                                                                                await this.promisedSetState({
                                                                                    update: this.state.update
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='flex ml-2 items-center'>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.functions.updateBudgetSchedule(budget, "campaign", this.renders.daily(false, this.state.update && this.state.update[budget.id] ? this.state.update[budget.id].budget : budget.budgetPlanerTotal, this.state.update && this.state.update[budget.id] ? moment().format("YYYY-MM-DD") : budget.scheduleStartDate), true, item);
                                                                            }}
                                                                            className={((this.state.update && Object.keys(this.state.update).includes(budget.id) ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "cursor-pointer h-12 mt-2 items-center  shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                                                        >
                                                                            Save
                                                                            {
                                                                                this.state.loading_save &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.order_planer[item].campaigns.map((campaign) => {
                                                        return (
                                                            <>
                                                                <div className='flex flex-row justify-between mt-2'>
                                                                    <div className='flex flex-1 text-xs ml-5 items-center'>
                                                                        {campaign.name}
                                                                    </div>
                                                                    <div className='flex mx-2 '>
                                                                        {
                                                                            [{ name: "Mon", value: "scheduleMonday" }, { name: "Tue", value: "scheduleTuesday" }, { name: "Wed", value: "scheduleWednesday" }, { name: "Thu", value: "scheduleThursday" }, { name: "Fri", value: "scheduleFriday" }, { name: "Sat", value: "scheduleSaturday" }, { name: "Sun", value: "scheduleSunday" }].map((day) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className='flex flex-col mr-2 items-center min-w-7'>
                                                                                            <div className='text-xs'>{day.name}</div>
                                                                                            <div className={(campaign[day.value] ? "bg-purple-500 " : "bg-gray-500 ") + 'w-3 h-12 rounded-md'}></div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className='flex flex-col mx-2 w-48'>
                                                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                            <div className="flex flex-1 ml-5 min-w-20 ">
                                                                                {campaign.budgetPlanerActivated ? "Active" : "Disabled"}
                                                                            </div>
                                                                            <div className="relative overflow-hidden mr-5">
                                                                                <SwitchTailwind
                                                                                    value={campaign.budgetPlanerActivated}
                                                                                    onSwitch={async () => {
                                                                                        campaign.budgetPlanerActivated = !campaign.budgetPlanerActivated;
                                                                                        await this.promisedSetState({
                                                                                            order_planer: this.state.order_planer
                                                                                        });
                                                                                        this.functions.updateBudgetPlanerStatus("campaign", campaign, campaign.budgetPlanerActivated);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex mr-2 w-24'>
                                                                        <InputTailwind
                                                                            value={this.renders.daily(campaign)}
                                                                            label={"Daily budget"}
                                                                            locked={true}
                                                                            onChange={async (value) => {

                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='flex w-32'>
                                                                        <InputTailwind
                                                                            value={this.state.update && this.state.update[campaign.id] ? this.state.update[campaign.id].budget : campaign.budgetPlanerTotal}
                                                                            type={"number"}
                                                                            leftSection={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? true : false)}
                                                                            leftSectionLabel={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? this.state.client.currency[item] : "")}
                                                                            label={"Total"}
                                                                            onChange={async (value) => {
                                                                                if (!this.state.update) {
                                                                                    this.state.update = {};
                                                                                }
                                                                                if (!this.state.update[campaign.id]) {
                                                                                    this.state.update[campaign.id] = {};
                                                                                }
                                                                                this.state.update[campaign.id].budget = value;
                                                                                await this.promisedSetState({
                                                                                    update: this.state.update
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='flex ml-2 items-center'>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.functions.updateBudgetSchedule(campaign, "campaign");
                                                                            }}
                                                                            className={((this.state.update && Object.keys(this.state.update).includes(campaign.id) ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "cursor-pointer h-12 mt-2 items-center  shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                                                        >
                                                                            Save
                                                                            {
                                                                                this.state.loading_save &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.order_planer[item].adgroups.length > 0 &&
                                        <>
                                            <div className='flex border-t border-b my-1'>
                                                <div className='ml-5 text-base py-2'>
                                                    Adgroups
                                                </div>

                                            </div>
                                            <div className='flex flex-col'>
                                                {
                                                    this.state.order_planer[item].adgroups.map((adgroup) => {
                                                        return (
                                                            <>
                                                                <div className='flex flex-row justify-between mt-2'>
                                                                    <div className='flex flex-1 text-xs ml-5 items-center'>
                                                                        {adgroup.name}
                                                                    </div>
                                                                    <div className='flex mx-2 '>
                                                                        {
                                                                            [{ name: "Mon", value: "scheduleMonday" }, { name: "Tue", value: "scheduleTuesday" }, { name: "Wed", value: "scheduleWednesday" }, { name: "Thu", value: "scheduleThursday" }, { name: "Fri", value: "scheduleFriday" }, { name: "Sat", value: "scheduleSaturday" }, { name: "Sun", value: "scheduleSunday" }].map((day) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className='flex flex-col mr-2 items-center min-w-7'>
                                                                                            <div className='text-xs'>{day.name}</div>
                                                                                            <div className={(adgroup[day.value] ? "bg-purple-500 " : "bg-gray-500 ") + 'w-3 h-12 rounded-md'}></div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className='flex flex-col mx-2 w-48'>
                                                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                            <div className="flex flex-1 ml-5 min-w-20 ">
                                                                                {adgroup.budgetPlanerActivated ? "Active" : "Disabled"}
                                                                            </div>
                                                                            <div className="relative overflow-hidden mr-5">
                                                                                <SwitchTailwind
                                                                                    value={adgroup.budgetPlanerActivated}
                                                                                    onSwitch={async () => {
                                                                                        adgroup.budgetPlanerActivated = !adgroup.budgetPlanerActivated;
                                                                                        await this.promisedSetState({
                                                                                            order_planer: this.state.order_planer
                                                                                        });
                                                                                        this.functions.updateBudgetPlanerStatus("adgroup", adgroup, adgroup.budgetPlanerActivated);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex mr-2 w-24'>
                                                                        <InputTailwind
                                                                            value={this.renders.daily(adgroup)}
                                                                            label={"Daily budget"}
                                                                            locked={true}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                    <div className='flex w-32'>
                                                                        <InputTailwind
                                                                            value={this.state.update && this.state.update[adgroup.id] ? this.state.update[adgroup.id].budget : adgroup.budgetPlanerTotal}
                                                                            type={"number"}
                                                                            leftSection={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? true : false)}
                                                                            leftSectionLabel={(this.state.client && this.state.client.currency && this.state.client.currency[item] && this.state.client.currency[item] !== "" ? this.state.client.currency[item] : "")}
                                                                            label={"Total"}
                                                                            onChange={async (value) => {
                                                                                if (!this.state.update) {
                                                                                    this.state.update = {};
                                                                                }
                                                                                if (!this.state.update[adgroup.id]) {
                                                                                    this.state.update[adgroup.id] = {};
                                                                                }
                                                                                this.state.update[adgroup.id].budget = value;
                                                                                this.renders.daily(adgroup);
                                                                                await this.promisedSetState({
                                                                                    update: this.state.update
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='flex ml-2 items-center'>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.functions.updateBudgetSchedule(adgroup, "adgroup");
                                                                            }}
                                                                            className={((this.state.update && Object.keys(this.state.update).includes(adgroup.id) ? "bg-purple-500 hover:bg-purple-600 " : "bg-gray-500 cursor-not-allowed ") + "cursor-pointer h-12 mt-2 items-center  shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                                                        >
                                                                            Save
                                                                            {
                                                                                this.state.loading_save &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div >
        )
    }

}

export default orderPerformance;