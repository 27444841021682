import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, SearchIcon, SortAscendingIcon, PlusIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { UserIcon } from '@heroicons/react/outline';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

class LogList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "created",
            direction: "descending",
            search: "",
            columns: [
                { name: 'status', value: 'status_color', min_width: '50px' },
                { name: 'created', value: 'created', min_width: '200px' },
                { name: 'trigger date', value: 'trigger_date', min_width: '150px', noSort: true },
                { name: 'agency', value: 'agency', min_width: '250px' },
                { name: 'client', value: 'client', min_width: '250px' },
                { name: 'type', value: 'type', min_width: '250px' },
                { name: 'channel', value: 'channel', min_width: '250px' },
                { name: 'level', value: 'level', min_width: '250px' },
                { name: 'item', value: 'item', min_width: '250px' },
                { name: 'trigger', value: 'trigger', min_width: '250px', noSort: true },
                { name: 'value', value: 'value', min_width: '250px', noSort: true },
                { name: 'status', value: 'status', min_width: '250px', noSort: true },
                { name: 'error', value: 'error', min_width: '250px', noSort: true }
            ],
            chart: {},
            client: { id: 0 },
            advancedFilters: [
                {
                    type: "time",
                    placeholder: "All dates (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Last 24 hours", value: "24" },
                        { id: 2, name: "Last 48 hours", value: "48" },
                        { id: 3, name: "Last 72 hours", value: "72" },
                        { id: 4, name: "Last 96 hours", value: "96" },
                    ],
                    selected: { id: 1, name: "Last 24 hours", value: "24" }
                },
                {
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [
                        { id: 0, name: "All channels (default)", value: "default" }
                    ],
                    options: [
                        { id: 1, name: "Facebook", value: "facebook" },
                        { id: 2, name: "Google", value: "google" },
                        { id: 3, name: "Tiktok", value: "tiktok" },
                        { id: 4, name: "Linkedin", value: "linkedin" },
                        { id: 5, name: "Snapchat", value: "snapchat" },
                        { id: 6, name: "Twitter", value: "twitter" }
                    ],
                    selected: { id: 0, name: "All channels (default)", value: "default" }
                },
                {
                    type: "trigger",
                    placeholder: "All triggers (default)",
                    defaultOptions: [
                        { id: 0, name: "All triggers (default)", value: "default" }
                    ],
                    options: [
                        { id: 1, name: "Update status", value: "Update status" },
                        { id: 2, name: "Update budget", value: "Update budget" }
                    ],
                    selected: { id: 0, name: "All triggers (default)", value: "default" }
                },
                {
                    type: "status",
                    placeholder: "All stats (default)",
                    defaultOptions: [
                        { id: 0, name: "All stats (default)", value: "default" }
                    ],
                    options: [
                        { id: 1, name: "200 success", value: "success" },
                        { id: 2, name: "400 error", value: "error" },
                    ],
                    selected: { id: 0, name: "All stats (default)", value: "default" }
                }
            ]
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.logs(true);
    }

    functions = {
        logs: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                params = params + "&chart=true";
                let response = await this.calls.getLogs(params);
                if (response.meta && response.meta.chart) {
                    await this.promisedSetState({ chart: response.meta.chart });
                }
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ logs: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        }
    };

    calls = {
        getLogs: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listLogs?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {

                        }}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.logs();
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.logs();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onSearch={async (value) => {

                        }}
                        showAdvanced={true}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta["total"] : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={false}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.logs();
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading logs ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {/*GRAPH ON ERRORS*/}
                        {
                            this.state.logs.length > 0 &&
                            <div className="shadow bg-white rounded-lg w-full overflow-hidden mb-5 relative">
                                <div className="flex flex-row mb-4 px-4 pt-4">
                                    <div className="flex flex-row mr-4 items-center">
                                        <div className="h-5 w-5 rounded-full mr-2 bg-red-500"></div>
                                        <div className="text-sm font-medium">Errors</div>
                                    </div>
                                    <div className="flex flex-row items-center">
                                        <div className="h-5 w-5 rounded-full mr-2 bg-purple-500"></div>
                                        <div className="text-sm font-medium">Success</div>
                                    </div>
                                </div>
                                <div className="w-full h-64 p-4 pt-0">
                                    <Line
                                        options={{
                                            tooltips: {
                                                mode: 'index'
                                            },
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false
                                            },
                                            layout: {
                                                padding: {
                                                    left: 0,
                                                    right: 0,
                                                    top: 0,
                                                    bottom: 0
                                                }
                                            },
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        fontSize: 8,
                                                        beginAtZero: true,
                                                        precision: 0,
                                                    }
                                                }],
                                                xAxes: [{
                                                    ticks: {
                                                        fontSize: 8
                                                    }
                                                }]
                                            }
                                        }}
                                        data={this.state.chart}
                                    />
                                </div>
                                {
                                    this.state.loading_partial &&
                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </div>
                        }

                        <div className="shadow bg-white rounded-lg w-full overflow-hidden">

                            {/*NO ORDERS*/}
                            {
                                this.state.logs.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No logs available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.logs.length > 0 &&
                                <div className="relative overflow-scroll">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                {this.state.columns.map((item, index) => {
                                                    if (item.value === "status_color") {
                                                        return (
                                                            <th className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}></th>
                                                        )
                                                    } else {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.logs();
                                                                    }
                                                                }}
                                                                style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                            >
                                                                <div className="flex flex-row items-center">
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex flex-col">
                                                                            <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                            <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.logs.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr className="border-b" key={item.id}>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {
                                                                        item.status != 200 &&
                                                                        <div className="h-5 w-5 rounded-full bg-red-500"></div>
                                                                    }
                                                                    {
                                                                        item.status == 200 &&
                                                                        <div className="h-5 w-5 rounded-full bg-purple-500"></div>
                                                                    }
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.created ? moment(item.created).format("YYYY-MM-DD hh:mm") : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'trigger_date' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.created ? moment(item.created).add(2, "hours").format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'agency' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            {
                                                                                item.agency && item.agency.logo &&
                                                                                <img src={item.agency.logo} className="bg-cover" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.agency ? item.agency.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'client' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            {
                                                                                item.client && item.client.logo &&
                                                                                <img src={item.client.logo} className="bg-cover" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.client ? item.client.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'type' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.type ? item.type : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'channel' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.channel ? item.channel : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'level' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                    {item.level ? item.level : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'item' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                    {item.item ? item.item : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'trigger' ? "bg-gray-50" : "") + " font-medium px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {item.trigger ? item.trigger : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'value' ? "bg-gray-50" : "") + " font-medium px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {item.value ? item.value : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " font-medium px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {item.status ? item.status : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'error' ? "bg-gray-50" : "") + " font-medium px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {item.status != "200" ? (item.error ? item.error : "-") : "-"}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.logs.length > 0 &&
                                <div className="flex flex-row p-4 items-center">
                                    <div className="flex justify-center items-center">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1) {
                                                    await this.promisedSetState({
                                                        page: this.state.page - 1
                                                    });
                                                    this.functions.logs();
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous
                                        </button>
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                        this.functions.logs();
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default LogList;