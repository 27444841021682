import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, CubeIcon, ExclamationCircleIcon, FolderIcon, PauseIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import CampaignOverview from '../moduleFiles/campaignOverview';
import SwitchTailwind from '../moduleFiles/switchTailwind';

class AdminCampaignList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            search: "",
            sort: "clientperformancesMissing",
            direction: "ascending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [
                { name: 'Missing days', value: 'clientperformancesMissing', min_width: '150px' },
                { name: 'channel', value: 'channel' },
                { name: 'name', value: 'name', min_width: '350px', max_width: "350px", noSort: true },
                { name: 'schedule', value: 'has_schedule', min_width: '150px', max_width: "150px" },
                { name: 'latest log', value: 'latest_log', min_width: '150px', max_width: "150px" },
                { name: 'spend', value: 'spend' },
                { name: 'impressions', value: 'impressions' },
                { name: 'clicks', value: 'clicks' },
                { name: 'status', value: 'status', min_width: '100px', noSort: true },
                { name: 'start', value: 'start', min_width: '100px', noSort: true },
                { name: 'end', value: 'end', min_width: '100px', noSort: true },
                { name: 'agency', value: 'agency' },
                { name: 'client', value: 'client' },
                { name: 'user', value: 'user', min_width: '250px', noSort: true }
            ],
            status: {},
            agency: { id: 0 },
            client: { id: 0 },
            clients: [],
            stats: [],
            filters: [],
            throttling: {},
            advancedFilters: [
                {
                    type: "timeSpan",
                    search: false,
                    placeholder: "This month (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month (default)", value: "this_month" },
                        { id: 2, name: "This week", value: "this_week" },
                        { id: 3, name: "Yesterday", value: "yesterday" },
                        { id: 4, name: "Last week", value: "last_week" },
                        { id: 5, name: "Last month", value: "last_month" }
                    ],
                    selected: { id: 1, name: "This month (default)", value: "this_month" },
                    loading: false
                },
                {
                    type: "campaignRunning",
                    placeholder: "Exclude not started/ended (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Exclude not started/ended (default)", value: "runing" },
                        { id: 2, name: "Only ended", value: "ended" },
                        { id: 3, name: "Only not started", value: "not_started" },
                        { id: 4, name: "Include started/ended", value: "all" }
                    ],
                    selected: { id: 1, name: "Exclude not started/ended (default)", value: "runing" },
                },
                {
                    type: "notAuthorizedUser",
                    search: true,
                    placeholder: "Exclude not authorized (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Exclude not authorized (default)", value: "exclude" },
                        { id: 1, name: "Include not authorized", value: "include" }
                    ],
                    selected: { id: 1, name: "Exclude not authorized (default)", value: "exclude" },
                    loading: false
                },
                {
                    search: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "All channels (default)", value: "all" },
                        { id: 2, name: "Facebook", value: "facebook" },
                        { id: 3, name: "Linkedin", value: "linkedin" },
                        { id: 4, name: "Tiktok", value: "tiktok" },
                        { id: 5, name: "Google", value: "google" }
                    ],
                    selected: { id: 1, name: "All channels (default)", value: "all" },
                    loading: false
                }
            ],
            random_id: Math.floor(Math.random() * 10000000)
        }
    };

    componentDidMount() {
        this.functions.query();
        this.functions.getAgencies();
    }

    componentWillUnmount() {

    }

    functions = {
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            let channel = null;
            let client = {};
            queries.map((item) => {
                if (item.indexOf('client=') !== -1) {
                    client.id = item.replace("client=", "");
                }
                if (item.indexOf('client_name=') !== -1) {
                    client.name = item.replace("client_name=", "");
                    client.name = decodeURIComponent(client.name);
                }
                if (item.indexOf('channel=') !== -1) {
                    channel = item.replace("channel=", "");
                }
            });
            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                if (channel && item.type == 'channel') {
                    let option = null;
                    item.options.map((inner_item) => {
                        if (inner_item.value == channel) {
                            option = inner_item;
                        }
                    });
                    if (option) {
                        item.selected = option;
                    }
                }
                return item;
            })
            await this.promisedSetState({
                client: client.id ? client : { id: 0 },
                advancedFilters: this.state.advancedFilters
            });
            if (this.state.client.id !== 0) {
                this.refs.TopNavigationAgent.functions.setClient(this.state.client);
            }
            this.functions.orders(true);
        },
        orders: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                params = params + "&chart=true";
                let response = await this.calls.orders(params);
                response.data = response.data.map((item) => {
                    if (Array.isArray(item.performanceItems)) {
                        item.performanceItems = this.renders.performanceItems(item.performanceItems);
                    }
                    return item;
                });
                await this.promisedSetState({
                    orders: response.data,
                    meta: response.meta
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
        },
        checkSchedule: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.checkSchedule(id);
            } catch (error) { }
            this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
            await this.promisedSetState({ orders: this.state.orders });
        },
        getAgencies: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_agencies_pagination: paginaton,
                loading_agencies: init
            });
            try {
                let response = await this.calls.getAgencies();
                if (!search_value || (search_value && search_value == this.state.agency_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            agencies: response.data.map((item) => { item.image = item.logo; return item }),
                            agency_total: response.meta.total
                        });
                    } else {
                        this.state.agencies = this.state.agencies.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            agencies: this.state.agencies,
                            agency_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agencies_search: false,
                loading_agencies_pagination: false,
                loading_agencies: false
            });
        },
        runFunction: async (item, save_data) => {
            item.syncing = true;
            item.performanceItems = item.performanceItems.map((inner_item) => {
                if (item.selected_items[inner_item._id]) {
                    inner_item.loading = true;
                }
                return inner_item;
            });
            await this.promisedSetState({
                orders: this.state.orders
            });
            let dates = [];
            let channel = item.channel;
            let agency = item.agency.id;
            let client = item.client.id;
            let campaign = item.campaignId;
            for (let key in item.selected_items) {
                if (item.selected_items[key].createdAtString) {
                    dates.push(item.selected_items[key].createdAtString);
                }
            }
            try {
                await this.calls.runFunction(agency, client, channel, dates, save_data, campaign);
            } catch (error) { }
        }
    };

    calls = {
        orders: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCampaigns?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.agency && this.state.agency.id !== 0 ? "&agency=" + this.state.agency.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        getAgencies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencies?limit=" + this.state.agency_limit + "&page=" + this.state.agency_page + "&sortBy=" + this.state.agency_sort + "&orderBy=" + this.state.agency_direction + (this.state.agency_search !== "" ? ("&search=" + this.state.agency_search) : "");
            return apiRegister.call(options, url);
        },
        checkSchedule: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/checkSchedule?campaign=" + id;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeExternalCampaignAdmin?id=" + id;
            return apiRegister.call(options, url);
        },
        runFunction: (agency, client, channel, dates, save_data, campaign) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/runCron?agency=" + agency + "&client=" + client + "&function=" + "updateAgencyClientsPerformance" + "&sub_function=" + "campaigns" + "&channel=" + channel + (!save_data ? "&skip_save=true" : "") + "&dates=" + dates + "&pusher=" + this.state.random_id + "&campaign=" + campaign;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        datesInBetween: (startDate, endDate) => {
            var dates = [];
            var currDate = moment(startDate).startOf('day');
            var lastDate = moment(endDate).startOf('day');
            dates.push(moment(startDate).startOf('day').clone().toDate());
            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                dates.push(currDate.clone().toDate());
            }
            dates.push(moment(endDate).startOf('day').clone().toDate());
            return dates;
        },
        performanceItems: (items) => {
            let all_dates = [];
            let time_span = true;
            this.state.advancedFilters.map((item) => {
                if (item.type == "timeSpan") {
                    time_span = item.selected.value;
                }
            });
            if (time_span === "this_month") {
                all_dates = this.renders.datesInBetween(moment().startOf('month'), moment().subtract(1, 'days'));
            } else if (time_span === "this_week") {
                all_dates = this.renders.datesInBetween(moment().startOf('week'), moment().subtract(1, 'days'));
            } else if (time_span === "yesterday") {
                all_dates = this.renders.datesInBetween(moment().subtract(1, 'days'), moment().subtract(1, 'days'));
            } else if (time_span === "last_week") {
                all_dates = this.renders.datesInBetween(moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week'));
            } else if (time_span === "last_month") {
                all_dates = this.renders.datesInBetween(moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month'));
            } else {
                all_dates = this.renders.datesInBetween(moment().startOf('month'), moment().subtract(1, 'days'));
            }
            all_dates = all_dates.map((item) => {
                return moment(item).format("YYYY-MM-DD")
            });
            all_dates = all_dates.filter((item, index) => all_dates.indexOf(item) === index);
            let missing_dates = [];
            all_dates.map((item) => {
                let missing = true;
                items.map((inner_item) => {
                    if (inner_item.createdAtString == moment(item).format("YYYY-MM-DD")) {
                        missing = false;
                    }
                })
                if (missing) {
                    missing_dates.push({
                        _id: moment(item).format("YYYY-MM-DD"),
                        missing: true,
                        spend: 0,
                        impressions: 0,
                        clicks: 0,
                        createdAtString: moment(item).format("YYYY-MM-DD")
                    });
                }
            });
            if (missing_dates.length > 0) {
                items = items.concat(missing_dates);
            }
            return items;
        },
        spend: (spend) => {
            try {
                spend = +spend;
                return spend.toFixed(2);
            } catch (error) {
                return spend;
            }
        },
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        weekday: (day) => {
            try {
                let weekday = moment(day).weekday();
                let weekdays = {
                    "1": "Monday",
                    "2": "Tuesday",
                    "3": "Wednesday",
                    "4": "Thursday",
                    "5": "Friday",
                    "6": "Saturday",
                    "0": "Sunday"
                };
                return weekdays[weekday];
            } catch (error) {
                return "-"
            }
        },
        timeSpan: () => {
            let time_span = "this_month";
            this.state.advancedFilters.map((item) => {
                if (item.type == "timeSpan") {
                    time_span = item.selected.value;
                }
            });
            return time_span;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        ref={"TopNavigationAgent"}
                        onButton={(type) => {

                        }}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.orders();
                        }}
                        onAdvancedFilterSearch={async (value, type) => {

                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.orders();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onSearch={async (value) => {
                            if (value && value === "") {
                                await this.promisedSetState({
                                    search: "",
                                    page: 1
                                });
                                this.functions.orders();
                            } else {
                                if (this.state.throttling.current) {
                                    //SKIP
                                } else {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: value,
                                            page: 1
                                        });
                                        this.functions.orders();
                                    } else {
                                        this.state.throttling.current = true;
                                        await this.promisedSetState({
                                            search: value,
                                            throttling: this.state.throttling
                                        });
                                        setTimeout(async () => {
                                            this.state.throttling.current = false;
                                            await this.promisedSetState({
                                                throttling: this.state.throttling
                                            });
                                            await this.promisedSetState({
                                                page: 1
                                            });
                                            this.functions.orders();
                                        }, 300);
                                    }
                                }
                            }
                        }}
                        showAdvanced={true}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        filters={[]}
                        hideUserDropdown={true}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        client={this.state.client}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.orders();
                        }}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*CREATE DEFAULT METRIC*/}
                <SlideoutTailwind
                    title={this.state.campaign ? this.state.campaign.name : ""}
                    submitButtonText={"Close"}
                    full={true}
                    noPadding={true}
                    secondaryButton={false}
                    open={this.state.campaign ? true : false}
                    onCancel={async () => {
                        await this.refs.CampaignOverview.functions.disconnect();
                        await this.promisedSetState({ campaign: false });
                    }}
                    onSubmit={async () => {
                        await this.refs.CampaignOverview.functions.disconnect();
                        await this.promisedSetState({ campaign: false });
                    }}
                >
                    {
                        this.state.campaign &&
                        <CampaignOverview
                            ref={"CampaignOverview"}
                            campaign={this.state.campaign}
                            timeSpan={this.renders.timeSpan()}
                        />
                    }
                </SlideoutTailwind>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaigns ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO ORDERS*/}
                            {
                                this.state.orders.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No Campaigns available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.orders.length > 0 &&
                                <div className="relative overflow-scroll h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.orders();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4 truncate">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                        <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.orders.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr className="border-b" key={item.id + index}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Open", "Run schedule check", "Delete"].map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                if (option == "Delete") {
                                                                                                                    this.setState({
                                                                                                                        remove: item
                                                                                                                    })
                                                                                                                } else if (option == "Open") {
                                                                                                                    this.setState({
                                                                                                                        campaign: item
                                                                                                                    })
                                                                                                                } else if (option == "Run schedule check") {
                                                                                                                    this.functions.checkSchedule(item.id);
                                                                                                                }
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                    {
                                                                        false &&
                                                                        <div onClick={() => {
                                                                            item.open = !item.open;
                                                                            this.setState({
                                                                                orders: this.state.orders
                                                                            })
                                                                        }} className="cursor-pointer ml-2 flex relative w-20 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                            {
                                                                                !item.open &&
                                                                                <div>Open</div>
                                                                            }
                                                                            {
                                                                                item.open &&
                                                                                <div>Close</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td className={(this.state.sort === 'clientperformancesMissing' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.clientperformancesMissing}
                                                                </td>
                                                                <td className={(this.state.sort === 'channel' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row">
                                                                        {item.channel === "facebook" &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channel === "linkedin" &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channel === "google" &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channel === "tiktok" &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channel === "snapchat" &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channel === "twitter" &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td style={{ maxWidth: "350px" }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 text-sm truncate font-medium whitespace-no-wrap cursor-pointer items-center border-gray-300"}>
                                                                    {item.campaignName ? item.campaignName : (item.name ? item.name : "-")}
                                                                </td>
                                                                <td className={(this.state.sort === 'has_schedule' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    <span
                                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                                            {
                                                                                ["bg-purple-100 text-purple-500"]: item.has_schedule,
                                                                                ["bg-gray-100 text-gray-500 bg-opacity-50"]: !item.has_schedule,
                                                                            })
                                                                        }>
                                                                        {item.has_schedule ? "schedule" : "none"}
                                                                    </span>
                                                                </td>
                                                                <td style={{ maxWidth: "150px" }} className={(this.state.sort === 'latest_log' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 text-sm truncate font-medium whitespace-no-wrap cursor-pointer items-center border-gray-300"}>
                                                                    {item.latest_log ? moment(item.latest_log.triggerDate).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'spend' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {this.renders.convertToDecimal(item.metrics.spend)} {item.client.currency}
                                                                </td>
                                                                <td className={(this.state.sort === 'impressions' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.metrics.impressions}
                                                                </td>
                                                                <td className={(this.state.sort === 'clicks' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.metrics.clicks}
                                                                </td>
                                                                {
                                                                    !item.ended &&
                                                                    <td className={(this.state.sort === 'campaignStatus' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                        <span
                                                                            className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                                                {
                                                                                    ["bg-green-100 text-green-500"]: typeof item.campaignStatus === "string" && (item.campaignStatus.toString().toLowerCase() === "enabled" || item.campaignStatus.toString().toLowerCase() === "active"),
                                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: typeof item.campaignStatus === "string" && item.campaignStatus.toString().toLowerCase() === "paused",
                                                                                    ["bg-gray-100 text-gray-500 bg-opacity-50"]: typeof item.campaignStatus !== "string" || (typeof item.campaignStatus === "string" && item.campaignStatus.toString().toLowerCase() !== "enabled" && item.campaignStatus.toString().toLowerCase() !== "paused")
                                                                                })
                                                                            }>
                                                                            {typeof item.campaignStatus == "string" && item.campaignStatus !== "" ? item.campaignStatus.toLowerCase() : "unknown"}
                                                                        </span>
                                                                    </td>
                                                                }
                                                                {
                                                                    item.ended &&
                                                                    <td className={(this.state.sort === 'campaignStatus' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                        <span
                                                                            className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                                                {
                                                                                    ["bg-red-100 text-red-500 bg-opacity-50"]: true
                                                                                })
                                                                            }>
                                                                            {"Ended"}
                                                                        </span>
                                                                    </td>
                                                                }
                                                                <td style={{ maxWidth: "350px" }} className={(this.state.sort === 'start' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 text-sm truncate font-medium whitespace-no-wrap cursor-pointer items-center border-gray-300"}>
                                                                    {item.campaignStartDate ? moment(item.campaignStartDate).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td style={{ maxWidth: "350px" }} className={(this.state.sort === 'end' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 text-sm truncate font-medium whitespace-no-wrap cursor-pointer items-center border-gray-300"}>
                                                                    {item.campaignEndDate ? moment(item.campaignEndDate).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'agency' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {
                                                                        item.agency &&
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="h-8 w-8 p-1 rounded-full overflow-hidden flex justify-center items-center border">
                                                                                <img src={item.agency.logo} className="bg-cover" />
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <div className="font-medium text-sm whitespace-no-wrap">{item.agency.name ? item.agency.name : "-"}</div>
                                                                                <div className="font-medium text-xxxs whitespace-no-wrap">ID: {item.agency.id}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td className={(this.state.sort === 'client' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    {
                                                                        item.client &&
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="h-8 w-8 p-1 rounded-full overflow-hidden flex justify-center items-center border">
                                                                                <img src={item.client.logo} className="bg-cover" />
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <div className="font-medium text-sm whitespace-no-wrap">{item.client.name ? item.client.name : "-"}</div>
                                                                                <div className="font-medium text-xxxs whitespace-no-wrap">ID: {item.client.id}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </td>
                                                                {
                                                                    item.channel === "facebook" &&
                                                                    <td className={(this.state.sort === 'user' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div>{item.channel_user && item.channel_user.id ? item.channel_user.email : "No user"}</div>
                                                                        <div className={(item.facebook_user_authenticted ? "text-green-500" : "text-red-500") + " text-xxxs"}>{item.facebook_user_authenticted ? "Authenticated" : "Not Authenticated"}</div>
                                                                    </td>
                                                                }
                                                                {
                                                                    item.channel === "google" &&
                                                                    <td className={(this.state.sort === 'user' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div>{item.channel_user && item.channel_user.id ? item.channel_user.email : "No user"}</div>
                                                                        <div className={(item.google_user_authenticted ? "text-green-500" : "text-red-500") + " text-xxxs"}>{item.google_user_authenticted ? "Authenticated" : "Not Authenticated"}</div>
                                                                    </td>
                                                                }
                                                                {
                                                                    item.channel === "tiktok" &&
                                                                    <td className={(this.state.sort === 'user' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div>{item.channel_user && item.channel_user.id ? item.channel_user.email : "No user"}</div>
                                                                        <div className={(item.tiktok_user_authenticted ? "text-green-500" : "text-red-500") + " text-xxxs"}>{item.tiktok_user_authenticted ? "Authenticated" : "Not Authenticated"}</div>
                                                                    </td>
                                                                }
                                                                {
                                                                    item.channel === "linkedin" &&
                                                                    <td className={(this.state.sort === 'user' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div>{item.channel_user && item.channel_user.id ? item.channel_user.email : "No user"}</div>
                                                                        <div className={(item.linkedin_user_authenticted ? "text-green-500" : "text-red-500") + " text-xxxs"}>{item.linkedin_user_authenticted ? "Authenticated" : "Not Authenticated"}</div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                            <tr key={item.id} className={(item.open ? "h-100 border-b " : "h-0 ") + " overflow-hidden transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                {
                                                                    item.open &&
                                                                    <div className={(item.open ? "" : "h-0 max-h-0 opacity-0 ") + " grid grid-cols-12 gap-4 p-4 w-full absolute left-0 right-0 h-full overflow-hidden"}>
                                                                        {
                                                                            Array.isArray(item.performanceItems) &&
                                                                            item.performanceItems.sort(function (a, b) {
                                                                                return new Date(b.createdAtString) - new Date(a.createdAtString);
                                                                            }).map((inner_item) => {
                                                                                return (
                                                                                    <div onClick={() => {
                                                                                        if (!inner_item.loading) {
                                                                                            if (!item.selected_items) {
                                                                                                item.selected_items = {};
                                                                                            }
                                                                                            if (item.selected_items[inner_item._id]) {
                                                                                                delete item.selected_items[inner_item._id];
                                                                                            } else {
                                                                                                item.selected_items[inner_item._id] = inner_item;
                                                                                            }
                                                                                            this.setState({
                                                                                                orders: this.state.orders
                                                                                            });
                                                                                        }
                                                                                    }} className={(item.selected_items && item.selected_items[inner_item._id] ? "border-purple-500 " : (inner_item.missing ? "border-red-500" : "hover:border-gray-500 ")) + " relative min-h-20 flex flex-col cursor-pointer border-1.5 rounded-md p-2"}>
                                                                                        <div className="flex flex-row">
                                                                                            <div className="flex flex-1">
                                                                                                <div className={(inner_item.missing ? "text-red-500" : "") + " font-medium text-xxs"}>
                                                                                                    {inner_item.createdAtString}
                                                                                                    <div className="text-xxxs">{this.renders.weekday(inner_item.createdAtString)}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                {
                                                                                                    (this.renders.weekday(inner_item.createdAtString) in item) &&
                                                                                                    item.scheduleActivated && !item[this.renders.weekday(inner_item.createdAtString)] &&
                                                                                                    <div className="bg-orange-500 px-1 py-0.5 rounded-md text-xxs text-white">
                                                                                                        <PauseIcon className="h-3 text-white" />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={"flex flex-1"}></div>
                                                                                        <div className="flex flex-row">
                                                                                            <div className="flex flex-1 flex-col items-center justify-center">
                                                                                                <div className="text-xs">{inner_item.missing ? "-" : this.renders.spend(inner_item.spend)}</div>
                                                                                                <div className="text-xxs text-gray-400">Spend</div>
                                                                                            </div>
                                                                                            <div className="flex flex-1 flex-col items-center justify-center">
                                                                                                <div className="text-xs">{inner_item.missing ? "-" : +inner_item.impressions}</div>
                                                                                                <div className="text-xxs text-gray-400">Imp.</div>
                                                                                            </div>
                                                                                            <div className="flex flex-1 flex-col items-center justify-center">
                                                                                                <div className="text-xs">{inner_item.missing ? "-" : +inner_item.clicks}</div>
                                                                                                <div className="text-xxs text-gray-400">Clicks</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            inner_item.loading &&
                                                                                            <div className="w-full h-full absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center z-20 rounded-lg">
                                                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            <div className="col-span-12">
                                                                                <div className="flex flex-1 justify-start">
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            if (!item.syncing && item.selected_items && Object.keys(item.selected_items).length > 0) {
                                                                                                this.functions.runFunction(item, item.save_data);
                                                                                            }
                                                                                        }}
                                                                                        className={(item.selected_items && Object.keys(item.selected_items).length > 0 ? "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white cursor-pointer " : "bg-gray-500 text-white cursor-not-allowed ") + " inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                                                    >
                                                                                        Run function
                                                                                        {
                                                                                            item.syncing &&
                                                                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </button>
                                                                                    <div className="ml-4 pt-1">
                                                                                        <SwitchTailwind
                                                                                            disabled={!(item.selected_items && Object.keys(item.selected_items).length > 0)}
                                                                                            value={item.save_data ? true : false}
                                                                                            onSwitch={async () => {
                                                                                                if (!item.syncing) {
                                                                                                    item.save_data = !item.save_data;
                                                                                                    this.setState({
                                                                                                        order: this.state.orders
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default AdminCampaignList;
