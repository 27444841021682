import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Input from "../input";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import SweetAlert from "sweetalert-react";
import Dropdown from "../dropdown";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";

var moment = require('moment');

class AdvertisingChannelBudgetNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            budget: {},
            details: {},
            client: {},
            channels: {},
            error: false,
            total: 0,
            loading: false,
            round: {name: "Round down", value: 'down'},
            loaded: false
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'channels' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
        this.init.agency();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                loaded: true
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {}
            })
        },
        agency: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getAgency().then((response) => {
                    this.state.details.agency = response.data;
                    this.setState({
                        loading: false,
                        details: this.state.details
                    }, () => {
                        this.functions.save('details');
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                    });
                });
            });
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true,
                    next: true
                })
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    renders = {
        availableChannels: () => {
            try {
                let channels = [];
                if (this.state.client.google) {
                    channels.push('google');
                }
                if (this.state.client.facebook) {
                    channels.push('facebook_instagram');
                }
                if (this.state.client.linkedin) {
                    channels.push('linkedin');
                }
                if (this.state.client.google_shopping && this.state.client.google) {
                    channels.push('google_shopping');
                }
                if (this.state.client.twitter) {
                    //channels.push('twitter');
                }
                return channels;
            } catch (e) {
                return []
            }
        },
        channels: () => {
            try {
                return Object.keys(this.state.channels).filter((key) => {
                    return this.state.channels[key]
                }).length > 0
            } catch (e) {
                return false
            }
        },
        currency: (key) => {
            try {
                if (key === 'facebook' || key === 'instagram') {
                    return this.state.client[key].facebookAdAccountCurrency
                } else if (key === 'facebook_instagram') {
                    return this.state.client['facebook'].facebookAdAccountCurrency
                } else {
                    if(this.state.client.google_shopping && this.state.client.google){
                        return this.state.client['google'].currency
                    }else{
                        return this.state.client[key].currency
                    }
                }
            } catch (e) {
                return false
            }
        },
        errors: () => {
            let array = [];
            if (Object.keys(this.state.channels).filter((key) => {
                return this.state.channels[key]
            }).length < 1) {
                array.push({title: 'Select channels', type: 'channels', section: 'channels'});
            }
            for (let key in this.state.budget) {
                if (this.renders.checked(key) && (this.state.budget[key].daily < 1 || !this.state.budget[key].daily)) {
                    array.push({
                        title: 'Daily budget for ' + key + " is to low",
                        type: 'channel_budget',
                        section: 'channels'
                    });
                }
            }
            let total = 0;
            for (let key in this.state.budget) {
                if(this.renders.checked(key)){
                    total = total + this.state.budget[key].total;
                }
            }
            if(total < this.state.budget.total){
                array.push({
                    title: 'Channels total is lower than total budget',
                    type: 'channel_budget',
                    section: 'channels'
                });
            }
            if(total > this.state.budget.total){
                array.push({
                    title: 'Channels total is higher than total budget',
                    type: 'channel_budget',
                    section: 'channels'
                });
            }
            return array;
        },
        round: (num) => {
            try {
                return Math.round((num + Number.EPSILON) * 100) / 100
            } catch (e) {
                return 0
            }
        },
        checked: (key) => {
            if (key === 'google') {
                return this.state.channels[key];
            }if (key === 'google_shopping') {
                return this.state.channels[key];
            }else if(key === 'linkedin'){
                return this.state.channels[key];
            } else {
                if (key === 'facebook_instagram') {
                    return this.state.channels[key]
                } else {
                    if (this.state.channels.facebook_instagram) {
                        return false
                    } else {
                        return this.state.channels[key]
                    }
                }
            }
        },
        divideOptions: () => {
            let array = [];
            let options = [
                {name: "10%", value: 0.1, type: 'percent', total: 0.9},
                {name: "20%", value: 0.2, type: 'percent', total: 0.8},
                {name: "25%", value: 0.25, type: 'percent', total: 0.75},
                {name: "30%", value: 0.3, type: 'percent', total: 0.7},
                {name: "33%", value: 0.33, type: 'percent', total: 0.67},
                {name: "40%", value: 0.4, type: 'percent', total: 0.6},
                {name: "50%", value: 0.5, type: 'percent', total: 0.5},
                {name: "60%", value: 0.6, type: 'percent', total: 0.4},
                {name: "70%", value: 0.7, type: 'percent', total: 0.3},
                {name: "80%", value: 0.8, type: 'percent', total: 0.2},
                {name: "90%", value: 0.9, type: 'percent', total: 0.1},
                {name: "100%", value: 1, type: 'percent', total: 0},
                {name: "custom", value: 'custom'}
            ];
            return options
        },
        systemFee: (channel) => {
            try{
                if(!this.state.details.noEndDate){
                    return (this.state.details.agency.system_fee / 100) * this.state.budget[channel].total;
                }else{
                    return (this.state.details.agency.system_fee / 100) * this.state.budget[channel].daily;
                }
            }catch (e) {
                return "";
            }
        },
        systemFeePercentage: () => {
            try{
                return this.state.details.agency.system_fee;
            }catch (e) {
                return null;
            }
        }
    };

    render() {
        return (
            <div className="h-full flex flex-1 flex-col pb-5">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'}/>
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 2: Channels and budget
                    </div>
                </div>
                <div>
                    <div className="rounded-md py-4 px-4 h-full w-full bg-white">
                        <div className="flex flex-row flex-wrap">
                            {
                                this.renders.availableChannels().map((key, index) => {
                                    return (
                                        <div onClick={() => {
                                            if ((key === 'instagram' || key === 'facebook') && this.renders.checked('facebook_instagram')) {

                                            } else {
                                                if (key === 'facebook_instagram') {
                                                    if (this.renders.checked(key)) {
                                                        if (this.state.client.facebook) {
                                                            this.state.channels['facebook'] = false;
                                                        }
                                                        if (this.state.client.instagram) {
                                                            this.state.channels['instagram'] = false;
                                                        }
                                                    } else {
                                                        if (this.state.client.facebook) {
                                                            this.state.channels['facebook'] = true;
                                                        }
                                                        if (this.state.client.instagram) {
                                                            this.state.channels['instagram'] = true;
                                                        }
                                                    }
                                                }

                                                this.state.channels[key] = !this.state.channels[key];
                                                this.state.budget[key] = {};
                                                this.state.budget[key].total = 0;
                                                this.state.budget[key].daily = 0;


                                                this.setState({
                                                    channels: this.state.channels,
                                                    budget: this.state.budget
                                                }, () => {

                                                    //SET CHANNEL PERCENTAGE
                                                    let selected = this.renders.availableChannels().filter((key) => {
                                                        return this.renders.checked(key)
                                                    }).length;

                                                    if(!this.state.details.noEndDate){
                                                        let dates = [];
                                                        if(typeof this.state.details === "object" && this.state.details.startDate && this.state.details.endDate){
                                                            dates = [];
                                                            var currDate = moment(this.state.details.startDate).startOf('day');
                                                            var lastDate = moment(this.state.details.endDate).startOf('day');
                                                            dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                                                            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                dates.push(currDate.clone().toDate());
                                                            }
                                                            dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                                                        }
                                                        for(let inner_key in this.state.budget){
                                                            if(this.renders.checked(inner_key) && (typeof this.state.budget[inner_key].percentage !== "object" || (typeof this.state.budget[inner_key].percentage === "object" && this.state.budget[inner_key].percentage.value !== "custom"))){
                                                                if(selected === 1){
                                                                    this.state.budget[inner_key].percentage = {name: "100%", value: 1, type: 'percent', total: 0};
                                                                }else if(selected === 2){
                                                                    this.state.budget[inner_key].percentage = {name: "50%", value: 0.5, type: 'percent', total: 0.5};
                                                                }else if(selected === 3){
                                                                    this.state.budget[inner_key].percentage = {name: "33%", value: 0.33, type: 'percent', total: 0.33};
                                                                }else if(selected === 4){
                                                                    this.state.budget[inner_key].percentage = {name: "25%", value: 0.25, type: 'percent', total: 0.25};
                                                                }
                                                                this.state.budget[inner_key].total = (this.state.budget.total * this.state.budget[inner_key].percentage.value);
                                                                this.state.budget[inner_key].daily = this.renders.round(this.state.budget[inner_key].total / dates.length);
                                                            }
                                                        }
                                                    }else{
                                                        for(let inner_key in this.state.budget){
                                                            if(this.renders.checked(inner_key) && (typeof this.state.budget[inner_key].percentage !== "object" || (typeof this.state.budget[inner_key].percentage === "object" && this.state.budget[inner_key].percentage.value !== "custom"))){
                                                                if(selected === 1){
                                                                    this.state.budget[inner_key].percentage = {name: "100%", value: 1, type: 'percent', total: 0};
                                                                }else if(selected === 2){
                                                                    this.state.budget[inner_key].percentage = {name: "50%", value: 0.5, type: 'percent', total: 0.5};
                                                                }else if(selected === 3){
                                                                    this.state.budget[inner_key].percentage = {name: "33%", value: 0.33, type: 'percent', total: 0.33};
                                                                }else if(selected === 4){
                                                                    this.state.budget[inner_key].percentage = {name: "25%", value: 0.25, type: 'percent', total: 0.25};
                                                                }
                                                                this.state.budget[inner_key].daily = (this.state.budget.daily_total * this.state.budget[inner_key].percentage.value);
                                                            }
                                                        }
                                                    }

                                                    this.setState({
                                                        budget: this.state.budget,
                                                        channels: this.state.channels
                                                    }, () => {
                                                        this.functions.save('budget');
                                                        this.functions.save('channels');
                                                    })

                                                })
                                            }
                                        }} style={!this.renders.checked(key) ? {backgroundColor: '#fcfcfc'} : {}}
                                             className={
                                                 cx("flex-col border-gray-100 border-2 bg-white py-4 w-full md:w-1/6 cursor-pointer rounded-md mr-3 flex items-center justify-center relative",
                                                     {
                                                         ["border-green-500 bg-green-100"]: this.renders.checked(key),
                                                         ['border-red-500']: this.state.next && this.renders.errors().filter((item) => {
                                                             return item.type === 'channels'
                                                         }).length > 0,
                                                         ['opacity-25 cursor-not-allowed']: (key === 'instagram' || key === 'facebook') && this.renders.checked('facebook_instagram')
                                                     })
                                             }>
                                            {this.renders.checked(key) &&
                                            <div
                                                className="checked bg-green-500 absolute top-0 left-0 w-6 h-6 -ml-3 -mt-3 rounded-full text-white flex justify-center items-center">
                                                <FeatherIcon className="stroke-current" size={16} icon={'check'}/>
                                            </div>
                                            }
                                            <div className="flex flex-row">
                                                {
                                                    key === 'google' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-google-500">
                                                        <img className="w-6"
                                                             src={require('../../assets/images/google_icon.png')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'google_shopping' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-googleshopping-500">
                                                        <img className="w-6"
                                                             src={require('../../assets/images/google-shopping.png')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook_instagram' &&
                                                    <div className="px-2 py-2 border-2 border-white h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-facebook-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'facebook_instagram' &&
                                                    <div className="px-2 py-2 border-2 border-white -ml-4 h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-instagram-500">
                                                        <img className="w-6"
                                                             src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'linkedin' &&
                                                    <div className="px-2 py-2 border-2 border-white h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-linkedin-500">
                                                        <img className="w-6"
                                                             src={require('../../assets/images/linkedin_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'twitter' &&
                                                    <div className="px-2 py-2 border-2 border-white h-16 w-16 rounded-full mb-2 flex items-center justify-center bg-twitter-500">
                                                        <img className="w-6"
                                                             src={require('../../assets/images/twitter_icon.svg')}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className="font-bold text-xs capitalize text-center">
                                                {key !== 'facebook_instagram' ? (key !== 'google_shopping'?key:"Google Shopping") : 'Facebook & Instagram'}
                                            </div>
                                            {
                                                false &&
                                                <div className="mt-4">
                                                    <Switch
                                                        onColor="#1AD5BD"
                                                        checked={this.renders.checked(key)}
                                                        onChange={(checked) => {

                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.next && this.renders.errors().filter((item) => {
                                return item.type === 'channels'
                            }).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-decimal">
                                    {
                                        this.renders.errors().filter((item) => {
                                            return item.type === 'channels'
                                        }).map((item, index) => {
                                            return (
                                                <li className="text-xs text-red-500 font-bold" key={index}>
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            this.renders.availableChannels().filter((key) => {
                                return this.renders.checked(key)
                            }).length > 0 &&
                            <div className="rounded-md mt-4 bg-gray-100 p-4">
                                {
                                    <div className="">
                                        {
                                            !this.state.details.noEndDate &&
                                            <h3 className="mb-0 font-bold">
                                                Set total budget
                                            </h3>
                                        }
                                        {
                                            this.state.details.noEndDate &&
                                            <h3 className="mb-0 font-bold">
                                                Set daily total budget
                                            </h3>
                                        }
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Avg. number of search per month in parantheses
                                        </p>
                                    </div>
                                }
                                <div className="flex flex-row mt-4 mb-4">
                                    {
                                        !this.state.details.noEndDate &&
                                        <div className="flex flex-2">
                                            <Input
                                                noBorder={true}
                                                noTitle={true}
                                                title={"Total budget"}
                                                type={'number'}
                                                white={true}
                                                placeholder={''}
                                                value={this.state.budget.total ? this.state.budget.total : 0}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.budget.total = +event.target.value;

                                                    //CAMPAIGN DAYS
                                                    let dates = [];
                                                    if(typeof this.state.details === "object" && this.state.details.startDate && this.state.details.endDate){
                                                        dates = [];
                                                        var currDate = moment(this.state.details.startDate).startOf('day');
                                                        var lastDate = moment(this.state.details.endDate).startOf('day');
                                                        dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                                                        while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                            dates.push(currDate.clone().toDate());
                                                        }
                                                        dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                                                    }

                                                    //UPDATE BUDGETS
                                                    for(let inner_key in this.state.budget){
                                                        if(this.renders.checked(inner_key) && typeof this.state.budget[inner_key].percentage === "object" && this.state.budget[inner_key].percentage.value !== "custom"){
                                                            this.state.budget[inner_key].total = (this.state.budget.total * this.state.budget[inner_key].percentage.value);
                                                            this.state.budget[inner_key].daily = this.renders.round(this.state.budget[inner_key].total / dates.length);
                                                        }
                                                    }

                                                    this.setState({
                                                        budget: this.state.budget
                                                    }, () => {
                                                        this.functions.save('budget');
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.details.noEndDate &&
                                        <div className="flex flex-2">
                                            <Input
                                                noBorder={true}
                                                noTitle={true}
                                                title={"Daily total budget"}
                                                type={'number'}
                                                white={true}
                                                placeholder={''}
                                                value={this.state.budget.daily_total ? this.state.budget.daily_total : 0}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.budget.daily_total = +event.target.value;

                                                    //UPDATE BUDGETS
                                                    for(let inner_key in this.state.budget){
                                                        if(this.renders.checked(inner_key) && typeof this.state.budget[inner_key].percentage === "object" && this.state.budget[inner_key].percentage.value !== "custom"){
                                                            this.state.budget[inner_key].daily = (this.state.budget.daily_total * this.state.budget[inner_key].percentage.value);
                                                        }
                                                    }

                                                    this.setState({
                                                        budget: this.state.budget
                                                    }, () => {
                                                        this.functions.save('budget');
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                {
                                    this.renders.availableChannels().filter((key) => {
                                        return this.renders.checked(key)
                                    }).map((key, index) => {
                                        return (
                                            <div className={(index !== (this.renders.availableChannels().filter((key) => {
                                                return this.renders.checked(key)
                                            }).length - 1) ? "mb-2" : "") + " flex flex-1 flex-row"}>
                                                <div style={index>0?{minWidth: "256px"}:{minWidth: "256px", marginTop: '23px'}} className="flex flex-1 align-middle justify-center items-center px-4 flex-row bg-white mr-2 rounded flex-row">
                                                    {key === 'google' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-google-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/google_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {key === 'google_shopping' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-googleshopping-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/google-shopping.png')}/>
                                                    </div>
                                                    }
                                                    {key === 'linkedin' &&
                                                    <div
                                                         className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-linkedin-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/linkedin_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {key === 'twitter' &&
                                                    <div
                                                         className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-twitter-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/twitter_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {(key === 'facebook' || key === 'facebook_instagram') &&
                                                    <div className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-1 flex items-center justify-center bg-facebook-500">
                                                        <img style={{width: "6px"}}
                                                             src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                    }
                                                    {(key === 'instagram' || key === 'facebook_instagram') &&
                                                    <div className="px-2 border-2 border-white -ml-3 py-2 h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-instagram-500">
                                                        <img className="w-3"
                                                             src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                    }
                                                    <div className="font-bold text-xs flex-1 pl-2 flex">
                                                        {key !== 'facebook_instagram' ? (key !== 'google_shopping'?key:"Google Shopping") : 'Facebook & Instagram'}
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 mr-2">
                                                    <Dropdown
                                                        filter={false}
                                                        searchFilter={false}
                                                        search={false}
                                                        noTitle={index > 0}
                                                        title={!this.state.details.noEndDate?"% of total budget":"% of daily total budget"}
                                                        noBorder={true}
                                                        white={true}
                                                        size={'full'}
                                                        placeholder={"Select option"}
                                                        options={this.renders.divideOptions(key)}
                                                        value={this.state.budget[key] ? (this.state.budget[key].percentage ? this.state.budget[key].percentage : {}) : {}}
                                                        onChange={(value) => {

                                                            if(!this.state.details.noEndDate){
                                                                if(value.value !== "custom"){
                                                                    this.state.budget[key].percentage = value;
                                                                    this.state.budget[key].total = (this.state.budget.total * value.value);
                                                                }else{
                                                                    this.state.budget[key].percentage = value;
                                                                }
                                                            }else{
                                                                if(value.value !== "custom"){
                                                                    this.state.budget[key].percentage = value;
                                                                    this.state.budget[key].daily = (this.state.budget.daily_total * value.value);
                                                                }else{
                                                                    this.state.budget[key].percentage = value;
                                                                }
                                                            }

                                                            this.setState({
                                                                budget: this.state.budget
                                                            }, () => {
                                                                this.functions.save('budget');
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    !this.state.details.noEndDate &&
                                                    <div className="flex flex-1 mr-2">
                                                        <Input
                                                            noBorder={true}
                                                            noTitle={index > 0}
                                                            title={"Budget"}
                                                            type={'number'}
                                                            white={true}
                                                            placeholder={'0'}
                                                            value={this.state.budget[key] ? this.state.budget[key].total : 0}
                                                            size={'full'}
                                                            onChange={(event) => {

                                                                this.state.budget[key].total = +event.target.value;
                                                                this.state.budget[key].percentage = {name: "custom", value: 'custom'};

                                                                //CAMPAIGN DAYS
                                                                let dates = [];
                                                                if(typeof this.state.details === "object" && this.state.details.startDate && this.state.details.endDate){
                                                                    dates = [];
                                                                    var currDate = moment(this.state.details.startDate).startOf('day');
                                                                    var lastDate = moment(this.state.details.endDate).startOf('day');
                                                                    dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                                                                    while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                        dates.push(currDate.clone().toDate());
                                                                    }
                                                                    dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                                                                }

                                                                this.state.budget[key].daily = this.renders.round(this.state.budget[key].total / dates.length);

                                                                this.setState({
                                                                    budget: this.state.budget
                                                                }, () => {
                                                                    this.functions.save('budget');
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className="flex flex-1">
                                                    <Input
                                                        noBorder={true}
                                                        noTitle={index > 0}
                                                        title={"Daily budget"}
                                                        type={'number'}
                                                        white={true}
                                                        placeholder={'0'}
                                                        value={this.state.budget[key] ? this.state.budget[key].daily : 0}
                                                        size={'full'}
                                                        onChange={(event) => {

                                                            this.state.budget[key].daily = +event.target.value;
                                                            this.state.budget[key].percentage = {name: "custom", value: 'custom'};

                                                            if(!this.state.details.noEndDate){
                                                                //CAMPAIGN DAYS
                                                                let dates = [];
                                                                if(typeof this.state.details === "object" && this.state.details.startDate && this.state.details.endDate){
                                                                    dates = [];
                                                                    var currDate = moment(this.state.details.startDate).startOf('day');
                                                                    var lastDate = moment(this.state.details.endDate).startOf('day');
                                                                    dates.push(moment(this.state.details.startDate).startOf('day').clone().toDate());
                                                                    while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                        dates.push(currDate.clone().toDate());
                                                                    }
                                                                    dates.push(moment(this.state.details.endDate).startOf('day').clone().toDate());
                                                                }

                                                                this.state.budget[key].total = this.renders.round(this.state.budget[key].daily * dates.length);
                                                            }

                                                            this.setState({
                                                                budget: this.state.budget
                                                            }, () => {
                                                                this.functions.save('budget');
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.renders.systemFeePercentage() &&
                                                    <div className="flex w-48 ml-2">
                                                        <Input
                                                            noBorder={true}
                                                            title={"System fee (" + this.renders.systemFeePercentage() + "%)"}
                                                            type={'number'}
                                                            white={true}
                                                            disabled={true}
                                                            placeholder={'0'}
                                                            noTitle={index > 0}
                                                            value={this.renders.systemFee(key)}
                                                            size={'full'}
                                                            onChange={(event) => {

                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    <div style={index === 0 ? {marginTop: "18px"}: {}} className="flex w-20 ml-2">
                                                        <Input
                                                            noBorder={true}
                                                            title={""}
                                                            type={'text'}
                                                            white={true}
                                                            noTitle={index > 0}
                                                            disabled={true}
                                                            placeholder={'Set total budget'}
                                                            value={this.renders.currency(key)}
                                                            size={'full'}
                                                            onChange={(event) => {

                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.renders.availableChannels().filter((key) => {
                                return this.renders.checked(key)
                            }).length > 0 &&
                            this.state.next && this.renders.errors().filter((item) => {
                                return item.type === 'channel_budget' || item.type === 'total'
                            }).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                <ul className="list-decimal">
                                    {
                                        this.renders.errors().filter((item) => {
                                            return item.type === 'channel_budget' || item.type === 'total'
                                        }).map((item) => {
                                            return (
                                                <li className="text-xs text-red-500 font-bold">
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex flex-row mt-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) {
                            }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>
                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }} className="btn btn-lg btn-primary inline-flex items-center">
                            Next step: Locations <span className="arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingChannelBudgetNew;
