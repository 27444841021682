import React, { Component } from 'react';
import { CameraIcon, CheckIcon, GiftIcon, SwitchVerticalIcon, VideoCameraIcon, AnnotationIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon, SwitchHorizontalIcon, DeviceMobileIcon, LightBulbIcon, SparklesIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import UploadImageTailwind from './uploadImageTailwind';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import FacebookCTA from '../assets/json/facebook_cta.json';
import tiktokCTA from '../assets/json/tiktok_cta.json';
import linkedinCTA from '../assets/json/linkedin_cta.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { BeatLoader } from 'react-spinners';
import PreviewSectionFacebook from './previewSectionFacebook';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import PreviewSectionTwitter from './previewSectionTwitter';
import PreviewSectionTikTok from './previewSectionTikTok';

class CreateOrderCreativeCollectionEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: false,
            utm: false,
            selected_channels: [],
            utm_channels: [],
            utm_values: {
                facebook: "",
                twitter: "",
                linkedin: "",
                tiktok: "",
            },
            utm_error: {
                facebook: false,
                twitter: false,
                linkedin: false,
                tiktok: false
            },
            type: "",
            files: [],
            assetItems: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            apps: [],
            pixels: [],
            channels: {},
            identity_name: "",
            identity_image: {},
            new_identity: false,
            instagram_post: false,
            ai_suggetion: false,
            ad_type: "collection_ad",
            headline: "",
            body: "",
            name: "",
            name_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: ""
            },
            body_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: ""
            },
            headline_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: ""
            },
            website_by_channels: {
                facebook: "",
                linkedin: "",
                tiktok: "",
                twitter: ""
            },
            preview: {
                facebook: { id: 1, value: "feed", name: "Feed" },
                instagram: { id: 1, value: "feed", name: "Feed" },
                twitter: { id: 1, value: "feed", name: "Feed" },
                linkedin: { id: 1, value: "feed", name: "Feed" },
                tiktok: { id: 1, value: "feed", name: "Feed" }
            },
            previews: {
                facebook: [{ id: 1, value: "feed", name: "Feed" }],
                instagram: [{ id: 1, value: "feed", name: "Feed" }],
                twitter: [{ id: 1, value: "feed", name: "Feed" }],
                linkedin: [{ id: 1, value: "feed", name: "Feed" }],
                tiktok: [{ id: 1, value: "feed", name: "Feed" }],
            },
            collection_catalogs: [],
            collection_catalog: { id: 0, name: "Select catalog" },
            product_sets: [],
            product_set: { id: 0, name: "Select product set" },
            preview_products: [],
            preview_channel: "facebook",
            description: "",
            origin: "",
            caption: "",
            website: "",
            header_title: "",
            button_label: "",
            tracking: {
                app: { id: 0, name: "Click here ..." },
                pixel: { id: 0, name: "No pixel", value: null },
            },
            cta: {
                facebook: { id: 0, name: "Click here ..." },
                tiktok: { id: 0, name: "Click here ..." },
                linkedin: { id: 0, name: "Click here ..." },
                twitter: { id: 0, name: "Click here ..." },
                instagram: { id: 0, name: "" }
            },
            ctas: {
                facebook: FacebookCTA,
                tiktok: tiktokCTA,
                linkedin: linkedinCTA
            },
            page: {
                facebook: { id: 0, name: "Click here ..." },
                tiktok: { id: 0, name: "Click here ..." },
                instagram: { id: 0, name: "Click here ..." },
                linkedin: { id: 0, name: "Click here ..." },
                twitter: { id: 0, name: "Click here ..." }
            },
            pages: {
                facebook: [],
                tiktok: [],
                instagram: [],
                twitter: [],
                linkedin: []
            },
            loading_scraper: false,
            carousel_menu: "Main ad",
            platform: { id: 6, name: "Social Media", value: "Social Media" },
            platforms: [
                { id: 1, name: "Facebook", value: "Facebook" },
                { id: 2, name: "Instagram", value: "Instagram" },
                { id: 3, name: "TikTok", value: "TikTok" },
                { id: 4, name: "Linkedin", value: "Linkedin" },
                { id: 5, name: "Twitter", value: "Twitter" },
                { id: 6, name: "Social Media", value: "Social Media" },
            ],
            channel_menu: "",
            language: { id: 1, name: "English" },
            searchOpenAi: "",
            OpenAiCompare: "",
            OpenAiResult: [],
            loading_suggest: false,
            openAi_error: false,
            amount: 125,
            temperature: { id: 2, name: "Mid", value: 0.5 },
            instantExperience: { id: 0, name: "Click here ..." },
            instant_options: [],
            instant_type: { id: 0, name: "Click here ..." },
            catalog: { id: 0, name: "Click here ..." },
            product_set: { id: 0, name: "Click here ..." },
            instant_types: [
                { id: 1932289657009030, name: "Store Front", value: "store_front" },
                { id: 1369752616394017, name: "Look Book", value: "look_book" },
                { id: 133471657203838, name: "Customer Acquisition", value: "customer_acquisition" }
            ]
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            files: this.props.files ? this.props.files : [],
            carousel: this.props.carousel,
            merge: this.props.merge,
            catalog: this.props.catalog,
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            channels: this.props.channels ? this.props.channels : {},
            client: this.props.client,
            assetItems: this.props.assetItems ? this.props.assetItems : [],
            selected_channels: this.props.selected_channels ? this.props.selected_channels : [],

        });

        if (this.state.assetItems.length > 0) {
            await this.promisedSetState({
                files: this.state.assetItems,
                step: true,
            });
        }
        if (this.state.merge && this.state.merge.error) {
            this.props.onDisable(true);
        }

        //IF ONLY ONE FILE SELETED, PRESET ALL AVALUES
        if (this.state.files.length === 1) {
            await this.promisedSetState({
                headline: this.state.files[0].headline ? this.state.files[0].headline : "",
                body: this.state.files[0].body ? this.state.files[0].body : "",
                name: this.state.files[0].name ? this.state.files[0].name : this.state.name,
                website: this.state.files[0].website ? this.state.files[0].website : "",
                description: this.state.files[0].description ? this.state.files[0].description : "",
                origin: this.state.files[0].origin ? this.state.files[0].origin : "",
                caption: this.state.files[0].caption ? this.state.files[0].caption : "",
                cta: this.state.files[0].cta ? this.state.files[0].cta : this.state.cta,
                page: this.state.files[0].page ? this.state.files[0].page : this.state.page,
                instagram_post: this.state.files[0].instagram_post ? this.state.files[0].instagram_post : false,
                type: this.state.files[0].type ? this.state.files[0].type : "",
                ad_type: this.state.files[0].ad_type ? this.state.files[0].ad_type : this.state.ad_type,
                tracking: this.state.files[0].tracking ? this.state.files[0].tracking : this.state.tracking,
                utm_values: this.state.files[0].utm_values ? this.state.files[0].utm_values : this.state.utm_values,
                OpenAiResult: this.state.files[0].OpenAiResult ? this.state.files[0].OpenAiResult : this.state.OpenAiResult,
                name_by_channels: this.state.files[0].name_by_channels ? this.state.files[0].name_by_channels : this.state.name_by_channels,
                body_by_channels: this.state.files[0].body_by_channels ? this.state.files[0].body_by_channels : this.state.body_by_channels,
                website_by_channels: this.state.files[0].website_by_channels ? this.state.files[0].website_by_channels : this.state.website_by_channels,
                headline_by_channels: this.state.files[0].headline_by_channels ? this.state.files[0].headline_by_channels : this.state.headline_by_channels,
                button_label: this.state.files[0].button_label ? this.state.files[0].button_label : this.state.button_label,
                header_title: this.state.files[0].header_title ? this.state.files[0].header_title : this.state.header_title,
                collection_catalog: this.state.files[0].collection_catalog ? this.state.files[0].collection_catalog : this.state.collection_catalog,
                product_set: this.state.files[0].product_set ? this.state.files[0].product_set : this.state.product_set,
                preview_products: this.state.files[0].preview_products ? this.state.files[0].preview_products : this.state.preview_products,
                instantExperience: this.state.files[0].instantExperience ? this.state.files[0].instantExperience : this.state.instantExperience,
            });
        }


        if (!this.state.step) {
            await this.promisedSetState({
                utm_channels: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] }),
                channel_menu: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] })[0],
                preview_channel: Object.keys(this.state.channels).filter((item) => { return this.state.files[0].allowed_channels[item] })[0],

            });
        } else {
            await this.promisedSetState({
                utm_channels: this.state.selected_channels.filter((item) => { return item !== "google" }),
                channel_menu: this.state.selected_channels.filter((item) => { return item !== "google" })[0],
                preview_channel: this.state.selected_channels.filter((item) => { return item !== "google" })[0],

            });
        }


        //LIST IDENTITIES

        if (this.state.channels.facebook) {
            this.functions.getFacebookCatalogs();
            if (this.state.collection_catalog.id !== 0) {
                this.functions.getFacebookProductSets();
            }
            this.functions.pixels();
            this.functions.listApps();
            this.functions.listFacebookPages();
            if (this.state.page.facebook && this.state.page.facebook.id !== 0 && this.state.instagram_post === false) {
                this.functions.listInstagramAccounts(this.state.page.facebook.id);
            }
            if (this.state.instagram_post) {
                this.state.page.instagram = this.state.files[0].instagram;
                await this.promisedSetState({
                    page: this.state.page
                });
            }
        }

        if (this.state.files.filter((item) => { return item.ratio === "9:16" }).length > 0) {
            this.state.preview.facebook = { id: 2, value: "story", name: "Story" };
            this.state.preview.instagram = { id: 2, value: "story", name: "Story" };
            await this.promisedSetState({
                preview: this.state.preview
            });
        }
        if (this.state.page.facebook && this.state.page.facebook.id !== 0 && this.state.instantExperience && this.state.instantExperience.id !== 0) {
            this.functions.getInstant();
        }
        //console.log("AFTER", this.state)
        this.functions.init();
        if (this.state.OpenAiResult && Array.isArray(this.state.OpenAiResult) && this.state.OpenAiResult.length > 0) {

            this.props.onUpdateChat(this.state.OpenAiResult);
        }
        this.functions.updatePreview();

    }

    functions = {
        init: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '3033879003330818',
                    version: 'v20.0',
                    status: true,
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        updateOpenAi: async (data) => {
            await this.promisedSetState({
                OpenAiResult: data,
            });
        },
        updatePreview: async () => {
            try {
                let buffer = {};
                if (this.state.name_by_channels.facebook === "" || this.state.headline_by_channels.facebook === "" || this.state.body_by_channels.facebook === "" || this.state.page.facebook.id === 0) {
                    buffer.facebook = true;
                }
                this.props.updatePreview({
                    merge: this.state.merge,
                    carousel: this.state.carousel,
                    catalog: this.state.catalog,
                    files: this.state.files,
                    headline_by_channels: this.state.headline_by_channels,
                    body_by_channels: this.state.body_by_channels,
                    cta: this.state.cta,
                    page: this.state.page,
                    website_by_channels: this.state.website_by_channels,
                    description: this.state.description,
                    caption: this.state.caption,
                    preview_channel: this.state.preview_channel,
                    previews: this.state.previews,
                    instantExperience: this.state.instantExperience,
                    snapchat_brandname: "",
                    snapchat_phone: "",
                    snapchat_message: "",
                    snapchat_attachment: "",
                    snapchat_status: "",
                    snapchat_shareable: "",
                    snapchat_autofill: "",
                    snapchat_prefetching: "",
                    utm_channels: this.state.utm_channels,
                    channel_menu: this.state.channel_menu,
                    validation: buffer


                })
            } catch (error) {
            }
        },
        swithChannel: async (channel) => {
            try {
                await this.promisedSetState({
                    channel_menu: channel,
                    preview_channel: channel
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        previewChannel: async (channel) => {
            try {
                await this.promisedSetState({
                    preview_channel: channel
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        addHeadlines: async (data) => {
            try {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].force) {
                        this.state.headline_by_channels[data[i].channel] = data[i].value[0];
                        this.state.channel_menu = data[i].channel;
                        this.state.preview_channel = data[i].channel;
                    } else {
                        if (this.state.headline_by_channels[data[i].channel] === "") this.state.headline_by_channels[data[i].channel] = data[i].value[0];
                    }
                }
                await this.promisedSetState({
                    headline_by_channels: this.state.headline_by_channels,
                    channel_menu: this.state.channel_menu,
                    preview_channel: this.state.preview_channel,
                });
                this.functions.updatePreview();
            } catch (error) {

            }
        },
        removeHeadline: async (data) => {
            try {
                this.state.headline_by_channels[data.channel] = "";
                await this.promisedSetState({
                    headline_by_channels: this.state.headline_by_channels
                });
                this.functions.updatePreview();
            } catch (error) {
            }

        },
        addDescriptions: async (data) => {
            try {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].force) {
                        this.state.body_by_channels[data[i].channel] = data[i].value[0];
                        this.state.channel_menu = data[i].channel;
                        this.state.preview_channel = data[i].channel;
                    } else {
                        if (this.state.body_by_channels[data[i].channel] === "") this.state.body_by_channels[data[i].channel] = data[i].value[0];
                    }
                }
                await this.promisedSetState({
                    body_by_channels: this.state.body_by_channels,
                    channel_menu: this.state.channel_menu,
                    preview_channel: this.state.preview_channel,
                });
                this.functions.updatePreview();
            } catch (error) {
            }
        },
        removeDescription: async (data) => {
            try {
                this.state.body_by_channels[data.channel] = "";
                await this.promisedSetState({
                    body_by_channels: this.state.body_by_channels
                });
                this.functions.updatePreview();
            } catch (error) {
            }

        },
        save: async () => {

            this.state.files = this.state.files.map((item) => {

                item.name = this.state.name !== "" ? this.state.name : item.name;;
                item.headline = this.state.headline !== "" ? this.state.headline : item.headline;
                item.body = this.state.body !== "" ? this.state.body : item.body;
                item.description = this.state.description !== "" ? this.state.description : item.description;
                item.origin = this.state.origin !== "" ? this.state.origin : item.origin;
                item.caption = this.state.caption !== "" ? this.state.caption : item.caption;
                item.website = this.state.website !== "" ? this.state.website : item.website;
                item.OpenAiResult = this.state.OpenAiResult;

                item.utm_values = this.state.utm_values;
                item.name_by_channels = this.state.name_by_channels;
                item.body_by_channels = this.state.body_by_channels;
                item.headline_by_channels = this.state.headline_by_channels;
                item.website_by_channels = this.state.website_by_channels;
                item.product_set = this.state.product_set;
                item.collection_catalog = this.state.collection_catalog;
                item.preview_products = this.state.preview_products;
                item.header_title = this.state.header_title;
                item.button_label = this.state.button_label;
                item.ad_type = this.state.ad_type;
                item.instantExperience = this.state.instantExperience;



                //Tracking
                if (!item.tracking) {
                    item.tracking = JSON.parse(JSON.stringify(this.state.tracking));
                } else {
                    item.tracking.pixel = this.state.tracking.pixel;
                    item.tracking.app = this.state.tracking.app.id !== 0 ? this.state.tracking.app : item.tracking.app;

                }
                //CTA
                if (!item.cta) {
                    item.cta = JSON.parse(JSON.stringify(this.state.cta));
                } else {
                    item.cta.facebook = this.state.cta.facebook.id !== 0 ? this.state.cta.facebook : item.cta.facebook;
                    item.cta.linkedin = this.state.cta.linkedin.id !== 0 ? this.state.cta.linkedin : item.cta.linkedin;
                    item.cta.twitter = this.state.cta.twitter.id !== 0 ? this.state.cta.twitter : item.cta.twitter;
                    item.cta.tiktok = this.state.cta.tiktok.id !== 0 ? this.state.cta.tiktok : item.cta.tiktok;
                }

                //PAGE
                if (!item.page) {
                    item.page = JSON.parse(JSON.stringify(this.state.page));
                } else {
                    item.page.facebook = this.state.page.facebook.id !== 0 ? this.state.page.facebook : item.page.facebook;
                    item.page.linkedin = this.state.page.linkedin.id !== 0 ? this.state.page.linkedin : item.page.linkedin;
                    item.page.twitter = this.state.page.twitter.id !== 0 ? this.state.page.twitter : item.page.twitter;
                    item.page.tiktok = this.state.page.tiktok.id !== 0 ? this.state.page.tiktok : item.page.tiktok;
                    item.page.instagram = this.state.page.instagram.id !== 0 ? this.state.page.instagram : item.page.instagram;
                }

                return item;
            });

            if (this.state.selected_channels.length < 1) {
                this.props.onSave(this.state.files);
            } else {
                let buff = [];
                this.state.files.map((file) => {
                    file.selected = false;
                    this.state.selected_channels.map((item) => {
                        let b = JSON.parse(JSON.stringify(file))
                        b.assetId = b.id;
                        b.id = Math.floor((Math.random() * 9999999999) + 1);
                        b.allowed_channels = { [item]: true }
                        buff.push(b);
                    })
                })
                this.props.onAddCreative(buff);
            }



        },
        scrape: async () => {
            await this.promisedSetState({ loading_scraper: true });
            try {
                let response = await this.calls.scrape();
                this.promisedSetState({
                    description: response.data.description ? response.data.description : this.state.description,
                    origin: response.data.hostname ? response.data.hostname : this.state.origin,
                    loading_scraper: false
                })
            } catch (error) {
                await this.promisedSetState({ loading_scraper: false });
            }
        },
        openAi: async () => {
            let data = {
                text: this.renders.promt_text() + " " + this.state.searchOpenAi,
                temperature: this.state.temperature
            }
            await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data);
                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })
                this.state.OpenAiResult.unshift({ text: response.data[0].text, error: false, hidden: false });
                this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: false
                })
            } catch (error) {
                this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })
                this.state.OpenAiResult.unshift({ text: error.body.message, error: true });
                await this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    loading_suggest: false,
                    openAi_error: true
                });
            }
        },
        instantExperience: () => {
            let self = this;
            //1932289657009030  STORE FRONT
            //1369752616394017 LOOK BOOK
            //133471657203838 CUSTUMER ACQUISITION

            if (self.state.page.facebook && self.state.page.facebook.id !== 0 && self.state.instant_type.id !== 0) {
                window.FB.ui({
                    account_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId.replace("act_", ""),
                    page_id: this.state.page.facebook.id,
                    business_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookBusinessManagerId,
                    display: 'popup',
                    method: 'instant_experiences_builder',
                    template_id: this.state.instant_type.id,
                    product_catalog_id: this.state.collection_catalog.id,
                    product_set_id: this.state.product_set.id,

                }, function (response) {
                    console.log(response, "RESPONSE=======")
                    try {
                        if (response.success) {
                            self.functions.instantCreated(response);
                        }
                        self.setState({ loading_instant: false });
                    } catch (e) {
                        self.setState({ loading_instant: false });
                    }
                })
            }
        },
        instantCreated: async (response) => {
            try {
                this.state.instantExperience = { id: response.id, name: response.id };
                await this.calls.verifyInstant(response.id)
                await this.promisedSetState({
                    instantExperience: this.state.instantExperience
                });
                this.functions.getInstant();
                //console.log(this.state.selected_lead, "selected_lead")
            } catch (error) {
            }
        },
        listInstantExpriences: async () => {
            try {
                let response = await this.calls.listInstantExpriences();
                await this.promisedSetState({
                    instant_options: response.data
                });
            } catch (error) {
            }
        },
        getInstant: async () => {
            try {
                let response = await this.calls.getInstant();
                if (response.data && ((response.data.photo || response.data.video) && (response.data.carousel || response.data.product_set))) {
                    this.state.instantExperience.elements = response.data;
                    await this.promisedSetState({
                        instantExperience: this.state.instantExperience
                    });
                }
                console.log("INSTANT", this.state.instantExperience);

                this.functions.updatePreview();
                //await this.promisedSetState({  });
            } catch (error) { }
        },
        getFacebookCatalogs: async () => {
            await this.promisedSetState({ loading_fb_catalogs: true });
            try {
                let response = await this.calls.facebookCatalogs();
                this.state.collection_catalogs = response.data;
                await this.promisedSetState({ collection_catalogs: this.state.collection_catalogs });
            } catch (error) { }
            await this.promisedSetState({ loading_fb_catalogs: false });
        },
        getFacebookProductSets: async () => {
            await this.promisedSetState({ loading_fb_productsets: true });
            try {
                let response = await this.calls.facebookProductSets(this.state.collection_catalog.id);
                this.state.product_sets = response.data;
                await this.promisedSetState({ product_sets: this.state.product_sets });
            } catch (error) { }
            await this.promisedSetState({ loading_fb_productsets: false });
        },
        getFacebookProducts: async (product_set) => {
            try {
                let response = await this.calls.facebookProducts(product_set);
                this.state.preview_products = response.data;
                await this.promisedSetState({ preview_products: this.state.preview_products });
            } catch (error) { }
        },
        listFacebookPages: async () => {
            try {
                await this.promisedSetState({ loading_facebook: true });
                let response = await this.calls.facebookPages(this.state.facebook_pages_pagination);
                if (response.pagination) {
                    //this.state.facebook_pages_pagination = response.pagination;
                } else {
                    this.state.facebook_pages_pagination = false;
                }
                if (!Array.isArray(this.state.pages.facebook)) {
                    this.state.pages.facebook = [];
                }
                this.state.pages.facebook = Array.isArray(response.data) ? this.state.pages.facebook.concat(response.data.map((item) => { return { name: item.name, id: item.id, image: item.picture.data.url } })) : []
                await this.promisedSetState({
                    pages: this.state.pages,
                    facebook_pages_pagination: this.state.facebook_pages_pagination
                })
            } catch (error) { }
            await this.promisedSetState({ loading_facebook: false });
        },
        getFacebookPage: async (id) => {
            try {
                await this.promisedSetState({ loading_facebook: true });
                let response = await this.calls.facebookPage(id);
                if (response.data) {
                    this.state.page.facebook = response.data;
                    if (response.data.picture) {
                        this.state.page.facebook.image = response.data.picture.data.url;
                    }
                    if (!Array.isArray(this.state.pages.facebook)) {
                        this.state.pages.facebook = [];
                    }
                    this.state.pages.facebook.push(response.data);
                    await this.promisedSetState({
                        page: this.state.page
                    });
                    if (!this.state.instagram_post) this.functions.listInstagramAccounts(this.state.page.facebook.id);
                }
            } catch (error) { }
            await this.promisedSetState({ loading_facebook: false });
        },

        listInstagramAccounts: async (page) => {
            await this.promisedSetState({ loading_instagram: true });
            try {
                let response = await this.calls.instagramAccounts(page);
                this.state.pages.instagram = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic } }) : [];
                this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_instagram: false });
        },
        listInstagramBusinessAccounts: async (page) => {
            await this.promisedSetState({ loading_instagram: true });
            try {
                let response = await this.calls.instagramBusinessAccounts();
                this.state.pages.instagram = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic } }) : [];
                this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_instagram: false });
        },
        pixels: async () => {
            try {
                this.promisedSetState({ loading_pixels: true });
                let response = await this.calls.pixels();
                response.data.unshift({ id: 0, name: "No pixel", value: null });
                this.promisedSetState({ loading_pixels: false, pixels: response.data });
            } catch (error) {
                this.promisedSetState({
                    pixels: []
                })
            }
        },
        listApps: async () => {
            try {
                this.promisedSetState({ loading_apps: true });
                let response = await this.calls.apps();
                let data = [];
                data = data.concat(response.data);
                this.promisedSetState({ loading_apps: false, apps: data });
            } catch (error) {
                this.promisedSetState({
                    apps: []
                })
            }
        }
    };

    calls = {
        openAi: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAi?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        scrape: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', { url: this.state.website });
            let url = apiRegister.url.api + "/v3/adcredo/scrapeUrl";
            return apiRegister.call(options, url);
        },
        facebookCatalogs: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listCatalogs?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        facebookProductSets: (catalog) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listProductSets?client=" + this.state.client.id + "&id=" + catalog;
            return apiRegister.call(options, url);
        },
        facebookProducts: (product_set) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listProducts?client=" + this.state.client.id + "&id=" + product_set;
            return apiRegister.call(options, url);
        },

        listInstantExpriences: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listInstantExpriences?client=" + this.state.client.id + "&page=" + this.state.page.facebook.id;
            return apiRegister.call(options, url);
        },
        getInstant: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/getInstant?client=" + this.state.client.id + "&id=" + this.state.instantExperience.id + "&page=" + this.state.page.facebook.id;
            return apiRegister.call(options, url);
        },
        verifyInstant: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', null);
            let url = apiRegister.url.api + "/v3/facebook/verifyInstant?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        facebookPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id + (this.state.facebook_pages_pagination ? ("&next=" + this.state.facebook_pages_pagination) : "");
            return apiRegister.call(options, url);
        },
        facebookPage: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/getPage?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },

        instagramAccounts: (page) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listInstagramAccounts?client=" + this.state.client.id + "&page=" + page;
            return apiRegister.call(options, url);
        },
        instagramBusinessAccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },

        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        pixels: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPixels?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        promt_text: () => {
            if (this.state.language.id === 5) {
                return "Skriv en kreativ annonse basert på følgende tekst for å kjøre på " + this.state.platform.name + " med maksimalt " + this.state.amount + " tegn:";
            } else if (this.state.language.id === 4) {
                return "Skriv en kreativ annonce baseret på følgende tekst til at køre på " + this.state.platform.name + " med maksimalt " + this.state.amount + " tegn:";
            } else if (this.state.language.id === 3) {
                return "Kirjoita " + this.state.platform.name + " seuraavan tekstin perusteella luova mainos, jossa on enintään " + this.state.amount + " merkkiä:";
            } else if (this.state.language.id === 2) {
                return "Skriv en annonstext baserat på följande text som ska visas på " + this.state.platform.name + " och använda max " + this.state.amount + " karaktärer:";
            } else {
                return "Write a creative ad based on the following text to run on " + this.state.platform.name + " with maximum " + this.state.amount + " characters:";
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        const SortableItem = SortableElement(({ item }) => {
            return (
                <div onClick={() => {
                    if (this.state.carousel && this.state.carousel_menu === "Slides information") {
                        item.selected = !item.selected;
                        this.setState({
                            carousel: this.state.carousel
                        });
                    }
                }} key={item.id} className="flex flex-row justify-center items-center cursor-pointer relative m-3">
                    <div className="flex flex-1 flex-row items-center overflow-hidden">
                        {

                            (item.type === "image" || (item.type === "posts" && item.ad_type === "image")) &&
                            <div className="h-10 w-10 rounded-md relative overflow-hidden">
                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                </div>
                            </div>
                        }
                        {
                            (item.type === "video" || (item.type === "posts" && item.ad_type === "video")) &&
                            <video poster={item.thumbnail} autoPlay={true} muted={true} controls={false} className="h-10 w-10 rounded-md">
                                <source src={item.url} type="video/mp4" />
                            </video>
                        }
                        <div style={{ "paddingTop": "4px" }} className="ml-3 truncate">
                            <p className={(item.selected ? "" : "text-gray-900") + " text-sm font-medium truncate"}>{item.name}</p>
                            <p className={"text-sm text-gray-500 fle flex-row inline-flex items-center justify-center"}>
                                {item.type === "image" && <CameraIcon className="h-4 w-4 mr-1" />}
                                {item.type === "video" && <VideoCameraIcon className="h-4 w-4 mr-1" />}
                                {item.type} - {item.format}
                            </p>
                        </div>
                        <div className="flex flex-1"></div>
                        <div>
                            <div className={"border-1.5 h-10 w-10  ml-2 flex justify-center cursor-pointer items-center rounded-md"}>
                                {
                                    this.state.carousel &&
                                    <SwitchHorizontalIcon className="h-5 w-5" />
                                }
                                {
                                    this.state.merge &&
                                    <div className="text-sm font-medium">
                                        {item.ratio}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({ items }) => (
            <div className="relative flex flex-row">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));

        const onSortEnd = ({ oldIndex, newIndex }) => {
            this.setState(() => ({
                array: arrayMove((this.state.carousel ? this.state.carousel.files : []), oldIndex, newIndex),
            }), () => {
                if (this.state.carousel) {
                    this.state.carousel.files = this.state.array;
                    this.setState({
                        carousel: this.state.carousel
                    })
                }
            });
        };

        return (
            <>
                <div className="grid grid-cols-12 gap-4 bg-gray-50 px-2">
                    <div className="col-span-12 gap-4">
                        <div className="col-span-12 relative flex flex-row bg-white p-4 mx-4 rounded-lg border-blue-200 border-dashed border-4 overflow-x-scroll">
                            {
                                !this.state.carousel && !this.state.merge &&
                                this.state.files.map((item) => (
                                    <div onClick={() => {
                                        if (this.state.carousel && this.state.carousel_menu === "Slides information") {
                                            item.selected = !item.selected;
                                            this.setState({
                                                carousel: this.state.carousel
                                            });
                                        }
                                    }} key={item.id} className="flex flex-row justify-center items-center overflow-hidden">
                                        <div className="flex flex-1 flex-row items-center overflow-hidden">
                                            {
                                                (item.type === "image" || (item.type === "posts" && item.ad_type === "image" && !item.files)) &&
                                                <img className={(item.selected ? "" : "") + " h-10 w-10 rounded-md"} src={item.url} alt="" />
                                            }
                                            {
                                                (item.type === "video" || (item.type === "posts" && item.ad_type === "video" && !item.files)) &&
                                                <video poster={item.thumbnail} autoPlay={false} muted={true} controls={false} className="h-10 w-10 rounded-md">
                                                    <source src={item.url} type="video/mp4" />
                                                </video>
                                            }
                                            {
                                                (item.type === "posts" && item.files) &&
                                                <div className="h-10 w-10 rounded-md grid grid-cols-2 grid-rows-2 overflow-hidden border">
                                                    {
                                                        item.files.filter((item, index) => { return index < 4 }).map((item) => {
                                                            return (
                                                                <div className="w-full h-full overflow-hidden">
                                                                    {
                                                                        (item.type === "image" || item.type === "IMAGE") &&
                                                                        <div className="w-full h-full relative">
                                                                            <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (item.type === "video" || item.type === "VIDEO") &&
                                                                        <video poster={item.thumbnail ? item.thumbnail : ""} autoPlay={true} muted={true} controls={false} className={"w-full h-full"}>
                                                                            <source src={item.url} type="video/mp4" />
                                                                        </video>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                            <div style={{ "paddingTop": "4px" }} className="ml-3 truncate">
                                                <p className={(item.selected ? "" : "text-gray-900") + " text-sm font-medium truncate"}>{item.name}</p>
                                                <div className="flex flex-row items-center mr-1">
                                                    <p className={"text-sm text-gray-500 fle flex-row inline-flex items-center justify-center"}>
                                                        {item.type === "image" && <CameraIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "video" && <VideoCameraIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "catalog" && <GiftIcon className="h-4 w-4 mr-1" />}
                                                        {item.type === "posts" && <AnnotationIcon className="h-4 w-4 mr-1" />}
                                                        {item.type}
                                                    </p>
                                                    {
                                                        item.channel &&
                                                        <div className={"bg-" + item.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center ml-1"}>

                                                            {
                                                                item.channel === 'facebook' &&
                                                                <img className="w-1"
                                                                    alt=""
                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-1"></div>

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>



                    <div className="col-span-12">


                        <div className="block w-full mb-4 px-2 mt-3">
                            <div className=" border-gray-200 w-full">
                                <nav className="-mb-px flex" aria-label="Tabs">
                                    {["Main ad", "Instant Experience"].map((item) => (
                                        <div
                                            onClick={() => {
                                                this.setState({
                                                    carousel_menu: item
                                                })
                                            }}
                                            key={item}
                                            className={classNames(
                                                item === this.state.carousel_menu
                                                    ? 'border-purple-500 text-purple-600'
                                                    : 'border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400',
                                                'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md mx-2'
                                            )}
                                            aria-current={item === this.state.carousel_menu ? 'page' : undefined}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        {
                            this.state.carousel_menu === "Main ad" &&

                            <div className="grid grid-cols-6 gap-4 px-2">
                                {
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        <div className="col-span-6">
                                            <InputTailwind
                                                error={this.state.name_by_channels[item] === "" ? true : false}
                                                channel={item}
                                                label={"Name"}
                                                value={this.state.name_by_channels[item]}
                                                onChange={async (value) => {
                                                    this.state.name_by_channels[item] = value;
                                                    this.promisedSetState({
                                                        name_by_channels: this.state.name_by_channels
                                                    })
                                                }}
                                            />
                                        </div>
                                    ))
                                }




                                <div className="col-span-6 text-lg font-semibold">
                                    Select identity
                                </div>

                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook page"}
                                            searchInput={true}
                                            error={this.state.page.facebook.id === 0 ? true : false}
                                            pagination={this.state.facebook_pages_pagination}
                                            loader={this.state.loading_facebook}
                                            selected={this.state.page.facebook}
                                            options={this.state.pages.facebook}
                                            onPagination={() => {
                                                this.functions.listFacebookPages();
                                            }}
                                            onChange={async (page) => {
                                                this.state.page.facebook = page;
                                                if (!this.state.instagram_post) this.state.page.instagram = { id: 0, name: "Click here ..." };
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                this.functions.listInstantExpriences();
                                                if (!this.state.instagram_post) this.functions.listInstagramAccounts(this.state.page.facebook.id);
                                                this.functions.updatePreview();
                                                /*
                                                if (this.state.type === "posts" && this.state.instagram_post) {
                                                    this.functions.listInstagramBusinessAccounts();
                                                } else {
                                                    this.functions.listInstagramAccounts(this.state.page.facebook.id);
                                                }*/
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook && this.state.channel_menu === "facebook" &&
                                    <div className={(this.state.page.facebook.id === 0 ? "opacity-50 cursor-not-allowed" : "") + " col-span-3"}>
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Instagram account"}
                                            searchInput={true}
                                            locked={this.state.files[0].instagram_post}
                                            disabled={this.state.page.facebook.id === 0 || this.state.files[0].instagram_post}
                                            loader={this.state.loading_instagram}
                                            selected={this.state.page.instagram}
                                            options={this.state.pages.instagram}
                                            onChange={async (page) => {
                                                this.state.page.instagram = page;
                                                await this.promisedSetState({
                                                    page: this.state.page
                                                });
                                                this.functions.updatePreview();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.channels.facebook && !this.state.loading_facebook && this.state.pages.facebook.length < 1 && this.state.channel_menu === "facebook" &&
                                    <div className="col-span-6">
                                        <div className="rounded-md font-medium text-sm bg-orange-100 text-orange-500 p-4">
                                            <div className="mb-2">
                                                Facebook is having a temporary error with locating pages, please type in your page ID in input field below.
                                            </div>
                                            <div className="mb-4">
                                                <InputTailwind
                                                    label={"Page ID"}
                                                    value={this.state.facebook_page_id}
                                                    onChange={async (value) => {
                                                        this.promisedSetState({
                                                            facebook_page_id: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div onClick={async () => {
                                                    if (!this.state.loading_facebook) {
                                                        this.functions.getFacebookPage(this.state.facebook_page_id);
                                                    }
                                                }} className="rounded-full font-medium cursor-pointer inline-flex bg-orange-500 text-white hover:bg-orange-600 py-2 px-6">
                                                    Add page
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }

                                <div className="col-span-6 text-lg font-semibold">
                                    Ad settings
                                </div>


                                {
                                    !this.state.instagram_post &&
                                    !this.state.merge &&
                                    !this.state.catalog &&
                                    this.state.channel_menu !== "tiktok" &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => (
                                        <div className="col-span-6 relative">
                                            <InputTailwind
                                                error={this.state.headline_by_channels[item] === "" ? true : false}
                                                channel={item}
                                                label={"Headline"}
                                                disabled={this.state.type === "posts" ? true : false}
                                                locked={this.state.type === "posts" ? true : false}
                                                value={this.state.headline_by_channels[item]}
                                                onChange={async (value) => {
                                                    this.state.headline_by_channels[item] = value;
                                                    await this.promisedSetState({
                                                        headline: value
                                                    })
                                                    this.functions.updatePreview();
                                                }}
                                            />
                                            {
                                                this.state.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === item && inner_item.value.includes(this.state.headline_by_channels[item]) }).length > 0 }).length > 0 &&
                                                <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                    <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                </div>
                                            }
                                        </div>
                                    ))


                                }

                                {
                                    !this.state.merge &&
                                    this.state.utm_channels.filter((item) => { return item === this.state.channel_menu }).map((item) => {
                                        if (item === "facebook") {
                                            return (
                                                <div className="col-span-6">
                                                    <div className="grid grid-cols-6 gap-4 ">
                                                        <div className={"col-span-6 relative"}>
                                                            <TextAreaTailwind
                                                                error={this.state.body_by_channels[item] === "" ? true : false}
                                                                disabled={this.state.type === "posts" ? true : false}
                                                                locked={this.state.type === "posts" ? true : false}
                                                                channel={item}
                                                                label={"Primary text"}
                                                                value={this.state.body_by_channels[item]}
                                                                onChange={async (value) => {
                                                                    this.state.body_by_channels[item] = value
                                                                    await this.setState({
                                                                        body_by_channels: this.state.body_by_channels
                                                                    })
                                                                    this.functions.updatePreview();
                                                                }}
                                                            />
                                                            {
                                                                this.state.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === item && inner_item.value.includes(this.state.body_by_channels[item]) }).length > 0 }).length > 0 &&
                                                                <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                    <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }



                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-6 text-lg font-semibold">
                                        Tracking settings
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook tracking website events"}
                                            searchInput={true}
                                            selected={this.state.tracking.pixel}
                                            options={this.state.pixels}
                                            onChange={async (pixel) => {
                                                this.state.tracking.pixel = pixel;
                                                await this.promisedSetState({
                                                    tracking: this.state.tracking
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.merge &&
                                    (!this.state.catalog || (this.state.catalog && this.state.catalog.channel === "facebook")) &&
                                    this.state.channels.facebook &&
                                    this.state.channel_menu === "facebook" &&
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            channel="facebook"
                                            label={"Facebook tracking app events"}
                                            searchInput={true}
                                            selected={this.state.tracking.app}
                                            options={this.state.apps}
                                            onChange={async (app) => {
                                                this.state.tracking.app = app;
                                                await this.promisedSetState({
                                                    tracking: this.state.tracking
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.carousel_menu === "Instant Experience" &&
                            <div className="grid grid-cols-6 gap-4">
                                {
                                    this.state.page.facebook.id === 0 &&
                                    <div className="col-span-6 bg-orange-100 rounded-md p-4 text-sm font-medium text-orange-500 my-2">
                                        OBS! Choose a Facebook page to create a Instant Experience
                                    </div>
                                }
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        channel="facebook"
                                        label={"Instant Experience"}
                                        locked={this.state.page.facebook.id === 0}
                                        disabled={this.state.page.facebook.id !== 0 ? false : true}
                                        placeholder={"Click to search ..."}
                                        selected={this.state.instantExperience}
                                        options={this.state.instant_options}
                                        onChange={async (instant) => {
                                            await this.promisedSetState({
                                                instantExperience: instant
                                            })
                                            this.functions.getInstant();

                                        }}
                                    />
                                </div>
                                <div className="col-span-6 text-lg font-semibold">
                                    New Instant Experience
                                </div>
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        channel="facebook"
                                        label={"Select type"}
                                        locked={this.state.page.facebook.id === 0 ? true : false}
                                        disabled={this.state.page.facebook.id === 0 ? true : false}
                                        selected={this.state.instant_type}
                                        options={this.state.instant_types}
                                        onChange={async (instant) => {
                                            await this.promisedSetState({
                                                instant_type: instant
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        channel="facebook"
                                        label={"Select catalog"}
                                        locked={this.state.instant_type.id === 0 ? true : false}
                                        disabled={this.state.instant_type.id === 0 ? true : false}
                                        loader={this.state.loading_fb_catalogs}
                                        selected={this.state.collection_catalog}
                                        options={this.state.collection_catalogs}
                                        onChange={async (catalog) => {
                                            await this.promisedSetState({
                                                collection_catalog: catalog
                                            })
                                            this.functions.getFacebookProductSets();
                                        }}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        channel="facebook"
                                        label={"Select Product Set"}
                                        loader={this.state.loading_fb_productsets}
                                        locked={this.state.collection_catalog.id === 0 ? true : false}
                                        disabled={this.state.collection_catalog.id === 0 ? true : false}
                                        selected={this.state.product_set}
                                        options={this.state.product_sets}
                                        onChange={async (item) => {
                                            await this.promisedSetState({
                                                product_set: item
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <button
                                        onClick={async () => {
                                            if (this.state.page.facebook.id !== 0 && this.state.instant_type.id !== 0 && this.state.collection_catalog.id !== 0 && this.state.product_set.id !== 0) {
                                                await this.promisedSetState({
                                                    loading_instant: true
                                                });
                                                this.functions.instantExperience();
                                            }
                                        }}
                                        className={(this.state.page.facebook.id === 0 || this.state.instant_type.id === 0 || this.state.collection_catalog.id === 0 || this.state.product_set.id === 0 ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + " py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 inline-flex shadow relative justify-center rounded-md border border-transparent "}
                                    >
                                        Create new
                                        {
                                            this.state.loading_instant &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>

                            </div>
                        }



                    </div>
                    {/*
                    <div className="col-span-5 border-l-1.5 pl-6">
                        <div className="grid grid-cols-6 gap-4 rounded-md bg-gray-100">
                            <div className="col-span-6 mb-2 inline-flex pt-5 pl-3">
                                <div onClick={async () => {
                                    if (this.state.ai_suggetion) {
                                        await this.promisedSetState({
                                            ai_suggetion: false
                                        })
                                    }
                                }} className={"col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden text-white " + (this.state.ai_suggetion ? "cursor-pointer bg-gray-300 hover:bg-gray-400 " : " bg-purple-500")}>
                                    {<DeviceMobileIcon className="h-4 w-4 mr-1" />}
                                    Preview
                                </div>
                                <div onClick={async () => {
                                    if (!this.state.ai_suggetion) {
                                        await this.promisedSetState({
                                            ai_suggetion: true
                                        })
                                    }
                                }} className={" ml-5 col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden text-white " + (!this.state.ai_suggetion ? "cursor-pointer bg-gray-300 hover:bg-gray-400 " : " bg-purple-500")}>
                                    {<LightBulbIcon className="h-4 w-4 mr-1" />}
                                    AI
                                </div>
                            </div>


                            {/* PREVIEW /*\/}
                            {
                                !this.state.ai_suggetion &&
                                <div className="grid grid-cols-6 col-span-6 gap-4">
                                    <div className="col-span-6 text-lg font-semibold pr-5 pl-5">
                                        PREVIEW
                                    </div>
                                    <div className="col-span-6 pr-5 pl-5 pb-5 flex flex-row">
                                        {
                                            this.state.utm_channels.map((channel) => {
                                                return (
                                                    <>
                                                        <div onClick={async () => {
                                                            await this.promisedSetState({
                                                                preview_channel: channel,
                                                                channel_menu: channel === "instagram" ? "facebook" : channel
                                                            });
                                                        }}
                                                            className={(channel === this.state.preview_channel ? ("bg-" + channel + "-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " mr-4 py-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>

                                                            {
                                                                <span
                                                                    className={"px-4 pl-2 text-sm font-medium w-24 text-center capitalize"}>
                                                                    {channel}
                                                                </span>
                                                            }
                                                        </div>
                                                        {
                                                            channel === 'facebook' ? <div onClick={async () => {
                                                                await this.promisedSetState({
                                                                    preview_channel: "instagram"
                                                                });

                                                            }}
                                                                className={(this.state.preview_channel === "instagram" ? ("bg-instagram-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " mr-4 py-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>

                                                                {
                                                                    <span
                                                                        className={"px-4 pl-2 text-sm font-medium w-24 text-center capitalize"}>
                                                                        Instagram
                                                                    </span>
                                                                }
                                                            </div> : null
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !this.state.ai_suggetion &&
                                <div className="col-span-6 grid grid-cols-6 gap-4">
                                    <div className="col-span-2 pl-5">
                                        <DropdownTailwind
                                            channel={this.state.preview_channel}
                                            label={"Preview"}
                                            selected={this.state.preview[this.state.preview_channel]}
                                            options={this.state.previews[this.state.preview_channel]}
                                            onChange={(value) => {
                                                this.state.preview[this.state.preview_channel] = value;
                                                this.setState({
                                                    preview: this.state.preview
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-6 pr-5 pl-5 pb-5 flex flex-row overflow-x-auto">
                                        {
                                            !this.state.merge &&
                                            this.state.files.map((item) => {
                                                let ad = {
                                                    collection_ad: true,
                                                    picture: this.state.instantExperience.elements && this.state.instantExperience.elements.photo ? this.state.instantExperience.elements.photo : (item.type === "image" ? item.url : null),
                                                    video: this.state.instantExperience.elements && this.state.instantExperience.elements.video ? this.state.instantExperience.elements.video : (item.type === "video" ? item.url : null),
                                                    colors: item.colors ? item.colors : [],
                                                    title: (this.state.preview_channel === "instagram" ? this.state.headline_by_channels.facebook : this.state.headline_by_channels[this.state.preview_channel]),
                                                    body: item.type === "posts" ? item.body : (this.state.preview_channel === "instagram" ? this.state.body_by_channels.facebook : this.state.body_by_channels[this.state.preview_channel]),
                                                    description: this.state.description,
                                                    caption: this.state.caption,
                                                    link: this.state.website_by_channels[this.state.preview_channel],
                                                    cta: this.state.preview_channel === "instagram" && this.state.cta.facebook && this.state.cta.facebook.id !== 0 ? this.state.cta.facebook : (this.state.cta[this.state.preview_channel] && this.state.cta[this.state.preview_channel].id !== 0 ? this.state.cta[this.state.preview_channel] : { id: 0, name: "No Button", type: "NO_BUTTON" }),
                                                    page: this.state.preview_channel === "instagram" ? (this.state.page.instagram.id !== 0 ? this.state.page.instagram : { id: 0, name: "Select page" }) : (this.state.page[this.state.preview_channel] && this.state.page[this.state.preview_channel].id !== 0 ? this.state.page[this.state.preview_channel] : { id: 0, name: "Select page" }),
                                                    ad_type: ({ id: 1, name: "Catalog ad", value: "catalog_ad" }),
                                                    preview: (
                                                        (this.state.catalog || this.state.carousel) ?
                                                            { value: "carousel_feed" } :
                                                            (item.type === "image" ?
                                                                { value: "image_" + this.state.preview[this.state.preview_channel].value } :
                                                                (item.type === "video" ?
                                                                    { value: "video_" + this.state.preview[this.state.preview_channel].value } :
                                                                    (item.type === "posts" && item.files ?
                                                                        { value: "carousel_feed" } :
                                                                        (item.ad_type && item.ad_type === "image" ?
                                                                            { value: "image_feed" } :
                                                                            { value: "video_feed" }
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                    ),
                                                    instantExperience: this.state.instantExperience,
                                                    slides: [],
                                                    files: item.type === "posts" && item.files ? item.files : (this.state.carousel ? this.state.carousel.files : false),
                                                    file: item
                                                };

                                                return (
                                                    <div className="mr-4">
                                                        {

                                                            (this.state.preview_channel === "facebook" || this.state.preview_channel === "instagram") &&

                                                            <PreviewSectionFacebook
                                                                ad={ad}
                                                                channel={{ name: this.state.preview_channel, value: this.state.preview_channel }}
                                                            />
                                                        }
                                                    </div>
                                                )
                                            })

                                        }
                                    </div>
                                </div>
                            }


                            {/* OPEN AI *\/}
                            {
                                this.state.ai_suggetion &&
                                <div className="grid grid-cols-6 col-span-6 gap-4">
                                    <div className="col-span-6 text-lg font-semibold p-5">
                                        AI Suggestions
                                    </div>
                                    <div className="grid grid-cols-6 gap-4 col-span-6 pl-5 pr-5">
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose language"}
                                                selected={this.state.language}
                                                options={
                                                    [
                                                        { id: 1, name: "English" },
                                                        { id: 2, name: "Swedish" },
                                                        { id: 3, name: "Finnish" },
                                                        { id: 4, name: "Danish" },
                                                        { id: 5, name: "Norwegian" },
                                                    ]}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        language: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose platform"}
                                                selected={this.state.platform}
                                                options={this.state.platforms}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        platform: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        false &&
                                        //device-mobile preview icon
                                        //light-bulb AI icon
                                        <div className="col-span-6">
                                            <div className="rounded-md text-sm bg-gray-100 p-4">
                                                <div className="mb-2">
                                                    {this.renders.promt_text()}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="grid grid-cols-6 gap-4 col-span-6 pl-5 pr-5">
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"Choose randomness"}
                                                selected={this.state.temperature}
                                                options={[
                                                    { id: 1, name: "Low", value: 0 },
                                                    { id: 2, name: "Mid", value: 0.5 },
                                                    { id: 3, name: "High", value: 1 },
                                                ]}
                                                onChange={async (value) => {
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        temperature: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTailwind
                                                label={"Set max characters"}
                                                value={this.state.amount}
                                                onChange={async (value) => {
                                                    value = value.replace(/(\D+)/g, '')
                                                    await this.promisedSetState({
                                                        OpenAiCompare: "",
                                                        amount: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-6 pl-5 pr-5">
                                        <TextAreaTailwind
                                            label={"What do you want suggestions for?"}
                                            value={this.state.searchOpenAi}
                                            onChange={(value) => {
                                                this.setState({
                                                    searchOpenAi: value
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="col-span-6 mb-2 inline-flex pl-5 pr-5">

                                        <div onClick={async () => {
                                            if (this.state.searchOpenAi === "" || ((this.state.searchOpenAi === this.state.OpenAiCompare) && ((this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error) || this.state.OpenAiResult.length === 0))) {

                                            } else {
                                                await this.promisedSetState({
                                                    OpenAiCompare: this.state.searchOpenAi
                                                })
                                                this.functions.openAi();
                                            }
                                        }} className={"col-span-3 flex flex-row items-center text-sm font-medium rounded-full px-4 py-2 relative overflow-hidden bg-white " + (this.state.searchOpenAi === "" || ((this.state.searchOpenAi === this.state.OpenAiCompare) && ((this.state.OpenAiResult.length > 0 && !this.state.OpenAiResult[0].error) || this.state.OpenAiResult.length === 0)) ? "" : " bg-purple-500 cursor-pointer text-white hover:bg-purple-600")}>
                                            Suggest
                                            {
                                                this.state.loading_suggest &&
                                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    {
                                        this.state.OpenAiResult.length > 0 &&
                                        <div className="col-span-6 pl-5 pr-5">
                                            <div className="col-span-6 text-lg font-semibold">
                                                AI result
                                            </div>

                                            {
                                                false &&
                                                <div onClick={async () => {
                                                    this.state.OpenAiResult = this.state.OpenAiResult.map((item) => {
                                                        item.hidden = true
                                                        return item;
                                                    })

                                                    await this.promisedSetState({
                                                        OpenAiResult: this.state.OpenAiResult
                                                    })
                                                }} className=" absolute w-80">
                                                    1
                                                </div>

                                            }

                                            {
                                                this.state.OpenAiResult.map((item, index) => (
                                                    <div className={"col-span-6 m-4"}>
                                                        {index == 1 ? <div className="col-span-6 m-2 text-gray-700 text-base">History</div> : ""}
                                                        <div className={"rounded-md text-sm  p-4" + (item.error ? " bg-red-200 text-red-600" : " bg-gray-50")}>
                                                            <div className="mb-2">
                                                                {item.text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }


                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                    */}

                </div>
            </>
        )
    }
}

export default CreateOrderCreativeCollectionEdit;
