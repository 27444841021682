import React, {Component} from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import Loader from "../loader";

class CampaignEditBudget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: null,
            budget: {},
            channels: {},
            init_budget: {},
            errors: [],
            loading: false
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            channels: this.props.channels,
            budget: JSON.parse(JSON.stringify(this.props.budget)),
            init_budget: JSON.parse(JSON.stringify(this.props.budget))
        }, () => {
            if(this.state.channels.fb_insta_combined){
                this.state.budget.fb_insta_combined = this.state.budget.facebook;
                delete this.state.budget.facebook;
                delete this.state.budget.instagram;
                this.state.init_budget.fb_insta_combined = this.state.init_budget.facebook;
                delete this.state.init_budget.facebook;
                delete this.state.init_budget.instagram;
                this.setState({
                    init_budget: this.state.init_budget,
                    budget: this.state.budget
                })
            }
        })
    }

    init = {};

    functions = {
        update: () => {
            if (this.renders.change()) {
                this.setState({
                    loading: true
                }, () => {
                    let budget = {};
                    for (let key in this.state.budget) {
                        if(key === 'fb_insta_combined'){
                            budget.facebook = this.state.budget.fb_insta_combined.daily_budget;
                        }else{
                            budget[key] = this.state.budget[key].daily_budget;
                        }
                    }
                    calls.updateBudget(this.state.campaign, {budget: budget}).then((response) => {
                        this.state.errors = [];
                        if(this.state.channels.fb_insta_combined){
                            if(response.data.facebook){
                                response.data.fb_insta_combined = response.data.facebook;
                                delete response.data.facebook;
                                delete response.data.instagram;
                            }
                        }
                        for (let key in response.data) {
                            if (response.data[key].error) {
                                if(key === 'fb_insta_combined'){
                                    this.state.errors.push({channel: 'facebook / instagram', message: response.data[key].message});
                                    this.state.budget[key].daily_budget = this.state.init_budget[key].daily_budget;
                                }else{
                                    this.state.errors.push({channel: key, message: response.data[key].message});
                                    this.state.budget[key].daily_budget = this.state.init_budget[key].daily_budget;
                                }
                            } else {
                                this.state.init_budget[key].daily_budget = this.state.budget[key].daily_budget;
                            }
                        }
                        this.setState({
                            loading: false,
                            errors: this.state.errors,
                            budget: this.state.budget,
                            init_budget: this.state.init_budget,
                            success_modal: this.state.errors.length < 1
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    })
                })
            } else {
                this.setState({
                    warning_modal: true
                })
            }
        }
    };

    renders = {
        change: () => {
            try {
                let changes = false;
                for (let key in this.state.init_budget) {
                    if (this.state.init_budget[key].daily_budget !== this.state.budget[key].daily_budget) {
                        changes = true;
                    }
                }
                return changes;
            } catch (e) {
                return false
            }
        },
        title: (key) => {
            if(key === 'fb_insta_combined'){
                return 'facebook / instagram'
            }else{
                return key;
            }
        }
    };

    render() {
        return (
            <div className='campaign-edit-duration'>
                {
                    this.state.loading &&
                    <Loader
                        title={'Updating budget'}
                    />
                }
                <SweetAlert
                    show={this.state.success_modal}
                    title="Budget updated"
                    type="success"
                    text={"Click OK to continue"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="No change to budget"
                    type="warning"
                    text={"Click OK to continue"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div className='campaign-budget-duration-top'>
                    BUDGET
                </div>
                <div className="campaign-budget-duration-inner">
                    <div className="RelatedSites-items">
                        <div className="Channels-itemsItemsTitles">
                            <div className="Channels-itemsItemsTitlesChannel">
                                Channels
                            </div>
                            <div className="Channels-itemsItemsTitlesBudgetDaily">
                                Daily budget
                            </div>
                            <div className="Channels-itemsItemsTitlesCurrency">
                                Currency
                            </div>
                        </div>
                        {
                            Object.keys(this.state.budget).map((key, index) => {
                                return (
                                    <div className="Channels-itemsItem" key="index">
                                        <div className="Channels-itemsItemChannel">
                                            <div className="Channels-itemsItemChannelName">
                                                {
                                                    key === 'google' &&
                                                    <div style={{marginRight: '7px'}}>
                                                        <IconGoogle noMargin={true}/>
                                                    </div>
                                                }
                                                {
                                                    (key === 'facebook' || key === 'fb_insta_combined') &&
                                                    <div style={{marginRight: '7px'}}>
                                                        <IconFacebook noMargin={true}/>
                                                    </div>
                                                }
                                                {
                                                    (key === 'instagram' || key === 'fb_insta_combined') &&
                                                    <div style={{marginRight: '7px'}}>
                                                        <IconInstagram noMargin={true}/>
                                                    </div>
                                                }
                                                <div style={{textTransform: 'capitalize'}}
                                                     className="RelatedSites-itemsItemTitle">
                                                    {this.renders.title(key)}
                                                </div>
                                            </div>
                                            <div className="Channels-itemsItemChannelSlider">
                                                <Switch
                                                    onColor="#24bda8"
                                                    checked={true}
                                                    disabled={true}
                                                    onChange={(checked) => {

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="Channels-itemsItemBudgetDaily">
                                            <Input
                                                noTitle={true}
                                                type={'number'}
                                                disabled={false}
                                                value={this.state.budget[key].daily_budget}
                                                size={'full'}
                                                onChange={(event) => {
                                                    this.state.budget[key].daily_budget = event.target.value;
                                                    this.setState({
                                                        budget: this.state.budget
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="Channels-itemsItemCurrency">
                                            {this.state.budget[key].currency}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.errors.length > 0 &&
                        <div style={{marginTop: '15px'}} className="SectionError">
                            {
                                this.state.errors.map((item) => {
                                    return (
                                        <div><b>{item.channel}</b>{" "}{item.message}</div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        <div onClick={() => {
                            if (!this.state.loading) {
                                this.functions.update();
                            }
                        }} style={{marginTop: '15px'}} className="Button ButtonFull ButtonNeutral">
                            <div>Update budget</div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignEditBudget;
