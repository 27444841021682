import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import Switch from 'react-ios-switch';
import IconFacebook from "../../icons/facebook";
import IconInstagram from "../../icons/instagram";

class TargetingPlacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            creatives: {},
            channels: {},
            adset: {},
            positions: [
                {
                    title: 'All recommended',
                    channels: {facebook: true, instagram: true},
                    key: 'all'
                },
                {
                    title: 'Feed',
                    channels: {facebook: false, instagram: true},
                    key: 'stream_instagram'
                },
                {
                    title: 'Story',
                    channels: {facebook: false, instagram: true},
                    key: 'story_instagram',
                    vertical: true
                },
                {
                    title: 'Reels',
                    channels: {facebook: false, instagram: true},
                    key: 'reels_instagram'
                },
                {
                    title: 'Explore',
                    channels: {facebook: false, instagram: true},
                    key: 'explore_instagram'
                },
                {
                    title: 'Feed',
                    channels: {facebook: true, instagram: false},
                    key: 'feed'
                },
                {
                    title: 'Story',
                    channels: {facebook: true, instagram: false},
                    key: 'story',
                    vertical: true
                },
                {
                    title: 'Right hand column',
                    channels: {facebook: true, instagram: false},
                    key: 'right_hand_column'
                },
                {
                    title: 'Instant article',
                    channels: {facebook: true, instagram: false},
                    key: 'instant_article'
                },
                {
                    title: 'Marketplace',
                    channels: {facebook: true, instagram: false},
                    key: 'marketplace'
                },
                {
                    title: 'Messenger Inbox',
                    channels: {facebook: true, instagram: false},
                    key: 'messenger_inbox'
                },
                {
                    title: 'Messenger Story',
                    channels: {facebook: true, instagram: false},
                    key: 'messenger_story',
                    vertical: true
                },
                {
                    title: 'Video feeds',
                    channels: {facebook: true, instagram: false},
                    key: 'video_feeds'
                },
                {
                    title: 'Search',
                    channels: {facebook: true, instagram: false},
                    key: 'search'
                },
                {
                    title: 'Audience network rewarded video',
                    channels: {facebook: true, instagram: false},
                    key: 'audience_rewarded_video'
                },
                {
                    title: 'Audience network classic',
                    channels: {facebook: true, instagram: false},
                    key: 'audience_classic'
                },
                {
                    title: 'Audience instream video',
                    channels: {facebook: true, instagram: false},
                    key: 'audience_instream_video'
                }
            ]
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting,
            creatives: nextProps.creatives,
            channels: nextProps.channels,
            adset: nextProps.adset?nextProps.adset:{}
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting,
            creatives: this.props.creatives,
            channels: this.props.channels,
            adset: this.props.adset?this.props.adset:{}
        });
    }

    init = {};

    functions = {};

    renders = {
        filter: (item) => {
            if(this.state.targeting.placement && this.state.targeting.placement.all && item.key !== 'all'){
                return false
            }else if(this.renders.type() === 'vertical' && !item.vertical){
                return false
            }else{
                return true
            }
        },
        type: () => {
            let type = "normal";
            if(this.state.creatives.dynamic_ads && this.state.creatives.dynamic_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id}).length > 0){
                type = "dynamic";
            }else if(this.state.creatives.video_ads && this.state.creatives.video_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id && this.state.adset.type === 'vertical'}).length > 0){
                type = "vertical";
            }else if(this.state.creatives.image_ads && this.state.creatives.image_ads.filter((item) => {return item.internal_adset && item.internal_adset.id === this.state.adset.id && this.state.adset.type === 'vertical'}).length > 0){
                type = "vertical";
            }
            return type;
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    this.state.targeting.placement &&
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%'}}>
                            <div className="WeekDaysTitle">
                                <div className="font-bold text-xs">
                                    Placement
                                </div>
                            </div>
                            <div style={{backgroundColor: "#fcfcfc"}} className="WeekDaysItems bg-white">
                                <div style={{with: '100%'}}>
                                    {
                                        this.state.positions.filter((item) => {
                                            return this.renders.filter(item)
                                        }).map((item, index) => {
                                            return(
                                                <div>
                                                    <div className="WeekDaysItems-Item">
                                                        <div style={{marginLeft: '0px'}} className="WeekDaysItems-ItemTitle">
                                                            <div style={{marginRight: '3px'}}>{item.title}</div>
                                                            {
                                                                item.channels.facebook &&
                                                                <div style={{marginLeft: '3px'}}>
                                                                    <IconFacebook noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                item.channels.instagram &&
                                                                <div style={{marginLeft: '3px'}}>
                                                                    <IconInstagram noMargin={true}/>
                                                                </div>
                                                            }
                                                            {
                                                                item.vertical &&
                                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical
                                                                    format
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="WeekDaysItems-ItemSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={this.state.targeting.placement[item.key]}
                                                                onChange={(checked) => {
                                                                    this.state.targeting.placement[item.key] = checked;
                                                                    this.setState({
                                                                        targeting: this.state.targeting
                                                                    }, () => {
                                                                        this.props.onChange(this.state.targeting);
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        index !== (this.state.positions.filter((item) => {
                                                            return this.renders.filter(item)
                                                        }).length - 1) &&
                                                        <div className="WeekDaysItems-Item-Border"/>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default TargetingPlacement;
