import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { AdjustmentsIcon, BeakerIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClipboardListIcon, CogIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, LockClosedIcon, MenuAlt1Icon, MinusIcon, PlusIcon, ViewGridAddIcon } from '@heroicons/react/outline'

import FeatherIcon from 'feather-icons-react';

import SingleDatepicker from "../modules/singleDatepicker";
import fuzzysort from 'fuzzysort';
import moment from 'moment';
import cn from "classnames";

class CustomizeOrderDates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dates: {
                start: false,
                end: false
            }
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            dates: this.props.dates ? this.props.dates : this.state.dates,
        })
        this.props.onDisable(true);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            //dates: nextProps.dates
        })
    }

    functions = {
        create: async () => {
            try {
                this.props.onUpdate(this.state.dates);
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    calls = {

    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                <>
                    <div
                        className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                        <div className="flex flex-1 flex-row justify-center items-center">
                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                <CalendarIcon className="w-5 h-5 " />
                            </div>
                            <div className="flex flex-col flex-1 cursor-pointer ml-3">
                                <h3 className="mb-0 font-semibold">
                                    Select date range
                                </h3>
                                {
                                    this.state.dates.start && this.state.dates.end &&
                                    <div className="text-xxs flex items-center text-gray-700 leading-tight">
                                        From <span className="font-bold text-purple-500 mx-1">{this.state.dates.start}</span> to <span className="font-bold text-purple-500 ml-1">{this.state.dates.end}</span>
                                    </div>
                                }
                            </div>

                            <div className="flex flex-row">
                                {
                                    this.state.dates.start && this.state.dates.end &&
                                    <div
                                        className="mr-2 border-green-500 border-2 text-green-500 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="w-full h-6" />
                        <div className="w-full">
                            <SingleDatepicker
                                maxDate={moment().format('YYYY-MM-DD')}
                                from={this.state.dates.start}
                                to={this.state.dates.end}
                                onChange={async (data) => {
                                    this.state.dates.start = data.start_date;
                                    this.state.dates.end = data.end_date;
                                    await this.promisedSetState({
                                        dates: this.state.dates
                                    })
                                    if (this.state.dates.start && this.state.dates.end) {
                                        this.props.onDisable(false);
                                    } else {
                                        this.props.onDisable(true);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            </div >
        )
    }
}

export default CustomizeOrderDates;
