import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from 'react-spinners';
import {CardElement, injectStripe} from 'react-stripe-elements';

class Card extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            success_modal: false,
            error_modal: false,
            warning_modal: false,
            loading: false,
            button_loading: false,
            error_message: "",
            cards: []
        };
    };

    componentWillMount() {
        this.init.cards();
    }

    init = {
        cards: () => {
            dataRegister.getCard().then((response) => {
                this.setState({
                    loading: false,
                    cards: response
                });
            }, (error) => {
                console.log(error);
            })
        }
    };

    functions = {
        add: () => {
            if (!this.state.button_loading) {
                this.setState({button_loading: true}, () => {
                    this.props.stripe.createToken({name: "Test"}).then((response) => {
                        if (response.error) {
                            this.setState({
                                button_loading: false,
                                error_modal: true,
                                error_message: response.error.message
                            })
                        } else {
                            dataRegister.addCard({token: response.token.id}).then((response) => {
                                this.setState({
                                    hide: true
                                }, () => {
                                    this.state.cards.push(response);
                                    this.setState({
                                        button_loading: false,
                                        success_modal: true,
                                        hide: false,
                                        card: this.state.cards
                                    });
                                })
                            }, (error) => {
                                this.setState({
                                    button_loading: false,
                                    error_modal: true,
                                    error_message: error.body.message
                                })
                            })
                        }
                    });
                });
            }
        },
        remove: (item, index) => {
            if (!this.state.button_loading) {
                this.setState({button_loading: true, warning_modal: false}, () => {
                    dataRegister.removeCard({token: item.id}).then((response) => {
                        this.state.cards.splice(index, 1);
                        this.setState({
                            button_loading: false,
                            card: this.state.cards
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    })
                });
            }
        }
    };

    renders = {
        card: () => {
            try {
                return this.state.card.last4;
            }catch (e) {
                return false;
            }
        },
        exp_month: (item) => {
            try {
                if(+item.exp_month < 10){
                    return "0" + item.exp_month;
                }else{
                    return item.exp_month;
                }
            }catch (e) {
                return item.exp_month;
            }
        }
    };

    render() {

        var style = {
            base: {
                color: '#333333',
                fontSize: '16px',
                fontWeight: '500',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#ccc',
                },
            },
            invalid: {
                color: '#333333',
                ':focus': {
                    color: '#333333',
                },
            },
        };

        return (
            <div className="Container">
                <SweetAlert
                    show={this.state.success_modal}
                    title="Card added"
                    type="success"
                    text="Click OK to continue!"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove card"
                    type="warning"
                    text="Are you sure ?"
                    showCancelButton={true}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({error_modal: false});
                    }}
                />
                {
                    this.state.loading &&
                    <div className="Account-Loader">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#388f8a'}
                            loading={true}
                        />
                    </div>
                }
                <div className="Section">
                    <div className={cx("Card-InputContainerTitle")}>
                        Mina kort
                    </div>
                </div>
                {
                    this.state.cards.map((item, index) => {
                        return(
                            <div className="Card-InnerContainerInnerSectionCard">
                                <div className="Card-InnerContainerInnerSectionCardInner">
                                    <div className="Card-InnerContainerInnerSectionCardInnerIcon">
                                        {
                                            item.brand === "Visa" &&
                                            <img className="Card-InnerContainerInnerSectionCardInnerIconSize" src={require('../../assets/images/visa.png')}/>
                                        }
                                    </div>
                                    <div className="Card-InnerContainerInnerSectionCardInnerNumber">
                                        ****{" "}****{" "}****{" "}{item.last4}
                                    </div>
                                    <div className="Card-InnerContainerInnerSectionCardInnerExpire">
                                        {this.renders.exp_month(item)}{" / "}{item.exp_year}
                                    </div>
                                    <div onClick={() => this.functions.remove(item, index)} className="Card-InnerContainerInnerSectionCardInnerExpireRemove">
                                        <FeatherIcon color={"#FC5353"} size={23} icon="trash"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    <div className="Card-InnerContainerInnerSectionCardStripe Card-InnerContainerInnerSectionCardLast">
                        {
                            !this.state.hide &&
                            <CardElement hidePostalCode={true} style={style}/>
                        }
                    </div>
                }
                {
                    <div className="Section">
                        <div onClick={() => {
                            this.functions.add();
                        }} className="Button">
                            {
                                !this.state.button_loading &&
                                <div>Lägg till</div>
                            }
                            {
                                this.state.button_loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    this.renders.card() && false &&
                    <div className="Section">
                        <div onClick={() => {
                            this.setState({warning_modal: true});
                        }} className="Button ButtonRed">
                            {
                                !this.state.button_loading &&
                                <div>Ta bort</div>
                            }
                            {
                                this.state.button_loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default injectStripe(Card);
