import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { clientRegister } from '../services/clientRegister';
import { dataRegister } from "../services/dataRegister";
import ClientTopNavigation from '../moduleFiles/clientTopNavigation';
import { CameraIcon, ChevronRightIcon, QuestionMarkCircleIcon, ShieldExclamationIcon, CogIcon, LogoutIcon } from '@heroicons/react/outline';
import translations from '../assets/json/onboarding_translation.json'

class ClientProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            closed: true,
            page: 1,
            whitelabel_settings: {},
            list_buttons: [
                { id: 1, name: "list_1", icon: <CogIcon className="w-6 h-6 " />, link: "/client/settings" },
                { id: 2, name: "list_2", icon: <QuestionMarkCircleIcon className="w-6 h-6 " />, link: "https://www.google.com" },
                { id: 3, name: "list_3", icon: <ShieldExclamationIcon className="w-6 h-6 " />, link: "https://www.adcredo.com/privacy-policy" },
            ]
        };
    }

    componentWillMount() {
        this.init.user();
    }

    componentDidMount() {
    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
    };

    functions = {
        handleLogout: async () => {
            await this.calls.logout()
            this.props.history.push("/test-onboarding")
        },
        uploadLogo: async (e) => {
            let id = Object.keys(this.state.user).length > 0 ? this.state.user.id : null;
            try {
                if (e.target.files[0].size > 5999000) {
                    alert("File is too big!")
                    return;
                } else {
                    const formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    let response = await this.calls.uploadImage(formData, id)
                    if (response && response.data.url !== "") {
                        let url = response.data.url;
                        // UPDATE CLIENT LOGO WITH URL
                        let result = await this.calls.updateClient(url, id)
                        console.log(result)
                        // SET NEW LOGO TO CLIENT REGISTER
                        if (result.status === 200) {
                            this.state.user.logo = url
                            await this.promisedSetState({
                                user: this.state.user
                            })
                            clientRegister.set(this.state.user)

                            // NEEDS TO RELOG TO SEE CHANGES, RELOAD PAGE DOES NOT WORK

                            // await this.promisedSetState({
                            //     user: userRegister.get()
                            // })
                            // window.location.reload()
                        }
                        // REFRESH PAGE ??
                    }
                }
            } catch (error) {
                console.log("error", error)
            }

        },
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/client/logout";
            return apiRegister.call(options, url);
        },
        uploadImage: (formData, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', formData, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + id;
            return apiRegister.call(options, url);
        },
        updateClient: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { logo: data, whitelabelClient: {} });
            let url = apiRegister.url.api + "/v3/adcredo/client/updateProfile";
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = window.location.pathname.split("/")[2];

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {
        let t = this.translate;
        return (
            <>

                {
                    this.state.loading &&
                    <div className="h-screen flex flex-col justify-center items-center">
                        <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                            style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}></div>
                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <>
                        <ClientTopNavigation
                            breadcrumb={"Profile"}
                            showUser={true}
                        />

                        <div className='p-4 h-screen'>

                            <div className='flex flex-col justify-center items-center space-y-2'>

                                {
                                    this.state.user && !this.state.user.logo &&
                                    <>
                                        <label className="relative flex flex-col items-center justify-center h-24 w-24 bg-white rounded-full cursor-pointer">
                                            <div className='text-xl font-bold'>
                                                {this.state.user && this.state.user.name ? this.state.user.name.charAt(0).toUpperCase() : "A B"}
                                            </div>
                                            <div className='absolute flex items-center justify-center w-5 h-5 rounded-full bg-black right-0 border border-white'
                                                style={{ bottom: "5px" }}
                                            >
                                                <CameraIcon className='w-3 h-3 text-white' />
                                            </div>
                                            <input type="file" id="fileUpload" className="hidden"
                                                onChange={(e) => this.functions.uploadLogo(e)}
                                            />
                                        </label>
                                    </>

                                }
                                {
                                    this.state.user && this.state.user.logo &&
                                    <>
                                        <label for="fileUpload" className="relative flex flex-col items-center justify-center cursor-pointer">
                                            <img className="h-24 w-24 rounded-full object-contain border border-gray-300 bg-white" src={this.state.user && this.state.user.logo} alt="" />
                                            {console.log("logo", this.state.user.logo)}
                                            <div className='absolute flex items-center justify-center w-5 h-5 rounded-full bg-black right-0 border border-white'
                                                style={{ bottom: "5px" }}>
                                                <CameraIcon className='w-3 h-3 text-white' />
                                            </div>
                                        </label>
                                        <input type="file" id="fileUpload" className="hidden"
                                            onChange={(e) => this.functions.uploadLogo(e)}
                                        />
                                    </>
                                }

                                <div className='flex flex-col space-y-2 text-center'>
                                    <div className='text-lg font-bold'>
                                        {this.state.user ? this.state.user.name : "No Name"}
                                    </div>
                                    <div className='text-xs text-gray-400'>
                                        {this.state.user ? this.state.user.email : "user@email.com"}
                                    </div>
                                </div>

                            </div>

                            <div className='flex flex-col mt-8'>

                                <div>
                                    <div>
                                        {t("text_1")}
                                    </div>

                                    <div className='flex flex-col space-y-4 mt-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0'>
                                        {
                                            this.state.list_buttons.map((item, index) => {
                                                return (
                                                    <div className='flex justify-between items-center md:bg-white md:p-4 md:rounded-lg md:shadow md:cursor-pointer'
                                                        onClick={() => {
                                                            if (item.link.includes("privacy")) {
                                                                window.open(item.link, "_blank")
                                                            }
                                                            if (item.link.includes("settings")) {
                                                                this.props.history.push(item.link)
                                                            }
                                                        }}
                                                    >

                                                        <div className='flex items-center justify-center'>
                                                            <div className="flex flex-col justify-center items-center bg-white md:bg-gray-100 w-12 h-12 rounded-full mr-4"
                                                                style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                            >
                                                                <span
                                                                    style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                                >
                                                                    {item.icon}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {t(item.name)}
                                                            </div>
                                                        </div>

                                                        <div className='flex items-center justify-center'>
                                                            <ChevronRightIcon className='w-6 h-6 text-gray-400' />
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='hidden md:flex justify-between items-center bg-white p-4 rounded-lg shadow cursor-pointer'
                                            onClick={() => this.functions.handleLogout()}
                                        >

                                            <div className='flex items-center justify-center'>
                                                <div className="flex flex-col justify-center items-center bg-gray-100 w-12 h-12 rounded-full mr-4"
                                                    style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                >
                                                    <LogoutIcon className='w-6 h-6 '
                                                        style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                    />
                                                </div>
                                                <div>
                                                    {t("list_4")}
                                                </div>
                                            </div>

                                            <div className='flex items-center justify-center'>
                                                <ChevronRightIcon className='w-6 h-6 text-gray-400' />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='flex flex-col space-y-4 mt-8 md:hidden'>
                                        <div>
                                            {t("text_2")}
                                        </div>

                                        <div className='flex justify-between items-center'
                                            onClick={() => this.functions.handleLogout()}
                                        >
                                            <div className='flex items-center justify-center'>
                                                <div className="flex flex-col justify-center items-center bg-white md:bg-gray-100 w-12 h-12 rounded-full mr-4"
                                                    style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                >
                                                    <LogoutIcon className='w-6 h-6'
                                                        style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                    />
                                                </div>
                                                <div>
                                                    {t("list_4")}
                                                </div>
                                            </div>

                                            <div className='flex items-center justify-center'>
                                                <ChevronRightIcon className='w-6 h-6 text-gray-400' />
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </>
                }

            </>
        )
    }
}

export default ClientProfile;