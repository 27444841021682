import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import {tokenRegister} from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";

import {LinkedIn} from 'react-linkedin-login-oauth2';

class ClientLinkedin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            refresh_token: null,
            loading: false,
            accounts: [],
            account: {},
            loading_accounts: false,
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    init = {};

    functions = {
        login: () => {
            let self = this;
            if (!self.state.loading) {
                self.setState({
                    loading: true
                }, () => {

                })
            }
        },
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.linkedin;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        token: (code) => {
            calls.getToken({
                code: code
            }).then((response) => {
                this.setState({
                    token: response.access_token,
                    refresh_token: response.refresh_token,
                    loading: false
                }, () => {
                    this.functions.accounts();
                })
            }, (error) => {
                this.setState({
                    loading: false
                })
            });
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        account: this.state.account,
                        refresh_token: this.state.refresh_token,
                        access_token: this.state.token
                    }).then((response) => {
                        this.state.item.linkedin = response.data;
                        this.setState({
                            token: null,
                            refresh_token: null,
                            account: {},
                            item: this.state.item,
                            loading_create: false,
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        accounts: () => {
            if (!this.state.loading_accounts) {
                this.setState({
                    loading_accounts: true
                }, () => {
                    calls.getAccounts({
                        client: this.state.item.id,
                        access_token: this.state.token
                    }).then((response) => {
                        this.setState({
                            accounts: response.data,
                            loading_accounts: false,
                        })
                    }, (error) => {
                        this.setState({
                            loading_accounts: false
                        })
                    });
                })
            }
        },
        account: () => {
            if (!this.state.loading_accounts) {
                this.setState({
                    loading_accounts: true
                }, () => {
                    let org_id = "";
                    try {
                        org_id = this.state.account.reference.replace("urn:li:organization:", "");
                    }catch (e) {}
                    calls.getAccount({
                        client: this.state.item.id,
                        id: org_id,
                        name: this.state.account.name,
                        access_token: this.state.token
                    }).then((response) => {
                        let image_url = "";
                        try{
                            image_url = response.data.logoV2["original~"].elements[0].identifiers[0].identifier;
                            this.state.account.logo = image_url;
                        }catch (e) {}
                        this.setState({
                            account: this.state.account,
                            loading_accounts: false,
                        })
                    }, (error) => {
                        this.setState({
                            loading_accounts: false
                        })
                    });
                })
            }
        },
        remove: () => {
            this.setState({
                loading: true,
                remove_modal: false
            }, () => {
                calls.remove(this.state.item.id).then((response) => {
                    delete this.state.item.linkedin;
                    this.setState({
                        token: null,
                        refresh_token: null,
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });
                });
            });
        }
    };

    renders = {
        submenus: () => {
            let array = [
                {id: 0, name: "Add adaccount"}
            ];
            return array;
        }
    };

    handleSuccess = (data) => {
        this.functions.token(data.code);
    };

    handleFailure = (error) => {
        this.setState({
            code: '',
            errorMessage: error.errorMessage,
            loading: false
        }, () => {
            console.log(this.state.errorMessage);
        });
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Linkedin"
                    type="warning"
                    text={"You wont be able access any Linkedin campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({remove_modal: false});
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.item.linkedin && !this.state.token &&
                            <LinkedIn
                                scope={"rw_ads,r_ads,w_organization_social,r_organization_social,w_member_social,rw_organization_admin,r_ads_reporting,r_liteprofile"}
                                clientId="78v2iaew9m2uw6"
                                onFailure={this.handleFailure}
                                onSuccess={this.handleSuccess}
                                redirectUri="https://app.adcredo.io/callback/linkedin"
                                onClick={() => {
                                    this.setState({
                                        loading: true
                                    })
                                }}
                                renderElement={({onClick, disabled}) => (
                                    <div onClick={onClick} className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>signin Linkedin</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                )}
                            />
                        }
                        {
                            this.state.token && !this.state.item.linkedin &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Dropdown
                                    filter={true}
                                    title={'Select account'}
                                    size={'full'}
                                    placeholder={(!this.state.loading_accounts && this.state.accounts.length < 1) ? "No accounts available" : (this.state.loading_accounts ? "Loading accounts" : "Click here ..")}
                                    error={this.state.error === "manager" && !this.state.account.name}
                                    options={this.state.accounts}
                                    loading={this.state.loading_accounts}
                                    removeArrow={!this.state.loading_accounts && this.state.accounts.length < 1}
                                    value={this.state.account}
                                    onChange={(value) => this.setState({
                                        account: value
                                    }, () => {
                                        this.functions.account();
                                    })}
                                />
                            </div>
                        }
                        {
                            (this.state.item.linkedin) &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Input
                                    title={'adaccount'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.linkedin.adaccountName + ' - ' + this.state.item.linkedin.adaccountId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {!this.state.loading_accounts &&
                            this.state.token && !this.state.item.linkedin && this.state.account.id &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull bg-linkedin-500">
                                    {
                                        !this.state.loading_create &&
                                        <div>add LinkedIn adaccount</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                this.state.item.linkedin &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull bg-linkedin-500">
                                        {
                                            !this.state.loading &&
                                            <div>signout LinkedIn</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.linkedin &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        token: null,
                                        refresh_token: null,
                                        account: {},
                                        accounts: []
                                    })} className="Button ButtonFull bg-linkedin-500">
                                        signout LinkedIn
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientLinkedin;
