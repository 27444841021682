import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from 'feather-icons-react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { tokenRegister } from "../../services/tokenRegister";
import UserProfileSettings from "../user-profile-settings";
//import UserClientsAll from "../user-clients-all";
import UserProfileAccount from "../user-profile-account";
import UserProfilePassword from "../user-profile-password";
import UserProfileBilling from "../user-profile-billing";
import { userRegister } from "../../services/userRegister";
import UserUsersAll from "../user-users-all";
import checkAuthAdmin from "../../functions/checkAuthAdmin";
import UserUsersNew from "../user-users-new";
import UserAgenciesNew from "../user-agencies-new";
import checkAuthAgent from "../../functions/checkAuthAgent";
import UserUsersAllAgent from "../user-users-all-agent";
import UserUsersNewAgent from "../user-users-new-agent";
import checkAuthClient from "../../functions/checkAuthClient";
import UserUsersAllClient from "../user-users-all-client";
import UserUsersNewClient from "../user-users-new-client";
import { campaignRegister } from "../../services/campaignRegister";
import UserRequestsAll from "../user-requests-all";
import UserRequestsNew from "../user-requests-new";
import UserOptimizeAll from "../user-optimize-all";
import UserDashboard from '../user-dashboard-new-by-mark';
import { reportRegister } from "../../services/reportRegister";
import PremiumReportNew from '../premium-report/reports-select-template';
import ReportList from "../../pages/premium-report/reports-reportlist"
import CreateNewTemplate from '../premium-report/create-new-template';
import TemplateOlv from '../premium-report/reports-template-olv';
import UserClientItem from "../user-clients-item";
import UserRegionsNew from "../user-regions-new";
import UserRegionsAll from "../user-regions-all";

import Sidenav from "../../modules/sidenav";
import Topnav from "../../modules/topnav";
import FreemiumReport from "../freemium-report";
import ReportStandardInternal from "../report-standard-internal";
//import ReportAdvancedInternal from "../report-advanced-internal";
import AdvancedReportInternal from "../../pageFIles/advancedReportInternal";
import UserMetrics from "../user-metrics";
import UserMetricsNew from "../user-metrics-new";
import UserFinance from "../user-finance";
import AgencyEdit from "../agencies/edit";
import UserAgenciesList from "../agencies/list";
import ClientsNew from "../clients/new";
import UserAdvertising from "../campaigns/new";
import UserCampaignsPaused from "../campaigns/paused";
import UserCampaignsUpcoming from "../campaigns/upcoming";
import UserCampaignsPending from "../campaigns/pending";
import UserCampaignsActive from "../campaigns/active";
import UserCampaignsDrafted from "../campaigns/drafted";
import UserCampaignsEnded from "../campaigns/ended";
import UserCampaignsError from "../campaigns/error";
import UserCampaignsAll from "../campaigns/all";
import UserCampaignsItem from "../campaigns/item";

//NEW FILES
import ClientList from "../../pageFIles/clientList";
import Optimize from '../../pageFIles/old/optimize';
import OrganicPosts from '../../pageFIles/organicPosts';
import Account from '../../pageFIles/account';
import AgentList from '../../pageFIles/agentList';
import AdminOverview from '../../pageFIles/adminOverview';

class User extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    componentWillUpdate(nextProps, nextState, nextContext) {
        /*
        if (window.location.pathname !== '/v2/campaigns/new') {
            campaignRegister.remove();
        }
        */
        /*
         if (window.location.pathname !== '/v2/report/all') {
             reportRegister.remove();
         }
         */
    }

    functions = {};

    renders = {};

    render() {
        return (
            <div className="flex flex-row flex-wrap">
                <Topnav history={this.props.history} />
                <div className={(window.location.href.indexOf("user/order/new") !== -1 ? "md:w-20 lg:w-20" : "md:w-2/12 lg:w-2/12") + " w-full"}>
                    <Sidenav history={this.props.history} />
                </div>
                <main className={(window.location.href.indexOf("user/order/new") !== -1 ? "" : "md:w-10/12 lg:w-10/12") + " w-full md:px-6 px-4 md:pt-16 pt-12"}>
                    <div className={(window.location.href.indexOf("user/order/new") !== -1 ? "md:pl-20" : "") + " h-full"}>
                        <Switch>

                            <Route exact path="/user/dashboard" component={UserDashboard} />

                            <Route exact path="/user/profile/account" component={Account} />
                            <Route exact path="/user/profile/agents" component={AgentList} />
                            {/*<Route exact path="/user/profile/account" component={UserProfileAccount} />*/}
                            {/*<Route exact path="/user/profile/password" component={UserProfilePassword} />*/}
                            {/*<Route exact path="/user/profile/billing" component={UserProfileBilling} />*/}
                            {/*<Route exact path="/user/profile/settings" component={UserProfileSettings} />*/}

                            <Route exact path="/user/campaigns/new" component={UserAdvertising} />
                            <Route exact path="/user/campaigns/paused" component={UserCampaignsPaused} />
                            <Route exact path="/user/campaigns/upcoming" component={UserCampaignsUpcoming} />
                            <Route exact path="/user/campaigns/pending" component={UserCampaignsPending} />
                            <Route exact path="/user/campaigns/active" component={UserCampaignsActive} />
                            <Route exact path="/user/campaigns/drafted" component={UserCampaignsDrafted} />
                            <Route exact path="/user/campaigns/ended" component={UserCampaignsEnded} />
                            <Route exact path="/user/campaigns/error" component={UserCampaignsError} />
                            <Route exact path="/user/campaigns/all" component={UserCampaignsAll} />
                            <Route exact path="/user/campaigns/:id" component={UserCampaignsItem} />

                            <Route exact path="/user/clients/new" component={ClientsNew} />
                            <Route exact path="/user/clients/all" component={ClientList} />
                            <Route exact path="/user/client/:id" component={UserClientItem} />

                            <Route exact path="/user/reports/all" component={ReportList} />
                            <Route exact path="/user/reports/new" component={PremiumReportNew} />

                            <Route exact path="/user/reports/new/freemium/:id" component={ReportStandardInternal} />
                            <Route exact path="/user/reports/freemium/:id/:report" component={ReportStandardInternal} />

                            <Route exact path="/user/reports/new/premium/:id" component={AdvancedReportInternal} />
                            <Route exact path="/user/reports/premium/:id/:report" component={AdvancedReportInternal} />

                            {/* <Route exact path="/user/reports/all" component={UserReportsAll}/> */}
                            {/* <Route exact path="/user/reports/create-new" component={CreateNewTemplate}/> */}
                            {/* <Route path="/user/reports/template/olv/777" component={TemplateOlv}/> */}

                            <Route exact path="/user/optimize/all" component={Optimize} />
                            <Route exact path="/user/organic" component={OrganicPosts} />

                            {/*<Route exact path="/user/optimize/all" component={UserOptimizeAll} />*/}
                            <Route exact path="/user/metrics" component={UserMetrics} />
                            <Route exact path="/user/metrics/new" component={UserMetricsNew} />

                            {
                                userRegister.get().type === 'client' &&
                                <Route exact path="/user/users/all" component={checkAuthClient(UserUsersAllClient)} />
                            }
                            {
                                userRegister.get().type === 'client' &&
                                <Route exact path="/user/users/new" component={checkAuthClient(UserUsersNewClient)} />
                            }
                            {
                                userRegister.get().type === 'client' &&
                                <Route exact path="/user/requests/all" component={checkAuthClient(UserRequestsAll)} />
                            }
                            {
                                userRegister.get().type === 'client' &&
                                <Route exact path="/user/requests/new" component={checkAuthClient(UserRequestsNew)} />
                            }
                            {
                                userRegister.get().type === 'agent' &&
                                <Route exact path="/user/users/all" component={checkAuthAgent(UserUsersAllAgent)} />
                            }
                            {
                                userRegister.get().type === 'agent' &&
                                <Route exact path="/user/users/new" component={checkAuthAgent(UserUsersNewAgent)} />
                            }
                            {
                                userRegister.get().type === 'admin' &&
                                <Route exact path="/user/users/all" component={checkAuthAdmin(UserUsersAll)} />
                            }
                            {
                                userRegister.get().type === 'admin' &&
                                <Route exact path="/user/users/new" component={checkAuthAdmin(UserUsersNew)} />
                            }
                            {
                                userRegister.get().type === 'admin' &&
                                <Route exact path="/user/overview" component={checkAuthAdmin(AdminOverview)} />
                            }

                            {/*ADMIN ONLY*/}
                            <Route exact path="/user/agencies/new" component={checkAuthAdmin(UserAgenciesNew)} />
                            <Route exact path="/user/agencies/all" component={checkAuthAdmin(UserAgenciesList)} />
                            <Route exact path="/user/agencies/:id" component={checkAuthAdmin(AgencyEdit)} />
                            <Route exact path="/user/regions/new" component={checkAuthAdmin(UserRegionsNew)} />
                            <Route exact path="/user/regions/all" component={checkAuthAdmin(UserRegionsAll)} />
                            <Route exact path="/user/finance" component={checkAuthAdmin(UserFinance)} />

                        </Switch>
                    </div>
                </main>
            </div>
        )
    }
}

export default User;
