import {apiRegister} from '../../../services/apiRegister';
import {tokenRegister} from '../../../services/tokenRegister';

class calls {
    static url = {
        api: apiRegister.url.api,
    };

    // static getClient(data) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', data);
    //     let url = calls.url.api + '/client';
    //     return apiRegister.call(options, url);
    // }

    // static getCampaignsByClient(id) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/campaign?type=all&client=' + id;
    //     console.log('Fetching ' + url);
    //     return apiRegister.call(options, url);
    // }

    // static getCampaigns() {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/campaign?type=all&report=true';
    //     return apiRegister.call(options, url);
    // }

    // static getCampaign(id) {
    //     let options = apiRegister.options(tokenRegister.get(), 'GET', null);
    //     let url = calls.url.api + '/campaign/' + id;
    //     return apiRegister.call(options, url);
    // }

    // static createReport(data) {
    //     let options = apiRegister.options(tokenRegister.get(), 'POST', data);
    //     let url = calls.url.api + '/report';
    //     return apiRegister.call(options, url);
    // }

    static updateReportProperties(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + '/report/properties';
        return apiRegister.call(options, url);
    }

    // static createPdf(data) {
    //     let options = apiRegister.options(tokenRegister.get(), 'POST', data);
    //     let url = calls.url.api + '/pdf/report';
    //     return apiRegister.call(options, url);
    // }

    // static createUrl(data) {
    //     let options = apiRegister.options(tokenRegister.get(), 'POST', data);
    //     let url = calls.url.api + '/url';
    //     return apiRegister.call(options, url);
    // }
}

export {calls};
