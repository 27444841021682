import { KeyIcon, QuestionMarkCircleIcon, TrashIcon, PencilAltIcon, PlusIcon } from '@heroicons/react/outline';
import React, { Component, Fragment } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import SuccessModal from './successModal';
import moment from 'moment';
import TopNavigationAgent from './topNavigationAgent';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import SlideoutTailwind from './slideoutTailwind';
import CreateGoogleAdAccount from './createGoogleAdAccount';
import UpdateConversionAction from './updateConversionAction';
import UpdateBiddingStrategy from './updateBiddingStrategy';
import CreateAccountBudget from './createAccountBudget';
import cn from "classnames";
import CopyButton from './copyButton';

class AccountGoogle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            me: {},
            agency: {},
            agency_user: {},
            message: "",
            throttling: {},
            loading: false,
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            manager: {},
            managers: [],
            customer: {},
            customers: [],
            agents: [],
            agent: { id: 0, name: "Select agent" },
            subtab: { id: 4, name: "Account budgets" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            healthcheck: {},
            include_adaccounts: { id: 1, name: "Include all" },
            include_campaigns: { id: 1, name: "Include all" },
            owner: false,
            disabled: false,
            syncMessage: "",
            syncManagers: false,
            name: "",
            budget_limit: 0,
            loading_billings: false,
            billing_setup: { id: 0, name: "Select setup" },
            billings: [],
            error_massage: "",
            bidding_strategies: [],
            budget_exist: false,
            account_budgets: [],
            currency: {},
            currencies: currencies,
            conversion_actions: [],
            conversion_action_type: { id: 1, name: "Webpage", value: "WEBPAGE" },
            conversion_action_category: { id: 1, name: "Default", value: "DEFAULT" },
            strategies: [
                { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" },
                { id: 3, name: "Conversions value", value: "MAXIMIZE_CONVERSION_VALUE" },
                { id: 4, name: "Impression share", value: "TARGET_IMPRESSION_SHARE" },
                { id: 5, name: "Manual CPC", value: "MANUAL_CPC" },
                { id: 6, name: "Target ROAS", value: "MAXIMIZE_CONVERSION_VALUE" },
                { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" },
            ]
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            agency: this.props.agency ? this.props.agency : {},
            me: this.props.me ? this.props.me : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.google && !this.state.agency.google) {
            this.functions.getAgents(true);
        }
        if (this.state.client.google) {
            this.functions.getGoogleAccount();
            this.functions.listConversionActions();
            this.functions.biddingStrategies();
            await this.functions.listBillings();
            if (this.state.billings.length === 1) {
                this.functions.listAccountBudget(this.state.billings[0]);
            }
            if (this.state.client.google.budget && this.state.client.google.budget.limit > 0) {
                await this.promisedSetState({
                    budget_exist: true,
                    budget_limit: this.state.client.google.budget.limit
                });
                if (this.state.billings.filter((item) => { return item.resourceName === this.state.client.google.budget.billingSetup }).length > 0) {
                    await this.promisedSetState({
                        billing_setup: this.state.billings.filter((item) => { return item.resourceName === this.state.client.google.budget.billingSetup })[0]
                    });
                    this.functions.listAccountBudget(this.state.billing_setup);
                }
            }
        }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            agency: nextprops.agency ? nextprops.agency : {},
            me: nextprops.me ? nextprops.me : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "google",
                    token: token
                });

                //TEMP SOLUTION
                window.location.reload();
                //TEMP SOLUTION

                await self.promisedSetState({
                    user: { ...this.state.user, ...{ google: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "google"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ google: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listManagers: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.listManagers();
                await this.promisedSetState({
                    managers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        listCustomers: async () => {
            await this.promisedSetState({
                loading_customers: true
            });
            try {
                let response = await this.calls.listCustomers();
                await this.promisedSetState({
                    customers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_customers: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.customer.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    manager: this.state.manager,
                    customer: this.state.customer,
                    ocid: this.state.ocid
                });
                this.state.client.google = {
                    defaultAgent: this.state.agent,
                    managerId: this.state.manager.id,
                    managerName: this.state.manager.name,
                    clientId: this.state.customer.id,
                    clientName: this.state.customer.name,
                    ocid: this.state.ocid,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    customer: {},
                    manager: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_budget: true,
                error: false
            });
            try {
                let response = await this.calls.updateCientPlatformBudget({
                    channel: "google",
                    enabled: this.state.client.google.maxBudgetEnabled,
                    budget: this.state.client.google.maxBudget
                });
                this.state.client.google.campaignsWithSchedule = response.data.campaignsWithSchedule;
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_budget: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.google;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    customer: {},
                    manager: {},
                    ocid: "",
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'google'
                });
                this.state.agency.google = {
                    google_connected: response.data.google_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'google'
                });
                this.state.agency.google = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => {
                            item.name = item.email;
                            return item
                        }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => {
                        item.name = item.email;
                        return item
                    }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        listBillings: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_billings: true
                });
                try {
                    let response = await this.calls.listBillings();
                    await this.promisedSetState({
                        billings: response.data.map((item) => { item.name = (item.paymentsAccountInfo ? item.paymentsAccountInfo.paymentsAccountName : item.id); return item })
                    });

                } catch (error) { }
                await this.promisedSetState({
                    loading_billings: false
                });
                resolve();
            });
        },
        updateOcid: async () => {
            try {
                await this.promisedSetState({
                    loading_ocid: true
                });

                let resp = await this.calls.updateGoogleAccountOcid({
                    ocid: this.state.updatedOcid
                });
                this.state.client.google.ocid = this.state.updatedOcid;
                delete this.state.updatedOcid;
                await this.promisedSetState({
                    client: this.state.client,
                    loading_ocid: false
                });
            } catch (error) { }
        },
        biddingStrategies: async () => {
            return new Promise(async (resolve) => {
                try {
                    //await this.promisedSetState({ loading_merchants: true });
                    let response = await this.calls.listBiddingStrategies();
                    this.state.bidding_strategies = [];
                    response.data.map((item) => {
                        this.state.bidding_strategies.push(item.biddingStrategy);
                    })

                    //response.data.unshift({ id: 0, name: "None" });
                    console.log(response.data);
                    await this.promisedSetState({
                        bidding_strategies: this.state.bidding_strategies
                    });
                } catch (error) {
                    this.promisedSetState({

                    })
                }
                resolve();
            })
        },
        listConversionActions: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_conversions: true
                });
                try {
                    let response = await this.calls.listConversionActions();
                    await this.promisedSetState({
                        conversion_actions: response.data
                    });
                } catch (error) { }
                await this.promisedSetState({
                    loading_conversions: false
                });
                resolve();
            });
        },
        createConversionAction: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_conversion: true
                });
                try {
                    await this.calls.createConversionAction({
                        operations: [
                            {
                                create: {
                                    name: this.state.conversion_action_name,
                                    category: this.state.conversion_action_category.value,
                                    type: this.state.conversion_action_type.value,
                                    status: "ENABLED"
                                }
                            }
                        ]
                    });
                    await this.promisedSetState({
                        conversion_action_name: "",
                        conversion_action_type: { id: 1, name: "Webpage", value: "WEBPAGE" },
                        conversion_action_category: { id: 1, name: "Default", value: "DEFAULT" }
                    });
                    this.functions.listConversionActions();
                } catch (error) {
                    console.log("error", error)
                    await this.promisedSetState({
                        error_massage: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                    });
                }
                await this.promisedSetState({
                    loading_conversion: false
                });
                resolve();
            });
        },
        listAccountBudget: async (data) => {
            try {
                let response = await this.calls.listAccountBudget();
                if (Array.isArray(response.data) && response.data.length > 0) {
                    let buff = [];
                    response.data.map((item) => {
                        if (item.billingSetup === data.resourceName) {
                            if ((item.proposedEndTimeType && item.proposedEndTimeType === "FOREVER") || (item.proposedEndDateTime && moment(item.proposedEndDateTime).isAfter(moment()))) {
                                buff.push(item);
                            }
                        }
                    })
                    if (buff.length > 0) {
                        await this.promisedSetState({
                            account_budgets: buff
                        });
                    }
                }
            } catch (error) { }

        },
        getGoogleAccount: async () => {
            try {
                this.setState({ loading_auto_tagging: true });
                let response = await this.calls.getGoogleAccount();
                this.setState({
                    auto_tagging: response.data.autoTaggingEnabled
                });
            } catch (error) { }
            this.setState({ loading_auto_tagging: false });
        },
        deleteAccountBudget: async (data) => {
            try {
                let body = {};
                if (data.status === "APPROVED") {
                    body = {
                        operation: {
                            create: {
                                proposalType: (moment().isBefore(data.approvedStartDateTime) ? "REMOVE" : "END"),
                                account_budget: data.resourceName
                            }
                        }
                    }
                }
                if (Object.keys(body).length > 0) {
                    let response = await this.calls.deleteAccountBudget(body);
                    await this.promisedSetState({
                        account_budgets: this.state.account_budgets.filter((item) => item.resourceName !== data.resourceName)
                    });
                }


            } catch (error) { }

        },
        updateAccountBudget: async (data) => {
            try {
                if (data < 1) {
                    await this.promisedSetState({
                        budget_exist: false,
                        budget_limit: 0
                    });
                }
                if (this.state.account_budgets.filter((item) => { return moment().endOf('month').isBefore(item.proposedStartDateTime) && item.status !== "PENDING" }).length > 0) {
                    let buffer = this.state.account_budgets.filter((item) => { return moment().endOf('month').isBefore(item.proposedStartDateTime) && item.status !== "PENDING" });
                    for (let i = 0; i < buffer.length; i++) {
                        let responce = await this.calls.deleteAccountBudget({
                            operation: {
                                create: {
                                    proposalType: "REMOVE",
                                    account_budget: buffer[i].resourceName
                                }
                            }
                        });

                        this.state.account_budgets = this.state.account_budgets.filter((item) => item.resourceName !== buffer[i].resourceName);
                        if (responce) {
                            await this.promisedSetState({
                                account_budgets: this.state.account_budgets
                            });
                        }
                        if (data > 0) {
                            let resp = await this.calls.createAccountBudget({
                                proposalType: "CREATE",
                                billingSetup: this.state.billing_setup.resourceName,
                                proposedSpendingLimitMicros: this.state.budget_limit * 1000000,
                                proposedStartDateTime: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss'),
                                proposedEndDateTime: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss'),
                                proposed_name: moment().add(1, 'months').format('YYYY-MM')
                            });
                            if (resp) {
                                this.state.account_budgets.push(
                                    {
                                        status: "PENDING",
                                        proposedStartDateTime: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss'),
                                        proposedEndDateTime: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss'),
                                        proposedSpendingLimitMicros: data * 1000000,

                                    }
                                );
                                await this.promisedSetState({
                                    account_budgets: this.state.account_budgets
                                });
                            }
                        }
                    }
                }
                await this.calls.updateAccountBudget({
                    limit: data,
                    billingSetup: this.state.billing_setup.resourceName,
                });

            } catch (error) {
                console.log("error", error)
                await this.promisedSetState({
                    error_massage: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
        },
        createAccountBudget: async () => {
            try {
                let data = {
                    proposalType: "CREATE",
                    billingSetup: this.state.billing_setup.resourceName,
                    proposedSpendingLimitMicros: this.state.budget_limit * 1000000,
                    proposedStartDateTime: moment().format('YYYY-MM-DD hh:mm:ss'),
                    proposedEndDateTime: moment().endOf('month').format('YYYY-MM-DD hh:mm:ss'),
                    proposed_name: moment().format('YYYY-MM'),
                }
                let response = await this.calls.createAccountBudget(data);
                if (response) {
                    await this.calls.updateAccountBudget({
                        limit: this.state.budget_limit,
                        billingSetup: this.state.billing_setup.resourceName,
                    });
                    this.state.account_budgets.push(
                        {
                            status: "PENDING",
                            proposedStartDateTime: data.proposedStartDateTime,
                            proposedEndDateTime: data.proposedEndDateTime,
                            proposedSpendingLimitMicros: data.proposedSpendingLimitMicros

                        }
                    );
                    if (moment().isAfter(moment(moment().format("YYYY-MM") + "-25"))) {
                        data.proposedStartDateTime = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss');
                        data.proposedEndDateTime = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss');
                        data.proposed_name = moment().add(1, 'months').format('YYYY-MM');
                        let response2 = await this.calls.createAccountBudget(data);
                        if (response2) {
                            this.state.account_budgets.push(
                                {
                                    status: "PENDING",
                                    proposedStartDateTime: data.proposedStartDateTime,
                                    proposedEndDateTime: data.proposedEndDateTime,
                                    proposedSpendingLimitMicros: data.proposedSpendingLimitMicros

                                }
                            );
                        }
                    }
                }
                await this.promisedSetState({
                    account_budgets: this.state.account_budgets,
                    budget_exist: true
                });
            } catch (error) {
                console.log("error", error)
                await this.promisedSetState({
                    error_massage: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
        },
        createAccount: async () => {
            await this.promisedSetState({
                loading_new_account: true,
                error: false
            });
            try {
                let response = await this.calls.createAccount();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_new_account: false
            });
        },
        updateAutoTagging: async () => {
            this.setState({ loading_auto_tagging: true });
            try {
                await this.calls.updateAutoTagging({ auto_tagging: !this.state.auto_tagging });
                this.functions.getGoogleAccount();
            } catch (error) {
                this.setState({ loading_auto_tagging: false });
            }
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateCientPlatformBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCientPlatformBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateAccountBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAccountBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createAccountBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createAccountBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        deleteAccountBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/removeAccountBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listManagers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listCustomers?client=" + this.state.client.id + (this.state.agency_user.id ? ("&agent=" + this.state.agency_user.id) : ("&agent=" + this.state.agent.id));
            return apiRegister.call(options, url);
        },
        listCustomers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listClients?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        listConversionActions: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listConversionActions?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listBiddingStrategies: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBiddingStrategies?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createConversionAction: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createConversionAction?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listBillings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBillings?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listAccountBudget: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listAccountBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getGoogleAccount: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/getAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateGoogleAccountOcid: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateGoogleAccountOcid?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateAutoTagging: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/google/updateCustomerAutoTagging?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createAccount: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/google/createAccount?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=google";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Google"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                {/*OPEN CONVERSION ACTION*/}
                <SlideoutTailwind
                    title={"Conversion action"}
                    submitButtonText={"Close"}
                    secondaryButton={false}
                    medium={true}
                    open={this.state.conversion_action ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ conversion_action: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            conversion_action: false
                        });
                    }}
                >
                    {
                        this.state.conversion_action &&
                        <UpdateConversionAction
                            ref="UpdateConversionAction"
                            conversionAction={this.state.conversion_action}
                            onCreated={async () => {

                            }}
                            onError={async () => {

                            }}
                        />
                    }
                </SlideoutTailwind>
                {/*OPEN CONVERSION ACTION*/}
                <SlideoutTailwind
                    title={"Bidding strategy action"}
                    submitButtonText={"Update"}
                    secondaryButton={false}
                    medium={true}
                    open={this.state.bidding_strategy_action ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ bidding_strategy_action: false });
                    }}
                    onSubmit={async () => {
                        this.refs.UpdateBiddingStrategy.functions.update();
                    }}
                >
                    {
                        this.state.bidding_strategy_action &&
                        <UpdateBiddingStrategy
                            ref="UpdateBiddingStrategy"
                            biddingStrategy={this.state.bidding_strategy_action}
                            client={this.state.client}
                            onUpdated={async () => {
                                await this.promisedSetState({
                                    bidding_strategy_action: false
                                });
                                this.functions.biddingStrategies();
                            }}
                            onError={async () => {

                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*CREATE ADACCOUNT*/}
                <SlideoutTailwind
                    title={"Create Ad account"}
                    submitButtonText={"Create"}
                    secondaryButton={false}
                    medium={true}
                    open={this.state.create_account ? true : false}
                    loader={this.state.create_account_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_account: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_account_loading: true
                        });
                        this.refs.CreateGoogleAdAccount.functions.createAccount();
                    }}
                >
                    {
                        this.state.create_account &&
                        <CreateGoogleAdAccount
                            currencies={this.state.currencies}
                            ref="CreateGoogleAdAccount"
                            manager={this.state.manager}
                            agent={this.state.agent}
                            client={this.state.client}
                            onCreated={async (data) => {
                                if (this.state.client && this.state.client.googleScaleSettings && this.state.client.googleScaleSettings.createAccounts) {
                                    this.setState({
                                        create_account_loading: false,
                                        create_account: false,
                                        lock_settings: true,
                                        agent: {
                                            id: this.state.client.googleScaleSettings.agent ? this.state.client.googleScaleSettings.agent.id : null,
                                            email: this.state.client.googleScaleSettings.agent ? this.state.client.googleScaleSettings.agent.email : null,
                                            name: this.state.client.googleScaleSettings.agent ? this.state.client.googleScaleSettings.agent.email : null,
                                        },
                                        manager: {
                                            new: true,
                                            id: data.manager,
                                            name: "(" + data.manager + ") " + data.manager_name
                                        },
                                        customer: {
                                            id: data.account,
                                            name: "(" + data.account + ") " + data.name
                                        },
                                        currency: this.state.client.googleScaleSettings.currency ? this.state.client.googleScaleSettings.currency : { name: "Select currency" }
                                    });
                                }
                            }}
                            onError={async () => {
                                await this.promisedSetState({
                                    create_account_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-google-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Google
                        </div>
                        {
                            !this.state.agency.google &&
                            !this.state.user.google &&
                            <div className="text-sm text-gray-500">
                                Connect Google to this client
                            </div>
                        }
                        {
                            !this.state.agency.google &&
                            this.state.user.google &&
                            <div className="text-sm text-gray-500">
                                Connect Google to this account
                            </div>
                        }
                        {
                            this.state.agency.google &&
                            <div className="text-sm text-gray-500">
                                Connect Google to agency
                            </div>
                        }
                    </div>
                    {
                        (
                            this.state.agency.google ||
                            (
                                !this.state.user.id &&
                                this.state.client.google &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.google && this.state.client.google.defaultAgent && this.state.client.google.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.google ? (this.state.client.google ? true : false) : this.state.agency.google.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.google) {
                                        if (!this.state.agency.google.authenticated) {
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                        }
                                    } else {
                                        if (!this.state.client.google) {
                                            this.state.client.google = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account || this.state.syncManagers) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.agency.google &&
                    !this.state.user.id &&
                    < div className="mt-5 flex">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Default agent"}
                                    small={false}
                                    locked={this.state.client.google || this.state.lock_settings}
                                    skipInternalSearch={this.state.is_manager}
                                    loader={this.state.loading_agents}
                                    loadingPagination={this.state.loading_agents_pagination}
                                    loadingSearch={this.state.loading_agents_search}
                                    total={this.state.total}
                                    searchInput={true}
                                    placeholder={"..."}
                                    defaultOptions={this.state.me.google && this.state.me.google.authenticated ? [{ id: this.state.me.id, email: this.state.me.email, name: "Myself (" + this.state.me.email + ")" }] : []}
                                    pagination={this.state.total > (this.state.page * this.state.limit)}
                                    selected={this.state.client.google && this.state.client.google.defaultAgent ? this.state.client.google.defaultAgent : this.state.agent}
                                    options={this.state.agents}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            agent: value,
                                            manager: {},
                                            customer: {},
                                            ocid: ""
                                        });
                                        this.functions.listManagers();
                                    }}
                                    onPagination={async () => {
                                        if (!this.state.loading_agents_pagination) {
                                            await this.promisedSetState({
                                                page: this.state.page + 1
                                            });
                                            this.functions.getAgents(false, true, false);
                                        }
                                    }}
                                    onSearch={async (value) => {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: "",
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            if (this.state.throttling.current) {
                                                //SKIP
                                            } else {
                                                if (value && value === "") {
                                                    await this.promisedSetState({
                                                        search: value,
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                } else {
                                                    this.state.throttling.current = true;
                                                    await this.promisedSetState({
                                                        search: value,
                                                        throttling: this.state.throttling
                                                    });
                                                    setTimeout(async () => {
                                                        this.state.throttling.current = false;
                                                        await this.promisedSetState({
                                                            throttling: this.state.throttling
                                                        });
                                                        await this.promisedSetState({
                                                            page: 1
                                                        });
                                                        this.functions.getAgents(false, false, true);
                                                    }, 300);
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {
                            this.state.client.id &&
                            !this.state.client.google &&
                            !this.state.manager.id &&
                            (this.state.client && this.state.client.googleScaleSettings && this.state.client.googleScaleSettings.createAccounts) &&
                            <div className="w-32 pt-5.5 ml-2">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_new_account) {
                                            this.setState({
                                                create_account: true
                                            });
                                        }
                                    }}
                                    className={"cursor-pointer bg-google-500 inline-flex relative justify-center w-full rounded-md border border-transparent items-center h-12 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    <span>Create new</span>
                                    {
                                        this.state.loading_new_account &&
                                        <div className={"bg-google-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        }
                    </div>
                }
                {
                    ((this.state.client.id && (this.state.client.google || this.state.agent.id !== 0))) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={!this.state.agency.google ? (this.state.client.google && this.state.client.google.managerId ? "Manager" : "Managers") : (this.state.agency.google && this.state.agency.google.managerId ? "Manager" : "Managers")}
                                loader={this.state.loading_managers}
                                searchInput={true}
                                locked={(!this.state.agency.google ? this.state.client.google : this.state.agency.google.managerId) || this.state.lock_settings}
                                selected={!this.state.agency.google ? (this.state.client.google && this.state.client.google.managerId ? { name: this.state.client.google.managerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" })) : (this.state.agency.google && this.state.agency.google.managerId ? { name: this.state.agency.google.managerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" }))}
                                options={this.state.managers}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        manager: value,
                                        customer: {},
                                        ocid: ""
                                    });
                                    if (!this.state.agency.google) {
                                        this.functions.listCustomers();
                                    }
                                }}
                            />
                        </div>
                        {
                            (this.state.manager.id || (this.state.client.google && this.state.client.google.managerId)) &&
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.google && this.state.client.google.managerId) || this.state.manager.id)}
                                copyText={(this.state.client.google && this.state.client.google.managerId) ? this.state.client.google.managerId : this.state.manager.id}
                            />
                        }
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    (this.state.manager.id || (this.state.client.google && this.state.client.google.managerId)) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                limitList={true}
                                searchInput={true}
                                label={this.state.client.google && this.state.client.google.clientId ? "Account" : "Accounts"}
                                loader={this.state.loading_customers}
                                locked={this.state.client.google || this.state.lock_settings}
                                selected={this.state.client.google && this.state.client.google.clientId ? { name: this.state.client.google.clientName } : (this.state.customer.id ? this.state.customer : { name: "Select customer" })}
                                options={this.state.customers}
                                onChange={async (value) => {

                                    //PRESET CURRENCY
                                    let currency = null;
                                    this.state.currencies.map((item) => {
                                        if (item.id == value.currencyCode) {
                                            currency = item;
                                        }
                                    });
                                    if (currency) {
                                        this.state.currency = currency;
                                    }

                                    this.setState({
                                        customer: value,
                                        ocid: ""
                                    });
                                }}
                            />
                        </div>
                        {
                            (this.state.customer.id || (this.state.client.google && this.state.client.google.clientId)) &&
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.google && this.state.client.google.clientId) || this.state.customer.id)}
                                copyText={(this.state.client.google && this.state.client.google.clientId) ? this.state.client.google.clientId : this.state.customer.id}
                            />
                        }
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    (this.state.customer.id || (this.state.client.google && this.state.client.google.clientId)) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.google || this.state.lock_settings}
                            selected={this.state.client.google && this.state.client.google.clientId ? { name: this.state.client.google.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    (this.state.customer.id || (this.state.client.google && this.state.client.google.managerId)) &&
                    <div className="mt-5 flex flex-row">
                        <div className="flex-1">
                            <InputTailwind
                                label={"OCID"}
                                locked={false}
                                value={"updatedOcid" in this.state ? this.state.updatedOcid : (this.state.client.google && this.state.client.google.clientId ? this.state.client.google.ocid : this.state.ocid)}
                                onChange={async (value) => {
                                    if (this.state.client.google) {
                                        await this.promisedSetState({
                                            updatedOcid: value
                                        });
                                    } else {
                                        this.setState({
                                            ocid: value
                                        });
                                    }
                                }}
                            />
                        </div>
                        {
                            "updatedOcid" in this.state && typeof this.state.updatedOcid === "string" &&
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_ocid) {
                                            this.functions.updateOcid();
                                        }
                                    }}
                                    className={(true ? "bg-google-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-5.5 ml-2 shadow inline-flex relative justify-center rounded-md border border-transparent py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    <span>Save</span>
                                    {
                                        this.state.loading_ocid &&
                                        <div className={"bg-google-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.client.google && this.state.client.google.managerId &&
                    <div className="pt-5">
                        <div className="">
                            <div className="text-xs mb-1">
                                Auto tagging
                            </div>
                            <div className="h-12 relative flex flex-row items-center w-full border-1.5 rounded-md bg-custom-input px-4">
                                {
                                    this.state.loading_auto_tagging &&
                                    <div className={"bg-white absolute bg-opacity-25 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                <div className="flex flex-1 text-sm">
                                    {
                                        this.state.auto_tagging &&
                                        <span>Enabled</span>
                                    }
                                    {
                                        !this.state.auto_tagging &&
                                        <span>Disabled</span>
                                    }
                                </div>
                                <SwitchTailwind
                                    value={this.state.auto_tagging}
                                    onSwitch={async () => {
                                        this.functions.updateAutoTagging();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    <nav className="flex space-x-4 border-t mt-5 pt-5" aria-label="Tabs">
                        {[{ id: 4, name: "Account budgets" }, { id: 2, name: "Conversion actions" }, { id: 3, name: "Bidding strategies" }].filter((item) => {
                            return !item.hidden
                        }).map((tab) => {
                            return (
                                <div
                                    onClick={() => {
                                        this.setState({
                                            subtab: tab
                                        });
                                    }}
                                    key={tab.name}
                                    style={{ whiteSpace: "nowrap" }}
                                    className={cn(
                                        tab.id == this.state.subtab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                        'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                    )}
                                    aria-current={tab.id == this.state.subtab.id ? 'page' : undefined}
                                >
                                    {tab.name}
                                </div>
                            );
                        })}
                    </nav>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.subtab.id == 4 &&
                    <CreateAccountBudget
                        client={this.state.client}
                        channel={"google"}
                    />
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.subtab.id == 2 &&
                    <div className="mt-5 w-full grid grid-cols-12 gap-3 relative">
                        {
                            this.state.loading_conversion &&
                            <div className={"bg-white w-full h-full absolute bg-opacity-50 top-0 bottom-0 z-53 left-0 right-0 flex justify-center items-center"}>
                                <div style={{ borderTopColor: "transparent" }}
                                    class="w-10 h-10 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                            </div>
                        }
                        <div className="col-span-4">
                            <InputTailwind
                                label={"Name"}
                                value={this.state.conversion_action_name}
                                onChange={async (value) => {
                                    this.setState({
                                        conversion_action_name: value
                                    });
                                }}
                            />
                        </div>
                        <div className="col-span-4">
                            <DropdownTailwind
                                label={"Type"}
                                selected={this.state.conversion_action_type}
                                options={[
                                    { id: 1, name: "Webpage", value: "WEBPAGE" },
                                    { id: 2, name: "Calls from ads", value: "AD_CALL" }
                                ]}
                                onChange={async (value) => {
                                    this.setState({
                                        conversion_action_type: value
                                    });
                                }}
                            />
                        </div>
                        <div className="col-span-4">
                            <DropdownTailwind
                                label={"Type"}
                                selected={this.state.conversion_action_category}
                                options={[
                                    { id: 1, name: "Default", value: "DEFAULT" },
                                    { id: 2, name: "Page view", value: "PAGE_VIEW" },
                                    { id: 3, name: "Purchase", value: "PURCHASE" },
                                    { id: 4, name: "Signup", value: "SIGNUP" },
                                    { id: 5, name: "Add to cart", value: "ADD_TO_CART" },
                                    { id: 6, name: "Begin checkout", value: "BEGIN_CHECKOUT" },
                                    { id: 7, name: "Subscripe paid", value: "SUBSCRIBE_PAID" },
                                    { id: 8, name: "Phone call lead", value: "PHONE_CALL_LEAD" },
                                    { id: 9, name: "Imported lead", value: "IMPORTED_LEAD" },
                                    { id: 10, name: "Submit lead form", value: "SUBMIT_LEAD_FORM" },
                                    { id: 11, name: "Book appointment", value: "BOOK_APPOINTMENT" },
                                    { id: 12, name: "Request quote", value: "REQUEST_QUOTE" },
                                    { id: 13, name: "Get directions", value: "GET_DIRECTIONS" },
                                    { id: 14, name: "Outbound click", value: "OUTBOUND_CLICK" },
                                    { id: 15, name: "Contact", value: "CONTACT" },
                                    { id: 16, name: "Engagement", value: "ENGAGEMENT" },
                                    { id: 17, name: "Store visit", value: "STORE_VISIT" },
                                    { id: 18, name: "Store sale", value: "STORE_SALE" },
                                    { id: 19, name: "Qualified lead", value: "QUALIFIED_LEAD" },
                                    { id: 20, name: "Converted lead", value: "CONVERTED_LEAD" }
                                ]}
                                onChange={async (value) => {
                                    this.setState({
                                        conversion_action_category: value
                                    });
                                }}
                            />
                        </div>
                        <div className="col-span-12">
                            <div onClick={() => {
                                if (!this.state.loading_conversion && typeof this.state.conversion_action_name === "string" && this.state.conversion_action_name !== "") {
                                    this.functions.createConversionAction();
                                }
                            }} className={(typeof this.state.conversion_action_name === "string" && this.state.conversion_action_name !== "" ? "cursor-pointer text-purple-500 hover:text-purple-700" : " text-gray-600 cursor-not-allowed") + " inline-flex  flex-row items-center"}>
                                <PlusIcon className="w-5 mr-2" />
                                <div className="text-sm font-medium">
                                    Create new
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.subtab.id == 2 &&
                    this.state.error_massage !== "" &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.error_massage}
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.subtab.id == 2 &&
                    Array.isArray(this.state.conversion_actions) && this.state.conversion_actions.length > 0 &&
                    <div className="grid grid-cols-12 gap-3 mt-7 relative min-h-24">
                        {
                            this.state.loading_conversions &&
                            <div className={"bg-white w-full h-full absolute bg-opacity-50 top-0 bottom-0 z-53 left-0 right-0 flex justify-center items-center"}>
                                <div style={{ borderTopColor: "transparent" }}
                                    class="w-10 h-10 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                            </div>
                        }
                        {
                            this.state.conversion_actions.map((item) => {
                                return (
                                    <div className="col-span-12 flex flex-row border-b pb-3">
                                        <div className="flex-1 flex flex-row text-sm items-center font-medium truncate whitespace-no-wrap">
                                            <div className="truncate whitespace-no-wrap">
                                                {item.conversionAction.name}
                                            </div>
                                            <div className="ml-4">
                                                <span
                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                        {
                                                            ["bg-green-100 text-green-500"]: item.conversionAction.status == "ENABLED",
                                                            ["bg-red-100 text-orange-500 bg-opacity-50"]: item.conversionAction.status != "ENABLED"
                                                        })
                                                    }>
                                                    {item.conversionAction.status}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pr-4 underline text-sm font-medium">
                                            <div onClick={() => {
                                                this.setState({
                                                    conversion_action: item
                                                });
                                            }} className="text-purple-500 hover:text-purple-700 cursor-pointer">Open</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.subtab.id == 1 &&
                    <div className="grid grid-cols-12 gap-3">
                        <div className="mt-5 col-span-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Blilling setup"}
                                loader={this.state.loading_billings}
                                locked={false}
                                selected={this.state.billing_setup}
                                options={this.state.billings}
                                onChange={async (value) => {
                                    this.setState({
                                        billing_setup: value
                                    });
                                    this.functions.listAccountBudget(value);
                                }}
                            />
                        </div>
                        <div className="mt-5 col-span-3">
                            <InputTailwind
                                label={"Budget month limit"}
                                type={'number'}
                                value={this.state.budget_limit}
                                onChange={(value) => {
                                    if (value < 0) value = 0;
                                    this.setState({ budget_limit: value })
                                }}
                            />
                        </div>
                        <div className={"mt-5 col-span-" + (this.state.budget_exist ? "2" : "4")}>
                            <div className={"mt-5.5 cursor-pointer whitespace-nowrap py-3 border-b-2 font-medium text-sm border-t-1.5 text-center border-r-1.5 border-l-1.5 rounded-md px-" + (this.state.budget_exist ? "2" : "2") + (this.state.budget_limit > 0 && this.state.billing_setup.id > 0 ? " bg-purple-500 hover:bg-purple-600 text-white" : " bg-gray-100 text-gray-600")}
                                onClick={async () => {
                                    if (this.state.budget_limit > 0 && this.state.billing_setup.id > 0 && this.state.budget_exist) {
                                        this.functions.updateAccountBudget(this.state.budget_limit);
                                    } else if (this.state.budget_limit > 0 && this.state.billing_setup.id > 0 && !this.state.budget_exist) {
                                        this.functions.createAccountBudget();
                                    }
                                }}
                            >
                                {(this.state.budget_exist ? "Update" : "Create")}
                            </div>
                        </div>
                        {this.state.budget_exist &&
                            <div className={"mt-5 col-span-" + (this.state.budget_exist ? "2" : "4")}>
                                <div className={"mt-5.5 cursor-pointer bg-red-500 hover:bg-red-600 text-white text-center whitespace-nowrap py-3 border-b-2 font-medium text-sm border-t-1.5 border-r-1.5 border-l-1.5 rounded-md px-2"}
                                    onClick={async () => {
                                        this.functions.updateAccountBudget(0);
                                    }}
                                >
                                    Remove
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.subtab.id == 1 &&
                    this.state.error_massage !== "" &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.error_massage}
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.account_budgets.length > 0 &&
                    this.state.subtab.id == 1 &&
                    <div className="grid grid-cols-12 gap-3">
                        <div className="mt-5 col-span-11 grid grid-cols-7 gap-4">
                            <div className="col-span-2">
                                <div className=" font-bold">
                                    Start
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className=" font-bold">
                                    End
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className=" font-bold">
                                    Limit
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className=" font-bold">
                                    Status
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 col-span-1">
                        </div>
                        {

                            this.state.account_budgets.map((item) => {
                                return (
                                    <Fragment>
                                        <div className="mt-5 col-span-11 grid grid-cols-7 gap-4">
                                            <div className="col-span-2">
                                                <div className="">
                                                    {moment(item.proposedStartDateTime).format("YYYY-MM-DD")}
                                                </div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="">
                                                    {item.proposedEndTimeType && item.proposedEndTimeType === "FOREVER" ? "FOREVER" : moment(item.proposedEndDateTime).format("YYYY-MM-DD")}
                                                </div>
                                            </div>
                                            <div className="col-span-1">
                                                <div className="">
                                                    {parseInt(item.proposedSpendingLimitMicros) / 1000000}
                                                </div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className={"px-4 py-1 inline-flex items-centertext-xs font-bold rounded-full " + (item.status && item.status === "APPROVED" ? "bg-green-100 text-green-500" : "")}>
                                                    {item.status ? item.status : "-"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-1">
                                            {item.status && item.status !== "PENDING" &&
                                                <div className="mt-2 cursor-pointer bg-red-500 hover:bg-red-600 text-white whitespace-nowrap py-3 px-2.5 border-b-2 font-medium text-sm border-t-1.5 border-r-1.5 border-l-1.5 rounded-md "
                                                    onClick={async () => {
                                                        this.functions.deleteAccountBudget(item);
                                                    }}
                                                >
                                                    <TrashIcon className="h-5 w-5" />
                                                </div>
                                            }
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    this.state.subtab.id == 3 &&
                    Array.isArray(this.state.bidding_strategies) && this.state.bidding_strategies.length > 0 &&
                    <div className="grid grid-cols-12 gap-3 mt-7 relative min-h-24">
                        {
                            this.state.loading_conversions &&
                            <div className={"bg-white w-full h-full absolute bg-opacity-50 top-0 bottom-0 z-53 left-0 right-0 flex justify-center items-center"}>
                                <div style={{ borderTopColor: "transparent" }}
                                    class="w-10 h-10 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                            </div>
                        }
                        {
                            this.state.bidding_strategies.map((item) => {
                                return (
                                    <div className="col-span-12 flex flex-row border-b pb-3">
                                        <div className="flex-1 flex flex-row text-sm items-center font-medium truncate whitespace-no-wrap">
                                            <div className="truncate whitespace-no-wrap">
                                                {item.name}
                                            </div>
                                            <div className="ml-4">
                                                <span
                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full capitalize",
                                                        {
                                                            ["bg-green-100 text-green-500"]: true,
                                                        })
                                                    }>
                                                    {this.state.strategies.filter((inner_item) => { return inner_item.value === item.type }).length > 0 ? this.state.strategies.filter((inner_item) => { return inner_item.value === item.type })[0].name : item.type}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pr-4 underline text-sm font-medium">
                                            <div onClick={() => {
                                                this.setState({
                                                    bidding_strategy_action: item
                                                });
                                            }} className="text-purple-500 hover:text-purple-700 cursor-pointer">Open</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    ((this.state.agency.google && this.state.agency.google.authenticated && this.state.manager.id) || (this.state.client.id &&
                        !this.state.client.google &&
                        this.state.customer.id &&
                        this.state.manager.id)) &&
                    <div className="flex w-full flex-row">
                        <div className="flex flex-1">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        if (!this.state.agency.google) {
                                            this.functions.connectToClient();
                                        } else {
                                            this.functions.connectToAgency();
                                        }
                                    }
                                }}
                                className={(((this.state.agency.google && this.state.agency.google.authenticated && this.state.manager.id) || this.state.customer.id) ? "bg-google-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save Google account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-google-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                        {
                            !this.state.agency.google &&
                            <div>
                                <button
                                    onClick={() => {
                                        window.open("https://ads.google.com/nav/selectaccount");
                                    }}
                                    className={(this.state.customer.id ? "bg-purple-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    <span> Open Adwords</span>
                                </button>
                            </div>
                        }
                    </div>
                }
                {
                    !this.state.agency.google &&
                    !this.state.client.id &&
                    (!this.state.user.google || (this.state.user.google && !this.state.user.google.authenticated)) &&
                    <div onClick={() => {
                        let self = this;
                        const script = document.createElement('script');
                        script.setAttribute("id", "google_api");
                        script.src = "https://apis.google.com/js/platform.js";
                        script.async = true;
                        script.defer = true;
                        script.onload = () => window.gapi.load('auth2', initClient);
                        document.body.appendChild(script);

                        const initClient = () => {
                            window['gapi'].auth2.init({
                                client_id: '308101956397-dmf2hvrqrfgpgvttb22t2eljgelp6ook.apps.googleusercontent.com',
                                cookiepolicy: 'single_host_origin',
                                scope: 'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content'
                            }).then(() => {
                                const auth = window['gapi'].auth2.getAuthInstance();
                                auth.grantOfflineAccess().then(res => {
                                    self.functions.login(res.code);
                                    document.body.removeChild(script);
                                }).catch(function (error) {
                                    document.body.removeChild(script);
                                });
                            });
                        };
                    }} className="mt-5">
                        <img className="w-48 cursor-pointer" src={require("../assets/images/google_signin_button_2.png")} />
                    </div>
                }
                {/* TEMP */}
                {
                    false &&
                    !this.state.agency.google &&
                    !this.state.client.id &&
                    (!this.state.user.google || (this.state.user.google && !this.state.user.google.authenticated)) &&
                    <div className="mt-5">
                        <button onClick={() => {

                            let self = this;
                            const script = document.createElement('script');
                            script.setAttribute("id", "google_api");
                            script.src = "https://apis.google.com/js/platform.js";
                            script.async = true;
                            script.defer = true;
                            script.onload = () => window.gapi.load('auth2', initClient);
                            document.body.appendChild(script);

                            const initClient = () => {
                                window['gapi'].auth2.init({
                                    client_id: '308101956397-dmf2hvrqrfgpgvttb22t2eljgelp6ook.apps.googleusercontent.com',
                                    cookiepolicy: 'single_host_origin',
                                    scope: 'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content'
                                }).then(() => {
                                    const auth = window['gapi'].auth2.getAuthInstance();
                                    auth.grantOfflineAccess().then(res => {
                                        self.functions.login(res.code);
                                        document.body.removeChild(script);
                                    }).catch(function (error) {
                                        document.body.removeChild(script);
                                    });
                                });
                            };
                        }} className={(!(this.state.user.google && this.state.user.google.authenticated) ?
                            "cursor-pointer bg-google-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login with Google</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.google && this.state.user.google.authenticated) ? "bg-google-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.client.id &&
                    this.state.user.google && this.state.user.google.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.google && this.state.user.google.authenticated) ?
                                "cursor-pointer bg-google-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.google && this.state.user.google.authenticated) ? "bg-google-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    false &&
                    this.state.agency.google &&
                    this.state.agency.google.authenticated &&
                    <div className="mt-5 w-full">
                        <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4"}>
                            {[
                                { name: "Clients", stat: this.state.agency.google.google_connected },
                                { name: "Managers", stat: this.state.agency.google.matching_managers },
                                { name: "Ad accounts", stat: this.state.agency.google.matching_accounts },
                                { name: "Campaigns", stat: this.state.agency.google.campaigns }
                            ].map((item) => (
                                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-5">
                                    <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }
                {
                    this.state.agency.google &&
                    this.state.agency.google.authenticated &&
                    this.state.syncManagers &&
                    <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                        {this.state.syncMessage}
                    </div>
                }
                {
                    this.state.error &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.healthcheck && this.state.healthcheck.google && this.state.healthcheck.google.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.google.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.agency.google &&
                    !this.state.user.id &&
                    this.state.client.google &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.google && this.state.client.google.defaultAgent && this.state.client.google.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.agency.google &&
                    !this.state.user.id &&
                    !this.state.is_manager &&
                    !this.state.client.google &&
                    !(this.state.me.google && this.state.me.google.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated Google, go to My Profile.
                    </div>
                }
                {
                    !this.state.agency.google &&
                    !this.state.user.id &&
                    this.state.is_manager &&
                    !this.state.client.google &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated Google
                    </div>
                }
            </div >
        )
    }
}

export default AccountGoogle;
