class adgroupTikTok {
    
    static validate(adgroup) {
        let error = {
            name: false,
            budget: false,
            location: false,
            start_date:false,
            end_date:false,
            bid_cap:false,
        };
        if (adgroup.budget_mode) {
            if (adgroup.budget_mode.value === "BUDGET_MODE_TOTAL" && (adgroup.total_budget === "" || parseInt(adgroup.total_budget) < 1 || isNaN(parseInt(adgroup.total_budget)))) {
                error.budget = true;
            } else if (adgroup.budget_mode.value === "BUDGET_MODE_DAY" && (adgroup.daily_budget === "" || parseInt(adgroup.daily_budget) < 1 || isNaN(parseInt(adgroup.daily_budget)))) {
                error.budget = true;
            }
        }
        if (!adgroup.locations || adgroup.locations.filter((item) => {return item.included}).length<1) {
                error.location = true;
        }
        if (adgroup.start_date && !adgroup.status) {
            if (new Date(adgroup.start_date).getTime() <= new Date().getTime()) {
                error.start_date = true;
            }
        }
        if (adgroup.name === "") error.name = true;
        if(adgroup.bid_strategy && adgroup.bid_strategy.id === 2) {
            if (!adgroup.bid_cap || adgroup.bid_cap <= 0 || isNaN(adgroup.bid_cap) || adgroup.bid_cap === "") error.bid_cap = true;
        }
        if (adgroup.end_date && !adgroup.status) {
            if (new Date(adgroup.end_date).getTime() <= new Date(adgroup.start_date).getTime() || new Date(adgroup.end_date).getTime() <= new Date().getTime()) {
                error.end_date = true;
            }
        }
        return error;
    }
}
export { adgroupTikTok }