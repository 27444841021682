// initData for tabsFiltering

let data1 = [];
let data2 = [];
let data3 = [];
let data4 = [];
let data5 = [];
let data6 = [];

function randomInteger(min, max) {
    let rand = Math.floor(min + Math.random() * (max + 1 - min));
    return Math.floor(rand);
}

for (let i = 0; i < 6; i++) {
    data1.push(randomInteger(0, 10000));
    data2.push(randomInteger(0, 10000));
    data3.push(randomInteger(0, 10000));
    data4.push(randomInteger(0, 10000));
    data5.push(randomInteger(0, 10000));
    data6.push(randomInteger(0, 10000));
}

export const initialFakeData = [
    {
        id: 1,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Total Impressions',
                data: 28087409,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Gross reach',
                data: 4717605,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Clicks',
                data: 105337,
                typeOfBlock: 'number',
            },
            {
                i: '4',
                x: 0,
                y: 1,
                w: 4,
                h: 2,
                static: true,
                data: {
                    labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

                    datasets: [
                        {
                            label: 'Clicks',
                            backgroundColor: '#DBDAFA',
                            hoverBorderColor: '#DBDAFA',
                            data: data1,
                        },

                        {
                            label: 'CTR',
                            backgroundColor: '#FCDAC3',
                            hoverBorderColor: '#FCDAC3',
                            data: data2,
                        },
                    ],
                },
                header: 'Clicks & CRT%',
                typeOfBlock: 'bar',
            },
            {
                i: '5',
                x: 0,
                y: 3,
                w: 1,
                h: 1,
                static: true,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '6',
                x: 1,
                y: 3,
                w: 1,
                h: 1,
                static: true,
                header: 'Spend',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '7',
                x: 2,
                y: 3,
                w: 2,
                h: 2,
                static: true,
                header: 'Conversions platform',
                data: {
                    labels: ['Bonnier', 'Prog. Display'],
                    datasets: [
                        {
                            data: [50, 70],
                            backgroundColor: ['#C3FCE0', '#6FDB9D'],
                            hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
                        },
                    ],
                    text: '23%',
                },
                typeOfBlock: 'doughnut',
            },
            {
                i: '8',
                x: 0,
                y: 4,
                w: 1,
                h: 1,
                static: true,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '9',
                x: 1,
                y: 4,
                w: 1,
                h: 1,
                static: true,
                header: 'Spend',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '10',
                x: 0,
                y: 5,
                w: 2,
                h: 2,
                static: true,
                header: 'screenshot',
                data: 'https://support.apple.com/library/content/dam/edam/applecare/images/en_US/mac_apps/safari/macos-big-sur-safari-screenshot-portion.jpg',
                typeOfBlock: 'img',
            },
            {
                i: '11',
                x: 2,
                y: 5,
                w: 2,
                h: 2,
                static: true,
                header: 'screenshot',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '12',
                x: 0,
                y: 7,
                w: 1,
                h: 1,
                static: true,
                header: 'screenshot',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '13',
                x: 1,
                y: 7,
                w: 1,
                h: 2,
                static: true,
                header: 'screenshot',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
        ],
    },
    {
        id: 2,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Clicks',
                data: 12415,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Gross reach',
                data: 534636,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Clicks',
                data: 25432,
                typeOfBlock: 'number',
            },
            {
                i: '4',
                x: 0,
                y: 1,
                w: 4,
                h: 2,
                static: true,
                data: {
                    labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

                    datasets: [
                        {
                            label: 'Clicks',
                            backgroundColor: '#DBDAFA',
                            hoverBorderColor: '#DBDAFA',
                            data: data3,
                        },

                        {
                            label: 'CTR',
                            backgroundColor: '#FCDAC3',
                            hoverBorderColor: '#FCDAC3',
                            data: data4,
                        },
                    ],
                },
                header: 'Clicks & CRT%',
                typeOfBlock: 'bar',
            },
            {
                i: '5',
                x: 0,
                y: 3,
                w: 1,
                h: 1,
                static: true,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '6',
                x: 1,
                y: 3,
                w: 1,
                h: 1,
                static: true,
                header: 'Spend',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '7',
                x: 2,
                y: 3,
                w: 2,
                h: 2,
                static: true,
                header: 'Conversions platform',
                data: {
                    labels: ['Bonnier', 'Prog. Display'],
                    datasets: [
                        {
                            data: [50, 70],
                            backgroundColor: ['#C3FCE0', '#6FDB9D'],
                            hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
                        },
                    ],
                    text: '23%',
                },
                typeOfBlock: 'doughnut',
            },
            {
                i: '8',
                x: 0,
                y: 4,
                w: 1,
                h: 1,
                static: true,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '9',
                x: 1,
                y: 4,
                w: 1,
                h: 1,
                static: true,
                header: 'Spend',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
        ],
    },
    {
        id: 3,
        data: [
            {
                i: '0',
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Estimated TMP and reach',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
            {
                i: '1',
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Clicks',
                data: 12561,
                typeOfBlock: 'number',
            },
            {
                i: '2',
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Gross reach',
                data: 564235,
                typeOfBlock: 'number',
            },
            {
                i: '3',
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                static: true,
                header: 'Clicks',
                data: 9235,
                typeOfBlock: 'number',
            },
            {
                i: '8',
                x: 0,
                y: 1,
                w: 1,
                h: 1,
                static: true,
                header: 'Results',
                data:
                    '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
                typeOfBlock: 'input',
            },
        ],
    },
    // {
    //     id: 4,
    //     data: [
    //         {
    //             i: '0',
    //             x: 0,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Estimated TMP and reach',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '1',
    //             x: 1,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Clicks facebook',
    //             data: 25932,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '2',
    //             x: 2,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Gross reach',
    //             data: 23523621,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '3',
    //             x: 3,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Clicks youtube',
    //             data: 16953,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '4',
    //             x: 0,
    //             y: 1,
    //             w: 2,
    //             h: 2,
    //             static: true,
    //             data: {
    //                 labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

    //                 datasets: [
    //                     {
    //                         label: 'Clicks',
    //                         backgroundColor: '#DBDAFA',
    //                         hoverBorderColor: '#DBDAFA',
    //                         data: data5,
    //                     },

    //                     {
    //                         label: 'CTR',
    //                         backgroundColor: '#FCDAC3',
    //                         hoverBorderColor: '#FCDAC3',
    //                         data: data6,
    //                     },
    //                 ],
    //             },
    //             header: 'Clicks & CRT% Facebook',
    //             typeOfBlock: 'bar',
    //         },
    //         {
    //             i: '5',
    //             x: 2,
    //             y: 1,
    //             w: 2,
    //             h: 2,
    //             static: true,
    //             data: {
    //                 labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

    //                 datasets: [
    //                     {
    //                         label: 'Clicks',
    //                         backgroundColor: '#DBDAFA',
    //                         hoverBorderColor: '#DBDAFA',
    //                         data: data3,
    //                     },

    //                     {
    //                         label: 'CTR',
    //                         backgroundColor: '#FCDAC3',
    //                         hoverBorderColor: '#FCDAC3',
    //                         data: data4,
    //                     },
    //                 ],
    //             },
    //             header: 'Clicks & CRT% Youtube',
    //             typeOfBlock: 'bar',
    //         },
    //         {
    //             i: '6',
    //             x: 0,
    //             y: 3,
    //             w: 1,
    //             h: 2,
    //             static: true,
    //             header: 'Results Facebook',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '12',
    //             x: 1,
    //             y: 3,
    //             w: 1,
    //             h: 2,
    //             static: true,
    //             header: 'Spend Facebook',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '7',
    //             x: 2,
    //             y: 3,
    //             w: 2,
    //             h: 2,
    //             static: true,
    //             header: 'Conversions platform',
    //             data: {
    //                 labels: ['Bonnier', 'Prog. Display'],
    //                 datasets: [
    //                     {
    //                         data: [50, 70],
    //                         backgroundColor: ['#C3FCE0', '#6FDB9D'],
    //                         hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
    //                     },
    //                 ],
    //                 text: '23%',
    //             },
    //             typeOfBlock: 'doughnut',
    //         },
    //     ],
    // },
    // {
    //     id: 5,
    //     data: [
    //         {
    //             i: '0',
    //             x: 0,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Estimated TMP and reach',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '1',
    //             x: 0,
    //             y: 1,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Clicks Facebook',
    //             data: 12415,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '2',
    //             x: 3,
    //             y: 1,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Gross reach',
    //             data: 46343,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '3',
    //             x: 3,
    //             y: 0,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Clicks Youtube',
    //             data: 12656,
    //             typeOfBlock: 'number',
    //         },
    //         {
    //             i: '4',
    //             x: 1,
    //             y: 0,
    //             w: 2,
    //             h: 2,
    //             static: true,
    //             data: {
    //                 labels: ['Viafree', 'Dplay', 'Expressen TV Instream', 'Expressen TV Bumper ad', 'YouTube Pre roll', 'YouTube Bumper ad'],

    //                 datasets: [
    //                     {
    //                         label: 'Clicks',
    //                         backgroundColor: '#DBDAFA',
    //                         hoverBorderColor: '#DBDAFA',
    //                         data: data3,
    //                     },

    //                     {
    //                         label: 'CTR',
    //                         backgroundColor: '#FCDAC3',
    //                         hoverBorderColor: '#FCDAC3',
    //                         data: data4,
    //                     },
    //                 ],
    //             },
    //             header: 'Clicks & CRT%',
    //             typeOfBlock: 'bar',
    //         },
    //         {
    //             i: '5',
    //             x: 0,
    //             y: 2,
    //             w: 2,
    //             h: 2,
    //             static: true,
    //             header: 'Conversions platform',
    //             data: {
    //                 labels: ['Bonnier', 'Prog. Display'],
    //                 datasets: [
    //                     {
    //                         data: [50, 70],
    //                         backgroundColor: ['#C3FCE0', '#6FDB9D'],
    //                         hoverBackgroundColor: ['#C3FCE0', '#6FDB9D'],
    //                     },
    //                 ],
    //                 text: '23%',
    //             },
    //             typeOfBlock: 'doughnut',
    //         },
    //         {
    //             i: '6',
    //             x: 2,
    //             y: 2,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Results',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '7',
    //             x: 3,
    //             y: 2,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Spend',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },

    //         {
    //             i: '8',
    //             x: 2,
    //             y: 3,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Results',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //         {
    //             i: '9',
    //             x: 3,
    //             y: 3,
    //             w: 1,
    //             h: 1,
    //             static: true,
    //             header: 'Spend',
    //             data:
    //                 '<p><b>Spend</b></p><ul><li>Media spend: 963 889 SEK</li><li>Avarage vCPM: 47,58 SEK</li><li>SPA (step 1): 45,55 SEK</li><li>Budget CTC: 1 085 594 SEK</li></ul>',
    //             typeOfBlock: 'input',
    //         },
    //     ],
    // },
];

export const fakeDataTabs = [
    {
        number: 1,
        name: 'Impressions',
    },
    {
        number: 2,
        name: 'Clicks',
    },
    {
        number: 3,
        name: 'ROI',
    },
];

// export const fakeDataTabs = [
//     {
//         number: 1,
//         text: 'OLV',
//     },
//     {
//         number: 2,
//         text: 'Prog. Display',
//     },
//     {
//         number: 3,
//         text: 'Social - Branding',
//     },
//     {
//         number: 4,
//         text: 'Social - Performance',
//     },
//     {
//         number: 5,
//         text: 'Social - Branding + Performance',
//     },
// ];

export const fakeDataForCalendarFilter = [
    {
        id: 1,
        icon: '',
        text: 'From compaign start to now',
        link: '#',
        active: true,
    },
    {
        id: 2,
        icon: '',
        text: 'Last day',
        link: '#',
        active: false,
    },
    {
        id: 3,
        icon: '',
        text: 'Last week',
        link: '#',
        active: false,
    },
    {
        id: 4,
        icon: '',
        text: 'Last month',
        link: '#',
        active: false,
    },
    {
        id: 5,
        icon: '',
        text: 'Set specific dates',
        link: '#',
        active: false,
    },
];
