import React, {Component} from 'react';
import './style.css';
import cx from "classnames";

class IconTwitter extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    functions = {};

    renders = {};

    render() {
        return (
            <div className={
                cx("Icon-Background", "ButtonGoogle", "bg-twitter-500",
                    {
                        ["Icon-BackgroundNoMargin"]: this.props.noMargin,
                        ["Icon-Large"]: this.props.large,
                        ["Icon-Medium"]: this.props.medium,
                    })
            }>
                <img className="Icon-Twitter" src={require('../../../assets/images/twitter_icon.svg')}/>
            </div>
        )
    }
}

export default IconTwitter;
