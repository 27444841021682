import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import Switch from "react-ios-switch";
import CampaignStatusSocialPreviewFacebookImage from "../campaign-status-social-preview-facebook-image";
import Carousel from 're-carousel'
import { calls } from "./calls";
import ReactTooltip from 'react-tooltip'
var moment = require('moment');

class CampaignOverviewTypeItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            channel: this.props.channel,
            type: this.props.type,
            error: false
        };
    };

    componentWillMount() { }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            channel: this.props.channel,
            type: this.props.type,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            channel: nextProps.channel,
            type: nextProps.type
        });
    }

    init = {};

    functions = {};

    renders = {
        type: () => {
            try {
                return this.state.data.status
            } catch (e) {
                return "-"
            }
        },
        status: () => {
            try {
                return this.state.data.type
            } catch (e) {
                return "-"
            }
        },
        errors: () => {
            try {
                return this.state.data.errors.length
            } catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div style={{ width: '100%' }}>
                <div className="Campaign-Adset-Item">
                    {
                        <div style={{ backgroundColor: '#ffffff' }} className={cx("Campaign-Adset-Item-Channel")}>

                        </div>
                    }
                    <div className={cx("Campaign-Adset-Item-Values")}>
                        {
                            <div style={{ flex: '1', minWidth: '200px' }} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Name"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.state.type}
                                </div>
                            </div>
                        }
                        {
                            <div style={{ flex: '1' }} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Type"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.type()}
                                </div>
                            </div>
                        }
                        {
                            <div style={{ flex: '1' }} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Status"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.status()}
                                </div>
                            </div>
                        }
                        {
                            <div style={{ flex: '1' }} className={cx("Campaign-Adset-Item-Section")}>
                                <div className="Campaign-Adset-Item-Section-Title">
                                    {"Errors"}
                                </div>
                                <div className="Campaign-Adset-Item-Section-Value">
                                    {this.renders.errors()}
                                </div>
                            </div>
                        }
                    </div>
                    <div onClick={() => {
                        this.setState({
                            open: !this.state.open
                        })
                    }} className="Campaign-Adset-Item-Button">
                        {
                            !this.state.open &&
                            <FeatherIcon size={25} color={'#333333'} icon={'chevron-down'} />
                        }
                        {
                            this.state.open &&
                            <FeatherIcon size={25} color={'#333333'} icon={'chevron-up'} />
                        }
                    </div>
                    <div onClick={() => {

                    }} className="Campaign-Adset-Item-Button">
                        {
                            <FeatherIcon size={20} color={'#333333'} icon={'external-link'} />
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default CampaignOverviewTypeItem;
