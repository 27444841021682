import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {calls} from "../../pages/user-optimize-all/calls";
import {BeatLoader} from "react-spinners";
import OptimizeSearchKeywordsItem from "../optimize-search-keywords-item";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchKeywordsAdd from "../optimize-search-keywords-add";
import OptimizeSearchKeywordsAsset from "../optimize-search-keywords-asset";
import {campaignRegister} from "../../services/campaignRegister";
import SearchtermList from "../../modules/searchtermlist";
import NegativeKeywordList from "../../modules/negativekeywordlist";
import CsvDownload from "react-json-to-csv";

class OptimizeSearchKeywords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keywords: [],
            channels: [],
            campaign: {},
            sort: 'text',
            order: 'asc',
            loading: true,
            index: 10,
            pagesize: 10,
            keyword_text: "",
            keyword_channel: {},
            keyword_type: {},
            client: {},
            search: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            keywords: nextProps.keywords,
            loading: nextProps.loading
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            keywords: this.props.keywords,
            loading: this.props.loading,
            channels: this.props.channels
        });
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div>
                {
                    !this.state.loading &&
                    <div className="flex flex-1 justify-end">
                        <button onClick={() => {
                            this.setState({
                                advanced: !this.state.advanced
                            })
                        }} className="py-2 pt-0 pl-2 text-xs text-purple-500 hover:underline inline-flex flex-row items-center relative">
                            <FeatherIcon className="stroke-current mr-2" size={15} icon="zap"/>
                            {!this.state.advanced&&
                            <span>Open advanced</span>
                            }
                            {this.state.advanced&&
                            <span>Close advanced</span>
                            }
                        </button>
                    </div>
                }
                {
                    this.state.advanced &&
                    <OptimizeSearchKeywordsAsset
                        campaign={this.state.campaign}
                        keywords={this.state.keywords}
                    />
                }
                {
                    this.state.advanced &&
                    <OptimizeSearchKeywordsAdd
                        campaign={this.state.campaign}
                        onAdd={(value) => {
                            this.props.onAdd(value);
                        }}
                    />
                }
                {
                    !this.state.loading &&
                    <NegativeKeywordList
                        campaign={this.state.campaign}
                        campaignitems={this.state.keywords}
                        properties={{channel: true, text: true, type: true, level: true, actions: {delete: true}}}
                        sort={this.state.sort}
                        order={this.state.order}
                        pagesize={this.state.pagesize}
                        updateSort={(sort, order) => {
                            this.setState({
                                sort: sort,
                                order: order
                            })
                        }}
                        onRemove={(value) => {
                            this.props.onRemove(value);
                        }}
                    />
                }
                {
                    !this.state.loading && (this.state.keywords.length > this.state.pagesize) &&
                    <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                        <div onClick={(e) => {
                            this.setState({
                                pagesize: this.state.pagesize + 10
                            })
                        }} className="btn btn-primary btn-lg max-w-md flex-1">
                            <div>
                                Show more ({this.state.keywords.length}/{this.state.pagesize})
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchKeywords;
