import React, { Component, Fragment } from 'react';
import cx from "classnames";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HomeIcon, UsersIcon, FolderIcon, CalendarIcon, InboxIcon, ChartBarIcon, XIcon, MenuAlt2Icon, SearchIcon, BellIcon, OfficeBuildingIcon, CurrencyDollarIcon, AdjustmentsIcon, ChartPieIcon, VariableIcon, PlayIcon, PauseIcon, ExclamationIcon, CloudIcon, HandIcon, ClockIcon, LightBulbIcon, PlusIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import { SelectorIcon } from '@heroicons/react/solid';
import { apiRegister } from '../services/apiRegister';
import { clientRegister } from '../services/clientRegister';

class SideNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            status: {},
            user: {},
            menuOpen: false,
            mobile_menu: false
        }
    };

    componentWillMount() {
        this.init.menu();
        this.init.user();
    }

    componentDidMount() { }

    init = {
        menu: () => {
            let menu = [];
            if (userRegister.get().type === 'admin') {
                menu = [
                    {
                        title: "Dashboard",
                        link: "/user/dashboard",
                        active: "/user/dashboard",
                        newIcon: HomeIcon,
                    },
                    {
                        title: "Users",
                        link: "/user/users/all",
                        active: "/user/users",
                        newIcon: UsersIcon,
                    },
                    {
                        title: "Agencies",
                        link: "/user/agencies/all",
                        active: "/user/agencies",
                        newIcon: OfficeBuildingIcon,
                    },
                    {
                        title: "Finance",
                        link: "/user/finance",
                        active: "/user/finance",
                        newIcon: CurrencyDollarIcon,
                    }
                ];
            } else if (userRegister.get().type === 'agent') {
                menu = [
                    {
                        title: "Dashboard",
                        link: "/v2/dashboard",
                        active: "/v2/dashboard",
                        newIcon: HomeIcon,
                    },
                    /*
                    {
                        headline: "Orders",
                        title: "Orders",
                        link: null,
                        active: "/v2/orders",
                        submenu: [
                            {
                                title: "All",
                                narrowTitle: "All orders",
                                parent: "/v2/orders",
                                link: "/v2/orders",
                                active: "/v2/orders",
                                newIcon: FolderIcon,
                                hideNarrow: false,
                            },
                            {
                                title: "Active",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=active",
                                active: "/v2/orders/active",
                                newIcon: PlayIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Upcoming",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=upcoming",
                                active: "/v2/orders?status=upcoming",
                                newIcon: ClockIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Pending",
                                parent: "/user/orders",
                                link: "/v2/orders?status=pending",
                                active: "/v2/orders?status=pending",
                                newIcon: LightBulbIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Paused",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=paused",
                                active: "/v2/orders?status=paused",
                                newIcon: PauseIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Error",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=error",
                                active: "/v2/orders?status=error",
                                newIcon: ExclamationIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Drafted",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=drafted",
                                active: "/v2/orders?status=drafted",
                                newIcon: CloudIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Ended",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=ended",
                                active: "/v2/orders?status=ended",
                                newIcon: HandIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Create new order",
                                parent: "/v2/orders",
                                link: "/v2/orders/new",
                                active: "/v2/orders/new",
                                type: "btn",
                                newIcon: PlusCircleIcon,
                                hideNarrow: true,
                            },
                        ]
                    },
                    */
                    {
                        headline: "Management",
                        title: "Management",
                        link: null,
                        active: "/user/management",
                        submenu: [
                            {
                                title: "Orders",
                                link: "/v2/orders",
                                active: "/v2/orders",
                                newIcon: FolderIcon,
                            },
                            {
                                type: 0,
                                title: "Optimize",
                                narrowTitle: "Optimize",
                                link: "/user/optimize/all",
                                active: "/user/optimize",
                                newIcon: AdjustmentsIcon,
                            },
                            /*
                            {
                                type: 0,
                                title: "Reports",
                                narrowTitle: "Reports",
                                link: "/user/reports/all",
                                active: "/user/reports",
                                newIcon: ChartPieIcon,
                            },
                            */
                            {
                                type: 0,
                                title: "Clients",
                                narrowTitle: "Clients",
                                link: "/user/clients/all",
                                active: "/user/clients",
                                newIcon: UsersIcon,
                            },
                            /*
                            {
                                type: 0,
                                title: "Metrics",
                                narrowTitle: "Metrics",
                                link: "/user/metrics",
                                active: "/user/metrics",
                                newIcon: VariableIcon,
                            }
                            */
                        ]
                    },
                    {
                        headline: "Analytics",
                        title: "Analytics",
                        link: null,
                        active: "/v2/analytics",
                        submenu: [
                            {
                                type: 0,
                                title: "Reports",
                                narrowTitle: "Reports",
                                link: "/v2/reports/all",
                                active: "/v2/reports",
                                newIcon: ChartPieIcon,
                            },
                            {
                                type: 0,
                                title: "Metrics",
                                narrowTitle: "Metrics",
                                link: "/v2/metrics",
                                active: "/v2/metrics",
                                newIcon: VariableIcon,
                            }
                        ]
                    },
                ];
            } else {
                menu = [];
            }
            this.setState({
                menu: menu
            });
        },
        status: () => {

        },
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get()
                })
            }
        }
    };

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        toggleMenu: () => {
            this.setState({ menuOpen: !this.state.menuOpen })
        },
        status: () => {

        }
    };

    renders = {
        firstLetter: () => {
            try {
                return this.state.user.name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        campaigns: (status) => {
            try {
                return this.state.status[status.toLowerCase()];
            } catch (e) {
                return '';
            }
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        }
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>

                {
                    window.location.href.indexOf("v2/orders/new") !== -1 &&
                    <div className="w-20">
                        <div className="hidden md:flex md:flex-col md:fixed md:inset-y-0">

                            <nav aria-label="Sidebar" className="hidden md:block flex-1 md:flex-shrink-0 md:bg-purple-900">
                                <div className="relative w-20 flex h-full items-center flex-col space-y-3">
                                    <div className="h-16 w-20 bg-white">

                                    </div>
                                    {
                                        this.state.menu.map((item) => (
                                            <Fragment>
                                                {
                                                    item.link &&
                                                    <div className="relative">
                                                        <div
                                                            onClick={(e) => {
                                                                //e.preventDefault();
                                                                this.props.history.push(item.link)
                                                            }}
                                                            onMouseOver={() => {
                                                                item.hover = true;
                                                                this.setState({
                                                                    menu: this.state.menu
                                                                })
                                                            }}
                                                            onMouseLeave={() => {
                                                                item.hover = false;
                                                                this.setState({
                                                                    menu: this.state.menu
                                                                })
                                                            }}
                                                            key={item.title}

                                                            className={classNames(
                                                                window.location.href.indexOf(item.active) !== -1 ? 'bg-white text-purple-400' : 'text-gray-400 hover:bg-white hover:bg-opacity-25',
                                                                'flex-shrink-0 inline-flex relative cursor-pointer items-center justify-center h-12 w-12 rounded-md'
                                                            )}
                                                        >
                                                            <span className="sr-only">{item.title}</span>
                                                            {
                                                                item.newIcon &&
                                                                <item.newIcon className="h-6 w-6" aria-hidden="true" />
                                                            }
                                                        </div>
                                                        <div
                                                            style={{ left: "80px", top: "5px" }} className={(item.hover ? "opacity-100" : "opacity-0") + " absolute h-10 whitespace-no-wrap rounded-md flex justify-center items-center bg-purple-900 text-white"}>
                                                            <div className="px-4 whitespace-no-wrap">
                                                                {item.title}
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                                {
                                                    Array.isArray(item.submenu) &&
                                                    item.submenu.filter((item) => { return !item.hideNarrow }).map((child) => {
                                                        return (
                                                            <div className="relative">
                                                                <div
                                                                    onClick={(e) => {
                                                                        //e.preventDefault();
                                                                        this.props.history.push(child.link)
                                                                    }}
                                                                    key={child.title}
                                                                    onMouseOver={() => {
                                                                        child.hover = true;
                                                                        this.setState({
                                                                            menu: this.state.menu
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        child.hover = false;
                                                                        this.setState({
                                                                            menu: this.state.menu
                                                                        })
                                                                    }}
                                                                    className={classNames(
                                                                        window.location.href.indexOf(child.active) !== -1 ? 'bg-white text-purple-400' : 'text-gray-400 hover:bg-white hover:bg-opacity-25',
                                                                        'flex-shrink-0 relative cursor-pointer inline-flex items-center justify-center h-12 w-12 rounded-md'
                                                                    )}
                                                                >
                                                                    <span className="sr-only">{child.narrowTitle}</span>
                                                                    {
                                                                        child.newIcon &&
                                                                        <child.newIcon className="h-6 w-6" aria-hidden="true" />
                                                                    }
                                                                </div>
                                                                <div
                                                                    style={{ left: "80px", top: "5px", zIndex: 999999 }} className={(child.hover ? "opacity-100" : "opacity-0") + " absolute h-10 whitespace-no-wrap rounded-md flex justify-center items-center bg-purple-900 text-white"}>
                                                                    <div className="px-4 whitespace-no-wrap">
                                                                        {child.narrowTitle}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            </nav>
                        </div>
                    </div>
                }

                {/*DESKTOP MENU LARGE*/}
                {
                    window.location.href.indexOf("v2/orders/new") == -1 &&
                    <div className="md:w-2/12 lg:w-2/12  hidden md:flex md:flex-col md:fixed md:inset-y-0">
                        <div className="flex-1 flex flex-col min-h-0 bg-purple-900">
                            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-purple-900 flex-row">
                                {
                                    false &&
                                    userRegister.get().logo &&
                                    <img
                                        className="h-12 w-auto"
                                        src={userRegister.get().logo}
                                        alt="Workflow"
                                    />
                                }
                                <div className="w-16 h-16 rounded-full overflow-hidden flex justify-center items-center">
                                    <img
                                        className="object-cover"
                                        src={userRegister.get().logo}
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3 font-semibold text-lg text-white">
                                    Adcredo
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col overflow-y-auto">

                                <nav className="flex-1 px-2 py-4 space-y-1">
                                    {this.state.menu.map((item, i) => (
                                        <div key={i}>
                                            {
                                                item.headline &&
                                                <div className="mb-2 text-white mt-4 px-2 text-xs uppercase font-semibold">
                                                    {item.headline}
                                                </div>
                                            }
                                            {
                                                Array.isArray(item.submenu) &&
                                                item.submenu.map((item) => {
                                                    return (
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.history.push(item.link)
                                                            }}
                                                            key={item.title}
                                                            href={"#"}
                                                            className={classNames(
                                                                window.location.href.indexOf(item.active) !== -1 ? ' text-white text-opacity-100' : 'text-white text-opacity-50',
                                                                //(item.type && item.type == "btn") ? ' btn btn-primary font-semibold block mt-2 mb-6 w-full justify-center' : '',
                                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white text-opacity-50 hover:text-opacity-100 transition duration-200'
                                                            )}
                                                        >
                                                            {
                                                                item.newIcon &&
                                                                <item.newIcon
                                                                    className={classNames(
                                                                        window.location.href.indexOf(item.active) !== -1 ? ' text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            }
                                                            {item.title}
                                                            {item.type !== "btn" && this.renders.campaigns(item.title) > 0 && (['all', 'active', 'paused', 'upcoming', 'error'].indexOf(item.title.toLowerCase()) >= 0) &&
                                                                <div className="flex justify-end flex-1">
                                                                    <div style={{ minWidth: '35px' }} className={
                                                                        cx("flex rounded-md py-1 justify-center items-center text-xxs font-small text-white bg-white bg-opacity-25 ml-1 text-center", {
                                                                            ["bg-red-500 bg-opacity-75"]: item.title.toLowerCase() == 'error'
                                                                        })}>
                                                                        {this.renders.campaigns(item.title)}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </a>
                                                    )
                                                })
                                            }
                                            {
                                                item.link &&
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(item.link)
                                                    }}
                                                    key={item.title}
                                                    href={"#"}
                                                    className={classNames(
                                                        window.location.href.indexOf(item.active) !== -1 ? ' text-white text-opacity-100' : 'text-white text-opacity-50 hover:text-opacity-100 ',
                                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                                    )}
                                                >
                                                    {
                                                        item.newIcon &&
                                                        <item.newIcon
                                                            className={classNames(
                                                                window.location.href.indexOf(item.active) !== -1 ? ' text-purple-400 text-opacity-100' : 'text-white text-opacity-50',
                                                                'mr-3 flex-shrink-0 h-6 w-6'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                    {item.title}
                                                </a>
                                            }
                                        </div>
                                    ))}
                                </nav>

                                <Menu as="div" className="px-3 py-4 relative inline-block text-left border-t">
                                    <div>
                                        <Menu.Button className="group w-full rounded-md text-sm text-left font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                                            <span className="flex w-full justify-between items-center">
                                                <span className="flex min-w-0 items-center justify-between space-x-3">
                                                    {
                                                        this.state.user.picture &&
                                                        <img
                                                            className="w-10 h-10 rounded-full"
                                                            src={this.state.user.picture}
                                                            alt=""
                                                        />
                                                    }
                                                    {
                                                        !this.state.user.picture &&
                                                        <div className="w-10 h-10 rounded-full flex justify-center items-center border-1.5 bg-purple-100 text-sm uppercase font-semibold text-purple-500">
                                                            {this.renders.firstLetter()}
                                                        </div>
                                                    }
                                                    <span className="flex-1 flex flex-col min-w-0">
                                                        <span className="text-white text-sm font-medium truncate">{this.state.user.name}</span>
                                                        <span className="text-gray-500 text-sm truncate">{this.state.user.email}</span>
                                                    </span>
                                                </span>
                                                <SelectorIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.history.push('/user/profile/account');
                                                            }}
                                                            href="#"
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            My profile
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                {
                                                    this.state.user.type === 'agent' &&
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.history.push('/user/users/all');
                                                                }}
                                                                href="#"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Settings
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                }
                                            </div>
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() => {
                                                                this.functions.logout();
                                                            }}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block w-full text-left px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Sign out
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>


                            </div>
                        </div>
                    </div>
                }

                {/*MOBILE NAVBAR*/}
                <div className="top-0 z-10 w-full flex-shrink-0 h-16 bg-white shadow fixed flex md:hidden">
                    <div className="flex-1 px-4 justify-between flex">
                        <div className="flex-1 flex">
                            <div className="w-full flex md:ml-0 items-center">
                                <img
                                    className="h-12 w-auto"
                                    src={userRegister.get().logo}
                                    alt="Workflow"
                                />
                            </div>
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">

                        </div>
                    </div>
                    <div className="p-2 items-center flex">
                        <button
                            type="button"
                            className="p-2   text-gray-500 rounded-md focus:outline-none hover:text-gray-500 hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            //className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                            onClick={() => {
                                this.setState({
                                    mobile_menu: true
                                })
                            }}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                {/*MOBILE MENU*/}
                <Transition.Root show={this.state.mobile_menu} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 z-40 md:hidden" onClose={() => {

                    }}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                            enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
                            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                            leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
                        >
                            <nav
                                className="fixed z-40 overflow-y-scroll inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
                                aria-label="Global"
                            >
                                <div className="h-16 flex items-center justify-between px-4 sm:px-6">
                                    <a href="#">
                                        <img
                                            className="h-12 w-auto"
                                            src={userRegister.get().logo}
                                            alt="Workflow"
                                        />
                                    </a>
                                    <button
                                        type="button"
                                        className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        onClick={() => {
                                            this.setState({
                                                mobile_menu: false
                                            })
                                        }}
                                    >
                                        <span className="sr-only">Close main menu</span>
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                                    {this.state.menu.map((item) => (
                                        <Fragment key={item.name}>
                                            {
                                                !item.headline &&
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.push(item.link)
                                                        this.setState({
                                                            mobile_menu: false
                                                        })
                                                    }}
                                                    key={item.title}
                                                    href={"#"}
                                                    className={classNames(
                                                        window.location.href.indexOf(item.active) !== -1 ? ' text-gray-900' : '',
                                                        'flex flex-row rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-100'
                                                    )}
                                                >
                                                    {
                                                        item.newIcon &&
                                                        <item.newIcon
                                                            className={classNames(
                                                                window.location.href.indexOf(item.active) !== -1 ? ' text-purple-400 text-opacity-100' : 'text-gray-900',
                                                                'mr-3 flex-shrink-0 h-6 w-6'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                    {item.title}
                                                </a>
                                            }
                                            {
                                                item.headline &&
                                                <div className="flex flex-row rounded-md py-2 px-3 text-sm font-semibold text-gray-900">
                                                    {item.title}
                                                </div>
                                            }
                                            {Array.isArray(item.submenu) &&
                                                item.submenu.map((child) => (
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push(child.link)
                                                            this.setState({
                                                                mobile_menu: false
                                                            })
                                                        }}
                                                        key={child.title}
                                                        href={"#"}
                                                        className={classNames(
                                                            window.location.href.indexOf(child.active) !== -1 ? ' text-purple-900 ' : '',
                                                            'flex flex-row rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-100'
                                                        )}
                                                    >
                                                        {
                                                            child.newIcon &&
                                                            <child.newIcon
                                                                className={classNames(
                                                                    window.location.href.indexOf(child.active) !== -1 ? ' text-purple-400 text-opacity-100' : 'text-gray-900',
                                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        }
                                                        {child.title}
                                                        {child.type !== "btn" && this.renders.campaigns(child.title) > 0 && (['all', 'active', 'paused', 'upcoming', 'error'].indexOf(child.title.toLowerCase()) >= 0) &&
                                                            <div className="flex justify-end flex-1">
                                                                <div style={{ minWidth: '35px' }} className={
                                                                    cx("flex rounded-md h-6 justify-center items-center text-xs font-medium text-gray-900 bg-gray-100 ml-1 text-center", {
                                                                        ["bg-red-100 text-red-500"]: child.title.toLowerCase() == 'error',
                                                                    })}>
                                                                    {this.renders.campaigns(child.title)}
                                                                </div>
                                                            </div>
                                                        }
                                                    </a>
                                                ))}
                                        </Fragment>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                                        <div className="flex-shrink-0">
                                            {
                                                this.state.user.picture &&
                                                <img className="h-10 w-10 rounded-full" src={this.renders.user().picture} alt="" />
                                            }
                                            {
                                                !this.state.user.picture &&
                                                <div className="h-10 w-10 rounded-full flex justify-center items-center border-1.5 bg-purple-100 text-sm uppercase font-semibold text-purple-500">
                                                    {this.renders.firstLetter()}
                                                </div>
                                            }
                                        </div>
                                        <div className="ml-3 min-w-0 flex-1">
                                            <div className="text-base font-medium text-gray-800 truncate">{this.state.user.name}</div>
                                            <div className="text-sm font-medium text-gray-500 truncate">{this.state.user.email}</div>
                                        </div>
                                        <a href="#" className="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </a>
                                    </div>
                                    <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push("/user/profile/account")
                                                this.setState({
                                                    mobile_menu: false
                                                })
                                            }}
                                            key={"profile"}
                                            href={"#"}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                                        >
                                            My profile
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push("/user/users/all")
                                                this.setState({
                                                    mobile_menu: false
                                                })
                                            }}
                                            key={"settings"}
                                            href={"#"}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                                        >
                                            Settings
                                        </a>
                                        <div
                                            onClick={(e) => {
                                                this.functions.logout();
                                                this.setState({
                                                    mobile_menu: false
                                                })
                                            }}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                                        >
                                            Sign out
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </Transition.Child>
                    </Dialog>
                </Transition.Root>

            </>
        )
    }

}

export default SideNavigation;