import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import {BeatLoader} from "react-spinners";

class Input extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: this.props.disabled,
            type: this.props.type,
            value: this.props.value,
            placeholder: this.props.placeholder,
            maxLength: this.props.maxLength,
            error: this.props.error,
            noTitle: this.props.noTitle,
            tight: this.props.tight,
            icons: this.props.icons,
            channels: this.props.channels,
            title: this.props.title,
            sign: this.props.sign,
            sign_title: this.props.signTitle,
            white: this.props.white,
            noBorderRight: this.props.noBorderRight,
            showSugestions: this.props.showSugestions,
            sugestions: [],
            highlight: this.props.highlight
        };
    };

    componentDidMount() {
        this.setState({
            disabled: this.props.disabled,
            type: this.props.type,
            value: this.props.value?this.props.value:"",
            placeholder: this.props.placeholder,
            maxLength: this.props.maxLength,
            error: this.props.error,
            noTitle: this.props.noTitle,
            tight: this.props.tight,
            icons: this.props.icons,
            channels: this.props.channels,
            title: this.props.title,
            sign: this.props.sign,
            sign_title: this.props.signTitle,
            white: this.props.white,
            showSugestions: this.props.showSugestions,
            sugestions: this.props.sugestions?this.props.sugestions:[],
            highlight: this.props.highlight,
            rightTitle: this.props.rightTitle,
            rightTitleText: this.props.rightTitleText
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disabled: nextProps.disabled,
            value: nextProps.value?nextProps.value:'',
            placeholder: nextProps.placeholder,
            error: nextProps.error,
            channels: nextProps.channels,
            title: nextProps.title,
            sign: nextProps.sign,
            icons: nextProps.icons,
            sign_title: nextProps.signTitle,
            noTitle: nextProps.noTitle,
            white: nextProps.white,
            rightTitle: nextProps.rightTitle,
            rightTitleText: nextProps.rightTitleText,
            showSugestions: nextProps.showSugestions,
            sugestions: nextProps.sugestions?nextProps.sugestions:[],
        });
    }

    init = {};

    renders = {
        sugestions: () => {
            try{
                let sugestions = JSON.parse(JSON.stringify(this.state.sugestions));
                if(sugestions.length > 0 && this.state.value){
                    return sugestions.filter((item) => {
                        return item.toLowerCase().indexOf(this.state.value.toLowerCase()) !== -1 && (item.toLowerCase() !== this.state.value.toLowerCase())
                    });
                }else{
                    return []
                }
            }catch (e) {
                return []
            }
        }
    };

    render(){
        return (
            <div className={
                cx("InputContainer",
                    {
                        ["InputContainerFull"]: this.props.size === "full",
                        ["InputContainerThird"]: this.props.size === "third",
                        ["InputContainerTight"]: this.props.tight,
                    })
            }>
                {
                    (!this.state.noTitle  || this.state.rightTitle) &&
                    <div className={cx("flex flex-row mb-1")}>
                        {
                            !this.state.noTitle &&
                            <div style={this.props.thinTitle?{fontWeight: 300, textTransform: "lowercase", paddingTop: "1px"}:{paddingTop: "1px"}} className={
                                cx("font-bold text-xs",
                                    {
                                        ["text-red-500"]: this.state.error
                                    })
                            }>
                                {this.state.title}
                            </div>
                        }
                        {
                            this.state.icons &&
                            <div className="flex flex-row ml-2">
                                {false &&
                                    this.state.channels.map((item) => {
                                        if(item === 'google'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconGoogle noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'facebook'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconFacebook noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'instagram'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconInstagram noMargin={true}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {
                                    this.state.channels.map((item) => {
                                        if(item === 'google'){
                                            return(
                                                <div className={'w-5 h-5 bg-google-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/google_icon.png')}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'facebook'){
                                            return(
                                                <div className="flex-row flex">
                                                    <div className={'w-5 h-5 bg-facebook-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img style={{width: "4px"}} src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                    <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img className="w-2" src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if(item === 'dont_show'){
                                            return(
                                                <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/instagram_icon.svg')}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'linkedin'){
                                            return(
                                                <div className={'w-5 h-5 bg-linkedin-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/linkedin_icon.svg')}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.rightTitle &&
                            <div className="font-bold text-xs flex flex-1 justify-end text-purple-500">
                                <div onClick={() => {
                                    try{
                                        this.props.onRightTitleClick();
                                    }catch (e) {}
                                }} className="cursor-pointer">
                                    {this.state.rightTitleText}
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="InputContainerInputContainer relative overflow-hidden">
                    <input
                        maxLength={this.state.maxLength}
                        disabled={this.state.disabled}
                        placeholder={this.state.placeholder}
                        type={this.state.type}
                        value={this.state.value}
                        readOnly={this.props.readOnly}
                        onChange={this.props.onChange}
                        name={this.props.name ? this.props.name : ''}
                        onFocus={() => {
                            this.setState({
                                focus: true
                            });
                            try{
                                this.props.onFocus();
                            }catch (e) {}
                        }}
                        onBlur={() => {
                            this.setState({
                                focus: false
                            });
                            try{
                                this.props.onBlur();
                            }catch (e) {}
                        }}
                        className={
                            cx("rounded w-full transition-all duration-200 py-4 px-6 text-purple-900",
                                {
                                    ["InputContainerInputError border"]: this.state.error,
                                    ["InputContainerInputWhite"]: this.state.white,
                                    ["InputContainerInputBorderRight"]: this.state.noBorderRight,
                                    ["InputContainerInputBorderHighlight"]: this.state.highlight,
                                    ["pl-12"]:this.props.icon,
                                    [this.props.bgcolor]:this.props.bgcolor && this.props.bgcolor.length > 0,
                                    ["bg-purple-100 bg-opacity-25"]: this.props.bgcolor,
                                    ["border-0"]: this.props.noBorder,
                                    ["border"]: !this.props.noBorder
                                })
                        }
                        onKeyDown={(event) => {
                            try{
                                if(this.props.showKeyDown){
                                    this.props.onKeyDown(event.keyCode);
                                }
                            }catch (e) {}
                        }}
                    />
                    {this.props.icon &&
                        <span className='absolute top-0 left-0 mt-5 ml-6 inline-flex items-center justify-center text-gray-500'>
                            {this.state.value
                                ? <span className="text-green-500"><FeatherIcon className="stroke-current mr-2" size={13} icon="check-circle"/></span>
                                : <FeatherIcon className="stroke-current mr-2" size={13} icon={this.props.icon} />
                            }
                        </span>
                    }
                    {
                        this.state.sign &&
                        <div className={
                            cx("InputContainerInputContainerSign",
                                {
                                    ["InputContainerInputContainerSignError"]: this.state.error,
                                })
                        }>
                            {this.state.sign_title}
                        </div>
                    }
                    {
                        this.renders.sugestions().length > 0 && this.state.focus &&
                        <div className="InputContainerInputContainer-Suggestions">
                            {
                                this.renders.sugestions().map((item) => {
                                    return(
                                        <div onMouseDown={() => this.props.onChange({target: {value: item}})} className="InputContainerInputContainer-Suggestions-item">
                                            {item}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.props.showCross &&
                        <div onClick={() => {
                            try{
                                this.props.onCrossClick();
                            }catch (e) {}
                        }} className="InputContainerInputContainerCross">
                            <FeatherIcon color={"#333333"} size={25} icon="x"/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Input;
