import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BeatLoader } from "react-spinners";

class AdvertisingCreativesInstagramImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: [],
            page: {},
            no_container: false,
            language: "",
            location: "",
            read_more: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            page: nextProps.page,
            post: nextProps.post,
            language: nextProps.language,
            no_container: nextProps.noContainer,
            location: nextProps.location,
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            page: this.props.page,
            post: this.props.post,
            language: this.props.language,
            no_container: this.props.noContainer,
            location: this.props.location,
        });
    }

    functions = {};

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.creatives[0].headline !== "" ? this.state.creatives[0].headline : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.creatives[0].body !== "" ? this.state.creatives[0].body : "Body ...";
                body = body.replace("{location}", this.state.location);
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        /*
        title: () => {
            try {
                return this.state.creatives[0].headline !== "" ? this.state.creatives[0].headline : "Headline ..."
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                return this.state.creatives[0].body !== "" ? this.state.creatives[0].body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        */
        image: () => {
            try {
                return this.state.creatives[0].creative.url
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        callToAction: () => {
            try {
                if (this.state.creatives[0].call_to_action.value === 'NO_BUTTON') {
                    return null
                } else {
                    return this.state.creatives[0].call_to_action.name;
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className={cx("InstagramImage-PreviewContainer")}>
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem",
                        {
                            ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                        })
                }>
                    <div className={cx("InstagramImage-PreviewContainerItemInner")}>
                        {/*<div className="InstagramImage-PreviewContainerItemInnerTop">*/}
                        {/*    <img className="InstagramImage-PreviewContainerItemInnerTopLogo" src={require('../../assets/images/insta_logo.svg')} />*/}
                        {/*</div>*/}
                        <div className="InstagramImage-PreviewBasicContainerItemInnerHeader">
                            <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderPage">
                                <div style={{ backgroundImage: 'url(' + this.state.page.image + ')' }} className="InstagramImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                            </div>
                            <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderText">
                                <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                    {this.state.page.name}
                                </div>
                                <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                    <div className="InstagramImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                        Sponsored
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={!this.state.post ? this.renders.image() : this.renders.media()} className="InstagramImage-PreviewContainerItemInnerImage" />
                        {
                            this.renders.callToAction() &&
                            <div className="InstagramImage-PreviewContainerItemInnerButton">
                                <div className="InstagramImage-PreviewContainerItemInnerButtonText">
                                    {
                                        this.renders.callToAction()
                                    }
                                </div>
                                <FeatherIcon color={"#3397f0"} size={20} icon="chevron-right" />
                            </div>
                        }
                        {
                            this.renders.callToAction() &&
                            <div className="InstagramImage-PreviewContainerItemInnerDivider">
                                <div className="InstagramImage-PreviewContainerItemInnerDividerLine" />
                            </div>
                        }
                        <div className="InstagramImage-PreviewContainerItemInnerIcons">
                            <div className="InstagramImage-PreviewContainerItemInnerIconsLeft">
                                <FeatherIcon color={"#575757"} size={20} icon="heart" />
                                <FeatherIcon color={"#575757"} size={20} icon="message-circle" />
                                <FeatherIcon color={"#575757"} size={20} icon="send" />
                            </div>
                            <div className="InstagramImage-PreviewContainerItemInnerIconsRight">
                                <FeatherIcon color={"#575757"} size={20} icon="bookmark" />
                            </div>
                        </div>
                        <div className="InstagramImage-PreviewContainerItemInnerCaption">
                            <span className="InstagramImage-PreviewContainerItemInnerCaptionAuthor">
                                {this.state.page.name}
                            </span>
                            <div className="p-3">
                                {
                                    !this.state.post &&
                                    <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-xs font-medium whitespace-pre-wrap">

                                    </span>
                                }

                                <div>
                                    {
                                        !this.state.read_more && this.renders.body().length > 125 &&
                                        <div onClick={() => {
                                            this.setState({
                                                read_more: true
                                            });
                                        }} className=" font-semibold text-sm text-center text-purple-500 ">
                                            Read more
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                this.state.post &&
                                this.state.creatives[0].instagram_post &&
                                <span className="InstagramImage-PreviewContainerItemInnerCaptionPrimaryText">
                                    {this.state.creatives[0].instagram_post.caption}
                                </span>
                            }
                            {
                                this.state.post &&
                                this.state.creatives[0].facebook_post &&
                                <span className="InstagramImage-PreviewContainerItemInnerCaptionPrimaryText">
                                    {this.state.creatives[0].facebook_post.message}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesInstagramImage;
