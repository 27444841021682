import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FeatherIcon from "feather-icons-react";

var moment = require('moment');

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp = Date.now() - (Date.now() % oneDay) + (new Date().getTimezoneOffset() * 1000 * 60);

export default class SingleDatepicker extends Component {

    state = {
        getMonthDetails: []
    };

    constructor() {
        super();
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        this.state = {
            year,
            month,
            selectedDay: todayTimestamp,
            monthDetails: this.getMonthDetails(year, month),
            minDateToday: false,
            minToday: false
        }
    }

    componentDidMount() {
        this.setState({
            singleDate: this.props.singleDate,
            selectedFromDate: this.props.from,
            selectedToDate: this.props.to,
            max_date: this.props.maxDate,
            minDateToday: this.props.minDateToday,
            minToday: this.props.minToday,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            singleDate: nextProps.singleDate,
            selectedFromDate: nextProps.from,
            selectedToDate: nextProps.to
        })
    }

    daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    getDayDetails = (args) => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        let full_date = "";
        if (month === -1 && args.month === 0) {
            full_date = full_date + (args.year - 1) + "-12";
        } else if (month === 1 && args.month === 11) {
            full_date = full_date + (args.year + 1) + "-01";
        } else {
            full_date = full_date + args.year + "-";
            if (month === -1) {
                full_date = full_date + (args.month < 10 ? "0" + (args.month) : args.month);
            } else if (month === 1) {
                full_date = full_date + (args.month + 2 < 10 ? "0" + (args.month + 2) : args.month + 2);
            } else {
                full_date = full_date + (args.month + 1 < 10 ? "0" + (args.month + 1) : args.month + 1);
            }
        }
        full_date = full_date + "-" + (_date < 10 ? "0" + _date : _date);
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: this.daysMap[day],
            full_date: full_date
        }
    };

    getNumberOfDays = (year, month) => {
        return 40 - new Date(year, month, 40).getDate();
    };

    getMonthDetails = (year, month) => {
        let firstDay = (new Date(year, month)).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }

        return monthArray;
    };

    isCurrentDay = (day) => {
        return day.timestamp === todayTimestamp;
    };

    isSelectedDay = (day) => {
        if (!this.state.singleDate) {
            let month = this.state.month + day.month;
            let date = day.date;
            month = month + 1;
            if (month < 10) {
                month = "0" + month;
            }
            if (date < 10) {
                date = "0" + date;
            }
            return ((this.state.year + "-" + (month) + "-" + date) === this.state.selectedFromDate) ||
                ((this.state.year + "-" + (month) + "-" + date) === this.state.selectedToDate)
        } else {
            return this.state.selectedDate && this.state.selectedDate.full_date == day.full_date
        }
    };

    getSelectedDay = (day) => {
        let month = this.state.month + day.month;
        let date = day.date;
        month = month + 1;
        if (month < 10) {
            month = "0" + month;
        }
        if (date < 10) {
            date = "0" + date;
        }
        return this.state.year + "-" + (month) + "-" + date;
    };

    isAfterMaxDate = (day) => {
        try {
            if (this.state.max_date) {
                let month = this.state.month + day.month;
                let date = day.date;
                month = month + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                if (date < 10) {
                    date = "0" + date;
                }
                let selected_date = this.state.year + "-" + (month) + "-" + date;
                return moment(selected_date).isAfter(this.state.max_date);
            } else {
                return false
            }
        } catch (e) {
            return false;
        }
    };

    isBetweenSelectedDates = (day) => {
        try {
            let month = this.state.month + day.month;
            let date = day.date;
            month = month + 1;
            if (month < 10) {
                month = "0" + month;
            }
            if (date < 10) {
                date = "0" + date;
            }
            let selected_date = this.state.year + "-" + (month) + "-" + date;
            return moment(selected_date).isBetween(this.state.selectedFromDate, this.state.selectedToDate);
        } catch (e) {
            return false;
        }
    };

    getDateFromDateString = dateValue => {
        let dateData = dateValue.split('-').map(d => parseInt(d, 10));
        if (dateData.length < 3)
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return { year, month, date };
    };

    getMonthStr = month => this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp = timestamp => {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();
        return dateObject.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
    };

    setDate = dateData => {
        let selectedDay = new Date(dateData.year, dateData.month - 1, dateData.date).getTime();
        this.setState({ selectedDay })
        if (this.props.onChange) {
            this.props.onChange(selectedDay);
        }
    };

    updateDateFromInput = () => {
        let dateValue = this.state.date;//inputRef.current.value;
        let dateData = this.getDateFromDateString(dateValue);
        if (dateData !== null) {
            this.setDate(dateData);
            this.setState({
                year: dateData.year,
                month: dateData.month - 1,
                monthDetails: this.getMonthDetails(dateData.year, dateData.month - 1)
            })
        }
    };

    setDateToInput = (timestamp) => {
        let dateString = this.getDateStringFromTimestamp(timestamp);
    };

    onDateClick = (day) => {
        let month = this.state.month + day.month;
        let date = day.date;
        month = month + 1;
        if (month < 10) {
            month = "0" + month;
        }
        if (date < 10) {
            date = "0" + date;
        }
        let selected_date = this.state.year + "-" + month + "-" + date;
        if (!this.state.singleDate) {
            if (!this.state.selectedFromDate) {
                this.state.selectedFromDate = selected_date;
            } else if (this.state.selectedFromDate && !this.state.selectedToDate) {
                if (moment(selected_date).isBefore(this.state.selectedFromDate)) {
                    this.state.selectedToDate = JSON.parse(JSON.stringify(this.state.selectedFromDate));
                    this.state.selectedFromDate = selected_date;
                } else {
                    this.state.selectedToDate = selected_date;
                }
            } else if (this.state.selectedFromDate && this.state.selectedToDate) {
                this.state.selectedFromDate = selected_date;
                this.state.selectedToDate = false;
            }
            this.setState({
                selectedFromDate: this.state.selectedFromDate,
                selectedToDate: this.state.selectedToDate
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange({ start_date: this.state.selectedFromDate, end_date: this.state.selectedToDate });
                }
            });
        } else {
            this.setState({
                selectedDate: day
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(day);
                }
            });
        }
    };

    setYear = (offset) => {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            year,
            monthDetails: this.getMonthDetails(year, month)
        })
    };

    setMonth = (offset) => {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month)
        })
    };

    renderCalendar() {
        let days = this.state.monthDetails.map((day, index) => {
            return (
                <div onClick={() => {
                    if (!this.isAfterMaxDate(day)) {
                        if (!this.state.minDateToday || (this.state.minDateToday && moment().add((this.state.minToday ? 0 : 1), "days").startOf("day").isBefore(moment(day.full_date).startOf("day").add(1, "hours")))) {
                            this.onDateClick(day);
                        }
                    }
                }} style={{ width: "14.285%", height: "16.6666%" }} className={'c-day-container flex justify-center items-center block float-left relative' + (day.month !== 0 ? ' disabled' : '') +

                    (this.isCurrentDay(day) ? ' highlight' : '')} key={index}>
                    <div style={(day.month != 0 || this.isAfterMaxDate(day) || (this.state.minDateToday && moment().add((this.state.minToday ? 0 : 1), "days").startOf("day").isAfter(moment(day.full_date).startOf("day").add(1, "hours")))) ? { opacity: "0.3" } : {}} className={(this.isAfterMaxDate(day) ? "cursor-not-allowed bg-opacity-25" : "") + " cdc-day w-full absolute h-full z-10 text-center block float-left text-sm cursor-pointer justify-center items-center hover:text-purple-500"}>
                        {day.date}
                    </div>
                    {
                        !this.state.singleDate &&
                        this.state.selectedFromDate && this.state.selectedToDate && this.isBetweenSelectedDates(day) &&
                        <div style={{ top: "-14%", right: "0%", left: "0%" }} className="absolute h-8 bg-purple-100" />
                    }
                    {
                        !this.state.singleDate &&
                        this.state.selectedToDate && this.state.selectedFromDate && this.isSelectedDay(day) && this.getSelectedDay(day) === this.state.selectedFromDate &&
                        <div style={{ top: "-14%", right: "0%", left: "0%" }} className="absolute h-8 rounded-l-full bg-purple-100" />
                    }
                    {
                        !this.state.singleDate &&
                        this.state.selectedFromDate && this.state.selectedToDate && this.isSelectedDay(day) && this.getSelectedDay(day) === this.state.selectedToDate &&
                        <div style={{ top: "-14%", left: "0%", right: "0%" }} className="absolute h-8 rounded-r-full bg-purple-100" />
                    }
                    {
                        !this.state.singleDate &&
                        (!this.state.selectedFromDate || !this.state.selectedToDate) && this.isSelectedDay(day) &&
                        <div style={{ top: "-13%", right: "23%" }} className="absolute w-8 h-8 rounded-full bg-purple-100" />
                    }
                    {
                        this.state.singleDate &&
                        this.state.selectedDate && this.isSelectedDay(day) &&
                        <div style={{ top: "-13%", right: "32%" }} className="absolute w-8 h-8 rounded-full bg-purple-100" />
                    }
                </div>
            )
        });
        return (
            <div className='c-container block relative box-border w-full h-full float-left'>
                <div style={{ height: "30px" }} className='cc-head w-full mt-2 mb-3 block float-left font-bold text-sm'>
                    {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d, i) => <div style={{ width: "14.285%", height: "30px", lineHeight: "30px" }} key={i} className='cch-name text-center block float-left'>{d}</div>)}
                </div>
                <div style={{ height: "270px" }} className='cc-body w-full block float-left'>
                    {days}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='MyDatePicker relative'>
                <div style={{ minHeight: "350px" }} className='mdp-container w-full overflow-hidden'>
                    <div style={{ height: "53px" }} className='w-full flex flex-row justify-center items-center'>
                        <div onClick={() => this.setYear(-1)} className={(this.props.large ? "h-10 w-10" : "h-8 w-8") + ' text-purple-500 bg-purple-100 cursor-pointer flex rounded font-bold justify-center align-middle items-center'}>
                            <FeatherIcon className={'stroke-current'} size={this.props.large ? 16 : 14} icon={"chevrons-left"} />
                        </div>
                        <div onClick={() => this.setMonth(-1)} className={(this.props.large ? "h-10 w-10" : "h-8 w-8") + ' text-purple-500 bg-purple-100 cursor-pointer flex rounded font-bold justify-center align-middle items-center ml-2'}>
                            <FeatherIcon className={'stroke-current'} size={this.props.large ? 16 : 14} icon={"chevron-left"} />
                        </div>
                        <div className='flex flex-row flex-1 justify-center items-center pt-1'>
                            <div className='font-bold text-sm'>{this.state.year}</div>
                            <div className='font-bold text-sm ml-1'>{this.getMonthStr(this.state.month)}</div>
                        </div>
                        <div onClick={() => this.setMonth(1)} className={(this.props.large ? "h-10 w-10" : "h-8 w-8") + ' text-purple-500 bg-purple-100 cursor-pointer flex mr-2 rounded font-bold justify-center align-middle items-center'}>
                            <FeatherIcon className={'stroke-current'} size={this.props.large ? 16 : 14} icon={"chevron-right"} />
                        </div>
                        <div onClick={() => this.setYear(1)} className={(this.props.large ? "h-10 w-10" : "h-8 w-8") + ' text-purple-500 bg-purple-100 cursor-pointer flex rounded font-bold justify-center align-middle items-center'}>
                            <FeatherIcon className={'stroke-current'} size={this.props.large ? 16 : 14} icon={"chevrons-right"} />
                        </div>
                    </div>
                    <div className='w-full'>
                        {this.renderCalendar()}
                    </div>
                </div>
            </div>
        )
    }

}