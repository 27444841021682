import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCampaigns(type) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?type=" + type;
        return apiRegister.call(options, url);
    }

    static getLogs() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/logs";
        return apiRegister.call(options, url);
    }

    static getCampaignsStats() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal/stats";
        return apiRegister.call(options, url);
    }

}

export {calls}