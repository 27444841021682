import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getCatalogs(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/client/product/catalogs/' + data.channels + '/' + data.client;
        return apiRegister.call(options, url);
    }

    static getProductSets(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/client/product/productsets/' + data.channels + '/' + data.client + '/' + data.catalog;
        return apiRegister.call(options, url);
    }

    static getProducts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/client/product/products/' + data.channels + '/' + data.client + '/' + data.productset;
        return apiRegister.call(options, url);
    }

    static getPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listPages?client=" + data.client;
        return apiRegister.call(options, url);
    }

    static getEvents(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listEvents?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static getBusinessInstagramAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + data.client;
        return apiRegister.call(options, url);
    }

    static getInstagramMedia(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listInstagramMedia?client=" + data.client + "&instagram_account=" + data.instagram_account;
        return apiRegister.call(options, url);
    }

    static getFacebookMedia(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listFacebookMedia?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static createPageToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', null);
        let url = calls.url.api + "/v3/facebook/createPageToken?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static getEvents(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listEvents?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static getInstagramAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listInstagramAccounts?client=" + data.client + (data.page ? ("&page=" + data.page) : "") + (data.page_token ? ("&pagetoken=" + encodeURIComponent(data.page_token)) : "");
        return apiRegister.call(options, url);
    }

}

export { calls }