import React, { Component } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { AnnotationIcon, HeartIcon, PaperAirplaneIcon, BookmarkIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


class PreviewInstagramCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            slides: [],
            body: "",
            headline: "",
            index: 0,
            cta: {},
            noCta: false,
            read_more: false,
            adData: {},
            is_post: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            slides: this.props.slides,
            body: this.props.body,
            headline: this.props.headline,
            cta: this.props.cta,
            noCta: this.props.noCta,
            adData: this.props.adData,
            is_post: this.props.is_post
        })

        await this.promisedSetState({
            slides: this.state.slides.map((slide, index) => {
                if (index !== this.state.index) {
                    slide.selected = false
                }
                return slide
            })
        })

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200)

        this.refs.carousel.moveTo(0);

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            slides: nextProps.slides,
            body: nextProps.body,
            headline: nextProps.headline,
            cta: nextProps.cta,
            noCta: nextProps.noCta,
            adData: nextProps.adData,
            is_post: nextProps.is_post
        })
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.state.cta.value === 'NO_BUTTON') {
                    return "No Button"
                } else {
                    return this.state.cta.name;
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div className='flex flex-col justify-center'>
                <div className='w-80 pt-2 pb-12 rounded border shadow-xl bg-white'>
                    <div className='mx-3 flex justify-between items-center pb-2'>
                        <div className='flex items-center'>
                            <div className='mr-1'>
                                {
                                    this.state.page.image &&
                                    <img src={this.state.page.image} alt={this.state.page.name ? this.state.page.name : "Alt text"} className='w-10 h-10 border rounded-full' />
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='border w-10 h-10 rounded-full'></div>
                                }
                            </div>
                            <div className='flex flex-col text-xs font-semibold'>
                                {this.state.page.name ? this.state.page.name : "Page name"} <span className='font-normal text-xxs text-gray-700'>Sponsored</span>
                            </div>
                        </div>
                        <div><DotsHorizontalIcon className='w-5' /></div>
                    </div>
                    <div className='relative flex flex-col items-center justify-center select-none'>
                        <div className='absolute flex w-full justify-between items-center'>
                            <ChevronLeftIcon className='h-8 cursor-pointer text-white' />
                            <ChevronRightIcon className='h-8 cursor-pointer text-white' />
                        </div>
                        <Carousel
                            ref={"carousel"}
                            showThumbs={false}
                            showStatus={false}
                            swipeable={true}
                            showArrows={true}
                            showIndicators={true}
                            swipeScrollTolerance={1}
                            infiniteLoop={true}
                            autoPlay={false}
                            onChange={async (index, item) => {
                                await this.promisedSetState({
                                    index: index,
                                    slides: this.state.slides.map((slide, inner_index) => {
                                        if (inner_index != index) {
                                            slide.selected = false
                                        } else {
                                            slide.selected = true
                                        }
                                        return slide
                                    })
                                })
                            }}
                        >
                            {
                                Array.isArray(this.state.slides) &&
                                this.state.slides.map((slide, index) => {
                                    return (
                                        <div key={index}>
                                            {slide.type === "image" &&
                                                <img src={slide.picture} alt={slide.name ? slide.name : "Alt text"}
                                                    className="w-full overflow-hidden"
                                                    style={!this.state.adData.files ? { height: 318, objectFit: "contain" } : (this.state.adData.files && ((this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16") || (this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio !== "1:1"))) ?
                                                        { height: 318, objectFit: `${this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16" ? "cover" : "contain"}` }
                                                        : { minHeight: "100%" }}
                                                />
                                            }
                                            {slide.type === "video" &&
                                                <video src={slide.url ? slide.url : slide.video} autoplay={false} controls={true} loop muted ref="video"
                                                    className="h-full w-full overflow-hidden"
                                                    style={!this.state.adData.files ? { height: 318, objectFit: "contain" } : (this.state.adData.files && ((this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16") || (this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio !== "1:1"))) ?
                                                        { height: 318, objectFit: "cover" } : { minHeight: "100%" }}
                                                />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    {
                        !this.state.noCta &&
                        <div className='flex justify-between items-center mx-3  text-sm font-semibold pb-3 pt-6 border-b border-gray-200'>
                            {/* {this.state.cta && this.state.cta.name ? this.state.cta.name : this.renders.callToAction() ? this.renders.callToAction() :''} */}
                            {this.renders.callToAction()}
                            {
                                this.state.cta &&
                                <span><ChevronRightIcon className='w-5' /></span>
                            }
                        </div>
                    }
                    <div className='flex justify-between mx-3  pt-1 pb-2 items-center'>
                        <div className='flex space-x-4 items-center'>
                            <div><HeartIcon className='w-6' /></div>
                            <div><AnnotationIcon className='w-6' /></div>
                            <div><PaperAirplaneIcon style={{ transform: "rotate(60deg)" }} className='w-6 pb-3' /></div>
                        </div>
                        <div><BookmarkIcon className='w-6' /></div>
                    </div>
                    <div className='flex flex-col text-xs mx-3'>
                        <div className='flex'>
                            <span className='font-semibold mr-1'>{this.state.page.name ? this.state.page.name : "Page name"}</span>
                            {Array.isArray(this.state.slides) && this.state.slides.map((slide, index) => {
                                if (slide.selected) {
                                    return (
                                        // <span key={index} className={`mr-1 ${this.state.adData && this.state.adData.adData && this.state.adData.adData.ad_type && (this.state.adData.adData.ad_type.value === "posts" || this.state.adData.adData.ad_type.value === "carousel_posts") && "hidden"} `}>{slide.headline ? this.functions.truncText(slide.headline, 30) : "Headline..."}</span>
                                        <span key={index} className={`mr-1 ${this.state.is_post && "hidden"} `}>{slide.headline ? this.functions.truncText(slide.headline, 30) : "Headline..."}</span>
                                    )
                                }
                            }
                            )}
                        </div>
                        <span>
                            {
                                this.state.body !== "" ?
                                    <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                    </span>
                                    :
                                    "Body..."
                            }
                            {
                                !this.state.read_more &&
                                this.renders.body().length > 125 &&
                                <span
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            read_more: true
                                        });
                                    }} className="cursor-pointer font-semibold text-sm text-center pb-1">
                                    {" "}Read more
                                </span>
                            }
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewInstagramCarousel



