import React, { Component } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/solid'

import { AnnotationIcon, HeartIcon, PaperAirplaneIcon, BookmarkIcon, ChevronRightIcon } from '@heroicons/react/outline';

class PreviewInstagramCollectionAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            picture: null,
            body: "",
            title: "",
            slides: {},
            video: null,
            adData: {},
            for_report: false
        }
    };


    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            picture: this.props.picture,
            body: this.props.body,
            title: this.props.title,
            slides: this.props.slides,
            video: this.props.video,
            adData: this.props.adData,
            for_report: this.props.for_report
        })
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            picture: nextProps.picture,
            body: nextProps.body,
            title: nextProps.title,
            slides: nextProps.slides,
            video: nextProps.video,
            adData: nextProps.adData,
            for_report: nextProps.for_report
        })
        setTimeout(() => {
            try {
                if (this.refs.videoOne) {
                    this.refs.videoOne.setAttribute("muted", true);
                }
                if (this.refs.videoTwo) {
                    this.refs.videoTwo.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error);
            }
        }, 200);
    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    render() {
        return (
            <div className='flex flex-col justify-center'>
                <div className='w-80 pt-2 pb-4 rounded border shadow-xl bg-white'>
                    <div className='flex justify-between mx-3 items-center pb-2'>
                        <div className='flex items-center'>
                            <div className='mr-1'>
                                {
                                    this.state.page.image &&
                                    <img src={this.state.page.image} alt={this.state.page.name ? this.state.page.name : "Alt text"} className='border h-10 w-10 rounded-full' />
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='border rounded-full h-10 w-10'></div>
                                }
                            </div>
                            <div className='flex flex-col text-xs font-semibold'>
                                {this.state.page.name ? this.state.page.name : "Page name"} <span className='font-normal text-xxs text-gray-700'>Sponsored</span>
                            </div>
                        </div>
                        <div><DotsHorizontalIcon className='w-5' /></div>
                    </div>
                    <div>
                        {
                            !this.state.for_report &&
                            <div className='h-full w-full'>
                                <div
                                    style={this.state.adData && this.state.adData.file && this.state.adData.file.ratio === "9:16" ? { height: 318 } : { height: "100%" }}
                                >
                                    {this.state.picture && this.state.picture !== "" &&
                                        <img src={this.state.picture} alt={this.state.name} className="w-full h-full object-cover" />
                                    }
                                    {this.state.video && this.state.video !== "" &&
                                        <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoOne" />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.for_report &&
                            <div
                                className='h-full w-full'
                            >
                                <div
                                // style={this.state.adData && this.state.adData.file && this.state.adData.file.ratio === "9:16" ? { height: 318 } : { height: "100%" }}
                                >
                                    {this.state.picture && this.state.picture !== "" &&
                                        <img src={this.state.picture} alt={this.state.name} className="w-full h-full object-cover" />
                                    }
                                    {this.state.video && this.state.video !== "" &&
                                        <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoTwo" />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='w-full py-2 justify-between items-center grid grid-cols-9 gap-1'>
                        <div className='col-span-3 h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[0] &&
                                <img src={this.state.slides[0].picture} alt={this.state.slides[0].name} className="w-full h-full object-cover" />
                            }
                        </div>
                        <div className='col-span-3 h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[1] &&
                                <img src={this.state.slides[1].picture} alt={this.state.slides[1].name} className="w-full h-full object-cover" />
                            }
                        </div>
                        <div className='col-span-3 h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[2] &&
                                <img src={this.state.slides[2].picture} alt={this.state.slides[2].name} className="w-full h-full object-cover" />
                            }
                        </div>

                    </div>
                    {/* <div className='flex justify-between items-center text-sm font-semibold mx-3 py-3 border-b border-gray-200'>
                        Learn more
                        <span><ChevronRightIcon className='w-5' /></span>
                    </div> */}
                    <div className='flex justify-between mx-3 pt-1 pb-2 items-center'>
                        <div className='flex space-x-4 items-center'>
                            <div><HeartIcon className='w-6' /></div>
                            <div><AnnotationIcon className='w-6' /></div>
                            <div><PaperAirplaneIcon style={{ transform: "rotate(60deg)" }} className='w-6 pb-3' /></div>
                        </div>
                        <div><BookmarkIcon className='w-6' /></div>
                    </div>
                    <div className='text-xs mx-3'>
                        <span className='font-semibold mr-1'>{this.state.page.name ? this.state.page.name : "Page name"}</span>
                        <span>{this.state.body ? this.state.body : "Body text here..."}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewInstagramCollectionAd;