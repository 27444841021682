import React, { Component, Fragment } from 'react';
import { BeatLoader } from "react-spinners";
import ClientOverview from "../components/client-overview";
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { Menu, Transition } from '@headlessui/react';
import { BadgeCheckIcon, CalendarIcon, ChevronDownIcon } from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class AdminOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            year: { name: '2024', value: '2024' },
            years: [
                { name: '2020', value: '2020' },
                { name: '2021', value: '2021' },
                { name: '2022', value: '2022' },
                { name: '2023', value: '2023' },
                { name: '2024', value: '2024' },
                { name: 'Total', value: 'total' }
            ],
            sektion: { name: 'Clients', value: 'clients' },
            sektions: [
                { name: 'Agencies', value: 'agencies' },
                { name: 'Users', value: 'users' },
                { name: 'Clients', value: 'clients' },
                { name: 'Orders', value: 'orders' },
                { name: 'Drafts', value: 'drafts' },
                { name: 'Campaigns', value: 'campaigns' },
                { name: 'Imported', value: 'imported' }
            ],
            chart_options: {
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                },
                legend: {
                    display: false
                },
                tooltips: {
                    // Overrides the global setting
                    mode: 'label'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontSize: 10,
                            suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 10,
                        }
                    }],
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    }
                },
            }
        };
    };

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.getOverview();
    }

    functions = {
        getOverview: async () => {
            try {
                let response = await this.calls.adminOverview();
                this.setState({
                    data: response.data
                });
            } catch (error) { }
            this.setState({
                loading: false
            });
        },
        barChart: () => {
            let graph_data = {
                labels: [],
                datasets: []
            };
            if (this.state.year.value === "total") {
                let dataset_month = {
                    label: 'This month',
                    data: [],
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.4)'
                };
                let dataset_total = {
                    label: 'Total',
                    data: [],
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.7)'
                };
                let total = 0;
                for (let year in this.state.data) {
                    this.state.data[year].monthly_breakdown[this.state.sektion.value].sort((a, b) => a.value - b.value);
                    this.state.data[year].monthly_breakdown[this.state.sektion.value].map((item, index) => {
                        graph_data.labels.push(year + " - " + item.name);
                        dataset_month.data.push(item.total);
                        total += +item.total;
                        dataset_total.data.push(total);
                    });
                }
                graph_data.datasets.push(dataset_month);
                graph_data.datasets.push(dataset_total);
            } else {
                this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value].sort((a, b) => a.value - b.value);
                graph_data.labels = this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value].map((item) => {
                    return item.name + " - " + this.state.year.value;
                });

                if (["agencies", "clients"].includes(this.state.sektion.value)) {
                    let dataset_month = {
                        label: 'Disabled',
                        data: this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value + "_disabled"].map((item) => {
                            return item.total;
                        }),
                        borderColor: 'rgb(255, 77, 84)',
                        backgroundColor: 'rgba(255, 77, 84, 0.8)'
                    };
                    graph_data.datasets.push(dataset_month);
                }

                let dataset_month = {
                    label: 'New',
                    data: this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value].map((item) => {
                        return item.total;
                    }),
                    borderColor: 'rgb(26, 213, 189)',
                    backgroundColor: 'rgba(26, 213, 189, 0.8)'
                };
                graph_data.datasets.push(dataset_month);

                let dataset_total = {
                    label: 'Active',
                    data: this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value].map((item, index) => {
                        let total = 0;
                        let created = 0;
                        let disabled = 0;
                        this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value].map((inner_item, inner_index) => {
                            if (inner_index <= index) {
                                created += +inner_item.total;
                            }
                        });
                        if (this.state.sektion.value === "agencies") {
                            this.state.data[this.state.year.value].monthly_breakdown[this.state.sektion.value + "_disabled"].map((inner_item, inner_index) => {
                                if (inner_index <= index) {
                                    disabled += +inner_item.total;
                                }
                            });
                        }
                        total = created - disabled;
                        return total;
                    }),
                    borderColor: 'rgb(69, 63, 241)',
                    backgroundColor: 'rgba(69, 63, 241, 0.9)'
                };
                graph_data.datasets.push(dataset_total);
            }
            console.log(graph_data);
            return graph_data;
        }
    };

    renders = {
        sektionOptionTotal: (sektion, disabled) => {
            let total = 0;
            try {
                this.state.data[this.state.year.value].monthly_breakdown[disabled ? sektion.value + "_disabled" : sektion.value].map((item, index) => {
                    total += item.total;
                });
            } catch (error) { }
            return total;
        },
        sektionsTotal: (sektion, disabled) => {
            let total = 0;
            try {
                Object.keys(this.state.data).map((year) => {
                    this.state.data[year].monthly_breakdown[disabled ? sektion.value + "_disabled" : sektion.value].map((item, index) => {
                        total += item.total;
                    });
                });
            } catch (error) { }
            return total;
        }
    };

    calls = {
        adminOverview: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/adminOverview";
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={async (type) => {
                            await this.promisedSetState({
                                year: type
                            });
                        }}
                        hideUserDropdown={true}
                        buttons={[
                            { text: "Total", icon: this.state.year.value === 'total' ? BadgeCheckIcon : null, value: { name: 'total', value: 'total' }, loading: false, show: !this.state.loading },
                            { text: "2024", icon: this.state.year.value === '2024' ? BadgeCheckIcon : null, value: { name: '2024', value: '2024' }, loading: false, show: !this.state.loading },
                            { text: "2023", icon: this.state.year.value === '2023' ? BadgeCheckIcon : null, value: { name: '2023', value: '2023' }, loading: false, show: !this.state.loading },
                            { text: "2022", icon: this.state.year.value === '2022' ? BadgeCheckIcon : null, value: { name: '2022', value: '2022' }, loading: false, show: !this.state.loading },
                            { text: "2021", icon: this.state.year.value === '2021' ? BadgeCheckIcon : null, value: { name: '2021', value: '2021' }, loading: false, show: !this.state.loading },
                            { text: "2020", icon: this.state.year.value === '2020' ? BadgeCheckIcon : null, value: { name: '2020', value: '2020' }, loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*SECTIONS*/}
                {
                    !this.state.loading &&
                    <div className="grid grid-cols-7 gap-4 p-4 pb-0">
                        {
                            this.state.sektions.map((sektion) => {
                                return (
                                    <div onClick={async () => {
                                        await this.promisedSetState({
                                            sektion: sektion
                                        });
                                    }} className={(sektion.value === this.state.sektion.value ? "border-purple-500" : "border-purple-500 border-opacity-0") + " sm:col-span-1 col-span-6 rounded-lg cursor-pointer overflow-hidden bg-white shadow border-2 "}>
                                        <div className="flex p-4 px-6 border-b border-gray-100">
                                            <div className="font-medium text-sm">
                                                {sektion.name}
                                            </div>
                                        </div>
                                        {
                                            this.state.year.value !== "total" &&
                                            <div className="p-6 py-6 flex flex-col">
                                                <div className="flex flex-row">
                                                    <dd className="text-3xl font-bold tracking-tight text-green-500">{this.renders.sektionOptionTotal(sektion)}</dd>
                                                    <div className="flex flex-1"></div>
                                                    {
                                                        ["agencies", "clients"].includes(sektion.value) && 
                                                        <dd className="text-3xl font-bold tracking-tight text-red-500">-{this.renders.sektionOptionTotal(sektion, true)}</dd>
                                                    }
                                                </div>
                                                {
                                                    false &&
                                                    sektion.value === "agencies" &&
                                                    <dd className="text-3xl font-bold tracking-tight text-purple-500">{this.renders.sektionOptionTotal(sektion) - this.renders.sektionOptionTotal(sektion, true)}</dd>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.year.value === "total" &&
                                            <div className="p-6 py-6 flex flex-col">
                                                <div className="flex flex-row">
                                                    <dd className="text-3xl font-bold tracking-tight text-green-500">{this.renders.sektionsTotal(sektion)}</dd>
                                                    <div className="flex flex-1"></div>
                                                    {
                                                        sektion.value === "agencies" &&
                                                        <dd className="text-3xl font-bold tracking-tight text-red-500">-{this.renders.sektionsTotal(sektion, true)}</dd>
                                                    }
                                                </div>
                                                {
                                                    false &&
                                                    sektion.value === "agencies" &&
                                                    <dd className="text-3xl font-bold tracking-tight text-purple-500">Active {this.renders.sektionsTotal(sektion) - this.renders.sektionsTotal(sektion, true)}</dd>
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="col-span-12 row-gap-4 grid grid-cols-12 mt-4 p-4 pt-0">
                        <div className="bg-white shadow overflow-hidden rounded-lg col-span-12">
                            <div className="flex p-4 px-6 border-b border-gray-100">
                                <div className="text-sm font-medium flex-1 flex">
                                    Monthly breakdown on {this.state.sektion.name}
                                </div>
                                {
                                    false &&
                                    <Menu as="div" className="relative inline-block text-left mr-2">
                                        <div>
                                            <Menu.Button className={"cursor-pointer capitalize inline-flex relative justify-center items-center w-full rounded-md px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                {this.state.sektion.value}
                                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="border absolute right-0 capitalize z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {
                                                        this.state.sektions.map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={async () => {
                                                                                await this.promisedSetState({
                                                                                    sektion: item
                                                                                });
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer capitalize',
                                                                                this.state.sektion.value == item.value ? "text-purple-500" : ""
                                                                            )}
                                                                        >
                                                                            {item.value}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                }
                            </div>
                            <div className="h-120 pt-4 pb-4 px-2">
                                <Bar
                                    options={this.state.chart_options}
                                    data={this.functions.barChart()}
                                />
                            </div>
                        </div>
                    </div>
                }


            </div>
        )
    }
}

export default AdminOverview;
