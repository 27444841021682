import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import UploadImage from "../../components/image-upload";
import AgencyUsers from "../../components/agency-users";
import {calls} from "./calls";

import SlideOver from "../slideover";
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";

class ClientTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            open: false,
            loading: false,
            warning_modal: false
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        handleChildClick: (e) => {
            this.setState({open: !this.state.open})
        }
    };

    renders = {};

    render() {
        return (
            <tr className="">
                <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex items-center">
                        <div className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                            {this.state.item.logo &&
                            <img className="h-10 w-10 rounded-full object-contain" src={this.state.item.logo}
                                 alt={this.state.item.company}/>
                            }
                        </div>
                        <div className="text-sm leading-5 font-bold text-gray-900 ml-4">
                            {this.state.item.name}
                        </div>
                    </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm">
                    <div className="text-sm leading-5 text-gray-900 capitalize">
                        {this.state.item.company}
                    </div>
                    <div className="text-xs leading-5 text-gray-500">
                        {this.state.item.email}
                    </div>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex flex-row">
                        {this.state.item.facebook &&
                        <div
                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/facebook_icon.svg') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.instagram &&
                        <div
                            className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white ' + (this.state.item.facebook ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/instagram_icon.svg') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.google &&
                        <div
                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + (this.state.item.facebook || this.state.item.instagram ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.linkedin &&
                        <div
                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white ' + (this.state.item.google || this.state.item.facebook || this.state.item.instagram ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/linkedin_icon.svg') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.twitter &&
                        <div
                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white ' + (this.state.item.linkedin || this.state.item.google || this.state.item.facebook || this.state.item.instagram ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/twitter_icon.svg') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.google_analytics &&
                        <div
                            className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white ' + (this.state.item.linkedin || this.state.item.google || this.state.item.facebook || this.state.item.instagram || this.state.item.twitter ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/ga_icon.png') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.google_shopping &&
                        <div
                            className={'w-8 h-8 bg-googleshopping-500 rounded-full p-2 border-2 border-white ' + (this.state.item.linkedin || this.state.item.google || this.state.item.facebook || this.state.item.instagram || this.state.item.twitter ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/google-shopping.png') + ")"}}></div>
                        </div>
                        }
                        {this.state.item.adform &&
                        <div
                            className={'w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white ' + (this.state.item.linkedin || this.state.item.google || this.state.item.facebook || this.state.item.instagram || this.state.item.twitter || this.state.item.google_analytics ? '-ml-2' : '')}>
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{backgroundImage: "url(" + require('../../assets/images/adform_icon.png') + ")"}}></div>
                        </div>
                        }
                    </div>
                </td>
                {
                    false &&
                    <td className="px-6 py-4 whitespace-no-wrap text-sm">
                        <div className="flex flex-row items-center">
                            <div
                                className="h-6 w-6 bg-purple-500 text-white flex items-center justify-center rounded-full font-bold">{this.state.item.users_count}</div>
                        </div>
                    </td>
                }
                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                    <div className="flex flex-row items-end justify-end">
                        {
                            false &&
                            <button onClick={() => {
                                this.props.history.push('/user/clients/' + this.state.item.id);
                            }}
                                    className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                <FeatherIcon className="stroke-current mr-1" size={16} icon="edit"/> <span>Edit client</span>
                            </button>
                        }
                        <button onClick={() => {
                            this.props.history.push('/user/clients/' + this.state.item.id);
                        }} className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white " + ((this.props.properties && this.props.properties.actions && this.props.properties.actions.delete)?'ml-2':'')}>
                            <span>Select client</span>
                            <FeatherIcon className="stroke-current ml-2" size={16}
                                         icon="arrow-right-circle"/>
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default ClientTableRow;
