import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import ClientTopNavigation from '../moduleFiles/clientTopNavigation';
import translations from '../assets/json/onboarding_translation.json'
import DropdownTailwind from '../moduleFiles/dropdownTailwind';

class ClientSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            closed: true,
            page: 1,
            languages: [
                { id: 1, name: "English", value: "en" },
                { id: 2, name: "Svenska", value: "sv" },
                { id: 3, name: "Dansk", value: "dk" },
                { id: 4, name: "Norsk", value: "no" }
            ],
            selected_language: { id: 0, name: "Select ...", value: "" },
            whitelabel_settings: {},

        };
    }

    componentWillMount() {
        this.init.user();
    }

    componentDidMount() {
    }

    init = {
        user: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
    };

    functions = {
        handleSave: async () => {

            this.state.whitelabel_settings.user_language = this.state.selected_language
            await this.promisedSetState({
                loading: true,
                whitelabel_settings: this.state.whitelabel_settings
            })

            // ADD MORE SETTINGS TO DATA OBJECT 
            let data = {
                whitelabelClient: this.state.whitelabel_settings,
            };

            let id = Object.keys(this.state.user).length > 0 ? this.state.user.id : null
            let res = await this.calls.updateClient(data, id)
            if (res.status === 200) {
                this.state.user.language = this.state.selected_language
                await this.promisedSetState({
                    user: this.state.user
                })
                userRegister.set(this.state.user)

                window.location.reload()
            }
            await this.promisedSetState({
                loading: false,
            })

        },
    };

    calls = {
        updateClient: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/client/updateProfile";
            return apiRegister.call(options, url);
        }
    }

    renders = {
        language: () => {
            try {
                if (this.state.selected_language.id !== 0) {
                    return this.state.selected_language
                } else if (this.state.user && this.state.user.language) {
                    return this.state.user.language
                } else {
                    return this.state.whitelabel_settings.defaultLanguage
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: "" }
            }
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = window.location.pathname.split("/")[2];

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {
        let t = this.translate;
        return (
            <>

                {
                    this.state.loading &&
                    <div className="h-screen flex flex-col justify-center items-center">
                        <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                            style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}></div>
                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <>
                        <ClientTopNavigation
                            breadcrumb={"Profile"}
                            showUser={true}
                        />

                        <div className='flex flex-col h-full p-4'>
                            <div className='flex flex-col justify-between h-full bg-white rounded-md shadow p-4 relative'>
                                <div className='flex flex-col md:grid grid-cols-3'>

                                    <div className='col-span-1'>
                                        <DropdownTailwind
                                            label={t("language")}
                                            // selected={this.state.whitelabel_settings && this.state.whitelabel_settings.language ? this.state.whitelabel_settings.language : { id: 0, name: "Select ...", value: "" }}
                                            selected={this.renders.language()}
                                            options={this.state.languages}
                                            onChange={async (language) => {
                                                await this.promisedSetState({
                                                    selected_language: language
                                                })
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className='block mt-4 md:flex md:w-full md:justify-end'>
                                    <div
                                        onClick={() => this.functions.handleSave()}
                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                        className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white'
                                    >
                                        <span
                                            style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                            {(t("save"))}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </>
                }

            </>
        )
    }
}

export default ClientSettings;