import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, CubeIcon, ExclamationCircleIcon, LightningBoltIcon, PlusIcon, TrashIcon, UserAddIcon, ArrowDownIcon, ArrowUpIcon, CheckIcon, FireIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import { userRegister } from '../services/userRegister';

class Actions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            search: "",
            sort: "triggerDate",
            direction: "ascending",
            orders: [],
            columns: [
                { name: 'title', value: 'title' },
                { name: 'type', value: 'type' },
                { name: 'deadline', value: 'triggerDate', min_width: '100px' },
                { name: 'client', value: 'client', min_width: '350px' }
            ],
            status: { id: 0, name: "All", value: "total" },
            client: { id: 0 },
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "checked",
                    placeholder: "All",
                    defaultOptions: [],
                    options: [
                        { id: 2, name: "Not completed", value: "unchecked" },
                        { id: 3, name: "Completed", value: "checked" }
                    ],
                    selected: { id: 2, name: "Not completed", value: "unchecked" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "type",
                    placeholder: "All",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Client/Order", value: "all" },
                        { id: 2, name: "Order", value: "order" },
                        { id: 3, name: "Client", value: "client" }
                    ],
                    selected: { id: 1, name: "Client/Order", value: "all" },
                    loading: false
                }
            ],
            throttling: {}
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.orders(true);
        this.props.updateStatistics();
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        orders: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.orders(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        orders: response.data,
                        meta: response.meta
                    });
                }

            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        menu: (option, row) => {
            if (option === "Open") {
                if (row.type == "client") {
                    if (row.client) {
                        this.props.history.push("/v2/client/" + row.client.id);
                    }
                } else if (row.type == "order") {
                    this.props.history.push("/v2/orders/" + row.order + "/actions");
                }
            }
        },
        updateAction: async (item) => {
            item.loading = true;
            await this.promisedSetState({
                orders: this.state.orders
            });
            try {
                await this.calls.updateAction({ checked: !item.checked, id: item.id });
                item.checked = !item.checked;
                item.loading = false;
                await this.promisedSetState({
                    orders: this.state.orders
                });
            } catch (error) {
                item.loading = false;
                await this.promisedSetState({
                    orders: this.state.orders
                });
            }
        }
    };

    renders = {
        urgent: (item) => {
            try {
                if (moment(item.triggerDate).subtract(7, 'days').isAfter(moment())) {
                    return false;
                } else {
                    if (item.checked) {
                        return false;
                    } else {
                        return true;
                    }
                }
            } catch (error) {
                return false;
            }
        }
    };

    calls = {
        orders: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listActions?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        updateAction: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAction?action=" + data.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.orders();
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.props.history.push("/v2/orders/new");
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.orders();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.orders(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta[this.state.status.value] : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        filters={[]}
                        hideUserDropdown={true}
                        breadcrumb={"Orders"}
                        buttons={[
                            { text: "Create new", icon: LightningBoltIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.orders();
                            this.props.updateStatistics(client);
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading actions ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className={`shadow bg-white w-full overflow-hidden h-full ${this.state.orders.length > 0 ? "rounded-t-lg" : "rounded-lg"}`}>

                            {/*NO ORDERS*/}
                            {
                                this.state.orders.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-sm font-medium">No Actions available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.orders.length > 0 &&
                                <div className="relative h-full">
                                    <div className="overflow-x-auto  table-overflow h-full">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                            <thead className="">
                                                <tr>
                                                    <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                    <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="border-l"></th>
                                                    {this.state.columns.map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.orders();
                                                                    }
                                                                }}
                                                                style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " border-l px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                            >
                                                                <div className="flex flex-row items-center justify-between">
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex flex-col">
                                                                            <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-gray-300">
                                                {
                                                    this.state.orders.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        item.hover = true;
                                                                        this.setState({
                                                                            orders: this.state.orders
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        item.hover = false;
                                                                        this.setState({
                                                                            orders: this.state.orders
                                                                        })
                                                                    }}
                                                                    className="border-b" key={item.id}>
                                                                    <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-3 flex flex-row items-center justify-center  border-gray-300"}>
                                                                        <Menu as="div" className="relative inline-block text-left">
                                                                            <div>
                                                                                <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    {
                                                                                        item.loading &&
                                                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </Menu.Button>
                                                                            </div>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                    {/* <Menu.Items className={`${this.state.orders.length - (index + 1) < 5 && this.state.orders.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}
                                                                                    <div className="py-1">
                                                                                        {
                                                                                            ["Open"].map((option) => {
                                                                                                return (
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    this.functions.menu(option, item);
                                                                                                                }}
                                                                                                                className={cn(
                                                                                                                    active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer ',
                                                                                                                    option == "Claim order" ? 'bg-purple-100 text-purple-500' : ''
                                                                                                                )}
                                                                                                            >
                                                                                                                {option}
                                                                                                                <div className="flex flex-1"></div>
                                                                                                                {
                                                                                                                    option == "Claim order" &&
                                                                                                                    <UserAddIcon className="w-5" />
                                                                                                                }
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </Menu.Items>
                                                                            </Transition>
                                                                        </Menu>
                                                                    </td>
                                                                    <td className={((this.state.sort === 'checked' || item.hover) ? "bg-gray-50" : "") + " border-l font-medium py-3 text-sm border-gray-300"}>
                                                                        <div className="w-full flex items-center justify-center">
                                                                            <div onClick={() => {
                                                                                if (!item.loading) {
                                                                                    this.functions.updateAction(item);
                                                                                }
                                                                            }} className={(item.checked ? "bg-purple-500" : "bg-white") + " cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300  text-sm font-medium  focus:outline-none"}>
                                                                                {
                                                                                    item.checked &&
                                                                                    <CheckIcon className="w-5 text-white" />
                                                                                }
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td onClick={() => {
                                                                        this.functions.menu("Open", item);
                                                                    }} className={((this.state.sort === 'title' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 py-3 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        {item.title ? item.title : "-"}
                                                                    </td>
                                                                    <td className={((this.state.sort === 'type' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 font-medium py-3 items-center text-sm border-gray-300"}>
                                                                        {item.type ? item.type : '-'}
                                                                    </td>
                                                                    <td className={((this.state.sort === 'triggerDate' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 font-medium py-3 items-center text-sm border-gray-300"}>
                                                                        <div className={(this.renders.urgent(item) ? "text-red-500" : "") + " flex flex-row"}>
                                                                            {item.triggerDate ? item.triggerDate : '-'}
                                                                            {
                                                                                this.renders.urgent(item) &&
                                                                                <FireIcon className="w-5 ml-2" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50" : "bg-white") + " border-l px-4 sm:px-6 py-3 font-medium  items-center text-sm   border-gray-300"}>
                                                                        <div className="flex flex-row items-center">
                                                                            <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                                {
                                                                                    item.client &&
                                                                                    <img src={item.client.logo} className="bg-cover" />
                                                                                }
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                {item.client ? item.client.name : "-"}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                        </div>
                        {/* PAGINATION */}

                        {
                            this.state.orders.length > 0 &&
                            <div className="flex flex-row p-4 items-center bg-white rounded-b-lg">
                                <div className="flex justify-center items-center">
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (this.state.page !== 1) {
                                                await this.promisedSetState({
                                                    page: this.state.page - 1
                                                });
                                                this.functions.orders();
                                            }
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                        Previous
                                    </button>
                                </div>
                                <div className="flex flex-1 items-center justify-center">
                                    {
                                        (this.state.meta ? +this.state.meta[this.state.status.value] : 0) !== 0 &&
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta[this.state.status.value] : 0)) ? (this.state.meta ? +this.state.meta[this.state.status.value] : 0) : this.state.page * this.state.limit}</span> of{' '}
                                            <span className="font-medium">{(this.state.meta ? +this.state.meta[this.state.status.value] : 0)}</span> results
                                        </p>
                                    }
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="">
                                        <button
                                            onClick={async () => {
                                                if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta[this.state.status.value] : 0))) {
                                                    await this.promisedSetState({
                                                        page: this.state.page + 1
                                                    });
                                                    this.functions.orders();
                                                }
                                            }}
                                            className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta[this.state.status.value] : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <span>Next</span>
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>
        )
    }
}

export default Actions;
