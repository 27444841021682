import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {BeatLoader, HashLoader} from "react-spinners";


class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {}

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div style={{zIndex: 53}} className="fixed bg-purple-900 bg-opacity-75 text-center text-white top-0 left-0 w-full h-full flex items-center justify-center">
                <div>
                    <div className="font-bold text-lg mb-4">
                        {this.props.title}
                    </div>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={16}
                        color={'#ffffff'}
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

export default Loader;
