import React, {Component} from 'react';
import './style.css';
import Account from "../../components/account";
import Accordion from "../../components/accordion";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import {calls} from "./calls";
import Loader from "../../components/loader";

class UserProfileAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submenu: 1
        };
    };

    componentWillMount() {}

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div className="w-full pt-8 pb-8">
                <div className="mb-4">
                    <h3 className="mb-0 font-bold">
                        Profile settings
                    </h3>
                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                        Avg. number of search per month in parantheses
                    </p>
                </div>
                <div className="bg-white rounded-md px-4 py-4 pt-0">
                    <div className="rounded-md">
                        <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                            <ul className="flex flex-row items-end">
                                {[{id: 1, name: "User information"}, {id: 2, name: "Update password"}].map((item, j) => {
                                    return (
                                        <li key={j}
                                            className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                            onClick={() => {
                                                this.setState({
                                                    submenu: item.id
                                                });
                                            }}>
                                            {item.name}
                                        </li>
                                    )
                                })
                                }
                                <li className="flex-grow border-b-2 border-gray-200"></li>
                            </ul>
                        </nav>
                        {
                            this.state.submenu === 1 &&
                            <div className="bg-white rounded-md">
                                <Info/>
                            </div>
                        }
                        {
                            this.state.submenu === 2 &&
                            <div className="bg-white rounded-md">
                                <Password/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class Info extends Component{

    constructor(props) {
        super(props);
        this.state = {
            success_modal: false,
            error_modal: false,
            email: "",
            name: "",
            loading: true,
            button_loading: false,
            error_message: "",
        };
    };

    componentWillMount() {
        this.functions.user();
    }

    functions = {
        user: () => {
            calls.getUser().then((response) => {
                this.setState({
                    loading: false,
                    email: response.data.email,
                    name: response.data.name
                });
            }, (error) => {
                this.setState({
                    loading: false
                });
            });
        },
        update: () => {
            if(!this.state.button_loading){
                this.setState({
                    button_loading: true
                }, () => {
                    dataRegister.updateUser({
                        email: this.state.email,
                        name: this.state.name
                    }).then((response) => {
                        this.setState({
                            button_loading: false,
                            success_modal: true,
                            email: response.email,
                            name: response.name
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    });
                });
            }
        }
    };

    renders = {};

    render(){
        return (
            <div className="">
                {
                    this.state.button_loading &&
                    <Loader
                        title={"Updating user"}
                    />
                }
                <SweetAlert
                    show={this.state.success_modal}
                    title="Updated"
                    type="success"
                    text="Click OK to continue!"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <div className="flex flex-1">
                    <Input
                        title={'Name'}
                        size={'full'}
                        type={'text'}
                        value={this.state.name}
                        onChange={(event) => this.setState({name: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        title={'Email'}
                        size={'full'}
                        type={'email'}
                        value={this.state.email}
                        onChange={(event) => this.setState({email: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4 justify-end">
                    {
                        false &&
                        <button onClick={() => {
                            this.functions.update();
                        }}
                                className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-purple-500 bg-opacity-100 text-white"}>
                            <span>Update user</span>
                        </button>
                    }
                    {
                        false &&
                        <div onClick={() => {
                            this.functions.update();
                        }} className="Button ButtonFull">
                            {
                                !this.state.button_loading &&
                                <div>Update</div>
                            }
                            {
                                this.state.button_loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

}

class Password extends Component{

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            new_password: "",
            error_message: "",
            open: false,
            success_modal: false,
            error_modal: false,
            button_loading: false,
        };
    };

    componentWillMount() {}

    functions = {
        update: () => {
            if(!this.state.button_loading){
                this.setState({button_loading: true}, () => {
                    dataRegister.updatePassword({
                        password: this.state.password,
                        new_password: this.state.new_password
                    }).then((response) => {
                        this.setState({
                            button_loading: false,
                            success_modal: true,
                            password: "",
                            new_password: ""
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    });
                });
            }
        }
    };

    renders = {};

    render(){
        return (
            <div className="">
                {
                    this.state.button_loading &&
                    <Loader
                        title={"Updating password"}
                    />
                }
                <SweetAlert
                    show={this.state.success_modal}
                    title="Updated"
                    type="success"
                    text="Your password has been updated"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <div className="flex flex-1">
                    <Input
                        size={'full'}
                        title={'Old password'}
                        type={'password'}
                        value={this.state.password}
                        onChange={(event) => this.setState({password: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        size={'full'}
                        title={'New password'}
                        type={'password'}
                        value={this.state.new_password}
                        onChange={(event) => this.setState({new_password: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4 justify-end">
                    <button onClick={() => {
                        this.functions.update();
                    }}
                            className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-purple-500 bg-opacity-100 text-white"}>
                        <span>Update password</span>
                    </button>
                    {
                        false &&
                        <div onClick={() => {
                            this.functions.update();
                        }} className="Button ButtonFull">
                            {
                                !this.state.button_loading &&
                                <div>Update</div>
                            }
                            {
                                this.state.button_loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default UserProfileAccount;
