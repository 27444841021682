import React, { Component } from 'react';
import InputTailwind from '../moduleFiles/inputTailwind';
import SuccessModal from '../moduleFiles/successModal';
import { apiRegister } from "../services/apiRegister";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            success: false
        }
    };

    functions = {
        forgotPassword: async () => {
            if (!this.state.loading) {
                await this.promisedSetState({ loading: true });
                try {
                    await this.calls.forgotPassword({ email: this.state.email });
                    this.promisedSetState({
                        loading: false,
                        success: true
                    });
                } catch (error) {
                    this.promisedSetState({
                        loading: false,
                        error: true
                    });
                }
            }
        }
    };

    calls = {
        forgotPassword: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/forgotPassword";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <SuccessModal
                    title={"Thank you!"}
                    text={"Check your email for password reset link"}
                    button={"Go to login"}
                    open={this.state.success}
                    onClose={async () => {
                        await this.promisedSetState({ success: false });
                        this.props.history.push('/login');
                    }}
                />
                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-12 w-auto"
                            src={require("../assets/images/adcredo_icon_purple.png")}
                            alt="Workflow"
                        />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot your password ? </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Please type your email below
                        </p>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="space-y-6">
                                <div>
                                    <InputTailwind
                                        locked={false}
                                        label={"Email address"}
                                        placeholder={""}
                                        value={this.state.email}
                                        onChange={(value) => {
                                            this.setState({
                                                email: value
                                            })
                                        }}
                                        onKeyDown={(value) => {
                                            if (value == 13) {
                                                this.functions.forgotPassword();
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            this.functions.forgotPassword();
                                        }}
                                        className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 w-full text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <span>Send</span>
                                        {
                                            this.state.loading &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ForgotPassword;
