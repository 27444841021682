import React, {Component} from 'react';
import './style.css';
import {Doughnut} from "react-chartjs-2";
import StatCard from "../../modules/statcard";
import {BeatLoader} from "react-spinners";

class CampaignDonuts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips:{
                    enabled:false,
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');
            
                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }
            
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }
            
                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }
            
                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }
            
                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);
            
                            var innerHtml = '<thead>';
            
                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';
            
                            bodyLines.forEach(function(body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';
            
                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }
            
                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();
            
                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            donutSize:65,
            impressions: null,
            click: null,
            ctr: null,
            cpc: null,
            conversions: null,
            spend: null,
            loading: true,
            properties: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            properties: nextProps.properties?nextProps.properties:[],
            loading: nextProps.loading
        }, () => {
            this.functions.data();
        });
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            properties: this.props.properties?this.props.properties:[],
            loading: this.props.loading
        }, () => {
            this.functions.data();
        });
    }

    init = {};

    functions = {
        data: () => {
            let impressions = {
                labels: [],
                datasets: [{
                    label: 'Impressions',
                    data: [],
                    backgroundColor: []
                }]
            };
            let click = {
                labels: [],
                datasets: [{
                    label: 'Click',
                    data: [],
                    backgroundColor: []
                }]
            };
            let ctr = {
                labels: [],
                datasets: [{
                    label: 'CTR',
                    data: [],
                    backgroundColor: []
                }]
            };
            let cpc = {
                labels: [],
                datasets: [{
                    label: 'CPC',
                    data: [],
                    backgroundColor: []
                }]
            };
            let conversions = {
                labels: [],
                datasets: [{
                    label: 'Conversions',
                    data: [],
                    backgroundColor: []
                }]
            };
            let spend = {
                labels: [],
                datasets: [{
                    label: 'Spend',
                    data: [],
                    backgroundColor: []
                }]
            };

            for (let key in this.state.data) {
                if(key !== 'information'){
                    this.state.data[key].campaigns = {};
                    this.state.data[key].campaigns[this.state.data[key].campaign.id] = {data: this.state.data[key].campaign};
                }
                if (key === 'facebook') {
                    for (let inner_key in this.state.data[key].campaigns) {
                        impressions.labels.push('Facebook');
                        impressions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.impressions ? this.state.data[key].campaigns[inner_key].data.impressions : 0);
                        impressions.datasets[0].backgroundColor.push('#3A5997');
                        click.labels.push('Facebook');
                        click.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.click ? this.state.data[key].campaigns[inner_key].data.click : 0);
                        click.datasets[0].backgroundColor.push('#3A5997');
                        ctr.labels.push('Facebook');
                        ctr.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.ctr ? this.state.data[key].campaigns[inner_key].data.ctr : 0);
                        ctr.datasets[0].backgroundColor.push('#3A5997');
                        cpc.labels.push('Facebook');
                        cpc.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.cpc ? this.state.data[key].campaigns[inner_key].data.cpc : 0);
                        cpc.datasets[0].backgroundColor.push('#3A5997');
                        conversions.labels.push('Facebook');
                        conversions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.conversions ? this.state.data[key].campaigns[inner_key].data.conversions : 0);
                        conversions.datasets[0].backgroundColor.push('#3A5997');
                        spend.labels.push('Facebook');
                        spend.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.spend ? this.state.data[key].campaigns[inner_key].data.spend : 0);
                        spend.datasets[0].backgroundColor.push('#3A5997');
                    }
                }
                if (key === 'instagram') {
                    for (let inner_key in this.state.data[key].campaigns) {
                        impressions.labels.push('Instagram');
                        impressions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.impressions ? this.state.data[key].campaigns[inner_key].data.impressions : 0);
                        impressions.datasets[0].backgroundColor.push('#DD2A7B');
                        click.labels.push('Instagram');
                        click.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.click ? this.state.data[key].campaigns[inner_key].data.click : 0);
                        click.datasets[0].backgroundColor.push('#DD2A7B');
                        ctr.labels.push('Instagram');
                        ctr.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.ctr ? this.state.data[key].campaigns[inner_key].data.ctr : 0);
                        ctr.datasets[0].backgroundColor.push('#DD2A7B');
                        cpc.labels.push('Instagram');
                        cpc.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.cpc ? this.state.data[key].campaigns[inner_key].data.cpc : 0);
                        cpc.datasets[0].backgroundColor.push('#DD2A7B');
                        conversions.labels.push('Instagram');
                        conversions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.conversions ? this.state.data[key].campaigns[inner_key].data.conversions : 0);
                        conversions.datasets[0].backgroundColor.push('#DD2A7B');
                        spend.labels.push('Instagram');
                        spend.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.spend ? this.state.data[key].campaigns[inner_key].data.spend : 0);
                        spend.datasets[0].backgroundColor.push('#DD2A7B');
                    }
                }
                if (key === 'google') {
                    for (let inner_key in this.state.data[key].campaigns) {
                        impressions.labels.push('Google');
                        impressions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.impressions ? this.state.data[key].campaigns[inner_key].data.impressions : 0);
                        impressions.datasets[0].backgroundColor.push('#4285F4');
                        click.labels.push('Google');
                        click.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.click ? this.state.data[key].campaigns[inner_key].data.click : 0);
                        click.datasets[0].backgroundColor.push('#4285F4');
                        ctr.labels.push('Google');
                        ctr.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.ctr ? this.state.data[key].campaigns[inner_key].data.ctr : 0);
                        ctr.datasets[0].backgroundColor.push('#4285F4');
                        cpc.labels.push('Google');
                        cpc.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.cpc ? this.state.data[key].campaigns[inner_key].data.cpc : 0);
                        cpc.datasets[0].backgroundColor.push('#4285F4');
                        conversions.labels.push('Google');
                        conversions.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.conversions ? this.state.data[key].campaigns[inner_key].data.conversions : 0);
                        conversions.datasets[0].backgroundColor.push('#4285F4');
                        spend.labels.push('Google');
                        spend.datasets[0].data.push(this.state.data[key].campaigns[inner_key].data.spend ? this.state.data[key].campaigns[inner_key].data.spend : 0);
                        spend.datasets[0].backgroundColor.push('#4285F4');
                    }
                }
            }
            this.setState({
                impressions: impressions,
                click: click,
                ctr: ctr,
                cpc: cpc,
                conversions: conversions,
                spend: spend
            })
        }
    };

    renders = {
        impressions: () => {
            try {
                let total = 0;
                this.state.impressions.datasets[0].data.map((item) => {
                    total = total + item;
                });
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
        click: () => {
            try {
                let total = 0;
                this.state.click.datasets[0].data.map((item) => {
                    total = total + item;
                });
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
        ctr: () => {
            try {
                let total = 0;
                this.state.ctr.datasets[0].data.map((item) => {
                    total = total + item;
                });
                total = total / this.state.ctr.datasets[0].data.length;
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
        cpc: () => {
            try {
                let total = 0;
                this.state.cpc.datasets[0].data.map((item) => {
                    total = total + item;
                });
                total = total / this.state.cpc.datasets[0].data.length;
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
        conversions: () => {
            try {
                let total = 0;
                this.state.conversions.datasets[0].data.map((item) => {
                    total = total + item;
                });
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
        spend: () => {
            try {
                let total = 0;
                this.state.spend.datasets[0].data.map((item) => {
                    total = total + item;
                });
                return parseFloat(parseFloat(total).toFixed(2));
            } catch (e) {
                return 0
            }
        },
    };

    render() {
        return (
            <div className="relative" style={{width: '100%'}}>
                <div className="data--wrapper flex flex-row flex-wrap md:-mx-2">
                    {this.state.impressions && this.state.properties.filter((key) => {return key === 'impressions'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.impressions()}
                                label={'Impressions'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.impressions}
                            />
                        </div>
                    }
                    {this.state.click && this.state.properties.filter((key) => {return key === 'click'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.click()}
                                label={'Clicks'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.click}
                            />
                        </div>
                    }
                    {this.state.ctr && this.state.properties.filter((key) => {return key === 'ctr'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.ctr()}
                                unit={'%'}
                                label={'Avg. CTR'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.ctr}
                            />
                        </div>
                    }
                    {this.state.cpc && this.state.properties.filter((key) => {return key === 'cpc'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.cpc()}
                                label={'Avg. CPC'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.cpc}
                            />
                        </div>
                    }
                    {this.state.conversions && this.state.properties.filter((key) => {return key === 'conversions'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.conversions()}
                                label={'Conversions'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.conversions}
                            />
                        </div>
                    }
                    {this.state.spend && this.state.properties.filter((key) => {return key === 'spend'}).length > 0 &&
                        <div className="data--item w-full md:w-auto md:px-2 mb-4 flex-grow flex-1">
                            <StatCard
                                stats={this.renders.spend()}
                                label={'Spend'}
                                unit={'kr'}
                                donutSize={this.state.donutSize}
                                donutOptions={this.state.doughnut}
                                donutData={this.state.spend}
                            />
                        </div>
                    }
                </div>
                {this.props.showchannels &&
                <div className="explainer flex flex-row">
                    {this.state.data && this.state.data.facebook &&
                    <div className="explainer--item rounded p-2 flex flex-row items-center bg-facebook-100 text-facebook-500 font-bold text-xs mr-2">
                        <div className="w-4 h-4 rounded mr-2 bg-facebook-500"></div>
                        <span>Facebook</span>
                    </div>
                    }
                    {this.state.data && this.state.data.instagram &&
                    <div className="explainer--item rounded p-2 flex flex-row items-center bg-instagram-100 text-instagram-500 font-bold text-xs mr-2">
                        <div className="w-4 h-4 rounded mr-2 bg-instagram-500"></div>
                        <span>Instagram</span>
                    </div>
                    }
                    {this.state.data && this.state.data.google &&
                    <div className="explainer--item rounded p-2 flex flex-row items-center bg-google-100 text-google-500 font-bold text-xs">
                        <div className="w-4 h-4 rounded mr-2 bg-google-500"></div>
                        <span>Google</span>
                    </div>
                    }
                </div>
                }
                {this.state.loading &&
                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-white flex justify-center items-center">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={12}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default CampaignDonuts;
