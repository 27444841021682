import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, PaperClipIcon, PhotographIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';

class OrderActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_update: false,
            order: {},
            tab: {},
            actions: [],
            client: {}
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        this.functions.getActions();
        this.props.updateStatistics();
    }

    functions = {
        getActions: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.actions();
                await this.promisedSetState({
                    order: response.data,
                    actions: response.data.actions,
                    meta: response.meta,
                    client: response.client
                });
                if (this.props.setOrder) {
                    this.props.setOrder(this.state.order);
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        updateActions: async () => {
            await this.promisedSetState({ loading_update: true });
            try {
                await this.calls.actions(this.state.actions);
            } catch (error) {
                await this.promisedSetState({ loading_update: false });
            }
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        }
    };

    calls = {
        actions: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderActions?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateActions: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateActions?order=" + this.state.order.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={() => {

                        }}
                        hideUserDropdown={true}
                        breadcrumb={"Documents"}
                        history={this.props.history}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading actions ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="shadow bg-white rounded-lg w-full h-full pb-4">
                            <div className="py-5 px-4 sm:px-6 pb-0">
                                {
                                    Array.isArray(this.state.actions) &&
                                    this.state.actions.map((item) => {
                                        return (
                                            <div className="rounded-md bg-blue-100 p-5 flex flex-col mb-4 relative">
                                                {
                                                    this.state.loading_update &&
                                                    <div className="w-full h-full absolute bg-blue-100 opacity-50 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div className="w-full flex flex-row">
                                                    <div className="mr-4">
                                                        <div onClick={() => {
                                                            if (!this.state.loading_update) {
                                                                item.checked = !item.checked;
                                                                item.completed = new Date();
                                                                item.agent = this.state.user.email;
                                                                this.setState({
                                                                    actions: this.state.actions
                                                                }, () => {
                                                                    this.functions.updateActions()
                                                                });
                                                            }
                                                        }} className={(item.checked ? "bg-purple-500" : "bg-white") + " cursor-pointer flex justify-center items-center rounded-md border h-10 w-10"}>
                                                            {
                                                                item.checked &&
                                                                <CheckIcon className="w-5 text-white" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1">
                                                        <div className="w-full">
                                                            <div className="text-sm font-medium">
                                                                {item.title}
                                                            </div>
                                                            <div className="text-sm font-light">
                                                                {item.description}
                                                            </div>
                                                            <div onClick={() => {
                                                                item.open = !item.open;
                                                                this.setState({
                                                                    actions: this.state.actions
                                                                });
                                                            }} className="text-sm cursor-pointer underline mt-4">Show more</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="text-xs text-right">
                                                            {moment(item.created).format("YYYY-MM-DD")}
                                                        </div>
                                                        {
                                                            item.checked &&
                                                            <div className="text-xs mt-2 text-purple-500 text-right">
                                                                {moment(item.completed).format("YYYY-MM-DD")}
                                                            </div>
                                                        }
                                                        {
                                                            item.checked &&
                                                            <div className="text-xs mt-2 text-purple-500 text-right">
                                                                {item.agent}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    item.open &&
                                                    <div className="bg-white text-sm text-black border rounded-md w-full mt-5 p-4">
                                                        {
                                                            item.data &&
                                                            <pre style={{ textWrap: "pretty" }} className="">
                                                                {JSON.stringify(item.data, null, 2)}
                                                            </pre>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OrderActions;