import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import {SlideDown} from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesAdsItem from "../advertising-creatives-ads-item";
import {calls} from "./calls";

class AdvertisingCreativesProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {},
            client: {},
            site: {},
            creatives: {},
            languages: [],
            dynamic: [],
            errors: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            site: nextProps.site,
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            languages: nextProps.languages,
            dynamic: nextProps.dynamic,
            errors: nextProps.errors?nextProps.errors:[]
        }, () => {
            //console.log(this.state.dynamic);
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            languages: this.props.languages,
            channels: this.props.channels,
            client: this.props.client,
            site: this.props.site,
            dynamic: this.props.dynamic,
            creatives: this.props.creatives,
            errors: this.props.errors?this.props.errors:[]
        }, () => {
            this.functions.catalogs();
        });
    }

    init = {};

    functions = {
        catalogs: () => {
            calls.getCatalogs({channels: JSON.stringify({facebook: true}), client: this.state.client.id}).then((response) => {
                this.state.creatives.catalogs = response.data;
                this.setState({
                    creatives: this.state.creatives
                }, () => {
                    this.props.onChange(this.state.creatives);
                })
            }, (error) => {
                this.state.creatives.catalogs = [];
                this.setState({
                    creatives: this.state.creatives
                }, () => {
                    this.props.onChange(this.state.creatives);
                })
            });
        }
    };

    validators = {};

    renders = {};

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    <div className="flex flex-1 flex-row items-center pt-4">
                        <div className="bg-white rounded-md overflow-hidden w-full flex-1">
                            <div className="px-4 py-4 flex-row flex">
                                <div
                                    className="h-10 mr-4 bg-purple-100 text-purple-500 rounded w-10 flex justify-center align-middle items-center">
                                    <FeatherIcon className="stroke-current" size={16} icon="box"/>
                                </div>
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        Dynamic ads
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                <span
                                                    className="font-bold mr-1">{this.state.dynamic.length}</span> available
                                    </p>
                                </div>
                                <div className="flex flex-row">
                                    <button onClick={() => {
                                        try{
                                            this.props.createProduct();
                                        }catch (e) {}
                                    }} className="bg-purple-500 text-2xl relative text-white h-10 w-10 flex justify-center items-center rounded">
                                        +
                                    </button>
                                    {
                                        this.state.dynamic.length > 0 &&
                                        <button
                                            className="bg-gray-200 text-gray-900 ml-2 h-10 w-10 flex justify-center items-center rounded"
                                            onClick={() => {
                                                this.setState({
                                                    open_dynamic: !this.state.open_dynamic
                                                })
                                            }}>
                                            {
                                                !this.state.open_dynamic &&
                                                <FeatherIcon className="stroke-current" size={16}
                                                             icon={"chevron-down"}/>
                                            }
                                            {
                                                this.state.open_dynamic &&
                                                <FeatherIcon className="stroke-current" size={16}
                                                             icon={"chevron-up"}/>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                            <SlideDown closed={this.state.open_dynamic}>
                                {
                                    this.state.dynamic.length > 0 &&
                                    <div className="w-full border-t"/>
                                }
                                <div className="">
                                    {
                                        this.state.dynamic.map((item, index) => {
                                            return (
                                                <AdvertisingCreativesAdsItem
                                                    last={index === (this.state.dynamic.length - 1)}
                                                    item={item}
                                                    index={index}
                                                    dynamic={true}
                                                    errors={this.state.errors}
                                                    catalogs={this.state.creatives.catalogs?this.state.creatives.catalogs:[]}
                                                    creatives={this.state.creatives}
                                                    channels={this.state.channels}
                                                    content={this.state.creatives.dynamic_ads}
                                                    languages={this.state.languages}
                                                    client={this.state.client}
                                                    onChange={(value) => {
                                                        this.state.creatives.dynamic_ads = value;
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.props.onChange(this.state.creatives);
                                                        })
                                                    }}
                                                    updateCreatives={(value) => {
                                                        this.setState({
                                                            creatives: value
                                                        }, () => {
                                                            this.props.onChange(this.state.creatives);
                                                        })
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </SlideDown>
                        </div>
                    </div>
                }

                {
                    false &&
                    <div style={{width: '100%'}} className="Section-Inner">
                        {
                            <div className="flex flex-1 flex-row items-center pt-6 ">
                                <h4 className="mb-0 text-lg text-purple-900 font-bold flex flex-row items-center border-gray-200">
                                    <div className="w-10 h-10 flex items-center justify-center text-white bg-blue-500 mr-2 rounded-full">
                                        <FeatherIcon className="stroke-current" size={16} icon="gift"/>
                                    </div>
                                    {this.state.dynamic.length > 0 && <span className="mr-1">{this.state.dynamic.length}</span>}Product ads
                                </h4>
                                <div className="flex flex-1"></div>
                                <div onClick={() => {
                                    try{
                                        this.props.createProduct();
                                    }catch (e) {}
                                }} style={{width: '150px'}} className="bg-indigo-500 text-white flex-row cursor-pointer text-xs px-4 py-2 flex justify-center items-center rounded-full font-bold hover:bg-transparent hover:text-indigo-500 duration-200 transition-all">
                                    Add product
                                </div>
                            </div>
                        }
                        {
                            this.state.dynamic.map((item, index) => {
                                return (
                                    <AdvertisingCreativesAdsItem
                                        item={item}
                                        index={index}
                                        dynamic={true}
                                        errors={this.state.errors}
                                        catalogs={this.state.creatives.catalogs?this.state.creatives.catalogs:[]}
                                        creatives={this.state.creatives}
                                        channels={this.state.channels}
                                        content={this.state.creatives.dynamic_ads}
                                        languages={this.state.languages}
                                        client={this.state.client}
                                        onChange={(value) => {
                                            this.state.creatives.dynamic_ads = value;
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.props.onChange(this.state.creatives);
                                            })
                                        }}
                                        updateCreatives={(value) => {
                                            this.setState({
                                                creatives: value
                                            }, () => {
                                                this.props.onChange(this.state.creatives);
                                            })
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesProducts;
