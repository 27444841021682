import React, {Component} from 'react';
import './style.css';
import cx from "classnames";

class IconLinkedin extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    functions = {};

    renders = {};

    render() {
        return (
            <div className={(!this.props.large?"w-5 h-5":"w-8 h-8") + " flex rounded-full bg-linkedin-500 justify-center align-middle items-center"}>
                <img className={(!this.props.large ?"w-2":"w-3")} src={require('../../../assets/images/linkedin_icon.svg')}/>
            </div>
        )
    }
}

export default IconLinkedin;
