import React, { Component, Fragment } from 'react';
import { ArrowRightIcon, ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CameraIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, GiftIcon, PlusCircleIcon, PlusIcon, SparklesIcon, SwitchVerticalIcon, VideoCameraIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import languages from '../assets/json/languages.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import PreviewGoogleSearch from '../previews/google/search';
import TextAreaTailwind from './textAreaTailwind';

import { searchGoogle } from '../validators/searchGoogle';
import Input from '../components/input';

const fuzzysort = require('fuzzysort');

class CreateOrderCreativeSearchAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            keywordLists: [],
            group: {},
            sort_suggestions: 'avg_monthly_searches',
            direction_suggestions: 'descending',
            ai_suggetion: false,
            headlines_max_characters: 30,
            description_max_characters: 90,
            headlines_amount: { id: 15, name: "15", value: 15 },
            description_amount: { id: 4, name: "4", value: 4 },
            ai_action: { id: 1, name: "Suggestion" },
            language: { id: 1, name: "English" },
            searchOpenAi: "",
            OpenAiCompare: "",
            OpenAiResult: [],
            loading_suggest: false,
            openAi_error: false,
            ai_result: false,
            assistant_index: 0,
            ai_loading: false,

        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            groups: this.props.groups,
            keywordLists: this.props.keywordLists ? this.props.keywordLists : [],
            client: this.props.client,
        });

        //IF ONLY ONE GROUP SELETED
        if (Array.isArray(this.state.groups) && this.state.groups.length === 1) {
            await this.promisedSetState({
                group: this.state.groups[0]
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        } else if (this.state.groups && !Array.isArray(this.state.groups)) {
            await this.promisedSetState({
                group: this.state.groups
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        } else {
            this.state.group.name = "";
            this.state.group.link = "";
            this.state.group.OpenAiResult = [];
            this.state.group.format = { id: 1, name: "Responsive text ad", value: "responsive" };
            this.state.group.headlines = [];
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies = [];
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
            await this.promisedSetState({
                group: this.state.group
            });
            this.functions.combinations();
            this.functions.insideHeadlines();
            this.functions.insideDescriptions();
        }
        if (this.state.group.OpenAiResult && Array.isArray(this.state.group.OpenAiResult) && this.state.group.OpenAiResult.length > 0) {
            await this.promisedSetState({
                ai_result: true
            });
            console.log(this.state.group.OpenAiResult, "INSIDE FILE");
            this.props.onUpdateChat(this.state.group.OpenAiResult);
        }
        //this.functions.typeWriter();

    }

    functions = {
        save: async () => {
            if (this.props.onSave) {
                this.props.onSave(this.state.group);
            }
        },
        scroll: () => {
            try {
                let myDiv = document.getElementById('chat');
                myDiv.scrollTo(0, 100000000000);
            } catch (error) {
            }
        },
        addKeyword: async (person) => {
            if (!Array.isArray(this.state.group.headlines)) {
                this.state.group.headlines = [];
            }
            if (this.state.group.headlines[0] && this.state.group.headlines[0].text == "") {
                this.state.group.headlines[0].text = person.keyword;
            } else if (this.state.group.headlines[1] && this.state.group.headlines[1].text == "") {
                this.state.group.headlines[1].text = person.keyword;
            } else if (this.state.group.headlines[2] && this.state.group.headlines[2].text == "") {
                this.state.group.headlines[2].text = person.keyword;
            } else {
                if (this.state.group.headlines.length < 15) {
                    this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: person.keyword });
                }
            }
            await this.promisedSetState({
                group: this.state.group
            });
            this.functions.insideHeadlines();
        },
        updateOpenAi: async (data) => {
            //console.log(data, "OpenAiResult ===");
            this.state.group.OpenAiResult = data;
            await this.promisedSetState({
                group: this.state.group,
            });
        },
        addHeadlines: async (headlines) => {
            for (let i = 0; i < headlines.length; i++) {
                if (this.state.group.headlines.filter((inner_item) => { return inner_item.text !== "" }).length < 15) {
                    if (this.state.group.headlines.filter((inner_item) => { return inner_item.text === headlines[i] }).length > 0) {
                    } else if (this.state.group.headlines.filter((item) => { return item.text === "" }).length > 0 && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headlines[i] }).length < 1) {
                        let buff = this.state.group.headlines.filter((item) => { return item.text === "" })[0];
                        this.state.group.headlines = this.state.group.headlines.map((inner_item) => {
                            if (inner_item.id === buff.id) {
                                inner_item.text = headlines[i]
                            }
                            return inner_item
                        })
                    } else {
                        this.state.group.headlines.push({
                            id: Math.floor((Math.random() * 9999999999) + 1),
                            text: headlines[i]
                        })
                    }
                    await this.promisedSetState({
                        group: this.state.group
                    })
                    this.functions.insideHeadlines();
                    await new Promise(resolve => setTimeout(resolve, 300));
                    this.props.onUpdatePreview(this.state.group);
                }
            }
        },
        addDescriptions: async (descriptions) => {
            for (let i = 0; i < descriptions.length; i++) {
                if (this.state.group.bodies.filter((inner_item) => { return inner_item.text !== "" }).length < 4) {
                    if (this.state.group.bodies.filter((inner_item) => { return inner_item.text === descriptions[i] }).length > 0) {
                    } else if (this.state.group.bodies.filter((item) => { return item.text === "" }).length > 0 && this.state.group.bodies.filter((inner_item) => { return inner_item.text === descriptions[i] }).length < 1) {
                        let buff = this.state.group.bodies.filter((item) => { return item.text === "" })[0];
                        this.state.group.bodies = this.state.group.bodies.map((inner_item) => {
                            if (inner_item.id === buff.id) {
                                inner_item.text = descriptions[i]
                            }
                            return inner_item
                        })
                    } else {
                        this.state.group.bodies.push({
                            id: Math.floor((Math.random() * 9999999999) + 1),
                            text: descriptions[i]
                        })
                    }
                    await this.promisedSetState({
                        group: this.state.group
                    })

                    this.functions.insideDescriptions();
                    await new Promise(resolve => setTimeout(resolve, 300));
                    this.props.onUpdatePreview(this.state.group);
                }
            }
        },
        removeDescription: async (description) => {
            this.state.group.bodies = JSON.parse(JSON.stringify(this.state.group.bodies.filter((item) => { return item.text !== description })));
            await this.promisedSetState({
                group: this.state.group
            })
            this.props.onUpdatePreview(this.state.group);
        },
        removeHeadline: async (headline) => {
            try {
                this.state.group.headlines = JSON.parse(JSON.stringify(this.state.group.headlines.filter((item) => { return item.text !== headline })));
                await this.promisedSetState({
                    group: this.state.group
                })
                this.props.onUpdatePreview(this.state.group);
            } catch (error) { }
        },
        typeWriter: () => {
            return new Promise(async (resolve) => {

                try {

                    if (this.state.group.OpenAiResult[this.state.group.OpenAiResult.length - 1].content !== "") {
                        await this.promisedSetState({
                            assistant_index: this.state.group.OpenAiResult.length - 1
                        });
                        let element = document.getElementById('assistant_' + (this.state.group.OpenAiResult.length - 1));

                        for (let i = 0; i < this.state.group.OpenAiResult[this.state.group.OpenAiResult.length - 1].content.length; i++) {
                            element.innerHTML += this.state.group.OpenAiResult[this.state.group.OpenAiResult.length - 1].content.charAt(i);
                            await new Promise(resolve => setTimeout(resolve, 10));
                            this.functions.scroll();
                        }
                        await this.promisedSetState({
                            assistant_index: 99999
                        });
                    }
                } catch (error) {
                }
                resolve();
            });
        },
        openAi: async (init = false) => {

            let data = {
                message: this.state.group.OpenAiResult.filter((item) => { return !item.fake }).map((item) => { return { role: item.role, content: item.content } }),
                language: this.state.language.name,
                keyword_list: this.state.keyword_list ? this.state.keyword_list.keywords.map((item) => { return item.keyword }) : [],
            }
            if (!init && this.state.searchOpenAi !== "") {
                data.message.push(
                    {
                        role: "user",
                        content: this.state.searchOpenAi
                    }
                )

            }

            await this.promisedSetState({
                ai_loading: true,
                searchOpenAi: ""
            })
            this.functions.scroll();
            //await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data, init);
                if (response.data.content.includes("***") || response.data.content.includes("```")) {
                    let regex = /\*\*\*(.*?)\*\*\*/s;
                    if (response.data.content.includes("```")) {
                        regex = /```(.*?)```/s;
                    }
                    let buffer = response.data.content.match(regex);
                    response.data.not_show = true;
                    this.state.group.OpenAiResult.push(response.data);
                    await this.promisedSetState({
                        group: this.state.group
                    })
                    if (buffer && buffer[1]) {
                        response.data.content = response.data.content.replace(buffer[0], "*+*");
                        let parts = response.data.content.split("*+*");
                        if (parts[0] !== "") {
                            this.state.group.OpenAiResult.push({ role: "assistant", fake: true, content: parts[0] });
                            await this.promisedSetState({
                                group: this.state.group,
                                ai_loading: false,
                            })
                            this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                        let a = JSON.parse(buffer[1]);
                        let headlines = a.Google && a.Google.Headline ? a.Google.Headline : [];
                        let descriptions = a.Google && a.Google.Description ? a.Google.Description : [];
                        if (Array.isArray(headlines) && headlines.length > 0 && this.state.ai_action.id === 2) {
                            for (let i = 0; i < headlines.length; i++) {
                                if (this.state.group.headlines.filter((inner_item) => { return inner_item.text !== "" }).length < 15) {
                                    if (this.state.group.headlines.filter((inner_item) => { return inner_item.text === headlines[i] }).length > 0) {
                                    } else if (this.state.group.headlines.filter((item) => { return item.text === "" }).length > 0 && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headlines[i] }).length < 1) {
                                        let buff = this.state.group.headlines.filter((item) => { return item.text === "" })[0];
                                        this.state.group.headlines = this.state.group.headlines.map((inner_item) => {
                                            if (inner_item.id === buff.id) {
                                                inner_item.text = headlines[i]
                                            }
                                            return inner_item
                                        })
                                    } else {
                                        this.state.group.headlines.push({
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            text: headlines[i]
                                        })
                                    }
                                    await this.promisedSetState({
                                        group: this.state.group
                                    })
                                }
                            }
                        }
                        if (Array.isArray(descriptions) && descriptions.length > 0 && this.state.ai_action.id === 2) {
                            for (let i = 0; i < descriptions.length; i++) {
                                if (this.state.group.bodies.filter((inner_item) => { return inner_item.text !== "" }).length < 4) {
                                    if (this.state.group.bodies.filter((inner_item) => { return inner_item.text === descriptions[i] }).length > 0) {
                                    } else if (this.state.group.bodies.filter((item) => { return item.text === "" }).length > 0 && this.state.group.bodies.filter((inner_item) => { return inner_item.text === descriptions[i] }).length < 1) {
                                        let buff = this.state.group.bodies.filter((item) => { return item.text === "" })[0];
                                        this.state.group.bodies = this.state.group.bodies.map((inner_item) => {
                                            if (inner_item.id === buff.id) {
                                                inner_item.text = descriptions[i]
                                            }
                                            return inner_item
                                        })
                                    } else {
                                        this.state.group.bodies.push({
                                            id: Math.floor((Math.random() * 9999999999) + 1),
                                            text: descriptions[i]
                                        })
                                    }
                                    await this.promisedSetState({
                                        group: this.state.group
                                    })
                                }
                            }
                        }
                        this.state.group.OpenAiResult.push({ role: "assistant", fake: true, content: "", headlines: headlines, descriptions: descriptions });
                        await this.promisedSetState({
                            group: this.state.group,
                            ai_loading: false,
                        })
                        this.functions.scroll();
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        if (parts[1] !== "") {
                            this.state.group.OpenAiResult.push({ role: "assistant", fake: true, content: parts[1].replace(/\n/g, "") });
                            await this.promisedSetState({
                                group: this.state.group,
                                ai_loading: false,
                            })
                            this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                    }
                } else {
                    this.state.group.OpenAiResult.push(response.data);
                    await this.promisedSetState({
                        group: this.state.group,
                        ai_loading: false,
                    })
                    this.props.onUpdateChat(this.state.group.OpenAiResult);
                }
                console.log(response, "response");
            } catch (error) {

            }

        },
        openAi1: async () => {

            let data = {
                text: this.renders.promt_text(),
                temperature: this.state.temperature
            }

            await this.promisedSetState({ loading_suggest: true, ai_result: true });
            try {
                let response = await this.calls.openAi(data);

                if (!Array.isArray(this.state.group.OpenAiResult)) {
                    this.state.group.OpenAiResult = [];
                }

                this.state.group.OpenAiResult = this.state.group.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })





                if (response.data[0].text.includes("***") || response.data[0].text.includes("```")) {
                    let regex = /\*\*\*(.*?)\*\*\*/s;
                    if (response.data[0].text.includes("```")) {
                        regex = /```(.*?)```/s;
                    }
                    let buffer = response.data[0].text.match(regex);
                    if (buffer && buffer[1]) {
                        let a = JSON.parse(buffer[1]);
                        let headlines = [];
                        let descriptions = [];
                        headlines = a.Headline;
                        descriptions = a.Description;
                        this.state.group.OpenAiResult.unshift({ headlines: headlines, descriptions: descriptions, error: false, hidden: false });
                    }

                }

                //response.data[0].text = response.data[0].text.replace(/\\/g, "");
                //response.data[0].text = response.data[0].text.replace(/\\n/g, "");

                //let a = JSON.parse(response.data[0].text);



                this.promisedSetState({
                    group: this.state.group,
                    loading_suggest: false,
                    openAi_error: false
                })

            } catch (error) {

                if (!Array.isArray(this.state.group.OpenAiResult)) {
                    this.state.group.OpenAiResult = [];
                }

                this.state.group.OpenAiResult = this.state.group.OpenAiResult.map((item) => {
                    item.hidden = true
                    return item;
                })

                this.state.group.OpenAiResult.unshift({
                    text: ((error && error.body && error.body.message) ? error.body.message : "Something went wrong"),
                    error: true
                });

                await this.promisedSetState({
                    group: this.state.group,
                    loading_suggest: false,
                    openAi_error: true
                });

            }
        },
        combinations: () => {

            let combinations_headlines = [];
            let combinations_bodies = [];
            let combinations = [];

            if (Array.isArray(this.state.group.headlines)) {
                let first_headlines = [];
                let second_headlines = [];
                let third_headlines = [];
                this.state.group.headlines.map((item) => {
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_1"))) {
                        first_headlines.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_2"))) {
                        second_headlines.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "HEADLINE_3"))) {
                        third_headlines.push(item);
                    }
                });
                first_headlines.map((first) => {
                    second_headlines.map((second) => {
                        third_headlines.map((third) => {
                            let combo = { headlineFirst: first, headlineSecond: second, headlineThird: third };
                            combinations_headlines.push(combo);
                        })
                    })
                });
                combinations_headlines = combinations_headlines.filter((item) => {
                    let keys = [];
                    for (let key in item) {
                        keys.push(item[key].id)
                    };
                    let uniqueArray = keys.filter(function (item, pos) {
                        return keys.indexOf(item) == pos;
                    });
                    return uniqueArray.length === 3;
                })
            }

            if (this.state.group.bodies) {
                let first_bodies = [];
                let second_bodies = [];
                this.state.group.bodies.map((item) => {
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "DESCRIPTION_1"))) {
                        first_bodies.push(item);
                    }
                    if (!item.pinned || (item.pinned && (item.pinned.value === "UNSPECIFIED" || item.pinned.value === "DESCRIPTION_2"))) {
                        second_bodies.push(item);
                    }
                });
                first_bodies.map((first) => {
                    second_bodies.map((second) => {
                        let combo = { bodyOne: first, bodySecond: second };
                        combinations_bodies.push(combo);
                    })
                });
                combinations_bodies = combinations_bodies.filter((item) => {
                    let keys = [];
                    for (let key in item) {
                        keys.push(item[key].id)
                    };
                    let uniqueArray = keys.filter(function (item, pos) {
                        return keys.indexOf(item) == pos;
                    });
                    return uniqueArray.length === 2;
                })
            }

            combinations_bodies.map((bodies) => {
                combinations_headlines.map((headlines) => {
                    let combo = { ...bodies, ...headlines };
                    combinations.push(combo);
                });
            });

            this.state.group.combinations = combinations.filter((item, index) => { return index < 100 });

            this.setState({
                group: this.state.group
            })
            this.props.onUpdatePreview(this.state.group);

        },
        insideHeadlines: async () => {
            if (Array.isArray(this.state.group.headlines) && this.state.keyword_list && Array.isArray(this.state.keyword_list.keywords)) {
                this.state.keyword_list.keywords = this.state.keyword_list.keywords.map((item) => { item.inside_headline = false; return item; })
                this.state.group.headlines.map((item) => {
                    this.state.keyword_list.keywords = this.state.keyword_list.keywords.map((inner_item) => {
                        if (typeof inner_item.keyword === "string" && typeof item.text === "string" && item.text.toLowerCase().indexOf(inner_item.keyword.toLowerCase()) !== -1) {
                            inner_item.inside_headline = true;
                        }
                        return inner_item;
                    });
                });
                await this.promisedSetState({
                    keyword_list: this.state.keyword_list
                });
            }
            this.props.updateKeywordList(this.state.keyword_list);
        },
        insideDescriptions: async () => {
            if (Array.isArray(this.state.group.bodies) && this.state.keyword_list && Array.isArray(this.state.keyword_list.keywords)) {
                this.state.keyword_list.keywords = this.state.keyword_list.keywords.map((item) => { item.inside_description = false; return item; })
                this.state.group.bodies.map((item) => {
                    this.state.keyword_list.keywords = this.state.keyword_list.keywords.map((inner_item) => {
                        if (typeof inner_item.keyword === "string" && typeof item.text === "string" && item.text.toLowerCase().indexOf(inner_item.keyword.toLowerCase()) !== -1) {
                            inner_item.inside_description = true;
                        }
                        return inner_item;
                    });
                });
                //console.log(this.state.keyword_list);
                await this.promisedSetState({
                    keyword_list: this.state.keyword_list
                });
            }
            this.props.updateKeywordList(this.state.keyword_list);
        }
    };

    calls = {
        openAi: (data, init) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAiAssistant?client=" + this.state.client.id + "&init=" + init;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        sort: (list, sort, order) => {
            if (!Array.isArray(list)) {
                list = [];
            }
            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : 0) : (x[sort] ? x[sort] : 0);
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : 0) : (y[sort] ? y[sort] : 0);
                    if (order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }
            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : "") : (x[sort] ? x[sort] : "");
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : "") : (y[sort] ? y[sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }
            if (sort === "keyword" || sort === "competition") {
                return sortCharacter(list);
            } else {
                return sortNumber(list);
            }
        },
        adStrength: () => {
            let strength = "Incomplete";

            if (Array.isArray(this.state.group.headlines) && this.state.group.headlines.filter((item) => { return item.text !== "" }).length > 2 && this.state.group.bodies.filter((item) => { return item.text !== "" }).length > 1) {

                strength = "Poor";

                // Average (Popular keywords)
                if (this.state.keyword_list && Array.isArray(this.state.keyword_list.keywords) && this.state.keyword_list.keywords.filter((item) => { return item.inside_headline }).length > 4) {
                    strength = "Average";
                }

                // Good (Unique headlines)
                if (strength === "Average" && this.state.group.headlines.filter((value, index, self) => {
                    return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                }).length > 4) {
                    strength = "Good";
                }

                // Excellent (14+ headlines and Unique descriptions)
                if (strength === "Good" && this.state.group.headlines.filter((item) => { return item.text !== "" }).length > 13 && this.state.group.bodies.filter((value, index, self) => {
                    return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                }).length > 1) {
                    strength = "Excellent";
                }

            }
            return strength;
        },
        promt_text: () => {
            return "Write a best possible ad copy for my upcoming Google ads campaign that needs to include: [up to 15 unique headlines and 4 unique descriptions] with up to:[30 characters for headlines, and up to 90 characters for desscription including letters, numbers, emojis, spaces, and punctuation marks]. Do not use  exclamation mark or emojies in headlines. Write the headlines and description ad copy based on the following text:[" + this.state.searchOpenAi + "]. The ad copy needs to be written in the language:[" + this.state.language.name + "].  \n\nPlease provide ad copy suggestions in the following JSON structure starting and ending with ***:\n\n    {\n      \"Headline\": \n        [\"headline value 1\", \n          \"headline value 2\",\n         // Repeat this structure to include between 8-13 more headlines ],\n      \"Description\": \n      [\"description value 1\", \n       \"description value 2\"\n        // Repeat this structure until you have provided four descriptions]\n    }";
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }
        return (
            <div className="flex flex-1">
                <div className="flex flex-row">
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="bg-blue-100 flex flex-row rounded-md mb-2 p-2 sticky top-0 z-60">
                            <div className="flex flex-1 flex-col justify-center">
                                <div className="flex flex-row justify-center items-center pr-2">
                                    <div className="font-medium text-sm mr-2">Ad Strength - </div>
                                    <div className="text-sm">{this.renders.adStrength()}</div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className="flex-row  items-center mb-2 flex mr-2">
                                        <div className={((Array.isArray(this.state.group.headlines) && this.state.group.headlines.length > 8) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Add more headlines</div>
                                    </div>
                                    <div className="flex-row flex items-center mb-2">
                                        <div className={(this.state.keyword_list && (Array.isArray(this.state.keyword_list.keywords) && this.state.keyword_list.keywords.filter((item) => { return item.inside_headline }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Include popular keywords in your headlines</div>
                                    </div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className="flex-row flex items-center mr-2">
                                        <div className={((Array.isArray(this.state.group.headlines) && this.state.group.headlines.filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 4) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your headlines more unique</div>
                                    </div>
                                    <div className="flex-row flex items-center">
                                        <div className={((Array.isArray(this.state.group.bodies) && this.state.group.bodies.filter((value, index, self) => {
                                            return value.text !== "" && self.findIndex(v => v.text === value.text) === index;
                                        }).length > 1) ? "bg-blue-600" : "bg-white ") + " text-white h-4 w-4 rounded-full border border-blue-600 flex justify-center items-center"}>
                                            <CheckIcon className="h-3 w-3" />
                                        </div>
                                        <div className="text-xs ml-2">Make your descriptions more unique</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col">
                            <div className="grid grid-cols-12 gap-4 mb-4 w-full ">
                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Keyword list"}
                                        searchInput={true}
                                        error={this.state.keyword_list ? false : true}
                                        selected={this.state.keyword_list ? this.state.keyword_list : { name: "Select list" }}
                                        options={this.state.keywordLists}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                keyword_list: value
                                            });
                                            this.functions.insideHeadlines();
                                            this.functions.insideDescriptions();
                                        }}
                                    />
                                </div>
                                <div className="col-span-12">
                                    <InputTailwind
                                        error={this.state.group.name === "" ? true : false}
                                        label={"Name"}
                                        value={this.state.group.name}
                                        onChange={async (value) => {
                                            this.state.group.name = value;
                                            this.setState({
                                                group: this.state.group
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    <div className="col-span-8">
                                        <InputTailwind
                                            label={"Website"}
                                            error={this.state.group.link === "" || !this.state.group.link || !this.state.group.link.includes("https://") ? true : false}
                                            value={this.state.group.link}
                                            onChange={async (value) => {
                                                this.state.group.link = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Path 1"}
                                            value={this.state.group.path1}
                                            onChange={async (value) => {
                                                this.state.group.path1 = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    <div className="col-span-2">
                                        <InputTailwind
                                            label={"Path 2"}
                                            value={this.state.group.path2}
                                            onChange={async (value) => {
                                                this.state.group.path2 = value;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }}
                                            onBlur={() => {
                                                this.functions.combinations();
                                            }}
                                        />
                                    </div>
                                }
                                <div className="text-sm font-medium col-span-12">
                                    Headlines
                                </div>
                                {
                                    this.state.group.format && this.state.group.format.id === 1 && Array.isArray(this.state.group.headlines) &&
                                    this.state.group.headlines.map((item, index) => {
                                        return (
                                            <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-12"
                                            }>
                                                <div className="grid grid-cols-12 col-gap-4">
                                                    <div className="col-span-9 relative">
                                                        <InputTailwind
                                                            error={(item.text.length > 30 || item.text.includes("!") || this.state.group.headlines.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).includes(item.text)) || (item.text === "" && searchGoogle.validate(this.state.group).headlines) ? true : false}
                                                            value={item.text}
                                                            label={(index + 1) + " Headline (" + item.text.replace("{keyword}", "").length + "/30) " + (item.text.indexOf("{keyword}") !== -1 ? "OBS! Keyword + Headline cant be more than 30 characters" : "") + (item.text !== "" && this.state.group.headlines.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).includes(item.text)? " OBS! Duplicated headline" : "") + (item.text.includes("!") ? " OBS! Exclamation mark not allowed" : "")}
                                                            onChange={async (value) => {
                                                                item.text = value;
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            maxLength={30}
                                                            insertion={true}
                                                            keywordInsertion={item.keywordInsertion ? item.keywordInsertion : false}
                                                            onKeywordInsertionClick={async () => {
                                                                this.state.group.headlines = this.state.group.headlines.filter((headline) => {
                                                                    if (headline.id === item.id) {
                                                                        if ("keywordInsertion" in headline) {
                                                                            headline.keywordInsertion = !headline.keywordInsertion;
                                                                        } else {
                                                                            headline.keywordInsertion = true;
                                                                        }
                                                                    }
                                                                    return headline;
                                                                });
                                                                await this.promisedSetState({
                                                                    group: this.state.group
                                                                });
                                                            }}
                                                            leftSection={item.keywordInsertion}
                                                            leftSectionLabel={"{keyword}"}
                                                            rightLabel={this.state.group.headlines.length > 3}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.headlines = this.state.group.headlines.filter((headline) => { return headline.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            onBlur={() => {
                                                                this.functions.combinations();
                                                                this.functions.insideHeadlines();
                                                                //this.functions.insideDescriptions();
                                                            }}
                                                        />
                                                        {
                                                            this.state.group.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.includes(item.text) }).length > 0 &&
                                                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-span-3">
                                                        <DropdownTailwind
                                                            searchInput={false}
                                                            label={"Pin"}
                                                            selected={item.pinned ? item.pinned : { id: 1, name: "Auto", value: "UNSPECIFIED" }}
                                                            options={[
                                                                { id: 1, name: "Auto", value: "UNSPECIFIED" },
                                                                { id: 2, name: "1st", value: "HEADLINE_1" },
                                                                { id: 3, name: "2nd", value: "HEADLINE_2" },
                                                                { id: 4, name: "3rd", value: "HEADLINE_3" }
                                                            ]}
                                                            onChange={async (value) => {
                                                                item.pinned = value;
                                                                await this.promisedSetState({
                                                                    group: this.state.group
                                                                });
                                                                this.functions.combinations();
                                                            }}

                                                        />
                                                    </div>
                                                    {
                                                        typeof item.text === "string" && item.text.indexOf("{keyword}") !== -1 &&
                                                        <div className="col-span-12 mt-2">
                                                            <InputTailwind
                                                                value={item.keyword_fallback}
                                                                label={"Keyword fallback (" + (typeof item.keyword_fallback === "string" ? item.keyword_fallback.length : 0) + "/" + (30 - item.text.replace("{keyword}", "").length) + ")"}
                                                                onChange={async (value) => {
                                                                    item.keyword_fallback = value;
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                                maxLength={(30 - (item.text.replace("{keyword}", "").length))}
                                                                onBlur={() => {
                                                                    //this.functions.combinations();
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.group.format && this.state.group.format.id === 1 && Array.isArray(this.state.group.headlines) && this.state.group.headlines.length < 15 &&
                                    <div className="col-span-12">
                                        <div className="inline-flex">
                                            <div onClick={() => {
                                                this.state.group.headlines.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                <PlusIcon className="h-5 w-5 mr-1" /> Add new headline
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="text-sm font-medium col-span-12">
                                    Descriptions
                                </div>
                                {
                                    this.state.group.format && this.state.group.format.id === 1 && Array.isArray(this.state.group.bodies) &&
                                    this.state.group.bodies.map((item, index) => {
                                        return (
                                            <div className={(index !== 0 ? "border-t pt-4" : "") + " col-span-12"}>
                                                <div className="grid grid-cols-12 col-gap-4">
                                                    <div className="col-span-9 relative">
                                                        <TextAreaTailwind
                                                            error={(item.text.length > 90 || this.state.group.bodies.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).includes(item.text)) || (item.text === "" && searchGoogle.validate(this.state.group).bodies) ? true : false}
                                                            value={item.text}
                                                            label={(index + 1) + " Descripton (" + item.text.replace("{keyword}", "").length + "/90) " + (item.text.indexOf("{keyword}") !== -1 ? "OBS! Keyword + Description cant be more than 90 characters" : "") + (item.text !== "" && this.state.group.bodies.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).includes(item.text)? " OBS! Duplicated description" : "")}
                                                            onChange={async (value) => {
                                                                item.text = value;
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            lookLikeInput={true}
                                                            maxLength={90}
                                                            rightLabel={this.state.group.bodies.length > 2}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.bodies = this.state.group.bodies.filter((body) => { return body.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            onBlur={() => {
                                                                this.functions.combinations();
                                                                //this.functions.insideHeadlines();
                                                                this.functions.insideDescriptions();
                                                            }}
                                                        />
                                                        {
                                                            this.state.group.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.includes(item.text) }).length > 0 &&
                                                            <div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
                                                                <SparklesIcon className="h-5 w-5 text-purple-500" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-span-3">
                                                        <DropdownTailwind
                                                            searchInput={false}
                                                            label={"Pin"}
                                                            selected={item.pinned ? item.pinned : { id: 1, name: "Auto", value: "UNSPECIFIED" }}
                                                            options={[
                                                                { id: 1, name: "Auto", value: "UNSPECIFIED" },
                                                                { id: 2, name: "1st", value: "DESCRIPTION_1" },
                                                                { id: 3, name: "2nd", value: "DESCRIPTION_2" }
                                                            ]}
                                                            onChange={async (value) => {
                                                                item.pinned = value;
                                                                await this.promisedSetState({
                                                                    group: this.state.group
                                                                });
                                                                this.functions.combinations();
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        typeof item.text === "string" && item.text.indexOf("{keyword}") !== -1 &&
                                                        <div className="col-span-12 mt-2">
                                                            <InputTailwind
                                                                value={item.keyword_fallback}
                                                                label={"Keyword fallback (" + (typeof item.keyword_fallback === "string" ? item.keyword_fallback.length : 0) + "/" + (90 - item.text.replace("{keyword}", "").length) + ")"}
                                                                onChange={async (value) => {
                                                                    item.keyword_fallback = value;
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                                maxLength={(90 - (item.text.replace("{keyword}", "").length))}
                                                                onBlur={() => {
                                                                    //this.functions.combinations();
                                                                }}
                                                            />
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.group.format && this.state.group.format.id === 1 && Array.isArray(this.state.group.bodies) && this.state.group.bodies.length < 4 &&
                                    <div className="col-span-12">
                                        <div className="inline-flex">
                                            <div onClick={() => {
                                                this.state.group.bodies.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                <PlusIcon className="h-5 w-5 mr-1" /> Add new description
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default CreateOrderCreativeSearchAd;
