import { ChevronLeftIcon, DotsVerticalIcon, PlayIcon, StarIcon, TrashIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

class PreviewGooglePerformanceMaxGmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: {},
            videos: [],
            images: [],
            headlines: [],
            descriptions: [],
            long_headlines: [],
            business: "",
            website: "",
            cta: {}
        }
    };

    componentDidMount() {
        this.setState({
            videos: this.props.videos,
            images: this.props.images,
            headlines: this.props.headlines,
            descriptions: this.props.descriptions,
            long_headlines: this.props.long_headlines,
            business: this.props.business,
            website: this.props.website,
            cta: this.props.cta
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            videos: nextProps.videos,
            images: nextProps.images,
            headlines: nextProps.headlines,
            descriptions: nextProps.descriptions,
            long_headlines: nextProps.long_headlines,
            business: nextProps.business,
            website: nextProps.website,
            cta: nextProps.cta
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + (chars > 30 ? " ..." : "");
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "400px" }} className={"w-full border rounded-md p-4 bg-white"}>
                <div className="flex flex-row items-center mb-4">
                    <ChevronLeftIcon className="text-gray-700 w-8 h-8" />
                    <div className="flex flex-1"></div>
                    <div className="border border-green-800 p-1 text-xs text-green-800 rounded-md">
                        Ad
                    </div>
                    <StarIcon className="w-5 h-5 text-gray-700 ml-5" />
                    <TrashIcon className="w-5 h-5 text-gray-700 ml-5" />
                    <DotsVerticalIcon className="w-5 h-5 text-gray-700 ml-5" />
                </div>
                <div className="text-md font-medium w-full mb-4">
                    {Array.isArray(this.state.headlines) && this.state.headlines.length > 0 ? this.state.headlines[0].text : "Headline"}
                </div>
                <div className="flex flex-row mb-4">
                    <div className="w-8 h-8 bg-gray-700 mr-2 mt-1">
                        {
                            Array.isArray(this.state.images) &&
                            this.state.images.filter((item) => {
                                return item.type === "logo_square"
                            }).map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <img className="w-full h-full" src={item.url} />
                                    )
                                } else {
                                    return ("");
                                }
                            })
                        }
                    </div>
                    <div className="">
                        <div className="text-sm font-semibold">
                            {this.state.business !== "" ? this.state.business : "Business"}
                        </div>
                        <div className="text-xs text-gray2-400">to me</div>
                    </div>
                    <div className="flex flex-1"></div>
                </div>
                <div className="w-full bg-gray2-700">
                    {
                        Array.isArray(this.state.images) &&
                        this.state.images.filter((item) => {
                            return item.type === "square"
                        }).map((item, index) => {
                            if (index === 0) {
                                return (
                                    <img className="w-full" src={item.url} />
                                )
                            } else {
                                return ("");
                            }
                        })
                    }
                </div>
                <div className="p-4">
                    <div className="shadow p-4">
                        <div className="text-sm font-semibold">
                            {Array.isArray(this.state.headlines) && this.state.headlines.length > 0 ? this.state.headlines[0].text : "Headline"}
                        </div>
                        <div className="text-xs text-gray2-500 mb-2">
                            {Array.isArray(this.state.descriptions) && this.state.descriptions.length > 0 ? this.state.descriptions[0].text : "Description"}
                        </div>
                        <div className="w-full bg-blue-700 rounded-sm h-6 flex justify-center items-center text-xxs text-white">
                            {this.state.cta ? this.state.cta.name : "Learn More"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewGooglePerformanceMaxGmail;
