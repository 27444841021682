import React, { Component, Fragment } from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
    Circle,
    Polygon,
    FaAnchor
} from "react-google-maps";
import * as turf from '@turf/turf'

const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

class AdvertisingLocationMapRegions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            center: this.props.center,
            zoom: this.props.zoom,
            places: [],
            languages: [],
            place: {},
            level: {},
            negative_places: [],
            selected: []
        };
        this.renders.radius = this.renders.radius.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            center: this.state.center !== nextProps.center ? nextProps.center : this.state.center,
            zoom: this.state.zoom !== nextProps.zoom ? nextProps.zoom : this.state.zoom,
            places: nextProps.places ? nextProps.places : [],
            selected: nextProps.selected ? nextProps.selected : [],
            languages: nextProps.languages,
            level: nextProps.level ? nextProps.level : "",
            place: nextProps.place,
            update: nextProps.update
        }, () => {
            if (this.state.update) {
                this.functions.init();
            }
        });
    }

    componentDidMount() {
        this.setState({
            center: this.props.center,
            zoom: this.props.zoom,
            place: this.props.place,
            level: this.props.level ? this.props.level : "",
            places: this.props.places ? this.props.places : [],
            selected: this.props.selected ? this.props.selected : [],
            languages: this.props.languages
        }, () => {
            this.functions.init();
        });

    }

    functions = {
        init: () => {
            if (this.state.place.name) {
                if (this.state.place.custom) {
                    try {
                        var circle = new window.google.maps.Circle({
                            center: new window.google.maps.LatLng(this.state.place.lat, this.state.place.lng),
                            radius: this.state.place.radius
                        });
                        var bounds = new window.google.maps.LatLngBounds();
                        bounds.union(circle.getBounds());
                        this.map.fitBounds(bounds);
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    var bounds = new window.google.maps.LatLngBounds();
                    window.google.maps.Polygon.prototype.getBounds = function () {
                        var bounds = new window.google.maps.LatLngBounds();
                        var paths = this.getPaths();
                        for (var i = 0; i < paths.getLength(); i++) {
                            var path = paths.getAt(i);
                            for (var j = 0; j < path.getLength(); j++) {
                                bounds.extend(path.getAt(j));
                            }
                        }
                        return bounds;
                    };
                    if (this.state.place.lat && this.state.place.lng && !this.state.place.paths) {
                        this.state.place.paths = [];
                        this.state.place.paths.push([{ lat: this.state.place.lat, lng: this.state.place.lng }])
                    }
                    if (this.state.place.paths) {
                        for (let i = 0; i < this.state.place.paths.length; i++) {
                            var polygon = new window.google.maps.Polygon({
                                paths: this.state.place.paths[i]
                            });
                            bounds.union(polygon.getBounds());
                        }
                        this.map.fitBounds(bounds);
                    }
                }
                this.props.onUpdate(false);
            }
        },
        onCircleDragEnd: (coordinate) => {
            // this.state.place.lat = coordinate.lat();
            // this.state.place.lng = coordinate.lng();
            // this.setState({
            //     place: this.state.place
            // })
        }
    };

    renders = {
        center: () => {
            try {
                let place = this.state.places.filter((item) => {
                    return item.hover
                })[0];
                let array = [];
                place.paths.map((item) => {
                    let inner_array = [];
                    item.map((inner_item) => {
                        let coordinates_array = [inner_item.lat, inner_item.lng];
                        inner_array.push(coordinates_array);
                    });
                    array.push(inner_array);
                });
                var polygon = turf.polygon(array);
                var center = turf.centerOfMass(polygon);
                return { lat: center.geometry.coordinates[0], lng: center.geometry.coordinates[1] };
            } catch (e) {
                return null
            }
        },
        selected: (item) => {
            try {
                return this.state.selected.filter((inner_item) => {
                    return item.id === inner_item.id
                }).length > 0
            } catch (e) {
                return false
            }
        },
        excluded: (item) => {
            try {
                return this.state.selected.filter((inner_item) => {
                    return item.id === inner_item.id && !inner_item.include
                }).length > 0
            } catch (e) {
                return false
            }
        },
        places: () => {
            try {
                let places = [];
                places = this.state.places.concat(this.state.selected.filter((item) => {
                    return item.custom
                }));
                return places;
            } catch (e) {
                return []
            }
        },
        radius: (place) => {
            if (this[`${place.id}_ref`]) {
                try {
                    let center = this[`${place.id}_ref`].getCenter();
                    place.lat = center.lat();
                    place.lng = center.lng();
                } catch (e) {
                }
                let radius = Math.round(this[`${place.id}_ref`].getRadius());
                /*
                if (radius < 25000) {
                    place.radius = 25001;
                } else {
                    place.radius = radius
                }
                */
                place.radius = radius;
                this.state.place = place;
                for (let i = 0; i < this.state.selected.length; i++) {
                    if (this.state.selected[i].id === place.id) {
                        this.state.selected[i] = place;
                    }
                }
                this.setState({
                    place: place,
                    selected: this.state.selected
                }, () => {
                    /*
                    if (radius < 25000) {
                        place.radius = 25000;
                    }
                    */
                    this.state.place = place;
                    for (let i = 0; i < this.state.selected.length; i++) {
                        if (this.state.selected[i].id === place.id) {
                            this.state.selected[i] = place;
                        }
                    }
                    this.setState({
                        place: place,
                        selected: this.state.selected
                    }, () => {
                        this.props.onUpdateSelected(this.state.selected, this.state.place);
                        this.functions.init();
                    });
                })
            }
        },
        zIndex: (item) => {
            try {
                if (item.level === '4') {
                    return 1
                } else if (item.level === '3') {
                    return 2
                } else if (item.level === '2') {
                    return 3
                } else if (item.level === '1') {
                    return 4
                } else {
                    return 1
                }
            } catch (e) {
                return 1
            }
        },
        color: (item) => {
            if (item.campaign_edit) {
                return "#7f9cf5"
            } else {
                if (this.renders.excluded(item)) {
                    return "#D35B0B"
                } else if (!this.renders.excluded(item) && !item.intersection && item.include) {
                    return "#1AD5BD"
                } else {
                    return "#FC5353"
                }
            }
        }
    };

    render() {
        const defaultMapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };
        return (
            <GoogleMap
                defaultOptions={defaultMapOptions}
                ref={(ref) => {
                    this.map = ref;
                }}
            >
                {
                    this.state.selected.map((place) => {
                        if (place.custom) {
                            return (
                                <Fragment key={place.id}>
                                    <Circle
                                        ref={(ref) => this[`${place.id}_ref`] = ref}
                                        zIndex={1}
                                        draggable={true}
                                        editable={true}
                                        onDragEnd={() => {
                                            this.renders.radius(place);
                                        }}
                                        onRadiusChanged={() => {
                                            this.renders.radius(place);
                                        }}
                                        onCenterChanged={() => {
                                            this.renders.radius(place);
                                        }}
                                        defaultCenter={{
                                            lat: parseFloat(place.lat),
                                            lng: parseFloat(place.lng)
                                        }}
                                        onClick={() => {
                                            this.renders.radius(place);
                                        }}
                                        radius={place.radius}
                                        options={{
                                            strokeWeight: "2",
                                            strokeColor: this.renders.color(place),
                                            fillColor: this.renders.color(place)
                                        }}
                                        onCenterChanged={() => {
                                            this.renders.radius(place);
                                        }}
                                    />
                                </Fragment>
                            )
                        } else {
                            return (
                                <Fragment key={place.id}>
                                    <Polygon
                                        paths={place.paths}
                                        options={{
                                            zIndex: this.renders.zIndex(place),
                                            strokeWeight: "2",
                                            fillOpacity: '0.5',
                                            strokeColor: place.include ? "#1AD5BD" : "#FC5353",
                                            fillColor: place.include ? "#1AD5BD" : "#FC5353"
                                        }} />
                                </Fragment>
                            )
                        }
                    })
                }
            </GoogleMap>
        );
    }

}

export default withGoogleMap(AdvertisingLocationMapRegions);