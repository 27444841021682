import React, { Component, createRef } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import * as turf from '@turf/turf'
import AdvertisingLocationLanguage from "../advertising-location-language";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import { calls } from "./calls";
import AdvertisingLocationRegions from "../advertising-location-regions";
import AdvertisingLocationCustom from "../advertising-location-custom";
import AdvertisingLocationCountry from "../advertising-location-country";
import AdvertisingLocationMapRegions from "../advertising-location-map-regions";
import SweetAlert from "sweetalert-react";
import AdvertisingLocationPlatform from "../advertising-location-platform";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconLinkedin from "../icons/linkedin";
import CountriesWithCoordinates from "../../assets/json/countries_with_coordinates";
import { SlideDown } from "react-slidedown";
import IconTwitter from "../icons/twitter";

class AdvertisingLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            countries: [],
            locations: [],
            regions: [],
            languages: [],
            loading_countries: false,
            countries_selected: [],
            countries_search: "",
            loading_locations: false,
            loading_regions: false,
            region_search: "",
            locations_suggestions: [],
            locations_selected: [],
            locations_search: "",
            channels: {},
            center: { lat: 40.64, lng: -73.96 },
            zoom: 6,
            location: {},
            keywords: {},
            client: {},
            country: {},
            level: { name: "Country", value: '4' },
            search: "",
            error: false,
            height: '40vh',
            selected_locations: [],
            custom_location: false,
            tab: { id: 2, name: "Individual" },
            loaded: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            step: nextProps.step
        });
        if (nextProps.step === 'locations' && !this.state.loaded) {
            this.init.first();
        } else {
            this.init.data();
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();

            //CHECK IF CHANNELS OBJECT EXIST
            if (information && information.locations && information.locations.value) {
                information.locations.value = information.locations.value.map((item) => {
                    if (!item.channels) {
                        item.channels = {
                            facebook: { keep: true },
                            google: { keep: true },
                        };
                    }
                    return item;
                })
            }

            this.setState({
                selected_locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                loaded: true
            }, () => {
                if (Array.isArray(this.state.selected_locations) && this.state.selected_locations.length > 0) {
                    if (this.state.selected_locations.filter((item) => {
                        return item.custom && !item.negative
                    }).length > 0) {
                        this.setState({
                            tab: { id: 3, name: "Geo location" }
                        }, () => {
                            this.functions.locate(this.state.selected_locations[0]);
                        });
                    } else {

                        this.setState({
                            tab: { id: 2, name: "Individual" }
                        });
                    }
                }
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {}
            })
        }
    };

    functions = {
        locations: (channel) => {

        },
        paths: (item) => {
            if (!item.paths) {
                this.setState({
                    loading_regions: true
                }, () => {
                    calls.getRegion({ region: item.id }).then((response) => {
                        this.state.selected_locations = this.state.selected_locations.map((region) => {
                            if (region.id === item.id) {
                                region.paths = response.data.paths;
                            }
                            return region
                        });
                        this.setState({
                            location: item,
                            update: true,
                            selected_locations: this.state.selected_locations,
                            loading_regions: false
                        }, () => {
                            this.functions.save('locations');
                        })
                    }, (error) => {
                        this.setState({
                            loading_regions: false
                        })
                    });
                });
            }
        },
        region: (item) => {
            this.setState({
                loading_regions: true
            }, () => {
                item.include = true;
                this.state.selected_locations.unshift(item);
                this.setState({
                    location: item,
                    update: true,
                    selected_locations: this.state.selected_locations,
                    loading_regions: false
                }, () => {
                    this.functions.save('locations');
                    try {
                        this.props.onChange();
                    } catch (e) {
                    }
                });

                /*
                calls.getRegion({region: item.id}).then((response) => {
                    console.log(response);
                }, (error) => {
                    this.setState({
                        loading_regions: false
                    })
                });
                */

            });
        },
        add: (value) => {

        },
        remove: (value) => {
            if (value.custom) {
                let new_array = JSON.parse(JSON.stringify(this.state.selected_locations));
                this.setState({
                    selected_locations: new_array.filter((item) => {
                        return item.id !== value.id
                    })
                }, () => {
                    if (this.state.selected_locations.length > 0) {
                        this.setState({
                            location: this.state.selected_locations[0]
                        })
                    } else {
                        this.setState({
                            location: {}
                        })
                    }
                    this.functions.save('locations');
                    this.functions.checkIntersections();
                });
            } else {
                this.state.selected_locations = this.state.selected_locations.filter((item) => {
                    return item.id !== value.id
                });
                this.setState({
                    selected_locations: this.state.selected_locations
                }, () => {
                    if (this.state.selected_locations.length > 0) {
                        this.functions.locate(this.state.selected_locations[0]);
                    }
                    this.functions.save('locations');
                    try {
                        this.props.onChange();
                    } catch (e) {

                    }
                })
            }
        },
        locate: (value) => {
            CountriesWithCoordinates.map((item) => {
                if (item.country_code === value.country_code) {
                    value.lat = item.latlng[0];
                    value.lng = item.latlng[1];
                }
            });
            this.setState({
                location: value,
                update: true
            });
        },
        next: () => {
            if (this.renders.error().length > 0) {
                this.setState({
                    error: true,
                    next: true
                })
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            if (key === 'locations') {
                let locations = JSON.parse(JSON.stringify(this.state.selected_locations));
                locations = locations.map((item) => {
                    delete item.paths;
                    return item;
                });
                information[key].value = locations;
            } else {
                information[key].value = this.state[key];
            }
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        checkIntersections: () => {
            let locations = this.state.selected_locations;
            for (let i = 0; i < locations.length; i++) {
                this.functions.negatives(this.state.selected_locations, locations[i], this.state.location);
            }
        },
        negatives: (locations, location, default_location) => {
            try {
                let negative_polygons = [];
                if (location) {
                    if (location.custom) {
                        var options = { steps: 100, units: 'kilometers' };
                        var place_circle = turf.circle([location.lng, location.lat], location.radius / 1000, options);
                        let intersection_circle = false;
                        for (let i = 0; i < locations.length; i++) {
                            if (locations[i].custom && locations[i].id !== location.id && locations[i].include && Object.keys(location.channels).filter((channel) => {
                                return !location.channels[channel].disabled
                            }).filter(function (val) {
                                return Object.keys(locations[i].channels).filter((channel) => {
                                    return !locations[i].channels[channel].disabled
                                }).indexOf(val) != -1;
                            }).length > 0) {
                                var circle = turf.circle([locations[i].lng, locations[i].lat], locations[i].radius / 1000, options);
                                intersection_circle = turf.intersect(place_circle, circle);
                                if (intersection_circle) {
                                    break;
                                }
                            }
                        }
                        locations.intersection = intersection_circle;
                        for (let i = 0; i < locations.length; i++) {
                            if (location.id === locations[i].id && Object.keys(location.channels).filter((channel) => {
                                return !location.channels[channel].disabled
                            }).filter(function (val) {
                                return Object.keys(locations[i].channels).filter((channel) => {
                                    return !locations[i].channels[channel].disabled
                                }).indexOf(val) != -1;
                            }).length > 0) {
                                locations[i].intersection = intersection_circle;
                            }
                        }
                        this.setState({
                            selected_locations: locations,
                            location: default_location ? default_location : location,
                            update: true
                        }, () => {
                            this.functions.save('locations');
                            try {
                                this.props.onChange();
                            } catch (e) { }
                        })
                    }
                }
            } catch (e) {

            }
        }
    };

    renders = {
        error: () => {
            let errors = [];
            if (this.state.selected_locations.filter((item) => {
                return !item.negative && item.include
            }).length < 1) {
                errors.push({ title: "Add minimum one location", type: 'all', section: 'location' });
            }
            if (this.state.selected_locations.filter((item) => {
                return item.intersection && item.include
            }).length > 0) {
                errors.push({
                    title: "Update locations which intersect",
                    type: 'custom',
                    section: 'location'
                });
            }
            this.renders.channels().map((channel) => {
                let inner_key = channel.value;
                if (this.state.selected_locations.filter((item) => {
                    return item.channels[inner_key] && !item.channels[inner_key].disabled;
                }).length < 1) {
                    errors.push({
                        title: inner_key + " locations is missing",
                        type: 'platform',
                        section: 'locations'
                    });
                };
                if (this.state.selected_locations.filter((item) => {
                    return item.channels[inner_key] && item.include && !item.channels[inner_key].disabled
                }).length < 1) {
                    errors.push({
                        title: "Include minimum one " + inner_key + " location",
                        type: 'platform',
                        section: 'locations'
                    });
                };
            });
            return errors;
        },
        languages: () => {
            try {
                return this.state.languages ? this.state.languages : [];
            } catch (e) {
                return []
            }
        },
        tabs: () => {
            return [{ id: 1, name: 'Internal regions' }, { id: 2, name: 'Custom regions' }]
        },
        channels: () => {
            let channels = [];
            if (this.state.channels.facebook_instagram || (this.state.channels.facebook && this.state.channels.instagram)) {
                channels.push({ title: "Facebook", value: "facebook" });
            } else if (!this.state.channels.facebook_instagram && this.state.channels.facebook) {
                channels.push({ title: "Facebook", valiue: "facebook" });
            } else if (!this.state.channels.facebook_instagram && this.state.channels.instagram) {
                channels.push({ title: "Instagram", value: "facebook" });
            }
            if (this.state.channels.google) {
                channels.push({ title: "Google", value: "google" });
            }
            if (this.state.channels.google_shopping) {
                channels.push({ title: "Google Shopping", value: "google_shopping" });
            }
            if (this.state.channels.linkedin) {
                channels.push({ title: "Linkedin", value: "linkedin" });
            }
            if (this.state.channels.twitter) {
                channels.push({ title: "Twitter", value: "twitter" });
            }
            return channels;
        },
        nextStep: () => {
            let step = "";
            if (this.state.channels.facebook || this.state.channels.instagram || this.state.channels.linkedin || this.state.channels.twitter) {
                step = 'Creative overview';
            } else if (this.state.channels.google) {
                step = 'Search';
            }else{
                step = 'Creative overview';
            }
            return step;
        }
    };

    render() {
        return (
            <div className="h-full flex flex-1 flex-col pb-5">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.error().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 3: Locations
                    </div>
                </div>
                <SweetAlert
                    show={this.state.warning_modal}
                    title="This region is already selected"
                    type="warning"
                    text={"Click OK to continue"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ warning_modal: false });
                    }}
                />
                <div>
                    <div className="rounded-md bg-white p-4 pt-0 relative">
                        {
                            <nav role="navigation" aria-label="Social sub navigation">
                                <ul className="flex flex-row items-end">
                                    {[
                                        //{id: 1, name: "Basic"},
                                        { id: 2, name: "Individual" },
                                        { id: 3, name: "Geo location" }
                                    ].map((item, j) => {
                                        return (
                                            <li key={j} className={(((item.id === 1 && this.state.selected_locations.filter((item) => {
                                                return item.custom
                                            }).length > 0)) ? "cursor-not-allowed" : "cursor-pointer") + " font-bold flex flex-row text-xs py-4 px-4 border-b-2 " + (this.state.tab.id == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                onClick={() => {
                                                    if (((item.id === 1 && this.state.selected_locations.filter((item) => {
                                                        return item.custom
                                                    }).length > 0))) {

                                                    } else {
                                                        this.setState({ tab: item });
                                                    }
                                                }}>
                                                {item.name}
                                            </li>
                                        )
                                    })
                                    }
                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                </ul>
                            </nav>
                        }
                        {
                            <div>
                                {
                                    this.state.tab.id === 1 &&
                                    <div className="flex flex-1 flex-row mt-4">
                                        <div className="flex-1 flex">
                                            <AdvertisingLocationCountry
                                                countries={this.state.countries}
                                                country={this.state.country}
                                                channels={this.renders.channels().filter((item) => {
                                                    return item.value !== "linkedin"
                                                }).map((item) => {
                                                    return item.value
                                                })}
                                                loading={this.state.loading_countries}
                                                selected={this.state.selected_locations}
                                                icons={true}
                                                title={"Defined locations"}
                                                size={'full'}
                                                noTitle={true}
                                                onRegion={(value) => {
                                                    this.functions.region(value);
                                                }}
                                                showAll={() => {
                                                    this.setState({
                                                        tab: { id: 2 }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.id === 2 &&
                                    <div className="flex flex-1 flex-col">
                                        {
                                            this.renders.channels().filter((channel) => {
                                                return true
                                            }).map((channel) => {
                                                return (
                                                    <div className="flex flex-1 flex-row mt-4 box-border">
                                                        <div className="flex flex-1">
                                                            <AdvertisingLocationPlatform
                                                                channel={channel.value}
                                                                channels={this.state.channels}
                                                                disable={this.state.selected_locations.filter((item) => {
                                                                    return item.custom && item.channels[channel.value]
                                                                }).length > 0}
                                                                title={channel.title + " locations"}
                                                                client={this.state.client}
                                                                onRegion={(value) => {
                                                                    this.functions.region(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {
                                    this.state.tab.id === 3 &&
                                    <div className="flex flex-1 flex-col mt-4">
                                        <div className="flex flex-1">
                                            <AdvertisingLocationCustom
                                                languages={this.state.languages}
                                                country={this.state.country}
                                                countries={this.state.countries_selected}
                                                selected={this.state.selected_locations}
                                                channels={this.renders.channels().map((item) => {
                                                    return item.value
                                                })}
                                                title={"Search location"}
                                                size={'full'}
                                                noTitle={true}
                                                onChange={(value) => {
                                                    //console.log(value);
                                                    value.location_group = {
                                                        name: 'Group 1',
                                                        value: 1
                                                    };
                                                    this.state.selected_locations.unshift(value);
                                                    this.setState({
                                                        selected_locations: this.state.selected_locations,
                                                        update: true,
                                                        location: value
                                                    }, () => {
                                                        this.functions.checkIntersections();
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.selected_locations.filter((item) => {
                                return !item.negative
                            }).length > 0 &&
                            <div className="flex flex-row mt-4">
                                {
                                    <div className="rounded-md bg-gray-100 flex-1 flex-col flex box-border">
                                        <div className="flex flex-1 p-4 pb-2 box-border flex-col">
                                            <div className="mb-4">
                                                <h3 className="mb-0 font-bold">
                                                    Selected regions
                                                </h3>
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    You have selected <span
                                                        className="font-bold ml-1 mr-1">{this.state.selected_locations.filter((item) => {
                                                            return !item.negative && item.channels
                                                        }).length > 0}</span> locations
                                                </p>
                                            </div>
                                            {
                                                <div className="w-full flex-col">
                                                    <div className="flex-col flex flex-1">
                                                        <div className="flex-col flex flex-1">
                                                            {
                                                                this.state.selected_locations.filter((item) => {
                                                                    return !item.negative && item.channels
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <div onClick={() => {
                                                                            this.functions.locate(item);
                                                                        }} className={cx("flex flex-col flex-1 items-center mb-2 bg-white pl-6 px-4 py-3 pb-0 rounded-md cursor-pointer")}>
                                                                            <div className="flex flex-row flex-1 w-full pb-3">
                                                                                <div className="flex flex-row items-center">
                                                                                    {item.channels.google && this.state.channels.google && !item.channels.google.disabled &&
                                                                                        <div
                                                                                            className="px-2 py-2 border-2 border-white -ml-3 h-8 w-8 rounded-full mr-1 flex items-center justify-center bg-google-500">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/google_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {item.channels.google_shopping && this.state.channels.google_shopping && !item.channels.google_shopping.disabled &&
                                                                                        <div
                                                                                            className="px-2 py-2 border-2 border-white -ml-3 h-8 w-8 rounded-full mr-1 flex items-center justify-center bg-googleshopping-500">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/google-shopping.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {item.channels.linkedin && this.state.channels.linkedin && !item.channels.linkedin.disabled &&
                                                                                        <div
                                                                                            className="px-2 py-2 h-8 w-8 border-2 -ml-3 border-white rounded-full mr-1 flex items-center justify-center bg-linkedin-500">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/linkedin_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {item.channels.twitter && this.state.channels.twitter && !item.channels.twitter.disabled &&
                                                                                        <div
                                                                                            className="px-2 py-2 h-8 w-8 border-2 -ml-3 border-white rounded-full mr-1 flex items-center justify-center bg-twitter-500">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/twitter_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {item.channels.facebook && this.state.channels.facebook && !item.channels.facebook.disabled &&
                                                                                        <div className="px-2 py-2 h-8 w-8 border-2 -ml-3 border-white rounded-full mr-1 flex items-center justify-center bg-facebook-500">
                                                                                            <img style={{ width: "6px" }}
                                                                                                src={require('../../assets/images/facebook_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {item.channels.facebook && this.state.channels.facebook && !item.channels.facebook.disabled &&
                                                                                        <div className="px-2 border-2 border-white -ml-3 py-2 h-8 w-8 rounded-full mr-1 flex items-center justify-center bg-instagram-500">
                                                                                            <img className="w-3"
                                                                                                src={require('../../assets/images/instagram_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    <div
                                                                                        className="text-sm font-bold ml-3">{item.name}</div>
                                                                                    {
                                                                                        !(item.custom && item.intersection && item.include) &&
                                                                                        <span
                                                                                            className={cx("px-4 py-1 ml-3 inline-flex text-xs font-bold rounded-full",
                                                                                                {
                                                                                                    ["bg-green-100 text-green-500"]: item.include,
                                                                                                    ["bg-orange-100 text-orange-600"]: !item.include
                                                                                                })
                                                                                            }>
                                                                                            {item.include && <span>include</span>}
                                                                                            {!item.include &&
                                                                                                <span>exclude</span>}
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        item.custom && !(item.custom && item.intersection && item.include) &&
                                                                                        <span
                                                                                            className={cx("px-4 py-1 ml-3 inline-flex text-xs font-bold rounded-full",
                                                                                                {
                                                                                                    ["bg-gray-100 text-gray-500"]: true,
                                                                                                })
                                                                                            }>
                                                                                            {Math.round(item.radius / 1000)}{" "}km radius

                                                                                            {
                                                                                                item.custom && item.include && item.channels.facebook && !item.channels.facebook.disabled && item.radius < 25000 &&
                                                                                                <div style={{ paddingTop: "1px" }} className="ml-2">
                                                                                                    <FeatherIcon className="stroke-current" size={15} icon="alert-circle" />
                                                                                                </div>
                                                                                            }

                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        item.custom && item.intersection && item.include &&
                                                                                        <span
                                                                                            className={cx("px-4 py-1 ml-3 inline-flex text-xs font-bold rounded-full",
                                                                                                {
                                                                                                    ["bg-red-100 text-red-600"]: true,
                                                                                                })
                                                                                            }>
                                                                                            This location will not be used
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                <div className="flex flex-1"></div>
                                                                                <Switch
                                                                                    onColor="#1AD5BD"
                                                                                    checked={item.include}
                                                                                    onChange={(checked) => {
                                                                                        if (item.custom) {
                                                                                            item.include = checked;
                                                                                            this.setState({
                                                                                                update: true,
                                                                                                location: item,
                                                                                                selected_locations: this.state.selected_locations,
                                                                                            }, () => {
                                                                                                this.functions.checkIntersections();
                                                                                            });
                                                                                        } else {
                                                                                            item.include = checked;
                                                                                            this.setState({
                                                                                                selected_locations: this.state.selected_locations
                                                                                            }, () => {
                                                                                                this.functions.save('locations');
                                                                                                try {
                                                                                                    this.props.onChange();
                                                                                                } catch (e) {
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="bg-red-200 text-red-500 ml-3 py-2 px-2 rounded"
                                                                                    onClick={() => {
                                                                                        this.functions.remove(item);
                                                                                    }}>
                                                                                    <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                                                                </button>
                                                                                <div onClick={() => {
                                                                                    item.open = !item.open;
                                                                                    this.setState({
                                                                                        selected_locations: this.state.selected_locations
                                                                                    }, () => {
                                                                                        this.functions.save('locations');
                                                                                        try {
                                                                                            this.props.onChange();
                                                                                        } catch (e) { }
                                                                                    });
                                                                                }} className="justify-center items-center flex ml-3">
                                                                                    <FeatherIcon size={18} icon={'more-vertical'} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="w-full">
                                                                                <SlideDown closed={!item.open}>
                                                                                    <div className="flex flex-1 flex-col w-full">
                                                                                        {
                                                                                            Object.keys(item.channels).filter((channel) => {
                                                                                                return item.channels[channel] && this.state.channels[channel]
                                                                                            }).map((channel) => {
                                                                                                return (
                                                                                                    <div className="flex flex-1 flex-row pt-3 border-t pb-3">
                                                                                                        {!item.channels[channel].disabled &&
                                                                                                            <div className="text-sm font-bold pt-1 capitalize">{channel ? channel.replace("_", " ") : channel}</div>
                                                                                                        }
                                                                                                        {item.channels[channel].disabled &&
                                                                                                            <div className="text-sm font-bold opacity-50 pt-1 capitalize">{channel ? channel.replace("_", " ") : channel}</div>
                                                                                                        }
                                                                                                        {
                                                                                                            channel === "facebook" && item.custom && item.include && item.channels.facebook && !item.channels.facebook.disabled && item.radius < 25000 &&
                                                                                                            <div>
                                                                                                                <div className="px-4 py-1 ml-3 inline-flex text-xs font-bold rounded-full bg-yellow-100 text-yellow-500">
                                                                                                                    Recommended is 25 km radius
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        <div className="flex-1 flex"></div>
                                                                                                        <Switch
                                                                                                            disabled={(((Object.keys(item.channels).filter((channel) => {
                                                                                                                return item.channels[channel] && this.state.channels[channel]
                                                                                                            }).length - 1) === Object.keys(item.channels).filter((channel) => {
                                                                                                                return item.channels[channel] && this.state.channels[channel] && item.channels[channel].disabled
                                                                                                            }).length) || Object.keys(item.channels).filter((channel) => {
                                                                                                                return item.channels[channel] && this.state.channels[channel]
                                                                                                            }).length < 2) && !item.channels[channel].disabled}
                                                                                                            onColor="#1AD5BD"
                                                                                                            checked={!item.channels[channel].disabled}
                                                                                                            onChange={(checked) => {
                                                                                                                item.channels[channel].disabled = !checked;
                                                                                                                this.setState({
                                                                                                                    selected_locations: this.state.selected_locations
                                                                                                                }, () => {
                                                                                                                    this.functions.save('locations');
                                                                                                                    this.functions.checkIntersections();
                                                                                                                })
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            <div className="pt-3 mb-4 border-t">
                                                                                                <Input
                                                                                                    title={'Location name'}
                                                                                                    type={'text'}
                                                                                                    noTitle={true}
                                                                                                    value={item.name}
                                                                                                    size={'full'}
                                                                                                    onChange={(event) => {
                                                                                                        item.name = event.target.value;
                                                                                                        for (let channel in item.channels) {
                                                                                                            item.channels[channel].name = event.target.value;
                                                                                                        }
                                                                                                        this.setState({
                                                                                                            selected_locations: this.state.selected_locations
                                                                                                        }, () => {
                                                                                                            this.functions.save('locations');
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </SlideDown>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.location && this.state.location.custom &&
                                    <div className="ml-2 mr-2"></div>
                                }
                                {
                                    this.state.location && this.state.location.custom &&
                                    <div className="flex flex-1 rounded-md overflow-hidden">
                                        {
                                            this.state.step === 'locations' &&
                                            <div className="rounded-md overflow-hidden bg-white rounded-md"
                                                style={{ position: 'relative', width: '100%', height: "500px" }}>
                                                {
                                                    this.state.loading_regions &&
                                                    <div className="Location-map-loader">
                                                        Loading map ...
                                                    </div>
                                                }
                                                {
                                                    this.state.location && !this.state.location.custom &&
                                                    <div
                                                        className="absolute z-10 left-0 top-0 bottom-0 right-0 flex justify-center align-middle items-center bg-white bg-opacity-75">
                                                        <div className="text-sm font-bold">
                                                            No map data available for selected location
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.loading_regions &&
                                                    <AdvertisingLocationMapRegions
                                                        update={this.state.update}
                                                        center={this.state.center}
                                                        zoom={this.state.zoom}
                                                        level={this.state.level.value}
                                                        place={this.state.location}
                                                        places={this.state.locations}
                                                        selected={this.state.selected_locations.filter((item) => {
                                                            return item.custom
                                                        })}
                                                        languages={this.state.languages}
                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                                        loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                                                        containerElement={<div style={{
                                                            height: "500px",
                                                            width: '100%',
                                                            borderRadius: '3px',
                                                            overflow: 'hidden',
                                                            border: '1px solid #e7e7e7'
                                                        }} />}
                                                        mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                                                        onUpdateLocations={(values) => {
                                                            this.setState({
                                                                locations: values
                                                            })
                                                        }}
                                                        onUpdateSelected={(values, location) => {
                                                            this.setState({
                                                                location: location ? location : this.state.location,
                                                                selected_locations: values
                                                            }, () => {
                                                                this.functions.save('locations');
                                                                this.functions.checkIntersections();
                                                            })
                                                        }}
                                                        onUpdate={(value) => {
                                                            this.setState({
                                                                update: value
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.next && this.renders.error().length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-decimal">
                                    {
                                        this.renders.error().map((item) => {
                                            return (
                                                <li className="text-xs text-red-500 font-bold ">
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex flex-1"></div>
                <div className="flex flex-row mt-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) {
                            }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>
                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }} className="btn btn-lg btn-primary inline-flex items-center">
                            Next step: {this.renders.nextStep()} <span className="arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingLocation;
