class campaignSnapchat {
    static validate(campaign) {
        let error = {
            daily_spend_cap: false,
            lifetime_amount: false,
            start_date: false,
            end_date: false,
        };
        if (campaign.has_daily_cap && campaign.has_daily_cap.id === 1) {
            if ((campaign.daily_spend_cap === "" || parseInt(campaign.daily_spend_cap) < 20 || isNaN(parseInt(campaign.daily_spend_cap)))) {
                error.daily_spend_cap = true;
            }
        }
        if (campaign.has_lifetime_cap && campaign.has_lifetime_cap.id === 1) {
            if ((campaign.lifetime_spend_cap === "" || parseInt(campaign.lifetime_spend_cap) < 20 || isNaN(parseInt(campaign.lifetime_spend_cap)))) {
                error.lifetime_spend_cap = true;
            }
        }
        if (campaign.start_date && typeof campaign.status !== "string") {
            if (new Date(campaign.start_date).getTime() < new Date().getTime()) {
                error.start_date = true;
            }
        }
        if (typeof campaign.status !== "string" && campaign.end_date) {
            if (new Date(campaign.end_date).getTime() <= new Date(campaign.start_date).getTime() || new Date(campaign.end_date).getTime() <= new Date().getTime()) {
                error.end_date = true;
            }
        }
        return error;
    }
}

export { campaignSnapchat }