class campaignTwitter {
    static validate(campaign) {
        let error = false;
        if (campaign.budget_optimization && campaign.budget_optimization.value) {
            if (!campaign.daily_budget || (campaign.daily_budget && (campaign.daily_budget === "" || parseInt(campaign.daily_budget) < 1 || isNaN(parseInt(campaign.daily_budget)))) || (campaign.total_budget && (parseInt(campaign.total_budget) < 0 || isNaN(parseInt(campaign.total_budget))))) {
                error = true;
            }
        }
        return error;
    }
}

export { campaignTwitter }