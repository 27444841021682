import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import {SlideDown} from "react-slidedown";
import AdvertisingCreativesSearchAdsItemSitelinks from "../advertising-creatives-search-ads-item-sitelinks";

class AdvertisingCreativesSearchAdsItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            content: [],
            languages: [],
            edit: false,
            inner_edit: 'basic',
            selected_language: null,
            item: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives ? nextProps.creatives : {},
            site: nextProps.site,
            languages: nextProps.languages,
            content: nextProps.content,
            item: nextProps.item
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives ? this.props.creatives : {},
            site: this.props.site,
            languages: this.props.languages,
            content: this.props.content,
            item: this.props.item
        }, () => {
            if (!this.state.selected_language) {
                this.setState({
                    selected_language: this.state.languages[0].code
                })
            }
        })
    }

    init = {};

    functions = {
        remove: () => {
            if (this.state.content.length > 1) {
                this.state.content = this.state.content.filter((item) => {
                    return item.id !== this.state.item.id
                });
                this.props.onRemove(this.state.content);
            }
        }
    };

    validators = {};

    renders = {
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        headline: (item) => {
            try {
                let option = {};
                if (this.state.item[item].type !== 'keyword_insert' && this.state.item[item].type !== 'no_option') {
                    option = {
                        name: this.renders.removeEmoji(this.state.creatives.languages[this.state.selected_language][this.state.item[item].type]),
                        type: this.state.item[item].type
                    };
                } else {
                    option = this.state.item[item];
                }
                return option
            } catch (e) {
                return {}
            }
        },
        bold: (item) => {
            try {
                return this.state.item[item].bold
            } catch (e) {
                return false
            }
        },
        property: (type) => {
            try {
                return this.state.item.languages[this.state.selected_language][type];
            } catch (e) {
                if (type !== 'site_links') {
                    return ""
                } else {
                    return []
                }
            }
        },
        options: (last) => {
            try {
                let extras = [];
                let options = [
                    {name: 'Headline first', type: 'headline'},
                    {name: 'Headline second', type: 'headline_second'},
                    {name: 'Headline third', type: 'headline_third'}
                ];
                options = options.filter((item) => {
                    return this.state.creatives.languages[this.state.selected_language][item.type] !== "";
                }).map((item) => {
                    item.name = this.state.creatives.languages[this.state.selected_language][item.type];
                    return item
                });
                if (last) {
                    options.unshift({name: 'No third headline', type: 'no_option', bold: true});
                }
                options.push({name: 'Keyword', type: 'keyword_insert', bold: true});
                options.push({name: 'Custom headline', type: 'custom', bold: true});
                return options;
            } catch (e) {
                return [];
            }
        },
        type: (item) => {
            try {
                if (item.headline_first_template.type === 'headline' && item.headline_second_template.type === 'headline_second' && item.headline_third_template.type === 'headline_third') {
                    return 'Create&nbsp;<b>Text ad</b>&nbsp;with&nbsp;<b>Headline first, Headline second</b>&nbsp;and&nbsp;<b>Headline third (if exist)</b>';
                } else if (item.headline_first_template.type === 'keyword_insert' && item.headline_second_template.type === 'headline_second' && item.headline_third_template.type === 'headline_third') {
                    return 'Create&nbsp;<b>Text ad</b>&nbsp;with&nbsp;<b>Keyword, Headline second</b>&nbsp;and&nbsp;<b>Headline third (if exist)</b>';
                } else if (item.headline_first_template.type === 'headline' && item.headline_second_template.type === 'keyword_insert' && item.headline_third_template.type === 'headline_third') {
                    return 'Create&nbsp;<b>Text ad</b>&nbsp;with&nbsp;<b>Headline first, Keyword</b>&nbsp;and&nbsp;<b>Headline third (if exist)</b>';
                } else if (item.headline_first_template.type === 'headline' && item.headline_second_template.type === 'headline_second' && item.headline_third_template.type === 'keyword_insert') {
                    return 'Create&nbsp;<b>Text ad</b>&nbsp;with&nbsp;<b>Headline first, Headline second</b>&nbsp;and&nbsp;<b>Keyword</b>';
                }
            } catch (e) {
                return ""
            }
        },
        markdown: (input) => {
            return {__html: input}
        },
        removeEmoji: (input) => {
            try {
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            } catch (e) {
                return input
            }
        },
        customHeadline: (option) => {
            try{
                return this.state.item.custom[this.state.selected_language][option]
            }catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <div className="Creative-search-item">
                {
                    false &&
                    <div className="Advertising-Creatives-ads-title Advertising-Creatives-ads-title-column">
                        <div className="Advertising-Creatives-ads-title-inner">
                            <FeatherIcon color={"#333333"} size={25} icon="layout"/>
                            <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.type(this.state.item))}
                                 className="Advertising-Creatives-ads-title-text">

                            </div>
                            <div className="Advertising-Creatives-ads-title-slider">
                                <Switch
                                    onColor="#24bda8"
                                    checked={this.state.item.show}
                                    onChange={(checked) => {
                                        this.state.item.show = checked;
                                        this.setState({
                                            item: this.state.item
                                        }, () => {
                                            this.props.onChange(this.state.item);
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width: '100%'}}>
                            <SlideDown closed={!this.state.item.show}>
                                <div style={{
                                    width: '100%',
                                    borderTop: '1px solid #e7e7e7',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    boxSizing: 'border-box'
                                }}>
                                    <AdvertisingCreativesSearchPreview
                                        item={this.state.item}
                                        creatives={this.state.creatives}
                                        language={this.state.creatives.selected_language}
                                        url={this.state.site.url}
                                        noContainer={true}
                                    />
                                </div>
                            </SlideDown>
                        </div>
                    </div>
                }
                <div style={{width: '100%'}} className="Section-Inner">
                    {
                        <div className="Creative-search-item-inner">
                            {
                                false &&
                                <div className="Creative-search-item-inner-values-inner">
                                    {
                                        false &&
                                        <FeatherIcon color={"#333333"} size={25} icon="type"/>
                                    }
                                    {
                                        <div className="Creative-search-item-inner-values-inner-text">
                                            Text ad
                                        </div>
                                    }
                                </div>
                            }
                            <div className="Creative-search-item-inner-values-inputs">
                                <div className="Creative-search-item-inner-values-inputs-item">
                                    {
                                        this.state.item.headline_first_template && this.state.item.headline_first_template.type !== 'custom' &&
                                        <Dropdown
                                            filter={false}
                                            searchFilter={false}
                                            search={false}
                                            noTitle={true}
                                            size={'full'}
                                            placeholder={"Select first headline"}
                                            options={this.renders.options()}
                                            value={this.renders.headline('headline_first_template') ? this.renders.headline('headline_first_template') : {}}
                                            bold={this.renders.bold('headline_first_template')}
                                            onChange={(value) => {
                                                this.state.item.headline_first_template = value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                    {
                                        this.state.item.headline_first_template && this.state.item.headline_first_template.type === 'custom' &&
                                        <Input
                                            sign={false}
                                            noTitle={true}
                                            maxLength={30}
                                            white={false}
                                            value={this.renders.customHeadline('headline')}
                                            placeholder={"Max 30 characters"}
                                            tight={true}
                                            noBorderRight={false}
                                            size={'full'}
                                            type={"text"}
                                            icons={false}
                                            showCross={true}
                                            onCrossClick={() => {
                                                if(this.state.item.custom){
                                                    this.state.item.custom[this.state.selected_language].headline = "";
                                                }
                                                this.state.item.headline_first_template = {name: 'Headline first', type: 'headline'};
                                                this.props.onChange(this.state.item);
                                            }}
                                            onChange={(event) => {
                                                if (!this.state.item.custom) {
                                                    this.state.item.custom = {};
                                                    this.state.item.custom[this.state.selected_language] = {
                                                        headline: "",
                                                        headline_second: "",
                                                        headline_third: ""
                                                    };
                                                }
                                                this.state.item.custom[this.state.selected_language].headline = event.target.value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                </div>
                                <div className="Creative-search-item-inner-values-inputs-item">
                                    {
                                        this.state.item.headline_second_template && this.state.item.headline_second_template.type !== 'custom' &&
                                        <Dropdown
                                            filter={false}
                                            searchFilter={false}
                                            search={false}
                                            noTitle={true}
                                            size={'full'}
                                            placeholder={"Select second headline"}
                                            options={this.renders.options()}
                                            value={this.renders.headline('headline_second_template') ? this.renders.headline('headline_second_template') : {}}
                                            bold={this.renders.bold('headline_second_template')}
                                            onChange={(value) => {
                                                this.state.item.headline_second_template = value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                    {
                                        this.state.item.headline_second_template && this.state.item.headline_second_template.type === 'custom' &&
                                        <Input
                                            sign={false}
                                            noTitle={true}
                                            maxLength={30}
                                            white={false}
                                            value={this.renders.customHeadline('headline_second')}
                                            placeholder={"Max 30 characters"}
                                            tight={true}
                                            noBorderRight={false}
                                            size={'full'}
                                            type={"text"}
                                            icons={false}
                                            showCross={true}
                                            onCrossClick={() => {
                                                if(this.state.item.custom){
                                                    this.state.item.custom[this.state.selected_language].headline_second = "";
                                                }
                                                this.state.item.headline_second_template = {name: 'Headline second', type: 'headline_second'};
                                                this.props.onChange(this.state.item);
                                            }}
                                            onChange={(event) => {
                                                if (!this.state.item.custom) {
                                                    this.state.item.custom = {};
                                                    this.state.item.custom[this.state.selected_language] = {
                                                        headline: "",
                                                        headline_second: "",
                                                        headline_third: ""
                                                    };
                                                }
                                                this.state.item.custom[this.state.selected_language].headline_second = event.target.value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                </div>
                                <div className="Creative-search-item-inner-values-inputs-item">
                                    {
                                        this.state.item.headline_third_template && this.state.item.headline_third_template.type !== 'custom' &&
                                        <Dropdown
                                            filter={false}
                                            searchFilter={false}
                                            search={false}
                                            noTitle={true}
                                            size={'full'}
                                            placeholder={"Select third headline"}
                                            options={this.renders.options(true)}
                                            value={this.renders.headline('headline_third_template') ? this.renders.headline('headline_third_template') : {}}
                                            bold={this.renders.bold('headline_third_template')}
                                            onChange={(value) => {
                                                this.state.item.headline_third_template = value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                    {
                                        this.state.item.headline_third_template && this.state.item.headline_third_template.type === 'custom' &&
                                        <Input
                                            sign={false}
                                            noTitle={true}
                                            maxLength={30}
                                            white={false}
                                            value={this.renders.customHeadline('headline_third')}
                                            placeholder={"Max 30 characters"}
                                            tight={true}
                                            noBorderRight={false}
                                            size={'full'}
                                            type={"text"}
                                            icons={false}
                                            showCross={true}
                                            onCrossClick={() => {
                                                if(this.state.item.custom){
                                                    this.state.item.custom[this.state.selected_language].headline_third = "";
                                                }
                                                this.state.item.headline_third_template = {name: 'No third headline', type: 'no_option', bold: true};
                                                this.props.onChange(this.state.item);
                                            }}
                                            onChange={(event) => {
                                                if (!this.state.item.custom) {
                                                    this.state.item.custom = {};
                                                    this.state.item.custom[this.state.selected_language] = {
                                                        headline: "",
                                                        headline_second: "",
                                                        headline_third: ""
                                                    };
                                                }
                                                this.state.item.custom[this.state.selected_language].headline_third = event.target.value;
                                                this.props.onChange(this.state.item);
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="Creative-search-item-inner-button">
                                <div onClick={() => {
                                    this.state.edit = !this.state.edit;
                                    this.setState({
                                        edit: this.state.edit
                                    });
                                }} className="Button ButtonNeutral ButtonFull">
                                    {
                                        !this.state.edit &&
                                        <FeatherIcon color={"#333333"} size={25} icon="eye"/>
                                    }
                                    {
                                        this.state.edit &&
                                        <FeatherIcon color={"#333333"} size={25} icon="eye-off"/>
                                    }
                                </div>
                            </div>
                            <div className="Creative-search-item-inner-button">
                                <div onClick={() => {
                                    this.functions.remove();
                                }} className="Button ButtonRed ButtonFull">
                                    {
                                        this.state.content.length > 1 &&
                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        <SlideDown closed={!this.state.edit}>
                            <div className="Creative-search-item-bottom">
                                <div className="Creative-search-item-bottom-inner">
                                    {
                                        <div className="Creative-search-item-bottom-inner-left">
                                            <AdvertisingCreativesSearchPreview
                                                item={this.state.item}
                                                creatives={this.state.creatives}
                                                language={this.state.creatives.selected_language}
                                                url={this.state.site.url}
                                                noContainer={true}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </SlideDown>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesSearchAdsItem;
