import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import ReactGridLayout from '../react-grid-layout/index';
import Tabs from '../reports-tabs';
import PptsGenJs from '../report-pptsGenJs/index';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';

// fake data's for react grids on this page
import {initialFakeData, fakeDataTabs, fakeDataForCalendarFilter} from './initialFakeData';

import './style.css';

export default class TemplateOlv extends Component {
    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.pptx = React.createRef();
        this.printRef = React.createRef();

        this.state = {
            initialFakeData: initialFakeData ? initialFakeData : [],
            fakeDataForCalendarFilter: fakeDataForCalendarFilter,
            isCalendarFilterOpen: false,
            calendarFilterValue: 'From compaign start to now',
            fakeDataForDropdown: [
                {
                    id: 1,
                    icon: 'link',
                    text: 'Generate external link for client',
                    link: '#',
                },

                {
                    id: 2,
                    icon: 'link-2',
                    text: 'Generate internal link -/premium/report/12345',
                    link: '#',
                    func: () => this.props.history.push('/premium/report/12345'),
                },
                {
                    id: 3,
                    icon: 'file-text',
                    text: 'Download as PDF',
                    link: '#',
                    func: () => this.functions.pdfPrint(),
                },
                {
                    id: 4,
                    icon: 'file-text',
                    text: 'Download as PowerPoint',
                    link: '#',
                    func: () =>
                        this.pptx.current.functions.createNewPptx({
                            initialFakeData: this.state.initialFakeData,
                            tabs: this.state.fakeDataTabs,
                            selected_logo: this.state.selected_logo,
                            text: this.state.text,
                            title: this.state.title,
                        }),
                },
                {
                    id: 5,
                    icon: 'plus-circle',
                    text: 'Save as new template',
                    link: '#',
                },
            ],
            isDroppedDown: false,
            fakeDataTabs: fakeDataTabs,
            activeTab: 1,
            activeData: [],
            pdfArr: [],
        };
    }

    componentWillMount() {
        const createPrintToPdfPart = () => {
            const arr = [];

            for (var i = 0; i < this.state.initialFakeData.length; i++) {
                arr.push(
                    <div className="relative">
                        <div className="">
                            <ReactGridLayout
                                initialItems={this.state.initialFakeData[i].data}
                                backgroundColor={'section-to-pdf-print'}
                                verticalCompact={true}
                                remove={true}
                                onInput={(htmlState) => this.functions.onInput(htmlState)}
                            />
                        </div>
                        <div className="absolute bottom-0 right-0">TITLE</div>
                        <div className="pagebreak"></div>
                    </div>
                );
            }

            this.setState({pdfArr: arr});
        };

        createPrintToPdfPart();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('mousedown', this.functions.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.functions.handleClickOutside);
    }

    init() {}

    functions = {
        // check where the click was made If outside --> close dropdown
        handleClickOutside: (e) => {
            let path = e.path || (e.composedPath && e.composedPath()) || e.composedPath(e.target);

            if (!path.includes(this.buttonRef.current) && !path.includes(this.dropdownRef.current)) {
                this.setState({isDroppedDown: false});
            }
        },

        setActiveTab: (number) => {
            this.setState({activeTab: number});
        },

        // set new value of calendar filter
        setCalendarFilter: (id, text) => {
            let tempData = [...this.state.fakeDataForCalendarFilter];
            tempData.map((t) => (t.id === id ? (t.active = true) : (t.active = false)));

            this.setState({
                fakeDataForCalendarFilter: tempData,
                calendarFilterValue: text,
                isCalendarFilterOpen: false,
            });
        },

        onGridChange: (items, id) => {
            let gridData = [...this.state.initialFakeData];
            let bbb = gridData.forEach(function (gd) {
                if (gd.id === id + 1) {
                    gd.data = items;
                    return gd;
                } else {
                    return gd;
                }
            });

            //console.log('olv-template__functions.onGridChange', bbb);
            // this.setState({ initialFakeData: bbb });
        },

        onInput: (htmlState) => {
            //console.log('htmlState', htmlState);
        },

        pdfPrint: () => {
            window.confirm('print as PDF?') && this.printRef.current.handleClick();
        },
    };

    render() {
        let {isCalendarFilterOpen, fakeDataForCalendarFilter} = this.state;
        console.log('render()');

        return (
            <section className="report-template w-full h-full pb-8 flex flex-col">
                <div className="report-template__header flex justify-between items-center">
                    <ul className="flex items-center">
                        <Tabs
                            color="purple"
                            activeTab={this.state.activeTab}
                            setActiveTab={this.functions.setActiveTab}
                            fakeDataTabs={this.state.fakeDataTabs}
                        />
                    </ul>

                    {/* Calendar Filter start */}

                    <div className="date-filter flex items-center">
                        <div className="mr-4">
                            <div className="relative">
                                <button
                                    className={`${
                                        isCalendarFilterOpen ? 'bg-white' : 'bg-gray-200'
                                    } font-bold text-sm py-3 px-6 rounded-lg focus:outline-none flex items-center justify-between duration-200`}
                                    style={{minWidth: '200px'}}
                                    onClick={() =>
                                        this.setState({
                                            isCalendarFilterOpen: !isCalendarFilterOpen,
                                        })
                                    }
                                    ref={this.buttonRef}>
                                    <span className="flex items-center">
                                        <span className="mr-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="20.25" viewBox="0 0 20.25 20.25">
                                                <path
                                                    id="svg-169"
                                                    data-name="svg 169"
                                                    d="M13.865,10.75v1.558h-.779a2.345,2.345,0,0,0-2.337,2.337V28.663A2.345,2.345,0,0,0,13.087,31H28.663A2.345,2.345,0,0,0,31,28.663V14.644a2.345,2.345,0,0,0-2.337-2.337h-.779V10.75H26.327v1.558h-10.9V10.75Zm-.779,3.115h.779v1.558h1.558V13.865h10.9v1.558h1.558V13.865h.779a.769.769,0,0,1,.779.779v2.337H12.308V14.644A.769.769,0,0,1,13.087,13.865Zm-.779,4.673H29.442V28.663a.769.769,0,0,1-.779.779H13.087a.769.769,0,0,1-.779-.779ZM16.981,20.1v1.558h1.558V20.1Zm3.115,0v1.558h1.558V20.1Zm3.115,0v1.558h1.558V20.1Zm3.115,0v1.558h1.558V20.1ZM13.865,23.212v1.558h1.558V23.212Zm3.115,0v1.558h1.558V23.212Zm3.115,0v1.558h1.558V23.212Zm3.115,0v1.558h1.558V23.212Zm3.115,0v1.558h1.558V23.212ZM13.865,26.327v1.558h1.558V26.327Zm3.115,0v1.558h1.558V26.327Zm3.115,0v1.558h1.558V26.327Z"
                                                    transform="translate(-10.75 -10.75)"
                                                    fill={isCalendarFilterOpen ? '#453FF1' : '#92919A'}
                                                />
                                            </svg>
                                        </span>
                                        <span className={`${isCalendarFilterOpen ? 'text-black' : 'text-gray-600'} ml-1`}>
                                            {this.state.calendarFilterValue}
                                        </span>
                                    </span>
                                    <svg
                                        className={`-mr-1 ml-2 h-5 w-5 ${isCalendarFilterOpen ? 'rotated' : 'similar '}`}
                                        viewBox="0 0 20 20"
                                        fill="#92919A"
                                        aria-hidden="true">
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                {/* Calendar Filter DropDown-menu start */}

                                <div
                                    className={`export-dropdown absolute mt-2 right-0 rounded-md overflow-hidden shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 
                    ${isCalendarFilterOpen ? 'block' : 'hidden'}`}
                                    ref={this.dropdownRef}>
                                    {fakeDataForCalendarFilter.map(({text, id, active}) => (
                                        <div
                                            className="export-dropdown__button text-sm hover:bg-gray-300 divide-y divide-gray-200"
                                            onClick={() => this.functions.setCalendarFilter(id, text)}
                                            key={id}>
                                            <div className="flex items-center whitespace-nowrap p-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                                                    <circle id="ellipse" data-name="ellipse 37" cx="4" cy="4" r="4" fill={`${active ? 'black' : '#F1F0F8'}`} />
                                                </svg>
                                                <span
                                                    className={`${
                                                        active ? 'text-gray-400 font-semibold italic' : 'text-gray-600'
                                                    } export-dropdown__text ml-2 `}>
                                                    {text}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div>
                            <FeatherIcon id="svg--more-vertical" color={'#707070'} size={16} icon="more-vertical" />
                        </div>
                    </div>
                </div>

                {/* Calendar Filter DropDown-menu end */}
                {/* Tabs & Grid for PDF PRINT start */}

                <div style={{overflow: 'hidden', height: 0}}>
                    <div ref={(el) => (this.componentRef = el)}>
                        {this.state.pdfArr.map((e, index) => (
                            <div key={index}>{e}</div>
                        ))}
                    </div>
                </div>

                {/* Tabs & Grid for PDF PRINT end */}
                {/* Tabs & Grid start */}

                {this.state.initialFakeData.map(
                    (fakeData, id) =>
                        fakeData.id === this.state.activeTab && (
                            <ReactGridLayout
                                onGridChange={(items) => this.functions.onGridChange(items, id)}
                                initialItems={fakeData.data}
                                isSlideOverClosed={this.functions.isSlideOverClosed}
                                backgroundColor={'bg-gray-200'}
                                verticalCompact={true}
                                remove={false}
                                ref={this.child}
                                onInput={(htmlState) => this.functions.onInput(htmlState)}
                                key={id}
                            />
                        )
                )}

                {/* Tabs & Grid end */}
                {/* Header right buttons start */}

                <aside
                    style={{zIndex: 9998}}
                    className="bg-white top-0 fixed flex flex-row items-center justify-end lg:w-6/12 md:w-5/12 px-6 py-2 md:py-3 right-0 w-full">
                    <button
                        onClick={() => alert('save compaign')}
                        className="bg-transparent text-purple-500 font-bold text-sm py-2 px-4 rounded-full mr-4 focus:outline-none">
                        Save template
                    </button>

                    <div className="relative">
                        <button
                            className="bg-green-100 text-green-500 hover:bg-green-500 hover:text-white font-bold text-sm py-2 px-4 rounded-full focus:outline-none"
                            onClick={() =>
                                this.setState({
                                    isDroppedDown: !this.state.isDroppedDown,
                                })
                            }
                            ref={this.buttonRef}>
                            Export
                        </button>

                        <div
                            className={`export-dropdown absolute mt-2 right-0 rounded-md overflow-hidden shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200      
                    ${this.state.isDroppedDown ? 'block' : 'hidden'}`}
                            ref={this.dropdownRef}>
                            {this.state.fakeDataForDropdown.map(({text, icon, id, func}) => (
                                <div className="export-dropdown__button text-sm hover:bg-gray-300 divide-y divide-gray-200" key={id} onClick={func}>
                                    <div className="flex items-center whitespace-nowrap p-4">
                                        <FeatherIcon id={icon} color={'#0f0a7e'} size={18} icon={icon} />
                                        <span className="export-dropdown__text ml-2 font-semibold text-gray-900">{text}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </aside>

                {/* Header right buttons end */}
                {/* PptsGenJs & ReactToPrint modules call start */}

                <PptsGenJs ref={this.pptx} />
                <ReactToPrint content={() => this.componentRef} ref={this.printRef}>
                    <PrintContextConsumer>{({handlePrint}) => <span onClick={handlePrint}></span>}</PrintContextConsumer>
                </ReactToPrint>
            </section>
        );
    }
}
