import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, ExclamationIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar } from 'react-chartjs-2';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import { createChangelog } from '../services/createChangelog';
var moment = require('moment');

class AdgroupScheduler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adgroup: {},
            error: false,
            tab: { id: 1, name: "Weekday planer", value: "weekdays" },
            tabs: [
                { id: 1, name: "Weekday planer", value: "weekdays" },
                //{ id: 2, name: "Daily budget planer", value: "budget_planer" },
                //{ id: 3, name: "Start/Pause Dates", value: "dates" }
            ],
            budget: 0,
            start_date: moment(new Date()).format("YYYY-MM-DD"),
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            order: this.props.order
        });

        if (this.state.adgroup.scheduleStartDate) {
            await this.promisedSetState({
                start_date: this.state.adgroup.scheduleStartDate
            });
            this.state.budget = this.renders.budgetPlannerGraph().datasets[0].data[0];
            await this.promisedSetState({
                budget: this.state.budget
            });
        }

        console.log(this.state.adgroup, "ADGROUP INFO");

    }

    componentWillReceiveProps(nextProps) { }

    functions = {
        updateSchedule: async () => {
            await this.promisedSetState({ error: true });
            this.props.onLoader(true);
            try {
                await this.calls.update({
                    scheduledates_activated: this.state.adgroup.scheduleDatesActivated ? true : false,
                    schedule_dates: this.state.adgroup.scheduleDates,
                    budgetplaner_total: this.state.adgroup.budgetPlanerTotal ? this.state.adgroup.budgetPlanerTotal : 0,
                    budgetplaner_activated: this.state.adgroup.budgetPlanerActivated ? this.state.adgroup.budgetPlanerActivated : false,
                    schedule_activated: this.state.adgroup.scheduleActivated ? true : false,
                    schedule_monday: this.state.adgroup.scheduleMonday ? true : false,
                    schedule_tuesday: this.state.adgroup.scheduleTuesday ? true : false,
                    schedule_wednesday: this.state.adgroup.scheduleWednesday ? true : false,
                    schedule_thursday: this.state.adgroup.scheduleThursday ? true : false,
                    schedule_friday: this.state.adgroup.scheduleFriday ? true : false,
                    schedule_saturday: this.state.adgroup.scheduleSaturday ? true : false,
                    schedule_sunday: this.state.adgroup.scheduleSunday ? true : false,
                    schedule_start_date: this.state.adgroup.scheduleStartDate ? this.state.adgroup.scheduleStartDate : (this.state.new ? this.state.start_date : false),
                });
                if (this.state.adgroup.budgetPlanerActivated) {
                    this.state.adgroup.scheduleStartDate = this.state.adgroup.scheduleStartDate ? this.state.adgroup.scheduleStartDate : (this.state.new ? this.state.start_date : false);
                    await this.promisedSetState({
                        adgroup: this.state.adgroup
                    });
                }
                this.props.onSuccess(this.state.adgroup);
            } catch (error) {
                this.setState({
                    error: true
                });
            }
            this.props.onLoader(false);
            this.functions.handleCreateChangelog()
        },
        handleCreateChangelog: async () => {
            try {
                let initial_data = this.props.adgroup
                let updated_data = this.state.adgroup
                let order = window.location.pathname.split("/").pop();
                createChangelog({ initial_data: initial_data, updated_data: updated_data, client: this.state.adgroup.client, order: order, item: this.state.adgroup.id, level: "adgroup" });
            } catch (error) { }
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupSchedule?id=" + this.state.adgroup.id + "&campaign=" + this.state.adgroup.internal_campaign;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        budgetPlannerGraph: () => {
            let graph_data = {
                labels: [],
                datasets: []
            }
            let months = [];
            let today = moment(this.state.start_date).startOf('month');
            if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.id === 1) {
                today = moment(this.state.start_date);
            }
            for (let i = 0; i < 12; i++) {
                let month = today.month();
                let data = { daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
                let next = false;
                while (!next) {
                    if (month !== today.month()) {
                        next = true;
                    } else {
                        data[today.isoWeekday()] += 1;
                        today.add(1, 'd');
                    }
                }
                if (next) {
                    if (this.state.adgroup.scheduleMonday) {
                        data.total += data["1"];
                    }
                    if (this.state.adgroup.scheduleTuesday) {
                        data.total += data["2"];
                    }
                    if (this.state.adgroup.scheduleWednesday) {
                        data.total += data["3"];
                    }
                    if (this.state.adgroup.scheduleThursday) {
                        data.total += data["4"];
                    }
                    if (this.state.adgroup.scheduleFriday) {
                        data.total += data["5"];
                    }
                    if (this.state.adgroup.scheduleSaturday) {
                        data.total += data["6"];
                    }
                    if (this.state.adgroup.scheduleSunday) {
                        data.total += data["7"];
                    }
                    if (this.state.adgroup.budgetPlanerTotal > 0) {
                        if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => this.state.adgroup[item]).length < 7) {
                            data.daily_budget = ((this.state.adgroup.budgetPlanerTotal) / data.total).toFixed(2);
                        } else {
                            if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.id === 1) {
                                data.daily_budget = ((this.state.adgroup.budgetPlanerTotal) / data.total).toFixed(2);
                            } else {
                                data.daily_budget = ((this.state.adgroup.budgetPlanerTotal) / 30).toFixed(2);
                            }
                        }
                        //data.daily_budget = Math.floor((this.state.adgroup.budgetPlanerTotal) / data.total);
                        //data.left_over = this.state.adgroup.budgetPlanerTotal - (data.daily_budget * data.total);
                    }
                    graph_data.labels.push([data.total + " days", data.month, data.year]);
                    months.push(data);
                    continue;
                }
            }

            let dataset_daily = {
                label: 'Daily budget',
                data: [],
                borderColor: 'rgb(69, 63, 241)',
                backgroundColor: 'rgba(69, 63, 241, 0.7)'
            }

            let dataset_leftover = {
                label: 'Leftover added to last day',
                data: [],
                borderColor: 'rgb(255, 77, 84)',
                backgroundColor: 'rgba(255, 77, 84, 0.7)'
            }

            months.map((item) => {
                dataset_daily.data.push(item.daily_budget);
                dataset_leftover.data.push(item.left_over);
            });

            graph_data.datasets.push(dataset_daily);
            //graph_data.datasets.push(dataset_leftover);

            return graph_data;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
            
        });
    }

    render() {
        return (
            <>
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.value == this.state.tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                        )}
                                        aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>
                    {
                        this.state.tab.value === 'weekdays' &&
                        <div className="col-span-6">
                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className="flex flex-1 ml-5">
                                    {this.state.adgroup.scheduleActivated ? "Active" : "Disabled"}
                                </div>
                                <div className="relative  mr-5">
                                    <SwitchTailwind
                                        value={this.state.adgroup.scheduleActivated ? true : false}
                                        onSwitch={async () => {
                                            this.state.adgroup.scheduleActivated = !this.state.adgroup.scheduleActivated;
                                            if (!this.state.adgroup.scheduleActivated) {
                                                this.state.adgroup.budgetPlanerActivated = false;
                                                this.state.adgroup.budgetPlanerTotal = 0;
                                            }
                                            this.state.adgroup.scheduleMonday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleTuesday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleWednesday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleThursday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleFriday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleSaturday = this.state.adgroup.scheduleActivated;
                                            this.state.adgroup.scheduleSunday = this.state.adgroup.scheduleActivated;

                                            if (this.state.adgroup.scheduleActivated && this.state.adgroup.budgetPlanerActivated) {
                                                if (this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity) {
                                                    this.state.budget = this.renders.budgetPlannerGraph().datasets[0].data[0];
                                                } else {
                                                    this.state.budget = 0;
                                                }
                                            }
                                            await this.promisedSetState({
                                                adgroup: this.state.adgroup,
                                                budget: this.state.budget
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.tab.value === 'weekdays' &&
                        this.state.adgroup.scheduleActivated &&
                        <>
                            <div className="col-span-6 -mt-3">
                                <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                    Days
                                </div>
                                <div className='flex flex-wrap'>
                                    {
                                        [
                                            { name: "Monday", property: "scheduleMonday" },
                                            { name: "Tuesday", property: "scheduleTuesday" },
                                            { name: "Wednesday", property: "scheduleWednesday" },
                                            { name: "Thursday", property: "scheduleThursday" },
                                            { name: "Friday", property: "scheduleFriday" },
                                            { name: "Saturday", property: "scheduleSaturday" },
                                            { name: "Sunday", property: "scheduleSunday" }
                                        ].map((weekday, index) => {
                                            return (
                                                <div onClick={async () => {
                                                    this.state.adgroup[weekday.property] = !this.state.adgroup[weekday.property];
                                                    if (this.state.adgroup.budgetPlanerActivated) {
                                                        if (this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity) {
                                                            this.state.budget = this.renders.budgetPlannerGraph().datasets[0].data[0];
                                                        } else {
                                                            this.state.budget = 0;
                                                        }
                                                    }
                                                    await this.promisedSetState({
                                                        adgroup: this.state.adgroup,
                                                        budget: this.state.budget
                                                    })
                                                    if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => { return this.state.adgroup[item] }).length === 0) {
                                                        this.props.onDisable(true)
                                                    } else {
                                                        this.props.onDisable(false)
                                                    }

                                                }} className={(this.state.adgroup[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div>{weekday.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(this.state.adgroup[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(this.state.adgroup[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='col-span-6 ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                <div className='col-span-4'>
                                    <div className='flex flex-row pt-4  ease-in-out transition-all duration-500'>
                                        <div className={'flex flex-col transition-all transform duration-500 ' + (!this.state.adgroup.budgetPlanerActivated ? " flex-1" : " pr-4")}>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                            <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 min-w-24">
                                                    {(this.state.adgroup.budgetPlanerActivated ? "Active" : "Disabled")}
                                                </div>
                                                <div className="relative  mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.adgroup.budgetPlanerActivated}
                                                        onSwitch={async () => {
                                                            this.state.adgroup.budgetPlanerActivated = !this.state.adgroup.budgetPlanerActivated;
                                                            if (this.state.adgroup.budgetPlanerActivated) {
                                                                this.state.adgroup.scheduleStartDate = false;
                                                                this.state.start_date = moment(new Date()).format("YYYY-MM-DD");
                                                                await this.promisedSetState({
                                                                    new: true,
                                                                    start_date: this.state.start_date
                                                                });

                                                            }
                                                            if (this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity) {
                                                                this.state.budget = this.renders.budgetPlannerGraph().datasets[0].data[0];
                                                            } else {
                                                                this.state.budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                adgroup: this.state.adgroup,
                                                                budget: this.state.budget
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex transition-all transform duration-500  ' + (this.state.adgroup.budgetPlanerActivated ? "pr-4" : "w-0 hidden")}>
                                            <div className=' pr-2'>
                                                <InputTailwind
                                                    locked={!this.state.adgroup.budgetPlanerActivated}
                                                    disabled={!this.state.adgroup.budgetPlanerActivated}
                                                    label={"Total monthly"}
                                                    value={this.state.adgroup.budgetPlanerTotal}
                                                    type={"number"}
                                                    onChange={async (value) => {
                                                        this.state.adgroup.budgetPlanerTotal = value;
                                                        this.state.adgroup.scheduleStartDate = false;
                                                        this.state.start_date = moment(new Date()).format("YYYY-MM-DD");
                                                        await this.promisedSetState({
                                                            new: true,
                                                            start_date: this.state.start_date
                                                        });


                                                        if (this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity) {
                                                            this.state.budget = this.renders.budgetPlannerGraph().datasets[0].data[0];
                                                        } else {
                                                            this.state.budget = 0;
                                                        }
                                                        await this.promisedSetState({
                                                            adgroup: this.state.adgroup,
                                                            budget: this.state.budget
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className=''>
                                                <InputTailwind
                                                    locked={true}
                                                    disabled={true}
                                                    label={"Current month daily budget"}
                                                    value={this.state.budget}
                                                    type={"number"}
                                                />
                                            </div>
                                        </div>
                                        {
                                            !this.state.adgroup.scheduleStartDate &&
                                            <div className={'flex transition-all transform duration-500  ' + (this.state.adgroup.budgetPlanerActivated ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={true}
                                                        locked={true}
                                                        placeholder={moment(this.state.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.adgroup.budgetPlanerActivated && this.state.adgroup.budgetPlanerTotal > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] === Infinity &&
                                        <div className='flex flex-row border-t mt-4'>
                                            <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                            </div>
                                        </div>
                                    }
                                </div>

                                {
                                    this.state.adgroup.budgetPlanerActivated &&
                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.adgroup.budgetPlanerActivated ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={this.renders.budgetPlannerGraph()}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        </>
                    }




                    {
                        this.state.tab.value === 'dates' &&
                        <>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    label={"Set dates"}
                                    selected={this.state.adgroup.scheduleDatesActivated ? { id: 1, name: "Enabled", value: true } : { id: 2, name: "Disabled", value: false }}
                                    options={[
                                        { id: 1, name: "Enabled", value: true },
                                        { id: 2, name: "Disabled", value: false }
                                    ]}
                                    onChange={(option) => {
                                        this.state.adgroup.scheduleDatesActivated = option.value;
                                        this.setState({
                                            adgroup: this.state.adgroup
                                        });
                                    }}
                                />
                            </div>
                            {
                                this.state.adgroup.scheduleDatesActivated &&
                                (Array.isArray(this.state.adgroup.scheduleDates) ? this.state.adgroup.scheduleDates : []).map((item) => {
                                    return (
                                        <div className="col-span-6 grid grid-cols-12 gap-4">
                                            <div className="col-span-3">
                                                <InputDatepickerTailwind
                                                    label={"Date"}
                                                    value={item.date ? item.date : moment(new Date()).format("YYYY-MM-DD")}
                                                    onChange={(value) => {
                                                        item.date = value;
                                                        this.setState({
                                                            adgroup: this.state.adgroup
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <DropdownTailwind
                                                    label={"Time"}
                                                    selected={item.time ? item.time : { id: 0, name: "Select time", value: false }}
                                                    options={[
                                                        { id: 1, name: "00:00", value: "00:00" },
                                                        { id: 2, name: "01:00", value: "01:00" },
                                                        { id: 3, name: "02:00", value: "02:00" },
                                                        { id: 4, name: "03:00", value: "03:00" },
                                                        { id: 5, name: "04:00", value: "04:00" },
                                                        { id: 6, name: "05:00", value: "05:00" },
                                                        { id: 7, name: "06:00", value: "06:00" },
                                                        { id: 8, name: "07:00", value: "07:00" },
                                                        { id: 9, name: "08:00", value: "08:00" },
                                                        { id: 10, name: "09:00", value: "09:00" },
                                                        { id: 11, name: "10:00", value: "10:00" },
                                                        { id: 12, name: "11:00", value: "11:00" },
                                                        { id: 13, name: "12:00", value: "12:00" },
                                                        { id: 14, name: "13:00", value: "13:00" },
                                                        { id: 15, name: "14:00", value: "14:00" },
                                                        { id: 16, name: "15:00", value: "15:00" },
                                                        { id: 17, name: "16:00", value: "16:00" },
                                                        { id: 18, name: "17:00", value: "17:00" },
                                                        { id: 19, name: "18:00", value: "18:00" },
                                                        { id: 20, name: "19:00", value: "19:00" },
                                                        { id: 21, name: "20:00", value: "20:00" },
                                                        { id: 22, name: "21:00", value: "21:00" },
                                                        { id: 23, name: "22:00", value: "22:00" },
                                                        { id: 24, name: "23:00", value: "23:00" }
                                                    ]}
                                                    onChange={(option) => {
                                                        item.time = option;
                                                        this.setState({
                                                            adgroup: this.state.adgroup
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    rightLabel={true}
                                                    rightLabelText={"Remove"}
                                                    rightLabelColor={"text-red-500 hover:text-red-700"}
                                                    onRightLabelClick={() => {
                                                        this.state.adgroup.scheduleDates = this.state.adgroup.scheduleDates.filter((inner_item) => {
                                                            return inner_item.id !== item.id;
                                                        });
                                                        this.setState({
                                                            adgroup: this.state.adgroup
                                                        });
                                                    }}
                                                    label={"Status"}
                                                    selected={item.status ? item.status : { id: 0, name: "Select status", value: false }}
                                                    options={[
                                                        { id: 1, name: "Start", value: "activate" },
                                                        { id: 2, name: "Pause", value: "pause" }
                                                    ]}
                                                    onChange={(option) => {
                                                        item.status = option;
                                                        this.setState({
                                                            adgroup: this.state.adgroup
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.adgroup.scheduleDatesActivated &&
                                <div className="col-span-6">
                                    <div onClick={() => {
                                        if (!Array.isArray(this.state.adgroup.scheduleDates)) {
                                            this.state.adgroup.scheduleDates = [];
                                        }
                                        this.state.adgroup.scheduleDates.push({ id: Math.floor((Math.random() * 9999999999) + 1), date: null, status: null });
                                        this.setState({
                                            adgroup: this.state.adgroup
                                        });
                                    }} className="col-span-3 inline-flex flex-row items-center text-sm font-medium bg-purple-100 rounded-full px-4 py-2 cursor-pointer text-purple-500 hover:text-purple-700">
                                        Add new Date
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

export default AdgroupScheduler;
