import React, {Component} from 'react';
import './style.css';
import Menu from "../../components/menu";

class TermsOfService extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount(){
        this.init.title();
    }

    init = {
        title: () => {
            document.title = "Terms of Service | Adcredo";
        }
    };

    functions = {};

    renders = {};

    render(){
        return (
            <div>
                <img onClick={() => {
                    this.props.history.push('/login');
                }} className="policy-logo" src={require('../../assets/images/adcredo_logo.png')}/>
                <div className="policy">
                    <h1>
                        Software as a Service - Terms and Conditions
                    </h1>
                    <p>
                        The Adcredo Software as a Service (“SaaS”) Terms and Conditions (“Terms and Conditions”) are related to SaaS service between Adcredo (Absolutio AB), a company based in Sweden and the licensed client (the “Client”). Client’s SaaS license (subject to the payment of the License fee and the provisions for termination set out in this Agreement) shall be deemed Client’s agreement to these Terms and Conditions.
                    </p>
                    <h3>
                        1. DEFINITIONS
                    </h3>
                    <p>
                        For purposes of these Terms and Conditions, the terms below shall have the meanings defined below.
                    </p>
                    <p>
                        1.1 “Client Content” means any data, information, trademarks, logos, files, images, text or other content that may be provided by Client or its authorized users for use in conjunction with the Software or Services. The Client Content used in conjunction with the Software or Service is stored and maintained in Adcredo.io along with Other Platforms (defined in Section 1.7) cloud platform.
                    </p>
                    <p>
                        1.2 “License Fee” means the fee which covers the provision of the Services during SaaS Term per Client with a unique license connected to the Service.
                    </p>
                    <p>
                        1.3 “SaaS Term” means the period during which the Services and access to the Software will be provided by Adcredo to Client, including the Initial Term (as each is defined in Section 7.1).
                    </p>
                    <p>
                        1.4 “Services” means the hosting, maintenance, support and other services provided by Adcredo pursuant to these Terms and Conditions.
                    </p>
                    <p>
                        1.5 “SaaS” means Adcredo Software as a Service offering that is based on Adcredo’s proprietary software and provisioned as a service by Adcredo. It can be used to support various different use cases in the area of digital advertising deliveries and reportings. Depending on the use case, the Client can access SaaS using a web browser or API.
                    </p>
                    <p>
                        1.6 “User Documentation” means Adcredo user documentation relating to the SaaS.
                    </p>
                    <p>
                        1.7 “Other Platforms” means the digital advertising companies and their available platforms that the SaaS is connected to, which includes but not limited to, Google, Facebook, Instagram, Bing. These companies and platforms are also shown within the SaaS platform and for Client to select if they want to use or not in connection to Client’s own services.
                    </p>
                    <h3>
                        2. WEB-BASED LICENSE
                    </h3>
                    <p>
                        Adcredo grants to Client, and Client accepts, a non-transferable, non-exclusive license and right to access the SaaS via the Internet and use the SaaS and the User Documentation only as authorized in these Terms and Conditions, for the purpose of enabling its business operations during the SaaS Term. The SaaS will be managed by Adcredo (as described in Section 3) and accessed and used by Client through the use of the Internet or API.
                    </p>
                    <h3>
                        3. ACCESSIBILITY
                    </h3>
                    <p>
                        Adcredo will provide Client with secure access to the latest supported version of the SaaS via the Internet from the hosting facility Adcredo has chosen to use for the Service (the “Hosting Site”) on a 24x7 basis (excludes scheduled downtime), except for scheduled on-going maintenance as required and scheduled in advance by Adcredo.
                    </p>
                    <h3>
                        4. LIMITATIONS
                    </h3>
                    <p>
                        Any Client’s employees using the SaaS accessing Client Content, accessing or using the SaaS are granted to do so. Any other third party or client of Client need to have to have have their own account with a valid license.
                    </p>
                    <h3>
                        5. USER RIGHTS AND LIMITATIONS
                    </h3>
                    <p>
                        By accessing and using the SaaS thru the Service provisioned,
                        <br/>
                        Client will:
                        <br/>
                        • comply with applicable laws
                        <br/>
                        • comply with any codes of conduct or other notices provided by Adcredo;
                        <br/>
                        • keep its password/s and other authentication details secret;
                        <br/>
                        • promptly notify Adcredo if it learns of a security breach or unauthorized access related to the Service.
                    </p>
                    <p>
                        Client may not:
                        <br/>
                        • use the Service in any way that harms Adcredo or its Affiliates, resellers, distributors and/or vendors (collectively, the “Adcredo parties”), or any customer of a Adcredo party or the Service or other Users of the Service;
                        <br/>
                        • engage in, facilitate, or further unlawful conduct;
                        <br/>
                        • damage, disable, overburden or impair the Service (or the networks connected to the Service) or interfere with anyone’s use and enjoyment of the Service;
                        <br/>
                        • resell or redistribute the Service, or any part of the Service, unless Client has a contract with Adcredo that permits it to do so;
                        <br/>
                        • use any unauthorized automated process or service to access and/or use the Service, however, periodic automated access to the Service for report creation or scheduling is permitted;
                        <br/>
                        • use any unauthorized means to modify or reroute, or attempt to modify or reroute, the Service or work around any of the technical limitations in the Service;
                        <br/>
                        • modify, create derivative works from, reverse engineer, decompile or disassemble or otherwise attempt to discover any trade secret contained in the Service or in any technology, or system used by Adcredo in connection with providing the Service, except and only to the extent that applicable law expressly permits Customer to do so despite this limitation;
                        <br/>
                        • copy any ideas, features, functions or graphics of the Service.
                        <br/>
                    </p>
                    <h3>
                        6. SERVICE FEES
                    </h3>
                    <p>
                        In order to access and use the SaaS, Client shall pay a “License Fee” agreed together with the Client and during the SaaS Term. The License Fee is fixed for the Initial Term (defined below). The License Fee for any subsequent Renewal Term (defined below) may be subject adjustment decided by Adcredo at its sole discretion. If License Fees are not paid in accordance with the provisions hereof and any additional terms of payment communicated to Client by Adcredo, all further access to the Service could be blocked without notice.
                    </p>
                    <p>
                        In consideration of the provision of any other services provided by Adcredo to Client, Client shall pay additional fees based on Adcredo then current Service fees.
                    </p>
                    <p>
                        Adcredo shall send to Client an invoice in respect of the License Fee and any Extension Fees at the last day of each month based on impressions delivered during the invoiced month. All invoices are payable within the terms specified in the invoice by Adcredo after the date thereof unless otherwise agreed in writing.
                    </p>
                    <h3>
                        7. TERM AND TERMINATION
                    </h3>
                    <p>
                        7.1 Initial Term
                        The SaaS Term will commence when Adcredo makes the SaaS available to the Client via the Internet as contemplated by Section 2 (“Web-based license”) above. The SaaS Term shall continue in effect until further notice, unless terminated as provided in these Terms and Conditions.
                    </p>
                    <p>
                        7.2 Termination for Breach
                        Notwithstanding Section 7.1, either Client or Adcredo may terminate the SaaS Term as a result of a material breach of these Terms and Conditions by the other party, if (a) such party provides written notification to the other party of the material breach, and (b) such material breach is not resolved within thirty (30) days of notification, or, in the case of a failure to pay fees in a timely manner by Client after a ten (10) day late payment period. For purposes of this Section, a material breach by Adcredo includes a failure to provide at least 98% availability in three (3) consecutive calendar months. If a breach described in the preceding sentence occurs, Client shall have the right to request a compensation of affected campaigns based on the License Fees commensurate with the lack of availability.
                    </p>
                    <p>
                        7.4 Effect of Termination
                        In the event the SaaS Term is terminated by Client for convenience or by Adcredo as a result of a material breach by Client prior to the completion of the Initial Term, Client shall pay Adcredo the remaining balance of License Fees owed in connection to the already started campaign(s) and campaign period. Both parties acknowledge that this payment represents a reasonable estimate of Adcredo’s damages in the event of an early termination. In the event of termination of the SaaS Term for any reason, Client’s access and use of the Software shall cease immediately, and the provisions of Sections 13, 16, 17 and 18 shall survive.
                    </p>
                    <h3>
                        8. MAINTENANCE WINDOWS
                    </h3>
                    <p>
                        Adcredo and/or its hosting may perform system maintenance during the following “Maintenance Windows”, and Adcredo will announce upgrades and all planned outages in advance.
                    </p>
                    <h3>
                        9. AVAILABILITY
                    </h3>
                    <p>
                        Adcredo targets to provide 99.8% “availability” to the Software during the SaaS Term, calculated on a monthly basis. For purposes of these Terms and Conditions, “availability” exists unless the SaaS is not accessible to Client due to (i) a hardware failure of the server at the Hosting Site, or (ii) a connection failure between the server/s hosting the SaaS and the closest Internet router, in to each case excluding Maintenance Windows defined above. Possible software bugs, errors or other problems are not relevant to availability and are addressed under Section 11 below.
                    </p>
                    <h3>
                        10. SUPPORT
                    </h3>
                    <p>
                        Adcredo will provide Technical support via e-mail, online channel and phone, with an average five (4) support requests per month (excluding requests directly related to the Software bugs) to Client during the SaaS Term. Technical support services are provided during business days and hours, excluding national holidays of Sweden. The first reply is provided within one (1) business day, and resolution of reproducible bugs shall be according to severity categorization below.
                        Adcredo will categorize support request as follows:
                    </p>
                    <h4>
                        Urgent priority issues
                    </h4>
                    <p>
                        Failures such as; Software aborts, not able to get any functionality from the Service. An example of urgent priority issue are: Client not being able to access important platform features or create any types of campaigns.
                        Maximum 24-hours for the fix; action will be started immediately and continued uninterruptedly as long as initial fix for the failure is ready.
                    </p>
                    <h4>
                        High priority issues
                    </h4>
                    <p>
                        Issues such as; Software not fully functioning as it should. Example of high priority issue: Only parts of the campaign configuration are being applied to a specific type of ad sets for a specific platform.
                        Maximum 5 business days
                    </p>
                    <h4>
                        Medium priority issues
                    </h4>
                    <p>
                        Minor Software issue or very annoying UX functionality or similar. An example of medium: User journey are being affected by a drop down menu that fills very little functionality but are still mandatory to fill in before being able to save as drafted campaign.
                        To be fixed in next regularly scheduled release
                    </p>
                    <h4>
                        Low priority issues & feature requests
                    </h4>
                    <p>
                        Prioritized based on individual issue and feature requests.
                        May be implemented in a future Software release
                        Support outside of these hours is only provided for down or mission critical cases. For example:
                        Unable to access software
                        The Client may access Adcredo’s support resources in any of the following ways:
                        <br/>
                        • www.adcredo.io
                        <br/>
                        • e-mail: support@adcredo.io
                        <br/>
                        • Tel: your dedicated account manager at Adcredo
                        <br/>
                        Adcredo Support will assist the Client with the following types of issues:
                        <br/>
                        • Problems with or questions about the operation of SaaS or related Services
                        <br/>
                        • Problems with interfaces between the SaaS and third party data sources such as specified under Section 1.7 “Other Platforms”
                        <br/>
                        • Error messages that occur in the context of the SaaS
                    </p>
                    <h3>
                        11. UPGRADES
                    </h3>
                    <p>
                        Adcredo will install software upgrades/releases of the SaaS which are generally made available to its other Clients of the Saas thru the related Service, including patches and/or fixes, as they are made available at no charge during the SaaS Term. Adcredo will determine and announce upgrades as described in Section 8 of these Terms and Conditions.
                    </p>
                    <h3>
                        12. CLIENT RESPONSIBILITIES
                    </h3>
                    <p>
                        Client is responsible for administering and granting of rights to its users and clients using a specific authentication form in the SaaS. Client is also responsible for ensuring that its users and clients comply with these Terms and Conditions with respect to use of the SaaS and related Services. Client shall provide connectivity and security to the Internet for its location(s) for purposes of providing adequate access to the SaaS hosted at the Adcredo Hosting Site.
                        Adcredo shall not be responsible for the reliability or continued availability of the communications lines, or the corresponding security configurations, used by Client in accessing the Internet to access the Software.
                    </p>
                    <h3>
                        13. INTELLECTUAL PROPERTY RIGHTS
                    </h3>
                    <p>
                        Client agrees that the SaaS, and related Services are proprietary products and services and that all right, title and interest in and to the SaaS, related Services, including all associated intellectual property rights, are and shall at all times remain with Adcredo and its third party licensors.
                        The Adcredo software empowering SaaS and related Client installable software modules contain trade secrets and proprietary information owned by Adcredo or its third party licensors and is protected by copyright laws and international trade provisions. Client must treat the SaaS and related Client installable software modules like any other copyrighted material and Client may not copy or distribute related Client software modules, electronically or otherwise, for any purpose.
                        Client hereby grants to Adcredo a non-exclusive right to use all Client Content as necessary solely for the purposes of provisioning the SaaS and related Services to Client and its authorized users pursuant to these Terms and Conditions. In all usage situations all right, title and interest in and to the Client Content remains with the Client.
                    </p>
                    <h3>
                        14. OTHER RESTRICTIONS
                    </h3>
                    <p>
                        Use of the SaaS is restricted to use by the specific licensing entity only, and only in the context of the Client Content. Client may not reverse engineer, disassemble, decompile or make any attempt to ascertain, derive or obtain the source code the SaaS related Client installable software.
                        The SaaS and Client Content shall not be used for any commercial purpose beyond the functionality offered by the SaaS. Except as may be permitted in section 5 consistent with Adcredo’s permissions for the SaaS, the Client hereby agrees, represents and warrants to Adcredo that it will not access or use the SaaS for any purpose that is unlawful or prohibited by these terms, conditions, and notices.
                    </p>
                    <h3>
                        15. WARRANTIES
                    </h3>
                    <p>
                        15.1 Mutual Warranties
                        Each party warrants that (i) it has the right and power to enter into these Terms and Conditions, and (ii) it will comply with any applicable laws and regulations pertaining to these Terms and Conditions.
                    </p>
                    <p>
                        15.2 Adcredo Limited Warranty
                        Adcredo warrants that the provisioning of the SaaS and related Services will be performed in a professional and workmanlike manner in accordance with recognized industry standards.
                    </p>
                    <p>
                        15.3 Remedies
                        If during the Warranty Period the SaaS fails to comply with the Warranties set forth above, Adcredo’s entire liability and Client’s exclusive remedy will be either a) repair or replacement of the SaaS, or if in Adcredo’s opinion such repair or replacement is not possible, then b) termination of the SaaS Term and a compensation of the License Fees paid for the affected campaigns that’s been running within SaaS. This limited warranty is void if failure of the SaaS has resulted from accident, abuse, misuse or negligence of any kind in the use, handling or operation of the Software, including any use not consistent with the Adcredo documents or training. Adcredo's entire liability and Client’s exclusive remedy for any breach of warranty with respect to the Services related to the SaaS as described above shall be Adcredo repeating the Services performed.
                    </p>
                    <p>
                        15.4 Disclaimers
                        Any written or oral information or representations provided by Adcredo agents, employees, resellers, consultants or service providers with respect to the use or operation of the SaaS will in no way increase the scope of Adcredo’s warranty. Adcredo and its suppliers exercise no control whatsoever over the content of the information passing through their systems. Client and users must exercise their own due diligence before distributing and/or relying on information available on the Internet, and must determine that they have all necessary rights to copy, publish, or otherwise distribute such information under copyright and other applicable laws.
                        ADCREDO DOES NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS OBTAINED BY CLIENT IN USING THE SAAS OR RELATED CLIENT INSTALLABLE SOFTWARE, OR THAT THE SAAS OR RELATED CLIENT INSTALLABLE SOFTWARE WILL MEET CLIENT’S REQUIREMENTS OR THAT THE PROVISIONING OF THE SAAS OR RELATED SERVICES WILL BE UNINTERRUPTED OR ERROR FREE. EXCEPT FOR THE WARRANTIES SET FORTH ABOVE, ADCREDO EXPRESSLY DISCLAIMS ALL OTHER REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY (BY ANY TERRITORY OR JURISDICTION) TO THE EXTENT PERMITTED BY LAW, AND FURTHER ADCREDO EXPRESSLY EXCLUDES ANY WARRANTY OF NONINFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR MERCHANTABILITY TO THE EXTENT PERMITTED BY LAW.
                    </p>
                    <h3>
                        16. CONFIDENTIAL INFORMATION
                    </h3>
                    <p>
                        16.1 Definition
                        The term “Confidential Information” shall mean: (i) any and all information which is disclosed by either party (“Owner”) to the other (“Recipient”) verbally, electronically, visually, or in a written or other tangible form which is either identified or should be reasonably understood to be confidential or proprietary; and (ii) the terms, including without limitation, the pricing, of the Services and any proposals or other documents that preceded these Terms and Conditions.
                        Confidential Information may include, but not be limited to, personal information (individual name, address, contact information), organization, trade secrets, computer programs, software, documentation, formulas, data, inventions, techniques, marketing plans, strategies, forecasts, client lists, employee information, financial information, confidential information concerning any of Owner’s past, current or future plans and initiatives, and confidential information concerning Owner’s business or organization, as Owner has conducted it or as Owner may conduct it in the future. In addition, Confidential Information may include information concerning any of Owner’s past, current, or possible future products or methods, including information about Owner’s research, development, engineering, purchasing, manufacturing, accounting, marketing, selling, leasing, and/or software (including third party software).
                    </p>
                    <p>
                        16.2 Treatment of Confidential Information
                        Owner’s Confidential Information shall be treated as strictly confidential by Recipient and shall not be disclosed by Recipient to any third party except to those third parties operating under non- disclosure provisions no less restrictive than in this Section and who have a justified business “need to know”. Client shall protect the deliverables resulting from Services with the same degree of care. These Terms and Conditions impose no obligation upon the parties with respect to Confidential Information which either party can establish by legally sufficient evidence: (a) was in the possession of, or was rightfully known by the Recipient without an obligation to maintain its confidentiality prior to receipt from Owner; (b) is or becomes generally known to the public without violation of these Terms and Conditions; (c) is obtained by Recipient in good faith from a third party having the right to disclose it without an obligation of confidentiality; (d) is independently developed by Recipient without the participation of individuals who have had access to the Confidential Information; or (e) is required to be disclosed by court order or applicable law, provided notice is promptly given to the Owner and provided further that diligent efforts are undertaken to limit disclosure.
                    </p>
                    <p>
                        16.3 Rights and Duties
                        The Recipient shall not obtain, by virtue of these Terms and Conditions, any rights, title, or interest in any Confidential Information of the Owner. Within fifteen (15) days after termination of the SaaS Term all copies of Confidential Information in any form, including partial copies, have been destroyed, returned, or used solely as the Owner so directs.
                    </p>
                    <p>
                        16.4 Publicity
                        Notwithstanding this Section 16 (“Confidentiality”), no prior written approval is required by Adcredo to reference Client as a customer as part of its own sales and marketing activities.
                    </p>
                    <p>
                        16.5 Survival
                        The terms of this Section 16 shall survive termination of the SaaS Term.
                    </p>
                    <h3>
                        17. LIMITATION OF LIABILITY
                    </h3>
                    <p>
                        ADCREDO MAXIMUM LIABILITY FOR ANY ACTION ARISING UNDER THESE TERMS AND CONDITIONS, REGARDLESS OF THE FORM OF ACTION AND WHETHER IN TORT, CONTRACT OR OTHER FORM OF LIABILITY, SHALL IN NO EVENT EXCEED THE FEES PAID BY CLIENT DURING THE TWO-YEAR PERIOD TO ADCREDO OF CLIENT’S LOSS. IN NO EVENT SHALL ADCREDO BE LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION, LOST DATA, LOST PROFITS, OPPORTUNITIES OR CONTRIBUTIONS, LOSS OF USE, GOOD WILL, BUSINESS INTERRUPTION, COST OF COVER, OR OTHER PECUNIARY OR NON- PECUNIARY LOSS, HOWEVER ARISING, EVEN IF ADCREDO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ADCREDO SHALL HAVE NO LIABILITY OR RESPONSIBILITY IN THE EVENT OF ANY LOSS OR INTERRUPTION IN ACCESS TO THE SAAS OR RELATED CLIENT INSTALLABLE SOFTWARE DUE TO CAUSES BEYOND ITS REASONABLE CONTROL OR FORESEEABILITY, SUCH AS LOSS, INTERRUPTION OR FAILURE OF DIGITAL TRANSMISSIONS AND LINKS, INTERNET SLOWDOWN OR FAILURES. THE PARTIES AGREE TO THE ALLOCATION OF RISK SET FORTH HEREIN.
                    </p>
                    <h3>
                        18. FORCE MAJEURE
                    </h3>
                    <p>
                        Except for Client’s obligation to pay Adcredo, neither party shall be liable for any failure to perform its obligations under these Terms and Conditions if prevented from doing so by a cause or causes beyond its control, including without limitation, failure of suppliers to perform, fire, floods, storms, epidemic or quarantine restrictions, earthquakes, riots or civil commotion, strikes, war, and restraints of government freight or other embargoes, weather conditions or any failures by Adcredo’s subcontractors or suppliers.
                    </p>
                    <h3>
                        19. NOTICES
                    </h3>
                    <p>
                        All notices or other communications referenced under these Terms and Conditions shall be made in writing and sent to legal@adcredo.io or post address mentioned at www.adcredo.io. All notices shall be deemed given to the other party if delivered receipt confirmed using one of the following methods: registered or certified first class mail, postage prepaid; recognized courier delivery; or electronic mail.
                    </p>
                    <h3>
                        20. GOVERNING LAW; ARBITRATION
                    </h3>
                    <p>
                        These Terms and Conditions shall be governed by the laws of Sweden. Any dispute, controversy or claim arising out of or relating to this contract, or the breach, termination or validity thereof, shall be finally settled by arbitration in accordance with the Rules for Stockholm Chamber of Commerce Arbitration Institute. Any decision in arbitration shall be final and binding upon the parties. Notwithstanding the above, Adcredo may sue in any court for infringement of its proprietary or intellectual property rights.
                    </p>
                    <h3>
                        21. MISCELLANEOUS
                    </h3>
                    <p>
                        Except as otherwise specifically stated herein, remedies shall be cumulative and there shall be no obligation to exercise a particular remedy. If any provision of these Terms and Conditions are held to be unenforceable, the other provisions shall nevertheless remain in full force and effect. The failure by either party to enforce any rights granted hereunder or to take action against the other party in the event of any breach of these Terms and Conditions will not be deemed a waiver by that party as to the subsequent enforcement of rights or subsequent actions in the event of future breaches. These Terms and Conditions set forth the entire agreement between the parties with respect to the subject matter hereof and all other agreements, representations, communications and understandings, both oral and written, are superseded hereby.
                    </p>
                </div>
            </div>
        )
    }
}

export default TermsOfService;
