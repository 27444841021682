import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from 'feather-icons-react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { tokenRegister } from "../../services/tokenRegister";
import { userRegister } from "../../services/userRegister";
import { apiRegister } from '../../services/apiRegister';
import { clientRegister } from '../../services/clientRegister';

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            submenu: []
        }
    };

    componentWillMount() {
        this.init.menu();
        this.init.submenu();
    }

    componentDidMount() {
    }

    init = {
        menu: () => {
            let menu = [];
            if (userRegister.get().type === 'admin') {
                menu = [
                    { title: "Dashboard", link: "/user/dashboard", active: "/user/dashboard" },
                    { title: "Users", link: "/user/users/all", active: "/user/users" },
                    { title: "Agencies", link: "/user/agencies/all", active: "/user/agencies" },
                    { title: "Regions", link: "/user/regions/all", active: "/user/regions" }
                ];
            } else if (userRegister.get().type === 'agent') {
                menu = [
                    { title: "Dashboard", link: "/user/dashboard", active: "/user/dashboard" },
                    { title: "Campaigns", link: "/user/campaigns/active", active: "/user/campaigns" },
                    { title: "Clients", link: "/user/clients/all", active: "/user/clients" },
                    { title: "Reports", link: "/user/reports/all", active: "/user/reports" },
                    { title: "Optimize", link: "/user/optimize/all", active: "/user/optimize" }
                ];
            } else {
                menu = [
                    { title: "Users", link: "/user/users/all", active: "/user/users" },
                    { title: "Campaigns", link: "/user/campaigns/active", active: "/user/campaigns" }
                ];
            }
            this.setState({
                menu: menu
            });
        },
        submenu: () => {
            let submenu = [];
            if (userRegister.get().type === 'admin') {
                submenu = [
                    {
                        title: "All users",
                        parent: "/user/users",
                        link: "/user/users/all",
                        active: "/user/users/all"
                    },
                    {
                        title: "Create new",
                        parent: "/user/users",
                        link: "/user/users/new",
                        active: "/user/users/new",
                        new: true
                    },
                    {
                        title: "Create new",
                        parent: "/user/regions",
                        link: "/user/regions/new",
                        active: "/user/regions/new",
                        new: true
                    },
                    {
                        title: "All",
                        parent: "/user/agencies",
                        link: "/user/agencies/all",
                        active: "/user/agencies/all"
                    },
                    {
                        title: "Create new",
                        parent: "/user/agencies",
                        link: "/user/agencies/new",
                        active: "/user/agencies/new",
                        new: true
                    }
                ];
            } else if (userRegister.get().type === 'agent') {
                submenu = [
                    {
                        title: "All",
                        parent: "/user/clients",
                        link: "/user/clients/all",
                        active: "/user/clients/all"
                    },
                    {
                        title: "Create new",
                        parent: "/user/clients",
                        link: "/user/clients/new",
                        active: "/user/clients/new",
                        new: true
                    },
                    {
                        title: "Active",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/active",
                        active: "/user/campaigns/active"
                    },
                    {
                        title: "Upcoming",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/upcoming",
                        active: "/user/campaigns/upcoming"
                    },
                    {
                        title: "Pending",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/pending",
                        active: "/user/campaigns/pending"
                    },
                    {
                        title: "Paused",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/paused",
                        active: "/user/campaigns/paused"
                    },
                    {
                        title: "Drafted",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/drafted",
                        active: "/user/campaigns/drafted"
                    },
                    {
                        title: "Ended",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/ended",
                        active: "/user/campaigns/ended"
                    },
                    {
                        title: "Create new",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/new",
                        active: "/user/campaigns/new",
                        new: true
                    },
                    {
                        title: "All",
                        parent: "/user/users",
                        link: "/user/users/all",
                        active: "/user/users/all"
                    },
                    {
                        title: "Create new",
                        parent: "/user/users",
                        link: "/user/users/new",
                        active: "/user/users/new",
                        new: true
                    },
                    {
                        title: "Billing",
                        parent: "/user/profile/billing",
                        link: "/user/profile/billing",
                        active: "/user/profile/billing"
                    },
                    {
                        title: "Password",
                        parent: "/user/profile/password",
                        link: "/user/profile/password",
                        active: "/user/profile/password"
                    },
                    {
                        title: "Profile",
                        parent: "/user/profile/account",
                        link: "/user/profile/account",
                        active: "/user/profile/account"
                    },
                    {
                        title: "Create new",
                        parent: "/user/reports",
                        link: "/user/reports/all",
                        active: "/user/reports/all",
                        new: true
                    },
                    {
                        title: "Create new",
                        parent: "/user/optimize",
                        link: "/user/optimize/all",
                        active: "/user/optimize/all",
                        new: true
                    },
                    {
                        title: "Overview",
                        parent: "/user/dashboard",
                        link: "/user/dashboard",
                        active: "/user/dashboard"
                    }
                ];
            } else {
                submenu = [
                    {
                        title: "All",
                        parent: "/user/users",
                        link: "/user/users/all",
                        active: "/user/users/all"
                    },
                    {
                        title: "Create new",
                        parent: "/user/users",
                        link: "/user/users/new",
                        active: "/user/users/new",
                        new: true
                    },
                    {
                        title: "Active",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/active",
                        active: "/user/campaigns/active"
                    },
                    {
                        title: "Upcoming",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/upcoming",
                        active: "/user/campaigns/upcoming"
                    },
                    {
                        title: "Paused",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/paused",
                        active: "/user/campaigns/paused"
                    },
                    {
                        title: "Ended",
                        parent: "/user/campaigns",
                        link: "/user/campaigns/ended",
                        active: "/user/campaigns/ended"
                    },
                    {
                        title: "All",
                        parent: "/user/requests",
                        link: "/user/requests/all",
                        active: "/user/requests/all"
                    }
                ];
            }
            this.setState({
                submenu: submenu
            });
        }
    };

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        }
    };

    renders = {
        firstLetter: () => {
            try {
                return userRegister.get().name.charAt(0);
            } catch (e) {
                return ''
            }
        }
    };

    render() {
        return (
            <div className="Menu">
                <div className="Menu-Top">
                    <div className="Menu-TopStart">
                        {
                            !userRegister.get().logo &&
                            <div className="Dashboard-MenuStartLogoText">
                                <img src={require('../../assets/images/large_logo_icon_white.png')}
                                    className="Dashboard-MenuStartLogoTextImageMain" />
                            </div>
                        }
                        {
                            userRegister.get().logo &&
                            <img src={userRegister.get().logo} className="Dashboard-MenuStartLogoTextImage" />
                        }
                    </div>
                    <div className="Dashboard-MenuItems">
                        {
                            this.state.menu.map((item) => {
                                return (
                                    <div onClick={() => {
                                        this.props.history.push(item.link);
                                    }} className="Dashboard-MenuItem">
                                        {
                                            false &&
                                            <div onClick={() => {
                                                this.props.history.push(item.link);
                                            }} className={
                                                cx("Dashboard-MenuItemInner",
                                                    {
                                                        //["Dashboard-MenuItemInnerActive"]: window.location.href.indexOf(item.active) !== -1,
                                                    })
                                            } />
                                        }
                                        {
                                            <div className={
                                                cx("Dashboard-MenuItemInnerText",
                                                    {
                                                        ["Dashboard-MenuItemInnerTextActive"]: window.location.href.indexOf(item.active) !== -1,
                                                    })
                                            }>{item.title}</div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="Dashboard-MenuButtons">
                        <div className="Dashboard-MenuButtonsUser">
                            <div className="Dashboard-MenuButtonsUserName">
                                {userRegister.get().name}
                            </div>
                            <div className="Dashboard-MenuButtonsUserEmail">
                                {userRegister.get().email}
                            </div>
                        </div>
                        <div className="Dashboard-MenuButtonsUserIcon">
                            {this.renders.firstLetter()}
                            <input className="Dashboard-MenuButtonsUserSettingsInput"
                                onMouseDown={() => {
                                    this.setState({
                                        user_dropdown: !this.state.user_dropdown
                                    })
                                }}
                                onFocus={() => {
                                    this.setState({
                                        user_dropdown: true
                                    })
                                }}
                                onBlur={() => {
                                    this.setState({
                                        user_dropdown: false
                                    })
                                }}
                            />
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsTriangle"></div>
                            }
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsTriangleWhite"></div>
                            }
                            {
                                this.state.user_dropdown &&
                                <div className="Dashboard-MenuButtonsUserSettingsDropdown">
                                    <div onMouseDown={() => {
                                        this.props.history.push('/user/profile/account');
                                    }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                        Profile
                                    </div>
                                    <div onMouseDown={() => {
                                        this.props.history.push('/user/profile/password');
                                    }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                        Update password
                                    </div>
                                    <div onMouseDown={() => {
                                        this.functions.logout()
                                    }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                        Logout
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            userRegister.get().type === 'agent' &&
                            <div className="Dashboard-MenuButtonsUserSettings">
                                <input className="Dashboard-MenuButtonsUserSettingsInput"
                                    onMouseDown={() => {
                                        this.setState({
                                            settings_dropdown: !this.state.settings_dropdown
                                        })
                                    }}
                                    onFocus={() => {
                                        this.setState({
                                            settings_dropdown: true
                                        })
                                    }}
                                    onBlur={() => {
                                        this.setState({
                                            settings_dropdown: false
                                        })
                                    }}
                                />
                                <FeatherIcon color={"#333333"} size={19} icon="settings" />
                                {
                                    this.state.settings_dropdown &&
                                    <div className="Dashboard-MenuButtonsUserSettingsTriangle"></div>
                                }
                                {
                                    this.state.settings_dropdown &&
                                    <div className="Dashboard-MenuButtonsUserSettingsTriangleWhite"></div>
                                }
                                {
                                    this.state.settings_dropdown &&
                                    <div className="Dashboard-MenuButtonsUserSettingsDropdown">
                                        {
                                            userRegister.get().type === 'agent' &&
                                            <div onMouseDown={() => {
                                                this.props.history.push('/user/users/all');
                                            }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                                Users
                                            </div>
                                        }
                                        {
                                            userRegister.get().type === 'agent' &&
                                            <div onMouseDown={() => {
                                                this.props.history.push('/user/profile/billing');
                                            }} className="Dashboard-MenuButtonsUserSettingsDropdownItem">
                                                Billing
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="Dashboard-SubMenu">
                    <div className="Dashboard-SubMenuInner">
                        {
                            this.state.submenu.filter((item) => {
                                return window.location.href.indexOf(item.parent) !== -1
                            }).map((item) => {
                                return (
                                    <div onClick={() => {
                                        this.props.history.push(item.link);
                                    }} className="Dashboard-SubMenuItem">
                                        <div className={
                                            cx("Dashboard-SubMenuItemText",
                                                {
                                                    ["Dashboard-SubMenuItemActiveText"]: window.location.href.indexOf(item.active) !== -1
                                                })
                                        }>
                                            <div className={
                                                cx({
                                                    ["Dashboard-SubMenuItemNew"]: item.new && window.location.href.indexOf(item.active) === -1
                                                })
                                            }>
                                                {item.title}
                                            </div>
                                        </div>
                                        {
                                            window.location.href.indexOf(item.active) !== -1 &&
                                            <div className="Dashboard-SubMenuItemActiveBorder" />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;
