import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from 'react-spinners';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import {calls} from "./calls";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import {campaignRegister} from "../../services/campaignRegister";

class AdvertisingCreativesAdsItemCreativeCarouselUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            client: {},
            site: {},
            channels: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items
        });
    }

    componentDidMount() {
        this.setState({
            items: this.props.items
        });
        this.init.data();
    }

    init = {
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
            })
        }
    };

    functions = {
        uploadImage: (image) => {

            const formData = new FormData();
            formData.append('file', image);

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let slide = {
                id: id,
                headline: "",
                description: "",
                website: this.state.site.url,
                creative: {
                    url: "",
                    format: "",
                    ratio: "",
                    channels: {}
                },
                loading: true,
                image: true,
                video: false,
                error: false,
                call_to_action: {name: 'No call to action', value: 'NO_BUTTON'},
            };

            try{
                slide.headline = this.state.creatives.carousel_title;
                slide.description = this.state.creatives.carousel_description;
                if(this.state.creatives.call_to_action){
                    slide.call_to_action = this.state.creatives.call_to_action;
                }
            }catch (e) {}

            this.state.items.push(slide);
            this.props.onChange(this.state.items);

            calls.uploadImage(formData).then((response) => {
                for(let i = 0; i < this.state.items.length; i++){
                    if(this.state.items[i].id === slide.id){
                        this.state.items[i].loading = false;
                        if(response.data.ratio === '1:1'){
                            this.state.items[i].creative = {
                                url: response.data.url,
                                format: response.data.format,
                                ratio: response.data.ratio,
                                channels: response.data.channels?response.data.channels:{}
                            };
                        }else{
                            this.state.items[i].error = true;
                            this.state.items[i].error_message = 'Wrong aspect ratio, must be 1:1';
                        }
                        break;
                    }
                }
                this.props.onChange(this.state.items);
            }, (error) => {
                for(let i = 0; i < this.state.items.length; i++){
                    if(this.state.items[i].id === slide.id){
                        this.state.items[i].loading = false;
                        this.state.items[i].error = true;
                        this.state.items[i].error_message = 'Something went wrong';
                        break;
                    }
                }
                this.props.onChange(this.state.items);
            });

        },
        uploadVideo: (video) => {

            const formData = new FormData();
            formData.append('file', video);

            let id = Math.floor((Math.random() * 9999999999) + 1);
            let slide = {
                id: id,
                headline: "",
                description: "",
                website: this.state.site.url,
                creative: {
                    url: "",
                    format: "",
                    ratio: "",
                    channels: {}
                },
                loading: true,
                image: false,
                video: true,
                call_to_action: {name: 'No call to action', value: 'NO_BUTTON'},
            };

            this.state.items.push(slide);
            this.props.onChange(this.state.items);

            let channels = Object.keys(this.state.channels).filter((key) => {
                return this.state.channels[key]
            });
            channels = channels.join(',');

            calls.uploadVideo(formData, channels, this.state.client.id).then((response) => {
                for(let i = 0; i < this.state.items.length; i++){
                    if(this.state.items[i].id === slide.id){
                        this.state.items[i].loading = false;
                        if(response.data.ratio === '1:1'){
                            this.state.items[i].creative = {
                                url: response.data.url,
                                format: response.data.format,
                                ratio: response.data.ratio,
                                channels: response.data.channels?response.data.channels:{}
                            };
                        }else{
                            this.state.items[i].error = true;
                            this.state.items[i].error_message = 'Wrong aspect ratio, must be 1:1';
                        }
                        break;
                    }
                }
                this.props.onChange(this.state.items);
            }, (error) => {
                for(let i = 0; i < this.state.items.length; i++){
                    if(this.state.items[i].id === slide.id){
                        this.state.items[i].loading = false;
                        this.state.items[i].error = true;
                        this.state.items[i].error_message = 'Something went wrong';
                        break;
                    }
                }
                this.props.onChange(this.state.items);
            });

        },
        onSelectFile: (e) => {
            if (e.target.files && e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    if(e.target.files[i].size < 100000000){
                        if (e.target.files[i].type === 'video/mp4') {
                            this.functions.uploadVideo(e.target.files[i]);
                        } else if (e.target.files[i].type === 'image/jpg' || e.target.files[i].type === 'image/jpeg' || e.target.files[i].type === 'image/png') {
                            this.functions.uploadImage(e.target.files[i]);
                        }
                    }
                }
                const randomString = Math.random().toString(36);
                this.setState({
                    theInputKeyOne: randomString + 1
                });
            }
        }
    };

    renders = {};

    render() {
        return (
            <input
                onChange={(e) => this.functions.onSelectFile(e)}
                type="file"
                multiple
                accept={(this.state.channels.facebook || this.state.channels.instagram)?"image/png, image/jpg, image/jpeg, video/mp4":"image/png, image/jpg, image/jpeg"}
                className="Advertising-creative-carousel-upload"
            />
        )
    }
}

export default AdvertisingCreativesAdsItemCreativeCarouselUpload;
