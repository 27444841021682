import { text } from "body-parser";
import React, { Component } from "react";
import SuccessModal from "../moduleFiles/successModal";
import { apiRegister } from "../services/apiRegister";

class SetPassword extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm_password: "",
      token: "",
      loading: false,
      success_modal: false,
      error_modal: false,
      error_message: "Passwords not matching",
    };
  }

  componentDidMount() {
    this.setState({
      token: this.props.match ? this.props.match.params.token : null,
    });
  }

  init = {
    token: () => {
      try {
        this.setState({ token: this.props.match.params.token });
      } catch (e) { }
    },
  };

  functions = {
    setPassword: () => {
      if (!this.state.loading) {
        this.setState({ loading: true }, () => {
          this.calls
            .setPassword({
              password: this.state.password,
              confirm_password: this.state.confirm_password,
              token: this.state.token,
            })
            .then(
              (response) => {
                this.setState({
                  success_modal: true,
                  loading: false,
                  password: "",
                  confirm_password: "",
                });
              },
              (error) => {
                this.setState({
                  error_modal: true,
                  loading: false,
                  error_message: error.body.message,
                });
              }
            );
        });
      }
    },
  };

  calls = {
    setPassword: (data) => {
      let options = apiRegister.options(null, "POST", data);
      let url = apiRegister.url.api + "/v3/adcredo/resetPassword";
      return apiRegister.call(options, url);
    }
  };

  renders = {};

  render() {
    return (
      <>
        <SuccessModal
          title={"Success!"}
          text={"Your password is updated"}
          button={"Go to login"}
          open={this.state.success_modal}
          onClose={(button_click) => {
            this.setState(
              {
                success_modal: false,
              },
              () => {
                this.props.history.push("/login");
              }
            );
          }}
        />

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={require("../assets/images/adcredo_icon_purple.png")}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Set your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Please type your new password below
            </p>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(event) => {
                        this.setState({
                          password: event.target.value,
                        });
                      }}
                      value={this.state.password}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm password
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(event) => {
                        this.setState({
                          confirm_password: event.target.value,
                        });
                      }}
                      value={this.state.confirm_password}
                      id="confirm_password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div class="pt-5">
                    {this.state.error_modal && (
                      <label
                        htmlFor="password_not_matching"
                        className="block text-sm font-medium text-red-700 text-center"
                      >
                        {this.state.error_message}
                      </label>
                    )}
                  </div>
                  <div></div>
                </div>

                <div>
                  <button
                    onClick={() => {
                      this.functions.setPassword();
                    }}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {this.state.loading && (
                      <div>
                        <div
                          style={{ borderTopColor: "transparent" }}
                          class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"
                        ></div>
                      </div>
                    )}
                    {!this.state.loading && <div>Set</div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SetPassword;
