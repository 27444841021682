class adgroupFacebook {

    static validate(adgroup) {
        let error = {
            name: false,
            destination_type: false,
            beneficiary: false,
            payor: false,
            budget: false,
            location: false,
            start_date: false,
            end_date: false,
            app: false,
            page: false,
            placements: false,
        };
        if (adgroup.campaign_budget_optimization && !adgroup.campaign_budget_optimization.value) {
            if (adgroup.budget_type.value === "total" && (adgroup.lifetime_budget === "" || parseInt(adgroup.lifetime_budget) < 1 || isNaN(parseInt(adgroup.lifetime_budget)))) {
                error.budget = true;
            } else if (adgroup.budget_type.value === "daily" && (adgroup.daily_budget === "" || parseInt(adgroup.daily_budget) < 1 || isNaN(parseInt(adgroup.daily_budget)))) {
                error.budget = true;
            }
        }
        if (!adgroup.locations || adgroup.locations.filter((item) => { return item.included }).length < 1) {
            error.location = true;
        }
        if (adgroup.start_date && !adgroup.status) {
            if (new Date(adgroup.start_date).getTime() <= new Date().getTime()) {
                error.start_date = true;
            }
        }
        if (adgroup.end_date && !adgroup.status) {
            if (new Date(adgroup.end_date).getTime() <= new Date(adgroup.start_date).getTime() || new Date(adgroup.end_date).getTime() <= new Date().getTime()) {
                error.end_date = true;
            }
        }
        if (adgroup.campaign_objective && adgroup.campaign_objective.id === 5) {
            if (adgroup.application.id === 0) {
                error.app = true;
            } else {
                if (!adgroup.store || adgroup.store.id === 0) {
                    error.app = true;
                }
            }
        }
        if (!adgroup.name || adgroup.name === "") {
            error.name = true;
        }
        if (adgroup.auto_placements && adgroup.auto_placements.id === 2 && adgroup.platforms.filter((item) => { return item.selected }).length < 1) {
            error.placements = true;
        }

        if (!adgroup.status) {
            if (!adgroup.beneficiary || adgroup.beneficiary === "") {
                error.beneficiary = true;
            }
            if (!adgroup.payor || adgroup.payor === "") {
                error.payor = true;
            }
        }
        if (adgroup && adgroup.campaign_objective && adgroup.campaign_objective.id !== 1 && adgroup.campaign_objective.id !== 5) {
            if (!adgroup.destination_type || adgroup.destination_type.id === 0) {
                error.destination_type = true;
            }
            if (adgroup.destination_type && adgroup.destination_type.id === 2) {
                if (!adgroup.application || adgroup.application.id === 0) {
                    error.app = true;
                } else {
                    if (!adgroup.store || adgroup.store.id === 0) {
                        error.app = true;
                    }
                }
            }
            if (adgroup.destination_type && adgroup.destination_type.id === 8) {
                if (!adgroup.page || adgroup.page.id === 0) {
                    error.page = true;
                }
            }
        }
        return error;
    }
}
export { adgroupFacebook }