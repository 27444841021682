import React, { Component } from 'react';
import { DotsHorizontalIcon, GlobeIcon } from '@heroicons/react/solid'
import { ThumbUpIcon, AnnotationIcon, ShareIcon, XIcon } from '@heroicons/react/outline';

class PreviewFacebookCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            slides: {},
            cta: {},
            adData: {},
            noCta: false,
            body: "",
            read_more: false,
            share: false,
            for_report: false,
            is_post: false,
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            page: this.props.page,
            slides: this.props.slides,
            cta: this.props.cta,
            body: this.props.body,
            adData: this.props.adData,
            noCta: this.props.noCta,
            for_report: this.props.for_report,
            is_post: this.props.is_post,
            share: this.props.share,
        })

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200)

        try {
            const slider = document.querySelector('#carousel_container');
            let isDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        } catch (e) {

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            slides: nextProps.slides,
            cta: nextProps.cta,
            body: nextProps.body,
            adData: nextProps.adData,
            noCta: nextProps.noCta,
            for_report: nextProps.for_report,
            is_post: nextProps.is_post,
        })
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.state.cta.value === 'NO_BUTTON') {
                    return null
                } else {
                    return this.state.cta.name;
                }
            } catch (e) {
                return null
            }
        }
    };


    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                <div
                    className='flex flex-col w-80 rounded-lg border bg-white'>
                    <div className='flex justify-between items-center px-2 py-3 bg-white rounded-t-lg'>
                        <div className='flex flex-1'>
                            <div className='mr-2 h-10 w-10'>
                                {
                                    this.state.page && this.state.page.image &&
                                    <img src={this.state.page.image}
                                        className="rounded-full"
                                        alt={this.state.page.name}
                                    />}
                                {
                                    this.state.page && !this.state.page.image &&
                                    <div className="w-10 h-10 border rounded-full"></div>
                                }
                            </div>
                            <div className='flex flex-col flex-1'>
                                <div className='text-sm'><span className='font-semibold'>{this.state.page ? this.state.page.name : "Page name"}</span> shared a <span className='font-semibold'>post</span></div>
                                <div className='flex text-xs text-gray-600'>Sponsored <span className='mx-1'>&#183;</span><GlobeIcon style={{ width: 12 }} /></div>
                            </div>
                        </div>
                        <div className='flex flex-col self-start'>
                            <div className='flex'>
                                <DotsHorizontalIcon className="mr-2 w-5" />
                                <XIcon className="w-5" />
                            </div>
                            <div classname="flex flex-1"></div>
                        </div>
                    </div>
                    <div className='bg-white px-2 pb-1 text-sm'>
                        {
                            this.state.body !== "" ?
                                <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="text-sm whitespace-pre-wrap">

                                </span>
                                :
                                "Body..."
                        }
                        {
                            !this.state.read_more &&
                            this.renders.body().length > 125 &&
                            <span
                                onClick={async () => {
                                    await this.promisedSetState({
                                        read_more: true
                                    });
                                }} className="cursor-pointer font-semibold text-sm text-center pb-1">
                                {" "}See more
                            </span>
                        }
                    </div>
                    {
                        // FACEBOOK / FACEBOOK SLIDE TOTAL =  2 OR 4
                        this.state.adData && this.state.adData.facebook_post && !this.state.share &&
                        Array.isArray(this.state.slides) && (this.state.slides.length === 2 || this.state.slides.length === 4) &&
                        <div className='grid grid-cols-2 gap-1'>
                            {
                                this.state.slides.map((slide, index) => {
                                    return (
                                        <div key={index} className="flex justify-center items-center">
                                            {
                                                slide.type === "image" &&
                                                <img src={slide.picture} className='' />
                                            }
                                            {
                                                slide.type === "video" &&
                                                <video src={slide.url ? slide.url : slide.picture} autoplay={false} controls={false} loop muted ref="video" />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    {
                        // FACEBOOK / FACEBOOK SLIDE TOTAL = 3
                        this.state.adData && this.state.adData.facebook_post && !this.state.share &&
                        Array.isArray(this.state.slides) && this.state.slides.length === 3 &&
                        <div className='grid grid-cols-2 gap-1'>
                            <div className='col-span-2'>
                                {
                                    this.state.slides[0].type === "image" &&
                                    <img src={this.state.slides[0].picture} className='' />
                                }
                                {
                                    this.state.slides[0].type === "video" &&
                                    <video src={this.state.slides[0].url ? this.state.slides[0].url : this.state.slides[0].picture} autoplay={false} controls={true} loop muted ref="video" />
                                }
                            </div>
                            {
                                JSON.parse(JSON.stringify(this.state.slides)).slice(1).map((slide, index) => {
                                    return (
                                        <div key={index} className="flex justify-center items-center col-span-1">
                                            {
                                                slide.type === "image" &&
                                                <img src={slide.picture} className='' />
                                            }
                                            {
                                                slide.type === "video" &&
                                                <video src={slide.url ? slide.url : slide.picture} autoplay={false} controls={false} loop muted ref="video" />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        // FACEBOOK / FACEBOOK SLIDE TOTAL = 5 OR 5+
                        this.state.adData && this.state.adData.facebook_post && !this.state.share &&
                        Array.isArray(this.state.slides) && this.state.slides.length > 5 &&
                        <>
                            <div className='grid grid-cols-2 gap-1'>
                                <div className='col-span-1'>
                                    {
                                        this.state.slides[0].type === "image" &&
                                        <img src={this.state.slides[0].picture} className='' />
                                    }
                                    {
                                        this.state.slides[0].type === "video" &&
                                        <video src={this.state.slides[0].url ? this.state.slides[0].url : this.state.slides[0].picture} autoplay={false} controls={false} loop muted ref="video" />
                                    }
                                </div>
                                <div className='col-span-1'>
                                    {
                                        this.state.slides[1].type === "image" &&
                                        <img src={this.state.slides[1].picture} className='' />
                                    }
                                    {
                                        this.state.slides[1].type === "video" &&
                                        <video src={this.state.slides[1].url ? this.state.slides[1].url : this.state.slides[1].picture} autoplay={false} controls={true} loop muted ref="video" />
                                    }
                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-1 pt-1'>
                                {
                                    JSON.parse(JSON.stringify(this.state.slides)).slice(2).map((slide, index) => {
                                        if (index >= 3) return;
                                        return (
                                            <div key={index} className={`flex justify-center items-center ${index === 2 ? "relative" : ""}`}>
                                                {
                                                    slide.type === "image" &&
                                                    <img src={slide.picture} className='' />
                                                }
                                                {
                                                    slide.type === "video" &&
                                                    <video src={slide.url ? slide.url : slide.picture} autoplay={false} controls={true} loop muted ref="video" />
                                                }
                                                {
                                                    this.state.slides.length > 5 && index === 2 &&
                                                    <div className='absolute flex items-center justify-center bg-black bg-opacity-50 h-full w-full'>
                                                        <span className='text-white text-2xl font-semibold'>+ {this.state.slides.length - 4}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </>
                    }
                    {
                        this.state.adData && (this.state.share || !this.state.adData.facebook_post) &&
                        Array.isArray(this.state.slides) && this.state.slides.length > 0 &&
                        <div
                            id={"carousel_container"}
                            className='flex w-full space-x-2 overflow-x-auto px-1 pb-1 cursor-pointer select-none'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 &&
                                this.state.slides.map((slide, index) => {
                                    return (
                                        <span key={index}
                                            className='flex flex-col flex-1 min-w-68 min-h-68 rounded-t'>
                                            {slide.type === "image" &&
                                                <img src={slide.picture} alt={slide.name ? slide.name : "Alt text"}
                                                    className="w-full overflow-hidden rounded-t"
                                                    style={!this.state.adData.files ? { height: 272, objectFit: "contain" } : (this.state.adData.files && ((this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16") || (this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio !== "1:1"))) ?
                                                        { height: 272, objectFit: `${this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16" ? "cover" : "contain"}` }
                                                        : { maxHeight: 272, height: "100%" }}
                                                />
                                            }
                                            {slide.type === "video" &&
                                                <video src={slide.url} autoplay={false} controls={true} loop muted ref="video"
                                                    className="h-full w-full overflow-hidden rounded-t"
                                                    style={
                                                        !this.state.adData.files ? { height: 272, objectFit: "contain" } : (this.state.adData.files && ((this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio === "9:16") || (this.state.adData.files && this.state.adData.files[index] && this.state.adData.files[index].ratio !== "1:1"))) ?
                                                            { height: 272, objectFit: "cover" } : { height: "100%" }}
                                                />
                                            }
                                            <div className='flex flex-grow p-2 bg-gray-200 rounded-b'>
                                                <div className={`flex flex-col space-y-2 ${!this.state.noCta ? "w-3/5" : "w-full"}`}>
                                                    <div className={`text-sm font-semibold pr-1 ${this.state.is_post && "hidden"}`}>
                                                        {slide.title ? this.functions.truncText(slide.title, 40) : "Headline..."}
                                                    </div>
                                                    <div className='truncate pr-2 text-xs'>{slide.body}</div>
                                                </div>
                                                {
                                                    !this.state.noCta &&
                                                    <>
                                                        <div className='flex flex-col w-2/5 ml-auto'>
                                                            <div className="py-2 text-sm text-center font-semibold rounded bg-gray-300">
                                                                {
                                                                    //!this.state.is_post &&
                                                                    !(slide.cta && slide.cta.name) &&
                                                                    <span>{this.state.cta ? this.state.cta.name : ""}</span>
                                                                }
                                                                {
                                                                    (slide.cta && slide.cta.name) &&
                                                                    //this.state.is_post &&
                                                                    <span>{slide.cta && slide.cta.name ? slide.cta.name : ""}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </span>
                                    )
                                }
                                )
                            }
                        </div >
                    }
                    <div className='pt-4 pb-3 bg-white rounded-b-lg'>
                        <div className='flex justify-around text-xms text-gray-600 font-bold border-t pt-3 mx-2 border-gray-300 rounded-b-lg'>
                            <div className='flex items-center'><span><ThumbUpIcon className='w-5 mr-1' /></span>Like</div>
                            <div className='flex items-center'><span><AnnotationIcon className='w-5 mr-1' /></span>Comment</div>
                            <div className='flex items-center'><span><ShareIcon className='w-5 mr-1' /></span>Share</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewFacebookCarousel;
