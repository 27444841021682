import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, ExclamationCircleIcon, OfficeBuildingIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { clientRegister } from '../services/clientRegister';
import CreateAgency from '../moduleFiles/createAgency';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import AgencyMonthlySpend from '../moduleFiles/agencyMonthlySpend';
import TableHeadTailwind from '../moduleFiles/tableHeadTailwind';

class AgencyListNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            orders: [],
            order: { id: 0, name: "Filter by order" },
            drafts: [],
            campaigns: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'created', value: 'createdAt', min_width: '150px' },
                { name: 'disabled', value: 'disabledAt', min_width: '150px', noSort: true },
                { name: 'admin agency', value: 'adminAccess', min_width: '170px' },
                { name: 'status', value: 'status', min_width: '150px' },
                { name: 'type', value: 'test', min_width: '150px', noSort: true },
                { name: 'agents', value: 'agents', min_width: '150px', noSort: true },
                { name: 'clients', value: 'clients', min_width: '150px', noSort: true },
                { name: 'orders', value: 'orders', min_width: '150px', noSort: true },
                { name: 'campaigns', value: 'campaigns', min_width: '150px', noSort: true }
            ],
            status: {},
            stats: [],
            client: {},
            filters: [],
            search: "",
            throttling: {},
            create_agency: false,

        }
    };

    componentDidMount() {
        this.functions.wizards(true);
        // this.functions.getColumns()
    }

    functions = {
        wizards: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.wizards();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        campaigns: response.data,
                        meta: response.meta
                    });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
        },
        removeWarnings: async (row, trigger) => {
            let temp = JSON.parse(JSON.stringify(row));
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.removeWarnings(temp.id, trigger);
            } catch (error) { }
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
        },
        menu: (option, row) => {
            if (option === "Edit") {
                this.setState({
                    create_agency: row
                });
            } else if (option === "List agents") {
                this.props.history.push("/admin/agents?agency=" + (row.id ? row.id : row._id));
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            } else if (option === "Remove policy warnings") {
                this.functions.removeWarnings(row);
            } else if (option === "Remove trigger warnings") {
                this.functions.removeWarnings(row, true);
            } else if (option == "Monthly spend") {
                this.setState({
                    monthly_spend: row
                })
            }
        },
    };

    calls = {
        wizards: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencies?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&order=" + this.state.order.id + "&status=" + this.state.status.value + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        removeWarnings: (id, trigger) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyWarnings?agency=" + id + (trigger ? "&type=trigger" : "");
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgency?id=" + id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">
                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_agency: {}
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.wizards();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.wizards(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[
                            { text: "Create new", icon: OfficeBuildingIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading agencies ...</div>
                    </div>
                }

                {/*CREATE AGENCY*/}
                <SlideoutTailwind
                    title={this.state.create_agency && this.state.create_agency.id ? "Update Agency" : "Create Agency"}
                    submitButtonText={this.state.create_agency && this.state.create_agency.id ? "Update" : "Create"}
                    large={true}
                    secondaryButton={false}
                    open={this.state.create_agency ? true : false}
                    loader={this.state.create_agency_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_agency: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_agency_loading: true
                        });
                        this.refs.CreateAgency.functions.createAgency();
                    }}
                >
                    {
                        this.state.create_agency &&
                        <CreateAgency
                            agency={this.state.create_agency ? this.state.create_agency : {}}
                            ref="CreateAgency"
                            onCreated={async () => {
                                await this.promisedSetState({
                                    create_agency: false,
                                    create_agency_loading: false,
                                    //sort: 'createdAt',
                                    //direction: 'descending'
                                });
                                this.functions.wizards();
                            }}
                            onError={async () => {
                                await this.promisedSetState({
                                    create_agency_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*MONTHLY SPEND*/}
                <SlideoutTailwind
                    title={"Monthly spend"}
                    submitButtonText={"Run"}
                    large={true}
                    secondaryButton={false}
                    open={this.state.monthly_spend ? true : false}
                    loader={this.state.monthly_spend_loading}
                    onCancel={() => {
                        this.promisedSetState({ monthly_spend: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            monthly_spend_loading: true
                        });
                        this.refs.MonthlySpend.functions.run();
                    }}
                >
                    {
                        this.state.monthly_spend &&
                        <AgencyMonthlySpend
                            agency={this.state.monthly_spend ? this.state.monthly_spend : {}}
                            ref="MonthlySpend"
                            onLoading={async (value) => {
                                await this.promisedSetState({
                                    monthly_spend_loading: value
                                });
                            }}
                            onError={async () => {
                                await this.promisedSetState({
                                    monthly_spend_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO DRAFTS*/}
                            {
                                this.state.campaigns.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No agencies available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="relative overflow-x-auto h-full table-overflow">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">

                                        <TableHeadTailwind
                                            empty_space={true}
                                            columns={this.state.columns}
                                            sort={this.state.sort}
                                            direction={this.state.direction}
                                            view_name={"agencyListNew"}
                                            onColumnSort={async (value) => {
                                                await this.promisedSetState({
                                                    page: value.page,
                                                    sort: value.sort,
                                                    direction: value.direction
                                                })
                                                this.functions.wizards();
                                            }}
                                            onColumnSave={async (value) => {
                                                this.setState({ columns: value })
                                            }}

                                        />
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.campaigns.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                {/* <Menu.Items className={`${this.state.campaigns.length - (index + 1) < 5 && this.state.campaigns.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}

                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Edit", "List agents", "Remove policy warnings", "Remove trigger warnings"].filter((option) => {
                                                                                            return (item.status === "disabled" && option === "Delete") || option !== "Delete"
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>

                                                                {
                                                                    this.state.columns.map((column, index) => {
                                                                        if (column.disabled) {
                                                                            return;
                                                                        } else if (column.value === "name") {
                                                                            return (
                                                                                <td onClick={() => {
                                                                                    this.functions.menu("List agents", item);
                                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                                    <div className="flex flex-row items-center">
                                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                                            <img src={item.logo} className="bg-cover" />
                                                                                        </div>
                                                                                        <div className="ml-4">
                                                                                            {item.name ? item.name : "-"}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "createdAt") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "-"}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "disabledAt") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'disabledAt' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.disabledAt ? moment(item.disabledAt).format("YYYY-MM-DD") : "-"}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "status") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                                    <span
                                                                                        className={cn("px-4 py-1 inline-flex text-xs uppercase font-bold rounded-full",
                                                                                            {
                                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                                ["bg-red-100 text-red-600"]: item.status === "disabled",
                                                                                            })
                                                                                        }>
                                                                                        {item.status ? item.status : "-"}
                                                                                    </span>
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "adminAccess") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'adminAccess' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                                    <span
                                                                                        className={cn("px-4 py-1 inline-flex whitespace-no-wrap text-xs font-bold rounded-full",
                                                                                            {
                                                                                                ["bg-purple-100 text-purple-500"]: item.adminAccess,
                                                                                                ["bg-gray-100 text-gray-600"]: !item.adminAccess
                                                                                            })
                                                                                        }>
                                                                                        {item.adminAccess ? "ADMIN AGENCY" : "REGULAR AGENCY"}
                                                                                    </span>
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "test") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'type' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                                    <span
                                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                                            {
                                                                                                ["bg-purple-100 text-purple-500"]: !item.test,
                                                                                                ["bg-gray-100 text-gray-500"]: item.test,
                                                                                            })
                                                                                        }>
                                                                                        {item.test ? "TEST" : "LIVE"}
                                                                                    </span>
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "agents") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'agents' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.agents}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "clients") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'clients' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.clients}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "orders") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'orders' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.orders}
                                                                                </td>
                                                                            )
                                                                        } else if (column.value === "campaigns") {
                                                                            return (
                                                                                <td className={(this.state.sort === 'campaigns' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                                    {item.campaigns}
                                                                                </td>
                                                                            )
                                                                        } else {
                                                                            return <td></td>
                                                                        }


                                                                    })

                                                                }

                                                                {
                                                                    false &&
                                                                    <>
                                                                        <td onClick={() => {
                                                                            this.functions.menu("List agents", item);
                                                                        }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                            <div className="flex flex-row items-center">
                                                                                <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                                    <img src={item.logo} className="bg-cover" />
                                                                                </div>
                                                                                <div className="ml-4">
                                                                                    {item.name ? item.name : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'disabledAt' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.disabledAt ? moment(item.disabledAt).format("YYYY-MM-DD") : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                            <span
                                                                                className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                                    {
                                                                                        ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                        ["bg-red-100 text-red-600"]: item.status === "disabled",
                                                                                    })
                                                                                }>
                                                                                {item.status ? item.status : "-"}
                                                                            </span>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'type' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                            <span
                                                                                className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                                    {
                                                                                        ["bg-purple-100 text-purple-500"]: !item.test,
                                                                                        ["bg-gray-100 text-gray-500"]: item.test,
                                                                                    })
                                                                                }>
                                                                                {item.test ? "TEST" : "LIVE"}
                                                                            </span>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'agents' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.agents}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'clients' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.clients}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'orders' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.orders}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'campaigns' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                            {item.campaigns}
                                                                        </td>
                                                                    </>

                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default AgencyListNew;
