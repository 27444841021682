import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/client/" + data.id;
        return apiRegister.call(options, url);
    }

    static getReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/report/" + data.id;
        return apiRegister.call(options, url);
    }

    static saveReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/report";
        return apiRegister.call(options, url);
    }

    static updateReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/report/" + data.id;
        return apiRegister.call(options, url);
    }

    static getMetrics(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/metrics?custom=true";
        return apiRegister.call(options, url);
    }

    static getCampaignsInformation(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/campaignInformation?order=" + order;
        return apiRegister.call(options, url);
    }

    static getCampaignsReport(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/campaignReport?order=" + order;
        return apiRegister.call(options, url);
    }

    static getAdgroupsInformation(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/adgroupInformation?order=" + order;
        return apiRegister.call(options, url);
    }

    static getAdgroupsReport(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/adgroupReport?order=" + order;
        return apiRegister.call(options, url);
    }

    static getAdsInformation(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/adInformation?order=" + order;
        return apiRegister.call(options, url);
    }

    static getAdsReport(order, data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/controllers/adReport?order=" + order;
        return apiRegister.call(options, url);
    }

}

export {calls}