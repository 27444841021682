import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Input from "../../components/input";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";
import Switch from "react-ios-switch";
import Dropdown from "../../components/dropdown";
import Loader from "../../components/loader";

class UserMetricsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            metrics: {},
            google: {},
            facebook: {},
            linkedin: {},
            bidtheatre: {},
            google_analytics: {},
            clients: [],
            loading: true,
            loading_clients: true,
            excluded_clients: {}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.functions.metrics();
        this.functions.clients();
    }

    functions = {
        metric: () => {
            this.setState({
                loading_metric: true
            }, () => {
                let included_clients = {};
                calls.createMetric({
                    name: this.state.name,
                    facebook: (Object.keys(this.state.facebook).length > 0)?this.state.facebook:null,
                    google: (Object.keys(this.state.google).length > 0)?this.state.google:null,
                    bidtheatre: (Object.keys(this.state.bidtheatre).length > 0)?this.state.bidtheatre:null,
                    linkedin: (Object.keys(this.state.linkedin).length > 0)?this.state.linkedin:null,
                    google_analytics:  (Object.keys(this.state.google_analytics).length > 0)?this.state.google_analytics:null,
                    included_clients: this.state.clients.filter((item) => {
                        return item.selected
                    })
                }).then((response) => {
                    this.props.history.push("/user/metrics");
                }, (error) => {
                    this.setState({
                        loading_metric: false
                    })
                });
            })
        },
        metrics: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getMetrics().then((response) => {
                    this.setState({
                        loading: false,
                        metrics: response.data
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        clients: () => {
            this.setState({
                loading_clients: true
            }, () => {
                calls.getClient().then((response) => {
                    this.setState({
                        clients: response.data,
                        loading_clients: false
                    }, () => {
                        this.setState({
                            clients: this.state.clients.map((item) => {
                                item.selected = true;
                                return item;
                            })
                        })
                    })
                }, (error) => {
                    this.setState({
                        loading_clients: false
                    })
                });
            })
        }
    };

    renders = {
        facebook: () => {
            try {
                return Array.isArray(this.state.metrics.facebook)  ? this.state.metrics.facebook : []
            } catch (e) {
                return []
            }
        },
        linkedin: () => {
            try {
                return Array.isArray(this.state.metrics.linkedin)  ? this.state.metrics.linkedin : []
            } catch (e) {
                return []
            }
        },
        google: () => {
            try {
                return Array.isArray(this.state.metrics.google) ? this.state.metrics.google : []
            } catch (e) {
                return []
            }
        },
        googleAnalytics: () => {
            try {
                return Array.isArray(this.state.metrics.google_analytics) ? this.state.metrics.google_analytics : []
            } catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                {
                    this.state.loading_metric &&
                    <Loader
                        title={'Creating metric ...'}
                    />
                }
                {
                    !this.state.loading && !this.state.loading_clients &&
                    <div className="rounded-md bg-white p-4">
                        <div>
                            <h3 className="mb-0 font-bold">
                                Metric information
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Avg. number of search per month in parantheses
                            </p>
                        </div>
                        <div className="mt-4">
                            <div className="flex flex-1">
                                <Input
                                    title={'Name'}
                                    noBorder={false}
                                    white={false}
                                    value={this.state.name}
                                    error={this.state.error === "name" && this.state.name === ''}
                                    type={"text"}
                                    size={'full'}
                                    maxLength={40}
                                    onChange={(value) => this.setState({name: value.target.value})}
                                />
                            </div>
                            <div className="mt-4">
                                <Dropdown
                                    title={'Google'}
                                    size={'full'}
                                    noBorder={false}
                                    white={false}
                                    placeholder={"Select metric"}
                                    options={this.renders.google()}
                                    value={this.state.google}
                                    onChange={(value) => {
                                        this.setState({
                                            google: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                <Dropdown
                                    title={'Facebook'}
                                    size={'full'}
                                    noBorder={false}
                                    white={false}
                                    placeholder={"Select metric"}
                                    options={this.renders.facebook()}
                                    value={this.state.facebook}
                                    onChange={(value) => {
                                        this.setState({
                                            facebook: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                <Dropdown
                                    title={'Linkedin'}
                                    size={'full'}
                                    noBorder={false}
                                    white={false}
                                    placeholder={"Select metric"}
                                    options={this.renders.linkedin()}
                                    value={this.state.linkedin}
                                    onChange={(value) => {
                                        this.setState({
                                            linkedin: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                <Dropdown
                                    title={'Google Analytics'}
                                    size={'full'}
                                    noBorder={false}
                                    white={false}
                                    placeholder={"Select metric"}
                                    options={this.renders.googleAnalytics()}
                                    value={this.state.google_analytics}
                                    onChange={(value) => {
                                        this.setState({
                                            google_analytics: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {
                            false &&
                            <div>
                                <h3 className="mb-0 font-bold mt-4">
                                    Access
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Avg. number of search per month in parantheses
                                </p>
                            </div>
                        }
                        {
                            false &&
                            <div className="rounded-md bg-gray-100 px-4 pb-2 pt-4 mt-4">
                                {
                                    this.state.loading_clients &&
                                    <div className="h-32 flex justify-center align-middle items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.loading_clients &&
                                    Array.isArray(this.state.clients) &&
                                    this.state.clients.map((item) => {
                                        return (
                                            <div
                                                className="flex flex-row flex-1 items-center mb-2 bg-white px-4 py-3 py-1 rounded-md relative">
                                                <div style={{fontWeight: "500", fontSize: "14px"}}
                                                     className="text-sm">{item.name}</div>
                                                <div className="flex flex-1"></div>
                                                <Switch
                                                    onColor="#1AD5BD"
                                                    checked={item.selected}
                                                    onChange={(checked) => {
                                                        item.selected = checked;
                                                        this.setState({
                                                            clients: this.state.clients
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        <div className="mt-4 flex flex-row">
                            <div className="flex flex-1">

                            </div>
                            <div className="justify-end">
                                <button onClick={() => {
                                    this.functions.metric();
                                }} className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                                    <span>Create metric</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {
                    (this.state.loading || this.state.loading_clients) &&
                    <div className="background-Loader">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={24}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
            </section>
        )
    }
}

export default UserMetricsNew;
