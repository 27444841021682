import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getTargeting(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/targeting/linkedin?urn=" + data.urn + "&client=" + data.client + "&search=" + data.search;
        return apiRegister.call(options, url);
    }

}

export {calls}