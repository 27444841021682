import React, { Component } from 'react';
import { Doughnut } from "react-chartjs-2";

class StatCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: 0
        };
    };

    componentDidMount() {
        this.setState({
            stats: this.props.stats,
            donutData: this.props.donutData
        })
    }

    render() {
        return (
            <div style={this.props.border ? { backgroundColor: "#fcfcfc" } : {}} className={(this.props.border ? "border-1.5" : "shadow-lg bg-white") + " rounded-lg px-4 py-6 flex w-full h-full"}>
                <div className="data--item__donut flex flex-row justify-between items-center w-full">
                    <div className="mr-4 leading-none">
                        <div className="font-black text-xl md:text-2xl xl:text-3xl">{this.state.stats}{this.props.unit && <span className="font-light text-base ml-1">{this.props.unit}</span>}</div>
                        <span className="text-xs xl:text-sm">{this.props.label}</span>
                    </div>
                    <div className="flex">
                        {
                            this.state.donutData &&
                            <Doughnut
                                width={this.props.donutSize}
                                height={this.props.donutSize}
                                options={this.props.donutOptions}
                                data={this.props.donutData}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default StatCard;
