import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';

class UserRegionsAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            regions: [],
            loading: true,
        };
    };

    componentWillMount() {
    }

    componentDidMount() {

    }

    functions = {};

    renders = {};

    validators = {};

    render() {
        return (
            <section className="w-full pt-8">
                {
                    <div className="flex flex-row items-center">
                        <button onClick={() => {
                            this.props.history.push('/user/regions/new');
                        }} className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                            <FeatherIcon className="stroke-current mr-1" size={16} icon="plus"/> <span>Create Region</span>
                        </button>
                    </div>
                }
            </section>
        )
    }
}

export default UserRegionsAll;
