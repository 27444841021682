import React, {Component} from 'react';
import './style.css';
import CampaignOverviewType from "../campaign-overview-type";
import cx from "classnames";

class CampaignOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            loading: this.props.loading
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            loading: nextProps.loading
        });
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className={cx("Campaign-Overview",
                {
                    ["Campaign-Overview-Open"]: !this.state.loading
                })
            }>
                <div className="campaign-Overview-top">
                    Channel stats
                </div>
                <div className="Campaign-Overview-inner">
                    {
                        !this.state.loading &&
                        Object.keys(this.state.data).filter((key) => {
                            return key !== 'campaign' && key !== 'information'
                        }).map((channel) => {
                            return (
                                <div className="Campaign-OverviewChannel">
                                    <div className="Campaign-OverviewChannelTitle">
                                        {channel}
                                    </div>
                                    {
                                        this.state.data[channel].campaign &&
                                        <CampaignOverviewType
                                            data={this.state.data[channel]}
                                            channel={channel}
                                        />
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        this.state.loading &&
                        <div className="Campaign-Overview-Skeleton">
                            <div className="Campaign-Overview-Skeleton-Title"></div>
                            <div className="Campaign-Overview-Skeleton-inner">
                                <div className="Campaign-Overview-Skeleton-Channel"></div>
                                <div className="Campaign-Overview-Skeleton-Content"></div>
                                <div className="Campaign-Overview-Skeleton-Button"></div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className="Campaign-Overview-Skeleton-second">
                            <div className="Campaign-Overview-Skeleton-Title"></div>
                            <div className="Campaign-Overview-Skeleton-inner">
                                <div className="Campaign-Overview-Skeleton-Channel"></div>
                                <div className="Campaign-Overview-Skeleton-Content"></div>
                                <div className="Campaign-Overview-Skeleton-Button"></div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div style={{top: '0', position: 'absolute', right: '0', left: '0', bottom: '0'}}>
                            <span className="skeleton-box"></span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignOverview;
