import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import timezones from "../../assets/json/timezones";
import currencies from "../../assets/json/currencies";
import ClientAudience from "../client-audience";
import TextArea from "../text-area";
import {calls} from "./calls";
import Loader from "../loader";

class ClientFacebookRequestAdaccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            business: {},
            businesses: [],
            creditcard: {},
            creditcards: [],
            creditline: {},
            creditlines: [],
            currency: {},
            currencies: currencies,
            timezone: {},
            timezones: timezones,
            pending_adaccounts: []
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            item: this.props.item,
            token: this.props.token,
            channel: this.props.channel
        }, () => {
            this.functions.getBusinesses();
        })
    }

    init = {};

    functions = {
        getBusinesses: () => {
            this.setState({
                businesses_loading: true
            }, () => {
                calls.getBusinesses({
                    token: this.state.token,
                    client: this.state.client,
                    channel: this.state.channel
                }).then((response) => {
                    this.setState({
                        business: {},
                        businesses: response.data,
                        businesses_loading: false
                    })
                }, (error) => {
                    this.setState({
                        businesses_error: true,
                        businesses_loading: error
                    })
                });
            })
        },
        getPendingAdaccount: () => {
            this.setState({
                pending_loading: true
            }, () => {
                calls.getPendingAdaccounts({
                    token: this.state.token,
                    client: this.state.client,
                    channel: this.state.channel,
                    business: this.state.business.id
                }).then((response) => {
                    this.setState({
                        pending_adaccounts: response.data,
                        pending_loading: false
                    })
                }, (error) => {
                    this.setState({
                        pending_error: true,
                        pending_loading: error
                    })
                });
            })
        },
        requestAdaccount: (item) => {
            this.setState({
                loading: true,
            }, () => {
                calls.requestAdaccount({
                    client: this.state.client,
                    channel: this.state.channel,
                    adaccount_id: this.state.name,
                    business: this.state.business.id
                }).then((response) => {
                    this.setState({
                        loading: false,
                        success_modal: true,
                        name: "",
                        business: {},
                        open: false
                    })
                }, (error) => {
                    let message = "Something went wrong";
                    try {
                        message = error.body.message;
                    } catch (e) {
                    }
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: message
                    })
                });
            })
        }
    };

    renders = {};

    render() {
        return (
            <div>{
                this.state.loading &&
                <Loader
                    title={"Sending request"}
                />
            }
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({error_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.success_modal}
                    title="Success"
                    type="success"
                    text={"Request send"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({success_modal: false});
                    }}
                />
                <div className="bg-white rounded-md px-4 py-4">
                    {
                        <div className="Section" style={{paddingTop: '0px'}}>
                            <Dropdown
                                filter={true}
                                title={'Select a business manager'}
                                size={'full'}
                                placeholder={"Click here .."}
                                error={this.state.error === "business" && !this.state.business.name}
                                options={this.state.businesses}
                                loading={this.state.businesses_loading}
                                value={this.state.business}
                                onChange={(value) => this.setState({business: value}, () => {
                                    this.functions.getPendingAdaccount();
                                })}
                            />
                        </div>
                    }
                    {
                        this.state.business.name &&
                        <div className="Section">
                            <Input
                                title={"Adaccount ID"}
                                value={this.state.name}
                                error={this.state.error === "name"}
                                placeholder={""}
                                disabled={false}
                                size={'full'}
                                type={"text"}
                                onChange={(value) => {
                                    this.setState({
                                        name: value.target.value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.business.name &&
                        <div className="Section">
                            <div onClick={() => {
                                this.functions.requestAdaccount()
                            }} className="Button ButtonFull ButtonNeutral">
                                send request
                            </div>
                        </div>
                    }
                    {
                        this.state.business.name && this.state.pending_adaccounts.length > 0 &&
                        <div className="Section">
                            <div className="Section-Inner">
                                <div className="Section-InnerTitle">
                                    Pending requests ({this.state.business.name})
                                </div>
                                {
                                    this.state.pending_adaccounts.map((item, index) => {
                                        return (
                                            <div style={index !== 0?{marginBottom: '10px'}:{}}>
                                                <Input
                                                    noTitle={true}
                                                    value={item.id}
                                                    disabled={true}
                                                    size={'full'}
                                                    type={"text"}
                                                    onChange={(value) => {

                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ClientFacebookRequestAdaccount;
