import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";
import Input from "../../components/input";
import Dropdown from "../../components/dropdown";
import DropDown from "../dropdown";
import Loader from "../../components/loader";

class KeywordList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            campaignitems: [],
            sort: "",
            order: "",
            search: "",
            total: 0,
            pagesize: 0,
            type: {name: 'Exact match', value: 'exact'},
            level: {name: 'Add to Campaign', value: 'campaign'}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            campaignitems: this.props.campaignitems,
            sort: this.props.sort,
            order: this.props.order,
            total: this.props.total,
            search: this.props.search,
            pagesize: this.props.pagesize
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            campaignitems: nextProps.campaignitems,
            sort: nextProps.sort,
            order: nextProps.order,
            total: nextProps.total,
            search: nextProps.search,
            pagesize: nextProps.pagesize
        })
    }

    functions = {
        changeStatus: (item) => {
            this.setState({
                loading: true
            }, () => {
                let status = null;
                if(item.status === 'paused'){
                    status = 'active'
                }else{
                    status = 'paused'
                }
                calls.changeStatus({
                    channels: {google: true},
                    campaign_id: this.state.campaign.id,
                    adgroup_id: item.adgroup_id,
                    status: status
                }).then((response) => {
                    item.status = status;
                    this.setState({
                        campaignitems: this.state.campaignitems,
                        loading: false
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    renders = {
        ending: (key, item) => {
            if (key === 'ctr') {
                return "%"
            } else if (key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion') {
                return " " + item.currency
            } else {
                return "";
            }
        }
    };

    render() {
        return (
            <div className="flex flex-col my-5">
                {this.state.loading &&
                <Loader
                    title={'Updating keyword status'}
                />
                }
                {this.state.campaignitems.length > 0 && this.props.headline &&
                <h3 className="font-bold text-sm mb-2">{this.props.headline}</h3>
                }
                {this.state.campaignitems.length > 0 &&
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                <tr>
                                    <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions{false&&<div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                    {/** Exempel på class som sätts vid aktiv: sort-desc eller sort-asc på th-nivå */}
                                    <th className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'query' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Channel
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('text', 'text' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'text' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Keyword <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    {this.props.properties && this.props.properties.type &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('type', 'type' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Type <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.status &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('status', 'status' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'status' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Status <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.impressions &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('impressions', 'impressions' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'impressions' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Impressions <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.click &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('click', 'click' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'click' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Click <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.ctr &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('ctr', 'ctr' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'ctr' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            CTR <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.cpc &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('cpc', 'cpc' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'cpc' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            CPC <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.spend &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('spend', 'spend' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'spend' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Spend <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.conversions &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('conversions', 'conversions' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'conversions' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Conversions <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.campaignitems.sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            nameA = a[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            nameB = b[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).filter((item) => {
                                    try{
                                        return item.text.indexOf(this.state.search) !== -1
                                    }catch (e) {
                                        return true
                                    }
                                }).filter((item, i) => {
                                    return i < this.state.pagesize
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete && item.status === 'paused' &&
                                                        <button onClick={() => {
                                                            this.functions.changeStatus(item)
                                                        }} className="bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white">
                                                            <span>Activate keyword</span>
                                                            <FeatherIcon className="stroke-current ml-2" size={15} icon="play-circle"/>
                                                        </button>
                                                        }
                                                        {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete && item.status === 'active' &&
                                                        <button onClick={() => {
                                                            this.functions.changeStatus(item)
                                                        }} className="bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white">
                                                            <span>Pause keyword</span>
                                                            <FeatherIcon className="stroke-current ml-2" size={15} icon="pause-circle"/>
                                                        </button>
                                                        }
                                                    </div>
                                                </td>
                                                {this.props.properties && this.props.properties.channel &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className="flex flex-row">
                                                        {item.channel === 'google' &&
                                                        <div className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")"}}></div>
                                                        </div>}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.keyword &&
                                                    <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                                        <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'text'?'font-bold':'')}>
                                                            {item.text}
                                                        </div>
                                                    </td>
                                                }
                                                {this.props.properties && this.props.properties.type &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'type'?'font-bold':'')}>
                                                        {item.type?(item.type + this.renders.ending('type', item)):'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.status &&
                                                <td className="px-6 py-4 whitespace-no-wrap capitalize">
                                                        <span
                                                            className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                {
                                                                    ["bg-green-100 text-green-500"]: item.status === "active",
                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                    ["bg-red-100 text-red-600"]: item.status === "error" || item.status === "removed",
                                                                })
                                                            }>
                                                            {item.status}
                                                        </span>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.impressions &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'impressions'?'font-bold':'')}>
                                                        {item.impressions?item.impressions:'0'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.click &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'click'?'font-bold':'')}>
                                                        {item.click?item.click:'0'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.ctr &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'ctr'?'font-bold':'')}>
                                                        {item.ctr?(item.ctr + this.renders.ending('ctr', item)):'0%'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.cpc &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'cpc'?'font-bold':'')}>
                                                        {item.cpc?(item.cpc + this.renders.ending('cpc', item)):'0'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.spend &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'spend'?'font-bold':'')}>
                                                        {item.spend?(item.spend + this.renders.ending('spend', item)):'0'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.conversions &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'conversions'?'font-bold':'')}>
                                                        {item.conversions?item.conversions:'0'}
                                                    </div>
                                                </td>
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default KeywordList;