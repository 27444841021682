import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import { dataRegister } from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { BeatLoader } from "react-spinners";

class AdvertisingCreativesFacebookCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content,
            body: this.props.body,
            page: this.props.page,
            dynamic: this.props.dynamic,
            no_container: this.props.noContainer,
            language: "",
            location: "",
            read_more: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            content: nextProps.content,
            body: nextProps.body,
            dynamic: nextProps.dynamic,
            language: nextProps.language,
            page: nextProps.page,
            location: nextProps.location
        }, () => {
            console.log(this.state.content);
        });
    }

    componentDidMount() {

        this.setState({
            content: this.props.content,
            body: this.props.body,
            dynamic: this.props.dynamic,
            language: this.props.language,
            page: this.props.page,
            location: this.props.location
        }, () => {
            console.log(this.state.content);
        });

        try {
            const slider = document.querySelector('#carousel_slider');
            let isDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 1; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        } catch (e) {

        }

    }

    functions = {};

    init = {};

    renders = {
        body: () => {
            try {
                let body = this.state.content.body !== "" ? this.state.content.body : "Body ...";
                body = body.replace("{location}", this.state.location);
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        /*
        body: () => {
            try {
                return this.state.content.body !== "" ? this.state.content.body : "Body ..."
            } catch (e) {
                return ""
            }
        },
        */
        title: (item) => {
            try {
                if (item.headline.length > 20) {
                    return item.headline.substring(0, 20) + '...';
                } else {
                    return item.headline !== "" ? item.headline : "Headline ..."
                }
            } catch (e) {
                return ""
            }
        },
        description: (item) => {
            try {
                return item.description !== "" ? item.description : "Description ..."
            } catch (e) {
                return ""
            }
        },
        video: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        image: (item) => {
            try {
                return item.creative.url
            } catch (e) {
                return ""
            }
        },
        gif: (item) => {
            try {
                return item.creative.url.indexOf('.gif') !== -1 ? item.creative.url : null
            } catch (e) {
                return ""
            }
        },
        poster: (item) => {
            try {
                return item.creative.thumbnail.url
            } catch (e) {
                return ""
            }
        },
        ratio: (item) => {
            try {
                return item.creative.ratio
            } catch (e) {
                return ""
            }
        },
        items: () => {
            try {
                return this.state.content.items ? this.state.content.items : []
            } catch (e) {
                return []
            }
        },
        callToAction: (item) => {
            console.log(item);
            try {
                if (this.props.linkedin) {
                    if (item.cta) {
                        return item.cta.name;
                    } else if (item.call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return item.call_to_action_linkedin.name;
                    }
                } else {
                    if (item.cta) {
                        return item.cta.name;
                    } else if (item.call_to_action.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return item.call_to_action.name;
                    }
                }
            } catch (e) {
                return null
            }
        }
    };


    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer")}>
                {
                    (!this.state.dynamic || (this.state.dynamic && this.renders.items().length > 0)) &&
                    <div className={
                        cx("FacebookImage-PreviewBasicContainerItem",
                            {
                                ["FacebookImage-PreviewBasicContainerItemError"]: this.state.error,
                                ["FacebookImage-PreviewBasicContainerItemNo"]: this.state.no_container,
                                ["FacebookImage-PreviewBasicContainerItemWhiteBackground"]: this.props.white,
                            })
                    }>
                        <div className={cx("FacebookImage-PreviewBasicContainerItemInner")}>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeader">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                                    <div style={{ backgroundImage: 'url(' + this.state.page.image + ')' }} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText">
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                        {this.state.page.name}
                                    </div>
                                    {
                                        !this.props.linkedin &&
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                                Sponsored
                                            </div>
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot" />
                                            <img className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe" src={require('../../assets/images/globe.png')} />
                                        </div>
                                    }
                                    {
                                        this.props.linkedin &&
                                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                                Promoted
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="p-3">
                                <div dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="font-medium text-sm pb-0 whitespace-pre-wrap">
                                </div>
                                <div>
                                    {
                                        !this.state.read_more && this.renders.body().length > 125 &&
                                        <div onClick={() => {
                                            this.setState({
                                                read_more: true
                                            });
                                        }} className=" font-semibold text-sm text-center text-purple-500 ">
                                            Read more
                                        </div>
                                    }
                                </div>
                            </div>
                            <div id={"carousel_slider"} className={
                                cx("FacebookImage-PreviewBasicContainerItemInnerImageCarousel",
                                    {
                                        ["FacebookImage-PreviewBasicContainerItemInnerImageSquare"]: false,
                                    })
                            }>
                                {
                                    this.renders.items().filter((item) => {
                                        return (this.renders.ratio(item) === '1:1' || item.loading) && ((this.props.linkedin && (item.image || item.video)) || !this.props.linkedin)
                                    }).map((item) => {
                                        return (
                                            <div onClick={(e) => {
                                                if (!this.props.noHighlight) {
                                                    this.renders.items().map((inner_item) => {
                                                        inner_item.selected = item.id === inner_item.id;
                                                    });
                                                    this.props.onChange(this.state.content.items);
                                                }
                                            }} className="FacebookImage-PreviewBasicContainerItemInnerImageItem">
                                                {
                                                    item.selected && !item.loading && !this.props.noHighlight && !this.state.dynamic &&
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemActive">
                                                        <FeatherIcon color={"#ffffff"} size={20} icon="check" />
                                                    </div>
                                                }
                                                {
                                                    item.loading &&
                                                    <div className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={10}
                                                            color={'#388f8a'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    !this.renders.gif(item) &&
                                                    item.image && !item.dynamic &&
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemTop">
                                                        {
                                                            !item.loading &&
                                                            <img src={this.renders.image(item)} className="FacebookImage-PreviewBasicContainerItemInnerImageItemTopImage" />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.renders.gif(item) &&
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemTop">
                                                        {
                                                            !item.loading &&
                                                            <img src={this.renders.gif(item)} className="FacebookImage-PreviewBasicContainerItemInnerImageItemTopImage" />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !this.renders.gif(item) &&
                                                    item.image && item.dynamic &&
                                                    <div style={{ backgroundImage: "url(" + this.renders.image(item) + ")" }} className="FacebookImage-PreviewBasicContainerItemInnerImageItemTopDynamic" />
                                                }
                                                {
                                                    !this.renders.gif(item) &&
                                                    item.video &&
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemTop">
                                                        {
                                                            !item.loading &&
                                                            <video poster={this.renders.poster(item)} controls={true} style={{ width: '100%', height: '100%' }}>
                                                                <source src={this.renders.video(item)} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                }
                                                <div style={this.props.linkedin ? { paddingTop: "20px", paddingBottom: "20px" } : {}} className="flex flex-row FacebookImage-PreviewBasicContainerItemInnerImageItemBottom">
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft truncate">
                                                        <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemBottomTitle">
                                                            {this.renders.title(item)}
                                                        </div>
                                                        {
                                                            !this.props.linkedin &&
                                                            <div className="FacebookImage-PreviewBasicContainerItemInnerImageItemBottomDescription truncate">
                                                                {this.renders.description(item)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRight">
                                                        {
                                                            this.renders.callToAction(item) && !this.props.linkedin &&
                                                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton">
                                                                {this.renders.callToAction(item)}
                                                            </div>
                                                        }
                                                        {
                                                            this.renders.callToAction(item) && this.props.linkedin && false &&
                                                            <div className="rounded-full px-2 py-1 border border-google-500 text-google-500 font-bold text-xs">
                                                                {this.renders.callToAction(item)}
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div style={{ height: '100px', minWidth: '5px' }}></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShare">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                    <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike" src={require('../../assets/images/like.png')} />
                                    Like
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                    <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment" src={require('../../assets/images/comment.png')} />
                                    Comment
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                    <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare" src={require('../../assets/images/share.png')} />
                                    Share
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (this.state.dynamic && this.renders.items().length > 0) &&
                    <div style={{ fontSize: '12px', fontWeight: '500', marginTop: '10px', textAlign: 'center' }}>(Sample products)</div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesFacebookCarousel;
