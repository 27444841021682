import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, MenuAlt1Icon, PlusIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';
import InputTimepickerTailwind from '../moduleFiles/inputTimepickerTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { isNumber, round } from '@turf/turf';
import TagManager from '../moduleFiles/tagManager';
import { createChangelog } from '../services/createChangelog';

class OrderOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            clients: [],
            order: {},
            org_order: {},
            search: "",
            client: {},
            meta: {},
            loading_button: {},
            remove: false,
            onboarding_statuses: [
                // { id: 1, name: "Created", value: "created" },
                { id: 1, name: "Pending", value: "pending", step: 2 },
                { id: 2, name: "Review", value: "review", step: 3 },
                { id: 3, name: "Active", value: "active", step: 4 },
                { id: 4, name: "Paused", value: "paused", step: 4 },
                { id: 5, name: "Archived", value: "archived", step: 5 }
            ],
            // onboarding_status: { id: 0, name: "Select ..." },
            user: {},
            throttling: {},
            agents: [],
            agent_options: [],
            loading_agents_search: "",
            loading_agents_pagination: 0,
            loading_agents: false,
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            tab: { id: 1, name: "Order information" },
            tabs: [
                { id: 1, name: "Order information" },
                { id: 2, name: "Assigned agents" },
                { id: 3, name: "Shared budgets Google/Bing" },
                { id: 4, name: "Budget split (new drafts)" }
            ],
            shared_google_budgets: [],
            shared_bing_budgets: [],
            show_pause_modal: false,
            initial_data: {},
            updated_data: {},
            pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true },
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        };
    }

    componentWillMount() {

    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getOrder();
        this.props.updateStatistics();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getOrder: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.order();
                    if (!response.data.crmBudgets) {
                        response.data.crmBudgets = {
                            google: "",
                            bing: "",
                            facebook: ""
                        };
                    }
                    if (!("google") in response.data.crmBudgets) {
                        response.data.crmBudgets["google"] = "";
                    }
                    if (!("bing") in response.data.crmBudgets) {
                        response.data.crmBudgets["bing"] = "";
                    }
                    if (!("facebook") in response.data.crmBudgets) {
                        response.data.crmBudgets["facebook"] = "";
                    }
                    await this.promisedSetState({
                        order: response.data,
                        org_order: JSON.parse(JSON.stringify(response.data)),
                        meta: response.meta,
                        initial_data: JSON.parse(JSON.stringify(response.data)),
                        ...(response.data.client && { client: response.data.client }),
                    });
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                this.functions.listGoogleBudgets();
                this.functions.listBingBudgets();
                resolve();
            });
        },
        removeOrder: async () => {
            await this.promisedSetState({
                loading_button: {
                    remove_order: true,
                }
            });
            try {
                await this.calls.removeOrder();
                this.functions.handleCreateChangelog("removed");
                this.props.history.push("/v2/orders/active");
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_button: { remove_order: false } });
        },
        updateName: async () => {
            await this.promisedSetState({ loading_name: true });
            try {
                await this.calls.updateOrder({ name: this.state.order.name });
                this.state.org_order.name = JSON.parse(JSON.stringify(this.state.order.name));
                await this.promisedSetState({ org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_name: false });
        },
        updateSplitBudget: async () => {
            await this.promisedSetState({ loading_budget: true });
            await this.promisedSetState({ loading_split: true });
            await this.promisedSetState({ loading_google: true });
            await this.promisedSetState({ loading_crmbudget: true });
            try {
                await this.calls.updateOrder({
                    crmBudget: this.state.order.crmBudget,
                    maxBudget: this.state.order.maxBudget,
                    budgetSplit: {
                        value: this.state.order.budgetSplit.value,
                        budget: this.state.order.budgetSplit.budget,
                        google_budget: this.state.order.budgetSplit.google_budget,
                        bing_budget: this.state.order.budgetSplit.bing_budget
                    }
                });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_budget: false });
            await this.promisedSetState({ loading_split: false });
            await this.promisedSetState({ loading_google: false });
            await this.promisedSetState({ loading_crmbudget: false });
            this.functions.handleCreateChangelog();
        },
        updateStatus: async (status) => {
            await this.promisedSetState({
                loading_button: {
                    pause_order: status === 'paused',
                    activate_order: status === 'active',
                    archive_order: status === 'archived'
                }
            });
            try {
                this.state.order.status = status;
                if (status === 'paused') {
                    await this.calls.pause(this.state.order.id, this.state.order.client.id);
                } else {
                    await this.calls.updateOrder({ status: status });
                }
                await this.promisedSetState({ order: this.state.order, show_pause_modal: false });
                this.functions.handleCreateChangelog();
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_button: { pause_order: false, activate_order: false, archive_order: false }, pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true }, show_pause_modal: false })
            if (this.props.updateStatistics) {
                this.props.updateStatistics();
            }
        },
        updateCrmData: async () => {
            await this.promisedSetState({
                loading_crm: true
            });
            try {
                await this.calls.updateOrder({
                    crmRef: this.state.order.crmRef,
                    crmIdHistory: this.state.order.crmIdHistory ? this.state.order.crmIdHistory : {},
                    crmBudgets: this.state.order.crmBudgets ? this.state.order.crmBudgets : {},
                });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_crm: false });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_maxbudget: true
            });
            try {
                await this.calls.updateOrder({
                    maxBudgetEnabled: this.state.order.maxBudgetEnabled,
                    maxBudget: this.state.order.maxBudget
                });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_maxbudget: false });
        },
        updateStartDate: async () => {
            await this.promisedSetState({ loading_start_date: true });
            try {
                await this.calls.updateOrder({ startDate: this.state.order.startDate });
                this.state.org_order.startDate = JSON.parse(JSON.stringify(this.state.order.startDate));
                await this.promisedSetState({ org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_start_date: false });
        },
        updateStartTime: async () => {
            await this.promisedSetState({ loading_start_time: true });
            try {
                await this.calls.updateOrder({ startDate: this.state.order.startDate });
                this.state.org_order.startDate = JSON.parse(JSON.stringify(this.state.order.startDate));
                await this.promisedSetState({ org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_start_time: false });
        },
        updateEndDate: async () => {
            await this.promisedSetState({ loading_end_date: true });
            try {
                await this.calls.updateOrder({ endDate: this.state.order.endDate });
                this.state.org_order.endDate = JSON.parse(JSON.stringify(this.state.order.endDate));
                await this.promisedSetState({ org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_end_date: false });
        },
        updateEndTime: async () => {
            await this.promisedSetState({ loading_end_time: true });
            try {
                await this.calls.updateOrder({ endDate: this.state.order.endDate });
                this.state.org_order.endDate = JSON.parse(JSON.stringify(this.state.order.endDate));
                await this.promisedSetState({ org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_end_time: false });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                console.log(error);
                return null
            }
        },
        updateAgents: async () => {
            await this.promisedSetState({ loading_end_time: true });
            try {
                let response = await this.calls.updateOrder({ agents: this.state.order.agents });
                this.state.org_order.agents = JSON.parse(JSON.stringify(this.state.order.agents));
                this.state.order.status = response.data.status;
                await this.promisedSetState({ order: this.state.order, org_order: this.state.org_order });
                this.functions.handleCreateChangelog();
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_end_time: false });
        },
        updateOnboardingStatus: async (status) => {
            try {
                this.state.order.clientStatus = status;
                await this.promisedSetState({
                    order: this.state.order
                })
                await this.calls.updateOrder({ clientStatus: status });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agent_options: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agent_options = this.state.agent_options.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agent_options: this.state.agent_options,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        listGoogleBudgets: async () => {
            await this.promisedSetState({
                loading_google_budgets: true
            });
            try {
                let response = await this.calls.listGoogleBudgets();
                if (Array.isArray(this.state.order.googleBudgets) && this.state.order.googleBudgets.length > 0) {
                    this.state.order.googleBudgets = this.state.order.googleBudgets.map((item) => {
                        if (Array.isArray(response.data)) {
                            response.data.map((inner_item) => {
                                if (inner_item.id == item.id) {
                                    item.amount = inner_item.amount;
                                    item.referenceCount = inner_item.referenceCount;
                                    item.status = inner_item.status;
                                    item.period = inner_item.period;
                                    item = { ...item, inner_item };
                                }
                            });
                        }
                        return item;
                    });
                    response.data = response.data.filter((item) => { return !this.state.order.googleBudgets.map((inner_item) => { return inner_item.id }).includes(item.id) });
                }
                await this.promisedSetState({
                    order: this.state.order,
                    shared_google_budgets: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_google_budgets: false
            });
        },
        listBingBudgets: async () => {
            await this.promisedSetState({
                loading_bing_budgets: true
            });
            try {
                let response = await this.calls.listBingBudgets();
                if (Array.isArray(this.state.order.bingBudgets) && this.state.order.bingBudgets.length > 0) {
                    this.state.order.bingBudgets = this.state.order.bingBudgets.map((item) => {
                        if (Array.isArray(response.data)) {
                            response.data.map((inner_item) => {
                                if (inner_item.id == item.id) {
                                    item.amount = inner_item.amount;
                                    item = { ...item, inner_item };
                                }
                            });
                        }
                        return item;
                    });
                    response.data = response.data.filter((item) => { return !this.state.order.bingBudgets.map((inner_item) => { return inner_item.id }).includes(item.id) });
                }
                this.setState({
                    order: this.state.order,
                    shared_bing_budgets: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_bing_budgets: false
            });
        },
        setGoogleBudget: async (budget) => {
            await this.promisedSetState({ loading_google_budgets: true });
            try {
                let buffer = this.state.order.googleBudgets ? JSON.parse(JSON.stringify(this.state.order.googleBudgets)) : [];
                buffer.push({ id: budget.id });
                this.state.shared_google_budgets = this.state.shared_google_budgets.filter((item) => { return item.id !== budget.id });
                await this.calls.updateOrder({ googleBudgets: buffer });
                if (!Array.isArray(this.state.order.googleBudgets)) {
                    this.state.order.googleBudgets = [];
                }
                this.state.order.googleBudgets.push(budget);
                await this.promisedSetState({ order: this.state.order, shared_google_budgets: this.state.shared_google_budgets });
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_google_budgets: false });
        },
        setBingBudget: async (budget) => {
            await this.promisedSetState({ loading_bing_budgets: true });
            try {
                let buffer = this.state.order.bingBudgets ? JSON.parse(JSON.stringify(this.state.order.bingBudgets)) : [];
                buffer.push({ id: budget.id });
                this.state.shared_bing_budgets = this.state.shared_bing_budgets.filter((item) => { return item.id !== budget.id });
                await this.calls.updateOrder({ bingBudgets: buffer });
                if (!Array.isArray(this.state.order.bingBudgets)) {
                    this.state.order.bingBudgets = [];
                }
                this.state.order.bingBudgets.push(budget);
                await this.promisedSetState({ order: this.state.order, shared_bing_budgets: this.state.shared_bing_budgets });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_bing_budgets: false });
        },
        removeGoogleBudget: async (budget) => {
            await this.promisedSetState({ loading_google_budgets: true });
            try {
                let buffer = this.state.order.googleBudgets ? JSON.parse(JSON.stringify(this.state.order.googleBudgets)) : [];
                buffer = buffer.filter((item) => { return item.id !== budget.id }).map((item) => { return { id: item.id } });
                await this.calls.updateOrder({ googleBudgets: buffer });
                this.state.order.googleBudgets = this.state.order.googleBudgets.filter((item) => { return item.id !== budget.id });
                await this.promisedSetState({ order: this.state.order, error_google: false });
                this.functions.listGoogleBudgets();
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_google_budgets: false });
        },
        removeBingBudget: async (budget) => {
            await this.promisedSetState({ loading_google_loading_bing_budgetsbudgets: true });
            try {
                let buffer = this.state.order.bingBudgets ? JSON.parse(JSON.stringify(this.state.order.bingBudgets)) : [];
                buffer = buffer.filter((item) => { return item.id !== budget.id }).map((item) => { return { id: item.id } });
                await this.calls.updateOrder({ bingBudgets: buffer });
                this.state.order.bingBudgets = this.state.order.bingBudgets.filter((item) => { return item.id !== budget.id });
                await this.promisedSetState({ order: this.state.order, error_bing: false });
                this.functions.listBingBudgets();
            } catch (error) {
                console.log(error);
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_bing_budgets: false });
        },
        updateGoogleBudget: async (budget) => {
            await this.promisedSetState({ loading_google_budgets: true, error_google: false });
            try {
                await this.calls.updateGoogleBudget({
                    operations: [{
                        updateMask: "amount_micros",
                        update: {
                            resourceName: budget.resourceName,
                            amountMicros: parseInt(budget.new_amount) * 1000000
                        }
                    }]
                });
                this.state.order.googleBudgets = this.state.order.googleBudgets.map((item) => {
                    if (item.id === budget.id) {
                        item.amount = budget.new_amount;
                        delete item.new_amount;
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                await this.promisedSetState({ error_google: error.body && error.body.message ? error.body.message : "Something went wrong" });
            }
            await this.promisedSetState({ loading_google_budgets: false });
        },
        updateBingBudget: async (budget) => {
            await this.promisedSetState({ loading_bing_budgets: true, error_bing: false });
            try {
                await this.calls.updateBingBudget({ amount: budget.new_amount, id: budget.id });

                this.state.order.bingBudgets = this.state.order.bingBudgets.map((item) => {
                    if (item.id === budget.id) {
                        item.amount = budget.new_amount;
                        delete item.new_amount;
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                await this.promisedSetState({ error_bing: error.body && error.body.message ? error.body.message : "Something went wrong" });
            }
            await this.promisedSetState({ loading_bing_budgets: false });
        },
        updateGoogleBudgets: async () => {
            await this.promisedSetState({ loading_google_budgets: true });
            try {
                await this.calls.updateOrder({ googleBudgets: this.state.order.googleBudgets });
                if (!Array.isArray(this.state.order.googleBudgets)) {
                    this.state.order.googleBudgets = [];
                }
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_google_budgets: false });
        },
        updateBingBudgets: async () => {
            await this.promisedSetState({ loading_bing_budgets: true });
            try {
                await this.calls.updateOrder({ bingBudgets: this.state.order.bingBudgets });
                if (!Array.isArray(this.state.order.bingBudgets)) {
                    this.state.order.bingBudgets = [];
                }
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_bing_budgets: false });
        },
        showPauseModal: async () => {
            await this.promisedSetState({ show_pause_modal: true });
        },
        handleCreateChangelog: async (action) => {
            try {
                this.state.updated_data = this.state.order
                if (action === "removed") {
                    this.state.updated_data.status = action
                }
                await this.promisedSetState({
                    updated_data: this.state.updated_data
                })
                createChangelog({ initial_data: this.state.initial_data, updated_data: this.state.updated_data, client: this.state.order.client.id, order: this.state.order.id, item: this.state.order.id, level: "order" });
                await this.promisedSetState({
                    initial_data: JSON.parse(JSON.stringify(this.state.order)),
                    updated_data: {}
                })
            } catch (error) { }

        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        order: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        removeOrder: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        listGoogleBudgets: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBudgets?client=" + (this.state.order.client ? this.state.order.client.id : "undefined") + (id ? "&id=" + id : "");
            return apiRegister.call(options, url);
        },
        listBingBudgets: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bing/listBudgets?client=" + (this.state.order.client ? this.state.order.client.id : "undefined");
            return apiRegister.call(options, url);
        },
        updateGoogleBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateCampaignBudget?client=" + (this.state.order.client ? this.state.order.client.id : "undefined");
            return apiRegister.call(options, url);
        },
        updateBingBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateBingBudget?client=" + (this.state.order.client ? this.state.order.client.id : "undefined");
            return apiRegister.call(options, url);
        },
        pause: (id, client) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', { status: "paused", pause_campaigns: this.state.pause_all_campaigns ? this.state.pause_all_campaigns.value : true });
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + id + "&client=" + client;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        pauseAllCampaigns: (value) => {
            try {
                if (value) {
                    return value;
                } else if (this.state.pause_all_campaigns) {
                    return this.state.pause_all_campaigns;
                } else {
                    return { id: 1, name: "Yes, pause all campaigns", value: true }
                }
            } catch (error) {
                return { id: 0, name: "Select ..." }
            }
        }
    }

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">
                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === 'paused') {
                                this.functions.showPauseModal();
                                // this.functions.updateStatus('paused');
                            } else if (type === 'archived') {
                                this.functions.updateStatus('archived');
                            } else if (type === 'active') {
                                this.functions.updateStatus('active');
                            } else if (type === 'delete') {
                                this.setState({
                                    remove: true
                                })
                            }
                        }}
                        hideUserDropdown={true}
                        breadcrumb={"Overview"}
                        history={this.props.history}
                        buttonTypes={{
                            activate_order: !this.state.loading && (this.state.order.status === "paused" || this.state.order.status === "archived"),
                            pause_order: !this.state.loading && this.state.order.status === "active",
                            archive_order: !this.state.loading && (this.state.order.status == "paused" || this.state.order.status == "ended"),
                            delete_order: !this.state.loading && this.state.order.status === "archived"
                        }}
                        buttonLoaders={this.state.loading_button}
                        showClient={true}
                        lockClient={false}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                        onSubtab={(tab) => {
                            this.setState({
                                tab: tab
                            })
                        }}
                        subtab={this.state.tab}
                        show_subtabs={true}
                        subtabs={this.state.tabs.filter((item) => { return (this.state.order.budgetSplit && item.id === 4) || item.id !== 4 })}
                    // this.props.updateStatistics(client);

                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={this.state.remove_name != this.state.order.name}
                    showInput={true}
                    inputTitle={"Type Order name to remove"}
                    copyInput={this.state.order ? this.state.order.name : ""}
                    inputError={(this.state.remove_name != this.state.order.name) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.order.name) {
                            this.functions.removeOrder();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*PAUSE MODAL*/}
                <WarningModalTailwind
                    open={this.state.show_pause_modal ? true : false}
                    title={"Pause"}
                    description={'Are you sure you want to Pause? Related campaigns will be paused by default'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Pause"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ show_pause_modal: false, pause_all_campaigns: { id: 1, name: "Yes, pause all campaigns", value: true } });
                    }}
                    onSubmit={(value) => {
                        this.functions.updateStatus('paused');
                    }}
                    loading={this.state.loading_button ? this.state.loading_button.pause_order : false}
                    dropDown={true}
                    dropDownOptions={[{ id: 1, name: "Yes, pause all campaigns", value: true }, { id: 2, name: "No, don't pause campaigns", value: false }]}
                    dropDownSelectedRender={this.renders.pauseAllCampaigns()}
                    onDropDownSelect={async (value) => {
                        this.state.pause_all_campaigns = value
                        await this.promisedSetState({ pause_all_campaigns: this.state.pause_all_campaigns });
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading order ...</div>
                    </div>
                }

                {/*SECTION INFORMATION*/}
                {
                    this.state.tab.id == 1 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Order settings
                                </div>
                                <div className="flex flex-1">

                                </div>
                                <div className='flex space-x-12'>
                                    {/* <div className="flex flex-row justify-center items-center">
                                        <div className="text-sm mr-4">
                                            Force max budget reached
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.force_max_budget}
                                            onSwitch={async () => {
                                                this.setState({
                                                    force_max_budget: !this.state.force_max_budget
                                                })
                                                console.log("forced", this.state.force_max_budget)
                                            }}
                                        />
                                    </div> */}
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="text-sm mr-4">
                                            Edit sensitive data
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.allow_update}
                                            onSwitch={async () => {
                                                this.setState({
                                                    allow_update: !this.state.allow_update
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full py-5 px-4">
                                <div className="grid-cols-12 gap-4 grid">

                                    <div className={"col-span-3"}>
                                        <InputTailwind
                                            onBlur={() => {
                                                if (this.state.order.name !== this.state.org_order.name) {
                                                    this.functions.updateName();
                                                }
                                            }}
                                            loading={this.state.loading_name}
                                            readOnly={this.state.loading_name}
                                            onFocus={() => { }}
                                            label={"Name"}
                                            //error={this.state.order.name === "" && this.state.error}
                                            errorMessage={"Name is not valid"}
                                            value={this.state.order.name}
                                            onChange={(value) => {
                                                this.state.order.name = value;
                                                this.promisedSetState({
                                                    order: this.state.order
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-3"}>
                                        <DropdownTailwind
                                            label={"Status"}
                                            selected={{ id: this.state.order.status, name: this.state.order.status }}
                                            locked={true}
                                            options={[]}
                                            onChange={async (value) => {

                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-3"}>
                                        <DropdownTailwind
                                            label={"Client"}
                                            selected={this.state.order.client}
                                            locked={true}
                                            options={[]}
                                            onChange={async (value) => {

                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-3"}>
                                        <InputTailwind
                                            onBlur={() => {
                                                if (this.state.allow_update) {
                                                    this.functions.updateCrmData()
                                                }
                                            }}
                                            loading={this.state.loading_crm}
                                            locked={!this.state.allow_update}
                                            readOnly={!this.state.allow_update}
                                            onFocus={() => { }}
                                            label={"Reference (CRM)"}
                                            value={this.state.order.crmRef}
                                            onChange={(value) => {
                                                this.state.order.crmRef = value;
                                                this.promisedSetState({
                                                    order: this.state.order
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"Start date"}
                                                loading={this.state.loading_start_date}
                                                value={moment(this.state.order.startDate).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.state.order.startDate).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.order.startDate = moment(new_date);
                                                    await this.promisedSetState({
                                                        order: this.state.order
                                                    })
                                                    if (moment(this.state.order.startDate).format("YYYY-MM-DD") !== moment(this.state.org_order.startDate).format("YYYY-MM-DD")) {
                                                        this.functions.updateStartDate();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                label={"Start time"}
                                                loading={this.state.loading_start_time}
                                                value={moment(this.state.order.startDate).format("HH:mm")}
                                                onChange={async (value) => {
                                                    let date = moment(this.state.order.startDate).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    this.state.order.startDate = moment(new_date);
                                                    await this.promisedSetState({
                                                        order: this.state.order
                                                    })
                                                    if (moment(this.state.order.startDate).format("HH:mm") !== moment(this.state.org_order.startDate).format("HH:mm")) {
                                                        this.functions.updateStartTime();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                        <div className={(this.state.order.endDate ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                loading={this.state.loading_end_date}
                                                disabled={!this.state.order.endDate}
                                                labelRight={this.state.order.endDate ? "Disable" : "Enable"}
                                                placeholder={!this.state.order.endDate ? "No end date" : ""}
                                                value={moment(this.state.order.endDate).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.state.order.endDate).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.order.endDate = moment(new_date);
                                                    await this.promisedSetState({
                                                        order: this.state.order
                                                    })
                                                    if (moment(this.state.order.endDate).format("YYYY-MM-DD") !== moment(this.state.org_order.endDate).format("YYYY-MM-DD")) {
                                                        this.functions.updateEndDate();
                                                    }
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.state.order.endDate) {
                                                        this.state.order.endDate = null;
                                                    } else {
                                                        let new_date = moment(this.state.order.startDate).add(1, 'days');
                                                        this.state.order.endDate = new_date;
                                                    }
                                                    await this.promisedSetState({
                                                        order: this.state.order
                                                    });
                                                    if ((!this.state.order.endDate && this.state.org_order.endDate) || (this.state.order.endDate && !this.state.org_order.endDate)) {
                                                        this.functions.updateEndDate();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.order.endDate &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    loading={this.state.loading_end_time}
                                                    placeholder={!this.state.order.endDate ? "No end time" : ""}
                                                    disabled={!this.state.order.endDate}
                                                    value={moment(this.state.order.endDate).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.state.order.endDate).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.order.endDate = moment(new_date);
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                        if (moment(this.state.order.endDate).format("HH:mm") !== moment(this.state.org_order.endDate).format("HH:mm")) {
                                                            this.functions.updateEndTime();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-span-12">
                                        <TagManager
                                            order={this.state.order}
                                            label={"Add tag"}
                                            tags={this.state.order.tags ? this.state.order.tags : []}
                                            tagType={"order"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*SECTION INFORMATION*/}
                {
                    this.state.tab.id == 2 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                Assigned agents
                            </div>
                            <div className="w-full py-5 px-4">
                                <div className="grid-cols-12 gap-4 grid">
                                    <div className='col-span-12'>
                                        <div className="w-full">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Agents"}
                                                        small={false}
                                                        skipInternalSearch={true}
                                                        loader={this.state.loading_agents}
                                                        loadingPagination={this.state.loading_agents_pagination}
                                                        loadingSearch={this.state.loading_agents_search}
                                                        total={this.state.total}
                                                        searchInput={true}
                                                        placeholder={"..."}
                                                        pagination={this.state.total > (this.state.page * this.state.limit)}
                                                        selected={{ id: 0, name: "Select agent" }}
                                                        options={this.state.agent_options}
                                                        onChange={async (value) => {
                                                            if (!Array.isArray(this.state.order.agents)) {
                                                                this.state.order.agents = [];
                                                            }
                                                            this.state.order.agents.unshift({
                                                                email: value.name,
                                                                id: value.id
                                                            });
                                                            await this.promisedSetState({
                                                                order: this.state.order
                                                            });
                                                            this.functions.updateAgents();
                                                        }}
                                                        onPagination={async () => {
                                                            if (!this.state.loading_agents_pagination) {
                                                                await this.promisedSetState({
                                                                    page: this.state.page + 1
                                                                });
                                                                this.functions.getAgents(false, true, false);
                                                            }
                                                        }}
                                                        onSearch={async (value) => {
                                                            if (value && value === "") {
                                                                await this.promisedSetState({
                                                                    search: "",
                                                                    page: 1
                                                                });
                                                                this.functions.getAgents(false, false, true);
                                                            } else {
                                                                if (this.state.throttling.current) {
                                                                    //SKIP
                                                                } else {
                                                                    if (value && value === "") {
                                                                        await this.promisedSetState({
                                                                            search: value,
                                                                            page: 1
                                                                        });
                                                                        this.functions.getAgents(false, false, true);
                                                                    } else {
                                                                        this.state.throttling.current = true;
                                                                        await this.promisedSetState({
                                                                            search: value,
                                                                            throttling: this.state.throttling
                                                                        });
                                                                        setTimeout(async () => {
                                                                            this.state.throttling.current = false;
                                                                            await this.promisedSetState({
                                                                                throttling: this.state.throttling
                                                                            });
                                                                            await this.promisedSetState({
                                                                                page: 1
                                                                            });
                                                                            this.functions.getAgents(false, false, true);
                                                                        }, 300);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    Array.isArray(this.state.order.agents) && this.state.order.agents.length > 0 &&
                                                    <div className="col-span-6 -mt-4">
                                                        {
                                                            this.state.order.agents.map((item, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <div className={"bg-indigo-100 text-indigo-700 mt-2 flex flex-1 rounded-md items-center p-3 text-sm font-medium "}>
                                                                            <div className="flex flex-1">{item.email}</div>
                                                                            <button
                                                                                onClick={async () => {
                                                                                    this.state.order.agents = this.state.order.agents.filter((agent) => {
                                                                                        return agent.id !== item.id;
                                                                                    });
                                                                                    await this.promisedSetState({
                                                                                        order: this.state.order
                                                                                    });
                                                                                    this.functions.updateAgents();
                                                                                }}
                                                                                type="button"
                                                                                className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-8 w-8 rounded-md inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                            >
                                                                                <span className="sr-only">Remove agent</span>
                                                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*SHARED BUDGETS*/}
                {
                    this.state.tab.id == 3 &&
                    !this.state.loading &&
                    <div className="p-4 pb-2">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                Google
                            </div>
                            <div className="w-full relative">
                                {
                                    this.state.loading_google_budgets &&
                                    <div className="w-full h-full absolute bg-white top-0 bg-opacity-25 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-10 h-10 border-2 border-purple-500 z-10 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                {
                                    <div className="w-full py-5 px-4 sm:px-6 border-b">
                                        <div className="grid-cols-12 gap-4 grid">

                                            <div className={"col-span-12"}>
                                                <DropdownTailwind
                                                    label={"Select Google budget"}
                                                    selected={{ id: 0, name: "Select ..." }}
                                                    options={this.state.shared_google_budgets}
                                                    onChange={async (budget) => {
                                                        if (!this.state.loading_google_budgets) {
                                                            this.functions.setGoogleBudget(budget);
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {
                                                Array.isArray(this.state.order.googleBudgets) &&
                                                this.state.order.googleBudgets.map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={"col-span-6"}>
                                                                <InputTailwind
                                                                    onFocus={() => { }}
                                                                    label={item.period + " Budget " + item.status + " (" + item.id + ")"}
                                                                    value={item.new_amount ? item.new_amount : item.amount}
                                                                    onChange={(value) => {
                                                                        item.new_amount = value;
                                                                        this.setState({
                                                                            order: this.state.order
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={"col-span-3"}>
                                                                <InputTailwind
                                                                    readOnly={true}
                                                                    onFocus={() => { }}
                                                                    label={"Connected campaigns"}
                                                                    value={item.referenceCount}
                                                                    onChange={(value) => {

                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-span-3'>
                                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget planer</div>
                                                                <div className=' flex flex-1 h-12 items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                    <div className="flex flex-1 ml-5 text-sm font-medium">
                                                                        {item.budgetPlaner ? "Active" : "Disabled"}
                                                                    </div>
                                                                    <div className="relative overflow-hidden">
                                                                        <div className="pr-5">
                                                                            <SwitchTailwind
                                                                                value={item.budgetPlaner}
                                                                                onSwitch={async () => {
                                                                                    item.budgetPlaner = !item.budgetPlaner;
                                                                                    await this.promisedSetState({
                                                                                        order: this.state.order
                                                                                    });
                                                                                    this.functions.updateGoogleBudgets();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                false &&
                                                                <div className={"col-span-12 flex flex-row items-center justify-start"}>
                                                                    <div onClick={() => {
                                                                        if (!this.state.loading_google_budgets) {
                                                                            this.functions.removeGoogleBudget(item);
                                                                        }
                                                                    }} className="inline-flex cursor-pointer justify-center items-center hover:text-red-700 flex-row text-red-500">
                                                                        <TrashIcon className="w-5 mr-2"></TrashIcon>
                                                                        <div className="text-sm font-medium">Remove reference</div>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.functions.updateGoogleBudget(item);
                                                                    }} className={(item.new_amount && item.amount != item.new_amount ? "cursor-pointer hover:text-purple-600 text-purple-500 " : " cursor-not-allowed text-gray2-600") + " ml-8 items-center justify-center text-sm cursor-pointer font-medium  inline-flex flex-row"}>
                                                                        <SaveAsIcon className="w-5 mr-2" />
                                                                        Save budget
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                false &&
                                                                <div className={"col-span-6"}>
                                                                    <div className="flex flex-1 justify-end">
                                                                        {
                                                                            item.new_amount && item.amount != item.new_amount &&
                                                                            <button className='bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2'
                                                                                onClick={() => {
                                                                                    this.functions.updateGoogleBudget(item);
                                                                                }}>
                                                                                Update
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }

                                            {
                                                this.state.error_google &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm text-red-500">
                                                            {this.state.error_google}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow mt-4">
                            <div class="py-5 px-4 border-b font-semibold text-sm">
                                Bing
                            </div>
                            <div className="w-full relative">
                                {
                                    this.state.loading_bing_budgets &&
                                    <div className="w-full h-full absolute bg-white top-0 bg-opacity-25 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-10 h-10 border-2 border-purple-500 z-10 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                {
                                    <div className="w-full py-5 px-4 sm:px-6 border-b">
                                        <div className="grid-cols-12 gap-4 grid">

                                            <div className={"col-span-12"}>
                                                <DropdownTailwind
                                                    label={"Select Bing budget"}
                                                    selected={{ id: 0, name: "Select ..." }}
                                                    options={this.state.shared_bing_budgets}
                                                    onChange={async (budget) => {
                                                        if (!this.state.loading_bing_budgets) {
                                                            this.functions.setBingBudget(budget);
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {
                                                Array.isArray(this.state.order.bingBudgets) &&
                                                this.state.order.bingBudgets.map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={"col-span-6"}>
                                                                <InputTailwind
                                                                    onFocus={() => { }}
                                                                    label={(item.budget_type === "DailyBudgetStandard" ? "Starndard" : "Accelerated") + " Daily Budget " + " (" + item.id + ")"}
                                                                    value={item.new_amount ? item.new_amount : item.amount}
                                                                    onChange={(value) => {
                                                                        item.new_amount = value;
                                                                        this.setState({
                                                                            order: this.state.order
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={"col-span-3"}>
                                                                <InputTailwind
                                                                    readOnly={true}
                                                                    onFocus={() => { }}
                                                                    label={"Connected campaigns"}
                                                                    value={item.association_count}
                                                                    onChange={(value) => {

                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-span-3'>
                                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget planer</div>
                                                                <div className=' flex flex-1 h-12 items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                    <div className="flex flex-1 ml-5 text-sm font-medium">
                                                                        {item.budgetPlaner ? "Active" : "Disabled"}
                                                                    </div>
                                                                    <div className="relative overflow-hidden">
                                                                        <div className="pr-5">
                                                                            <SwitchTailwind
                                                                                value={item.budgetPlaner}
                                                                                onSwitch={async () => {
                                                                                    item.budgetPlaner = !item.budgetPlaner;
                                                                                    await this.promisedSetState({
                                                                                        order: this.state.order
                                                                                    });
                                                                                    this.functions.updateBingBudgets();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"col-span-12 flex flex-row items-center justify-start"}>
                                                                <div onClick={() => {
                                                                    if (!this.state.loading_bing_budgets) {
                                                                        this.functions.removeBingBudget(item);
                                                                    }
                                                                }} className="inline-flex cursor-pointer justify-center items-center hover:text-red-700 flex-row text-red-500">
                                                                    <TrashIcon className="w-5 mr-2"></TrashIcon>
                                                                    <div className="text-sm font-medium">Remove reference</div>
                                                                </div>
                                                                <div onClick={() => {
                                                                    this.functions.updateBingBudget(item);
                                                                }} className={(item.new_amount && item.amount != item.new_amount ? "cursor-pointer hover:text-purple-600 text-purple-500 " : " cursor-not-allowed text-gray2-600") + " ml-8 items-center justify-center text-sm cursor-pointer font-medium  inline-flex flex-row"}>
                                                                    <SaveAsIcon className="w-5 mr-2" />
                                                                    Save budget
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.error_bing &&
                                                <div className={"col-span-12"}>
                                                    <div className="bg-red-100 p-4 rounded-md">
                                                        <div className="text-sm text-red-500">
                                                            {this.state.error_bing}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }



                {/*CRM SECTION*/}
                {
                    this.state.tab.id == 4 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Budget split (new drafts)
                                </div>
                                <div className="flex flex-1">

                                </div>
                                <div className="flex flex-row justify-center items-center">
                                    <div className="text-sm mr-4">
                                        Edit sensitive data
                                    </div>
                                    <SwitchTailwind
                                        value={this.state.allow_update}
                                        onSwitch={async () => {
                                            this.setState({
                                                allow_update: !this.state.allow_update
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full">
                                {
                                    false &&
                                    <div className="w-full py-5 px-4 sm:px-6">
                                        <div className="grid-cols-12 gap-4 grid">
                                            <div className={"col-span-6"}>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.allow_update) {
                                                            this.functions.updateCrmData()
                                                        }
                                                    }}
                                                    loading={this.state.loading_crm}
                                                    locked={!this.state.allow_update}
                                                    readOnly={!this.state.allow_update}
                                                    onFocus={() => { }}
                                                    label={"Reference"}
                                                    value={this.state.order.crmRef}
                                                    onChange={(value) => {
                                                        this.state.order.crmRef = value;
                                                        this.promisedSetState({
                                                            order: this.state.order
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-2"}>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.allow_update) {
                                                            this.functions.updateSplitBudget()
                                                        }
                                                    }}
                                                    loading={this.state.loading_crmbudget}
                                                    locked={!this.state.allow_update}
                                                    readOnly={!this.state.allow_update}
                                                    onFocus={() => { }}
                                                    label={"Total monthly budget"}
                                                    value={this.state.order.crmBudget}
                                                    onChange={(value) => {
                                                        if (isNumber(value) && value >= 0) {
                                                            this.state.order.crmBudget = value;
                                                            if (this.state.order.budgetSplit) {
                                                                this.state.order.budgetSplit.budget = value;
                                                            }
                                                            this.promisedSetState({
                                                                order: this.state.order
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-2"}>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.allow_update) {
                                                            this.functions.updateCrmData()
                                                        }
                                                    }}
                                                    loading={this.state.loading_crm}
                                                    locked={!this.state.allow_update}
                                                    readOnly={!this.state.allow_update}
                                                    label={"Google monthly budget"}
                                                    value={this.state.order.crmBudgets["google"]}
                                                    onChange={(value) => {
                                                        this.state.order.crmBudgets["google"] = value;
                                                        this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-2"}>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.allow_update) {
                                                            this.functions.updateCrmData()
                                                        }
                                                    }}
                                                    loading={this.state.loading_crm}
                                                    locked={!this.state.allow_update}
                                                    readOnly={!this.state.allow_update}
                                                    label={"Bing monthly budget"}
                                                    value={this.state.order.crmBudgets["bing"]}
                                                    onChange={(value) => {
                                                        this.state.order.crmBudgets["bing"] = value;
                                                        this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.order.budgetSplit &&
                                    <div className={"w-full py-5 px-4 sm:px-6"}>
                                        <div className='grid grid-cols-10 gap-6'>

                                            <div className='col-span-2'>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget split</div>
                                                <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5">
                                                        {this.state.order.budgetSplit.value ? "Active" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={!this.state.allow_update}
                                                            value={this.state.order.budgetSplit.value}
                                                            onSwitch={async () => {
                                                                this.state.order.budgetSplit.value = !this.state.order.budgetSplit.value;
                                                                await this.promisedSetState({
                                                                    order: this.state.order
                                                                });
                                                                this.functions.updateSplitBudget();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-span-2'>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.order.budgetSplit.value) {
                                                            this.functions.updateSplitBudget()
                                                        }
                                                    }}
                                                    loading={this.state.loading_google}
                                                    locked={!this.state.order.budgetSplit.value}
                                                    readOnly={this.state.loading_google || !(this.state.order.budgetSplit.value)}
                                                    channel={"google"}
                                                    full={true}
                                                    label={"Google budget"}
                                                    value={this.state.order.budgetSplit.value ? +this.state.order.budgetSplit.google_budget : ""}
                                                    leftSection={true}
                                                    leftSectionLabel={"%"}
                                                    onChange={(value) => {
                                                        if (+value >= 0 && +value <= 100) {
                                                            this.state.order.budgetSplit.google_budget = +value;
                                                            //if (+value + +this.state.order.budgetSplit.bing_budget > 100) {
                                                            this.state.order.budgetSplit.bing_budget = 100 - +value;
                                                            //}
                                                            this.promisedSetState({
                                                                order: this.state.order
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className='col-span-2'>
                                                <InputTailwind
                                                    channel={"google"}
                                                    full={true}
                                                    label={"Monthly budget"}
                                                    value={this.state.order.budgetSplit.value ? round(+this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.google_budget / 100)) : ""}
                                                    locked={true}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className='col-span-2'>
                                                <InputTailwind
                                                    onBlur={() => {
                                                        if (this.state.order.budgetSplit.value) {
                                                            this.functions.updateSplitBudget()
                                                        }
                                                    }}
                                                    loading={this.state.loading_google}
                                                    locked={!this.state.order.budgetSplit.value}
                                                    readOnly={this.state.loading_google || !(this.state.order.budgetSplit.value)}
                                                    channel={"bing"}
                                                    full={true}
                                                    label={"Bing budget"}
                                                    leftSection={true}
                                                    leftSectionLabel={"%"}
                                                    value={this.state.order.budgetSplit.value ? +this.state.order.budgetSplit.bing_budget : ""}
                                                    onChange={(value) => {
                                                        if (+value >= 0 && +value <= 100) {
                                                            this.state.order.budgetSplit.bing_budget = +value;
                                                            //if (+value + +this.state.order.budgetSplit.google_budget > 100) {
                                                            this.state.order.budgetSplit.google_budget = 100 - +value;
                                                            //}
                                                            this.promisedSetState({
                                                                order: this.state.order
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className='col-span-2'>
                                                <InputTailwind
                                                    channel={"bing"}
                                                    full={true}
                                                    label={"Monthly budget"}
                                                    value={this.state.order.budgetSplit.value ? round(+this.state.order.budgetSplit.budget * (+this.state.order.budgetSplit.bing_budget / 100)) : ""}
                                                    locked={true}
                                                    disabled={true}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {/*CRM SECTION*/}
                {
                    false &&
                    this.state.tab.id == 4 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Planing
                                </div>
                                <div className="flex flex-1">

                                </div>
                                <div className="flex flex-row justify-center items-center">
                                    <div className="text-sm mr-4">
                                        Edit sensitive data
                                    </div>
                                    <SwitchTailwind
                                        value={this.state.allow_update}
                                        onSwitch={async () => {
                                            this.setState({
                                                allow_update: !this.state.allow_update
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-full pt-5">
                                {
                                    typeof this.state.order.crmIdHistory == "object" && this.state.order.crmIdHistory !== null &&
                                    Object.keys(this.state.order.crmIdHistory).length > 0 &&
                                    <div className="w-full py-5 pt-0 px-4 sm:px-6">
                                        <div className="grid-cols-12 gap-4 grid">
                                            {
                                                typeof this.state.order.crmIdHistory == "object" && this.state.order.crmIdHistory !== null &&
                                                Object.keys(this.state.order.crmIdHistory).map((item) => {
                                                    return (
                                                        <Fragment>
                                                            <div className={"col-span-6"}>
                                                                <InputTailwind
                                                                    locked={!this.state.allow_update}
                                                                    onFocus={() => { }}
                                                                    label={"Date (YYYY-MM-DD)"}
                                                                    value={item}
                                                                    onChange={(value) => {

                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={"col-span-6"}>
                                                                <InputTailwind
                                                                    locked={!this.state.allow_update}
                                                                    onFocus={() => { }}
                                                                    label={"Crm ID"}
                                                                    value={this.state.order.crmIdHistory[item]}
                                                                    onChange={(value) => {
                                                                        this.state.order.crmIdHistory[item] = value;
                                                                        this.setState({
                                                                            order: this.state.order
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.allow_update &&
                                    <div className="px-5 pb-5 flex flex-row items-center">
                                        <div className="flex flex-1 items-center pl-1">
                                            <div onClick={() => {
                                                if (!this.state.order.crmIdHistory) {
                                                    this.state.order.crmIdHistory = {};
                                                }
                                                if (!(moment().format("YYYY-MM-DD") in this.state.order.crmIdHistory)) {
                                                    this.state.order.crmIdHistory[moment().format("YYYY-MM-DD")] = "";
                                                }
                                                this.setState({
                                                    order: this.state.order
                                                });
                                            }} className="text-sm cursor-pointer font-medium hover:text-purple-600 text-purple-500 inline-flex flex-row">
                                                <PlusIcon className="w-5 mr-2" />
                                                Add new date
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (!this.state.loading_crm) {
                                                    this.functions.updateCrmData();
                                                }
                                            }}
                                            className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                        >
                                            Save
                                            {
                                                this.state.loading_crm &&
                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }


            </div>
        )
    }
}

export default OrderOverview;