import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";

class AdvertisingCreativesVariations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: this.props.channels?this.props.channels:{},
            variations: this.props.variations?this.props.variations:{},
            content: this.props.content,
            error: this.props.error
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels?nextProps.channels:{},
            variations: nextProps.variations?nextProps.variations:{},
            content: nextProps.content,
            error: nextProps.error
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            channels: this.props.channels?this.props.channels:{},
            variations: this.props.variations?this.props.variations:{},
            content: this.props.content,
            error: this.props.error
        });
    }

    init = {};

    functions = {};

    validators = {
        image: () => {
            try {
                return this.state.content.filter((item) => {
                    return item.image && !item.error && !item.button
                }).length === 1 && this.state.content.filter((item) => {
                    return !item.error && !item.button
                }).length < 2
            }catch (e) {
                return false
            }
        },
        video: () => {
            try {
                return this.state.content.filter((item) => {
                    return item.video && !item.error && !item.button
                }).length === 1 && this.state.content.filter((item) => {
                    return !item.error && !item.button
                }).length === 1
            }catch (e) {
                return false
            }
        }
    };

    renders = {
        index: (item) => {
            if(item.video){
                let index = 0;
                let videos = this.state.content.filter((item) => {
                    return item.video && !item.button && !item.error && item.url
                });
                for(let i = 0; i < videos.length; i++){
                    if(videos[i].id === item.id){
                        index = (i + 1);
                        break;
                    }
                }
                return 'video ' + index;
            }else if(item.image){
                let index = 0;
                let images = this.state.content.filter((item) => {
                    return item.image && !item.button && !item.error && item.url
                });
                for(let i = 0; i < images.length; i++){
                    if(images[i].id === item.id){
                        index = (i + 1);
                        break;
                    }
                }
                return 'image ' + index;
            }
        },
        error: () => {
            try{
                return this.state.content.filter((item) => {
                    return !item.error && !item.button
                }).length > 0 && this.state.content.filter((item) => {
                    return !item.error && !item.button && (item.show || item.carousel_show)
                }).length < 1 && this.state.error
            }catch (e) {
                return false
            }
        }
    };

    render() {
        if(this.state.content.filter((item) => {
            return item.url && !item.error && !item.button
        }).length > 0){
            return (
                <div style={{width: '100%', paddingTop: '20px'}}>
                    <div className="Section-Inner">
                        <div className="Section-InnerTitle">
                            <div className={cx("InputContainerTitleText")}>
                                Social combination
                            </div>
                            {
                                <div className={cx("InputContainerTitleIcons")}>
                                    {
                                        this.state.channels.facebook &&
                                        <div style={{marginLeft: '3px', marginTop: '2px'}}>
                                            <IconFacebook noMargin={true}/>
                                        </div>
                                    }
                                    {
                                        this.state.channels.instagram &&
                                        <div style={{marginLeft: '3px', marginTop: '2px'}}>
                                            <IconInstagram noMargin={true}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="RelatedSites-items">
                            {
                                this.state.content.filter((item) => {
                                    return item.image && !item.error
                                }).length > 0 &&
                                <div className={
                                    cx("Variations-Item",
                                        {
                                            ["Variations-ItemError"]: this.renders.error(),
                                        })
                                }>
                                    <div className="Variations-ItemLine">
                                        <FeatherIcon color={"#333333"} size={22} icon="image"/>
                                        <div style={{marginLeft: '10px'}} className="Variations-ItemLineTitle">
                                            Image
                                        </div>
                                        <div className="Variations-ItemLineSlider">
                                            <Switch
                                                onColor="#24bda8"
                                                checked={this.state.variations.image}
                                                onChange={(checked) => {
                                                    this.state.variations.image = checked;
                                                    this.state.content.filter((item) => {
                                                        return item.url && !item.error && !item.button && item.image
                                                    }).map((item) => {
                                                        item.show = checked;
                                                    });
                                                    this.props.onContent(this.state.content);
                                                    this.props.onChange(this.state.variations);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.variations.image &&
                                        this.state.content.filter((item) => {
                                            return item.url && !item.error && !item.button && item.image
                                        }).map((item, index) => {
                                            return(
                                                <div style={{width: '100%'}}>
                                                    <div className="Variations-ItemBorder"></div>
                                                    <div className="Variations-ItemLine">
                                                        <FeatherIcon className="Variations-ItemLineIconCarousel" color={"#333333"} size={22} icon="corner-down-right"/>
                                                        <div className="Variations-ItemLineTitle Variations-ItemLineTitleIcon">
                                                            {this.renders.index(item)}
                                                            {
                                                                (item.ratio === '9:16') &&
                                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical format</div>
                                                            }
                                                        </div>
                                                        <div className="Variations-ItemLineSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={item.show}
                                                                onChange={(checked) => {
                                                                    item.show = checked;
                                                                    this.setState({
                                                                        content: this.state.content
                                                                    }, () => {
                                                                        this.props.onContent(this.state.content);
                                                                        this.props.onChange(this.state.variations);
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.content.filter((item) => {
                                    return item.video && !item.error
                                }).length > 0 &&
                                <div className={
                                    cx("Variations-Item",
                                        {
                                            ["Variations-ItemError"]: this.renders.error(),
                                        })
                                }>
                                    <div className="Variations-ItemLine">
                                        <FeatherIcon color={"#333333"} size={22} icon="video"/>
                                        <div style={{marginLeft: '10px'}} className="Variations-ItemLineTitle">
                                            Video
                                        </div>
                                        <div className="Variations-ItemLineSlider">
                                            <Switch
                                                onColor="#24bda8"
                                                checked={this.state.variations.video}
                                                onChange={(checked) => {
                                                    this.state.variations.video = checked;
                                                    this.state.content.filter((item) => {
                                                        return item.url && !item.error && !item.button && item.video
                                                    }).map((item) => {
                                                        if(item.thumbnail && checked){
                                                            item.show = checked;
                                                        }else{
                                                            item.show = false;
                                                        }
                                                    });
                                                    this.props.onContent(this.state.content);
                                                    this.props.onChange(this.state.variations);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.variations.video &&
                                        this.state.content.filter((item) => {
                                            return item.url && !item.error && !item.button && item.video
                                        }).map((item, index) => {
                                            return(
                                                <div style={{width: '100%'}}>
                                                    <div className="Variations-ItemBorder"></div>
                                                    <div className="Variations-ItemLine">
                                                        <FeatherIcon className="Variations-ItemLineIconCarousel" color={"#333333"} size={22} icon="corner-down-right"/>
                                                        <div className="Variations-ItemLineTitle Variations-ItemLineTitleIcon">
                                                            {this.renders.index(item)}
                                                            {
                                                                !item.thumbnail &&
                                                                <div className="Variations-ItemLineTitleIconError">{" "}thumbnail is missing</div>
                                                            }
                                                            {
                                                                (item.ratio === '9:16') &&
                                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical format</div>
                                                            }
                                                        </div>
                                                        <div className="Variations-ItemLineSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                checked={item.show}
                                                                disabled={!item.thumbnail}
                                                                onChange={(checked) => {
                                                                    item.show = checked;
                                                                    this.setState({
                                                                        content: this.state.content
                                                                    }, () => {
                                                                        this.props.onContent(this.state.content);
                                                                        this.props.onChange(this.state.variations);
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.content.filter((item) => {
                                    return !item.button && !item.error
                                }).length > 1 && this.state.content.filter((item) => {
                                    return !item.button && !item.error && item.ratio === '1:1'
                                }).length > 1 &&
                                <div className={
                                    cx("Variations-Item",
                                        {
                                            ["Variations-ItemError"]: this.renders.error(),
                                        })
                                }>
                                    <div className="Variations-ItemLine">
                                        <FeatherIcon color={"#333333"} size={22} icon="sidebar"/>
                                        <div style={{marginLeft: '10px'}} className="Variations-ItemLineTitle">
                                            Carousel
                                        </div>
                                        <div className="Variations-ItemLineSlider">
                                            <Switch
                                                onColor="#24bda8"
                                                checked={this.state.variations.carousel}
                                                onChange={(checked) => {
                                                    this.state.variations.carousel = checked;
                                                    this.state.content.filter((item) => {
                                                        return item.url && !item.error && !item.button
                                                    }).map((item) => {
                                                        if(item.ratio === '1:1'){
                                                            item.carousel_show = checked;
                                                        }else{
                                                            item.carousel_show = false;
                                                        }
                                                    });
                                                    this.props.onContent(this.state.content);
                                                    this.props.onChange(this.state.variations);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.variations.carousel &&
                                        this.state.content.filter((item) => {
                                            return item.url && !item.error && !item.button
                                        }).map((item, index) => {
                                            return(
                                                <div style={{width: '100%'}}>
                                                    <div className="Variations-ItemBorder"></div>
                                                    <div className="Variations-ItemLine">
                                                        <FeatherIcon className="Variations-ItemLineIconCarousel" color={"#333333"} size={22} icon="corner-down-right"/>
                                                        <div className="Variations-ItemLineTitle Variations-ItemLineTitleIcon">
                                                            {this.renders.index(item)}
                                                            {
                                                                item.ratio !== '1:1' &&
                                                                <span className="Variations-ItemLineTitleIconError">{" "}disabled because of aspect ratio, must be 1 : 1</span>
                                                            }
                                                            {
                                                                !item.thumbnail && item.video &&
                                                                <span className="Variations-ItemLineTitleIconError">{" "}thumbnail is missing</span>
                                                            }
                                                            {
                                                                (item.ratio === '9:16') &&
                                                                <div className="Variations-ItemLineTitleIconRatio">{" "}Vertical format</div>
                                                            }
                                                        </div>
                                                        <div className="Variations-ItemLineSlider">
                                                            <Switch
                                                                onColor="#24bda8"
                                                                disabled={item.ratio !== '1:1' || (item.video && !item.thumbnail)}
                                                                checked={item.carousel_show}
                                                                onChange={(checked) => {
                                                                    item.carousel_show = checked;
                                                                    this.setState({
                                                                        content: this.state.content
                                                                    }, () => {
                                                                        this.props.onContent(this.state.content);
                                                                        this.props.onChange(this.state.variations);
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }else{
            return(<div></div>)
        }
    }
}

export default AdvertisingCreativesVariations;
