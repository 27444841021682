import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api
    };

    static getPixels(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api  + '/client/pixel/facebook/' + data.client;
        return apiRegister.call(options, url);
    }

    static getBusinesses(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/facebook/business';
        return apiRegister.call(options, url);
    }

    static requestPage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/facebook/request/page';
        return apiRegister.call(options, url);
    }

    static getPendingPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = apiRegister.url.api  + '/client/facebook/pending/page';
        return apiRegister.call(options, url);
    }

}

export {calls}