import React, { Component } from 'react';
import PreviewGooglePerformanceMaxDiscover from './performanceMaxDiscover';
import PreviewGooglePerformanceMaxDisplay from './performanceMaxDisplay';
import PreviewGooglePerformanceMaxGmail from './performanceMaxGmail';
import PreviewGooglePerformanceMaxYoutube from './performanceMaxYoutube';

class PreviewGooglePerformanceMax extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: {},
            videos: [],
            images: [],
            headlines: [],
            descriptions: [],
            long_headlines: [],
            business: "",
            website: "",
            cta: {}
        }
    };

    componentDidMount() {
        this.setState({
            channel: this.props.channel,
            videos: this.props.videos,
            images: this.props.images,
            headlines: this.props.headlines,
            descriptions: this.props.descriptions,
            long_headlines: this.props.long_headlines,
            business: this.props.business,
            website: this.props.website,
            cta: this.props.cta
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            channel: nextProps.channel,
            videos: nextProps.videos,
            images: nextProps.images,
            headlines: nextProps.headlines,
            descriptions: nextProps.descriptions,
            long_headlines: nextProps.long_headlines,
            business: nextProps.business,
            website: nextProps.website,
            cta: nextProps.cta
        })
    }

    renders = {}

    render() {
        return (
            <div style={{ width: "500px" }} className={"w-full border rounded-md p-4 bg-white flex items-center justify-center"}>
                {
                    this.state.channel && this.state.channel.value === "youtube" &&
                    <PreviewGooglePerformanceMaxYoutube
                        business={this.state.business}
                        website={this.state.website}
                        images={this.state.images}
                        headlines={this.state.headlines}
                        descriptions={this.state.descriptions}
                        long_headlines={this.state.long_headlines}
                    />
                }
                {
                    this.state.channel && this.state.channel.value === "gmail" &&
                    <PreviewGooglePerformanceMaxGmail
                        business={this.state.business}
                        website={this.state.website}
                        images={this.state.images}
                        descriptions={this.state.descriptions}
                        headlines={this.state.headlines}
                        long_headlines={this.state.long_headlines}
                    />
                }
                {
                    this.state.channel && this.state.channel.value === "display" &&
                    <PreviewGooglePerformanceMaxDisplay
                        business={this.state.business}
                        website={this.state.website}
                        images={this.state.images}
                        descriptions={this.state.descriptions}
                        headlines={this.state.headlines}
                        long_headlines={this.state.long_headlines}
                    />
                }
                {
                    this.state.channel && this.state.channel.value === "discover" &&
                    <PreviewGooglePerformanceMaxDiscover
                        business={this.state.business}
                        website={this.state.website}
                        images={this.state.images}
                        descriptions={this.state.descriptions}
                        headlines={this.state.headlines}
                        long_headlines={this.state.long_headlines}
                    />
                }
            </div>
        )
    }
}

export default PreviewGooglePerformanceMax;
