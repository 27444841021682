import { apiRegister } from "../../../services/apiRegister";
import { tokenRegister } from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getBehaviors(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/facebook/behaviors";
        return apiRegister.call(options, url);
    }

    static getInterests(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/facebook/interests";
        return apiRegister.call(options, url);
    }

    static getFacebookAudience(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/facebook/estimate";
        return apiRegister.call(options, url);
    }

    static getAudience(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = apiRegister.url.api + '/v2/audience?channel=' + data.channel + '&client=' + data.client;
        return apiRegister.call(options, url);
    }

    static getPages(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listPages?client=" + data.client;
        return apiRegister.call(options, url);
    }

    static getEvents(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listEvents?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static getApps(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listApps?client=" + data.client;
        return apiRegister.call(options, url);
    }

    static createPageToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', null);
        let url = calls.url.api + "/v3/facebook/createPageToken?client=" + data.client + (data.page ? ("&page=" + data.page) : "");
        return apiRegister.call(options, url);
    }

    static getInstagramAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v3/facebook/listInstagramAccounts?client=" + data.client + (data.page ? ("&page=" + data.page) : "") + (data.page_token ? ("&pagetoken=" + encodeURIComponent(data.page_token)) : "");
        return apiRegister.call(options, url);
    }

}

export { calls }