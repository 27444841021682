import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class AdvertisingShoppingTemplatePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            shopping: {},
            width: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            shopping: nextProps.shopping,
            shoppingName: nextProps.shoppingName,
            width: nextProps.width
        });
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
            shopping: this.props.shopping,
            shoppingName: this.props.shoppingName,
            width: this.props.width
        });
    }

    functions = {};

    init = {};

    renders = {
        products: () => {
            return (this.state.shopping&&Array.isArray(this.state.shopping.products))?this.state.shopping.products:[]
        },
        title: (item) => {
            return item.slice(0, 23) + '...';
        },
        type: () => {
            return (this.state.item && this.state.item.shopping_campaigntype && this.state.item.shopping_campaigntype.value)?this.state.item.shopping_campaigntype.value:"regular";
        }
    };

    render() {
        return (
            <div>
                {
                    this.renders.type() === 'smart' &&
                    <div style={{maxWidth: '662px'}} className="bg-white p-4 rounded">
                        <div className="flex flex-wrap flex-row">
                            {
                                this.renders.products().filter((item, index) => {return index < 6}).map((item) => {
                                    return(
                                        <div className="border rounded-md bg-white mr-2 overflow-hidden mb-2">
                                            <div style={{height: '200px'}} className="w-full overflow-hidden">
                                                <img src={item.imageLink} style={{height: '100%'}}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex justify-center align-middle items-center py-2 pb-0">
                            {this.state.shoppingName}
                        </div>
                    </div>
                }
        
                {
                    this.renders.type() === 'regular' &&
                    <div style={this.state.width=="full"?{}:{maxWidth: "600px"}} className="bg-white p-4 rounded">
                        <div className="mb-2">
                            <span className="font-bold">Ads</span> <span>·</span> <span className="">Shop</span>
                        </div>
                        <div className="overflow-scroll flex flex-row bg-white">
                            {
                                this.renders.products().map((item) => {
                                    return(
                                        <div style={{minWidth: '122px'}} className="border rounded-md bg-white mr-2 overflow-hidden flex flex-col">
                                            <div style={{height: '122px'}} className="w-full overflow-hidden">
                                                <img src={item.imageLink} style={{height: '100%'}}/>
                                            </div>
                                            <div style={{color: "#1a0dab"}} className="p-2 pb-1 text-xs line-clamp-2">
                                                {this.renders.title(item.title)}
                                            </div>
                                            <div style={{color: "#202124"}} className="font-bold p-2 pt-0 text-xs">
                                                {item.price?item.price.value:''}
                                            </div>
                                            <div className="flex flex-1">

                                            </div>
                                            <div style={{color: "#1a0dab"}} className='p-2 text-xs'>
                                                By {this.state.shoppingName}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingShoppingTemplatePreview;
