import React, {Component, Fragment} from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle, Polygon
} from "react-google-maps";

class RegionsNewMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            place: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            place: nextProps.place,
        }, () => {
            this.functions.init();
        });
    }

    componentDidMount() {
        this.setState({
            place: this.props.place
        }, () => {
            this.functions.init();
        });
    }

    functions = {
        init: () => {
            if(this.state.place){
                let place = this.state.place;
                var bounds = new window.google.maps.LatLngBounds();
                window.google.maps.Polygon.prototype.getBounds = function () {
                    var bounds = new window.google.maps.LatLngBounds();
                    var paths = this.getPaths();
                    for (var i = 0; i < paths.getLength(); i++) {
                        var path = paths.getAt(i);
                        for (var j = 0; j < path.getLength(); j++) {
                            bounds.extend(path.getAt(j));
                        }
                    }
                    return bounds;
                };
                for (let i = 0; i < place.paths.length; i++) {
                    var polygon = new window.google.maps.Polygon({
                        paths: place.paths[i].paths
                    });
                    bounds.union(polygon.getBounds());
                }
                this.map.fitBounds(bounds);
            }
        }
    };

    renders = {};

    render() {
        const defaultMapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };
        return (
            <GoogleMap
                defaultOptions={defaultMapOptions}
                ref={(ref) => {
                    this.map = ref;
                }}
            >
                {
                    this.state.place &&
                    <Fragment key={this.state.place.id}>
                        {
                            this.state.place.paths.map((polygon, index) => {
                                let paths = [];
                                paths.push(polygon.paths);
                                return(
                                    <Fragment key={index}>
                                        <Polygon
                                            onClick={() => {
                                                polygon.include = !polygon.include;
                                                this.setState({
                                                    place: this.state.place
                                                }, () => {
                                                    this.props.updatePlace(this.state.place);
                                                });
                                            }}
                                            paths={paths}
                                            options={{
                                                zIndex: 1,
                                                strokeWeight: "2",
                                                strokeColor: polygon.include?"#0185fe":"#333333",
                                                fillColor: polygon.include?"#0185fe":"#333333"
                                            }}/>
                                    </Fragment>
                                )
                            })
                        }
                    </Fragment>
                }
            </GoogleMap>
        );
    }

}

export default withGoogleMap(RegionsNewMap);