import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import translations from '../assets/json/onboarding_translation.json'


class ClientOrderStepCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            order: {},
            drafts: [],
            steps: [
                { id: 1, name: "Step 1", complete: false, current: false, type: "step" },
                { id: 2, name: "", complete: false, current: false, type: "divider" },
                { id: 3, name: "Step 2", complete: false, current: false, type: "step" },
                { id: 4, name: "", complete: false, current: false, type: "divider" },
                { id: 5, name: "Step 3", complete: false, current: false, type: "step" },
                { id: 6, name: "", complete: false, current: false, type: "divider" },
                { id: 7, name: "Step 4", complete: false, current: false, type: "step" },
                { id: 8, name: "", complete: false, current: false, type: "divider" },
                { id: 9, name: "Step 5", complete: false, current: false, type: "step" },
                { id: 10, name: "", complete: false, current: false, type: "cancel" }
            ],
            current_step: 0,
            whitelabel_settings: {},
        }
    };

    componentWillMount() { }

    componentDidMount() {
        this.init.user();
        this.functions.initProgress();
        console.log(this.state.user)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
    };

    functions = {
        getOrder: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getOrder();
                await this.promisedSetState({ order: response.data.order, drafts: response.data.drafts });
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                console.log(error);
                return null
            }
        },
        initProgress: async () => {
            if (this.functions.getIdFromPath()) {
                await this.functions.getOrder();
            }
            if (Object.keys(this.state.order).length !== 0) {
                await this.promisedSetState({ current_step: this.state.order.clientStatus.step });
            }
            // FILL PROGRESS BAR
            if (this.state.current_step !== 0) {
                let steps = this.state.steps;
                for (let i = 0; i < steps.length; i++) {
                    // IF STEP STRING IS EQUAL TO CURRENT STEP THEN TRUE
                    if (steps[i].name === "Step " + this.state.current_step) {
                        steps[i].complete = true;
                        break;
                    }

                    // IF STEP STRING IS LESS THAN CURRENT STEP THEN TRUE
                    if (steps[i].name < "Step " + this.state.current_step) {
                        steps[i].complete = true;
                    }

                }
                await this.promisedSetState({ steps: steps });
            }
        }

    };

    renders = {};

    calls = {
        getOrder: async () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/getOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        }
    };

    translate = (text) => {
        let lang;
        let key = "step"

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let t = this.translate;
        let order = this.state.order;
        return (
            <>
                {console.log("!", this.state)}
                {/* PROGRESS BAR */}
                <div className="relative flex  w-full h-16 bg-white justify-center items-center">
                    <div className='flex space-x-1 items-center'>
                        {
                            this.state.steps.map((step, index) => {
                                return (
                                    <div className='col-span-1'>
                                        {step.type === "step" &&
                                            <div className='flex flex-col items-center'>
                                                <div className={'flex justify-center text-center items-center text-xs text-gray-600 w-5 h-5 rounded-full ' + (step.current ? 'bg-white border border-gray-600' : (step.complete ? 'bg-green-400' : 'bg-gray-300'))}>
                                                    {
                                                        step.complete ?
                                                            <CheckCircleIcon className="h-full w-full text-white" /> :
                                                            <span>{step.name.replace("Step", "")}</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {step.type === "divider" &&
                                            <div className='hidden md:flex justify-center items-center'>
                                                <div className={'w-8 ' + (step.current ? 'bg-black' : (step.complete ? 'bg-green-500' : 'bg-gray-300'))} style={{ height: 2 }}></div>
                                            </div>
                                        }
                                        {
                                            step.type === "cancel" &&
                                            <div className='absolute flex justify-center items-center top-0 bottom-0 right-0 mr-4'>
                                                <div className='bg-red-500 hover:bg-red-400 rounded-full p-1 md:p-2 cursor-pointer'
                                                    // onClick={() => { this.props.history.push('/client/orders') }}
                                                    onClick={() => { window.close() }}
                                                >
                                                    <XIcon className='w-5 h-5 text-white' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* CONTENT */}
                <div className='flex flex-col h-full p-4 space-y-4 justify-between'>

                    <div className='flex flex-col justify-between h-full bg-white rounded-md shadow p-4 relative'>
                        {
                            this.state.order._id &&
                            <>
                                <div className='flex flex-col h-full'>
                                    <div className='flex flex-col text-center mb-4'>
                                        <span className='text-2xl font-bold'>{order.name}</span>
                                    </div>

                                    {/* IF STATUS IS PENDING OR REVIEW */}
                                    {
                                        (order.clientStatus.id === 1 || order.clientStatus.id === 2) &&
                                        <>
                                            <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                                                <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                                                    style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}>
                                                </div>
                                                <div className="font-semibold mt-3">
                                                    {
                                                        order.clientStatus.id === 1 &&
                                                        <>
                                                            {t("check_pending")} ...
                                                        </>
                                                    }
                                                    {
                                                        order.clientStatus.id === 2 &&
                                                        <>
                                                            {t("check_review")} ...
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {/* IF DRAFTS ARE AVAILABLE */}
                                    {
                                        this.state.drafts.length !== 0 && this.state.current_step > 2 &&
                                        <>
                                            <div className='flex flex-col space-y-4'>
                                                {console.log("state drafts", this.state)}
                                                {
                                                    this.state.drafts.map((draft, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='hidden md:flex flex-col rounded-lg bg-gray-50 p-4 shadow md:justify-between'
                                                            >
                                                                <div className='flex justify-between items-center mb-4'>
                                                                    <div className="font-medium text-sm">
                                                                        {draft.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    }


                                    {
                                        false &&
                                        <div className='flex w-full justify-end mt-4'>
                                            <button
                                                onClick={() => { this.props.history.push('/client/order/step-two') }}
                                                className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white'>
                                                Next
                                            </button>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div >
            </>
        );
    }
}


export default ClientOrderStepCheck;
