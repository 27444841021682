import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/client/" + data.id;
        return apiRegister.call(options, url);
    }

    static getReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/report/" + data.id;
        return apiRegister.call(options, url);
    }

    static saveReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/report";
        return apiRegister.call(options, url);
    }

    static updateReport(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/report/" + data.id;
        return apiRegister.call(options, url);
    }

    static getMetrics(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/metrics?custom=true";
        return apiRegister.call(options, url);
    }
    
    /*
    static getCampaigns(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/campaign/" + data.id + data.query;
        return apiRegister.call(options, url);
    }
    */

    static getCampaignsInformation(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', null);
        let url = calls.url.api + "/v2/controllers/campaignInformation?order=" + data.id;
        return apiRegister.call(options, url);
    }

    static getCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/campaign/" + data.id + "?externals=" + data.externals + "&insights=true&metrics=all";
        return apiRegister.call(options, url);
    }

    /*
    static getBulkCampaigns(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/campaign/" + data.id + "?externals=" + data.externals + data.query;
        return apiRegister.call(options, url);
    }
    */

    static getBulkCampaigns(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/reporting/campaign?order=" + data.id + data.query;
        return apiRegister.call(options, url);
    }

    static getCampaignsReport(order, body) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', body);
        let url = calls.url.api + "/v2/controllers/campaignReport?order=" + order;
        return apiRegister.call(options, url);
    }

    static getAdgroups(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/adgroup/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign +"&information=true";
        return apiRegister.call(options, url);
    }

    static getAdgroup(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/adgroup/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroup=" + data.adgroup + "&insights=true&metrics=all";
        return apiRegister.call(options, url);
    }

    static getBulkAdgroups(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/adgroup/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroups=" + data.adgroups + data.query;
        return apiRegister.call(options, url);
    }

    static getAds(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/ad/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroup=" + data.adgroup + data.query;
        return apiRegister.call(options, url);
    }

    static getBulkAds(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/ad/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroup=" + data.adgroup + "&ads=" + data.ads + data.query;
        return apiRegister.call(options, url);
    }

    static getAd(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/ad/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroup=" + data.adgroup + "&ad=" + data.ad + "&insights=true&metrics=all";
        return apiRegister.call(options, url);
    }

}

export {calls}