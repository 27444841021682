import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import AccordionStatic from "../accordion-static";
import Dropdown from "../dropdown";
import {dataRegister} from "../../services/dataRegister";
import AccordionFixed from "../accordion-fixed";
import {calls} from "./calls";
import Input from "../input";
import {BeatLoader} from "react-spinners";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import FacebookImage from "../facebook-image";
import FacebookLocation from "../facebook-location";
import Switch from 'react-ios-switch';
import CreativeFacebook from "../creative-facebook";

class Facebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {
                gender: {name: 'Male and Female', value: 0},
                age_min: {name: 18, value: 18},
                age_max: {name: '65+', value: 65},
                location: "",
                locations: [],
                locations_selected: [],
                audience: 0,
                estimate: 0
            },
            budget: {
                duration: {},
                amount: 0,
                estimate: {
                    clicks: 0,
                    impressions: 0
                }
            },
            settings: {},
            facebook: {},
            instagram: {},
            site: {},
            client: {},
            creative: {},
            toggle: {},
            channels: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {

    }

    componentWillMount() {}

    componentDidMount() {

    }

    init = {
        facebook: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '1813873645538492',
                    version: 'v4.0',
                    redirect_uri: '',
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        values: () => {
            try {
                let values = JSON.parse(sessionStorage.getItem('facebook'));
                if (values) {
                    this.setState({
                        toggle: values.toggle ? values.toggle : {},
                        creative: values.creative ? values.creative : {},
                        targeting: values.targeting ? values.targeting : this.state.targeting,
                        budget: values.budget ? values.budget : this.state.budget,
                    });
                }
            } catch (e) {
            }
        }
    };

    functions = {
        reset: (step) => {
            if (step === 'site') {
                this.state.toggle.creative = false;
                this.state.toggle.budget = false;
                this.state.toggle.targeting = false;
                this.state.toggle.channels = false;
                this.setState({
                    creative: {},
                    targeting: {
                        gender: {name: 'Male and Female', value: 0},
                        age_min: {name: 18, value: 18},
                        age_max: {name: '65+', value: 65},
                        location: "",
                        locations: [],
                        locations_selected: [],
                        audience: 0,
                        estimate: 0
                    },
                    budget: {
                        duration: {},
                        estimate: {
                            clicks: 0,
                            impressions: 0
                        }
                    },
                    channels: {
                        facebook: false
                    },
                    toggle: this.state.toggle
                }, () => {
                    this.functions.save();
                })
            }

        },
        geoTargeting: (search) => {
            this.state.targeting.locations = [];
            this.setState({
                targeting: this.state.targeting
            }, () => {
                calls.getGeoTargeting(this.state.facebook.facebookUserToken, search).then((response) => {
                    if (search !== "") {
                        this.state.targeting.locations = response.data;
                        this.setState({
                            targeting: this.state.targeting
                        });
                    } else {
                        this.state.targeting.locations = [];
                        this.setState({
                            targeting: this.state.targeting
                        })
                    }
                }, (error) => {
                    this.state.targeting.locations = [];
                    this.setState({
                        targeting: this.state.targeting
                    })
                });
            })
        },
        estimate: () => {

        },
        audience: () => {

        },
        campaign: () => {
            let name = "";
            let objective = "LINK_CLICKS";
            let bid_strategy = "LOWEST_COST_WITHOUT_CAP";
            calls.createCampaign(this.state.facebook.facebookAdAccountId, this.state.facebook.facebookUserToken, name, objective, bid_strategy).then((response) => {
                //console.log(response);
            }, (error) => {

            });
        },
        adset: () => {
            let name = "";
            let campaignId = "";
            let optimization_goal = "LINK_CLICKS";
            let billing_event = "LINK_CLICKS";
            let targeting = {
                geo_locations: {
                    countries: this.state.targeting.locations_selected.filter((item) => {
                        return item.type === 'country'
                    }).map((item) => {
                        return item.country_code
                    }),
                    cities: this.state.targeting.locations_selected.filter((item) => {
                        return item.type === 'city'
                    }).map((item) => {
                        return {key: item.key}
                    })
                },
                genders: this.state.targeting.gender.value === 0 ? [] : (this.state.targeting.gender.value === 1 ? [1] : [2]),
                age_min: this.state.targeting.age_min.value,
                age_max: this.state.targeting.age_max.value,
                publisher_platforms: ['facebook'],
                facebook_positions: ['feed'],
                device_platforms: ['mobile', 'desktop']
            };
            let start_time = null;
            let end_time = null;
            let status = "PAUSED";
        },
        ad: () => {

        },
        payment: () => {
            let self = this;
            window.FB.ui({
                account_id: this.state.adaccount.account_id,
                display: 'popup',
                method: 'ads_payment',
            }, function (response) {
                calls.getAdaccount(self.state.facebook.facebookAdAccountId, self.state.facebook.facebookUserToken).then((response) => {
                    self.setState({
                        adaccount: response
                    }, () => {
                        self.functions.save();
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        },
        save: () => {
            let object = {
                token: this.state.token,
                userName: this.state.userName,
                userId: this.state.userId,
                business: this.state.business,
                businesses: this.state.businesses,
                adaccount: this.state.adaccount,
                adaccounts: this.state.adaccounts,
                page: this.state.page,
                pages: this.state.pages,
                creative: this.state.creative,
                targeting: this.state.targeting,
                budget: this.state.budget,
                toggle: this.state.toggle
            };
            try {
                sessionStorage.setItem("facebook", JSON.stringify(object));
            } catch (e) {}
        }
    };

    renders = {
        age: (min, max) => {
            let age = [];
            for (let i = min; i < max; i++) {
                let object = {name: i, value: i};
                if (i === 65) {
                    object.name = '65+';
                }
                age.push(object);
            }
            return age;
        },
        creative: () => {
            try {
                return this.state.creative.name && this.state.creative.body.length > 1 && this.state.creative.image && this.state.creative.title.length > 1 && this.state.creative.description.length > 1
            } catch (e) {
                return false
            }
        },
        channels: () => {
            try {
                return this.state.channels.facebook
            }catch (e) {
                return false
            }
        },
        location: () => {

        },
        targeting: () => {
            try {
                return this.state.targeting.locations_selected.length > 0
            } catch (e) {
                return false
            }
        },
        budget: () => {
            try {
                return this.state.budget.duration.name && this.state.budget.amount > 0
            } catch (e) {
                return false
            }
        },
        number: (x) => {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    };

    render() {
        return (
            <div className="Facebook">
                <AccordionFixed
                    icon={this.renders.channels() ? "check" : ""}
                    icon_color={"#0185fe"}
                    open={this.state.toggle.channels}
                    title={"Channels"}
                    subtitle={""}
                    edit={this.state.settings.name && this.state.client.name && this.state.site.name}
                    onEdit={() => {
                        this.state.toggle.channels = !this.state.toggle.channels;
                        this.setState({
                            toggle: this.state.toggle
                        }, () => {
                            this.functions.save();
                        })
                    }}
                >
                    <div style={{textAlign: 'left'}} className="Section">
                        Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                        usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                        molestiae est.
                    </div>
                    <div className="Section">
                        <div className="RelatedSites-items">
                            {
                                this.state.facebook &&
                                <div className="RelatedSites-itemsItem">
                                    <div className="RelatedSites-itemsItemTitle">
                                        Facebook
                                    </div>
                                    <div className="RelatedSites-itemsItemSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            checked={this.state.channels.checked}
                                            onChange={(checked) => {
                                                this.state.channels.facebook = checked;
                                                this.setState({
                                                    channels: this.state.channels
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </AccordionFixed>
                <AccordionFixed
                    noPadding={true}
                    icon={this.renders.creative() ? "check" : ""}
                    icon_color={"#0185fe"}
                    open={this.state.toggle.creative}
                    title={"Creative"}
                    subtitle={""}
                    edit={this.state.settings.name && this.state.client.name && this.state.site.name && (this.renders.channels())}
                    onEdit={() => {
                        this.state.toggle.creative = !this.state.toggle.creative;
                        this.setState({
                            toggle: this.state.toggle
                        }, () => {
                            this.functions.save();
                        })
                    }}
                >
                    <div style={{textAlign: 'left', padding: '15px', paddingTop: '10px'}} className="Section">
                        Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                        usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                        molestiae est.
                    </div>
                    <CreativeFacebook
                        border={this.state.facebook.valid}
                        site={this.state.site}
                        creative={this.state.creative}
                        facebook={this.state.facebook}
                    />
                    {
                        this.state.creative.facebook &&
                        <div style={{padding: '15px'}} className="Section">
                            <div onClick={() => {
                                if (this.renders.creative()) {
                                    this.state.toggle.creative = false;
                                    this.state.toggle.targeting = true;
                                    this.setState({
                                        toggle: this.state.toggle
                                    }, () => {
                                        this.functions.save();
                                    })
                                } else {
                                    if (this.state.creative.title.length < 1) {
                                        this.setState({
                                            error: "creative_title"
                                        })
                                    } else if (this.state.creative.body.length < 1) {
                                        this.setState({
                                            error: "creative_body"
                                        })
                                    } else if (this.state.creative.description.length < 1) {
                                        this.setState({
                                            error: "creative_description"
                                        })
                                    } else if (!this.state.creative.image) {
                                        this.setState({
                                            error: "creative_image"
                                        })
                                    }
                                }
                            }} className="Button ButtonFull">
                                Next
                            </div>
                        </div>
                    }
                </AccordionFixed>
                <AccordionFixed
                    icon={(this.renders.creative() && this.renders.location()) ? "check" : ""}
                    icon_color={"#0185fe"}
                    open={this.renders.creative() && this.state.toggle.location}
                    title={"Location"}
                    subtitle={""}
                    edit={this.renders.creative()}
                    onEdit={() => {
                        this.state.toggle.location = !this.state.toggle.location;
                        this.setState({
                            toggle: this.state.toggle
                        }, () => {
                            this.functions.save();
                        })
                    }}
                >
                    <FacebookLocation token={this.state.token}/>
                </AccordionFixed>
                <AccordionFixed
                    icon={(this.renders.creative() && this.renders.targeting()) ? "check" : ""}
                    icon_color={"#0185fe"}
                    open={this.renders.creative() && this.state.toggle.targeting}
                    title={"Targeting"}
                    subtitle={""}
                    edit={this.renders.creative()}
                    onEdit={() => {
                        this.state.toggle.targeting = !this.state.toggle.targeting;
                        this.setState({
                            toggle: this.state.toggle
                        }, () => {
                            this.functions.save();
                        })
                    }}
                >
                    <div style={{textAlign: 'left'}} className="Section">
                        Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                        usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                        molestiae est.
                    </div>
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            title={'Gender'}
                            size={'half'}
                            placeholder={"Click here .."}
                            error={this.state.error === "gender"}
                            options={[{name: 'Male', value: 1}, {name: 'Female', value: 2}, {
                                name: 'Male and Female',
                                value: 0
                            }]}
                            value={this.state.targeting.gender}
                            onChange={(value) => {
                                this.state.targeting.gender = value;
                                this.setState({targeting: this.state.targeting}, () => {
                                    this.functions.audience();
                                })
                            }}
                        />
                        <div className="AgeSpan">
                            <div className="AgeSpanItem">
                                <Dropdown
                                    filter={false}
                                    title={'Age span'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "gender"}
                                    options={this.renders.age(18, this.state.targeting.age_max.value)}
                                    value={this.state.targeting.age_min}
                                    onChange={(value) => {
                                        this.state.targeting.age_min = value;
                                        this.setState({targeting: this.state.targeting}, () => {
                                            this.functions.audience();
                                        })
                                    }}
                                />
                            </div>
                            <div className="AgeSpanItemCenter">
                                <div className="AgeSpanItemCenterInner">
                                    -
                                </div>
                            </div>
                            <div className="AgeSpanItem">
                                <Dropdown
                                    filter={false}
                                    title={'.'}
                                    size={'full'}
                                    placeholder={"Click here .."}
                                    error={this.state.error === "gender"}
                                    options={this.renders.age(this.state.targeting.age_min.value + 1, 66)}
                                    value={this.state.targeting.age_max}
                                    onChange={(value) => {
                                        this.state.targeting.age_max = value;
                                        this.setState({targeting: this.state.targeting}, () => {
                                            this.functions.audience();
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Section">
                        <div className="Audience">
                            <div className="Audience-Title">
                                Audience size
                            </div>
                            <div className="Audience-inner">
                                {
                                    this.state.targeting.audience < 1 &&
                                    <div className="Audience-text">
                                        Set targeting to see your audience size
                                    </div>
                                }
                                {
                                    this.state.targeting.audience > 0 &&
                                    <div className="Audience-amount">
                                        {this.renders.number(this.state.targeting.audience)}
                                        <div className="Audience-amountTitle">
                                            People
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="Section">
                        <div onClick={() => {
                            if (this.state.targeting.locations_selected.length < 1) {
                                this.setState({
                                    error: "locations"
                                });
                            } else {
                                this.state.toggle.targeting = false;
                                this.state.toggle.budget = true;
                                this.setState({
                                    toggle: this.state.toggle
                                })
                            }
                        }} className="Button ButtonFull">
                            Next
                        </div>
                    </div>
                </AccordionFixed>
                <AccordionFixed
                    icon={(this.renders.creative() && this.renders.targeting() && this.renders.budget()) ? "check" : ""}
                    icon_color={"#0185fe"}
                    open={this.state.toggle.budget}
                    title={"Budget"}
                    subtitle={""}
                    edit={this.renders.creative() && this.renders.targeting()}
                    onEdit={() => {
                        this.state.toggle.budget = !this.state.toggle.budget;
                        this.setState({
                            toggle: this.state.toggle
                        })
                    }}
                >
                    <div style={{textAlign: 'left'}} className="Section">
                        Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                        usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                        molestiae est.
                    </div>
                    <div className="Section">
                        <Dropdown
                            filter={false}
                            title={'Duration'}
                            size={'half'}
                            placeholder={"Select here .."}
                            error={this.state.error === "duration"}
                            options={[{name: '5 days', value: 5}, {name: '10 days', value: 10}, {
                                name: '20 days',
                                value: 20
                            }, {name: '30 days', value: 30}]}
                            value={this.state.budget.duration}
                            onChange={(value) => {
                                this.state.budget.duration = value;
                                this.setState({budget: this.state.budget}, () => {
                                    this.functions.save();
                                })
                            }}
                        />
                        <Input
                            title={'Budget (' + this.state.facebook.facebookAdAccountCurrency + ')'}
                            value={this.state.budget.amount}
                            error={this.state.error === "budget"}
                            placeholder={""}
                            disabled={false}
                            size={'half'}
                            type={"number"}
                            onChange={(value) => {
                                this.state.budget.amount = value.target.value;
                                this.setState({budget: this.state.budget}, () => {
                                    this.functions.save();
                                });
                            }}
                        />
                    </div>
                    <div className="Section">
                        <div className="Audience">
                            <div className="Audience-Title">
                                Estimate
                            </div>
                            <div className="Audience-inner">
                                {
                                    this.state.budget.estimate.clicks < 1 &&
                                    <div className="Audience-text">
                                        Set Duration and budget to see your estimate
                                    </div>
                                }
                                {
                                    this.state.budget.estimate.clicks > 0 &&
                                    <div className="Audience-amount">
                                        {this.renders.number(this.state.budget.estimate.clicks)}
                                        <div className="Audience-amountTitle">
                                            Clicks
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.budget.estimate.impressions > 0 &&
                                    <div className="Audience-amount">
                                        {this.renders.number(this.state.budget.estimate.impressions)}
                                        <div className="Audience-amountTitle">
                                            Impressions
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="Section">
                        <div className="Button ButtonFull">
                            Next
                        </div>
                    </div>
                </AccordionFixed>
                <AccordionFixed
                    icon={false}
                    open={this.renders.targeting() && this.renders.budget() && this.renders.creative() && this.state.toggle.create}
                    title={"Create campaign"}
                    subtitle={""}
                    edit={this.renders.targeting() && this.renders.creative() && this.renders.budget()}
                    onEdit={() => {
                        this.state.toggle.create = !this.state.toggle.create;
                        this.setState({
                            toggle: this.state.toggle
                        })
                    }}
                >
                    <div style={{textAlign: 'left'}} className="Section">
                        Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                        usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                        molestiae est.
                    </div>
                    <div className="Section">
                        <Input
                            title={'Campaign name (internal use)'}
                            value={this.state.budget.name}
                            error={this.state.error === "name"}
                            placeholder={""}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            onChange={(value) => {
                                this.state.budget.name = value.target.value;
                                this.setState({budget: this.state.budget});
                            }}
                        />
                    </div>
                    <div className="Section">
                        <div className="Button ButtonFull">
                            Create
                        </div>
                    </div>
                </AccordionFixed>
            </div>
        )
    }
}

export default Facebook;
