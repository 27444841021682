import React, { Component, Fragment } from 'react';
import cx from "classnames";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HomeIcon, UsersIcon, FolderIcon, CalendarIcon, InboxIcon, ChartBarIcon, XIcon, MenuAlt2Icon, SearchIcon, BellIcon, OfficeBuildingIcon, CurrencyDollarIcon, AdjustmentsIcon, ChartPieIcon, VariableIcon, PlayIcon, PauseIcon, ExclamationIcon, CloudIcon, HandIcon, ClockIcon, LightBulbIcon, PlusIcon, PlusCircleIcon, MenuIcon } from '@heroicons/react/outline'
import { Disclosure, Transition, Menu } from '@headlessui/react'
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import { apiRegister } from '../services/apiRegister';
import { clientRegister } from '../services/clientRegister';

const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Team', href: '#', current: false },
    { name: 'Projects', href: '#', current: false },
    { name: 'Calendar', href: '#', current: false },
    { name: 'Reports', href: '#', current: false },
]
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]

class TopNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            status: {},
            user: {},
            menuOpen: false,
            mobile_menu: false
        }
    };

    componentWillMount() {
        this.init.menu();
        this.init.user();
    }

    componentDidMount() { }

    init = {
        menu: () => {
            let menu = [];
            if (userRegister.get().type === 'admin') {
                menu = [
                    {
                        title: "Dashboard",
                        link: "/user/dashboard",
                        active: "/user/dashboard",
                        newIcon: HomeIcon,
                    },
                    {
                        title: "Users",
                        link: "/user/users/all",
                        active: "/user/users",
                        newIcon: UsersIcon,
                    },
                    {
                        title: "Agencies",
                        link: "/user/agencies/all",
                        active: "/user/agencies",
                        newIcon: OfficeBuildingIcon,
                    },
                    {
                        title: "Finance",
                        link: "/user/finance",
                        active: "/user/finance",
                        newIcon: CurrencyDollarIcon,
                    }
                ];
            } else if (userRegister.get().type === 'agent') {
                menu = [
                    {
                        title: "Dashboard",
                        link: "/v2/dashboard",
                        active: "/v2/dashboard",
                        newIcon: HomeIcon,
                    },
                    /*
                    {
                        headline: "Orders",
                        title: "Orders",
                        link: null,
                        active: "/v2/orders",
                        submenu: [
                            {
                                title: "All",
                                narrowTitle: "All orders",
                                parent: "/v2/orders",
                                link: "/v2/orders",
                                active: "/v2/orders",
                                newIcon: FolderIcon,
                                hideNarrow: false,
                            },
                            {
                                title: "Active",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=active",
                                active: "/v2/orders/active",
                                newIcon: PlayIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Upcoming",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=upcoming",
                                active: "/v2/orders?status=upcoming",
                                newIcon: ClockIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Pending",
                                parent: "/user/orders",
                                link: "/v2/orders?status=pending",
                                active: "/v2/orders?status=pending",
                                newIcon: LightBulbIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Paused",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=paused",
                                active: "/v2/orders?status=paused",
                                newIcon: PauseIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Error",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=error",
                                active: "/v2/orders?status=error",
                                newIcon: ExclamationIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Drafted",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=drafted",
                                active: "/v2/orders?status=drafted",
                                newIcon: CloudIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Ended",
                                parent: "/v2/orders",
                                link: "/v2/orders?status=ended",
                                active: "/v2/orders?status=ended",
                                newIcon: HandIcon,
                                hideNarrow: true,
                            },
                            {
                                title: "Create new order",
                                parent: "/v2/orders",
                                link: "/v2/orders/new",
                                active: "/v2/orders/new",
                                type: "btn",
                                newIcon: PlusCircleIcon,
                                hideNarrow: true,
                            },
                        ]
                    },
                    */
                    {
                        headline: "Management",
                        title: "Management",
                        link: null,
                        active: "/user/management",
                        submenu: [
                            {
                                title: "Orders",
                                link: "/v2/orders",
                                active: "/v2/orders",
                                newIcon: FolderIcon,
                            },
                            {
                                type: 0,
                                title: "Optimize",
                                narrowTitle: "Optimize",
                                link: "/user/optimize/all",
                                active: "/user/optimize",
                                newIcon: AdjustmentsIcon,
                            },
                            /*
                            {
                                type: 0,
                                title: "Reports",
                                narrowTitle: "Reports",
                                link: "/user/reports/all",
                                active: "/user/reports",
                                newIcon: ChartPieIcon,
                            },
                            */
                            {
                                type: 0,
                                title: "Clients",
                                narrowTitle: "Clients",
                                link: "/user/clients/all",
                                active: "/user/clients",
                                newIcon: UsersIcon,
                            },
                            /*
                            {
                                type: 0,
                                title: "Metrics",
                                narrowTitle: "Metrics",
                                link: "/user/metrics",
                                active: "/user/metrics",
                                newIcon: VariableIcon,
                            }
                            */
                        ]
                    },
                    {
                        headline: "Analytics",
                        title: "Analytics",
                        link: null,
                        active: "/v2/analytics",
                        submenu: [
                            {
                                type: 0,
                                title: "Reports",
                                narrowTitle: "Reports",
                                link: "/v2/reports/all",
                                active: "/v2/reports",
                                newIcon: ChartPieIcon,
                            },
                            {
                                type: 0,
                                title: "Metrics",
                                narrowTitle: "Metrics",
                                link: "/v2/metrics",
                                active: "/v2/metrics",
                                newIcon: VariableIcon,
                            }
                        ]
                    },
                ];
            } else {
                menu = [];
            }
            this.setState({
                menu: menu
            });
        },
        status: () => {

        },
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get()
                })
            }
        }
    };

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        toggleMenu: () => {
            this.setState({ menuOpen: !this.state.menuOpen })
        },
        status: () => {

        }
    };

    renders = {
        firstLetter: () => {
            try {
                return this.state.user.name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        campaigns: (status) => {
            try {
                return this.state.status[status.toLowerCase()];
            } catch (e) {
                return '';
            }
        }
    };

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        }
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                <div className="">
                    <Disclosure as="nav" className="bg-white shadow-sm">
                        {({ open }) => (
                            <>
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="flex justify-between h-16">
                                        <div className="flex">
                                            <div className="flex-shrink-0 flex items-center">
                                                <img
                                                    className="block lg:hidden h-8 w-auto"
                                                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                                    alt="Workflow"
                                                />
                                                <img
                                                    className="hidden lg:block h-8 w-auto"
                                                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                                                    alt="Workflow"
                                                />
                                            </div>
                                            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                                {this.state.menu.map((item) => (
                                                    <div
                                                        key={item.name}
                                                        href={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? 'border-indigo-500 text-gray-900'
                                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="hidden sm:ml-6 sm:flex sm:items-center">

                                            <button
                                                type="button"
                                                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>

                                            {/* Profile dropdown */}
                                            <Menu as="div" className="ml-3 relative">
                                                <div>
                                                    <Menu.Button className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <span className="sr-only">Open user menu</span>
                                                        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {this.state.menu.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({ active }) => (
                                                                    <a
                                                                        href={item.href}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <div className="-mr-2 flex items-center sm:hidden">
                                            {/* Mobile menu button */}
                                            <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                </div>

                                <Disclosure.Panel className="sm:hidden">
                                    <div className="pt-2 pb-3 space-y-1">
                                        {this.state.menu.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                                                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                    <div className="pt-4 pb-3 border-t border-gray-200">
                                        <div className="flex items-center px-4">
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium text-gray-800">{user.name}</div>
                                                <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="mt-3 space-y-1">
                                            {userNavigation.map((item) => (
                                                <Disclosure.Button
                                                    key={item.name}
                                                    as="a"
                                                    href={item.href}
                                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                                >
                                                    {item.name}
                                                </Disclosure.Button>
                                            ))}
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
            </>
        )
    }

}

export default TopNavigation;