import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getMetrics() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/metrics";
        return apiRegister.call(options, url);
    }

    static createMetric(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/metrics";
        return apiRegister.call(options, url);
    }

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', data);
        let url = calls.url.api + "/client";
        return apiRegister.call(options, url);
    }

}

export {calls}