import React, {Component} from 'react';
import './style.css';
import Dropdown from "../../components/dropdown";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import OptimizeSearchQueriesItem from "../optimize-search-queries-item";
import {calls} from "../../pages/user-optimize-all/calls";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchKeywordsPositiveItem from "../optimize-search-keywords-positive-item";
import OptimizeSearchKeywordsPositiveAdd from "../optimize-search-keywords-positive-add";
import SearchtermList from "../../modules/searchtermlist";
import KeywordList from "../../modules/keywordslist";

class OptimizeSearchKeywordsPositive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keywords: [],
            channels: [],
            campaign: {},
            sort: 'click',
            order: 'desc',
            loading: true,
            index: 10,
            pagesize: 10,
            keyword_text: "",
            keyword_channel: {},
            keyword_type: {},
            search: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            keywords: nextProps.keywords,
            loading: nextProps.loading
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            keywords: this.props.keywords,
            loading: this.props.loading,
            channels: this.props.channels
        });
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div>
                {
                    !this.state.loading &&
                    <div className="flex flex-1 justify-end">
                        <button onClick={() => {
                            this.setState({
                                advanced: !this.state.advanced
                            })
                        }} className="py-2 pt-0 pl-2 text-xs text-purple-500 hover:underline inline-flex flex-row items-center relative">
                            <FeatherIcon className="stroke-current mr-2" size={15} icon="zap"/>
                            {!this.state.advanced&&
                            <span>Open advanced</span>
                            }
                            {this.state.advanced&&
                            <span>Close advanced</span>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.loading && this.state.advanced &&
                    <OptimizeSearchKeywordsPositiveAdd
                        campaign={this.state.campaign}
                        onAdd={(value) => {
                            this.props.onAdd(value);
                        }}
                    />
                }
                {
                    !this.state.loading && this.state.advanced &&
                    <Input
                        noTitle={!this.state.advanced}
                        title={"Search text"}
                        type={'text'}
                        placeholder={"Search text"}
                        sign={true}
                        signTitle={"Search"}
                        value={this.state.search}
                        size={'full'}
                        onChange={(event) => this.setState({search: event.target.value})}
                    />
                }
                {
                    !this.state.loading &&
                    <KeywordList
                        campaign={this.state.campaign}
                        campaignitems={this.state.keywords}
                        properties={{channel: true, query: true, status: true, type: true, keyword: true, impressions: true, click: true, ctr: true, cpc: true, spend: true, conversions: true, actions: {delete: true, preview: false}}}
                        sort={this.state.sort}
                        order={this.state.order}
                        pagesize={this.state.pagesize}
                        search={this.state.search}
                        updateSort={(sort, order) => {
                            this.setState({
                                sort: sort,
                                order: order
                            })
                        }}
                        onUpdate={(value) => {

                        }}
                    />
                }
                {
                    !this.state.loading && (this.state.keywords.filter((item) => {
                        try{
                            return item.text.indexOf(this.state.search) !== -1
                        }catch (e) {
                            return true
                        }
                    }).length > this.state.pagesize) &&
                    <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                        <div onClick={(e) => {
                            this.setState({
                                pagesize: this.state.pagesize + 30
                            })
                        }} className="btn btn-primary btn-lg max-w-md flex-1">
                            <div>
                                Show more ({this.state.keywords.filter((item) => {
                                try{
                                    return item.text.indexOf(this.state.search) !== -1
                                }catch (e) {
                                    return true
                                }
                            }).length}/{this.state.pagesize})
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchKeywordsPositive;
