import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import fuzzysort from 'fuzzysort';
import CsvDownload from 'react-json-to-csv'
import SlideDown from 'react-slidedown';

class ReportTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sort: "name",
            order: "asc",
            selected: {},
            metrics: {},
            parsed_metrics: [],
            parsed_items: [],
            pagination_page: 1,
            pagination_amount: 50,
            pagination_limit: 150
        };
    };

    componentDidMount() {
        this.setState({
            parsed_metrics: this.props.parsed_metrics ? this.props.parsed_metrics : [],
            parsed_items: this.props.parsed_items ? this.props.parsed_items : [],
            selected: this.props.selected,
            premium: this.props.premium,
            static: this.props.static,
            sort: (this.props.selected && this.props.selected.sort) ? this.props.selected.sort : "name",
            order: (this.props.selected && this.props.selected.order) ? this.props.selected.order : "asc"
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            parsed_metrics: nextProps.parsed_metrics,
            parsed_items: nextProps.parsed_items
        });
    }

    parseData() { }

    functions = {
        updateTab: () => {
            this.state.selected.sort = this.state.sort;
            this.state.selected.order = this.state.order;
            this.setState({
                selected: this.state.selected
            }, () => {
                this.props.updateTab(this.state.selected);
            })
        },
        downloadCsv: () => {
            let filename = this.state.selected.title + ".csv";
            let arrayOfJson = this.renders.options(this.state.parsed_items).map((item) => {
                item["breakdown value"] = item["breakdown_value"];
                item["level"] = item["type"];
                return item;
            });
            console.log(arrayOfJson);
            const replacer = (key, value) => value === null ? "" : value // specify how you want to handle null values here

            const header = this.state.parsed_metrics.map((metric) => {
                return metric.title;
            });

            if (!this.state.selected.remove_name) {
                header.unshift("name");
            }
            if (!this.state.selected.remove_id) {
                header.unshift("id");
            }
            if (!this.state.selected.remove_level) {
                header.unshift("level");
            }
            if (!this.state.selected.remove_channel_breakdown_value) {
                header.unshift("breakdown value");
            }
            if (!this.state.selected.remove_channel_breakdown) {
                header.unshift("breakdown");
            }
            if (!this.state.selected.remove_channel) {
                header.unshift("channel");
            }

            let csv = arrayOfJson.map(row => header.map(fieldName =>
                JSON.stringify(row[fieldName], replacer)).join(','))
            csv.unshift(header.join(','))
            csv = csv.join('\r\n')

            // Create link and download
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    renders = {
        markdown: (input) => {
            return { __html: input }
        },
        options: (options) => {
            let keywords = [];
            if (!this.state.name_search_products || (this.state.name_search_products && this.state.name_search_products === "")) {
                keywords = options;
            } else {
                let result = fuzzysort.go(this.state.name_search_products, options.map((item) => { return item.product_name }));
                let old_keywords = JSON.parse(JSON.stringify(options));
                result.map((item) => {
                    for (let i = 0; i < old_keywords.length; i++) {
                        if (item.target && item.target.toLowerCase() === old_keywords[i].product_name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="text-purple-500">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_keywords[i].html = html_string;
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            }
            return keywords;
        }
    };

    render() {
        return (
            <>
                <>
                    {
                        this.state.parsed_items.length > 0 &&
                        <div className="border rounded w-full overflow-hidden">
                            {
                                !this.state.static &&
                                <div style={{ backgroundColor: "#fcfcfc", height: "55px" }} className="px-4 rounded-t-lg flex border-b justify-center items-center relative">
                                    <div className="flex flex-row w-full h-full justify-center items-center">
                                        <div className="flex flex-1 justify-start">
                                            {
                                                this.state.selected && this.state.selected.daterange &&
                                                <div onClick={() => {

                                                }} className="text-xs mr-2 font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-gray-500 text-gray-900 rounded transition-all duration-200">
                                                    <FeatherIcon className="stroke-current mr-2" size={13} icon="calendar" /> <span className="capitalize">{this.state.selected.daterange.title ? this.state.selected.daterange.title : this.state.selected.daterange.value}</span>
                                                    {
                                                        this.state.selected.daterange.value !== "lifetime" &&
                                                        <span className="ml-1 opacity-50">
                                                            {moment(this.state.selected.daterange.start_date).format("YYYY.MM.DD")}{" - "}
                                                            {moment(this.state.selected.daterange.end_date).format("YYYY.MM.DD")}
                                                        </span>
                                                    }
                                                </div>
                                            }
                                            {
                                                false &&
                                                this.state.selected && this.state.selected.breakdown &&
                                                <div
                                                    className="text-xs mr-2 font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-gray-500 text-gray-900 rounded transition-all duration-200">
                                                    <FeatherIcon className="stroke-current mr-2" size={13}
                                                        icon={this.state.selected.breakdown.icon} /> {this.state.selected.breakdown.title}
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.selected && this.state.selected.breakdown && this.state.selected && this.state.selected.breakdown.value === 'products' &&
                                            <div className="">
                                                <button onClick={() => {
                                                    this.state.selected.open_filter = !this.state.selected.open_filter;
                                                    this.setState({
                                                        selected: this.state.selected
                                                    })
                                                }} className="text-xs font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-purple-500 text-purple-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                                    <FeatherIcon className="stroke-current mr-2" size={13} icon="filter" /> Filter
                                                </button>
                                            </div>
                                        }
                                        {
                                            !this.state.premium &&
                                            <button
                                                onClick={() => {
                                                    this.functions.downloadCsv();
                                                }}
                                                className="text-xs font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 relative bg-purple-500 text-purple-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                                <FeatherIcon className="stroke-current mr-2" size={15} icon="download" />
                                                <span>Export (.csv)</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !this.state.static &&
                                !this.state.premium &&
                                this.state.selected && this.state.selected.breakdown && this.state.selected && this.state.selected.breakdown.value === 'products' &&
                                <SlideDown closed={!this.state.selected.open_filter}>
                                    <div style={{ backgroundColor: "#fcfcfc" }} className="px-4 rounded-t-lg py-4 flex-row flex border-b relative">
                                        <div className="w-64 mr-4">
                                            <div className="text-xs font-bold mb-1">
                                                Product name
                                            </div>
                                            <input
                                                onChange={(event) => {
                                                    this.setState({
                                                        name_search_products: event.target.value
                                                    })
                                                }}
                                                value={this.state.name_search_products}
                                                className="h-10 w-full rounded bg-white text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                            />
                                        </div>
                                        <div className="mr-4">
                                            <div className="text-xs font-bold mb-1 opacity-0">
                                                All
                                            </div>
                                            <div className="h-10 w-full justify-center align-middle items-center text-purple-500 flex flex-row pl-4 pr-4 box-border">
                                                <div className="text-xs font-bold mr-4">
                                                    Show only existing products
                                                </div>
                                                <div onClick={() => {
                                                    this.state.selected.show_all_products = !this.state.selected.show_all_products;
                                                    this.setState({
                                                        selected: this.state.selected
                                                    })
                                                }} className="text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    {
                                                        this.state.selected && this.state.selected.show_all_products &&
                                                        <FeatherIcon className={'stroke-current'} size={14} icon={"check"} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mr-4">
                                            <div className="text-xs font-bold mb-1 opacity-0">
                                                All
                                            </div>
                                            <div className="h-10 w-full justify-center align-middle items-center text-purple-500 flex flex-row pl-4 pr-4 box-border">
                                                <div className="text-xs font-bold mr-4">
                                                    Show only matching products
                                                </div>
                                                <div onClick={() => {
                                                    this.state.selected.show_matching_products = !this.state.selected.show_matching_products;
                                                    this.setState({
                                                        selected: this.state.selected
                                                    })
                                                }} className="text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    {
                                                        this.state.selected && this.state.selected.show_matching_products &&
                                                        <FeatherIcon className={'stroke-current'} size={14} icon={"check"} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SlideDown>
                            }
                            <div className="overflow-scroll">
                                <table className="min-w-full divide-y divide-gray-200 bg-white table-fixed"
                                    id="reportstable">
                                    <thead>
                                        <tr>
                                            {
                                                this.state.selected.breakdown.value === "products" &&
                                                <th onClick={() => {

                                                }} className={"transition-all duration-200 text-left text-xs font-medium uppercase " + (this.state.sort === 'products_selected' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol" className="flex flex-row items-center px-6 pr-3">

                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_channel &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: 'channel',
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"campaign-name--label cursor-pointer transition-all duration-200 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'channel' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3">
                                                        <div>
                                                            {"channel"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_breakdown &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: "channel_breakdown",
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"cursor-pointer transition-all duration-200 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'channel_breakdown' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3">
                                                        <div>
                                                            {"breakdown"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_breakdown_value &&
                                                <th onClick={() => {
                                                    let sort = "";
                                                    if (this.state.selected.breakdown.value === "daily") {
                                                        sort = "date";
                                                    } else if (this.state.selected.breakdown.value === "products") {
                                                        sort = "product_name";
                                                    } else {
                                                        sort = this.state.selected.breakdown.value;
                                                    }
                                                    this.setState({
                                                        sort: "breakdown_value",
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"campaign-name--label cursor-pointer transition-all whitespace-no-wrap duration-200  text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'breakdown_value' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3 whitespace-no-wrap">
                                                        <div>
                                                            {"breakdown value"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.selected.breakdown.value === "daily" &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: "date",
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"campaign-name--label cursor-pointer transition-all whitespace-no-wrap duration-200  text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === (this.state.selected.breakdown.value !== "daily" ? (this.state.selected.breakdown.value === "products" ? "product_name" : this.state.selected.breakdown.value) : "date") ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3 whitespace-no-wrap">
                                                        <div>
                                                            {"date"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_level &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: 'type',
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"campaign-name--label cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'type' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3">
                                                        <div>
                                                            {"level"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_id &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: 'id',
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={"campaign-name--label cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'id' ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="typecol"
                                                        className="draggable flex flex-row items-center px-6 py-3">
                                                        <div>
                                                            {"id"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                !this.state.selected.remove_name &&
                                                <th onClick={() => {
                                                    this.setState({
                                                        sort: 'name',
                                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                    }, () => {
                                                        this.functions.updateTab();
                                                    });
                                                }}
                                                    className={(this.props.type) + "-name--label cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'name' ? 'text-gray-700 border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                    <div id="namecol"
                                                        className="draggable px-6 py-3 flex flex-row items-center">
                                                        <div>
                                                            {"name"}
                                                            <div className="text-xxs">
                                                                {"basic"}
                                                            </div>
                                                        </div>
                                                        <div className="sorting-arrows ml-2"></div>
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.parsed_metrics.map((item, index) => {
                                                    return (
                                                        <th key={index} onClick={() => {
                                                            this.setState({
                                                                sort: item.metric,
                                                                order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                            }, () => {
                                                                this.functions.updateTab();
                                                            });
                                                        }} className={(item.title.toLowerCase().replace(/\s/g, '')) + "--label cursor-pointer transition-all duration-200  text-left text-xs leading-4 font-medium text-gray-500 uppercase " + (this.state.sort === item.metric ? 'text-gray-700  border-l border-r ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                            <div id={(item.title.toLowerCase().replace(/\s/g, ''))}
                                                                className="draggable px-6 py-3 flex flex-row items-center whitespace-no-wrap">
                                                                <div>
                                                                    {item.title}
                                                                    {
                                                                        item.subtitle &&
                                                                        <div className="text-xxs">
                                                                            {item.subtitle}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="sorting-arrows ml-2"></div>
                                                            </div>
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200 relative">
                                        {this.renders.options(this.state.parsed_items).filter((item) => {
                                            if (this.state.selected.breakdown.value === "products") {
                                                if (this.state.selected.show_all_products) {
                                                    return item.exist;
                                                } else {
                                                    return true
                                                }
                                            } else {
                                                return true
                                            }
                                        }).filter((item) => {
                                            if (this.state.selected.show_matching_products) {
                                                return this.state.parsed_items.filter((inner_item) => { return inner_item.product_url === item.product_url }).length > 1
                                            } else {
                                                return true
                                            }
                                        }).sort((a, b) => {
                                            if (this.state.sort) {
                                                var nameA = null;
                                                var nameB = null;
                                                try {
                                                    if (a[this.state.sort].value) {
                                                        nameA = a[this.state.sort].value.toLowerCase();
                                                    } else {
                                                        nameA = a[this.state.sort].toLowerCase();
                                                    }
                                                } catch (e) {
                                                    nameA = a[this.state.sort];
                                                }
                                                try {
                                                    if (b[this.state.sort].value) {
                                                        nameB = b[this.state.sort].value.toLowerCase();
                                                    } else {
                                                        nameB = b[this.state.sort].toLowerCase();
                                                    }
                                                } catch (e) {
                                                    nameB = b[this.state.sort];
                                                }
                                                if (this.state.order === 'desc') {
                                                    if (nameA > nameB)
                                                        return -1;
                                                    if (nameA < nameB)
                                                        return 1;
                                                    return 0
                                                } else {
                                                    if (nameA < nameB)
                                                        return -1;
                                                    if (nameA > nameB)
                                                        return 1;
                                                    return 0
                                                }
                                            } else {
                                                return 1
                                            }
                                        }).filter((item, index) => {
                                            if (this.state.parsed_items.length > this.state.pagination_limit) {
                                                return ((this.state.selected.pagination_page ? this.state.selected.pagination_page : 1) * 50) > index
                                            } else {
                                                return true;
                                            }
                                        }).map((item, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    {
                                                        <tr>
                                                            {
                                                                this.state.selected.breakdown.value === "products" &&
                                                                <td style={this.state.sort === 'product_selected' ? {} : { backgroundColor: "#fcfcfc" }} className={(this.props.type) + "-name--block px-4 py-4 whitespace-no-wrap " + (this.state.sort === 'product_selected' ? ' border-l border-r' : '')}>
                                                                    <div onClick={() => {
                                                                        if (!this.state.selected.products_selected) {
                                                                            this.state.selected.products_selected = {};
                                                                        }
                                                                        if (item.products in this.state.selected.products_selected) {
                                                                            delete this.state.selected.products_selected[item.products];
                                                                            if (Object.keys(this.state.selected.products_selected).length < 1) {
                                                                                this.state.selected.products_selected = null;
                                                                            }
                                                                        } else {
                                                                            this.state.selected.products_selected[item.products] = item;
                                                                        }
                                                                        this.setState({
                                                                            selected: this.state.selected
                                                                        }, () => {
                                                                            this.functions.updateTab();
                                                                        })
                                                                    }} className={((this.state.selected.products_selected && this.state.selected.products_selected[item.products]) ? "text-purple-500 border-purple-500 " : "border-purple-100 ") + " border-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.selected.products_selected && this.state.selected.products_selected[item.products] &&
                                                                            <FeatherIcon className={'stroke-current'} size={14} icon={"check"} />
                                                                        }
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_channel &&
                                                                <td
                                                                    //data-colind="1"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "channel" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={(this.state.sort === 'channel' || this.state.sort === "date") ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={(this.props.type) + "-name--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === 'channel' ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div className={"text-sm flex flex-row justify-center align-middle items-center leading-5 text-gray-900 truncate"}>
                                                                            {
                                                                                item.channel === "google" &&
                                                                                <div
                                                                                    className="bg-google-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img className="w-3" src={require('../../../assets/images/google_icon.svg')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "google_shopping" &&
                                                                                <div
                                                                                    className="bg-googleshopping-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img style={{ width: "0.65rem" }} src={require('../../../assets/images/google-shopping.png')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "linkedin" &&
                                                                                <div
                                                                                    className="bg-linkedin-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img className="w-3"
                                                                                        src={require('../../../assets/images/linkedin_icon.svg')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "facebook" &&
                                                                                <div
                                                                                    className="bg-facebook-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img style={{ width: '0.4rem' }}
                                                                                        src={require('../../../assets/images/facebook_icon.svg')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "google_analytics" &&
                                                                                <div
                                                                                    className="bg-googleanalytics-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img className="w-3"
                                                                                        src={require('../../../assets/images/ga_icon.png')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "adform" &&
                                                                                <div
                                                                                    className="bg-adform-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img className="w-3"
                                                                                        src={require('../../../assets/images/adform_icon.png')} />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.channel === "twitter" &&
                                                                                <div
                                                                                    className="bg-twitter-500 mr-2 h-6 w-6 rounded flex justify-center align-middle items-center">
                                                                                    <img className="w-3"
                                                                                        src={require('../../../assets/images/twitter_icon.svg')} />
                                                                                </div>
                                                                            }
                                                                            <div className="capitalize ml-1">
                                                                                {item.channel === 'google_shopping' ? "Google Shopping" : (item.channel === 'google_analytics' ? "Google Analytics" : item.channel)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_breakdown &&
                                                                <td
                                                                    //data-colind="0"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "channel_breakdown" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort === 'breakdown' ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={"campaign-name--block px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'type' ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center relative">
                                                                        <div className={"capitalize text-sm leading-5 text-gray-900 truncate"}>
                                                                            {item.channel_breakdown}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_breakdown_value &&
                                                                <td
                                                                    //data-colind="1"
                                                                    //className={"px-6 py-4 whitespace-no-wrap"}
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "breakdown_value" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort === (this.state.selected.breakdown.value !== "daily" ? (this.state.selected.breakdown.value === "products" ? "product_name" : this.state.selected.breakdown.value) : "date") ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={(this.props.type) + "-name--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === (this.state.selected.breakdown.value !== "daily" ? (this.state.selected.breakdown.value === "products" ? "product_name" : this.state.selected.breakdown.value) : "date") ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        {
                                                                            this.state.selected.breakdown.value === "products" &&
                                                                            <div className="h-8 w-8 rounded overflow-hidden flex items-center justify-center mr-4 border">
                                                                                <img className="w-full" src={item.product_image} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channel_breakdown === "publisher_platform" && item.breakdown_value === 'messenger' &&
                                                                            <div className="bg-facebookmessenger-500 mr-2 h-6 w-6 rounded flex mr-4 justify-center align-middle items-center">
                                                                                <img style={{ width: '0.9rem' }}
                                                                                    src={require('../../../assets/images/facebookmessenger_icon.png')} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channel_breakdown === "publisher_platform" && item.breakdown_value === 'instagram' &&
                                                                            <div className="bg-instagram-500 mr-2 h-6 w-6 rounded flex mr-4 justify-center align-middle items-center">
                                                                                <img className="w-3"
                                                                                    src={require('../../../assets/images/instagram_icon.svg')} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channel_breakdown === "publisher_platform" && item.breakdown_value === 'unknown' &&
                                                                            <div className="bg-gray-500 mr-2 h-6 w-6 rounded flex mr-4 justify-center align-middle items-center">
                                                                                <div className="font-bold text-white text-md">?</div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channel_breakdown === "publisher_platform" && item.breakdown_value === 'facebook' &&
                                                                            <div
                                                                                className="bg-facebook-500 mr-2 h-6 w-6 rounded mr-4 flex justify-center align-middle items-center">
                                                                                <img style={{ width: '0.4rem' }}
                                                                                    src={require('../../../assets/images/facebook_icon.svg')} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channel_breakdown === "publisher_platform" && item.breakdown_value === 'audience_network' &&
                                                                            <div
                                                                                className="bg-facebooknetwork-500 mr-2 h-6 w-6 rounded mr-4 flex justify-center align-middle items-center">
                                                                                <img style={{ width: '0.7rem' }}
                                                                                    src={require('../../../assets/images/facebooknetwork_icon.png')} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            <div dangerouslySetInnerHTML={this.renders.markdown(item.html ? item.html : (item.breakdown_value))} className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === ("breakdown_value") ? '' : '')}>

                                                                            </div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            item.channel !== "google_analytics" &&
                                                                            this.state.selected.breakdown.value === "overview" &&
                                                                            <div
                                                                                className={"capitalize text-sm leading-5 text-gray-900 truncate"}>
                                                                                All
                                                                            </div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className={"capitalize text-sm leading-5 text-gray-900 truncate"}>
                                                                                {item.breakdown_value}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            item.channel === "google_analytics" &&
                                                                            <div
                                                                                className={"capitalize text-sm leading-5 text-gray-900 truncate"}>
                                                                                {item.ga_type === "overview" ? "All" : item.ga_type}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                this.state.selected.breakdown.value === "daily" &&
                                                                <td
                                                                    //data-colind="1"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "date" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort !== "date" ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={(this.props.type) + "-name--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === (this.state.selected.breakdown.value !== "daily" ? (this.state.selected.breakdown.value === "products" ? "product_name" : this.state.selected.breakdown.value) : "date") ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div

                                                                        className="flex flex-row items-center">
                                                                        <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                            {item['date']}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_level &&
                                                                <td
                                                                    //data-colind="0"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "type" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort === 'type' ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={"campaign-name--block px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'type' ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center relative">
                                                                        <div className={"capitalize text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'type' ? '' : '')}>
                                                                            {item.channel === "adform" ? (item.type === "adgroup" ? "order" : (item.type === "ad" ? "line item" : "campaign")) : (item.channel === "google_analytics" ? "web view" : item.type)}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_id &&
                                                                <td
                                                                    //data-colind="2"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "id" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort === "id" ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={(this.props.type) + "-name--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === 'id' ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === "id" ? '' : '')}>
                                                                            #{item.id}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                !this.state.selected.remove_name &&
                                                                <td
                                                                    //data-colind="3"
                                                                    className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === "name" ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                //style={this.state.sort === 'name' ? {} : { backgroundColor: "#fcfcfc" }}
                                                                //className={(this.props.type) + "-name--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === 'name' ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'name' ? '' : '')}>
                                                                            {item.name}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                this.state.parsed_metrics.map((metric, i) => {
                                                                    return (
                                                                        <td key={i}
                                                                            //data-colind={i + 3}
                                                                            className={((this.state.sort === "date" && item.date && (parseInt(item.date.replace(/\-/g, "")) % 2 === 0)) ? " bg-purple-100 bg-opacity-25 " : "") + (this.state.sort === metric.metric ? " border-l border-r " : "") + " px-6 py-4 whitespace-no-wrap"}
                                                                        //style={this.state.sort === metric.metric ? {} : { backgroundColor: "#fcfcfc" }}
                                                                        //className={(metric.title.toLowerCase().replace(/\s/g, '')) + "--block px-6 py-4 whitespace-no-wrap " + (this.state.sort === metric.metric ? 'bg-purple-100 bg-opacity-25 border-l border-r' : '')}
                                                                        >
                                                                            <div className="flex flex-row items-center">
                                                                                <div className={"text-sm leading-5 text-gray-900 truncate"}>
                                                                                    {item[metric.metric]}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    }
                                                </Fragment>
                                            )
                                        })
                                        }
                                    </tbody >
                                </table >
                            </div >
                        </div >
                    }
                    {
                        !this.state.loading && (this.renders.options(this.state.parsed_items).filter((item) => {
                            if (this.state.selected.breakdown.value === "products") {
                                if (this.state.selected.show_all_products) {
                                    return item.exist;
                                } else {
                                    return true
                                }
                            } else {
                                return true
                            }
                        }).filter((item) => {
                            if (this.state.selected.show_matching_products) {
                                return this.state.parsed_items.filter((inner_item) => { return inner_item.product_url === item.product_url }).length > 1
                            } else {
                                return true
                            }
                        }).length > ((this.state.selected.pagination_page ? this.state.selected.pagination_page : 1) * 50)) &&
                        <div className="mt-5 mb-5 text-center flex-col flex-1 flex items-center align-middle justify-center">
                            <div onClick={(e) => {
                                if (!this.state.selected.pagination_page) {
                                    this.state.selected.pagination_page = 2;
                                } else {
                                    this.state.selected.pagination_page = this.state.selected.pagination_page + 1;
                                };
                                this.setState({
                                    selected: this.state.selected
                                })
                            }} className="btn btn-primary btn-lg max-w-md">
                                <div>
                                    Load more ({(this.state.selected.pagination_page ? this.state.selected.pagination_page : 1) * 50}/{this.renders.options(this.state.parsed_items).filter((item) => {
                                        if (this.state.selected.breakdown.value === "products") {
                                            if (this.state.selected.show_all_products) {
                                                return item.exist;
                                            } else {
                                                return true
                                            }
                                        } else {
                                            return true
                                        }
                                    }).filter((item) => {
                                        if (this.state.selected.show_matching_products) {
                                            return this.state.parsed_items.filter((inner_item) => { return inner_item.product_url === item.product_url }).length > 1
                                        } else {
                                            return true
                                        }
                                    }).length})
                                </div>
                            </div>
                            <div onClick={() => {
                                this.state.selected.pagination_page = 10000000000;
                                this.setState({
                                    selected: this.state.selected
                                })
                            }} className="mt-3 text-xs text-purple-500 cursor-pointer">
                                show all
                            </div>
                        </div>
                    }
                </>
            </>
        )
    }

}

export default ReportTable;