import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, DuplicateIcon, ExclamationIcon, MinusIcon, PencilIcon, PlusIcon, TrashIcon, XCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import TextAreaTailwind from './textAreaTailwind';
import UploadImageTailwind from './uploadImageTailwind';
import UploadVideoTailwind from './uploadVideoTailwind';
import classNames from 'classnames';
import { adFacebook } from '../validators/adFacebook';
import { adLinkedin } from '../validators/adLinkedin';

class EditFacebookAdCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            call_to_actions: [
                {
                    id: 1,
                    "name": "No Button",
                    "type": "NO_BUTTON"
                },
                {
                    id: 2,
                    "name": "Buy Tickets",
                    "type": "BUY_TICKETS",
                },
                {
                    id: 3,
                    "name": "Shop Now",
                    "type": "SHOP_NOW",
                },
                {
                    id: 4,
                    "name": "Learn more",
                    "type": "LEARN_MORE",
                },
                {
                    id: 5,
                    "name": "Get Showtimes",
                    "type": "GET_SHOWTIMES",
                },
                {
                    id: 6,
                    "name": "Sign Up",
                    "type": "SIGN_UP",
                },
                {
                    id: 7,
                    "name": "Download",
                    "type": "DOWNLOAD",
                },
                {
                    id: 8,
                    "name": "Watch More",
                    "type": "WATCH_MORE",
                },
                {
                    id: 9,
                    "name": "Contact Us",
                    "type": "CONTACT_US",
                },
                {
                    id: 10,
                    "name": "Apply Now",
                    "type": "APPLY_NOW",
                },
                {
                    id: 11,
                    "name": "Get Quote",
                    "type": "GET_QUOTE",
                },
                {
                    id: 12,
                    "name": "Request Time",
                    "type": "REQUEST_TIME",
                },
                {
                    id: 13,
                    "name": "See Menu",
                    "type": "SEE_MENU",
                },
                {
                    id: 14,
                    "name": "Subscribe",
                    "type": "SUBSCRIBE",
                }
            ],
            client: {},
            slides: [],
            pages: [],
            slide: {},
            updated: {},
            loader: false,
            assetItems: [],
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client,
            updated: this.props.updated,
            wizard: this.props.wizard,
            pages: this.props.pages,
            loader: this.props.loader,
            assetItems: this.props.assetItems
        })
        this.functions.slides();
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            client: nextProps.client,
            updated: nextProps.updated,
            wizard: nextProps.wizard,
            pages: nextProps.pages,
            loader: nextProps.loader,
            assetItems: nextProps.assetItems
        })
    }

    functions = {
        validate: (item = false) => {
            if (!this.props.linkedin) {
                if (item) {
                    if (!item.link || (adFacebook.validate(this.state.updated).slides && !item.link.includes("https://"))) {
                        return true;
                    } else return false;
                } else return adFacebook.validate(this.state.updated)
            } else {
                if (item) {
                    if (!item.link || (adLinkedin.validate(this.state.updated).slides && !item.link.includes("https://")) || !item.title) {
                        return true;
                    } else return false;
                } else return adLinkedin.validate(this.state.updated)
            }
        },
        slides: async () => {
            try {
                let slides = [];
                if (!this.state.wizard) {
                    this.state.ad.creative.object_story_spec.link_data.child_attachments.map((item, index) => {
                        let slide = {
                            org_index: index,
                            index: index,
                            video: item.video_id ? true : false,
                            video_data: null,
                            body: item.description,
                            headline: item.name,
                            link: item.link,
                            picture: item.picture,
                            video_id: item.video_id,
                            image_hash: item.image_hash,
                            loading: false,
                            cta: this.renders.cta(item.call_to_action)
                        };
                        if (slide.video) {
                            slide.loading = true;
                            this.functions.getVideo(slide.video_id);
                        }
                        slides.push(slide);
                    })
                } else {
                    slides = this.state.updated.slides;
                }
                await this.promisedSetState({
                    slides: slides
                });
                if (this.props.onChange) {
                    this.state.updated.slides = this.state.slides;
                    this.props.onChange(this.state.updated);
                }
            } catch (error) {
                console.log(error);
            }
        },
        getVideo: async (video) => {
            try {
                let response = await this.calls.video(video);
                this.state.slides = this.state.slides.map((slide) => {
                    if (slide.video_id == video) {
                        slide.loading = false;
                        slide.video_data = {
                            thumbnail: response.data.picture,
                            url: response.data.source
                        };
                    }
                    return slide;
                });
                await this.promisedSetState({
                    slides: this.state.slides
                });
                if (this.props.onChange) {
                    this.state.updated.slides = this.state.slides;
                    this.props.onChange(this.state.updated);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    renders = {
        cta: (item) => {
            try {
                let cta = null;
                if (cta) {
                    return this.state.call_to_actions.filter((cta) => { return cta.type == item.type })[0];
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (this.state.updated.page) {
                    return this.state.updated.page
                } else {
                    let page = this.state.pages.filter((page) => { return page.id == item.creative.object_story_spec.page_id })[0];
                    if (page) {
                        return page
                    } else {
                        return { id: 0, name: "Select page" };
                    }
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
    };

    calls = {
        video: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/getVideo?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="col-span-3">
                {
                    this.state.slides.map((item, index) => {
                        return (
                            <div className={"bg-gray-100 rounded-md mb-2 w-full p-4 " + (this.functions.validate(item) ? " border-red-600 border" : "") }>
                                <div className="flex flex-row">
                                    <img
                                        className="relative inline-block h-10 w-10 rounded-md"
                                        src={!item.video_data ? item.picture : item.video_data.thumbnail}
                                        alt=""
                                    />
                                    <div className="ml-2 items-start flex flex-col flex-1 justify-center">
                                        <div className="text-sm">{item.title}</div>
                                        <div className="text-xs">{item.body}</div>
                                    </div>
                                    <div onClick={async () => {
                                        this.state.slides = this.state.slides.filter((item, inner_index) => {
                                            return index !== inner_index;
                                        })
                                        await this.promisedSetState({
                                            slides: this.state.slides
                                        });
                                        if (this.props.onChange) {
                                            this.state.updated.slides = this.state.slides;
                                            this.props.onChange(this.state.updated);
                                        }
                                    }} className="h-10 w-10 cursor-pointer rounded-md mr-2 bg-red-200 flex justify-center items-center">
                                        <TrashIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                    {
                                        false &&
                                        <div onClick={async () => {
                                            if (this.state.slides.length < 10) {
                                                let new_slide = JSON.parse(JSON.stringify(item));
                                                this.state.slides.push(new_slide);
                                                await this.promisedSetState({
                                                    slides: this.state.slides
                                                });
                                                if (this.props.onChange) {
                                                    this.state.updated.slides = this.state.slides;
                                                    this.props.onChange(this.state.updated);
                                                }
                                            }
                                        }} className="h-10 w-10 cursor-pointer rounded-md mr-2 bg-gray-300 flex justify-center items-center">
                                            <DuplicateIcon className="h-5 w-5 text-gray-500" />
                                        </div>
                                    }
                                    <div onClick={() => {
                                        item.open = !item.open;
                                        this.setState({
                                            slides: this.state.slides
                                        })
                                    }} className="h-10 w-10 cursor-pointer rounded-md bg-gray-300 flex justify-center items-center">
                                        <PencilIcon className="h-5 w-5 text-gray-500" />
                                    </div>
                                </div>
                                {
                                    item.open &&
                                    <div className="w-full bg-white rounded-md p-4 mt-4">
                                        <div className="mb-4">
                                            <div className="grid grid-cols-6 gap-4">
                                                <div className="col-span-6">
                                                    <div className="h-12">
                                                        <div key={''} className="flex justify-center border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                            <input
                                                                onChange={(e) => this.props.UploadFile(e, item)}
                                                                type="file"
                                                                accept={(this.props.linkedin ? "image/png, image/jpg, image/jpeg, image/gif" : "image/png, image/jpg, image/jpeg, image/gif, video/*")}
                                                                className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
                                                            />
                                                            <div className="space-y-1 text-center justify-center flex flex-col">
                                                                {
                                                                    this.state.loader &&
                                                                    <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                        <div className="ml-2 text-xs">
                                                                            Loading ...
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !this.state.loader &&
                                                                    <div className="text-xxs text-gray-600">
                                                                        <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Select other asset"}
                                                        loader={this.state.loader}
                                                        locked={!this.state.wizard}
                                                        selected={{ id: 0, name: "Select an asset" }}
                                                        options={this.state.assetItems.filter((item) => { if (this.props.linkedin) { return item.type === "image" } else return item }).map((item) => {
                                                            return {
                                                                id: item.id,
                                                                type: item.type,
                                                                name: item.name,
                                                                url: item.url,
                                                                assetId: item.id,
                                                                colors: (item.colors ? item.colors : false),
                                                                ratio: (item.ratio ? item.ratio : false),
                                                                size: (item.size ? item.size : false),
                                                                thumbnail: (item.thumbnail ? item.thumbnail : false),
                                                                format: (item.format ? item.format : false)
                                                            }
                                                        })}
                                                        onChange={async (value) => {
                                                            this.state.updated.slides = this.state.updated.slides.map((inner_item, inner_index) => {
                                                                if (inner_index === index) {
                                                                    if (value.type === "image") {
                                                                        value.picture = value.url;
                                                                        value.image = true;
                                                                        value.video = false;
                                                                    } else if (value.type === "video") {
                                                                        value.image = false;
                                                                        value.video = true;
                                                                    }
                                                                    inner_item = { ...inner_item, ...value };
                                                                }
                                                                return inner_item;
                                                            })
                                                            this.state.updated.files = this.state.updated.files.map((inner_item, inner_index) => {
                                                                if (inner_index === index) {
                                                                    if (value.type === "image") {
                                                                        value.image = true;
                                                                        value.video = false;
                                                                    } else if (value.type === "video") {
                                                                        value.image = false;
                                                                        value.video = true;
                                                                    }
                                                                    inner_item = { ...inner_item, ...value };
                                                                }
                                                                return inner_item;
                                                            })
                                                            await this.promisedSetState({ slides: this.state.updated.slides });
                                                            this.props.onChange(this.state.updated);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        {
                                            /*
                                        <div className="col-span-6">
                                            <UploadImageTailwind
                                                label={"Image"}
                                                buttonText={"Upload"}
                                                client={this.state.client}
                                                onChange={async (value) => {
                                                    item.picture = value.url;
                                                    item.id = value.id;
                                                    item.url = value.url;
                                                    delete item.video_id;
                                                    await this.promisedSetState({
                                                        slides: this.state.slides
                                                    });
                                                    if (this.props.onChange) {
                                                        this.state.updated.slides = this.state.slides;
                                                        this.props.onChange(this.state.updated);
                                                    }
                                                }}
                                            />
                                        </div>
                                        
                                            <div className="col-span-3">
                                                <UploadVideoTailwind
                                                    label={"Video"}
                                                    buttonText={"Upload"}
                                                    page={this.renders.page(this.state.ad)}
                                                    channel={"facebook"}
                                                    client={this.state.client}
                                                    onChange={async (value) => {
                                                        item.video_data = value;
                                                        await this.promisedSetState({
                                                            slides: this.state.slides
                                                        });
                                                        if (this.props.onChange) {
                                                            this.state.updated.slides = this.state.slides;
                                                            this.props.onChange(this.state.updated);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            */
                                        }
                                        <div className="mb-4">
                                            <InputTailwind
                                                label={"Headline"}
                                                value={item.title}
                                                error={this.props.linkedin ? (!item.title || (item.title === "" && this.functions.validate().slides)) : false}
                                                onChange={async (value) => {
                                                    item.title = value;
                                                    await this.promisedSetState({
                                                        slides: this.state.slides
                                                    });
                                                    if (this.props.onChange) {
                                                        this.state.updated.slides = this.state.slides;
                                                        this.props.onChange(this.state.updated);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {
                                            !this.props.linkedin &&
                                            <div className="mb-4">
                                                <InputTailwind
                                                    label={"Description"}
                                                    value={item.body}
                                                    onChange={async (value) => {
                                                        item.body = value;
                                                        await this.promisedSetState({
                                                            slides: this.state.slides
                                                        });
                                                        if (this.props.onChange) {
                                                            this.state.updated.slides = this.state.slides;
                                                            this.props.onChange(this.state.updated);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                        <div className="mb-4">
                                            <InputTailwind
                                                label={"Website URL"}
                                                error={!item.link || (!item.link.includes("https://") && this.functions.validate().slides)}
                                                value={item.link}
                                                onChange={async (value) => {
                                                    item.link = value;
                                                    await this.promisedSetState({
                                                        slides: this.state.slides
                                                    });
                                                    if (this.props.onChange) {
                                                        this.state.updated.slides = this.state.slides;
                                                        this.props.onChange(this.state.updated);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {
                                            false &&
                                            <div className="">
                                                <DropdownTailwind
                                                    label={"Call to Action"}
                                                    searchInput={true}
                                                    selected={item.cta}
                                                    options={this.state.call_to_actions}
                                                    onChange={async (value) => {
                                                        item.cta = value;
                                                        await this.promisedSetState({
                                                            slides: this.state.slides
                                                        });
                                                        if (this.props.onChange) {
                                                            this.state.updated.slides = this.state.slides;
                                                            this.props.onChange(this.state.updated);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default EditFacebookAdCarousel;
