import React, { Component } from 'react';

class SwitchTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: false,
            disabled: false
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            disabled: this.props.disabled,
            color: this.props.color
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            disabled: nextProps.disabled,
            color: nextProps.color
        })
    }

    render() {
        return (
            <div className="flex items-center justify-center w-14">
                <div
                    className={(this.state.disabled ? "cursor-not-allowed opacity-25 " : "cursor-pointer ") + (this.state.value ? (this.state.color ? this.state.color : " bg-purple-500") : " bg-gray-200") + " md:h-7 w-14 flex justify-start rounded-full p-1"}
                    onClick={() => {
                        if (this.props.onSwitch && !this.state.disabled) {
                            this.props.onSwitch();
                        }
                    }}
                >
                    <div
                        className={
                            "bg-white h-5 w-5 rounded-full transform duration-300 ease-in-out" +
                            (this.state.value ? " translate-x-6 transform" : "")
                        }
                    ></div>
                </div>
            </div>
        )
    }
}

export default SwitchTailwind;
