import React, { Component, Fragment } from 'react';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import {
    FolderIcon,
    ArrowSmRightIcon,
    ArrowSmLeftIcon
} from '@heroicons/react/outline';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesInstagramVideo from '../components/advertising-creatives-instagram-video';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesInstagramImage from '../components/advertising-creatives-instagram-image';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import AdvertisingCreativesInstagramCarousel from '../components/advertising-creatives-instagram-carousel';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import PreviewSnapchatStories from '../previews/snapchat/stories';
import PreviewTwitterFeed from '../previews/twitter/feed';
import AdvertisingCreativesVertical from '../components/advertising-creatives-vertical';
import PreviewTwitterCarousel from '../previews/twitter/carousel';
import PreviewGoogleSearch from '../previews/google/search';
import PreviewYoutubeDesktop from '../previews/youtube/desktop';
import PreviewGoogleShopping from '../previews/google/shopping';
import PreviewGooglePerformanceMax from '../previews/google/performanceMax';
import PreviewSectionTikTok from './previewSectionTikTok';
import PreviewSectionSnapchat from './previewSectionSnapchat';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import PreviewSectionTwitter from './previewSectionTwitter';
import PreviewSectionGoogle from './previewSectionGoogle';
import PreviewSectionYoutube from './previewSectionYotube';
import PreviewSectionFacebook from './previewSectionFacebook';
import PreviewFacebookFeed from '../previews/facebook/feed';
import PreviewGooglePerformanceMaxDisplay from '../previews/google/performanceMaxDisplay';
import DisplayBannerSquare from '../previews/google/displayBannerSquare';

class PreviewSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            ads: [],
            campaign: {},
            adgroup: {},
            channels: {},
            channel: {},
            options: [
                { id: 1, name: "Facebook Ads Manager", value: "facebook", color: "facebook-500" },
                { id: 2, name: "Google Adwords", value: "google", color: "google-500" },
                { id: 3, name: "Linkedin Ads", value: "linkedin", color: "linkedin-500" },
                { id: 4, name: "Twitter Ads", value: "twitter", color: "twitter-500" },
                { id: 5, name: "TikTok Ads", value: "tiktok", color: "tiktok-500" },
                { id: 6, name: "Youtube", value: "youtube", color: "youtube-500" },
                { id: 7, name: "Snapchat Ads", value: "snapchat", color: "snapchat-500" },
                //{ id: 7, name: "Instagram", value: "instagram", color: "instagram-500" }
            ],
            previews: {
                'facebook': [{ id: 1, name: "feed facebook" },
                // {id: 2, name: "feed instagram"},
                {
                    id: 3,
                    name: "Story"
                }],
                'tiktok': [{ id: 1, name: "feed" }],
                'snapchat': [{ id: 2, name: "Stories", value: "feed" }, { id: 1, name: "Spotlight", value: "feed" }],
                // 'snapchat': [{ id: 1, name: "spotlight" }],
                'twitter': [{ id: 1, name: "feed" }],
                'linkedin': [{ id: 1, name: "feed" }],
                'google': [{ id: 1, name: "Search", value: "search" }, { id: 2, name: "Youtube", value: "youtube" }, { id: 3, name: "Display", value: "display" }, { id: 4, name: "Gmail", value: "gmail" }, { id: 4, name: "Discover", value: "discover" }],
                //'google': [{ id: 1, name: "Search result - Desktop" }],
                'youtube': [{ id: 1, name: "Video - Desktop" }],
                'instagram': [{ id: 1, name: "feed Instagram" }, {
                    id: 3,
                    name: "Story"
                }]
            },
            wizard: {},
            is_external: false,
            ext_selected_channel_tab: {},
            ext_selected_campaign: {},
            ext_selected_adgroup: {},
            whitelabel_settings: {},
            is_post: false
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            channels: this.props.channels,
            wizard: this.props.wizard,
            is_external: this.props.is_external,
            ext_selected_channel_tab: this.props.ext_selected_channel_tab,
            ext_selected_campaign: this.props.ext_selected_campaign,
            ext_selected_adgroup: this.props.ext_selected_adgroup,
            whitelabel_settings: this.props.whitelabel_settings,
            is_post: this.props.is_post
        });
        this.functions.init();
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            ads: nextProps.ads,
            channels: nextProps.channels,
            wizard: nextProps.wizard,
            is_external: nextProps.is_external,
            ext_selected_channel_tab: nextProps.ext_selected_channel_tab,
            ext_selected_campaign: nextProps.ext_selected_campaign,
            ext_selected_adgroup: nextProps.ext_selected_adgroup,
            whitelabel_settings: nextProps.whitelabel_settings,
            is_post: nextProps.is_post
        });
        this.functions.init();
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        init: async () => {

            //CHECK IF YOUTUBE AVAILABLE
            if (Array.isArray(this.state.campaigns)) {
                let google = false;
                let youtube = false;
                this.state.campaigns.map((item) => {
                    if (item.channel === "google") {
                        if (item.advertising_type && item.advertising_type.value === "VIDEO") {
                            youtube = true;
                        } else if (!(item.advertising_type && item.advertising_type.value === "VIDEO")) {
                            google = true;
                        }
                    }
                });
                this.state.channels.youtube = youtube;
                this.state.channels.google = google;
            }

            await this.promisedSetState({
                channels: this.state.channels
            });

            /*
            //CHECK IF INSTAGRAM AVAILABLE
            if (Array.isArray(this.state.campaigns)) {
                this.state.campaigns.map((item) => {
                    if (item.channel === "facebook") {
                        this.state.channels.instagram = true;
                    }
                });
            }
            */

            if (this.state.channel && !this.state.channel.value) {
                let channel = false;
                this.state.options.map((item) => {
                    if (this.state.channels[item.value] && !channel) {
                        channel = item;
                    }
                });
                await this.promisedSetState({
                    channel: channel
                });
                let campaign = this.renders.campaigns()[0];
                let adgroup = this.state.adgroups.filter((item) => {
                    return item.campaign === campaign.id
                })[0];
                await this.promisedSetState({
                    campaign: campaign,
                    adgroup: adgroup
                });
            }

        },

    }

    renders = {
        showInstagramPreview: () => {
            let showInstagramPreview = false
            const facebookAdGroup = this.state.adgroups.find(adgroup => adgroup.channel === 'facebook')
            if (facebookAdGroup) {
                let adgroups = this.state.adgroups.filter(adgroup => adgroup.channel === 'facebook');
                for (var i = 0; i < adgroups.length; i++) {
                    if (adgroups[i].auto_placements && adgroups[i].auto_placements.value) {
                        showInstagramPreview = true;
                        break;
                    } else {
                        if (Array.isArray(adgroups[i].instagram_positions) && adgroups[i].instagram_positions.filter(position => position.selected).length) {
                            showInstagramPreview = true;
                            break;
                        }
                    }

                }
            }
            return showInstagramPreview
        },
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        brandname: (item) => {
            try {
                if ('snapchat_brandname' in item) {
                    return item.snapchat_brandname;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((item) => {
                            if (item.ratio !== '9:16' && placement_format === 'not_vertical') {
                                if (item.type === 'image') {
                                    creative = item.picture;
                                } else {
                                    creative = { thumbnail: item.thumbnail, video: item.url };
                                }
                            } else if (item.ratio === '9:16' && placement_format === 'vertical') {
                                if (item.type === 'image') {
                                    creative = item.picture;
                                } else {
                                    creative = { thumbnail: item.thumbnail, video: item.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        },
        campaigns: () => {
            let campaigns = this.state.campaigns.filter((item) => {
                return (this.state.wizard.status !== "error" || this.state.wizard.status === "error" && !item.remove_from_order)
            });
            campaigns = campaigns.filter((item) => {
                if (this.state.channel.value === "youtube") {
                    return item.channel === "google" && item.advertising_type && item.advertising_type.value === "VIDEO";
                } else if (this.state.channel.value === "google") {
                    return item.channel === "google" && !(item.advertising_type && item.advertising_type.value === "VIDEO");
                } else {
                    return item.channel === this.state.channel.value
                }
            });
            return campaigns;
        }
    }

    render() {
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    !this.state.is_external &&
                    <div className="flex flex-row mt-6">
                        {
                            this.state.options.filter((channel) =>
                                this.state.channels[channel.value]
                            ).map((channel) => {
                                return (
                                    <>
                                        <div onClick={async () => {
                                            await this.promisedSetState({
                                                channel: channel
                                            });
                                            //this.props.onChannel(channel);
                                            let campaign = this.renders.campaigns()[0];
                                            let adgroup = this.state.adgroups.filter((item) => {
                                                return item.campaign === campaign.id
                                            })[0];
                                            await this.promisedSetState({
                                                campaign: campaign,
                                                adgroup: adgroup
                                            });
                                        }}
                                            className={(channel.value === this.state.channel.value && !this.state.channel.isInstagram ? ("bg-" + channel.color + " " + `${this.state.channel.value === "snapchat" ? "text-black" : "text-white"}`) : "text-white bg-gray-300 hover:bg-gray-400") + " mr-4 py-2 flex flex-row bg-custom-input cursor-pointer overflow-hidden rounded-md items-center"}>
                                            <div className={"ml-3 rounded-full flex justify-center items-center"}>
                                                {
                                                    channel.value === 'google' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/google_icon.png')} />
                                                }
                                                {
                                                    channel.value === 'facebook' &&
                                                    <img className="w-1.5"
                                                        alt=""
                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                }
                                                {
                                                    channel.value === 'linkedin' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                }
                                                {
                                                    channel.value === 'twitter' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                }
                                                {
                                                    channel.value === 'tiktok' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                }
                                                {
                                                    channel.value === 'snapchat' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/snapchat.svg')} />
                                                }
                                                {
                                                    channel.value === 'youtube' &&
                                                    <img className="w-3"
                                                        alt=""
                                                        src={require('../assets/images/youtube_icon.png')} />
                                                }
                                                {
                                                    channel.value === 'instagram' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/instagram_icon.svg')} />
                                                }
                                            </div>
                                            {
                                                <span
                                                    className={"px-4 pl-2 text-sm font-medium w-24 text-center capitalize"}>
                                                    {channel.value}
                                                </span>
                                            }
                                        </div >
                                        {
                                            channel.value === 'facebook' && this.renders.showInstagramPreview() ? <div onClick={async () => {
                                                await this.promisedSetState({
                                                    channel: {
                                                        id: 1,
                                                        name: "Facebook Ads Manager",
                                                        value: "facebook",
                                                        color: "facebook-500",
                                                        isInstagram: true
                                                    }
                                                });
                                                let campaign = this.renders.campaigns()[0];
                                                let adgroup = this.state.adgroups.filter((item) => {
                                                    return item.campaign === campaign.id
                                                })[0];
                                                await this.promisedSetState({
                                                    campaign: campaign,
                                                    adgroup: adgroup
                                                });
                                            }}
                                                className={(this.state.channel.isInstagram ? ("bg-instagram-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400") + " mr-4 py-2 flex flex-row bg-custom-input cursor-pointer overflow-hidden rounded-md items-center"}>
                                                <div className={"ml-3 rounded-full flex justify-center items-center"}>

                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/instagram_icon.svg')} />

                                                </div>
                                                {
                                                    <span
                                                        className={"px-4 pl-2 text-sm font-medium w-24 text-center capitalize"}>
                                                        Instagram
                                                    </span>
                                                }
                                            </div> : null
                                        }
                                    </>
                                )
                            })
                        }
                    </div >
                }

                {/* CAMPAIGN */}
                {
                    !this.state.is_external &&
                    < div className="flex flex-1 flex-row mt-4" >
                        {
                            this.renders.campaigns().map((campaign, index) => {
                                return (
                                    <Fragment>
                                        {
                                            index > 0 &&
                                            this.renders.campaigns().length > 1 &&
                                            <div className="w-2"></div>
                                        }
                                        <div onClick={() => {
                                            //this.props.onCampaign(campaign);
                                            let adgroup = this.state.adgroups.filter((item) => {
                                                return item.campaign === campaign.id
                                            })[0];
                                            this.setState({
                                                campaign: campaign,
                                                adgroup: adgroup
                                            });
                                        }}
                                            style={campaign.id === this.state.campaign.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                            className={(campaign.id === this.state.campaign.id ? "bg-white" : "text-gray-300 hover:text-gray-700 bg-gray-50") + " shadow py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                            <FolderIcon
                                                className={(campaign.id === this.state.campaign.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                            <div className="flex flex-1 truncate">
                                                <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                    {campaign.name}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.renders.campaigns().length === 1 &&
                                            <div className="flex flex-1"></div>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </div >
                }


                {/* ADGROUP AND ADS */}
                {
                    !this.state.is_external &&
                    < div className=" bg-white flex flex-1 rounded-b-lg shadow flex-col" >
                        <div className="pt-6 w-full">
                            <div className="block overflow-scroll w-full px-6">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {this.state.adgroups.filter((adgroup) => {
                                        return adgroup.campaign === this.state.campaign.id && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error" && !adgroup.remove_from_order))
                                    }).map((adgroup) => (
                                        <div
                                            onClick={() => {
                                                //this.props.onAdgroup(adgroup);
                                                this.setState({
                                                    adgroup: adgroup
                                                });
                                            }}
                                            key={adgroup.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={classNames(
                                                adgroup.id == this.state.adgroup.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer'
                                            )}
                                            aria-current={adgroup.id == this.state.adgroup.id ? 'page' : undefined}
                                        >
                                            {adgroup.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                            <div className="w-full border-b pb-6"></div>
                            {
                                this.state.ads.filter((ad) => {
                                    return ad.adgroup === this.state.adgroup.id && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error" && !ad.remove_from_order))
                                }).length < 1 &&
                                <div className="p-6 pt-4">
                                    <div className="h-64 w-full flex justify-center items-center">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No ads available</div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.ads.filter((ad) => {
                                    return ad.adgroup === this.state.adgroup.id && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error" && !ad.remove_from_order))
                                }).length > 0 &&
                                <div className="p-6 pt-4">
                                    <div className="pt-4 pb-3 w-full flex-row flex overflow-x-scroll">
                                        {
                                            this.state.ads.filter((ad) => {
                                                return ad.adgroup === this.state.adgroup.id && (this.state.wizard.status !== "error" || (this.state.wizard.status === "error" && !ad.remove_from_order))
                                            }).filter((ad) => {
                                                return (this.state.channel.value === "google" && ["display", "performance_max", "youtube", "shopping", "search_ad"].includes(ad.type)) || this.state.channel.value !== "google"
                                            }).map((ad) => {
                                                if (ad.new_preview && this.state.channel.value === ad.channel) {
                                                    return (
                                                        <div className="mr-4">
                                                            <div style={{ backgroundColor: "#fcfcfc" }} className="border p-4 border-gray-300 rounded-md shadow-sm">
                                                                <div className="mb-4">
                                                                    <InputTailwind
                                                                        label={"Name"}
                                                                        value={ad.name}
                                                                        locked={true}
                                                                        disabled={true}
                                                                        onChange={(value) => {

                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="mb-4 flex flex-row">
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full">
                                                                            <DropdownTailwind
                                                                                label={"Placement"}
                                                                                selected={ad.preview ? ad.preview : { id: 1, name: "Feed", value: "feed" }}
                                                                                options={Array.isArray(ad.previews) ? ad.previews : [{ id: 1, name: "Feed", value: "feed" }]}
                                                                                onChange={(value) => {
                                                                                    ad.preview = value;
                                                                                    this.setState({
                                                                                        ads: this.state.ads
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (this.state.channel.value === "facebook" || this.state.channel.value === "instagram") &&
                                                                    <PreviewSectionFacebook
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel.isInstagram ? { name: 'instagran', value: 'instagram' } : this.state.channel}
                                                                        is_post={this.state.is_post}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "tiktok" &&
                                                                    <PreviewSectionTikTok
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "snapchat" &&
                                                                    <PreviewSectionSnapchat
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "linkedin" &&
                                                                    <PreviewSectionLinkedin
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "twitter" &&
                                                                    <PreviewSectionTwitter
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "youtube" &&
                                                                    <PreviewSectionYoutube
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "google" &&
                                                                    <PreviewSectionGoogle
                                                                        ad={ad}
                                                                        ads={this.state.ads}
                                                                        channel={this.state.channel}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="mr-4">
                                                            <div style={{ backgroundColor: "#fcfcfc" }}
                                                                className="border p-4 border-gray-300 rounded-md shadow-sm">
                                                                <div className="mb-4">
                                                                    <InputTailwind
                                                                        label={"Name"}
                                                                        value={ad.name}
                                                                        locked={true}
                                                                        disabled={true}
                                                                        onChange={(value) => {

                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="mb-4 flex flex-row">
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full">
                                                                            <DropdownTailwind
                                                                                label={"Placement"}
                                                                                locked={ad.type === "youtube"}
                                                                                selected={this.state.channel.isInstagram ? (ad.previewInstagram ? ad.previewInstagram : { id: 1, name: 'feed Instagram' }) : ad.preview ? ad.preview : {
                                                                                    id: 0,
                                                                                    name: "Select placement"
                                                                                }}
                                                                                options={Array.isArray(ad.previews) ? ad.previews : this.state.previews[this.state.channel.isInstagram ? 'instagram' : ad.channel]}
                                                                                onChange={(value) => {
                                                                                    if (this.state.channel.isInstagram) {
                                                                                        ad.previewInstagram = value
                                                                                    } else {
                                                                                        ad.preview = value;
                                                                                    }
                                                                                    this.setState({
                                                                                        ads: this.state.ads
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.channel.value === "google" &&
                                                                        Array.isArray(ad.combinations) &&
                                                                        ad.preview && ad.preview.id === 1 &&
                                                                        <div className="flex pl-4">
                                                                            {
                                                                                this.state.channel.value === "google" &&
                                                                                Array.isArray(ad.combinations) &&
                                                                                ad.preview && ad.preview.id === 1 &&
                                                                                <div className="flex flex-row pt-5.5">
                                                                                    <div onClick={() => {
                                                                                        if (!("combi_index" in ad)) {
                                                                                            ad.combi_index = 1;
                                                                                        } else {
                                                                                            if (ad.combi_index > 0) {
                                                                                                ad.combi_index = ad.combi_index - 1;
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            ads: this.state.ads
                                                                                        })
                                                                                    }}
                                                                                        className={(!("combi_index" in ad) || ad.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                                                                        <ArrowSmLeftIcon
                                                                                            className="w-5 h-5" />
                                                                                    </div>
                                                                                    <div onClick={() => {
                                                                                        if (!("combi_index" in ad)) {
                                                                                            ad.combi_index = 1;
                                                                                        } else {
                                                                                            if (ad.combi_index < ad.combinations.length - 1) {
                                                                                                ad.combi_index = ad.combi_index + 1;
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            ads: this.state.ads
                                                                                        })
                                                                                    }}
                                                                                        className={(ad.combi_index === (ad.combinations.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 rounded-full flex items-center justify-center"}>
                                                                                        <ArrowSmRightIcon
                                                                                            className="w-5 h-5" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {
                                                                    this.state.channel.value === "google" &&
                                                                    ad.ad_type && ad.ad_type.value === "search_ad" &&
                                                                    (
                                                                        (Array.isArray(ad.headlines) &&
                                                                            ad.headlines.filter((item, index) => {
                                                                                return typeof item.text == "string" && item.text.indexOf("{keyword}") !== -1
                                                                            }).length > 0) ||
                                                                        (Array.isArray(ad.bodies) &&
                                                                            ad.bodies.filter((item, index) => {
                                                                                return typeof item.text == "string" && item.text.indexOf("{keyword}") !== -1
                                                                            }).length > 0)
                                                                    ) &&
                                                                    <div className="mb-4 flex flex-row">
                                                                        <div className="flex flex-1">
                                                                            <div className="w-full">
                                                                                <DropdownTailwind
                                                                                    label={"Keyword insert"}
                                                                                    selected={ad.keyword_insert ? ad.keyword_insert : { id: 0, name: "Select keyword" }}
                                                                                    options={this.state.ads.filter((item) => {
                                                                                        return item.type === "keywords"
                                                                                    }).map((item) => {
                                                                                        return item.keywords.map((item) => {
                                                                                            return { id: item.id, name: item.keyword }
                                                                                        })
                                                                                    }).flat(1)}
                                                                                    onChange={(value) => {
                                                                                        ad.keyword_insert = value;
                                                                                        this.setState({
                                                                                            ads: this.state.ads
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.channel.value === "google" &&
                                                                    ad.ad_type.value === "performance_max" &&
                                                                    <div className="border rounded-md">
                                                                        <PreviewGooglePerformanceMax
                                                                            channel={ad.preview}
                                                                            videos={ad.videos}
                                                                            images={ad.images}
                                                                            headlines={ad.headlines}
                                                                            descriptions={ad.bodies}
                                                                            long_headlines={ad.long_headlines}
                                                                            business={ad.business}
                                                                            website={ad.link}
                                                                            cta={ad.cta}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.channel.value === "google" &&
                                                                    ad.ad_type.value !== "shopping" &&
                                                                    ad.ad_type.value !== "performance_max" &&
                                                                    ad.ad_type &&
                                                                    ad.ad_type && ad.ad_type.value === "search_ad" &&
                                                                    this.state.ads.filter((item) => { return item.type === "extension" && item.picture }).length > 0 &&
                                                                    <div className="mb-4 flex flex-row">
                                                                        <div className="flex flex-1">
                                                                            <div className="w-full">
                                                                                <DropdownTailwind
                                                                                    label={"Image extension"}
                                                                                    selected={ad.image_insert ? ad.image_insert : { id: 0, name: "Select image" }}
                                                                                    options={this.state.ads.filter((item) => {
                                                                                        return item.type === "extension" && item.picture
                                                                                    }).map((item) => {
                                                                                        return { url: item.picture, name: item.name }
                                                                                    }).flat(1)}
                                                                                    onChange={(value) => {
                                                                                        ad.image_insert = value;
                                                                                        this.setState({
                                                                                            ads: this.state.ads
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.channel.value === "google" &&
                                                                    ad.ad_type &&
                                                                    ad.ad_type && ad.ad_type.value !== "shopping" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    Array.isArray(ad.combinations) &&
                                                                    ad.combinations.filter((item, index) => {
                                                                        return (!("combi_index" in ad) && index === 0) || (ad.combi_index === index)
                                                                    }).map((item) => {
                                                                        return (
                                                                            <div className="border rounded-md">
                                                                                <PreviewGoogleSearch
                                                                                    noBorder={true}
                                                                                    keywordInsert={ad.keyword_insert}
                                                                                    image={ad.image_insert}
                                                                                    headlineFirst={item.headlineFirst.text}
                                                                                    headlineSecond={item.headlineSecond.text}
                                                                                    headlineThird={item.headlineThird.text}
                                                                                    bodyOne={item.bodyOne.text}
                                                                                    bodySecond={item.bodySecond.text}
                                                                                    link={ad.link}
                                                                                    path1={ad.path1}
                                                                                    path2={ad.path2}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    (this.state.channel.value === "google") &&
                                                                    ad.ad_type &&
                                                                    ad.ad_type.value === "shopping" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    Array.isArray(ad.preview_products) &&
                                                                    <PreviewGoogleShopping
                                                                        products={ad.preview_products}
                                                                        noBorder={true}
                                                                    />
                                                                }
                                                                {
                                                                    (this.state.channel.value === "youtube" || this.state.channel.value === "google") &&
                                                                    ad.ad_type && ad.ad_type.value === "youtube" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    <PreviewYoutubeDesktop
                                                                        videoId={ad.youtube_id}
                                                                        title={ad.name}
                                                                        description={ad.description}
                                                                        noBorder={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 3 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "video_ad" && ad.file && ad.file.ratio === '9:16') || (ad.ad_type && ad.ad_type.value === "merge_ad" && ad.ad_type.type === "video" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio === '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesVertical
                                                                        page={this.renders.page(ad)}
                                                                        loader={false}
                                                                        creatives={[
                                                                            {
                                                                                video: true,
                                                                                call_to_action: this.renders.cta(ad, 'vertical'),
                                                                                headline: this.renders.title(ad, 'vertical'),
                                                                                description: this.renders.description(ad, 'vertical'),
                                                                                display_website: this.renders.caption(ad, 'vertical'),
                                                                                body: this.renders.body(ad, 'vertical'),
                                                                                creative: {
                                                                                    url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').video : ""),
                                                                                    thumbnail: { url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').thumbnail : "") }
                                                                                }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 3 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "image_ad" && ad.file && ad.file.ratio === '9:16') || (ad.ad_type && ad.ad_type.value === "merge_ad" && ad.ad_type.type === "image" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio === '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesVertical
                                                                        page={this.renders.page(ad)}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'vertical'),
                                                                                description: this.renders.description(ad, 'vertical'),
                                                                                display_website: this.renders.caption(ad, 'vertical'),
                                                                                headline: this.renders.title(ad, 'vertical'),
                                                                                body: this.renders.body(ad, 'vertical'),
                                                                                creative: { url: this.renders.creative(ad, 'vertical') }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && this.state.channel.isInstagram &&
                                                                    ad.previewInstagram && ad.previewInstagram.id === 3 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "video_ad" && ad.file && ad.file.ratio === '9:16') || (ad.ad_type && ad.ad_type.value === "merge_ad" && ad.ad_type.type === "video" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio === '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesVertical
                                                                        page={this.renders.page(ad)}
                                                                        loader={false}
                                                                        creatives={[
                                                                            {
                                                                                video: true,
                                                                                call_to_action: this.renders.cta(ad, 'vertical'),
                                                                                headline: this.renders.title(ad, 'vertical'),
                                                                                description: this.renders.description(ad, 'vertical'),
                                                                                display_website: this.renders.caption(ad, 'vertical'),
                                                                                body: this.renders.body(ad, 'vertical'),
                                                                                creative: {
                                                                                    url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').video : ""),
                                                                                    thumbnail: { url: (this.renders.creative(ad, 'vertical') ? this.renders.creative(ad, 'vertical').thumbnail : "") }
                                                                                }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && this.state.channel.isInstagram &&
                                                                    ad.previewInstagram && ad.previewInstagram.id === 3 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "image_ad" && ad.file && ad.file.ratio === '9:16') || (ad.ad_type && ad.ad_type.value === "merge_ad" && ad.ad_type.type === "image" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio === '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesVertical
                                                                        page={this.renders.page(ad)}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'vertical'),
                                                                                description: this.renders.description(ad, 'vertical'),
                                                                                display_website: this.renders.caption(ad, 'vertical'),
                                                                                headline: this.renders.title(ad, 'vertical'),
                                                                                body: this.renders.body(ad, 'vertical'),
                                                                                creative: { url: this.renders.creative(ad, 'vertical') }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {false &&
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "posts" && ad.ad_type.type === "image" && !ad.files) || (ad.ad_type.value === "image_ad" && ad.file && ad.file.ratio !== '9:16') || (ad.ad_type.value === "merge_ad" && ad.ad_type.type === "image" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio !== '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesFacebookImage
                                                                        page={this.renders.page(ad)}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'not_vertical'),
                                                                                description: this.renders.description(ad, 'not_vertical'),
                                                                                display_website: this.renders.caption(ad, 'not_vertical'),
                                                                                headline: this.renders.title(ad, 'not_vertical'),
                                                                                body: this.renders.body(ad, 'not_vertical'),
                                                                                creative: { url: this.renders.creative(ad, 'not_vertical') }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    <PreviewFacebookFeed
                                                                        page={this.renders.page(ad)}
                                                                        body={this.renders.body(ad, 'not_vertical')}
                                                                        title={this.renders.title(ad, 'not_vertical')}
                                                                        image={this.state.ad.picture}
                                                                        description={this.renders.description(ad, 'not_vertical')}
                                                                        link={this.renders.link(ad, 'not_vertical')}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "video_ad" && ad.file && ad.file.ratio !== '9:16') || (ad.ad_type.value === "merge_ad" && ad.ad_type.type === "video" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio !== '9:16'
                                                                    }).length > 0)) || (ad.ad_type.value === "posts" && ad.ad_type.type === "video" && !ad.files)) &&
                                                                    <AdvertisingCreativesFacebookVideo
                                                                        page={this.renders.page(ad)}
                                                                        loader={false}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'not_vertical'),
                                                                                headline: this.renders.title(ad, 'not_vertical'),
                                                                                description: this.renders.description(ad, 'not_vertical'),
                                                                                display_website: this.renders.caption(ad, 'not_vertical'),
                                                                                body: this.renders.body(ad, 'not_vertical'),
                                                                                creative: {
                                                                                    url: (this.renders.creative(ad, 'not_vertical') ? this.renders.creative(ad, 'not_vertical').video : ""),
                                                                                    thumbnail: { url: (this.renders.creative(ad, 'not_vertical') ? this.renders.creative(ad, 'not_vertical').thumbnail : "") }
                                                                                }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && this.state.channel.isInstagram &&
                                                                    ((ad.previewInstagram && ad.previewInstagram.id === 1) || !ad.previewInstagram) &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "video_ad" && ad.file && ad.file.ratio !== '9:16') || (ad.ad_type.value === "posts" && ad.ad_type.type === "video") || (ad.ad_type.value === "merge_ad" && ad.ad_type.type === "video" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio !== '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesInstagramVideo
                                                                        page={this.renders.page(ad)}
                                                                        loader={false}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'not_vertical'),
                                                                                headline: this.renders.title(ad, 'not_vertical'),
                                                                                description: this.renders.description(ad, 'not_vertical'),
                                                                                display_website: this.renders.caption(ad, 'not_vertical'),
                                                                                body: this.renders.body(ad, 'not_vertical'),
                                                                                creative: {
                                                                                    url: (this.renders.creative(ad, 'not_vertical') ? this.renders.creative(ad, 'not_vertical').video : ""),
                                                                                    thumbnail: { url: (this.renders.creative(ad, 'not_vertical') ? this.renders.creative(ad, 'not_vertical').thumbnail : "") }
                                                                                }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && this.state.channel.isInstagram &&
                                                                    ((ad.previewInstagram && ad.previewInstagram.id === 1) || !ad.previewInstagram) &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "posts" && ad.ad_type.type === "image" && !ad.files) || (ad.ad_type.value === "image_ad" && ad.file && ad.file.ratio !== '9:16') || (ad.ad_type.value === "merge_ad" && ad.ad_type.type === "image" && (Array.isArray(ad.slides) && ad.slides.filter((item) => {
                                                                        return item.ratio !== '9:16'
                                                                    }).length > 0))) &&
                                                                    <AdvertisingCreativesInstagramImage
                                                                        page={this.renders.page(ad)}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action: this.renders.cta(ad, 'not_vertical'),
                                                                                description: this.renders.description(ad, 'not_vertical'),
                                                                                display_website: this.renders.caption(ad, 'not_vertical'),
                                                                                headline: this.renders.title(ad, 'not_vertical'),
                                                                                body: this.renders.body(ad, 'not_vertical'),
                                                                                creative: { url: this.renders.creative(ad, 'not_vertical') }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && !this.state.channel.isInstagram &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    (ad.ad_type.value === "carousel_ad" || ad.ad_type.value === "catalog_ad" || (ad.ad_type.value === "posts" && ad.files)) &&
                                                                    <AdvertisingCreativesFacebookCarousel
                                                                        page={this.renders.page(ad)}
                                                                        content={{
                                                                            body: this.renders.body(ad),
                                                                            items: this.renders.creative(ad)
                                                                        }}
                                                                        noContainer={true}
                                                                        onChange={() => {

                                                                        }}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "facebook" && this.state.channel.isInstagram &&
                                                                    ((ad.previewInstagram && ad.previewInstagram.id === 1) || !ad.previewInstagram) &&
                                                                    ad.ad_type &&
                                                                    (ad.ad_type.value === "carousel_ad" || ad.ad_type.value === "catalog_ad" || (ad.ad_type.value === "posts" && ad.files)) &&
                                                                    <AdvertisingCreativesInstagramCarousel
                                                                        page={this.renders.page(ad)}
                                                                        content={{
                                                                            body: this.renders.body(ad),
                                                                            items: this.renders.creative(ad)
                                                                        }}
                                                                        noContainer={true}
                                                                        onChange={() => {

                                                                        }}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "linkedin" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "posts" && ad.files) || ad.ad_type.value === "carousel_ad") &&
                                                                    <AdvertisingCreativesFacebookCarousel
                                                                        linkedin={true}
                                                                        page={this.renders.page(ad)}
                                                                        content={{
                                                                            body: this.renders.body(ad),
                                                                            items: this.renders.creative(ad)
                                                                        }}
                                                                        noContainer={true}
                                                                        onChange={() => {

                                                                        }}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "twitter" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "posts" && ad.files) || ad.ad_type.value === "carousel_ad") &&
                                                                    <PreviewTwitterCarousel
                                                                        page={this.renders.page(ad)}
                                                                        body={this.renders.body(ad)}
                                                                        content={{
                                                                            body: this.renders.body(ad),
                                                                            items: this.renders.creative(ad)
                                                                        }}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "tiktok" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type.value === "video_ad" || ad.ad_type.value === "posts")) &&
                                                                    <PreviewTikTokFeed
                                                                        video={this.renders.creative(ad).video}
                                                                        text={this.renders.body(ad)}
                                                                        profile={this.renders.page(ad)}
                                                                        thumbnail={this.renders.creative(ad).thumbnail}
                                                                        cta={this.renders.cta(ad)}
                                                                        page={this.renders.page(ad)}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "snapchat" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    (ad.ad_type.value === "video_ad") &&
                                                                    <PreviewSnapchatStories
                                                                        video={this.renders.creative(ad).video}
                                                                        text={this.renders.body(ad)}
                                                                        profile={this.renders.page(ad)}
                                                                        thumbnail={this.renders.creative(ad).thumbnail}
                                                                        cta={this.renders.cta(ad)}
                                                                        page={this.renders.page(ad)}
                                                                    // brandname={this.state.ad.file.brandname}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "snapchat" &&
                                                                    ad.preview && ad.preview.id === 2 &&
                                                                    ad.ad_type &&
                                                                    (ad.ad_type.value === "image_ad") &&
                                                                    <PreviewSnapchatStories
                                                                        picture={this.renders.creative(ad)}
                                                                        text={this.renders.body(ad)}
                                                                        profile={this.renders.page(ad)}
                                                                        cta={this.renders.cta(ad)}
                                                                        page={this.renders.page(ad)}
                                                                        brandname={this.renders.brandname(ad)}
                                                                    />
                                                                }
                                                                {/* {
                                                                this.state.channel.value === "snapchat" &&
                                                                ad.preview && ad.preview.id === 1 &&
                                                                ad.ad_type &&
                                                                ((ad.ad_type.value === "video_ad" || ad.ad_type.value === "posts")) &&
                                                                <PreviewSnapchatSpotlight
                                                                    video={this.renders.creative(ad).video}
                                                                    text={this.renders.body(ad)}
                                                                    profile={this.renders.page(ad)}
                                                                    thumbnail={this.renders.creative(ad).thumbnail}
                                                                    cta={this.renders.cta(ad)}
                                                                    page={this.renders.page(ad)}
                                                                />
                                                            } */}
                                                                {
                                                                    this.state.channel.value === "linkedin" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "image_ad") || (ad.ad_type && ad.ad_type.value === "posts" && ad.ad_type.type === "image")) &&
                                                                    <AdvertisingCreativesFacebookImage
                                                                        linkedin={true}
                                                                        page={this.renders.page(ad)}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action_linkedin: this.renders.cta(ad),
                                                                                description: this.renders.description(ad),
                                                                                display_website: this.renders.caption(ad),
                                                                                headline: this.renders.title(ad),
                                                                                body: this.renders.body(ad),
                                                                                creative: { url: this.renders.creative(ad) }
                                                                            }
                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "linkedin" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "video_ad") || (ad.ad_type && ad.ad_type.value === "posts" && ad.ad_type.type === "video")) &&
                                                                    <AdvertisingCreativesFacebookVideo
                                                                        linkedin={true}
                                                                        page={this.renders.page(ad)}
                                                                        loader={false}
                                                                        creatives={[
                                                                            {
                                                                                call_to_action_linkedin: this.renders.cta(ad),
                                                                                headline: this.renders.title(ad),
                                                                                description: this.renders.description(ad),
                                                                                display_website: this.renders.caption(ad),
                                                                                body: this.renders.body(ad),
                                                                                creative: {
                                                                                    url: (this.renders.creative(ad) ? this.renders.creative(ad).video : ""),
                                                                                    thumbnail: { url: (this.renders.creative(ad) ? this.renders.creative(ad).thumbnail : "") }
                                                                                }
                                                                            }

                                                                        ]}
                                                                        noContainer={true}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "twitter" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "image_ad") || (ad.ad_type && ad.ad_type.value === "posts" && ad.ad_type.type === "image" && !ad.files)) &&
                                                                    <PreviewTwitterFeed
                                                                        video={this.renders.creative(ad).video}
                                                                        image={this.renders.creative(ad)}
                                                                        link={this.renders.link(ad)}
                                                                        body={this.renders.body(ad)}
                                                                        headline={this.renders.title(ad)}
                                                                        page={this.renders.page(ad)}
                                                                    />
                                                                }
                                                                {
                                                                    this.state.channel.value === "twitter" &&
                                                                    ad.preview && ad.preview.id === 1 &&
                                                                    ad.ad_type &&
                                                                    ((ad.ad_type && ad.ad_type.value === "video_ad") || (ad.ad_type && ad.ad_type.value === "posts" && ad.ad_type.type === "video" && !ad.files)) &&
                                                                    <PreviewTwitterFeed
                                                                        video={this.renders.creative(ad).video}
                                                                        link={this.renders.link(ad)}
                                                                        body={this.renders.body(ad)}
                                                                        headline={this.renders.title(ad)}
                                                                        thumbnail={this.renders.creative(ad).thumbnail}
                                                                        page={this.renders.page(ad)}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div >
                }
                {
                    this.state.is_external &&
                    <>
                        {
                            this.state.ads.filter(ad => {
                                return ad.adgroup === (this.state.ext_selected_adgroup && this.state.ext_selected_adgroup.id) && ((this.state.ext_selected_channel_tab.value === "google" && ["search_ad", "display"].includes(ad.type)) || this.state.ext_selected_channel_tab.value !== "google")
                            }).map((ad, index) => {
                                return (
                                    <>
                                        <div
                                            className={`border p-4 border-gray-300 sm:mr-4 mb-4 rounded-md ${this.state.whitelabel_settings && this.state.whitelabel_settings.shadows ? "shadow-md" : ""}`}
                                            style={{ backgroundColor: "#fcfcfc" }} >

                                            <div className="mb-4">
                                                <InputTailwind
                                                    label={"Name"}
                                                    value={ad.name}
                                                    locked={true}
                                                    disabled={true}
                                                    onChange={(value) => {

                                                    }}
                                                />
                                            </div>
                                            <div className="mb-4 flex">
                                                <div className="flex flex-1">
                                                    <div className="w-full">
                                                        <DropdownTailwind
                                                            label={"Placement"}
                                                            selected={ad.preview ? ad.preview : { id: 1, name: "Feed", value: "feed" }}
                                                            options={Array.isArray(ad.previews) ? ad.previews : [{ id: 1, name: "Feed", value: "feed" }]}
                                                            onChange={(value) => {
                                                                ad.preview = value;
                                                                this.setState({
                                                                    ads: this.state.ads
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex justify-center items-center'>

                                                {
                                                    (this.state.ext_selected_channel_tab.value === "facebook" || this.state.ext_selected_channel_tab.value === "instagram") &&
                                                    <PreviewSectionFacebook
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.ext_selected_channel_tab.value === "instagram" ? { name: 'instagran', value: 'instagram' } : this.state.channel}
                                                        is_post={ad.type === "posts" ? true : false}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "tiktok" &&
                                                    <PreviewSectionTikTok
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "snapchat" &&
                                                    <PreviewSectionSnapchat
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "linkedin" &&
                                                    <PreviewSectionLinkedin
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "twitter" &&
                                                    <PreviewSectionTwitter
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "youtube" &&
                                                    <PreviewSectionYoutube
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                                {
                                                    this.state.ext_selected_channel_tab.value === "google" &&
                                                    <PreviewSectionGoogle
                                                        ad={ad}
                                                        ads={this.state.ads}
                                                        channel={this.state.channel}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </>
                }
            </>
        )
    }
}

export default PreviewSection;
