import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAssets(client) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/keyword/negative/assets/" + client;
        return apiRegister.call(options, url);
    }

    static getCampaignAssets(campaign) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/keyword/negative/campaign/assets/" + campaign;
        return apiRegister.call(options, url);
    }

    static createAsset(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/keyword/negative/assets";
        return apiRegister.call(options, url);
    }

    static removeAsset(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/keyword/negative/assets/campaign";
        return apiRegister.call(options, url);
    }

    static addKeywords(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/keyword/negative/assets/keywords";
        return apiRegister.call(options, url);
    }

    static addAsset(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/keyword/negative/assets";
        return apiRegister.call(options, url);
    }

}

export {calls}