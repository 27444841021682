import React, { Component, Fragment } from 'react';
import ReactGridLayout from "../../pages/premium-report/react-grid-layout";

class ReportGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      campaigns: [],
      adgroups: [],
      ads: [],
      groups: [],
      tab: 0,
      static: false
    };
  };

  componentDidMount() {
    this.setState({
      static: this.props.static,
      data: this.props.data ? this.props.data : [],
      groups: this.props.groups ? this.props.groups : [],
      campaigns: Array.isArray(this.props.campaigns) ? this.props.campaigns : [],
      adgroups: Array.isArray(this.props.adgroups) ? this.props.adgroups : [],
      ads: Array.isArray(this.props.ads) ? this.props.ads : [],
      tabLoading: this.props.tabLoading
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: nextProps.data ? nextProps.data : [],
      groups: nextProps.groups ? nextProps.groups : [],
      campaigns: Array.isArray(nextProps.campaigns) ? nextProps.campaigns : [],
      adgroups: Array.isArray(nextProps.adgroups) ? nextProps.adgroups : [],
      ads: Array.isArray(nextProps.ads) ? nextProps.ads : [],
      tabLoading: nextProps.tabLoading
    })
  }

  updateCell(cell) {
    try {
      this.refs.grid.updateCell(cell);
    } catch (error) {

    }
  }

  updateLoaderCell(cell) {
    try {
      this.refs.grid.updateLoaderCell(cell);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="">
        {
          Array.isArray(this.state.data) &&
          <ReactGridLayout
            static={this.state.static}
            ref={"grid"}
            onGridChange={(items, layout) => {
              if (this.props.onGridChange) {
                this.props.onGridChange(items);
              }
            }}

            tabLoading={this.state.tabLoading}

            initialItems={this.state.data}
            groups={this.state.groups}

            campaigns={this.state.campaigns}
            adgroups={this.state.adgroups}
            ads={this.state.ads}

            isSlideOverClosed={() => {
              this.setState({
                isSlideOverClosed: !this.state.isSlideOverClosed
              });
            }}
            backgroundColor={'border bg-gray-100 bg-opacity-50 overflow-hidden border-gray-200'}
            isBounded={false}
            preventCollision={false}
            verticalCompact={true}
            remove={true}
            //ref={this.child}
            removePage={(group) => {
              if (this.props.removePage) {
                this.props.removePage(group);
              }
            }}
            onEditItem={(item) => {
              if (this.props.onEditItem) {
                this.props.onEditItem(item);
              }
            }}
            onRefreshItem={(item) => {
              if (this.props.onRefreshItem) {
                this.props.onRefreshItem(item);
              }
            }}
            onUpdateItem={(item) => {
              if (this.props.onUpdateItem) {
                this.props.onUpdateItem(item);
              }
            }}
            clonePage={(group) => {
              if (this.props.clonePage) {
                this.props.clonePage(group);
              }
            }}
            cloneCell={(cell) => {

            }}
            addPage={() => {
              if (this.props.addPage) {
                this.props.addPage();
              }
            }}
            addRows={(group) => {
              if (this.props.addRows) {
                this.props.addRows(group);
              }
            }}
            removeRows={(group) => {
              if (this.props.removeRows) {
                this.props.removeRows(group);
              }
            }}
            addCell={(group) => {
              if (this.props.addCell) {
                this.props.addCell(group);
              }
            }}
            updateGroups={(groups) => {
              if (this.props.updateGroups) {
                this.props.updateGroups(groups);
              }
            }}
            onUpdate={(item) => {
              this.setState({
                data: this.state.data.map((inner_item) => {
                  if (inner_item.i === item.i) {
                    inner_item = item;
                  }
                  return inner_item;
                })
              }, () => {
                if (this.props.updateGrid) {
                  this.props.updateGrid(this.state.data);
                }
              })
            }}
            onRemove={(item) => {
              this.setState({
                data: this.state.data.filter((inner_item) => {
                  return inner_item.i !== item.i;
                })
              }, () => {
                if (this.props.updateGrid) {
                  this.props.updateGrid(this.state.data);
                }
              })
            }}
            onCopyItem={(item) => {
              if (this.props.cloneCell) {
                this.props.cloneCell(item);
              }
            }}
          />
        }
      </div>
    )
  }

}

export default ReportGrid;