import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";

class AdvertisingCreativesVariationsSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: this.props.channels ? this.props.channels : {},
            variations: this.props.variations ? this.props.variations : {},
            error: this.props.error,
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels ? nextProps.channels : {},
            variations: nextProps.variations ? nextProps.variations : {},
            error: nextProps.error
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels ? this.props.channels : {},
            variations: this.props.variations ? this.props.variations : {},
            error: this.props.error
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        error: () => {
            try{
                return this.state.error && !this.state.variations.search_one && !this.state.variations.search_two && !this.state.variations.search_three && !this.state.variations.search_four
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%', paddingTop: '0px'}}>
                <div className="Section-Inner">
                    <div className="Section-InnerTitle">
                        <div className={cx("InputContainerTitleText")}>
                            Search combination
                        </div>
                        {
                            <div className={cx("InputContainerTitleIcons")}>
                                {
                                    this.state.channels.google &&
                                    <div style={{marginLeft: '3px'}}>
                                        <IconGoogle noMargin={true}/>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="RelatedSites-items">
                        {
                            <div className={
                                cx("Variations-Item",
                                    {
                                        ["Variations-ItemError"]: this.renders.error(),
                                    })
                            }>
                                <div className="Variations-ItemLine">
                                    <div className="Variations-ItemLineTitle">
                                        headline 1 - headline 2 - headline 3
                                    </div>
                                    <div className="Variations-ItemLineSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            disabled={this.state.variations.search_one && !this.state.variations.search_two && !this.state.variations.search_three && !this.state.variations.search_four}
                                            checked={this.state.variations.search_one}
                                            onChange={(checked) => {
                                                this.state.variations.search_one = checked;
                                                this.props.onChange(this.state.variations);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="Variations-ItemBorder"/>
                                <div className="Variations-ItemLine">
                                    <div className="Variations-ItemLineTitle">
                                        keyword - headline 2 - headline 3
                                    </div>
                                    <div className="Variations-ItemLineSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            disabled={!this.state.variations.search_one && this.state.variations.search_two && !this.state.variations.search_three && !this.state.variations.search_four}
                                            checked={this.state.variations.search_two}
                                            onChange={(checked) => {
                                                this.state.variations.search_two = checked;
                                                this.props.onChange(this.state.variations);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="Variations-ItemBorder"/>
                                <div className="Variations-ItemLine">
                                    <div className="Variations-ItemLineTitle">
                                        headline 1 - keyword - headline 3
                                    </div>
                                    <div className="Variations-ItemLineSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            disabled={!this.state.variations.search_one && !this.state.variations.search_two && this.state.variations.search_three && !this.state.variations.search_four}
                                            checked={this.state.variations.search_three}
                                            onChange={(checked) => {
                                                this.state.variations.search_three = checked;
                                                this.props.onChange(this.state.variations);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="Variations-ItemBorder"/>
                                <div className="Variations-ItemLine">
                                    <div className="Variations-ItemLineTitle">
                                        headline 1 - headline 2 - keyword
                                    </div>
                                    <div className="Variations-ItemLineSlider">
                                        <Switch
                                            onColor="#24bda8"
                                            disabled={!this.state.variations.search_one && !this.state.variations.search_two && !this.state.variations.search_three && this.state.variations.search_four}
                                            checked={this.state.variations.search_four}
                                            onChange={(checked) => {
                                                this.state.variations.search_four = checked;
                                                this.props.onChange(this.state.variations);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesVariationsSearch;
