class adLinkedin {

    static validate(ad) {
        let error = {
            name: false,
            headline: false,
            body: false,
            page: false,
            website: false,
            cta: false,
            slides: false
        };

        if (ad) {
            if (!ad.name || ad.name === "") {
                error.name = true;
            }
            if (!ad.page || ad.page.id === 0) {
                error.page = true;
            }

            if (!ad.link || ad.link === "" || !ad.link.includes("https://")) {
                error.website = true;


            }
            if (!ad.body || ad.body === "") {
                error.body = true;

            }
            if (!ad.title || ad.title === "") {
                error.headline = true;
                if (ad.type === "posts") error.headline = false;
            }
            if (!ad.cta || ad.cta.id === 0) {
                error.cta = true;
            }

            if (ad.type && ad.type === "carousel") {
                if (!ad.slides || ad.slides.length < 1 || ad.slides.map((item) => { if (!item.link || !item.link.includes("https://") || !item.title || item.title === "") { return true } else return false }).filter((item) => { return item }).length > 0) {
                    error.slides = true;
                }
            }
        }


        return error;
    }
}
export { adLinkedin }