import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import cn from "classnames";

class CreateGoogleNegativeKeyword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            campaigns: [],
            adgroups: [],
            client: {},
            level: { id: 1, name: "Campaign level", value: "campaign" },
            campaign: { id: 0, name: "Click here ..." },
            adgroup: { id: 0, name: "Click here ..." },
            match_type: { id: 1, name: "Exact match", value: "EXACT" },
            match_types: { exact: true, phrase: false, broad: false },
            keyword: "",
            loader: false,
            searchTerm: null
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            client: this.props.client,
            searchTerm: this.props.searchTerm,
            keyword: this.props.searchTerm ? this.props.searchTerm.text : ""
        })
        if (Array.isArray(this.state.campaigns) && this.state.campaigns.length > 0) {
            let campaign = null;
            this.state.campaigns.map((item) => {
                if (this.state.searchTerm && item.id == this.state.searchTerm.campaign_id) {
                    campaign = item;
                }
            });
            if (campaign) {
                await this.promisedSetState({
                    campaign: campaign
                })
            } else {
                await this.promisedSetState({
                    campaign:  this.state.campaigns[0]
                })
            }
        }
    }

    functions = {
        create: async () => {
            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);
            let body = {
                operations: []
            };
            for (let key in this.state.match_types) {
                let match_type = "";
                if (key == "exact") {
                    match_type = "EXACT";
                } else if (key == "phrase") {
                    match_type = "PHRASE";
                } else if (key == "broad") {
                    match_type = "BROAD";
                } else {
                    match_type = "EXACT";
                }
                if (this.state.match_types[key]) {
                    let operation = {
                        "create": {
                            keyword: {
                                text: this.state.keyword,
                                match_type: match_type
                            },
                            negative: true,
                            status: "ENABLED"
                        }
                    };
                    if (this.state.level.id === 1) {
                        operation.create.campaign = this.state.campaign.resource_name;
                    } else {
                        operation.create.adGroup = this.state.adgroup.resource_name;
                    }
                    body.operations.push(operation);
                }
            }
            try {
                if (this.state.level.id === 1) {
                    await this.calls.createCampaignKeyword(body);
                } else {
                    await this.calls.createAdgroupKeyword(body);
                }
                this.props.onSuccess("Keyword created");
                await this.promisedSetState({
                    keyword: "",
                    level: { id: 1, name: "Campaign level", value: "campaign" },
                    campaign: { id: 0, name: "Click here ..." },
                    adgroup: { id: 0, name: "Click here ..." },
                    match_types: { exact: true, phrase: false, broad: false },
                    keyword: ""
                })
                this.props.onCreate();
            } catch (error) {
                this.props.onError(error.body ? error.body.message : "Something went wrong");
            }
            this.props.onLoader(false);
        }
    };

    calls = {
        createCampaignKeyword: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createCampaignCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createAdgroupKeyword: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createAdgroupCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    timeout = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    render() {
        return (
            <>
                <div className="mt-5 grid grid-cols-3 gap-4">

                    {/*Basic*/}
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Level"}
                            selected={this.state.level}
                            options={[
                                { id: 1, name: "Campaign level", value: "campaign" },
                                { id: 2, name: "Adgroup level", value: "adgroup" }
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    level: value
                                })
                            }}
                        />
                    </div>

                    {/*Campaign level*/}
                    {
                        this.state.level.id == 1 &&
                        <div className="col-span-3">
                            <DropdownTailwind
                                label={"Select campaigns"}
                                searchInput={true}
                                selected={this.state.campaign}
                                options={Array.isArray(this.state.campaigns) ? this.state.campaigns : []}
                                onChange={(value) => {
                                    this.setState({
                                        campaign: value
                                    })
                                }}
                            />
                        </div>
                    }

                    {/*Adgroup level*/}
                    {
                        this.state.level.id == 2 &&
                        <div className="col-span-3">
                            <DropdownTailwind
                                label={"Select adgroups"}
                                searchInput={true}
                                selected={this.state.adgroup}
                                options={Array.isArray(this.state.adgroups) ? this.state.adgroups : []}
                                onChange={(value) => {
                                    this.setState({
                                        adgroup: value
                                    })
                                }}
                            />
                        </div>
                    }

                    {/*Keyword info*/}
                    <div className="col-span-3">
                        {
                            false &&
                            <DropdownTailwind
                                label={"Match type"}
                                selected={this.state.match_type}
                                options={[
                                    { id: 1, name: "Exact match", value: "EXACT" },
                                    { id: 2, name: "Phrase match", value: "PHRASE" },
                                    { id: 3, name: "Broad match", value: "BROAD" }
                                ]}
                                onChange={(value) => {
                                    this.setState({
                                        match_type: value
                                    })
                                }}
                            />
                        }
                        <div className="block text-xs font-medium text-gray-700">Match types</div>
                        <div className="mt-1 grid grid-cols-3 gap-2">
                            {
                                Object.keys(this.state.match_types).map((key) => {
                                    return (
                                        <div className="col-span-1">
                                            <div onClick={() => {
                                                this.state.match_types[key] = !this.state.match_types[key];
                                                this.setState({
                                                    match_types: this.state.match_types
                                                })
                                            }} className={(this.state.match_types[key] ? "border-purple-500" : "border-estatery-400") + " relative w-full hover:border-purple-500 hover:text-purple-500 items-center flex bg-white border-1.5  rounded-md pl-3 py-2 text-left cursor-pointer focus:outline-none sm:text-sm"}>
                                                <div className={(this.state.match_types[key] ? "text-purple-500" : "") + " flex items-center flex-1 "}>
                                                    {key}
                                                </div>
                                                <div className="items-center justify-center ml-3 absolute inset-y-0 right-0 flex pr-2 pointer-events-none">
                                                    {
                                                        this.state.match_types[key] &&
                                                        <CheckIcon className="h-5 w-5 text-purple-500" />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-span-3">
                        <InputTailwind
                            label={"Keyword text"}
                            value={this.state.keyword}
                            onChange={(value) => {
                                this.setState({
                                    keyword: value
                                })
                            }}
                        />
                    </div>

                </div>
            </>
        )
    }
}

export default CreateGoogleNegativeKeyword;
