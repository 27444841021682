import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAgency(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/agency/" + id;
        return apiRegister.call(options, url);
    }

    static updateAgency(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/agency/" + data._id;
        return apiRegister.call(options, url);
    }

}

export {calls}