import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";
import Input from "../../components/input";
import Dropdown from "../../components/dropdown";
import DropDown from "../dropdown";
import Loader from "../../components/loader";

class NegativeKeywordList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            campaignitems: [],
            sort: "",
            order: "",
            total: 0,
            pagesize: 0,
            type: {name: 'Exact match', value: 'exact'},
            level: {name: 'Add to Campaign', value: 'campaign'}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            campaignitems: this.props.campaignitems,
            sort: this.props.sort,
            order: this.props.order,
            total: this.props.total,
            pagesize: this.props.pagesize
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            campaignitems: nextProps.campaignitems,
            sort: nextProps.sort,
            order: nextProps.order,
            total: nextProps.total,
            pagesize: nextProps.pagesize
        })
    }

    functions = {
        removeNegative: (item) => {
            this.setState({
                loading: true
            }, () => {
                calls.removeNegative({
                    channels: {google: true},
                    campaign_id: this.state.campaign.id,
                    //adgroup_id: item.adgroup_id,
                    criterion_id: item.criterion_id,
                }).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onRemove(item);
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_message: error.body.message,
                        error_modal: true
                    })
                });
            })
        }
    };

    renders = {
        ending: (key, item) => {
            if (key === 'ctr') {
                return "%"
            } else if (key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion') {
                return " " + item.currency
            } else {
                return "";
            }
        }
    };

    render() {
        return (
            <div className="flex flex-col">
                {this.state.loading &&
                    <Loader
                        title={'Deleting negative keyword'}
                    />
                }
                {this.state.campaignitems.length > 0 && this.props.headline &&
                <h3 className="font-bold text-sm mb-2">{this.props.headline}</h3>
                }
                {this.state.campaignitems.length > 0 &&
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                <tr>
                                    <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex flex-row items-center justify-start">
                                            Actions{false&&<div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                    <th className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'campaign_name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Campaign
                                        </div>
                                    </th>
                                    {this.props.properties && this.props.properties.channel &&
                                        <th className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'query' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                Channel
                                            </div>
                                        </th>
                                    }
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('text', 'text' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'text' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center">
                                            Text <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    {this.props.properties && this.props.properties.type &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('type', 'type' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Type <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.level &&
                                    <th onClick={() => {
                                        try{
                                            this.props.updateSort('level', 'level' === this.state.sort && this.state.order === 'asc'?'desc':'asc');
                                        }catch (e) {}
                                    }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'level' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')} >
                                        <div className="flex flex-row items-center">
                                            Level <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.campaignitems.sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            nameA = a[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            nameB = b[this.state.sort].toLowerCase();
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).filter((item, i) => {
                                    return i < this.state.pagesize
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                    <div className="flex flex-row items-center justify-start">
                                                        {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete &&
                                                        <button onClick={() => {
                                                            //this.functions.removeNegative(item)
                                                            this.props.onRemove(item);
                                                        }} className="bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                            <span>Delete</span>
                                                            <FeatherIcon className="stroke-current ml-2" size={15} icon="trash"/>
                                                        </button>
                                                        }
                                                    </div>
                                                </td>
                                                {this.props.properties && this.props.properties.campaign_name &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'campaign_name'?'font-bold':'')}>
                                                        #{item.campaign}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.channel &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className="flex flex-row">
                                                        {item.channel === 'google' &&
                                                        <div className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{backgroundImage: "url(" + require('../../assets/images/google_icon.svg') + ")"}}></div>
                                                        </div>}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.text &&
                                                    <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                                        <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'text'?'font-bold':'')}>
                                                            {item.text}
                                                        </div>
                                                    </td>
                                                }
                                                {this.props.properties && this.props.properties.type &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'type'?'font-bold':'')}>
                                                        {item.type?item.type:'-'}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.level &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'level'?'font-bold':'')}>
                                                        {item.level?item.level:'-'}
                                                    </div>
                                                </td>
                                                }
                                            </tr>
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default NegativeKeywordList;