import moment from 'moment';
import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import UploadImage from '../components/image-upload';
import cn from "classnames";
import SwitchTailwind from './switchTailwind';

class CreateAgency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            company: "",
            logo: "",
            status: { name: "Active", value: "active" },
            premium: { name: "Enabled", value: true },
            test: { id: 0, name: "Live account", value: false },
            agency: {},
            admin_access: false,
            //tabs: [{ id: 0, name: "General settings" }, { id: 1, name: "Report whitelabel settings" }, { id: 2, name: "Client whitelabel settings" }, { id: 4, name: "Data settings" }, { id: 5, name: "CRM settings" }],
            tabs: [{ id: 0, name: "General settings" }, { id: 1, name: "Report whitelabel settings" }, { id: 4, name: "Data settings" }, { id: 5, name: "CRM settings" }, { id: 6, name: "Scheduler settings" }],
            sub_tabs: [{ id: 0, name: "Generic" }, { id: 1, name: "Navbar" }, { id: 2, name: "Footer" }],
            tab: { id: 0, name: "General settings" },
            sub_tab: { id: 0, name: "Navbar" },
            selectedId: "",
            languages: [
                { id: 1, name: "English", value: "en" },
                { id: 2, name: "Svenska", value: "sv" },
                { id: 3, name: "Dansk", value: "dk" },
                { id: 4, name: "Norsk", value: "no" }
            ],
            whitelabel_settings: {
                color_accent: "",
                color_navbar: "",
                color_font: "000000",
                color_background: "",
                logo_size: { id: 2, name: "Medium", value: "h-5" },
                shadows: false,
                logo_primary: "",
                footer: false,
                link_1: { id: 1, name: "", url: "" },
                link_2: { id: 2, name: "", url: "" },
                link_3: { id: 3, name: "", url: "" },
                link_4: { id: 4, name: "", url: "" },
                link_5: { id: 5, name: "", url: "" },
                color_footer: "",
                color_footer_font: "",
                copyright: false,
                footer_text: "",
            },
            client_settings: {
                color_font: "000000",
                color_accent: "",
                logo_size: { id: 2, name: "Medium", value: "h-5" },
                logo_primary: "",
                logo_secondary: "",
                defaultLanguage: { id: 1, name: "English", value: "en" },
                public_orders: true,
                show_impressions: true,
                show_clicks: true,
                show_spend: true,
                show_ctr: true,
                show_cpc: true,
            },
            scheduler: { name: "Disabled", value: false },

        };
    }

    componentDidMount() {
        if (this.props.agency && this.props.agency.id) {
            this.setState({
                agency: this.props.agency,
                name: this.props.agency.name,
                email: this.props.agency.email,
                logo: this.props.agency.logo,
                company: this.props.agency.company,
                custom_file_upload: this.props.agency.customFileUpload,
                custom_file_size: this.props.agency.customFileSize,
                custom_file_limit: this.props.agency.customFileLimit,
                crm_enabled: this.props.agency.crmEnabled,
                crm_id: this.props.agency.crmId,
                admin_access: this.props.agency.adminAccess,
                test: this.props.agency.test ? { id: 1, name: "Test account", value: true } : { id: 0, name: "Live account", value: false },
                status: this.props.agency.status === "active" ? { name: "Active", value: "active" } : { name: "Disabled", value: "disabled" },
                premium: this.props.agency.showPremium ? { name: "Enabled", value: true } : { name: "Disabled", value: false },
                tab: { id: 0, name: "General settings" },
                sub_tab: { id: 0, name: "Other settings" },
                whitelabel_settings: {
                    color_accent: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_accent : "",
                    color_navbar: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_navbar : "",
                    color_font: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_font : "000000",
                    color_background: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_background : "",
                    logo_size: this.props.agency.whitelabel ? this.props.agency.whitelabel.logo_size : { id: 2, name: "Medium", value: "h-5" },
                    shadows: this.props.agency.whitelabel ? this.props.agency.whitelabel.shadows : false,
                    logo_primary: this.props.agency.whitelabel ? this.props.agency.whitelabel.logo_primary : "",
                    link_1: this.props.agency.whitelabel && this.props.agency.whitelabel.link_1 ? this.props.agency.whitelabel.link_1 : { id: 1, name: "", value: "", url: "" },
                    link_2: this.props.agency.whitelabel && this.props.agency.whitelabel.link_2 ? this.props.agency.whitelabel.link_2 : { id: 2, name: "", value: "", url: "" },
                    link_3: this.props.agency.whitelabel && this.props.agency.whitelabel.link_3 ? this.props.agency.whitelabel.link_3 : { id: 3, name: "", value: "", url: "" },
                    link_4: this.props.agency.whitelabel && this.props.agency.whitelabel.link_4 ? this.props.agency.whitelabel.link_4 : { id: 4, name: "", value: "", url: "" },
                    link_5: this.props.agency.whitelabel && this.props.agency.whitelabel.link_5 ? this.props.agency.whitelabel.link_5 : { id: 5, name: "", value: "", url: "" },
                    footer: this.props.agency.whitelabel ? this.props.agency.whitelabel.footer : false,
                    color_footer: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_footer : "",
                    color_footer_font: this.props.agency.whitelabel ? this.props.agency.whitelabel.color_footer_font : "",
                    footer_text: this.props.agency.whitelabel ? this.props.agency.whitelabel.footer_text : "",
                    copyright: this.props.agency.whitelabel ? this.props.agency.whitelabel.copyright : false,
                },
                client_settings: {
                    color_font: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.color_font : "000000",
                    color_accent: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.color_accent : "",
                    logo_size: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.logo_size : { id: 2, name: "Medium", value: "h-5" },
                    logo_primary: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.logo_primary : "",
                    logo_secondary: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.logo_secondary : "",
                    defaultLanguage: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.defaultLanguage : { id: 1, name: "English", value: "en" },
                    public_orders: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.public_orders : true,
                    show_impressions: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.show_impressions : true,
                    show_clicks: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.show_clicks : true,
                    show_spend: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.show_spend : true,
                    show_ctr: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.show_ctr : true,
                    show_cpc: this.props.agency.whitelabelClient ? this.props.agency.whitelabelClient.show_cpc : true,
                },
                scheduler: this.props.agency.scheduler ? { name: "Active", value: true } : { name: "Disabled", value: false },
                ...(this.props.agency.budgetSplit && { budgetSplit: this.props.agency.budgetSplit })
            })
        }
    }

    functions = {
        createAgency: async () => {
            let agency = {
                name: this.state.name,
                email: this.state.email,
                company: this.state.company,
                logo: this.state.logo,
                status: this.state.status.value,
                showPremium: this.state.premium.value,
                adminAccess: this.state.admin_access,
                test: this.state.test.value,
                whitelabel: this.state.whitelabel_settings,
                whitelabelClient: this.state.client_settings,
                ...(this.state.custom_file_upload && { customFileUpload: this.state.custom_file_upload }),
                ...(this.state.custom_file_size && { customFileSize: this.state.custom_file_size }),
                ...(this.state.custom_file_limit && { customFileLimit: this.state.custom_file_limit }),
                scheduler: this.state.scheduler.value,
                ...(this.state.crm_enabled && { crmEnabled: this.state.crm_enabled }),
                ...(this.state.crm_id && { crmId: this.state.crm_id }),
                ...(this.state.budgetSplit && { budgetSplit: this.state.budgetSplit })
            };
            if (this.state.agency.id) {
                try {
                    await this.calls.updateAgency(agency);
                    this.props.onCreated();
                } catch (error) {
                    this.setState({
                        error: error.body.message
                    });
                    this.props.onError();
                }
            } else {
                try {
                    await this.calls.createAgency(agency);
                    this.props.onCreated();
                } catch (error) {
                    this.setState({
                        error: error.body.message
                    });
                    this.props.onError();
                }
            }
        },
    };

    calls = {
        createAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createAgency";
            return apiRegister.call(options, url);
        },
        updateAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgency?id=" + this.state.agency.id;
            return apiRegister.call(options, url);
        }
    };


    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="col-span-6 mb-4">
                    <div className="block overflow-scroll">
                        <nav className="flex space-x-4" aria-label="Tabs">
                            {this.state.tabs.filter((item) => {
                                return (!(this.props.agency && this.props.agency.id) && item.id == 0) || (this.props.agency && this.props.agency.id)
                            }).map((tab) => (
                                <div
                                    onClick={() => (
                                        this.setState({
                                            tab: tab
                                        })
                                    )}
                                    key={tab.name}
                                    style={{ whiteSpace: "nowrap" }}
                                    className={cn(
                                        tab.id == this.state.tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                        'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                    )}
                                    aria-current={tab.id == this.state.tab.id ? 'page' : undefined}
                                >
                                    {tab.name}
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>

                {
                    this.state.tab.id === 0 &&
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Agency type"}
                                            selected={this.state.test ? this.state.test : {}}
                                            options={[
                                                { id: 0, name: "Live account", value: false },
                                                { id: 1, name: "Test account", value: true }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    test: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Name"}
                                            value={this.state.name}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    name: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Company name"}
                                            value={this.state.company}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    company: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Email"}
                                            value={this.state.email}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    email: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='grid grid-cols-12 gap-4 mt-4 w-full'>
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Show professional report"}
                                            selected={this.state.premium ? this.state.premium : {}}
                                            options={[
                                                { name: "Enabled", value: true },
                                                { name: "Disabled", value: false }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    premium: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            disabled={!this.state.agency.id}
                                            locked={!this.state.agency.id}
                                            label={"Agency status"}
                                            selected={this.state.status ? this.state.status : {}}
                                            options={[
                                                { name: "Active", value: "active" },
                                                { name: "Disabled", value: "disabled" }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    status: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <div className='flex flex-col flex-1'>
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Admin access</div>
                                            <div
                                                className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md'
                                                style={{paddingTop: "0.5rem", paddingBottom: "0.6rem"}}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.state.admin_access ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.admin_access}
                                                        onSwitch={async () => {
                                                            this.setState({
                                                                admin_access: !this.state.admin_access
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-12 mt-4'>
                                    <div className="col-span-12 h-32 max-h-40">
                                        <UploadImage
                                            imageOnly={true}
                                            title={"Upload logo (png)"}
                                            size={"full"}
                                            cover={false}
                                            logo={true}
                                            value={this.state.logo}
                                            error={this.state.error === "logo" && !this.state.logo}
                                            onChange={(url) => {
                                                this.setState({logo: url});
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.error &&
                                        <div className={"col-span-12"}>
                                            <div className="bg-red-100 p-4 rounded-md">
                                                <div className="text-sm text-red-500">
                                                    {this.state.error}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* REPORT WHITELABEL SETTINGS */}
                {
                    this.state.tab.id === 1 &&

                    <>

                        <div className='flex w-full space-x-4 mb-4'>
                            {
                                this.state.sub_tabs.map((sub_tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                sub_tab: sub_tab
                                            })
                                        )}
                                        key={sub_tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            sub_tab.id == this.state.sub_tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={sub_tab.id == this.state.sub_tab.id ? 'page' : undefined}
                                    >
                                        {sub_tab.name}
                                    </div>
                                ))
                            }
                        </div>

                        {
                            this.state.sub_tab.id === 0 &&
                            <div className='grid grid-cols-4 gap-4 mb-4'>
                                <div className="col-span-1">
                                    <div className="text-xs font-medium mb-1">
                                        Background color
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_background" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="color_background"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='000000'
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_background ? this.state.whitelabel_settings.color_background : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.whitelabel_settings.color_background = e.target.value;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_background ? this.state.whitelabel_settings.color_background : "ffffff")}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.whitelabel_settings.color_background = color_string;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {
                            this.state.sub_tab.id === 1 &&

                            <div className="grid grid-cols-4 gap-4">

                                {/* UPLOAD WHITELABEL REPORT LOGO */}
                                <div className='col-span-5 w-full gap-4'>
                                    <UploadImage
                                        imageOnly={true}
                                        title={"Upload logo (png)"}
                                        size={"full"}
                                        cover={false}
                                        logo={true}
                                        max_width={false}
                                        value={this.state.whitelabel_settings.logo_primary}
                                        error={this.state.error === "logo" && !this.state.logo}
                                        onChange={(url) => {
                                            // this.setState({ logo: url });
                                            this.state.whitelabel_settings.logo_primary = url;
                                            this.promisedSetState({
                                                whitelabel_settings: this.state.whitelabel_settings
                                            })
                                        }}
                                    />
                                </div>

                                <div className='col-span-1'>
                                    <DropdownTailwind
                                        label='Logo size'
                                        selected={this.state.whitelabel_settings.logo_size && this.state.whitelabel_settings.logo_size.value ? this.state.whitelabel_settings.logo_size : { id: 2, name: 'Medium', value: "h-5" }}
                                        options={[
                                            { id: 1, name: "Large", value: "h-8" },
                                            { id: 2, name: "Medium", value: "h-5" },
                                            { id: 3, name: "Small", value: "h-3" },
                                        ]}
                                        onChange={async (value) => {
                                            this.state.whitelabel_settings.logo_size = value;
                                            await this.promisedSetState({
                                                whitelabel_settings: this.state.whitelabel_settings
                                            })
                                        }}
                                    />
                                </div>

                                {/* PICK WHITELABEL AGENCY COLORS */}

                                <div className="col-span-1">
                                    <div className="text-xs font-medium mb-1">
                                        Accent color
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_accent" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="color_accent"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='FFFFFF'
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_accent ? this.state.whitelabel_settings.color_accent : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.whitelabel_settings.color_accent = e.target.value;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_accent ? this.state.whitelabel_settings.color_accent : "ffffff")}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.whitelabel_settings.color_accent = color_string;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <div className="text-xs font-medium mb-1">
                                        Font color
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_font" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="color_font"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='000000'
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_font ? this.state.whitelabel_settings.color_font : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.whitelabel_settings.color_font = e.target.value;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_font ? this.state.whitelabel_settings.color_font : "ffffff")}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.whitelabel_settings.color_font = color_string;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <div className="text-xs font-medium mb-1">
                                        Navbar color
                                    </div>
                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_navbar" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="color_navbar"
                                                    className="w-full border-none p-2 bg-transparent"
                                                    type="text"
                                                    placeholder='000000'
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_navbar ? this.state.whitelabel_settings.color_navbar : "ffffff"}
                                                    onClick={(e) => {
                                                        this.setState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        this.state.whitelabel_settings.color_navbar = e.target.value;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className="absolute cursor-pointer"
                                                    type="color"
                                                    value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_navbar ? this.state.whitelabel_settings.color_navbar : "ffffff")}
                                                    onChange={async (e) => {
                                                        // if (!this.state.whitelabel_settings) {
                                                        //     this.state.whitelabel_settings = { color_accent: "" };
                                                        // }
                                                        let color_string = e.target.value.slice(1)
                                                        this.state.whitelabel_settings.color_navbar = color_string;
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        }

                        {
                            this.state.sub_tab.id === 2 &&
                            <>


                                <div className='grid grid-cols-5 gap-4 mb-4'>

                                    <div className='col-span-1'>
                                        <div className='flex flex-col w-full'>
                                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Footer</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 text-sm ml-5">
                                                    {this.state.whitelabel_settings.footer ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.whitelabel_settings.footer ? true : false}
                                                        onSwitch={async () => {
                                                            this.state.whitelabel_settings.footer = !this.state.whitelabel_settings.footer;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="text-xs font-medium mb-1">
                                            Footer color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_navbar" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_navbar"
                                                        className={`w-full border-none p-2 bg-transparent ${this.state.whitelabel_settings && !this.state.whitelabel_settings.footer && "cursor-not-allowed"}`}
                                                        type="text"
                                                        placeholder='ffffff'
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer ? this.state.whitelabel_settings.color_footer : "ffffff"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_footer = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className={`absolute ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                        type="color"
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer ? this.state.whitelabel_settings.color_footer : "ffffff")}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_footer = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="text-xs font-medium mb-1">
                                            Font color
                                        </div>
                                        <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_footer_font" ? "border-purple-500" : ""}`}>
                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                <div
                                                    style={{ backgroundColor: "#fcfcfc" }}
                                                    className="flex items-center col-span-3 p-1">
                                                    <span className={`pl-3`}>#</span>
                                                    <input
                                                        id="color_footer_font"
                                                        className={`w-full border-none p-2 bg-transparent ${this.state.whitelabel_settings && !this.state.whitelabel_settings.footer && "cursor-not-allowed"}`}
                                                        type="text"
                                                        placeholder='333333'
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        value={this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer_font ? this.state.whitelabel_settings.color_footer_font : "333333"}
                                                        onClick={(e) => {
                                                            this.setState({ selectedId: e.target.id })
                                                        }}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                            this.state.whitelabel_settings.color_footer_font = e.target.value;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                    <input
                                                        style={{ width: 300, height: 65, bottom: -10 }}
                                                        className={`absolute ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                        type="color"
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        value={"#" + (this.state.whitelabel_settings && this.state.whitelabel_settings.color_footer_font ? this.state.whitelabel_settings.color_footer_font : "333333")}
                                                        onChange={async (e) => {
                                                            // if (!this.state.whitelabel_settings) {
                                                            //     this.state.whitelabel_settings = { color_accent: "" };
                                                            // }
                                                            let color_string = e.target.value.slice(1)
                                                            this.state.whitelabel_settings.color_footer_font = color_string;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="w-full grid grid-cols-5 gap-4">
                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <InputTailwind
                                                    label="Link 1 text"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_1 && this.state.whitelabel_settings.link_1.name ? this.state.whitelabel_settings.link_1.name : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_1.name = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <InputTailwind
                                                    label="URL"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_1 && this.state.whitelabel_settings.link_1.url ? this.state.whitelabel_settings.link_1.url : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_1.url = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <InputTailwind
                                                    label="Link 2"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_2 && this.state.whitelabel_settings.link_2.name ? this.state.whitelabel_settings.link_2.name : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_2.name = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <InputTailwind
                                                    label="URL"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_2 && this.state.whitelabel_settings.link_2.url ? this.state.whitelabel_settings.link_2.url : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_2.url = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <InputTailwind
                                                    label="Link 3 text"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_3 && this.state.whitelabel_settings.link_3.name ? this.state.whitelabel_settings.link_3.name : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_3.name = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <InputTailwind
                                                    label="URL"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_3 && this.state.whitelabel_settings.link_3.url ? this.state.whitelabel_settings.link_3.url : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_3.url = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <InputTailwind
                                                    label="Link 4 text"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_4 && this.state.whitelabel_settings.link_4.name ? this.state.whitelabel_settings.link_4.name : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_4.name = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <InputTailwind
                                                    label="URL"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_4 && this.state.whitelabel_settings.link_4.url ? this.state.whitelabel_settings.link_4.url : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_4.url = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <InputTailwind
                                                    label="Link 5 text"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_5 && this.state.whitelabel_settings.link_5.name ? this.state.whitelabel_settings.link_5.name : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_5.name = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <InputTailwind
                                                    label="URL text"
                                                    value={this.state.whitelabel_settings && this.state.whitelabel_settings.link_5 && this.state.whitelabel_settings.link_5.url ? this.state.whitelabel_settings.link_5.url : ""}
                                                    disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                    onChange={async (value) => {
                                                        this.state.whitelabel_settings.link_5.url = value
                                                        await this.promisedSetState({
                                                            whitelabel_settings: this.state.whitelabel_settings
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className='grid w-full grid-cols-5 mt-4 gap-4'>

                                    <div className={`col-span-4 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <InputTailwind
                                            label="Footer text"
                                            value={this.state.whitelabel_settings && this.state.whitelabel_settings.footer_text ? this.state.whitelabel_settings.footer_text : ""}
                                            disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                            onChange={async (value) => {
                                                this.state.whitelabel_settings.footer_text = value
                                                await this.promisedSetState({
                                                    whitelabel_settings: this.state.whitelabel_settings
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className={`col-span-1 ${this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? "opacity-100" : "opacity-50"}`}>
                                        <div className='flex flex-col w-full'>
                                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Copyright icon & year</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 text-sm ml-5">
                                                    {this.state.whitelabel_settings.copyright ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.whitelabel_settings.copyright ? true : false}
                                                        disabled={this.state.whitelabel_settings && this.state.whitelabel_settings.footer ? false : true}
                                                        onSwitch={async () => {
                                                            this.state.whitelabel_settings.copyright = !this.state.whitelabel_settings.copyright;
                                                            await this.promisedSetState({
                                                                whitelabel_settings: this.state.whitelabel_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        }

                        {false &&
                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Shadows</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-5">
                                            {this.state.whitelabel_settings.shadows ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.whitelabel_settings.shadows ? true : false}
                                                onSwitch={async () => {
                                                    this.state.whitelabel_settings.shadows = !this.state.whitelabel_settings.shadows;
                                                    await this.promisedSetState({
                                                        whitelabel_settings: this.state.whitelabel_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }


                {/* CLIENT WHITELABEL SETTINGS */}
                {
                    this.state.tab.id === 2 &&
                    <>
                        <div className="grid grid-cols-4 gap-4">

                            {/* PICK WHITELABEL CLIENT COLORS */}

                            <div className="col-span-1">
                                <div className="text-xs font-medium mb-1">
                                    Font color
                                </div>
                                <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_font" ? "border-purple-500" : ""}`}>
                                    <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center col-span-3 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="color_font"
                                                className="w-full border-none p-2 bg-transparent"
                                                type="text"
                                                placeholder='000000'
                                                value={this.state.client_settings && this.state.client_settings.color_font ? this.state.client_settings.color_font : "ffffff"}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    // if (!this.state.client_settings) {
                                                    //     this.state.client_settings = { color_accent: "" };
                                                    // }
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.client_settings.color_font = e.target.value;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                            <input
                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                className="absolute cursor-pointer"
                                                type="color"
                                                value={"#" + (this.state.client_settings && this.state.client_settings.color_font ? this.state.client_settings.color_font : "ffffff")}
                                                onChange={async (e) => {
                                                    // if (!this.state.client_settings) {
                                                    //     this.state.client_settings = { color_accent: "" };
                                                    // }
                                                    let color_string = e.target.value.slice(1)
                                                    this.state.client_settings.color_font = color_string;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1">
                                <div className="text-xs font-medium mb-1">
                                    Accent color
                                </div>
                                <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_accent" ? "border-purple-500" : ""}`}>
                                    <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center col-span-3 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="color_accent"
                                                className="w-full border-none p-2 bg-transparent"
                                                type="text"
                                                placeholder='FFFFFF'
                                                value={this.state.client_settings && this.state.client_settings.color_accent ? this.state.client_settings.color_accent : "ffffff"}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    // if (!this.state.client_settings) {
                                                    //     this.state.client_settings = { color_accent: "" };
                                                    // }
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.client_settings.color_accent = e.target.value;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                            <input
                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                className="absolute cursor-pointer"
                                                type="color"
                                                value={"#" + (this.state.client_settings && this.state.client_settings.color_accent ? this.state.client_settings.color_accent : "ffffff")}
                                                onChange={async (e) => {
                                                    // if (!this.state.client_settings) {
                                                    //     this.state.client_settings = { color_accent: "" };
                                                    // }
                                                    let color_string = e.target.value.slice(1)
                                                    this.state.client_settings.color_accent = color_string;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-span-2'>
                                <div className='grid grid-cols-3 gap-4'>

                                    <div className='col-span-1'>
                                        <div className='flex flex-col w-full'>
                                            <DropdownTailwind
                                                label='Primary logo size'
                                                selected={this.state.client_settings.logo_size && this.state.client_settings.logo_size.value ? this.state.client_settings.logo_size : { id: 2, name: 'Medium', value: "h-5" }}
                                                options={[
                                                    { id: 1, name: "Large", value: "h-8" },
                                                    { id: 2, name: "Medium", value: "h-5" },
                                                    { id: 3, name: "Small", value: "h-3" },
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.client_settings.logo_size = value;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-span-1'>
                                        <div className='flex flex-col w-full'>
                                            <DropdownTailwind
                                                label='Default language'
                                                selected={this.state.client_settings.defaultLanguage && this.state.client_settings.defaultLanguage.value ? this.state.client_settings.defaultLanguage : { id: 1, name: 'English', value: "en" }}
                                                options={this.state.languages}
                                                onChange={async (value) => {
                                                    this.state.client_settings.defaultLanguage = value;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-span-1'>
                                        <div className='flex flex-col w-full'>
                                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Public orders</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 text-sm ml-3">
                                                    {this.state.client_settings.public_orders ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.client_settings.public_orders ? true : false}
                                                        onSwitch={async () => {
                                                            this.state.client_settings.public_orders = !this.state.client_settings.public_orders;
                                                            await this.promisedSetState({
                                                                client_settings: this.state.client_settings
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>



                        </div>

                        <div className='text-sm font-medium mt-4 mb-2'>
                            Visible metrics on client dashboard
                        </div>

                        <div className='grid grid-cols-5 gap-4 mb-4'>

                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Impressions</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-3">
                                            {this.state.client_settings.show_impressions ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.client_settings.show_impressions ? true : false}
                                                onSwitch={async () => {
                                                    this.state.client_settings.show_impressions = !this.state.client_settings.show_impressions;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Clicks</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-3">
                                            {this.state.client_settings.show_clicks ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.client_settings.show_clicks ? true : false}
                                                onSwitch={async () => {
                                                    this.state.client_settings.show_clicks = !this.state.client_settings.show_clicks;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Spend</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-3">
                                            {this.state.client_settings.show_spend ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.client_settings.show_spend ? true : false}
                                                onSwitch={async () => {
                                                    this.state.client_settings.show_spend = !this.state.client_settings.show_spend;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>CTR</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-3">
                                            {this.state.client_settings.show_ctr ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.client_settings.show_ctr ? true : false}
                                                onSwitch={async () => {
                                                    this.state.client_settings.show_ctr = !this.state.client_settings.show_ctr;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>CPC</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-3">
                                            {this.state.client_settings.show_cpc ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.client_settings.show_cpc ? true : false}
                                                onSwitch={async () => {
                                                    this.state.client_settings.show_cpc = !this.state.client_settings.show_cpc;
                                                    await this.promisedSetState({
                                                        client_settings: this.state.client_settings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* UPLOAD CLIENT WHITELABEL AGENCY LOGO */}
                        <div className='flex w-full gap-4 mt-4'>
                            <div className='flex flex-col w-full'>
                                <UploadImage
                                    imageOnly={true}
                                    title={"Upload primary logo (png)"}
                                    size={"full"}
                                    cover={false}
                                    logo={true}
                                    max_width={false}
                                    value={this.state.client_settings.logo_primary}
                                    error={this.state.error === "logo" && !this.state.logo}
                                    onChange={(url) => {
                                        // this.setState({ logo: url });
                                        this.state.client_settings.logo_primary = url;
                                        this.promisedSetState({
                                            client_settings: this.state.client_settings
                                        })
                                    }}
                                />
                            </div>
                            <div className='w-full'>
                                <UploadImage
                                    imageOnly={true}
                                    title={"Upload smaller, secondary logo (png)"}
                                    size={"full"}
                                    cover={false}
                                    logo={true}
                                    max_width={false}
                                    value={this.state.client_settings.logo_secondary}
                                    error={this.state.error === "logo" && !this.state.logo}
                                    onChange={(url) => {
                                        // this.setState({ logo: url });
                                        this.state.client_settings.logo_secondary = url;
                                        this.promisedSetState({
                                            client_settings: this.state.client_settings
                                        })
                                    }}
                                />
                            </div>

                        </div>
                    </>
                }
                {
                    this.state.tab.id === 4 &&
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Custom file upload"}
                                            selected={this.state.custom_file_upload ? this.state.custom_file_upload : { id: 0, name: "Disabled", value: "disabled" }}
                                            options={[
                                                { id: 0, name: "Disabled", value: "disabled" },
                                                { id: 1, name: "Enabled", value: "enabled" }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    custom_file_upload: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Amount limit"}
                                            disabled={!(this.state.custom_file_upload && this.state.custom_file_upload.value == "enabled")}
                                            selected={this.state.custom_file_limit ? this.state.custom_file_limit : { id: 0, name: "Select limit" }}
                                            options={[
                                                { id: 1, name: "Unlimited", value: "unlimited" },
                                                { id: 11, name: "2", value: 2 },
                                                { id: 2, name: "10", value: 10 },
                                                { id: 3, name: "30", value: 30 },
                                                { id: 4, name: "40", value: 40 },
                                                { id: 5, name: "50", value: 50 },
                                                { id: 6, name: "60", value: 60 },
                                                { id: 7, name: "70", value: 70 },
                                                { id: 8, name: "80", value: 80 },
                                                { id: 9, name: "90", value: 90 },
                                                { id: 10, name: "100", value: 100 }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    custom_file_limit: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            disabled={!(this.state.custom_file_upload && this.state.custom_file_upload.value == "enabled")}
                                            label={"Max file size (MB)"}
                                            value={this.state.custom_file_size}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    custom_file_size: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                }
                {
                    this.state.tab.id === 5 &&
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"CRM"}
                                            selected={this.state.crm_enabled ? this.state.crm_enabled : { id: 0, name: "Disabled", value: "disabled" }}
                                            options={[
                                                { id: 0, name: "Disabled", value: "disabled" },
                                                { id: 1, name: "Enabled", value: "enabled" }
                                            ]}
                                            onChange={(value) => {
                                                this.setState({
                                                    custom_file_upload: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-8">
                                        <InputTailwind
                                            disabled={!(this.state.crm_enabled && this.state.crm_enabled.value == "enabled")}
                                            label={"CRM ID"}
                                            value={this.state.crm_id}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    crm_id: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                }

                {
                    this.state.tab.id === 6 &&
                    <>
                        <div className='grid grid-cols-12 gap-4'>
                            <div className="col-span-12">
                                <DropdownTailwind
                                    disabled={!this.state.agency.id}
                                    locked={!this.state.agency.id}
                                    label={"Scheduler status"}
                                    selected={this.state.scheduler ? this.state.scheduler : {}}
                                    options={[
                                        { name: "Active", value: true },
                                        { name: "Disabled", value: false }
                                    ]}
                                    onChange={(value) => {
                                        this.setState({
                                            scheduler: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`flex w-full my-4 ${this.state.scheduler.value ? "opacity-100" : "opacity-50"}`}>
                            <div className='flex w-full transform transition-all duration-500 ease-in-out'>
                                <div className='flex flex-col flex-1'>
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget split</div>
                                    <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.state.budgetSplit && this.state.budgetSplit.value ? "Active" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                disabled={!this.state.scheduler.value}
                                                value={this.state.budgetSplit && ('value' in this.state.budgetSplit) ? this.state.budgetSplit.value : false}
                                                onSwitch={async () => {
                                                    if (!this.state.budgetSplit) {
                                                        this.state.budgetSplit = {
                                                            value: true,
                                                            google_budget: 50,
                                                            bing_budget: 50,
                                                            budget: 0
                                                        };
                                                    } else {
                                                        this.state.budgetSplit.value = !this.state.budgetSplit.value;
                                                    }
                                                    await this.promisedSetState({
                                                        budgetSplit: this.state.budgetSplit
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.budgetSplit && this.state.budgetSplit.value ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                    <InputTailwind
                                        disabled={!this.state.scheduler.value}
                                        channel={"google"}
                                        full={true}
                                        label={"Google budget"}
                                        value={this.state.budgetSplit && this.state.budgetSplit.google_budget ? +this.state.budgetSplit.google_budget : 0}
                                        leftSection={true}
                                        leftSectionLabel={"%"}
                                        onChange={async (value) => {
                                            if (+value >= 0 && +value <= 100) {
                                                //if (+value + +this.state.agency.budgetSplit.bing_budget > 100) {
                                                this.state.budgetSplit.bing_budget = 100 - +value;
                                                //}
                                                this.state.budgetSplit.google_budget = +value;
                                                await this.promisedSetState({
                                                    budgetSplit: this.state.budgetSplit
                                                })
                                            }
                                        }}
                                    />
                                </div>

                                <div className={'flex  transform transition-all duration-500 ease-in-out' + (this.state.budgetSplit && this.state.budgetSplit.value ? " flex-1" : " w-0 overflow-hidden")}>
                                    <InputTailwind
                                        disabled={!this.state.scheduler.value}
                                        channel={"bing"}
                                        full={true}
                                        label={"Bing budget"}
                                        leftSection={true}
                                        leftSectionLabel={"%"}
                                        value={this.state.budgetSplit && this.state.budgetSplit.bing_budget ? +this.state.budgetSplit.bing_budget : 0}
                                        onChange={async (value) => {
                                            if (+value >= 0 && +value <= 100) {
                                                //if (+value + +this.state.agency.budgetSplit.google_budget > 100) {
                                                this.state.budgetSplit.google_budget = 100 - +value;
                                                //}
                                                this.state.budgetSplit.bing_budget = +value;
                                                await this.promisedSetState({
                                                    budgetSplit: this.state.budgetSplit
                                                })
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}



export default CreateAgency;