class reportRegister {

    static report = null;

    static set(value){
        reportRegister.report = value;
        try {
            sessionStorage.setItem("report", JSON.stringify(reportRegister.report));
        }catch (e) {}
    }

    static get(){
        if(reportRegister.report){
            return reportRegister.report
        }else{
            try {
                if(sessionStorage.getItem("report")){
                    let report = JSON.parse(sessionStorage.getItem("report"));
                    return report;
                }else{
                    return null;
                }
            }catch (e) {
                return null;
            }
        }
    }

    static remove(){
        reportRegister.report = null;
        try {
            sessionStorage.removeItem("report");
        }catch (e) {}
    }

}

export {reportRegister}