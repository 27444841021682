import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";

class UserRequestsAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            loading: true
        };
    };

    componentWillMount() {
        this.init.campaigns();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {
        campaigns: () => {
            this.setState({
                requests: []
            }, () => {
                calls.getRequest().then((response) => {
                    this.setState({
                        requests: response.data,
                        loading: false
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        }
    };

    functions = {};

    renders = {};

    render() {
        return (
            <div className="Profile">
                <div className="Profile-Inner">
                    <div className="background">
                        {
                            !this.state.loading &&
                            <div className="background-Inner">
                                {
                                    this.state.requests.map((item) => {
                                        return (
                                            <div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.loading &&
                            <div className="background-Loader">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={30}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            !this.state.loading && this.state.requests.length < 1 &&
                            <div className="background-Loader">
                                <div className="background-LoaderText">
                                    You dont have any <b>requests</b>
                                </div>
                                {
                                    <div onClick={() => this.props.history.push("/user/requests/new")} className="background-LoaderButton">
                                        Create new
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default UserRequestsAll;
