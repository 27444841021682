import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class AdvertisingCreativesSearchPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: {},
            item: {},
            url: "",
            selected: "",
            language: null,
            selected_keyword: this.props.selectedKeyword
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            item: nextProps.item,
            url: nextProps.url,
            selected: nextProps.selected,
            no_container: nextProps.noContainer,
            language: nextProps.language,
            selected_keyword: nextProps.selectedKeyword
        });
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            item: this.props.item,
            url: this.props.url,
            no_container: this.props.noContainer,
            selected: this.props.selected,
            language: this.props.language,
            selected_keyword: this.props.selectedKeyword
        });
    }

    functions = {};

    init = {};

    renders = {
        site: () => {
            try{
                return this.state.url
            }catch (e) {
                return "wwww.example.com"
            }
        },
        variations: () => {
            try {

                let string = "";

                if(this.state.item.headline_first_template){
                    if(this.state.item.headline_first_template.type === 'no_option'){
                        string = string + "Headline first | ";
                    }else if(this.state.item.headline_first_template.type === 'headline'){
                        if(this.state.creatives.languages[this.state.language].headline.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'headline_second'){
                        if(this.state.creatives.languages[this.state.language].headline_second.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline_second.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'headline_third'){
                        if(this.state.creatives.languages[this.state.language].headline_third.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline_third.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string + "<b>&#123;keyword&#125;</b>" + " | ";
                        }else{
                            string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"") + " | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'custom'){
                        //console.log(this.state.language);
                        if(this.state.item.custom){
                            string = string + this.state.item.custom[this.state.language].headline + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else{
                        string = string + "Headline first | ";
                    }
                }else{
                    string = string + "Headline first | ";
                }

                if(this.state.item.headline_second_template){
                    if(this.state.item.headline_second_template.type === 'no_option'){
                        string = string + "Headline second";
                    }else if(this.state.item.headline_second_template.type === 'headline'){
                        if(this.state.creatives.languages[this.state.language].headline.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'headline_second'){
                        if(this.state.creatives.languages[this.state.language].headline_second.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline_second.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'headline_third'){
                        if(this.state.creatives.languages[this.state.language].headline_third.trim() !== ""){
                            string = string + this.state.creatives.languages[this.state.language].headline_third.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string + "<b>&#123;keyword&#125;</b>";
                        }else{
                            string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                        }
                    }else if(this.state.item.headline_second_template.type === 'custom'){
                        if(this.state.item.custom){
                            string = string + this.state.item.custom[this.state.language].headline_second;
                        }else{
                            string = string + "Headline second";
                        }
                    }else{
                        string = string + "Headline second";
                    }
                }else{
                    string = string + "Headline second";
                }

                if(this.state.item.headline_third_template){
                    if(this.state.item.headline_third_template.type === 'no_option'){
                        string = string + "";
                    }else if(this.state.item.headline_third_template.type === 'headline'){
                        if(this.state.creatives.languages[this.state.language].headline.trim() !== ""){
                            string = string +  " | " + this.state.creatives.languages[this.state.language].headline.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'headline_second'){
                        if(this.state.creatives.languages[this.state.language].headline_second.trim() !== ""){
                            string = string +  " | " + this.state.creatives.languages[this.state.language].headline_second.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'headline_third'){
                        if(this.state.creatives.languages[this.state.language].headline_third.trim() !== ""){
                            string = string +  " | " + this.state.creatives.languages[this.state.language].headline_third.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string +  " | " + "<b>&#123;keyword&#125;</b>";
                        }else{
                            string = string +  " | " + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                        }
                    }else if(this.state.item.headline_third_template.type === 'custom'){
                        if(this.state.item.custom){
                            string = string +  " | " + this.state.item.custom[this.state.language].headline_third;
                        }else{
                            string = string +  " | " + "Headline third";
                        }
                    }else{
                        string = string +  " | " + "Headline third";
                    }
                }else{
                    string = string +  " | " + "Headline third";
                }

                /*
                if(this.state.item.languages[this.state.language].headline_keyword){
                    if(!this.state.selected_keyword){
                        string = string + "<b>keyword insert</b>" + " | ";
                    }else{
                        string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"") + " | ";
                    }
                }else{
                    if(this.state.item.languages[this.state.language].headline && this.state.item.languages[this.state.language].headline !== ""){
                        string = string + this.state.item.languages[this.state.language].headline + ' | '
                    }else{
                        string = string + "Headline first | ";
                    }
                }
                */

                /*
                if(this.state.item.languages[this.state.language].headline_second_keyword){
                    if(!this.state.selected_keyword){
                        string = string + "<b>keyword insert</b>";
                    }else{
                        string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                    }
                }else{
                    if(this.state.item.languages[this.state.language].headline_second && this.state.item.languages[this.state.language].headline_second !== ""){
                        string = string + this.state.item.languages[this.state.language].headline_second;
                    }else{
                        string = string + "Headline second";
                    }
                }

                if(this.state.item.languages[this.state.language].headline_third_keyword){
                    if(!this.state.selected_keyword){
                        string = string +  " | " + "<b>keyword insert</b>";
                    }else{
                        string = string +  " | " + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + "<b>"+this.state.selected_keyword.name+"</b>" + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                    }
                }else{
                    if(this.state.item.languages[this.state.language].headline_third && this.state.item.languages[this.state.language].headline_third !== ""){
                        string = string  + ' | ' + this.state.item.languages[this.state.language].headline_third
                    }
                }
                */

                return this.renders.removeEmoji(string);
            }catch (e) {
                //console.log(e);
                return "";
            }
        },
        bodyOne: () => {
            try{
                if(this.state.creatives.languages[this.state.language].body && this.state.creatives.languages[this.state.language].body !== ''){
                    return this.renders.removeEmoji(this.state.creatives.languages[this.state.language].body);
                }else{
                    return "Body text ...";
                }
            }catch (e) {
                return "";
            }
        },
        bodyTwo: () => {
            try{
                return this.state.creatives.languages[this.state.language].body_second;
            }catch (e) {
                return "";
            }
        },
        siteLinks: () => {
            try{
                if(this.state.creatives.languages[this.state.language].site_links.length > 0){
                    let items = this.state.creatives.languages[this.state.language].site_links.map((item) => {return item.name});
                    return items.join(' ‧ ');
                }else{
                    return null;
                }
            }catch (e) {
                //console.log(e);
                return null;
            }
        },
        markdown: (input) => {
            return { __html: input }
        },
        removeEmoji: (input) => {
            try{
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            }catch (e) {
                return input
            }
        }
    };

    render() {
        return (
            <div className={cx("Google-PreviewContainer")}>
                <div style={{marginTop: '0px'}} className={
                    cx("Google-PreviewBasicContainerItem",
                        {
                            ["Google-PreviewBasicContainerItemNo"]: this.state.no_container,
                        })
                }>
                    <div className="Google-PreviewBasicContainerItemInner">
                        <div className="Google-PreviewBasicContainerItemInnerTop">
                            <div className="Google-PreviewBasicContainerItemInnerTopAd">
                                Ad
                            </div>
                            <div className="Google-PreviewBasicContainerItemInnerTopAdDot">&bull;</div>
                            <div className="Google-PreviewBasicContainerItemInnerTopUrl">
                                {this.renders.site()}
                            </div>
                            {
                                this.state.creatives.show_call &&
                                <div className="Google-PreviewBasicContainerItemInnerCallExtension">
                                    {this.state.creatives.phone_number}
                                </div>
                            }
                        </div>
                        <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.variations())} className="Google-PreviewBasicContainerItemInnerTitle"/>
                        <div className="Google-PreviewBasicContainerItemInnerBody">
                            {this.renders.bodyOne()}{" "}{this.renders.bodyTwo()}
                        </div>
                        {
                            this.renders.siteLinks() &&
                            <div className="Google-PreviewBasicContainerItemInnerSitelinks">
                                {this.renders.siteLinks()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesSearchPreview;
