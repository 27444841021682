import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import currencies from '../../assets/json/currencies';
import Dropdown from "../../components/dropdown";
import UploadImage from "../../components/image-upload";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";

class UserUsersNewAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            error: null,
            error_message: ""
        };
    };

    componentWillMount() {}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {};

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.createUser({
                    name: this.state.name,
                    email: this.state.email,
                    type: 'agent',
                    agency: userRegister.get().agency
                }).then((response) => {
                    this.props.history.push('/user/users/all');
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error: error.body.name,
                        error_message: error.body.message
                    })
                });
            });
        }
    };

    renders = {

    };

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render(){
        return (
            <div className="mt-8 bg-white p-4 rounded-md">
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <div className="flex flex-1">
                    <div>
                        <h3 className="mb-0 font-bold">
                            New user
                        </h3>
                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                            Avg. number of search per month in parantheses
                        </p>
                    </div>
                </div>
                <div className="rounded-md bg-gray-100 p-4 mt-4">
                    <div className="flex flex-1 flex-row">
                        <div className="flex flex-1 mr-2">
                            <Input
                                title={'Name'}
                                value={this.state.name}
                                error={this.state.error === "name" && this.state.name.length < 1}
                                type={"text"}
                                size={"full"}
                                maxLength={40}
                                onChange={(value) => this.setState({name: value.target.value}, () => {

                                })}
                            />
                        </div>
                        <div className="flex flex-1 ml-2">
                            <Input
                                title={'Email'}
                                value={this.state.email}
                                error={this.state.error === "email"}
                                placeholder={""}
                                type={"text"}
                                size={"full"}
                                onChange={(value) => this.setState({email: value.target.value}, () => {

                                })}
                            />
                        </div>
                    </div>
                    <div className="flex flex-1 mt-4">
                        <div onClick={() => {
                            this.functions.create();
                        }} className="ButtonFull Button">
                            {
                                !this.state.loading &&
                                <div>Create</div>
                            }
                            {
                                this.state.loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#ffffff'}
                                    loading={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserUsersNewAgent;
