var EventEmitter = require('eventemitter3');

class eventRegister {

    static eventEmitter = new EventEmitter();

    static on(eventName, listener) {
        eventRegister.eventEmitter.on(eventName, listener);
    }

    static removeEventListener(eventName, listener) {
        eventRegister.eventEmitter.removeListener(eventName, listener);
    }

    static emit(event, payload, error = false) {
        eventRegister.eventEmitter.emit(event, payload, error);
    }

    static getEventEmitter() {
        return eventRegister.eventEmitter;
    }

}

export {eventRegister}