import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import Input from "../input";
import ClientFacebook from "../client-facebook";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import UploadImage from "../image-upload";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";
import { userRegister } from "../../services/userRegister";

class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            open: false,
            loading: false,
            warning_modal: false,
            error_modal: false,
            error_message: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item
        })
    }

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false
            }, () => {
                calls.removeUser(this.state.item._id).then((response) => {
                    this.setState({
                        loading: false,
                        open: false
                    }, () => {
                        this.props.onRemove(this.state.item._id);
                    });
                }, (error) => {
                    this.setState({
                        error_modal: true,
                        loading: false,
                        error_message: error.body.message
                    });
                });
            })
        },
        open: () => {
            if (window.location.hostname === 'localhost') {
                window.open("http://localhost:5001/login/" + this.state.item._id);
            } else if (window.location.hostname === 'app.adcredo.io') {
                window.open("https://app.adcredo.io/login/" + this.state.item._id);
            } else if (window.location.hostname === 'dev.adcredo.io') {
                window.open("https://dev.adcredo.io/login/" + this.state.item._id);
            }
        }
    };

    renders = {};

    render() {
        return (
            <div className="Client">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove user"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({ warning_modal: false });
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <div className="Client-InnerContainerInner">
                    <div onClick={() => {
                        this.setState({ open: !this.state.open })
                    }} className="Client-InnerContainerInnerTop">
                        {
                            userRegister.get().type === 'agent' &&
                            <div style={{ marginRight: '20px' }} className="Client-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Email
                                </div>
                                <div className="Client-InnerContainerInnerTopTitleValue">
                                    {this.state.item.email}
                                </div>
                            </div>
                        }
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Name
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.name}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Status
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.status}
                            </div>
                        </div>
                        {
                            userRegister.get().type === 'admin' &&
                            <div className="Client-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Type
                                </div>
                                <div className="Client-InnerContainerInnerTopTitleValue">
                                    {this.state.item.type}
                                </div>
                            </div>
                        }
                        {
                            userRegister.get().type === 'admin' &&
                            <div className="Client-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Agency
                                </div>
                                <div className="Client-InnerContainerInnerTopTitleValue">
                                    {this.state.item.agency ? this.state.item.agencyName : "-"}
                                </div>
                            </div>
                        }
                        {
                            userRegister.get().type === 'admin' &&
                            <div className="Client-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Client
                                </div>
                                <div className="Client-InnerContainerInnerTopTitleValue">
                                    {this.state.item.client ? this.state.item.clientName : "-"}
                                </div>
                            </div>
                        }
                        <div className="Client-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("Client-InnerContainerInnerTopArrow",
                                    {
                                        ["Client-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={20} icon="plus" />
                            <div className="Client-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("Client-InnerContainerInnerTopMinus",
                                        {
                                            ["Client-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="minus" />
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div style={{ textAlign: 'left', padding: '15px', paddingTop: '0px' }} className="Section">
                            Lorem ipsum dolor sit amet, no sonet eligendi disputationi nec, inani nonumes similique in
                            usu. Vim ad alii officiis, no eos wisi impetus sapientem. No eam magna movet solet. No elitr
                            molestiae est.
                        </div>
                        <div style={{ padding: '15px', paddingTop: '0px' }} className="Section">
                            <Input
                                title={'Name'}
                                value={this.state.item.name}
                                disabled={true}
                                size={'half'}
                                type={"text"}
                            />
                            {
                                this.state.item.type === 'admin' &&
                                <Input
                                    title={'Type'}
                                    value={this.state.item.type}
                                    disabled={true}
                                    size={'half'}
                                    type={"text"}
                                />
                            }
                            {
                                this.state.item.type !== 'admin' &&
                                <Input
                                    title={'Status'}
                                    value={this.state.item.status}
                                    disabled={true}
                                    size={'half'}
                                    type={"text"}
                                />
                            }
                        </div>
                        <div style={{ padding: '15px', paddingTop: '0px' }} className="Section">
                            <Input
                                title={'Email'}
                                value={this.state.item.email}
                                size={"full"}
                                disabled={true}
                                type={"text"}
                            />
                        </div>
                        {
                            this.state.item.type === 'admin' &&
                            <div style={{ padding: '15px', paddingTop: '0px' }} className="Section">
                                <Input
                                    title={'Agency'}
                                    value={this.state.item.agencyName ? this.state.item.agencyName : '-'}
                                    size={"half"}
                                    disabled={true}
                                    type={"text"}
                                />
                                <Input
                                    title={'Client'}
                                    value={this.state.item.clientName ? this.state.item.clientName : "-"}
                                    size={"half"}
                                    placeholder={""}
                                    disabled={true}
                                    type={"text"}
                                />
                            </div>
                        }
                        {
                            this.state.item.type !== 'admin' && userRegister.get().id !== this.state.item._id &&
                            <div style={{ padding: '15px', paddingTop: '15px' }} className="Section">
                                {
                                    userRegister.get().type === 'admin' &&
                                    <div onClick={() => {
                                        this.functions.open();
                                    }} className="Button ButtonNeutral">
                                        Open
                                    </div>
                                }
                                {
                                    userRegister.get().id !== this.state.item._id &&
                                    <div onClick={() => this.setState({
                                        warning_modal: true
                                    })} className={
                                        cx("Button", "ButtonRed",
                                            {
                                                ["ButtonFull"]: userRegister.get().type !== 'admin',
                                            })
                                    }>
                                        Remove
                                    </div>
                                }
                            </div>
                        }
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default User;
