import React, {Component, Fragment} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import OptimizeActive from "../../components/optimize-active";
import {calls} from "./calls";
import OptimizeDisabled from "../../components/optimize-disabled";
import OptimizeSocial from "../../components/optimize-social";
import OptimizeSearch from "../../components/optimize-search";

import DropDown from "../../modules/dropdown";
import CampaignsList from "../../modules/campaignslist";
import SearchtermList from "../../modules/searchtermlist";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchKeywordsAsset from "../../components/optimize-search-keywords-asset";
import OptimizeSearchKeywordsAdd from "../../components/optimize-search-keywords-add";
import NegativeKeywordList from "../../modules/negativekeywordlist";
import Input from "../../components/input";
import ReactTooltip from "react-tooltip";
import CSVReader from "react-csv-reader";
import Loader from "../../components/loader";
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';
import cx from "classnames";

class UserFinance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            client: {},
            agencies: [],
            agency: {},
            campaigns: [],
            start_date: null,
            end_date: null,
            loading_agencies: true,
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.functions.agencies();
    }

    functions = {
        agencies: () => {
            this.setState({
                    loading_agencies: true
                }, () => {
                    calls.getAgencies().then((response) => {
                            this.setState({
                                agencies: response.data,
                                loading_agencies: false,
                            });
                        }, (error) => {
                            this.setState({
                                loading_agencies: false,
                            });
                        }
                    );
                }
            );
        },
        clients: () => {
            this.setState({
                    loading_clients: true
                }, () => {
                    calls.getClients(this.state.agency._id).then((response) => {
                            this.setState({
                                clients: response.data,
                                loading_clients: false,
                            });
                        }, (error) => {
                            this.setState({
                                loading_clients: false,
                            });
                        }
                    );
                }
            );
        },
        campaigns: () => {
            this.setState({
                    loading_campaigns: true
                }, () => {
                    calls.getCampaignsByClient(this.state.client.id).then((response) => {
                            this.setState({
                                campaigns: this.renders.campaigns(response.data),
                                loading_campaigns: false,
                            });
                        }, (error) => {
                            this.setState({
                                loading_campaigns: false,
                            });
                        }
                    );
                }
            );
        },
        campaign: (item) => {
            calls.getCampaign({
                id: item.internal_id,
                external: item.external_id,
                start_date: moment(this.state.start_date).format("YYYY-MM-DD"),
                end_date: moment(this.state.end_date).format("YYYY-MM-DD"),
            }).then((response) => {
                    let information = {};
                    for (let campaign in response.data.channels[item.channel].campaigns) {
                        information = response.data.channels[item.channel].campaigns[campaign];
                    }
                    item = {...item, ...information};
                    item.is_loading = false;
                    //console.log(item);
                    this.setState({
                        campaigns: this.state.campaigns.map((campaign) => {
                            if (campaign.temp_id === item.temp_id) {
                                campaign = item;
                                campaign.is_loading = false;
                            }
                            return campaign;
                        })
                    });
                }, (error) => {
                    item.is_loading = false;
                    this.setState({
                        campaigns: this.state.campaigns.map((campaign) => {
                            if (campaign.temp_id === item.temp_id) {
                                campaign = item;
                            }
                            return campaign;
                        })
                    });
                }
            );
        }
    };

    renders = {
        campaigns: (items) => {
            let new_array = [];
            if (Array.isArray(items)) {
                items.map((item) => {
                    for (let channel in item.external_campaigns) {
                        if (Array.isArray(item.external_campaigns[channel])) {
                            item.external_campaigns[channel].map((campaign) => {
                                let new_item = {};
                                new_item.temp_id = Math.floor((Math.random() * 9999999999) + 1);
                                new_item.internal_name = item.name;
                                new_item.internal_id = item.id;
                                new_item.channel = channel;
                                new_item.external_id = campaign.id;
                                new_item.system_fee = this.state.agency.fee;
                                new_item.is_loading = true;
                                this.functions.campaign(new_item);
                                new_array.push(new_item);
                            })
                        }
                    }
                });
            }
            ;
            return new_array;
        }
    };

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                <div className="">
                    <div className="">
                        {
                            this.state.loading_agencies && (
                                <div className="background-Loader">
                                    <BeatLoader sizeUnit={'px'} size={24} color={'#060534'} loading={true}/>
                                </div>
                            )
                        }
                        {
                            !this.state.loading_agencies &&
                            <div className="flex flex-1 flex-col">
                                <div className="flex flex-1 mb-4 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        1. Select agency
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                                {
                                    typeof this.state.agency == "object" && !this.state.agency.name &&
                                    <div className="flex flex-1">
                                        <AgencyList
                                            agencies={this.state.agencies}
                                            onSelect={(agency) => {
                                                this.setState({
                                                    agency: agency,
                                                    client: {},
                                                    clients: [],
                                                    campaigns: [],
                                                    campaign: {}
                                                }, () => {
                                                    this.functions.clients();
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    typeof this.state.agency == "object" && this.state.agency.name &&
                                    <DropDown
                                        label={this.state.agency.name ? this.state.agency.name : 'Click here'}
                                        listitems={[]}
                                        showreset={!this.state.loading_agencies && this.state.agency.name}
                                        callfunc={() => {

                                        }}
                                        disabled={this.state.agency.name}
                                        res={() => {
                                            this.setState(
                                                {
                                                    agency: {},
                                                    client: {},
                                                    clients: [],
                                                    campaigns: [],
                                                    dates_selected: false
                                                }
                                            );
                                        }}
                                        emptybool={false}
                                    />
                                }
                                {
                                    typeof this.state.agency == "object" && this.state.agency.name &&
                                    <div className="flex flex-1 flex-col mb-4">
                                        <h3 className="mb-0 font-bold">
                                            2. Select client
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Avg. number of search per month in parantheses
                                        </p>
                                    </div>
                                }
                                {
                                    typeof this.state.agency == "object" && this.state.agency.name && typeof this.state.client == "object" && !this.state.client.name && !this.state.loading_clients &&
                                    <div className="flex flex-1">
                                        <ClientList
                                            clients={this.state.clients}
                                            onSelect={(client) => {
                                                this.setState({
                                                    client: client,
                                                    campaigns: []
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.loading_clients &&
                                    <div className="h-32 w-full flex justify-center align-middle items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={20}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && !this.state.loading_clients &&
                                    <DropDown
                                        label={this.state.client.name ? this.state.client.name : 'Click here'}
                                        listitems={[]}
                                        showreset={!this.state.loading_clients && this.state.client.name}
                                        callfunc={() => {

                                        }}
                                        disabled={this.state.client.name}
                                        res={() => {
                                            this.setState(
                                                {
                                                    client: {},
                                                    campaigns: [],
                                                    dates_selected: false
                                                }
                                            );
                                        }}
                                        emptybool={false}
                                    />
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && !this.state.loading_clients &&
                                    <div className="flex flex-1 flex-col mb-4">
                                        <h3 className="mb-0 font-bold">
                                            3. Set date range
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Avg. number of search per month in parantheses
                                        </p>
                                    </div>
                                }
                                {
                                    typeof this.state.client == "object" && this.state.client.name && !this.state.loading_clients && !this.state.dates_selected &&
                                    <div className="bg-white p-4 rounded-md">
                                        <div>
                                            <Datepicker
                                                size={'full'}
                                                showTime={true}
                                                allowPast={true}
                                                border={true}
                                                startDate={this.state.start_date}
                                                endDate={this.state.end_date}
                                                onStartDate={(value) => {
                                                    this.state.start_date = value;
                                                    this.setState({
                                                        start_date: value
                                                    })
                                                }}
                                                onEndDate={(value) => {
                                                    this.state.end_date = value;
                                                    if (!this.state.start_date) {
                                                        this.state.start_date = new Date();
                                                    }
                                                    this.setState({
                                                        start_date: this.state.start_date,
                                                        end_date: this.state.end_date
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="flex mt-4">
                                            <div className="flex-1"></div>
                                            <button onClick={() => {
                                                if (this.state.start_date && this.state.end_date) {
                                                    this.setState({
                                                        dates_selected: true
                                                    }, () => {
                                                        this.functions.campaigns();
                                                    })
                                                }
                                            }}
                                                    className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                <span>Set dates</span>
                                                <FeatherIcon className="stroke-current ml-2" size={16}
                                                             icon="arrow-right-circle"/>
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.dates_selected &&
                                    <DropDown
                                        label={(moment(this.state.start_date).format('DD MMMM YYYY') + " - " + moment(this.state.end_date).format('DD MMMM YYYY'))}
                                        listitems={[]}
                                        showreset={this.state.dates_selected}
                                        callfunc={() => {

                                        }}
                                        disabled={this.state.dates_selected}
                                        res={() => {
                                            this.setState(
                                                {
                                                    dates_selected: false
                                                }
                                            );
                                        }}
                                        emptybool={false}
                                    />
                                }
                                {
                                    this.state.dates_selected &&
                                    <div className="flex flex-1 flex-col mb-4">
                                        <h3 className="mb-0 font-bold">
                                            Campaign overview
                                        </h3>
                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                            Avg. number of search per month in parantheses
                                        </p>
                                    </div>
                                }
                                {
                                    this.state.dates_selected && this.state.loading_campaigns &&
                                    <div className="h-32 w-full flex justify-center align-middle items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={20}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    this.state.dates_selected && !this.state.loading_campaigns && Array.isArray(this.state.campaigns) && this.state.campaigns.length < 1 &&
                                    <div
                                        className="bg-white rounded-md h-32 w-full flex justify-center align-middle items-center">
                                        <div className="text-sm font-bold">
                                            No campaigns available for this client
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.dates_selected && !this.state.loading_campaigns && this.state.campaigns.length > 0 &&
                                    <CampaignList
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}
                                        client={this.state.client}
                                        agency={this.state.agency}
                                        campaigns={this.state.campaigns}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

class AgencyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agencies: [],
            order: "asc",
            sort: "name"
        };
    }

    componentDidMount() {
        this.setState({
            agencies: this.props.agencies
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            agencies: nextProps.agencies
        })
    }

    render() {
        return (
            <>
                <div className="shadow-lg overflow-hidden border-b border-gray-200 rounded-lg w-full">
                    <table className="min-w-full divide-y divide-gray-200 bg-white">
                        <thead>
                        <tr>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'name',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Name <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'company',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Company info <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                <div className="flex flex-row items-center justify-end">
                                    Actions
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            this.state.agencies.sort((a, b) => {
                                if (this.state.sort) {
                                    var nameA = null;
                                    var nameB = null;
                                    try {
                                        nameA = a[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameA = a[this.state.sort];
                                    }
                                    try {
                                        nameB = b[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameB = b[this.state.sort];
                                    }
                                    if (this.state.order === 'desc') {
                                        if (nameA > nameB)
                                            return -1;
                                        if (nameA < nameB)
                                            return 1;
                                        return 0
                                    } else {
                                        if (nameA < nameB)
                                            return -1;
                                        if (nameA > nameB)
                                            return 1;
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }).map((item, i) => {
                                return (
                                    <tr className="">
                                        <td className="px-6 py-4 whitespace-no-wrap">
                                            <div className="flex items-center">
                                                <div
                                                    className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                                                    {item.logo &&
                                                    <img className="h-10 w-10 rounded-full object-contain"
                                                         src={item.logo}
                                                         alt={item.company}/>
                                                    }
                                                </div>
                                                <div
                                                    className="text-sm leading-5 font-bold text-gray-900 hover:text-blue-500 hover:underline cursor-pointer ml-4"
                                                    onClick={() => {
                                                        this.props.onSelect(item);
                                                    }}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                            <div className="text-sm leading-5 text-gray-900 capitalize">
                                                {item.company}
                                            </div>
                                            <div className="text-xs leading-5 text-gray-500">
                                                {item.email}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                            <div className="flex flex-row items-end justify-end">
                                                <button onClick={() => {
                                                    this.props.onSelect(item);
                                                }}
                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                    <span>Select agency</span>
                                                    <FeatherIcon className="stroke-current ml-2" size={16}
                                                                 icon="arrow-right-circle"/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            order: "asc",
            sort: "name"
        };
    }

    componentDidMount() {
        this.setState({
            clients: this.props.clients
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            clients: nextProps.clients
        })
    }

    render() {
        return (
            <>
                <div className="shadow-lg overflow-hidden border-b border-gray-200 rounded-lg w-full">
                    <table className="min-w-full divide-y divide-gray-200 bg-white">
                        <thead>
                        <tr>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'name',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Name <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th onClick={() => {
                                this.setState({
                                    sort: 'company',
                                    order: this.state.order === 'asc' ? 'desc' : 'asc'
                                });
                            }}
                                className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'company' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                <div className="flex flex-row items-center">
                                    Company info <div className="sorting-arrows ml-2"></div>
                                </div>
                            </th>
                            <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                <div className="flex flex-row items-center justify-end">
                                    Actions
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {
                            this.state.clients.sort((a, b) => {
                                if (this.state.sort) {
                                    var nameA = null;
                                    var nameB = null;
                                    try {
                                        nameA = a[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameA = a[this.state.sort];
                                    }
                                    try {
                                        nameB = b[this.state.sort].value.toLowerCase();
                                    } catch (e) {
                                        nameB = b[this.state.sort];
                                    }
                                    if (this.state.order === 'desc') {
                                        if (nameA > nameB)
                                            return -1;
                                        if (nameA < nameB)
                                            return 1;
                                        return 0
                                    } else {
                                        if (nameA < nameB)
                                            return -1;
                                        if (nameA > nameB)
                                            return 1;
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }).map((item, i) => {
                                return (
                                    <tr className="">
                                        <td className="px-6 py-4 whitespace-no-wrap">
                                            <div className="flex items-center">
                                                <div
                                                    className="bg-gray-100 bg-opacity-50 flex-shrink-0 h-10 rounded-full w-10">
                                                    {item.logo &&
                                                    <img className="h-10 w-10 rounded-full object-contain"
                                                         src={item.logo}
                                                         alt={item.company}/>
                                                    }
                                                </div>
                                                <div
                                                    className="text-sm leading-5 font-bold text-gray-900 hover:text-blue-500 hover:underline cursor-pointer ml-4"
                                                    onClick={() => {
                                                        this.props.onSelect(item);
                                                    }}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm">
                                            <div className="text-sm leading-5 text-gray-900 capitalize">
                                                {item.company}
                                            </div>
                                            <div className="text-xs leading-5 text-gray-500">
                                                {item.email}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                            <div className="flex flex-row items-end justify-end">
                                                <button onClick={() => {
                                                    this.props.onSelect(item);
                                                }}
                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                    <span>Select client</span>
                                                    <FeatherIcon className="stroke-current ml-2" size={16}
                                                                 icon="arrow-right-circle"/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

class CampaignList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            client: {},
            agency: {},
            start_date: "",
            end_date: ""
        };
    };

    componentDidMount() {
        this.setState({
            campaigns: this.props.campaigns,
            client: this.props.client,
            agency: this.props.agency,
            start_date: this.props.start_date,
            end_date: this.props.end_date
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaigns: nextProps.campaigns,
            client: nextProps.client,
            agency: nextProps.agency,
            start_date: nextProps.start_date,
            end_date: nextProps.end_date
        })
    }

    functions = {};

    renders = {
        data: (item) => {
            let data = {};
            if (!Array.isArray(item.insights)) {
                let array = [];
                item.insights = array.push(item.insights);
            }
            if (Array.isArray(item.insights)) {
                item.insights.map((inner_item) => {
                    if (item.channel === "google") {
                        if (!data.spend) {
                            data.spend = 0;
                        }
                        data.spend += (+inner_item.cost_micros * 0.000001);
                    } else if (item.channel === "facebook") {
                        if (!data.spend) {
                            data.spend = 0;
                        }
                        data.spend += +inner_item.spend;
                    }
                });
            }
            item = {...item, ...data};

            //CALCUALTE COMMISSION
            item.system_commission = ((+item.system_fee / 100) * +item.spend);
            item.system_commission = this.renders.number(+item.system_commission);

            item.spend = this.renders.number(+item.spend);

            return item;
        },
        items: () => {
            try {
                return this.state.campaigns.map((item) => {
                    item = this.renders.data(item);
                    return item;
                });
            } catch (e) {
                return [];
            }
        },
        number: (item) => {
            try {
                if (!item) {
                    return "";
                } else {
                    if (this.renders.isNumeric(item)) {
                        return Math.round((item + Number.EPSILON) * 100) / 100;
                    } else {
                        return item;
                    }
                }
            } catch (e) {
                return item;
            }
        },
        isNumeric: (str) => {
            if (typeof str === "number") {
                return true;
            } else {
                return !isNaN(str) && !isNaN(parseFloat(str))
            }
            ;
        }
    };

    render() {
        return (
            <>
                {
                    !this.state.loading &&
                    <div className="flex flex-1 justify-end mb-2">
                        <button
                            className="py-2 pt-0 pl-2 text-xs text-purple-500 hover:underline inline-flex flex-row items-center relative">
                            <FeatherIcon className="stroke-current mr-2" size={15} icon="download"/>
                            <span>Export (.csv)</span>
                            <CsvDownload
                                data={this.state.campaigns.map((item) => {
                                    item = this.renders.data(item);
                                    return item;
                                }).map((item) => {
                                    return {
                                        "Agency name": this.state.agency.name,
                                        "Client name": this.state.client.name,
                                        "Internal name": item.internal_name,
                                        "Internal ID": item.internal_id,
                                        "Platform name": item.channel,
                                        "Platform ID": item.external_id,
                                        "System fee": item.system_fee,
                                        "Start date": moment(this.state.start_date).format("YYYY-MM-DD"),
                                        "End date": moment(this.state.end_date).format("YYYY-MM-DD"),
                                        "Spend": item.spend,
                                        "System commission": item.system_commission,
                                    };
                                })}
                                filename={this.state.agency.name + " ( " + this.state.client.name + " )" + ".csv"}
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    top: "0",
                                    bottom: "0",
                                    opacity: "0",
                                    backgroundColor: 'red',
                                    zindex: '10',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                }}
                            />
                        </button>
                    </div>
                }
                <>
                    {
                        this.state.campaigns.length > 0 &&
                        <div className="shadow-lg overflow-scroll border-b border-gray-200 sm:rounded-lg w-full">
                            <table className="min-w-full divide-y divide-gray-200 bg-white table-fixed"
                                   id="reportstable">
                                <thead>
                                <tr>
                                    <th className={"campaign-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'index' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="typecol" className="draggable flex flex-row items-center px-6 py-3">
                                            Index <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'internal_name',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={"campaign-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'internal_name' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="typecol" className="draggable flex flex-row items-center px-6 py-3">
                                            Order name <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'external_id',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={"campaign-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'external_id' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="typecol" className="draggable flex flex-row items-center px-6 py-3">
                                            External ID <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'name',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={"campaign-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'name' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="typecol" className="draggable flex flex-row items-center px-6 py-3">
                                            External name <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'channel',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={(this.props.type) + "-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'name' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="namecol" className="draggable px-6 py-3 flex flex-row items-center">
                                            Channel <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'spend',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={(this.props.type) + "-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'spend' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="namecol" className="draggable px-6 py-3 flex flex-row items-center">
                                            Spend <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'system_fee',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={(this.props.type) + "-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'system_fee' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="namecol" className="draggable px-6 py-3 flex flex-row items-center">
                                            System fee in percentage <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'system_commission',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        });
                                    }}
                                        className={(this.props.type) + "-name--label cursor-pointer transition-all duration-200 hover:bg-gray-100 text-left text-xs leading-4 font-medium uppercase " + (this.state.sort === 'system_commission' ? 'text-gray-700 bg-purple-100 bg-opacity-25 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div id="namecol" className="draggable px-6 py-3 flex flex-row items-center">
                                            System commission
                                            <div className="sorting-arrows ml-2"></div>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 relative">
                                {this.state.campaigns.map((item) => {
                                    item = this.renders.data(item);
                                    return item;
                                }).sort((a, b) => {
                                    if (this.state.sort) {
                                        var nameA = null;
                                        var nameB = null;
                                        try {
                                            nameA = a[this.state.sort].value.toLowerCase();
                                        } catch (e) {
                                            nameA = a[this.state.sort];
                                        }
                                        try {
                                            nameB = b[this.state.sort].value.toLowerCase();
                                        } catch (e) {
                                            nameB = b[this.state.sort];
                                        }
                                        if (this.state.order === 'desc') {
                                            if (nameA > nameB)
                                                return -1;
                                            if (nameA < nameB)
                                                return 1;
                                            return 0
                                        } else {
                                            if (nameA < nameB)
                                                return -1;
                                            if (nameA > nameB)
                                                return 1;
                                            return 0
                                        }
                                    } else {
                                        return 1
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            {
                                                <tr className={`sortabletablerow ${item.is_loading ? 'opacity-50 bg-purple-100' : ''}`}>
                                                    <td data-colind="0"
                                                        className={"campaign-name--block px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'index' ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center relative px-4">
                                                            {
                                                                item.is_loading &&
                                                                <span
                                                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                                                    <BeatLoader sizeUnit={'px'} size={12}
                                                                                color={'#060534'} loading={true}/>
                                                                </span>
                                                            }
                                                            {
                                                                !item.is_loading &&
                                                                <div
                                                                    className={"capitalize text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'index' ? 'font-bold' : '')}>
                                                                    {i}
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td data-colind="0"
                                                        className={"campaign-name--block px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'internal_name' ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center relative">
                                                            <div
                                                                className={"capitalize text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'internal_name' ? 'font-bold' : '')}>
                                                                {item.internal_name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind="1"
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'external_id' ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'external_id' ? 'font-bold' : '')}>
                                                                {item.external_id}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind="1"
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'external_id' ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'external_id' ? 'font-bold' : '')}>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind="1"
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === 'channel' ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'channel' ? 'font-bold' : '')}>
                                                                {item.channel}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind={"2"}
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === "spend" ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === "spend" ? 'font-bold' : '')}>
                                                                {item.spend}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind={"2"}
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === "system_fee" ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === "system_fee" ? 'font-bold' : '')}>
                                                                {item.system_fee}%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-colind={"2"}
                                                        className={"px-6 py-4 whitespace-no-wrap max-w-xs " + (this.state.sort === "system_commission" ? 'bg-purple-100 bg-opacity-25' : '')}>
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === "system_commission" ? 'font-bold' : '')}>
                                                                {item.system_commission}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            </>
        )
    }

}

export default UserFinance;