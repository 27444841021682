import React, { Component, Fragment } from 'react';
import './style.css';

import { BeatLoader } from "react-spinners";
import Campaign from "../../../components/campaign";
import { calls } from "./calls";
import { userRegister } from "../../../services/userRegister";
import FeatherIcon from 'feather-icons-react';
import Dropdown from "../../../components/dropdown";
import callToAction from "../../../assets/json/call_to_action";
import Input from "../../../components/input";
import Datepicker from "../../../components/datepicker";
import cx from "classnames";
import CampaignDonuts2 from "../../../components/campaign-donuts-2";
import { SlideDown } from "react-slidedown";
import Switch from "react-ios-switch";
import IconFacebook from "../../../components/icons/facebook";
import IconInstagram from "../../../components/icons/instagram";
import IconLinkedin from "../../../components/icons/linkedin";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import TextArea from "../../../components/text-area";
import AdvertisingCreativesFacebookImage from "../../../components/advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookCarousel from "../../../components/advertising-creatives-facebook-carousel";
import AdvertisingCreativesFacebookVideo from "../../../components/advertising-creatives-facebook-video";
import AdvertisingCreativesVertical from "../../../components/advertising-creatives-vertical";
import AdvertisingCreativesInstagramImage from "../../../components/advertising-creatives-instagram-image";
import AdvertisingCreativesInstagramVideo from "../../../components/advertising-creatives-instagram-video";
import AdvertisingCreativesInstagramCarousel from "../../../components/advertising-creatives-instagram-carousel";
import { campaignRegister } from "../../../services/campaignRegister";
import Loader from "../../../components/loader";

var moment = require('moment');

class UserCampaignsItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: "",
            loading: true,
            view: 'overview',
            sort: "start_date",
            order: "asc",
            tab: 0,
            submenu: 0,
            campaignDataErrors: {
                errorsExist: false,
                errors: [],
            },
            campaign: {},
            adgroup: {},
            ad: {},
            campaign_donut: {},
            adgroup_donut: {},
            ad_donut: {}
        };
    };

    componentWillMount() {
        this.functions.campaigns(this.props.match.params.id);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {
        campaigns: (id) => {
            let self = this;
            this.setState({
                loading: true
            }, () => {
                calls.getCampaign({ id: id }).then((response) => {
                    let status = "";
                    let errors = [];
                    let data = {};
                    if (response.data.errors) {
                        errors = response.data.errors;
                    }
                    if (response.data) {
                        data = response.data;
                        delete data.channels.google_analytics;
                        delete data.errors;
                    }
                    if (response.data.status) {
                        status = response.data.status;
                    }
                    self.setState({
                        status: status,
                        errors: Array.isArray(errors) ? errors : [],
                        data: data,
                        original_data: JSON.parse(JSON.stringify(data)),
                        loading: false
                    }, () => {
                        self.functions.updateCampaigns();
                    });
                }, (error) => {
                    self.setState({
                        loading: false
                    });
                });
            })
        },
        updateCampaigns: () => {

            //SET NAME
            if (typeof this.state.data === "object" && this.state.data) {
                for (let channel in this.state.data.channels) {
                    if (typeof this.state.data.channels[channel].campaigns === "object" && this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            if (this.state.data.channels[channel].campaigns[campaign].name) {
                                this.state.data.channels[channel].campaigns[campaign].init_name = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].name));
                            }
                        }
                    }
                }
            }

            //SET STATUS
            if (typeof this.state.data === "object" && this.state.data) {
                for (let channel in this.state.data.channels) {
                    if (typeof this.state.data.channels[channel].campaigns === "object" && this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            this.state.data.channels[channel].campaigns[campaign].status = this.renders.status(channel, this.state.data.channels[channel].campaigns[campaign].status);
                        }
                    }
                }
            }


            //SET BUDGET
            if (typeof this.state.data === "object" && this.state.data) {
                for (let channel in this.state.data.channels) {
                    if (typeof this.state.data.channels[channel].campaigns === "object" && this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            if (this.state.data.channels[channel].campaigns[campaign].daily_budget) {
                                this.state.data.channels[channel].campaigns[campaign].daily_budget = this.renders.budget(channel, this.state.data.channels[channel].campaigns[campaign].daily_budget);
                                this.state.data.channels[channel].campaigns[campaign].init_daily_budget = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].daily_budget))
                            }
                        }
                    }
                }
            }

            //SET OBJECTIVE
            if (typeof this.state.data === "object" && this.state.data) {
                for (let channel in this.state.data.channels) {
                    if (typeof this.state.data.channels[channel].campaigns === "object" && this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            if (this.state.data.channels[channel].campaigns[campaign].objective) {
                                this.state.data.channels[channel].campaigns[campaign].objective = this.renders.objective(channel, this.state.data.channels[channel].campaigns[campaign].objective);
                            }
                        }
                    }
                }
            }

            //SET START DATE AND END DATE
            if (typeof this.state.data === "object" && this.state.data) {
                for (let channel in this.state.data.channels) {
                    if (typeof this.state.data.channels[channel].campaigns === "object" && this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            if (channel === "facebook" || channel === "instagram") {
                                this.state.data.channels[channel].campaigns[campaign].start_date = this.state.data.channels[channel].campaigns[campaign].start_time;
                                if (this.state.data.channels[channel].campaigns[campaign].start_date) {
                                    this.state.data.channels[channel].campaigns[campaign].init_start_date = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].start_date));
                                }
                                delete this.state.data.channels[channel].campaigns[campaign].start_time;
                                if (this.state.data.channels[channel].campaigns[campaign].stop_time) {
                                    this.state.data.channels[channel].campaigns[campaign].end_date = this.state.data.channels[channel].campaigns[campaign].stop_time;
                                    if (this.state.data.channels[channel].campaigns[campaign].end_date) {
                                        this.state.data.channels[channel].campaigns[campaign].init_end_date = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].end_date));
                                    }
                                    delete this.state.data.channels[channel].campaigns[campaign].stop_time;
                                }
                            } else if (channel === "google") {
                                if (this.state.data.channels[channel].campaigns[campaign].start_date) {
                                    this.state.data.channels[channel].campaigns[campaign].init_start_date = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].start_date));
                                }
                                if (this.state.data.channels[channel].campaigns[campaign].end_date) {
                                    this.state.data.channels[channel].campaigns[campaign].init_end_date = JSON.parse(JSON.stringify(this.state.data.channels[channel].campaigns[campaign].end_date));
                                }
                            }
                        }
                    }
                }
            }

            //SET CAMPAIGNS
            let campaigns = [];
            if (this.state.data.channels) {
                for (let channel in this.state.data.channels) {
                    if (this.state.data.channels[channel].campaigns) {
                        for (let campaign in this.state.data.channels[channel].campaigns) {
                            if (!this.state.data.channels[channel].campaigns[campaign].facebook) {
                                let object = this.state.data.channels[channel].campaigns[campaign];
                                object.id = campaign;
                                object.channel = channel;
                                campaigns.push(object);
                            }
                        }
                    }
                }
            }

            this.setState({
                data: this.state.data,
                campaigns: campaigns
            }, () => {
                this.setState({
                    campaign_donut: this.functions.donut('campaigns')
                })
            });

        },
        adgroups: () => {
            if (!this.state.loading_adgroups && (!Array.isArray(this.state.campaign.adgroups)) || (Array.isArray(this.state.campaign.adgroups)) && this.state.campaign.adgroups.length < 1) {
                this.setState({
                    loading_adgroups: true,
                }, () => {
                    calls.getAdgroups({
                        id: this.props.match.params.id,
                        campaign: this.state.campaign.id,
                        channel: this.state.campaign.channel
                    }).then((response) => {
                        if (this.state.campaign.channel) {
                            this.state.campaign.adgroups = response.data.channels[this.state.campaign.channel].adgroups.filter((item) => {
                                return item.status !== "REMOVED"
                            }).map((item) => {
                                item.internal_id = this.state.campaign.internal_id;
                                item.campaign = this.state.campaign.id;
                                item.channel = this.state.campaign.channel;
                                item.instagram = this.state.campaign.instagram;
                                item.currency = this.state.campaign.currency;
                                item.init_name = JSON.parse(JSON.stringify(item.name));
                                item.status = this.renders.status(this.state.campaign.channel, item.status);
                                return item;
                            });
                            this.state.campaigns = this.state.campaigns.map((item) => {
                                if (item.id === this.state.campaign.id) {
                                    item = this.state.campaign;
                                }
                                return item;
                            });
                            this.setState({
                                loading_adgroups: false,
                                campaign: this.state.campaign,
                                campaigns: this.state.campaigns
                            });
                        } else {
                            this.setState({
                                loading_adgroups: false
                            });
                        }
                    }, (error) => {
                        this.setState({
                            loading_adgroups: false
                        });
                    })
                })
            }
        },
        ads: () => {
            if (!this.state.loading_ads && (!Array.isArray(this.state.adgroup.ads)) || (Array.isArray(this.state.adgroup.ads)) && this.state.adgroup.ads.length < 1) {
                this.setState({
                    loading_ads: true
                }, () => {
                    calls.getAds({
                        id: this.props.match.params.id,
                        adgroup: this.state.adgroup.id,
                        campaign: this.state.adgroup.campaign,
                        channel: this.state.adgroup.channel
                    }).then((response) => {
                        if (this.state.adgroup.channel) {
                            this.state.adgroup.ads = response.data.channels[this.state.adgroup.channel].ads.map((item) => {
                                item.internal_id = this.state.campaign.internal_id;
                                item.init_name = JSON.parse(JSON.stringify(item.name));
                                item.channel = this.state.adgroup.channel;
                                item.instagram = this.state.adgroup.instagram;
                                item.currency = this.state.adgroup.currency;
                                item.status = this.renders.status(this.state.adgroup.channel, item.status);
                                return item;
                            });
                            this.state.campaign.adgroups = this.state.campaign.adgroups.map((item) => {
                                if (item.id === this.state.adgroup.id) {
                                    item = this.state.adgroup;
                                }
                                return item;
                            });
                            this.state.campaigns = this.state.campaigns.map((item) => {
                                if (item.id === this.state.campaign.id) {
                                    item = this.state.campaign;
                                }
                                return item;
                            });
                            this.setState({
                                loading_ads: false,
                                adgroup: this.state.adgroup,
                                campaigns: this.state.campaigns
                            });
                        } else {
                            this.setState({
                                loading_ads: false
                            });
                        }
                    }, (error) => {
                        this.setState({
                            loading_ads: false
                        });
                    })
                })
            }
        },
        creative: () => {
            if (!this.state.loading_ad && this.state.ad.channel !== "google") {
                this.setState({
                    loading_ad: true
                }, () => {
                    calls.getAd({
                        id: this.props.match.params.id,
                        ad: this.state.ad.creative ? this.state.ad.creative.id : "",
                        campaign: this.state.ad.internal_id,
                        channel: this.state.ad.channel
                    }).then((response) => {
                        this.state.ad.preview = response.data;
                        this.state.campaigns = this.state.campaigns.map((campaign) => {
                            if (Array.isArray(campaign.adgroups)) {
                                campaign.adgroups.map((adgroup) => {
                                    if (Array.isArray(adgroup.ads)) {
                                        adgroup.ads.map((ad) => {
                                            if (ad.id === this.state.ad.id) {
                                                ad = this.state.ad;
                                            }
                                        })
                                    }
                                })
                            }
                            return campaign;
                        });
                        this.setState({
                            loading_ad: false,
                            ad: this.state.ad,
                            campaigns: this.state.campaigns
                        });
                    }, (error) => {
                        this.setState({
                            loading_ad: false
                        });
                    })
                })
            }
        },
        remove: () => {
            if (!this.state.loading_remove) {
                this.setState({
                    loading_remove: true
                }, () => {
                    calls.removeInternalCampaign({ id: this.props.match.params.id }).then((response) => {
                        this.props.history.push("/user/campaigns/all");
                    }, (error) => {
                        this.setState({
                            loading_remove: false
                        })
                    });
                })
            }
        },
        pause: () => {
            if (!this.state.loading_pause) {
                this.setState({
                    loading_pause: true
                }, () => {
                    calls.updateStatusInternalCampaign(this.props.match.params.id, { status: "paused", update_status: true }).then((response) => {
                        this.props.history.push("/user/campaigns/paused");
                    }, (error) => {
                        this.setState({
                            loading_pause: false
                        })
                    });
                })
            }
        },
        enable: () => {
            if (!this.state.loading_enable) {
                this.setState({
                    loading_enable: true
                }, () => {
                    calls.updateStatusInternalCampaign(this.props.match.params.id, { status: "active", update_status: true }).then((response) => {
                        this.props.history.push("/user/campaigns/active");
                    }, (error) => {
                        this.setState({
                            loading_enable: false
                        })
                    });
                })
            }
        },
        edit: () => {
            if (!this.state.loading) {
                this.setState({
                    loading_draft: true
                }, () => {
                    calls.getDraft(this.props.match.params.id).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            if (response.data.onboarding) {
                                response.data.onboarding.extra = {
                                    campaign_id: this.props.match.params.id,
                                    remove_after: true
                                };
                                campaignRegister.set(response.data.onboarding);
                                this.props.history.push('/user/campaigns/new');
                            }
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        donut: (type) => {
            let donut_data = {};
            if (this.state.data.channels) {
                for (let channel in this.state.data.channels) {
                    donut_data[channel] = {
                        click: 0,
                        impressions: 0,
                        ctr: 0,
                        cpc: 0,
                        spend: 0
                    };
                    if (this.state.data.channels[channel][type]) {
                        for (let campaign in this.state.data.channels[channel][type]) {
                            if (channel === "facebook") {
                                if (Array.isArray(this.state.data.channels[channel][type][campaign].insights)) {
                                    this.state.data.channels[channel][type][campaign].insights.map((item) => {

                                        if (!donut_data[channel].insight_length) {
                                            donut_data[channel].insight_length = 1;
                                        } else {
                                            donut_data[channel].insight_length = donut_data[channel].insight_length + 1;
                                        }

                                        donut_data[channel].click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                                        donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                        //donut_data[channel].ctr += +item.inline_link_click_ctr ? +item.inline_link_click_ctr : 0;
                                        //donut_data[channel].cpc += +item.cpc ? +item.cpc : 0;
                                        donut_data[channel].spend += +item.spend ? +item.spend : 0;

                                        /*
                                        if (item.publisher_platform === "instagram") {
                                            if (!donut_data["instagram"]) {
                                                donut_data["instagram"] = {
                                                    click: 0,
                                                    impressions: 0,
                                                    ctr: 0,
                                                    cpc: 0,
                                                    spend: 0
                                                }
                                            };
                                            donut_data["instagram"].click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                                            donut_data["instagram"].impressions += +item.impressions ? +item.impressions : 0;
                                            donut_data["instagram"].ctr += +item.inline_link_click_ctr ? +item.inline_link_click_ctr : 0;
                                            donut_data["instagram"].cpc += +item.cpc ? +item.cpc : 0;
                                            donut_data["instagram"].spend += +item.spend ? +item.spend : 0;
                                        } else {
                                            donut_data[channel].click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                                            donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                            donut_data[channel].ctr += +item.inline_link_click_ctr ? +item.inline_link_click_ctr : 0;
                                            donut_data[channel].cpc += +item.cpc ? +item.cpc : 0;
                                            donut_data[channel].spend += +item.spend ? +item.spend : 0;
                                        }
                                        */

                                    });
                                }
                            } else if (channel === "google") {
                                if (Array.isArray(this.state.data.channels[channel][type][campaign].insights)) {
                                    this.state.data.channels[channel][type][campaign].insights.map((item) => {

                                        if (!donut_data[channel].insight_length) {
                                            donut_data[channel].insight_length = 1;
                                        } else {
                                            donut_data[channel].insight_length = donut_data[channel].insight_length + 1;
                                        }

                                        donut_data[channel].click += +item.clicks ? +item.clicks : 0;
                                        donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                        //donut_data[channel].ctr += +item.ctr?+(item.ctr * 100):0;
                                        //donut_data[channel].cpc += +item.average_cpc?+(item.average_cpc/1000000):0;
                                        donut_data[channel].spend += +item.cost_micros ? +(item.cost_micros / 1000000) : 0;
                                    });
                                }
                            } else if (channel === "linkedin") {
                                if (Array.isArray(this.state.data.channels[channel][type][campaign].insights)) {
                                    this.state.data.channels[channel][type][campaign].insights.map((item) => {

                                        if (!donut_data[channel].insight_length) {
                                            donut_data[channel].insight_length = 1;
                                        } else {
                                            donut_data[channel].insight_length = donut_data[channel].insight_length + 1;
                                        }

                                        donut_data[channel].click += +item.clicks ? +item.clicks : 0;
                                        donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                        donut_data[channel].spend += +item.costInLocalCurrency ? +(item.costInLocalCurrency) : 0;

                                        //donut_data[channel].ctr += (donut_data[channel].click/donut_data[channel].impressions);
                                        //donut_data[channel].cpc += (donut_data[channel].click/donut_data[channel].spend);

                                    });
                                }
                            }
                        }
                    }
                }


                //GET AVERAGE
                for (let key in donut_data) {
                    donut_data[key].ctr = (donut_data[key].click / donut_data[key].impressions) * 100;
                    donut_data[key].cpc = donut_data[key].spend / donut_data[key].click;
                }

            }
            return donut_data;
        },
        errors: () => {
            let allErrors = [];
            let self = this;
            Object.keys(self.state.data).forEach(function (channel) {
                if (channel === 'facebook' || channel === 'instagram' || channel === 'google') {
                    Object.keys(self.state.data[channel]).forEach(function (level) {
                        Object.keys(self.state.data[channel][level]).forEach(function (item) {
                            if (typeof self.state.data[channel][level][item] === 'object') {
                                try {
                                    if (self.state.data[channel][level][item].errors && self.state.data[channel][level][item].errors.length > 0) {
                                        let error = {
                                            errors: self.state.data[channel][level][item].errors,
                                            channel: channel
                                        };
                                        allErrors.push(error);
                                    }
                                } catch (e) {
                                }
                            }
                        });
                    });
                }
            });
            if (allErrors.length > 0) {
                this.setState(prevState => ({
                    campaignDataErrors: {
                        ...prevState.campaignDataErrors,
                        errorsExist: true,
                        errors: allErrors
                    }
                }))
            }
        },
        closeTab: (tabitem) => {
            //console.log(this.renders.tabs())
            this.setState({
                tab: (tabitem.id - 1)
            })

            if (tabitem.id == 1) {
                //Campaign tab
                //delete this.state.campaign;
                this.setState({
                    campaign: {},
                    adgroup: {}
                })
            }
            if (tabitem.id == 2) {
                //Campaign tab
                this.setState({
                    adgroup: {}
                })
            }
            /*
            this.setState({
                adgroup:{
                    ...prevState.adgroup,
                    id:''
                }
            })
            */
        }
    };

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "facebook" || channel === "instagram") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "linkedin") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                    if (external_status === "REMOVED") {
                        status = { name: "Removed", value: "REMOVED" };
                    }
                }
                return status;
            } catch (e) {
                return {}
            }
        },
        budget: (channel, external_budget) => {
            try {
                let budget = 0;
                if (channel === "facebook" || channel === "instagram") {
                    budget = external_budget / 100;
                } else if (channel === "google") {
                    budget = external_budget / 1000000;
                }
                return budget;
            } catch (e) {
                return 0
            }
        },
        objective: (channel, external_objective) => {
            try {
                let objective = {};
                objective = { name: external_objective.replace("_", " ").toLowerCase(), value: external_objective };
                return objective;
            } catch (e) {
                return {}
            }
        },
        tabs: () => {
            let tabs = [{ id: 0, type: "Overview" }];
            if (this.state.campaign.id) {
                tabs.push({ id: 1, title: this.state.campaign.name, type: "Campaign" });
            }
            if (this.state.adgroup.id) {
                tabs.push({ id: 2, title: this.state.adgroup.name, type: "Adgroup" });
            }
            if (this.state.ad.id) {
                //tabs.push({id: 3, title: this.state.ad.name, type: "Ad"});
            }
            return tabs;
        },
        submenus: () => {
            try {
                if (this.state.tab === 0) {
                    return [
                        { id: 0, name: "All campaigns" },
                        { id: 1, name: "Import campaign" }
                    ];
                } else if (this.state.tab === 1) {
                    return [
                        { id: 0, name: "All adgroups" },
                        { id: 1, name: "Edit campaign" }
                    ];
                } else if (this.state.tab === 2) {
                    return [
                        { id: 0, name: "All ads" },
                        { id: 1, name: "Edit adgroup" }
                    ];
                }
            } catch (e) {
                return [];
            }
        },
        errorName: (item) => {
            try {
                if (typeof item.name === 'string') {
                    return item.name
                } else if (Array.isArray(item.name)) {
                    let string = "";
                    return item.name.map((inner_item) => {
                        return inner_item.name;
                    }).join(', ');
                }
            } catch (e) {
                return 'Something went wrong'
            }
        }
    };

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                {
                    this.state.loading_draft &&
                    <Loader
                        title={"Loading campaign ..."}
                    />
                }
                {
                    (this.state.loading_pause || this.state.loading_enable) &&
                    <Loader
                        title={"Updating campaign ..."}
                    />
                }
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Remove all campaigns"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            remove_modal: false
                        }, () => {
                            this.functions.remove();
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            remove_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.pause_modal}
                    title="Pause all campaigns"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            pause_modal: false
                        }, () => {
                            this.functions.pause();
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            pause_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.activate_modal}
                    title="Activate all campaigns"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            activate_modal: false
                        }, () => {
                            this.functions.enable();
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            activate_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.edit_modal}
                    title="Edit campaign"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            edit_modal: false
                        }, () => {
                            this.functions.edit();
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            edit_modal: false
                        })
                    }}
                />
                {
                    this.state.status === "error" &&
                    !this.state.loading &&
                    <div className="rounded-md bg-white p-4 shadow-xl">
                        <div className="flex flex-1 flex-col mb-4">
                            <h3 className="mb-0 font-bold">
                                Errors
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Please correct errors
                            </p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md">
                            <div className="flex flex-1 flex-row mb-4 px-4">
                                <div className="text-sm font-bold w-40">
                                    Channel
                                </div>
                                <div className="text-sm font-bold w-40">
                                    Type
                                </div>
                                <div className="text-sm font-bold w-1/4">
                                    Name
                                </div>
                                <div className="text-sm font-bold w-1/4">
                                    Message
                                </div>
                            </div>
                            <div className="bg-white p-4 rounded-md">
                                <div className="flex flex-col">
                                    {
                                        this.state.errors.map((item) => {
                                            return (
                                                <div className="flex flex-1 flex-row pb-2 border-b mb-2 items-center">
                                                    <div className="text-sm w-40">
                                                        {item.channel}
                                                    </div>
                                                    <div className="text-sm w-40">
                                                        {item.type}
                                                    </div>
                                                    <div className="text-sm w-1/4">
                                                        {this.renders.errorName(item)}
                                                    </div>
                                                    <div className="text-sm w-1/4">
                                                        {item.message}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            this.state.status === "error" &&
                            !this.state.loading &&
                            <div className="flex mt-4 justify-end">
                                <div>
                                    <button onClick={() => {
                                        this.setState({
                                            activate_modal: true
                                        })
                                    }} className="ml-2 mr-2 bg-green-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200">
                                        Activate
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => {
                                        this.setState({
                                            edit_modal: true
                                        })
                                    }} className="ml-2 mr-2 bg-purple-500 text-white flex flex-row items-center py-2 px-6 rounded-full font-bold text-sm transition-all duration-200 hover:bg-purple-800">
                                        Resolve errors
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.status !== "error" &&
                    !this.state.loading &&
                    <div className="">
                        <div className="flex flex-1 flex-row justify-start px-4 mt-4 relative z-10">
                            {
                                this.renders.tabs().map((item, index) => {
                                    return (
                                        <div
                                            className={cx("cursor-pointer relative max-w-xs bg-white flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-center rounded-t-md text-sm", {
                                                ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.tab,
                                                ["border-transparent"]: item.id != this.state.tab,
                                                ["ml-1"]: index !== 0
                                            })}>
                                            <div onClick={(e) => {
                                                if (!this.state.loading_remove && this.state.tab != item.id) {
                                                    this.setState({
                                                        tab: item.id
                                                    })
                                                }
                                            }}
                                                className={"truncate p-3 " + (index != 0 ? 'w-11/12' : 'w-full')}
                                                title={item.title}>
                                                <span
                                                    className="font-bold">{item.type}</span><span>{item.title ? ': ' + item.title : ''}</span>
                                            </div>
                                            {item.id === this.state.tab &&
                                                <span
                                                    className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
                                            }
                                            {index != 0 &&
                                                <span
                                                    className="close w-6 h-6 absolute right-0 mr-2 top-50 transform -translate-y-1/2 bg-gray-100 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"
                                                    onClick={() => {
                                                        this.functions.closeTab(item);
                                                    }}
                                                >
                                                    &times;
                                                </span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="bg-white shadow-xl rounded-md p-4 relative">
                            {
                                this.state.loading_remove &&
                                <div
                                    className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={10}
                                        color={'#060534'}
                                        loading={true}
                                    />
                                </div>
                            }
                            {
                                this.state.tab === 0 && this.state.data.name && false &&
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        {this.state.data.name}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 1 && this.state.campaign.id && false &&
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        {this.state.campaign.name}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 2 && this.state.adgroup.id && false &&
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        {this.state.adgroup.name}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 3 && this.state.ad.id && false &&
                                <div className="flex flex-1 flex-col">
                                    <h3 className="mb-0 font-bold">
                                        {this.state.ad.name}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                false &&
                                <div className="p-4 pb-0 bg-gray-100 rounded-md">
                                    {
                                        this.state.tab === 2 && this.state.adgroup.id &&
                                        <Donuts
                                            item={this.state.adgroup}
                                        />
                                    }
                                </div>
                            }
                            {
                                <div>
                                    <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                        <ul className="flex flex-row items-end">
                                            {
                                                this.renders.submenus().map((item, j) => {
                                                    return (
                                                        <li key={j}
                                                            className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                            onClick={() => {
                                                                this.setState({
                                                                    submenu: item.id
                                                                });
                                                            }}>
                                                            {item.name}
                                                        </li>
                                                    )
                                                })
                                            }
                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                        </ul>
                                    </nav>
                                </div>
                            }
                            {
                                this.state.tab === 0 && false &&
                                <div className="flex flex-1 flex-col mt-4">
                                    <h3 className="mb-0 font-bold">
                                        {"All campaigns"}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 1 && this.state.campaign.id && false &&
                                <div className="flex flex-1 flex-col mt-4">
                                    <h3 className="mb-0 font-bold">
                                        {"Adgroups"}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 2 && this.state.adgroup.id && false &&
                                <div className="flex flex-1 flex-col mt-4">
                                    <h3 className="mb-0 font-bold">
                                        {"Ads"}
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Avg. number of search per month in parantheses
                                    </p>
                                </div>
                            }
                            {
                                this.state.tab === 0 && this.state.submenu === 0 &&
                                <div className="p-4 pb-0 bg-gray-100 rounded-md mt-4">
                                    <div className="pb-4">
                                        <CampaignDonuts2
                                            data={this.state.campaign_donut}
                                            loading={this.state.loading}
                                            properties={['click', 'impressions', 'ctr', 'spend', 'cpc']}
                                            showchannels={false}
                                        />
                                        <List
                                            internalCampaign={this.props.match.params.id}
                                            items={this.state.campaigns}
                                            loading={this.state.campaigns_loading}
                                            type={"campaign"}
                                            selectItem={(campaign) => {
                                                this.setState({
                                                    campaign: campaign,
                                                    tab: 1
                                                }, () => {
                                                    this.functions.adgroups();
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.tab === 1 && this.state.campaign.id && this.state.submenu === 0 &&
                                <div className="p-4 pb-0 bg-gray-100 rounded-md mt-4">
                                    <div className="pb-4">
                                        <Donuts
                                            item={this.state.campaign}
                                        />
                                        <List
                                            internalCampaign={this.props.match.params.id}
                                            items={Array.isArray(this.state.campaign.adgroups) ? this.state.campaign.adgroups : []}
                                            loading={this.state.loading_adgroups}
                                            type={"adgroup"}
                                            selectItem={(adgroup) => {
                                                this.setState({
                                                    adgroup: adgroup,
                                                    tab: 2
                                                }, () => {
                                                    this.functions.ads();
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.tab === 2 && this.state.adgroup.id && this.state.submenu === 0 &&
                                <div className="p-4 pb-0 bg-gray-100 rounded-md mt-4">
                                    <div className="pb-4">
                                        <Donuts
                                            item={this.state.adgroup}
                                        />
                                        <List
                                            internalCampaign={this.props.match.params.id}
                                            items={Array.isArray(this.state.adgroup.ads) ? this.state.adgroup.ads : []}
                                            loading={this.state.loading_ads}
                                            type={"ad"}
                                            selectItem={(ad) => {
                                                this.setState({
                                                    ad: ad,
                                                    tab: 3
                                                }, () => {
                                                    this.functions.creative();
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.tab === 0 && this.state.submenu === 1 &&
                                <ImportCampaign
                                    data={this.state.original_data}
                                    internalCampaign={this.props.match.params.id}
                                    refresh={() => {
                                        this.functions.campaigns(this.props.match.params.id);
                                    }}
                                    updateData={(data) => {
                                        this.setState({
                                            data: data,
                                            original_data: data,
                                        }, () => {
                                            this.functions.updateCampaigns();
                                        })
                                    }}
                                />
                            }
                            <div className="mt-4 flex flex-1 flex-row">
                                {
                                    this.state.tab === 0 && this.state.submenu === 0 &&
                                    <button onClick={() => {
                                        this.setState({
                                            remove_modal: true
                                        });
                                    }} className={"mr-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                        <span>Delete order campaign</span>
                                    </button>
                                }
                                {
                                    this.state.tab === 0 && this.state.submenu === 0 && this.state.status === "active" &&
                                    <button onClick={() => {
                                        this.setState({
                                            pause_modal: true
                                        });
                                    }} className={"bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                        <span>Pause order campaign</span>
                                    </button>
                                }
                                {
                                    this.state.tab === 0 && this.state.submenu === 0 && this.state.status === "paused" &&
                                    <button onClick={() => {
                                        this.setState({
                                            activate_modal: true
                                        });
                                    }} className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                        <span>Activate order campaign</span>
                                    </button>
                                }
                            </div>
                            {
                                this.state.tab === 1 && this.state.campaign.id && this.state.submenu === 1 &&
                                <EditCampaign
                                    data={this.state.data}
                                    item={this.state.campaign}
                                    internalCampaign={this.props.match.params.id}
                                    update={(campaign) => {
                                        this.setState({
                                            data: this.state.data,
                                            campaign: campaign,
                                            campaigns: this.state.campaigns.map((item) => {
                                                if (item.id === campaign.id) {
                                                    item = campaign;
                                                }
                                                return item;
                                            })
                                        });
                                    }}
                                    remove={(campaign) => {
                                        this.setState({
                                            campaign: {},
                                            tab: 0
                                        }, () => {
                                            try {
                                                for (let channel in this.state.data.channels) {
                                                    for (let inner_campaign in this.state.data.channels[channel].campaigns) {
                                                        if (inner_campaign == campaign.id) {
                                                            delete this.state.data.channels[channel].campaigns[inner_campaign];
                                                            delete this.state.original_data.channels[channel].campaigns[inner_campaign];
                                                        }
                                                    }
                                                }
                                            } catch (e) {
                                                console.log(e);
                                            }
                                            if (Array.isArray(this.state.campaigns)) {
                                                this.state.campaigns = this.state.campaigns.filter((internal_campaign) => {
                                                    return internal_campaign.id !== campaign.id;
                                                })
                                            }
                                            this.setState({
                                                original_data: this.state.original_data,
                                                data: this.state.data,
                                                campaigns: this.state.campaigns
                                            });
                                        });
                                    }}
                                />
                            }
                            {
                                this.state.tab === 2 && this.state.adgroup.id && this.state.submenu === 1 &&
                                <EditAdgroup
                                    item={this.state.adgroup}
                                    internalCampaign={this.props.match.params.id}
                                    update={(adgroup) => {
                                        this.setState({
                                            adgroup: adgroup,
                                        }, () => {
                                            if (Array.isArray(this.state.campaign.adgroups)) {
                                                this.state.campaign.adgroups = this.state.campaign.adgroups.map((adgroup) => {
                                                    if (adgroup.id === this.state.adgroup.id) {
                                                        adgroup = this.state.adgroup;
                                                    }
                                                    return adgroup;
                                                })
                                            }
                                            this.setState({
                                                campaign: this.state.campaign
                                            });
                                        });
                                    }}
                                    remove={(adgroup) => {
                                        this.setState({
                                            adgroup: {},
                                            tab: 1
                                        }, () => {
                                            if (Array.isArray(this.state.campaign.adgroups)) {
                                                this.state.campaign.adgroups = this.state.campaign.adgroups.filter((internal_adgroup) => {
                                                    return internal_adgroup.id !== adgroup.id;
                                                })
                                            }
                                            this.setState({
                                                campaign: this.state.campaign
                                            });
                                        });
                                    }}
                                />
                            }
                            {
                                this.state.tab === 3 && this.state.ad.id && false &&
                                <EditAd
                                    item={this.state.ad}
                                    loading={this.state.loading_ad}
                                    internalCampaign={this.props.match.params.id}
                                    update={(ad) => {
                                        this.setState({
                                            ad: ad,
                                        }, () => {
                                            if (Array.isArray(this.state.adgroup.ads)) {
                                                this.state.adgroup.ads = this.state.adgroup.ads.map((ad) => {
                                                    if (ad.id === this.state.ad.id) {
                                                        ad = this.state.ad;
                                                    }
                                                    return ad;
                                                })
                                            }
                                            this.setState({
                                                adgroup: this.state.adgroup
                                            });
                                        });
                                    }}
                                    remove={(ad) => {
                                        this.setState({
                                            ad: {},
                                            tab: 2
                                        }, () => {
                                            if (Array.isArray(this.state.adgroup.ads)) {
                                                this.state.adgroup.ads = this.state.adgroup.ads.filter((internal_ad) => {
                                                    return internal_ad.id !== ad.id;
                                                })
                                            }
                                            this.setState({
                                                adgroup: this.state.adgroup
                                            });
                                        });
                                    }}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    false &&
                    this.state.status !== "error" &&
                    !this.state.loading && this.state.original_data && this.state.original_data.channels && this.state.original_data.channels.google &&
                    <AddKeyword
                        data={this.state.original_data}
                        internalCampaign={this.props.match.params.id}
                    />
                }
                {
                    this.state.loading &&
                    <div className="mt-48 mb-48">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Campaign ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
            </section>
        )
    }
}

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            internal_campaign: "",
            sort: "name",
            order: "asc",
            base_metrics: [
                { title: "Click", value: "click" },
                { title: "Impressions", value: "impressions" },
                { title: "CTR", value: "ctr" },
                { title: "CPC", value: "cpc" },
                { title: "Spend", value: "spend" }
            ]
        };
    };

    componentDidMount() {
        this.setState({
            internal_campaign: this.props.internalCampaign,
            items: this.props.items,
            type: this.props.type,
            loading: this.props.loading
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            internal_campaign: nextProps.internalCampaign,
            items: nextProps.items,
            type: nextProps.type,
            loading: nextProps.loading
        })
    }

    functions = {};

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "facebook" || channel === "instagram") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                    if (external_status === "REMOVED") {
                        status = { name: "Removed", value: "REMOVED" };
                    }
                }
                return status;
            } catch (e) {
                return {}
            }
        },
        insights: (item) => {
            let donut_data = {};
            donut_data = {
                click: 0,
                impressions: 0,
                ctr: 0,
                cpc: 0,
                spend: 0
            };
            if (item.channel === "facebook") {
                if (Array.isArray(item.insights)) {
                    item.insights.map((item) => {
                        donut_data.click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                        donut_data.impressions += +item.impressions ? +item.impressions : 0;
                        donut_data.ctr += +item.inline_link_click_ctr ? +item.inline_link_click_ctr : 0;
                        donut_data.cpc += +item.cpc ? +item.cpc : 0;
                        donut_data.spend += +item.spend ? +item.spend : 0;
                    });
                    //donut_data.ctr = donut_data.ctr ? (donut_data.ctr / item.insights.length) : 0;
                    //donut_data.cpc = donut_data.cpc ? (donut_data.cpc / item.insights.length) : 0;
                }
            } else if (item.channel === "google") {
                if (Array.isArray(item.insights)) {
                    item.insights.map((item) => {
                        donut_data.click += +item.clicks ? +item.clicks : 0;
                        donut_data.impressions += +item.impressions ? +item.impressions : 0;
                        donut_data.ctr += +item.ctr ? +(item.ctr * 100) : 0;
                        donut_data.cpc += +item.average_cpc ? +(item.average_cpc / 1000000) : 0;
                        donut_data.spend += +item.cost_micros ? +(item.cost_micros / 1000000) : 0;
                    });
                    //donut_data.ctr = donut_data.ctr ? (donut_data.ctr / item.insights.length) : 0;
                    //donut_data.cpc = donut_data.cpc ? (donut_data.cpc / item.insights.length) : 0;
                } else {
                    donut_data.click += +item.insights.clicks ? +item.insights.clicks : 0;
                    donut_data.impressions += +item.insights.impressions ? +item.insights.impressions : 0;
                    donut_data.ctr += +item.insights.ctr ? +(item.insights.ctr * 100) : 0;
                    donut_data.cpc += +item.insights.average_cpc ? +(item.insights.average_cpc / 1000000) : 0;
                    donut_data.spend += +item.insights.cost_micros ? +(item.insights.cost_micros / 1000000) : 0;
                }
            }
            for (let key in donut_data) {
                donut_data[key] = (Math.round((+donut_data[key] + Number.EPSILON) * 100) / 100)
            }
            item = { ...item, ...donut_data };
            return item;
        },
        instagram: (item) => {
            let exist = false;
            try {
                if (Array.isArray(item.insights)) {
                    item.insights.map((inner_item) => {
                        if (inner_item.publisher_platform === "instagram") {
                            exist = true;
                        }
                    })
                }
            } catch (e) {
            }
            return exist;
        }
    };

    render() {
        return (
            <>
                <>
                    {
                        this.state.loading &&
                        <div className="flex justify-center items-center align-middle h-32 w-full">
                            <BeatLoader sizeUnit={'px'} size={24} color={'#060534'} loading={true} />
                        </div>
                    }
                    {
                        !this.state.loading && Array.isArray(this.state.items) &&
                        <div className="shadow-lg overflow-scroll border-b border-gray-200 sm:rounded-lg w-full">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                    <tr>
                                        <th onClick={() => {
                                            this.setState({
                                                sort: 'name',
                                                order: this.state.order === 'asc' ? 'desc' : 'asc'
                                            });
                                        }}
                                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 pr-3 pl-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                {this.props.type} name <div className="sorting-arrows ml-2"></div>
                                            </div>
                                        </th>
                                        <th onClick={() => {
                                            this.setState({
                                                sort: 'status',
                                                order: this.state.order === 'asc' ? 'desc' : 'asc'
                                            });
                                        }}
                                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'status' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                Status <div className="sorting-arrows ml-2"></div>
                                            </div>
                                        </th>
                                        <th onClick={() => {
                                            this.setState({
                                                sort: 'channel',
                                                order: this.state.order === 'asc' ? 'desc' : 'asc'
                                            });
                                        }}
                                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'channel' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                            <div className="flex flex-row items-center">
                                                Channel <div className="sorting-arrows ml-2"></div>
                                            </div>
                                        </th>
                                        {
                                            this.state.base_metrics.map((item) => {
                                                return (
                                                    <th onClick={() => {
                                                        this.setState({
                                                            sort: item.value,
                                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                                        });
                                                    }}
                                                        className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-3 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === item.value ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                                        <div className="flex flex-row items-center">
                                                            {item.title}
                                                            <div className="sorting-arrows ml-2"></div>
                                                        </div>
                                                    </th>
                                                )
                                            })
                                        }
                                        {this.props.type !== " ad" &&
                                            <th className="transition-all duration-200 pl-3 pr-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                <div className="flex flex-row items-center justify-end">
                                                    Actions
                                                </div>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {this.state.items.map((item) => {
                                        item = this.renders.insights(item);
                                        return item;
                                    }).sort((a, b) => {
                                        if (this.state.sort) {
                                            var nameA = null;
                                            var nameB = null;
                                            try {
                                                nameA = a[this.state.sort].value.toLowerCase();
                                            } catch (e) {
                                                nameA = a[this.state.sort];
                                            }
                                            try {
                                                nameB = b[this.state.sort].value.toLowerCase();
                                            } catch (e) {
                                                nameB = b[this.state.sort];
                                            }
                                            if (this.state.order === 'desc') {
                                                if (nameA > nameB)
                                                    return -1;
                                                if (nameA < nameB)
                                                    return 1;
                                                return 0
                                            } else {
                                                if (nameA < nameB)
                                                    return -1;
                                                if (nameA > nameB)
                                                    return 1;
                                                return 0
                                            }
                                        } else {
                                            return 1
                                        }
                                    }).map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <tr>
                                                    <td className="pr-3 pl-6 py-4 max-w-xs">
                                                        <div className="flex flex-row items-center">
                                                            <div
                                                                className={"text-sm leading-5 text-gray-900 " + (this.state.sort === 'name' ? 'font-bold' : '')}>
                                                                {item.status.name !== "Removed"
                                                                    ? //if
                                                                    <span onClick={() => {
                                                                        if (this.props.type === "ad") {
                                                                            this.setState({
                                                                                items: this.state.items.map((inner_item) => {
                                                                                    if (inner_item.id === item.id) {
                                                                                        inner_item.open = !item.open;
                                                                                    }
                                                                                    return inner_item;
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.props.selectItem(item);
                                                                        }
                                                                    }}
                                                                        className={"hover:text-blue-500 hover:underline cursor-pointer"}>
                                                                        {item.name}
                                                                    </span>
                                                                    : // else 
                                                                    <span>{item.init_name}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-3 py-4 whitespace-no-wrap capitalize">
                                                        <div
                                                            className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                {
                                                                    ["bg-green-100 text-green-500"]: item.status.name === "Active",
                                                                    ["bg-purple-100 text-purple-500"]: item.status.name === "Ended",
                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status.name === "Paused",
                                                                    ["bg-red-100 text-red-600"]: (item.status.name === "Error" || item.status.name === "Removed"),
                                                                })
                                                            }>
                                                            {item.status.name}
                                                        </div>
                                                    </td>
                                                    <td className="px-3 py-4 whitespace-no-wrap">
                                                        <div className="flex flex-row">
                                                            {item.channel === "facebook" &&
                                                                <div
                                                                    className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                    <div
                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                        style={{ backgroundImage: "url(" + require('../../../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                </div>
                                                            }
                                                            {this.renders.instagram(item) &&
                                                                <div
                                                                    className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white ' + (item.channel === "facebook" ? '-ml-2' : '')}>
                                                                    <div
                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                        style={{ backgroundImage: "url(" + require('../../../assets/images/instagram_icon.svg') + ")" }}></div>
                                                                </div>
                                                            }
                                                            {item.channel === "linkedin" &&
                                                                <div
                                                                    className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + ((item.channel === "facebook" || this.renders.instagram(item)) ? '-ml-2' : '')}>
                                                                    <div
                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                        style={{ backgroundImage: "url(" + require('../../../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                </div>
                                                            }
                                                            {item.channel === "google" &&
                                                                <div
                                                                    className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + ((item.channel === "facebook" || this.renders.instagram(item) || item.channel === "linkedin") ? '-ml-2' : '')}>
                                                                    <div
                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                        style={{ backgroundImage: "url(" + require('../../../assets/images/google_icon.svg') + ")" }}></div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                    {
                                                        this.state.base_metrics.map((metric) => {
                                                            return (
                                                                <td className="px-3 py-4 whitespace-no-wrap max-w-xs">
                                                                    <div className="flex flex-row items-center">
                                                                        <div
                                                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === metric.value ? 'font-bold' : '')}>
                                                                            {item[metric.value]}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        <td className="pl-3 pr-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                            <div className="flex flex-row items-center justify-end">
                                                                {
                                                                    item.status.name !== "Removed" &&
                                                                    <button onClick={() => {
                                                                        if (this.props.type === "ad") {
                                                                            this.setState({
                                                                                items: this.state.items.map((inner_item) => {
                                                                                    if (inner_item.id === item.id) {
                                                                                        inner_item.open = !item.open;
                                                                                    }
                                                                                    return inner_item;
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.props.selectItem(item);
                                                                        }
                                                                    }}
                                                                        className={"bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white"}>
                                                                        <span>Open {this.props.type}</span>
                                                                        <FeatherIcon className="stroke-current ml-2"
                                                                            size={16}
                                                                            icon="arrow-right-circle" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                                {this.props.type === "ad" &&
                                                    <tr style={{ borderTop: 'none' }}>
                                                        <td colspan="15">
                                                            <SlideDown closed={!item.open}>
                                                                <div className="flex flex-1 pl-4 pr-4 pb-4">
                                                                    <EditAd
                                                                        item={item}
                                                                        loading={item.loading}
                                                                        internalCampaign={this.state.internal_campaign}
                                                                        update={(ad) => {
                                                                            this.setState({
                                                                                items: this.state.items.map((internal_item) => {
                                                                                    if (ad.id === internal_item.id) {
                                                                                        internal_item = ad;
                                                                                    }
                                                                                    return internal_item;
                                                                                })
                                                                            });
                                                                        }}
                                                                        remove={(ad) => {
                                                                            this.setState({
                                                                                items: this.state.items.filter((ad) => {
                                                                                    return ad.id !== item.id;
                                                                                })
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </SlideDown>
                                                        </td>
                                                    </tr>
                                                }
                                            </Fragment>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            </>
        )
    }

}

class EditCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            campaign: {},
            data: {},
            internal_campaign: ""
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item,
            internal_campaign: this.props.internalCampaign
        }, () => {
            this.state.item.no_end_date = !this.state.item.end_date;
            this.setState({
                item: this.state.item
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            internal_campaign: nextProps.internalCampaign,
        }, () => {
            this.state.item.no_end_date = !this.state.item.end_date;
            this.setState({
                item: this.state.item
            })
        })
    }

    functions = {
        save: (item) => {
            if (this.state.item.daily_budget !== this.state.item.init_daily_budget) {
                this.functions.updateBudget(this.state.item);
            } else if (!moment(this.state.item.end_date).isSame(this.state.item.init_end_date) || (!this.state.item.end_date && !this.state.item.no_end_date) || (this.state.item.end_date && this.state.item.no_end_date)) {
                this.functions.updateEndDate(this.state.item);
            } else if (this.state.item.name !== this.state.item.init_name) {
                this.functions.updateName(this.state.item);
            }
        },
        updateBudget: (item) => {
            if (!item.loading_budget) {
                item.loading_budget = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateCampaign({
                        update_budget: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        channel: item.channel,
                        daily_budget: item.daily_budget
                    }).then((response) => {
                        item.init_daily_budget = JSON.parse(JSON.stringify(item.daily_budget));
                        item.loading_budget = false;
                        item.error_budget = false;
                        item.error_budget_message = "";
                        this.props.update(item);
                    }, (error) => {
                        item.loading_budget = false;
                        item.error_budget = true;
                        item.error_budget_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        updateEndDate: (item) => {
            if (!item.loading_end_date) {
                item.loading_end_date = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateCampaign({
                        update_end_date: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        channel: item.channel,
                        end_date: !item.no_end_date ? item.end_date : "no_date"
                    }).then((response) => {
                        if (item.no_end_date) {
                            delete item.end_date;
                        }
                        item.init_end_date = !item.no_end_date ? JSON.parse(JSON.stringify(item.end_date)) : false;
                        item.loading_end_date = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        this.props.update(item);
                    }, (error) => {
                        item.loading_end_date = false;
                        item.error_date = true;
                        item.error_date_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        updateStatus: (item, status) => {
            if (!item.loading_status) {
                item.loading_status = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateCampaign({
                        update_status: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        channel: item.channel,
                        status: status
                    }).then((response) => {
                        item.loading_status = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        item.status = this.renders.status(item.channel, response.data);
                        this.props.update(item);
                    }, (error) => {
                        item.loading_status = false;
                        item.error_status = true;
                        item.error_status_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        updateName: (item) => {
            if (!item.loading_name) {
                item.loading_name = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateCampaign({
                        update_name: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        channel: item.channel,
                        name: item.name
                    }).then((response) => {
                        item.loading_name = false;
                        item.error_name = false;
                        item.error_name_message = "";
                        item.init_name = JSON.parse(JSON.stringify(item.name));
                        this.props.update(item);
                    }, (error) => {
                        item.loading_name = false;
                        item.error_name = true;
                        item.error_name_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading_remove) {
                item.loading_remove = true;
                this.setState({
                    item: item
                }, () => {
                    calls.removeCampaign({
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        channel: item.channel,
                    }).then((response) => {
                        item.loading_remove = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        this.props.remove(item);
                    }, (error) => {
                        item.loading_remove = false;
                        item.error_remove = true;
                        item.error_remove_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        removeReference: (item) => {
            if (!item.loading_remove) {
                item.loading_remove = true;
                this.setState({
                    item: item
                }, () => {
                    calls.removeCampaignReference({
                        id: this.state.internal_campaign,
                        campaign: item.id,
                        channel: item.channel,
                    }).then((response) => {
                        item.loading_remove = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        this.props.remove(item);
                    }, (error) => {
                        item.loading_remove = false;
                        item.error_remove = true;
                        item.error_remove_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        }
    };

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "facebook" || channel === "instagram") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                }
                return status;
            } catch (e) {
                return {}
            }
        },
    };

    render() {
        return (
            <div className="relative">
                {
                    false &&
                    <div className="flex flex-1 flex-col mb-4 mt-4">
                        <h3 className="mb-0 font-bold">
                            {"Edit Campaign"}
                        </h3>
                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                            Avg. number of search per month in parantheses
                        </p>
                    </div>
                }
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Delete campaign"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            remove_modal: false
                        }, () => {
                            this.functions.remove(this.state.item);
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            remove_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.removereference_modal}
                    title="Remove campaign reference"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            removereference_modal: false
                        }, () => {
                            this.functions.removeReference(this.state.item);
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            removereference_modal: false
                        })
                    }}
                />
                {
                    (this.state.item.loading_status || this.state.item.loading_remove) &&
                    <div
                        className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                <div className="flex flex-1 flex-col rounded-md px-4 py-4 bg-gray-100">
                    <div className="flex flex-1 flex-row">
                        <div className="flex flex-1 flex-col">
                            <div className="flex flex-1 relative">
                                {
                                    this.state.item.loading_name &&
                                    <div style={{ top: "25px" }}
                                        className="absolute z-10 left-0 right-0 bottom-0 bg-white rounded flex justify-center items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                <Input
                                    noBorder={true}
                                    title={"Name"}
                                    type={'text'}
                                    white={true}
                                    placeholder={'Name'}
                                    value={this.state.item.name}
                                    size={'full'}
                                    onChange={(event) => {
                                        this.state.item.name = event.target.value;
                                        this.setState({
                                            data: this.state.data
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.item.error_name && !this.state.item.loading_name &&
                                <div className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                    <ul className="list-disc">
                                        <li className="text-xs text-red-500 font-bold ">
                                            {this.state.item.error_name_message}
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex flex-1 mt-4">
                        <div className="flex flex-1 flex-col mr-2">
                            <div className="flex flex-1 relative">
                                {
                                    this.state.item.loading_budget &&
                                    <div style={{ top: "25px" }}
                                        className="absolute z-10 left-0 right-0 bottom-0 bg-white rounded flex justify-center items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                <Input
                                    noBorder={true}
                                    title={"Daily budget"}
                                    type={'number'}
                                    white={true}
                                    placeholder={'Set daily budget'}
                                    value={this.state.item.daily_budget}
                                    size={'full'}
                                    onChange={(event) => {
                                        this.state.item.daily_budget = event.target.value;
                                        this.setState({
                                            item: this.state.item
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.item.error_budget && !this.state.item.loading_budget &&
                                <div className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                    <ul className="list-disc">
                                        <li className="text-xs text-red-500 font-bold ">
                                            {this.state.item.error_budget_message}
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="ml-2 flex-1 flex">
                            <Input
                                noBorder={true}
                                title={"Objective"}
                                type={'text'}
                                white={true}
                                disabled={true}
                                placeholder={'Objective'}
                                value={this.state.item.objective ? this.state.item.objective.name : ""}
                                size={'full'}
                                onChange={(event) => {

                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col mt-4">
                        <div className="flex flex-1">
                            <Datepicker
                                size={'full'}
                                showTime={true}
                                disabled={false}
                                border={false}
                                noIcon={true}
                                white={true}
                                showNoEndDateSwitch={true}
                                disableEndDate={this.state.item.no_end_date}
                                loadingEndDate={this.state.item.loading_end_date}
                                disableStartDate={true}
                                startDate={this.state.item.start_date}
                                endDate={this.state.item.end_date}
                                minEndDate={new Date()}
                                updateNoEndDate={(value) => {
                                    this.state.item.no_end_date = value;
                                    this.setState({
                                        item: this.state.item
                                    })
                                }}
                                onStartDate={(value) => {
                                    this.state.item.start_date = value;
                                    this.setState({
                                        item: this.state.item
                                    })
                                }}
                                onEndDate={(value) => {
                                    this.state.item.end_date = value;
                                    this.setState({
                                        item: this.state.item
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.item.error_date &&
                            <div className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                <ul className="list-disc">
                                    <li className="text-xs text-red-500 font-bold ">
                                        {this.state.item.error_date_message}
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-4 flex flex-row">
                    <div className="flex flex-1 flex-row">
                        {
                            this.state.item.status && this.state.item.status.name === "Active" &&
                            <button onClick={() => {
                                this.functions.updateStatus(this.state.item, "paused");
                            }}
                                className={"bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Pause campaign</span>
                            </button>
                        }
                        {
                            this.state.item.status && this.state.item.status.name === "Paused" &&
                            <button onClick={() => {
                                this.functions.updateStatus(this.state.item, "active");
                            }}
                                className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Activate campaign</span>
                            </button>
                        }
                        <button onClick={() => {
                            this.setState({
                                remove_modal: true
                            })
                        }}
                            className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                            <span>Delete campaign</span>
                        </button>
                        <button onClick={() => {
                            this.setState({
                                removereference_modal: true
                            })
                        }}
                            className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                            <span>Remove reference</span>
                        </button>
                        {
                            false &&
                            <button onClick={() => {
                                //this.functions.save(this.state.item);
                            }}
                                className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Remove reference</span>
                            </button>
                        }
                    </div>
                    <div className="justify-end">
                        <button onClick={() => {
                            this.functions.save(this.state.item);
                        }}
                            className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                            <span>Save changes</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

class EditAdgroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            campaign: {},
            data: {},
            internal_campaign: ""
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item,
            internal_campaign: this.props.internalCampaign
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            internal_campaign: nextProps.internalCampaign,
        })
    }

    functions = {
        save: () => {
            if (this.state.item.name !== this.state.item.init_name) {
                this.functions.updateName(this.state.item);
            }
        },
        updateStatus: (item, status) => {
            if (!item.loading_status) {
                item.loading_status = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateAdgroup({
                        update_status: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        adgroup: item.id,
                        channel: item.channel,
                        status: status
                    }).then((response) => {
                        item.loading_status = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        item.status = this.renders.status(item.channel, response.data);
                        this.props.update(item);
                    }, (error) => {
                        item.loading_status = false;
                        item.error_status = true;
                        item.error_status_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        updateName: (item) => {
            if (!item.loading_name) {
                item.loading_name = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateAdgroup({
                        update_name: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        adgroup: item.id,
                        channel: item.channel,
                        name: item.name
                    }).then((response) => {
                        item.loading_name = false;
                        item.error_name = false;
                        item.error_name_message = "";
                        item.init_name = JSON.parse(JSON.stringify(item.name));
                        this.props.update(item);
                    }, (error) => {
                        item.loading_name = false;
                        item.error_name = true;
                        item.error_name_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading_remove) {
                item.loading_remove = true;
                this.setState({
                    item: item
                }, () => {
                    calls.removeAdgroup({
                        loading_remove: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        adgroup: item.id,
                        channel: item.channel,
                    }).then((response) => {
                        item.loading_remove = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        this.props.remove(item);
                    }, (error) => {
                        item.loading_remove = false;
                        item.error_remove = true;
                        item.error_remove_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
    };

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "facebook" || channel === "instagram") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                }
                return status;
            } catch (e) {
                return {}
            }
        }
    };

    render() {
        return (
            <div className="relative">
                {
                    false &&
                    <div className="flex flex-1 flex-col mb-4 mt-4">
                        <h3 className="mb-0 font-bold">
                            {"Edit Adgroup"}
                        </h3>
                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                            Avg. number of search per month in parantheses
                        </p>
                    </div>
                }
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Remove Adgroup"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            remove_modal: false
                        }, () => {
                            this.functions.remove(this.state.item);
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            remove_modal: false
                        })
                    }}
                />
                {
                    (this.state.item.loading_status || this.state.item.loading_remove) &&
                    <div
                        className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                <div className="flex flex-1 flex-col rounded-md px-4 py-4 bg-gray-100 mt-4">
                    <div className="flex flex-1">
                        <div className="flex flex-1 flex-col">
                            <div className="flex flex-1 relative">
                                {
                                    this.state.item.loading_name &&
                                    <div style={{ top: "25px" }}
                                        className="absolute z-10 left-0 right-0 bottom-0 bg-white rounded flex justify-center items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                <Input
                                    noBorder={true}
                                    title={"Name"}
                                    type={'text'}
                                    white={true}
                                    placeholder={'Name'}
                                    value={this.state.item.name}
                                    size={'full'}
                                    onChange={(event) => {
                                        this.state.item.name = event.target.value;
                                        this.setState({
                                            data: this.state.data
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.item.error_name && !this.state.item.loading_name &&
                                <div className="flex flex-1 bg-red-100 mt-4 rounded-md py-4 px-8">
                                    <ul className="list-disc">
                                        <li className="text-xs text-red-500 font-bold ">
                                            {this.state.item.error_name_message}
                                        </li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex flex-row">
                    <div className="flex flex-1 flex-row">
                        {
                            this.state.item.status && this.state.item.status.name === "Active" &&
                            <button onClick={() => {
                                this.functions.updateStatus(this.state.item, "paused");
                            }}
                                className={"bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Pause adgroup</span>
                            </button>
                        }
                        {
                            this.state.item.status && this.state.item.status.name === "Paused" &&
                            <button onClick={() => {
                                this.functions.updateStatus(this.state.item, "active");
                            }}
                                className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Activate adgroup</span>
                            </button>
                        }
                        <button onClick={() => {
                            this.setState({
                                remove_modal: true
                            })
                        }}
                            className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                            <span>Delete adgroup</span>
                        </button>
                    </div>
                    <div className="justify-end">
                        <button onClick={() => {
                            this.functions.save(this.state.item);
                        }}
                            className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                            <span>Save changes</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

class EditAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            campaign: {},
            data: {},
            internal_campaign: "",
            call_to_actions: callToAction,
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item,
            loading: this.props.loading,
            internal_campaign: this.props.internalCampaign
        }, () => {
            this.setState({
                channel: this.state.item.channel
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            loading: nextProps.loading,
            internal_campaign: nextProps.internalCampaign,
        })
    }

    functions = {
        save: () => {
            if (this.state.item.name !== this.state.item.init_name) {
                this.functions.updateName(this.state.item);
            }
        },
        updateStatus: (item, status) => {
            if (!item.loading_status) {
                item.loading_status = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateAd({
                        update_status: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        ad: item.id,
                        channel: item.channel,
                        status: status
                    }).then((response) => {
                        item.loading_status = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        item.status = this.renders.status(item.channel, response.data);
                        this.props.update(item);
                    }, (error) => {
                        item.loading_status = false;
                        item.error_status = true;
                        item.error_status_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        updateName: (item) => {
            if (!item.loading_name) {
                item.loading_name = true;
                this.setState({
                    item: item
                }, () => {
                    calls.updateAd({
                        update_name: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        ad: item.id,
                        channel: item.channel,
                        name: item.name
                    }).then((response) => {
                        item.loading_name = false;
                        item.error_name = false;
                        item.error_name_message = "";
                        item.init_name = JSON.parse(JSON.stringify(item.name));
                        this.props.update(item);
                    }, (error) => {
                        item.loading_name = false;
                        item.error_name = true;
                        item.error_name_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
        remove: (item) => {
            if (!item.loading_remove) {
                item.loading_remove = true;
                this.setState({
                    item: item
                }, () => {
                    calls.removeAd({
                        loading_remove: true,
                        id: this.state.internal_campaign,
                        campaign: item.internal_id,
                        ad: item.id,
                        channel: item.channel,
                    }).then((response) => {
                        item.loading_remove = false;
                        item.error_date = false;
                        item.error_date_message = "";
                        this.props.remove(item);
                    }, (error) => {
                        item.loading_remove = false;
                        item.error_remove = true;
                        item.error_remove_message = typeof error.body == "object" ? error.body.message : "";
                        this.setState({
                            item: item
                        })
                    });
                })
            }
        },
    };

    renders = {
        status: (channel, external_status) => {
            try {
                let status = {};
                if (channel === "facebook" || channel === "instagram") {
                    if (external_status === "ACTIVE") {
                        status = { name: "Active", value: "ACTIVE" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                } else if (channel === "google") {
                    if (external_status === "ENABLED") {
                        status = { name: "Active", value: "ENABLED" };
                    }
                    if (external_status === "PAUSED") {
                        status = { name: "Paused", value: "PAUSED" };
                    }
                }
                return status;
            } catch (e) {
                return {}
            }
        },
        object: (item) => {
            try {
                if (item === "title") {
                    return this.state.item.preview.title
                } else if (item === "body") {
                    return this.state.item.preview.body
                } else if (item === "description") {
                    return this.state.item.preview.object_story_spec.link_data.description;
                } else if (item === "image") {
                    return this.state.item.preview.image_url
                } else if (item === "image_ratio") {
                    return this.state.item.preview.image_ratio
                } else if (item === "page_name") {
                    return this.state.item.preview.page.name
                } else if (item === "page_image") {
                    return this.state.item.preview.page.image
                } else if (item === "display_url") {
                    return this.state.item.preview.object_story_spec.link_data.caption;
                } else if (item === "website") {
                    return this.state.item.preview.object_story_spec.link_data.link;
                } else if (item === "video_website") {
                    return this.state.item.preview.object_story_spec.video_data.call_to_action.value.link;
                } else if (item === "video_description") {
                    return this.state.item.preview.object_story_spec.video_data.link_description;
                } else if (item === "video_display_url") {
                    return this.state.item.preview.object_story_spec.video_data.call_to_action.value.link_caption;
                } else if (item === "video_thumbnail") {
                    return this.state.item.preview.object_story_spec.video_data.image_url;
                } else if (item === "call_to_action") {
                    let value = {};
                    if (this.state.item.preview.call_to_action_type && !this.state.item.preview.call_to_action) {
                        this.state.call_to_actions.map((button) => {
                            if (this.state.item.preview.call_to_action_type === button.value) {
                                value = button;
                            }
                        })
                    } else if (this.state.item.preview.call_to_action) {
                        value = this.state.item.preview.call_to_action;
                    }
                    return value;
                } else if (item === "items") {
                    return this.state.item.preview.object_story_spec.link_data.child_attachments.map((slide) => {
                        let new_slide = {
                            headline: slide.name,
                            description: slide.description,
                            creative: {
                                url: slide.picture,
                                ratio: "1:1",
                                selected: true,
                                thumbnail: { url: slide.picture }
                            },
                            image: slide.video_id ? false : true,
                            video: slide.video_id ? true : false,
                        };
                        if (slide.call_to_action) {
                            this.state.call_to_actions.map((button) => {
                                if (slide.call_to_action.type === button.value) {
                                    new_slide.call_to_action = button;
                                }
                            })
                        }
                        return new_slide;
                    })
                }

            } catch (e) {
                console.log(e);
                if (item === "call_to_action") {
                    return {}
                } else if ("items") {
                    return []
                } else {
                    return ""
                }
            }
        },
        facebookPreivewType: () => {
            let type = "image";
            try {
                if (Array.isArray(this.state.item.preview.object_story_spec.link_data.child_attachments)) {
                    type = "carousel"
                }
            } catch (e) {
            }
            try {
                if (this.state.item.preview.object_story_spec.video_data) {
                    type = "video"
                }
            } catch (e) {
            }
            return type;
        }
    };

    render() {
        return (
            <div className="relative w-full">
                {
                    (this.state.item.loading_status || this.state.item.loading_remove) &&
                    <div
                        className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#060534'}
                            loading={true}
                        />
                    </div>
                }
                <div className="flex flex-1 flex-col rounded-md px-4 py-4 bg-gray-100">
                    <div className="flex flex-1 flex-row">
                        {
                            !this.state.loading &&
                            <div className="flex flex-1 mr-2 bg-white rounded-md px-3 py-6 flex-col">
                                {
                                    this.state.item.channel === "facebook" &&
                                    <div className="flex flex-row mb-6">
                                        {
                                            ["facebook", "instagram"].map((key) => {
                                                return (
                                                    <div onClick={() => {
                                                        this.setState({
                                                            channel: key
                                                        })
                                                    }}>
                                                        {key === 'instagram' &&
                                                            <div
                                                                className={`mr-2 cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-instagram-100 text-instagram-500 border-instagram-500' : ''}`}>
                                                                <IconInstagram /> <span className="ml-1">{key}</span>
                                                            </div>
                                                        }
                                                        {key === 'facebook' &&
                                                            <div
                                                                className={`mr-2 cursor-pointer transition-all duration-200 hover:bg-gray-300 border-2 border-transparent py-2 px-4 rounded-full text-sm bg-gray-200 capitalize font-bold flex items-center justify-center ${key === this.state.channel ? 'bg-facebook-100 text-facebook-500 border-facebook-500' : ''}`}>
                                                                <IconFacebook /> <span className="ml-1">{key}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="flex flex-1">

                                        </div>
                                        <div onClick={() => {
                                            if (this.state.channel === "instagram") {
                                                window.open(this.state.item.preview.instagram_permalink_url, "_blank")
                                            } else if (this.state.channel === "facebook") {
                                                window.open("https://fb.com/" + this.state.item.preview.effective_object_story_id, "_blank")
                                            }
                                        }} style={{ height: "41px", width: "41px" }}
                                            className="cursor-pointer rounded-full bg-gray-500 text-white flex justify-center align-middle items-center">
                                            <FeatherIcon className="stroke-current" size={16} icon="link" />
                                        </div>
                                    </div>
                                }
                                <div className="justify-center items-center align-middle flex flex-1">
                                    {
                                        this.state.item.channel === "google" && !this.state.loading &&
                                        <PreviewGoogleAd
                                            item={this.state.item.preview}
                                        />
                                    }
                                    {
                                        (this.renders.object("image_ratio") === "9:16") && this.renders.facebookPreivewType() === "image" && (this.state.channel === "facebook" || this.state.channel === "instagram") && !this.state.loading &&
                                        <AdvertisingCreativesVertical
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            white={true}
                                            creatives={[
                                                {
                                                    headline: this.renders.object("title"),
                                                    body: this.renders.object("body"),
                                                    description: this.renders.object("description"),
                                                    creative: { url: this.renders.object("image") },
                                                    display_website: this.renders.object("display_url"),
                                                    call_to_action: this.renders.object("call_to_action")
                                                }
                                            ]}
                                            noContainer={true}
                                        />
                                    }
                                    {
                                        this.renders.object("image_ratio") !== "9:16" && this.renders.facebookPreivewType() === "image" && this.state.channel === "instagram" && !this.state.loading &&
                                        <AdvertisingCreativesInstagramImage
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            creatives={[
                                                {
                                                    headline: this.renders.object("title"),
                                                    body: this.renders.object("body"),
                                                    description: this.renders.object("description"),
                                                    creative: { url: this.renders.object("image") },
                                                    display_website: this.renders.object("display_url"),
                                                    call_to_action: this.renders.object("call_to_action")
                                                }
                                            ]}
                                            noContainer={true}
                                        />
                                    }
                                    {
                                        this.renders.object("image_ratio") !== "9:16" && this.renders.facebookPreivewType() === "image" && this.state.channel === "facebook" && !this.state.loading &&
                                        <AdvertisingCreativesFacebookImage
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            white={true}
                                            creatives={[
                                                {
                                                    headline: this.renders.object("title"),
                                                    body: this.renders.object("body"),
                                                    description: this.renders.object("description"),
                                                    creative: { url: this.renders.object("image") },
                                                    display_website: this.renders.object("display_url"),
                                                    call_to_action: this.renders.object("call_to_action")
                                                }
                                            ]}
                                            noContainer={true}
                                        />
                                    }
                                    {
                                        this.renders.facebookPreivewType() === "video" && this.state.channel === "instagram" && !this.state.loading &&
                                        <AdvertisingCreativesInstagramVideo
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            creatives={[
                                                {
                                                    headline: this.renders.object("title"),
                                                    body: this.renders.object("body"),
                                                    description: this.renders.object("video_description"),
                                                    creative: {
                                                        ratio: "1:1",
                                                        url: "",
                                                        thumbnail: { url: this.renders.object("video_thumbnail") }
                                                    },
                                                    display_website: this.renders.object("video_display_url"),
                                                    call_to_action: this.renders.object("call_to_action")
                                                }
                                            ]}
                                            noContainer={true}
                                        />
                                    }
                                    {
                                        this.renders.facebookPreivewType() === "video" && this.state.channel === "facebook" && !this.state.loading &&
                                        <AdvertisingCreativesFacebookVideo
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            white={true}
                                            creatives={[
                                                {
                                                    headline: this.renders.object("title"),
                                                    body: this.renders.object("body"),
                                                    description: this.renders.object("video_description"),
                                                    creative: {
                                                        ratio: "1:1",
                                                        url: "",
                                                        thumbnail: { url: this.renders.object("video_thumbnail") }
                                                    },
                                                    display_website: this.renders.object("video_display_url"),
                                                    call_to_action: this.renders.object("call_to_action")
                                                }
                                            ]}
                                            noContainer={true}
                                        />
                                    }
                                    {
                                        this.renders.facebookPreivewType() === "carousel" && this.state.channel === "instagram" && !this.state.loading &&
                                        <AdvertisingCreativesInstagramCarousel
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            body={{
                                                body: this.renders.object("body"),
                                                items: this.renders.object("items")
                                            }}
                                            content={{
                                                body: this.renders.object("body"),
                                                items: this.renders.object("items")
                                            }}
                                            noContainer={true}
                                            showArrows={false}
                                            onChange={(value) => {

                                            }}
                                        />
                                    }
                                    {
                                        this.renders.facebookPreivewType() === "carousel" && this.state.channel === "facebook" && !this.state.loading &&
                                        <AdvertisingCreativesFacebookCarousel
                                            page={{
                                                name: this.renders.object("page_name"),
                                                image: this.renders.object("page_image")
                                            }}
                                            white={true}
                                            content={{
                                                body: this.renders.object("body"),
                                                items: this.renders.object("items")
                                            }}
                                            noContainer={true}
                                            onChange={() => {

                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.item.channel === "google" && !this.state.loading &&
                            <div className="flex flex-1 ml-2">
                                <EditGoogleAd
                                    item={this.state.item}
                                />
                            </div>
                        }
                        {
                            this.state.item.channel === "facebook" && !this.state.loading &&
                            <div className="flex flex-1 ml-2">
                                <EditFacebookAd
                                    type={this.renders.facebookPreivewType()}
                                    item={this.state.item}
                                />
                            </div>
                        }
                        {
                            this.state.loading &&
                            <div className="flex justify-center items-center align-middle h-32 w-full">
                                <BeatLoader sizeUnit={'px'} size={24} color={'#060534'} loading={true} />
                            </div>
                        }
                    </div>
                </div>
                {
                    !this.state.loading &&
                    <div className="mt-4 flex flex-row">
                        <div className="flex flex-1 flex-row">
                            {
                                this.state.item.status && this.state.item.status.name === "Active" &&
                                <button onClick={() => {
                                    this.functions.updateStatus(this.state.item, "paused");
                                }}
                                    className={"bg-orange-200 bg-opacity-25 text-orange-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-orange-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                    <span>Pause ad</span>
                                </button>
                            }
                            {
                                this.state.item.status && this.state.item.status.name === "Paused" &&
                                <button onClick={() => {
                                    this.functions.updateStatus(this.state.item, "active");
                                }}
                                    className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                    <span>Activate ad</span>
                                </button>
                            }
                            <button onClick={() => {
                                this.functions.remove(this.state.item);
                            }}
                                className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white mr-2"}>
                                <span>Delete ad</span>
                            </button>
                        </div>
                        <div className="justify-end">
                            <button onClick={() => {
                                this.functions.save();
                            }}
                                className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                                <span>Save changes</span>
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

class Donuts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            campaign: {},
            data: {}
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item
        })
    }

    renders = {
        donut: (item) => {
            let donut_data = {};
            let channels = {
                facebook: item.channel === "facebook",
                instagram: (item.channel === "instagram" || item.instagram),
                google: item.channel === "google",
                linkedin: item.channel === "linkedin"
            };
            for (let channel in channels) {
                if (!channels[channel]) {
                    delete channels[channel];
                } else {
                    donut_data[channel] = {
                        click: 0,
                        impressions: 0,
                        ctr: 0,
                        cpc: 0,
                        spend: 0
                    };
                    if (channel === "facebook") {
                        if (Array.isArray(item.insights)) {
                            item.insights.map((item) => {
                                donut_data[channel].click += +item.inline_link_clicks ? +item.inline_link_clicks : 0;
                                donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                donut_data[channel].ctr += +item.inline_link_click_ctr ? +item.inline_link_click_ctr : 0;
                                donut_data[channel].cpc += +item.cpc ? +item.cpc : 0;
                                donut_data[channel].spend += +item.spend ? +item.spend : 0;
                            });
                        }
                    } else if (channel === "google") {
                        if (Array.isArray(item.insights)) {
                            item.insights.map((item) => {
                                donut_data[channel].click += +item.clicks ? +item.clicks : 0;
                                donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                donut_data[channel].ctr += +item.ctr ? +(item.ctr * 100) : 0;
                                donut_data[channel].cpc += +item.average_cpc ? +(item.average_cpc / 1000000) : 0;
                                donut_data[channel].spend += +item.cost_micros ? +(item.cost_micros / 1000000) : 0;

                            });
                        } else {
                            donut_data[channel].click += +item.insights.clicks ? +item.insights.clicks : 0;
                            donut_data[channel].impressions += +item.insights.impressions ? +item.insights.impressions : 0;
                            donut_data[channel].ctr += +item.insights.ctr ? +(item.insights.ctr * 100) : 0;
                            donut_data[channel].cpc += +item.insights.average_cpc ? +(item.insights.average_cpc / 1000000) : 0;
                            donut_data[channel].spend += +item.insights.cost_micros ? +(item.insights.cost_micros / 1000000) : 0;
                        }
                    } else if ("linkedin") {
                        if (Array.isArray(item.insights)) {
                            item.insights.map((item) => {
                                donut_data[channel].click += +item.clicks ? +item.clicks : 0;
                                donut_data[channel].impressions += +item.impressions ? +item.impressions : 0;
                                donut_data[channel].spend += +item.costInLocalCurrency ? +(item.costInLocalCurrency) : 0;
                                donut_data[channel].ctr += (donut_data[channel].click / donut_data[channel].impressions);
                                donut_data[channel].cpc += (donut_data[channel].spend / donut_data[channel].click);
                            });
                        }
                    }
                }
            }
            return donut_data;
        }
    };

    render() {
        return (
            <CampaignDonuts2
                data={this.renders.donut(this.state.item)}
                properties={['click', 'impressions', 'ctr', 'spend', 'cpc']}
                showchannels={false}
                currency={this.state.item.currency}
            />
        )
    }

}

class EditFacebookAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            type: "",
            call_to_actions: callToAction
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item,
            type: this.props.type
        });
    }

    functions = {};

    renders = {
        object: (item) => {
            try {
                if (item === "title") {
                    return this.state.item.preview.title
                } else if (item === "body") {
                    return this.state.item.preview.body
                } else if (item === "description") {
                    return this.state.item.preview.object_story_spec.link_data.description;
                } else if (item === "image") {
                    return this.state.item.preview.image_url
                } else if (item === "page_name") {
                    return this.state.item.preview.page.name
                } else if (item === "page_image") {
                    return this.state.item.preview.page.image
                } else if (item === "display_url") {
                    return this.state.item.preview.object_story_spec.link_data.caption;
                } else if (item === "website") {
                    return this.state.item.preview.object_story_spec.link_data.link;
                } else if (item === "video_website") {
                    return this.state.item.preview.object_story_spec.video_data.call_to_action.value.link;
                } else if (item === "video_description") {
                    return this.state.item.preview.object_story_spec.video_data.link_description;
                } else if (item === "video_display_url") {
                    return this.state.item.preview.object_story_spec.video_data.call_to_action.value.link_caption;
                } else if (item === "video_thumbnail") {
                    return this.state.item.preview.object_story_spec.video_data.image_url;
                } else if (item === "call_to_action") {
                    let value = {};
                    if (this.state.item.preview.call_to_action_type && !this.state.item.preview.call_to_action) {
                        this.state.call_to_actions.map((button) => {
                            if (this.state.item.preview.call_to_action_type === button.value) {
                                value = button;
                            }
                        })
                    } else if (this.state.item.preview.call_to_action) {
                        value = this.state.item.preview.call_to_action;
                    }
                    return value;
                }
            } catch (e) {
                if (item === "call_to_action") {
                    return {}
                } else {
                    return ""
                }
            }
        }
    };

    render() {
        return (
            <div className="flex flex-1 ml-2 flex-col">
                <Input
                    noBorder={true}
                    title={"Name"}
                    type={'text'}
                    white={true}
                    placeholder={'Name'}
                    value={this.state.item.name}
                    size={'full'}
                    onChange={(event) => {
                        this.state.item.name = event.target.value;
                        this.setState({
                            data: this.state.data
                        })
                    }}
                />
                {
                    this.state.type !== "carousel" &&
                    <div className="mt-4">
                        <Input
                            noBorder={true}
                            title={"Headline text"}
                            type={'text'}
                            white={true}
                            disabled={true}
                            placeholder={'Headline'}
                            value={this.renders.object("title")}
                            size={'full'}
                            onChange={(event) => {

                            }}
                        />
                    </div>
                }
                <div>
                    <div className="flex flex-1 flex-row mt-4">
                        <div className={
                            cx("flex flex-1",
                                {
                                    ["mr-2"]: this.state.type !== "carousel",
                                })
                        }>
                            <TextArea
                                maxLength={125}
                                type={'text'}
                                tiny={false}
                                noBorder={true}
                                disabled={true}
                                white={true}
                                title={"Body text"}
                                placeholder={'Max length 125'}
                                value={this.renders.object("body")}
                                size={'full'}
                                onChange={(event) => {

                                }}
                            />
                        </div>
                        {
                            this.state.type !== "carousel" &&
                            <div className="flex flex-1 ml-2">
                                <TextArea
                                    maxLength={125}
                                    type={'text'}
                                    tiny={false}
                                    noBorder={true}
                                    white={true}
                                    disabled={true}
                                    title={"Body text"}
                                    placeholder={'Max length 125'}
                                    value={this.state.type === "video" ? this.renders.object("video_description") : this.renders.object("description")}
                                    size={'full'}
                                    onChange={(event) => {

                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-4">
                    <Input
                        noBorder={true}
                        title={"Website URL"}
                        type={'text'}
                        white={true}
                        placeholder={'Website'}
                        disabled={true}
                        value={this.state.type === "video" ? this.renders.object("video_website") : this.renders.object("website")}
                        size={'full'}
                        onChange={(event) => {

                        }}
                    />
                </div>
                {
                    this.state.type !== "carousel" &&
                    <div className="mt-4">
                        <Input
                            noBorder={true}
                            title={"Display URL"}
                            type={'text'}
                            white={true}
                            placeholder={'Display'}
                            disabled={true}
                            value={this.state.type === "video" ? this.renders.object("video_display_url") : this.renders.object("display_url")}
                            size={'full'}
                            onChange={(event) => {

                            }}
                        />
                    </div>
                }
                {
                    this.state.type !== "carousel" &&
                    <div className="mt-4">
                        <Dropdown
                            filter={false}
                            noBorder={true}
                            white={true}
                            title={'Call to action button'}
                            size={'full'}
                            disabled={true}
                            placeholder={"Call to action"}
                            options={this.state.call_to_actions}
                            value={this.renders.object("call_to_action")}
                            onChange={(value) => {

                            }}
                        />
                    </div>
                }
            </div>
        )
    }

}

class EditGoogleAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        });
    }

    functions = {};

    renders = {
        object: (item) => {
            try {
                if (item === "headline_1") {
                    return this.state.item.preview.headline_part1
                } else if (item === "headline_2") {
                    return this.state.item.preview.headline_part2
                } else if (item === "headline_3") {
                    return this.state.item.preview.headline_part3
                } else if (item === "description") {
                    return this.state.item.preview.description
                } else if (item === "description2") {
                    return this.state.item.preview.description2
                }
            } catch (e) {
                return ""
            }
        }
    };

    render() {
        return (
            <div className="flex flex-1 flex-col">
                <div className="flex flex-1">
                    <Input
                        noBorder={true}
                        title={"Name"}
                        type={'text'}
                        white={true}
                        disabled={true}
                        placeholder={'Name'}
                        value={this.state.item.name}
                        size={'full'}
                        onChange={(event) => {
                            this.state.item.name = event.target.value;
                            this.setState({
                                data: this.state.data
                            })
                        }}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        noBorder={true}
                        title={"Headline 1"}
                        type={'text'}
                        white={true}
                        placeholder={'Headline'}
                        value={this.renders.object("headline_1")}
                        size={'full'}
                        onChange={(event) => {

                        }}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        noBorder={true}
                        title={"Headline 2"}
                        type={'text'}
                        white={true}
                        placeholder={'Headline 2'}
                        value={this.renders.object("headline_2")}
                        size={'full'}
                        onChange={(event) => {

                        }}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        noBorder={true}
                        title={"Headline 3"}
                        type={'text'}
                        white={true}
                        placeholder={'Headline 3'}
                        value={this.renders.object("headline_3")}
                        size={'full'}
                        onChange={(event) => {

                        }}
                    />
                </div>
                <div className="flex flex-1 flex-row mt-4">
                    <div className="flex flex-1 mr-2">
                        <TextArea
                            maxLength={90}
                            type={'text'}
                            tiny={false}
                            noBorder={true}
                            disabled={true}
                            white={true}
                            title={"Body first"}
                            placeholder={'Max length 90'}
                            value={this.renders.object("description")}
                            size={'full'}
                            onChange={(event) => {

                            }}
                        />
                    </div>
                    <div className="flex flex-1 ml-2">
                        <TextArea
                            maxLength={90}
                            type={'text'}
                            tiny={false}
                            noBorder={true}
                            white={true}
                            title={"Body second"}
                            placeholder={'Max length 90'}
                            value={this.renders.object("description2")}
                            size={'full'}
                            onChange={(event) => {

                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

class PreviewGoogleAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item ? this.props.item : {}
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        })
    }

    functions = {};

    renders = {
        headline: () => {
            let headline = "";
            if (this.state.item.headline_part1 && this.state.item.headline_part1 != "") {
                headline = headline + this.state.item.headline_part1;
            }
            if (this.state.item.headline_part2 && this.state.item.headline_part2 != "") {
                headline = headline + " | " + this.state.item.headline_part2;
            }
            if (this.state.item.headline_part3 && this.state.item.headline_part3 != "") {
                headline = headline + " | " + this.state.item.headline_part3;
            }
            return headline;
        },
        url: () => {
            let url = "";
            try {
                if (Array.isArray(this.state.item.final_urls) && this.state.item.final_urls.length > 0) {
                    url = this.state.item.final_urls[0];
                }

                if ((this.state.item.path1 && this.state.item.path1 !== "") || (this.state.item.path2 && this.state.item.path2 !== "")) {
                    url = url.replace('https://', "");
                    url = url.replace('http://', "");
                    url = url.split('/')[0];
                    url = 'https://' + url;
                    if (this.state.item.path1 && this.state.item.path1 !== "") {
                        url = url + '/' + this.state.item.path1;
                    }
                    if (this.state.item.path2 && this.state.item.path2 !== "") {
                        url = url + '/' + this.state.item.path2;
                    }
                }

            } catch (e) {
            }
            return url;
        }
    };

    render() {
        return (
            <>
                <div style={{ minWidth: "100%" }} className="preview__block--wrapper flex-1">
                    <div>
                        <div style={{ maxWidth: "650px" }} className="border rounded-md p-4">
                            <div className="flex flex-row items-center text-xs text-gray-800 mb-3">
                                <div className="font-semibold">
                                    Ad
                                </div>
                                <div className="mx-1">&bull;</div>
                                <div className="">
                                    {this.renders.url()}
                                </div>
                                {
                                    typeof this.state.item.phone === "string" &&
                                    <div className="ml-3 font-bold">
                                        {this.state.item.phone}
                                    </div>
                                }
                            </div>
                            <div className="text-google-500 font-semibold">
                                {this.renders.headline()}
                            </div>
                            <div className="text-gray-800 text-sm">
                                {this.state.item.description}{" "}{this.state.item.description2}
                            </div>
                            {
                                Array.isArray(this.state.item.site_links) &&
                                <div className="flex flex-1 flex-row flex-wrap px-4 mt-2">
                                    {
                                        this.state.item.site_links.map((item) => {
                                            return (
                                                <div className="w-3/6 pr-2">
                                                    <div className="text-google-500 font-semibold mt-2">
                                                        {item.name}
                                                    </div>
                                                    {
                                                        item.description !== "" &&
                                                        <div className="text-xs">
                                                            {item.description}
                                                        </div>
                                                    }
                                                    {
                                                        item.description2 !== "" &&
                                                        <div className="text-xs">
                                                            {item.description2}
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

class AddKeyword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            suffix: "",
            prefix: "",
            keyword: "",
            url: "",
            type: { name: "Exact", value: 'exact' },
            campaigns: []
        };
    };

    componentDidMount() {
        this.setState({
            data: this.props.data ? this.props.data : {},
            internal_campaign: this.props.internalCampaign
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data ? nextProps.data : {},
            internal_campaign: nextProps.internalCampaign
        })
    }

    functions = {
        add: () => {
            if (!this.state.loading && this.state.keyword !== "" && this.renders.isUrlValid(this.state.url) && this.renders.campaigns().filter((item) => { return item.dont_add_keyword }).length < 1) {
                this.setState({
                    loading: true,
                    error: false
                }, () => {
                    calls.addKeyword({
                        internal_id: this.state.internal_campaign,
                        positive: true,
                        external_campaigns: this.renders.campaigns().filter((item) => { return !item.dont_add_keyword }).map((item) => { return item.id }),
                        keyword: this.state.keyword,
                        suffix: this.state.suffix,
                        prefix: this.state.prefix,
                        url: this.state.url,
                        type: this.state.type.value,
                        skip_insert_keyword: this.state.skip_insert_keyword
                    }).then((response) => {
                        this.setState({
                            loading: false,
                            external_campaigns: [],
                            keyword: "",
                            suffix: "",
                            prefix: "",
                            url: this.state.url,
                            skip_insert_keyword: this.state.skip_insert_keyword
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    })
                })
            } else {
                this.setState({
                    error: true
                })
            }
        }
    };

    renders = {
        characters: () => {
            try {
                let prefix = this.state.prefix ? this.state.prefix.length : 0;
                let keyword = this.state.keyword.length;
                let suffix = this.state.suffix ? this.state.suffix.length : 0;
                return prefix + keyword + suffix;
            } catch (e) {
                return 0
            }
        },
        isUrlValid: (userInput) => {
            var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (res == null)
                return false;
            else
                return true;
        },
        campaigns: () => {
            let campaigns = [];
            try {
                for (let key in this.state.data.channels.google.campaigns) {
                    campaigns.push(this.state.data.channels.google.campaigns[key]);
                }
            } catch (e) { }
            return campaigns;
        }
    };

    render() {
        return (
            <>
                <div className="bg-white rounded-md shadow-xl p-4 mt-8 relative">
                    {
                        this.state.loading &&
                        <div className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    }
                    <div className="flex flex-1 flex-col">
                        <h3 className="mb-0 font-bold">
                            Add keyword
                        </h3>
                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                            Avg. number of search per month in parantheses
                        </p>
                    </div>
                    <div className="bg-gray-100 rounded-md p-4 mt-4">
                        <div className="w-full">
                            <div className="flex flex-col">
                                <div className="text-xs mb-1 font-bold">
                                    Add keyword to these campaigns
                                </div>
                                {
                                    this.renders.campaigns().map((item) => {
                                        return (
                                            <div className="bg-white rounded w-full px-4">
                                                <div className="Variations-ItemLine">
                                                    <div className="text-xs mb-1 font-bold">
                                                        {item.name}
                                                    </div>
                                                    <div className="flex flex-1"></div>
                                                    <Switch
                                                        onColor="#24bda8"
                                                        checked={!item.dont_add_keyword}
                                                        onChange={(checked) => {
                                                            item.dont_add_keyword = !checked;
                                                            this.setState({
                                                                data: this.state.data
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.error && this.renders.campaigns().filter((item) => { return item.dont_add_keyword }).length > 0 &&
                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                    <ul className="list-disc">
                                        <li className="text-xs text-red-500 font-bold ">
                                            Select one campaign
                                        </li>
                                    </ul>
                                </div>
                            }
                            <div className="mt-4">
                                <Dropdown
                                    filter={false}
                                    noTitle={false}
                                    noBorder={true}
                                    white={true}
                                    size={'full'}
                                    title={"Select Type"}
                                    options={[
                                        { name: "Exact", value: 'exact' },
                                        { name: "Phrase", value: 'phrase' },
                                        { name: "Broad", value: 'broad' }
                                    ]}
                                    value={this.state.type}
                                    onChange={(value) => {
                                        this.setState({
                                            type: value
                                        })
                                    }}
                                />
                            </div>
                            <div className="flex flex-row mt-4">
                                {
                                    !this.state.skip_insert_keyword &&
                                    <div className="flex-1 flex mr-4">
                                        <Input
                                            title={'Prefix'}
                                            sign={true}
                                            signTitle={this.renders.characters() + ' / 29'}
                                            maxLength={29}
                                            value={this.state.prefix}
                                            size={'full'}
                                            type={"text"}
                                            noBorder={true}
                                            white={true}
                                            onChange={(value) => {
                                                let new_value = this.state.keyword.length + this.state.suffix.length + value.target.value.length;
                                                if (new_value <= 29) {
                                                    this.setState({
                                                        prefix: value.target.value
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                <div className="flex-1 flex flex-col">
                                    <Input
                                        title={'Keyword'}
                                        value={this.state.keyword}
                                        disabled={false}
                                        size={'full'}
                                        error={this.state.keyword === "" && this.state.error}
                                        type={"text"}
                                        noBorder={true}
                                        white={true}
                                        onChange={(value) => {
                                            this.setState({
                                                keyword: value.target.value
                                            })
                                        }}
                                    />
                                    {
                                        this.state.error && this.state.keyword === "" &&
                                        <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                            <ul className="list-disc">
                                                <li className="text-xs text-red-500 font-bold ">
                                                    Invalid keyword
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                                {
                                    !this.state.skip_insert_keyword &&
                                    <div className="flex-1 flex ml-4">
                                        <Input
                                            title={'Sufix'}
                                            sign={true}
                                            signTitle={this.renders.characters() + ' / 29'}
                                            maxLength={29}
                                            value={this.state.suffix}
                                            size={'full'}
                                            type={"text"}
                                            noBorder={true}
                                            white={true}
                                            onChange={(value) => {
                                                let new_value = this.state.keyword.length + this.state.prefix.length + value.target.value.length;
                                                if (new_value <= 29) {
                                                    this.state.suffix = value.target.value;
                                                    this.setState({
                                                        suffix: value.target.value
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex flex-1 mt-4 flex-col">
                                <Input
                                    title={'Website'}
                                    value={this.state.url}
                                    noBorder={true}
                                    error={!this.renders.isUrlValid(this.state.url) && this.state.error}
                                    white={true}
                                    size={'full'}
                                    type={"text"}
                                    onChange={(value) => {
                                        this.state.url = value.target.value;
                                        this.setState({
                                            url: value.target.value
                                        })
                                    }}
                                />
                                {
                                    this.state.error && !this.renders.isUrlValid(this.state.url) &&
                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ul className="list-disc">
                                            <li className="text-xs text-red-500 font-bold ">
                                                Invalid keyword
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            {
                                false &&
                                <div className="KeywordsPositive-container-right-item">
                                    <Input
                                        title={'Max CPC'}
                                        value={this.state.keyword.cpc}
                                        disabled={false}
                                        size={'full'}
                                        type={"number"}
                                        tight={true}
                                        white={true}
                                        onChange={(value) => {
                                            this.state.keyword.cpc = value.target.value;
                                            this.setState({
                                                keyword: this.state.keyword
                                            }, () => {
                                                this.props.onChange(this.state.keyword);
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="flex flex-col mt-4">
                                <div className="text-xs mb-1 font-bold">
                                    Keyword
                                </div>
                                <div className="bg-white rounded w-full px-4">
                                    <div className="Variations-ItemLine">
                                        <div className="text-xs mb-1 font-bold">
                                            Skip headline keyword
                                        </div>
                                        <div className="flex flex-1"></div>
                                        <Switch
                                            onColor="#24bda8"
                                            checked={this.state.skip_insert_keyword}
                                            onChange={(checked) => {
                                                this.setState({
                                                    skip_insert_keyword: checked
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-end flex-1">
                        <button onClick={() => {
                            this.functions.add();
                        }} className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-purple-500 text-white"}>
                            <span>Add keyword</span>
                        </button>
                    </div>
                </div>
            </>
        )
    }

}

class ImportCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: false,
            loading_import: false,
            channel: {},
            campaigns: {},
            internal_campaigns: [],
            internal_campaign: {},
            campaign: {},
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            data: this.props.data,
            internal_campaign_id: this.props.internalCampaign
        }, () => {
            //this.functions.internalCampaigns();
            this.functions.externalCampaigns();
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data,
            internal_campaign_id: nextProps.internalCampaign
        })
    }

    functions = {
        externalCampaigns: () => {
            if (Object.keys(this.state.campaigns).length < 1) {
                this.setState({
                    loading: true
                }, () => {
                    calls.getAllExternalCampaigns({ id: this.state.internal_campaign_id }).then((response) => {
                        this.setState({
                            loading: false,
                            campaigns: response.data
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                });
            }
        },
        internalCampaigns: () => {
            if (Array.isArray(this.state.internal_campaigns) && this.state.internal_campaigns.length < 1) {
                this.setState({
                    loading: true
                }, () => {
                    calls.getAllInternalCampaigns().then((response) => {
                        //console.log(response.data)
                        this.setState({
                            loading: false,
                            internal_campaigns: Array.isArray(response.data) ? response.data : []
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                });
            }
        },
        campaign: () => {
            this.setState({
                loading_campaign: true
            }, () => {
                calls.getCampaign({
                    id: this.state.internal_campaign.id
                }).then((response) => {
                    this.state.internal_campaign.data = response.data;
                    this.setState({
                        loading_campaign: false,
                        internal_campaign: this.state.internal_campaign
                    })
                }, (error) => {
                    this.setState({
                        loading_campaign: false
                    })
                });
            })
        },
        import: () => {
            if (!this.state.loading_import) {
                this.setState({
                    loading_import: true,
                    error: false
                }, () => {
                    calls.importCampaign({
                        campaign: this.state.internal_campaign_id,
                        external_campaign: this.state.campaign.id,
                        channel: this.state.channel.value
                    }).then((response) => {
                        this.setState({
                            loading_import: false,
                            channel: {},
                            campaign: {},
                            campaigns: {},
                        }, () => {
                            this.props.refresh();
                            /*
                            try {
                                let data = JSON.parse(JSON.stringify(this.state.data));
                                for (let channel in response.data.channels) {
                                    for (let campaign in response.data.channels[channel].campaigns) {
                                        if (!data.channels[channel]) {
                                            data.channels[channel] = {
                                                campaigns: {}
                                            };
                                            data.channels[channel].campaigns[campaign] = response.data.channels[channel].campaigns[campaign];
                                        } else {
                                            data.channels[channel].campaigns[campaign] = response.data.channels[channel].campaigns[campaign];
                                        }
                                    }
                                }
                                this.props.updateData(data);
                            } catch (e) {
                                console.log(e);
                            }
                            */
                        })
                    }, (error) => {
                        this.setState({
                            loading_import: false,
                            error: true
                        })
                    });
                })
            }
        },
        importBulk: () => {
            let selected = [];
            try {
                for (let channel in this.state.internal_campaign.data.channels) {
                    for (let campaign in this.state.internal_campaign.data.channels[channel].campaigns) {
                        this.state.internal_campaign.data.channels[channel].campaigns[campaign].channel = channel;
                        this.state.internal_campaign.data.channels[channel].campaigns[campaign].id = campaign;
                        this.state.internal_campaign.data.channels[channel].campaigns[campaign].importing = true;
                        if (
                            !this.state.internal_campaign.data.channels[channel].campaigns[campaign].selected &&
                            !this.state.internal_campaign.data.channels[channel].campaigns[campaign].success &&
                            !this.state.internal_campaign.data.channels[channel].campaigns[campaign].error
                        ) {
                            this.state.internal_campaign.data.channels[channel].campaigns[campaign].loading = true;
                            selected.push(this.state.internal_campaign.data.channels[channel].campaigns[campaign]);
                        }
                    }
                }
            } catch (e) {
            }
            this.setState({
                internal_campaign: this.state.internal_campaign
            }, () => {
                selected.map((campaign) => {
                    calls.importCampaign({
                        campaign: this.state.internal_campaign_id,
                        external_campaign: campaign.id,
                        channel: campaign.channel
                    }).then((response) => {
                        campaign.success = true;
                        campaign.loading = false;
                        this.setState({
                            internal_campaign: this.state.internal_campaign
                        }, () => {
                            try {
                                let data = JSON.parse(JSON.stringify(this.state.data));
                                for (let channel in response.data.channels) {
                                    for (let campaign in response.data.channels[channel].campaigns) {
                                        if (!data.channels[channel]) {
                                            data.channels[channel] = {
                                                campaigns: {}
                                            };
                                            data.channels[channel].campaigns[campaign] = response.data.channels[channel].campaigns[campaign];
                                        } else {
                                            data.channels[channel].campaigns[campaign] = response.data.channels[channel].campaigns[campaign];
                                        }
                                    }
                                }
                                this.props.updateData(data);
                            } catch (e) {
                                console.log(e);
                            }
                        });
                    }, (error) => {
                        campaign.success = false;
                        campaign.error = true;
                        campaign.loading = false;
                        this.setState({
                            internal_campaign: this.state.internal_campaign
                        })
                    });
                })
            })
        }
    };

    renders = {
        internalCampaginData: () => {
            let campaigns = [];
            try {
                for (let channel in this.state.internal_campaign.data.channels) {
                    for (let campaign in this.state.internal_campaign.data.channels[channel].campaigns) {
                        campaigns.push(this.state.internal_campaign.data.channels[channel].campaigns[campaign]);
                    }
                }
            } catch (e) {
            }
            return campaigns;
        },
        channels: () => {
            let channels = [];
            if (this.state.campaigns.facebook) {
                channels.push({ name: "Facebook", value: "facebook" });
            }
            if (this.state.campaigns.google) {
                channels.push({ name: "Google", value: "google" });
            }
            if (this.state.campaigns.linkedin) {
                channels.push({ name: "Linkedin", value: "linkedin" });
            }
            return channels;
        }
    };

    render() {
        return (
            <>
                <div className="bg-white relative">
                    {
                        this.state.loading_import &&
                        <div
                            className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        false &&
                        <div className="flex flex-1 flex-col">
                            <h3 className="mb-0 font-bold">
                                Import campaigns
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Import campaigns directly from external platforms or from internal campaigns
                            </p>
                        </div>
                    }
                    <div className="bg-gray-100 rounded-md p-4 mt-4">
                        {
                            false &&
                            <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                                <ul className="flex flex-row items-end">
                                    {[
                                        { id: 0, name: "External campaigns" },
                                        //{id: 1, name: "Internal campaigns"}
                                    ].map((item, j) => {
                                        return (
                                            <li key={j}
                                                className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                onClick={() => {
                                                    this.setState({
                                                        submenu: item.id
                                                    });
                                                }}>
                                                {item.name}
                                            </li>
                                        )
                                    })
                                    }
                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                </ul>
                            </nav>
                        }
                        {
                            this.state.submenu === 0 &&
                            <div className="">
                                <Dropdown
                                    filter={false}
                                    noBorder={true}
                                    white={true}
                                    title={'Select channel'}
                                    size={'full'}
                                    loading={this.state.loading}
                                    placeholder={this.state.loading ? "Loading campaigns ..." : "Click here ..."}
                                    options={this.renders.channels()}
                                    value={this.state.channel}
                                    onChange={(value) => {
                                        this.setState({
                                            channel: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.channel.value && this.state.submenu === 0 &&
                            <div className="mt-4">
                                <Dropdown
                                    filter={true}
                                    search={true}
                                    noBorder={true}
                                    white={true}
                                    title={'Select campaign'}
                                    size={'full'}
                                    loading={this.state.loading}
                                    placeholder={this.state.loading ? "Loading campaigns ..." : "Click here ..."}
                                    options={(this.state.channel.value && Array.isArray(this.state.campaigns[this.state.channel.value])) ? this.state.campaigns[this.state.channel.value] : []}
                                    value={this.state.campaign}
                                    onChange={(value) => {
                                        this.setState({
                                            campaign: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.submenu === 1 &&
                            <div className="mt-4">
                                <Dropdown
                                    filter={true}
                                    search={true}
                                    noBorder={true}
                                    white={true}
                                    title={'Select campaign'}
                                    size={'full'}
                                    disabled={(this.state.loading || this.state.loading_campaign || !Array.isArray(this.state.internal_campaigns))}
                                    loading={(this.state.loading || this.state.loading_campaign)}
                                    placeholder={(this.state.loading || this.state.loading_campaign) ? "Loading campaigns ..." : "Click here ..."}
                                    options={Array.isArray(this.state.internal_campaigns) ? this.state.internal_campaigns : []}
                                    value={this.state.internal_campaign ? this.state.internal_campaign : {}}
                                    onChange={(value) => {
                                        this.setState({
                                            internal_campaign: value
                                        }, () => {
                                            this.functions.campaign();
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.renders.internalCampaginData().length > 0 &&
                            this.state.submenu === 1 &&
                            <div className="font-bold text-xs mb-1 mt-4">
                                Select campaigns to import
                            </div>
                        }
                        {
                            this.renders.internalCampaginData().length > 0 &&
                            this.state.submenu === 1 &&
                            <div>
                                {
                                    this.state.internal_campaign.data &&
                                    this.state.internal_campaign.data.channels &&
                                    Object.keys(this.state.internal_campaign.data.channels).map((channel) => {
                                        return Object.keys(this.state.internal_campaign.data.channels[channel].campaigns).map((campaign) => {
                                            let internal_campaign = this.state.internal_campaign.data.channels[channel].campaigns[campaign];
                                            return (
                                                <div
                                                    className="flex flex-row flex-1 items-center mt-2 bg-white px-4 py-3 py-1 rounded-md relative">
                                                    <div className="text-sm font-bold">{internal_campaign.name}</div>
                                                    <div className="flex flex-1"></div>
                                                    {
                                                        !internal_campaign.success && !internal_campaign.error &&
                                                        <Switch
                                                            onColor="#1AD5BD"
                                                            checked={!internal_campaign.selected}
                                                            disabled={this.renders.internalCampaginData().filter((item) => {
                                                                return item.loading
                                                            }).length > 0}
                                                            onChange={(checked) => {
                                                                internal_campaign.selected = !checked;
                                                                this.setState({
                                                                    internal_campaign: this.state.internal_campaign
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        !internal_campaign.loading && internal_campaign.success &&
                                                        <div style={{ height: "30px" }}
                                                            className="flex justify-center align-middle items-center">
                                                            <FeatherIcon color={"#1AD5BD"} size={22} icon="check" />
                                                        </div>
                                                    }
                                                    {
                                                        !internal_campaign.loading && internal_campaign.error &&
                                                        <div style={{ height: "30px" }}
                                                            className="text-red-500 flex justify-center align-middle items-center">
                                                            <FeatherIcon className="stroke-current ml-2" size={20}
                                                                icon="alert-triangle" />
                                                        </div>
                                                    }
                                                    {
                                                        internal_campaign.loading &&
                                                        <div
                                                            className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                                                            <BeatLoader
                                                                sizeUnit={"px"}
                                                                size={10}
                                                                color={'#060534'}
                                                                loading={true}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.error &&
                            <div className="mt-4">
                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                    <ul className="list-decimal">
                                        <li className="text-xs text-red-500 font-bold ">
                                            Campaign is already importet
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="flex flex-1"></div>
                        {
                            this.renders.internalCampaginData().length > 0 &&
                            this.state.submenu === 1 && this.renders.internalCampaginData().filter((item) => {
                                return item.loading
                            }).length < 1 &&
                            <div className="mt-4">
                                <div className="">
                                    <button onClick={() => {
                                        this.functions.importBulk();
                                    }}
                                        className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white"}>
                                        <span>Import campaigns</span>
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            this.renders.internalCampaginData().length > 0 &&
                            this.state.submenu === 1 && this.renders.internalCampaginData().filter((item) => {
                                return item.loading
                            }).length < 1 &&
                            <div className="mt-4 ml-2">
                                <div className="">
                                    <button onClick={() => {
                                        this.setState({
                                            internal_campaign: {}
                                        });
                                    }}
                                        className={"bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white"}>
                                        <span>Reset selections</span>
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            this.state.campaign.name && this.state.submenu === 0 &&
                            <div className="mt-4">
                                <div className="">
                                    <button onClick={() => {
                                        this.functions.import();
                                    }}
                                        className={"bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green-500 hover:bg-opacity-100 hover:text-white"}>
                                        <span>Import campaign</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }

}

export default UserCampaignsItem;
