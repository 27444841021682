import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import Input from "../../components/input";
import AccordionStatic from "../../components/accordion-static";
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Client from "../../components/client";
import {calls} from "./calls";
import User from "../../components/user";
import Dropdown from "../../components/dropdown";

import UserTableRow from "../../modules/usertablerow"

class UserUsersAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            type: {},
            status: {},
            agency: {},
            agencies: [],
            client: {},
            clients: [],
            email: "",
            loading: true,
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.users();
        this.init.agencies();
        window.scrollTo(0, 0);
    }

    init = {
        users: () => {
            calls.getUsers().then((response) => {
                this.setState({
                    users: response.data,
                    loading: false
                })
            }, (error) => {});
        },
        agencies: () => {
            calls.getAgencies().then((response) => {
                response.data.push({name: "All agencies", value: 'none'});
                this.setState({
                    agencies: response.data,
                    loading: false
                })
            }, (error) => {});
        }
    };

    functions = {
        remove: (id) => {
            for(let i = 0; i < this.state.users.length; i++){
                if(this.state.users[i]._id === id){
                    this.state.users.splice(i, 1);
                    break;
                }
            }
            this.setState({
                users: this.state.users
            })
        }
    };

    renders = {};

    validators = {};

    render(){
        return (
            <section className="w-full pt-8">
                <div className="">
                    <div className="">
                        {
                            !this.state.loading &&
                            <div className="flex flex-row items-center">
                                <button onClick={() => {
                                    this.props.history.push('/user/users/new');
                                }} className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="plus"/> <span>Create User</span>
                                </button>
                            </div>
                        }
                        {
                            !this.state.loading && this.state.users.length > 0 &&
                            <AccordionStatic title={""}>
                                <div className="Section">
                                    <Dropdown
                                        filter={false}
                                        noTitle={true}
                                        size={'half'}
                                        placeholder={"Select Type"}
                                        error={this.state.error === "type" && !this.state.type.name}
                                        options={[
                                            {name: "Admin", value: 'admin'},
                                            {name: "Agent", value: 'agent'},
                                            {name: "All types", value: 'none'}
                                        ]}
                                        value={this.state.type}
                                        onChange={(value) => {
                                            if(value.value === 'none'){
                                                this.setState({type: {}});
                                            }else{
                                                this.setState({type: value});
                                            }
                                        }}
                                    />
                                    <Dropdown
                                        filter={false}
                                        noTitle={true}
                                        size={'half'}
                                        placeholder={"Select Status"}
                                        error={this.state.error === "status" && !this.state.status.name}
                                        options={[
                                            {name: "Pending", value: 'pending'},
                                            {name: "Active", value: 'active'},
                                            {name: "All stats", value: 'none'}
                                        ]}
                                        value={this.state.status}
                                        onChange={(value) => {
                                            if(value.value === 'none'){
                                                this.setState({status: {}});
                                            }else{
                                                this.setState({status: value});
                                            }
                                        }}
                                    />
                                </div>
                                <div className="Section">
                                    <Dropdown
                                        filter={false}
                                        noTitle={true}
                                        size={'half'}
                                        placeholder={"Select Agency"}
                                        error={this.state.error === "agency" && !this.state.agency.name}
                                        options={this.state.agencies}
                                        value={this.state.agency}
                                        onChange={(value) => {
                                            if(value.value === 'none'){
                                                this.setState({agency: {}});
                                            }else{
                                                this.setState({agency: value});
                                            }
                                        }}
                                    />
                                    <Dropdown
                                        filter={false}
                                        noTitle={true}
                                        size={'half'}
                                        placeholder={"Select Client"}
                                        options={this.state.clients}
                                        value={this.state.client}
                                        onChange={(value) => {
                                            if(value.value === 'none'){
                                                this.setState({client: {}});
                                            }else{
                                                this.setState({client: value});
                                            }
                                        }}
                                    />
                                </div>
                                <div className="Section">
                                    <Input
                                        noTitle={true}
                                        placeholder={"Search email"}
                                        size={'full'}
                                        value={this.state.email}
                                        type={"text"}
                                        onChange={(value) => this.setState({email: value.target.value}, () => {

                                        })}
                                    />
                                </div>
                            </AccordionStatic>
                        }
                        {
                            !this.state.loading &&
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                                <thead>
                                                    <tr>
                                                        <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Name
                                                        </th>
                                                        <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Type
                                                        </th>
                                                        <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Agency
                                                        </th>
                                                        <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Client
                                                        </th>
                                                        <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Status
                                                        </th>
                                                        <th className="px-6 py-3 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {
                                                        this.state.users.filter((item) => {
                                                            return this.state.status.value?(item.status === this.state.status.value):true;
                                                        }).filter((item) => {
                                                            return this.state.type.value?(item.type === this.state.type.value):true;
                                                        }).filter((item) => {
                                                            return this.state.agency.name?(item.agencyName === this.state.agency.name):true;
                                                        }).filter((item) => {
                                                            return item.email.indexOf(this.state.email) !== -1;
                                                        }).sort((a, b) => {
                                                            return  b.createdAt - a.createdAt;
                                                        }).map((item) => {
                                                            return (
                                                                <UserTableRow
                                                                    item={item}
                                                                    onRemove={(id) => {
                                                                        this.functions.remove(id);
                                                                    }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.loading &&
                            <div className="background-Loader">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={24}
                                    color={'#060534'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            !this.state.loading && this.state.users.length < 1 &&
                            <div className="background-Loader">
                                <div className="background-LoaderText">
                                    You dont have any users yet
                                </div>
                                <div onClick={() => this.props.history.push("/user/users/new")} className="background-LoaderButton">
                                    Lets get started
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default UserUsersAll;
