import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, AdjustmentsIcon, TrashIcon, UserGroupIcon, CogIcon, CalculatorIcon, IdentificationIcon, PauseIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import SlideDown from 'react-slidedown';
import AdvancedReportCellChart from './advancedReportCellChart';
import { io } from 'socket.io-client';
import SwitchTailwind from './switchTailwind';
import { Bar, Line } from 'react-chartjs-2';
import { List } from 'immutable';

//WEBSOCKET
const socket = io((window.location.hostname === 'localhost' ? "http://localhost:3000" : (window.location.hostname === 'app.adcredo.io' ? "https://api.adcredo.io" : "https://apidev.adcredo.io")), {
    autoConnect: false,
    auth: {
        token: tokenRegister.get()
    }
});
//WEBSOCKET END

class CampaignOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            loading: true,
            timeSpan: null,
            item: {},
            random_id: Math.floor(Math.random() * 10000000)
        };
    }

    async componentDidMount() {
        await this.promisedSetState({
            campaign: JSON.parse(JSON.stringify(this.props.campaign)),
            timeSpan: this.props.timeSpan
        });
        let self = this;
        socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err}`);
        });
        socket.on(this.state.random_id, async function (response, callback) {
            console.log(response);
            if (response.start) {

            }
            if (response.running) {
                if (Array.isArray(self.state.campaign.performanceItems) && self.state.campaign.performanceItems.length > 0) {
                    self.state.campaign.performanceItems = self.state.campaign.performanceItems.map((inner_item) => {
                        if (inner_item && inner_item.createdAtString == response.date) {
                            inner_item.clicks = response.logs.data.clicks;
                            inner_item.impressions = response.logs.data.impressions;
                            inner_item.spend = response.logs.data.spend;
                            inner_item.loading = false;
                            inner_item.missing = false;
                            if (self.state.campaign.selected_items && self.state.campaign.selected_items[inner_item._id]) {
                                delete self.state.campaign.selected_items[inner_item._id];
                            }
                        }
                        return inner_item;
                    });
                }
                await self.promisedSetState({
                    campaign: self.state.campaign
                });
            }
            if (response.done) {
                self.state.campaign.syncing = false;
                await self.promisedSetState({
                    campaign: self.state.campaign
                });
            }
            callback("got it");
        });
        socket.open();
        this.functions.getCampaign();
    }

    componentWillUnmount() {
        socket.disconnect();
    }

    functions = {
        getCampaign: async () => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.getCampaign(this.state.campaign.id);
                this.state.campaign.logs = response.data.logs;
                await this.promisedSetState({
                    campaign: { ...response.data, ...this.state.campaign }
                });
                console.log(response.data);
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        },
        runFunction: async (item, save_data) => {
            item.syncing = true;
            item.performanceItems = item.performanceItems.map((inner_item) => {
                if (item.selected_items[inner_item._id]) {
                    inner_item.loading = true;
                }
                return inner_item;
            });
            await this.promisedSetState({
                orders: this.state.orders
            });
            let dates = [];
            let channel = item.channel;
            let agency = item.agency.id;
            let client = item.client.id;
            let campaign = item.campaignId;
            for (let key in item.selected_items) {
                if (item.selected_items[key].createdAtString) {
                    dates.push(item.selected_items[key].createdAtString);
                }
            }
            try {
                await this.calls.runFunction(agency, client, channel, dates, save_data, campaign);
            } catch (error) { }
        },
        disconnect: () => {
            return new Promise((resolve) => {
                socket.disconnect();
                resolve();
            })
        }
    };

    calls = {
        getCampaign: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getCampaignOverview?campaign=" + id + "&timeSpan=" + this.state.timeSpan;
            return apiRegister.call(options, url);
        },
        runFunction: (agency, client, channel, dates, save_data, campaign) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/runCron?agency=" + agency + "&client=" + client + "&function=" + "updateAgencyClientsPerformance" + "&sub_function=" + "campaigns" + "&channel=" + channel + (!save_data ? "&skip_save=true" : "") + "&dates=" + dates + "&pusher=" + this.state.random_id + "&campaign=" + campaign;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        spend: (spend) => {
            try {
                spend = +spend;
                return spend.toFixed(2);
            } catch (error) {
                return spend;
            }
        },
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        weekday: (day) => {
            try {
                let weekday = moment(day).weekday();
                let weekdays = {
                    "1": "Monday",
                    "2": "Tuesday",
                    "3": "Wednesday",
                    "4": "Thursday",
                    "5": "Friday",
                    "6": "Saturday",
                    "0": "Sunday"
                };
                return weekdays[weekday];
            } catch (error) {
                return "-"
            }
        },
        paused: (inner_item) => {
            let paused = false;
            if (this.renders.weekday(inner_item.createdAtString) in this.state.campaign && this.state.campaign.scheduleActivated && !this.state.campaign[this.renders.weekday(inner_item.createdAtString)]) {
                paused = true;
            }
            if (this.state.campaign.scheduleAdgroups) {
                for (let key in this.state.campaign.scheduleAdgroups) {
                    if (this.state.campaign.scheduleAdgroups[key].scheduleActivated) {
                        if ("schedule" + this.renders.weekday(inner_item.createdAtString) in this.state.campaign.scheduleAdgroups[key] && !this.state.campaign.scheduleAdgroups[key]["schedule" + this.renders.weekday(inner_item.createdAtString)]) {
                            paused = true;
                        }
                    }
                }
            }
            return paused;
        },
        pausedList: (inner_item) => {
            let list = [];
            if (this.renders.weekday(inner_item.createdAtString) in this.state.campaign && this.state.campaign.scheduleActivated && !this.state.campaign[this.renders.weekday(inner_item.createdAtString)]) {
                list.push("Campaign: " + this.state.campaign.id);
            }
            if (this.state.campaign.scheduleAdgroups) {
                for (let key in this.state.campaign.scheduleAdgroups) {
                    if (this.state.campaign.scheduleAdgroups[key].scheduleActivated) {
                        if ("schedule" + this.renders.weekday(inner_item.createdAtString) in this.state.campaign.scheduleAdgroups[key] && !this.state.campaign.scheduleAdgroups[key]["schedule" + this.renders.weekday(inner_item.createdAtString)]) {
                            list.push("Adgroup: " + key);
                        }
                    }
                }
            }
            return list;
        },
        maxBudgetReached: (inner_item) => {
            let max_bugdet = false;
            if (Array.isArray(this.state.campaign.logs)) {
                this.state.campaign.logs.map((item) => {
                    if (item.trigger == "Client max budget reached" && moment(item.created).format("YYYY-MM-DD") == inner_item.createdAtString) {
                        max_bugdet = true;
                    }
                })
            }
            return max_bugdet;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="flex flex-1 flex-col h-full relative">
                {
                    <div className="flex flex-col px-6 h-full">
                        {
                            <div className="flex flex-1 flex-row">
                                <div className="flex-1 flex flex-col">
                                    {
                                        this.state.campaign.metrics &&
                                        <div className="flex flex-row mb-5">
                                            <div className="flex flex-1 font-medium flex-col items-start">
                                                <div className="text-sm">{this.renders.convertToDecimal(this.state.campaign.metrics.spend)} {this.state.campaign.client.currency}</div>
                                                <div className="text-sm text-gray-400">Spend</div>
                                            </div>
                                            <div className="flex flex-1 font-medium flex-col items-start">
                                                <div className="text-sm">{this.state.campaign.metrics.impressions}</div>
                                                <div className="text-sm text-gray-400">Impressions</div>
                                            </div>
                                            <div className="flex flex-1 font-medium flex-col items-start">
                                                <div className="text-sm">{this.state.campaign.metrics.clicks}</div>
                                                <div className="text-sm text-gray-400">Clicks</div>
                                            </div>
                                            <div className="flex flex-1 font-medium flex-col items-start">
                                                <div className="text-sm">{this.renders.convertToDecimal(this.state.campaign.metrics.clicks / this.state.campaign.metrics.impressions * 100)}</div>
                                                <div className="text-sm text-gray-400">CTR</div>
                                            </div>
                                            <div className="flex flex-1 font-medium flex-col items-start">
                                                <div className="text-sm">{this.renders.convertToDecimal(this.state.campaign.metrics.spend / this.state.campaign.metrics.clicks)} {this.state.campaign.client.currency}</div>
                                                <div className="text-sm text-gray-400">CPC</div>
                                            </div>
                                        </div>
                                    }
                                    <div className="font-medium text-sm mb-1">
                                        Spend (real time)
                                    </div>
                                    <div className={"h-full relative"}>
                                        {
                                            this.state.loading &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white rounded-md right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        {
                                            this.state.campaign && this.state.campaign.chart &&
                                            <Line
                                                width={null}
                                                height={null}
                                                responsive={false}
                                                options={{
                                                    orientation: 'horizontal',
                                                    maintainAspectRatio: false,
                                                    legend: {
                                                        display: false
                                                    },
                                                    layout: {
                                                        padding: {
                                                            left: 0,
                                                            right: 0,
                                                            top: 0,
                                                            bottom: 0
                                                        }
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                fontSize: 10,
                                                                beginAtZero: true,
                                                                precision: 0,
                                                            }
                                                        }],
                                                        xAxes: [{
                                                            ticks: {
                                                                fontSize: 7,
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={this.state.campaign.chart.data}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col ml-5">
                                    <div className="font-medium text-sm mb-1">
                                        Logs
                                    </div>
                                    <div className="flex flex-1 flex-col h-68 relative">
                                        {
                                            this.state.loading &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white rounded-md right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        <div className="left-0 absolute right-0 top-0 bottom-0">
                                            <div className="h-full overflow-x-scroll border rounded-md">
                                                <div className="grid-cols-12 grid sticky top-0 bg-white">
                                                    <div className={"col-span-2 px-2 py-2 border-b"}>
                                                        <div className="text-xxs font-medium">Created</div>
                                                    </div>
                                                    <div className={"col-span-3 px-2 py-2 border-l border-b"}>
                                                        <div className="text-xxs font-medium">User</div>
                                                    </div>
                                                    <div className={"col-span-2 px-2 py-2 border-l border-b"}>
                                                        <div className="text-xxs font-medium">Id</div>
                                                    </div>
                                                    <div className={"col-span-3 px-2 py-2 border-l border-b"}>
                                                        <div className="text-xxs font-medium">Action</div>
                                                    </div>
                                                    <div className={"col-span-2 px-2 py-2 border-l border-b"}>
                                                        <div className="text-xxs font-medium">Status</div>
                                                    </div>
                                                </div>
                                                {
                                                    Array.isArray(this.state.campaign.logs) &&
                                                    this.state.campaign.logs.map((item) => {
                                                        return (
                                                            <div className="grid-cols-12 grid">
                                                                <div onMouseEnter={() => { this.setState({ item: { log_hover: true, id: item.item, weekday: item.created ? moment(item.created).format("dddd") : "-", date: item.created ? moment(item.created).format("YYYY-MM-DD") : "-" } }) }} onMouseLeave={() => { this.setState({ item: {} }) }} className={(this.state.item.id == item.item && this.state.item.log_hover && this.state.item.date == moment(item.created).format("YYYY-MM-DD") ? "bg-gray-50 " : "") + " col-span-2 cursor-pointer px-2 py-2 border-b"}>
                                                                    <div className="text-xxs font-medium"> {item.created ? moment(item.created).format("YYYY-MM-DD") : "-"}</div>
                                                                    <div className="text-xxxs font-medium"> {item.created ? moment(item.created).format("dddd") : "-"}</div>
                                                                </div>
                                                                <div onMouseEnter={() => { this.setState({ item: { log_hover: true, id: item.item, weekday: item.created ? moment(item.created).format("dddd") : "-", date: item.created ? moment(item.created).format("YYYY-MM-DD") : "-" } }) }} onMouseLeave={() => { this.setState({ item: {} }) }} className={(this.state.item.id == item.item && this.state.item.log_hover && this.state.item.date == moment(item.created).format("YYYY-MM-DD") ? "bg-gray-50 " : "") + " col-span-3 cursor-pointer px-2 py-2 border-l border-b"}>
                                                                    <div className="text-xxs font-medium">{item.type}</div>
                                                                    <div className="text-xxxs font-medium">{item.type === "Adcredo" ? "Auto" : ""}</div>
                                                                </div>
                                                                <div onMouseEnter={() => { this.setState({ item: { log_hover: true, id: item.item, weekday: item.created ? moment(item.created).format("dddd") : "-", date: item.created ? moment(item.created).format("YYYY-MM-DD") : "-" } }) }} onMouseLeave={() => { this.setState({ item: {} }) }} className={(this.state.item.id == item.item && this.state.item.log_hover && this.state.item.date == moment(item.created).format("YYYY-MM-DD") ? "bg-gray-50 " : "") + " col-span-2 cursor-pointer px-2 py-2 border-l border-b"}>
                                                                    <div className="text-xxs font-medium truncate">{item.item}</div>
                                                                    <div className="text-xxxs font-medium">{item.level}</div>
                                                                </div>
                                                                <div onMouseEnter={() => { this.setState({ item: { log_hover: true, id: item.item, weekday: item.created ? moment(item.created).format("dddd") : "-", date: item.created ? moment(item.created).format("YYYY-MM-DD") : "-" } }) }} onMouseLeave={() => { this.setState({ item: {} }) }} className={(this.state.item.id == item.item && this.state.item.log_hover && this.state.item.date == moment(item.created).format("YYYY-MM-DD") ? "bg-gray-50 " : "") + " col-span-3 cursor-pointer px-2 py-2 border-l border-b"}>
                                                                    <div className="text-xxs font-medium">{item.value}</div>
                                                                    <div className="text-xxxs font-medium">{item.trigger}</div>
                                                                </div>
                                                                <div onMouseEnter={() => { this.setState({ item: { log_hover: true, id: item.item, weekday: item.created ? moment(item.created).format("dddd") : "-", date: item.created ? moment(item.created).format("YYYY-MM-DD") : "-" } }) }} onMouseLeave={() => { this.setState({ item: {} }) }} className={(this.state.item.id == item.item && this.state.item.log_hover && this.state.item.date == moment(item.created).format("YYYY-MM-DD") ? "bg-gray-50 " : "") + " col-span-2 cursor-pointer px-2 py-2 border-l border-b flex items-center justify-center"}>
                                                                    <div className="text-xxs font-medium">{item.status}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="font-medium text-sm mt-4 mb-1">
                            Daily breakdown (cached)
                        </div>
                        <div className={"flex flex-1 relative"}>
                            <div className={"grid grid-cols-11 grid-rows-3 gap-2 p-0 w-full absolute left-0 right-0 h-full"}>
                                {
                                    Array.isArray(this.state.campaign.performanceItems) &&
                                    this.state.campaign.performanceItems.sort(function (a, b) {
                                        return new Date(b.createdAtString) - new Date(a.createdAtString);
                                    }).map((inner_item) => {
                                        return (
                                            <div onClick={() => {
                                                if (!inner_item.loading) {
                                                    if (!this.state.campaign.selected_items) {
                                                        this.state.campaign.selected_items = {};
                                                    }
                                                    if (this.state.campaign.selected_items[inner_item._id]) {
                                                        delete this.state.campaign.selected_items[inner_item._id];
                                                    } else {
                                                        this.state.campaign.selected_items[inner_item._id] = inner_item;
                                                    }
                                                    this.setState({
                                                        orders: this.state.orders
                                                    });
                                                }
                                            }} className={(this.state.campaign.selected_items && this.state.campaign.selected_items[inner_item._id] ? "border-purple-500 " : (inner_item.missing ? "border-red-500" : "hover:border-gray-500 ")) + " relative min-h-20 flex flex-col cursor-pointer border-1.5 rounded-md p-2"}>
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1">
                                                        <div className={(inner_item.missing ? "text-red-500" : "") + " font-medium text-xxs"}>
                                                            {inner_item.createdAtString}
                                                            <div className="text-xxxs">{this.renders.weekday(inner_item.createdAtString)}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            !this.renders.maxBudgetReached(inner_item) &&
                                                            this.renders.paused(inner_item) &&
                                                            <div
                                                                onMouseEnter={() => {
                                                                    inner_item.show_paused = true;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    inner_item.show_paused = false;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    })
                                                                }}
                                                                className="bg-orange-500 cursor-pointer px-1 py-0.5 rounded-md text-xxs text-white relative">
                                                                {
                                                                    inner_item.show_paused &&
                                                                    <div style={{ bottom: "20px", left: "0px" }} className="absolute bg-orange-500 rounded-md p-2 z-10">
                                                                        {
                                                                            this.renders.pausedList(inner_item,).map((item, index) => {
                                                                                return (
                                                                                    <div className={(index !== 0 ? "mb-1" : "") + " text-xxs text-white whitespace-no-wrap font-medium"}>{item}</div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                                <PauseIcon className="h-3 text-white" />
                                                            </div>
                                                        }
                                                        {
                                                            this.renders.maxBudgetReached(inner_item) &&
                                                            <div onMouseEnter={() => {
                                                                inner_item.show_alert = true;
                                                                this.setState({
                                                                    campaign: this.state.campaign
                                                                })
                                                            }}
                                                                onMouseLeave={() => {
                                                                    inner_item.show_alert = false;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    })
                                                                }} className="bg-red-500 cursor-pointer px-1 py-0.5 rounded-md text-xxs text-white relative">
                                                                {
                                                                    inner_item.show_alert &&
                                                                    <div style={{ bottom: "20px", left: "0px" }} className="absolute bg-red-500 rounded-md p-2 z-10">
                                                                        <div className={"text-xxs text-white whitespace-no-wrap font-medium"}>Client max budget reached</div>
                                                                    </div>
                                                                }
                                                                <ExclamationCircleIcon className="h-3 text-white" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className={"flex flex-1"}></div>
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col items-center justify-center">
                                                        <div className="text-xs">{inner_item.missing ? "-" : this.renders.spend(inner_item.spend)}</div>
                                                        <div className="text-xxs text-gray-400">Spend</div>
                                                    </div>
                                                    <div className="flex flex-1 flex-col items-center justify-center">
                                                        <div className="text-xs">{inner_item.missing ? "-" : +inner_item.impressions}</div>
                                                        <div className="text-xxs text-gray-400">Imp.</div>
                                                    </div>
                                                    <div className="flex flex-1 flex-col items-center justify-center">
                                                        <div className="text-xs">{inner_item.missing ? "-" : +inner_item.clicks}</div>
                                                        <div className="text-xxs text-gray-400">Clicks</div>
                                                    </div>
                                                </div>
                                                {
                                                    inner_item.loading &&
                                                    <div className="w-full h-full absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-span-2 h-full">
                                    <div className="flex flex-1 justify-start pl-12 h-full items-center">
                                        <button
                                            onClick={() => {
                                                if (!this.state.campaign.syncing && this.state.campaign.selected_items && Object.keys(this.state.campaign.selected_items).length > 0) {
                                                    this.functions.runFunction(this.state.campaign, this.state.campaign.save_data);
                                                }
                                            }}
                                            className={(this.state.campaign.selected_items && Object.keys(this.state.campaign.selected_items).length > 0 ? "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white cursor-pointer " : "bg-gray-500 text-white cursor-not-allowed ") + " inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            Run function
                                            {
                                                this.state.campaign.syncing &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                        <div className="ml-4 pt-1">
                                            <SwitchTailwind
                                                disabled={!(this.state.campaign.selected_items && Object.keys(this.state.campaign.selected_items).length > 0)}
                                                value={this.state.campaign.save_data ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.campaign.syncing) {
                                                        this.state.campaign.save_data = !this.state.campaign.save_data;
                                                        this.setState({
                                                            order: this.state.orders
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

export default CampaignOverview;