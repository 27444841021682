import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import {calls} from "../client-facebook/calls";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";

class ClientBing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentDidMount() {}

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="FacebookClient">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Error"
                    type="error"
                    text={"Payment method must be available and ad account must have currency " + this.state.item.currency}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ warning_modal: false});
                    }}
                />
                <div className="FacebookClientTop">
                    <div onClick={() => {
                        this.setState({open: !this.state.open})
                    }} className="FacebookClientTop-InnerContainerInnerTop">
                        <div className="FacebookClientTop-InnerContainerInnerTopTitle">
                            Bing (coming soon)
                        </div>
                        {
                            !this.state.item.bing &&
                            <div className="FacebookClientTop-InnerContainerInnerTopArrowContainer">
                                <FeatherIcon className={
                                    cx("FacebookClientTop-InnerContainerInnerTopArrow",
                                        {
                                            ["FacebookClientTop-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="plus"/>
                                <div className="FacebookClientTop-InnerContainerInnerTopMinusContainer">
                                    <FeatherIcon className={
                                        cx("FacebookClientTop-InnerContainerInnerTopMinus",
                                            {
                                                ["FacebookClientTop-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                            })
                                    } color={"#333333"} size={20} icon="minus"/>
                                </div>
                            </div>
                        }
                        {
                            this.state.item.bing &&
                            <div className="FacebookClientTop-InnerContainerInnerTopArrowContainer FacebookClientTop-InnerContainerInnerTopArrowContainerActive">
                                <FeatherIcon color={"#0185fe"} size={25} icon="check"/>
                            </div>
                        }
                        {
                            this.state.item.bing &&
                            <div className="FacebookClientTop-InnerContainerInnerTopArrowContainer FacebookClientTop-InnerContainerInnerTopArrowContainerEdit">
                                <FeatherIcon className={
                                    cx("FacebookClientTop-InnerContainerInnerTopArrow",
                                        {
                                            ["FacebookClientTop-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="plus"/>
                                <div className="FacebookClientTop-InnerContainerInnerTopMinusContainer">
                                    <FeatherIcon className={
                                        cx("FacebookClientTop-InnerContainerInnerTopMinus",
                                            {
                                                ["FacebookClientTop-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                            })
                                    } color={"#333333"} size={20} icon="minus"/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <SlideDown closed={!this.state.open}>

                </SlideDown>
            </div>
        )
    }
}

export default ClientBing;
