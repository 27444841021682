import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import Switch from "react-ios-switch";
import CampaignStatusSocialPreviewFacebookImage from "../campaign-status-social-preview-facebook-image";
import Carousel from 're-carousel'
import CampaignStatusSearchAdgroup from "../campaign-status-levels-adset";
import {calls} from "./calls";
import ReactTooltip from 'react-tooltip'

var moment = require('moment');

class CampaignStatusLevelsCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: this.props.channel,
            campaign: this.props.campaign,
            loading: true,
            error: false
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.init.campaign();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channel: nextProps.channel
        });
    }

    init = {
        campaign: () => {
            calls.getCampaign(this.state.campaign.internal, this.state.channel).then((response) => {
                response.data.level = 'Campaign';
                if(!response.data.message){
                    response.data.message = "Something went wrong";
                }
                this.setState({
                    campaign: response.data,
                    loading: false
                })
            }, (error) => {
                this.setState({
                    loading: false,
                    error: true,
                    message: error.body.message
                })
            });
        }
    };

    functions = {};

    renders = {
        width: (key) => {
            if(key === 'level'){
                return {width: '90px'}
            }
            else if(key === 'name'){
                return {width: '180px'}
            }
            else if(key === 'status'){
                return {width: '70px'}
            }
            else if(key === 'impressions'){
                return {width: '90px'}
            }
            else if(key === 'click'){
                return {width: '70px'}
            }
            else if(key === 'ctr'){
                return {width: '85px'}
            }
            else if(key === 'cpc'){
                return {width: '90px'}
            }
            else if(key === 'spend'){
                return {width: '100px'}
            }
            else if(key === 'conversions'){
                return {width: '90px'}
            }
            else if(key === 'message'){
                return {width: '540px'}
            }
        },
        ending: (key) => {
            if(key === 'ctr'){
                return "%"
            }else if(key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion'){
                return this.state.campaign.currency
            }else{
                return  "";
            }
        },
        keys: () => {
            if(this.state.campaign.status === 'error'){
                return ['level', 'name', 'status', 'message'];
            }else{
                return ['level', 'name', 'status', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
            }
        },
        status: (key, value) => {
            try{
                if(key === 'status'){
                    if(value.toLowerCase() === 'enabled' || value.toLowerCase() === 'active'){
                        return 'ACTIVE';
                    }else if(value.toLowerCase() === 'error'){
                        return 'ERROR';
                    }else{
                        return value
                    }
                }else{
                    return value
                }
            }catch (e) {
                return value
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <div className="Campaign-Campaign-Item">
                    <div className="Campaign-Campaign-Item-Channel">
                        {
                            this.state.channel === 'google' &&
                            <div className="Campaign-Campaign-Item-Channel-Google">
                                <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.channel === 'facebook' &&
                            <div className="Campaign-Campaign-Item-Channel-Facebook">
                                <img style={{width: '12px'}} src={require('../../assets/images/facebook_icon.svg')}/>
                            </div>
                        }
                        {
                            this.state.channel === 'instagram' &&
                            <div className="Campaign-Campaign-Item-Channel-Instagram">
                                <img style={{width: '25px'}} src={require('../../assets/images/instagram_icon.svg')}/>
                            </div>
                        }
                    </div>
                    <div className={cx("Campaign-Campaign-Item-Values",
                        {
                            ["Campaign-Campaign-Item-Values-Error"]: this.state.campaign.status === 'error'
                        })
                    }>
                        {
                            this.state.loading &&
                            <div className="Campaign-Campaign-Item-Values-Loading">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            this.renders.keys().map((key) => {
                                return(
                                    <div style={this.renders.width(key)} className={cx("Campaign-Campaign-Item-Section")}>
                                        <div className="Campaign-Campaign-Item-Section-Title">
                                            {key}
                                        </div>
                                        <div data-tip-disable={key !== 'name' && key !== 'message'} data-tip={(this.state.campaign[key] + ' ' + this.renders.ending(key))} className="Campaign-Campaign-Item-Section-Value">
                                            {this.renders.status(key, this.state.campaign[key])}{" "}{this.renders.ending(key)}
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div onClick={() => {
                        if(!this.state.loading && this.state.campaign.status !== 'error'){
                            this.setState({
                                open: !this.state.open
                            }, () => {
                                this.props.onChange(this.state.open);
                            })
                        }
                    }} className="Campaign-Campaign-Item-Button">
                        {
                            !this.state.open && !this.state.loading && this.state.campaign.status !== 'error' &&
                            <FeatherIcon size={20} color={'#333333'} icon={'plus'}/>
                        }
                        {
                            this.state.open &&  !this.state.loading && this.state.campaign.status !== 'error' &&
                            <FeatherIcon size={20} color={'#333333'} icon={'minus'}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CampaignStatusLevelsCampaign;
