import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import ReportListItem from "../../components/report-list-item";
import CsvDownload from "react-json-to-csv";

import {SlideDown} from 'react-slidedown'
import {Bar, Doughnut, Line} from "react-chartjs-2";
import Dropdown from "../../components/dropdown";

import ReportsListRow from "../reportslistrow";
import ReportsChart from "../reportschart";
import ReportListRows from "../reportlistrows";

var moment = require('moment');

class ReportsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: {},
            level: {},
            items: [],
            properties: [],
            open: false,
            sort: 'date',
            order: 'asc',
            thead:[
                '',
                'Impressions',
                'Clicks',
                'CTR',
                'CPC',
                'CPP',
                'Spend',
                'Top imp%',
                'Conversions',
                'Cost/conv',
                'ROAS'
            ],
            activeToggle:'list',
            togglers:[
                {
                    title:'List',
                    icon:'list',
                    id:'list'
                },
                {
                    title:'Chart',
                    icon:'pie-chart',
                    id:'chart'
                }
            ],
            line: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            chart: {},
            chart_type: {name: 'Bar chart', value: 'bar'},
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            report: nextProps.report ? nextProps.report : {},
            level: nextProps.level ? nextProps.level : {},
        }, () => {
            this.functions.data();
        });
    }

    componentDidMount() {
        this.setState({
            report: this.props.report ? this.props.report : {},
            level: this.props.level ? this.props.level : {},
            properties: this.props.properties ? this.props.properties : [],
        }, () => {
            this.setState({
                chart: {name: 'click', value: 'click'}
            }, () => {
                this.functions.data();
            })
        })
    }

    init = {};

    renders = {
        summary: () => {

        }
    };

    functions = {
        data: () => {
            let items = {};
            for (let key in this.state.report) {
                for (let i = 0; i < this.state.report[key][this.state.level].daily.length; i++) {
                    let item = this.state.report[key][this.state.level].daily[i];
                    item.currency = this.state.report[key][this.state.level].currency;
                    if (!items[item.date]) {
                        items[item.date] = {};
                        items[item.date].channels = {};
                    }
                    items[item.date].channels[key] = item;
                }
            }
            this.setState({
                items: Object.keys(items).map((key) => {
                    return {date: key, channels: items[key].channels}
                })
            })
        },
        chartData:() =>{
            let labels = {};
            let data = {
                labels: [],
                datasets: []
            };
            for(let key in this.state.report){
                let dataset = {
                    label: key,
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1
                };
                if(key === 'facebook'){
                    for(let i = 0; i < this.state.report[key][this.state.level].daily.length; i ++){
                        if(!labels[this.state.report[key][this.state.level].daily[i].date]){
                            labels[this.state.report[key][this.state.level].daily[i].date] = true;
                            data.labels.push(this.state.report[key][this.state.level].daily[i].date);
                        }
                        dataset.backgroundColor.push("rgba(58,89,151, 0.7)");
                        dataset.borderColor.push("rgba(58,89,151, 1)");
                        dataset.data.push(this.state.report[key][this.state.level].daily[i][this.state.chart.value]);
                    }
                    data.datasets.push(dataset);
                }
                if(key === 'instagram'){
                    for(let i = 0; i < this.state.report[key][this.state.level].daily.length; i ++){
                        if(!labels[this.state.report[key][this.state.level].daily[i].date]){
                            labels[this.state.report[key][this.state.level].daily[i].date] = true;
                            data.labels.push(this.state.report[key][this.state.level].daily[i].date);
                        }
                        dataset.backgroundColor.push("rgba(221,42,123, 0.7)");
                        dataset.borderColor.push("rgba(221,42,123, 1)");
                        dataset.data.push(this.state.report[key][this.state.level].daily[i][this.state.chart.value]);
                    }
                    data.datasets.push(dataset);
                }
                if(key === 'google'){
                    for(let i = 0; i < this.state.report[key][this.state.level].daily.length; i ++){
                        if(!labels[this.state.report[key][this.state.level].daily[i].date]){
                            labels[this.state.report[key][this.state.level].daily[i].date] = true;
                            data.labels.push(this.state.report[key][this.state.level].daily[i].date);
                        }
                        dataset.backgroundColor.push("rgba(66,133,244, 0.7)");
                        dataset.borderColor.push("rgba(66,133,244, 1)");
                        dataset.data.push(this.state.report[key][this.state.level].daily[i][this.state.chart.value]);
                    }
                    data.datasets.push(dataset);
                }
            }
            return data;
        },
        exportCSVFile: (headers, items, fileTitle) => {

            let csv_string = ","; //',CLICK,,,IMPRESSIONS\r\n\t,Facebook,Instagram,total\r\n\t';

            let channels = {};
            this.state.items.map((item) => {
                for(let channel in item.channels){
                    channels[channel] = true;
                }
            });

            //SET HEADER SPACES
            let header_spaces = ',';
            let header_items = {};
            for(let channel in channels){
                header_spaces += ',';
            }

            //IMPRESSIONS HEADER
            csv_string += 'IMPRESSIONS' + header_spaces;
            header_items.impressions = true;

            //CLICK HEADER
            csv_string += 'CLICK' + header_spaces;
            header_items.click = true;

            //CTR HEADER
            csv_string += 'CTR' + header_spaces;
            header_items.ctr = true;

            //SPEND HEADER
            csv_string += 'SPEND' + header_spaces;
            header_items.spend = true;

            //NEXT LINE
            csv_string += '\r\n\t,';

            //CHANNEL HEADERS
            for(let header in header_items){
                if(header_items[header]){
                    for(let channel in channels){
                        csv_string += channel+',';
                    }
                    if(header === 'ctr'){
                        csv_string += 'avg. total,';
                    }else{
                        csv_string += 'total,';
                    }
                }
            }

            //NEXT LINE
            csv_string += '\r\n\t';

            let impressions_total_channels = {};
            let click_total_channels = {};
            let ctr_total_channels = {};
            let spend_total_channels = {};

            this.state.items.map((item) => {
                csv_string += item.date + ',';
                if(header_items.impressions){
                    let impressions_total = 0;
                    for(let channel in channels){
                        csv_string += item.channels[channel].impressions + ',';
                        impressions_total += item.channels[channel].impressions;
                        if(!impressions_total_channels[channel]){
                            impressions_total_channels[channel] = item.channels[channel].impressions;
                        }else{
                            impressions_total_channels[channel] += item.channels[channel].impressions;
                        }
                    }
                    csv_string += impressions_total + ',';
                }
                if(header_items.click){
                    let click_total = 0;
                    for(let channel in channels){
                        csv_string += item.channels[channel].click + ',';
                        click_total += item.channels[channel].click;
                        if(!click_total_channels[channel]){
                            click_total_channels[channel] = item.channels[channel].click;
                        }else{
                            click_total_channels[channel] += item.channels[channel].click;
                        }
                    }
                    csv_string += click_total + ',';
                }
                if(header_items.ctr){
                    let ctr_total = 0;
                    let channels_total = 0;
                    for(let channel in channels){
                        channels_total += 1;
                        csv_string += item.channels[channel].ctr + ',';
                        ctr_total += item.channels[channel].ctr;
                        if(!ctr_total_channels[channel]){
                            ctr_total_channels[channel] = item.channels[channel].ctr;
                        }else{
                            ctr_total_channels[channel] += item.channels[channel].ctr;
                        }
                    }
                    csv_string += (ctr_total/channels_total).toFixed(2) + ',';
                }
                if(header_items.spend){
                    let spend_total = 0;
                    for(let channel in channels){
                        csv_string += item.channels[channel].spend + ',';
                        spend_total += item.channels[channel].spend;
                        if(!spend_total_channels[channel]){
                            spend_total_channels[channel] = item.channels[channel].spend;
                        }else {
                            spend_total_channels[channel] += item.channels[channel].spend;
                        }
                    }
                    csv_string += spend_total.toFixed(2) + ',';
                }
                //NEXT LINE
                csv_string += '\r\n\t';
            });

            //NEXT LINE
            csv_string += '\r\n\t';
            csv_string += '\r\n\t';

            //SUMMARY
            csv_string += 'Summary:,';
            csv_string += '\r\n\t';

            //DAYS
            csv_string +=  this.state.items.length + ' days,';

            if(header_items.impressions){
                let total = 0;
                for(let channel in impressions_total_channels){
                    csv_string += impressions_total_channels[channel] + ',';
                    total += impressions_total_channels[channel];
                }
                csv_string += total;
            }
            if(header_items.click){
                csv_string += ',';
                let total = 0;
                for(let channel in click_total_channels){
                    csv_string += click_total_channels[channel] + ',';
                    total += click_total_channels[channel];
                }
                csv_string += total;
            }
            if(header_items.ctr){
                csv_string += ',';
                let total = 0;
                for(let channel in ctr_total_channels){
                    csv_string += (ctr_total_channels[channel]/this.state.items.length).toFixed(2) + ',';
                    total += ctr_total_channels[channel]/this.state.items.length;
                }
                csv_string += total.toFixed(2);
            }
            if(header_items.spend){
                csv_string += ',';
                let total = 0;
                for(let channel in spend_total_channels){
                    csv_string += spend_total_channels[channel].toFixed(2) + ',';
                    total += spend_total_channels[channel];
                }
                csv_string += total.toFixed(2);
            }
            var exportedFilenmae = 'campaign.csv';

            var blob = new Blob([csv_string], {type: 'text/csv;charset=utf-8;'});
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    renders = {
        date: (date) => {
            try {
                return moment(date).format('MMMM D');
            } catch (e) {
            }
        },
        summary: () => {
            let data = [];
            data = this.state.items.map((item) => {
                let object = {};
                object['DATE'] = item.date;

                let click_total = 0;
                for (let channel in item.channels) {
                    let click = 'CLICK - ' + channel.toUpperCase();
                    object[click] = item.channels[channel].click;
                    click_total += item.channels[channel].click;
                }
                object['CLICK TOTAL'] = click_total;

                let impressions_total = 0;
                for (let channel in item.channels) {
                    let impressions = 'IMPRESSIONS - ' + channel.toUpperCase();
                    object[impressions] = item.channels[channel].impressions;
                    impressions_total += item.channels[channel].impressions;
                }
                object['IMPRESSIONS TOTAL'] = impressions_total;

                let spend_total = 0;
                for (let channel in item.channels) {
                    let spend = 'SPEND - ' + channel.toUpperCase();
                    object[spend] = item.channels[channel].spend;
                    spend_total += item.channels[channel].spend;
                }
                object['SPEND TOTAL'] = spend_total;

                return object;
            });

            data.push({'DATE': ''});
            data.push({'DATE': ''});
            data.push({'DATE': 'Summary: '});

            return data;
        },
        name: (item) => {
            if(item === 'cost_pr_conversion'){
                return "cost pr conversion"
            }else{
                return item;
            }
        }
    };

    render() {
        return (
            <section>
                <div className="mb-4 mt-6 flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <h3 className="mb-0 text-2xl">{this.props.headline}</h3>
                        {this.props.toggleChart &&
                            <nav>
                                <ul className="ml-4 flex flex-row">
                                    { this.state.togglers.map((item, i) => {
                                        return(
                                            <li key={i}>
                                                <button onClick={() => {
                                                    this.setState({
                                                        activeToggle:item.id
                                                    })
                                                }} 
                                                    className={cx("border cursor-pointer py-1 mr-2 px-4 transition-all duration-200 hover:bg-gray-300 hover:text-dark-900 font-bold rounded text-xs flex flex-row items-center focus:outline-none",{
                                                        ["bg-gray-900 text-white hover:text-dark-900 border-dark-900"]: item.id === this.state.activeToggle,
                                                        ["bg-transparent text-gray-900"]: item.id != this.state.activeToggle,
                                                    })}>
                                                    <FeatherIcon className="stroke-current mr-2" size={15} icon={item.icon}/>
                                                    {item.title}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        }
                    </div>
                    <div className="flex flex-row justify-end items-center">
                        {false&&
                        <div className="mr-2">
                            <button onClick={() => {}}
                                    className="py-2 px-4 text-xs text-purple-900 border rounded border-gray-300 hover:bg-gray-300 hover:border-gray-500 transition-all duration-200 inline-flex flex-row items-center">
                                <FeatherIcon className="stroke-current mr-2" size={15} icon="calendar"/>
                                <span>Start date - End date</span>
                            </button>
                        </div>}
                        <div>
                            <button onClick={() => {
                                this.functions.exportCSVFile()
                            }} className="py-2 pl-2 text-xs text-purple-500 hover:underline inline-flex flex-row items-center">
                                <FeatherIcon className="stroke-current mr-2" size={15} icon="download"/>
                                <span>Export (.csv)</span>
                            </button>
                        </div>
                    </div>
                </div>
                {this.state.activeToggle === 'list' &&
                    <ReportListRows
                        properties={this.state.properties}
                        items={this.state.items}
                        sort={this.state.sort}
                        order={this.state.order}
                        updateSort={(sort, order) =>{
                            this.setState({
                                sort: sort,
                                order: order
                            })
                        }}
                    />
                }
                {this.state.activeToggle === 'list' && false &&
                    <div className="flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="align-middle inline-block min-w-full">
                                <div className="overflow-hidden border-gray-200 sm:rounded-lg">
                                    { this.state.items.map((item, i) => {
                                        return (
                                            <table key={i} className="min-w-full divide-y divide-gray-200 mb-4 shadow-xl">
                                                {i == 0 &&
                                                    <thead>
                                                        <tr>
                                                            {this.state.thead.map((th, j) => {
                                                                return(
                                                                    <th key={j} className="px-3 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                                        {th}
                                                                    </th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                }
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    <ReportsListRow 
                                                        items={item.channels}
                                                        properties={this.state.properties}
                                                        title={this.renders.date(item.date)}
                                                    />
                                                    { Object.keys(item.channels).map((key, k) => {
                                                            return (
                                                                <ReportsListRow
                                                                    item={item.channels[key]}
                                                                    properties={this.state.properties}
                                                                    key={k}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.activeToggle === 'chart' &&
                    <div>
                        <ReportsChart 
                            report={this.state.report}
                            level={'campaign'}
                            properties={this.state.properties.filter((item) => {return item !== 'date'})}
                            type={'daily'}
                            showChartType={this.props.showChartType ? this.props.showChartType : false}
                        />
                    </div>
                }
            </section>
        )
    }
}

export default ReportsList;