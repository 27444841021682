import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, FolderIcon, SearchIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import SwitchTailwind from './switchTailwind';
import DropdownTailwind from './dropdownTailwind';

class KeywordModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            exact: false,
            phrase: false,
            broad: false,
            multi_input: false,
            input: "",
            adgroups: false,
            adgroup: false,
            locked: false,
            error: false,
            new_multi_input: false,
            customMatch: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            error: this.props.error,
            locked: this.props.locked,
            adgroup: this.props.adgroup,
            adgroups: this.props.adgroups,
            input: this.props.input,
            exact: this.props.exact,
            phrase: this.props.phrase,
            customMatch: this.props.customMatch,
            multi_input: this.props.multi_input ? this.props.multi_input : false,
            broad: this.props.broad,
            new_multi_input: this.props.new_multi_input ? this.props.new_multi_input : false
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            adgroups: nextProps.adgroups,
            adgroup: nextProps.adgroup,
            locked: nextProps.locked,
            error: nextProps.error,
            open: nextProps.open,
            input: nextProps.input,
            exact: nextProps.exact,
            phrase: nextProps.phrase,
            multi_input: nextProps.multi_input,
            broad: nextProps.broad,
            new_multi_input: nextProps.new_multi_input,
            customMatch: nextProps.customMatch
        })
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed z-60 inset-0 overflow-y-auto" onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose(true, false);
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                                        <SearchIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {this.props.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {this.props.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.adgroups &&
                                    <div className="mt-5 sm:mt-6">
                                        <DropdownTailwind
                                            channel="google"
                                            label={"Adgroup"}
                                            error={this.state.error}
                                            locked={this.state.locked}
                                            searchInput={true}
                                            selected={this.state.adgroup}
                                            options={this.state.adgroups}
                                            onChange={async (value) => {
                                                if (this.props.onAdgroup) {
                                                    this.props.onAdgroup(value);
                                                }
                                            }}
                                        />
                                    </div>

                                }
                                <div className="mt-5 sm:mt-6">
                                    {this.state.multi_input && !this.state.new_multi_input &&
                                        this.state.multi_input.map((input, index) => {
                                            return (
                                                <Fragment>
                                                    <InputTailwind
                                                        label={"Keyword"}
                                                        value={input.name}
                                                        onChange={(value) => {
                                                            this.state.multi_input[index].name = value;
                                                            if (this.props.onMultiInput) {
                                                                this.props.onMultiInput(this.state.multi_input);
                                                            }
                                                        }}
                                                    />
                                                </Fragment>
                                            )
                                        })
                                    }
                                    {!this.state.multi_input && !this.state.new_multi_input &&
                                        <InputTailwind
                                            label={"Keyword"}
                                            value={this.state.input}
                                            onChange={(value) => {
                                                if (this.props.onInput) {
                                                    this.props.onInput(value);
                                                }
                                            }}
                                        />
                                    }
                                    {

                                        this.state.new_multi_input &&
                                        <>
                                            <div className='flex flex-row'>
                                                <div className='mr-1'>
                                                    <div className='bg-google-500 h-4 w-4 rounded-full flex justify-center items-center'>
                                                        <img className="w-2"
                                                            src={require('../assets/images/google_icon.svg')} />
                                                    </div>
                                                </div>
                                                <label class="block text-xs font-medium text-gray-700">Keywords (You can separate each keyword by commas or enter one per line.)</label>
                                            </div>
                                            <div className='mt-1 relative'>
                                                <textarea
                                                    rows={6}
                                                    className={" bg-custom-input rounded-md block w-full border-1.5 pr-10 px-2 py-2 focus:outline-none sm:text-sm"}
                                                    value={this.state.multi_input[0].name}
                                                    onChange={async (event) => {
                                                        if (event.target.value !== "\n") {
                                                            this.state.multi_input[0].name = event.target.value;
                                                            if (this.props.onMultiInput) {
                                                                this.props.onMultiInput(this.state.multi_input);
                                                            }
                                                        }

                                                        // if (!this.state.ai_loading) { await this.promisedSetState({ searchOpenAi: event.target.value }) }

                                                    }}
                                                    onKeyDown={async (event) => {
                                                        try {

                                                        } catch (e) { }
                                                    }}
                                                />
                                            </div>
                                        </>

                                    }

                                </div>
                                {
                                    this.state.adgroups && false &&
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                            onClick={() => {
                                                this.state.multi_input.push({ id: Math.floor((Math.random() * 9999999999) + 1), name: "" });
                                                if (this.props.onMultiInput) {
                                                    this.props.onMultiInput(this.state.multi_input);
                                                }
                                            }}
                                        >
                                            {"Add more keyword"}
                                        </button>
                                    </div>
                                }
                                {
                                    this.state.adgroups && !this.state.new_multi_input &&
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                            onClick={() => {
                                                this.state.new_multi_input = true;
                                                if (this.props.onNewMultiInput) {
                                                    this.props.onNewMultiInput(this.state.new_multi_input);
                                                }
                                            }}
                                        >
                                            {"Add keyword list"}
                                        </button>
                                    </div>
                                }
                                <div className="flex flex-row pt-4">
                                    <div className="">
                                        <div className="text-xs font-medium mb-3">
                                            Exact
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.exact}
                                            onSwitch={(value) => {
                                                if (this.props.onExact) {
                                                    this.props.onExact(!this.state.exact);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="pl-4">
                                        <div className="text-xs font-medium mb-3">
                                            Phrase
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.phrase}
                                            onSwitch={(value) => {
                                                if (this.props.onPhrase) {
                                                    this.props.onPhrase(!this.state.phrase);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="px-4">
                                        <div className="text-xs font-medium mb-3">
                                            Broad
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.broad}
                                            onSwitch={(value) => {
                                                if (this.props.onBroad) {
                                                    this.props.onBroad(!this.state.broad);
                                                }
                                            }}
                                        />
                                    </div>
                                    {
                                        this.props.button === "Add" && this.state.new_multi_input &&
                                        <div className="px-4">
                                            <div className="text-xs font-medium mb-3">
                                                Custom
                                            </div>
                                            <SwitchTailwind
                                                value={this.state.customMatch}
                                                onSwitch={(value) => {
                                                    if (this.props.onCustomMatch) {
                                                        this.props.onCustomMatch(!this.state.customMatch);
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="mt-4">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (this.props.onClose) {
                                                        this.props.onClose();
                                                    }
                                                }}
                                            >
                                                {"Close"}
                                            </button>
                                        </div>
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (this.props.onCreate) {
                                                        this.props.onCreate();
                                                    }
                                                }}
                                            >
                                                {this.props.button}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default KeywordModal;
