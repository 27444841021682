import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";
import AdvertisingKeywordsPositiveItem from "../../components/advertising-keywords-positive-item";

class Keywords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignitems: [],
            sort: "",
            order: "",
            total: 0,
            page: 0
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            campaignitems: this.props.campaignitems,
            sort: this.props.sort,
            order: this.props.order,
            search: this.props.search,
            creatives: this.props.creatives,
            campaign: this.props.campaign,
            page: this.props.page
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaignitems: nextProps.campaignitems,
            sort: nextProps.sort,
            order: nextProps.order,
            search: nextProps.search,
            creatives: nextProps.creatives,
            campaign: nextProps.campaign,
            page: nextProps.page
        })
    }

    functions = {};

    renders = {};

    render() {
        return (
            <div className="flex flex-1">
                <table style={{backgroundColor: "#fcfcfc"}} className="min-w-full divide-y divide-gray-200 border">
                    <thead>
                    <tr>
                        <th className="transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            <div className="flex flex-row items-center justify-start">
                                Actions{false && <div className="sorting-arrows ml-2"></div>}
                            </div>
                        </th>
                        <th onClick={() => {
                            try {
                                this.props.updateSort('name', 'name' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                            } catch (e) {
                            }
                        }}
                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'name' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                            <div className="flex flex-row items-center">
                                Keyword <div className="sorting-arrows ml-2"></div>
                            </div>
                        </th>
                        {this.props.properties && this.props.properties.type &&
                        <th onClick={() => {
                            try {
                                this.props.updateSort('type', 'type' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                            } catch (e) {
                            }
                        }} className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                            <div className="flex flex-row items-center">
                                Type <div className="sorting-arrows ml-2"></div>
                            </div>
                        </th>
                        }
                        {this.props.properties && this.props.properties.cpc &&
                        <th onClick={() => {
                            try {
                                this.props.updateSort('cpc', 'cpc' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                            } catch (e) {
                            }
                        }}
                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'cpc' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                            <div className="flex flex-row items-center">
                                Max CPC <div className="sorting-arrows ml-2"></div>
                            </div>
                        </th>
                        }
                        {this.props.properties && this.props.properties.monthly_searches &&
                        <th onClick={() => {
                            try {
                                this.props.updateSort('monthly_searches', 'monthly_searches' === this.state.sort && this.state.order === 'asc' ? 'desc' : 'asc');
                            } catch (e) {
                            }
                        }}
                            className={"cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider " + (this.state.sort === 'monthly_searches' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                            <div className="flex flex-row items-center">
                                Monthly Searches <div className="sorting-arrows ml-2"></div>
                            </div>
                        </th>
                        }
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {this.state.campaignitems.sort((a, b) => {
                        if (this.state.sort) {
                            var nameA = null;
                            var nameB = null;
                            try {
                                nameA = a[this.state.sort].toLowerCase();
                            } catch (e) {
                                nameA = a[this.state.sort];
                            }
                            try {
                                nameB = b[this.state.sort].toLowerCase();
                            } catch (e) {
                                nameB = b[this.state.sort];
                            }
                            if (this.state.order === 'desc') {
                                if (nameA > nameB)
                                    return -1;
                                if (nameA < nameB)
                                    return 1;
                                return 0
                            } else {
                                if (nameA < nameB)
                                    return -1;
                                if (nameA > nameB)
                                    return 1;
                                return 0
                            }
                        } else {
                            return 1
                        }
                    }).map((item, i) => {
                        return (
                            <Fragment key={i}>
                                <tr>
                                    <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                        <div className="flex flex-row items-center justify-start">
                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete &&
                                            <button onClick={() => {
                                                try {
                                                    this.props.delete(item)
                                                } catch (e) {
                                                }
                                            }}
                                                    className="bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                <span>Delete</span>
                                                <FeatherIcon className="stroke-current ml-2" size={15}
                                                             icon="trash"/>
                                            </button>
                                            }
                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.overview &&
                                            <button onClick={() => {
                                                item.open = !item.open;
                                                this.setState({
                                                    campaignitems: this.state.campaignitems
                                                });
                                            }}
                                                    className="ml-2 bg-black-200 bg-opacity-25 text-black-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-black hover:bg-opacity-25 hover:text-white">
                                                <span>Overview</span>
                                                <FeatherIcon className="stroke-current ml-2" size={15}
                                                             icon="chevron-down"/>
                                            </button>
                                            }
                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.select &&
                                            <button onClick={() => {
                                                this.props.select(item.name);
                                            }} className="ml-2 bg-green-200 bg-opacity-25 text-green-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-green hover:bg-opacity-25 hover:text-white">
                                                <span>Select</span>
                                                <FeatherIcon className="stroke-current ml-2" size={15} icon="plus"/>
                                            </button>
                                            }
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                        <div
                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'name' ? 'font-bold' : '')}>
                                            {item.name}
                                        </div>
                                    </td>
                                    {this.props.properties && this.props.properties.type &&
                                    <td className="px-6 py-4 whitespace-no-wrap capitalize">
                                                        <span
                                                            className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                {
                                                                    ["bg-green-100 text-green-500"]: item.type === "broad",
                                                                    ["bg-purple-100 text-purple-500"]: item.type === "phrase",
                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.type === "exact",
                                                                })
                                                            }>
                                                            {item.type}
                                                        </span>
                                    </td>
                                    }
                                    {this.props.properties && this.props.properties.cpc &&
                                        <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                            <div
                                                className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'cpc' ? 'font-bold' : '')}>
                                                {item.cpc === 0 ? '-' : item.cpc}
                                            </div>
                                        </td>
                                    }
                                    {this.props.properties && this.props.properties.monthly_searches &&
                                    <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                        <div
                                            className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'monthly_searches' ? 'font-bold' : '')}>
                                            {item.monthly_searches}
                                        </div>
                                    </td>
                                    }
                                </tr>
                                {this.props.properties && this.props.properties.actions && this.props.properties.actions.overview &&
                                <tr style={{borderTop: 'none'}}>
                                    <td colSpan="4">
                                        <SlideDown closed={!item.open}>
                                            <div style={{backgroundColor: "#fcfcfc"}} className="flex flex-1 w-full px-4 py-4 box-border border-t">
                                                <AdvertisingKeywordsPositiveItem
                                                    keyword={item}
                                                    campaign={this.state.campaign}
                                                    search={this.state.search}
                                                    creatives={this.state.creatives}
                                                    onChange={(value) => {
                                                        item = value;
                                                        this.setState({
                                                            campaignitems: this.state.campaignitems
                                                        }, () => {
                                                            try{
                                                                this.props.onChange(this.state.campaignitems);
                                                            }catch (e) {}
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </SlideDown>
                                    </td>
                                </tr>
                                }
                            </Fragment>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Keywords;