import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from 'react-spinners';
import {tokenRegister} from "../../services/tokenRegister";

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            removed_success_modal: false,
            removed_warning_modal: false,
            error_modal: false,
            email: "",
            button_loading: false,
            errors: {},
            error_message: "",
        };
    };

    componentWillMount() {}

    init = {};

    functions = {
        remove: () => {
            if(!this.state.button_loading){
                this.setState({button_loading: true, removed_warning_modal: false}, () => {
                    dataRegister.removeUser().then((response) => {
                        this.setState({
                            button_loading: false,
                            removed_success_modal: true
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                    });
                });
            }
        }
    };

    renders = {};

    render(){
        return (
            <div className="Container">
                <SweetAlert
                    show={this.state.removed_success_modal}
                    title="Removed"
                    type="success"
                    closeOnClickOutside={false}
                    text="Your account has been removed"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({removed_success_modal: false}, () => {
                            tokenRegister.remove();
                            this.props.history.push("/login");
                        });
                    }}
                />
                <SweetAlert
                    show={this.state.removed_warning_modal}
                    title="Remove account"
                    type="warning"
                    text="Are you sure ?"
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({removed_warning_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />

                <div className="Section">
                    <div onClick={() => {
                        this.setState({removed_warning_modal: true});
                    }} className="Button ButtonRed">
                        {
                            !this.state.button_loading &&
                            <div>Remove account</div>
                        }
                        {
                            this.state.button_loading &&
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={'#ffffff'}
                                loading={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings;