import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";

class AccordionChannels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open?this.props.open:false,
            inner: this.props.inner,
            google: this.props.google,
            facebook: this.props.facebook,
            instagram: this.props.instagram
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            google: nextProps.google,
            facebook: nextProps.facebook,
            instagram: nextProps.instagram
        });
    }

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <div style={{padding: '0px'}} className={
                cx("Accordion-InnerContent",
                    {
                        ["Accordion-NoBorder"]: this.state.inner,
                        ["Accordion-Shadow"]: true,
                    })
            }>
                <div className={cx("Accordion-InnerContent-InnerContainerInner")}>
                    <div onClick={() => {
                        this.setState({open: !this.state.open})
                    }} className="Accordion-InnerContent-InnerContainerInnerTop">
                        <div className="Accordion-InnerContainerInnerTopTitle">
                            {this.props.title}
                        </div>
                        {
                            this.state.facebook &&
                            <IconFacebook large={true}/>
                        }
                        {
                            this.state.instagram &&
                            <IconInstagram large={true}/>
                        }
                        {
                            this.state.google &&
                            <IconGoogle large={true}/>
                        }
                        <div className="Accordion-InnerContent-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("Accordion-InnerContainerInnerTopArrow",
                                    {
                                        ["Accordion-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={20} icon="plus"/>
                            <div className="Accordion-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("Accordion-InnerContainerInnerTopMinus",
                                        {
                                            ["Accordion-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="minus"/>
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        {this.props.children}
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default AccordionChannels;
