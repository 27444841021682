var moment = require('moment');

class cellData {

    static items(cell, campaigns, adgroups, ads) {
        let items = [];
        let all_items = [];
        all_items = all_items.concat(Array.isArray(campaigns) ? JSON.parse(JSON.stringify(campaigns.filter((item) => { return cell.data_sources.campaigns[item.id] }))) : []);
        all_items = all_items.concat(Array.isArray(adgroups) ? JSON.parse(JSON.stringify(adgroups.filter((item) => { return cell.data_sources.adgroups[item.id] }))) : []);
        all_items = all_items.concat(Array.isArray(ads) ? JSON.parse(JSON.stringify(ads.filter((item) => { return cell.data_sources.ads[item.id] }))) : []);
        all_items.map((campaign) => {
            let dimension = {};
            if (Array.isArray(campaign.insights)) {
                campaign.insights.map((item) => {
                    if (
                        cell.channel_breakdowns && cell.channel_breakdowns[campaign.channel] && cell.channel_breakdowns[campaign.channel][item.channel_breakdown]
                        &&
                        ((cell.daterange.value === "lifetime" && !item.daterange_start && !item.daterange_end) || (item.daterange_start === cell.daterange.start_date && item.daterange_end === cell.daterange.end_date))
                    ) {
                        item.breakdown_value = item[item.channel_breakdown];
                        if (item.breakdown_value in dimension) {
                            dimension[item.breakdown_value].data.push(item);
                        } else {
                            let object = { ...campaign, ...item };
                            dimension[item.breakdown_value] = { campaign: object, data: [] };
                            dimension[item.breakdown_value].data.push(item);
                        }
                    }
                });
            }
            for (let dim in dimension) {
                let campaign = { ...JSON.parse(JSON.stringify(dimension[dim].campaign)), ...{ insights: dimension[dim].data } };
                items = items.concat([campaign]);
            }
        });

        //ADD CUSTOM AND CALCULATED METRICS
        let colors = {
            facebook: "#3A5998",
            google: "#4384F4",
            adform: "#60C1A4",
            bidtheatre: "#f48e22",
            google_analytics: "#e8710a"
        }

        let custom_metrics = {};
        if (cell.metrics) {
            for (let metric in cell.metrics) {
                if (cell.metrics[metric].calculated) {
                    for (let key in cell.metrics[metric].metrics) {
                        let inner_custom = cell.metrics[metric].metrics[key];
                        custom_metrics[inner_custom.name] = inner_custom;
                    }
                } else {
                    custom_metrics[metric] = cell.metrics[metric];
                }
            }
        }

        items = items.map((item) => {
            let new_item = {
                id: item.id,
                name: item.name,
                channel: item.channel,
                type: item.type,
                channel_breakdown: item.channel_breakdown,
                breakdown_value: item[item.channel_breakdown],
                hex: item.hex ? item.hex : colors[item.channel]
            };
            new_item[item.channel_breakdown] = item.breakdown_value;
            item.insights.map((insight) => {

                for (let metric in custom_metrics) {
                    let custom_metric = custom_metrics[metric];
                    if (!new_item[custom_metric.name]) {
                        new_item[custom_metric.name] = 0;
                    }
                    let channel_metric = custom_metric.channels[item.channel];
                    if (channel_metric) {
                        if (cellData.isNumeric(insight[channel_metric.name])) {
                            new_item[custom_metric.name] += (+insight[channel_metric.name] * +channel_metric.factor);
                            new_item[custom_metric.name] = cellData.roundUp(new_item[custom_metric.name]);
                        } else {
                            //new_item[custom_metric.name] = insight[channel_metric.name];
                        }
                    } else {
                        //new_item[custom_metric.name] = 0;
                    }
                }

            });

            //CALCULATED METRIC (BASED ON SUM OF CUSTOM METRIC)
            for (let metric in cell.metrics) {
                let custom_metric = cell.metrics[metric];
                if (custom_metric.calculated) {
                    let calc_string = custom_metric.calculation;
                    for (let key in custom_metric.metrics) {
                        if (calc_string.indexOf("<" + key + ">") !== -1) {
                            if (!new_item[custom_metric.metrics[key].name]) {
                                new_item[custom_metric.metrics[key].name] = 0;
                            }
                            calc_string = calc_string.replace("<" + key + ">", new_item[custom_metric.metrics[key].name]);
                        }
                    }
                    new_item[custom_metric.name] = cellData.calculate(calc_string);
                    new_item[custom_metric.name] = cellData.roundUp(new_item[custom_metric.name]);
                }
            }

            return new_item;
        });

        return items;
    }

    static chart(items, cell) {

        let data = {
            labels: [],
            datasets: []
        };
        let dateList = [];
        try {
            let startDate = moment(cell.daterange.start_date);
            let endDate = moment(cell.daterange.end_date);
            dateList = cellData.getDaysBetweenDates(startDate, endDate);
        } catch (error) { }

        if (cell.channel_breakdowns) {
            let labels = {};
            let datasources = {};
            items.map((item) => {
                if (cell.channel_breakdowns && cell.channel_breakdowns[item.channel] && cell.channel_breakdowns[item.channel][item.channel_breakdown]) {
                    labels[item[item.channel_breakdown]] = { key: item[item.channel_breakdown], value: item.channel_breakdown };
                    if (item.channel_breakdown === 'date' || item.channel_breakdown === 'ga:date') {
                        dateList.map((item) => {
                            labels[item] = { key: item, value: item.channel_breakdown };
                        })
                    }
                }
                if (!datasources[item.id]) {
                    datasources[item.id] = { hex: item.hex, channel: item.channel, id: item.id, name: item.name, items: [item] };
                } else {
                    datasources[item.id].items.push(item);
                }
            });
            let new_labels = [];
            if (cell.settings && cell.settings.chart_setting && cell.settings.chart_setting.value == "breakdown/metric") {
                for (let label in labels) {
                    for (let metric in cell.metrics) {
                        new_labels.push({ metric: metric, dimension: labels[label].key });
                    }
                }
            } else {
                for (let label in labels) {
                    new_labels.push({ metric: null, dimension: labels[label].key });
                }
            }
            
            //BAR
            if (cell.chart.value === "bar") {
                if (cell.settings && cell.settings.chart_setting && cell.settings.chart_setting.value == "breakdown/metric") {
                    for (let datasource in datasources) {
                        let dataset = {
                            label: datasources[datasource].name,
                            data: [],
                            backgroundColor: [],
                            borderColor: [],
                            borderWidth: 2,
                        }
                        let labels_value = {};
                        new_labels.map((label) => {
                            datasources[datasource].items.map((item) => {
                                if (label.dimension === item[item.channel_breakdown]) {
                                    labels_value[label.dimension + ":  " + label.metric] = item[label.metric] ? item[label.metric] : 0;
                                }
                            })
                        });
                        for (let label in labels_value) {
                            dataset.data.push(labels_value[label]);
                            dataset.backgroundColor.push(datasources[datasource].hex);
                            dataset.borderColor.push(datasources[datasource].hex);
                        }
                        data.datasets.push(dataset);
                    }
                    data.labels = [];
                    new_labels.map((label) => {
                        data.labels.push(label.dimension + ": " + label.metric);
                    })
                } else {
                    for (let datasource in datasources) {
                        for (let metric in cell.metrics) {
                            let dataset = {
                                label: datasources[datasource].name + ": " + metric,
                                data: [],
                                backgroundColor: datasources[datasource].hex,
                                borderColor: datasources[datasource].hex,
                                borderWidth: 2,
                            }
                            new_labels.map((label) => {
                                datasources[datasource].items.map((item) => {
                                    if (label.dimension === item[item.channel_breakdown]) {
                                        if (item[metric]) {
                                            dataset.data.push(item[metric]);
                                        } else {
                                            dataset.data.push(0);
                                        }
                                    }
                                })
                            });
                            data.datasets.push(dataset);
                        }
                    }
                    new_labels.map((item) => {
                        data.labels.push(item.dimension);
                    })
                }

            }

            //LINE
            if (cell.chart.value === "line") {

                if (cell.settings && cell.settings.chart_setting && cell.settings.chart_setting.value == "breakdown/metric") {
                    for (let datasource in datasources) {
                        let dataset = {
                            label: datasources[datasource].name,
                            data: [],
                            backgroundColor: datasources[datasource].hex,
                            borderColor: datasources[datasource].hex,
                            borderWidth: 2,
                            fill: false
                        }
                        let labels_value = {};
                        new_labels.map((label) => {
                            let exist = false;
                            datasources[datasource].items.map((item) => {
                                if (label.dimension == item[item.channel_breakdown]) {
                                    exist = true;
                                    labels_value[label.dimension + ":  " + label.metric] = item[label.metric] ? item[label.metric] : 0;
                                }
                            })
                        });
                        for (let label in labels_value) {
                            dataset.data.push(labels_value[label]);
                        }
                        data.datasets.push(dataset);
                    }
                    data.labels = [];
                    new_labels.map((label) => {
                        data.labels.push(label.dimension + ": " + label.metric);
                    })
                } else {
                    for (let datasource in datasources) {
                        for (let metric in cell.metrics) {
                            let dataset = {
                                label: datasources[datasource].name + ": " + metric,
                                data: [],
                                backgroundColor: datasources[datasource].hex,
                                borderColor: datasources[datasource].hex,
                                borderWidth: 2,
                                fill: false
                            }
                            new_labels.map((label) => {
                                datasources[datasource].items.map((item) => {
                                    if (label.dimension == item[item.channel_breakdown]) {
                                        if (item[metric]) {
                                            dataset.data.push(item[metric]);
                                        } else {
                                            dataset.data.push(0);
                                        }
                                    }
                                })
                            });
                            data.datasets.push(dataset);
                        }
                    }
                    new_labels.map((item) => {
                        data.labels.push(item.dimension);
                    })
                }
            }

            //DONUT
            if (cell.chart.value === "donut") {

                let dataset = {
                    label: "",
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 2
                }
                data.labels = [];

                let amount_of_datasources = Object.keys(datasources).length;
                let selected_datasource = {};
                for (let datasource in datasources) {
                    selected_datasource = datasources[datasource];
                    dataset.label = "";
                    let labels_value = {};
                    new_labels = new_labels.map((label) => {
                        datasources[datasource].items.map((item) => {
                            if (label.dimension === item[item.channel_breakdown]) {
                                labels_value[label.dimension] = item[label.metric] ? item[label.metric] : 0;
                            }
                        });
                        return label;
                    });
                    for (let label in labels_value) {
                        dataset.data.push(labels_value[label]);
                        if (amount_of_datasources > 1) {
                            dataset.backgroundColor.push(datasources[datasource].hex);
                            dataset.borderColor.push(datasources[datasource].hex);
                            data.labels.push(datasources[datasource].name);
                        }
                    }
                }
                data.datasets.push(dataset);
                if (amount_of_datasources < 2) {
                    let colors = [];
                    if (selected_datasource.channel === "google") {
                        var google = ["#0a42a4", "#0b4ab7", "#0c52ca", "#0d5ade", "#0e61f1", "#216ef2", "#357bf3", "#4887f4", "#5b94f5", "#6ea0f7", "#82adf8", "#a8c6fa", "#bcd3fb"];
                        colors = google;
                    }
                    if (selected_datasource.channel === "facebook") {
                        var facebook = ["#36528c", "#3b5b9b", "#4163aa", "#466cb9", "#5578be", "#6484c4", "#738fc9", "#738fc9", "#90a7d5", "#9fb3da", "#aebee0", "#bdcae6", "#cbd6eb"];
                        colors = facebook;
                    }
                    if (selected_datasource.channel === "adform") {
                        var adform = ["#368b72", "#3c9a7e", "#42a98a", "#48b796", "#56bd9e", "#65c3a7", "#74c9af", "#82ceb8", "#91d4c0", "#a0dac8", "#aee0d1", "#bde5d9", "#ccebe2"];
                        colors = adform;
                    }
                    if (selected_datasource.channel === "google_analytics") {
                        var google_analytics = ["#e16e0a", "#f4770b", "#f5821e", "#f68d32", "#f79845", "#f8a359", "#f9ad6c", "#fab880", "#fac393", "#fbcea7", "#fcd9bb", "#fde4ce", "#feefe2"];
                        colors = google_analytics;
                    }
                    new_labels.map((label, index) => {
                        if (index > (colors.length - 1)) {
                            let number = Math.floor(index % 10);
                            dataset.backgroundColor.push(colors[number]);
                            dataset.borderColor.push(colors[number]);
                        } else {
                            dataset.backgroundColor.push(colors[index]);
                            dataset.borderColor.push(colors[index]);
                        }
                        data.labels.push(label.dimension);
                    })
                }
            }
        }
        return data;
    }

    static table(items, cell) {
        let metrics = [];
        for (let metric in cell.metrics) {
            metrics.push({
                title: cell.metrics[metric].name,
                subtitle: cell.metrics[metric].calculated ? "calculated" : "custom",
                metric: cell.metrics[metric].name,
                value: cell.metrics[metric]
            });
        }
        metrics = metrics.filter((v, i, a) => a.findIndex(t => (t.metric === v.metric)) === i);
        if (cell.table_metrics_index && cell.metrics) {
            metrics = metrics.filter((item) => {
                return (item.metric in cell.metrics)
            }).map((item) => {
                cell.table_metrics_index.map((inner_item, index) => {
                    if (inner_item.name === item.metric) {
                        item.index = index;
                    }
                });
                return item;
            });
        }
        metrics = metrics.sort((a, b) => a.index - b.index);
        return { metrics: metrics, items: items }
    }

    static number(items, cell) {
        let total = 0;
        let collect_values_for_calculate_metric = {};
        let custom_metric = {};
        for (let metric in cell.metrics) {
            custom_metric = cell.metrics[metric];
        }

        items.map((item, index) => {
            if (custom_metric.calculated) {
                for (let key in custom_metric.metrics) {
                    let channel_metric = custom_metric.metrics[key];
                    if (channel_metric) {
                        if (!collect_values_for_calculate_metric[key]) {
                            collect_values_for_calculate_metric[key] = 0;
                        }
                        if (channel_metric.name in item) {
                            collect_values_for_calculate_metric[key] += +item[channel_metric.name];
                        }
                    }
                }
            } else {
                if (custom_metric && item[custom_metric.name]) {
                    total += +item[custom_metric.name];
                }
            }
        });
        if (Object.keys(collect_values_for_calculate_metric).length > 0 && custom_metric.calculation) {
            let calc_string = custom_metric.calculation;
            for (let key in collect_values_for_calculate_metric) {
                if (calc_string.indexOf("<" + key + ">") !== -1) {
                    calc_string = calc_string.replace("<" + key + ">", collect_values_for_calculate_metric[key]);
                }
            }
            total = cellData.calculate(calc_string);
            if (total == NaN) {
                total = 0;
            }
        }
        try {
            total = total.toFixed(2);
        } catch (error) { }
        try {
            total = parseFloat(total);
        } catch (error) { }
        return total;
    }

    static hexToRgb(aRgbHex) {
        let new_aRgbHex = aRgbHex.replace("#", "");
        if (new_aRgbHex.length != 6) {
            return aRgbHex;
        } else {
            let parsed_aRgbHex = new_aRgbHex.match(/.{1,2}/g);
            var aRgb = [
                parseInt(parsed_aRgbHex[0], 16),
                parseInt(parsed_aRgbHex[1], 16),
                parseInt(parsed_aRgbHex[2], 16)
            ];
            return "rgba(" + aRgb[0] + "," + aRgb[1] + "," + aRgb[2] + ", 0.5)";
        }
    }

    static roundUp(item) {
        try {
            if (item === undefined) {
                return "";
            } else {
                if (cellData.isNumeric(item)) {
                    return Math.round((item + Number.EPSILON) * 100) / 100;
                } else {
                    return item;
                }
            }
        } catch (e) {
            return item;
        }
    }

    static isNumeric(str) {
        if (typeof str === "number") {
            return true;
        } else {
            return !isNaN(str) && !isNaN(parseFloat(str))
        }
    }

    static calculate(input) {
        var f = {
            add: '+',
            sub: '-',
            div: '/',
            mlt: '*',
            mod: '%',
            exp: '^'
        };

        // Create array for Order of Operation and precedence
        f.ooo = [
            [
                [f.mlt],
                [f.div],
                [f.mod],
                [f.exp]
            ],
            [
                [f.add],
                [f.sub]
            ]
        ];

        input = input.replace(/[^0-9%^*\/()\-+.]/g, ''); // clean up unnecessary characters

        var output;
        for (var i = 0, n = f.ooo.length; i < n; i++) {

            // Regular Expression to look for operators between floating numbers or integers
            var re = new RegExp('(\\d+\\.?\\d*)([\\' + f.ooo[i].join('\\') + '])(\\d+\\.?\\d*)');
            re.lastIndex = 0; // take precautions and reset re starting pos

            // Loop while there is still calculation for level of precedence
            while (re.test(input)) {
                output = _calculate(RegExp.$1, RegExp.$2, RegExp.$3);
                if (isNaN(output) || !isFinite(output))
                    return output; // exit early if not a number
                input = input.replace(re, output);
            }
        }

        return output;

        function _calculate(a, op, b) {
            a = a * 1;
            b = b * 1;
            switch (op) {
                case f.add:
                    return a + b;
                    break;
                case f.sub:
                    return a - b;
                    break;
                case f.div:
                    return a / b;
                    break;
                case f.mlt:
                    return a * b;
                    break;
                case f.mod:
                    return a % b;
                    break;
                case f.exp:
                    return Math.pow(a, b);
                    break;
                default:
                    return null;
            }
        }
    }

    static getDaysBetweenDates(startDate, endDate) {
        var now = startDate.clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        return dates;
    }

}

export { cellData }