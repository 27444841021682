import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ArrowsExpandIcon, BeakerIcon, CameraIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, CollectionIcon, CubeIcon, ExclamationIcon, EyeIcon, FolderIcon, GiftIcon, MinusIcon, NewspaperIcon, PlusIcon, SearchCircleIcon, TrashIcon, VideoCameraIcon, ViewGridIcon, AnnotationIcon, FilmIcon, TemplateIcon, ExternalLinkIcon, ClipboardListIcon, PuzzleIcon, BanIcon, SparklesIcon, PaperAirplaneIcon } from '@heroicons/react/outline';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import SwitchTailwind from './switchTailwind';
import ToolTip from './toolTip';
import { useRouteMatch } from 'react-router';

import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';

class CreateOrderCreativePush extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            assistant_index: 0,
            OpenAiResult: [],
            searchOpenAi: "",
            ai_loading: false,
            thread: false
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            client: this.props.client ? this.props.client : this.state.client,
        });
        this.functions.newAI();
        //this.calls.openAi2({}, true);
        // console.log("STATE FILES", this.state.files)
    }
    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            client: nextProps.client ? nextProps.client : this.state.client,
        });
    }
    functions = {
        scroll: () => {
            return new Promise(async (resolve) => {
                try {
                    let myDiv = document.getElementById('chat');
                    myDiv.scrollTo(0, 100000000000);
                } catch (error) {
                }
                resolve();
            })
        },
        typeWriter: () => {
            return new Promise(async (resolve) => {
                try {

                    if (this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content !== "") {
                        await this.promisedSetState({
                            assistant_index: this.state.OpenAiResult.length - 1
                        });
                        let element = document.getElementById('assistant_' + (this.state.OpenAiResult.length - 1));

                        for (let i = 0; i < this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.length; i++) {
                            element.innerHTML += this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.charAt(i);
                            await new Promise(resolve => setTimeout(resolve, 10));
                            await this.functions.scroll();
                        }
                        await this.promisedSetState({
                            assistant_index: 99999
                        });
                    }
                } catch (error) {
                }
                resolve();
            });
        },
        newAI: async () => {
            let data = {
                text: this.state.searchOpenAi,
            }
            this.state.OpenAiResult.push({ role: "user", content: this.state.searchOpenAi });
            await this.promisedSetState({
                OpenAiResult: this.state.OpenAiResult,
                ai_loading: true,
                searchOpenAi: ""
            })
            await this.functions.scroll();
            try {
                let response = await this.calls.openAi2(data, this.state.thread);
                if (!response.data.message.url) {
                    this.state.OpenAiResult.push({ role: response.data.message.role, content: response.data.message.content[0].text.value });
                } else {
                    this.state.OpenAiResult.push({ role: response.data.message.role, content: "", image: response.data.message.url });
                }
                await this.promisedSetState({
                    OpenAiResult: this.state.OpenAiResult,
                    ai_loading: false,
                    thread: response.data.thread_id
                })
                await this.functions.scroll();
                await this.functions.typeWriter();
            } catch (error) {
                await this.promisedSetState({
                    //OpenAiResult: this.state.OpenAiResult,
                    ai_loading: false,
                    //thread: response.data.thread_id
                })
            }
        },
        openAi: async (init = false, failed = false, count = 0) => {

            let data = {
                message: this.state.OpenAiResult.filter((item) => { return !item.fake }).map((item) => { return { role: item.role, content: item.content } }),
                language: this.state.language.name,
                keyword_list: this.state.keywordList ? this.state.keywordList.keywords.map((item) => { return item.keyword }) : [],
            }
            if (!init && this.state.searchOpenAi !== "") {
                data.message.push(
                    {
                        role: "user",
                        content: this.state.searchOpenAi
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: this.state.searchOpenAi });
            }
            if (failed) {
                data.message.push(
                    {
                        role: "user",
                        content: "Return in requested JSON format without any other comments"
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: "Return in requested JSON format without any other comments", not_show: true });
            }


            await this.promisedSetState({
                OpenAiResult: this.state.OpenAiResult,
                ai_loading: true,
                searchOpenAi: ""
            })
            await this.functions.scroll();
            //await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data, init);
                let temp = JSON.parse(JSON.stringify(response.data));
                if (response.data.content.includes("***") || response.data.content.includes("```") && false) {
                    let regex = /\*\*\*(.*?)\*\*\*/s;
                    if (response.data.content.includes("```")) {
                        regex = /```(.*?)```/s;
                    }
                    temp.not_show = true;
                    this.state.OpenAiResult.push(temp);

                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult
                    })
                    let buffer = response.data.content.match(regex);
                    if (buffer && buffer[1]) {
                        response.data.content = response.data.content.replace(buffer[0], "*+*");
                        let a = JSON.parse(buffer[1]);
                        let headlines = a.Google && a.Google.Headline ? a.Google.Headline : [];
                        let descriptions = a.Google && a.Google.Description ? a.Google.Description : [];
                        let parts = response.data.content.split("*+*");
                        if (parts[0] !== "") {
                            if (headlines.length < 1 && descriptions.length < 1 && count < 2) {
                                //this.state.OpenAiResult.push({ role: "user", fake: true, content: parts[0] });
                                this.functions.openAi(false, true, count + 1);
                                return;

                            } else {
                                this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[0] });
                                await this.promisedSetState({
                                    OpenAiResult: this.state.OpenAiResult,
                                    ai_loading: false,
                                })
                                await this.functions.scroll();
                                await this.functions.typeWriter();
                            }
                        }


                        if (Array.isArray(headlines) && headlines.length > 0 && this.state.ai_action.id === 2) {
                            this.props.onAddHeadlines(headlines);
                        }
                        if (Array.isArray(descriptions) && descriptions.length > 0 && this.state.ai_action.id === 2) {
                            this.props.onAddDescriptions(descriptions);
                        }
                        this.state.OpenAiResult.push({ role: "assistant", fake: true, content: "", headlines: headlines, descriptions: descriptions });
                        await this.promisedSetState({
                            OpenAiResult: this.state.OpenAiResult,
                            ai_loading: false,
                        })
                        await this.functions.scroll();
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        if (parts[1] !== "") {
                            this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[1].replace(/\n/g, "") });
                            await this.promisedSetState({
                                OpenAiResult: this.state.OpenAiResult,
                                ai_loading: false,
                            })
                            await this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                    }
                } else {
                    this.state.OpenAiResult.push(response.data);
                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult,
                        ai_loading: false,
                    })
                    await this.functions.scroll();
                    await this.functions.typeWriter();
                }
                //this.props.onUpdateChat(this.state.OpenAiResult);
                console.log(response, "response");
            } catch (error) {

            }

        },
    };

    calls = {
        openAi: (data, init) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAiAssistant?client=" + this.state.client.id + "&init=" + init;
            return apiRegister.call(options, url);
        },
        openAi2: (data, init) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAiAssistantCreado?client=" + this.state.client.id + (init ? "&thread=" + init : "");
            return apiRegister.call(options, url);
        },
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    wait = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    render() {
        return (
            <>
                <div className={`flex h-full flex-col overflow-hidden`}>
                    <div className='flex-1 flex h-full bg-white rounded-md border-gray-300 border overflow-hidden'>
                        <div style={{ scrollBehavior: "smooth" }} id="chat" className='h-full overflow-scroll flex flex-col flex-1 relative '>
                            {
                                this.state.OpenAiResult.map((item, index) => {
                                    return (
                                        <Fragment>
                                            {
                                                item.role === "assistant" && !item.not_show &&
                                                <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-start mt-2 ml-2'}>
                                                    <div className='flex flex-col'>
                                                        <div className='flex items-center justify-start'>
                                                            <div className='bg-gray-100 rounded-full flex justify-center items-center h-7 w-7'>
                                                                <SparklesIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                            </div>
                                                            <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                                        </div>
                                                        <div className='flex items-start bg-gray-100 text-black rounded-md m-2 p-2 w-auto flex-col text-sm'>
                                                            <p className=' whitespace-pre-wrap ' id={"assistant_" + index} dangerouslySetInnerHTML={{ __html: index !== this.state.assistant_index ? item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") : "" }} ></p>
                                                            {
                                                                item.image &&
                                                                <div className='w-full h-auto'>
                                                                    <img src={item.image} className='w-full h-auto' />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                item.role === "user" && !item.not_show &&
                                                <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-end'}>
                                                    <div className='flex flex-col'>
                                                        <div className='flex items-end bg-purple-500 text-white rounded-md m-2 p-2 w-auto typewriter text-sm'>
                                                            <p className=' whitespace-pre-wrap ' dangerouslySetInnerHTML={{ __html: item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") }} ></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                this.state.ai_loading &&
                                <div className={'flex justify-start'}>
                                    <div id={"index_" + (this.state.OpenAiResult.length + 1)} className='flex flex-col ml-2 mt-2'>
                                        <div className='flex items-center justify-start'>
                                            <div className='bg-gray-100 rounded-full flex text-purple-500 justify-center items-center h-7 w-7'>
                                                <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                                            </div>
                                            <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                        </div>
                                        <div className='flex items-start bg-gray-100 text-white rounded-md m-2 p-2 w-20 relative'>
                                            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-800 opacity-75 top-0 left-0"></span>
                                            <div className=' w-4 h-4 rounded-full bg-gray-300 animate-pulse mr-2'>
                                            </div>
                                            <div className=' w-4 h-4 rounded-full bg-gray-500 animate-pulse mr-2'>
                                            </div>
                                            <div className=' w-4 h-4 rounded-full bg-gray-700 animate-pulse'>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='flex flex-row h-full mt-2 pb-2'>
                            <div className='flex flex-1 w-full'>
                                <div className='w-full inline-flex relative'>
                                    <textarea
                                        rows={2}
                                        className={" bg-custom-input rounded-md block w-full border-1.5 pr-10 px-2 py-2 focus:outline-none sm:text-sm"}
                                        value={this.state.searchOpenAi}
                                        onChange={async (event) => {
                                            if (event.target.value !== "") { this.functions.scroll(); }
                                            if (!this.state.ai_loading) { await this.promisedSetState({ searchOpenAi: event.target.value }) }

                                        }}
                                        onKeyDown={async (event) => {
                                            try {
                                                if (event.keyCode === 13 && this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                    if (!event.shiftKey) {
                                                        await this.functions.scroll();
                                                        this.functions.newAI();
                                                    }
                                                }
                                            } catch (e) { }
                                        }}
                                    />
                                    <button
                                        onClick={async () => {
                                            if (this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                this.functions.scroll();
                                                this.functions.newAI();
                                            }
                                        }}
                                        className={(this.state.ai_loading ? "bg-gray-300 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 cursor-pointer") + " items-center h-full inline-flex shadow absolute right-0 bottom-0 justify-center rounded-md border border-transparent  py-2 px-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <PaperAirplaneIcon className='h-5 w-5' style={{ transform: "rotate(90deg)" }} />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default CreateOrderCreativePush;
