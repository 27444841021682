import React, { Component } from 'react';
import cx from "classnames";
import { dataRegister } from "../../services/dataRegister";
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import Input from "../../components/input";
import ClientFacebook from "../../components/client-facebook";
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import UploadImage from "../../components/image-upload";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";
import { userRegister } from "../../services/userRegister";

class UserTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            open: false,
            loading: false,
            warning_modal: false,
            error_modal: false,
            error_message: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item
        })
    }

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false
            }, () => {
                calls.removeUser(this.state.item._id).then((response) => {
                    this.setState({
                        loading: false,
                        open: false
                    }, () => {
                        this.props.onRemove(this.state.item._id);
                    });
                }, (error) => {
                    this.setState({
                        error_modal: true,
                        loading: false,
                        error_message: error.body.message
                    });
                });
            })
        },
        open: () => {
            if (window.location.hostname === 'localhost') {
                window.open("http://localhost:5001/login/" + this.state.item._id);
            } else if (window.location.hostname === 'app.adcredo.io') {
                window.open("https://app.adcredo.io/login/" + this.state.item._id);
            } else if (window.location.hostname === 'dev.adcredo.io') {
                window.open("https://dev.adcredo.io/login/" + this.state.item._id);
            }
        },
        welcome: () => {
            if (window.location.hostname === 'localhost') {
                window.open("http://localhost:5001/set-password/" + this.state.item.resetPasswordToken);
            } else if (window.location.hostname === 'app.adcredo.io') {
                window.open("https://app.adcredo.io/set-password/" + this.state.item.resetPasswordToken);
            } else if (window.location.hostname === 'dev.adcredo.io') {
                window.open("https://dev.adcredo.io/set-password/" + this.state.item.resetPasswordToken);
            }
        }
    };

    renders = {};

    render() {
        return (
            <tr className="">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove user"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({ warning_modal: false });
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />

                <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="font-bold leading-5 text-gray-900 capitalize text-sm">
                        {this.state.item.name}
                    </div>
                    <div class="leading-5 text-gray-500 text-xs">
                        {this.state.item.email}
                    </div>

                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm capitalize">
                    {userRegister.get().type === 'admin' ? this.state.item.type : '-'}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 capitalize">
                    {userRegister.get().type === 'admin' && this.state.item.agency ? this.state.item.agencyName : '-'}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 capitalize">
                    {userRegister.get().type === 'admin' && this.state.item.client ? this.state.item.clientName : '-'}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap capitalize">
                    <span className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                        {
                            ["bg-green-100 text-green-500"]: this.state.item.status === "active",
                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: this.state.item.status === "paused",
                            ["bg-yellow-100 text-yellow-600"]: this.state.item.status === "pending",
                        })
                    }>
                        {this.state.item.status}
                    </span>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                    {
                        <div className="flex flex-row justify-end">
                            {
                                userRegister.get().type === 'admin' && this.state.item.status === "active" &&
                                <button onClick={() => {
                                    this.functions.open();
                                }} className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="external-link" /> <span>Open</span>
                                </button>
                            }
                            {
                                userRegister.get().type === 'admin' && this.state.item.status === "pending" &&
                                <button onClick={() => {
                                    this.functions.welcome();
                                }} className="bg-gray-500 bg-opacity-25 text-gray-700 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-900 hover:bg-opacity-100 hover:text-white">
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="external-link" /> <span>Password url</span>
                                </button>
                            }
                            {
                                userRegister.get().id !== this.state.item._id &&
                                <button onClick={() => this.setState({
                                    warning_modal: true
                                })} className="ml-2 bg-red-300 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="trash" /> <span>Remove</span>
                                </button>
                            }
                        </div>
                    }
                </td>
            </tr>
        )
    }
}

export default UserTableRow;
