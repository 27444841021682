import React, { Component } from 'react';
import { ArrowLeftIcon, ArrowNarrowDownIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, CogIcon, CursorClickIcon, InformationCircleIcon, PlusIcon, TrashIcon, UploadIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import cn from "classnames";
import UploadImage from '../components/image-upload';

class CreateCustomPlatform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            start_line: 0,
            end_line: 1,
            status: { id: 0, name: "Active", value: "active" },
            logo: null,
            backgroundColor: "525358",
            metrics: [],
            breakdowns: [],
            properties: [],
            tab: { name: "Upload test data", value: "upload" },
            tabs: [{ name: "Upload test data", value: "upload" }],
            second_tab: { name: "Campaign", value: "campaign", type: 'campaign' },
            second_tabs: [
                //{ name: "Account", value: "account", type: 'account' },
                { name: "Campaign", value: "campaign", type: 'campaign' },
                { name: "Adgroup", value: "adgroup", type: 'adgroup' },
                { name: "Ad", value: "ad", type: 'ad' },
                { name: "Date", value: "dates", type: 'dates' },
                { name: "Breakdowns", value: "breakdowns", type: "breakdowns" },
                { name: "Metrics", value: "metrics", type: "metrics" }
            ],
            headers: [],
            lines: [],
            raw_lines: [],
            step: null,
            rows_pagination: 100,
            rows_page: 1,
            steps: [
                { id: 1, step: "one", type: "Platform information", icon: CogIcon },
                { id: 2, step: "two", type: "Upload example .csv file", icon: UploadIcon },
                { id: 3, step: "three", type: "Select which row column headers exist, Example: Date, Campaign, Clicks, Spend", icon: CursorClickIcon },
                { id: 4, step: "four", type: "Date", icon: ClipboardCheckIcon, type_value: "date", mandatory: true },
                //{ id: 5, step: "five", type: "Account ID", icon: ClipboardCheckIcon, type_value: "accountID" },
                //{ id: 6, step: "six", type: "Account Name", icon: ClipboardCheckIcon, type_value: "accountName" },
                { id: 5, step: "five", type: "Campaign ID", icon: ClipboardCheckIcon, type_value: "campaignID", mandatory: true },
                { id: 6, step: "six", type: "Campaign Name", icon: ClipboardCheckIcon, type_value: "campaignName", mandatory: true },
                { id: 7, step: "seven", type: "Adgroup ID", icon: ClipboardCheckIcon, type_value: "adgroupID" },
                { id: 8, step: "eight", type: "Adgroup Name", icon: ClipboardCheckIcon, type_value: "adgroupName" },
                { id: 9, step: "nine", type: "Ad ID", icon: ClipboardCheckIcon, type_value: "adID" },
                { id: 10, step: "ten", type: "Ad Name", icon: ClipboardCheckIcon, type_value: "adName" },
                { id: 11, step: "eleven", type: "Breakdowns", icon: ClipboardCheckIcon },
                { id: 12, step: "twelve", type: "Metrics", icon: ClipboardCheckIcon }
            ],
            loading: false
        };
    }

    async componentDidMount() {
        if (this.props.platform && this.props.platform.id) {
            this.state = { ...this.props.platform };
            this.state.step = { id: 13, step: "thirteen" };
            await this.promisedSetState(this.state);
            this.props.onStep(this.state.step, true);
        } else {
            await this.promisedSetState({
                step: { id: 1, step: "one" }
            });
            this.props.onStep(this.state.step, true);
        }
    }

    functions = {
        create: async () => {
            if (!this.state.loading) {
                this.props.onCreate();
                await this.promisedSetState({
                    error: false,
                    loading: true,
                    second_tab: { name: "Account", value: "account", type: 'account' },
                });
                try {
                    let response = this.props.platform ? await this.calls.update(this.state) : await this.calls.create(this.state);
                    this.props.onCreated(response.data);
                } catch (err) {
                    await this.promisedSetState({
                        error: err.body.message,
                        loading: false
                    });
                    this.props.onError();
                }
            }
        },
        setArrayData: (property, name, index, value) => {
            this.state[property][index][name] = value
            this.setState({
                [property]: this.state[property]
            })
        },
        addNewElement: (property) => {
            if (!Array.isArray(this.state[property])) {
                this.state[property] = [];
            }
            this.state[property].unshift({ name: '', value: '', type: 'string' });
            this.setState({
                [property]: this.state[property]
            });
        },
        removeElement: (property, index) => {
            if (index >= 0) {
                this.state[property].splice(index, 1);
                this.setState({
                    [property]: this.state[property]
                })
            }
        },
        setNext: async () => {
            if (this.state.step.step == "one") {
                await this.promisedSetState({
                    step: { id: 2, step: "two" }
                });
                this.props.onStep(this.state.step, !(Array.isArray(this.state.lines) && this.state.lines.length > 0));
            } else if (this.state.step.step == "two") {
                await this.promisedSetState({
                    step: { id: 3, step: "three" }
                });
                this.props.onStep(this.state.step, !(this.state.header_row !== null));
            } else if (this.state.step.step == "three") {
                await this.promisedSetState({
                    step: { id: 4, step: "four" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.date === "string" && this.state.date !== ""));
            } else if (this.state.step.step == "four") {
                await this.promisedSetState({
                    step: { id: 5, step: "five" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.campaignID === "string" && this.state.campaignID !== ""));
            } else if (this.state.step.step == "five") {
                await this.promisedSetState({
                    step: { id: 6, step: "six" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.campaignName === "string" && this.state.campaignName !== ""));
            } else if (this.state.step.step == "six") {
                await this.promisedSetState({
                    step: { id: 7, step: "seven" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "seven") {
                await this.promisedSetState({
                    step: { id: 8, step: "eight" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "eight") {
                await this.promisedSetState({
                    step: { id: 9, step: "nine" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "nine") {
                await this.promisedSetState({
                    step: { id: 10, step: "ten" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "ten") {
                await this.promisedSetState({
                    step: { id: 11, step: "eleven" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "eleven") {
                await this.promisedSetState({
                    step: { id: 12, step: "twelve" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "twelve") {
                await this.promisedSetState({
                    step: { id: 13, step: "thirteen" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "thirteen") {
                this.functions.create();
            }
        },
        setBack: async () => {
            if (this.state.step.step == "two") {
                await this.promisedSetState({
                    step: { id: 1, step: "one" }
                });
                this.props.onStep(this.state.step, !(this.state.name !== ""));
            } else if (this.state.step.step == "three") {
                await this.promisedSetState({
                    step: { id: 2, step: "two" }
                });
                this.props.onStep(this.state.step, !(Array.isArray(this.state.lines) && this.state.lines.length > 0));
            } else if (this.state.step.step == "four") {
                await this.promisedSetState({
                    step: { id: 3, step: "three" }
                });
                this.props.onStep(this.state.step, !(this.state.header_row !== null));
            } else if (this.state.step.step == "five") {
                await this.promisedSetState({
                    step: { id: 4, step: "four" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.date === "string" && this.state.date !== ""));
            } else if (this.state.step.step == "six") {
                await this.promisedSetState({
                    step: { id: 5, step: "five" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.campaignID === "string" && this.state.campaignID !== ""));
            } else if (this.state.step.step == "seven") {
                await this.promisedSetState({
                    step: { id: 6, step: "six" }
                });
                this.props.onStep(this.state.step, !(typeof this.state.campaignName === "string" && this.state.campaignName !== ""));
            } else if (this.state.step.step == "eight") {
                await this.promisedSetState({
                    step: { id: 7, step: "seven" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "nine") {
                await this.promisedSetState({
                    step: { id: 8, step: "eight" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "ten") {
                await this.promisedSetState({
                    step: { id: 9, step: "nine" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "eleven") {
                await this.promisedSetState({
                    step: { id: 10, step: "ten" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "twelve") {
                await this.promisedSetState({
                    step: { id: 11, step: "eleven" }
                });
                this.props.onStep(this.state.step, false);
            } else if (this.state.step.step == "thirteen") {
                await this.promisedSetState({
                    step: { id: 12, step: "twelve" }
                });
                this.props.onStep(this.state.step, false);
            }
        }
    };

    calls = {
        create: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createCustomPlatform";
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCustomPlatform?id=" + this.props.platform.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({
                loading_file: true,
                campaignID: "",
                campaignName: "",
                date: "",
                adgroupID: "",
                adgroupName: "",
                adID: "",
                adName: "",
                breakdowns: [],
                metrics: [],
                header_row: null
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvText = e.target.result;
                this.csvToJsonSetStartLine(csvText);
            };
            reader.readAsText(file);
        }
    };

    csvToJsonSetStartLine = async (csvText) => {
        const lines = csvText.split('\n');
        let result_json = [];
        for (let i = 0; i < lines.length; i++) {
            const currentLine = Splitter(lines[i]);
            result_json.push(currentLine)
        }
        await this.promisedSetState({
            step: { id: 3, step: "three" },
            raw_lines: lines.slice(0, 100),
            lines: result_json.slice(0, 100),
            loading_file: false
        });
        function Splitter(text) {
            var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
            var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            if (!re_valid.test(text)) return null;
            var a = [];
            text.replace(re_value,
                function (m0, m1, m2, m3) {
                    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                    else if (m3 !== undefined) a.push(m3);
                    return '';
                });
            if (/,\s*$/.test(text)) a.push('');
            return a;
        };
    };

    csvToJson = async (csvText) => {
        const lines = csvText ? csvText.split('\n') : this.state.raw_lines;
        let header_index = this.state.header_row;
        if (header_index == null) {
            lines.map((line, index) => {
                let date_exist = false;
                let campaign_exist = false;
                let headers = Splitter(line);
                headers.map((header) => {
                    header = header.replaceAll(/["\r]/g, "");
                    if (header == this.state.date) {
                        date_exist = true;
                    }
                    if (header == this.state.campaignID) {
                        campaign_exist = true;
                    }
                });
                if (!header_index && date_exist && campaign_exist) {
                    header_index = index;
                }
            });
        }
        const init_headers = Splitter(lines[header_index]);
        let parsed_headers = [];
        init_headers.map((header) => {
            parsed_headers.push({
                externalName: header.replaceAll(/["\r]/g, ""),
                internalName: header.replaceAll(/["\r]/g, ""),
                status: "",
                type: null
            });
        });
        await this.promisedSetState({
            step: { id: 4, step: 'four' },
            loading_file: false,
            headers: parsed_headers
        });
        this.props.onStep(this.state.step, !(typeof this.state.date === "string" && this.state.date !== ""));
        function Splitter(text) {
            var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
            var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
            if (!re_valid.test(text)) return null;
            var a = [];
            text.replace(re_value,
                function (m0, m1, m2, m3) {
                    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                    else if (m3 !== undefined) a.push(m3);
                    return '';
                });
            if (/,\s*$/.test(text)) a.push('');
            return a;
        };
    };

    render() {
        return (
            <div className="grid-cols-12 gap-4 h-full grid">

                <div className="col-span-6 h-full ">
                    {
                        <div className={"col-span-9 flex flex-col h-full pb-4"}>
                            <div className={(this.state.step && this.state.step.step !== "thirteen" && this.state.step.step !== "one" ? "overflow-hidden" : "") + " w-full h-full flex-col flex border-1.5 bg-custom-input rounded-md"}>
                                <div className={(this.state.step && this.state.step.step !== "thirteen" && this.state.step.step !== "one" ? "overflow-hidden" : "") + " flex w-full flex-col relative flex-1"}>
                                    <div className="absolute h-full w-full">
                                        {
                                            this.state.step &&
                                            (this.state.step.step == "one" || this.state.step.step == "thirteen") &&
                                            <div className="flex flex-1 h-full justify-center items-center flex-col">
                                                <div style={{ backgroundColor: this.state.backgroundColor ? "#" + (this.state.backgroundColor) : "#525358" }} className=" w-48 h-48 rounded-full flex items-center justify-center mb-8 relative">
                                                    {
                                                        !this.state.logo && typeof this.state.name == "string" && this.state.name.length > 1 &&
                                                        <div className="font-semibold text-6xl text-white uppercase">
                                                            {this.state.name[0] + "" + this.state.name[1]}
                                                        </div>
                                                    }
                                                    {
                                                        this.state.logo &&
                                                        <img src={this.state.logo} className={"w-20"} />
                                                    }
                                                    <div style={{ bottom: "8px", right: "20px" }} className="absolute w-8 h-8 bg-black rounded-full cursor-pointer border-1.5 border-white overflow-hidden">
                                                        {
                                                            !this.state.logo &&
                                                            <div className="relative w-full h-full flex items-center justify-center">
                                                                <CameraIcon className="text-white w-4" />
                                                                <div className="absolute opacity-0 cursor-pointer">
                                                                    <UploadImage
                                                                        height={"h-12"}
                                                                        color={this.state.logo ? this.state.backgroundColor : null}
                                                                        imageOnly={true}
                                                                        title={"Upload white icon (png)"}
                                                                        maxWidth="max-w-7"
                                                                        size={"full"}
                                                                        cover={false}
                                                                        logo={true}
                                                                        value={this.state.logo}
                                                                        error={this.state.error === "logo" && !this.state.logo}
                                                                        onChange={async (url) => {
                                                                            await this.promisedSetState({ logo: url });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.logo &&
                                                            <div onClick={() => {
                                                                this.setState({
                                                                    logo: undefined
                                                                });
                                                            }} className="relative w-full h-full flex items-center justify-center">
                                                                <div><XIcon className="text-white w-4" /></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="relative w-68">
                                                    <div className="w-full mb-8">
                                                        <InputTailwind
                                                            label={"Platform name"}
                                                            value={this.state.name}
                                                            onChange={async (value) => {
                                                                await this.promisedSetState({
                                                                    name: value
                                                                });
                                                                if (this.state.name !== "") {
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.step &&
                                                        this.state.step.step === "one" &&
                                                        <div style={{ right: "-115px", top: "35px" }} className="absolute">
                                                            <div className="items-center justify-center flex-row flex text-sm font-medium">
                                                                <div className="mr-2">
                                                                    <ArrowLeftIcon className="w-5" />
                                                                </div>
                                                                Start here
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="w-68 relative">
                                                    <div className="w-full mb-8">
                                                        <div className="text-xs mb-1 flex flex-row font-medium text-gray-700">
                                                            Platform color
                                                        </div>
                                                        <div className={"w-full mb-4 border-1.5 rounded-md"}>
                                                            <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                                <div
                                                                    style={{ backgroundColor: "#FCFCFC" }}
                                                                    className="flex items-center col-span-3 p-1">
                                                                    <span className={`pl-3`}>#</span>
                                                                    <input
                                                                        id="backgroundColor"
                                                                        className="w-full border-none p-2 bg-transparent"
                                                                        type="text"
                                                                        placeholder='FCFCFC'
                                                                        value={this.state.backgroundColor}
                                                                        onClick={async (e) => {
                                                                            await this.promisedSetState({
                                                                                selectedId: e.target.id
                                                                            })
                                                                        }}
                                                                        onChange={async (e) => {
                                                                            e.target.value = e.target.value.replace(/#/g, "")
                                                                            await this.promisedSetState({
                                                                                backgroundColor: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                                    <input
                                                                        style={{ width: 110, height: 65, bottom: -10 }}
                                                                        className="absolute cursor-pointer"
                                                                        type="color"
                                                                        value={"#" + (this.state.backgroundColor)}
                                                                        onChange={async (e) => {
                                                                            let color_string = e.target.value.slice(1)
                                                                            await this.promisedSetState({
                                                                                backgroundColor: color_string
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.props.admin &&
                                                    <div className="relative w-68">
                                                        <div className="w-full">
                                                            <DropdownTailwind
                                                                label={"Status"}
                                                                selected={this.state.status}
                                                                options={[
                                                                    { id: 0, name: "Active", value: "active" },
                                                                    { id: 1, name: "Disabled", value: "disabled" }
                                                                ]}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        status: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {
                                            this.state.step &&
                                            this.state.step.step == "two" &&
                                            <div className="rounded-md absolute h-full w-full cursor-pointer flex justify-center items-center" onClick={() => document.getElementById('file').click()}>
                                                <div className="relative">
                                                    <div className="items-center animate-bounce justify-center flex-col flex text-sm font-medium">
                                                        Click here
                                                        <div className="mt-2">
                                                            <ArrowNarrowDownIcon className="w-5" />
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <input type="file" accept='.csv' className='hidden' id="file" onChange={this.handleFileUpload} />
                                                        <div className="font-medium rounded-full overflow-hidden relative inline-flex flex-row cursor-pointer py-2 px-4 bg-purple-100 text-sm text-purple-500">
                                                            {
                                                                !(Array.isArray(this.state.lines) && this.state.lines.length > 0) &&
                                                                <span>Upload .csv file</span>
                                                                ||
                                                                <span>Upload new file</span>
                                                            }
                                                            <UploadIcon className="w-5 ml-2" />
                                                            {
                                                                this.state.loading_file &&
                                                                <div className="absolute left-0 right-0 top-0 bottom-0 flex w-full h-full items-center bg-purple-100 justify-center">
                                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500  border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.step &&
                                            this.state.step.step == "three" &&
                                            Array.isArray(this.state.lines) && this.state.lines.length > 0 &&
                                            <div className="overflow-scroll h-full">
                                                <table className="w-full table-fixed">
                                                    <tr className="border-b shadow-md bg-gray-50 sticky top-0 z-20">

                                                        {/*ROW*/}
                                                        <th className="px-4 p-2 text-left text-xs font-medium">
                                                            Row
                                                        </th>

                                                    </tr>
                                                    {
                                                        this.state.lines.map((item, index) => {
                                                            return (
                                                                <tr
                                                                    onClick={async () => {
                                                                        await this.promisedSetState({
                                                                            header_row: index
                                                                        });
                                                                        this.csvToJson();
                                                                    }}
                                                                    className={(index == this.state.header_row ? "bg-gray-100 opacity-100 " : "") + (index !== 0 ? "border-t " : "") + " hover:bg-gray-100 hover:bg-opacity-75 opacity-50 hover:opacity-100 cursor-pointer"}>
                                                                    <th className="px-4 p-2 text-xs font-light">
                                                                        <div className="truncate">{item.join(",")}</div>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            </div>
                                        }

                                        {
                                            this.state.step && this.state.step.id >= 4 && this.state.step.id < 13 &&
                                            Array.isArray(this.state.headers) && this.state.headers.length > 0 &&
                                            <div className="overflow-scroll h-full">
                                                {
                                                    Array.isArray(this.state.headers) && this.state.headers.length > 0 &&
                                                    <table className="w-full table-fixed">
                                                        <tr className="border-b shadow-md bg-gray-50 sticky top-0 z-20">

                                                            {/*HEADER NAME*/}
                                                            <th className="px-4 p-2 text-left text-xs font-medium">
                                                                Column name
                                                            </th>

                                                        </tr>
                                                        {
                                                            this.state.headers.filter((item, index) => {
                                                                return index < (this.state.rows_page * this.state.rows_pagination);
                                                            }).filter((item) => {
                                                                let dont_show = false;
                                                                if (!dont_show && this.state.step.step !== "four") {
                                                                    dont_show = item.externalName === this.state.date;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "five" && this.state.step.step !== "six") {
                                                                    dont_show = item.externalName === this.state.campaignID;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "five" && this.state.step.step !== "six") {
                                                                    dont_show = item.externalName === this.state.campaignName;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "seven" && this.state.step.step !== "eight") {
                                                                    dont_show = item.externalName === this.state.adgroupID;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "seven" && this.state.step.step !== "eight") {
                                                                    dont_show = item.externalName === this.state.adgroupName;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "nine" && this.state.step.step !== "ten") {
                                                                    dont_show = item.externalName === this.state.adID;
                                                                }
                                                                if (!dont_show && this.state.step.step !== "nine" && this.state.step.step !== "ten") {
                                                                    dont_show = item.externalName === this.state.adName;
                                                                }
                                                                if (!dont_show && Array.isArray(this.state.breakdowns) && this.state.breakdowns.length > 0) {
                                                                    this.state.breakdowns.map((breakdown) => {
                                                                        if (item.externalName === breakdown.value) {
                                                                            dont_show = true;
                                                                        }
                                                                    });
                                                                }
                                                                if (!dont_show && Array.isArray(this.state.metrics) && this.state.metrics.length > 0) {
                                                                    this.state.metrics.map((metric) => {
                                                                        if (item.externalName === metric.value) {
                                                                            dont_show = true;
                                                                        }
                                                                    });
                                                                }
                                                                return !dont_show;
                                                            }).map((item, index) => {
                                                                return (
                                                                    <tr
                                                                        onClick={async () => {
                                                                            if (this.state.step) {
                                                                                if (this.state.step.step === "four") {
                                                                                    item.internalName = "Date";
                                                                                    item.type = { id: 8, name: "Date", value: "date", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 5, step: 'five' },
                                                                                        date: item.externalName,
                                                                                    });
                                                                                    this.props.onStep(this.state.step, !(typeof this.state.campaignID === "string" && this.state.campaignID !== ""));
                                                                                }
                                                                                else if (this.state.step.step === "five") {
                                                                                    item.internalName = "campaignID";
                                                                                    item.type = { id: 0, name: "Campaign ID", value: "campaignID", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 6, step: 'six' },
                                                                                        campaignID: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, !(typeof this.state.campaignName === "string" && this.state.campaignName !== ""));
                                                                                }
                                                                                else if (this.state.step.step === "six") {
                                                                                    item.internalName = "campaignName";
                                                                                    item.type = { id: 0, name: "Campaign Name", value: "campaignName", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 7, step: 'seven' },
                                                                                        campaignName: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, false);
                                                                                }
                                                                                else if (this.state.step.step === "seven") {
                                                                                    item.internalName = "adgroupID";
                                                                                    item.type = { id: 0, name: "Adgroup ID", value: "adgroupID", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 8, step: 'eight' },
                                                                                        adgroupID: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, false);
                                                                                }
                                                                                else if (this.state.step.step === "eight") {
                                                                                    item.internalName = "adgroupName";
                                                                                    item.type = { id: 0, name: "Adgroup Name", value: "adgroupName", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 9, step: 'nine' },
                                                                                        adgroupName: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, false);
                                                                                }
                                                                                else if (this.state.step.step === "nine") {
                                                                                    item.internalName = "adID";
                                                                                    item.type = { id: 0, name: "Ad ID", value: "adID", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 10, step: 'ten' },
                                                                                        adID: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, false);
                                                                                }
                                                                                else if (this.state.step.step === "ten") {
                                                                                    item.internalName = "adName";
                                                                                    item.type = { id: 0, name: "Ad Name", value: "adName", locked: true };
                                                                                    await this.promisedSetState({
                                                                                        step: { id: 11, step: 'eleven' },
                                                                                        adName: item.externalName
                                                                                    });
                                                                                    this.props.onStep(this.state.step, false);
                                                                                }
                                                                                else if (this.state.step.step === "eleven") {
                                                                                    item.internalName = item.internalName.toLowerCase();
                                                                                    item.internalName = item.internalName[0].toUpperCase() + item.internalName.slice(1);
                                                                                    if (Array.isArray(this.state.breakdowns)) {
                                                                                        let exist = false;
                                                                                        this.state.breakdowns.map((breakdown) => {
                                                                                            if (breakdown.value == item.externalName) {
                                                                                                exist = true;
                                                                                            }
                                                                                        });
                                                                                        if (!exist) {
                                                                                            this.state.breakdowns.unshift({ name: item.internalName, value: item.externalName });
                                                                                        }
                                                                                    }
                                                                                    await this.promisedSetState({
                                                                                        breakdowns: this.state.breakdowns
                                                                                    });
                                                                                }
                                                                                else if (this.state.step.step === "twelve") {
                                                                                    item.internalName = item.internalName.toLowerCase();
                                                                                    item.internalName = item.internalName[0].toUpperCase() + item.internalName.slice(1);
                                                                                    if (Array.isArray(this.state.metrics)) {
                                                                                        let exist = false;
                                                                                        this.state.metrics.map((metric) => {
                                                                                            if (metric.value == item.externalName) {
                                                                                                exist = true;
                                                                                            }
                                                                                        });
                                                                                        if (!exist) {
                                                                                            this.state.metrics.unshift({ name: item.internalName, value: item.externalName });
                                                                                        }
                                                                                    }
                                                                                    await this.promisedSetState({
                                                                                        metrics: this.state.metrics
                                                                                    });
                                                                                }
                                                                                await this.promisedSetState({
                                                                                    headers: this.state.headers
                                                                                });
                                                                            }
                                                                        }}
                                                                        className={(index !== 0 ? "border-t " : "") + (this.state.step ? " hover:bg-gray-100 opacity-50 hover:opacity-100 cursor-pointer" : "")}>

                                                                        {/*HEADER NAME*/}
                                                                        {
                                                                            false &&
                                                                            <th className="px-4 py-4 truncate cursor-pointer font-medium text-left">
                                                                                <div className="truncate text-sm">{item.externalName}</div>
                                                                            </th>
                                                                        }

                                                                        <th className="px-4 p-2 text-xs font-light text-left">
                                                                            <div className="truncate">{item.externalName}</div>
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </table>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.step && this.state.step.id >= 4 && this.state.step.id < 13 &&
                                    Array.isArray(this.state.headers) && this.state.headers.length > 0 &&
                                    Array.isArray(this.state.headers) && this.state.headers.length > 99 &&
                                    <div>
                                        <div className="w-full py-2 bg-gray-50 border-t flex justify-center items-center">
                                            <button
                                                onClick={async () => {
                                                    if ((this.state.headers.length > (this.state.rows_page * this.state.rows_pagination))) {
                                                        this.setState({
                                                            rows_page: this.state.rows_page + 1
                                                        });
                                                    }
                                                }}
                                                className={(!(this.state.headers.length > (this.state.rows_page * this.state.rows_pagination)) ? "cursor-not-allowed opacity-50" : "cursor-pointer hover:border-gray-500") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                {
                                                    (this.state.headers.length > (this.state.rows_page * this.state.rows_pagination)) &&
                                                    <span>Load more ({this.state.rows_page * this.state.rows_pagination}/{this.state.headers.length})</span>
                                                    ||
                                                    <span>All loaded ({this.state.headers.length})</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {
                    this.state.step &&
                    this.state.step.step !== 'thirteen' &&
                    <div className="col-span-6 relative overflow-hidden">
                        <div className="absolute w-full h-full flex flex-col">
                            {
                                this.state.steps.map((item) => {
                                    if (this.state.step && item.id < this.state.step.id) {
                                        item.hide = true;
                                    } else {
                                        item.hide = false;
                                    }
                                    return item;
                                }).map((item) => {
                                    return (
                                        <div style={item.hide ? { marginTop: "-" + 88 + "px" } : {}} className={(this.state.step.step == item.step ? " bg-blue-200 " : " opacity-25 bg-blue-100 ") + " transition-all duration-500 ease-in-out rounded-md  p-4 mb-4"}>
                                            <div className="flex flex-col">
                                                <div className="flex flex-row items-center">
                                                    <div className="bg-white rounded-full flex items-center justify-center w-10 h-10 text-blue-700">
                                                        <item.icon className="w-5" />
                                                    </div>
                                                    <div className="text-sm text-blue-700 ml-3">
                                                        <span className="font-medium">Step {item.step}:</span>
                                                        {
                                                            item.id > 3 &&
                                                            <span className="text-sm text-blue-700 ml-2">Select "Column name" that indicates <b>{item.type}</b></span>
                                                        }
                                                        {
                                                            item.id <= 3 &&
                                                            <span className="text-sm text-blue-700 ml-2">{item.type}</span>
                                                        }
                                                    </div>
                                                    {
                                                        !item.mandatory &&
                                                        item.id > 3 && item.id < 11 &&
                                                        this.state.step.id < 11 &&
                                                        <div className="flex flex-1 justify-end cursor-pointer">
                                                            <div onClick={async () => {
                                                                if (this.state.step.step === "four") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 5, step: 'five' },
                                                                        date: "",
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "five") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 6, step: 'six' },
                                                                        campaignID: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "six") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 7, step: 'seven' },
                                                                        campaignName: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "seven") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 8, step: 'eight' },
                                                                        adgroupID: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "eight") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 9, step: 'nine' },
                                                                        adgroupName: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "nine") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 10, step: 'ten' },
                                                                        adID: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                                else if (this.state.step.step === "ten") {
                                                                    await this.promisedSetState({
                                                                        step: { id: 11, step: 'eleven' },
                                                                        adName: ""
                                                                    });
                                                                    this.props.onStep(this.state.step, false);
                                                                }
                                                            }} className="text-sm underline text-blue-700 cursor-pointer">Can't find it</div>
                                                        </div>
                                                    }
                                                    {
                                                        item.mandatory &&
                                                        <div className="flex flex-1 justify-end cursor-pointer">
                                                            <div className="text-sm text-blue-700 cursor-pointer">
                                                                OBS! Mandatory
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    item.description &&
                                                    <div className="pl-14">
                                                        <div className="text-xs blue-orange-700 pl-1">{item.description}</div>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.state.step &&
                                                this.state.step.id < 11 &&
                                                item.id == this.state.step.id &&
                                                <div className={(this.state.step && this.state.step.id > 3 && this.state.step.id < 11 && item.step == this.state.step.step ? "mt-4" : "opacity-0 h-0") + " w-full overflow-hidden ease-in-out transition-all duration-75"}>
                                                    <InputTailwind
                                                        label={"Column name"}
                                                        value={this.state[item.type_value]}
                                                        onChange={async (value) => {
                                                            this.state[item.type_value] = value;
                                                            await this.promisedSetState(this.state);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                this.state.step &&
                                                this.state.step.id === 11 &&
                                                item.id == this.state.step.id &&
                                                Array.isArray(this.state.breakdowns) &&
                                                this.state.breakdowns.length > 0 &&
                                                <ul className="list-disc pl-20 text-blue-700 w-full mt-2">
                                                    {
                                                        this.state.breakdowns.map((item, index) => {
                                                            return (
                                                                <li className="text-sm mb-1">
                                                                    <span>{item.name}</span>
                                                                    <span onClick={() => {
                                                                        this.setState({
                                                                            breakdowns: this.state.breakdowns.filter((inner_item, inner_index) => { return inner_index !== index })
                                                                        });
                                                                    }} className='ml-2 text-red-500 underline cursor-pointer'>
                                                                        Remove
                                                                    </span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }
                                            {
                                                this.state.step &&
                                                this.state.step.id === 12 &&
                                                item.id == this.state.step.id &&
                                                Array.isArray(this.state.metrics) &&
                                                this.state.metrics.length > 0 &&
                                                <ul className="list-disc pl-20 text-blue-700 w-full mt-2">
                                                    {
                                                        this.state.metrics.map((item, index) => {
                                                            return (
                                                                <li className="text-sm mb-1">
                                                                    <span>{item.name}</span>
                                                                    <span onClick={() => {
                                                                        this.setState({
                                                                            metrics: this.state.metrics.filter((inner_item, inner_index) => { return inner_index !== index })
                                                                        });
                                                                    }} className='ml-2 text-red-500 underline cursor-pointer'>
                                                                        Remove
                                                                    </span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="flex flex-1"></div>
                        </div>
                    </div>
                }

                {
                    this.state.step &&
                    this.state.step.step === 'thirteen' &&
                    <div className="col-span-6 overflow-scroll relative">
                        <div className="w-full">
                            <div className="mb-4 w-full">
                                <div className="block overflow-scroll w-ful">
                                    <nav className="flex space-x-4" aria-label="Tabs">
                                        {this.state.second_tabs.map((tab) => (
                                            <div
                                                onClick={() => (
                                                    this.setState({
                                                        second_tab: tab
                                                    })
                                                )}
                                                key={tab.name}
                                                style={{ whiteSpace: "nowrap" }}
                                                className={cn(
                                                    tab.value == this.state.second_tab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                    'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                                )}
                                                aria-current={tab.value == this.state.tab.value ? 'page' : undefined}
                                            >
                                                {tab.name}
                                            </div>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                            {
                                this.state.second_tab.type !== 'campaign' &&
                                this.state.second_tab.type !== 'adgroup' &&
                                this.state.second_tab.type !== 'ad' &&
                                this.state.second_tab.type !== 'dates' &&
                                <div className="mt-4 mb-4 w-full flex justify-start">
                                    <div className="text-purple-500  hover:text-purple-700 cursor-pointer text-sm font-medium inline-flex flex-row"
                                        onClick={() => this.functions.addNewElement(this.state.second_tab.type)}
                                    >
                                        <PlusIcon className="w-5 mr-2" />
                                        Add another
                                    </div>
                                </div>
                            }
                            {
                                ['breakdowns', 'metrics'].filter((item) => {
                                    return item === this.state.second_tab.type;
                                }).map((item) => {
                                    return (
                                        <div className="w-full pb-2">
                                            {
                                                Array.isArray(this.state[item]) &&
                                                this.state[item].map((itemProp, index) => {
                                                    return (
                                                        <div className={"w-full flex flex-row mb-2"} key={index + item}>
                                                            <div className="flex flex-1 mr-1">
                                                                <div className="w-full">
                                                                    <InputTailwind
                                                                        label={index === 0 ? "Internal name" : null}
                                                                        value={itemProp.name}
                                                                        onChange={async (value) => this.functions.setArrayData(item, 'name', index, value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-1 ml-1">
                                                                <div className="w-full">
                                                                    <InputTailwind
                                                                        label={index === 0 ? "External column name" : null}
                                                                        value={itemProp.value}
                                                                        onChange={async (value) => this.functions.setArrayData(item, 'value', index, value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={(index !== 0 ? "pt-1" : "pt-5") + " flex justify-center items-center"}>
                                                                <div className="text-sm font-medium ml-4" onClick={() => this.functions.removeElement(item, index)}>
                                                                    <TrashIcon className="w-5 text-red-500 cursor-pointer hover:text-red-600" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.second_tab.type == "dates" &&
                                <div>
                                    <div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={"Internal name"}
                                                        value={"Date"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={"External column name"}
                                                        value={this.state.date}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                date: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.second_tab.type == "campaign" &&
                                <div>
                                    <div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={"Internal name"}
                                                        value={"Campaign ID"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={"External column name"}
                                                        value={this.state.campaignID}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                campaignID: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={null}
                                                        value={"Campaign Name"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={null}
                                                        value={this.state.campaignName}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                campaignName: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.second_tab.type == "adgroup" &&
                                <div>
                                    <div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={"Internal name"}
                                                        value={"Adgroup ID"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={"External column name"}
                                                        value={this.state.adgroupID}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                adgroupID: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={null}
                                                        value={"Adgroup Name"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={null}
                                                        value={this.state.adgroupName}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                adgroupName: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.second_tab.type == "ad" &&
                                <div>
                                    <div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={"Internal name"}
                                                        value={"Ad ID"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={"External column name"}
                                                        value={this.state.adID}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                adID: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"w-full flex flex-row mb-2"}>
                                            <div className="flex flex-1 mr-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={true}
                                                        label={null}
                                                        value={"Ad Name"}
                                                        onChange={() => { }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 ml-1">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        label={null}
                                                        value={this.state.adName}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                adName: value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            this.state.error &&
                            <div className={"w-full mt-4"}>
                                <div className="bg-red-100 p-4 rounded-md">
                                    <div className="text-sm font-medium text-red-500">
                                        {this.state.error}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

            </div>
        )
    }
}

export default CreateCustomPlatform;