import { ExclamationIcon } from '@heroicons/react/outline';
import { TagIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { Component } from 'react';
//import {  } from '@heroicons/react/outline'

class PreviewGoogleSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headlineFirst: null,
            headlineSecond: null,
            headlineThird: null,
            bodyOne: null,
            bodySecond: null,
            link: "",
            path1: null,
            path2: null,
            phone: null,
            keywordInsert: null,
            image: false,
            header: null,
            promotion: false,
            price: false,
            error: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            headlineFirst: this.props.headlineFirst,
            headlineSecond: this.props.headlineSecond,
            headlineThird: this.props.headlineThird,
            bodyOne: this.props.bodyOne,
            bodySecond: this.props.bodySecond,
            link: this.props.link,
            path1: this.props.path1,
            path2: this.props.path2,
            phone: this.props.phone,
            sitelinks: this.props.sitelinks,
            keywordInsert: this.props.keywordInsert,
            image: this.props.image,
            callout: this.props.callout,
            snippet: this.props.snippet,
            header: this.props.header,
            promotion: this.props.promotion,
            price: this.props.price,
            error: this.props.error,
            businessName: this.props.businessName,
            businessLogo: this.props.businessLogo,
        })
        console.log(this.state);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            headlineFirst: nextProps.headlineFirst,
            headlineSecond: nextProps.headlineSecond,
            headlineThird: nextProps.headlineThird,
            bodyOne: nextProps.bodyOne,
            bodySecond: nextProps.bodySecond,
            link: nextProps.link,
            path1: nextProps.path1,
            path2: nextProps.path2,
            phone: nextProps.phone,
            sitelinks: nextProps.sitelinks,
            keywordInsert: nextProps.keywordInsert,
            image: nextProps.image,
            callout: nextProps.callout,
            snippet: nextProps.snippet,
            header: nextProps.header,
            price: nextProps.price,
            error: nextProps.error,
            promotion: nextProps.promotion,
            businessName: nextProps.businessName,
            businessLogo: nextProps.businessLogo,
        })
    }

    renders = {
        keyword: (text) => {
            if (text && typeof text === "string" && text.indexOf("{keyword}") !== -1 && this.props.keywordInsert && this.props.keywordInsert.id) {
                return text.replace("{keyword}", this.props.keywordInsert.name);
            } else if (text && typeof text.text === "string" && text.text.indexOf("{keyword}") !== -1 && this.props.keywordInsert && this.props.keywordInsert.id) {
                return text.text.replace("{keyword}", this.props.keywordInsert.name);
            } else if (text && typeof text !== "string") {
                return text.text;
            } else if (text && typeof text === "string") {
                return text;
            } else {
                return ""
            }
        },
        dates: () => {
            if (this.state.promotion.promotion_start_date !== null && this.state.promotion.promotion_end_date !== null) {
                return "Valid " + moment(this.state.promotion.promotion_start_date).format("MMM DD") + " - " + moment(this.state.promotion.promotion_end_date).format("MMM DD")
            } else if (this.state.promotion.promotion_start_date !== null) {
                return "Starts  " + moment(this.state.promotion.promotion_start_date).format("MMM DD")
            } else if (this.state.promotion.promotion_end_date !== null) {
                return "Ends  " + moment(this.state.promotion.promotion_end_date).format("MMM DD")
            }
        },

    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='flex flex-col w-full justify-center items-center'>
                <div className={(this.props.noBorder ? "" : "border rounded-md") + " w-full p-2 overflow-hidden"}>
                    <div className="flex flex-row">
                        <div className="flex flex-col flex-1">

                            <div className="flex flex-row">
                                <div className='flex flex-1'>
                                    {
                                        this.state.businessLogo &&
                                        <>
                                            <div className='w-8 h-8 rounded-full overflow-hidden'>
                                                <img src={this.state.businessLogo ? this.state.businessLogo : this.state.image} />
                                            </div>
                                        </>
                                    }
                                    {
                                        !this.state.businessLogo &&
                                        <div className="text-xs font-bold">
                                            Ad •
                                        </div>
                                    }

                                    {
                                        this.state.businessName &&
                                        <>
                                            <div className='flex flex-col '>
                                                <div className="text-xs font-light ml-1">
                                                    {this.state.businessName !== "" ? this.state.businessName : "Business Name"}
                                                </div>
                                                <div className="text-xs font-light ml-1">
                                                    {this.state.link}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        !this.state.businessName &&
                                        <div className="text-xs font-light ml-1">
                                            {this.state.link}
                                        </div>
                                    }
                                    {
                                        this.state.path1 && this.state.path1 !== "" &&
                                        <div className="text-xs font-light">
                                            /{this.state.path1}
                                        </div>
                                    }
                                    {
                                        this.state.path2 && this.state.path2 !== "" &&
                                        <div className="text-xs font-light">
                                            /{this.state.path2}
                                        </div>
                                    }
                                    {
                                        this.state.phone && this.state.phone.number &&
                                        <div className="text-xs font-medium ml-1">
                                            {this.state.phone.country_code && this.state.phone.country_code.code ? ("+" + this.state.phone.country_code.code) : ""}{this.state.phone.number}
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.error &&
                                    <div className='text-red-600 flex'>
                                        <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                    </div>

                                }
                            </div>
                            <div className="mt-2 relative">
                                {
                                    <div className="text-md text-blue-500 font-medium float-left">
                                        {this.state.headlineFirst && this.state.headlineFirst !== "" ? this.renders.keyword(this.state.headlineFirst) : "Headline ..."}
                                    </div>
                                }
                                {
                                    <div className="text-md text-blue-500 font-medium px-2 float-left">
                                        |
                                    </div>
                                }
                                {
                                    <div className="text-md text-blue-500 font-medium float-left">
                                        {this.state.headlineSecond && this.state.headlineSecond !== "" ? this.renders.keyword(this.state.headlineSecond) : "Headline ..."}
                                    </div>
                                }
                                {
                                    <div className="text-md text-blue-500 font-medium px-2 float-left">
                                        |
                                    </div>
                                }
                                {
                                    <div className="text-md text-blue-500 font-medium float-left">
                                        {this.state.headlineThird && this.state.headlineThird !== "" ? this.renders.keyword(this.state.headlineThird) : "Headline ..."}
                                    </div>
                                }
                            </div>
                            <div className="mt-2 text-sm flex flex-row">
                                <p className="text-gray-500 mr-2">
                                    {this.state.bodyOne && this.state.bodyOne !== "" ? this.renders.keyword(this.state.bodyOne) : "Description 1"}{" "}{this.state.bodySecond && this.state.bodySecond !== "" ? this.renders.keyword(this.state.bodySecond) : "Description 2"}
                                </p>
                                {
                                    Array.isArray(this.state.callout) && this.state.callout.length > 0 &&
                                    this.state.callout.map((item) => {
                                        return (item.callout === "" ? "" : " " + item.callout)
                                    })

                                }
                                {
                                    Array.isArray(this.state.snippet) && this.state.snippet.length > 0 &&
                                    <p>{this.state.header + ": "}{
                                        this.state.snippet.map((item, index) => {
                                            return (item.text === "" ? "" : " " + item.text + (index === this.state.snippet.length - 1 ? "" : ","))
                                        })
                                    }
                                    </p>

                                }
                            </div>

                            {
                                Array.isArray(this.state.sitelinks) && this.state.sitelinks.length > 0 &&
                                <div className="mt-2 pl-2 grid grid-cols-12 gap-4">
                                    {
                                        this.state.sitelinks.map((item) => {
                                            return (
                                                <div className="col-span-6">
                                                    <div className="text-md text-blue-500 font-medium">{item.text}</div>
                                                    <div className="text-sm">{item.description1}{" "}{item.description2}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.price &&
                                <div className="mt-2 pl-2 flex flex-row flex-1 overflow-scroll max-w-2xl">
                                    {
                                        this.state.price.items.map((item) => {
                                            return (
                                                <div>
                                                    <div className="w-56 border border-gray-400 shadow p-3 mr-3">
                                                        <div className="text-sm border-b p-1 text-center truncate">{item.text === "" ? this.state.price.price_type.name : item.text}</div>
                                                        <div className="text-sm text-center truncate">{this.state.price.price_qualifier.preview}{this.state.price.currency.name}{" "}{item.number}{item.unit.preview}</div>
                                                        <div className="text-sm text-center truncate">{item.description1 === "" ? "Description" : item.description1}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.promotion &&
                                <div className="mt-2 text-sm flex flex-row items-center">
                                    <TagIcon className="w-6 h-6 mr-2" />
                                    <div>
                                        <div className="flex flex-row">
                                            {
                                                this.state.promotion.occasion.id > 0 &&
                                                <div className="text-gray-700 mr-2 font-semibold">
                                                    {this.state.promotion.occasion.name}
                                                </div>
                                            }
                                            <div className="text-gray-600 mr-2">
                                                {this.state.promotion.promotion.id === 3 || this.state.promotion.promotion.id === 4 ? "Up to " : ""}
                                                {this.state.promotion.promotion.id === 2 || this.state.promotion.promotion.id === 4 ? "" : this.state.promotion.currency.value + " "}
                                                {this.state.promotion.promotion_value === "" ? 0 : this.state.promotion.promotion_value}
                                                {this.state.promotion.promotion.id === 2 || this.state.promotion.promotion.id === 4 ? "%" : ""} off {this.state.promotion.promotion_description === "" ? "Item" : this.state.promotion.promotion_description}
                                            </div>
                                        </div>
                                        <div className="flex flex-row">
                                            {
                                                this.state.promotion.promotion_detail.id > 0 && this.state.promotion.promotion_detail_value !== "" &&
                                                <div className="text-gray-600 mr-2">
                                                    {this.state.promotion.promotion_detail.value} {this.state.promotion.promotion_detail.id === 1 ? this.state.promotion.currency.value : ""} {this.state.promotion.promotion_detail_value}
                                                </div>
                                            }
                                            {
                                                (this.state.promotion.promotion_start_date !== null || this.state.promotion.promotion_end_date !== null) &&
                                                <div className="text-gray-600 mr-2">
                                                    {this.renders.dates()}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            }



                        </div>
                        {
                            this.state.image &&
                            <div className='w-32 h-32 rounded-md overflow-hidden'>
                                <img src={this.state.image.url ? this.state.image.url : this.state.image} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewGoogleSearch;
