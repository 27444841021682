import { ArrowDownIcon, ArrowUpIcon, DuplicateIcon, PencilAltIcon, PlusCircleIcon, PlusIcon, XIcon } from '@heroicons/react/outline';
import { FolderIcon } from '@heroicons/react/solid';
import React, { Component, Fragment } from 'react';

class CampaignTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: "",
            campaigns: [],
            adgroups: [],
            show_side_menu: false,
            minimize_campaigns: false,
            hide_remove: false,
            hide_new: false,
            hide_clone: false
        }
    };

    componentDidMount() {
        this.setState({
            channel: this.props.channel,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            show_side_menu: this.props.showSideMenu,
            minimize_campaigns: this.props.minimize,
            hide_remove: this.props.hideRemoveButton,
            hide_new: this.props.hideNewButton,
            hide_clone: this.props.hideCloneButton
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            channel: nextProps.channel,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            show_side_menu: nextProps.showSideMenu,
            minimize_campaigns: nextProps.minimize,
            hide_remove: nextProps.hideRemoveButton,
            hide_new: nextProps.hideNewButton,
            hide_clone: nextProps.hideCloneButton
        })
    }

    render() {
        return (
            <div className="flex flex-row">
                {
                    Array.isArray(this.state.campaigns) &&
                    this.state.campaigns.filter((item) => {
                        return item.channel === this.state.channel
                    }).sort((a, b) => {
                        return (a.group_color ? a.group_color.id : 0) - (b.group_color ? b.group_color.id : 0);
                    }).map((campaign, index) => {
                        let color_group_start_index = -1;
                        let color_group_length = 0;
                        let color_group = {};
                        this.state.campaigns.filter((item) => { return item.channel === this.state.channel }).sort((a, b) => { return (a.group_color ? a.group_color.id : 0) - (b.group_color ? b.group_color.id : 0) }).map((item, index) => {
                            if (color_group_start_index < 0 && item.group_color && campaign.group_color && campaign.group_color.id === item.group_color.id) {
                                color_group_start_index = index;
                                color_group = campaign.group_color;
                            } else if (item.group_color && campaign.group_color && campaign.group_color.id === item.group_color.id) {
                                color_group_length = color_group_length + 1;
                            }
                        });
                        return (
                            <Fragment>
                                {
                                    index === color_group_start_index &&
                                    <div className={"border-" + color_group.value + "-500 px-2 border-b-1.5 h-14 flex justify-center items-center"}>
                                        <div onClick={() => {
                                            this.state.campaigns = this.state.campaigns.map((campaign) => {
                                                if (campaign.channel === this.state.channel && campaign.group_color && campaign.group_color.id === color_group.id) {
                                                    campaign.hidden = !campaign.hidden;
                                                    if (campaign.hidden) {
                                                        campaign.selected = false;
                                                    }
                                                }
                                                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                                                    if (adgroup.campaign === campaign.id) {
                                                        adgroup.selected = campaign.selected;
                                                    }
                                                    return adgroup;
                                                })
                                                return campaign;
                                            });
                                            this.props.onCampaigns(this.state.campaigns);
                                            this.props.onAdgroups(this.state.adgroups);
                                        }} className={"bg-" + color_group.value + "-500 flex items-center flex-row text-sm cursor-pointer font-medium rounded-md text-white px-3 py-1"}>
                                            {color_group.name}
                                        </div>
                                    </div>
                                }
                                {
                                    !campaign.hidden &&
                                    <div onClick={() => {
                                        if (window.event && window.event.shiftKey) {
                                            campaign.selected = !campaign.selected;
                                        } else {
                                            this.state.campaigns = this.state.campaigns.map((item) => {
                                                item.selected = (item.id === campaign.id);
                                                return item;
                                            });
                                            let only_first = false;
                                            this.state.adgroups = this.state.adgroups.map((item) => {
                                                item.selected = (item.campaign === campaign.id && !only_first)
                                                only_first = item.selected;
                                                return item;
                                            });
                                        }
                                        this.props.onCampaigns(this.state.campaigns);
                                        this.props.onAdgroups(this.state.adgroups);
                                    }}
                                        style={campaign.selected ? (campaign.group_color ? { borderColor: campaign.group_color.hex, borderBottomColor: "rgb(249, 250, 251)" } : { borderBottomColor: "rgb(249, 250, 251)" }) : (campaign.group_color ? { borderBottomColor: campaign.group_color.hex } : {})}
                                        className={(this.state.minimize ? "inline-flex " : "flex flex-1 ") + (campaign.selected ? "bg-gray-50" : "text-gray-300 hover:text-gray-900") + " border-1.5 py-3 max-w-1/5 items-center relative flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                        {
                                            !campaign.group_color &&
                                            <FolderIcon className={(campaign.selected ? "" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                        }
                                        {
                                            campaign.group_color &&
                                            <FolderIcon className={(campaign.selected ? campaign.group_color.text : ("text-" + campaign.group_color.value + "-300")) + " h-6 w-6 -ml-1 mr-2 "} />
                                        }
                                        <div className="flex flex-1 truncate">
                                            <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                {campaign.name}
                                            </div>
                                        </div>
                                        {
                                            !this.state.hide_remove &&
                                            <div onClick={() => {
                                                this.state.campaigns = this.state.campaigns.filter((item) => {
                                                    return item.id !== campaign.id;
                                                });
                                                this.state.adgroups = this.state.adgroups.filter((item) => {
                                                    return item.campaign !== campaign.id;
                                                });
                                                this.props.onCampaigns(this.state.campaigns);
                                                this.props.onAdgroups(this.state.adgroups);
                                            }} className={(this.state.campaigns.filter((item) => {
                                                return item.channel === this.state.channel
                                            }).length < 2 ? 'opacity-0' : 'opacity-100') + " border-1.5 -mr-2 h-5 w-5 rounded-full hover:text-red-500 flex justify-center items-center border-opacity-0 border-red-500 hover:border-opacity-100 hover:bg-red-100"}>
                                                <XIcon className="h-4 w-4" />
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    !campaign.hidden && (!color_group.id || (index !== (color_group_start_index + color_group_length))) &&
                                    <div style={campaign.group_color ? { borderBottomColor: campaign.group_color.hex } : {}} className="w-2 border-b-1.5"></div>
                                }
                            </Fragment>
                        )
                    })
                }
                {
                    this.state.show_side_menu &&
                    <div className="flex flex-1 flex-row">
                        {
                            !this.state.hide_new &&
                            <div onClick={() => {
                                this.props.onNew("campaign");
                            }} className={"text-purple-500 hover:text-purple-700 cursor-pointer border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                                <PlusIcon className={"h-6 w-6"} />
                            </div>
                        }
                        {
                            !this.state.hide_new &&
                            <div className="w-2 border-b-1.5"></div>
                        }
                        <div onClick={() => {
                            this.props.onEdit("campaign");
                        }} className={(this.state.campaigns.filter((item) => {
                            return item.selected && item.channel === this.state.channel
                        }).length > 0 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                            <PencilAltIcon className={"h-6 w-6"} />
                        </div>
                        {
                             !this.state.hide_clone &&
                            <div className="w-2 border-b-1.5"></div>
                        }
                        {
                            !this.state.hide_clone &&
                            <div onClick={() => {
                                if (this.state.campaigns.filter((item) => {
                                    return item.selected && item.channel === this.state.channel
                                }).length === 1) {
                                    this.props.onClone("campaign");
                                }
                            }} className={(this.state.campaigns.filter((item) => {
                                return item.selected && item.channel === this.state.channel
                            }).length === 1 ? "text-purple-500 hover:text-purple-700 cursor-pointer" : "text-gray-300 cursor-not-allowed") + " border-1.5 h-14 w-14 flex justify-center items-center rounded-t-md"}>
                                <DuplicateIcon className={"h-6 w-6"} />
                            </div>
                        }
                        <div className="flex-1 flex border-b-1.5"></div>
                    </div>
                }
            </div>
        )
    }
}

export default CampaignTabs;
