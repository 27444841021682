import React, { Component } from 'react';

class PreviewYoutubeDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoId: "",
            title: "",
            descripton: "...."
        }
    };

    componentDidMount() {
        this.setState({
            videoId: this.props.videoId,
            title: this.props.title,
            description: this.props.description
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            videoId: nextProps.videoId,
            title: nextProps.title,
            description: nextProps.description
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + " ...";
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ width: "450px" }} className={(this.props.noBorder ? "" : "border rounded-md") + " w-full"}>
                <div className="w-full">
                    <div className="h-64 w-full">
                        <iframe src={"https://www.youtube.com/embed/" + this.state.videoId} frameborder={"0"} styles={{ "overflow": "hidden", "height": "100%", "width": "100%" }} height={"100%"} width={"100%"}></iframe>
                    </div>
                </div>
                <div className="mt-2 text-sm font-semibold">
                    {this.state.title}
                </div>
                <div className="mt-1 text-xs text-gray-400">
                    {this.renders.truncate(this.state.description, 150)} <span className="font-semibold text-black ml-1">more</span>
                </div>
            </div>
        )
    }
}

export default PreviewYoutubeDesktop;
