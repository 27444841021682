import React, { Component } from 'react';
import './style.css';
import { calls } from "./calls";

import { BeatLoader } from "react-spinners";

import Input from "../../../components/input";
import currencies from '../../../assets/json/currencies';
import AccordionFixed from "../../../components/accordion-fixed";
import Loader from "../../../components/loader";

class ClientsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            company: "",
            logo: "",
            error: null,
            currencies: currencies,
            error_message: ""
        };
    };

    componentWillMount() { }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {};

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                calls.createClient({
                    name: this.state.name,
                    email: this.state.email,
                    company: this.state.company,
                    logo: this.state.logo
                }).then((response) => {
                    this.props.history.push('/user/clients/' + response.data.id);
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: error.body.name,
                        error_message: error.body.message
                    })
                });
            });
        }
    };

    renders = {

    };

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render() {
        return (
            <section className="w-full pt-8">
                {
                    this.state.loading &&
                    <Loader
                        title={'Creating client ...'}
                    />
                }
                <div className="">
                    <div className="rounded-md bg-white p-4">
                        <div>
                            <h3 className="mb-0 font-bold">
                                Create new client
                            </h3>
                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                Avg. number of search per month in parantheses
                            </p>
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Name'}
                                value={this.state.name}
                                error={this.state.error === "name" && this.state.name === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ name: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Company name'}
                                value={this.state.company}
                                error={this.state.error === "company" && this.state.company === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ company: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Email'}
                                value={this.state.email}
                                error={this.state.error === "email" && !this.validators.email(this.state.email)}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ email: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Phone (optional)'}
                                value={this.state.phone}
                                error={this.state.error === "phone" && this.state.phone === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ phone: value.target.value }, () => {

                                })}
                            />
                        </div>
                        {
                            this.state.error &&
                            <div className="Section SectionError">
                                {this.state.error_message}
                            </div>
                        }
                        <div className="mt-4 flex flex-row">
                            <div className="flex flex-1">

                            </div>
                            <div className="justify-end">
                                <button onClick={() => {
                                    this.functions.create();
                                }} className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                                    <span>Create client</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ClientsNew;
