import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import { BeatLoader } from "react-spinners";
import { calls } from "../client-facebook/calls";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import ClientFacebookRequestAdaccount from "../client-facebook-request-adaccount";
import ClientFacebookRequestPage from "../client-facebook-request-page";
import ReactTooltip from "react-tooltip";
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';
import InputTailwind from '../../moduleFiles/inputTailwind';

class ClientFacebook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            business: {},
            businesses: [],
            businesses_loading: false,
            adaccount: {},
            owned_adaccount: {},
            owned_adaccounts: [],
            owned_adaccount_loading: false,
            client_adaccount: {},
            client_adaccounts: [],
            client_adaccounts_loading: false,
            page: {},
            owned_page: {},
            client_page: {},
            owned_pages: [],
            owned_pages_loading: false,
            client_pages: [],
            client_pages_loading: false,
            pixel: {},
            pixels: [],
            creditlines: [],
            creditlines_loading: false,
            creditline: {},
            submenu: 0,
            instagram_account: {},
            instagram_from_pbia: [],
            instagram_from_page: [],
            instagram_from_adaccount: []
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        }, () => {
            this.init.facebook();
            //this.functions.businesses();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    init = {
        facebook: () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    //appId: window.location.hostname.indexOf("app.adcredo") !== -1 ? '3033879003330818' : "1463647207383186",
                    appId: '3033879003330818',
                    version: 'v6.0',
                    status: false,
                    redirect_uri: '',
                    autoLogAppEvents: true,
                    xfbml: true,
                });
                window.FB.AppEvents.logPageView();
            }.bind(this);
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
    };

    functions = {
        login: () => {
            let self = this;
            window.FB.login(function (response) {
                if (response.authResponse && response.status === "connected") {
                    self.setState({
                        token: response.authResponse.accessToken
                    }, () => {
                        self.functions.user();
                        self.functions.businesses();
                    });
                }
            }, {
                scope: 'ads_management, ads_read, business_management, instagram_basic, pages_manage_ads, pages_show_list, catalog_management, pages_read_engagement, pages_read_user_content',
                return_scopes: true,
                auth_type: 'rerequest'
            })
            //page_events
        },
        logout: () => {
            this.setState({
                token: null,
                userName: null,
                userId: null,
                business: {},
                businesses: [],
                businesses_loading: false,
                adaccount: {},
                owned_adaccount: {},
                owned_adaccounts: [],
                owned_adaccount_loading: false,
                client_adaccount: {},
                client_adaccounts: [],
                client_adaccounts_loading: false,
                page: {},
                owned_page: {},
                client_page: {},
                owned_pages: [],
                owned_pages_loading: false,
                client_pages: [],
                client_pages_loading: false,
                pixel: {},
                pixels: [],
            })
        },
        user: () => {
            calls.getAccount(this.state.token).then((response) => {
                this.setState({
                    userName: response.name,
                    userId: response.id
                })
            }, (error) => {
                console.log(error);
            });
        },
        creditlines: () => {
            this.setState({
                creditlines_loading: true
            }, () => {
                calls.getCreditLines(this.state.business.id, this.state.token).then((response) => {
                    this.setState({
                        creditline: {},
                        creditlines: response.data,
                        creditlines_loading: false
                    })
                }, (error) => {
                    this.setState({
                        creditlines_error_message: error,
                        creditlines_error: true,
                        creditlines_loading: false
                    })
                });
            })
        },
        payment: () => {
            let self = this;
            let id = "";
            try {
                id = this.state.adaccount.id;
            } catch (e) { }
            try {
                id = this.state.adaccount.id.toLowerCase();
                id = id.replace("act_", "");
            } catch (e) { }
            window.FB.ui({
                account_id: id,
                display: 'popup',
                method: 'ads_payment',
            }, function (response) {
                try {
                    if (response.success) {
                        self.functions.adaccount(self.state.adaccount.id);
                    }
                } catch (e) { }
            })
        },
        businesses: () => {
            this.setState({
                businesses_loading: true
            }, () => {
                calls.getBusinessesFB({ client: this.state.item.id, token: this.state.token }).then((response) => {
                    this.setState({
                        business: {},
                        businesses: response.data,
                        businesses_loading: false
                    })
                }, (error) => {
                    this.setState({
                        businesses_error_message: error,
                        businesses_error: true,
                        businesses_loading: false
                    })
                });
            })
        },
        adaccounts: () => {
            this.setState({
                owned_adaccount_loading: true
            }, async () => {

                let owned = [];
                let client = [];
                let error_message = "";

                try {
                    let response = await calls.getAdaccountsOwnedFB({ manager: this.state.business.id, token: this.state.token });
                    if (Array.isArray(response.data)) {
                        owned = response.data;
                    }
                } catch (error) {
                    error_message = error;
                }

                try {
                    let response = await calls.getAdaccountsClientFB({ manager: this.state.business.id, token: this.state.token });
                    if (Array.isArray(response.data)) {
                        client = response.data;
                    }
                } catch (error) {
                    error_message = error_message + " /n " + error;
                }

                this.setState({
                    adaccount: {},
                    owned_adaccount: {},
                    adaccount_new: false,
                    owned_adaccounts_error: error_message !== "",
                    owned_adaccounts_error_message: error_message,
                    owned_adaccounts: owned.concat(client),
                    owned_adaccount_loading: false
                })

                /*
                calls.getAdaccounts({ client: this.state.item.id, manager: this.state.business.id, token: this.state.token }).then((response) => {
                    this.setState({
                        adaccount: {},
                        owned_adaccount: {},
                        adaccount_new: false,
                        owned_adaccounts_error: false,
                        owned_adaccounts: response.data,
                        owned_adaccount_loading: false
                    })
                }, (error) => {
                    this.setState({
                        owned_adaccounts_error_message: error,
                        owned_adaccounts_error: true,
                        owned_adaccount_loading: false
                    })
                });
                */
            })
        },
        instagramAccounts: (page_token) => {
            this.setState({
                owned_adaccount_loading: false
            }, async () => {

                let owned = [];
                let client = [];
                let error_message = "";

                try {
                    let response = await calls.getInstagramFB({ page: this.state.page.id, token: page_token });
                    if (Array.isArray(response.data)) {
                        owned = response.data.map((item) => { item.name = item.username; return item });
                    }
                } catch (error) {
                    error_message = error;
                }

                try {
                    let response = await calls.getInstagramPageBackedFB({ page: this.state.page.id, token: page_token });
                    if (Array.isArray(response.data)) {
                        client = response.data.map((item) => { item.name = item.username; return item });
                    }
                } catch (error) {
                    error_message = error_message + " /n " + error;
                }

                this.setState({
                    instagram_loading_page: false,
                    instagram_page_error_message: error_message,
                    instagram_page_error: error_message !== "",
                    instagram_from_page: owned.concat(client),
                })

            })
        },
        clientAdaccounts: () => {
            this.setState({
                client_adaccount_loading: true
            }, () => {
                calls.getClientAdaccounts(this.state.business.id, this.state.token).then((response) => {
                    this.setState({
                        adaccount: {},
                        client_adaccount: {},
                        adaccount_new: false,
                        client_adaccounts_error: false,
                        client_adaccounts: response.data,
                        client_adaccount_loading: false
                    })
                }, (error) => {
                    this.setState({
                        client_adaccounts_error_message: error,
                        client_adaccounts_error: true,
                        client_adaccount_loading: false
                    })
                });
            })
        },
        adaccount: (id) => {
            this.setState({
                adaccount: {},
                page: {},
                owned_page: {},
                client_page: {},
                owned_adaccount_loading: true
            }, () => {
                calls.getAdaccount(id, this.state.token).then((response) => {
                    this.setState({
                        owned_adaccount_loading: false,
                        owned_adaccount_error: false,
                        adaccount: response,
                    })
                }, (error) => {
                    this.setState({
                        owned_adaccount_error_message: error,
                        owned_adaccount_error: true,
                        owned_adaccount_loading: false,
                    })
                });
            })
        },
        pages: () => {
            this.setState({
                owned_pages_loading: true
            }, () => {
                calls.getPages({ client: this.state.item.id, manager: this.state.business.id, token: this.state.token }).then((response) => {
                    this.setState({
                        page: {},
                        instagram_account: {},
                        owned_page: {},
                        owned_pages: response.data,
                        owned_pages_loading: false,
                        owned_pages_error: false,
                    })
                }, (error) => {
                    this.setState({
                        owned_pages_error_message: error,
                        owned_pages_error: true,
                        owned_pages_loading: false
                    })
                });
            })
        },
        clientPages: () => {
            this.setState({
                client_pages_loading: true
            }, () => {
                if (!this.state.item.facebook) {
                    calls.getClientPages(this.state.business.id, this.state.token).then((response) => {
                        this.setState({
                            page: {},
                            client_page: {},
                            client_pages: response.data,
                            client_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            client_pages_error_message: error,
                            client_pages_error: true,
                            client_pages_loading: false
                        })
                    });
                } else {
                    calls.getInternalClientPages({ client: this.state.item.id, channel: "facebook" }).then((response) => {
                        this.setState({
                            page: {},
                            client_page: {},
                            client_pages: response.data,
                            client_pages_loading: false
                        })
                    }, (error) => {
                        this.setState({
                            client_pages_error_message: error,
                            client_pages_error: true,
                            client_pages_loading: false
                        })
                    });
                }
            })
        },
        updatePage: (page) => {
            this.setState({
                client_pages_loading: true
            }, () => {
                let image = null;
                try {
                    image = page.picture.data.url;
                } catch (e) { }
                calls.updatePage({
                    facebookPageId: page.id,
                    facebookPageName: page.name,
                    facebookPageImage: image,
                    client: this.state.item.id,
                    update: 'page'
                }).then((response) => {
                    this.state.item.facebook = response.data;
                    this.setState({
                        client_pages_loading: false,
                        item: this.state.item
                    })
                }, (error) => {
                    this.setState({
                        client_pages_error: true,
                        client_pages_loading: false
                    })
                });
            });
        },
        pageAccessToken: (page_id) => {
            this.setState({
                instagram_loading_page: true
            }, () => {
                calls.createPageTokenFB({ client: this.state.item.id, page: page_id, token: this.state.token }).then((response) => {
                    //this.functions.instagramFromPage(page_id, response.data);
                    this.functions.instagramAccounts(response.access_token);
                    /*
                    this.functions.instagramFromPBIA(response.id, response.data);
                    this.functions.instagramFromAdAccount(this.state.adaccount.id);
                    */
                }, (error) => {
                    this.setState({
                        instagram_loading_page: false
                    }, () => {
                        //this.functions.instagramFromAdAccount(this.state.adaccount.id);
                    });
                });
            })
        },
        instagramFromAdAccount: (id) => {
            this.setState({
                instagram_loading_adaccount: true
            }, () => {
                calls.getInstagramAccountsFromAdAccount(id, this.state.token).then((response) => {
                    this.setState({
                        instagram_from_adaccount: Array.isArray(response.data) ? response.data.map((item) => { item.name = item.username; return item }) : [],
                        instagram_loading_adaccount: false,
                        instagram_adaccount_error: false,
                    })
                }, (error) => {
                    this.setState({
                        instagram_from_adaccount: [],
                        instagram_adaccount_error_message: error,
                        instagram_adaccount_error: true,
                        instagram_loading_adaccount: false
                    })
                });
            })
        },
        instagramFromPage: (page_id) => {
            this.setState({
                instagram_loading_page: true
            }, () => {
                calls.getInstagramAccountsFromPage({ client: this.state.item.id, page: page_id, token: this.state.token }).then((response) => {
                    this.setState({
                        instagram_from_page: Array.isArray(response.data) ? response.data.map((item) => { item.name = item.username; return item }) : [],
                        instagram_loading_page: false,
                        instagram_page_error: false,
                    })
                }, (error) => {
                    this.setState({
                        instagram_from_page: [],
                        instagram_page_error_message: error,
                        instagram_page_error: true,
                        instagram_loading_page: false
                    })
                });
            })
        },
        instagramFromPBIA: (id, token) => {
            this.setState({
                instagram_loading_PBIA: true
            }, () => {
                calls.getInstagramAccountsFromPBIA(id, token).then((response) => {
                    this.setState({
                        instagram_from_pbia: Array.isArray(response.data) ? response.data.map((item) => { item.name = item.username; return item }) : [],
                        instagram_loading_PBIA: false,
                        instagram_PBIA_error: false,
                    })
                }, (error) => {
                    this.setState({
                        instagram_from_pbia: [],
                        instagram_loading_PBIA: false,
                        instagram_PBIA_error: true,
                        instagram_PBIA_error_message: error,
                    })
                });
            })
        },
        pixels: (id) => {
            this.setState({
                pixel: {},
            }, () => {
                calls.getPixels(id, this.state.token).then((response) => {
                    this.setState({
                        pixels: response.data,
                    })
                }, (error) => {
                    console.log(error);
                });
            })
        },
        reset: () => {
            this.setState({
                loading: true,
                remove_modal: false
            }, () => {
                calls.removeFacebookSettings(this.state.item.id).then((response) => {
                    delete this.state.item.facebook;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                        this.functions.logout();
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    });
                });
            });
        },
        settings: () => {
            this.setState({
                loading: true
            }, () => {

                let object = {
                    facebookUserId: this.state.userId,
                    facebookUserName: this.state.userName,
                    facebookUserToken: this.state.token,
                    facebookBusinessManagerId: this.state.business.id,
                    facebookBusinessManagerName: this.state.business.name,
                    facebookAdAccountId: this.state.adaccount.id,
                    facebookAdAccountName: this.state.adaccount.name,
                    facebookAdAccountCurrency: this.state.adaccount.currency,
                    timezone: this.state.adaccount.timezone_name,
                    //facebookPageId: this.state.page.id,
                    //facebookPageName: this.state.page.name,
                    //instagramAccountName: this.state.instagram_account.name,
                    //instagramAccountId: this.state.instagram_account.id
                };

                if (this.state.facebookAdPixelName && this.state.facebookAdPixelId) {
                    object.facebookAdPixelName = this.state.pixel.name;
                    object.facebookAdPixelId = this.state.pixel.id;
                }

                try {
                    //object.facebookPageImage = this.state.page.picture.data.url;
                } catch (e) { }

                calls.updateFacebookSettings(object, this.state.item.id).then((response) => {
                    this.state.item.facebook = response.data;
                    this.setState({
                        item: this.state.item,
                        loading: false,
                        open: false
                    }, () => {
                        this.props.onUpdate(this.state.item);
                    });
                }, (error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });
            });
        },
        updateToken: () => {

        }
    };

    renders = {
        submenus: () => {
            let array = [];
            if (this.state.token || this.state.item.facebook) {
                array.push({ id: 0, name: "Add adaccount" });
                array.push({ id: 1, name: "Create adaccount" });
                array.push({ id: 2, name: "Setup pixel" });
                array.push({ id: 3, name: "Request adaccount" });
                array.push({ id: 4, name: "Request page" });
            } else {
                array.push({ id: 0, name: "Get started" });
            }
            return array;
        }
    };

    render() {
        return (
            <div style={{ padding: '0px' }}>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Error"
                    type="error"
                    text={"Payment method doesnt exist for this adaccount, please select another"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ warning_modal: false });
                    }}
                />
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Facebook"
                    type="warning"
                    text={"You wont be able access any Facebook campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.reset();
                    }}
                    onCancel={() => {
                        this.setState({ remove_modal: false });
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            this.state.token && !this.state.item.facebook &&
                            <div className="w-full">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select a business manager"}
                                    selected={this.state.business}
                                    options={this.state.businesses}
                                    placeholder={"Search managers"}
                                    loader={this.state.businesses_loading}
                                    onChange={(value) => {
                                        this.setState({
                                            business: value
                                        }, () => {
                                            this.functions.adaccounts();
                                            //this.functions.pages();
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.token && !this.state.item.facebook &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Dropdown
                                    filter={true}
                                    title={'Select a business manager'}
                                    size={'full'}
                                    placeholder={(!this.state.businesses_loading && this.state.businesses.length > 0) ? "Click here .." : "No business managers available"}
                                    error={this.state.businesses_error}
                                    options={this.state.businesses}
                                    removeArrow={!this.state.businesses_loading && this.state.businesses.length < 1}
                                    disabled={!this.state.businesses_loading && this.state.businesses.length < 1}
                                    loading={this.state.businesses_loading}
                                    value={this.state.business}
                                    onChange={(value) =>
                                        this.setState({
                                            business: value
                                        }, () => {
                                            this.functions.adaccounts();
                                            this.functions.pages();
                                        })
                                    }
                                />
                            </div>
                            */
                        }
                        {
                            this.state.businesses_error &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <div className="text-xs text-red-500 font-bold">
                                    {JSON.stringify(this.state.businesses_error_message)}
                                </div>
                            </div>
                        }
                        {
                            this.state.item.facebook &&
                            <div className="w-full">
                                <InputTailwind
                                    label={"Facebook user"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.facebook.facebookUserName}
                                    onChange={(value) => { }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.item.facebook &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Input
                                    title={'Facebook user'}
                                    value={this.state.item.facebook.facebookUserName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                            */
                        }
                        {
                            this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Business manager"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.facebook.facebookBusinessManagerName}
                                    onChange={(value) => { }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.item.facebook &&
                            <div className="Section">
                                <Input
                                    title={'Selected business manager'}
                                    value={this.state.item.facebook.facebookBusinessManagerName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                            */
                        }
                        {
                            this.state.token && this.state.business.name && !this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select ad account"}
                                    selected={this.state.owned_adaccount}
                                    options={this.state.owned_adaccounts.concat(this.state.client_adaccounts)}
                                    placeholder={"Search"}
                                    loader={this.state.owned_adaccount_loading || this.state.client_adaccount_loading}
                                    onChange={(value) => {
                                        this.setState({
                                            client_adaccount: {},
                                            page: {},
                                            pixel: {},
                                            owned_page: {},
                                            client_page: {},
                                            adaccount: value,
                                            owned_adaccount: value
                                        }, () => {
                                            //this.functions.pixels(value.id);
                                            //this.functions.instagramFromAdAccount(value.id);
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.token && this.state.business.name && !this.state.item.facebook &&
                            <div className="Section">
                                <Dropdown
                                    filter={true}
                                    title={'Select account'}
                                    size={'full'}
                                    placeholder={(!this.state.owned_adaccount_loading && !this.state.client_adaccount_loading) ? "Click here .." : "Loading adaccounts"}
                                    error={this.state.owned_adaccounts_error || this.state.client_adaccounts_error}
                                    options={this.state.owned_adaccounts.concat(this.state.client_adaccounts)}
                                    loading={this.state.owned_adaccount_loading || this.state.client_adaccount_loading}
                                    value={this.state.owned_adaccount}
                                    extraItem={"currency"}
                                    extraSecondItem={"funding_source"}
                                    onChange={(value) => {
                                        //console.log(value);
                                        this.setState({
                                            client_adaccount: {},
                                            page: {},
                                            pixel: {},
                                            owned_page: {},
                                            client_page: {},
                                            adaccount: value,
                                            owned_adaccount: value
                                        }, () => {
                                            this.functions.pixels(value.id);
                                            this.functions.instagramFromAdAccount(value.id);
                                        })
                                    }}
                                />
                                <div onClick={() => {
                                    this.functions.adaccounts();
                                    //this.functions.clientAdaccounts();
                                }} data-tip={"Update adaccounts"} style={{ marginTop: '26px', marginLeft: '10px', width: '55px' }} className="Button ButtonNeutral">
                                    <FeatherIcon color="#333333" size={20} icon="refresh-ccw" />
                                    <ReactTooltip />
                                </div>
                            </div>
                            */
                        }
                        {
                            (this.state.owned_adaccount_error || this.state.client_adaccounts_error) &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                {
                                    this.state.owned_adaccount_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.owned_adaccount_error_message)}
                                    </div>
                                }
                                {
                                    this.state.client_adaccounts_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.client_adaccounts_error_message)}
                                    </div>
                                }
                            </div>
                        }
                        {
                            false &&
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook && !this.state.adaccount.funding_source &&
                            <div className="Section">
                                <div onClick={() => this.functions.payment()} className="Button ButtonFull ButtonNeutral">
                                    Add payment method
                                </div>
                            </div>
                        }
                        {
                            this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Ad account"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.facebook.facebookAdAccountName}
                                    onChange={(value) => { }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.item.facebook &&
                            <div className="Section">
                                <Input
                                    title={'Selected adaccount'}
                                    value={this.state.item.facebook.facebookAdAccountName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                            */
                        }

                        {
                            false &&
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select page"}
                                    selected={this.state.owned_page}
                                    options={this.state.owned_pages.concat(this.state.client_pages)}
                                    placeholder={"Search"}
                                    loader={this.state.owned_pages_loading || this.state.client_pages_loading}
                                    onChange={(value) => {
                                        this.setState({ owned_page: value, page: value, client_page: {} }, () => {
                                            this.functions.pageAccessToken(value.id);
                                        })
                                    }}
                                />
                            </div>
                        }

                        {
                            /*
                            false &&
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook &&
                            //this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <Dropdown
                                    filter={true}
                                    title={'Select page'}
                                    size={'full'}
                                    placeholder={(!this.state.owned_pages_loading && !this.state.client_pages_loading) ? "Click here .." : "Loading pages"}
                                    error={this.state.owned_pages_error || this.state.client_pages_error}
                                    options={this.state.owned_pages.concat(this.state.client_pages)}
                                    loading={this.state.owned_pages_loading || this.state.client_pages_loading}
                                    value={this.state.owned_page}
                                    onChange={(value) => this.setState({ owned_page: value, page: value, client_page: {} }, () => {
                                        this.functions.pageAccessToken(value.id);
                                        //this.functions.instagramFromPage(value.id);

                                    })}
                                />
                                <div onClick={() => {
                                    this.functions.pages();
                                    //this.functions.clientPages();
                                }} data-tip={"Update pages"} style={{ marginTop: '26px', marginLeft: '10px', width: '55px' }} className="Button ButtonNeutral">
                                    <FeatherIcon color="#333333" size={20} icon="refresh-ccw" />
                                    <ReactTooltip />
                                </div>
                            </div>
                            */
                        }
                        {
                            (this.state.owned_pages_error || this.state.client_pages_error) &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                {
                                    this.state.owned_pages_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.owned_pages_error_message)}
                                    </div>
                                }
                                {
                                    this.state.client_pages_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.client_pages_error_message)}
                                    </div>
                                }
                            </div>
                        }
                        {
                            false &&
                            this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Page"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.facebook.facebookPageName}
                                    onChange={(value) => { }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.item.facebook &&
                            <div className="Section">
                                <Input
                                    title={'Selected page'}
                                    value={this.state.item.facebook.facebookPageName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                            */
                        }

                        {
                            false &&
                            this.state.token && this.state.page.name && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select instagram account"}
                                    selected={this.state.instagram_account}
                                    options={this.state.instagram_from_page}
                                    placeholder={"Search"}
                                    loader={this.state.owned_pages_loading || this.state.instagram_loading_page || this.state.instagram_loading_PBIA}
                                    onChange={(value) => {
                                        this.setState({
                                            instagram_account: value
                                        });
                                    }}
                                />
                            </div>
                        }

                        {
                            /*
                            false &&
                            this.state.token && this.state.page.name && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook &&
                            //this.state.token && this.state.page.name && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <Dropdown
                                    filter={true}
                                    title={'Select instagram account'}
                                    size={'full'}
                                    placeholder={(!this.state.instagram_loading_adaccount && !this.state.instagram_loading_page && !this.state.instagram_loading_PBIA && !this.state.instagram_loading_pagetoken) ? "Click here .." : "Loading account"}
                                    error={(this.state.instagram_adaccount_error || this.state.instagram_page_error || this.state.instagram_PBIA_error)}
                                    options={this.state.instagram_from_page}
                                    loading={this.state.owned_pages_loading || this.state.client_pages_loading || this.state.instagram_loading_PBIA}
                                    value={this.state.instagram_account}
                                    onChange={(value) => {
                                        this.setState({
                                            instagram_account: value
                                        });
                                    }}
                                />
                            </div>
                            */
                        }

                        {
                            (this.state.instagram_adaccount_error || this.state.instagram_page_error || this.state.instagram_PBIA_error) &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                {
                                    this.state.owned_pages_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.instagram_adaccount_error_message)}
                                    </div>
                                }
                                {
                                    this.state.client_pages_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.instagram_page_error_message)}
                                    </div>
                                }
                                {
                                    this.state.instagram_PBIA_error &&
                                    <div className="text-xs text-red-500 font-bold">
                                        {JSON.stringify(this.state.instagram_PBIA_error_message)}
                                    </div>
                                }
                            </div>
                        }
                        {
                            false &&
                            this.state.item.facebook &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Instagram"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.facebook.instagramAccountName}
                                    onChange={(value) => { }}
                                />
                            </div>
                        }
                        {
                            /*
                            false &&
                            this.state.item.facebook &&
                            <div className="Section">
                                <Input
                                    title={'Selected instagram account'}
                                    value={this.state.item.facebook.instagramAccountName}
                                    disabled={true}
                                    size={'full'}
                                    type={"text"}
                                />
                            </div>
                            */
                        }
                        {
                            this.state.token && this.state.business.name && this.state.adaccount.name && !this.state.item.facebook &&
                            //this.state.instagram_account.id && this.state.token && this.state.business.name && this.state.adaccount.name && this.state.page.name && !this.state.item.facebook && this.state.adaccount.funding_source &&
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.settings();
                                }} className="Button ButtonFull">
                                    {
                                        !this.state.loading &&
                                        <div>Add Facebook settings to client</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                !this.state.token && !this.state.item.facebook &&
                                <div className="flex justify-center items-center">
                                    <img onClick={() => {
                                        this.functions.login()
                                    }} style={{ maxWidth: "250px", width: "100%" }} className="cursor-pointer" src={require("../../assets/images/facebook_login_button.png")} />
                                    {false &&
                                        <div onClick={() => {
                                            this.functions.login()
                                        }} className="ButtonFull Button ButtonFacebook">
                                            login Facebook
                                        </div>
                                    }
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.facebook &&
                                <div className="Section">
                                    <div onClick={() => {
                                        this.functions.logout()
                                    }} className="ButtonFull Button ButtonFacebook">
                                        signout Facebook
                                    </div>
                                </div>
                            }
                            {
                                this.state.item.facebook &&
                                <div className="Section">
                                    <div onClick={() => {
                                        this.setState({
                                            remove_modal: true
                                        })
                                    }} className="Button ButtonFull ButtonFacebook">
                                        {
                                            !this.state.loading &&
                                            <div>signout Facebook</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                    {
                                        false && <div onClick={() => {
                                            //this.functions.update()
                                        }} className="Button ButtonNeutral">
                                            update token
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.submenu === 1 &&
                    <ClientFacebookAdaccount
                        client={this.state.item.id}
                        token={this.state.token}
                        item={this.state.item.facebook}
                        channel={'facebook'}
                        onChange={(value) => {
                            this.functions.adaccounts();
                            //this.functions.clientAdaccounts();
                        }}
                    />
                }
                {
                    this.state.submenu === 2 &&
                    <ClientFacebookPixel
                        client={this.state.item.id}
                        item={this.state.item.facebook}
                        onChange={(value) => {
                            this.state.item.facebook = value;
                            this.setState({
                                item: this.state.item
                            })
                        }}
                    />
                }
                {
                    this.state.submenu === 3 &&
                    <ClientFacebookRequestAdaccount
                        client={this.state.item.id}
                        token={this.state.token}
                        channel={'facebook'}
                        item={this.state.item.facebook}
                        onChange={(value) => {

                        }}
                    />
                }
                {
                    this.state.submenu === 4 &&
                    <ClientFacebookRequestPage
                        client={this.state.item.id}
                        token={this.state.token}
                        channel={'facebook'}
                        item={this.state.item.facebook}
                        onChange={(value) => {

                        }}
                    />
                }
            </div>
        )
    }
}

export default ClientFacebook;
