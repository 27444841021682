import React, {Component} from 'react';
import './style.css';
import {BeatLoader} from "react-spinners";
import {calls} from "./calls";
import CampaignsList from "../../../modules/campaignslist";
import SweetAlert from "sweetalert-react";
import Loader from "../../../components/loader";
import {campaignRegister} from "../../../services/campaignRegister";

class UserCampaignsDrafted extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            order: "desc",
            sort: "created",
            loading: true
        };
    };

    componentWillMount() {
        this.init.drafts();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {
        drafts: () => {
            this.setState({
                campaigns: []
            }, () => {
                calls.getDrafts().then((response) => {
                    this.setState({
                        campaigns: response.data,
                        loading: false
                    });
                }, (error) => {
                    console.log(error);
                });
            })
        }
    };

    functions = {
        draft: (item) => {
            this.setState({
                loading_draft: true,
                loading_text: 'Loading draft'
            }, () => {
                calls.getDraft(item.id).then((response) => {
                    this.setState({
                        loading_draft: false
                    }, () => {
                        if(response.data.onboarding){
                            campaignRegister.set(response.data.onboarding);
                            this.props.history.push('/user/campaigns/new');
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading_draft: false
                    })
                });
            })
        },
        delete: () => {
            this.setState({
                loading_delete: true,
                warning_modal: false,
                loading_text: 'Deleting draft'
            }, () => {
                calls.removeDraft(this.state.temp.id).then((response) => {
                    this.state.campaigns = this.state.campaigns.filter((inner_item) => {
                        return inner_item.id !== this.state.temp.id;
                    });
                    this.setState({
                        campaigns: this.state.campaigns,
                        loading_delete: false,
                        temp: {}
                    })
                }, (error) => {
                    this.setState({
                        loading_delete: false
                    })
                });
            })
        }
    };

    renders = {};

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                <div className="">
                    <div className="">
                        {
                            <SweetAlert
                                show={this.state.warning_modal}
                                title={'Delete draft'}
                                type="warning"
                                text={'Are you sure ?'}
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                showCancelButton={true}
                                onConfirm={() => {
                                    this.functions.delete();
                                }}
                                onCancel={() => {
                                    this.setState({
                                        warning_modal: false
                                    });
                                }}
                            />
                        }
                        {
                            this.state.loading &&
                            <div className="mt-48 mb-48">
                                <div className="justify-center align-middle flex flex-col text-center">
                                    <div className="font-bold mb-2">Loading Drafts ...</div>
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={17}
                                        color={'#060534'}
                                        loading={true}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !this.state.loading &&
                            <div className="">
                                {
                                    !this.state.loading &&
                                    <div className="flex items-center justify-center">
                                        <div className="flex flex-1">
                                            <div>
                                                <h3 className="mb-0 font-bold">
                                                    Drafted campaigns
                                                </h3>
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    There are <span
                                                    className="font-bold ml-1 mr-1">{this.state.campaigns.length}</span> drafts
                                                    available
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    <CampaignsList
                                        campaignitems={this.state.campaigns}
                                        properties={{name: true, client: true, created: true, actions: {open: true, delete: true}}}
                                        callfunc={(item) => {
                                            this.functions.draft(item);
                                        }}
                                        draft={true}
                                        order={this.state.order}
                                        sort={this.state.sort}
                                        updateSort={(sort, order) => {
                                            this.setState({
                                                sort: sort,
                                                order: order
                                            })
                                        }}
                                        delete={(item) => {
                                            this.setState({
                                                warning_modal: true,
                                                temp: item
                                            })
                                        }}
                                    />
                                }
                            </div>
                        }
                        {
                            (this.state.loading_delete || this.state.loading_draft) &&
                            <Loader
                                title={this.state.loading_text}
                            />
                        }
                        {
                            !this.state.loading && this.state.campaigns.length < 1 &&
                            <div className="background-Loader">
                                <div className="background-LoaderText">
                                    You dont have any <span className="font-bold text-purple-500">Drafted</span> campaigns
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default UserCampaignsDrafted;
