import React, { Component } from 'react';
import InputTailwind from "./inputTailwind";
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from "../services/tokenRegister";
import OauthPopup from "react-oauth-popup";
import DropdownTailwind from "./dropdownTailwind";
import WarningModalTailwind from "./warningModalTailwind";
import SwitchTailwind from "./switchTailwind";
import currencies from "../assets/json/currencies.json";
import SlideoutTailwind from './slideoutTailwind';

import CreateGoogleAdAccount from './createGoogleAdAccount';
import CreateBingAdAccount from './createBingAdAccount';
import CopyButton from './copyButton';
import cn from "classnames";
import CreateAccountBudget from './createAccountBudget';

class AccountBing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            me: {},
            agency: {},
            agency_user: {},
            is_manager: false,
            loading: false,
            loading_managers: false,
            loading_adaccounts: false,
            loading_account: false,
            manager: {},
            managers: [],
            adaccount: {},
            adaccounts: [],
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agents: [],
            agent: { id: 0, name: "Select agent" },
            subtab: { id: 4, name: "Account budgets" },
            googleAdsImportCredentialID: "",
            healthcheck: {},
            owner: false,
            disabled: false,
            throttling: {},
            url: "",
            currency: {},
            currencies: currencies,
            syncMessage: "",
            syncManagers: false,
        }
    };

    async componentDidMount() {
        let redirect_uri = "http://localhost:5001/callback/bing-web/";
        if (window.location.hostname === 'localhost') {
            redirect_uri = "http://localhost:5001/callback/bing-web/"
        } else if (window.location.hostname === 'app.adcredo.io') {
            redirect_uri = "https://app.adcredo.io/callback/bing-web/"
        } else if (window.location.hostname === 'dev.adcredo.io') {
            redirect_uri = "https://dev.adcredo.io/callback/bing-web/"
        }
        let url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?` +
            `client_id=7fc58c76-e3f0-4c0b-8b78-490ac9a8aa2c` +
            `&response_type=code` +
            `&redirect_uri=` + redirect_uri +
            `&response_mode=query` +
            `&scope=openid%20offline_access%20https%3A%2F%2Fads.microsoft.com%2Fmsads.manage` +
            `&state=12345`;

        this.promisedSetState({
            url: url
        });
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me ? this.props.me : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            agency: this.props.agency ? this.props.agency : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.bing && !this.state.agency.bing) {
            this.functions.getAgents(true);
        }
        if (this.state.client.bing) {
            this.functions.getBingAccount();
        }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me ? nextprops.me : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            agency: nextprops.agency ? nextprops.agency : {}
        });
    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "bing",
                    token: token,
                    googleAdsImportCredentialID: this.state.googleAdsImportCredentialID
                });
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ bing: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "bing"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ bing: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.adaccount.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    manager: this.state.manager,
                    adaccount: this.state.adaccount,
                    googleAdsImportCredentialID: this.state.googleAdsImportCredentialID
                });
                this.state.client.bing = {
                    connected: true,
                    defaultAgent: this.state.agent,
                    bingBusinessManagerId: this.state.manager.id,
                    bingBusinessManagerName: this.state.manager.name,
                    bingAdAccountId: this.state.adaccount.id,
                    bingAdAccountName: this.state.adaccount.name,
                    bingAdAccountCurrency: this.state.adaccount.currency,
                    currency: this.state.currency.id,
                    bingProfileName: this.state.adaccount.advertiser || null,
                    googleAdsImportCredentialID: this.state.googleAdsImportCredentialID
                };
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    adaccount: {},
                    manager: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.bing;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    client: this.state.client,
                    googleAdsImportCredentialID: ""
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                lock_settings: false,
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        listManagers: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.listManagers();
                await this.promisedSetState({
                    managers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        getManager: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.getManager();
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        listAdaccounts: async () => {
            await this.promisedSetState({
                loading_adaccounts: true
            });
            try {
                let response = await this.calls.listAdaccounts();
                await this.promisedSetState({
                    adaccounts: response.data.map((item) => { (item.name = item.name + " (" + item.id + "/" + item.number + ")"); return item })
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_adaccounts: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'bing'
                });
                this.state.agency.bing = {
                    //facebook_connected: response.data.facebook_connected,
                    //matching_managers: response.data.matching_managers,
                    //matching_accounts: response.data.matching_accounts,
                    //campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'bing'
                });
                this.state.agency.bing = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_budget: true,
                error: false
            });
            try {
                let response = await this.calls.updateCientPlatformBudget({
                    channel: "bing",
                    enabled: this.state.client.bing.maxBudgetEnabled,
                    budget: this.state.client.bing.maxBudget
                });
                this.state.client.bing.campaignsWithSchedule = response.data.campaignsWithSchedule;
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_budget: false
            });
        },
        getBingAccount: async () => {
            try {
                this.setState({ loading_auto_tagging: true });
                let response = await this.calls.getBingAccount();
                this.setState({
                    auto_tagging: response.data.auto_tagging
                });
            } catch (error) { }
            this.setState({ loading_auto_tagging: false });
        },
        updateAutoTagging: async () => {
            this.setState({ loading_auto_tagging: true });
            try {
                await this.calls.updateAutoTagging({ auto_tagging: !this.state.auto_tagging });
                this.functions.getBingAccount();
            } catch (error) {
                this.setState({ loading_auto_tagging: false });
            }
        }
    };

    calls = {
        getBingAccount: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bing/getAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateAutoTagging: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/bing/updateAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/bing/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/bing/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=bing";
            return apiRegister.call(options, url);
        },
        listManagers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bing/listBusinessManagers?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getManager: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bing/getManager?client=" + this.state.client.id + "&agent=" + this.state.agent.id + "&manager=" + this.state.manager.id
            return apiRegister.call(options, url);
        },
        listAdaccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/bing/listAdaccounts?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        updateCientPlatformBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCientPlatformBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div>

                {/*CREATE ADACCOUNT*/}
                <SlideoutTailwind
                    title={"Create Account"}
                    submitButtonText={"Create"}
                    secondaryButton={false}
                    medium={true}
                    open={this.state.create_account ? true : false}
                    loader={this.state.create_account_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_account: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_account_loading: true
                        });
                        this.refs.CreateBingAdAccount.functions.createAccount();
                    }}
                >
                    {
                        this.state.create_account &&
                        <CreateBingAdAccount
                            currencies={this.state.currencies}
                            ref="CreateBingAdAccount"
                            manager={this.state.manager}
                            agent={this.state.agent}
                            client={this.state.client}
                            onCreated={async (data) => {
                                if (this.state.client && this.state.client.bingScaleSettings && this.state.client.bingScaleSettings.createAccounts) {
                                    this.setState({
                                        create_account_loading: false,
                                        create_account: false,
                                        lock_settings: true,
                                        agent: {
                                            id: this.state.client.bingScaleSettings.agent.id,
                                            email: this.state.client.bingScaleSettings.agent.email,
                                            name: this.state.client.bingScaleSettings.agent.email
                                        },
                                        manager: {
                                            new: true,
                                            id: data.manager,
                                            name: "(" + data.manager + ") " + data.manager_name
                                        },
                                        adaccount: {
                                            id: data.account,
                                            name: "(" + data.account + ") " + data.name
                                        },
                                        currency: this.state.client.bingScaleSettings.currency,
                                        googleAdsImportCredentialID: this.state.client.bingScaleSettings.googleAdsImportCredentialID
                                    });
                                }
                            }}
                            onError={async () => {
                                await this.promisedSetState({
                                    create_account_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-bing-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Bing
                        </div>
                        {
                            !this.state.agency.bing &&
                            !this.state.user.id &&
                            <div className="text-sm text-gray-500">
                                Connect Bing to this client
                            </div>
                        }
                        {
                            !this.state.agency.bing &&
                            this.state.user.id &&
                            <div className="text-sm text-gray-500">
                                Connect Bing to this account
                            </div>
                        }
                        {
                            this.state.agency.bing &&
                            <div className="text-sm text-gray-500">
                                Connect Bing to agency
                            </div>
                        }
                    </div>
                    {
                        (
                            this.state.agency.bing ||
                            (
                                !this.state.user.id &&
                                this.state.client.bing &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.bing && this.state.client.bing.defaultAgent && this.state.client.bing.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.bing ? (this.state.client.bing ? true : false) : this.state.agency.bing.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.bing) {
                                        if (!this.state.agency.bing.authenticated) {
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                        }
                                    } else {
                                        if (!this.state.client.bing) {
                                            this.state.client.bing = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account || this.state.syncManagers) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.agency.bing &&
                    !this.state.client.id &&
                    (!this.state.user.bing || (this.state.user.bing && !this.state.user.bing.authenticated)) &&
                    <>
                        <div className="mt-5">
                            <InputTailwind
                                label={"Google Ads Import Credential ID"}
                                disabled={this.state.client.bing && this.state.client.bing.googleAdsImportCredentialID}
                                value={this.state.client.bing && this.state.client.bing.googleAdsImportCredentialID ? this.state.client.bing.googleAdsImportCredentialID : this.state.googleAdsImportCredentialID}
                                onChange={async (value) => {
                                    this.setState({
                                        googleAdsImportCredentialID: value
                                    });
                                }}
                            />
                        </div>
                        <div className="flex justify-between w-full flex-row">
                            <OauthPopup
                                url={this.state.url}
                                onCode={(code) => {
                                    if (code) {
                                        this.functions.login(code);
                                    }
                                }}
                                onClose={() => {
                                    console.log('closed')
                                }}
                            >
                                <button className={(!(this.state.user.bing && this.state.user.bing.authenticated) ?
                                    "cursor-pointer bg-bing-500 text-white" : " bg-red-500 cursor-pointer text-white") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 mt-5"
                                }
                                >
                                    <span>Login with bing</span>
                                    {
                                        this.state.loading &&
                                        <div className={(!(this.state.user.bing && this.state.user.bing.authenticated) ? "bg-bing-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </OauthPopup>
                            <button
                                onClick={() => {
                                    window.open("https://ui.ads.microsoft.com/campaign/vnext/importcredentialid");
                                }}
                                className={"cursor-pointer bg-bing-500 text-white shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 mt-5"}
                            >
                                <span> Open Bing</span>
                            </button>
                        </div>
                    </>
                }

                {
                    !this.state.client.id &&
                    this.state.user.bing && this.state.user.bing.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                                this.functions.logout();
                            }}
                            className={(!(this.state.user.bing && this.state.user.bing.authenticated) ?
                                "cursor-pointer bg-tiktok-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.bing && this.state.user.bing.authenticated) ? "bg-tiktok-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.agency.bing &&
                    !this.state.user.id &&
                    < div className="mt-5 flex">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <DropdownTailwind
                                    label={"Default agent"}
                                    small={false}
                                    locked={this.state.client.bing || this.state.lock_settings}
                                    skipInternalSearch={this.state.is_manager}
                                    loader={this.state.loading_agents}
                                    loadingPagination={this.state.loading_agents_pagination}
                                    loadingSearch={this.state.loading_agents_search}
                                    total={this.state.total}
                                    searchInput={true}
                                    placeholder={"..."}
                                    defaultOptions={this.state.me.bing && this.state.me.bing.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                    pagination={this.state.total > (this.state.page * this.state.limit)}
                                    selected={this.state.client.bing && this.state.client.bing.defaultAgent ? this.state.client.bing.defaultAgent : this.state.agent}
                                    options={this.state.agents}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            agent: value,
                                            manager: {},
                                            adaccount: {},
                                            googleAdsImportCredentialID: ""
                                        });
                                        this.functions.listManagers();
                                    }}
                                    onPagination={async () => {
                                        if (!this.state.loading_agents_pagination) {
                                            await this.promisedSetState({
                                                page: this.state.page + 1
                                            });
                                            this.functions.getAgents(false, true, false);
                                        }
                                    }}
                                    onSearch={async (value) => {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: "",
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            if (this.state.throttling.current) {
                                                //SKIP
                                            } else {
                                                if (value && value === "") {
                                                    await this.promisedSetState({
                                                        search: value,
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                } else {
                                                    this.state.throttling.current = true;
                                                    await this.promisedSetState({
                                                        search: value,
                                                        throttling: this.state.throttling
                                                    });
                                                    setTimeout(async () => {
                                                        this.state.throttling.current = false;
                                                        await this.promisedSetState({
                                                            throttling: this.state.throttling
                                                        });
                                                        await this.promisedSetState({
                                                            page: 1
                                                        });
                                                        this.functions.getAgents(false, false, true);
                                                    }, 300);
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {
                            this.state.client.id &&
                            !this.state.client.bing &&
                            !this.state.agent.id &&
                            this.state.client && this.state.client.bingScaleSettings && this.state.client.bingScaleSettings.createAccounts &&
                            <div className="w-32 pt-5.5 ml-2">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading_new_account) {
                                            this.setState({
                                                create_account: true
                                            });
                                        }
                                    }}
                                    className={"cursor-pointer bg-bing-500 h-12 inline-flex relative justify-center rounded-md border border-transparent w-full items-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                >
                                    <span>Create new</span>
                                    {
                                        this.state.loading_new_account &&
                                        <div className={"bg-bing-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        }
                    </div>
                }
                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={!!this.state.remove}
                    title={"Sign out bing"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />
                {
                    this.state.client.id &&
                    (this.state.client.bing || this.state.agent.id !== 0) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={this.state.client.bing && this.state.client.bing.bingBusinessManagerId ? "Organization" : "Organizations"}
                                loader={this.state.loading_managers}
                                searchInput={true}
                                locked={this.state.client.bing || this.state.lock_settings}
                                selected={this.state.client.bing && this.state.client.bing.bingBusinessManagerId ? { name: this.state.client.bing.bingBusinessManagerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" })}
                                options={this.state.managers}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        manager: value,
                                        adaccount: {},
                                        currency: {}
                                    });
                                    this.functions.listAdaccounts();
                                }}
                            />
                        </div>
                        {
                            (this.state.manager.id || (this.state.client.bing && this.state.client.bing.bingBusinessManagerId)) &&
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.bing && this.state.client.bing.bingBusinessManagerId) || this.state.manager.id)}
                                copyText={(this.state.client.bing && this.state.client.bing.bingBusinessManagerId) ? this.state.client.bing.bingBusinessManagerId : this.state.manager.id}
                            />
                        }
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.manager.id || (this.state.client.bing && this.state.client.bing.bingBusinessManagerId)) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                searchInput={true}
                                label={this.state.client.bing && this.state.client.bing.bingAdAccountId ? "Ad account" : "Ad accounts"}
                                loader={this.state.loading_adaccounts}
                                locked={this.state.client.bing || this.state.lock_settings}
                                selected={this.state.client.bing && this.state.client.bing.bingAdAccountId ? { name: this.state.client.bing.bingAdAccountName } : (this.state.adaccount.id ? this.state.adaccount : { name: "Select adaccount" })}
                                options={this.state.adaccounts}
                                onChange={async (value) => {
                                    this.setState({
                                        adaccount: value
                                    });
                                }}
                            />
                        </div>
                        {
                            (this.state.adaccount.id || (this.state.client.bing && this.state.client.bing.bingAdAccountId)) &&
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.bing && this.state.client.bing.bingAdAccountId) || this.state.adaccount.id)}
                                copyText={(this.state.client.bing && this.state.client.bing.bingAdAccountId) ? this.state.client.bing.bingAdAccountId : this.state.adaccount.id}
                            />
                        }
                    </div>
                }
                {
                    !this.state.agency.bing &&
                    this.state.client.id &&
                    (this.state.adaccount.id || (this.state.client.bing && this.state.client.bing.bingBusinessManagerId)) &&
                    <div className="mt-5 flex">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <InputTailwind
                                    label={"Google Ads Import Credential ID (optional)"}
                                    locked={this.state.lock_settings}
                                    readOnly={(this.state.client.bing && this.state.client.bing.googleAdsImportCredentialID) || this.state.lock_settings}
                                    disabled={(this.state.client.bing && this.state.client.bing.googleAdsImportCredentialID) || this.state.lock_settings}
                                    value={this.state.client.bing && this.state.client.bing.googleAdsImportCredentialID ? this.state.client.bing.googleAdsImportCredentialID : this.state.googleAdsImportCredentialID}
                                    onChange={async (value) => {
                                        this.setState({
                                            googleAdsImportCredentialID: value
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        {
                            this.state.client.bing && this.state.client.bing.bingBusinessManagerId &&
                            <CopyButton
                                buttonText="Copy"
                                copyText={this.state.client.bing.googleAdsImportCredentialID}
                            />
                        }
                    </div>
                }
                {
                    !this.state.agency.bing &&
                    this.state.client.id &&
                    (this.state.adaccount.id || (this.state.client.bing && this.state.client.bing.bingAdAccountId)) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.bing || this.state.lock_settings}
                            selected={this.state.client.bing && this.state.client.bing.bingAdAccountId ? { name: this.state.client.bing.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    this.state.client.bing && this.state.client.bing.bingBusinessManagerId &&
                    <div className="pt-5">
                        <div className="">
                            <div className="text-xs mb-1">
                                Auto tagging
                            </div>
                            <div className="h-12 relative flex flex-row items-center w-full border-1.5 rounded-md bg-custom-input px-4">
                                {
                                    this.state.loading_auto_tagging &&
                                    <div className={"bg-white absolute bg-opacity-25 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                <div className="flex flex-1 text-sm">
                                    {
                                        this.state.auto_tagging &&
                                        <span>Enabled</span>
                                    }
                                    {
                                        !this.state.auto_tagging &&
                                        <span>Disabled</span>
                                    }
                                </div>
                                <SwitchTailwind
                                    value={this.state.auto_tagging}
                                    onSwitch={async () => {
                                        this.functions.updateAutoTagging();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.agency.google &&
                    this.state.client.id &&
                    this.state.client.google && this.state.client.google.managerId &&
                    <nav className="flex space-x-4 border-t mt-5 pt-5" aria-label="Tabs">
                        {[{ id: 4, name: "Account budgets" }].filter((item) => {
                            return !item.hidden
                        }).map((tab) => {
                            return (
                                <div
                                    onClick={() => {
                                        this.setState({
                                            subtab: tab
                                        });
                                    }}
                                    key={tab.name}
                                    style={{ whiteSpace: "nowrap" }}
                                    className={cn(
                                        tab.id == this.state.subtab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                        'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                    )}
                                    aria-current={tab.id == this.state.subtab.id ? 'page' : undefined}
                                >
                                    {tab.name}
                                </div>
                            );
                        })}
                    </nav>
                }
                {
                    !this.state.agency.bing &&
                    this.state.client.id &&
                    this.state.client.bing && this.state.client.bing.bingBusinessManagerId &&
                    this.state.subtab.id == 4 &&
                    !this.state.loading_account &&
                    <CreateAccountBudget
                        client={this.state.client}
                        channel={"bing"}
                    />
                }
                {
                    this.state.client.id &&
                    !this.state.client.bing &&
                    this.state.manager.id &&
                    this.state.adaccount.id &&
                    this.state.currency.id &&
                    <div className="flex justify-between w-full flex-row">
                        <button
                            onClick={() => {
                                if (!this.state.loading_account) {
                                    this.functions.connectToClient();
                                }
                            }}
                            className={(this.state.adaccount.id ? "bg-bing-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Save bing account</span>
                            {
                                this.state.loading_account &&
                                <div className={"bg-bing-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                        <button
                            onClick={() => {
                                window.open("https://ui.ads.microsoft.com/campaign/vnext/importcredentialid");
                            }}
                            className={(this.state.client.id ? "bg-purple-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span> Open Bing</span>
                        </button>
                    </div>
                }
                {
                    this.state.error &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    this.state.healthcheck && this.state.healthcheck.bing && this.state.healthcheck.bing.status !== 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.bing.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.client.bing &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.bing && this.state.client.bing.defaultAgent && this.state.client.bing.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.user &&
                    !this.state.is_manager &&
                    !this.state.client.bing &&
                    !(this.state.me.bing && this.state.me.bing.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated bing, go to My Profile.
                    </div>
                }
                {
                    !this.state.user &&
                    this.state.is_manager &&
                    !this.state.client.bing &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated bing
                    </div>
                }
            </div>
        )
    }
}

export default AccountBing;
