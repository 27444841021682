import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import moment from 'moment';
import SingleDatepicker from "../modules/singleDatepicker";

class AdvancedReportSlideInDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            tab: {},
            daterange: {}
        };
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            tab: nextProps.tab
        }, () => {
            if (!nextProps.closed) {
                this.setState({
                    daterange: nextProps.tab.daterange ? nextProps.tab.daterange : {}
                })
            }
        });
    }

    functions = {
        valid: () => {
            return Object.keys(this.state.daterange).length > 0;
        }
    };

    render() {
        return (
            <Transition.Root show={!this.state.closed} as={Fragment}>
                <Dialog as="div" style={{ zIndex: 9999, position: "absolute" }} className="absolute inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 transition duration-500 ease-in-out bg-black bg-opacity-25" />
                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-6 px-4 bg-purple-500 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">Update Date</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-purple-500 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-1">
                                                    <p className="text-sm text-indigo-300">
                                                        Select daterange below to update tab
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <SelectCellDaterange
                                                    tab={{ daterange: this.state.daterange }}
                                                    onTab={(tab) => {
                                                        this.setState({
                                                            daterange: tab.daterange
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    this.props.toggle()
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => {
                                                    if (this.functions.valid()) {
                                                        /*
                                                        if (this.state.daterange && this.state.daterange.value === "10_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "14_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "20_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "30_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "custom_dates") {

                                                        } else {
                                                            this.state.daterange = {};
                                                            //this.state.tab.daterange.value = "lifetime";
                                                        }
                                                        */

                                                        if (this.state.daterange && this.state.daterange.value === "7_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "10_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "14_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "20_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "30_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "last_week") {
                                                            this.state.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "last_month") {
                                                            this.state.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "this_month") {
                                                            this.state.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "custom_dates") {

                                                        } else {
                                                            this.state.daterange = {};
                                                        }

                                                        this.state.tab.daterange = this.state.daterange;
                                                        this.props.onTab(this.state.tab);
                                                        this.props.toggle();
                                                    }
                                                }}
                                                className={(this.functions.valid() ? "" : "opacity-50 cursor-not-allowed") + " ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                Add dates
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

class SelectCellDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            dateranges: []
        };
    };

    componentDidMount() {
        let dateranges = [
            /*
            {
                title: "Lifetime",
                subtitle: "From start date until today",
                icon: "calendar",
                value: "lifetime",
                disabled: true
            },
            */
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {}
        })
    }

    functions = {
        dailySelected: () => {
            return false;
        }
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="">
                        <div className="">
                            {
                                this.state.dateranges.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                <div onClick={() => {

                                                }}
                                                    className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14} icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (!item.disabled) {
                                                        this.state.tab.daterange = item;
                                                        this.props.onTab(this.state.tab);
                                                    }
                                                }}
                                                    className={(item.disabled ? "cursor-not-allowed" : "cursor-pointer") + ((this.state.tab.daterange && this.state.tab.daterange.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col truncate">
                                                    <div className="font-bold text-xs truncate">
                                                        {item.title}
                                                    </div>
                                                    {
                                                        (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                            (<div className="text-xxs">
                                                                From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                            </div>) :
                                                            (<div className="text-xxs">
                                                                {item.subtitle}
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                            <div className="w-full">
                                <SingleDatepicker
                                    maxDate={new Date()}
                                    from={this.state.tab.daterange.start_date}
                                    to={this.state.tab.daterange.end_date}
                                    onChange={(data) => {
                                        if (!this.state.tab.daterange) {
                                            this.state.tab.daterange = {};
                                        }
                                        this.state.tab.daterange.start_date = data.start_date;
                                        this.state.tab.daterange.end_date = data.end_date;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default AdvancedReportSlideInDate;