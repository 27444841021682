import moment from 'moment';

class budgetPlannerGraph {

    static main(scheduler, total_budget = false) {
        let graph_data = {
            labels: [],
            datasets: []
        }
        
        let months = [];
        let today = moment(scheduler.start_date).startOf('month');
        //let today = moment(scheduler.start_date);
        console.log(scheduler,"scheduler.order_info======");
        if (scheduler.order_info && scheduler.order_info.maxBudgetSubAction && scheduler.order_info.maxBudgetSubAction.id && (scheduler.order_info.maxBudgetSubAction.id === 1 || scheduler.order_info.maxBudgetSubAction.id === 2)) {
            if (scheduler.order_info.maxBudgetSubAction.id === 1) {
                today = moment(scheduler.start_date);
            }
        }
        for (let i = 0; i < 12; i++) {
            let month = today.month();
            let data = { daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
            let next = false;
            while (!next) {
                if (month !== today.month()) {
                    next = true;
                } else {
                    data[today.isoWeekday()] += 1;
                    today.add(1, 'd');
                }
            }
            if (next) {
                if (scheduler.weekdays.scheduleMonday) {
                    data.total += data["1"];
                }
                if (scheduler.weekdays.scheduleTuesday) {
                    data.total += data["2"];
                }
                if (scheduler.weekdays.scheduleWednesday) {
                    data.total += data["3"];
                }
                if (scheduler.weekdays.scheduleThursday) {
                    data.total += data["4"];
                }
                if (scheduler.weekdays.scheduleFriday) {
                    data.total += data["5"];
                }
                if (scheduler.weekdays.scheduleSaturday) {
                    data.total += data["6"];
                }
                if (scheduler.weekdays.scheduleSunday) {
                    data.total += data["7"];
                }
                if (scheduler.schedule_budget > 0 && !total_budget) {
                    data.daily_budget = scheduler.schedule_budget;
                    data.left_over = scheduler.schedule_budget - (data.daily_budget * data.total);
                } else {
                    data.daily_budget = total_budget
                    data.left_over = total_budget - (data.daily_budget * data.total);
                }

                if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => scheduler.weekdays[item]).length < 7) {
                    data.daily_budget = (data.daily_budget/data.total).toFixed(2);
                } else {
                    if (scheduler.order_info && scheduler.order_info.maxBudgetSubAction && scheduler.order_info.maxBudgetSubAction.id && scheduler.order_info.maxBudgetSubAction.id === 1) {
                        data.daily_budget = (data.daily_budget/data.total).toFixed(2);
                    } else {
                        data.daily_budget = (data.daily_budget/30).toFixed(2);
                    }
                }

                graph_data.labels.push([data.total + " days", data.month, data.year]);
                months.push(data);
                continue;
            }
        }

        let dataset_daily = {
            label: 'Daily budget',
            data: [],
            borderColor: 'rgb(69, 63, 241)',
            backgroundColor: 'rgba(69, 63, 241, 0.7)'
        }

        let dataset_leftover = {
            label: 'Leftover added to last day',
            data: [],
            borderColor: 'rgb(255, 77, 84)',
            backgroundColor: 'rgba(255, 77, 84, 0.7)'
        }

        months.map((item) => {
            dataset_daily.data.push(item.daily_budget);
            dataset_leftover.data.push(item.left_over);
        });

        graph_data.datasets.push(dataset_daily);
        //graph_data.datasets.push(dataset_leftover);
        console.log(graph_data,"graph_data");
        return graph_data;
    }
}
export { budgetPlannerGraph }