import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import * as turf from '@turf/turf'
import AdvertisingLocationLanguage from "../advertising-location-language";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import {calls} from "./calls";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";

class AdvertisingLocationCountry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searches: {},
            countries: [],
            locations: [],
            selected: [],
            regions: [],
            channels: [],
            loading: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            countries: nextProps.countries,
            loading: nextProps.loading,
            country: nextProps.country?nextProps.country:{},
            channels: nextProps.channels?nextProps.channels:[],
            selected: nextProps.selected?nextProps.selected:[],
            locations: nextProps.locations?nextProps.locations:[]
        })
    }

    componentDidMount() {
        this.setState({
            locations: this.props.locations,
            countries: this.props.countries,
            selected: this.props.selected,
            channels: this.props.channels?this.props.channels:[],
            loading: this.props.loading
        }, () => {
            this.functions.regions("");
        })
    }

    functions = {
        regions: (value) => {
            this.setState({
                search: value
            }, () => {
                if(value !== ""){
                    value = value === ""?"empty_string":value;
                    if(!this.state.searches[value]){
                        this.state.searches[value] = {
                            regions: [],
                            loading: true
                        };
                        this.setState({
                            searches: this.state.searches
                        }, () => {
                            value = value === "empty_string"?"":value;
                            calls.getRegions(value).then((response) => {
                                value = value === ""?"empty_string":value;
                                this.state.searches[value].regions = response.data;
                                this.state.searches[value].error = false;
                                this.state.searches[value].loading = false;
                                this.setState({
                                    searches: this.state.searches
                                });
                            }, (error) => {
                                value = value === ""?"empty_string":value;
                                this.state.searches[value].regions = [];
                                this.state.searches[value].error = true;
                                this.state.searches[value].loading = false;
                                this.setState({
                                    searches: this.state.searches
                                });
                            });
                        })
                    }
                }
            })
        },
        region: (item) => {
            item.country_code = item.country;
            this.props.onRegion(item);
        }
    };

    renders = {
        countries: () => {
            try{
                let countries = this.state.countries.filter((item) => {
                    return this.state.selected.filter((inner_item) => {return inner_item.country === item.country}).length < 1
                });
                return countries;
            }catch (e) {
                return this.state.countries;
            }
        },
        regions: (value) => {
            try{
                value = value === ""?"empty_string":value;
                return this.state.searches[value]?this.state.searches[value]:{regions: [], loading: false};
            }catch (e) {
                return {regions: [], loading: false}
            }
        }
    };

    render() {
        return (
            <Dropdown
                filter={true}
                searchFilter={true}
                searchList={false}
                search={true}
                title={this.props.title}
                rightTitle={true}
                rightTitleText={"Search pr. platforms"}
                size={"full"}
                icons={true}
                channels={this.state.channels}
                placeholder={"Search here .."}
                loading={this.renders.regions(this.state.search).loading}
                options={this.renders.regions(this.state.search).regions.map((item) => {
                    let level_name = "";
                    if(item.level == 1){
                        level_name = "Area";
                    }else if(item.level == 2){
                        level_name = "City";
                    }else if(item.level == 3){
                        level_name = "State/County";
                    }else if(item.level == 4){
                        level_name = "Country";
                    }
                    item.suffix = ", " + item.country_name + " (" + level_name + ")";
                    return item;
                })}
                value={this.state.search}
                onSearch={(value) => {
                    this.functions.regions(value);
                }}
                onChange={(value) => {
                    //TEMP
                    if(value.channels && value.channels.google){
                        value.channels.google_shopping = JSON.parse(JSON.stringify(value.channels.google));
                    }
                    this.functions.region(value);
                }}
                onRightTitleClick={() => {
                    this.props.showAll()
                }}
            />
        )
    }
}

export default AdvertisingLocationCountry;
