import { orderRegister } from './orderRegister';

var moment = require('moment');

class campaignRegister {

    static campaign = null;

    static set(value) {
        campaignRegister.campaign = value;
        try {
            sessionStorage.setItem("campaign", JSON.stringify(campaignRegister.campaign));
        } catch (e) {
        }
    }

    static get() {
        if (campaignRegister.campaign) {
            return campaignRegister.campaign
        } else {
            try {
                if (sessionStorage.getItem("campaign")) {
                    let campaign = JSON.parse(sessionStorage.getItem("campaign"));
                    return campaign;
                } else {
                    return null;
                }
            } catch (e) {
                return null;
            }
        }
    }

    static remove() {
        campaignRegister.campaign = null;
        try {
            sessionStorage.removeItem("campaign");
        } catch (e) {
        }
    }

    static exportV2() {

        let data = JSON.parse(JSON.stringify(campaignRegister.get()));
        let onboarding_copy = JSON.parse(JSON.stringify(campaignRegister.get()));
        let campaign = {};

        //SET BASIC
        campaign.client = typeof data.client.value === 'object' ? data.client.value.id : null;
        campaign.campaign_name = typeof data.details.value === 'object' ? data.details.value.name : "Campaign";

        //SET CHANNELS
        campaign.channels = {};
        try {
            for (let key in data.channels.value) {
                campaign.channels[key] = data.channels.value[key];
            }
        } catch (e) {
        }

        //ERROR CAMPAIGN ID
        try {
            if (onboarding_copy.extra.remove_after) {
                campaign.error_campaign_id = onboarding_copy.extra.campaign_id;
                campaign.remove_after = true;
            }
        } catch (e) {
            console.log(e);
        }

        //COPY ONBOARDING
        try {
            onboarding_copy.locations.value = onboarding_copy.locations.value.filter((item) => {
                return !item.negative && onboarding_copy.locations.value.filter((inner_item) => {
                    return inner_item.relative_location === item.id
                }).length < 1
            }).map((item) => {
                delete item.paths;
                return item;
            });
        } catch (e) {
            console.log(e);
        }
        try {
            if (Array.isArray(onboarding_copy.search.value.campaigns)) {
                onboarding_copy.search.value.campaigns = onboarding_copy.search.value.campaigns.map((item) => {
                    delete item.ideas;
                    return item;
                });
            }
        } catch (e) {
            console.log(e);
        }
        try {
            if (onboarding_copy.targeting.value.facebook && Array.isArray(onboarding_copy.targeting.value.facebook.audience)) {
                delete onboarding_copy.targeting.value.facebook.audience;
            }
        } catch (e) { }

        campaign.onboarding = onboarding_copy;

        //SEARCH CAMPAIGNS
        campaign.search_campaigns = {};

        if (data.search && typeof data.search.value === 'object' && Array.isArray(data.search.value.campaigns)) {
            data.search.value.campaigns.map((item) => {

                //SET BASIC
                campaign.search_campaigns[item.id] = {};
                campaign.search_campaigns[item.id].keywords = Array.isArray(data.search.value.keywords) ? data.search.value.keywords.filter((keyword) => {
                    return keyword.campaign_id === item.id
                }).map((keyword) => {
                    try {
                        keyword.name = keyword.name.trim();
                        keyword.name = keyword.name.toLowerCase();
                        keyword.name = keyword.name.replace(/^\s+/g, '');
                        keyword.name = keyword.name.replace(/\s+$/g, '');
                    } catch (e) { }

                    keyword.id = keyword.name;

                    return keyword;
                }) : [];
                campaign.search_campaigns[item.id].sitelinks = Array.isArray(data.search.value.site_links) ? data.search.value.site_links.filter((keyword) => {
                    return keyword.campaign_id === item.id
                }) : [];
                campaign.search_campaigns[item.id].language = typeof item.language === "object" ? item.language : {};
                campaign.search_campaigns[item.id].languages = Array.isArray(item.languages) ? item.languages : [];
                campaign.search_campaigns[item.id].phone_numbers = Array.isArray(data.search.value.phone_numbers) ? data.search.value.phone_numbers.filter((keyword) => {
                    return keyword.campaign_id === item.id
                }) : [];
                campaign.search_campaigns[item.id].targeting = {
                    age_max: typeof item.targeting === "object" ? (typeof item.targeting.age_max === "object" ? item.targeting.age_max.value : 65) : 65,
                    age_min: typeof item.targeting === "object" ? (typeof item.targeting.age_min === "object" ? item.targeting.age_min.value : 18) : 18,
                    gender: typeof item.targeting === "object" ? (typeof item.targeting.gender === "object" ? item.targeting.gender.value : 0) : 0
                };
                campaign.search_campaigns[item.id].budget = {};

                //SET CAMPAIGN CHANNEL DATA
                for (let channel in item.budget) {
                    if (channel === "google") {
                        campaign.search_campaigns[item.id].budget[channel] = {};
                        campaign.search_campaigns[item.id].budget[channel].focus = typeof item.budget[channel].focus === "object" ? item.budget[channel].focus.value : "clicks";
                        campaign.search_campaigns[item.id].budget[channel].target_cost = item.budget[channel].target_cost;
                        campaign.search_campaigns[item.id].budget[channel].start_date = item.budget[channel].start_date;
                        campaign.search_campaigns[item.id].budget[channel].end_date = item.budget[channel].end_date;
                        campaign.search_campaigns[item.id].budget[channel].no_end_date = item.budget[channel].no_end_date;
                        campaign.search_campaigns[item.id].budget[channel].daily = item.budget[channel].daily_budget;
                        campaign.search_campaigns[item.id].budget[channel].disabled = item.budget[channel].disabled;
                    }
                }
                campaign.search_campaigns[item.id].name = item.name;

                //SET NEGATIVE KEYWORDS
                campaign.search_campaigns[item.id].negatives = Array.isArray(data.search.value.negatives) ? data.search.value.negatives.filter((keyword) => {
                    return keyword.campaign_id === item.id
                }).map((keyword) => {
                    if (keyword.phrase) {
                        keyword.type = "phrase"
                    } else if (keyword.exact) {
                        keyword.type = "exact"
                    } else if (keyword.broad) {
                        keyword.type = "broad"
                    } else {
                        keyword.type = "exact"
                    }
                    return keyword
                }) : [];


                //SET TEMPLATES
                campaign.search_campaigns[item.id].templates = Array.isArray(data.search.value.templates) ? data.search.value.templates.filter((template) => {
                    return template.campaign_id === item.id
                }).map((template) => {
                    let template_data = {
                        headline_type: template.headline_first_template.type,
                        headline_second_type: template.headline_second_template.type,
                        headline_third_type: template.headline_third_template.type,
                        headline_keyword: template.headline_first_template.type === "keyword_insert",
                        headline_second_keyword: template.headline_second_template.type === "keyword_insert",
                        headline_third_keyword: template.headline_third_template.type === "keyword_insert",
                        overwrite: template.overwrite,
                        overwrite_url: template.overwrite ? template.overwrite_url : "",
                        overwrite_url_path1: template.overwrite ? template.overwrite_url_path1 : "",
                        overwrite_url_path2: template.overwrite ? template.overwrite_url_path2 : "",
                        specific_keywords: template.specific_keywords,
                        specific_sitelinks: template.specific_sitelinks,
                        no_sitelinks: template.no_sitelinks,
                        body: template.body,
                        adgroup_strategy: template.adgroup_strategy ? template.adgroup_strategy.value : "combined",
                        adgroup_name: template.adgroup_name,
                        adgroup_cpc: template.adgroup_cpc ? template.adgroup_cpc : 0,
                        adgroup_keyword_fallback: template.adgroup_keyword_fallback,
                        body_second: template.body_second ? template.body_second : "",
                        template_type: template.template_type ? template.template_type.value : null,
                        extra_headlines: Array.isArray(template.extra_headlines) ? template.extra_headlines.map((item) => { return item.text }) : [],
                        extra_descriptions: Array.isArray(template.extra_descriptions) ? template.extra_descriptions.map((item) => { return item.text }) : [],
                    };

                    //CHANGE SPECIFIC KEYWORDS ID AGAINST NAME
                    if (template_data.specific_keywords && Object.keys(template_data.specific_keywords).length > 0) {
                        let specific_keywords = {};
                        try {
                            for (let specific_keyword in template_data.specific_keywords) {
                                try {
                                    template_data.specific_keywords[specific_keyword].id = template_data.specific_keywords[specific_keyword].id.trim();
                                    template_data.specific_keywords[specific_keyword].id = template_data.specific_keywords[specific_keyword].id.toLowerCase();
                                    template_data.specific_keywords[specific_keyword].id = template_data.specific_keywords[specific_keyword].id.replace(/^\s+/g, '');
                                    template_data.specific_keywords[specific_keyword].id = template_data.specific_keywords[specific_keyword].id.replace(/\s+$/g, '');
                                } catch (e) { }
                                specific_keywords[template_data.specific_keywords[specific_keyword].id] = template_data.specific_keywords[specific_keyword];
                            }
                            template_data.specific_keywords = specific_keywords;
                        } catch (e) { }
                    } else {
                        delete template_data.specific_keywords;
                    }

                    //CHECK SITELINKS
                    if (template_data.specific_sitelinks && Object.keys(template_data.specific_sitelinks).length > 0) {

                    } else {
                        delete template_data.specific_sitelinks;
                    }

                    //FIRST HEADLINE
                    if (template_data.headline_type === "custom" && typeof template.custom === "object") {
                        template_data.headline_text = template.custom.headline;
                    }
                    if (template_data.headline_type !== "custom" && typeof data.creatives.value === "object") {
                        template_data.headline_text = data.creatives.value[template_data.headline_type];
                    }
                    if (template_data.headline_type === "no_option" || template_data.headline_type === "keyword_insert") {
                        template_data.headline_text = "";
                    }
                    //SECOND HEADLINE
                    if (template_data.headline_second_type === "custom" && typeof template.custom === "object") {
                        template_data.headline_second_text = template.custom.headline_second;
                    }
                    if (template_data.headline_second_type !== "custom" && typeof data.creatives.value === "object") {
                        template_data.headline_second_text = data.creatives.value[template_data.headline_second_type];
                    }
                    if (template_data.headline_second_type === "no_option" || template_data.headline_second_type === "keyword_insert") {
                        template_data.headline_second_text = "";
                    }
                    //THIRD HEADLINE
                    if (template_data.headline_third_type === "custom" && typeof template.custom === "object") {
                        template_data.headline_third_text = template.custom.headline_third;
                    }
                    if (template_data.headline_third_type !== "custom" && typeof data.creatives.value === "object") {
                        template_data.headline_third_text = data.creatives.value[template_data.headline_third_type];
                    }
                    if (template_data.headline_third_type === "no_option" || template_data.headline_third_type === "keyword_insert") {
                        template_data.headline_third_text = "";
                    }
                    return template_data;
                }) : [];

                //SET LOCATIONS
                campaign.search_campaigns[item.id].locations = [];
                campaign.search_campaigns[item.id].negative_locations = [];
                if (Array.isArray(data.locations.value)) {
                    let exclude = typeof item.exclude_locations === "object" ? item.exclude_locations : {};
                    campaign.search_campaigns[item.id].locations = data.locations.value.filter((location) => {
                        return !location.negative && location.include && !exclude[location.id] &&
                            (location.channels['google'] && !location.channels['google'].disabled && !item.budget['google'].disabled)
                    }).map((location) => {
                        return {
                            id: location.id,
                            lat: location.lat,
                            lng: location.lng,
                            radius: location.radius,
                            channels: location.channels,
                            level: location.level,
                            name: location.name,
                            custom: location.custom,
                            include: location.include
                        }
                    });
                    campaign.search_campaigns[item.id].negative_locations = data.locations.value.filter((location) => {
                        return !location.negative && !location.include && !exclude[location.id] &&
                            (location.channels['google'] && !location.channels['google'].disabled && !item.budget['google'].disabled)
                    }).map((location) => {
                        return {
                            id: location.id,
                            lat: location.lat,
                            lng: location.lng,
                            radius: location.radius,
                            channels: location.channels,
                            level: location.level,
                            name: location.name,
                            custom: location.custom,
                            include: location.include
                        }
                    });
                }

            });
        }

        //SOCIAL CAMPAIGNS
        campaign.social_campaigns = {};

        if (data.channels && data.channels.value && (data.channels.value.facebook || data.channels.value.linkedin) && data.creatives && typeof data.creatives.value === 'object' && Array.isArray(data.creatives.value.campaigns)) {

            data.creatives.value.campaigns.map((item) => {

                campaign.social_campaigns[item.id] = {};
                campaign.social_campaigns[item.id].adsets = {};
                campaign.social_campaigns[item.id].name = item.name;
                campaign.social_campaigns[item.id].budget = {};

                //SET CAMPAIGN CHANNEL DATA
                for (let channel in item.budget) {
                    if (channel !== "total" && channel !== "google") {

                        campaign.social_campaigns[item.id].budget[channel] = {};
                        campaign.social_campaigns[item.id].budget[channel].focus = typeof item.budget[channel].focus === "object" ? item.budget[channel].focus.value : "traffic";
                        campaign.social_campaigns[item.id].budget[channel].bid_amount = item.budget[channel].bid_amount;
                        campaign.social_campaigns[item.id].budget[channel].start_date = item.budget[channel].start_date;
                        campaign.social_campaigns[item.id].budget[channel].end_date = item.budget[channel].end_date;
                        campaign.social_campaigns[item.id].budget[channel].no_end_date = item.budget[channel].no_end_date;
                        campaign.social_campaigns[item.id].budget[channel].daily = item.budget[channel].daily_budget;
                        campaign.social_campaigns[item.id].budget[channel].disabled = item.budget[channel].disabled;
                        campaign.social_campaigns[item.id].budget[channel].special_ad_category = item.budget[channel].special_ad_category ? item.budget[channel].special_ad_category.value : "NONE";

                        //IF ADSET LEVEL BUDGET (FACEBOOK)
                        if (channel === "facebook_instagram") {
                            if (item.budget[channel].budget_level) {
                                campaign.social_campaigns[item.id].budget[channel].budget_level = item.budget[channel].budget_level;
                                if (item.budget[channel].budget_level.value == "adset_budget") {
                                    campaign.social_campaigns[item.id].budget[channel].daily = 0;
                                }
                            }
                        }

                        if (channel === "linkedin") {
                            campaign.social_campaigns[item.id].budget[channel].budget_type = item.budget[channel].budget_type ? item.budget[channel].budget_type.value : "daily_budget";
                            campaign.social_campaigns[item.id].budget[channel].total_budget = item.budget[channel].total_budget;
                        }

                    }
                }
            });
        }

        //ADD SOCIAL ADSETS TO CAMPAIGN
        if (data.creatives && typeof data.creatives.value === 'object' && Array.isArray(data.creatives.value.adsets)) {
            for (let campaign_id in campaign.social_campaigns) {
                data.creatives.value.adsets.map((item) => {

                    if (+item.campaign_id === +campaign_id) {

                        //SET BASIC
                        campaign.social_campaigns[campaign_id].adsets[item.id] = {};
                        campaign.social_campaigns[campaign_id].adsets[item.id].name = item.name;
                        campaign.social_campaigns[campaign_id].adsets[item.id].targeting = {};

                        //PLACEMENT TARGETING
                        if (item.targeting && typeof item.targeting === "object" && typeof item.targeting.placement === "object") {
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement = {};

                            //ALL
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.all = item.targeting.placement.all;

                            //FACEBOOK
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.feed = item.targeting.placement.feed;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.story = item.targeting.placement.story;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.right_hand_column = item.targeting.placement.right_hand_column;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.instant_article = item.targeting.placement.instant_article;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.marketplace = item.targeting.placement.marketplace;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.video_feeds = item.targeting.placement.video_feeds;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.search = item.targeting.placement.search;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.messenger_inbox = item.targeting.placement.messenger_inbox;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.messenger_story = item.targeting.placement.messenger_story;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.audience_rewarded_video = item.targeting.placement.audience_rewarded_video;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.audience_classic = item.targeting.placement.audience_classic;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.audience_instream_video = item.targeting.placement.audience_instream_video;

                            //INSTAGRAM
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.explore_instagram = (item.targeting.placement.explore_instagram || item.targeting.placement.explore);
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.stream_instagram = (item.targeting.placement.stream_instagram || item.targeting.placement.feed_instagram);
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.story_instagram = item.targeting.placement.story_instagram;
                            //campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.shop_instagram = item.targeting.placement.shop_instagram;
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.placement.reels_instagram = item.targeting.placement.reels_instagram;

                        }

                        //SET DEMOGRAPHIC TARGETING
                        if (item.targeting && typeof item.targeting === "object") {
                            if (typeof item.targeting.age_min === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.age_min = item.targeting.age_min.value;
                            } else {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.age_min = 18;
                            }
                            if (typeof item.targeting.age_max === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.age_max = item.targeting.age_max.value;
                            } else {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.age_max = 18;
                            }
                            if (typeof item.targeting.gender === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.gender = item.targeting.gender.value;
                            } else {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.gender = 0;
                            }

                            //SET TARGETING LINKEDIN
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin = {};
                            if (item.targeting.age_span_linkedin && typeof item.targeting.age_span_linkedin === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.age_span = item.targeting.age_span_linkedin.value;
                            }
                            if (item.targeting.linkedin && item.targeting.linkedin.language && typeof item.targeting.linkedin.language === "object") {
                                try {
                                    let lang_country = item.targeting.linkedin.language.value;
                                    lang_country = lang_country.split("_");
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.language = lang_country[0];
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.country = lang_country[1];
                                } catch (e) { }
                                try {
                                    if (Array.isArray(item.targeting.linkedin.targeting_items)) {
                                        campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.targeting_items = item.targeting.linkedin.targeting_items;
                                    }
                                } catch (e) { }
                            }

                            //BUDGET ADSET LINKEDIN
                            try {
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.budget_type = item.targeting.linkedin.budget_type ? item.targeting.linkedin.budget_type.value : "daily_budget";
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.daily_budget = item.targeting.linkedin.daily_budget;
                                campaign.social_campaigns[campaign_id].adsets[item.id].targeting.linkedin.total_budget = item.targeting.linkedin.total_budget;
                            } catch (error) { }

                            //SET TARGEING FACEBOOK
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook = {};
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.behaviors = [];
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.interests = [];
                            campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.audience = [];
                            if (typeof item.targeting.facebook === "object") {
                                if (Array.isArray(item.targeting.facebook.behaviors)) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.behaviors = item.targeting.facebook.behaviors.map((item) => {
                                        return { id: item.id, name: item.name }
                                    });
                                }
                                if (Array.isArray(item.targeting.facebook.interests)) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.interests = item.targeting.facebook.interests.map((item) => {
                                        return { id: item.id, name: item.name }
                                    });
                                }
                                if (Array.isArray(item.targeting.facebook.selected_audience)) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.audience = item.targeting.facebook.selected_audience.map((item) => {
                                        return { id: item.id, name: item.name }
                                    });
                                }
                                /*
                                if (typeof item.targeting.facebook.special_ad_category === "object") {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.special_ad_category = item.targeting.facebook.special_ad_category.value;
                                } else {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.special_ad_category = "NONE";
                                }
                                */
                                if (item.targeting.facebook.selected_page) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.page = item.targeting.facebook.selected_page.id;
                                }
                                if (item.targeting.facebook.selected_instagram) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.instagram_account = item.targeting.facebook.selected_instagram.id;
                                }
                                if (item.targeting.facebook.selected_event) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.event = item.targeting.facebook.selected_event.id;
                                }
                                if (item.targeting.facebook.start_date) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.start_date = item.targeting.facebook.start_date;
                                }
                                if (item.targeting.facebook.end_date) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.end_date = item.targeting.facebook.end_date;
                                }
                                if (item.targeting.facebook.no_end_date) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.no_end_date = item.targeting.facebook.no_end_date;
                                }
                                if (item.targeting.facebook.app_url) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.app_url = item.targeting.facebook.app_url;
                                }
                                if (item.targeting.facebook.app && item.targeting.facebook.app.id) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.app_id = item.targeting.facebook.app.id;
                                }
                                if (campaign.social_campaigns[campaign_id].budget["facebook_instagram"] && campaign.social_campaigns[campaign_id].budget["facebook_instagram"].budget_level && campaign.social_campaigns[campaign_id].budget["facebook_instagram"].budget_level.value == "adset_budget") {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.daily_budget = item.targeting.facebook.daily_budget;
                                } else {
                                    if (item.targeting.facebook.min_budget && item.targeting.facebook.min_budget > 0) {
                                        campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.min_budget = item.targeting.facebook.min_budget;
                                    }
                                    if (item.targeting.facebook.max_budget && item.targeting.facebook.max_budget > 0) {
                                        campaign.social_campaigns[campaign_id].adsets[item.id].targeting.facebook.max_budget = item.targeting.facebook.max_budget;
                                    }
                                }
                            }
                        }

                        //SET CONVERSION
                        campaign.social_campaigns[campaign_id].adsets[item.id].conversion = {
                            facebook: {},
                            linkedin: {},
                            twitter: {}
                        };
                        if (typeof item.conversion === "object") {
                            if (item.conversion.prospect_default) {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.prospect_default = true;
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.prospect = {
                                    show: true,
                                    audience: []
                                };
                            }
                            if (item.conversion.lookalike_default) {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.lookalike_default = true;
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.lookalike = {
                                    show: true,
                                    audience: []
                                };
                                if (typeof item.conversion.facebook === "object" && Array.isArray(item.conversion.facebook.audiences)) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.lookalike.audience = item.conversion.facebook.audiences.filter((audience) => {
                                        return audience.look_a_like_selected && !item.error
                                    });
                                }
                            }
                            if (item.conversion.remarketing_default) {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.remarketing_default = true;
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.remarketing = {
                                    show: true,
                                    audience: []
                                };
                                if (typeof item.conversion.facebook === "object" && Array.isArray(item.conversion.facebook.audiences)) {
                                    campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.remarketing.audience = item.conversion.facebook.audiences.filter((audience) => {
                                        return audience.remarketing_selected && !item.error
                                    });
                                }
                            }
                            if (typeof item.conversion.facebook === "object" && typeof item.conversion.facebook.conversion_event === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.conversion_event = item.conversion.facebook.conversion_event.value;
                            }
                            if (typeof item.conversion.facebook === "object" && typeof item.conversion.facebook.conversion_dynamic_days === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.conversion_dynamic_days = item.conversion.facebook.conversion_dynamic_days.value;
                            }
                            if (typeof item.conversion.facebook === "object" && typeof item.conversion.facebook.conversion_dynamic_event === "object") {
                                campaign.social_campaigns[campaign_id].adsets[item.id].conversion.facebook.conversion_dynamic_event = item.conversion.facebook.conversion_dynamic_event.value;
                            }
                        }

                        //SET LOCATIONS
                        campaign.social_campaigns[campaign_id].adsets[item.id].locations = [];
                        campaign.social_campaigns[campaign_id].adsets[item.id].negative_locations = [];

                        if (Array.isArray(data.locations.value)) {

                            //DONT INCLUDE IN ADSET
                            let exclude = (item.exclude_locations && typeof item.exclude_locations === "object") ? item.exclude_locations : {};

                            //POSITIVE LOCATIONS
                            campaign.social_campaigns[campaign_id].adsets[item.id].locations = data.locations.value.filter((location) => {
                                return location.include && !exclude[location.id]
                                    && (
                                        (location.channels['facebook'] && !location.channels['facebook'].disabled && campaign.social_campaigns[campaign_id] && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['facebook_instagram'] && !campaign.social_campaigns[campaign_id].budget['facebook_instagram'].disabled)
                                        ||
                                        (location.channels['linkedin'] && !location.channels['linkedin'].disabled && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['linkedin'] && !campaign.social_campaigns[campaign_id].budget['linkedin'].disabled)
                                        ||
                                        (location.channels['twitter'] && !location.channels['twitter'].disabled && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['twitter'] && !campaign.social_campaigns[campaign_id].budget['twitter'].disabled)
                                    )
                            }).map((item) => {
                                return {
                                    name: item.name,
                                    id: item.id,
                                    lat: item.lat,
                                    lng: item.lng,
                                    radius: item.radius,
                                    channels: item.channels,
                                    level: item.level,
                                    custom: item.custom,
                                    include: item.include
                                }
                            });

                            //NEGATIVE LOCATIONS
                            campaign.social_campaigns[campaign_id].adsets[item.id].negative_locations = data.locations.value.filter((location) => {
                                return !location.include && !exclude[location.id]
                                    && (
                                        (location.channels['facebook'] && !location.channels['facebook'].disabled && campaign.social_campaigns[campaign_id] && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['facebook_instagram'] && !campaign.social_campaigns[campaign_id].budget['facebook_instagram'].disabled)
                                        ||
                                        (location.channels['linkedin'] && !location.channels['linkedin'].disabled && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['linkedin'] && !campaign.social_campaigns[campaign_id].budget['linkedin'].disabled)
                                        ||
                                        (location.channels['twitter'] && !location.channels['twitter'].disabled && campaign.social_campaigns[campaign_id].budget && campaign.social_campaigns[campaign_id].budget['twitter'] && !campaign.social_campaigns[campaign_id].budget['twitter'].disabled)
                                    )
                            }).map((item) => {
                                return {
                                    name: item.name,
                                    id: item.id,
                                    lat: item.lat,
                                    lng: item.lng,
                                    radius: item.radius,
                                    channels: item.channels,
                                    level: item.level,
                                    custom: item.custom,
                                    include: item.include
                                }
                            });

                        }

                        //SET IMAGE ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].image_ads = [];
                        if (Array.isArray(data.creatives.value.image_ads)) {
                            data.creatives.value.image_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let image_ad = {};
                                    image_ad.name = ad.title;
                                    image_ad.body = ad.body;
                                    image_ad.website = ad.website;
                                    image_ad.description = ad.description;
                                    image_ad.display_website = ad.display_website;
                                    image_ad.call_to_action = typeof ad.call_to_action === "object" ? ad.call_to_action.value : "NO_BUTTON";
                                    image_ad.call_to_action_linkedin = typeof ad.call_to_action_linkedin === "object" ? ad.call_to_action_linkedin.value : "NO_BUTTON";
                                    image_ad.headline = ad.headline;
                                    image_ad.creative = ad.creative;
                                    image_ad.facebook_page = ad.facebook_page;
                                    image_ad.facebook_event = ad.facebook_event;
                                    image_ad.instagram_account = ad.instagram_account;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].image_ads.push(image_ad);

                                    //SET LOCATION HEADLINE
                                    try {
                                        let location = campaign.social_campaigns[campaign_id].adsets[item.id].locations[0];
                                        image_ad.headline = image_ad.headline.replace("{location}", location.name);
                                        image_ad.body = image_ad.body.replace("{location}", location.name);
                                        image_ad.description = image_ad.description.replace("{location}", location.name);
                                    } catch (error) { }

                                }
                            })
                        }

                        //SET VIDEO ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].video_ads = [];
                        if (Array.isArray(data.creatives.value.video_ads)) {
                            data.creatives.value.video_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let video_ad = {};
                                    video_ad.name = ad.title;
                                    video_ad.body = ad.body;
                                    video_ad.website = ad.website;
                                    video_ad.description = ad.description;
                                    video_ad.display_website = ad.display_website;
                                    video_ad.call_to_action = typeof ad.call_to_action === "object" ? ad.call_to_action.value : "NO_BUTTON";
                                    video_ad.call_to_action_linkedin = typeof ad.call_to_action_linkedin === "object" ? ad.call_to_action_linkedin.value : "NO_BUTTON";
                                    video_ad.headline = ad.headline;
                                    video_ad.creative = ad.creative;
                                    video_ad.facebook_page = ad.facebook_page;
                                    video_ad.instagram_account = ad.instagram_account;
                                    video_ad.facebook_event = ad.facebook_event;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].video_ads.push(video_ad);

                                    //SET LOCATION HEADLINE
                                    try {
                                        let location = campaign.social_campaigns[campaign_id].adsets[item.id].locations[0];
                                        video_ad.headline = video_ad.headline.replace("{location}", location.name);
                                        video_ad.body = video_ad.body.replace("{location}", location.name);
                                        video_ad.description = video_ad.description.replace("{location}", location.name);
                                    } catch (error) { }

                                }
                            })
                        }

                        //SET CAROUSEL ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].carousel_ads = [];
                        if (Array.isArray(data.creatives.value.carousel_ads)) {
                            data.creatives.value.carousel_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let carousel_ad = {};
                                    carousel_ad.name = ad.title;
                                    carousel_ad.body = ad.body;
                                    carousel_ad.website = ad.website;
                                    carousel_ad.items = ad.items;
                                    carousel_ad.facebook_event = ad.facebook_event;
                                    carousel_ad.facebook_page = ad.facebook_page;
                                    carousel_ad.instagram_account = ad.instagram_account;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].carousel_ads.push(carousel_ad);

                                    //SET LOCATION HEADLINE
                                    try {
                                        let location = campaign.social_campaigns[campaign_id].adsets[item.id].locations[0];
                                        carousel_ad.body = carousel_ad.body.replace("{location}", location.name);
                                    } catch (error) { }

                                }
                            })
                        }

                        //SET DYNAMIC ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].dynamic_ads = [];
                        if (Array.isArray(data.creatives.value.dynamic_ads)) {
                            data.creatives.value.dynamic_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let dynamic_ad = {};
                                    dynamic_ad.name = ad.title;
                                    dynamic_ad.body = ad.body;
                                    dynamic_ad.website = ad.website;
                                    dynamic_ad.facebook_page = ad.facebook_page;
                                    dynamic_ad.instagram_account = ad.instagram_account;
                                    dynamic_ad.call_to_action = typeof ad.call_to_action === "object" ? ad.call_to_action.value : "NO_BUTTON";
                                    dynamic_ad.headline = ad.headline;
                                    dynamic_ad.product_set = typeof ad.product_set === "object" ? ad.product_set.id : null;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].dynamic_ads.push(dynamic_ad);

                                    //SET LOCATION HEADLINE
                                    try {
                                        let location = campaign.social_campaigns[campaign_id].adsets[item.id].locations[0];
                                        dynamic_ad.body = dynamic_ad.body.replace("{location}", location.name);
                                        dynamic_ad.headline = dynamic_ad.headline.replace("{location}", location.name);
                                    } catch (error) { }

                                }
                            })
                        }

                        //SET EVENT ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].event_ads = [];
                        if (Array.isArray(data.creatives.value.event_ads)) {
                            data.creatives.value.event_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let event_ad = {};
                                    event_ad.name = ad.title;
                                    event_ad.body = ad.body;
                                    event_ad.facebook_page = ad.facebook_page;
                                    event_ad.facebook_event = ad.facebook_event;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].event_ads.push(event_ad);

                                    //SET LOCATION HEADLINE
                                    try {
                                        let location = campaign.social_campaigns[campaign_id].adsets[item.id].locations[0];
                                        event_ad.body = event_ad.body.replace("{location}", location.name);
                                    } catch (error) { }

                                }
                            })
                        }

                        //SET POST ADS
                        campaign.social_campaigns[campaign_id].adsets[item.id].post_ads = [];
                        if (Array.isArray(data.creatives.value.post_ads)) {
                            data.creatives.value.post_ads.filter((ad) => {
                                return !ad.error
                            }).map((ad) => {
                                if (typeof ad.internal_adset === "object" && ad.internal_adset.id === item.id) {
                                    let post_ad = {};
                                    post_ad.name = ad.title;
                                    post_ad.facebook_page = ad.facebook_page;
                                    post_ad.instagram_account = ad.instagram_account;
                                    post_ad.instagram_post = ad.instagram_post;
                                    post_ad.facebook_post = ad.facebook_post;
                                    campaign.social_campaigns[campaign_id].adsets[item.id].post_ads.push(post_ad);
                                }
                            })
                        }

                    }
                });
            }
        }

        //SHOPPING CAMPAIGNS
        campaign.shopping_campaigns = {};

        if (data.shopping && typeof data.shopping.value === 'object' && Array.isArray(data.shopping.value.campaigns)) {
            data.shopping.value.campaigns.map((item) => {

                //SET BASIC
                campaign.shopping_campaigns[item.id] = {};

                campaign.shopping_campaigns[item.id].targeting = {
                    //age_max: typeof item.targeting === "object" ? (typeof item.targeting.age_max === "object" ? item.targeting.age_max.value : 65) : 65,
                    //age_min: typeof item.targeting === "object" ? (typeof item.targeting.age_min === "object" ? item.targeting.age_min.value : 18) : 18,
                    //gender: typeof item.targeting === "object" ? (typeof item.targeting.gender === "object" ? item.targeting.gender.value : 0) : 0
                };

                campaign.shopping_campaigns[item.id].budget = {};

                //SET CAMPAIGN CHANNEL DATA
                for (let channel in item.budget) {
                    if (channel === "google_shopping") {
                        campaign.shopping_campaigns[item.id].budget[channel] = {};
                        campaign.shopping_campaigns[item.id].budget[channel].focus = typeof item.budget[channel].focus === "object" ? item.budget[channel].focus.value : "clicks";
                        campaign.shopping_campaigns[item.id].budget[channel].target_cost = item.budget[channel].target_cost ? item.budget[channel].target_cost : 0;
                        campaign.shopping_campaigns[item.id].budget[channel].start_date = item.budget[channel].start_date;
                        campaign.shopping_campaigns[item.id].budget[channel].end_date = item.budget[channel].end_date;
                        campaign.shopping_campaigns[item.id].budget[channel].no_end_date = item.budget[channel].no_end_date;
                        campaign.shopping_campaigns[item.id].budget[channel].daily = item.budget[channel].daily_budget;
                        campaign.shopping_campaigns[item.id].budget[channel].disabled = item.budget[channel].disabled;
                        campaign.shopping_campaigns[item.id].budget[channel].target_roas = item.budget[channel].target_roas ? item.budget[channel].target_roas : 0;
                    }

                    //PARSE NUMBER
                    campaign.shopping_campaigns[item.id].budget[channel].target_cost = Math.floor(campaign.shopping_campaigns[item.id].budget[channel].target_cost);
                    campaign.shopping_campaigns[item.id].budget[channel].target_roas = parseFloat(campaign.shopping_campaigns[item.id].budget[channel].target_roas);

                }
                campaign.shopping_campaigns[item.id].name = item.name;

                //SHOPPING DATA
                campaign.shopping_campaigns[item.id].smart = item.shopping_campaigntype.value === "smart";
                campaign.shopping_campaigns[item.id].country = item.shopping_country.value;

                if (item.shopping_priority) {
                    campaign.shopping_campaigns[item.id].priority = item.shopping_priority.value;
                }

            });
        }

        //TURN SEARCH CAMPAIGNS INTO ARRAY
        let search_campaigns = [];
        for (let key in campaign.search_campaigns) {
            search_campaigns.push(campaign.search_campaigns[key]);
        }
        campaign.search_campaigns = search_campaigns;

        //TURN SOCIAL CAMPAIGNS INTO ARRAY
        let social_campaigns = [];
        for (let key in campaign.social_campaigns) {
            social_campaigns.push(campaign.social_campaigns[key]);
        }
        campaign.social_campaigns = social_campaigns;

        //TURN SHOPPING CAMPAIGNS INTO ARRAY
        let shopping_campaigns = [];
        for (let key in campaign.shopping_campaigns) {
            shopping_campaigns.push(campaign.shopping_campaigns[key]);
        }
        campaign.shopping_campaigns = shopping_campaigns;

        return campaign;

    }

}

export { campaignRegister }