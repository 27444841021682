import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import ReportListItem from "../../components/report-list-item";
import CsvDownload from "react-json-to-csv";

import {SlideDown} from 'react-slidedown'
import {Bar, Doughnut, Line} from "react-chartjs-2";
//import Dropdown from "../../components/dropdown";

import Dropdown from "../dropdown";

import ReportsListRow from "../reportslistrow";

var moment = require('moment');

class ReportsChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: {},
            level: {},
            items: [],
            properties: [],
            line: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            chart: {},
            chart_type: {name: 'Bar chart', value: 'bar'},
            type:'',
            channels: [],
            channel: {},
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            report: nextProps.report ? nextProps.report : {},
            level: nextProps.level ? nextProps.level : {},
            channels: nextProps.channels ? nextProps.channels : [],
        }, () => {
            //this.functions.data();
        });
    }

    componentDidMount() {
        this.setState({
            report: this.props.report ? this.props.report : {},
            level: this.props.level ? this.props.level : {},
            properties: this.props.properties ? this.props.properties : [],
            type:this.props.type ? this.props.type : '',
            channels: this.props.channels ? this.props.channels : [],
        }, () => {
            if(this.state.channels.length > 0){
                this.state.channel = this.state.channels[0];
            }
            this.setState({
                chart: this.state.type === 'quality'?{name: 'Quality score', value: 'historical_quality_score'}:{name: 'click', value: 'click'},
                channel: this.state.channels.length > 0 ? this.state.channel : {}
            }, () => {
                //this.functions.data();
            })
        })
    }

    init = {};

    renders = {
        summary: () => {

        }
    };

    functions = {
        chartData:() =>{

            try{
                let type = this.state.type;

                let label = type;
                if(type == "daily"){
                    label = 'date';
                } else if(type == "region"){
                    label = 'region';
                } else if(type == "hourly"){
                    label = 'hour';
                } else if(type == "quality"){
                    label = 'quality score';
                }

                let labels = {};
                let data = {
                    labels: [],
                    datasets: []
                };
                for(let key in this.state.report){
                    let dataset = {
                        label: key,
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 1
                    };
                    if(key === 'facebook'){

                        if(type == 'daily' || type == "region"){
                            if(type == "region" && key != this.state.channel.value){}
                            else{

                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = true;
                                        data.labels.push(this.state.report[key][this.state.level][type][i][label]);
                                    }
                                    dataset.backgroundColor.push("rgba(58,89,151, 0.7)");
                                    dataset.borderColor.push("rgba(58,89,151, 1)");
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }

                                data.datasets.push(dataset);
                            }
                        } else {
                            let combined = false;
                            try{
                                combined = this.state.report[key].info.combined;
                            }catch (e) {}
                            if(this.state.report[key][this.state.level][type]){
                                if(combined){
                                    dataset.label = 'Facebook and Instagram';
                                }
                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = this.state.report[key][this.state.level][type][i][label];
                                        data.labels.push(labels[this.state.report[key][this.state.level][type][i][label]]);
                                    }
                                    try{
                                        if(combined){
                                            dataset.backgroundColor.push("rgb(102,76,143)");
                                            dataset.borderColor.push("rgba(58,89,151, 1)");
                                        }else{
                                            dataset.backgroundColor.push("rgba(58,89,151, 1)");
                                            dataset.borderColor.push("rgba(58,89,151, 1)");
                                        }
                                    }catch (e) {
                                        dataset.backgroundColor.push("rgba(58,89,151, 1)");
                                        dataset.borderColor.push("rgba(58,89,151, 1)");
                                    }
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }
                            }
                            data.datasets.push(dataset);
                        }
                    }
                    if(key === 'instagram'){

                        if(type == 'daily' || type == "region"){
                            if(type == "region" && key != this.state.channel.value){}
                            else{
                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = true;
                                        data.labels.push(this.state.report[key][this.state.level][type][i][label]);
                                    }
                                    dataset.backgroundColor.push("rgba(221,42,123, 0.7)");
                                    dataset.borderColor.push("rgba(221,42,123, 1)");
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }
                                data.datasets.push(dataset);
                            }
                        } else {
                            let combined = false;
                            try{
                                combined = this.state.report[key].info.combined;
                                //console.log(combined);
                            }catch (e) {}
                            if(this.state.report[key][this.state.level][type] && !combined){
                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = this.state.report[key][this.state.level][type][i][label];
                                        data.labels.push(labels[this.state.report[key][this.state.level][type][i][label]]);
                                    }
                                    dataset.backgroundColor.push("rgba(221,42,123, 1)");
                                    dataset.borderColor.push("rgba(221,42,123, 1)");
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }
                            }
                            data.datasets.push(dataset);
                        }

                    }
                    if(key === 'google'){
                        if(type == 'daily' || type == "region"){
                            if(type == "region" && key != this.state.channel.value){

                            } else {
                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = true;
                                        data.labels.push(this.state.report[key][this.state.level][type][i][label]);
                                    }
                                    dataset.backgroundColor.push("rgba(66,133,244, 0.7)");
                                    dataset.borderColor.push("rgba(66,133,244, 1)");
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }
                                data.datasets.push(dataset);
                            }
                        }else if(type == "quality"){
                            for(let i = 0; i < this.state.report[key][this.state.level].quality.length; i ++){
                                if(!labels[this.state.report[key][this.state.level].quality[i].date]){
                                    labels[this.state.report[key][this.state.level].quality[i].date] = true;
                                    data.labels.push(this.state.report[key][this.state.level].quality[i].date);
                                }
                                dataset.backgroundColor.push("rgba(66,133,244, 0.1)");
                                dataset.borderColor.push("rgba(66,133,244, 1)");
                                dataset.data.push(this.state.report[key][this.state.level].quality[i][this.state.chart.value]);
                            }
                            console.log(dataset);
                            data.datasets.push(dataset);
                        } else {
                            if(this.state.report[key][this.state.level][type]){
                                for(let i = 0; i < this.state.report[key][this.state.level][type].length; i ++){
                                    if(!labels[this.state.report[key][this.state.level][type][i][label]]){
                                        labels[this.state.report[key][this.state.level][type][i][label]] = this.state.report[key][this.state.level][type][i][label];
                                        data.labels.push(labels[this.state.report[key][this.state.level][type][i][label]]);
                                    }
                                    dataset.backgroundColor.push("rgba(66,133,244, 1)");
                                    dataset.borderColor.push("rgba(66,133,244, 1)");
                                    dataset.data.push(this.state.report[key][this.state.level][type][i][this.state.chart.value]);
                                }
                            }
                            data.datasets.push(dataset);
                        }
                    }
                }
                return data;
            }catch (e) {
                let data = {
                    labels: [],
                    datasets: []
                };
                return data;
            }
        },
        setChartProp:(value) =>{
            this.setState({
                chart: value
            })
        },
        setChartChannel:(value) =>{
            this.setState({
                channel: value
            })
        },
        setChartType:(value) =>{
            this.setState({
                chart_type: value
            })
        },
    };

    renders = {
        date: (date) => {
            try {
                return moment(date).format('MMMM D');
            } catch (e) {
            }
        },
        summary: () => {
            let data = [];
            data = this.state.items.map((item) => {
                let object = {};
                object['DATE'] = item.date;

                let click_total = 0;
                for (let channel in item.channels) {
                    let click = 'CLICK - ' + channel.toUpperCase();
                    object[click] = item.channels[channel].click;
                    click_total += item.channels[channel].click;
                }
                object['CLICK TOTAL'] = click_total;

                let impressions_total = 0;
                for (let channel in item.channels) {
                    let impressions = 'IMPRESSIONS - ' + channel.toUpperCase();
                    object[impressions] = item.channels[channel].impressions;
                    impressions_total += item.channels[channel].impressions;
                }
                object['IMPRESSIONS TOTAL'] = impressions_total;

                let spend_total = 0;
                for (let channel in item.channels) {
                    let spend = 'SPEND - ' + channel.toUpperCase();
                    object[spend] = item.channels[channel].spend;
                    spend_total += item.channels[channel].spend;
                }
                object['SPEND TOTAL'] = spend_total;

                return object;
            });

            data.push({'DATE': ''});
            data.push({'DATE': ''});
            data.push({'DATE': 'Summary: '});

            return data;
        },
        name: (item) => {
            if(item === 'cost_pr_conversion'){
                return "cost pr conversion"
            }else{
                return item;
            }
        }
    };

    render() {
        return (
            <section>
                { this.props.headline &&
                    <div className="mb-4 mt-6">
                        <div className="flex flex-row items-center">
                            <h3 className="mb-0 text-2xl">{this.props.headline}</h3>
                        </div>
                    </div>
                }
                <div>
                    <div className="flex flex-row space-x-4">
                        <div className="flex-1">
                            <Dropdown 
                                label={this.state.chart.name ? this.state.chart.name : 'Select property'}
                                listitems={this.state.type === 'quality'?[{name: 'Quality score', value: 'historical_quality_score'}]:this.state.properties.map((item) => {
                                    return {name: this.renders.name(item), value: item}
                                })}
                                callfunc={this.functions.setChartProp}
                            />
                        </div>
                        {this.state.channels && this.state.channels.length > 0 &&
                            <div className="flex-1">
                                <Dropdown 
                                    label={this.state.channel && this.state.channel.name ? this.state.channel.name : 'Select channel'}
                                    listitems={this.state.channels}
                                    callfunc={this.functions.setChartChannel}
                                />
                            </div>
                        }
                        { this.props.showChartType &&
                            <div className="flex-1">
                                <Dropdown 
                                    label={this.state.chart_type && this.state.chart_type.name ? this.state.chart_type.name : 'Select chart type'}
                                    listitems={[{name: 'Bar chart', value: 'bar'}, {name: 'Line chart', value: 'line'}]}
                                    callfunc={this.functions.setChartType}
                                />
                            </div>
                        }
                        {/** 
                        <Dropdown
                            filter={false}
                            title={'Select property'}
                            size={this.state.channels && this.state.channels.length > 0 ? 'half' : 'full'}
                            placeholder={"Select property"}
                            options={this.state.properties.map((item) => {
                                return {name: this.renders.name(item), value: item}
                            })}
                            value={this.state.chart}
                            onChange={(value) => {
                                this.setState({
                                    chart: value
                                })
                            }}
                        />
                        {this.state.channels && this.state.channels.length > 0 &&
                            <Dropdown
                                filter={false}
                                title={'Select channel'}
                                size={'half'}
                                placeholder={"Click here"}
                                options={this.state.channels}
                                value={this.state.channel}
                                onChange={(value) => {
                                    this.setState({
                                        channel: value
                                    })
                                }}
                            />
                        }
                        { this.props.showChartType &&
                            <div className="mt-8">
                                <Dropdown
                                    filter={false}
                                    title={'Select chart'}
                                    size={'full'}
                                    placeholder={"Click here"}
                                    options={[{name: 'Bar chart', value: 'bar'}, {name: 'Line chart', value: 'line'}]}
                                    value={this.state.chart_type}
                                    onChange={(value) => {
                                        this.setState({
                                            chart_type: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        */}
                    </div>
                    { this.state.chart.name && this.state.chart_type.value === 'bar' &&
                        <div className="Report-ChartInner mb-5 mt-5">
                            <Bar
                                options={this.state.line}
                                data={this.functions.chartData()}
                            />
                        </div>
                    }
                    {
                        this.state.chart.name && this.state.chart_type.value === 'line' &&
                        <div className="Report-ChartInner mb-5 mt-5">
                            <Line
                                options={this.state.line}
                                data={this.functions.chartData()}
                            />
                        </div>
                    }
                </div>
            </section>
        )
    }
}

export default ReportsChart;