import React, { Component } from 'react';
import InputTailwind from './inputTailwind';

const iconNames = [
    "charts/bar-chart-arrow",
    "charts/bar-chart-diversified-double-vertical",
    "charts/bar-chart-diversified-double",
    "charts/bar-chart-diversified-vertical",
    "charts/bar-chart",
    "charts/common-part-chart",
    "charts/decrease",
    "charts/graph-line-chart",
    "charts/growth",
    "charts/pie-chart-multi",
    "charts/pie-chart",
    "charts/process-flow-game-plan",
    "charts/project-management",
    "charts/radial-bar-chart",
    "charts/radial-histogram",
    "charts/scatter-chart",
    "charts/sitemap",
    "charts/stacked-organization",
    "charts/web-analytics",
    "communication/alarms-notification",
    "communication/chat-message-approved",
    "communication/chat-message-cross",
    "communication/chat-message-heart",
    "communication/chat-message-minus",
    "communication/chat-message-note",
    "communication/chat-message-play",
    "communication/chat-message-plus",
    "communication/chat-message-user",
    "communication/chat-message",
    "communication/chat",
    "communication/complaint",
    "communication/computer-chat",
    "communication/consultation",
    "communication/earphone-telephone",
    "communication/email-book",
    "communication/email",
    "communication/emergency",
    "communication/envelope-arrow-down",
    "communication/envelope-arrow-left",
    "communication/envelope-arrow-right",
    "communication/envelope-arrow-up",
    "communication/envelope-mail-approved",
    "communication/envelope-mail-cross",
    "communication/envelope-mail-minus",
    "communication/envelope-mail-plus",
    "communication/envelope-mail-trash",
    "communication/envelope-mail",
    "communication/envelope-send",
    "communication/exclamation-mark-bubble",
    "communication/faq-frequent-ask-questions",
    "communication/feedback",
    "communication/female-customer-service",
    "communication/foreign-language",
    "communication/hiring",
    "communication/mailbox",
    "communication/male-customer-service",
    "communication/marine-radio",
    "communication/military-radio",
    "communication/news-newspaper",
    "communication/no-bs",
    "communication/paperplane-send",
    "communication/person-talking",
    "communication/phone-book-contacts",
    "communication/question-bubble",
    "communication/radio-nanny",
    "communication/radio-walkie-talkie",
    "communication/request-support-service",
    "communication/social-media-marketing",
    "communication/two-envelopes",
    "communication/video-conference",
    "design/3-d-front-view",
    "design/3-d-top-view",
    "design/3-d",
    "design/300-dpi-resolution",
    "design/360-view",
    "design/agumented-reality",
    "design/align-objects-bottom",
    "design/align-objects-top",
    "design/align-text-center",
    "design/align-text-justify",
    "design/align-text-left",
    "design/align-text-right",
    "design/animation-effects",
    "design/black-and-white",
    "design/blog-writing-machine",
    "design/blur",
    "design/border-bottom",
    "design/border-horizontal",
    "design/border-left",
    "design/border-right",
    "design/border-top",
    "design/border-vertical",
    "design/box",
    "design/brightness-settings",
    "design/brightness",
    "design/bring-forward",
    "design/bring-to-front",
    "design/brush",
    "design/canvas-business-model",
    "design/circle",
    "design/cmyk",
    "design/compare",
    "design/compass",
    "design/contrast-settings",
    "design/contrast",
    "design/copy",
    "design/copyright",
    "design/crop",
    "design/cube-3-d-object",
    "design/drag-order-list",
    "design/drawing-tablet",
    "design/edit",
    "design/elipse",
    "design/erase",
    "design/example",
    "design/fibonacci",
    "design/fill-color",
    "design/fold",
    "design/footer",
    "design/grid-list",
    "design/hexagon",
    "design/index",
    "design/invert-selection",
    "design/irregular-quadrilateral-shape",
    "design/layers",
    "design/mesh",
    "design/metaverse",
    "design/page-orientation",
    "design/page-size",
    "design/page-view-array",
    "design/page-view-carousel",
    "design/page-view-column",
    "design/page-view-headline",
    "design/page-view-module",
    "design/page-view-quilt",
    "design/page-view-stream",
    "design/pages-add",
    "design/pentagon",
    "design/photo-editor",
    "design/polygon",
    "design/polyline",
    "design/quotation-mark-first",
    "design/quotation-mark-second",
    "design/rectangle",
    "design/registered",
    "design/reorder",
    "design/resize-file",
    "design/rgb-colors",
    "design/rhombus",
    "design/rounded-square",
    "design/rulers",
    "design/send-backward",
    "design/send-to-back",
    "design/sphere",
    "design/square-footage",
    "design/square",
    "design/take-screenshot",
    "design/text-box",
    "design/trademark",
    "design/triangle",
    "design/vector-design",
    "electronics/alert",
    "electronics/alt-key",
    "electronics/arrow-down-key",
    "electronics/arrow-left-key",
    "electronics/arrow-right-key",
    "electronics/arrow-up-key",
    "electronics/artificial-intelligence-ai-alt",
    "electronics/battery-charger-horizontal",
    "electronics/battery-charger-vertical",
    "electronics/battery-cross-horizontal",
    "electronics/battery-cross-vertical",
    "electronics/battery-horizontal",
    "electronics/battery-sides-horizontal",
    "electronics/battery-sides-vertical",
    "electronics/battery-warning-horizontal",
    "electronics/battery-warning-vertical",
    "electronics/battery",
    "electronics/bluetooth",
    "electronics/broadcast-tower",
    "electronics/cable-network",
    "electronics/change-tv-screen-widescreen",
    "electronics/command-key",
    "electronics/computer-display",
    "electronics/computer-mouse-left-click",
    "electronics/computer-mouse-mouse-scrolling",
    "electronics/computer-mouse-right-click",
    "electronics/computer-mouse",
    "electronics/computer-speakers",
    "electronics/control-ctrl-key",
    "electronics/data",
    "electronics/disconnect-network-offline",
    "electronics/ebook-reader",
    "electronics/eight-8-key",
    "electronics/electric-power",
    "electronics/enter-key",
    "electronics/esc-key",
    "electronics/five-5-key",
    "electronics/four-4-key",
    "electronics/function-fn-key",
    "electronics/hand-metal-detector",
    "electronics/keyboard",
    "electronics/knob-twist",
    "electronics/laptop-notebook",
    "electronics/load-balancer",
    "electronics/micro-sd-card",
    "electronics/nas-server",
    "electronics/nine-9-key",
    "electronics/notebook-laptop-ok-approved",
    "electronics/old-tv",
    "electronics/one-1-key",
    "electronics/pendrive-storage",
    "electronics/plug-in-electricity",
    "electronics/powerbank",
    "electronics/printer",
    "electronics/projector",
    "electronics/proximity-sensor",
    "electronics/responsive",
    "electronics/robot",
    "electronics/save",
    "electronics/scanner-image",
    "electronics/sd-card",
    "electronics/server-connection",
    "electronics/server",
    "electronics/servers",
    "electronics/seven-7-key",
    "electronics/shift-key",
    "electronics/shredder",
    "electronics/signal-streams",
    "electronics/six-6-key",
    "electronics/smart-tv-layout-interface",
    "electronics/smartphone-ring",
    "electronics/smartphone-rotate",
    "electronics/switch-off-on",
    "electronics/tab-key",
    "electronics/technology-integrated-circuits",
    "electronics/three-3-key",
    "electronics/tv-turn-on",
    "electronics/tv",
    "electronics/two-2-key",
    "electronics/usb-cable",
    "electronics/usb-connector",
    "electronics/video-card-computer",
    "electronics/vr-glasses-gogle",
    "electronics/web-camera",
    "electronics/wifi-connection",
    "electronics/wifi",
    "electronics/wire",
    "electronics/wireless-charging",
    "electronics/wireless-connection",
    "electronics/zero-0-key",
    "emoji/emoji-angry-wrr",
    "emoji/emoji-cry",
    "emoji/emoji-lol",
    "emoji/emoji-sad",
    "emoji/emoji-smile",
    "emoji/emoji-wow",
    "emoji/thinking-skeptic-person-avatar",
    "finance/accounting",
    "finance/atm",
    "finance/bank-branch",
    "finance/bank",
    "finance/banknote",
    "finance/bonds",
    "finance/calculator",
    "finance/card-exchange",
    "finance/card-security-code",
    "finance/card-verification-value",
    "finance/coin-btc",
    "finance/coin-cny",
    "finance/coin-dollar",
    "finance/coin-eth",
    "finance/coin-euro",
    "finance/coin-polish-zloty",
    "finance/coin-rub",
    "finance/coin",
    "finance/coins-btc",
    "finance/coins-cny",
    "finance/coins-dollar",
    "finance/coins-eth",
    "finance/coins-euro",
    "finance/coins-pln",
    "finance/coins-rub",
    "finance/coins",
    "finance/commodity",
    "finance/court",
    "finance/credit-card",
    "finance/crowdfunding",
    "finance/department",
    "finance/diamond-luxury-precious",
    "finance/dividends",
    "finance/donate-sign",
    "finance/equity-security",
    "finance/financial-project",
    "finance/graph-candles-stock",
    "finance/investment",
    "finance/invoice-euro",
    "finance/invoice-gbp",
    "finance/invoice-general",
    "finance/invoice-receipt-euro",
    "finance/invoice-receipt-gbp",
    "finance/invoice-receipt-general",
    "finance/invoice-receipt-validating-ticket",
    "finance/invoice",
    "finance/loan",
    "finance/money-bag-btc",
    "finance/money-bag-cny",
    "finance/money-bag-dollar",
    "finance/money-bag-eth",
    "finance/money-bag-euro",
    "finance/money-bag-pln",
    "finance/money-bag-rub",
    "finance/money-bag",
    "finance/money-check",
    "finance/money-safety",
    "finance/mortgage-loan",
    "finance/project-estimate",
    "finance/recurring-cash",
    "finance/refund",
    "finance/rent",
    "finance/savings-pig",
    "finance/share-network",
    "finance/stock-share",
    "finance/tapping-credit-card",
    "finance/treasure-chest",
    "finance/wallet-purse",
    "finance/withdrawal",
    "game/apple-arcade-joistick",
    "game/arcade-cabinet",
    "game/billiards-alt",
    "game/billiards",
    "game/boomerang",
    "game/bow-arrow-toy",
    "game/bowling",
    "game/bricks-toy",
    "game/candy-machine-lottery",
    "game/card-clubs",
    "game/card-diamonds",
    "game/card-heart",
    "game/card-joker",
    "game/card-spades",
    "game/cards",
    "game/casino-gambling-chip",
    "game/chess-bishop",
    "game/chess-clock",
    "game/chess-king",
    "game/chess-knight",
    "game/chess-pawn",
    "game/chess-queen",
    "game/chess-rook",
    "game/chess-strategy",
    "game/chessboard",
    "game/controller-pad-game-play",
    "game/counter",
    "game/dice-cube",
    "game/footman",
    "game/goal-target",
    "game/joistick-alt",
    "game/juggling",
    "game/mage-staff",
    "game/magic-ball",
    "game/magician-rabbit-hat",
    "game/mana",
    "game/morale",
    "game/paintball-gun",
    "game/puzzle",
    "game/ring-toss",
    "game/roulette",
    "game/scoreboard",
    "game/slingshot",
    "game/slot-machine",
    "game/small-cute-monster",
    "game/sword",
    "game/win",
    "industry/air-quality",
    "industry/alternative-fuels",
    "industry/bio-mass",
    "industry/biofuels",
    "industry/biomass-alt",
    "industry/biomass",
    "industry/bulker-ship",
    "industry/burning-fuel-flame",
    "industry/carbon-neutral",
    "industry/carbon-tax-co-2",
    "industry/cargo-ship",
    "industry/coal",
    "industry/dam-barrier",
    "industry/decarbonization",
    "industry/dynamo",
    "industry/e-fuels",
    "industry/energy-sources",
    "industry/factory",
    "industry/foundry",
    "industry/freight",
    "industry/fuse",
    "industry/ground-symbol",
    "industry/led-diode",
    "industry/mine-cart",
    "industry/oil-pump",
    "industry/oil-storage-tank",
    "industry/poison-bottle",
    "industry/poison",
    "industry/recycling",
    "industry/service",
    "industry/shipping-container",
    "industry/solar-panel",
    "industry/sustainable-energy",
    "industry/tanker-ship",
    "industry/transmission-tower",
    "industry/water-wheel",
    "industry/wind-turbines",
    "industry/windmill",
    "industry/world-care",
    "interface/approve-checked-simple",
    "interface/approved-checked",
    "interface/ask-question",
    "interface/attachment",
    "interface/battery-levels-horizontal-alt",
    "interface/battery-levels-horizontal",
    "interface/battery-levels-vertical-alt",
    "interface/battery-levels-vertical",
    "interface/bell-notification",
    "interface/cloud-avatar-in-out",
    "interface/cloud-download",
    "interface/cloud-error",
    "interface/cloud-minus",
    "interface/cloud-plus",
    "interface/cloud-refresh",
    "interface/cloud-settings",
    "interface/cloud-upload",
    "interface/cog",
    "interface/cogs",
    "interface/double-play-back-2-smooth",
    "interface/double-play-back-smooth",
    "interface/double-play-forward-2-smooth",
    "interface/double-play-forward-smooth",
    "interface/double-play-next-smooth",
    "interface/double-play-previous-smooth",
    "interface/download-1",
    "interface/download-2",
    "interface/draw-circle",
    "interface/error-cross-simple",
    "interface/error-cross",
    "interface/folder-arrow-down",
    "interface/folder-arrow-up",
    "interface/folder-camera",
    "interface/folder-cloud",
    "interface/folder-lock",
    "interface/folder-play",
    "interface/folder",
    "interface/funnel-tools-utensils",
    "interface/globe",
    "interface/hand-with-phone",
    "interface/interface-settings",
    "interface/interface-slider-music",
    "interface/learn-more-text",
    "interface/learn-more",
    "interface/like-thumb-up",
    "interface/link-unlink",
    "interface/login",
    "interface/love-heart",
    "interface/magnifier-minus",
    "interface/magnifier-plus",
    "interface/magnifier-zoom-search",
    "interface/minus-circle",
    "interface/minus-rotation",
    "interface/navigate-three-fingers",
    "interface/pin-pad",
    "interface/play-pause-circle",
    "interface/play-pause",
    "interface/plus-circle",
    "interface/plus-to-square-rotation",
    "interface/record-button",
    "interface/reset-text",
    "interface/restore-password",
    "interface/rules",
    "interface/scan-qr-code",
    "interface/settings-sliders",
    "interface/shake-phone",
    "interface/share ",
    "interface/share-arrow 2",
    "interface/share-arrow",
    "interface/share",
    "interface/star-rating",
    "interface/street-view",
    "interface/submit-text",
    "interface/subscribe-text",
    "interface/swipe-left-right",
    "interface/swipe-left",
    "interface/swipe-right",
    "interface/tap-hold",
    "interface/tap-once",
    "interface/tap-three-fingers",
    "interface/tap-twice",
    "interface/tap-two-fingers-once",
    "interface/tap-two-fingers-twice",
    "interface/thumb-down",
    "interface/toggle-radio-button-1",
    "interface/toggle-radio-button-2",
    "interface/touch-pad",
    "interface/upload-1",
    "interface/upload-2",
    "interface/zoom-out-two-fingers",
    "people/avatar-female-approved",
    "people/avatar-female-cross",
    "people/avatar-female-minus",
    "people/avatar-female-plus",
    "people/avatar-female-search",
    "people/avatar-female-trash",
    "people/avatar-female",
    "people/avatar-icon-approved",
    "people/avatar-icon-calm-plus",
    "people/avatar-icon-cross",
    "people/avatar-icon-minus",
    "people/avatar-icon-search",
    "people/avatar-icon-trash",
    "people/avatar-man-approved",
    "people/avatar-man-cross",
    "people/avatar-man-minus",
    "people/avatar-man-nodding",
    "people/avatar-man-plus",
    "people/avatar-man-search",
    "people/avatar-man-trash",
    "people/avatar-man",
    "people/avatar-user-in-circle",
    "people/avatar-woman-nodding",
    "people/avatar",
    "people/beard-barber",
    "people/blinking-eye-with-eyelashes",
    "people/brain",
    "people/couple-man-woman",
    "people/couple-mans",
    "people/couple-womans",
    "people/delicious-hand",
    "people/digestion",
    "people/disabled-person",
    "people/elderly-old-couple-grand-parents",
    "people/eye",
    "people/falling-person",
    "people/family",
    "people/female-and-male",
    "people/female-and-two-males",
    "people/female-standing",
    "people/fetus",
    "people/goth-music-character",
    "people/handshake-deal",
    "people/heart",
    "people/horizontal-brain",
    "people/hugging-love",
    "people/keep-distance",
    "people/kidney",
    "people/lips",
    "people/liver",
    "people/love-care",
    "people/lungs",
    "people/male-and-two-female",
    "people/male-female",
    "people/male-standing",
    "people/man-style-1",
    "people/man-style-2",
    "people/man-style-3",
    "people/man-style-4",
    "people/man-style-5",
    "people/man-style-6",
    "people/man-style-7",
    "people/man-style-8",
    "people/membership-vip-card",
    "people/middle-finger",
    "people/muscle-arm",
    "people/mustache-beard",
    "people/nails",
    "people/old-person-walking-walker",
    "people/ovaries",
    "people/peace-sign",
    "people/people-exchange-arrows",
    "people/pinecone-italian-hand",
    "people/pray-hands",
    "people/reggae-music",
    "people/rised-hand",
    "people/rock-hand",
    "people/signature-sig",
    "people/skeleton",
    "people/sleep-zzz",
    "people/sleeping-in-bed-sleepy",
    "people/smiling-mouth",
    "people/stomach",
    "people/telephone-call-hand",
    "people/three-avatars-icon-calm",
    "people/three-female-avatars",
    "people/three-male-avatars",
    "people/trash-disposal",
    "people/two-avatar-icon-calm",
    "people/two-female-avatars",
    "people/two-male-avatars",
    "people/vip",
    "people/walking-walkcycle-person",
    "people/walking-with-dog",
    "people/wave-hello-hand",
    "people/wc-toilet-rest-room-people",
    "people/woman-style-10",
    "people/woman-style-11",
    "people/woman-style-12",
    "people/woman-style-13",
    "people/woman-style-14",
    "people/woman-style-15",
    "people/woman-style-16",
    "people/woman-style-17",
    "people/woman-style-18",
    "people/woman-style-9",
    "photo/camera",
    "photo/clipboard-film-clap",
    "photo/countdown",
    "photo/director-chair",
    "photo/drone-alt",
    "photo/drone",
    "photo/explicit",
    "photo/film-tape",
    "photo/film",
    "photo/home-camera",
    "photo/movie-camera",
    "photo/photo",
    "photo/polaroid-camera",
    "photo/remote-control",
    "photo/shutter-photo-picture",
    "photo/spotlight",
    "photo/vlog-camera",
    "photo/web-camera",
    "programming/3-d",
    "programming/api-symbol",
    "programming/artificial-intelligence-ai-alt",
    "programming/artificial-intelligence-ai",
    "programming/bar-code-scan",
    "programming/bug-pest",
    "programming/c-code-language",
    "programming/closed-captions",
    "programming/code-fork",
    "programming/code",
    "programming/command-window-line",
    "programming/data",
    "programming/dns-domain",
    "programming/email",
    "programming/fail",
    "programming/form-sheet-questionnaire",
    "programming/hacking",
    "programming/html-5-code-language",
    "programming/hub-network",
    "programming/internet",
    "programming/it-developer",
    "programming/java-code-language",
    "programming/json",
    "programming/metaverse",
    "programming/multichannel",
    "programming/nft",
    "programming/not-applicable",
    "programming/phishing",
    "programming/php-code-language",
    "programming/programming-book",
    "programming/python-code-language",
    "programming/qr-code",
    "programming/remove-ads",
    "programming/repository",
    "programming/rest-api",
    "programming/ruby-code-language",
    "programming/share-network",
    "programming/social-media-marketing",
    "programming/ssl-security",
    "programming/web-advertising",
    "programming/web-code",
    "programming/web-design",
    "programming/web-development-brackets",
    "security/arrest",
    "security/border-customs",
    "security/bribery",
    "security/city-camera",
    "security/emergency-exit",
    "security/error",
    "security/exit-sign",
    "security/face-id",
    "security/fingerprint-security",
    "security/fire-alarm",
    "security/fire-exit",
    "security/fire-extinguisher",
    "security/fireman",
    "security/fraud",
    "security/gang",
    "security/gdpr-data",
    "security/gdpr",
    "security/handcuffs",
    "security/jail",
    "security/key",
    "security/lawyer",
    "security/lock-unlock",
    "security/lost-and-found",
    "security/medical-insurance",
    "security/murder-chalk",
    "security/murder",
    "security/police-badge",
    "security/police-car",
    "security/policeman",
    "security/prison",
    "security/prisoner",
    "security/privacy",
    "security/rape",
    "security/rescue-safety-ring-help",
    "security/restriction",
    "security/robber",
    "security/scratch-resistant",
    "security/shield-security",
    "security/slippery-floor",
    "security/smoke-detector",
    "security/ssl-security",
    "security/tied-hands",
    "shoping/assembling-delivery",
    "shoping/attract-customers",
    "shoping/bar-code-scan",
    "shoping/barbershop",
    "shoping/bargain-off-promotion-percentage",
    "shoping/basket-vegetables",
    "shoping/basket",
    "shoping/best-seller",
    "shoping/billboard",
    "shoping/box",
    "shoping/box1",
    "shoping/box2",
    "shoping/bubble-wrap",
    "shoping/cafe-shop",
    "shoping/cargo-owner",
    "shoping/cargo-secure",
    "shoping/cash-register-messenger",
    "shoping/closed-open",
    "shoping/complaint",
    "shoping/coupon",
    "shoping/customer-insights",
    "shoping/delivery",
    "shoping/demand",
    "shoping/discount-finder",
    "shoping/engage-users",
    "shoping/error",
    "shoping/example",
    "shoping/fast-food-drive-thru",
    "shoping/franchise",
    "shoping/free-",
    "shoping/gift-card",
    "shoping/grocery-shelf",
    "shoping/ground-land-sale",
    "shoping/guarantee",
    "shoping/line-queue",
    "shoping/load-cargo",
    "shoping/loyalty-card",
    "shoping/marketing-campaign",
    "shoping/membership-vip-card",
    "shoping/non-stoph",
    "shoping/old-school-shopping-mall",
    "shoping/old-shop",
    "shoping/omnichannel",
    "shoping/one-free",
    "shoping/online-shopping",
    "shoping/order-history",
    "shoping/package-delivery-carry",
    "shoping/package",
    "shoping/paper-bag-vegetables",
    "shoping/person-dolly",
    "shoping/pharmacy",
    "shoping/post-ads-advertisment",
    "shoping/price-tag-bitcoin",
    "shoping/price-tag-dollar",
    "shoping/price-tag-empty",
    "shoping/price-tag-ethereum",
    "shoping/price-tag-euro",
    "shoping/price-tag-sale",
    "shoping/prize-padge-ribbon",
    "shoping/purchase-order",
    "shoping/qr-code",
    "shoping/review",
    "shoping/sale-10",
    "shoping/sale-20",
    "shoping/sale-30",
    "shoping/sale-40",
    "shoping/sale-50",
    "shoping/sale-60",
    "shoping/sale-70",
    "shoping/sale",
    "shoping/scan-qr-code",
    "shoping/scanning-nfc-tag",
    "shoping/scratch-resistant",
    "shoping/shop-scanner",
    "shoping/shop",
    "shoping/shopping-mall",
    "shoping/teaser",
    "shoping/this-way-up",
    "shoping/ticket-coupon",
    "shoping/track-order",
    "shoping/trolley 2",
    "shoping/trolley-arrow-down",
    "shoping/trolley-arrow-up",
    "shoping/trolley-cross",
    "shoping/trolley-minus",
    "shoping/trolley-plus",
    "shoping/trolley",
    "shoping/unload-cargo",
    "shoping/vip",
    "shoping/web-advertising",
    "shoping/wooden-box",
    "time/alarm-clock",
    "time/appointment-schedule",
    "time/calendar-subscription",
    "time/calendar",
    "time/clock-time-reverse",
    "time/clock-time",
    "time/hourglass",
    "time/smart-watch",
    "time/speed",
    "time/timer-stopwatch",
    "time/timezone-global",
    "time/wasting-time",
    "time/watch"
]

class IconPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            show_icons: [],
            icons: {}
        }
    }

    componentDidMount() {
        this.setState({
            show_icons: iconNames.slice(0, 30),
        }, () => {
            this.state.show_icons.forEach(this.loadIcon);
        });

        if (this.props.filter) {
            this.handleFilterChange(this.props.filter);
        }
    }

    loadIcon = async (iconName) => {
        try {
            const icon = await import(`../assets/icons/${iconName}.svg`);
            this.setState(prevState => ({
                icons: {
                    ...prevState.icons,
                    [iconName]: icon.default
                }
            }));
        } catch (error) {
            console.error(`Error loading icon ${iconName}:`, error);
        }
    };

    handleFilterChange = (value) => {
        const filteredIcons = iconNames.filter(iconName =>
            iconName.toLowerCase().includes(value.toLowerCase())
        );

        this.setState({
            filter: value,
            show_icons: filteredIcons.slice(0, 30),
        }, () => {
            this.state.show_icons.forEach(this.loadIcon);
        });
    };

    render() {
        return (
            <div className="p-5">
                <InputTailwind
                    label={"Filter"}
                    value={this.state.filter}
                    onChange={this.handleFilterChange}
                />

                    {this.state.show_icons.length > 0 ? (
                        <div className="grid grid-cols-10 pt-5">
                            {
                                this.state.show_icons.map(iconName => (
                                    <div
                                        className="p-1 cursor-pointer"
                                        key={iconName}
                                        onClick={() => {
                                            if (this.props.onSelect) {
                                                this.props.onSelect({name: iconName, path: this.state.icons[iconName]});
                                            }
                                        }}
                                    >
                                        {this.state.icons[iconName] ? (
                                            <img src={this.state.icons[iconName]} className="w-16 h-16"/>
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                ))
                            }
                        </div>
                    ) : (
                        <div className="pt-5">
                            <p>No icons found.</p>
                        </div>
                    )}
            </div>
        )
    }
}

export default IconPicker;
