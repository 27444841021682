class apiRegister {

    static url = {
        api: window.location.hostname === 'localhost' ?
            "http://localhost:8000/api" :
            (window.location.hostname === 'app.adcredo.io' ?
                "https://api.adcredo.io/api" :
                (window.location.hostname === 'docker-test.adcredo.io' ?
                    'https://api-docker-test.adcredo.io/api' :
                    "https://apidev.adcredo.io/api"
                )
            ),
    };

    static call(options, url) {
        return new Promise(function (resolve, reject) {
            fetch(url, options).then((response) => {
                //console.log(response);
                if (response.status >= 200 && response.status < 300) {
                    return response;
                }
                return response.json().then(json => {
                    return Promise.reject({
                        status: response.status,
                        ok: false,
                        statusText: response.statusText,
                        body: json
                    });
                });
            })
                .then((response) => {
                    //console.log(response);
                    if (response.status === 204 || response.status === 205) {
                        return null;
                    }
                    resolve(response.json());
                })
                .catch((error) => {
                    //console.log(error);
                    if (!error.body) {
                        reject({ body: { message: 'Something went wrong' } });
                    } else {
                        reject(error);
                    }
                });
        });
    }

    static options(token, type, data, multiform) {
        let options = {
            method: type,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (token) {
            options.headers['x-auth-token'] = token;
        }
        if (data) {
            options.body = JSON.stringify(data);
        }
        if (multiform) {
            options.body = data;
            delete options.headers['Content-Type'];
        }
        return options;
    }

}

export { apiRegister }
