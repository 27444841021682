import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Input from "../input";
import Switch from "react-ios-switch";
import {SlideDown} from "react-slidedown";
import Modal from "../../modules/modal";

class AdvertisingCreativesSitelinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {},
            campaign: {},
            search: {},
            site: {},
            name: "",
            website: "",
            description: "",
            description2: "",
            new: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            search: nextProps.search,
            campaign: nextProps.campaign,
            site: nextProps.site
        }, () => {
            if(this.props.site){
                this.setState({
                    website: this.props.site.url
                })
            }
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            search: this.props.search,
            campaign: this.props.campaign,
            site: this.props.site
        }, () => {
            if(this.props.site){
                this.setState({
                    website: this.props.site.url
                })
            }
        })
    }

    init = {};

    functions = {
        add: () => {
            if(!this.state.search.site_links){
                this.state.search.site_links = [];
            }
            this.setState({
                name_error: !this.state.name || this.state.name === "",
                website_error: !this.state.website || this.state.website === "",
                description_error: (this.state.description.length > 0 && this.state.description2.length < 1) || (this.state.description2.length > 0 && this.state.description.length < 1)
            }, () => {
                if(!this.state.name_error && !this.state.website_error && !this.state.description_error && !this.state.description_error){
                    let id = Math.floor((Math.random() * 9999999999) + 1);
                    let site_link = {
                        campaign_id: this.state.campaign.id,
                        name: this.state.name,
                        url: this.state.website,
                        description: this.state.description,
                        description2: this.state.description2,
                        id: id
                    };
                    this.state.search.site_links.unshift(site_link);
                    this.setState({
                        new: false,
                        website: this.state.site.url,
                        description: "",
                        description2: "",
                        name: ""
                    }, () => {
                        this.props.onChange(this.state.search);
                    });
                }
            })
        },
        clone: (item) => {
            let new_item = JSON.parse(JSON.stringify(item));
            new_item.id = Math.floor((Math.random() * 9999999999) + 1);
            this.state.search.site_links.unshift(new_item);
            this.props.onChange(this.state.search);
        },
        remove: (item) => {
            try{
                this.state.search.site_links = this.state.search.site_links.filter((inner_item) => {
                    return inner_item.id !== item.id
                });
            }catch (e) {}
            this.setState({
                description: "",
                description2: "",
                website: this.state.site.url,
                name: "",
                edit_id: null,
                new: false,
                search: this.state.search
            }, () => {
                this.props.onRemove(this.state.search, item);
            });
        }
    };

    validators = {};

    renders = {
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div className="">
                {
                    <div className="bg-white rounded-md">
                        <div className="px-4 py-4 flex-row flex">
                            <div className="flex flex-1 flex-col">
                                <h3 className="mb-0 font-bold">
                                    Site links extension
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Avg. number of search per month in parantheses
                                </p>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-row">
                                    <div onClick={() => {
                                        this.setState({
                                            new: true
                                        })
                                    }} className="bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer">
                                        <div className={"close h-10 w-10 flex items-center justify-center text-2xl rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                            +
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Array.isArray(this.state.search.site_links) && this.state.search.site_links.filter((item) => {
                            return item.campaign_id === this.state.campaign.id
                        }).length > 0 &&
                            <div>
                                <div className="w-full border-t"/>
                                {
                                    Array.isArray(this.state.search.site_links) && this.state.search.site_links.filter((item) => {
                                        return item.campaign_id === this.state.campaign.id
                                    }).length > 0 &&
                                    <div className="p-4">
                                        {
                                            this.state.search.site_links.filter((item) => {
                                                return item.campaign_id === this.state.campaign.id
                                            }).map((item, index) => {
                                                return (
                                                    <div style={{backgroundColor: "#fcfcfc"}} className={(index === 0 ? "mt-0" : "mt-4") + " flex flex-col flex-1 bg-white px-4 py-3 transition py-1 rounded-md border"}>
                                                        <div className="flex flex-1 flex-col">
                                                            <div className="text-sm font-bold">{item.name}{" "}
                                                                <span
                                                                    className={cx("ml-2 inline-flex text-xs font-bold rounded-full",
                                                                        {
                                                                            ["text-gray-500"]: true,
                                                                        })
                                                                    }>
                                                    {item.url}
                                                </span>
                                                            </div>
                                                            {
                                                                item.description !== "" &&
                                                                <div className="text-sm font-medium">{item.description}</div>
                                                            }
                                                            {
                                                                item.description2 !== "" &&
                                                                <div className="text-sm font-medium">{item.description2}</div>
                                                            }
                                                        </div>
                                                        {
                                                            (!this.state.edit_id || this.state.edit_id === item.id) &&
                                                            <div className="mt-8 flex flex-row">
                                                                <div className="flex flex-1 justify-start flex-row">
                                                                    {
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                name: item.name,
                                                                                website: item.url,
                                                                                description: item.description,
                                                                                description2: item.description2,
                                                                                edit_id: item.id,
                                                                            })
                                                                        }} className="bg-gray-200 mr-2 text-gray-500 flex flex-row items-center flex justify-center items-center px-3 py-2 rounded-full font-bold text-xs transition-all duration-200 hover:bg-gray-500 hover:bg-opacity-100 hover:text-white">
                                                                            <FeatherIcon className="stroke-current mr-2" size={15} icon="edit"/>
                                                                            <span>Edit extension</span>
                                                                        </button>
                                                                    }
                                                                    {
                                                                        <button onClick={() => {
                                                                            this.functions.clone(item);
                                                                        }} className="bg-blue-200 mr-2 text-blue-500 flex flex-row items-center flex justify-center items-center px-3 py-2 rounded-full font-bold text-xs transition-all duration-200 hover:bg-blue-500 hover:bg-opacity-100 hover:text-white">
                                                                            <FeatherIcon className="stroke-current mr-2" size={15} icon="copy"/>
                                                                            <span>Clone extension</span>
                                                                        </button>
                                                                    }
                                                                    <div className="flex flex-1"/>
                                                                    {
                                                                        <button onClick={() => {
                                                                            this.functions.remove(item)
                                                                        }} className="bg-gred-200 text-red-500 flex flex-row items-center flex justify-center items-center px-3 py-2 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                            <FeatherIcon className="stroke-current mr-2" size={15} icon="trash"/>
                                                                            <span>Remove extension</span>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {
                    (this.state.new || this.state.edit_id) &&
                    <Modal
                        title={this.state.new?"New site link extension":"Edit site link extension"}
                        subtitle={"You have selected 1 template"}
                        blueButton={true}
                        blueButtonText={this.state.new?"Add extension":"Update extension"}
                        onBlueButtonClick={() => {
                            if(this.state.edit_id && Array.isArray(this.state.search.site_links)){
                                this.setState({
                                    name_error: !this.state.name || this.state.name === "",
                                    website_error: !this.state.website || this.state.website === "",
                                    description_error: (this.state.description.length > 0 && this.state.description2.length < 1) || (this.state.description2.length > 0 && this.state.description.length < 1)
                                }, () => {
                                    if(!this.state.name_error && !this.state.website_error && !this.state.description_error && !this.state.description_error){
                                        this.state.search.site_links.map((item) => {
                                            if(item.id === this.state.edit_id){
                                                item.name = this.state.name;
                                                item.url = this.state.website;
                                                item.description = this.state.description;
                                                item.description2 = this.state.description2;
                                            }
                                        });
                                        this.setState({
                                            search: this.state.search,
                                            description: "",
                                            description2: "",
                                            website: this.state.site.url,
                                            name: "",
                                            edit_id: null,
                                            new: false
                                        }, () => {
                                            this.props.onChange(this.state.search);
                                        })
                                    }
                                })
                            }else{
                                this.functions.add();
                            }
                        }}
                        onClose={() => {
                            this.setState({
                                new: false,
                                description: "",
                                description2: "",
                                website: this.state.site.url,
                                name: "",
                                edit_id: null
                            })
                        }}
                    >
                        <div className="flex flex-1 flex-col pb-4">
                            <div className="flex flex-1 flex-row">
                                <div className="flex flex-1 pr-1">
                                    <Input
                                        noTitle={false}
                                        title={"Title"}
                                        maxLength={25}
                                        white={false}
                                        value={this.state.name}
                                        placeholder={"Max 25 characters"}
                                        sign={true}
                                        error={this.state.name_error && (!this.state.name || this.state.name === "")}
                                        signTitle={this.renders.characters(this.state.name) +' / 25'}
                                        size={'full'}
                                        type={"text"}
                                        onChange={(value) => {
                                            this.setState({
                                                name: value.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-1 pl-1">
                                    <Input
                                        noTitle={false}
                                        title={"Url"}
                                        white={false}
                                        value={this.state.website}
                                        placeholder={"Url"}
                                        error={this.state.website_error && (!this.state.website || this.state.website === "")}
                                        size={'full'}
                                        type={"text"}
                                        onChange={(value) => {
                                            this.setState({
                                                website: value.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-1 flex-row mt-4">
                                <div className="flex flex-1 pr-1">
                                    <Input
                                        noTitle={false}
                                        title={"Description 1 (optional)"}
                                        value={this.state.description}
                                        placeholder={"Max 35 characters"}
                                        sign={true}
                                        signTitle={this.renders.characters(this.state.description) +' / 35'}
                                        maxLength={35}
                                        error={this.state.description_error && (this.state.description.length < 1 && this.state.description2.length > 0)}
                                        size={'full'}
                                        type={"text"}
                                        onChange={(value) => {
                                            this.setState({
                                                description: value.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-1 pl-1">
                                    <Input
                                        noTitle={false}
                                        title={"Description 2 (optional)"}
                                        value={this.state.description2}
                                        placeholder={"Max 35 characters"}
                                        sign={true}
                                        signTitle={this.renders.characters(this.state.description2) +' / 35'}
                                        maxLength={35}
                                        error={this.state.description_error && (this.state.description2.length < 1 && this.state.description.length > 0)}
                                        size={'full'}
                                        type={"text"}
                                        onChange={(value) => {
                                            this.setState({
                                                description2: value.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesSitelinks;
