import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/external"+'?query='+data.query+'&country='+data.country+"&client="+data.client;
        return apiRegister.call(options, url);
    }

    static createRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/region";
        return apiRegister.call(options, url);
    }

    static updateRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/region/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/region/" + data.region;
        return apiRegister.call(options, url);
    }

    static getInternalRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal?country="+data.country+"&client="+data.client;
        return apiRegister.call(options, url);
    }

    static getInternalRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/internal/"+data.region;
        return apiRegister.call(options, url);
    }

    static getClients() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/client";
        return apiRegister.call(options, url);
    }

}

export {calls}