import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import {tokenRegister} from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import OauthPopup from "react-oauth-popup";

class ClientAdform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            loading: false,
            managers: [],
            clients: [],
            advertiser: {},
            client: {},
            managers_loading: false,
            clients_loading: false,
            submenu: 0
        };
    };

    componentDidMount() {

        let url = "https://id.adform.com/sts/connect/authorize";
        url = url + "?scope=https://api.adform.com/scope/eapi https://api.adform.com/scope/ndw.public.fast.lines.readonly https://api.adform.com/scope/buyer.rtb.lineitem.readonly https://api.adform.com/scope/buyer.direct.lineitems https://api.adform.com/scope/buyer.direct.lineitems.readonly offline_access openid https://api.adform.com/scope/buyer.advertisers.readonly https://api.adform.com/scope/buyer.stats https://api.adform.com/scope/buyer.campaigns.api.readonly";
        url = url + "&response_type=code";
        url = url + "&client_id=reporting.adcredo@apps.adform.com";

        if(window.location.hostname.indexOf("dev") !== -1){
            url = url + "&redirect_uri=https://dev.adcredo.io/callback/adform";
        }else{
            url = url + "&redirect_uri=https://app.adcredo.io/callback/adform";
        }

        this.setState({
            item: this.props.item,
            adform_url: url
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item?nextProps.item:{}
        });
    }

    init = {};

    functions = {
        token: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.getToken({code: this.state.code}).then((response) => {
                        this.setState({
                            token: response.access_token,
                            refresh_token: response.refresh_token,
                            loading: false
                        }, () => {
                            this.functions.accounts();
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.adform;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        advertiser: this.state.advertiser,
                        access_token: this.state.token,
                        refresh_token: this.state.refresh_token
                    }).then((response) => {
                        this.state.item.adform = response.data;
                        this.setState({
                            token: null,
                            advertiser: {},
                            item: this.state.item,
                            loading_create: false,
                            open: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        accounts: () => {
            this.setState({
                managers_loading: true
            }, () => {
                calls.getAccounts({token: this.state.token}).then((response) => {
                    this.setState({
                        managers: response.data,
                        managers_loading: false
                    })
                }, (error) => {
                    this.setState({
                        managers_loading: false
                    })
                });
            })
        }
    };

    renders = {
        submenus: () => {
            let array = [
                {id: 0, name: "Add account"}
            ];
            return array;
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Adform"
                    type="warning"
                    text={"You wont be able access any Adform campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.logout();
                    }}
                    onCancel={() => {
                        this.setState({remove_modal: false});
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.item.adform && !this.state.token &&
                            <OauthPopup
                                url={this.state.adform_url}
                                onCode={(code) => {
                                    console.log(code);
                                    if(code){
                                        this.setState({
                                            code: code
                                        }, () => {
                                            this.functions.token();
                                        });
                                    }
                                }}
                                onClose={() => {
                                    console.log('closed')
                                }}
                            >
                                <div className="Section">
                                    <div className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>login Adform</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            </OauthPopup>
                        }
                        {
                            this.state.token && !this.state.item.adform &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Dropdown
                                    filter={false}
                                    title={'Select advertiser'}
                                    size={'full'}
                                    placeholder={(!this.state.managers_loading && this.state.managers.length < 1)?"No advertisers available":(this.state.managers_loading?"Loading advertisers":"Click here ..")}
                                    error={this.state.error === "manager" && !this.state.advertiser.name}
                                    options={this.state.managers}
                                    loading={this.state.managers_loading}
                                    removeArrow={!this.state.managers_loading && this.state.managers.length < 1}
                                    value={this.state.advertiser}
                                    onChange={(value) => this.setState({
                                        advertiser: value
                                    })}
                                />
                            </div>
                        }
                        {
                            (this.state.item.adform) &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Input
                                    title={'Advertiser'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.adform.advertiserName + ' - ' + this.state.item.adform.advertiserId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            (window.location.hostname.indexOf("localhost") !== -1) &&
                            !this.state.item.adform &&
                            <div className="Section mt-4">
                                <Input
                                    title={'Copy code from Adform window'}
                                    type={'text'}
                                    onChange={(event) => {
                                        this.setState({
                                            code: event.target.value
                                        })
                                    }}
                                    value={this.state.code}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            (window.location.hostname.indexOf("localhost") !== -1) &&
                            !this.state.item.adform &&
                            <div className="Section">
                                <div onClick={() => this.functions.token()} className="Button ButtonFull">
                                    {
                                        !this.state.loading &&
                                        <div>Upload code</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.adform && this.state.advertiser.id &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>add Adform</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                this.state.item.adform &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>signout Adform</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.adform &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        token: null,
                                        advertiser: {}
                                    })} className="Button ButtonFull ButtonGoogle">
                                        signout Adform
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientAdform;
