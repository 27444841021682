import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, TrashIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, FilterIcon, UserAddIcon, LightningBoltIcon, ExternalLinkIcon, PauseIcon, MenuAlt1Icon, PlayIcon, PencilAltIcon, RefreshIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import { Doughnut, Line } from 'react-chartjs-2';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';
import CreateBingAdAccount from '../moduleFiles/createBingAdAccount';
import CreateAccountBudget from '../moduleFiles/createAccountBudget';
var moment = require('moment');

class ClientBudgetList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            loading: true,
            loading_partial: false,
            meta: {},
            user: {},
            limit: 10,
            page: 1,
            sort: "name",
            direction: "descending",
            search: "",
            columns: [
                { name: 'name', value: 'name', min_width: '350px' },
                //{ name: 'client status', value: 'status', min_width: '150px' },
                //{ name: 'order status', value: 'orders', noSort: true },
                { name: 'google', value: 'google', min_width: '100px' },
                { name: 'bing', value: 'bing', min_width: '100px' },
                { name: 'facebook', value: 'facebook', min_width: '100px' },
                { name: 'linkedin', value: 'linkedin', min_width: '100px' },
                { name: 'tiktok', value: 'tiktok', min_width: '100px' },
                { name: 'snapchat', value: 'snapchat', min_width: '100px' }
            ],
            client: {},
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "period",
                    placeholder: "Current",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Current month", value: "current" },
                        { id: 2, name: "Upcoming month", value: "upcoming" },
                    ],
                    selected: { id: 1, name: "Current month", value: "current" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "clientStatus",
                    placeholder: "Client status",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "All client stats", value: "all" },
                        { id: 2, name: "Only active clients", value: "active" },
                        { id: 3, name: "Only paused clients", value: "paused" }
                    ],
                    selected: { id: 1, name: "All client stats", value: "all" },
                    loading: false
                }
            ],
            updated: false,
            selected_tags: [],
        };
    }

    async componentDidMount() {

        /*
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page") || 1;
        let limit = params.get("limit") || this.state.limit;
        let sort = params.get("sortBy") || this.state.sort;
        let direction = params.get("orderBy") || this.state.direction;
        let search = params.get("search") || this.state.search;
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        let buff = [];
        if (tags.length > 0) {
            tags.map(tag => {
                buff.push({ name: tag });
            })
        }

        await this.promisedSetState({
            page: +page,
            limit: +limit,
            sort: sort,
            direction: direction,
            ...(search && { search: search }),
            advancedFilters: this.state.advancedFilters,
            selected_tags: buff
        });
        */

        this.functions.getUser();
        this.functions.client();
        this.functions.getClients(true);
    }

    // ON BACK BUTTON
    async componentWillReceiveProps(nextProps, nextContext) {

        /*
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page")
        let limit = params.get("limit")
        let sort = params.get("sortBy")
        let direction = params.get("orderBy")
        let search = params.get("search");
        let agents = params.get("agents");
        let tags = params.getAll("tags[]");
        let active = params.get("active");

        if (agents) {
            this.state.advancedFilters[1].selected = this.state.advancedFilters[1].options.filter(item => item.value === agents)[0];
        }

        if (active) {
            this.state.advancedFilters[0].selected = this.state.advancedFilters[0].options.filter(item => item.value === active)[0];
        }

        if (tags && tags.length > 0) {
            let selected_tags = [];
            tags.map(tag => {
                selected_tags.push({ name: tag });
            })
            this.state.selected_tags = selected_tags;
        }

        if (page && page !== this.state.page) {
            await this.promisedSetState({
                page: +page,
                limit: +limit,
                sort: sort,
                direction: direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        } else if (!window.location.pathname.includes("?limit=")) {
            await this.promisedSetState({
                page: 1,
                limit: 10,
                sort: this.state.sort,
                direction: this.state.direction,
                ...(search && { search: search }),
                advancedFilters: this.state.advancedFilters,
                selected_tags: this.state.selected_tags
            })
            this.functions.getClients();
        }
        */
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        handleSetTags: async (value) => {
            await this.promisedSetState({
                selected_tags: value
            });
            this.functions.getClients()
        },
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name;
                });
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getClients(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                    client: JSON.stringify(this.state.client),
                    agents: this.state.advancedFilters[1].selected ? this.state.advancedFilters[1].selected : { id: 1, name: "Assigned/Unassigned", value: "all" },
                    active: this.state.advancedFilters[0].selected ? this.state.advancedFilters[0].selected : { id: 1, name: "Active", value: "active" },
                    tags: this.state.selected_tags ? this.state.selected_tags : []
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.selected_tags.length > 0 ? this.state.selected_tags.map(item => "&tags[]=" + item.name).join('') : "") + "&agents=" + this.state.advancedFilters[1].selected.value + "&active=" + this.state.advancedFilters[0].selected.value)
                )
            } catch (error) {
                console.log(error)
            }
        }
    };

    renders = {
        date: (date) => {
            let parsed_date = null;
            try {
                parsed_date = moment(date).format("DD MMMM");
            } catch (error) { }
            return parsed_date ? parsed_date : date;
        },
        number: (number) => {
            let parsed_number = null;
            try {
                parsed_number = number.toFixed(2);
            } catch (error) { }
            return parsed_number ? parsed_number : number;
        }
    }

    calls = {
        getClients: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientBudgets?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeClient?client=" + id;
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">
                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.getClients();
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_order: true
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getClients();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.history()
                            this.functions.getClients();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.history()
                            this.functions.getClients();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.getClients(false, value);
                                }
                            }, 400);
                        }}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={true}
                        hideUserDropdown={true}
                        breadcrumb={"Clients"}
                        buttons={[
                            //{ text: "Create new", icon: IdentificationIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.getClients();
                            this.props.updateStatistics(client);
                        }}
                        // show_table_settings={true}
                        onSetTags={async (value) => {
                            this.functions.handleSetTags(value)
                        }}
                        showTagsDropdown={true}
                        tagType={"client"}
                        url_tags={this.state.selected_tags}
                        searchInputValue={this.state.search}
                        client={this.state.client}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading budgets ...</div>
                    </div>
                }

                {/*CREATE ACCOUNT BUDGET*/}
                <SlideoutTailwind
                    title={"Manage client budgets"}
                    submitButtonText={"Create"}
                    medium={true}
                    noSubmit={true}
                    secondaryButton={false}
                    open={this.state.create_budget ? true : false}
                    loader={this.state.create_budget_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_budget: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_budget_loading: true
                        });
                        this.refs.CreateAccountBudget.functions.createOrder();
                    }}
                >
                    {
                        this.state.create_budget &&
                        <CreateAccountBudget
                            ref="CreateAccountBudget"
                            client={this.state.create_budget}
                            onCreated={async (response) => {

                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_budget_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>
                <SuccessModal
                    open={!!this.state.updated}
                    title="Updated"
                    button="OK"
                    onClose={() => {
                        this.setState({
                            updated: false
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        <div className={`shadow bg-white w-full overflow-hidden h-full ${this.state.clients.length > 0 ? "rounded-t-lg" : "rounded-lg"}`}
                            style={{ minHeight: "250px" }}
                        >

                            {/*NO ORDERS*/}
                            {
                                this.state.clients.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No clients available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }
                            {/*TABLE*/}
                            {
                                this.state.clients.length > 0 &&
                                <div className="relative overflow-x-auto table-overflow h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-55 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.getClients();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value === 'name' ? "sticky z-50 left-0 " : "") + (item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-50 hover:bg-opacity-75 cursor-pointer bg-white" : "bg-white")) + " border-l px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                {
                                                                    item.value == "google" &&
                                                                    <div
                                                                        className={'w-5 h-5 mr-2 bg-google-500 rounded-full p-1.5 border-1.5 border-white'}>
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.value == "bing" &&
                                                                    <div
                                                                        className={'w-5 h-5 bg-bing-500 mr-2 rounded-full p-1.5 border-2 border-white relative'}>
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.value == "facebook" &&
                                                                    <div
                                                                        className="w-5 h-5 bg-facebook-500 rounded-full p-1 border-2 border-white mr-2 relative">
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.value == "linkedin" &&
                                                                    <div
                                                                        className={'w-5 h-5 bg-linkedin-500 rounded-full p-1.5 border-2 border-white mr-2 relative'}>
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.value == "tiktok" &&
                                                                    <div
                                                                        className={'w-5 h-5 bg-black rounded-full p-1.5 border-2 border-white mr-2 relative'}>
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.value == "snapchat" &&
                                                                    <div
                                                                        className={'w-5 h-5 bg-snapchat-500 rounded-full p-1.5 border-2 border-white mr-2 relative'}>
                                                                        {
                                                                            false &&
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(https://s3.amazonaws.com/bitmoji-sdk-images/logo-snapchat.svg)" }}></div>
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.clients.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr
                                                                onMouseEnter={() => {
                                                                    item.hover = true;
                                                                    this.setState({
                                                                        clients: this.state.clients
                                                                    })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    item.hover = false;
                                                                    this.setState({
                                                                        clients: this.state.clients
                                                                    })
                                                                }}
                                                                className="border-b" key={item.id}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-4 flex flex-row items-center justify-center  border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                                                                style={{ zIndex: 9999 }}
                                                                            >
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Edit"].map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                if (option === "Edit") {
                                                                                                                    this.setState({
                                                                                                                        create_budget: item
                                                                                                                    });
                                                                                                                }
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? (option !== "Delete" ? 'bg-gray-50 text-gray-900' : '') : (option !== "Delete" ? 'text-gray-700' : ''),
                                                                                                                option === "Delete" && item.disabled ? 'bg-red-500 text-white' : '',
                                                                                                                'px-4 py-2 text-sm relative items-center flex flex-row cursor-pointer',
                                                                                                                option == "Claim client" ? 'bg-purple-100 text-purple-500' : ''
                                                                                                            )}
                                                                                                        >
                                                                                                            <div className="flex flex-1">
                                                                                                                {option}
                                                                                                            </div>
                                                                                                            {
                                                                                                                option == "Edit" &&
                                                                                                                <PencilAltIcon className="w-5" />
                                                                                                            }
                                                                                                        </div>

                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.setState({
                                                                        create_budget: item
                                                                    });
                                                                }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50" : "bg-white") + " border-l sticky left-0 z-50 border-r px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div>
                                                                            <div className="h-8 w-8 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                <img src={item.logo} className="bg-cover" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <div className="flex flex-row items-center">
                                                                                {item.name}
                                                                                {item.status == "active" &&
                                                                                    <div className="h-4 w-4 ml-2 rounded-full bg-green-500"></div>
                                                                                }
                                                                                {item.status == "disabled" &&
                                                                                    <div className="h-4 w-4 ml-2 rounded-full bg-orange-500"></div>
                                                                                }
                                                                                {item.status == "archived" &&
                                                                                    <div className="h-4 w-4 ml-2 rounded-full bg-gray-500"></div>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                <div className="text-xxs text-gray-600 font-medium">{item.tags.map((item) => { return "#" + item.name }).join(" ")}</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    false &&
                                                                    <td className={((this.state.sort === 'orders' || item.hover) ? "bg-gray-50" : "") + " whitespace-no-wrap px-4 sm:px-6 py-4 border-r font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                        <div className="flex flex-row">
                                                                            {
                                                                                Array.isArray(item.ordersStats) &&
                                                                                item.ordersStats.map((status) => {
                                                                                    return (
                                                                                        <div className={(status == "paused" ? "bg-orange-100 text-orange-500 " : "bg-green-100 text-green-500 ") + " w-8 h-8 rounded-full flex items-center justify-center"}>
                                                                                            {
                                                                                                status == "paused" &&
                                                                                                <PauseIcon className="w-5" />
                                                                                                ||
                                                                                                <PlayIcon className="w-5" />
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                }
                                                                {
                                                                    ["google", "bing", "facebook", "linkedin", "tiktok", "snapchat"].map((channel) => {
                                                                        return (
                                                                            <td className={((this.state.sort === channel || item.hover) ? "bg-gray-50" : "") + " whitespace-no-wrap px-4 sm:px-6 py-4 border-r font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                                {
                                                                                    (item[channel] && item[channel].connected) &&
                                                                                    item[channel] && item[channel].budget && item[channel].budget.id &&
                                                                                    <div className="flex flex-row items-center justify-start">
                                                                                        {this.renders.number(item[channel].budget.spendLimit)} {item[channel].currency}
                                                                                        {
                                                                                            item[channel].autoRenewBudget &&
                                                                                            <RefreshIcon className="w-3 ml-2"></RefreshIcon>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    (item[channel] && item[channel].connected) &&
                                                                                    item[channel] && item[channel].budget && item[channel].budget.id &&
                                                                                    <div className="text-xxs text-gray-300 font-medium">{this.renders.date(item[channel].budget.start)}{" - "}{this.renders.date(item[channel].budget.end)}</div>
                                                                                }
                                                                                {
                                                                                    (item[channel] && item[channel].connected) &&
                                                                                    !(item[channel] && item[channel].budget && item[channel].budget.id) &&
                                                                                    <div className="text-sm font-medium text-gray-500">
                                                                                        No budget
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !(item[channel] && item[channel].connected) &&
                                                                                    <div className="text-sm font-medium text-gray-500">
                                                                                        Not connected
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }



                        </div>

                        {/* PAGINATION */}
                        {
                            this.state.clients.length > 0 &&
                            <div className="flex flex-row p-4 items-center bg-white rounded-b-lg">
                                <div className="flex justify-center items-center">
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (this.state.page !== 1) {
                                                await this.promisedSetState({
                                                    page: this.state.page - 1
                                                });
                                                this.functions.getClients();
                                            }
                                        }}
                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                    >
                                        <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                        Previous
                                    </button>
                                </div>
                                <div className="flex flex-1 items-center justify-center">
                                    {
                                        (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                            <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                        </p>
                                    }
                                </div>
                                <div className="flex justify-center items-center">
                                    <div className="">
                                        <button
                                            onClick={async () => {
                                                if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                    await this.promisedSetState({
                                                        page: this.state.page + 1
                                                    });
                                                    this.functions.getClients();
                                                }
                                            }}
                                            className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <span>Next</span>
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>
        )
    }
}

export default ClientBudgetList;