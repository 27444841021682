import React, { Component, Fragment } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearch from "../advertising-creatives-search";
import AdvertisingCreativesSearchList from "../advertising-creatives-search-list";
import AdvertisingCreativesSearchAds from "../advertising-creatives-search-ads";
import AdvertisingCreativesSitelinks from "../advertising-creatives-sitelinks";
import Accordion from "../accordion";
import { calls } from "./calls";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ReactTooltip from "react-tooltip";
import AdvertisingCreativesCall from "../advertising-creatives-call";
import AdvertisingTargetingCommon from "../advertising-targeting-common";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import AdvertisingTargetingFacebookPlacement from "../advertising-targeting-facebook-placement";
import TargetingPlacement from "../targeting/placement";
import TargetingGender from "../targeting/gender";
import TargetingAge from "../targeting/age";
import TargetingWeekdays from "../targeting/weekdays";
import TargetingFacebook from "../targeting/facebook";
import ConversionFacebookProspect from "../conversion/facebook-prospect";
import callToAction from "../../assets/json/call_to_action";
import TargetingLocation from "../targeting/location";
import Datepicker from "../datepicker";
import { SlideDown } from "react-slidedown";
import ConversionStrategy from "../conversion/strategy";
import IconLinkedin from "../icons/linkedin";
import ErrorAlert from "../../modules/error-alert";
import IconTwitter from "../icons/twitter";
import Modal from "../../modules/modal";
import TargetingLinkedin from "../targeting/linkedin";

var moment = require('moment');

class AdvertisingSocial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            locations: [],
            languages: [],
            adset: {},
            campaign: {},
            details: {},
            conversion: {},
            adsets: [],
            call_to_actions: callToAction,
            loaded: false,
            opencampaignoverview: false,
            socialsublinks: [
                {
                    id: 1,
                    name: 'Creatives'
                },
                {
                    id: 2,
                    name: 'Targeting'
                },
                {
                    id: 3,
                    name: 'Locations'
                },
                /*{
                    id: 4,
                    name: 'Audiences'
                },*/
                {
                    id: 5,
                    name: 'Adset settings'
                }
            ],
            activesocialsub: 1
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'social' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        if (this.state.loaded) {
            this.init.data();
        }
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                open: this.props.open,
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                loaded: true
            }, () => {
                if ((this.state.channels.facebook || this.state.channels.instagram) && typeof this.state.conversion.facebook !== "object") {
                    this.state.conversion.facebook = {
                        audiences: [],
                        conversion_event: {}
                    };
                }
                if (!Array.isArray(this.state.creatives.campaigns) || (Array.isArray(this.state.creatives.campaigns) && this.state.creatives.campaigns.length < 1)) {
                    this.state.creatives.campaigns = [];
                    let item = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        name: this.state.details.name,
                        website: this.state.site.url,
                        budget: {}
                    };
                    this.state.creatives.campaigns.push(item);
                    this.setState({
                        campaign: item
                    }, () => {
                        if (Array.isArray(this.state.creatives.adsets)) {
                            this.state.creatives.adsets = this.state.creatives.adsets.map((item) => {
                                if (!item.campaign_id) {
                                    item.campaign_id = this.state.campaign.id;
                                }
                                return item
                            });
                        }
                    })
                } else if (Array.isArray(this.state.creatives.campaigns) && this.state.creatives.campaigns.length > 0) {
                    this.state.creatives.campaigns = this.state.creatives.campaigns.map((item) => {
                        if (!item.budget || (typeof item.budget === "object" && Object.keys(item.budget).length < 1)) {
                            item.budget = {};
                            for (let channel in this.state.budget) {
                                if (channel !== "total" && channel !== "google" && this.state.channels[channel]) {
                                    item.budget[channel] = {};
                                    if (this.state.budget[channel].daily > 0) {
                                        item.budget[channel].daily_budget = this.state.budget[channel].daily;
                                    }
                                    if (this.state.budget[channel].total > 0) {
                                        item.budget[channel].total_budget = this.state.budget[channel].total;
                                    }
                                    if (this.state.details.startDate) {
                                        item.budget[channel].start_date = this.state.details.startDate;
                                    }
                                    if (this.state.details.endDate) {
                                        item.budget[channel].end_date = this.state.details.endDate;
                                    }
                                    if (this.state.details.noEndDate) {
                                        item.budget[channel].no_end_date = this.state.details.noEndDate;
                                    }
                                    if (this.state.details.focus && this.state.details.focus.value) {
                                        if (this.state.details.focus.value === 'impressions') {
                                            item.budget[channel].focus = { name: "Reach", value: 'impressions' };
                                        }
                                        if (this.state.details.focus.value === 'clicks') {
                                            item.budget[channel].focus = { name: "Traffic", value: 'clicks' };
                                        }
                                        if (this.state.details.focus.value === 'conversion') {
                                            item.budget[channel].focus = { name: "Conversions", value: 'conversion' };
                                        }
                                    }
                                }
                            }
                        }
                        return item;
                    });
                    /*
                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        console.log(this.state.creatives);
                        this.setState({
                            campaign: this.state.creatives.campaigns[0]
                        }, () => {
                        })
                    });
                     */
                }
                if (!this.state.creatives.adsets) {
                    this.state.creatives.adsets = [];
                } else if (Array.isArray(this.state.creatives.adsets) && this.state.creatives.adsets.length > 0) {
                    this.setState({
                        adset: this.state.creatives.adsets[0]
                    })
                }
                this.setState({
                    creatives: this.state.creatives,
                    conversion: this.state.conversion
                }, () => {
                    this.setState({
                        campaign: Array.isArray(this.state.creatives.campaigns) ? this.state.creatives.campaigns[0] : {}
                    }, () => {
                        this.functions.save('creatives');
                        this.functions.save('conversion');
                    })
                })
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
            }, () => {
                if (Array.isArray(this.state.creatives.adsets) && this.state.creatives.adsets.length < 1) {
                    this.setState({
                        adset: {}
                    })
                }

                console.log(this.state.client);

            })
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true
                });
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            try {
                this.props.onChange()
            } catch (e) {
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        newCampaign: () => {
            let campaign = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: this.state.details.name,
                website: this.state.site.url,
                headline: "",
                headline_second: "",
                headline_third: "",
                carousel_title: "",
                carousel_description: "",
                call_to_action: {},
                budget: {}
            };
            campaign.budget = this.state.budget;
            for (let channel in this.state.budget) {
                if (channel !== "total" && this.state.channels[channel]) {
                    if (this.state.budget[channel].daily > 0) {
                        campaign.budget[channel].daily_budget = this.state.budget[channel].daily;
                    }
                    if (this.state.budget[channel].total > 0) {
                        campaign.budget[channel].total_budget = this.state.budget[channel].total;
                    }
                    if (this.state.details.startDate) {
                        campaign.budget[channel].start_date = this.state.details.startDate;
                    }
                    if (this.state.details.endDate) {
                        campaign.budget[channel].end_date = this.state.details.endDate;
                    }
                    if (this.state.details.noEndDate) {
                        campaign.budget[channel].no_end_date = this.state.details.noEndDate;
                    }
                    if (this.state.details.focus && this.state.details.focus.value) {
                        if (this.state.details.focus.value === 'impressions') {
                            campaign.budget[channel].focus = { name: "Reach", value: 'impressions' };
                        }
                        if (this.state.details.focus.value === 'clicks') {
                            campaign.budget[channel].focus = { name: "Traffic", value: 'clicks' };
                        }
                        if (this.state.details.focus.value === 'conversion') {
                            campaign.budget[channel].focus = { name: "Conversions", value: 'conversion' };
                        }
                    }
                }
            }
            this.state.creatives.campaigns.push(campaign);
            this.setState({
                campaign: campaign,
                creatives: this.state.creatives
            }, () => {
                this.functions.newAdset();
                this.functions.save('creatives');
            });
        },
        newAdset: () => {
            let adset = {};
            adset = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: 'Adset ' + (this.state.creatives.adsets.filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length + 1),
                value: this.state.creatives.adsets.filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length + 1,
                type: 'normal'
            };
            adset.campaign_id = this.state.campaign.id;
            adset.targeting = {};
            adset.targeting.age_max = { name: '65+', value: 65 };
            adset.targeting.age_min = { name: 18, value: 18 };
            adset.targeting.gender = { name: 'Male and Female', value: 0 };
            adset.targeting.facebook = {};
            adset.targeting.linkedin = {};
            adset.targeting.placement = {
                all: true
            };
            adset.conversion = {
                prospect_default: true
            };
            adset.targeting.weekdays = {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                all: true
            };
            this.state.creatives.adsets.push(adset);
            this.setState({
                adset: adset,
                creatives: this.state.creatives
            }, () => {
                this.functions.save('creatives');
            });
        },
        cloneCampaign: () => {
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let new_campaign = JSON.parse(JSON.stringify(this.state.campaign));
            let old_id = this.state.campaign.id;
            new_campaign.id = id;
            new_campaign.name = new_campaign.name + ' clone';
            this.state.creatives.campaigns.push(new_campaign);
            this.setState({
                campaign: new_campaign,
                creatives: this.state.creatives
            }, () => {
                this.state.creatives.adsets.filter((item) => {
                    return item.campaign_id === old_id;
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.campaign_id = new_campaign.id;
                    this.functions.clone(new_item);
                });
            });
        },
        clone: (adset) => {
            let id = Math.floor((Math.random() * 9999999999) + 1);
            let new_adset = JSON.parse(JSON.stringify(adset));
            new_adset.id = id;
            new_adset.name = new_adset.name + ' clone';
            this.state.creatives.adsets.push(new_adset);
            if (Array.isArray(this.state.creatives.image_ads)) {
                let double = [];
                this.state.creatives.image_ads.filter((item) => {
                    return item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'image_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    new_item.overview = false;
                    double.push(new_item);
                });
                this.state.creatives.image_ads = this.state.creatives.image_ads.concat(double);
            }
            if (Array.isArray(this.state.creatives.video_ads)) {
                let double = [];
                this.state.creatives.video_ads.filter((item) => {
                    return !item.error && item.internal_adset && item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'video_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    new_item.overview = false;
                    double.push(new_item);
                });
                this.state.creatives.video_ads = this.state.creatives.video_ads.concat(double);
            }
            if (Array.isArray(this.state.creatives.carousel_ads)) {
                let double = [];
                this.state.creatives.carousel_ads.filter((item) => {
                    return !item.error && item.internal_adset && item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'carousel_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    double.push(new_item);
                });
                this.state.creatives.carousel_ads = this.state.creatives.carousel_ads.concat(double);
            }
            if (Array.isArray(this.state.creatives.dynamic_ads)) {
                let double = [];
                this.state.creatives.dynamic_ads.filter((item) => {
                    return !item.error && item.internal_adset && item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'dynamic_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    double.push(new_item);
                });
                this.state.creatives.dynamic_ads = this.state.creatives.dynamic_ads.concat(double);
            }
            if (Array.isArray(this.state.creatives.event_ads)) {
                let double = [];
                this.state.creatives.event_ads.filter((item) => {
                    return !item.error && item.internal_adset && item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'event_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    double.push(new_item);
                });
                this.state.creatives.event_ads = this.state.creatives.event_ads.concat(double);
            }
            if (Array.isArray(this.state.creatives.post_ads)) {
                let double = [];
                this.state.creatives.post_ads.filter((item) => {
                    return !item.error && item.internal_adset && item.internal_adset.id === adset.id
                }).map((item) => {
                    let new_item = JSON.parse(JSON.stringify(item));
                    new_item.id = Math.floor((Math.random() * 9999999999) + 1);
                    new_item.internal_id = 'event_' + new_item.id;
                    new_item.internal_adset = new_adset;
                    double.push(new_item);
                });
                this.state.creatives.post_ads = this.state.creatives.post_ads.concat(double);
            }
            this.setState({
                adset: new_adset,
                creatives: this.state.creatives
            }, () => {
                this.functions.save('creatives');
            });
        },
        removeCampaign: (campaign, index) => {

            this.state.creatives.adsets.filter((item) => {
                return item.campaign_id === campaign.id;
            }).map((item) => {
                this.functions.remove(item);
            });

            this.state.creatives.campaigns = this.state.creatives.campaigns.filter((item) => {
                return item.id !== campaign.id
            });

            this.setState({
                creatives: this.state.creatives
            }, () => {

                //SET NEXT CAMPAIGN TAB
                if (this.state.creatives.campaigns.length > 1 && index > 0) {
                    this.state.campaign = this.state.creatives.campaigns[index - 1];
                } else {
                    this.state.campaign = this.state.creatives.campaigns[0];
                }

                if (this.state.creatives.adsets.filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length > 0) {
                    this.state.adset = this.state.creatives.adsets.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    })[0];
                } else {
                    this.state.adset = {};
                }

                this.setState({
                    campaign: this.state.campaign,
                    adset: this.state.adset,
                }, () => {
                    this.functions.save('creatives');
                });

            });
        },
        remove: (adset, index) => {
            if (this.state.creatives.image_ads) {
                let double = [];
                this.state.creatives.image_ads = this.state.creatives.image_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== adset.id
                });
            }
            if (this.state.creatives.video_ads) {
                let double = [];
                this.state.creatives.video_ads = this.state.creatives.video_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== adset.id
                });
            }
            if (this.state.creatives.carousel_ads) {
                let double = [];
                this.state.creatives.carousel_ads = this.state.creatives.carousel_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== adset.id
                });
            }
            if (this.state.creatives.dynamic_ads) {
                let double = [];
                this.state.creatives.dynamic_ads = this.state.creatives.dynamic_ads.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== adset.id
                });
            }
            this.state.creatives.adsets = this.state.creatives.adsets.filter((item) => {
                return item.id !== adset.id
            });

            this.setState({
                creatives: this.state.creatives
            }, () => {

                //SET NEXT ADSET TAB
                if (this.renders.adsets().filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length > 0 && index > 0) {
                    this.state.adset = this.renders.adsets().filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    })[index - 1];
                } else if (this.renders.adsets().filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length > 0) {
                    this.state.adset = this.renders.adsets().filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    })[0];
                }

                this.setState({
                    adset: this.state.adset
                }, () => {
                    this.functions.save('creatives');
                })

            });
        }
    };

    validators = {};

    renders = {
        subtitle: () => {
            try {
                let string = [];
                return string.join(', ');
            } catch (e) {
                return ""
            }
        },
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        property: (type) => {
            try {
                return this.state.creatives.languages[this.state.creatives.selected_language][type];
            } catch (e) {
                if (type !== 'site_links' && type !== 'extra_headlines') {
                    return ""
                } else {
                    return []
                }
            }
        },
        errorsSocial: () => {
            let errors = [];
            return errors;
        },
        errors: () => {
            let array = [];
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((item) => {
                    if (item.name === "") {
                        array.push({
                            title: 'Campaign title is missing',
                            campaign: item.id,
                            type: 'title',
                            section: 'social'
                        });
                    }
                });
            }
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((campaign) => {
                    if (Array.isArray(this.state.creatives.adsets)) {
                        this.state.creatives.adsets.map((adset) => {
                            if (adset.name === "" && adset.campaign_id === campaign.id) {
                                array.push({
                                    title: 'Adset title is missing',
                                    adset: adset.id,
                                    campaign: campaign.id,
                                    type: 'title',
                                    section: 'social'
                                });
                            }
                        });
                    }
                });
            }
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((campaign) => {
                    if (!Array.isArray(this.state.creatives.adsets) || (Array.isArray(this.state.creatives.adsets) && this.state.creatives.adsets.filter((adset) => {
                        return adset.campaign_id === campaign.id
                    }).length < 1)) {
                        array.push({
                            title: 'Adsets are missing',
                            campaign: campaign.id,
                            type: 'adset',
                            section: 'social'
                        });
                    }
                });
            }
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((campaign) => {
                    let all_disabled = true;
                    try {
                        for (let key in campaign.budget) {
                            if (!campaign.budget[key].disabled && key !== "total" && key !== "google") {
                                all_disabled = false;
                            }
                        }
                    } catch (e) {
                    }
                    if (all_disabled) {
                        array.push({
                            title: 'All channels are disabled',
                            campaign: campaign.id,
                            type: 'channels',
                            section: 'social'
                        });
                    }
                });
            }
            /*
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((campaign) => {
                    try {
                        for (let key in campaign.budget) {
                            if (!campaign.budget[key].disabled && key !== "total" && key !== "google" && (campaign.budget[key].daily_budget < 1 || !campaign.budget[key].daily_budget)) {
                                array.push({
                                    title: 'Daily budget is to low',
                                    campaign: campaign.id,
                                    channel: key,
                                    type: 'daily_budget',
                                    section: 'social'
                                });
                            }
                        }
                    } catch (e) {
                    }
                });
            }
            */
            /*
             if (Array.isArray(this.state.creatives.campaigns)) {
                 this.state.creatives.campaigns.map((campaign) => {
                     try {
                         for (let key in campaign.budget) {
                             if (!campaign.budget[key].disabled && key !== "total" && key !== "google" && (!campaign.budget[key].end_date && !campaign.budget[key].no_end_date)) {
                                 array.push({
                                     title: 'End date i missing',
                                     campaign: campaign.id,
                                     channel: key,
                                     type: 'date',
                                     section: 'social'
                                 });
                             }
                         }
                     } catch (e) {
                     }
                 });
             }
             */
            if (Array.isArray(this.state.creatives.campaigns)) {
                this.state.creatives.campaigns.map((campaign) => {
                    //console.log(campaign);
                    let conversion = false;
                    try {
                        for (let key in campaign.budget) {
                            if (campaign.budget[key].focus.value === "conversion" && !campaign.budget[key].disabled && key !== "google") {
                                conversion = true;
                            }
                        }
                    } catch (e) {
                    }
                    if (conversion) {
                        if (Array.isArray(this.state.creatives.adsets)) {
                            this.state.creatives.adsets.map((adset) => {
                                if (adset.campaign_id === campaign.id) {
                                    if (typeof adset.conversion === "object") {
                                        if (!adset.conversion.remarketing_default && !adset.conversion.prospect_default && !adset.conversion.lookalike_default) {
                                            array.push({
                                                title: 'Select prospect or remarketing',
                                                adset: adset.id,
                                                campaign: adset.campaign_id,
                                                type: 'conversion',
                                                section: 'social'
                                            });
                                        }
                                        if (adset.conversion.remarketing_default && Array.isArray(this.state.creatives.dynamic_ads)) {
                                            if (this.state.creatives.dynamic_ads.filter((item) => {
                                                return item.internal_adset.id === adset.id
                                            }).length > 0) {
                                                if (typeof adset.conversion.facebook === "object") {
                                                    if (!adset.conversion.facebook.conversion_dynamic_event || (typeof adset.conversion.facebook.conversion_dynamic_event === "object" && !adset.conversion.facebook.conversion_dynamic_event.value)) {
                                                        array.push({
                                                            title: 'Select target audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                    if (!adset.conversion.facebook.conversion_dynamic_days || (typeof adset.conversion.facebook.conversion_dynamic_days === "object" && !adset.conversion.facebook.conversion_dynamic_days.value)) {
                                                        array.push({
                                                            title: 'Select retention days',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                    if (!adset.conversion.facebook.conversion_event) {
                                                        array.push({
                                                            title: 'Select conversion event',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                    if (adset.conversion.lookalike_default && adset.conversion.facebook.audiences.filter((item) => {
                                                        return item.look_a_like_selected && !item.error
                                                    }).length < 1) {
                                                        array.push({
                                                            title: 'Select one or more look a like audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                } else {
                                                    array.push({
                                                        title: 'Select target audience',
                                                        adset: adset.id,
                                                        campaign: adset.campaign_id,
                                                        type: 'conversion',
                                                        section: 'social'
                                                    });
                                                    array.push({
                                                        title: 'Select retention days',
                                                        adset: adset.id,
                                                        campaign: adset.campaign_id,
                                                        type: 'conversion',
                                                        section: 'social'
                                                    });
                                                }
                                            }
                                        } else if (this.state.channels.facebook || this.state.channels.instagram) {
                                            if (typeof adset.conversion.facebook !== "object") {
                                                if (adset.conversion.remarketing_default) {
                                                    array.push({
                                                        title: 'Select one or more remarketing audience',
                                                        adset: adset.id,
                                                        campaign: adset.campaign_id,
                                                        type: 'conversion',
                                                        section: 'social'
                                                    });
                                                }
                                                if (adset.conversion.lookalike_default) {
                                                    array.push({
                                                        title: 'Select one or more look a like audience',
                                                        adset: adset.id,
                                                        campaign: adset.campaign_id,
                                                        type: 'conversion',
                                                        section: 'social'
                                                    });
                                                }
                                                array.push({
                                                    title: 'Select conversion event',
                                                    adset: adset.id,
                                                    campaign: adset.campaign_id,
                                                    type: 'conversion',
                                                    section: 'social'
                                                });
                                            } else {
                                                if (!Array.isArray(adset.conversion.facebook.audiences)) {
                                                    if (adset.conversion.remarketing_default) {
                                                        array.push({
                                                            title: 'Select one or more remarketing audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                    if (adset.conversion.lookalike_default) {
                                                        array.push({
                                                            title: 'Select one or more look a like audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                } else {
                                                    if (adset.conversion.remarketing_default && adset.conversion.facebook.audiences.filter((item) => {
                                                        return item.remarketing_selected && !item.error
                                                    }).length < 1) {
                                                        array.push({
                                                            title: 'Select one or more remarketing audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }
                                                    if (adset.conversion.lookalike_default && adset.conversion.facebook.audiences.filter((item) => {
                                                        return item.look_a_like_selected && !item.error
                                                    }).length < 1) {
                                                        array.push({
                                                            title: 'Select one or more look a like audience',
                                                            adset: adset.id,
                                                            campaign: adset.campaign_id,
                                                            type: 'conversion',
                                                            section: 'social'
                                                        });
                                                    }

                                                }
                                                if (!adset.conversion.facebook.conversion_event) {
                                                    array.push({
                                                        title: 'Select conversion event',
                                                        adset: adset.id,
                                                        campaign: adset.campaign_id,
                                                        type: 'conversion',
                                                        section: 'social'
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            }
            let items = [];
            if (Array.isArray(this.state.creatives.image_ads)) {
                items = items.concat(this.state.creatives.image_ads);
            }
            if (Array.isArray(this.state.creatives.video_ads)) {
                items = items.concat(this.state.creatives.video_ads);
            }
            if (Array.isArray(this.state.creatives.carousel_ads)) {
                items = items.concat(this.state.creatives.carousel_ads);
            }
            if (Array.isArray(this.state.creatives.dynamic_ads)) {
                items = items.concat(this.state.creatives.dynamic_ads);
            }
            if (Array.isArray(this.state.creatives.event_ads)) {
                items = items.concat(this.state.creatives.event_ads);
            }
            if (Array.isArray(this.state.creatives.post_ads)) {
                items = items.concat(this.state.creatives.post_ads);
            }
            if (Array.isArray(this.state.creatives.adsets)) {
                this.state.creatives.adsets.map((adset) => {

                    //CHECK LOCATIONS PR CAMPAIGN

                    let campaign = null;
                    let locations = 0;

                    this.state.creatives.campaigns.map((inner_campaign) => {
                        if (adset.campaign_id === inner_campaign.id) {
                            campaign = inner_campaign;
                        }
                    });

                    //CHECK IF LINKEDIN TARGETING -> LANGUAGE IS SELECTED
                    if (this.state.channels.linkedin && campaign.budget["linkedin"] && !campaign.budget["linkedin"].disabled) {
                        try {
                            if (!adset.targeting.linkedin.language) {
                                array.push({
                                    title: 'Linkedin Language targeting is missing',
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'targeting',
                                    channel: "linkedin",
                                    section: 'social'
                                });
                            }
                        } catch (e) {
                            array.push({
                                title: 'Linkedin Language targeting is missing',
                                adset: adset.id,
                                campaign: adset.campaign_id,
                                type: 'targeting',
                                channel: "linkedin",
                                section: 'social'
                            });
                        }
                    }

                    this.state.locations.map((item) => {
                        //console.log(item);
                        let include = false;
                        for (let key in campaign.budget) {
                            let inner_key = "";
                            if (key === "facebook_instagram") {
                                inner_key = "facebook";
                            } else {
                                inner_key = key;
                            }
                            if (item.channels && item.channels[inner_key] && !item.channels[inner_key].disabled && campaign && campaign.budget && !campaign.budget[key].disabled) {
                                include = true;
                            }
                        }
                        if (include) {
                            locations += 1;
                        }
                    });

                    //CHECK LOCATIONS
                    if (adset.exclude_locations && Object.keys(adset.exclude_locations).filter((key) => {
                        return adset.exclude_locations[key]
                    }).length === locations) {
                        array.push({
                            title: 'Minimum one location should be added',
                            adset: adset.id,
                            campaign: adset.campaign_id,
                            type: 'geographic',
                            section: 'social'
                        });
                    }

                    for (let channel in campaign.budget) {
                        if (campaign.budget && !campaign.budget[channel].disabled && adset.exclude_locations) {
                            let inner_key = "";
                            if (channel === "facebook_instagram") {
                                inner_key = "facebook";
                            } else {
                                inner_key = channel;
                            }
                            if (this.state.locations.filter((location) => {
                                return location.channels[inner_key] && !adset.exclude_locations[location.id]
                            }).length < 1) {
                                array.push({
                                    title: 'Minimum one location should be added for ' + inner_key,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'geographic',
                                    section: 'social'
                                });
                            }
                        }
                    }

                    if (items.filter((ad) => {
                        return ad.internal_adset && ad.internal_adset.id === adset.id
                    }).length < 1) {
                        array.push({
                            title: 'Ads are missing',
                            adset: adset.id,
                            campaign: adset.campaign_id,
                            type: 'ads',
                            section: 'social'
                        });
                    }
                    items.map((ad) => {
                        if (ad.internal_adset && ad.internal_adset.id === adset.id) {

                            if ((ad.image || ad.video) && (!ad.headline || ad.headline === "")) {
                                array.push({
                                    title: 'Headline is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_headline',
                                    section: 'social'
                                });
                            }
                            if ((!ad.body || ad.body === "") && !ad.post) {
                                array.push({
                                    title: 'Body is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_body',
                                    section: 'social'
                                });
                            }
                            if (!ad.title || ad.title === "") {
                                array.push({
                                    title: 'Name is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_title',
                                    section: 'social'
                                });
                            }
                            if (!ad.website || ad.website === "") {
                                array.push({
                                    title: 'Website is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_website',
                                    section: 'social'
                                });
                            }
                            if (ad.video && !ad.creative.thumbnail) {
                                array.push({
                                    title: 'Video is missing thumbnail',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_thumbnail',
                                    section: 'social'
                                });
                            }
                            if (ad.dynamic && !ad.catalog) {
                                array.push({
                                    title: 'Catalog is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_catalog',
                                    section: 'social'
                                });
                            }
                            if (ad.dynamic && !ad.product_set) {
                                array.push({
                                    title: 'Product set is missing',
                                    ad: ad.id,
                                    adset: adset.id,
                                    campaign: adset.campaign_id,
                                    type: 'ad_products',
                                    section: 'social'
                                });
                            }
                            if (ad.carousel) {
                                if (ad.items.filter((item) => {
                                    return !item.error && !item.loading
                                }).length < 2) {
                                    array.push({
                                        title: "Minimum 2 slides, upload another creative",
                                        ad: ad.id,
                                        adset: adset.id,
                                        campaign: adset.campaign_id,
                                        type: 'ad_carousel',
                                        section: 'social'
                                    });
                                }
                                if (this.state.channels.linkedin) {
                                    if (ad.items.filter((item) => {
                                        return !item.error && !item.loading && !item.video
                                    }).length < 2) {
                                        array.push({
                                            title: "Minimum 2 image slides (Linkedin)",
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                }
                                ad.items.map((item, index) => {
                                    if (!item.headline || item.headline === "") {
                                        array.push({
                                            title: 'Title is missing from slide ' + (index + 1),
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            slide: index,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                    if ((!item.description || item.description === "") && (this.state.channels.instagram || this.state.channels.facebook)) {
                                        array.push({
                                            title: 'Description is missing from slide ' + (index + 1),
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            slide: index,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                    if (!item.website || item.website === "") {
                                        array.push({
                                            title: 'Webiste is missing from slide ' + (index + 1),
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            slide: index,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                    /*
                                    if (!item.call_to_action || (item.call_to_action && item.call_to_action.value === "NO_BUTTON")) {
                                        array.push({
                                            title: 'Call to action is missing ' + (index + 1),
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            slide: index,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                    */
                                    if (item.video && !item.creative.thumbnail && (this.state.channels.instagram || this.state.channels.facebook)) {
                                        array.push({
                                            title: 'Thumbnail is missing from slide ' + (index + 1),
                                            ad: ad.id,
                                            adset: adset.id,
                                            campaign: adset.campaign_id,
                                            slide: index,
                                            type: 'ad_carousel',
                                            section: 'social'
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
            }
            console.log(array);
            return array
        },
        markdown: (input) => {
            return { __html: input }
        },
        adsets: () => {
            try {
                if (Array.isArray(this.state.creatives.adsets)) {
                    return this.state.creatives.adsets;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },
        campaigns: () => {
            try {
                if (Array.isArray(this.state.creatives.campaigns)) {
                    return this.state.creatives.campaigns;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        },

        date: (date) => {
            try {
                if (this.state.time) {
                    return moment(date).format('DD MMMM YYYY - LT');
                } else {
                    return moment(date).format('DD MMM YYYY');
                }
            } catch (e) {
            }
        },
        campaignChannels: (channel) => {
            try {
                return !this.state.campaign.budget[channel].disabled
            } catch (e) {
                return false
            }
        },
        round: (num) => {
            try {
                return Math.round((num + Number.EPSILON) * 100) / 100
            } catch (e) {
                return 0
            }
        },
        baseUrl: (url) => {
            try {
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                url = 'https://' + url;
                return url;
            } catch (e) {
                return url
            }
        },
        websites: () => {
            let websites = [];
            if (this.state.creatives && Array.isArray(this.state.creatives.image_ads)) {
                this.state.creatives.image_ads.filter((item) => {
                    return this.state.campaign && item.internal_adset && item.internal_adset.campaign_id === this.state.campaign.id
                }).map((item) => {
                    websites.push({
                        url: item.website,
                        base: this.renders.baseUrl(item.website),
                        name: item.name,
                        type: "image",
                        platform: "social"
                    });
                });
            }
            if (this.state.creatives && Array.isArray(this.state.creatives.video_ads)) {
                this.state.creatives.video_ads.filter((item) => {
                    return this.state.campaign && item.internal_adset && item.internal_adset.campaign_id === this.state.campaign.id
                }).map((item) => {
                    websites.push({
                        url: item.website,
                        base: this.renders.baseUrl(item.website),
                        name: item.name,
                        type: "video",
                        platform: "social"
                    });
                });
            }
            if (this.state.creatives && Array.isArray(this.state.creatives.carousel_ads)) {
                this.state.creatives.carousel_ads.filter((item) => {
                    return this.state.campaign && item.internal_adset && item.internal_adset.campaign_id === this.state.campaign.id
                }).map((item) => {
                    websites.push({
                        url: item.website,
                        base: this.renders.baseUrl(item.website),
                        name: item.name,
                        type: "carousel",
                        platform: "social"
                    });
                });
            }
            if (this.state.creatives && Array.isArray(this.state.creatives.dynamic_ads)) {
                this.state.creatives.dynamic_ads.filter((item) => {
                    return this.state.campaign && item.internal_adset && item.internal_adset.campaign_id === this.state.campaign.id
                }).map((item) => {
                    websites.push({
                        url: item.website,
                        base: this.renders.baseUrl(item.website),
                        name: item.name,
                        type: "dynamic",
                        platform: "social"
                    });
                });
            }
            try {
                websites.map((item) => {
                    if (this.state.campaign.website.toLowerCase() !== item.url.toLowerCase()) {
                        item.not_matching = true;
                    }
                });
            } catch (e) {
            }
            return websites;
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 5: Social campaigns
                    </div>
                </div>
                <div>
                    <div>
                        <div className="flex flex-1 flex-row justify-start px-4">
                            {
                                this.renders.campaigns().map((item, index) => {
                                    return (
                                        <div onClick={() => {
                                            let adset = {};
                                            if (this.state.creatives.adsets.filter((adset) => {
                                                return adset.campaign_id === item.id
                                            }).length > 0) {
                                                adset = this.state.creatives.adsets.filter((adset) => {
                                                    return adset.campaign_id === item.id
                                                })[0];
                                            }
                                            this.setState({
                                                campaign: item,
                                                adset: adset
                                            })
                                        }}
                                            className={cx("cursor-pointer overflow-hidden relative max-w-xs bg-white flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-middle rounded-t-md p-3 text-sm", {
                                                ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.campaign.id,
                                                ["ml-1"]: index !== 0
                                            })}>
                                            {
                                                this.renders.campaigns().length < 7 &&
                                                <div className={cx("InputContainerTitleIcons")}>
                                                    {
                                                        typeof item.budget === "object" &&
                                                        Object.keys(item.budget).filter((key) => {
                                                            return this.state.channels[key] && key !== "total" && key !== "google" && !item.budget[key].disabled
                                                        }).map((item) => {
                                                            if (item === 'facebook_instagram') {
                                                                return (
                                                                    <Fragment>
                                                                        <div
                                                                            className={'w-5 h-5 bg-facebook-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                            <img style={{ width: "4px" }}
                                                                                src={require('../../assets/images/facebook_icon.svg')} />
                                                                        </div>
                                                                        <div
                                                                            className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                            <img className="w-2"
                                                                                src={require('../../assets/images/instagram_icon.svg')} />
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            }
                                                            if (item === 'linkedin') {
                                                                return (
                                                                    <div
                                                                        className={'w-5 h-5 bg-linkedin-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                        <img className="w-2"
                                                                            src={require('../../assets/images/linkedin_icon.svg')} />
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div className={cx("pl-3 pr-3 truncate font-bold", {
                                                ["pl-1"]: this.renders.campaigns().length > 10,
                                                ["text-red-400"]: this.renders.campaigns().length > 6 &&
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0
                                            })}>
                                                {item.name ? item.name : "..."}
                                            </div>
                                            {
                                                false &&
                                                this.renders.campaigns().length < 7 &&
                                                this.renders.errors().filter((error) => {
                                                    return error.campaign === item.id
                                                }).length > 0 &&
                                                <div className="justify-center items-center font-bold">
                                                    <div
                                                        className="h-5 rounded-md text-xs px-2 flex justify-center items-center bg-red-400 text-white">
                                                        {this.renders.errors().filter((error) => {
                                                            return error.campaign === item.id
                                                        }).length}
                                                        {
                                                            this.renders.campaigns().length < 4 &&
                                                            <span className="ml-1">errors</span>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="flex flex-1 justify-end items-center flex-row">
                                                {
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0 &&
                                                    <div
                                                        className={"bg-gray-100 close mr-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15}
                                                            icon="alert-circle" />
                                                    </div>
                                                }
                                                {this.renders.campaigns().length > 1 &&
                                                    <div
                                                        className="close w-6 h-6 bg-gray-100 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"
                                                        onClick={() => {
                                                            this.functions.removeCampaign(item, index);
                                                        }}>&times;
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="flex flex-1 justify-start pl-1">
                                <div onClick={() => {
                                    this.functions.cloneCampaign();
                                }} data-tip={"Clone campaign"}
                                    className="bg-white mr-1 text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                        <FeatherIcon className="stroke-current" size={11}
                                            icon="copy" />
                                        <ReactTooltip />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.functions.newCampaign()
                                }} data-tip={"New campaign"}
                                    className="bg-white text-gray-700 flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>+
                                    </div>
                                    <ReactTooltip />
                                </div>
                            </div>
                            {
                                false &&
                                <div>
                                    <div onClick={() => {
                                        this.functions.cloneCampaign();
                                    }}
                                        className="bg-transparent cursor-pointer text-xs px-6 py-2 rounded-full flex justify-center items-center font-bold text-purple-500 hover:bg-purple-500 underline hover:text-white transition-all duration-200">
                                        <FeatherIcon className="stroke-current mr-1" size={16}
                                            icon="copy" /> Clone campaign
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="pb-4 bg-white shadow-xl rounded-md">
                            <div className="p-4 pb-0">

                                {
                                    <div className="overview bg-gray-100 py-4 px-4 rounded-md">
                                        <div className="flex flex-row flex-wrap items-center">
                                            <div>
                                                <h3 className="mb-0 font-bold text-black relative">
                                                    Campaign information
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return (item.tab === 'campaign' || item.type === 'title' || item.type === 'channels' || item.type === 'date') && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div style={{ right: "-37px" }}
                                                            className={"bg-white close top-0 mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                            <FeatherIcon className="stroke-current" size={15}
                                                                icon="alert-circle" />
                                                        </div>
                                                    }
                                                </h3>
                                                {
                                                    this.state.campaign && this.state.campaign.budget &&
                                                    <p className="text-xxs text-black capitalize">
                                                        <span
                                                            className="font-bold">Channels: </span>{Object.keys(this.state.campaign.budget).filter((key) => {
                                                                return key !== "google" && !this.state.campaign.budget[key].disabled
                                                            }).map((key) => {
                                                                if (key === "facebook_instagram") {
                                                                    return "facebook, instagram";
                                                                } else {
                                                                    return key;
                                                                }
                                                            }).join(", ")}{" "}
                                                    </p>
                                                }
                                            </div>
                                            <div className="flex flex-1 justify-end">
                                                <button
                                                    className="h-10 w-10 flex items-center justify-center text-2xl rounded-md transition-all duration-200 bg-purple-500 text-white"
                                                    onClick={() => {
                                                        let channel = null;
                                                        for (let key in this.state.campaign.budget) {
                                                            if (key !== "google" && key !== "total") {
                                                                channel = key;
                                                                break;
                                                            }
                                                        }
                                                        this.setState({
                                                            opencampaignoverview: !this.state.opencampaignoverview,
                                                            activechannel: channel
                                                        });
                                                    }}>
                                                    <FeatherIcon className="stroke-current" size={16} icon={'edit'} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.opencampaignoverview &&
                                    <Modal
                                        title={"Edit campaign information"}
                                        subtitle={this.state.campaign.name}
                                        blueButton={true}
                                        blueButtonText={"Update campaign"}
                                        onBlueButtonClick={() => {
                                            this.setState({
                                                opencampaignoverview: false
                                            })
                                        }}
                                        onClose={() => {
                                            this.setState({
                                                opencampaignoverview: false
                                            })
                                        }}
                                    >
                                        <div>
                                            {
                                                this.state.campaign.id &&
                                                <div className="flex flex-1 flex-row items-end">
                                                    <div className="flex flex-1">
                                                        <Input
                                                            title={"Title"}
                                                            sign={false}
                                                            white={false}
                                                            error={this.renders.errors().filter((item) => {
                                                                return item.type === 'title' && item.campaign === this.state.campaign.id && !item.adset
                                                            }).length > 0}
                                                            value={this.state.campaign.name}
                                                            tight={true}
                                                            type={"text"}
                                                            size={'full'}
                                                            icons={false}
                                                            onChange={(value) => {
                                                                this.state.campaign.name = value.target.value;
                                                                this.setState({
                                                                    campaign: this.state.campaign
                                                                }, () => {
                                                                    for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                        if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                            this.state.creatives.campaigns[i].name = this.state.campaign.name;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        creatives: this.state.creatives
                                                                    }, () => {
                                                                        this.functions.save('creatives');
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.opencampaignoverview &&
                                                this.renders.errors().filter((item) => {
                                                    return item.type === 'title' && item.campaign === this.state.campaign.id && !item.adset
                                                }).length > 0 &&
                                                <div className="">
                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                        <ul className="list-decimal">
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id && !item.adset
                                                                }).map((item) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold ">
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.campaign.id &&
                                                <div className="flex flex-1 flex-row items-end pt-4">
                                                    <div className="flex flex-1">
                                                        <Input
                                                            title={"Base website"}
                                                            sign={false}
                                                            white={false}
                                                            error={this.renders.errors().filter((item) => {
                                                                return item.type === 'website' && item.campaign === this.state.campaign.id && !item.adset
                                                            }).length > 0}
                                                            value={this.state.campaign.website}
                                                            tight={true}
                                                            type={"text"}
                                                            size={'full'}
                                                            icons={false}
                                                            onChange={(value) => {
                                                                this.state.campaign.website = value.target.value;
                                                                this.setState({
                                                                    campaign: this.state.campaign
                                                                }, () => {
                                                                    for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                        if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                            this.state.creatives.campaigns[i].website = this.state.campaign.website;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        creatives: this.state.creatives
                                                                    }, () => {
                                                                        this.functions.save('creatives');
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.renders.websites().filter((item) => {
                                                    return item.not_matching
                                                }).length > 0 &&
                                                <div className="flex flex-1 bg-yellow-100 mt-3 rounded-md py-4 px-8">
                                                    <ul className="list-disc">
                                                        {
                                                            this.renders.websites().filter((item) => {
                                                                return item.not_matching && item.platform === "search"
                                                            }).length > 0 &&
                                                            <li className="text-xs text-yellow-500 font-bold">
                                                                You have {this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "search"
                                                                }).length} keywords not mathing this exact URL
                                                            </li>
                                                        }
                                                        {
                                                            this.renders.websites().filter((item) => {
                                                                return item.not_matching && item.platform === "social" && item.type === "image"
                                                            }).length > 0 &&
                                                            <li className="text-xs text-yellow-500 font-bold">
                                                                You have {this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "social" && item.type === "image"
                                                                }).length} image ads not mathing this exact URL
                                                            </li>
                                                        }
                                                        {
                                                            this.renders.websites().filter((item) => {
                                                                return item.not_matching && item.platform === "social" && item.type === "video"
                                                            }).length > 0 &&
                                                            <li className="text-xs text-yellow-500 font-bold">
                                                                You have {this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "social" && item.type === "video"
                                                                }).length} video ads not mathing this exact URL
                                                            </li>
                                                        }
                                                        {
                                                            this.renders.websites().filter((item) => {
                                                                return item.not_matching && item.platform === "social" && item.type === "carousel"
                                                            }).length > 0 &&
                                                            <li className="text-xs text-yellow-500 font-bold">
                                                                You have {this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "social" && item.type === "carousel"
                                                                }).length} carousel ads not mathing this exact URL
                                                            </li>
                                                        }
                                                        {
                                                            this.renders.websites().filter((item) => {
                                                                return item.not_matching && item.platform === "social" && item.type === "dynamic"
                                                            }).length > 0 &&
                                                            <li className="text-xs text-yellow-500 font-bold">
                                                                You have {this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "social" && item.type === "dynamic"
                                                                }).length} dynamic ads not mathing this exact URL
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            <div className="bg-gray-100 rounded-md p-4 pt-0 mt-4">
                                                <div className="navigation">
                                                    <nav role="navigation" aria-label="Social sub navigation">
                                                        <ul className="flex flex-row items-end">
                                                            {Object.keys(this.state.campaign.budget).filter((key) => {
                                                                //console.log(key);
                                                                return this.state.channels[key] && key !== "total" && key !== "google"
                                                            }).map((key, j) => {
                                                                return (
                                                                    <li key={j}
                                                                        className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activechannel == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                activechannel: key
                                                                            });
                                                                        }}>
                                                                        {key === "facebook_instagram" ? "Facebook & Instagram" : key}
                                                                    </li>
                                                                )
                                                            })
                                                            }
                                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="bg-white rounded-md p-4 mt-4">
                                                    {
                                                        this.state.campaign.id && this.state.activechannel &&
                                                        <div className="">
                                                            <div className="font-bold text-xs mb-1">
                                                                Enable channel
                                                            </div>
                                                            <div style={{ height: "55px", backgroundColor: "#fcfcfc" }}
                                                                className="align-middle items-center flex flex-row flex-1 border rounded">
                                                                <div
                                                                    className="flex flex-row flex-1 text-xs font-bold pl-4">
                                                                    {
                                                                        (this.state.activechannel === "facebook" || this.state.activechannel === "facebook_instagram") &&
                                                                        <div style={{ marginRight: '3px' }}>
                                                                            <IconFacebook large={true} noMargin={true} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (this.state.activechannel === "instagram" || this.state.activechannel === "facebook_instagram") &&
                                                                        <div style={{ marginRight: '3px' }}>
                                                                            <IconInstagram large={true}
                                                                                noMargin={true} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (this.state.activechannel === "linkedin") &&
                                                                        <div style={{ marginRight: '3px' }}>
                                                                            <IconLinkedin large={true} noMargin={true} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (this.state.activechannel === "twitter") &&
                                                                        <div style={{ marginRight: '3px' }}>
                                                                            <IconTwitter large={true} noMargin={true} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="flex align-middle items-center pr-4">
                                                                    <Switch
                                                                        onColor="#1AD5BD"
                                                                        checked={!this.state.campaign.budget[this.state.activechannel].disabled}
                                                                        onChange={(checked) => {
                                                                            this.state.campaign.budget[this.state.activechannel].disabled = !checked;
                                                                            this.setState({
                                                                                campaign: this.state.campaign
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                    if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                        this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.activechannel === "facebook_instagram" && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                        <div>
                                                            <div className="flex flex-1 pt-4">
                                                                <Dropdown
                                                                    filter={false}
                                                                    title={'Budget planing'}
                                                                    noTitle={false}
                                                                    size={'full'}
                                                                    placeholder={"Select budget plan"}
                                                                    options={[
                                                                        { name: "Campaign level budget", value: 'campaign_budget' },
                                                                        { name: "Adset level budget", value: 'adset_budget' }
                                                                    ]}
                                                                    value={this.state.campaign.budget[this.state.activechannel].budget_level ? this.state.campaign.budget[this.state.activechannel].budget_level : { name: "Campaign level budget", value: 'campaign_budget' }}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.budget[this.state.activechannel].budget_level = value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.creatives.campaigns[i].budget_level = this.state.campaign.budget_level;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.activechannel === "linkedin" && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                        <div>
                                                            <div className="flex flex-1 pt-4">
                                                                <Dropdown
                                                                    filter={false}
                                                                    title={'Total budget'}
                                                                    noTitle={false}
                                                                    size={'full'}
                                                                    placeholder={"Select budget plan"}
                                                                    options={[
                                                                        { name: "Yes", value: 'total_budget' },
                                                                        { name: "No", value: 'daily_budget' }
                                                                    ]}
                                                                    value={this.state.campaign.budget[this.state.activechannel].budget_type ? this.state.campaign.budget[this.state.activechannel].budget_type : { name: "No", value: 'daily_budget' }}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.budget[this.state.activechannel].budget_type = value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.creatives.campaigns[i].budget_type = this.state.campaign.budget_type;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'channels' && item.campaign === this.state.campaign.id && !item.adset
                                                        }).length > 0 &&
                                                        <div className="">
                                                            <div
                                                                className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                <ul className="list-decimal">
                                                                    {
                                                                        this.renders.errors().filter((item) => {
                                                                            return item.type === 'channels' && item.campaign === this.state.campaign.id && !item.adset
                                                                        }).map((item) => {
                                                                            return (
                                                                                <li className="text-xs text-red-500 font-bold ">
                                                                                    {item.title}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.activechannel === "linkedin" &&
                                                        <div className="flex flex-1 flex-row pt-4 items-end">
                                                            {
                                                                (this.state.campaign.budget[this.state.activechannel].budget_type && this.state.campaign.budget[this.state.activechannel].budget_type.value === "total_budget") &&
                                                                <div className="flex flex-1 mr-2">
                                                                    <Input
                                                                        title={"Total budget"}
                                                                        sign={false}
                                                                        white={false}
                                                                        value={this.state.campaign.budget[this.state.activechannel].total_budget}
                                                                        tight={true}
                                                                        type={"number"}
                                                                        size={'full'}
                                                                        icons={false}
                                                                        onChange={(value) => {
                                                                            this.state.campaign.budget[this.state.activechannel].total_budget = value.target.value;
                                                                            this.setState({
                                                                                campaign: this.state.campaign
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                    if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                        this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                        <div className="flex flex-1 pt-4">
                                                            <Datepicker
                                                                size={'full'}
                                                                showTime={true}
                                                                border={true}
                                                                showNoEndDateSwitch={true}
                                                                disableEndDate={this.state.campaign.budget[this.state.activechannel].no_end_date}
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                }).length > 0}
                                                                startDate={this.state.campaign.budget[this.state.activechannel].start_date}
                                                                endDate={this.state.campaign.budget[this.state.activechannel].end_date}
                                                                updateNoEndDate={(value) => {
                                                                    this.state.campaign.budget[this.state.activechannel].no_end_date = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                            if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.creatives.campaigns[i].no_end_date = this.state.campaign.no_end_date;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                                onStartDate={(value) => {
                                                                    this.state.campaign.budget[this.state.activechannel].start_date = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                            if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                                onEndDate={(value) => {
                                                                    this.state.campaign.budget[this.state.activechannel].end_date = value;
                                                                    if (!this.state.campaign.budget[this.state.activechannel].start_date) {
                                                                        this.state.campaign.budget[this.state.activechannel].start_date = new Date();
                                                                    }
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                            if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.campaign.edit === true &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'date' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                            <ul className="list-decimal">
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                    }).map((item) => {
                                                                        return (
                                                                            <li className="text-xs text-red-500 font-bold ">
                                                                                {item.title}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                        <div className="flex flex-1 pt-4">
                                                            <Dropdown
                                                                filter={false}
                                                                title={'Optimization goal'}
                                                                noTitle={false}
                                                                size={'full'}
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                }).length > 0}
                                                                placeholder={"Select focus"}
                                                                options={[
                                                                    { name: "Reach", value: 'impressions' },
                                                                    { name: "Traffic", value: 'clicks' },
                                                                    { name: "Conversions", value: 'conversion' },
                                                                    { name: "App installs", value: 'APP_INSTALLS', channel: "facebook_instagram" },
                                                                    { name: "Link click (traffic)", value: 'LINK_CLICKS', channel: "facebook_instagram" },
                                                                    { name: "Engagement (event response)", value: 'EVENT_RESPONSES', channel: "facebook_instagram" },
                                                                    { name: "Landing page views (traffic)", value: 'LANDING_PAGE_VIEWS', channel: "facebook_instagram" },
                                                                    { name: "Page likes (engagement)", value: 'PAGE_LIKES', channel: "facebook_instagram" }
                                                                ].filter((item) => {
                                                                    return !item.channel || (item.channel == this.state.activechannel)
                                                                })}
                                                                value={this.state.campaign.budget[this.state.activechannel].focus ? this.state.campaign.budget[this.state.activechannel].focus : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.budget[this.state.activechannel].focus = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.campaigns.length; i++) {

                                                                            if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.creatives.campaigns[i].budget = this.state.campaign.budget;

                                                                                //REMOVE ALL NONE EVENT ADS
                                                                                if (value.value == "EVENT_RESPONSES") {

                                                                                    //SET SPECIFIK CALL TO ACTION BUTTON
                                                                                    if (Array.isArray(this.state.creatives.image_ads)) {
                                                                                        this.state.creatives.image_ads = this.state.creatives.image_ads.map((item) => {
                                                                                            item.call_to_action = { name: "INTERESTED", value: "EVENT_RSVP", vertical: true };
                                                                                            return item;
                                                                                        })
                                                                                    }

                                                                                    //SET SPECIFIK CALL TO ACTION BUTTON
                                                                                    if (Array.isArray(this.state.creatives.video_ads)) {
                                                                                        this.state.creatives.video_ads = this.state.creatives.video_ads.map((item) => {
                                                                                            item.call_to_action = { name: "INTERESTED", value: "EVENT_RSVP", vertical: true };
                                                                                            return item;
                                                                                        })
                                                                                    }

                                                                                    this.state.creatives.campaigns[i].carousel_ads = [];
                                                                                    if (this.state.creatives.carousel_ads) {
                                                                                        this.state.creatives.carousel_ads = this.state.creatives.carousel_ads.filter((item) => {
                                                                                            return item.internal_adset.campaign_id != this.state.creatives.campaigns[i].id;
                                                                                        })
                                                                                    }
                                                                                    this.state.creatives.campaigns[i].dynamic_ads = [];
                                                                                    if (this.state.creatives.dynamic_ads) {
                                                                                        this.state.creatives.dynamic_ads = this.state.creatives.dynamic_ads.filter((item) => {
                                                                                            return item.internal_adset.campaign_id != this.state.creatives.campaigns[i].id;
                                                                                        })
                                                                                    }
                                                                                    this.state.creatives.campaigns[i].post_ads = [];
                                                                                    if (this.state.creatives.post_ads) {
                                                                                        this.state.creatives.post_ads = this.state.creatives.post_ads.filter((item) => {
                                                                                            return item.internal_adset.campaign_id != this.state.creatives.campaigns[i].id;
                                                                                        })
                                                                                    }
                                                                                } else {
                                                                                    this.state.creatives.campaigns[i].event_ads = [];
                                                                                    if (this.state.creatives.event_ads) {
                                                                                        this.state.creatives.event_ads = this.state.creatives.event_ads.filter((item) => {
                                                                                            return item.internal_adset.campaign_id != this.state.creatives.campaigns[i].id;
                                                                                        })
                                                                                    }
                                                                                }

                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.campaign.edit === true &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                            <ul className="list-decimal">
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                    }).map((item) => {
                                                                        return (
                                                                            <li className="text-xs text-red-500 font-bold ">
                                                                                {item.title}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.activechannel === "facebook_instagram" &&
                                                        <div className="pt-3">
                                                            <Dropdown
                                                                filter={false}
                                                                searchFilter={false}
                                                                search={false}
                                                                title={'Special Ad Category'}
                                                                size={'full'}
                                                                placeholder={"Select category here ..."}
                                                                options={[
                                                                    { name: 'Housing', value: "HOUSING" },
                                                                    { name: 'Credit', value: "CREDIT" },
                                                                    { name: 'Employment', value: "EMPLOYMENT" },
                                                                    { name: 'None', value: "NONE" }
                                                                ]}
                                                                value={this.state.campaign.budget[this.state.activechannel].special_ad_category ? this.state.campaign.budget[this.state.activechannel].special_ad_category : { name: 'None', value: "NONE" }}
                                                                onChange={(value) => {
                                                                    this.state.campaign.budget[this.state.activechannel].special_ad_category = value;
                                                                    this.setState({
                                                                        creatives: this.state.creatives
                                                                    }, () => {
                                                                        this.functions.save('creatives');
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        false &&
                                                        this.state.campaign.budget[this.state.activechannel].focus && this.state.campaign.budget[this.state.activechannel].focus.value == "EVENT_RESPONSES" &&
                                                        <div className="flex flex-1 flex-row pt-4 items-end">
                                                            <div className="flex flex-1 mr-2">
                                                                <Input
                                                                    title={"Bid amount"}
                                                                    sign={false}
                                                                    white={false}
                                                                    value={this.state.campaign.budget[this.state.activechannel].bid_amount}
                                                                    tight={true}
                                                                    type={"number"}
                                                                    size={'full'}
                                                                    icons={false}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.budget[this.state.activechannel].bid_amount = value.target.value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        this.state.activechannel === "facebook_instagram" &&
                                                        this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                        (!this.state.campaign.budget[this.state.activechannel].budget_level || (this.state.campaign.budget[this.state.activechannel].budget_level && this.state.campaign.budget[this.state.activechannel].budget_level.value == "campaign_budget")) &&
                                                        <div className="flex flex-1 flex-row pt-4 items-end">
                                                            {
                                                                !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                <div className="flex flex-1 mr-2">
                                                                    <Input
                                                                        title={"Total budget"}
                                                                        sign={false}
                                                                        white={false}
                                                                        error={this.renders.errors().filter((item) => {
                                                                            return item.type === 'budget' && item.campaign === this.state.campaign.id && !item.adset
                                                                        }).length > 0}
                                                                        value={this.state.campaign.budget[this.state.activechannel].total_budget}
                                                                        tight={true}
                                                                        type={"number"}
                                                                        size={'full'}
                                                                        icons={false}
                                                                        onChange={(value) => {

                                                                            this.state.campaign.budget[this.state.activechannel].total_budget = value.target.value;

                                                                            //CAMPAIGN DAYS
                                                                            let dates = [];
                                                                            if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                dates = [];
                                                                                var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                    dates.push(currDate.clone().toDate());
                                                                                }
                                                                                dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                            }

                                                                            this.state.campaign.budget[this.state.activechannel].daily_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].total_budget / dates.length);

                                                                            this.setState({
                                                                                campaign: this.state.campaign
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                    if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                        this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                <div className="ml-1 mr-1"></div>
                                                            }
                                                            <div className="flex flex-1">
                                                                <Input
                                                                    title={"Daily budget"}
                                                                    sign={false}
                                                                    white={false}
                                                                    error={this.renders.errors().filter((item) => {
                                                                        return item.type === 'budget' && item.campaign === this.state.campaign.id && !item.adset
                                                                    }).length > 0}
                                                                    value={this.state.campaign.budget[this.state.activechannel].daily_budget}
                                                                    tight={true}
                                                                    type={"number"}
                                                                    size={'full'}
                                                                    icons={false}
                                                                    onChange={(value) => {

                                                                        this.state.campaign.budget[this.state.activechannel].daily_budget = value.target.value;

                                                                        if (!this.state.campaign.budget[this.state.activechannel].no_end_date) {
                                                                            //CAMPAIGN DAYS
                                                                            let dates = [];
                                                                            if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                dates = [];
                                                                                var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                    dates.push(currDate.clone().toDate());
                                                                                }
                                                                                dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                            }

                                                                            this.state.campaign.budget[this.state.activechannel].total_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].daily_budget * dates.length);
                                                                        }

                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.creatives.campaigns.length; i++) {
                                                                                if (this.state.creatives.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.creatives.campaigns[i].budget = this.state.campaign.budget;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id && !item.adset
                                                        }).length > 0 &&
                                                        <div
                                                            className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                            <ul className="list-decimal">
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id && !item.adset
                                                                    }).map((item) => {
                                                                        return (
                                                                            <li className="text-xs text-red-500 font-bold ">
                                                                                {item.title}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                }

                                {
                                    false &&
                                    <div className="overview bg-white border py-4 px-4 rounded">
                                        <div className="text-xs flex flex-row flex-wrap items-center">
                                            <div><span className="font-bold">Campaign data:</span>
                                            </div>
                                            <div className="ml-2">
                                                {this.state.campaign.name}
                                            </div>
                                            {
                                                this.renders.errors().filter((error) => {
                                                    return error.campaign === this.state.campaign.id && !error.adset
                                                }).length > 0 &&
                                                <div className="justify-center items-center ml-2 font-bold">
                                                    <div
                                                        className="h-5 rounded-md text-xs px-2 flex justify-center items-center bg-red-400 text-white">
                                                        {this.renders.errors().filter((error) => {
                                                            return error.campaign === this.state.campaign.id
                                                        }).length}
                                                        <span className="ml-1">errors</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                false &&
                                                <div className="ml-2 pl-2 border-l flex flex-row items-center">
                                                    <FeatherIcon
                                                        className="stroke-current mr-1" size={12}
                                                        icon="calendar" /> {this.renders.date(this.state.campaign.start_date)} - {this.renders.date(this.state.campaign.end_date)}
                                                </div>
                                            }
                                            {this.state.campaign.focus && this.state.campaign.focus.name && false &&
                                                <div className="ml-2 pl-2 border-l flex flex-row items-center">
                                                    <FeatherIcon
                                                        className="stroke-current mr-1" size={12}
                                                        icon="crosshair" /> {this.state.campaign.focus.name}
                                                </div>
                                            }
                                            {
                                                false &&
                                                <div className="ml-2 pl-2 border-l flex flex-row items-center">
                                                    <FeatherIcon
                                                        className="stroke-current mr-1" size={12}
                                                        icon="dollar-sign" /> {1000}</div>
                                            }
                                            <div className="ml-auto">
                                                <button
                                                    className="bg-gray-200 text-gray-900 py-3 px-3 rounded"
                                                    onClick={() => {
                                                        let channel = null;
                                                        for (let key in this.state.campaign.budget) {
                                                            if (key !== "google" && key !== "total") {
                                                                channel = key;
                                                                break;
                                                            }
                                                        }
                                                        this.setState({
                                                            opencampaignoverview: !this.state.opencampaignoverview,
                                                            activechannel: channel
                                                        });
                                                    }}>
                                                    <FeatherIcon className="stroke-current" size={16}
                                                        icon={this.state.opencampaignoverview ? 'x' : 'edit'} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="box-border px-4">
                                {
                                    this.renders.adsets().length > 0 &&
                                    <div className="flex flex-1 flex-row mt-4 justify-start pl-4">
                                        {
                                            false &&
                                            <div style={{ fontWeight: 300 }}
                                                className="relative mr-3 bg-white flex bg-opacity-50 text-purple-900 align-middle rounded-t-md p-3 text-xs font-normal">
                                                Adsets in<span
                                                    className="font-bold ml-1">{this.state.campaign.name}</span>
                                            </div>
                                        }
                                        {
                                            this.renders.adsets().filter((item) => {
                                                return item.campaign_id === this.state.campaign.id
                                            }).map((item, index) => {
                                                return (
                                                    <div onClick={() => {
                                                        this.setState({
                                                            adset: item
                                                        }, () => {
                                                            console.log(this.state.adset);
                                                        })
                                                    }}
                                                        style={item.id !== this.state.adset.id ? { backgroundColor: "#fcfcfc" } : {}}
                                                        className={cx("relative overflow-hidden max-w-xs bg-gray-100 flex hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 justify-center align-middle rounded-t-md p-3 cursor-pointer text-sm", {
                                                            ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.adset.id,
                                                            ["ml-1"]: index !== 0
                                                        })}>
                                                        {
                                                            false &&
                                                            this.renders.adsets().filter((item) => {
                                                                return item.campaign_id === this.state.campaign.id
                                                            }).length < 7 &&
                                                            <div style={{ paddingTop: "2px" }}>
                                                                <FeatherIcon color={"#333333"} size={16} icon="grid" />
                                                            </div>
                                                        }
                                                        {
                                                            <div className={cx("pl-1 pr-5 truncate font-bold", {
                                                                ["pl-1 text-red-400"]: this.renders.adsets().filter((item) => {
                                                                    return item.campaign_id === this.state.campaign.id
                                                                }).length > 6
                                                            })}>
                                                                {item.name ? item.name : "..."}
                                                            </div>
                                                        }
                                                        {false &&
                                                            this.renders.errors().filter((error) => {
                                                                return error.adset === item.id
                                                            }).length > 0 && this.renders.adsets().filter((item) => {
                                                                return item.campaign_id === this.state.campaign.id
                                                            }).length < 7 &&
                                                            <div className="justify-center items-center mr-2 font-bold">
                                                                <div
                                                                    className="h-5 rounded-md text-xs px-2 flex justify-center items-center bg-red-400 text-white whitespace-no-wrap">
                                                                    {this.renders.errors().filter((error) => {
                                                                        return error.adset === item.id
                                                                    }).length}
                                                                    {
                                                                        this.renders.adsets().filter((item) => {
                                                                            return item.campaign_id === this.state.campaign.id
                                                                        }).length < 5 &&
                                                                        <span className="ml-1">errors</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="flex flex-1 justify-end items-center flex-row">
                                                            {
                                                                this.renders.errors().filter((error) => {
                                                                    return error.adset === item.id
                                                                }).length > 0 &&
                                                                <div
                                                                    className={(item.id === this.state.adset.id ? "bg-white" : "bg-gray-100") + " close mr-1 w-6 h-6  mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                    <FeatherIcon className="stroke-current" size={15}
                                                                        icon="alert-circle" />
                                                                </div>
                                                            }
                                                            {
                                                                this.renders.adsets().filter((adset) => {
                                                                    return adset.campaign_id === this.state.campaign.id
                                                                }).length > 1 &&
                                                                <div
                                                                    className={(item.id === this.state.adset.id ? "bg-white" : "bg-gray-100") + " close w-6 h-6 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"}
                                                                    onClick={() => {
                                                                        this.functions.remove(item, index);
                                                                    }}>&times;
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="flex flex-1 justify-start pl-1">
                                            <div onClick={() => {
                                                this.functions.clone(this.state.adset);
                                            }} data-tip={"Clone adset"}
                                                className="bg-gray-100 mr-1 text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                                style={{ height: '100%' }}>
                                                <div
                                                    className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                                    <FeatherIcon className="stroke-current" size={11} icon="copy" />
                                                    <ReactTooltip />
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                this.functions.newAdset()
                                            }} data-tip={"New adset"}
                                                className="bg-gray-100 text-gray-700 flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                                style={{ height: '100%' }}>
                                                <div
                                                    className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>+
                                                </div>
                                                <ReactTooltip />
                                            </div>
                                        </div>
                                        {
                                            false &&
                                            <div>
                                                <div onClick={() => {
                                                    this.functions.clone(this.state.adset);
                                                }}
                                                    className="bg-transparent cursor-pointer text-xs px-6 py-2 rounded-full flex justify-center items-center font-bold text-purple-500 hover:bg-purple-500 underline hover:text-white transition-all duration-200">
                                                    <FeatherIcon className="stroke-current mr-1" size={16}
                                                        icon="copy" /> Clone adset
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    <div className="rounded-t-md bg-gray-100 px-4 pb-4">
                                        {
                                            this.state.adset.id && false &&
                                            <div className="flex flex-1 flex-row">
                                                <div className="flex flex-1 mt-4">
                                                    <Input
                                                        title={"Adset Title"}
                                                        sign={false}
                                                        noTitle={true}
                                                        maxLength={100}
                                                        placeholder={"Adset title"}
                                                        white={true}
                                                        noBorder={true}
                                                        error={this.renders.errors().filter((item) => {
                                                            return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                        }).length > 0}
                                                        value={this.state.adset.name}
                                                        type={"text"}
                                                        size={'full'}
                                                        onChange={(value) => {
                                                            this.state.adset.name = value.target.value;
                                                            this.setState({
                                                                adset: this.state.adset
                                                            }, () => {
                                                                for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                    if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                        this.state.creatives.adsets[i].name = this.state.adset.name;
                                                                    }
                                                                }
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.functions.save('creatives');
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            false &&
                                            this.renders.errors().filter((item) => {
                                                return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                            }).length > 0 &&
                                            <div
                                                className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                <ol className="list-decimal">
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                        }).map((item) => {
                                                            return (
                                                                <li className="text-xs text-red-500 font-bold ">
                                                                    {item.title}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </div>
                                        }
                                        <div className="navigation">
                                            <nav role="navigation" aria-label="Social sub navigation">
                                                <ul className="flex flex-row items-end">
                                                    {this.state.socialsublinks.filter((item) => {
                                                        let conversion = false;
                                                        try {
                                                            for (let key in this.state.campaign.budget) {
                                                                if (this.state.campaign.budget[key].focus.value === "conversion" && key !== "google") {
                                                                    conversion = true;
                                                                }
                                                            }
                                                        } catch (e) {
                                                        }
                                                        if (conversion) {
                                                            return true
                                                        } else {
                                                            return item.id !== 4
                                                        }
                                                    }).map((item, j) => {
                                                        return (
                                                            <li key={j}
                                                                className={"justify-center items-center font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activesocialsub == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                onClick={() => {
                                                                    this.setState({ activesocialsub: item.id });
                                                                }}>
                                                                {item.name}
                                                                {
                                                                    item.id === 1 &&
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.campaign === this.state.campaign.id && item.adset === this.state.adset.id && (item.ad || item.type === 'ads')
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                                        <div
                                                                            className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                            <FeatherIcon className="stroke-current"
                                                                                size={15} icon="alert-circle" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.id === 4 &&
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'conversion' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                                        <div
                                                                            className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                            <FeatherIcon className="stroke-current"
                                                                                size={15} icon="alert-circle" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.id === 3 &&
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'geographic' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                                        <div
                                                                            className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                            <FeatherIcon className="stroke-current"
                                                                                size={15} icon="alert-circle" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.id === 2 &&
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'targeting' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                                        <div
                                                                            className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                            <FeatherIcon className="stroke-current"
                                                                                size={15} icon="alert-circle" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.id === 5 &&
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                                        <div
                                                                            className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                            <FeatherIcon className="stroke-current"
                                                                                size={15} icon="alert-circle" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                    <li className="flex-grow border-b-2 border-gray-200"></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div>
                                            {
                                                this.renders.adsets().filter((item) => {
                                                    return item.campaign_id === this.state.campaign.id
                                                }).length > 0 &&
                                                <div className="">
                                                    {
                                                        this.state.activesocialsub == 1 && this.state.adset && this.state.adset.id &&
                                                        <AdvertisingCreativesAds
                                                            channels={this.state.channels}
                                                            error={this.state.error}
                                                            errors={this.renders.errors().filter((item) => {
                                                                return item.campaign === this.state.campaign.id && item.adset === this.state.adset.id && item.ad
                                                            })}
                                                            client={this.state.client}
                                                            site={this.state.site}
                                                            languages={this.state.languages}
                                                            creatives={this.state.creatives}
                                                            campaign={this.state.campaign}
                                                            adset={this.state.adset}
                                                            updateAdset={(value) => {
                                                                this.setState({
                                                                    adset: value
                                                                })
                                                            }}
                                                            onChange={(value) => {
                                                                this.state.creatives = value;
                                                                if (this.state.creatives.adsets && this.state.creatives.adsets.length === 1) {
                                                                    this.setState({
                                                                        adset: this.state.creatives.adsets[0]
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                            if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                this.state.creatives.adsets[i].name = this.state.adset.name;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    });
                                                                }
                                                                this.functions.save('creatives');
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        this.state.activesocialsub == 1 &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'ads' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                        }).length > 0 &&
                                                        <ErrorAlert
                                                            items={this.renders.errors().filter((item) => {
                                                                return item.type === 'ads' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                            })}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    this.renders.errors().filter((item) => {
                                        return item.type === 'adset' && item.campaign === this.state.campaign.id
                                    }).length > 0 &&
                                    <div
                                        className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                        <ol className="list-decimal">
                                            {
                                                this.renders.errors().filter((item) => {
                                                    return item.type === 'adset' && item.campaign === this.state.campaign.id
                                                }).map((item) => {
                                                    return (
                                                        <li className="text-xs text-red-500 font-bold ">
                                                            {item.title}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </div>
                                }

                                {this.renders.adsets().filter((item) => {
                                    return item.campaign_id === this.state.campaign.id
                                }).length > 0 &&
                                    <div>
                                        {
                                            this.state.activesocialsub == 4 &&
                                            this.state.adset && this.state.adset.targeting &&
                                            <div className="rounded-b-md bg-gray-100 px-4 pb-4">
                                                {
                                                    false &&
                                                    <div className="bg-white rounded-md">
                                                        <div className="px-4 py-4 flex-row flex">
                                                            <div className="flex flex-1 flex-col">
                                                                <h3 className="mb-0 font-bold">
                                                                    Adset strategy
                                                                </h3>
                                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                    Avg. number of search per month in parantheses
                                                                </p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                        <div className="w-full border-t" />
                                                        <div className="p-4">
                                                            <ConversionStrategy
                                                                client={this.state.client}
                                                                channels={this.state.channels}
                                                                adset={this.state.adset}
                                                                creatives={this.state.creatives}
                                                                conversion={this.state.adset.conversion ? this.state.adset.conversion : {}}
                                                                updateConversion={(value) => {
                                                                    this.state.adset.conversion = value;
                                                                    this.setState({
                                                                        adset: this.state.adset
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                            if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                this.state.creatives.adsets[i].conversion = this.state.adset.conversion;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <div className="bg-white rounded-md mt-4">
                                                        <div className="px-4 py-4 flex-row flex">
                                                            <div className="flex flex-1 flex-col">
                                                                <h3 className="mb-0 font-bold">
                                                                    Facebook
                                                                </h3>
                                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                    Avg. number of search per month in parantheses
                                                                </p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div>
                                                        <div className="w-full border-t" />
                                                        <div className="p-4">
                                                            <ConversionFacebookProspect
                                                                client={this.state.client}
                                                                channels={this.state.channels}
                                                                adset={this.state.adset}
                                                                creatives={this.state.creatives}
                                                                locations={this.state.locations}
                                                                conversion={this.state.adset.conversion ? this.state.adset.conversion : {}}
                                                                updateConversion={(value) => {
                                                                    this.state.adset.conversion = value;
                                                                    this.setState({
                                                                        adset: this.state.adset
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                            if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                this.state.creatives.adsets[i].conversion = this.state.adset.conversion;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'conversion' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                }).length > 0 &&
                                                                <ErrorAlert
                                                                    items={this.renders.errors().filter((item) => {
                                                                        return item.type === 'conversion' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                    })}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {this.state.activesocialsub == 2 && this.state.adset && this.state.adset.targeting &&
                                            <div className="rounded-b-md bg-gray-100 px-4 pb-4">
                                                <div className="bg-white rounded-md">
                                                    <div className="px-4 py-4 flex-row flex">
                                                        <div className="flex flex-1 flex-col">
                                                            <h3 className="mb-0 font-bold">
                                                                Basic
                                                            </h3>
                                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                Avg. number of search per month in parantheses
                                                            </p>
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                    <div className="w-full border-t" />
                                                    <div className="p-4">
                                                        <div className="flex flex-1 flex-col">
                                                            <div className="flex flex-1">
                                                                <div className="flex flex-1">
                                                                    <TargetingGender
                                                                        channels={this.state.channels}
                                                                        targeting={this.state.adset.targeting}
                                                                        onChange={(value) => {
                                                                            this.state.adset.targeting = value;
                                                                            this.setState({
                                                                                adset: this.state.adset
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                    if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                        this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    try {
                                                                                        this.refs.facebook_targeting.functions.estimate();
                                                                                    } catch (e) {
                                                                                        console.log(e);
                                                                                    }
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="w-4" />
                                                                <div
                                                                    className={(this.state.channels.linkedin && this.state.channels.facebook ? "flex-2" : "flex-1") + " flex"}>
                                                                    <TargetingAge
                                                                        channels={this.state.channels}
                                                                        targeting={this.state.adset.targeting}
                                                                        onChange={(value) => {
                                                                            this.state.adset.targeting = value;
                                                                            this.setState({
                                                                                adset: this.state.adset
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                    if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                        this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    try {
                                                                                        this.refs.facebook_targeting.functions.estimate();
                                                                                    } catch (e) {
                                                                                        console.log(e);
                                                                                    }
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                false &&
                                                                <div className="pt-5">
                                                                    <TargetingWeekdays
                                                                        targeting={this.state.adset.targeting}
                                                                        onChange={(value) => {
                                                                            this.state.adset.targeting = value;
                                                                            this.setState({
                                                                                adset: this.state.adset
                                                                            }, () => {
                                                                                for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                    if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                        this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                    }
                                                                                }
                                                                                this.setState({
                                                                                    creatives: this.state.creatives
                                                                                }, () => {
                                                                                    this.functions.save('creatives');
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.channels.linkedin &&
                                                    <div className="mt-4">
                                                        <div className="bg-white rounded-md">
                                                            <div className="px-4 py-4 flex-row flex">
                                                                <div className="flex flex-1 flex-col">
                                                                    <h3 className="mb-0 font-bold">
                                                                        Linkedin targeting
                                                                    </h3>
                                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                        Avg. number of search per month in parantheses
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                open_linkedin_targeting: !this.state.open_linkedin_targeting
                                                                            })
                                                                        }}>
                                                                        {
                                                                            !this.state.open_linkedin_targeting &&
                                                                            <FeatherIcon className="stroke-current" size={16}
                                                                                icon={"chevron-down"} />
                                                                        }
                                                                        {
                                                                            this.state.open_linkedin_targeting &&
                                                                            <FeatherIcon className="stroke-current" size={16}
                                                                                icon={"chevron-up"} />
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <SlideDown
                                                                closed={!this.state.open_linkedin_targeting && this.renders.errors().filter((item) => {
                                                                    return item.channel === "linkedin" && item.type === 'targeting' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                }).length < 1}>
                                                                <div className="w-full border-t" />
                                                                <div className="p-4">
                                                                    <div className="flex flex-1">
                                                                        <TargetingLinkedin
                                                                            client={this.state.client}
                                                                            channels={this.state.channels}
                                                                            targeting={this.state.adset.targeting}
                                                                            errors={this.renders.errors().filter((item) => {
                                                                                return item.channel === "linkedin" && item.type === 'targeting' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                            })}
                                                                            onChange={(value) => {
                                                                                this.state.adset.targeting = value;
                                                                                this.setState({
                                                                                    adset: this.state.adset
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                        if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                            this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        creatives: this.state.creatives
                                                                                    }, () => {
                                                                                        this.functions.save('creatives');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SlideDown>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    (this.state.channels.facebook || this.state.channels.instagram) &&
                                                    <div className="mt-4">
                                                        <div className="bg-white rounded-md">
                                                            <div className="px-4 py-4 flex-row flex">
                                                                <div className="flex flex-1 flex-col">
                                                                    <h3 className="mb-0 font-bold">
                                                                        Facebook targeting
                                                                    </h3>
                                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                        Avg. number of search per month in parantheses
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-col mr-8">
                                                                    <h3 className="mb-0 font-bold">
                                                                        {(this.state.adset.targeting && this.state.adset.targeting.facebook && this.state.adset.targeting.facebook.estimate) ? (this.state.adset.targeting.facebook.estimate.estimate_mau == -1 ? "Not available" : this.state.adset.targeting.facebook.estimate.estimate_dau) : "..."}
                                                                    </h3>
                                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                        Daily reach
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-col mr-16">
                                                                    <h3 className="mb-0 font-bold">
                                                                        {(this.state.adset.targeting && this.state.adset.targeting.facebook && this.state.adset.targeting.facebook.estimate) ? (this.state.adset.targeting.facebook.estimate.estimate_mau_lower_bound == -1 ? "Not available" : this.state.adset.targeting.facebook.estimate.estimate_mau_lower_bound + " - " + this.state.adset.targeting.facebook.estimate.estimate_mau_upper_bound) : "..."}
                                                                    </h3>
                                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                        Monthly reach
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                open_fb_targeting: !this.state.open_fb_targeting
                                                                            })
                                                                        }}>
                                                                        {
                                                                            !this.state.open_fb_targeting &&
                                                                            <FeatherIcon className="stroke-current" size={16}
                                                                                icon={"chevron-down"} />
                                                                        }
                                                                        {
                                                                            this.state.open_fb_targeting &&
                                                                            <FeatherIcon className="stroke-current" size={16}
                                                                                icon={"chevron-up"} />
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <SlideDown closed={!this.state.open_fb_targeting}>
                                                                <div className="w-full border-t" />
                                                                <div className="p-4">
                                                                    {
                                                                        this.state.campaign &&
                                                                        this.state.campaign.budget &&
                                                                        this.state.campaign.budget["facebook_instagram"] &&
                                                                        this.state.campaign.budget["facebook_instagram"].focus &&
                                                                        this.state.campaign.budget["facebook_instagram"].focus.value === "conversion" &&
                                                                        <div className="flex flex-1 flex-col mb-4">
                                                                            <ConversionFacebookProspect
                                                                                client={this.state.client}
                                                                                channels={this.state.channels}
                                                                                adset={this.state.adset}
                                                                                creatives={this.state.creatives}
                                                                                locations={this.state.locations}
                                                                                conversion={this.state.adset.conversion ? this.state.adset.conversion : {}}
                                                                                updateConversion={(value) => {
                                                                                    this.state.adset.conversion = value;
                                                                                    this.setState({
                                                                                        adset: this.state.adset
                                                                                    }, () => {
                                                                                        for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                            if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                                this.state.creatives.adsets[i].conversion = this.state.adset.conversion;
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            creatives: this.state.creatives
                                                                                        }, () => {
                                                                                            this.functions.save('creatives');
                                                                                        })
                                                                                    })
                                                                                }}
                                                                            />
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return item.type === 'conversion' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                                }).length > 0 &&
                                                                                <ErrorAlert
                                                                                    items={this.renders.errors().filter((item) => {
                                                                                        return item.type === 'conversion' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                                    })}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="flex flex-1">
                                                                        <TargetingFacebook
                                                                            ref="facebook_targeting"
                                                                            locations={this.state.locations}
                                                                            details={this.state.details}
                                                                            adset={this.state.adset}
                                                                            campaign={this.state.campaign}
                                                                            client={this.state.client}
                                                                            channels={this.state.channels}
                                                                            targeting={this.state.adset.targeting}
                                                                            onChange={(value) => {
                                                                                this.state.adset.targeting = value;
                                                                                this.setState({
                                                                                    adset: this.state.adset
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                        if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                            this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        creatives: this.state.creatives
                                                                                    }, () => {
                                                                                        this.functions.save('creatives');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-1 mt-3">
                                                                        <TargetingPlacement
                                                                            targeting={this.state.adset.targeting}
                                                                            channels={this.state.channels}
                                                                            creatives={this.state.creatives}
                                                                            adset={this.state.adset}
                                                                            onChange={(value) => {
                                                                                this.state.adset.targeting = value;
                                                                                this.setState({
                                                                                    adset: this.state.adset
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                        if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                            this.state.creatives.adsets[i].targeting = this.state.adset.targeting;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        creatives: this.state.creatives
                                                                                    }, () => {
                                                                                        this.functions.save('creatives');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </SlideDown>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.activesocialsub == 5 && this.state.adset &&
                                            <div className="bg-gray-100 rounded-b-md p-4 pt-0">
                                                <div className="bg-white rounded-md">
                                                    <div className="px-4 py-4 flex-row flex">
                                                        <div className="flex flex-1 flex-col">
                                                            <h3 className="mb-0 font-bold">
                                                                Adset name
                                                            </h3>
                                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                Avg. number of search per month in parantheses
                                                            </p>
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                    <div className="w-full border-t" />
                                                    <div className="p-4">
                                                        <div className="flex flex-1">
                                                            <Input
                                                                title={"Name"}
                                                                sign={false}
                                                                noTitle={false}
                                                                icon={'award'}
                                                                maxLength={30}
                                                                white={false}
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                }).length > 0}
                                                                value={this.state.adset.name}
                                                                placeholder={"Max 30 characters"}
                                                                tight={true}
                                                                type={"text"}
                                                                size={'full'}
                                                                icons={false}
                                                                onChange={(value) => {
                                                                    this.state.adset.name = value.target.value;
                                                                    this.setState({
                                                                        adset: this.state.adset
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                            if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                this.state.creatives.adsets[i].name = this.state.adset.name;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            creatives: this.state.creatives
                                                                        }, () => {
                                                                            this.functions.save('creatives');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                            }).length > 0 &&
                                                            <ErrorAlert
                                                                items={this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                })}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.activesocialsub == 3 && this.state.adset && this.state.adset.targeting &&
                                            <div className="rounded-b-md bg-gray-100 px-4 pb-4">
                                                <div className="bg-white rounded-md">
                                                    <div className="px-4 py-4 flex-row flex">
                                                        <div className="flex flex-1 flex-col">
                                                            <h3 className="mb-0 font-bold">
                                                                Locations
                                                            </h3>
                                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                                Avg. number of search per month in parantheses
                                                            </p>
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                    <div className="w-full border-t" />
                                                    <div className="p-4 pb-2">
                                                        <div className="flex flex-1 flex-col">
                                                            <div className="flex flex-1">
                                                                <TargetingLocation
                                                                    border={true}
                                                                    exclude_locations={this.state.adset.exclude_locations ? this.state.adset.exclude_locations : {}}
                                                                    locations={this.state.locations}
                                                                    channels={this.state.campaign.budget}
                                                                    updateLocation={(value) => {
                                                                        this.state.adset.exclude_locations = value;
                                                                        this.setState({
                                                                            adset: this.state.adset
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.creatives.adsets.length; i++) {
                                                                                if (this.state.creatives.adsets[i].id === this.state.adset.id) {
                                                                                    this.state.creatives.adsets[i].exclude_locations = this.state.adset.exclude_locations;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'geographic' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                }).length > 0 &&
                                                                <div
                                                                    className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8 mb-2">
                                                                    <ul className="list-disc">
                                                                        {
                                                                            this.renders.errors().filter((item) => {
                                                                                return item.type === 'geographic' && item.campaign === this.state.campaign.id && item.adset === this.state.adset.id
                                                                            }).map((item, index) => {
                                                                                return (
                                                                                    <li className="text-xs text-red-500 font-bold "
                                                                                        key={index}>
                                                                                        {item.title}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row mt-4 mb-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) {
                            }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>
                    <div className="flex flex-1"></div>
                    {
                        this.state.channels.google &&
                        <div className="text-right">
                            <button onClick={() => {
                                this.functions.next()
                            }} className="btn btn-lg btn-primary inline-flex items-center">
                                Next step: Search <span className="arrow-right"></span>
                            </button>
                        </div>
                    }
                    {
                        !this.state.channels.google &&
                        <div className="text-right">
                            <button onClick={() => {
                                this.functions.next()
                            }} className="btn btn-lg btn-primary inline-flex items-center">
                                Next step: Preview <span className="arrow-right"></span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingSocial;
