import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {BeatLoader} from "react-spinners";

class AccordionFixed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            subtitle: this.props.subtitle,
            open: this.props.open,
            loading: this.props.loading,
            icon: this.props.icon,
            edit: this.props.edit,
            icon_color: this.props.icon_color,
            icon_background: this.props.icon_background,
            noPadding: this.props.noPadding
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            title: nextProps.title,
            subtitle: nextProps.subtitle,
            open: nextProps.open,
            loading: nextProps.loading,
            icon: nextProps.icon,
            icon_color: nextProps.icon_color,
            icon_background: nextProps.icon_background
        });
    }

    init = {};

    functions = {};

    renders = {};

    render(){
        return (
            <section className="card__block mb-4 pb-4 md:mb-8 md:pb-8 border-b border-purple-200 border-opacity-25">
                <div className={
                    cx("card__block--inner",
                        {
                            ["card__block--inner--open"]: this.state.open,
                            [""]: true,
                        })
                }>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center">
                            { this.state.icon &&
                                <div className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                                    <FeatherIcon className={cx("stroke-current")} size={16} icon={this.state.icon}/>
                                </div>
                            }
                            <div>
                                <h3 className="text-2xl font-bold leading-none flex flex-row items-center">{this.state.title}
                                    {
                                        this.state.subtitle &&
                                        <span className="bg-gray-500 bg-opacity-25 text-gray-700 text-xs py-2 px-3 rounded-full ml-2">
                                            {this.state.subtitle}
                                        </span>
                                    }
                                </h3>
                            </div>
                        </div>
                        {
                            this.props.edit &&
                            <div onClick={() => this.props.onEdit()} className={
                                cx("py-1 px-1 text-xs font-bold rounded-full flex items-center justify-center cursor-pointer hover:bg-opacity-50",
                                    {
                                        ["bg-red-500 bg-opacity-25 text-red-500"]: this.state.open,
                                        ["bg-white"]: !this.state.open,
                                    })
                            } >
                                {this.state.open &&
                                    <span className="close w-6 h-6 bg-red-500 rounded-full flex justify-center items-center text-white">&#10005;</span>
                                }
                                {!this.state.open &&
                                    <span className="expand w-6 h-6 rounded-full flex justify-center items-center text-gray-900 bg-gray-200 text-base">+</span>
                                }
                                <span className="px-2">{this.state.open ? 'Close' : 'Expand'}</span>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className={
                            cx("mt-2",
                                {
                                    ["Accordion-InnerContainerInnerTopNoPadding"]: this.state.noPadding,
                                })
                        }>
                            {this.props.children}
                        </div>
                    </SlideDown>
                </div>
            </section>
        )
    }
}

export default AccordionFixed;
