import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils, convertFromHTML, ContentState } from 'draft-js';
import FeatherIcon from 'feather-icons-react';

import '../RichTextEditor/style.css';

const options = {
    inlineStyles: {
        // Override default element (`strong`).
        BOLD: { element: 'b' },
        ITALIC: {
            element: 'i',
            // Add custom attributes. You can also use React-style `className`.
            // attributes: {className: 'foo'},
            // Use camel-case. Units (`px`) will be added where necessary.
            style: { fontSize: 12 },
        },
    },
};

export default class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: this.initialState(this.props.data),
            htmlState: this.props.data ? this.props.data : '',
        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            const htmlState = stateToHTML(this.state.editorState.getCurrentContent(), options);
            this.setState({ editorState, htmlState: htmlState });
            this.props.onInput(htmlState);
        };

        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleHeadingType = this._toggleHeadingType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    // !!!
    initialState = (html) => {
        if (html && typeof html === 'string') {
            const blocksFromHTML = convertFromHTML(html);
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            return EditorState.createWithContent(state);
        } else {
            return EditorState.createEmpty();
        }
    };

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, this.state.editorState, 4 /* maxDepth */);
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleHeadingType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    }

    render() {
        const { editorState } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'py-3 h-full';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                {
                    !this.props.static &&
                    <div className="overflow-scroll">
                        <div className="flex flex-row overflow-scroll">
                            <HeadingStyleControls editorState={editorState} onToggle={this.toggleHeadingType} />
                            <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
                            <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
                        </div>
                    </div>
                }
                <div className={className} onClick={() => {
                    this.focus();
                }}>
                    <Editor
                        readOnly={this.props.static}
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        placeholder=""
                        ref="editor"
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        default:
            return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'h-10 w-10 mr-1 hover:bg-purple-200 rounded duration-200 cursor-pointer bg-purple-100 flex justify-center items-center font-medium';

        if (this.props.active) {
            className += ' text-purple-500';
        }

        if (this.props.label == "Quote") {
            className += ' px-2';
        } else {
            className += ' w-10';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.icon &&
                    <span>
                        <FeatherIcon className="stroke-current" size={16} icon={this.props.icon} />
                    </span>
                }
                {!this.props.icon &&
                    <span>{this.props.label}</span>
                }
            </span>
        );
    }
}

const HEADING_TYPES = [
    { label: 'P', style: 'unstyled' },
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
];
const HeadingStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="heading-control--wrapper">
            <div className={"heading-drop--wrapper relative flex flex-row"}>
                {
                    false &&
                    <div className="bg-purple-100 text-xs font-semibold rounded h-8 w-8 mr-1 flex flex-row items-center text-purple-900 justify-center">
                        <div className="">
                            {HEADING_TYPES.find(e => e.style === blockType)
                                ? HEADING_TYPES.find(e => e.style === blockType).label
                                : 'P'
                            }
                        </div>
                        {
                            false &&
                            <div className=""><FeatherIcon className="stroke-current ml-2" size={12} icon="chevron-down" /></div>
                        }
                    </div>
                }
                {HEADING_TYPES.map((type) => (
                    <StyleButton key={type.label} active={type.style === blockType} label={type.label} onToggle={props.onToggle} style={type.style} />
                ))}
                {
                    false &&
                    <div className={"heading-drop--drop absolute top-100 bg-purple-100 rounded-md flex flex-col right-0 text-xs w-full z-10 text-center"}>
                        {HEADING_TYPES.map((type) => (
                            <StyleButton key={type.label} active={type.style === blockType} label={type.label} onToggle={props.onToggle} style={type.style} />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

const BLOCK_TYPES = [
    { label: 'Quote', style: 'blockquote', icon: '' },
    { label: 'UL', style: 'unordered-list-item', icon: 'list' },
    { label: 'OL', style: 'ordered-list-item', icon: '' },
    { label: '</>', style: 'code-block', icon: '' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="flex flex-row text-xs items-stretch">
            {BLOCK_TYPES.map((type) => (
                <StyleButton key={type.label} active={type.style === blockType} label={type.label} icon={type.icon} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD', icon: 'bold' },
    { label: 'Italic', style: 'ITALIC', icon: 'italic' },
    { label: 'Underline', style: 'UNDERLINE', icon: 'underline' },
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="flex flex-row text-xs items-stretch">
            {INLINE_STYLES.map((type) => (
                <StyleButton key={type.label} active={currentStyle.has(type.style)} label={type.label} icon={type.icon} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};
