import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import cn from "classnames";
import TextAreaTailwind from './textAreaTailwind';

class EditGoogleAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            ad: {},
            client: {},
            updated: {},
            loader: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            client: this.props.client
        })
    }

    functions = {
        update: async () => {
            this.props.onError(false);
            this.props.onSuccess(false);
            this.props.onLoader(true);
            let body = {
                operations: [
                    {
                        "updateMask": "",
                        "update": {
                            resourceName: this.state.ad.resource_name
                        }
                    }
                ]
            }
            if (this.renders.type().value == "EXPANDED_TEXT_AD") {
                body.operations[0].updateMask += "final_urls";
                body.operations[0].update.finalUrls = [this.renders.finalUrl()];
                body.operations[0].update.expandedTextAd = {};
                if (this.renders.path1() !== "") {
                    body.operations[0].updateMask += ",expanded_text_ad.path1";
                    body.operations[0].update.expandedTextAd.path1 = this.renders.path1();
                }
                if (this.renders.path2() !== "") {
                    body.operations[0].updateMask += ",expanded_text_ad.path2";
                    body.operations[0].update.expandedTextAd.path2 = this.renders.path2();
                }
                this.renders.headlines().map((item, index) => {
                    body.operations[0].updateMask += ",expanded_text_ad.headlinePart" + (index + 1);
                    body.operations[0].update.expandedTextAd["headlinePart" + (index + 1)] = item.text;
                })
                this.renders.descriptions().map((item, index) => {
                    body.operations[0].updateMask += ",expanded_text_ad.description" + (index > 0 ? 2 : "");
                    body.operations[0].update.expandedTextAd["description" + (index > 0 ? 2 : "")] = item.text;
                })
            } else if (this.renders.type().value == "RESPONSIVE_SEARCH_AD") {
                body.operations[0].updateMask += "final_urls,responsive_search_ad.headlines,responsive_search_ad.descriptions";
                body.operations[0].update.finalUrls = [this.renders.finalUrl()];
                body.operations[0].update.responsiveSearchAd = {};
                if (this.renders.path1() !== "") {
                    body.operations[0].updateMask += ",responsive_search_ad.path1";
                    body.operations[0].update.responsiveSearchAd.path1 = this.renders.path1();
                }
                if (this.renders.path2() !== "") {
                    body.operations[0].updateMask += ",responsive_search_ad.path2";
                    body.operations[0].update.responsiveSearchAd.path2 = this.renders.path2();
                }
                body.operations[0].update.responsiveSearchAd.headlines = this.renders.headlines().map((item, index) => {
                    return { text: item.text }
                })
                body.operations[0].update.responsiveSearchAd.descriptions = this.renders.descriptions().map((item, index) => {
                    return { text: item.text }
                })
            }
            try {
                await this.calls.updateAd(body);
                this.props.onSuccess("Ad updated");
                this.props.onUpdate();
            } catch (error) {
                this.props.onError(error.body ? error.body.message : "Something went wrong");
            }
            this.props.onLoader(false);
        }
    };

    calls = {
        updateAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        type: () => {
            try {
                if (this.state.ad.advertising_type === 'RESPONSIVE_SEARCH_AD') {
                    return { id: 3, name: 'Responsive Search Ad', value: "RESPONSIVE_SEARCH_AD" }
                } else if (this.state.ad.advertising_type === 'EXPANDED_TEXT_AD') {
                    return { id: 3, name: 'Expanded Text Ad', value: "EXPANDED_TEXT_AD" }
                } else {
                    return { id: 1, name: 'Unknown', value: "UNKNOWN" }
                }
            } catch (error) {
                return { id: 1, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        status: () => {
            try {
                if ("status" in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.ad.status === 'UNSPECIFIED') {
                    return { id: 3, name: 'Unspecified', value: "UNSPECIFIED" }
                } else if (this.state.ad.status === 'UNKNOWN') {
                    return { id: 4, name: 'Unknown', value: "UNKNOWN" }
                } else if (this.state.ad.status === 'ENABLED') {
                    return { id: 1, name: 'Enabled', value: "ENABLED" }
                } else if (this.state.ad.status === 'PAUSED') {
                    return { id: 2, name: 'Paused', value: "PAUSED" }
                } else if (this.state.ad.status === 'REMOVED') {
                    return { id: 5, name: 'Removed', value: "REMOVED" }
                } else {
                    return { id: 0, name: 'Unknown', value: "UNKNOWN" }
                }
            } catch (error) {
                console.log(error);
                return { id: 0, name: 'Unknown', value: "UNKNOWN" }
            }
        },
        path1: () => {
            try {
                if ('path1' in this.state.updated) {
                    return this.state.updated.path1;
                } else if (this.state.ad.path1) {
                    return this.state.ad.path1;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        path2: () => {
            try {
                if ('path2' in this.state.updated) {
                    return this.state.updated.path2;
                } else if (this.state.ad.path2) {
                    return this.state.ad.path2;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        headlines: () => {
            return Array.isArray(this.state.ad.headlines) ? this.state.ad.headlines : [];
        },
        descriptions: () => {
            return Array.isArray(this.state.ad.descriptions) ? this.state.ad.descriptions : [];
        },
        finalUrl: () => {
            if ("final_url" in this.state.updated) {
                return this.state.updated.final_url;
            } else {
                return Array.isArray(this.state.ad.final_urls) && this.state.ad.final_urls.length > 0 ? this.state.ad.final_urls[0] : "";
            }
        },
        finalUrlDisplayPath: () => {
            if ("final_url" in this.state.updated) {
                return this.state.updated.final_url.replace("https://", "").replace("http://");
            } else {
                return Array.isArray(this.state.ad.final_urls) && this.state.ad.final_urls.length > 0 ? this.state.ad.final_urls[0].replace("https://", "").replace("http://") : "";
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    timeout = (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    render() {
        return (
            <>
                <div className="mt-5 grid grid-cols-3 gap-4 pb-8">

                    {/*Type*/}
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Type"}
                            locked={true}
                            selected={this.renders.type()}
                            options={[]}
                            onChange={(value) => {
                                this.setState({
                                    level: value
                                })
                            }}
                        />
                    </div>

                    {/*Status*/}
                    <div className="col-span-3">
                        <DropdownTailwind
                            label={"Status"}
                            selected={this.renders.status()}
                            options={[
                                { id: 1, name: 'Enabled', value: "ENABLED" },
                                { id: 2, name: 'Paused', value: "PAUSED" }
                            ]}
                            onChange={(value) => {
                                this.state.updated.status = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>

                    {/*Final URLS*/}
                    <div className="col-span-3">
                        <InputTailwind
                            label={"Final URL"}
                            value={this.renders.finalUrl()}
                            onChange={(value) => {
                                this.state.updated.final_url = value;
                                this.setState({
                                    updated: this.state.updated
                                })
                            }}
                        />
                    </div>

                    <div className="col-span-3">
                        <div>
                            <label htmlFor="company-website" className="block text-xs font-medium text-gray-700">
                                Display path
                            </label>
                            <div className="mt-1 flex flex-row rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                    {this.renders.finalUrlDisplayPath()}/
                                </span>
                                <input
                                    value={this.renders.path1()}
                                    onChange={(event) => {
                                        this.state.updated.path1 = event.target.value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="flex-1 min-w-0 border-r-0 block w-full px-3 py-2 rounded-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                                    placeholder="path 1"
                                />
                                <span className="inline-flex items-center px-3 rounded-none border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                    /
                                </span>
                                <input
                                    value={this.renders.path2()}
                                    onChange={(event) => {
                                        this.state.updated.path2 = event.target.value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                                    placeholder="path 2"
                                />
                            </div>
                        </div>
                    </div>

                    {/*Headlines*/}
                    {
                        this.renders.headlines().map((item, index) => {
                            return (
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Headline " + (index + 1)}
                                        rightLabel={true}
                                        rightLabelText={"Remove"}
                                        onRightLabelClick={() => {

                                        }}
                                        value={item.text}
                                        onChange={(value) => {
                                            item.text = value;
                                            this.setState({
                                                ad: this.state.ad
                                            })
                                        }}
                                    />
                                </div>
                            )
                        })
                    }

                    <div className="col-span-3">
                        <div onClick={() => {
                            if (Array.isArray(this.state.ad.headlines) && this.state.ad.headlines.length < 15) {
                                this.state.ad.headlines.push({ text: "" });
                            }
                            this.setState({
                                ad: this.state.ad
                            })
                        }} className="inline-flex flex-row items-center cursor-pointer">
                            <div className="bg-purple-100 rounded-full w-8 h-8 flex justify-center items-center">
                                <PlusIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                            </div>
                            <span className="text-sm font-semibold ml-2 text-gray-700 hover:text-purple-500">add headline</span>
                        </div>
                    </div>

                    {/*Descriptions*/}
                    {
                        this.renders.descriptions().map((item, index) => {
                            return (
                                <div className="col-span-3">
                                    <TextAreaTailwind
                                        label={"Description " + (index + 1)}
                                        value={item.text}
                                        onChange={(value) => {
                                            item.text = value;
                                            this.setState({
                                                ad: this.state.ad
                                            })
                                        }}
                                    />
                                </div>
                            )
                        })
                    }

                    <div className="col-span-3">
                        <div onClick={() => {
                            if (Array.isArray(this.state.ad.descriptions) && this.state.ad.descriptions.length < 15) {
                                this.state.ad.descriptions.push({ text: "" });
                            }
                            this.setState({
                                ad: this.state.ad
                            })
                        }} className="inline-flex flex-row items-center cursor-pointer">
                            <div className="bg-purple-100 rounded-full w-8 h-8 flex justify-center items-center">
                                <PlusIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                            </div>
                            <span className="text-sm font-semibold ml-2 text-gray-700 hover:text-purple-500">add description</span>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default EditGoogleAd;
