import React, { Component } from 'react';
import { apiRegister } from "../services/apiRegister";
import { clientRegister } from '../services/clientRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';

class LoginToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    };

    componentDidMount() {
        this.functions.login();
    }

    functions = {
        login: () => {
            this.calls.login({ 
                id: this.props.match.params.agent 
            }).then((response) => {
                tokenRegister.set(response.headers['x-auth-token'], response.headers['x-auth-refresh-token'], false);
                userRegister.set(response.data, false);
                clientRegister.remove();
                this.props.history.push('/v2/dashboard');
            }, (error) => {
                this.setState({
                    loading: false,
                    error: true
                });
            });
        }
    };

    calls = {
        login: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/loginAgent";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <>
                <div className="min-h-full min-w-full flex justify-center items-center">

                    {/*LOADING VIEW*/}
                    {
                        <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold mt-3">Loading ...</div>
                        </div>
                    }

                </div>
            </>
        )
    }
}

export default LoginToken;
