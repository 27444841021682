import React, {Component} from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";
import Datepicker from "../datepicker";
import FeatherIcon from "feather-icons-react";
import FacebookLocationMap from "../advertising-location-map";
import {calls} from "../advertising-location-regions/calls";

class CampaignEditLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            place: {},
            center: {lat: 40.64, lng: -73.96},
            zoom: 9
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            locations: this.props.locations?this.props.locations:[]
        }, () => {
            if(this.state.locations.length > 0){
                this.functions.locate(this.state.locations[0]);
            }
        })
    }

    init = {};

    functions = {
        locate: (item) => {
            if(item.custom){
                this.setState({
                    place: item
                })
            }else{
                this.functions.region(item);
            }
        },
        region: (region) => {
            calls.getRegion({
                region: encodeURI(region.id)
            }).then((response) => {
                region.paths = response.data.paths;
                this.setState({
                    place: region
                })
            }, (error) => {

            });
        }
    };

    renders = {
        paths: () => {
            try{
                return this.state.place.paths?this.state.place.paths:[];
            }catch  {
                return []
            }
        },
        places: () => {
            try{
                let regions = [];
                regions.push(this.state.place);
                return regions
            }catch  {
                return []
            }
        }
    };

    render() {
        return (
            <div className='campaign-edit-duration'>
                <div className='campaign-budget-duration-top'>
                    LOCATIONS
                </div>
                <div className="campaign-budget-duration-inner">
                    <div className="Section-InnerTitle">
                        Selected locations
                    </div>
                    <div className="Locations-Items">
                        {
                            this.state.locations.map((item, index) => {
                                return (
                                    <div className="Site-Item" key="index">
                                        <div onClick={() => {
                                            this.functions.locate(item);
                                        }} className="Location-Item">
                                            <div className="Location-ItemRight">
                                                <FeatherIcon color={item.name === this.state.place.name?"#0185fe":'#fcfcfc'} size={23} icon="map-pin"/>
                                            </div>
                                            <div className="Location-ItemLeft">
                                                {item.name}
                                                {
                                                    item.custom &&
                                                    <span className="Location-ItemLeftRadius">{Math.round(item.radius/1000)}km radius</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        <div className="Section">
                            <div className="Campaign-item-edit-map">
                                {
                                    (this.renders.paths().length > 0 || this.state.place.custom) &&
                                    <FacebookLocationMap
                                        center={this.state.center}
                                        zoom={this.state.zoom}
                                        place={this.state.place}
                                        places={this.renders.places()}
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                        loadingElement={<div style={{ height: `100%`, width: '100%'}} />}
                                        containerElement={<div style={{ height: `500px` ,width: '100%', borderRadius: '3px', overflow: 'hidden', border: '1px solid #e7e7e7'}}/>}
                                        mapElement={<div style={{ height: `100%` ,width: '100%'}} />}
                                    />
                                }
                                {
                                    (this.renders.paths().length < 1 && !this.state.place.custom) &&
                                    <div className="Campaign-item-edit-map-loader">
                                        Loading map
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignEditLocation;
