import React, { Component } from 'react';
import cn from "classnames";

class CopyButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyText: props.copyText,
            disabled: props.disabled,
            buttonText: props.buttonText || 'Copy',
            copied: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.copyText !== prevProps.copyText) {
            this.setState({ copyText: this.props.copyText, disabled: this.props.disabled });
        }
    }

    handleCopy = () => {
        console.log(this.state)
        navigator.clipboard.writeText(this.state.copyText);
        this.setState({
            copied: true,
            buttonText: "Copied!"
        });

        setTimeout(() => {
            this.setState({
                copied: false,
                buttonText: this.props.buttonText || 'Copy'
            });
        }, 3000);
    }

    render() {
        return (
            <button
                className={
                    cn(
                        this.state.copied ? '' : (this.state.disabled ? "bg-gray-500 cursor-not-allowed" : "bg-custom-input"),
                        "mt-5.5 ml-2 relative justify-center border-1.5 rounded-md h-12 w-32 text-sm font-medium"
                    )
                }
                disabled={this.state.disabled}
                onClick={() => this.handleCopy()}
            >
                {this.state.buttonText}
            </button>
        )
    }
}

export default CopyButton;