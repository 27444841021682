import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, ChevronRightIcon, HomeIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { clientRegister } from '../services/clientRegister';

class CreateOrderClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 15,
            page: 1,
            sort: "name",
            direction: "ascending",
            search: "",
            clients: [],
            client: {},
            order: {},
            filter_client: {}
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.client();
        this.form.get();
    }

    form = {
        set: () => {
            orderRegister.set("client", this.state.client);
            orderRegister.set("agents", Array.isArray(this.state.client.agents) ? this.state.client.agents : []);
        },
        get: () => {
            if (orderRegister.get()) {
                this.setState({
                    client: orderRegister.get()["client"] ? orderRegister.get()["client"] : this.state.client,
                    order: orderRegister.get()["order"] ? orderRegister.get()["order"] : this.state.order
                })
            }
        }
    };

    functions = {
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getClients();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        client: async () => {
            await this.promisedSetState({
                filter_client: clientRegister.get()
            });
            this.functions.getClients(true);
        }
    };

    calls = {
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?include_agents=true&limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative h-full">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                orderRegister.remove();
                                if (this.state.order && this.state.order.id) {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                } else {
                                    this.props.history.push("/v2/orders/active");
                                }
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.getClients();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.getClients();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.getClients(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        search={this.state.search}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={true}
                        hideUserDropdown={true}
                        breadcrumb={"Clients"}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", loading: false, show: !this.state.loading, red: true }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                        includeAgents={true}
                        client={this.state.client.id ? { name: this.state.client.name, image: this.state.client.logo } : this.state.filter_client}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client: {},
                                filter_client: client
                            });
                            this.form.set();
                            this.functions.getClients();
                            //this.props.updateStatistics(client);
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading clients...</div>
                    </div>
                }

                {/*SECTION: SELECT CLIENT*/}
                {
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="shadow bg-white rounded-lg flex flex-col relative">

                            {/*LOADER PARTIAL*/}
                            {
                                this.state.loading_partial &&
                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }

                            <div className="flex flex-1 p-6">
                                <div className="w-full">
                                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-5">
                                        {this.state.clients.map((client, index) => (
                                            <div
                                                onClick={async () => {
                                                    await this.promisedSetState({
                                                        client: client,
                                                        filter_client: client
                                                    });
                                                    clientRegister.set(client);
                                                    this.form.set();
                                                    this.props.history.push("/v2/orders/new/information");
                                                }}
                                                key={index}
                                                className={(this.state.client.id === client.id ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " relative cursor-pointer rounded-lg border-1.5 p-6 shadow-sm flex-col flex items-center"}
                                            >
                                                <div className="h-16 w-16 rounded-full overflow-hidden flex items-center justify-center border bg-white p-2 mb-4">
                                                    <div>
                                                        <img className="w-full" src={client.logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="text-sm font-medium text-gray-900 text-center">
                                                    {client.name}
                                                </div>
                                                <div className="text-sm font-medium text-gray-500 text-center">
                                                    {client.company}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-4 flex flex-row">
                            <div className="flex flex-1"></div>
                            <div className="flex justify-center items-center">
                                <div className="">
                                    <button
                                        onClick={async () => {
                                            if (this.state.client.id) {
                                                this.props.history.push("/v2/orders/new/information");
                                            }
                                        }}
                                        className={(this.state.client.id ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <span>Next: Information</span>
                                        <ArrowRightIcon className="ml-2 h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default CreateOrderClient;