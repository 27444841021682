import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CSVReader from 'react-csv-reader'
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import {SlideDown} from "react-slidedown";
import Accordion from "../accordion";
import AdvertisingKeywordsNegativeAsset from "../advertising-keywords-negative-asset";

class AdvertisingKeywordsNegativeAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {},
            theInputKey: "",
            main: "",
            exact: true,
            phrase: false,
            broad: false,
            campaign: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            keywords: nextProps.keywords,
            campaign: nextProps.campaign,
        });
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            keywords: this.props.keywords,
            campaign: this.props.campaign,
        })
    }

    init = {};

    functions = {
        add: (keyword, extras) => {
            if (typeof extras !== "object") {
                extras = {};
            }
            if (keyword !== "" && keyword) {
                if (this.state.exact || extras.exact) {
                    let id = Math.floor((Math.random() * 9999999999) + 1);
                    let main = {
                        id: id,
                        campaign_id: this.state.campaign.id,
                        name: keyword,
                        exact: true,
                        broad: false,
                        phrase: false,
                    };
                    main.index = this.state.keywords.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    }).length + 1;
                    this.state.keywords.unshift(main);
                }
                if (this.state.phrase || extras.phrase) {
                    let id = Math.floor((Math.random() * 9999999999) + 1);
                    let main = {
                        id: id,
                        campaign_id: this.state.campaign.id,
                        name: keyword,
                        exact: false,
                        broad: false,
                        phrase: true,
                    };
                    main.index = this.state.keywords.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    }).length + 1;
                    this.state.keywords.unshift(main);
                }
                if (this.state.broad || extras.broad) {
                    let id = Math.floor((Math.random() * 9999999999) + 1);
                    let main = {
                        id: id,
                        campaign_id: this.state.campaign.id,
                        name: keyword,
                        exact: false,
                        broad: true,
                        phrase: false,
                    };
                    main.index = this.state.keywords.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    }).length + 1;
                    this.state.keywords.unshift(main);
                }
                this.props.onChange(this.state.keywords);
                this.setState({
                    main: "",
                    exact: true,
                    phrase: false,
                    broad: false
                });
            }
        },
        upload: (list) => {
            try {
                for (let i = 0; i < list.length; i++) {
                    let item = {
                        keyword: "",
                        campaign_id: this.state.campaign.id,
                        exact: true,
                        phrase: false,
                        broad: false,
                    };
                    for (let key in list[i]) {
                        if (key === 'keyword') {
                            item.keyword = list[i].keyword;
                        }
                        if (key === 'exact') {
                            item.exact = list[i].exact;
                        }
                        if (key === 'phrase') {
                            item.phrase = list[i].phrase;
                        }
                        if (key === 'broad') {
                            item.broad = list[i].broad;
                        }
                    }
                    this.functions.add(item.keyword, item);
                }
                const randomString = Math.random().toString(36);
                this.setState({
                    theInputKey: randomString,
                });
            } catch (e) {
                //console.log(e);
            }
        }
    };

    validators = {};

    renders = {};

    render() {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <div className="flex flex-row mr-2">
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Exact
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.exact}
                                    disabled={!this.state.phrase && !this.state.broad}
                                    onChange={(checked) => {
                                        this.setState({
                                            exact: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Phrase
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.phrase}
                                    disabled={!this.state.exact && !this.state.broad}
                                    onChange={(checked) => {
                                        this.setState({
                                            phrase: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Broad
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.broad}
                                    disabled={!this.state.exact && !this.state.phrase}
                                    onChange={(checked) => {
                                        this.setState({
                                            broad: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1">
                        <Input
                            noTitle={false}
                            title={"Keyword"}
                            type={'text'}
                            placeholder={'Add keyword text here'}
                            value={this.state.main}
                            size={'full'}
                            onChange={(event) => {
                                this.setState({main: event.target.value})
                            }}
                            showKeyDown={true}
                            onKeyDown={(value) => {
                                if (value === 13) {
                                    this.functions.add(this.state.main, {});
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end flex-1 mt-4 px-2">
                    <div>
                        <button key={this.state.theInputKey || ''} className="w-48 relative w-full px-3 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                            Upload keywords
                            <CSVReader
                                inputStyle={{
                                    opacity: 0,
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: '100%',
                                    backgroundColor: 'red'
                                }}
                                parserOptions={{
                                    header: true,
                                    dynamicTyping: true,
                                    skipEmptyLines: true,
                                    transformHeader: header =>
                                        header
                                            .toLowerCase()
                                            .replace(/\W/g, '_')
                                }}
                                onFileLoaded={(data, fileInfo) => {
                                    this.functions.upload(data);
                                }}/>
                        </button>
                    </div>
                    <div>
                        <button onClick={() => {
                            this.functions.add(this.state.main);
                        }} className="w-48 ml-2 w-full px-3 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                            Add keyword
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingKeywordsNegativeAdd;
