import React, { Component } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/solid'

import { AnnotationIcon, HeartIcon, PaperAirplaneIcon, BookmarkIcon, ChevronRightIcon } from '@heroicons/react/outline';

class PreviewInstagramFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            picture: null,
            body: "",
            title: "",
            cta: {},
            video: null,
            adData: {},
            for_report: false,
            thumbnail: null,
        }
    };


    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            picture: this.props.picture,
            body: this.props.body,
            title: this.props.title,
            cta: this.props.cta,
            video: this.props.video,
            adData: this.props.adData,
            for_report: this.props.for_report,
            thumbnail: this.props.thumbnail,
        })

        setTimeout(() => {
            try {
                if (this.refs.videoOne) {
                    this.refs.videoOne.setAttribute("muted", true);
                }
                if (this.refs.videoTwo) {
                    this.refs.videoTwo.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error);
            }
        }, 200);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            picture: nextProps.picture,
            body: nextProps.body,
            title: nextProps.title,
            cta: nextProps.cta,
            video: nextProps.video,
            adData: nextProps.adData,
            for_report: nextProps.for_report,
            thumbnail: nextProps.thumbnail,
        })
    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    init = {};

    renders = {
        title: () => {
            try {
                let headline = this.state.title !== "" ? this.state.title : "Headline ...";
                headline = headline.replace("{location}", this.state.location);
                return headline;
            } catch (e) {
                return ""
            }
        },
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                body = body.replace("{location}", this.state.location);

                const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                body = body.replace(regex, '<br>');
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }

                return body;
            } catch (e) {
                return ""
            }
        },
        description: () => {
            try {
                let description = this.state.description !== "" ? this.state.description : "";
                description = description.replace("{location}", this.state.location);
                return description
            } catch (e) {
                return ""
            }
        },
        ratioCheck: () => {
            try {
                if (this.state.adData.ad_type.value !== "merge_ad") {
                    if (this.state.adData.file.ratio === "9:16") {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } catch (e) {
                return false
            }

        },
        image: () => {
            try {
                return this.state.image
            } catch (e) {
                return ""
            }
        },
        cover: () => {
            try {
                return this.state.facebook_event.cover.source
            } catch (e) {
                return ""
            }
        },
        media: () => {
            try {
                if (this.state.creatives[0].instagram_post) {
                    return this.state.creatives[0].instagram_post.media_url
                } else {
                    return this.state.creatives[0].facebook_post.full_picture
                }
            } catch (e) {
                return ""
            }
        },
        url: () => {
            try {
                return this.state.creatives[0].display_website !== "" ? this.state.creatives[0].display_website : this.state.creatives[0].website
            } catch (e) {
                return "example.com"
            }
        },
        callToAction: () => {
            try {
                if (this.props.linkedin) {
                    if (this.state.creatives[0].call_to_action_linkedin.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.state.creatives[0].call_to_action_linkedin.name;
                    }
                } else {
                    if (this.state.cta.value === 'NO_BUTTON') {
                        return null
                    } else {
                        return this.cta.name;
                    }
                }
            } catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div
                className='flex flex-col justify-center'
            >
                <div className='w-80 pt-2 pb-12 rounded border shadow-xl bg-white'>
                    <div className='flex justify-between mx-3 items-center pb-2'>
                        <div className='flex items-center'>
                            <div className='mr-1'>
                                {
                                    this.state.page.image &&
                                    <img src={this.state.page.image} alt={this.state.page.name ? this.state.page.name : "Alt text"} className='border h-10 w-10 rounded-full' />
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='border rounded-full h-10 w-10'></div>
                                }
                            </div>
                            <div className='flex flex-col text-xs font-semibold'>
                                {this.state.page.name ? this.state.page.name : "Page name"} <span className='font-normal text-xxs text-gray-700'>Sponsored</span>
                            </div>
                        </div>
                        <div><DotsHorizontalIcon className='w-5' /></div>
                    </div>
                    <div>
                        {
                            this.state.picture && <img src={this.state.picture} alt={this.state.title ? this.state.title : "Alt text"}
                                style={this.renders.ratioCheck() ? { maxHeight: 318 } : { height: "100%" }}
                                className="w-full object-cover"
                            />
                        }
                        {!this.state.for_report && this.state.video &&
                            <video src={this.state.video} autoplay={false} controls={true} muted alt={this.state.name} className="h-full w-full object-cover"
                                style={this.renders.ratioCheck() ? { maxHeight: 318 } : { height: "100%" }}
                                ref="videoOne"
                            />
                        }
                        {this.state.for_report && this.state.video &&
                            <video src={this.state.video} autoplay={false} controls={true} muted alt={this.state.name} className="h-full w-full object-cover"
                                style={{ maxHeight: 318 }}
                                ref="videoTwo"
                            />
                        }
                        {
                            this.state.for_report && this.state.video === null && this.state.thumbnail !== null &&
                            <div style={{ height: 318 }}>
                                <img src={this.state.thumbnail} alt={this.state.name} className='w-full h-full object-cover' />
                            </div>
                        }
                    </div>
                    <div className='flex justify-between items-center text-sm font-semibold mx-3 py-3 border-b border-gray-200'>
                        {this.state.cta ? this.state.cta.name : ''}
                        <span><ChevronRightIcon className='w-5' /></span>
                    </div>
                    <div className='flex justify-between mx-3 pt-1 pb-2 items-center'>
                        <div className='flex space-x-4 items-center'>
                            <div><HeartIcon className='w-6' /></div>
                            <div><AnnotationIcon className='w-6' /></div>
                            <div><PaperAirplaneIcon style={{ transform: "rotate(60deg)" }} className='w-6 pb-3' /></div>
                        </div>
                        <div><BookmarkIcon className='w-6' /></div>
                    </div>
                    <div className='text-xs mx-3'>
                        <span className='font-semibold mr-1'>{this.state.page.name ? this.state.page.name : "Page name"}</span>
                        <span>
                            {
                                this.state.body !== "" ?
                                    <span dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="whitespace-pre-wrap">

                                    </span>
                                    :
                                    "Body ..."
                            }
                            {
                                !this.state.read_more &&
                                this.renders.body().length > 125 &&
                                <span
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            read_more: true
                                        });
                                    }} className="cursor-pointer font-semibold text-xs text-center pb-1">
                                    {" "}Read more
                                </span>
                            }
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewInstagramFeed;