import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon, MenuIcon, PlayIcon, SearchIcon, StarIcon, TrashIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

class PreviewGooglePerformanceMaxDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: {},
            videos: [],
            images: [],
            headlines: [],
            descriptions: [],
            long_headlines: [],
            business: "",
            website: "",
            cta: {}
        }
    };

    componentDidMount() {
        this.setState({
            videos: this.props.videos,
            images: this.props.images,
            headlines: this.props.headlines,
            descriptions: this.props.descriptions,
            long_headlines: this.props.long_headlines,
            business: this.props.business,
            website: this.props.website,
            cta: this.props.cta
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            videos: nextProps.videos,
            images: nextProps.images,
            headlines: nextProps.headlines,
            descriptions: nextProps.descriptions,
            long_headlines: nextProps.long_headlines,
            business: nextProps.business,
            website: nextProps.website,
            cta: nextProps.cta
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + (chars > 30 ? " ..." : "");
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "400px" }} className={"w-full border rounded-md bg-white"}>
                <div className="h-10 w-full flex flex-row bg-gray-200 items-center">
                    <div className="px-4">
                        <MenuIcon className="w-5 h-5" />
                    </div>
                    <div className="flex flex-1 justify-center items-center">
                        <div className="h-2 bg-gray-500 w-32"></div>
                    </div>
                    <div className="px-4">
                        <SearchIcon className="w-5 h-5" />
                    </div>
                </div>
                <div className="px-2 pt-3 mb-3">
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                </div>
                <div className="px-4">
                    <div className="w-full bg-gray2-500 relative">
                        {
                            Array.isArray(this.state.images) &&
                            this.state.images.filter((item) => {
                                return item.type === "portrait"
                            }).map((item, index) => {
                                if (index === 0) {
                                    return (
                                        <img className="w-full" src={item.url} />
                                    )
                                } else {
                                    return ("");
                                }
                            })
                        }
                        <div className="absolute flex flex-col top-0 bottom-0 left-0 right-0">
                            <div className="flex flex-1"></div>
                            <div className="p-2 bg-gray-500 bg-opacity-75">
                                <div className="text-lg text-white font-bold mb-1">
                                    {Array.isArray(this.state.headlines) && this.state.headlines.length > 0 ? this.state.headlines[0].text : "Headline"}
                                </div>
                                <div className="w-full flex flex-row items-center">
                                    <div className="h-12 w-12 bg-white">
                                        {
                                            Array.isArray(this.state.images) &&
                                            this.state.images.filter((item) => {
                                                return item.type === "logo_square"
                                            }).map((item, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <img className="w-full" src={item.url} />
                                                    )
                                                } else {
                                                    return ("");
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="text-sm text-white ml-2">{Array.isArray(this.state.descriptions) && this.state.descriptions.length > 0 ? this.state.descriptions[0].text : "Description"}</div>
                                    <div className="flex flex-1"></div>
                                    <div>
                                        <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center">
                                            <ChevronRightIcon className="w-5 h-5 text-blue-700" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 pt-3">
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                </div>
            </div>
        )
    }
}

export default PreviewGooglePerformanceMaxDisplay;
