import React, { Component, Fragment } from 'react';
import { ExclamationCircleIcon, UploadIcon } from '@heroicons/react/outline'
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';

class UploadVideoTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            client: {},
            error: false,
            disabled: false,
            channel: "",
            page: {}
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            error: this.props.error,
            client: this.props.client,
            disabled: this.props.disabled,
            channel: this.props.channel,
            page: this.props.page
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            error: nextProps.error,
            client: nextProps.client,
            disabled: nextProps.disabled,
            channel: nextProps.channel,
            page: nextProps.page
        })
    }

    functions = {
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                const formData = new FormData();
                if (Object.keys(files).length > 0) {
                    formData.append('file', files["0"]);
                    try {
                        await this.promisedSetState({ loader: true });
                        let result = await this.calls.upload(formData);
                        if (this.props.onChange) {
                            this.props.onChange(result.data);
                        }
                        await this.promisedSetState({ loader: false });
                    } catch (error) {
                        await this.promisedSetState({ loader: false, error: true });
                    }
                }
            }
        }
    };

    calls = {
        upload: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&" + this.state.channel + "=true";
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div>
                <label className="block text-xs font-medium text-gray-700">
                    {this.props.label}
                </label>
                <div className="mt-1 relative flex flex-row rounded-md">
                    {
                        this.props.leftSection &&
                        <span className="inline-flex items-center whitespace-no-wrap px-3 rounded-l-md border border-r-0 border-gray-300 sm:text-sm">
                            {this.props.leftSectionLabel}
                        </span>
                    }
                    <div className={(this.state.error ? " border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 " : " border-estatery-400 ") + " overflow-hidden rounded-md border-1.5 flex flex-row justify-center items-center w-full focus:outline-none sm:text-sm"}>
                        <div className="h-full w-12 border-r bg-gray-100 flex justify-center items-center">
                            <UploadIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                        <div className="flex font-medium flex-1 relative justify-center items-center px-4 py-3">
                            {
                                !this.props.buttonText &&
                                <span className="text-sm">Click here to upload</span>
                            }
                            {
                                this.props.buttonText &&
                                <span>{this.props.buttonText}</span>
                            }
                            <input
                                onChange={(e) => this.functions.onSelectFile(e)}
                                type="file"
                                multiple
                                accept={"video/*"}
                                className="absolute top-0 bottom-0 opacity-0 right-0 left-0 bg-red-500"
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                            />
                            {
                                this.state.loader &&
                                <div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    }
                </div>
                {
                    this.state.error &&
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {this.state.errorMessage}
                    </p>
                }
            </div>
        )
    }
}

export default UploadVideoTailwind;
