import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { clientRegister } from '../services/clientRegister';
import { ArrowRightIcon, CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import moment from 'moment';
import TextAreaTailwind from '../moduleFiles/textAreaTailwind';
import InputTimepickerTailwind from '../moduleFiles/inputTimepickerTailwind';
import translations from '../assets/json/onboarding_translation.json'


class ClientOrderStepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: false,
            steps: [
                { id: 1, name: "Step 1", complete: false, current: true, type: "step" },
                { id: 2, name: "", complete: false, current: false, type: "divider" },
                { id: 3, name: "Step 2", complete: false, current: false, type: "step" },
                { id: 4, name: "", complete: false, current: false, type: "divider" },
                { id: 5, name: "Step 3", complete: false, current: false, type: "step" },
                { id: 6, name: "", complete: false, current: false, type: "divider" },
                { id: 7, name: "Step 4", complete: false, current: false, type: "step" },
                { id: 8, name: "", complete: false, current: false, type: "divider" },
                { id: 9, name: "Step 5", complete: false, current: false, type: "step" },
                { id: 10, name: "", complete: false, current: false, type: "cancel" }
            ],
            name: "",
            note: "",
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: null,
            order: {},
            whitelabel_settings: {}
        }
    };

    componentDidMount() {
        this.init.user();

        this.setState({
            order: this.props.order ? this.props.order : {},
        });
    }

    functions = {};

    renders = {};

    calls = {};

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = window.location.pathname.split("/")[3].split("-")[0]

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {
        let t = this.translate;
        let order = this.state.order;
        return (
            <>
                {/* PROGRESS BAR */}
                <div className="relative flex  w-full h-16 bg-white justify-center items-center">
                    <div className='flex space-x-1 items-center'>
                        {
                            this.state.steps.map((step, index) => {
                                return (
                                    <div className='col-span-1'>
                                        {step.type === "step" &&
                                            <div className='flex flex-col items-center'>
                                                <div className={'flex justify-center text-center items-center text-xs text-gray-600 w-5 h-5 rounded-full ' + (step.current ? 'bg-white border border-gray-600' : (step.complete ? 'bg-green-400' : 'bg-gray-300'))}>
                                                    {
                                                        step.complete ?
                                                            <CheckCircleIcon className="h-full w-full text-white" /> :
                                                            <span>{step.name.replace("Step", "")}</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {step.type === "divider" &&
                                            <div className='hidden md:flex justify-center items-center'>
                                                <div className={'w-8 ' + (step.current ? 'bg-black' : (step.complete ? 'bg-green-500' : 'bg-gray-300'))} style={{ height: 2 }}></div>
                                            </div>
                                        }
                                        {
                                            step.type === "cancel" &&
                                            <div className='absolute flex justify-center items-center top-0 bottom-0 right-0 mr-4'>
                                                <div className='bg-red-500 hover:bg-red-400 rounded-full p-1 md:p-2 cursor-pointer'
                                                    onClick={() => { this.props.history.push('/client') }}>
                                                    <XIcon className='w-5 h-5 text-white' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* CONTENT */}
                <div className='flex flex-col h-full p-4'>
                    <div className='flex flex-col justify-between h-full bg-white rounded-md shadow p-4 relative'>
                        <div className='flex flex-col space-y-4'>

                            <InputTailwind
                                label={t("order_name")}
                                value={order.name}
                                onChange={async (value) => {
                                    this.state.order.name = value;
                                    await this.promisedSetState({
                                        order: this.state.order,
                                        name: value
                                    })
                                }}
                            />

                            <div className='grid md:grid-cols-4 gap-4'>
                                <InputDatepickerTailwind
                                    label={t("start_date")}
                                    value={this.state.startDate}
                                    onChange={(value) => {
                                        this.state.order.startDate = value;
                                        this.promisedSetState({
                                            order: this.state.order,
                                            startDate: value
                                        })
                                    }}
                                />

                                <InputTimepickerTailwind
                                    label={t("start_time")}
                                    loading={this.state.loading_start_time}
                                    value={moment(this.state.order.startDate).format("HH:mm")}
                                    onChange={async (value) => {
                                        let date = moment(this.state.order.startDate).format("YYYY-MM-DD");
                                        let new_date = date + " " + value;
                                        this.state.order.startDate = moment(new_date);
                                        await this.promisedSetState({
                                            order: this.state.order
                                        })
                                        if (moment(this.state.order.startDate).format("HH:mm") !== moment(this.state.org_order.startDate).format("HH:mm")) {
                                            this.functions.updateStartTime();
                                        }
                                    }}
                                />

                                <InputDatepickerTailwind
                                    label={t("end_date")}
                                    disabled={!this.state.endDate}
                                    labelRight={this.state.endDate ? "Disable" : "Enable"}
                                    placeholder={!this.state.endDate ? "No end date" : ""}
                                    value={moment(this.state.endDate).format("YYYY-MM-DD")}
                                    onChange={async (value) => {
                                        let time = moment(this.state.endDate).format("HH:mm");
                                        let new_date = value + " " + time;
                                        await this.promisedSetState({
                                            endDate: moment(new_date)
                                        })
                                    }}
                                    onLabelRightClick={async (value) => {
                                        if (this.state.endDate) {
                                            this.state.endDate = null;
                                        } else {
                                            let new_date = moment(this.state.startDate).add(1, 'days');
                                            this.state.endDate = new_date;
                                        }
                                        await this.promisedSetState({
                                            endDate: this.state.endDate
                                        });
                                    }}
                                />

                                <InputTimepickerTailwind
                                    label={t("end_time")}
                                    placeholder={!this.state.endDate ? "No end time" : ""}
                                    disabled={!this.state.endDate}
                                    value={moment(this.state.endDate).format("HH:mm")}
                                    onChange={async (value) => {
                                        let date = moment(this.state.endDate).format("YYYY-MM-DD");
                                        let new_date = date + " " + value;
                                        await this.promisedSetState({
                                            endDate: moment(new_date)
                                        })
                                    }}
                                />

                            </div>

                            <TextAreaTailwind
                                label={t("notes")}
                                rows={3}
                                value={this.state.note}
                                onChange={async (value) => {
                                    this.state.order.note = value;
                                    await this.promisedSetState({
                                        order: this.state.order,
                                        note: value
                                    })
                                }}
                            />
                        </div>

                        <div className='block mt-4 md:flex md:w-full md:justify-end'>
                            <div
                                onClick={() => { this.props.history.push('/client/order/step-two') }}
                                style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white'
                            >
                                <span
                                    style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                    {(t("next"))}
                                </span>
                                <ArrowRightIcon
                                    style={{ color: this.state.whitelabel_settings.color_font !== "" ? "#" + this.state.whitelabel_settings.color_font : "#ffffff" }}
                                    className='w-5 h-5 ml-2'
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </>
        );
    }
}


export default ClientOrderStepOne;
