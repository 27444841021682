import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import Input from "../../components/input";
import TextArea from "../../components/text-area";
import Dropdown from "../../components/dropdown";
import Loader from "../../components/loader";

class UserRequestsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: {
                facebook: {
                    active: false,
                    budget: 0
                },
                linkedin: {
                    active: false,
                    budget: 0
                },
                instagram: {
                    active: false,
                    budget: 0
                },
                google: {
                    active: false,
                    budget: 0
                }
            },
            uploads: [],
            locations: [],
            steps: {
                first: {
                    active: true
                },
                second: {
                    active: false
                },
                third: {
                    active: false
                },
                fourth: {
                    active: false
                },
                fifth: {
                    active: false
                },
                sixth: {
                    active: false
                },
            },
            loading: false,
            age_min: {},
            age_max: {},
            description: "",
            age_min_options: [],
            age_max_options: []
        };
    };

    componentWillMount() {

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.init.data();
    }

    init = {
        data: () => {
            this.setState({
                age_min_options: this.renders.age(18, this.state.age_max.value ? this.state.age_max.value : 65),
                age_max_options: this.renders.age(this.state.age_min.value ? (this.state.age_min.value + 1) : 18, 66)
            })
        }
    };

    functions = {
        send: () => {
            this.setState({
                loading: true
            }, () => {
                let object = {
                    channels: this.state.channels,
                    content: [],
                    targeting: {
                        gender: "",//this.state.gender.value,
                        age_min: "",//this.state.age_min.value,
                        age_max: "",//this.state.age_max.value,
                        website: this.state.website,
                        description: this.state.description
                    },
                    budget: this.state.channels
                };
                calls.createRequest(object).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.history.push('/user/requests/all');
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    }, () => {

                    })
                });
            });
        },
        next: () => {
            if(this.state.steps.first.active){
                this.state.steps.first.active = false;
                this.state.steps.second.active = true;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.second.active){
                this.state.steps.second.active = false;
                this.state.steps.third.active = true;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.third.active){
                this.state.steps.third.active = false;
                this.state.steps.fourth.active = true;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.fourth.active){
                this.state.steps.fourth.active = false;
                this.state.steps.fifth.active = true;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.fifth.active){
                this.state.steps.fifth.active = false;
                this.state.steps.sixth.active = true;
                this.setState({
                    steps: this.state.steps
                })
            }
        },
        back: () => {
            if(this.state.steps.second.active){
                this.state.steps.first.active = true;
                this.state.steps.second.active = false;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.third.active){
                this.state.steps.second.active = true;
                this.state.steps.third.active = false;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.fourth.active){
                this.state.steps.third.active = true;
                this.state.steps.fourth.active = false;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.fifth.active){
                this.state.steps.fourth.active = true;
                this.state.steps.fifth.active = false;
                this.setState({
                    steps: this.state.steps
                })
            }else if(this.state.steps.sixth.active){
                this.state.steps.fifth.active = true;
                this.state.steps.sixth.active = false;
                this.setState({
                    steps: this.state.steps
                })
            }
        },
        uploadImage: (image) => {
            this.setState({
                src: null
            }, () => {
                const formData = new FormData();
                formData.append('file', image);
                let id = Math.floor((Math.random() * 9999999999) + 1);
                this.state.uploads.push({
                    id: id,
                    url: null,
                    image: true,
                    error: false,
                    loading: true,
                    button: false
                });
                this.setState({
                    content: this.state.uploads
                }, () => {
                    calls.uploadImage(formData).then((response) => {
                        for (let i = 0; i < this.state.uploads.length; i++) {
                            if (this.state.uploads[i].id === id) {
                                this.state.uploads[i].url = response.data.url;
                                this.state.uploads[i].format = response.data.format;
                                this.state.uploads[i].ratio = response.data.ratio;
                                this.state.uploads[i].loading = false;
                                break;
                            }
                        }
                        this.setState({
                            uploads: this.state.uploads
                        });
                    }, (error) => {
                        for (let i = 0; i < this.state.uploads.length; i++) {
                            if (this.state.uploads[i].id === id) {
                                this.state.uploads[i].error = true;
                                this.state.uploads[i].error_message = error.body.message;
                                this.state.uploads[i].loading = false;
                                break;
                            }
                        }
                        this.setState({
                            uploads: this.state.uploads
                        });
                    });
                });

            });
        },
        uploadVideo: (video) => {
            const formData = new FormData();
            formData.append('file', video);
            let id = Math.floor((Math.random() * 9999999999) + 1);
            this.state.uploads.push({
                id: id,
                url: null,
                video: true,
                error: false,
                loading: true,
                button: false
            });
            this.setState({
                uploads: this.state.uploads
            }, () => {
                calls.uploadVideo(formData).then((response) => {
                    for (let i = 0; i < this.state.content.length; i++) {
                        if (this.state.uploads[i].id === id) {
                            this.state.uploads[i].url = response.data.url;
                            this.state.uploads[i].format = response.data.format;
                            this.state.uploads[i].ratio = response.data.ratio;
                            this.state.uploads[i].loading = false;
                            break;
                        }
                    }
                    this.setState({
                        uploads: this.state.uploads
                    });
                }, (error) => {
                    for (let i = 0; i < this.state.uploads.length; i++) {
                        if (this.state.uploads[i].id === id) {
                            this.state.uploads[i].error = true;
                            this.state.uploads[i].error_message = error.body.message;
                            this.state.uploads[i].loading = false;
                            break;
                        }
                    }
                    this.setState({
                        uploads: this.state.uploads
                    });
                });
            });
        },
        onSelectFile: (e) => {
            if (e.target.files && e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    if (e.target.files[i].type === 'video/mp4') {
                        this.functions.uploadVideo(e.target.files[i]);
                    } else if (e.target.files[i].type === 'image/jpg' || e.target.files[i].type === 'image/jpeg' || e.target.files[i].type === 'image/png') {
                        this.functions.uploadImage(e.target.files[i]);
                    }
                }
            }
        },
        remove: (item) => {
            for (let i = 0; i < this.state.uploads.length; i++) {
                if (this.state.uploads[i].id === item.id) {
                    this.state.uploads.splice(i, 1);
                    break;
                }
            }
            this.setState({
                uploads: this.state.uploads
            });
        }
    };

    renders = {
        age: (min, max) => {
            let age = [];
            for (let i = min; i < max; i++) {
                let object = {name: i, value: i};
                if (i === 65) {
                    object.name = '65+';
                }
                age.push(object);
            }
            return age;
        }
    };

    render() {
        return (
            <div className="Requests">
                {
                    this.state.loading &&
                    <Loader
                        title={"Creating request ..."}
                    />
                }
                <div className="Requests-Steps">
                    <div className="Requests-StepsItem">
                        <div className={
                            cx("Requests-StepsItemCircle",
                                {
                                    ["Requests-StepsItemCircleActive"]: (this.state.steps.second.active || this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active),
                                })
                        }>
                            {
                                !(this.state.steps.second.active || this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <span>1</span>
                            }
                            {
                                (this.state.steps.second.active || this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <FeatherIcon color={"#ffffff"} icon={'check'} size={20}/>
                            }
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Channels
                        </div>
                    </div>
                    <div className="Requests-StepsItem">
                        <div className={
                            cx("Requests-StepsItemCircle",
                                {
                                    ["Requests-StepsItemCircleActive"]: (this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active),
                                })
                        }>
                            {
                                !(this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <span>2</span>
                            }
                            {
                                (this.state.steps.third.active || this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <FeatherIcon color={"#ffffff"} icon={'check'} size={20}/>
                            }
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Creatives
                        </div>
                    </div>
                    <div className="Requests-StepsItem">
                        <div className={
                            cx("Requests-StepsItemCircle",
                                {
                                    ["Requests-StepsItemCircleActive"]: (this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active),
                                })
                        }>
                            {
                                !(this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <span>3</span>
                            }
                            {
                                (this.state.steps.fourth.active || this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <FeatherIcon color={"#ffffff"} icon={'check'} size={20}/>
                            }
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Ad text
                        </div>
                    </div>
                    <div className="Requests-StepsItem">
                        <div className={
                            cx("Requests-StepsItemCircle",
                                {
                                    ["Requests-StepsItemCircleActive"]: (this.state.steps.fifth.active || this.state.steps.sixth.active),
                                })
                        }>
                            {
                                !(this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <span>4</span>
                            }
                            {
                                (this.state.steps.fifth.active || this.state.steps.sixth.active) &&
                                <FeatherIcon color={"#ffffff"} icon={'check'} size={20}/>
                            }
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Targeting
                        </div>
                    </div>
                    <div className="Requests-StepsItem">
                        <div className={
                            cx("Requests-StepsItemCircle",
                                {
                                    ["Requests-StepsItemCircleActive"]: this.state.steps.sixth.active,
                                })
                        }>
                            {
                                !(this.state.steps.sixth.active) &&
                                <span>5</span>
                            }
                            {
                                (this.state.steps.sixth.active) &&
                                <FeatherIcon color={"#ffffff"} icon={'check'} size={20}/>
                            }
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Location
                        </div>
                    </div>
                    <div className="Requests-StepsItem">
                        <div className="Requests-StepsItemCircle">
                            6
                        </div>
                        <div className="Requests-StepsItemTitle">
                            Budget
                        </div>
                    </div>
                </div>
                <div className="Requests-Content">
                    {
                        this.state.steps.first.active &&
                        <div className="Requests-ContentTitle">
                            Select channels
                        </div>
                    }
                    {
                        this.state.steps.first.active &&
                        <div className="Requests-ContentChannels">
                            <div onClick={() => {
                                this.state.channels.facebook.active = !this.state.channels.facebook.active;
                                this.setState({
                                    channels: this.state.channels
                                })
                            }} className={
                                cx("Requests-ContentChannelsItem",
                                    {
                                        ["Requests-ContentChannelsItemFacebook"]: this.state.channels.facebook.active,
                                    })
                            }>
                                <img className="Requests-ContentChannelsItemFacebookIcon" src={require('../../assets/images/facebook_icon.svg')}/>
                            </div>
                            <div onClick={() => {
                                this.state.channels.instagram.active = !this.state.channels.instagram.active;
                                this.setState({
                                    channels: this.state.channels
                                })
                            }}  className={
                                cx("Requests-ContentChannelsItem",
                                    {
                                        ["Requests-ContentChannelsItemInstagram"]: this.state.channels.instagram.active,
                                    })
                            }>
                                <img className="Requests-ContentChannelsItemInstagramIcon" src={require('../../assets/images/instagram_icon.svg')}/>
                            </div>
                            <div onClick={() => {
                                this.state.channels.linkedin.active = !this.state.channels.linkedin.active;
                                this.setState({
                                    channels: this.state.channels
                                })
                            }}  className={
                                cx("Requests-ContentChannelsItem",
                                    {
                                        ["Requests-ContentChannelsItemLinkedIn"]: this.state.channels.linkedin.active,
                                    })
                            }>
                                <img className="Requests-ContentChannelsItemLinkedInIcon" src={require('../../assets/images/linkedin_icon.svg')}/>
                            </div>
                            <div onClick={() => {
                                this.state.channels.google.active = !this.state.channels.google.active;
                                this.setState({
                                    channels: this.state.channels
                                })
                            }}  className={
                                cx("Requests-ContentChannelsItem",
                                    {
                                        ["Requests-ContentChannelsItemGoogle"]: this.state.channels.google.active,
                                    })
                            }>
                                <img className="Requests-ContentChannelsItemGoogleIcon" src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        </div>
                    }
                    {
                        this.state.steps.second.active &&
                        <div className="Requests-ContentTitle">
                            Upload content
                        </div>
                    }
                    {
                        this.state.steps.second.active &&
                        <div className="Requests-ContentUploads">
                            <div className="Requests-ContentUploadsItem">
                                {
                                    this.state.uploads.filter((item) => {
                                        return !item.button && !item.error
                                    }).length < 10 &&
                                    <FeatherIcon size={100} color={"#ffffff"} icon={"plus"}/>
                                }
                                {
                                    this.state.uploads.filter((item) => {
                                        return !item.button && !item.error
                                    }).length > 9 &&
                                    <div>Maximum limit 10</div>
                                }
                                {
                                    !this.state.loading && this.state.uploads.filter((item) => {
                                        return !item.button && !item.error
                                    }).length < 10 &&
                                    <input
                                        onChange={(e) => this.functions.onSelectFile(e)}
                                        type="file"
                                        multiple
                                        accept={"image/png, image/jpg, image/jpeg, video/mp4"}
                                        className="Upload-ImageContainerAreaPlusUpload"
                                    />
                                }
                                {
                                    this.state.loading && this.state.uploads.length < 11 &&
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={15}
                                        color={'#388f8a'}
                                        loading={true}
                                    />
                                }
                            </div>
                            {
                                this.state.uploads.map((item) => {
                                    return(
                                        <div className="Requests-ContentUploadsItem">
                                            {
                                                item.loading &&
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={15}
                                                    color={'#388f8a'}
                                                    loading={true}
                                                />
                                            }
                                            {
                                                !item.loading && item.video &&
                                                <video autoPlay muted loop style={{width: '100%', height: '100%'}}>
                                                    <source src={item.url} type="video/mp4"/>
                                                </video>
                                            }
                                            {
                                                !item.loading && item.image &&
                                                <img style={{width: '100%'}} src={item.url}/>
                                            }
                                            {
                                                item.error &&
                                                <div className="Upload-ImageContainerAreaImageError">
                                                    {item.error_message}
                                                </div>
                                            }
                                            {
                                                <div className="Upload-ImageContainerAreaImageOverlay">
                                                    <div className="Upload-ImageContainerAreaImageOverlayRemove">
                                                        <FeatherIcon className="Upload-ImageContainerAreaImageOverlayRemoveIcon"
                                                                     onMouseDown={() => this.functions.remove(item)} color={"#FC5353"}
                                                                     size={25} icon="trash"/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.steps.third.active &&
                        <div className="Requests-ContentTitle">
                            Ad text
                        </div>
                    }
                    {
                        this.state.steps.third.active &&
                        <div className="Requests-ContentTargeting">
                            <div className="Requests-ContentTargetingItem">
                                <Input
                                    noTitle={true}
                                    size={'full'}
                                    value={this.state.headline}
                                    placeholder={'Headline'}
                                    onChange={(event) => {
                                        this.setState({
                                            headline: event.target.value
                                        })
                                    }}
                                />
                            </div>
                            <div className="Requests-ContentTargetingItem">
                                <TextArea
                                    noTitle={true}
                                    size={'full'}
                                    value={this.state.body}
                                    placeholder={'Body'}
                                    onChange={(event) => {
                                        this.setState({
                                            body: event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        this.state.steps.fourth.active &&
                        <div className="Requests-ContentTitle">
                            Set targeting
                        </div>
                    }
                    {
                        this.state.steps.fourth.active &&
                        <div className="Requests-ContentTargeting">
                            <div className="Requests-ContentTargetingItem">
                                <Input
                                    noTitle={true}
                                    size={'full'}
                                    value={this.state.website}
                                    placeholder={'Your website'}
                                    onChange={(event) => {
                                        this.setState({
                                            website: event.target.value
                                        })
                                    }}
                                />
                            </div>
                            <div style={{marginBottom: '15px'}} className="Requests-ContentTargetingItem">
                                <Dropdown
                                    filter={false}
                                    title={'Gender'}
                                    noTitle={true}
                                    size={'half'}
                                    placeholder={"Select genders"}
                                    options={[
                                        {name: 'Male', value: 1},
                                        {name: 'Female', value: 2},
                                        {name: 'Male and Female', value: 0}
                                    ]}
                                    value={this.state.gender?this.state.gender:{}}
                                    onChange={(value) => {
                                        this.state.gender = value;
                                        this.setState({gender: this.state.gender});
                                    }}
                                />
                                <div className="AgeSpan">
                                    <div className="AgeSpanItem">
                                        <Dropdown
                                            filter={false}
                                            title={'Age'}
                                            noTitle={true}
                                            size={'full'}
                                            placeholder={"Set"}
                                            options={this.state.age_min_options}
                                            value={this.state.age_min?this.state.age_min:{}}
                                            onChange={(value) => {
                                                this.setState({
                                                    age_min: value,
                                                    age_min_options: this.renders.age(18, this.state.age_max.value ? this.state.age_max.value : 65),
                                                    age_max_options: this.renders.age(value.value ? (value.value + 1) : 18, 66)
                                                })
                                            }}
                                        />
                                    </div>
                                    <div style={{width: '10%'}}>

                                    </div>
                                    <div className="AgeSpanItem">
                                        <Dropdown
                                            filter={false}
                                            noTitle={true}
                                            title={'Max age'}
                                            size={'full'}
                                            placeholder={"Age"}
                                            options={this.state.age_max_options}
                                            value={this.state.age_max?this.state.age_max:{}}
                                            onChange={(value) => {
                                                this.setState({
                                                    age_max: value,
                                                    age_min_options: this.renders.age(18, value.value ? value.value : 65),
                                                    age_max_options: this.renders.age(this.state.age_min.value ? (this.state.age_min.value + 1) : 18, 66)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="Requests-ContentTargetingItem">
                                <TextArea
                                    noTitle={true}
                                    size={'full'}
                                    value={this.state.description}
                                    placeholder={'Describe your targeting group'}
                                    onChange={(event) => {
                                        this.setState({
                                            description: event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        this.state.steps.fifth.active &&
                        <div className="Requests-ContentTitle">
                            Set location
                        </div>
                    }
                    {
                        this.state.steps.fifth.active &&
                        <div className="Requests-ContentLocation">
                            <div className="Requests-ContentLocationItem">
                                <div className="Requests-ContentLocationItemInput">
                                    <Input
                                        noTitle={true}
                                        size={'full'}
                                        placeholder={'Location'}
                                        value={this.state.location}
                                        onChange={(event) => {
                                            this.setState({
                                                location: event.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div onClick={() => {
                                    this.state.locations.push(this.state.location);
                                    this.setState({
                                        location: ""
                                    });
                                }} className="Requests-ContentLocationItemPlus">
                                    <FeatherIcon size={25} color={"#333333"} icon={"plus"}/>
                                </div>
                            </div>
                            {
                                this.state.locations.map((item, index) => {
                                    return(
                                        <div className="Requests-ContentLocationItem">
                                            <div className="Requests-ContentLocationItemInput">
                                                <Input
                                                    noTitle={true}
                                                    size={'full'}
                                                    disabled={true}
                                                    placeholder={'Location'}
                                                    value={item}
                                                    onChange={(event) => {

                                                    }}
                                                />
                                            </div>
                                            <div onClick={() => {
                                                this.state.locations.splice(index, 1);
                                                this.setState({
                                                    locations: this.state.locations
                                                })
                                            }} className="Requests-ContentLocationItemPlus">
                                                <FeatherIcon size={25} color={"#333333"} icon={"trash"}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.steps.sixth.active &&
                        <div className="Requests-ContentTitle">
                            Set budget
                        </div>
                    }
                    {
                        this.state.steps.sixth.active &&
                        <div className="Requests-ContentLocation">
                            {
                                Object.keys(this.state.channels).map((key) => {
                                    if(this.state.channels[key].active){
                                        return(
                                            <div className="Requests-ContentLocationItem">
                                                <div className="Requests-ContentLocationItemInput">
                                                    <Input
                                                        noTitle={true}
                                                        size={'full'}
                                                        type={'number'}
                                                        value={this.state.channels[key].budget}
                                                        placeholder={'Location'}
                                                        onChange={(event) => {
                                                            this.state.channels[key].budget = event.target.value;
                                                            this.setState({
                                                                channels: this.state.channels
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    key === 'facebook' && this.state.channels[key].active &&
                                                    <div className="Requests-ContentLocationItemPlus Requests-ContentLocationItemFacebook">
                                                        <img className="Requests-ContentLocationItemFacebookIcon" src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'instagram' && this.state.channels[key].active &&
                                                    <div className="Requests-ContentLocationItemPlus Requests-ContentLocationItemInstagram">
                                                        <img className="Requests-ContentLocationItemInstagramIcon" src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'linkedin' && this.state.channels[key].active &&
                                                    <div className="Requests-ContentLocationItemPlus Requests-ContentLocationItemLinkedIn">
                                                        <img className="Requests-ContentLocationItemLinkedInIcon" src={require('../../assets/images/linkedin_icon.svg')}/>
                                                    </div>
                                                }
                                                {
                                                    key === 'google' && this.state.channels[key].active &&
                                                    <div className="Requests-ContentLocationItemPlus Requests-ContentLocationItemGoogle">
                                                        <img className="Requests-ContentLocationItemGoogleIcon" src={require('../../assets/images/google_icon.svg')}/>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    }
                </div>
                <div className="Requests-Buttons">
                    <div  className="Requests-ButtonsLeft">
                        {
                            !this.state.steps.first.active &&
                            <div onClick={() => {
                                this.functions.back()
                            }} className="Button ButtonNeutral">
                                Back
                            </div>
                        }
                    </div>
                    <div className="Requests-ButtonsRight">
                        {
                            !this.state.steps.sixth.active &&
                            <div onClick={() => {
                                this.functions.next()
                            }} className="Button ButtonNeutral">
                                Next
                            </div>
                        }
                        {
                            this.state.steps.sixth.active &&
                            <div onClick={() => {
                                this.functions.send()
                            }} className="Button">
                                Send request
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default UserRequestsNew;
